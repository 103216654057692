import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Json: any;
  Placeholder: any;
};

export type AcceptUserInvitationInput = {
  invitationCode: Scalars['String'];
};

export type AcceptUserInvitationResponse = {
  __typename?: 'AcceptUserInvitationResponse';
  token?: Maybe<Scalars['String']>;
};

export type ActiveIngredient = {
  __typename?: 'ActiveIngredient';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  productId: Scalars['String'];
  chemicalCompoundId: Scalars['String'];
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  product: Product;
  chemicalCompound: ChemicalCompound;
};

export type ActiveIngredientAvgAggregateOutputType = {
  __typename?: 'ActiveIngredientAvgAggregateOutputType';
  massPerDose?: Maybe<Scalars['Float']>;
};

export type ActiveIngredientAvgOrderByAggregateInput = {
  massPerDose?: Maybe<SortOrder>;
};

export type ActiveIngredientCountAggregateOutputType = {
  __typename?: 'ActiveIngredientCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  productId: Scalars['Int'];
  chemicalCompoundId: Scalars['Int'];
  massPerDose: Scalars['Int'];
  massPerDoseUnit: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ActiveIngredientCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  chemicalCompoundId?: Maybe<SortOrder>;
  massPerDose?: Maybe<SortOrder>;
  massPerDoseUnit?: Maybe<SortOrder>;
};

export type ActiveIngredientCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutActiveIngredientsInput;
  chemicalCompound: ChemicalCompoundCreateNestedOneWithoutActiveIngredientsInput;
};

export type ActiveIngredientCreateManyChemicalCompoundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  productId: Scalars['String'];
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
};

export type ActiveIngredientCreateManyChemicalCompoundInputEnvelope = {
  data: Array<ActiveIngredientCreateManyChemicalCompoundInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ActiveIngredientCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  productId: Scalars['String'];
  chemicalCompoundId: Scalars['String'];
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
};

export type ActiveIngredientCreateManyProductInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  chemicalCompoundId: Scalars['String'];
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
};

export type ActiveIngredientCreateManyProductInputEnvelope = {
  data: Array<ActiveIngredientCreateManyProductInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ActiveIngredientCreateNestedManyWithoutChemicalCompoundInput = {
  create?: Maybe<Array<ActiveIngredientCreateWithoutChemicalCompoundInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutChemicalCompoundInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyChemicalCompoundInputEnvelope>;
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
};

export type ActiveIngredientCreateNestedManyWithoutProductInput = {
  create?: Maybe<Array<ActiveIngredientCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutProductInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
};

export type ActiveIngredientCreateOrConnectWithoutChemicalCompoundInput = {
  where: ActiveIngredientWhereUniqueInput;
  create: ActiveIngredientUncheckedCreateWithoutChemicalCompoundInput;
};

export type ActiveIngredientCreateOrConnectWithoutProductInput = {
  where: ActiveIngredientWhereUniqueInput;
  create: ActiveIngredientUncheckedCreateWithoutProductInput;
};

export type ActiveIngredientCreateWithoutChemicalCompoundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutActiveIngredientsInput;
};

export type ActiveIngredientCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  chemicalCompound: ChemicalCompoundCreateNestedOneWithoutActiveIngredientsInput;
};

export type ActiveIngredientListRelationFilter = {
  every?: Maybe<ActiveIngredientWhereInput>;
  some?: Maybe<ActiveIngredientWhereInput>;
  none?: Maybe<ActiveIngredientWhereInput>;
};

export type ActiveIngredientMaxAggregateOutputType = {
  __typename?: 'ActiveIngredientMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  chemicalCompoundId?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
};

export type ActiveIngredientMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  chemicalCompoundId?: Maybe<SortOrder>;
  massPerDose?: Maybe<SortOrder>;
  massPerDoseUnit?: Maybe<SortOrder>;
};

export type ActiveIngredientMinAggregateOutputType = {
  __typename?: 'ActiveIngredientMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  chemicalCompoundId?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
};

export type ActiveIngredientMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  chemicalCompoundId?: Maybe<SortOrder>;
  massPerDose?: Maybe<SortOrder>;
  massPerDoseUnit?: Maybe<SortOrder>;
};

export type ActiveIngredientOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ActiveIngredientOrderByRelevanceFieldEnum {
  Id = 'id',
  ProductId = 'productId',
  ChemicalCompoundId = 'chemicalCompoundId',
  MassPerDoseUnit = 'massPerDoseUnit'
}

export type ActiveIngredientOrderByRelevanceInput = {
  fields: Array<ActiveIngredientOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ActiveIngredientOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  chemicalCompoundId?: Maybe<SortOrder>;
  massPerDose?: Maybe<SortOrder>;
  massPerDoseUnit?: Maybe<SortOrder>;
  _count?: Maybe<ActiveIngredientCountOrderByAggregateInput>;
  _avg?: Maybe<ActiveIngredientAvgOrderByAggregateInput>;
  _max?: Maybe<ActiveIngredientMaxOrderByAggregateInput>;
  _min?: Maybe<ActiveIngredientMinOrderByAggregateInput>;
  _sum?: Maybe<ActiveIngredientSumOrderByAggregateInput>;
};

export type ActiveIngredientOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  chemicalCompoundId?: Maybe<SortOrder>;
  massPerDose?: Maybe<SortOrder>;
  massPerDoseUnit?: Maybe<SortOrder>;
  product?: Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>;
  chemicalCompound?: Maybe<ChemicalCompoundOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ActiveIngredientOrderByRelevanceInput>;
};

export enum ActiveIngredientScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ProductId = 'productId',
  ChemicalCompoundId = 'chemicalCompoundId',
  MassPerDose = 'massPerDose',
  MassPerDoseUnit = 'massPerDoseUnit'
}

export type ActiveIngredientScalarWhereInput = {
  AND?: Maybe<Array<ActiveIngredientScalarWhereInput>>;
  OR?: Maybe<Array<ActiveIngredientScalarWhereInput>>;
  NOT?: Maybe<Array<ActiveIngredientScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  productId?: Maybe<StringFilter>;
  chemicalCompoundId?: Maybe<StringFilter>;
  massPerDose?: Maybe<FloatNullableFilter>;
  massPerDoseUnit?: Maybe<StringNullableFilter>;
};

export type ActiveIngredientScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ActiveIngredientScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ActiveIngredientScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ActiveIngredientScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  productId?: Maybe<StringWithAggregatesFilter>;
  chemicalCompoundId?: Maybe<StringWithAggregatesFilter>;
  massPerDose?: Maybe<FloatNullableWithAggregatesFilter>;
  massPerDoseUnit?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ActiveIngredientSumAggregateOutputType = {
  __typename?: 'ActiveIngredientSumAggregateOutputType';
  massPerDose?: Maybe<Scalars['Float']>;
};

export type ActiveIngredientSumOrderByAggregateInput = {
  massPerDose?: Maybe<SortOrder>;
};

export type ActiveIngredientUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  productId: Scalars['String'];
  chemicalCompoundId: Scalars['String'];
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
};

export type ActiveIngredientUncheckedCreateNestedManyWithoutChemicalCompoundInput = {
  create?: Maybe<Array<ActiveIngredientCreateWithoutChemicalCompoundInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutChemicalCompoundInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyChemicalCompoundInputEnvelope>;
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
};

export type ActiveIngredientUncheckedCreateNestedManyWithoutProductInput = {
  create?: Maybe<Array<ActiveIngredientCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutProductInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
};

export type ActiveIngredientUncheckedCreateWithoutChemicalCompoundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  productId: Scalars['String'];
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
};

export type ActiveIngredientUncheckedCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  chemicalCompoundId: Scalars['String'];
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
};

export type ActiveIngredientUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  chemicalCompoundId?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
};

export type ActiveIngredientUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  chemicalCompoundId?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
};

export type ActiveIngredientUncheckedUpdateManyWithoutActiveIngredientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
};

export type ActiveIngredientUncheckedUpdateManyWithoutChemicalCompoundNestedInput = {
  create?: Maybe<Array<ActiveIngredientCreateWithoutChemicalCompoundInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutChemicalCompoundInput>>;
  upsert?: Maybe<Array<ActiveIngredientUpsertWithWhereUniqueWithoutChemicalCompoundInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyChemicalCompoundInputEnvelope>;
  set?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  disconnect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  delete?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  update?: Maybe<Array<ActiveIngredientUpdateWithWhereUniqueWithoutChemicalCompoundInput>>;
  updateMany?: Maybe<Array<ActiveIngredientUpdateManyWithWhereWithoutChemicalCompoundInput>>;
  deleteMany?: Maybe<Array<ActiveIngredientScalarWhereInput>>;
};

export type ActiveIngredientUncheckedUpdateManyWithoutProductNestedInput = {
  create?: Maybe<Array<ActiveIngredientCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutProductInput>>;
  upsert?: Maybe<Array<ActiveIngredientUpsertWithWhereUniqueWithoutProductInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyProductInputEnvelope>;
  set?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  disconnect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  delete?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  update?: Maybe<Array<ActiveIngredientUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<ActiveIngredientUpdateManyWithWhereWithoutProductInput>>;
  deleteMany?: Maybe<Array<ActiveIngredientScalarWhereInput>>;
};

export type ActiveIngredientUncheckedUpdateWithoutChemicalCompoundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
};

export type ActiveIngredientUncheckedUpdateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  chemicalCompoundId?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
};

export type ActiveIngredientUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  product?: Maybe<ProductUpdateOneRequiredWithoutActiveIngredientsNestedInput>;
  chemicalCompound?: Maybe<ChemicalCompoundUpdateOneRequiredWithoutActiveIngredientsNestedInput>;
};

export type ActiveIngredientUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
};

export type ActiveIngredientUpdateManyWithWhereWithoutChemicalCompoundInput = {
  where: ActiveIngredientScalarWhereInput;
  data: ActiveIngredientUncheckedUpdateManyWithoutActiveIngredientsInput;
};

export type ActiveIngredientUpdateManyWithWhereWithoutProductInput = {
  where: ActiveIngredientScalarWhereInput;
  data: ActiveIngredientUncheckedUpdateManyWithoutActiveIngredientsInput;
};

export type ActiveIngredientUpdateManyWithoutChemicalCompoundNestedInput = {
  create?: Maybe<Array<ActiveIngredientCreateWithoutChemicalCompoundInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutChemicalCompoundInput>>;
  upsert?: Maybe<Array<ActiveIngredientUpsertWithWhereUniqueWithoutChemicalCompoundInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyChemicalCompoundInputEnvelope>;
  set?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  disconnect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  delete?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  update?: Maybe<Array<ActiveIngredientUpdateWithWhereUniqueWithoutChemicalCompoundInput>>;
  updateMany?: Maybe<Array<ActiveIngredientUpdateManyWithWhereWithoutChemicalCompoundInput>>;
  deleteMany?: Maybe<Array<ActiveIngredientScalarWhereInput>>;
};

export type ActiveIngredientUpdateManyWithoutProductNestedInput = {
  create?: Maybe<Array<ActiveIngredientCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutProductInput>>;
  upsert?: Maybe<Array<ActiveIngredientUpsertWithWhereUniqueWithoutProductInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyProductInputEnvelope>;
  set?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  disconnect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  delete?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  update?: Maybe<Array<ActiveIngredientUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<ActiveIngredientUpdateManyWithWhereWithoutProductInput>>;
  deleteMany?: Maybe<Array<ActiveIngredientScalarWhereInput>>;
};

export type ActiveIngredientUpdateWithWhereUniqueWithoutChemicalCompoundInput = {
  where: ActiveIngredientWhereUniqueInput;
  data: ActiveIngredientUncheckedUpdateWithoutChemicalCompoundInput;
};

export type ActiveIngredientUpdateWithWhereUniqueWithoutProductInput = {
  where: ActiveIngredientWhereUniqueInput;
  data: ActiveIngredientUncheckedUpdateWithoutProductInput;
};

export type ActiveIngredientUpdateWithoutChemicalCompoundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  product?: Maybe<ProductUpdateOneRequiredWithoutActiveIngredientsNestedInput>;
};

export type ActiveIngredientUpdateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  chemicalCompound?: Maybe<ChemicalCompoundUpdateOneRequiredWithoutActiveIngredientsNestedInput>;
};

export type ActiveIngredientUpsertWithWhereUniqueWithoutChemicalCompoundInput = {
  where: ActiveIngredientWhereUniqueInput;
  update: ActiveIngredientUncheckedUpdateWithoutChemicalCompoundInput;
  create: ActiveIngredientUncheckedCreateWithoutChemicalCompoundInput;
};

export type ActiveIngredientUpsertWithWhereUniqueWithoutProductInput = {
  where: ActiveIngredientWhereUniqueInput;
  update: ActiveIngredientUncheckedUpdateWithoutProductInput;
  create: ActiveIngredientUncheckedCreateWithoutProductInput;
};

export type ActiveIngredientWhereInput = {
  AND?: Maybe<Array<ActiveIngredientWhereInput>>;
  OR?: Maybe<Array<ActiveIngredientWhereInput>>;
  NOT?: Maybe<Array<ActiveIngredientWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  productId?: Maybe<StringFilter>;
  chemicalCompoundId?: Maybe<StringFilter>;
  massPerDose?: Maybe<FloatNullableFilter>;
  massPerDoseUnit?: Maybe<StringNullableFilter>;
  product?: Maybe<ProductWhereInput>;
  chemicalCompound?: Maybe<ChemicalCompoundWhereInput>;
};

export type ActiveIngredientWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type AggregateActiveIngredient = {
  __typename?: 'AggregateActiveIngredient';
  _count?: Maybe<ActiveIngredientCountAggregateOutputType>;
  _avg?: Maybe<ActiveIngredientAvgAggregateOutputType>;
  _sum?: Maybe<ActiveIngredientSumAggregateOutputType>;
  _min?: Maybe<ActiveIngredientMinAggregateOutputType>;
  _max?: Maybe<ActiveIngredientMaxAggregateOutputType>;
};

export type AggregateApiKey = {
  __typename?: 'AggregateApiKey';
  _count?: Maybe<ApiKeyCountAggregateOutputType>;
  _min?: Maybe<ApiKeyMinAggregateOutputType>;
  _max?: Maybe<ApiKeyMaxAggregateOutputType>;
};

export type AggregateAppointment = {
  __typename?: 'AggregateAppointment';
  _count?: Maybe<AppointmentCountAggregateOutputType>;
  _avg?: Maybe<AppointmentAvgAggregateOutputType>;
  _sum?: Maybe<AppointmentSumAggregateOutputType>;
  _min?: Maybe<AppointmentMinAggregateOutputType>;
  _max?: Maybe<AppointmentMaxAggregateOutputType>;
};

export type AggregateAppointmentAvailability = {
  __typename?: 'AggregateAppointmentAvailability';
  _count?: Maybe<AppointmentAvailabilityCountAggregateOutputType>;
  _min?: Maybe<AppointmentAvailabilityMinAggregateOutputType>;
  _max?: Maybe<AppointmentAvailabilityMaxAggregateOutputType>;
};

export type AggregateAppointmentType = {
  __typename?: 'AggregateAppointmentType';
  _count?: Maybe<AppointmentTypeCountAggregateOutputType>;
  _avg?: Maybe<AppointmentTypeAvgAggregateOutputType>;
  _sum?: Maybe<AppointmentTypeSumAggregateOutputType>;
  _min?: Maybe<AppointmentTypeMinAggregateOutputType>;
  _max?: Maybe<AppointmentTypeMaxAggregateOutputType>;
};

export type AggregateAutomationRun = {
  __typename?: 'AggregateAutomationRun';
  _count?: Maybe<AutomationRunCountAggregateOutputType>;
  _min?: Maybe<AutomationRunMinAggregateOutputType>;
  _max?: Maybe<AutomationRunMaxAggregateOutputType>;
};

export type AggregateAutomationRunAction = {
  __typename?: 'AggregateAutomationRunAction';
  _count?: Maybe<AutomationRunActionCountAggregateOutputType>;
  _avg?: Maybe<AutomationRunActionAvgAggregateOutputType>;
  _sum?: Maybe<AutomationRunActionSumAggregateOutputType>;
  _min?: Maybe<AutomationRunActionMinAggregateOutputType>;
  _max?: Maybe<AutomationRunActionMaxAggregateOutputType>;
};

export type AggregateBitwerxHealthcheck = {
  __typename?: 'AggregateBitwerxHealthcheck';
  _count?: Maybe<BitwerxHealthcheckCountAggregateOutputType>;
  _min?: Maybe<BitwerxHealthcheckMinAggregateOutputType>;
  _max?: Maybe<BitwerxHealthcheckMaxAggregateOutputType>;
};

export type AggregateBitwerxIntegration = {
  __typename?: 'AggregateBitwerxIntegration';
  _count?: Maybe<BitwerxIntegrationCountAggregateOutputType>;
  _min?: Maybe<BitwerxIntegrationMinAggregateOutputType>;
  _max?: Maybe<BitwerxIntegrationMaxAggregateOutputType>;
};

export type AggregateBitwerxPaymentHealthcheckResult = {
  __typename?: 'AggregateBitwerxPaymentHealthcheckResult';
  _count?: Maybe<BitwerxPaymentHealthcheckResultCountAggregateOutputType>;
  _avg?: Maybe<BitwerxPaymentHealthcheckResultAvgAggregateOutputType>;
  _sum?: Maybe<BitwerxPaymentHealthcheckResultSumAggregateOutputType>;
  _min?: Maybe<BitwerxPaymentHealthcheckResultMinAggregateOutputType>;
  _max?: Maybe<BitwerxPaymentHealthcheckResultMaxAggregateOutputType>;
};

export type AggregateBitwerxPaymentHealthcheckRun = {
  __typename?: 'AggregateBitwerxPaymentHealthcheckRun';
  _count?: Maybe<BitwerxPaymentHealthcheckRunCountAggregateOutputType>;
  _avg?: Maybe<BitwerxPaymentHealthcheckRunAvgAggregateOutputType>;
  _sum?: Maybe<BitwerxPaymentHealthcheckRunSumAggregateOutputType>;
  _min?: Maybe<BitwerxPaymentHealthcheckRunMinAggregateOutputType>;
  _max?: Maybe<BitwerxPaymentHealthcheckRunMaxAggregateOutputType>;
};

export type AggregateBitwerxPaymentWritebackConfiguration = {
  __typename?: 'AggregateBitwerxPaymentWritebackConfiguration';
  _count?: Maybe<BitwerxPaymentWritebackConfigurationCountAggregateOutputType>;
  _min?: Maybe<BitwerxPaymentWritebackConfigurationMinAggregateOutputType>;
  _max?: Maybe<BitwerxPaymentWritebackConfigurationMaxAggregateOutputType>;
};

export type AggregateBoardFilterColumn = {
  __typename?: 'AggregateBoardFilterColumn';
  _count?: Maybe<BoardFilterColumnCountAggregateOutputType>;
  _min?: Maybe<BoardFilterColumnMinAggregateOutputType>;
  _max?: Maybe<BoardFilterColumnMaxAggregateOutputType>;
};

export type AggregateBoardFilterSpecificDateRange = {
  __typename?: 'AggregateBoardFilterSpecificDateRange';
  _count?: Maybe<BoardFilterSpecificDateRangeCountAggregateOutputType>;
  _min?: Maybe<BoardFilterSpecificDateRangeMinAggregateOutputType>;
  _max?: Maybe<BoardFilterSpecificDateRangeMaxAggregateOutputType>;
};

export type AggregateBoehringerIngelheimCustomerAlignment = {
  __typename?: 'AggregateBoehringerIngelheimCustomerAlignment';
  _count?: Maybe<BoehringerIngelheimCustomerAlignmentCountAggregateOutputType>;
  _avg?: Maybe<BoehringerIngelheimCustomerAlignmentAvgAggregateOutputType>;
  _sum?: Maybe<BoehringerIngelheimCustomerAlignmentSumAggregateOutputType>;
  _min?: Maybe<BoehringerIngelheimCustomerAlignmentMinAggregateOutputType>;
  _max?: Maybe<BoehringerIngelheimCustomerAlignmentMaxAggregateOutputType>;
};

export type AggregateBoehringerIngelheimTerritory = {
  __typename?: 'AggregateBoehringerIngelheimTerritory';
  _count?: Maybe<BoehringerIngelheimTerritoryCountAggregateOutputType>;
  _min?: Maybe<BoehringerIngelheimTerritoryMinAggregateOutputType>;
  _max?: Maybe<BoehringerIngelheimTerritoryMaxAggregateOutputType>;
};

export type AggregateBreed = {
  __typename?: 'AggregateBreed';
  _count?: Maybe<BreedCountAggregateOutputType>;
  _min?: Maybe<BreedMinAggregateOutputType>;
  _max?: Maybe<BreedMaxAggregateOutputType>;
};

export type AggregateCallHistory = {
  __typename?: 'AggregateCallHistory';
  _count?: Maybe<CallHistoryCountAggregateOutputType>;
  _avg?: Maybe<CallHistoryAvgAggregateOutputType>;
  _sum?: Maybe<CallHistorySumAggregateOutputType>;
  _min?: Maybe<CallHistoryMinAggregateOutputType>;
  _max?: Maybe<CallHistoryMaxAggregateOutputType>;
};

export type AggregateCallParticipant = {
  __typename?: 'AggregateCallParticipant';
  _count?: Maybe<CallParticipantCountAggregateOutputType>;
  _min?: Maybe<CallParticipantMinAggregateOutputType>;
  _max?: Maybe<CallParticipantMaxAggregateOutputType>;
};

export type AggregateCallRecording = {
  __typename?: 'AggregateCallRecording';
  _count?: Maybe<CallRecordingCountAggregateOutputType>;
  _avg?: Maybe<CallRecordingAvgAggregateOutputType>;
  _sum?: Maybe<CallRecordingSumAggregateOutputType>;
  _min?: Maybe<CallRecordingMinAggregateOutputType>;
  _max?: Maybe<CallRecordingMaxAggregateOutputType>;
};

export type AggregateCampaignRegistryCampaign = {
  __typename?: 'AggregateCampaignRegistryCampaign';
  _count?: Maybe<CampaignRegistryCampaignCountAggregateOutputType>;
  _min?: Maybe<CampaignRegistryCampaignMinAggregateOutputType>;
  _max?: Maybe<CampaignRegistryCampaignMaxAggregateOutputType>;
};

export type AggregateCanonicalBreed = {
  __typename?: 'AggregateCanonicalBreed';
  _count?: Maybe<CanonicalBreedCountAggregateOutputType>;
  _avg?: Maybe<CanonicalBreedAvgAggregateOutputType>;
  _sum?: Maybe<CanonicalBreedSumAggregateOutputType>;
  _min?: Maybe<CanonicalBreedMinAggregateOutputType>;
  _max?: Maybe<CanonicalBreedMaxAggregateOutputType>;
};

export type AggregateCanonicalCanineBreedProfile = {
  __typename?: 'AggregateCanonicalCanineBreedProfile';
  _count?: Maybe<CanonicalCanineBreedProfileCountAggregateOutputType>;
  _avg?: Maybe<CanonicalCanineBreedProfileAvgAggregateOutputType>;
  _sum?: Maybe<CanonicalCanineBreedProfileSumAggregateOutputType>;
  _min?: Maybe<CanonicalCanineBreedProfileMinAggregateOutputType>;
  _max?: Maybe<CanonicalCanineBreedProfileMaxAggregateOutputType>;
};

export type AggregateCanonicalService = {
  __typename?: 'AggregateCanonicalService';
  _count?: Maybe<CanonicalServiceCountAggregateOutputType>;
  _min?: Maybe<CanonicalServiceMinAggregateOutputType>;
  _max?: Maybe<CanonicalServiceMaxAggregateOutputType>;
};

export type AggregateCanonicalSpecies = {
  __typename?: 'AggregateCanonicalSpecies';
  _count?: Maybe<CanonicalSpeciesCountAggregateOutputType>;
  _min?: Maybe<CanonicalSpeciesMinAggregateOutputType>;
  _max?: Maybe<CanonicalSpeciesMaxAggregateOutputType>;
};

export type AggregateCareAccountCreditIntent = {
  __typename?: 'AggregateCareAccountCreditIntent';
  _count?: Maybe<CareAccountCreditIntentCountAggregateOutputType>;
  _avg?: Maybe<CareAccountCreditIntentAvgAggregateOutputType>;
  _sum?: Maybe<CareAccountCreditIntentSumAggregateOutputType>;
  _min?: Maybe<CareAccountCreditIntentMinAggregateOutputType>;
  _max?: Maybe<CareAccountCreditIntentMaxAggregateOutputType>;
};

export type AggregateCareAccountCreditPimsWritebackLogEntry = {
  __typename?: 'AggregateCareAccountCreditPimsWritebackLogEntry';
  _count?: Maybe<CareAccountCreditPimsWritebackLogEntryCountAggregateOutputType>;
  _min?: Maybe<CareAccountCreditPimsWritebackLogEntryMinAggregateOutputType>;
  _max?: Maybe<CareAccountCreditPimsWritebackLogEntryMaxAggregateOutputType>;
};

export type AggregateCareAddonEnrollment = {
  __typename?: 'AggregateCareAddonEnrollment';
  _count?: Maybe<CareAddonEnrollmentCountAggregateOutputType>;
  _min?: Maybe<CareAddonEnrollmentMinAggregateOutputType>;
  _max?: Maybe<CareAddonEnrollmentMaxAggregateOutputType>;
};

export type AggregateCareAddonPackage = {
  __typename?: 'AggregateCareAddonPackage';
  _count?: Maybe<CareAddonPackageCountAggregateOutputType>;
  _avg?: Maybe<CareAddonPackageAvgAggregateOutputType>;
  _sum?: Maybe<CareAddonPackageSumAggregateOutputType>;
  _min?: Maybe<CareAddonPackageMinAggregateOutputType>;
  _max?: Maybe<CareAddonPackageMaxAggregateOutputType>;
};

export type AggregateCareAddonPackageBenefit = {
  __typename?: 'AggregateCareAddonPackageBenefit';
  _count?: Maybe<CareAddonPackageBenefitCountAggregateOutputType>;
  _avg?: Maybe<CareAddonPackageBenefitAvgAggregateOutputType>;
  _sum?: Maybe<CareAddonPackageBenefitSumAggregateOutputType>;
  _min?: Maybe<CareAddonPackageBenefitMinAggregateOutputType>;
  _max?: Maybe<CareAddonPackageBenefitMaxAggregateOutputType>;
};

export type AggregateCareAuthorizationRequest = {
  __typename?: 'AggregateCareAuthorizationRequest';
  _count?: Maybe<CareAuthorizationRequestCountAggregateOutputType>;
  _min?: Maybe<CareAuthorizationRequestMinAggregateOutputType>;
  _max?: Maybe<CareAuthorizationRequestMaxAggregateOutputType>;
};

export type AggregateCareBenefit = {
  __typename?: 'AggregateCareBenefit';
  _count?: Maybe<CareBenefitCountAggregateOutputType>;
  _avg?: Maybe<CareBenefitAvgAggregateOutputType>;
  _sum?: Maybe<CareBenefitSumAggregateOutputType>;
  _min?: Maybe<CareBenefitMinAggregateOutputType>;
  _max?: Maybe<CareBenefitMaxAggregateOutputType>;
};

export type AggregateCareBenefitUsage = {
  __typename?: 'AggregateCareBenefitUsage';
  _count?: Maybe<CareBenefitUsageCountAggregateOutputType>;
  _avg?: Maybe<CareBenefitUsageAvgAggregateOutputType>;
  _sum?: Maybe<CareBenefitUsageSumAggregateOutputType>;
  _min?: Maybe<CareBenefitUsageMinAggregateOutputType>;
  _max?: Maybe<CareBenefitUsageMaxAggregateOutputType>;
};

export type AggregateCareBenefitUsageFollowup = {
  __typename?: 'AggregateCareBenefitUsageFollowup';
  _count?: Maybe<CareBenefitUsageFollowupCountAggregateOutputType>;
  _avg?: Maybe<CareBenefitUsageFollowupAvgAggregateOutputType>;
  _sum?: Maybe<CareBenefitUsageFollowupSumAggregateOutputType>;
  _min?: Maybe<CareBenefitUsageFollowupMinAggregateOutputType>;
  _max?: Maybe<CareBenefitUsageFollowupMaxAggregateOutputType>;
};

export type AggregateCareBenefitUsageFollowupStep = {
  __typename?: 'AggregateCareBenefitUsageFollowupStep';
  _count?: Maybe<CareBenefitUsageFollowupStepCountAggregateOutputType>;
  _avg?: Maybe<CareBenefitUsageFollowupStepAvgAggregateOutputType>;
  _sum?: Maybe<CareBenefitUsageFollowupStepSumAggregateOutputType>;
  _min?: Maybe<CareBenefitUsageFollowupStepMinAggregateOutputType>;
  _max?: Maybe<CareBenefitUsageFollowupStepMaxAggregateOutputType>;
};

export type AggregateCareBenefitUsageToInvoiceLineItem = {
  __typename?: 'AggregateCareBenefitUsageToInvoiceLineItem';
  _count?: Maybe<CareBenefitUsageToInvoiceLineItemCountAggregateOutputType>;
  _avg?: Maybe<CareBenefitUsageToInvoiceLineItemAvgAggregateOutputType>;
  _sum?: Maybe<CareBenefitUsageToInvoiceLineItemSumAggregateOutputType>;
  _min?: Maybe<CareBenefitUsageToInvoiceLineItemMinAggregateOutputType>;
  _max?: Maybe<CareBenefitUsageToInvoiceLineItemMaxAggregateOutputType>;
};

export type AggregateCareEnrollmentPayment = {
  __typename?: 'AggregateCareEnrollmentPayment';
  _count?: Maybe<CareEnrollmentPaymentCountAggregateOutputType>;
  _avg?: Maybe<CareEnrollmentPaymentAvgAggregateOutputType>;
  _sum?: Maybe<CareEnrollmentPaymentSumAggregateOutputType>;
  _min?: Maybe<CareEnrollmentPaymentMinAggregateOutputType>;
  _max?: Maybe<CareEnrollmentPaymentMaxAggregateOutputType>;
};

export type AggregateCareEnrollmentToStripeInvoiceItem = {
  __typename?: 'AggregateCareEnrollmentToStripeInvoiceItem';
  _count?: Maybe<CareEnrollmentToStripeInvoiceItemCountAggregateOutputType>;
  _min?: Maybe<CareEnrollmentToStripeInvoiceItemMinAggregateOutputType>;
  _max?: Maybe<CareEnrollmentToStripeInvoiceItemMaxAggregateOutputType>;
};

export type AggregateCareLapsedPayment = {
  __typename?: 'AggregateCareLapsedPayment';
  _count?: Maybe<CareLapsedPaymentCountAggregateOutputType>;
  _min?: Maybe<CareLapsedPaymentMinAggregateOutputType>;
  _max?: Maybe<CareLapsedPaymentMaxAggregateOutputType>;
};

export type AggregateCarePlan = {
  __typename?: 'AggregateCarePlan';
  _count?: Maybe<CarePlanCountAggregateOutputType>;
  _avg?: Maybe<CarePlanAvgAggregateOutputType>;
  _sum?: Maybe<CarePlanSumAggregateOutputType>;
  _min?: Maybe<CarePlanMinAggregateOutputType>;
  _max?: Maybe<CarePlanMaxAggregateOutputType>;
};

export type AggregateCarePlanBenefit = {
  __typename?: 'AggregateCarePlanBenefit';
  _count?: Maybe<CarePlanBenefitCountAggregateOutputType>;
  _avg?: Maybe<CarePlanBenefitAvgAggregateOutputType>;
  _sum?: Maybe<CarePlanBenefitSumAggregateOutputType>;
  _min?: Maybe<CarePlanBenefitMinAggregateOutputType>;
  _max?: Maybe<CarePlanBenefitMaxAggregateOutputType>;
};

export type AggregateCarePlanBenefitEnrollmentCarryover = {
  __typename?: 'AggregateCarePlanBenefitEnrollmentCarryover';
  _count?: Maybe<CarePlanBenefitEnrollmentCarryoverCountAggregateOutputType>;
  _avg?: Maybe<CarePlanBenefitEnrollmentCarryoverAvgAggregateOutputType>;
  _sum?: Maybe<CarePlanBenefitEnrollmentCarryoverSumAggregateOutputType>;
  _min?: Maybe<CarePlanBenefitEnrollmentCarryoverMinAggregateOutputType>;
  _max?: Maybe<CarePlanBenefitEnrollmentCarryoverMaxAggregateOutputType>;
};

export type AggregateCarePlanEnrollment = {
  __typename?: 'AggregateCarePlanEnrollment';
  _count?: Maybe<CarePlanEnrollmentCountAggregateOutputType>;
  _min?: Maybe<CarePlanEnrollmentMinAggregateOutputType>;
  _max?: Maybe<CarePlanEnrollmentMaxAggregateOutputType>;
};

export type AggregateCarePlanProviderGroup = {
  __typename?: 'AggregateCarePlanProviderGroup';
  _count?: Maybe<CarePlanProviderGroupCountAggregateOutputType>;
  _min?: Maybe<CarePlanProviderGroupMinAggregateOutputType>;
  _max?: Maybe<CarePlanProviderGroupMaxAggregateOutputType>;
};

export type AggregateCareStripeSubscription = {
  __typename?: 'AggregateCareStripeSubscription';
  _count?: Maybe<CareStripeSubscriptionCountAggregateOutputType>;
  _avg?: Maybe<CareStripeSubscriptionAvgAggregateOutputType>;
  _sum?: Maybe<CareStripeSubscriptionSumAggregateOutputType>;
  _min?: Maybe<CareStripeSubscriptionMinAggregateOutputType>;
  _max?: Maybe<CareStripeSubscriptionMaxAggregateOutputType>;
};

export type AggregateCareSubscriptionTrueUp = {
  __typename?: 'AggregateCareSubscriptionTrueUp';
  _count?: Maybe<CareSubscriptionTrueUpCountAggregateOutputType>;
  _avg?: Maybe<CareSubscriptionTrueUpAvgAggregateOutputType>;
  _sum?: Maybe<CareSubscriptionTrueUpSumAggregateOutputType>;
  _min?: Maybe<CareSubscriptionTrueUpMinAggregateOutputType>;
  _max?: Maybe<CareSubscriptionTrueUpMaxAggregateOutputType>;
};

export type AggregateCareSubscriptionTrueUpLogEntry = {
  __typename?: 'AggregateCareSubscriptionTrueUpLogEntry';
  _count?: Maybe<CareSubscriptionTrueUpLogEntryCountAggregateOutputType>;
  _avg?: Maybe<CareSubscriptionTrueUpLogEntryAvgAggregateOutputType>;
  _sum?: Maybe<CareSubscriptionTrueUpLogEntrySumAggregateOutputType>;
  _min?: Maybe<CareSubscriptionTrueUpLogEntryMinAggregateOutputType>;
  _max?: Maybe<CareSubscriptionTrueUpLogEntryMaxAggregateOutputType>;
};

export type AggregateChannel = {
  __typename?: 'AggregateChannel';
  _count?: Maybe<ChannelCountAggregateOutputType>;
  _min?: Maybe<ChannelMinAggregateOutputType>;
  _max?: Maybe<ChannelMaxAggregateOutputType>;
};

export type AggregateChannelAutomationStatus = {
  __typename?: 'AggregateChannelAutomationStatus';
  _count?: Maybe<ChannelAutomationStatusCountAggregateOutputType>;
  _min?: Maybe<ChannelAutomationStatusMinAggregateOutputType>;
  _max?: Maybe<ChannelAutomationStatusMaxAggregateOutputType>;
};

export type AggregateChannelCreationSource = {
  __typename?: 'AggregateChannelCreationSource';
  _count?: Maybe<ChannelCreationSourceCountAggregateOutputType>;
  _min?: Maybe<ChannelCreationSourceMinAggregateOutputType>;
  _max?: Maybe<ChannelCreationSourceMaxAggregateOutputType>;
};

export type AggregateChannelFilterSelection = {
  __typename?: 'AggregateChannelFilterSelection';
  _count?: Maybe<ChannelFilterSelectionCountAggregateOutputType>;
  _min?: Maybe<ChannelFilterSelectionMinAggregateOutputType>;
  _max?: Maybe<ChannelFilterSelectionMaxAggregateOutputType>;
};

export type AggregateChannelMember = {
  __typename?: 'AggregateChannelMember';
  _count?: Maybe<ChannelMemberCountAggregateOutputType>;
  _avg?: Maybe<ChannelMemberAvgAggregateOutputType>;
  _sum?: Maybe<ChannelMemberSumAggregateOutputType>;
  _min?: Maybe<ChannelMemberMinAggregateOutputType>;
  _max?: Maybe<ChannelMemberMaxAggregateOutputType>;
};

export type AggregateChannelMessage = {
  __typename?: 'AggregateChannelMessage';
  _count?: Maybe<ChannelMessageCountAggregateOutputType>;
  _avg?: Maybe<ChannelMessageAvgAggregateOutputType>;
  _sum?: Maybe<ChannelMessageSumAggregateOutputType>;
  _min?: Maybe<ChannelMessageMinAggregateOutputType>;
  _max?: Maybe<ChannelMessageMaxAggregateOutputType>;
};

export type AggregateChannelMessageAttachment = {
  __typename?: 'AggregateChannelMessageAttachment';
  _count?: Maybe<ChannelMessageAttachmentCountAggregateOutputType>;
  _min?: Maybe<ChannelMessageAttachmentMinAggregateOutputType>;
  _max?: Maybe<ChannelMessageAttachmentMaxAggregateOutputType>;
};

export type AggregateChannelMessageAutoResponse = {
  __typename?: 'AggregateChannelMessageAutoResponse';
  _count?: Maybe<ChannelMessageAutoResponseCountAggregateOutputType>;
  _min?: Maybe<ChannelMessageAutoResponseMinAggregateOutputType>;
  _max?: Maybe<ChannelMessageAutoResponseMaxAggregateOutputType>;
};

export type AggregateChannelMessageReaction = {
  __typename?: 'AggregateChannelMessageReaction';
  _count?: Maybe<ChannelMessageReactionCountAggregateOutputType>;
  _min?: Maybe<ChannelMessageReactionMinAggregateOutputType>;
  _max?: Maybe<ChannelMessageReactionMaxAggregateOutputType>;
};

export type AggregateChannelPin = {
  __typename?: 'AggregateChannelPin';
  _count?: Maybe<ChannelPinCountAggregateOutputType>;
  _min?: Maybe<ChannelPinMinAggregateOutputType>;
  _max?: Maybe<ChannelPinMaxAggregateOutputType>;
};

export type AggregateChannelSelection = {
  __typename?: 'AggregateChannelSelection';
  _count?: Maybe<ChannelSelectionCountAggregateOutputType>;
  _min?: Maybe<ChannelSelectionMinAggregateOutputType>;
  _max?: Maybe<ChannelSelectionMaxAggregateOutputType>;
};

export type AggregateChannelStatus = {
  __typename?: 'AggregateChannelStatus';
  _count?: Maybe<ChannelStatusCountAggregateOutputType>;
  _avg?: Maybe<ChannelStatusAvgAggregateOutputType>;
  _sum?: Maybe<ChannelStatusSumAggregateOutputType>;
  _min?: Maybe<ChannelStatusMinAggregateOutputType>;
  _max?: Maybe<ChannelStatusMaxAggregateOutputType>;
};

export type AggregateChannelStatusChannelAssignee = {
  __typename?: 'AggregateChannelStatusChannelAssignee';
  _count?: Maybe<ChannelStatusChannelAssigneeCountAggregateOutputType>;
  _min?: Maybe<ChannelStatusChannelAssigneeMinAggregateOutputType>;
  _max?: Maybe<ChannelStatusChannelAssigneeMaxAggregateOutputType>;
};

export type AggregateChemicalCompound = {
  __typename?: 'AggregateChemicalCompound';
  _count?: Maybe<ChemicalCompoundCountAggregateOutputType>;
  _min?: Maybe<ChemicalCompoundMinAggregateOutputType>;
  _max?: Maybe<ChemicalCompoundMaxAggregateOutputType>;
};

export type AggregateClientConnectIntegration = {
  __typename?: 'AggregateClientConnectIntegration';
  _count?: Maybe<ClientConnectIntegrationCountAggregateOutputType>;
  _min?: Maybe<ClientConnectIntegrationMinAggregateOutputType>;
  _max?: Maybe<ClientConnectIntegrationMaxAggregateOutputType>;
};

export type AggregateClinic = {
  __typename?: 'AggregateClinic';
  _count?: Maybe<ClinicCountAggregateOutputType>;
  _avg?: Maybe<ClinicAvgAggregateOutputType>;
  _sum?: Maybe<ClinicSumAggregateOutputType>;
  _min?: Maybe<ClinicMinAggregateOutputType>;
  _max?: Maybe<ClinicMaxAggregateOutputType>;
};

export type AggregateClinicBlockedPhoneNumber = {
  __typename?: 'AggregateClinicBlockedPhoneNumber';
  _count?: Maybe<ClinicBlockedPhoneNumberCountAggregateOutputType>;
  _min?: Maybe<ClinicBlockedPhoneNumberMinAggregateOutputType>;
  _max?: Maybe<ClinicBlockedPhoneNumberMaxAggregateOutputType>;
};

export type AggregateClinicDirectBookingSetting = {
  __typename?: 'AggregateClinicDirectBookingSetting';
  _count?: Maybe<ClinicDirectBookingSettingCountAggregateOutputType>;
  _avg?: Maybe<ClinicDirectBookingSettingAvgAggregateOutputType>;
  _sum?: Maybe<ClinicDirectBookingSettingSumAggregateOutputType>;
  _min?: Maybe<ClinicDirectBookingSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicDirectBookingSettingMaxAggregateOutputType>;
};

export type AggregateClinicEmailCampaignSetting = {
  __typename?: 'AggregateClinicEmailCampaignSetting';
  _count?: Maybe<ClinicEmailCampaignSettingCountAggregateOutputType>;
  _min?: Maybe<ClinicEmailCampaignSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicEmailCampaignSettingMaxAggregateOutputType>;
};

export type AggregateClinicEmployee = {
  __typename?: 'AggregateClinicEmployee';
  _count?: Maybe<ClinicEmployeeCountAggregateOutputType>;
  _min?: Maybe<ClinicEmployeeMinAggregateOutputType>;
  _max?: Maybe<ClinicEmployeeMaxAggregateOutputType>;
};

export type AggregateClinicEmployeeAppointmentTypeSetting = {
  __typename?: 'AggregateClinicEmployeeAppointmentTypeSetting';
  _count?: Maybe<ClinicEmployeeAppointmentTypeSettingCountAggregateOutputType>;
  _min?: Maybe<ClinicEmployeeAppointmentTypeSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicEmployeeAppointmentTypeSettingMaxAggregateOutputType>;
};

export type AggregateClinicEmployeeToWorkflowEventSetting = {
  __typename?: 'AggregateClinicEmployeeToWorkflowEventSetting';
  _count?: Maybe<ClinicEmployeeToWorkflowEventSettingCountAggregateOutputType>;
  _min?: Maybe<ClinicEmployeeToWorkflowEventSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicEmployeeToWorkflowEventSettingMaxAggregateOutputType>;
};

export type AggregateClinicEntityPhoneNumber = {
  __typename?: 'AggregateClinicEntityPhoneNumber';
  _count?: Maybe<ClinicEntityPhoneNumberCountAggregateOutputType>;
  _min?: Maybe<ClinicEntityPhoneNumberMinAggregateOutputType>;
  _max?: Maybe<ClinicEntityPhoneNumberMaxAggregateOutputType>;
};

export type AggregateClinicOfficeHour = {
  __typename?: 'AggregateClinicOfficeHour';
  _count?: Maybe<ClinicOfficeHourCountAggregateOutputType>;
  _avg?: Maybe<ClinicOfficeHourAvgAggregateOutputType>;
  _sum?: Maybe<ClinicOfficeHourSumAggregateOutputType>;
  _min?: Maybe<ClinicOfficeHourMinAggregateOutputType>;
  _max?: Maybe<ClinicOfficeHourMaxAggregateOutputType>;
};

export type AggregateClinicOnboarding = {
  __typename?: 'AggregateClinicOnboarding';
  _count?: Maybe<ClinicOnboardingCountAggregateOutputType>;
  _min?: Maybe<ClinicOnboardingMinAggregateOutputType>;
  _max?: Maybe<ClinicOnboardingMaxAggregateOutputType>;
};

export type AggregateClinicPaymentFeeConfiguration = {
  __typename?: 'AggregateClinicPaymentFeeConfiguration';
  _count?: Maybe<ClinicPaymentFeeConfigurationCountAggregateOutputType>;
  _avg?: Maybe<ClinicPaymentFeeConfigurationAvgAggregateOutputType>;
  _sum?: Maybe<ClinicPaymentFeeConfigurationSumAggregateOutputType>;
  _min?: Maybe<ClinicPaymentFeeConfigurationMinAggregateOutputType>;
  _max?: Maybe<ClinicPaymentFeeConfigurationMaxAggregateOutputType>;
};

export type AggregateClinicPet = {
  __typename?: 'AggregateClinicPet';
  _count?: Maybe<ClinicPetCountAggregateOutputType>;
  _avg?: Maybe<ClinicPetAvgAggregateOutputType>;
  _sum?: Maybe<ClinicPetSumAggregateOutputType>;
  _min?: Maybe<ClinicPetMinAggregateOutputType>;
  _max?: Maybe<ClinicPetMaxAggregateOutputType>;
};

export type AggregateClinicPetAlert = {
  __typename?: 'AggregateClinicPetAlert';
  _count?: Maybe<ClinicPetAlertCountAggregateOutputType>;
  _min?: Maybe<ClinicPetAlertMinAggregateOutputType>;
  _max?: Maybe<ClinicPetAlertMaxAggregateOutputType>;
};

export type AggregateClinicPetCustomFieldValue = {
  __typename?: 'AggregateClinicPetCustomFieldValue';
  _count?: Maybe<ClinicPetCustomFieldValueCountAggregateOutputType>;
  _min?: Maybe<ClinicPetCustomFieldValueMinAggregateOutputType>;
  _max?: Maybe<ClinicPetCustomFieldValueMaxAggregateOutputType>;
};

export type AggregateClinicPetParent = {
  __typename?: 'AggregateClinicPetParent';
  _count?: Maybe<ClinicPetParentCountAggregateOutputType>;
  _min?: Maybe<ClinicPetParentMinAggregateOutputType>;
  _max?: Maybe<ClinicPetParentMaxAggregateOutputType>;
};

export type AggregateClinicPetParentAddress = {
  __typename?: 'AggregateClinicPetParentAddress';
  _count?: Maybe<ClinicPetParentAddressCountAggregateOutputType>;
  _min?: Maybe<ClinicPetParentAddressMinAggregateOutputType>;
  _max?: Maybe<ClinicPetParentAddressMaxAggregateOutputType>;
};

export type AggregateClinicPetParentCustomFieldValue = {
  __typename?: 'AggregateClinicPetParentCustomFieldValue';
  _count?: Maybe<ClinicPetParentCustomFieldValueCountAggregateOutputType>;
  _min?: Maybe<ClinicPetParentCustomFieldValueMinAggregateOutputType>;
  _max?: Maybe<ClinicPetParentCustomFieldValueMaxAggregateOutputType>;
};

export type AggregateClinicPetParentDevice = {
  __typename?: 'AggregateClinicPetParentDevice';
  _count?: Maybe<ClinicPetParentDeviceCountAggregateOutputType>;
  _min?: Maybe<ClinicPetParentDeviceMinAggregateOutputType>;
  _max?: Maybe<ClinicPetParentDeviceMaxAggregateOutputType>;
};

export type AggregateClinicPetParentPimsSyncStatus = {
  __typename?: 'AggregateClinicPetParentPimsSyncStatus';
  _count?: Maybe<ClinicPetParentPimsSyncStatusCountAggregateOutputType>;
  _avg?: Maybe<ClinicPetParentPimsSyncStatusAvgAggregateOutputType>;
  _sum?: Maybe<ClinicPetParentPimsSyncStatusSumAggregateOutputType>;
  _min?: Maybe<ClinicPetParentPimsSyncStatusMinAggregateOutputType>;
  _max?: Maybe<ClinicPetParentPimsSyncStatusMaxAggregateOutputType>;
};

export type AggregateClinicPetPimsSyncStatus = {
  __typename?: 'AggregateClinicPetPimsSyncStatus';
  _count?: Maybe<ClinicPetPimsSyncStatusCountAggregateOutputType>;
  _avg?: Maybe<ClinicPetPimsSyncStatusAvgAggregateOutputType>;
  _sum?: Maybe<ClinicPetPimsSyncStatusSumAggregateOutputType>;
  _min?: Maybe<ClinicPetPimsSyncStatusMinAggregateOutputType>;
  _max?: Maybe<ClinicPetPimsSyncStatusMaxAggregateOutputType>;
};

export type AggregateClinicPhoneNumber = {
  __typename?: 'AggregateClinicPhoneNumber';
  _count?: Maybe<ClinicPhoneNumberCountAggregateOutputType>;
  _min?: Maybe<ClinicPhoneNumberMinAggregateOutputType>;
  _max?: Maybe<ClinicPhoneNumberMaxAggregateOutputType>;
};

export type AggregateClinicPimsIntegration = {
  __typename?: 'AggregateClinicPimsIntegration';
  _count?: Maybe<ClinicPimsIntegrationCountAggregateOutputType>;
  _min?: Maybe<ClinicPimsIntegrationMinAggregateOutputType>;
  _max?: Maybe<ClinicPimsIntegrationMaxAggregateOutputType>;
};

export type AggregateClinicPimsIntegrationCapability = {
  __typename?: 'AggregateClinicPimsIntegrationCapability';
  _count?: Maybe<ClinicPimsIntegrationCapabilityCountAggregateOutputType>;
  _avg?: Maybe<ClinicPimsIntegrationCapabilityAvgAggregateOutputType>;
  _sum?: Maybe<ClinicPimsIntegrationCapabilitySumAggregateOutputType>;
  _min?: Maybe<ClinicPimsIntegrationCapabilityMinAggregateOutputType>;
  _max?: Maybe<ClinicPimsIntegrationCapabilityMaxAggregateOutputType>;
};

export type AggregateClinicPimsIntegrationCapabilityHistoryEntry = {
  __typename?: 'AggregateClinicPimsIntegrationCapabilityHistoryEntry';
  _count?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCountAggregateOutputType>;
  _avg?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryAvgAggregateOutputType>;
  _sum?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntrySumAggregateOutputType>;
  _min?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryMinAggregateOutputType>;
  _max?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryMaxAggregateOutputType>;
};

export type AggregateClinicPostcardSetting = {
  __typename?: 'AggregateClinicPostcardSetting';
  _count?: Maybe<ClinicPostcardSettingCountAggregateOutputType>;
  _min?: Maybe<ClinicPostcardSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicPostcardSettingMaxAggregateOutputType>;
};

export type AggregateClinicRoom = {
  __typename?: 'AggregateClinicRoom';
  _count?: Maybe<ClinicRoomCountAggregateOutputType>;
  _min?: Maybe<ClinicRoomMinAggregateOutputType>;
  _max?: Maybe<ClinicRoomMaxAggregateOutputType>;
};

export type AggregateClinicRoomToWorkflowEventSetting = {
  __typename?: 'AggregateClinicRoomToWorkflowEventSetting';
  _count?: Maybe<ClinicRoomToWorkflowEventSettingCountAggregateOutputType>;
  _min?: Maybe<ClinicRoomToWorkflowEventSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicRoomToWorkflowEventSettingMaxAggregateOutputType>;
};

export type AggregateClinicSetting = {
  __typename?: 'AggregateClinicSetting';
  _count?: Maybe<ClinicSettingCountAggregateOutputType>;
  _avg?: Maybe<ClinicSettingAvgAggregateOutputType>;
  _sum?: Maybe<ClinicSettingSumAggregateOutputType>;
  _min?: Maybe<ClinicSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicSettingMaxAggregateOutputType>;
};

export type AggregateClinicWidgetRequest = {
  __typename?: 'AggregateClinicWidgetRequest';
  _count?: Maybe<ClinicWidgetRequestCountAggregateOutputType>;
  _min?: Maybe<ClinicWidgetRequestMinAggregateOutputType>;
  _max?: Maybe<ClinicWidgetRequestMaxAggregateOutputType>;
};

export type AggregateClinicWidgetRequestType = {
  __typename?: 'AggregateClinicWidgetRequestType';
  _count?: Maybe<ClinicWidgetRequestTypeCountAggregateOutputType>;
  _avg?: Maybe<ClinicWidgetRequestTypeAvgAggregateOutputType>;
  _sum?: Maybe<ClinicWidgetRequestTypeSumAggregateOutputType>;
  _min?: Maybe<ClinicWidgetRequestTypeMinAggregateOutputType>;
  _max?: Maybe<ClinicWidgetRequestTypeMaxAggregateOutputType>;
};

export type AggregateClinicWidgetSetting = {
  __typename?: 'AggregateClinicWidgetSetting';
  _count?: Maybe<ClinicWidgetSettingCountAggregateOutputType>;
  _min?: Maybe<ClinicWidgetSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicWidgetSettingMaxAggregateOutputType>;
};

export type AggregateClinicWorkflowType = {
  __typename?: 'AggregateClinicWorkflowType';
  _count?: Maybe<ClinicWorkflowTypeCountAggregateOutputType>;
  _min?: Maybe<ClinicWorkflowTypeMinAggregateOutputType>;
  _max?: Maybe<ClinicWorkflowTypeMaxAggregateOutputType>;
};

export type AggregateColor = {
  __typename?: 'AggregateColor';
  _count?: Maybe<ColorCountAggregateOutputType>;
  _min?: Maybe<ColorMinAggregateOutputType>;
  _max?: Maybe<ColorMaxAggregateOutputType>;
};

export type AggregateCondition = {
  __typename?: 'AggregateCondition';
  _count?: Maybe<ConditionCountAggregateOutputType>;
  _min?: Maybe<ConditionMinAggregateOutputType>;
  _max?: Maybe<ConditionMaxAggregateOutputType>;
};

export type AggregateConditionSet = {
  __typename?: 'AggregateConditionSet';
  _count?: Maybe<ConditionSetCountAggregateOutputType>;
  _min?: Maybe<ConditionSetMinAggregateOutputType>;
  _max?: Maybe<ConditionSetMaxAggregateOutputType>;
};

export type AggregateConditionalEntityEvaluation = {
  __typename?: 'AggregateConditionalEntityEvaluation';
  _count?: Maybe<ConditionalEntityEvaluationCountAggregateOutputType>;
  _min?: Maybe<ConditionalEntityEvaluationMinAggregateOutputType>;
  _max?: Maybe<ConditionalEntityEvaluationMaxAggregateOutputType>;
};

export type AggregateConsultation = {
  __typename?: 'AggregateConsultation';
  _count?: Maybe<ConsultationCountAggregateOutputType>;
  _avg?: Maybe<ConsultationAvgAggregateOutputType>;
  _sum?: Maybe<ConsultationSumAggregateOutputType>;
  _min?: Maybe<ConsultationMinAggregateOutputType>;
  _max?: Maybe<ConsultationMaxAggregateOutputType>;
};

export type AggregateConsultationChat = {
  __typename?: 'AggregateConsultationChat';
  _count?: Maybe<ConsultationChatCountAggregateOutputType>;
  _min?: Maybe<ConsultationChatMinAggregateOutputType>;
  _max?: Maybe<ConsultationChatMaxAggregateOutputType>;
};

export type AggregateConsultationRequest = {
  __typename?: 'AggregateConsultationRequest';
  _count?: Maybe<ConsultationRequestCountAggregateOutputType>;
  _min?: Maybe<ConsultationRequestMinAggregateOutputType>;
  _max?: Maybe<ConsultationRequestMaxAggregateOutputType>;
};

export type AggregateCustomFieldDefinition = {
  __typename?: 'AggregateCustomFieldDefinition';
  _count?: Maybe<CustomFieldDefinitionCountAggregateOutputType>;
  _min?: Maybe<CustomFieldDefinitionMinAggregateOutputType>;
  _max?: Maybe<CustomFieldDefinitionMaxAggregateOutputType>;
};

export type AggregateCustomFieldPimsWritebackLogEntry = {
  __typename?: 'AggregateCustomFieldPimsWritebackLogEntry';
  _count?: Maybe<CustomFieldPimsWritebackLogEntryCountAggregateOutputType>;
  _min?: Maybe<CustomFieldPimsWritebackLogEntryMinAggregateOutputType>;
  _max?: Maybe<CustomFieldPimsWritebackLogEntryMaxAggregateOutputType>;
};

export type AggregateDataPointIntegration = {
  __typename?: 'AggregateDataPointIntegration';
  _count?: Maybe<DataPointIntegrationCountAggregateOutputType>;
  _min?: Maybe<DataPointIntegrationMinAggregateOutputType>;
  _max?: Maybe<DataPointIntegrationMaxAggregateOutputType>;
};

export type AggregateDirectBookingAppointmentTypeSetting = {
  __typename?: 'AggregateDirectBookingAppointmentTypeSetting';
  _count?: Maybe<DirectBookingAppointmentTypeSettingCountAggregateOutputType>;
  _avg?: Maybe<DirectBookingAppointmentTypeSettingAvgAggregateOutputType>;
  _sum?: Maybe<DirectBookingAppointmentTypeSettingSumAggregateOutputType>;
  _min?: Maybe<DirectBookingAppointmentTypeSettingMinAggregateOutputType>;
  _max?: Maybe<DirectBookingAppointmentTypeSettingMaxAggregateOutputType>;
};

export type AggregateDirectBookingExclusionRule = {
  __typename?: 'AggregateDirectBookingExclusionRule';
  _count?: Maybe<DirectBookingExclusionRuleCountAggregateOutputType>;
  _min?: Maybe<DirectBookingExclusionRuleMinAggregateOutputType>;
  _max?: Maybe<DirectBookingExclusionRuleMaxAggregateOutputType>;
};

export type AggregateDirectBookingExclusionTimeSlot = {
  __typename?: 'AggregateDirectBookingExclusionTimeSlot';
  _count?: Maybe<DirectBookingExclusionTimeSlotCountAggregateOutputType>;
  _avg?: Maybe<DirectBookingExclusionTimeSlotAvgAggregateOutputType>;
  _sum?: Maybe<DirectBookingExclusionTimeSlotSumAggregateOutputType>;
  _min?: Maybe<DirectBookingExclusionTimeSlotMinAggregateOutputType>;
  _max?: Maybe<DirectBookingExclusionTimeSlotMaxAggregateOutputType>;
};

export type AggregateDisbursement = {
  __typename?: 'AggregateDisbursement';
  _count?: Maybe<DisbursementCountAggregateOutputType>;
  _avg?: Maybe<DisbursementAvgAggregateOutputType>;
  _sum?: Maybe<DisbursementSumAggregateOutputType>;
  _min?: Maybe<DisbursementMinAggregateOutputType>;
  _max?: Maybe<DisbursementMaxAggregateOutputType>;
};

export type AggregateDocumentation = {
  __typename?: 'AggregateDocumentation';
  _count?: Maybe<DocumentationCountAggregateOutputType>;
  _min?: Maybe<DocumentationMinAggregateOutputType>;
  _max?: Maybe<DocumentationMaxAggregateOutputType>;
};

export type AggregateEmailCampaign = {
  __typename?: 'AggregateEmailCampaign';
  _count?: Maybe<EmailCampaignCountAggregateOutputType>;
  _avg?: Maybe<EmailCampaignAvgAggregateOutputType>;
  _sum?: Maybe<EmailCampaignSumAggregateOutputType>;
  _min?: Maybe<EmailCampaignMinAggregateOutputType>;
  _max?: Maybe<EmailCampaignMaxAggregateOutputType>;
};

export type AggregateEmailCampaignCustomList = {
  __typename?: 'AggregateEmailCampaignCustomList';
  _count?: Maybe<EmailCampaignCustomListCountAggregateOutputType>;
  _avg?: Maybe<EmailCampaignCustomListAvgAggregateOutputType>;
  _sum?: Maybe<EmailCampaignCustomListSumAggregateOutputType>;
  _min?: Maybe<EmailCampaignCustomListMinAggregateOutputType>;
  _max?: Maybe<EmailCampaignCustomListMaxAggregateOutputType>;
};

export type AggregateEmailCampaignCustomListRecipient = {
  __typename?: 'AggregateEmailCampaignCustomListRecipient';
  _count?: Maybe<EmailCampaignCustomListRecipientCountAggregateOutputType>;
  _min?: Maybe<EmailCampaignCustomListRecipientMinAggregateOutputType>;
  _max?: Maybe<EmailCampaignCustomListRecipientMaxAggregateOutputType>;
};

export type AggregateEmailCampaignLog = {
  __typename?: 'AggregateEmailCampaignLog';
  _count?: Maybe<EmailCampaignLogCountAggregateOutputType>;
  _min?: Maybe<EmailCampaignLogMinAggregateOutputType>;
  _max?: Maybe<EmailCampaignLogMaxAggregateOutputType>;
};

export type AggregateEmailCampaignTemplate = {
  __typename?: 'AggregateEmailCampaignTemplate';
  _count?: Maybe<EmailCampaignTemplateCountAggregateOutputType>;
  _min?: Maybe<EmailCampaignTemplateMinAggregateOutputType>;
  _max?: Maybe<EmailCampaignTemplateMaxAggregateOutputType>;
};

export type AggregateEmailCampaignUnsubscribe = {
  __typename?: 'AggregateEmailCampaignUnsubscribe';
  _count?: Maybe<EmailCampaignUnsubscribeCountAggregateOutputType>;
  _min?: Maybe<EmailCampaignUnsubscribeMinAggregateOutputType>;
  _max?: Maybe<EmailCampaignUnsubscribeMaxAggregateOutputType>;
};

export type AggregateEmailProviderLog = {
  __typename?: 'AggregateEmailProviderLog';
  _count?: Maybe<EmailProviderLogCountAggregateOutputType>;
  _min?: Maybe<EmailProviderLogMinAggregateOutputType>;
  _max?: Maybe<EmailProviderLogMaxAggregateOutputType>;
};

export type AggregateEmailTemplate = {
  __typename?: 'AggregateEmailTemplate';
  _count?: Maybe<EmailTemplateCountAggregateOutputType>;
  _avg?: Maybe<EmailTemplateAvgAggregateOutputType>;
  _sum?: Maybe<EmailTemplateSumAggregateOutputType>;
  _min?: Maybe<EmailTemplateMinAggregateOutputType>;
  _max?: Maybe<EmailTemplateMaxAggregateOutputType>;
};

export type AggregateEmailTemplateVersion = {
  __typename?: 'AggregateEmailTemplateVersion';
  _count?: Maybe<EmailTemplateVersionCountAggregateOutputType>;
  _avg?: Maybe<EmailTemplateVersionAvgAggregateOutputType>;
  _sum?: Maybe<EmailTemplateVersionSumAggregateOutputType>;
  _min?: Maybe<EmailTemplateVersionMinAggregateOutputType>;
  _max?: Maybe<EmailTemplateVersionMaxAggregateOutputType>;
};

export type AggregateEmergencyClinics = {
  __typename?: 'AggregateEmergencyClinics';
  _count?: Maybe<EmergencyClinicsCountAggregateOutputType>;
  _avg?: Maybe<EmergencyClinicsAvgAggregateOutputType>;
  _sum?: Maybe<EmergencyClinicsSumAggregateOutputType>;
  _min?: Maybe<EmergencyClinicsMinAggregateOutputType>;
  _max?: Maybe<EmergencyClinicsMaxAggregateOutputType>;
};

export type AggregateEnrollmentSupportEvent = {
  __typename?: 'AggregateEnrollmentSupportEvent';
  _count?: Maybe<EnrollmentSupportEventCountAggregateOutputType>;
  _avg?: Maybe<EnrollmentSupportEventAvgAggregateOutputType>;
  _sum?: Maybe<EnrollmentSupportEventSumAggregateOutputType>;
  _min?: Maybe<EnrollmentSupportEventMinAggregateOutputType>;
  _max?: Maybe<EnrollmentSupportEventMaxAggregateOutputType>;
};

export type AggregateEzyVetIntegration = {
  __typename?: 'AggregateEzyVetIntegration';
  _count?: Maybe<EzyVetIntegrationCountAggregateOutputType>;
  _min?: Maybe<EzyVetIntegrationMinAggregateOutputType>;
  _max?: Maybe<EzyVetIntegrationMaxAggregateOutputType>;
};

export type AggregateFeatureFlag = {
  __typename?: 'AggregateFeatureFlag';
  _count?: Maybe<FeatureFlagCountAggregateOutputType>;
  _min?: Maybe<FeatureFlagMinAggregateOutputType>;
  _max?: Maybe<FeatureFlagMaxAggregateOutputType>;
};

export type AggregateFinancialAdjustment = {
  __typename?: 'AggregateFinancialAdjustment';
  _count?: Maybe<FinancialAdjustmentCountAggregateOutputType>;
  _avg?: Maybe<FinancialAdjustmentAvgAggregateOutputType>;
  _sum?: Maybe<FinancialAdjustmentSumAggregateOutputType>;
  _min?: Maybe<FinancialAdjustmentMinAggregateOutputType>;
  _max?: Maybe<FinancialAdjustmentMaxAggregateOutputType>;
};

export type AggregateFinancialTransaction = {
  __typename?: 'AggregateFinancialTransaction';
  _count?: Maybe<FinancialTransactionCountAggregateOutputType>;
  _min?: Maybe<FinancialTransactionMinAggregateOutputType>;
  _max?: Maybe<FinancialTransactionMaxAggregateOutputType>;
};

export type AggregateFirebaseStructure = {
  __typename?: 'AggregateFirebaseStructure';
  _count?: Maybe<FirebaseStructureCountAggregateOutputType>;
  _min?: Maybe<FirebaseStructureMinAggregateOutputType>;
  _max?: Maybe<FirebaseStructureMaxAggregateOutputType>;
};

export type AggregateFormSubmission = {
  __typename?: 'AggregateFormSubmission';
  _count?: Maybe<FormSubmissionCountAggregateOutputType>;
  _min?: Maybe<FormSubmissionMinAggregateOutputType>;
  _max?: Maybe<FormSubmissionMaxAggregateOutputType>;
};

export type AggregateFormTemplate = {
  __typename?: 'AggregateFormTemplate';
  _count?: Maybe<FormTemplateCountAggregateOutputType>;
  _min?: Maybe<FormTemplateMinAggregateOutputType>;
  _max?: Maybe<FormTemplateMaxAggregateOutputType>;
};

export type AggregateGlobalPetParent = {
  __typename?: 'AggregateGlobalPetParent';
  _count?: Maybe<GlobalPetParentCountAggregateOutputType>;
  _min?: Maybe<GlobalPetParentMinAggregateOutputType>;
  _max?: Maybe<GlobalPetParentMaxAggregateOutputType>;
};

export type AggregateHillsToHomeApiLog = {
  __typename?: 'AggregateHillsToHomeAPILog';
  _count?: Maybe<HillsToHomeApiLogCountAggregateOutputType>;
  _min?: Maybe<HillsToHomeApiLogMinAggregateOutputType>;
  _max?: Maybe<HillsToHomeApiLogMaxAggregateOutputType>;
};

export type AggregateImage = {
  __typename?: 'AggregateImage';
  _count?: Maybe<ImageCountAggregateOutputType>;
  _min?: Maybe<ImageMinAggregateOutputType>;
  _max?: Maybe<ImageMaxAggregateOutputType>;
};

export type AggregateInformAudienceDefinition = {
  __typename?: 'AggregateInformAudienceDefinition';
  _count?: Maybe<InformAudienceDefinitionCountAggregateOutputType>;
  _min?: Maybe<InformAudienceDefinitionMinAggregateOutputType>;
  _max?: Maybe<InformAudienceDefinitionMaxAggregateOutputType>;
};

export type AggregateInformAutomationCampaign = {
  __typename?: 'AggregateInformAutomationCampaign';
  _count?: Maybe<InformAutomationCampaignCountAggregateOutputType>;
  _min?: Maybe<InformAutomationCampaignMinAggregateOutputType>;
  _max?: Maybe<InformAutomationCampaignMaxAggregateOutputType>;
};

export type AggregateInformCampaign = {
  __typename?: 'AggregateInformCampaign';
  _count?: Maybe<InformCampaignCountAggregateOutputType>;
  _min?: Maybe<InformCampaignMinAggregateOutputType>;
  _max?: Maybe<InformCampaignMaxAggregateOutputType>;
};

export type AggregateInformCampaignEnrollment = {
  __typename?: 'AggregateInformCampaignEnrollment';
  _count?: Maybe<InformCampaignEnrollmentCountAggregateOutputType>;
  _min?: Maybe<InformCampaignEnrollmentMinAggregateOutputType>;
  _max?: Maybe<InformCampaignEnrollmentMaxAggregateOutputType>;
};

export type AggregateInformCampaignEnrollmentCanonicalService = {
  __typename?: 'AggregateInformCampaignEnrollmentCanonicalService';
  _count?: Maybe<InformCampaignEnrollmentCanonicalServiceCountAggregateOutputType>;
  _min?: Maybe<InformCampaignEnrollmentCanonicalServiceMinAggregateOutputType>;
  _max?: Maybe<InformCampaignEnrollmentCanonicalServiceMaxAggregateOutputType>;
};

export type AggregateInformCampaignExecution = {
  __typename?: 'AggregateInformCampaignExecution';
  _count?: Maybe<InformCampaignExecutionCountAggregateOutputType>;
  _min?: Maybe<InformCampaignExecutionMinAggregateOutputType>;
  _max?: Maybe<InformCampaignExecutionMaxAggregateOutputType>;
};

export type AggregateInformCampaignGroup = {
  __typename?: 'AggregateInformCampaignGroup';
  _count?: Maybe<InformCampaignGroupCountAggregateOutputType>;
  _min?: Maybe<InformCampaignGroupMinAggregateOutputType>;
  _max?: Maybe<InformCampaignGroupMaxAggregateOutputType>;
};

export type AggregateInformControlGroupParticipant = {
  __typename?: 'AggregateInformControlGroupParticipant';
  _count?: Maybe<InformControlGroupParticipantCountAggregateOutputType>;
  _min?: Maybe<InformControlGroupParticipantMinAggregateOutputType>;
  _max?: Maybe<InformControlGroupParticipantMaxAggregateOutputType>;
};

export type AggregateInformEmailTemplate = {
  __typename?: 'AggregateInformEmailTemplate';
  _count?: Maybe<InformEmailTemplateCountAggregateOutputType>;
  _min?: Maybe<InformEmailTemplateMinAggregateOutputType>;
  _max?: Maybe<InformEmailTemplateMaxAggregateOutputType>;
};

export type AggregateInformEntityHistory = {
  __typename?: 'AggregateInformEntityHistory';
  _count?: Maybe<InformEntityHistoryCountAggregateOutputType>;
  _min?: Maybe<InformEntityHistoryMinAggregateOutputType>;
  _max?: Maybe<InformEntityHistoryMaxAggregateOutputType>;
};

export type AggregateInformPartner = {
  __typename?: 'AggregateInformPartner';
  _count?: Maybe<InformPartnerCountAggregateOutputType>;
  _min?: Maybe<InformPartnerMinAggregateOutputType>;
  _max?: Maybe<InformPartnerMaxAggregateOutputType>;
};

export type AggregateInformPartnerCustomer = {
  __typename?: 'AggregateInformPartnerCustomer';
  _count?: Maybe<InformPartnerCustomerCountAggregateOutputType>;
  _min?: Maybe<InformPartnerCustomerMinAggregateOutputType>;
  _max?: Maybe<InformPartnerCustomerMaxAggregateOutputType>;
};

export type AggregateInformPartnerCustomerPharmacyProvider = {
  __typename?: 'AggregateInformPartnerCustomerPharmacyProvider';
  _count?: Maybe<InformPartnerCustomerPharmacyProviderCountAggregateOutputType>;
  _min?: Maybe<InformPartnerCustomerPharmacyProviderMinAggregateOutputType>;
  _max?: Maybe<InformPartnerCustomerPharmacyProviderMaxAggregateOutputType>;
};

export type AggregateInformPartnerIntegration = {
  __typename?: 'AggregateInformPartnerIntegration';
  _count?: Maybe<InformPartnerIntegrationCountAggregateOutputType>;
  _min?: Maybe<InformPartnerIntegrationMinAggregateOutputType>;
  _max?: Maybe<InformPartnerIntegrationMaxAggregateOutputType>;
};

export type AggregateInformPartnerIntegrationModel = {
  __typename?: 'AggregateInformPartnerIntegrationModel';
  _count?: Maybe<InformPartnerIntegrationModelCountAggregateOutputType>;
  _avg?: Maybe<InformPartnerIntegrationModelAvgAggregateOutputType>;
  _sum?: Maybe<InformPartnerIntegrationModelSumAggregateOutputType>;
  _min?: Maybe<InformPartnerIntegrationModelMinAggregateOutputType>;
  _max?: Maybe<InformPartnerIntegrationModelMaxAggregateOutputType>;
};

export type AggregateInformPartnerProgram = {
  __typename?: 'AggregateInformPartnerProgram';
  _count?: Maybe<InformPartnerProgramCountAggregateOutputType>;
  _min?: Maybe<InformPartnerProgramMinAggregateOutputType>;
  _max?: Maybe<InformPartnerProgramMaxAggregateOutputType>;
};

export type AggregateInformPartnerProgramEnrollment = {
  __typename?: 'AggregateInformPartnerProgramEnrollment';
  _count?: Maybe<InformPartnerProgramEnrollmentCountAggregateOutputType>;
  _min?: Maybe<InformPartnerProgramEnrollmentMinAggregateOutputType>;
  _max?: Maybe<InformPartnerProgramEnrollmentMaxAggregateOutputType>;
};

export type AggregateInformPartnerUser = {
  __typename?: 'AggregateInformPartnerUser';
  _count?: Maybe<InformPartnerUserCountAggregateOutputType>;
  _min?: Maybe<InformPartnerUserMinAggregateOutputType>;
  _max?: Maybe<InformPartnerUserMaxAggregateOutputType>;
};

export type AggregateInformPartnerUserRole = {
  __typename?: 'AggregateInformPartnerUserRole';
  _count?: Maybe<InformPartnerUserRoleCountAggregateOutputType>;
  _min?: Maybe<InformPartnerUserRoleMinAggregateOutputType>;
  _max?: Maybe<InformPartnerUserRoleMaxAggregateOutputType>;
};

export type AggregateInformScheduledCampaign = {
  __typename?: 'AggregateInformScheduledCampaign';
  _count?: Maybe<InformScheduledCampaignCountAggregateOutputType>;
  _min?: Maybe<InformScheduledCampaignMinAggregateOutputType>;
  _max?: Maybe<InformScheduledCampaignMaxAggregateOutputType>;
};

export type AggregateInstinctIntegration = {
  __typename?: 'AggregateInstinctIntegration';
  _count?: Maybe<InstinctIntegrationCountAggregateOutputType>;
  _min?: Maybe<InstinctIntegrationMinAggregateOutputType>;
  _max?: Maybe<InstinctIntegrationMaxAggregateOutputType>;
};

export type AggregateInsuranceIntegration = {
  __typename?: 'AggregateInsuranceIntegration';
  _count?: Maybe<InsuranceIntegrationCountAggregateOutputType>;
  _min?: Maybe<InsuranceIntegrationMinAggregateOutputType>;
  _max?: Maybe<InsuranceIntegrationMaxAggregateOutputType>;
};

export type AggregateIntegrationOutageHistoryEntry = {
  __typename?: 'AggregateIntegrationOutageHistoryEntry';
  _count?: Maybe<IntegrationOutageHistoryEntryCountAggregateOutputType>;
  _min?: Maybe<IntegrationOutageHistoryEntryMinAggregateOutputType>;
  _max?: Maybe<IntegrationOutageHistoryEntryMaxAggregateOutputType>;
};

export type AggregateInterchangeRate = {
  __typename?: 'AggregateInterchangeRate';
  _count?: Maybe<InterchangeRateCountAggregateOutputType>;
  _avg?: Maybe<InterchangeRateAvgAggregateOutputType>;
  _sum?: Maybe<InterchangeRateSumAggregateOutputType>;
  _min?: Maybe<InterchangeRateMinAggregateOutputType>;
  _max?: Maybe<InterchangeRateMaxAggregateOutputType>;
};

export type AggregateInvoice = {
  __typename?: 'AggregateInvoice';
  _count?: Maybe<InvoiceCountAggregateOutputType>;
  _avg?: Maybe<InvoiceAvgAggregateOutputType>;
  _sum?: Maybe<InvoiceSumAggregateOutputType>;
  _min?: Maybe<InvoiceMinAggregateOutputType>;
  _max?: Maybe<InvoiceMaxAggregateOutputType>;
};

export type AggregateInvoiceLineItem = {
  __typename?: 'AggregateInvoiceLineItem';
  _count?: Maybe<InvoiceLineItemCountAggregateOutputType>;
  _avg?: Maybe<InvoiceLineItemAvgAggregateOutputType>;
  _sum?: Maybe<InvoiceLineItemSumAggregateOutputType>;
  _min?: Maybe<InvoiceLineItemMinAggregateOutputType>;
  _max?: Maybe<InvoiceLineItemMaxAggregateOutputType>;
};

export type AggregateInvoicePimsWritebackLogEntry = {
  __typename?: 'AggregateInvoicePimsWritebackLogEntry';
  _count?: Maybe<InvoicePimsWritebackLogEntryCountAggregateOutputType>;
  _min?: Maybe<InvoicePimsWritebackLogEntryMinAggregateOutputType>;
  _max?: Maybe<InvoicePimsWritebackLogEntryMaxAggregateOutputType>;
};

export type AggregateLapsedPetParentTriggers = {
  __typename?: 'AggregateLapsedPetParentTriggers';
  _count?: Maybe<LapsedPetParentTriggersCountAggregateOutputType>;
  _min?: Maybe<LapsedPetParentTriggersMinAggregateOutputType>;
  _max?: Maybe<LapsedPetParentTriggersMaxAggregateOutputType>;
};

export type AggregateLegalEntity = {
  __typename?: 'AggregateLegalEntity';
  _count?: Maybe<LegalEntityCountAggregateOutputType>;
  _min?: Maybe<LegalEntityMinAggregateOutputType>;
  _max?: Maybe<LegalEntityMaxAggregateOutputType>;
};

export type AggregateLoyaltyAccount = {
  __typename?: 'AggregateLoyaltyAccount';
  _count?: Maybe<LoyaltyAccountCountAggregateOutputType>;
  _avg?: Maybe<LoyaltyAccountAvgAggregateOutputType>;
  _sum?: Maybe<LoyaltyAccountSumAggregateOutputType>;
  _min?: Maybe<LoyaltyAccountMinAggregateOutputType>;
  _max?: Maybe<LoyaltyAccountMaxAggregateOutputType>;
};

export type AggregateLoyaltyAccountHolder = {
  __typename?: 'AggregateLoyaltyAccountHolder';
  _count?: Maybe<LoyaltyAccountHolderCountAggregateOutputType>;
  _min?: Maybe<LoyaltyAccountHolderMinAggregateOutputType>;
  _max?: Maybe<LoyaltyAccountHolderMaxAggregateOutputType>;
};

export type AggregateLoyaltyAccountMerger = {
  __typename?: 'AggregateLoyaltyAccountMerger';
  _count?: Maybe<LoyaltyAccountMergerCountAggregateOutputType>;
  _min?: Maybe<LoyaltyAccountMergerMinAggregateOutputType>;
  _max?: Maybe<LoyaltyAccountMergerMaxAggregateOutputType>;
};

export type AggregateLoyaltyInvoicePointGranter = {
  __typename?: 'AggregateLoyaltyInvoicePointGranter';
  _count?: Maybe<LoyaltyInvoicePointGranterCountAggregateOutputType>;
  _min?: Maybe<LoyaltyInvoicePointGranterMinAggregateOutputType>;
  _max?: Maybe<LoyaltyInvoicePointGranterMaxAggregateOutputType>;
};

export type AggregateLoyaltyPointDelta = {
  __typename?: 'AggregateLoyaltyPointDelta';
  _count?: Maybe<LoyaltyPointDeltaCountAggregateOutputType>;
  _avg?: Maybe<LoyaltyPointDeltaAvgAggregateOutputType>;
  _sum?: Maybe<LoyaltyPointDeltaSumAggregateOutputType>;
  _min?: Maybe<LoyaltyPointDeltaMinAggregateOutputType>;
  _max?: Maybe<LoyaltyPointDeltaMaxAggregateOutputType>;
};

export type AggregateLoyaltyPointGrantingService = {
  __typename?: 'AggregateLoyaltyPointGrantingService';
  _count?: Maybe<LoyaltyPointGrantingServiceCountAggregateOutputType>;
  _min?: Maybe<LoyaltyPointGrantingServiceMinAggregateOutputType>;
  _max?: Maybe<LoyaltyPointGrantingServiceMaxAggregateOutputType>;
};

export type AggregateLoyaltyPointGrantingServiceCategory = {
  __typename?: 'AggregateLoyaltyPointGrantingServiceCategory';
  _count?: Maybe<LoyaltyPointGrantingServiceCategoryCountAggregateOutputType>;
  _min?: Maybe<LoyaltyPointGrantingServiceCategoryMinAggregateOutputType>;
  _max?: Maybe<LoyaltyPointGrantingServiceCategoryMaxAggregateOutputType>;
};

export type AggregateLoyaltyPointUserGrant = {
  __typename?: 'AggregateLoyaltyPointUserGrant';
  _count?: Maybe<LoyaltyPointUserGrantCountAggregateOutputType>;
  _avg?: Maybe<LoyaltyPointUserGrantAvgAggregateOutputType>;
  _sum?: Maybe<LoyaltyPointUserGrantSumAggregateOutputType>;
  _min?: Maybe<LoyaltyPointUserGrantMinAggregateOutputType>;
  _max?: Maybe<LoyaltyPointUserGrantMaxAggregateOutputType>;
};

export type AggregateLoyaltyProgram = {
  __typename?: 'AggregateLoyaltyProgram';
  _count?: Maybe<LoyaltyProgramCountAggregateOutputType>;
  _avg?: Maybe<LoyaltyProgramAvgAggregateOutputType>;
  _sum?: Maybe<LoyaltyProgramSumAggregateOutputType>;
  _min?: Maybe<LoyaltyProgramMinAggregateOutputType>;
  _max?: Maybe<LoyaltyProgramMaxAggregateOutputType>;
};

export type AggregateLoyaltyProgramStatusHistory = {
  __typename?: 'AggregateLoyaltyProgramStatusHistory';
  _count?: Maybe<LoyaltyProgramStatusHistoryCountAggregateOutputType>;
  _min?: Maybe<LoyaltyProgramStatusHistoryMinAggregateOutputType>;
  _max?: Maybe<LoyaltyProgramStatusHistoryMaxAggregateOutputType>;
};

export type AggregateLoyaltyReward = {
  __typename?: 'AggregateLoyaltyReward';
  _count?: Maybe<LoyaltyRewardCountAggregateOutputType>;
  _avg?: Maybe<LoyaltyRewardAvgAggregateOutputType>;
  _sum?: Maybe<LoyaltyRewardSumAggregateOutputType>;
  _min?: Maybe<LoyaltyRewardMinAggregateOutputType>;
  _max?: Maybe<LoyaltyRewardMaxAggregateOutputType>;
};

export type AggregateLoyaltyRewardRedemption = {
  __typename?: 'AggregateLoyaltyRewardRedemption';
  _count?: Maybe<LoyaltyRewardRedemptionCountAggregateOutputType>;
  _min?: Maybe<LoyaltyRewardRedemptionMinAggregateOutputType>;
  _max?: Maybe<LoyaltyRewardRedemptionMaxAggregateOutputType>;
};

export type AggregateLoyaltyRewardRedemptionHistoryEntry = {
  __typename?: 'AggregateLoyaltyRewardRedemptionHistoryEntry';
  _count?: Maybe<LoyaltyRewardRedemptionHistoryEntryCountAggregateOutputType>;
  _min?: Maybe<LoyaltyRewardRedemptionHistoryEntryMinAggregateOutputType>;
  _max?: Maybe<LoyaltyRewardRedemptionHistoryEntryMaxAggregateOutputType>;
};

export type AggregateMassTextAlert = {
  __typename?: 'AggregateMassTextAlert';
  _count?: Maybe<MassTextAlertCountAggregateOutputType>;
  _min?: Maybe<MassTextAlertMinAggregateOutputType>;
  _max?: Maybe<MassTextAlertMaxAggregateOutputType>;
};

export type AggregateMassTextAlertEntry = {
  __typename?: 'AggregateMassTextAlertEntry';
  _count?: Maybe<MassTextAlertEntryCountAggregateOutputType>;
  _min?: Maybe<MassTextAlertEntryMinAggregateOutputType>;
  _max?: Maybe<MassTextAlertEntryMaxAggregateOutputType>;
};

export type AggregateMassTextAlertEntryAppointment = {
  __typename?: 'AggregateMassTextAlertEntryAppointment';
  _count?: Maybe<MassTextAlertEntryAppointmentCountAggregateOutputType>;
  _min?: Maybe<MassTextAlertEntryAppointmentMinAggregateOutputType>;
  _max?: Maybe<MassTextAlertEntryAppointmentMaxAggregateOutputType>;
};

export type AggregateMedia = {
  __typename?: 'AggregateMedia';
  _count?: Maybe<MediaCountAggregateOutputType>;
  _avg?: Maybe<MediaAvgAggregateOutputType>;
  _sum?: Maybe<MediaSumAggregateOutputType>;
  _min?: Maybe<MediaMinAggregateOutputType>;
  _max?: Maybe<MediaMaxAggregateOutputType>;
};

export type AggregateMessageTemplate = {
  __typename?: 'AggregateMessageTemplate';
  _count?: Maybe<MessageTemplateCountAggregateOutputType>;
  _min?: Maybe<MessageTemplateMinAggregateOutputType>;
  _max?: Maybe<MessageTemplateMaxAggregateOutputType>;
};

export type AggregateMessagingCampaign = {
  __typename?: 'AggregateMessagingCampaign';
  _count?: Maybe<MessagingCampaignCountAggregateOutputType>;
  _min?: Maybe<MessagingCampaignMinAggregateOutputType>;
  _max?: Maybe<MessagingCampaignMaxAggregateOutputType>;
};

export type AggregateMessagingPartner = {
  __typename?: 'AggregateMessagingPartner';
  _count?: Maybe<MessagingPartnerCountAggregateOutputType>;
  _min?: Maybe<MessagingPartnerMinAggregateOutputType>;
  _max?: Maybe<MessagingPartnerMaxAggregateOutputType>;
};

export type AggregateNeoIntegration = {
  __typename?: 'AggregateNeoIntegration';
  _count?: Maybe<NeoIntegrationCountAggregateOutputType>;
  _min?: Maybe<NeoIntegrationMinAggregateOutputType>;
  _max?: Maybe<NeoIntegrationMaxAggregateOutputType>;
};

export type AggregateNonPrismaSchemaScriptRan = {
  __typename?: 'AggregateNonPrismaSchemaScriptRan';
  _count?: Maybe<NonPrismaSchemaScriptRanCountAggregateOutputType>;
  _min?: Maybe<NonPrismaSchemaScriptRanMinAggregateOutputType>;
  _max?: Maybe<NonPrismaSchemaScriptRanMaxAggregateOutputType>;
};

export type AggregateNotificationEventToMessage = {
  __typename?: 'AggregateNotificationEventToMessage';
  _count?: Maybe<NotificationEventToMessageCountAggregateOutputType>;
  _min?: Maybe<NotificationEventToMessageMinAggregateOutputType>;
  _max?: Maybe<NotificationEventToMessageMaxAggregateOutputType>;
};

export type AggregateOrganization = {
  __typename?: 'AggregateOrganization';
  _count?: Maybe<OrganizationCountAggregateOutputType>;
  _min?: Maybe<OrganizationMinAggregateOutputType>;
  _max?: Maybe<OrganizationMaxAggregateOutputType>;
};

export type AggregateOrganizationCareBenefitToClinicService = {
  __typename?: 'AggregateOrganizationCareBenefitToClinicService';
  _count?: Maybe<OrganizationCareBenefitToClinicServiceCountAggregateOutputType>;
  _min?: Maybe<OrganizationCareBenefitToClinicServiceMinAggregateOutputType>;
  _max?: Maybe<OrganizationCareBenefitToClinicServiceMaxAggregateOutputType>;
};

export type AggregateOrganizationCareBenefitToClinicServiceCategory = {
  __typename?: 'AggregateOrganizationCareBenefitToClinicServiceCategory';
  _count?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCountAggregateOutputType>;
  _min?: Maybe<OrganizationCareBenefitToClinicServiceCategoryMinAggregateOutputType>;
  _max?: Maybe<OrganizationCareBenefitToClinicServiceCategoryMaxAggregateOutputType>;
};

export type AggregateOrganizationPet = {
  __typename?: 'AggregateOrganizationPet';
  _count?: Maybe<OrganizationPetCountAggregateOutputType>;
  _min?: Maybe<OrganizationPetMinAggregateOutputType>;
  _max?: Maybe<OrganizationPetMaxAggregateOutputType>;
};

export type AggregateOrganizationPetParent = {
  __typename?: 'AggregateOrganizationPetParent';
  _count?: Maybe<OrganizationPetParentCountAggregateOutputType>;
  _min?: Maybe<OrganizationPetParentMinAggregateOutputType>;
  _max?: Maybe<OrganizationPetParentMaxAggregateOutputType>;
};

export type AggregateOrganizationPetToOrganizationPetParent = {
  __typename?: 'AggregateOrganizationPetToOrganizationPetParent';
  _count?: Maybe<OrganizationPetToOrganizationPetParentCountAggregateOutputType>;
  _min?: Maybe<OrganizationPetToOrganizationPetParentMinAggregateOutputType>;
  _max?: Maybe<OrganizationPetToOrganizationPetParentMaxAggregateOutputType>;
};

export type AggregateOwnerInfo = {
  __typename?: 'AggregateOwnerInfo';
  _count?: Maybe<OwnerInfoCountAggregateOutputType>;
  _min?: Maybe<OwnerInfoMinAggregateOutputType>;
  _max?: Maybe<OwnerInfoMaxAggregateOutputType>;
};

export type AggregatePpcIntegration = {
  __typename?: 'AggregatePPCIntegration';
  _count?: Maybe<PpcIntegrationCountAggregateOutputType>;
  _min?: Maybe<PpcIntegrationMinAggregateOutputType>;
  _max?: Maybe<PpcIntegrationMaxAggregateOutputType>;
};

export type AggregatePaymentPimsWritebackLogEntry = {
  __typename?: 'AggregatePaymentPimsWritebackLogEntry';
  _count?: Maybe<PaymentPimsWritebackLogEntryCountAggregateOutputType>;
  _min?: Maybe<PaymentPimsWritebackLogEntryMinAggregateOutputType>;
  _max?: Maybe<PaymentPimsWritebackLogEntryMaxAggregateOutputType>;
};

export type AggregatePayoutBatchingPeriod = {
  __typename?: 'AggregatePayoutBatchingPeriod';
  _count?: Maybe<PayoutBatchingPeriodCountAggregateOutputType>;
  _min?: Maybe<PayoutBatchingPeriodMinAggregateOutputType>;
  _max?: Maybe<PayoutBatchingPeriodMaxAggregateOutputType>;
};

export type AggregatePermission = {
  __typename?: 'AggregatePermission';
  _count?: Maybe<PermissionCountAggregateOutputType>;
  _min?: Maybe<PermissionMinAggregateOutputType>;
  _max?: Maybe<PermissionMaxAggregateOutputType>;
};

export type AggregatePet = {
  __typename?: 'AggregatePet';
  _count?: Maybe<PetCountAggregateOutputType>;
  _avg?: Maybe<PetAvgAggregateOutputType>;
  _sum?: Maybe<PetSumAggregateOutputType>;
  _min?: Maybe<PetMinAggregateOutputType>;
  _max?: Maybe<PetMaxAggregateOutputType>;
};

export type AggregatePetParentSetting = {
  __typename?: 'AggregatePetParentSetting';
  _count?: Maybe<PetParentSettingCountAggregateOutputType>;
  _min?: Maybe<PetParentSettingMinAggregateOutputType>;
  _max?: Maybe<PetParentSettingMaxAggregateOutputType>;
};

export type AggregatePetPortalSetting = {
  __typename?: 'AggregatePetPortalSetting';
  _count?: Maybe<PetPortalSettingCountAggregateOutputType>;
  _min?: Maybe<PetPortalSettingMinAggregateOutputType>;
  _max?: Maybe<PetPortalSettingMaxAggregateOutputType>;
};

export type AggregatePhoneNumberLookupRequest = {
  __typename?: 'AggregatePhoneNumberLookupRequest';
  _count?: Maybe<PhoneNumberLookupRequestCountAggregateOutputType>;
  _min?: Maybe<PhoneNumberLookupRequestMinAggregateOutputType>;
  _max?: Maybe<PhoneNumberLookupRequestMaxAggregateOutputType>;
};

export type AggregatePhoneNumberLookupResult = {
  __typename?: 'AggregatePhoneNumberLookupResult';
  _count?: Maybe<PhoneNumberLookupResultCountAggregateOutputType>;
  _avg?: Maybe<PhoneNumberLookupResultAvgAggregateOutputType>;
  _sum?: Maybe<PhoneNumberLookupResultSumAggregateOutputType>;
  _min?: Maybe<PhoneNumberLookupResultMinAggregateOutputType>;
  _max?: Maybe<PhoneNumberLookupResultMaxAggregateOutputType>;
};

export type AggregatePimsInvoice = {
  __typename?: 'AggregatePimsInvoice';
  _count?: Maybe<PimsInvoiceCountAggregateOutputType>;
  _min?: Maybe<PimsInvoiceMinAggregateOutputType>;
  _max?: Maybe<PimsInvoiceMaxAggregateOutputType>;
};

export type AggregatePimsInvoiceLineItem = {
  __typename?: 'AggregatePimsInvoiceLineItem';
  _count?: Maybe<PimsInvoiceLineItemCountAggregateOutputType>;
  _min?: Maybe<PimsInvoiceLineItemMinAggregateOutputType>;
  _max?: Maybe<PimsInvoiceLineItemMaxAggregateOutputType>;
};

export type AggregatePimsPaymentType = {
  __typename?: 'AggregatePimsPaymentType';
  _count?: Maybe<PimsPaymentTypeCountAggregateOutputType>;
  _min?: Maybe<PimsPaymentTypeMinAggregateOutputType>;
  _max?: Maybe<PimsPaymentTypeMaxAggregateOutputType>;
};

export type AggregatePimsWritebackLogEntry = {
  __typename?: 'AggregatePimsWritebackLogEntry';
  _count?: Maybe<PimsWritebackLogEntryCountAggregateOutputType>;
  _avg?: Maybe<PimsWritebackLogEntryAvgAggregateOutputType>;
  _sum?: Maybe<PimsWritebackLogEntrySumAggregateOutputType>;
  _min?: Maybe<PimsWritebackLogEntryMinAggregateOutputType>;
  _max?: Maybe<PimsWritebackLogEntryMaxAggregateOutputType>;
};

export type AggregatePrescription = {
  __typename?: 'AggregatePrescription';
  _count?: Maybe<PrescriptionCountAggregateOutputType>;
  _avg?: Maybe<PrescriptionAvgAggregateOutputType>;
  _sum?: Maybe<PrescriptionSumAggregateOutputType>;
  _min?: Maybe<PrescriptionMinAggregateOutputType>;
  _max?: Maybe<PrescriptionMaxAggregateOutputType>;
};

export type AggregateProduct = {
  __typename?: 'AggregateProduct';
  _count?: Maybe<ProductCountAggregateOutputType>;
  _avg?: Maybe<ProductAvgAggregateOutputType>;
  _sum?: Maybe<ProductSumAggregateOutputType>;
  _min?: Maybe<ProductMinAggregateOutputType>;
  _max?: Maybe<ProductMaxAggregateOutputType>;
};

export type AggregatePromoCode = {
  __typename?: 'AggregatePromoCode';
  _count?: Maybe<PromoCodeCountAggregateOutputType>;
  _avg?: Maybe<PromoCodeAvgAggregateOutputType>;
  _sum?: Maybe<PromoCodeSumAggregateOutputType>;
  _min?: Maybe<PromoCodeMinAggregateOutputType>;
  _max?: Maybe<PromoCodeMaxAggregateOutputType>;
};

export type AggregateProviderAppointmentTypeRoomMap = {
  __typename?: 'AggregateProviderAppointmentTypeRoomMap';
  _count?: Maybe<ProviderAppointmentTypeRoomMapCountAggregateOutputType>;
  _min?: Maybe<ProviderAppointmentTypeRoomMapMinAggregateOutputType>;
  _max?: Maybe<ProviderAppointmentTypeRoomMapMaxAggregateOutputType>;
};

export type AggregatePushNotificationLog = {
  __typename?: 'AggregatePushNotificationLog';
  _count?: Maybe<PushNotificationLogCountAggregateOutputType>;
  _min?: Maybe<PushNotificationLogMinAggregateOutputType>;
  _max?: Maybe<PushNotificationLogMaxAggregateOutputType>;
};

export type AggregateReview = {
  __typename?: 'AggregateReview';
  _count?: Maybe<ReviewCountAggregateOutputType>;
  _avg?: Maybe<ReviewAvgAggregateOutputType>;
  _sum?: Maybe<ReviewSumAggregateOutputType>;
  _min?: Maybe<ReviewMinAggregateOutputType>;
  _max?: Maybe<ReviewMaxAggregateOutputType>;
};

export type AggregateReviewTag = {
  __typename?: 'AggregateReviewTag';
  _count?: Maybe<ReviewTagCountAggregateOutputType>;
  _min?: Maybe<ReviewTagMinAggregateOutputType>;
  _max?: Maybe<ReviewTagMaxAggregateOutputType>;
};

export type AggregateRules = {
  __typename?: 'AggregateRules';
  _count?: Maybe<RulesCountAggregateOutputType>;
  _min?: Maybe<RulesMinAggregateOutputType>;
  _max?: Maybe<RulesMaxAggregateOutputType>;
};

export type AggregateSegment = {
  __typename?: 'AggregateSegment';
  _count?: Maybe<SegmentCountAggregateOutputType>;
  _avg?: Maybe<SegmentAvgAggregateOutputType>;
  _sum?: Maybe<SegmentSumAggregateOutputType>;
  _min?: Maybe<SegmentMinAggregateOutputType>;
  _max?: Maybe<SegmentMaxAggregateOutputType>;
};

export type AggregateSentTrupanionExamDayOffer = {
  __typename?: 'AggregateSentTrupanionExamDayOffer';
  _count?: Maybe<SentTrupanionExamDayOfferCountAggregateOutputType>;
  _min?: Maybe<SentTrupanionExamDayOfferMinAggregateOutputType>;
  _max?: Maybe<SentTrupanionExamDayOfferMaxAggregateOutputType>;
};

export type AggregateService = {
  __typename?: 'AggregateService';
  _count?: Maybe<ServiceCountAggregateOutputType>;
  _min?: Maybe<ServiceMinAggregateOutputType>;
  _max?: Maybe<ServiceMaxAggregateOutputType>;
};

export type AggregateServiceCategory = {
  __typename?: 'AggregateServiceCategory';
  _count?: Maybe<ServiceCategoryCountAggregateOutputType>;
  _min?: Maybe<ServiceCategoryMinAggregateOutputType>;
  _max?: Maybe<ServiceCategoryMaxAggregateOutputType>;
};

export type AggregateServiceReminder = {
  __typename?: 'AggregateServiceReminder';
  _count?: Maybe<ServiceReminderCountAggregateOutputType>;
  _min?: Maybe<ServiceReminderMinAggregateOutputType>;
  _max?: Maybe<ServiceReminderMaxAggregateOutputType>;
};

export type AggregateServiceReminderNotification = {
  __typename?: 'AggregateServiceReminderNotification';
  _count?: Maybe<ServiceReminderNotificationCountAggregateOutputType>;
  _min?: Maybe<ServiceReminderNotificationMinAggregateOutputType>;
  _max?: Maybe<ServiceReminderNotificationMaxAggregateOutputType>;
};

export type AggregateServiceReminderTiming = {
  __typename?: 'AggregateServiceReminderTiming';
  _count?: Maybe<ServiceReminderTimingCountAggregateOutputType>;
  _avg?: Maybe<ServiceReminderTimingAvgAggregateOutputType>;
  _sum?: Maybe<ServiceReminderTimingSumAggregateOutputType>;
  _min?: Maybe<ServiceReminderTimingMinAggregateOutputType>;
  _max?: Maybe<ServiceReminderTimingMaxAggregateOutputType>;
};

export type AggregateSex = {
  __typename?: 'AggregateSex';
  _count?: Maybe<SexCountAggregateOutputType>;
  _min?: Maybe<SexMinAggregateOutputType>;
  _max?: Maybe<SexMaxAggregateOutputType>;
};

export type AggregateSmsNotificationStatus = {
  __typename?: 'AggregateSmsNotificationStatus';
  _count?: Maybe<SmsNotificationStatusCountAggregateOutputType>;
  _min?: Maybe<SmsNotificationStatusMinAggregateOutputType>;
  _max?: Maybe<SmsNotificationStatusMaxAggregateOutputType>;
};

export type AggregateSpecies = {
  __typename?: 'AggregateSpecies';
  _count?: Maybe<SpeciesCountAggregateOutputType>;
  _min?: Maybe<SpeciesMinAggregateOutputType>;
  _max?: Maybe<SpeciesMaxAggregateOutputType>;
};

export type AggregateStaffRole = {
  __typename?: 'AggregateStaffRole';
  _count?: Maybe<StaffRoleCountAggregateOutputType>;
  _min?: Maybe<StaffRoleMinAggregateOutputType>;
  _max?: Maybe<StaffRoleMaxAggregateOutputType>;
};

export type AggregateStripeCustomer = {
  __typename?: 'AggregateStripeCustomer';
  _count?: Maybe<StripeCustomerCountAggregateOutputType>;
  _min?: Maybe<StripeCustomerMinAggregateOutputType>;
  _max?: Maybe<StripeCustomerMaxAggregateOutputType>;
};

export type AggregateStripeDispute = {
  __typename?: 'AggregateStripeDispute';
  _count?: Maybe<StripeDisputeCountAggregateOutputType>;
  _avg?: Maybe<StripeDisputeAvgAggregateOutputType>;
  _sum?: Maybe<StripeDisputeSumAggregateOutputType>;
  _min?: Maybe<StripeDisputeMinAggregateOutputType>;
  _max?: Maybe<StripeDisputeMaxAggregateOutputType>;
};

export type AggregateStripeInvoice = {
  __typename?: 'AggregateStripeInvoice';
  _count?: Maybe<StripeInvoiceCountAggregateOutputType>;
  _avg?: Maybe<StripeInvoiceAvgAggregateOutputType>;
  _sum?: Maybe<StripeInvoiceSumAggregateOutputType>;
  _min?: Maybe<StripeInvoiceMinAggregateOutputType>;
  _max?: Maybe<StripeInvoiceMaxAggregateOutputType>;
};

export type AggregateStripeInvoiceItem = {
  __typename?: 'AggregateStripeInvoiceItem';
  _count?: Maybe<StripeInvoiceItemCountAggregateOutputType>;
  _avg?: Maybe<StripeInvoiceItemAvgAggregateOutputType>;
  _sum?: Maybe<StripeInvoiceItemSumAggregateOutputType>;
  _min?: Maybe<StripeInvoiceItemMinAggregateOutputType>;
  _max?: Maybe<StripeInvoiceItemMaxAggregateOutputType>;
};

export type AggregateStripePaymentIntent = {
  __typename?: 'AggregateStripePaymentIntent';
  _count?: Maybe<StripePaymentIntentCountAggregateOutputType>;
  _avg?: Maybe<StripePaymentIntentAvgAggregateOutputType>;
  _sum?: Maybe<StripePaymentIntentSumAggregateOutputType>;
  _min?: Maybe<StripePaymentIntentMinAggregateOutputType>;
  _max?: Maybe<StripePaymentIntentMaxAggregateOutputType>;
};

export type AggregateStripePaymentMethod = {
  __typename?: 'AggregateStripePaymentMethod';
  _count?: Maybe<StripePaymentMethodCountAggregateOutputType>;
  _avg?: Maybe<StripePaymentMethodAvgAggregateOutputType>;
  _sum?: Maybe<StripePaymentMethodSumAggregateOutputType>;
  _min?: Maybe<StripePaymentMethodMinAggregateOutputType>;
  _max?: Maybe<StripePaymentMethodMaxAggregateOutputType>;
};

export type AggregateStripePayout = {
  __typename?: 'AggregateStripePayout';
  _count?: Maybe<StripePayoutCountAggregateOutputType>;
  _avg?: Maybe<StripePayoutAvgAggregateOutputType>;
  _sum?: Maybe<StripePayoutSumAggregateOutputType>;
  _min?: Maybe<StripePayoutMinAggregateOutputType>;
  _max?: Maybe<StripePayoutMaxAggregateOutputType>;
};

export type AggregateStripePayoutAccount = {
  __typename?: 'AggregateStripePayoutAccount';
  _count?: Maybe<StripePayoutAccountCountAggregateOutputType>;
  _min?: Maybe<StripePayoutAccountMinAggregateOutputType>;
  _max?: Maybe<StripePayoutAccountMaxAggregateOutputType>;
};

export type AggregateStripeReceipt = {
  __typename?: 'AggregateStripeReceipt';
  _count?: Maybe<StripeReceiptCountAggregateOutputType>;
  _min?: Maybe<StripeReceiptMinAggregateOutputType>;
  _max?: Maybe<StripeReceiptMaxAggregateOutputType>;
};

export type AggregateStripeReceiptHistory = {
  __typename?: 'AggregateStripeReceiptHistory';
  _count?: Maybe<StripeReceiptHistoryCountAggregateOutputType>;
  _min?: Maybe<StripeReceiptHistoryMinAggregateOutputType>;
  _max?: Maybe<StripeReceiptHistoryMaxAggregateOutputType>;
};

export type AggregateStripeRefund = {
  __typename?: 'AggregateStripeRefund';
  _count?: Maybe<StripeRefundCountAggregateOutputType>;
  _avg?: Maybe<StripeRefundAvgAggregateOutputType>;
  _sum?: Maybe<StripeRefundSumAggregateOutputType>;
  _min?: Maybe<StripeRefundMinAggregateOutputType>;
  _max?: Maybe<StripeRefundMaxAggregateOutputType>;
};

export type AggregateStripeTerminal = {
  __typename?: 'AggregateStripeTerminal';
  _count?: Maybe<StripeTerminalCountAggregateOutputType>;
  _min?: Maybe<StripeTerminalMinAggregateOutputType>;
  _max?: Maybe<StripeTerminalMaxAggregateOutputType>;
};

export type AggregateStripeTerminalHealthcheck = {
  __typename?: 'AggregateStripeTerminalHealthcheck';
  _count?: Maybe<StripeTerminalHealthcheckCountAggregateOutputType>;
  _min?: Maybe<StripeTerminalHealthcheckMinAggregateOutputType>;
  _max?: Maybe<StripeTerminalHealthcheckMaxAggregateOutputType>;
};

export type AggregateStripeTerminalLocation = {
  __typename?: 'AggregateStripeTerminalLocation';
  _count?: Maybe<StripeTerminalLocationCountAggregateOutputType>;
  _min?: Maybe<StripeTerminalLocationMinAggregateOutputType>;
  _max?: Maybe<StripeTerminalLocationMaxAggregateOutputType>;
};

export type AggregateSurveyAppointmentTypeSetting = {
  __typename?: 'AggregateSurveyAppointmentTypeSetting';
  _count?: Maybe<SurveyAppointmentTypeSettingCountAggregateOutputType>;
  _avg?: Maybe<SurveyAppointmentTypeSettingAvgAggregateOutputType>;
  _sum?: Maybe<SurveyAppointmentTypeSettingSumAggregateOutputType>;
  _min?: Maybe<SurveyAppointmentTypeSettingMinAggregateOutputType>;
  _max?: Maybe<SurveyAppointmentTypeSettingMaxAggregateOutputType>;
};

export type AggregateSurveyResult = {
  __typename?: 'AggregateSurveyResult';
  _count?: Maybe<SurveyResultCountAggregateOutputType>;
  _avg?: Maybe<SurveyResultAvgAggregateOutputType>;
  _sum?: Maybe<SurveyResultSumAggregateOutputType>;
  _min?: Maybe<SurveyResultMinAggregateOutputType>;
  _max?: Maybe<SurveyResultMaxAggregateOutputType>;
};

export type AggregateSurveySetting = {
  __typename?: 'AggregateSurveySetting';
  _count?: Maybe<SurveySettingCountAggregateOutputType>;
  _avg?: Maybe<SurveySettingAvgAggregateOutputType>;
  _sum?: Maybe<SurveySettingSumAggregateOutputType>;
  _min?: Maybe<SurveySettingMinAggregateOutputType>;
  _max?: Maybe<SurveySettingMaxAggregateOutputType>;
};

export type AggregateSyncVetIntegration = {
  __typename?: 'AggregateSyncVetIntegration';
  _count?: Maybe<SyncVetIntegrationCountAggregateOutputType>;
  _min?: Maybe<SyncVetIntegrationMinAggregateOutputType>;
  _max?: Maybe<SyncVetIntegrationMaxAggregateOutputType>;
};

export type AggregateTinyUrl = {
  __typename?: 'AggregateTinyUrl';
  _count?: Maybe<TinyUrlCountAggregateOutputType>;
  _min?: Maybe<TinyUrlMinAggregateOutputType>;
  _max?: Maybe<TinyUrlMaxAggregateOutputType>;
};

export type AggregateTransaction = {
  __typename?: 'AggregateTransaction';
  _count?: Maybe<TransactionCountAggregateOutputType>;
  _avg?: Maybe<TransactionAvgAggregateOutputType>;
  _sum?: Maybe<TransactionSumAggregateOutputType>;
  _min?: Maybe<TransactionMinAggregateOutputType>;
  _max?: Maybe<TransactionMaxAggregateOutputType>;
};

export type AggregateTrupanionCertificateAvailability = {
  __typename?: 'AggregateTrupanionCertificateAvailability';
  _count?: Maybe<TrupanionCertificateAvailabilityCountAggregateOutputType>;
  _avg?: Maybe<TrupanionCertificateAvailabilityAvgAggregateOutputType>;
  _sum?: Maybe<TrupanionCertificateAvailabilitySumAggregateOutputType>;
  _min?: Maybe<TrupanionCertificateAvailabilityMinAggregateOutputType>;
  _max?: Maybe<TrupanionCertificateAvailabilityMaxAggregateOutputType>;
};

export type AggregateTrupanionIntegratedAppointmentType = {
  __typename?: 'AggregateTrupanionIntegratedAppointmentType';
  _count?: Maybe<TrupanionIntegratedAppointmentTypeCountAggregateOutputType>;
  _min?: Maybe<TrupanionIntegratedAppointmentTypeMinAggregateOutputType>;
  _max?: Maybe<TrupanionIntegratedAppointmentTypeMaxAggregateOutputType>;
};

export type AggregateTrupanionIntegration = {
  __typename?: 'AggregateTrupanionIntegration';
  _count?: Maybe<TrupanionIntegrationCountAggregateOutputType>;
  _min?: Maybe<TrupanionIntegrationMinAggregateOutputType>;
  _max?: Maybe<TrupanionIntegrationMaxAggregateOutputType>;
};

export type AggregateTwilioCallRecording = {
  __typename?: 'AggregateTwilioCallRecording';
  _count?: Maybe<TwilioCallRecordingCountAggregateOutputType>;
  _avg?: Maybe<TwilioCallRecordingAvgAggregateOutputType>;
  _sum?: Maybe<TwilioCallRecordingSumAggregateOutputType>;
  _min?: Maybe<TwilioCallRecordingMinAggregateOutputType>;
  _max?: Maybe<TwilioCallRecordingMaxAggregateOutputType>;
};

export type AggregateTwilioConfiguration = {
  __typename?: 'AggregateTwilioConfiguration';
  _count?: Maybe<TwilioConfigurationCountAggregateOutputType>;
  _min?: Maybe<TwilioConfigurationMinAggregateOutputType>;
  _max?: Maybe<TwilioConfigurationMaxAggregateOutputType>;
};

export type AggregateTwilioVerification = {
  __typename?: 'AggregateTwilioVerification';
  _count?: Maybe<TwilioVerificationCountAggregateOutputType>;
  _min?: Maybe<TwilioVerificationMinAggregateOutputType>;
  _max?: Maybe<TwilioVerificationMaxAggregateOutputType>;
};

export type AggregateUnlayerSetting = {
  __typename?: 'AggregateUnlayerSetting';
  _count?: Maybe<UnlayerSettingCountAggregateOutputType>;
  _avg?: Maybe<UnlayerSettingAvgAggregateOutputType>;
  _sum?: Maybe<UnlayerSettingSumAggregateOutputType>;
  _min?: Maybe<UnlayerSettingMinAggregateOutputType>;
  _max?: Maybe<UnlayerSettingMaxAggregateOutputType>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _count?: Maybe<UserCountAggregateOutputType>;
  _min?: Maybe<UserMinAggregateOutputType>;
  _max?: Maybe<UserMaxAggregateOutputType>;
};

export type AggregateUserAppointmentGrouping = {
  __typename?: 'AggregateUserAppointmentGrouping';
  _count?: Maybe<UserAppointmentGroupingCountAggregateOutputType>;
  _min?: Maybe<UserAppointmentGroupingMinAggregateOutputType>;
  _max?: Maybe<UserAppointmentGroupingMaxAggregateOutputType>;
};

export type AggregateUserBoardFilter = {
  __typename?: 'AggregateUserBoardFilter';
  _count?: Maybe<UserBoardFilterCountAggregateOutputType>;
  _min?: Maybe<UserBoardFilterMinAggregateOutputType>;
  _max?: Maybe<UserBoardFilterMaxAggregateOutputType>;
};

export type AggregateUserChannelFilterSelection = {
  __typename?: 'AggregateUserChannelFilterSelection';
  _count?: Maybe<UserChannelFilterSelectionCountAggregateOutputType>;
  _min?: Maybe<UserChannelFilterSelectionMinAggregateOutputType>;
  _max?: Maybe<UserChannelFilterSelectionMaxAggregateOutputType>;
};

export type AggregateUserImpersonation = {
  __typename?: 'AggregateUserImpersonation';
  _count?: Maybe<UserImpersonationCountAggregateOutputType>;
  _min?: Maybe<UserImpersonationMinAggregateOutputType>;
  _max?: Maybe<UserImpersonationMaxAggregateOutputType>;
};

export type AggregateUserNotificationSetting = {
  __typename?: 'AggregateUserNotificationSetting';
  _count?: Maybe<UserNotificationSettingCountAggregateOutputType>;
  _min?: Maybe<UserNotificationSettingMinAggregateOutputType>;
  _max?: Maybe<UserNotificationSettingMaxAggregateOutputType>;
};

export type AggregateUserSetting = {
  __typename?: 'AggregateUserSetting';
  _count?: Maybe<UserSettingCountAggregateOutputType>;
  _min?: Maybe<UserSettingMinAggregateOutputType>;
  _max?: Maybe<UserSettingMaxAggregateOutputType>;
};

export type AggregateVaccination = {
  __typename?: 'AggregateVaccination';
  _count?: Maybe<VaccinationCountAggregateOutputType>;
  _min?: Maybe<VaccinationMinAggregateOutputType>;
  _max?: Maybe<VaccinationMaxAggregateOutputType>;
};

export type AggregateVetAvailability = {
  __typename?: 'AggregateVetAvailability';
  _count?: Maybe<VetAvailabilityCountAggregateOutputType>;
  _min?: Maybe<VetAvailabilityMinAggregateOutputType>;
  _max?: Maybe<VetAvailabilityMaxAggregateOutputType>;
};

export type AggregateVetAvailabilityClinicRoomMap = {
  __typename?: 'AggregateVetAvailabilityClinicRoomMap';
  _count?: Maybe<VetAvailabilityClinicRoomMapCountAggregateOutputType>;
  _min?: Maybe<VetAvailabilityClinicRoomMapMinAggregateOutputType>;
  _max?: Maybe<VetAvailabilityClinicRoomMapMaxAggregateOutputType>;
};

export type AggregateVetAvailabilityMap = {
  __typename?: 'AggregateVetAvailabilityMap';
  _count?: Maybe<VetAvailabilityMapCountAggregateOutputType>;
  _min?: Maybe<VetAvailabilityMapMinAggregateOutputType>;
  _max?: Maybe<VetAvailabilityMapMaxAggregateOutputType>;
};

export type AggregateVetDataIntegration = {
  __typename?: 'AggregateVetDataIntegration';
  _count?: Maybe<VetDataIntegrationCountAggregateOutputType>;
  _min?: Maybe<VetDataIntegrationMinAggregateOutputType>;
  _max?: Maybe<VetDataIntegrationMaxAggregateOutputType>;
};

export type AggregateVetInfo = {
  __typename?: 'AggregateVetInfo';
  _count?: Maybe<VetInfoCountAggregateOutputType>;
  _min?: Maybe<VetInfoMinAggregateOutputType>;
  _max?: Maybe<VetInfoMaxAggregateOutputType>;
};

export type AggregateVetLicense = {
  __typename?: 'AggregateVetLicense';
  _count?: Maybe<VetLicenseCountAggregateOutputType>;
  _min?: Maybe<VetLicenseMinAggregateOutputType>;
  _max?: Maybe<VetLicenseMaxAggregateOutputType>;
};

export type AggregateVetsourceCodeTag = {
  __typename?: 'AggregateVetsourceCodeTag';
  _count?: Maybe<VetsourceCodeTagCountAggregateOutputType>;
  _avg?: Maybe<VetsourceCodeTagAvgAggregateOutputType>;
  _sum?: Maybe<VetsourceCodeTagSumAggregateOutputType>;
  _min?: Maybe<VetsourceCodeTagMinAggregateOutputType>;
  _max?: Maybe<VetsourceCodeTagMaxAggregateOutputType>;
};

export type AggregateVetsourceInstallation = {
  __typename?: 'AggregateVetsourceInstallation';
  _count?: Maybe<VetsourceInstallationCountAggregateOutputType>;
  _min?: Maybe<VetsourceInstallationMinAggregateOutputType>;
  _max?: Maybe<VetsourceInstallationMaxAggregateOutputType>;
};

export type AggregateVetsourceRevenueCategory = {
  __typename?: 'AggregateVetsourceRevenueCategory';
  _count?: Maybe<VetsourceRevenueCategoryCountAggregateOutputType>;
  _avg?: Maybe<VetsourceRevenueCategoryAvgAggregateOutputType>;
  _sum?: Maybe<VetsourceRevenueCategorySumAggregateOutputType>;
  _min?: Maybe<VetsourceRevenueCategoryMinAggregateOutputType>;
  _max?: Maybe<VetsourceRevenueCategoryMaxAggregateOutputType>;
};

export type AggregateVetterIntegration = {
  __typename?: 'AggregateVetterIntegration';
  _count?: Maybe<VetterIntegrationCountAggregateOutputType>;
  _min?: Maybe<VetterIntegrationMinAggregateOutputType>;
  _max?: Maybe<VetterIntegrationMaxAggregateOutputType>;
};

export type AggregateWorkflowEventAction = {
  __typename?: 'AggregateWorkflowEventAction';
  _count?: Maybe<WorkflowEventActionCountAggregateOutputType>;
  _avg?: Maybe<WorkflowEventActionAvgAggregateOutputType>;
  _sum?: Maybe<WorkflowEventActionSumAggregateOutputType>;
  _min?: Maybe<WorkflowEventActionMinAggregateOutputType>;
  _max?: Maybe<WorkflowEventActionMaxAggregateOutputType>;
};

export type AggregateWorkflowEventSetting = {
  __typename?: 'AggregateWorkflowEventSetting';
  _count?: Maybe<WorkflowEventSettingCountAggregateOutputType>;
  _avg?: Maybe<WorkflowEventSettingAvgAggregateOutputType>;
  _sum?: Maybe<WorkflowEventSettingSumAggregateOutputType>;
  _min?: Maybe<WorkflowEventSettingMinAggregateOutputType>;
  _max?: Maybe<WorkflowEventSettingMaxAggregateOutputType>;
};

export type AggregateWorkflowTrigger = {
  __typename?: 'AggregateWorkflowTrigger';
  _count?: Maybe<WorkflowTriggerCountAggregateOutputType>;
  _min?: Maybe<WorkflowTriggerMinAggregateOutputType>;
  _max?: Maybe<WorkflowTriggerMaxAggregateOutputType>;
};

export enum AiConverseRole {
  User = 'user',
  Assistant = 'assistant'
}

export enum AmericanKennelClubBreedGroup {
  FoundationStockService = 'FOUNDATION_STOCK_SERVICE',
  HerdingGroup = 'HERDING_GROUP',
  HoundGroup = 'HOUND_GROUP',
  MiscellaneousClass = 'MISCELLANEOUS_CLASS',
  NonSportingGroup = 'NON_SPORTING_GROUP',
  SportingGroup = 'SPORTING_GROUP',
  TerrierGroup = 'TERRIER_GROUP',
  WorkingGroup = 'WORKING_GROUP'
}

export enum AmericanKennelClubBreedTemperament {
  Active = 'ACTIVE',
  Adaptable = 'ADAPTABLE',
  Adventurous = 'ADVENTUROUS',
  Affectionate = 'AFFECTIONATE',
  Agile = 'AGILE',
  Alert = 'ALERT',
  AlertAndIntelligent = 'ALERT_AND_INTELLIGENT',
  Amiable = 'AMIABLE',
  Amusing = 'AMUSING',
  Aristocratic = 'ARISTOCRATIC',
  Athletic = 'ATHLETIC',
  Attentive = 'ATTENTIVE',
  Boisterous = 'BOISTEROUS',
  Bold = 'BOLD',
  Bouncy = 'BOUNCY',
  Brave = 'BRAVE',
  Bright = 'BRIGHT',
  Busy = 'BUSY',
  Calm = 'CALM',
  Canny = 'CANNY',
  Charismatic = 'CHARISMATIC',
  Charming = 'CHARMING',
  Cheerful = 'CHEERFUL',
  Clever = 'CLEVER',
  Comical = 'COMICAL',
  Confident = 'CONFIDENT',
  ConfidentGuardian = 'CONFIDENT_GUARDIAN',
  Courageous = 'COURAGEOUS',
  Courteous = 'COURTEOUS',
  Curious = 'CURIOUS',
  Dashing = 'DASHING',
  DeeplyAffectionate = 'DEEPLY_AFFECTIONATE',
  DeeplyDevoted = 'DEEPLY_DEVOTED',
  Dependable = 'DEPENDABLE',
  Determined = 'DETERMINED',
  Devoted = 'DEVOTED',
  Dignified = 'DIGNIFIED',
  Docile = 'DOCILE',
  Eager = 'EAGER',
  EagerToPlease = 'EAGER_TO_PLEASE',
  EasyGoing = 'EASY_GOING',
  Energetic = 'ENERGETIC',
  Entertaining = 'ENTERTAINING',
  Enthusiastic = 'ENTHUSIASTIC',
  EvenTempered = 'EVEN_TEMPERED',
  Exuberant = 'EXUBERANT',
  Faithful = 'FAITHFUL',
  FamilyOriented = 'FAMILY_ORIENTED',
  FamouslyFunny = 'FAMOUSLY_FUNNY',
  Fearless = 'FEARLESS',
  Friendly = 'FRIENDLY',
  Frollicking = 'FROLLICKING',
  Funny = 'FUNNY',
  FunLoving = 'FUN_LOVING',
  Gentle = 'GENTLE',
  Gentlemanly = 'GENTLEMANLY',
  GoodHumored = 'GOOD_HUMORED',
  GoodNatured = 'GOOD_NATURED',
  GoodTempered = 'GOOD_TEMPERED',
  Graceful = 'GRACEFUL',
  Gregarious = 'GREGARIOUS',
  Happy = 'HAPPY',
  HappyGoLucky = 'HAPPY_GO_LUCKY',
  Hardworking = 'HARDWORKING',
  HomeLoving = 'HOME_LOVING',
  Humble = 'HUMBLE',
  Independent = 'INDEPENDENT',
  IndependentMinded = 'INDEPENDENT_MINDED',
  Inquisitive = 'INQUISITIVE',
  Intelligent = 'INTELLIGENT',
  Keen = 'KEEN',
  KeenlyAlert = 'KEENLY_ALERT',
  KeenlyObservant = 'KEENLY_OBSERVANT',
  Kind = 'KIND',
  Lively = 'LIVELY',
  Lovable = 'LOVABLE',
  Loving = 'LOVING',
  LowKey = 'LOW_KEY',
  Loyal = 'LOYAL',
  Majestic = 'MAJESTIC',
  Mellow = 'MELLOW',
  Merry = 'MERRY',
  Mischievous = 'MISCHIEVOUS',
  Noble = 'NOBLE',
  Obedient = 'OBEDIENT',
  Observant = 'OBSERVANT',
  Optimistic = 'OPTIMISTIC',
  Outgoing = 'OUTGOING',
  Patient = 'PATIENT',
  PeopleOriented = 'PEOPLE_ORIENTED',
  Peppy = 'PEPPY',
  Perceptive = 'PERCEPTIVE',
  Perky = 'PERKY',
  Playful = 'PLAYFUL',
  PlayfulButAlsoWorkOrientedVeryActiveAndUpbeat = 'PLAYFUL_BUT_ALSO_WORK_ORIENTED_VERY_ACTIVE_AND_UPBEAT',
  Pleasant = 'PLEASANT',
  Plucky = 'PLUCKY',
  Poised = 'POISED',
  Polite = 'POLITE',
  Positive = 'POSITIVE',
  Powerful = 'POWERFUL',
  ProfoundlyLoyal = 'PROFOUNDLY_LOYAL',
  Proud = 'PROUD',
  Quick = 'QUICK',
  ReadyToWork = 'READY_TO_WORK',
  Regal = 'REGAL',
  RegallyDignified = 'REGALLY_DIGNIFIED',
  RegalInManner = 'REGAL_IN_MANNER',
  Reserved = 'RESERVED',
  ReservedWithStrangers = 'RESERVED_WITH_STRANGERS',
  Responsive = 'RESPONSIVE',
  Sassy = 'SASSY',
  SelfConfident = 'SELF_CONFIDENT',
  SenseOfHumor = 'SENSE_OF_HUMOR',
  Sensitive = 'SENSITIVE',
  SeriousMinded = 'SERIOUS_MINDED',
  Smart = 'SMART',
  Sociable = 'SOCIABLE',
  Spirited = 'SPIRITED',
  Sprightly = 'SPRIGHTLY',
  Spunky = 'SPUNKY',
  Strong = 'STRONG',
  StrongWilled = 'STRONG_WILLED',
  Sweet = 'SWEET',
  SweetNatured = 'SWEET_NATURED',
  SweetTempered = 'SWEET_TEMPERED',
  Tenacious = 'TENACIOUS',
  Tenderhearted = 'TENDERHEARTED',
  Tomboyish = 'TOMBOYISH',
  Trainable = 'TRAINABLE',
  Undemanding = 'UNDEMANDING',
  Upbeat = 'UPBEAT',
  Versatile = 'VERSATILE',
  VerySmart = 'VERY_SMART',
  Vigilant = 'VIGILANT',
  Vivacious = 'VIVACIOUS',
  Watchful = 'WATCHFUL',
  WickedlySmart = 'WICKEDLY_SMART',
  WillingToPlease = 'WILLING_TO_PLEASE',
  WorkOriented = 'WORK_ORIENTED'
}

export enum AmericanKennelClubDemeanorCategory {
  AlertResponsive = 'ALERT_RESPONSIVE',
  AloofWary = 'ALOOF_WARY',
  Friendly = 'FRIENDLY',
  Outgoing = 'OUTGOING',
  ReservedWithStrangers = 'RESERVED_WITH_STRANGERS'
}

export enum AmericanKennelClubEnergyLevelCategory {
  Calm = 'CALM',
  CouchPotato = 'COUCH_POTATO',
  Energetic = 'ENERGETIC',
  NeedsLotsOfActivity = 'NEEDS_LOTS_OF_ACTIVITY',
  RegularExercise = 'REGULAR_EXERCISE'
}

export enum AmericanKennelClubGroomingFrequencyCategory {
  TwoToThreeTimesPerWeekBrushing = 'TWO_TO_THREE_TIMES_PER_WEEK_BRUSHING',
  DailyBrushing = 'DAILY_BRUSHING',
  OccasionalBathBrush = 'OCCASIONAL_BATH_BRUSH',
  SpecialtyProfessional = 'SPECIALTY_PROFESSIONAL',
  WeeklyBrushing = 'WEEKLY_BRUSHING'
}

export enum AmericanKennelClubSheddingCategory {
  Frequent = 'FREQUENT',
  Infrequent = 'INFREQUENT',
  Occasional = 'OCCASIONAL',
  Regularly = 'REGULARLY',
  Seasonal = 'SEASONAL'
}

export enum AmericanKennelClubTrainabilityCategory {
  Agreeable = 'AGREEABLE',
  EagerToPlease = 'EAGER_TO_PLEASE',
  EasyTraining = 'EASY_TRAINING',
  Independent = 'INDEPENDENT',
  MayBeStubborn = 'MAY_BE_STUBBORN'
}

export type ApiKey = {
  __typename?: 'ApiKey';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyCountAggregateOutputType = {
  __typename?: 'ApiKeyCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  name: Scalars['Int'];
  integrationType: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ApiKeyCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
};

export type ApiKeyCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyMaxAggregateOutputType = {
  __typename?: 'ApiKeyMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
};

export type ApiKeyMinAggregateOutputType = {
  __typename?: 'ApiKeyMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
};

export enum ApiKeyOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name'
}

export type ApiKeyOrderByRelevanceInput = {
  fields: Array<ApiKeyOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ApiKeyOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  _count?: Maybe<ApiKeyCountOrderByAggregateInput>;
  _max?: Maybe<ApiKeyMaxOrderByAggregateInput>;
  _min?: Maybe<ApiKeyMinOrderByAggregateInput>;
};

export type ApiKeyOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  _relevance?: Maybe<ApiKeyOrderByRelevanceInput>;
};

export enum ApiKeyScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Name = 'name',
  IntegrationType = 'integrationType'
}

export type ApiKeyScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ApiKeyScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ApiKeyScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ApiKeyScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  integrationType?: Maybe<EnumIntegrationTypeNullableWithAggregatesFilter>;
};

export type ApiKeyUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyWhereInput = {
  AND?: Maybe<Array<ApiKeyWhereInput>>;
  OR?: Maybe<Array<ApiKeyWhereInput>>;
  NOT?: Maybe<Array<ApiKeyWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  integrationType?: Maybe<EnumIntegrationTypeNullableFilter>;
};

export type ApiKeyWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status: AppointmentStatus;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  /** Replacement for consultation_id for Conversation Exports */
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentType>;
  channel?: Maybe<Channel>;
  clinic?: Maybe<Clinic>;
  clinicPet?: Maybe<ClinicPet>;
  room?: Maybe<ClinicRoom>;
  /** Deprecated: use pimsExportConsultationId for Conversation Exports */
  consultation?: Maybe<Consultation>;
  integration?: Maybe<ClinicPimsIntegration>;
  formSubmissions: Array<FormSubmission>;
  clinicEmployees: Array<ClinicEmployee>;
  clinicPetParents: Array<ClinicPetParent>;
  workflows: Array<WorkflowEventSetting>;
  surveyResults: Array<SurveyResult>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequest>;
  workflowTriggers: Array<WorkflowTrigger>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  automationRuns: Array<AutomationRun>;
  massTextAlertEntryAppointments: Array<MassTextAlertEntryAppointment>;
  pushNotificationLogs: Array<PushNotificationLog>;
  _count: AppointmentCountOutputType;
};


export type AppointmentFormSubmissionsArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
};


export type AppointmentClinicEmployeesArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeScalarFieldEnum>;
};


export type AppointmentClinicPetParentsArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};


export type AppointmentWorkflowsArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
};


export type AppointmentSurveyResultsArgs = {
  where?: Maybe<SurveyResultWhereInput>;
  orderBy?: Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SurveyResultScalarFieldEnum>;
};


export type AppointmentWorkflowTriggersArgs = {
  where?: Maybe<WorkflowTriggerWhereInput>;
  orderBy?: Maybe<WorkflowTriggerOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowTriggerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowTriggerScalarFieldEnum>;
};


export type AppointmentChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};


export type AppointmentAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};


export type AppointmentMassTextAlertEntryAppointmentsArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  orderBy?: Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>;
};


export type AppointmentPushNotificationLogsArgs = {
  where?: Maybe<PushNotificationLogWhereInput>;
  orderBy?: Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PushNotificationLogScalarFieldEnum>;
};

export type AppointmentAppointmentPimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type AppointmentAvailability = {
  __typename?: 'AppointmentAvailability';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities: Array<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<Clinic>;
  clinicEmployee?: Maybe<ClinicEmployee>;
  room?: Maybe<ClinicRoom>;
  integration?: Maybe<ClinicPimsIntegration>;
};

export type AppointmentAvailabilityAvailabilityPimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type AppointmentAvailabilityCountAggregateOutputType = {
  __typename?: 'AppointmentAvailabilityCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  pimsId: Scalars['Int'];
  isProvider: Scalars['Int'];
  availabilities: Scalars['Int'];
  isDeleted: Scalars['Int'];
  clinicEmployeeId: Scalars['Int'];
  clinicId: Scalars['Int'];
  roomId: Scalars['Int'];
  integrationId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type AppointmentAvailabilityCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isProvider?: Maybe<SortOrder>;
  availabilities?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type AppointmentAvailabilityCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  clinicEmployee?: Maybe<ClinicEmployeeCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentAvailabilityInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAvailabilitiesInput>;
};

export type AppointmentAvailabilityCreateManyClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityCreateManyClinicEmployeeInputEnvelope = {
  data: Array<AppointmentAvailabilityCreateManyClinicEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentAvailabilityCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityCreateManyClinicInputEnvelope = {
  data: Array<AppointmentAvailabilityCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentAvailabilityCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityCreateManyIntegrationInputEnvelope = {
  data: Array<AppointmentAvailabilityCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentAvailabilityCreateManyRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityCreateManyRoomInputEnvelope = {
  data: Array<AppointmentAvailabilityCreateManyRoomInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
};

export type AppointmentAvailabilityCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
};

export type AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
};

export type AppointmentAvailabilityCreateNestedManyWithoutRoomInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutRoomInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyRoomInputEnvelope>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
};

export type AppointmentAvailabilityCreateOrConnectWithoutClinicEmployeeInput = {
  where: AppointmentAvailabilityWhereUniqueInput;
  create: AppointmentAvailabilityUncheckedCreateWithoutClinicEmployeeInput;
};

export type AppointmentAvailabilityCreateOrConnectWithoutClinicInput = {
  where: AppointmentAvailabilityWhereUniqueInput;
  create: AppointmentAvailabilityUncheckedCreateWithoutClinicInput;
};

export type AppointmentAvailabilityCreateOrConnectWithoutIntegrationInput = {
  where: AppointmentAvailabilityWhereUniqueInput;
  create: AppointmentAvailabilityUncheckedCreateWithoutIntegrationInput;
};

export type AppointmentAvailabilityCreateOrConnectWithoutRoomInput = {
  where: AppointmentAvailabilityWhereUniqueInput;
  create: AppointmentAvailabilityUncheckedCreateWithoutRoomInput;
};

export type AppointmentAvailabilityCreateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentAvailabilityInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAvailabilitiesInput>;
};

export type AppointmentAvailabilityCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicEmployee?: Maybe<ClinicEmployeeCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentAvailabilityInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAvailabilitiesInput>;
};

export type AppointmentAvailabilityCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  clinicEmployee?: Maybe<ClinicEmployeeCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentAvailabilityInput>;
};

export type AppointmentAvailabilityCreateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  clinicEmployee?: Maybe<ClinicEmployeeCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAvailabilitiesInput>;
};

export type AppointmentAvailabilityCreateavailabilitiesInput = {
  set: Array<Scalars['DateTime']>;
};

export type AppointmentAvailabilityListRelationFilter = {
  every?: Maybe<AppointmentAvailabilityWhereInput>;
  some?: Maybe<AppointmentAvailabilityWhereInput>;
  none?: Maybe<AppointmentAvailabilityWhereInput>;
};

export type AppointmentAvailabilityMaxAggregateOutputType = {
  __typename?: 'AppointmentAvailabilityMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
};

export type AppointmentAvailabilityMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isProvider?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
};

export type AppointmentAvailabilityMinAggregateOutputType = {
  __typename?: 'AppointmentAvailabilityMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
};

export type AppointmentAvailabilityMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isProvider?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
};

export type AppointmentAvailabilityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AppointmentAvailabilityOrderByRelevanceFieldEnum {
  Id = 'id',
  PimsId = 'pimsId',
  ClinicEmployeeId = 'clinicEmployeeId',
  ClinicId = 'clinicId',
  RoomId = 'roomId',
  IntegrationId = 'integrationId'
}

export type AppointmentAvailabilityOrderByRelevanceInput = {
  fields: Array<AppointmentAvailabilityOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type AppointmentAvailabilityOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isProvider?: Maybe<SortOrder>;
  availabilities?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<AppointmentAvailabilityCountOrderByAggregateInput>;
  _max?: Maybe<AppointmentAvailabilityMaxOrderByAggregateInput>;
  _min?: Maybe<AppointmentAvailabilityMinOrderByAggregateInput>;
};

export type AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isProvider?: Maybe<SortOrder>;
  availabilities?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployee?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  room?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<AppointmentAvailabilityOrderByRelevanceInput>;
};

export enum AppointmentAvailabilityScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PimsId = 'pimsId',
  IsProvider = 'isProvider',
  Availabilities = 'availabilities',
  IsDeleted = 'isDeleted',
  ClinicEmployeeId = 'clinicEmployeeId',
  ClinicId = 'clinicId',
  RoomId = 'roomId',
  IntegrationId = 'integrationId',
  RawPimsValue = 'rawPimsValue'
}

export type AppointmentAvailabilityScalarWhereInput = {
  AND?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
  OR?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
  NOT?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  isProvider?: Maybe<BoolNullableFilter>;
  availabilities?: Maybe<DateTimeNullableListFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinicEmployeeId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  roomId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type AppointmentAvailabilityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AppointmentAvailabilityScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AppointmentAvailabilityScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AppointmentAvailabilityScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  isProvider?: Maybe<BoolNullableWithAggregatesFilter>;
  availabilities?: Maybe<DateTimeNullableListFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  clinicEmployeeId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  roomId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type AppointmentAvailabilityUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
};

export type AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
};

export type AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
};

export type AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutRoomInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyRoomInputEnvelope>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
};

export type AppointmentAvailabilityUncheckedCreateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUncheckedCreateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUncheckedUpdateManyWithoutAppointmentAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUncheckedUpdateManyWithoutAppointmentAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUncheckedUpdateManyWithoutAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
};

export type AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
};

export type AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
};

export type AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutRoomInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutRoomInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyRoomInputEnvelope>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutRoomInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutRoomInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
};

export type AppointmentAvailabilityUncheckedUpdateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUncheckedUpdateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentAvailabilityNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAvailabilitiesNestedInput>;
};

export type AppointmentAvailabilityUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentAvailabilityUpdateManyWithWhereWithoutClinicEmployeeInput = {
  where: AppointmentAvailabilityScalarWhereInput;
  data: AppointmentAvailabilityUncheckedUpdateManyWithoutAppointmentAvailabilitiesInput;
};

export type AppointmentAvailabilityUpdateManyWithWhereWithoutClinicInput = {
  where: AppointmentAvailabilityScalarWhereInput;
  data: AppointmentAvailabilityUncheckedUpdateManyWithoutAppointmentAvailabilitiesInput;
};

export type AppointmentAvailabilityUpdateManyWithWhereWithoutIntegrationInput = {
  where: AppointmentAvailabilityScalarWhereInput;
  data: AppointmentAvailabilityUncheckedUpdateManyWithoutAvailabilitiesInput;
};

export type AppointmentAvailabilityUpdateManyWithWhereWithoutRoomInput = {
  where: AppointmentAvailabilityScalarWhereInput;
  data: AppointmentAvailabilityUncheckedUpdateManyWithoutAppointmentAvailabilityInput;
};

export type AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
};

export type AppointmentAvailabilityUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
};

export type AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
};

export type AppointmentAvailabilityUpdateManyWithoutRoomNestedInput = {
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutRoomInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutRoomInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyRoomInputEnvelope>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutRoomInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutRoomInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
};

export type AppointmentAvailabilityUpdateWithWhereUniqueWithoutClinicEmployeeInput = {
  where: AppointmentAvailabilityWhereUniqueInput;
  data: AppointmentAvailabilityUncheckedUpdateWithoutClinicEmployeeInput;
};

export type AppointmentAvailabilityUpdateWithWhereUniqueWithoutClinicInput = {
  where: AppointmentAvailabilityWhereUniqueInput;
  data: AppointmentAvailabilityUncheckedUpdateWithoutClinicInput;
};

export type AppointmentAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: AppointmentAvailabilityWhereUniqueInput;
  data: AppointmentAvailabilityUncheckedUpdateWithoutIntegrationInput;
};

export type AppointmentAvailabilityUpdateWithWhereUniqueWithoutRoomInput = {
  where: AppointmentAvailabilityWhereUniqueInput;
  data: AppointmentAvailabilityUncheckedUpdateWithoutRoomInput;
};

export type AppointmentAvailabilityUpdateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentAvailabilityNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAvailabilitiesNestedInput>;
};

export type AppointmentAvailabilityUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentAvailabilityNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAvailabilitiesNestedInput>;
};

export type AppointmentAvailabilityUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentAvailabilityNestedInput>;
};

export type AppointmentAvailabilityUpdateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAvailabilitiesNestedInput>;
};

export type AppointmentAvailabilityUpdateavailabilitiesInput = {
  set?: Maybe<Array<Scalars['DateTime']>>;
  push?: Maybe<Array<Scalars['DateTime']>>;
};

export type AppointmentAvailabilityUpsertWithWhereUniqueWithoutClinicEmployeeInput = {
  where: AppointmentAvailabilityWhereUniqueInput;
  update: AppointmentAvailabilityUncheckedUpdateWithoutClinicEmployeeInput;
  create: AppointmentAvailabilityUncheckedCreateWithoutClinicEmployeeInput;
};

export type AppointmentAvailabilityUpsertWithWhereUniqueWithoutClinicInput = {
  where: AppointmentAvailabilityWhereUniqueInput;
  update: AppointmentAvailabilityUncheckedUpdateWithoutClinicInput;
  create: AppointmentAvailabilityUncheckedCreateWithoutClinicInput;
};

export type AppointmentAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: AppointmentAvailabilityWhereUniqueInput;
  update: AppointmentAvailabilityUncheckedUpdateWithoutIntegrationInput;
  create: AppointmentAvailabilityUncheckedCreateWithoutIntegrationInput;
};

export type AppointmentAvailabilityUpsertWithWhereUniqueWithoutRoomInput = {
  where: AppointmentAvailabilityWhereUniqueInput;
  update: AppointmentAvailabilityUncheckedUpdateWithoutRoomInput;
  create: AppointmentAvailabilityUncheckedCreateWithoutRoomInput;
};

export type AppointmentAvailabilityWhereInput = {
  AND?: Maybe<Array<AppointmentAvailabilityWhereInput>>;
  OR?: Maybe<Array<AppointmentAvailabilityWhereInput>>;
  NOT?: Maybe<Array<AppointmentAvailabilityWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  isProvider?: Maybe<BoolNullableFilter>;
  availabilities?: Maybe<DateTimeNullableListFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinicEmployeeId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  roomId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicEmployee?: Maybe<ClinicEmployeeWhereInput>;
  room?: Maybe<ClinicRoomWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type AppointmentAvailabilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  availabilityPimsIdAndIntegrationId?: Maybe<AppointmentAvailabilityAvailabilityPimsIdAndIntegrationIdCompoundUniqueInput>;
};

export type AppointmentAvgAggregateOutputType = {
  __typename?: 'AppointmentAvgAggregateOutputType';
  durationInMinutes?: Maybe<Scalars['Float']>;
};

export type AppointmentAvgOrderByAggregateInput = {
  durationInMinutes?: Maybe<SortOrder>;
};

export type AppointmentCountAggregateOutputType = {
  __typename?: 'AppointmentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  description: Scalars['Int'];
  durationInMinutes: Scalars['Int'];
  pimsId: Scalars['Int'];
  startAt: Scalars['Int'];
  consultationId: Scalars['Int'];
  integrationId: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  title: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  clinicId: Scalars['Int'];
  roomId: Scalars['Int'];
  status: Scalars['Int'];
  pimsStatus: Scalars['Int'];
  workflow: Scalars['Int'];
  channelId: Scalars['Int'];
  hash: Scalars['Int'];
  pimsExportConsultationId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type AppointmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  durationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  pimsExportConsultationId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type AppointmentCountOutputType = {
  __typename?: 'AppointmentCountOutputType';
  formSubmissions: Scalars['Int'];
  clinicEmployees: Scalars['Int'];
  clinicPetParents: Scalars['Int'];
  workflows: Scalars['Int'];
  surveyResults: Scalars['Int'];
  workflowTriggers: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  automationRuns: Scalars['Int'];
  massTextAlertEntryAppointments: Scalars['Int'];
  pushNotificationLogs: Scalars['Int'];
};

export type AppointmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateManyAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyAppointmentTypeInputEnvelope = {
  data: Array<AppointmentCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyChannelInputEnvelope = {
  data: Array<AppointmentCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyClinicInputEnvelope = {
  data: Array<AppointmentCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyClinicPetInputEnvelope = {
  data: Array<AppointmentCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyConsultationInputEnvelope = {
  data: Array<AppointmentCreateManyConsultationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyIntegrationInputEnvelope = {
  data: Array<AppointmentCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyRoomInputEnvelope = {
  data: Array<AppointmentCreateManyRoomInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<AppointmentCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<AppointmentCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<AppointmentCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<AppointmentCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutClinicEmployeesInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicEmployeesInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutClinicPetParentsInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetParentsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetParentsInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<AppointmentCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutConsultationInput>>;
  createMany?: Maybe<AppointmentCreateManyConsultationInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<AppointmentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutRoomInput = {
  create?: Maybe<Array<AppointmentCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutRoomInput>>;
  createMany?: Maybe<AppointmentCreateManyRoomInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutWorkflowsInput = {
  create?: Maybe<Array<AppointmentCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutWorkflowsInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedOneWithoutAutomationRunsInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutAutomationRunsInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
};

export type AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
};

export type AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutDirectBookingWidgetRequestInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutDirectBookingWidgetRequestInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
};

export type AppointmentCreateNestedOneWithoutFormSubmissionsInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutFormSubmissionsInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
};

export type AppointmentCreateNestedOneWithoutMassTextAlertEntryAppointmentsInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutMassTextAlertEntryAppointmentsInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
};

export type AppointmentCreateNestedOneWithoutPushNotificationLogsInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutPushNotificationLogsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutPushNotificationLogsInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
};

export type AppointmentCreateNestedOneWithoutSurveyResultsInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutSurveyResultsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutSurveyResultsInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
};

export type AppointmentCreateNestedOneWithoutWorkflowTriggersInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutWorkflowTriggersInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutWorkflowTriggersInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
};

export type AppointmentCreateOrConnectWithoutAppointmentTypeInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutAppointmentTypeInput;
};

export type AppointmentCreateOrConnectWithoutAutomationRunsInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutAutomationRunsInput;
};

export type AppointmentCreateOrConnectWithoutChannelAutomationStatusesInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type AppointmentCreateOrConnectWithoutChannelInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutChannelInput;
};

export type AppointmentCreateOrConnectWithoutClinicEmployeesInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutClinicEmployeesInput;
};

export type AppointmentCreateOrConnectWithoutClinicInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutClinicInput;
};

export type AppointmentCreateOrConnectWithoutClinicPetInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutClinicPetInput;
};

export type AppointmentCreateOrConnectWithoutClinicPetParentsInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutClinicPetParentsInput;
};

export type AppointmentCreateOrConnectWithoutConsultationInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutConsultationInput;
};

export type AppointmentCreateOrConnectWithoutDirectBookingWidgetRequestInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutDirectBookingWidgetRequestInput;
};

export type AppointmentCreateOrConnectWithoutFormSubmissionsInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutFormSubmissionsInput;
};

export type AppointmentCreateOrConnectWithoutIntegrationInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutIntegrationInput;
};

export type AppointmentCreateOrConnectWithoutMassTextAlertEntryAppointmentsInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput;
};

export type AppointmentCreateOrConnectWithoutPushNotificationLogsInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutPushNotificationLogsInput;
};

export type AppointmentCreateOrConnectWithoutRoomInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutRoomInput;
};

export type AppointmentCreateOrConnectWithoutSurveyResultsInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutSurveyResultsInput;
};

export type AppointmentCreateOrConnectWithoutWorkflowTriggersInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutWorkflowTriggersInput;
};

export type AppointmentCreateOrConnectWithoutWorkflowsInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutWorkflowsInput;
};

export type AppointmentCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutDirectBookingWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutMassTextAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
};

export enum AppointmentDateRangeType {
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  ThisWeek = 'This_Week',
  ThisMonth = 'This_Month',
  NextWeek = 'Next_Week',
  Custom = 'Custom'
}

export type AppointmentListRelationFilter = {
  every?: Maybe<AppointmentWhereInput>;
  some?: Maybe<AppointmentWhereInput>;
  none?: Maybe<AppointmentWhereInput>;
};

export type AppointmentMaxAggregateOutputType = {
  __typename?: 'AppointmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
};

export type AppointmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  durationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  pimsExportConsultationId?: Maybe<SortOrder>;
};

export type AppointmentMinAggregateOutputType = {
  __typename?: 'AppointmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
};

export type AppointmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  durationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  pimsExportConsultationId?: Maybe<SortOrder>;
};

export type AppointmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AppointmentOrderByRelevanceFieldEnum {
  Id = 'id',
  Description = 'description',
  PimsId = 'pimsId',
  ConsultationId = 'consultationId',
  IntegrationId = 'integrationId',
  AppointmentTypeId = 'appointmentTypeId',
  Title = 'title',
  ClinicPetId = 'clinicPetId',
  ClinicId = 'clinicId',
  RoomId = 'roomId',
  PimsStatus = 'pimsStatus',
  ChannelId = 'channelId',
  Hash = 'hash',
  PimsExportConsultationId = 'pimsExportConsultationId'
}

export type AppointmentOrderByRelevanceInput = {
  fields: Array<AppointmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type AppointmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  durationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  pimsExportConsultationId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<AppointmentCountOrderByAggregateInput>;
  _avg?: Maybe<AppointmentAvgOrderByAggregateInput>;
  _max?: Maybe<AppointmentMaxOrderByAggregateInput>;
  _min?: Maybe<AppointmentMinOrderByAggregateInput>;
  _sum?: Maybe<AppointmentSumOrderByAggregateInput>;
};

export type AppointmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  durationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  pimsExportConsultationId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  room?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  consultation?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  clinicEmployees?: Maybe<ClinicEmployeeOrderByRelationAggregateInput>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  workflows?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
  surveyResults?: Maybe<SurveyResultOrderByRelationAggregateInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  workflowTriggers?: Maybe<WorkflowTriggerOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentOrderByRelationAggregateInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogOrderByRelationAggregateInput>;
  _relevance?: Maybe<AppointmentOrderByRelevanceInput>;
};

export type AppointmentRelationFilter = {
  is?: Maybe<AppointmentWhereInput>;
  isNot?: Maybe<AppointmentWhereInput>;
};

export enum AppointmentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Description = 'description',
  DurationInMinutes = 'durationInMinutes',
  PimsId = 'pimsId',
  StartAt = 'startAt',
  ConsultationId = 'consultationId',
  IntegrationId = 'integrationId',
  AppointmentTypeId = 'appointmentTypeId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Title = 'title',
  ClinicPetId = 'clinicPetId',
  ClinicId = 'clinicId',
  RoomId = 'roomId',
  Status = 'status',
  PimsStatus = 'pimsStatus',
  Workflow = 'workflow',
  ChannelId = 'channelId',
  Hash = 'hash',
  PimsExportConsultationId = 'pimsExportConsultationId',
  RawPimsValue = 'rawPimsValue'
}

export type AppointmentScalarWhereInput = {
  AND?: Maybe<Array<AppointmentScalarWhereInput>>;
  OR?: Maybe<Array<AppointmentScalarWhereInput>>;
  NOT?: Maybe<Array<AppointmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  durationInMinutes?: Maybe<IntFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  startAt?: Maybe<DateTimeFilter>;
  consultationId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  appointmentTypeId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  title?: Maybe<StringNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  roomId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumAppointmentStatusFilter>;
  pimsStatus?: Maybe<StringNullableFilter>;
  workflow?: Maybe<EnumWorkflowTypeNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  hash?: Maybe<StringNullableFilter>;
  pimsExportConsultationId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type AppointmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AppointmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AppointmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AppointmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  durationInMinutes?: Maybe<IntWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  startAt?: Maybe<DateTimeWithAggregatesFilter>;
  consultationId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  appointmentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  title?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  roomId?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumAppointmentStatusWithAggregatesFilter>;
  pimsStatus?: Maybe<StringNullableWithAggregatesFilter>;
  workflow?: Maybe<EnumWorkflowTypeNullableWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
  hash?: Maybe<StringNullableWithAggregatesFilter>;
  pimsExportConsultationId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum AppointmentStatus {
  Pending = 'Pending',
  Confirmed = 'Confirmed',
  ArrivalConfirmed = 'ArrivalConfirmed',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Reschedule = 'Reschedule'
}

export type AppointmentSumAggregateOutputType = {
  __typename?: 'AppointmentSumAggregateOutputType';
  durationInMinutes?: Maybe<Scalars['Int']>;
};

export type AppointmentSumOrderByAggregateInput = {
  durationInMinutes?: Maybe<SortOrder>;
};

export type AppointmentType = {
  __typename?: 'AppointmentType';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking: Scalars['Boolean'];
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility: AppointmentTypeVisibility;
  isVisible: Scalars['Boolean'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal: Scalars['Boolean'];
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<Clinic>;
  integration?: Maybe<ClinicPimsIntegration>;
  appointments: Array<Appointment>;
  clinicWorkflowTypes: Array<ClinicWorkflowType>;
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  workflows: Array<WorkflowEventSetting>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSetting>;
  clinicEmployeeAppointmentTypeSettings: Array<ClinicEmployeeAppointmentTypeSetting>;
  surveyAppointmentTypeSettings: Array<SurveyAppointmentTypeSetting>;
  directBookingExclusionRules: Array<DirectBookingExclusionRule>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentType>;
  ProviderAppointmentTypeRoomMap: Array<ProviderAppointmentTypeRoomMap>;
  relatedTrupanionExamDayOffers: Array<SentTrupanionExamDayOffer>;
  _count: AppointmentTypeCountOutputType;
};


export type AppointmentTypeAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};


export type AppointmentTypeClinicWorkflowTypesArgs = {
  where?: Maybe<ClinicWorkflowTypeWhereInput>;
  orderBy?: Maybe<ClinicWorkflowTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWorkflowTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWorkflowTypeScalarFieldEnum>;
};


export type AppointmentTypeUserAppointmentGroupingsArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
};


export type AppointmentTypeWorkflowsArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
};


export type AppointmentTypeClinicEmployeeAppointmentTypeSettingsArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>;
};


export type AppointmentTypeSurveyAppointmentTypeSettingsArgs = {
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<SurveyAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SurveyAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SurveyAppointmentTypeSettingScalarFieldEnum>;
};


export type AppointmentTypeDirectBookingExclusionRulesArgs = {
  where?: Maybe<DirectBookingExclusionRuleWhereInput>;
  orderBy?: Maybe<DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<DirectBookingExclusionRuleScalarFieldEnum>;
};


export type AppointmentTypeProviderAppointmentTypeRoomMapArgs = {
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
  orderBy?: Maybe<ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ProviderAppointmentTypeRoomMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ProviderAppointmentTypeRoomMapScalarFieldEnum>;
};


export type AppointmentTypeRelatedTrupanionExamDayOffersArgs = {
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
  orderBy?: Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SentTrupanionExamDayOfferScalarFieldEnum>;
};

export type AppointmentTypeAppointmentTypePimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type AppointmentTypeAvgAggregateOutputType = {
  __typename?: 'AppointmentTypeAvgAggregateOutputType';
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Float']>;
  defaultDurationInMinutes?: Maybe<Scalars['Float']>;
  displayOrder?: Maybe<Scalars['Float']>;
  delayedStartTime?: Maybe<Scalars['Float']>;
};

export type AppointmentTypeAvgOrderByAggregateInput = {
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  delayedStartTime?: Maybe<SortOrder>;
};

export type AppointmentTypeClinicEmployee = {
  __typename?: 'AppointmentTypeClinicEmployee';
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type AppointmentTypeClinicEmployeeMapping = {
  __typename?: 'AppointmentTypeClinicEmployeeMapping';
  clinicEmployee: AppointmentTypeClinicEmployee;
};

export type AppointmentTypeCountAggregateOutputType = {
  __typename?: 'AppointmentTypeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  name: Scalars['Int'];
  displayName: Scalars['Int'];
  description: Scalars['Int'];
  isAvailableForDirectBooking: Scalars['Int'];
  defaultSoonestAvailableBookingInHours: Scalars['Int'];
  defaultDurationInMinutes: Scalars['Int'];
  pimsId: Scalars['Int'];
  integrationId: Scalars['Int'];
  clinicId: Scalars['Int'];
  appointmentTypeVisibility: Scalars['Int'];
  isVisible: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  showInPetPortal: Scalars['Int'];
  displayOrder: Scalars['Int'];
  delayedStartTime: Scalars['Int'];
  _all: Scalars['Int'];
};

export type AppointmentTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentTypeVisibility?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  showInPetPortal?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  delayedStartTime?: Maybe<SortOrder>;
};

export type AppointmentTypeCountOutputType = {
  __typename?: 'AppointmentTypeCountOutputType';
  appointments: Scalars['Int'];
  clinicWorkflowTypes: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  workflows: Scalars['Int'];
  clinicEmployeeAppointmentTypeSettings: Scalars['Int'];
  surveyAppointmentTypeSettings: Scalars['Int'];
  directBookingExclusionRules: Scalars['Int'];
  ProviderAppointmentTypeRoomMap: Scalars['Int'];
  relatedTrupanionExamDayOffers: Scalars['Int'];
};

export type AppointmentTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
};

export type AppointmentTypeCreateManyClinicInputEnvelope = {
  data: Array<AppointmentTypeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
};

export type AppointmentTypeCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
};

export type AppointmentTypeCreateManyIntegrationInputEnvelope = {
  data: Array<AppointmentTypeCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeCreateNestedManyWithoutDirectBookingExclusionRulesInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutDirectBookingExclusionRulesInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeCreateNestedManyWithoutWorkflowsInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutWorkflowsInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
};

export type AppointmentTypeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
};

export type AppointmentTypeCreateNestedOneWithoutClinicWorkflowTypesInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutClinicWorkflowTypesInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
};

export type AppointmentTypeCreateNestedOneWithoutDirectBookingAppointmentTypeSettingInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutDirectBookingAppointmentTypeSettingInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutDirectBookingAppointmentTypeSettingInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
};

export type AppointmentTypeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
};

export type AppointmentTypeCreateNestedOneWithoutRelatedTrupanionExamDayOffersInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutRelatedTrupanionExamDayOffersInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutRelatedTrupanionExamDayOffersInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
};

export type AppointmentTypeCreateNestedOneWithoutSurveyAppointmentTypeSettingsInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutSurveyAppointmentTypeSettingsInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutSurveyAppointmentTypeSettingsInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
};

export type AppointmentTypeCreateNestedOneWithoutTrupanionIntegratedAppointmentTypeInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutTrupanionIntegratedAppointmentTypeInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutTrupanionIntegratedAppointmentTypeInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
};

export type AppointmentTypeCreateOrConnectWithoutAppointmentsInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutAppointmentsInput;
};

export type AppointmentTypeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type AppointmentTypeCreateOrConnectWithoutClinicInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutClinicInput;
};

export type AppointmentTypeCreateOrConnectWithoutClinicWorkflowTypesInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput;
};

export type AppointmentTypeCreateOrConnectWithoutDirectBookingAppointmentTypeSettingInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutDirectBookingAppointmentTypeSettingInput;
};

export type AppointmentTypeCreateOrConnectWithoutDirectBookingExclusionRulesInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutDirectBookingExclusionRulesInput;
};

export type AppointmentTypeCreateOrConnectWithoutIntegrationInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutIntegrationInput;
};

export type AppointmentTypeCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput;
};

export type AppointmentTypeCreateOrConnectWithoutRelatedTrupanionExamDayOffersInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutRelatedTrupanionExamDayOffersInput;
};

export type AppointmentTypeCreateOrConnectWithoutSurveyAppointmentTypeSettingsInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutSurveyAppointmentTypeSettingsInput;
};

export type AppointmentTypeCreateOrConnectWithoutTrupanionIntegratedAppointmentTypeInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutTrupanionIntegratedAppointmentTypeInput;
};

export type AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type AppointmentTypeCreateOrConnectWithoutWorkflowsInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutWorkflowsInput;
};

export type AppointmentTypeCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeCreateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeCreateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeCreateWithoutDirectBookingAppointmentTypeSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeCreateWithoutDirectBookingExclusionRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeCreateWithoutProviderAppointmentTypeRoomMapInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeCreateWithoutRelatedTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeCreateWithoutSurveyAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeCreateWithoutTrupanionIntegratedAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeListRelationFilter = {
  every?: Maybe<AppointmentTypeWhereInput>;
  some?: Maybe<AppointmentTypeWhereInput>;
  none?: Maybe<AppointmentTypeWhereInput>;
};

export type AppointmentTypeMaxAggregateOutputType = {
  __typename?: 'AppointmentTypeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
};

export type AppointmentTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentTypeVisibility?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  showInPetPortal?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  delayedStartTime?: Maybe<SortOrder>;
};

export type AppointmentTypeMinAggregateOutputType = {
  __typename?: 'AppointmentTypeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
};

export type AppointmentTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentTypeVisibility?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  showInPetPortal?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  delayedStartTime?: Maybe<SortOrder>;
};

export type AppointmentTypeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AppointmentTypeOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  DisplayName = 'displayName',
  Description = 'description',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  ClinicId = 'clinicId'
}

export type AppointmentTypeOrderByRelevanceInput = {
  fields: Array<AppointmentTypeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type AppointmentTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentTypeVisibility?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  showInPetPortal?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  delayedStartTime?: Maybe<SortOrder>;
  _count?: Maybe<AppointmentTypeCountOrderByAggregateInput>;
  _avg?: Maybe<AppointmentTypeAvgOrderByAggregateInput>;
  _max?: Maybe<AppointmentTypeMaxOrderByAggregateInput>;
  _min?: Maybe<AppointmentTypeMinOrderByAggregateInput>;
  _sum?: Maybe<AppointmentTypeSumOrderByAggregateInput>;
};

export type AppointmentTypeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentTypeVisibility?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  showInPetPortal?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  delayedStartTime?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeOrderByRelationAggregateInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  workflows?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByRelationAggregateInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingOrderByRelationAggregateInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleOrderByRelationAggregateInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapOrderByRelationAggregateInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferOrderByRelationAggregateInput>;
  _relevance?: Maybe<AppointmentTypeOrderByRelevanceInput>;
};

export type AppointmentTypeRelationFilter = {
  is?: Maybe<AppointmentTypeWhereInput>;
  isNot?: Maybe<AppointmentTypeWhereInput>;
};

export enum AppointmentTypeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Name = 'name',
  DisplayName = 'displayName',
  Description = 'description',
  IsAvailableForDirectBooking = 'isAvailableForDirectBooking',
  DefaultSoonestAvailableBookingInHours = 'defaultSoonestAvailableBookingInHours',
  DefaultDurationInMinutes = 'defaultDurationInMinutes',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  ClinicId = 'clinicId',
  AppointmentTypeVisibility = 'appointmentTypeVisibility',
  IsVisible = 'isVisible',
  RawPimsValue = 'rawPimsValue',
  ShowInPetPortal = 'showInPetPortal',
  DisplayOrder = 'displayOrder',
  DelayedStartTime = 'delayedStartTime'
}

export type AppointmentTypeScalarWhereInput = {
  AND?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  OR?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  NOT?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  displayName?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  isAvailableForDirectBooking?: Maybe<BoolFilter>;
  defaultSoonestAvailableBookingInHours?: Maybe<IntNullableFilter>;
  defaultDurationInMinutes?: Maybe<IntFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  appointmentTypeVisibility?: Maybe<EnumAppointmentTypeVisibilityFilter>;
  isVisible?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  showInPetPortal?: Maybe<BoolFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  delayedStartTime?: Maybe<IntNullableFilter>;
};

export type AppointmentTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AppointmentTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AppointmentTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AppointmentTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  displayName?: Maybe<StringNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  isAvailableForDirectBooking?: Maybe<BoolWithAggregatesFilter>;
  defaultSoonestAvailableBookingInHours?: Maybe<IntNullableWithAggregatesFilter>;
  defaultDurationInMinutes?: Maybe<IntWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  appointmentTypeVisibility?: Maybe<EnumAppointmentTypeVisibilityWithAggregatesFilter>;
  isVisible?: Maybe<BoolWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  showInPetPortal?: Maybe<BoolWithAggregatesFilter>;
  displayOrder?: Maybe<IntNullableWithAggregatesFilter>;
  delayedStartTime?: Maybe<IntNullableWithAggregatesFilter>;
};

export type AppointmentTypeSumAggregateOutputType = {
  __typename?: 'AppointmentTypeSumAggregateOutputType';
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
};

export type AppointmentTypeSumOrderByAggregateInput = {
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  delayedStartTime?: Maybe<SortOrder>;
};

export type AppointmentTypeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutDirectBookingExclusionRulesInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutWorkflowsInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeUncheckedCreateWithoutDirectBookingAppointmentTypeSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeUncheckedCreateWithoutDirectBookingExclusionRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeUncheckedCreateWithoutRelatedTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeUncheckedCreateWithoutSurveyAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeUncheckedCreateWithoutTrupanionIntegratedAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeUncheckedCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
};

export type AppointmentTypeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutAppoinmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutDirectBookingExclusionRulesInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutDirectBookingExclusionRulesInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutWorkflowsInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutWorkflowsInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutDirectBookingAppointmentTypeSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutDirectBookingExclusionRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutRelatedTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutSurveyAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutTrupanionIntegratedAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
};

export type AppointmentTypeUpdateManyWithWhereWithoutClinicInput = {
  where: AppointmentTypeScalarWhereInput;
  data: AppointmentTypeUncheckedUpdateManyWithoutAppoinmentTypesInput;
};

export type AppointmentTypeUpdateManyWithWhereWithoutDirectBookingExclusionRulesInput = {
  where: AppointmentTypeScalarWhereInput;
  data: AppointmentTypeUncheckedUpdateManyWithoutAppointmentTypesInput;
};

export type AppointmentTypeUpdateManyWithWhereWithoutIntegrationInput = {
  where: AppointmentTypeScalarWhereInput;
  data: AppointmentTypeUncheckedUpdateManyWithoutAppointmentTypesInput;
};

export type AppointmentTypeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput = {
  where: AppointmentTypeScalarWhereInput;
  data: AppointmentTypeUncheckedUpdateManyWithoutAppointmentTypesInput;
};

export type AppointmentTypeUpdateManyWithWhereWithoutWorkflowsInput = {
  where: AppointmentTypeScalarWhereInput;
  data: AppointmentTypeUncheckedUpdateManyWithoutAppointmentTypesInput;
};

export type AppointmentTypeUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUpdateManyWithoutDirectBookingExclusionRulesNestedInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutDirectBookingExclusionRulesInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutDirectBookingExclusionRulesInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsNestedInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUpdateManyWithoutWorkflowsNestedInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutWorkflowsInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutWorkflowsInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsNestedInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
};

export type AppointmentTypeUpdateOneRequiredWithoutDirectBookingAppointmentTypeSettingNestedInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutDirectBookingAppointmentTypeSettingInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutDirectBookingAppointmentTypeSettingInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutDirectBookingAppointmentTypeSettingInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutDirectBookingAppointmentTypeSettingInput>;
};

export type AppointmentTypeUpdateOneRequiredWithoutRelatedTrupanionExamDayOffersNestedInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutRelatedTrupanionExamDayOffersInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutRelatedTrupanionExamDayOffersInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutRelatedTrupanionExamDayOffersInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutRelatedTrupanionExamDayOffersInput>;
};

export type AppointmentTypeUpdateOneRequiredWithoutSurveyAppointmentTypeSettingsNestedInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutSurveyAppointmentTypeSettingsInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutSurveyAppointmentTypeSettingsInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutSurveyAppointmentTypeSettingsInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutSurveyAppointmentTypeSettingsInput>;
};

export type AppointmentTypeUpdateOneRequiredWithoutTrupanionIntegratedAppointmentTypeNestedInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutTrupanionIntegratedAppointmentTypeInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutTrupanionIntegratedAppointmentTypeInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutTrupanionIntegratedAppointmentTypeInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutTrupanionIntegratedAppointmentTypeInput>;
};

export type AppointmentTypeUpdateOneWithoutAppointmentsNestedInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutAppointmentsInput>;
};

export type AppointmentTypeUpdateOneWithoutClinicWorkflowTypesNestedInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutClinicWorkflowTypesInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutClinicWorkflowTypesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutClinicWorkflowTypesInput>;
};

export type AppointmentTypeUpdateOneWithoutProviderAppointmentTypeRoomMapNestedInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutProviderAppointmentTypeRoomMapInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput>;
};

export type AppointmentTypeUpdateWithWhereUniqueWithoutClinicInput = {
  where: AppointmentTypeWhereUniqueInput;
  data: AppointmentTypeUncheckedUpdateWithoutClinicInput;
};

export type AppointmentTypeUpdateWithWhereUniqueWithoutDirectBookingExclusionRulesInput = {
  where: AppointmentTypeWhereUniqueInput;
  data: AppointmentTypeUncheckedUpdateWithoutDirectBookingExclusionRulesInput;
};

export type AppointmentTypeUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: AppointmentTypeWhereUniqueInput;
  data: AppointmentTypeUncheckedUpdateWithoutIntegrationInput;
};

export type AppointmentTypeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  where: AppointmentTypeWhereUniqueInput;
  data: AppointmentTypeUncheckedUpdateWithoutUserAppointmentGroupingsInput;
};

export type AppointmentTypeUpdateWithWhereUniqueWithoutWorkflowsInput = {
  where: AppointmentTypeWhereUniqueInput;
  data: AppointmentTypeUncheckedUpdateWithoutWorkflowsInput;
};

export type AppointmentTypeUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpdateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpdateWithoutDirectBookingAppointmentTypeSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpdateWithoutDirectBookingExclusionRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpdateWithoutProviderAppointmentTypeRoomMapInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpdateWithoutRelatedTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpdateWithoutSurveyAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpdateWithoutTrupanionIntegratedAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  displayOrder?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
};

export type AppointmentTypeUpsertWithWhereUniqueWithoutClinicInput = {
  where: AppointmentTypeWhereUniqueInput;
  update: AppointmentTypeUncheckedUpdateWithoutClinicInput;
  create: AppointmentTypeUncheckedCreateWithoutClinicInput;
};

export type AppointmentTypeUpsertWithWhereUniqueWithoutDirectBookingExclusionRulesInput = {
  where: AppointmentTypeWhereUniqueInput;
  update: AppointmentTypeUncheckedUpdateWithoutDirectBookingExclusionRulesInput;
  create: AppointmentTypeUncheckedCreateWithoutDirectBookingExclusionRulesInput;
};

export type AppointmentTypeUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: AppointmentTypeWhereUniqueInput;
  update: AppointmentTypeUncheckedUpdateWithoutIntegrationInput;
  create: AppointmentTypeUncheckedCreateWithoutIntegrationInput;
};

export type AppointmentTypeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  where: AppointmentTypeWhereUniqueInput;
  update: AppointmentTypeUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  create: AppointmentTypeUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type AppointmentTypeUpsertWithWhereUniqueWithoutWorkflowsInput = {
  where: AppointmentTypeWhereUniqueInput;
  update: AppointmentTypeUncheckedUpdateWithoutWorkflowsInput;
  create: AppointmentTypeUncheckedCreateWithoutWorkflowsInput;
};

export type AppointmentTypeUpsertWithoutAppointmentsInput = {
  update: AppointmentTypeUncheckedUpdateWithoutAppointmentsInput;
  create: AppointmentTypeUncheckedCreateWithoutAppointmentsInput;
};

export type AppointmentTypeUpsertWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  update: AppointmentTypeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput;
  create: AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type AppointmentTypeUpsertWithoutClinicWorkflowTypesInput = {
  update: AppointmentTypeUncheckedUpdateWithoutClinicWorkflowTypesInput;
  create: AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput;
};

export type AppointmentTypeUpsertWithoutDirectBookingAppointmentTypeSettingInput = {
  update: AppointmentTypeUncheckedUpdateWithoutDirectBookingAppointmentTypeSettingInput;
  create: AppointmentTypeUncheckedCreateWithoutDirectBookingAppointmentTypeSettingInput;
};

export type AppointmentTypeUpsertWithoutProviderAppointmentTypeRoomMapInput = {
  update: AppointmentTypeUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput;
  create: AppointmentTypeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput;
};

export type AppointmentTypeUpsertWithoutRelatedTrupanionExamDayOffersInput = {
  update: AppointmentTypeUncheckedUpdateWithoutRelatedTrupanionExamDayOffersInput;
  create: AppointmentTypeUncheckedCreateWithoutRelatedTrupanionExamDayOffersInput;
};

export type AppointmentTypeUpsertWithoutSurveyAppointmentTypeSettingsInput = {
  update: AppointmentTypeUncheckedUpdateWithoutSurveyAppointmentTypeSettingsInput;
  create: AppointmentTypeUncheckedCreateWithoutSurveyAppointmentTypeSettingsInput;
};

export type AppointmentTypeUpsertWithoutTrupanionIntegratedAppointmentTypeInput = {
  update: AppointmentTypeUncheckedUpdateWithoutTrupanionIntegratedAppointmentTypeInput;
  create: AppointmentTypeUncheckedCreateWithoutTrupanionIntegratedAppointmentTypeInput;
};

export enum AppointmentTypeVisibility {
  ShowAll = 'ShowAll',
  HideFromCalendarOnly = 'HideFromCalendarOnly',
  HideFromDropDownOnly = 'HideFromDropDownOnly',
  HideAll = 'HideAll'
}

export type AppointmentTypeWhereInput = {
  AND?: Maybe<Array<AppointmentTypeWhereInput>>;
  OR?: Maybe<Array<AppointmentTypeWhereInput>>;
  NOT?: Maybe<Array<AppointmentTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  displayName?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  isAvailableForDirectBooking?: Maybe<BoolFilter>;
  defaultSoonestAvailableBookingInHours?: Maybe<IntNullableFilter>;
  defaultDurationInMinutes?: Maybe<IntFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  appointmentTypeVisibility?: Maybe<EnumAppointmentTypeVisibilityFilter>;
  isVisible?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  showInPetPortal?: Maybe<BoolFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  delayedStartTime?: Maybe<IntNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeListRelationFilter>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  workflows?: Maybe<WorkflowEventSettingListRelationFilter>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingListRelationFilter>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingListRelationFilter>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleListRelationFilter>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapListRelationFilter>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferListRelationFilter>;
};

export type AppointmentTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  appointmentTypePimsIdAndIntegrationId?: Maybe<AppointmentTypeAppointmentTypePimsIdAndIntegrationIdCompoundUniqueInput>;
};

export type AppointmentTypeWithOrder = {
  id: Scalars['String'];
  displayOrder: Scalars['Int'];
};

export type AppointmentTypeWithOrderResponse = {
  __typename?: 'AppointmentTypeWithOrderResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type AppointmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<AppointmentCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<AppointmentCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<AppointmentCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<AppointmentCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicEmployeesInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetParentsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetParentsInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<AppointmentCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutConsultationInput>>;
  createMany?: Maybe<AppointmentCreateManyConsultationInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<AppointmentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutRoomInput = {
  create?: Maybe<Array<AppointmentCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutRoomInput>>;
  createMany?: Maybe<AppointmentCreateManyRoomInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput = {
  create?: Maybe<Array<AppointmentCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutWorkflowsInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutDirectBookingWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<AppointmentCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentUncheckedUpdateManyWithoutChannelNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<AppointmentCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicEmployeesInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicEmployeesInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicEmployeesInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicEmployeesInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetParentsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetParentsInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicPetParentsInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicPetParentsInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicPetParentsInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutConsultationNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutConsultationInput>>;
  createMany?: Maybe<AppointmentCreateManyConsultationInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutRoomNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutRoomInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutRoomInput>>;
  createMany?: Maybe<AppointmentCreateManyRoomInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutRoomInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutRoomInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutWorkflowsInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutWorkflowsInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutDirectBookingWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutMassTextAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentUpdateManyWithWhereWithoutAppointmentTypeInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutChannelInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutClinicEmployeesInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutClinicInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutClinicPetInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutClinicPetParentsInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutConsultationInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutIntegrationInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutRoomInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutWorkflowsInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithoutAppointmentTypeNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<AppointmentCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutChannelNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<AppointmentCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutClinicEmployeesNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicEmployeesInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicEmployeesInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicEmployeesInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicEmployeesInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutClinicPetParentsNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetParentsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetParentsInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicPetParentsInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicPetParentsInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicPetParentsInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutConsultationNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutConsultationInput>>;
  createMany?: Maybe<AppointmentCreateManyConsultationInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutRoomNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutRoomInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutRoomInput>>;
  createMany?: Maybe<AppointmentCreateManyRoomInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutRoomInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutRoomInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutWorkflowsNestedInput = {
  create?: Maybe<Array<AppointmentCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutWorkflowsInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutWorkflowsInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateOneRequiredWithoutMassTextAlertEntryAppointmentsNestedInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutMassTextAlertEntryAppointmentsInput>;
  upsert?: Maybe<AppointmentUpsertWithoutMassTextAlertEntryAppointmentsInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutMassTextAlertEntryAppointmentsInput>;
};

export type AppointmentUpdateOneRequiredWithoutSurveyResultsNestedInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutSurveyResultsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutSurveyResultsInput>;
  upsert?: Maybe<AppointmentUpsertWithoutSurveyResultsInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutSurveyResultsInput>;
};

export type AppointmentUpdateOneWithoutAutomationRunsNestedInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutAutomationRunsInput>;
  upsert?: Maybe<AppointmentUpsertWithoutAutomationRunsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutAutomationRunsInput>;
};

export type AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<AppointmentUpsertWithoutChannelAutomationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutChannelAutomationStatusesInput>;
};

export type AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutDirectBookingWidgetRequestInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutDirectBookingWidgetRequestInput>;
  upsert?: Maybe<AppointmentUpsertWithoutDirectBookingWidgetRequestInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutDirectBookingWidgetRequestInput>;
};

export type AppointmentUpdateOneWithoutFormSubmissionsNestedInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutFormSubmissionsInput>;
  upsert?: Maybe<AppointmentUpsertWithoutFormSubmissionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutFormSubmissionsInput>;
};

export type AppointmentUpdateOneWithoutPushNotificationLogsNestedInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutPushNotificationLogsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutPushNotificationLogsInput>;
  upsert?: Maybe<AppointmentUpsertWithoutPushNotificationLogsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutPushNotificationLogsInput>;
};

export type AppointmentUpdateOneWithoutWorkflowTriggersNestedInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutWorkflowTriggersInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutWorkflowTriggersInput>;
  upsert?: Maybe<AppointmentUpsertWithoutWorkflowTriggersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutWorkflowTriggersInput>;
};

export type AppointmentUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutAppointmentTypeInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutChannelInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutChannelInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutClinicEmployeesInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutClinicEmployeesInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutClinicInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutClinicInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutClinicPetInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutClinicPetParentsInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutClinicPetParentsInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutConsultationInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutConsultationInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutIntegrationInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutRoomInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutRoomInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutWorkflowsInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutWorkflowsInput;
};

export type AppointmentUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutDirectBookingWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutMassTextAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutAppointmentTypeInput;
  create: AppointmentUncheckedCreateWithoutAppointmentTypeInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutChannelInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutChannelInput;
  create: AppointmentUncheckedCreateWithoutChannelInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutClinicEmployeesInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutClinicEmployeesInput;
  create: AppointmentUncheckedCreateWithoutClinicEmployeesInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutClinicInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutClinicInput;
  create: AppointmentUncheckedCreateWithoutClinicInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutClinicPetInput;
  create: AppointmentUncheckedCreateWithoutClinicPetInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutClinicPetParentsInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutClinicPetParentsInput;
  create: AppointmentUncheckedCreateWithoutClinicPetParentsInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutConsultationInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutConsultationInput;
  create: AppointmentUncheckedCreateWithoutConsultationInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutIntegrationInput;
  create: AppointmentUncheckedCreateWithoutIntegrationInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutRoomInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutRoomInput;
  create: AppointmentUncheckedCreateWithoutRoomInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutWorkflowsInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutWorkflowsInput;
  create: AppointmentUncheckedCreateWithoutWorkflowsInput;
};

export type AppointmentUpsertWithoutAutomationRunsInput = {
  update: AppointmentUncheckedUpdateWithoutAutomationRunsInput;
  create: AppointmentUncheckedCreateWithoutAutomationRunsInput;
};

export type AppointmentUpsertWithoutChannelAutomationStatusesInput = {
  update: AppointmentUncheckedUpdateWithoutChannelAutomationStatusesInput;
  create: AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type AppointmentUpsertWithoutDirectBookingWidgetRequestInput = {
  update: AppointmentUncheckedUpdateWithoutDirectBookingWidgetRequestInput;
  create: AppointmentUncheckedCreateWithoutDirectBookingWidgetRequestInput;
};

export type AppointmentUpsertWithoutFormSubmissionsInput = {
  update: AppointmentUncheckedUpdateWithoutFormSubmissionsInput;
  create: AppointmentUncheckedCreateWithoutFormSubmissionsInput;
};

export type AppointmentUpsertWithoutMassTextAlertEntryAppointmentsInput = {
  update: AppointmentUncheckedUpdateWithoutMassTextAlertEntryAppointmentsInput;
  create: AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput;
};

export type AppointmentUpsertWithoutPushNotificationLogsInput = {
  update: AppointmentUncheckedUpdateWithoutPushNotificationLogsInput;
  create: AppointmentUncheckedCreateWithoutPushNotificationLogsInput;
};

export type AppointmentUpsertWithoutSurveyResultsInput = {
  update: AppointmentUncheckedUpdateWithoutSurveyResultsInput;
  create: AppointmentUncheckedCreateWithoutSurveyResultsInput;
};

export type AppointmentUpsertWithoutWorkflowTriggersInput = {
  update: AppointmentUncheckedUpdateWithoutWorkflowTriggersInput;
  create: AppointmentUncheckedCreateWithoutWorkflowTriggersInput;
};

export type AppointmentWhereInput = {
  AND?: Maybe<Array<AppointmentWhereInput>>;
  OR?: Maybe<Array<AppointmentWhereInput>>;
  NOT?: Maybe<Array<AppointmentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  durationInMinutes?: Maybe<IntFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  startAt?: Maybe<DateTimeFilter>;
  consultationId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  appointmentTypeId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  title?: Maybe<StringNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  roomId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumAppointmentStatusFilter>;
  pimsStatus?: Maybe<StringNullableFilter>;
  workflow?: Maybe<EnumWorkflowTypeNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  hash?: Maybe<StringNullableFilter>;
  pimsExportConsultationId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
  channel?: Maybe<ChannelWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  room?: Maybe<ClinicRoomWhereInput>;
  consultation?: Maybe<ConsultationWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  clinicEmployees?: Maybe<ClinicEmployeeListRelationFilter>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  workflows?: Maybe<WorkflowEventSettingListRelationFilter>;
  surveyResults?: Maybe<SurveyResultListRelationFilter>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestWhereInput>;
  workflowTriggers?: Maybe<WorkflowTriggerListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentListRelationFilter>;
  pushNotificationLogs?: Maybe<PushNotificationLogListRelationFilter>;
};

export type AppointmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  appointmentPimsIdAndIntegrationId?: Maybe<AppointmentAppointmentPimsIdAndIntegrationIdCompoundUniqueInput>;
};

export type AuthLinkEmailMatch = {
  __typename?: 'AuthLinkEmailMatch';
  id: Scalars['String'];
  redacted: Scalars['String'];
};

export type AuthLinkRequestInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  queryParams?: Maybe<Scalars['Json']>;
};

export type AuthLinkRequestResult = {
  __typename?: 'AuthLinkRequestResult';
  status: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<AuthLinkEmailMatch>>>;
};

export enum AutoResponseSuggestionStatus {
  Generated = 'Generated',
  Used = 'Used',
  Edited = 'Edited',
  Discarded = 'Discarded'
}

export enum AutomatedTriggerType {
  Before = 'Before',
  After = 'After'
}

export type AutomationRun = {
  __typename?: 'AutomationRun';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType: AutomationRunType;
  manuallySentById?: Maybe<Scalars['String']>;
  status: AutomationRunStatus;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParent;
  channelMessage: ChannelMessage;
  clinic: Clinic;
  appointment?: Maybe<Appointment>;
  formSubmission?: Maybe<FormSubmission>;
  serviceReminder?: Maybe<ServiceReminder>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  actions: Array<AutomationRunAction>;
  originAutomation: WorkflowEventSetting;
  manuallySentBy?: Maybe<User>;
  invioceLineItem?: Maybe<InvoiceLineItem>;
  returnToAutomationAction?: Maybe<WorkflowEventAction>;
  surveyResults: Array<SurveyResult>;
  HillsToHomeAPILog: Array<HillsToHomeApiLog>;
  _count: AutomationRunCountOutputType;
};


export type AutomationRunActionsArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
};


export type AutomationRunSurveyResultsArgs = {
  where?: Maybe<SurveyResultWhereInput>;
  orderBy?: Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SurveyResultScalarFieldEnum>;
};


export type AutomationRunHillsToHomeApiLogArgs = {
  where?: Maybe<HillsToHomeApiLogWhereInput>;
  orderBy?: Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<HillsToHomeApiLogScalarFieldEnum>;
};

export type AutomationRunAction = {
  __typename?: 'AutomationRunAction';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments: Scalars['Json'];
  promptType: AutomationRunActionPromptType;
  actionType: AutomationRunActionType;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons: Scalars['Json'];
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  automationRun: AutomationRun;
  petParent?: Maybe<ClinicPetParent>;
  pet?: Maybe<ClinicPet>;
  automationAction?: Maybe<WorkflowEventAction>;
  channelStatusChange?: Maybe<ChannelStatus>;
  formSubmission?: Maybe<FormSubmission>;
  paymentIntent?: Maybe<StripePaymentIntent>;
};

export type AutomationRunActionAvgAggregateOutputType = {
  __typename?: 'AutomationRunActionAvgAggregateOutputType';
  order?: Maybe<Scalars['Float']>;
};

export type AutomationRunActionAvgOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type AutomationRunActionCountAggregateOutputType = {
  __typename?: 'AutomationRunActionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  automationRunId: Scalars['Int'];
  automationActionId: Scalars['Int'];
  body: Scalars['Int'];
  attachments: Scalars['Int'];
  promptType: Scalars['Int'];
  actionType: Scalars['Int'];
  petParentId: Scalars['Int'];
  petId: Scalars['Int'];
  buttons: Scalars['Int'];
  channelStatusChangeId: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  paymentIntentId: Scalars['Int'];
  order: Scalars['Int'];
  _all: Scalars['Int'];
};

export type AutomationRunActionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  automationActionId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  buttons?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  paymentIntentId?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
};

export type AutomationRunActionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionCreateManyAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionCreateManyAutomationActionInputEnvelope = {
  data: Array<AutomationRunActionCreateManyAutomationActionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateManyAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionCreateManyAutomationRunInputEnvelope = {
  data: Array<AutomationRunActionCreateManyAutomationRunInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateManyChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionCreateManyChannelStatusChangeInputEnvelope = {
  data: Array<AutomationRunActionCreateManyChannelStatusChangeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionCreateManyPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionCreateManyPetInputEnvelope = {
  data: Array<AutomationRunActionCreateManyPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateManyPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionCreateManyPetParentInputEnvelope = {
  data: Array<AutomationRunActionCreateManyPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateNestedManyWithoutAutomationActionInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationActionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationActionInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationActionInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionCreateNestedManyWithoutAutomationRunInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationRunInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationRunInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyChannelStatusChangeInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionCreateNestedManyWithoutPetInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionCreateNestedManyWithoutPetParentInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetParentInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetParentInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionCreateNestedOneWithoutFormSubmissionInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutFormSubmissionInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutFormSubmissionInput>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
};

export type AutomationRunActionCreateNestedOneWithoutPaymentIntentInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutPaymentIntentInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutPaymentIntentInput>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
};

export type AutomationRunActionCreateOrConnectWithoutAutomationActionInput = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionUncheckedCreateWithoutAutomationActionInput;
};

export type AutomationRunActionCreateOrConnectWithoutAutomationRunInput = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionUncheckedCreateWithoutAutomationRunInput;
};

export type AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionUncheckedCreateWithoutChannelStatusChangeInput;
};

export type AutomationRunActionCreateOrConnectWithoutFormSubmissionInput = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionUncheckedCreateWithoutFormSubmissionInput;
};

export type AutomationRunActionCreateOrConnectWithoutPaymentIntentInput = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionUncheckedCreateWithoutPaymentIntentInput;
};

export type AutomationRunActionCreateOrConnectWithoutPetInput = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionUncheckedCreateWithoutPetInput;
};

export type AutomationRunActionCreateOrConnectWithoutPetParentInput = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionUncheckedCreateWithoutPetParentInput;
};

export type AutomationRunActionCreateWithoutAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionCreateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionCreateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionCreateWithoutPaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionCreateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionListRelationFilter = {
  every?: Maybe<AutomationRunActionWhereInput>;
  some?: Maybe<AutomationRunActionWhereInput>;
  none?: Maybe<AutomationRunActionWhereInput>;
};

export type AutomationRunActionMaxAggregateOutputType = {
  __typename?: 'AutomationRunActionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  automationActionId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  paymentIntentId?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
};

export type AutomationRunActionMinAggregateOutputType = {
  __typename?: 'AutomationRunActionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  automationActionId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  paymentIntentId?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
};

export type AutomationRunActionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AutomationRunActionOrderByRelevanceFieldEnum {
  Id = 'id',
  AutomationRunId = 'automationRunId',
  AutomationActionId = 'automationActionId',
  Body = 'body',
  PetParentId = 'petParentId',
  PetId = 'petId',
  ChannelStatusChangeId = 'channelStatusChangeId',
  FormSubmissionId = 'formSubmissionId',
  PaymentIntentId = 'paymentIntentId'
}

export type AutomationRunActionOrderByRelevanceInput = {
  fields: Array<AutomationRunActionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type AutomationRunActionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  automationActionId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  buttons?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  paymentIntentId?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  _count?: Maybe<AutomationRunActionCountOrderByAggregateInput>;
  _avg?: Maybe<AutomationRunActionAvgOrderByAggregateInput>;
  _max?: Maybe<AutomationRunActionMaxOrderByAggregateInput>;
  _min?: Maybe<AutomationRunActionMinOrderByAggregateInput>;
  _sum?: Maybe<AutomationRunActionSumOrderByAggregateInput>;
};

export type AutomationRunActionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  automationActionId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  buttons?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  paymentIntentId?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  automationRun?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  petParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  pet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  automationAction?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  channelStatusChange?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  paymentIntent?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<AutomationRunActionOrderByRelevanceInput>;
};

export enum AutomationRunActionPromptType {
  Buttons = 'Buttons',
  Form = 'Form',
  Payment = 'Payment',
  Prompt = 'Prompt',
  Statement = 'Statement'
}

export type AutomationRunActionRelationFilter = {
  is?: Maybe<AutomationRunActionWhereInput>;
  isNot?: Maybe<AutomationRunActionWhereInput>;
};

export enum AutomationRunActionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  AutomationRunId = 'automationRunId',
  AutomationActionId = 'automationActionId',
  Body = 'body',
  Attachments = 'attachments',
  PromptType = 'promptType',
  ActionType = 'actionType',
  PetParentId = 'petParentId',
  PetId = 'petId',
  Buttons = 'buttons',
  ChannelStatusChangeId = 'channelStatusChangeId',
  FormSubmissionId = 'formSubmissionId',
  PaymentIntentId = 'paymentIntentId',
  Order = 'order'
}

export type AutomationRunActionScalarWhereInput = {
  AND?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  OR?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  NOT?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  automationRunId?: Maybe<StringFilter>;
  automationActionId?: Maybe<StringNullableFilter>;
  body?: Maybe<StringFilter>;
  attachments?: Maybe<JsonFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeFilter>;
  actionType?: Maybe<EnumAutomationRunActionTypeFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  petId?: Maybe<StringNullableFilter>;
  buttons?: Maybe<JsonFilter>;
  channelStatusChangeId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  paymentIntentId?: Maybe<StringNullableFilter>;
  order?: Maybe<IntFilter>;
};

export type AutomationRunActionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AutomationRunActionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AutomationRunActionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AutomationRunActionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  automationRunId?: Maybe<StringWithAggregatesFilter>;
  automationActionId?: Maybe<StringNullableWithAggregatesFilter>;
  body?: Maybe<StringWithAggregatesFilter>;
  attachments?: Maybe<JsonWithAggregatesFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeWithAggregatesFilter>;
  actionType?: Maybe<EnumAutomationRunActionTypeWithAggregatesFilter>;
  petParentId?: Maybe<StringNullableWithAggregatesFilter>;
  petId?: Maybe<StringNullableWithAggregatesFilter>;
  buttons?: Maybe<JsonWithAggregatesFilter>;
  channelStatusChangeId?: Maybe<StringNullableWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  paymentIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  order?: Maybe<IntWithAggregatesFilter>;
};

export type AutomationRunActionSubscription = {
  __typename?: 'AutomationRunActionSubscription';
  node: AutomationRunAction;
  mutation: MutationType;
};

export type AutomationRunActionSumAggregateOutputType = {
  __typename?: 'AutomationRunActionSumAggregateOutputType';
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionSumOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export enum AutomationRunActionType {
  PetParent = 'PetParent',
  Automated = 'Automated',
  System = 'System'
}

export type AutomationRunActionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationActionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationActionInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationActionInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationRunInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationRunInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyChannelStatusChangeInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutPetInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetParentInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetParentInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutFormSubmissionInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutFormSubmissionInput>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
};

export type AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutPaymentIntentInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutPaymentIntentInput>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
};

export type AutomationRunActionUncheckedCreateWithoutAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedCreateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedCreateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedCreateWithoutPaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedCreateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationActionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationActionInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutAutomationActionInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationActionInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutAutomationActionInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutAutomationActionInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationRunInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutAutomationRunInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationRunInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutAutomationRunInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutAutomationRunInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyChannelStatusChangeInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutChannelStatusChangeInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutPetInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutPetInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutPetInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetParentInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutPetParentInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetParentInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutPetParentInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutFormSubmissionInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutFormSubmissionInput>;
  upsert?: Maybe<AutomationRunActionUpsertWithoutFormSubmissionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  update?: Maybe<AutomationRunActionUncheckedUpdateWithoutFormSubmissionInput>;
};

export type AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutPaymentIntentInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutPaymentIntentInput>;
  upsert?: Maybe<AutomationRunActionUpsertWithoutPaymentIntentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  update?: Maybe<AutomationRunActionUncheckedUpdateWithoutPaymentIntentInput>;
};

export type AutomationRunActionUncheckedUpdateWithoutAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateWithoutPaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsNestedInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput>;
};

export type AutomationRunActionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUpdateManyWithWhereWithoutAutomationActionInput = {
  where: AutomationRunActionScalarWhereInput;
  data: AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput;
};

export type AutomationRunActionUpdateManyWithWhereWithoutAutomationRunInput = {
  where: AutomationRunActionScalarWhereInput;
  data: AutomationRunActionUncheckedUpdateManyWithoutActionsInput;
};

export type AutomationRunActionUpdateManyWithWhereWithoutChannelStatusChangeInput = {
  where: AutomationRunActionScalarWhereInput;
  data: AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput;
};

export type AutomationRunActionUpdateManyWithWhereWithoutPetInput = {
  where: AutomationRunActionScalarWhereInput;
  data: AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput;
};

export type AutomationRunActionUpdateManyWithWhereWithoutPetParentInput = {
  where: AutomationRunActionScalarWhereInput;
  data: AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput;
};

export type AutomationRunActionUpdateManyWithoutAutomationActionNestedInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationActionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationActionInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutAutomationActionInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationActionInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutAutomationActionInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutAutomationActionInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUpdateManyWithoutAutomationRunNestedInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationRunInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutAutomationRunInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationRunInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutAutomationRunInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutAutomationRunInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyChannelStatusChangeInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutChannelStatusChangeInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUpdateManyWithoutPetNestedInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutPetInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutPetInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutPetInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUpdateManyWithoutPetParentNestedInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetParentInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutPetParentInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetParentInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutPetParentInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutFormSubmissionInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutFormSubmissionInput>;
  upsert?: Maybe<AutomationRunActionUpsertWithoutFormSubmissionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  update?: Maybe<AutomationRunActionUncheckedUpdateWithoutFormSubmissionInput>;
};

export type AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutPaymentIntentInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutPaymentIntentInput>;
  upsert?: Maybe<AutomationRunActionUpsertWithoutPaymentIntentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  update?: Maybe<AutomationRunActionUncheckedUpdateWithoutPaymentIntentInput>;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutAutomationActionInput = {
  where: AutomationRunActionWhereUniqueInput;
  data: AutomationRunActionUncheckedUpdateWithoutAutomationActionInput;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutAutomationRunInput = {
  where: AutomationRunActionWhereUniqueInput;
  data: AutomationRunActionUncheckedUpdateWithoutAutomationRunInput;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput = {
  where: AutomationRunActionWhereUniqueInput;
  data: AutomationRunActionUncheckedUpdateWithoutChannelStatusChangeInput;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutPetInput = {
  where: AutomationRunActionWhereUniqueInput;
  data: AutomationRunActionUncheckedUpdateWithoutPetInput;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutPetParentInput = {
  where: AutomationRunActionWhereUniqueInput;
  data: AutomationRunActionUncheckedUpdateWithoutPetParentInput;
};

export type AutomationRunActionUpdateWithoutAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsNestedInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput>;
};

export type AutomationRunActionUpdateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput>;
};

export type AutomationRunActionUpdateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsNestedInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput>;
};

export type AutomationRunActionUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsNestedInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput>;
};

export type AutomationRunActionUpdateWithoutPaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsNestedInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput>;
};

export type AutomationRunActionUpdateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsNestedInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput>;
};

export type AutomationRunActionUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsNestedInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput>;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutAutomationActionInput = {
  where: AutomationRunActionWhereUniqueInput;
  update: AutomationRunActionUncheckedUpdateWithoutAutomationActionInput;
  create: AutomationRunActionUncheckedCreateWithoutAutomationActionInput;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutAutomationRunInput = {
  where: AutomationRunActionWhereUniqueInput;
  update: AutomationRunActionUncheckedUpdateWithoutAutomationRunInput;
  create: AutomationRunActionUncheckedCreateWithoutAutomationRunInput;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput = {
  where: AutomationRunActionWhereUniqueInput;
  update: AutomationRunActionUncheckedUpdateWithoutChannelStatusChangeInput;
  create: AutomationRunActionUncheckedCreateWithoutChannelStatusChangeInput;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutPetInput = {
  where: AutomationRunActionWhereUniqueInput;
  update: AutomationRunActionUncheckedUpdateWithoutPetInput;
  create: AutomationRunActionUncheckedCreateWithoutPetInput;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutPetParentInput = {
  where: AutomationRunActionWhereUniqueInput;
  update: AutomationRunActionUncheckedUpdateWithoutPetParentInput;
  create: AutomationRunActionUncheckedCreateWithoutPetParentInput;
};

export type AutomationRunActionUpsertWithoutFormSubmissionInput = {
  update: AutomationRunActionUncheckedUpdateWithoutFormSubmissionInput;
  create: AutomationRunActionUncheckedCreateWithoutFormSubmissionInput;
};

export type AutomationRunActionUpsertWithoutPaymentIntentInput = {
  update: AutomationRunActionUncheckedUpdateWithoutPaymentIntentInput;
  create: AutomationRunActionUncheckedCreateWithoutPaymentIntentInput;
};

export type AutomationRunActionWhereInput = {
  AND?: Maybe<Array<AutomationRunActionWhereInput>>;
  OR?: Maybe<Array<AutomationRunActionWhereInput>>;
  NOT?: Maybe<Array<AutomationRunActionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  automationRunId?: Maybe<StringFilter>;
  automationActionId?: Maybe<StringNullableFilter>;
  body?: Maybe<StringFilter>;
  attachments?: Maybe<JsonFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeFilter>;
  actionType?: Maybe<EnumAutomationRunActionTypeFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  petId?: Maybe<StringNullableFilter>;
  buttons?: Maybe<JsonFilter>;
  channelStatusChangeId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  paymentIntentId?: Maybe<StringNullableFilter>;
  order?: Maybe<IntFilter>;
  automationRun?: Maybe<AutomationRunWhereInput>;
  petParent?: Maybe<ClinicPetParentWhereInput>;
  pet?: Maybe<ClinicPetWhereInput>;
  automationAction?: Maybe<WorkflowEventActionWhereInput>;
  channelStatusChange?: Maybe<ChannelStatusWhereInput>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  paymentIntent?: Maybe<StripePaymentIntentWhereInput>;
};

export type AutomationRunActionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
};

export type AutomationRunCountAggregateOutputType = {
  __typename?: 'AutomationRunCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  channelMessageId: Scalars['Int'];
  originAutomationId: Scalars['Int'];
  runType: Scalars['Int'];
  manuallySentById: Scalars['Int'];
  status: Scalars['Int'];
  automationEvent: Scalars['Int'];
  clinicId: Scalars['Int'];
  startedAt: Scalars['Int'];
  completedAt: Scalars['Int'];
  appointmentId: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  serviceReminderId: Scalars['Int'];
  clinicWidgetRequestId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  invoiceLineItemId: Scalars['Int'];
  returnToAutomationActionId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type AutomationRunCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  originAutomationId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  manuallySentById?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  returnToAutomationActionId?: Maybe<SortOrder>;
};

export type AutomationRunCountOutputType = {
  __typename?: 'AutomationRunCountOutputType';
  actions: Scalars['Int'];
  surveyResults: Scalars['Int'];
  HillsToHomeAPILog: Scalars['Int'];
};

export type AutomationRunCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunCreateManyAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunCreateManyAppointmentInputEnvelope = {
  data: Array<AutomationRunCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunCreateManyClinicInputEnvelope = {
  data: Array<AutomationRunCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunCreateManyClinicPetParentInputEnvelope = {
  data: Array<AutomationRunCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunCreateManyClinicWidgetRequestInputEnvelope = {
  data: Array<AutomationRunCreateManyClinicWidgetRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunCreateManyFormSubmissionInputEnvelope = {
  data: Array<AutomationRunCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunCreateManyInvioceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunCreateManyInvioceLineItemInputEnvelope = {
  data: Array<AutomationRunCreateManyInvioceLineItemInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyManuallySentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunCreateManyManuallySentByInputEnvelope = {
  data: Array<AutomationRunCreateManyManuallySentByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyOriginAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunCreateManyOriginAutomationInputEnvelope = {
  data: Array<AutomationRunCreateManyOriginAutomationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyReturnToAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type AutomationRunCreateManyReturnToAutomationActionInputEnvelope = {
  data: Array<AutomationRunCreateManyReturnToAutomationActionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunCreateManyServiceReminderInputEnvelope = {
  data: Array<AutomationRunCreateManyServiceReminderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<AutomationRunCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicWidgetRequestInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<AutomationRunCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutInvioceLineItemInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutInvioceLineItemInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutInvioceLineItemInput>>;
  createMany?: Maybe<AutomationRunCreateManyInvioceLineItemInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutManuallySentByInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutManuallySentByInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutManuallySentByInput>>;
  createMany?: Maybe<AutomationRunCreateManyManuallySentByInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutOriginAutomationInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutOriginAutomationInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutOriginAutomationInput>>;
  createMany?: Maybe<AutomationRunCreateManyOriginAutomationInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutReturnToAutomationActionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutReturnToAutomationActionInput>>;
  createMany?: Maybe<AutomationRunCreateManyReturnToAutomationActionInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<AutomationRunCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedOneWithoutActionsInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutActionsInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutActionsInput>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
};

export type AutomationRunCreateNestedOneWithoutChannelMessageInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutChannelMessageInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutChannelMessageInput>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
};

export type AutomationRunCreateNestedOneWithoutHillsToHomeApiLogInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutHillsToHomeApiLogInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutHillsToHomeApiLogInput>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
};

export type AutomationRunCreateNestedOneWithoutSurveyResultsInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutSurveyResultsInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutSurveyResultsInput>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
};

export type AutomationRunCreateOrConnectWithoutActionsInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutActionsInput;
};

export type AutomationRunCreateOrConnectWithoutAppointmentInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutAppointmentInput;
};

export type AutomationRunCreateOrConnectWithoutChannelMessageInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutChannelMessageInput;
};

export type AutomationRunCreateOrConnectWithoutClinicInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutClinicInput;
};

export type AutomationRunCreateOrConnectWithoutClinicPetParentInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutClinicPetParentInput;
};

export type AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutClinicWidgetRequestInput;
};

export type AutomationRunCreateOrConnectWithoutFormSubmissionInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutFormSubmissionInput;
};

export type AutomationRunCreateOrConnectWithoutHillsToHomeApiLogInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutHillsToHomeApiLogInput;
};

export type AutomationRunCreateOrConnectWithoutInvioceLineItemInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutInvioceLineItemInput;
};

export type AutomationRunCreateOrConnectWithoutManuallySentByInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutManuallySentByInput;
};

export type AutomationRunCreateOrConnectWithoutOriginAutomationInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutOriginAutomationInput;
};

export type AutomationRunCreateOrConnectWithoutReturnToAutomationActionInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutReturnToAutomationActionInput;
};

export type AutomationRunCreateOrConnectWithoutServiceReminderInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutServiceReminderInput;
};

export type AutomationRunCreateOrConnectWithoutSurveyResultsInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutSurveyResultsInput;
};

export type AutomationRunCreateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunCreateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunCreateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunCreateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunCreateWithoutInvioceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunCreateWithoutManuallySentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunCreateWithoutOriginAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunCreateWithoutReturnToAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunCreateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunListRelationFilter = {
  every?: Maybe<AutomationRunWhereInput>;
  some?: Maybe<AutomationRunWhereInput>;
  none?: Maybe<AutomationRunWhereInput>;
};

export type AutomationRunMaxAggregateOutputType = {
  __typename?: 'AutomationRunMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  originAutomationId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  manuallySentById?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  returnToAutomationActionId?: Maybe<SortOrder>;
};

export type AutomationRunMinAggregateOutputType = {
  __typename?: 'AutomationRunMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  originAutomationId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  manuallySentById?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  returnToAutomationActionId?: Maybe<SortOrder>;
};

export type AutomationRunOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AutomationRunOrderByRelevanceFieldEnum {
  Id = 'id',
  ChannelMessageId = 'channelMessageId',
  OriginAutomationId = 'originAutomationId',
  ManuallySentById = 'manuallySentById',
  ClinicId = 'clinicId',
  AppointmentId = 'appointmentId',
  FormSubmissionId = 'formSubmissionId',
  ServiceReminderId = 'serviceReminderId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  ClinicPetParentId = 'clinicPetParentId',
  InvoiceLineItemId = 'invoiceLineItemId',
  ReturnToAutomationActionId = 'returnToAutomationActionId'
}

export type AutomationRunOrderByRelevanceInput = {
  fields: Array<AutomationRunOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type AutomationRunOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  originAutomationId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  manuallySentById?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  returnToAutomationActionId?: Maybe<SortOrder>;
  _count?: Maybe<AutomationRunCountOrderByAggregateInput>;
  _max?: Maybe<AutomationRunMaxOrderByAggregateInput>;
  _min?: Maybe<AutomationRunMinOrderByAggregateInput>;
};

export type AutomationRunOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  originAutomationId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  manuallySentById?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  returnToAutomationActionId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  channelMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  serviceReminder?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  actions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  originAutomation?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  manuallySentBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  invioceLineItem?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  surveyResults?: Maybe<SurveyResultOrderByRelationAggregateInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogOrderByRelationAggregateInput>;
  _relevance?: Maybe<AutomationRunOrderByRelevanceInput>;
};

export type AutomationRunRelationFilter = {
  is?: Maybe<AutomationRunWhereInput>;
  isNot?: Maybe<AutomationRunWhereInput>;
};

export enum AutomationRunScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ChannelMessageId = 'channelMessageId',
  OriginAutomationId = 'originAutomationId',
  RunType = 'runType',
  ManuallySentById = 'manuallySentById',
  Status = 'status',
  AutomationEvent = 'automationEvent',
  ClinicId = 'clinicId',
  StartedAt = 'startedAt',
  CompletedAt = 'completedAt',
  AppointmentId = 'appointmentId',
  FormSubmissionId = 'formSubmissionId',
  ServiceReminderId = 'serviceReminderId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  ClinicPetParentId = 'clinicPetParentId',
  InvoiceLineItemId = 'invoiceLineItemId',
  ReturnToAutomationActionId = 'returnToAutomationActionId'
}

export type AutomationRunScalarWhereInput = {
  AND?: Maybe<Array<AutomationRunScalarWhereInput>>;
  OR?: Maybe<Array<AutomationRunScalarWhereInput>>;
  NOT?: Maybe<Array<AutomationRunScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  channelMessageId?: Maybe<StringFilter>;
  originAutomationId?: Maybe<StringFilter>;
  runType?: Maybe<EnumAutomationRunTypeFilter>;
  manuallySentById?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumAutomationRunStatusFilter>;
  automationEvent?: Maybe<EnumWorkflowEventFilter>;
  clinicId?: Maybe<StringFilter>;
  startedAt?: Maybe<DateTimeFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  invoiceLineItemId?: Maybe<StringNullableFilter>;
  returnToAutomationActionId?: Maybe<StringNullableFilter>;
};

export type AutomationRunScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AutomationRunScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AutomationRunScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AutomationRunScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  channelMessageId?: Maybe<StringWithAggregatesFilter>;
  originAutomationId?: Maybe<StringWithAggregatesFilter>;
  runType?: Maybe<EnumAutomationRunTypeWithAggregatesFilter>;
  manuallySentById?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumAutomationRunStatusWithAggregatesFilter>;
  automationEvent?: Maybe<EnumWorkflowEventWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  startedAt?: Maybe<DateTimeWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  appointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  serviceReminderId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  invoiceLineItemId?: Maybe<StringNullableWithAggregatesFilter>;
  returnToAutomationActionId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum AutomationRunStatus {
  Sent = 'Sent',
  Started = 'Started',
  Completed = 'Completed'
}

export type AutomationRunSubscription = {
  __typename?: 'AutomationRunSubscription';
  node: AutomationRun;
  mutation: MutationType;
};

export enum AutomationRunType {
  Manual = 'Manual',
  Automated = 'Automated'
}

export type AutomationRunUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<AutomationRunCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicWidgetRequestInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<AutomationRunCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutInvioceLineItemInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutInvioceLineItemInput>>;
  createMany?: Maybe<AutomationRunCreateManyInvioceLineItemInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutManuallySentByInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutManuallySentByInput>>;
  createMany?: Maybe<AutomationRunCreateManyManuallySentByInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutOriginAutomationInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutOriginAutomationInput>>;
  createMany?: Maybe<AutomationRunCreateManyOriginAutomationInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutReturnToAutomationActionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutReturnToAutomationActionInput>>;
  createMany?: Maybe<AutomationRunCreateManyReturnToAutomationActionInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<AutomationRunCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutChannelMessageInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutChannelMessageInput>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
};

export type AutomationRunUncheckedCreateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutInvioceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutManuallySentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutOriginAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutReturnToAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<AutomationRunCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicWidgetRequestInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<AutomationRunCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutInvioceLineItemInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutInvioceLineItemInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutInvioceLineItemInput>>;
  createMany?: Maybe<AutomationRunCreateManyInvioceLineItemInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutInvioceLineItemInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutInvioceLineItemInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutManuallySentAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
};

export type AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutManuallySentByInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutManuallySentByInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutManuallySentByInput>>;
  createMany?: Maybe<AutomationRunCreateManyManuallySentByInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutManuallySentByInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutManuallySentByInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutOriginAutomationInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutOriginAutomationInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutOriginAutomationInput>>;
  createMany?: Maybe<AutomationRunCreateManyOriginAutomationInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutOriginAutomationInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutOriginAutomationInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutReturnToAutomationActionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutReturnToAutomationActionInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutReturnToAutomationActionInput>>;
  createMany?: Maybe<AutomationRunCreateManyReturnToAutomationActionInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutReturnToAutomationActionInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutReturnToAutomationActionInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutReturnedAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<AutomationRunCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutChannelMessageInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutChannelMessageInput>;
  upsert?: Maybe<AutomationRunUpsertWithoutChannelMessageInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  update?: Maybe<AutomationRunUncheckedUpdateWithoutChannelMessageInput>;
};

export type AutomationRunUncheckedUpdateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUncheckedUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUncheckedUpdateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUncheckedUpdateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUncheckedUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUncheckedUpdateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUncheckedUpdateWithoutInvioceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUncheckedUpdateWithoutManuallySentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUncheckedUpdateWithoutOriginAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUncheckedUpdateWithoutReturnToAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUncheckedUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUncheckedUpdateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateManyWithWhereWithoutAppointmentInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutClinicInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutClinicWidgetRequestInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutFormSubmissionInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutInvioceLineItemInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunInput;
};

export type AutomationRunUpdateManyWithWhereWithoutManuallySentByInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutManuallySentAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutOriginAutomationInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutReturnToAutomationActionInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutReturnedAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutServiceReminderInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
};

export type AutomationRunUpdateManyWithoutAppointmentNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<AutomationRunCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicWidgetRequestInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutFormSubmissionNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<AutomationRunCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutInvioceLineItemNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutInvioceLineItemInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutInvioceLineItemInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutInvioceLineItemInput>>;
  createMany?: Maybe<AutomationRunCreateManyInvioceLineItemInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutInvioceLineItemInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutInvioceLineItemInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutManuallySentByNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutManuallySentByInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutManuallySentByInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutManuallySentByInput>>;
  createMany?: Maybe<AutomationRunCreateManyManuallySentByInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutManuallySentByInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutManuallySentByInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutOriginAutomationNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutOriginAutomationInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutOriginAutomationInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutOriginAutomationInput>>;
  createMany?: Maybe<AutomationRunCreateManyOriginAutomationInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutOriginAutomationInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutOriginAutomationInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutReturnToAutomationActionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutReturnToAutomationActionInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutReturnToAutomationActionInput>>;
  createMany?: Maybe<AutomationRunCreateManyReturnToAutomationActionInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutReturnToAutomationActionInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutReturnToAutomationActionInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutServiceReminderNestedInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<AutomationRunCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateOneRequiredWithoutActionsNestedInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutActionsInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutActionsInput>;
  upsert?: Maybe<AutomationRunUpsertWithoutActionsInput>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  update?: Maybe<AutomationRunUncheckedUpdateWithoutActionsInput>;
};

export type AutomationRunUpdateOneWithoutChannelMessageNestedInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutChannelMessageInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutChannelMessageInput>;
  upsert?: Maybe<AutomationRunUpsertWithoutChannelMessageInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  update?: Maybe<AutomationRunUncheckedUpdateWithoutChannelMessageInput>;
};

export type AutomationRunUpdateOneWithoutHillsToHomeApiLogNestedInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutHillsToHomeApiLogInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutHillsToHomeApiLogInput>;
  upsert?: Maybe<AutomationRunUpsertWithoutHillsToHomeApiLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  update?: Maybe<AutomationRunUncheckedUpdateWithoutHillsToHomeApiLogInput>;
};

export type AutomationRunUpdateOneWithoutSurveyResultsNestedInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutSurveyResultsInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutSurveyResultsInput>;
  upsert?: Maybe<AutomationRunUpsertWithoutSurveyResultsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  update?: Maybe<AutomationRunUncheckedUpdateWithoutSurveyResultsInput>;
};

export type AutomationRunUpdateWithWhereUniqueWithoutAppointmentInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutAppointmentInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutClinicInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutClinicInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutClinicPetParentInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutClinicWidgetRequestInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutClinicWidgetRequestInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutFormSubmissionInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutInvioceLineItemInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutInvioceLineItemInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutManuallySentByInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutManuallySentByInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutOriginAutomationInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutOriginAutomationInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutReturnToAutomationActionInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutReturnToAutomationActionInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutServiceReminderInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutServiceReminderInput;
};

export type AutomationRunUpdateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateWithoutInvioceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateWithoutManuallySentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateWithoutOriginAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateWithoutReturnToAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpdateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
};

export type AutomationRunUpsertWithWhereUniqueWithoutAppointmentInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutAppointmentInput;
  create: AutomationRunUncheckedCreateWithoutAppointmentInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutClinicInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutClinicInput;
  create: AutomationRunUncheckedCreateWithoutClinicInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutClinicPetParentInput;
  create: AutomationRunUncheckedCreateWithoutClinicPetParentInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutClinicWidgetRequestInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutClinicWidgetRequestInput;
  create: AutomationRunUncheckedCreateWithoutClinicWidgetRequestInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutFormSubmissionInput;
  create: AutomationRunUncheckedCreateWithoutFormSubmissionInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutInvioceLineItemInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutInvioceLineItemInput;
  create: AutomationRunUncheckedCreateWithoutInvioceLineItemInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutManuallySentByInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutManuallySentByInput;
  create: AutomationRunUncheckedCreateWithoutManuallySentByInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutOriginAutomationInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutOriginAutomationInput;
  create: AutomationRunUncheckedCreateWithoutOriginAutomationInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutReturnToAutomationActionInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutReturnToAutomationActionInput;
  create: AutomationRunUncheckedCreateWithoutReturnToAutomationActionInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutServiceReminderInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutServiceReminderInput;
  create: AutomationRunUncheckedCreateWithoutServiceReminderInput;
};

export type AutomationRunUpsertWithoutActionsInput = {
  update: AutomationRunUncheckedUpdateWithoutActionsInput;
  create: AutomationRunUncheckedCreateWithoutActionsInput;
};

export type AutomationRunUpsertWithoutChannelMessageInput = {
  update: AutomationRunUncheckedUpdateWithoutChannelMessageInput;
  create: AutomationRunUncheckedCreateWithoutChannelMessageInput;
};

export type AutomationRunUpsertWithoutHillsToHomeApiLogInput = {
  update: AutomationRunUncheckedUpdateWithoutHillsToHomeApiLogInput;
  create: AutomationRunUncheckedCreateWithoutHillsToHomeApiLogInput;
};

export type AutomationRunUpsertWithoutSurveyResultsInput = {
  update: AutomationRunUncheckedUpdateWithoutSurveyResultsInput;
  create: AutomationRunUncheckedCreateWithoutSurveyResultsInput;
};

export type AutomationRunWhereInput = {
  AND?: Maybe<Array<AutomationRunWhereInput>>;
  OR?: Maybe<Array<AutomationRunWhereInput>>;
  NOT?: Maybe<Array<AutomationRunWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  channelMessageId?: Maybe<StringFilter>;
  originAutomationId?: Maybe<StringFilter>;
  runType?: Maybe<EnumAutomationRunTypeFilter>;
  manuallySentById?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumAutomationRunStatusFilter>;
  automationEvent?: Maybe<EnumWorkflowEventFilter>;
  clinicId?: Maybe<StringFilter>;
  startedAt?: Maybe<DateTimeFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  invoiceLineItemId?: Maybe<StringNullableFilter>;
  returnToAutomationActionId?: Maybe<StringNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  channelMessage?: Maybe<ChannelMessageWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  appointment?: Maybe<AppointmentWhereInput>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  serviceReminder?: Maybe<ServiceReminderWhereInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestWhereInput>;
  actions?: Maybe<AutomationRunActionListRelationFilter>;
  originAutomation?: Maybe<WorkflowEventSettingWhereInput>;
  manuallySentBy?: Maybe<UserWhereInput>;
  invioceLineItem?: Maybe<InvoiceLineItemWhereInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionWhereInput>;
  surveyResults?: Maybe<SurveyResultListRelationFilter>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogListRelationFilter>;
};

export type AutomationRunWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  channelMessageId?: Maybe<Scalars['String']>;
};

export enum AutomationStatus {
  Queued = 'Queued',
  Started = 'Started',
  Paused = 'Paused',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
  Completed = 'Completed',
  ClientVisitAlreadyInitiated = 'ClientVisitAlreadyInitiated'
}

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count: Scalars['Int'];
};

export type BenefitSource = {
  __typename?: 'BenefitSource';
  planIds: Array<Scalars['String']>;
  addonIds: Array<Scalars['String']>;
};

export type BitwerxClientBalance = {
  __typename?: 'BitwerxClientBalance';
  currentBalance?: Maybe<Scalars['Float']>;
  agingCurrent?: Maybe<Scalars['Float']>;
  aging30D?: Maybe<Scalars['Float']>;
  aging60D?: Maybe<Scalars['Float']>;
  aging90D?: Maybe<Scalars['Float']>;
  aging120D?: Maybe<Scalars['Float']>;
};

export type BitwerxHealthcheck = {
  __typename?: 'BitwerxHealthcheck';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  type: BitwerxHealthcheckType;
  clinic: Clinic;
  paymentHealthcheckRuns: Array<BitwerxPaymentHealthcheckRun>;
  _count: BitwerxHealthcheckCountOutputType;
};


export type BitwerxHealthcheckPaymentHealthcheckRunsArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
  orderBy?: Maybe<BitwerxPaymentHealthcheckRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BitwerxPaymentHealthcheckRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BitwerxPaymentHealthcheckRunScalarFieldEnum>;
};

export type BitwerxHealthcheckCountAggregateOutputType = {
  __typename?: 'BitwerxHealthcheckCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  startsAt: Scalars['Int'];
  endsAt: Scalars['Int'];
  isEnabled: Scalars['Int'];
  type: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BitwerxHealthcheckCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type BitwerxHealthcheckCountOutputType = {
  __typename?: 'BitwerxHealthcheckCountOutputType';
  paymentHealthcheckRuns: Scalars['Int'];
};

export type BitwerxHealthcheckCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  type: BitwerxHealthcheckType;
  clinic: ClinicCreateNestedOneWithoutBitwerxHealthchecksInput;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunCreateNestedManyWithoutBitwerxHealthcheckInput>;
};

export type BitwerxHealthcheckCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  type: BitwerxHealthcheckType;
};

export type BitwerxHealthcheckCreateManyClinicInputEnvelope = {
  data: Array<BitwerxHealthcheckCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxHealthcheckCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  type: BitwerxHealthcheckType;
};

export type BitwerxHealthcheckCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<BitwerxHealthcheckCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<BitwerxHealthcheckCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<BitwerxHealthcheckCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
};

export type BitwerxHealthcheckCreateNestedOneWithoutPaymentHealthcheckRunsInput = {
  create?: Maybe<BitwerxHealthcheckUncheckedCreateWithoutPaymentHealthcheckRunsInput>;
  connectOrCreate?: Maybe<BitwerxHealthcheckCreateOrConnectWithoutPaymentHealthcheckRunsInput>;
  connect?: Maybe<BitwerxHealthcheckWhereUniqueInput>;
};

export type BitwerxHealthcheckCreateOrConnectWithoutClinicInput = {
  where: BitwerxHealthcheckWhereUniqueInput;
  create: BitwerxHealthcheckUncheckedCreateWithoutClinicInput;
};

export type BitwerxHealthcheckCreateOrConnectWithoutPaymentHealthcheckRunsInput = {
  where: BitwerxHealthcheckWhereUniqueInput;
  create: BitwerxHealthcheckUncheckedCreateWithoutPaymentHealthcheckRunsInput;
};

export type BitwerxHealthcheckCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  type: BitwerxHealthcheckType;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunCreateNestedManyWithoutBitwerxHealthcheckInput>;
};

export type BitwerxHealthcheckCreateWithoutPaymentHealthcheckRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  type: BitwerxHealthcheckType;
  clinic: ClinicCreateNestedOneWithoutBitwerxHealthchecksInput;
};

export type BitwerxHealthcheckListRelationFilter = {
  every?: Maybe<BitwerxHealthcheckWhereInput>;
  some?: Maybe<BitwerxHealthcheckWhereInput>;
  none?: Maybe<BitwerxHealthcheckWhereInput>;
};

export type BitwerxHealthcheckMaxAggregateOutputType = {
  __typename?: 'BitwerxHealthcheckMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BitwerxHealthcheckType>;
};

export type BitwerxHealthcheckMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type BitwerxHealthcheckMinAggregateOutputType = {
  __typename?: 'BitwerxHealthcheckMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BitwerxHealthcheckType>;
};

export type BitwerxHealthcheckMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type BitwerxHealthcheckOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BitwerxHealthcheckOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId'
}

export type BitwerxHealthcheckOrderByRelevanceInput = {
  fields: Array<BitwerxHealthcheckOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type BitwerxHealthcheckOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  _count?: Maybe<BitwerxHealthcheckCountOrderByAggregateInput>;
  _max?: Maybe<BitwerxHealthcheckMaxOrderByAggregateInput>;
  _min?: Maybe<BitwerxHealthcheckMinOrderByAggregateInput>;
};

export type BitwerxHealthcheckOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunOrderByRelationAggregateInput>;
  _relevance?: Maybe<BitwerxHealthcheckOrderByRelevanceInput>;
};

export type BitwerxHealthcheckRelationFilter = {
  is?: Maybe<BitwerxHealthcheckWhereInput>;
  isNot?: Maybe<BitwerxHealthcheckWhereInput>;
};

export enum BitwerxHealthcheckRunStatus {
  Healthy = 'Healthy',
  Mixed = 'Mixed',
  Unhealthy = 'Unhealthy',
  NoResults = 'NoResults'
}

export enum BitwerxHealthcheckScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  StartsAt = 'startsAt',
  EndsAt = 'endsAt',
  IsEnabled = 'isEnabled',
  Type = 'type'
}

export type BitwerxHealthcheckScalarWhereInput = {
  AND?: Maybe<Array<BitwerxHealthcheckScalarWhereInput>>;
  OR?: Maybe<Array<BitwerxHealthcheckScalarWhereInput>>;
  NOT?: Maybe<Array<BitwerxHealthcheckScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  isEnabled?: Maybe<BoolFilter>;
  type?: Maybe<EnumBitwerxHealthcheckTypeFilter>;
};

export type BitwerxHealthcheckScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BitwerxHealthcheckScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BitwerxHealthcheckScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BitwerxHealthcheckScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  startsAt?: Maybe<DateTimeWithAggregatesFilter>;
  endsAt?: Maybe<DateTimeWithAggregatesFilter>;
  isEnabled?: Maybe<BoolWithAggregatesFilter>;
  type?: Maybe<EnumBitwerxHealthcheckTypeWithAggregatesFilter>;
};

export enum BitwerxHealthcheckType {
  Payment = 'Payment'
}

export type BitwerxHealthcheckUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  type: BitwerxHealthcheckType;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunUncheckedCreateNestedManyWithoutBitwerxHealthcheckInput>;
};

export type BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<BitwerxHealthcheckCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<BitwerxHealthcheckCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<BitwerxHealthcheckCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
};

export type BitwerxHealthcheckUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  type: BitwerxHealthcheckType;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunUncheckedCreateNestedManyWithoutBitwerxHealthcheckInput>;
};

export type BitwerxHealthcheckUncheckedCreateWithoutPaymentHealthcheckRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  type: BitwerxHealthcheckType;
};

export type BitwerxHealthcheckUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BitwerxHealthcheckType>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunUncheckedUpdateManyWithoutBitwerxHealthcheckNestedInput>;
};

export type BitwerxHealthcheckUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BitwerxHealthcheckType>;
};

export type BitwerxHealthcheckUncheckedUpdateManyWithoutBitwerxHealthchecksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BitwerxHealthcheckType>;
};

export type BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<BitwerxHealthcheckCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<BitwerxHealthcheckCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<BitwerxHealthcheckUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<BitwerxHealthcheckCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxHealthcheckUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<BitwerxHealthcheckUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<BitwerxHealthcheckScalarWhereInput>>;
};

export type BitwerxHealthcheckUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BitwerxHealthcheckType>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunUncheckedUpdateManyWithoutBitwerxHealthcheckNestedInput>;
};

export type BitwerxHealthcheckUncheckedUpdateWithoutPaymentHealthcheckRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BitwerxHealthcheckType>;
};

export type BitwerxHealthcheckUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BitwerxHealthcheckType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutBitwerxHealthchecksNestedInput>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunUpdateManyWithoutBitwerxHealthcheckNestedInput>;
};

export type BitwerxHealthcheckUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BitwerxHealthcheckType>;
};

export type BitwerxHealthcheckUpdateManyWithWhereWithoutClinicInput = {
  where: BitwerxHealthcheckScalarWhereInput;
  data: BitwerxHealthcheckUncheckedUpdateManyWithoutBitwerxHealthchecksInput;
};

export type BitwerxHealthcheckUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<BitwerxHealthcheckCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<BitwerxHealthcheckCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<BitwerxHealthcheckUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<BitwerxHealthcheckCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxHealthcheckUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<BitwerxHealthcheckUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<BitwerxHealthcheckScalarWhereInput>>;
};

export type BitwerxHealthcheckUpdateOneRequiredWithoutPaymentHealthcheckRunsNestedInput = {
  create?: Maybe<BitwerxHealthcheckUncheckedCreateWithoutPaymentHealthcheckRunsInput>;
  connectOrCreate?: Maybe<BitwerxHealthcheckCreateOrConnectWithoutPaymentHealthcheckRunsInput>;
  upsert?: Maybe<BitwerxHealthcheckUpsertWithoutPaymentHealthcheckRunsInput>;
  connect?: Maybe<BitwerxHealthcheckWhereUniqueInput>;
  update?: Maybe<BitwerxHealthcheckUncheckedUpdateWithoutPaymentHealthcheckRunsInput>;
};

export type BitwerxHealthcheckUpdateWithWhereUniqueWithoutClinicInput = {
  where: BitwerxHealthcheckWhereUniqueInput;
  data: BitwerxHealthcheckUncheckedUpdateWithoutClinicInput;
};

export type BitwerxHealthcheckUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BitwerxHealthcheckType>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunUpdateManyWithoutBitwerxHealthcheckNestedInput>;
};

export type BitwerxHealthcheckUpdateWithoutPaymentHealthcheckRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BitwerxHealthcheckType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutBitwerxHealthchecksNestedInput>;
};

export type BitwerxHealthcheckUpsertWithWhereUniqueWithoutClinicInput = {
  where: BitwerxHealthcheckWhereUniqueInput;
  update: BitwerxHealthcheckUncheckedUpdateWithoutClinicInput;
  create: BitwerxHealthcheckUncheckedCreateWithoutClinicInput;
};

export type BitwerxHealthcheckUpsertWithoutPaymentHealthcheckRunsInput = {
  update: BitwerxHealthcheckUncheckedUpdateWithoutPaymentHealthcheckRunsInput;
  create: BitwerxHealthcheckUncheckedCreateWithoutPaymentHealthcheckRunsInput;
};

export type BitwerxHealthcheckWhereInput = {
  AND?: Maybe<Array<BitwerxHealthcheckWhereInput>>;
  OR?: Maybe<Array<BitwerxHealthcheckWhereInput>>;
  NOT?: Maybe<Array<BitwerxHealthcheckWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  isEnabled?: Maybe<BoolFilter>;
  type?: Maybe<EnumBitwerxHealthcheckTypeFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunListRelationFilter>;
};

export type BitwerxHealthcheckWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BitwerxIntegration = {
  __typename?: 'BitwerxIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  practiceId: Scalars['String'];
  /** This property is the same as siteId on Vetdata/Datapoint, for multi-location practices */
  locationId?: Maybe<Scalars['String']>;
  isMultiSite: Scalars['Boolean'];
  system: BitwerxSystem;
  /** This property references the PIMS PaymentType ID for writebacks */
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type BitwerxIntegrationCountAggregateOutputType = {
  __typename?: 'BitwerxIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  practiceId: Scalars['Int'];
  locationId: Scalars['Int'];
  isMultiSite: Scalars['Int'];
  system: Scalars['Int'];
  writebackPaymentTypeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BitwerxIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
};

export type BitwerxIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  system: BitwerxSystem;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutBitwerxInput>;
};

export type BitwerxIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  system: BitwerxSystem;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<BitwerxIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<BitwerxIntegrationWhereUniqueInput>;
};

export type BitwerxIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: BitwerxIntegrationWhereUniqueInput;
  create: BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type BitwerxIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  system: BitwerxSystem;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationMaxAggregateOutputType = {
  __typename?: 'BitwerxIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  system?: Maybe<BitwerxSystem>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
};

export type BitwerxIntegrationMinAggregateOutputType = {
  __typename?: 'BitwerxIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  system?: Maybe<BitwerxSystem>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
};

export enum BitwerxIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  PracticeId = 'practiceId',
  LocationId = 'locationId',
  WritebackPaymentTypeId = 'writebackPaymentTypeId'
}

export type BitwerxIntegrationOrderByRelevanceInput = {
  fields: Array<BitwerxIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type BitwerxIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
  _count?: Maybe<BitwerxIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<BitwerxIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<BitwerxIntegrationMinOrderByAggregateInput>;
};

export type BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<BitwerxIntegrationOrderByRelevanceInput>;
};

export type BitwerxIntegrationRelationFilter = {
  is?: Maybe<BitwerxIntegrationWhereInput>;
  isNot?: Maybe<BitwerxIntegrationWhereInput>;
};

export enum BitwerxIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PracticeId = 'practiceId',
  LocationId = 'locationId',
  IsMultiSite = 'isMultiSite',
  System = 'system',
  WritebackPaymentTypeId = 'writebackPaymentTypeId'
}

export type BitwerxIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BitwerxIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BitwerxIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BitwerxIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  practiceId?: Maybe<StringWithAggregatesFilter>;
  locationId?: Maybe<StringNullableWithAggregatesFilter>;
  isMultiSite?: Maybe<BoolWithAggregatesFilter>;
  system?: Maybe<EnumBitwerxSystemWithAggregatesFilter>;
  writebackPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type BitwerxIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  system: BitwerxSystem;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutBitwerxInput>;
};

export type BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  system: BitwerxSystem;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  system?: Maybe<BitwerxSystem>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutBitwerxNestedInput>;
};

export type BitwerxIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  system?: Maybe<BitwerxSystem>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  system?: Maybe<BitwerxSystem>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  system?: Maybe<BitwerxSystem>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutBitwerxNestedInput>;
};

export type BitwerxIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  system?: Maybe<BitwerxSystem>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  create?: Maybe<BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<BitwerxIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<BitwerxIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  update?: Maybe<BitwerxIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type BitwerxIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  system?: Maybe<BitwerxSystem>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: BitwerxIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type BitwerxIntegrationWhereInput = {
  AND?: Maybe<Array<BitwerxIntegrationWhereInput>>;
  OR?: Maybe<Array<BitwerxIntegrationWhereInput>>;
  NOT?: Maybe<Array<BitwerxIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  practiceId?: Maybe<StringFilter>;
  locationId?: Maybe<StringNullableFilter>;
  isMultiSite?: Maybe<BoolFilter>;
  system?: Maybe<EnumBitwerxSystemFilter>;
  writebackPaymentTypeId?: Maybe<StringNullableFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type BitwerxIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum BitwerxPaymentEndpoint {
  GetInvoicesForClient = 'GetInvoicesForClient',
  GetBalanceForClient = 'GetBalanceForClient'
}

export type BitwerxPaymentHealthcheckResult = {
  __typename?: 'BitwerxPaymentHealthcheckResult';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  bitwerxPaymentHealthcheckRunId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  endpoint: BitwerxPaymentEndpoint;
  responseTime: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  healthcheckRun: BitwerxPaymentHealthcheckRun;
  clinicPetParent: ClinicPetParent;
};

export type BitwerxPaymentHealthcheckResultAvgAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckResultAvgAggregateOutputType';
  responseTime?: Maybe<Scalars['Float']>;
  httpCode?: Maybe<Scalars['Float']>;
};

export type BitwerxPaymentHealthcheckResultAvgOrderByAggregateInput = {
  responseTime?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckResultCountAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckResultCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  bitwerxPaymentHealthcheckRunId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  endpoint: Scalars['Int'];
  responseTime: Scalars['Int'];
  error: Scalars['Int'];
  httpCode: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BitwerxPaymentHealthcheckResultCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  bitwerxPaymentHealthcheckRunId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  endpoint?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckResultCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  endpoint: BitwerxPaymentEndpoint;
  responseTime: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  healthcheckRun: BitwerxPaymentHealthcheckRunCreateNestedOneWithoutHealthcheckResultsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutBitwerxPaymentHealthcheckResultsInput;
};

export type BitwerxPaymentHealthcheckResultCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxPaymentHealthcheckRunId: Scalars['String'];
  endpoint: BitwerxPaymentEndpoint;
  responseTime: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultCreateManyClinicPetParentInputEnvelope = {
  data: Array<BitwerxPaymentHealthcheckResultCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentHealthcheckResultCreateManyHealthcheckRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  endpoint: BitwerxPaymentEndpoint;
  responseTime: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultCreateManyHealthcheckRunInputEnvelope = {
  data: Array<BitwerxPaymentHealthcheckResultCreateManyHealthcheckRunInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentHealthcheckResultCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxPaymentHealthcheckRunId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  endpoint: BitwerxPaymentEndpoint;
  responseTime: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
};

export type BitwerxPaymentHealthcheckResultCreateNestedManyWithoutHealthcheckRunInput = {
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutHealthcheckRunInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutHealthcheckRunInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyHealthcheckRunInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
};

export type BitwerxPaymentHealthcheckResultCreateOrConnectWithoutClinicPetParentInput = {
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
  create: BitwerxPaymentHealthcheckResultUncheckedCreateWithoutClinicPetParentInput;
};

export type BitwerxPaymentHealthcheckResultCreateOrConnectWithoutHealthcheckRunInput = {
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
  create: BitwerxPaymentHealthcheckResultUncheckedCreateWithoutHealthcheckRunInput;
};

export type BitwerxPaymentHealthcheckResultCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  endpoint: BitwerxPaymentEndpoint;
  responseTime: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  healthcheckRun: BitwerxPaymentHealthcheckRunCreateNestedOneWithoutHealthcheckResultsInput;
};

export type BitwerxPaymentHealthcheckResultCreateWithoutHealthcheckRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  endpoint: BitwerxPaymentEndpoint;
  responseTime: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutBitwerxPaymentHealthcheckResultsInput;
};

export type BitwerxPaymentHealthcheckResultListRelationFilter = {
  every?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
  some?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
  none?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
};

export type BitwerxPaymentHealthcheckResultMaxAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckResultMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxPaymentHealthcheckRunId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  responseTime?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  bitwerxPaymentHealthcheckRunId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  endpoint?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckResultMinAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckResultMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxPaymentHealthcheckRunId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  responseTime?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  bitwerxPaymentHealthcheckRunId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  endpoint?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckResultOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BitwerxPaymentHealthcheckResultOrderByRelevanceFieldEnum {
  Id = 'id',
  BitwerxPaymentHealthcheckRunId = 'bitwerxPaymentHealthcheckRunId',
  ClinicPetParentId = 'clinicPetParentId',
  Error = 'error'
}

export type BitwerxPaymentHealthcheckResultOrderByRelevanceInput = {
  fields: Array<BitwerxPaymentHealthcheckResultOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type BitwerxPaymentHealthcheckResultOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  bitwerxPaymentHealthcheckRunId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  endpoint?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  _count?: Maybe<BitwerxPaymentHealthcheckResultCountOrderByAggregateInput>;
  _avg?: Maybe<BitwerxPaymentHealthcheckResultAvgOrderByAggregateInput>;
  _max?: Maybe<BitwerxPaymentHealthcheckResultMaxOrderByAggregateInput>;
  _min?: Maybe<BitwerxPaymentHealthcheckResultMinOrderByAggregateInput>;
  _sum?: Maybe<BitwerxPaymentHealthcheckResultSumOrderByAggregateInput>;
};

export type BitwerxPaymentHealthcheckResultOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  bitwerxPaymentHealthcheckRunId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  endpoint?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  healthcheckRun?: Maybe<BitwerxPaymentHealthcheckRunOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<BitwerxPaymentHealthcheckResultOrderByRelevanceInput>;
};

export enum BitwerxPaymentHealthcheckResultScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  BitwerxPaymentHealthcheckRunId = 'bitwerxPaymentHealthcheckRunId',
  ClinicPetParentId = 'clinicPetParentId',
  Endpoint = 'endpoint',
  ResponseTime = 'responseTime',
  Error = 'error',
  HttpCode = 'httpCode'
}

export type BitwerxPaymentHealthcheckResultScalarWhereInput = {
  AND?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereInput>>;
  OR?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereInput>>;
  NOT?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  bitwerxPaymentHealthcheckRunId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  endpoint?: Maybe<EnumBitwerxPaymentEndpointFilter>;
  responseTime?: Maybe<IntFilter>;
  error?: Maybe<StringNullableFilter>;
  httpCode?: Maybe<IntNullableFilter>;
};

export type BitwerxPaymentHealthcheckResultScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  bitwerxPaymentHealthcheckRunId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  endpoint?: Maybe<EnumBitwerxPaymentEndpointWithAggregatesFilter>;
  responseTime?: Maybe<IntWithAggregatesFilter>;
  error?: Maybe<StringNullableWithAggregatesFilter>;
  httpCode?: Maybe<IntNullableWithAggregatesFilter>;
};

export type BitwerxPaymentHealthcheckResultSumAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckResultSumAggregateOutputType';
  responseTime?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultSumOrderByAggregateInput = {
  responseTime?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckResultUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxPaymentHealthcheckRunId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  endpoint: BitwerxPaymentEndpoint;
  responseTime: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
};

export type BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutHealthcheckRunInput = {
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutHealthcheckRunInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutHealthcheckRunInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyHealthcheckRunInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
};

export type BitwerxPaymentHealthcheckResultUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxPaymentHealthcheckRunId: Scalars['String'];
  endpoint: BitwerxPaymentEndpoint;
  responseTime: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedCreateWithoutHealthcheckRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  endpoint: BitwerxPaymentEndpoint;
  responseTime: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxPaymentHealthcheckRunId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  responseTime?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxPaymentHealthcheckRunId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  responseTime?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutBitwerxPaymentHealthcheckResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxPaymentHealthcheckRunId?: Maybe<Scalars['String']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  responseTime?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<BitwerxPaymentHealthcheckResultUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereInput>>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutHealthcheckResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  responseTime?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutHealthcheckRunNestedInput = {
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutHealthcheckRunInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutHealthcheckRunInput>>;
  upsert?: Maybe<Array<BitwerxPaymentHealthcheckResultUpsertWithWhereUniqueWithoutHealthcheckRunInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyHealthcheckRunInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateWithWhereUniqueWithoutHealthcheckRunInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateManyWithWhereWithoutHealthcheckRunInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereInput>>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxPaymentHealthcheckRunId?: Maybe<Scalars['String']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  responseTime?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateWithoutHealthcheckRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  responseTime?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  responseTime?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  healthcheckRun?: Maybe<BitwerxPaymentHealthcheckRunUpdateOneRequiredWithoutHealthcheckResultsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutBitwerxPaymentHealthcheckResultsNestedInput>;
};

export type BitwerxPaymentHealthcheckResultUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  responseTime?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: BitwerxPaymentHealthcheckResultScalarWhereInput;
  data: BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutBitwerxPaymentHealthcheckResultsInput;
};

export type BitwerxPaymentHealthcheckResultUpdateManyWithWhereWithoutHealthcheckRunInput = {
  where: BitwerxPaymentHealthcheckResultScalarWhereInput;
  data: BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutHealthcheckResultsInput;
};

export type BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<BitwerxPaymentHealthcheckResultUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereInput>>;
};

export type BitwerxPaymentHealthcheckResultUpdateManyWithoutHealthcheckRunNestedInput = {
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutHealthcheckRunInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutHealthcheckRunInput>>;
  upsert?: Maybe<Array<BitwerxPaymentHealthcheckResultUpsertWithWhereUniqueWithoutHealthcheckRunInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyHealthcheckRunInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateWithWhereUniqueWithoutHealthcheckRunInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateManyWithWhereWithoutHealthcheckRunInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereInput>>;
};

export type BitwerxPaymentHealthcheckResultUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
  data: BitwerxPaymentHealthcheckResultUncheckedUpdateWithoutClinicPetParentInput;
};

export type BitwerxPaymentHealthcheckResultUpdateWithWhereUniqueWithoutHealthcheckRunInput = {
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
  data: BitwerxPaymentHealthcheckResultUncheckedUpdateWithoutHealthcheckRunInput;
};

export type BitwerxPaymentHealthcheckResultUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  responseTime?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  healthcheckRun?: Maybe<BitwerxPaymentHealthcheckRunUpdateOneRequiredWithoutHealthcheckResultsNestedInput>;
};

export type BitwerxPaymentHealthcheckResultUpdateWithoutHealthcheckRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  responseTime?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutBitwerxPaymentHealthcheckResultsNestedInput>;
};

export type BitwerxPaymentHealthcheckResultUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
  update: BitwerxPaymentHealthcheckResultUncheckedUpdateWithoutClinicPetParentInput;
  create: BitwerxPaymentHealthcheckResultUncheckedCreateWithoutClinicPetParentInput;
};

export type BitwerxPaymentHealthcheckResultUpsertWithWhereUniqueWithoutHealthcheckRunInput = {
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
  update: BitwerxPaymentHealthcheckResultUncheckedUpdateWithoutHealthcheckRunInput;
  create: BitwerxPaymentHealthcheckResultUncheckedCreateWithoutHealthcheckRunInput;
};

export type BitwerxPaymentHealthcheckResultWhereInput = {
  AND?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereInput>>;
  OR?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereInput>>;
  NOT?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  bitwerxPaymentHealthcheckRunId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  endpoint?: Maybe<EnumBitwerxPaymentEndpointFilter>;
  responseTime?: Maybe<IntFilter>;
  error?: Maybe<StringNullableFilter>;
  httpCode?: Maybe<IntNullableFilter>;
  healthcheckRun?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
};

export type BitwerxPaymentHealthcheckResultWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BitwerxPaymentHealthcheckRun = {
  __typename?: 'BitwerxPaymentHealthcheckRun';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  bitwerxHealthcheckId: Scalars['String'];
  triggeredAt: Scalars['DateTime'];
  historicWeeksBack: Scalars['Int'];
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
  bitwerxHealthcheck: BitwerxHealthcheck;
  healthcheckResults: Array<BitwerxPaymentHealthcheckResult>;
  _count: BitwerxPaymentHealthcheckRunCountOutputType;
};


export type BitwerxPaymentHealthcheckRunHealthcheckResultsArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
  orderBy?: Maybe<BitwerxPaymentHealthcheckResultOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BitwerxPaymentHealthcheckResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BitwerxPaymentHealthcheckResultScalarFieldEnum>;
};

export type BitwerxPaymentHealthcheckRunAvgAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckRunAvgAggregateOutputType';
  historicWeeksBack?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
};

export type BitwerxPaymentHealthcheckRunAvgOrderByAggregateInput = {
  historicWeeksBack?: Maybe<SortOrder>;
  averageResponseTime?: Maybe<SortOrder>;
  averageRequestCount?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckRunCountAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckRunCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  bitwerxHealthcheckId: Scalars['Int'];
  triggeredAt: Scalars['Int'];
  historicWeeksBack: Scalars['Int'];
  status: Scalars['Int'];
  averageResponseTime: Scalars['Int'];
  averageRequestCount: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BitwerxPaymentHealthcheckRunCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  bitwerxHealthcheckId?: Maybe<SortOrder>;
  triggeredAt?: Maybe<SortOrder>;
  historicWeeksBack?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  averageResponseTime?: Maybe<SortOrder>;
  averageRequestCount?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckRunCountOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckRunCountOutputType';
  healthcheckResults: Scalars['Int'];
};

export type BitwerxPaymentHealthcheckRunCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggeredAt: Scalars['DateTime'];
  historicWeeksBack: Scalars['Int'];
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
  bitwerxHealthcheck: BitwerxHealthcheckCreateNestedOneWithoutPaymentHealthcheckRunsInput;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutHealthcheckRunInput>;
};

export type BitwerxPaymentHealthcheckRunCreateManyBitwerxHealthcheckInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggeredAt: Scalars['DateTime'];
  historicWeeksBack: Scalars['Int'];
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
};

export type BitwerxPaymentHealthcheckRunCreateManyBitwerxHealthcheckInputEnvelope = {
  data: Array<BitwerxPaymentHealthcheckRunCreateManyBitwerxHealthcheckInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentHealthcheckRunCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxHealthcheckId: Scalars['String'];
  triggeredAt: Scalars['DateTime'];
  historicWeeksBack: Scalars['Int'];
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
};

export type BitwerxPaymentHealthcheckRunCreateNestedManyWithoutBitwerxHealthcheckInput = {
  create?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateWithoutBitwerxHealthcheckInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateOrConnectWithoutBitwerxHealthcheckInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckRunCreateManyBitwerxHealthcheckInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
};

export type BitwerxPaymentHealthcheckRunCreateNestedOneWithoutHealthcheckResultsInput = {
  create?: Maybe<BitwerxPaymentHealthcheckRunUncheckedCreateWithoutHealthcheckResultsInput>;
  connectOrCreate?: Maybe<BitwerxPaymentHealthcheckRunCreateOrConnectWithoutHealthcheckResultsInput>;
  connect?: Maybe<BitwerxPaymentHealthcheckRunWhereUniqueInput>;
};

export type BitwerxPaymentHealthcheckRunCreateOrConnectWithoutBitwerxHealthcheckInput = {
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
  create: BitwerxPaymentHealthcheckRunUncheckedCreateWithoutBitwerxHealthcheckInput;
};

export type BitwerxPaymentHealthcheckRunCreateOrConnectWithoutHealthcheckResultsInput = {
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
  create: BitwerxPaymentHealthcheckRunUncheckedCreateWithoutHealthcheckResultsInput;
};

export type BitwerxPaymentHealthcheckRunCreateWithoutBitwerxHealthcheckInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggeredAt: Scalars['DateTime'];
  historicWeeksBack: Scalars['Int'];
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutHealthcheckRunInput>;
};

export type BitwerxPaymentHealthcheckRunCreateWithoutHealthcheckResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggeredAt: Scalars['DateTime'];
  historicWeeksBack: Scalars['Int'];
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
  bitwerxHealthcheck: BitwerxHealthcheckCreateNestedOneWithoutPaymentHealthcheckRunsInput;
};

export type BitwerxPaymentHealthcheckRunListRelationFilter = {
  every?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
  some?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
  none?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
};

export type BitwerxPaymentHealthcheckRunMaxAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckRunMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxHealthcheckId?: Maybe<Scalars['String']>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
};

export type BitwerxPaymentHealthcheckRunMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  bitwerxHealthcheckId?: Maybe<SortOrder>;
  triggeredAt?: Maybe<SortOrder>;
  historicWeeksBack?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  averageResponseTime?: Maybe<SortOrder>;
  averageRequestCount?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckRunMinAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckRunMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxHealthcheckId?: Maybe<Scalars['String']>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
};

export type BitwerxPaymentHealthcheckRunMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  bitwerxHealthcheckId?: Maybe<SortOrder>;
  triggeredAt?: Maybe<SortOrder>;
  historicWeeksBack?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  averageResponseTime?: Maybe<SortOrder>;
  averageRequestCount?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckRunOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BitwerxPaymentHealthcheckRunOrderByRelevanceFieldEnum {
  Id = 'id',
  BitwerxHealthcheckId = 'bitwerxHealthcheckId'
}

export type BitwerxPaymentHealthcheckRunOrderByRelevanceInput = {
  fields: Array<BitwerxPaymentHealthcheckRunOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type BitwerxPaymentHealthcheckRunOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  bitwerxHealthcheckId?: Maybe<SortOrder>;
  triggeredAt?: Maybe<SortOrder>;
  historicWeeksBack?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  averageResponseTime?: Maybe<SortOrder>;
  averageRequestCount?: Maybe<SortOrder>;
  _count?: Maybe<BitwerxPaymentHealthcheckRunCountOrderByAggregateInput>;
  _avg?: Maybe<BitwerxPaymentHealthcheckRunAvgOrderByAggregateInput>;
  _max?: Maybe<BitwerxPaymentHealthcheckRunMaxOrderByAggregateInput>;
  _min?: Maybe<BitwerxPaymentHealthcheckRunMinOrderByAggregateInput>;
  _sum?: Maybe<BitwerxPaymentHealthcheckRunSumOrderByAggregateInput>;
};

export type BitwerxPaymentHealthcheckRunOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  bitwerxHealthcheckId?: Maybe<SortOrder>;
  triggeredAt?: Maybe<SortOrder>;
  historicWeeksBack?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  averageResponseTime?: Maybe<SortOrder>;
  averageRequestCount?: Maybe<SortOrder>;
  bitwerxHealthcheck?: Maybe<BitwerxHealthcheckOrderByWithRelationAndSearchRelevanceInput>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultOrderByRelationAggregateInput>;
  _relevance?: Maybe<BitwerxPaymentHealthcheckRunOrderByRelevanceInput>;
};

export type BitwerxPaymentHealthcheckRunRelationFilter = {
  is?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
  isNot?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
};

export enum BitwerxPaymentHealthcheckRunScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  BitwerxHealthcheckId = 'bitwerxHealthcheckId',
  TriggeredAt = 'triggeredAt',
  HistoricWeeksBack = 'historicWeeksBack',
  Status = 'status',
  AverageResponseTime = 'averageResponseTime',
  AverageRequestCount = 'averageRequestCount'
}

export type BitwerxPaymentHealthcheckRunScalarWhereInput = {
  AND?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereInput>>;
  OR?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereInput>>;
  NOT?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  bitwerxHealthcheckId?: Maybe<StringFilter>;
  triggeredAt?: Maybe<DateTimeFilter>;
  historicWeeksBack?: Maybe<IntFilter>;
  status?: Maybe<EnumBitwerxHealthcheckRunStatusNullableFilter>;
  averageResponseTime?: Maybe<FloatNullableFilter>;
  averageRequestCount?: Maybe<FloatNullableFilter>;
};

export type BitwerxPaymentHealthcheckRunScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  bitwerxHealthcheckId?: Maybe<StringWithAggregatesFilter>;
  triggeredAt?: Maybe<DateTimeWithAggregatesFilter>;
  historicWeeksBack?: Maybe<IntWithAggregatesFilter>;
  status?: Maybe<EnumBitwerxHealthcheckRunStatusNullableWithAggregatesFilter>;
  averageResponseTime?: Maybe<FloatNullableWithAggregatesFilter>;
  averageRequestCount?: Maybe<FloatNullableWithAggregatesFilter>;
};

export type BitwerxPaymentHealthcheckRunSumAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckRunSumAggregateOutputType';
  historicWeeksBack?: Maybe<Scalars['Int']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
};

export type BitwerxPaymentHealthcheckRunSumOrderByAggregateInput = {
  historicWeeksBack?: Maybe<SortOrder>;
  averageResponseTime?: Maybe<SortOrder>;
  averageRequestCount?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckRunUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxHealthcheckId: Scalars['String'];
  triggeredAt: Scalars['DateTime'];
  historicWeeksBack: Scalars['Int'];
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutHealthcheckRunInput>;
};

export type BitwerxPaymentHealthcheckRunUncheckedCreateNestedManyWithoutBitwerxHealthcheckInput = {
  create?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateWithoutBitwerxHealthcheckInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateOrConnectWithoutBitwerxHealthcheckInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckRunCreateManyBitwerxHealthcheckInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
};

export type BitwerxPaymentHealthcheckRunUncheckedCreateWithoutBitwerxHealthcheckInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggeredAt: Scalars['DateTime'];
  historicWeeksBack: Scalars['Int'];
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutHealthcheckRunInput>;
};

export type BitwerxPaymentHealthcheckRunUncheckedCreateWithoutHealthcheckResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxHealthcheckId: Scalars['String'];
  triggeredAt: Scalars['DateTime'];
  historicWeeksBack: Scalars['Int'];
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
};

export type BitwerxPaymentHealthcheckRunUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxHealthcheckId?: Maybe<Scalars['String']>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutHealthcheckRunNestedInput>;
};

export type BitwerxPaymentHealthcheckRunUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxHealthcheckId?: Maybe<Scalars['String']>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
};

export type BitwerxPaymentHealthcheckRunUncheckedUpdateManyWithoutBitwerxHealthcheckNestedInput = {
  create?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateWithoutBitwerxHealthcheckInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateOrConnectWithoutBitwerxHealthcheckInput>>;
  upsert?: Maybe<Array<BitwerxPaymentHealthcheckRunUpsertWithWhereUniqueWithoutBitwerxHealthcheckInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckRunCreateManyBitwerxHealthcheckInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentHealthcheckRunUpdateWithWhereUniqueWithoutBitwerxHealthcheckInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentHealthcheckRunUpdateManyWithWhereWithoutBitwerxHealthcheckInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereInput>>;
};

export type BitwerxPaymentHealthcheckRunUncheckedUpdateManyWithoutPaymentHealthcheckRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
};

export type BitwerxPaymentHealthcheckRunUncheckedUpdateWithoutBitwerxHealthcheckInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutHealthcheckRunNestedInput>;
};

export type BitwerxPaymentHealthcheckRunUncheckedUpdateWithoutHealthcheckResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bitwerxHealthcheckId?: Maybe<Scalars['String']>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
};

export type BitwerxPaymentHealthcheckRunUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
  bitwerxHealthcheck?: Maybe<BitwerxHealthcheckUpdateOneRequiredWithoutPaymentHealthcheckRunsNestedInput>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutHealthcheckRunNestedInput>;
};

export type BitwerxPaymentHealthcheckRunUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
};

export type BitwerxPaymentHealthcheckRunUpdateManyWithWhereWithoutBitwerxHealthcheckInput = {
  where: BitwerxPaymentHealthcheckRunScalarWhereInput;
  data: BitwerxPaymentHealthcheckRunUncheckedUpdateManyWithoutPaymentHealthcheckRunsInput;
};

export type BitwerxPaymentHealthcheckRunUpdateManyWithoutBitwerxHealthcheckNestedInput = {
  create?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateWithoutBitwerxHealthcheckInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateOrConnectWithoutBitwerxHealthcheckInput>>;
  upsert?: Maybe<Array<BitwerxPaymentHealthcheckRunUpsertWithWhereUniqueWithoutBitwerxHealthcheckInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckRunCreateManyBitwerxHealthcheckInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentHealthcheckRunUpdateWithWhereUniqueWithoutBitwerxHealthcheckInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentHealthcheckRunUpdateManyWithWhereWithoutBitwerxHealthcheckInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereInput>>;
};

export type BitwerxPaymentHealthcheckRunUpdateOneRequiredWithoutHealthcheckResultsNestedInput = {
  create?: Maybe<BitwerxPaymentHealthcheckRunUncheckedCreateWithoutHealthcheckResultsInput>;
  connectOrCreate?: Maybe<BitwerxPaymentHealthcheckRunCreateOrConnectWithoutHealthcheckResultsInput>;
  upsert?: Maybe<BitwerxPaymentHealthcheckRunUpsertWithoutHealthcheckResultsInput>;
  connect?: Maybe<BitwerxPaymentHealthcheckRunWhereUniqueInput>;
  update?: Maybe<BitwerxPaymentHealthcheckRunUncheckedUpdateWithoutHealthcheckResultsInput>;
};

export type BitwerxPaymentHealthcheckRunUpdateWithWhereUniqueWithoutBitwerxHealthcheckInput = {
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
  data: BitwerxPaymentHealthcheckRunUncheckedUpdateWithoutBitwerxHealthcheckInput;
};

export type BitwerxPaymentHealthcheckRunUpdateWithoutBitwerxHealthcheckInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutHealthcheckRunNestedInput>;
};

export type BitwerxPaymentHealthcheckRunUpdateWithoutHealthcheckResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  averageRequestCount?: Maybe<Scalars['Float']>;
  bitwerxHealthcheck?: Maybe<BitwerxHealthcheckUpdateOneRequiredWithoutPaymentHealthcheckRunsNestedInput>;
};

export type BitwerxPaymentHealthcheckRunUpsertWithWhereUniqueWithoutBitwerxHealthcheckInput = {
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
  update: BitwerxPaymentHealthcheckRunUncheckedUpdateWithoutBitwerxHealthcheckInput;
  create: BitwerxPaymentHealthcheckRunUncheckedCreateWithoutBitwerxHealthcheckInput;
};

export type BitwerxPaymentHealthcheckRunUpsertWithoutHealthcheckResultsInput = {
  update: BitwerxPaymentHealthcheckRunUncheckedUpdateWithoutHealthcheckResultsInput;
  create: BitwerxPaymentHealthcheckRunUncheckedCreateWithoutHealthcheckResultsInput;
};

export type BitwerxPaymentHealthcheckRunWhereInput = {
  AND?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereInput>>;
  OR?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereInput>>;
  NOT?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  bitwerxHealthcheckId?: Maybe<StringFilter>;
  triggeredAt?: Maybe<DateTimeFilter>;
  historicWeeksBack?: Maybe<IntFilter>;
  status?: Maybe<EnumBitwerxHealthcheckRunStatusNullableFilter>;
  averageResponseTime?: Maybe<FloatNullableFilter>;
  averageRequestCount?: Maybe<FloatNullableFilter>;
  bitwerxHealthcheck?: Maybe<BitwerxHealthcheckWhereInput>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultListRelationFilter>;
};

export type BitwerxPaymentHealthcheckRunWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BitwerxPaymentWritebackConfiguration = {
  __typename?: 'BitwerxPaymentWritebackConfiguration';
  id: Scalars['String'];
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  /** global enablement of writebacks */
  isEnabled: Scalars['Boolean'];
  /** enablement of payment writebacks */
  paymentsEnabled: Scalars['Boolean'];
  /** enablement of Care discount writebacks */
  careEnabled: Scalars['Boolean'];
  /** enablement of retries */
  retriesEnabled: Scalars['Boolean'];
  /** enablement of retries for balance writebacks */
  balanceRetriesEnabled: Scalars['Boolean'];
  /** are we writing back payments for amounts that are manually entered (replacing "Add credit to account" input) */
  writebackManualPayment: Scalars['Boolean'];
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  integration: ClinicPimsIntegration;
  enteredBy?: Maybe<ClinicEmployee>;
  defaultPimsPaymentType?: Maybe<PimsPaymentType>;
  visaPimsPaymentType?: Maybe<PimsPaymentType>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentType>;
  amexPimsPaymentType?: Maybe<PimsPaymentType>;
  discoverPimsPaymentType?: Maybe<PimsPaymentType>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentType>;
};

export type BitwerxPaymentWritebackConfigurationCountAggregateOutputType = {
  __typename?: 'BitwerxPaymentWritebackConfigurationCountAggregateOutputType';
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  enteredById: Scalars['Int'];
  isEnabled: Scalars['Int'];
  paymentsEnabled: Scalars['Int'];
  careEnabled: Scalars['Int'];
  retriesEnabled: Scalars['Int'];
  balanceRetriesEnabled: Scalars['Int'];
  writebackManualPayment: Scalars['Int'];
  defaultPimsPaymentTypeId: Scalars['Int'];
  visaPimsPaymentTypeId: Scalars['Int'];
  mastercardPimsPaymentTypeId: Scalars['Int'];
  amexPimsPaymentTypeId: Scalars['Int'];
  discoverPimsPaymentTypeId: Scalars['Int'];
  careDiscountPimsPaymentTypeId: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BitwerxPaymentWritebackConfigurationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  paymentsEnabled?: Maybe<SortOrder>;
  careEnabled?: Maybe<SortOrder>;
  retriesEnabled?: Maybe<SortOrder>;
  balanceRetriesEnabled?: Maybe<SortOrder>;
  writebackManualPayment?: Maybe<SortOrder>;
  defaultPimsPaymentTypeId?: Maybe<SortOrder>;
  visaPimsPaymentTypeId?: Maybe<SortOrder>;
  mastercardPimsPaymentTypeId?: Maybe<SortOrder>;
  amexPimsPaymentTypeId?: Maybe<SortOrder>;
  discoverPimsPaymentTypeId?: Maybe<SortOrder>;
  careDiscountPimsPaymentTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxPaymentWritebackConfigurationCreateInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyAmexPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyAmexPimsPaymentTypeInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyAmexPimsPaymentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyCareDiscountPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyCareDiscountPimsPaymentTypeInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyCareDiscountPimsPaymentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyDefaultPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyDefaultPimsPaymentTypeInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyDefaultPimsPaymentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyDiscoverPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyDiscoverPimsPaymentTypeInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyDiscoverPimsPaymentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyEnteredByInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyEnteredByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyIntegrationInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyMastercardPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyMastercardPimsPaymentTypeInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyMastercardPimsPaymentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyVisaPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyVisaPimsPaymentTypeInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyVisaPimsPaymentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutAmexPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutAmexPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyAmexPimsPaymentTypeInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutCareDiscountPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutCareDiscountPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyCareDiscountPimsPaymentTypeInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDefaultPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDefaultPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDefaultPimsPaymentTypeInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDiscoverPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDiscoverPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDiscoverPimsPaymentTypeInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutEnteredByInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutEnteredByInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyEnteredByInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutMastercardPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutMastercardPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyMastercardPimsPaymentTypeInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutVisaPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutVisaPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyVisaPimsPaymentTypeInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutAmexPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutAmexPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutCareDiscountPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutCareDiscountPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDefaultPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutDefaultPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDiscoverPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutDiscoverPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutEnteredByInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutEnteredByInput;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutIntegrationInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutIntegrationInput;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutMastercardPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutMastercardPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutVisaPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutVisaPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutAmexPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutCareDiscountPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutDefaultPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutDiscoverPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutMastercardPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutVisaPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
};

export type BitwerxPaymentWritebackConfigurationListRelationFilter = {
  every?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
  some?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
  none?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
};

export type BitwerxPaymentWritebackConfigurationMaxAggregateOutputType = {
  __typename?: 'BitwerxPaymentWritebackConfigurationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  paymentsEnabled?: Maybe<SortOrder>;
  careEnabled?: Maybe<SortOrder>;
  retriesEnabled?: Maybe<SortOrder>;
  balanceRetriesEnabled?: Maybe<SortOrder>;
  writebackManualPayment?: Maybe<SortOrder>;
  defaultPimsPaymentTypeId?: Maybe<SortOrder>;
  visaPimsPaymentTypeId?: Maybe<SortOrder>;
  mastercardPimsPaymentTypeId?: Maybe<SortOrder>;
  amexPimsPaymentTypeId?: Maybe<SortOrder>;
  discoverPimsPaymentTypeId?: Maybe<SortOrder>;
  careDiscountPimsPaymentTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxPaymentWritebackConfigurationMinAggregateOutputType = {
  __typename?: 'BitwerxPaymentWritebackConfigurationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  paymentsEnabled?: Maybe<SortOrder>;
  careEnabled?: Maybe<SortOrder>;
  retriesEnabled?: Maybe<SortOrder>;
  balanceRetriesEnabled?: Maybe<SortOrder>;
  writebackManualPayment?: Maybe<SortOrder>;
  defaultPimsPaymentTypeId?: Maybe<SortOrder>;
  visaPimsPaymentTypeId?: Maybe<SortOrder>;
  mastercardPimsPaymentTypeId?: Maybe<SortOrder>;
  amexPimsPaymentTypeId?: Maybe<SortOrder>;
  discoverPimsPaymentTypeId?: Maybe<SortOrder>;
  careDiscountPimsPaymentTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BitwerxPaymentWritebackConfigurationOrderByRelevanceFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  EnteredById = 'enteredById',
  DefaultPimsPaymentTypeId = 'defaultPimsPaymentTypeId',
  VisaPimsPaymentTypeId = 'visaPimsPaymentTypeId',
  MastercardPimsPaymentTypeId = 'mastercardPimsPaymentTypeId',
  AmexPimsPaymentTypeId = 'amexPimsPaymentTypeId',
  DiscoverPimsPaymentTypeId = 'discoverPimsPaymentTypeId',
  CareDiscountPimsPaymentTypeId = 'careDiscountPimsPaymentTypeId'
}

export type BitwerxPaymentWritebackConfigurationOrderByRelevanceInput = {
  fields: Array<BitwerxPaymentWritebackConfigurationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type BitwerxPaymentWritebackConfigurationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  paymentsEnabled?: Maybe<SortOrder>;
  careEnabled?: Maybe<SortOrder>;
  retriesEnabled?: Maybe<SortOrder>;
  balanceRetriesEnabled?: Maybe<SortOrder>;
  writebackManualPayment?: Maybe<SortOrder>;
  defaultPimsPaymentTypeId?: Maybe<SortOrder>;
  visaPimsPaymentTypeId?: Maybe<SortOrder>;
  mastercardPimsPaymentTypeId?: Maybe<SortOrder>;
  amexPimsPaymentTypeId?: Maybe<SortOrder>;
  discoverPimsPaymentTypeId?: Maybe<SortOrder>;
  careDiscountPimsPaymentTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<BitwerxPaymentWritebackConfigurationCountOrderByAggregateInput>;
  _max?: Maybe<BitwerxPaymentWritebackConfigurationMaxOrderByAggregateInput>;
  _min?: Maybe<BitwerxPaymentWritebackConfigurationMinOrderByAggregateInput>;
};

export type BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  paymentsEnabled?: Maybe<SortOrder>;
  careEnabled?: Maybe<SortOrder>;
  retriesEnabled?: Maybe<SortOrder>;
  balanceRetriesEnabled?: Maybe<SortOrder>;
  writebackManualPayment?: Maybe<SortOrder>;
  defaultPimsPaymentTypeId?: Maybe<SortOrder>;
  visaPimsPaymentTypeId?: Maybe<SortOrder>;
  mastercardPimsPaymentTypeId?: Maybe<SortOrder>;
  amexPimsPaymentTypeId?: Maybe<SortOrder>;
  discoverPimsPaymentTypeId?: Maybe<SortOrder>;
  careDiscountPimsPaymentTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  enteredBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelevanceInput>;
};

export enum BitwerxPaymentWritebackConfigurationScalarFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  EnteredById = 'enteredById',
  IsEnabled = 'isEnabled',
  PaymentsEnabled = 'paymentsEnabled',
  CareEnabled = 'careEnabled',
  RetriesEnabled = 'retriesEnabled',
  BalanceRetriesEnabled = 'balanceRetriesEnabled',
  WritebackManualPayment = 'writebackManualPayment',
  DefaultPimsPaymentTypeId = 'defaultPimsPaymentTypeId',
  VisaPimsPaymentTypeId = 'visaPimsPaymentTypeId',
  MastercardPimsPaymentTypeId = 'mastercardPimsPaymentTypeId',
  AmexPimsPaymentTypeId = 'amexPimsPaymentTypeId',
  DiscoverPimsPaymentTypeId = 'discoverPimsPaymentTypeId',
  CareDiscountPimsPaymentTypeId = 'careDiscountPimsPaymentTypeId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type BitwerxPaymentWritebackConfigurationScalarWhereInput = {
  AND?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  OR?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  NOT?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  enteredById?: Maybe<StringNullableFilter>;
  isEnabled?: Maybe<BoolFilter>;
  paymentsEnabled?: Maybe<BoolFilter>;
  careEnabled?: Maybe<BoolFilter>;
  retriesEnabled?: Maybe<BoolFilter>;
  balanceRetriesEnabled?: Maybe<BoolFilter>;
  writebackManualPayment?: Maybe<BoolFilter>;
  defaultPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  visaPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  mastercardPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  amexPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  discoverPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  careDiscountPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BitwerxPaymentWritebackConfigurationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  enteredById?: Maybe<StringNullableWithAggregatesFilter>;
  isEnabled?: Maybe<BoolWithAggregatesFilter>;
  paymentsEnabled?: Maybe<BoolWithAggregatesFilter>;
  careEnabled?: Maybe<BoolWithAggregatesFilter>;
  retriesEnabled?: Maybe<BoolWithAggregatesFilter>;
  balanceRetriesEnabled?: Maybe<BoolWithAggregatesFilter>;
  writebackManualPayment?: Maybe<BoolWithAggregatesFilter>;
  defaultPimsPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  visaPimsPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  mastercardPimsPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  amexPimsPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  discoverPimsPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  careDiscountPimsPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutAmexPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutAmexPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyAmexPimsPaymentTypeInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutCareDiscountPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutCareDiscountPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyCareDiscountPimsPaymentTypeInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDefaultPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDefaultPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDefaultPimsPaymentTypeInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDiscoverPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDiscoverPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDiscoverPimsPaymentTypeInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutEnteredByInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutEnteredByInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyEnteredByInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutMastercardPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutMastercardPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyMastercardPimsPaymentTypeInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutVisaPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutVisaPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyVisaPimsPaymentTypeInputEnvelope>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutAmexPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutCareDiscountPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutDefaultPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutDiscoverPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutMastercardPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutVisaPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutAmexPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutAmexPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutAmexPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyAmexPimsPaymentTypeInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutAmexPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutAmexPimsPaymentTypeInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutCareDiscountPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutCareDiscountPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutCareDiscountPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyCareDiscountPimsPaymentTypeInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutCareDiscountPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutCareDiscountPimsPaymentTypeInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDefaultPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDefaultPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutDefaultPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDefaultPimsPaymentTypeInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutDefaultPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutDefaultPimsPaymentTypeInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDiscoverPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDiscoverPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutDiscoverPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDiscoverPimsPaymentTypeInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutDiscoverPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutDiscoverPimsPaymentTypeInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutEnteredByInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutEnteredByInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutEnteredByInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyEnteredByInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutEnteredByInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutEnteredByInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutMastercardPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutMastercardPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutMastercardPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyMastercardPimsPaymentTypeInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutMastercardPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutMastercardPimsPaymentTypeInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutVisaPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutVisaPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutVisaPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyVisaPimsPaymentTypeInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutVisaPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutVisaPimsPaymentTypeInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutAmexPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutCareDiscountPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutDefaultPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutDiscoverPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutMastercardPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutVisaPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutAmexPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexBitwerxPaymentWritebackConfigurationsInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutCareDiscountPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutDefaultPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultBitwerxPaymentWritebackConfigurationsInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutDiscoverPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutEnteredByInput = {
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutBitwerxPaymentWritebackConfigurationsInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutIntegrationInput = {
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutBitwerxPaymentWritebackConfigurationsInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutMastercardPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardBitwerxPaymentWritebackConfigurationsInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutVisaPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaBitwerxPaymentWritebackConfigurationsInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutAmexPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutAmexPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutAmexPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyAmexPimsPaymentTypeInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutAmexPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutAmexPimsPaymentTypeInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutCareDiscountPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutCareDiscountPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutCareDiscountPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyCareDiscountPimsPaymentTypeInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutCareDiscountPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutCareDiscountPimsPaymentTypeInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDefaultPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDefaultPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutDefaultPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDefaultPimsPaymentTypeInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutDefaultPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutDefaultPimsPaymentTypeInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDiscoverPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDiscoverPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutDiscoverPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDiscoverPimsPaymentTypeInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutDiscoverPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutDiscoverPimsPaymentTypeInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutEnteredByInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutEnteredByInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutEnteredByInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyEnteredByInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutEnteredByInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutEnteredByInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutMastercardPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutMastercardPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutMastercardPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyMastercardPimsPaymentTypeInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutMastercardPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutMastercardPimsPaymentTypeInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput = {
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutVisaPimsPaymentTypeInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutVisaPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutVisaPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyVisaPimsPaymentTypeInputEnvelope>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutVisaPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutVisaPimsPaymentTypeInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutAmexPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutAmexPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutCareDiscountPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutCareDiscountPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutDefaultPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutDefaultPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutDiscoverPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutDiscoverPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutEnteredByInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutEnteredByInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutIntegrationInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutMastercardPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutMastercardPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutVisaPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutVisaPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutAmexPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutCareDiscountPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutDefaultPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutDiscoverPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutMastercardPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutVisaPimsPaymentTypeInput = {
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutAmexPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutAmexPimsPaymentTypeInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutAmexPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutCareDiscountPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutCareDiscountPimsPaymentTypeInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutCareDiscountPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutDefaultPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutDefaultPimsPaymentTypeInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutDefaultPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutDiscoverPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutDiscoverPimsPaymentTypeInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutDiscoverPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutEnteredByInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutEnteredByInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutEnteredByInput;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutIntegrationInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutIntegrationInput;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutMastercardPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutMastercardPimsPaymentTypeInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutMastercardPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutVisaPimsPaymentTypeInput = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutVisaPimsPaymentTypeInput;
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutVisaPimsPaymentTypeInput;
};

export type BitwerxPaymentWritebackConfigurationWhereInput = {
  AND?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereInput>>;
  OR?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereInput>>;
  NOT?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereInput>>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  enteredById?: Maybe<StringNullableFilter>;
  isEnabled?: Maybe<BoolFilter>;
  paymentsEnabled?: Maybe<BoolFilter>;
  careEnabled?: Maybe<BoolFilter>;
  retriesEnabled?: Maybe<BoolFilter>;
  balanceRetriesEnabled?: Maybe<BoolFilter>;
  writebackManualPayment?: Maybe<BoolFilter>;
  defaultPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  visaPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  mastercardPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  amexPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  discoverPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  careDiscountPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  enteredBy?: Maybe<ClinicEmployeeWhereInput>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeWhereInput>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeWhereInput>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeWhereInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeWhereInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeWhereInput>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeWhereInput>;
};

export type BitwerxPaymentWritebackConfigurationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
};

export enum BitwerxSystem {
  Cornerstone = 'Cornerstone',
  Impromed = 'Impromed',
  Avimark = 'Avimark'
}

export type BoardFilterColumn = {
  __typename?: 'BoardFilterColumn';
  id: Scalars['String'];
  type: BoardFilterColumnType;
  userBoardFilterId: Scalars['String'];
  channelStatusId: Scalars['String'];
  clinicId: Scalars['String'];
  clinic: Clinic;
  userBoardFilter?: Maybe<UserBoardFilter>;
  channelStatus?: Maybe<ChannelStatus>;
};

export type BoardFilterColumnCountAggregateOutputType = {
  __typename?: 'BoardFilterColumnCountAggregateOutputType';
  id: Scalars['Int'];
  type: Scalars['Int'];
  userBoardFilterId: Scalars['Int'];
  channelStatusId: Scalars['Int'];
  clinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BoardFilterColumnCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type BoardFilterColumnCreateInput = {
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  clinic: ClinicCreateNestedOneWithoutUserBoardFiltersColumnsInput;
  userBoardFilter?: Maybe<UserBoardFilterCreateNestedOneWithoutColumnsInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutBoardFilterColumnsInput>;
};

export type BoardFilterColumnCreateManyChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilterId: Scalars['String'];
  clinicId: Scalars['String'];
};

export type BoardFilterColumnCreateManyChannelStatusInputEnvelope = {
  data: Array<BoardFilterColumnCreateManyChannelStatusInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BoardFilterColumnCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilterId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type BoardFilterColumnCreateManyClinicInputEnvelope = {
  data: Array<BoardFilterColumnCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BoardFilterColumnCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilterId: Scalars['String'];
  channelStatusId: Scalars['String'];
  clinicId: Scalars['String'];
};

export type BoardFilterColumnCreateManyUserBoardFilterInput = {
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  channelStatusId: Scalars['String'];
  clinicId: Scalars['String'];
};

export type BoardFilterColumnCreateManyUserBoardFilterInputEnvelope = {
  data: Array<BoardFilterColumnCreateManyUserBoardFilterInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BoardFilterColumnCreateNestedManyWithoutChannelStatusInput = {
  create?: Maybe<Array<BoardFilterColumnCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutChannelStatusInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyChannelStatusInputEnvelope>;
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
};

export type BoardFilterColumnCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<BoardFilterColumnCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
};

export type BoardFilterColumnCreateNestedManyWithoutUserBoardFilterInput = {
  create?: Maybe<Array<BoardFilterColumnCreateWithoutUserBoardFilterInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutUserBoardFilterInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyUserBoardFilterInputEnvelope>;
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
};

export type BoardFilterColumnCreateOrConnectWithoutChannelStatusInput = {
  where: BoardFilterColumnWhereUniqueInput;
  create: BoardFilterColumnUncheckedCreateWithoutChannelStatusInput;
};

export type BoardFilterColumnCreateOrConnectWithoutClinicInput = {
  where: BoardFilterColumnWhereUniqueInput;
  create: BoardFilterColumnUncheckedCreateWithoutClinicInput;
};

export type BoardFilterColumnCreateOrConnectWithoutUserBoardFilterInput = {
  where: BoardFilterColumnWhereUniqueInput;
  create: BoardFilterColumnUncheckedCreateWithoutUserBoardFilterInput;
};

export type BoardFilterColumnCreateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  clinic: ClinicCreateNestedOneWithoutUserBoardFiltersColumnsInput;
  userBoardFilter?: Maybe<UserBoardFilterCreateNestedOneWithoutColumnsInput>;
};

export type BoardFilterColumnCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilter?: Maybe<UserBoardFilterCreateNestedOneWithoutColumnsInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutBoardFilterColumnsInput>;
};

export type BoardFilterColumnCreateWithoutUserBoardFilterInput = {
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  clinic: ClinicCreateNestedOneWithoutUserBoardFiltersColumnsInput;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutBoardFilterColumnsInput>;
};

export type BoardFilterColumnListRelationFilter = {
  every?: Maybe<BoardFilterColumnWhereInput>;
  some?: Maybe<BoardFilterColumnWhereInput>;
  none?: Maybe<BoardFilterColumnWhereInput>;
};

export type BoardFilterColumnMaxAggregateOutputType = {
  __typename?: 'BoardFilterColumnMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type BoardFilterColumnMinAggregateOutputType = {
  __typename?: 'BoardFilterColumnMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type BoardFilterColumnOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BoardFilterColumnOrderByRelevanceFieldEnum {
  Id = 'id',
  UserBoardFilterId = 'userBoardFilterId',
  ChannelStatusId = 'channelStatusId',
  ClinicId = 'clinicId'
}

export type BoardFilterColumnOrderByRelevanceInput = {
  fields: Array<BoardFilterColumnOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type BoardFilterColumnOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  _count?: Maybe<BoardFilterColumnCountOrderByAggregateInput>;
  _max?: Maybe<BoardFilterColumnMaxOrderByAggregateInput>;
  _min?: Maybe<BoardFilterColumnMinOrderByAggregateInput>;
};

export type BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  userBoardFilter?: Maybe<UserBoardFilterOrderByWithRelationAndSearchRelevanceInput>;
  channelStatus?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<BoardFilterColumnOrderByRelevanceInput>;
};

export enum BoardFilterColumnScalarFieldEnum {
  Id = 'id',
  Type = 'type',
  UserBoardFilterId = 'userBoardFilterId',
  ChannelStatusId = 'channelStatusId',
  ClinicId = 'clinicId'
}

export type BoardFilterColumnScalarWhereInput = {
  AND?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
  OR?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
  NOT?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  type?: Maybe<EnumBoardFilterColumnTypeFilter>;
  userBoardFilterId?: Maybe<StringFilter>;
  channelStatusId?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
};

export type BoardFilterColumnScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BoardFilterColumnScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BoardFilterColumnScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BoardFilterColumnScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumBoardFilterColumnTypeWithAggregatesFilter>;
  userBoardFilterId?: Maybe<StringWithAggregatesFilter>;
  channelStatusId?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
};

export enum BoardFilterColumnType {
  Collapsed = 'Collapsed',
  Selected = 'Selected'
}

export type BoardFilterColumnUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilterId: Scalars['String'];
  channelStatusId: Scalars['String'];
  clinicId: Scalars['String'];
};

export type BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput = {
  create?: Maybe<Array<BoardFilterColumnCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutChannelStatusInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyChannelStatusInputEnvelope>;
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
};

export type BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<BoardFilterColumnCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
};

export type BoardFilterColumnUncheckedCreateNestedManyWithoutUserBoardFilterInput = {
  create?: Maybe<Array<BoardFilterColumnCreateWithoutUserBoardFilterInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutUserBoardFilterInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyUserBoardFilterInputEnvelope>;
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
};

export type BoardFilterColumnUncheckedCreateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilterId: Scalars['String'];
  clinicId: Scalars['String'];
};

export type BoardFilterColumnUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilterId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type BoardFilterColumnUncheckedCreateWithoutUserBoardFilterInput = {
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  channelStatusId: Scalars['String'];
  clinicId: Scalars['String'];
};

export type BoardFilterColumnUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnUncheckedUpdateManyWithoutBoardFilterColumnsInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput = {
  create?: Maybe<Array<BoardFilterColumnCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<BoardFilterColumnUpsertWithWhereUniqueWithoutChannelStatusInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyChannelStatusInputEnvelope>;
  set?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  disconnect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  delete?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  update?: Maybe<Array<BoardFilterColumnUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<BoardFilterColumnUpdateManyWithWhereWithoutChannelStatusInput>>;
  deleteMany?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
};

export type BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<BoardFilterColumnCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<BoardFilterColumnUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  disconnect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  delete?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  update?: Maybe<Array<BoardFilterColumnUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<BoardFilterColumnUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
};

export type BoardFilterColumnUncheckedUpdateManyWithoutColumnsInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnUncheckedUpdateManyWithoutUserBoardFilterNestedInput = {
  create?: Maybe<Array<BoardFilterColumnCreateWithoutUserBoardFilterInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutUserBoardFilterInput>>;
  upsert?: Maybe<Array<BoardFilterColumnUpsertWithWhereUniqueWithoutUserBoardFilterInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyUserBoardFilterInputEnvelope>;
  set?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  disconnect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  delete?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  update?: Maybe<Array<BoardFilterColumnUpdateWithWhereUniqueWithoutUserBoardFilterInput>>;
  updateMany?: Maybe<Array<BoardFilterColumnUpdateManyWithWhereWithoutUserBoardFilterInput>>;
  deleteMany?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
};

export type BoardFilterColumnUncheckedUpdateManyWithoutUserBoardFiltersColumnsInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnUncheckedUpdateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnUncheckedUpdateWithoutUserBoardFilterInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnUpdateInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutUserBoardFiltersColumnsNestedInput>;
  userBoardFilter?: Maybe<UserBoardFilterUpdateOneWithoutColumnsNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutBoardFilterColumnsNestedInput>;
};

export type BoardFilterColumnUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
};

export type BoardFilterColumnUpdateManyWithWhereWithoutChannelStatusInput = {
  where: BoardFilterColumnScalarWhereInput;
  data: BoardFilterColumnUncheckedUpdateManyWithoutBoardFilterColumnsInput;
};

export type BoardFilterColumnUpdateManyWithWhereWithoutClinicInput = {
  where: BoardFilterColumnScalarWhereInput;
  data: BoardFilterColumnUncheckedUpdateManyWithoutUserBoardFiltersColumnsInput;
};

export type BoardFilterColumnUpdateManyWithWhereWithoutUserBoardFilterInput = {
  where: BoardFilterColumnScalarWhereInput;
  data: BoardFilterColumnUncheckedUpdateManyWithoutColumnsInput;
};

export type BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput = {
  create?: Maybe<Array<BoardFilterColumnCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<BoardFilterColumnUpsertWithWhereUniqueWithoutChannelStatusInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyChannelStatusInputEnvelope>;
  set?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  disconnect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  delete?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  update?: Maybe<Array<BoardFilterColumnUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<BoardFilterColumnUpdateManyWithWhereWithoutChannelStatusInput>>;
  deleteMany?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
};

export type BoardFilterColumnUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<BoardFilterColumnCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<BoardFilterColumnUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  disconnect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  delete?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  update?: Maybe<Array<BoardFilterColumnUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<BoardFilterColumnUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
};

export type BoardFilterColumnUpdateManyWithoutUserBoardFilterNestedInput = {
  create?: Maybe<Array<BoardFilterColumnCreateWithoutUserBoardFilterInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutUserBoardFilterInput>>;
  upsert?: Maybe<Array<BoardFilterColumnUpsertWithWhereUniqueWithoutUserBoardFilterInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyUserBoardFilterInputEnvelope>;
  set?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  disconnect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  delete?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  update?: Maybe<Array<BoardFilterColumnUpdateWithWhereUniqueWithoutUserBoardFilterInput>>;
  updateMany?: Maybe<Array<BoardFilterColumnUpdateManyWithWhereWithoutUserBoardFilterInput>>;
  deleteMany?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
};

export type BoardFilterColumnUpdateWithWhereUniqueWithoutChannelStatusInput = {
  where: BoardFilterColumnWhereUniqueInput;
  data: BoardFilterColumnUncheckedUpdateWithoutChannelStatusInput;
};

export type BoardFilterColumnUpdateWithWhereUniqueWithoutClinicInput = {
  where: BoardFilterColumnWhereUniqueInput;
  data: BoardFilterColumnUncheckedUpdateWithoutClinicInput;
};

export type BoardFilterColumnUpdateWithWhereUniqueWithoutUserBoardFilterInput = {
  where: BoardFilterColumnWhereUniqueInput;
  data: BoardFilterColumnUncheckedUpdateWithoutUserBoardFilterInput;
};

export type BoardFilterColumnUpdateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutUserBoardFiltersColumnsNestedInput>;
  userBoardFilter?: Maybe<UserBoardFilterUpdateOneWithoutColumnsNestedInput>;
};

export type BoardFilterColumnUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilter?: Maybe<UserBoardFilterUpdateOneWithoutColumnsNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutBoardFilterColumnsNestedInput>;
};

export type BoardFilterColumnUpdateWithoutUserBoardFilterInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutUserBoardFiltersColumnsNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutBoardFilterColumnsNestedInput>;
};

export type BoardFilterColumnUpsertWithWhereUniqueWithoutChannelStatusInput = {
  where: BoardFilterColumnWhereUniqueInput;
  update: BoardFilterColumnUncheckedUpdateWithoutChannelStatusInput;
  create: BoardFilterColumnUncheckedCreateWithoutChannelStatusInput;
};

export type BoardFilterColumnUpsertWithWhereUniqueWithoutClinicInput = {
  where: BoardFilterColumnWhereUniqueInput;
  update: BoardFilterColumnUncheckedUpdateWithoutClinicInput;
  create: BoardFilterColumnUncheckedCreateWithoutClinicInput;
};

export type BoardFilterColumnUpsertWithWhereUniqueWithoutUserBoardFilterInput = {
  where: BoardFilterColumnWhereUniqueInput;
  update: BoardFilterColumnUncheckedUpdateWithoutUserBoardFilterInput;
  create: BoardFilterColumnUncheckedCreateWithoutUserBoardFilterInput;
};

export type BoardFilterColumnWhereInput = {
  AND?: Maybe<Array<BoardFilterColumnWhereInput>>;
  OR?: Maybe<Array<BoardFilterColumnWhereInput>>;
  NOT?: Maybe<Array<BoardFilterColumnWhereInput>>;
  id?: Maybe<StringFilter>;
  type?: Maybe<EnumBoardFilterColumnTypeFilter>;
  userBoardFilterId?: Maybe<StringFilter>;
  channelStatusId?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  userBoardFilter?: Maybe<UserBoardFilterWhereInput>;
  channelStatus?: Maybe<ChannelStatusWhereInput>;
};

export type BoardFilterColumnWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum BoardFilterDateRange {
  Yesterday = 'yesterday',
  Today = 'today',
  Tomorrow = 'tomorrow',
  ThisWeek = 'thisWeek'
}

export type BoardFilterSpecificDateRange = {
  __typename?: 'BoardFilterSpecificDateRange';
  id: Scalars['String'];
  gte: Scalars['DateTime'];
  lte: Scalars['DateTime'];
  userBoardFilterId: Scalars['String'];
  userBoardFilter?: Maybe<UserBoardFilter>;
};

export type BoardFilterSpecificDateRangeCountAggregateOutputType = {
  __typename?: 'BoardFilterSpecificDateRangeCountAggregateOutputType';
  id: Scalars['Int'];
  gte: Scalars['Int'];
  lte: Scalars['Int'];
  userBoardFilterId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BoardFilterSpecificDateRangeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gte?: Maybe<SortOrder>;
  lte?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
};

export type BoardFilterSpecificDateRangeCreateInput = {
  id?: Maybe<Scalars['String']>;
  gte: Scalars['DateTime'];
  lte: Scalars['DateTime'];
  userBoardFilter?: Maybe<UserBoardFilterCreateNestedOneWithoutSpecificDateRangeInput>;
};

export type BoardFilterSpecificDateRangeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  gte: Scalars['DateTime'];
  lte: Scalars['DateTime'];
  userBoardFilterId: Scalars['String'];
};

export type BoardFilterSpecificDateRangeCreateNestedOneWithoutUserBoardFilterInput = {
  create?: Maybe<BoardFilterSpecificDateRangeUncheckedCreateWithoutUserBoardFilterInput>;
  connectOrCreate?: Maybe<BoardFilterSpecificDateRangeCreateOrConnectWithoutUserBoardFilterInput>;
  connect?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
};

export type BoardFilterSpecificDateRangeCreateOrConnectWithoutUserBoardFilterInput = {
  where: BoardFilterSpecificDateRangeWhereUniqueInput;
  create: BoardFilterSpecificDateRangeUncheckedCreateWithoutUserBoardFilterInput;
};

export type BoardFilterSpecificDateRangeCreateWithoutUserBoardFilterInput = {
  id?: Maybe<Scalars['String']>;
  gte: Scalars['DateTime'];
  lte: Scalars['DateTime'];
};

export type BoardFilterSpecificDateRangeMaxAggregateOutputType = {
  __typename?: 'BoardFilterSpecificDateRangeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterSpecificDateRangeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gte?: Maybe<SortOrder>;
  lte?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
};

export type BoardFilterSpecificDateRangeMinAggregateOutputType = {
  __typename?: 'BoardFilterSpecificDateRangeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterSpecificDateRangeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gte?: Maybe<SortOrder>;
  lte?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
};

export enum BoardFilterSpecificDateRangeOrderByRelevanceFieldEnum {
  Id = 'id',
  UserBoardFilterId = 'userBoardFilterId'
}

export type BoardFilterSpecificDateRangeOrderByRelevanceInput = {
  fields: Array<BoardFilterSpecificDateRangeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type BoardFilterSpecificDateRangeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  gte?: Maybe<SortOrder>;
  lte?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
  _count?: Maybe<BoardFilterSpecificDateRangeCountOrderByAggregateInput>;
  _max?: Maybe<BoardFilterSpecificDateRangeMaxOrderByAggregateInput>;
  _min?: Maybe<BoardFilterSpecificDateRangeMinOrderByAggregateInput>;
};

export type BoardFilterSpecificDateRangeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  gte?: Maybe<SortOrder>;
  lte?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
  userBoardFilter?: Maybe<UserBoardFilterOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<BoardFilterSpecificDateRangeOrderByRelevanceInput>;
};

export type BoardFilterSpecificDateRangeRelationFilter = {
  is?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
  isNot?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
};

export enum BoardFilterSpecificDateRangeScalarFieldEnum {
  Id = 'id',
  Gte = 'gte',
  Lte = 'lte',
  UserBoardFilterId = 'userBoardFilterId'
}

export type BoardFilterSpecificDateRangeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BoardFilterSpecificDateRangeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BoardFilterSpecificDateRangeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BoardFilterSpecificDateRangeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  gte?: Maybe<DateTimeWithAggregatesFilter>;
  lte?: Maybe<DateTimeWithAggregatesFilter>;
  userBoardFilterId?: Maybe<StringWithAggregatesFilter>;
};

export type BoardFilterSpecificDateRangeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  gte: Scalars['DateTime'];
  lte: Scalars['DateTime'];
  userBoardFilterId: Scalars['String'];
};

export type BoardFilterSpecificDateRangeUncheckedCreateNestedOneWithoutUserBoardFilterInput = {
  create?: Maybe<BoardFilterSpecificDateRangeUncheckedCreateWithoutUserBoardFilterInput>;
  connectOrCreate?: Maybe<BoardFilterSpecificDateRangeCreateOrConnectWithoutUserBoardFilterInput>;
  connect?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
};

export type BoardFilterSpecificDateRangeUncheckedCreateWithoutUserBoardFilterInput = {
  id?: Maybe<Scalars['String']>;
  gte: Scalars['DateTime'];
  lte: Scalars['DateTime'];
};

export type BoardFilterSpecificDateRangeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterSpecificDateRangeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterSpecificDateRangeUncheckedUpdateOneWithoutUserBoardFilterNestedInput = {
  create?: Maybe<BoardFilterSpecificDateRangeUncheckedCreateWithoutUserBoardFilterInput>;
  connectOrCreate?: Maybe<BoardFilterSpecificDateRangeCreateOrConnectWithoutUserBoardFilterInput>;
  upsert?: Maybe<BoardFilterSpecificDateRangeUpsertWithoutUserBoardFilterInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
  update?: Maybe<BoardFilterSpecificDateRangeUncheckedUpdateWithoutUserBoardFilterInput>;
};

export type BoardFilterSpecificDateRangeUncheckedUpdateWithoutUserBoardFilterInput = {
  id?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
};

export type BoardFilterSpecificDateRangeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  userBoardFilter?: Maybe<UserBoardFilterUpdateOneWithoutSpecificDateRangeNestedInput>;
};

export type BoardFilterSpecificDateRangeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
};

export type BoardFilterSpecificDateRangeUpdateOneWithoutUserBoardFilterNestedInput = {
  create?: Maybe<BoardFilterSpecificDateRangeUncheckedCreateWithoutUserBoardFilterInput>;
  connectOrCreate?: Maybe<BoardFilterSpecificDateRangeCreateOrConnectWithoutUserBoardFilterInput>;
  upsert?: Maybe<BoardFilterSpecificDateRangeUpsertWithoutUserBoardFilterInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
  update?: Maybe<BoardFilterSpecificDateRangeUncheckedUpdateWithoutUserBoardFilterInput>;
};

export type BoardFilterSpecificDateRangeUpdateWithoutUserBoardFilterInput = {
  id?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
};

export type BoardFilterSpecificDateRangeUpsertWithoutUserBoardFilterInput = {
  update: BoardFilterSpecificDateRangeUncheckedUpdateWithoutUserBoardFilterInput;
  create: BoardFilterSpecificDateRangeUncheckedCreateWithoutUserBoardFilterInput;
};

export type BoardFilterSpecificDateRangeWhereInput = {
  AND?: Maybe<Array<BoardFilterSpecificDateRangeWhereInput>>;
  OR?: Maybe<Array<BoardFilterSpecificDateRangeWhereInput>>;
  NOT?: Maybe<Array<BoardFilterSpecificDateRangeWhereInput>>;
  id?: Maybe<StringFilter>;
  gte?: Maybe<DateTimeFilter>;
  lte?: Maybe<DateTimeFilter>;
  userBoardFilterId?: Maybe<StringFilter>;
  userBoardFilter?: Maybe<UserBoardFilterWhereInput>;
};

export type BoardFilterSpecificDateRangeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export enum BoardFilterView {
  Conversations = 'conversations',
  Automations = 'automations',
  Appointments = 'appointments'
}

export type BoehringerIngelheimCustomerAlignment = {
  __typename?: 'BoehringerIngelheimCustomerAlignment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  informPartnerCustomerId: Scalars['String'];
  boehringerIngelheimTerritoryId: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritory: BoehringerIngelheimTerritory;
  informPartnerCustomer: InformPartnerCustomer;
};

export type BoehringerIngelheimCustomerAlignmentAvgAggregateOutputType = {
  __typename?: 'BoehringerIngelheimCustomerAlignmentAvgAggregateOutputType';
  soldToId?: Maybe<Scalars['Float']>;
};

export type BoehringerIngelheimCustomerAlignmentAvgOrderByAggregateInput = {
  soldToId?: Maybe<SortOrder>;
};

export type BoehringerIngelheimCustomerAlignmentCountAggregateOutputType = {
  __typename?: 'BoehringerIngelheimCustomerAlignmentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  soldToId: Scalars['Int'];
  territoryName: Scalars['Int'];
  informPartnerCustomerId: Scalars['Int'];
  boehringerIngelheimTerritoryId: Scalars['Int'];
  rawData: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BoehringerIngelheimCustomerAlignmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  soldToId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  boehringerIngelheimTerritoryId?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
};

export type BoehringerIngelheimCustomerAlignmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritory: BoehringerIngelheimTerritoryCreateNestedOneWithoutBoehringerIngelheimCustomerAlignmentsInput;
  informPartnerCustomer: InformPartnerCustomerCreateNestedOneWithoutBoehringerIngelheimCustomerAlignmentsInput;
};

export type BoehringerIngelheimCustomerAlignmentCreateManyBoehringerIngelheimTerritoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  informPartnerCustomerId: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
};

export type BoehringerIngelheimCustomerAlignmentCreateManyBoehringerIngelheimTerritoryInputEnvelope = {
  data: Array<BoehringerIngelheimCustomerAlignmentCreateManyBoehringerIngelheimTerritoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BoehringerIngelheimCustomerAlignmentCreateManyInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  boehringerIngelheimTerritoryId: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
};

export type BoehringerIngelheimCustomerAlignmentCreateManyInformPartnerCustomerInputEnvelope = {
  data: Array<BoehringerIngelheimCustomerAlignmentCreateManyInformPartnerCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BoehringerIngelheimCustomerAlignmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  informPartnerCustomerId: Scalars['String'];
  boehringerIngelheimTerritoryId: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
};

export type BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutBoehringerIngelheimTerritoryInput = {
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutBoehringerIngelheimTerritoryInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutBoehringerIngelheimTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyBoehringerIngelheimTerritoryInputEnvelope>;
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
};

export type BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput = {
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyInformPartnerCustomerInputEnvelope>;
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
};

export type BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutBoehringerIngelheimTerritoryInput = {
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
  create: BoehringerIngelheimCustomerAlignmentUncheckedCreateWithoutBoehringerIngelheimTerritoryInput;
};

export type BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutInformPartnerCustomerInput = {
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
  create: BoehringerIngelheimCustomerAlignmentUncheckedCreateWithoutInformPartnerCustomerInput;
};

export type BoehringerIngelheimCustomerAlignmentCreateWithoutBoehringerIngelheimTerritoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartnerCustomer: InformPartnerCustomerCreateNestedOneWithoutBoehringerIngelheimCustomerAlignmentsInput;
};

export type BoehringerIngelheimCustomerAlignmentCreateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritory: BoehringerIngelheimTerritoryCreateNestedOneWithoutBoehringerIngelheimCustomerAlignmentsInput;
};

export type BoehringerIngelheimCustomerAlignmentListRelationFilter = {
  every?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
  some?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
  none?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
};

export type BoehringerIngelheimCustomerAlignmentMaxAggregateOutputType = {
  __typename?: 'BoehringerIngelheimCustomerAlignmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  boehringerIngelheimTerritoryId?: Maybe<Scalars['String']>;
};

export type BoehringerIngelheimCustomerAlignmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  soldToId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  boehringerIngelheimTerritoryId?: Maybe<SortOrder>;
};

export type BoehringerIngelheimCustomerAlignmentMinAggregateOutputType = {
  __typename?: 'BoehringerIngelheimCustomerAlignmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  boehringerIngelheimTerritoryId?: Maybe<Scalars['String']>;
};

export type BoehringerIngelheimCustomerAlignmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  soldToId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  boehringerIngelheimTerritoryId?: Maybe<SortOrder>;
};

export type BoehringerIngelheimCustomerAlignmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BoehringerIngelheimCustomerAlignmentOrderByRelevanceFieldEnum {
  Id = 'id',
  TerritoryName = 'territoryName',
  InformPartnerCustomerId = 'informPartnerCustomerId',
  BoehringerIngelheimTerritoryId = 'boehringerIngelheimTerritoryId'
}

export type BoehringerIngelheimCustomerAlignmentOrderByRelevanceInput = {
  fields: Array<BoehringerIngelheimCustomerAlignmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type BoehringerIngelheimCustomerAlignmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  soldToId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  boehringerIngelheimTerritoryId?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  _count?: Maybe<BoehringerIngelheimCustomerAlignmentCountOrderByAggregateInput>;
  _avg?: Maybe<BoehringerIngelheimCustomerAlignmentAvgOrderByAggregateInput>;
  _max?: Maybe<BoehringerIngelheimCustomerAlignmentMaxOrderByAggregateInput>;
  _min?: Maybe<BoehringerIngelheimCustomerAlignmentMinOrderByAggregateInput>;
  _sum?: Maybe<BoehringerIngelheimCustomerAlignmentSumOrderByAggregateInput>;
};

export type BoehringerIngelheimCustomerAlignmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  soldToId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  boehringerIngelheimTerritoryId?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  boehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<BoehringerIngelheimCustomerAlignmentOrderByRelevanceInput>;
};

export enum BoehringerIngelheimCustomerAlignmentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  SoldToId = 'soldToId',
  TerritoryName = 'territoryName',
  InformPartnerCustomerId = 'informPartnerCustomerId',
  BoehringerIngelheimTerritoryId = 'boehringerIngelheimTerritoryId',
  RawData = 'rawData'
}

export type BoehringerIngelheimCustomerAlignmentScalarWhereInput = {
  AND?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereInput>>;
  OR?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereInput>>;
  NOT?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  soldToId?: Maybe<IntFilter>;
  territoryName?: Maybe<StringFilter>;
  informPartnerCustomerId?: Maybe<StringFilter>;
  boehringerIngelheimTerritoryId?: Maybe<StringFilter>;
  rawData?: Maybe<JsonNullableFilter>;
};

export type BoehringerIngelheimCustomerAlignmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  soldToId?: Maybe<IntWithAggregatesFilter>;
  territoryName?: Maybe<StringWithAggregatesFilter>;
  informPartnerCustomerId?: Maybe<StringWithAggregatesFilter>;
  boehringerIngelheimTerritoryId?: Maybe<StringWithAggregatesFilter>;
  rawData?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type BoehringerIngelheimCustomerAlignmentSoldToIdTerritoryNameCompoundUniqueInput = {
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
};

export type BoehringerIngelheimCustomerAlignmentSumAggregateOutputType = {
  __typename?: 'BoehringerIngelheimCustomerAlignmentSumAggregateOutputType';
  soldToId?: Maybe<Scalars['Int']>;
};

export type BoehringerIngelheimCustomerAlignmentSumOrderByAggregateInput = {
  soldToId?: Maybe<SortOrder>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  informPartnerCustomerId: Scalars['String'];
  boehringerIngelheimTerritoryId: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoryInput = {
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutBoehringerIngelheimTerritoryInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutBoehringerIngelheimTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyBoehringerIngelheimTerritoryInputEnvelope>;
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput = {
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyInformPartnerCustomerInputEnvelope>;
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedCreateWithoutBoehringerIngelheimTerritoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  informPartnerCustomerId: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedCreateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  boehringerIngelheimTerritoryId: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  boehringerIngelheimTerritoryId?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  boehringerIngelheimTerritoryId?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput = {
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutBoehringerIngelheimTerritoryInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutBoehringerIngelheimTerritoryInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpsertWithWhereUniqueWithoutBoehringerIngelheimTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyBoehringerIngelheimTerritoryInputEnvelope>;
  set?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  delete?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateWithWhereUniqueWithoutBoehringerIngelheimTerritoryInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateManyWithWhereWithoutBoehringerIngelheimTerritoryInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereInput>>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput = {
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpsertWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyInformPartnerCustomerInputEnvelope>;
  set?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  delete?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateManyWithWhereWithoutInformPartnerCustomerInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereInput>>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedUpdateWithoutBoehringerIngelheimTerritoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedUpdateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  boehringerIngelheimTerritoryId?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type BoehringerIngelheimCustomerAlignmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritoryUpdateOneRequiredWithoutBoehringerIngelheimCustomerAlignmentsNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneRequiredWithoutBoehringerIngelheimCustomerAlignmentsNestedInput>;
};

export type BoehringerIngelheimCustomerAlignmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type BoehringerIngelheimCustomerAlignmentUpdateManyWithWhereWithoutBoehringerIngelheimTerritoryInput = {
  where: BoehringerIngelheimCustomerAlignmentScalarWhereInput;
  data: BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimCustomerAlignmentsInput;
};

export type BoehringerIngelheimCustomerAlignmentUpdateManyWithWhereWithoutInformPartnerCustomerInput = {
  where: BoehringerIngelheimCustomerAlignmentScalarWhereInput;
  data: BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimCustomerAlignmentsInput;
};

export type BoehringerIngelheimCustomerAlignmentUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput = {
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutBoehringerIngelheimTerritoryInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutBoehringerIngelheimTerritoryInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpsertWithWhereUniqueWithoutBoehringerIngelheimTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyBoehringerIngelheimTerritoryInputEnvelope>;
  set?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  delete?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateWithWhereUniqueWithoutBoehringerIngelheimTerritoryInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateManyWithWhereWithoutBoehringerIngelheimTerritoryInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereInput>>;
};

export type BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput = {
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpsertWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyInformPartnerCustomerInputEnvelope>;
  set?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  delete?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateManyWithWhereWithoutInformPartnerCustomerInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereInput>>;
};

export type BoehringerIngelheimCustomerAlignmentUpdateWithWhereUniqueWithoutBoehringerIngelheimTerritoryInput = {
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
  data: BoehringerIngelheimCustomerAlignmentUncheckedUpdateWithoutBoehringerIngelheimTerritoryInput;
};

export type BoehringerIngelheimCustomerAlignmentUpdateWithWhereUniqueWithoutInformPartnerCustomerInput = {
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
  data: BoehringerIngelheimCustomerAlignmentUncheckedUpdateWithoutInformPartnerCustomerInput;
};

export type BoehringerIngelheimCustomerAlignmentUpdateWithoutBoehringerIngelheimTerritoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneRequiredWithoutBoehringerIngelheimCustomerAlignmentsNestedInput>;
};

export type BoehringerIngelheimCustomerAlignmentUpdateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritoryUpdateOneRequiredWithoutBoehringerIngelheimCustomerAlignmentsNestedInput>;
};

export type BoehringerIngelheimCustomerAlignmentUpsertWithWhereUniqueWithoutBoehringerIngelheimTerritoryInput = {
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
  update: BoehringerIngelheimCustomerAlignmentUncheckedUpdateWithoutBoehringerIngelheimTerritoryInput;
  create: BoehringerIngelheimCustomerAlignmentUncheckedCreateWithoutBoehringerIngelheimTerritoryInput;
};

export type BoehringerIngelheimCustomerAlignmentUpsertWithWhereUniqueWithoutInformPartnerCustomerInput = {
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
  update: BoehringerIngelheimCustomerAlignmentUncheckedUpdateWithoutInformPartnerCustomerInput;
  create: BoehringerIngelheimCustomerAlignmentUncheckedCreateWithoutInformPartnerCustomerInput;
};

export type BoehringerIngelheimCustomerAlignmentWhereInput = {
  AND?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereInput>>;
  OR?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereInput>>;
  NOT?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  soldToId?: Maybe<IntFilter>;
  territoryName?: Maybe<StringFilter>;
  informPartnerCustomerId?: Maybe<StringFilter>;
  boehringerIngelheimTerritoryId?: Maybe<StringFilter>;
  rawData?: Maybe<JsonNullableFilter>;
  boehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerWhereInput>;
};

export type BoehringerIngelheimCustomerAlignmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  soldToId_territoryName?: Maybe<BoehringerIngelheimCustomerAlignmentSoldToIdTerritoryNameCompoundUniqueInput>;
};

export type BoehringerIngelheimTerritory = {
  __typename?: 'BoehringerIngelheimTerritory';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  parentTerritoryId?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode: Scalars['String'];
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments: Array<BoehringerIngelheimCustomerAlignment>;
  informPartnerUsers: Array<InformPartnerUser>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritory>;
  childTerritories: Array<BoehringerIngelheimTerritory>;
  _count: BoehringerIngelheimTerritoryCountOutputType;
};


export type BoehringerIngelheimTerritoryBoehringerIngelheimCustomerAlignmentsArgs = {
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
  orderBy?: Maybe<BoehringerIngelheimCustomerAlignmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BoehringerIngelheimCustomerAlignmentScalarFieldEnum>;
};


export type BoehringerIngelheimTerritoryInformPartnerUsersArgs = {
  where?: Maybe<InformPartnerUserWhereInput>;
  orderBy?: Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerUserScalarFieldEnum>;
};


export type BoehringerIngelheimTerritoryChildTerritoriesArgs = {
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  orderBy?: Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BoehringerIngelheimTerritoryScalarFieldEnum>;
};

export type BoehringerIngelheimTerritoryCountAggregateOutputType = {
  __typename?: 'BoehringerIngelheimTerritoryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  parentTerritoryId: Scalars['Int'];
  territoryName: Scalars['Int'];
  territoryDescription: Scalars['Int'];
  parentTerritoryName: Scalars['Int'];
  territoryType: Scalars['Int'];
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
  countryCode: Scalars['Int'];
  enterpriseSegment: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BoehringerIngelheimTerritoryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentTerritoryId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  territoryDescription?: Maybe<SortOrder>;
  parentTerritoryName?: Maybe<SortOrder>;
  territoryType?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  countryCode?: Maybe<SortOrder>;
  enterpriseSegment?: Maybe<SortOrder>;
};

export type BoehringerIngelheimTerritoryCountOutputType = {
  __typename?: 'BoehringerIngelheimTerritoryCountOutputType';
  boehringerIngelheimCustomerAlignments: Scalars['Int'];
  informPartnerUsers: Scalars['Int'];
  childTerritories: Scalars['Int'];
};

export type BoehringerIngelheimTerritoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName: Scalars['String'];
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode: Scalars['String'];
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryCreateNestedOneWithoutChildTerritoriesInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutParentTerritoryInput>;
};

export type BoehringerIngelheimTerritoryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode: Scalars['String'];
  enterpriseSegment?: Maybe<Scalars['String']>;
};

export type BoehringerIngelheimTerritoryCreateManyParentTerritoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName: Scalars['String'];
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode: Scalars['String'];
  enterpriseSegment?: Maybe<Scalars['String']>;
};

export type BoehringerIngelheimTerritoryCreateManyParentTerritoryInputEnvelope = {
  data: Array<BoehringerIngelheimTerritoryCreateManyParentTerritoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput = {
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutInformPartnerUsersInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutInformPartnerUsersInput>>;
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
};

export type BoehringerIngelheimTerritoryCreateNestedManyWithoutParentTerritoryInput = {
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutParentTerritoryInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutParentTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimTerritoryCreateManyParentTerritoryInputEnvelope>;
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
};

export type BoehringerIngelheimTerritoryCreateNestedOneWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  create?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  connectOrCreate?: Maybe<BoehringerIngelheimTerritoryCreateOrConnectWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  connect?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
};

export type BoehringerIngelheimTerritoryCreateNestedOneWithoutChildTerritoriesInput = {
  create?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateWithoutChildTerritoriesInput>;
  connectOrCreate?: Maybe<BoehringerIngelheimTerritoryCreateOrConnectWithoutChildTerritoriesInput>;
  connect?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
};

export type BoehringerIngelheimTerritoryCreateOrConnectWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput;
};

export type BoehringerIngelheimTerritoryCreateOrConnectWithoutChildTerritoriesInput = {
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutChildTerritoriesInput;
};

export type BoehringerIngelheimTerritoryCreateOrConnectWithoutInformPartnerUsersInput = {
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutInformPartnerUsersInput;
};

export type BoehringerIngelheimTerritoryCreateOrConnectWithoutParentTerritoryInput = {
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutParentTerritoryInput;
};

export type BoehringerIngelheimTerritoryCreateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName: Scalars['String'];
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode: Scalars['String'];
  enterpriseSegment?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryCreateNestedOneWithoutChildTerritoriesInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutParentTerritoryInput>;
};

export type BoehringerIngelheimTerritoryCreateWithoutChildTerritoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName: Scalars['String'];
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode: Scalars['String'];
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryCreateNestedOneWithoutChildTerritoriesInput>;
};

export type BoehringerIngelheimTerritoryCreateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName: Scalars['String'];
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode: Scalars['String'];
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryCreateNestedOneWithoutChildTerritoriesInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutParentTerritoryInput>;
};

export type BoehringerIngelheimTerritoryCreateWithoutParentTerritoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName: Scalars['String'];
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode: Scalars['String'];
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutParentTerritoryInput>;
};

export type BoehringerIngelheimTerritoryListRelationFilter = {
  every?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  some?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  none?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
};

export type BoehringerIngelheimTerritoryMaxAggregateOutputType = {
  __typename?: 'BoehringerIngelheimTerritoryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
};

export type BoehringerIngelheimTerritoryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentTerritoryId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  territoryDescription?: Maybe<SortOrder>;
  parentTerritoryName?: Maybe<SortOrder>;
  territoryType?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  countryCode?: Maybe<SortOrder>;
  enterpriseSegment?: Maybe<SortOrder>;
};

export type BoehringerIngelheimTerritoryMinAggregateOutputType = {
  __typename?: 'BoehringerIngelheimTerritoryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
};

export type BoehringerIngelheimTerritoryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentTerritoryId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  territoryDescription?: Maybe<SortOrder>;
  parentTerritoryName?: Maybe<SortOrder>;
  territoryType?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  countryCode?: Maybe<SortOrder>;
  enterpriseSegment?: Maybe<SortOrder>;
};

export type BoehringerIngelheimTerritoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BoehringerIngelheimTerritoryOrderByRelevanceFieldEnum {
  Id = 'id',
  ParentTerritoryId = 'parentTerritoryId',
  TerritoryName = 'territoryName',
  TerritoryDescription = 'territoryDescription',
  ParentTerritoryName = 'parentTerritoryName',
  TerritoryType = 'territoryType',
  CountryCode = 'countryCode',
  EnterpriseSegment = 'enterpriseSegment'
}

export type BoehringerIngelheimTerritoryOrderByRelevanceInput = {
  fields: Array<BoehringerIngelheimTerritoryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type BoehringerIngelheimTerritoryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentTerritoryId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  territoryDescription?: Maybe<SortOrder>;
  parentTerritoryName?: Maybe<SortOrder>;
  territoryType?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  countryCode?: Maybe<SortOrder>;
  enterpriseSegment?: Maybe<SortOrder>;
  _count?: Maybe<BoehringerIngelheimTerritoryCountOrderByAggregateInput>;
  _max?: Maybe<BoehringerIngelheimTerritoryMaxOrderByAggregateInput>;
  _min?: Maybe<BoehringerIngelheimTerritoryMinOrderByAggregateInput>;
};

export type BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentTerritoryId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  territoryDescription?: Maybe<SortOrder>;
  parentTerritoryName?: Maybe<SortOrder>;
  territoryType?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  countryCode?: Maybe<SortOrder>;
  enterpriseSegment?: Maybe<SortOrder>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentOrderByRelationAggregateInput>;
  informPartnerUsers?: Maybe<InformPartnerUserOrderByRelationAggregateInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryOrderByRelationAggregateInput>;
  _relevance?: Maybe<BoehringerIngelheimTerritoryOrderByRelevanceInput>;
};

export type BoehringerIngelheimTerritoryRelationFilter = {
  is?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  isNot?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
};

export enum BoehringerIngelheimTerritoryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ParentTerritoryId = 'parentTerritoryId',
  TerritoryName = 'territoryName',
  TerritoryDescription = 'territoryDescription',
  ParentTerritoryName = 'parentTerritoryName',
  TerritoryType = 'territoryType',
  StartDate = 'startDate',
  EndDate = 'endDate',
  CountryCode = 'countryCode',
  EnterpriseSegment = 'enterpriseSegment'
}

export type BoehringerIngelheimTerritoryScalarWhereInput = {
  AND?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereInput>>;
  OR?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereInput>>;
  NOT?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  parentTerritoryId?: Maybe<StringNullableFilter>;
  territoryName?: Maybe<StringFilter>;
  territoryDescription?: Maybe<StringNullableFilter>;
  parentTerritoryName?: Maybe<StringNullableFilter>;
  territoryType?: Maybe<StringFilter>;
  startDate?: Maybe<DateTimeFilter>;
  endDate?: Maybe<DateTimeNullableFilter>;
  countryCode?: Maybe<StringFilter>;
  enterpriseSegment?: Maybe<StringNullableFilter>;
};

export type BoehringerIngelheimTerritoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  parentTerritoryId?: Maybe<StringNullableWithAggregatesFilter>;
  territoryName?: Maybe<StringWithAggregatesFilter>;
  territoryDescription?: Maybe<StringNullableWithAggregatesFilter>;
  parentTerritoryName?: Maybe<StringNullableWithAggregatesFilter>;
  territoryType?: Maybe<StringWithAggregatesFilter>;
  startDate?: Maybe<DateTimeWithAggregatesFilter>;
  endDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  countryCode?: Maybe<StringWithAggregatesFilter>;
  enterpriseSegment?: Maybe<StringNullableWithAggregatesFilter>;
};

export type BoehringerIngelheimTerritoryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode: Scalars['String'];
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutParentTerritoryInput>;
};

export type BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput = {
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutInformPartnerUsersInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutInformPartnerUsersInput>>;
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
};

export type BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutParentTerritoryInput = {
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutParentTerritoryInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutParentTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimTerritoryCreateManyParentTerritoryInputEnvelope>;
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
};

export type BoehringerIngelheimTerritoryUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode: Scalars['String'];
  enterpriseSegment?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutParentTerritoryInput>;
};

export type BoehringerIngelheimTerritoryUncheckedCreateWithoutChildTerritoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode: Scalars['String'];
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
};

export type BoehringerIngelheimTerritoryUncheckedCreateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode: Scalars['String'];
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutParentTerritoryInput>;
};

export type BoehringerIngelheimTerritoryUncheckedCreateWithoutParentTerritoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName: Scalars['String'];
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode: Scalars['String'];
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutParentTerritoryInput>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutParentTerritoryNestedInput>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutBoehringerIngelheimTerritoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutChildTerritoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput = {
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutInformPartnerUsersInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutInformPartnerUsersInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimTerritoryUpsertWithWhereUniqueWithoutInformPartnerUsersInput>>;
  set?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  delete?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimTerritoryUpdateWithWhereUniqueWithoutInformPartnerUsersInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimTerritoryUpdateManyWithWhereWithoutInformPartnerUsersInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereInput>>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutParentTerritoryNestedInput = {
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutParentTerritoryInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutParentTerritoryInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimTerritoryUpsertWithWhereUniqueWithoutParentTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimTerritoryCreateManyParentTerritoryInputEnvelope>;
  set?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  delete?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimTerritoryUpdateWithWhereUniqueWithoutParentTerritoryInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimTerritoryUpdateManyWithWhereWithoutParentTerritoryInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereInput>>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutParentTerritoryNestedInput>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateWithoutChildTerritoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutParentTerritoryNestedInput>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateWithoutParentTerritoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutParentTerritoryNestedInput>;
};

export type BoehringerIngelheimTerritoryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryUpdateOneWithoutChildTerritoriesNestedInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutParentTerritoryNestedInput>;
};

export type BoehringerIngelheimTerritoryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
};

export type BoehringerIngelheimTerritoryUpdateManyWithWhereWithoutInformPartnerUsersInput = {
  where: BoehringerIngelheimTerritoryScalarWhereInput;
  data: BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutBoehringerIngelheimTerritoriesInput;
};

export type BoehringerIngelheimTerritoryUpdateManyWithWhereWithoutParentTerritoryInput = {
  where: BoehringerIngelheimTerritoryScalarWhereInput;
  data: BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutChildTerritoriesInput;
};

export type BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput = {
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutInformPartnerUsersInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutInformPartnerUsersInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimTerritoryUpsertWithWhereUniqueWithoutInformPartnerUsersInput>>;
  set?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  delete?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimTerritoryUpdateWithWhereUniqueWithoutInformPartnerUsersInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimTerritoryUpdateManyWithWhereWithoutInformPartnerUsersInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereInput>>;
};

export type BoehringerIngelheimTerritoryUpdateManyWithoutParentTerritoryNestedInput = {
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutParentTerritoryInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutParentTerritoryInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimTerritoryUpsertWithWhereUniqueWithoutParentTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimTerritoryCreateManyParentTerritoryInputEnvelope>;
  set?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  delete?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimTerritoryUpdateWithWhereUniqueWithoutParentTerritoryInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimTerritoryUpdateManyWithWhereWithoutParentTerritoryInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereInput>>;
};

export type BoehringerIngelheimTerritoryUpdateOneRequiredWithoutBoehringerIngelheimCustomerAlignmentsNestedInput = {
  create?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  connectOrCreate?: Maybe<BoehringerIngelheimTerritoryCreateOrConnectWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  upsert?: Maybe<BoehringerIngelheimTerritoryUpsertWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  connect?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  update?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput>;
};

export type BoehringerIngelheimTerritoryUpdateOneWithoutChildTerritoriesNestedInput = {
  create?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateWithoutChildTerritoriesInput>;
  connectOrCreate?: Maybe<BoehringerIngelheimTerritoryCreateOrConnectWithoutChildTerritoriesInput>;
  upsert?: Maybe<BoehringerIngelheimTerritoryUpsertWithoutChildTerritoriesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  update?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateWithoutChildTerritoriesInput>;
};

export type BoehringerIngelheimTerritoryUpdateWithWhereUniqueWithoutInformPartnerUsersInput = {
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
  data: BoehringerIngelheimTerritoryUncheckedUpdateWithoutInformPartnerUsersInput;
};

export type BoehringerIngelheimTerritoryUpdateWithWhereUniqueWithoutParentTerritoryInput = {
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
  data: BoehringerIngelheimTerritoryUncheckedUpdateWithoutParentTerritoryInput;
};

export type BoehringerIngelheimTerritoryUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryUpdateOneWithoutChildTerritoriesNestedInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutParentTerritoryNestedInput>;
};

export type BoehringerIngelheimTerritoryUpdateWithoutChildTerritoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryUpdateOneWithoutChildTerritoriesNestedInput>;
};

export type BoehringerIngelheimTerritoryUpdateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryUpdateOneWithoutChildTerritoriesNestedInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutParentTerritoryNestedInput>;
};

export type BoehringerIngelheimTerritoryUpdateWithoutParentTerritoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryDescription?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutParentTerritoryNestedInput>;
};

export type BoehringerIngelheimTerritoryUpsertWithWhereUniqueWithoutInformPartnerUsersInput = {
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
  update: BoehringerIngelheimTerritoryUncheckedUpdateWithoutInformPartnerUsersInput;
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutInformPartnerUsersInput;
};

export type BoehringerIngelheimTerritoryUpsertWithWhereUniqueWithoutParentTerritoryInput = {
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
  update: BoehringerIngelheimTerritoryUncheckedUpdateWithoutParentTerritoryInput;
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutParentTerritoryInput;
};

export type BoehringerIngelheimTerritoryUpsertWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  update: BoehringerIngelheimTerritoryUncheckedUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput;
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput;
};

export type BoehringerIngelheimTerritoryUpsertWithoutChildTerritoriesInput = {
  update: BoehringerIngelheimTerritoryUncheckedUpdateWithoutChildTerritoriesInput;
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutChildTerritoriesInput;
};

export type BoehringerIngelheimTerritoryWhereInput = {
  AND?: Maybe<Array<BoehringerIngelheimTerritoryWhereInput>>;
  OR?: Maybe<Array<BoehringerIngelheimTerritoryWhereInput>>;
  NOT?: Maybe<Array<BoehringerIngelheimTerritoryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  parentTerritoryId?: Maybe<StringNullableFilter>;
  territoryName?: Maybe<StringFilter>;
  territoryDescription?: Maybe<StringNullableFilter>;
  parentTerritoryName?: Maybe<StringNullableFilter>;
  territoryType?: Maybe<StringFilter>;
  startDate?: Maybe<DateTimeFilter>;
  endDate?: Maybe<DateTimeNullableFilter>;
  countryCode?: Maybe<StringFilter>;
  enterpriseSegment?: Maybe<StringNullableFilter>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentListRelationFilter>;
  informPartnerUsers?: Maybe<InformPartnerUserListRelationFilter>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryListRelationFilter>;
};

export type BoehringerIngelheimTerritoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedBoolNullableFilter>;
  _max?: Maybe<NestedBoolNullableFilter>;
};

export type BoolWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedBoolFilter>;
  _max?: Maybe<NestedBoolFilter>;
};

export type Breed = {
  __typename?: 'Breed';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration: ClinicPimsIntegration;
  species?: Maybe<Species>;
  pets: Array<ClinicPet>;
  _count: BreedCountOutputType;
};


export type BreedPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};

export type BreedCountAggregateOutputType = {
  __typename?: 'BreedCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  integrationId: Scalars['Int'];
  pimsId: Scalars['Int'];
  name: Scalars['Int'];
  description: Scalars['Int'];
  speciesId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BreedCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type BreedCountOutputType = {
  __typename?: 'BreedCountOutputType';
  pets: Scalars['Int'];
};

export type BreedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBreedsInput;
  species?: Maybe<SpeciesCreateNestedOneWithoutBreedsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutBreedModelInput>;
};

export type BreedCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type BreedCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type BreedCreateManyIntegrationInputEnvelope = {
  data: Array<BreedCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BreedCreateManySpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type BreedCreateManySpeciesInputEnvelope = {
  data: Array<BreedCreateManySpeciesInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BreedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<BreedCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<BreedCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
};

export type BreedCreateNestedManyWithoutSpeciesInput = {
  create?: Maybe<Array<BreedCreateWithoutSpeciesInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutSpeciesInput>>;
  createMany?: Maybe<BreedCreateManySpeciesInputEnvelope>;
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
};

export type BreedCreateNestedOneWithoutPetsInput = {
  create?: Maybe<BreedUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<BreedCreateOrConnectWithoutPetsInput>;
  connect?: Maybe<BreedWhereUniqueInput>;
};

export type BreedCreateOrConnectWithoutIntegrationInput = {
  where: BreedWhereUniqueInput;
  create: BreedUncheckedCreateWithoutIntegrationInput;
};

export type BreedCreateOrConnectWithoutPetsInput = {
  where: BreedWhereUniqueInput;
  create: BreedUncheckedCreateWithoutPetsInput;
};

export type BreedCreateOrConnectWithoutSpeciesInput = {
  where: BreedWhereUniqueInput;
  create: BreedUncheckedCreateWithoutSpeciesInput;
};

export type BreedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<SpeciesCreateNestedOneWithoutBreedsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutBreedModelInput>;
};

export type BreedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBreedsInput;
  species?: Maybe<SpeciesCreateNestedOneWithoutBreedsInput>;
};

export type BreedCreateWithoutSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBreedsInput;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutBreedModelInput>;
};

export type BreedIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type BreedListRelationFilter = {
  every?: Maybe<BreedWhereInput>;
  some?: Maybe<BreedWhereInput>;
  none?: Maybe<BreedWhereInput>;
};

export type BreedMaxAggregateOutputType = {
  __typename?: 'BreedMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type BreedMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type BreedMinAggregateOutputType = {
  __typename?: 'BreedMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type BreedMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type BreedOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BreedOrderByRelevanceFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  Name = 'name',
  Description = 'description',
  SpeciesId = 'speciesId'
}

export type BreedOrderByRelevanceInput = {
  fields: Array<BreedOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type BreedOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<BreedCountOrderByAggregateInput>;
  _max?: Maybe<BreedMaxOrderByAggregateInput>;
  _min?: Maybe<BreedMinOrderByAggregateInput>;
};

export type BreedOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  species?: Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  _relevance?: Maybe<BreedOrderByRelevanceInput>;
};

export type BreedRelationFilter = {
  is?: Maybe<BreedWhereInput>;
  isNot?: Maybe<BreedWhereInput>;
};

export enum BreedScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  Name = 'name',
  Description = 'description',
  SpeciesId = 'speciesId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  RawPimsValue = 'rawPimsValue'
}

export type BreedScalarWhereInput = {
  AND?: Maybe<Array<BreedScalarWhereInput>>;
  OR?: Maybe<Array<BreedScalarWhereInput>>;
  NOT?: Maybe<Array<BreedScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  speciesId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type BreedScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BreedScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BreedScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BreedScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  speciesId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type BreedUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutBreedModelInput>;
};

export type BreedUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<BreedCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<BreedCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
};

export type BreedUncheckedCreateNestedManyWithoutSpeciesInput = {
  create?: Maybe<Array<BreedCreateWithoutSpeciesInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutSpeciesInput>>;
  createMany?: Maybe<BreedCreateManySpeciesInputEnvelope>;
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
};

export type BreedUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutBreedModelInput>;
};

export type BreedUncheckedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type BreedUncheckedCreateWithoutSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutBreedModelInput>;
};

export type BreedUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutBreedModelNestedInput>;
};

export type BreedUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type BreedUncheckedUpdateManyWithoutBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type BreedUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<BreedCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<BreedUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<BreedCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<BreedWhereUniqueInput>>;
  disconnect?: Maybe<Array<BreedWhereUniqueInput>>;
  delete?: Maybe<Array<BreedWhereUniqueInput>>;
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
  update?: Maybe<Array<BreedUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<BreedUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<BreedScalarWhereInput>>;
};

export type BreedUncheckedUpdateManyWithoutSpeciesNestedInput = {
  create?: Maybe<Array<BreedCreateWithoutSpeciesInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutSpeciesInput>>;
  upsert?: Maybe<Array<BreedUpsertWithWhereUniqueWithoutSpeciesInput>>;
  createMany?: Maybe<BreedCreateManySpeciesInputEnvelope>;
  set?: Maybe<Array<BreedWhereUniqueInput>>;
  disconnect?: Maybe<Array<BreedWhereUniqueInput>>;
  delete?: Maybe<Array<BreedWhereUniqueInput>>;
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
  update?: Maybe<Array<BreedUpdateWithWhereUniqueWithoutSpeciesInput>>;
  updateMany?: Maybe<Array<BreedUpdateManyWithWhereWithoutSpeciesInput>>;
  deleteMany?: Maybe<Array<BreedScalarWhereInput>>;
};

export type BreedUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutBreedModelNestedInput>;
};

export type BreedUncheckedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type BreedUncheckedUpdateWithoutSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutBreedModelNestedInput>;
};

export type BreedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBreedsNestedInput>;
  species?: Maybe<SpeciesUpdateOneWithoutBreedsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutBreedModelNestedInput>;
};

export type BreedUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type BreedUpdateManyWithWhereWithoutIntegrationInput = {
  where: BreedScalarWhereInput;
  data: BreedUncheckedUpdateManyWithoutBreedsInput;
};

export type BreedUpdateManyWithWhereWithoutSpeciesInput = {
  where: BreedScalarWhereInput;
  data: BreedUncheckedUpdateManyWithoutBreedsInput;
};

export type BreedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<BreedCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<BreedUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<BreedCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<BreedWhereUniqueInput>>;
  disconnect?: Maybe<Array<BreedWhereUniqueInput>>;
  delete?: Maybe<Array<BreedWhereUniqueInput>>;
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
  update?: Maybe<Array<BreedUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<BreedUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<BreedScalarWhereInput>>;
};

export type BreedUpdateManyWithoutSpeciesNestedInput = {
  create?: Maybe<Array<BreedCreateWithoutSpeciesInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutSpeciesInput>>;
  upsert?: Maybe<Array<BreedUpsertWithWhereUniqueWithoutSpeciesInput>>;
  createMany?: Maybe<BreedCreateManySpeciesInputEnvelope>;
  set?: Maybe<Array<BreedWhereUniqueInput>>;
  disconnect?: Maybe<Array<BreedWhereUniqueInput>>;
  delete?: Maybe<Array<BreedWhereUniqueInput>>;
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
  update?: Maybe<Array<BreedUpdateWithWhereUniqueWithoutSpeciesInput>>;
  updateMany?: Maybe<Array<BreedUpdateManyWithWhereWithoutSpeciesInput>>;
  deleteMany?: Maybe<Array<BreedScalarWhereInput>>;
};

export type BreedUpdateOneWithoutPetsNestedInput = {
  create?: Maybe<BreedUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<BreedCreateOrConnectWithoutPetsInput>;
  upsert?: Maybe<BreedUpsertWithoutPetsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BreedWhereUniqueInput>;
  update?: Maybe<BreedUncheckedUpdateWithoutPetsInput>;
};

export type BreedUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: BreedWhereUniqueInput;
  data: BreedUncheckedUpdateWithoutIntegrationInput;
};

export type BreedUpdateWithWhereUniqueWithoutSpeciesInput = {
  where: BreedWhereUniqueInput;
  data: BreedUncheckedUpdateWithoutSpeciesInput;
};

export type BreedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<SpeciesUpdateOneWithoutBreedsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutBreedModelNestedInput>;
};

export type BreedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBreedsNestedInput>;
  species?: Maybe<SpeciesUpdateOneWithoutBreedsNestedInput>;
};

export type BreedUpdateWithoutSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBreedsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutBreedModelNestedInput>;
};

export type BreedUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: BreedWhereUniqueInput;
  update: BreedUncheckedUpdateWithoutIntegrationInput;
  create: BreedUncheckedCreateWithoutIntegrationInput;
};

export type BreedUpsertWithWhereUniqueWithoutSpeciesInput = {
  where: BreedWhereUniqueInput;
  update: BreedUncheckedUpdateWithoutSpeciesInput;
  create: BreedUncheckedCreateWithoutSpeciesInput;
};

export type BreedUpsertWithoutPetsInput = {
  update: BreedUncheckedUpdateWithoutPetsInput;
  create: BreedUncheckedCreateWithoutPetsInput;
};

export type BreedWhereInput = {
  AND?: Maybe<Array<BreedWhereInput>>;
  OR?: Maybe<Array<BreedWhereInput>>;
  NOT?: Maybe<Array<BreedWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  speciesId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  species?: Maybe<SpeciesWhereInput>;
  pets?: Maybe<ClinicPetListRelationFilter>;
};

export type BreedWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  integrationId_pimsId?: Maybe<BreedIntegrationIdPimsIdCompoundUniqueInput>;
};

export type BulkPublishWorkflowEventSettingsInput = {
  workflowEventSettingIds: Array<Scalars['String']>;
  isPublished: Scalars['Boolean'];
};

export type BulkPublishWorkflowEventSettingsResponse = {
  __typename?: 'BulkPublishWorkflowEventSettingsResponse';
  count: Scalars['Int'];
};

export type CalculateMultiplePetsBenefitUsageInput = {
  organizationPetIds: Array<Scalars['String']>;
};

export type CalculateMultiplePetsBenefitUsageOutput = {
  __typename?: 'CalculateMultiplePetsBenefitUsageOutput';
  petUsages: Array<PetBenefitUsage>;
};

export type CalculatePetBenefitUsageInput = {
  organizationPetId: Scalars['String'];
};

export type CalculatePetBenefitUsageOutput = {
  __typename?: 'CalculatePetBenefitUsageOutput';
  usages: Array<UsageByBenefit>;
};

export type CallHistory = {
  __typename?: 'CallHistory';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status: CallStatus;
  /** duration of call in seconds */
  duration?: Maybe<Scalars['Int']>;
  clinicId: Scalars['String'];
  type: CallHistoryType;
  clinic: Clinic;
  recordings: Array<CallRecording>;
  participants: Array<CallParticipant>;
  _count: CallHistoryCountOutputType;
};


export type CallHistoryRecordingsArgs = {
  where?: Maybe<CallRecordingWhereInput>;
  orderBy?: Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CallRecordingScalarFieldEnum>;
};


export type CallHistoryParticipantsArgs = {
  where?: Maybe<CallParticipantWhereInput>;
  orderBy?: Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CallParticipantScalarFieldEnum>;
};

export type CallHistoryAvgAggregateOutputType = {
  __typename?: 'CallHistoryAvgAggregateOutputType';
  duration?: Maybe<Scalars['Float']>;
};

export type CallHistoryAvgOrderByAggregateInput = {
  duration?: Maybe<SortOrder>;
};

export type CallHistoryCountAggregateOutputType = {
  __typename?: 'CallHistoryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  provider: Scalars['Int'];
  sourceId: Scalars['Int'];
  from: Scalars['Int'];
  status: Scalars['Int'];
  duration: Scalars['Int'];
  clinicId: Scalars['Int'];
  type: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CallHistoryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type CallHistoryCountOutputType = {
  __typename?: 'CallHistoryCountOutputType';
  recordings: Scalars['Int'];
  participants: Scalars['Int'];
};

export type CallHistoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type: CallHistoryType;
  clinic: ClinicCreateNestedOneWithoutCallsInput;
  recordings?: Maybe<CallRecordingCreateNestedManyWithoutCallHistoryInput>;
  participants?: Maybe<CallParticipantCreateNestedManyWithoutCallHistoryInput>;
};

export type CallHistoryCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type: CallHistoryType;
};

export type CallHistoryCreateManyClinicInputEnvelope = {
  data: Array<CallHistoryCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallHistoryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId: Scalars['String'];
  type: CallHistoryType;
};

export type CallHistoryCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<CallHistoryCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<CallHistoryCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<CallHistoryCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
};

export type CallHistoryCreateNestedOneWithoutParticipantsInput = {
  create?: Maybe<CallHistoryUncheckedCreateWithoutParticipantsInput>;
  connectOrCreate?: Maybe<CallHistoryCreateOrConnectWithoutParticipantsInput>;
  connect?: Maybe<CallHistoryWhereUniqueInput>;
};

export type CallHistoryCreateNestedOneWithoutRecordingsInput = {
  create?: Maybe<CallHistoryUncheckedCreateWithoutRecordingsInput>;
  connectOrCreate?: Maybe<CallHistoryCreateOrConnectWithoutRecordingsInput>;
  connect?: Maybe<CallHistoryWhereUniqueInput>;
};

export type CallHistoryCreateOrConnectWithoutClinicInput = {
  where: CallHistoryWhereUniqueInput;
  create: CallHistoryUncheckedCreateWithoutClinicInput;
};

export type CallHistoryCreateOrConnectWithoutParticipantsInput = {
  where: CallHistoryWhereUniqueInput;
  create: CallHistoryUncheckedCreateWithoutParticipantsInput;
};

export type CallHistoryCreateOrConnectWithoutRecordingsInput = {
  where: CallHistoryWhereUniqueInput;
  create: CallHistoryUncheckedCreateWithoutRecordingsInput;
};

export type CallHistoryCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type: CallHistoryType;
  recordings?: Maybe<CallRecordingCreateNestedManyWithoutCallHistoryInput>;
  participants?: Maybe<CallParticipantCreateNestedManyWithoutCallHistoryInput>;
};

export type CallHistoryCreateWithoutParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type: CallHistoryType;
  clinic: ClinicCreateNestedOneWithoutCallsInput;
  recordings?: Maybe<CallRecordingCreateNestedManyWithoutCallHistoryInput>;
};

export type CallHistoryCreateWithoutRecordingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type: CallHistoryType;
  clinic: ClinicCreateNestedOneWithoutCallsInput;
  participants?: Maybe<CallParticipantCreateNestedManyWithoutCallHistoryInput>;
};

export type CallHistoryListRelationFilter = {
  every?: Maybe<CallHistoryWhereInput>;
  some?: Maybe<CallHistoryWhereInput>;
  none?: Maybe<CallHistoryWhereInput>;
};

export type CallHistoryMaxAggregateOutputType = {
  __typename?: 'CallHistoryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<CallHistoryType>;
};

export type CallHistoryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type CallHistoryMinAggregateOutputType = {
  __typename?: 'CallHistoryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<CallHistoryType>;
};

export type CallHistoryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type CallHistoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CallHistoryOrderByRelevanceFieldEnum {
  Id = 'id',
  SourceId = 'sourceId',
  From = 'from',
  ClinicId = 'clinicId'
}

export type CallHistoryOrderByRelevanceInput = {
  fields: Array<CallHistoryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CallHistoryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  _count?: Maybe<CallHistoryCountOrderByAggregateInput>;
  _avg?: Maybe<CallHistoryAvgOrderByAggregateInput>;
  _max?: Maybe<CallHistoryMaxOrderByAggregateInput>;
  _min?: Maybe<CallHistoryMinOrderByAggregateInput>;
  _sum?: Maybe<CallHistorySumOrderByAggregateInput>;
};

export type CallHistoryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  recordings?: Maybe<CallRecordingOrderByRelationAggregateInput>;
  participants?: Maybe<CallParticipantOrderByRelationAggregateInput>;
  _relevance?: Maybe<CallHistoryOrderByRelevanceInput>;
};

export type CallHistoryRelationFilter = {
  is?: Maybe<CallHistoryWhereInput>;
  isNot?: Maybe<CallHistoryWhereInput>;
};

export enum CallHistoryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Provider = 'provider',
  SourceId = 'sourceId',
  From = 'from',
  Status = 'status',
  Duration = 'duration',
  ClinicId = 'clinicId',
  Type = 'type'
}

export type CallHistoryScalarWhereInput = {
  AND?: Maybe<Array<CallHistoryScalarWhereInput>>;
  OR?: Maybe<Array<CallHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<CallHistoryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  provider?: Maybe<EnumCallProviderFilter>;
  sourceId?: Maybe<StringFilter>;
  from?: Maybe<StringFilter>;
  status?: Maybe<EnumCallStatusFilter>;
  duration?: Maybe<IntNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  type?: Maybe<EnumCallHistoryTypeFilter>;
};

export type CallHistoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CallHistoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CallHistoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CallHistoryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  provider?: Maybe<EnumCallProviderWithAggregatesFilter>;
  sourceId?: Maybe<StringWithAggregatesFilter>;
  from?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumCallStatusWithAggregatesFilter>;
  duration?: Maybe<IntNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumCallHistoryTypeWithAggregatesFilter>;
};

export type CallHistorySumAggregateOutputType = {
  __typename?: 'CallHistorySumAggregateOutputType';
  duration?: Maybe<Scalars['Int']>;
};

export type CallHistorySumOrderByAggregateInput = {
  duration?: Maybe<SortOrder>;
};

export enum CallHistoryType {
  Video = 'Video',
  Voice = 'Voice'
}

export type CallHistoryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId: Scalars['String'];
  type: CallHistoryType;
  recordings?: Maybe<CallRecordingUncheckedCreateNestedManyWithoutCallHistoryInput>;
  participants?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutCallHistoryInput>;
};

export type CallHistoryUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<CallHistoryCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<CallHistoryCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<CallHistoryCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
};

export type CallHistoryUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type: CallHistoryType;
  recordings?: Maybe<CallRecordingUncheckedCreateNestedManyWithoutCallHistoryInput>;
  participants?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutCallHistoryInput>;
};

export type CallHistoryUncheckedCreateWithoutParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId: Scalars['String'];
  type: CallHistoryType;
  recordings?: Maybe<CallRecordingUncheckedCreateNestedManyWithoutCallHistoryInput>;
};

export type CallHistoryUncheckedCreateWithoutRecordingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId: Scalars['String'];
  type: CallHistoryType;
  participants?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutCallHistoryInput>;
};

export type CallHistoryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<CallHistoryType>;
  recordings?: Maybe<CallRecordingUncheckedUpdateManyWithoutCallHistoryNestedInput>;
  participants?: Maybe<CallParticipantUncheckedUpdateManyWithoutCallHistoryNestedInput>;
};

export type CallHistoryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<CallHistoryType>;
};

export type CallHistoryUncheckedUpdateManyWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type?: Maybe<CallHistoryType>;
};

export type CallHistoryUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<CallHistoryCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<CallHistoryCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<CallHistoryUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<CallHistoryCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  delete?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  update?: Maybe<Array<CallHistoryUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<CallHistoryUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<CallHistoryScalarWhereInput>>;
};

export type CallHistoryUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type?: Maybe<CallHistoryType>;
  recordings?: Maybe<CallRecordingUncheckedUpdateManyWithoutCallHistoryNestedInput>;
  participants?: Maybe<CallParticipantUncheckedUpdateManyWithoutCallHistoryNestedInput>;
};

export type CallHistoryUncheckedUpdateWithoutParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<CallHistoryType>;
  recordings?: Maybe<CallRecordingUncheckedUpdateManyWithoutCallHistoryNestedInput>;
};

export type CallHistoryUncheckedUpdateWithoutRecordingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<CallHistoryType>;
  participants?: Maybe<CallParticipantUncheckedUpdateManyWithoutCallHistoryNestedInput>;
};

export type CallHistoryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type?: Maybe<CallHistoryType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutCallsNestedInput>;
  recordings?: Maybe<CallRecordingUpdateManyWithoutCallHistoryNestedInput>;
  participants?: Maybe<CallParticipantUpdateManyWithoutCallHistoryNestedInput>;
};

export type CallHistoryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type?: Maybe<CallHistoryType>;
};

export type CallHistoryUpdateManyWithWhereWithoutClinicInput = {
  where: CallHistoryScalarWhereInput;
  data: CallHistoryUncheckedUpdateManyWithoutCallsInput;
};

export type CallHistoryUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<CallHistoryCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<CallHistoryCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<CallHistoryUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<CallHistoryCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  delete?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  update?: Maybe<Array<CallHistoryUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<CallHistoryUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<CallHistoryScalarWhereInput>>;
};

export type CallHistoryUpdateOneRequiredWithoutParticipantsNestedInput = {
  create?: Maybe<CallHistoryUncheckedCreateWithoutParticipantsInput>;
  connectOrCreate?: Maybe<CallHistoryCreateOrConnectWithoutParticipantsInput>;
  upsert?: Maybe<CallHistoryUpsertWithoutParticipantsInput>;
  connect?: Maybe<CallHistoryWhereUniqueInput>;
  update?: Maybe<CallHistoryUncheckedUpdateWithoutParticipantsInput>;
};

export type CallHistoryUpdateOneRequiredWithoutRecordingsNestedInput = {
  create?: Maybe<CallHistoryUncheckedCreateWithoutRecordingsInput>;
  connectOrCreate?: Maybe<CallHistoryCreateOrConnectWithoutRecordingsInput>;
  upsert?: Maybe<CallHistoryUpsertWithoutRecordingsInput>;
  connect?: Maybe<CallHistoryWhereUniqueInput>;
  update?: Maybe<CallHistoryUncheckedUpdateWithoutRecordingsInput>;
};

export type CallHistoryUpdateWithWhereUniqueWithoutClinicInput = {
  where: CallHistoryWhereUniqueInput;
  data: CallHistoryUncheckedUpdateWithoutClinicInput;
};

export type CallHistoryUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type?: Maybe<CallHistoryType>;
  recordings?: Maybe<CallRecordingUpdateManyWithoutCallHistoryNestedInput>;
  participants?: Maybe<CallParticipantUpdateManyWithoutCallHistoryNestedInput>;
};

export type CallHistoryUpdateWithoutParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type?: Maybe<CallHistoryType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutCallsNestedInput>;
  recordings?: Maybe<CallRecordingUpdateManyWithoutCallHistoryNestedInput>;
};

export type CallHistoryUpdateWithoutRecordingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type?: Maybe<CallHistoryType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutCallsNestedInput>;
  participants?: Maybe<CallParticipantUpdateManyWithoutCallHistoryNestedInput>;
};

export type CallHistoryUpsertWithWhereUniqueWithoutClinicInput = {
  where: CallHistoryWhereUniqueInput;
  update: CallHistoryUncheckedUpdateWithoutClinicInput;
  create: CallHistoryUncheckedCreateWithoutClinicInput;
};

export type CallHistoryUpsertWithoutParticipantsInput = {
  update: CallHistoryUncheckedUpdateWithoutParticipantsInput;
  create: CallHistoryUncheckedCreateWithoutParticipantsInput;
};

export type CallHistoryUpsertWithoutRecordingsInput = {
  update: CallHistoryUncheckedUpdateWithoutRecordingsInput;
  create: CallHistoryUncheckedCreateWithoutRecordingsInput;
};

export type CallHistoryWhereInput = {
  AND?: Maybe<Array<CallHistoryWhereInput>>;
  OR?: Maybe<Array<CallHistoryWhereInput>>;
  NOT?: Maybe<Array<CallHistoryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  provider?: Maybe<EnumCallProviderFilter>;
  sourceId?: Maybe<StringFilter>;
  from?: Maybe<StringFilter>;
  status?: Maybe<EnumCallStatusFilter>;
  duration?: Maybe<IntNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  type?: Maybe<EnumCallHistoryTypeFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  recordings?: Maybe<CallRecordingListRelationFilter>;
  participants?: Maybe<CallParticipantListRelationFilter>;
};

export type CallHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
};

export type CallParticipant = {
  __typename?: 'CallParticipant';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId: Scalars['String'];
  callHistory: CallHistory;
  clinicPetParent?: Maybe<ClinicPetParent>;
  user?: Maybe<User>;
};

export type CallParticipantCallHistoryIdClinicPetParentIdCompoundUniqueInput = {
  callHistoryId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type CallParticipantCallHistoryIdUserIdCompoundUniqueInput = {
  callHistoryId: Scalars['String'];
  userId: Scalars['String'];
};

export type CallParticipantCountAggregateOutputType = {
  __typename?: 'CallParticipantCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  number: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  userId: Scalars['Int'];
  callHistoryId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CallParticipantCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
};

export type CallParticipantCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  callHistory: CallHistoryCreateNestedOneWithoutParticipantsInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutCallsInput>;
  user?: Maybe<UserCreateNestedOneWithoutCallsInput>;
};

export type CallParticipantCreateManyCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantCreateManyCallHistoryInputEnvelope = {
  data: Array<CallParticipantCreateManyCallHistoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallParticipantCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId: Scalars['String'];
};

export type CallParticipantCreateManyClinicPetParentInputEnvelope = {
  data: Array<CallParticipantCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallParticipantCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId: Scalars['String'];
};

export type CallParticipantCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  callHistoryId: Scalars['String'];
};

export type CallParticipantCreateManyUserInputEnvelope = {
  data: Array<CallParticipantCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallParticipantCreateNestedManyWithoutCallHistoryInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutCallHistoryInput>>;
  createMany?: Maybe<CallParticipantCreateManyCallHistoryInputEnvelope>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
};

export type CallParticipantCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<CallParticipantCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
};

export type CallParticipantCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<CallParticipantCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
};

export type CallParticipantCreateOrConnectWithoutCallHistoryInput = {
  where: CallParticipantWhereUniqueInput;
  create: CallParticipantUncheckedCreateWithoutCallHistoryInput;
};

export type CallParticipantCreateOrConnectWithoutClinicPetParentInput = {
  where: CallParticipantWhereUniqueInput;
  create: CallParticipantUncheckedCreateWithoutClinicPetParentInput;
};

export type CallParticipantCreateOrConnectWithoutUserInput = {
  where: CallParticipantWhereUniqueInput;
  create: CallParticipantUncheckedCreateWithoutUserInput;
};

export type CallParticipantCreateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutCallsInput>;
  user?: Maybe<UserCreateNestedOneWithoutCallsInput>;
};

export type CallParticipantCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  callHistory: CallHistoryCreateNestedOneWithoutParticipantsInput;
  user?: Maybe<UserCreateNestedOneWithoutCallsInput>;
};

export type CallParticipantCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  callHistory: CallHistoryCreateNestedOneWithoutParticipantsInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutCallsInput>;
};

export type CallParticipantListRelationFilter = {
  every?: Maybe<CallParticipantWhereInput>;
  some?: Maybe<CallParticipantWhereInput>;
  none?: Maybe<CallParticipantWhereInput>;
};

export type CallParticipantMaxAggregateOutputType = {
  __typename?: 'CallParticipantMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId?: Maybe<Scalars['String']>;
};

export type CallParticipantMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
};

export type CallParticipantMinAggregateOutputType = {
  __typename?: 'CallParticipantMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId?: Maybe<Scalars['String']>;
};

export type CallParticipantMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
};

export type CallParticipantOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CallParticipantOrderByRelevanceFieldEnum {
  Id = 'id',
  Number = 'number',
  ClinicPetParentId = 'clinicPetParentId',
  UserId = 'userId',
  CallHistoryId = 'callHistoryId'
}

export type CallParticipantOrderByRelevanceInput = {
  fields: Array<CallParticipantOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CallParticipantOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
  _count?: Maybe<CallParticipantCountOrderByAggregateInput>;
  _max?: Maybe<CallParticipantMaxOrderByAggregateInput>;
  _min?: Maybe<CallParticipantMinOrderByAggregateInput>;
};

export type CallParticipantOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
  callHistory?: Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CallParticipantOrderByRelevanceInput>;
};

export enum CallParticipantScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Number = 'number',
  ClinicPetParentId = 'clinicPetParentId',
  UserId = 'userId',
  CallHistoryId = 'callHistoryId'
}

export type CallParticipantScalarWhereInput = {
  AND?: Maybe<Array<CallParticipantScalarWhereInput>>;
  OR?: Maybe<Array<CallParticipantScalarWhereInput>>;
  NOT?: Maybe<Array<CallParticipantScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  number?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  callHistoryId?: Maybe<StringFilter>;
};

export type CallParticipantScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CallParticipantScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CallParticipantScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CallParticipantScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  number?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  callHistoryId?: Maybe<StringWithAggregatesFilter>;
};

export type CallParticipantUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId: Scalars['String'];
};

export type CallParticipantUncheckedCreateNestedManyWithoutCallHistoryInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutCallHistoryInput>>;
  createMany?: Maybe<CallParticipantCreateManyCallHistoryInputEnvelope>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
};

export type CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<CallParticipantCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
};

export type CallParticipantUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<CallParticipantCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
};

export type CallParticipantUncheckedCreateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId: Scalars['String'];
};

export type CallParticipantUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  callHistoryId: Scalars['String'];
};

export type CallParticipantUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateManyWithoutCallHistoryNestedInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutCallHistoryInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutCallHistoryInput>>;
  createMany?: Maybe<CallParticipantCreateManyCallHistoryInputEnvelope>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutCallHistoryInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutCallHistoryInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
};

export type CallParticipantUncheckedUpdateManyWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<CallParticipantCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
};

export type CallParticipantUncheckedUpdateManyWithoutParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<CallParticipantCreateManyUserInputEnvelope>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
};

export type CallParticipantUncheckedUpdateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  callHistoryId?: Maybe<Scalars['String']>;
};

export type CallParticipantUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutParticipantsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutCallsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutCallsNestedInput>;
};

export type CallParticipantUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
};

export type CallParticipantUpdateManyWithWhereWithoutCallHistoryInput = {
  where: CallParticipantScalarWhereInput;
  data: CallParticipantUncheckedUpdateManyWithoutParticipantsInput;
};

export type CallParticipantUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: CallParticipantScalarWhereInput;
  data: CallParticipantUncheckedUpdateManyWithoutCallsInput;
};

export type CallParticipantUpdateManyWithWhereWithoutUserInput = {
  where: CallParticipantScalarWhereInput;
  data: CallParticipantUncheckedUpdateManyWithoutCallsInput;
};

export type CallParticipantUpdateManyWithoutCallHistoryNestedInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutCallHistoryInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutCallHistoryInput>>;
  createMany?: Maybe<CallParticipantCreateManyCallHistoryInputEnvelope>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutCallHistoryInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutCallHistoryInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
};

export type CallParticipantUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<CallParticipantCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
};

export type CallParticipantUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<CallParticipantCreateManyUserInputEnvelope>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
};

export type CallParticipantUpdateWithWhereUniqueWithoutCallHistoryInput = {
  where: CallParticipantWhereUniqueInput;
  data: CallParticipantUncheckedUpdateWithoutCallHistoryInput;
};

export type CallParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: CallParticipantWhereUniqueInput;
  data: CallParticipantUncheckedUpdateWithoutClinicPetParentInput;
};

export type CallParticipantUpdateWithWhereUniqueWithoutUserInput = {
  where: CallParticipantWhereUniqueInput;
  data: CallParticipantUncheckedUpdateWithoutUserInput;
};

export type CallParticipantUpdateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutCallsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutCallsNestedInput>;
};

export type CallParticipantUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutParticipantsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutCallsNestedInput>;
};

export type CallParticipantUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutParticipantsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutCallsNestedInput>;
};

export type CallParticipantUpsertWithWhereUniqueWithoutCallHistoryInput = {
  where: CallParticipantWhereUniqueInput;
  update: CallParticipantUncheckedUpdateWithoutCallHistoryInput;
  create: CallParticipantUncheckedCreateWithoutCallHistoryInput;
};

export type CallParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: CallParticipantWhereUniqueInput;
  update: CallParticipantUncheckedUpdateWithoutClinicPetParentInput;
  create: CallParticipantUncheckedCreateWithoutClinicPetParentInput;
};

export type CallParticipantUpsertWithWhereUniqueWithoutUserInput = {
  where: CallParticipantWhereUniqueInput;
  update: CallParticipantUncheckedUpdateWithoutUserInput;
  create: CallParticipantUncheckedCreateWithoutUserInput;
};

export type CallParticipantWhereInput = {
  AND?: Maybe<Array<CallParticipantWhereInput>>;
  OR?: Maybe<Array<CallParticipantWhereInput>>;
  NOT?: Maybe<Array<CallParticipantWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  number?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  callHistoryId?: Maybe<StringFilter>;
  callHistory?: Maybe<CallHistoryWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  user?: Maybe<UserWhereInput>;
};

export type CallParticipantWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  callHistoryId_clinicPetParentId?: Maybe<CallParticipantCallHistoryIdClinicPetParentIdCompoundUniqueInput>;
  callHistoryId_userId?: Maybe<CallParticipantCallHistoryIdUserIdCompoundUniqueInput>;
};

export enum CallProvider {
  Twilio = 'Twilio'
}

export type CallRecording = {
  __typename?: 'CallRecording';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  callHistoryId: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  downloadStatus: RecordingDownloadStatus;
  s3Uri: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  callHistory: CallHistory;
  twilioCallRecording?: Maybe<TwilioCallRecording>;
};

export type CallRecordingAvgAggregateOutputType = {
  __typename?: 'CallRecordingAvgAggregateOutputType';
  duration?: Maybe<Scalars['Float']>;
};

export type CallRecordingAvgOrderByAggregateInput = {
  duration?: Maybe<SortOrder>;
};

export type CallRecordingCountAggregateOutputType = {
  __typename?: 'CallRecordingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  callHistoryId: Scalars['Int'];
  duration: Scalars['Int'];
  downloadStatus: Scalars['Int'];
  s3Uri: Scalars['Int'];
  isDeleted: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CallRecordingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  downloadStatus?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type CallRecordingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  callHistory: CallHistoryCreateNestedOneWithoutRecordingsInput;
  twilioCallRecording?: Maybe<TwilioCallRecordingCreateNestedOneWithoutCallRecordingInput>;
};

export type CallRecordingCreateManyCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingCreateManyCallHistoryInputEnvelope = {
  data: Array<CallRecordingCreateManyCallHistoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingCreateNestedManyWithoutCallHistoryInput = {
  create?: Maybe<Array<CallRecordingCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallRecordingCreateOrConnectWithoutCallHistoryInput>>;
  createMany?: Maybe<CallRecordingCreateManyCallHistoryInputEnvelope>;
  connect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
};

export type CallRecordingCreateNestedOneWithoutTwilioCallRecordingInput = {
  create?: Maybe<CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput>;
  connectOrCreate?: Maybe<CallRecordingCreateOrConnectWithoutTwilioCallRecordingInput>;
  connect?: Maybe<CallRecordingWhereUniqueInput>;
};

export type CallRecordingCreateOrConnectWithoutCallHistoryInput = {
  where: CallRecordingWhereUniqueInput;
  create: CallRecordingUncheckedCreateWithoutCallHistoryInput;
};

export type CallRecordingCreateOrConnectWithoutTwilioCallRecordingInput = {
  where: CallRecordingWhereUniqueInput;
  create: CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput;
};

export type CallRecordingCreateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingCreateNestedOneWithoutCallRecordingInput>;
};

export type CallRecordingCreateWithoutTwilioCallRecordingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  callHistory: CallHistoryCreateNestedOneWithoutRecordingsInput;
};

export type CallRecordingListRelationFilter = {
  every?: Maybe<CallRecordingWhereInput>;
  some?: Maybe<CallRecordingWhereInput>;
  none?: Maybe<CallRecordingWhereInput>;
};

export type CallRecordingMaxAggregateOutputType = {
  __typename?: 'CallRecordingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  downloadStatus?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type CallRecordingMinAggregateOutputType = {
  __typename?: 'CallRecordingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  downloadStatus?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type CallRecordingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CallRecordingOrderByRelevanceFieldEnum {
  Id = 'id',
  CallHistoryId = 'callHistoryId',
  S3Uri = 's3Uri'
}

export type CallRecordingOrderByRelevanceInput = {
  fields: Array<CallRecordingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CallRecordingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  downloadStatus?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _count?: Maybe<CallRecordingCountOrderByAggregateInput>;
  _avg?: Maybe<CallRecordingAvgOrderByAggregateInput>;
  _max?: Maybe<CallRecordingMaxOrderByAggregateInput>;
  _min?: Maybe<CallRecordingMinOrderByAggregateInput>;
  _sum?: Maybe<CallRecordingSumOrderByAggregateInput>;
};

export type CallRecordingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  downloadStatus?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  callHistory?: Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>;
  twilioCallRecording?: Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CallRecordingOrderByRelevanceInput>;
};

export type CallRecordingRelationFilter = {
  is?: Maybe<CallRecordingWhereInput>;
  isNot?: Maybe<CallRecordingWhereInput>;
};

export enum CallRecordingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CallHistoryId = 'callHistoryId',
  Duration = 'duration',
  DownloadStatus = 'downloadStatus',
  S3Uri = 's3Uri',
  IsDeleted = 'isDeleted'
}

export type CallRecordingScalarWhereInput = {
  AND?: Maybe<Array<CallRecordingScalarWhereInput>>;
  OR?: Maybe<Array<CallRecordingScalarWhereInput>>;
  NOT?: Maybe<Array<CallRecordingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  callHistoryId?: Maybe<StringFilter>;
  duration?: Maybe<IntNullableFilter>;
  downloadStatus?: Maybe<EnumRecordingDownloadStatusFilter>;
  s3Uri?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type CallRecordingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CallRecordingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CallRecordingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CallRecordingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  callHistoryId?: Maybe<StringWithAggregatesFilter>;
  duration?: Maybe<IntNullableWithAggregatesFilter>;
  downloadStatus?: Maybe<EnumRecordingDownloadStatusWithAggregatesFilter>;
  s3Uri?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
};

export type CallRecordingSumAggregateOutputType = {
  __typename?: 'CallRecordingSumAggregateOutputType';
  duration?: Maybe<Scalars['Int']>;
};

export type CallRecordingSumOrderByAggregateInput = {
  duration?: Maybe<SortOrder>;
};

export type CallRecordingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUncheckedCreateNestedOneWithoutCallRecordingInput>;
};

export type CallRecordingUncheckedCreateNestedManyWithoutCallHistoryInput = {
  create?: Maybe<Array<CallRecordingCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallRecordingCreateOrConnectWithoutCallHistoryInput>>;
  createMany?: Maybe<CallRecordingCreateManyCallHistoryInputEnvelope>;
  connect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
};

export type CallRecordingUncheckedCreateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUncheckedCreateNestedOneWithoutCallRecordingInput>;
};

export type CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUncheckedUpdateOneWithoutCallRecordingNestedInput>;
};

export type CallRecordingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUncheckedUpdateManyWithoutCallHistoryNestedInput = {
  create?: Maybe<Array<CallRecordingCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallRecordingCreateOrConnectWithoutCallHistoryInput>>;
  upsert?: Maybe<Array<CallRecordingUpsertWithWhereUniqueWithoutCallHistoryInput>>;
  createMany?: Maybe<CallRecordingCreateManyCallHistoryInputEnvelope>;
  set?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  delete?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  connect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  update?: Maybe<Array<CallRecordingUpdateWithWhereUniqueWithoutCallHistoryInput>>;
  updateMany?: Maybe<Array<CallRecordingUpdateManyWithWhereWithoutCallHistoryInput>>;
  deleteMany?: Maybe<Array<CallRecordingScalarWhereInput>>;
};

export type CallRecordingUncheckedUpdateManyWithoutRecordingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUncheckedUpdateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUncheckedUpdateOneWithoutCallRecordingNestedInput>;
};

export type CallRecordingUncheckedUpdateWithoutTwilioCallRecordingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutRecordingsNestedInput>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUpdateOneWithoutCallRecordingNestedInput>;
  status: RecordingControlStatus;
};

export type CallRecordingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUpdateManyWithWhereWithoutCallHistoryInput = {
  where: CallRecordingScalarWhereInput;
  data: CallRecordingUncheckedUpdateManyWithoutRecordingsInput;
};

export type CallRecordingUpdateManyWithoutCallHistoryNestedInput = {
  create?: Maybe<Array<CallRecordingCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallRecordingCreateOrConnectWithoutCallHistoryInput>>;
  upsert?: Maybe<Array<CallRecordingUpsertWithWhereUniqueWithoutCallHistoryInput>>;
  createMany?: Maybe<CallRecordingCreateManyCallHistoryInputEnvelope>;
  set?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  delete?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  connect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  update?: Maybe<Array<CallRecordingUpdateWithWhereUniqueWithoutCallHistoryInput>>;
  updateMany?: Maybe<Array<CallRecordingUpdateManyWithWhereWithoutCallHistoryInput>>;
  deleteMany?: Maybe<Array<CallRecordingScalarWhereInput>>;
};

export type CallRecordingUpdateOneRequiredWithoutTwilioCallRecordingNestedInput = {
  create?: Maybe<CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput>;
  connectOrCreate?: Maybe<CallRecordingCreateOrConnectWithoutTwilioCallRecordingInput>;
  upsert?: Maybe<CallRecordingUpsertWithoutTwilioCallRecordingInput>;
  connect?: Maybe<CallRecordingWhereUniqueInput>;
  update?: Maybe<CallRecordingUncheckedUpdateWithoutTwilioCallRecordingInput>;
};

export type CallRecordingUpdateWithWhereUniqueWithoutCallHistoryInput = {
  where: CallRecordingWhereUniqueInput;
  data: CallRecordingUncheckedUpdateWithoutCallHistoryInput;
};

export type CallRecordingUpdateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUpdateOneWithoutCallRecordingNestedInput>;
};

export type CallRecordingUpdateWithoutTwilioCallRecordingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutRecordingsNestedInput>;
};

export type CallRecordingUpsertWithWhereUniqueWithoutCallHistoryInput = {
  where: CallRecordingWhereUniqueInput;
  update: CallRecordingUncheckedUpdateWithoutCallHistoryInput;
  create: CallRecordingUncheckedCreateWithoutCallHistoryInput;
};

export type CallRecordingUpsertWithoutTwilioCallRecordingInput = {
  update: CallRecordingUncheckedUpdateWithoutTwilioCallRecordingInput;
  create: CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput;
};

export type CallRecordingWhereInput = {
  AND?: Maybe<Array<CallRecordingWhereInput>>;
  OR?: Maybe<Array<CallRecordingWhereInput>>;
  NOT?: Maybe<Array<CallRecordingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  callHistoryId?: Maybe<StringFilter>;
  duration?: Maybe<IntNullableFilter>;
  downloadStatus?: Maybe<EnumRecordingDownloadStatusFilter>;
  s3Uri?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  callHistory?: Maybe<CallHistoryWhereInput>;
  twilioCallRecording?: Maybe<TwilioCallRecordingWhereInput>;
};

export type CallRecordingWhereUniqueInput = {
  id: Scalars['String'];
};

export enum CallStatus {
  Queued = 'Queued',
  Ringing = 'Ringing',
  InProgress = 'InProgress',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Failed = 'Failed',
  Busy = 'Busy',
  NoAnswer = 'NoAnswer'
}

export enum CampaignRegistryBrandIdentityStatus {
  SelfDeclared = 'SELF_DECLARED',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
  VettedVerified = 'VETTED_VERIFIED'
}

export type CampaignRegistryCampaign = {
  __typename?: 'CampaignRegistryCampaign';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  clinicSettingId: Scalars['String'];
  sourceId: Scalars['String'];
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  clinicPhoneNumbers: Array<ClinicPhoneNumber>;
  clinicSetting: ClinicSetting;
  _count: CampaignRegistryCampaignCountOutputType;
};


export type CampaignRegistryCampaignClinicPhoneNumbersArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
  orderBy?: Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPhoneNumberScalarFieldEnum>;
};

export type CampaignRegistryCampaignCountAggregateOutputType = {
  __typename?: 'CampaignRegistryCampaignCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  importedAt: Scalars['Int'];
  isDeleted: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  sourceId: Scalars['Int'];
  sharingStatus: Scalars['Int'];
  upstreamCnpId: Scalars['Int'];
  mnoMetaData: Scalars['Int'];
  settings: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CampaignRegistryCampaignCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  importedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  sharingStatus?: Maybe<SortOrder>;
  upstreamCnpId?: Maybe<SortOrder>;
  mnoMetaData?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
};

export type CampaignRegistryCampaignCountOutputType = {
  __typename?: 'CampaignRegistryCampaignCountOutputType';
  clinicPhoneNumbers: Scalars['Int'];
};

export type CampaignRegistryCampaignCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sourceId: Scalars['String'];
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutCampaignRegistryCampaignInput>;
  clinicSetting: ClinicSettingCreateNestedOneWithoutCampaignRegistryCampaignsInput;
};

export type CampaignRegistryCampaignCreateManyClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sourceId: Scalars['String'];
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type CampaignRegistryCampaignCreateManyClinicSettingInputEnvelope = {
  data: Array<CampaignRegistryCampaignCreateManyClinicSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CampaignRegistryCampaignCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicSettingId: Scalars['String'];
  sourceId: Scalars['String'];
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type CampaignRegistryCampaignCreateNestedManyWithoutClinicSettingInput = {
  create?: Maybe<Array<CampaignRegistryCampaignCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<CampaignRegistryCampaignCreateOrConnectWithoutClinicSettingInput>>;
  createMany?: Maybe<CampaignRegistryCampaignCreateManyClinicSettingInputEnvelope>;
  connect?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
};

export type CampaignRegistryCampaignCreateNestedOneWithoutClinicPhoneNumbersInput = {
  create?: Maybe<CampaignRegistryCampaignUncheckedCreateWithoutClinicPhoneNumbersInput>;
  connectOrCreate?: Maybe<CampaignRegistryCampaignCreateOrConnectWithoutClinicPhoneNumbersInput>;
  connect?: Maybe<CampaignRegistryCampaignWhereUniqueInput>;
};

export type CampaignRegistryCampaignCreateOrConnectWithoutClinicPhoneNumbersInput = {
  where: CampaignRegistryCampaignWhereUniqueInput;
  create: CampaignRegistryCampaignUncheckedCreateWithoutClinicPhoneNumbersInput;
};

export type CampaignRegistryCampaignCreateOrConnectWithoutClinicSettingInput = {
  where: CampaignRegistryCampaignWhereUniqueInput;
  create: CampaignRegistryCampaignUncheckedCreateWithoutClinicSettingInput;
};

export type CampaignRegistryCampaignCreateWithoutClinicPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sourceId: Scalars['String'];
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  clinicSetting: ClinicSettingCreateNestedOneWithoutCampaignRegistryCampaignsInput;
};

export type CampaignRegistryCampaignCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sourceId: Scalars['String'];
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutCampaignRegistryCampaignInput>;
};

export type CampaignRegistryCampaignListRelationFilter = {
  every?: Maybe<CampaignRegistryCampaignWhereInput>;
  some?: Maybe<CampaignRegistryCampaignWhereInput>;
  none?: Maybe<CampaignRegistryCampaignWhereInput>;
};

export type CampaignRegistryCampaignMaxAggregateOutputType = {
  __typename?: 'CampaignRegistryCampaignMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  importedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  sharingStatus?: Maybe<SortOrder>;
  upstreamCnpId?: Maybe<SortOrder>;
};

export type CampaignRegistryCampaignMinAggregateOutputType = {
  __typename?: 'CampaignRegistryCampaignMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  importedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  sharingStatus?: Maybe<SortOrder>;
  upstreamCnpId?: Maybe<SortOrder>;
};

export type CampaignRegistryCampaignOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CampaignRegistryCampaignOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicSettingId = 'clinicSettingId',
  SourceId = 'sourceId',
  UpstreamCnpId = 'upstreamCnpId'
}

export type CampaignRegistryCampaignOrderByRelevanceInput = {
  fields: Array<CampaignRegistryCampaignOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CampaignRegistryCampaignOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  importedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  sharingStatus?: Maybe<SortOrder>;
  upstreamCnpId?: Maybe<SortOrder>;
  mnoMetaData?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  _count?: Maybe<CampaignRegistryCampaignCountOrderByAggregateInput>;
  _max?: Maybe<CampaignRegistryCampaignMaxOrderByAggregateInput>;
  _min?: Maybe<CampaignRegistryCampaignMinOrderByAggregateInput>;
};

export type CampaignRegistryCampaignOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  importedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  sharingStatus?: Maybe<SortOrder>;
  upstreamCnpId?: Maybe<SortOrder>;
  mnoMetaData?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberOrderByRelationAggregateInput>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CampaignRegistryCampaignOrderByRelevanceInput>;
};

export type CampaignRegistryCampaignRelationFilter = {
  is?: Maybe<CampaignRegistryCampaignWhereInput>;
  isNot?: Maybe<CampaignRegistryCampaignWhereInput>;
};

export enum CampaignRegistryCampaignScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ImportedAt = 'importedAt',
  IsDeleted = 'isDeleted',
  ClinicSettingId = 'clinicSettingId',
  SourceId = 'sourceId',
  SharingStatus = 'sharingStatus',
  UpstreamCnpId = 'upstreamCnpId',
  MnoMetaData = 'mnoMetaData',
  Settings = 'settings'
}

export type CampaignRegistryCampaignScalarWhereInput = {
  AND?: Maybe<Array<CampaignRegistryCampaignScalarWhereInput>>;
  OR?: Maybe<Array<CampaignRegistryCampaignScalarWhereInput>>;
  NOT?: Maybe<Array<CampaignRegistryCampaignScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  importedAt?: Maybe<DateTimeNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinicSettingId?: Maybe<StringFilter>;
  sourceId?: Maybe<StringFilter>;
  sharingStatus?: Maybe<EnumCampaignRegistryCampaignSharingStatusNullableFilter>;
  upstreamCnpId?: Maybe<StringNullableFilter>;
  mnoMetaData?: Maybe<JsonNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
};

export type CampaignRegistryCampaignScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CampaignRegistryCampaignScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CampaignRegistryCampaignScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CampaignRegistryCampaignScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  importedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  clinicSettingId?: Maybe<StringWithAggregatesFilter>;
  sourceId?: Maybe<StringWithAggregatesFilter>;
  sharingStatus?: Maybe<EnumCampaignRegistryCampaignSharingStatusNullableWithAggregatesFilter>;
  upstreamCnpId?: Maybe<StringNullableWithAggregatesFilter>;
  mnoMetaData?: Maybe<JsonNullableWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum CampaignRegistryCampaignSharingStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED'
}

export type CampaignRegistryCampaignUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicSettingId: Scalars['String'];
  sourceId: Scalars['String'];
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutCampaignRegistryCampaignInput>;
};

export type CampaignRegistryCampaignUncheckedCreateNestedManyWithoutClinicSettingInput = {
  create?: Maybe<Array<CampaignRegistryCampaignCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<CampaignRegistryCampaignCreateOrConnectWithoutClinicSettingInput>>;
  createMany?: Maybe<CampaignRegistryCampaignCreateManyClinicSettingInputEnvelope>;
  connect?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
};

export type CampaignRegistryCampaignUncheckedCreateWithoutClinicPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicSettingId: Scalars['String'];
  sourceId: Scalars['String'];
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type CampaignRegistryCampaignUncheckedCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sourceId: Scalars['String'];
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutCampaignRegistryCampaignInput>;
};

export type CampaignRegistryCampaignUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutCampaignRegistryCampaignNestedInput>;
};

export type CampaignRegistryCampaignUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type CampaignRegistryCampaignUncheckedUpdateManyWithoutCampaignRegistryCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sourceId?: Maybe<Scalars['String']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type CampaignRegistryCampaignUncheckedUpdateManyWithoutClinicSettingNestedInput = {
  create?: Maybe<Array<CampaignRegistryCampaignCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<CampaignRegistryCampaignCreateOrConnectWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<CampaignRegistryCampaignUpsertWithWhereUniqueWithoutClinicSettingInput>>;
  createMany?: Maybe<CampaignRegistryCampaignCreateManyClinicSettingInputEnvelope>;
  set?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  update?: Maybe<Array<CampaignRegistryCampaignUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<CampaignRegistryCampaignUpdateManyWithWhereWithoutClinicSettingInput>>;
  deleteMany?: Maybe<Array<CampaignRegistryCampaignScalarWhereInput>>;
};

export type CampaignRegistryCampaignUncheckedUpdateWithoutClinicPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type CampaignRegistryCampaignUncheckedUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sourceId?: Maybe<Scalars['String']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutCampaignRegistryCampaignNestedInput>;
};

export type CampaignRegistryCampaignUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sourceId?: Maybe<Scalars['String']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutCampaignRegistryCampaignNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutCampaignRegistryCampaignsNestedInput>;
};

export type CampaignRegistryCampaignUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sourceId?: Maybe<Scalars['String']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type CampaignRegistryCampaignUpdateManyWithWhereWithoutClinicSettingInput = {
  where: CampaignRegistryCampaignScalarWhereInput;
  data: CampaignRegistryCampaignUncheckedUpdateManyWithoutCampaignRegistryCampaignsInput;
};

export type CampaignRegistryCampaignUpdateManyWithoutClinicSettingNestedInput = {
  create?: Maybe<Array<CampaignRegistryCampaignCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<CampaignRegistryCampaignCreateOrConnectWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<CampaignRegistryCampaignUpsertWithWhereUniqueWithoutClinicSettingInput>>;
  createMany?: Maybe<CampaignRegistryCampaignCreateManyClinicSettingInputEnvelope>;
  set?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  update?: Maybe<Array<CampaignRegistryCampaignUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<CampaignRegistryCampaignUpdateManyWithWhereWithoutClinicSettingInput>>;
  deleteMany?: Maybe<Array<CampaignRegistryCampaignScalarWhereInput>>;
};

export type CampaignRegistryCampaignUpdateOneWithoutClinicPhoneNumbersNestedInput = {
  create?: Maybe<CampaignRegistryCampaignUncheckedCreateWithoutClinicPhoneNumbersInput>;
  connectOrCreate?: Maybe<CampaignRegistryCampaignCreateOrConnectWithoutClinicPhoneNumbersInput>;
  upsert?: Maybe<CampaignRegistryCampaignUpsertWithoutClinicPhoneNumbersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CampaignRegistryCampaignWhereUniqueInput>;
  update?: Maybe<CampaignRegistryCampaignUncheckedUpdateWithoutClinicPhoneNumbersInput>;
};

export type CampaignRegistryCampaignUpdateWithWhereUniqueWithoutClinicSettingInput = {
  where: CampaignRegistryCampaignWhereUniqueInput;
  data: CampaignRegistryCampaignUncheckedUpdateWithoutClinicSettingInput;
};

export type CampaignRegistryCampaignUpdateWithoutClinicPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sourceId?: Maybe<Scalars['String']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutCampaignRegistryCampaignsNestedInput>;
};

export type CampaignRegistryCampaignUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sourceId?: Maybe<Scalars['String']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  upstreamCnpId?: Maybe<Scalars['String']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutCampaignRegistryCampaignNestedInput>;
};

export type CampaignRegistryCampaignUpsertWithWhereUniqueWithoutClinicSettingInput = {
  where: CampaignRegistryCampaignWhereUniqueInput;
  update: CampaignRegistryCampaignUncheckedUpdateWithoutClinicSettingInput;
  create: CampaignRegistryCampaignUncheckedCreateWithoutClinicSettingInput;
};

export type CampaignRegistryCampaignUpsertWithoutClinicPhoneNumbersInput = {
  update: CampaignRegistryCampaignUncheckedUpdateWithoutClinicPhoneNumbersInput;
  create: CampaignRegistryCampaignUncheckedCreateWithoutClinicPhoneNumbersInput;
};

export type CampaignRegistryCampaignWhereInput = {
  AND?: Maybe<Array<CampaignRegistryCampaignWhereInput>>;
  OR?: Maybe<Array<CampaignRegistryCampaignWhereInput>>;
  NOT?: Maybe<Array<CampaignRegistryCampaignWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  importedAt?: Maybe<DateTimeNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinicSettingId?: Maybe<StringFilter>;
  sourceId?: Maybe<StringFilter>;
  sharingStatus?: Maybe<EnumCampaignRegistryCampaignSharingStatusNullableFilter>;
  upstreamCnpId?: Maybe<StringNullableFilter>;
  mnoMetaData?: Maybe<JsonNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberListRelationFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
};

export type CampaignRegistryCampaignWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
};

export type CanManuallyRetryPaymentWritebackOutput = {
  __typename?: 'CanManuallyRetryPaymentWritebackOutput';
  retryable: Scalars['Boolean'];
  reason?: Maybe<Scalars['String']>;
};

export type CancelCareSubscriptionInput = {
  careSubscriptionId: Scalars['String'];
  allowRefund?: Maybe<Scalars['Boolean']>;
  trueUpInformation?: Maybe<CancellationTrueupInformation>;
};

export type CancelCareSubscriptionResult = {
  __typename?: 'CancelCareSubscriptionResult';
  success: Scalars['Boolean'];
  messages: Array<Scalars['String']>;
};

export enum CancelReasonType {
  DeceasedPet = 'DeceasedPet',
  Moving = 'Moving',
  ChangedMind = 'ChangedMind',
  FinancialHardship = 'FinancialHardship',
  PetRehomed = 'PetRehomed',
  Other = 'Other',
  ClinicInitiatedUncollectible = 'ClinicInitiatedUncollectible',
  ClinicInitiatedOther = 'ClinicInitiatedOther'
}

export type CancellationTrueupInformation = {
  monthlyAmount: Scalars['Int'];
  months: Scalars['Int'];
  firstDueDate: Scalars['Date'];
  finalMonthAmount?: Maybe<Scalars['Int']>;
  paymentMethodId?: Maybe<Scalars['String']>;
};

export type CanonicalBreed = {
  __typename?: 'CanonicalBreed';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  source: CanonicalDataSource;
  canonicalSpeciesId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalSpecies: CanonicalSpecies;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfile>;
  informAudienceDefinitions: Array<InformAudienceDefinition>;
  _count: CanonicalBreedCountOutputType;
};


export type CanonicalBreedInformAudienceDefinitionsArgs = {
  where?: Maybe<InformAudienceDefinitionWhereInput>;
  orderBy?: Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformAudienceDefinitionScalarFieldEnum>;
};

export type CanonicalBreedAvgAggregateOutputType = {
  __typename?: 'CanonicalBreedAvgAggregateOutputType';
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
};

export type CanonicalBreedAvgOrderByAggregateInput = {
  minHeightInCm?: Maybe<SortOrder>;
  avgHeightInCm?: Maybe<SortOrder>;
  maxHeightInCm?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  avgWeightInKg?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minLifeExpectancyInYears?: Maybe<SortOrder>;
  avgLifeExpectancyInYears?: Maybe<SortOrder>;
  maxLifeExpectancyInYears?: Maybe<SortOrder>;
};

export type CanonicalBreedCountAggregateOutputType = {
  __typename?: 'CanonicalBreedCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  source: Scalars['Int'];
  canonicalSpeciesId: Scalars['Int'];
  name: Scalars['Int'];
  description: Scalars['Int'];
  minHeightInCm: Scalars['Int'];
  avgHeightInCm: Scalars['Int'];
  maxHeightInCm: Scalars['Int'];
  minWeightInKg: Scalars['Int'];
  avgWeightInKg: Scalars['Int'];
  maxWeightInKg: Scalars['Int'];
  minLifeExpectancyInYears: Scalars['Int'];
  avgLifeExpectancyInYears: Scalars['Int'];
  maxLifeExpectancyInYears: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CanonicalBreedCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  canonicalSpeciesId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  minHeightInCm?: Maybe<SortOrder>;
  avgHeightInCm?: Maybe<SortOrder>;
  maxHeightInCm?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  avgWeightInKg?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minLifeExpectancyInYears?: Maybe<SortOrder>;
  avgLifeExpectancyInYears?: Maybe<SortOrder>;
  maxLifeExpectancyInYears?: Maybe<SortOrder>;
};

export type CanonicalBreedCountOutputType = {
  __typename?: 'CanonicalBreedCountOutputType';
  informAudienceDefinitions: Scalars['Int'];
};

export type CanonicalBreedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: CanonicalDataSource;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalSpecies: CanonicalSpeciesCreateNestedOneWithoutCanonicalBreedsInput;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileCreateNestedOneWithoutCanonicalBreedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalBreedsInput>;
};

export type CanonicalBreedCreateManyCanonicalSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: CanonicalDataSource;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
};

export type CanonicalBreedCreateManyCanonicalSpeciesInputEnvelope = {
  data: Array<CanonicalBreedCreateManyCanonicalSpeciesInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CanonicalBreedCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: CanonicalDataSource;
  canonicalSpeciesId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
};

export type CanonicalBreedCreateNestedManyWithoutCanonicalSpeciesInput = {
  create?: Maybe<Array<CanonicalBreedCreateWithoutCanonicalSpeciesInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutCanonicalSpeciesInput>>;
  createMany?: Maybe<CanonicalBreedCreateManyCanonicalSpeciesInputEnvelope>;
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
};

export type CanonicalBreedCreateNestedManyWithoutInformAudienceDefinitionsInput = {
  create?: Maybe<Array<CanonicalBreedCreateWithoutInformAudienceDefinitionsInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
};

export type CanonicalBreedCreateNestedOneWithoutCanonicalCanineBreedProfileInput = {
  create?: Maybe<CanonicalBreedUncheckedCreateWithoutCanonicalCanineBreedProfileInput>;
  connectOrCreate?: Maybe<CanonicalBreedCreateOrConnectWithoutCanonicalCanineBreedProfileInput>;
  connect?: Maybe<CanonicalBreedWhereUniqueInput>;
};

export type CanonicalBreedCreateOrConnectWithoutCanonicalCanineBreedProfileInput = {
  where: CanonicalBreedWhereUniqueInput;
  create: CanonicalBreedUncheckedCreateWithoutCanonicalCanineBreedProfileInput;
};

export type CanonicalBreedCreateOrConnectWithoutCanonicalSpeciesInput = {
  where: CanonicalBreedWhereUniqueInput;
  create: CanonicalBreedUncheckedCreateWithoutCanonicalSpeciesInput;
};

export type CanonicalBreedCreateOrConnectWithoutInformAudienceDefinitionsInput = {
  where: CanonicalBreedWhereUniqueInput;
  create: CanonicalBreedUncheckedCreateWithoutInformAudienceDefinitionsInput;
};

export type CanonicalBreedCreateWithoutCanonicalCanineBreedProfileInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: CanonicalDataSource;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalSpecies: CanonicalSpeciesCreateNestedOneWithoutCanonicalBreedsInput;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalBreedsInput>;
};

export type CanonicalBreedCreateWithoutCanonicalSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: CanonicalDataSource;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileCreateNestedOneWithoutCanonicalBreedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalBreedsInput>;
};

export type CanonicalBreedCreateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: CanonicalDataSource;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalSpecies: CanonicalSpeciesCreateNestedOneWithoutCanonicalBreedsInput;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileCreateNestedOneWithoutCanonicalBreedInput>;
};

export type CanonicalBreedListRelationFilter = {
  every?: Maybe<CanonicalBreedWhereInput>;
  some?: Maybe<CanonicalBreedWhereInput>;
  none?: Maybe<CanonicalBreedWhereInput>;
};

export type CanonicalBreedMaxAggregateOutputType = {
  __typename?: 'CanonicalBreedMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<CanonicalDataSource>;
  canonicalSpeciesId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
};

export type CanonicalBreedMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  canonicalSpeciesId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  minHeightInCm?: Maybe<SortOrder>;
  avgHeightInCm?: Maybe<SortOrder>;
  maxHeightInCm?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  avgWeightInKg?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minLifeExpectancyInYears?: Maybe<SortOrder>;
  avgLifeExpectancyInYears?: Maybe<SortOrder>;
  maxLifeExpectancyInYears?: Maybe<SortOrder>;
};

export type CanonicalBreedMinAggregateOutputType = {
  __typename?: 'CanonicalBreedMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<CanonicalDataSource>;
  canonicalSpeciesId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
};

export type CanonicalBreedMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  canonicalSpeciesId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  minHeightInCm?: Maybe<SortOrder>;
  avgHeightInCm?: Maybe<SortOrder>;
  maxHeightInCm?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  avgWeightInKg?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minLifeExpectancyInYears?: Maybe<SortOrder>;
  avgLifeExpectancyInYears?: Maybe<SortOrder>;
  maxLifeExpectancyInYears?: Maybe<SortOrder>;
};

export type CanonicalBreedNameCanonicalSpeciesIdCompoundUniqueInput = {
  name: Scalars['String'];
  canonicalSpeciesId: Scalars['String'];
};

export type CanonicalBreedOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CanonicalBreedOrderByRelevanceFieldEnum {
  Id = 'id',
  CanonicalSpeciesId = 'canonicalSpeciesId',
  Name = 'name',
  Description = 'description'
}

export type CanonicalBreedOrderByRelevanceInput = {
  fields: Array<CanonicalBreedOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CanonicalBreedOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  canonicalSpeciesId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  minHeightInCm?: Maybe<SortOrder>;
  avgHeightInCm?: Maybe<SortOrder>;
  maxHeightInCm?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  avgWeightInKg?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minLifeExpectancyInYears?: Maybe<SortOrder>;
  avgLifeExpectancyInYears?: Maybe<SortOrder>;
  maxLifeExpectancyInYears?: Maybe<SortOrder>;
  _count?: Maybe<CanonicalBreedCountOrderByAggregateInput>;
  _avg?: Maybe<CanonicalBreedAvgOrderByAggregateInput>;
  _max?: Maybe<CanonicalBreedMaxOrderByAggregateInput>;
  _min?: Maybe<CanonicalBreedMinOrderByAggregateInput>;
  _sum?: Maybe<CanonicalBreedSumOrderByAggregateInput>;
};

export type CanonicalBreedOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  canonicalSpeciesId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  minHeightInCm?: Maybe<SortOrder>;
  avgHeightInCm?: Maybe<SortOrder>;
  maxHeightInCm?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  avgWeightInKg?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minLifeExpectancyInYears?: Maybe<SortOrder>;
  avgLifeExpectancyInYears?: Maybe<SortOrder>;
  maxLifeExpectancyInYears?: Maybe<SortOrder>;
  canonicalSpecies?: Maybe<CanonicalSpeciesOrderByWithRelationAndSearchRelevanceInput>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileOrderByWithRelationAndSearchRelevanceInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionOrderByRelationAggregateInput>;
  _relevance?: Maybe<CanonicalBreedOrderByRelevanceInput>;
};

export type CanonicalBreedRelationFilter = {
  is?: Maybe<CanonicalBreedWhereInput>;
  isNot?: Maybe<CanonicalBreedWhereInput>;
};

export enum CanonicalBreedScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Source = 'source',
  CanonicalSpeciesId = 'canonicalSpeciesId',
  Name = 'name',
  Description = 'description',
  MinHeightInCm = 'minHeightInCm',
  AvgHeightInCm = 'avgHeightInCm',
  MaxHeightInCm = 'maxHeightInCm',
  MinWeightInKg = 'minWeightInKg',
  AvgWeightInKg = 'avgWeightInKg',
  MaxWeightInKg = 'maxWeightInKg',
  MinLifeExpectancyInYears = 'minLifeExpectancyInYears',
  AvgLifeExpectancyInYears = 'avgLifeExpectancyInYears',
  MaxLifeExpectancyInYears = 'maxLifeExpectancyInYears'
}

export type CanonicalBreedScalarWhereInput = {
  AND?: Maybe<Array<CanonicalBreedScalarWhereInput>>;
  OR?: Maybe<Array<CanonicalBreedScalarWhereInput>>;
  NOT?: Maybe<Array<CanonicalBreedScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  source?: Maybe<EnumCanonicalDataSourceFilter>;
  canonicalSpeciesId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  minHeightInCm?: Maybe<FloatNullableFilter>;
  avgHeightInCm?: Maybe<FloatNullableFilter>;
  maxHeightInCm?: Maybe<FloatNullableFilter>;
  minWeightInKg?: Maybe<FloatNullableFilter>;
  avgWeightInKg?: Maybe<FloatNullableFilter>;
  maxWeightInKg?: Maybe<FloatNullableFilter>;
  minLifeExpectancyInYears?: Maybe<FloatNullableFilter>;
  avgLifeExpectancyInYears?: Maybe<FloatNullableFilter>;
  maxLifeExpectancyInYears?: Maybe<FloatNullableFilter>;
};

export type CanonicalBreedScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CanonicalBreedScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CanonicalBreedScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CanonicalBreedScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  source?: Maybe<EnumCanonicalDataSourceWithAggregatesFilter>;
  canonicalSpeciesId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  minHeightInCm?: Maybe<FloatNullableWithAggregatesFilter>;
  avgHeightInCm?: Maybe<FloatNullableWithAggregatesFilter>;
  maxHeightInCm?: Maybe<FloatNullableWithAggregatesFilter>;
  minWeightInKg?: Maybe<FloatNullableWithAggregatesFilter>;
  avgWeightInKg?: Maybe<FloatNullableWithAggregatesFilter>;
  maxWeightInKg?: Maybe<FloatNullableWithAggregatesFilter>;
  minLifeExpectancyInYears?: Maybe<FloatNullableWithAggregatesFilter>;
  avgLifeExpectancyInYears?: Maybe<FloatNullableWithAggregatesFilter>;
  maxLifeExpectancyInYears?: Maybe<FloatNullableWithAggregatesFilter>;
};

export type CanonicalBreedSumAggregateOutputType = {
  __typename?: 'CanonicalBreedSumAggregateOutputType';
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
};

export type CanonicalBreedSumOrderByAggregateInput = {
  minHeightInCm?: Maybe<SortOrder>;
  avgHeightInCm?: Maybe<SortOrder>;
  maxHeightInCm?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  avgWeightInKg?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minLifeExpectancyInYears?: Maybe<SortOrder>;
  avgLifeExpectancyInYears?: Maybe<SortOrder>;
  maxLifeExpectancyInYears?: Maybe<SortOrder>;
};

export type CanonicalBreedUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: CanonicalDataSource;
  canonicalSpeciesId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUncheckedCreateNestedOneWithoutCanonicalBreedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalBreedsInput>;
};

export type CanonicalBreedUncheckedCreateNestedManyWithoutCanonicalSpeciesInput = {
  create?: Maybe<Array<CanonicalBreedCreateWithoutCanonicalSpeciesInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutCanonicalSpeciesInput>>;
  createMany?: Maybe<CanonicalBreedCreateManyCanonicalSpeciesInputEnvelope>;
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
};

export type CanonicalBreedUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput = {
  create?: Maybe<Array<CanonicalBreedCreateWithoutInformAudienceDefinitionsInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
};

export type CanonicalBreedUncheckedCreateWithoutCanonicalCanineBreedProfileInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: CanonicalDataSource;
  canonicalSpeciesId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalBreedsInput>;
};

export type CanonicalBreedUncheckedCreateWithoutCanonicalSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: CanonicalDataSource;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUncheckedCreateNestedOneWithoutCanonicalBreedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalBreedsInput>;
};

export type CanonicalBreedUncheckedCreateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: CanonicalDataSource;
  canonicalSpeciesId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUncheckedCreateNestedOneWithoutCanonicalBreedInput>;
};

export type CanonicalBreedUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<CanonicalDataSource>;
  canonicalSpeciesId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUncheckedUpdateOneWithoutCanonicalBreedNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalBreedsNestedInput>;
};

export type CanonicalBreedUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<CanonicalDataSource>;
  canonicalSpeciesId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
};

export type CanonicalBreedUncheckedUpdateManyWithoutCanonicalBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<CanonicalDataSource>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
};

export type CanonicalBreedUncheckedUpdateManyWithoutCanonicalSpeciesNestedInput = {
  create?: Maybe<Array<CanonicalBreedCreateWithoutCanonicalSpeciesInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutCanonicalSpeciesInput>>;
  upsert?: Maybe<Array<CanonicalBreedUpsertWithWhereUniqueWithoutCanonicalSpeciesInput>>;
  createMany?: Maybe<CanonicalBreedCreateManyCanonicalSpeciesInputEnvelope>;
  set?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  disconnect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  delete?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalBreedUpdateWithWhereUniqueWithoutCanonicalSpeciesInput>>;
  updateMany?: Maybe<Array<CanonicalBreedUpdateManyWithWhereWithoutCanonicalSpeciesInput>>;
  deleteMany?: Maybe<Array<CanonicalBreedScalarWhereInput>>;
};

export type CanonicalBreedUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput = {
  create?: Maybe<Array<CanonicalBreedCreateWithoutInformAudienceDefinitionsInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  upsert?: Maybe<Array<CanonicalBreedUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  set?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  disconnect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  delete?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalBreedUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  updateMany?: Maybe<Array<CanonicalBreedUpdateManyWithWhereWithoutInformAudienceDefinitionsInput>>;
  deleteMany?: Maybe<Array<CanonicalBreedScalarWhereInput>>;
};

export type CanonicalBreedUncheckedUpdateWithoutCanonicalCanineBreedProfileInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<CanonicalDataSource>;
  canonicalSpeciesId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalBreedsNestedInput>;
};

export type CanonicalBreedUncheckedUpdateWithoutCanonicalSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<CanonicalDataSource>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUncheckedUpdateOneWithoutCanonicalBreedNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalBreedsNestedInput>;
};

export type CanonicalBreedUncheckedUpdateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<CanonicalDataSource>;
  canonicalSpeciesId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUncheckedUpdateOneWithoutCanonicalBreedNestedInput>;
};

export type CanonicalBreedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<CanonicalDataSource>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateOneRequiredWithoutCanonicalBreedsNestedInput>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUpdateOneWithoutCanonicalBreedNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalBreedsNestedInput>;
};

export type CanonicalBreedUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<CanonicalDataSource>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
};

export type CanonicalBreedUpdateManyWithWhereWithoutCanonicalSpeciesInput = {
  where: CanonicalBreedScalarWhereInput;
  data: CanonicalBreedUncheckedUpdateManyWithoutCanonicalBreedsInput;
};

export type CanonicalBreedUpdateManyWithWhereWithoutInformAudienceDefinitionsInput = {
  where: CanonicalBreedScalarWhereInput;
  data: CanonicalBreedUncheckedUpdateManyWithoutCanonicalBreedsInput;
};

export type CanonicalBreedUpdateManyWithoutCanonicalSpeciesNestedInput = {
  create?: Maybe<Array<CanonicalBreedCreateWithoutCanonicalSpeciesInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutCanonicalSpeciesInput>>;
  upsert?: Maybe<Array<CanonicalBreedUpsertWithWhereUniqueWithoutCanonicalSpeciesInput>>;
  createMany?: Maybe<CanonicalBreedCreateManyCanonicalSpeciesInputEnvelope>;
  set?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  disconnect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  delete?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalBreedUpdateWithWhereUniqueWithoutCanonicalSpeciesInput>>;
  updateMany?: Maybe<Array<CanonicalBreedUpdateManyWithWhereWithoutCanonicalSpeciesInput>>;
  deleteMany?: Maybe<Array<CanonicalBreedScalarWhereInput>>;
};

export type CanonicalBreedUpdateManyWithoutInformAudienceDefinitionsNestedInput = {
  create?: Maybe<Array<CanonicalBreedCreateWithoutInformAudienceDefinitionsInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  upsert?: Maybe<Array<CanonicalBreedUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  set?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  disconnect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  delete?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalBreedUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  updateMany?: Maybe<Array<CanonicalBreedUpdateManyWithWhereWithoutInformAudienceDefinitionsInput>>;
  deleteMany?: Maybe<Array<CanonicalBreedScalarWhereInput>>;
};

export type CanonicalBreedUpdateOneRequiredWithoutCanonicalCanineBreedProfileNestedInput = {
  create?: Maybe<CanonicalBreedUncheckedCreateWithoutCanonicalCanineBreedProfileInput>;
  connectOrCreate?: Maybe<CanonicalBreedCreateOrConnectWithoutCanonicalCanineBreedProfileInput>;
  upsert?: Maybe<CanonicalBreedUpsertWithoutCanonicalCanineBreedProfileInput>;
  connect?: Maybe<CanonicalBreedWhereUniqueInput>;
  update?: Maybe<CanonicalBreedUncheckedUpdateWithoutCanonicalCanineBreedProfileInput>;
};

export type CanonicalBreedUpdateWithWhereUniqueWithoutCanonicalSpeciesInput = {
  where: CanonicalBreedWhereUniqueInput;
  data: CanonicalBreedUncheckedUpdateWithoutCanonicalSpeciesInput;
};

export type CanonicalBreedUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput = {
  where: CanonicalBreedWhereUniqueInput;
  data: CanonicalBreedUncheckedUpdateWithoutInformAudienceDefinitionsInput;
};

export type CanonicalBreedUpdateWithoutCanonicalCanineBreedProfileInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<CanonicalDataSource>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateOneRequiredWithoutCanonicalBreedsNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalBreedsNestedInput>;
};

export type CanonicalBreedUpdateWithoutCanonicalSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<CanonicalDataSource>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUpdateOneWithoutCanonicalBreedNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalBreedsNestedInput>;
};

export type CanonicalBreedUpdateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<CanonicalDataSource>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateOneRequiredWithoutCanonicalBreedsNestedInput>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUpdateOneWithoutCanonicalBreedNestedInput>;
};

export type CanonicalBreedUpsertWithWhereUniqueWithoutCanonicalSpeciesInput = {
  where: CanonicalBreedWhereUniqueInput;
  update: CanonicalBreedUncheckedUpdateWithoutCanonicalSpeciesInput;
  create: CanonicalBreedUncheckedCreateWithoutCanonicalSpeciesInput;
};

export type CanonicalBreedUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput = {
  where: CanonicalBreedWhereUniqueInput;
  update: CanonicalBreedUncheckedUpdateWithoutInformAudienceDefinitionsInput;
  create: CanonicalBreedUncheckedCreateWithoutInformAudienceDefinitionsInput;
};

export type CanonicalBreedUpsertWithoutCanonicalCanineBreedProfileInput = {
  update: CanonicalBreedUncheckedUpdateWithoutCanonicalCanineBreedProfileInput;
  create: CanonicalBreedUncheckedCreateWithoutCanonicalCanineBreedProfileInput;
};

export type CanonicalBreedWhereInput = {
  AND?: Maybe<Array<CanonicalBreedWhereInput>>;
  OR?: Maybe<Array<CanonicalBreedWhereInput>>;
  NOT?: Maybe<Array<CanonicalBreedWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  source?: Maybe<EnumCanonicalDataSourceFilter>;
  canonicalSpeciesId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  minHeightInCm?: Maybe<FloatNullableFilter>;
  avgHeightInCm?: Maybe<FloatNullableFilter>;
  maxHeightInCm?: Maybe<FloatNullableFilter>;
  minWeightInKg?: Maybe<FloatNullableFilter>;
  avgWeightInKg?: Maybe<FloatNullableFilter>;
  maxWeightInKg?: Maybe<FloatNullableFilter>;
  minLifeExpectancyInYears?: Maybe<FloatNullableFilter>;
  avgLifeExpectancyInYears?: Maybe<FloatNullableFilter>;
  maxLifeExpectancyInYears?: Maybe<FloatNullableFilter>;
  canonicalSpecies?: Maybe<CanonicalSpeciesWhereInput>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileWhereInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionListRelationFilter>;
};

export type CanonicalBreedWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name_canonicalSpeciesId?: Maybe<CanonicalBreedNameCanonicalSpeciesIdCompoundUniqueInput>;
};

export type CanonicalCanineBreedProfile = {
  __typename?: 'CanonicalCanineBreedProfile';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  canonicalBreedId: Scalars['String'];
  popularity?: Maybe<Scalars['Int']>;
  temperament: Array<AmericanKennelClubBreedTemperament>;
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  canonicalBreed: CanonicalBreed;
};

export type CanonicalCanineBreedProfileAvgAggregateOutputType = {
  __typename?: 'CanonicalCanineBreedProfileAvgAggregateOutputType';
  popularity?: Maybe<Scalars['Float']>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  sheddingValue?: Maybe<Scalars['Float']>;
  energyLevel?: Maybe<Scalars['Float']>;
  trainability?: Maybe<Scalars['Float']>;
  demeanor?: Maybe<Scalars['Float']>;
};

export type CanonicalCanineBreedProfileAvgOrderByAggregateInput = {
  popularity?: Maybe<SortOrder>;
  groomingFrequency?: Maybe<SortOrder>;
  sheddingValue?: Maybe<SortOrder>;
  energyLevel?: Maybe<SortOrder>;
  trainability?: Maybe<SortOrder>;
  demeanor?: Maybe<SortOrder>;
};

export type CanonicalCanineBreedProfileCountAggregateOutputType = {
  __typename?: 'CanonicalCanineBreedProfileCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  canonicalBreedId: Scalars['Int'];
  popularity: Scalars['Int'];
  temperament: Scalars['Int'];
  breedGroup: Scalars['Int'];
  groomingFrequency: Scalars['Int'];
  groomingFrequencyCategory: Scalars['Int'];
  sheddingValue: Scalars['Int'];
  sheddingCategory: Scalars['Int'];
  energyLevel: Scalars['Int'];
  energyLevelCategory: Scalars['Int'];
  trainability: Scalars['Int'];
  trainabilityCategory: Scalars['Int'];
  demeanor: Scalars['Int'];
  demeanorCategory: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CanonicalCanineBreedProfileCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  canonicalBreedId?: Maybe<SortOrder>;
  popularity?: Maybe<SortOrder>;
  temperament?: Maybe<SortOrder>;
  breedGroup?: Maybe<SortOrder>;
  groomingFrequency?: Maybe<SortOrder>;
  groomingFrequencyCategory?: Maybe<SortOrder>;
  sheddingValue?: Maybe<SortOrder>;
  sheddingCategory?: Maybe<SortOrder>;
  energyLevel?: Maybe<SortOrder>;
  energyLevelCategory?: Maybe<SortOrder>;
  trainability?: Maybe<SortOrder>;
  trainabilityCategory?: Maybe<SortOrder>;
  demeanor?: Maybe<SortOrder>;
  demeanorCategory?: Maybe<SortOrder>;
};

export type CanonicalCanineBreedProfileCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  popularity?: Maybe<Scalars['Int']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  canonicalBreed: CanonicalBreedCreateNestedOneWithoutCanonicalCanineBreedProfileInput;
};

export type CanonicalCanineBreedProfileCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  canonicalBreedId: Scalars['String'];
  popularity?: Maybe<Scalars['Int']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
};

export type CanonicalCanineBreedProfileCreateNestedOneWithoutCanonicalBreedInput = {
  create?: Maybe<CanonicalCanineBreedProfileUncheckedCreateWithoutCanonicalBreedInput>;
  connectOrCreate?: Maybe<CanonicalCanineBreedProfileCreateOrConnectWithoutCanonicalBreedInput>;
  connect?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
};

export type CanonicalCanineBreedProfileCreateOrConnectWithoutCanonicalBreedInput = {
  where: CanonicalCanineBreedProfileWhereUniqueInput;
  create: CanonicalCanineBreedProfileUncheckedCreateWithoutCanonicalBreedInput;
};

export type CanonicalCanineBreedProfileCreateWithoutCanonicalBreedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  popularity?: Maybe<Scalars['Int']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
};

export type CanonicalCanineBreedProfileCreatetemperamentInput = {
  set: Array<AmericanKennelClubBreedTemperament>;
};

export type CanonicalCanineBreedProfileMaxAggregateOutputType = {
  __typename?: 'CanonicalCanineBreedProfileMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  canonicalBreedId?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
};

export type CanonicalCanineBreedProfileMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  canonicalBreedId?: Maybe<SortOrder>;
  popularity?: Maybe<SortOrder>;
  breedGroup?: Maybe<SortOrder>;
  groomingFrequency?: Maybe<SortOrder>;
  groomingFrequencyCategory?: Maybe<SortOrder>;
  sheddingValue?: Maybe<SortOrder>;
  sheddingCategory?: Maybe<SortOrder>;
  energyLevel?: Maybe<SortOrder>;
  energyLevelCategory?: Maybe<SortOrder>;
  trainability?: Maybe<SortOrder>;
  trainabilityCategory?: Maybe<SortOrder>;
  demeanor?: Maybe<SortOrder>;
  demeanorCategory?: Maybe<SortOrder>;
};

export type CanonicalCanineBreedProfileMinAggregateOutputType = {
  __typename?: 'CanonicalCanineBreedProfileMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  canonicalBreedId?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
};

export type CanonicalCanineBreedProfileMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  canonicalBreedId?: Maybe<SortOrder>;
  popularity?: Maybe<SortOrder>;
  breedGroup?: Maybe<SortOrder>;
  groomingFrequency?: Maybe<SortOrder>;
  groomingFrequencyCategory?: Maybe<SortOrder>;
  sheddingValue?: Maybe<SortOrder>;
  sheddingCategory?: Maybe<SortOrder>;
  energyLevel?: Maybe<SortOrder>;
  energyLevelCategory?: Maybe<SortOrder>;
  trainability?: Maybe<SortOrder>;
  trainabilityCategory?: Maybe<SortOrder>;
  demeanor?: Maybe<SortOrder>;
  demeanorCategory?: Maybe<SortOrder>;
};

export enum CanonicalCanineBreedProfileOrderByRelevanceFieldEnum {
  Id = 'id',
  CanonicalBreedId = 'canonicalBreedId'
}

export type CanonicalCanineBreedProfileOrderByRelevanceInput = {
  fields: Array<CanonicalCanineBreedProfileOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CanonicalCanineBreedProfileOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  canonicalBreedId?: Maybe<SortOrder>;
  popularity?: Maybe<SortOrder>;
  temperament?: Maybe<SortOrder>;
  breedGroup?: Maybe<SortOrder>;
  groomingFrequency?: Maybe<SortOrder>;
  groomingFrequencyCategory?: Maybe<SortOrder>;
  sheddingValue?: Maybe<SortOrder>;
  sheddingCategory?: Maybe<SortOrder>;
  energyLevel?: Maybe<SortOrder>;
  energyLevelCategory?: Maybe<SortOrder>;
  trainability?: Maybe<SortOrder>;
  trainabilityCategory?: Maybe<SortOrder>;
  demeanor?: Maybe<SortOrder>;
  demeanorCategory?: Maybe<SortOrder>;
  _count?: Maybe<CanonicalCanineBreedProfileCountOrderByAggregateInput>;
  _avg?: Maybe<CanonicalCanineBreedProfileAvgOrderByAggregateInput>;
  _max?: Maybe<CanonicalCanineBreedProfileMaxOrderByAggregateInput>;
  _min?: Maybe<CanonicalCanineBreedProfileMinOrderByAggregateInput>;
  _sum?: Maybe<CanonicalCanineBreedProfileSumOrderByAggregateInput>;
};

export type CanonicalCanineBreedProfileOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  canonicalBreedId?: Maybe<SortOrder>;
  popularity?: Maybe<SortOrder>;
  temperament?: Maybe<SortOrder>;
  breedGroup?: Maybe<SortOrder>;
  groomingFrequency?: Maybe<SortOrder>;
  groomingFrequencyCategory?: Maybe<SortOrder>;
  sheddingValue?: Maybe<SortOrder>;
  sheddingCategory?: Maybe<SortOrder>;
  energyLevel?: Maybe<SortOrder>;
  energyLevelCategory?: Maybe<SortOrder>;
  trainability?: Maybe<SortOrder>;
  trainabilityCategory?: Maybe<SortOrder>;
  demeanor?: Maybe<SortOrder>;
  demeanorCategory?: Maybe<SortOrder>;
  canonicalBreed?: Maybe<CanonicalBreedOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CanonicalCanineBreedProfileOrderByRelevanceInput>;
};

export type CanonicalCanineBreedProfileRelationFilter = {
  is?: Maybe<CanonicalCanineBreedProfileWhereInput>;
  isNot?: Maybe<CanonicalCanineBreedProfileWhereInput>;
};

export enum CanonicalCanineBreedProfileScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CanonicalBreedId = 'canonicalBreedId',
  Popularity = 'popularity',
  Temperament = 'temperament',
  BreedGroup = 'breedGroup',
  GroomingFrequency = 'groomingFrequency',
  GroomingFrequencyCategory = 'groomingFrequencyCategory',
  SheddingValue = 'sheddingValue',
  SheddingCategory = 'sheddingCategory',
  EnergyLevel = 'energyLevel',
  EnergyLevelCategory = 'energyLevelCategory',
  Trainability = 'trainability',
  TrainabilityCategory = 'trainabilityCategory',
  Demeanor = 'demeanor',
  DemeanorCategory = 'demeanorCategory'
}

export type CanonicalCanineBreedProfileScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CanonicalCanineBreedProfileScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CanonicalCanineBreedProfileScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CanonicalCanineBreedProfileScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  canonicalBreedId?: Maybe<StringWithAggregatesFilter>;
  popularity?: Maybe<IntNullableWithAggregatesFilter>;
  temperament?: Maybe<EnumAmericanKennelClubBreedTemperamentNullableListFilter>;
  breedGroup?: Maybe<EnumAmericanKennelClubBreedGroupNullableWithAggregatesFilter>;
  groomingFrequency?: Maybe<FloatNullableWithAggregatesFilter>;
  groomingFrequencyCategory?: Maybe<EnumAmericanKennelClubGroomingFrequencyCategoryNullableWithAggregatesFilter>;
  sheddingValue?: Maybe<FloatNullableWithAggregatesFilter>;
  sheddingCategory?: Maybe<EnumAmericanKennelClubSheddingCategoryNullableWithAggregatesFilter>;
  energyLevel?: Maybe<FloatNullableWithAggregatesFilter>;
  energyLevelCategory?: Maybe<EnumAmericanKennelClubEnergyLevelCategoryNullableWithAggregatesFilter>;
  trainability?: Maybe<FloatNullableWithAggregatesFilter>;
  trainabilityCategory?: Maybe<EnumAmericanKennelClubTrainabilityCategoryNullableWithAggregatesFilter>;
  demeanor?: Maybe<FloatNullableWithAggregatesFilter>;
  demeanorCategory?: Maybe<EnumAmericanKennelClubDemeanorCategoryNullableWithAggregatesFilter>;
};

export type CanonicalCanineBreedProfileSumAggregateOutputType = {
  __typename?: 'CanonicalCanineBreedProfileSumAggregateOutputType';
  popularity?: Maybe<Scalars['Int']>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  sheddingValue?: Maybe<Scalars['Float']>;
  energyLevel?: Maybe<Scalars['Float']>;
  trainability?: Maybe<Scalars['Float']>;
  demeanor?: Maybe<Scalars['Float']>;
};

export type CanonicalCanineBreedProfileSumOrderByAggregateInput = {
  popularity?: Maybe<SortOrder>;
  groomingFrequency?: Maybe<SortOrder>;
  sheddingValue?: Maybe<SortOrder>;
  energyLevel?: Maybe<SortOrder>;
  trainability?: Maybe<SortOrder>;
  demeanor?: Maybe<SortOrder>;
};

export type CanonicalCanineBreedProfileUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  canonicalBreedId: Scalars['String'];
  popularity?: Maybe<Scalars['Int']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
};

export type CanonicalCanineBreedProfileUncheckedCreateNestedOneWithoutCanonicalBreedInput = {
  create?: Maybe<CanonicalCanineBreedProfileUncheckedCreateWithoutCanonicalBreedInput>;
  connectOrCreate?: Maybe<CanonicalCanineBreedProfileCreateOrConnectWithoutCanonicalBreedInput>;
  connect?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
};

export type CanonicalCanineBreedProfileUncheckedCreateWithoutCanonicalBreedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  popularity?: Maybe<Scalars['Int']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
};

export type CanonicalCanineBreedProfileUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  canonicalBreedId?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
};

export type CanonicalCanineBreedProfileUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  canonicalBreedId?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
};

export type CanonicalCanineBreedProfileUncheckedUpdateOneWithoutCanonicalBreedNestedInput = {
  create?: Maybe<CanonicalCanineBreedProfileUncheckedCreateWithoutCanonicalBreedInput>;
  connectOrCreate?: Maybe<CanonicalCanineBreedProfileCreateOrConnectWithoutCanonicalBreedInput>;
  upsert?: Maybe<CanonicalCanineBreedProfileUpsertWithoutCanonicalBreedInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
  update?: Maybe<CanonicalCanineBreedProfileUncheckedUpdateWithoutCanonicalBreedInput>;
};

export type CanonicalCanineBreedProfileUncheckedUpdateWithoutCanonicalBreedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  popularity?: Maybe<Scalars['Int']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
};

export type CanonicalCanineBreedProfileUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  popularity?: Maybe<Scalars['Int']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  canonicalBreed?: Maybe<CanonicalBreedUpdateOneRequiredWithoutCanonicalCanineBreedProfileNestedInput>;
};

export type CanonicalCanineBreedProfileUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  popularity?: Maybe<Scalars['Int']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
};

export type CanonicalCanineBreedProfileUpdateOneWithoutCanonicalBreedNestedInput = {
  create?: Maybe<CanonicalCanineBreedProfileUncheckedCreateWithoutCanonicalBreedInput>;
  connectOrCreate?: Maybe<CanonicalCanineBreedProfileCreateOrConnectWithoutCanonicalBreedInput>;
  upsert?: Maybe<CanonicalCanineBreedProfileUpsertWithoutCanonicalBreedInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
  update?: Maybe<CanonicalCanineBreedProfileUncheckedUpdateWithoutCanonicalBreedInput>;
};

export type CanonicalCanineBreedProfileUpdateWithoutCanonicalBreedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  popularity?: Maybe<Scalars['Int']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
};

export type CanonicalCanineBreedProfileUpdatetemperamentInput = {
  set?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  push?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
};

export type CanonicalCanineBreedProfileUpsertWithoutCanonicalBreedInput = {
  update: CanonicalCanineBreedProfileUncheckedUpdateWithoutCanonicalBreedInput;
  create: CanonicalCanineBreedProfileUncheckedCreateWithoutCanonicalBreedInput;
};

export type CanonicalCanineBreedProfileWhereInput = {
  AND?: Maybe<Array<CanonicalCanineBreedProfileWhereInput>>;
  OR?: Maybe<Array<CanonicalCanineBreedProfileWhereInput>>;
  NOT?: Maybe<Array<CanonicalCanineBreedProfileWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  canonicalBreedId?: Maybe<StringFilter>;
  popularity?: Maybe<IntNullableFilter>;
  temperament?: Maybe<EnumAmericanKennelClubBreedTemperamentNullableListFilter>;
  breedGroup?: Maybe<EnumAmericanKennelClubBreedGroupNullableFilter>;
  groomingFrequency?: Maybe<FloatNullableFilter>;
  groomingFrequencyCategory?: Maybe<EnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter>;
  sheddingValue?: Maybe<FloatNullableFilter>;
  sheddingCategory?: Maybe<EnumAmericanKennelClubSheddingCategoryNullableFilter>;
  energyLevel?: Maybe<FloatNullableFilter>;
  energyLevelCategory?: Maybe<EnumAmericanKennelClubEnergyLevelCategoryNullableFilter>;
  trainability?: Maybe<FloatNullableFilter>;
  trainabilityCategory?: Maybe<EnumAmericanKennelClubTrainabilityCategoryNullableFilter>;
  demeanor?: Maybe<FloatNullableFilter>;
  demeanorCategory?: Maybe<EnumAmericanKennelClubDemeanorCategoryNullableFilter>;
  canonicalBreed?: Maybe<CanonicalBreedWhereInput>;
};

export type CanonicalCanineBreedProfileWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  canonicalBreedId?: Maybe<Scalars['String']>;
};

export enum CanonicalDataSource {
  AmericanKennelClub = 'AmericanKennelClub'
}

export type CanonicalService = {
  __typename?: 'CanonicalService';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status: CanonicalServiceStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<User>;
  informAudienceDefinitions: Array<InformAudienceDefinition>;
  product?: Maybe<Product>;
  services: Array<Service>;
  informCampaignEnrollmentCanonicalServices: Array<InformCampaignEnrollmentCanonicalService>;
  _count: CanonicalServiceCountOutputType;
};


export type CanonicalServiceInformAudienceDefinitionsArgs = {
  where?: Maybe<InformAudienceDefinitionWhereInput>;
  orderBy?: Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformAudienceDefinitionScalarFieldEnum>;
};


export type CanonicalServiceServicesArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceScalarFieldEnum>;
};


export type CanonicalServiceInformCampaignEnrollmentCanonicalServicesArgs = {
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
  orderBy?: Maybe<InformCampaignEnrollmentCanonicalServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformCampaignEnrollmentCanonicalServiceScalarFieldEnum>;
};

export type CanonicalServiceCountAggregateOutputType = {
  __typename?: 'CanonicalServiceCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  type: Scalars['Int'];
  name: Scalars['Int'];
  displayName: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  description: Scalars['Int'];
  validatedByUserId: Scalars['Int'];
  validatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CanonicalServiceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  validatedByUserId?: Maybe<SortOrder>;
  validatedAt?: Maybe<SortOrder>;
};

export type CanonicalServiceCountOutputType = {
  __typename?: 'CanonicalServiceCountOutputType';
  informAudienceDefinitions: Scalars['Int'];
  services: Scalars['Int'];
  informCampaignEnrollmentCanonicalServices: Scalars['Int'];
};

export type CanonicalServiceCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserCreateNestedOneWithoutValidatedCanonicalServicesInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalServicesInput>;
  product?: Maybe<ProductCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutCanonicalServiceInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutCanonicalServiceInput>;
};

export type CanonicalServiceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceCreateManyValidatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceCreateManyValidatedByUserInputEnvelope = {
  data: Array<CanonicalServiceCreateManyValidatedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CanonicalServiceCreateNestedManyWithoutInformAudienceDefinitionsInput = {
  create?: Maybe<Array<CanonicalServiceCreateWithoutInformAudienceDefinitionsInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
};

export type CanonicalServiceCreateNestedManyWithoutValidatedByUserInput = {
  create?: Maybe<Array<CanonicalServiceCreateWithoutValidatedByUserInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutValidatedByUserInput>>;
  createMany?: Maybe<CanonicalServiceCreateManyValidatedByUserInputEnvelope>;
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
};

export type CanonicalServiceCreateNestedOneWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  create?: Maybe<CanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  connectOrCreate?: Maybe<CanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  connect?: Maybe<CanonicalServiceWhereUniqueInput>;
};

export type CanonicalServiceCreateNestedOneWithoutProductInput = {
  create?: Maybe<CanonicalServiceUncheckedCreateWithoutProductInput>;
  connectOrCreate?: Maybe<CanonicalServiceCreateOrConnectWithoutProductInput>;
  connect?: Maybe<CanonicalServiceWhereUniqueInput>;
};

export type CanonicalServiceCreateNestedOneWithoutServicesInput = {
  create?: Maybe<CanonicalServiceUncheckedCreateWithoutServicesInput>;
  connectOrCreate?: Maybe<CanonicalServiceCreateOrConnectWithoutServicesInput>;
  connect?: Maybe<CanonicalServiceWhereUniqueInput>;
};

export type CanonicalServiceCreateOrConnectWithoutInformAudienceDefinitionsInput = {
  where: CanonicalServiceWhereUniqueInput;
  create: CanonicalServiceUncheckedCreateWithoutInformAudienceDefinitionsInput;
};

export type CanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  where: CanonicalServiceWhereUniqueInput;
  create: CanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput;
};

export type CanonicalServiceCreateOrConnectWithoutProductInput = {
  where: CanonicalServiceWhereUniqueInput;
  create: CanonicalServiceUncheckedCreateWithoutProductInput;
};

export type CanonicalServiceCreateOrConnectWithoutServicesInput = {
  where: CanonicalServiceWhereUniqueInput;
  create: CanonicalServiceUncheckedCreateWithoutServicesInput;
};

export type CanonicalServiceCreateOrConnectWithoutValidatedByUserInput = {
  where: CanonicalServiceWhereUniqueInput;
  create: CanonicalServiceUncheckedCreateWithoutValidatedByUserInput;
};

export type CanonicalServiceCreateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserCreateNestedOneWithoutValidatedCanonicalServicesInput>;
  product?: Maybe<ProductCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutCanonicalServiceInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutCanonicalServiceInput>;
};

export type CanonicalServiceCreateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserCreateNestedOneWithoutValidatedCanonicalServicesInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalServicesInput>;
  product?: Maybe<ProductCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutCanonicalServiceInput>;
};

export type CanonicalServiceCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserCreateNestedOneWithoutValidatedCanonicalServicesInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalServicesInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutCanonicalServiceInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutCanonicalServiceInput>;
};

export type CanonicalServiceCreateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserCreateNestedOneWithoutValidatedCanonicalServicesInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalServicesInput>;
  product?: Maybe<ProductCreateNestedOneWithoutCanonicalServiceInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutCanonicalServiceInput>;
};

export type CanonicalServiceCreateWithoutValidatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalServicesInput>;
  product?: Maybe<ProductCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutCanonicalServiceInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutCanonicalServiceInput>;
};

export type CanonicalServiceListRelationFilter = {
  every?: Maybe<CanonicalServiceWhereInput>;
  some?: Maybe<CanonicalServiceWhereInput>;
  none?: Maybe<CanonicalServiceWhereInput>;
};

export type CanonicalServiceMaxAggregateOutputType = {
  __typename?: 'CanonicalServiceMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  validatedByUserId?: Maybe<SortOrder>;
  validatedAt?: Maybe<SortOrder>;
};

export type CanonicalServiceMinAggregateOutputType = {
  __typename?: 'CanonicalServiceMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  validatedByUserId?: Maybe<SortOrder>;
  validatedAt?: Maybe<SortOrder>;
};

export type CanonicalServiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CanonicalServiceOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  DisplayName = 'displayName',
  Description = 'description',
  ValidatedByUserId = 'validatedByUserId'
}

export type CanonicalServiceOrderByRelevanceInput = {
  fields: Array<CanonicalServiceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CanonicalServiceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  validatedByUserId?: Maybe<SortOrder>;
  validatedAt?: Maybe<SortOrder>;
  _count?: Maybe<CanonicalServiceCountOrderByAggregateInput>;
  _max?: Maybe<CanonicalServiceMaxOrderByAggregateInput>;
  _min?: Maybe<CanonicalServiceMinOrderByAggregateInput>;
};

export type CanonicalServiceOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  validatedByUserId?: Maybe<SortOrder>;
  validatedAt?: Maybe<SortOrder>;
  validatedByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionOrderByRelationAggregateInput>;
  product?: Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>;
  services?: Maybe<ServiceOrderByRelationAggregateInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceOrderByRelationAggregateInput>;
  _relevance?: Maybe<CanonicalServiceOrderByRelevanceInput>;
};

export type CanonicalServiceRelationFilter = {
  is?: Maybe<CanonicalServiceWhereInput>;
  isNot?: Maybe<CanonicalServiceWhereInput>;
};

export enum CanonicalServiceScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Type = 'type',
  Name = 'name',
  DisplayName = 'displayName',
  Status = 'status',
  StatusMessage = 'statusMessage',
  Description = 'description',
  ValidatedByUserId = 'validatedByUserId',
  ValidatedAt = 'validatedAt'
}

export type CanonicalServiceScalarWhereInput = {
  AND?: Maybe<Array<CanonicalServiceScalarWhereInput>>;
  OR?: Maybe<Array<CanonicalServiceScalarWhereInput>>;
  NOT?: Maybe<Array<CanonicalServiceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumCanonicalServiceTypeFilter>;
  name?: Maybe<StringFilter>;
  displayName?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumCanonicalServiceStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  validatedByUserId?: Maybe<StringNullableFilter>;
  validatedAt?: Maybe<DateTimeNullableFilter>;
};

export type CanonicalServiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CanonicalServiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CanonicalServiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CanonicalServiceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  type?: Maybe<EnumCanonicalServiceTypeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  displayName?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumCanonicalServiceStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  validatedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  validatedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
};

export enum CanonicalServiceStatus {
  Validated = 'Validated',
  NeedsReview = 'NeedsReview'
}

export enum CanonicalServiceType {
  Product = 'Product'
}

export type CanonicalServiceUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalServicesInput>;
  product?: Maybe<ProductUncheckedCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
};

export type CanonicalServiceUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput = {
  create?: Maybe<Array<CanonicalServiceCreateWithoutInformAudienceDefinitionsInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
};

export type CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput = {
  create?: Maybe<Array<CanonicalServiceCreateWithoutValidatedByUserInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutValidatedByUserInput>>;
  createMany?: Maybe<CanonicalServiceCreateManyValidatedByUserInputEnvelope>;
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
};

export type CanonicalServiceUncheckedCreateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductUncheckedCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
};

export type CanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalServicesInput>;
  product?: Maybe<ProductUncheckedCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
};

export type CanonicalServiceUncheckedCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalServicesInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
};

export type CanonicalServiceUncheckedCreateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalServicesInput>;
  product?: Maybe<ProductUncheckedCreateNestedOneWithoutCanonicalServiceInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
};

export type CanonicalServiceUncheckedCreateWithoutValidatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: CanonicalServiceType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalServicesInput>;
  product?: Maybe<ProductUncheckedCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
};

export type CanonicalServiceUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalServicesNestedInput>;
  product?: Maybe<ProductUncheckedUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
};

export type CanonicalServiceUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceUncheckedUpdateManyWithoutCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput = {
  create?: Maybe<Array<CanonicalServiceCreateWithoutInformAudienceDefinitionsInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  upsert?: Maybe<Array<CanonicalServiceUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  set?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  delete?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalServiceUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  updateMany?: Maybe<Array<CanonicalServiceUpdateManyWithWhereWithoutInformAudienceDefinitionsInput>>;
  deleteMany?: Maybe<Array<CanonicalServiceScalarWhereInput>>;
};

export type CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput = {
  create?: Maybe<Array<CanonicalServiceCreateWithoutValidatedByUserInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutValidatedByUserInput>>;
  upsert?: Maybe<Array<CanonicalServiceUpsertWithWhereUniqueWithoutValidatedByUserInput>>;
  createMany?: Maybe<CanonicalServiceCreateManyValidatedByUserInputEnvelope>;
  set?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  delete?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalServiceUpdateWithWhereUniqueWithoutValidatedByUserInput>>;
  updateMany?: Maybe<Array<CanonicalServiceUpdateManyWithWhereWithoutValidatedByUserInput>>;
  deleteMany?: Maybe<Array<CanonicalServiceScalarWhereInput>>;
};

export type CanonicalServiceUncheckedUpdateManyWithoutValidatedCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceUncheckedUpdateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductUncheckedUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
};

export type CanonicalServiceUncheckedUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalServicesNestedInput>;
  product?: Maybe<ProductUncheckedUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
};

export type CanonicalServiceUncheckedUpdateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalServicesNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
};

export type CanonicalServiceUncheckedUpdateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedByUserId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalServicesNestedInput>;
  product?: Maybe<ProductUncheckedUpdateOneWithoutCanonicalServiceNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
};

export type CanonicalServiceUncheckedUpdateWithoutValidatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalServicesNestedInput>;
  product?: Maybe<ProductUncheckedUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
};

export type CanonicalServiceUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserUpdateOneWithoutValidatedCanonicalServicesNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalServicesNestedInput>;
  product?: Maybe<ProductUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutCanonicalServiceNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutCanonicalServiceNestedInput>;
};

export type CanonicalServiceUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceUpdateManyWithWhereWithoutInformAudienceDefinitionsInput = {
  where: CanonicalServiceScalarWhereInput;
  data: CanonicalServiceUncheckedUpdateManyWithoutCanonicalServicesInput;
};

export type CanonicalServiceUpdateManyWithWhereWithoutValidatedByUserInput = {
  where: CanonicalServiceScalarWhereInput;
  data: CanonicalServiceUncheckedUpdateManyWithoutValidatedCanonicalServicesInput;
};

export type CanonicalServiceUpdateManyWithoutInformAudienceDefinitionsNestedInput = {
  create?: Maybe<Array<CanonicalServiceCreateWithoutInformAudienceDefinitionsInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  upsert?: Maybe<Array<CanonicalServiceUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  set?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  delete?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalServiceUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  updateMany?: Maybe<Array<CanonicalServiceUpdateManyWithWhereWithoutInformAudienceDefinitionsInput>>;
  deleteMany?: Maybe<Array<CanonicalServiceScalarWhereInput>>;
};

export type CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput = {
  create?: Maybe<Array<CanonicalServiceCreateWithoutValidatedByUserInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutValidatedByUserInput>>;
  upsert?: Maybe<Array<CanonicalServiceUpsertWithWhereUniqueWithoutValidatedByUserInput>>;
  createMany?: Maybe<CanonicalServiceCreateManyValidatedByUserInputEnvelope>;
  set?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  delete?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalServiceUpdateWithWhereUniqueWithoutValidatedByUserInput>>;
  updateMany?: Maybe<Array<CanonicalServiceUpdateManyWithWhereWithoutValidatedByUserInput>>;
  deleteMany?: Maybe<Array<CanonicalServiceScalarWhereInput>>;
};

export type CanonicalServiceUpdateOneRequiredWithoutInformCampaignEnrollmentCanonicalServicesNestedInput = {
  create?: Maybe<CanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  connectOrCreate?: Maybe<CanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  upsert?: Maybe<CanonicalServiceUpsertWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  connect?: Maybe<CanonicalServiceWhereUniqueInput>;
  update?: Maybe<CanonicalServiceUncheckedUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput>;
};

export type CanonicalServiceUpdateOneRequiredWithoutProductNestedInput = {
  create?: Maybe<CanonicalServiceUncheckedCreateWithoutProductInput>;
  connectOrCreate?: Maybe<CanonicalServiceCreateOrConnectWithoutProductInput>;
  upsert?: Maybe<CanonicalServiceUpsertWithoutProductInput>;
  connect?: Maybe<CanonicalServiceWhereUniqueInput>;
  update?: Maybe<CanonicalServiceUncheckedUpdateWithoutProductInput>;
};

export type CanonicalServiceUpdateOneWithoutServicesNestedInput = {
  create?: Maybe<CanonicalServiceUncheckedCreateWithoutServicesInput>;
  connectOrCreate?: Maybe<CanonicalServiceCreateOrConnectWithoutServicesInput>;
  upsert?: Maybe<CanonicalServiceUpsertWithoutServicesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CanonicalServiceWhereUniqueInput>;
  update?: Maybe<CanonicalServiceUncheckedUpdateWithoutServicesInput>;
};

export type CanonicalServiceUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput = {
  where: CanonicalServiceWhereUniqueInput;
  data: CanonicalServiceUncheckedUpdateWithoutInformAudienceDefinitionsInput;
};

export type CanonicalServiceUpdateWithWhereUniqueWithoutValidatedByUserInput = {
  where: CanonicalServiceWhereUniqueInput;
  data: CanonicalServiceUncheckedUpdateWithoutValidatedByUserInput;
};

export type CanonicalServiceUpdateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserUpdateOneWithoutValidatedCanonicalServicesNestedInput>;
  product?: Maybe<ProductUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutCanonicalServiceNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutCanonicalServiceNestedInput>;
};

export type CanonicalServiceUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserUpdateOneWithoutValidatedCanonicalServicesNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalServicesNestedInput>;
  product?: Maybe<ProductUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutCanonicalServiceNestedInput>;
};

export type CanonicalServiceUpdateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserUpdateOneWithoutValidatedCanonicalServicesNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalServicesNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutCanonicalServiceNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutCanonicalServiceNestedInput>;
};

export type CanonicalServiceUpdateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserUpdateOneWithoutValidatedCanonicalServicesNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalServicesNestedInput>;
  product?: Maybe<ProductUpdateOneWithoutCanonicalServiceNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutCanonicalServiceNestedInput>;
};

export type CanonicalServiceUpdateWithoutValidatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CanonicalServiceType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalServicesNestedInput>;
  product?: Maybe<ProductUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutCanonicalServiceNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutCanonicalServiceNestedInput>;
};

export type CanonicalServiceUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput = {
  where: CanonicalServiceWhereUniqueInput;
  update: CanonicalServiceUncheckedUpdateWithoutInformAudienceDefinitionsInput;
  create: CanonicalServiceUncheckedCreateWithoutInformAudienceDefinitionsInput;
};

export type CanonicalServiceUpsertWithWhereUniqueWithoutValidatedByUserInput = {
  where: CanonicalServiceWhereUniqueInput;
  update: CanonicalServiceUncheckedUpdateWithoutValidatedByUserInput;
  create: CanonicalServiceUncheckedCreateWithoutValidatedByUserInput;
};

export type CanonicalServiceUpsertWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  update: CanonicalServiceUncheckedUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput;
  create: CanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput;
};

export type CanonicalServiceUpsertWithoutProductInput = {
  update: CanonicalServiceUncheckedUpdateWithoutProductInput;
  create: CanonicalServiceUncheckedCreateWithoutProductInput;
};

export type CanonicalServiceUpsertWithoutServicesInput = {
  update: CanonicalServiceUncheckedUpdateWithoutServicesInput;
  create: CanonicalServiceUncheckedCreateWithoutServicesInput;
};

export type CanonicalServiceWhereInput = {
  AND?: Maybe<Array<CanonicalServiceWhereInput>>;
  OR?: Maybe<Array<CanonicalServiceWhereInput>>;
  NOT?: Maybe<Array<CanonicalServiceWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumCanonicalServiceTypeFilter>;
  name?: Maybe<StringFilter>;
  displayName?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumCanonicalServiceStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  validatedByUserId?: Maybe<StringNullableFilter>;
  validatedAt?: Maybe<DateTimeNullableFilter>;
  validatedByUser?: Maybe<UserWhereInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionListRelationFilter>;
  product?: Maybe<ProductWhereInput>;
  services?: Maybe<ServiceListRelationFilter>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceListRelationFilter>;
};

export type CanonicalServiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CanonicalSpecies = {
  __typename?: 'CanonicalSpecies';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  canonicalBreeds: Array<CanonicalBreed>;
  informAudienceDefinitions: Array<InformAudienceDefinition>;
  _count: CanonicalSpeciesCountOutputType;
};


export type CanonicalSpeciesCanonicalBreedsArgs = {
  where?: Maybe<CanonicalBreedWhereInput>;
  orderBy?: Maybe<CanonicalBreedOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CanonicalBreedWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CanonicalBreedScalarFieldEnum>;
};


export type CanonicalSpeciesInformAudienceDefinitionsArgs = {
  where?: Maybe<InformAudienceDefinitionWhereInput>;
  orderBy?: Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformAudienceDefinitionScalarFieldEnum>;
};

export type CanonicalSpeciesCountAggregateOutputType = {
  __typename?: 'CanonicalSpeciesCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  name: Scalars['Int'];
  scientificName: Scalars['Int'];
  genus: Scalars['Int'];
  family: Scalars['Int'];
  order: Scalars['Int'];
  class: Scalars['Int'];
  phylum: Scalars['Int'];
  kingdom: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CanonicalSpeciesCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  scientificName?: Maybe<SortOrder>;
  genus?: Maybe<SortOrder>;
  family?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  class?: Maybe<SortOrder>;
  phylum?: Maybe<SortOrder>;
  kingdom?: Maybe<SortOrder>;
};

export type CanonicalSpeciesCountOutputType = {
  __typename?: 'CanonicalSpeciesCountOutputType';
  canonicalBreeds: Scalars['Int'];
  informAudienceDefinitions: Scalars['Int'];
};

export type CanonicalSpeciesCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  canonicalBreeds?: Maybe<CanonicalBreedCreateNestedManyWithoutCanonicalSpeciesInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalSpeciesInput>;
};

export type CanonicalSpeciesCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
};

export type CanonicalSpeciesCreateNestedManyWithoutInformAudienceDefinitionsInput = {
  create?: Maybe<Array<CanonicalSpeciesCreateWithoutInformAudienceDefinitionsInput>>;
  connectOrCreate?: Maybe<Array<CanonicalSpeciesCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  connect?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
};

export type CanonicalSpeciesCreateNestedOneWithoutCanonicalBreedsInput = {
  create?: Maybe<CanonicalSpeciesUncheckedCreateWithoutCanonicalBreedsInput>;
  connectOrCreate?: Maybe<CanonicalSpeciesCreateOrConnectWithoutCanonicalBreedsInput>;
  connect?: Maybe<CanonicalSpeciesWhereUniqueInput>;
};

export type CanonicalSpeciesCreateOrConnectWithoutCanonicalBreedsInput = {
  where: CanonicalSpeciesWhereUniqueInput;
  create: CanonicalSpeciesUncheckedCreateWithoutCanonicalBreedsInput;
};

export type CanonicalSpeciesCreateOrConnectWithoutInformAudienceDefinitionsInput = {
  where: CanonicalSpeciesWhereUniqueInput;
  create: CanonicalSpeciesUncheckedCreateWithoutInformAudienceDefinitionsInput;
};

export type CanonicalSpeciesCreateWithoutCanonicalBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalSpeciesInput>;
};

export type CanonicalSpeciesCreateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  canonicalBreeds?: Maybe<CanonicalBreedCreateNestedManyWithoutCanonicalSpeciesInput>;
};

export type CanonicalSpeciesListRelationFilter = {
  every?: Maybe<CanonicalSpeciesWhereInput>;
  some?: Maybe<CanonicalSpeciesWhereInput>;
  none?: Maybe<CanonicalSpeciesWhereInput>;
};

export type CanonicalSpeciesMaxAggregateOutputType = {
  __typename?: 'CanonicalSpeciesMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
};

export type CanonicalSpeciesMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  scientificName?: Maybe<SortOrder>;
  genus?: Maybe<SortOrder>;
  family?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  class?: Maybe<SortOrder>;
  phylum?: Maybe<SortOrder>;
  kingdom?: Maybe<SortOrder>;
};

export type CanonicalSpeciesMinAggregateOutputType = {
  __typename?: 'CanonicalSpeciesMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
};

export type CanonicalSpeciesMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  scientificName?: Maybe<SortOrder>;
  genus?: Maybe<SortOrder>;
  family?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  class?: Maybe<SortOrder>;
  phylum?: Maybe<SortOrder>;
  kingdom?: Maybe<SortOrder>;
};

export type CanonicalSpeciesOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CanonicalSpeciesOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  ScientificName = 'scientificName',
  Genus = 'genus',
  Family = 'family',
  Order = 'order',
  Class = 'class',
  Phylum = 'phylum',
  Kingdom = 'kingdom'
}

export type CanonicalSpeciesOrderByRelevanceInput = {
  fields: Array<CanonicalSpeciesOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CanonicalSpeciesOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  scientificName?: Maybe<SortOrder>;
  genus?: Maybe<SortOrder>;
  family?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  class?: Maybe<SortOrder>;
  phylum?: Maybe<SortOrder>;
  kingdom?: Maybe<SortOrder>;
  _count?: Maybe<CanonicalSpeciesCountOrderByAggregateInput>;
  _max?: Maybe<CanonicalSpeciesMaxOrderByAggregateInput>;
  _min?: Maybe<CanonicalSpeciesMinOrderByAggregateInput>;
};

export type CanonicalSpeciesOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  scientificName?: Maybe<SortOrder>;
  genus?: Maybe<SortOrder>;
  family?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  class?: Maybe<SortOrder>;
  phylum?: Maybe<SortOrder>;
  kingdom?: Maybe<SortOrder>;
  canonicalBreeds?: Maybe<CanonicalBreedOrderByRelationAggregateInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionOrderByRelationAggregateInput>;
  _relevance?: Maybe<CanonicalSpeciesOrderByRelevanceInput>;
};

export type CanonicalSpeciesRelationFilter = {
  is?: Maybe<CanonicalSpeciesWhereInput>;
  isNot?: Maybe<CanonicalSpeciesWhereInput>;
};

export enum CanonicalSpeciesScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Name = 'name',
  ScientificName = 'scientificName',
  Genus = 'genus',
  Family = 'family',
  Order = 'order',
  Class = 'class',
  Phylum = 'phylum',
  Kingdom = 'kingdom'
}

export type CanonicalSpeciesScalarWhereInput = {
  AND?: Maybe<Array<CanonicalSpeciesScalarWhereInput>>;
  OR?: Maybe<Array<CanonicalSpeciesScalarWhereInput>>;
  NOT?: Maybe<Array<CanonicalSpeciesScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  scientificName?: Maybe<StringNullableFilter>;
  genus?: Maybe<StringNullableFilter>;
  family?: Maybe<StringNullableFilter>;
  order?: Maybe<StringNullableFilter>;
  class?: Maybe<StringNullableFilter>;
  phylum?: Maybe<StringNullableFilter>;
  kingdom?: Maybe<StringNullableFilter>;
};

export type CanonicalSpeciesScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CanonicalSpeciesScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CanonicalSpeciesScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CanonicalSpeciesScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  scientificName?: Maybe<StringNullableWithAggregatesFilter>;
  genus?: Maybe<StringNullableWithAggregatesFilter>;
  family?: Maybe<StringNullableWithAggregatesFilter>;
  order?: Maybe<StringNullableWithAggregatesFilter>;
  class?: Maybe<StringNullableWithAggregatesFilter>;
  phylum?: Maybe<StringNullableWithAggregatesFilter>;
  kingdom?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CanonicalSpeciesUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedCreateNestedManyWithoutCanonicalSpeciesInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalSpeciesInput>;
};

export type CanonicalSpeciesUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput = {
  create?: Maybe<Array<CanonicalSpeciesCreateWithoutInformAudienceDefinitionsInput>>;
  connectOrCreate?: Maybe<Array<CanonicalSpeciesCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  connect?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
};

export type CanonicalSpeciesUncheckedCreateWithoutCanonicalBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalSpeciesInput>;
};

export type CanonicalSpeciesUncheckedCreateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedCreateNestedManyWithoutCanonicalSpeciesInput>;
};

export type CanonicalSpeciesUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedUpdateManyWithoutCanonicalSpeciesNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalSpeciesNestedInput>;
};

export type CanonicalSpeciesUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
};

export type CanonicalSpeciesUncheckedUpdateManyWithoutCanonicalSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
};

export type CanonicalSpeciesUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput = {
  create?: Maybe<Array<CanonicalSpeciesCreateWithoutInformAudienceDefinitionsInput>>;
  connectOrCreate?: Maybe<Array<CanonicalSpeciesCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  upsert?: Maybe<Array<CanonicalSpeciesUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  set?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  disconnect?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  delete?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  connect?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalSpeciesUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  updateMany?: Maybe<Array<CanonicalSpeciesUpdateManyWithWhereWithoutInformAudienceDefinitionsInput>>;
  deleteMany?: Maybe<Array<CanonicalSpeciesScalarWhereInput>>;
};

export type CanonicalSpeciesUncheckedUpdateWithoutCanonicalBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalSpeciesNestedInput>;
};

export type CanonicalSpeciesUncheckedUpdateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedUpdateManyWithoutCanonicalSpeciesNestedInput>;
};

export type CanonicalSpeciesUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  canonicalBreeds?: Maybe<CanonicalBreedUpdateManyWithoutCanonicalSpeciesNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalSpeciesNestedInput>;
};

export type CanonicalSpeciesUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
};

export type CanonicalSpeciesUpdateManyWithWhereWithoutInformAudienceDefinitionsInput = {
  where: CanonicalSpeciesScalarWhereInput;
  data: CanonicalSpeciesUncheckedUpdateManyWithoutCanonicalSpeciesInput;
};

export type CanonicalSpeciesUpdateManyWithoutInformAudienceDefinitionsNestedInput = {
  create?: Maybe<Array<CanonicalSpeciesCreateWithoutInformAudienceDefinitionsInput>>;
  connectOrCreate?: Maybe<Array<CanonicalSpeciesCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  upsert?: Maybe<Array<CanonicalSpeciesUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  set?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  disconnect?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  delete?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  connect?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalSpeciesUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  updateMany?: Maybe<Array<CanonicalSpeciesUpdateManyWithWhereWithoutInformAudienceDefinitionsInput>>;
  deleteMany?: Maybe<Array<CanonicalSpeciesScalarWhereInput>>;
};

export type CanonicalSpeciesUpdateOneRequiredWithoutCanonicalBreedsNestedInput = {
  create?: Maybe<CanonicalSpeciesUncheckedCreateWithoutCanonicalBreedsInput>;
  connectOrCreate?: Maybe<CanonicalSpeciesCreateOrConnectWithoutCanonicalBreedsInput>;
  upsert?: Maybe<CanonicalSpeciesUpsertWithoutCanonicalBreedsInput>;
  connect?: Maybe<CanonicalSpeciesWhereUniqueInput>;
  update?: Maybe<CanonicalSpeciesUncheckedUpdateWithoutCanonicalBreedsInput>;
};

export type CanonicalSpeciesUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput = {
  where: CanonicalSpeciesWhereUniqueInput;
  data: CanonicalSpeciesUncheckedUpdateWithoutInformAudienceDefinitionsInput;
};

export type CanonicalSpeciesUpdateWithoutCanonicalBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalSpeciesNestedInput>;
};

export type CanonicalSpeciesUpdateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  canonicalBreeds?: Maybe<CanonicalBreedUpdateManyWithoutCanonicalSpeciesNestedInput>;
};

export type CanonicalSpeciesUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput = {
  where: CanonicalSpeciesWhereUniqueInput;
  update: CanonicalSpeciesUncheckedUpdateWithoutInformAudienceDefinitionsInput;
  create: CanonicalSpeciesUncheckedCreateWithoutInformAudienceDefinitionsInput;
};

export type CanonicalSpeciesUpsertWithoutCanonicalBreedsInput = {
  update: CanonicalSpeciesUncheckedUpdateWithoutCanonicalBreedsInput;
  create: CanonicalSpeciesUncheckedCreateWithoutCanonicalBreedsInput;
};

export type CanonicalSpeciesWhereInput = {
  AND?: Maybe<Array<CanonicalSpeciesWhereInput>>;
  OR?: Maybe<Array<CanonicalSpeciesWhereInput>>;
  NOT?: Maybe<Array<CanonicalSpeciesWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  scientificName?: Maybe<StringNullableFilter>;
  genus?: Maybe<StringNullableFilter>;
  family?: Maybe<StringNullableFilter>;
  order?: Maybe<StringNullableFilter>;
  class?: Maybe<StringNullableFilter>;
  phylum?: Maybe<StringNullableFilter>;
  kingdom?: Maybe<StringNullableFilter>;
  canonicalBreeds?: Maybe<CanonicalBreedListRelationFilter>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionListRelationFilter>;
};

export type CanonicalSpeciesWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum CardBrand {
  Visa = 'Visa',
  Mastercard = 'Mastercard',
  Amex = 'Amex',
  Discover = 'Discover',
  Diners = 'Diners',
  Jcb = 'JCB',
  UnionPay = 'UnionPay',
  Unknown = 'Unknown'
}

export enum CardFunding {
  Credit = 'credit',
  Debit = 'debit',
  Prepaid = 'prepaid',
  Unknown = 'unknown'
}

export type CareAccountCreditIntent = {
  __typename?: 'CareAccountCreditIntent';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicPetParentId: Scalars['String'];
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue: Scalars['Int'];
  currency: Currency;
  clinicPetParent: ClinicPetParent;
  followupStep?: Maybe<CareBenefitUsageFollowupStep>;
  invoice?: Maybe<Invoice>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  careBenefitUsages: Array<CareBenefitUsage>;
  careAccountCreditPimsWritebackLogEntries: Array<CareAccountCreditPimsWritebackLogEntry>;
  _count: CareAccountCreditIntentCountOutputType;
};


export type CareAccountCreditIntentStripePaymentIntentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};


export type CareAccountCreditIntentCareBenefitUsagesArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
};


export type CareAccountCreditIntentCareAccountCreditPimsWritebackLogEntriesArgs = {
  where?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<CareAccountCreditPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAccountCreditPimsWritebackLogEntryScalarFieldEnum>;
};

export type CareAccountCreditIntentAvgAggregateOutputType = {
  __typename?: 'CareAccountCreditIntentAvgAggregateOutputType';
  paymentDue?: Maybe<Scalars['Float']>;
};

export type CareAccountCreditIntentAvgOrderByAggregateInput = {
  paymentDue?: Maybe<SortOrder>;
};

export type CareAccountCreditIntentCountAggregateOutputType = {
  __typename?: 'CareAccountCreditIntentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  followupStepId: Scalars['Int'];
  invoiceId: Scalars['Int'];
  paymentDue: Scalars['Int'];
  currency: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareAccountCreditIntentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  followupStepId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  paymentDue?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
};

export type CareAccountCreditIntentCountOutputType = {
  __typename?: 'CareAccountCreditIntentCountOutputType';
  stripePaymentIntents: Scalars['Int'];
  careBenefitUsages: Scalars['Int'];
  careAccountCreditPimsWritebackLogEntries: Scalars['Int'];
};

export type CareAccountCreditIntentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAccountCreditIntentsInput;
  followupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  invoice?: Maybe<InvoiceCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedManyWithoutCareAccountCreditIntentInput>;
};

export type CareAccountCreditIntentCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
};

export type CareAccountCreditIntentCreateManyClinicPetParentInputEnvelope = {
  data: Array<CareAccountCreditIntentCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAccountCreditIntentCreateManyFollowupStepInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
};

export type CareAccountCreditIntentCreateManyFollowupStepInputEnvelope = {
  data: Array<CareAccountCreditIntentCreateManyFollowupStepInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAccountCreditIntentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
};

export type CareAccountCreditIntentCreateManyInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  followupStepId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
};

export type CareAccountCreditIntentCreateManyInvoiceInputEnvelope = {
  data: Array<CareAccountCreditIntentCreateManyInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
};

export type CareAccountCreditIntentCreateNestedManyWithoutFollowupStepInput = {
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutFollowupStepInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutFollowupStepInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyFollowupStepInputEnvelope>;
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
};

export type CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput = {
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutInvoiceInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyInvoiceInputEnvelope>;
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
};

export type CareAccountCreditIntentCreateNestedOneWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  create?: Maybe<CareAccountCreditIntentUncheckedCreateWithoutCareAccountCreditPimsWritebackLogEntriesInput>;
  connectOrCreate?: Maybe<CareAccountCreditIntentCreateOrConnectWithoutCareAccountCreditPimsWritebackLogEntriesInput>;
  connect?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
};

export type CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput = {
  create?: Maybe<CareAccountCreditIntentUncheckedCreateWithoutCareBenefitUsagesInput>;
  connectOrCreate?: Maybe<CareAccountCreditIntentCreateOrConnectWithoutCareBenefitUsagesInput>;
  connect?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
};

export type CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput = {
  create?: Maybe<CareAccountCreditIntentUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<CareAccountCreditIntentCreateOrConnectWithoutStripePaymentIntentsInput>;
  connect?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
};

export type CareAccountCreditIntentCreateOrConnectWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  where: CareAccountCreditIntentWhereUniqueInput;
  create: CareAccountCreditIntentUncheckedCreateWithoutCareAccountCreditPimsWritebackLogEntriesInput;
};

export type CareAccountCreditIntentCreateOrConnectWithoutCareBenefitUsagesInput = {
  where: CareAccountCreditIntentWhereUniqueInput;
  create: CareAccountCreditIntentUncheckedCreateWithoutCareBenefitUsagesInput;
};

export type CareAccountCreditIntentCreateOrConnectWithoutClinicPetParentInput = {
  where: CareAccountCreditIntentWhereUniqueInput;
  create: CareAccountCreditIntentUncheckedCreateWithoutClinicPetParentInput;
};

export type CareAccountCreditIntentCreateOrConnectWithoutFollowupStepInput = {
  where: CareAccountCreditIntentWhereUniqueInput;
  create: CareAccountCreditIntentUncheckedCreateWithoutFollowupStepInput;
};

export type CareAccountCreditIntentCreateOrConnectWithoutInvoiceInput = {
  where: CareAccountCreditIntentWhereUniqueInput;
  create: CareAccountCreditIntentUncheckedCreateWithoutInvoiceInput;
};

export type CareAccountCreditIntentCreateOrConnectWithoutStripePaymentIntentsInput = {
  where: CareAccountCreditIntentWhereUniqueInput;
  create: CareAccountCreditIntentUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type CareAccountCreditIntentCreateWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAccountCreditIntentsInput;
  followupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  invoice?: Maybe<InvoiceCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutCareAccountCreditIntentInput>;
};

export type CareAccountCreditIntentCreateWithoutCareBenefitUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAccountCreditIntentsInput;
  followupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  invoice?: Maybe<InvoiceCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedManyWithoutCareAccountCreditIntentInput>;
};

export type CareAccountCreditIntentCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  invoice?: Maybe<InvoiceCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedManyWithoutCareAccountCreditIntentInput>;
};

export type CareAccountCreditIntentCreateWithoutFollowupStepInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAccountCreditIntentsInput;
  invoice?: Maybe<InvoiceCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedManyWithoutCareAccountCreditIntentInput>;
};

export type CareAccountCreditIntentCreateWithoutInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAccountCreditIntentsInput;
  followupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedManyWithoutCareAccountCreditIntentInput>;
};

export type CareAccountCreditIntentCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAccountCreditIntentsInput;
  followupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  invoice?: Maybe<InvoiceCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedManyWithoutCareAccountCreditIntentInput>;
};

export type CareAccountCreditIntentListRelationFilter = {
  every?: Maybe<CareAccountCreditIntentWhereInput>;
  some?: Maybe<CareAccountCreditIntentWhereInput>;
  none?: Maybe<CareAccountCreditIntentWhereInput>;
};

export type CareAccountCreditIntentMaxAggregateOutputType = {
  __typename?: 'CareAccountCreditIntentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
};

export type CareAccountCreditIntentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  followupStepId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  paymentDue?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
};

export type CareAccountCreditIntentMinAggregateOutputType = {
  __typename?: 'CareAccountCreditIntentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
};

export type CareAccountCreditIntentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  followupStepId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  paymentDue?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
};

export type CareAccountCreditIntentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAccountCreditIntentOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicPetParentId = 'clinicPetParentId',
  FollowupStepId = 'followupStepId',
  InvoiceId = 'invoiceId'
}

export type CareAccountCreditIntentOrderByRelevanceInput = {
  fields: Array<CareAccountCreditIntentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareAccountCreditIntentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  followupStepId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  paymentDue?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  _count?: Maybe<CareAccountCreditIntentCountOrderByAggregateInput>;
  _avg?: Maybe<CareAccountCreditIntentAvgOrderByAggregateInput>;
  _max?: Maybe<CareAccountCreditIntentMaxOrderByAggregateInput>;
  _min?: Maybe<CareAccountCreditIntentMinOrderByAggregateInput>;
  _sum?: Maybe<CareAccountCreditIntentSumOrderByAggregateInput>;
};

export type CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  followupStepId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  paymentDue?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>;
  invoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryOrderByRelationAggregateInput>;
  _relevance?: Maybe<CareAccountCreditIntentOrderByRelevanceInput>;
};

export type CareAccountCreditIntentRelationFilter = {
  is?: Maybe<CareAccountCreditIntentWhereInput>;
  isNot?: Maybe<CareAccountCreditIntentWhereInput>;
};

export enum CareAccountCreditIntentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicPetParentId = 'clinicPetParentId',
  FollowupStepId = 'followupStepId',
  InvoiceId = 'invoiceId',
  PaymentDue = 'paymentDue',
  Currency = 'currency'
}

export type CareAccountCreditIntentScalarWhereInput = {
  AND?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
  OR?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
  NOT?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  followupStepId?: Maybe<StringNullableFilter>;
  invoiceId?: Maybe<StringNullableFilter>;
  paymentDue?: Maybe<IntFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
};

export type CareAccountCreditIntentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAccountCreditIntentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAccountCreditIntentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAccountCreditIntentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  followupStepId?: Maybe<StringNullableWithAggregatesFilter>;
  invoiceId?: Maybe<StringNullableWithAggregatesFilter>;
  paymentDue?: Maybe<IntWithAggregatesFilter>;
  currency?: Maybe<EnumCurrencyWithAggregatesFilter>;
};

export type CareAccountCreditIntentSumAggregateOutputType = {
  __typename?: 'CareAccountCreditIntentSumAggregateOutputType';
  paymentDue?: Maybe<Scalars['Int']>;
};

export type CareAccountCreditIntentSumOrderByAggregateInput = {
  paymentDue?: Maybe<SortOrder>;
};

export type CareAccountCreditIntentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
};

export type CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
};

export type CareAccountCreditIntentUncheckedCreateNestedManyWithoutFollowupStepInput = {
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutFollowupStepInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutFollowupStepInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyFollowupStepInputEnvelope>;
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
};

export type CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput = {
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutInvoiceInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyInvoiceInputEnvelope>;
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
};

export type CareAccountCreditIntentUncheckedCreateWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
};

export type CareAccountCreditIntentUncheckedCreateWithoutCareBenefitUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
};

export type CareAccountCreditIntentUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
};

export type CareAccountCreditIntentUncheckedCreateWithoutFollowupStepInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
};

export type CareAccountCreditIntentUncheckedCreateWithoutInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  followupStepId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
};

export type CareAccountCreditIntentUncheckedCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
};

export type CareAccountCreditIntentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
};

export type CareAccountCreditIntentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
};

export type CareAccountCreditIntentUncheckedUpdateManyWithoutCareAccountCreditIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
};

export type CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CareAccountCreditIntentUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditIntentUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CareAccountCreditIntentUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
};

export type CareAccountCreditIntentUncheckedUpdateManyWithoutFollowupStepNestedInput = {
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutFollowupStepInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutFollowupStepInput>>;
  upsert?: Maybe<Array<CareAccountCreditIntentUpsertWithWhereUniqueWithoutFollowupStepInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyFollowupStepInputEnvelope>;
  set?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditIntentUpdateWithWhereUniqueWithoutFollowupStepInput>>;
  updateMany?: Maybe<Array<CareAccountCreditIntentUpdateManyWithWhereWithoutFollowupStepInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
};

export type CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput = {
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutInvoiceInput>>;
  upsert?: Maybe<Array<CareAccountCreditIntentUpsertWithWhereUniqueWithoutInvoiceInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyInvoiceInputEnvelope>;
  set?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditIntentUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<CareAccountCreditIntentUpdateManyWithWhereWithoutInvoiceInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
};

export type CareAccountCreditIntentUncheckedUpdateWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
};

export type CareAccountCreditIntentUncheckedUpdateWithoutCareBenefitUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
};

export type CareAccountCreditIntentUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
};

export type CareAccountCreditIntentUncheckedUpdateWithoutFollowupStepInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
};

export type CareAccountCreditIntentUncheckedUpdateWithoutInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  followupStepId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
};

export type CareAccountCreditIntentUncheckedUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  followupStepId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
};

export type CareAccountCreditIntentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAccountCreditIntentsNestedInput>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  invoice?: Maybe<InvoiceUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateManyWithoutCareAccountCreditIntentNestedInput>;
};

export type CareAccountCreditIntentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
};

export type CareAccountCreditIntentUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: CareAccountCreditIntentScalarWhereInput;
  data: CareAccountCreditIntentUncheckedUpdateManyWithoutCareAccountCreditIntentsInput;
};

export type CareAccountCreditIntentUpdateManyWithWhereWithoutFollowupStepInput = {
  where: CareAccountCreditIntentScalarWhereInput;
  data: CareAccountCreditIntentUncheckedUpdateManyWithoutCareAccountCreditIntentsInput;
};

export type CareAccountCreditIntentUpdateManyWithWhereWithoutInvoiceInput = {
  where: CareAccountCreditIntentScalarWhereInput;
  data: CareAccountCreditIntentUncheckedUpdateManyWithoutCareAccountCreditIntentsInput;
};

export type CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CareAccountCreditIntentUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditIntentUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CareAccountCreditIntentUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
};

export type CareAccountCreditIntentUpdateManyWithoutFollowupStepNestedInput = {
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutFollowupStepInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutFollowupStepInput>>;
  upsert?: Maybe<Array<CareAccountCreditIntentUpsertWithWhereUniqueWithoutFollowupStepInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyFollowupStepInputEnvelope>;
  set?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditIntentUpdateWithWhereUniqueWithoutFollowupStepInput>>;
  updateMany?: Maybe<Array<CareAccountCreditIntentUpdateManyWithWhereWithoutFollowupStepInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
};

export type CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput = {
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutInvoiceInput>>;
  upsert?: Maybe<Array<CareAccountCreditIntentUpsertWithWhereUniqueWithoutInvoiceInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyInvoiceInputEnvelope>;
  set?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditIntentUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<CareAccountCreditIntentUpdateManyWithWhereWithoutInvoiceInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
};

export type CareAccountCreditIntentUpdateOneRequiredWithoutCareAccountCreditPimsWritebackLogEntriesNestedInput = {
  create?: Maybe<CareAccountCreditIntentUncheckedCreateWithoutCareAccountCreditPimsWritebackLogEntriesInput>;
  connectOrCreate?: Maybe<CareAccountCreditIntentCreateOrConnectWithoutCareAccountCreditPimsWritebackLogEntriesInput>;
  upsert?: Maybe<CareAccountCreditIntentUpsertWithoutCareAccountCreditPimsWritebackLogEntriesInput>;
  connect?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  update?: Maybe<CareAccountCreditIntentUncheckedUpdateWithoutCareAccountCreditPimsWritebackLogEntriesInput>;
};

export type CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput = {
  create?: Maybe<CareAccountCreditIntentUncheckedCreateWithoutCareBenefitUsagesInput>;
  connectOrCreate?: Maybe<CareAccountCreditIntentCreateOrConnectWithoutCareBenefitUsagesInput>;
  upsert?: Maybe<CareAccountCreditIntentUpsertWithoutCareBenefitUsagesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  update?: Maybe<CareAccountCreditIntentUncheckedUpdateWithoutCareBenefitUsagesInput>;
};

export type CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput = {
  create?: Maybe<CareAccountCreditIntentUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<CareAccountCreditIntentCreateOrConnectWithoutStripePaymentIntentsInput>;
  upsert?: Maybe<CareAccountCreditIntentUpsertWithoutStripePaymentIntentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  update?: Maybe<CareAccountCreditIntentUncheckedUpdateWithoutStripePaymentIntentsInput>;
};

export type CareAccountCreditIntentUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: CareAccountCreditIntentWhereUniqueInput;
  data: CareAccountCreditIntentUncheckedUpdateWithoutClinicPetParentInput;
};

export type CareAccountCreditIntentUpdateWithWhereUniqueWithoutFollowupStepInput = {
  where: CareAccountCreditIntentWhereUniqueInput;
  data: CareAccountCreditIntentUncheckedUpdateWithoutFollowupStepInput;
};

export type CareAccountCreditIntentUpdateWithWhereUniqueWithoutInvoiceInput = {
  where: CareAccountCreditIntentWhereUniqueInput;
  data: CareAccountCreditIntentUncheckedUpdateWithoutInvoiceInput;
};

export type CareAccountCreditIntentUpdateWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAccountCreditIntentsNestedInput>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  invoice?: Maybe<InvoiceUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutCareAccountCreditIntentNestedInput>;
};

export type CareAccountCreditIntentUpdateWithoutCareBenefitUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAccountCreditIntentsNestedInput>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  invoice?: Maybe<InvoiceUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateManyWithoutCareAccountCreditIntentNestedInput>;
};

export type CareAccountCreditIntentUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  invoice?: Maybe<InvoiceUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateManyWithoutCareAccountCreditIntentNestedInput>;
};

export type CareAccountCreditIntentUpdateWithoutFollowupStepInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAccountCreditIntentsNestedInput>;
  invoice?: Maybe<InvoiceUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateManyWithoutCareAccountCreditIntentNestedInput>;
};

export type CareAccountCreditIntentUpdateWithoutInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAccountCreditIntentsNestedInput>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateManyWithoutCareAccountCreditIntentNestedInput>;
};

export type CareAccountCreditIntentUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAccountCreditIntentsNestedInput>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  invoice?: Maybe<InvoiceUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateManyWithoutCareAccountCreditIntentNestedInput>;
};

export type CareAccountCreditIntentUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: CareAccountCreditIntentWhereUniqueInput;
  update: CareAccountCreditIntentUncheckedUpdateWithoutClinicPetParentInput;
  create: CareAccountCreditIntentUncheckedCreateWithoutClinicPetParentInput;
};

export type CareAccountCreditIntentUpsertWithWhereUniqueWithoutFollowupStepInput = {
  where: CareAccountCreditIntentWhereUniqueInput;
  update: CareAccountCreditIntentUncheckedUpdateWithoutFollowupStepInput;
  create: CareAccountCreditIntentUncheckedCreateWithoutFollowupStepInput;
};

export type CareAccountCreditIntentUpsertWithWhereUniqueWithoutInvoiceInput = {
  where: CareAccountCreditIntentWhereUniqueInput;
  update: CareAccountCreditIntentUncheckedUpdateWithoutInvoiceInput;
  create: CareAccountCreditIntentUncheckedCreateWithoutInvoiceInput;
};

export type CareAccountCreditIntentUpsertWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  update: CareAccountCreditIntentUncheckedUpdateWithoutCareAccountCreditPimsWritebackLogEntriesInput;
  create: CareAccountCreditIntentUncheckedCreateWithoutCareAccountCreditPimsWritebackLogEntriesInput;
};

export type CareAccountCreditIntentUpsertWithoutCareBenefitUsagesInput = {
  update: CareAccountCreditIntentUncheckedUpdateWithoutCareBenefitUsagesInput;
  create: CareAccountCreditIntentUncheckedCreateWithoutCareBenefitUsagesInput;
};

export type CareAccountCreditIntentUpsertWithoutStripePaymentIntentsInput = {
  update: CareAccountCreditIntentUncheckedUpdateWithoutStripePaymentIntentsInput;
  create: CareAccountCreditIntentUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type CareAccountCreditIntentWhereInput = {
  AND?: Maybe<Array<CareAccountCreditIntentWhereInput>>;
  OR?: Maybe<Array<CareAccountCreditIntentWhereInput>>;
  NOT?: Maybe<Array<CareAccountCreditIntentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  followupStepId?: Maybe<StringNullableFilter>;
  invoiceId?: Maybe<StringNullableFilter>;
  paymentDue?: Maybe<IntFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  invoice?: Maybe<InvoiceWhereInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  careBenefitUsages?: Maybe<CareBenefitUsageListRelationFilter>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryListRelationFilter>;
};

export type CareAccountCreditIntentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntry = {
  __typename?: 'CareAccountCreditPimsWritebackLogEntry';
  writebackLogId: Scalars['String'];
  careAccountCreditIntentId: Scalars['String'];
  writebackLog: PimsWritebackLogEntry;
  careAccountCreditIntent: CareAccountCreditIntent;
};

export type CareAccountCreditPimsWritebackLogEntryCountAggregateOutputType = {
  __typename?: 'CareAccountCreditPimsWritebackLogEntryCountAggregateOutputType';
  writebackLogId: Scalars['Int'];
  careAccountCreditIntentId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareAccountCreditPimsWritebackLogEntryCountOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
};

export type CareAccountCreditPimsWritebackLogEntryCreateInput = {
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutCareAccountCreditLogInput;
  careAccountCreditIntent: CareAccountCreditIntentCreateNestedOneWithoutCareAccountCreditPimsWritebackLogEntriesInput;
};

export type CareAccountCreditPimsWritebackLogEntryCreateManyCareAccountCreditIntentInput = {
  writebackLogId: Scalars['String'];
};

export type CareAccountCreditPimsWritebackLogEntryCreateManyCareAccountCreditIntentInputEnvelope = {
  data: Array<CareAccountCreditPimsWritebackLogEntryCreateManyCareAccountCreditIntentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAccountCreditPimsWritebackLogEntryCreateManyInput = {
  writebackLogId: Scalars['String'];
  careAccountCreditIntentId: Scalars['String'];
};

export type CareAccountCreditPimsWritebackLogEntryCreateNestedManyWithoutCareAccountCreditIntentInput = {
  create?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateWithoutCareAccountCreditIntentInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateManyCareAccountCreditIntentInputEnvelope>;
  connect?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
};

export type CareAccountCreditPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput = {
  create?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  connect?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
};

export type CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditIntentInput = {
  where: CareAccountCreditPimsWritebackLogEntryWhereUniqueInput;
  create: CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditIntentInput;
};

export type CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput = {
  where: CareAccountCreditPimsWritebackLogEntryWhereUniqueInput;
  create: CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
};

export type CareAccountCreditPimsWritebackLogEntryCreateWithoutCareAccountCreditIntentInput = {
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutCareAccountCreditLogInput;
};

export type CareAccountCreditPimsWritebackLogEntryCreateWithoutWritebackLogInput = {
  careAccountCreditIntent: CareAccountCreditIntentCreateNestedOneWithoutCareAccountCreditPimsWritebackLogEntriesInput;
};

export type CareAccountCreditPimsWritebackLogEntryListRelationFilter = {
  every?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
  some?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
  none?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
};

export type CareAccountCreditPimsWritebackLogEntryMaxAggregateOutputType = {
  __typename?: 'CareAccountCreditPimsWritebackLogEntryMaxAggregateOutputType';
  writebackLogId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntryMaxOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
};

export type CareAccountCreditPimsWritebackLogEntryMinAggregateOutputType = {
  __typename?: 'CareAccountCreditPimsWritebackLogEntryMinAggregateOutputType';
  writebackLogId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntryMinOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
};

export type CareAccountCreditPimsWritebackLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAccountCreditPimsWritebackLogEntryOrderByRelevanceFieldEnum {
  WritebackLogId = 'writebackLogId',
  CareAccountCreditIntentId = 'careAccountCreditIntentId'
}

export type CareAccountCreditPimsWritebackLogEntryOrderByRelevanceInput = {
  fields: Array<CareAccountCreditPimsWritebackLogEntryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareAccountCreditPimsWritebackLogEntryOrderByWithAggregationInput = {
  writebackLogId?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  _count?: Maybe<CareAccountCreditPimsWritebackLogEntryCountOrderByAggregateInput>;
  _max?: Maybe<CareAccountCreditPimsWritebackLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<CareAccountCreditPimsWritebackLogEntryMinOrderByAggregateInput>;
};

export type CareAccountCreditPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  writebackLogId?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  writebackLog?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CareAccountCreditPimsWritebackLogEntryOrderByRelevanceInput>;
};

export type CareAccountCreditPimsWritebackLogEntryRelationFilter = {
  is?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
  isNot?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
};

export enum CareAccountCreditPimsWritebackLogEntryScalarFieldEnum {
  WritebackLogId = 'writebackLogId',
  CareAccountCreditIntentId = 'careAccountCreditIntentId'
}

export type CareAccountCreditPimsWritebackLogEntryScalarWhereInput = {
  AND?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereInput>>;
  writebackLogId?: Maybe<StringFilter>;
  careAccountCreditIntentId?: Maybe<StringFilter>;
};

export type CareAccountCreditPimsWritebackLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  writebackLogId?: Maybe<StringWithAggregatesFilter>;
  careAccountCreditIntentId?: Maybe<StringWithAggregatesFilter>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedCreateInput = {
  writebackLogId: Scalars['String'];
  careAccountCreditIntentId: Scalars['String'];
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput = {
  create?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateWithoutCareAccountCreditIntentInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateManyCareAccountCreditIntentInputEnvelope>;
  connect?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput = {
  create?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  connect?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditIntentInput = {
  writebackLogId: Scalars['String'];
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput = {
  careAccountCreditIntentId: Scalars['String'];
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedUpdateInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput = {
  create?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateWithoutCareAccountCreditIntentInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  upsert?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateManyCareAccountCreditIntentInputEnvelope>;
  set?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  updateMany?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryUpdateManyWithWhereWithoutCareAccountCreditIntentInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereInput>>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  writebackLogId?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput = {
  create?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  upsert?: Maybe<CareAccountCreditPimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedUpdateWithoutCareAccountCreditIntentInput = {
  writebackLogId?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput = {
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntryUpdateInput = {
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutCareAccountCreditLogNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneRequiredWithoutCareAccountCreditPimsWritebackLogEntriesNestedInput>;
};

export type CareAccountCreditPimsWritebackLogEntryUpdateManyWithWhereWithoutCareAccountCreditIntentInput = {
  where: CareAccountCreditPimsWritebackLogEntryScalarWhereInput;
  data: CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditPimsWritebackLogEntriesInput;
};

export type CareAccountCreditPimsWritebackLogEntryUpdateManyWithoutCareAccountCreditIntentNestedInput = {
  create?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateWithoutCareAccountCreditIntentInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  upsert?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateManyCareAccountCreditIntentInputEnvelope>;
  set?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  updateMany?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryUpdateManyWithWhereWithoutCareAccountCreditIntentInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereInput>>;
};

export type CareAccountCreditPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput = {
  create?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  upsert?: Maybe<CareAccountCreditPimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
};

export type CareAccountCreditPimsWritebackLogEntryUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput = {
  where: CareAccountCreditPimsWritebackLogEntryWhereUniqueInput;
  data: CareAccountCreditPimsWritebackLogEntryUncheckedUpdateWithoutCareAccountCreditIntentInput;
};

export type CareAccountCreditPimsWritebackLogEntryUpdateWithoutCareAccountCreditIntentInput = {
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutCareAccountCreditLogNestedInput>;
};

export type CareAccountCreditPimsWritebackLogEntryUpdateWithoutWritebackLogInput = {
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneRequiredWithoutCareAccountCreditPimsWritebackLogEntriesNestedInput>;
};

export type CareAccountCreditPimsWritebackLogEntryUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput = {
  where: CareAccountCreditPimsWritebackLogEntryWhereUniqueInput;
  update: CareAccountCreditPimsWritebackLogEntryUncheckedUpdateWithoutCareAccountCreditIntentInput;
  create: CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditIntentInput;
};

export type CareAccountCreditPimsWritebackLogEntryUpsertWithoutWritebackLogInput = {
  update: CareAccountCreditPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput;
  create: CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
};

export type CareAccountCreditPimsWritebackLogEntryWhereInput = {
  AND?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereInput>>;
  OR?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereInput>>;
  NOT?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereInput>>;
  writebackLogId?: Maybe<StringFilter>;
  careAccountCreditIntentId?: Maybe<StringFilter>;
  writebackLog?: Maybe<PimsWritebackLogEntryWhereInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentWhereInput>;
};

export type CareAccountCreditPimsWritebackLogEntryWhereUniqueInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  writebackLogId_careAccountCreditIntentId?: Maybe<CareAccountCreditPimsWritebackLogEntryWritebackLogIdCareAccountCreditIntentIdCompoundUniqueInput>;
};

export type CareAccountCreditPimsWritebackLogEntryWritebackLogIdCareAccountCreditIntentIdCompoundUniqueInput = {
  writebackLogId: Scalars['String'];
  careAccountCreditIntentId: Scalars['String'];
};

export type CareAddonEnrollment = {
  __typename?: 'CareAddonEnrollment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  status: EnrollmentStatus;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPet;
  addOnPackage: CareAddonPackage;
  careStripeSubscription?: Maybe<CareStripeSubscription>;
  enrollmentToStripeInvoiceItems: Array<CareEnrollmentToStripeInvoiceItem>;
  enrollmentPayments: Array<CareEnrollmentPayment>;
  associatedUsages: Array<CareBenefitUsage>;
  _count: CareAddonEnrollmentCountOutputType;
};


export type CareAddonEnrollmentEnrollmentToStripeInvoiceItemsArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  orderBy?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>;
};


export type CareAddonEnrollmentEnrollmentPaymentsArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
  orderBy?: Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareEnrollmentPaymentScalarFieldEnum>;
};


export type CareAddonEnrollmentAssociatedUsagesArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
};

export type CareAddonEnrollmentCountAggregateOutputType = {
  __typename?: 'CareAddonEnrollmentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  startDate: Scalars['Int'];
  expirationDate: Scalars['Int'];
  status: Scalars['Int'];
  organizationPetId: Scalars['Int'];
  addOnPackageId: Scalars['Int'];
  careStripeSubscriptionId: Scalars['Int'];
  stripeSubscriptionItemId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareAddonEnrollmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type CareAddonEnrollmentCountOutputType = {
  __typename?: 'CareAddonEnrollmentCountOutputType';
  enrollmentToStripeInvoiceItems: Scalars['Int'];
  enrollmentPayments: Scalars['Int'];
  associatedUsages: Scalars['Int'];
};

export type CareAddonEnrollmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentCreateManyAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope = {
  data: Array<CareAddonEnrollmentCreateManyAddOnPackageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonEnrollmentCreateManyCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope = {
  data: Array<CareAddonEnrollmentCreateManyCareStripeSubscriptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonEnrollmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentCreateManyOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope = {
  data: Array<CareAddonEnrollmentCreateManyOrganizationPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonEnrollmentCreateNestedManyWithoutAddOnPackageInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
};

export type CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
};

export type CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
};

export type CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput = {
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutAssociatedUsagesInput>;
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
};

export type CareAddonEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput = {
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput>;
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
};

export type CareAddonEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput = {
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
};

export type CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutAddOnPackageInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutAssociatedUsagesInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutOrganizationPetInput;
};

export type CareAddonEnrollmentCreateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentCreateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
};

export type CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentCreateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentCreateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentListRelationFilter = {
  every?: Maybe<CareAddonEnrollmentWhereInput>;
  some?: Maybe<CareAddonEnrollmentWhereInput>;
  none?: Maybe<CareAddonEnrollmentWhereInput>;
};

export type CareAddonEnrollmentMaxAggregateOutputType = {
  __typename?: 'CareAddonEnrollmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type CareAddonEnrollmentMinAggregateOutputType = {
  __typename?: 'CareAddonEnrollmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type CareAddonEnrollmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAddonEnrollmentOrderByRelevanceFieldEnum {
  Id = 'id',
  OrganizationPetId = 'organizationPetId',
  AddOnPackageId = 'addOnPackageId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId'
}

export type CareAddonEnrollmentOrderByRelevanceInput = {
  fields: Array<CareAddonEnrollmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareAddonEnrollmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  _count?: Maybe<CareAddonEnrollmentCountOrderByAggregateInput>;
  _max?: Maybe<CareAddonEnrollmentMaxOrderByAggregateInput>;
  _min?: Maybe<CareAddonEnrollmentMinOrderByAggregateInput>;
};

export type CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  organizationPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  addOnPackage?: Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByRelationAggregateInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentOrderByRelationAggregateInput>;
  associatedUsages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  _relevance?: Maybe<CareAddonEnrollmentOrderByRelevanceInput>;
};

export type CareAddonEnrollmentRelationFilter = {
  is?: Maybe<CareAddonEnrollmentWhereInput>;
  isNot?: Maybe<CareAddonEnrollmentWhereInput>;
};

export enum CareAddonEnrollmentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StartDate = 'startDate',
  ExpirationDate = 'expirationDate',
  Status = 'status',
  OrganizationPetId = 'organizationPetId',
  AddOnPackageId = 'addOnPackageId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId'
}

export type CareAddonEnrollmentScalarWhereInput = {
  AND?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  OR?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  NOT?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  startDate?: Maybe<DateTimeFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumEnrollmentStatusFilter>;
  organizationPetId?: Maybe<StringFilter>;
  addOnPackageId?: Maybe<StringFilter>;
  careStripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
};

export type CareAddonEnrollmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAddonEnrollmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAddonEnrollmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAddonEnrollmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  startDate?: Maybe<DateTimeWithAggregatesFilter>;
  expirationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumEnrollmentStatusWithAggregatesFilter>;
  organizationPetId?: Maybe<StringWithAggregatesFilter>;
  addOnPackageId?: Maybe<StringWithAggregatesFilter>;
  careStripeSubscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareAddonEnrollmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedCreateNestedManyWithoutAddOnPackageInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
};

export type CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
};

export type CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
};

export type CareAddonEnrollmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutAddOnPackageNestedInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutAddOnPackageInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutAddOnPackageInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
};

export type CareAddonEnrollmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsNestedInput>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
};

export type CareAddonEnrollmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentUpdateManyWithWhereWithoutAddOnPackageInput = {
  where: CareAddonEnrollmentScalarWhereInput;
  data: CareAddonEnrollmentUncheckedUpdateManyWithoutEnrollmentsInput;
};

export type CareAddonEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput = {
  where: CareAddonEnrollmentScalarWhereInput;
  data: CareAddonEnrollmentUncheckedUpdateManyWithoutCareAddonEnrollmentsInput;
};

export type CareAddonEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput = {
  where: CareAddonEnrollmentScalarWhereInput;
  data: CareAddonEnrollmentUncheckedUpdateManyWithoutCareAddonEnrollmentsInput;
};

export type CareAddonEnrollmentUpdateManyWithoutAddOnPackageNestedInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutAddOnPackageInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutAddOnPackageInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
};

export type CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
};

export type CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
};

export type CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput = {
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutAssociatedUsagesInput>;
  upsert?: Maybe<CareAddonEnrollmentUpsertWithoutAssociatedUsagesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  update?: Maybe<CareAddonEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput>;
};

export type CareAddonEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput = {
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput>;
  upsert?: Maybe<CareAddonEnrollmentUpsertWithoutEnrollmentPaymentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  update?: Maybe<CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput>;
};

export type CareAddonEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput = {
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  upsert?: Maybe<CareAddonEnrollmentUpsertWithoutEnrollmentToStripeInvoiceItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  update?: Maybe<CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type CareAddonEnrollmentUpdateWithWhereUniqueWithoutAddOnPackageInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  data: CareAddonEnrollmentUncheckedUpdateWithoutAddOnPackageInput;
};

export type CareAddonEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  data: CareAddonEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput;
};

export type CareAddonEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  data: CareAddonEnrollmentUncheckedUpdateWithoutOrganizationPetInput;
};

export type CareAddonEnrollmentUpdateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
};

export type CareAddonEnrollmentUpdateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsNestedInput>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentNestedInput>;
};

export type CareAddonEnrollmentUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsNestedInput>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
};

export type CareAddonEnrollmentUpdateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsNestedInput>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
};

export type CareAddonEnrollmentUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsNestedInput>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
};

export type CareAddonEnrollmentUpdateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
};

export type CareAddonEnrollmentUpsertWithWhereUniqueWithoutAddOnPackageInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  update: CareAddonEnrollmentUncheckedUpdateWithoutAddOnPackageInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutAddOnPackageInput;
};

export type CareAddonEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  update: CareAddonEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type CareAddonEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  update: CareAddonEnrollmentUncheckedUpdateWithoutOrganizationPetInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutOrganizationPetInput;
};

export type CareAddonEnrollmentUpsertWithoutAssociatedUsagesInput = {
  update: CareAddonEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput;
};

export type CareAddonEnrollmentUpsertWithoutEnrollmentPaymentsInput = {
  update: CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput;
};

export type CareAddonEnrollmentUpsertWithoutEnrollmentToStripeInvoiceItemsInput = {
  update: CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareAddonEnrollmentWhereInput = {
  AND?: Maybe<Array<CareAddonEnrollmentWhereInput>>;
  OR?: Maybe<Array<CareAddonEnrollmentWhereInput>>;
  NOT?: Maybe<Array<CareAddonEnrollmentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  startDate?: Maybe<DateTimeFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumEnrollmentStatusFilter>;
  organizationPetId?: Maybe<StringFilter>;
  addOnPackageId?: Maybe<StringFilter>;
  careStripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
  organizationPet?: Maybe<OrganizationPetWhereInput>;
  addOnPackage?: Maybe<CareAddonPackageWhereInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemListRelationFilter>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentListRelationFilter>;
  associatedUsages?: Maybe<CareBenefitUsageListRelationFilter>;
};

export type CareAddonEnrollmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareAddonPackage = {
  __typename?: 'CareAddonPackage';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal: Scalars['Int'];
  billingCycle: CarePlanCycleType;
  renewalCycle: CarePlanCycleType;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes: Scalars['String'];
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth: Scalars['Int'];
  addonPackageBenefit: Array<CareAddonPackageBenefit>;
  enrollments: Array<CareAddonEnrollment>;
  organization?: Maybe<Organization>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  _count: CareAddonPackageCountOutputType;
};


export type CareAddonPackageAddonPackageBenefitArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
  orderBy?: Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAddonPackageBenefitScalarFieldEnum>;
};


export type CareAddonPackageEnrollmentsArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
  orderBy?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAddonEnrollmentScalarFieldEnum>;
};

export type CareAddonPackageAvgAggregateOutputType = {
  __typename?: 'CareAddonPackageAvgAggregateOutputType';
  pricePerRenewal?: Maybe<Scalars['Float']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Float']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Float']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Float']>;
};

export type CareAddonPackageAvgOrderByAggregateInput = {
  pricePerRenewal?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefit = {
  __typename?: 'CareAddonPackageBenefit';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue: Scalars['Int'];
  reportingExpectedUsages: Scalars['Int'];
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
  benefit: CareBenefit;
  addOnPackage: CareAddonPackage;
};

export type CareAddonPackageBenefitAddOnPackageIdDisplayOrderCompoundUniqueInput = {
  addOnPackageId: Scalars['String'];
  displayOrder: Scalars['Int'];
};

export type CareAddonPackageBenefitAvgAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitAvgAggregateOutputType';
  includedUses?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Float']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  displayOrder?: Maybe<Scalars['Float']>;
};

export type CareAddonPackageBenefitAvgOrderByAggregateInput = {
  includedUses?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitCountAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  includedUses: Scalars['Int'];
  reportingExpectedValue: Scalars['Int'];
  reportingExpectedUsages: Scalars['Int'];
  displayOrder: Scalars['Int'];
  benefitId: Scalars['Int'];
  addOnPackageId: Scalars['Int'];
  projectedSavings: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareAddonPackageBenefitCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  benefit: CareBenefitCreateNestedOneWithoutAddonPackageBenefitsInput;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutAddonPackageBenefitInput;
};

export type CareAddonPackageBenefitCreateManyAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope = {
  data: Array<CareAddonPackageBenefitCreateManyAddOnPackageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonPackageBenefitCreateManyBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  addOnPackageId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitCreateManyBenefitInputEnvelope = {
  data: Array<CareAddonPackageBenefitCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonPackageBenefitCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitCreateNestedManyWithoutAddOnPackageInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
};

export type CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
};

export type CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput = {
  where: CareAddonPackageBenefitWhereUniqueInput;
  create: CareAddonPackageBenefitUncheckedCreateWithoutAddOnPackageInput;
};

export type CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput = {
  where: CareAddonPackageBenefitWhereUniqueInput;
  create: CareAddonPackageBenefitUncheckedCreateWithoutBenefitInput;
};

export type CareAddonPackageBenefitCreateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  benefit: CareBenefitCreateNestedOneWithoutAddonPackageBenefitsInput;
};

export type CareAddonPackageBenefitCreateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutAddonPackageBenefitInput;
};

export type CareAddonPackageBenefitListRelationFilter = {
  every?: Maybe<CareAddonPackageBenefitWhereInput>;
  some?: Maybe<CareAddonPackageBenefitWhereInput>;
  none?: Maybe<CareAddonPackageBenefitWhereInput>;
};

export type CareAddonPackageBenefitMaxAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitMinAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAddonPackageBenefitOrderByRelevanceFieldEnum {
  Id = 'id',
  BenefitId = 'benefitId',
  AddOnPackageId = 'addOnPackageId',
  ProjectedSavings = 'projectedSavings'
}

export type CareAddonPackageBenefitOrderByRelevanceInput = {
  fields: Array<CareAddonPackageBenefitOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareAddonPackageBenefitOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  _count?: Maybe<CareAddonPackageBenefitCountOrderByAggregateInput>;
  _avg?: Maybe<CareAddonPackageBenefitAvgOrderByAggregateInput>;
  _max?: Maybe<CareAddonPackageBenefitMaxOrderByAggregateInput>;
  _min?: Maybe<CareAddonPackageBenefitMinOrderByAggregateInput>;
  _sum?: Maybe<CareAddonPackageBenefitSumOrderByAggregateInput>;
};

export type CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  addOnPackage?: Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CareAddonPackageBenefitOrderByRelevanceInput>;
};

export enum CareAddonPackageBenefitScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IncludedUses = 'includedUses',
  ReportingExpectedValue = 'reportingExpectedValue',
  ReportingExpectedUsages = 'reportingExpectedUsages',
  DisplayOrder = 'displayOrder',
  BenefitId = 'benefitId',
  AddOnPackageId = 'addOnPackageId',
  ProjectedSavings = 'projectedSavings'
}

export type CareAddonPackageBenefitScalarWhereInput = {
  AND?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
  OR?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
  NOT?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  includedUses?: Maybe<IntNullableFilter>;
  reportingExpectedValue?: Maybe<IntFilter>;
  reportingExpectedUsages?: Maybe<IntFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  benefitId?: Maybe<StringFilter>;
  addOnPackageId?: Maybe<StringFilter>;
  projectedSavings?: Maybe<StringNullableFilter>;
};

export type CareAddonPackageBenefitScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAddonPackageBenefitScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAddonPackageBenefitScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAddonPackageBenefitScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  includedUses?: Maybe<IntNullableWithAggregatesFilter>;
  reportingExpectedValue?: Maybe<IntWithAggregatesFilter>;
  reportingExpectedUsages?: Maybe<IntWithAggregatesFilter>;
  displayOrder?: Maybe<IntNullableWithAggregatesFilter>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  addOnPackageId?: Maybe<StringWithAggregatesFilter>;
  projectedSavings?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareAddonPackageBenefitSumAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitSumAggregateOutputType';
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageBenefitSumOrderByAggregateInput = {
  includedUses?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedCreateNestedManyWithoutAddOnPackageInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
};

export type CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
};

export type CareAddonPackageBenefitUncheckedCreateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedCreateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  addOnPackageId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyWithoutAddOnPackageNestedInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput>>;
  upsert?: Maybe<Array<CareAddonPackageBenefitUpsertWithWhereUniqueWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope>;
  set?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageBenefitUpdateWithWhereUniqueWithoutAddOnPackageInput>>;
  updateMany?: Maybe<Array<CareAddonPackageBenefitUpdateManyWithWhereWithoutAddOnPackageInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyWithoutAddonPackageBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyWithoutAddonPackageBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<CareAddonPackageBenefitUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageBenefitUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CareAddonPackageBenefitUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
};

export type CareAddonPackageBenefitUncheckedUpdateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedUpdateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutAddonPackageBenefitsNestedInput>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutAddonPackageBenefitNestedInput>;
};

export type CareAddonPackageBenefitUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUpdateManyWithWhereWithoutAddOnPackageInput = {
  where: CareAddonPackageBenefitScalarWhereInput;
  data: CareAddonPackageBenefitUncheckedUpdateManyWithoutAddonPackageBenefitInput;
};

export type CareAddonPackageBenefitUpdateManyWithWhereWithoutBenefitInput = {
  where: CareAddonPackageBenefitScalarWhereInput;
  data: CareAddonPackageBenefitUncheckedUpdateManyWithoutAddonPackageBenefitsInput;
};

export type CareAddonPackageBenefitUpdateManyWithoutAddOnPackageNestedInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput>>;
  upsert?: Maybe<Array<CareAddonPackageBenefitUpsertWithWhereUniqueWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope>;
  set?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageBenefitUpdateWithWhereUniqueWithoutAddOnPackageInput>>;
  updateMany?: Maybe<Array<CareAddonPackageBenefitUpdateManyWithWhereWithoutAddOnPackageInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
};

export type CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<CareAddonPackageBenefitUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageBenefitUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CareAddonPackageBenefitUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
};

export type CareAddonPackageBenefitUpdateWithWhereUniqueWithoutAddOnPackageInput = {
  where: CareAddonPackageBenefitWhereUniqueInput;
  data: CareAddonPackageBenefitUncheckedUpdateWithoutAddOnPackageInput;
};

export type CareAddonPackageBenefitUpdateWithWhereUniqueWithoutBenefitInput = {
  where: CareAddonPackageBenefitWhereUniqueInput;
  data: CareAddonPackageBenefitUncheckedUpdateWithoutBenefitInput;
};

export type CareAddonPackageBenefitUpdateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutAddonPackageBenefitsNestedInput>;
};

export type CareAddonPackageBenefitUpdateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutAddonPackageBenefitNestedInput>;
};

export type CareAddonPackageBenefitUpsertWithWhereUniqueWithoutAddOnPackageInput = {
  where: CareAddonPackageBenefitWhereUniqueInput;
  update: CareAddonPackageBenefitUncheckedUpdateWithoutAddOnPackageInput;
  create: CareAddonPackageBenefitUncheckedCreateWithoutAddOnPackageInput;
};

export type CareAddonPackageBenefitUpsertWithWhereUniqueWithoutBenefitInput = {
  where: CareAddonPackageBenefitWhereUniqueInput;
  update: CareAddonPackageBenefitUncheckedUpdateWithoutBenefitInput;
  create: CareAddonPackageBenefitUncheckedCreateWithoutBenefitInput;
};

export type CareAddonPackageBenefitWhereInput = {
  AND?: Maybe<Array<CareAddonPackageBenefitWhereInput>>;
  OR?: Maybe<Array<CareAddonPackageBenefitWhereInput>>;
  NOT?: Maybe<Array<CareAddonPackageBenefitWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  includedUses?: Maybe<IntNullableFilter>;
  reportingExpectedValue?: Maybe<IntFilter>;
  reportingExpectedUsages?: Maybe<IntFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  benefitId?: Maybe<StringFilter>;
  addOnPackageId?: Maybe<StringFilter>;
  projectedSavings?: Maybe<StringNullableFilter>;
  benefit?: Maybe<CareBenefitWhereInput>;
  addOnPackage?: Maybe<CareAddonPackageWhereInput>;
};

export type CareAddonPackageBenefitWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  addOnPackageId_displayOrder?: Maybe<CareAddonPackageBenefitAddOnPackageIdDisplayOrderCompoundUniqueInput>;
};

export type CareAddonPackageCountAggregateOutputType = {
  __typename?: 'CareAddonPackageCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  title: Scalars['Int'];
  description: Scalars['Int'];
  published: Scalars['Int'];
  themeColor: Scalars['Int'];
  organizationId: Scalars['Int'];
  pricePerRenewal: Scalars['Int'];
  billingCycle: Scalars['Int'];
  renewalCycle: Scalars['Int'];
  addonCancelsAt: Scalars['Int'];
  stripePriceId: Scalars['Int'];
  targetSpecies: Scalars['Int'];
  earliestTargetPetAgeInMonths: Scalars['Int'];
  latestTargetPetAgeInMonths: Scalars['Int'];
  editorNotes: Scalars['Int'];
  associatedCarePlanProviderGroupId: Scalars['Int'];
  salesTaxCentsPerMonth: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareAddonPackageCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  addonCancelsAt?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
};

export type CareAddonPackageCountOutputType = {
  __typename?: 'CareAddonPackageCountOutputType';
  addonPackageBenefit: Scalars['Int'];
  enrollments: Scalars['Int'];
};

export type CareAddonPackageCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutAddOnPackageInput>;
  enrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutAddOnPackageInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCareAddonPackageInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedAddonPackagesInput>;
};

export type CareAddonPackageCreateManyAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageCreateManyAssociatedCarePlanProviderGroupInputEnvelope = {
  data: Array<CareAddonPackageCreateManyAssociatedCarePlanProviderGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonPackageCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageCreateManyOrganizationInputEnvelope = {
  data: Array<CareAddonPackageCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  create?: Maybe<Array<CareAddonPackageCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
};

export type CareAddonPackageCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CareAddonPackageCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
};

export type CareAddonPackageCreateNestedOneWithoutAddonPackageBenefitInput = {
  create?: Maybe<CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput>;
  connectOrCreate?: Maybe<CareAddonPackageCreateOrConnectWithoutAddonPackageBenefitInput>;
  connect?: Maybe<CareAddonPackageWhereUniqueInput>;
};

export type CareAddonPackageCreateNestedOneWithoutEnrollmentsInput = {
  create?: Maybe<CareAddonPackageUncheckedCreateWithoutEnrollmentsInput>;
  connectOrCreate?: Maybe<CareAddonPackageCreateOrConnectWithoutEnrollmentsInput>;
  connect?: Maybe<CareAddonPackageWhereUniqueInput>;
};

export type CareAddonPackageCreateOrConnectWithoutAddonPackageBenefitInput = {
  where: CareAddonPackageWhereUniqueInput;
  create: CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput;
};

export type CareAddonPackageCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput = {
  where: CareAddonPackageWhereUniqueInput;
  create: CareAddonPackageUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
};

export type CareAddonPackageCreateOrConnectWithoutEnrollmentsInput = {
  where: CareAddonPackageWhereUniqueInput;
  create: CareAddonPackageUncheckedCreateWithoutEnrollmentsInput;
};

export type CareAddonPackageCreateOrConnectWithoutOrganizationInput = {
  where: CareAddonPackageWhereUniqueInput;
  create: CareAddonPackageUncheckedCreateWithoutOrganizationInput;
};

export type CareAddonPackageCreateWithoutAddonPackageBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutAddOnPackageInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCareAddonPackageInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedAddonPackagesInput>;
};

export type CareAddonPackageCreateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutAddOnPackageInput>;
  enrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutAddOnPackageInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCareAddonPackageInput>;
};

export type CareAddonPackageCreateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutAddOnPackageInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCareAddonPackageInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedAddonPackagesInput>;
};

export type CareAddonPackageCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutAddOnPackageInput>;
  enrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutAddOnPackageInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedAddonPackagesInput>;
};

export type CareAddonPackageListRelationFilter = {
  every?: Maybe<CareAddonPackageWhereInput>;
  some?: Maybe<CareAddonPackageWhereInput>;
  none?: Maybe<CareAddonPackageWhereInput>;
};

export type CareAddonPackageMaxAggregateOutputType = {
  __typename?: 'CareAddonPackageMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  addonCancelsAt?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
};

export type CareAddonPackageMinAggregateOutputType = {
  __typename?: 'CareAddonPackageMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  addonCancelsAt?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
};

export type CareAddonPackageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAddonPackageOrderByRelevanceFieldEnum {
  Id = 'id',
  Title = 'title',
  Description = 'description',
  ThemeColor = 'themeColor',
  OrganizationId = 'organizationId',
  StripePriceId = 'stripePriceId',
  EditorNotes = 'editorNotes',
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId'
}

export type CareAddonPackageOrderByRelevanceInput = {
  fields: Array<CareAddonPackageOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareAddonPackageOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  addonCancelsAt?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  _count?: Maybe<CareAddonPackageCountOrderByAggregateInput>;
  _avg?: Maybe<CareAddonPackageAvgOrderByAggregateInput>;
  _max?: Maybe<CareAddonPackageMaxOrderByAggregateInput>;
  _min?: Maybe<CareAddonPackageMinOrderByAggregateInput>;
  _sum?: Maybe<CareAddonPackageSumOrderByAggregateInput>;
};

export type CareAddonPackageOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  addonCancelsAt?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitOrderByRelationAggregateInput>;
  enrollments?: Maybe<CareAddonEnrollmentOrderByRelationAggregateInput>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CareAddonPackageOrderByRelevanceInput>;
};

export type CareAddonPackageRelationFilter = {
  is?: Maybe<CareAddonPackageWhereInput>;
  isNot?: Maybe<CareAddonPackageWhereInput>;
};

export enum CareAddonPackageScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Title = 'title',
  Description = 'description',
  Published = 'published',
  ThemeColor = 'themeColor',
  OrganizationId = 'organizationId',
  PricePerRenewal = 'pricePerRenewal',
  BillingCycle = 'billingCycle',
  RenewalCycle = 'renewalCycle',
  AddonCancelsAt = 'addonCancelsAt',
  StripePriceId = 'stripePriceId',
  TargetSpecies = 'targetSpecies',
  EarliestTargetPetAgeInMonths = 'earliestTargetPetAgeInMonths',
  LatestTargetPetAgeInMonths = 'latestTargetPetAgeInMonths',
  EditorNotes = 'editorNotes',
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  SalesTaxCentsPerMonth = 'salesTaxCentsPerMonth'
}

export type CareAddonPackageScalarWhereInput = {
  AND?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
  OR?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
  NOT?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  published?: Maybe<BoolFilter>;
  themeColor?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  pricePerRenewal?: Maybe<IntFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  addonCancelsAt?: Maybe<DateTimeNullableFilter>;
  stripePriceId?: Maybe<StringNullableFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  salesTaxCentsPerMonth?: Maybe<IntFilter>;
};

export type CareAddonPackageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAddonPackageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAddonPackageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAddonPackageScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  published?: Maybe<BoolWithAggregatesFilter>;
  themeColor?: Maybe<StringNullableWithAggregatesFilter>;
  organizationId?: Maybe<StringNullableWithAggregatesFilter>;
  pricePerRenewal?: Maybe<IntWithAggregatesFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeWithAggregatesFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeWithAggregatesFilter>;
  addonCancelsAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  stripePriceId?: Maybe<StringNullableWithAggregatesFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableWithAggregatesFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  editorNotes?: Maybe<StringWithAggregatesFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  salesTaxCentsPerMonth?: Maybe<IntWithAggregatesFilter>;
};

export type CareAddonPackageSumAggregateOutputType = {
  __typename?: 'CareAddonPackageSumAggregateOutputType';
  pricePerRenewal?: Maybe<Scalars['Int']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageSumOrderByAggregateInput = {
  pricePerRenewal?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
};

export type CareAddonPackageUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutAddOnPackageInput>;
};

export type CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  create?: Maybe<Array<CareAddonPackageCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
};

export type CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CareAddonPackageCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
};

export type CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutAddOnPackageInput>;
};

export type CareAddonPackageUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutAddOnPackageInput>;
};

export type CareAddonPackageUncheckedCreateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutAddOnPackageInput>;
};

export type CareAddonPackageUncheckedCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutAddOnPackageInput>;
};

export type CareAddonPackageUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
};

export type CareAddonPackageUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageUncheckedUpdateManyWithoutAssociatedAddonPackagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  create?: Maybe<Array<CareAddonPackageCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CareAddonPackageUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  set?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CareAddonPackageUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
};

export type CareAddonPackageUncheckedUpdateManyWithoutCareAddonPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<CareAddonPackageCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CareAddonPackageUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CareAddonPackageUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
};

export type CareAddonPackageUncheckedUpdateWithoutAddonPackageBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
};

export type CareAddonPackageUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
};

export type CareAddonPackageUncheckedUpdateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
};

export type CareAddonPackageUncheckedUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
};

export type CareAddonPackageUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUpdateManyWithoutAddOnPackageNestedInput>;
  enrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutAddOnPackageNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutCareAddonPackageNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedAddonPackagesNestedInput>;
};

export type CareAddonPackageUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput = {
  where: CareAddonPackageScalarWhereInput;
  data: CareAddonPackageUncheckedUpdateManyWithoutAssociatedAddonPackagesInput;
};

export type CareAddonPackageUpdateManyWithWhereWithoutOrganizationInput = {
  where: CareAddonPackageScalarWhereInput;
  data: CareAddonPackageUncheckedUpdateManyWithoutCareAddonPackageInput;
};

export type CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  create?: Maybe<Array<CareAddonPackageCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CareAddonPackageUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  set?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CareAddonPackageUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
};

export type CareAddonPackageUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<CareAddonPackageCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CareAddonPackageUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CareAddonPackageUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
};

export type CareAddonPackageUpdateOneRequiredWithoutAddonPackageBenefitNestedInput = {
  create?: Maybe<CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput>;
  connectOrCreate?: Maybe<CareAddonPackageCreateOrConnectWithoutAddonPackageBenefitInput>;
  upsert?: Maybe<CareAddonPackageUpsertWithoutAddonPackageBenefitInput>;
  connect?: Maybe<CareAddonPackageWhereUniqueInput>;
  update?: Maybe<CareAddonPackageUncheckedUpdateWithoutAddonPackageBenefitInput>;
};

export type CareAddonPackageUpdateOneRequiredWithoutEnrollmentsNestedInput = {
  create?: Maybe<CareAddonPackageUncheckedCreateWithoutEnrollmentsInput>;
  connectOrCreate?: Maybe<CareAddonPackageCreateOrConnectWithoutEnrollmentsInput>;
  upsert?: Maybe<CareAddonPackageUpsertWithoutEnrollmentsInput>;
  connect?: Maybe<CareAddonPackageWhereUniqueInput>;
  update?: Maybe<CareAddonPackageUncheckedUpdateWithoutEnrollmentsInput>;
};

export type CareAddonPackageUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  where: CareAddonPackageWhereUniqueInput;
  data: CareAddonPackageUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
};

export type CareAddonPackageUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: CareAddonPackageWhereUniqueInput;
  data: CareAddonPackageUncheckedUpdateWithoutOrganizationInput;
};

export type CareAddonPackageUpdateWithoutAddonPackageBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutAddOnPackageNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutCareAddonPackageNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedAddonPackagesNestedInput>;
};

export type CareAddonPackageUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUpdateManyWithoutAddOnPackageNestedInput>;
  enrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutAddOnPackageNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutCareAddonPackageNestedInput>;
};

export type CareAddonPackageUpdateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUpdateManyWithoutAddOnPackageNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutCareAddonPackageNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedAddonPackagesNestedInput>;
};

export type CareAddonPackageUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUpdateManyWithoutAddOnPackageNestedInput>;
  enrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutAddOnPackageNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedAddonPackagesNestedInput>;
};

export type CareAddonPackageUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  where: CareAddonPackageWhereUniqueInput;
  update: CareAddonPackageUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
  create: CareAddonPackageUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
};

export type CareAddonPackageUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: CareAddonPackageWhereUniqueInput;
  update: CareAddonPackageUncheckedUpdateWithoutOrganizationInput;
  create: CareAddonPackageUncheckedCreateWithoutOrganizationInput;
};

export type CareAddonPackageUpsertWithoutAddonPackageBenefitInput = {
  update: CareAddonPackageUncheckedUpdateWithoutAddonPackageBenefitInput;
  create: CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput;
};

export type CareAddonPackageUpsertWithoutEnrollmentsInput = {
  update: CareAddonPackageUncheckedUpdateWithoutEnrollmentsInput;
  create: CareAddonPackageUncheckedCreateWithoutEnrollmentsInput;
};

export type CareAddonPackageWhereInput = {
  AND?: Maybe<Array<CareAddonPackageWhereInput>>;
  OR?: Maybe<Array<CareAddonPackageWhereInput>>;
  NOT?: Maybe<Array<CareAddonPackageWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  published?: Maybe<BoolFilter>;
  themeColor?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  pricePerRenewal?: Maybe<IntFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  addonCancelsAt?: Maybe<DateTimeNullableFilter>;
  stripePriceId?: Maybe<StringNullableFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  salesTaxCentsPerMonth?: Maybe<IntFilter>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitListRelationFilter>;
  enrollments?: Maybe<CareAddonEnrollmentListRelationFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupWhereInput>;
};

export type CareAddonPackageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareAuthorizationRequest = {
  __typename?: 'CareAuthorizationRequest';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
  clinicPetParent: ClinicPetParent;
};

export type CareAuthorizationRequestCountAggregateOutputType = {
  __typename?: 'CareAuthorizationRequestCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  expiresAt: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  email: Scalars['Int'];
  authCode: Scalars['Int'];
  claimed: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareAuthorizationRequestCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
};

export type CareAuthorizationRequestCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAuthorizationRequestsInput;
};

export type CareAuthorizationRequestCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
};

export type CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope = {
  data: Array<CareAuthorizationRequestCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
};

export type CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<CareAuthorizationRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
};

export type CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput = {
  where: CareAuthorizationRequestWhereUniqueInput;
  create: CareAuthorizationRequestUncheckedCreateWithoutClinicPetParentInput;
};

export type CareAuthorizationRequestCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
};

export type CareAuthorizationRequestListRelationFilter = {
  every?: Maybe<CareAuthorizationRequestWhereInput>;
  some?: Maybe<CareAuthorizationRequestWhereInput>;
  none?: Maybe<CareAuthorizationRequestWhereInput>;
};

export type CareAuthorizationRequestMaxAggregateOutputType = {
  __typename?: 'CareAuthorizationRequestMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
};

export type CareAuthorizationRequestMinAggregateOutputType = {
  __typename?: 'CareAuthorizationRequestMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
};

export type CareAuthorizationRequestOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAuthorizationRequestOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicPetParentId = 'clinicPetParentId',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  AuthCode = 'authCode'
}

export type CareAuthorizationRequestOrderByRelevanceInput = {
  fields: Array<CareAuthorizationRequestOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareAuthorizationRequestOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
  _count?: Maybe<CareAuthorizationRequestCountOrderByAggregateInput>;
  _max?: Maybe<CareAuthorizationRequestMaxOrderByAggregateInput>;
  _min?: Maybe<CareAuthorizationRequestMinOrderByAggregateInput>;
};

export type CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CareAuthorizationRequestOrderByRelevanceInput>;
};

export enum CareAuthorizationRequestScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ExpiresAt = 'expiresAt',
  ClinicPetParentId = 'clinicPetParentId',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  AuthCode = 'authCode',
  Claimed = 'claimed'
}

export type CareAuthorizationRequestScalarWhereInput = {
  AND?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
  OR?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
  NOT?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  expiresAt?: Maybe<DateTimeFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  authCode?: Maybe<StringFilter>;
  claimed?: Maybe<BoolFilter>;
};

export type CareAuthorizationRequestScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAuthorizationRequestScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAuthorizationRequestScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAuthorizationRequestScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  expiresAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  phoneNumber?: Maybe<StringWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  authCode?: Maybe<StringWithAggregatesFilter>;
  claimed?: Maybe<BoolWithAggregatesFilter>;
};

export type CareAuthorizationRequestUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
};

export type CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<CareAuthorizationRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
};

export type CareAuthorizationRequestUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
};

export type CareAuthorizationRequestUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestUncheckedUpdateManyWithoutCareAuthorizationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<CareAuthorizationRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CareAuthorizationRequestUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  delete?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  connect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  update?: Maybe<Array<CareAuthorizationRequestUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CareAuthorizationRequestUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
};

export type CareAuthorizationRequestUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAuthorizationRequestsNestedInput>;
};

export type CareAuthorizationRequestUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: CareAuthorizationRequestScalarWhereInput;
  data: CareAuthorizationRequestUncheckedUpdateManyWithoutCareAuthorizationRequestsInput;
};

export type CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<CareAuthorizationRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CareAuthorizationRequestUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  delete?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  connect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  update?: Maybe<Array<CareAuthorizationRequestUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CareAuthorizationRequestUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
};

export type CareAuthorizationRequestUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: CareAuthorizationRequestWhereUniqueInput;
  data: CareAuthorizationRequestUncheckedUpdateWithoutClinicPetParentInput;
};

export type CareAuthorizationRequestUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: CareAuthorizationRequestWhereUniqueInput;
  update: CareAuthorizationRequestUncheckedUpdateWithoutClinicPetParentInput;
  create: CareAuthorizationRequestUncheckedCreateWithoutClinicPetParentInput;
};

export type CareAuthorizationRequestWhereInput = {
  AND?: Maybe<Array<CareAuthorizationRequestWhereInput>>;
  OR?: Maybe<Array<CareAuthorizationRequestWhereInput>>;
  NOT?: Maybe<Array<CareAuthorizationRequestWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  expiresAt?: Maybe<DateTimeFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  authCode?: Maybe<StringFilter>;
  claimed?: Maybe<BoolFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
};

export type CareAuthorizationRequestWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareBenefit = {
  __typename?: 'CareBenefit';
  id: Scalars['String'];
  title: Scalars['String'];
  type: CareBenefitType;
  description: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  tangible: Scalars['Boolean'];
  /** @deprecated */
  reportingExpectedValue: Scalars['Int'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes: Scalars['String'];
  exhaustible: Scalars['Boolean'];
  providesTeletriage: Scalars['Boolean'];
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization: Organization;
  planBenefits: Array<CarePlanBenefit>;
  addonPackageBenefits: Array<CareAddonPackageBenefit>;
  usages: Array<CareBenefitUsage>;
  organizationCareBenefitToClinicService: Array<OrganizationCareBenefitToClinicService>;
  connectedServiceCategories: Array<OrganizationCareBenefitToClinicServiceCategory>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  enrollmentCarryovers: Array<CarePlanBenefitEnrollmentCarryover>;
  _count: CareBenefitCountOutputType;
};


export type CareBenefitPlanBenefitsArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
  orderBy?: Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanBenefitScalarFieldEnum>;
};


export type CareBenefitAddonPackageBenefitsArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
  orderBy?: Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAddonPackageBenefitScalarFieldEnum>;
};


export type CareBenefitUsagesArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
};


export type CareBenefitOrganizationCareBenefitToClinicServiceArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  orderBy?: Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>;
};


export type CareBenefitConnectedServiceCategoriesArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
  orderBy?: Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationCareBenefitToClinicServiceCategoryScalarFieldEnum>;
};


export type CareBenefitEnrollmentCarryoversArgs = {
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
  orderBy?: Maybe<CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanBenefitEnrollmentCarryoverScalarFieldEnum>;
};

export type CareBenefitAvgAggregateOutputType = {
  __typename?: 'CareBenefitAvgAggregateOutputType';
  reportingExpectedValue?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Float']>;
};

export type CareBenefitAvgOrderByAggregateInput = {
  reportingExpectedValue?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
};

export type CareBenefitCountAggregateOutputType = {
  __typename?: 'CareBenefitCountAggregateOutputType';
  id: Scalars['Int'];
  title: Scalars['Int'];
  type: Scalars['Int'];
  description: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  tangible: Scalars['Int'];
  reportingExpectedValue: Scalars['Int'];
  providedDiscountPercentage: Scalars['Int'];
  organizationId: Scalars['Int'];
  usageUnitOfMeasure: Scalars['Int'];
  editorNotes: Scalars['Int'];
  exhaustible: Scalars['Int'];
  providesTeletriage: Scalars['Int'];
  associatedCarePlanProviderGroupId: Scalars['Int'];
  flowAlias: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareBenefitCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  exhaustible?: Maybe<SortOrder>;
  providesTeletriage?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
};

export type CareBenefitCountOutputType = {
  __typename?: 'CareBenefitCountOutputType';
  planBenefits: Scalars['Int'];
  addonPackageBenefits: Scalars['Int'];
  usages: Scalars['Int'];
  organizationCareBenefitToClinicService: Scalars['Int'];
  connectedServiceCategories: Scalars['Int'];
  enrollmentCarryovers: Scalars['Int'];
};

export type CareBenefitCreateInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateManyAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
};

export type CareBenefitCreateManyAssociatedCarePlanProviderGroupInputEnvelope = {
  data: Array<CareBenefitCreateManyAssociatedCarePlanProviderGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
};

export type CareBenefitCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
};

export type CareBenefitCreateManyOrganizationInputEnvelope = {
  data: Array<CareBenefitCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  create?: Maybe<Array<CareBenefitCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareBenefitCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
};

export type CareBenefitCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CareBenefitCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CareBenefitCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
};

export type CareBenefitCreateNestedOneWithoutAddonPackageBenefitsInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutAddonPackageBenefitsInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
};

export type CareBenefitCreateNestedOneWithoutConnectedServiceCategoriesInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutConnectedServiceCategoriesInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutConnectedServiceCategoriesInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
};

export type CareBenefitCreateNestedOneWithoutEnrollmentCarryoversInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutEnrollmentCarryoversInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutEnrollmentCarryoversInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
};

export type CareBenefitCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
};

export type CareBenefitCreateNestedOneWithoutPlanBenefitsInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutPlanBenefitsInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutPlanBenefitsInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
};

export type CareBenefitCreateNestedOneWithoutUsagesInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutUsagesInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutUsagesInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
};

export type CareBenefitCreateOrConnectWithoutAddonPackageBenefitsInput = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput;
};

export type CareBenefitCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
};

export type CareBenefitCreateOrConnectWithoutConnectedServiceCategoriesInput = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitUncheckedCreateWithoutConnectedServiceCategoriesInput;
};

export type CareBenefitCreateOrConnectWithoutEnrollmentCarryoversInput = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitUncheckedCreateWithoutEnrollmentCarryoversInput;
};

export type CareBenefitCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type CareBenefitCreateOrConnectWithoutOrganizationInput = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitUncheckedCreateWithoutOrganizationInput;
};

export type CareBenefitCreateOrConnectWithoutPlanBenefitsInput = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitUncheckedCreateWithoutPlanBenefitsInput;
};

export type CareBenefitCreateOrConnectWithoutUsagesInput = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitUncheckedCreateWithoutUsagesInput;
};

export type CareBenefitCreateWithoutAddonPackageBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateWithoutConnectedServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateWithoutEnrollmentCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
};

export type CareBenefitCreateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateWithoutUsagesInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitListRelationFilter = {
  every?: Maybe<CareBenefitWhereInput>;
  some?: Maybe<CareBenefitWhereInput>;
  none?: Maybe<CareBenefitWhereInput>;
};

export type CareBenefitMaxAggregateOutputType = {
  __typename?: 'CareBenefitMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
};

export type CareBenefitMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  exhaustible?: Maybe<SortOrder>;
  providesTeletriage?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
};

export type CareBenefitMinAggregateOutputType = {
  __typename?: 'CareBenefitMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
};

export type CareBenefitMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  exhaustible?: Maybe<SortOrder>;
  providesTeletriage?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
};

export type CareBenefitOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareBenefitOrderByRelevanceFieldEnum {
  Id = 'id',
  Title = 'title',
  Description = 'description',
  OrganizationId = 'organizationId',
  EditorNotes = 'editorNotes',
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  FlowAlias = 'flowAlias'
}

export type CareBenefitOrderByRelevanceInput = {
  fields: Array<CareBenefitOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareBenefitOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  exhaustible?: Maybe<SortOrder>;
  providesTeletriage?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  _count?: Maybe<CareBenefitCountOrderByAggregateInput>;
  _avg?: Maybe<CareBenefitAvgOrderByAggregateInput>;
  _max?: Maybe<CareBenefitMaxOrderByAggregateInput>;
  _min?: Maybe<CareBenefitMinOrderByAggregateInput>;
  _sum?: Maybe<CareBenefitSumOrderByAggregateInput>;
};

export type CareBenefitOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  exhaustible?: Maybe<SortOrder>;
  providesTeletriage?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  planBenefits?: Maybe<CarePlanBenefitOrderByRelationAggregateInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitOrderByRelationAggregateInput>;
  usages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceOrderByRelationAggregateInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByRelationAggregateInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverOrderByRelationAggregateInput>;
  _relevance?: Maybe<CareBenefitOrderByRelevanceInput>;
};

export type CareBenefitRelationFilter = {
  is?: Maybe<CareBenefitWhereInput>;
  isNot?: Maybe<CareBenefitWhereInput>;
};

export enum CareBenefitScalarFieldEnum {
  Id = 'id',
  Title = 'title',
  Type = 'type',
  Description = 'description',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Tangible = 'tangible',
  ReportingExpectedValue = 'reportingExpectedValue',
  ProvidedDiscountPercentage = 'providedDiscountPercentage',
  OrganizationId = 'organizationId',
  UsageUnitOfMeasure = 'usageUnitOfMeasure',
  EditorNotes = 'editorNotes',
  Exhaustible = 'exhaustible',
  ProvidesTeletriage = 'providesTeletriage',
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  FlowAlias = 'flowAlias'
}

export type CareBenefitScalarWhereInput = {
  AND?: Maybe<Array<CareBenefitScalarWhereInput>>;
  OR?: Maybe<Array<CareBenefitScalarWhereInput>>;
  NOT?: Maybe<Array<CareBenefitScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  type?: Maybe<EnumCareBenefitTypeFilter>;
  description?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  tangible?: Maybe<BoolFilter>;
  reportingExpectedValue?: Maybe<IntFilter>;
  providedDiscountPercentage?: Maybe<IntNullableFilter>;
  organizationId?: Maybe<StringFilter>;
  usageUnitOfMeasure?: Maybe<EnumCareBenefitUnitOfMeasureNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
  exhaustible?: Maybe<BoolFilter>;
  providesTeletriage?: Maybe<BoolFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  flowAlias?: Maybe<StringNullableFilter>;
};

export type CareBenefitScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareBenefitScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareBenefitScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareBenefitScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumCareBenefitTypeWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  tangible?: Maybe<BoolWithAggregatesFilter>;
  reportingExpectedValue?: Maybe<IntWithAggregatesFilter>;
  providedDiscountPercentage?: Maybe<IntNullableWithAggregatesFilter>;
  organizationId?: Maybe<StringWithAggregatesFilter>;
  usageUnitOfMeasure?: Maybe<EnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter>;
  editorNotes?: Maybe<StringWithAggregatesFilter>;
  exhaustible?: Maybe<BoolWithAggregatesFilter>;
  providesTeletriage?: Maybe<BoolWithAggregatesFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  flowAlias?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareBenefitSumAggregateOutputType = {
  __typename?: 'CareBenefitSumAggregateOutputType';
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
};

export type CareBenefitSumOrderByAggregateInput = {
  reportingExpectedValue?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
};

export enum CareBenefitType {
  Unspecified = 'Unspecified',
  Teletriage = 'Teletriage',
  Discount = 'Discount',
  AccountCredit = 'AccountCredit',
  ProvidedService = 'ProvidedService',
  ExternalAccess = 'ExternalAccess'
}

export type CareBenefitUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  create?: Maybe<Array<CareBenefitCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareBenefitCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
};

export type CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CareBenefitCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CareBenefitCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
};

export type CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateWithoutConnectedServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateWithoutEnrollmentCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateWithoutUsagesInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
};

export type CareBenefitUncheckedUpdateManyWithoutAssociatedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
};

export type CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  create?: Maybe<Array<CareBenefitCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CareBenefitUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareBenefitCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  set?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CareBenefitUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  deleteMany?: Maybe<Array<CareBenefitScalarWhereInput>>;
};

export type CareBenefitUncheckedUpdateManyWithoutCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
};

export type CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<CareBenefitCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CareBenefitUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CareBenefitCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CareBenefitUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CareBenefitScalarWhereInput>>;
};

export type CareBenefitUncheckedUpdateWithoutAddonPackageBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateWithoutConnectedServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateWithoutEnrollmentCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateWithoutUsagesInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export enum CareBenefitUnitOfMeasure {
  Occurence = 'OCCURENCE',
  Cents = 'CENTS'
}

export type CareBenefitUpdateInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
};

export type CareBenefitUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput = {
  where: CareBenefitScalarWhereInput;
  data: CareBenefitUncheckedUpdateManyWithoutAssociatedCareBenefitsInput;
};

export type CareBenefitUpdateManyWithWhereWithoutOrganizationInput = {
  where: CareBenefitScalarWhereInput;
  data: CareBenefitUncheckedUpdateManyWithoutCareBenefitsInput;
};

export type CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  create?: Maybe<Array<CareBenefitCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CareBenefitUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareBenefitCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  set?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CareBenefitUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  deleteMany?: Maybe<Array<CareBenefitScalarWhereInput>>;
};

export type CareBenefitUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<CareBenefitCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CareBenefitUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CareBenefitCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CareBenefitUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CareBenefitScalarWhereInput>>;
};

export type CareBenefitUpdateOneRequiredWithoutAddonPackageBenefitsNestedInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutAddonPackageBenefitsInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutAddonPackageBenefitsInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutAddonPackageBenefitsInput>;
};

export type CareBenefitUpdateOneRequiredWithoutConnectedServiceCategoriesNestedInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutConnectedServiceCategoriesInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutConnectedServiceCategoriesInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutConnectedServiceCategoriesInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutConnectedServiceCategoriesInput>;
};

export type CareBenefitUpdateOneRequiredWithoutEnrollmentCarryoversNestedInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutEnrollmentCarryoversInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutEnrollmentCarryoversInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutEnrollmentCarryoversInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutEnrollmentCarryoversInput>;
};

export type CareBenefitUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceNestedInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutOrganizationCareBenefitToClinicServiceInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput>;
};

export type CareBenefitUpdateOneRequiredWithoutPlanBenefitsNestedInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutPlanBenefitsInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutPlanBenefitsInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutPlanBenefitsInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutPlanBenefitsInput>;
};

export type CareBenefitUpdateOneRequiredWithoutUsagesNestedInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutUsagesInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutUsagesInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutUsagesInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutUsagesInput>;
};

export type CareBenefitUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  where: CareBenefitWhereUniqueInput;
  data: CareBenefitUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
};

export type CareBenefitUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: CareBenefitWhereUniqueInput;
  data: CareBenefitUncheckedUpdateWithoutOrganizationInput;
};

export type CareBenefitUpdateWithoutAddonPackageBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateWithoutConnectedServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateWithoutEnrollmentCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
};

export type CareBenefitUpdateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateWithoutUsagesInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  where: CareBenefitWhereUniqueInput;
  update: CareBenefitUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
  create: CareBenefitUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
};

export type CareBenefitUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: CareBenefitWhereUniqueInput;
  update: CareBenefitUncheckedUpdateWithoutOrganizationInput;
  create: CareBenefitUncheckedCreateWithoutOrganizationInput;
};

export type CareBenefitUpsertWithoutAddonPackageBenefitsInput = {
  update: CareBenefitUncheckedUpdateWithoutAddonPackageBenefitsInput;
  create: CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput;
};

export type CareBenefitUpsertWithoutConnectedServiceCategoriesInput = {
  update: CareBenefitUncheckedUpdateWithoutConnectedServiceCategoriesInput;
  create: CareBenefitUncheckedCreateWithoutConnectedServiceCategoriesInput;
};

export type CareBenefitUpsertWithoutEnrollmentCarryoversInput = {
  update: CareBenefitUncheckedUpdateWithoutEnrollmentCarryoversInput;
  create: CareBenefitUncheckedCreateWithoutEnrollmentCarryoversInput;
};

export type CareBenefitUpsertWithoutOrganizationCareBenefitToClinicServiceInput = {
  update: CareBenefitUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput;
  create: CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type CareBenefitUpsertWithoutPlanBenefitsInput = {
  update: CareBenefitUncheckedUpdateWithoutPlanBenefitsInput;
  create: CareBenefitUncheckedCreateWithoutPlanBenefitsInput;
};

export type CareBenefitUpsertWithoutUsagesInput = {
  update: CareBenefitUncheckedUpdateWithoutUsagesInput;
  create: CareBenefitUncheckedCreateWithoutUsagesInput;
};

export type CareBenefitUsage = {
  __typename?: 'CareBenefitUsage';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  usageClinic: Clinic;
  usageClinicPet: ClinicPet;
  recordedByUser?: Maybe<User>;
  benefit: CareBenefit;
  invoiceLineItemConnections: Array<CareBenefitUsageToInvoiceLineItem>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollment>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollment>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStep>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntent>;
  _count: CareBenefitUsageCountOutputType;
};


export type CareBenefitUsageInvoiceLineItemConnectionsArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
};

export type CareBenefitUsageAvgAggregateOutputType = {
  __typename?: 'CareBenefitUsageAvgAggregateOutputType';
  quantityUsed?: Maybe<Scalars['Float']>;
};

export type CareBenefitUsageAvgOrderByAggregateInput = {
  quantityUsed?: Maybe<SortOrder>;
};

export type CareBenefitUsageCountAggregateOutputType = {
  __typename?: 'CareBenefitUsageCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  usedAt: Scalars['Int'];
  quantityUsed: Scalars['Int'];
  reason: Scalars['Int'];
  usageClinicId: Scalars['Int'];
  usageClinicPetId: Scalars['Int'];
  recordedByUserId: Scalars['Int'];
  benefitId: Scalars['Int'];
  exhaustsBenefit: Scalars['Int'];
  connectedPlanEnrollmentId: Scalars['Int'];
  connectedAddonEnrollmentId: Scalars['Int'];
  relatedFollowupStepId: Scalars['Int'];
  careAccountCreditIntentId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareBenefitUsageCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinicPetId?: Maybe<SortOrder>;
  recordedByUserId?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  exhaustsBenefit?: Maybe<SortOrder>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
  relatedFollowupStepId?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
};

export type CareBenefitUsageCountOutputType = {
  __typename?: 'CareBenefitUsageCountOutputType';
  invoiceLineItemConnections: Scalars['Int'];
};

export type CareBenefitUsageCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
};

export type CareBenefitUsageCreateManyBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyBenefitInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyCareAccountCreditIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyCareAccountCreditIntentInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyCareAccountCreditIntentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyConnectedAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyConnectedAddonEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyConnectedPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyConnectedPlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyRecordedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyRecordedByUserInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyRecordedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyRelatedFollowupStepInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyRelatedFollowupStepInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyRelatedFollowupStepInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyUsageClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyUsageClinicInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyUsageClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyUsageClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyUsageClinicPetInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyUsageClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageCreateNestedManyWithoutCareAccountCreditIntentInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutCareAccountCreditIntentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyCareAccountCreditIntentInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRecordedByUserInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRecordedByUserInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageCreateNestedManyWithoutRelatedFollowupStepInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRelatedFollowupStepInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRelatedFollowupStepInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRelatedFollowupStepInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageCreateNestedManyWithoutUsageClinicInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicPetInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicPetInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput = {
  create?: Maybe<CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput>;
  connectOrCreate?: Maybe<CareBenefitUsageCreateOrConnectWithoutInvoiceLineItemConnectionsInput>;
  connect?: Maybe<CareBenefitUsageWhereUniqueInput>;
};

export type CareBenefitUsageCreateOrConnectWithoutBenefitInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutBenefitInput;
};

export type CareBenefitUsageCreateOrConnectWithoutCareAccountCreditIntentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutCareAccountCreditIntentInput;
};

export type CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutConnectedAddonEnrollmentInput;
};

export type CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutConnectedPlanEnrollmentInput;
};

export type CareBenefitUsageCreateOrConnectWithoutInvoiceLineItemConnectionsInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput;
};

export type CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutRecordedByUserInput;
};

export type CareBenefitUsageCreateOrConnectWithoutRelatedFollowupStepInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutRelatedFollowupStepInput;
};

export type CareBenefitUsageCreateOrConnectWithoutUsageClinicInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutUsageClinicInput;
};

export type CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutUsageClinicPetInput;
};

export type CareBenefitUsageCreateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
};

export type CareBenefitUsageCreateWithoutCareAccountCreditIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
};

export type CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
};

export type CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
};

export type CareBenefitUsageCreateWithoutInvoiceLineItemConnectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
};

export type CareBenefitUsageCreateWithoutRecordedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
};

export type CareBenefitUsageCreateWithoutRelatedFollowupStepInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
};

export type CareBenefitUsageCreateWithoutUsageClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
};

export type CareBenefitUsageCreateWithoutUsageClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
};

export type CareBenefitUsageFollowup = {
  __typename?: 'CareBenefitUsageFollowup';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  completed: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  relatedOrganizationPetId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  reportingOriginalInvoiceValueCents: Scalars['Int'];
  relatedOrganizationPet: OrganizationPet;
  careStripeSubscription: CareStripeSubscription;
  followupSteps: Array<CareBenefitUsageFollowupStep>;
  _count: CareBenefitUsageFollowupCountOutputType;
};


export type CareBenefitUsageFollowupFollowupStepsArgs = {
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  orderBy?: Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageFollowupStepScalarFieldEnum>;
};

export type CareBenefitUsageFollowupAvgAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupAvgAggregateOutputType';
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Float']>;
};

export type CareBenefitUsageFollowupAvgOrderByAggregateInput = {
  reportingOriginalInvoiceValueCents?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupCountAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  completed: Scalars['Int'];
  completedAt: Scalars['Int'];
  relatedOrganizationPetId: Scalars['Int'];
  careStripeSubscriptionId: Scalars['Int'];
  reportingOriginalInvoiceValueCents: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareBenefitUsageFollowupCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  relatedOrganizationPetId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  reportingOriginalInvoiceValueCents?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupCountOutputType = {
  __typename?: 'CareBenefitUsageFollowupCountOutputType';
  followupSteps: Scalars['Int'];
};

export type CareBenefitUsageFollowupCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  relatedOrganizationPet: OrganizationPetCreateNestedOneWithoutAssociatedBenefitUsageFollowupInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareBenefitUsageFollowupsInput;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutUsageFollowupInput>;
};

export type CareBenefitUsageFollowupCreateManyCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  relatedOrganizationPetId: Scalars['String'];
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupCreateManyCareStripeSubscriptionInputEnvelope = {
  data: Array<CareBenefitUsageFollowupCreateManyCareStripeSubscriptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageFollowupCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  relatedOrganizationPetId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupCreateManyRelatedOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId: Scalars['String'];
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupCreateManyRelatedOrganizationPetInputEnvelope = {
  data: Array<CareBenefitUsageFollowupCreateManyRelatedOrganizationPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
};

export type CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutRelatedOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutRelatedOrganizationPetInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyRelatedOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
};

export type CareBenefitUsageFollowupCreateNestedOneWithoutFollowupStepsInput = {
  create?: Maybe<CareBenefitUsageFollowupUncheckedCreateWithoutFollowupStepsInput>;
  connectOrCreate?: Maybe<CareBenefitUsageFollowupCreateOrConnectWithoutFollowupStepsInput>;
  connect?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
};

export type CareBenefitUsageFollowupCreateOrConnectWithoutCareStripeSubscriptionInput = {
  where: CareBenefitUsageFollowupWhereUniqueInput;
  create: CareBenefitUsageFollowupUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type CareBenefitUsageFollowupCreateOrConnectWithoutFollowupStepsInput = {
  where: CareBenefitUsageFollowupWhereUniqueInput;
  create: CareBenefitUsageFollowupUncheckedCreateWithoutFollowupStepsInput;
};

export type CareBenefitUsageFollowupCreateOrConnectWithoutRelatedOrganizationPetInput = {
  where: CareBenefitUsageFollowupWhereUniqueInput;
  create: CareBenefitUsageFollowupUncheckedCreateWithoutRelatedOrganizationPetInput;
};

export type CareBenefitUsageFollowupCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  relatedOrganizationPet: OrganizationPetCreateNestedOneWithoutAssociatedBenefitUsageFollowupInput;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutUsageFollowupInput>;
};

export type CareBenefitUsageFollowupCreateWithoutFollowupStepsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  relatedOrganizationPet: OrganizationPetCreateNestedOneWithoutAssociatedBenefitUsageFollowupInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareBenefitUsageFollowupsInput;
};

export type CareBenefitUsageFollowupCreateWithoutRelatedOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareBenefitUsageFollowupsInput;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutUsageFollowupInput>;
};

export type CareBenefitUsageFollowupListRelationFilter = {
  every?: Maybe<CareBenefitUsageFollowupWhereInput>;
  some?: Maybe<CareBenefitUsageFollowupWhereInput>;
  none?: Maybe<CareBenefitUsageFollowupWhereInput>;
};

export type CareBenefitUsageFollowupMaxAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  relatedOrganizationPetId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  relatedOrganizationPetId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  reportingOriginalInvoiceValueCents?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupMinAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  relatedOrganizationPetId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  relatedOrganizationPetId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  reportingOriginalInvoiceValueCents?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareBenefitUsageFollowupOrderByRelevanceFieldEnum {
  Id = 'id',
  RelatedOrganizationPetId = 'relatedOrganizationPetId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId'
}

export type CareBenefitUsageFollowupOrderByRelevanceInput = {
  fields: Array<CareBenefitUsageFollowupOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareBenefitUsageFollowupOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  relatedOrganizationPetId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  reportingOriginalInvoiceValueCents?: Maybe<SortOrder>;
  _count?: Maybe<CareBenefitUsageFollowupCountOrderByAggregateInput>;
  _avg?: Maybe<CareBenefitUsageFollowupAvgOrderByAggregateInput>;
  _max?: Maybe<CareBenefitUsageFollowupMaxOrderByAggregateInput>;
  _min?: Maybe<CareBenefitUsageFollowupMinOrderByAggregateInput>;
  _sum?: Maybe<CareBenefitUsageFollowupSumOrderByAggregateInput>;
};

export type CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  relatedOrganizationPetId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  reportingOriginalInvoiceValueCents?: Maybe<SortOrder>;
  relatedOrganizationPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepOrderByRelationAggregateInput>;
  _relevance?: Maybe<CareBenefitUsageFollowupOrderByRelevanceInput>;
};

export type CareBenefitUsageFollowupRelationFilter = {
  is?: Maybe<CareBenefitUsageFollowupWhereInput>;
  isNot?: Maybe<CareBenefitUsageFollowupWhereInput>;
};

export enum CareBenefitUsageFollowupScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Completed = 'completed',
  CompletedAt = 'completedAt',
  RelatedOrganizationPetId = 'relatedOrganizationPetId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  ReportingOriginalInvoiceValueCents = 'reportingOriginalInvoiceValueCents'
}

export type CareBenefitUsageFollowupScalarWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageFollowupScalarWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageFollowupScalarWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageFollowupScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  completed?: Maybe<BoolFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  relatedOrganizationPetId?: Maybe<StringFilter>;
  careStripeSubscriptionId?: Maybe<StringFilter>;
  reportingOriginalInvoiceValueCents?: Maybe<IntFilter>;
};

export type CareBenefitUsageFollowupScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareBenefitUsageFollowupScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareBenefitUsageFollowupScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareBenefitUsageFollowupScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  completed?: Maybe<BoolWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  relatedOrganizationPetId?: Maybe<StringWithAggregatesFilter>;
  careStripeSubscriptionId?: Maybe<StringWithAggregatesFilter>;
  reportingOriginalInvoiceValueCents?: Maybe<IntWithAggregatesFilter>;
};

export type CareBenefitUsageFollowupStep = {
  __typename?: 'CareBenefitUsageFollowupStep';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  completed: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  /** Display order starts at 1, and 0 is used for the default value but marks it as not being set */
  displayOrder: Scalars['Int'];
  usageFollowupId: Scalars['String'];
  completedByUserId?: Maybe<Scalars['String']>;
  usageFollowup: CareBenefitUsageFollowup;
  completedByUser?: Maybe<User>;
  relatedUsages: Array<CareBenefitUsage>;
  careAccountCreditIntents: Array<CareAccountCreditIntent>;
  _count: CareBenefitUsageFollowupStepCountOutputType;
};


export type CareBenefitUsageFollowupStepRelatedUsagesArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
};


export type CareBenefitUsageFollowupStepCareAccountCreditIntentsArgs = {
  where?: Maybe<CareAccountCreditIntentWhereInput>;
  orderBy?: Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAccountCreditIntentScalarFieldEnum>;
};

export type CareBenefitUsageFollowupStepAvgAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupStepAvgAggregateOutputType';
  displayOrder?: Maybe<Scalars['Float']>;
};

export type CareBenefitUsageFollowupStepAvgOrderByAggregateInput = {
  displayOrder?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupStepCountAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupStepCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  completed: Scalars['Int'];
  completedAt: Scalars['Int'];
  description: Scalars['Int'];
  displayOrder: Scalars['Int'];
  usageFollowupId: Scalars['Int'];
  completedByUserId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareBenefitUsageFollowupStepCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  usageFollowupId?: Maybe<SortOrder>;
  completedByUserId?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupStepCountOutputType = {
  __typename?: 'CareBenefitUsageFollowupStepCountOutputType';
  relatedUsages: Scalars['Int'];
  careAccountCreditIntents: Scalars['Int'];
};

export type CareBenefitUsageFollowupStepCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowup: CareBenefitUsageFollowupCreateNestedOneWithoutFollowupStepsInput;
  completedByUser?: Maybe<UserCreateNestedOneWithoutCompletedBenefitUsageFollowupStepsInput>;
  relatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutRelatedFollowupStepInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutFollowupStepInput>;
};

export type CareBenefitUsageFollowupStepCreateManyCompletedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowupId: Scalars['String'];
};

export type CareBenefitUsageFollowupStepCreateManyCompletedByUserInputEnvelope = {
  data: Array<CareBenefitUsageFollowupStepCreateManyCompletedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageFollowupStepCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowupId: Scalars['String'];
  completedByUserId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepCreateManyUsageFollowupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  completedByUserId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepCreateManyUsageFollowupInputEnvelope = {
  data: Array<CareBenefitUsageFollowupStepCreateManyUsageFollowupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutCompletedByUserInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutCompletedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyCompletedByUserInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
};

export type CareBenefitUsageFollowupStepCreateNestedManyWithoutUsageFollowupInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutUsageFollowupInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutUsageFollowupInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyUsageFollowupInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
};

export type CareBenefitUsageFollowupStepCreateNestedOneWithoutCareAccountCreditIntentsInput = {
  create?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateWithoutCareAccountCreditIntentsInput>;
  connectOrCreate?: Maybe<CareBenefitUsageFollowupStepCreateOrConnectWithoutCareAccountCreditIntentsInput>;
  connect?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
};

export type CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput = {
  create?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateWithoutRelatedUsagesInput>;
  connectOrCreate?: Maybe<CareBenefitUsageFollowupStepCreateOrConnectWithoutRelatedUsagesInput>;
  connect?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
};

export type CareBenefitUsageFollowupStepCreateOrConnectWithoutCareAccountCreditIntentsInput = {
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutCareAccountCreditIntentsInput;
};

export type CareBenefitUsageFollowupStepCreateOrConnectWithoutCompletedByUserInput = {
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutCompletedByUserInput;
};

export type CareBenefitUsageFollowupStepCreateOrConnectWithoutRelatedUsagesInput = {
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutRelatedUsagesInput;
};

export type CareBenefitUsageFollowupStepCreateOrConnectWithoutUsageFollowupInput = {
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutUsageFollowupInput;
};

export type CareBenefitUsageFollowupStepCreateWithoutCareAccountCreditIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowup: CareBenefitUsageFollowupCreateNestedOneWithoutFollowupStepsInput;
  completedByUser?: Maybe<UserCreateNestedOneWithoutCompletedBenefitUsageFollowupStepsInput>;
  relatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutRelatedFollowupStepInput>;
};

export type CareBenefitUsageFollowupStepCreateWithoutCompletedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowup: CareBenefitUsageFollowupCreateNestedOneWithoutFollowupStepsInput;
  relatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutRelatedFollowupStepInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutFollowupStepInput>;
};

export type CareBenefitUsageFollowupStepCreateWithoutRelatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowup: CareBenefitUsageFollowupCreateNestedOneWithoutFollowupStepsInput;
  completedByUser?: Maybe<UserCreateNestedOneWithoutCompletedBenefitUsageFollowupStepsInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutFollowupStepInput>;
};

export type CareBenefitUsageFollowupStepCreateWithoutUsageFollowupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  completedByUser?: Maybe<UserCreateNestedOneWithoutCompletedBenefitUsageFollowupStepsInput>;
  relatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutRelatedFollowupStepInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutFollowupStepInput>;
};

export type CareBenefitUsageFollowupStepListRelationFilter = {
  every?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  some?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  none?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
};

export type CareBenefitUsageFollowupStepMaxAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupStepMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowupId?: Maybe<Scalars['String']>;
  completedByUserId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  usageFollowupId?: Maybe<SortOrder>;
  completedByUserId?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupStepMinAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupStepMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowupId?: Maybe<Scalars['String']>;
  completedByUserId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  usageFollowupId?: Maybe<SortOrder>;
  completedByUserId?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupStepOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareBenefitUsageFollowupStepOrderByRelevanceFieldEnum {
  Id = 'id',
  Description = 'description',
  UsageFollowupId = 'usageFollowupId',
  CompletedByUserId = 'completedByUserId'
}

export type CareBenefitUsageFollowupStepOrderByRelevanceInput = {
  fields: Array<CareBenefitUsageFollowupStepOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareBenefitUsageFollowupStepOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  usageFollowupId?: Maybe<SortOrder>;
  completedByUserId?: Maybe<SortOrder>;
  _count?: Maybe<CareBenefitUsageFollowupStepCountOrderByAggregateInput>;
  _avg?: Maybe<CareBenefitUsageFollowupStepAvgOrderByAggregateInput>;
  _max?: Maybe<CareBenefitUsageFollowupStepMaxOrderByAggregateInput>;
  _min?: Maybe<CareBenefitUsageFollowupStepMinOrderByAggregateInput>;
  _sum?: Maybe<CareBenefitUsageFollowupStepSumOrderByAggregateInput>;
};

export type CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  usageFollowupId?: Maybe<SortOrder>;
  completedByUserId?: Maybe<SortOrder>;
  usageFollowup?: Maybe<CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput>;
  completedByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  relatedUsages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentOrderByRelationAggregateInput>;
  _relevance?: Maybe<CareBenefitUsageFollowupStepOrderByRelevanceInput>;
};

export type CareBenefitUsageFollowupStepRelationFilter = {
  is?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  isNot?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
};

export enum CareBenefitUsageFollowupStepScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Completed = 'completed',
  CompletedAt = 'completedAt',
  Description = 'description',
  DisplayOrder = 'displayOrder',
  UsageFollowupId = 'usageFollowupId',
  CompletedByUserId = 'completedByUserId'
}

export type CareBenefitUsageFollowupStepScalarWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  completed?: Maybe<BoolFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  description?: Maybe<StringFilter>;
  displayOrder?: Maybe<IntFilter>;
  usageFollowupId?: Maybe<StringFilter>;
  completedByUserId?: Maybe<StringNullableFilter>;
};

export type CareBenefitUsageFollowupStepScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  completed?: Maybe<BoolWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  displayOrder?: Maybe<IntWithAggregatesFilter>;
  usageFollowupId?: Maybe<StringWithAggregatesFilter>;
  completedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareBenefitUsageFollowupStepSumAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupStepSumAggregateOutputType';
  displayOrder?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupStepSumOrderByAggregateInput = {
  displayOrder?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupStepUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowupId: Scalars['String'];
  completedByUserId?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRelatedFollowupStepInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutFollowupStepInput>;
};

export type CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutCompletedByUserInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutCompletedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyCompletedByUserInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
};

export type CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutUsageFollowupInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutUsageFollowupInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutUsageFollowupInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyUsageFollowupInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
};

export type CareBenefitUsageFollowupStepUncheckedCreateWithoutCareAccountCreditIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowupId: Scalars['String'];
  completedByUserId?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRelatedFollowupStepInput>;
};

export type CareBenefitUsageFollowupStepUncheckedCreateWithoutCompletedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowupId: Scalars['String'];
  relatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRelatedFollowupStepInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutFollowupStepInput>;
};

export type CareBenefitUsageFollowupStepUncheckedCreateWithoutRelatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowupId: Scalars['String'];
  completedByUserId?: Maybe<Scalars['String']>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutFollowupStepInput>;
};

export type CareBenefitUsageFollowupStepUncheckedCreateWithoutUsageFollowupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  completedByUserId?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRelatedFollowupStepInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutFollowupStepInput>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowupId?: Maybe<Scalars['String']>;
  completedByUserId?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRelatedFollowupStepNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutFollowupStepNestedInput>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowupId?: Maybe<Scalars['String']>;
  completedByUserId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedBenefitUsageFollowupStepsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowupId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutCompletedByUserInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutCompletedByUserInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupStepUpsertWithWhereUniqueWithoutCompletedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyCompletedByUserInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupStepUpdateWithWhereUniqueWithoutCompletedByUserInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupStepUpdateManyWithWhereWithoutCompletedByUserInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereInput>>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutFollowupStepsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  completedByUserId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutUsageFollowupNestedInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutUsageFollowupInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutUsageFollowupInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupStepUpsertWithWhereUniqueWithoutUsageFollowupInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyUsageFollowupInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupStepUpdateWithWhereUniqueWithoutUsageFollowupInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupStepUpdateManyWithWhereWithoutUsageFollowupInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereInput>>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateWithoutCareAccountCreditIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowupId?: Maybe<Scalars['String']>;
  completedByUserId?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRelatedFollowupStepNestedInput>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateWithoutCompletedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowupId?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRelatedFollowupStepNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutFollowupStepNestedInput>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateWithoutRelatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowupId?: Maybe<Scalars['String']>;
  completedByUserId?: Maybe<Scalars['String']>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutFollowupStepNestedInput>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateWithoutUsageFollowupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  completedByUserId?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRelatedFollowupStepNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutFollowupStepNestedInput>;
};

export type CareBenefitUsageFollowupStepUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowup?: Maybe<CareBenefitUsageFollowupUpdateOneRequiredWithoutFollowupStepsNestedInput>;
  completedByUser?: Maybe<UserUpdateOneWithoutCompletedBenefitUsageFollowupStepsNestedInput>;
  relatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutRelatedFollowupStepNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutFollowupStepNestedInput>;
};

export type CareBenefitUsageFollowupStepUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupStepUpdateManyWithWhereWithoutCompletedByUserInput = {
  where: CareBenefitUsageFollowupStepScalarWhereInput;
  data: CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedBenefitUsageFollowupStepsInput;
};

export type CareBenefitUsageFollowupStepUpdateManyWithWhereWithoutUsageFollowupInput = {
  where: CareBenefitUsageFollowupStepScalarWhereInput;
  data: CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutFollowupStepsInput;
};

export type CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutCompletedByUserInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutCompletedByUserInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupStepUpsertWithWhereUniqueWithoutCompletedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyCompletedByUserInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupStepUpdateWithWhereUniqueWithoutCompletedByUserInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupStepUpdateManyWithWhereWithoutCompletedByUserInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereInput>>;
};

export type CareBenefitUsageFollowupStepUpdateManyWithoutUsageFollowupNestedInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutUsageFollowupInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutUsageFollowupInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupStepUpsertWithWhereUniqueWithoutUsageFollowupInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyUsageFollowupInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupStepUpdateWithWhereUniqueWithoutUsageFollowupInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupStepUpdateManyWithWhereWithoutUsageFollowupInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereInput>>;
};

export type CareBenefitUsageFollowupStepUpdateOneWithoutCareAccountCreditIntentsNestedInput = {
  create?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateWithoutCareAccountCreditIntentsInput>;
  connectOrCreate?: Maybe<CareBenefitUsageFollowupStepCreateOrConnectWithoutCareAccountCreditIntentsInput>;
  upsert?: Maybe<CareBenefitUsageFollowupStepUpsertWithoutCareAccountCreditIntentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  update?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateWithoutCareAccountCreditIntentsInput>;
};

export type CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput = {
  create?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateWithoutRelatedUsagesInput>;
  connectOrCreate?: Maybe<CareBenefitUsageFollowupStepCreateOrConnectWithoutRelatedUsagesInput>;
  upsert?: Maybe<CareBenefitUsageFollowupStepUpsertWithoutRelatedUsagesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  update?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateWithoutRelatedUsagesInput>;
};

export type CareBenefitUsageFollowupStepUpdateWithWhereUniqueWithoutCompletedByUserInput = {
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
  data: CareBenefitUsageFollowupStepUncheckedUpdateWithoutCompletedByUserInput;
};

export type CareBenefitUsageFollowupStepUpdateWithWhereUniqueWithoutUsageFollowupInput = {
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
  data: CareBenefitUsageFollowupStepUncheckedUpdateWithoutUsageFollowupInput;
};

export type CareBenefitUsageFollowupStepUpdateWithoutCareAccountCreditIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowup?: Maybe<CareBenefitUsageFollowupUpdateOneRequiredWithoutFollowupStepsNestedInput>;
  completedByUser?: Maybe<UserUpdateOneWithoutCompletedBenefitUsageFollowupStepsNestedInput>;
  relatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutRelatedFollowupStepNestedInput>;
};

export type CareBenefitUsageFollowupStepUpdateWithoutCompletedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowup?: Maybe<CareBenefitUsageFollowupUpdateOneRequiredWithoutFollowupStepsNestedInput>;
  relatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutRelatedFollowupStepNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutFollowupStepNestedInput>;
};

export type CareBenefitUsageFollowupStepUpdateWithoutRelatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  usageFollowup?: Maybe<CareBenefitUsageFollowupUpdateOneRequiredWithoutFollowupStepsNestedInput>;
  completedByUser?: Maybe<UserUpdateOneWithoutCompletedBenefitUsageFollowupStepsNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutFollowupStepNestedInput>;
};

export type CareBenefitUsageFollowupStepUpdateWithoutUsageFollowupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  completedByUser?: Maybe<UserUpdateOneWithoutCompletedBenefitUsageFollowupStepsNestedInput>;
  relatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutRelatedFollowupStepNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutFollowupStepNestedInput>;
};

export type CareBenefitUsageFollowupStepUpsertWithWhereUniqueWithoutCompletedByUserInput = {
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
  update: CareBenefitUsageFollowupStepUncheckedUpdateWithoutCompletedByUserInput;
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutCompletedByUserInput;
};

export type CareBenefitUsageFollowupStepUpsertWithWhereUniqueWithoutUsageFollowupInput = {
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
  update: CareBenefitUsageFollowupStepUncheckedUpdateWithoutUsageFollowupInput;
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutUsageFollowupInput;
};

export type CareBenefitUsageFollowupStepUpsertWithoutCareAccountCreditIntentsInput = {
  update: CareBenefitUsageFollowupStepUncheckedUpdateWithoutCareAccountCreditIntentsInput;
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutCareAccountCreditIntentsInput;
};

export type CareBenefitUsageFollowupStepUpsertWithoutRelatedUsagesInput = {
  update: CareBenefitUsageFollowupStepUncheckedUpdateWithoutRelatedUsagesInput;
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutRelatedUsagesInput;
};

export type CareBenefitUsageFollowupStepWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageFollowupStepWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageFollowupStepWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageFollowupStepWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  completed?: Maybe<BoolFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  description?: Maybe<StringFilter>;
  displayOrder?: Maybe<IntFilter>;
  usageFollowupId?: Maybe<StringFilter>;
  completedByUserId?: Maybe<StringNullableFilter>;
  usageFollowup?: Maybe<CareBenefitUsageFollowupWhereInput>;
  completedByUser?: Maybe<UserWhereInput>;
  relatedUsages?: Maybe<CareBenefitUsageListRelationFilter>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentListRelationFilter>;
};

export type CareBenefitUsageFollowupStepWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupSumAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupSumAggregateOutputType';
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupSumOrderByAggregateInput = {
  reportingOriginalInvoiceValueCents?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  relatedOrganizationPetId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutUsageFollowupInput>;
};

export type CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
};

export type CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutRelatedOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutRelatedOrganizationPetInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyRelatedOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
};

export type CareBenefitUsageFollowupUncheckedCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  relatedOrganizationPetId: Scalars['String'];
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutUsageFollowupInput>;
};

export type CareBenefitUsageFollowupUncheckedCreateWithoutFollowupStepsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  relatedOrganizationPetId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupUncheckedCreateWithoutRelatedOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId: Scalars['String'];
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutUsageFollowupInput>;
};

export type CareBenefitUsageFollowupUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  relatedOrganizationPetId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutUsageFollowupNestedInput>;
};

export type CareBenefitUsageFollowupUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  relatedOrganizationPetId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupUncheckedUpdateManyWithoutAssociatedBenefitUsageFollowupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareBenefitUsageFollowupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  relatedOrganizationPetId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupScalarWhereInput>>;
};

export type CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutRelatedOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutRelatedOrganizationPetInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupUpsertWithWhereUniqueWithoutRelatedOrganizationPetInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyRelatedOrganizationPetInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupUpdateWithWhereUniqueWithoutRelatedOrganizationPetInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupUpdateManyWithWhereWithoutRelatedOrganizationPetInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupScalarWhereInput>>;
};

export type CareBenefitUsageFollowupUncheckedUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  relatedOrganizationPetId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutUsageFollowupNestedInput>;
};

export type CareBenefitUsageFollowupUncheckedUpdateWithoutFollowupStepsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  relatedOrganizationPetId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupUncheckedUpdateWithoutRelatedOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutUsageFollowupNestedInput>;
};

export type CareBenefitUsageFollowupUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  relatedOrganizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutAssociatedBenefitUsageFollowupNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareBenefitUsageFollowupsNestedInput>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutUsageFollowupNestedInput>;
};

export type CareBenefitUsageFollowupUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupUpdateManyWithWhereWithoutCareStripeSubscriptionInput = {
  where: CareBenefitUsageFollowupScalarWhereInput;
  data: CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareBenefitUsageFollowupsInput;
};

export type CareBenefitUsageFollowupUpdateManyWithWhereWithoutRelatedOrganizationPetInput = {
  where: CareBenefitUsageFollowupScalarWhereInput;
  data: CareBenefitUsageFollowupUncheckedUpdateManyWithoutAssociatedBenefitUsageFollowupInput;
};

export type CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupScalarWhereInput>>;
};

export type CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput = {
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutRelatedOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutRelatedOrganizationPetInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupUpsertWithWhereUniqueWithoutRelatedOrganizationPetInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyRelatedOrganizationPetInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupUpdateWithWhereUniqueWithoutRelatedOrganizationPetInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupUpdateManyWithWhereWithoutRelatedOrganizationPetInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupScalarWhereInput>>;
};

export type CareBenefitUsageFollowupUpdateOneRequiredWithoutFollowupStepsNestedInput = {
  create?: Maybe<CareBenefitUsageFollowupUncheckedCreateWithoutFollowupStepsInput>;
  connectOrCreate?: Maybe<CareBenefitUsageFollowupCreateOrConnectWithoutFollowupStepsInput>;
  upsert?: Maybe<CareBenefitUsageFollowupUpsertWithoutFollowupStepsInput>;
  connect?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  update?: Maybe<CareBenefitUsageFollowupUncheckedUpdateWithoutFollowupStepsInput>;
};

export type CareBenefitUsageFollowupUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: CareBenefitUsageFollowupWhereUniqueInput;
  data: CareBenefitUsageFollowupUncheckedUpdateWithoutCareStripeSubscriptionInput;
};

export type CareBenefitUsageFollowupUpdateWithWhereUniqueWithoutRelatedOrganizationPetInput = {
  where: CareBenefitUsageFollowupWhereUniqueInput;
  data: CareBenefitUsageFollowupUncheckedUpdateWithoutRelatedOrganizationPetInput;
};

export type CareBenefitUsageFollowupUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  relatedOrganizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutAssociatedBenefitUsageFollowupNestedInput>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutUsageFollowupNestedInput>;
};

export type CareBenefitUsageFollowupUpdateWithoutFollowupStepsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  relatedOrganizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutAssociatedBenefitUsageFollowupNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareBenefitUsageFollowupsNestedInput>;
};

export type CareBenefitUsageFollowupUpdateWithoutRelatedOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareBenefitUsageFollowupsNestedInput>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutUsageFollowupNestedInput>;
};

export type CareBenefitUsageFollowupUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: CareBenefitUsageFollowupWhereUniqueInput;
  update: CareBenefitUsageFollowupUncheckedUpdateWithoutCareStripeSubscriptionInput;
  create: CareBenefitUsageFollowupUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type CareBenefitUsageFollowupUpsertWithWhereUniqueWithoutRelatedOrganizationPetInput = {
  where: CareBenefitUsageFollowupWhereUniqueInput;
  update: CareBenefitUsageFollowupUncheckedUpdateWithoutRelatedOrganizationPetInput;
  create: CareBenefitUsageFollowupUncheckedCreateWithoutRelatedOrganizationPetInput;
};

export type CareBenefitUsageFollowupUpsertWithoutFollowupStepsInput = {
  update: CareBenefitUsageFollowupUncheckedUpdateWithoutFollowupStepsInput;
  create: CareBenefitUsageFollowupUncheckedCreateWithoutFollowupStepsInput;
};

export type CareBenefitUsageFollowupWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageFollowupWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageFollowupWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageFollowupWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  completed?: Maybe<BoolFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  relatedOrganizationPetId?: Maybe<StringFilter>;
  careStripeSubscriptionId?: Maybe<StringFilter>;
  reportingOriginalInvoiceValueCents?: Maybe<IntFilter>;
  relatedOrganizationPet?: Maybe<OrganizationPetWhereInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepListRelationFilter>;
};

export type CareBenefitUsageFollowupWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageListRelationFilter = {
  every?: Maybe<CareBenefitUsageWhereInput>;
  some?: Maybe<CareBenefitUsageWhereInput>;
  none?: Maybe<CareBenefitUsageWhereInput>;
};

export type CareBenefitUsageMaxAggregateOutputType = {
  __typename?: 'CareBenefitUsageMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinicPetId?: Maybe<SortOrder>;
  recordedByUserId?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  exhaustsBenefit?: Maybe<SortOrder>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
  relatedFollowupStepId?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
};

export type CareBenefitUsageMinAggregateOutputType = {
  __typename?: 'CareBenefitUsageMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinicPetId?: Maybe<SortOrder>;
  recordedByUserId?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  exhaustsBenefit?: Maybe<SortOrder>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
  relatedFollowupStepId?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
};

export type CareBenefitUsageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareBenefitUsageOrderByRelevanceFieldEnum {
  Id = 'id',
  Reason = 'reason',
  UsageClinicId = 'usageClinicId',
  UsageClinicPetId = 'usageClinicPetId',
  RecordedByUserId = 'recordedByUserId',
  BenefitId = 'benefitId',
  ConnectedPlanEnrollmentId = 'connectedPlanEnrollmentId',
  ConnectedAddonEnrollmentId = 'connectedAddonEnrollmentId',
  RelatedFollowupStepId = 'relatedFollowupStepId',
  CareAccountCreditIntentId = 'careAccountCreditIntentId'
}

export type CareBenefitUsageOrderByRelevanceInput = {
  fields: Array<CareBenefitUsageOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareBenefitUsageOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinicPetId?: Maybe<SortOrder>;
  recordedByUserId?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  exhaustsBenefit?: Maybe<SortOrder>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
  relatedFollowupStepId?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  _count?: Maybe<CareBenefitUsageCountOrderByAggregateInput>;
  _avg?: Maybe<CareBenefitUsageAvgOrderByAggregateInput>;
  _max?: Maybe<CareBenefitUsageMaxOrderByAggregateInput>;
  _min?: Maybe<CareBenefitUsageMinOrderByAggregateInput>;
  _sum?: Maybe<CareBenefitUsageSumOrderByAggregateInput>;
};

export type CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinicPetId?: Maybe<SortOrder>;
  recordedByUserId?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  exhaustsBenefit?: Maybe<SortOrder>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
  relatedFollowupStepId?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  usageClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  usageClinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  recordedByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CareBenefitUsageOrderByRelevanceInput>;
};

export type CareBenefitUsageRelationFilter = {
  is?: Maybe<CareBenefitUsageWhereInput>;
  isNot?: Maybe<CareBenefitUsageWhereInput>;
};

export enum CareBenefitUsageScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  UsedAt = 'usedAt',
  QuantityUsed = 'quantityUsed',
  Reason = 'reason',
  UsageClinicId = 'usageClinicId',
  UsageClinicPetId = 'usageClinicPetId',
  RecordedByUserId = 'recordedByUserId',
  BenefitId = 'benefitId',
  ExhaustsBenefit = 'exhaustsBenefit',
  ConnectedPlanEnrollmentId = 'connectedPlanEnrollmentId',
  ConnectedAddonEnrollmentId = 'connectedAddonEnrollmentId',
  RelatedFollowupStepId = 'relatedFollowupStepId',
  CareAccountCreditIntentId = 'careAccountCreditIntentId'
}

export type CareBenefitUsageScalarWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  usedAt?: Maybe<DateTimeFilter>;
  quantityUsed?: Maybe<IntNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  usageClinicId?: Maybe<StringFilter>;
  usageClinicPetId?: Maybe<StringFilter>;
  recordedByUserId?: Maybe<StringNullableFilter>;
  benefitId?: Maybe<StringFilter>;
  exhaustsBenefit?: Maybe<BoolNullableFilter>;
  connectedPlanEnrollmentId?: Maybe<StringNullableFilter>;
  connectedAddonEnrollmentId?: Maybe<StringNullableFilter>;
  relatedFollowupStepId?: Maybe<StringNullableFilter>;
  careAccountCreditIntentId?: Maybe<StringNullableFilter>;
};

export type CareBenefitUsageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareBenefitUsageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareBenefitUsageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareBenefitUsageScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  usedAt?: Maybe<DateTimeWithAggregatesFilter>;
  quantityUsed?: Maybe<IntNullableWithAggregatesFilter>;
  reason?: Maybe<StringNullableWithAggregatesFilter>;
  usageClinicId?: Maybe<StringWithAggregatesFilter>;
  usageClinicPetId?: Maybe<StringWithAggregatesFilter>;
  recordedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  exhaustsBenefit?: Maybe<BoolNullableWithAggregatesFilter>;
  connectedPlanEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  connectedAddonEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  relatedFollowupStepId?: Maybe<StringNullableWithAggregatesFilter>;
  careAccountCreditIntentId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareBenefitUsageSumAggregateOutputType = {
  __typename?: 'CareBenefitUsageSumAggregateOutputType';
  quantityUsed?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageSumOrderByAggregateInput = {
  quantityUsed?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItem = {
  __typename?: 'CareBenefitUsageToInvoiceLineItem';
  id: Scalars['String'];
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  careBenefitUsage: CareBenefitUsage;
  invoiceLineItem?: Maybe<InvoiceLineItem>;
  associatedInvoice?: Maybe<Invoice>;
  confirmedBy?: Maybe<User>;
  draftService?: Maybe<Service>;
};

export type CareBenefitUsageToInvoiceLineItemAvgAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemAvgAggregateOutputType';
  adjustedAmount?: Maybe<Scalars['Float']>;
};

export type CareBenefitUsageToInvoiceLineItemAvgOrderByAggregateInput = {
  adjustedAmount?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemCareBenefitUsageIdInvoiceLineItemIdCompoundUniqueInput = {
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId: Scalars['String'];
};

export type CareBenefitUsageToInvoiceLineItemCountAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemCountAggregateOutputType';
  id: Scalars['Int'];
  careBenefitUsageId: Scalars['Int'];
  invoiceLineItemId: Scalars['Int'];
  associatedInvoiceId: Scalars['Int'];
  adjustedAmount: Scalars['Int'];
  confirmedByUserId: Scalars['Int'];
  draftServiceId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareBenefitUsageToInvoiceLineItemCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  careBenefitUsageId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  associatedInvoiceId?: Maybe<SortOrder>;
  adjustedAmount?: Maybe<SortOrder>;
  confirmedByUserId?: Maybe<SortOrder>;
  draftServiceId?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemCreateInput = {
  id?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInput = {
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutAssociatedInvoiceInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutCareBenefitUsageInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutConfirmedByInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutDraftServiceInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput = {
  id?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput = {
  id?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput = {
  id?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemListRelationFilter = {
  every?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  some?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  none?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};

export type CareBenefitUsageToInvoiceLineItemMaxAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  careBenefitUsageId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  associatedInvoiceId?: Maybe<SortOrder>;
  adjustedAmount?: Maybe<SortOrder>;
  confirmedByUserId?: Maybe<SortOrder>;
  draftServiceId?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemMinAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  careBenefitUsageId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  associatedInvoiceId?: Maybe<SortOrder>;
  adjustedAmount?: Maybe<SortOrder>;
  confirmedByUserId?: Maybe<SortOrder>;
  draftServiceId?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareBenefitUsageToInvoiceLineItemOrderByRelevanceFieldEnum {
  Id = 'id',
  CareBenefitUsageId = 'careBenefitUsageId',
  InvoiceLineItemId = 'invoiceLineItemId',
  AssociatedInvoiceId = 'associatedInvoiceId',
  ConfirmedByUserId = 'confirmedByUserId',
  DraftServiceId = 'draftServiceId'
}

export type CareBenefitUsageToInvoiceLineItemOrderByRelevanceInput = {
  fields: Array<CareBenefitUsageToInvoiceLineItemOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareBenefitUsageToInvoiceLineItemOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  careBenefitUsageId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  associatedInvoiceId?: Maybe<SortOrder>;
  adjustedAmount?: Maybe<SortOrder>;
  confirmedByUserId?: Maybe<SortOrder>;
  draftServiceId?: Maybe<SortOrder>;
  _count?: Maybe<CareBenefitUsageToInvoiceLineItemCountOrderByAggregateInput>;
  _avg?: Maybe<CareBenefitUsageToInvoiceLineItemAvgOrderByAggregateInput>;
  _max?: Maybe<CareBenefitUsageToInvoiceLineItemMaxOrderByAggregateInput>;
  _min?: Maybe<CareBenefitUsageToInvoiceLineItemMinOrderByAggregateInput>;
  _sum?: Maybe<CareBenefitUsageToInvoiceLineItemSumOrderByAggregateInput>;
};

export type CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  careBenefitUsageId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  associatedInvoiceId?: Maybe<SortOrder>;
  adjustedAmount?: Maybe<SortOrder>;
  confirmedByUserId?: Maybe<SortOrder>;
  draftServiceId?: Maybe<SortOrder>;
  careBenefitUsage?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  invoiceLineItem?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  associatedInvoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  confirmedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  draftService?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelevanceInput>;
};

export enum CareBenefitUsageToInvoiceLineItemScalarFieldEnum {
  Id = 'id',
  CareBenefitUsageId = 'careBenefitUsageId',
  InvoiceLineItemId = 'invoiceLineItemId',
  AssociatedInvoiceId = 'associatedInvoiceId',
  AdjustedAmount = 'adjustedAmount',
  ConfirmedByUserId = 'confirmedByUserId',
  DraftServiceId = 'draftServiceId'
}

export type CareBenefitUsageToInvoiceLineItemScalarWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  careBenefitUsageId?: Maybe<StringFilter>;
  invoiceLineItemId?: Maybe<StringNullableFilter>;
  associatedInvoiceId?: Maybe<StringNullableFilter>;
  adjustedAmount?: Maybe<IntFilter>;
  confirmedByUserId?: Maybe<StringNullableFilter>;
  draftServiceId?: Maybe<StringNullableFilter>;
};

export type CareBenefitUsageToInvoiceLineItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  careBenefitUsageId?: Maybe<StringWithAggregatesFilter>;
  invoiceLineItemId?: Maybe<StringNullableWithAggregatesFilter>;
  associatedInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
  adjustedAmount?: Maybe<IntWithAggregatesFilter>;
  confirmedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  draftServiceId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareBenefitUsageToInvoiceLineItemSumAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemSumAggregateOutputType';
  adjustedAmount?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageToInvoiceLineItemSumOrderByAggregateInput = {
  adjustedAmount?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutAssociatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutCareBenefitUsageInput = {
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutConfirmedByInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutDraftServiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedCareBenefitCreditsInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutAssociatedInvoiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutAssociatedInvoiceInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutAssociatedInvoiceInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutCareBenefitUsageInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutCareBenefitUsageInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutCareBenefitUsageInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutConfirmedByInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutConfirmedByInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutConfirmedByInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConnectedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutDraftServiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutDraftServiceInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutDraftServiceInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemConnectionsInput = {
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceLineItemInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceLineItemInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutInvoiceLineItemInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutAssociatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutCareBenefitUsageInput = {
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutConfirmedByInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutDraftServiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateInput = {
  id?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsNestedInput>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsNestedInput>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsNestedInput>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsNestedInput>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsNestedInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutAssociatedInvoiceInput = {
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedCareBenefitCreditsInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutCareBenefitUsageInput = {
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemConnectionsInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutConfirmedByInput = {
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageToInvoiceLineItemsInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutDraftServiceInput = {
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftCareBenefitUsageToInvoiceLineItemsInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutInvoiceLineItemInput = {
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConnectedCareBenefitsInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutAssociatedInvoiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutAssociatedInvoiceInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutAssociatedInvoiceInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutCareBenefitUsageInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutCareBenefitUsageInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutCareBenefitUsageInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutConfirmedByInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutConfirmedByInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutConfirmedByInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutDraftServiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutDraftServiceInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutDraftServiceInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceLineItemInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceLineItemInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutInvoiceLineItemInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutAssociatedInvoiceInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutAssociatedInvoiceInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutCareBenefitUsageInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutCareBenefitUsageInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutConfirmedByInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutConfirmedByInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutDraftServiceInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutDraftServiceInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceLineItemInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutAssociatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsNestedInput>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsNestedInput>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsNestedInput>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsNestedInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutCareBenefitUsageInput = {
  id?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsNestedInput>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsNestedInput>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsNestedInput>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsNestedInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutConfirmedByInput = {
  id?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsNestedInput>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsNestedInput>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsNestedInput>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsNestedInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutDraftServiceInput = {
  id?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsNestedInput>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsNestedInput>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsNestedInput>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsNestedInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsNestedInput>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsNestedInput>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsNestedInput>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsNestedInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutAssociatedInvoiceInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutAssociatedInvoiceInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutAssociatedInvoiceInput;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutCareBenefitUsageInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutCareBenefitUsageInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutCareBenefitUsageInput;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutConfirmedByInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutConfirmedByInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutConfirmedByInput;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutDraftServiceInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutDraftServiceInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutDraftServiceInput;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceLineItemInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput;
};

export type CareBenefitUsageToInvoiceLineItemWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereInput>>;
  id?: Maybe<StringFilter>;
  careBenefitUsageId?: Maybe<StringFilter>;
  invoiceLineItemId?: Maybe<StringNullableFilter>;
  associatedInvoiceId?: Maybe<StringNullableFilter>;
  adjustedAmount?: Maybe<IntFilter>;
  confirmedByUserId?: Maybe<StringNullableFilter>;
  draftServiceId?: Maybe<StringNullableFilter>;
  careBenefitUsage?: Maybe<CareBenefitUsageWhereInput>;
  invoiceLineItem?: Maybe<InvoiceLineItemWhereInput>;
  associatedInvoice?: Maybe<InvoiceWhereInput>;
  confirmedBy?: Maybe<UserWhereInput>;
  draftService?: Maybe<ServiceWhereInput>;
};

export type CareBenefitUsageToInvoiceLineItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId_invoiceLineItemId?: Maybe<CareBenefitUsageToInvoiceLineItemCareBenefitUsageIdInvoiceLineItemIdCompoundUniqueInput>;
};

export type CareBenefitUsageUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutCareAccountCreditIntentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyCareAccountCreditIntentInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRecordedByUserInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRecordedByUserInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutRelatedFollowupStepInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRelatedFollowupStepInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRelatedFollowupStepInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRelatedFollowupStepInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicPetInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicPetInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageUncheckedCreateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
};

export type CareBenefitUsageUncheckedCreateWithoutCareAccountCreditIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
};

export type CareBenefitUsageUncheckedCreateWithoutConnectedAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
};

export type CareBenefitUsageUncheckedCreateWithoutConnectedPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
};

export type CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedCreateWithoutRecordedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
};

export type CareBenefitUsageUncheckedCreateWithoutRelatedFollowupStepInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
};

export type CareBenefitUsageUncheckedCreateWithoutUsageClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
};

export type CareBenefitUsageUncheckedCreateWithoutUsageClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
};

export type CareBenefitUsageUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
};

export type CareBenefitUsageUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutBenefitsProvidedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutCareAccountCreditIntentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyCareAccountCreditIntentInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutCareAccountCreditIntentInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutCareBenefitUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedAddonEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutConnectedAddonEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedPlanEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedPlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutConnectedPlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRecordedByUserInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutRecordedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRecordedByUserInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutRecordedByUserInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutRecordedByUserInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutRelatedFollowupStepNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRelatedFollowupStepInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRelatedFollowupStepInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutRelatedFollowupStepInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRelatedFollowupStepInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutRelatedFollowupStepInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutRelatedFollowupStepInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutRelatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicPetInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicPetInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicPetInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicPetInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicPetInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutUsagesRecordedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
};

export type CareBenefitUsageUncheckedUpdateWithoutCareAccountCreditIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
};

export type CareBenefitUsageUncheckedUpdateWithoutConnectedAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
};

export type CareBenefitUsageUncheckedUpdateWithoutConnectedPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
};

export type CareBenefitUsageUncheckedUpdateWithoutInvoiceLineItemConnectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutRecordedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
};

export type CareBenefitUsageUncheckedUpdateWithoutRelatedFollowupStepInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
};

export type CareBenefitUsageUncheckedUpdateWithoutUsageClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
};

export type CareBenefitUsageUncheckedUpdateWithoutUsageClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
};

export type CareBenefitUsageUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
};

export type CareBenefitUsageUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutBenefitInput = {
  where: CareBenefitUsageScalarWhereInput;
  data: CareBenefitUsageUncheckedUpdateManyWithoutUsagesInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutCareAccountCreditIntentInput = {
  where: CareBenefitUsageScalarWhereInput;
  data: CareBenefitUsageUncheckedUpdateManyWithoutCareBenefitUsagesInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutConnectedAddonEnrollmentInput = {
  where: CareBenefitUsageScalarWhereInput;
  data: CareBenefitUsageUncheckedUpdateManyWithoutAssociatedUsagesInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutConnectedPlanEnrollmentInput = {
  where: CareBenefitUsageScalarWhereInput;
  data: CareBenefitUsageUncheckedUpdateManyWithoutAssociatedUsagesInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutRecordedByUserInput = {
  where: CareBenefitUsageScalarWhereInput;
  data: CareBenefitUsageUncheckedUpdateManyWithoutUsagesRecordedInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutRelatedFollowupStepInput = {
  where: CareBenefitUsageScalarWhereInput;
  data: CareBenefitUsageUncheckedUpdateManyWithoutRelatedUsagesInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicInput = {
  where: CareBenefitUsageScalarWhereInput;
  data: CareBenefitUsageUncheckedUpdateManyWithoutBenefitsProvidedInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicPetInput = {
  where: CareBenefitUsageScalarWhereInput;
  data: CareBenefitUsageUncheckedUpdateManyWithoutCareBenefitUsagesInput;
};

export type CareBenefitUsageUpdateManyWithoutBenefitNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUpdateManyWithoutCareAccountCreditIntentNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutCareAccountCreditIntentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyCareAccountCreditIntentInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutCareAccountCreditIntentInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedAddonEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutConnectedAddonEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedPlanEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedPlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutConnectedPlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRecordedByUserInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutRecordedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRecordedByUserInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutRecordedByUserInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutRecordedByUserInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUpdateManyWithoutRelatedFollowupStepNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRelatedFollowupStepInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRelatedFollowupStepInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutRelatedFollowupStepInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRelatedFollowupStepInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutRelatedFollowupStepInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutRelatedFollowupStepInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicPetInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicPetInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicPetInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicPetInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicPetInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsNestedInput = {
  create?: Maybe<CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput>;
  connectOrCreate?: Maybe<CareBenefitUsageCreateOrConnectWithoutInvoiceLineItemConnectionsInput>;
  upsert?: Maybe<CareBenefitUsageUpsertWithoutInvoiceLineItemConnectionsInput>;
  connect?: Maybe<CareBenefitUsageWhereUniqueInput>;
  update?: Maybe<CareBenefitUsageUncheckedUpdateWithoutInvoiceLineItemConnectionsInput>;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutBenefitInput = {
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUncheckedUpdateWithoutBenefitInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUncheckedUpdateWithoutCareAccountCreditIntentInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedAddonEnrollmentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUncheckedUpdateWithoutConnectedAddonEnrollmentInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedPlanEnrollmentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUncheckedUpdateWithoutConnectedPlanEnrollmentInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutRecordedByUserInput = {
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUncheckedUpdateWithoutRecordedByUserInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutRelatedFollowupStepInput = {
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUncheckedUpdateWithoutRelatedFollowupStepInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicInput = {
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUncheckedUpdateWithoutUsageClinicInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicPetInput = {
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUncheckedUpdateWithoutUsageClinicPetInput;
};

export type CareBenefitUsageUpdateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
};

export type CareBenefitUsageUpdateWithoutCareAccountCreditIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
};

export type CareBenefitUsageUpdateWithoutConnectedAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
};

export type CareBenefitUsageUpdateWithoutConnectedPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
};

export type CareBenefitUsageUpdateWithoutInvoiceLineItemConnectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
};

export type CareBenefitUsageUpdateWithoutRecordedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
};

export type CareBenefitUsageUpdateWithoutRelatedFollowupStepInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
};

export type CareBenefitUsageUpdateWithoutUsageClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
};

export type CareBenefitUsageUpdateWithoutUsageClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutBenefitInput = {
  where: CareBenefitUsageWhereUniqueInput;
  update: CareBenefitUsageUncheckedUpdateWithoutBenefitInput;
  create: CareBenefitUsageUncheckedCreateWithoutBenefitInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  update: CareBenefitUsageUncheckedUpdateWithoutCareAccountCreditIntentInput;
  create: CareBenefitUsageUncheckedCreateWithoutCareAccountCreditIntentInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedAddonEnrollmentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  update: CareBenefitUsageUncheckedUpdateWithoutConnectedAddonEnrollmentInput;
  create: CareBenefitUsageUncheckedCreateWithoutConnectedAddonEnrollmentInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedPlanEnrollmentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  update: CareBenefitUsageUncheckedUpdateWithoutConnectedPlanEnrollmentInput;
  create: CareBenefitUsageUncheckedCreateWithoutConnectedPlanEnrollmentInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutRecordedByUserInput = {
  where: CareBenefitUsageWhereUniqueInput;
  update: CareBenefitUsageUncheckedUpdateWithoutRecordedByUserInput;
  create: CareBenefitUsageUncheckedCreateWithoutRecordedByUserInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutRelatedFollowupStepInput = {
  where: CareBenefitUsageWhereUniqueInput;
  update: CareBenefitUsageUncheckedUpdateWithoutRelatedFollowupStepInput;
  create: CareBenefitUsageUncheckedCreateWithoutRelatedFollowupStepInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicInput = {
  where: CareBenefitUsageWhereUniqueInput;
  update: CareBenefitUsageUncheckedUpdateWithoutUsageClinicInput;
  create: CareBenefitUsageUncheckedCreateWithoutUsageClinicInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicPetInput = {
  where: CareBenefitUsageWhereUniqueInput;
  update: CareBenefitUsageUncheckedUpdateWithoutUsageClinicPetInput;
  create: CareBenefitUsageUncheckedCreateWithoutUsageClinicPetInput;
};

export type CareBenefitUsageUpsertWithoutInvoiceLineItemConnectionsInput = {
  update: CareBenefitUsageUncheckedUpdateWithoutInvoiceLineItemConnectionsInput;
  create: CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput;
};

export type CareBenefitUsageWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  usedAt?: Maybe<DateTimeFilter>;
  quantityUsed?: Maybe<IntNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  usageClinicId?: Maybe<StringFilter>;
  usageClinicPetId?: Maybe<StringFilter>;
  recordedByUserId?: Maybe<StringNullableFilter>;
  benefitId?: Maybe<StringFilter>;
  exhaustsBenefit?: Maybe<BoolNullableFilter>;
  connectedPlanEnrollmentId?: Maybe<StringNullableFilter>;
  connectedAddonEnrollmentId?: Maybe<StringNullableFilter>;
  relatedFollowupStepId?: Maybe<StringNullableFilter>;
  careAccountCreditIntentId?: Maybe<StringNullableFilter>;
  usageClinic?: Maybe<ClinicWhereInput>;
  usageClinicPet?: Maybe<ClinicPetWhereInput>;
  recordedByUser?: Maybe<UserWhereInput>;
  benefit?: Maybe<CareBenefitWhereInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentWhereInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentWhereInput>;
};

export type CareBenefitUsageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitWhereInput = {
  AND?: Maybe<Array<CareBenefitWhereInput>>;
  OR?: Maybe<Array<CareBenefitWhereInput>>;
  NOT?: Maybe<Array<CareBenefitWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  type?: Maybe<EnumCareBenefitTypeFilter>;
  description?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  tangible?: Maybe<BoolFilter>;
  reportingExpectedValue?: Maybe<IntFilter>;
  providedDiscountPercentage?: Maybe<IntNullableFilter>;
  organizationId?: Maybe<StringFilter>;
  usageUnitOfMeasure?: Maybe<EnumCareBenefitUnitOfMeasureNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
  exhaustible?: Maybe<BoolFilter>;
  providesTeletriage?: Maybe<BoolFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  flowAlias?: Maybe<StringNullableFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  planBenefits?: Maybe<CarePlanBenefitListRelationFilter>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitListRelationFilter>;
  usages?: Maybe<CareBenefitUsageListRelationFilter>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceListRelationFilter>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryListRelationFilter>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupWhereInput>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverListRelationFilter>;
};

export type CareBenefitWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareClinicInfo = {
  __typename?: 'CareClinicInfo';
  id: Scalars['String'];
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<CareOrganizationInfo>;
  enabledFeatureFlags: Array<Scalars['String']>;
};

export type CareEnrollmentData = {
  __typename?: 'CareEnrollmentData';
  carePlanProviderGroupId: Scalars['String'];
  clientName: Scalars['String'];
  petName: Scalars['String'];
  planName: Scalars['String'];
  isRenewal: Scalars['Boolean'];
};

export type CareEnrollmentOutput = {
  __typename?: 'CareEnrollmentOutput';
  isEnrolled: Scalars['Boolean'];
};

export type CareEnrollmentPayment = {
  __typename?: 'CareEnrollmentPayment';
  id: Scalars['String'];
  /** the total amount of the payment (including fee if present) */
  amount: Scalars['Int'];
  /** the fee amount for the payment (part of the amount column) */
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollment>;
  carePlanEnrollment?: Maybe<CarePlanEnrollment>;
  stripeInvoice?: Maybe<StripeInvoice>;
};

export type CareEnrollmentPaymentAvgAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  applicationFeeAmount?: Maybe<Scalars['Float']>;
};

export type CareEnrollmentPaymentAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  applicationFeeAmount?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentCareAddonEnrollmentIdStripeInvoiceIdCompoundUniqueInput = {
  careAddonEnrollmentId: Scalars['String'];
  stripeInvoiceId: Scalars['String'];
};

export type CareEnrollmentPaymentCarePlanEnrollmentIdStripeInvoiceIdCompoundUniqueInput = {
  carePlanEnrollmentId: Scalars['String'];
  stripeInvoiceId: Scalars['String'];
};

export type CareEnrollmentPaymentCountAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentCountAggregateOutputType';
  id: Scalars['Int'];
  amount: Scalars['Int'];
  applicationFeeAmount: Scalars['Int'];
  paidAt: Scalars['Int'];
  lastPaymentAttempt: Scalars['Int'];
  nextPaymentAttempt: Scalars['Int'];
  lastPaymentError: Scalars['Int'];
  careAddonEnrollmentId: Scalars['Int'];
  carePlanEnrollmentId: Scalars['Int'];
  stripeInvoiceId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareEnrollmentPaymentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  applicationFeeAmount?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  lastPaymentAttempt?: Maybe<SortOrder>;
  nextPaymentAttempt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentCreateInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutEnrollmentPaymentInput>;
};

export type CareEnrollmentPaymentCreateManyCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope = {
  data: Array<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentPaymentCreateManyCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope = {
  data: Array<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentPaymentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentCreateManyStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope = {
  data: Array<CareEnrollmentPaymentCreateManyStripeInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
};

export type CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
};

export type CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
};

export type CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  create: CareEnrollmentPaymentUncheckedCreateWithoutCareAddonEnrollmentInput;
};

export type CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  create: CareEnrollmentPaymentUncheckedCreateWithoutCarePlanEnrollmentInput;
};

export type CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  create: CareEnrollmentPaymentUncheckedCreateWithoutStripeInvoiceInput;
};

export type CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutEnrollmentPaymentInput>;
};

export type CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutEnrollmentPaymentInput>;
};

export type CareEnrollmentPaymentCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
};

export type CareEnrollmentPaymentListRelationFilter = {
  every?: Maybe<CareEnrollmentPaymentWhereInput>;
  some?: Maybe<CareEnrollmentPaymentWhereInput>;
  none?: Maybe<CareEnrollmentPaymentWhereInput>;
};

export type CareEnrollmentPaymentMaxAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  applicationFeeAmount?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  lastPaymentAttempt?: Maybe<SortOrder>;
  nextPaymentAttempt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentMinAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  applicationFeeAmount?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  lastPaymentAttempt?: Maybe<SortOrder>;
  nextPaymentAttempt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareEnrollmentPaymentOrderByRelevanceFieldEnum {
  Id = 'id',
  CareAddonEnrollmentId = 'careAddonEnrollmentId',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  StripeInvoiceId = 'stripeInvoiceId'
}

export type CareEnrollmentPaymentOrderByRelevanceInput = {
  fields: Array<CareEnrollmentPaymentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareEnrollmentPaymentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  applicationFeeAmount?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  lastPaymentAttempt?: Maybe<SortOrder>;
  nextPaymentAttempt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  _count?: Maybe<CareEnrollmentPaymentCountOrderByAggregateInput>;
  _avg?: Maybe<CareEnrollmentPaymentAvgOrderByAggregateInput>;
  _max?: Maybe<CareEnrollmentPaymentMaxOrderByAggregateInput>;
  _min?: Maybe<CareEnrollmentPaymentMinOrderByAggregateInput>;
  _sum?: Maybe<CareEnrollmentPaymentSumOrderByAggregateInput>;
};

export type CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  applicationFeeAmount?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  lastPaymentAttempt?: Maybe<SortOrder>;
  nextPaymentAttempt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  stripeInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CareEnrollmentPaymentOrderByRelevanceInput>;
};

export enum CareEnrollmentPaymentScalarFieldEnum {
  Id = 'id',
  Amount = 'amount',
  ApplicationFeeAmount = 'applicationFeeAmount',
  PaidAt = 'paidAt',
  LastPaymentAttempt = 'lastPaymentAttempt',
  NextPaymentAttempt = 'nextPaymentAttempt',
  LastPaymentError = 'lastPaymentError',
  CareAddonEnrollmentId = 'careAddonEnrollmentId',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  StripeInvoiceId = 'stripeInvoiceId'
}

export type CareEnrollmentPaymentScalarWhereInput = {
  AND?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  OR?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  NOT?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  applicationFeeAmount?: Maybe<IntNullableFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  lastPaymentAttempt?: Maybe<DateTimeNullableFilter>;
  nextPaymentAttempt?: Maybe<DateTimeNullableFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableFilter>;
  careAddonEnrollmentId?: Maybe<StringNullableFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableFilter>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
};

export type CareEnrollmentPaymentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareEnrollmentPaymentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareEnrollmentPaymentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareEnrollmentPaymentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  applicationFeeAmount?: Maybe<IntNullableWithAggregatesFilter>;
  paidAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastPaymentAttempt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  nextPaymentAttempt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableWithAggregatesFilter>;
  careAddonEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareEnrollmentPaymentSumAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
};

export type CareEnrollmentPaymentSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  applicationFeeAmount?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
};

export type CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
};

export type CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
};

export type CareEnrollmentPaymentUncheckedCreateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedCreateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutCareAddonEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
};

export type CareEnrollmentPaymentUncheckedUpdateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutEnrollmentPaymentNestedInput>;
};

export type CareEnrollmentPaymentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
};

export type CareEnrollmentPaymentUpdateManyWithWhereWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentPaymentScalarWhereInput;
  data: CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentsInput;
};

export type CareEnrollmentPaymentUpdateManyWithWhereWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentPaymentScalarWhereInput;
  data: CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentsInput;
};

export type CareEnrollmentPaymentUpdateManyWithWhereWithoutStripeInvoiceInput = {
  where: CareEnrollmentPaymentScalarWhereInput;
  data: CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentInput;
};

export type CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentNestedInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutCareAddonEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
};

export type CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
};

export type CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
};

export type CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  data: CareEnrollmentPaymentUncheckedUpdateWithoutCareAddonEnrollmentInput;
};

export type CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  data: CareEnrollmentPaymentUncheckedUpdateWithoutCarePlanEnrollmentInput;
};

export type CareEnrollmentPaymentUpdateWithWhereUniqueWithoutStripeInvoiceInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  data: CareEnrollmentPaymentUncheckedUpdateWithoutStripeInvoiceInput;
};

export type CareEnrollmentPaymentUpdateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutEnrollmentPaymentNestedInput>;
};

export type CareEnrollmentPaymentUpdateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutEnrollmentPaymentNestedInput>;
};

export type CareEnrollmentPaymentUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput>;
};

export type CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  update: CareEnrollmentPaymentUncheckedUpdateWithoutCareAddonEnrollmentInput;
  create: CareEnrollmentPaymentUncheckedCreateWithoutCareAddonEnrollmentInput;
};

export type CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  update: CareEnrollmentPaymentUncheckedUpdateWithoutCarePlanEnrollmentInput;
  create: CareEnrollmentPaymentUncheckedCreateWithoutCarePlanEnrollmentInput;
};

export type CareEnrollmentPaymentUpsertWithWhereUniqueWithoutStripeInvoiceInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  update: CareEnrollmentPaymentUncheckedUpdateWithoutStripeInvoiceInput;
  create: CareEnrollmentPaymentUncheckedCreateWithoutStripeInvoiceInput;
};

export type CareEnrollmentPaymentWhereInput = {
  AND?: Maybe<Array<CareEnrollmentPaymentWhereInput>>;
  OR?: Maybe<Array<CareEnrollmentPaymentWhereInput>>;
  NOT?: Maybe<Array<CareEnrollmentPaymentWhereInput>>;
  id?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  applicationFeeAmount?: Maybe<IntNullableFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  lastPaymentAttempt?: Maybe<DateTimeNullableFilter>;
  nextPaymentAttempt?: Maybe<DateTimeNullableFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableFilter>;
  careAddonEnrollmentId?: Maybe<StringNullableFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableFilter>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentWhereInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  stripeInvoice?: Maybe<StripeInvoiceWhereInput>;
};

export type CareEnrollmentPaymentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  careAddonEnrollmentId_stripeInvoiceId?: Maybe<CareEnrollmentPaymentCareAddonEnrollmentIdStripeInvoiceIdCompoundUniqueInput>;
  carePlanEnrollmentId_stripeInvoiceId?: Maybe<CareEnrollmentPaymentCarePlanEnrollmentIdStripeInvoiceIdCompoundUniqueInput>;
};

export type CareEnrollmentToStripeInvoiceItem = {
  __typename?: 'CareEnrollmentToStripeInvoiceItem';
  id: Scalars['String'];
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
  carePlanEnrollment?: Maybe<CarePlanEnrollment>;
  careAddonEnrollment?: Maybe<CareAddonEnrollment>;
  stripeInvoiceItem: StripeInvoiceItem;
};

export type CareEnrollmentToStripeInvoiceItemCountAggregateOutputType = {
  __typename?: 'CareEnrollmentToStripeInvoiceItemCountAggregateOutputType';
  id: Scalars['Int'];
  carePlanEnrollmentId: Scalars['Int'];
  careAddonEnrollmentId: Scalars['Int'];
  stripeInvoiceItemId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareEnrollmentToStripeInvoiceItemCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
};

export type CareEnrollmentToStripeInvoiceItemCreateInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  stripeInvoiceItem: StripeInvoiceItemCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope = {
  data: Array<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope = {
  data: Array<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentToStripeInvoiceItemCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope = {
  data: Array<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
};

export type CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
};

export type CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutStripeInvoiceItemInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
};

export type CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCareAddonEnrollmentInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCarePlanEnrollmentInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutStripeInvoiceItemInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  stripeInvoiceItem: StripeInvoiceItemCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  stripeInvoiceItem: StripeInvoiceItemCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type CareEnrollmentToStripeInvoiceItemListRelationFilter = {
  every?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  some?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  none?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};

export type CareEnrollmentToStripeInvoiceItemMaxAggregateOutputType = {
  __typename?: 'CareEnrollmentToStripeInvoiceItemMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
};

export type CareEnrollmentToStripeInvoiceItemMinAggregateOutputType = {
  __typename?: 'CareEnrollmentToStripeInvoiceItemMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
};

export type CareEnrollmentToStripeInvoiceItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareEnrollmentToStripeInvoiceItemOrderByRelevanceFieldEnum {
  Id = 'id',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  CareAddonEnrollmentId = 'careAddonEnrollmentId',
  StripeInvoiceItemId = 'stripeInvoiceItemId'
}

export type CareEnrollmentToStripeInvoiceItemOrderByRelevanceInput = {
  fields: Array<CareEnrollmentToStripeInvoiceItemOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
  _count?: Maybe<CareEnrollmentToStripeInvoiceItemCountOrderByAggregateInput>;
  _max?: Maybe<CareEnrollmentToStripeInvoiceItemMaxOrderByAggregateInput>;
  _min?: Maybe<CareEnrollmentToStripeInvoiceItemMinOrderByAggregateInput>;
};

export type CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByRelevanceInput>;
};

export enum CareEnrollmentToStripeInvoiceItemScalarFieldEnum {
  Id = 'id',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  CareAddonEnrollmentId = 'careAddonEnrollmentId',
  StripeInvoiceItemId = 'stripeInvoiceItemId'
}

export type CareEnrollmentToStripeInvoiceItemScalarWhereInput = {
  AND?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  OR?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  NOT?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableFilter>;
  careAddonEnrollmentId?: Maybe<StringNullableFilter>;
  stripeInvoiceItemId?: Maybe<StringFilter>;
};

export type CareEnrollmentToStripeInvoiceItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  careAddonEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeInvoiceItemId?: Maybe<StringWithAggregatesFilter>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceItemInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutStripeInvoiceItemInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCareAddonEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceItemNestedInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceItemInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceItemInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceItemInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceItemInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemUpdateOneRequiredWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemScalarWhereInput;
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemScalarWhereInput;
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceItemInput = {
  where: CareEnrollmentToStripeInvoiceItemScalarWhereInput;
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentNestedInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCareAddonEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithoutStripeInvoiceItemNestedInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceItemInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceItemInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceItemInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCareAddonEnrollmentInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCarePlanEnrollmentInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceItemInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceItemInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemUpdateOneRequiredWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemUpdateOneRequiredWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithoutStripeInvoiceItemInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
};

export type CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  update: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCareAddonEnrollmentInput;
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCareAddonEnrollmentInput;
};

export type CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  update: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCarePlanEnrollmentInput;
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCarePlanEnrollmentInput;
};

export type CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceItemInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  update: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceItemInput;
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutStripeInvoiceItemInput;
};

export type CareEnrollmentToStripeInvoiceItemWhereInput = {
  AND?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereInput>>;
  OR?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereInput>>;
  NOT?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereInput>>;
  id?: Maybe<StringFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableFilter>;
  careAddonEnrollmentId?: Maybe<StringNullableFilter>;
  stripeInvoiceItemId?: Maybe<StringFilter>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentWhereInput>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemWhereInput>;
};

export type CareEnrollmentToStripeInvoiceItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareEnrollmentWhereInput = {
  organizationPetId: Scalars['String'];
};

export type CareEnrollmentsByClinicOutput = {
  __typename?: 'CareEnrollmentsByClinicOutput';
  dailyTotal: Scalars['Int'];
  monthlyTotal: Scalars['Int'];
  enrollmentData: CareEnrollmentData;
  carePlanProviderGroupId: Scalars['String'];
};

export type CareEnrollmentsByClinicWhereInput = {
  carePlanProviderGroupId: Scalars['String'];
};

export type CareLapsedPayment = {
  __typename?: 'CareLapsedPayment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeInvoiceId: Scalars['String'];
  status: CareLapsedPaymentStatus;
  expiredAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  stripeInvoice: StripeInvoice;
};

export type CareLapsedPaymentCountAggregateOutputType = {
  __typename?: 'CareLapsedPaymentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  stripeInvoiceId: Scalars['Int'];
  status: Scalars['Int'];
  expiredAt: Scalars['Int'];
  resolvedAt: Scalars['Int'];
  lastNotifiedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareLapsedPaymentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  lastNotifiedAt?: Maybe<SortOrder>;
};

export type CareLapsedPaymentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  stripeInvoice: StripeInvoiceCreateNestedOneWithoutLapsedPaymentInput;
};

export type CareLapsedPaymentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId: Scalars['String'];
  status?: Maybe<CareLapsedPaymentStatus>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput = {
  create?: Maybe<CareLapsedPaymentUncheckedCreateWithoutStripeInvoiceInput>;
  connectOrCreate?: Maybe<CareLapsedPaymentCreateOrConnectWithoutStripeInvoiceInput>;
  connect?: Maybe<CareLapsedPaymentWhereUniqueInput>;
};

export type CareLapsedPaymentCreateOrConnectWithoutStripeInvoiceInput = {
  where: CareLapsedPaymentWhereUniqueInput;
  create: CareLapsedPaymentUncheckedCreateWithoutStripeInvoiceInput;
};

export type CareLapsedPaymentCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentMaxAggregateOutputType = {
  __typename?: 'CareLapsedPaymentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  lastNotifiedAt?: Maybe<SortOrder>;
};

export type CareLapsedPaymentMinAggregateOutputType = {
  __typename?: 'CareLapsedPaymentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  lastNotifiedAt?: Maybe<SortOrder>;
};

export enum CareLapsedPaymentOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeInvoiceId = 'stripeInvoiceId'
}

export type CareLapsedPaymentOrderByRelevanceInput = {
  fields: Array<CareLapsedPaymentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareLapsedPaymentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  lastNotifiedAt?: Maybe<SortOrder>;
  _count?: Maybe<CareLapsedPaymentCountOrderByAggregateInput>;
  _max?: Maybe<CareLapsedPaymentMaxOrderByAggregateInput>;
  _min?: Maybe<CareLapsedPaymentMinOrderByAggregateInput>;
};

export type CareLapsedPaymentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  lastNotifiedAt?: Maybe<SortOrder>;
  stripeInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CareLapsedPaymentOrderByRelevanceInput>;
};

export type CareLapsedPaymentRelationFilter = {
  is?: Maybe<CareLapsedPaymentWhereInput>;
  isNot?: Maybe<CareLapsedPaymentWhereInput>;
};

export enum CareLapsedPaymentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeInvoiceId = 'stripeInvoiceId',
  Status = 'status',
  ExpiredAt = 'expiredAt',
  ResolvedAt = 'resolvedAt',
  LastNotifiedAt = 'lastNotifiedAt'
}

export type CareLapsedPaymentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareLapsedPaymentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareLapsedPaymentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareLapsedPaymentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeInvoiceId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumCareLapsedPaymentStatusWithAggregatesFilter>;
  expiredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  resolvedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastNotifiedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
};

export enum CareLapsedPaymentStatus {
  Pending = 'Pending',
  Resolved = 'Resolved',
  Expired = 'Expired'
}

export type CareLapsedPaymentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId: Scalars['String'];
  status?: Maybe<CareLapsedPaymentStatus>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput = {
  create?: Maybe<CareLapsedPaymentUncheckedCreateWithoutStripeInvoiceInput>;
  connectOrCreate?: Maybe<CareLapsedPaymentCreateOrConnectWithoutStripeInvoiceInput>;
  connect?: Maybe<CareLapsedPaymentWhereUniqueInput>;
};

export type CareLapsedPaymentUncheckedCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput = {
  create?: Maybe<CareLapsedPaymentUncheckedCreateWithoutStripeInvoiceInput>;
  connectOrCreate?: Maybe<CareLapsedPaymentCreateOrConnectWithoutStripeInvoiceInput>;
  upsert?: Maybe<CareLapsedPaymentUpsertWithoutStripeInvoiceInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareLapsedPaymentWhereUniqueInput>;
  update?: Maybe<CareLapsedPaymentUncheckedUpdateWithoutStripeInvoiceInput>;
};

export type CareLapsedPaymentUncheckedUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneRequiredWithoutLapsedPaymentNestedInput>;
};

export type CareLapsedPaymentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput = {
  create?: Maybe<CareLapsedPaymentUncheckedCreateWithoutStripeInvoiceInput>;
  connectOrCreate?: Maybe<CareLapsedPaymentCreateOrConnectWithoutStripeInvoiceInput>;
  upsert?: Maybe<CareLapsedPaymentUpsertWithoutStripeInvoiceInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareLapsedPaymentWhereUniqueInput>;
  update?: Maybe<CareLapsedPaymentUncheckedUpdateWithoutStripeInvoiceInput>;
};

export type CareLapsedPaymentUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUpsertWithoutStripeInvoiceInput = {
  update: CareLapsedPaymentUncheckedUpdateWithoutStripeInvoiceInput;
  create: CareLapsedPaymentUncheckedCreateWithoutStripeInvoiceInput;
};

export type CareLapsedPaymentWhereInput = {
  AND?: Maybe<Array<CareLapsedPaymentWhereInput>>;
  OR?: Maybe<Array<CareLapsedPaymentWhereInput>>;
  NOT?: Maybe<Array<CareLapsedPaymentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeInvoiceId?: Maybe<StringFilter>;
  status?: Maybe<EnumCareLapsedPaymentStatusFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  resolvedAt?: Maybe<DateTimeNullableFilter>;
  lastNotifiedAt?: Maybe<DateTimeNullableFilter>;
  stripeInvoice?: Maybe<StripeInvoiceWhereInput>;
};

export type CareLapsedPaymentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareOrganizationInfo = {
  __typename?: 'CareOrganizationInfo';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CarePetSignupInput = {
  organizationPetId: Scalars['String'];
  carePlanId: Scalars['String'];
};

export type CarePlan = {
  __typename?: 'CarePlan';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal: Scalars['Int'];
  billingCycle: CarePlanCycleType;
  renewalCycle: CarePlanCycleType;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  /** Note: we will eventually transform this into proper models once the marketing information gets more nailed down */
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes: Scalars['String'];
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth: Scalars['Int'];
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal: Scalars['Boolean'];
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization: Organization;
  enrollments: Array<CarePlanEnrollment>;
  planBenefits: Array<CarePlanBenefit>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlan>;
  renewalPredecessorPlan: Array<CarePlan>;
  explicitRenewalSuccessors: Array<CarePlanEnrollment>;
  selectedByRenewalEmailEnrollments: Array<CarePlanEnrollment>;
  currentPlanEnrollmentSupportEvents: Array<EnrollmentSupportEvent>;
  newPlanEnrollmentSupportEvents: Array<EnrollmentSupportEvent>;
  _count: CarePlanCountOutputType;
};


export type CarePlanEnrollmentsArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanEnrollmentScalarFieldEnum>;
};


export type CarePlanPlanBenefitsArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
  orderBy?: Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanBenefitScalarFieldEnum>;
};


export type CarePlanRenewalPredecessorPlanArgs = {
  where?: Maybe<CarePlanWhereInput>;
  orderBy?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanScalarFieldEnum>;
};


export type CarePlanExplicitRenewalSuccessorsArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanEnrollmentScalarFieldEnum>;
};


export type CarePlanSelectedByRenewalEmailEnrollmentsArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanEnrollmentScalarFieldEnum>;
};


export type CarePlanCurrentPlanEnrollmentSupportEventsArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
};


export type CarePlanNewPlanEnrollmentSupportEventsArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
};

export type CarePlanAvgAggregateOutputType = {
  __typename?: 'CarePlanAvgAggregateOutputType';
  pricePerRenewal?: Maybe<Scalars['Float']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Float']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Float']>;
  signupFeePricing?: Maybe<Scalars['Float']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Float']>;
  planDiscount?: Maybe<Scalars['Float']>;
};

export type CarePlanAvgOrderByAggregateInput = {
  pricePerRenewal?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  planDiscount?: Maybe<SortOrder>;
};

export type CarePlanBenefit = {
  __typename?: 'CarePlanBenefit';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue: Scalars['Int'];
  reportingExpectedUsages: Scalars['Float'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  planId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
  benefit: CareBenefit;
  plan: CarePlan;
};

export type CarePlanBenefitAvgAggregateOutputType = {
  __typename?: 'CarePlanBenefitAvgAggregateOutputType';
  includedUses?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Float']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Float']>;
  displayOrder?: Maybe<Scalars['Float']>;
};

export type CarePlanBenefitAvgOrderByAggregateInput = {
  includedUses?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
};

export type CarePlanBenefitCountAggregateOutputType = {
  __typename?: 'CarePlanBenefitCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  includedUses: Scalars['Int'];
  reportingExpectedValue: Scalars['Int'];
  reportingExpectedUsages: Scalars['Int'];
  providedDiscountPercentage: Scalars['Int'];
  displayOrder: Scalars['Int'];
  benefitId: Scalars['Int'];
  planId: Scalars['Int'];
  projectedSavings: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CarePlanBenefitCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
};

export type CarePlanBenefitCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  benefit: CareBenefitCreateNestedOneWithoutPlanBenefitsInput;
  plan: CarePlanCreateNestedOneWithoutPlanBenefitsInput;
};

export type CarePlanBenefitCreateManyBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  planId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitCreateManyBenefitInputEnvelope = {
  data: Array<CarePlanBenefitCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanBenefitCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  planId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitCreateManyPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitCreateManyPlanInputEnvelope = {
  data: Array<CarePlanBenefitCreateManyPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanBenefitCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
};

export type CarePlanBenefitCreateNestedManyWithoutPlanInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutPlanInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyPlanInputEnvelope>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
};

export type CarePlanBenefitCreateOrConnectWithoutBenefitInput = {
  where: CarePlanBenefitWhereUniqueInput;
  create: CarePlanBenefitUncheckedCreateWithoutBenefitInput;
};

export type CarePlanBenefitCreateOrConnectWithoutPlanInput = {
  where: CarePlanBenefitWhereUniqueInput;
  create: CarePlanBenefitUncheckedCreateWithoutPlanInput;
};

export type CarePlanBenefitCreateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  plan: CarePlanCreateNestedOneWithoutPlanBenefitsInput;
};

export type CarePlanBenefitCreateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  benefit: CareBenefitCreateNestedOneWithoutPlanBenefitsInput;
};

export type CarePlanBenefitEnrollmentCarryover = {
  __typename?: 'CarePlanBenefitEnrollmentCarryover';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  benefitId: Scalars['String'];
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  benefit: CareBenefit;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollment>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollment>;
};

export type CarePlanBenefitEnrollmentCarryoverAvgAggregateOutputType = {
  __typename?: 'CarePlanBenefitEnrollmentCarryoverAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type CarePlanBenefitEnrollmentCarryoverAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type CarePlanBenefitEnrollmentCarryoverCountAggregateOutputType = {
  __typename?: 'CarePlanBenefitEnrollmentCarryoverCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  benefitId: Scalars['Int'];
  sourcePlanEnrollmentId: Scalars['Int'];
  amount: Scalars['Int'];
  destinationPlanEnrollmentId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CarePlanBenefitEnrollmentCarryoverCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  sourcePlanEnrollmentId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  destinationPlanEnrollmentId?: Maybe<SortOrder>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  benefit: CareBenefitCreateNestedOneWithoutEnrollmentCarryoversInput;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutForwardedCarePlanBenefitCarryoversInput>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutReceivedCarePlanBenefitCarryoversInput>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateManyBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateManyBenefitInputEnvelope = {
  data: Array<CarePlanBenefitEnrollmentCarryoverCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateManyDestinationPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  benefitId: Scalars['String'];
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateManyDestinationPlanEnrollmentInputEnvelope = {
  data: Array<CarePlanBenefitEnrollmentCarryoverCreateManyDestinationPlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  benefitId: Scalars['String'];
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateManySourcePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  benefitId: Scalars['String'];
  amount?: Maybe<Scalars['Int']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateManySourcePlanEnrollmentInputEnvelope = {
  data: Array<CarePlanBenefitEnrollmentCarryoverCreateManySourcePlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput = {
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutDestinationPlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutDestinationPlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyDestinationPlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput = {
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutSourcePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutSourcePlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManySourcePlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutBenefitInput = {
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
  create: CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutBenefitInput;
};

export type CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutDestinationPlanEnrollmentInput = {
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
  create: CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutDestinationPlanEnrollmentInput;
};

export type CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutSourcePlanEnrollmentInput = {
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
  create: CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutSourcePlanEnrollmentInput;
};

export type CarePlanBenefitEnrollmentCarryoverCreateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutForwardedCarePlanBenefitCarryoversInput>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutReceivedCarePlanBenefitCarryoversInput>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateWithoutDestinationPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  benefit: CareBenefitCreateNestedOneWithoutEnrollmentCarryoversInput;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutForwardedCarePlanBenefitCarryoversInput>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateWithoutSourcePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  benefit: CareBenefitCreateNestedOneWithoutEnrollmentCarryoversInput;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutReceivedCarePlanBenefitCarryoversInput>;
};

export type CarePlanBenefitEnrollmentCarryoverListRelationFilter = {
  every?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
  some?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
  none?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
};

export type CarePlanBenefitEnrollmentCarryoverMaxAggregateOutputType = {
  __typename?: 'CarePlanBenefitEnrollmentCarryoverMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  benefitId?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitEnrollmentCarryoverMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  sourcePlanEnrollmentId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  destinationPlanEnrollmentId?: Maybe<SortOrder>;
};

export type CarePlanBenefitEnrollmentCarryoverMinAggregateOutputType = {
  __typename?: 'CarePlanBenefitEnrollmentCarryoverMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  benefitId?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitEnrollmentCarryoverMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  sourcePlanEnrollmentId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  destinationPlanEnrollmentId?: Maybe<SortOrder>;
};

export type CarePlanBenefitEnrollmentCarryoverOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CarePlanBenefitEnrollmentCarryoverOrderByRelevanceFieldEnum {
  Id = 'id',
  BenefitId = 'benefitId',
  SourcePlanEnrollmentId = 'sourcePlanEnrollmentId',
  DestinationPlanEnrollmentId = 'destinationPlanEnrollmentId'
}

export type CarePlanBenefitEnrollmentCarryoverOrderByRelevanceInput = {
  fields: Array<CarePlanBenefitEnrollmentCarryoverOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CarePlanBenefitEnrollmentCarryoverOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  sourcePlanEnrollmentId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  destinationPlanEnrollmentId?: Maybe<SortOrder>;
  _count?: Maybe<CarePlanBenefitEnrollmentCarryoverCountOrderByAggregateInput>;
  _avg?: Maybe<CarePlanBenefitEnrollmentCarryoverAvgOrderByAggregateInput>;
  _max?: Maybe<CarePlanBenefitEnrollmentCarryoverMaxOrderByAggregateInput>;
  _min?: Maybe<CarePlanBenefitEnrollmentCarryoverMinOrderByAggregateInput>;
  _sum?: Maybe<CarePlanBenefitEnrollmentCarryoverSumOrderByAggregateInput>;
};

export type CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  sourcePlanEnrollmentId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  destinationPlanEnrollmentId?: Maybe<SortOrder>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CarePlanBenefitEnrollmentCarryoverOrderByRelevanceInput>;
};

export enum CarePlanBenefitEnrollmentCarryoverScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  BenefitId = 'benefitId',
  SourcePlanEnrollmentId = 'sourcePlanEnrollmentId',
  Amount = 'amount',
  DestinationPlanEnrollmentId = 'destinationPlanEnrollmentId'
}

export type CarePlanBenefitEnrollmentCarryoverScalarWhereInput = {
  AND?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
  OR?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
  NOT?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  benefitId?: Maybe<StringFilter>;
  sourcePlanEnrollmentId?: Maybe<StringNullableFilter>;
  amount?: Maybe<IntFilter>;
  destinationPlanEnrollmentId?: Maybe<StringNullableFilter>;
};

export type CarePlanBenefitEnrollmentCarryoverScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  sourcePlanEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  destinationPlanEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CarePlanBenefitEnrollmentCarryoverSourcePlanEnrollmentIdDestinationPlanEnrollmentIdBenefitIdCompoundUniqueInput = {
  sourcePlanEnrollmentId: Scalars['String'];
  destinationPlanEnrollmentId: Scalars['String'];
  benefitId: Scalars['String'];
};

export type CarePlanBenefitEnrollmentCarryoverSumAggregateOutputType = {
  __typename?: 'CarePlanBenefitEnrollmentCarryoverSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
};

export type CarePlanBenefitEnrollmentCarryoverSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  benefitId: Scalars['String'];
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput = {
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutDestinationPlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutDestinationPlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyDestinationPlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput = {
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutSourcePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutSourcePlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManySourcePlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutDestinationPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  benefitId: Scalars['String'];
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutSourcePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  benefitId: Scalars['String'];
  amount?: Maybe<Scalars['Int']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  benefitId?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  benefitId?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput = {
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput = {
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutDestinationPlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutDestinationPlanEnrollmentInput>>;
  upsert?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutDestinationPlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyDestinationPlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutDestinationPlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutDestinationPlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutEnrollmentCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutForwardedCarePlanBenefitCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  benefitId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutReceivedCarePlanBenefitCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  benefitId?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput = {
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutSourcePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutSourcePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutSourcePlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManySourcePlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutSourcePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutSourcePlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutDestinationPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  benefitId?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutSourcePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  benefitId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutEnrollmentCarryoversNestedInput>;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutForwardedCarePlanBenefitCarryoversNestedInput>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutReceivedCarePlanBenefitCarryoversNestedInput>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutBenefitInput = {
  where: CarePlanBenefitEnrollmentCarryoverScalarWhereInput;
  data: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutEnrollmentCarryoversInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutDestinationPlanEnrollmentInput = {
  where: CarePlanBenefitEnrollmentCarryoverScalarWhereInput;
  data: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutReceivedCarePlanBenefitCarryoversInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutSourcePlanEnrollmentInput = {
  where: CarePlanBenefitEnrollmentCarryoverScalarWhereInput;
  data: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutForwardedCarePlanBenefitCarryoversInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput = {
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput = {
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutDestinationPlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutDestinationPlanEnrollmentInput>>;
  upsert?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutDestinationPlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyDestinationPlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutDestinationPlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutDestinationPlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput = {
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutSourcePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutSourcePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutSourcePlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManySourcePlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutSourcePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutSourcePlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutBenefitInput = {
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
  data: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutBenefitInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutDestinationPlanEnrollmentInput = {
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
  data: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutDestinationPlanEnrollmentInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutSourcePlanEnrollmentInput = {
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
  data: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutSourcePlanEnrollmentInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutForwardedCarePlanBenefitCarryoversNestedInput>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutReceivedCarePlanBenefitCarryoversNestedInput>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateWithoutDestinationPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutEnrollmentCarryoversNestedInput>;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutForwardedCarePlanBenefitCarryoversNestedInput>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateWithoutSourcePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutEnrollmentCarryoversNestedInput>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutReceivedCarePlanBenefitCarryoversNestedInput>;
};

export type CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutBenefitInput = {
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
  update: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutBenefitInput;
  create: CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutBenefitInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutDestinationPlanEnrollmentInput = {
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
  update: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutDestinationPlanEnrollmentInput;
  create: CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutDestinationPlanEnrollmentInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutSourcePlanEnrollmentInput = {
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
  update: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutSourcePlanEnrollmentInput;
  create: CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutSourcePlanEnrollmentInput;
};

export type CarePlanBenefitEnrollmentCarryoverWhereInput = {
  AND?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereInput>>;
  OR?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereInput>>;
  NOT?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  benefitId?: Maybe<StringFilter>;
  sourcePlanEnrollmentId?: Maybe<StringNullableFilter>;
  amount?: Maybe<IntFilter>;
  destinationPlanEnrollmentId?: Maybe<StringNullableFilter>;
  benefit?: Maybe<CareBenefitWhereInput>;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
};

export type CarePlanBenefitEnrollmentCarryoverWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId_destinationPlanEnrollmentId_benefitId?: Maybe<CarePlanBenefitEnrollmentCarryoverSourcePlanEnrollmentIdDestinationPlanEnrollmentIdBenefitIdCompoundUniqueInput>;
};

export type CarePlanBenefitListRelationFilter = {
  every?: Maybe<CarePlanBenefitWhereInput>;
  some?: Maybe<CarePlanBenefitWhereInput>;
  none?: Maybe<CarePlanBenefitWhereInput>;
};

export type CarePlanBenefitMaxAggregateOutputType = {
  __typename?: 'CarePlanBenefitMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
};

export type CarePlanBenefitMinAggregateOutputType = {
  __typename?: 'CarePlanBenefitMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
};

export type CarePlanBenefitOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CarePlanBenefitOrderByRelevanceFieldEnum {
  Id = 'id',
  BenefitId = 'benefitId',
  PlanId = 'planId',
  ProjectedSavings = 'projectedSavings'
}

export type CarePlanBenefitOrderByRelevanceInput = {
  fields: Array<CarePlanBenefitOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CarePlanBenefitOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  _count?: Maybe<CarePlanBenefitCountOrderByAggregateInput>;
  _avg?: Maybe<CarePlanBenefitAvgOrderByAggregateInput>;
  _max?: Maybe<CarePlanBenefitMaxOrderByAggregateInput>;
  _min?: Maybe<CarePlanBenefitMinOrderByAggregateInput>;
  _sum?: Maybe<CarePlanBenefitSumOrderByAggregateInput>;
};

export type CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  plan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CarePlanBenefitOrderByRelevanceInput>;
};

export type CarePlanBenefitPlanIdDisplayOrderCompoundUniqueInput = {
  planId: Scalars['String'];
  displayOrder: Scalars['Int'];
};

export enum CarePlanBenefitScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IncludedUses = 'includedUses',
  ReportingExpectedValue = 'reportingExpectedValue',
  ReportingExpectedUsages = 'reportingExpectedUsages',
  ProvidedDiscountPercentage = 'providedDiscountPercentage',
  DisplayOrder = 'displayOrder',
  BenefitId = 'benefitId',
  PlanId = 'planId',
  ProjectedSavings = 'projectedSavings'
}

export type CarePlanBenefitScalarWhereInput = {
  AND?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
  OR?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
  NOT?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  includedUses?: Maybe<IntNullableFilter>;
  reportingExpectedValue?: Maybe<IntFilter>;
  reportingExpectedUsages?: Maybe<FloatFilter>;
  providedDiscountPercentage?: Maybe<IntNullableFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  benefitId?: Maybe<StringFilter>;
  planId?: Maybe<StringFilter>;
  projectedSavings?: Maybe<StringNullableFilter>;
};

export type CarePlanBenefitScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CarePlanBenefitScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CarePlanBenefitScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CarePlanBenefitScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  includedUses?: Maybe<IntNullableWithAggregatesFilter>;
  reportingExpectedValue?: Maybe<IntWithAggregatesFilter>;
  reportingExpectedUsages?: Maybe<FloatWithAggregatesFilter>;
  providedDiscountPercentage?: Maybe<IntNullableWithAggregatesFilter>;
  displayOrder?: Maybe<IntNullableWithAggregatesFilter>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  planId?: Maybe<StringWithAggregatesFilter>;
  projectedSavings?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CarePlanBenefitSumAggregateOutputType = {
  __typename?: 'CarePlanBenefitSumAggregateOutputType';
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
};

export type CarePlanBenefitSumOrderByAggregateInput = {
  includedUses?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
};

export type CarePlanBenefitUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  planId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
};

export type CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutPlanInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyPlanInputEnvelope>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
};

export type CarePlanBenefitUncheckedCreateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  planId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUncheckedCreateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<CarePlanBenefitUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
};

export type CarePlanBenefitUncheckedUpdateManyWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutPlanInput>>;
  upsert?: Maybe<Array<CarePlanBenefitUpsertWithWhereUniqueWithoutPlanInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyPlanInputEnvelope>;
  set?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitUpdateManyWithWhereWithoutPlanInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
};

export type CarePlanBenefitUncheckedUpdateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUncheckedUpdateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutPlanBenefitsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutPlanBenefitsNestedInput>;
};

export type CarePlanBenefitUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUpdateManyWithWhereWithoutBenefitInput = {
  where: CarePlanBenefitScalarWhereInput;
  data: CarePlanBenefitUncheckedUpdateManyWithoutPlanBenefitsInput;
};

export type CarePlanBenefitUpdateManyWithWhereWithoutPlanInput = {
  where: CarePlanBenefitScalarWhereInput;
  data: CarePlanBenefitUncheckedUpdateManyWithoutPlanBenefitsInput;
};

export type CarePlanBenefitUpdateManyWithoutBenefitNestedInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<CarePlanBenefitUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
};

export type CarePlanBenefitUpdateManyWithoutPlanNestedInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutPlanInput>>;
  upsert?: Maybe<Array<CarePlanBenefitUpsertWithWhereUniqueWithoutPlanInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyPlanInputEnvelope>;
  set?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitUpdateManyWithWhereWithoutPlanInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
};

export type CarePlanBenefitUpdateWithWhereUniqueWithoutBenefitInput = {
  where: CarePlanBenefitWhereUniqueInput;
  data: CarePlanBenefitUncheckedUpdateWithoutBenefitInput;
};

export type CarePlanBenefitUpdateWithWhereUniqueWithoutPlanInput = {
  where: CarePlanBenefitWhereUniqueInput;
  data: CarePlanBenefitUncheckedUpdateWithoutPlanInput;
};

export type CarePlanBenefitUpdateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutPlanBenefitsNestedInput>;
};

export type CarePlanBenefitUpdateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutPlanBenefitsNestedInput>;
};

export type CarePlanBenefitUpsertWithWhereUniqueWithoutBenefitInput = {
  where: CarePlanBenefitWhereUniqueInput;
  update: CarePlanBenefitUncheckedUpdateWithoutBenefitInput;
  create: CarePlanBenefitUncheckedCreateWithoutBenefitInput;
};

export type CarePlanBenefitUpsertWithWhereUniqueWithoutPlanInput = {
  where: CarePlanBenefitWhereUniqueInput;
  update: CarePlanBenefitUncheckedUpdateWithoutPlanInput;
  create: CarePlanBenefitUncheckedCreateWithoutPlanInput;
};

export type CarePlanBenefitWhereInput = {
  AND?: Maybe<Array<CarePlanBenefitWhereInput>>;
  OR?: Maybe<Array<CarePlanBenefitWhereInput>>;
  NOT?: Maybe<Array<CarePlanBenefitWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  includedUses?: Maybe<IntNullableFilter>;
  reportingExpectedValue?: Maybe<IntFilter>;
  reportingExpectedUsages?: Maybe<FloatFilter>;
  providedDiscountPercentage?: Maybe<IntNullableFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  benefitId?: Maybe<StringFilter>;
  planId?: Maybe<StringFilter>;
  projectedSavings?: Maybe<StringNullableFilter>;
  benefit?: Maybe<CareBenefitWhereInput>;
  plan?: Maybe<CarePlanWhereInput>;
};

export type CarePlanBenefitWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  planId_displayOrder?: Maybe<CarePlanBenefitPlanIdDisplayOrderCompoundUniqueInput>;
};

export type CarePlanCountAggregateOutputType = {
  __typename?: 'CarePlanCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  title: Scalars['Int'];
  description: Scalars['Int'];
  published: Scalars['Int'];
  isAcceptingPreEnrollments: Scalars['Int'];
  themeColor: Scalars['Int'];
  organizationId: Scalars['Int'];
  pricePerRenewal: Scalars['Int'];
  billingCycle: Scalars['Int'];
  renewalCycle: Scalars['Int'];
  stripePriceId: Scalars['Int'];
  targetSpecies: Scalars['Int'];
  earliestTargetPetAgeInMonths: Scalars['Int'];
  latestTargetPetAgeInMonths: Scalars['Int'];
  projectedSavings: Scalars['Int'];
  marketingInformation: Scalars['Int'];
  editorNotes: Scalars['Int'];
  signupFeePricing: Scalars['Int'];
  signupFeeStripeProductId: Scalars['Int'];
  associatedCarePlanProviderGroupId: Scalars['Int'];
  salesTaxCentsPerMonth: Scalars['Int'];
  flowAlias: Scalars['Int'];
  defaultRenewalSuccessorPlanId: Scalars['Int'];
  allowSelfRenewal: Scalars['Int'];
  publishStartDate: Scalars['Int'];
  publishEndDate: Scalars['Int'];
  planDiscount: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CarePlanCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  isAcceptingPreEnrollments?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  marketingInformation?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  defaultRenewalSuccessorPlanId?: Maybe<SortOrder>;
  allowSelfRenewal?: Maybe<SortOrder>;
  publishStartDate?: Maybe<SortOrder>;
  publishEndDate?: Maybe<SortOrder>;
  planDiscount?: Maybe<SortOrder>;
};

export type CarePlanCountOutputType = {
  __typename?: 'CarePlanCountOutputType';
  enrollments: Scalars['Int'];
  planBenefits: Scalars['Int'];
  renewalPredecessorPlan: Scalars['Int'];
  explicitRenewalSuccessors: Scalars['Int'];
  selectedByRenewalEmailEnrollments: Scalars['Int'];
  currentPlanEnrollmentSupportEvents: Scalars['Int'];
  newPlanEnrollmentSupportEvents: Scalars['Int'];
};

export type CarePlanCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanCreateManyAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
};

export type CarePlanCreateManyAssociatedCarePlanProviderGroupInputEnvelope = {
  data: Array<CarePlanCreateManyAssociatedCarePlanProviderGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanCreateManyDefaultRenewalSuccessorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
};

export type CarePlanCreateManyDefaultRenewalSuccessorPlanInputEnvelope = {
  data: Array<CarePlanCreateManyDefaultRenewalSuccessorPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
};

export type CarePlanCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
};

export type CarePlanCreateManyOrganizationInputEnvelope = {
  data: Array<CarePlanCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  create?: Maybe<Array<CarePlanCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CarePlanCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
};

export type CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput = {
  create?: Maybe<Array<CarePlanCreateWithoutDefaultRenewalSuccessorPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutDefaultRenewalSuccessorPlanInput>>;
  createMany?: Maybe<CarePlanCreateManyDefaultRenewalSuccessorPlanInputEnvelope>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
};

export type CarePlanCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CarePlanCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
};

export type CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutCurrentPlanEnrollmentSupportEventsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutCurrentPlanEnrollmentSupportEventsInput>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
};

export type CarePlanCreateNestedOneWithoutEnrollmentsInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutEnrollmentsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutEnrollmentsInput>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
};

export type CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutExplicitRenewalSuccessorsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutExplicitRenewalSuccessorsInput>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
};

export type CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutNewPlanEnrollmentSupportEventsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutNewPlanEnrollmentSupportEventsInput>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
};

export type CarePlanCreateNestedOneWithoutPlanBenefitsInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutPlanBenefitsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutPlanBenefitsInput>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
};

export type CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutRenewalPredecessorPlanInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutRenewalPredecessorPlanInput>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
};

export type CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutSelectedByRenewalEmailEnrollmentsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutSelectedByRenewalEmailEnrollmentsInput>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
};

export type CarePlanCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
};

export type CarePlanCreateOrConnectWithoutCurrentPlanEnrollmentSupportEventsInput = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanUncheckedCreateWithoutCurrentPlanEnrollmentSupportEventsInput;
};

export type CarePlanCreateOrConnectWithoutDefaultRenewalSuccessorPlanInput = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanUncheckedCreateWithoutDefaultRenewalSuccessorPlanInput;
};

export type CarePlanCreateOrConnectWithoutEnrollmentsInput = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanUncheckedCreateWithoutEnrollmentsInput;
};

export type CarePlanCreateOrConnectWithoutExplicitRenewalSuccessorsInput = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanUncheckedCreateWithoutExplicitRenewalSuccessorsInput;
};

export type CarePlanCreateOrConnectWithoutNewPlanEnrollmentSupportEventsInput = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanUncheckedCreateWithoutNewPlanEnrollmentSupportEventsInput;
};

export type CarePlanCreateOrConnectWithoutOrganizationInput = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanUncheckedCreateWithoutOrganizationInput;
};

export type CarePlanCreateOrConnectWithoutPlanBenefitsInput = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanUncheckedCreateWithoutPlanBenefitsInput;
};

export type CarePlanCreateOrConnectWithoutRenewalPredecessorPlanInput = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanUncheckedCreateWithoutRenewalPredecessorPlanInput;
};

export type CarePlanCreateOrConnectWithoutSelectedByRenewalEmailEnrollmentsInput = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanUncheckedCreateWithoutSelectedByRenewalEmailEnrollmentsInput;
};

export type CarePlanCreateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanCreateWithoutCurrentPlanEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanCreateWithoutDefaultRenewalSuccessorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanCreateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanCreateWithoutExplicitRenewalSuccessorsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanCreateWithoutNewPlanEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
};

export type CarePlanCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanCreateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanCreateWithoutRenewalPredecessorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanCreateWithoutSelectedByRenewalEmailEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
};

export enum CarePlanCycleType {
  Monthly = 'Monthly',
  Semianually = 'Semianually',
  Annually = 'Annually'
}

export type CarePlanEnrollment = {
  __typename?: 'CarePlanEnrollment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status: EnrollmentStatus;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews: Scalars['Boolean'];
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPet;
  plan: CarePlan;
  careStripeSubscription?: Maybe<CareStripeSubscription>;
  enrollmentToStripeInvoiceItems: Array<CareEnrollmentToStripeInvoiceItem>;
  enrollmentPayments: Array<CareEnrollmentPayment>;
  associatedUsages: Array<CareBenefitUsage>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlan>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlan>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollment>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollment>;
  careSubscriptionTrueUps: Array<CareSubscriptionTrueUp>;
  forwardedCarePlanBenefitCarryovers: Array<CarePlanBenefitEnrollmentCarryover>;
  receivedCarePlanBenefitCarryovers: Array<CarePlanBenefitEnrollmentCarryover>;
  enrollmentSupportEvents: Array<EnrollmentSupportEvent>;
  _count: CarePlanEnrollmentCountOutputType;
};


export type CarePlanEnrollmentEnrollmentToStripeInvoiceItemsArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  orderBy?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>;
};


export type CarePlanEnrollmentEnrollmentPaymentsArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
  orderBy?: Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareEnrollmentPaymentScalarFieldEnum>;
};


export type CarePlanEnrollmentAssociatedUsagesArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
};


export type CarePlanEnrollmentCareSubscriptionTrueUpsArgs = {
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
  orderBy?: Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareSubscriptionTrueUpScalarFieldEnum>;
};


export type CarePlanEnrollmentForwardedCarePlanBenefitCarryoversArgs = {
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
  orderBy?: Maybe<CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanBenefitEnrollmentCarryoverScalarFieldEnum>;
};


export type CarePlanEnrollmentReceivedCarePlanBenefitCarryoversArgs = {
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
  orderBy?: Maybe<CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanBenefitEnrollmentCarryoverScalarFieldEnum>;
};


export type CarePlanEnrollmentEnrollmentSupportEventsArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
};

export type CarePlanEnrollmentCountAggregateOutputType = {
  __typename?: 'CarePlanEnrollmentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  startDate: Scalars['Int'];
  expirationDate: Scalars['Int'];
  cancelledAt: Scalars['Int'];
  status: Scalars['Int'];
  organizationPetId: Scalars['Int'];
  planId: Scalars['Int'];
  careStripeSubscriptionId: Scalars['Int'];
  stripeSubscriptionItemId: Scalars['Int'];
  autoRenews: Scalars['Int'];
  renewalEmailNoticeSentAt: Scalars['Int'];
  renewalEmailSelectedTargetPlanId: Scalars['Int'];
  explicitRenewalSuccessorPlanId: Scalars['Int'];
  renewalPredecessorEnrollmentId: Scalars['Int'];
  renewalRejectionReason: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CarePlanEnrollmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  autoRenews?: Maybe<SortOrder>;
  renewalEmailNoticeSentAt?: Maybe<SortOrder>;
  renewalEmailSelectedTargetPlanId?: Maybe<SortOrder>;
  explicitRenewalSuccessorPlanId?: Maybe<SortOrder>;
  renewalPredecessorEnrollmentId?: Maybe<SortOrder>;
  renewalRejectionReason?: Maybe<SortOrder>;
};

export type CarePlanEnrollmentCountOutputType = {
  __typename?: 'CarePlanEnrollmentCountOutputType';
  enrollmentToStripeInvoiceItems: Scalars['Int'];
  enrollmentPayments: Scalars['Int'];
  associatedUsages: Scalars['Int'];
  careSubscriptionTrueUps: Scalars['Int'];
  forwardedCarePlanBenefitCarryovers: Scalars['Int'];
  receivedCarePlanBenefitCarryovers: Scalars['Int'];
  enrollmentSupportEvents: Scalars['Int'];
};

export type CarePlanEnrollmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateManyCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
};

export type CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope = {
  data: Array<CarePlanEnrollmentCreateManyCareStripeSubscriptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEnrollmentCreateManyExplicitRenewalSucccesorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
};

export type CarePlanEnrollmentCreateManyExplicitRenewalSucccesorPlanInputEnvelope = {
  data: Array<CarePlanEnrollmentCreateManyExplicitRenewalSucccesorPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEnrollmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
};

export type CarePlanEnrollmentCreateManyOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
};

export type CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope = {
  data: Array<CarePlanEnrollmentCreateManyOrganizationPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEnrollmentCreateManyPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
};

export type CarePlanEnrollmentCreateManyPlanInputEnvelope = {
  data: Array<CarePlanEnrollmentCreateManyPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEnrollmentCreateManyRenewalEmailSelectedTargetPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
};

export type CarePlanEnrollmentCreateManyRenewalEmailSelectedTargetPlanInputEnvelope = {
  data: Array<CarePlanEnrollmentCreateManyRenewalEmailSelectedTargetPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutExplicitRenewalSucccesorPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutExplicitRenewalSucccesorPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyExplicitRenewalSucccesorPlanInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentCreateNestedManyWithoutPlanInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyPlanInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutRenewalEmailSelectedTargetPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutRenewalEmailSelectedTargetPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyRenewalEmailSelectedTargetPlanInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutAssociatedUsagesInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutCareSubscriptionTrueUpsInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentSupportEventsInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentSupportEventsInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutForwardedCarePlanBenefitCarryoversInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutForwardedCarePlanBenefitCarryoversInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutForwardedCarePlanBenefitCarryoversInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutReceivedCarePlanBenefitCarryoversInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutReceivedCarePlanBenefitCarryoversInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutReceivedCarePlanBenefitCarryoversInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutRenewalPredecessorEnrollmentInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutRenewalPredecessorEnrollmentInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutRenewalSuccessorEnrollmentInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutRenewalSuccessorEnrollmentInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
};

export type CarePlanEnrollmentCreateOrConnectWithoutAssociatedUsagesInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutCareSubscriptionTrueUpsInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutEnrollmentSupportEventsInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentSupportEventsInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutExplicitRenewalSucccesorPlanInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutExplicitRenewalSucccesorPlanInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutForwardedCarePlanBenefitCarryoversInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutForwardedCarePlanBenefitCarryoversInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutOrganizationPetInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutPlanInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutPlanInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutReceivedCarePlanBenefitCarryoversInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutReceivedCarePlanBenefitCarryoversInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutRenewalEmailSelectedTargetPlanInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutRenewalEmailSelectedTargetPlanInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutRenewalPredecessorEnrollmentInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutRenewalPredecessorEnrollmentInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutRenewalSuccessorEnrollmentInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutRenewalSuccessorEnrollmentInput;
};

export type CarePlanEnrollmentCreateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutExplicitRenewalSucccesorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutForwardedCarePlanBenefitCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutReceivedCarePlanBenefitCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutRenewalEmailSelectedTargetPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutRenewalPredecessorEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutRenewalSuccessorEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentListRelationFilter = {
  every?: Maybe<CarePlanEnrollmentWhereInput>;
  some?: Maybe<CarePlanEnrollmentWhereInput>;
  none?: Maybe<CarePlanEnrollmentWhereInput>;
};

export type CarePlanEnrollmentMaxAggregateOutputType = {
  __typename?: 'CarePlanEnrollmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
};

export type CarePlanEnrollmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  autoRenews?: Maybe<SortOrder>;
  renewalEmailNoticeSentAt?: Maybe<SortOrder>;
  renewalEmailSelectedTargetPlanId?: Maybe<SortOrder>;
  explicitRenewalSuccessorPlanId?: Maybe<SortOrder>;
  renewalPredecessorEnrollmentId?: Maybe<SortOrder>;
  renewalRejectionReason?: Maybe<SortOrder>;
};

export type CarePlanEnrollmentMinAggregateOutputType = {
  __typename?: 'CarePlanEnrollmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
};

export type CarePlanEnrollmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  autoRenews?: Maybe<SortOrder>;
  renewalEmailNoticeSentAt?: Maybe<SortOrder>;
  renewalEmailSelectedTargetPlanId?: Maybe<SortOrder>;
  explicitRenewalSuccessorPlanId?: Maybe<SortOrder>;
  renewalPredecessorEnrollmentId?: Maybe<SortOrder>;
  renewalRejectionReason?: Maybe<SortOrder>;
};

export type CarePlanEnrollmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CarePlanEnrollmentOrderByRelevanceFieldEnum {
  Id = 'id',
  OrganizationPetId = 'organizationPetId',
  PlanId = 'planId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
  RenewalEmailSelectedTargetPlanId = 'renewalEmailSelectedTargetPlanId',
  ExplicitRenewalSuccessorPlanId = 'explicitRenewalSuccessorPlanId',
  RenewalPredecessorEnrollmentId = 'renewalPredecessorEnrollmentId'
}

export type CarePlanEnrollmentOrderByRelevanceInput = {
  fields: Array<CarePlanEnrollmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CarePlanEnrollmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  autoRenews?: Maybe<SortOrder>;
  renewalEmailNoticeSentAt?: Maybe<SortOrder>;
  renewalEmailSelectedTargetPlanId?: Maybe<SortOrder>;
  explicitRenewalSuccessorPlanId?: Maybe<SortOrder>;
  renewalPredecessorEnrollmentId?: Maybe<SortOrder>;
  renewalRejectionReason?: Maybe<SortOrder>;
  _count?: Maybe<CarePlanEnrollmentCountOrderByAggregateInput>;
  _max?: Maybe<CarePlanEnrollmentMaxOrderByAggregateInput>;
  _min?: Maybe<CarePlanEnrollmentMinOrderByAggregateInput>;
};

export type CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  autoRenews?: Maybe<SortOrder>;
  renewalEmailNoticeSentAt?: Maybe<SortOrder>;
  renewalEmailSelectedTargetPlanId?: Maybe<SortOrder>;
  explicitRenewalSuccessorPlanId?: Maybe<SortOrder>;
  renewalPredecessorEnrollmentId?: Maybe<SortOrder>;
  renewalRejectionReason?: Maybe<SortOrder>;
  organizationPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  plan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByRelationAggregateInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentOrderByRelationAggregateInput>;
  associatedUsages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpOrderByRelationAggregateInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverOrderByRelationAggregateInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverOrderByRelationAggregateInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  _relevance?: Maybe<CarePlanEnrollmentOrderByRelevanceInput>;
};

export type CarePlanEnrollmentRelationFilter = {
  is?: Maybe<CarePlanEnrollmentWhereInput>;
  isNot?: Maybe<CarePlanEnrollmentWhereInput>;
};

export enum CarePlanEnrollmentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StartDate = 'startDate',
  ExpirationDate = 'expirationDate',
  CancelledAt = 'cancelledAt',
  Status = 'status',
  OrganizationPetId = 'organizationPetId',
  PlanId = 'planId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
  AutoRenews = 'autoRenews',
  RenewalEmailNoticeSentAt = 'renewalEmailNoticeSentAt',
  RenewalEmailSelectedTargetPlanId = 'renewalEmailSelectedTargetPlanId',
  ExplicitRenewalSuccessorPlanId = 'explicitRenewalSuccessorPlanId',
  RenewalPredecessorEnrollmentId = 'renewalPredecessorEnrollmentId',
  RenewalRejectionReason = 'renewalRejectionReason'
}

export type CarePlanEnrollmentScalarWhereInput = {
  AND?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  OR?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  NOT?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  startDate?: Maybe<DateTimeFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumEnrollmentStatusFilter>;
  organizationPetId?: Maybe<StringFilter>;
  planId?: Maybe<StringFilter>;
  careStripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
  autoRenews?: Maybe<BoolFilter>;
  renewalEmailNoticeSentAt?: Maybe<DateTimeNullableFilter>;
  renewalEmailSelectedTargetPlanId?: Maybe<StringNullableFilter>;
  explicitRenewalSuccessorPlanId?: Maybe<StringNullableFilter>;
  renewalPredecessorEnrollmentId?: Maybe<StringNullableFilter>;
  renewalRejectionReason?: Maybe<EnumRenewalRejectionReasonNullableFilter>;
};

export type CarePlanEnrollmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CarePlanEnrollmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CarePlanEnrollmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CarePlanEnrollmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  startDate?: Maybe<DateTimeWithAggregatesFilter>;
  expirationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  cancelledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumEnrollmentStatusWithAggregatesFilter>;
  organizationPetId?: Maybe<StringWithAggregatesFilter>;
  planId?: Maybe<StringWithAggregatesFilter>;
  careStripeSubscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableWithAggregatesFilter>;
  autoRenews?: Maybe<BoolWithAggregatesFilter>;
  renewalEmailNoticeSentAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  renewalEmailSelectedTargetPlanId?: Maybe<StringNullableWithAggregatesFilter>;
  explicitRenewalSuccessorPlanId?: Maybe<StringNullableWithAggregatesFilter>;
  renewalPredecessorEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  renewalRejectionReason?: Maybe<EnumRenewalRejectionReasonNullableWithAggregatesFilter>;
};

export type CarePlanEnrollmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutExplicitRenewalSucccesorPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutExplicitRenewalSucccesorPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyExplicitRenewalSucccesorPlanInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyPlanInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutRenewalEmailSelectedTargetPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutRenewalEmailSelectedTargetPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyRenewalEmailSelectedTargetPlanInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutRenewalPredecessorEnrollmentInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutRenewalPredecessorEnrollmentInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutExplicitRenewalSucccesorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutForwardedCarePlanBenefitCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutReceivedCarePlanBenefitCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutRenewalEmailSelectedTargetPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutRenewalPredecessorEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutRenewalSuccessorEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutExplicitRenewalSucccesorPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutExplicitRenewalSucccesorPlanInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutExplicitRenewalSucccesorPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyExplicitRenewalSucccesorPlanInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutExplicitRenewalSucccesorPlanInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutExplicitRenewalSucccesorPlanInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSuccessorsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutPlanInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyPlanInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutPlanInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutRenewalEmailSelectedTargetPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutRenewalEmailSelectedTargetPlanInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutRenewalEmailSelectedTargetPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyRenewalEmailSelectedTargetPlanInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutRenewalEmailSelectedTargetPlanInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutRenewalEmailSelectedTargetPlanInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutSelectedByRenewalEmailEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
};

export type CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutRenewalPredecessorEnrollmentInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutRenewalPredecessorEnrollmentInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutRenewalPredecessorEnrollmentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutRenewalPredecessorEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutExplicitRenewalSucccesorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutForwardedCarePlanBenefitCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutReceivedCarePlanBenefitCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutRenewalEmailSelectedTargetPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutRenewalPredecessorEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutRenewalSuccessorEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
};

export type CarePlanEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput = {
  where: CarePlanEnrollmentScalarWhereInput;
  data: CarePlanEnrollmentUncheckedUpdateManyWithoutCarePlanEnrollmentsInput;
};

export type CarePlanEnrollmentUpdateManyWithWhereWithoutExplicitRenewalSucccesorPlanInput = {
  where: CarePlanEnrollmentScalarWhereInput;
  data: CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSuccessorsInput;
};

export type CarePlanEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput = {
  where: CarePlanEnrollmentScalarWhereInput;
  data: CarePlanEnrollmentUncheckedUpdateManyWithoutCarePlanEnrollmentsInput;
};

export type CarePlanEnrollmentUpdateManyWithWhereWithoutPlanInput = {
  where: CarePlanEnrollmentScalarWhereInput;
  data: CarePlanEnrollmentUncheckedUpdateManyWithoutEnrollmentsInput;
};

export type CarePlanEnrollmentUpdateManyWithWhereWithoutRenewalEmailSelectedTargetPlanInput = {
  where: CarePlanEnrollmentScalarWhereInput;
  data: CarePlanEnrollmentUncheckedUpdateManyWithoutSelectedByRenewalEmailEnrollmentsInput;
};

export type CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutExplicitRenewalSucccesorPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutExplicitRenewalSucccesorPlanInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutExplicitRenewalSucccesorPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyExplicitRenewalSucccesorPlanInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutExplicitRenewalSucccesorPlanInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutExplicitRenewalSucccesorPlanInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUpdateManyWithoutPlanNestedInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutPlanInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyPlanInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutPlanInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutRenewalEmailSelectedTargetPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutRenewalEmailSelectedTargetPlanInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutRenewalEmailSelectedTargetPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyRenewalEmailSelectedTargetPlanInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutRenewalEmailSelectedTargetPlanInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutRenewalEmailSelectedTargetPlanInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutCareSubscriptionTrueUpsInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutCareSubscriptionTrueUpsInput>;
};

export type CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentSupportEventsInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentSupportEventsInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutEnrollmentSupportEventsInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentSupportEventsInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutAssociatedUsagesInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutAssociatedUsagesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutEnrollmentPaymentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutEnrollmentToStripeInvoiceItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutForwardedCarePlanBenefitCarryoversNestedInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutForwardedCarePlanBenefitCarryoversInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutForwardedCarePlanBenefitCarryoversInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutForwardedCarePlanBenefitCarryoversInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutForwardedCarePlanBenefitCarryoversInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutReceivedCarePlanBenefitCarryoversNestedInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutReceivedCarePlanBenefitCarryoversInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutReceivedCarePlanBenefitCarryoversInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutReceivedCarePlanBenefitCarryoversInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutReceivedCarePlanBenefitCarryoversInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutRenewalPredecessorEnrollmentInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutRenewalPredecessorEnrollmentInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutRenewalPredecessorEnrollmentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutRenewalPredecessorEnrollmentInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutRenewalSuccessorEnrollmentInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutRenewalSuccessorEnrollmentInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutRenewalSuccessorEnrollmentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutRenewalSuccessorEnrollmentInput>;
};

export type CarePlanEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  data: CarePlanEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput;
};

export type CarePlanEnrollmentUpdateWithWhereUniqueWithoutExplicitRenewalSucccesorPlanInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  data: CarePlanEnrollmentUncheckedUpdateWithoutExplicitRenewalSucccesorPlanInput;
};

export type CarePlanEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  data: CarePlanEnrollmentUncheckedUpdateWithoutOrganizationPetInput;
};

export type CarePlanEnrollmentUpdateWithWhereUniqueWithoutPlanInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  data: CarePlanEnrollmentUncheckedUpdateWithoutPlanInput;
};

export type CarePlanEnrollmentUpdateWithWhereUniqueWithoutRenewalEmailSelectedTargetPlanInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  data: CarePlanEnrollmentUncheckedUpdateWithoutRenewalEmailSelectedTargetPlanInput;
};

export type CarePlanEnrollmentUpdateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateWithoutEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateWithoutExplicitRenewalSucccesorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateWithoutForwardedCarePlanBenefitCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateWithoutReceivedCarePlanBenefitCarryoversInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateWithoutRenewalEmailSelectedTargetPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateWithoutRenewalPredecessorEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpdateWithoutRenewalSuccessorEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
};

export type CarePlanEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type CarePlanEnrollmentUpsertWithWhereUniqueWithoutExplicitRenewalSucccesorPlanInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutExplicitRenewalSucccesorPlanInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutExplicitRenewalSucccesorPlanInput;
};

export type CarePlanEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutOrganizationPetInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutOrganizationPetInput;
};

export type CarePlanEnrollmentUpsertWithWhereUniqueWithoutPlanInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutPlanInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutPlanInput;
};

export type CarePlanEnrollmentUpsertWithWhereUniqueWithoutRenewalEmailSelectedTargetPlanInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutRenewalEmailSelectedTargetPlanInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutRenewalEmailSelectedTargetPlanInput;
};

export type CarePlanEnrollmentUpsertWithoutAssociatedUsagesInput = {
  update: CarePlanEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput;
};

export type CarePlanEnrollmentUpsertWithoutCareSubscriptionTrueUpsInput = {
  update: CarePlanEnrollmentUncheckedUpdateWithoutCareSubscriptionTrueUpsInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
};

export type CarePlanEnrollmentUpsertWithoutEnrollmentPaymentsInput = {
  update: CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput;
};

export type CarePlanEnrollmentUpsertWithoutEnrollmentSupportEventsInput = {
  update: CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentSupportEventsInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentSupportEventsInput;
};

export type CarePlanEnrollmentUpsertWithoutEnrollmentToStripeInvoiceItemsInput = {
  update: CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CarePlanEnrollmentUpsertWithoutForwardedCarePlanBenefitCarryoversInput = {
  update: CarePlanEnrollmentUncheckedUpdateWithoutForwardedCarePlanBenefitCarryoversInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutForwardedCarePlanBenefitCarryoversInput;
};

export type CarePlanEnrollmentUpsertWithoutReceivedCarePlanBenefitCarryoversInput = {
  update: CarePlanEnrollmentUncheckedUpdateWithoutReceivedCarePlanBenefitCarryoversInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutReceivedCarePlanBenefitCarryoversInput;
};

export type CarePlanEnrollmentUpsertWithoutRenewalPredecessorEnrollmentInput = {
  update: CarePlanEnrollmentUncheckedUpdateWithoutRenewalPredecessorEnrollmentInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutRenewalPredecessorEnrollmentInput;
};

export type CarePlanEnrollmentUpsertWithoutRenewalSuccessorEnrollmentInput = {
  update: CarePlanEnrollmentUncheckedUpdateWithoutRenewalSuccessorEnrollmentInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutRenewalSuccessorEnrollmentInput;
};

export type CarePlanEnrollmentWhereInput = {
  AND?: Maybe<Array<CarePlanEnrollmentWhereInput>>;
  OR?: Maybe<Array<CarePlanEnrollmentWhereInput>>;
  NOT?: Maybe<Array<CarePlanEnrollmentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  startDate?: Maybe<DateTimeFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumEnrollmentStatusFilter>;
  organizationPetId?: Maybe<StringFilter>;
  planId?: Maybe<StringFilter>;
  careStripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
  autoRenews?: Maybe<BoolFilter>;
  renewalEmailNoticeSentAt?: Maybe<DateTimeNullableFilter>;
  renewalEmailSelectedTargetPlanId?: Maybe<StringNullableFilter>;
  explicitRenewalSuccessorPlanId?: Maybe<StringNullableFilter>;
  renewalPredecessorEnrollmentId?: Maybe<StringNullableFilter>;
  renewalRejectionReason?: Maybe<EnumRenewalRejectionReasonNullableFilter>;
  organizationPet?: Maybe<OrganizationPetWhereInput>;
  plan?: Maybe<CarePlanWhereInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemListRelationFilter>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentListRelationFilter>;
  associatedUsages?: Maybe<CareBenefitUsageListRelationFilter>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanWhereInput>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanWhereInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpListRelationFilter>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverListRelationFilter>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverListRelationFilter>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventListRelationFilter>;
};

export type CarePlanEnrollmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanListRelationFilter = {
  every?: Maybe<CarePlanWhereInput>;
  some?: Maybe<CarePlanWhereInput>;
  none?: Maybe<CarePlanWhereInput>;
};

export type CarePlanMaxAggregateOutputType = {
  __typename?: 'CarePlanMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
};

export type CarePlanMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  isAcceptingPreEnrollments?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  defaultRenewalSuccessorPlanId?: Maybe<SortOrder>;
  allowSelfRenewal?: Maybe<SortOrder>;
  publishStartDate?: Maybe<SortOrder>;
  publishEndDate?: Maybe<SortOrder>;
  planDiscount?: Maybe<SortOrder>;
};

export type CarePlanMinAggregateOutputType = {
  __typename?: 'CarePlanMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
};

export type CarePlanMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  isAcceptingPreEnrollments?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  defaultRenewalSuccessorPlanId?: Maybe<SortOrder>;
  allowSelfRenewal?: Maybe<SortOrder>;
  publishStartDate?: Maybe<SortOrder>;
  publishEndDate?: Maybe<SortOrder>;
  planDiscount?: Maybe<SortOrder>;
};

export type CarePlanOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CarePlanOrderByRelevanceFieldEnum {
  Id = 'id',
  Title = 'title',
  Description = 'description',
  ThemeColor = 'themeColor',
  OrganizationId = 'organizationId',
  StripePriceId = 'stripePriceId',
  ProjectedSavings = 'projectedSavings',
  EditorNotes = 'editorNotes',
  SignupFeeStripeProductId = 'signupFeeStripeProductId',
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  FlowAlias = 'flowAlias',
  DefaultRenewalSuccessorPlanId = 'defaultRenewalSuccessorPlanId'
}

export type CarePlanOrderByRelevanceInput = {
  fields: Array<CarePlanOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CarePlanOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  isAcceptingPreEnrollments?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  marketingInformation?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  defaultRenewalSuccessorPlanId?: Maybe<SortOrder>;
  allowSelfRenewal?: Maybe<SortOrder>;
  publishStartDate?: Maybe<SortOrder>;
  publishEndDate?: Maybe<SortOrder>;
  planDiscount?: Maybe<SortOrder>;
  _count?: Maybe<CarePlanCountOrderByAggregateInput>;
  _avg?: Maybe<CarePlanAvgOrderByAggregateInput>;
  _max?: Maybe<CarePlanMaxOrderByAggregateInput>;
  _min?: Maybe<CarePlanMinOrderByAggregateInput>;
  _sum?: Maybe<CarePlanSumOrderByAggregateInput>;
};

export type CarePlanOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  isAcceptingPreEnrollments?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  marketingInformation?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  defaultRenewalSuccessorPlanId?: Maybe<SortOrder>;
  allowSelfRenewal?: Maybe<SortOrder>;
  publishStartDate?: Maybe<SortOrder>;
  publishEndDate?: Maybe<SortOrder>;
  planDiscount?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  enrollments?: Maybe<CarePlanEnrollmentOrderByRelationAggregateInput>;
  planBenefits?: Maybe<CarePlanBenefitOrderByRelationAggregateInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  renewalPredecessorPlan?: Maybe<CarePlanOrderByRelationAggregateInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentOrderByRelationAggregateInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentOrderByRelationAggregateInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  _relevance?: Maybe<CarePlanOrderByRelevanceInput>;
};

export type CarePlanProviderGroup = {
  __typename?: 'CarePlanProviderGroup';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus: CareProgramLaunchStatus;
  organization?: Maybe<Organization>;
  paymentProcessorClinic?: Maybe<Clinic>;
  participatingClinics: Array<Clinic>;
  associatedCareBenefits: Array<CareBenefit>;
  associatedPlans: Array<CarePlan>;
  associatedAddonPackages: Array<CareAddonPackage>;
  associatedSubscriptions: Array<CareStripeSubscription>;
  _count: CarePlanProviderGroupCountOutputType;
};


export type CarePlanProviderGroupParticipatingClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};


export type CarePlanProviderGroupAssociatedCareBenefitsArgs = {
  where?: Maybe<CareBenefitWhereInput>;
  orderBy?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitScalarFieldEnum>;
};


export type CarePlanProviderGroupAssociatedPlansArgs = {
  where?: Maybe<CarePlanWhereInput>;
  orderBy?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanScalarFieldEnum>;
};


export type CarePlanProviderGroupAssociatedAddonPackagesArgs = {
  where?: Maybe<CareAddonPackageWhereInput>;
  orderBy?: Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAddonPackageScalarFieldEnum>;
};


export type CarePlanProviderGroupAssociatedSubscriptionsArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
  orderBy?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareStripeSubscriptionScalarFieldEnum>;
};

export type CarePlanProviderGroupCountAggregateOutputType = {
  __typename?: 'CarePlanProviderGroupCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  organizationId: Scalars['Int'];
  paymentProcessorClinicId: Scalars['Int'];
  launchStatus: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CarePlanProviderGroupCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  launchStatus?: Maybe<SortOrder>;
};

export type CarePlanProviderGroupCountOutputType = {
  __typename?: 'CarePlanProviderGroupCountOutputType';
  participatingClinics: Scalars['Int'];
  associatedCareBenefits: Scalars['Int'];
  associatedPlans: Scalars['Int'];
  associatedAddonPackages: Scalars['Int'];
  associatedSubscriptions: Scalars['Int'];
};

export type CarePlanProviderGroupCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
};

export type CarePlanProviderGroupCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
};

export type CarePlanProviderGroupCreateManyOrganizationInputEnvelope = {
  data: Array<CarePlanProviderGroupCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CarePlanProviderGroupCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CarePlanProviderGroupCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanProviderGroupCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
};

export type CarePlanProviderGroupCreateNestedOneWithoutAssociatedAddonPackagesInput = {
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedAddonPackagesInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedAddonPackagesInput>;
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
};

export type CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput = {
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedCareBenefitsInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedCareBenefitsInput>;
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
};

export type CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput = {
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedPlansInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedPlansInput>;
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
};

export type CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput = {
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedSubscriptionsInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedSubscriptionsInput>;
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
};

export type CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput = {
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutParticipatingClinicsInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutParticipatingClinicsInput>;
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
};

export type CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput = {
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutPaymentProcessorClinicInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutPaymentProcessorClinicInput>;
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
};

export type CarePlanProviderGroupCreateOrConnectWithoutAssociatedAddonPackagesInput = {
  where: CarePlanProviderGroupWhereUniqueInput;
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedAddonPackagesInput;
};

export type CarePlanProviderGroupCreateOrConnectWithoutAssociatedCareBenefitsInput = {
  where: CarePlanProviderGroupWhereUniqueInput;
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedCareBenefitsInput;
};

export type CarePlanProviderGroupCreateOrConnectWithoutAssociatedPlansInput = {
  where: CarePlanProviderGroupWhereUniqueInput;
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedPlansInput;
};

export type CarePlanProviderGroupCreateOrConnectWithoutAssociatedSubscriptionsInput = {
  where: CarePlanProviderGroupWhereUniqueInput;
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedSubscriptionsInput;
};

export type CarePlanProviderGroupCreateOrConnectWithoutOrganizationInput = {
  where: CarePlanProviderGroupWhereUniqueInput;
  create: CarePlanProviderGroupUncheckedCreateWithoutOrganizationInput;
};

export type CarePlanProviderGroupCreateOrConnectWithoutParticipatingClinicsInput = {
  where: CarePlanProviderGroupWhereUniqueInput;
  create: CarePlanProviderGroupUncheckedCreateWithoutParticipatingClinicsInput;
};

export type CarePlanProviderGroupCreateOrConnectWithoutPaymentProcessorClinicInput = {
  where: CarePlanProviderGroupWhereUniqueInput;
  create: CarePlanProviderGroupUncheckedCreateWithoutPaymentProcessorClinicInput;
};

export type CarePlanProviderGroupCreateWithoutAssociatedAddonPackagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupCreateWithoutAssociatedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupCreateWithoutAssociatedPlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupCreateWithoutAssociatedSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupCreateWithoutParticipatingClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupCreateWithoutPaymentProcessorClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupListRelationFilter = {
  every?: Maybe<CarePlanProviderGroupWhereInput>;
  some?: Maybe<CarePlanProviderGroupWhereInput>;
  none?: Maybe<CarePlanProviderGroupWhereInput>;
};

export type CarePlanProviderGroupMaxAggregateOutputType = {
  __typename?: 'CarePlanProviderGroupMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
};

export type CarePlanProviderGroupMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  launchStatus?: Maybe<SortOrder>;
};

export type CarePlanProviderGroupMinAggregateOutputType = {
  __typename?: 'CarePlanProviderGroupMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
};

export type CarePlanProviderGroupMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  launchStatus?: Maybe<SortOrder>;
};

export type CarePlanProviderGroupOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CarePlanProviderGroupOrderByRelevanceFieldEnum {
  Id = 'id',
  OrganizationId = 'organizationId',
  PaymentProcessorClinicId = 'paymentProcessorClinicId'
}

export type CarePlanProviderGroupOrderByRelevanceInput = {
  fields: Array<CarePlanProviderGroupOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CarePlanProviderGroupOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  launchStatus?: Maybe<SortOrder>;
  _count?: Maybe<CarePlanProviderGroupCountOrderByAggregateInput>;
  _max?: Maybe<CarePlanProviderGroupMaxOrderByAggregateInput>;
  _min?: Maybe<CarePlanProviderGroupMinOrderByAggregateInput>;
};

export type CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  launchStatus?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  paymentProcessorClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  participatingClinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  associatedCareBenefits?: Maybe<CareBenefitOrderByRelationAggregateInput>;
  associatedPlans?: Maybe<CarePlanOrderByRelationAggregateInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageOrderByRelationAggregateInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionOrderByRelationAggregateInput>;
  _relevance?: Maybe<CarePlanProviderGroupOrderByRelevanceInput>;
};

export type CarePlanProviderGroupRelationFilter = {
  is?: Maybe<CarePlanProviderGroupWhereInput>;
  isNot?: Maybe<CarePlanProviderGroupWhereInput>;
};

export enum CarePlanProviderGroupScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  OrganizationId = 'organizationId',
  PaymentProcessorClinicId = 'paymentProcessorClinicId',
  LaunchStatus = 'launchStatus'
}

export type CarePlanProviderGroupScalarWhereInput = {
  AND?: Maybe<Array<CarePlanProviderGroupScalarWhereInput>>;
  OR?: Maybe<Array<CarePlanProviderGroupScalarWhereInput>>;
  NOT?: Maybe<Array<CarePlanProviderGroupScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  paymentProcessorClinicId?: Maybe<StringNullableFilter>;
  launchStatus?: Maybe<EnumCareProgramLaunchStatusFilter>;
};

export type CarePlanProviderGroupScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CarePlanProviderGroupScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CarePlanProviderGroupScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CarePlanProviderGroupScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  organizationId?: Maybe<StringNullableWithAggregatesFilter>;
  paymentProcessorClinicId?: Maybe<StringNullableWithAggregatesFilter>;
  launchStatus?: Maybe<EnumCareProgramLaunchStatusWithAggregatesFilter>;
};

export type CarePlanProviderGroupUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CarePlanProviderGroupCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CarePlanProviderGroupCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanProviderGroupCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
};

export type CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput = {
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutPaymentProcessorClinicInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutPaymentProcessorClinicInput>;
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
};

export type CarePlanProviderGroupUncheckedCreateWithoutAssociatedAddonPackagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupUncheckedCreateWithoutAssociatedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupUncheckedCreateWithoutAssociatedPlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupUncheckedCreateWithoutAssociatedSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupUncheckedCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupUncheckedCreateWithoutParticipatingClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupUncheckedCreateWithoutPaymentProcessorClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
};

export type CarePlanProviderGroupUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
};

export type CarePlanProviderGroupUncheckedUpdateManyWithoutCarePlanProviderGroupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
};

export type CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<CarePlanProviderGroupCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CarePlanProviderGroupCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CarePlanProviderGroupUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanProviderGroupCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanProviderGroupUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CarePlanProviderGroupUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CarePlanProviderGroupScalarWhereInput>>;
};

export type CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput = {
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutPaymentProcessorClinicInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutPaymentProcessorClinicInput>;
  upsert?: Maybe<CarePlanProviderGroupUpsertWithoutPaymentProcessorClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  update?: Maybe<CarePlanProviderGroupUncheckedUpdateWithoutPaymentProcessorClinicInput>;
};

export type CarePlanProviderGroupUncheckedUpdateWithoutAssociatedAddonPackagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUncheckedUpdateWithoutAssociatedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUncheckedUpdateWithoutAssociatedPlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUncheckedUpdateWithoutAssociatedSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUncheckedUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUncheckedUpdateWithoutParticipatingClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUncheckedUpdateWithoutPaymentProcessorClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
};

export type CarePlanProviderGroupUpdateManyWithWhereWithoutOrganizationInput = {
  where: CarePlanProviderGroupScalarWhereInput;
  data: CarePlanProviderGroupUncheckedUpdateManyWithoutCarePlanProviderGroupsInput;
};

export type CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<CarePlanProviderGroupCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CarePlanProviderGroupCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CarePlanProviderGroupUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanProviderGroupCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanProviderGroupUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CarePlanProviderGroupUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CarePlanProviderGroupScalarWhereInput>>;
};

export type CarePlanProviderGroupUpdateOneWithoutAssociatedAddonPackagesNestedInput = {
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedAddonPackagesInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedAddonPackagesInput>;
  upsert?: Maybe<CarePlanProviderGroupUpsertWithoutAssociatedAddonPackagesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  update?: Maybe<CarePlanProviderGroupUncheckedUpdateWithoutAssociatedAddonPackagesInput>;
};

export type CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput = {
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedCareBenefitsInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedCareBenefitsInput>;
  upsert?: Maybe<CarePlanProviderGroupUpsertWithoutAssociatedCareBenefitsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  update?: Maybe<CarePlanProviderGroupUncheckedUpdateWithoutAssociatedCareBenefitsInput>;
};

export type CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput = {
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedPlansInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedPlansInput>;
  upsert?: Maybe<CarePlanProviderGroupUpsertWithoutAssociatedPlansInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  update?: Maybe<CarePlanProviderGroupUncheckedUpdateWithoutAssociatedPlansInput>;
};

export type CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput = {
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedSubscriptionsInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedSubscriptionsInput>;
  upsert?: Maybe<CarePlanProviderGroupUpsertWithoutAssociatedSubscriptionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  update?: Maybe<CarePlanProviderGroupUncheckedUpdateWithoutAssociatedSubscriptionsInput>;
};

export type CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput = {
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutParticipatingClinicsInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutParticipatingClinicsInput>;
  upsert?: Maybe<CarePlanProviderGroupUpsertWithoutParticipatingClinicsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  update?: Maybe<CarePlanProviderGroupUncheckedUpdateWithoutParticipatingClinicsInput>;
};

export type CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput = {
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutPaymentProcessorClinicInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutPaymentProcessorClinicInput>;
  upsert?: Maybe<CarePlanProviderGroupUpsertWithoutPaymentProcessorClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  update?: Maybe<CarePlanProviderGroupUncheckedUpdateWithoutPaymentProcessorClinicInput>;
};

export type CarePlanProviderGroupUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: CarePlanProviderGroupWhereUniqueInput;
  data: CarePlanProviderGroupUncheckedUpdateWithoutOrganizationInput;
};

export type CarePlanProviderGroupUpdateWithoutAssociatedAddonPackagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUpdateWithoutAssociatedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUpdateWithoutAssociatedPlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUpdateWithoutAssociatedSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUpdateWithoutParticipatingClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUpdateWithoutPaymentProcessorClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
};

export type CarePlanProviderGroupUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: CarePlanProviderGroupWhereUniqueInput;
  update: CarePlanProviderGroupUncheckedUpdateWithoutOrganizationInput;
  create: CarePlanProviderGroupUncheckedCreateWithoutOrganizationInput;
};

export type CarePlanProviderGroupUpsertWithoutAssociatedAddonPackagesInput = {
  update: CarePlanProviderGroupUncheckedUpdateWithoutAssociatedAddonPackagesInput;
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedAddonPackagesInput;
};

export type CarePlanProviderGroupUpsertWithoutAssociatedCareBenefitsInput = {
  update: CarePlanProviderGroupUncheckedUpdateWithoutAssociatedCareBenefitsInput;
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedCareBenefitsInput;
};

export type CarePlanProviderGroupUpsertWithoutAssociatedPlansInput = {
  update: CarePlanProviderGroupUncheckedUpdateWithoutAssociatedPlansInput;
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedPlansInput;
};

export type CarePlanProviderGroupUpsertWithoutAssociatedSubscriptionsInput = {
  update: CarePlanProviderGroupUncheckedUpdateWithoutAssociatedSubscriptionsInput;
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedSubscriptionsInput;
};

export type CarePlanProviderGroupUpsertWithoutParticipatingClinicsInput = {
  update: CarePlanProviderGroupUncheckedUpdateWithoutParticipatingClinicsInput;
  create: CarePlanProviderGroupUncheckedCreateWithoutParticipatingClinicsInput;
};

export type CarePlanProviderGroupUpsertWithoutPaymentProcessorClinicInput = {
  update: CarePlanProviderGroupUncheckedUpdateWithoutPaymentProcessorClinicInput;
  create: CarePlanProviderGroupUncheckedCreateWithoutPaymentProcessorClinicInput;
};

export type CarePlanProviderGroupWhereInput = {
  AND?: Maybe<Array<CarePlanProviderGroupWhereInput>>;
  OR?: Maybe<Array<CarePlanProviderGroupWhereInput>>;
  NOT?: Maybe<Array<CarePlanProviderGroupWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  paymentProcessorClinicId?: Maybe<StringNullableFilter>;
  launchStatus?: Maybe<EnumCareProgramLaunchStatusFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  paymentProcessorClinic?: Maybe<ClinicWhereInput>;
  participatingClinics?: Maybe<ClinicListRelationFilter>;
  associatedCareBenefits?: Maybe<CareBenefitListRelationFilter>;
  associatedPlans?: Maybe<CarePlanListRelationFilter>;
  associatedAddonPackages?: Maybe<CareAddonPackageListRelationFilter>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionListRelationFilter>;
};

export type CarePlanProviderGroupWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type CarePlanRelationFilter = {
  is?: Maybe<CarePlanWhereInput>;
  isNot?: Maybe<CarePlanWhereInput>;
};

export enum CarePlanScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Title = 'title',
  Description = 'description',
  Published = 'published',
  IsAcceptingPreEnrollments = 'isAcceptingPreEnrollments',
  ThemeColor = 'themeColor',
  OrganizationId = 'organizationId',
  PricePerRenewal = 'pricePerRenewal',
  BillingCycle = 'billingCycle',
  RenewalCycle = 'renewalCycle',
  StripePriceId = 'stripePriceId',
  TargetSpecies = 'targetSpecies',
  EarliestTargetPetAgeInMonths = 'earliestTargetPetAgeInMonths',
  LatestTargetPetAgeInMonths = 'latestTargetPetAgeInMonths',
  ProjectedSavings = 'projectedSavings',
  MarketingInformation = 'marketingInformation',
  EditorNotes = 'editorNotes',
  SignupFeePricing = 'signupFeePricing',
  SignupFeeStripeProductId = 'signupFeeStripeProductId',
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  SalesTaxCentsPerMonth = 'salesTaxCentsPerMonth',
  FlowAlias = 'flowAlias',
  DefaultRenewalSuccessorPlanId = 'defaultRenewalSuccessorPlanId',
  AllowSelfRenewal = 'allowSelfRenewal',
  PublishStartDate = 'publishStartDate',
  PublishEndDate = 'publishEndDate',
  PlanDiscount = 'planDiscount'
}

export type CarePlanScalarWhereInput = {
  AND?: Maybe<Array<CarePlanScalarWhereInput>>;
  OR?: Maybe<Array<CarePlanScalarWhereInput>>;
  NOT?: Maybe<Array<CarePlanScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  published?: Maybe<BoolFilter>;
  isAcceptingPreEnrollments?: Maybe<BoolFilter>;
  themeColor?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringFilter>;
  pricePerRenewal?: Maybe<IntFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  stripePriceId?: Maybe<StringNullableFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  projectedSavings?: Maybe<StringNullableFilter>;
  marketingInformation?: Maybe<JsonNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
  signupFeePricing?: Maybe<IntNullableFilter>;
  signupFeeStripeProductId?: Maybe<StringNullableFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  salesTaxCentsPerMonth?: Maybe<IntFilter>;
  flowAlias?: Maybe<StringNullableFilter>;
  defaultRenewalSuccessorPlanId?: Maybe<StringNullableFilter>;
  allowSelfRenewal?: Maybe<BoolFilter>;
  publishStartDate?: Maybe<DateTimeNullableFilter>;
  publishEndDate?: Maybe<DateTimeNullableFilter>;
  planDiscount?: Maybe<IntNullableFilter>;
};

export type CarePlanScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CarePlanScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CarePlanScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CarePlanScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  published?: Maybe<BoolWithAggregatesFilter>;
  isAcceptingPreEnrollments?: Maybe<BoolWithAggregatesFilter>;
  themeColor?: Maybe<StringNullableWithAggregatesFilter>;
  organizationId?: Maybe<StringWithAggregatesFilter>;
  pricePerRenewal?: Maybe<IntWithAggregatesFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeWithAggregatesFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeWithAggregatesFilter>;
  stripePriceId?: Maybe<StringNullableWithAggregatesFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableWithAggregatesFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  projectedSavings?: Maybe<StringNullableWithAggregatesFilter>;
  marketingInformation?: Maybe<JsonNullableWithAggregatesFilter>;
  editorNotes?: Maybe<StringWithAggregatesFilter>;
  signupFeePricing?: Maybe<IntNullableWithAggregatesFilter>;
  signupFeeStripeProductId?: Maybe<StringNullableWithAggregatesFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  salesTaxCentsPerMonth?: Maybe<IntWithAggregatesFilter>;
  flowAlias?: Maybe<StringNullableWithAggregatesFilter>;
  defaultRenewalSuccessorPlanId?: Maybe<StringNullableWithAggregatesFilter>;
  allowSelfRenewal?: Maybe<BoolWithAggregatesFilter>;
  publishStartDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  publishEndDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  planDiscount?: Maybe<IntNullableWithAggregatesFilter>;
};

export type CarePlanSumAggregateOutputType = {
  __typename?: 'CarePlanSumAggregateOutputType';
  pricePerRenewal?: Maybe<Scalars['Int']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  planDiscount?: Maybe<Scalars['Int']>;
};

export type CarePlanSumOrderByAggregateInput = {
  pricePerRenewal?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  planDiscount?: Maybe<SortOrder>;
};

export type CarePlanUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  create?: Maybe<Array<CarePlanCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CarePlanCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
};

export type CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput = {
  create?: Maybe<Array<CarePlanCreateWithoutDefaultRenewalSuccessorPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutDefaultRenewalSuccessorPlanInput>>;
  createMany?: Maybe<CarePlanCreateManyDefaultRenewalSuccessorPlanInputEnvelope>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
};

export type CarePlanUncheckedCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CarePlanCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
};

export type CarePlanUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanUncheckedCreateWithoutCurrentPlanEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanUncheckedCreateWithoutDefaultRenewalSuccessorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanUncheckedCreateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanUncheckedCreateWithoutExplicitRenewalSuccessorsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanUncheckedCreateWithoutNewPlanEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
};

export type CarePlanUncheckedCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanUncheckedCreateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanUncheckedCreateWithoutRenewalPredecessorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanUncheckedCreateWithoutSelectedByRenewalEmailEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
};

export type CarePlanUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
};

export type CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  create?: Maybe<Array<CarePlanCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CarePlanUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CarePlanCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  set?: Maybe<Array<CarePlanWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CarePlanUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  deleteMany?: Maybe<Array<CarePlanScalarWhereInput>>;
};

export type CarePlanUncheckedUpdateManyWithoutAssociatedPlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
};

export type CarePlanUncheckedUpdateManyWithoutCarePlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
};

export type CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput = {
  create?: Maybe<Array<CarePlanCreateWithoutDefaultRenewalSuccessorPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutDefaultRenewalSuccessorPlanInput>>;
  upsert?: Maybe<Array<CarePlanUpsertWithWhereUniqueWithoutDefaultRenewalSuccessorPlanInput>>;
  createMany?: Maybe<CarePlanCreateManyDefaultRenewalSuccessorPlanInputEnvelope>;
  set?: Maybe<Array<CarePlanWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanUpdateWithWhereUniqueWithoutDefaultRenewalSuccessorPlanInput>>;
  updateMany?: Maybe<Array<CarePlanUpdateManyWithWhereWithoutDefaultRenewalSuccessorPlanInput>>;
  deleteMany?: Maybe<Array<CarePlanScalarWhereInput>>;
};

export type CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<CarePlanCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CarePlanUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<CarePlanWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CarePlanUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CarePlanScalarWhereInput>>;
};

export type CarePlanUncheckedUpdateManyWithoutRenewalPredecessorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
};

export type CarePlanUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUncheckedUpdateWithoutCurrentPlanEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUncheckedUpdateWithoutDefaultRenewalSuccessorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUncheckedUpdateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUncheckedUpdateWithoutExplicitRenewalSuccessorsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUncheckedUpdateWithoutNewPlanEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
};

export type CarePlanUncheckedUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUncheckedUpdateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUncheckedUpdateWithoutRenewalPredecessorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUncheckedUpdateWithoutSelectedByRenewalEmailEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
};

export type CarePlanUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput = {
  where: CarePlanScalarWhereInput;
  data: CarePlanUncheckedUpdateManyWithoutAssociatedPlansInput;
};

export type CarePlanUpdateManyWithWhereWithoutDefaultRenewalSuccessorPlanInput = {
  where: CarePlanScalarWhereInput;
  data: CarePlanUncheckedUpdateManyWithoutRenewalPredecessorPlanInput;
};

export type CarePlanUpdateManyWithWhereWithoutOrganizationInput = {
  where: CarePlanScalarWhereInput;
  data: CarePlanUncheckedUpdateManyWithoutCarePlansInput;
};

export type CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  create?: Maybe<Array<CarePlanCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CarePlanUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CarePlanCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  set?: Maybe<Array<CarePlanWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CarePlanUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  deleteMany?: Maybe<Array<CarePlanScalarWhereInput>>;
};

export type CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput = {
  create?: Maybe<Array<CarePlanCreateWithoutDefaultRenewalSuccessorPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutDefaultRenewalSuccessorPlanInput>>;
  upsert?: Maybe<Array<CarePlanUpsertWithWhereUniqueWithoutDefaultRenewalSuccessorPlanInput>>;
  createMany?: Maybe<CarePlanCreateManyDefaultRenewalSuccessorPlanInputEnvelope>;
  set?: Maybe<Array<CarePlanWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanUpdateWithWhereUniqueWithoutDefaultRenewalSuccessorPlanInput>>;
  updateMany?: Maybe<Array<CarePlanUpdateManyWithWhereWithoutDefaultRenewalSuccessorPlanInput>>;
  deleteMany?: Maybe<Array<CarePlanScalarWhereInput>>;
};

export type CarePlanUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<CarePlanCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CarePlanUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<CarePlanWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CarePlanUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CarePlanScalarWhereInput>>;
};

export type CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutEnrollmentsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutEnrollmentsInput>;
  upsert?: Maybe<CarePlanUpsertWithoutEnrollmentsInput>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutEnrollmentsInput>;
};

export type CarePlanUpdateOneRequiredWithoutPlanBenefitsNestedInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutPlanBenefitsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutPlanBenefitsInput>;
  upsert?: Maybe<CarePlanUpsertWithoutPlanBenefitsInput>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutPlanBenefitsInput>;
};

export type CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutCurrentPlanEnrollmentSupportEventsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutCurrentPlanEnrollmentSupportEventsInput>;
  upsert?: Maybe<CarePlanUpsertWithoutCurrentPlanEnrollmentSupportEventsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutCurrentPlanEnrollmentSupportEventsInput>;
};

export type CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutExplicitRenewalSuccessorsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutExplicitRenewalSuccessorsInput>;
  upsert?: Maybe<CarePlanUpsertWithoutExplicitRenewalSuccessorsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutExplicitRenewalSuccessorsInput>;
};

export type CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutNewPlanEnrollmentSupportEventsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutNewPlanEnrollmentSupportEventsInput>;
  upsert?: Maybe<CarePlanUpsertWithoutNewPlanEnrollmentSupportEventsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutNewPlanEnrollmentSupportEventsInput>;
};

export type CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutRenewalPredecessorPlanInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutRenewalPredecessorPlanInput>;
  upsert?: Maybe<CarePlanUpsertWithoutRenewalPredecessorPlanInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutRenewalPredecessorPlanInput>;
};

export type CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutSelectedByRenewalEmailEnrollmentsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutSelectedByRenewalEmailEnrollmentsInput>;
  upsert?: Maybe<CarePlanUpsertWithoutSelectedByRenewalEmailEnrollmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutSelectedByRenewalEmailEnrollmentsInput>;
};

export type CarePlanUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  where: CarePlanWhereUniqueInput;
  data: CarePlanUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
};

export type CarePlanUpdateWithWhereUniqueWithoutDefaultRenewalSuccessorPlanInput = {
  where: CarePlanWhereUniqueInput;
  data: CarePlanUncheckedUpdateWithoutDefaultRenewalSuccessorPlanInput;
};

export type CarePlanUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: CarePlanWhereUniqueInput;
  data: CarePlanUncheckedUpdateWithoutOrganizationInput;
};

export type CarePlanUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUpdateWithoutCurrentPlanEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUpdateWithoutDefaultRenewalSuccessorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUpdateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUpdateWithoutExplicitRenewalSuccessorsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUpdateWithoutNewPlanEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
};

export type CarePlanUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUpdateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUpdateWithoutRenewalPredecessorPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUpdateWithoutSelectedByRenewalEmailEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  flowAlias?: Maybe<Scalars['String']>;
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  planDiscount?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
};

export type CarePlanUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  where: CarePlanWhereUniqueInput;
  update: CarePlanUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
  create: CarePlanUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
};

export type CarePlanUpsertWithWhereUniqueWithoutDefaultRenewalSuccessorPlanInput = {
  where: CarePlanWhereUniqueInput;
  update: CarePlanUncheckedUpdateWithoutDefaultRenewalSuccessorPlanInput;
  create: CarePlanUncheckedCreateWithoutDefaultRenewalSuccessorPlanInput;
};

export type CarePlanUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: CarePlanWhereUniqueInput;
  update: CarePlanUncheckedUpdateWithoutOrganizationInput;
  create: CarePlanUncheckedCreateWithoutOrganizationInput;
};

export type CarePlanUpsertWithoutCurrentPlanEnrollmentSupportEventsInput = {
  update: CarePlanUncheckedUpdateWithoutCurrentPlanEnrollmentSupportEventsInput;
  create: CarePlanUncheckedCreateWithoutCurrentPlanEnrollmentSupportEventsInput;
};

export type CarePlanUpsertWithoutEnrollmentsInput = {
  update: CarePlanUncheckedUpdateWithoutEnrollmentsInput;
  create: CarePlanUncheckedCreateWithoutEnrollmentsInput;
};

export type CarePlanUpsertWithoutExplicitRenewalSuccessorsInput = {
  update: CarePlanUncheckedUpdateWithoutExplicitRenewalSuccessorsInput;
  create: CarePlanUncheckedCreateWithoutExplicitRenewalSuccessorsInput;
};

export type CarePlanUpsertWithoutNewPlanEnrollmentSupportEventsInput = {
  update: CarePlanUncheckedUpdateWithoutNewPlanEnrollmentSupportEventsInput;
  create: CarePlanUncheckedCreateWithoutNewPlanEnrollmentSupportEventsInput;
};

export type CarePlanUpsertWithoutPlanBenefitsInput = {
  update: CarePlanUncheckedUpdateWithoutPlanBenefitsInput;
  create: CarePlanUncheckedCreateWithoutPlanBenefitsInput;
};

export type CarePlanUpsertWithoutRenewalPredecessorPlanInput = {
  update: CarePlanUncheckedUpdateWithoutRenewalPredecessorPlanInput;
  create: CarePlanUncheckedCreateWithoutRenewalPredecessorPlanInput;
};

export type CarePlanUpsertWithoutSelectedByRenewalEmailEnrollmentsInput = {
  update: CarePlanUncheckedUpdateWithoutSelectedByRenewalEmailEnrollmentsInput;
  create: CarePlanUncheckedCreateWithoutSelectedByRenewalEmailEnrollmentsInput;
};

export type CarePlanWhereInput = {
  AND?: Maybe<Array<CarePlanWhereInput>>;
  OR?: Maybe<Array<CarePlanWhereInput>>;
  NOT?: Maybe<Array<CarePlanWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  published?: Maybe<BoolFilter>;
  isAcceptingPreEnrollments?: Maybe<BoolFilter>;
  themeColor?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringFilter>;
  pricePerRenewal?: Maybe<IntFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  stripePriceId?: Maybe<StringNullableFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  projectedSavings?: Maybe<StringNullableFilter>;
  marketingInformation?: Maybe<JsonNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
  signupFeePricing?: Maybe<IntNullableFilter>;
  signupFeeStripeProductId?: Maybe<StringNullableFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  salesTaxCentsPerMonth?: Maybe<IntFilter>;
  flowAlias?: Maybe<StringNullableFilter>;
  defaultRenewalSuccessorPlanId?: Maybe<StringNullableFilter>;
  allowSelfRenewal?: Maybe<BoolFilter>;
  publishStartDate?: Maybe<DateTimeNullableFilter>;
  publishEndDate?: Maybe<DateTimeNullableFilter>;
  planDiscount?: Maybe<IntNullableFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  enrollments?: Maybe<CarePlanEnrollmentListRelationFilter>;
  planBenefits?: Maybe<CarePlanBenefitListRelationFilter>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupWhereInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanWhereInput>;
  renewalPredecessorPlan?: Maybe<CarePlanListRelationFilter>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentListRelationFilter>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentListRelationFilter>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventListRelationFilter>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventListRelationFilter>;
};

export type CarePlanWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum CareProgramLaunchStatus {
  Draft = 'Draft',
  Prelaunch = 'Prelaunch',
  Live = 'Live',
  Churning = 'Churning',
  Archived = 'Archived'
}

export type CareStripeSubscription = {
  __typename?: 'CareStripeSubscription';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription: Scalars['Boolean'];
  /** If this subscription originated somewhere else, this allows us to track it */
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments: Array<CareAddonEnrollment>;
  carePlanEnrollments: Array<CarePlanEnrollment>;
  activeSubscriber?: Maybe<OrganizationPetParent>;
  subscriptionOwner: OrganizationPetParent;
  stripePaymentMethod?: Maybe<StripePaymentMethod>;
  invoices: Array<StripeInvoice>;
  careBenefitUsageFollowups: Array<CareBenefitUsageFollowup>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  associatedPet?: Maybe<OrganizationPet>;
  careSubscriptionTrueUps: Array<CareSubscriptionTrueUp>;
  _count: CareStripeSubscriptionCountOutputType;
};


export type CareStripeSubscriptionCareAddonEnrollmentsArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
  orderBy?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAddonEnrollmentScalarFieldEnum>;
};


export type CareStripeSubscriptionCarePlanEnrollmentsArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanEnrollmentScalarFieldEnum>;
};


export type CareStripeSubscriptionInvoicesArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
  orderBy?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeInvoiceScalarFieldEnum>;
};


export type CareStripeSubscriptionCareBenefitUsageFollowupsArgs = {
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
  orderBy?: Maybe<CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageFollowupScalarFieldEnum>;
};


export type CareStripeSubscriptionCareSubscriptionTrueUpsArgs = {
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
  orderBy?: Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareSubscriptionTrueUpScalarFieldEnum>;
};

export type CareStripeSubscriptionAvgAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionAvgAggregateOutputType';
  upcomingTotalPrice?: Maybe<Scalars['Float']>;
};

export type CareStripeSubscriptionAvgOrderByAggregateInput = {
  upcomingTotalPrice?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionCountAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  paymentStatus: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  stripeSubscriptionId: Scalars['Int'];
  subscriptionOwnerId: Scalars['Int'];
  nextBillingDate: Scalars['Int'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId: Scalars['Int'];
  associatedCarePlanProviderGroupId: Scalars['Int'];
  associatedPetId: Scalars['Int'];
  isTestSubscription: Scalars['Int'];
  parentCareStripeSubscriptionId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareStripeSubscriptionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  nextBillingDate?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  associatedPetId?: Maybe<SortOrder>;
  isTestSubscription?: Maybe<SortOrder>;
  parentCareStripeSubscriptionId?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionCountOutputType = {
  __typename?: 'CareStripeSubscriptionCountOutputType';
  careAddonEnrollments: Scalars['Int'];
  carePlanEnrollments: Scalars['Int'];
  invoices: Scalars['Int'];
  careBenefitUsageFollowups: Scalars['Int'];
  careSubscriptionTrueUps: Scalars['Int'];
};

export type CareStripeSubscriptionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionCreateManyAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionCreateManyAssociatedCarePlanProviderGroupInputEnvelope = {
  data: Array<CareStripeSubscriptionCreateManyAssociatedCarePlanProviderGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareStripeSubscriptionCreateManyAssociatedPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionCreateManyAssociatedPetInputEnvelope = {
  data: Array<CareStripeSubscriptionCreateManyAssociatedPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareStripeSubscriptionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionCreateManyStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope = {
  data: Array<CareStripeSubscriptionCreateManyStripePaymentMethodInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareStripeSubscriptionCreateManySubscriptionOwnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope = {
  data: Array<CareStripeSubscriptionCreateManySubscriptionOwnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
};

export type CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedPetInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedPetInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedPetInputEnvelope>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
};

export type CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
};

export type CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutActiveSubscriberInput>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCareAddonEnrollmentsInput>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutCareBenefitUsageFollowupsInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCareBenefitUsageFollowupsInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCareBenefitUsageFollowupsInput>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCarePlanEnrollmentsInput>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutCareSubscriptionTrueUpsInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutInvoicesInput>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
};

export type CareStripeSubscriptionCreateOrConnectWithoutActiveSubscriberInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutAssociatedPetInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutAssociatedPetInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutCareAddonEnrollmentsInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutCareBenefitUsageFollowupsInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutCareBenefitUsageFollowupsInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutCarePlanEnrollmentsInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutCareSubscriptionTrueUpsInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutInvoicesInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutStripePaymentMethodInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutSubscriptionOwnerInput;
};

export type CareStripeSubscriptionCreateWithoutActiveSubscriberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionCreateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionCreateWithoutAssociatedPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionCreateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionCreateWithoutCareBenefitUsageFollowupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionCreateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionCreateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
};

export type CareStripeSubscriptionCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionCreateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionListRelationFilter = {
  every?: Maybe<CareStripeSubscriptionWhereInput>;
  some?: Maybe<CareStripeSubscriptionWhereInput>;
  none?: Maybe<CareStripeSubscriptionWhereInput>;
};

export type CareStripeSubscriptionMaxAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  nextBillingDate?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  associatedPetId?: Maybe<SortOrder>;
  isTestSubscription?: Maybe<SortOrder>;
  parentCareStripeSubscriptionId?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionMinAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  nextBillingDate?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  associatedPetId?: Maybe<SortOrder>;
  isTestSubscription?: Maybe<SortOrder>;
  parentCareStripeSubscriptionId?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareStripeSubscriptionOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeCustomerId = 'stripeCustomerId',
  StripeSubscriptionId = 'stripeSubscriptionId',
  SubscriptionOwnerId = 'subscriptionOwnerId',
  StripePaymentMethodId = 'stripePaymentMethodId',
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  AssociatedPetId = 'associatedPetId',
  ParentCareStripeSubscriptionId = 'parentCareStripeSubscriptionId'
}

export type CareStripeSubscriptionOrderByRelevanceInput = {
  fields: Array<CareStripeSubscriptionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareStripeSubscriptionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  nextBillingDate?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  associatedPetId?: Maybe<SortOrder>;
  isTestSubscription?: Maybe<SortOrder>;
  parentCareStripeSubscriptionId?: Maybe<SortOrder>;
  _count?: Maybe<CareStripeSubscriptionCountOrderByAggregateInput>;
  _avg?: Maybe<CareStripeSubscriptionAvgOrderByAggregateInput>;
  _max?: Maybe<CareStripeSubscriptionMaxOrderByAggregateInput>;
  _min?: Maybe<CareStripeSubscriptionMinOrderByAggregateInput>;
  _sum?: Maybe<CareStripeSubscriptionSumOrderByAggregateInput>;
};

export type CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  nextBillingDate?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  associatedPetId?: Maybe<SortOrder>;
  isTestSubscription?: Maybe<SortOrder>;
  parentCareStripeSubscriptionId?: Maybe<SortOrder>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentOrderByRelationAggregateInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentOrderByRelationAggregateInput>;
  activeSubscriber?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  invoices?: Maybe<StripeInvoiceOrderByRelationAggregateInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupOrderByRelationAggregateInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>;
  associatedPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpOrderByRelationAggregateInput>;
  _relevance?: Maybe<CareStripeSubscriptionOrderByRelevanceInput>;
};

export type CareStripeSubscriptionRelationFilter = {
  is?: Maybe<CareStripeSubscriptionWhereInput>;
  isNot?: Maybe<CareStripeSubscriptionWhereInput>;
};

export enum CareStripeSubscriptionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PaymentStatus = 'paymentStatus',
  StripeCustomerId = 'stripeCustomerId',
  StripeSubscriptionId = 'stripeSubscriptionId',
  SubscriptionOwnerId = 'subscriptionOwnerId',
  NextBillingDate = 'nextBillingDate',
  UpcomingTotalPrice = 'upcomingTotalPrice',
  StripePaymentMethodId = 'stripePaymentMethodId',
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  AssociatedPetId = 'associatedPetId',
  IsTestSubscription = 'isTestSubscription',
  ParentCareStripeSubscriptionId = 'parentCareStripeSubscriptionId'
}

export type CareStripeSubscriptionScalarWhereInput = {
  AND?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  OR?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  NOT?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  paymentStatus?: Maybe<EnumCareSubscriptionPaymentStatusNullableFilter>;
  stripeCustomerId?: Maybe<StringFilter>;
  stripeSubscriptionId?: Maybe<StringFilter>;
  subscriptionOwnerId?: Maybe<StringFilter>;
  nextBillingDate?: Maybe<DateTimeFilter>;
  upcomingTotalPrice?: Maybe<IntFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  associatedPetId?: Maybe<StringNullableFilter>;
  isTestSubscription?: Maybe<BoolFilter>;
  parentCareStripeSubscriptionId?: Maybe<StringNullableFilter>;
};

export type CareStripeSubscriptionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareStripeSubscriptionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareStripeSubscriptionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareStripeSubscriptionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  paymentStatus?: Maybe<EnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringWithAggregatesFilter>;
  stripeSubscriptionId?: Maybe<StringWithAggregatesFilter>;
  subscriptionOwnerId?: Maybe<StringWithAggregatesFilter>;
  nextBillingDate?: Maybe<DateTimeWithAggregatesFilter>;
  upcomingTotalPrice?: Maybe<IntWithAggregatesFilter>;
  stripePaymentMethodId?: Maybe<StringNullableWithAggregatesFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  associatedPetId?: Maybe<StringNullableWithAggregatesFilter>;
  isTestSubscription?: Maybe<BoolWithAggregatesFilter>;
  parentCareStripeSubscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareStripeSubscriptionSumAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionSumAggregateOutputType';
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
};

export type CareStripeSubscriptionSumOrderByAggregateInput = {
  upcomingTotalPrice?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
};

export type CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedPetInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedPetInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedPetInputEnvelope>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
};

export type CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
};

export type CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutAssociatedPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutCareBenefitUsageFollowupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutSubscriptionOwnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutAllCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedPetInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedPetInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutAssociatedPetInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedPetInputEnvelope>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutAssociatedPetInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutAssociatedPetInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodNestedInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerNestedInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutSubscriptionOwnerInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutSubscriptionOwnerInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutSubscriptionOwnerInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutActiveSubscriberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutAssociatedPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutCareBenefitUsageFollowupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutSubscriptionOwnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput = {
  where: CareStripeSubscriptionScalarWhereInput;
  data: CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedSubscriptionsInput;
};

export type CareStripeSubscriptionUpdateManyWithWhereWithoutAssociatedPetInput = {
  where: CareStripeSubscriptionScalarWhereInput;
  data: CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedSubscriptionsInput;
};

export type CareStripeSubscriptionUpdateManyWithWhereWithoutStripePaymentMethodInput = {
  where: CareStripeSubscriptionScalarWhereInput;
  data: CareStripeSubscriptionUncheckedUpdateManyWithoutCareStripeSubscriptionsInput;
};

export type CareStripeSubscriptionUpdateManyWithWhereWithoutSubscriptionOwnerInput = {
  where: CareStripeSubscriptionScalarWhereInput;
  data: CareStripeSubscriptionUncheckedUpdateManyWithoutAllCareStripeSubscriptionsInput;
};

export type CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
};

export type CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedPetInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedPetInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutAssociatedPetInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedPetInputEnvelope>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutAssociatedPetInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutAssociatedPetInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
};

export type CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodNestedInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
};

export type CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerNestedInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutSubscriptionOwnerInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutSubscriptionOwnerInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutSubscriptionOwnerInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
};

export type CareStripeSubscriptionUpdateOneRequiredWithoutCareBenefitUsageFollowupsNestedInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCareBenefitUsageFollowupsInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCareBenefitUsageFollowupsInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutCareBenefitUsageFollowupsInput>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutCareBenefitUsageFollowupsInput>;
};

export type CareStripeSubscriptionUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutCareSubscriptionTrueUpsInput>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutCareSubscriptionTrueUpsInput>;
};

export type CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutInvoicesInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutInvoicesInput>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutInvoicesInput>;
};

export type CareStripeSubscriptionUpdateOneWithoutActiveSubscriberNestedInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutActiveSubscriberInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutActiveSubscriberInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutActiveSubscriberInput>;
};

export type CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsNestedInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCareAddonEnrollmentsInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutCareAddonEnrollmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutCareAddonEnrollmentsInput>;
};

export type CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCarePlanEnrollmentsInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutCarePlanEnrollmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutCarePlanEnrollmentsInput>;
};

export type CareStripeSubscriptionUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  data: CareStripeSubscriptionUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
};

export type CareStripeSubscriptionUpdateWithWhereUniqueWithoutAssociatedPetInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  data: CareStripeSubscriptionUncheckedUpdateWithoutAssociatedPetInput;
};

export type CareStripeSubscriptionUpdateWithWhereUniqueWithoutStripePaymentMethodInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  data: CareStripeSubscriptionUncheckedUpdateWithoutStripePaymentMethodInput;
};

export type CareStripeSubscriptionUpdateWithWhereUniqueWithoutSubscriptionOwnerInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  data: CareStripeSubscriptionUncheckedUpdateWithoutSubscriptionOwnerInput;
};

export type CareStripeSubscriptionUpdateWithoutActiveSubscriberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUpdateWithoutAssociatedPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUpdateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUpdateWithoutCareBenefitUsageFollowupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUpdateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUpdateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
};

export type CareStripeSubscriptionUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUpdateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUpdateWithoutSubscriptionOwnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
};

export type CareStripeSubscriptionUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
};

export type CareStripeSubscriptionUpsertWithWhereUniqueWithoutAssociatedPetInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutAssociatedPetInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutAssociatedPetInput;
};

export type CareStripeSubscriptionUpsertWithWhereUniqueWithoutStripePaymentMethodInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutStripePaymentMethodInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutStripePaymentMethodInput;
};

export type CareStripeSubscriptionUpsertWithWhereUniqueWithoutSubscriptionOwnerInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutSubscriptionOwnerInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutSubscriptionOwnerInput;
};

export type CareStripeSubscriptionUpsertWithoutActiveSubscriberInput = {
  update: CareStripeSubscriptionUncheckedUpdateWithoutActiveSubscriberInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput;
};

export type CareStripeSubscriptionUpsertWithoutCareAddonEnrollmentsInput = {
  update: CareStripeSubscriptionUncheckedUpdateWithoutCareAddonEnrollmentsInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput;
};

export type CareStripeSubscriptionUpsertWithoutCareBenefitUsageFollowupsInput = {
  update: CareStripeSubscriptionUncheckedUpdateWithoutCareBenefitUsageFollowupsInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutCareBenefitUsageFollowupsInput;
};

export type CareStripeSubscriptionUpsertWithoutCarePlanEnrollmentsInput = {
  update: CareStripeSubscriptionUncheckedUpdateWithoutCarePlanEnrollmentsInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput;
};

export type CareStripeSubscriptionUpsertWithoutCareSubscriptionTrueUpsInput = {
  update: CareStripeSubscriptionUncheckedUpdateWithoutCareSubscriptionTrueUpsInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
};

export type CareStripeSubscriptionUpsertWithoutInvoicesInput = {
  update: CareStripeSubscriptionUncheckedUpdateWithoutInvoicesInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput;
};

export type CareStripeSubscriptionWhereInput = {
  AND?: Maybe<Array<CareStripeSubscriptionWhereInput>>;
  OR?: Maybe<Array<CareStripeSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CareStripeSubscriptionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  paymentStatus?: Maybe<EnumCareSubscriptionPaymentStatusNullableFilter>;
  stripeCustomerId?: Maybe<StringFilter>;
  stripeSubscriptionId?: Maybe<StringFilter>;
  subscriptionOwnerId?: Maybe<StringFilter>;
  nextBillingDate?: Maybe<DateTimeFilter>;
  upcomingTotalPrice?: Maybe<IntFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  associatedPetId?: Maybe<StringNullableFilter>;
  isTestSubscription?: Maybe<BoolFilter>;
  parentCareStripeSubscriptionId?: Maybe<StringNullableFilter>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentListRelationFilter>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentListRelationFilter>;
  activeSubscriber?: Maybe<OrganizationPetParentWhereInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentWhereInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodWhereInput>;
  invoices?: Maybe<StripeInvoiceListRelationFilter>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupListRelationFilter>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupWhereInput>;
  associatedPet?: Maybe<OrganizationPetWhereInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpListRelationFilter>;
};

export type CareStripeSubscriptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
};

export enum CareSubscriptionPaymentStatus {
  Draft = 'Draft',
  Open = 'Open',
  Paid = 'Paid',
  PastDue = 'PastDue'
}

export type CareSubscriptionTrueUp = {
  __typename?: 'CareSubscriptionTrueUp';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: CareSubscriptionTrueUpType;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId: Scalars['String'];
  carePlanEnrollmentId: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  currency: Currency;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment: Scalars['Boolean'];
  careStripeSubscription: CareStripeSubscription;
  carePlanEnrollment: CarePlanEnrollment;
  stripeInvoice?: Maybe<StripeInvoice>;
  stripePaymentMethod?: Maybe<StripePaymentMethod>;
  logEntries: Array<CareSubscriptionTrueUpLogEntry>;
  _count: CareSubscriptionTrueUpCountOutputType;
};


export type CareSubscriptionTrueUpLogEntriesArgs = {
  where?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
  orderBy?: Maybe<CareSubscriptionTrueUpLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareSubscriptionTrueUpLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareSubscriptionTrueUpLogEntryScalarFieldEnum>;
};

export type CareSubscriptionTrueUpAvgAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type CareSubscriptionTrueUpAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpCountAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  type: Scalars['Int'];
  dueAt: Scalars['Int'];
  retryAt: Scalars['Int'];
  completedAt: Scalars['Int'];
  careStripeSubscriptionId: Scalars['Int'];
  carePlanEnrollmentId: Scalars['Int'];
  stripeInvoiceId: Scalars['Int'];
  stripePaymentMethodId: Scalars['Int'];
  amount: Scalars['Int'];
  currency: Scalars['Int'];
  slackMessageTimestamp: Scalars['Int'];
  shouldCancelEnrollment: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareSubscriptionTrueUpCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  retryAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  slackMessageTimestamp?: Maybe<SortOrder>;
  shouldCancelEnrollment?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpCountOutputType = {
  __typename?: 'CareSubscriptionTrueUpCountOutputType';
  logEntries: Scalars['Int'];
};

export type CareSubscriptionTrueUpCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
};

export type CareSubscriptionTrueUpCreateManyCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpCreateManyCarePlanEnrollmentInputEnvelope = {
  data: Array<CareSubscriptionTrueUpCreateManyCarePlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpCreateManyCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpCreateManyCareStripeSubscriptionInputEnvelope = {
  data: Array<CareSubscriptionTrueUpCreateManyCareStripeSubscriptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId: Scalars['String'];
  carePlanEnrollmentId: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpCreateManyStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId: Scalars['String'];
  carePlanEnrollmentId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpCreateManyStripeInvoiceInputEnvelope = {
  data: Array<CareSubscriptionTrueUpCreateManyStripeInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpCreateManyStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId: Scalars['String'];
  carePlanEnrollmentId: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpCreateManyStripePaymentMethodInputEnvelope = {
  data: Array<CareSubscriptionTrueUpCreateManyStripePaymentMethodInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCarePlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
};

export type CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
};

export type CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripeInvoiceInputEnvelope>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
};

export type CareSubscriptionTrueUpCreateNestedManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripePaymentMethodInputEnvelope>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
};

export type CareSubscriptionTrueUpCreateNestedOneWithoutLogEntriesInput = {
  create?: Maybe<CareSubscriptionTrueUpUncheckedCreateWithoutLogEntriesInput>;
  connectOrCreate?: Maybe<CareSubscriptionTrueUpCreateOrConnectWithoutLogEntriesInput>;
  connect?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
};

export type CareSubscriptionTrueUpCreateOrConnectWithoutCarePlanEnrollmentInput = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
  create: CareSubscriptionTrueUpUncheckedCreateWithoutCarePlanEnrollmentInput;
};

export type CareSubscriptionTrueUpCreateOrConnectWithoutCareStripeSubscriptionInput = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
  create: CareSubscriptionTrueUpUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type CareSubscriptionTrueUpCreateOrConnectWithoutLogEntriesInput = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
  create: CareSubscriptionTrueUpUncheckedCreateWithoutLogEntriesInput;
};

export type CareSubscriptionTrueUpCreateOrConnectWithoutStripeInvoiceInput = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
  create: CareSubscriptionTrueUpUncheckedCreateWithoutStripeInvoiceInput;
};

export type CareSubscriptionTrueUpCreateOrConnectWithoutStripePaymentMethodInput = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
  create: CareSubscriptionTrueUpUncheckedCreateWithoutStripePaymentMethodInput;
};

export type CareSubscriptionTrueUpCreateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
};

export type CareSubscriptionTrueUpCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
};

export type CareSubscriptionTrueUpCreateWithoutLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
};

export type CareSubscriptionTrueUpCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
};

export type CareSubscriptionTrueUpCreateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
};

export type CareSubscriptionTrueUpListRelationFilter = {
  every?: Maybe<CareSubscriptionTrueUpWhereInput>;
  some?: Maybe<CareSubscriptionTrueUpWhereInput>;
  none?: Maybe<CareSubscriptionTrueUpWhereInput>;
};

export type CareSubscriptionTrueUpLogEntry = {
  __typename?: 'CareSubscriptionTrueUpLogEntry';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  careSubscriptionTrueUpId: Scalars['String'];
  status: CareSubscriptionTrueUpStatus;
  retryAttempt: Scalars['Int'];
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careSubscriptionTrueUp: CareSubscriptionTrueUp;
};

export type CareSubscriptionTrueUpLogEntryAvgAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpLogEntryAvgAggregateOutputType';
  retryAttempt?: Maybe<Scalars['Float']>;
  httpCode?: Maybe<Scalars['Float']>;
};

export type CareSubscriptionTrueUpLogEntryAvgOrderByAggregateInput = {
  retryAttempt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpLogEntryCountAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpLogEntryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  careSubscriptionTrueUpId: Scalars['Int'];
  status: Scalars['Int'];
  retryAttempt: Scalars['Int'];
  httpCode: Scalars['Int'];
  lastPaymentError: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareSubscriptionTrueUpLogEntryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  careSubscriptionTrueUpId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  retryAttempt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpLogEntryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careSubscriptionTrueUp: CareSubscriptionTrueUpCreateNestedOneWithoutLogEntriesInput;
};

export type CareSubscriptionTrueUpLogEntryCreateManyCareSubscriptionTrueUpInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
};

export type CareSubscriptionTrueUpLogEntryCreateManyCareSubscriptionTrueUpInputEnvelope = {
  data: Array<CareSubscriptionTrueUpLogEntryCreateManyCareSubscriptionTrueUpInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpLogEntryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  careSubscriptionTrueUpId: Scalars['String'];
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
};

export type CareSubscriptionTrueUpLogEntryCreateNestedManyWithoutCareSubscriptionTrueUpInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateWithoutCareSubscriptionTrueUpInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateOrConnectWithoutCareSubscriptionTrueUpInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpLogEntryCreateManyCareSubscriptionTrueUpInputEnvelope>;
  connect?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
};

export type CareSubscriptionTrueUpLogEntryCreateOrConnectWithoutCareSubscriptionTrueUpInput = {
  where: CareSubscriptionTrueUpLogEntryWhereUniqueInput;
  create: CareSubscriptionTrueUpLogEntryUncheckedCreateWithoutCareSubscriptionTrueUpInput;
};

export type CareSubscriptionTrueUpLogEntryCreateWithoutCareSubscriptionTrueUpInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
};

export type CareSubscriptionTrueUpLogEntryListRelationFilter = {
  every?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
  some?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
  none?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
};

export type CareSubscriptionTrueUpLogEntryMaxAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpLogEntryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  careSubscriptionTrueUpId?: Maybe<Scalars['String']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
};

export type CareSubscriptionTrueUpLogEntryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  careSubscriptionTrueUpId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  retryAttempt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpLogEntryMinAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpLogEntryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  careSubscriptionTrueUpId?: Maybe<Scalars['String']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
};

export type CareSubscriptionTrueUpLogEntryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  careSubscriptionTrueUpId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  retryAttempt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareSubscriptionTrueUpLogEntryOrderByRelevanceFieldEnum {
  Id = 'id',
  CareSubscriptionTrueUpId = 'careSubscriptionTrueUpId'
}

export type CareSubscriptionTrueUpLogEntryOrderByRelevanceInput = {
  fields: Array<CareSubscriptionTrueUpLogEntryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareSubscriptionTrueUpLogEntryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  careSubscriptionTrueUpId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  retryAttempt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  _count?: Maybe<CareSubscriptionTrueUpLogEntryCountOrderByAggregateInput>;
  _avg?: Maybe<CareSubscriptionTrueUpLogEntryAvgOrderByAggregateInput>;
  _max?: Maybe<CareSubscriptionTrueUpLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<CareSubscriptionTrueUpLogEntryMinOrderByAggregateInput>;
  _sum?: Maybe<CareSubscriptionTrueUpLogEntrySumOrderByAggregateInput>;
};

export type CareSubscriptionTrueUpLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  careSubscriptionTrueUpId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  retryAttempt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  careSubscriptionTrueUp?: Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CareSubscriptionTrueUpLogEntryOrderByRelevanceInput>;
};

export enum CareSubscriptionTrueUpLogEntryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CareSubscriptionTrueUpId = 'careSubscriptionTrueUpId',
  Status = 'status',
  RetryAttempt = 'retryAttempt',
  HttpCode = 'httpCode',
  LastPaymentError = 'lastPaymentError'
}

export type CareSubscriptionTrueUpLogEntryScalarWhereInput = {
  AND?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  careSubscriptionTrueUpId?: Maybe<StringFilter>;
  status?: Maybe<EnumCareSubscriptionTrueUpStatusFilter>;
  retryAttempt?: Maybe<IntFilter>;
  httpCode?: Maybe<IntNullableFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableFilter>;
};

export type CareSubscriptionTrueUpLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  careSubscriptionTrueUpId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumCareSubscriptionTrueUpStatusWithAggregatesFilter>;
  retryAttempt?: Maybe<IntWithAggregatesFilter>;
  httpCode?: Maybe<IntNullableWithAggregatesFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableWithAggregatesFilter>;
};

export type CareSubscriptionTrueUpLogEntrySumAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpLogEntrySumAggregateOutputType';
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
};

export type CareSubscriptionTrueUpLogEntrySumOrderByAggregateInput = {
  retryAttempt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  careSubscriptionTrueUpId: Scalars['String'];
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedCreateNestedManyWithoutCareSubscriptionTrueUpInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateWithoutCareSubscriptionTrueUpInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateOrConnectWithoutCareSubscriptionTrueUpInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpLogEntryCreateManyCareSubscriptionTrueUpInputEnvelope>;
  connect?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedCreateWithoutCareSubscriptionTrueUpInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  careSubscriptionTrueUpId?: Maybe<Scalars['String']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  careSubscriptionTrueUpId?: Maybe<Scalars['String']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutCareSubscriptionTrueUpNestedInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateWithoutCareSubscriptionTrueUpInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateOrConnectWithoutCareSubscriptionTrueUpInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpLogEntryUpsertWithWhereUniqueWithoutCareSubscriptionTrueUpInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpLogEntryCreateManyCareSubscriptionTrueUpInputEnvelope>;
  set?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpLogEntryUpdateWithWhereUniqueWithoutCareSubscriptionTrueUpInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpLogEntryUpdateManyWithWhereWithoutCareSubscriptionTrueUpInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereInput>>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedUpdateWithoutCareSubscriptionTrueUpInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
};

export type CareSubscriptionTrueUpLogEntryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  careSubscriptionTrueUp?: Maybe<CareSubscriptionTrueUpUpdateOneRequiredWithoutLogEntriesNestedInput>;
};

export type CareSubscriptionTrueUpLogEntryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
};

export type CareSubscriptionTrueUpLogEntryUpdateManyWithWhereWithoutCareSubscriptionTrueUpInput = {
  where: CareSubscriptionTrueUpLogEntryScalarWhereInput;
  data: CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutLogEntriesInput;
};

export type CareSubscriptionTrueUpLogEntryUpdateManyWithoutCareSubscriptionTrueUpNestedInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateWithoutCareSubscriptionTrueUpInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateOrConnectWithoutCareSubscriptionTrueUpInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpLogEntryUpsertWithWhereUniqueWithoutCareSubscriptionTrueUpInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpLogEntryCreateManyCareSubscriptionTrueUpInputEnvelope>;
  set?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpLogEntryUpdateWithWhereUniqueWithoutCareSubscriptionTrueUpInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpLogEntryUpdateManyWithWhereWithoutCareSubscriptionTrueUpInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereInput>>;
};

export type CareSubscriptionTrueUpLogEntryUpdateWithWhereUniqueWithoutCareSubscriptionTrueUpInput = {
  where: CareSubscriptionTrueUpLogEntryWhereUniqueInput;
  data: CareSubscriptionTrueUpLogEntryUncheckedUpdateWithoutCareSubscriptionTrueUpInput;
};

export type CareSubscriptionTrueUpLogEntryUpdateWithoutCareSubscriptionTrueUpInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  retryAttempt?: Maybe<Scalars['Int']>;
  httpCode?: Maybe<Scalars['Int']>;
  lastPaymentError?: Maybe<StripePaymentError>;
};

export type CareSubscriptionTrueUpLogEntryUpsertWithWhereUniqueWithoutCareSubscriptionTrueUpInput = {
  where: CareSubscriptionTrueUpLogEntryWhereUniqueInput;
  update: CareSubscriptionTrueUpLogEntryUncheckedUpdateWithoutCareSubscriptionTrueUpInput;
  create: CareSubscriptionTrueUpLogEntryUncheckedCreateWithoutCareSubscriptionTrueUpInput;
};

export type CareSubscriptionTrueUpLogEntryWhereInput = {
  AND?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereInput>>;
  OR?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereInput>>;
  NOT?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  careSubscriptionTrueUpId?: Maybe<StringFilter>;
  status?: Maybe<EnumCareSubscriptionTrueUpStatusFilter>;
  retryAttempt?: Maybe<IntFilter>;
  httpCode?: Maybe<IntNullableFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableFilter>;
  careSubscriptionTrueUp?: Maybe<CareSubscriptionTrueUpWhereInput>;
};

export type CareSubscriptionTrueUpLogEntryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareSubscriptionTrueUpMaxAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  retryAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  slackMessageTimestamp?: Maybe<SortOrder>;
  shouldCancelEnrollment?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpMinAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  retryAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  slackMessageTimestamp?: Maybe<SortOrder>;
  shouldCancelEnrollment?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareSubscriptionTrueUpOrderByRelevanceFieldEnum {
  Id = 'id',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  StripeInvoiceId = 'stripeInvoiceId',
  StripePaymentMethodId = 'stripePaymentMethodId',
  SlackMessageTimestamp = 'slackMessageTimestamp'
}

export type CareSubscriptionTrueUpOrderByRelevanceInput = {
  fields: Array<CareSubscriptionTrueUpOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareSubscriptionTrueUpOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  retryAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  slackMessageTimestamp?: Maybe<SortOrder>;
  shouldCancelEnrollment?: Maybe<SortOrder>;
  _count?: Maybe<CareSubscriptionTrueUpCountOrderByAggregateInput>;
  _avg?: Maybe<CareSubscriptionTrueUpAvgOrderByAggregateInput>;
  _max?: Maybe<CareSubscriptionTrueUpMaxOrderByAggregateInput>;
  _min?: Maybe<CareSubscriptionTrueUpMinOrderByAggregateInput>;
  _sum?: Maybe<CareSubscriptionTrueUpSumOrderByAggregateInput>;
};

export type CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  retryAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  slackMessageTimestamp?: Maybe<SortOrder>;
  shouldCancelEnrollment?: Maybe<SortOrder>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  stripeInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryOrderByRelationAggregateInput>;
  _relevance?: Maybe<CareSubscriptionTrueUpOrderByRelevanceInput>;
};

export type CareSubscriptionTrueUpRelationFilter = {
  is?: Maybe<CareSubscriptionTrueUpWhereInput>;
  isNot?: Maybe<CareSubscriptionTrueUpWhereInput>;
};

export enum CareSubscriptionTrueUpScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Type = 'type',
  DueAt = 'dueAt',
  RetryAt = 'retryAt',
  CompletedAt = 'completedAt',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  StripeInvoiceId = 'stripeInvoiceId',
  StripePaymentMethodId = 'stripePaymentMethodId',
  Amount = 'amount',
  Currency = 'currency',
  SlackMessageTimestamp = 'slackMessageTimestamp',
  ShouldCancelEnrollment = 'shouldCancelEnrollment'
}

export type CareSubscriptionTrueUpScalarWhereInput = {
  AND?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
  OR?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
  NOT?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumCareSubscriptionTrueUpTypeFilter>;
  dueAt?: Maybe<DateTimeFilter>;
  retryAt?: Maybe<DateTimeNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  careStripeSubscriptionId?: Maybe<StringFilter>;
  carePlanEnrollmentId?: Maybe<StringFilter>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  amount?: Maybe<IntFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  slackMessageTimestamp?: Maybe<StringNullableFilter>;
  shouldCancelEnrollment?: Maybe<BoolFilter>;
};

export type CareSubscriptionTrueUpScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareSubscriptionTrueUpScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareSubscriptionTrueUpScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareSubscriptionTrueUpScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  type?: Maybe<EnumCareSubscriptionTrueUpTypeWithAggregatesFilter>;
  dueAt?: Maybe<DateTimeWithAggregatesFilter>;
  retryAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  careStripeSubscriptionId?: Maybe<StringWithAggregatesFilter>;
  carePlanEnrollmentId?: Maybe<StringWithAggregatesFilter>;
  stripeInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePaymentMethodId?: Maybe<StringNullableWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  currency?: Maybe<EnumCurrencyWithAggregatesFilter>;
  slackMessageTimestamp?: Maybe<StringNullableWithAggregatesFilter>;
  shouldCancelEnrollment?: Maybe<BoolWithAggregatesFilter>;
};

export enum CareSubscriptionTrueUpStatus {
  Success = 'Success',
  Failure = 'Failure',
  Pending = 'Pending',
  PendingRetryAutomatic = 'Pending_RetryAutomatic'
}

export type CareSubscriptionTrueUpSubscriptionEnrollmentAndDueAtCompoundUniqueInput = {
  careStripeSubscriptionId: Scalars['String'];
  carePlanEnrollmentId: Scalars['String'];
  dueAt: Scalars['DateTime'];
};

export type CareSubscriptionTrueUpSumAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
};

export type CareSubscriptionTrueUpSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export enum CareSubscriptionTrueUpType {
  Internal = 'Internal',
  ClinicAlternativePayment = 'ClinicAlternativePayment',
  ClinicCollections = 'ClinicCollections'
}

export type CareSubscriptionTrueUpUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId: Scalars['String'];
  carePlanEnrollmentId: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
};

export type CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCarePlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
};

export type CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
};

export type CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripeInvoiceInputEnvelope>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
};

export type CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripePaymentMethodInputEnvelope>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
};

export type CareSubscriptionTrueUpUncheckedCreateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
};

export type CareSubscriptionTrueUpUncheckedCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
};

export type CareSubscriptionTrueUpUncheckedCreateWithoutLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId: Scalars['String'];
  carePlanEnrollmentId: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpUncheckedCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId: Scalars['String'];
  carePlanEnrollmentId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
};

export type CareSubscriptionTrueUpUncheckedCreateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt: Scalars['DateTime'];
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId: Scalars['String'];
  carePlanEnrollmentId: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
};

export type CareSubscriptionTrueUpUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
};

export type CareSubscriptionTrueUpUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCarePlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
};

export type CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
};

export type CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripeInvoiceInputEnvelope>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
};

export type CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripePaymentMethodNestedInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripePaymentMethodInputEnvelope>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
};

export type CareSubscriptionTrueUpUncheckedUpdateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
};

export type CareSubscriptionTrueUpUncheckedUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
};

export type CareSubscriptionTrueUpUncheckedUpdateWithoutLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpUncheckedUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
};

export type CareSubscriptionTrueUpUncheckedUpdateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
};

export type CareSubscriptionTrueUpUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
};

export type CareSubscriptionTrueUpUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpUpdateManyWithWhereWithoutCarePlanEnrollmentInput = {
  where: CareSubscriptionTrueUpScalarWhereInput;
  data: CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareSubscriptionTrueUpsInput;
};

export type CareSubscriptionTrueUpUpdateManyWithWhereWithoutCareStripeSubscriptionInput = {
  where: CareSubscriptionTrueUpScalarWhereInput;
  data: CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareSubscriptionTrueUpsInput;
};

export type CareSubscriptionTrueUpUpdateManyWithWhereWithoutStripeInvoiceInput = {
  where: CareSubscriptionTrueUpScalarWhereInput;
  data: CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareSubscriptionTrueUpsInput;
};

export type CareSubscriptionTrueUpUpdateManyWithWhereWithoutStripePaymentMethodInput = {
  where: CareSubscriptionTrueUpScalarWhereInput;
  data: CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareSubscriptionTrueUpsInput;
};

export type CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCarePlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
};

export type CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
};

export type CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripeInvoiceInputEnvelope>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
};

export type CareSubscriptionTrueUpUpdateManyWithoutStripePaymentMethodNestedInput = {
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripePaymentMethodInputEnvelope>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
};

export type CareSubscriptionTrueUpUpdateOneRequiredWithoutLogEntriesNestedInput = {
  create?: Maybe<CareSubscriptionTrueUpUncheckedCreateWithoutLogEntriesInput>;
  connectOrCreate?: Maybe<CareSubscriptionTrueUpCreateOrConnectWithoutLogEntriesInput>;
  upsert?: Maybe<CareSubscriptionTrueUpUpsertWithoutLogEntriesInput>;
  connect?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  update?: Maybe<CareSubscriptionTrueUpUncheckedUpdateWithoutLogEntriesInput>;
};

export type CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
  data: CareSubscriptionTrueUpUncheckedUpdateWithoutCarePlanEnrollmentInput;
};

export type CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
  data: CareSubscriptionTrueUpUncheckedUpdateWithoutCareStripeSubscriptionInput;
};

export type CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutStripeInvoiceInput = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
  data: CareSubscriptionTrueUpUncheckedUpdateWithoutStripeInvoiceInput;
};

export type CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutStripePaymentMethodInput = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
  data: CareSubscriptionTrueUpUncheckedUpdateWithoutStripePaymentMethodInput;
};

export type CareSubscriptionTrueUpUpdateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
};

export type CareSubscriptionTrueUpUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
};

export type CareSubscriptionTrueUpUpdateWithoutLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
};

export type CareSubscriptionTrueUpUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
};

export type CareSubscriptionTrueUpUpdateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  dueAt?: Maybe<Scalars['DateTime']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
};

export type CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
  update: CareSubscriptionTrueUpUncheckedUpdateWithoutCarePlanEnrollmentInput;
  create: CareSubscriptionTrueUpUncheckedCreateWithoutCarePlanEnrollmentInput;
};

export type CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
  update: CareSubscriptionTrueUpUncheckedUpdateWithoutCareStripeSubscriptionInput;
  create: CareSubscriptionTrueUpUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutStripeInvoiceInput = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
  update: CareSubscriptionTrueUpUncheckedUpdateWithoutStripeInvoiceInput;
  create: CareSubscriptionTrueUpUncheckedCreateWithoutStripeInvoiceInput;
};

export type CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutStripePaymentMethodInput = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
  update: CareSubscriptionTrueUpUncheckedUpdateWithoutStripePaymentMethodInput;
  create: CareSubscriptionTrueUpUncheckedCreateWithoutStripePaymentMethodInput;
};

export type CareSubscriptionTrueUpUpsertWithoutLogEntriesInput = {
  update: CareSubscriptionTrueUpUncheckedUpdateWithoutLogEntriesInput;
  create: CareSubscriptionTrueUpUncheckedCreateWithoutLogEntriesInput;
};

export type CareSubscriptionTrueUpWhereInput = {
  AND?: Maybe<Array<CareSubscriptionTrueUpWhereInput>>;
  OR?: Maybe<Array<CareSubscriptionTrueUpWhereInput>>;
  NOT?: Maybe<Array<CareSubscriptionTrueUpWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumCareSubscriptionTrueUpTypeFilter>;
  dueAt?: Maybe<DateTimeFilter>;
  retryAt?: Maybe<DateTimeNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  careStripeSubscriptionId?: Maybe<StringFilter>;
  carePlanEnrollmentId?: Maybe<StringFilter>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  amount?: Maybe<IntFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  slackMessageTimestamp?: Maybe<StringNullableFilter>;
  shouldCancelEnrollment?: Maybe<BoolFilter>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  stripeInvoice?: Maybe<StripeInvoiceWhereInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodWhereInput>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryListRelationFilter>;
};

export type CareSubscriptionTrueUpWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  subscriptionEnrollmentAndDueAt?: Maybe<CareSubscriptionTrueUpSubscriptionEnrollmentAndDueAtCompoundUniqueInput>;
};

export type ChangeCareSubscriptionPaymentMethodInput = {
  careStripeSubscriptionId: Scalars['String'];
  careStripePaymentMethodId: Scalars['String'];
};

export type ChangeCareSubscriptionPaymentMethodOutput = {
  __typename?: 'ChangeCareSubscriptionPaymentMethodOutput';
  success: Scalars['Boolean'];
};

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventAction>;
  lastMessage?: Maybe<ChannelMessage>;
  channelStatus?: Maybe<ChannelStatus>;
  clinic?: Maybe<Clinic>;
  appointments: Array<Appointment>;
  channelMembers: Array<ChannelMember>;
  pets: Array<ClinicPet>;
  messages: Array<ChannelMessage>;
  assignees: Array<User>;
  financialTransactions: Array<FinancialTransaction>;
  automationStatuses: Array<ChannelAutomationStatus>;
  creationSource?: Maybe<ChannelCreationSource>;
  channelPins: Array<ChannelPin>;
  HillsToHomeAPILog: Array<HillsToHomeApiLog>;
  _count: ChannelCountOutputType;
};


export type ChannelAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};


export type ChannelChannelMembersArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
  orderBy?: Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMemberScalarFieldEnum>;
};


export type ChannelPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};


export type ChannelMessagesArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageScalarFieldEnum>;
};


export type ChannelAssigneesArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};


export type ChannelFinancialTransactionsArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
  orderBy?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FinancialTransactionScalarFieldEnum>;
};


export type ChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};


export type ChannelChannelPinsArgs = {
  where?: Maybe<ChannelPinWhereInput>;
  orderBy?: Maybe<ChannelPinOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelPinWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelPinScalarFieldEnum>;
};


export type ChannelHillsToHomeApiLogArgs = {
  where?: Maybe<HillsToHomeApiLogWhereInput>;
  orderBy?: Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<HillsToHomeApiLogScalarFieldEnum>;
};

export type ChannelAutomationStatus = {
  __typename?: 'ChannelAutomationStatus';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status: AutomationStatus;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  channel: Channel;
  workflowEventSetting: WorkflowEventSetting;
  clinicPet?: Maybe<ClinicPet>;
  currentWorkflowEventAction?: Maybe<WorkflowEventAction>;
  appointment?: Maybe<Appointment>;
  formSubmission?: Maybe<FormSubmission>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  serviceReminder?: Maybe<ServiceReminder>;
};

export type ChannelAutomationStatusCountAggregateOutputType = {
  __typename?: 'ChannelAutomationStatusCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  cancelledAt: Scalars['Int'];
  startedAt: Scalars['Int'];
  completedAt: Scalars['Int'];
  expiredAt: Scalars['Int'];
  channelId: Scalars['Int'];
  status: Scalars['Int'];
  workflowEventSettingId: Scalars['Int'];
  currentWorkflowEventActionId: Scalars['Int'];
  appointmentId: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  serviceReminderId: Scalars['Int'];
  clinicWidgetRequestTypeId: Scalars['Int'];
  clinicWidgetRequestId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelAutomationStatusCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ChannelAutomationStatusCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateManyAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyAppointmentInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyChannelInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyClinicPetInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyClinicWidgetRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyServiceReminderInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyServiceReminderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyWorkflowEventSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutAppointmentInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutChannelInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutChannelInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutClinicPetInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutClinicWidgetRequestInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutCurrentWorkflowEventActionInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutFormSubmissionInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutServiceReminderInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutWorkflowEventSettingInput;
};

export type ChannelAutomationStatusCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusListRelationFilter = {
  every?: Maybe<ChannelAutomationStatusWhereInput>;
  some?: Maybe<ChannelAutomationStatusWhereInput>;
  none?: Maybe<ChannelAutomationStatusWhereInput>;
};

export type ChannelAutomationStatusMaxAggregateOutputType = {
  __typename?: 'ChannelAutomationStatusMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ChannelAutomationStatusMinAggregateOutputType = {
  __typename?: 'ChannelAutomationStatusMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ChannelAutomationStatusOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelAutomationStatusOrderByRelevanceFieldEnum {
  Id = 'id',
  ChannelId = 'channelId',
  WorkflowEventSettingId = 'workflowEventSettingId',
  CurrentWorkflowEventActionId = 'currentWorkflowEventActionId',
  AppointmentId = 'appointmentId',
  FormSubmissionId = 'formSubmissionId',
  ServiceReminderId = 'serviceReminderId',
  ClinicWidgetRequestTypeId = 'clinicWidgetRequestTypeId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  ClinicPetId = 'clinicPetId'
}

export type ChannelAutomationStatusOrderByRelevanceInput = {
  fields: Array<ChannelAutomationStatusOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelAutomationStatusOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  _count?: Maybe<ChannelAutomationStatusCountOrderByAggregateInput>;
  _max?: Maybe<ChannelAutomationStatusMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelAutomationStatusMinOrderByAggregateInput>;
};

export type ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  serviceReminder?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ChannelAutomationStatusOrderByRelevanceInput>;
};

export enum ChannelAutomationStatusScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CancelledAt = 'cancelledAt',
  StartedAt = 'startedAt',
  CompletedAt = 'completedAt',
  ExpiredAt = 'expiredAt',
  ChannelId = 'channelId',
  Status = 'status',
  WorkflowEventSettingId = 'workflowEventSettingId',
  CurrentWorkflowEventActionId = 'currentWorkflowEventActionId',
  AppointmentId = 'appointmentId',
  FormSubmissionId = 'formSubmissionId',
  ServiceReminderId = 'serviceReminderId',
  ClinicWidgetRequestTypeId = 'clinicWidgetRequestTypeId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  ClinicPetId = 'clinicPetId'
}

export type ChannelAutomationStatusScalarWhereInput = {
  AND?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  OR?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  startedAt?: Maybe<DateTimeNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  channelId?: Maybe<StringFilter>;
  status?: Maybe<EnumAutomationStatusFilter>;
  workflowEventSettingId?: Maybe<StringFilter>;
  currentWorkflowEventActionId?: Maybe<StringNullableFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
};

export type ChannelAutomationStatusScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelAutomationStatusScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelAutomationStatusScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelAutomationStatusScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  cancelledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  startedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  expiredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  channelId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumAutomationStatusWithAggregatesFilter>;
  workflowEventSettingId?: Maybe<StringWithAggregatesFilter>;
  currentWorkflowEventActionId?: Maybe<StringNullableWithAggregatesFilter>;
  appointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  serviceReminderId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ChannelAutomationStatusUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutCurrentWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutCurrentWorkflowEventActionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutCurrentWorkflowEventActionInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutCurrentWorkflowEventActionInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutCurrentWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutAppointmentInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutChannelInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutClinicPetInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutClinicWidgetRequestInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutCurrentWorkflowEventActionInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutFormSubmissionInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutServiceReminderInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutWorkflowEventSettingInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutChannelNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutCurrentWorkflowEventActionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutCurrentWorkflowEventActionInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutCurrentWorkflowEventActionInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutAppointmentInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutAppointmentInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutChannelInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutChannelInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutClinicPetInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicWidgetRequestInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutClinicWidgetRequestInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutCurrentWorkflowEventActionInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutCurrentWorkflowEventActionInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutFormSubmissionInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutServiceReminderInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutServiceReminderInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutWorkflowEventSettingInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutWorkflowEventSettingInput;
};

export type ChannelAutomationStatusUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateWithoutCurrentWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutAppointmentInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutAppointmentInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutAppointmentInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutChannelInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutChannelInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutChannelInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutClinicPetInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutClinicPetInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicWidgetRequestInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutClinicWidgetRequestInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutClinicWidgetRequestInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutCurrentWorkflowEventActionInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutCurrentWorkflowEventActionInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutCurrentWorkflowEventActionInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutFormSubmissionInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutFormSubmissionInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutServiceReminderInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutServiceReminderInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutServiceReminderInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutWorkflowEventSettingInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutWorkflowEventSettingInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutWorkflowEventSettingInput;
};

export type ChannelAutomationStatusWhereInput = {
  AND?: Maybe<Array<ChannelAutomationStatusWhereInput>>;
  OR?: Maybe<Array<ChannelAutomationStatusWhereInput>>;
  NOT?: Maybe<Array<ChannelAutomationStatusWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  startedAt?: Maybe<DateTimeNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  channelId?: Maybe<StringFilter>;
  status?: Maybe<EnumAutomationStatusFilter>;
  workflowEventSettingId?: Maybe<StringFilter>;
  currentWorkflowEventActionId?: Maybe<StringNullableFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  channel?: Maybe<ChannelWhereInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingWhereInput>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionWhereInput>;
  appointment?: Maybe<AppointmentWhereInput>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestWhereInput>;
  serviceReminder?: Maybe<ServiceReminderWhereInput>;
};

export type ChannelAutomationStatusWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelCountAggregateOutputType = {
  __typename?: 'ChannelCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  twilioChannelSid: Scalars['Int'];
  uniqueName: Scalars['Int'];
  friendlyName: Scalars['Int'];
  attributes: Scalars['Int'];
  clinicId: Scalars['Int'];
  lastMessageId: Scalars['Int'];
  isActive: Scalars['Int'];
  channelStatusId: Scalars['Int'];
  currentWorkflowActionId: Scalars['Int'];
  channelType: Scalars['Int'];
  channelVisibility: Scalars['Int'];
  tags: Scalars['Int'];
  lastExportAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  friendlyName?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  lastMessageId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  lastExportAt?: Maybe<SortOrder>;
};

export type ChannelCountOutputType = {
  __typename?: 'ChannelCountOutputType';
  appointments: Scalars['Int'];
  channelMembers: Scalars['Int'];
  pets: Scalars['Int'];
  messages: Scalars['Int'];
  assignees: Scalars['Int'];
  financialTransactions: Scalars['Int'];
  automationStatuses: Scalars['Int'];
  channelPins: Scalars['Int'];
  HillsToHomeAPILog: Scalars['Int'];
};

export type ChannelCountResult = {
  __typename?: 'ChannelCountResult';
  total: Scalars['Int'];
  unreadChannels: Array<UnreadMessageChannel>;
};

export type ChannelCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateManyChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateManyChannelStatusInputEnvelope = {
  data: Array<ChannelCreateManyChannelStatusInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateManyClinicInputEnvelope = {
  data: Array<ChannelCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreateManyCurrentWorkflowActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateManyCurrentWorkflowActionInputEnvelope = {
  data: Array<ChannelCreateManyCurrentWorkflowActionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateManyLastMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateManyLastMessageInputEnvelope = {
  data: Array<ChannelCreateManyLastMessageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreateNestedManyWithoutAssigneesInput = {
  create?: Maybe<Array<ChannelCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutAssigneesInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelCreateNestedManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ChannelCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelCreateManyChannelStatusInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ChannelCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelCreateNestedManyWithoutCurrentWorkflowActionInput = {
  create?: Maybe<Array<ChannelCreateWithoutCurrentWorkflowActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutCurrentWorkflowActionInput>>;
  createMany?: Maybe<ChannelCreateManyCurrentWorkflowActionInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelCreateNestedManyWithoutLastMessageInput = {
  create?: Maybe<Array<ChannelCreateWithoutLastMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutLastMessageInput>>;
  createMany?: Maybe<ChannelCreateManyLastMessageInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelCreateNestedManyWithoutPetsInput = {
  create?: Maybe<Array<ChannelCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutPetsInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
};

export type ChannelCreateNestedOneWithoutAutomationStatusesInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutAutomationStatusesInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutAutomationStatusesInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
};

export type ChannelCreateNestedOneWithoutChannelMembersInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutChannelMembersInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutChannelMembersInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
};

export type ChannelCreateNestedOneWithoutChannelPinsInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutChannelPinsInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutChannelPinsInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
};

export type ChannelCreateNestedOneWithoutCreationSourceInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutCreationSourceInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutCreationSourceInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
};

export type ChannelCreateNestedOneWithoutFinancialTransactionsInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutFinancialTransactionsInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutFinancialTransactionsInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
};

export type ChannelCreateNestedOneWithoutHillsToHomeApiLogInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutHillsToHomeApiLogInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutHillsToHomeApiLogInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
};

export type ChannelCreateNestedOneWithoutMessagesInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutMessagesInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutMessagesInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
};

export type ChannelCreateOrConnectWithoutAppointmentsInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutAppointmentsInput;
};

export type ChannelCreateOrConnectWithoutAssigneesInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutAssigneesInput;
};

export type ChannelCreateOrConnectWithoutAutomationStatusesInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutAutomationStatusesInput;
};

export type ChannelCreateOrConnectWithoutChannelMembersInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutChannelMembersInput;
};

export type ChannelCreateOrConnectWithoutChannelPinsInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutChannelPinsInput;
};

export type ChannelCreateOrConnectWithoutChannelStatusInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutChannelStatusInput;
};

export type ChannelCreateOrConnectWithoutClinicInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutClinicInput;
};

export type ChannelCreateOrConnectWithoutCreationSourceInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutCreationSourceInput;
};

export type ChannelCreateOrConnectWithoutCurrentWorkflowActionInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutCurrentWorkflowActionInput;
};

export type ChannelCreateOrConnectWithoutFinancialTransactionsInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutFinancialTransactionsInput;
};

export type ChannelCreateOrConnectWithoutHillsToHomeApiLogInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutHillsToHomeApiLogInput;
};

export type ChannelCreateOrConnectWithoutLastMessageInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutLastMessageInput;
};

export type ChannelCreateOrConnectWithoutMessagesInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutMessagesInput;
};

export type ChannelCreateOrConnectWithoutPetsInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutPetsInput;
};

export type ChannelCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutChannelPinsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutCreationSourceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutCurrentWorkflowActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutLastMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreationSource = {
  __typename?: 'ChannelCreationSource';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  creationSource: ChannelCreationSourceType;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  workflowEventSetting?: Maybe<WorkflowEventSetting>;
  user?: Maybe<User>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  channel: Channel;
  serviceReminder?: Maybe<ServiceReminder>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  formSubmission?: Maybe<FormSubmission>;
};

export type ChannelCreationSourceCountAggregateOutputType = {
  __typename?: 'ChannelCreationSourceCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  creationSource: Scalars['Int'];
  workflowEventSettingId: Scalars['Int'];
  userId: Scalars['Int'];
  clinicWidgetRequestId: Scalars['Int'];
  channelId: Scalars['Int'];
  serviceReminderId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelCreationSourceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
};

export type ChannelCreationSourceCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyClinicPetParentInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyClinicWidgetRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyFormSubmissionInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyServiceReminderInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyServiceReminderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyUserInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyWorkflowEventSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceCreateNestedOneWithoutChannelInput = {
  create?: Maybe<ChannelCreationSourceUncheckedCreateWithoutChannelInput>;
  connectOrCreate?: Maybe<ChannelCreationSourceCreateOrConnectWithoutChannelInput>;
  connect?: Maybe<ChannelCreationSourceWhereUniqueInput>;
};

export type ChannelCreationSourceCreateOrConnectWithoutChannelInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceUncheckedCreateWithoutChannelInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceUncheckedCreateWithoutClinicPetParentInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceUncheckedCreateWithoutClinicWidgetRequestInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceUncheckedCreateWithoutFormSubmissionInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceUncheckedCreateWithoutServiceReminderInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutUserInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceUncheckedCreateWithoutUserInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceUncheckedCreateWithoutWorkflowEventSettingInput;
};

export type ChannelCreationSourceCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceInput = {
  creationSource: ChannelCreationSourceType;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceListRelationFilter = {
  every?: Maybe<ChannelCreationSourceWhereInput>;
  some?: Maybe<ChannelCreationSourceWhereInput>;
  none?: Maybe<ChannelCreationSourceWhereInput>;
};

export type ChannelCreationSourceMaxAggregateOutputType = {
  __typename?: 'ChannelCreationSourceMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
};

export type ChannelCreationSourceMinAggregateOutputType = {
  __typename?: 'ChannelCreationSourceMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
};

export type ChannelCreationSourceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelCreationSourceOrderByRelevanceFieldEnum {
  Id = 'id',
  WorkflowEventSettingId = 'workflowEventSettingId',
  UserId = 'userId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  ChannelId = 'channelId',
  ServiceReminderId = 'serviceReminderId',
  ClinicPetParentId = 'clinicPetParentId',
  FormSubmissionId = 'formSubmissionId'
}

export type ChannelCreationSourceOrderByRelevanceInput = {
  fields: Array<ChannelCreationSourceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelCreationSourceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  _count?: Maybe<ChannelCreationSourceCountOrderByAggregateInput>;
  _max?: Maybe<ChannelCreationSourceMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelCreationSourceMinOrderByAggregateInput>;
};

export type ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  workflowEventSetting?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  serviceReminder?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ChannelCreationSourceOrderByRelevanceInput>;
};

export type ChannelCreationSourceRelationFilter = {
  is?: Maybe<ChannelCreationSourceWhereInput>;
  isNot?: Maybe<ChannelCreationSourceWhereInput>;
};

export enum ChannelCreationSourceScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  CreationSource = 'creationSource',
  WorkflowEventSettingId = 'workflowEventSettingId',
  UserId = 'userId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  ChannelId = 'channelId',
  ServiceReminderId = 'serviceReminderId',
  ClinicPetParentId = 'clinicPetParentId',
  FormSubmissionId = 'formSubmissionId'
}

export type ChannelCreationSourceScalarWhereInput = {
  AND?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  OR?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  creationSource?: Maybe<EnumChannelCreationSourceTypeFilter>;
  workflowEventSettingId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
};

export type ChannelCreationSourceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelCreationSourceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelCreationSourceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelCreationSourceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  creationSource?: Maybe<EnumChannelCreationSourceTypeWithAggregatesFilter>;
  workflowEventSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableWithAggregatesFilter>;
  channelId?: Maybe<StringWithAggregatesFilter>;
  serviceReminderId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum ChannelCreationSourceType {
  Automation = 'Automation',
  ClinicInitiated = 'ClinicInitiated',
  ClinicWidgetRequest = 'ClinicWidgetRequest',
  ServiceReminder = 'ServiceReminder',
  ClientInitiated = 'ClientInitiated',
  SystemMessage = 'SystemMessage',
  CallDeflection = 'CallDeflection'
}

export type ChannelCreationSourceUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput = {
  create?: Maybe<ChannelCreationSourceUncheckedCreateWithoutChannelInput>;
  connectOrCreate?: Maybe<ChannelCreationSourceCreateOrConnectWithoutChannelInput>;
  connect?: Maybe<ChannelCreationSourceWhereUniqueInput>;
};

export type ChannelCreationSourceUncheckedCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput = {
  create?: Maybe<ChannelCreationSourceUncheckedCreateWithoutChannelInput>;
  connectOrCreate?: Maybe<ChannelCreationSourceCreateOrConnectWithoutChannelInput>;
  upsert?: Maybe<ChannelCreationSourceUpsertWithoutChannelInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  update?: Maybe<ChannelCreationSourceUncheckedUpdateWithoutChannelInput>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ChannelCreationSourceScalarWhereInput;
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutClinicWidgetRequestInput = {
  where: ChannelCreationSourceScalarWhereInput;
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutFormSubmissionInput = {
  where: ChannelCreationSourceScalarWhereInput;
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutServiceReminderInput = {
  where: ChannelCreationSourceScalarWhereInput;
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutUserInput = {
  where: ChannelCreationSourceScalarWhereInput;
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutWorkflowEventSettingInput = {
  where: ChannelCreationSourceScalarWhereInput;
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
};

export type ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUpdateOneWithoutChannelNestedInput = {
  create?: Maybe<ChannelCreationSourceUncheckedCreateWithoutChannelInput>;
  connectOrCreate?: Maybe<ChannelCreationSourceCreateOrConnectWithoutChannelInput>;
  upsert?: Maybe<ChannelCreationSourceUpsertWithoutChannelInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  update?: Maybe<ChannelCreationSourceUncheckedUpdateWithoutChannelInput>;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  data: ChannelCreationSourceUncheckedUpdateWithoutClinicPetParentInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicWidgetRequestInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  data: ChannelCreationSourceUncheckedUpdateWithoutClinicWidgetRequestInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  data: ChannelCreationSourceUncheckedUpdateWithoutFormSubmissionInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutServiceReminderInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  data: ChannelCreationSourceUncheckedUpdateWithoutServiceReminderInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutUserInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  data: ChannelCreationSourceUncheckedUpdateWithoutUserInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutWorkflowEventSettingInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  data: ChannelCreationSourceUncheckedUpdateWithoutWorkflowEventSettingInput;
};

export type ChannelCreationSourceUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpdateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesNestedInput>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpdateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceNestedInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutClinicPetParentInput;
  create: ChannelCreationSourceUncheckedCreateWithoutClinicPetParentInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicWidgetRequestInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutClinicWidgetRequestInput;
  create: ChannelCreationSourceUncheckedCreateWithoutClinicWidgetRequestInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutFormSubmissionInput;
  create: ChannelCreationSourceUncheckedCreateWithoutFormSubmissionInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutServiceReminderInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutServiceReminderInput;
  create: ChannelCreationSourceUncheckedCreateWithoutServiceReminderInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutUserInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutUserInput;
  create: ChannelCreationSourceUncheckedCreateWithoutUserInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutWorkflowEventSettingInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutWorkflowEventSettingInput;
  create: ChannelCreationSourceUncheckedCreateWithoutWorkflowEventSettingInput;
};

export type ChannelCreationSourceUpsertWithoutChannelInput = {
  update: ChannelCreationSourceUncheckedUpdateWithoutChannelInput;
  create: ChannelCreationSourceUncheckedCreateWithoutChannelInput;
};

export type ChannelCreationSourceWhereInput = {
  AND?: Maybe<Array<ChannelCreationSourceWhereInput>>;
  OR?: Maybe<Array<ChannelCreationSourceWhereInput>>;
  NOT?: Maybe<Array<ChannelCreationSourceWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  creationSource?: Maybe<EnumChannelCreationSourceTypeFilter>;
  workflowEventSettingId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  workflowEventSetting?: Maybe<WorkflowEventSettingWhereInput>;
  user?: Maybe<UserWhereInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestWhereInput>;
  channel?: Maybe<ChannelWhereInput>;
  serviceReminder?: Maybe<ServiceReminderWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
};

export type ChannelCreationSourceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type ChannelExportWhereInput = {
  id: Scalars['String'];
  includeNotes?: Maybe<Scalars['Boolean']>;
  fileName?: Maybe<Scalars['String']>;
};

export type ChannelFilterSelection = {
  __typename?: 'ChannelFilterSelection';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
  clinicId: Scalars['String'];
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
  clinic: Clinic;
  userChannelFilterSelections: Array<UserChannelFilterSelection>;
  _count: ChannelFilterSelectionCountOutputType;
};


export type ChannelFilterSelectionUserChannelFilterSelectionsArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserChannelFilterSelectionScalarFieldEnum>;
};

export type ChannelFilterSelectionCountAggregateOutputType = {
  __typename?: 'ChannelFilterSelectionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isDeleted: Scalars['Int'];
  clinicId: Scalars['Int'];
  name: Scalars['Int'];
  filters: Scalars['Int'];
  filterSelectionType: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelFilterSelectionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  filters?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
};

export type ChannelFilterSelectionCountOutputType = {
  __typename?: 'ChannelFilterSelectionCountOutputType';
  userChannelFilterSelections: Scalars['Int'];
};

export type ChannelFilterSelectionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
  clinic: ClinicCreateNestedOneWithoutChannelFilterSelectionsInput;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
};

export type ChannelFilterSelectionCreateManyClinicInputEnvelope = {
  data: Array<ChannelFilterSelectionCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelFilterSelectionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId: Scalars['String'];
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
};

export type ChannelFilterSelectionCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelFilterSelectionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelFilterSelectionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ChannelFilterSelectionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
};

export type ChannelFilterSelectionCreateNestedOneWithoutUserChannelFilterSelectionsInput = {
  create?: Maybe<ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput>;
  connectOrCreate?: Maybe<ChannelFilterSelectionCreateOrConnectWithoutUserChannelFilterSelectionsInput>;
  connect?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
};

export type ChannelFilterSelectionCreateOrConnectWithoutClinicInput = {
  where: ChannelFilterSelectionWhereUniqueInput;
  create: ChannelFilterSelectionUncheckedCreateWithoutClinicInput;
};

export type ChannelFilterSelectionCreateOrConnectWithoutUserChannelFilterSelectionsInput = {
  where: ChannelFilterSelectionWhereUniqueInput;
  create: ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput;
};

export type ChannelFilterSelectionCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionCreateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
  clinic: ClinicCreateNestedOneWithoutChannelFilterSelectionsInput;
};

export type ChannelFilterSelectionListRelationFilter = {
  every?: Maybe<ChannelFilterSelectionWhereInput>;
  some?: Maybe<ChannelFilterSelectionWhereInput>;
  none?: Maybe<ChannelFilterSelectionWhereInput>;
};

export type ChannelFilterSelectionMaxAggregateOutputType = {
  __typename?: 'ChannelFilterSelectionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
};

export type ChannelFilterSelectionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
};

export type ChannelFilterSelectionMinAggregateOutputType = {
  __typename?: 'ChannelFilterSelectionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
};

export type ChannelFilterSelectionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
};

export type ChannelFilterSelectionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelFilterSelectionOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  Name = 'name'
}

export type ChannelFilterSelectionOrderByRelevanceInput = {
  fields: Array<ChannelFilterSelectionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelFilterSelectionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  filters?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
  _count?: Maybe<ChannelFilterSelectionCountOrderByAggregateInput>;
  _max?: Maybe<ChannelFilterSelectionMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelFilterSelectionMinOrderByAggregateInput>;
};

export type ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  filters?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionOrderByRelationAggregateInput>;
  _relevance?: Maybe<ChannelFilterSelectionOrderByRelevanceInput>;
};

export type ChannelFilterSelectionRelationFilter = {
  is?: Maybe<ChannelFilterSelectionWhereInput>;
  isNot?: Maybe<ChannelFilterSelectionWhereInput>;
};

export enum ChannelFilterSelectionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsDeleted = 'isDeleted',
  ClinicId = 'clinicId',
  Name = 'name',
  Filters = 'filters',
  FilterSelectionType = 'filterSelectionType'
}

export type ChannelFilterSelectionScalarWhereInput = {
  AND?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
  OR?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinicId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  filters?: Maybe<JsonFilter>;
  filterSelectionType?: Maybe<EnumFilterSelectionTypeFilter>;
};

export type ChannelFilterSelectionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  filters?: Maybe<JsonWithAggregatesFilter>;
  filterSelectionType?: Maybe<EnumFilterSelectionTypeWithAggregatesFilter>;
};

export type ChannelFilterSelectionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId: Scalars['String'];
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelFilterSelectionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelFilterSelectionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ChannelFilterSelectionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
};

export type ChannelFilterSelectionUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId: Scalars['String'];
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
};

export type ChannelFilterSelectionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutFilterSelectionNestedInput>;
};

export type ChannelFilterSelectionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
};

export type ChannelFilterSelectionUncheckedUpdateManyWithoutChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
};

export type ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ChannelFilterSelectionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelFilterSelectionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelFilterSelectionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ChannelFilterSelectionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelFilterSelectionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelFilterSelectionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
};

export type ChannelFilterSelectionUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutFilterSelectionNestedInput>;
};

export type ChannelFilterSelectionUncheckedUpdateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
};

export type ChannelFilterSelectionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelFilterSelectionsNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutFilterSelectionNestedInput>;
};

export type ChannelFilterSelectionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
};

export type ChannelFilterSelectionUpdateManyWithWhereWithoutClinicInput = {
  where: ChannelFilterSelectionScalarWhereInput;
  data: ChannelFilterSelectionUncheckedUpdateManyWithoutChannelFilterSelectionsInput;
};

export type ChannelFilterSelectionUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ChannelFilterSelectionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelFilterSelectionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelFilterSelectionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ChannelFilterSelectionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelFilterSelectionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelFilterSelectionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
};

export type ChannelFilterSelectionUpdateOneRequiredWithoutUserChannelFilterSelectionsNestedInput = {
  create?: Maybe<ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput>;
  connectOrCreate?: Maybe<ChannelFilterSelectionCreateOrConnectWithoutUserChannelFilterSelectionsInput>;
  upsert?: Maybe<ChannelFilterSelectionUpsertWithoutUserChannelFilterSelectionsInput>;
  connect?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  update?: Maybe<ChannelFilterSelectionUncheckedUpdateWithoutUserChannelFilterSelectionsInput>;
};

export type ChannelFilterSelectionUpdateWithWhereUniqueWithoutClinicInput = {
  where: ChannelFilterSelectionWhereUniqueInput;
  data: ChannelFilterSelectionUncheckedUpdateWithoutClinicInput;
};

export type ChannelFilterSelectionUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutFilterSelectionNestedInput>;
};

export type ChannelFilterSelectionUpdateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelFilterSelectionsNestedInput>;
};

export type ChannelFilterSelectionUpsertWithWhereUniqueWithoutClinicInput = {
  where: ChannelFilterSelectionWhereUniqueInput;
  update: ChannelFilterSelectionUncheckedUpdateWithoutClinicInput;
  create: ChannelFilterSelectionUncheckedCreateWithoutClinicInput;
};

export type ChannelFilterSelectionUpsertWithoutUserChannelFilterSelectionsInput = {
  update: ChannelFilterSelectionUncheckedUpdateWithoutUserChannelFilterSelectionsInput;
  create: ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput;
};

export type ChannelFilterSelectionWhereInput = {
  AND?: Maybe<Array<ChannelFilterSelectionWhereInput>>;
  OR?: Maybe<Array<ChannelFilterSelectionWhereInput>>;
  NOT?: Maybe<Array<ChannelFilterSelectionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinicId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  filters?: Maybe<JsonFilter>;
  filterSelectionType?: Maybe<EnumFilterSelectionTypeFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionListRelationFilter>;
};

export type ChannelFilterSelectionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelListRelationFilter = {
  every?: Maybe<ChannelWhereInput>;
  some?: Maybe<ChannelWhereInput>;
  none?: Maybe<ChannelWhereInput>;
};

export type ChannelMaxAggregateOutputType = {
  __typename?: 'ChannelMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  friendlyName?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  lastMessageId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
  lastExportAt?: Maybe<SortOrder>;
};

export type ChannelMember = {
  __typename?: 'ChannelMember';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification: Scalars['Boolean'];
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<Channel>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  user?: Maybe<User>;
  channelMessages: Array<ChannelMessage>;
  _count: ChannelMemberCountOutputType;
};


export type ChannelMemberChannelMessagesArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageScalarFieldEnum>;
};

export type ChannelMemberAvgAggregateOutputType = {
  __typename?: 'ChannelMemberAvgAggregateOutputType';
  lastConsumedMessageIndex?: Maybe<Scalars['Float']>;
};

export type ChannelMemberAvgOrderByAggregateInput = {
  lastConsumedMessageIndex?: Maybe<SortOrder>;
};

export type ChannelMemberCountAggregateOutputType = {
  __typename?: 'ChannelMemberCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  twilioIdentity: Scalars['Int'];
  attributes: Scalars['Int'];
  lastConsumedMessageIndex: Scalars['Int'];
  lastConsumptionTimestamp: Scalars['Int'];
  twilioMemberSid: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  userId: Scalars['Int'];
  channelId: Scalars['Int'];
  sendMessageNotification: Scalars['Int'];
  removedAt: Scalars['Int'];
  draft: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelMemberCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
  draft?: Maybe<SortOrder>;
};

export type ChannelMemberCountOutputType = {
  __typename?: 'ChannelMemberCountOutputType';
  channelMessages: Scalars['Int'];
};

export type ChannelMemberCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelMembersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelMembersInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberCreateManyChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberCreateManyChannelInputEnvelope = {
  data: Array<ChannelMemberCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMemberCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberCreateManyClinicPetParentInputEnvelope = {
  data: Array<ChannelMemberCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMemberCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberCreateManyUserInputEnvelope = {
  data: Array<ChannelMemberCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMemberCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<ChannelMemberCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
};

export type ChannelMemberCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelMemberCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
};

export type ChannelMemberCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelMemberCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
};

export type ChannelMemberCreateNestedOneWithoutChannelMessagesInput = {
  create?: Maybe<ChannelMemberUncheckedCreateWithoutChannelMessagesInput>;
  connectOrCreate?: Maybe<ChannelMemberCreateOrConnectWithoutChannelMessagesInput>;
  connect?: Maybe<ChannelMemberWhereUniqueInput>;
};

export type ChannelMemberCreateOrConnectWithoutChannelInput = {
  where: ChannelMemberWhereUniqueInput;
  create: ChannelMemberUncheckedCreateWithoutChannelInput;
};

export type ChannelMemberCreateOrConnectWithoutChannelMessagesInput = {
  where: ChannelMemberWhereUniqueInput;
  create: ChannelMemberUncheckedCreateWithoutChannelMessagesInput;
};

export type ChannelMemberCreateOrConnectWithoutClinicPetParentInput = {
  where: ChannelMemberWhereUniqueInput;
  create: ChannelMemberUncheckedCreateWithoutClinicPetParentInput;
};

export type ChannelMemberCreateOrConnectWithoutUserInput = {
  where: ChannelMemberWhereUniqueInput;
  create: ChannelMemberUncheckedCreateWithoutUserInput;
};

export type ChannelMemberCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelMembersInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberCreateWithoutChannelMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelMembersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelMembersInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelMembersInput>;
};

export type ChannelMemberCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelMembersInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelMembersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberListRelationFilter = {
  every?: Maybe<ChannelMemberWhereInput>;
  some?: Maybe<ChannelMemberWhereInput>;
  none?: Maybe<ChannelMemberWhereInput>;
};

export type ChannelMemberMaxAggregateOutputType = {
  __typename?: 'ChannelMemberMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMemberMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
};

export type ChannelMemberMinAggregateOutputType = {
  __typename?: 'ChannelMemberMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMemberMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
};

export type ChannelMemberOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelMemberOrderByRelevanceFieldEnum {
  Id = 'id',
  TwilioIdentity = 'twilioIdentity',
  TwilioMemberSid = 'twilioMemberSid',
  ClinicPetParentId = 'clinicPetParentId',
  UserId = 'userId',
  ChannelId = 'channelId'
}

export type ChannelMemberOrderByRelevanceInput = {
  fields: Array<ChannelMemberOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelMemberOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
  draft?: Maybe<SortOrder>;
  _count?: Maybe<ChannelMemberCountOrderByAggregateInput>;
  _avg?: Maybe<ChannelMemberAvgOrderByAggregateInput>;
  _max?: Maybe<ChannelMemberMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMemberMinOrderByAggregateInput>;
  _sum?: Maybe<ChannelMemberSumOrderByAggregateInput>;
};

export type ChannelMemberOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
  draft?: Maybe<SortOrder>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  channelMessages?: Maybe<ChannelMessageOrderByRelationAggregateInput>;
  _relevance?: Maybe<ChannelMemberOrderByRelevanceInput>;
};

export type ChannelMemberRelationFilter = {
  is?: Maybe<ChannelMemberWhereInput>;
  isNot?: Maybe<ChannelMemberWhereInput>;
};

export enum ChannelMemberScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  TwilioIdentity = 'twilioIdentity',
  Attributes = 'attributes',
  LastConsumedMessageIndex = 'lastConsumedMessageIndex',
  LastConsumptionTimestamp = 'lastConsumptionTimestamp',
  TwilioMemberSid = 'twilioMemberSid',
  ClinicPetParentId = 'clinicPetParentId',
  UserId = 'userId',
  ChannelId = 'channelId',
  SendMessageNotification = 'sendMessageNotification',
  RemovedAt = 'removedAt',
  Draft = 'draft'
}

export type ChannelMemberScalarWhereInput = {
  AND?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  OR?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  twilioIdentity?: Maybe<StringNullableFilter>;
  attributes?: Maybe<JsonNullableFilter>;
  lastConsumedMessageIndex?: Maybe<IntNullableFilter>;
  lastConsumptionTimestamp?: Maybe<DateTimeNullableFilter>;
  twilioMemberSid?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  sendMessageNotification?: Maybe<BoolFilter>;
  removedAt?: Maybe<DateTimeNullableFilter>;
  draft?: Maybe<JsonNullableFilter>;
};

export type ChannelMemberScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelMemberScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelMemberScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelMemberScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  twilioIdentity?: Maybe<StringNullableWithAggregatesFilter>;
  attributes?: Maybe<JsonNullableWithAggregatesFilter>;
  lastConsumedMessageIndex?: Maybe<IntNullableWithAggregatesFilter>;
  lastConsumptionTimestamp?: Maybe<DateTimeNullableWithAggregatesFilter>;
  twilioMemberSid?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
  sendMessageNotification?: Maybe<BoolWithAggregatesFilter>;
  removedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  draft?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ChannelMemberSubscription = {
  __typename?: 'ChannelMemberSubscription';
  node: ChannelMember;
  mutation: MutationType;
};

export type ChannelMemberSumAggregateOutputType = {
  __typename?: 'ChannelMemberSumAggregateOutputType';
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
};

export type ChannelMemberSumOrderByAggregateInput = {
  lastConsumedMessageIndex?: Maybe<SortOrder>;
};

export type ChannelMemberUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberUncheckedCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<ChannelMemberCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
};

export type ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelMemberCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
};

export type ChannelMemberUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelMemberCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
};

export type ChannelMemberUncheckedCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberUncheckedCreateWithoutChannelMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAuthorNestedInput>;
};

export type ChannelMemberUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberUncheckedUpdateManyWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<ChannelMemberCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
};

export type ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelMemberCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
};

export type ChannelMemberUncheckedUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelMemberCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
};

export type ChannelMemberUncheckedUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAuthorNestedInput>;
};

export type ChannelMemberUncheckedUpdateWithoutChannelMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAuthorNestedInput>;
};

export type ChannelMemberUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAuthorNestedInput>;
};

export type ChannelMemberUniqueChannelMemberForPetParentAndChannelCompoundUniqueInput = {
  channelId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type ChannelMemberUniqueChannelMemberForUserAndChannelCompoundUniqueInput = {
  channelId: Scalars['String'];
  userId: Scalars['String'];
};

export type ChannelMemberUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelUpdateOneWithoutChannelMembersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelMembersNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelMembersNestedInput>;
  channelMessages?: Maybe<ChannelMessageUpdateManyWithoutAuthorNestedInput>;
};

export type ChannelMemberUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberUpdateManyWithWhereWithoutChannelInput = {
  where: ChannelMemberScalarWhereInput;
  data: ChannelMemberUncheckedUpdateManyWithoutChannelMembersInput;
};

export type ChannelMemberUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ChannelMemberScalarWhereInput;
  data: ChannelMemberUncheckedUpdateManyWithoutChannelMembersInput;
};

export type ChannelMemberUpdateManyWithWhereWithoutUserInput = {
  where: ChannelMemberScalarWhereInput;
  data: ChannelMemberUncheckedUpdateManyWithoutChannelMembersInput;
};

export type ChannelMemberUpdateManyWithoutChannelNestedInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<ChannelMemberCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
};

export type ChannelMemberUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelMemberCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
};

export type ChannelMemberUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelMemberCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
};

export type ChannelMemberUpdateOneWithoutChannelMessagesNestedInput = {
  create?: Maybe<ChannelMemberUncheckedCreateWithoutChannelMessagesInput>;
  connectOrCreate?: Maybe<ChannelMemberCreateOrConnectWithoutChannelMessagesInput>;
  upsert?: Maybe<ChannelMemberUpsertWithoutChannelMessagesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelMemberWhereUniqueInput>;
  update?: Maybe<ChannelMemberUncheckedUpdateWithoutChannelMessagesInput>;
};

export type ChannelMemberUpdateWithWhereUniqueWithoutChannelInput = {
  where: ChannelMemberWhereUniqueInput;
  data: ChannelMemberUncheckedUpdateWithoutChannelInput;
};

export type ChannelMemberUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ChannelMemberWhereUniqueInput;
  data: ChannelMemberUncheckedUpdateWithoutClinicPetParentInput;
};

export type ChannelMemberUpdateWithWhereUniqueWithoutUserInput = {
  where: ChannelMemberWhereUniqueInput;
  data: ChannelMemberUncheckedUpdateWithoutUserInput;
};

export type ChannelMemberUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelMembersNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelMembersNestedInput>;
  channelMessages?: Maybe<ChannelMessageUpdateManyWithoutAuthorNestedInput>;
};

export type ChannelMemberUpdateWithoutChannelMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelUpdateOneWithoutChannelMembersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelMembersNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelMembersNestedInput>;
};

export type ChannelMemberUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelUpdateOneWithoutChannelMembersNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelMembersNestedInput>;
  channelMessages?: Maybe<ChannelMessageUpdateManyWithoutAuthorNestedInput>;
};

export type ChannelMemberUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelUpdateOneWithoutChannelMembersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelMembersNestedInput>;
  channelMessages?: Maybe<ChannelMessageUpdateManyWithoutAuthorNestedInput>;
};

export type ChannelMemberUpsertWithWhereUniqueWithoutChannelInput = {
  where: ChannelMemberWhereUniqueInput;
  update: ChannelMemberUncheckedUpdateWithoutChannelInput;
  create: ChannelMemberUncheckedCreateWithoutChannelInput;
};

export type ChannelMemberUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ChannelMemberWhereUniqueInput;
  update: ChannelMemberUncheckedUpdateWithoutClinicPetParentInput;
  create: ChannelMemberUncheckedCreateWithoutClinicPetParentInput;
};

export type ChannelMemberUpsertWithWhereUniqueWithoutUserInput = {
  where: ChannelMemberWhereUniqueInput;
  update: ChannelMemberUncheckedUpdateWithoutUserInput;
  create: ChannelMemberUncheckedCreateWithoutUserInput;
};

export type ChannelMemberUpsertWithoutChannelMessagesInput = {
  update: ChannelMemberUncheckedUpdateWithoutChannelMessagesInput;
  create: ChannelMemberUncheckedCreateWithoutChannelMessagesInput;
};

export type ChannelMemberWhereInput = {
  AND?: Maybe<Array<ChannelMemberWhereInput>>;
  OR?: Maybe<Array<ChannelMemberWhereInput>>;
  NOT?: Maybe<Array<ChannelMemberWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  twilioIdentity?: Maybe<StringNullableFilter>;
  attributes?: Maybe<JsonNullableFilter>;
  lastConsumedMessageIndex?: Maybe<IntNullableFilter>;
  lastConsumptionTimestamp?: Maybe<DateTimeNullableFilter>;
  twilioMemberSid?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  sendMessageNotification?: Maybe<BoolFilter>;
  removedAt?: Maybe<DateTimeNullableFilter>;
  draft?: Maybe<JsonNullableFilter>;
  channel?: Maybe<ChannelWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  user?: Maybe<UserWhereInput>;
  channelMessages?: Maybe<ChannelMessageListRelationFilter>;
};

export type ChannelMemberWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  uniqueChannelMemberForPetParentAndChannel?: Maybe<ChannelMemberUniqueChannelMemberForPetParentAndChannelCompoundUniqueInput>;
  uniqueChannelMemberForUserAndChannel?: Maybe<ChannelMemberUniqueChannelMemberForUserAndChannelCompoundUniqueInput>;
};

export type ChannelMessage = {
  __typename?: 'ChannelMessage';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited: Scalars['Boolean'];
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source: MessageSource;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt: Scalars['Boolean'];
  isUnreadEligible: Scalars['Boolean'];
  channel?: Maybe<Channel>;
  author?: Maybe<ChannelMember>;
  lastMessageChannel: Array<Channel>;
  attachments: Array<ChannelMessageAttachment>;
  autoResponses: Array<ChannelMessageAutoResponse>;
  mentionedUsers: Array<User>;
  automationRun?: Maybe<AutomationRun>;
  smsNotifificationStatuses: Array<SmsNotificationStatus>;
  reactions: Array<ChannelMessageReaction>;
  pushNotificationLogs: Array<PushNotificationLog>;
  _count: ChannelMessageCountOutputType;
};


export type ChannelMessageLastMessageChannelArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
};


export type ChannelMessageAttachmentsArgs = {
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
  orderBy?: Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageAttachmentScalarFieldEnum>;
};


export type ChannelMessageAutoResponsesArgs = {
  where?: Maybe<ChannelMessageAutoResponseWhereInput>;
  orderBy?: Maybe<ChannelMessageAutoResponseOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageAutoResponseWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageAutoResponseScalarFieldEnum>;
};


export type ChannelMessageMentionedUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};


export type ChannelMessageSmsNotifificationStatusesArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
  orderBy?: Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SmsNotificationStatusScalarFieldEnum>;
};


export type ChannelMessageReactionsArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
  orderBy?: Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageReactionScalarFieldEnum>;
};


export type ChannelMessagePushNotificationLogsArgs = {
  where?: Maybe<PushNotificationLogWhereInput>;
  orderBy?: Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PushNotificationLogScalarFieldEnum>;
};

export type ChannelMessageAttachment = {
  __typename?: 'ChannelMessageAttachment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  mediaId?: Maybe<Scalars['String']>;
  channelMessage: Array<ChannelMessage>;
  media?: Maybe<Media>;
  _count: ChannelMessageAttachmentCountOutputType;
  computed_urlExpiresAt?: Maybe<Scalars['DateTime']>;
  computed_optimizedAttachment?: Maybe<Scalars['String']>;
};


export type ChannelMessageAttachmentChannelMessageArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageScalarFieldEnum>;
};

export type ChannelMessageAttachmentCountAggregateOutputType = {
  __typename?: 'ChannelMessageAttachmentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  attachmentType: Scalars['Int'];
  entityType: Scalars['Int'];
  entityId: Scalars['Int'];
  filename: Scalars['Int'];
  url: Scalars['Int'];
  uploadStatus: Scalars['Int'];
  mediaId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelMessageAttachmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  attachmentType?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
};

export type ChannelMessageAttachmentCountOutputType = {
  __typename?: 'ChannelMessageAttachmentCountOutputType';
  channelMessage: Scalars['Int'];
};

export type ChannelMessageAttachmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  channelMessage?: Maybe<ChannelMessageCreateNestedManyWithoutAttachmentsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutChannelMessageAttachmentsInput>;
};

export type ChannelMessageAttachmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  mediaId?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentCreateManyMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export type ChannelMessageAttachmentCreateManyMediaInputEnvelope = {
  data: Array<ChannelMessageAttachmentCreateManyMediaInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput = {
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput>>;
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
};

export type ChannelMessageAttachmentCreateNestedManyWithoutMediaInput = {
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutMediaInput>>;
  createMany?: Maybe<ChannelMessageAttachmentCreateManyMediaInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
};

export type ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput = {
  where: ChannelMessageAttachmentWhereUniqueInput;
  create: ChannelMessageAttachmentUncheckedCreateWithoutChannelMessageInput;
};

export type ChannelMessageAttachmentCreateOrConnectWithoutMediaInput = {
  where: ChannelMessageAttachmentWhereUniqueInput;
  create: ChannelMessageAttachmentUncheckedCreateWithoutMediaInput;
};

export type ChannelMessageAttachmentCreateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  media?: Maybe<MediaCreateNestedOneWithoutChannelMessageAttachmentsInput>;
};

export type ChannelMessageAttachmentCreateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  channelMessage?: Maybe<ChannelMessageCreateNestedManyWithoutAttachmentsInput>;
};

export enum ChannelMessageAttachmentEntityType {
  Form = 'Form',
  Appointment = 'Appointment',
  Transaction = 'Transaction',
  StripePaymentIntent = 'StripePaymentIntent',
  ClinicWidgetRequest = 'ClinicWidgetRequest',
  AutomationRun = 'AutomationRun',
  ServiceReminderNotification = 'ServiceReminderNotification'
}

export type ChannelMessageAttachmentListRelationFilter = {
  every?: Maybe<ChannelMessageAttachmentWhereInput>;
  some?: Maybe<ChannelMessageAttachmentWhereInput>;
  none?: Maybe<ChannelMessageAttachmentWhereInput>;
};

export type ChannelMessageAttachmentMaxAggregateOutputType = {
  __typename?: 'ChannelMessageAttachmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  mediaId?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  attachmentType?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
};

export type ChannelMessageAttachmentMinAggregateOutputType = {
  __typename?: 'ChannelMessageAttachmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  mediaId?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  attachmentType?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
};

export type ChannelMessageAttachmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelMessageAttachmentOrderByRelevanceFieldEnum {
  Id = 'id',
  EntityId = 'entityId',
  Filename = 'filename',
  Url = 'url',
  MediaId = 'mediaId'
}

export type ChannelMessageAttachmentOrderByRelevanceInput = {
  fields: Array<ChannelMessageAttachmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelMessageAttachmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  attachmentType?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  _count?: Maybe<ChannelMessageAttachmentCountOrderByAggregateInput>;
  _max?: Maybe<ChannelMessageAttachmentMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMessageAttachmentMinOrderByAggregateInput>;
};

export type ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  attachmentType?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  channelMessage?: Maybe<ChannelMessageOrderByRelationAggregateInput>;
  media?: Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ChannelMessageAttachmentOrderByRelevanceInput>;
};

export enum ChannelMessageAttachmentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  AttachmentType = 'attachmentType',
  EntityType = 'entityType',
  EntityId = 'entityId',
  Filename = 'filename',
  Url = 'url',
  UploadStatus = 'uploadStatus',
  MediaId = 'mediaId'
}

export type ChannelMessageAttachmentScalarWhereInput = {
  AND?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
  OR?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  attachmentType?: Maybe<EnumChannelMessageAttachmentTypeFilter>;
  entityType?: Maybe<EnumChannelMessageAttachmentEntityTypeNullableFilter>;
  entityId?: Maybe<StringNullableFilter>;
  filename?: Maybe<StringNullableFilter>;
  url?: Maybe<StringNullableFilter>;
  uploadStatus?: Maybe<EnumChannelMessageAttachmentUploadStatusNullableFilter>;
  mediaId?: Maybe<StringNullableFilter>;
};

export type ChannelMessageAttachmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelMessageAttachmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelMessageAttachmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelMessageAttachmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  attachmentType?: Maybe<EnumChannelMessageAttachmentTypeWithAggregatesFilter>;
  entityType?: Maybe<EnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter>;
  entityId?: Maybe<StringNullableWithAggregatesFilter>;
  filename?: Maybe<StringNullableWithAggregatesFilter>;
  url?: Maybe<StringNullableWithAggregatesFilter>;
  uploadStatus?: Maybe<EnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter>;
  mediaId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ChannelMessageAttachmentSubscription = {
  __typename?: 'ChannelMessageAttachmentSubscription';
  node: ChannelMessageAttachment;
  mutation: MutationType;
};

export enum ChannelMessageAttachmentType {
  Image = 'Image',
  Video = 'Video',
  File = 'File',
  FormRequest = 'FormRequest',
  ServiceReminderNotification = 'ServiceReminderNotification',
  ServiceReminderFormSubmission = 'ServiceReminderFormSubmission',
  PetParentRequest = 'PetParentRequest',
  AppointmentConfirmation = 'AppointmentConfirmation',
  AppointmentReminder = 'AppointmentReminder',
  AppointmentArrival = 'AppointmentArrival',
  Invoice = 'Invoice',
  CallRequest = 'CallRequest',
  WidgetRequest = 'WidgetRequest',
  CallHistory = 'CallHistory',
  AutomationRun = 'AutomationRun'
}

export type ChannelMessageAttachmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  mediaId?: Maybe<Scalars['String']>;
  channelMessage?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAttachmentsInput>;
};

export type ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput = {
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput>>;
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
};

export type ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput = {
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutMediaInput>>;
  createMany?: Maybe<ChannelMessageAttachmentCreateManyMediaInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
};

export type ChannelMessageAttachmentUncheckedCreateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  mediaId?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUncheckedCreateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  channelMessage?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAttachmentsInput>;
};

export type ChannelMessageAttachmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  mediaId?: Maybe<Scalars['String']>;
  channelMessage?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAttachmentsNestedInput>;
};

export type ChannelMessageAttachmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  mediaId?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUncheckedUpdateManyWithoutAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  mediaId?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export type ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput = {
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageAttachmentUpsertWithWhereUniqueWithoutChannelMessageInput>>;
  set?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageAttachmentUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageAttachmentUpdateManyWithWhereWithoutChannelMessageInput>>;
  deleteMany?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
};

export type ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput = {
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutMediaInput>>;
  upsert?: Maybe<Array<ChannelMessageAttachmentUpsertWithWhereUniqueWithoutMediaInput>>;
  createMany?: Maybe<ChannelMessageAttachmentCreateManyMediaInputEnvelope>;
  set?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageAttachmentUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<ChannelMessageAttachmentUpdateManyWithWhereWithoutMediaInput>>;
  deleteMany?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
};

export type ChannelMessageAttachmentUncheckedUpdateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  mediaId?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUncheckedUpdateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  channelMessage?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAttachmentsNestedInput>;
};

export type ChannelMessageAttachmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  channelMessage?: Maybe<ChannelMessageUpdateManyWithoutAttachmentsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutChannelMessageAttachmentsNestedInput>;
};

export type ChannelMessageAttachmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export type ChannelMessageAttachmentUpdateManyWithWhereWithoutChannelMessageInput = {
  where: ChannelMessageAttachmentScalarWhereInput;
  data: ChannelMessageAttachmentUncheckedUpdateManyWithoutAttachmentsInput;
};

export type ChannelMessageAttachmentUpdateManyWithWhereWithoutMediaInput = {
  where: ChannelMessageAttachmentScalarWhereInput;
  data: ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageAttachmentsInput;
};

export type ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput = {
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageAttachmentUpsertWithWhereUniqueWithoutChannelMessageInput>>;
  set?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageAttachmentUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageAttachmentUpdateManyWithWhereWithoutChannelMessageInput>>;
  deleteMany?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
};

export type ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput = {
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutMediaInput>>;
  upsert?: Maybe<Array<ChannelMessageAttachmentUpsertWithWhereUniqueWithoutMediaInput>>;
  createMany?: Maybe<ChannelMessageAttachmentCreateManyMediaInputEnvelope>;
  set?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageAttachmentUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<ChannelMessageAttachmentUpdateManyWithWhereWithoutMediaInput>>;
  deleteMany?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
};

export type ChannelMessageAttachmentUpdateWithWhereUniqueWithoutChannelMessageInput = {
  where: ChannelMessageAttachmentWhereUniqueInput;
  data: ChannelMessageAttachmentUncheckedUpdateWithoutChannelMessageInput;
};

export type ChannelMessageAttachmentUpdateWithWhereUniqueWithoutMediaInput = {
  where: ChannelMessageAttachmentWhereUniqueInput;
  data: ChannelMessageAttachmentUncheckedUpdateWithoutMediaInput;
};

export type ChannelMessageAttachmentUpdateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  media?: Maybe<MediaUpdateOneWithoutChannelMessageAttachmentsNestedInput>;
};

export type ChannelMessageAttachmentUpdateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  channelMessage?: Maybe<ChannelMessageUpdateManyWithoutAttachmentsNestedInput>;
};

export enum ChannelMessageAttachmentUploadStatus {
  Success = 'Success',
  Error = 'Error',
  InProgress = 'InProgress',
  Pending = 'Pending'
}

export type ChannelMessageAttachmentUpsertWithWhereUniqueWithoutChannelMessageInput = {
  where: ChannelMessageAttachmentWhereUniqueInput;
  update: ChannelMessageAttachmentUncheckedUpdateWithoutChannelMessageInput;
  create: ChannelMessageAttachmentUncheckedCreateWithoutChannelMessageInput;
};

export type ChannelMessageAttachmentUpsertWithWhereUniqueWithoutMediaInput = {
  where: ChannelMessageAttachmentWhereUniqueInput;
  update: ChannelMessageAttachmentUncheckedUpdateWithoutMediaInput;
  create: ChannelMessageAttachmentUncheckedCreateWithoutMediaInput;
};

export type ChannelMessageAttachmentWhereInput = {
  AND?: Maybe<Array<ChannelMessageAttachmentWhereInput>>;
  OR?: Maybe<Array<ChannelMessageAttachmentWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageAttachmentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  attachmentType?: Maybe<EnumChannelMessageAttachmentTypeFilter>;
  entityType?: Maybe<EnumChannelMessageAttachmentEntityTypeNullableFilter>;
  entityId?: Maybe<StringNullableFilter>;
  filename?: Maybe<StringNullableFilter>;
  url?: Maybe<StringNullableFilter>;
  uploadStatus?: Maybe<EnumChannelMessageAttachmentUploadStatusNullableFilter>;
  mediaId?: Maybe<StringNullableFilter>;
  channelMessage?: Maybe<ChannelMessageListRelationFilter>;
  media?: Maybe<MediaWhereInput>;
};

export type ChannelMessageAttachmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelMessageAutoResponse = {
  __typename?: 'ChannelMessageAutoResponse';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  aiSuggestionMessage: Scalars['String'];
  channelMessageId?: Maybe<Scalars['String']>;
  suggestionStatus: AutoResponseSuggestionStatus;
  channelMessage?: Maybe<ChannelMessage>;
};

export type ChannelMessageAutoResponseCountAggregateOutputType = {
  __typename?: 'ChannelMessageAutoResponseCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  aiSuggestionMessage: Scalars['Int'];
  channelMessageId: Scalars['Int'];
  suggestionStatus: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelMessageAutoResponseCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  aiSuggestionMessage?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  suggestionStatus?: Maybe<SortOrder>;
};

export type ChannelMessageAutoResponseCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage: Scalars['String'];
  suggestionStatus: AutoResponseSuggestionStatus;
  channelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutAutoResponsesInput>;
};

export type ChannelMessageAutoResponseCreateManyChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage: Scalars['String'];
  suggestionStatus: AutoResponseSuggestionStatus;
};

export type ChannelMessageAutoResponseCreateManyChannelMessageInputEnvelope = {
  data: Array<ChannelMessageAutoResponseCreateManyChannelMessageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageAutoResponseCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage: Scalars['String'];
  channelMessageId?: Maybe<Scalars['String']>;
  suggestionStatus: AutoResponseSuggestionStatus;
};

export type ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput = {
  create?: Maybe<Array<ChannelMessageAutoResponseCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAutoResponseCreateOrConnectWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageAutoResponseCreateManyChannelMessageInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
};

export type ChannelMessageAutoResponseCreateOrConnectWithoutChannelMessageInput = {
  where: ChannelMessageAutoResponseWhereUniqueInput;
  create: ChannelMessageAutoResponseUncheckedCreateWithoutChannelMessageInput;
};

export type ChannelMessageAutoResponseCreateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage: Scalars['String'];
  suggestionStatus: AutoResponseSuggestionStatus;
};

export type ChannelMessageAutoResponseListRelationFilter = {
  every?: Maybe<ChannelMessageAutoResponseWhereInput>;
  some?: Maybe<ChannelMessageAutoResponseWhereInput>;
  none?: Maybe<ChannelMessageAutoResponseWhereInput>;
};

export type ChannelMessageAutoResponseMaxAggregateOutputType = {
  __typename?: 'ChannelMessageAutoResponseMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  channelMessageId?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
};

export type ChannelMessageAutoResponseMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  aiSuggestionMessage?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  suggestionStatus?: Maybe<SortOrder>;
};

export type ChannelMessageAutoResponseMinAggregateOutputType = {
  __typename?: 'ChannelMessageAutoResponseMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  channelMessageId?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
};

export type ChannelMessageAutoResponseMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  aiSuggestionMessage?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  suggestionStatus?: Maybe<SortOrder>;
};

export type ChannelMessageAutoResponseOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelMessageAutoResponseOrderByRelevanceFieldEnum {
  Id = 'id',
  AiSuggestionMessage = 'aiSuggestionMessage',
  ChannelMessageId = 'channelMessageId'
}

export type ChannelMessageAutoResponseOrderByRelevanceInput = {
  fields: Array<ChannelMessageAutoResponseOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelMessageAutoResponseOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  aiSuggestionMessage?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  suggestionStatus?: Maybe<SortOrder>;
  _count?: Maybe<ChannelMessageAutoResponseCountOrderByAggregateInput>;
  _max?: Maybe<ChannelMessageAutoResponseMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMessageAutoResponseMinOrderByAggregateInput>;
};

export type ChannelMessageAutoResponseOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  aiSuggestionMessage?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  suggestionStatus?: Maybe<SortOrder>;
  channelMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ChannelMessageAutoResponseOrderByRelevanceInput>;
};

export enum ChannelMessageAutoResponseScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  AiSuggestionMessage = 'aiSuggestionMessage',
  ChannelMessageId = 'channelMessageId',
  SuggestionStatus = 'suggestionStatus'
}

export type ChannelMessageAutoResponseScalarWhereInput = {
  AND?: Maybe<Array<ChannelMessageAutoResponseScalarWhereInput>>;
  OR?: Maybe<Array<ChannelMessageAutoResponseScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageAutoResponseScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  aiSuggestionMessage?: Maybe<StringFilter>;
  channelMessageId?: Maybe<StringNullableFilter>;
  suggestionStatus?: Maybe<EnumAutoResponseSuggestionStatusFilter>;
};

export type ChannelMessageAutoResponseScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelMessageAutoResponseScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelMessageAutoResponseScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelMessageAutoResponseScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  aiSuggestionMessage?: Maybe<StringWithAggregatesFilter>;
  channelMessageId?: Maybe<StringNullableWithAggregatesFilter>;
  suggestionStatus?: Maybe<EnumAutoResponseSuggestionStatusWithAggregatesFilter>;
};

export type ChannelMessageAutoResponseUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage: Scalars['String'];
  channelMessageId?: Maybe<Scalars['String']>;
  suggestionStatus: AutoResponseSuggestionStatus;
};

export type ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput = {
  create?: Maybe<Array<ChannelMessageAutoResponseCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAutoResponseCreateOrConnectWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageAutoResponseCreateManyChannelMessageInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
};

export type ChannelMessageAutoResponseUncheckedCreateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage: Scalars['String'];
  suggestionStatus: AutoResponseSuggestionStatus;
};

export type ChannelMessageAutoResponseUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  channelMessageId?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
};

export type ChannelMessageAutoResponseUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  channelMessageId?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
};

export type ChannelMessageAutoResponseUncheckedUpdateManyWithoutAutoResponsesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
};

export type ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput = {
  create?: Maybe<Array<ChannelMessageAutoResponseCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAutoResponseCreateOrConnectWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageAutoResponseUpsertWithWhereUniqueWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageAutoResponseCreateManyChannelMessageInputEnvelope>;
  set?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageAutoResponseUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageAutoResponseUpdateManyWithWhereWithoutChannelMessageInput>>;
  deleteMany?: Maybe<Array<ChannelMessageAutoResponseScalarWhereInput>>;
};

export type ChannelMessageAutoResponseUncheckedUpdateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
};

export type ChannelMessageAutoResponseUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
  channelMessage?: Maybe<ChannelMessageUpdateOneWithoutAutoResponsesNestedInput>;
};

export type ChannelMessageAutoResponseUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
};

export type ChannelMessageAutoResponseUpdateManyWithWhereWithoutChannelMessageInput = {
  where: ChannelMessageAutoResponseScalarWhereInput;
  data: ChannelMessageAutoResponseUncheckedUpdateManyWithoutAutoResponsesInput;
};

export type ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput = {
  create?: Maybe<Array<ChannelMessageAutoResponseCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAutoResponseCreateOrConnectWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageAutoResponseUpsertWithWhereUniqueWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageAutoResponseCreateManyChannelMessageInputEnvelope>;
  set?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageAutoResponseUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageAutoResponseUpdateManyWithWhereWithoutChannelMessageInput>>;
  deleteMany?: Maybe<Array<ChannelMessageAutoResponseScalarWhereInput>>;
};

export type ChannelMessageAutoResponseUpdateWithWhereUniqueWithoutChannelMessageInput = {
  where: ChannelMessageAutoResponseWhereUniqueInput;
  data: ChannelMessageAutoResponseUncheckedUpdateWithoutChannelMessageInput;
};

export type ChannelMessageAutoResponseUpdateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
};

export type ChannelMessageAutoResponseUpsertWithWhereUniqueWithoutChannelMessageInput = {
  where: ChannelMessageAutoResponseWhereUniqueInput;
  update: ChannelMessageAutoResponseUncheckedUpdateWithoutChannelMessageInput;
  create: ChannelMessageAutoResponseUncheckedCreateWithoutChannelMessageInput;
};

export type ChannelMessageAutoResponseWhereInput = {
  AND?: Maybe<Array<ChannelMessageAutoResponseWhereInput>>;
  OR?: Maybe<Array<ChannelMessageAutoResponseWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageAutoResponseWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  aiSuggestionMessage?: Maybe<StringFilter>;
  channelMessageId?: Maybe<StringNullableFilter>;
  suggestionStatus?: Maybe<EnumAutoResponseSuggestionStatusFilter>;
  channelMessage?: Maybe<ChannelMessageWhereInput>;
};

export type ChannelMessageAutoResponseWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelMessageAvgAggregateOutputType = {
  __typename?: 'ChannelMessageAvgAggregateOutputType';
  index?: Maybe<Scalars['Float']>;
};

export type ChannelMessageAvgOrderByAggregateInput = {
  index?: Maybe<SortOrder>;
};

export type ChannelMessageCountAggregateOutputType = {
  __typename?: 'ChannelMessageCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  body: Scalars['Int'];
  attributes: Scalars['Int'];
  twilioMessageSid: Scalars['Int'];
  index: Scalars['Int'];
  lastUpdatedBy: Scalars['Int'];
  media: Scalars['Int'];
  wasEdited: Scalars['Int'];
  authorId: Scalars['Int'];
  channelId: Scalars['Int'];
  messageType: Scalars['Int'];
  source: Scalars['Int'];
  sourcePhoneNumber: Scalars['Int'];
  forceReadReceipt: Scalars['Int'];
  isUnreadEligible: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelMessageCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  lastUpdatedBy?: Maybe<SortOrder>;
  media?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  messageType?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  forceReadReceipt?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
};

export type ChannelMessageCountOutputType = {
  __typename?: 'ChannelMessageCountOutputType';
  lastMessageChannel: Scalars['Int'];
  attachments: Scalars['Int'];
  autoResponses: Scalars['Int'];
  mentionedUsers: Scalars['Int'];
  smsNotifificationStatuses: Scalars['Int'];
  reactions: Scalars['Int'];
  pushNotificationLogs: Scalars['Int'];
};

export type ChannelMessageCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageCreateManyAuthorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateManyAuthorInputEnvelope = {
  data: Array<ChannelMessageCreateManyAuthorInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateManyChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateManyChannelInputEnvelope = {
  data: Array<ChannelMessageCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateNestedManyWithoutAttachmentsInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAttachmentsInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAttachmentsInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageCreateNestedManyWithoutAuthorInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAuthorInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAuthorInput>>;
  createMany?: Maybe<ChannelMessageCreateManyAuthorInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<ChannelMessageCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageCreateNestedManyWithoutMentionedUsersInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutMentionedUsersInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutMentionedUsersInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageCreateNestedOneWithoutAutoResponsesInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutAutoResponsesInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutAutoResponsesInput>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
};

export type ChannelMessageCreateNestedOneWithoutAutomationRunInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutAutomationRunInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutAutomationRunInput>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
};

export type ChannelMessageCreateNestedOneWithoutLastMessageChannelInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutLastMessageChannelInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutLastMessageChannelInput>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
};

export type ChannelMessageCreateNestedOneWithoutPushNotificationLogsInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutPushNotificationLogsInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutPushNotificationLogsInput>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
};

export type ChannelMessageCreateNestedOneWithoutReactionsInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutReactionsInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutReactionsInput>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
};

export type ChannelMessageCreateNestedOneWithoutSmsNotifificationStatusesInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutSmsNotifificationStatusesInput>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
};

export type ChannelMessageCreateOrConnectWithoutAttachmentsInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutAttachmentsInput;
};

export type ChannelMessageCreateOrConnectWithoutAuthorInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutAuthorInput;
};

export type ChannelMessageCreateOrConnectWithoutAutoResponsesInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutAutoResponsesInput;
};

export type ChannelMessageCreateOrConnectWithoutAutomationRunInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutAutomationRunInput;
};

export type ChannelMessageCreateOrConnectWithoutChannelInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutChannelInput;
};

export type ChannelMessageCreateOrConnectWithoutLastMessageChannelInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutLastMessageChannelInput;
};

export type ChannelMessageCreateOrConnectWithoutMentionedUsersInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutMentionedUsersInput;
};

export type ChannelMessageCreateOrConnectWithoutPushNotificationLogsInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutPushNotificationLogsInput;
};

export type ChannelMessageCreateOrConnectWithoutReactionsInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutReactionsInput;
};

export type ChannelMessageCreateOrConnectWithoutSmsNotifificationStatusesInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput;
};

export type ChannelMessageCreateWithoutAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageCreateWithoutAuthorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageCreateWithoutAutoResponsesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageCreateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageCreateWithoutLastMessageChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageCreateWithoutMentionedUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageCreateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageCreateWithoutReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageCreateWithoutSmsNotifificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageListRelationFilter = {
  every?: Maybe<ChannelMessageWhereInput>;
  some?: Maybe<ChannelMessageWhereInput>;
  none?: Maybe<ChannelMessageWhereInput>;
};

export type ChannelMessageMaxAggregateOutputType = {
  __typename?: 'ChannelMessageMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  lastUpdatedBy?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  messageType?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  forceReadReceipt?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
};

export type ChannelMessageMinAggregateOutputType = {
  __typename?: 'ChannelMessageMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  lastUpdatedBy?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  messageType?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  forceReadReceipt?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
};

export type ChannelMessageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelMessageOrderByRelevanceFieldEnum {
  Id = 'id',
  Body = 'body',
  TwilioMessageSid = 'twilioMessageSid',
  LastUpdatedBy = 'lastUpdatedBy',
  AuthorId = 'authorId',
  ChannelId = 'channelId',
  SourcePhoneNumber = 'sourcePhoneNumber'
}

export type ChannelMessageOrderByRelevanceInput = {
  fields: Array<ChannelMessageOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelMessageOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  lastUpdatedBy?: Maybe<SortOrder>;
  media?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  messageType?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  forceReadReceipt?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
  _count?: Maybe<ChannelMessageCountOrderByAggregateInput>;
  _avg?: Maybe<ChannelMessageAvgOrderByAggregateInput>;
  _max?: Maybe<ChannelMessageMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMessageMinOrderByAggregateInput>;
  _sum?: Maybe<ChannelMessageSumOrderByAggregateInput>;
};

export type ChannelMessageOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  lastUpdatedBy?: Maybe<SortOrder>;
  media?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  messageType?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  forceReadReceipt?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  author?: Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>;
  lastMessageChannel?: Maybe<ChannelOrderByRelationAggregateInput>;
  attachments?: Maybe<ChannelMessageAttachmentOrderByRelationAggregateInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseOrderByRelationAggregateInput>;
  mentionedUsers?: Maybe<UserOrderByRelationAggregateInput>;
  automationRun?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusOrderByRelationAggregateInput>;
  reactions?: Maybe<ChannelMessageReactionOrderByRelationAggregateInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogOrderByRelationAggregateInput>;
  _relevance?: Maybe<ChannelMessageOrderByRelevanceInput>;
};

export type ChannelMessageReaction = {
  __typename?: 'ChannelMessageReaction';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  userId: Scalars['String'];
  reaction: Scalars['String'];
  channelMessage: ChannelMessage;
  user: User;
};

export type ChannelMessageReactionCountAggregateOutputType = {
  __typename?: 'ChannelMessageReactionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  channelMessageId: Scalars['Int'];
  userId: Scalars['Int'];
  reaction: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelMessageReactionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
};

export type ChannelMessageReactionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reaction: Scalars['String'];
  channelMessage: ChannelMessageCreateNestedOneWithoutReactionsInput;
  user: UserCreateNestedOneWithoutChannelMessageReactionsInput;
};

export type ChannelMessageReactionCreateManyChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  reaction: Scalars['String'];
};

export type ChannelMessageReactionCreateManyChannelMessageInputEnvelope = {
  data: Array<ChannelMessageReactionCreateManyChannelMessageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageReactionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  userId: Scalars['String'];
  reaction: Scalars['String'];
};

export type ChannelMessageReactionCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  reaction: Scalars['String'];
};

export type ChannelMessageReactionCreateManyUserInputEnvelope = {
  data: Array<ChannelMessageReactionCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyChannelMessageInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
};

export type ChannelMessageReactionCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
};

export type ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput = {
  where: ChannelMessageReactionWhereUniqueInput;
  create: ChannelMessageReactionUncheckedCreateWithoutChannelMessageInput;
};

export type ChannelMessageReactionCreateOrConnectWithoutUserInput = {
  where: ChannelMessageReactionWhereUniqueInput;
  create: ChannelMessageReactionUncheckedCreateWithoutUserInput;
};

export type ChannelMessageReactionCreateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reaction: Scalars['String'];
  user: UserCreateNestedOneWithoutChannelMessageReactionsInput;
};

export type ChannelMessageReactionCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reaction: Scalars['String'];
  channelMessage: ChannelMessageCreateNestedOneWithoutReactionsInput;
};

export type ChannelMessageReactionListRelationFilter = {
  every?: Maybe<ChannelMessageReactionWhereInput>;
  some?: Maybe<ChannelMessageReactionWhereInput>;
  none?: Maybe<ChannelMessageReactionWhereInput>;
};

export type ChannelMessageReactionMaxAggregateOutputType = {
  __typename?: 'ChannelMessageReactionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
};

export type ChannelMessageReactionMinAggregateOutputType = {
  __typename?: 'ChannelMessageReactionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
};

export type ChannelMessageReactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelMessageReactionOrderByRelevanceFieldEnum {
  Id = 'id',
  ChannelMessageId = 'channelMessageId',
  UserId = 'userId',
  Reaction = 'reaction'
}

export type ChannelMessageReactionOrderByRelevanceInput = {
  fields: Array<ChannelMessageReactionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelMessageReactionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
  _count?: Maybe<ChannelMessageReactionCountOrderByAggregateInput>;
  _max?: Maybe<ChannelMessageReactionMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMessageReactionMinOrderByAggregateInput>;
};

export type ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
  channelMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ChannelMessageReactionOrderByRelevanceInput>;
};

export enum ChannelMessageReactionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  ChannelMessageId = 'channelMessageId',
  UserId = 'userId',
  Reaction = 'reaction'
}

export type ChannelMessageReactionScalarWhereInput = {
  AND?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
  OR?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  channelMessageId?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  reaction?: Maybe<StringFilter>;
};

export type ChannelMessageReactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelMessageReactionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelMessageReactionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelMessageReactionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  channelMessageId?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  reaction?: Maybe<StringWithAggregatesFilter>;
};

export type ChannelMessageReactionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  userId: Scalars['String'];
  reaction: Scalars['String'];
};

export type ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyChannelMessageInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
};

export type ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
};

export type ChannelMessageReactionUncheckedCreateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  reaction: Scalars['String'];
};

export type ChannelMessageReactionUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  reaction: Scalars['String'];
};

export type ChannelMessageReactionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageReactionUpsertWithWhereUniqueWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyChannelMessageInputEnvelope>;
  set?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageReactionUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageReactionUpdateManyWithWhereWithoutChannelMessageInput>>;
  deleteMany?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
};

export type ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUncheckedUpdateManyWithoutReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelMessageReactionUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageReactionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelMessageReactionUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
};

export type ChannelMessageReactionUncheckedUpdateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reaction?: Maybe<Scalars['String']>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutReactionsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelMessageReactionsNestedInput>;
};

export type ChannelMessageReactionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUpdateManyWithWhereWithoutChannelMessageInput = {
  where: ChannelMessageReactionScalarWhereInput;
  data: ChannelMessageReactionUncheckedUpdateManyWithoutReactionsInput;
};

export type ChannelMessageReactionUpdateManyWithWhereWithoutUserInput = {
  where: ChannelMessageReactionScalarWhereInput;
  data: ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageReactionsInput;
};

export type ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageReactionUpsertWithWhereUniqueWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyChannelMessageInputEnvelope>;
  set?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageReactionUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageReactionUpdateManyWithWhereWithoutChannelMessageInput>>;
  deleteMany?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
};

export type ChannelMessageReactionUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelMessageReactionUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageReactionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelMessageReactionUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
};

export type ChannelMessageReactionUpdateWithWhereUniqueWithoutChannelMessageInput = {
  where: ChannelMessageReactionWhereUniqueInput;
  data: ChannelMessageReactionUncheckedUpdateWithoutChannelMessageInput;
};

export type ChannelMessageReactionUpdateWithWhereUniqueWithoutUserInput = {
  where: ChannelMessageReactionWhereUniqueInput;
  data: ChannelMessageReactionUncheckedUpdateWithoutUserInput;
};

export type ChannelMessageReactionUpdateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reaction?: Maybe<Scalars['String']>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelMessageReactionsNestedInput>;
};

export type ChannelMessageReactionUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reaction?: Maybe<Scalars['String']>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutReactionsNestedInput>;
};

export type ChannelMessageReactionUpsertWithWhereUniqueWithoutChannelMessageInput = {
  where: ChannelMessageReactionWhereUniqueInput;
  update: ChannelMessageReactionUncheckedUpdateWithoutChannelMessageInput;
  create: ChannelMessageReactionUncheckedCreateWithoutChannelMessageInput;
};

export type ChannelMessageReactionUpsertWithWhereUniqueWithoutUserInput = {
  where: ChannelMessageReactionWhereUniqueInput;
  update: ChannelMessageReactionUncheckedUpdateWithoutUserInput;
  create: ChannelMessageReactionUncheckedCreateWithoutUserInput;
};

export type ChannelMessageReactionWhereInput = {
  AND?: Maybe<Array<ChannelMessageReactionWhereInput>>;
  OR?: Maybe<Array<ChannelMessageReactionWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageReactionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  channelMessageId?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  reaction?: Maybe<StringFilter>;
  channelMessage?: Maybe<ChannelMessageWhereInput>;
  user?: Maybe<UserWhereInput>;
};

export type ChannelMessageReactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelMessageRelationFilter = {
  is?: Maybe<ChannelMessageWhereInput>;
  isNot?: Maybe<ChannelMessageWhereInput>;
};

export enum ChannelMessageScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Body = 'body',
  Attributes = 'attributes',
  TwilioMessageSid = 'twilioMessageSid',
  Index = 'index',
  LastUpdatedBy = 'lastUpdatedBy',
  Media = 'media',
  WasEdited = 'wasEdited',
  AuthorId = 'authorId',
  ChannelId = 'channelId',
  MessageType = 'messageType',
  Source = 'source',
  SourcePhoneNumber = 'sourcePhoneNumber',
  ForceReadReceipt = 'forceReadReceipt',
  IsUnreadEligible = 'isUnreadEligible'
}

export type ChannelMessageScalarWhereInput = {
  AND?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  OR?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  body?: Maybe<StringNullableFilter>;
  attributes?: Maybe<JsonNullableFilter>;
  twilioMessageSid?: Maybe<StringNullableFilter>;
  index?: Maybe<IntFilter>;
  lastUpdatedBy?: Maybe<StringNullableFilter>;
  media?: Maybe<JsonNullableFilter>;
  wasEdited?: Maybe<BoolFilter>;
  authorId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  messageType?: Maybe<EnumMessageTypeFilter>;
  source?: Maybe<EnumMessageSourceFilter>;
  sourcePhoneNumber?: Maybe<StringNullableFilter>;
  forceReadReceipt?: Maybe<BoolFilter>;
  isUnreadEligible?: Maybe<BoolFilter>;
};

export type ChannelMessageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelMessageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelMessageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelMessageScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  body?: Maybe<StringNullableWithAggregatesFilter>;
  attributes?: Maybe<JsonNullableWithAggregatesFilter>;
  twilioMessageSid?: Maybe<StringNullableWithAggregatesFilter>;
  index?: Maybe<IntWithAggregatesFilter>;
  lastUpdatedBy?: Maybe<StringNullableWithAggregatesFilter>;
  media?: Maybe<JsonNullableWithAggregatesFilter>;
  wasEdited?: Maybe<BoolWithAggregatesFilter>;
  authorId?: Maybe<StringNullableWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
  messageType?: Maybe<EnumMessageTypeWithAggregatesFilter>;
  source?: Maybe<EnumMessageSourceWithAggregatesFilter>;
  sourcePhoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  forceReadReceipt?: Maybe<BoolWithAggregatesFilter>;
  isUnreadEligible?: Maybe<BoolWithAggregatesFilter>;
};

export type ChannelMessageSubscription = {
  __typename?: 'ChannelMessageSubscription';
  node: ChannelMessage;
  mutation: MutationType;
};

export type ChannelMessageSumAggregateOutputType = {
  __typename?: 'ChannelMessageSumAggregateOutputType';
  index?: Maybe<Scalars['Int']>;
};

export type ChannelMessageSumOrderByAggregateInput = {
  index?: Maybe<SortOrder>;
};

export type ChannelMessageUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateNestedManyWithoutAttachmentsInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAttachmentsInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAttachmentsInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAuthorInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAuthorInput>>;
  createMany?: Maybe<ChannelMessageCreateManyAuthorInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageUncheckedCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<ChannelMessageCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutMentionedUsersInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutMentionedUsersInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageUncheckedCreateWithoutAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutAuthorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutAutoResponsesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutLastMessageChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutMentionedUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateManyWithoutAttachmentsNestedInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAttachmentsInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAttachmentsInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutAttachmentsInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutAttachmentsInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutAttachmentsInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUncheckedUpdateManyWithoutAuthorNestedInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAuthorInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAuthorInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutAuthorInput>>;
  createMany?: Maybe<ChannelMessageCreateManyAuthorInputEnvelope>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutAuthorInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUncheckedUpdateManyWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateManyWithoutChannelMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<ChannelMessageCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutMentionedUsersInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutMentionedUsersInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutMentionedUsersInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutMentionedUsersInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutMentionedUsersInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUncheckedUpdateManyWithoutMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateWithoutAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUncheckedUpdateWithoutAuthorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUncheckedUpdateWithoutAutoResponsesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUncheckedUpdateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUncheckedUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUncheckedUpdateWithoutLastMessageChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUncheckedUpdateWithoutMentionedUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUncheckedUpdateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
};

export type ChannelMessageUncheckedUpdateWithoutReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUncheckedUpdateWithoutSmsNotifificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUnreadCountResult = {
  __typename?: 'ChannelMessageUnreadCountResult';
  total: Scalars['Int'];
  unreadChannels: Array<UnreadMessageChannel>;
};

export type ChannelMessageUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUpdateManyWithWhereWithoutAttachmentsInput = {
  where: ChannelMessageScalarWhereInput;
  data: ChannelMessageUncheckedUpdateManyWithoutChannelMessageInput;
};

export type ChannelMessageUpdateManyWithWhereWithoutAuthorInput = {
  where: ChannelMessageScalarWhereInput;
  data: ChannelMessageUncheckedUpdateManyWithoutChannelMessagesInput;
};

export type ChannelMessageUpdateManyWithWhereWithoutChannelInput = {
  where: ChannelMessageScalarWhereInput;
  data: ChannelMessageUncheckedUpdateManyWithoutMessagesInput;
};

export type ChannelMessageUpdateManyWithWhereWithoutMentionedUsersInput = {
  where: ChannelMessageScalarWhereInput;
  data: ChannelMessageUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput;
};

export type ChannelMessageUpdateManyWithoutAttachmentsNestedInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAttachmentsInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAttachmentsInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutAttachmentsInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutAttachmentsInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutAttachmentsInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUpdateManyWithoutAuthorNestedInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAuthorInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAuthorInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutAuthorInput>>;
  createMany?: Maybe<ChannelMessageCreateManyAuthorInputEnvelope>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutAuthorInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUpdateManyWithoutChannelNestedInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<ChannelMessageCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUpdateManyWithoutMentionedUsersNestedInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutMentionedUsersInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutMentionedUsersInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutMentionedUsersInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutMentionedUsersInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutMentionedUsersInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutAutomationRunInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutAutomationRunInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutAutomationRunInput>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutAutomationRunInput>;
};

export type ChannelMessageUpdateOneRequiredWithoutReactionsNestedInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutReactionsInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutReactionsInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutReactionsInput>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutReactionsInput>;
};

export type ChannelMessageUpdateOneWithoutAutoResponsesNestedInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutAutoResponsesInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutAutoResponsesInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutAutoResponsesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutAutoResponsesInput>;
};

export type ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutLastMessageChannelInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutLastMessageChannelInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutLastMessageChannelInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutLastMessageChannelInput>;
};

export type ChannelMessageUpdateOneWithoutPushNotificationLogsNestedInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutPushNotificationLogsInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutPushNotificationLogsInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutPushNotificationLogsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutPushNotificationLogsInput>;
};

export type ChannelMessageUpdateOneWithoutSmsNotifificationStatusesNestedInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutSmsNotifificationStatusesInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutSmsNotifificationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutSmsNotifificationStatusesInput>;
};

export type ChannelMessageUpdateWithWhereUniqueWithoutAttachmentsInput = {
  where: ChannelMessageWhereUniqueInput;
  data: ChannelMessageUncheckedUpdateWithoutAttachmentsInput;
};

export type ChannelMessageUpdateWithWhereUniqueWithoutAuthorInput = {
  where: ChannelMessageWhereUniqueInput;
  data: ChannelMessageUncheckedUpdateWithoutAuthorInput;
};

export type ChannelMessageUpdateWithWhereUniqueWithoutChannelInput = {
  where: ChannelMessageWhereUniqueInput;
  data: ChannelMessageUncheckedUpdateWithoutChannelInput;
};

export type ChannelMessageUpdateWithWhereUniqueWithoutMentionedUsersInput = {
  where: ChannelMessageWhereUniqueInput;
  data: ChannelMessageUncheckedUpdateWithoutMentionedUsersInput;
};

export type ChannelMessageUpdateWithoutAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUpdateWithoutAuthorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUpdateWithoutAutoResponsesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUpdateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUpdateWithoutLastMessageChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUpdateWithoutMentionedUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUpdateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
};

export type ChannelMessageUpdateWithoutReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUpdateWithoutSmsNotifificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
};

export type ChannelMessageUpsertWithWhereUniqueWithoutAttachmentsInput = {
  where: ChannelMessageWhereUniqueInput;
  update: ChannelMessageUncheckedUpdateWithoutAttachmentsInput;
  create: ChannelMessageUncheckedCreateWithoutAttachmentsInput;
};

export type ChannelMessageUpsertWithWhereUniqueWithoutAuthorInput = {
  where: ChannelMessageWhereUniqueInput;
  update: ChannelMessageUncheckedUpdateWithoutAuthorInput;
  create: ChannelMessageUncheckedCreateWithoutAuthorInput;
};

export type ChannelMessageUpsertWithWhereUniqueWithoutChannelInput = {
  where: ChannelMessageWhereUniqueInput;
  update: ChannelMessageUncheckedUpdateWithoutChannelInput;
  create: ChannelMessageUncheckedCreateWithoutChannelInput;
};

export type ChannelMessageUpsertWithWhereUniqueWithoutMentionedUsersInput = {
  where: ChannelMessageWhereUniqueInput;
  update: ChannelMessageUncheckedUpdateWithoutMentionedUsersInput;
  create: ChannelMessageUncheckedCreateWithoutMentionedUsersInput;
};

export type ChannelMessageUpsertWithoutAutoResponsesInput = {
  update: ChannelMessageUncheckedUpdateWithoutAutoResponsesInput;
  create: ChannelMessageUncheckedCreateWithoutAutoResponsesInput;
};

export type ChannelMessageUpsertWithoutAutomationRunInput = {
  update: ChannelMessageUncheckedUpdateWithoutAutomationRunInput;
  create: ChannelMessageUncheckedCreateWithoutAutomationRunInput;
};

export type ChannelMessageUpsertWithoutLastMessageChannelInput = {
  update: ChannelMessageUncheckedUpdateWithoutLastMessageChannelInput;
  create: ChannelMessageUncheckedCreateWithoutLastMessageChannelInput;
};

export type ChannelMessageUpsertWithoutPushNotificationLogsInput = {
  update: ChannelMessageUncheckedUpdateWithoutPushNotificationLogsInput;
  create: ChannelMessageUncheckedCreateWithoutPushNotificationLogsInput;
};

export type ChannelMessageUpsertWithoutReactionsInput = {
  update: ChannelMessageUncheckedUpdateWithoutReactionsInput;
  create: ChannelMessageUncheckedCreateWithoutReactionsInput;
};

export type ChannelMessageUpsertWithoutSmsNotifificationStatusesInput = {
  update: ChannelMessageUncheckedUpdateWithoutSmsNotifificationStatusesInput;
  create: ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput;
};

export type ChannelMessageWhereInput = {
  AND?: Maybe<Array<ChannelMessageWhereInput>>;
  OR?: Maybe<Array<ChannelMessageWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  body?: Maybe<StringNullableFilter>;
  attributes?: Maybe<JsonNullableFilter>;
  twilioMessageSid?: Maybe<StringNullableFilter>;
  index?: Maybe<IntFilter>;
  lastUpdatedBy?: Maybe<StringNullableFilter>;
  media?: Maybe<JsonNullableFilter>;
  wasEdited?: Maybe<BoolFilter>;
  authorId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  messageType?: Maybe<EnumMessageTypeFilter>;
  source?: Maybe<EnumMessageSourceFilter>;
  sourcePhoneNumber?: Maybe<StringNullableFilter>;
  forceReadReceipt?: Maybe<BoolFilter>;
  isUnreadEligible?: Maybe<BoolFilter>;
  channel?: Maybe<ChannelWhereInput>;
  author?: Maybe<ChannelMemberWhereInput>;
  lastMessageChannel?: Maybe<ChannelListRelationFilter>;
  attachments?: Maybe<ChannelMessageAttachmentListRelationFilter>;
  autoResponses?: Maybe<ChannelMessageAutoResponseListRelationFilter>;
  mentionedUsers?: Maybe<UserListRelationFilter>;
  automationRun?: Maybe<AutomationRunWhereInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusListRelationFilter>;
  reactions?: Maybe<ChannelMessageReactionListRelationFilter>;
  pushNotificationLogs?: Maybe<PushNotificationLogListRelationFilter>;
};

export type ChannelMessageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelMinAggregateOutputType = {
  __typename?: 'ChannelMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  friendlyName?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  lastMessageId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
  lastExportAt?: Maybe<SortOrder>;
};

export type ChannelOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelOrderByRelevanceFieldEnum {
  Id = 'id',
  TwilioChannelSid = 'twilioChannelSid',
  UniqueName = 'uniqueName',
  FriendlyName = 'friendlyName',
  ClinicId = 'clinicId',
  LastMessageId = 'lastMessageId',
  ChannelStatusId = 'channelStatusId',
  CurrentWorkflowActionId = 'currentWorkflowActionId'
}

export type ChannelOrderByRelevanceInput = {
  fields: Array<ChannelOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  friendlyName?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  lastMessageId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  lastExportAt?: Maybe<SortOrder>;
  _count?: Maybe<ChannelCountOrderByAggregateInput>;
  _max?: Maybe<ChannelMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMinOrderByAggregateInput>;
};

export type ChannelOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  friendlyName?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  lastMessageId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  lastExportAt?: Maybe<SortOrder>;
  currentWorkflowAction?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  lastMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  channelStatus?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  channelMembers?: Maybe<ChannelMemberOrderByRelationAggregateInput>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  messages?: Maybe<ChannelMessageOrderByRelationAggregateInput>;
  assignees?: Maybe<UserOrderByRelationAggregateInput>;
  financialTransactions?: Maybe<FinancialTransactionOrderByRelationAggregateInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  creationSource?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  channelPins?: Maybe<ChannelPinOrderByRelationAggregateInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogOrderByRelationAggregateInput>;
  _relevance?: Maybe<ChannelOrderByRelevanceInput>;
};

export type ChannelPin = {
  __typename?: 'ChannelPin';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  userId: Scalars['String'];
  channelId?: Maybe<Scalars['String']>;
  user: User;
  channel?: Maybe<Channel>;
};

export type ChannelPinCountAggregateOutputType = {
  __typename?: 'ChannelPinCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  userId: Scalars['Int'];
  channelId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelPinCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
};

export type ChannelPinCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutPinnedChannelsInput;
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelPinsInput>;
};

export type ChannelPinCreateManyChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ChannelPinCreateManyChannelInputEnvelope = {
  data: Array<ChannelPinCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelPinCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  channelId?: Maybe<Scalars['String']>;
};

export type ChannelPinCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
};

export type ChannelPinCreateManyUserInputEnvelope = {
  data: Array<ChannelPinCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelPinCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelPinCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<ChannelPinCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
};

export type ChannelPinCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelPinCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelPinCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
};

export type ChannelPinCreateOrConnectWithoutChannelInput = {
  where: ChannelPinWhereUniqueInput;
  create: ChannelPinUncheckedCreateWithoutChannelInput;
};

export type ChannelPinCreateOrConnectWithoutUserInput = {
  where: ChannelPinWhereUniqueInput;
  create: ChannelPinUncheckedCreateWithoutUserInput;
};

export type ChannelPinCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutPinnedChannelsInput;
};

export type ChannelPinCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelPinsInput>;
};

export type ChannelPinListRelationFilter = {
  every?: Maybe<ChannelPinWhereInput>;
  some?: Maybe<ChannelPinWhereInput>;
  none?: Maybe<ChannelPinWhereInput>;
};

export type ChannelPinMaxAggregateOutputType = {
  __typename?: 'ChannelPinMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type ChannelPinMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
};

export type ChannelPinMinAggregateOutputType = {
  __typename?: 'ChannelPinMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type ChannelPinMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
};

export type ChannelPinOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelPinOrderByRelevanceFieldEnum {
  Id = 'id',
  UserId = 'userId',
  ChannelId = 'channelId'
}

export type ChannelPinOrderByRelevanceInput = {
  fields: Array<ChannelPinOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelPinOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  _count?: Maybe<ChannelPinCountOrderByAggregateInput>;
  _max?: Maybe<ChannelPinMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelPinMinOrderByAggregateInput>;
};

export type ChannelPinOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ChannelPinOrderByRelevanceInput>;
};

export enum ChannelPinScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UserId = 'userId',
  ChannelId = 'channelId'
}

export type ChannelPinScalarWhereInput = {
  AND?: Maybe<Array<ChannelPinScalarWhereInput>>;
  OR?: Maybe<Array<ChannelPinScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelPinScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
  channelId?: Maybe<StringNullableFilter>;
};

export type ChannelPinScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelPinScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelPinScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelPinScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ChannelPinUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  channelId?: Maybe<Scalars['String']>;
};

export type ChannelPinUncheckedCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelPinCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<ChannelPinCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
};

export type ChannelPinUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelPinCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelPinCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
};

export type ChannelPinUncheckedCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ChannelPinUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
};

export type ChannelPinUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type ChannelPinUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type ChannelPinUncheckedUpdateManyWithoutChannelNestedInput = {
  create?: Maybe<Array<ChannelPinCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelPinUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<ChannelPinCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  update?: Maybe<Array<ChannelPinUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelPinUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<ChannelPinScalarWhereInput>>;
};

export type ChannelPinUncheckedUpdateManyWithoutChannelPinsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelPinUncheckedUpdateManyWithoutPinnedChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
};

export type ChannelPinUncheckedUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ChannelPinCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelPinUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelPinCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  update?: Maybe<Array<ChannelPinUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelPinUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelPinScalarWhereInput>>;
};

export type ChannelPinUncheckedUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelPinUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
};

export type ChannelPinUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneRequiredWithoutPinnedChannelsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutChannelPinsNestedInput>;
};

export type ChannelPinUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelPinUpdateManyWithWhereWithoutChannelInput = {
  where: ChannelPinScalarWhereInput;
  data: ChannelPinUncheckedUpdateManyWithoutChannelPinsInput;
};

export type ChannelPinUpdateManyWithWhereWithoutUserInput = {
  where: ChannelPinScalarWhereInput;
  data: ChannelPinUncheckedUpdateManyWithoutPinnedChannelsInput;
};

export type ChannelPinUpdateManyWithoutChannelNestedInput = {
  create?: Maybe<Array<ChannelPinCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelPinUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<ChannelPinCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  update?: Maybe<Array<ChannelPinUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelPinUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<ChannelPinScalarWhereInput>>;
};

export type ChannelPinUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ChannelPinCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelPinUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelPinCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  update?: Maybe<Array<ChannelPinUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelPinUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelPinScalarWhereInput>>;
};

export type ChannelPinUpdateWithWhereUniqueWithoutChannelInput = {
  where: ChannelPinWhereUniqueInput;
  data: ChannelPinUncheckedUpdateWithoutChannelInput;
};

export type ChannelPinUpdateWithWhereUniqueWithoutUserInput = {
  where: ChannelPinWhereUniqueInput;
  data: ChannelPinUncheckedUpdateWithoutUserInput;
};

export type ChannelPinUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneRequiredWithoutPinnedChannelsNestedInput>;
};

export type ChannelPinUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  channel?: Maybe<ChannelUpdateOneWithoutChannelPinsNestedInput>;
};

export type ChannelPinUpsertWithWhereUniqueWithoutChannelInput = {
  where: ChannelPinWhereUniqueInput;
  update: ChannelPinUncheckedUpdateWithoutChannelInput;
  create: ChannelPinUncheckedCreateWithoutChannelInput;
};

export type ChannelPinUpsertWithWhereUniqueWithoutUserInput = {
  where: ChannelPinWhereUniqueInput;
  update: ChannelPinUncheckedUpdateWithoutUserInput;
  create: ChannelPinUncheckedCreateWithoutUserInput;
};

export type ChannelPinWhereInput = {
  AND?: Maybe<Array<ChannelPinWhereInput>>;
  OR?: Maybe<Array<ChannelPinWhereInput>>;
  NOT?: Maybe<Array<ChannelPinWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
  channelId?: Maybe<StringNullableFilter>;
  user?: Maybe<UserWhereInput>;
  channel?: Maybe<ChannelWhereInput>;
};

export type ChannelPinWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelRelationFilter = {
  is?: Maybe<ChannelWhereInput>;
  isNot?: Maybe<ChannelWhereInput>;
};

export enum ChannelScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  TwilioChannelSid = 'twilioChannelSid',
  UniqueName = 'uniqueName',
  FriendlyName = 'friendlyName',
  Attributes = 'attributes',
  ClinicId = 'clinicId',
  LastMessageId = 'lastMessageId',
  IsActive = 'isActive',
  ChannelStatusId = 'channelStatusId',
  CurrentWorkflowActionId = 'currentWorkflowActionId',
  ChannelType = 'channelType',
  ChannelVisibility = 'channelVisibility',
  Tags = 'tags',
  LastExportAt = 'lastExportAt'
}

export type ChannelScalarWhereInput = {
  AND?: Maybe<Array<ChannelScalarWhereInput>>;
  OR?: Maybe<Array<ChannelScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  twilioChannelSid?: Maybe<StringNullableFilter>;
  uniqueName?: Maybe<StringNullableFilter>;
  friendlyName?: Maybe<StringNullableFilter>;
  attributes?: Maybe<JsonNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  lastMessageId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  channelStatusId?: Maybe<StringNullableFilter>;
  currentWorkflowActionId?: Maybe<StringNullableFilter>;
  channelType?: Maybe<EnumChannelTypeNullableFilter>;
  channelVisibility?: Maybe<EnumChannelVisibilityNullableFilter>;
  tags?: Maybe<JsonNullableFilter>;
  lastExportAt?: Maybe<DateTimeNullableFilter>;
};

export type ChannelScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  twilioChannelSid?: Maybe<StringNullableWithAggregatesFilter>;
  uniqueName?: Maybe<StringNullableWithAggregatesFilter>;
  friendlyName?: Maybe<StringNullableWithAggregatesFilter>;
  attributes?: Maybe<JsonNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  lastMessageId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  channelStatusId?: Maybe<StringNullableWithAggregatesFilter>;
  currentWorkflowActionId?: Maybe<StringNullableWithAggregatesFilter>;
  channelType?: Maybe<EnumChannelTypeNullableWithAggregatesFilter>;
  channelVisibility?: Maybe<EnumChannelVisibilityNullableWithAggregatesFilter>;
  tags?: Maybe<JsonNullableWithAggregatesFilter>;
  lastExportAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
};

export type ChannelSearchOutput = {
  __typename?: 'ChannelSearchOutput';
  channels: Array<Channel>;
  total: Scalars['Int'];
};

export type ChannelSelection = {
  __typename?: 'ChannelSelection';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  includeUnassigned: Scalars['Boolean'];
  assignees: Array<User>;
  statuses: Array<ChannelStatus>;
  userSetting?: Maybe<UserSetting>;
  _count: ChannelSelectionCountOutputType;
};


export type ChannelSelectionAssigneesArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};


export type ChannelSelectionStatusesArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
  orderBy?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelStatusScalarFieldEnum>;
};

export type ChannelSelectionCountAggregateOutputType = {
  __typename?: 'ChannelSelectionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  includeUnassigned: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelSelectionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includeUnassigned?: Maybe<SortOrder>;
};

export type ChannelSelectionCountOutputType = {
  __typename?: 'ChannelSelectionCountOutputType';
  assignees: Scalars['Int'];
  statuses: Scalars['Int'];
};

export type ChannelSelectionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelSelectionsInput>;
  statuses?: Maybe<ChannelStatusCreateNestedManyWithoutChannelSelectionsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
};

export type ChannelSelectionCreateNestedManyWithoutAssigneesInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutAssigneesInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
};

export type ChannelSelectionCreateNestedManyWithoutStatusesInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutStatusesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutStatusesInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
};

export type ChannelSelectionCreateNestedOneWithoutUserSettingInput = {
  create?: Maybe<ChannelSelectionUncheckedCreateWithoutUserSettingInput>;
  connectOrCreate?: Maybe<ChannelSelectionCreateOrConnectWithoutUserSettingInput>;
  connect?: Maybe<ChannelSelectionWhereUniqueInput>;
};

export type ChannelSelectionCreateOrConnectWithoutAssigneesInput = {
  where: ChannelSelectionWhereUniqueInput;
  create: ChannelSelectionUncheckedCreateWithoutAssigneesInput;
};

export type ChannelSelectionCreateOrConnectWithoutStatusesInput = {
  where: ChannelSelectionWhereUniqueInput;
  create: ChannelSelectionUncheckedCreateWithoutStatusesInput;
};

export type ChannelSelectionCreateOrConnectWithoutUserSettingInput = {
  where: ChannelSelectionWhereUniqueInput;
  create: ChannelSelectionUncheckedCreateWithoutUserSettingInput;
};

export type ChannelSelectionCreateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusCreateNestedManyWithoutChannelSelectionsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionCreateWithoutStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelSelectionsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionCreateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelSelectionsInput>;
  statuses?: Maybe<ChannelStatusCreateNestedManyWithoutChannelSelectionsInput>;
};

export type ChannelSelectionListRelationFilter = {
  every?: Maybe<ChannelSelectionWhereInput>;
  some?: Maybe<ChannelSelectionWhereInput>;
  none?: Maybe<ChannelSelectionWhereInput>;
};

export type ChannelSelectionMaxAggregateOutputType = {
  __typename?: 'ChannelSelectionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
};

export type ChannelSelectionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includeUnassigned?: Maybe<SortOrder>;
};

export type ChannelSelectionMinAggregateOutputType = {
  __typename?: 'ChannelSelectionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
};

export type ChannelSelectionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includeUnassigned?: Maybe<SortOrder>;
};

export type ChannelSelectionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelSelectionOrderByRelevanceFieldEnum {
  Id = 'id'
}

export type ChannelSelectionOrderByRelevanceInput = {
  fields: Array<ChannelSelectionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelSelectionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includeUnassigned?: Maybe<SortOrder>;
  _count?: Maybe<ChannelSelectionCountOrderByAggregateInput>;
  _max?: Maybe<ChannelSelectionMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelSelectionMinOrderByAggregateInput>;
};

export type ChannelSelectionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includeUnassigned?: Maybe<SortOrder>;
  assignees?: Maybe<UserOrderByRelationAggregateInput>;
  statuses?: Maybe<ChannelStatusOrderByRelationAggregateInput>;
  userSetting?: Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ChannelSelectionOrderByRelevanceInput>;
};

export type ChannelSelectionRelationFilter = {
  is?: Maybe<ChannelSelectionWhereInput>;
  isNot?: Maybe<ChannelSelectionWhereInput>;
};

export enum ChannelSelectionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IncludeUnassigned = 'includeUnassigned'
}

export type ChannelSelectionScalarWhereInput = {
  AND?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
  OR?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  includeUnassigned?: Maybe<BoolFilter>;
};

export type ChannelSelectionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelSelectionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelSelectionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelSelectionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  includeUnassigned?: Maybe<BoolWithAggregatesFilter>;
};

export type ChannelSelectionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  statuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  userSetting?: Maybe<UserSettingUncheckedCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutAssigneesInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
};

export type ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutStatusesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutStatusesInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
};

export type ChannelSelectionUncheckedCreateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  userSetting?: Maybe<UserSettingUncheckedCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUncheckedCreateWithoutStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  userSetting?: Maybe<UserSettingUncheckedCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUncheckedCreateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  statuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
};

export type ChannelSelectionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelSelectionsNestedInput>;
  statuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutChannelSelectionsNestedInput>;
  userSetting?: Maybe<UserSettingUncheckedUpdateOneWithoutBrowserNotificationFilterNestedInput>;
};

export type ChannelSelectionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
};

export type ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ChannelSelectionUpsertWithWhereUniqueWithoutAssigneesInput>>;
  set?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelSelectionUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ChannelSelectionUpdateManyWithWhereWithoutAssigneesInput>>;
  deleteMany?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
};

export type ChannelSelectionUncheckedUpdateManyWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
};

export type ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutStatusesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutStatusesInput>>;
  upsert?: Maybe<Array<ChannelSelectionUpsertWithWhereUniqueWithoutStatusesInput>>;
  set?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelSelectionUpdateWithWhereUniqueWithoutStatusesInput>>;
  updateMany?: Maybe<Array<ChannelSelectionUpdateManyWithWhereWithoutStatusesInput>>;
  deleteMany?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
};

export type ChannelSelectionUncheckedUpdateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutChannelSelectionsNestedInput>;
  userSetting?: Maybe<UserSettingUncheckedUpdateOneWithoutBrowserNotificationFilterNestedInput>;
};

export type ChannelSelectionUncheckedUpdateWithoutStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelSelectionsNestedInput>;
  userSetting?: Maybe<UserSettingUncheckedUpdateOneWithoutBrowserNotificationFilterNestedInput>;
};

export type ChannelSelectionUncheckedUpdateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelSelectionsNestedInput>;
  statuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutChannelSelectionsNestedInput>;
};

export type ChannelSelectionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  assignees?: Maybe<UserUpdateManyWithoutChannelSelectionsNestedInput>;
  statuses?: Maybe<ChannelStatusUpdateManyWithoutChannelSelectionsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutBrowserNotificationFilterNestedInput>;
};

export type ChannelSelectionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
};

export type ChannelSelectionUpdateManyWithWhereWithoutAssigneesInput = {
  where: ChannelSelectionScalarWhereInput;
  data: ChannelSelectionUncheckedUpdateManyWithoutChannelSelectionsInput;
};

export type ChannelSelectionUpdateManyWithWhereWithoutStatusesInput = {
  where: ChannelSelectionScalarWhereInput;
  data: ChannelSelectionUncheckedUpdateManyWithoutChannelSelectionsInput;
};

export type ChannelSelectionUpdateManyWithoutAssigneesNestedInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ChannelSelectionUpsertWithWhereUniqueWithoutAssigneesInput>>;
  set?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelSelectionUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ChannelSelectionUpdateManyWithWhereWithoutAssigneesInput>>;
  deleteMany?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
};

export type ChannelSelectionUpdateManyWithoutStatusesNestedInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutStatusesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutStatusesInput>>;
  upsert?: Maybe<Array<ChannelSelectionUpsertWithWhereUniqueWithoutStatusesInput>>;
  set?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelSelectionUpdateWithWhereUniqueWithoutStatusesInput>>;
  updateMany?: Maybe<Array<ChannelSelectionUpdateManyWithWhereWithoutStatusesInput>>;
  deleteMany?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
};

export type ChannelSelectionUpdateOneWithoutUserSettingNestedInput = {
  create?: Maybe<ChannelSelectionUncheckedCreateWithoutUserSettingInput>;
  connectOrCreate?: Maybe<ChannelSelectionCreateOrConnectWithoutUserSettingInput>;
  upsert?: Maybe<ChannelSelectionUpsertWithoutUserSettingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelSelectionWhereUniqueInput>;
  update?: Maybe<ChannelSelectionUncheckedUpdateWithoutUserSettingInput>;
};

export type ChannelSelectionUpdateWithWhereUniqueWithoutAssigneesInput = {
  where: ChannelSelectionWhereUniqueInput;
  data: ChannelSelectionUncheckedUpdateWithoutAssigneesInput;
};

export type ChannelSelectionUpdateWithWhereUniqueWithoutStatusesInput = {
  where: ChannelSelectionWhereUniqueInput;
  data: ChannelSelectionUncheckedUpdateWithoutStatusesInput;
};

export type ChannelSelectionUpdateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUpdateManyWithoutChannelSelectionsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutBrowserNotificationFilterNestedInput>;
};

export type ChannelSelectionUpdateWithoutStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  assignees?: Maybe<UserUpdateManyWithoutChannelSelectionsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutBrowserNotificationFilterNestedInput>;
};

export type ChannelSelectionUpdateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  assignees?: Maybe<UserUpdateManyWithoutChannelSelectionsNestedInput>;
  statuses?: Maybe<ChannelStatusUpdateManyWithoutChannelSelectionsNestedInput>;
};

export type ChannelSelectionUpsertWithWhereUniqueWithoutAssigneesInput = {
  where: ChannelSelectionWhereUniqueInput;
  update: ChannelSelectionUncheckedUpdateWithoutAssigneesInput;
  create: ChannelSelectionUncheckedCreateWithoutAssigneesInput;
};

export type ChannelSelectionUpsertWithWhereUniqueWithoutStatusesInput = {
  where: ChannelSelectionWhereUniqueInput;
  update: ChannelSelectionUncheckedUpdateWithoutStatusesInput;
  create: ChannelSelectionUncheckedCreateWithoutStatusesInput;
};

export type ChannelSelectionUpsertWithoutUserSettingInput = {
  update: ChannelSelectionUncheckedUpdateWithoutUserSettingInput;
  create: ChannelSelectionUncheckedCreateWithoutUserSettingInput;
};

export type ChannelSelectionWhereInput = {
  AND?: Maybe<Array<ChannelSelectionWhereInput>>;
  OR?: Maybe<Array<ChannelSelectionWhereInput>>;
  NOT?: Maybe<Array<ChannelSelectionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  includeUnassigned?: Maybe<BoolFilter>;
  assignees?: Maybe<UserListRelationFilter>;
  statuses?: Maybe<ChannelStatusListRelationFilter>;
  userSetting?: Maybe<UserSettingWhereInput>;
};

export type ChannelSelectionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelStatus = {
  __typename?: 'ChannelStatus';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  channelStatusAction: ChannelStatusAction;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSetting>;
  deletedBy?: Maybe<User>;
  channels: Array<Channel>;
  clinicWidgetRequestType: Array<ClinicWidgetRequestType>;
  workflowEventSettings: Array<WorkflowEventSetting>;
  workflowEventAction: Array<WorkflowEventAction>;
  responseWorkflowEventActions: Array<WorkflowEventAction>;
  channelStatusChannelAssignees: Array<ChannelStatusChannelAssignee>;
  boardFilterColumns: Array<BoardFilterColumn>;
  automationRunActions: Array<AutomationRunAction>;
  channelSelections: Array<ChannelSelection>;
  _count: ChannelStatusCountOutputType;
};


export type ChannelStatusChannelsArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
};


export type ChannelStatusClinicWidgetRequestTypeArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
};


export type ChannelStatusWorkflowEventSettingsArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
};


export type ChannelStatusWorkflowEventActionArgs = {
  where?: Maybe<WorkflowEventActionWhereInput>;
  orderBy?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventActionScalarFieldEnum>;
};


export type ChannelStatusResponseWorkflowEventActionsArgs = {
  where?: Maybe<WorkflowEventActionWhereInput>;
  orderBy?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventActionScalarFieldEnum>;
};


export type ChannelStatusChannelStatusChannelAssigneesArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  orderBy?: Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>;
};


export type ChannelStatusBoardFilterColumnsArgs = {
  where?: Maybe<BoardFilterColumnWhereInput>;
  orderBy?: Maybe<BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BoardFilterColumnWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BoardFilterColumnScalarFieldEnum>;
};


export type ChannelStatusAutomationRunActionsArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
};


export type ChannelStatusChannelSelectionsArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
  orderBy?: Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelSelectionScalarFieldEnum>;
};

export enum ChannelStatusAction {
  Active = 'Active',
  Inactive = 'Inactive',
  InactivePermanently = 'InactivePermanently',
  Migrated = 'Migrated'
}

export type ChannelStatusAvgAggregateOutputType = {
  __typename?: 'ChannelStatusAvgAggregateOutputType';
  order?: Maybe<Scalars['Float']>;
};

export type ChannelStatusAvgOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type ChannelStatusChannelAssignee = {
  __typename?: 'ChannelStatusChannelAssignee';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  userId: Scalars['String'];
  channelStatusId: Scalars['String'];
  clinic: Clinic;
  user: User;
  channelStatus: ChannelStatus;
};

export type ChannelStatusChannelAssigneeChannelStatusChannelAssigneeChannelStatusIdAndUserIdCompoundUniqueInput = {
  userId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeCountAggregateOutputType = {
  __typename?: 'ChannelStatusChannelAssigneeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  userId: Scalars['Int'];
  channelStatusId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelStatusChannelAssigneeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
};

export type ChannelStatusChannelAssigneeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinic: ClinicCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  user: UserCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  channelStatus: ChannelStatusCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeCreateManyChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  userId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope = {
  data: Array<ChannelStatusChannelAssigneeCreateManyChannelStatusInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusChannelAssigneeCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope = {
  data: Array<ChannelStatusChannelAssigneeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusChannelAssigneeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  userId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeCreateManyUserInputEnvelope = {
  data: Array<ChannelStatusChannelAssigneeCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
};

export type ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
};

export type ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
};

export type ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutChannelStatusInput;
};

export type ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutClinicInput;
};

export type ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutUserInput;
};

export type ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinic: ClinicCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  user: UserCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  channelStatus: ChannelStatusCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinic: ClinicCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  channelStatus: ChannelStatusCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeListRelationFilter = {
  every?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  some?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  none?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};

export type ChannelStatusChannelAssigneeMaxAggregateOutputType = {
  __typename?: 'ChannelStatusChannelAssigneeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
};

export type ChannelStatusChannelAssigneeMinAggregateOutputType = {
  __typename?: 'ChannelStatusChannelAssigneeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
};

export type ChannelStatusChannelAssigneeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelStatusChannelAssigneeOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  UserId = 'userId',
  ChannelStatusId = 'channelStatusId'
}

export type ChannelStatusChannelAssigneeOrderByRelevanceInput = {
  fields: Array<ChannelStatusChannelAssigneeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelStatusChannelAssigneeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  _count?: Maybe<ChannelStatusChannelAssigneeCountOrderByAggregateInput>;
  _max?: Maybe<ChannelStatusChannelAssigneeMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelStatusChannelAssigneeMinOrderByAggregateInput>;
};

export type ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  channelStatus?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ChannelStatusChannelAssigneeOrderByRelevanceInput>;
};

export enum ChannelStatusChannelAssigneeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  ClinicId = 'clinicId',
  UserId = 'userId',
  ChannelStatusId = 'channelStatusId'
}

export type ChannelStatusChannelAssigneeScalarWhereInput = {
  AND?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  OR?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  channelStatusId?: Maybe<StringFilter>;
};

export type ChannelStatusChannelAssigneeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  channelStatusId?: Maybe<StringWithAggregatesFilter>;
};

export type ChannelStatusChannelAssigneeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  userId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedCreateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  userId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutChannelStatusInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
};

export type ChannelStatusChannelAssigneeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutChannelStatusInput = {
  where: ChannelStatusChannelAssigneeScalarWhereInput;
  data: ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutClinicInput = {
  where: ChannelStatusChannelAssigneeScalarWhereInput;
  data: ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutUserInput = {
  where: ChannelStatusChannelAssigneeScalarWhereInput;
  data: ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutChannelStatusInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
};

export type ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
};

export type ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
};

export type ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutChannelStatusInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  data: ChannelStatusChannelAssigneeUncheckedUpdateWithoutChannelStatusInput;
};

export type ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutClinicInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  data: ChannelStatusChannelAssigneeUncheckedUpdateWithoutClinicInput;
};

export type ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutUserInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  data: ChannelStatusChannelAssigneeUncheckedUpdateWithoutUserInput;
};

export type ChannelStatusChannelAssigneeUpdateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
};

export type ChannelStatusChannelAssigneeUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
};

export type ChannelStatusChannelAssigneeUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
};

export type ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutChannelStatusInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  update: ChannelStatusChannelAssigneeUncheckedUpdateWithoutChannelStatusInput;
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutChannelStatusInput;
};

export type ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutClinicInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  update: ChannelStatusChannelAssigneeUncheckedUpdateWithoutClinicInput;
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutClinicInput;
};

export type ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutUserInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  update: ChannelStatusChannelAssigneeUncheckedUpdateWithoutUserInput;
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutUserInput;
};

export type ChannelStatusChannelAssigneeWhereInput = {
  AND?: Maybe<Array<ChannelStatusChannelAssigneeWhereInput>>;
  OR?: Maybe<Array<ChannelStatusChannelAssigneeWhereInput>>;
  NOT?: Maybe<Array<ChannelStatusChannelAssigneeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  channelStatusId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  user?: Maybe<UserWhereInput>;
  channelStatus?: Maybe<ChannelStatusWhereInput>;
};

export type ChannelStatusChannelAssigneeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  channelStatusChannelAssigneeChannelStatusIdAndUserId?: Maybe<ChannelStatusChannelAssigneeChannelStatusChannelAssigneeChannelStatusIdAndUserIdCompoundUniqueInput>;
};

export type ChannelStatusCountAggregateOutputType = {
  __typename?: 'ChannelStatusCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  channelStatusAction: Scalars['Int'];
  color: Scalars['Int'];
  deletedOn: Scalars['Int'];
  isDefault: Scalars['Int'];
  name: Scalars['Int'];
  order: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  deletedById: Scalars['Int'];
  isExportedToPims: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelStatusCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelStatusAction?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  deletedById?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
};

export type ChannelStatusCountOutputType = {
  __typename?: 'ChannelStatusCountOutputType';
  channels: Scalars['Int'];
  clinicWidgetRequestType: Scalars['Int'];
  workflowEventSettings: Scalars['Int'];
  workflowEventAction: Scalars['Int'];
  responseWorkflowEventActions: Scalars['Int'];
  channelStatusChannelAssignees: Scalars['Int'];
  boardFilterColumns: Scalars['Int'];
  automationRunActions: Scalars['Int'];
  channelSelections: Scalars['Int'];
};

export type ChannelStatusCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateManyClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusCreateManyClinicSettingInputEnvelope = {
  data: Array<ChannelStatusCreateManyClinicSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusCreateManyDeletedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusCreateManyDeletedByInputEnvelope = {
  data: Array<ChannelStatusCreateManyDeletedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusCreateNestedManyWithoutChannelSelectionsInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutChannelSelectionsInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
};

export type ChannelStatusCreateNestedManyWithoutClinicSettingInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutClinicSettingInput>>;
  createMany?: Maybe<ChannelStatusCreateManyClinicSettingInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
};

export type ChannelStatusCreateNestedManyWithoutDeletedByInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutDeletedByInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutDeletedByInput>>;
  createMany?: Maybe<ChannelStatusCreateManyDeletedByInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
};

export type ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutAutomationRunActionsInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
};

export type ChannelStatusCreateNestedOneWithoutBoardFilterColumnsInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutBoardFilterColumnsInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutBoardFilterColumnsInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
};

export type ChannelStatusCreateNestedOneWithoutChannelStatusChannelAssigneesInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
};

export type ChannelStatusCreateNestedOneWithoutChannelsInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutChannelsInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutChannelsInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
};

export type ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutClinicWidgetRequestTypeInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
};

export type ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutResponseWorkflowEventActionsInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutResponseWorkflowEventActionsInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
};

export type ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutWorkflowEventActionInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
};

export type ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutWorkflowEventSettingsInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutWorkflowEventSettingsInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
};

export type ChannelStatusCreateOrConnectWithoutAutomationRunActionsInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput;
};

export type ChannelStatusCreateOrConnectWithoutBoardFilterColumnsInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutBoardFilterColumnsInput;
};

export type ChannelStatusCreateOrConnectWithoutChannelSelectionsInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutChannelSelectionsInput;
};

export type ChannelStatusCreateOrConnectWithoutChannelStatusChannelAssigneesInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusCreateOrConnectWithoutChannelsInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutChannelsInput;
};

export type ChannelStatusCreateOrConnectWithoutClinicSettingInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutClinicSettingInput;
};

export type ChannelStatusCreateOrConnectWithoutClinicWidgetRequestTypeInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput;
};

export type ChannelStatusCreateOrConnectWithoutDeletedByInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutDeletedByInput;
};

export type ChannelStatusCreateOrConnectWithoutResponseWorkflowEventActionsInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutResponseWorkflowEventActionsInput;
};

export type ChannelStatusCreateOrConnectWithoutWorkflowEventActionInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput;
};

export type ChannelStatusCreateOrConnectWithoutWorkflowEventSettingsInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutWorkflowEventSettingsInput;
};

export type ChannelStatusCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutBoardFilterColumnsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
};

export type ChannelStatusCreateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutDeletedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutResponseWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export enum ChannelStatusEntity {
  Channel = 'Channel',
  Appointment = 'Appointment',
  Automation = 'Automation'
}

export type ChannelStatusListRelationFilter = {
  every?: Maybe<ChannelStatusWhereInput>;
  some?: Maybe<ChannelStatusWhereInput>;
  none?: Maybe<ChannelStatusWhereInput>;
};

export type ChannelStatusMaxAggregateOutputType = {
  __typename?: 'ChannelStatusMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelStatusAction?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  deletedById?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
};

export type ChannelStatusMinAggregateOutputType = {
  __typename?: 'ChannelStatusMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelStatusAction?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  deletedById?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
};

export type ChannelStatusOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelStatusOrderByRelevanceFieldEnum {
  Id = 'id',
  Color = 'color',
  Name = 'name',
  ClinicSettingId = 'clinicSettingId',
  DeletedById = 'deletedById'
}

export type ChannelStatusOrderByRelevanceInput = {
  fields: Array<ChannelStatusOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelStatusOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelStatusAction?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  deletedById?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
  _count?: Maybe<ChannelStatusCountOrderByAggregateInput>;
  _avg?: Maybe<ChannelStatusAvgOrderByAggregateInput>;
  _max?: Maybe<ChannelStatusMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelStatusMinOrderByAggregateInput>;
  _sum?: Maybe<ChannelStatusSumOrderByAggregateInput>;
};

export type ChannelStatusOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelStatusAction?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  deletedById?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  deletedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
  workflowEventAction?: Maybe<WorkflowEventActionOrderByRelationAggregateInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionOrderByRelationAggregateInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeOrderByRelationAggregateInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnOrderByRelationAggregateInput>;
  automationRunActions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  channelSelections?: Maybe<ChannelSelectionOrderByRelationAggregateInput>;
  _relevance?: Maybe<ChannelStatusOrderByRelevanceInput>;
};

export type ChannelStatusRelationFilter = {
  is?: Maybe<ChannelStatusWhereInput>;
  isNot?: Maybe<ChannelStatusWhereInput>;
};

export enum ChannelStatusScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ChannelStatusAction = 'channelStatusAction',
  Color = 'color',
  DeletedOn = 'deletedOn',
  IsDefault = 'isDefault',
  Name = 'name',
  Order = 'order',
  ClinicSettingId = 'clinicSettingId',
  DeletedById = 'deletedById',
  IsExportedToPims = 'isExportedToPims'
}

export type ChannelStatusScalarWhereInput = {
  AND?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  OR?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  channelStatusAction?: Maybe<EnumChannelStatusActionFilter>;
  color?: Maybe<StringFilter>;
  deletedOn?: Maybe<DateTimeNullableFilter>;
  isDefault?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  clinicSettingId?: Maybe<StringNullableFilter>;
  deletedById?: Maybe<StringNullableFilter>;
  isExportedToPims?: Maybe<BoolNullableFilter>;
};

export type ChannelStatusScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelStatusScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelStatusScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelStatusScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  channelStatusAction?: Maybe<EnumChannelStatusActionWithAggregatesFilter>;
  color?: Maybe<StringWithAggregatesFilter>;
  deletedOn?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isDefault?: Maybe<BoolNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  order?: Maybe<IntNullableWithAggregatesFilter>;
  clinicSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  deletedById?: Maybe<StringNullableWithAggregatesFilter>;
  isExportedToPims?: Maybe<BoolNullableWithAggregatesFilter>;
};

export type ChannelStatusSumAggregateOutputType = {
  __typename?: 'ChannelStatusSumAggregateOutputType';
  order?: Maybe<Scalars['Int']>;
};

export type ChannelStatusSumOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type ChannelStatusUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateNestedManyWithoutChannelSelectionsInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutChannelSelectionsInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
};

export type ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutClinicSettingInput>>;
  createMany?: Maybe<ChannelStatusCreateManyClinicSettingInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
};

export type ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutDeletedByInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutDeletedByInput>>;
  createMany?: Maybe<ChannelStatusCreateManyDeletedByInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
};

export type ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutBoardFilterColumnsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutDeletedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutResponseWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusUncheckedUpdateManyWithoutChannelSelectionsNestedInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutChannelSelectionsInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutChannelSelectionsInput>>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutChannelSelectionsInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutChannelSelectionsInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
};

export type ChannelStatusUncheckedUpdateManyWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusUncheckedUpdateManyWithoutClinicSettingNestedInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutClinicSettingInput>>;
  createMany?: Maybe<ChannelStatusCreateManyClinicSettingInputEnvelope>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutClinicSettingInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
};

export type ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutDeletedByInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutDeletedByInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutDeletedByInput>>;
  createMany?: Maybe<ChannelStatusCreateManyDeletedByInputEnvelope>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutDeletedByInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutDeletedByInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
};

export type ChannelStatusUncheckedUpdateManyWithoutStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusUncheckedUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutBoardFilterColumnsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutDeletedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutResponseWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusUpdateManyWithWhereWithoutChannelSelectionsInput = {
  where: ChannelStatusScalarWhereInput;
  data: ChannelStatusUncheckedUpdateManyWithoutStatusesInput;
};

export type ChannelStatusUpdateManyWithWhereWithoutClinicSettingInput = {
  where: ChannelStatusScalarWhereInput;
  data: ChannelStatusUncheckedUpdateManyWithoutChannelStatusesInput;
};

export type ChannelStatusUpdateManyWithWhereWithoutDeletedByInput = {
  where: ChannelStatusScalarWhereInput;
  data: ChannelStatusUncheckedUpdateManyWithoutChannelStatusesInput;
};

export type ChannelStatusUpdateManyWithoutChannelSelectionsNestedInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutChannelSelectionsInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutChannelSelectionsInput>>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutChannelSelectionsInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutChannelSelectionsInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
};

export type ChannelStatusUpdateManyWithoutClinicSettingNestedInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutClinicSettingInput>>;
  createMany?: Maybe<ChannelStatusCreateManyClinicSettingInputEnvelope>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutClinicSettingInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
};

export type ChannelStatusUpdateManyWithoutDeletedByNestedInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutDeletedByInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutDeletedByInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutDeletedByInput>>;
  createMany?: Maybe<ChannelStatusCreateManyDeletedByInputEnvelope>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutDeletedByInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutDeletedByInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
};

export type ChannelStatusUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutChannelStatusChannelAssigneesInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutChannelStatusChannelAssigneesInput>;
};

export type ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutAutomationRunActionsInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutAutomationRunActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutAutomationRunActionsInput>;
};

export type ChannelStatusUpdateOneWithoutBoardFilterColumnsNestedInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutBoardFilterColumnsInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutBoardFilterColumnsInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutBoardFilterColumnsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutBoardFilterColumnsInput>;
};

export type ChannelStatusUpdateOneWithoutChannelsNestedInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutChannelsInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutChannelsInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutChannelsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutChannelsInput>;
};

export type ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutClinicWidgetRequestTypeInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutClinicWidgetRequestTypeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutClinicWidgetRequestTypeInput>;
};

export type ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutResponseWorkflowEventActionsInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutResponseWorkflowEventActionsInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutResponseWorkflowEventActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutResponseWorkflowEventActionsInput>;
};

export type ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutWorkflowEventActionInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutWorkflowEventActionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutWorkflowEventActionInput>;
};

export type ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutWorkflowEventSettingsInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutWorkflowEventSettingsInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutWorkflowEventSettingsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutWorkflowEventSettingsInput>;
};

export type ChannelStatusUpdateWithWhereUniqueWithoutChannelSelectionsInput = {
  where: ChannelStatusWhereUniqueInput;
  data: ChannelStatusUncheckedUpdateWithoutChannelSelectionsInput;
};

export type ChannelStatusUpdateWithWhereUniqueWithoutClinicSettingInput = {
  where: ChannelStatusWhereUniqueInput;
  data: ChannelStatusUncheckedUpdateWithoutClinicSettingInput;
};

export type ChannelStatusUpdateWithWhereUniqueWithoutDeletedByInput = {
  where: ChannelStatusWhereUniqueInput;
  data: ChannelStatusUncheckedUpdateWithoutDeletedByInput;
};

export type ChannelStatusUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUpdateWithoutBoardFilterColumnsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUpdateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
};

export type ChannelStatusUpdateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUpdateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUpdateWithoutDeletedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUpdateWithoutResponseWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUpdateWithoutWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUpdateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
};

export type ChannelStatusUpsertWithWhereUniqueWithoutChannelSelectionsInput = {
  where: ChannelStatusWhereUniqueInput;
  update: ChannelStatusUncheckedUpdateWithoutChannelSelectionsInput;
  create: ChannelStatusUncheckedCreateWithoutChannelSelectionsInput;
};

export type ChannelStatusUpsertWithWhereUniqueWithoutClinicSettingInput = {
  where: ChannelStatusWhereUniqueInput;
  update: ChannelStatusUncheckedUpdateWithoutClinicSettingInput;
  create: ChannelStatusUncheckedCreateWithoutClinicSettingInput;
};

export type ChannelStatusUpsertWithWhereUniqueWithoutDeletedByInput = {
  where: ChannelStatusWhereUniqueInput;
  update: ChannelStatusUncheckedUpdateWithoutDeletedByInput;
  create: ChannelStatusUncheckedCreateWithoutDeletedByInput;
};

export type ChannelStatusUpsertWithoutAutomationRunActionsInput = {
  update: ChannelStatusUncheckedUpdateWithoutAutomationRunActionsInput;
  create: ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput;
};

export type ChannelStatusUpsertWithoutBoardFilterColumnsInput = {
  update: ChannelStatusUncheckedUpdateWithoutBoardFilterColumnsInput;
  create: ChannelStatusUncheckedCreateWithoutBoardFilterColumnsInput;
};

export type ChannelStatusUpsertWithoutChannelStatusChannelAssigneesInput = {
  update: ChannelStatusUncheckedUpdateWithoutChannelStatusChannelAssigneesInput;
  create: ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusUpsertWithoutChannelsInput = {
  update: ChannelStatusUncheckedUpdateWithoutChannelsInput;
  create: ChannelStatusUncheckedCreateWithoutChannelsInput;
};

export type ChannelStatusUpsertWithoutClinicWidgetRequestTypeInput = {
  update: ChannelStatusUncheckedUpdateWithoutClinicWidgetRequestTypeInput;
  create: ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput;
};

export type ChannelStatusUpsertWithoutResponseWorkflowEventActionsInput = {
  update: ChannelStatusUncheckedUpdateWithoutResponseWorkflowEventActionsInput;
  create: ChannelStatusUncheckedCreateWithoutResponseWorkflowEventActionsInput;
};

export type ChannelStatusUpsertWithoutWorkflowEventActionInput = {
  update: ChannelStatusUncheckedUpdateWithoutWorkflowEventActionInput;
  create: ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput;
};

export type ChannelStatusUpsertWithoutWorkflowEventSettingsInput = {
  update: ChannelStatusUncheckedUpdateWithoutWorkflowEventSettingsInput;
  create: ChannelStatusUncheckedCreateWithoutWorkflowEventSettingsInput;
};

export type ChannelStatusWhereInput = {
  AND?: Maybe<Array<ChannelStatusWhereInput>>;
  OR?: Maybe<Array<ChannelStatusWhereInput>>;
  NOT?: Maybe<Array<ChannelStatusWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  channelStatusAction?: Maybe<EnumChannelStatusActionFilter>;
  color?: Maybe<StringFilter>;
  deletedOn?: Maybe<DateTimeNullableFilter>;
  isDefault?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  clinicSettingId?: Maybe<StringNullableFilter>;
  deletedById?: Maybe<StringNullableFilter>;
  isExportedToPims?: Maybe<BoolNullableFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
  deletedBy?: Maybe<UserWhereInput>;
  channels?: Maybe<ChannelListRelationFilter>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
  workflowEventSettings?: Maybe<WorkflowEventSettingListRelationFilter>;
  workflowEventAction?: Maybe<WorkflowEventActionListRelationFilter>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionListRelationFilter>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeListRelationFilter>;
  boardFilterColumns?: Maybe<BoardFilterColumnListRelationFilter>;
  automationRunActions?: Maybe<AutomationRunActionListRelationFilter>;
  channelSelections?: Maybe<ChannelSelectionListRelationFilter>;
};

export type ChannelStatusWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelStatusWithOrder = {
  id: Scalars['String'];
  order: Scalars['Int'];
};

export type ChannelStatusWithOrderResponse = {
  __typename?: 'ChannelStatusWithOrderResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type ChannelSubscription = {
  __typename?: 'ChannelSubscription';
  node: Channel;
  mutation: MutationType;
};

export type ChannelSuggestedMessageResponse = {
  __typename?: 'ChannelSuggestedMessageResponse';
  response: Scalars['String'];
  parsedJson?: Maybe<Scalars['Json']>;
};

export type ChannelSuggestedMessageWhereInput = {
  channelId: Scalars['String'];
};

export type ChannelSyncedToSearchWhereInput = {
  id?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  channelType?: Maybe<Scalars['String']>;
};

export enum ChannelType {
  Client = 'Client',
  Team = 'Team'
}

export type ChannelUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateNestedManyWithoutAssigneesInput = {
  create?: Maybe<Array<ChannelCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutAssigneesInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelUncheckedCreateNestedManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ChannelCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelCreateManyChannelStatusInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ChannelCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput = {
  create?: Maybe<Array<ChannelCreateWithoutCurrentWorkflowActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutCurrentWorkflowActionInput>>;
  createMany?: Maybe<ChannelCreateManyCurrentWorkflowActionInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelUncheckedCreateNestedManyWithoutLastMessageInput = {
  create?: Maybe<Array<ChannelCreateWithoutLastMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutLastMessageInput>>;
  createMany?: Maybe<ChannelCreateManyLastMessageInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelUncheckedCreateNestedManyWithoutPetsInput = {
  create?: Maybe<Array<ChannelCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutPetsInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutChannelPinsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutCreationSourceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutCurrentWorkflowActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutLastMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateManyWithoutAssigneesNestedInput = {
  create?: Maybe<Array<ChannelCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutAssigneesInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutAssigneesInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput = {
  create?: Maybe<Array<ChannelCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelCreateManyChannelStatusInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutChannelStatusInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUncheckedUpdateManyWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ChannelCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ChannelCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput = {
  create?: Maybe<Array<ChannelCreateWithoutCurrentWorkflowActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutCurrentWorkflowActionInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutCurrentWorkflowActionInput>>;
  createMany?: Maybe<ChannelCreateManyCurrentWorkflowActionInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutCurrentWorkflowActionInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutCurrentWorkflowActionInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUncheckedUpdateManyWithoutLastMessageChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateManyWithoutLastMessageNestedInput = {
  create?: Maybe<Array<ChannelCreateWithoutLastMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutLastMessageInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutLastMessageInput>>;
  createMany?: Maybe<ChannelCreateManyLastMessageInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutLastMessageInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutLastMessageInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUncheckedUpdateManyWithoutPetsNestedInput = {
  create?: Maybe<Array<ChannelCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutPetsInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutPetsInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutPetsInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUncheckedUpdateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUncheckedUpdateWithoutAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUncheckedUpdateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUncheckedUpdateWithoutChannelPinsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUncheckedUpdateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUncheckedUpdateWithoutCreationSourceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUncheckedUpdateWithoutCurrentWorkflowActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUncheckedUpdateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUncheckedUpdateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUncheckedUpdateWithoutLastMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUncheckedUpdateWithoutMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUncheckedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUnreadMessageCountResult = {
  __typename?: 'ChannelUnreadMessageCountResult';
  total: Scalars['Int'];
  unreadChannels: Array<UnreadMessageChannel>;
  client?: Maybe<ChannelCountResult>;
  team?: Maybe<ChannelCountResult>;
};

export type ChannelUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateManyWithWhereWithoutAssigneesInput = {
  where: ChannelScalarWhereInput;
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
};

export type ChannelUpdateManyWithWhereWithoutChannelStatusInput = {
  where: ChannelScalarWhereInput;
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
};

export type ChannelUpdateManyWithWhereWithoutClinicInput = {
  where: ChannelScalarWhereInput;
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
};

export type ChannelUpdateManyWithWhereWithoutCurrentWorkflowActionInput = {
  where: ChannelScalarWhereInput;
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
};

export type ChannelUpdateManyWithWhereWithoutLastMessageInput = {
  where: ChannelScalarWhereInput;
  data: ChannelUncheckedUpdateManyWithoutLastMessageChannelInput;
};

export type ChannelUpdateManyWithWhereWithoutPetsInput = {
  where: ChannelScalarWhereInput;
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
};

export type ChannelUpdateManyWithoutAssigneesNestedInput = {
  create?: Maybe<Array<ChannelCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutAssigneesInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutAssigneesInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUpdateManyWithoutChannelStatusNestedInput = {
  create?: Maybe<Array<ChannelCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelCreateManyChannelStatusInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutChannelStatusInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ChannelCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ChannelCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput = {
  create?: Maybe<Array<ChannelCreateWithoutCurrentWorkflowActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutCurrentWorkflowActionInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutCurrentWorkflowActionInput>>;
  createMany?: Maybe<ChannelCreateManyCurrentWorkflowActionInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutCurrentWorkflowActionInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutCurrentWorkflowActionInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUpdateManyWithoutLastMessageNestedInput = {
  create?: Maybe<Array<ChannelCreateWithoutLastMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutLastMessageInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutLastMessageInput>>;
  createMany?: Maybe<ChannelCreateManyLastMessageInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutLastMessageInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutLastMessageInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUpdateManyWithoutPetsNestedInput = {
  create?: Maybe<Array<ChannelCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutPetsInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutPetsInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutPetsInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutAutomationStatusesInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutAutomationStatusesInput>;
  upsert?: Maybe<ChannelUpsertWithoutAutomationStatusesInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutAutomationStatusesInput>;
};

export type ChannelUpdateOneRequiredWithoutCreationSourceNestedInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutCreationSourceInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutCreationSourceInput>;
  upsert?: Maybe<ChannelUpsertWithoutCreationSourceInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutCreationSourceInput>;
};

export type ChannelUpdateOneWithoutAppointmentsNestedInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<ChannelUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelWhereUniqueInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutAppointmentsInput>;
};

export type ChannelUpdateOneWithoutChannelMembersNestedInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutChannelMembersInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutChannelMembersInput>;
  upsert?: Maybe<ChannelUpsertWithoutChannelMembersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelWhereUniqueInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutChannelMembersInput>;
};

export type ChannelUpdateOneWithoutChannelPinsNestedInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutChannelPinsInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutChannelPinsInput>;
  upsert?: Maybe<ChannelUpsertWithoutChannelPinsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelWhereUniqueInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutChannelPinsInput>;
};

export type ChannelUpdateOneWithoutFinancialTransactionsNestedInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutFinancialTransactionsInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutFinancialTransactionsInput>;
  upsert?: Maybe<ChannelUpsertWithoutFinancialTransactionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelWhereUniqueInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutFinancialTransactionsInput>;
};

export type ChannelUpdateOneWithoutHillsToHomeApiLogNestedInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutHillsToHomeApiLogInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutHillsToHomeApiLogInput>;
  upsert?: Maybe<ChannelUpsertWithoutHillsToHomeApiLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelWhereUniqueInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutHillsToHomeApiLogInput>;
};

export type ChannelUpdateOneWithoutMessagesNestedInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutMessagesInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutMessagesInput>;
  upsert?: Maybe<ChannelUpsertWithoutMessagesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelWhereUniqueInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutMessagesInput>;
};

export type ChannelUpdateWithWhereUniqueWithoutAssigneesInput = {
  where: ChannelWhereUniqueInput;
  data: ChannelUncheckedUpdateWithoutAssigneesInput;
};

export type ChannelUpdateWithWhereUniqueWithoutChannelStatusInput = {
  where: ChannelWhereUniqueInput;
  data: ChannelUncheckedUpdateWithoutChannelStatusInput;
};

export type ChannelUpdateWithWhereUniqueWithoutClinicInput = {
  where: ChannelWhereUniqueInput;
  data: ChannelUncheckedUpdateWithoutClinicInput;
};

export type ChannelUpdateWithWhereUniqueWithoutCurrentWorkflowActionInput = {
  where: ChannelWhereUniqueInput;
  data: ChannelUncheckedUpdateWithoutCurrentWorkflowActionInput;
};

export type ChannelUpdateWithWhereUniqueWithoutLastMessageInput = {
  where: ChannelWhereUniqueInput;
  data: ChannelUncheckedUpdateWithoutLastMessageInput;
};

export type ChannelUpdateWithWhereUniqueWithoutPetsInput = {
  where: ChannelWhereUniqueInput;
  data: ChannelUncheckedUpdateWithoutPetsInput;
};

export type ChannelUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpdateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpdateWithoutAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpdateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpdateWithoutChannelPinsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpdateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpdateWithoutCreationSourceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpdateWithoutCurrentWorkflowActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpdateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpdateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpdateWithoutLastMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpdateWithoutMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
};

export type ChannelUpsertWithWhereUniqueWithoutAssigneesInput = {
  where: ChannelWhereUniqueInput;
  update: ChannelUncheckedUpdateWithoutAssigneesInput;
  create: ChannelUncheckedCreateWithoutAssigneesInput;
};

export type ChannelUpsertWithWhereUniqueWithoutChannelStatusInput = {
  where: ChannelWhereUniqueInput;
  update: ChannelUncheckedUpdateWithoutChannelStatusInput;
  create: ChannelUncheckedCreateWithoutChannelStatusInput;
};

export type ChannelUpsertWithWhereUniqueWithoutClinicInput = {
  where: ChannelWhereUniqueInput;
  update: ChannelUncheckedUpdateWithoutClinicInput;
  create: ChannelUncheckedCreateWithoutClinicInput;
};

export type ChannelUpsertWithWhereUniqueWithoutCurrentWorkflowActionInput = {
  where: ChannelWhereUniqueInput;
  update: ChannelUncheckedUpdateWithoutCurrentWorkflowActionInput;
  create: ChannelUncheckedCreateWithoutCurrentWorkflowActionInput;
};

export type ChannelUpsertWithWhereUniqueWithoutLastMessageInput = {
  where: ChannelWhereUniqueInput;
  update: ChannelUncheckedUpdateWithoutLastMessageInput;
  create: ChannelUncheckedCreateWithoutLastMessageInput;
};

export type ChannelUpsertWithWhereUniqueWithoutPetsInput = {
  where: ChannelWhereUniqueInput;
  update: ChannelUncheckedUpdateWithoutPetsInput;
  create: ChannelUncheckedCreateWithoutPetsInput;
};

export type ChannelUpsertWithoutAppointmentsInput = {
  update: ChannelUncheckedUpdateWithoutAppointmentsInput;
  create: ChannelUncheckedCreateWithoutAppointmentsInput;
};

export type ChannelUpsertWithoutAutomationStatusesInput = {
  update: ChannelUncheckedUpdateWithoutAutomationStatusesInput;
  create: ChannelUncheckedCreateWithoutAutomationStatusesInput;
};

export type ChannelUpsertWithoutChannelMembersInput = {
  update: ChannelUncheckedUpdateWithoutChannelMembersInput;
  create: ChannelUncheckedCreateWithoutChannelMembersInput;
};

export type ChannelUpsertWithoutChannelPinsInput = {
  update: ChannelUncheckedUpdateWithoutChannelPinsInput;
  create: ChannelUncheckedCreateWithoutChannelPinsInput;
};

export type ChannelUpsertWithoutCreationSourceInput = {
  update: ChannelUncheckedUpdateWithoutCreationSourceInput;
  create: ChannelUncheckedCreateWithoutCreationSourceInput;
};

export type ChannelUpsertWithoutFinancialTransactionsInput = {
  update: ChannelUncheckedUpdateWithoutFinancialTransactionsInput;
  create: ChannelUncheckedCreateWithoutFinancialTransactionsInput;
};

export type ChannelUpsertWithoutHillsToHomeApiLogInput = {
  update: ChannelUncheckedUpdateWithoutHillsToHomeApiLogInput;
  create: ChannelUncheckedCreateWithoutHillsToHomeApiLogInput;
};

export type ChannelUpsertWithoutMessagesInput = {
  update: ChannelUncheckedUpdateWithoutMessagesInput;
  create: ChannelUncheckedCreateWithoutMessagesInput;
};

export enum ChannelVisibility {
  Public = 'Public',
  Private = 'Private',
  DirectMessage = 'DirectMessage'
}

export type ChannelWhereInput = {
  AND?: Maybe<Array<ChannelWhereInput>>;
  OR?: Maybe<Array<ChannelWhereInput>>;
  NOT?: Maybe<Array<ChannelWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  twilioChannelSid?: Maybe<StringNullableFilter>;
  uniqueName?: Maybe<StringNullableFilter>;
  friendlyName?: Maybe<StringNullableFilter>;
  attributes?: Maybe<JsonNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  lastMessageId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  channelStatusId?: Maybe<StringNullableFilter>;
  currentWorkflowActionId?: Maybe<StringNullableFilter>;
  channelType?: Maybe<EnumChannelTypeNullableFilter>;
  channelVisibility?: Maybe<EnumChannelVisibilityNullableFilter>;
  tags?: Maybe<JsonNullableFilter>;
  lastExportAt?: Maybe<DateTimeNullableFilter>;
  currentWorkflowAction?: Maybe<WorkflowEventActionWhereInput>;
  lastMessage?: Maybe<ChannelMessageWhereInput>;
  channelStatus?: Maybe<ChannelStatusWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  channelMembers?: Maybe<ChannelMemberListRelationFilter>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  messages?: Maybe<ChannelMessageListRelationFilter>;
  assignees?: Maybe<UserListRelationFilter>;
  financialTransactions?: Maybe<FinancialTransactionListRelationFilter>;
  automationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  creationSource?: Maybe<ChannelCreationSourceWhereInput>;
  channelPins?: Maybe<ChannelPinListRelationFilter>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogListRelationFilter>;
};

export type ChannelWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ChargeCaptureType {
  Automatic = 'Automatic',
  Manual = 'Manual'
}

export type CheckClinicCareInput = {
  token?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type CheckClinicCareStatusResult = {
  __typename?: 'CheckClinicCareStatusResult';
  tokenClinic?: Maybe<CareClinicInfo>;
  idClinic?: Maybe<CareClinicInfo>;
};

export type CheckPhoneVerificationInput = {
  id: Scalars['String'];
};

export type ChemicalCompound = {
  __typename?: 'ChemicalCompound';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  activeIngredients: Array<ActiveIngredient>;
  _count: ChemicalCompoundCountOutputType;
};


export type ChemicalCompoundActiveIngredientsArgs = {
  where?: Maybe<ActiveIngredientWhereInput>;
  orderBy?: Maybe<ActiveIngredientOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ActiveIngredientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ActiveIngredientScalarFieldEnum>;
};

export type ChemicalCompoundCountAggregateOutputType = {
  __typename?: 'ChemicalCompoundCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  name: Scalars['Int'];
  description: Scalars['Int'];
  formula: Scalars['Int'];
  iupacName: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChemicalCompoundCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  formula?: Maybe<SortOrder>;
  iupacName?: Maybe<SortOrder>;
};

export type ChemicalCompoundCountOutputType = {
  __typename?: 'ChemicalCompoundCountOutputType';
  activeIngredients: Scalars['Int'];
};

export type ChemicalCompoundCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  activeIngredients?: Maybe<ActiveIngredientCreateNestedManyWithoutChemicalCompoundInput>;
};

export type ChemicalCompoundCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
};

export type ChemicalCompoundCreateNestedOneWithoutActiveIngredientsInput = {
  create?: Maybe<ChemicalCompoundUncheckedCreateWithoutActiveIngredientsInput>;
  connectOrCreate?: Maybe<ChemicalCompoundCreateOrConnectWithoutActiveIngredientsInput>;
  connect?: Maybe<ChemicalCompoundWhereUniqueInput>;
};

export type ChemicalCompoundCreateOrConnectWithoutActiveIngredientsInput = {
  where: ChemicalCompoundWhereUniqueInput;
  create: ChemicalCompoundUncheckedCreateWithoutActiveIngredientsInput;
};

export type ChemicalCompoundCreateWithoutActiveIngredientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
};

export type ChemicalCompoundMaxAggregateOutputType = {
  __typename?: 'ChemicalCompoundMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
};

export type ChemicalCompoundMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  formula?: Maybe<SortOrder>;
  iupacName?: Maybe<SortOrder>;
};

export type ChemicalCompoundMinAggregateOutputType = {
  __typename?: 'ChemicalCompoundMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
};

export type ChemicalCompoundMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  formula?: Maybe<SortOrder>;
  iupacName?: Maybe<SortOrder>;
};

export enum ChemicalCompoundOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  Formula = 'formula',
  IupacName = 'iupacName'
}

export type ChemicalCompoundOrderByRelevanceInput = {
  fields: Array<ChemicalCompoundOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChemicalCompoundOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  formula?: Maybe<SortOrder>;
  iupacName?: Maybe<SortOrder>;
  _count?: Maybe<ChemicalCompoundCountOrderByAggregateInput>;
  _max?: Maybe<ChemicalCompoundMaxOrderByAggregateInput>;
  _min?: Maybe<ChemicalCompoundMinOrderByAggregateInput>;
};

export type ChemicalCompoundOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  formula?: Maybe<SortOrder>;
  iupacName?: Maybe<SortOrder>;
  activeIngredients?: Maybe<ActiveIngredientOrderByRelationAggregateInput>;
  _relevance?: Maybe<ChemicalCompoundOrderByRelevanceInput>;
};

export type ChemicalCompoundRelationFilter = {
  is?: Maybe<ChemicalCompoundWhereInput>;
  isNot?: Maybe<ChemicalCompoundWhereInput>;
};

export enum ChemicalCompoundScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Name = 'name',
  Description = 'description',
  Formula = 'formula',
  IupacName = 'iupacName'
}

export type ChemicalCompoundScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChemicalCompoundScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChemicalCompoundScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChemicalCompoundScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  formula?: Maybe<StringNullableWithAggregatesFilter>;
  iupacName?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ChemicalCompoundUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  activeIngredients?: Maybe<ActiveIngredientUncheckedCreateNestedManyWithoutChemicalCompoundInput>;
};

export type ChemicalCompoundUncheckedCreateWithoutActiveIngredientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
};

export type ChemicalCompoundUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  activeIngredients?: Maybe<ActiveIngredientUncheckedUpdateManyWithoutChemicalCompoundNestedInput>;
};

export type ChemicalCompoundUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
};

export type ChemicalCompoundUncheckedUpdateWithoutActiveIngredientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
};

export type ChemicalCompoundUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  activeIngredients?: Maybe<ActiveIngredientUpdateManyWithoutChemicalCompoundNestedInput>;
};

export type ChemicalCompoundUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
};

export type ChemicalCompoundUpdateOneRequiredWithoutActiveIngredientsNestedInput = {
  create?: Maybe<ChemicalCompoundUncheckedCreateWithoutActiveIngredientsInput>;
  connectOrCreate?: Maybe<ChemicalCompoundCreateOrConnectWithoutActiveIngredientsInput>;
  upsert?: Maybe<ChemicalCompoundUpsertWithoutActiveIngredientsInput>;
  connect?: Maybe<ChemicalCompoundWhereUniqueInput>;
  update?: Maybe<ChemicalCompoundUncheckedUpdateWithoutActiveIngredientsInput>;
};

export type ChemicalCompoundUpdateWithoutActiveIngredientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
};

export type ChemicalCompoundUpsertWithoutActiveIngredientsInput = {
  update: ChemicalCompoundUncheckedUpdateWithoutActiveIngredientsInput;
  create: ChemicalCompoundUncheckedCreateWithoutActiveIngredientsInput;
};

export type ChemicalCompoundWhereInput = {
  AND?: Maybe<Array<ChemicalCompoundWhereInput>>;
  OR?: Maybe<Array<ChemicalCompoundWhereInput>>;
  NOT?: Maybe<Array<ChemicalCompoundWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  formula?: Maybe<StringNullableFilter>;
  iupacName?: Maybe<StringNullableFilter>;
  activeIngredients?: Maybe<ActiveIngredientListRelationFilter>;
};

export type ChemicalCompoundWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ChurnZeroResponse = {
  __typename?: 'ChurnZeroResponse';
  authToken: Scalars['String'];
};

export type ClaimClinicPetParentAuthCodeRequest = {
  otc: Scalars['String'];
  requestId: Scalars['String'];
};

export type ClaimClinicPetParentAuthCodeResponse = {
  __typename?: 'ClaimClinicPetParentAuthCodeResponse';
  success: Scalars['Boolean'];
  message: Scalars['String'];
  payload?: Maybe<Scalars['Json']>;
};

export type ClearTerminalDisplayResponse = {
  __typename?: 'ClearTerminalDisplayResponse';
  terminal: StripeTerminal;
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type ClearUnreadMessagesInput = {
  userId: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
};

export enum ClientConnectClinicSoftware {
  Cornerstone = 'CORNERSTONE',
  Neo = 'NEO',
  Avimark = 'AVIMARK',
  Infinity = 'INFINITY',
  Via = 'VIA',
  CovetrusPulse = 'COVETRUS_PULSE',
  Dvmax = 'DVMAX',
  VetechAdvantage = 'VETECH_ADVANTAGE',
  DvmManager = 'DVM_MANAGER',
  Clientrax = 'CLIENTRAX',
  VtechPlatinum = 'VTECH_PLATINUM',
  Intravet = 'INTRAVET',
  Daysmart = 'DAYSMART',
  Intravetsql = 'INTRAVETSQL',
  Ezyvet = 'EZYVET',
  Pulse = 'PULSE',
  ImpromedAdvantage = 'IMPROMED_ADVANTAGE',
  ImpromedInfinity = 'IMPROMED_INFINITY',
  ShepherdVeterinarySoftware = 'SHEPHERD_VETERINARY_SOFTWARE',
  Hippo = 'HIPPO',
  Vetspire = 'VETSPIRE',
  Rhapsody = 'RHAPSODY',
  CornerstoneCloud = 'CORNERSTONE_CLOUD',
  Unsupported = 'UNSUPPORTED',
  Unknown = 'UNKNOWN'
}

export type ClientConnectIntegration = {
  __typename?: 'ClientConnectIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clientConnectSystem: ClientConnectSystem;
  clinicSoftware: ClientConnectClinicSoftware;
  locationNumber?: Maybe<Scalars['String']>;
  erpId?: Maybe<Scalars['String']>;
  isMultiSite: Scalars['Boolean'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type ClientConnectIntegrationCountAggregateOutputType = {
  __typename?: 'ClientConnectIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clientConnectSystem: Scalars['Int'];
  clinicSoftware: Scalars['Int'];
  locationNumber: Scalars['Int'];
  erpId: Scalars['Int'];
  isMultiSite: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClientConnectIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clientConnectSystem?: Maybe<SortOrder>;
  clinicSoftware?: Maybe<SortOrder>;
  locationNumber?: Maybe<SortOrder>;
  erpId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
};

export type ClientConnectIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientConnectSystem: ClientConnectSystem;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  locationNumber?: Maybe<Scalars['String']>;
  erpId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutClientConnectInput>;
};

export type ClientConnectIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientConnectSystem: ClientConnectSystem;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  locationNumber?: Maybe<Scalars['String']>;
  erpId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<ClientConnectIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<ClientConnectIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<ClientConnectIntegrationWhereUniqueInput>;
};

export type ClientConnectIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: ClientConnectIntegrationWhereUniqueInput;
  create: ClientConnectIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type ClientConnectIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientConnectSystem: ClientConnectSystem;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  locationNumber?: Maybe<Scalars['String']>;
  erpId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type ClientConnectIntegrationMaxAggregateOutputType = {
  __typename?: 'ClientConnectIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  locationNumber?: Maybe<Scalars['String']>;
  erpId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type ClientConnectIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clientConnectSystem?: Maybe<SortOrder>;
  clinicSoftware?: Maybe<SortOrder>;
  locationNumber?: Maybe<SortOrder>;
  erpId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
};

export type ClientConnectIntegrationMinAggregateOutputType = {
  __typename?: 'ClientConnectIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  locationNumber?: Maybe<Scalars['String']>;
  erpId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type ClientConnectIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clientConnectSystem?: Maybe<SortOrder>;
  clinicSoftware?: Maybe<SortOrder>;
  locationNumber?: Maybe<SortOrder>;
  erpId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
};

export enum ClientConnectIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  LocationNumber = 'locationNumber',
  ErpId = 'erpId'
}

export type ClientConnectIntegrationOrderByRelevanceInput = {
  fields: Array<ClientConnectIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClientConnectIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clientConnectSystem?: Maybe<SortOrder>;
  clinicSoftware?: Maybe<SortOrder>;
  locationNumber?: Maybe<SortOrder>;
  erpId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  _count?: Maybe<ClientConnectIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<ClientConnectIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<ClientConnectIntegrationMinOrderByAggregateInput>;
};

export type ClientConnectIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clientConnectSystem?: Maybe<SortOrder>;
  clinicSoftware?: Maybe<SortOrder>;
  locationNumber?: Maybe<SortOrder>;
  erpId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClientConnectIntegrationOrderByRelevanceInput>;
};

export type ClientConnectIntegrationRelationFilter = {
  is?: Maybe<ClientConnectIntegrationWhereInput>;
  isNot?: Maybe<ClientConnectIntegrationWhereInput>;
};

export enum ClientConnectIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClientConnectSystem = 'clientConnectSystem',
  ClinicSoftware = 'clinicSoftware',
  LocationNumber = 'locationNumber',
  ErpId = 'erpId',
  IsMultiSite = 'isMultiSite'
}

export type ClientConnectIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClientConnectIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClientConnectIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClientConnectIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clientConnectSystem?: Maybe<EnumClientConnectSystemWithAggregatesFilter>;
  clinicSoftware?: Maybe<EnumClientConnectClinicSoftwareWithAggregatesFilter>;
  locationNumber?: Maybe<StringNullableWithAggregatesFilter>;
  erpId?: Maybe<StringNullableWithAggregatesFilter>;
  isMultiSite?: Maybe<BoolWithAggregatesFilter>;
};

export type ClientConnectIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientConnectSystem: ClientConnectSystem;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  locationNumber?: Maybe<Scalars['String']>;
  erpId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutClientConnectInput>;
};

export type ClientConnectIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientConnectSystem: ClientConnectSystem;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  locationNumber?: Maybe<Scalars['String']>;
  erpId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type ClientConnectIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  locationNumber?: Maybe<Scalars['String']>;
  erpId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutClientConnectNestedInput>;
};

export type ClientConnectIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  locationNumber?: Maybe<Scalars['String']>;
  erpId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type ClientConnectIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  locationNumber?: Maybe<Scalars['String']>;
  erpId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type ClientConnectIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  locationNumber?: Maybe<Scalars['String']>;
  erpId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutClientConnectNestedInput>;
};

export type ClientConnectIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  locationNumber?: Maybe<Scalars['String']>;
  erpId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  create?: Maybe<ClientConnectIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<ClientConnectIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<ClientConnectIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClientConnectIntegrationWhereUniqueInput>;
  update?: Maybe<ClientConnectIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type ClientConnectIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  locationNumber?: Maybe<Scalars['String']>;
  erpId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type ClientConnectIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: ClientConnectIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: ClientConnectIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type ClientConnectIntegrationWhereInput = {
  AND?: Maybe<Array<ClientConnectIntegrationWhereInput>>;
  OR?: Maybe<Array<ClientConnectIntegrationWhereInput>>;
  NOT?: Maybe<Array<ClientConnectIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clientConnectSystem?: Maybe<EnumClientConnectSystemFilter>;
  clinicSoftware?: Maybe<EnumClientConnectClinicSoftwareFilter>;
  locationNumber?: Maybe<StringNullableFilter>;
  erpId?: Maybe<StringNullableFilter>;
  isMultiSite?: Maybe<BoolFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type ClientConnectIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ClientConnectSystem {
  Idexx = 'IDEXX',
  Allydvm = 'ALLYDVM'
}

export type Clinic = {
  __typename?: 'Clinic';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  /** @deprecated - 1.0 */
  subscribedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  consultationCost?: Maybe<Scalars['Float']>;
  /** @deprecated - 1.0 */
  followUpCost?: Maybe<Scalars['Float']>;
  /** @deprecated - 1.0 */
  incomeMethod?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentName?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentAddress?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentCity?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentState?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentZip?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  lat?: Maybe<Scalars['Float']>;
  /** @deprecated - 1.0 */
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  /** @deprecated - 1.0 */
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  /** @deprecated - 1.0 */
  integrationType?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  integrationInfo?: Maybe<Scalars['Json']>;
  /** @deprecated - 1.0 */
  corporateId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  customerId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  subscriptionId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  subscriptionPlan?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive: Scalars['Boolean'];
  /** @deprecated - 1.0 */
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  /** @deprecated - 1.0 */
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  /** @deprecated - 1.0 */
  cancellationReason?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  cancellationNote?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  canceledAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  firebaseVets?: Maybe<Scalars['Json']>;
  /** @deprecated - 1.0 */
  integrationSystem?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  marketEmail?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  /** @deprecated - 1.0 */
  marketFacebookPageId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  printLastOrder?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  /** @deprecated - 1.0 */
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  submerchantId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  submerchantStatus?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  /** @deprecated - 1.0 */
  crmUrlParams?: Maybe<Scalars['Json']>;
  /** @deprecated - 1.0 */
  paymentPhone?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  automaticSubscriptionReceipts: Scalars['Boolean'];
  /** @deprecated - 1.0 */
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  clinicSetting?: Maybe<ClinicSetting>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  usersId?: Maybe<User>;
  organization?: Maybe<Organization>;
  appointments: Array<Appointment>;
  appointmentAvailabilities: Array<AppointmentAvailability>;
  appoinmentTypes: Array<AppointmentType>;
  channels: Array<Channel>;
  clinicEmployees: Array<ClinicEmployee>;
  clinicOfficeHours: Array<ClinicOfficeHour>;
  clinicPets: Array<ClinicPet>;
  clinicPetParents: Array<ClinicPetParent>;
  integrations: Array<ClinicPimsIntegration>;
  clinicRooms: Array<ClinicRoom>;
  clinicWidgetRequests: Array<ClinicWidgetRequest>;
  clinicWorkflowTypes: Array<ClinicWorkflowType>;
  /** @deprecated - 1.0 */
  consultations: Array<Consultation>;
  /** @deprecated - 1.0 */
  consultationRequests: Array<ConsultationRequest>;
  formSubmissions: Array<FormSubmission>;
  formTemplates: Array<FormTemplate>;
  /** @deprecated - 1.0 */
  petsId: Array<Pet>;
  /** @deprecated - 1.0 */
  promoCodes: Array<PromoCode>;
  roles: Array<StaffRole>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  staffId: Array<VetInfo>;
  workflowEventSettings: Array<WorkflowEventSetting>;
  stripePayoutAccounts: Array<StripePayoutAccount>;
  /** @deprecated - 1.0 */
  pets: Array<Pet>;
  staff: Array<VetInfo>;
  users: Array<User>;
  twilioVerifications: Array<TwilioVerification>;
  stripeTerminalLocations: Array<StripeTerminalLocation>;
  twilioConfiguration?: Maybe<TwilioConfiguration>;
  financialTransactions: Array<FinancialTransaction>;
  featureFlags: Array<FeatureFlag>;
  channelStatusChannelAssignees: Array<ChannelStatusChannelAssignee>;
  clinicEmailCampaignSettings: Array<ClinicEmailCampaignSetting>;
  calls: Array<CallHistory>;
  benefitsProvided: Array<CareBenefitUsage>;
  carePayoutOrganization?: Maybe<Organization>;
  vetAvailabilities: Array<VetAvailability>;
  clinicDirectBookingSettings: Array<ClinicDirectBookingSetting>;
  channelFilterSelections: Array<ChannelFilterSelection>;
  messageTemplates: Array<MessageTemplate>;
  Rules: Array<Rules>;
  postcardSettings: Array<ClinicPostcardSetting>;
  automationRuns: Array<AutomationRun>;
  vaccinations: Array<Vaccination>;
  massTextAlerts: Array<MassTextAlert>;
  prescriptions: Array<Prescription>;
  clinicPhoneNumber: Array<ClinicPhoneNumber>;
  clinicOnboardings: Array<ClinicOnboarding>;
  surveyResults: Array<SurveyResult>;
  informPartnerProgramEnrollments: Array<InformPartnerProgramEnrollment>;
  loyaltyPointDeltas: Array<LoyaltyPointDelta>;
  loyaltyRewardRedemptionActions: Array<LoyaltyRewardRedemptionHistoryEntry>;
  media: Array<Media>;
  loyaltyProgram?: Maybe<LoyaltyProgram>;
  legalEntity?: Maybe<LegalEntity>;
  unlayerSetting?: Maybe<UnlayerSetting>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroup>;
  directBookingExclusionRules: Array<DirectBookingExclusionRule>;
  sentTrupanionExamDayOffers: Array<SentTrupanionExamDayOffer>;
  insuranceIntegrations: Array<InsuranceIntegration>;
  payoutBatchingPeriods: Array<PayoutBatchingPeriod>;
  bitwerxHealthchecks: Array<BitwerxHealthcheck>;
  emailCampaign: Array<EmailCampaign>;
  emailCampaignTemplate: Array<EmailCampaignTemplate>;
  EmailCampaignUnsubscribe: Array<EmailCampaignUnsubscribe>;
  EmailCampaignLog: Array<EmailCampaignLog>;
  blockedPhoneNumbers: Array<ClinicBlockedPhoneNumber>;
  HillsToHomeAPILog: Array<HillsToHomeApiLog>;
  userBoardFiltersColumns: Array<BoardFilterColumn>;
  informPartnerCustomer?: Maybe<InformPartnerCustomer>;
  vetsourceInstallation?: Maybe<VetsourceInstallation>;
  _count: ClinicCountOutputType;
};


export type ClinicAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};


export type ClinicAppointmentAvailabilitiesArgs = {
  where?: Maybe<AppointmentAvailabilityWhereInput>;
  orderBy?: Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentAvailabilityScalarFieldEnum>;
};


export type ClinicAppoinmentTypesArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentTypeScalarFieldEnum>;
};


export type ClinicChannelsArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
};


export type ClinicClinicEmployeesArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeScalarFieldEnum>;
};


export type ClinicClinicOfficeHoursArgs = {
  where?: Maybe<ClinicOfficeHourWhereInput>;
  orderBy?: Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicOfficeHourScalarFieldEnum>;
};


export type ClinicClinicPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};


export type ClinicClinicPetParentsArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};


export type ClinicIntegrationsArgs = {
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
  orderBy?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPimsIntegrationScalarFieldEnum>;
};


export type ClinicClinicRoomsArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
  orderBy?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicRoomScalarFieldEnum>;
};


export type ClinicClinicWidgetRequestsArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestScalarFieldEnum>;
};


export type ClinicClinicWorkflowTypesArgs = {
  where?: Maybe<ClinicWorkflowTypeWhereInput>;
  orderBy?: Maybe<ClinicWorkflowTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWorkflowTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWorkflowTypeScalarFieldEnum>;
};


export type ClinicConsultationsArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
};


export type ClinicConsultationRequestsArgs = {
  where?: Maybe<ConsultationRequestWhereInput>;
  orderBy?: Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationRequestScalarFieldEnum>;
};


export type ClinicFormSubmissionsArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
};


export type ClinicFormTemplatesArgs = {
  where?: Maybe<FormTemplateWhereInput>;
  orderBy?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormTemplateScalarFieldEnum>;
};


export type ClinicPetsIdArgs = {
  where?: Maybe<PetWhereInput>;
  orderBy?: Maybe<PetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PetScalarFieldEnum>;
};


export type ClinicPromoCodesArgs = {
  where?: Maybe<PromoCodeWhereInput>;
  orderBy?: Maybe<PromoCodeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PromoCodeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PromoCodeScalarFieldEnum>;
};


export type ClinicRolesArgs = {
  where?: Maybe<StaffRoleWhereInput>;
  orderBy?: Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StaffRoleScalarFieldEnum>;
};


export type ClinicStripePaymentIntentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};


export type ClinicUserAppointmentGroupingsArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
};


export type ClinicStaffIdArgs = {
  where?: Maybe<VetInfoWhereInput>;
  orderBy?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetInfoScalarFieldEnum>;
};


export type ClinicWorkflowEventSettingsArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
};


export type ClinicStripePayoutAccountsArgs = {
  where?: Maybe<StripePayoutAccountWhereInput>;
  orderBy?: Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePayoutAccountScalarFieldEnum>;
};


export type ClinicPetsArgs = {
  where?: Maybe<PetWhereInput>;
  orderBy?: Maybe<PetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PetScalarFieldEnum>;
};


export type ClinicStaffArgs = {
  where?: Maybe<VetInfoWhereInput>;
  orderBy?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetInfoScalarFieldEnum>;
};


export type ClinicUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};


export type ClinicTwilioVerificationsArgs = {
  where?: Maybe<TwilioVerificationWhereInput>;
  orderBy?: Maybe<TwilioVerificationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<TwilioVerificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<TwilioVerificationScalarFieldEnum>;
};


export type ClinicStripeTerminalLocationsArgs = {
  where?: Maybe<StripeTerminalLocationWhereInput>;
  orderBy?: Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeTerminalLocationScalarFieldEnum>;
};


export type ClinicFinancialTransactionsArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
  orderBy?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FinancialTransactionScalarFieldEnum>;
};


export type ClinicFeatureFlagsArgs = {
  where?: Maybe<FeatureFlagWhereInput>;
  orderBy?: Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FeatureFlagScalarFieldEnum>;
};


export type ClinicChannelStatusChannelAssigneesArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  orderBy?: Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>;
};


export type ClinicClinicEmailCampaignSettingsArgs = {
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  orderBy?: Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmailCampaignSettingScalarFieldEnum>;
};


export type ClinicCallsArgs = {
  where?: Maybe<CallHistoryWhereInput>;
  orderBy?: Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CallHistoryScalarFieldEnum>;
};


export type ClinicBenefitsProvidedArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
};


export type ClinicVetAvailabilitiesArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
  orderBy?: Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetAvailabilityScalarFieldEnum>;
};


export type ClinicClinicDirectBookingSettingsArgs = {
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
  orderBy?: Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicDirectBookingSettingScalarFieldEnum>;
};


export type ClinicChannelFilterSelectionsArgs = {
  where?: Maybe<ChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelFilterSelectionScalarFieldEnum>;
};


export type ClinicMessageTemplatesArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
  orderBy?: Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MessageTemplateScalarFieldEnum>;
};


export type ClinicRulesArgs = {
  where?: Maybe<RulesWhereInput>;
  orderBy?: Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<RulesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<RulesScalarFieldEnum>;
};


export type ClinicPostcardSettingsArgs = {
  where?: Maybe<ClinicPostcardSettingWhereInput>;
  orderBy?: Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPostcardSettingScalarFieldEnum>;
};


export type ClinicAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};


export type ClinicVaccinationsArgs = {
  where?: Maybe<VaccinationWhereInput>;
  orderBy?: Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VaccinationScalarFieldEnum>;
};


export type ClinicMassTextAlertsArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
  orderBy?: Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MassTextAlertScalarFieldEnum>;
};


export type ClinicPrescriptionsArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
};


export type ClinicClinicPhoneNumberArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
  orderBy?: Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPhoneNumberScalarFieldEnum>;
};


export type ClinicClinicOnboardingsArgs = {
  where?: Maybe<ClinicOnboardingWhereInput>;
  orderBy?: Maybe<ClinicOnboardingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicOnboardingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicOnboardingScalarFieldEnum>;
};


export type ClinicSurveyResultsArgs = {
  where?: Maybe<SurveyResultWhereInput>;
  orderBy?: Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SurveyResultScalarFieldEnum>;
};


export type ClinicInformPartnerProgramEnrollmentsArgs = {
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  orderBy?: Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerProgramEnrollmentScalarFieldEnum>;
};


export type ClinicLoyaltyPointDeltasArgs = {
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
  orderBy?: Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyPointDeltaScalarFieldEnum>;
};


export type ClinicLoyaltyRewardRedemptionActionsArgs = {
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  orderBy?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum>;
};


export type ClinicMediaArgs = {
  where?: Maybe<MediaWhereInput>;
  orderBy?: Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MediaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MediaScalarFieldEnum>;
};


export type ClinicDirectBookingExclusionRulesArgs = {
  where?: Maybe<DirectBookingExclusionRuleWhereInput>;
  orderBy?: Maybe<DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<DirectBookingExclusionRuleScalarFieldEnum>;
};


export type ClinicSentTrupanionExamDayOffersArgs = {
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
  orderBy?: Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SentTrupanionExamDayOfferScalarFieldEnum>;
};


export type ClinicInsuranceIntegrationsArgs = {
  where?: Maybe<InsuranceIntegrationWhereInput>;
  orderBy?: Maybe<InsuranceIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InsuranceIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InsuranceIntegrationScalarFieldEnum>;
};


export type ClinicPayoutBatchingPeriodsArgs = {
  where?: Maybe<PayoutBatchingPeriodWhereInput>;
  orderBy?: Maybe<PayoutBatchingPeriodOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PayoutBatchingPeriodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PayoutBatchingPeriodScalarFieldEnum>;
};


export type ClinicBitwerxHealthchecksArgs = {
  where?: Maybe<BitwerxHealthcheckWhereInput>;
  orderBy?: Maybe<BitwerxHealthcheckOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BitwerxHealthcheckWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BitwerxHealthcheckScalarFieldEnum>;
};


export type ClinicEmailCampaignArgs = {
  where?: Maybe<EmailCampaignWhereInput>;
  orderBy?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignScalarFieldEnum>;
};


export type ClinicEmailCampaignTemplateArgs = {
  where?: Maybe<EmailCampaignTemplateWhereInput>;
  orderBy?: Maybe<EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignTemplateScalarFieldEnum>;
};


export type ClinicEmailCampaignUnsubscribeArgs = {
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
  orderBy?: Maybe<EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignUnsubscribeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignUnsubscribeScalarFieldEnum>;
};


export type ClinicEmailCampaignLogArgs = {
  where?: Maybe<EmailCampaignLogWhereInput>;
  orderBy?: Maybe<EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignLogScalarFieldEnum>;
};


export type ClinicBlockedPhoneNumbersArgs = {
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
  orderBy?: Maybe<ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicBlockedPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicBlockedPhoneNumberScalarFieldEnum>;
};


export type ClinicHillsToHomeApiLogArgs = {
  where?: Maybe<HillsToHomeApiLogWhereInput>;
  orderBy?: Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<HillsToHomeApiLogScalarFieldEnum>;
};


export type ClinicUserBoardFiltersColumnsArgs = {
  where?: Maybe<BoardFilterColumnWhereInput>;
  orderBy?: Maybe<BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BoardFilterColumnWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BoardFilterColumnScalarFieldEnum>;
};

export type ClinicAvgAggregateOutputType = {
  __typename?: 'ClinicAvgAggregateOutputType';
  lastPrintOrderedQuantity?: Maybe<Scalars['Float']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Float']>;
  marketFacebookNumber?: Maybe<Scalars['Float']>;
  printReceivedQuantity?: Maybe<Scalars['Float']>;
  unlayerSettingId?: Maybe<Scalars['Float']>;
};

export type ClinicAvgOrderByAggregateInput = {
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
};

export type ClinicBlockedPhoneNumber = {
  __typename?: 'ClinicBlockedPhoneNumber';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  phoneNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
  clinic: Clinic;
  blockedBy?: Maybe<User>;
  unblockedBy?: Maybe<User>;
};

export type ClinicBlockedPhoneNumberClinicIdAndPhoneNumberCompoundUniqueInput = {
  clinicId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ClinicBlockedPhoneNumberCountAggregateOutputType = {
  __typename?: 'ClinicBlockedPhoneNumberCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  notes: Scalars['Int'];
  blockedByUserId: Scalars['Int'];
  unblockedByUserId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicBlockedPhoneNumberCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  blockedByUserId?: Maybe<SortOrder>;
  unblockedByUserId?: Maybe<SortOrder>;
};

export type ClinicBlockedPhoneNumberCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutBlockedPhoneNumbersInput;
  blockedBy?: Maybe<UserCreateNestedOneWithoutBlockedNumbersInput>;
  unblockedBy?: Maybe<UserCreateNestedOneWithoutUnblockedNumbersInput>;
};

export type ClinicBlockedPhoneNumberCreateManyBlockedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  phoneNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberCreateManyBlockedByInputEnvelope = {
  data: Array<ClinicBlockedPhoneNumberCreateManyBlockedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicBlockedPhoneNumberCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberCreateManyClinicInputEnvelope = {
  data: Array<ClinicBlockedPhoneNumberCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicBlockedPhoneNumberCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  phoneNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberCreateManyUnblockedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  phoneNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberCreateManyUnblockedByInputEnvelope = {
  data: Array<ClinicBlockedPhoneNumberCreateManyUnblockedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput = {
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutBlockedByInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutBlockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyBlockedByInputEnvelope>;
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
};

export type ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
};

export type ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput = {
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutUnblockedByInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutUnblockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyUnblockedByInputEnvelope>;
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
};

export type ClinicBlockedPhoneNumberCreateOrConnectWithoutBlockedByInput = {
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
  create: ClinicBlockedPhoneNumberUncheckedCreateWithoutBlockedByInput;
};

export type ClinicBlockedPhoneNumberCreateOrConnectWithoutClinicInput = {
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
  create: ClinicBlockedPhoneNumberUncheckedCreateWithoutClinicInput;
};

export type ClinicBlockedPhoneNumberCreateOrConnectWithoutUnblockedByInput = {
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
  create: ClinicBlockedPhoneNumberUncheckedCreateWithoutUnblockedByInput;
};

export type ClinicBlockedPhoneNumberCreateWithoutBlockedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutBlockedPhoneNumbersInput;
  unblockedBy?: Maybe<UserCreateNestedOneWithoutUnblockedNumbersInput>;
};

export type ClinicBlockedPhoneNumberCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  blockedBy?: Maybe<UserCreateNestedOneWithoutBlockedNumbersInput>;
  unblockedBy?: Maybe<UserCreateNestedOneWithoutUnblockedNumbersInput>;
};

export type ClinicBlockedPhoneNumberCreateWithoutUnblockedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutBlockedPhoneNumbersInput;
  blockedBy?: Maybe<UserCreateNestedOneWithoutBlockedNumbersInput>;
};

export type ClinicBlockedPhoneNumberListRelationFilter = {
  every?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
  some?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
  none?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
};

export type ClinicBlockedPhoneNumberMaxAggregateOutputType = {
  __typename?: 'ClinicBlockedPhoneNumberMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  blockedByUserId?: Maybe<SortOrder>;
  unblockedByUserId?: Maybe<SortOrder>;
};

export type ClinicBlockedPhoneNumberMinAggregateOutputType = {
  __typename?: 'ClinicBlockedPhoneNumberMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  blockedByUserId?: Maybe<SortOrder>;
  unblockedByUserId?: Maybe<SortOrder>;
};

export type ClinicBlockedPhoneNumberOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicBlockedPhoneNumberOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  PhoneNumber = 'phoneNumber',
  Notes = 'notes',
  BlockedByUserId = 'blockedByUserId',
  UnblockedByUserId = 'unblockedByUserId'
}

export type ClinicBlockedPhoneNumberOrderByRelevanceInput = {
  fields: Array<ClinicBlockedPhoneNumberOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicBlockedPhoneNumberOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  blockedByUserId?: Maybe<SortOrder>;
  unblockedByUserId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicBlockedPhoneNumberCountOrderByAggregateInput>;
  _max?: Maybe<ClinicBlockedPhoneNumberMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicBlockedPhoneNumberMinOrderByAggregateInput>;
};

export type ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  blockedByUserId?: Maybe<SortOrder>;
  unblockedByUserId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  blockedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  unblockedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicBlockedPhoneNumberOrderByRelevanceInput>;
};

export enum ClinicBlockedPhoneNumberScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  PhoneNumber = 'phoneNumber',
  Notes = 'notes',
  BlockedByUserId = 'blockedByUserId',
  UnblockedByUserId = 'unblockedByUserId'
}

export type ClinicBlockedPhoneNumberScalarWhereInput = {
  AND?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
  OR?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
  notes?: Maybe<StringNullableFilter>;
  blockedByUserId?: Maybe<StringNullableFilter>;
  unblockedByUserId?: Maybe<StringNullableFilter>;
};

export type ClinicBlockedPhoneNumberScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  phoneNumber?: Maybe<StringWithAggregatesFilter>;
  notes?: Maybe<StringNullableWithAggregatesFilter>;
  blockedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  unblockedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicBlockedPhoneNumberUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  phoneNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput = {
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutBlockedByInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutBlockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyBlockedByInputEnvelope>;
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
};

export type ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
};

export type ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput = {
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutUnblockedByInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutUnblockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyUnblockedByInputEnvelope>;
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
};

export type ClinicBlockedPhoneNumberUncheckedCreateWithoutBlockedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  phoneNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberUncheckedCreateWithoutUnblockedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  phoneNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput = {
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutBlockedByInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutBlockedByInput>>;
  upsert?: Maybe<Array<ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutBlockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyBlockedByInputEnvelope>;
  set?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutBlockedByInput>>;
  updateMany?: Maybe<Array<ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutBlockedByInput>>;
  deleteMany?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput = {
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutUnblockedByInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutUnblockedByInput>>;
  upsert?: Maybe<Array<ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutUnblockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyUnblockedByInputEnvelope>;
  set?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutUnblockedByInput>>;
  updateMany?: Maybe<Array<ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutUnblockedByInput>>;
  deleteMany?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateWithoutBlockedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateWithoutUnblockedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  blockedByUserId?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutBlockedPhoneNumbersNestedInput>;
  blockedBy?: Maybe<UserUpdateOneWithoutBlockedNumbersNestedInput>;
  unblockedBy?: Maybe<UserUpdateOneWithoutUnblockedNumbersNestedInput>;
};

export type ClinicBlockedPhoneNumberUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutBlockedByInput = {
  where: ClinicBlockedPhoneNumberScalarWhereInput;
  data: ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedNumbersInput;
};

export type ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicBlockedPhoneNumberScalarWhereInput;
  data: ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedPhoneNumbersInput;
};

export type ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutUnblockedByInput = {
  where: ClinicBlockedPhoneNumberScalarWhereInput;
  data: ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedNumbersInput;
};

export type ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput = {
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutBlockedByInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutBlockedByInput>>;
  upsert?: Maybe<Array<ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutBlockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyBlockedByInputEnvelope>;
  set?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutBlockedByInput>>;
  updateMany?: Maybe<Array<ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutBlockedByInput>>;
  deleteMany?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
};

export type ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
};

export type ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput = {
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutUnblockedByInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutUnblockedByInput>>;
  upsert?: Maybe<Array<ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutUnblockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyUnblockedByInputEnvelope>;
  set?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutUnblockedByInput>>;
  updateMany?: Maybe<Array<ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutUnblockedByInput>>;
  deleteMany?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
};

export type ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutBlockedByInput = {
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
  data: ClinicBlockedPhoneNumberUncheckedUpdateWithoutBlockedByInput;
};

export type ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
  data: ClinicBlockedPhoneNumberUncheckedUpdateWithoutClinicInput;
};

export type ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutUnblockedByInput = {
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
  data: ClinicBlockedPhoneNumberUncheckedUpdateWithoutUnblockedByInput;
};

export type ClinicBlockedPhoneNumberUpdateWithoutBlockedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutBlockedPhoneNumbersNestedInput>;
  unblockedBy?: Maybe<UserUpdateOneWithoutUnblockedNumbersNestedInput>;
};

export type ClinicBlockedPhoneNumberUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  blockedBy?: Maybe<UserUpdateOneWithoutBlockedNumbersNestedInput>;
  unblockedBy?: Maybe<UserUpdateOneWithoutUnblockedNumbersNestedInput>;
};

export type ClinicBlockedPhoneNumberUpdateWithoutUnblockedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutBlockedPhoneNumbersNestedInput>;
  blockedBy?: Maybe<UserUpdateOneWithoutBlockedNumbersNestedInput>;
};

export type ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutBlockedByInput = {
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
  update: ClinicBlockedPhoneNumberUncheckedUpdateWithoutBlockedByInput;
  create: ClinicBlockedPhoneNumberUncheckedCreateWithoutBlockedByInput;
};

export type ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
  update: ClinicBlockedPhoneNumberUncheckedUpdateWithoutClinicInput;
  create: ClinicBlockedPhoneNumberUncheckedCreateWithoutClinicInput;
};

export type ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutUnblockedByInput = {
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
  update: ClinicBlockedPhoneNumberUncheckedUpdateWithoutUnblockedByInput;
  create: ClinicBlockedPhoneNumberUncheckedCreateWithoutUnblockedByInput;
};

export type ClinicBlockedPhoneNumberWhereInput = {
  AND?: Maybe<Array<ClinicBlockedPhoneNumberWhereInput>>;
  OR?: Maybe<Array<ClinicBlockedPhoneNumberWhereInput>>;
  NOT?: Maybe<Array<ClinicBlockedPhoneNumberWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
  notes?: Maybe<StringNullableFilter>;
  blockedByUserId?: Maybe<StringNullableFilter>;
  unblockedByUserId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  blockedBy?: Maybe<UserWhereInput>;
  unblockedBy?: Maybe<UserWhereInput>;
};

export type ClinicBlockedPhoneNumberWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicIdAndPhoneNumber?: Maybe<ClinicBlockedPhoneNumberClinicIdAndPhoneNumberCompoundUniqueInput>;
};

export type ClinicCancellationInput = {
  cancellationReason: Scalars['String'];
  cancellationNote: Scalars['String'];
};

export type ClinicContactInfo = {
  __typename?: 'ClinicContactInfo';
  name?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  emergencyClinics?: Maybe<Array<Maybe<EmergencyClinicContactInfo>>>;
};

export type ClinicCountAggregateOutputType = {
  __typename?: 'ClinicCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  name: Scalars['Int'];
  city: Scalars['Int'];
  state: Scalars['Int'];
  country: Scalars['Int'];
  addressLine1: Scalars['Int'];
  addressLine2: Scalars['Int'];
  postalCode: Scalars['Int'];
  websiteUrl: Scalars['Int'];
  hours: Scalars['Int'];
  logo: Scalars['Int'];
  optimizedLogoUrl: Scalars['Int'];
  lastPrintOrderedOn: Scalars['Int'];
  lastPrintOrderedQuantity: Scalars['Int'];
  subscribedAt: Scalars['Int'];
  consultationCost: Scalars['Int'];
  followUpCost: Scalars['Int'];
  incomeMethod: Scalars['Int'];
  paymentName: Scalars['Int'];
  paymentAddress: Scalars['Int'];
  paymentCity: Scalars['Int'];
  paymentState: Scalars['Int'];
  paymentZip: Scalars['Int'];
  paymentBankRoutingNumber: Scalars['Int'];
  paymentBankAccountNumber: Scalars['Int'];
  firebaseId: Scalars['Int'];
  timezone: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  lat: Scalars['Int'];
  long: Scalars['Int'];
  fullAddress: Scalars['Int'];
  stripeSubscriptionId: Scalars['Int'];
  stripeSubscriptionPlan: Scalars['Int'];
  token: Scalars['Int'];
  phone: Scalars['Int'];
  widgetSettings: Scalars['Int'];
  responseTime: Scalars['Int'];
  organizationId: Scalars['Int'];
  ppcLocationId: Scalars['Int'];
  integrationType: Scalars['Int'];
  integrationInfo: Scalars['Int'];
  corporateId: Scalars['Int'];
  customerId: Scalars['Int'];
  subscriptionId: Scalars['Int'];
  subscriptionPlan: Scalars['Int'];
  isIntegrationActive: Scalars['Int'];
  isClinicActive: Scalars['Int'];
  unsubscribedAt: Scalars['Int'];
  isPaymentUsBank: Scalars['Int'];
  isConsultationsActive: Scalars['Int'];
  cancellationReason: Scalars['Int'];
  cancellationNote: Scalars['Int'];
  canceledAt: Scalars['Int'];
  allowAllUsersToCloseConsultations: Scalars['Int'];
  clinicEmail: Scalars['Int'];
  firebaseVets: Scalars['Int'];
  integrationSystem: Scalars['Int'];
  marketEmail: Scalars['Int'];
  marketFacebookNumber: Scalars['Int'];
  marketFacebookPageId: Scalars['Int'];
  marketFacebookPageToken: Scalars['Int'];
  printLastOrder: Scalars['Int'];
  printReceivedQuantity: Scalars['Int'];
  submerchantApprovedAt: Scalars['Int'];
  submerchantId: Scalars['Int'];
  headUserId: Scalars['Int'];
  crmId: Scalars['Int'];
  submerchantStatus: Scalars['Int'];
  submerchantIsActive: Scalars['Int'];
  crmUrlParams: Scalars['Int'];
  paymentPhone: Scalars['Int'];
  automaticSubscriptionReceipts: Scalars['Int'];
  autoSubscriptionReceiptEmails: Scalars['Int'];
  submerchantStripeId: Scalars['Int'];
  submerchantStripeStatus: Scalars['Int'];
  submerchantStripeChargesEnabled: Scalars['Int'];
  submerchantStripePayoutsEnabled: Scalars['Int'];
  submerchantStripeHasCustomPayouts: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  timezoneName: Scalars['Int'];
  clinicWidgetSettingId: Scalars['Int'];
  activationDate: Scalars['Int'];
  isAddAppointmentDisabled: Scalars['Int'];
  hasServiceReminders: Scalars['Int'];
  isServiceRemindersActive: Scalars['Int'];
  remindersIntegrationRequestEmail: Scalars['Int'];
  hasSmsConversations: Scalars['Int'];
  callerIdPhoneNumber: Scalars['Int'];
  smsPhoneNumber: Scalars['Int'];
  isCallerIdVerified: Scalars['Int'];
  loyaltyProgramId: Scalars['Int'];
  legalEntityId: Scalars['Int'];
  unlayerSettingId: Scalars['Int'];
  carePlanProviderGroupId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  websiteUrl?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  optimizedLogoUrl?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  widgetSettings?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  integrationInfo?: Maybe<SortOrder>;
  corporateId?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  canceledAt?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  clinicEmail?: Maybe<SortOrder>;
  firebaseVets?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  crmUrlParams?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  autoSubscriptionReceiptEmails?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  isServiceRemindersActive?: Maybe<SortOrder>;
  remindersIntegrationRequestEmail?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  smsPhoneNumber?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
  loyaltyProgramId?: Maybe<SortOrder>;
  legalEntityId?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  carePlanProviderGroupId?: Maybe<SortOrder>;
};

export type ClinicCountOutputType = {
  __typename?: 'ClinicCountOutputType';
  appointments: Scalars['Int'];
  appointmentAvailabilities: Scalars['Int'];
  appoinmentTypes: Scalars['Int'];
  channels: Scalars['Int'];
  clinicEmployees: Scalars['Int'];
  clinicOfficeHours: Scalars['Int'];
  clinicPets: Scalars['Int'];
  clinicPetParents: Scalars['Int'];
  integrations: Scalars['Int'];
  clinicRooms: Scalars['Int'];
  clinicWidgetRequests: Scalars['Int'];
  clinicWorkflowTypes: Scalars['Int'];
  consultations: Scalars['Int'];
  consultationRequests: Scalars['Int'];
  formSubmissions: Scalars['Int'];
  formTemplates: Scalars['Int'];
  petsId: Scalars['Int'];
  promoCodes: Scalars['Int'];
  roles: Scalars['Int'];
  stripePaymentIntents: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  staffId: Scalars['Int'];
  workflowEventSettings: Scalars['Int'];
  stripePayoutAccounts: Scalars['Int'];
  pets: Scalars['Int'];
  staff: Scalars['Int'];
  users: Scalars['Int'];
  twilioVerifications: Scalars['Int'];
  stripeTerminalLocations: Scalars['Int'];
  financialTransactions: Scalars['Int'];
  featureFlags: Scalars['Int'];
  channelStatusChannelAssignees: Scalars['Int'];
  clinicEmailCampaignSettings: Scalars['Int'];
  calls: Scalars['Int'];
  benefitsProvided: Scalars['Int'];
  vetAvailabilities: Scalars['Int'];
  clinicDirectBookingSettings: Scalars['Int'];
  channelFilterSelections: Scalars['Int'];
  messageTemplates: Scalars['Int'];
  Rules: Scalars['Int'];
  postcardSettings: Scalars['Int'];
  automationRuns: Scalars['Int'];
  vaccinations: Scalars['Int'];
  massTextAlerts: Scalars['Int'];
  prescriptions: Scalars['Int'];
  clinicPhoneNumber: Scalars['Int'];
  clinicOnboardings: Scalars['Int'];
  surveyResults: Scalars['Int'];
  informPartnerProgramEnrollments: Scalars['Int'];
  loyaltyPointDeltas: Scalars['Int'];
  loyaltyRewardRedemptionActions: Scalars['Int'];
  media: Scalars['Int'];
  directBookingExclusionRules: Scalars['Int'];
  sentTrupanionExamDayOffers: Scalars['Int'];
  insuranceIntegrations: Scalars['Int'];
  payoutBatchingPeriods: Scalars['Int'];
  bitwerxHealthchecks: Scalars['Int'];
  emailCampaign: Scalars['Int'];
  emailCampaignTemplate: Scalars['Int'];
  EmailCampaignUnsubscribe: Scalars['Int'];
  EmailCampaignLog: Scalars['Int'];
  blockedPhoneNumbers: Scalars['Int'];
  HillsToHomeAPILog: Scalars['Int'];
  userBoardFiltersColumns: Scalars['Int'];
};

export type ClinicCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateManyCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
};

export type ClinicCreateManyCarePlanProviderGroupInputEnvelope = {
  data: Array<ClinicCreateManyCarePlanProviderGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicCreateManyClinicWidgetSettingInputEnvelope = {
  data: Array<ClinicCreateManyClinicWidgetSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicCreateManyLegalEntityInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicCreateManyLegalEntityInputEnvelope = {
  data: Array<ClinicCreateManyLegalEntityInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyLoyaltyProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicCreateManyLoyaltyProgramInputEnvelope = {
  data: Array<ClinicCreateManyLoyaltyProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicCreateManyOrganizationInputEnvelope = {
  data: Array<ClinicCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyUnlayerSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicCreateManyUnlayerSettingInputEnvelope = {
  data: Array<ClinicCreateManyUnlayerSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyUsersIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicCreateManyUsersIdInputEnvelope = {
  data: Array<ClinicCreateManyUsersIdInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateNestedManyWithoutCarePlanProviderGroupInput = {
  create?: Maybe<Array<ClinicCreateWithoutCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutCarePlanProviderGroupInput>>;
  createMany?: Maybe<ClinicCreateManyCarePlanProviderGroupInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutClinicWidgetSettingInput = {
  create?: Maybe<Array<ClinicCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicCreateManyClinicWidgetSettingInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutFeatureFlagsInput = {
  create?: Maybe<Array<ClinicCreateWithoutFeatureFlagsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutFeatureFlagsInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutLegalEntityInput = {
  create?: Maybe<Array<ClinicCreateWithoutLegalEntityInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLegalEntityInput>>;
  createMany?: Maybe<ClinicCreateManyLegalEntityInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutLoyaltyProgramInput = {
  create?: Maybe<Array<ClinicCreateWithoutLoyaltyProgramInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLoyaltyProgramInput>>;
  createMany?: Maybe<ClinicCreateManyLoyaltyProgramInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<ClinicCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<ClinicCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutPetsInput = {
  create?: Maybe<Array<ClinicCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutPetsInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutStaffInput = {
  create?: Maybe<Array<ClinicCreateWithoutStaffInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStaffInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutStripePayoutAccountsInput = {
  create?: Maybe<Array<ClinicCreateWithoutStripePayoutAccountsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStripePayoutAccountsInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutUnlayerSettingInput = {
  create?: Maybe<Array<ClinicCreateWithoutUnlayerSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUnlayerSettingInput>>;
  createMany?: Maybe<ClinicCreateManyUnlayerSettingInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutUsersIdInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersIdInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersIdInput>>;
  createMany?: Maybe<ClinicCreateManyUsersIdInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutUsersInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedOneWithoutAppoinmentTypesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutAppoinmentTypesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppoinmentTypesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutAppointmentAvailabilitiesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutAppointmentAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppointmentAvailabilitiesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutAutomationRunsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAutomationRunsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutBenefitsProvidedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutBenefitsProvidedInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutBenefitsProvidedInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutBitwerxHealthchecksInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutBitwerxHealthchecksInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutBitwerxHealthchecksInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutBlockedPhoneNumbersInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutBlockedPhoneNumbersInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutBlockedPhoneNumbersInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutCallsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutCallsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutCallsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutCarePayoutOrganizationInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutCarePayoutOrganizationInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutCarePayoutOrganizationInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutChannelFilterSelectionsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutChannelFilterSelectionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelFilterSelectionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutChannelStatusChannelAssigneesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutChannelsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutChannelsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicDirectBookingSettingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicDirectBookingSettingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicEmailCampaignSettingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicEmailCampaignSettingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicEmployeesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicEmployeesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicEmployeesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicOfficeHoursInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicOfficeHoursInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicOfficeHoursInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicOnboardingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicOnboardingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicOnboardingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicPetParentsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPetParentsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicPetsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPetsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPetsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicPhoneNumberInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPhoneNumberInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPhoneNumberInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicRoomsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicRoomsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicRoomsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicSettingInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicSettingInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicWidgetRequestsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicWidgetRequestsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicWorkflowTypesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicWorkflowTypesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicWorkflowTypesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutConsultationRequestsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutConsultationRequestsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutConsultationRequestsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutConsultationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutConsultationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutDirectBookingExclusionRulesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutDirectBookingExclusionRulesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutDirectBookingExclusionRulesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutEmailCampaignInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutEmailCampaignLogInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignLogInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignLogInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutEmailCampaignTemplateInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignTemplateInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignTemplateInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutEmailCampaignUnsubscribeInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignUnsubscribeInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignUnsubscribeInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutFinancialTransactionsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutFinancialTransactionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFinancialTransactionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutFormSubmissionsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFormSubmissionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutFormTemplatesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutFormTemplatesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFormTemplatesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutHillsToHomeApiLogInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutHillsToHomeApiLogInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutHillsToHomeApiLogInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutInformPartnerCustomerInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutInformPartnerCustomerInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutInformPartnerCustomerInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutInformPartnerProgramEnrollmentsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutInsuranceIntegrationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutInsuranceIntegrationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutInsuranceIntegrationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutIntegrationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutIntegrationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutIntegrationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutLoyaltyPointDeltasInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutLoyaltyPointDeltasInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutLoyaltyPointDeltasInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutLoyaltyRewardRedemptionActionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutMassTextAlertsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutMassTextAlertsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMassTextAlertsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutMediaInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutMediaInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMediaInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutMessageTemplatesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutMessageTemplatesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMessageTemplatesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPaymentProcessorForProviderGroupInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPaymentProcessorForProviderGroupInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutPayoutBatchingPeriodsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPayoutBatchingPeriodsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPayoutBatchingPeriodsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutPetsIdInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPetsIdInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPetsIdInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutPostcardSettingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPostcardSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPostcardSettingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutPrescriptionsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPrescriptionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutPromoCodesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPromoCodesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPromoCodesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutRolesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutRolesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutRolesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutRulesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutRulesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutRulesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutSentTrupanionExamDayOffersInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutSentTrupanionExamDayOffersInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutSentTrupanionExamDayOffersInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutStaffIdInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutStaffIdInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStaffIdInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutStripePaymentIntentsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStripePaymentIntentsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutStripeTerminalLocationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutStripeTerminalLocationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStripeTerminalLocationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutSurveyResultsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutSurveyResultsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutSurveyResultsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutTwilioConfigurationInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutTwilioConfigurationInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutTwilioConfigurationInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutTwilioVerificationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutTwilioVerificationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutTwilioVerificationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutUserAppointmentGroupingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutUserBoardFiltersColumnsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutUserBoardFiltersColumnsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutUserBoardFiltersColumnsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutVaccinationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutVaccinationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVaccinationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutVetAvailabilitiesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutVetAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVetAvailabilitiesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutVetsourceInstallationInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutVetsourceInstallationInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVetsourceInstallationInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutWorkflowEventSettingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutWorkflowEventSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutWorkflowEventSettingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateOrConnectWithoutAppoinmentTypesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutAppoinmentTypesInput;
};

export type ClinicCreateOrConnectWithoutAppointmentAvailabilitiesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutAppointmentAvailabilitiesInput;
};

export type ClinicCreateOrConnectWithoutAppointmentsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicCreateOrConnectWithoutAutomationRunsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutAutomationRunsInput;
};

export type ClinicCreateOrConnectWithoutBenefitsProvidedInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutBenefitsProvidedInput;
};

export type ClinicCreateOrConnectWithoutBitwerxHealthchecksInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutBitwerxHealthchecksInput;
};

export type ClinicCreateOrConnectWithoutBlockedPhoneNumbersInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutBlockedPhoneNumbersInput;
};

export type ClinicCreateOrConnectWithoutCallsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutCallsInput;
};

export type ClinicCreateOrConnectWithoutCarePayoutOrganizationInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutCarePayoutOrganizationInput;
};

export type ClinicCreateOrConnectWithoutCarePlanProviderGroupInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutCarePlanProviderGroupInput;
};

export type ClinicCreateOrConnectWithoutChannelFilterSelectionsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutChannelFilterSelectionsInput;
};

export type ClinicCreateOrConnectWithoutChannelStatusChannelAssigneesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
};

export type ClinicCreateOrConnectWithoutChannelsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutChannelsInput;
};

export type ClinicCreateOrConnectWithoutClinicDirectBookingSettingsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput;
};

export type ClinicCreateOrConnectWithoutClinicEmailCampaignSettingsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput;
};

export type ClinicCreateOrConnectWithoutClinicEmployeesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicEmployeesInput;
};

export type ClinicCreateOrConnectWithoutClinicOfficeHoursInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicOfficeHoursInput;
};

export type ClinicCreateOrConnectWithoutClinicOnboardingsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicOnboardingsInput;
};

export type ClinicCreateOrConnectWithoutClinicPetParentsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicPetParentsInput;
};

export type ClinicCreateOrConnectWithoutClinicPetsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicPetsInput;
};

export type ClinicCreateOrConnectWithoutClinicPhoneNumberInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicPhoneNumberInput;
};

export type ClinicCreateOrConnectWithoutClinicRoomsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicRoomsInput;
};

export type ClinicCreateOrConnectWithoutClinicSettingInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicSettingInput;
};

export type ClinicCreateOrConnectWithoutClinicWidgetRequestsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type ClinicCreateOrConnectWithoutClinicWidgetSettingInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicWidgetSettingInput;
};

export type ClinicCreateOrConnectWithoutClinicWorkflowTypesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicWorkflowTypesInput;
};

export type ClinicCreateOrConnectWithoutConsultationRequestsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutConsultationRequestsInput;
};

export type ClinicCreateOrConnectWithoutConsultationsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutConsultationsInput;
};

export type ClinicCreateOrConnectWithoutDirectBookingExclusionRulesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutDirectBookingExclusionRulesInput;
};

export type ClinicCreateOrConnectWithoutEmailCampaignInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutEmailCampaignInput;
};

export type ClinicCreateOrConnectWithoutEmailCampaignLogInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutEmailCampaignLogInput;
};

export type ClinicCreateOrConnectWithoutEmailCampaignTemplateInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutEmailCampaignTemplateInput;
};

export type ClinicCreateOrConnectWithoutEmailCampaignUnsubscribeInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutEmailCampaignUnsubscribeInput;
};

export type ClinicCreateOrConnectWithoutFeatureFlagsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutFeatureFlagsInput;
};

export type ClinicCreateOrConnectWithoutFinancialTransactionsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutFinancialTransactionsInput;
};

export type ClinicCreateOrConnectWithoutFormSubmissionsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutFormSubmissionsInput;
};

export type ClinicCreateOrConnectWithoutFormTemplatesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutFormTemplatesInput;
};

export type ClinicCreateOrConnectWithoutHillsToHomeApiLogInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutHillsToHomeApiLogInput;
};

export type ClinicCreateOrConnectWithoutInformPartnerCustomerInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutInformPartnerCustomerInput;
};

export type ClinicCreateOrConnectWithoutInformPartnerProgramEnrollmentsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput;
};

export type ClinicCreateOrConnectWithoutInsuranceIntegrationsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutInsuranceIntegrationsInput;
};

export type ClinicCreateOrConnectWithoutIntegrationsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutIntegrationsInput;
};

export type ClinicCreateOrConnectWithoutLegalEntityInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutLegalEntityInput;
};

export type ClinicCreateOrConnectWithoutLoyaltyPointDeltasInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutLoyaltyPointDeltasInput;
};

export type ClinicCreateOrConnectWithoutLoyaltyProgramInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutLoyaltyProgramInput;
};

export type ClinicCreateOrConnectWithoutLoyaltyRewardRedemptionActionsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput;
};

export type ClinicCreateOrConnectWithoutMassTextAlertsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutMassTextAlertsInput;
};

export type ClinicCreateOrConnectWithoutMediaInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutMediaInput;
};

export type ClinicCreateOrConnectWithoutMessageTemplatesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutMessageTemplatesInput;
};

export type ClinicCreateOrConnectWithoutOrganizationInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutOrganizationInput;
};

export type ClinicCreateOrConnectWithoutPaymentProcessorForProviderGroupInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutPaymentProcessorForProviderGroupInput;
};

export type ClinicCreateOrConnectWithoutPayoutBatchingPeriodsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutPayoutBatchingPeriodsInput;
};

export type ClinicCreateOrConnectWithoutPetsIdInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutPetsIdInput;
};

export type ClinicCreateOrConnectWithoutPetsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutPetsInput;
};

export type ClinicCreateOrConnectWithoutPostcardSettingsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutPostcardSettingsInput;
};

export type ClinicCreateOrConnectWithoutPrescriptionsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicCreateOrConnectWithoutPromoCodesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutPromoCodesInput;
};

export type ClinicCreateOrConnectWithoutRolesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutRolesInput;
};

export type ClinicCreateOrConnectWithoutRulesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutRulesInput;
};

export type ClinicCreateOrConnectWithoutSentTrupanionExamDayOffersInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutSentTrupanionExamDayOffersInput;
};

export type ClinicCreateOrConnectWithoutStaffIdInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutStaffIdInput;
};

export type ClinicCreateOrConnectWithoutStaffInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutStaffInput;
};

export type ClinicCreateOrConnectWithoutStripePaymentIntentsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type ClinicCreateOrConnectWithoutStripePayoutAccountsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutStripePayoutAccountsInput;
};

export type ClinicCreateOrConnectWithoutStripeTerminalLocationsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutStripeTerminalLocationsInput;
};

export type ClinicCreateOrConnectWithoutSurveyResultsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutSurveyResultsInput;
};

export type ClinicCreateOrConnectWithoutTwilioConfigurationInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutTwilioConfigurationInput;
};

export type ClinicCreateOrConnectWithoutTwilioVerificationsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutTwilioVerificationsInput;
};

export type ClinicCreateOrConnectWithoutUnlayerSettingInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutUnlayerSettingInput;
};

export type ClinicCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type ClinicCreateOrConnectWithoutUserBoardFiltersColumnsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutUserBoardFiltersColumnsInput;
};

export type ClinicCreateOrConnectWithoutUsersIdInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutUsersIdInput;
};

export type ClinicCreateOrConnectWithoutUsersInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutUsersInput;
};

export type ClinicCreateOrConnectWithoutVaccinationsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutVaccinationsInput;
};

export type ClinicCreateOrConnectWithoutVetAvailabilitiesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutVetAvailabilitiesInput;
};

export type ClinicCreateOrConnectWithoutVetsourceInstallationInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutVetsourceInstallationInput;
};

export type ClinicCreateOrConnectWithoutWorkflowEventSettingsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutWorkflowEventSettingsInput;
};

export type ClinicCreateWithoutAppoinmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutAppointmentAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutBenefitsProvidedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutBitwerxHealthchecksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutBlockedPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutCarePayoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicDirectBookingSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicEmailCampaignSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicOfficeHoursInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicOnboardingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutConsultationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutDirectBookingExclusionRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutEmailCampaignLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutEmailCampaignTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutEmailCampaignUnsubscribeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutFeatureFlagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutInformPartnerProgramEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutInsuranceIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutLegalEntityInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutLoyaltyProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutLoyaltyRewardRedemptionActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutPaymentProcessorForProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutPayoutBatchingPeriodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutPetsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutPostcardSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutPromoCodesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutSentTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutStaffIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutStaffInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutStripePayoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutStripeTerminalLocationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutTwilioConfigurationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutTwilioVerificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutUnlayerSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutUserBoardFiltersColumnsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutUsersIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutVetsourceInstallationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
};

export type ClinicCreateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
};

export type ClinicDirectBookingSetting = {
  __typename?: 'ClinicDirectBookingSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation: Scalars['Boolean'];
  isOneTimePasswordEnabled: Scalars['Boolean'];
  exclusionRulesEnabled: Scalars['Boolean'];
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  clinic: Clinic;
};

export type ClinicDirectBookingSettingAvgAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingAvgAggregateOutputType';
  maxSchedulingRangeInMonths?: Maybe<Scalars['Float']>;
  bookingDeadlineInHours?: Maybe<Scalars['Float']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Float']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Float']>;
};

export type ClinicDirectBookingSettingAvgOrderByAggregateInput = {
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  appointmentIntervalInMinutes?: Maybe<SortOrder>;
  appointmentIntervalInMinutesDisplay?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingCountAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  maxSchedulingRangeInMonths: Scalars['Int'];
  bookingDeadlineInHours: Scalars['Int'];
  bookingNeedsConfirmation: Scalars['Int'];
  isOneTimePasswordEnabled: Scalars['Int'];
  exclusionRulesEnabled: Scalars['Int'];
  appointmentIntervalInMinutes: Scalars['Int'];
  appointmentIntervalInMinutesDisplay: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicDirectBookingSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
  exclusionRulesEnabled?: Maybe<SortOrder>;
  appointmentIntervalInMinutes?: Maybe<SortOrder>;
  appointmentIntervalInMinutesDisplay?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  clinic: ClinicCreateNestedOneWithoutClinicDirectBookingSettingsInput;
};

export type ClinicDirectBookingSettingCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingCreateManyClinicInputEnvelope = {
  data: Array<ClinicDirectBookingSettingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicDirectBookingSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicDirectBookingSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
};

export type ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput = {
  where: ClinicDirectBookingSettingWhereUniqueInput;
  create: ClinicDirectBookingSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicDirectBookingSettingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingListRelationFilter = {
  every?: Maybe<ClinicDirectBookingSettingWhereInput>;
  some?: Maybe<ClinicDirectBookingSettingWhereInput>;
  none?: Maybe<ClinicDirectBookingSettingWhereInput>;
};

export type ClinicDirectBookingSettingMaxAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
  exclusionRulesEnabled?: Maybe<SortOrder>;
  appointmentIntervalInMinutes?: Maybe<SortOrder>;
  appointmentIntervalInMinutesDisplay?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingMinAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
  exclusionRulesEnabled?: Maybe<SortOrder>;
  appointmentIntervalInMinutes?: Maybe<SortOrder>;
  appointmentIntervalInMinutesDisplay?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicDirectBookingSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId'
}

export type ClinicDirectBookingSettingOrderByRelevanceInput = {
  fields: Array<ClinicDirectBookingSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicDirectBookingSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
  exclusionRulesEnabled?: Maybe<SortOrder>;
  appointmentIntervalInMinutes?: Maybe<SortOrder>;
  appointmentIntervalInMinutesDisplay?: Maybe<SortOrder>;
  _count?: Maybe<ClinicDirectBookingSettingCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicDirectBookingSettingAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicDirectBookingSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicDirectBookingSettingMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicDirectBookingSettingSumOrderByAggregateInput>;
};

export type ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
  exclusionRulesEnabled?: Maybe<SortOrder>;
  appointmentIntervalInMinutes?: Maybe<SortOrder>;
  appointmentIntervalInMinutesDisplay?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicDirectBookingSettingOrderByRelevanceInput>;
};

export enum ClinicDirectBookingSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  MaxSchedulingRangeInMonths = 'maxSchedulingRangeInMonths',
  BookingDeadlineInHours = 'bookingDeadlineInHours',
  BookingNeedsConfirmation = 'bookingNeedsConfirmation',
  IsOneTimePasswordEnabled = 'isOneTimePasswordEnabled',
  ExclusionRulesEnabled = 'exclusionRulesEnabled',
  AppointmentIntervalInMinutes = 'appointmentIntervalInMinutes',
  AppointmentIntervalInMinutesDisplay = 'appointmentIntervalInMinutesDisplay'
}

export type ClinicDirectBookingSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  maxSchedulingRangeInMonths?: Maybe<IntNullableFilter>;
  bookingDeadlineInHours?: Maybe<IntNullableFilter>;
  bookingNeedsConfirmation?: Maybe<BoolFilter>;
  isOneTimePasswordEnabled?: Maybe<BoolFilter>;
  exclusionRulesEnabled?: Maybe<BoolFilter>;
  appointmentIntervalInMinutes?: Maybe<IntNullableFilter>;
  appointmentIntervalInMinutesDisplay?: Maybe<IntNullableFilter>;
};

export type ClinicDirectBookingSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicDirectBookingSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicDirectBookingSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicDirectBookingSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  maxSchedulingRangeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  bookingDeadlineInHours?: Maybe<IntNullableWithAggregatesFilter>;
  bookingNeedsConfirmation?: Maybe<BoolWithAggregatesFilter>;
  isOneTimePasswordEnabled?: Maybe<BoolWithAggregatesFilter>;
  exclusionRulesEnabled?: Maybe<BoolWithAggregatesFilter>;
  appointmentIntervalInMinutes?: Maybe<IntNullableWithAggregatesFilter>;
  appointmentIntervalInMinutesDisplay?: Maybe<IntNullableWithAggregatesFilter>;
};

export type ClinicDirectBookingSettingSumAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingSumAggregateOutputType';
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingSumOrderByAggregateInput = {
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  appointmentIntervalInMinutes?: Maybe<SortOrder>;
  appointmentIntervalInMinutesDisplay?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicDirectBookingSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicDirectBookingSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
};

export type ClinicDirectBookingSettingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicDirectBookingSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicDirectBookingSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicDirectBookingSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicDirectBookingSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicDirectBookingSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicDirectBookingSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
};

export type ClinicDirectBookingSettingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicDirectBookingSettingsNestedInput>;
};

export type ClinicDirectBookingSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicDirectBookingSettingScalarWhereInput;
  data: ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicDirectBookingSettingsInput;
};

export type ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicDirectBookingSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicDirectBookingSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicDirectBookingSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicDirectBookingSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicDirectBookingSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
};

export type ClinicDirectBookingSettingUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicDirectBookingSettingWhereUniqueInput;
  data: ClinicDirectBookingSettingUncheckedUpdateWithoutClinicInput;
};

export type ClinicDirectBookingSettingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicDirectBookingSettingWhereUniqueInput;
  update: ClinicDirectBookingSettingUncheckedUpdateWithoutClinicInput;
  create: ClinicDirectBookingSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicDirectBookingSettingWhereInput = {
  AND?: Maybe<Array<ClinicDirectBookingSettingWhereInput>>;
  OR?: Maybe<Array<ClinicDirectBookingSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicDirectBookingSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  maxSchedulingRangeInMonths?: Maybe<IntNullableFilter>;
  bookingDeadlineInHours?: Maybe<IntNullableFilter>;
  bookingNeedsConfirmation?: Maybe<BoolFilter>;
  isOneTimePasswordEnabled?: Maybe<BoolFilter>;
  exclusionRulesEnabled?: Maybe<BoolFilter>;
  appointmentIntervalInMinutes?: Maybe<IntNullableFilter>;
  appointmentIntervalInMinutesDisplay?: Maybe<IntNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type ClinicDirectBookingSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicDirectBookingSettingsInput = {
  clinicId: Scalars['String'];
};

export type ClinicDirectBookingSettingsResponse = {
  __typename?: 'ClinicDirectBookingSettingsResponse';
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
};

export type ClinicEmailCampaignSetting = {
  __typename?: 'ClinicEmailCampaignSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  accountId: Scalars['String'];
  userGroupId: Scalars['String'];
  contactListId: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinic: Clinic;
};

export type ClinicEmailCampaignSettingCountAggregateOutputType = {
  __typename?: 'ClinicEmailCampaignSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  accountId: Scalars['Int'];
  userGroupId: Scalars['Int'];
  contactListId: Scalars['Int'];
  petSpeciesTypeFieldId: Scalars['Int'];
  careUrlFieldId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicEmailCampaignSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
};

export type ClinicEmailCampaignSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId: Scalars['String'];
  userGroupId: Scalars['String'];
  contactListId: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutClinicEmailCampaignSettingsInput;
};

export type ClinicEmailCampaignSettingCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId: Scalars['String'];
  userGroupId: Scalars['String'];
  contactListId: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingCreateManyClinicInputEnvelope = {
  data: Array<ClinicEmailCampaignSettingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmailCampaignSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  accountId: Scalars['String'];
  userGroupId: Scalars['String'];
  contactListId: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicEmailCampaignSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmailCampaignSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
};

export type ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput = {
  where: ClinicEmailCampaignSettingWhereUniqueInput;
  create: ClinicEmailCampaignSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicEmailCampaignSettingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId: Scalars['String'];
  userGroupId: Scalars['String'];
  contactListId: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingListRelationFilter = {
  every?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  some?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  none?: Maybe<ClinicEmailCampaignSettingWhereInput>;
};

export type ClinicEmailCampaignSettingMaxAggregateOutputType = {
  __typename?: 'ClinicEmailCampaignSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
};

export type ClinicEmailCampaignSettingMinAggregateOutputType = {
  __typename?: 'ClinicEmailCampaignSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
};

export type ClinicEmailCampaignSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEmailCampaignSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  AccountId = 'accountId',
  UserGroupId = 'userGroupId',
  ContactListId = 'contactListId',
  PetSpeciesTypeFieldId = 'petSpeciesTypeFieldId',
  CareUrlFieldId = 'careUrlFieldId'
}

export type ClinicEmailCampaignSettingOrderByRelevanceInput = {
  fields: Array<ClinicEmailCampaignSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicEmailCampaignSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicEmailCampaignSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEmailCampaignSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEmailCampaignSettingMinOrderByAggregateInput>;
};

export type ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicEmailCampaignSettingOrderByRelevanceInput>;
};

export enum ClinicEmailCampaignSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  AccountId = 'accountId',
  UserGroupId = 'userGroupId',
  ContactListId = 'contactListId',
  PetSpeciesTypeFieldId = 'petSpeciesTypeFieldId',
  CareUrlFieldId = 'careUrlFieldId'
}

export type ClinicEmailCampaignSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  accountId?: Maybe<StringFilter>;
  userGroupId?: Maybe<StringFilter>;
  contactListId?: Maybe<StringFilter>;
  petSpeciesTypeFieldId?: Maybe<StringFilter>;
  careUrlFieldId?: Maybe<StringNullableFilter>;
};

export type ClinicEmailCampaignSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  accountId?: Maybe<StringWithAggregatesFilter>;
  userGroupId?: Maybe<StringWithAggregatesFilter>;
  contactListId?: Maybe<StringWithAggregatesFilter>;
  petSpeciesTypeFieldId?: Maybe<StringWithAggregatesFilter>;
  careUrlFieldId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicEmailCampaignSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  accountId: Scalars['String'];
  userGroupId: Scalars['String'];
  contactListId: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicEmailCampaignSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmailCampaignSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
};

export type ClinicEmailCampaignSettingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId: Scalars['String'];
  userGroupId: Scalars['String'];
  contactListId: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicEmailCampaignSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicEmailCampaignSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicEmailCampaignSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmailCampaignSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmailCampaignSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicEmailCampaignSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
};

export type ClinicEmailCampaignSettingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicEmailCampaignSettingsNestedInput>;
};

export type ClinicEmailCampaignSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicEmailCampaignSettingScalarWhereInput;
  data: ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicEmailCampaignSettingsInput;
};

export type ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicEmailCampaignSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicEmailCampaignSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmailCampaignSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmailCampaignSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicEmailCampaignSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
};

export type ClinicEmailCampaignSettingUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicEmailCampaignSettingWhereUniqueInput;
  data: ClinicEmailCampaignSettingUncheckedUpdateWithoutClinicInput;
};

export type ClinicEmailCampaignSettingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicEmailCampaignSettingWhereUniqueInput;
  update: ClinicEmailCampaignSettingUncheckedUpdateWithoutClinicInput;
  create: ClinicEmailCampaignSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicEmailCampaignSettingWhereInput = {
  AND?: Maybe<Array<ClinicEmailCampaignSettingWhereInput>>;
  OR?: Maybe<Array<ClinicEmailCampaignSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicEmailCampaignSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  accountId?: Maybe<StringFilter>;
  userGroupId?: Maybe<StringFilter>;
  contactListId?: Maybe<StringFilter>;
  petSpeciesTypeFieldId?: Maybe<StringFilter>;
  careUrlFieldId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type ClinicEmailCampaignSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicEmployee = {
  __typename?: 'ClinicEmployee';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<Clinic>;
  integration?: Maybe<ClinicPimsIntegration>;
  appointments: Array<Appointment>;
  appointmentAvailabilities: Array<AppointmentAvailability>;
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  workflows: Array<ClinicEmployeeToWorkflowEventSetting>;
  providerInvoiceLineItems: Array<InvoiceLineItem>;
  enteredByInvoiceLineItems: Array<InvoiceLineItem>;
  vetAvailabilityMaps: Array<VetAvailabilityMap>;
  clinicEmployeeAppointmentTypeSettings: Array<ClinicEmployeeAppointmentTypeSetting>;
  prescriptions: Array<Prescription>;
  bitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  directBookingExclusionRules: Array<DirectBookingExclusionRule>;
  ProviderAppointmentTypeRoomMap: Array<ProviderAppointmentTypeRoomMap>;
  _count: ClinicEmployeeCountOutputType;
};


export type ClinicEmployeeAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};


export type ClinicEmployeeAppointmentAvailabilitiesArgs = {
  where?: Maybe<AppointmentAvailabilityWhereInput>;
  orderBy?: Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentAvailabilityScalarFieldEnum>;
};


export type ClinicEmployeeUserAppointmentGroupingsArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
};


export type ClinicEmployeeWorkflowsArgs = {
  where?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
  orderBy?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeToWorkflowEventSettingScalarFieldEnum>;
};


export type ClinicEmployeeProviderInvoiceLineItemsArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
};


export type ClinicEmployeeEnteredByInvoiceLineItemsArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
};


export type ClinicEmployeeVetAvailabilityMapsArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
  orderBy?: Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetAvailabilityMapScalarFieldEnum>;
};


export type ClinicEmployeeClinicEmployeeAppointmentTypeSettingsArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>;
};


export type ClinicEmployeePrescriptionsArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
};


export type ClinicEmployeeBitwerxPaymentWritebackConfigurationsArgs = {
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
};


export type ClinicEmployeeDirectBookingExclusionRulesArgs = {
  where?: Maybe<DirectBookingExclusionRuleWhereInput>;
  orderBy?: Maybe<DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<DirectBookingExclusionRuleScalarFieldEnum>;
};


export type ClinicEmployeeProviderAppointmentTypeRoomMapArgs = {
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
  orderBy?: Maybe<ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ProviderAppointmentTypeRoomMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ProviderAppointmentTypeRoomMapScalarFieldEnum>;
};

export type ClinicEmployeeAppointmentTypeSetting = {
  __typename?: 'ClinicEmployeeAppointmentTypeSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicEmployeeId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  clinicEmployee: ClinicEmployee;
  appointmentType: AppointmentType;
};

export type ClinicEmployeeAppointmentTypeSettingCountAggregateOutputType = {
  __typename?: 'ClinicEmployeeAppointmentTypeSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicEmployeeId: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicEmployeeAppointmentTypeSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput;
  appointmentType: AppointmentTypeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope = {
  data: Array<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope = {
  data: Array<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
};

export type ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
  create: ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput;
};

export type ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
  create: ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutClinicEmployeeInput;
};

export type ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentType: AppointmentTypeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeAppointmentTypeSettingListRelationFilter = {
  every?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  some?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  none?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};

export type ClinicEmployeeAppointmentTypeSettingMaxAggregateOutputType = {
  __typename?: 'ClinicEmployeeAppointmentTypeSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeAppointmentTypeSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
};

export type ClinicEmployeeAppointmentTypeSettingMinAggregateOutputType = {
  __typename?: 'ClinicEmployeeAppointmentTypeSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeAppointmentTypeSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
};

export type ClinicEmployeeAppointmentTypeSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEmployeeAppointmentTypeSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicEmployeeId = 'clinicEmployeeId',
  AppointmentTypeId = 'appointmentTypeId'
}

export type ClinicEmployeeAppointmentTypeSettingOrderByRelevanceInput = {
  fields: Array<ClinicEmployeeAppointmentTypeSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicEmployeeAppointmentTypeSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicEmployeeAppointmentTypeSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEmployeeAppointmentTypeSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEmployeeAppointmentTypeSettingMinOrderByAggregateInput>;
};

export type ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clinicEmployee?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByRelevanceInput>;
};

export enum ClinicEmployeeAppointmentTypeSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicEmployeeId = 'clinicEmployeeId',
  AppointmentTypeId = 'appointmentTypeId'
}

export type ClinicEmployeeAppointmentTypeSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
  appointmentTypeId?: Maybe<StringFilter>;
};

export type ClinicEmployeeAppointmentTypeSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicEmployeeId?: Maybe<StringWithAggregatesFilter>;
  appointmentTypeId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsNestedInput>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsNestedInput>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput = {
  where: ClinicEmployeeAppointmentTypeSettingScalarWhereInput;
  data: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutClinicEmployeeInput = {
  where: ClinicEmployeeAppointmentTypeSettingScalarWhereInput;
  data: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
  data: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
  data: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutClinicEmployeeInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsNestedInput>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsNestedInput>;
};

export type ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
  update: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput;
  create: ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
  update: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutClinicEmployeeInput;
  create: ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutClinicEmployeeInput;
};

export type ClinicEmployeeAppointmentTypeSettingWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
  appointmentTypeId?: Maybe<StringFilter>;
  clinicEmployee?: Maybe<ClinicEmployeeWhereInput>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
};

export type ClinicEmployeeAppointmentTypeSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeClinicEmployeePimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type ClinicEmployeeCountAggregateOutputType = {
  __typename?: 'ClinicEmployeeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  type: Scalars['Int'];
  pimsId: Scalars['Int'];
  firstName: Scalars['Int'];
  lastName: Scalars['Int'];
  integrationId: Scalars['Int'];
  clinicId: Scalars['Int'];
  isSchedulable: Scalars['Int'];
  isDefaultExporter: Scalars['Int'];
  isVisible: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicEmployeeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isDefaultExporter?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ClinicEmployeeCountOutputType = {
  __typename?: 'ClinicEmployeeCountOutputType';
  appointments: Scalars['Int'];
  appointmentAvailabilities: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  workflows: Scalars['Int'];
  providerInvoiceLineItems: Scalars['Int'];
  enteredByInvoiceLineItems: Scalars['Int'];
  vetAvailabilityMaps: Scalars['Int'];
  clinicEmployeeAppointmentTypeSettings: Scalars['Int'];
  prescriptions: Scalars['Int'];
  bitwerxPaymentWritebackConfigurations: Scalars['Int'];
  directBookingExclusionRules: Scalars['Int'];
  ProviderAppointmentTypeRoomMap: Scalars['Int'];
};

export type ClinicEmployeeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeCreateManyClinicInputEnvelope = {
  data: Array<ClinicEmployeeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicEmployeeCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeCreateNestedManyWithoutAppointmentsInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutAppointmentsInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeCreateNestedManyWithoutDirectBookingExclusionRulesInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutDirectBookingExclusionRulesInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeCreateNestedOneWithoutAppointmentAvailabilitiesInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutAppointmentAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutAppointmentAvailabilitiesInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutBitwerxPaymentWritebackConfigurationsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutEnteredByInvoiceLineItemsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutPrescriptionsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutProviderInvoiceLineItemsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutVetAvailabilityMapsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutVetAvailabilityMapsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutWorkflowsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutWorkflowsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutWorkflowsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateOrConnectWithoutAppointmentAvailabilitiesInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutAppointmentAvailabilitiesInput;
};

export type ClinicEmployeeCreateOrConnectWithoutAppointmentsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutBitwerxPaymentWritebackConfigurationsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutClinicInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutClinicInput;
};

export type ClinicEmployeeCreateOrConnectWithoutDirectBookingExclusionRulesInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutDirectBookingExclusionRulesInput;
};

export type ClinicEmployeeCreateOrConnectWithoutEnteredByInvoiceLineItemsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutIntegrationInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutIntegrationInput;
};

export type ClinicEmployeeCreateOrConnectWithoutPrescriptionsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput;
};

export type ClinicEmployeeCreateOrConnectWithoutProviderInvoiceLineItemsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutVetAvailabilityMapsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutWorkflowsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutWorkflowsInput;
};

export type ClinicEmployeeCreateWithoutAppointmentAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutDirectBookingExclusionRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutEnteredByInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutProviderAppointmentTypeRoomMapInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
};

export type ClinicEmployeeCreateWithoutProviderInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeListRelationFilter = {
  every?: Maybe<ClinicEmployeeWhereInput>;
  some?: Maybe<ClinicEmployeeWhereInput>;
  none?: Maybe<ClinicEmployeeWhereInput>;
};

export type ClinicEmployeeMaxAggregateOutputType = {
  __typename?: 'ClinicEmployeeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isDefaultExporter?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
};

export type ClinicEmployeeMinAggregateOutputType = {
  __typename?: 'ClinicEmployeeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isDefaultExporter?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
};

export type ClinicEmployeeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEmployeeOrderByRelevanceFieldEnum {
  Id = 'id',
  Type = 'type',
  PimsId = 'pimsId',
  FirstName = 'firstName',
  LastName = 'lastName',
  IntegrationId = 'integrationId',
  ClinicId = 'clinicId'
}

export type ClinicEmployeeOrderByRelevanceInput = {
  fields: Array<ClinicEmployeeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicEmployeeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isDefaultExporter?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ClinicEmployeeCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEmployeeMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEmployeeMinOrderByAggregateInput>;
};

export type ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isDefaultExporter?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityOrderByRelationAggregateInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByRelationAggregateInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapOrderByRelationAggregateInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByRelationAggregateInput>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleOrderByRelationAggregateInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicEmployeeOrderByRelevanceInput>;
};

export type ClinicEmployeeRelationFilter = {
  is?: Maybe<ClinicEmployeeWhereInput>;
  isNot?: Maybe<ClinicEmployeeWhereInput>;
};

export enum ClinicEmployeeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Type = 'type',
  PimsId = 'pimsId',
  FirstName = 'firstName',
  LastName = 'lastName',
  IntegrationId = 'integrationId',
  ClinicId = 'clinicId',
  IsSchedulable = 'isSchedulable',
  IsDefaultExporter = 'isDefaultExporter',
  IsVisible = 'isVisible',
  RawPimsValue = 'rawPimsValue'
}

export type ClinicEmployeeScalarWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  isSchedulable?: Maybe<BoolNullableFilter>;
  isDefaultExporter?: Maybe<BoolNullableFilter>;
  isVisible?: Maybe<BoolNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ClinicEmployeeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEmployeeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEmployeeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEmployeeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  type?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  firstName?: Maybe<StringWithAggregatesFilter>;
  lastName?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  isSchedulable?: Maybe<BoolNullableWithAggregatesFilter>;
  isDefaultExporter?: Maybe<BoolNullableWithAggregatesFilter>;
  isVisible?: Maybe<BoolNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicEmployeeToWorkflowEventSetting = {
  __typename?: 'ClinicEmployeeToWorkflowEventSetting';
  createdAt: Scalars['DateTime'];
  workflowId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
  workflow: WorkflowEventSetting;
  clinicEmployee: ClinicEmployee;
};

export type ClinicEmployeeToWorkflowEventSettingCountAggregateOutputType = {
  __typename?: 'ClinicEmployeeToWorkflowEventSettingCountAggregateOutputType';
  createdAt: Scalars['Int'];
  workflowId: Scalars['Int'];
  clinicEmployeeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicEmployeeToWorkflowEventSettingCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutClinicEmployeesInput;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutWorkflowsInput;
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope = {
  data: Array<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope = {
  data: Array<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput = {
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
  create: ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutClinicEmployeeInput;
};

export type ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput = {
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
  create: ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput;
};

export type ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutClinicEmployeesInput;
};

export type ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutWorkflowsInput;
};

export type ClinicEmployeeToWorkflowEventSettingListRelationFilter = {
  every?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
  some?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
  none?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
};

export type ClinicEmployeeToWorkflowEventSettingMaxAggregateOutputType = {
  __typename?: 'ClinicEmployeeToWorkflowEventSettingMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
};

export type ClinicEmployeeToWorkflowEventSettingMinAggregateOutputType = {
  __typename?: 'ClinicEmployeeToWorkflowEventSettingMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
};

export type ClinicEmployeeToWorkflowEventSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEmployeeToWorkflowEventSettingOrderByRelevanceFieldEnum {
  WorkflowId = 'workflowId',
  ClinicEmployeeId = 'clinicEmployeeId'
}

export type ClinicEmployeeToWorkflowEventSettingOrderByRelevanceInput = {
  fields: Array<ClinicEmployeeToWorkflowEventSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingOrderByWithAggregationInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicEmployeeToWorkflowEventSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEmployeeToWorkflowEventSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEmployeeToWorkflowEventSettingMinOrderByAggregateInput>;
};

export type ClinicEmployeeToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  workflow?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployee?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByRelevanceInput>;
};

export enum ClinicEmployeeToWorkflowEventSettingScalarFieldEnum {
  CreatedAt = 'createdAt',
  WorkflowId = 'workflowId',
  ClinicEmployeeId = 'clinicEmployeeId'
}

export type ClinicEmployeeToWorkflowEventSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
};

export type ClinicEmployeeToWorkflowEventSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  workflowId?: Maybe<StringWithAggregatesFilter>;
  clinicEmployeeId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  upsert?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutClinicEmployeesNestedInput>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutWorkflowsNestedInput>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutClinicEmployeeInput = {
  where: ClinicEmployeeToWorkflowEventSettingScalarWhereInput;
  data: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput = {
  where: ClinicEmployeeToWorkflowEventSettingScalarWhereInput;
  data: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeesInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  upsert?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput = {
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
  data: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutClinicEmployeeInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput = {
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
  data: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutClinicEmployeesNestedInput>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutWorkflowsNestedInput>;
};

export type ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput = {
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
  update: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutClinicEmployeeInput;
  create: ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutClinicEmployeeInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput = {
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
  update: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput;
  create: ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput;
};

export type ClinicEmployeeToWorkflowEventSettingWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
  workflow?: Maybe<WorkflowEventSettingWhereInput>;
  clinicEmployee?: Maybe<ClinicEmployeeWhereInput>;
};

export type ClinicEmployeeToWorkflowEventSettingWhereUniqueInput = {
  workflowId_clinicEmployeeId?: Maybe<ClinicEmployeeToWorkflowEventSettingWorkflowIdClinicEmployeeIdCompoundUniqueInput>;
};

export type ClinicEmployeeToWorkflowEventSettingWorkflowIdClinicEmployeeIdCompoundUniqueInput = {
  workflowId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
};

export type ClinicEmployeeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutAppointmentsInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutDirectBookingExclusionRulesInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeUncheckedCreateWithoutAppointmentAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutDirectBookingExclusionRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutAppointmentsInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutAppointmentsInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutDirectBookingExclusionRulesInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutDirectBookingExclusionRulesInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUncheckedUpdateWithoutAppointmentAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutDirectBookingExclusionRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutEnteredByInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutProviderInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeUpdateManyWithWhereWithoutAppointmentsInput = {
  where: ClinicEmployeeScalarWhereInput;
  data: ClinicEmployeeUncheckedUpdateManyWithoutClinicEmployeesInput;
};

export type ClinicEmployeeUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicEmployeeScalarWhereInput;
  data: ClinicEmployeeUncheckedUpdateManyWithoutClinicEmployeesInput;
};

export type ClinicEmployeeUpdateManyWithWhereWithoutDirectBookingExclusionRulesInput = {
  where: ClinicEmployeeScalarWhereInput;
  data: ClinicEmployeeUncheckedUpdateManyWithoutProvidersInput;
};

export type ClinicEmployeeUpdateManyWithWhereWithoutIntegrationInput = {
  where: ClinicEmployeeScalarWhereInput;
  data: ClinicEmployeeUncheckedUpdateManyWithoutEmployeesInput;
};

export type ClinicEmployeeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput = {
  where: ClinicEmployeeScalarWhereInput;
  data: ClinicEmployeeUncheckedUpdateManyWithoutClinicEmployeesInput;
};

export type ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutAppointmentsInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutAppointmentsInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUpdateManyWithoutDirectBookingExclusionRulesNestedInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutDirectBookingExclusionRulesInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutDirectBookingExclusionRulesInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsNestedInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsNestedInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
};

export type ClinicEmployeeUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutProviderAppointmentTypeRoomMapInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput>;
};

export type ClinicEmployeeUpdateOneRequiredWithoutVetAvailabilityMapsNestedInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutVetAvailabilityMapsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutVetAvailabilityMapsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutVetAvailabilityMapsInput>;
};

export type ClinicEmployeeUpdateOneRequiredWithoutWorkflowsNestedInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutWorkflowsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutWorkflowsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutWorkflowsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutWorkflowsInput>;
};

export type ClinicEmployeeUpdateOneWithoutAppointmentAvailabilitiesNestedInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutAppointmentAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutAppointmentAvailabilitiesInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutAppointmentAvailabilitiesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutAppointmentAvailabilitiesInput>;
};

export type ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutBitwerxPaymentWritebackConfigurationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutBitwerxPaymentWritebackConfigurationsInput>;
};

export type ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutEnteredByInvoiceLineItemsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutEnteredByInvoiceLineItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutEnteredByInvoiceLineItemsInput>;
};

export type ClinicEmployeeUpdateOneWithoutPrescriptionsNestedInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutPrescriptionsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutPrescriptionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutPrescriptionsInput>;
};

export type ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutProviderInvoiceLineItemsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutProviderInvoiceLineItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutProviderInvoiceLineItemsInput>;
};

export type ClinicEmployeeUpdateWithWhereUniqueWithoutAppointmentsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  data: ClinicEmployeeUncheckedUpdateWithoutAppointmentsInput;
};

export type ClinicEmployeeUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicEmployeeWhereUniqueInput;
  data: ClinicEmployeeUncheckedUpdateWithoutClinicInput;
};

export type ClinicEmployeeUpdateWithWhereUniqueWithoutDirectBookingExclusionRulesInput = {
  where: ClinicEmployeeWhereUniqueInput;
  data: ClinicEmployeeUncheckedUpdateWithoutDirectBookingExclusionRulesInput;
};

export type ClinicEmployeeUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicEmployeeWhereUniqueInput;
  data: ClinicEmployeeUncheckedUpdateWithoutIntegrationInput;
};

export type ClinicEmployeeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  data: ClinicEmployeeUncheckedUpdateWithoutUserAppointmentGroupingsInput;
};

export type ClinicEmployeeUpdateWithoutAppointmentAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutDirectBookingExclusionRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutEnteredByInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutProviderAppointmentTypeRoomMapInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
};

export type ClinicEmployeeUpdateWithoutProviderInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpsertWithWhereUniqueWithoutAppointmentsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  update: ClinicEmployeeUncheckedUpdateWithoutAppointmentsInput;
  create: ClinicEmployeeUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicEmployeeUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicEmployeeWhereUniqueInput;
  update: ClinicEmployeeUncheckedUpdateWithoutClinicInput;
  create: ClinicEmployeeUncheckedCreateWithoutClinicInput;
};

export type ClinicEmployeeUpsertWithWhereUniqueWithoutDirectBookingExclusionRulesInput = {
  where: ClinicEmployeeWhereUniqueInput;
  update: ClinicEmployeeUncheckedUpdateWithoutDirectBookingExclusionRulesInput;
  create: ClinicEmployeeUncheckedCreateWithoutDirectBookingExclusionRulesInput;
};

export type ClinicEmployeeUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicEmployeeWhereUniqueInput;
  update: ClinicEmployeeUncheckedUpdateWithoutIntegrationInput;
  create: ClinicEmployeeUncheckedCreateWithoutIntegrationInput;
};

export type ClinicEmployeeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  update: ClinicEmployeeUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  create: ClinicEmployeeUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type ClinicEmployeeUpsertWithoutAppointmentAvailabilitiesInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutAppointmentAvailabilitiesInput;
  create: ClinicEmployeeUncheckedCreateWithoutAppointmentAvailabilitiesInput;
};

export type ClinicEmployeeUpsertWithoutBitwerxPaymentWritebackConfigurationsInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutBitwerxPaymentWritebackConfigurationsInput;
  create: ClinicEmployeeUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput;
};

export type ClinicEmployeeUpsertWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput;
  create: ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeUpsertWithoutEnteredByInvoiceLineItemsInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutEnteredByInvoiceLineItemsInput;
  create: ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput;
};

export type ClinicEmployeeUpsertWithoutPrescriptionsInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutPrescriptionsInput;
  create: ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicEmployeeUpsertWithoutProviderAppointmentTypeRoomMapInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput;
  create: ClinicEmployeeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput;
};

export type ClinicEmployeeUpsertWithoutProviderInvoiceLineItemsInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutProviderInvoiceLineItemsInput;
  create: ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput;
};

export type ClinicEmployeeUpsertWithoutVetAvailabilityMapsInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutVetAvailabilityMapsInput;
  create: ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput;
};

export type ClinicEmployeeUpsertWithoutWorkflowsInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutWorkflowsInput;
  create: ClinicEmployeeUncheckedCreateWithoutWorkflowsInput;
};

export type ClinicEmployeeWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  isSchedulable?: Maybe<BoolNullableFilter>;
  isDefaultExporter?: Maybe<BoolNullableFilter>;
  isVisible?: Maybe<BoolNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityListRelationFilter>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingListRelationFilter>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapListRelationFilter>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingListRelationFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleListRelationFilter>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapListRelationFilter>;
};

export type ClinicEmployeeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicEmployeePimsIdAndIntegrationId?: Maybe<ClinicEmployeeClinicEmployeePimsIdAndIntegrationIdCompoundUniqueInput>;
};

export enum ClinicEntityCreationSource {
  Pims = 'Pims',
  ClinicApp = 'ClinicApp',
  DirectBooking = 'DirectBooking',
  WidgetRequest = 'WidgetRequest',
  InboundSms = 'InboundSms',
  TestEntityAutomation = 'TestEntityAutomation'
}

export type ClinicEntityPhoneNumber = {
  __typename?: 'ClinicEntityPhoneNumber';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  /** This is the result from the last time we tried to send */
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  /** The last time we tried to send an sms to this number */
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  /** the last time opt out messaging was sent to this number */
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  /** whether this number has opted out of messaging */
  isOptedOut: Scalars['Boolean'];
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequest>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResult>;
};

export type ClinicEntityPhoneNumberCountAggregateOutputType = {
  __typename?: 'ClinicEntityPhoneNumberCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  pimsId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isPrimary: Scalars['Int'];
  isPims: Scalars['Int'];
  isMobile: Scalars['Int'];
  number: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  smsStatus: Scalars['Int'];
  lastSmsSendDate: Scalars['Int'];
  lastOptOutSendDate: Scalars['Int'];
  isOptedOut: Scalars['Int'];
  phoneNumberLookupRequestId: Scalars['Int'];
  phoneNumberLookupResultId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicEntityPhoneNumberCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
  phoneNumberLookupRequestId?: Maybe<SortOrder>;
  phoneNumberLookupResultId?: Maybe<SortOrder>;
};

export type ClinicEntityPhoneNumberCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutPhoneNumbersInput>;
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestCreateNestedOneWithoutClinicEntityPhoneNumbersInput>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultCreateNestedOneWithoutClinicEntityPhoneNumbersInput>;
};

export type ClinicEntityPhoneNumberCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicEntityPhoneNumberCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberCreateManyPhoneNumberLookupRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberCreateManyPhoneNumberLookupRequestInputEnvelope = {
  data: Array<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberCreateManyPhoneNumberLookupResultInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberCreateManyPhoneNumberLookupResultInputEnvelope = {
  data: Array<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupResultInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
};

export type ClinicEntityPhoneNumberCreateNestedManyWithoutPhoneNumberLookupRequestInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupRequestInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupRequestInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupRequestInputEnvelope>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
};

export type ClinicEntityPhoneNumberCreateNestedManyWithoutPhoneNumberLookupResultInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupResultInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupResultInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupResultInputEnvelope>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
};

export type ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
  create: ClinicEntityPhoneNumberUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupRequestInput = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
  create: ClinicEntityPhoneNumberUncheckedCreateWithoutPhoneNumberLookupRequestInput;
};

export type ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupResultInput = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
  create: ClinicEntityPhoneNumberUncheckedCreateWithoutPhoneNumberLookupResultInput;
};

export type ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestCreateNestedOneWithoutClinicEntityPhoneNumbersInput>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultCreateNestedOneWithoutClinicEntityPhoneNumbersInput>;
};

export type ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutPhoneNumbersInput>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultCreateNestedOneWithoutClinicEntityPhoneNumbersInput>;
};

export type ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupResultInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutPhoneNumbersInput>;
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestCreateNestedOneWithoutClinicEntityPhoneNumbersInput>;
};

export type ClinicEntityPhoneNumberListRelationFilter = {
  every?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  some?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  none?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};

export type ClinicEntityPhoneNumberMaxAggregateOutputType = {
  __typename?: 'ClinicEntityPhoneNumberMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
  phoneNumberLookupRequestId?: Maybe<SortOrder>;
  phoneNumberLookupResultId?: Maybe<SortOrder>;
};

export type ClinicEntityPhoneNumberMinAggregateOutputType = {
  __typename?: 'ClinicEntityPhoneNumberMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
  phoneNumberLookupRequestId?: Maybe<SortOrder>;
  phoneNumberLookupResultId?: Maybe<SortOrder>;
};

export type ClinicEntityPhoneNumberNumberClinicPetParentIdCompoundUniqueInput = {
  number: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type ClinicEntityPhoneNumberOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEntityPhoneNumberOrderByRelevanceFieldEnum {
  Id = 'id',
  PimsId = 'pimsId',
  Number = 'number',
  ClinicPetParentId = 'clinicPetParentId',
  PhoneNumberLookupRequestId = 'phoneNumberLookupRequestId',
  PhoneNumberLookupResultId = 'phoneNumberLookupResultId'
}

export type ClinicEntityPhoneNumberOrderByRelevanceInput = {
  fields: Array<ClinicEntityPhoneNumberOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicEntityPhoneNumberOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
  phoneNumberLookupRequestId?: Maybe<SortOrder>;
  phoneNumberLookupResultId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicEntityPhoneNumberCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEntityPhoneNumberMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEntityPhoneNumberMinOrderByAggregateInput>;
};

export type ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
  phoneNumberLookupRequestId?: Maybe<SortOrder>;
  phoneNumberLookupResultId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestOrderByWithRelationAndSearchRelevanceInput>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicEntityPhoneNumberOrderByRelevanceInput>;
};

export enum ClinicEntityPhoneNumberScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PimsId = 'pimsId',
  IsDeleted = 'isDeleted',
  IsPrimary = 'isPrimary',
  IsPims = 'isPims',
  IsMobile = 'isMobile',
  Number = 'number',
  ClinicPetParentId = 'clinicPetParentId',
  SmsStatus = 'smsStatus',
  LastSmsSendDate = 'lastSmsSendDate',
  LastOptOutSendDate = 'lastOptOutSendDate',
  IsOptedOut = 'isOptedOut',
  PhoneNumberLookupRequestId = 'phoneNumberLookupRequestId',
  PhoneNumberLookupResultId = 'phoneNumberLookupResultId'
}

export type ClinicEntityPhoneNumberScalarWhereInput = {
  AND?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isPrimary?: Maybe<BoolNullableFilter>;
  isPims?: Maybe<BoolNullableFilter>;
  isMobile?: Maybe<BoolNullableFilter>;
  number?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  smsStatus?: Maybe<EnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  lastSmsSendDate?: Maybe<DateTimeNullableFilter>;
  lastOptOutSendDate?: Maybe<DateTimeNullableFilter>;
  isOptedOut?: Maybe<BoolFilter>;
  phoneNumberLookupRequestId?: Maybe<StringNullableFilter>;
  phoneNumberLookupResultId?: Maybe<StringNullableFilter>;
};

export type ClinicEntityPhoneNumberScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  isPrimary?: Maybe<BoolNullableWithAggregatesFilter>;
  isPims?: Maybe<BoolNullableWithAggregatesFilter>;
  isMobile?: Maybe<BoolNullableWithAggregatesFilter>;
  number?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  smsStatus?: Maybe<EnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter>;
  lastSmsSendDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastOptOutSendDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isOptedOut?: Maybe<BoolWithAggregatesFilter>;
  phoneNumberLookupRequestId?: Maybe<StringNullableWithAggregatesFilter>;
  phoneNumberLookupResultId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum ClinicEntityPhoneNumberSmsStatus {
  None = 'None',
  PreValidated = 'PreValidated',
  Delivered = 'Delivered',
  RejectedBecauseLandline = 'RejectedBecauseLandline',
  RejectedBecauseInvalidPhoneNumber = 'RejectedBecauseInvalidPhoneNumber',
  RejectedNotInService = 'RejectedNotInService',
  RejectedUnknown = 'RejectedUnknown',
  RejectedFilteredByCarrier = 'RejectedFilteredByCarrier',
  RejectedOptedOut = 'RejectedOptedOut'
}

export type ClinicEntityPhoneNumberUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
};

export type ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutPhoneNumberLookupRequestInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupRequestInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupRequestInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupRequestInputEnvelope>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
};

export type ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutPhoneNumberLookupResultInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupResultInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupResultInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupResultInputEnvelope>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
};

export type ClinicEntityPhoneNumberUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberUncheckedCreateWithoutPhoneNumberLookupRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberUncheckedCreateWithoutPhoneNumberLookupResultInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicEntityPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicEntityPhoneNumberUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumberLookupRequestNestedInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupRequestInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupRequestInput>>;
  upsert?: Maybe<Array<ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutPhoneNumberLookupRequestInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupRequestInputEnvelope>;
  set?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutPhoneNumberLookupRequestInput>>;
  updateMany?: Maybe<Array<ClinicEntityPhoneNumberUpdateManyWithWhereWithoutPhoneNumberLookupRequestInput>>;
  deleteMany?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumberLookupResultNestedInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupResultInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupResultInput>>;
  upsert?: Maybe<Array<ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutPhoneNumberLookupResultInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupResultInputEnvelope>;
  set?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutPhoneNumberLookupResultInput>>;
  updateMany?: Maybe<Array<ClinicEntityPhoneNumberUpdateManyWithWhereWithoutPhoneNumberLookupResultInput>>;
  deleteMany?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateWithoutPhoneNumberLookupRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateWithoutPhoneNumberLookupResultInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
};

export type ClinicEntityPhoneNumberUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutPhoneNumbersNestedInput>;
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestUpdateOneWithoutClinicEntityPhoneNumbersNestedInput>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultUpdateOneWithoutClinicEntityPhoneNumbersNestedInput>;
};

export type ClinicEntityPhoneNumberUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ClinicEntityPhoneNumberScalarWhereInput;
  data: ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumbersInput;
};

export type ClinicEntityPhoneNumberUpdateManyWithWhereWithoutPhoneNumberLookupRequestInput = {
  where: ClinicEntityPhoneNumberScalarWhereInput;
  data: ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicEntityPhoneNumbersInput;
};

export type ClinicEntityPhoneNumberUpdateManyWithWhereWithoutPhoneNumberLookupResultInput = {
  where: ClinicEntityPhoneNumberScalarWhereInput;
  data: ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicEntityPhoneNumbersInput;
};

export type ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicEntityPhoneNumberUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
};

export type ClinicEntityPhoneNumberUpdateManyWithoutPhoneNumberLookupRequestNestedInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupRequestInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupRequestInput>>;
  upsert?: Maybe<Array<ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutPhoneNumberLookupRequestInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupRequestInputEnvelope>;
  set?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutPhoneNumberLookupRequestInput>>;
  updateMany?: Maybe<Array<ClinicEntityPhoneNumberUpdateManyWithWhereWithoutPhoneNumberLookupRequestInput>>;
  deleteMany?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
};

export type ClinicEntityPhoneNumberUpdateManyWithoutPhoneNumberLookupResultNestedInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupResultInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupResultInput>>;
  upsert?: Maybe<Array<ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutPhoneNumberLookupResultInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupResultInputEnvelope>;
  set?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutPhoneNumberLookupResultInput>>;
  updateMany?: Maybe<Array<ClinicEntityPhoneNumberUpdateManyWithWhereWithoutPhoneNumberLookupResultInput>>;
  deleteMany?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
};

export type ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
  data: ClinicEntityPhoneNumberUncheckedUpdateWithoutClinicPetParentInput;
};

export type ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutPhoneNumberLookupRequestInput = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
  data: ClinicEntityPhoneNumberUncheckedUpdateWithoutPhoneNumberLookupRequestInput;
};

export type ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutPhoneNumberLookupResultInput = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
  data: ClinicEntityPhoneNumberUncheckedUpdateWithoutPhoneNumberLookupResultInput;
};

export type ClinicEntityPhoneNumberUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestUpdateOneWithoutClinicEntityPhoneNumbersNestedInput>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultUpdateOneWithoutClinicEntityPhoneNumbersNestedInput>;
};

export type ClinicEntityPhoneNumberUpdateWithoutPhoneNumberLookupRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutPhoneNumbersNestedInput>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultUpdateOneWithoutClinicEntityPhoneNumbersNestedInput>;
};

export type ClinicEntityPhoneNumberUpdateWithoutPhoneNumberLookupResultInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutPhoneNumbersNestedInput>;
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestUpdateOneWithoutClinicEntityPhoneNumbersNestedInput>;
};

export type ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
  update: ClinicEntityPhoneNumberUncheckedUpdateWithoutClinicPetParentInput;
  create: ClinicEntityPhoneNumberUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutPhoneNumberLookupRequestInput = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
  update: ClinicEntityPhoneNumberUncheckedUpdateWithoutPhoneNumberLookupRequestInput;
  create: ClinicEntityPhoneNumberUncheckedCreateWithoutPhoneNumberLookupRequestInput;
};

export type ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutPhoneNumberLookupResultInput = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
  update: ClinicEntityPhoneNumberUncheckedUpdateWithoutPhoneNumberLookupResultInput;
  create: ClinicEntityPhoneNumberUncheckedCreateWithoutPhoneNumberLookupResultInput;
};

export type ClinicEntityPhoneNumberWhereInput = {
  AND?: Maybe<Array<ClinicEntityPhoneNumberWhereInput>>;
  OR?: Maybe<Array<ClinicEntityPhoneNumberWhereInput>>;
  NOT?: Maybe<Array<ClinicEntityPhoneNumberWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isPrimary?: Maybe<BoolNullableFilter>;
  isPims?: Maybe<BoolNullableFilter>;
  isMobile?: Maybe<BoolNullableFilter>;
  number?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  smsStatus?: Maybe<EnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  lastSmsSendDate?: Maybe<DateTimeNullableFilter>;
  lastOptOutSendDate?: Maybe<DateTimeNullableFilter>;
  isOptedOut?: Maybe<BoolFilter>;
  phoneNumberLookupRequestId?: Maybe<StringNullableFilter>;
  phoneNumberLookupResultId?: Maybe<StringNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestWhereInput>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultWhereInput>;
};

export type ClinicEntityPhoneNumberWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  number_clinicPetParentId?: Maybe<ClinicEntityPhoneNumberNumberClinicPetParentIdCompoundUniqueInput>;
};

export type ClinicListRelationFilter = {
  every?: Maybe<ClinicWhereInput>;
  some?: Maybe<ClinicWhereInput>;
  none?: Maybe<ClinicWhereInput>;
};

export type ClinicMaxAggregateOutputType = {
  __typename?: 'ClinicMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  websiteUrl?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  optimizedLogoUrl?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  corporateId?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  canceledAt?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  clinicEmail?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  isServiceRemindersActive?: Maybe<SortOrder>;
  remindersIntegrationRequestEmail?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  smsPhoneNumber?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
  loyaltyProgramId?: Maybe<SortOrder>;
  legalEntityId?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  carePlanProviderGroupId?: Maybe<SortOrder>;
};

export type ClinicMinAggregateOutputType = {
  __typename?: 'ClinicMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  websiteUrl?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  optimizedLogoUrl?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  corporateId?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  canceledAt?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  clinicEmail?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  isServiceRemindersActive?: Maybe<SortOrder>;
  remindersIntegrationRequestEmail?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  smsPhoneNumber?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
  loyaltyProgramId?: Maybe<SortOrder>;
  legalEntityId?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  carePlanProviderGroupId?: Maybe<SortOrder>;
};

export type ClinicOfficeHour = {
  __typename?: 'ClinicOfficeHour';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['DateTime'];
  closeAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours: Scalars['Boolean'];
  type: ClinicOfficeHoursType;
  clinicId?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
};

export type ClinicOfficeHourAvgAggregateOutputType = {
  __typename?: 'ClinicOfficeHourAvgAggregateOutputType';
  dayOfWeek?: Maybe<Scalars['Float']>;
};

export type ClinicOfficeHourAvgOrderByAggregateInput = {
  dayOfWeek?: Maybe<SortOrder>;
};

export type ClinicOfficeHourCountAggregateOutputType = {
  __typename?: 'ClinicOfficeHourCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['Int'];
  closeAt: Scalars['Int'];
  isEnabled: Scalars['Int'];
  isOpenTwentyFourHours: Scalars['Int'];
  type: Scalars['Int'];
  clinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicOfficeHourCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type ClinicOfficeHourCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['DateTime'];
  closeAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicOfficeHoursInput>;
};

export type ClinicOfficeHourCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['DateTime'];
  closeAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
};

export type ClinicOfficeHourCreateManyClinicInputEnvelope = {
  data: Array<ClinicOfficeHourCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicOfficeHourCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['DateTime'];
  closeAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOfficeHourCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicOfficeHourCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicOfficeHourCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicOfficeHourCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
};

export type ClinicOfficeHourCreateOrConnectWithoutClinicInput = {
  where: ClinicOfficeHourWhereUniqueInput;
  create: ClinicOfficeHourUncheckedCreateWithoutClinicInput;
};

export type ClinicOfficeHourCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['DateTime'];
  closeAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
};

export type ClinicOfficeHourListRelationFilter = {
  every?: Maybe<ClinicOfficeHourWhereInput>;
  some?: Maybe<ClinicOfficeHourWhereInput>;
  none?: Maybe<ClinicOfficeHourWhereInput>;
};

export type ClinicOfficeHourMaxAggregateOutputType = {
  __typename?: 'ClinicOfficeHourMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOfficeHourMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type ClinicOfficeHourMinAggregateOutputType = {
  __typename?: 'ClinicOfficeHourMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOfficeHourMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type ClinicOfficeHourOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicOfficeHourOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId'
}

export type ClinicOfficeHourOrderByRelevanceInput = {
  fields: Array<ClinicOfficeHourOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicOfficeHourOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicOfficeHourCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicOfficeHourAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicOfficeHourMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicOfficeHourMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicOfficeHourSumOrderByAggregateInput>;
};

export type ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicOfficeHourOrderByRelevanceInput>;
};

export enum ClinicOfficeHourScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DayOfWeek = 'dayOfWeek',
  OpenAt = 'openAt',
  CloseAt = 'closeAt',
  IsEnabled = 'isEnabled',
  IsOpenTwentyFourHours = 'isOpenTwentyFourHours',
  Type = 'type',
  ClinicId = 'clinicId'
}

export type ClinicOfficeHourScalarWhereInput = {
  AND?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
  OR?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  dayOfWeek?: Maybe<IntFilter>;
  openAt?: Maybe<DateTimeFilter>;
  closeAt?: Maybe<DateTimeFilter>;
  isEnabled?: Maybe<BoolFilter>;
  isOpenTwentyFourHours?: Maybe<BoolFilter>;
  type?: Maybe<EnumClinicOfficeHoursTypeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
};

export type ClinicOfficeHourScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicOfficeHourScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicOfficeHourScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicOfficeHourScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  dayOfWeek?: Maybe<IntWithAggregatesFilter>;
  openAt?: Maybe<DateTimeWithAggregatesFilter>;
  closeAt?: Maybe<DateTimeWithAggregatesFilter>;
  isEnabled?: Maybe<BoolWithAggregatesFilter>;
  isOpenTwentyFourHours?: Maybe<BoolWithAggregatesFilter>;
  type?: Maybe<EnumClinicOfficeHoursTypeWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicOfficeHourSumAggregateOutputType = {
  __typename?: 'ClinicOfficeHourSumAggregateOutputType';
  dayOfWeek?: Maybe<Scalars['Int']>;
};

export type ClinicOfficeHourSumOrderByAggregateInput = {
  dayOfWeek?: Maybe<SortOrder>;
};

export type ClinicOfficeHourUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['DateTime'];
  closeAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicOfficeHourCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicOfficeHourCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicOfficeHourCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
};

export type ClinicOfficeHourUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['DateTime'];
  closeAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
};

export type ClinicOfficeHourUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOfficeHourUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicOfficeHourCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicOfficeHourCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicOfficeHourUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicOfficeHourCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  update?: Maybe<Array<ClinicOfficeHourUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicOfficeHourUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
};

export type ClinicOfficeHourUncheckedUpdateManyWithoutClinicOfficeHoursInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
};

export type ClinicOfficeHourUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
};

export type ClinicOfficeHourUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicOfficeHoursNestedInput>;
};

export type ClinicOfficeHourUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
};

export type ClinicOfficeHourUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicOfficeHourScalarWhereInput;
  data: ClinicOfficeHourUncheckedUpdateManyWithoutClinicOfficeHoursInput;
};

export type ClinicOfficeHourUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicOfficeHourCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicOfficeHourCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicOfficeHourUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicOfficeHourCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  update?: Maybe<Array<ClinicOfficeHourUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicOfficeHourUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
};

export type ClinicOfficeHourUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicOfficeHourWhereUniqueInput;
  data: ClinicOfficeHourUncheckedUpdateWithoutClinicInput;
};

export type ClinicOfficeHourUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
};

export type ClinicOfficeHourUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicOfficeHourWhereUniqueInput;
  update: ClinicOfficeHourUncheckedUpdateWithoutClinicInput;
  create: ClinicOfficeHourUncheckedCreateWithoutClinicInput;
};

export type ClinicOfficeHourWhereInput = {
  AND?: Maybe<Array<ClinicOfficeHourWhereInput>>;
  OR?: Maybe<Array<ClinicOfficeHourWhereInput>>;
  NOT?: Maybe<Array<ClinicOfficeHourWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  dayOfWeek?: Maybe<IntFilter>;
  openAt?: Maybe<DateTimeFilter>;
  closeAt?: Maybe<DateTimeFilter>;
  isEnabled?: Maybe<BoolFilter>;
  isOpenTwentyFourHours?: Maybe<BoolFilter>;
  type?: Maybe<EnumClinicOfficeHoursTypeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type ClinicOfficeHourWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ClinicOfficeHoursType {
  AutomationHours = 'AutomationHours',
  BusinessHours = 'BusinessHours',
  DirectBookingHours = 'DirectBookingHours'
}

export type ClinicOnboarding = {
  __typename?: 'ClinicOnboarding';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stage: Scalars['String'];
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinic: Clinic;
};

export type ClinicOnboardingCountAggregateOutputType = {
  __typename?: 'ClinicOnboardingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  stage: Scalars['Int'];
  kickoffDate: Scalars['Int'];
  trainingDate: Scalars['Int'];
  completionDate: Scalars['Int'];
  pauseUntilDate: Scalars['Int'];
  salesforceOnboardingId: Scalars['Int'];
  clinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicOnboardingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stage?: Maybe<SortOrder>;
  kickoffDate?: Maybe<SortOrder>;
  trainingDate?: Maybe<SortOrder>;
  completionDate?: Maybe<SortOrder>;
  pauseUntilDate?: Maybe<SortOrder>;
  salesforceOnboardingId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type ClinicOnboardingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutClinicOnboardingsInput;
};

export type ClinicOnboardingCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
};

export type ClinicOnboardingCreateManyClinicInputEnvelope = {
  data: Array<ClinicOnboardingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicOnboardingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
};

export type ClinicOnboardingCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicOnboardingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicOnboardingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicOnboardingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
};

export type ClinicOnboardingCreateOrConnectWithoutClinicInput = {
  where: ClinicOnboardingWhereUniqueInput;
  create: ClinicOnboardingUncheckedCreateWithoutClinicInput;
};

export type ClinicOnboardingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
};

export type ClinicOnboardingListRelationFilter = {
  every?: Maybe<ClinicOnboardingWhereInput>;
  some?: Maybe<ClinicOnboardingWhereInput>;
  none?: Maybe<ClinicOnboardingWhereInput>;
};

export type ClinicOnboardingMaxAggregateOutputType = {
  __typename?: 'ClinicOnboardingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOnboardingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stage?: Maybe<SortOrder>;
  kickoffDate?: Maybe<SortOrder>;
  trainingDate?: Maybe<SortOrder>;
  completionDate?: Maybe<SortOrder>;
  pauseUntilDate?: Maybe<SortOrder>;
  salesforceOnboardingId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type ClinicOnboardingMinAggregateOutputType = {
  __typename?: 'ClinicOnboardingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOnboardingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stage?: Maybe<SortOrder>;
  kickoffDate?: Maybe<SortOrder>;
  trainingDate?: Maybe<SortOrder>;
  completionDate?: Maybe<SortOrder>;
  pauseUntilDate?: Maybe<SortOrder>;
  salesforceOnboardingId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type ClinicOnboardingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicOnboardingOrderByRelevanceFieldEnum {
  Id = 'id',
  Stage = 'stage',
  SalesforceOnboardingId = 'salesforceOnboardingId',
  ClinicId = 'clinicId'
}

export type ClinicOnboardingOrderByRelevanceInput = {
  fields: Array<ClinicOnboardingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicOnboardingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stage?: Maybe<SortOrder>;
  kickoffDate?: Maybe<SortOrder>;
  trainingDate?: Maybe<SortOrder>;
  completionDate?: Maybe<SortOrder>;
  pauseUntilDate?: Maybe<SortOrder>;
  salesforceOnboardingId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicOnboardingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicOnboardingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicOnboardingMinOrderByAggregateInput>;
};

export type ClinicOnboardingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stage?: Maybe<SortOrder>;
  kickoffDate?: Maybe<SortOrder>;
  trainingDate?: Maybe<SortOrder>;
  completionDate?: Maybe<SortOrder>;
  pauseUntilDate?: Maybe<SortOrder>;
  salesforceOnboardingId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicOnboardingOrderByRelevanceInput>;
};

export enum ClinicOnboardingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Stage = 'stage',
  KickoffDate = 'kickoffDate',
  TrainingDate = 'trainingDate',
  CompletionDate = 'completionDate',
  PauseUntilDate = 'pauseUntilDate',
  SalesforceOnboardingId = 'salesforceOnboardingId',
  ClinicId = 'clinicId'
}

export type ClinicOnboardingScalarWhereInput = {
  AND?: Maybe<Array<ClinicOnboardingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicOnboardingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicOnboardingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stage?: Maybe<StringFilter>;
  kickoffDate?: Maybe<DateTimeNullableFilter>;
  trainingDate?: Maybe<DateTimeNullableFilter>;
  completionDate?: Maybe<DateTimeNullableFilter>;
  pauseUntilDate?: Maybe<DateTimeNullableFilter>;
  salesforceOnboardingId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
};

export type ClinicOnboardingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicOnboardingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicOnboardingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicOnboardingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  stage?: Maybe<StringWithAggregatesFilter>;
  kickoffDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  trainingDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  completionDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  pauseUntilDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  salesforceOnboardingId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicOnboardingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
};

export type ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicOnboardingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicOnboardingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicOnboardingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
};

export type ClinicOnboardingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
};

export type ClinicOnboardingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOnboardingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicOnboardingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicOnboardingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicOnboardingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicOnboardingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicOnboardingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicOnboardingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicOnboardingScalarWhereInput>>;
};

export type ClinicOnboardingUncheckedUpdateManyWithoutClinicOnboardingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
};

export type ClinicOnboardingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
};

export type ClinicOnboardingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicOnboardingsNestedInput>;
};

export type ClinicOnboardingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
};

export type ClinicOnboardingUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicOnboardingScalarWhereInput;
  data: ClinicOnboardingUncheckedUpdateManyWithoutClinicOnboardingsInput;
};

export type ClinicOnboardingUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicOnboardingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicOnboardingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicOnboardingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicOnboardingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicOnboardingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicOnboardingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicOnboardingScalarWhereInput>>;
};

export type ClinicOnboardingUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicOnboardingWhereUniqueInput;
  data: ClinicOnboardingUncheckedUpdateWithoutClinicInput;
};

export type ClinicOnboardingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
};

export type ClinicOnboardingUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicOnboardingWhereUniqueInput;
  update: ClinicOnboardingUncheckedUpdateWithoutClinicInput;
  create: ClinicOnboardingUncheckedCreateWithoutClinicInput;
};

export type ClinicOnboardingWhereInput = {
  AND?: Maybe<Array<ClinicOnboardingWhereInput>>;
  OR?: Maybe<Array<ClinicOnboardingWhereInput>>;
  NOT?: Maybe<Array<ClinicOnboardingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stage?: Maybe<StringFilter>;
  kickoffDate?: Maybe<DateTimeNullableFilter>;
  trainingDate?: Maybe<DateTimeNullableFilter>;
  completionDate?: Maybe<DateTimeNullableFilter>;
  pauseUntilDate?: Maybe<DateTimeNullableFilter>;
  salesforceOnboardingId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type ClinicOnboardingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  City = 'city',
  State = 'state',
  Country = 'country',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  PostalCode = 'postalCode',
  WebsiteUrl = 'websiteUrl',
  Hours = 'hours',
  Logo = 'logo',
  OptimizedLogoUrl = 'optimizedLogoUrl',
  IncomeMethod = 'incomeMethod',
  PaymentName = 'paymentName',
  PaymentAddress = 'paymentAddress',
  PaymentCity = 'paymentCity',
  PaymentState = 'paymentState',
  PaymentZip = 'paymentZip',
  PaymentBankRoutingNumber = 'paymentBankRoutingNumber',
  PaymentBankAccountNumber = 'paymentBankAccountNumber',
  FirebaseId = 'firebaseId',
  StripeCustomerId = 'stripeCustomerId',
  FullAddress = 'fullAddress',
  StripeSubscriptionId = 'stripeSubscriptionId',
  StripeSubscriptionPlan = 'stripeSubscriptionPlan',
  Token = 'token',
  Phone = 'phone',
  OrganizationId = 'organizationId',
  IntegrationType = 'integrationType',
  CorporateId = 'corporateId',
  CustomerId = 'customerId',
  SubscriptionId = 'subscriptionId',
  SubscriptionPlan = 'subscriptionPlan',
  CancellationReason = 'cancellationReason',
  CancellationNote = 'cancellationNote',
  ClinicEmail = 'clinicEmail',
  IntegrationSystem = 'integrationSystem',
  MarketEmail = 'marketEmail',
  MarketFacebookPageId = 'marketFacebookPageId',
  MarketFacebookPageToken = 'marketFacebookPageToken',
  SubmerchantId = 'submerchantId',
  HeadUserId = 'headUserId',
  CrmId = 'crmId',
  SubmerchantStatus = 'submerchantStatus',
  PaymentPhone = 'paymentPhone',
  SubmerchantStripeId = 'submerchantStripeId',
  SubmerchantStripeStatus = 'submerchantStripeStatus',
  ClinicSettingId = 'clinicSettingId',
  TimezoneName = 'timezoneName',
  ClinicWidgetSettingId = 'clinicWidgetSettingId',
  RemindersIntegrationRequestEmail = 'remindersIntegrationRequestEmail',
  CallerIdPhoneNumber = 'callerIdPhoneNumber',
  SmsPhoneNumber = 'smsPhoneNumber',
  LoyaltyProgramId = 'loyaltyProgramId',
  LegalEntityId = 'legalEntityId',
  CarePlanProviderGroupId = 'carePlanProviderGroupId'
}

export type ClinicOrderByRelevanceInput = {
  fields: Array<ClinicOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  websiteUrl?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  optimizedLogoUrl?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  widgetSettings?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  integrationInfo?: Maybe<SortOrder>;
  corporateId?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  canceledAt?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  clinicEmail?: Maybe<SortOrder>;
  firebaseVets?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  crmUrlParams?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  autoSubscriptionReceiptEmails?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  isServiceRemindersActive?: Maybe<SortOrder>;
  remindersIntegrationRequestEmail?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  smsPhoneNumber?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
  loyaltyProgramId?: Maybe<SortOrder>;
  legalEntityId?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  carePlanProviderGroupId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicSumOrderByAggregateInput>;
};

export type ClinicOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  websiteUrl?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  optimizedLogoUrl?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  widgetSettings?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  integrationInfo?: Maybe<SortOrder>;
  corporateId?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  canceledAt?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  clinicEmail?: Maybe<SortOrder>;
  firebaseVets?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  crmUrlParams?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  autoSubscriptionReceiptEmails?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  isServiceRemindersActive?: Maybe<SortOrder>;
  remindersIntegrationRequestEmail?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  smsPhoneNumber?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
  loyaltyProgramId?: Maybe<SortOrder>;
  legalEntityId?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  carePlanProviderGroupId?: Maybe<SortOrder>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>;
  usersId?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityOrderByRelationAggregateInput>;
  appoinmentTypes?: Maybe<AppointmentTypeOrderByRelationAggregateInput>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  clinicEmployees?: Maybe<ClinicEmployeeOrderByRelationAggregateInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourOrderByRelationAggregateInput>;
  clinicPets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  integrations?: Maybe<ClinicPimsIntegrationOrderByRelationAggregateInput>;
  clinicRooms?: Maybe<ClinicRoomOrderByRelationAggregateInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestOrderByRelationAggregateInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeOrderByRelationAggregateInput>;
  consultations?: Maybe<ConsultationOrderByRelationAggregateInput>;
  consultationRequests?: Maybe<ConsultationRequestOrderByRelationAggregateInput>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  formTemplates?: Maybe<FormTemplateOrderByRelationAggregateInput>;
  petsId?: Maybe<PetOrderByRelationAggregateInput>;
  promoCodes?: Maybe<PromoCodeOrderByRelationAggregateInput>;
  roles?: Maybe<StaffRoleOrderByRelationAggregateInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  staffId?: Maybe<VetInfoOrderByRelationAggregateInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountOrderByRelationAggregateInput>;
  pets?: Maybe<PetOrderByRelationAggregateInput>;
  staff?: Maybe<VetInfoOrderByRelationAggregateInput>;
  users?: Maybe<UserOrderByRelationAggregateInput>;
  twilioVerifications?: Maybe<TwilioVerificationOrderByRelationAggregateInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationOrderByRelationAggregateInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  financialTransactions?: Maybe<FinancialTransactionOrderByRelationAggregateInput>;
  featureFlags?: Maybe<FeatureFlagOrderByRelationAggregateInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeOrderByRelationAggregateInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingOrderByRelationAggregateInput>;
  calls?: Maybe<CallHistoryOrderByRelationAggregateInput>;
  benefitsProvided?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  carePayoutOrganization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  vetAvailabilities?: Maybe<VetAvailabilityOrderByRelationAggregateInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingOrderByRelationAggregateInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionOrderByRelationAggregateInput>;
  messageTemplates?: Maybe<MessageTemplateOrderByRelationAggregateInput>;
  Rules?: Maybe<RulesOrderByRelationAggregateInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  vaccinations?: Maybe<VaccinationOrderByRelationAggregateInput>;
  massTextAlerts?: Maybe<MassTextAlertOrderByRelationAggregateInput>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberOrderByRelationAggregateInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingOrderByRelationAggregateInput>;
  surveyResults?: Maybe<SurveyResultOrderByRelationAggregateInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentOrderByRelationAggregateInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaOrderByRelationAggregateInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByRelationAggregateInput>;
  media?: Maybe<MediaOrderByRelationAggregateInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>;
  legalEntity?: Maybe<LegalEntityOrderByWithRelationAndSearchRelevanceInput>;
  unlayerSetting?: Maybe<UnlayerSettingOrderByWithRelationAndSearchRelevanceInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleOrderByRelationAggregateInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferOrderByRelationAggregateInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationOrderByRelationAggregateInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodOrderByRelationAggregateInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckOrderByRelationAggregateInput>;
  emailCampaign?: Maybe<EmailCampaignOrderByRelationAggregateInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateOrderByRelationAggregateInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeOrderByRelationAggregateInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogOrderByRelationAggregateInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberOrderByRelationAggregateInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogOrderByRelationAggregateInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnOrderByRelationAggregateInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicOrderByRelevanceInput>;
};

export type ClinicPaymentActivityOutput = {
  __typename?: 'ClinicPaymentActivityOutput';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  totalRecentTransactions?: Maybe<Scalars['Int']>;
  totalRecentCentsProcessed?: Maybe<Scalars['Int']>;
};

export type ClinicPaymentFeeConfiguration = {
  __typename?: 'ClinicPaymentFeeConfiguration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicSettingId: Scalars['String'];
  onlineProcessingFeePercent: Scalars['Float'];
  onlineProcessingFeeFlat: Scalars['Int'];
  onlineClientServiceFeePercent: Scalars['Float'];
  terminalProcessingFeePercent: Scalars['Float'];
  terminalProcessingFeeFlat: Scalars['Int'];
  terminalClientServiceFeePercent: Scalars['Float'];
  disputeFeeCharge: Scalars['Int'];
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection: FeeCollection;
  pricingModel: PricingModel;
  clinicSetting: ClinicSetting;
  interchangeRates: Array<InterchangeRate>;
  _count: ClinicPaymentFeeConfigurationCountOutputType;
};


export type ClinicPaymentFeeConfigurationInterchangeRatesArgs = {
  where?: Maybe<InterchangeRateWhereInput>;
  orderBy?: Maybe<InterchangeRateOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InterchangeRateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InterchangeRateScalarFieldEnum>;
};

export type ClinicPaymentFeeConfigurationAvgAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationAvgAggregateOutputType';
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Float']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Float']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationAvgOrderByAggregateInput = {
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  disputeFeeCharge?: Maybe<SortOrder>;
};

export type ClinicPaymentFeeConfigurationCountAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  onlineProcessingFeePercent: Scalars['Int'];
  onlineProcessingFeeFlat: Scalars['Int'];
  onlineClientServiceFeePercent: Scalars['Int'];
  terminalProcessingFeePercent: Scalars['Int'];
  terminalProcessingFeeFlat: Scalars['Int'];
  terminalClientServiceFeePercent: Scalars['Int'];
  disputeFeeCharge: Scalars['Int'];
  responsibleForClinicRefundFeesAt: Scalars['Int'];
  feeCollection: Scalars['Int'];
  pricingModel: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPaymentFeeConfigurationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  disputeFeeCharge?: Maybe<SortOrder>;
  responsibleForClinicRefundFeesAt?: Maybe<SortOrder>;
  feeCollection?: Maybe<SortOrder>;
  pricingModel?: Maybe<SortOrder>;
};

export type ClinicPaymentFeeConfigurationCountOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationCountOutputType';
  interchangeRates: Scalars['Int'];
};

export type ClinicPaymentFeeConfigurationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
  clinicSetting: ClinicSettingCreateNestedOneWithoutPaymentFeeConfigInput;
  interchangeRates?: Maybe<InterchangeRateCreateNestedManyWithoutClinicPaymentFeeConfigurationInput>;
};

export type ClinicPaymentFeeConfigurationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSettingId: Scalars['String'];
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
};

export type ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput = {
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput>;
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
};

export type ClinicPaymentFeeConfigurationCreateNestedOneWithoutInterchangeRatesInput = {
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutInterchangeRatesInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutInterchangeRatesInput>;
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
};

export type ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput = {
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
  create: ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput;
};

export type ClinicPaymentFeeConfigurationCreateOrConnectWithoutInterchangeRatesInput = {
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
  create: ClinicPaymentFeeConfigurationUncheckedCreateWithoutInterchangeRatesInput;
};

export type ClinicPaymentFeeConfigurationCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
  interchangeRates?: Maybe<InterchangeRateCreateNestedManyWithoutClinicPaymentFeeConfigurationInput>;
};

export type ClinicPaymentFeeConfigurationCreateWithoutInterchangeRatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
  clinicSetting: ClinicSettingCreateNestedOneWithoutPaymentFeeConfigInput;
};

export type ClinicPaymentFeeConfigurationMaxAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
};

export type ClinicPaymentFeeConfigurationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  disputeFeeCharge?: Maybe<SortOrder>;
  responsibleForClinicRefundFeesAt?: Maybe<SortOrder>;
  feeCollection?: Maybe<SortOrder>;
  pricingModel?: Maybe<SortOrder>;
};

export type ClinicPaymentFeeConfigurationMinAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
};

export type ClinicPaymentFeeConfigurationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  disputeFeeCharge?: Maybe<SortOrder>;
  responsibleForClinicRefundFeesAt?: Maybe<SortOrder>;
  feeCollection?: Maybe<SortOrder>;
  pricingModel?: Maybe<SortOrder>;
};

export enum ClinicPaymentFeeConfigurationOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicSettingId = 'clinicSettingId'
}

export type ClinicPaymentFeeConfigurationOrderByRelevanceInput = {
  fields: Array<ClinicPaymentFeeConfigurationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPaymentFeeConfigurationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  disputeFeeCharge?: Maybe<SortOrder>;
  responsibleForClinicRefundFeesAt?: Maybe<SortOrder>;
  feeCollection?: Maybe<SortOrder>;
  pricingModel?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPaymentFeeConfigurationCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicPaymentFeeConfigurationAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicPaymentFeeConfigurationMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPaymentFeeConfigurationMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicPaymentFeeConfigurationSumOrderByAggregateInput>;
};

export type ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  disputeFeeCharge?: Maybe<SortOrder>;
  responsibleForClinicRefundFeesAt?: Maybe<SortOrder>;
  feeCollection?: Maybe<SortOrder>;
  pricingModel?: Maybe<SortOrder>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  interchangeRates?: Maybe<InterchangeRateOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicPaymentFeeConfigurationOrderByRelevanceInput>;
};

export type ClinicPaymentFeeConfigurationRelationFilter = {
  is?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  isNot?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
};

export enum ClinicPaymentFeeConfigurationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicSettingId = 'clinicSettingId',
  OnlineProcessingFeePercent = 'onlineProcessingFeePercent',
  OnlineProcessingFeeFlat = 'onlineProcessingFeeFlat',
  OnlineClientServiceFeePercent = 'onlineClientServiceFeePercent',
  TerminalProcessingFeePercent = 'terminalProcessingFeePercent',
  TerminalProcessingFeeFlat = 'terminalProcessingFeeFlat',
  TerminalClientServiceFeePercent = 'terminalClientServiceFeePercent',
  DisputeFeeCharge = 'disputeFeeCharge',
  ResponsibleForClinicRefundFeesAt = 'responsibleForClinicRefundFeesAt',
  FeeCollection = 'feeCollection',
  PricingModel = 'pricingModel'
}

export type ClinicPaymentFeeConfigurationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPaymentFeeConfigurationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPaymentFeeConfigurationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPaymentFeeConfigurationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicSettingId?: Maybe<StringWithAggregatesFilter>;
  onlineProcessingFeePercent?: Maybe<FloatWithAggregatesFilter>;
  onlineProcessingFeeFlat?: Maybe<IntWithAggregatesFilter>;
  onlineClientServiceFeePercent?: Maybe<FloatWithAggregatesFilter>;
  terminalProcessingFeePercent?: Maybe<FloatWithAggregatesFilter>;
  terminalProcessingFeeFlat?: Maybe<IntWithAggregatesFilter>;
  terminalClientServiceFeePercent?: Maybe<FloatWithAggregatesFilter>;
  disputeFeeCharge?: Maybe<IntWithAggregatesFilter>;
  responsibleForClinicRefundFeesAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  feeCollection?: Maybe<EnumFeeCollectionWithAggregatesFilter>;
  pricingModel?: Maybe<EnumPricingModelWithAggregatesFilter>;
};

export type ClinicPaymentFeeConfigurationSumAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationSumAggregateOutputType';
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
};

export type ClinicPaymentFeeConfigurationSumOrderByAggregateInput = {
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  disputeFeeCharge?: Maybe<SortOrder>;
};

export type ClinicPaymentFeeConfigurationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSettingId: Scalars['String'];
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
  interchangeRates?: Maybe<InterchangeRateUncheckedCreateNestedManyWithoutClinicPaymentFeeConfigurationInput>;
};

export type ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput = {
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput>;
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
};

export type ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
  interchangeRates?: Maybe<InterchangeRateUncheckedCreateNestedManyWithoutClinicPaymentFeeConfigurationInput>;
};

export type ClinicPaymentFeeConfigurationUncheckedCreateWithoutInterchangeRatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSettingId: Scalars['String'];
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
};

export type ClinicPaymentFeeConfigurationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
  interchangeRates?: Maybe<InterchangeRateUncheckedUpdateManyWithoutClinicPaymentFeeConfigurationNestedInput>;
};

export type ClinicPaymentFeeConfigurationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
};

export type ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingNestedInput = {
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput>;
  upsert?: Maybe<ClinicPaymentFeeConfigurationUpsertWithoutClinicSettingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  update?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateWithoutClinicSettingInput>;
};

export type ClinicPaymentFeeConfigurationUncheckedUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
  interchangeRates?: Maybe<InterchangeRateUncheckedUpdateManyWithoutClinicPaymentFeeConfigurationNestedInput>;
};

export type ClinicPaymentFeeConfigurationUncheckedUpdateWithoutInterchangeRatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
};

export type ClinicPaymentFeeConfigurationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutPaymentFeeConfigNestedInput>;
  interchangeRates?: Maybe<InterchangeRateUpdateManyWithoutClinicPaymentFeeConfigurationNestedInput>;
};

export type ClinicPaymentFeeConfigurationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
};

export type ClinicPaymentFeeConfigurationUpdateOneRequiredWithoutInterchangeRatesNestedInput = {
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutInterchangeRatesInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutInterchangeRatesInput>;
  upsert?: Maybe<ClinicPaymentFeeConfigurationUpsertWithoutInterchangeRatesInput>;
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  update?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateWithoutInterchangeRatesInput>;
};

export type ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingNestedInput = {
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput>;
  upsert?: Maybe<ClinicPaymentFeeConfigurationUpsertWithoutClinicSettingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  update?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateWithoutClinicSettingInput>;
};

export type ClinicPaymentFeeConfigurationUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
  interchangeRates?: Maybe<InterchangeRateUpdateManyWithoutClinicPaymentFeeConfigurationNestedInput>;
};

export type ClinicPaymentFeeConfigurationUpdateWithoutInterchangeRatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  feeCollection?: Maybe<FeeCollection>;
  pricingModel?: Maybe<PricingModel>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutPaymentFeeConfigNestedInput>;
};

export type ClinicPaymentFeeConfigurationUpsertWithoutClinicSettingInput = {
  update: ClinicPaymentFeeConfigurationUncheckedUpdateWithoutClinicSettingInput;
  create: ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput;
};

export type ClinicPaymentFeeConfigurationUpsertWithoutInterchangeRatesInput = {
  update: ClinicPaymentFeeConfigurationUncheckedUpdateWithoutInterchangeRatesInput;
  create: ClinicPaymentFeeConfigurationUncheckedCreateWithoutInterchangeRatesInput;
};

export type ClinicPaymentFeeConfigurationWhereInput = {
  AND?: Maybe<Array<ClinicPaymentFeeConfigurationWhereInput>>;
  OR?: Maybe<Array<ClinicPaymentFeeConfigurationWhereInput>>;
  NOT?: Maybe<Array<ClinicPaymentFeeConfigurationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicSettingId?: Maybe<StringFilter>;
  onlineProcessingFeePercent?: Maybe<FloatFilter>;
  onlineProcessingFeeFlat?: Maybe<IntFilter>;
  onlineClientServiceFeePercent?: Maybe<FloatFilter>;
  terminalProcessingFeePercent?: Maybe<FloatFilter>;
  terminalProcessingFeeFlat?: Maybe<IntFilter>;
  terminalClientServiceFeePercent?: Maybe<FloatFilter>;
  disputeFeeCharge?: Maybe<IntFilter>;
  responsibleForClinicRefundFeesAt?: Maybe<DateTimeNullableFilter>;
  feeCollection?: Maybe<EnumFeeCollectionFilter>;
  pricingModel?: Maybe<EnumPricingModelFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
  interchangeRates?: Maybe<InterchangeRateListRelationFilter>;
};

export type ClinicPaymentFeeConfigurationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
};

export type ClinicPet = {
  __typename?: 'ClinicPet';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType: SpeciesType;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders: Scalars['Boolean'];
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<Clinic>;
  integration?: Maybe<ClinicPimsIntegration>;
  organizationPet?: Maybe<OrganizationPet>;
  appointments: Array<Appointment>;
  alerts: Array<ClinicPetAlert>;
  formSubmissions: Array<FormSubmission>;
  petParents: Array<ClinicPetParent>;
  serviceReminders: Array<ServiceReminder>;
  channels: Array<Channel>;
  invoiceLineItems: Array<InvoiceLineItem>;
  careBenefitUsages: Array<CareBenefitUsage>;
  automationRunActions: Array<AutomationRunAction>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  vaccinations: Array<Vaccination>;
  prescriptions: Array<Prescription>;
  clinicPetPimsSyncStatus: Array<ClinicPetPimsSyncStatus>;
  breedModel?: Maybe<Breed>;
  sexModel?: Maybe<Sex>;
  colorModel?: Maybe<Color>;
  speciesModel?: Maybe<Species>;
  media?: Maybe<Media>;
  receivedTrupanionExamDayOffers: Array<SentTrupanionExamDayOffer>;
  trupanionCertificateAvailabilities: Array<TrupanionCertificateAvailability>;
  customFieldValues: Array<ClinicPetCustomFieldValue>;
  HillsToHomeAPILog: Array<HillsToHomeApiLog>;
  _count: ClinicPetCountOutputType;
  computed_rawPimsIsDeceased?: Maybe<Scalars['Boolean']>;
  computed_rawPimsIsActive?: Maybe<Scalars['Boolean']>;
  computed_optimizedPhotoUrl?: Maybe<Scalars['String']>;
};


export type ClinicPetAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};


export type ClinicPetAlertsArgs = {
  where?: Maybe<ClinicPetAlertWhereInput>;
  orderBy?: Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetAlertScalarFieldEnum>;
};


export type ClinicPetFormSubmissionsArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
};


export type ClinicPetPetParentsArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};


export type ClinicPetServiceRemindersArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderScalarFieldEnum>;
};


export type ClinicPetChannelsArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
};


export type ClinicPetInvoiceLineItemsArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
};


export type ClinicPetCareBenefitUsagesArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
};


export type ClinicPetAutomationRunActionsArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
};


export type ClinicPetChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};


export type ClinicPetVaccinationsArgs = {
  where?: Maybe<VaccinationWhereInput>;
  orderBy?: Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VaccinationScalarFieldEnum>;
};


export type ClinicPetPrescriptionsArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
};


export type ClinicPetClinicPetPimsSyncStatusArgs = {
  where?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
  orderBy?: Maybe<ClinicPetPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetPimsSyncStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetPimsSyncStatusScalarFieldEnum>;
};


export type ClinicPetReceivedTrupanionExamDayOffersArgs = {
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
  orderBy?: Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SentTrupanionExamDayOfferScalarFieldEnum>;
};


export type ClinicPetTrupanionCertificateAvailabilitiesArgs = {
  where?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
  orderBy?: Maybe<TrupanionCertificateAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<TrupanionCertificateAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<TrupanionCertificateAvailabilityScalarFieldEnum>;
};


export type ClinicPetCustomFieldValuesArgs = {
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
  orderBy?: Maybe<ClinicPetCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetCustomFieldValueWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetCustomFieldValueScalarFieldEnum>;
};


export type ClinicPetHillsToHomeApiLogArgs = {
  where?: Maybe<HillsToHomeApiLogWhereInput>;
  orderBy?: Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<HillsToHomeApiLogScalarFieldEnum>;
};

export type ClinicPetAlert = {
  __typename?: 'ClinicPetAlert';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPet?: Maybe<ClinicPet>;
};

export type ClinicPetAlertCountAggregateOutputType = {
  __typename?: 'ClinicPetAlertCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  value: Scalars['Int'];
  pimsId: Scalars['Int'];
  pimsDate: Scalars['Int'];
  isMedical: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPetAlertCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ClinicPetAlertCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAlertsInput>;
};

export type ClinicPetAlertCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertCreateManyClinicPetInputEnvelope = {
  data: Array<ClinicPetAlertCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetAlertCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ClinicPetAlertCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetAlertCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetAlertCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
};

export type ClinicPetAlertCreateOrConnectWithoutClinicPetInput = {
  where: ClinicPetAlertWhereUniqueInput;
  create: ClinicPetAlertUncheckedCreateWithoutClinicPetInput;
};

export type ClinicPetAlertCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertListRelationFilter = {
  every?: Maybe<ClinicPetAlertWhereInput>;
  some?: Maybe<ClinicPetAlertWhereInput>;
  none?: Maybe<ClinicPetAlertWhereInput>;
};

export type ClinicPetAlertMaxAggregateOutputType = {
  __typename?: 'ClinicPetAlertMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetAlertMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ClinicPetAlertMinAggregateOutputType = {
  __typename?: 'ClinicPetAlertMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetAlertMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ClinicPetAlertOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetAlertOrderByRelevanceFieldEnum {
  Id = 'id',
  Value = 'value',
  PimsId = 'pimsId',
  ClinicPetId = 'clinicPetId'
}

export type ClinicPetAlertOrderByRelevanceInput = {
  fields: Array<ClinicPetAlertOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPetAlertOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPetAlertCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetAlertMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetAlertMinOrderByAggregateInput>;
};

export type ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicPetAlertOrderByRelevanceInput>;
};

export enum ClinicPetAlertScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Value = 'value',
  PimsId = 'pimsId',
  PimsDate = 'pimsDate',
  IsMedical = 'isMedical',
  ClinicPetId = 'clinicPetId',
  RawPimsValue = 'rawPimsValue'
}

export type ClinicPetAlertScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  pimsDate?: Maybe<DateTimeNullableFilter>;
  isMedical?: Maybe<BoolNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ClinicPetAlertScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetAlertScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetAlertScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetAlertScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  value?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  pimsDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isMedical?: Maybe<BoolNullableWithAggregatesFilter>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicPetAlertUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ClinicPetAlertCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetAlertCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetAlertCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
};

export type ClinicPetAlertUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUncheckedUpdateManyWithoutAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<ClinicPetAlertCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetAlertCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ClinicPetAlertUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetAlertCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetAlertUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ClinicPetAlertUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
};

export type ClinicPetAlertUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAlertsNestedInput>;
};

export type ClinicPetAlertUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUpdateManyWithWhereWithoutClinicPetInput = {
  where: ClinicPetAlertScalarWhereInput;
  data: ClinicPetAlertUncheckedUpdateManyWithoutAlertsInput;
};

export type ClinicPetAlertUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<ClinicPetAlertCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetAlertCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ClinicPetAlertUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetAlertCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetAlertUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ClinicPetAlertUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
};

export type ClinicPetAlertUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: ClinicPetAlertWhereUniqueInput;
  data: ClinicPetAlertUncheckedUpdateWithoutClinicPetInput;
};

export type ClinicPetAlertUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: ClinicPetAlertWhereUniqueInput;
  update: ClinicPetAlertUncheckedUpdateWithoutClinicPetInput;
  create: ClinicPetAlertUncheckedCreateWithoutClinicPetInput;
};

export type ClinicPetAlertWhereInput = {
  AND?: Maybe<Array<ClinicPetAlertWhereInput>>;
  OR?: Maybe<Array<ClinicPetAlertWhereInput>>;
  NOT?: Maybe<Array<ClinicPetAlertWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  pimsDate?: Maybe<DateTimeNullableFilter>;
  isMedical?: Maybe<BoolNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
};

export type ClinicPetAlertWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicPetAvgAggregateOutputType = {
  __typename?: 'ClinicPetAvgAggregateOutputType';
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
};

export type ClinicPetAvgOrderByAggregateInput = {
  weight?: Maybe<SortOrder>;
  weightInKg?: Maybe<SortOrder>;
};

export type ClinicPetClinicPetPimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type ClinicPetCountAggregateOutputType = {
  __typename?: 'ClinicPetCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  breed: Scalars['Int'];
  breedId: Scalars['Int'];
  color: Scalars['Int'];
  colorId: Scalars['Int'];
  dateOfBirth: Scalars['Int'];
  dateOfDeath: Scalars['Int'];
  gender: Scalars['Int'];
  sexId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeceased: Scalars['Int'];
  isDeleted: Scalars['Int'];
  name: Scalars['Int'];
  pimsId: Scalars['Int'];
  species: Scalars['Int'];
  speciesId: Scalars['Int'];
  speciesType: Scalars['Int'];
  weight: Scalars['Int'];
  weightUnit: Scalars['Int'];
  weightInKg: Scalars['Int'];
  photoUrl: Scalars['Int'];
  isPrivacyProtected: Scalars['Int'];
  isSubscribedToReminders: Scalars['Int'];
  mediaId: Scalars['Int'];
  clinicId: Scalars['Int'];
  integrationId: Scalars['Int'];
  organizationPetId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPetCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  breedId?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  colorId?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  sexId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  speciesType?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
  weightInKg?: Maybe<SortOrder>;
  photoUrl?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ClinicPetCountOutputType = {
  __typename?: 'ClinicPetCountOutputType';
  appointments: Scalars['Int'];
  alerts: Scalars['Int'];
  formSubmissions: Scalars['Int'];
  petParents: Scalars['Int'];
  serviceReminders: Scalars['Int'];
  channels: Scalars['Int'];
  invoiceLineItems: Scalars['Int'];
  careBenefitUsages: Scalars['Int'];
  automationRunActions: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  vaccinations: Scalars['Int'];
  prescriptions: Scalars['Int'];
  clinicPetPimsSyncStatus: Scalars['Int'];
  receivedTrupanionExamDayOffers: Scalars['Int'];
  trupanionCertificateAvailabilities: Scalars['Int'];
  customFieldValues: Scalars['Int'];
  HillsToHomeAPILog: Scalars['Int'];
};

export type ClinicPetCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateManyBreedModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCreateManyBreedModelInputEnvelope = {
  data: Array<ClinicPetCreateManyBreedModelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCreateManyClinicInputEnvelope = {
  data: Array<ClinicPetCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManyColorModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCreateManyColorModelInputEnvelope = {
  data: Array<ClinicPetCreateManyColorModelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicPetCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManyMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCreateManyMediaInputEnvelope = {
  data: Array<ClinicPetCreateManyMediaInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManyOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCreateManyOrganizationPetInputEnvelope = {
  data: Array<ClinicPetCreateManyOrganizationPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManySexModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCreateManySexModelInputEnvelope = {
  data: Array<ClinicPetCreateManySexModelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManySpeciesModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCreateManySpeciesModelInputEnvelope = {
  data: Array<ClinicPetCreateManySpeciesModelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateNestedManyWithoutBreedModelInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutBreedModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutBreedModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyBreedModelInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutChannelsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutChannelsInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutColorModelInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutColorModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutColorModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyColorModelInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutInvoiceLineItemsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutMediaInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutMediaInput>>;
  createMany?: Maybe<ClinicPetCreateManyMediaInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<ClinicPetCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutPetParentsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutPetParentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutPetParentsInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutSexModelInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutSexModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSexModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySexModelInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutSpeciesModelInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutSpeciesModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSpeciesModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySpeciesModelInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedOneWithoutAlertsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutAlertsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAlertsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutAutomationRunActionsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAutomationRunActionsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutCareBenefitUsagesInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutClinicPetPimsSyncStatusInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutClinicPetPimsSyncStatusInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutClinicPetPimsSyncStatusInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutCustomFieldValuesInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutCustomFieldValuesInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutCustomFieldValuesInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutFormSubmissionsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutFormSubmissionsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutHillsToHomeApiLogInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutHillsToHomeApiLogInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutHillsToHomeApiLogInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutPrescriptionsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutPrescriptionsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutReceivedTrupanionExamDayOffersInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutServiceRemindersInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutServiceRemindersInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutServiceRemindersInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutTrupanionCertificateAvailabilitiesInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutTrupanionCertificateAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutTrupanionCertificateAvailabilitiesInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutVaccinationsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutVaccinationsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutVaccinationsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateOrConnectWithoutAlertsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutAlertsInput;
};

export type ClinicPetCreateOrConnectWithoutAppointmentsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicPetCreateOrConnectWithoutAutomationRunActionsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutAutomationRunActionsInput;
};

export type ClinicPetCreateOrConnectWithoutBreedModelInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutBreedModelInput;
};

export type ClinicPetCreateOrConnectWithoutCareBenefitUsagesInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput;
};

export type ClinicPetCreateOrConnectWithoutChannelAutomationStatusesInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type ClinicPetCreateOrConnectWithoutChannelsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutChannelsInput;
};

export type ClinicPetCreateOrConnectWithoutClinicInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutClinicInput;
};

export type ClinicPetCreateOrConnectWithoutClinicPetPimsSyncStatusInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutClinicPetPimsSyncStatusInput;
};

export type ClinicPetCreateOrConnectWithoutColorModelInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutColorModelInput;
};

export type ClinicPetCreateOrConnectWithoutCustomFieldValuesInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutCustomFieldValuesInput;
};

export type ClinicPetCreateOrConnectWithoutFormSubmissionsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutFormSubmissionsInput;
};

export type ClinicPetCreateOrConnectWithoutHillsToHomeApiLogInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutHillsToHomeApiLogInput;
};

export type ClinicPetCreateOrConnectWithoutIntegrationInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutIntegrationInput;
};

export type ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutInvoiceLineItemsInput;
};

export type ClinicPetCreateOrConnectWithoutMediaInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutMediaInput;
};

export type ClinicPetCreateOrConnectWithoutOrganizationPetInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutOrganizationPetInput;
};

export type ClinicPetCreateOrConnectWithoutPetParentsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutPetParentsInput;
};

export type ClinicPetCreateOrConnectWithoutPrescriptionsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicPetCreateOrConnectWithoutReceivedTrupanionExamDayOffersInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput;
};

export type ClinicPetCreateOrConnectWithoutServiceRemindersInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutServiceRemindersInput;
};

export type ClinicPetCreateOrConnectWithoutSexModelInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutSexModelInput;
};

export type ClinicPetCreateOrConnectWithoutSpeciesModelInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutSpeciesModelInput;
};

export type ClinicPetCreateOrConnectWithoutTrupanionCertificateAvailabilitiesInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutTrupanionCertificateAvailabilitiesInput;
};

export type ClinicPetCreateOrConnectWithoutVaccinationsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutVaccinationsInput;
};

export type ClinicPetCreateWithoutAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutBreedModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutCareBenefitUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutClinicPetPimsSyncStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutColorModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutCustomFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutReceivedTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutSexModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutSpeciesModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutTrupanionCertificateAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
};

/** Custom Values specifically for ClinicPets */
export type ClinicPetCustomFieldValue = {
  __typename?: 'ClinicPetCustomFieldValue';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  fieldDefinitionId: Scalars['String'];
  clinicPetId: Scalars['String'];
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  fieldDefinition: CustomFieldDefinition;
  clinicPet: ClinicPet;
};

export type ClinicPetCustomFieldValueCountAggregateOutputType = {
  __typename?: 'ClinicPetCustomFieldValueCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  fieldDefinitionId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  value: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPetCustomFieldValueCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ClinicPetCustomFieldValueCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  fieldDefinition: CustomFieldDefinitionCreateNestedOneWithoutClinicPetFieldValuesInput;
  clinicPet: ClinicPetCreateNestedOneWithoutCustomFieldValuesInput;
};

export type ClinicPetCustomFieldValueCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueCreateManyClinicPetInputEnvelope = {
  data: Array<ClinicPetCustomFieldValueCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCustomFieldValueCreateManyFieldDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId: Scalars['String'];
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueCreateManyFieldDefinitionInputEnvelope = {
  data: Array<ClinicPetCustomFieldValueCreateManyFieldDefinitionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCustomFieldValueCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  clinicPetId: Scalars['String'];
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
};

export type ClinicPetCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput = {
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
};

export type ClinicPetCustomFieldValueCreateOrConnectWithoutClinicPetInput = {
  where: ClinicPetCustomFieldValueWhereUniqueInput;
  create: ClinicPetCustomFieldValueUncheckedCreateWithoutClinicPetInput;
};

export type ClinicPetCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput = {
  where: ClinicPetCustomFieldValueWhereUniqueInput;
  create: ClinicPetCustomFieldValueUncheckedCreateWithoutFieldDefinitionInput;
};

export type ClinicPetCustomFieldValueCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  fieldDefinition: CustomFieldDefinitionCreateNestedOneWithoutClinicPetFieldValuesInput;
};

export type ClinicPetCustomFieldValueCreateWithoutFieldDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPet: ClinicPetCreateNestedOneWithoutCustomFieldValuesInput;
};

export type ClinicPetCustomFieldValueListRelationFilter = {
  every?: Maybe<ClinicPetCustomFieldValueWhereInput>;
  some?: Maybe<ClinicPetCustomFieldValueWhereInput>;
  none?: Maybe<ClinicPetCustomFieldValueWhereInput>;
};

export type ClinicPetCustomFieldValueMaxAggregateOutputType = {
  __typename?: 'ClinicPetCustomFieldValueMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetCustomFieldValueMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ClinicPetCustomFieldValueMinAggregateOutputType = {
  __typename?: 'ClinicPetCustomFieldValueMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetCustomFieldValueMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ClinicPetCustomFieldValueOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetCustomFieldValueOrderByRelevanceFieldEnum {
  Id = 'id',
  FieldDefinitionId = 'fieldDefinitionId',
  ClinicPetId = 'clinicPetId'
}

export type ClinicPetCustomFieldValueOrderByRelevanceInput = {
  fields: Array<ClinicPetCustomFieldValueOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPetCustomFieldValueOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPetCustomFieldValueCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetCustomFieldValueMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetCustomFieldValueMinOrderByAggregateInput>;
};

export type ClinicPetCustomFieldValueOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  fieldDefinition?: Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicPetCustomFieldValueOrderByRelevanceInput>;
};

export enum ClinicPetCustomFieldValueScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  FieldDefinitionId = 'fieldDefinitionId',
  ClinicPetId = 'clinicPetId',
  Value = 'value',
  RawPimsValue = 'rawPimsValue'
}

export type ClinicPetCustomFieldValueScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  fieldDefinitionId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  value?: Maybe<JsonFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ClinicPetCustomFieldValueScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  fieldDefinitionId?: Maybe<StringWithAggregatesFilter>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
  value?: Maybe<JsonWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicPetCustomFieldValueUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  clinicPetId: Scalars['String'];
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
};

export type ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput = {
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
};

export type ClinicPetCustomFieldValueUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUncheckedCreateWithoutFieldDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId: Scalars['String'];
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ClinicPetCustomFieldValueUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetCustomFieldValueUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ClinicPetCustomFieldValueUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereInput>>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateManyWithoutCustomFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput = {
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  upsert?: Maybe<Array<ClinicPetCustomFieldValueUpsertWithWhereUniqueWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
  set?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetCustomFieldValueUpdateWithWhereUniqueWithoutFieldDefinitionInput>>;
  updateMany?: Maybe<Array<ClinicPetCustomFieldValueUpdateManyWithWhereWithoutFieldDefinitionInput>>;
  deleteMany?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereInput>>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateWithoutFieldDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  fieldDefinition?: Maybe<CustomFieldDefinitionUpdateOneRequiredWithoutClinicPetFieldValuesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCustomFieldValuesNestedInput>;
};

export type ClinicPetCustomFieldValueUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUpdateManyWithWhereWithoutClinicPetInput = {
  where: ClinicPetCustomFieldValueScalarWhereInput;
  data: ClinicPetCustomFieldValueUncheckedUpdateManyWithoutCustomFieldValuesInput;
};

export type ClinicPetCustomFieldValueUpdateManyWithWhereWithoutFieldDefinitionInput = {
  where: ClinicPetCustomFieldValueScalarWhereInput;
  data: ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetFieldValuesInput;
};

export type ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ClinicPetCustomFieldValueUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetCustomFieldValueUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ClinicPetCustomFieldValueUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereInput>>;
};

export type ClinicPetCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput = {
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  upsert?: Maybe<Array<ClinicPetCustomFieldValueUpsertWithWhereUniqueWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
  set?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetCustomFieldValueUpdateWithWhereUniqueWithoutFieldDefinitionInput>>;
  updateMany?: Maybe<Array<ClinicPetCustomFieldValueUpdateManyWithWhereWithoutFieldDefinitionInput>>;
  deleteMany?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereInput>>;
};

export type ClinicPetCustomFieldValueUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: ClinicPetCustomFieldValueWhereUniqueInput;
  data: ClinicPetCustomFieldValueUncheckedUpdateWithoutClinicPetInput;
};

export type ClinicPetCustomFieldValueUpdateWithWhereUniqueWithoutFieldDefinitionInput = {
  where: ClinicPetCustomFieldValueWhereUniqueInput;
  data: ClinicPetCustomFieldValueUncheckedUpdateWithoutFieldDefinitionInput;
};

export type ClinicPetCustomFieldValueUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  fieldDefinition?: Maybe<CustomFieldDefinitionUpdateOneRequiredWithoutClinicPetFieldValuesNestedInput>;
};

export type ClinicPetCustomFieldValueUpdateWithoutFieldDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCustomFieldValuesNestedInput>;
};

export type ClinicPetCustomFieldValueUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: ClinicPetCustomFieldValueWhereUniqueInput;
  update: ClinicPetCustomFieldValueUncheckedUpdateWithoutClinicPetInput;
  create: ClinicPetCustomFieldValueUncheckedCreateWithoutClinicPetInput;
};

export type ClinicPetCustomFieldValueUpsertWithWhereUniqueWithoutFieldDefinitionInput = {
  where: ClinicPetCustomFieldValueWhereUniqueInput;
  update: ClinicPetCustomFieldValueUncheckedUpdateWithoutFieldDefinitionInput;
  create: ClinicPetCustomFieldValueUncheckedCreateWithoutFieldDefinitionInput;
};

export type ClinicPetCustomFieldValueWhereInput = {
  AND?: Maybe<Array<ClinicPetCustomFieldValueWhereInput>>;
  OR?: Maybe<Array<ClinicPetCustomFieldValueWhereInput>>;
  NOT?: Maybe<Array<ClinicPetCustomFieldValueWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  fieldDefinitionId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  value?: Maybe<JsonFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  fieldDefinition?: Maybe<CustomFieldDefinitionWhereInput>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
};

export type ClinicPetCustomFieldValueWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicPetListRelationFilter = {
  every?: Maybe<ClinicPetWhereInput>;
  some?: Maybe<ClinicPetWhereInput>;
  none?: Maybe<ClinicPetWhereInput>;
};

export type ClinicPetMaxAggregateOutputType = {
  __typename?: 'ClinicPetMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  breedId?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  colorId?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  sexId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  speciesType?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
  weightInKg?: Maybe<SortOrder>;
  photoUrl?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
};

export type ClinicPetMinAggregateOutputType = {
  __typename?: 'ClinicPetMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  breedId?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  colorId?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  sexId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  speciesType?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
  weightInKg?: Maybe<SortOrder>;
  photoUrl?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
};

export type ClinicPetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetOrderByRelevanceFieldEnum {
  Id = 'id',
  Breed = 'breed',
  BreedId = 'breedId',
  Color = 'color',
  ColorId = 'colorId',
  Gender = 'gender',
  SexId = 'sexId',
  Name = 'name',
  PimsId = 'pimsId',
  Species = 'species',
  SpeciesId = 'speciesId',
  WeightUnit = 'weightUnit',
  PhotoUrl = 'photoUrl',
  MediaId = 'mediaId',
  ClinicId = 'clinicId',
  IntegrationId = 'integrationId',
  OrganizationPetId = 'organizationPetId'
}

export type ClinicPetOrderByRelevanceInput = {
  fields: Array<ClinicPetOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPetOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  breedId?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  colorId?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  sexId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  speciesType?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
  weightInKg?: Maybe<SortOrder>;
  photoUrl?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPetCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicPetAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicPetMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicPetSumOrderByAggregateInput>;
};

export type ClinicPetOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  breedId?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  colorId?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  sexId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  speciesType?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
  weightInKg?: Maybe<SortOrder>;
  photoUrl?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  organizationPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  alerts?: Maybe<ClinicPetAlertOrderByRelationAggregateInput>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  petParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  serviceReminders?: Maybe<ServiceReminderOrderByRelationAggregateInput>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  automationRunActions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  vaccinations?: Maybe<VaccinationOrderByRelationAggregateInput>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusOrderByRelationAggregateInput>;
  breedModel?: Maybe<BreedOrderByWithRelationAndSearchRelevanceInput>;
  sexModel?: Maybe<SexOrderByWithRelationAndSearchRelevanceInput>;
  colorModel?: Maybe<ColorOrderByWithRelationAndSearchRelevanceInput>;
  speciesModel?: Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>;
  media?: Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferOrderByRelationAggregateInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityOrderByRelationAggregateInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueOrderByRelationAggregateInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicPetOrderByRelevanceInput>;
};

export type ClinicPetParent = {
  __typename?: 'ClinicPetParent';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  /** This is the user-readable ID from the PIMS if one exists */
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  /** StripeCustomer can be shared between multiple petParent records - not unique */
  stripeCustomerId?: Maybe<Scalars['String']>;
  /** Linked to User for testing and onboarding purposes */
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
  integration?: Maybe<ClinicPimsIntegration>;
  petParentSetting?: Maybe<PetParentSetting>;
  organizationPetParent?: Maybe<OrganizationPetParent>;
  stripeCustomer?: Maybe<StripeCustomer>;
  user?: Maybe<User>;
  channelMembers: Array<ChannelMember>;
  phoneNumbers: Array<ClinicEntityPhoneNumber>;
  addresses: Array<ClinicPetParentAddress>;
  clinicWidgetRequests: Array<ClinicWidgetRequest>;
  formSubmissions: Array<FormSubmission>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  stripePaymentMethods: Array<StripePaymentMethod>;
  appointments: Array<Appointment>;
  pets: Array<ClinicPet>;
  serviceReminders: Array<ServiceReminder>;
  invoices: Array<Invoice>;
  calls: Array<CallParticipant>;
  emailProviderLogs: Array<EmailProviderLog>;
  careAuthorizationRequests: Array<CareAuthorizationRequest>;
  automationRunActions: Array<AutomationRunAction>;
  channelCreationSources: Array<ChannelCreationSource>;
  automationRuns: Array<AutomationRun>;
  massTextAlertEntries: Array<MassTextAlertEntry>;
  surveyResults: Array<SurveyResult>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolder>;
  clinicPetParentPimSyncStatus: Array<ClinicPetParentPimsSyncStatus>;
  globalPetParent?: Maybe<GlobalPetParent>;
  devices: Array<ClinicPetParentDevice>;
  receivedTrupanionExamDayOffers: Array<SentTrupanionExamDayOffer>;
  lapsedPetParentTriggers: Array<LapsedPetParentTriggers>;
  careAccountCreditIntents: Array<CareAccountCreditIntent>;
  EmailCampaignLog: Array<EmailCampaignLog>;
  EmailCampaignUnsubscribe: Array<EmailCampaignUnsubscribe>;
  bitwerxPaymentHealthcheckResults: Array<BitwerxPaymentHealthcheckResult>;
  customFieldValues: Array<ClinicPetParentCustomFieldValue>;
  HillsToHomeAPILog: Array<HillsToHomeApiLog>;
  emailCampaignCustomListRecipients: Array<EmailCampaignCustomListRecipient>;
  informControlGroupParticipants: Array<InformControlGroupParticipant>;
  _count: ClinicPetParentCountOutputType;
};


export type ClinicPetParentChannelMembersArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
  orderBy?: Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMemberScalarFieldEnum>;
};


export type ClinicPetParentPhoneNumbersArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  orderBy?: Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEntityPhoneNumberScalarFieldEnum>;
};


export type ClinicPetParentAddressesArgs = {
  where?: Maybe<ClinicPetParentAddressWhereInput>;
  orderBy?: Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentAddressScalarFieldEnum>;
};


export type ClinicPetParentClinicWidgetRequestsArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestScalarFieldEnum>;
};


export type ClinicPetParentFormSubmissionsArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
};


export type ClinicPetParentStripePaymentIntentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};


export type ClinicPetParentStripePaymentMethodsArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
  orderBy?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentMethodScalarFieldEnum>;
};


export type ClinicPetParentAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};


export type ClinicPetParentPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};


export type ClinicPetParentServiceRemindersArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderScalarFieldEnum>;
};


export type ClinicPetParentInvoicesArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
};


export type ClinicPetParentCallsArgs = {
  where?: Maybe<CallParticipantWhereInput>;
  orderBy?: Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CallParticipantScalarFieldEnum>;
};


export type ClinicPetParentEmailProviderLogsArgs = {
  where?: Maybe<EmailProviderLogWhereInput>;
  orderBy?: Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailProviderLogScalarFieldEnum>;
};


export type ClinicPetParentCareAuthorizationRequestsArgs = {
  where?: Maybe<CareAuthorizationRequestWhereInput>;
  orderBy?: Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAuthorizationRequestScalarFieldEnum>;
};


export type ClinicPetParentAutomationRunActionsArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
};


export type ClinicPetParentChannelCreationSourcesArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
};


export type ClinicPetParentAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};


export type ClinicPetParentMassTextAlertEntriesArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
  orderBy?: Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MassTextAlertEntryScalarFieldEnum>;
};


export type ClinicPetParentSurveyResultsArgs = {
  where?: Maybe<SurveyResultWhereInput>;
  orderBy?: Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SurveyResultScalarFieldEnum>;
};


export type ClinicPetParentClinicPetParentPimSyncStatusArgs = {
  where?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
  orderBy?: Maybe<ClinicPetParentPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentPimsSyncStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentPimsSyncStatusScalarFieldEnum>;
};


export type ClinicPetParentDevicesArgs = {
  where?: Maybe<ClinicPetParentDeviceWhereInput>;
  orderBy?: Maybe<ClinicPetParentDeviceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentDeviceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentDeviceScalarFieldEnum>;
};


export type ClinicPetParentReceivedTrupanionExamDayOffersArgs = {
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
  orderBy?: Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SentTrupanionExamDayOfferScalarFieldEnum>;
};


export type ClinicPetParentLapsedPetParentTriggersArgs = {
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
  orderBy?: Maybe<LapsedPetParentTriggersOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LapsedPetParentTriggersWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LapsedPetParentTriggersScalarFieldEnum>;
};


export type ClinicPetParentCareAccountCreditIntentsArgs = {
  where?: Maybe<CareAccountCreditIntentWhereInput>;
  orderBy?: Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAccountCreditIntentScalarFieldEnum>;
};


export type ClinicPetParentEmailCampaignLogArgs = {
  where?: Maybe<EmailCampaignLogWhereInput>;
  orderBy?: Maybe<EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignLogScalarFieldEnum>;
};


export type ClinicPetParentEmailCampaignUnsubscribeArgs = {
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
  orderBy?: Maybe<EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignUnsubscribeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignUnsubscribeScalarFieldEnum>;
};


export type ClinicPetParentBitwerxPaymentHealthcheckResultsArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
  orderBy?: Maybe<BitwerxPaymentHealthcheckResultOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BitwerxPaymentHealthcheckResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BitwerxPaymentHealthcheckResultScalarFieldEnum>;
};


export type ClinicPetParentCustomFieldValuesArgs = {
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
  orderBy?: Maybe<ClinicPetParentCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentCustomFieldValueWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentCustomFieldValueScalarFieldEnum>;
};


export type ClinicPetParentHillsToHomeApiLogArgs = {
  where?: Maybe<HillsToHomeApiLogWhereInput>;
  orderBy?: Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<HillsToHomeApiLogScalarFieldEnum>;
};


export type ClinicPetParentEmailCampaignCustomListRecipientsArgs = {
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
  orderBy?: Maybe<EmailCampaignCustomListRecipientOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignCustomListRecipientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignCustomListRecipientScalarFieldEnum>;
};


export type ClinicPetParentInformControlGroupParticipantsArgs = {
  where?: Maybe<InformControlGroupParticipantWhereInput>;
  orderBy?: Maybe<InformControlGroupParticipantOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformControlGroupParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformControlGroupParticipantScalarFieldEnum>;
};

export type ClinicPetParentAddress = {
  __typename?: 'ClinicPetParentAddress';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvince: Scalars['String'];
  postalCode: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParent: ClinicPetParent;
};

export type ClinicPetParentAddressCountAggregateOutputType = {
  __typename?: 'ClinicPetParentAddressCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  pimsId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isPrimary: Scalars['Int'];
  addressType: Scalars['Int'];
  line1: Scalars['Int'];
  line2: Scalars['Int'];
  city: Scalars['Int'];
  stateProvince: Scalars['Int'];
  postalCode: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  creationSource: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPetParentAddressCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  addressType?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ClinicPetParentAddressCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvince: Scalars['String'];
  postalCode: Scalars['String'];
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAddressesInput;
};

export type ClinicPetParentAddressCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvince: Scalars['String'];
  postalCode: Scalars['String'];
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicPetParentAddressCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentAddressCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvince: Scalars['String'];
  postalCode: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicPetParentAddressCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
};

export type ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput = {
  where: ClinicPetParentAddressWhereUniqueInput;
  create: ClinicPetParentAddressUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicPetParentAddressCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvince: Scalars['String'];
  postalCode: Scalars['String'];
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressListRelationFilter = {
  every?: Maybe<ClinicPetParentAddressWhereInput>;
  some?: Maybe<ClinicPetParentAddressWhereInput>;
  none?: Maybe<ClinicPetParentAddressWhereInput>;
};

export type ClinicPetParentAddressMaxAggregateOutputType = {
  __typename?: 'ClinicPetParentAddressMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
};

export type ClinicPetParentAddressMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  addressType?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
};

export type ClinicPetParentAddressMinAggregateOutputType = {
  __typename?: 'ClinicPetParentAddressMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
};

export type ClinicPetParentAddressMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  addressType?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
};

export type ClinicPetParentAddressOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetParentAddressOrderByRelevanceFieldEnum {
  Id = 'id',
  PimsId = 'pimsId',
  Line1 = 'line1',
  Line2 = 'line2',
  City = 'city',
  StateProvince = 'stateProvince',
  PostalCode = 'postalCode',
  ClinicPetParentId = 'clinicPetParentId'
}

export type ClinicPetParentAddressOrderByRelevanceInput = {
  fields: Array<ClinicPetParentAddressOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPetParentAddressOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  addressType?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPetParentAddressCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetParentAddressMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetParentAddressMinOrderByAggregateInput>;
};

export type ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  addressType?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicPetParentAddressOrderByRelevanceInput>;
};

export enum ClinicPetParentAddressScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PimsId = 'pimsId',
  IsDeleted = 'isDeleted',
  IsPrimary = 'isPrimary',
  AddressType = 'addressType',
  Line1 = 'line1',
  Line2 = 'line2',
  City = 'city',
  StateProvince = 'stateProvince',
  PostalCode = 'postalCode',
  ClinicPetParentId = 'clinicPetParentId',
  CreationSource = 'creationSource',
  RawPimsValue = 'rawPimsValue'
}

export type ClinicPetParentAddressScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isPrimary?: Maybe<BoolNullableFilter>;
  addressType?: Maybe<EnumClinicPetParentAddressTypeNullableFilter>;
  line1?: Maybe<StringFilter>;
  line2?: Maybe<StringNullableFilter>;
  city?: Maybe<StringFilter>;
  stateProvince?: Maybe<StringFilter>;
  postalCode?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ClinicPetParentAddressScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetParentAddressScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetParentAddressScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetParentAddressScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  isPrimary?: Maybe<BoolNullableWithAggregatesFilter>;
  addressType?: Maybe<EnumClinicPetParentAddressTypeNullableWithAggregatesFilter>;
  line1?: Maybe<StringWithAggregatesFilter>;
  line2?: Maybe<StringNullableWithAggregatesFilter>;
  city?: Maybe<StringWithAggregatesFilter>;
  stateProvince?: Maybe<StringWithAggregatesFilter>;
  postalCode?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum ClinicPetParentAddressType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Billing = 'Billing',
  Work = 'Work',
  Unknown = 'Unknown'
}

export type ClinicPetParentAddressUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvince: Scalars['String'];
  postalCode: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicPetParentAddressCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
};

export type ClinicPetParentAddressUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvince: Scalars['String'];
  postalCode: Scalars['String'];
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUncheckedUpdateManyWithoutAddressesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ClinicPetParentAddressCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentAddressUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentAddressUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentAddressUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
};

export type ClinicPetParentAddressUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUniquePimsAddressPerPetParentCompoundUniqueInput = {
  clinicPetParentId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type ClinicPetParentAddressUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAddressesNestedInput>;
};

export type ClinicPetParentAddressUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ClinicPetParentAddressScalarWhereInput;
  data: ClinicPetParentAddressUncheckedUpdateManyWithoutAddressesInput;
};

export type ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ClinicPetParentAddressCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentAddressUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentAddressUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentAddressUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
};

export type ClinicPetParentAddressUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicPetParentAddressWhereUniqueInput;
  data: ClinicPetParentAddressUncheckedUpdateWithoutClinicPetParentInput;
};

export type ClinicPetParentAddressUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicPetParentAddressWhereUniqueInput;
  update: ClinicPetParentAddressUncheckedUpdateWithoutClinicPetParentInput;
  create: ClinicPetParentAddressUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicPetParentAddressWhereInput = {
  AND?: Maybe<Array<ClinicPetParentAddressWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentAddressWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentAddressWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isPrimary?: Maybe<BoolNullableFilter>;
  addressType?: Maybe<EnumClinicPetParentAddressTypeNullableFilter>;
  line1?: Maybe<StringFilter>;
  line2?: Maybe<StringNullableFilter>;
  city?: Maybe<StringFilter>;
  stateProvince?: Maybe<StringFilter>;
  postalCode?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
};

export type ClinicPetParentAddressWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  uniquePimsAddressPerPetParent?: Maybe<ClinicPetParentAddressUniquePimsAddressPerPetParentCompoundUniqueInput>;
};

export type ClinicPetParentAuthLinkResponse = {
  __typename?: 'ClinicPetParentAuthLinkResponse';
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type ClinicPetParentClinicPetParentPimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type ClinicPetParentCountAggregateOutputType = {
  __typename?: 'ClinicPetParentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  email: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isTest: Scalars['Int'];
  isPrivacyProtected: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  pimsId: Scalars['Int'];
  sourcePimsId: Scalars['Int'];
  firstName: Scalars['Int'];
  lastName: Scalars['Int'];
  isVerified: Scalars['Int'];
  creationSource: Scalars['Int'];
  pimsPhoneNumber: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  clinicId: Scalars['Int'];
  integrationId: Scalars['Int'];
  petParentSettingId: Scalars['Int'];
  organizationPetParentId: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  userId: Scalars['Int'];
  globalPetParentId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPetParentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isTest?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  sourcePimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  petParentSettingId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  globalPetParentId?: Maybe<SortOrder>;
};

export type ClinicPetParentCountOutputType = {
  __typename?: 'ClinicPetParentCountOutputType';
  channelMembers: Scalars['Int'];
  phoneNumbers: Scalars['Int'];
  addresses: Scalars['Int'];
  clinicWidgetRequests: Scalars['Int'];
  formSubmissions: Scalars['Int'];
  stripePaymentIntents: Scalars['Int'];
  stripePaymentMethods: Scalars['Int'];
  appointments: Scalars['Int'];
  pets: Scalars['Int'];
  serviceReminders: Scalars['Int'];
  invoices: Scalars['Int'];
  calls: Scalars['Int'];
  emailProviderLogs: Scalars['Int'];
  careAuthorizationRequests: Scalars['Int'];
  automationRunActions: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  automationRuns: Scalars['Int'];
  massTextAlertEntries: Scalars['Int'];
  surveyResults: Scalars['Int'];
  clinicPetParentPimSyncStatus: Scalars['Int'];
  devices: Scalars['Int'];
  receivedTrupanionExamDayOffers: Scalars['Int'];
  lapsedPetParentTriggers: Scalars['Int'];
  careAccountCreditIntents: Scalars['Int'];
  EmailCampaignLog: Scalars['Int'];
  EmailCampaignUnsubscribe: Scalars['Int'];
  bitwerxPaymentHealthcheckResults: Scalars['Int'];
  customFieldValues: Scalars['Int'];
  HillsToHomeAPILog: Scalars['Int'];
  emailCampaignCustomListRecipients: Scalars['Int'];
  informControlGroupParticipants: Scalars['Int'];
};

export type ClinicPetParentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyClinicInputEnvelope = {
  data: Array<ClinicPetParentCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyGlobalPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyGlobalPetParentInputEnvelope = {
  data: Array<ClinicPetParentCreateManyGlobalPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicPetParentCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyOrganizationPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyOrganizationPetParentInputEnvelope = {
  data: Array<ClinicPetParentCreateManyOrganizationPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyPetParentSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyPetParentSettingInputEnvelope = {
  data: Array<ClinicPetParentCreateManyPetParentSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyStripeCustomerInputEnvelope = {
  data: Array<ClinicPetParentCreateManyStripeCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyUserInputEnvelope = {
  data: Array<ClinicPetParentCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateNestedManyWithoutAppointmentsInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutAppointmentsInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutGlobalPetParentInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutGlobalPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutGlobalPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyGlobalPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyOrganizationPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutPetParentSettingInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetParentSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetParentSettingInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyPetParentSettingInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutPetsInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetsInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedOneWithoutAddressesInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAddressesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAddressesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAutomationRunActionsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutAutomationRunsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAutomationRunsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutBitwerxPaymentHealthcheckResultsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutBitwerxPaymentHealthcheckResultsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutBitwerxPaymentHealthcheckResultsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutCallsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCallsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCallsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutCareAccountCreditIntentsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCareAccountCreditIntentsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCareAccountCreditIntentsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutCareAuthorizationRequestsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCareAuthorizationRequestsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutChannelCreationSourcesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutChannelMembersInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutChannelMembersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutChannelMembersInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutClinicPetParentPimSyncStatusInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutClinicPetParentPimSyncStatusInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutClinicPetParentPimSyncStatusInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutClinicWidgetRequestsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutCustomFieldValuesInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCustomFieldValuesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCustomFieldValuesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutDevicesInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutDevicesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutDevicesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutEmailCampaignCustomListRecipientsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailCampaignCustomListRecipientsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailCampaignCustomListRecipientsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutEmailCampaignLogInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailCampaignLogInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailCampaignLogInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutEmailCampaignUnsubscribeInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailCampaignUnsubscribeInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailCampaignUnsubscribeInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutEmailProviderLogsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailProviderLogsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutFormSubmissionsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutHillsToHomeApiLogInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutHillsToHomeApiLogInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutHillsToHomeApiLogInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutInformControlGroupParticipantsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutInformControlGroupParticipantsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutInformControlGroupParticipantsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutInvoicesInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutInvoicesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutLapsedPetParentTriggersInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutLapsedPetParentTriggersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutLapsedPetParentTriggersInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutLoyaltyAccountHolderInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutLoyaltyAccountHolderInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutLoyaltyAccountHolderInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutMassTextAlertEntriesInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutMassTextAlertEntriesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutPhoneNumbersInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutPhoneNumbersInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutReceivedTrupanionExamDayOffersInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutServiceRemindersInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutServiceRemindersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutServiceRemindersInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutStripePaymentIntentsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutStripePaymentMethodsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutSurveyResultsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutSurveyResultsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutSurveyResultsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateOrConnectWithoutAddressesInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutAddressesInput;
};

export type ClinicPetParentCreateOrConnectWithoutAppointmentsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicPetParentCreateOrConnectWithoutAutomationRunActionsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput;
};

export type ClinicPetParentCreateOrConnectWithoutAutomationRunsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutAutomationRunsInput;
};

export type ClinicPetParentCreateOrConnectWithoutBitwerxPaymentHealthcheckResultsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutBitwerxPaymentHealthcheckResultsInput;
};

export type ClinicPetParentCreateOrConnectWithoutCallsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutCallsInput;
};

export type ClinicPetParentCreateOrConnectWithoutCareAccountCreditIntentsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutCareAccountCreditIntentsInput;
};

export type ClinicPetParentCreateOrConnectWithoutCareAuthorizationRequestsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput;
};

export type ClinicPetParentCreateOrConnectWithoutChannelCreationSourcesInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type ClinicPetParentCreateOrConnectWithoutChannelMembersInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutChannelMembersInput;
};

export type ClinicPetParentCreateOrConnectWithoutClinicInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutClinicInput;
};

export type ClinicPetParentCreateOrConnectWithoutClinicPetParentPimSyncStatusInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutClinicPetParentPimSyncStatusInput;
};

export type ClinicPetParentCreateOrConnectWithoutClinicWidgetRequestsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type ClinicPetParentCreateOrConnectWithoutCustomFieldValuesInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutCustomFieldValuesInput;
};

export type ClinicPetParentCreateOrConnectWithoutDevicesInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutDevicesInput;
};

export type ClinicPetParentCreateOrConnectWithoutEmailCampaignCustomListRecipientsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutEmailCampaignCustomListRecipientsInput;
};

export type ClinicPetParentCreateOrConnectWithoutEmailCampaignLogInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutEmailCampaignLogInput;
};

export type ClinicPetParentCreateOrConnectWithoutEmailCampaignUnsubscribeInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutEmailCampaignUnsubscribeInput;
};

export type ClinicPetParentCreateOrConnectWithoutEmailProviderLogsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput;
};

export type ClinicPetParentCreateOrConnectWithoutFormSubmissionsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput;
};

export type ClinicPetParentCreateOrConnectWithoutGlobalPetParentInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutGlobalPetParentInput;
};

export type ClinicPetParentCreateOrConnectWithoutHillsToHomeApiLogInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutHillsToHomeApiLogInput;
};

export type ClinicPetParentCreateOrConnectWithoutInformControlGroupParticipantsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutInformControlGroupParticipantsInput;
};

export type ClinicPetParentCreateOrConnectWithoutIntegrationInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutIntegrationInput;
};

export type ClinicPetParentCreateOrConnectWithoutInvoicesInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutInvoicesInput;
};

export type ClinicPetParentCreateOrConnectWithoutLapsedPetParentTriggersInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutLapsedPetParentTriggersInput;
};

export type ClinicPetParentCreateOrConnectWithoutLoyaltyAccountHolderInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutLoyaltyAccountHolderInput;
};

export type ClinicPetParentCreateOrConnectWithoutMassTextAlertEntriesInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput;
};

export type ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutOrganizationPetParentInput;
};

export type ClinicPetParentCreateOrConnectWithoutPetParentSettingInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutPetParentSettingInput;
};

export type ClinicPetParentCreateOrConnectWithoutPetsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutPetsInput;
};

export type ClinicPetParentCreateOrConnectWithoutPhoneNumbersInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput;
};

export type ClinicPetParentCreateOrConnectWithoutReceivedTrupanionExamDayOffersInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput;
};

export type ClinicPetParentCreateOrConnectWithoutServiceRemindersInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutServiceRemindersInput;
};

export type ClinicPetParentCreateOrConnectWithoutStripeCustomerInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutStripeCustomerInput;
};

export type ClinicPetParentCreateOrConnectWithoutStripePaymentIntentsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type ClinicPetParentCreateOrConnectWithoutStripePaymentMethodsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput;
};

export type ClinicPetParentCreateOrConnectWithoutSurveyResultsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutSurveyResultsInput;
};

export type ClinicPetParentCreateOrConnectWithoutUserInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutUserInput;
};

export type ClinicPetParentCreateWithoutAddressesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutBitwerxPaymentHealthcheckResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutCareAccountCreditIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutCareAuthorizationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutClinicPetParentPimSyncStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutCustomFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutDevicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutEmailCampaignCustomListRecipientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutEmailCampaignLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutEmailCampaignUnsubscribeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutEmailProviderLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutGlobalPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutInformControlGroupParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutLapsedPetParentTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutLoyaltyAccountHolderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutOrganizationPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutPetParentSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutReceivedTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutStripePaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
};

/** Custom Values specifically for ClinicPetParent */
export type ClinicPetParentCustomFieldValue = {
  __typename?: 'ClinicPetParentCustomFieldValue';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  fieldDefinitionId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  fieldDefinition: CustomFieldDefinition;
  clinicPetParent: ClinicPetParent;
};

export type ClinicPetParentCustomFieldValueCountAggregateOutputType = {
  __typename?: 'ClinicPetParentCustomFieldValueCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  fieldDefinitionId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  value: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPetParentCustomFieldValueCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ClinicPetParentCustomFieldValueCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  fieldDefinition: CustomFieldDefinitionCreateNestedOneWithoutClinicPetParentFieldValuesInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCustomFieldValuesInput;
};

export type ClinicPetParentCustomFieldValueCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicPetParentCustomFieldValueCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCustomFieldValueCreateManyFieldDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueCreateManyFieldDefinitionInputEnvelope = {
  data: Array<ClinicPetParentCustomFieldValueCreateManyFieldDefinitionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCustomFieldValueCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
};

export type ClinicPetParentCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput = {
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
};

export type ClinicPetParentCustomFieldValueCreateOrConnectWithoutClinicPetParentInput = {
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
  create: ClinicPetParentCustomFieldValueUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicPetParentCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput = {
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
  create: ClinicPetParentCustomFieldValueUncheckedCreateWithoutFieldDefinitionInput;
};

export type ClinicPetParentCustomFieldValueCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  fieldDefinition: CustomFieldDefinitionCreateNestedOneWithoutClinicPetParentFieldValuesInput;
};

export type ClinicPetParentCustomFieldValueCreateWithoutFieldDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCustomFieldValuesInput;
};

export type ClinicPetParentCustomFieldValueListRelationFilter = {
  every?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
  some?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
  none?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
};

export type ClinicPetParentCustomFieldValueMaxAggregateOutputType = {
  __typename?: 'ClinicPetParentCustomFieldValueMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCustomFieldValueMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
};

export type ClinicPetParentCustomFieldValueMinAggregateOutputType = {
  __typename?: 'ClinicPetParentCustomFieldValueMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCustomFieldValueMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
};

export type ClinicPetParentCustomFieldValueOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetParentCustomFieldValueOrderByRelevanceFieldEnum {
  Id = 'id',
  FieldDefinitionId = 'fieldDefinitionId',
  ClinicPetParentId = 'clinicPetParentId'
}

export type ClinicPetParentCustomFieldValueOrderByRelevanceInput = {
  fields: Array<ClinicPetParentCustomFieldValueOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPetParentCustomFieldValueOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPetParentCustomFieldValueCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetParentCustomFieldValueMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetParentCustomFieldValueMinOrderByAggregateInput>;
};

export type ClinicPetParentCustomFieldValueOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  fieldDefinition?: Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicPetParentCustomFieldValueOrderByRelevanceInput>;
};

export enum ClinicPetParentCustomFieldValueScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  FieldDefinitionId = 'fieldDefinitionId',
  ClinicPetParentId = 'clinicPetParentId',
  Value = 'value',
  RawPimsValue = 'rawPimsValue'
}

export type ClinicPetParentCustomFieldValueScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  fieldDefinitionId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  value?: Maybe<JsonFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ClinicPetParentCustomFieldValueScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  fieldDefinitionId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  value?: Maybe<JsonWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicPetParentCustomFieldValueUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
};

export type ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput = {
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
};

export type ClinicPetParentCustomFieldValueUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUncheckedCreateWithoutFieldDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  value: Scalars['Json'];
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentCustomFieldValueUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereInput>>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutCustomFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput = {
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  upsert?: Maybe<Array<ClinicPetParentCustomFieldValueUpsertWithWhereUniqueWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateWithWhereUniqueWithoutFieldDefinitionInput>>;
  updateMany?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateManyWithWhereWithoutFieldDefinitionInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereInput>>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateWithoutFieldDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  fieldDefinition?: Maybe<CustomFieldDefinitionUpdateOneRequiredWithoutClinicPetParentFieldValuesNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCustomFieldValuesNestedInput>;
};

export type ClinicPetParentCustomFieldValueUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ClinicPetParentCustomFieldValueScalarWhereInput;
  data: ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutCustomFieldValuesInput;
};

export type ClinicPetParentCustomFieldValueUpdateManyWithWhereWithoutFieldDefinitionInput = {
  where: ClinicPetParentCustomFieldValueScalarWhereInput;
  data: ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentFieldValuesInput;
};

export type ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentCustomFieldValueUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereInput>>;
};

export type ClinicPetParentCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput = {
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  upsert?: Maybe<Array<ClinicPetParentCustomFieldValueUpsertWithWhereUniqueWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateWithWhereUniqueWithoutFieldDefinitionInput>>;
  updateMany?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateManyWithWhereWithoutFieldDefinitionInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereInput>>;
};

export type ClinicPetParentCustomFieldValueUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
  data: ClinicPetParentCustomFieldValueUncheckedUpdateWithoutClinicPetParentInput;
};

export type ClinicPetParentCustomFieldValueUpdateWithWhereUniqueWithoutFieldDefinitionInput = {
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
  data: ClinicPetParentCustomFieldValueUncheckedUpdateWithoutFieldDefinitionInput;
};

export type ClinicPetParentCustomFieldValueUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  fieldDefinition?: Maybe<CustomFieldDefinitionUpdateOneRequiredWithoutClinicPetParentFieldValuesNestedInput>;
};

export type ClinicPetParentCustomFieldValueUpdateWithoutFieldDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCustomFieldValuesNestedInput>;
};

export type ClinicPetParentCustomFieldValueUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
  update: ClinicPetParentCustomFieldValueUncheckedUpdateWithoutClinicPetParentInput;
  create: ClinicPetParentCustomFieldValueUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicPetParentCustomFieldValueUpsertWithWhereUniqueWithoutFieldDefinitionInput = {
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
  update: ClinicPetParentCustomFieldValueUncheckedUpdateWithoutFieldDefinitionInput;
  create: ClinicPetParentCustomFieldValueUncheckedCreateWithoutFieldDefinitionInput;
};

export type ClinicPetParentCustomFieldValueWhereInput = {
  AND?: Maybe<Array<ClinicPetParentCustomFieldValueWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentCustomFieldValueWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentCustomFieldValueWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  fieldDefinitionId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  value?: Maybe<JsonFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  fieldDefinition?: Maybe<CustomFieldDefinitionWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
};

export type ClinicPetParentCustomFieldValueWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicPetParentDevice = {
  __typename?: 'ClinicPetParentDevice';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicPetParentId: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus: PushNotificationStatus;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  pushModalSkipped: Scalars['Boolean'];
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParent;
  pushNotificationLogs: Array<PushNotificationLog>;
  _count: ClinicPetParentDeviceCountOutputType;
};


export type ClinicPetParentDevicePushNotificationLogsArgs = {
  where?: Maybe<PushNotificationLogWhereInput>;
  orderBy?: Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PushNotificationLogScalarFieldEnum>;
};

export type ClinicPetParentDeviceCountAggregateOutputType = {
  __typename?: 'ClinicPetParentDeviceCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  deviceName: Scalars['Int'];
  pushNotificationStatus: Scalars['Int'];
  os: Scalars['Int'];
  osVersion: Scalars['Int'];
  appVersion: Scalars['Int'];
  model: Scalars['Int'];
  lastLogin: Scalars['Int'];
  manufacturer: Scalars['Int'];
  brand: Scalars['Int'];
  type: Scalars['Int'];
  token: Scalars['Int'];
  isDeleted: Scalars['Int'];
  snsEndpointArn: Scalars['Int'];
  instanceId: Scalars['Int'];
  pushModalSkipped: Scalars['Int'];
  lastPushAlertTimestamp: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPetParentDeviceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  deviceName?: Maybe<SortOrder>;
  pushNotificationStatus?: Maybe<SortOrder>;
  os?: Maybe<SortOrder>;
  osVersion?: Maybe<SortOrder>;
  appVersion?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  lastLogin?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  snsEndpointArn?: Maybe<SortOrder>;
  instanceId?: Maybe<SortOrder>;
  pushModalSkipped?: Maybe<SortOrder>;
  lastPushAlertTimestamp?: Maybe<SortOrder>;
};

export type ClinicPetParentDeviceCountOutputType = {
  __typename?: 'ClinicPetParentDeviceCountOutputType';
  pushNotificationLogs: Scalars['Int'];
};

export type ClinicPetParentDeviceCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutDevicesInput;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicPetParentDeviceInput>;
};

export type ClinicPetParentDeviceCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicPetParentDeviceCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentDeviceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicPetParentDeviceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentDeviceCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentDeviceCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
};

export type ClinicPetParentDeviceCreateNestedOneWithoutPushNotificationLogsInput = {
  create?: Maybe<ClinicPetParentDeviceUncheckedCreateWithoutPushNotificationLogsInput>;
  connectOrCreate?: Maybe<ClinicPetParentDeviceCreateOrConnectWithoutPushNotificationLogsInput>;
  connect?: Maybe<ClinicPetParentDeviceWhereUniqueInput>;
};

export type ClinicPetParentDeviceCreateOrConnectWithoutClinicPetParentInput = {
  where: ClinicPetParentDeviceWhereUniqueInput;
  create: ClinicPetParentDeviceUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicPetParentDeviceCreateOrConnectWithoutPushNotificationLogsInput = {
  where: ClinicPetParentDeviceWhereUniqueInput;
  create: ClinicPetParentDeviceUncheckedCreateWithoutPushNotificationLogsInput;
};

export type ClinicPetParentDeviceCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicPetParentDeviceInput>;
};

export type ClinicPetParentDeviceCreateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutDevicesInput;
};

export type ClinicPetParentDeviceListRelationFilter = {
  every?: Maybe<ClinicPetParentDeviceWhereInput>;
  some?: Maybe<ClinicPetParentDeviceWhereInput>;
  none?: Maybe<ClinicPetParentDeviceWhereInput>;
};

export type ClinicPetParentDeviceMaxAggregateOutputType = {
  __typename?: 'ClinicPetParentDeviceMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  deviceName?: Maybe<SortOrder>;
  pushNotificationStatus?: Maybe<SortOrder>;
  os?: Maybe<SortOrder>;
  osVersion?: Maybe<SortOrder>;
  appVersion?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  lastLogin?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  snsEndpointArn?: Maybe<SortOrder>;
  instanceId?: Maybe<SortOrder>;
  pushModalSkipped?: Maybe<SortOrder>;
  lastPushAlertTimestamp?: Maybe<SortOrder>;
};

export type ClinicPetParentDeviceMinAggregateOutputType = {
  __typename?: 'ClinicPetParentDeviceMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  deviceName?: Maybe<SortOrder>;
  pushNotificationStatus?: Maybe<SortOrder>;
  os?: Maybe<SortOrder>;
  osVersion?: Maybe<SortOrder>;
  appVersion?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  lastLogin?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  snsEndpointArn?: Maybe<SortOrder>;
  instanceId?: Maybe<SortOrder>;
  pushModalSkipped?: Maybe<SortOrder>;
  lastPushAlertTimestamp?: Maybe<SortOrder>;
};

export type ClinicPetParentDeviceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetParentDeviceOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicPetParentId = 'clinicPetParentId',
  DeviceName = 'deviceName',
  OsVersion = 'osVersion',
  AppVersion = 'appVersion',
  Model = 'model',
  Manufacturer = 'manufacturer',
  Brand = 'brand',
  Token = 'token',
  SnsEndpointArn = 'snsEndpointArn',
  InstanceId = 'instanceId'
}

export type ClinicPetParentDeviceOrderByRelevanceInput = {
  fields: Array<ClinicPetParentDeviceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPetParentDeviceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  deviceName?: Maybe<SortOrder>;
  pushNotificationStatus?: Maybe<SortOrder>;
  os?: Maybe<SortOrder>;
  osVersion?: Maybe<SortOrder>;
  appVersion?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  lastLogin?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  snsEndpointArn?: Maybe<SortOrder>;
  instanceId?: Maybe<SortOrder>;
  pushModalSkipped?: Maybe<SortOrder>;
  lastPushAlertTimestamp?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPetParentDeviceCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetParentDeviceMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetParentDeviceMinOrderByAggregateInput>;
};

export type ClinicPetParentDeviceOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  deviceName?: Maybe<SortOrder>;
  pushNotificationStatus?: Maybe<SortOrder>;
  os?: Maybe<SortOrder>;
  osVersion?: Maybe<SortOrder>;
  appVersion?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  lastLogin?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  snsEndpointArn?: Maybe<SortOrder>;
  instanceId?: Maybe<SortOrder>;
  pushModalSkipped?: Maybe<SortOrder>;
  lastPushAlertTimestamp?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicPetParentDeviceOrderByRelevanceInput>;
};

export type ClinicPetParentDeviceRelationFilter = {
  is?: Maybe<ClinicPetParentDeviceWhereInput>;
  isNot?: Maybe<ClinicPetParentDeviceWhereInput>;
};

export enum ClinicPetParentDeviceScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicPetParentId = 'clinicPetParentId',
  DeviceName = 'deviceName',
  PushNotificationStatus = 'pushNotificationStatus',
  Os = 'os',
  OsVersion = 'osVersion',
  AppVersion = 'appVersion',
  Model = 'model',
  LastLogin = 'lastLogin',
  Manufacturer = 'manufacturer',
  Brand = 'brand',
  Type = 'type',
  Token = 'token',
  IsDeleted = 'isDeleted',
  SnsEndpointArn = 'snsEndpointArn',
  InstanceId = 'instanceId',
  PushModalSkipped = 'pushModalSkipped',
  LastPushAlertTimestamp = 'lastPushAlertTimestamp'
}

export type ClinicPetParentDeviceScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetParentDeviceScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentDeviceScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentDeviceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  deviceName?: Maybe<StringNullableFilter>;
  pushNotificationStatus?: Maybe<EnumPushNotificationStatusFilter>;
  os?: Maybe<EnumDeviceOsNullableFilter>;
  osVersion?: Maybe<StringNullableFilter>;
  appVersion?: Maybe<StringNullableFilter>;
  model?: Maybe<StringNullableFilter>;
  lastLogin?: Maybe<DateTimeNullableFilter>;
  manufacturer?: Maybe<StringNullableFilter>;
  brand?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumDeviceTypeNullableFilter>;
  token?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  snsEndpointArn?: Maybe<StringNullableFilter>;
  instanceId?: Maybe<StringFilter>;
  pushModalSkipped?: Maybe<BoolFilter>;
  lastPushAlertTimestamp?: Maybe<DateTimeNullableFilter>;
};

export type ClinicPetParentDeviceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetParentDeviceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetParentDeviceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetParentDeviceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  deviceName?: Maybe<StringNullableWithAggregatesFilter>;
  pushNotificationStatus?: Maybe<EnumPushNotificationStatusWithAggregatesFilter>;
  os?: Maybe<EnumDeviceOsNullableWithAggregatesFilter>;
  osVersion?: Maybe<StringNullableWithAggregatesFilter>;
  appVersion?: Maybe<StringNullableWithAggregatesFilter>;
  model?: Maybe<StringNullableWithAggregatesFilter>;
  lastLogin?: Maybe<DateTimeNullableWithAggregatesFilter>;
  manufacturer?: Maybe<StringNullableWithAggregatesFilter>;
  brand?: Maybe<StringNullableWithAggregatesFilter>;
  type?: Maybe<EnumDeviceTypeNullableWithAggregatesFilter>;
  token?: Maybe<StringNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  snsEndpointArn?: Maybe<StringNullableWithAggregatesFilter>;
  instanceId?: Maybe<StringWithAggregatesFilter>;
  pushModalSkipped?: Maybe<BoolWithAggregatesFilter>;
  lastPushAlertTimestamp?: Maybe<DateTimeNullableWithAggregatesFilter>;
};

export type ClinicPetParentDeviceUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicPetParentDeviceInput>;
};

export type ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicPetParentDeviceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentDeviceCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentDeviceCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
};

export type ClinicPetParentDeviceUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicPetParentDeviceInput>;
};

export type ClinicPetParentDeviceUncheckedCreateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicPetParentDeviceNestedInput>;
};

export type ClinicPetParentDeviceUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ClinicPetParentDeviceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentDeviceCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentDeviceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentDeviceCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentDeviceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentDeviceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentDeviceScalarWhereInput>>;
};

export type ClinicPetParentDeviceUncheckedUpdateManyWithoutDevicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicPetParentDeviceNestedInput>;
};

export type ClinicPetParentDeviceUncheckedUpdateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUniqueInstanceIdPerPetParentCompoundUniqueInput = {
  clinicPetParentId: Scalars['String'];
  instanceId: Scalars['String'];
};

export type ClinicPetParentDeviceUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutDevicesNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicPetParentDeviceNestedInput>;
};

export type ClinicPetParentDeviceUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ClinicPetParentDeviceScalarWhereInput;
  data: ClinicPetParentDeviceUncheckedUpdateManyWithoutDevicesInput;
};

export type ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ClinicPetParentDeviceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentDeviceCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentDeviceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentDeviceCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentDeviceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentDeviceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentDeviceScalarWhereInput>>;
};

export type ClinicPetParentDeviceUpdateOneRequiredWithoutPushNotificationLogsNestedInput = {
  create?: Maybe<ClinicPetParentDeviceUncheckedCreateWithoutPushNotificationLogsInput>;
  connectOrCreate?: Maybe<ClinicPetParentDeviceCreateOrConnectWithoutPushNotificationLogsInput>;
  upsert?: Maybe<ClinicPetParentDeviceUpsertWithoutPushNotificationLogsInput>;
  connect?: Maybe<ClinicPetParentDeviceWhereUniqueInput>;
  update?: Maybe<ClinicPetParentDeviceUncheckedUpdateWithoutPushNotificationLogsInput>;
};

export type ClinicPetParentDeviceUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicPetParentDeviceWhereUniqueInput;
  data: ClinicPetParentDeviceUncheckedUpdateWithoutClinicPetParentInput;
};

export type ClinicPetParentDeviceUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicPetParentDeviceNestedInput>;
};

export type ClinicPetParentDeviceUpdateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  token?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutDevicesNestedInput>;
};

export type ClinicPetParentDeviceUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicPetParentDeviceWhereUniqueInput;
  update: ClinicPetParentDeviceUncheckedUpdateWithoutClinicPetParentInput;
  create: ClinicPetParentDeviceUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicPetParentDeviceUpsertWithoutPushNotificationLogsInput = {
  update: ClinicPetParentDeviceUncheckedUpdateWithoutPushNotificationLogsInput;
  create: ClinicPetParentDeviceUncheckedCreateWithoutPushNotificationLogsInput;
};

export type ClinicPetParentDeviceWhereInput = {
  AND?: Maybe<Array<ClinicPetParentDeviceWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentDeviceWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentDeviceWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  deviceName?: Maybe<StringNullableFilter>;
  pushNotificationStatus?: Maybe<EnumPushNotificationStatusFilter>;
  os?: Maybe<EnumDeviceOsNullableFilter>;
  osVersion?: Maybe<StringNullableFilter>;
  appVersion?: Maybe<StringNullableFilter>;
  model?: Maybe<StringNullableFilter>;
  lastLogin?: Maybe<DateTimeNullableFilter>;
  manufacturer?: Maybe<StringNullableFilter>;
  brand?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumDeviceTypeNullableFilter>;
  token?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  snsEndpointArn?: Maybe<StringNullableFilter>;
  instanceId?: Maybe<StringFilter>;
  pushModalSkipped?: Maybe<BoolFilter>;
  lastPushAlertTimestamp?: Maybe<DateTimeNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogListRelationFilter>;
};

export type ClinicPetParentDeviceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  uniqueInstanceIdPerPetParent?: Maybe<ClinicPetParentDeviceUniqueInstanceIdPerPetParentCompoundUniqueInput>;
};

export type ClinicPetParentListRelationFilter = {
  every?: Maybe<ClinicPetParentWhereInput>;
  some?: Maybe<ClinicPetParentWhereInput>;
  none?: Maybe<ClinicPetParentWhereInput>;
};

export type ClinicPetParentMaxAggregateOutputType = {
  __typename?: 'ClinicPetParentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isTest?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  sourcePimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  petParentSettingId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  globalPetParentId?: Maybe<SortOrder>;
};

export type ClinicPetParentMinAggregateOutputType = {
  __typename?: 'ClinicPetParentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isTest?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  sourcePimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  petParentSettingId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  globalPetParentId?: Maybe<SortOrder>;
};

export type ClinicPetParentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetParentOrderByRelevanceFieldEnum {
  Id = 'id',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  PimsId = 'pimsId',
  SourcePimsId = 'sourcePimsId',
  FirstName = 'firstName',
  LastName = 'lastName',
  PimsPhoneNumber = 'pimsPhoneNumber',
  ClinicId = 'clinicId',
  IntegrationId = 'integrationId',
  PetParentSettingId = 'petParentSettingId',
  OrganizationPetParentId = 'organizationPetParentId',
  StripeCustomerId = 'stripeCustomerId',
  UserId = 'userId',
  GlobalPetParentId = 'globalPetParentId'
}

export type ClinicPetParentOrderByRelevanceInput = {
  fields: Array<ClinicPetParentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPetParentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isTest?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  sourcePimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  petParentSettingId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  globalPetParentId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPetParentCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetParentMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetParentMinOrderByAggregateInput>;
};

export type ClinicPetParentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isTest?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  sourcePimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  petParentSettingId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  globalPetParentId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  petParentSetting?: Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>;
  organizationPetParent?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  stripeCustomer?: Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  channelMembers?: Maybe<ChannelMemberOrderByRelationAggregateInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberOrderByRelationAggregateInput>;
  addresses?: Maybe<ClinicPetParentAddressOrderByRelationAggregateInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestOrderByRelationAggregateInput>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodOrderByRelationAggregateInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  serviceReminders?: Maybe<ServiceReminderOrderByRelationAggregateInput>;
  invoices?: Maybe<InvoiceOrderByRelationAggregateInput>;
  calls?: Maybe<CallParticipantOrderByRelationAggregateInput>;
  emailProviderLogs?: Maybe<EmailProviderLogOrderByRelationAggregateInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestOrderByRelationAggregateInput>;
  automationRunActions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryOrderByRelationAggregateInput>;
  surveyResults?: Maybe<SurveyResultOrderByRelationAggregateInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusOrderByRelationAggregateInput>;
  globalPetParent?: Maybe<GlobalPetParentOrderByWithRelationAndSearchRelevanceInput>;
  devices?: Maybe<ClinicPetParentDeviceOrderByRelationAggregateInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferOrderByRelationAggregateInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersOrderByRelationAggregateInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentOrderByRelationAggregateInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogOrderByRelationAggregateInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeOrderByRelationAggregateInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultOrderByRelationAggregateInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueOrderByRelationAggregateInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogOrderByRelationAggregateInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientOrderByRelationAggregateInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicPetParentOrderByRelevanceInput>;
};

export type ClinicPetParentPimsSyncStatus = {
  __typename?: 'ClinicPetParentPimsSyncStatus';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: ProfilePimsSyncStatus;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParentId: Scalars['String'];
  clinicPetParent: ClinicPetParent;
};

export type ClinicPetParentPimsSyncStatusAvgAggregateOutputType = {
  __typename?: 'ClinicPetParentPimsSyncStatusAvgAggregateOutputType';
  attempts?: Maybe<Scalars['Float']>;
};

export type ClinicPetParentPimsSyncStatusAvgOrderByAggregateInput = {
  attempts?: Maybe<SortOrder>;
};

export type ClinicPetParentPimsSyncStatusCountAggregateOutputType = {
  __typename?: 'ClinicPetParentPimsSyncStatusCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  status: Scalars['Int'];
  details: Scalars['Int'];
  attempts: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPetParentPimsSyncStatusCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  attempts?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
};

export type ClinicPetParentPimsSyncStatusCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: ProfilePimsSyncStatus;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutClinicPetParentPimSyncStatusInput;
};

export type ClinicPetParentPimsSyncStatusCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: ProfilePimsSyncStatus;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetParentPimsSyncStatusCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicPetParentPimsSyncStatusCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentPimsSyncStatusCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: ProfilePimsSyncStatus;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParentId: Scalars['String'];
};

export type ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentPimsSyncStatusCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
};

export type ClinicPetParentPimsSyncStatusCreateOrConnectWithoutClinicPetParentInput = {
  where: ClinicPetParentPimsSyncStatusWhereUniqueInput;
  create: ClinicPetParentPimsSyncStatusUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicPetParentPimsSyncStatusCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: ProfilePimsSyncStatus;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetParentPimsSyncStatusListRelationFilter = {
  every?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
  some?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
  none?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
};

export type ClinicPetParentPimsSyncStatusMaxAggregateOutputType = {
  __typename?: 'ClinicPetParentPimsSyncStatusMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentPimsSyncStatusMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  attempts?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
};

export type ClinicPetParentPimsSyncStatusMinAggregateOutputType = {
  __typename?: 'ClinicPetParentPimsSyncStatusMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentPimsSyncStatusMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  attempts?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
};

export type ClinicPetParentPimsSyncStatusOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetParentPimsSyncStatusOrderByRelevanceFieldEnum {
  Id = 'id',
  Details = 'details',
  ClinicPetParentId = 'clinicPetParentId'
}

export type ClinicPetParentPimsSyncStatusOrderByRelevanceInput = {
  fields: Array<ClinicPetParentPimsSyncStatusOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPetParentPimsSyncStatusOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  attempts?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPetParentPimsSyncStatusCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicPetParentPimsSyncStatusAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicPetParentPimsSyncStatusMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetParentPimsSyncStatusMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicPetParentPimsSyncStatusSumOrderByAggregateInput>;
};

export type ClinicPetParentPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  attempts?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicPetParentPimsSyncStatusOrderByRelevanceInput>;
};

export enum ClinicPetParentPimsSyncStatusScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  Details = 'details',
  Attempts = 'attempts',
  ClinicPetParentId = 'clinicPetParentId'
}

export type ClinicPetParentPimsSyncStatusScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumProfilePimsSyncStatusFilter>;
  details?: Maybe<StringNullableFilter>;
  attempts?: Maybe<IntNullableFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
};

export type ClinicPetParentPimsSyncStatusScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumProfilePimsSyncStatusWithAggregatesFilter>;
  details?: Maybe<StringNullableWithAggregatesFilter>;
  attempts?: Maybe<IntNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicPetParentPimsSyncStatusSumAggregateOutputType = {
  __typename?: 'ClinicPetParentPimsSyncStatusSumAggregateOutputType';
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetParentPimsSyncStatusSumOrderByAggregateInput = {
  attempts?: Maybe<SortOrder>;
};

export type ClinicPetParentPimsSyncStatusUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: ProfilePimsSyncStatus;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParentId: Scalars['String'];
};

export type ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentPimsSyncStatusCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
};

export type ClinicPetParentPimsSyncStatusUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: ProfilePimsSyncStatus;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetParentPimsSyncStatusUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentPimsSyncStatusUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentPimsSyncStatusUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentPimsSyncStatusCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentPimsSyncStatusUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentPimsSyncStatusUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereInput>>;
};

export type ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentPimSyncStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetParentPimsSyncStatusUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetParentPimsSyncStatusUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutClinicPetParentPimSyncStatusNestedInput>;
};

export type ClinicPetParentPimsSyncStatusUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetParentPimsSyncStatusUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ClinicPetParentPimsSyncStatusScalarWhereInput;
  data: ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentPimSyncStatusInput;
};

export type ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentPimsSyncStatusUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentPimsSyncStatusCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentPimsSyncStatusUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentPimsSyncStatusUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereInput>>;
};

export type ClinicPetParentPimsSyncStatusUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicPetParentPimsSyncStatusWhereUniqueInput;
  data: ClinicPetParentPimsSyncStatusUncheckedUpdateWithoutClinicPetParentInput;
};

export type ClinicPetParentPimsSyncStatusUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetParentPimsSyncStatusUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicPetParentPimsSyncStatusWhereUniqueInput;
  update: ClinicPetParentPimsSyncStatusUncheckedUpdateWithoutClinicPetParentInput;
  create: ClinicPetParentPimsSyncStatusUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicPetParentPimsSyncStatusWhereInput = {
  AND?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumProfilePimsSyncStatusFilter>;
  details?: Maybe<StringNullableFilter>;
  attempts?: Maybe<IntNullableFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
};

export type ClinicPetParentPimsSyncStatusWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentRelationFilter = {
  is?: Maybe<ClinicPetParentWhereInput>;
  isNot?: Maybe<ClinicPetParentWhereInput>;
};

export enum ClinicPetParentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Email = 'email',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  IsTest = 'isTest',
  IsPrivacyProtected = 'isPrivacyProtected',
  PhoneNumber = 'phoneNumber',
  PimsId = 'pimsId',
  SourcePimsId = 'sourcePimsId',
  FirstName = 'firstName',
  LastName = 'lastName',
  IsVerified = 'isVerified',
  CreationSource = 'creationSource',
  PimsPhoneNumber = 'pimsPhoneNumber',
  RawPimsValue = 'rawPimsValue',
  ClinicId = 'clinicId',
  IntegrationId = 'integrationId',
  PetParentSettingId = 'petParentSettingId',
  OrganizationPetParentId = 'organizationPetParentId',
  StripeCustomerId = 'stripeCustomerId',
  UserId = 'userId',
  GlobalPetParentId = 'globalPetParentId'
}

export type ClinicPetParentScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isTest?: Maybe<BoolNullableFilter>;
  isPrivacyProtected?: Maybe<BoolNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  sourcePimsId?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  isVerified?: Maybe<BoolNullableFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableFilter>;
  pimsPhoneNumber?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  petParentSettingId?: Maybe<StringNullableFilter>;
  organizationPetParentId?: Maybe<StringNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  globalPetParentId?: Maybe<StringNullableFilter>;
};

export type ClinicPetParentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetParentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetParentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetParentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  email?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  isTest?: Maybe<BoolNullableWithAggregatesFilter>;
  isPrivacyProtected?: Maybe<BoolNullableWithAggregatesFilter>;
  phoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  sourcePimsId?: Maybe<StringNullableWithAggregatesFilter>;
  firstName?: Maybe<StringWithAggregatesFilter>;
  lastName?: Maybe<StringWithAggregatesFilter>;
  isVerified?: Maybe<BoolNullableWithAggregatesFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableWithAggregatesFilter>;
  pimsPhoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  petParentSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  organizationPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  globalPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicPetParentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutAppointmentsInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutGlobalPetParentInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutGlobalPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutGlobalPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyGlobalPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyOrganizationPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutPetParentSettingInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetParentSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetParentSettingInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyPetParentSettingInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetsInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateWithoutAddressesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutBitwerxPaymentHealthcheckResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutCareAccountCreditIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutClinicPetParentPimSyncStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutCustomFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutDevicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutEmailCampaignCustomListRecipientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutEmailCampaignLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutEmailCampaignUnsubscribeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutGlobalPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutInformControlGroupParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutLapsedPetParentTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutLoyaltyAccountHolderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutOrganizationPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutPetParentSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutAppointmentsInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutAppointmentsInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutGlobalPetParentNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutGlobalPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutGlobalPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutGlobalPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyGlobalPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutGlobalPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutGlobalPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyOrganizationPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutPetParentSettingNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetParentSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetParentSettingInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutPetParentSettingInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyPetParentSettingInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutPetParentSettingInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutPetParentSettingInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetsInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutPetsInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutPetsInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateWithoutAddressesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutBitwerxPaymentHealthcheckResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutCareAccountCreditIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutCareAuthorizationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutClinicPetParentPimSyncStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutCustomFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutDevicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutEmailCampaignCustomListRecipientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutEmailCampaignLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutEmailCampaignUnsubscribeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutEmailProviderLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutGlobalPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutInformControlGroupParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutLapsedPetParentTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutLoyaltyAccountHolderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutOrganizationPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutPetParentSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutReceivedTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutStripePaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentUpdateManyWithWhereWithoutAppointmentsInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutGlobalPetParentInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutIntegrationInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutPetParentsInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutPetParentSettingInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutPetParentInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutPetsInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutPetParentsInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutStripeCustomerInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutUserInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
};

export type ClinicPetParentUpdateManyWithoutAppointmentsNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutAppointmentsInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutAppointmentsInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateManyWithoutGlobalPetParentNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutGlobalPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutGlobalPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutGlobalPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyGlobalPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutGlobalPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutGlobalPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateManyWithoutOrganizationPetParentNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyOrganizationPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateManyWithoutPetParentSettingNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetParentSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetParentSettingInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutPetParentSettingInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyPetParentSettingInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutPetParentSettingInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutPetParentSettingInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateManyWithoutPetsNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetsInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutPetsInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutPetsInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateManyWithoutStripeCustomerNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateOneRequiredWithoutAddressesNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAddressesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAddressesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutAddressesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutAddressesInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAutomationRunsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutAutomationRunsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutAutomationRunsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutBitwerxPaymentHealthcheckResultsNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutBitwerxPaymentHealthcheckResultsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutBitwerxPaymentHealthcheckResultsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutBitwerxPaymentHealthcheckResultsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutBitwerxPaymentHealthcheckResultsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutCareAccountCreditIntentsNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCareAccountCreditIntentsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCareAccountCreditIntentsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutCareAccountCreditIntentsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutCareAccountCreditIntentsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutCareAuthorizationRequestsNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCareAuthorizationRequestsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutCareAuthorizationRequestsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutCareAuthorizationRequestsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutClinicPetParentPimSyncStatusNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutClinicPetParentPimSyncStatusInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutClinicPetParentPimSyncStatusInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutClinicPetParentPimSyncStatusInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutClinicPetParentPimSyncStatusInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutCustomFieldValuesNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCustomFieldValuesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCustomFieldValuesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutCustomFieldValuesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutCustomFieldValuesInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutDevicesNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutDevicesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutDevicesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutDevicesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutDevicesInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutEmailCampaignLogNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailCampaignLogInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailCampaignLogInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutEmailCampaignLogInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutEmailCampaignLogInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutEmailProviderLogsNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailProviderLogsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutEmailProviderLogsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutEmailProviderLogsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutInformControlGroupParticipantsNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutInformControlGroupParticipantsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutInformControlGroupParticipantsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutInformControlGroupParticipantsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutInformControlGroupParticipantsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutInvoicesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutInvoicesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutInvoicesInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutLapsedPetParentTriggersNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutLapsedPetParentTriggersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutLapsedPetParentTriggersInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutLapsedPetParentTriggersInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutLapsedPetParentTriggersInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutLoyaltyAccountHolderNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutLoyaltyAccountHolderInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutLoyaltyAccountHolderInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutLoyaltyAccountHolderInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutLoyaltyAccountHolderInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutMassTextAlertEntriesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutMassTextAlertEntriesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutMassTextAlertEntriesInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutReceivedTrupanionExamDayOffersInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutReceivedTrupanionExamDayOffersInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutReceivedTrupanionExamDayOffersInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutSurveyResultsNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutSurveyResultsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutSurveyResultsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutSurveyResultsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutSurveyResultsInput>;
};

export type ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAutomationRunActionsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutAutomationRunActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutAutomationRunActionsInput>;
};

export type ClinicPetParentUpdateOneWithoutCallsNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCallsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCallsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutCallsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutCallsInput>;
};

export type ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutChannelCreationSourcesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutChannelCreationSourcesInput>;
};

export type ClinicPetParentUpdateOneWithoutChannelMembersNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutChannelMembersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutChannelMembersInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutChannelMembersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutChannelMembersInput>;
};

export type ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutClinicWidgetRequestsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutClinicWidgetRequestsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutClinicWidgetRequestsInput>;
};

export type ClinicPetParentUpdateOneWithoutEmailCampaignCustomListRecipientsNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailCampaignCustomListRecipientsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailCampaignCustomListRecipientsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutEmailCampaignCustomListRecipientsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutEmailCampaignCustomListRecipientsInput>;
};

export type ClinicPetParentUpdateOneWithoutEmailCampaignUnsubscribeNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailCampaignUnsubscribeInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailCampaignUnsubscribeInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutEmailCampaignUnsubscribeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutEmailCampaignUnsubscribeInput>;
};

export type ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutFormSubmissionsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutFormSubmissionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutFormSubmissionsInput>;
};

export type ClinicPetParentUpdateOneWithoutHillsToHomeApiLogNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutHillsToHomeApiLogInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutHillsToHomeApiLogInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutHillsToHomeApiLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutHillsToHomeApiLogInput>;
};

export type ClinicPetParentUpdateOneWithoutPhoneNumbersNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutPhoneNumbersInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutPhoneNumbersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutPhoneNumbersInput>;
};

export type ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutServiceRemindersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutServiceRemindersInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutServiceRemindersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutServiceRemindersInput>;
};

export type ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutStripePaymentIntentsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutStripePaymentIntentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutStripePaymentIntentsInput>;
};

export type ClinicPetParentUpdateOneWithoutStripePaymentMethodsNestedInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutStripePaymentMethodsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutStripePaymentMethodsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutStripePaymentMethodsInput>;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutAppointmentsInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutAppointmentsInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutClinicInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutGlobalPetParentInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutGlobalPetParentInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutIntegrationInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutOrganizationPetParentInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutPetParentSettingInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutPetParentSettingInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutPetsInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutPetsInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutStripeCustomerInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutUserInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutUserInput;
};

export type ClinicPetParentUpdateWithoutAddressesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutBitwerxPaymentHealthcheckResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutCareAccountCreditIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutCareAuthorizationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutClinicPetParentPimSyncStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutCustomFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutDevicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutEmailCampaignCustomListRecipientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutEmailCampaignLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutEmailCampaignUnsubscribeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutEmailProviderLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutGlobalPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutInformControlGroupParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutLapsedPetParentTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutLoyaltyAccountHolderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutOrganizationPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutPetParentSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutReceivedTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutStripePaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutAppointmentsInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutAppointmentsInput;
  create: ClinicPetParentUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutClinicInput;
  create: ClinicPetParentUncheckedCreateWithoutClinicInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutGlobalPetParentInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutGlobalPetParentInput;
  create: ClinicPetParentUncheckedCreateWithoutGlobalPetParentInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutIntegrationInput;
  create: ClinicPetParentUncheckedCreateWithoutIntegrationInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutOrganizationPetParentInput;
  create: ClinicPetParentUncheckedCreateWithoutOrganizationPetParentInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutPetParentSettingInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutPetParentSettingInput;
  create: ClinicPetParentUncheckedCreateWithoutPetParentSettingInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutPetsInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutPetsInput;
  create: ClinicPetParentUncheckedCreateWithoutPetsInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutStripeCustomerInput;
  create: ClinicPetParentUncheckedCreateWithoutStripeCustomerInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutUserInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutUserInput;
  create: ClinicPetParentUncheckedCreateWithoutUserInput;
};

export type ClinicPetParentUpsertWithoutAddressesInput = {
  update: ClinicPetParentUncheckedUpdateWithoutAddressesInput;
  create: ClinicPetParentUncheckedCreateWithoutAddressesInput;
};

export type ClinicPetParentUpsertWithoutAutomationRunActionsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutAutomationRunActionsInput;
  create: ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput;
};

export type ClinicPetParentUpsertWithoutAutomationRunsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutAutomationRunsInput;
  create: ClinicPetParentUncheckedCreateWithoutAutomationRunsInput;
};

export type ClinicPetParentUpsertWithoutBitwerxPaymentHealthcheckResultsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutBitwerxPaymentHealthcheckResultsInput;
  create: ClinicPetParentUncheckedCreateWithoutBitwerxPaymentHealthcheckResultsInput;
};

export type ClinicPetParentUpsertWithoutCallsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutCallsInput;
  create: ClinicPetParentUncheckedCreateWithoutCallsInput;
};

export type ClinicPetParentUpsertWithoutCareAccountCreditIntentsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutCareAccountCreditIntentsInput;
  create: ClinicPetParentUncheckedCreateWithoutCareAccountCreditIntentsInput;
};

export type ClinicPetParentUpsertWithoutCareAuthorizationRequestsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutCareAuthorizationRequestsInput;
  create: ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput;
};

export type ClinicPetParentUpsertWithoutChannelCreationSourcesInput = {
  update: ClinicPetParentUncheckedUpdateWithoutChannelCreationSourcesInput;
  create: ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type ClinicPetParentUpsertWithoutChannelMembersInput = {
  update: ClinicPetParentUncheckedUpdateWithoutChannelMembersInput;
  create: ClinicPetParentUncheckedCreateWithoutChannelMembersInput;
};

export type ClinicPetParentUpsertWithoutClinicPetParentPimSyncStatusInput = {
  update: ClinicPetParentUncheckedUpdateWithoutClinicPetParentPimSyncStatusInput;
  create: ClinicPetParentUncheckedCreateWithoutClinicPetParentPimSyncStatusInput;
};

export type ClinicPetParentUpsertWithoutClinicWidgetRequestsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutClinicWidgetRequestsInput;
  create: ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type ClinicPetParentUpsertWithoutCustomFieldValuesInput = {
  update: ClinicPetParentUncheckedUpdateWithoutCustomFieldValuesInput;
  create: ClinicPetParentUncheckedCreateWithoutCustomFieldValuesInput;
};

export type ClinicPetParentUpsertWithoutDevicesInput = {
  update: ClinicPetParentUncheckedUpdateWithoutDevicesInput;
  create: ClinicPetParentUncheckedCreateWithoutDevicesInput;
};

export type ClinicPetParentUpsertWithoutEmailCampaignCustomListRecipientsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutEmailCampaignCustomListRecipientsInput;
  create: ClinicPetParentUncheckedCreateWithoutEmailCampaignCustomListRecipientsInput;
};

export type ClinicPetParentUpsertWithoutEmailCampaignLogInput = {
  update: ClinicPetParentUncheckedUpdateWithoutEmailCampaignLogInput;
  create: ClinicPetParentUncheckedCreateWithoutEmailCampaignLogInput;
};

export type ClinicPetParentUpsertWithoutEmailCampaignUnsubscribeInput = {
  update: ClinicPetParentUncheckedUpdateWithoutEmailCampaignUnsubscribeInput;
  create: ClinicPetParentUncheckedCreateWithoutEmailCampaignUnsubscribeInput;
};

export type ClinicPetParentUpsertWithoutEmailProviderLogsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutEmailProviderLogsInput;
  create: ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput;
};

export type ClinicPetParentUpsertWithoutFormSubmissionsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutFormSubmissionsInput;
  create: ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput;
};

export type ClinicPetParentUpsertWithoutHillsToHomeApiLogInput = {
  update: ClinicPetParentUncheckedUpdateWithoutHillsToHomeApiLogInput;
  create: ClinicPetParentUncheckedCreateWithoutHillsToHomeApiLogInput;
};

export type ClinicPetParentUpsertWithoutInformControlGroupParticipantsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutInformControlGroupParticipantsInput;
  create: ClinicPetParentUncheckedCreateWithoutInformControlGroupParticipantsInput;
};

export type ClinicPetParentUpsertWithoutInvoicesInput = {
  update: ClinicPetParentUncheckedUpdateWithoutInvoicesInput;
  create: ClinicPetParentUncheckedCreateWithoutInvoicesInput;
};

export type ClinicPetParentUpsertWithoutLapsedPetParentTriggersInput = {
  update: ClinicPetParentUncheckedUpdateWithoutLapsedPetParentTriggersInput;
  create: ClinicPetParentUncheckedCreateWithoutLapsedPetParentTriggersInput;
};

export type ClinicPetParentUpsertWithoutLoyaltyAccountHolderInput = {
  update: ClinicPetParentUncheckedUpdateWithoutLoyaltyAccountHolderInput;
  create: ClinicPetParentUncheckedCreateWithoutLoyaltyAccountHolderInput;
};

export type ClinicPetParentUpsertWithoutMassTextAlertEntriesInput = {
  update: ClinicPetParentUncheckedUpdateWithoutMassTextAlertEntriesInput;
  create: ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput;
};

export type ClinicPetParentUpsertWithoutPhoneNumbersInput = {
  update: ClinicPetParentUncheckedUpdateWithoutPhoneNumbersInput;
  create: ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput;
};

export type ClinicPetParentUpsertWithoutReceivedTrupanionExamDayOffersInput = {
  update: ClinicPetParentUncheckedUpdateWithoutReceivedTrupanionExamDayOffersInput;
  create: ClinicPetParentUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput;
};

export type ClinicPetParentUpsertWithoutServiceRemindersInput = {
  update: ClinicPetParentUncheckedUpdateWithoutServiceRemindersInput;
  create: ClinicPetParentUncheckedCreateWithoutServiceRemindersInput;
};

export type ClinicPetParentUpsertWithoutStripePaymentIntentsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutStripePaymentIntentsInput;
  create: ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type ClinicPetParentUpsertWithoutStripePaymentMethodsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutStripePaymentMethodsInput;
  create: ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput;
};

export type ClinicPetParentUpsertWithoutSurveyResultsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutSurveyResultsInput;
  create: ClinicPetParentUncheckedCreateWithoutSurveyResultsInput;
};

export type ClinicPetParentWhereInput = {
  AND?: Maybe<Array<ClinicPetParentWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isTest?: Maybe<BoolNullableFilter>;
  isPrivacyProtected?: Maybe<BoolNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  sourcePimsId?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  isVerified?: Maybe<BoolNullableFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableFilter>;
  pimsPhoneNumber?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  petParentSettingId?: Maybe<StringNullableFilter>;
  organizationPetParentId?: Maybe<StringNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  globalPetParentId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  petParentSetting?: Maybe<PetParentSettingWhereInput>;
  organizationPetParent?: Maybe<OrganizationPetParentWhereInput>;
  stripeCustomer?: Maybe<StripeCustomerWhereInput>;
  user?: Maybe<UserWhereInput>;
  channelMembers?: Maybe<ChannelMemberListRelationFilter>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberListRelationFilter>;
  addresses?: Maybe<ClinicPetParentAddressListRelationFilter>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestListRelationFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  stripePaymentMethods?: Maybe<StripePaymentMethodListRelationFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  serviceReminders?: Maybe<ServiceReminderListRelationFilter>;
  invoices?: Maybe<InvoiceListRelationFilter>;
  calls?: Maybe<CallParticipantListRelationFilter>;
  emailProviderLogs?: Maybe<EmailProviderLogListRelationFilter>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestListRelationFilter>;
  automationRunActions?: Maybe<AutomationRunActionListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryListRelationFilter>;
  surveyResults?: Maybe<SurveyResultListRelationFilter>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderWhereInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusListRelationFilter>;
  globalPetParent?: Maybe<GlobalPetParentWhereInput>;
  devices?: Maybe<ClinicPetParentDeviceListRelationFilter>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferListRelationFilter>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersListRelationFilter>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentListRelationFilter>;
  EmailCampaignLog?: Maybe<EmailCampaignLogListRelationFilter>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeListRelationFilter>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultListRelationFilter>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueListRelationFilter>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogListRelationFilter>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientListRelationFilter>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantListRelationFilter>;
};

export type ClinicPetParentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  clinicPetParentPimsIdAndIntegrationId?: Maybe<ClinicPetParentClinicPetParentPimsIdAndIntegrationIdCompoundUniqueInput>;
};

export type ClinicPetParentWithJobChainsResponse = {
  __typename?: 'ClinicPetParentWithJobChainsResponse';
  clinicPetParent: ClinicPetParent;
  jobChains?: Maybe<Array<Maybe<JobChain>>>;
};

export type ClinicPetPimsSyncStatus = {
  __typename?: 'ClinicPetPimsSyncStatus';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: ProfilePimsSyncStatus;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetId: Scalars['String'];
  clinicPet: ClinicPet;
};

export type ClinicPetPimsSyncStatusAvgAggregateOutputType = {
  __typename?: 'ClinicPetPimsSyncStatusAvgAggregateOutputType';
  attempts?: Maybe<Scalars['Float']>;
};

export type ClinicPetPimsSyncStatusAvgOrderByAggregateInput = {
  attempts?: Maybe<SortOrder>;
};

export type ClinicPetPimsSyncStatusCountAggregateOutputType = {
  __typename?: 'ClinicPetPimsSyncStatusCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  status: Scalars['Int'];
  details: Scalars['Int'];
  attempts: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPetPimsSyncStatusCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  attempts?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ClinicPetPimsSyncStatusCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: ProfilePimsSyncStatus;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPet: ClinicPetCreateNestedOneWithoutClinicPetPimsSyncStatusInput;
};

export type ClinicPetPimsSyncStatusCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: ProfilePimsSyncStatus;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetPimsSyncStatusCreateManyClinicPetInputEnvelope = {
  data: Array<ClinicPetPimsSyncStatusCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetPimsSyncStatusCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: ProfilePimsSyncStatus;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetId: Scalars['String'];
};

export type ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ClinicPetPimsSyncStatusCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetPimsSyncStatusCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetPimsSyncStatusCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
};

export type ClinicPetPimsSyncStatusCreateOrConnectWithoutClinicPetInput = {
  where: ClinicPetPimsSyncStatusWhereUniqueInput;
  create: ClinicPetPimsSyncStatusUncheckedCreateWithoutClinicPetInput;
};

export type ClinicPetPimsSyncStatusCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: ProfilePimsSyncStatus;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetPimsSyncStatusListRelationFilter = {
  every?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
  some?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
  none?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
};

export type ClinicPetPimsSyncStatusMaxAggregateOutputType = {
  __typename?: 'ClinicPetPimsSyncStatusMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetPimsSyncStatusMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  attempts?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ClinicPetPimsSyncStatusMinAggregateOutputType = {
  __typename?: 'ClinicPetPimsSyncStatusMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetPimsSyncStatusMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  attempts?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ClinicPetPimsSyncStatusOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetPimsSyncStatusOrderByRelevanceFieldEnum {
  Id = 'id',
  Details = 'details',
  ClinicPetId = 'clinicPetId'
}

export type ClinicPetPimsSyncStatusOrderByRelevanceInput = {
  fields: Array<ClinicPetPimsSyncStatusOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPetPimsSyncStatusOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  attempts?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPetPimsSyncStatusCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicPetPimsSyncStatusAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicPetPimsSyncStatusMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetPimsSyncStatusMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicPetPimsSyncStatusSumOrderByAggregateInput>;
};

export type ClinicPetPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  attempts?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicPetPimsSyncStatusOrderByRelevanceInput>;
};

export enum ClinicPetPimsSyncStatusScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  Details = 'details',
  Attempts = 'attempts',
  ClinicPetId = 'clinicPetId'
}

export type ClinicPetPimsSyncStatusScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumProfilePimsSyncStatusFilter>;
  details?: Maybe<StringNullableFilter>;
  attempts?: Maybe<IntNullableFilter>;
  clinicPetId?: Maybe<StringFilter>;
};

export type ClinicPetPimsSyncStatusScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumProfilePimsSyncStatusWithAggregatesFilter>;
  details?: Maybe<StringNullableWithAggregatesFilter>;
  attempts?: Maybe<IntNullableWithAggregatesFilter>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicPetPimsSyncStatusSumAggregateOutputType = {
  __typename?: 'ClinicPetPimsSyncStatusSumAggregateOutputType';
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetPimsSyncStatusSumOrderByAggregateInput = {
  attempts?: Maybe<SortOrder>;
};

export type ClinicPetPimsSyncStatusUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: ProfilePimsSyncStatus;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetId: Scalars['String'];
};

export type ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ClinicPetPimsSyncStatusCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetPimsSyncStatusCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetPimsSyncStatusCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
};

export type ClinicPetPimsSyncStatusUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: ProfilePimsSyncStatus;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetPimsSyncStatusUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetPimsSyncStatusUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<ClinicPetPimsSyncStatusCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetPimsSyncStatusCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ClinicPetPimsSyncStatusUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetPimsSyncStatusCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetPimsSyncStatusUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ClinicPetPimsSyncStatusUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereInput>>;
};

export type ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetPimsSyncStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetPimsSyncStatusUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetPimsSyncStatusUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutClinicPetPimsSyncStatusNestedInput>;
};

export type ClinicPetPimsSyncStatusUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetPimsSyncStatusUpdateManyWithWhereWithoutClinicPetInput = {
  where: ClinicPetPimsSyncStatusScalarWhereInput;
  data: ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetPimsSyncStatusInput;
};

export type ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<ClinicPetPimsSyncStatusCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetPimsSyncStatusCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ClinicPetPimsSyncStatusUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetPimsSyncStatusCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetPimsSyncStatusUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ClinicPetPimsSyncStatusUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereInput>>;
};

export type ClinicPetPimsSyncStatusUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: ClinicPetPimsSyncStatusWhereUniqueInput;
  data: ClinicPetPimsSyncStatusUncheckedUpdateWithoutClinicPetInput;
};

export type ClinicPetPimsSyncStatusUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  details?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetPimsSyncStatusUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: ClinicPetPimsSyncStatusWhereUniqueInput;
  update: ClinicPetPimsSyncStatusUncheckedUpdateWithoutClinicPetInput;
  create: ClinicPetPimsSyncStatusUncheckedCreateWithoutClinicPetInput;
};

export type ClinicPetPimsSyncStatusWhereInput = {
  AND?: Maybe<Array<ClinicPetPimsSyncStatusWhereInput>>;
  OR?: Maybe<Array<ClinicPetPimsSyncStatusWhereInput>>;
  NOT?: Maybe<Array<ClinicPetPimsSyncStatusWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumProfilePimsSyncStatusFilter>;
  details?: Maybe<StringNullableFilter>;
  attempts?: Maybe<IntNullableFilter>;
  clinicPetId?: Maybe<StringFilter>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
};

export type ClinicPetPimsSyncStatusWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetRelationFilter = {
  is?: Maybe<ClinicPetWhereInput>;
  isNot?: Maybe<ClinicPetWhereInput>;
};

export enum ClinicPetScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Breed = 'breed',
  BreedId = 'breedId',
  Color = 'color',
  ColorId = 'colorId',
  DateOfBirth = 'dateOfBirth',
  DateOfDeath = 'dateOfDeath',
  Gender = 'gender',
  SexId = 'sexId',
  IsActive = 'isActive',
  IsDeceased = 'isDeceased',
  IsDeleted = 'isDeleted',
  Name = 'name',
  PimsId = 'pimsId',
  Species = 'species',
  SpeciesId = 'speciesId',
  SpeciesType = 'speciesType',
  Weight = 'weight',
  WeightUnit = 'weightUnit',
  WeightInKg = 'weightInKg',
  PhotoUrl = 'photoUrl',
  IsPrivacyProtected = 'isPrivacyProtected',
  IsSubscribedToReminders = 'isSubscribedToReminders',
  MediaId = 'mediaId',
  ClinicId = 'clinicId',
  IntegrationId = 'integrationId',
  OrganizationPetId = 'organizationPetId',
  RawPimsValue = 'rawPimsValue'
}

export type ClinicPetScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  breed?: Maybe<StringNullableFilter>;
  breedId?: Maybe<StringNullableFilter>;
  color?: Maybe<StringNullableFilter>;
  colorId?: Maybe<StringNullableFilter>;
  dateOfBirth?: Maybe<DateTimeNullableFilter>;
  dateOfDeath?: Maybe<DateTimeNullableFilter>;
  gender?: Maybe<StringNullableFilter>;
  sexId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeceased?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  species?: Maybe<StringNullableFilter>;
  speciesId?: Maybe<StringNullableFilter>;
  speciesType?: Maybe<EnumSpeciesTypeFilter>;
  weight?: Maybe<FloatNullableFilter>;
  weightUnit?: Maybe<StringNullableFilter>;
  weightInKg?: Maybe<FloatNullableFilter>;
  photoUrl?: Maybe<StringNullableFilter>;
  isPrivacyProtected?: Maybe<BoolNullableFilter>;
  isSubscribedToReminders?: Maybe<BoolFilter>;
  mediaId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  organizationPetId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ClinicPetScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  breed?: Maybe<StringNullableWithAggregatesFilter>;
  breedId?: Maybe<StringNullableWithAggregatesFilter>;
  color?: Maybe<StringNullableWithAggregatesFilter>;
  colorId?: Maybe<StringNullableWithAggregatesFilter>;
  dateOfBirth?: Maybe<DateTimeNullableWithAggregatesFilter>;
  dateOfDeath?: Maybe<DateTimeNullableWithAggregatesFilter>;
  gender?: Maybe<StringNullableWithAggregatesFilter>;
  sexId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeceased?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  species?: Maybe<StringNullableWithAggregatesFilter>;
  speciesId?: Maybe<StringNullableWithAggregatesFilter>;
  speciesType?: Maybe<EnumSpeciesTypeWithAggregatesFilter>;
  weight?: Maybe<FloatNullableWithAggregatesFilter>;
  weightUnit?: Maybe<StringNullableWithAggregatesFilter>;
  weightInKg?: Maybe<FloatNullableWithAggregatesFilter>;
  photoUrl?: Maybe<StringNullableWithAggregatesFilter>;
  isPrivacyProtected?: Maybe<BoolNullableWithAggregatesFilter>;
  isSubscribedToReminders?: Maybe<BoolWithAggregatesFilter>;
  mediaId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  organizationPetId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicPetSumAggregateOutputType = {
  __typename?: 'ClinicPetSumAggregateOutputType';
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
};

export type ClinicPetSumOrderByAggregateInput = {
  weight?: Maybe<SortOrder>;
  weightInKg?: Maybe<SortOrder>;
};

export type ClinicPetUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutBreedModelInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutBreedModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutBreedModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyBreedModelInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutChannelsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutChannelsInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutColorModelInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutColorModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutColorModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyColorModelInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutInvoiceLineItemsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutMediaInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutMediaInput>>;
  createMany?: Maybe<ClinicPetCreateManyMediaInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<ClinicPetCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutPetParentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutPetParentsInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutSexModelInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutSexModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSexModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySexModelInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutSpeciesModelInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutSpeciesModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSpeciesModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySpeciesModelInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateWithoutAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutBreedModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutClinicPetPimsSyncStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutColorModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutCustomFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutSexModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutSpeciesModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutTrupanionCertificateAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetUncheckedUpdateManyWithoutBreedModelNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutBreedModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutBreedModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutBreedModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyBreedModelInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutBreedModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutBreedModelInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutChannelsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutChannelsInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutChannelsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutChannelsInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetUncheckedUpdateManyWithoutColorModelNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutColorModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutColorModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutColorModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyColorModelInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutColorModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutColorModelInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutInvoiceLineItemsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutInvoiceLineItemsInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutInvoiceLineItemsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutInvoiceLineItemsInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutMediaNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutMediaInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutMediaInput>>;
  createMany?: Maybe<ClinicPetCreateManyMediaInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutMediaInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<ClinicPetCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutPetParentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutPetParentsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutPetParentsInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutPetParentsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutPetParentsInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetUncheckedUpdateManyWithoutSexModelNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutSexModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSexModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutSexModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySexModelInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutSexModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutSexModelInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutSpeciesModelNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutSpeciesModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSpeciesModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutSpeciesModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySpeciesModelInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutSpeciesModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutSpeciesModelInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateWithoutAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutBreedModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutCareBenefitUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutClinicPetPimsSyncStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutColorModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutCustomFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutReceivedTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutSexModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutSpeciesModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutTrupanionCertificateAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUncheckedUpdateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetUpdateManyWithWhereWithoutBreedModelInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutChannelsInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutClinicPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutColorModelInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutIntegrationInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutInvoiceLineItemsInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutClinicPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutMediaInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutClinicPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutOrganizationPetInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutClinicPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutPetParentsInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutSexModelInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutSpeciesModelInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
};

export type ClinicPetUpdateManyWithoutBreedModelNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutBreedModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutBreedModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutBreedModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyBreedModelInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutBreedModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutBreedModelInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutChannelsNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutChannelsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutChannelsInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutChannelsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutChannelsInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutColorModelNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutColorModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutColorModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutColorModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyColorModelInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutColorModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutColorModelInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutInvoiceLineItemsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutInvoiceLineItemsInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutInvoiceLineItemsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutInvoiceLineItemsInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutMediaNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutMediaInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutMediaInput>>;
  createMany?: Maybe<ClinicPetCreateManyMediaInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutMediaInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutOrganizationPetNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<ClinicPetCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutPetParentsNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutPetParentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutPetParentsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutPetParentsInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutPetParentsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutPetParentsInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutSexModelNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutSexModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSexModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutSexModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySexModelInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutSexModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutSexModelInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutSpeciesModelNestedInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutSpeciesModelInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSpeciesModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutSpeciesModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySpeciesModelInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutSpeciesModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutSpeciesModelInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutCareBenefitUsagesInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutCareBenefitUsagesInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutCareBenefitUsagesInput>;
};

export type ClinicPetUpdateOneRequiredWithoutClinicPetPimsSyncStatusNestedInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutClinicPetPimsSyncStatusInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutClinicPetPimsSyncStatusInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutClinicPetPimsSyncStatusInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutClinicPetPimsSyncStatusInput>;
};

export type ClinicPetUpdateOneRequiredWithoutCustomFieldValuesNestedInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutCustomFieldValuesInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutCustomFieldValuesInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutCustomFieldValuesInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutCustomFieldValuesInput>;
};

export type ClinicPetUpdateOneRequiredWithoutPrescriptionsNestedInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutPrescriptionsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutPrescriptionsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutPrescriptionsInput>;
};

export type ClinicPetUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutReceivedTrupanionExamDayOffersInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutReceivedTrupanionExamDayOffersInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutReceivedTrupanionExamDayOffersInput>;
};

export type ClinicPetUpdateOneRequiredWithoutTrupanionCertificateAvailabilitiesNestedInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutTrupanionCertificateAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutTrupanionCertificateAvailabilitiesInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutTrupanionCertificateAvailabilitiesInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutTrupanionCertificateAvailabilitiesInput>;
};

export type ClinicPetUpdateOneRequiredWithoutVaccinationsNestedInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutVaccinationsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutVaccinationsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutVaccinationsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutVaccinationsInput>;
};

export type ClinicPetUpdateOneWithoutAlertsNestedInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutAlertsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAlertsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutAlertsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutAlertsInput>;
};

export type ClinicPetUpdateOneWithoutAppointmentsNestedInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutAppointmentsInput>;
};

export type ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAutomationRunActionsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutAutomationRunActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutAutomationRunActionsInput>;
};

export type ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutChannelAutomationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutChannelAutomationStatusesInput>;
};

export type ClinicPetUpdateOneWithoutFormSubmissionsNestedInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutFormSubmissionsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutFormSubmissionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutFormSubmissionsInput>;
};

export type ClinicPetUpdateOneWithoutHillsToHomeApiLogNestedInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutHillsToHomeApiLogInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutHillsToHomeApiLogInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutHillsToHomeApiLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutHillsToHomeApiLogInput>;
};

export type ClinicPetUpdateOneWithoutServiceRemindersNestedInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutServiceRemindersInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutServiceRemindersInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutServiceRemindersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutServiceRemindersInput>;
};

export type ClinicPetUpdateWithWhereUniqueWithoutBreedModelInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutBreedModelInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutChannelsInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutChannelsInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutClinicInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutColorModelInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutColorModelInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutIntegrationInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutInvoiceLineItemsInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutInvoiceLineItemsInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutMediaInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutMediaInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutOrganizationPetInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutOrganizationPetInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutPetParentsInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutPetParentsInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutSexModelInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutSexModelInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutSpeciesModelInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutSpeciesModelInput;
};

export type ClinicPetUpdateWithoutAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutBreedModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutCareBenefitUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutClinicPetPimsSyncStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutColorModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutCustomFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutReceivedTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutSexModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutSpeciesModelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutTrupanionCertificateAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpdateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  weightInKg?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
};

export type ClinicPetUpsertWithWhereUniqueWithoutBreedModelInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutBreedModelInput;
  create: ClinicPetUncheckedCreateWithoutBreedModelInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutChannelsInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutChannelsInput;
  create: ClinicPetUncheckedCreateWithoutChannelsInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutClinicInput;
  create: ClinicPetUncheckedCreateWithoutClinicInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutColorModelInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutColorModelInput;
  create: ClinicPetUncheckedCreateWithoutColorModelInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutIntegrationInput;
  create: ClinicPetUncheckedCreateWithoutIntegrationInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutInvoiceLineItemsInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutInvoiceLineItemsInput;
  create: ClinicPetUncheckedCreateWithoutInvoiceLineItemsInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutMediaInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutMediaInput;
  create: ClinicPetUncheckedCreateWithoutMediaInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutOrganizationPetInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutOrganizationPetInput;
  create: ClinicPetUncheckedCreateWithoutOrganizationPetInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutPetParentsInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutPetParentsInput;
  create: ClinicPetUncheckedCreateWithoutPetParentsInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutSexModelInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutSexModelInput;
  create: ClinicPetUncheckedCreateWithoutSexModelInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutSpeciesModelInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutSpeciesModelInput;
  create: ClinicPetUncheckedCreateWithoutSpeciesModelInput;
};

export type ClinicPetUpsertWithoutAlertsInput = {
  update: ClinicPetUncheckedUpdateWithoutAlertsInput;
  create: ClinicPetUncheckedCreateWithoutAlertsInput;
};

export type ClinicPetUpsertWithoutAppointmentsInput = {
  update: ClinicPetUncheckedUpdateWithoutAppointmentsInput;
  create: ClinicPetUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicPetUpsertWithoutAutomationRunActionsInput = {
  update: ClinicPetUncheckedUpdateWithoutAutomationRunActionsInput;
  create: ClinicPetUncheckedCreateWithoutAutomationRunActionsInput;
};

export type ClinicPetUpsertWithoutCareBenefitUsagesInput = {
  update: ClinicPetUncheckedUpdateWithoutCareBenefitUsagesInput;
  create: ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput;
};

export type ClinicPetUpsertWithoutChannelAutomationStatusesInput = {
  update: ClinicPetUncheckedUpdateWithoutChannelAutomationStatusesInput;
  create: ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type ClinicPetUpsertWithoutClinicPetPimsSyncStatusInput = {
  update: ClinicPetUncheckedUpdateWithoutClinicPetPimsSyncStatusInput;
  create: ClinicPetUncheckedCreateWithoutClinicPetPimsSyncStatusInput;
};

export type ClinicPetUpsertWithoutCustomFieldValuesInput = {
  update: ClinicPetUncheckedUpdateWithoutCustomFieldValuesInput;
  create: ClinicPetUncheckedCreateWithoutCustomFieldValuesInput;
};

export type ClinicPetUpsertWithoutFormSubmissionsInput = {
  update: ClinicPetUncheckedUpdateWithoutFormSubmissionsInput;
  create: ClinicPetUncheckedCreateWithoutFormSubmissionsInput;
};

export type ClinicPetUpsertWithoutHillsToHomeApiLogInput = {
  update: ClinicPetUncheckedUpdateWithoutHillsToHomeApiLogInput;
  create: ClinicPetUncheckedCreateWithoutHillsToHomeApiLogInput;
};

export type ClinicPetUpsertWithoutPrescriptionsInput = {
  update: ClinicPetUncheckedUpdateWithoutPrescriptionsInput;
  create: ClinicPetUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicPetUpsertWithoutReceivedTrupanionExamDayOffersInput = {
  update: ClinicPetUncheckedUpdateWithoutReceivedTrupanionExamDayOffersInput;
  create: ClinicPetUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput;
};

export type ClinicPetUpsertWithoutServiceRemindersInput = {
  update: ClinicPetUncheckedUpdateWithoutServiceRemindersInput;
  create: ClinicPetUncheckedCreateWithoutServiceRemindersInput;
};

export type ClinicPetUpsertWithoutTrupanionCertificateAvailabilitiesInput = {
  update: ClinicPetUncheckedUpdateWithoutTrupanionCertificateAvailabilitiesInput;
  create: ClinicPetUncheckedCreateWithoutTrupanionCertificateAvailabilitiesInput;
};

export type ClinicPetUpsertWithoutVaccinationsInput = {
  update: ClinicPetUncheckedUpdateWithoutVaccinationsInput;
  create: ClinicPetUncheckedCreateWithoutVaccinationsInput;
};

export type ClinicPetWhereInput = {
  AND?: Maybe<Array<ClinicPetWhereInput>>;
  OR?: Maybe<Array<ClinicPetWhereInput>>;
  NOT?: Maybe<Array<ClinicPetWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  breed?: Maybe<StringNullableFilter>;
  breedId?: Maybe<StringNullableFilter>;
  color?: Maybe<StringNullableFilter>;
  colorId?: Maybe<StringNullableFilter>;
  dateOfBirth?: Maybe<DateTimeNullableFilter>;
  dateOfDeath?: Maybe<DateTimeNullableFilter>;
  gender?: Maybe<StringNullableFilter>;
  sexId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeceased?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  species?: Maybe<StringNullableFilter>;
  speciesId?: Maybe<StringNullableFilter>;
  speciesType?: Maybe<EnumSpeciesTypeFilter>;
  weight?: Maybe<FloatNullableFilter>;
  weightUnit?: Maybe<StringNullableFilter>;
  weightInKg?: Maybe<FloatNullableFilter>;
  photoUrl?: Maybe<StringNullableFilter>;
  isPrivacyProtected?: Maybe<BoolNullableFilter>;
  isSubscribedToReminders?: Maybe<BoolFilter>;
  mediaId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  organizationPetId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  organizationPet?: Maybe<OrganizationPetWhereInput>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  alerts?: Maybe<ClinicPetAlertListRelationFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  petParents?: Maybe<ClinicPetParentListRelationFilter>;
  serviceReminders?: Maybe<ServiceReminderListRelationFilter>;
  channels?: Maybe<ChannelListRelationFilter>;
  invoiceLineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  careBenefitUsages?: Maybe<CareBenefitUsageListRelationFilter>;
  automationRunActions?: Maybe<AutomationRunActionListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  vaccinations?: Maybe<VaccinationListRelationFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusListRelationFilter>;
  breedModel?: Maybe<BreedWhereInput>;
  sexModel?: Maybe<SexWhereInput>;
  colorModel?: Maybe<ColorWhereInput>;
  speciesModel?: Maybe<SpeciesWhereInput>;
  media?: Maybe<MediaWhereInput>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferListRelationFilter>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityListRelationFilter>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueListRelationFilter>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogListRelationFilter>;
};

export type ClinicPetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicPetPimsIdAndIntegrationId?: Maybe<ClinicPetClinicPetPimsIdAndIntegrationIdCompoundUniqueInput>;
};

export type ClinicPhoneNumber = {
  __typename?: 'ClinicPhoneNumber';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  number: Scalars['String'];
  status: PhoneNumberStatus;
  clinicId: Scalars['String'];
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified: Scalars['Boolean'];
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered: Scalars['Boolean'];
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  clinic: Clinic;
  loaSignedByUser?: Maybe<User>;
  smsNotificationStatuses: Array<SmsNotificationStatus>;
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaign>;
  _count: ClinicPhoneNumberCountOutputType;
};


export type ClinicPhoneNumberSmsNotificationStatusesArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
  orderBy?: Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SmsNotificationStatusScalarFieldEnum>;
};

export type ClinicPhoneNumberCapabilityAndNumberAndProviderCompoundUniqueInput = {
  capability: ServiceCapability;
  number: Scalars['String'];
  provider: ServiceProvider;
};

export type ClinicPhoneNumberCountAggregateOutputType = {
  __typename?: 'ClinicPhoneNumberCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  capability: Scalars['Int'];
  provider: Scalars['Int'];
  isPrimary: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  number: Scalars['Int'];
  status: Scalars['Int'];
  clinicId: Scalars['Int'];
  loaSignedByUserId: Scalars['Int'];
  loaSignedAt: Scalars['Int'];
  portingStartAt: Scalars['Int'];
  incomingCarrier: Scalars['Int'];
  isVerified: Scalars['Int'];
  portingOrderId: Scalars['Int'];
  loaUrl: Scalars['Int'];
  portingOrderStatus: Scalars['Int'];
  isTestSmsDelivered: Scalars['Int'];
  campaignRegistryCampaignId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPhoneNumberCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  loaSignedByUserId?: Maybe<SortOrder>;
  loaSignedAt?: Maybe<SortOrder>;
  portingStartAt?: Maybe<SortOrder>;
  incomingCarrier?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  portingOrderId?: Maybe<SortOrder>;
  loaUrl?: Maybe<SortOrder>;
  portingOrderStatus?: Maybe<SortOrder>;
  isTestSmsDelivered?: Maybe<SortOrder>;
  campaignRegistryCampaignId?: Maybe<SortOrder>;
};

export type ClinicPhoneNumberCountOutputType = {
  __typename?: 'ClinicPhoneNumberCountOutputType';
  smsNotificationStatuses: Scalars['Int'];
};

export type ClinicPhoneNumberCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  clinic: ClinicCreateNestedOneWithoutClinicPhoneNumberInput;
  loaSignedByUser?: Maybe<UserCreateNestedOneWithoutLoaSignedClinicPhoneNumbersInput>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutClinicPhoneNumberInput>;
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignCreateNestedOneWithoutClinicPhoneNumbersInput>;
};

export type ClinicPhoneNumberCreateManyCampaignRegistryCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  clinicId: Scalars['String'];
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
};

export type ClinicPhoneNumberCreateManyCampaignRegistryCampaignInputEnvelope = {
  data: Array<ClinicPhoneNumberCreateManyCampaignRegistryCampaignInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPhoneNumberCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumberCreateManyClinicInputEnvelope = {
  data: Array<ClinicPhoneNumberCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPhoneNumberCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  clinicId: Scalars['String'];
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumberCreateManyLoaSignedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  clinicId: Scalars['String'];
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumberCreateManyLoaSignedByUserInputEnvelope = {
  data: Array<ClinicPhoneNumberCreateManyLoaSignedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPhoneNumberCreateNestedManyWithoutCampaignRegistryCampaignInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutCampaignRegistryCampaignInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutCampaignRegistryCampaignInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyCampaignRegistryCampaignInputEnvelope>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
};

export type ClinicPhoneNumberCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
};

export type ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutLoaSignedByUserInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutLoaSignedByUserInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyLoaSignedByUserInputEnvelope>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
};

export type ClinicPhoneNumberCreateNestedOneWithoutSmsNotificationStatusesInput = {
  create?: Maybe<ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput>;
  connectOrCreate?: Maybe<ClinicPhoneNumberCreateOrConnectWithoutSmsNotificationStatusesInput>;
  connect?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
};

export type ClinicPhoneNumberCreateOrConnectWithoutCampaignRegistryCampaignInput = {
  where: ClinicPhoneNumberWhereUniqueInput;
  create: ClinicPhoneNumberUncheckedCreateWithoutCampaignRegistryCampaignInput;
};

export type ClinicPhoneNumberCreateOrConnectWithoutClinicInput = {
  where: ClinicPhoneNumberWhereUniqueInput;
  create: ClinicPhoneNumberUncheckedCreateWithoutClinicInput;
};

export type ClinicPhoneNumberCreateOrConnectWithoutLoaSignedByUserInput = {
  where: ClinicPhoneNumberWhereUniqueInput;
  create: ClinicPhoneNumberUncheckedCreateWithoutLoaSignedByUserInput;
};

export type ClinicPhoneNumberCreateOrConnectWithoutSmsNotificationStatusesInput = {
  where: ClinicPhoneNumberWhereUniqueInput;
  create: ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput;
};

export type ClinicPhoneNumberCreateWithoutCampaignRegistryCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  clinic: ClinicCreateNestedOneWithoutClinicPhoneNumberInput;
  loaSignedByUser?: Maybe<UserCreateNestedOneWithoutLoaSignedClinicPhoneNumbersInput>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutClinicPhoneNumberInput>;
};

export type ClinicPhoneNumberCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  loaSignedByUser?: Maybe<UserCreateNestedOneWithoutLoaSignedClinicPhoneNumbersInput>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutClinicPhoneNumberInput>;
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignCreateNestedOneWithoutClinicPhoneNumbersInput>;
};

export type ClinicPhoneNumberCreateWithoutLoaSignedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  clinic: ClinicCreateNestedOneWithoutClinicPhoneNumberInput;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutClinicPhoneNumberInput>;
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignCreateNestedOneWithoutClinicPhoneNumbersInput>;
};

export type ClinicPhoneNumberCreateWithoutSmsNotificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  clinic: ClinicCreateNestedOneWithoutClinicPhoneNumberInput;
  loaSignedByUser?: Maybe<UserCreateNestedOneWithoutLoaSignedClinicPhoneNumbersInput>;
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignCreateNestedOneWithoutClinicPhoneNumbersInput>;
};

export type ClinicPhoneNumberListRelationFilter = {
  every?: Maybe<ClinicPhoneNumberWhereInput>;
  some?: Maybe<ClinicPhoneNumberWhereInput>;
  none?: Maybe<ClinicPhoneNumberWhereInput>;
};

export type ClinicPhoneNumberMaxAggregateOutputType = {
  __typename?: 'ClinicPhoneNumberMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinicId?: Maybe<Scalars['String']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumberMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  loaSignedByUserId?: Maybe<SortOrder>;
  loaSignedAt?: Maybe<SortOrder>;
  portingStartAt?: Maybe<SortOrder>;
  incomingCarrier?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  portingOrderId?: Maybe<SortOrder>;
  loaUrl?: Maybe<SortOrder>;
  portingOrderStatus?: Maybe<SortOrder>;
  isTestSmsDelivered?: Maybe<SortOrder>;
  campaignRegistryCampaignId?: Maybe<SortOrder>;
};

export type ClinicPhoneNumberMinAggregateOutputType = {
  __typename?: 'ClinicPhoneNumberMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinicId?: Maybe<Scalars['String']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumberMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  loaSignedByUserId?: Maybe<SortOrder>;
  loaSignedAt?: Maybe<SortOrder>;
  portingStartAt?: Maybe<SortOrder>;
  incomingCarrier?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  portingOrderId?: Maybe<SortOrder>;
  loaUrl?: Maybe<SortOrder>;
  portingOrderStatus?: Maybe<SortOrder>;
  isTestSmsDelivered?: Maybe<SortOrder>;
  campaignRegistryCampaignId?: Maybe<SortOrder>;
};

export type ClinicPhoneNumberOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPhoneNumberOrderByRelevanceFieldEnum {
  Id = 'id',
  Number = 'number',
  ClinicId = 'clinicId',
  LoaSignedByUserId = 'loaSignedByUserId',
  IncomingCarrier = 'incomingCarrier',
  PortingOrderId = 'portingOrderId',
  LoaUrl = 'loaUrl',
  PortingOrderStatus = 'portingOrderStatus',
  CampaignRegistryCampaignId = 'campaignRegistryCampaignId'
}

export type ClinicPhoneNumberOrderByRelevanceInput = {
  fields: Array<ClinicPhoneNumberOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPhoneNumberOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  loaSignedByUserId?: Maybe<SortOrder>;
  loaSignedAt?: Maybe<SortOrder>;
  portingStartAt?: Maybe<SortOrder>;
  incomingCarrier?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  portingOrderId?: Maybe<SortOrder>;
  loaUrl?: Maybe<SortOrder>;
  portingOrderStatus?: Maybe<SortOrder>;
  isTestSmsDelivered?: Maybe<SortOrder>;
  campaignRegistryCampaignId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPhoneNumberCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPhoneNumberMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPhoneNumberMinOrderByAggregateInput>;
};

export type ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  loaSignedByUserId?: Maybe<SortOrder>;
  loaSignedAt?: Maybe<SortOrder>;
  portingStartAt?: Maybe<SortOrder>;
  incomingCarrier?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  portingOrderId?: Maybe<SortOrder>;
  loaUrl?: Maybe<SortOrder>;
  portingOrderStatus?: Maybe<SortOrder>;
  isTestSmsDelivered?: Maybe<SortOrder>;
  campaignRegistryCampaignId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  loaSignedByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusOrderByRelationAggregateInput>;
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicPhoneNumberOrderByRelevanceInput>;
};

export type ClinicPhoneNumberRelationFilter = {
  is?: Maybe<ClinicPhoneNumberWhereInput>;
  isNot?: Maybe<ClinicPhoneNumberWhereInput>;
};

export enum ClinicPhoneNumberScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Capability = 'capability',
  Provider = 'provider',
  IsPrimary = 'isPrimary',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Number = 'number',
  Status = 'status',
  ClinicId = 'clinicId',
  LoaSignedByUserId = 'loaSignedByUserId',
  LoaSignedAt = 'loaSignedAt',
  PortingStartAt = 'portingStartAt',
  IncomingCarrier = 'incomingCarrier',
  IsVerified = 'isVerified',
  PortingOrderId = 'portingOrderId',
  LoaUrl = 'loaUrl',
  PortingOrderStatus = 'portingOrderStatus',
  IsTestSmsDelivered = 'isTestSmsDelivered',
  CampaignRegistryCampaignId = 'campaignRegistryCampaignId'
}

export type ClinicPhoneNumberScalarWhereInput = {
  AND?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  capability?: Maybe<EnumServiceCapabilityFilter>;
  provider?: Maybe<EnumServiceProviderFilter>;
  isPrimary?: Maybe<BoolFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  number?: Maybe<StringFilter>;
  status?: Maybe<EnumPhoneNumberStatusFilter>;
  clinicId?: Maybe<StringFilter>;
  loaSignedByUserId?: Maybe<StringNullableFilter>;
  loaSignedAt?: Maybe<DateTimeNullableFilter>;
  portingStartAt?: Maybe<DateTimeNullableFilter>;
  incomingCarrier?: Maybe<StringNullableFilter>;
  isVerified?: Maybe<BoolFilter>;
  portingOrderId?: Maybe<StringNullableFilter>;
  loaUrl?: Maybe<StringNullableFilter>;
  portingOrderStatus?: Maybe<StringNullableFilter>;
  isTestSmsDelivered?: Maybe<BoolFilter>;
  campaignRegistryCampaignId?: Maybe<StringNullableFilter>;
};

export type ClinicPhoneNumberScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPhoneNumberScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPhoneNumberScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPhoneNumberScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  capability?: Maybe<EnumServiceCapabilityWithAggregatesFilter>;
  provider?: Maybe<EnumServiceProviderWithAggregatesFilter>;
  isPrimary?: Maybe<BoolWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  number?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumPhoneNumberStatusWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  loaSignedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  loaSignedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  portingStartAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  incomingCarrier?: Maybe<StringNullableWithAggregatesFilter>;
  isVerified?: Maybe<BoolWithAggregatesFilter>;
  portingOrderId?: Maybe<StringNullableWithAggregatesFilter>;
  loaUrl?: Maybe<StringNullableWithAggregatesFilter>;
  portingOrderStatus?: Maybe<StringNullableWithAggregatesFilter>;
  isTestSmsDelivered?: Maybe<BoolWithAggregatesFilter>;
  campaignRegistryCampaignId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicPhoneNumberSubscription = {
  __typename?: 'ClinicPhoneNumberSubscription';
  node: ClinicPhoneNumber;
  mutation: MutationType;
};

export type ClinicPhoneNumberUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  clinicId: Scalars['String'];
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutClinicPhoneNumberInput>;
};

export type ClinicPhoneNumberUncheckedCreateNestedManyWithoutCampaignRegistryCampaignInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutCampaignRegistryCampaignInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutCampaignRegistryCampaignInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyCampaignRegistryCampaignInputEnvelope>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
};

export type ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
};

export type ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutLoaSignedByUserInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutLoaSignedByUserInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyLoaSignedByUserInputEnvelope>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
};

export type ClinicPhoneNumberUncheckedCreateWithoutCampaignRegistryCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  clinicId: Scalars['String'];
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutClinicPhoneNumberInput>;
};

export type ClinicPhoneNumberUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutClinicPhoneNumberInput>;
};

export type ClinicPhoneNumberUncheckedCreateWithoutLoaSignedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  clinicId: Scalars['String'];
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutClinicPhoneNumberInput>;
};

export type ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  clinicId: Scalars['String'];
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumberUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinicId?: Maybe<Scalars['String']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutClinicPhoneNumberNestedInput>;
};

export type ClinicPhoneNumberUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinicId?: Maybe<Scalars['String']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumberUncheckedUpdateManyWithoutCampaignRegistryCampaignNestedInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutCampaignRegistryCampaignInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutCampaignRegistryCampaignInput>>;
  upsert?: Maybe<Array<ClinicPhoneNumberUpsertWithWhereUniqueWithoutCampaignRegistryCampaignInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyCampaignRegistryCampaignInputEnvelope>;
  set?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPhoneNumberUpdateWithWhereUniqueWithoutCampaignRegistryCampaignInput>>;
  updateMany?: Maybe<Array<ClinicPhoneNumberUpdateManyWithWhereWithoutCampaignRegistryCampaignInput>>;
  deleteMany?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
};

export type ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPhoneNumberUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPhoneNumberUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPhoneNumberUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
};

export type ClinicPhoneNumberUncheckedUpdateManyWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumberUncheckedUpdateManyWithoutClinicPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinicId?: Maybe<Scalars['String']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
};

export type ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutLoaSignedByUserInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutLoaSignedByUserInput>>;
  upsert?: Maybe<Array<ClinicPhoneNumberUpsertWithWhereUniqueWithoutLoaSignedByUserInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyLoaSignedByUserInputEnvelope>;
  set?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPhoneNumberUpdateWithWhereUniqueWithoutLoaSignedByUserInput>>;
  updateMany?: Maybe<Array<ClinicPhoneNumberUpdateManyWithWhereWithoutLoaSignedByUserInput>>;
  deleteMany?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
};

export type ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedClinicPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinicId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumberUncheckedUpdateWithoutCampaignRegistryCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinicId?: Maybe<Scalars['String']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutClinicPhoneNumberNestedInput>;
};

export type ClinicPhoneNumberUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutClinicPhoneNumberNestedInput>;
};

export type ClinicPhoneNumberUncheckedUpdateWithoutLoaSignedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinicId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutClinicPhoneNumberNestedInput>;
};

export type ClinicPhoneNumberUncheckedUpdateWithoutSmsNotificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinicId?: Maybe<Scalars['String']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumberUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicPhoneNumberNestedInput>;
  loaSignedByUser?: Maybe<UserUpdateOneWithoutLoaSignedClinicPhoneNumbersNestedInput>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutClinicPhoneNumberNestedInput>;
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignUpdateOneWithoutClinicPhoneNumbersNestedInput>;
};

export type ClinicPhoneNumberUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
};

export type ClinicPhoneNumberUpdateManyWithWhereWithoutCampaignRegistryCampaignInput = {
  where: ClinicPhoneNumberScalarWhereInput;
  data: ClinicPhoneNumberUncheckedUpdateManyWithoutClinicPhoneNumbersInput;
};

export type ClinicPhoneNumberUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicPhoneNumberScalarWhereInput;
  data: ClinicPhoneNumberUncheckedUpdateManyWithoutClinicPhoneNumberInput;
};

export type ClinicPhoneNumberUpdateManyWithWhereWithoutLoaSignedByUserInput = {
  where: ClinicPhoneNumberScalarWhereInput;
  data: ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedClinicPhoneNumbersInput;
};

export type ClinicPhoneNumberUpdateManyWithoutCampaignRegistryCampaignNestedInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutCampaignRegistryCampaignInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutCampaignRegistryCampaignInput>>;
  upsert?: Maybe<Array<ClinicPhoneNumberUpsertWithWhereUniqueWithoutCampaignRegistryCampaignInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyCampaignRegistryCampaignInputEnvelope>;
  set?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPhoneNumberUpdateWithWhereUniqueWithoutCampaignRegistryCampaignInput>>;
  updateMany?: Maybe<Array<ClinicPhoneNumberUpdateManyWithWhereWithoutCampaignRegistryCampaignInput>>;
  deleteMany?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
};

export type ClinicPhoneNumberUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPhoneNumberUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPhoneNumberUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPhoneNumberUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
};

export type ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutLoaSignedByUserInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutLoaSignedByUserInput>>;
  upsert?: Maybe<Array<ClinicPhoneNumberUpsertWithWhereUniqueWithoutLoaSignedByUserInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyLoaSignedByUserInputEnvelope>;
  set?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPhoneNumberUpdateWithWhereUniqueWithoutLoaSignedByUserInput>>;
  updateMany?: Maybe<Array<ClinicPhoneNumberUpdateManyWithWhereWithoutLoaSignedByUserInput>>;
  deleteMany?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
};

export type ClinicPhoneNumberUpdateOneWithoutSmsNotificationStatusesNestedInput = {
  create?: Maybe<ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput>;
  connectOrCreate?: Maybe<ClinicPhoneNumberCreateOrConnectWithoutSmsNotificationStatusesInput>;
  upsert?: Maybe<ClinicPhoneNumberUpsertWithoutSmsNotificationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  update?: Maybe<ClinicPhoneNumberUncheckedUpdateWithoutSmsNotificationStatusesInput>;
};

export type ClinicPhoneNumberUpdateWithWhereUniqueWithoutCampaignRegistryCampaignInput = {
  where: ClinicPhoneNumberWhereUniqueInput;
  data: ClinicPhoneNumberUncheckedUpdateWithoutCampaignRegistryCampaignInput;
};

export type ClinicPhoneNumberUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicPhoneNumberWhereUniqueInput;
  data: ClinicPhoneNumberUncheckedUpdateWithoutClinicInput;
};

export type ClinicPhoneNumberUpdateWithWhereUniqueWithoutLoaSignedByUserInput = {
  where: ClinicPhoneNumberWhereUniqueInput;
  data: ClinicPhoneNumberUncheckedUpdateWithoutLoaSignedByUserInput;
};

export type ClinicPhoneNumberUpdateWithoutCampaignRegistryCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicPhoneNumberNestedInput>;
  loaSignedByUser?: Maybe<UserUpdateOneWithoutLoaSignedClinicPhoneNumbersNestedInput>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutClinicPhoneNumberNestedInput>;
};

export type ClinicPhoneNumberUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  loaSignedByUser?: Maybe<UserUpdateOneWithoutLoaSignedClinicPhoneNumbersNestedInput>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutClinicPhoneNumberNestedInput>;
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignUpdateOneWithoutClinicPhoneNumbersNestedInput>;
};

export type ClinicPhoneNumberUpdateWithoutLoaSignedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicPhoneNumberNestedInput>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutClinicPhoneNumberNestedInput>;
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignUpdateOneWithoutClinicPhoneNumbersNestedInput>;
};

export type ClinicPhoneNumberUpdateWithoutSmsNotificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  portingOrderId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicPhoneNumberNestedInput>;
  loaSignedByUser?: Maybe<UserUpdateOneWithoutLoaSignedClinicPhoneNumbersNestedInput>;
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignUpdateOneWithoutClinicPhoneNumbersNestedInput>;
};

export type ClinicPhoneNumberUpsertWithWhereUniqueWithoutCampaignRegistryCampaignInput = {
  where: ClinicPhoneNumberWhereUniqueInput;
  update: ClinicPhoneNumberUncheckedUpdateWithoutCampaignRegistryCampaignInput;
  create: ClinicPhoneNumberUncheckedCreateWithoutCampaignRegistryCampaignInput;
};

export type ClinicPhoneNumberUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicPhoneNumberWhereUniqueInput;
  update: ClinicPhoneNumberUncheckedUpdateWithoutClinicInput;
  create: ClinicPhoneNumberUncheckedCreateWithoutClinicInput;
};

export type ClinicPhoneNumberUpsertWithWhereUniqueWithoutLoaSignedByUserInput = {
  where: ClinicPhoneNumberWhereUniqueInput;
  update: ClinicPhoneNumberUncheckedUpdateWithoutLoaSignedByUserInput;
  create: ClinicPhoneNumberUncheckedCreateWithoutLoaSignedByUserInput;
};

export type ClinicPhoneNumberUpsertWithoutSmsNotificationStatusesInput = {
  update: ClinicPhoneNumberUncheckedUpdateWithoutSmsNotificationStatusesInput;
  create: ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput;
};

export type ClinicPhoneNumberWhereInput = {
  AND?: Maybe<Array<ClinicPhoneNumberWhereInput>>;
  OR?: Maybe<Array<ClinicPhoneNumberWhereInput>>;
  NOT?: Maybe<Array<ClinicPhoneNumberWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  capability?: Maybe<EnumServiceCapabilityFilter>;
  provider?: Maybe<EnumServiceProviderFilter>;
  isPrimary?: Maybe<BoolFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  number?: Maybe<StringFilter>;
  status?: Maybe<EnumPhoneNumberStatusFilter>;
  clinicId?: Maybe<StringFilter>;
  loaSignedByUserId?: Maybe<StringNullableFilter>;
  loaSignedAt?: Maybe<DateTimeNullableFilter>;
  portingStartAt?: Maybe<DateTimeNullableFilter>;
  incomingCarrier?: Maybe<StringNullableFilter>;
  isVerified?: Maybe<BoolFilter>;
  portingOrderId?: Maybe<StringNullableFilter>;
  loaUrl?: Maybe<StringNullableFilter>;
  portingOrderStatus?: Maybe<StringNullableFilter>;
  isTestSmsDelivered?: Maybe<BoolFilter>;
  campaignRegistryCampaignId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  loaSignedByUser?: Maybe<UserWhereInput>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusListRelationFilter>;
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignWhereInput>;
};

export type ClinicPhoneNumberWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  capabilityAndNumberAndProvider?: Maybe<ClinicPhoneNumberCapabilityAndNumberAndProviderCompoundUniqueInput>;
};

export type ClinicPimsIntegration = {
  __typename?: 'ClinicPimsIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency: PimsSyncFrequency;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration: Scalars['Json'];
  clinic?: Maybe<Clinic>;
  datapoint?: Maybe<DataPointIntegration>;
  ezyvet?: Maybe<EzyVetIntegration>;
  instinct?: Maybe<InstinctIntegration>;
  neo?: Maybe<NeoIntegration>;
  vetdata?: Maybe<VetDataIntegration>;
  syncvet?: Maybe<SyncVetIntegration>;
  vetter?: Maybe<VetterIntegration>;
  bitwerx?: Maybe<BitwerxIntegration>;
  ppc?: Maybe<PpcIntegration>;
  clientConnect?: Maybe<ClientConnectIntegration>;
  appointments: Array<Appointment>;
  availabilities: Array<AppointmentAvailability>;
  appointmentTypes: Array<AppointmentType>;
  employees: Array<ClinicEmployee>;
  pets: Array<ClinicPet>;
  petParents: Array<ClinicPetParent>;
  rooms: Array<ClinicRoom>;
  services: Array<Service>;
  serviceReminders: Array<ServiceReminder>;
  pimsInvoices: Array<PimsInvoice>;
  pimsInvoiceLineItems: Array<PimsInvoiceLineItem>;
  capabilities: Array<ClinicPimsIntegrationCapability>;
  breeds: Array<Breed>;
  colors: Array<Color>;
  species: Array<Species>;
  sexes: Array<Sex>;
  writebackLogEntries: Array<PimsWritebackLogEntry>;
  vetAvailabilities: Array<VetAvailability>;
  vaccinations: Array<Vaccination>;
  prescriptions: Array<Prescription>;
  integrationOutageHistoryEntry: Array<IntegrationOutageHistoryEntry>;
  capabilityHistoryEntries: Array<ClinicPimsIntegrationCapabilityHistoryEntry>;
  serviceCategories: Array<ServiceCategory>;
  pimsPaymentTypes: Array<PimsPaymentType>;
  bitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  customFieldDefinitions: Array<CustomFieldDefinition>;
  _count: ClinicPimsIntegrationCountOutputType;
};


export type ClinicPimsIntegrationAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};


export type ClinicPimsIntegrationAvailabilitiesArgs = {
  where?: Maybe<AppointmentAvailabilityWhereInput>;
  orderBy?: Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentAvailabilityScalarFieldEnum>;
};


export type ClinicPimsIntegrationAppointmentTypesArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentTypeScalarFieldEnum>;
};


export type ClinicPimsIntegrationEmployeesArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeScalarFieldEnum>;
};


export type ClinicPimsIntegrationPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};


export type ClinicPimsIntegrationPetParentsArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};


export type ClinicPimsIntegrationRoomsArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
  orderBy?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicRoomScalarFieldEnum>;
};


export type ClinicPimsIntegrationServicesArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceScalarFieldEnum>;
};


export type ClinicPimsIntegrationServiceRemindersArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderScalarFieldEnum>;
};


export type ClinicPimsIntegrationPimsInvoicesArgs = {
  where?: Maybe<PimsInvoiceWhereInput>;
  orderBy?: Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PimsInvoiceScalarFieldEnum>;
};


export type ClinicPimsIntegrationPimsInvoiceLineItemsArgs = {
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
  orderBy?: Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PimsInvoiceLineItemScalarFieldEnum>;
};


export type ClinicPimsIntegrationCapabilitiesArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  orderBy?: Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPimsIntegrationCapabilityScalarFieldEnum>;
};


export type ClinicPimsIntegrationBreedsArgs = {
  where?: Maybe<BreedWhereInput>;
  orderBy?: Maybe<BreedOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BreedWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BreedScalarFieldEnum>;
};


export type ClinicPimsIntegrationColorsArgs = {
  where?: Maybe<ColorWhereInput>;
  orderBy?: Maybe<ColorOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ColorWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ColorScalarFieldEnum>;
};


export type ClinicPimsIntegrationSpeciesArgs = {
  where?: Maybe<SpeciesWhereInput>;
  orderBy?: Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SpeciesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SpeciesScalarFieldEnum>;
};


export type ClinicPimsIntegrationSexesArgs = {
  where?: Maybe<SexWhereInput>;
  orderBy?: Maybe<SexOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SexWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SexScalarFieldEnum>;
};


export type ClinicPimsIntegrationWritebackLogEntriesArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PimsWritebackLogEntryScalarFieldEnum>;
};


export type ClinicPimsIntegrationVetAvailabilitiesArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
  orderBy?: Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetAvailabilityScalarFieldEnum>;
};


export type ClinicPimsIntegrationVaccinationsArgs = {
  where?: Maybe<VaccinationWhereInput>;
  orderBy?: Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VaccinationScalarFieldEnum>;
};


export type ClinicPimsIntegrationPrescriptionsArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
};


export type ClinicPimsIntegrationIntegrationOutageHistoryEntryArgs = {
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
  orderBy?: Maybe<IntegrationOutageHistoryEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<IntegrationOutageHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<IntegrationOutageHistoryEntryScalarFieldEnum>;
};


export type ClinicPimsIntegrationCapabilityHistoryEntriesArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
  orderBy?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryScalarFieldEnum>;
};


export type ClinicPimsIntegrationServiceCategoriesArgs = {
  where?: Maybe<ServiceCategoryWhereInput>;
  orderBy?: Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceCategoryScalarFieldEnum>;
};


export type ClinicPimsIntegrationPimsPaymentTypesArgs = {
  where?: Maybe<PimsPaymentTypeWhereInput>;
  orderBy?: Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PimsPaymentTypeScalarFieldEnum>;
};


export type ClinicPimsIntegrationBitwerxPaymentWritebackConfigurationsArgs = {
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
};


export type ClinicPimsIntegrationCustomFieldDefinitionsArgs = {
  where?: Maybe<CustomFieldDefinitionWhereInput>;
  orderBy?: Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CustomFieldDefinitionScalarFieldEnum>;
};

export type ClinicPimsIntegrationCapability = {
  __typename?: 'ClinicPimsIntegrationCapability';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability: PimsIntegrationCapability;
  integrationId: Scalars['String'];
  integration: ClinicPimsIntegration;
};

export type ClinicPimsIntegrationCapabilityAvgAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityAvgAggregateOutputType';
  syncFrequencyInMinutes?: Maybe<Scalars['Float']>;
};

export type ClinicPimsIntegrationCapabilityAvgOrderByAggregateInput = {
  syncFrequencyInMinutes?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityCountAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  lastSyncedAt: Scalars['Int'];
  status: Scalars['Int'];
  statusChangedAt: Scalars['Int'];
  statusErrorMessage: Scalars['Int'];
  isActive: Scalars['Int'];
  syncFrequencyInMinutes: Scalars['Int'];
  capability: Scalars['Int'];
  integrationId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPimsIntegrationCapabilityCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability: PimsIntegrationCapability;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutCapabilitiesInput;
};

export type ClinicPimsIntegrationCapabilityCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability: PimsIntegrationCapability;
  integrationId: Scalars['String'];
};

export type ClinicPimsIntegrationCapabilityCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability: PimsIntegrationCapability;
};

export type ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicPimsIntegrationCapabilityCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
};

export type ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput = {
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
  create: ClinicPimsIntegrationCapabilityUncheckedCreateWithoutIntegrationInput;
};

export type ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability: PimsIntegrationCapability;
};

export type ClinicPimsIntegrationCapabilityHistoryEntry = {
  __typename?: 'ClinicPimsIntegrationCapabilityHistoryEntry';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  integrationId: Scalars['String'];
  capability: PimsIntegrationCapability;
  syncDuration?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  recordsSync: Scalars['Int'];
  statusMessage?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegration;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryAvgAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityHistoryEntryAvgAggregateOutputType';
  syncDuration?: Maybe<Scalars['Float']>;
  recordsSync?: Maybe<Scalars['Float']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryAvgOrderByAggregateInput = {
  syncDuration?: Maybe<SortOrder>;
  recordsSync?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCountAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityHistoryEntryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  integrationId: Scalars['Int'];
  capability: Scalars['Int'];
  syncDuration: Scalars['Int'];
  status: Scalars['Int'];
  recordsSync: Scalars['Int'];
  statusMessage: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  syncDuration?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  recordsSync?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  capability: PimsIntegrationCapability;
  syncDuration?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  recordsSync: Scalars['Int'];
  statusMessage?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutCapabilityHistoryEntriesInput;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  capability: PimsIntegrationCapability;
  syncDuration?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  recordsSync: Scalars['Int'];
  statusMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  capability: PimsIntegrationCapability;
  syncDuration?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  recordsSync: Scalars['Int'];
  statusMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCreateOrConnectWithoutIntegrationInput = {
  where: ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput;
  create: ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateWithoutIntegrationInput;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  capability: PimsIntegrationCapability;
  syncDuration?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  recordsSync: Scalars['Int'];
  statusMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryListRelationFilter = {
  every?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
  some?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
  none?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryMaxAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityHistoryEntryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  capability?: Maybe<PimsIntegrationCapability>;
  syncDuration?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  statusMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  syncDuration?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  recordsSync?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryMinAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityHistoryEntryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  capability?: Maybe<PimsIntegrationCapability>;
  syncDuration?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  statusMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  syncDuration?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  recordsSync?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPimsIntegrationCapabilityHistoryEntryOrderByRelevanceFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  Status = 'status',
  StatusMessage = 'statusMessage'
}

export type ClinicPimsIntegrationCapabilityHistoryEntryOrderByRelevanceInput = {
  fields: Array<ClinicPimsIntegrationCapabilityHistoryEntryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPimsIntegrationCapabilityHistoryEntryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  syncDuration?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  recordsSync?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntrySumOrderByAggregateInput>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  syncDuration?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  recordsSync?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryOrderByRelevanceInput>;
};

export enum ClinicPimsIntegrationCapabilityHistoryEntryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  IntegrationId = 'integrationId',
  Capability = 'capability',
  SyncDuration = 'syncDuration',
  Status = 'status',
  RecordsSync = 'recordsSync',
  StatusMessage = 'statusMessage'
}

export type ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  integrationId?: Maybe<StringFilter>;
  capability?: Maybe<EnumPimsIntegrationCapabilityFilter>;
  syncDuration?: Maybe<IntNullableFilter>;
  status?: Maybe<StringFilter>;
  recordsSync?: Maybe<IntFilter>;
  statusMessage?: Maybe<StringNullableFilter>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  capability?: Maybe<EnumPimsIntegrationCapabilityWithAggregatesFilter>;
  syncDuration?: Maybe<IntNullableWithAggregatesFilter>;
  status?: Maybe<StringWithAggregatesFilter>;
  recordsSync?: Maybe<IntWithAggregatesFilter>;
  statusMessage?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntrySumAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityHistoryEntrySumAggregateOutputType';
  syncDuration?: Maybe<Scalars['Int']>;
  recordsSync?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntrySumOrderByAggregateInput = {
  syncDuration?: Maybe<SortOrder>;
  recordsSync?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  capability: PimsIntegrationCapability;
  syncDuration?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  recordsSync: Scalars['Int'];
  statusMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  capability: PimsIntegrationCapability;
  syncDuration?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  recordsSync: Scalars['Int'];
  statusMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  capability?: Maybe<PimsIntegrationCapability>;
  syncDuration?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  statusMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  capability?: Maybe<PimsIntegrationCapability>;
  syncDuration?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  statusMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutCapabilityHistoryEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<PimsIntegrationCapability>;
  syncDuration?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  statusMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereInput>>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<PimsIntegrationCapability>;
  syncDuration?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  statusMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<PimsIntegrationCapability>;
  syncDuration?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  statusMessage?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutCapabilityHistoryEntriesNestedInput>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<PimsIntegrationCapability>;
  syncDuration?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  statusMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithWhereWithoutIntegrationInput = {
  where: ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereInput;
  data: ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutCapabilityHistoryEntriesInput;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereInput>>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput;
  data: ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateWithoutIntegrationInput;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<PimsIntegrationCapability>;
  syncDuration?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  statusMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput;
  update: ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateWithoutIntegrationInput;
  create: ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateWithoutIntegrationInput;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  integrationId?: Maybe<StringFilter>;
  capability?: Maybe<EnumPimsIntegrationCapabilityFilter>;
  syncDuration?: Maybe<IntNullableFilter>;
  status?: Maybe<StringFilter>;
  recordsSync?: Maybe<IntFilter>;
  statusMessage?: Maybe<StringNullableFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityIntegrationIdCapabilityCompoundUniqueInput = {
  integrationId: Scalars['String'];
  capability: PimsIntegrationCapability;
};

export type ClinicPimsIntegrationCapabilityListRelationFilter = {
  every?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  some?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  none?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
};

export type ClinicPimsIntegrationCapabilityMaxAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability?: Maybe<PimsIntegrationCapability>;
  integrationId?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityMinAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability?: Maybe<PimsIntegrationCapability>;
  integrationId?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPimsIntegrationCapabilityOrderByRelevanceFieldEnum {
  Id = 'id',
  StatusErrorMessage = 'statusErrorMessage',
  IntegrationId = 'integrationId'
}

export type ClinicPimsIntegrationCapabilityOrderByRelevanceInput = {
  fields: Array<ClinicPimsIntegrationCapabilityOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPimsIntegrationCapabilityOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPimsIntegrationCapabilityCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicPimsIntegrationCapabilityAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicPimsIntegrationCapabilityMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPimsIntegrationCapabilityMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicPimsIntegrationCapabilitySumOrderByAggregateInput>;
};

export type ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicPimsIntegrationCapabilityOrderByRelevanceInput>;
};

export enum ClinicPimsIntegrationCapabilityScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  LastSyncedAt = 'lastSyncedAt',
  Status = 'status',
  StatusChangedAt = 'statusChangedAt',
  StatusErrorMessage = 'statusErrorMessage',
  IsActive = 'isActive',
  SyncFrequencyInMinutes = 'syncFrequencyInMinutes',
  Capability = 'capability',
  IntegrationId = 'integrationId'
}

export type ClinicPimsIntegrationCapabilityScalarWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableFilter>;
  statusChangedAt?: Maybe<DateTimeNullableFilter>;
  statusErrorMessage?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  syncFrequencyInMinutes?: Maybe<IntNullableFilter>;
  capability?: Maybe<EnumPimsIntegrationCapabilityFilter>;
  integrationId?: Maybe<StringFilter>;
};

export type ClinicPimsIntegrationCapabilityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableWithAggregatesFilter>;
  statusChangedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  statusErrorMessage?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  syncFrequencyInMinutes?: Maybe<IntNullableWithAggregatesFilter>;
  capability?: Maybe<EnumPimsIntegrationCapabilityWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicPimsIntegrationCapabilitySumAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilitySumAggregateOutputType';
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilitySumOrderByAggregateInput = {
  syncFrequencyInMinutes?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability: PimsIntegrationCapability;
  integrationId: Scalars['String'];
};

export type ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
};

export type ClinicPimsIntegrationCapabilityUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability: PimsIntegrationCapability;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability?: Maybe<PimsIntegrationCapability>;
  integrationId?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability?: Maybe<PimsIntegrationCapability>;
  integrationId?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutCapabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability?: Maybe<PimsIntegrationCapability>;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationCapabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationCapabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationCapabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability?: Maybe<PimsIntegrationCapability>;
};

export type ClinicPimsIntegrationCapabilityUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability?: Maybe<PimsIntegrationCapability>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutCapabilitiesNestedInput>;
};

export type ClinicPimsIntegrationCapabilityUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability?: Maybe<PimsIntegrationCapability>;
};

export type ClinicPimsIntegrationCapabilityUpdateManyWithWhereWithoutIntegrationInput = {
  where: ClinicPimsIntegrationCapabilityScalarWhereInput;
  data: ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutCapabilitiesInput;
};

export type ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationCapabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationCapabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationCapabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
};

export type ClinicPimsIntegrationCapabilityUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
  data: ClinicPimsIntegrationCapabilityUncheckedUpdateWithoutIntegrationInput;
};

export type ClinicPimsIntegrationCapabilityUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  capability?: Maybe<PimsIntegrationCapability>;
};

export type ClinicPimsIntegrationCapabilityUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
  update: ClinicPimsIntegrationCapabilityUncheckedUpdateWithoutIntegrationInput;
  create: ClinicPimsIntegrationCapabilityUncheckedCreateWithoutIntegrationInput;
};

export type ClinicPimsIntegrationCapabilityWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableFilter>;
  statusChangedAt?: Maybe<DateTimeNullableFilter>;
  statusErrorMessage?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  syncFrequencyInMinutes?: Maybe<IntNullableFilter>;
  capability?: Maybe<EnumPimsIntegrationCapabilityFilter>;
  integrationId?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type ClinicPimsIntegrationCapabilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  integrationId_capability?: Maybe<ClinicPimsIntegrationCapabilityIntegrationIdCapabilityCompoundUniqueInput>;
};

export type ClinicPimsIntegrationCountAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isActive: Scalars['Int'];
  lastSyncedAt: Scalars['Int'];
  lastBatchSyncedAt: Scalars['Int'];
  syncFrequency: Scalars['Int'];
  type: Scalars['Int'];
  bitwerxId: Scalars['Int'];
  clinicId: Scalars['Int'];
  datapointId: Scalars['Int'];
  ezyvetId: Scalars['Int'];
  instinctId: Scalars['Int'];
  neoId: Scalars['Int'];
  vetterId: Scalars['Int'];
  vetdataId: Scalars['Int'];
  syncvetId: Scalars['Int'];
  ppcId: Scalars['Int'];
  clientConnectId: Scalars['Int'];
  status: Scalars['Int'];
  statusChangedAt: Scalars['Int'];
  statusErrorMessage: Scalars['Int'];
  pauseUntil: Scalars['Int'];
  writebackConfiguration: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPimsIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  bitwerxId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  datapointId?: Maybe<SortOrder>;
  ezyvetId?: Maybe<SortOrder>;
  instinctId?: Maybe<SortOrder>;
  neoId?: Maybe<SortOrder>;
  vetterId?: Maybe<SortOrder>;
  vetdataId?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  ppcId?: Maybe<SortOrder>;
  clientConnectId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  pauseUntil?: Maybe<SortOrder>;
  writebackConfiguration?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCountOutputType = {
  __typename?: 'ClinicPimsIntegrationCountOutputType';
  appointments: Scalars['Int'];
  availabilities: Scalars['Int'];
  appointmentTypes: Scalars['Int'];
  employees: Scalars['Int'];
  pets: Scalars['Int'];
  petParents: Scalars['Int'];
  rooms: Scalars['Int'];
  services: Scalars['Int'];
  serviceReminders: Scalars['Int'];
  pimsInvoices: Scalars['Int'];
  pimsInvoiceLineItems: Scalars['Int'];
  capabilities: Scalars['Int'];
  breeds: Scalars['Int'];
  colors: Scalars['Int'];
  species: Scalars['Int'];
  sexes: Scalars['Int'];
  writebackLogEntries: Scalars['Int'];
  vetAvailabilities: Scalars['Int'];
  vaccinations: Scalars['Int'];
  prescriptions: Scalars['Int'];
  integrationOutageHistoryEntry: Scalars['Int'];
  capabilityHistoryEntries: Scalars['Int'];
  serviceCategories: Scalars['Int'];
  pimsPaymentTypes: Scalars['Int'];
  bitwerxPaymentWritebackConfigurations: Scalars['Int'];
  customFieldDefinitions: Scalars['Int'];
};

export type ClinicPimsIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationCreateManyClinicInputEnvelope = {
  data: Array<ClinicPimsIntegrationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPimsIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAppointmentTypesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutAvailabilitiesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAvailabilitiesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutBitwerxInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxPaymentWritebackConfigurationsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutBreedsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBreedsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBreedsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutCapabilitiesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutCapabilitiesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutCapabilityHistoryEntriesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutCapabilityHistoryEntriesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutCapabilityHistoryEntriesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutClientConnectInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutClientConnectInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutClientConnectInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutColorsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutColorsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutColorsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutCustomFieldDefinitionsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutCustomFieldDefinitionsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutCustomFieldDefinitionsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutDatapointInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEmployeesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutEzyvetInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutInstinctInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutIntegrationOutageHistoryEntryInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutIntegrationOutageHistoryEntryInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutIntegrationOutageHistoryEntryInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutNeoInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutNeoInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutNeoInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPetParentsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPetsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPetsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoiceLineItemsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoiceLineItemsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoicesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoicesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsPaymentTypesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsPaymentTypesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPpcInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPpcInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPpcInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPrescriptionsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutRoomsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutServiceCategoriesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutServiceCategoriesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutServiceCategoriesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutServiceRemindersInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutServiceRemindersInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutServicesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutServicesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutServicesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutSexesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSexesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSexesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutSpeciesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSpeciesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSpeciesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutSyncvetInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSyncvetInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSyncvetInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutVaccinationsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVaccinationsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutVetAvailabilitiesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetAvailabilitiesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutVetdataInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutVetterInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetterInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetterInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutWritebackLogEntriesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutAppointmentTypesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutAppointmentsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutAvailabilitiesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutAvailabilitiesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutBitwerxPaymentWritebackConfigurationsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutBreedsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutBreedsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutCapabilitiesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutCapabilityHistoryEntriesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutCapabilityHistoryEntriesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutClientConnectInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutClientConnectInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutClinicInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutClinicInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutColorsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutColorsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutCustomFieldDefinitionsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutCustomFieldDefinitionsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutEmployeesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutIntegrationOutageHistoryEntryInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutIntegrationOutageHistoryEntryInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutNeoInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutNeoInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPetParentsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPetsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPetsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoiceLineItemsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoicesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPimsPaymentTypesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsPaymentTypesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPpcInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPpcInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPrescriptionsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutRoomsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutServiceCategoriesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutServiceCategoriesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutServiceRemindersInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutServiceRemindersInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutServicesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutServicesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutSexesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutSexesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutSpeciesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutSpeciesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutSyncvetInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutSyncvetInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutVaccinationsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutVetAvailabilitiesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutVetterInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetterInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutWritebackLogEntriesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput;
};

export type ClinicPimsIntegrationCreateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutBitwerxInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutCapabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutCapabilityHistoryEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutClientConnectInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutColorsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutCustomFieldDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutDatapointInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutEzyvetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutInstinctInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutIntegrationOutageHistoryEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutNeoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPimsInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPimsInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPimsPaymentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPpcInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutSexesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutSyncvetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutVetdataInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutVetterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationListRelationFilter = {
  every?: Maybe<ClinicPimsIntegrationWhereInput>;
  some?: Maybe<ClinicPimsIntegrationWhereInput>;
  none?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type ClinicPimsIntegrationMaxAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  bitwerxId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  datapointId?: Maybe<SortOrder>;
  ezyvetId?: Maybe<SortOrder>;
  instinctId?: Maybe<SortOrder>;
  neoId?: Maybe<SortOrder>;
  vetterId?: Maybe<SortOrder>;
  vetdataId?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  ppcId?: Maybe<SortOrder>;
  clientConnectId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  pauseUntil?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationMinAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  bitwerxId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  datapointId?: Maybe<SortOrder>;
  ezyvetId?: Maybe<SortOrder>;
  instinctId?: Maybe<SortOrder>;
  neoId?: Maybe<SortOrder>;
  vetterId?: Maybe<SortOrder>;
  vetdataId?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  ppcId?: Maybe<SortOrder>;
  clientConnectId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  pauseUntil?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPimsIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  BitwerxId = 'bitwerxId',
  ClinicId = 'clinicId',
  DatapointId = 'datapointId',
  EzyvetId = 'ezyvetId',
  InstinctId = 'instinctId',
  NeoId = 'neoId',
  VetterId = 'vetterId',
  VetdataId = 'vetdataId',
  SyncvetId = 'syncvetId',
  PpcId = 'ppcId',
  ClientConnectId = 'clientConnectId',
  StatusErrorMessage = 'statusErrorMessage'
}

export type ClinicPimsIntegrationOrderByRelevanceInput = {
  fields: Array<ClinicPimsIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPimsIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  bitwerxId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  datapointId?: Maybe<SortOrder>;
  ezyvetId?: Maybe<SortOrder>;
  instinctId?: Maybe<SortOrder>;
  neoId?: Maybe<SortOrder>;
  vetterId?: Maybe<SortOrder>;
  vetdataId?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  ppcId?: Maybe<SortOrder>;
  clientConnectId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  pauseUntil?: Maybe<SortOrder>;
  writebackConfiguration?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPimsIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPimsIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPimsIntegrationMinOrderByAggregateInput>;
};

export type ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  bitwerxId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  datapointId?: Maybe<SortOrder>;
  ezyvetId?: Maybe<SortOrder>;
  instinctId?: Maybe<SortOrder>;
  neoId?: Maybe<SortOrder>;
  vetterId?: Maybe<SortOrder>;
  vetdataId?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  ppcId?: Maybe<SortOrder>;
  clientConnectId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  pauseUntil?: Maybe<SortOrder>;
  writebackConfiguration?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  datapoint?: Maybe<DataPointIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  ezyvet?: Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  instinct?: Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  neo?: Maybe<NeoIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  vetdata?: Maybe<VetDataIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  syncvet?: Maybe<SyncVetIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  vetter?: Maybe<VetterIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  bitwerx?: Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  ppc?: Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  clientConnect?: Maybe<ClientConnectIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  availabilities?: Maybe<AppointmentAvailabilityOrderByRelationAggregateInput>;
  appointmentTypes?: Maybe<AppointmentTypeOrderByRelationAggregateInput>;
  employees?: Maybe<ClinicEmployeeOrderByRelationAggregateInput>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  petParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  rooms?: Maybe<ClinicRoomOrderByRelationAggregateInput>;
  services?: Maybe<ServiceOrderByRelationAggregateInput>;
  serviceReminders?: Maybe<ServiceReminderOrderByRelationAggregateInput>;
  pimsInvoices?: Maybe<PimsInvoiceOrderByRelationAggregateInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemOrderByRelationAggregateInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityOrderByRelationAggregateInput>;
  breeds?: Maybe<BreedOrderByRelationAggregateInput>;
  colors?: Maybe<ColorOrderByRelationAggregateInput>;
  species?: Maybe<SpeciesOrderByRelationAggregateInput>;
  sexes?: Maybe<SexOrderByRelationAggregateInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryOrderByRelationAggregateInput>;
  vetAvailabilities?: Maybe<VetAvailabilityOrderByRelationAggregateInput>;
  vaccinations?: Maybe<VaccinationOrderByRelationAggregateInput>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryOrderByRelationAggregateInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryOrderByRelationAggregateInput>;
  serviceCategories?: Maybe<ServiceCategoryOrderByRelationAggregateInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeOrderByRelationAggregateInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicPimsIntegrationOrderByRelevanceInput>;
};

export type ClinicPimsIntegrationRelationFilter = {
  is?: Maybe<ClinicPimsIntegrationWhereInput>;
  isNot?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export enum ClinicPimsIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsActive = 'isActive',
  LastSyncedAt = 'lastSyncedAt',
  LastBatchSyncedAt = 'lastBatchSyncedAt',
  SyncFrequency = 'syncFrequency',
  Type = 'type',
  BitwerxId = 'bitwerxId',
  ClinicId = 'clinicId',
  DatapointId = 'datapointId',
  EzyvetId = 'ezyvetId',
  InstinctId = 'instinctId',
  NeoId = 'neoId',
  VetterId = 'vetterId',
  VetdataId = 'vetdataId',
  SyncvetId = 'syncvetId',
  PpcId = 'ppcId',
  ClientConnectId = 'clientConnectId',
  Status = 'status',
  StatusChangedAt = 'statusChangedAt',
  StatusErrorMessage = 'statusErrorMessage',
  PauseUntil = 'pauseUntil',
  WritebackConfiguration = 'writebackConfiguration'
}

export type ClinicPimsIntegrationScalarWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  lastBatchSyncedAt?: Maybe<DateTimeNullableFilter>;
  syncFrequency?: Maybe<EnumPimsSyncFrequencyFilter>;
  type?: Maybe<EnumClinicPimsIntegrationTypeFilter>;
  bitwerxId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  datapointId?: Maybe<StringNullableFilter>;
  ezyvetId?: Maybe<StringNullableFilter>;
  instinctId?: Maybe<StringNullableFilter>;
  neoId?: Maybe<StringNullableFilter>;
  vetterId?: Maybe<StringNullableFilter>;
  vetdataId?: Maybe<StringNullableFilter>;
  syncvetId?: Maybe<StringNullableFilter>;
  ppcId?: Maybe<StringNullableFilter>;
  clientConnectId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableFilter>;
  statusChangedAt?: Maybe<DateTimeNullableFilter>;
  statusErrorMessage?: Maybe<StringNullableFilter>;
  pauseUntil?: Maybe<DateTimeNullableFilter>;
  writebackConfiguration?: Maybe<JsonFilter>;
};

export type ClinicPimsIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastBatchSyncedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  syncFrequency?: Maybe<EnumPimsSyncFrequencyWithAggregatesFilter>;
  type?: Maybe<EnumClinicPimsIntegrationTypeWithAggregatesFilter>;
  bitwerxId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  datapointId?: Maybe<StringNullableWithAggregatesFilter>;
  ezyvetId?: Maybe<StringNullableWithAggregatesFilter>;
  instinctId?: Maybe<StringNullableWithAggregatesFilter>;
  neoId?: Maybe<StringNullableWithAggregatesFilter>;
  vetterId?: Maybe<StringNullableWithAggregatesFilter>;
  vetdataId?: Maybe<StringNullableWithAggregatesFilter>;
  syncvetId?: Maybe<StringNullableWithAggregatesFilter>;
  ppcId?: Maybe<StringNullableWithAggregatesFilter>;
  clientConnectId?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableWithAggregatesFilter>;
  statusChangedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  statusErrorMessage?: Maybe<StringNullableWithAggregatesFilter>;
  pauseUntil?: Maybe<DateTimeNullableWithAggregatesFilter>;
  writebackConfiguration?: Maybe<JsonWithAggregatesFilter>;
};

export enum ClinicPimsIntegrationType {
  Bitwerx = 'Bitwerx',
  Datapoint = 'Datapoint',
  Ezyvet = 'Ezyvet',
  Instinct = 'Instinct',
  Neo = 'Neo',
  Vetdata = 'Vetdata',
  Vetter = 'Vetter',
  Televet = 'Televet',
  Ppc = 'PPC',
  ClientConnect = 'ClientConnect',
  SyncVet = 'SyncVet'
}

export type ClinicPimsIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutBitwerxInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutClientConnectInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutClientConnectInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutClientConnectInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutDatapointInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutEzyvetInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutInstinctInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutNeoInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutNeoInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutNeoInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutPpcInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPpcInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPpcInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutSyncvetInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSyncvetInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSyncvetInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutVetdataInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutVetterInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetterInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetterInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutCapabilityHistoryEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutClientConnectInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutColorsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutCustomFieldDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutIntegrationOutageHistoryEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutNeoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPimsPaymentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPpcInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutSexesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutSyncvetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutVetterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
};

export type ClinicPimsIntegrationUncheckedUpdateManyWithoutIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutBitwerxNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutBitwerxInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutClientConnectNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutClientConnectInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutClientConnectInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutClientConnectInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutClientConnectInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutDatapointNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutDatapointInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutDatapointInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutEzyvetNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutEzyvetInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutEzyvetInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutInstinctNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutInstinctInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutInstinctInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutNeoNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutNeoInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutNeoInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutNeoInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutNeoInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutPpcNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPpcInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPpcInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPpcInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPpcInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutSyncvetNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSyncvetInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSyncvetInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutSyncvetInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutSyncvetInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutVetdataNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetdataInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetdataInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutVetterNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetterInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetterInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetterInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetterInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutCapabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutCapabilityHistoryEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutClientConnectInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutColorsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutCustomFieldDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutDatapointInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutEzyvetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutInstinctInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutIntegrationOutageHistoryEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutNeoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPimsPaymentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPpcInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutSexesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutSyncvetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutVetdataInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutVetterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicPimsIntegrationScalarWhereInput;
  data: ClinicPimsIntegrationUncheckedUpdateManyWithoutIntegrationsInput;
};

export type ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutBitwerxPaymentWritebackConfigurationsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxPaymentWritebackConfigurationsInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutBreedsNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBreedsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBreedsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutBreedsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutBreedsInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutCapabilitiesNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutCapabilitiesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutCapabilitiesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutCapabilitiesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutCapabilityHistoryEntriesNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutCapabilityHistoryEntriesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutCapabilityHistoryEntriesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutCapabilityHistoryEntriesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutCapabilityHistoryEntriesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutColorsNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutColorsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutColorsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutColorsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutColorsInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutCustomFieldDefinitionsNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutCustomFieldDefinitionsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutCustomFieldDefinitionsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutCustomFieldDefinitionsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutCustomFieldDefinitionsInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutIntegrationOutageHistoryEntryNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutIntegrationOutageHistoryEntryInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutIntegrationOutageHistoryEntryInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutIntegrationOutageHistoryEntryInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutIntegrationOutageHistoryEntryInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoiceLineItemsNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoiceLineItemsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPimsInvoiceLineItemsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoiceLineItemsInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoicesNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoicesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPimsInvoicesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoicesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsPaymentTypesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsPaymentTypesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPimsPaymentTypesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPimsPaymentTypesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPrescriptionsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPrescriptionsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPrescriptionsInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutServiceCategoriesNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutServiceCategoriesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutServiceCategoriesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutServiceCategoriesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutServiceCategoriesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutServiceRemindersInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutServiceRemindersInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutServiceRemindersInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutServiceRemindersInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutServicesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutServicesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutServicesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutServicesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutSexesNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSexesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSexesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutSexesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutSexesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutSpeciesNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSpeciesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSpeciesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutSpeciesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutSpeciesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutWritebackLogEntriesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutWritebackLogEntriesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutWritebackLogEntriesInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAppointmentTypesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutAppointmentTypesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentTypesInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutAvailabilitiesNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAvailabilitiesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutAvailabilitiesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutAvailabilitiesInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutBitwerxNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutBitwerxInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutClientConnectNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutClientConnectInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutClientConnectInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutClientConnectInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutClientConnectInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutDatapointNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutDatapointInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutDatapointInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEmployeesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutEmployeesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutEmployeesInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutEzyvetNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutEzyvetInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutEzyvetInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutInstinctNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutInstinctInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutInstinctInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutNeoNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutNeoInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutNeoInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutNeoInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutNeoInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPetParentsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPetParentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPetParentsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPetsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPetsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPetsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutPpcNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPpcInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPpcInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPpcInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPpcInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutRoomsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutRoomsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutRoomsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutSyncvetNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSyncvetInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSyncvetInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutSyncvetInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutSyncvetInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutVaccinationsNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVaccinationsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVaccinationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVaccinationsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutVetAvailabilitiesNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetAvailabilitiesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetAvailabilitiesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetAvailabilitiesInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutVetdataNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetdataInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetdataInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutVetterNestedInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetterInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetterInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetterInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetterInput>;
};

export type ClinicPimsIntegrationUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  data: ClinicPimsIntegrationUncheckedUpdateWithoutClinicInput;
};

export type ClinicPimsIntegrationUpdateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutBitwerxInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutCapabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutCapabilityHistoryEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutClientConnectInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutColorsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutCustomFieldDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutDatapointInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutEzyvetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutInstinctInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutIntegrationOutageHistoryEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutNeoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPimsInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPimsInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPimsPaymentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPpcInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutSexesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutSyncvetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutVetdataInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutVetterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutClinicInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutClinicInput;
};

export type ClinicPimsIntegrationUpsertWithoutAppointmentTypesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentTypesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput;
};

export type ClinicPimsIntegrationUpsertWithoutAppointmentsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicPimsIntegrationUpsertWithoutAvailabilitiesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutAvailabilitiesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutAvailabilitiesInput;
};

export type ClinicPimsIntegrationUpsertWithoutBitwerxInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput;
};

export type ClinicPimsIntegrationUpsertWithoutBitwerxPaymentWritebackConfigurationsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxPaymentWritebackConfigurationsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput;
};

export type ClinicPimsIntegrationUpsertWithoutBreedsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutBreedsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutBreedsInput;
};

export type ClinicPimsIntegrationUpsertWithoutCapabilitiesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutCapabilitiesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput;
};

export type ClinicPimsIntegrationUpsertWithoutCapabilityHistoryEntriesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutCapabilityHistoryEntriesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutCapabilityHistoryEntriesInput;
};

export type ClinicPimsIntegrationUpsertWithoutClientConnectInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutClientConnectInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutClientConnectInput;
};

export type ClinicPimsIntegrationUpsertWithoutColorsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutColorsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutColorsInput;
};

export type ClinicPimsIntegrationUpsertWithoutCustomFieldDefinitionsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutCustomFieldDefinitionsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutCustomFieldDefinitionsInput;
};

export type ClinicPimsIntegrationUpsertWithoutDatapointInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutDatapointInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput;
};

export type ClinicPimsIntegrationUpsertWithoutEmployeesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutEmployeesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput;
};

export type ClinicPimsIntegrationUpsertWithoutEzyvetInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutEzyvetInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput;
};

export type ClinicPimsIntegrationUpsertWithoutInstinctInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutInstinctInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput;
};

export type ClinicPimsIntegrationUpsertWithoutIntegrationOutageHistoryEntryInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutIntegrationOutageHistoryEntryInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutIntegrationOutageHistoryEntryInput;
};

export type ClinicPimsIntegrationUpsertWithoutNeoInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutNeoInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutNeoInput;
};

export type ClinicPimsIntegrationUpsertWithoutPetParentsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPetParentsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput;
};

export type ClinicPimsIntegrationUpsertWithoutPetsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPetsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPetsInput;
};

export type ClinicPimsIntegrationUpsertWithoutPimsInvoiceLineItemsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoiceLineItemsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput;
};

export type ClinicPimsIntegrationUpsertWithoutPimsInvoicesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoicesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput;
};

export type ClinicPimsIntegrationUpsertWithoutPimsPaymentTypesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPimsPaymentTypesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsPaymentTypesInput;
};

export type ClinicPimsIntegrationUpsertWithoutPpcInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPpcInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPpcInput;
};

export type ClinicPimsIntegrationUpsertWithoutPrescriptionsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPrescriptionsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicPimsIntegrationUpsertWithoutRoomsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutRoomsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput;
};

export type ClinicPimsIntegrationUpsertWithoutServiceCategoriesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutServiceCategoriesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutServiceCategoriesInput;
};

export type ClinicPimsIntegrationUpsertWithoutServiceRemindersInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutServiceRemindersInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutServiceRemindersInput;
};

export type ClinicPimsIntegrationUpsertWithoutServicesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutServicesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutServicesInput;
};

export type ClinicPimsIntegrationUpsertWithoutSexesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutSexesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutSexesInput;
};

export type ClinicPimsIntegrationUpsertWithoutSpeciesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutSpeciesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutSpeciesInput;
};

export type ClinicPimsIntegrationUpsertWithoutSyncvetInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutSyncvetInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutSyncvetInput;
};

export type ClinicPimsIntegrationUpsertWithoutVaccinationsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutVaccinationsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput;
};

export type ClinicPimsIntegrationUpsertWithoutVetAvailabilitiesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutVetAvailabilitiesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput;
};

export type ClinicPimsIntegrationUpsertWithoutVetdataInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutVetdataInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput;
};

export type ClinicPimsIntegrationUpsertWithoutVetterInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutVetterInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetterInput;
};

export type ClinicPimsIntegrationUpsertWithoutWritebackLogEntriesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutWritebackLogEntriesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput;
};

export type ClinicPimsIntegrationWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  lastBatchSyncedAt?: Maybe<DateTimeNullableFilter>;
  syncFrequency?: Maybe<EnumPimsSyncFrequencyFilter>;
  type?: Maybe<EnumClinicPimsIntegrationTypeFilter>;
  bitwerxId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  datapointId?: Maybe<StringNullableFilter>;
  ezyvetId?: Maybe<StringNullableFilter>;
  instinctId?: Maybe<StringNullableFilter>;
  neoId?: Maybe<StringNullableFilter>;
  vetterId?: Maybe<StringNullableFilter>;
  vetdataId?: Maybe<StringNullableFilter>;
  syncvetId?: Maybe<StringNullableFilter>;
  ppcId?: Maybe<StringNullableFilter>;
  clientConnectId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableFilter>;
  statusChangedAt?: Maybe<DateTimeNullableFilter>;
  statusErrorMessage?: Maybe<StringNullableFilter>;
  pauseUntil?: Maybe<DateTimeNullableFilter>;
  writebackConfiguration?: Maybe<JsonFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  datapoint?: Maybe<DataPointIntegrationWhereInput>;
  ezyvet?: Maybe<EzyVetIntegrationWhereInput>;
  instinct?: Maybe<InstinctIntegrationWhereInput>;
  neo?: Maybe<NeoIntegrationWhereInput>;
  vetdata?: Maybe<VetDataIntegrationWhereInput>;
  syncvet?: Maybe<SyncVetIntegrationWhereInput>;
  vetter?: Maybe<VetterIntegrationWhereInput>;
  bitwerx?: Maybe<BitwerxIntegrationWhereInput>;
  ppc?: Maybe<PpcIntegrationWhereInput>;
  clientConnect?: Maybe<ClientConnectIntegrationWhereInput>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  availabilities?: Maybe<AppointmentAvailabilityListRelationFilter>;
  appointmentTypes?: Maybe<AppointmentTypeListRelationFilter>;
  employees?: Maybe<ClinicEmployeeListRelationFilter>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  petParents?: Maybe<ClinicPetParentListRelationFilter>;
  rooms?: Maybe<ClinicRoomListRelationFilter>;
  services?: Maybe<ServiceListRelationFilter>;
  serviceReminders?: Maybe<ServiceReminderListRelationFilter>;
  pimsInvoices?: Maybe<PimsInvoiceListRelationFilter>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemListRelationFilter>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityListRelationFilter>;
  breeds?: Maybe<BreedListRelationFilter>;
  colors?: Maybe<ColorListRelationFilter>;
  species?: Maybe<SpeciesListRelationFilter>;
  sexes?: Maybe<SexListRelationFilter>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryListRelationFilter>;
  vetAvailabilities?: Maybe<VetAvailabilityListRelationFilter>;
  vaccinations?: Maybe<VaccinationListRelationFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryListRelationFilter>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryListRelationFilter>;
  serviceCategories?: Maybe<ServiceCategoryListRelationFilter>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeListRelationFilter>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionListRelationFilter>;
};

export type ClinicPimsIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  bitwerxId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSetting = {
  __typename?: 'ClinicPostcardSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  isRemindersEnabled: Scalars['Boolean'];
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  reminderTemplate: Scalars['String'];
  clinic: Clinic;
};

export type ClinicPostcardSettingCountAggregateOutputType = {
  __typename?: 'ClinicPostcardSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  isRemindersEnabled: Scalars['Int'];
  apiKey: Scalars['Int'];
  apiSecret: Scalars['Int'];
  reminderTemplate: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPostcardSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
};

export type ClinicPostcardSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  reminderTemplate: Scalars['String'];
  clinic: ClinicCreateNestedOneWithoutPostcardSettingsInput;
};

export type ClinicPostcardSettingCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  reminderTemplate: Scalars['String'];
};

export type ClinicPostcardSettingCreateManyClinicInputEnvelope = {
  data: Array<ClinicPostcardSettingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPostcardSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  reminderTemplate: Scalars['String'];
};

export type ClinicPostcardSettingCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPostcardSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPostcardSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPostcardSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
};

export type ClinicPostcardSettingCreateOrConnectWithoutClinicInput = {
  where: ClinicPostcardSettingWhereUniqueInput;
  create: ClinicPostcardSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicPostcardSettingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  reminderTemplate: Scalars['String'];
};

export type ClinicPostcardSettingListRelationFilter = {
  every?: Maybe<ClinicPostcardSettingWhereInput>;
  some?: Maybe<ClinicPostcardSettingWhereInput>;
  none?: Maybe<ClinicPostcardSettingWhereInput>;
};

export type ClinicPostcardSettingMaxAggregateOutputType = {
  __typename?: 'ClinicPostcardSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
};

export type ClinicPostcardSettingMinAggregateOutputType = {
  __typename?: 'ClinicPostcardSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
};

export type ClinicPostcardSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPostcardSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  ApiKey = 'apiKey',
  ApiSecret = 'apiSecret',
  ReminderTemplate = 'reminderTemplate'
}

export type ClinicPostcardSettingOrderByRelevanceInput = {
  fields: Array<ClinicPostcardSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPostcardSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPostcardSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPostcardSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPostcardSettingMinOrderByAggregateInput>;
};

export type ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicPostcardSettingOrderByRelevanceInput>;
};

export enum ClinicPostcardSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  IsRemindersEnabled = 'isRemindersEnabled',
  ApiKey = 'apiKey',
  ApiSecret = 'apiSecret',
  ReminderTemplate = 'reminderTemplate'
}

export type ClinicPostcardSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  isRemindersEnabled?: Maybe<BoolFilter>;
  apiKey?: Maybe<StringFilter>;
  apiSecret?: Maybe<StringFilter>;
  reminderTemplate?: Maybe<StringFilter>;
};

export type ClinicPostcardSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPostcardSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPostcardSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPostcardSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  isRemindersEnabled?: Maybe<BoolWithAggregatesFilter>;
  apiKey?: Maybe<StringWithAggregatesFilter>;
  apiSecret?: Maybe<StringWithAggregatesFilter>;
  reminderTemplate?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicPostcardSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  reminderTemplate: Scalars['String'];
};

export type ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPostcardSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPostcardSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPostcardSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
};

export type ClinicPostcardSettingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  reminderTemplate: Scalars['String'];
};

export type ClinicPostcardSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicPostcardSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPostcardSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPostcardSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPostcardSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPostcardSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPostcardSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
};

export type ClinicPostcardSettingUncheckedUpdateManyWithoutPostcardSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPostcardSettingsNestedInput>;
};

export type ClinicPostcardSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicPostcardSettingScalarWhereInput;
  data: ClinicPostcardSettingUncheckedUpdateManyWithoutPostcardSettingsInput;
};

export type ClinicPostcardSettingUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicPostcardSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPostcardSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPostcardSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPostcardSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPostcardSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPostcardSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
};

export type ClinicPostcardSettingUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicPostcardSettingWhereUniqueInput;
  data: ClinicPostcardSettingUncheckedUpdateWithoutClinicInput;
};

export type ClinicPostcardSettingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicPostcardSettingWhereUniqueInput;
  update: ClinicPostcardSettingUncheckedUpdateWithoutClinicInput;
  create: ClinicPostcardSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicPostcardSettingWhereInput = {
  AND?: Maybe<Array<ClinicPostcardSettingWhereInput>>;
  OR?: Maybe<Array<ClinicPostcardSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicPostcardSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  isRemindersEnabled?: Maybe<BoolFilter>;
  apiKey?: Maybe<StringFilter>;
  apiSecret?: Maybe<StringFilter>;
  reminderTemplate?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type ClinicPostcardSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicRelationFilter = {
  is?: Maybe<ClinicWhereInput>;
  isNot?: Maybe<ClinicWhereInput>;
};

export type ClinicRoom = {
  __typename?: 'ClinicRoom';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible: Scalars['Boolean'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<Clinic>;
  integration?: Maybe<ClinicPimsIntegration>;
  appointments: Array<Appointment>;
  appointmentAvailability: Array<AppointmentAvailability>;
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  workflows: Array<ClinicRoomToWorkflowEventSetting>;
  vetAvailabilityClinicRoomMaps: Array<VetAvailabilityClinicRoomMap>;
  ProviderAppointmentTypeRoomMap: Array<ProviderAppointmentTypeRoomMap>;
  _count: ClinicRoomCountOutputType;
};


export type ClinicRoomAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};


export type ClinicRoomAppointmentAvailabilityArgs = {
  where?: Maybe<AppointmentAvailabilityWhereInput>;
  orderBy?: Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentAvailabilityScalarFieldEnum>;
};


export type ClinicRoomUserAppointmentGroupingsArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
};


export type ClinicRoomWorkflowsArgs = {
  where?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
  orderBy?: Maybe<ClinicRoomToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicRoomToWorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicRoomToWorkflowEventSettingScalarFieldEnum>;
};


export type ClinicRoomVetAvailabilityClinicRoomMapsArgs = {
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
  orderBy?: Maybe<VetAvailabilityClinicRoomMapOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetAvailabilityClinicRoomMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetAvailabilityClinicRoomMapScalarFieldEnum>;
};


export type ClinicRoomProviderAppointmentTypeRoomMapArgs = {
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
  orderBy?: Maybe<ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ProviderAppointmentTypeRoomMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ProviderAppointmentTypeRoomMapScalarFieldEnum>;
};

export type ClinicRoomClinicRoomPimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type ClinicRoomCountAggregateOutputType = {
  __typename?: 'ClinicRoomCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  name: Scalars['Int'];
  pimsId: Scalars['Int'];
  clinicId: Scalars['Int'];
  integrationId: Scalars['Int'];
  isSchedulable: Scalars['Int'];
  type: Scalars['Int'];
  isVisible: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicRoomCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ClinicRoomCountOutputType = {
  __typename?: 'ClinicRoomCountOutputType';
  appointments: Scalars['Int'];
  appointmentAvailability: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  workflows: Scalars['Int'];
  vetAvailabilityClinicRoomMaps: Scalars['Int'];
  ProviderAppointmentTypeRoomMap: Scalars['Int'];
};

export type ClinicRoomCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomCreateManyClinicInputEnvelope = {
  data: Array<ClinicRoomCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicRoomCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicRoomCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
};

export type ClinicRoomCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicRoomCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
};

export type ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
};

export type ClinicRoomCreateNestedOneWithoutAppointmentAvailabilityInput = {
  create?: Maybe<ClinicRoomUncheckedCreateWithoutAppointmentAvailabilityInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutAppointmentAvailabilityInput>;
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
};

export type ClinicRoomCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<ClinicRoomUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
};

export type ClinicRoomCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput = {
  create?: Maybe<ClinicRoomUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput>;
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
};

export type ClinicRoomCreateNestedOneWithoutVetAvailabilityClinicRoomMapsInput = {
  create?: Maybe<ClinicRoomUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutVetAvailabilityClinicRoomMapsInput>;
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
};

export type ClinicRoomCreateNestedOneWithoutWorkflowsInput = {
  create?: Maybe<ClinicRoomUncheckedCreateWithoutWorkflowsInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutWorkflowsInput>;
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
};

export type ClinicRoomCreateOrConnectWithoutAppointmentAvailabilityInput = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomUncheckedCreateWithoutAppointmentAvailabilityInput;
};

export type ClinicRoomCreateOrConnectWithoutAppointmentsInput = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicRoomCreateOrConnectWithoutClinicInput = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomUncheckedCreateWithoutClinicInput;
};

export type ClinicRoomCreateOrConnectWithoutIntegrationInput = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomUncheckedCreateWithoutIntegrationInput;
};

export type ClinicRoomCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput;
};

export type ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type ClinicRoomCreateOrConnectWithoutVetAvailabilityClinicRoomMapsInput = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput;
};

export type ClinicRoomCreateOrConnectWithoutWorkflowsInput = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomUncheckedCreateWithoutWorkflowsInput;
};

export type ClinicRoomCreateWithoutAppointmentAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomCreateWithoutProviderAppointmentTypeRoomMapInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomCreateWithoutVetAvailabilityClinicRoomMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomListRelationFilter = {
  every?: Maybe<ClinicRoomWhereInput>;
  some?: Maybe<ClinicRoomWhereInput>;
  none?: Maybe<ClinicRoomWhereInput>;
};

export type ClinicRoomMaxAggregateOutputType = {
  __typename?: 'ClinicRoomMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
};

export type ClinicRoomMinAggregateOutputType = {
  __typename?: 'ClinicRoomMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
};

export type ClinicRoomOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicRoomOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  PimsId = 'pimsId',
  ClinicId = 'clinicId',
  IntegrationId = 'integrationId',
  Type = 'type'
}

export type ClinicRoomOrderByRelevanceInput = {
  fields: Array<ClinicRoomOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicRoomOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ClinicRoomCountOrderByAggregateInput>;
  _max?: Maybe<ClinicRoomMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicRoomMinOrderByAggregateInput>;
};

export type ClinicRoomOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityOrderByRelationAggregateInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingOrderByRelationAggregateInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapOrderByRelationAggregateInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicRoomOrderByRelevanceInput>;
};

export type ClinicRoomRelationFilter = {
  is?: Maybe<ClinicRoomWhereInput>;
  isNot?: Maybe<ClinicRoomWhereInput>;
};

export enum ClinicRoomScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Name = 'name',
  PimsId = 'pimsId',
  ClinicId = 'clinicId',
  IntegrationId = 'integrationId',
  IsSchedulable = 'isSchedulable',
  Type = 'type',
  IsVisible = 'isVisible',
  RawPimsValue = 'rawPimsValue'
}

export type ClinicRoomScalarWhereInput = {
  AND?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  OR?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isSchedulable?: Maybe<BoolNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  isVisible?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ClinicRoomScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicRoomScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicRoomScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicRoomScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  isSchedulable?: Maybe<BoolNullableWithAggregatesFilter>;
  type?: Maybe<StringNullableWithAggregatesFilter>;
  isVisible?: Maybe<BoolWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicRoomToWorkflowEventSetting = {
  __typename?: 'ClinicRoomToWorkflowEventSetting';
  createdAt: Scalars['DateTime'];
  workflowId: Scalars['String'];
  clinicRoomId: Scalars['String'];
  workflow: WorkflowEventSetting;
  clinicRoom: ClinicRoom;
};

export type ClinicRoomToWorkflowEventSettingCountAggregateOutputType = {
  __typename?: 'ClinicRoomToWorkflowEventSettingCountAggregateOutputType';
  createdAt: Scalars['Int'];
  workflowId: Scalars['Int'];
  clinicRoomId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicRoomToWorkflowEventSettingCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
};

export type ClinicRoomToWorkflowEventSettingCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutClinicRoomsInput;
  clinicRoom: ClinicRoomCreateNestedOneWithoutWorkflowsInput;
};

export type ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope = {
  data: Array<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomToWorkflowEventSettingCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  clinicRoomId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingCreateManyWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoomId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope = {
  data: Array<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput = {
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
  create: ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutClinicRoomInput;
};

export type ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput = {
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
  create: ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput;
};

export type ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutClinicRoomsInput;
};

export type ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoom: ClinicRoomCreateNestedOneWithoutWorkflowsInput;
};

export type ClinicRoomToWorkflowEventSettingListRelationFilter = {
  every?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
  some?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
  none?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
};

export type ClinicRoomToWorkflowEventSettingMaxAggregateOutputType = {
  __typename?: 'ClinicRoomToWorkflowEventSettingMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
};

export type ClinicRoomToWorkflowEventSettingMinAggregateOutputType = {
  __typename?: 'ClinicRoomToWorkflowEventSettingMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
};

export type ClinicRoomToWorkflowEventSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicRoomToWorkflowEventSettingOrderByRelevanceFieldEnum {
  WorkflowId = 'workflowId',
  ClinicRoomId = 'clinicRoomId'
}

export type ClinicRoomToWorkflowEventSettingOrderByRelevanceInput = {
  fields: Array<ClinicRoomToWorkflowEventSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingOrderByWithAggregationInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicRoomToWorkflowEventSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicRoomToWorkflowEventSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicRoomToWorkflowEventSettingMinOrderByAggregateInput>;
};

export type ClinicRoomToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
  workflow?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicRoom?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicRoomToWorkflowEventSettingOrderByRelevanceInput>;
};

export enum ClinicRoomToWorkflowEventSettingScalarFieldEnum {
  CreatedAt = 'createdAt',
  WorkflowId = 'workflowId',
  ClinicRoomId = 'clinicRoomId'
}

export type ClinicRoomToWorkflowEventSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
  clinicRoomId?: Maybe<StringFilter>;
};

export type ClinicRoomToWorkflowEventSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  workflowId?: Maybe<StringWithAggregatesFilter>;
  clinicRoomId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  clinicRoomId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoomId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput>>;
  upsert?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicRoomInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope>;
  set?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicRoomInput>>;
  updateMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutClinicRoomInput>>;
  deleteMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  upsert?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  set?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput>>;
  deleteMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutClinicRoomsNestedInput>;
  clinicRoom?: Maybe<ClinicRoomUpdateOneRequiredWithoutWorkflowsNestedInput>;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutClinicRoomInput = {
  where: ClinicRoomToWorkflowEventSettingScalarWhereInput;
  data: ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput = {
  where: ClinicRoomToWorkflowEventSettingScalarWhereInput;
  data: ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomsInput;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput>>;
  upsert?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicRoomInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope>;
  set?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicRoomInput>>;
  updateMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutClinicRoomInput>>;
  deleteMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  upsert?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  set?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput>>;
  deleteMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicRoomInput = {
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
  data: ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutClinicRoomInput;
};

export type ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput = {
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
  data: ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput;
};

export type ClinicRoomToWorkflowEventSettingUpdateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutClinicRoomsNestedInput>;
};

export type ClinicRoomToWorkflowEventSettingUpdateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoom?: Maybe<ClinicRoomUpdateOneRequiredWithoutWorkflowsNestedInput>;
};

export type ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicRoomInput = {
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
  update: ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutClinicRoomInput;
  create: ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutClinicRoomInput;
};

export type ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput = {
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
  update: ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput;
  create: ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput;
};

export type ClinicRoomToWorkflowEventSettingWhereInput = {
  AND?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereInput>>;
  OR?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
  clinicRoomId?: Maybe<StringFilter>;
  workflow?: Maybe<WorkflowEventSettingWhereInput>;
  clinicRoom?: Maybe<ClinicRoomWhereInput>;
};

export type ClinicRoomToWorkflowEventSettingWhereUniqueInput = {
  workflowId_clinicRoomId?: Maybe<ClinicRoomToWorkflowEventSettingWorkflowIdClinicRoomIdCompoundUniqueInput>;
};

export type ClinicRoomToWorkflowEventSettingWorkflowIdClinicRoomIdCompoundUniqueInput = {
  workflowId: Scalars['String'];
  clinicRoomId: Scalars['String'];
};

export type ClinicRoomUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicRoomCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
};

export type ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicRoomCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
};

export type ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
};

export type ClinicRoomUncheckedCreateWithoutAppointmentAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomUncheckedCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
};

export type ClinicRoomUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicRoomCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
};

export type ClinicRoomUncheckedUpdateManyWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicRoomCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
};

export type ClinicRoomUncheckedUpdateManyWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
};

export type ClinicRoomUncheckedUpdateWithoutAppointmentAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateWithoutVetAvailabilityClinicRoomMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicRoomScalarWhereInput;
  data: ClinicRoomUncheckedUpdateManyWithoutClinicRoomsInput;
};

export type ClinicRoomUpdateManyWithWhereWithoutIntegrationInput = {
  where: ClinicRoomScalarWhereInput;
  data: ClinicRoomUncheckedUpdateManyWithoutRoomsInput;
};

export type ClinicRoomUpdateManyWithWhereWithoutUserAppointmentGroupingsInput = {
  where: ClinicRoomScalarWhereInput;
  data: ClinicRoomUncheckedUpdateManyWithoutRoomsInput;
};

export type ClinicRoomUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicRoomCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
};

export type ClinicRoomUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicRoomCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
};

export type ClinicRoomUpdateManyWithoutUserAppointmentGroupingsNestedInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
};

export type ClinicRoomUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput = {
  create?: Maybe<ClinicRoomUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput>;
  upsert?: Maybe<ClinicRoomUpsertWithoutProviderAppointmentTypeRoomMapInput>;
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  update?: Maybe<ClinicRoomUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput>;
};

export type ClinicRoomUpdateOneRequiredWithoutVetAvailabilityClinicRoomMapsNestedInput = {
  create?: Maybe<ClinicRoomUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutVetAvailabilityClinicRoomMapsInput>;
  upsert?: Maybe<ClinicRoomUpsertWithoutVetAvailabilityClinicRoomMapsInput>;
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  update?: Maybe<ClinicRoomUncheckedUpdateWithoutVetAvailabilityClinicRoomMapsInput>;
};

export type ClinicRoomUpdateOneRequiredWithoutWorkflowsNestedInput = {
  create?: Maybe<ClinicRoomUncheckedCreateWithoutWorkflowsInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutWorkflowsInput>;
  upsert?: Maybe<ClinicRoomUpsertWithoutWorkflowsInput>;
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  update?: Maybe<ClinicRoomUncheckedUpdateWithoutWorkflowsInput>;
};

export type ClinicRoomUpdateOneWithoutAppointmentAvailabilityNestedInput = {
  create?: Maybe<ClinicRoomUncheckedCreateWithoutAppointmentAvailabilityInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutAppointmentAvailabilityInput>;
  upsert?: Maybe<ClinicRoomUpsertWithoutAppointmentAvailabilityInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  update?: Maybe<ClinicRoomUncheckedUpdateWithoutAppointmentAvailabilityInput>;
};

export type ClinicRoomUpdateOneWithoutAppointmentsNestedInput = {
  create?: Maybe<ClinicRoomUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<ClinicRoomUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  update?: Maybe<ClinicRoomUncheckedUpdateWithoutAppointmentsInput>;
};

export type ClinicRoomUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicRoomWhereUniqueInput;
  data: ClinicRoomUncheckedUpdateWithoutClinicInput;
};

export type ClinicRoomUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicRoomWhereUniqueInput;
  data: ClinicRoomUncheckedUpdateWithoutIntegrationInput;
};

export type ClinicRoomUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  where: ClinicRoomWhereUniqueInput;
  data: ClinicRoomUncheckedUpdateWithoutUserAppointmentGroupingsInput;
};

export type ClinicRoomUpdateWithoutAppointmentAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUpdateWithoutProviderAppointmentTypeRoomMapInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUpdateWithoutVetAvailabilityClinicRoomMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
};

export type ClinicRoomUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicRoomWhereUniqueInput;
  update: ClinicRoomUncheckedUpdateWithoutClinicInput;
  create: ClinicRoomUncheckedCreateWithoutClinicInput;
};

export type ClinicRoomUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicRoomWhereUniqueInput;
  update: ClinicRoomUncheckedUpdateWithoutIntegrationInput;
  create: ClinicRoomUncheckedCreateWithoutIntegrationInput;
};

export type ClinicRoomUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  where: ClinicRoomWhereUniqueInput;
  update: ClinicRoomUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  create: ClinicRoomUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type ClinicRoomUpsertWithoutAppointmentAvailabilityInput = {
  update: ClinicRoomUncheckedUpdateWithoutAppointmentAvailabilityInput;
  create: ClinicRoomUncheckedCreateWithoutAppointmentAvailabilityInput;
};

export type ClinicRoomUpsertWithoutAppointmentsInput = {
  update: ClinicRoomUncheckedUpdateWithoutAppointmentsInput;
  create: ClinicRoomUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicRoomUpsertWithoutProviderAppointmentTypeRoomMapInput = {
  update: ClinicRoomUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput;
  create: ClinicRoomUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput;
};

export type ClinicRoomUpsertWithoutVetAvailabilityClinicRoomMapsInput = {
  update: ClinicRoomUncheckedUpdateWithoutVetAvailabilityClinicRoomMapsInput;
  create: ClinicRoomUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput;
};

export type ClinicRoomUpsertWithoutWorkflowsInput = {
  update: ClinicRoomUncheckedUpdateWithoutWorkflowsInput;
  create: ClinicRoomUncheckedCreateWithoutWorkflowsInput;
};

export type ClinicRoomWhereInput = {
  AND?: Maybe<Array<ClinicRoomWhereInput>>;
  OR?: Maybe<Array<ClinicRoomWhereInput>>;
  NOT?: Maybe<Array<ClinicRoomWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isSchedulable?: Maybe<BoolNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  isVisible?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityListRelationFilter>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingListRelationFilter>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapListRelationFilter>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapListRelationFilter>;
};

export type ClinicRoomWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicRoomPimsIdAndIntegrationId?: Maybe<ClinicRoomClinicRoomPimsIdAndIntegrationIdCompoundUniqueInput>;
};

export enum ClinicScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Name = 'name',
  City = 'city',
  State = 'state',
  Country = 'country',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  PostalCode = 'postalCode',
  WebsiteUrl = 'websiteUrl',
  Hours = 'hours',
  Logo = 'logo',
  OptimizedLogoUrl = 'optimizedLogoUrl',
  LastPrintOrderedOn = 'lastPrintOrderedOn',
  LastPrintOrderedQuantity = 'lastPrintOrderedQuantity',
  SubscribedAt = 'subscribedAt',
  ConsultationCost = 'consultationCost',
  FollowUpCost = 'followUpCost',
  IncomeMethod = 'incomeMethod',
  PaymentName = 'paymentName',
  PaymentAddress = 'paymentAddress',
  PaymentCity = 'paymentCity',
  PaymentState = 'paymentState',
  PaymentZip = 'paymentZip',
  PaymentBankRoutingNumber = 'paymentBankRoutingNumber',
  PaymentBankAccountNumber = 'paymentBankAccountNumber',
  FirebaseId = 'firebaseId',
  Timezone = 'timezone',
  StripeCustomerId = 'stripeCustomerId',
  Lat = 'lat',
  Long = 'long',
  FullAddress = 'fullAddress',
  StripeSubscriptionId = 'stripeSubscriptionId',
  StripeSubscriptionPlan = 'stripeSubscriptionPlan',
  Token = 'token',
  Phone = 'phone',
  WidgetSettings = 'widgetSettings',
  ResponseTime = 'responseTime',
  OrganizationId = 'organizationId',
  PpcLocationId = 'ppcLocationId',
  IntegrationType = 'integrationType',
  IntegrationInfo = 'integrationInfo',
  CorporateId = 'corporateId',
  CustomerId = 'customerId',
  SubscriptionId = 'subscriptionId',
  SubscriptionPlan = 'subscriptionPlan',
  IsIntegrationActive = 'isIntegrationActive',
  IsClinicActive = 'isClinicActive',
  UnsubscribedAt = 'unsubscribedAt',
  IsPaymentUsBank = 'isPaymentUsBank',
  IsConsultationsActive = 'isConsultationsActive',
  CancellationReason = 'cancellationReason',
  CancellationNote = 'cancellationNote',
  CanceledAt = 'canceledAt',
  AllowAllUsersToCloseConsultations = 'allowAllUsersToCloseConsultations',
  ClinicEmail = 'clinicEmail',
  FirebaseVets = 'firebaseVets',
  IntegrationSystem = 'integrationSystem',
  MarketEmail = 'marketEmail',
  MarketFacebookNumber = 'marketFacebookNumber',
  MarketFacebookPageId = 'marketFacebookPageId',
  MarketFacebookPageToken = 'marketFacebookPageToken',
  PrintLastOrder = 'printLastOrder',
  PrintReceivedQuantity = 'printReceivedQuantity',
  SubmerchantApprovedAt = 'submerchantApprovedAt',
  SubmerchantId = 'submerchantId',
  HeadUserId = 'headUserId',
  CrmId = 'crmId',
  SubmerchantStatus = 'submerchantStatus',
  SubmerchantIsActive = 'submerchantIsActive',
  CrmUrlParams = 'crmUrlParams',
  PaymentPhone = 'paymentPhone',
  AutomaticSubscriptionReceipts = 'automaticSubscriptionReceipts',
  AutoSubscriptionReceiptEmails = 'autoSubscriptionReceiptEmails',
  SubmerchantStripeId = 'submerchantStripeId',
  SubmerchantStripeStatus = 'submerchantStripeStatus',
  SubmerchantStripeChargesEnabled = 'submerchantStripeChargesEnabled',
  SubmerchantStripePayoutsEnabled = 'submerchantStripePayoutsEnabled',
  SubmerchantStripeHasCustomPayouts = 'submerchantStripeHasCustomPayouts',
  ClinicSettingId = 'clinicSettingId',
  TimezoneName = 'timezoneName',
  ClinicWidgetSettingId = 'clinicWidgetSettingId',
  ActivationDate = 'activationDate',
  IsAddAppointmentDisabled = 'isAddAppointmentDisabled',
  HasServiceReminders = 'hasServiceReminders',
  IsServiceRemindersActive = 'isServiceRemindersActive',
  RemindersIntegrationRequestEmail = 'remindersIntegrationRequestEmail',
  HasSmsConversations = 'hasSmsConversations',
  CallerIdPhoneNumber = 'callerIdPhoneNumber',
  SmsPhoneNumber = 'smsPhoneNumber',
  IsCallerIdVerified = 'isCallerIdVerified',
  LoyaltyProgramId = 'loyaltyProgramId',
  LegalEntityId = 'legalEntityId',
  UnlayerSettingId = 'unlayerSettingId',
  CarePlanProviderGroupId = 'carePlanProviderGroupId'
}

export type ClinicScalarWhereInput = {
  AND?: Maybe<Array<ClinicScalarWhereInput>>;
  OR?: Maybe<Array<ClinicScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  city?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  country?: Maybe<StringNullableFilter>;
  addressLine1?: Maybe<StringNullableFilter>;
  addressLine2?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  websiteUrl?: Maybe<StringNullableFilter>;
  hours?: Maybe<StringNullableFilter>;
  logo?: Maybe<StringNullableFilter>;
  optimizedLogoUrl?: Maybe<StringNullableFilter>;
  lastPrintOrderedOn?: Maybe<DateTimeNullableFilter>;
  lastPrintOrderedQuantity?: Maybe<IntNullableFilter>;
  subscribedAt?: Maybe<DateTimeNullableFilter>;
  consultationCost?: Maybe<FloatNullableFilter>;
  followUpCost?: Maybe<FloatNullableFilter>;
  incomeMethod?: Maybe<StringNullableFilter>;
  paymentName?: Maybe<StringNullableFilter>;
  paymentAddress?: Maybe<StringNullableFilter>;
  paymentCity?: Maybe<StringNullableFilter>;
  paymentState?: Maybe<StringNullableFilter>;
  paymentZip?: Maybe<StringNullableFilter>;
  paymentBankRoutingNumber?: Maybe<StringNullableFilter>;
  paymentBankAccountNumber?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  timezone?: Maybe<IntNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  lat?: Maybe<FloatNullableFilter>;
  long?: Maybe<FloatNullableFilter>;
  fullAddress?: Maybe<StringNullableFilter>;
  stripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeSubscriptionPlan?: Maybe<StringNullableFilter>;
  token?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  widgetSettings?: Maybe<JsonNullableFilter>;
  responseTime?: Maybe<FloatNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  ppcLocationId?: Maybe<IntNullableFilter>;
  integrationType?: Maybe<StringNullableFilter>;
  integrationInfo?: Maybe<JsonNullableFilter>;
  corporateId?: Maybe<StringNullableFilter>;
  customerId?: Maybe<StringNullableFilter>;
  subscriptionId?: Maybe<StringNullableFilter>;
  subscriptionPlan?: Maybe<StringNullableFilter>;
  isIntegrationActive?: Maybe<BoolNullableFilter>;
  isClinicActive?: Maybe<BoolFilter>;
  unsubscribedAt?: Maybe<DateTimeNullableFilter>;
  isPaymentUsBank?: Maybe<BoolNullableFilter>;
  isConsultationsActive?: Maybe<BoolNullableFilter>;
  cancellationReason?: Maybe<StringNullableFilter>;
  cancellationNote?: Maybe<StringNullableFilter>;
  canceledAt?: Maybe<DateTimeNullableFilter>;
  allowAllUsersToCloseConsultations?: Maybe<BoolNullableFilter>;
  clinicEmail?: Maybe<StringNullableFilter>;
  firebaseVets?: Maybe<JsonNullableFilter>;
  integrationSystem?: Maybe<StringNullableFilter>;
  marketEmail?: Maybe<StringNullableFilter>;
  marketFacebookNumber?: Maybe<IntNullableFilter>;
  marketFacebookPageId?: Maybe<StringNullableFilter>;
  marketFacebookPageToken?: Maybe<StringNullableFilter>;
  printLastOrder?: Maybe<DateTimeNullableFilter>;
  printReceivedQuantity?: Maybe<IntNullableFilter>;
  submerchantApprovedAt?: Maybe<DateTimeNullableFilter>;
  submerchantId?: Maybe<StringNullableFilter>;
  headUserId?: Maybe<StringNullableFilter>;
  crmId?: Maybe<StringNullableFilter>;
  submerchantStatus?: Maybe<StringNullableFilter>;
  submerchantIsActive?: Maybe<BoolNullableFilter>;
  crmUrlParams?: Maybe<JsonNullableFilter>;
  paymentPhone?: Maybe<StringNullableFilter>;
  automaticSubscriptionReceipts?: Maybe<BoolFilter>;
  autoSubscriptionReceiptEmails?: Maybe<JsonNullableFilter>;
  submerchantStripeId?: Maybe<StringNullableFilter>;
  submerchantStripeStatus?: Maybe<StringNullableFilter>;
  submerchantStripeChargesEnabled?: Maybe<BoolNullableFilter>;
  submerchantStripePayoutsEnabled?: Maybe<BoolNullableFilter>;
  submerchantStripeHasCustomPayouts?: Maybe<BoolNullableFilter>;
  clinicSettingId?: Maybe<StringNullableFilter>;
  timezoneName?: Maybe<StringNullableFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableFilter>;
  activationDate?: Maybe<DateTimeNullableFilter>;
  isAddAppointmentDisabled?: Maybe<BoolNullableFilter>;
  hasServiceReminders?: Maybe<BoolNullableFilter>;
  isServiceRemindersActive?: Maybe<BoolNullableFilter>;
  remindersIntegrationRequestEmail?: Maybe<StringNullableFilter>;
  hasSmsConversations?: Maybe<BoolNullableFilter>;
  callerIdPhoneNumber?: Maybe<StringNullableFilter>;
  smsPhoneNumber?: Maybe<StringNullableFilter>;
  isCallerIdVerified?: Maybe<BoolNullableFilter>;
  loyaltyProgramId?: Maybe<StringNullableFilter>;
  legalEntityId?: Maybe<StringNullableFilter>;
  unlayerSettingId?: Maybe<IntNullableFilter>;
  carePlanProviderGroupId?: Maybe<StringNullableFilter>;
};

export type ClinicScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  city?: Maybe<StringNullableWithAggregatesFilter>;
  state?: Maybe<StringNullableWithAggregatesFilter>;
  country?: Maybe<StringNullableWithAggregatesFilter>;
  addressLine1?: Maybe<StringNullableWithAggregatesFilter>;
  addressLine2?: Maybe<StringNullableWithAggregatesFilter>;
  postalCode?: Maybe<StringNullableWithAggregatesFilter>;
  websiteUrl?: Maybe<StringNullableWithAggregatesFilter>;
  hours?: Maybe<StringNullableWithAggregatesFilter>;
  logo?: Maybe<StringNullableWithAggregatesFilter>;
  optimizedLogoUrl?: Maybe<StringNullableWithAggregatesFilter>;
  lastPrintOrderedOn?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastPrintOrderedQuantity?: Maybe<IntNullableWithAggregatesFilter>;
  subscribedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  consultationCost?: Maybe<FloatNullableWithAggregatesFilter>;
  followUpCost?: Maybe<FloatNullableWithAggregatesFilter>;
  incomeMethod?: Maybe<StringNullableWithAggregatesFilter>;
  paymentName?: Maybe<StringNullableWithAggregatesFilter>;
  paymentAddress?: Maybe<StringNullableWithAggregatesFilter>;
  paymentCity?: Maybe<StringNullableWithAggregatesFilter>;
  paymentState?: Maybe<StringNullableWithAggregatesFilter>;
  paymentZip?: Maybe<StringNullableWithAggregatesFilter>;
  paymentBankRoutingNumber?: Maybe<StringNullableWithAggregatesFilter>;
  paymentBankAccountNumber?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  timezone?: Maybe<IntNullableWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
  lat?: Maybe<FloatNullableWithAggregatesFilter>;
  long?: Maybe<FloatNullableWithAggregatesFilter>;
  fullAddress?: Maybe<StringNullableWithAggregatesFilter>;
  stripeSubscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeSubscriptionPlan?: Maybe<StringNullableWithAggregatesFilter>;
  token?: Maybe<StringNullableWithAggregatesFilter>;
  phone?: Maybe<StringNullableWithAggregatesFilter>;
  widgetSettings?: Maybe<JsonNullableWithAggregatesFilter>;
  responseTime?: Maybe<FloatNullableWithAggregatesFilter>;
  organizationId?: Maybe<StringNullableWithAggregatesFilter>;
  ppcLocationId?: Maybe<IntNullableWithAggregatesFilter>;
  integrationType?: Maybe<StringNullableWithAggregatesFilter>;
  integrationInfo?: Maybe<JsonNullableWithAggregatesFilter>;
  corporateId?: Maybe<StringNullableWithAggregatesFilter>;
  customerId?: Maybe<StringNullableWithAggregatesFilter>;
  subscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  subscriptionPlan?: Maybe<StringNullableWithAggregatesFilter>;
  isIntegrationActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isClinicActive?: Maybe<BoolWithAggregatesFilter>;
  unsubscribedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isPaymentUsBank?: Maybe<BoolNullableWithAggregatesFilter>;
  isConsultationsActive?: Maybe<BoolNullableWithAggregatesFilter>;
  cancellationReason?: Maybe<StringNullableWithAggregatesFilter>;
  cancellationNote?: Maybe<StringNullableWithAggregatesFilter>;
  canceledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  allowAllUsersToCloseConsultations?: Maybe<BoolNullableWithAggregatesFilter>;
  clinicEmail?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseVets?: Maybe<JsonNullableWithAggregatesFilter>;
  integrationSystem?: Maybe<StringNullableWithAggregatesFilter>;
  marketEmail?: Maybe<StringNullableWithAggregatesFilter>;
  marketFacebookNumber?: Maybe<IntNullableWithAggregatesFilter>;
  marketFacebookPageId?: Maybe<StringNullableWithAggregatesFilter>;
  marketFacebookPageToken?: Maybe<StringNullableWithAggregatesFilter>;
  printLastOrder?: Maybe<DateTimeNullableWithAggregatesFilter>;
  printReceivedQuantity?: Maybe<IntNullableWithAggregatesFilter>;
  submerchantApprovedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  submerchantId?: Maybe<StringNullableWithAggregatesFilter>;
  headUserId?: Maybe<StringNullableWithAggregatesFilter>;
  crmId?: Maybe<StringNullableWithAggregatesFilter>;
  submerchantStatus?: Maybe<StringNullableWithAggregatesFilter>;
  submerchantIsActive?: Maybe<BoolNullableWithAggregatesFilter>;
  crmUrlParams?: Maybe<JsonNullableWithAggregatesFilter>;
  paymentPhone?: Maybe<StringNullableWithAggregatesFilter>;
  automaticSubscriptionReceipts?: Maybe<BoolWithAggregatesFilter>;
  autoSubscriptionReceiptEmails?: Maybe<JsonNullableWithAggregatesFilter>;
  submerchantStripeId?: Maybe<StringNullableWithAggregatesFilter>;
  submerchantStripeStatus?: Maybe<StringNullableWithAggregatesFilter>;
  submerchantStripeChargesEnabled?: Maybe<BoolNullableWithAggregatesFilter>;
  submerchantStripePayoutsEnabled?: Maybe<BoolNullableWithAggregatesFilter>;
  submerchantStripeHasCustomPayouts?: Maybe<BoolNullableWithAggregatesFilter>;
  clinicSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  timezoneName?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  activationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isAddAppointmentDisabled?: Maybe<BoolNullableWithAggregatesFilter>;
  hasServiceReminders?: Maybe<BoolNullableWithAggregatesFilter>;
  isServiceRemindersActive?: Maybe<BoolNullableWithAggregatesFilter>;
  remindersIntegrationRequestEmail?: Maybe<StringNullableWithAggregatesFilter>;
  hasSmsConversations?: Maybe<BoolNullableWithAggregatesFilter>;
  callerIdPhoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  smsPhoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  isCallerIdVerified?: Maybe<BoolNullableWithAggregatesFilter>;
  loyaltyProgramId?: Maybe<StringNullableWithAggregatesFilter>;
  legalEntityId?: Maybe<StringNullableWithAggregatesFilter>;
  unlayerSettingId?: Maybe<IntNullableWithAggregatesFilter>;
  carePlanProviderGroupId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicSetting = {
  __typename?: 'ClinicSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  processingFeePercent: Scalars['Float'];
  processingFeeFlat: Scalars['Int'];
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner: Scalars['Boolean'];
  workflowsEnabled: Scalars['Boolean'];
  outOfOfficeEnabled: Scalars['Boolean'];
  outOfOfficeHeadline: Scalars['String'];
  outOfOfficeShowScheduleEnabled: Scalars['Boolean'];
  savePaymentInfo: Scalars['Boolean'];
  emergencyContactsEnabled: Scalars['Boolean'];
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  /** 6-digit pin for terminal setup, padded with one zero for demo, two zeroes for QA, no zeroes for prod */
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit: Scalars['Int'];
  /** When did we last run the loyalty program queueing cron job, if at all? */
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus: MissedCallMessageResponseStatus;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses: Array<ChannelStatus>;
  clinic?: Maybe<Clinic>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfiguration>;
  emergencyClinics: Array<EmergencyClinics>;
  petPortalSetting?: Maybe<PetPortalSetting>;
  campaignRegistryCampaigns: Array<CampaignRegistryCampaign>;
  _count: ClinicSettingCountOutputType;
};


export type ClinicSettingChannelStatusesArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
  orderBy?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelStatusScalarFieldEnum>;
};


export type ClinicSettingEmergencyClinicsArgs = {
  where?: Maybe<EmergencyClinicsWhereInput>;
  orderBy?: Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmergencyClinicsScalarFieldEnum>;
};


export type ClinicSettingCampaignRegistryCampaignsArgs = {
  where?: Maybe<CampaignRegistryCampaignWhereInput>;
  orderBy?: Maybe<CampaignRegistryCampaignOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CampaignRegistryCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CampaignRegistryCampaignScalarFieldEnum>;
};

export type ClinicSettingAvgAggregateOutputType = {
  __typename?: 'ClinicSettingAvgAggregateOutputType';
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Float']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Float']>;
  bulkConversationExportLimit?: Maybe<Scalars['Float']>;
};

export type ClinicSettingAvgOrderByAggregateInput = {
  processingFeePercent?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  bulkConversationExportLimit?: Maybe<SortOrder>;
};

export type ClinicSettingCountAggregateOutputType = {
  __typename?: 'ClinicSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  processingFeePercent: Scalars['Int'];
  processingFeeFlat: Scalars['Int'];
  appointmentTimeSlotIncrementInMinutes: Scalars['Int'];
  hasCustomizableFees: Scalars['Int'];
  hideAppointmentsWithoutPetOwner: Scalars['Int'];
  workflowsEnabled: Scalars['Int'];
  outOfOfficeEnabled: Scalars['Int'];
  outOfOfficeHeadline: Scalars['Int'];
  outOfOfficeShowScheduleEnabled: Scalars['Int'];
  savePaymentInfo: Scalars['Int'];
  emergencyContactsEnabled: Scalars['Int'];
  merchantIdentifier: Scalars['Int'];
  careStatementDescriptor: Scalars['Int'];
  terminalSetupPin: Scalars['Int'];
  financialReportingConfig: Scalars['Int'];
  smsAutoResponseWhenOpen: Scalars['Int'];
  smsAutoResponseWhenClosed: Scalars['Int'];
  tags: Scalars['Int'];
  campaignRegistryBrandId: Scalars['Int'];
  campaignRegistryBrandIdentityStatus: Scalars['Int'];
  campaignRegistryBrand: Scalars['Int'];
  bulkConversationExportLimit: Scalars['Int'];
  loyaltyProgramLastRunAt: Scalars['Int'];
  earliestAllowedLoyaltyInvoiceDate: Scalars['Int'];
  missedCallMessageResponseStatus: Scalars['Int'];
  MissedCallMessageResponseBody: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  merchantIdentifier?: Maybe<SortOrder>;
  careStatementDescriptor?: Maybe<SortOrder>;
  terminalSetupPin?: Maybe<SortOrder>;
  financialReportingConfig?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  campaignRegistryBrandId?: Maybe<SortOrder>;
  campaignRegistryBrandIdentityStatus?: Maybe<SortOrder>;
  campaignRegistryBrand?: Maybe<SortOrder>;
  bulkConversationExportLimit?: Maybe<SortOrder>;
  loyaltyProgramLastRunAt?: Maybe<SortOrder>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<SortOrder>;
  missedCallMessageResponseStatus?: Maybe<SortOrder>;
  MissedCallMessageResponseBody?: Maybe<SortOrder>;
};

export type ClinicSettingCountOutputType = {
  __typename?: 'ClinicSettingCountOutputType';
  channelStatuses: Scalars['Int'];
  emergencyClinics: Scalars['Int'];
  campaignRegistryCampaigns: Scalars['Int'];
};

export type ClinicSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignCreateNestedManyWithoutClinicSettingInput>;
};

export type ClinicSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
};

export type ClinicSettingCreateNestedOneWithoutCampaignRegistryCampaignsInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutCampaignRegistryCampaignsInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutCampaignRegistryCampaignsInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
};

export type ClinicSettingCreateNestedOneWithoutChannelStatusesInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutChannelStatusesInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutChannelStatusesInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
};

export type ClinicSettingCreateNestedOneWithoutClinicInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutClinicInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
};

export type ClinicSettingCreateNestedOneWithoutEmergencyClinicsInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutEmergencyClinicsInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
};

export type ClinicSettingCreateNestedOneWithoutPaymentFeeConfigInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutPaymentFeeConfigInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
};

export type ClinicSettingCreateNestedOneWithoutPetPortalSettingInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutPetPortalSettingInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutPetPortalSettingInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
};

export type ClinicSettingCreateOrConnectWithoutCampaignRegistryCampaignsInput = {
  where: ClinicSettingWhereUniqueInput;
  create: ClinicSettingUncheckedCreateWithoutCampaignRegistryCampaignsInput;
};

export type ClinicSettingCreateOrConnectWithoutChannelStatusesInput = {
  where: ClinicSettingWhereUniqueInput;
  create: ClinicSettingUncheckedCreateWithoutChannelStatusesInput;
};

export type ClinicSettingCreateOrConnectWithoutClinicInput = {
  where: ClinicSettingWhereUniqueInput;
  create: ClinicSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicSettingCreateOrConnectWithoutEmergencyClinicsInput = {
  where: ClinicSettingWhereUniqueInput;
  create: ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput;
};

export type ClinicSettingCreateOrConnectWithoutPaymentFeeConfigInput = {
  where: ClinicSettingWhereUniqueInput;
  create: ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput;
};

export type ClinicSettingCreateOrConnectWithoutPetPortalSettingInput = {
  where: ClinicSettingWhereUniqueInput;
  create: ClinicSettingUncheckedCreateWithoutPetPortalSettingInput;
};

export type ClinicSettingCreateWithoutCampaignRegistryCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
};

export type ClinicSettingCreateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignCreateNestedManyWithoutClinicSettingInput>;
};

export type ClinicSettingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignCreateNestedManyWithoutClinicSettingInput>;
};

export type ClinicSettingCreateWithoutEmergencyClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignCreateNestedManyWithoutClinicSettingInput>;
};

export type ClinicSettingCreateWithoutPaymentFeeConfigInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignCreateNestedManyWithoutClinicSettingInput>;
};

export type ClinicSettingCreateWithoutPetPortalSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignCreateNestedManyWithoutClinicSettingInput>;
};

export type ClinicSettingMaxAggregateOutputType = {
  __typename?: 'ClinicSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
};

export type ClinicSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  merchantIdentifier?: Maybe<SortOrder>;
  careStatementDescriptor?: Maybe<SortOrder>;
  terminalSetupPin?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
  campaignRegistryBrandId?: Maybe<SortOrder>;
  campaignRegistryBrandIdentityStatus?: Maybe<SortOrder>;
  bulkConversationExportLimit?: Maybe<SortOrder>;
  loyaltyProgramLastRunAt?: Maybe<SortOrder>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<SortOrder>;
  missedCallMessageResponseStatus?: Maybe<SortOrder>;
  MissedCallMessageResponseBody?: Maybe<SortOrder>;
};

export type ClinicSettingMinAggregateOutputType = {
  __typename?: 'ClinicSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
};

export type ClinicSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  merchantIdentifier?: Maybe<SortOrder>;
  careStatementDescriptor?: Maybe<SortOrder>;
  terminalSetupPin?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
  campaignRegistryBrandId?: Maybe<SortOrder>;
  campaignRegistryBrandIdentityStatus?: Maybe<SortOrder>;
  bulkConversationExportLimit?: Maybe<SortOrder>;
  loyaltyProgramLastRunAt?: Maybe<SortOrder>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<SortOrder>;
  missedCallMessageResponseStatus?: Maybe<SortOrder>;
  MissedCallMessageResponseBody?: Maybe<SortOrder>;
};

export enum ClinicSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  OutOfOfficeHeadline = 'outOfOfficeHeadline',
  MerchantIdentifier = 'merchantIdentifier',
  CareStatementDescriptor = 'careStatementDescriptor',
  TerminalSetupPin = 'terminalSetupPin',
  SmsAutoResponseWhenOpen = 'smsAutoResponseWhenOpen',
  SmsAutoResponseWhenClosed = 'smsAutoResponseWhenClosed',
  CampaignRegistryBrandId = 'campaignRegistryBrandId',
  MissedCallMessageResponseBody = 'MissedCallMessageResponseBody'
}

export type ClinicSettingOrderByRelevanceInput = {
  fields: Array<ClinicSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  merchantIdentifier?: Maybe<SortOrder>;
  careStatementDescriptor?: Maybe<SortOrder>;
  terminalSetupPin?: Maybe<SortOrder>;
  financialReportingConfig?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  campaignRegistryBrandId?: Maybe<SortOrder>;
  campaignRegistryBrandIdentityStatus?: Maybe<SortOrder>;
  campaignRegistryBrand?: Maybe<SortOrder>;
  bulkConversationExportLimit?: Maybe<SortOrder>;
  loyaltyProgramLastRunAt?: Maybe<SortOrder>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<SortOrder>;
  missedCallMessageResponseStatus?: Maybe<SortOrder>;
  MissedCallMessageResponseBody?: Maybe<SortOrder>;
  _count?: Maybe<ClinicSettingCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicSettingAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicSettingMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicSettingSumOrderByAggregateInput>;
};

export type ClinicSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  merchantIdentifier?: Maybe<SortOrder>;
  careStatementDescriptor?: Maybe<SortOrder>;
  terminalSetupPin?: Maybe<SortOrder>;
  financialReportingConfig?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  campaignRegistryBrandId?: Maybe<SortOrder>;
  campaignRegistryBrandIdentityStatus?: Maybe<SortOrder>;
  campaignRegistryBrand?: Maybe<SortOrder>;
  bulkConversationExportLimit?: Maybe<SortOrder>;
  loyaltyProgramLastRunAt?: Maybe<SortOrder>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<SortOrder>;
  missedCallMessageResponseStatus?: Maybe<SortOrder>;
  MissedCallMessageResponseBody?: Maybe<SortOrder>;
  channelStatuses?: Maybe<ChannelStatusOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  emergencyClinics?: Maybe<EmergencyClinicsOrderByRelationAggregateInput>;
  petPortalSetting?: Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicSettingOrderByRelevanceInput>;
};

export type ClinicSettingRelationFilter = {
  is?: Maybe<ClinicSettingWhereInput>;
  isNot?: Maybe<ClinicSettingWhereInput>;
};

export enum ClinicSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ProcessingFeePercent = 'processingFeePercent',
  ProcessingFeeFlat = 'processingFeeFlat',
  AppointmentTimeSlotIncrementInMinutes = 'appointmentTimeSlotIncrementInMinutes',
  HasCustomizableFees = 'hasCustomizableFees',
  HideAppointmentsWithoutPetOwner = 'hideAppointmentsWithoutPetOwner',
  WorkflowsEnabled = 'workflowsEnabled',
  OutOfOfficeEnabled = 'outOfOfficeEnabled',
  OutOfOfficeHeadline = 'outOfOfficeHeadline',
  OutOfOfficeShowScheduleEnabled = 'outOfOfficeShowScheduleEnabled',
  SavePaymentInfo = 'savePaymentInfo',
  EmergencyContactsEnabled = 'emergencyContactsEnabled',
  MerchantIdentifier = 'merchantIdentifier',
  CareStatementDescriptor = 'careStatementDescriptor',
  TerminalSetupPin = 'terminalSetupPin',
  FinancialReportingConfig = 'financialReportingConfig',
  SmsAutoResponseWhenOpen = 'smsAutoResponseWhenOpen',
  SmsAutoResponseWhenClosed = 'smsAutoResponseWhenClosed',
  Tags = 'tags',
  CampaignRegistryBrandId = 'campaignRegistryBrandId',
  CampaignRegistryBrandIdentityStatus = 'campaignRegistryBrandIdentityStatus',
  CampaignRegistryBrand = 'campaignRegistryBrand',
  BulkConversationExportLimit = 'bulkConversationExportLimit',
  LoyaltyProgramLastRunAt = 'loyaltyProgramLastRunAt',
  EarliestAllowedLoyaltyInvoiceDate = 'earliestAllowedLoyaltyInvoiceDate',
  MissedCallMessageResponseStatus = 'missedCallMessageResponseStatus',
  MissedCallMessageResponseBody = 'MissedCallMessageResponseBody'
}

export type ClinicSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  processingFeePercent?: Maybe<FloatWithAggregatesFilter>;
  processingFeeFlat?: Maybe<IntWithAggregatesFilter>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<IntNullableWithAggregatesFilter>;
  hasCustomizableFees?: Maybe<BoolNullableWithAggregatesFilter>;
  hideAppointmentsWithoutPetOwner?: Maybe<BoolWithAggregatesFilter>;
  workflowsEnabled?: Maybe<BoolWithAggregatesFilter>;
  outOfOfficeEnabled?: Maybe<BoolWithAggregatesFilter>;
  outOfOfficeHeadline?: Maybe<StringWithAggregatesFilter>;
  outOfOfficeShowScheduleEnabled?: Maybe<BoolWithAggregatesFilter>;
  savePaymentInfo?: Maybe<BoolWithAggregatesFilter>;
  emergencyContactsEnabled?: Maybe<BoolWithAggregatesFilter>;
  merchantIdentifier?: Maybe<StringNullableWithAggregatesFilter>;
  careStatementDescriptor?: Maybe<StringNullableWithAggregatesFilter>;
  terminalSetupPin?: Maybe<StringNullableWithAggregatesFilter>;
  financialReportingConfig?: Maybe<JsonNullableWithAggregatesFilter>;
  smsAutoResponseWhenOpen?: Maybe<StringNullableWithAggregatesFilter>;
  smsAutoResponseWhenClosed?: Maybe<StringNullableWithAggregatesFilter>;
  tags?: Maybe<JsonNullableWithAggregatesFilter>;
  campaignRegistryBrandId?: Maybe<StringNullableWithAggregatesFilter>;
  campaignRegistryBrandIdentityStatus?: Maybe<EnumCampaignRegistryBrandIdentityStatusNullableWithAggregatesFilter>;
  campaignRegistryBrand?: Maybe<JsonNullableWithAggregatesFilter>;
  bulkConversationExportLimit?: Maybe<IntWithAggregatesFilter>;
  loyaltyProgramLastRunAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  missedCallMessageResponseStatus?: Maybe<EnumMissedCallMessageResponseStatusWithAggregatesFilter>;
  MissedCallMessageResponseBody?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicSettingSumAggregateOutputType = {
  __typename?: 'ClinicSettingSumAggregateOutputType';
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
};

export type ClinicSettingSumOrderByAggregateInput = {
  processingFeePercent?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  bulkConversationExportLimit?: Maybe<SortOrder>;
};

export type ClinicSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedCreateNestedManyWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedCreateWithoutCampaignRegistryCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedCreateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedCreateNestedManyWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedCreateNestedManyWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedCreateNestedManyWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedCreateNestedManyWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedCreateWithoutPetPortalSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedCreateNestedManyWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedUpdateManyWithoutClinicSettingNestedInput>;
};

export type ClinicSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
};

export type ClinicSettingUncheckedUpdateWithoutCampaignRegistryCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingNestedInput>;
};

export type ClinicSettingUncheckedUpdateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedUpdateManyWithoutClinicSettingNestedInput>;
};

export type ClinicSettingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedUpdateManyWithoutClinicSettingNestedInput>;
};

export type ClinicSettingUncheckedUpdateWithoutEmergencyClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedUpdateManyWithoutClinicSettingNestedInput>;
};

export type ClinicSettingUncheckedUpdateWithoutPaymentFeeConfigInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedUpdateManyWithoutClinicSettingNestedInput>;
};

export type ClinicSettingUncheckedUpdateWithoutPetPortalSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedUpdateManyWithoutClinicSettingNestedInput>;
};

export type ClinicSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingNestedInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingNestedInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingNestedInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUpdateManyWithoutClinicSettingNestedInput>;
};

export type ClinicSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
};

export type ClinicSettingUpdateOneRequiredWithoutCampaignRegistryCampaignsNestedInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutCampaignRegistryCampaignsInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutCampaignRegistryCampaignsInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutCampaignRegistryCampaignsInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutCampaignRegistryCampaignsInput>;
};

export type ClinicSettingUpdateOneRequiredWithoutEmergencyClinicsNestedInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutEmergencyClinicsInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutEmergencyClinicsInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutEmergencyClinicsInput>;
};

export type ClinicSettingUpdateOneRequiredWithoutPaymentFeeConfigNestedInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutPaymentFeeConfigInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutPaymentFeeConfigInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutPaymentFeeConfigInput>;
};

export type ClinicSettingUpdateOneRequiredWithoutPetPortalSettingNestedInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutPetPortalSettingInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutPetPortalSettingInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutPetPortalSettingInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutPetPortalSettingInput>;
};

export type ClinicSettingUpdateOneWithoutChannelStatusesNestedInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutChannelStatusesInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutChannelStatusesInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutChannelStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutChannelStatusesInput>;
};

export type ClinicSettingUpdateOneWithoutClinicNestedInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutClinicInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutClinicInput>;
};

export type ClinicSettingUpdateWithoutCampaignRegistryCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingNestedInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingNestedInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingNestedInput>;
};

export type ClinicSettingUpdateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingNestedInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingNestedInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingNestedInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUpdateManyWithoutClinicSettingNestedInput>;
};

export type ClinicSettingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingNestedInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingNestedInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingNestedInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUpdateManyWithoutClinicSettingNestedInput>;
};

export type ClinicSettingUpdateWithoutEmergencyClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingNestedInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingNestedInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUpdateManyWithoutClinicSettingNestedInput>;
};

export type ClinicSettingUpdateWithoutPaymentFeeConfigInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingNestedInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingNestedInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUpdateManyWithoutClinicSettingNestedInput>;
};

export type ClinicSettingUpdateWithoutPetPortalSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingNestedInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingNestedInput>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingNestedInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUpdateManyWithoutClinicSettingNestedInput>;
};

export type ClinicSettingUpsertWithoutCampaignRegistryCampaignsInput = {
  update: ClinicSettingUncheckedUpdateWithoutCampaignRegistryCampaignsInput;
  create: ClinicSettingUncheckedCreateWithoutCampaignRegistryCampaignsInput;
};

export type ClinicSettingUpsertWithoutChannelStatusesInput = {
  update: ClinicSettingUncheckedUpdateWithoutChannelStatusesInput;
  create: ClinicSettingUncheckedCreateWithoutChannelStatusesInput;
};

export type ClinicSettingUpsertWithoutClinicInput = {
  update: ClinicSettingUncheckedUpdateWithoutClinicInput;
  create: ClinicSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicSettingUpsertWithoutEmergencyClinicsInput = {
  update: ClinicSettingUncheckedUpdateWithoutEmergencyClinicsInput;
  create: ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput;
};

export type ClinicSettingUpsertWithoutPaymentFeeConfigInput = {
  update: ClinicSettingUncheckedUpdateWithoutPaymentFeeConfigInput;
  create: ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput;
};

export type ClinicSettingUpsertWithoutPetPortalSettingInput = {
  update: ClinicSettingUncheckedUpdateWithoutPetPortalSettingInput;
  create: ClinicSettingUncheckedCreateWithoutPetPortalSettingInput;
};

export type ClinicSettingWhereInput = {
  AND?: Maybe<Array<ClinicSettingWhereInput>>;
  OR?: Maybe<Array<ClinicSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  processingFeePercent?: Maybe<FloatFilter>;
  processingFeeFlat?: Maybe<IntFilter>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<IntNullableFilter>;
  hasCustomizableFees?: Maybe<BoolNullableFilter>;
  hideAppointmentsWithoutPetOwner?: Maybe<BoolFilter>;
  workflowsEnabled?: Maybe<BoolFilter>;
  outOfOfficeEnabled?: Maybe<BoolFilter>;
  outOfOfficeHeadline?: Maybe<StringFilter>;
  outOfOfficeShowScheduleEnabled?: Maybe<BoolFilter>;
  savePaymentInfo?: Maybe<BoolFilter>;
  emergencyContactsEnabled?: Maybe<BoolFilter>;
  merchantIdentifier?: Maybe<StringNullableFilter>;
  careStatementDescriptor?: Maybe<StringNullableFilter>;
  terminalSetupPin?: Maybe<StringNullableFilter>;
  financialReportingConfig?: Maybe<JsonNullableFilter>;
  smsAutoResponseWhenOpen?: Maybe<StringNullableFilter>;
  smsAutoResponseWhenClosed?: Maybe<StringNullableFilter>;
  tags?: Maybe<JsonNullableFilter>;
  campaignRegistryBrandId?: Maybe<StringNullableFilter>;
  campaignRegistryBrandIdentityStatus?: Maybe<EnumCampaignRegistryBrandIdentityStatusNullableFilter>;
  campaignRegistryBrand?: Maybe<JsonNullableFilter>;
  bulkConversationExportLimit?: Maybe<IntFilter>;
  loyaltyProgramLastRunAt?: Maybe<DateTimeNullableFilter>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<DateTimeNullableFilter>;
  missedCallMessageResponseStatus?: Maybe<EnumMissedCallMessageResponseStatusFilter>;
  MissedCallMessageResponseBody?: Maybe<StringNullableFilter>;
  channelStatuses?: Maybe<ChannelStatusListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  emergencyClinics?: Maybe<EmergencyClinicsListRelationFilter>;
  petPortalSetting?: Maybe<PetPortalSettingWhereInput>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignListRelationFilter>;
};

export type ClinicSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
};

export type ClinicSubscription = {
  __typename?: 'ClinicSubscription';
  node: Clinic;
  mutation: MutationType;
};

export type ClinicSumAggregateOutputType = {
  __typename?: 'ClinicSumAggregateOutputType';
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
};

export type ClinicSumOrderByAggregateInput = {
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
};

export type ClinicUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput = {
  create?: Maybe<Array<ClinicCreateWithoutCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutCarePlanProviderGroupInput>>;
  createMany?: Maybe<ClinicCreateManyCarePlanProviderGroupInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutClinicWidgetSettingInput = {
  create?: Maybe<Array<ClinicCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicCreateManyClinicWidgetSettingInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutFeatureFlagsInput = {
  create?: Maybe<Array<ClinicCreateWithoutFeatureFlagsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutFeatureFlagsInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutLegalEntityInput = {
  create?: Maybe<Array<ClinicCreateWithoutLegalEntityInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLegalEntityInput>>;
  createMany?: Maybe<ClinicCreateManyLegalEntityInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutLoyaltyProgramInput = {
  create?: Maybe<Array<ClinicCreateWithoutLoyaltyProgramInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLoyaltyProgramInput>>;
  createMany?: Maybe<ClinicCreateManyLoyaltyProgramInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<ClinicCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<ClinicCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutPetsInput = {
  create?: Maybe<Array<ClinicCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutPetsInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutStaffInput = {
  create?: Maybe<Array<ClinicCreateWithoutStaffInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStaffInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutStripePayoutAccountsInput = {
  create?: Maybe<Array<ClinicCreateWithoutStripePayoutAccountsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStripePayoutAccountsInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutUnlayerSettingInput = {
  create?: Maybe<Array<ClinicCreateWithoutUnlayerSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUnlayerSettingInput>>;
  createMany?: Maybe<ClinicCreateManyUnlayerSettingInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutUsersIdInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersIdInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersIdInput>>;
  createMany?: Maybe<ClinicCreateManyUsersIdInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutUsersInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedOneWithoutClinicSettingInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicSettingInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicUncheckedCreateWithoutAppoinmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutAppointmentAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutBenefitsProvidedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutBitwerxHealthchecksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutBlockedPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutCarePayoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicOfficeHoursInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicOnboardingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutConsultationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutDirectBookingExclusionRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutEmailCampaignLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutEmailCampaignTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutEmailCampaignUnsubscribeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutFeatureFlagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutInsuranceIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutLegalEntityInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutLoyaltyProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPaymentProcessorForProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPayoutBatchingPeriodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPetsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPostcardSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPromoCodesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutSentTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStaffIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStaffInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStripePayoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStripeTerminalLocationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutTwilioConfigurationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutTwilioVerificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutUnlayerSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutUserBoardFiltersColumnsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutUsersIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutVetsourceInstallationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
};

export type ClinicUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutCarePlanProviderGroupInput>>;
  createMany?: Maybe<ClinicCreateManyCarePlanProviderGroupInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutCarePlanProviderGroupInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicUncheckedUpdateManyWithoutClinicWidgetSettingNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutClinicWidgetSettingInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicCreateManyClinicWidgetSettingInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutClinicWidgetSettingInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutClinicsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicUncheckedUpdateManyWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicUncheckedUpdateManyWithoutFeatureFlagsNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutFeatureFlagsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutFeatureFlagsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutFeatureFlagsInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutFeatureFlagsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutFeatureFlagsInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutLegalEntityNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutLegalEntityInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLegalEntityInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutLegalEntityInput>>;
  createMany?: Maybe<ClinicCreateManyLegalEntityInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutLegalEntityInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutLegalEntityInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutLoyaltyProgramNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutLoyaltyProgramInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLoyaltyProgramInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutLoyaltyProgramInput>>;
  createMany?: Maybe<ClinicCreateManyLoyaltyProgramInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutLoyaltyProgramInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutLoyaltyProgramInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<ClinicCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutParticipatingClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicUncheckedUpdateManyWithoutPetsNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutPetsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutPetsInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutPetsInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutPreviousClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
};

export type ClinicUncheckedUpdateManyWithoutStaffNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutStaffInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStaffInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutStaffInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutStaffInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutStaffInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutStripePayoutAccountsNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutStripePayoutAccountsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStripePayoutAccountsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutStripePayoutAccountsInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutStripePayoutAccountsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutStripePayoutAccountsInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutUnlayerSettingNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutUnlayerSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUnlayerSettingInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUnlayerSettingInput>>;
  createMany?: Maybe<ClinicCreateManyUnlayerSettingInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUnlayerSettingInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUnlayerSettingInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutUsersIdNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersIdInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersIdInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUsersIdInput>>;
  createMany?: Maybe<ClinicCreateManyUsersIdInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUsersIdInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUsersIdInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutUsersNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUsersInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUsersInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateOneWithoutClinicSettingNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicSettingInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicSettingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicSettingInput>;
};

export type ClinicUncheckedUpdateWithoutAppoinmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutAppointmentAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutBenefitsProvidedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutBitwerxHealthchecksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutBlockedPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutCarePayoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicDirectBookingSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicEmailCampaignSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicOfficeHoursInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicOnboardingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutConsultationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutDirectBookingExclusionRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutEmailCampaignLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutEmailCampaignTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutEmailCampaignUnsubscribeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutFeatureFlagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutInformPartnerProgramEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutInsuranceIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutLegalEntityInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutLoyaltyProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutLoyaltyRewardRedemptionActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutPaymentProcessorForProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutPayoutBatchingPeriodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutPetsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutPostcardSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutPromoCodesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutSentTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutStaffIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutStaffInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutStripePayoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutStripeTerminalLocationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutTwilioConfigurationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutTwilioVerificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutUnlayerSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutUserBoardFiltersColumnsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutUsersIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutVetsourceInstallationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  legalEntityId?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
};

export type ClinicUpdateManyWithWhereWithoutCarePlanProviderGroupInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutParticipatingClinicsInput;
};

export type ClinicUpdateManyWithWhereWithoutClinicWidgetSettingInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicInput;
};

export type ClinicUpdateManyWithWhereWithoutFeatureFlagsInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
};

export type ClinicUpdateManyWithWhereWithoutLegalEntityInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
};

export type ClinicUpdateManyWithWhereWithoutLoyaltyProgramInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutParticipatingClinicsInput;
};

export type ClinicUpdateManyWithWhereWithoutOrganizationInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
};

export type ClinicUpdateManyWithWhereWithoutPetsInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutPreviousClinicsInput;
};

export type ClinicUpdateManyWithWhereWithoutStaffInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
};

export type ClinicUpdateManyWithWhereWithoutStripePayoutAccountsInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
};

export type ClinicUpdateManyWithWhereWithoutUnlayerSettingInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
};

export type ClinicUpdateManyWithWhereWithoutUsersIdInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicsIdInput;
};

export type ClinicUpdateManyWithWhereWithoutUsersInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
};

export type ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutCarePlanProviderGroupInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutCarePlanProviderGroupInput>>;
  createMany?: Maybe<ClinicCreateManyCarePlanProviderGroupInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutCarePlanProviderGroupInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutClinicWidgetSettingNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutClinicWidgetSettingInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicCreateManyClinicWidgetSettingInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutClinicWidgetSettingInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutFeatureFlagsNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutFeatureFlagsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutFeatureFlagsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutFeatureFlagsInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutFeatureFlagsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutFeatureFlagsInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutLegalEntityNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutLegalEntityInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLegalEntityInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutLegalEntityInput>>;
  createMany?: Maybe<ClinicCreateManyLegalEntityInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutLegalEntityInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutLegalEntityInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutLoyaltyProgramNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutLoyaltyProgramInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLoyaltyProgramInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutLoyaltyProgramInput>>;
  createMany?: Maybe<ClinicCreateManyLoyaltyProgramInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutLoyaltyProgramInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutLoyaltyProgramInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<ClinicCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutPetsNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutPetsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutPetsInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutPetsInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutStaffNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutStaffInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStaffInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutStaffInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutStaffInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutStaffInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutStripePayoutAccountsNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutStripePayoutAccountsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStripePayoutAccountsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutStripePayoutAccountsInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutStripePayoutAccountsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutStripePayoutAccountsInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutUnlayerSettingNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutUnlayerSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUnlayerSettingInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUnlayerSettingInput>>;
  createMany?: Maybe<ClinicCreateManyUnlayerSettingInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUnlayerSettingInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUnlayerSettingInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutUsersIdNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersIdInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersIdInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUsersIdInput>>;
  createMany?: Maybe<ClinicCreateManyUsersIdInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUsersIdInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUsersIdInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutUsersNestedInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUsersInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUsersInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAutomationRunsInput>;
  upsert?: Maybe<ClinicUpsertWithoutAutomationRunsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutAutomationRunsInput>;
};

export type ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutBenefitsProvidedInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutBenefitsProvidedInput>;
  upsert?: Maybe<ClinicUpsertWithoutBenefitsProvidedInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutBenefitsProvidedInput>;
};

export type ClinicUpdateOneRequiredWithoutBitwerxHealthchecksNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutBitwerxHealthchecksInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutBitwerxHealthchecksInput>;
  upsert?: Maybe<ClinicUpsertWithoutBitwerxHealthchecksInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutBitwerxHealthchecksInput>;
};

export type ClinicUpdateOneRequiredWithoutBlockedPhoneNumbersNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutBlockedPhoneNumbersInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutBlockedPhoneNumbersInput>;
  upsert?: Maybe<ClinicUpsertWithoutBlockedPhoneNumbersInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutBlockedPhoneNumbersInput>;
};

export type ClinicUpdateOneRequiredWithoutCallsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutCallsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutCallsInput>;
  upsert?: Maybe<ClinicUpsertWithoutCallsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutCallsInput>;
};

export type ClinicUpdateOneRequiredWithoutChannelFilterSelectionsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutChannelFilterSelectionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelFilterSelectionsInput>;
  upsert?: Maybe<ClinicUpsertWithoutChannelFilterSelectionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutChannelFilterSelectionsInput>;
};

export type ClinicUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  upsert?: Maybe<ClinicUpsertWithoutChannelStatusChannelAssigneesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutChannelStatusChannelAssigneesInput>;
};

export type ClinicUpdateOneRequiredWithoutClinicDirectBookingSettingsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicDirectBookingSettingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicDirectBookingSettingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicDirectBookingSettingsInput>;
};

export type ClinicUpdateOneRequiredWithoutClinicEmailCampaignSettingsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicEmailCampaignSettingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicEmailCampaignSettingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicEmailCampaignSettingsInput>;
};

export type ClinicUpdateOneRequiredWithoutClinicOnboardingsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicOnboardingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicOnboardingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicOnboardingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicOnboardingsInput>;
};

export type ClinicUpdateOneRequiredWithoutClinicPhoneNumberNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPhoneNumberInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPhoneNumberInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicPhoneNumberInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicPhoneNumberInput>;
};

export type ClinicUpdateOneRequiredWithoutDirectBookingExclusionRulesNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutDirectBookingExclusionRulesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutDirectBookingExclusionRulesInput>;
  upsert?: Maybe<ClinicUpsertWithoutDirectBookingExclusionRulesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutDirectBookingExclusionRulesInput>;
};

export type ClinicUpdateOneRequiredWithoutEmailCampaignLogNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignLogInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignLogInput>;
  upsert?: Maybe<ClinicUpsertWithoutEmailCampaignLogInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutEmailCampaignLogInput>;
};

export type ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignInput>;
  upsert?: Maybe<ClinicUpsertWithoutEmailCampaignInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutEmailCampaignInput>;
};

export type ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutFinancialTransactionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFinancialTransactionsInput>;
  upsert?: Maybe<ClinicUpsertWithoutFinancialTransactionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutFinancialTransactionsInput>;
};

export type ClinicUpdateOneRequiredWithoutHillsToHomeApiLogNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutHillsToHomeApiLogInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutHillsToHomeApiLogInput>;
  upsert?: Maybe<ClinicUpsertWithoutHillsToHomeApiLogInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutHillsToHomeApiLogInput>;
};

export type ClinicUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutInformPartnerProgramEnrollmentsInput>;
  upsert?: Maybe<ClinicUpsertWithoutInformPartnerProgramEnrollmentsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutInformPartnerProgramEnrollmentsInput>;
};

export type ClinicUpdateOneRequiredWithoutInsuranceIntegrationsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutInsuranceIntegrationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutInsuranceIntegrationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutInsuranceIntegrationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutInsuranceIntegrationsInput>;
};

export type ClinicUpdateOneRequiredWithoutLoyaltyPointDeltasNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutLoyaltyPointDeltasInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutLoyaltyPointDeltasInput>;
  upsert?: Maybe<ClinicUpsertWithoutLoyaltyPointDeltasInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutLoyaltyPointDeltasInput>;
};

export type ClinicUpdateOneRequiredWithoutMassTextAlertsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutMassTextAlertsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMassTextAlertsInput>;
  upsert?: Maybe<ClinicUpsertWithoutMassTextAlertsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutMassTextAlertsInput>;
};

export type ClinicUpdateOneRequiredWithoutMessageTemplatesNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutMessageTemplatesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMessageTemplatesInput>;
  upsert?: Maybe<ClinicUpsertWithoutMessageTemplatesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutMessageTemplatesInput>;
};

export type ClinicUpdateOneRequiredWithoutPayoutBatchingPeriodsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPayoutBatchingPeriodsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPayoutBatchingPeriodsInput>;
  upsert?: Maybe<ClinicUpsertWithoutPayoutBatchingPeriodsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPayoutBatchingPeriodsInput>;
};

export type ClinicUpdateOneRequiredWithoutPostcardSettingsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPostcardSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPostcardSettingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutPostcardSettingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPostcardSettingsInput>;
};

export type ClinicUpdateOneRequiredWithoutPrescriptionsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPrescriptionsInput>;
  upsert?: Maybe<ClinicUpsertWithoutPrescriptionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPrescriptionsInput>;
};

export type ClinicUpdateOneRequiredWithoutRulesNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutRulesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutRulesInput>;
  upsert?: Maybe<ClinicUpsertWithoutRulesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutRulesInput>;
};

export type ClinicUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutSentTrupanionExamDayOffersInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutSentTrupanionExamDayOffersInput>;
  upsert?: Maybe<ClinicUpsertWithoutSentTrupanionExamDayOffersInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutSentTrupanionExamDayOffersInput>;
};

export type ClinicUpdateOneRequiredWithoutStripeTerminalLocationsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutStripeTerminalLocationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStripeTerminalLocationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutStripeTerminalLocationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutStripeTerminalLocationsInput>;
};

export type ClinicUpdateOneRequiredWithoutSurveyResultsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutSurveyResultsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutSurveyResultsInput>;
  upsert?: Maybe<ClinicUpsertWithoutSurveyResultsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutSurveyResultsInput>;
};

export type ClinicUpdateOneRequiredWithoutTwilioConfigurationNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutTwilioConfigurationInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutTwilioConfigurationInput>;
  upsert?: Maybe<ClinicUpsertWithoutTwilioConfigurationInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutTwilioConfigurationInput>;
};

export type ClinicUpdateOneRequiredWithoutTwilioVerificationsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutTwilioVerificationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutTwilioVerificationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutTwilioVerificationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutTwilioVerificationsInput>;
};

export type ClinicUpdateOneRequiredWithoutUserBoardFiltersColumnsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutUserBoardFiltersColumnsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutUserBoardFiltersColumnsInput>;
  upsert?: Maybe<ClinicUpsertWithoutUserBoardFiltersColumnsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutUserBoardFiltersColumnsInput>;
};

export type ClinicUpdateOneRequiredWithoutVaccinationsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutVaccinationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVaccinationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutVaccinationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutVaccinationsInput>;
};

export type ClinicUpdateOneRequiredWithoutVetAvailabilitiesNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutVetAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVetAvailabilitiesInput>;
  upsert?: Maybe<ClinicUpsertWithoutVetAvailabilitiesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutVetAvailabilitiesInput>;
};

export type ClinicUpdateOneWithoutAppoinmentTypesNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutAppoinmentTypesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppoinmentTypesInput>;
  upsert?: Maybe<ClinicUpsertWithoutAppoinmentTypesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutAppoinmentTypesInput>;
};

export type ClinicUpdateOneWithoutAppointmentAvailabilitiesNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutAppointmentAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppointmentAvailabilitiesInput>;
  upsert?: Maybe<ClinicUpsertWithoutAppointmentAvailabilitiesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutAppointmentAvailabilitiesInput>;
};

export type ClinicUpdateOneWithoutAppointmentsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<ClinicUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutAppointmentsInput>;
};

export type ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutCarePayoutOrganizationInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutCarePayoutOrganizationInput>;
  upsert?: Maybe<ClinicUpsertWithoutCarePayoutOrganizationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutCarePayoutOrganizationInput>;
};

export type ClinicUpdateOneWithoutChannelsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutChannelsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelsInput>;
  upsert?: Maybe<ClinicUpsertWithoutChannelsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutChannelsInput>;
};

export type ClinicUpdateOneWithoutClinicEmployeesNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicEmployeesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicEmployeesInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicEmployeesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicEmployeesInput>;
};

export type ClinicUpdateOneWithoutClinicOfficeHoursNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicOfficeHoursInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicOfficeHoursInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicOfficeHoursInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicOfficeHoursInput>;
};

export type ClinicUpdateOneWithoutClinicPetParentsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPetParentsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicPetParentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicPetParentsInput>;
};

export type ClinicUpdateOneWithoutClinicPetsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPetsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPetsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicPetsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicPetsInput>;
};

export type ClinicUpdateOneWithoutClinicRoomsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicRoomsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicRoomsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicRoomsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicRoomsInput>;
};

export type ClinicUpdateOneWithoutClinicSettingNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicSettingInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicSettingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicSettingInput>;
};

export type ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicWidgetRequestsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicWidgetRequestsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicWidgetRequestsInput>;
};

export type ClinicUpdateOneWithoutClinicWorkflowTypesNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicWorkflowTypesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicWorkflowTypesInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicWorkflowTypesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicWorkflowTypesInput>;
};

export type ClinicUpdateOneWithoutConsultationRequestsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutConsultationRequestsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutConsultationRequestsInput>;
  upsert?: Maybe<ClinicUpsertWithoutConsultationRequestsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutConsultationRequestsInput>;
};

export type ClinicUpdateOneWithoutConsultationsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutConsultationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutConsultationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutConsultationsInput>;
};

export type ClinicUpdateOneWithoutEmailCampaignTemplateNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignTemplateInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignTemplateInput>;
  upsert?: Maybe<ClinicUpsertWithoutEmailCampaignTemplateInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutEmailCampaignTemplateInput>;
};

export type ClinicUpdateOneWithoutEmailCampaignUnsubscribeNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignUnsubscribeInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignUnsubscribeInput>;
  upsert?: Maybe<ClinicUpsertWithoutEmailCampaignUnsubscribeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutEmailCampaignUnsubscribeInput>;
};

export type ClinicUpdateOneWithoutFormSubmissionsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFormSubmissionsInput>;
  upsert?: Maybe<ClinicUpsertWithoutFormSubmissionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutFormSubmissionsInput>;
};

export type ClinicUpdateOneWithoutFormTemplatesNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutFormTemplatesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFormTemplatesInput>;
  upsert?: Maybe<ClinicUpsertWithoutFormTemplatesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutFormTemplatesInput>;
};

export type ClinicUpdateOneWithoutInformPartnerCustomerNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutInformPartnerCustomerInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutInformPartnerCustomerInput>;
  upsert?: Maybe<ClinicUpsertWithoutInformPartnerCustomerInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutInformPartnerCustomerInput>;
};

export type ClinicUpdateOneWithoutIntegrationsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutIntegrationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutIntegrationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutIntegrationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutIntegrationsInput>;
};

export type ClinicUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutLoyaltyRewardRedemptionActionsInput>;
  upsert?: Maybe<ClinicUpsertWithoutLoyaltyRewardRedemptionActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutLoyaltyRewardRedemptionActionsInput>;
};

export type ClinicUpdateOneWithoutMediaNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutMediaInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMediaInput>;
  upsert?: Maybe<ClinicUpsertWithoutMediaInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutMediaInput>;
};

export type ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPaymentProcessorForProviderGroupInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPaymentProcessorForProviderGroupInput>;
  upsert?: Maybe<ClinicUpsertWithoutPaymentProcessorForProviderGroupInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPaymentProcessorForProviderGroupInput>;
};

export type ClinicUpdateOneWithoutPetsIdNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPetsIdInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPetsIdInput>;
  upsert?: Maybe<ClinicUpsertWithoutPetsIdInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPetsIdInput>;
};

export type ClinicUpdateOneWithoutPromoCodesNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPromoCodesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPromoCodesInput>;
  upsert?: Maybe<ClinicUpsertWithoutPromoCodesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPromoCodesInput>;
};

export type ClinicUpdateOneWithoutRolesNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutRolesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutRolesInput>;
  upsert?: Maybe<ClinicUpsertWithoutRolesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutRolesInput>;
};

export type ClinicUpdateOneWithoutStaffIdNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutStaffIdInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStaffIdInput>;
  upsert?: Maybe<ClinicUpsertWithoutStaffIdInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutStaffIdInput>;
};

export type ClinicUpdateOneWithoutStripePaymentIntentsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStripePaymentIntentsInput>;
  upsert?: Maybe<ClinicUpsertWithoutStripePaymentIntentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutStripePaymentIntentsInput>;
};

export type ClinicUpdateOneWithoutUserAppointmentGroupingsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutUserAppointmentGroupingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutUserAppointmentGroupingsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutUserAppointmentGroupingsInput>;
};

export type ClinicUpdateOneWithoutVetsourceInstallationNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutVetsourceInstallationInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVetsourceInstallationInput>;
  upsert?: Maybe<ClinicUpsertWithoutVetsourceInstallationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutVetsourceInstallationInput>;
};

export type ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutWorkflowEventSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutWorkflowEventSettingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutWorkflowEventSettingsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutWorkflowEventSettingsInput>;
};

export type ClinicUpdateWithWhereUniqueWithoutCarePlanProviderGroupInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutCarePlanProviderGroupInput;
};

export type ClinicUpdateWithWhereUniqueWithoutClinicWidgetSettingInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutClinicWidgetSettingInput;
};

export type ClinicUpdateWithWhereUniqueWithoutFeatureFlagsInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutFeatureFlagsInput;
};

export type ClinicUpdateWithWhereUniqueWithoutLegalEntityInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutLegalEntityInput;
};

export type ClinicUpdateWithWhereUniqueWithoutLoyaltyProgramInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutLoyaltyProgramInput;
};

export type ClinicUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutOrganizationInput;
};

export type ClinicUpdateWithWhereUniqueWithoutPetsInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutPetsInput;
};

export type ClinicUpdateWithWhereUniqueWithoutStaffInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutStaffInput;
};

export type ClinicUpdateWithWhereUniqueWithoutStripePayoutAccountsInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutStripePayoutAccountsInput;
};

export type ClinicUpdateWithWhereUniqueWithoutUnlayerSettingInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutUnlayerSettingInput;
};

export type ClinicUpdateWithWhereUniqueWithoutUsersIdInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutUsersIdInput;
};

export type ClinicUpdateWithWhereUniqueWithoutUsersInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutUsersInput;
};

export type ClinicUpdateWithoutAppoinmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutAppointmentAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutBenefitsProvidedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutBitwerxHealthchecksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutBlockedPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutCarePayoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutCarePlanProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicDirectBookingSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicEmailCampaignSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicOfficeHoursInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicOnboardingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutConsultationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutDirectBookingExclusionRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutEmailCampaignLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutEmailCampaignTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutEmailCampaignUnsubscribeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutFeatureFlagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutInformPartnerProgramEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutInsuranceIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutLegalEntityInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutLoyaltyProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutLoyaltyRewardRedemptionActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutPaymentProcessorForProviderGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutPayoutBatchingPeriodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutPetsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutPostcardSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutPromoCodesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutSentTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutStaffIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutStaffInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutStripePayoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutStripeTerminalLocationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutTwilioConfigurationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutTwilioVerificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutUnlayerSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutUserBoardFiltersColumnsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutUsersIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutVetsourceInstallationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
};

export type ClinicUpsertWithWhereUniqueWithoutCarePlanProviderGroupInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutCarePlanProviderGroupInput;
  create: ClinicUncheckedCreateWithoutCarePlanProviderGroupInput;
};

export type ClinicUpsertWithWhereUniqueWithoutClinicWidgetSettingInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutClinicWidgetSettingInput;
  create: ClinicUncheckedCreateWithoutClinicWidgetSettingInput;
};

export type ClinicUpsertWithWhereUniqueWithoutFeatureFlagsInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutFeatureFlagsInput;
  create: ClinicUncheckedCreateWithoutFeatureFlagsInput;
};

export type ClinicUpsertWithWhereUniqueWithoutLegalEntityInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutLegalEntityInput;
  create: ClinicUncheckedCreateWithoutLegalEntityInput;
};

export type ClinicUpsertWithWhereUniqueWithoutLoyaltyProgramInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutLoyaltyProgramInput;
  create: ClinicUncheckedCreateWithoutLoyaltyProgramInput;
};

export type ClinicUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutOrganizationInput;
  create: ClinicUncheckedCreateWithoutOrganizationInput;
};

export type ClinicUpsertWithWhereUniqueWithoutPetsInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutPetsInput;
  create: ClinicUncheckedCreateWithoutPetsInput;
};

export type ClinicUpsertWithWhereUniqueWithoutStaffInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutStaffInput;
  create: ClinicUncheckedCreateWithoutStaffInput;
};

export type ClinicUpsertWithWhereUniqueWithoutStripePayoutAccountsInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutStripePayoutAccountsInput;
  create: ClinicUncheckedCreateWithoutStripePayoutAccountsInput;
};

export type ClinicUpsertWithWhereUniqueWithoutUnlayerSettingInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutUnlayerSettingInput;
  create: ClinicUncheckedCreateWithoutUnlayerSettingInput;
};

export type ClinicUpsertWithWhereUniqueWithoutUsersIdInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutUsersIdInput;
  create: ClinicUncheckedCreateWithoutUsersIdInput;
};

export type ClinicUpsertWithWhereUniqueWithoutUsersInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutUsersInput;
  create: ClinicUncheckedCreateWithoutUsersInput;
};

export type ClinicUpsertWithoutAppoinmentTypesInput = {
  update: ClinicUncheckedUpdateWithoutAppoinmentTypesInput;
  create: ClinicUncheckedCreateWithoutAppoinmentTypesInput;
};

export type ClinicUpsertWithoutAppointmentAvailabilitiesInput = {
  update: ClinicUncheckedUpdateWithoutAppointmentAvailabilitiesInput;
  create: ClinicUncheckedCreateWithoutAppointmentAvailabilitiesInput;
};

export type ClinicUpsertWithoutAppointmentsInput = {
  update: ClinicUncheckedUpdateWithoutAppointmentsInput;
  create: ClinicUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicUpsertWithoutAutomationRunsInput = {
  update: ClinicUncheckedUpdateWithoutAutomationRunsInput;
  create: ClinicUncheckedCreateWithoutAutomationRunsInput;
};

export type ClinicUpsertWithoutBenefitsProvidedInput = {
  update: ClinicUncheckedUpdateWithoutBenefitsProvidedInput;
  create: ClinicUncheckedCreateWithoutBenefitsProvidedInput;
};

export type ClinicUpsertWithoutBitwerxHealthchecksInput = {
  update: ClinicUncheckedUpdateWithoutBitwerxHealthchecksInput;
  create: ClinicUncheckedCreateWithoutBitwerxHealthchecksInput;
};

export type ClinicUpsertWithoutBlockedPhoneNumbersInput = {
  update: ClinicUncheckedUpdateWithoutBlockedPhoneNumbersInput;
  create: ClinicUncheckedCreateWithoutBlockedPhoneNumbersInput;
};

export type ClinicUpsertWithoutCallsInput = {
  update: ClinicUncheckedUpdateWithoutCallsInput;
  create: ClinicUncheckedCreateWithoutCallsInput;
};

export type ClinicUpsertWithoutCarePayoutOrganizationInput = {
  update: ClinicUncheckedUpdateWithoutCarePayoutOrganizationInput;
  create: ClinicUncheckedCreateWithoutCarePayoutOrganizationInput;
};

export type ClinicUpsertWithoutChannelFilterSelectionsInput = {
  update: ClinicUncheckedUpdateWithoutChannelFilterSelectionsInput;
  create: ClinicUncheckedCreateWithoutChannelFilterSelectionsInput;
};

export type ClinicUpsertWithoutChannelStatusChannelAssigneesInput = {
  update: ClinicUncheckedUpdateWithoutChannelStatusChannelAssigneesInput;
  create: ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
};

export type ClinicUpsertWithoutChannelsInput = {
  update: ClinicUncheckedUpdateWithoutChannelsInput;
  create: ClinicUncheckedCreateWithoutChannelsInput;
};

export type ClinicUpsertWithoutClinicDirectBookingSettingsInput = {
  update: ClinicUncheckedUpdateWithoutClinicDirectBookingSettingsInput;
  create: ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput;
};

export type ClinicUpsertWithoutClinicEmailCampaignSettingsInput = {
  update: ClinicUncheckedUpdateWithoutClinicEmailCampaignSettingsInput;
  create: ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput;
};

export type ClinicUpsertWithoutClinicEmployeesInput = {
  update: ClinicUncheckedUpdateWithoutClinicEmployeesInput;
  create: ClinicUncheckedCreateWithoutClinicEmployeesInput;
};

export type ClinicUpsertWithoutClinicOfficeHoursInput = {
  update: ClinicUncheckedUpdateWithoutClinicOfficeHoursInput;
  create: ClinicUncheckedCreateWithoutClinicOfficeHoursInput;
};

export type ClinicUpsertWithoutClinicOnboardingsInput = {
  update: ClinicUncheckedUpdateWithoutClinicOnboardingsInput;
  create: ClinicUncheckedCreateWithoutClinicOnboardingsInput;
};

export type ClinicUpsertWithoutClinicPetParentsInput = {
  update: ClinicUncheckedUpdateWithoutClinicPetParentsInput;
  create: ClinicUncheckedCreateWithoutClinicPetParentsInput;
};

export type ClinicUpsertWithoutClinicPetsInput = {
  update: ClinicUncheckedUpdateWithoutClinicPetsInput;
  create: ClinicUncheckedCreateWithoutClinicPetsInput;
};

export type ClinicUpsertWithoutClinicPhoneNumberInput = {
  update: ClinicUncheckedUpdateWithoutClinicPhoneNumberInput;
  create: ClinicUncheckedCreateWithoutClinicPhoneNumberInput;
};

export type ClinicUpsertWithoutClinicRoomsInput = {
  update: ClinicUncheckedUpdateWithoutClinicRoomsInput;
  create: ClinicUncheckedCreateWithoutClinicRoomsInput;
};

export type ClinicUpsertWithoutClinicSettingInput = {
  update: ClinicUncheckedUpdateWithoutClinicSettingInput;
  create: ClinicUncheckedCreateWithoutClinicSettingInput;
};

export type ClinicUpsertWithoutClinicWidgetRequestsInput = {
  update: ClinicUncheckedUpdateWithoutClinicWidgetRequestsInput;
  create: ClinicUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type ClinicUpsertWithoutClinicWorkflowTypesInput = {
  update: ClinicUncheckedUpdateWithoutClinicWorkflowTypesInput;
  create: ClinicUncheckedCreateWithoutClinicWorkflowTypesInput;
};

export type ClinicUpsertWithoutConsultationRequestsInput = {
  update: ClinicUncheckedUpdateWithoutConsultationRequestsInput;
  create: ClinicUncheckedCreateWithoutConsultationRequestsInput;
};

export type ClinicUpsertWithoutConsultationsInput = {
  update: ClinicUncheckedUpdateWithoutConsultationsInput;
  create: ClinicUncheckedCreateWithoutConsultationsInput;
};

export type ClinicUpsertWithoutDirectBookingExclusionRulesInput = {
  update: ClinicUncheckedUpdateWithoutDirectBookingExclusionRulesInput;
  create: ClinicUncheckedCreateWithoutDirectBookingExclusionRulesInput;
};

export type ClinicUpsertWithoutEmailCampaignInput = {
  update: ClinicUncheckedUpdateWithoutEmailCampaignInput;
  create: ClinicUncheckedCreateWithoutEmailCampaignInput;
};

export type ClinicUpsertWithoutEmailCampaignLogInput = {
  update: ClinicUncheckedUpdateWithoutEmailCampaignLogInput;
  create: ClinicUncheckedCreateWithoutEmailCampaignLogInput;
};

export type ClinicUpsertWithoutEmailCampaignTemplateInput = {
  update: ClinicUncheckedUpdateWithoutEmailCampaignTemplateInput;
  create: ClinicUncheckedCreateWithoutEmailCampaignTemplateInput;
};

export type ClinicUpsertWithoutEmailCampaignUnsubscribeInput = {
  update: ClinicUncheckedUpdateWithoutEmailCampaignUnsubscribeInput;
  create: ClinicUncheckedCreateWithoutEmailCampaignUnsubscribeInput;
};

export type ClinicUpsertWithoutFinancialTransactionsInput = {
  update: ClinicUncheckedUpdateWithoutFinancialTransactionsInput;
  create: ClinicUncheckedCreateWithoutFinancialTransactionsInput;
};

export type ClinicUpsertWithoutFormSubmissionsInput = {
  update: ClinicUncheckedUpdateWithoutFormSubmissionsInput;
  create: ClinicUncheckedCreateWithoutFormSubmissionsInput;
};

export type ClinicUpsertWithoutFormTemplatesInput = {
  update: ClinicUncheckedUpdateWithoutFormTemplatesInput;
  create: ClinicUncheckedCreateWithoutFormTemplatesInput;
};

export type ClinicUpsertWithoutHillsToHomeApiLogInput = {
  update: ClinicUncheckedUpdateWithoutHillsToHomeApiLogInput;
  create: ClinicUncheckedCreateWithoutHillsToHomeApiLogInput;
};

export type ClinicUpsertWithoutInformPartnerCustomerInput = {
  update: ClinicUncheckedUpdateWithoutInformPartnerCustomerInput;
  create: ClinicUncheckedCreateWithoutInformPartnerCustomerInput;
};

export type ClinicUpsertWithoutInformPartnerProgramEnrollmentsInput = {
  update: ClinicUncheckedUpdateWithoutInformPartnerProgramEnrollmentsInput;
  create: ClinicUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput;
};

export type ClinicUpsertWithoutInsuranceIntegrationsInput = {
  update: ClinicUncheckedUpdateWithoutInsuranceIntegrationsInput;
  create: ClinicUncheckedCreateWithoutInsuranceIntegrationsInput;
};

export type ClinicUpsertWithoutIntegrationsInput = {
  update: ClinicUncheckedUpdateWithoutIntegrationsInput;
  create: ClinicUncheckedCreateWithoutIntegrationsInput;
};

export type ClinicUpsertWithoutLoyaltyPointDeltasInput = {
  update: ClinicUncheckedUpdateWithoutLoyaltyPointDeltasInput;
  create: ClinicUncheckedCreateWithoutLoyaltyPointDeltasInput;
};

export type ClinicUpsertWithoutLoyaltyRewardRedemptionActionsInput = {
  update: ClinicUncheckedUpdateWithoutLoyaltyRewardRedemptionActionsInput;
  create: ClinicUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput;
};

export type ClinicUpsertWithoutMassTextAlertsInput = {
  update: ClinicUncheckedUpdateWithoutMassTextAlertsInput;
  create: ClinicUncheckedCreateWithoutMassTextAlertsInput;
};

export type ClinicUpsertWithoutMediaInput = {
  update: ClinicUncheckedUpdateWithoutMediaInput;
  create: ClinicUncheckedCreateWithoutMediaInput;
};

export type ClinicUpsertWithoutMessageTemplatesInput = {
  update: ClinicUncheckedUpdateWithoutMessageTemplatesInput;
  create: ClinicUncheckedCreateWithoutMessageTemplatesInput;
};

export type ClinicUpsertWithoutPaymentProcessorForProviderGroupInput = {
  update: ClinicUncheckedUpdateWithoutPaymentProcessorForProviderGroupInput;
  create: ClinicUncheckedCreateWithoutPaymentProcessorForProviderGroupInput;
};

export type ClinicUpsertWithoutPayoutBatchingPeriodsInput = {
  update: ClinicUncheckedUpdateWithoutPayoutBatchingPeriodsInput;
  create: ClinicUncheckedCreateWithoutPayoutBatchingPeriodsInput;
};

export type ClinicUpsertWithoutPetsIdInput = {
  update: ClinicUncheckedUpdateWithoutPetsIdInput;
  create: ClinicUncheckedCreateWithoutPetsIdInput;
};

export type ClinicUpsertWithoutPostcardSettingsInput = {
  update: ClinicUncheckedUpdateWithoutPostcardSettingsInput;
  create: ClinicUncheckedCreateWithoutPostcardSettingsInput;
};

export type ClinicUpsertWithoutPrescriptionsInput = {
  update: ClinicUncheckedUpdateWithoutPrescriptionsInput;
  create: ClinicUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicUpsertWithoutPromoCodesInput = {
  update: ClinicUncheckedUpdateWithoutPromoCodesInput;
  create: ClinicUncheckedCreateWithoutPromoCodesInput;
};

export type ClinicUpsertWithoutRolesInput = {
  update: ClinicUncheckedUpdateWithoutRolesInput;
  create: ClinicUncheckedCreateWithoutRolesInput;
};

export type ClinicUpsertWithoutRulesInput = {
  update: ClinicUncheckedUpdateWithoutRulesInput;
  create: ClinicUncheckedCreateWithoutRulesInput;
};

export type ClinicUpsertWithoutSentTrupanionExamDayOffersInput = {
  update: ClinicUncheckedUpdateWithoutSentTrupanionExamDayOffersInput;
  create: ClinicUncheckedCreateWithoutSentTrupanionExamDayOffersInput;
};

export type ClinicUpsertWithoutStaffIdInput = {
  update: ClinicUncheckedUpdateWithoutStaffIdInput;
  create: ClinicUncheckedCreateWithoutStaffIdInput;
};

export type ClinicUpsertWithoutStripePaymentIntentsInput = {
  update: ClinicUncheckedUpdateWithoutStripePaymentIntentsInput;
  create: ClinicUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type ClinicUpsertWithoutStripeTerminalLocationsInput = {
  update: ClinicUncheckedUpdateWithoutStripeTerminalLocationsInput;
  create: ClinicUncheckedCreateWithoutStripeTerminalLocationsInput;
};

export type ClinicUpsertWithoutSurveyResultsInput = {
  update: ClinicUncheckedUpdateWithoutSurveyResultsInput;
  create: ClinicUncheckedCreateWithoutSurveyResultsInput;
};

export type ClinicUpsertWithoutTwilioConfigurationInput = {
  update: ClinicUncheckedUpdateWithoutTwilioConfigurationInput;
  create: ClinicUncheckedCreateWithoutTwilioConfigurationInput;
};

export type ClinicUpsertWithoutTwilioVerificationsInput = {
  update: ClinicUncheckedUpdateWithoutTwilioVerificationsInput;
  create: ClinicUncheckedCreateWithoutTwilioVerificationsInput;
};

export type ClinicUpsertWithoutUserAppointmentGroupingsInput = {
  update: ClinicUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  create: ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type ClinicUpsertWithoutUserBoardFiltersColumnsInput = {
  update: ClinicUncheckedUpdateWithoutUserBoardFiltersColumnsInput;
  create: ClinicUncheckedCreateWithoutUserBoardFiltersColumnsInput;
};

export type ClinicUpsertWithoutVaccinationsInput = {
  update: ClinicUncheckedUpdateWithoutVaccinationsInput;
  create: ClinicUncheckedCreateWithoutVaccinationsInput;
};

export type ClinicUpsertWithoutVetAvailabilitiesInput = {
  update: ClinicUncheckedUpdateWithoutVetAvailabilitiesInput;
  create: ClinicUncheckedCreateWithoutVetAvailabilitiesInput;
};

export type ClinicUpsertWithoutVetsourceInstallationInput = {
  update: ClinicUncheckedUpdateWithoutVetsourceInstallationInput;
  create: ClinicUncheckedCreateWithoutVetsourceInstallationInput;
};

export type ClinicUpsertWithoutWorkflowEventSettingsInput = {
  update: ClinicUncheckedUpdateWithoutWorkflowEventSettingsInput;
  create: ClinicUncheckedCreateWithoutWorkflowEventSettingsInput;
};

export type ClinicWhereInput = {
  AND?: Maybe<Array<ClinicWhereInput>>;
  OR?: Maybe<Array<ClinicWhereInput>>;
  NOT?: Maybe<Array<ClinicWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  city?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  country?: Maybe<StringNullableFilter>;
  addressLine1?: Maybe<StringNullableFilter>;
  addressLine2?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  websiteUrl?: Maybe<StringNullableFilter>;
  hours?: Maybe<StringNullableFilter>;
  logo?: Maybe<StringNullableFilter>;
  optimizedLogoUrl?: Maybe<StringNullableFilter>;
  lastPrintOrderedOn?: Maybe<DateTimeNullableFilter>;
  lastPrintOrderedQuantity?: Maybe<IntNullableFilter>;
  subscribedAt?: Maybe<DateTimeNullableFilter>;
  consultationCost?: Maybe<FloatNullableFilter>;
  followUpCost?: Maybe<FloatNullableFilter>;
  incomeMethod?: Maybe<StringNullableFilter>;
  paymentName?: Maybe<StringNullableFilter>;
  paymentAddress?: Maybe<StringNullableFilter>;
  paymentCity?: Maybe<StringNullableFilter>;
  paymentState?: Maybe<StringNullableFilter>;
  paymentZip?: Maybe<StringNullableFilter>;
  paymentBankRoutingNumber?: Maybe<StringNullableFilter>;
  paymentBankAccountNumber?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  timezone?: Maybe<IntNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  lat?: Maybe<FloatNullableFilter>;
  long?: Maybe<FloatNullableFilter>;
  fullAddress?: Maybe<StringNullableFilter>;
  stripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeSubscriptionPlan?: Maybe<StringNullableFilter>;
  token?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  widgetSettings?: Maybe<JsonNullableFilter>;
  responseTime?: Maybe<FloatNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  ppcLocationId?: Maybe<IntNullableFilter>;
  integrationType?: Maybe<StringNullableFilter>;
  integrationInfo?: Maybe<JsonNullableFilter>;
  corporateId?: Maybe<StringNullableFilter>;
  customerId?: Maybe<StringNullableFilter>;
  subscriptionId?: Maybe<StringNullableFilter>;
  subscriptionPlan?: Maybe<StringNullableFilter>;
  isIntegrationActive?: Maybe<BoolNullableFilter>;
  isClinicActive?: Maybe<BoolFilter>;
  unsubscribedAt?: Maybe<DateTimeNullableFilter>;
  isPaymentUsBank?: Maybe<BoolNullableFilter>;
  isConsultationsActive?: Maybe<BoolNullableFilter>;
  cancellationReason?: Maybe<StringNullableFilter>;
  cancellationNote?: Maybe<StringNullableFilter>;
  canceledAt?: Maybe<DateTimeNullableFilter>;
  allowAllUsersToCloseConsultations?: Maybe<BoolNullableFilter>;
  clinicEmail?: Maybe<StringNullableFilter>;
  firebaseVets?: Maybe<JsonNullableFilter>;
  integrationSystem?: Maybe<StringNullableFilter>;
  marketEmail?: Maybe<StringNullableFilter>;
  marketFacebookNumber?: Maybe<IntNullableFilter>;
  marketFacebookPageId?: Maybe<StringNullableFilter>;
  marketFacebookPageToken?: Maybe<StringNullableFilter>;
  printLastOrder?: Maybe<DateTimeNullableFilter>;
  printReceivedQuantity?: Maybe<IntNullableFilter>;
  submerchantApprovedAt?: Maybe<DateTimeNullableFilter>;
  submerchantId?: Maybe<StringNullableFilter>;
  headUserId?: Maybe<StringNullableFilter>;
  crmId?: Maybe<StringNullableFilter>;
  submerchantStatus?: Maybe<StringNullableFilter>;
  submerchantIsActive?: Maybe<BoolNullableFilter>;
  crmUrlParams?: Maybe<JsonNullableFilter>;
  paymentPhone?: Maybe<StringNullableFilter>;
  automaticSubscriptionReceipts?: Maybe<BoolFilter>;
  autoSubscriptionReceiptEmails?: Maybe<JsonNullableFilter>;
  submerchantStripeId?: Maybe<StringNullableFilter>;
  submerchantStripeStatus?: Maybe<StringNullableFilter>;
  submerchantStripeChargesEnabled?: Maybe<BoolNullableFilter>;
  submerchantStripePayoutsEnabled?: Maybe<BoolNullableFilter>;
  submerchantStripeHasCustomPayouts?: Maybe<BoolNullableFilter>;
  clinicSettingId?: Maybe<StringNullableFilter>;
  timezoneName?: Maybe<StringNullableFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableFilter>;
  activationDate?: Maybe<DateTimeNullableFilter>;
  isAddAppointmentDisabled?: Maybe<BoolNullableFilter>;
  hasServiceReminders?: Maybe<BoolNullableFilter>;
  isServiceRemindersActive?: Maybe<BoolNullableFilter>;
  remindersIntegrationRequestEmail?: Maybe<StringNullableFilter>;
  hasSmsConversations?: Maybe<BoolNullableFilter>;
  callerIdPhoneNumber?: Maybe<StringNullableFilter>;
  smsPhoneNumber?: Maybe<StringNullableFilter>;
  isCallerIdVerified?: Maybe<BoolNullableFilter>;
  loyaltyProgramId?: Maybe<StringNullableFilter>;
  legalEntityId?: Maybe<StringNullableFilter>;
  unlayerSettingId?: Maybe<IntNullableFilter>;
  carePlanProviderGroupId?: Maybe<StringNullableFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingWhereInput>;
  usersId?: Maybe<UserWhereInput>;
  organization?: Maybe<OrganizationWhereInput>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityListRelationFilter>;
  appoinmentTypes?: Maybe<AppointmentTypeListRelationFilter>;
  channels?: Maybe<ChannelListRelationFilter>;
  clinicEmployees?: Maybe<ClinicEmployeeListRelationFilter>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourListRelationFilter>;
  clinicPets?: Maybe<ClinicPetListRelationFilter>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  integrations?: Maybe<ClinicPimsIntegrationListRelationFilter>;
  clinicRooms?: Maybe<ClinicRoomListRelationFilter>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestListRelationFilter>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeListRelationFilter>;
  consultations?: Maybe<ConsultationListRelationFilter>;
  consultationRequests?: Maybe<ConsultationRequestListRelationFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  formTemplates?: Maybe<FormTemplateListRelationFilter>;
  petsId?: Maybe<PetListRelationFilter>;
  promoCodes?: Maybe<PromoCodeListRelationFilter>;
  roles?: Maybe<StaffRoleListRelationFilter>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  staffId?: Maybe<VetInfoListRelationFilter>;
  workflowEventSettings?: Maybe<WorkflowEventSettingListRelationFilter>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountListRelationFilter>;
  pets?: Maybe<PetListRelationFilter>;
  staff?: Maybe<VetInfoListRelationFilter>;
  users?: Maybe<UserListRelationFilter>;
  twilioVerifications?: Maybe<TwilioVerificationListRelationFilter>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationListRelationFilter>;
  twilioConfiguration?: Maybe<TwilioConfigurationWhereInput>;
  financialTransactions?: Maybe<FinancialTransactionListRelationFilter>;
  featureFlags?: Maybe<FeatureFlagListRelationFilter>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeListRelationFilter>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingListRelationFilter>;
  calls?: Maybe<CallHistoryListRelationFilter>;
  benefitsProvided?: Maybe<CareBenefitUsageListRelationFilter>;
  carePayoutOrganization?: Maybe<OrganizationWhereInput>;
  vetAvailabilities?: Maybe<VetAvailabilityListRelationFilter>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingListRelationFilter>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionListRelationFilter>;
  messageTemplates?: Maybe<MessageTemplateListRelationFilter>;
  Rules?: Maybe<RulesListRelationFilter>;
  postcardSettings?: Maybe<ClinicPostcardSettingListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  vaccinations?: Maybe<VaccinationListRelationFilter>;
  massTextAlerts?: Maybe<MassTextAlertListRelationFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberListRelationFilter>;
  clinicOnboardings?: Maybe<ClinicOnboardingListRelationFilter>;
  surveyResults?: Maybe<SurveyResultListRelationFilter>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentListRelationFilter>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaListRelationFilter>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryListRelationFilter>;
  media?: Maybe<MediaListRelationFilter>;
  loyaltyProgram?: Maybe<LoyaltyProgramWhereInput>;
  legalEntity?: Maybe<LegalEntityWhereInput>;
  unlayerSetting?: Maybe<UnlayerSettingWhereInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupWhereInput>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupWhereInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleListRelationFilter>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferListRelationFilter>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationListRelationFilter>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodListRelationFilter>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckListRelationFilter>;
  emailCampaign?: Maybe<EmailCampaignListRelationFilter>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateListRelationFilter>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeListRelationFilter>;
  EmailCampaignLog?: Maybe<EmailCampaignLogListRelationFilter>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberListRelationFilter>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogListRelationFilter>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnListRelationFilter>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerWhereInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationWhereInput>;
};

export type ClinicWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequest = {
  __typename?: 'ClinicWidgetRequest';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<Appointment>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  clinic?: Maybe<Clinic>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  formSubmission?: Maybe<FormSubmission>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channelCreationSources: Array<ChannelCreationSource>;
  automationRuns: Array<AutomationRun>;
  pushNotificationLogs: Array<PushNotificationLog>;
  _count: ClinicWidgetRequestCountOutputType;
};


export type ClinicWidgetRequestChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};


export type ClinicWidgetRequestChannelCreationSourcesArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
};


export type ClinicWidgetRequestAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};


export type ClinicWidgetRequestPushNotificationLogsArgs = {
  where?: Maybe<PushNotificationLogWhereInput>;
  orderBy?: Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PushNotificationLogScalarFieldEnum>;
};

export type ClinicWidgetRequestCountAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  url: Scalars['Int'];
  requestFormSubmission: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicWidgetRequestTypeId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  isVerified: Scalars['Int'];
  directBookingAppointmentId: Scalars['Int'];
  verificationMethod: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicWidgetRequestCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  requestFormSubmission?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  directBookingAppointmentId?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestCountOutputType = {
  __typename?: 'ClinicWidgetRequestCountOutputType';
  channelAutomationStatuses: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  automationRuns: Scalars['Int'];
  pushNotificationLogs: Scalars['Int'];
};

export type ClinicWidgetRequestCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateManyClinicInputEnvelope = {
  data: Array<ClinicWidgetRequestCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicWidgetRequestCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope = {
  data: Array<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestCreateManyFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope = {
  data: Array<ClinicWidgetRequestCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutAutomationRunsInput>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
};

export type ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
};

export type ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutChannelCreationSourcesInput>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
};

export type ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutDirectBookingAppointmentInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutDirectBookingAppointmentInput>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
};

export type ClinicWidgetRequestCreateNestedOneWithoutPushNotificationLogsInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutPushNotificationLogsInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutPushNotificationLogsInput>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
};

export type ClinicWidgetRequestCreateOrConnectWithoutAutomationRunsInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutChannelAutomationStatusesInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutChannelCreationSourcesInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutClinicInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicWidgetRequestTypeInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutDirectBookingAppointmentInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutDirectBookingAppointmentInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutFormSubmissionInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutPushNotificationLogsInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutPushNotificationLogsInput;
};

export type ClinicWidgetRequestCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateWithoutDirectBookingAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestListRelationFilter = {
  every?: Maybe<ClinicWidgetRequestWhereInput>;
  some?: Maybe<ClinicWidgetRequestWhereInput>;
  none?: Maybe<ClinicWidgetRequestWhereInput>;
};

export type ClinicWidgetRequestMaxAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  directBookingAppointmentId?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestMinAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  directBookingAppointmentId?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicWidgetRequestOrderByRelevanceFieldEnum {
  Id = 'id',
  Url = 'url',
  ClinicId = 'clinicId',
  ClinicWidgetRequestTypeId = 'clinicWidgetRequestTypeId',
  ClinicPetParentId = 'clinicPetParentId',
  FormSubmissionId = 'formSubmissionId',
  DirectBookingAppointmentId = 'directBookingAppointmentId'
}

export type ClinicWidgetRequestOrderByRelevanceInput = {
  fields: Array<ClinicWidgetRequestOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicWidgetRequestOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  requestFormSubmission?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  directBookingAppointmentId?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
  _count?: Maybe<ClinicWidgetRequestCountOrderByAggregateInput>;
  _max?: Maybe<ClinicWidgetRequestMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicWidgetRequestMinOrderByAggregateInput>;
};

export type ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  requestFormSubmission?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  directBookingAppointmentId?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
  directBookingAppointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicWidgetRequestOrderByRelevanceInput>;
};

export type ClinicWidgetRequestRelationFilter = {
  is?: Maybe<ClinicWidgetRequestWhereInput>;
  isNot?: Maybe<ClinicWidgetRequestWhereInput>;
};

export enum ClinicWidgetRequestScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Url = 'url',
  RequestFormSubmission = 'requestFormSubmission',
  ClinicId = 'clinicId',
  ClinicWidgetRequestTypeId = 'clinicWidgetRequestTypeId',
  ClinicPetParentId = 'clinicPetParentId',
  FormSubmissionId = 'formSubmissionId',
  IsVerified = 'isVerified',
  DirectBookingAppointmentId = 'directBookingAppointmentId',
  VerificationMethod = 'verificationMethod'
}

export type ClinicWidgetRequestScalarWhereInput = {
  AND?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  requestFormSubmission?: Maybe<JsonNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  isVerified?: Maybe<BoolNullableFilter>;
  directBookingAppointmentId?: Maybe<StringNullableFilter>;
  verificationMethod?: Maybe<EnumContactVerificationMethodNullableFilter>;
};

export type ClinicWidgetRequestScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicWidgetRequestScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  url?: Maybe<StringWithAggregatesFilter>;
  requestFormSubmission?: Maybe<JsonNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  isVerified?: Maybe<BoolNullableWithAggregatesFilter>;
  directBookingAppointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  verificationMethod?: Maybe<EnumContactVerificationMethodNullableWithAggregatesFilter>;
};

export type ClinicWidgetRequestSubscription = {
  __typename?: 'ClinicWidgetRequestSubscription';
  node: ClinicWidgetRequest;
  mutation: MutationType;
};

export type ClinicWidgetRequestType = {
  __typename?: 'ClinicWidgetRequestType';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  channelStatus?: Maybe<ChannelStatus>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  clinicWidgetRequests: Array<ClinicWidgetRequest>;
  assignees: Array<User>;
  workflows: Array<WorkflowEventSetting>;
  formTemplate?: Maybe<FormTemplate>;
  _count: ClinicWidgetRequestTypeCountOutputType;
};


export type ClinicWidgetRequestTypeClinicWidgetRequestsArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestScalarFieldEnum>;
};


export type ClinicWidgetRequestTypeAssigneesArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};


export type ClinicWidgetRequestTypeWorkflowsArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
};

export type ClinicWidgetRequestTypeAvgAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeAvgAggregateOutputType';
  order?: Maybe<Scalars['Float']>;
};

export type ClinicWidgetRequestTypeAvgOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeCountAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  displayName: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isActive: Scalars['Int'];
  order: Scalars['Int'];
  requestType: Scalars['Int'];
  requestFormTemplate: Scalars['Int'];
  clinicWidgetSettingId: Scalars['Int'];
  channelStatusId: Scalars['Int'];
  formTemplateId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicWidgetRequestTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  requestFormTemplate?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeCountOutputType = {
  __typename?: 'ClinicWidgetRequestTypeCountOutputType';
  clinicWidgetRequests: Scalars['Int'];
  assignees: Scalars['Int'];
  workflows: Scalars['Int'];
};

export type ClinicWidgetRequestTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateManyChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope = {
  data: Array<ClinicWidgetRequestTypeCreateManyChannelStatusInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope = {
  data: Array<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestTypeCreateManyFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope = {
  data: Array<ClinicWidgetRequestTypeCreateManyFormTemplateInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutClinicWidgetSettingInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput = {
  create?: Maybe<ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetRequestsInput>;
  connect?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutAssigneesInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutChannelStatusInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetRequestsInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetSettingInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutFormTemplateInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutWorkflowsInput;
};

export type ClinicWidgetRequestTypeCreateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeListRelationFilter = {
  every?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  some?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  none?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};

export type ClinicWidgetRequestTypeMaxAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeMinAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicWidgetRequestTypeOrderByRelevanceFieldEnum {
  Id = 'id',
  DisplayName = 'displayName',
  ClinicWidgetSettingId = 'clinicWidgetSettingId',
  ChannelStatusId = 'channelStatusId',
  FormTemplateId = 'formTemplateId'
}

export type ClinicWidgetRequestTypeOrderByRelevanceInput = {
  fields: Array<ClinicWidgetRequestTypeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicWidgetRequestTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  requestFormTemplate?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicWidgetRequestTypeCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicWidgetRequestTypeAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicWidgetRequestTypeMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicWidgetRequestTypeMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicWidgetRequestTypeSumOrderByAggregateInput>;
};

export type ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  requestFormTemplate?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  channelStatus?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestOrderByRelationAggregateInput>;
  assignees?: Maybe<UserOrderByRelationAggregateInput>;
  workflows?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
  formTemplate?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicWidgetRequestTypeOrderByRelevanceInput>;
};

export type ClinicWidgetRequestTypeRelationFilter = {
  is?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  isNot?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};

export enum ClinicWidgetRequestTypeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DisplayName = 'displayName',
  IsDeleted = 'isDeleted',
  IsActive = 'isActive',
  Order = 'order',
  RequestType = 'requestType',
  RequestFormTemplate = 'requestFormTemplate',
  ClinicWidgetSettingId = 'clinicWidgetSettingId',
  ChannelStatusId = 'channelStatusId',
  FormTemplateId = 'formTemplateId'
}

export type ClinicWidgetRequestTypeScalarWhereInput = {
  AND?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  displayName?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  order?: Maybe<IntFilter>;
  requestType?: Maybe<EnumWidgetRequestTypeFilter>;
  requestFormTemplate?: Maybe<JsonNullableFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableFilter>;
  channelStatusId?: Maybe<StringNullableFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
};

export type ClinicWidgetRequestTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  displayName?: Maybe<StringNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  order?: Maybe<IntWithAggregatesFilter>;
  requestType?: Maybe<EnumWidgetRequestTypeWithAggregatesFilter>;
  requestFormTemplate?: Maybe<JsonNullableWithAggregatesFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  channelStatusId?: Maybe<StringNullableWithAggregatesFilter>;
  formTemplateId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicWidgetRequestTypeSumAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeSumAggregateOutputType';
  order?: Maybe<Scalars['Int']>;
};

export type ClinicWidgetRequestTypeSumOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutClinicWidgetSettingInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutAssigneesInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutAssigneesInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutChannelStatusInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutChannelStatusInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetSettingNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutClinicWidgetSettingInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutFormTemplateInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutFormTemplateInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutWorkflowsInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutWorkflowsInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutAssigneesInput = {
  where: ClinicWidgetRequestTypeScalarWhereInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutChannelStatusInput = {
  where: ClinicWidgetRequestTypeScalarWhereInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypeInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutClinicWidgetSettingInput = {
  where: ClinicWidgetRequestTypeScalarWhereInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutFormTemplateInput = {
  where: ClinicWidgetRequestTypeScalarWhereInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutWorkflowsInput = {
  where: ClinicWidgetRequestTypeScalarWhereInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWidgetRequestTypesInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutAssigneesInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutAssigneesInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutChannelStatusInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutChannelStatusInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutClinicWidgetSettingNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutClinicWidgetSettingInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutFormTemplateInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutFormTemplateInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutWorkflowsInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutWorkflowsInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput = {
  create?: Maybe<ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetRequestsInput>;
  upsert?: Maybe<ClinicWidgetRequestTypeUpsertWithoutClinicWidgetRequestsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  update?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetRequestsInput>;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutAssigneesInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutAssigneesInput;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutChannelStatusInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutChannelStatusInput;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutClinicWidgetSettingInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetSettingInput;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutFormTemplateInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutFormTemplateInput;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutWorkflowsInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutWorkflowsInput;
};

export type ClinicWidgetRequestTypeUpdateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutAssigneesInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutAssigneesInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutAssigneesInput;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutChannelStatusInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutChannelStatusInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutChannelStatusInput;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutClinicWidgetSettingInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetSettingInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetSettingInput;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutFormTemplateInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutFormTemplateInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutFormTemplateInput;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutWorkflowsInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutWorkflowsInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutWorkflowsInput;
};

export type ClinicWidgetRequestTypeUpsertWithoutClinicWidgetRequestsInput = {
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetRequestsInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type ClinicWidgetRequestTypeWhereInput = {
  AND?: Maybe<Array<ClinicWidgetRequestTypeWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestTypeWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  displayName?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  order?: Maybe<IntFilter>;
  requestType?: Maybe<EnumWidgetRequestTypeFilter>;
  requestFormTemplate?: Maybe<JsonNullableFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableFilter>;
  channelStatusId?: Maybe<StringNullableFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
  channelStatus?: Maybe<ChannelStatusWhereInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingWhereInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestListRelationFilter>;
  assignees?: Maybe<UserListRelationFilter>;
  workflows?: Maybe<WorkflowEventSettingListRelationFilter>;
  formTemplate?: Maybe<FormTemplateWhereInput>;
};

export type ClinicWidgetRequestTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutDirectBookingAppointmentInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutDirectBookingAppointmentInput>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutDirectBookingAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicWidgetRequestTypeInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicWidgetRequestTypeInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicWidgetRequestTypeInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutDirectBookingAppointmentInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutDirectBookingAppointmentInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutDirectBookingAppointmentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutDirectBookingAppointmentInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutDirectBookingAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicWidgetRequestScalarWhereInput;
  data: ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput;
};

export type ClinicWidgetRequestUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ClinicWidgetRequestScalarWhereInput;
  data: ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput;
};

export type ClinicWidgetRequestUpdateManyWithWhereWithoutClinicWidgetRequestTypeInput = {
  where: ClinicWidgetRequestScalarWhereInput;
  data: ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput;
};

export type ClinicWidgetRequestUpdateManyWithWhereWithoutFormSubmissionInput = {
  where: ClinicWidgetRequestScalarWhereInput;
  data: ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput;
};

export type ClinicWidgetRequestUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicWidgetRequestTypeInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicWidgetRequestTypeInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicWidgetRequestTypeInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutAutomationRunsInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutAutomationRunsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutAutomationRunsInput>;
};

export type ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutChannelAutomationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutChannelAutomationStatusesInput>;
};

export type ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutChannelCreationSourcesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutChannelCreationSourcesInput>;
};

export type ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutDirectBookingAppointmentInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutDirectBookingAppointmentInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutDirectBookingAppointmentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutDirectBookingAppointmentInput>;
};

export type ClinicWidgetRequestUpdateOneWithoutPushNotificationLogsNestedInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutPushNotificationLogsInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutPushNotificationLogsInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutPushNotificationLogsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutPushNotificationLogsInput>;
};

export type ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  data: ClinicWidgetRequestUncheckedUpdateWithoutClinicInput;
};

export type ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  data: ClinicWidgetRequestUncheckedUpdateWithoutClinicPetParentInput;
};

export type ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicWidgetRequestTypeInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  data: ClinicWidgetRequestUncheckedUpdateWithoutClinicWidgetRequestTypeInput;
};

export type ClinicWidgetRequestUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  data: ClinicWidgetRequestUncheckedUpdateWithoutFormSubmissionInput;
};

export type ClinicWidgetRequestUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUpdateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUpdateWithoutDirectBookingAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUpdateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
};

export type ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutClinicInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicInput;
};

export type ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutClinicPetParentInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicWidgetRequestTypeInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutClinicWidgetRequestTypeInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicWidgetRequestTypeInput;
};

export type ClinicWidgetRequestUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutFormSubmissionInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutFormSubmissionInput;
};

export type ClinicWidgetRequestUpsertWithoutAutomationRunsInput = {
  update: ClinicWidgetRequestUncheckedUpdateWithoutAutomationRunsInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput;
};

export type ClinicWidgetRequestUpsertWithoutChannelAutomationStatusesInput = {
  update: ClinicWidgetRequestUncheckedUpdateWithoutChannelAutomationStatusesInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type ClinicWidgetRequestUpsertWithoutChannelCreationSourcesInput = {
  update: ClinicWidgetRequestUncheckedUpdateWithoutChannelCreationSourcesInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type ClinicWidgetRequestUpsertWithoutDirectBookingAppointmentInput = {
  update: ClinicWidgetRequestUncheckedUpdateWithoutDirectBookingAppointmentInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutDirectBookingAppointmentInput;
};

export type ClinicWidgetRequestUpsertWithoutPushNotificationLogsInput = {
  update: ClinicWidgetRequestUncheckedUpdateWithoutPushNotificationLogsInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutPushNotificationLogsInput;
};

export type ClinicWidgetRequestWhereInput = {
  AND?: Maybe<Array<ClinicWidgetRequestWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  requestFormSubmission?: Maybe<JsonNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  isVerified?: Maybe<BoolNullableFilter>;
  directBookingAppointmentId?: Maybe<StringNullableFilter>;
  verificationMethod?: Maybe<EnumContactVerificationMethodNullableFilter>;
  directBookingAppointment?: Maybe<AppointmentWhereInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  pushNotificationLogs?: Maybe<PushNotificationLogListRelationFilter>;
};

export type ClinicWidgetRequestWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetSetting = {
  __typename?: 'ClinicWidgetSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic: Array<Clinic>;
  clinicWidgetRequestTypes: Array<ClinicWidgetRequestType>;
  _count: ClinicWidgetSettingCountOutputType;
};


export type ClinicWidgetSettingClinicArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};


export type ClinicWidgetSettingClinicWidgetRequestTypesArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
};

export type ClinicWidgetSettingCountAggregateOutputType = {
  __typename?: 'ClinicWidgetSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isEnabled: Scalars['Int'];
  welcomeMessage: Scalars['Int'];
  primaryColor: Scalars['Int'];
  secondaryColor: Scalars['Int'];
  whiteLabelUrls: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicWidgetSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
  whiteLabelUrls?: Maybe<SortOrder>;
};

export type ClinicWidgetSettingCountOutputType = {
  __typename?: 'ClinicWidgetSettingCountOutputType';
  clinic: Scalars['Int'];
  clinicWidgetRequestTypes: Scalars['Int'];
};

export type ClinicWidgetSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedManyWithoutClinicWidgetSettingInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingCreateNestedOneWithoutClinicInput = {
  create?: Maybe<ClinicWidgetSettingUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<ClinicWidgetSettingCreateOrConnectWithoutClinicInput>;
  connect?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
};

export type ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput = {
  create?: Maybe<ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput>;
  connectOrCreate?: Maybe<ClinicWidgetSettingCreateOrConnectWithoutClinicWidgetRequestTypesInput>;
  connect?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
};

export type ClinicWidgetSettingCreateOrConnectWithoutClinicInput = {
  where: ClinicWidgetSettingWhereUniqueInput;
  create: ClinicWidgetSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicWidgetSettingCreateOrConnectWithoutClinicWidgetRequestTypesInput = {
  where: ClinicWidgetSettingWhereUniqueInput;
  create: ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput;
};

export type ClinicWidgetSettingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingCreateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingMaxAggregateOutputType = {
  __typename?: 'ClinicWidgetSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
};

export type ClinicWidgetSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
};

export type ClinicWidgetSettingMinAggregateOutputType = {
  __typename?: 'ClinicWidgetSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
};

export type ClinicWidgetSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
};

export enum ClinicWidgetSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  WelcomeMessage = 'welcomeMessage',
  PrimaryColor = 'primaryColor',
  SecondaryColor = 'secondaryColor'
}

export type ClinicWidgetSettingOrderByRelevanceInput = {
  fields: Array<ClinicWidgetSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicWidgetSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
  whiteLabelUrls?: Maybe<SortOrder>;
  _count?: Maybe<ClinicWidgetSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicWidgetSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicWidgetSettingMinOrderByAggregateInput>;
};

export type ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
  whiteLabelUrls?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByRelationAggregateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicWidgetSettingOrderByRelevanceInput>;
};

export type ClinicWidgetSettingRelationFilter = {
  is?: Maybe<ClinicWidgetSettingWhereInput>;
  isNot?: Maybe<ClinicWidgetSettingWhereInput>;
};

export enum ClinicWidgetSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsEnabled = 'isEnabled',
  WelcomeMessage = 'welcomeMessage',
  PrimaryColor = 'primaryColor',
  SecondaryColor = 'secondaryColor',
  WhiteLabelUrls = 'whiteLabelUrls'
}

export type ClinicWidgetSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicWidgetSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicWidgetSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicWidgetSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isEnabled?: Maybe<BoolNullableWithAggregatesFilter>;
  welcomeMessage?: Maybe<StringNullableWithAggregatesFilter>;
  primaryColor?: Maybe<StringNullableWithAggregatesFilter>;
  secondaryColor?: Maybe<StringNullableWithAggregatesFilter>;
  whiteLabelUrls?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicWidgetSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUncheckedCreateNestedManyWithoutClinicWidgetSettingInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUncheckedCreateNestedManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUncheckedUpdateManyWithoutClinicWidgetSettingNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetSettingNestedInput>;
};

export type ClinicWidgetSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetSettingNestedInput>;
};

export type ClinicWidgetSettingUncheckedUpdateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUncheckedUpdateManyWithoutClinicWidgetSettingNestedInput>;
};

export type ClinicWidgetSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateManyWithoutClinicWidgetSettingNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutClinicWidgetSettingNestedInput>;
};

export type ClinicWidgetSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUpdateOneWithoutClinicNestedInput = {
  create?: Maybe<ClinicWidgetSettingUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<ClinicWidgetSettingCreateOrConnectWithoutClinicInput>;
  upsert?: Maybe<ClinicWidgetSettingUpsertWithoutClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  update?: Maybe<ClinicWidgetSettingUncheckedUpdateWithoutClinicInput>;
};

export type ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesNestedInput = {
  create?: Maybe<ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput>;
  connectOrCreate?: Maybe<ClinicWidgetSettingCreateOrConnectWithoutClinicWidgetRequestTypesInput>;
  upsert?: Maybe<ClinicWidgetSettingUpsertWithoutClinicWidgetRequestTypesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  update?: Maybe<ClinicWidgetSettingUncheckedUpdateWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetSettingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutClinicWidgetSettingNestedInput>;
};

export type ClinicWidgetSettingUpdateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateManyWithoutClinicWidgetSettingNestedInput>;
};

export type ClinicWidgetSettingUpsertWithoutClinicInput = {
  update: ClinicWidgetSettingUncheckedUpdateWithoutClinicInput;
  create: ClinicWidgetSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicWidgetSettingUpsertWithoutClinicWidgetRequestTypesInput = {
  update: ClinicWidgetSettingUncheckedUpdateWithoutClinicWidgetRequestTypesInput;
  create: ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput;
};

export type ClinicWidgetSettingWhereInput = {
  AND?: Maybe<Array<ClinicWidgetSettingWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isEnabled?: Maybe<BoolNullableFilter>;
  welcomeMessage?: Maybe<StringNullableFilter>;
  primaryColor?: Maybe<StringNullableFilter>;
  secondaryColor?: Maybe<StringNullableFilter>;
  whiteLabelUrls?: Maybe<JsonNullableFilter>;
  clinic?: Maybe<ClinicListRelationFilter>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
};

export type ClinicWidgetSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowType = {
  __typename?: 'ClinicWorkflowType';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  defaultWorkflowType: WorkflowType;
  clinicId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  appointmentType?: Maybe<AppointmentType>;
  clinic?: Maybe<Clinic>;
};

export type ClinicWorkflowTypeCountAggregateOutputType = {
  __typename?: 'ClinicWorkflowTypeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  defaultWorkflowType: Scalars['Int'];
  clinicId: Scalars['Int'];
  appoinmentTypeId: Scalars['Int'];
  name: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicWorkflowTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type ClinicWorkflowTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  name: Scalars['String'];
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutClinicWorkflowTypesInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWorkflowTypesInput>;
};

export type ClinicWorkflowTypeCreateManyAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  clinicId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope = {
  data: Array<ClinicWorkflowTypeCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWorkflowTypeCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClinicWorkflowTypeCreateManyClinicInputEnvelope = {
  data: Array<ClinicWorkflowTypeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWorkflowTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  clinicId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
};

export type ClinicWorkflowTypeCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
};

export type ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput = {
  where: ClinicWorkflowTypeWhereUniqueInput;
  create: ClinicWorkflowTypeUncheckedCreateWithoutAppointmentTypeInput;
};

export type ClinicWorkflowTypeCreateOrConnectWithoutClinicInput = {
  where: ClinicWorkflowTypeWhereUniqueInput;
  create: ClinicWorkflowTypeUncheckedCreateWithoutClinicInput;
};

export type ClinicWorkflowTypeCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  name: Scalars['String'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWorkflowTypesInput>;
};

export type ClinicWorkflowTypeCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  name: Scalars['String'];
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutClinicWorkflowTypesInput>;
};

export type ClinicWorkflowTypeListRelationFilter = {
  every?: Maybe<ClinicWorkflowTypeWhereInput>;
  some?: Maybe<ClinicWorkflowTypeWhereInput>;
  none?: Maybe<ClinicWorkflowTypeWhereInput>;
};

export type ClinicWorkflowTypeMaxAggregateOutputType = {
  __typename?: 'ClinicWorkflowTypeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  clinicId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type ClinicWorkflowTypeMinAggregateOutputType = {
  __typename?: 'ClinicWorkflowTypeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  clinicId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type ClinicWorkflowTypeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicWorkflowTypeOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  AppoinmentTypeId = 'appoinmentTypeId',
  Name = 'name'
}

export type ClinicWorkflowTypeOrderByRelevanceInput = {
  fields: Array<ClinicWorkflowTypeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicWorkflowTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  _count?: Maybe<ClinicWorkflowTypeCountOrderByAggregateInput>;
  _max?: Maybe<ClinicWorkflowTypeMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicWorkflowTypeMinOrderByAggregateInput>;
};

export type ClinicWorkflowTypeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicWorkflowTypeOrderByRelevanceInput>;
};

export enum ClinicWorkflowTypeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DefaultWorkflowType = 'defaultWorkflowType',
  ClinicId = 'clinicId',
  AppoinmentTypeId = 'appoinmentTypeId',
  Name = 'name'
}

export type ClinicWorkflowTypeScalarWhereInput = {
  AND?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
  OR?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  defaultWorkflowType?: Maybe<EnumWorkflowTypeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  appoinmentTypeId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
};

export type ClinicWorkflowTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicWorkflowTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicWorkflowTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicWorkflowTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  defaultWorkflowType?: Maybe<EnumWorkflowTypeWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  appoinmentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicWorkflowTypeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  clinicId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
};

export type ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
};

export type ClinicWorkflowTypeUncheckedCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  clinicId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClinicWorkflowTypeUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClinicWorkflowTypeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  clinicId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  clinicId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ClinicWorkflowTypeUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWorkflowTypeUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ClinicWorkflowTypeUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
};

export type ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicWorkflowTypeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWorkflowTypeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicWorkflowTypeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
};

export type ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeUncheckedUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  name?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutClinicWorkflowTypesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWorkflowTypesNestedInput>;
};

export type ClinicWorkflowTypeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeUpdateManyWithWhereWithoutAppointmentTypeInput = {
  where: ClinicWorkflowTypeScalarWhereInput;
  data: ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicWorkflowTypesInput;
};

export type ClinicWorkflowTypeUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicWorkflowTypeScalarWhereInput;
  data: ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicWorkflowTypesInput;
};

export type ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ClinicWorkflowTypeUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWorkflowTypeUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ClinicWorkflowTypeUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
};

export type ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicWorkflowTypeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWorkflowTypeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicWorkflowTypeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
};

export type ClinicWorkflowTypeUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  where: ClinicWorkflowTypeWhereUniqueInput;
  data: ClinicWorkflowTypeUncheckedUpdateWithoutAppointmentTypeInput;
};

export type ClinicWorkflowTypeUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicWorkflowTypeWhereUniqueInput;
  data: ClinicWorkflowTypeUncheckedUpdateWithoutClinicInput;
};

export type ClinicWorkflowTypeUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  name?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWorkflowTypesNestedInput>;
};

export type ClinicWorkflowTypeUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  name?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutClinicWorkflowTypesNestedInput>;
};

export type ClinicWorkflowTypeUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  where: ClinicWorkflowTypeWhereUniqueInput;
  update: ClinicWorkflowTypeUncheckedUpdateWithoutAppointmentTypeInput;
  create: ClinicWorkflowTypeUncheckedCreateWithoutAppointmentTypeInput;
};

export type ClinicWorkflowTypeUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicWorkflowTypeWhereUniqueInput;
  update: ClinicWorkflowTypeUncheckedUpdateWithoutClinicInput;
  create: ClinicWorkflowTypeUncheckedCreateWithoutClinicInput;
};

export type ClinicWorkflowTypeWhereInput = {
  AND?: Maybe<Array<ClinicWorkflowTypeWhereInput>>;
  OR?: Maybe<Array<ClinicWorkflowTypeWhereInput>>;
  NOT?: Maybe<Array<ClinicWorkflowTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  defaultWorkflowType?: Maybe<EnumWorkflowTypeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  appoinmentTypeId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type ClinicWorkflowTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Color = {
  __typename?: 'Color';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted: Scalars['Boolean'];
  integration: ClinicPimsIntegration;
  pets: Array<ClinicPet>;
  _count: ColorCountOutputType;
};


export type ColorPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};

export type ColorCountAggregateOutputType = {
  __typename?: 'ColorCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  integrationId: Scalars['Int'];
  pimsId: Scalars['Int'];
  name: Scalars['Int'];
  description: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ColorCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type ColorCountOutputType = {
  __typename?: 'ColorCountOutputType';
  pets: Scalars['Int'];
};

export type ColorCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutColorsInput;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutColorModelInput>;
};

export type ColorCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ColorCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ColorCreateManyIntegrationInputEnvelope = {
  data: Array<ColorCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ColorCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ColorCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ColorCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ColorCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ColorWhereUniqueInput>>;
};

export type ColorCreateNestedOneWithoutPetsInput = {
  create?: Maybe<ColorUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<ColorCreateOrConnectWithoutPetsInput>;
  connect?: Maybe<ColorWhereUniqueInput>;
};

export type ColorCreateOrConnectWithoutIntegrationInput = {
  where: ColorWhereUniqueInput;
  create: ColorUncheckedCreateWithoutIntegrationInput;
};

export type ColorCreateOrConnectWithoutPetsInput = {
  where: ColorWhereUniqueInput;
  create: ColorUncheckedCreateWithoutPetsInput;
};

export type ColorCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutColorModelInput>;
};

export type ColorCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutColorsInput;
};

export type ColorIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type ColorListRelationFilter = {
  every?: Maybe<ColorWhereInput>;
  some?: Maybe<ColorWhereInput>;
  none?: Maybe<ColorWhereInput>;
};

export type ColorMaxAggregateOutputType = {
  __typename?: 'ColorMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ColorMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type ColorMinAggregateOutputType = {
  __typename?: 'ColorMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ColorMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type ColorOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ColorOrderByRelevanceFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  Name = 'name',
  Description = 'description'
}

export type ColorOrderByRelevanceInput = {
  fields: Array<ColorOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ColorOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _count?: Maybe<ColorCountOrderByAggregateInput>;
  _max?: Maybe<ColorMaxOrderByAggregateInput>;
  _min?: Maybe<ColorMinOrderByAggregateInput>;
};

export type ColorOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  _relevance?: Maybe<ColorOrderByRelevanceInput>;
};

export type ColorRelationFilter = {
  is?: Maybe<ColorWhereInput>;
  isNot?: Maybe<ColorWhereInput>;
};

export enum ColorScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  Name = 'name',
  Description = 'description',
  RawPimsValue = 'rawPimsValue',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted'
}

export type ColorScalarWhereInput = {
  AND?: Maybe<Array<ColorScalarWhereInput>>;
  OR?: Maybe<Array<ColorScalarWhereInput>>;
  NOT?: Maybe<Array<ColorScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type ColorScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ColorScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ColorScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ColorScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
};

export type ColorUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutColorModelInput>;
};

export type ColorUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ColorCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ColorCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ColorCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ColorWhereUniqueInput>>;
};

export type ColorUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutColorModelInput>;
};

export type ColorUncheckedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ColorUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutColorModelNestedInput>;
};

export type ColorUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ColorUncheckedUpdateManyWithoutColorsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ColorUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ColorCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ColorCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ColorUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ColorCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ColorWhereUniqueInput>>;
  disconnect?: Maybe<Array<ColorWhereUniqueInput>>;
  delete?: Maybe<Array<ColorWhereUniqueInput>>;
  connect?: Maybe<Array<ColorWhereUniqueInput>>;
  update?: Maybe<Array<ColorUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ColorUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ColorScalarWhereInput>>;
};

export type ColorUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutColorModelNestedInput>;
};

export type ColorUncheckedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ColorUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutColorsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutColorModelNestedInput>;
};

export type ColorUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ColorUpdateManyWithWhereWithoutIntegrationInput = {
  where: ColorScalarWhereInput;
  data: ColorUncheckedUpdateManyWithoutColorsInput;
};

export type ColorUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ColorCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ColorCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ColorUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ColorCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ColorWhereUniqueInput>>;
  disconnect?: Maybe<Array<ColorWhereUniqueInput>>;
  delete?: Maybe<Array<ColorWhereUniqueInput>>;
  connect?: Maybe<Array<ColorWhereUniqueInput>>;
  update?: Maybe<Array<ColorUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ColorUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ColorScalarWhereInput>>;
};

export type ColorUpdateOneWithoutPetsNestedInput = {
  create?: Maybe<ColorUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<ColorCreateOrConnectWithoutPetsInput>;
  upsert?: Maybe<ColorUpsertWithoutPetsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ColorWhereUniqueInput>;
  update?: Maybe<ColorUncheckedUpdateWithoutPetsInput>;
};

export type ColorUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ColorWhereUniqueInput;
  data: ColorUncheckedUpdateWithoutIntegrationInput;
};

export type ColorUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutColorModelNestedInput>;
};

export type ColorUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutColorsNestedInput>;
};

export type ColorUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ColorWhereUniqueInput;
  update: ColorUncheckedUpdateWithoutIntegrationInput;
  create: ColorUncheckedCreateWithoutIntegrationInput;
};

export type ColorUpsertWithoutPetsInput = {
  update: ColorUncheckedUpdateWithoutPetsInput;
  create: ColorUncheckedCreateWithoutPetsInput;
};

export type ColorWhereInput = {
  AND?: Maybe<Array<ColorWhereInput>>;
  OR?: Maybe<Array<ColorWhereInput>>;
  NOT?: Maybe<Array<ColorWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  pets?: Maybe<ClinicPetListRelationFilter>;
};

export type ColorWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  integrationId_pimsId?: Maybe<ColorIntegrationIdPimsIdCompoundUniqueInput>;
};

export type Condition = {
  __typename?: 'Condition';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  conditionSetId: Scalars['String'];
  checksum: Scalars['String'];
  attribute: ConditionAttribute;
  operator: ConditionOperator;
  operand: Scalars['Json'];
  set: ConditionSet;
};

export enum ConditionAttribute {
  ClinicEmployeeId = 'ClinicEmployeeId',
  ClinicRoomId = 'ClinicRoomId',
  AppointmentTypeId = 'AppointmentTypeId',
  SpeciesType = 'SpeciesType',
  PetAgeInYears = 'PetAgeInYears',
  TrupanionCertificateAvailability = 'TrupanionCertificateAvailability'
}

export type ConditionCountAggregateOutputType = {
  __typename?: 'ConditionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  conditionSetId: Scalars['Int'];
  checksum: Scalars['Int'];
  attribute: Scalars['Int'];
  operator: Scalars['Int'];
  operand: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ConditionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  conditionSetId?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  attribute?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  operand?: Maybe<SortOrder>;
};

export type ConditionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  checksum: Scalars['String'];
  attribute: ConditionAttribute;
  operator: ConditionOperator;
  operand: Scalars['Json'];
  set: ConditionSetCreateNestedOneWithoutConditionsInput;
};

export type ConditionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionSetId: Scalars['String'];
  checksum: Scalars['String'];
  attribute: ConditionAttribute;
  operator: ConditionOperator;
  operand: Scalars['Json'];
};

export type ConditionCreateManySetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  checksum: Scalars['String'];
  attribute: ConditionAttribute;
  operator: ConditionOperator;
  operand: Scalars['Json'];
};

export type ConditionCreateManySetInputEnvelope = {
  data: Array<ConditionCreateManySetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConditionCreateNestedManyWithoutSetInput = {
  create?: Maybe<Array<ConditionCreateWithoutSetInput>>;
  connectOrCreate?: Maybe<Array<ConditionCreateOrConnectWithoutSetInput>>;
  createMany?: Maybe<ConditionCreateManySetInputEnvelope>;
  connect?: Maybe<Array<ConditionWhereUniqueInput>>;
};

export type ConditionCreateOrConnectWithoutSetInput = {
  where: ConditionWhereUniqueInput;
  create: ConditionUncheckedCreateWithoutSetInput;
};

export type ConditionCreateWithoutSetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  checksum: Scalars['String'];
  attribute: ConditionAttribute;
  operator: ConditionOperator;
  operand: Scalars['Json'];
};

export enum ConditionEvaluationStatus {
  Evaluate = 'Evaluate',
  Ignore = 'Ignore'
}

export type ConditionListRelationFilter = {
  every?: Maybe<ConditionWhereInput>;
  some?: Maybe<ConditionWhereInput>;
  none?: Maybe<ConditionWhereInput>;
};

export type ConditionMaxAggregateOutputType = {
  __typename?: 'ConditionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionSetId?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  attribute?: Maybe<ConditionAttribute>;
  operator?: Maybe<ConditionOperator>;
};

export type ConditionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  conditionSetId?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  attribute?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
};

export type ConditionMinAggregateOutputType = {
  __typename?: 'ConditionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionSetId?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  attribute?: Maybe<ConditionAttribute>;
  operator?: Maybe<ConditionOperator>;
};

export type ConditionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  conditionSetId?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  attribute?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
};

export enum ConditionOperator {
  Equals = 'EQUALS',
  NotEquals = 'NOT_EQUALS',
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  StartsWith = 'STARTS_WITH',
  EndsWith = 'ENDS_WITH',
  Between = 'BETWEEN',
  In = 'IN',
  NotIn = 'NOT_IN',
  Exists = 'EXISTS'
}

export type ConditionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ConditionOrderByRelevanceFieldEnum {
  Id = 'id',
  ConditionSetId = 'conditionSetId',
  Checksum = 'checksum'
}

export type ConditionOrderByRelevanceInput = {
  fields: Array<ConditionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ConditionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  conditionSetId?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  attribute?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  operand?: Maybe<SortOrder>;
  _count?: Maybe<ConditionCountOrderByAggregateInput>;
  _max?: Maybe<ConditionMaxOrderByAggregateInput>;
  _min?: Maybe<ConditionMinOrderByAggregateInput>;
};

export type ConditionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  conditionSetId?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  attribute?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  operand?: Maybe<SortOrder>;
  set?: Maybe<ConditionSetOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ConditionOrderByRelevanceInput>;
};

export enum ConditionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ConditionSetId = 'conditionSetId',
  Checksum = 'checksum',
  Attribute = 'attribute',
  Operator = 'operator',
  Operand = 'operand'
}

export type ConditionScalarWhereInput = {
  AND?: Maybe<Array<ConditionScalarWhereInput>>;
  OR?: Maybe<Array<ConditionScalarWhereInput>>;
  NOT?: Maybe<Array<ConditionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  conditionSetId?: Maybe<StringFilter>;
  checksum?: Maybe<StringFilter>;
  attribute?: Maybe<EnumConditionAttributeFilter>;
  operator?: Maybe<EnumConditionOperatorFilter>;
  operand?: Maybe<JsonFilter>;
};

export type ConditionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConditionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConditionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConditionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  conditionSetId?: Maybe<StringWithAggregatesFilter>;
  checksum?: Maybe<StringWithAggregatesFilter>;
  attribute?: Maybe<EnumConditionAttributeWithAggregatesFilter>;
  operator?: Maybe<EnumConditionOperatorWithAggregatesFilter>;
  operand?: Maybe<JsonWithAggregatesFilter>;
};

export type ConditionSet = {
  __typename?: 'ConditionSet';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  parentConditionSetId?: Maybe<Scalars['String']>;
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityId: Scalars['String'];
  operator: ConditionSetOperator;
  negate: Scalars['Boolean'];
  conditions: Array<Condition>;
  parent?: Maybe<ConditionSet>;
  children: Array<ConditionSet>;
  _count: ConditionSetCountOutputType;
};


export type ConditionSetConditionsArgs = {
  where?: Maybe<ConditionWhereInput>;
  orderBy?: Maybe<ConditionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConditionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConditionScalarFieldEnum>;
};


export type ConditionSetChildrenArgs = {
  where?: Maybe<ConditionSetWhereInput>;
  orderBy?: Maybe<ConditionSetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConditionSetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConditionSetScalarFieldEnum>;
};

export type ConditionSetCountAggregateOutputType = {
  __typename?: 'ConditionSetCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  parentConditionSetId: Scalars['Int'];
  conditionalEntityType: Scalars['Int'];
  conditionalEntityId: Scalars['Int'];
  operator: Scalars['Int'];
  negate: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ConditionSetCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentConditionSetId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  negate?: Maybe<SortOrder>;
};

export type ConditionSetCountOutputType = {
  __typename?: 'ConditionSetCountOutputType';
  conditions: Scalars['Int'];
  children: Scalars['Int'];
};

export type ConditionSetCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityId: Scalars['String'];
  operator: ConditionSetOperator;
  negate?: Maybe<Scalars['Boolean']>;
  conditions?: Maybe<ConditionCreateNestedManyWithoutSetInput>;
  parent?: Maybe<ConditionSetCreateNestedOneWithoutChildrenInput>;
  children?: Maybe<ConditionSetCreateNestedManyWithoutParentInput>;
};

export type ConditionSetCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityId: Scalars['String'];
  operator: ConditionSetOperator;
  negate?: Maybe<Scalars['Boolean']>;
};

export type ConditionSetCreateManyParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityId: Scalars['String'];
  operator: ConditionSetOperator;
  negate?: Maybe<Scalars['Boolean']>;
};

export type ConditionSetCreateManyParentInputEnvelope = {
  data: Array<ConditionSetCreateManyParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConditionSetCreateNestedManyWithoutParentInput = {
  create?: Maybe<Array<ConditionSetCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<ConditionSetCreateOrConnectWithoutParentInput>>;
  createMany?: Maybe<ConditionSetCreateManyParentInputEnvelope>;
  connect?: Maybe<Array<ConditionSetWhereUniqueInput>>;
};

export type ConditionSetCreateNestedOneWithoutChildrenInput = {
  create?: Maybe<ConditionSetUncheckedCreateWithoutChildrenInput>;
  connectOrCreate?: Maybe<ConditionSetCreateOrConnectWithoutChildrenInput>;
  connect?: Maybe<ConditionSetWhereUniqueInput>;
};

export type ConditionSetCreateNestedOneWithoutConditionsInput = {
  create?: Maybe<ConditionSetUncheckedCreateWithoutConditionsInput>;
  connectOrCreate?: Maybe<ConditionSetCreateOrConnectWithoutConditionsInput>;
  connect?: Maybe<ConditionSetWhereUniqueInput>;
};

export type ConditionSetCreateOrConnectWithoutChildrenInput = {
  where: ConditionSetWhereUniqueInput;
  create: ConditionSetUncheckedCreateWithoutChildrenInput;
};

export type ConditionSetCreateOrConnectWithoutConditionsInput = {
  where: ConditionSetWhereUniqueInput;
  create: ConditionSetUncheckedCreateWithoutConditionsInput;
};

export type ConditionSetCreateOrConnectWithoutParentInput = {
  where: ConditionSetWhereUniqueInput;
  create: ConditionSetUncheckedCreateWithoutParentInput;
};

export type ConditionSetCreateWithoutChildrenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityId: Scalars['String'];
  operator: ConditionSetOperator;
  negate?: Maybe<Scalars['Boolean']>;
  conditions?: Maybe<ConditionCreateNestedManyWithoutSetInput>;
  parent?: Maybe<ConditionSetCreateNestedOneWithoutChildrenInput>;
};

export type ConditionSetCreateWithoutConditionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityId: Scalars['String'];
  operator: ConditionSetOperator;
  negate?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<ConditionSetCreateNestedOneWithoutChildrenInput>;
  children?: Maybe<ConditionSetCreateNestedManyWithoutParentInput>;
};

export type ConditionSetCreateWithoutParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityId: Scalars['String'];
  operator: ConditionSetOperator;
  negate?: Maybe<Scalars['Boolean']>;
  conditions?: Maybe<ConditionCreateNestedManyWithoutSetInput>;
  children?: Maybe<ConditionSetCreateNestedManyWithoutParentInput>;
};

export type ConditionSetListRelationFilter = {
  every?: Maybe<ConditionSetWhereInput>;
  some?: Maybe<ConditionSetWhereInput>;
  none?: Maybe<ConditionSetWhereInput>;
};

export type ConditionSetMaxAggregateOutputType = {
  __typename?: 'ConditionSetMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionSetOperator>;
  negate?: Maybe<Scalars['Boolean']>;
};

export type ConditionSetMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentConditionSetId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  negate?: Maybe<SortOrder>;
};

export type ConditionSetMinAggregateOutputType = {
  __typename?: 'ConditionSetMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionSetOperator>;
  negate?: Maybe<Scalars['Boolean']>;
};

export type ConditionSetMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentConditionSetId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  negate?: Maybe<SortOrder>;
};

export enum ConditionSetOperator {
  And = 'AND',
  Or = 'OR'
}

export type ConditionSetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ConditionSetOrderByRelevanceFieldEnum {
  Id = 'id',
  ParentConditionSetId = 'parentConditionSetId',
  ConditionalEntityId = 'conditionalEntityId'
}

export type ConditionSetOrderByRelevanceInput = {
  fields: Array<ConditionSetOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ConditionSetOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentConditionSetId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  negate?: Maybe<SortOrder>;
  _count?: Maybe<ConditionSetCountOrderByAggregateInput>;
  _max?: Maybe<ConditionSetMaxOrderByAggregateInput>;
  _min?: Maybe<ConditionSetMinOrderByAggregateInput>;
};

export type ConditionSetOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentConditionSetId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  negate?: Maybe<SortOrder>;
  conditions?: Maybe<ConditionOrderByRelationAggregateInput>;
  parent?: Maybe<ConditionSetOrderByWithRelationAndSearchRelevanceInput>;
  children?: Maybe<ConditionSetOrderByRelationAggregateInput>;
  _relevance?: Maybe<ConditionSetOrderByRelevanceInput>;
};

export type ConditionSetRelationFilter = {
  is?: Maybe<ConditionSetWhereInput>;
  isNot?: Maybe<ConditionSetWhereInput>;
};

export enum ConditionSetScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ParentConditionSetId = 'parentConditionSetId',
  ConditionalEntityType = 'conditionalEntityType',
  ConditionalEntityId = 'conditionalEntityId',
  Operator = 'operator',
  Negate = 'negate'
}

export type ConditionSetScalarWhereInput = {
  AND?: Maybe<Array<ConditionSetScalarWhereInput>>;
  OR?: Maybe<Array<ConditionSetScalarWhereInput>>;
  NOT?: Maybe<Array<ConditionSetScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  parentConditionSetId?: Maybe<StringNullableFilter>;
  conditionalEntityType?: Maybe<EnumConditionalEntityTypeFilter>;
  conditionalEntityId?: Maybe<StringFilter>;
  operator?: Maybe<EnumConditionSetOperatorFilter>;
  negate?: Maybe<BoolFilter>;
};

export type ConditionSetScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConditionSetScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConditionSetScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConditionSetScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  parentConditionSetId?: Maybe<StringNullableWithAggregatesFilter>;
  conditionalEntityType?: Maybe<EnumConditionalEntityTypeWithAggregatesFilter>;
  conditionalEntityId?: Maybe<StringWithAggregatesFilter>;
  operator?: Maybe<EnumConditionSetOperatorWithAggregatesFilter>;
  negate?: Maybe<BoolWithAggregatesFilter>;
};

export type ConditionSetUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityId: Scalars['String'];
  operator: ConditionSetOperator;
  negate?: Maybe<Scalars['Boolean']>;
  conditions?: Maybe<ConditionUncheckedCreateNestedManyWithoutSetInput>;
  children?: Maybe<ConditionSetUncheckedCreateNestedManyWithoutParentInput>;
};

export type ConditionSetUncheckedCreateNestedManyWithoutParentInput = {
  create?: Maybe<Array<ConditionSetCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<ConditionSetCreateOrConnectWithoutParentInput>>;
  createMany?: Maybe<ConditionSetCreateManyParentInputEnvelope>;
  connect?: Maybe<Array<ConditionSetWhereUniqueInput>>;
};

export type ConditionSetUncheckedCreateWithoutChildrenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityId: Scalars['String'];
  operator: ConditionSetOperator;
  negate?: Maybe<Scalars['Boolean']>;
  conditions?: Maybe<ConditionUncheckedCreateNestedManyWithoutSetInput>;
};

export type ConditionSetUncheckedCreateWithoutConditionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityId: Scalars['String'];
  operator: ConditionSetOperator;
  negate?: Maybe<Scalars['Boolean']>;
  children?: Maybe<ConditionSetUncheckedCreateNestedManyWithoutParentInput>;
};

export type ConditionSetUncheckedCreateWithoutParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityId: Scalars['String'];
  operator: ConditionSetOperator;
  negate?: Maybe<Scalars['Boolean']>;
  conditions?: Maybe<ConditionUncheckedCreateNestedManyWithoutSetInput>;
  children?: Maybe<ConditionSetUncheckedCreateNestedManyWithoutParentInput>;
};

export type ConditionSetUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionSetOperator>;
  negate?: Maybe<Scalars['Boolean']>;
  conditions?: Maybe<ConditionUncheckedUpdateManyWithoutSetNestedInput>;
  children?: Maybe<ConditionSetUncheckedUpdateManyWithoutParentNestedInput>;
};

export type ConditionSetUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionSetOperator>;
  negate?: Maybe<Scalars['Boolean']>;
};

export type ConditionSetUncheckedUpdateManyWithoutChildrenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionSetOperator>;
  negate?: Maybe<Scalars['Boolean']>;
};

export type ConditionSetUncheckedUpdateManyWithoutParentNestedInput = {
  create?: Maybe<Array<ConditionSetCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<ConditionSetCreateOrConnectWithoutParentInput>>;
  upsert?: Maybe<Array<ConditionSetUpsertWithWhereUniqueWithoutParentInput>>;
  createMany?: Maybe<ConditionSetCreateManyParentInputEnvelope>;
  set?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  delete?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  connect?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  update?: Maybe<Array<ConditionSetUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<ConditionSetUpdateManyWithWhereWithoutParentInput>>;
  deleteMany?: Maybe<Array<ConditionSetScalarWhereInput>>;
};

export type ConditionSetUncheckedUpdateWithoutChildrenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionSetOperator>;
  negate?: Maybe<Scalars['Boolean']>;
  conditions?: Maybe<ConditionUncheckedUpdateManyWithoutSetNestedInput>;
};

export type ConditionSetUncheckedUpdateWithoutConditionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionSetOperator>;
  negate?: Maybe<Scalars['Boolean']>;
  children?: Maybe<ConditionSetUncheckedUpdateManyWithoutParentNestedInput>;
};

export type ConditionSetUncheckedUpdateWithoutParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionSetOperator>;
  negate?: Maybe<Scalars['Boolean']>;
  conditions?: Maybe<ConditionUncheckedUpdateManyWithoutSetNestedInput>;
  children?: Maybe<ConditionSetUncheckedUpdateManyWithoutParentNestedInput>;
};

export type ConditionSetUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionSetOperator>;
  negate?: Maybe<Scalars['Boolean']>;
  conditions?: Maybe<ConditionUpdateManyWithoutSetNestedInput>;
  parent?: Maybe<ConditionSetUpdateOneWithoutChildrenNestedInput>;
  children?: Maybe<ConditionSetUpdateManyWithoutParentNestedInput>;
};

export type ConditionSetUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionSetOperator>;
  negate?: Maybe<Scalars['Boolean']>;
};

export type ConditionSetUpdateManyWithWhereWithoutParentInput = {
  where: ConditionSetScalarWhereInput;
  data: ConditionSetUncheckedUpdateManyWithoutChildrenInput;
};

export type ConditionSetUpdateManyWithoutParentNestedInput = {
  create?: Maybe<Array<ConditionSetCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<ConditionSetCreateOrConnectWithoutParentInput>>;
  upsert?: Maybe<Array<ConditionSetUpsertWithWhereUniqueWithoutParentInput>>;
  createMany?: Maybe<ConditionSetCreateManyParentInputEnvelope>;
  set?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  delete?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  connect?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  update?: Maybe<Array<ConditionSetUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<ConditionSetUpdateManyWithWhereWithoutParentInput>>;
  deleteMany?: Maybe<Array<ConditionSetScalarWhereInput>>;
};

export type ConditionSetUpdateOneRequiredWithoutConditionsNestedInput = {
  create?: Maybe<ConditionSetUncheckedCreateWithoutConditionsInput>;
  connectOrCreate?: Maybe<ConditionSetCreateOrConnectWithoutConditionsInput>;
  upsert?: Maybe<ConditionSetUpsertWithoutConditionsInput>;
  connect?: Maybe<ConditionSetWhereUniqueInput>;
  update?: Maybe<ConditionSetUncheckedUpdateWithoutConditionsInput>;
};

export type ConditionSetUpdateOneWithoutChildrenNestedInput = {
  create?: Maybe<ConditionSetUncheckedCreateWithoutChildrenInput>;
  connectOrCreate?: Maybe<ConditionSetCreateOrConnectWithoutChildrenInput>;
  upsert?: Maybe<ConditionSetUpsertWithoutChildrenInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ConditionSetWhereUniqueInput>;
  update?: Maybe<ConditionSetUncheckedUpdateWithoutChildrenInput>;
};

export type ConditionSetUpdateWithWhereUniqueWithoutParentInput = {
  where: ConditionSetWhereUniqueInput;
  data: ConditionSetUncheckedUpdateWithoutParentInput;
};

export type ConditionSetUpdateWithoutChildrenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionSetOperator>;
  negate?: Maybe<Scalars['Boolean']>;
  conditions?: Maybe<ConditionUpdateManyWithoutSetNestedInput>;
  parent?: Maybe<ConditionSetUpdateOneWithoutChildrenNestedInput>;
};

export type ConditionSetUpdateWithoutConditionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionSetOperator>;
  negate?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<ConditionSetUpdateOneWithoutChildrenNestedInput>;
  children?: Maybe<ConditionSetUpdateManyWithoutParentNestedInput>;
};

export type ConditionSetUpdateWithoutParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionSetOperator>;
  negate?: Maybe<Scalars['Boolean']>;
  conditions?: Maybe<ConditionUpdateManyWithoutSetNestedInput>;
  children?: Maybe<ConditionSetUpdateManyWithoutParentNestedInput>;
};

export type ConditionSetUpsertWithWhereUniqueWithoutParentInput = {
  where: ConditionSetWhereUniqueInput;
  update: ConditionSetUncheckedUpdateWithoutParentInput;
  create: ConditionSetUncheckedCreateWithoutParentInput;
};

export type ConditionSetUpsertWithoutChildrenInput = {
  update: ConditionSetUncheckedUpdateWithoutChildrenInput;
  create: ConditionSetUncheckedCreateWithoutChildrenInput;
};

export type ConditionSetUpsertWithoutConditionsInput = {
  update: ConditionSetUncheckedUpdateWithoutConditionsInput;
  create: ConditionSetUncheckedCreateWithoutConditionsInput;
};

export type ConditionSetWhereInput = {
  AND?: Maybe<Array<ConditionSetWhereInput>>;
  OR?: Maybe<Array<ConditionSetWhereInput>>;
  NOT?: Maybe<Array<ConditionSetWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  parentConditionSetId?: Maybe<StringNullableFilter>;
  conditionalEntityType?: Maybe<EnumConditionalEntityTypeFilter>;
  conditionalEntityId?: Maybe<StringFilter>;
  operator?: Maybe<EnumConditionSetOperatorFilter>;
  negate?: Maybe<BoolFilter>;
  conditions?: Maybe<ConditionListRelationFilter>;
  parent?: Maybe<ConditionSetWhereInput>;
  children?: Maybe<ConditionSetListRelationFilter>;
};

export type ConditionSetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ConditionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionSetId: Scalars['String'];
  checksum: Scalars['String'];
  attribute: ConditionAttribute;
  operator: ConditionOperator;
  operand: Scalars['Json'];
};

export type ConditionUncheckedCreateNestedManyWithoutSetInput = {
  create?: Maybe<Array<ConditionCreateWithoutSetInput>>;
  connectOrCreate?: Maybe<Array<ConditionCreateOrConnectWithoutSetInput>>;
  createMany?: Maybe<ConditionCreateManySetInputEnvelope>;
  connect?: Maybe<Array<ConditionWhereUniqueInput>>;
};

export type ConditionUncheckedCreateWithoutSetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  checksum: Scalars['String'];
  attribute: ConditionAttribute;
  operator: ConditionOperator;
  operand: Scalars['Json'];
};

export type ConditionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionSetId?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  attribute?: Maybe<ConditionAttribute>;
  operator?: Maybe<ConditionOperator>;
  operand?: Maybe<Scalars['Json']>;
};

export type ConditionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conditionSetId?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  attribute?: Maybe<ConditionAttribute>;
  operator?: Maybe<ConditionOperator>;
  operand?: Maybe<Scalars['Json']>;
};

export type ConditionUncheckedUpdateManyWithoutConditionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  checksum?: Maybe<Scalars['String']>;
  attribute?: Maybe<ConditionAttribute>;
  operator?: Maybe<ConditionOperator>;
  operand?: Maybe<Scalars['Json']>;
};

export type ConditionUncheckedUpdateManyWithoutSetNestedInput = {
  create?: Maybe<Array<ConditionCreateWithoutSetInput>>;
  connectOrCreate?: Maybe<Array<ConditionCreateOrConnectWithoutSetInput>>;
  upsert?: Maybe<Array<ConditionUpsertWithWhereUniqueWithoutSetInput>>;
  createMany?: Maybe<ConditionCreateManySetInputEnvelope>;
  set?: Maybe<Array<ConditionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConditionWhereUniqueInput>>;
  delete?: Maybe<Array<ConditionWhereUniqueInput>>;
  connect?: Maybe<Array<ConditionWhereUniqueInput>>;
  update?: Maybe<Array<ConditionUpdateWithWhereUniqueWithoutSetInput>>;
  updateMany?: Maybe<Array<ConditionUpdateManyWithWhereWithoutSetInput>>;
  deleteMany?: Maybe<Array<ConditionScalarWhereInput>>;
};

export type ConditionUncheckedUpdateWithoutSetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  checksum?: Maybe<Scalars['String']>;
  attribute?: Maybe<ConditionAttribute>;
  operator?: Maybe<ConditionOperator>;
  operand?: Maybe<Scalars['Json']>;
};

export type ConditionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  checksum?: Maybe<Scalars['String']>;
  attribute?: Maybe<ConditionAttribute>;
  operator?: Maybe<ConditionOperator>;
  operand?: Maybe<Scalars['Json']>;
  set?: Maybe<ConditionSetUpdateOneRequiredWithoutConditionsNestedInput>;
};

export type ConditionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  checksum?: Maybe<Scalars['String']>;
  attribute?: Maybe<ConditionAttribute>;
  operator?: Maybe<ConditionOperator>;
  operand?: Maybe<Scalars['Json']>;
};

export type ConditionUpdateManyWithWhereWithoutSetInput = {
  where: ConditionScalarWhereInput;
  data: ConditionUncheckedUpdateManyWithoutConditionsInput;
};

export type ConditionUpdateManyWithoutSetNestedInput = {
  create?: Maybe<Array<ConditionCreateWithoutSetInput>>;
  connectOrCreate?: Maybe<Array<ConditionCreateOrConnectWithoutSetInput>>;
  upsert?: Maybe<Array<ConditionUpsertWithWhereUniqueWithoutSetInput>>;
  createMany?: Maybe<ConditionCreateManySetInputEnvelope>;
  set?: Maybe<Array<ConditionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConditionWhereUniqueInput>>;
  delete?: Maybe<Array<ConditionWhereUniqueInput>>;
  connect?: Maybe<Array<ConditionWhereUniqueInput>>;
  update?: Maybe<Array<ConditionUpdateWithWhereUniqueWithoutSetInput>>;
  updateMany?: Maybe<Array<ConditionUpdateManyWithWhereWithoutSetInput>>;
  deleteMany?: Maybe<Array<ConditionScalarWhereInput>>;
};

export type ConditionUpdateWithWhereUniqueWithoutSetInput = {
  where: ConditionWhereUniqueInput;
  data: ConditionUncheckedUpdateWithoutSetInput;
};

export type ConditionUpdateWithoutSetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  checksum?: Maybe<Scalars['String']>;
  attribute?: Maybe<ConditionAttribute>;
  operator?: Maybe<ConditionOperator>;
  operand?: Maybe<Scalars['Json']>;
};

export type ConditionUpsertWithWhereUniqueWithoutSetInput = {
  where: ConditionWhereUniqueInput;
  update: ConditionUncheckedUpdateWithoutSetInput;
  create: ConditionUncheckedCreateWithoutSetInput;
};

export type ConditionWhereInput = {
  AND?: Maybe<Array<ConditionWhereInput>>;
  OR?: Maybe<Array<ConditionWhereInput>>;
  NOT?: Maybe<Array<ConditionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  conditionSetId?: Maybe<StringFilter>;
  checksum?: Maybe<StringFilter>;
  attribute?: Maybe<EnumConditionAttributeFilter>;
  operator?: Maybe<EnumConditionOperatorFilter>;
  operand?: Maybe<JsonFilter>;
  set?: Maybe<ConditionSetWhereInput>;
};

export type ConditionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ConditionalEntityEvaluation = {
  __typename?: 'ConditionalEntityEvaluation';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The pirmary key (CUID) of the entity whose ConditionSet(s) were evaluated */
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  /** The primary key (CUID) of the entity whose derived context was evaluated against the ConditionalEntity */
  contextEntityId: Scalars['String'];
  contextEntityType: ContextEntityType;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
  context?: Maybe<Scalars['Json']>;
  audit?: Maybe<Scalars['Json']>;
};

export type ConditionalEntityEvaluationCountAggregateOutputType = {
  __typename?: 'ConditionalEntityEvaluationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  conditionalEntityId: Scalars['Int'];
  conditionalEntityType: Scalars['Int'];
  conditionalEntityChecksum: Scalars['Int'];
  contextEntityId: Scalars['Int'];
  contextEntityType: Scalars['Int'];
  contextEntityChecksum: Scalars['Int'];
  result: Scalars['Int'];
  context: Scalars['Int'];
  audit: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ConditionalEntityEvaluationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  conditionalEntityChecksum?: Maybe<SortOrder>;
  contextEntityId?: Maybe<SortOrder>;
  contextEntityType?: Maybe<SortOrder>;
  contextEntityChecksum?: Maybe<SortOrder>;
  result?: Maybe<SortOrder>;
  context?: Maybe<SortOrder>;
  audit?: Maybe<SortOrder>;
};

export type ConditionalEntityEvaluationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId: Scalars['String'];
  contextEntityType: ContextEntityType;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
  context?: Maybe<Scalars['Json']>;
  audit?: Maybe<Scalars['Json']>;
};

export type ConditionalEntityEvaluationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId: Scalars['String'];
  contextEntityType: ContextEntityType;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
  context?: Maybe<Scalars['Json']>;
  audit?: Maybe<Scalars['Json']>;
};

export type ConditionalEntityEvaluationMaxAggregateOutputType = {
  __typename?: 'ConditionalEntityEvaluationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId?: Maybe<Scalars['String']>;
  contextEntityType?: Maybe<ContextEntityType>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
};

export type ConditionalEntityEvaluationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  conditionalEntityChecksum?: Maybe<SortOrder>;
  contextEntityId?: Maybe<SortOrder>;
  contextEntityType?: Maybe<SortOrder>;
  contextEntityChecksum?: Maybe<SortOrder>;
  result?: Maybe<SortOrder>;
};

export type ConditionalEntityEvaluationMinAggregateOutputType = {
  __typename?: 'ConditionalEntityEvaluationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId?: Maybe<Scalars['String']>;
  contextEntityType?: Maybe<ContextEntityType>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
};

export type ConditionalEntityEvaluationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  conditionalEntityChecksum?: Maybe<SortOrder>;
  contextEntityId?: Maybe<SortOrder>;
  contextEntityType?: Maybe<SortOrder>;
  contextEntityChecksum?: Maybe<SortOrder>;
  result?: Maybe<SortOrder>;
};

export enum ConditionalEntityEvaluationOrderByRelevanceFieldEnum {
  Id = 'id',
  ConditionalEntityId = 'conditionalEntityId',
  ConditionalEntityChecksum = 'conditionalEntityChecksum',
  ContextEntityId = 'contextEntityId',
  ContextEntityChecksum = 'contextEntityChecksum'
}

export type ConditionalEntityEvaluationOrderByRelevanceInput = {
  fields: Array<ConditionalEntityEvaluationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ConditionalEntityEvaluationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  conditionalEntityChecksum?: Maybe<SortOrder>;
  contextEntityId?: Maybe<SortOrder>;
  contextEntityType?: Maybe<SortOrder>;
  contextEntityChecksum?: Maybe<SortOrder>;
  result?: Maybe<SortOrder>;
  context?: Maybe<SortOrder>;
  audit?: Maybe<SortOrder>;
  _count?: Maybe<ConditionalEntityEvaluationCountOrderByAggregateInput>;
  _max?: Maybe<ConditionalEntityEvaluationMaxOrderByAggregateInput>;
  _min?: Maybe<ConditionalEntityEvaluationMinOrderByAggregateInput>;
};

export type ConditionalEntityEvaluationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  conditionalEntityChecksum?: Maybe<SortOrder>;
  contextEntityId?: Maybe<SortOrder>;
  contextEntityType?: Maybe<SortOrder>;
  contextEntityChecksum?: Maybe<SortOrder>;
  result?: Maybe<SortOrder>;
  context?: Maybe<SortOrder>;
  audit?: Maybe<SortOrder>;
  _relevance?: Maybe<ConditionalEntityEvaluationOrderByRelevanceInput>;
};

export enum ConditionalEntityEvaluationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  ConditionalEntityId = 'conditionalEntityId',
  ConditionalEntityType = 'conditionalEntityType',
  ConditionalEntityChecksum = 'conditionalEntityChecksum',
  ContextEntityId = 'contextEntityId',
  ContextEntityType = 'contextEntityType',
  ContextEntityChecksum = 'contextEntityChecksum',
  Result = 'result',
  Context = 'context',
  Audit = 'audit'
}

export type ConditionalEntityEvaluationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConditionalEntityEvaluationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConditionalEntityEvaluationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConditionalEntityEvaluationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  conditionalEntityId?: Maybe<StringWithAggregatesFilter>;
  conditionalEntityType?: Maybe<EnumConditionalEntityTypeWithAggregatesFilter>;
  conditionalEntityChecksum?: Maybe<StringNullableWithAggregatesFilter>;
  contextEntityId?: Maybe<StringWithAggregatesFilter>;
  contextEntityType?: Maybe<EnumContextEntityTypeWithAggregatesFilter>;
  contextEntityChecksum?: Maybe<StringNullableWithAggregatesFilter>;
  result?: Maybe<BoolWithAggregatesFilter>;
  context?: Maybe<JsonNullableWithAggregatesFilter>;
  audit?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ConditionalEntityEvaluationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId: Scalars['String'];
  contextEntityType: ContextEntityType;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
  context?: Maybe<Scalars['Json']>;
  audit?: Maybe<Scalars['Json']>;
};

export type ConditionalEntityEvaluationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId?: Maybe<Scalars['String']>;
  contextEntityType?: Maybe<ContextEntityType>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  context?: Maybe<Scalars['Json']>;
  audit?: Maybe<Scalars['Json']>;
};

export type ConditionalEntityEvaluationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId?: Maybe<Scalars['String']>;
  contextEntityType?: Maybe<ContextEntityType>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  context?: Maybe<Scalars['Json']>;
  audit?: Maybe<Scalars['Json']>;
};

export type ConditionalEntityEvaluationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId?: Maybe<Scalars['String']>;
  contextEntityType?: Maybe<ContextEntityType>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  context?: Maybe<Scalars['Json']>;
  audit?: Maybe<Scalars['Json']>;
};

export type ConditionalEntityEvaluationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId?: Maybe<Scalars['String']>;
  contextEntityType?: Maybe<ContextEntityType>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  context?: Maybe<Scalars['Json']>;
  audit?: Maybe<Scalars['Json']>;
};

export type ConditionalEntityEvaluationWhereInput = {
  AND?: Maybe<Array<ConditionalEntityEvaluationWhereInput>>;
  OR?: Maybe<Array<ConditionalEntityEvaluationWhereInput>>;
  NOT?: Maybe<Array<ConditionalEntityEvaluationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  conditionalEntityId?: Maybe<StringFilter>;
  conditionalEntityType?: Maybe<EnumConditionalEntityTypeFilter>;
  conditionalEntityChecksum?: Maybe<StringNullableFilter>;
  contextEntityId?: Maybe<StringFilter>;
  contextEntityType?: Maybe<EnumContextEntityTypeFilter>;
  contextEntityChecksum?: Maybe<StringNullableFilter>;
  result?: Maybe<BoolFilter>;
  context?: Maybe<JsonNullableFilter>;
  audit?: Maybe<JsonNullableFilter>;
};

export type ConditionalEntityEvaluationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ConditionalEntityType {
  WorkflowEventSetting = 'WorkflowEventSetting'
}

export type ConditionalSegmentsInput = {
  segmentId: Scalars['String'];
  definitionVariables: SegmentBuilderDefinitionVariablesInput;
};

export type ConfigureEmailCampaignsInput = {
  clinicId: Scalars['String'];
  publicIpAddress: Scalars['String'];
};

/** @deprecated - 1.0 */
export type Consultation = {
  __typename?: 'Consultation';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  consultationType: Scalars['String'];
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments: Array<Appointment>;
  chats: Array<ConsultationChat>;
  clinic?: Maybe<Clinic>;
  nurse?: Maybe<VetInfo>;
  pet?: Maybe<Pet>;
  petParent?: Maybe<OwnerInfo>;
  pics: Array<Image>;
  transactions: Array<Transaction>;
  vet?: Maybe<VetInfo>;
  _count: ConsultationCountOutputType;
};


/** @deprecated - 1.0 */
export type ConsultationAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};


/** @deprecated - 1.0 */
export type ConsultationChatsArgs = {
  where?: Maybe<ConsultationChatWhereInput>;
  orderBy?: Maybe<ConsultationChatOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationChatWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationChatScalarFieldEnum>;
};


/** @deprecated - 1.0 */
export type ConsultationPicsArgs = {
  where?: Maybe<ImageWhereInput>;
  orderBy?: Maybe<ImageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ImageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ImageScalarFieldEnum>;
};


/** @deprecated - 1.0 */
export type ConsultationTransactionsArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<TransactionScalarFieldEnum>;
};

export type ConsultationAvgAggregateOutputType = {
  __typename?: 'ConsultationAvgAggregateOutputType';
  cost?: Maybe<Scalars['Float']>;
  clientCost?: Maybe<Scalars['Float']>;
};

export type ConsultationAvgOrderByAggregateInput = {
  cost?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
};

/** @deprecated - 1.0 */
export type ConsultationChat = {
  __typename?: 'ConsultationChat';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  isRead?: Maybe<Scalars['Boolean']>;
  consultation?: Maybe<Consultation>;
  creator?: Maybe<User>;
};

export type ConsultationChatCountAggregateOutputType = {
  __typename?: 'ConsultationChatCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  text: Scalars['Int'];
  createdAtCopy: Scalars['Int'];
  creatorId: Scalars['Int'];
  consultationId: Scalars['Int'];
  firebaseId: Scalars['Int'];
  image: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isRead: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ConsultationChatCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creatorId?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
};

export type ConsultationChatCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutChatsInput>;
  creator?: Maybe<UserCreateNestedOneWithoutConsultationChatsInput>;
};

export type ConsultationChatCreateManyConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatCreateManyConsultationInputEnvelope = {
  data: Array<ConsultationChatCreateManyConsultationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatCreateManyCreatorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatCreateManyCreatorInputEnvelope = {
  data: Array<ConsultationChatCreateManyCreatorInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutConsultationInput>>;
  createMany?: Maybe<ConsultationChatCreateManyConsultationInputEnvelope>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
};

export type ConsultationChatCreateNestedManyWithoutCreatorInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutCreatorInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutCreatorInput>>;
  createMany?: Maybe<ConsultationChatCreateManyCreatorInputEnvelope>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
};

export type ConsultationChatCreateOrConnectWithoutConsultationInput = {
  where: ConsultationChatWhereUniqueInput;
  create: ConsultationChatUncheckedCreateWithoutConsultationInput;
};

export type ConsultationChatCreateOrConnectWithoutCreatorInput = {
  where: ConsultationChatWhereUniqueInput;
  create: ConsultationChatUncheckedCreateWithoutCreatorInput;
};

export type ConsultationChatCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<UserCreateNestedOneWithoutConsultationChatsInput>;
};

export type ConsultationChatCreateWithoutCreatorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutChatsInput>;
};

export type ConsultationChatListRelationFilter = {
  every?: Maybe<ConsultationChatWhereInput>;
  some?: Maybe<ConsultationChatWhereInput>;
  none?: Maybe<ConsultationChatWhereInput>;
};

export type ConsultationChatMaxAggregateOutputType = {
  __typename?: 'ConsultationChatMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creatorId?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
};

export type ConsultationChatMinAggregateOutputType = {
  __typename?: 'ConsultationChatMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creatorId?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
};

export type ConsultationChatOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ConsultationChatOrderByRelevanceFieldEnum {
  Id = 'id',
  Text = 'text',
  CreatorId = 'creatorId',
  ConsultationId = 'consultationId',
  FirebaseId = 'firebaseId',
  Image = 'image'
}

export type ConsultationChatOrderByRelevanceInput = {
  fields: Array<ConsultationChatOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ConsultationChatOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creatorId?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
  _count?: Maybe<ConsultationChatCountOrderByAggregateInput>;
  _max?: Maybe<ConsultationChatMaxOrderByAggregateInput>;
  _min?: Maybe<ConsultationChatMinOrderByAggregateInput>;
};

export type ConsultationChatOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creatorId?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
  consultation?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  creator?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ConsultationChatOrderByRelevanceInput>;
};

export enum ConsultationChatScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Text = 'text',
  CreatedAtCopy = 'createdAtCopy',
  CreatorId = 'creatorId',
  ConsultationId = 'consultationId',
  FirebaseId = 'firebaseId',
  Image = 'image',
  UpdatedAt = 'updatedAt',
  IsRead = 'isRead'
}

export type ConsultationChatScalarWhereInput = {
  AND?: Maybe<Array<ConsultationChatScalarWhereInput>>;
  OR?: Maybe<Array<ConsultationChatScalarWhereInput>>;
  NOT?: Maybe<Array<ConsultationChatScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  text?: Maybe<StringFilter>;
  createdAtCopy?: Maybe<DateTimeNullableFilter>;
  creatorId?: Maybe<StringNullableFilter>;
  consultationId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  image?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isRead?: Maybe<BoolNullableFilter>;
};

export type ConsultationChatScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConsultationChatScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConsultationChatScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConsultationChatScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  text?: Maybe<StringWithAggregatesFilter>;
  createdAtCopy?: Maybe<DateTimeNullableWithAggregatesFilter>;
  creatorId?: Maybe<StringNullableWithAggregatesFilter>;
  consultationId?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  image?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isRead?: Maybe<BoolNullableWithAggregatesFilter>;
};

export type ConsultationChatUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutConsultationInput>>;
  createMany?: Maybe<ConsultationChatCreateManyConsultationInputEnvelope>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
};

export type ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutCreatorInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutCreatorInput>>;
  createMany?: Maybe<ConsultationChatCreateManyCreatorInputEnvelope>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
};

export type ConsultationChatUncheckedCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedCreateWithoutCreatorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedUpdateManyWithoutChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedUpdateManyWithoutConsultationChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<ConsultationChatUpsertWithWhereUniqueWithoutConsultationInput>>;
  createMany?: Maybe<ConsultationChatCreateManyConsultationInputEnvelope>;
  set?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationChatUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<ConsultationChatUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<ConsultationChatScalarWhereInput>>;
};

export type ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutCreatorInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutCreatorInput>>;
  upsert?: Maybe<Array<ConsultationChatUpsertWithWhereUniqueWithoutCreatorInput>>;
  createMany?: Maybe<ConsultationChatCreateManyCreatorInputEnvelope>;
  set?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationChatUpdateWithWhereUniqueWithoutCreatorInput>>;
  updateMany?: Maybe<Array<ConsultationChatUpdateManyWithWhereWithoutCreatorInput>>;
  deleteMany?: Maybe<Array<ConsultationChatScalarWhereInput>>;
};

export type ConsultationChatUncheckedUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedUpdateWithoutCreatorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
  consultation?: Maybe<ConsultationUpdateOneWithoutChatsNestedInput>;
  creator?: Maybe<UserUpdateOneWithoutConsultationChatsNestedInput>;
};

export type ConsultationChatUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUpdateManyWithWhereWithoutConsultationInput = {
  where: ConsultationChatScalarWhereInput;
  data: ConsultationChatUncheckedUpdateManyWithoutChatsInput;
};

export type ConsultationChatUpdateManyWithWhereWithoutCreatorInput = {
  where: ConsultationChatScalarWhereInput;
  data: ConsultationChatUncheckedUpdateManyWithoutConsultationChatsInput;
};

export type ConsultationChatUpdateManyWithoutConsultationNestedInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<ConsultationChatUpsertWithWhereUniqueWithoutConsultationInput>>;
  createMany?: Maybe<ConsultationChatCreateManyConsultationInputEnvelope>;
  set?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationChatUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<ConsultationChatUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<ConsultationChatScalarWhereInput>>;
};

export type ConsultationChatUpdateManyWithoutCreatorNestedInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutCreatorInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutCreatorInput>>;
  upsert?: Maybe<Array<ConsultationChatUpsertWithWhereUniqueWithoutCreatorInput>>;
  createMany?: Maybe<ConsultationChatCreateManyCreatorInputEnvelope>;
  set?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationChatUpdateWithWhereUniqueWithoutCreatorInput>>;
  updateMany?: Maybe<Array<ConsultationChatUpdateManyWithWhereWithoutCreatorInput>>;
  deleteMany?: Maybe<Array<ConsultationChatScalarWhereInput>>;
};

export type ConsultationChatUpdateWithWhereUniqueWithoutConsultationInput = {
  where: ConsultationChatWhereUniqueInput;
  data: ConsultationChatUncheckedUpdateWithoutConsultationInput;
};

export type ConsultationChatUpdateWithWhereUniqueWithoutCreatorInput = {
  where: ConsultationChatWhereUniqueInput;
  data: ConsultationChatUncheckedUpdateWithoutCreatorInput;
};

export type ConsultationChatUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<UserUpdateOneWithoutConsultationChatsNestedInput>;
};

export type ConsultationChatUpdateWithoutCreatorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
  consultation?: Maybe<ConsultationUpdateOneWithoutChatsNestedInput>;
};

export type ConsultationChatUpsertWithWhereUniqueWithoutConsultationInput = {
  where: ConsultationChatWhereUniqueInput;
  update: ConsultationChatUncheckedUpdateWithoutConsultationInput;
  create: ConsultationChatUncheckedCreateWithoutConsultationInput;
};

export type ConsultationChatUpsertWithWhereUniqueWithoutCreatorInput = {
  where: ConsultationChatWhereUniqueInput;
  update: ConsultationChatUncheckedUpdateWithoutCreatorInput;
  create: ConsultationChatUncheckedCreateWithoutCreatorInput;
};

export type ConsultationChatWhereInput = {
  AND?: Maybe<Array<ConsultationChatWhereInput>>;
  OR?: Maybe<Array<ConsultationChatWhereInput>>;
  NOT?: Maybe<Array<ConsultationChatWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  text?: Maybe<StringFilter>;
  createdAtCopy?: Maybe<DateTimeNullableFilter>;
  creatorId?: Maybe<StringNullableFilter>;
  consultationId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  image?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isRead?: Maybe<BoolNullableFilter>;
  consultation?: Maybe<ConsultationWhereInput>;
  creator?: Maybe<UserWhereInput>;
};

export type ConsultationChatWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ConsultationCountAggregateOutputType = {
  __typename?: 'ConsultationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  consultationType: Scalars['Int'];
  cost: Scalars['Int'];
  consultationState: Scalars['Int'];
  summary: Scalars['Int'];
  details: Scalars['Int'];
  treat: Scalars['Int'];
  callType: Scalars['Int'];
  lastMessageAt: Scalars['Int'];
  firebaseId: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAtCopy: Scalars['Int'];
  openedAt: Scalars['Int'];
  resolvedAt: Scalars['Int'];
  petId: Scalars['Int'];
  transactionId: Scalars['Int'];
  petParentId: Scalars['Int'];
  veterinarianId: Scalars['Int'];
  scheduledAt: Scalars['Int'];
  cancelledAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  nurseId: Scalars['Int'];
  clientCost: Scalars['Int'];
  isPhoneAllowed: Scalars['Int'];
  isVideoAllowed: Scalars['Int'];
  isVetEmailed: Scalars['Int'];
  isVideoChat: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ConsultationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  treat?: Maybe<SortOrder>;
  callType?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  veterinarianId?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  nurseId?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
};

export type ConsultationCountOutputType = {
  __typename?: 'ConsultationCountOutputType';
  appointments: Scalars['Int'];
  chats: Scalars['Int'];
  pics: Scalars['Int'];
  transactions: Scalars['Int'];
};

export type ConsultationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyClinicInputEnvelope = {
  data: Array<ConsultationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyNurseInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyNurseInputEnvelope = {
  data: Array<ConsultationCreateManyNurseInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyPetInputEnvelope = {
  data: Array<ConsultationCreateManyPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyPetParentInputEnvelope = {
  data: Array<ConsultationCreateManyPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyVetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyVetInputEnvelope = {
  data: Array<ConsultationCreateManyVetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ConsultationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ConsultationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationCreateNestedManyWithoutNurseInput = {
  create?: Maybe<Array<ConsultationCreateWithoutNurseInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutNurseInput>>;
  createMany?: Maybe<ConsultationCreateManyNurseInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationCreateNestedManyWithoutPetInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetInput>>;
  createMany?: Maybe<ConsultationCreateManyPetInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationCreateNestedManyWithoutPetParentInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetParentInput>>;
  createMany?: Maybe<ConsultationCreateManyPetParentInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationCreateNestedManyWithoutPicsInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPicsInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPicsInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationCreateNestedManyWithoutVetInput = {
  create?: Maybe<Array<ConsultationCreateWithoutVetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutVetInput>>;
  createMany?: Maybe<ConsultationCreateManyVetInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<ConsultationUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<ConsultationWhereUniqueInput>;
};

export type ConsultationCreateNestedOneWithoutChatsInput = {
  create?: Maybe<ConsultationUncheckedCreateWithoutChatsInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutChatsInput>;
  connect?: Maybe<ConsultationWhereUniqueInput>;
};

export type ConsultationCreateNestedOneWithoutTransactionsInput = {
  create?: Maybe<ConsultationUncheckedCreateWithoutTransactionsInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutTransactionsInput>;
  connect?: Maybe<ConsultationWhereUniqueInput>;
};

export type ConsultationCreateOrConnectWithoutAppointmentsInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutAppointmentsInput;
};

export type ConsultationCreateOrConnectWithoutChatsInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutChatsInput;
};

export type ConsultationCreateOrConnectWithoutClinicInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutClinicInput;
};

export type ConsultationCreateOrConnectWithoutNurseInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutNurseInput;
};

export type ConsultationCreateOrConnectWithoutPetInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutPetInput;
};

export type ConsultationCreateOrConnectWithoutPetParentInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutPetParentInput;
};

export type ConsultationCreateOrConnectWithoutPicsInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutPicsInput;
};

export type ConsultationCreateOrConnectWithoutTransactionsInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutTransactionsInput;
};

export type ConsultationCreateOrConnectWithoutVetInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutVetInput;
};

export type ConsultationCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutNurseInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutPicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutVetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationListRelationFilter = {
  every?: Maybe<ConsultationWhereInput>;
  some?: Maybe<ConsultationWhereInput>;
  none?: Maybe<ConsultationWhereInput>;
};

export type ConsultationMaxAggregateOutputType = {
  __typename?: 'ConsultationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  treat?: Maybe<SortOrder>;
  callType?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  veterinarianId?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  nurseId?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
};

export type ConsultationMinAggregateOutputType = {
  __typename?: 'ConsultationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  treat?: Maybe<SortOrder>;
  callType?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  veterinarianId?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  nurseId?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
};

export type ConsultationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ConsultationOrderByRelevanceFieldEnum {
  Id = 'id',
  ConsultationType = 'consultationType',
  ConsultationState = 'consultationState',
  Summary = 'summary',
  Details = 'details',
  Treat = 'treat',
  CallType = 'callType',
  FirebaseId = 'firebaseId',
  ClinicId = 'clinicId',
  PetId = 'petId',
  TransactionId = 'transactionId',
  PetParentId = 'petParentId',
  VeterinarianId = 'veterinarianId',
  NurseId = 'nurseId'
}

export type ConsultationOrderByRelevanceInput = {
  fields: Array<ConsultationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ConsultationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  treat?: Maybe<SortOrder>;
  callType?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  veterinarianId?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  nurseId?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
  _count?: Maybe<ConsultationCountOrderByAggregateInput>;
  _avg?: Maybe<ConsultationAvgOrderByAggregateInput>;
  _max?: Maybe<ConsultationMaxOrderByAggregateInput>;
  _min?: Maybe<ConsultationMinOrderByAggregateInput>;
  _sum?: Maybe<ConsultationSumOrderByAggregateInput>;
};

export type ConsultationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  treat?: Maybe<SortOrder>;
  callType?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  veterinarianId?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  nurseId?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  chats?: Maybe<ConsultationChatOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  nurse?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  pet?: Maybe<PetOrderByWithRelationAndSearchRelevanceInput>;
  petParent?: Maybe<OwnerInfoOrderByWithRelationAndSearchRelevanceInput>;
  pics?: Maybe<ImageOrderByRelationAggregateInput>;
  transactions?: Maybe<TransactionOrderByRelationAggregateInput>;
  vet?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ConsultationOrderByRelevanceInput>;
};

export type ConsultationRelationFilter = {
  is?: Maybe<ConsultationWhereInput>;
  isNot?: Maybe<ConsultationWhereInput>;
};

/** @deprecated - 1.0 */
export type ConsultationRequest = {
  __typename?: 'ConsultationRequest';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petName: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  intent: Scalars['String'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  clinic?: Maybe<Clinic>;
};

export type ConsultationRequestCountAggregateOutputType = {
  __typename?: 'ConsultationRequestCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  petParentEmail: Scalars['Int'];
  petParentFirstname: Scalars['Int'];
  petParentLastname: Scalars['Int'];
  petParentPhone: Scalars['Int'];
  petName: Scalars['Int'];
  petSpecies: Scalars['Int'];
  summary: Scalars['Int'];
  clinicId: Scalars['Int'];
  intent: Scalars['Int'];
  isExistingClient: Scalars['Int'];
  consultationFirebaseId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ConsultationRequestCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type ConsultationRequestCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petName: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  intent: Scalars['String'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationRequestsInput>;
};

export type ConsultationRequestCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petName: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  intent: Scalars['String'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestCreateManyClinicInputEnvelope = {
  data: Array<ConsultationRequestCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petName: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  intent: Scalars['String'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ConsultationRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationRequestCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ConsultationRequestCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
};

export type ConsultationRequestCreateOrConnectWithoutClinicInput = {
  where: ConsultationRequestWhereUniqueInput;
  create: ConsultationRequestUncheckedCreateWithoutClinicInput;
};

export type ConsultationRequestCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petName: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  intent: Scalars['String'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestListRelationFilter = {
  every?: Maybe<ConsultationRequestWhereInput>;
  some?: Maybe<ConsultationRequestWhereInput>;
  none?: Maybe<ConsultationRequestWhereInput>;
};

export type ConsultationRequestMaxAggregateOutputType = {
  __typename?: 'ConsultationRequestMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type ConsultationRequestMinAggregateOutputType = {
  __typename?: 'ConsultationRequestMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type ConsultationRequestOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ConsultationRequestOrderByRelevanceFieldEnum {
  Id = 'id',
  PetParentEmail = 'petParentEmail',
  PetParentFirstname = 'petParentFirstname',
  PetParentLastname = 'petParentLastname',
  PetParentPhone = 'petParentPhone',
  PetName = 'petName',
  PetSpecies = 'petSpecies',
  Summary = 'summary',
  ClinicId = 'clinicId',
  Intent = 'intent',
  ConsultationFirebaseId = 'consultationFirebaseId'
}

export type ConsultationRequestOrderByRelevanceInput = {
  fields: Array<ConsultationRequestOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ConsultationRequestOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _count?: Maybe<ConsultationRequestCountOrderByAggregateInput>;
  _max?: Maybe<ConsultationRequestMaxOrderByAggregateInput>;
  _min?: Maybe<ConsultationRequestMinOrderByAggregateInput>;
};

export type ConsultationRequestOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ConsultationRequestOrderByRelevanceInput>;
};

export enum ConsultationRequestScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PetParentEmail = 'petParentEmail',
  PetParentFirstname = 'petParentFirstname',
  PetParentLastname = 'petParentLastname',
  PetParentPhone = 'petParentPhone',
  PetName = 'petName',
  PetSpecies = 'petSpecies',
  Summary = 'summary',
  ClinicId = 'clinicId',
  Intent = 'intent',
  IsExistingClient = 'isExistingClient',
  ConsultationFirebaseId = 'consultationFirebaseId',
  IsDeleted = 'isDeleted'
}

export type ConsultationRequestScalarWhereInput = {
  AND?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
  OR?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
  NOT?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  petParentEmail?: Maybe<StringNullableFilter>;
  petParentFirstname?: Maybe<StringFilter>;
  petParentLastname?: Maybe<StringFilter>;
  petParentPhone?: Maybe<StringFilter>;
  petName?: Maybe<StringFilter>;
  petSpecies?: Maybe<StringNullableFilter>;
  summary?: Maybe<StringFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  intent?: Maybe<StringFilter>;
  isExistingClient?: Maybe<BoolNullableFilter>;
  consultationFirebaseId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type ConsultationRequestScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConsultationRequestScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConsultationRequestScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConsultationRequestScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  petParentEmail?: Maybe<StringNullableWithAggregatesFilter>;
  petParentFirstname?: Maybe<StringWithAggregatesFilter>;
  petParentLastname?: Maybe<StringWithAggregatesFilter>;
  petParentPhone?: Maybe<StringWithAggregatesFilter>;
  petName?: Maybe<StringWithAggregatesFilter>;
  petSpecies?: Maybe<StringNullableWithAggregatesFilter>;
  summary?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  intent?: Maybe<StringWithAggregatesFilter>;
  isExistingClient?: Maybe<BoolNullableWithAggregatesFilter>;
  consultationFirebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
};

export type ConsultationRequestUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petName: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  intent: Scalars['String'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ConsultationRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationRequestCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ConsultationRequestCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
};

export type ConsultationRequestUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petName: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  intent: Scalars['String'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ConsultationRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationRequestCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ConsultationRequestUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ConsultationRequestCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationRequestUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ConsultationRequestUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
};

export type ConsultationRequestUncheckedUpdateManyWithoutConsultationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationRequestsNestedInput>;
};

export type ConsultationRequestUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUpdateManyWithWhereWithoutClinicInput = {
  where: ConsultationRequestScalarWhereInput;
  data: ConsultationRequestUncheckedUpdateManyWithoutConsultationRequestsInput;
};

export type ConsultationRequestUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ConsultationRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationRequestCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ConsultationRequestUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ConsultationRequestCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationRequestUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ConsultationRequestUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
};

export type ConsultationRequestUpdateWithWhereUniqueWithoutClinicInput = {
  where: ConsultationRequestWhereUniqueInput;
  data: ConsultationRequestUncheckedUpdateWithoutClinicInput;
};

export type ConsultationRequestUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUpsertWithWhereUniqueWithoutClinicInput = {
  where: ConsultationRequestWhereUniqueInput;
  update: ConsultationRequestUncheckedUpdateWithoutClinicInput;
  create: ConsultationRequestUncheckedCreateWithoutClinicInput;
};

export type ConsultationRequestWhereInput = {
  AND?: Maybe<Array<ConsultationRequestWhereInput>>;
  OR?: Maybe<Array<ConsultationRequestWhereInput>>;
  NOT?: Maybe<Array<ConsultationRequestWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  petParentEmail?: Maybe<StringNullableFilter>;
  petParentFirstname?: Maybe<StringFilter>;
  petParentLastname?: Maybe<StringFilter>;
  petParentPhone?: Maybe<StringFilter>;
  petName?: Maybe<StringFilter>;
  petSpecies?: Maybe<StringNullableFilter>;
  summary?: Maybe<StringFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  intent?: Maybe<StringFilter>;
  isExistingClient?: Maybe<BoolNullableFilter>;
  consultationFirebaseId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type ConsultationRequestWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ConsultationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  ConsultationType = 'consultationType',
  Cost = 'cost',
  ConsultationState = 'consultationState',
  Summary = 'summary',
  Details = 'details',
  Treat = 'treat',
  CallType = 'callType',
  LastMessageAt = 'lastMessageAt',
  FirebaseId = 'firebaseId',
  ClinicId = 'clinicId',
  CreatedAtCopy = 'createdAtCopy',
  OpenedAt = 'openedAt',
  ResolvedAt = 'resolvedAt',
  PetId = 'petId',
  TransactionId = 'transactionId',
  PetParentId = 'petParentId',
  VeterinarianId = 'veterinarianId',
  ScheduledAt = 'scheduledAt',
  CancelledAt = 'cancelledAt',
  UpdatedAt = 'updatedAt',
  NurseId = 'nurseId',
  ClientCost = 'clientCost',
  IsPhoneAllowed = 'isPhoneAllowed',
  IsVideoAllowed = 'isVideoAllowed',
  IsVetEmailed = 'isVetEmailed',
  IsVideoChat = 'isVideoChat'
}

export type ConsultationScalarWhereInput = {
  AND?: Maybe<Array<ConsultationScalarWhereInput>>;
  OR?: Maybe<Array<ConsultationScalarWhereInput>>;
  NOT?: Maybe<Array<ConsultationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  consultationType?: Maybe<StringFilter>;
  cost?: Maybe<FloatNullableFilter>;
  consultationState?: Maybe<StringFilter>;
  summary?: Maybe<StringNullableFilter>;
  details?: Maybe<StringNullableFilter>;
  treat?: Maybe<StringNullableFilter>;
  callType?: Maybe<StringNullableFilter>;
  lastMessageAt?: Maybe<DateTimeNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAtCopy?: Maybe<DateTimeNullableFilter>;
  openedAt?: Maybe<DateTimeNullableFilter>;
  resolvedAt?: Maybe<DateTimeNullableFilter>;
  petId?: Maybe<StringNullableFilter>;
  transactionId?: Maybe<StringNullableFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  veterinarianId?: Maybe<StringNullableFilter>;
  scheduledAt?: Maybe<DateTimeNullableFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  nurseId?: Maybe<StringNullableFilter>;
  clientCost?: Maybe<FloatNullableFilter>;
  isPhoneAllowed?: Maybe<BoolNullableFilter>;
  isVideoAllowed?: Maybe<BoolNullableFilter>;
  isVetEmailed?: Maybe<BoolNullableFilter>;
  isVideoChat?: Maybe<BoolNullableFilter>;
};

export type ConsultationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConsultationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConsultationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConsultationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  consultationType?: Maybe<StringWithAggregatesFilter>;
  cost?: Maybe<FloatNullableWithAggregatesFilter>;
  consultationState?: Maybe<StringWithAggregatesFilter>;
  summary?: Maybe<StringNullableWithAggregatesFilter>;
  details?: Maybe<StringNullableWithAggregatesFilter>;
  treat?: Maybe<StringNullableWithAggregatesFilter>;
  callType?: Maybe<StringNullableWithAggregatesFilter>;
  lastMessageAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAtCopy?: Maybe<DateTimeNullableWithAggregatesFilter>;
  openedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  resolvedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  petId?: Maybe<StringNullableWithAggregatesFilter>;
  transactionId?: Maybe<StringNullableWithAggregatesFilter>;
  petParentId?: Maybe<StringNullableWithAggregatesFilter>;
  veterinarianId?: Maybe<StringNullableWithAggregatesFilter>;
  scheduledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  cancelledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  nurseId?: Maybe<StringNullableWithAggregatesFilter>;
  clientCost?: Maybe<FloatNullableWithAggregatesFilter>;
  isPhoneAllowed?: Maybe<BoolNullableWithAggregatesFilter>;
  isVideoAllowed?: Maybe<BoolNullableWithAggregatesFilter>;
  isVetEmailed?: Maybe<BoolNullableWithAggregatesFilter>;
  isVideoChat?: Maybe<BoolNullableWithAggregatesFilter>;
};

export type ConsultationSumAggregateOutputType = {
  __typename?: 'ConsultationSumAggregateOutputType';
  cost?: Maybe<Scalars['Float']>;
  clientCost?: Maybe<Scalars['Float']>;
};

export type ConsultationSumOrderByAggregateInput = {
  cost?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
};

export type ConsultationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ConsultationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ConsultationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationUncheckedCreateNestedManyWithoutNurseInput = {
  create?: Maybe<Array<ConsultationCreateWithoutNurseInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutNurseInput>>;
  createMany?: Maybe<ConsultationCreateManyNurseInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationUncheckedCreateNestedManyWithoutPetInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetInput>>;
  createMany?: Maybe<ConsultationCreateManyPetInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationUncheckedCreateNestedManyWithoutPetParentInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetParentInput>>;
  createMany?: Maybe<ConsultationCreateManyPetParentInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationUncheckedCreateNestedManyWithoutPicsInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPicsInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPicsInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationUncheckedCreateNestedManyWithoutVetInput = {
  create?: Maybe<Array<ConsultationCreateWithoutVetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutVetInput>>;
  createMany?: Maybe<ConsultationCreateManyVetInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutNurseInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutPicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutVetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
};

export type ConsultationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ConsultationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ConsultationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationUncheckedUpdateManyWithoutConsultationsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationUncheckedUpdateManyWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationUncheckedUpdateManyWithoutNurseNestedInput = {
  create?: Maybe<Array<ConsultationCreateWithoutNurseInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutNurseInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutNurseInput>>;
  createMany?: Maybe<ConsultationCreateManyNurseInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutNurseInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutNurseInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutPetNestedInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPetInput>>;
  createMany?: Maybe<ConsultationCreateManyPetInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPetInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPetInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutPetParentNestedInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetParentInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPetParentInput>>;
  createMany?: Maybe<ConsultationCreateManyPetParentInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPetParentInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutPicsNestedInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPicsInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPicsInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPicsInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPicsInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPicsInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutVetNestedInput = {
  create?: Maybe<Array<ConsultationCreateWithoutVetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutVetInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutVetInput>>;
  createMany?: Maybe<ConsultationCreateManyVetInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutVetInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutVetInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
};

export type ConsultationUncheckedUpdateWithoutChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
};

export type ConsultationUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
};

export type ConsultationUncheckedUpdateWithoutNurseInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
};

export type ConsultationUncheckedUpdateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
};

export type ConsultationUncheckedUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
};

export type ConsultationUncheckedUpdateWithoutPicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
};

export type ConsultationUncheckedUpdateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
};

export type ConsultationUncheckedUpdateWithoutVetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
};

export type ConsultationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationUpdateManyWithWhereWithoutClinicInput = {
  where: ConsultationScalarWhereInput;
  data: ConsultationUncheckedUpdateManyWithoutConsultationsInput;
};

export type ConsultationUpdateManyWithWhereWithoutNurseInput = {
  where: ConsultationScalarWhereInput;
  data: ConsultationUncheckedUpdateManyWithoutConsultationsIdInput;
};

export type ConsultationUpdateManyWithWhereWithoutPetInput = {
  where: ConsultationScalarWhereInput;
  data: ConsultationUncheckedUpdateManyWithoutConsultationsInput;
};

export type ConsultationUpdateManyWithWhereWithoutPetParentInput = {
  where: ConsultationScalarWhereInput;
  data: ConsultationUncheckedUpdateManyWithoutConsultationsInput;
};

export type ConsultationUpdateManyWithWhereWithoutPicsInput = {
  where: ConsultationScalarWhereInput;
  data: ConsultationUncheckedUpdateManyWithoutConsultationInput;
};

export type ConsultationUpdateManyWithWhereWithoutVetInput = {
  where: ConsultationScalarWhereInput;
  data: ConsultationUncheckedUpdateManyWithoutConsultationsInput;
};

export type ConsultationUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<ConsultationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ConsultationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUpdateManyWithoutNurseNestedInput = {
  create?: Maybe<Array<ConsultationCreateWithoutNurseInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutNurseInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutNurseInput>>;
  createMany?: Maybe<ConsultationCreateManyNurseInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutNurseInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutNurseInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUpdateManyWithoutPetNestedInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPetInput>>;
  createMany?: Maybe<ConsultationCreateManyPetInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPetInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPetInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUpdateManyWithoutPetParentNestedInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetParentInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPetParentInput>>;
  createMany?: Maybe<ConsultationCreateManyPetParentInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPetParentInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUpdateManyWithoutPicsNestedInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPicsInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPicsInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPicsInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPicsInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPicsInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUpdateManyWithoutVetNestedInput = {
  create?: Maybe<Array<ConsultationCreateWithoutVetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutVetInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutVetInput>>;
  createMany?: Maybe<ConsultationCreateManyVetInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutVetInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutVetInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUpdateOneWithoutAppointmentsNestedInput = {
  create?: Maybe<ConsultationUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<ConsultationUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ConsultationWhereUniqueInput>;
  update?: Maybe<ConsultationUncheckedUpdateWithoutAppointmentsInput>;
};

export type ConsultationUpdateOneWithoutChatsNestedInput = {
  create?: Maybe<ConsultationUncheckedCreateWithoutChatsInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutChatsInput>;
  upsert?: Maybe<ConsultationUpsertWithoutChatsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ConsultationWhereUniqueInput>;
  update?: Maybe<ConsultationUncheckedUpdateWithoutChatsInput>;
};

export type ConsultationUpdateOneWithoutTransactionsNestedInput = {
  create?: Maybe<ConsultationUncheckedCreateWithoutTransactionsInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutTransactionsInput>;
  upsert?: Maybe<ConsultationUpsertWithoutTransactionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ConsultationWhereUniqueInput>;
  update?: Maybe<ConsultationUncheckedUpdateWithoutTransactionsInput>;
};

export type ConsultationUpdateWithWhereUniqueWithoutClinicInput = {
  where: ConsultationWhereUniqueInput;
  data: ConsultationUncheckedUpdateWithoutClinicInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutNurseInput = {
  where: ConsultationWhereUniqueInput;
  data: ConsultationUncheckedUpdateWithoutNurseInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutPetInput = {
  where: ConsultationWhereUniqueInput;
  data: ConsultationUncheckedUpdateWithoutPetInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutPetParentInput = {
  where: ConsultationWhereUniqueInput;
  data: ConsultationUncheckedUpdateWithoutPetParentInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutPicsInput = {
  where: ConsultationWhereUniqueInput;
  data: ConsultationUncheckedUpdateWithoutPicsInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutVetInput = {
  where: ConsultationWhereUniqueInput;
  data: ConsultationUncheckedUpdateWithoutVetInput;
};

export type ConsultationUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutNurseInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutPicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutVetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
};

export type ConsultationUpsertWithWhereUniqueWithoutClinicInput = {
  where: ConsultationWhereUniqueInput;
  update: ConsultationUncheckedUpdateWithoutClinicInput;
  create: ConsultationUncheckedCreateWithoutClinicInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutNurseInput = {
  where: ConsultationWhereUniqueInput;
  update: ConsultationUncheckedUpdateWithoutNurseInput;
  create: ConsultationUncheckedCreateWithoutNurseInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutPetInput = {
  where: ConsultationWhereUniqueInput;
  update: ConsultationUncheckedUpdateWithoutPetInput;
  create: ConsultationUncheckedCreateWithoutPetInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutPetParentInput = {
  where: ConsultationWhereUniqueInput;
  update: ConsultationUncheckedUpdateWithoutPetParentInput;
  create: ConsultationUncheckedCreateWithoutPetParentInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutPicsInput = {
  where: ConsultationWhereUniqueInput;
  update: ConsultationUncheckedUpdateWithoutPicsInput;
  create: ConsultationUncheckedCreateWithoutPicsInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutVetInput = {
  where: ConsultationWhereUniqueInput;
  update: ConsultationUncheckedUpdateWithoutVetInput;
  create: ConsultationUncheckedCreateWithoutVetInput;
};

export type ConsultationUpsertWithoutAppointmentsInput = {
  update: ConsultationUncheckedUpdateWithoutAppointmentsInput;
  create: ConsultationUncheckedCreateWithoutAppointmentsInput;
};

export type ConsultationUpsertWithoutChatsInput = {
  update: ConsultationUncheckedUpdateWithoutChatsInput;
  create: ConsultationUncheckedCreateWithoutChatsInput;
};

export type ConsultationUpsertWithoutTransactionsInput = {
  update: ConsultationUncheckedUpdateWithoutTransactionsInput;
  create: ConsultationUncheckedCreateWithoutTransactionsInput;
};

export type ConsultationWhereInput = {
  AND?: Maybe<Array<ConsultationWhereInput>>;
  OR?: Maybe<Array<ConsultationWhereInput>>;
  NOT?: Maybe<Array<ConsultationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  consultationType?: Maybe<StringFilter>;
  cost?: Maybe<FloatNullableFilter>;
  consultationState?: Maybe<StringFilter>;
  summary?: Maybe<StringNullableFilter>;
  details?: Maybe<StringNullableFilter>;
  treat?: Maybe<StringNullableFilter>;
  callType?: Maybe<StringNullableFilter>;
  lastMessageAt?: Maybe<DateTimeNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAtCopy?: Maybe<DateTimeNullableFilter>;
  openedAt?: Maybe<DateTimeNullableFilter>;
  resolvedAt?: Maybe<DateTimeNullableFilter>;
  petId?: Maybe<StringNullableFilter>;
  transactionId?: Maybe<StringNullableFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  veterinarianId?: Maybe<StringNullableFilter>;
  scheduledAt?: Maybe<DateTimeNullableFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  nurseId?: Maybe<StringNullableFilter>;
  clientCost?: Maybe<FloatNullableFilter>;
  isPhoneAllowed?: Maybe<BoolNullableFilter>;
  isVideoAllowed?: Maybe<BoolNullableFilter>;
  isVetEmailed?: Maybe<BoolNullableFilter>;
  isVideoChat?: Maybe<BoolNullableFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  chats?: Maybe<ConsultationChatListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  nurse?: Maybe<VetInfoWhereInput>;
  pet?: Maybe<PetWhereInput>;
  petParent?: Maybe<OwnerInfoWhereInput>;
  pics?: Maybe<ImageListRelationFilter>;
  transactions?: Maybe<TransactionListRelationFilter>;
  vet?: Maybe<VetInfoWhereInput>;
};

export type ConsultationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
};

export enum ContactEntityType {
  ClinicPetParent = 'ClinicPetParent',
  User = 'User'
}

export enum ContactVerificationMethod {
  Email = 'Email',
  Text = 'Text'
}

export enum ContextEntityType {
  Appointment = 'Appointment',
  AutomationRunAction = 'AutomationRunAction'
}

export type CreateCareEnrollmentAndSubscriptionInput = {
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  petSignups: Array<CarePetSignupInput>;
};

export type CreateCareEnrollmentAndSubscriptionResult = {
  __typename?: 'CreateCareEnrollmentAndSubscriptionResult';
  success: Scalars['Boolean'];
  message: Scalars['String'];
  paymentError?: Maybe<Scalars['String']>;
};

export type CreateCarePaymentMethodSetupInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type CreateChannelFilterSelectionInput = {
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
};

export type CreateEmailTemplateVersionInput = {
  unlayerId: Scalars['Int'];
  type: Scalars['String'];
  html: Scalars['String'];
  design: Scalars['Json'];
};

export type CreatePaymentMethodSetupInputResult = {
  __typename?: 'CreatePaymentMethodSetupInputResult';
  setupIntentSecret: Scalars['String'];
};

export type CreateTeamChannelInput = {
  friendlyName: Scalars['String'];
  assigneeIds: Array<Scalars['ID']>;
  channelVisibility?: Maybe<ChannelVisibility>;
};

export type CreateTwilioTokenInput = {
  identity: Scalars['String'];
};

export type CreateTwilioTokenResponse = {
  __typename?: 'CreateTwilioTokenResponse';
  token: Scalars['String'];
};

export type CreateWorkflowEventSettingAndConditionsInput = {
  workflowEventSetting: WorkflowEventSettingCreateInput;
  conditions: Array<ConditionCreateWithoutSetInput>;
};

export enum Currency {
  Usd = 'usd'
}

export type CurrentEmailTemplateVersionsFindResponse = {
  __typename?: 'CurrentEmailTemplateVersionsFindResponse';
  unlayer: UnlayerUser;
  draft?: Maybe<EmailTemplateVersion>;
  published?: Maybe<EmailTemplateVersion>;
};

export type CustomFieldDefinition = {
  __typename?: 'CustomFieldDefinition';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  targetType: CustomFieldTargetType;
  designation: CustomFieldDesignation;
  /**
   * isSensitiveData marks a field that contains data we do not want to sync regardless of a the settings a clinic may desire
   * because the information includes information like SSN, Credit Card, etc.
   * this behavior should override any sync settings if there is a conflict
   */
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType: CustomFieldNormalizedDataType;
  existsInPims: Scalars['Boolean'];
  pimsSyncType: CustomFieldPimsSyncType;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  /** Used to resolve sync conflicts when pimsSyncType is ReadWrite, if null use latest */
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  /** the DATE of the deletion of the field, not IF the field is deleted */
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  clinicPimsIntegrationId: Scalars['String'];
  /** Raw Pims data synced from the pims */
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPimsIntegration: ClinicPimsIntegration;
  /** Should only have one of these and should match the targetType */
  clinicPetParentFieldValues: Array<ClinicPetParentCustomFieldValue>;
  clinicPetFieldValues: Array<ClinicPetCustomFieldValue>;
  writebackLogs: Array<CustomFieldPimsWritebackLogEntry>;
  _count: CustomFieldDefinitionCountOutputType;
};


export type CustomFieldDefinitionClinicPetParentFieldValuesArgs = {
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
  orderBy?: Maybe<ClinicPetParentCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentCustomFieldValueWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentCustomFieldValueScalarFieldEnum>;
};


export type CustomFieldDefinitionClinicPetFieldValuesArgs = {
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
  orderBy?: Maybe<ClinicPetCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetCustomFieldValueWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetCustomFieldValueScalarFieldEnum>;
};


export type CustomFieldDefinitionWritebackLogsArgs = {
  where?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<CustomFieldPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CustomFieldPimsWritebackLogEntryScalarFieldEnum>;
};

export type CustomFieldDefinitionCountAggregateOutputType = {
  __typename?: 'CustomFieldDefinitionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  targetType: Scalars['Int'];
  designation: Scalars['Int'];
  isSensitiveData: Scalars['Int'];
  flowAlias: Scalars['Int'];
  normalizedType: Scalars['Int'];
  existsInPims: Scalars['Int'];
  pimsSyncType: Scalars['Int'];
  lastSyncedAt: Scalars['Int'];
  source_of_truth: Scalars['Int'];
  pimsId: Scalars['Int'];
  pimsSourceId: Scalars['Int'];
  pimsName: Scalars['Int'];
  pimsDataType: Scalars['Int'];
  pimsDisplayName: Scalars['Int'];
  pimsIsInactive: Scalars['Int'];
  pimsIsRequired: Scalars['Int'];
  pimsIsDeleted: Scalars['Int'];
  pimsCreated: Scalars['Int'];
  pimsUpdated: Scalars['Int'];
  pimsDeleted: Scalars['Int'];
  clinicPimsIntegrationId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CustomFieldDefinitionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  targetType?: Maybe<SortOrder>;
  designation?: Maybe<SortOrder>;
  isSensitiveData?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  normalizedType?: Maybe<SortOrder>;
  existsInPims?: Maybe<SortOrder>;
  pimsSyncType?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  source_of_truth?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsSourceId?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsDataType?: Maybe<SortOrder>;
  pimsDisplayName?: Maybe<SortOrder>;
  pimsIsInactive?: Maybe<SortOrder>;
  pimsIsRequired?: Maybe<SortOrder>;
  pimsIsDeleted?: Maybe<SortOrder>;
  pimsCreated?: Maybe<SortOrder>;
  pimsUpdated?: Maybe<SortOrder>;
  pimsDeleted?: Maybe<SortOrder>;
  clinicPimsIntegrationId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type CustomFieldDefinitionCountOutputType = {
  __typename?: 'CustomFieldDefinitionCountOutputType';
  clinicPetParentFieldValues: Scalars['Int'];
  clinicPetFieldValues: Scalars['Int'];
  writebackLogs: Scalars['Int'];
};

export type CustomFieldDefinitionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType: CustomFieldTargetType;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType: CustomFieldNormalizedDataType;
  existsInPims: Scalars['Boolean'];
  pimsSyncType: CustomFieldPimsSyncType;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPimsIntegration: ClinicPimsIntegrationCreateNestedOneWithoutCustomFieldDefinitionsInput;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionCreateManyClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType: CustomFieldTargetType;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType: CustomFieldNormalizedDataType;
  existsInPims: Scalars['Boolean'];
  pimsSyncType: CustomFieldPimsSyncType;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type CustomFieldDefinitionCreateManyClinicPimsIntegrationInputEnvelope = {
  data: Array<CustomFieldDefinitionCreateManyClinicPimsIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CustomFieldDefinitionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType: CustomFieldTargetType;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType: CustomFieldNormalizedDataType;
  existsInPims: Scalars['Boolean'];
  pimsSyncType: CustomFieldPimsSyncType;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  clinicPimsIntegrationId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput = {
  create?: Maybe<Array<CustomFieldDefinitionCreateWithoutClinicPimsIntegrationInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldDefinitionCreateOrConnectWithoutClinicPimsIntegrationInput>>;
  createMany?: Maybe<CustomFieldDefinitionCreateManyClinicPimsIntegrationInputEnvelope>;
  connect?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
};

export type CustomFieldDefinitionCreateNestedOneWithoutClinicPetFieldValuesInput = {
  create?: Maybe<CustomFieldDefinitionUncheckedCreateWithoutClinicPetFieldValuesInput>;
  connectOrCreate?: Maybe<CustomFieldDefinitionCreateOrConnectWithoutClinicPetFieldValuesInput>;
  connect?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
};

export type CustomFieldDefinitionCreateNestedOneWithoutClinicPetParentFieldValuesInput = {
  create?: Maybe<CustomFieldDefinitionUncheckedCreateWithoutClinicPetParentFieldValuesInput>;
  connectOrCreate?: Maybe<CustomFieldDefinitionCreateOrConnectWithoutClinicPetParentFieldValuesInput>;
  connect?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
};

export type CustomFieldDefinitionCreateNestedOneWithoutWritebackLogsInput = {
  create?: Maybe<CustomFieldDefinitionUncheckedCreateWithoutWritebackLogsInput>;
  connectOrCreate?: Maybe<CustomFieldDefinitionCreateOrConnectWithoutWritebackLogsInput>;
  connect?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
};

export type CustomFieldDefinitionCreateOrConnectWithoutClinicPetFieldValuesInput = {
  where: CustomFieldDefinitionWhereUniqueInput;
  create: CustomFieldDefinitionUncheckedCreateWithoutClinicPetFieldValuesInput;
};

export type CustomFieldDefinitionCreateOrConnectWithoutClinicPetParentFieldValuesInput = {
  where: CustomFieldDefinitionWhereUniqueInput;
  create: CustomFieldDefinitionUncheckedCreateWithoutClinicPetParentFieldValuesInput;
};

export type CustomFieldDefinitionCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: CustomFieldDefinitionWhereUniqueInput;
  create: CustomFieldDefinitionUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type CustomFieldDefinitionCreateOrConnectWithoutWritebackLogsInput = {
  where: CustomFieldDefinitionWhereUniqueInput;
  create: CustomFieldDefinitionUncheckedCreateWithoutWritebackLogsInput;
};

export type CustomFieldDefinitionCreateWithoutClinicPetFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType: CustomFieldTargetType;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType: CustomFieldNormalizedDataType;
  existsInPims: Scalars['Boolean'];
  pimsSyncType: CustomFieldPimsSyncType;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPimsIntegration: ClinicPimsIntegrationCreateNestedOneWithoutCustomFieldDefinitionsInput;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionCreateWithoutClinicPetParentFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType: CustomFieldTargetType;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType: CustomFieldNormalizedDataType;
  existsInPims: Scalars['Boolean'];
  pimsSyncType: CustomFieldPimsSyncType;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPimsIntegration: ClinicPimsIntegrationCreateNestedOneWithoutCustomFieldDefinitionsInput;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType: CustomFieldTargetType;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType: CustomFieldNormalizedDataType;
  existsInPims: Scalars['Boolean'];
  pimsSyncType: CustomFieldPimsSyncType;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionCreateWithoutWritebackLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType: CustomFieldTargetType;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType: CustomFieldNormalizedDataType;
  existsInPims: Scalars['Boolean'];
  pimsSyncType: CustomFieldPimsSyncType;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPimsIntegration: ClinicPimsIntegrationCreateNestedOneWithoutCustomFieldDefinitionsInput;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
};

export type CustomFieldDefinitionListRelationFilter = {
  every?: Maybe<CustomFieldDefinitionWhereInput>;
  some?: Maybe<CustomFieldDefinitionWhereInput>;
  none?: Maybe<CustomFieldDefinitionWhereInput>;
};

export type CustomFieldDefinitionMaxAggregateOutputType = {
  __typename?: 'CustomFieldDefinitionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  clinicPimsIntegrationId?: Maybe<Scalars['String']>;
};

export type CustomFieldDefinitionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  targetType?: Maybe<SortOrder>;
  designation?: Maybe<SortOrder>;
  isSensitiveData?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  normalizedType?: Maybe<SortOrder>;
  existsInPims?: Maybe<SortOrder>;
  pimsSyncType?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  source_of_truth?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsSourceId?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsDataType?: Maybe<SortOrder>;
  pimsDisplayName?: Maybe<SortOrder>;
  pimsIsInactive?: Maybe<SortOrder>;
  pimsIsRequired?: Maybe<SortOrder>;
  pimsIsDeleted?: Maybe<SortOrder>;
  pimsCreated?: Maybe<SortOrder>;
  pimsUpdated?: Maybe<SortOrder>;
  pimsDeleted?: Maybe<SortOrder>;
  clinicPimsIntegrationId?: Maybe<SortOrder>;
};

export type CustomFieldDefinitionMinAggregateOutputType = {
  __typename?: 'CustomFieldDefinitionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  clinicPimsIntegrationId?: Maybe<Scalars['String']>;
};

export type CustomFieldDefinitionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  targetType?: Maybe<SortOrder>;
  designation?: Maybe<SortOrder>;
  isSensitiveData?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  normalizedType?: Maybe<SortOrder>;
  existsInPims?: Maybe<SortOrder>;
  pimsSyncType?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  source_of_truth?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsSourceId?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsDataType?: Maybe<SortOrder>;
  pimsDisplayName?: Maybe<SortOrder>;
  pimsIsInactive?: Maybe<SortOrder>;
  pimsIsRequired?: Maybe<SortOrder>;
  pimsIsDeleted?: Maybe<SortOrder>;
  pimsCreated?: Maybe<SortOrder>;
  pimsUpdated?: Maybe<SortOrder>;
  pimsDeleted?: Maybe<SortOrder>;
  clinicPimsIntegrationId?: Maybe<SortOrder>;
};

export type CustomFieldDefinitionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CustomFieldDefinitionOrderByRelevanceFieldEnum {
  Id = 'id',
  FlowAlias = 'flowAlias',
  PimsId = 'pimsId',
  PimsSourceId = 'pimsSourceId',
  PimsName = 'pimsName',
  PimsDataType = 'pimsDataType',
  PimsDisplayName = 'pimsDisplayName',
  ClinicPimsIntegrationId = 'clinicPimsIntegrationId'
}

export type CustomFieldDefinitionOrderByRelevanceInput = {
  fields: Array<CustomFieldDefinitionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CustomFieldDefinitionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  targetType?: Maybe<SortOrder>;
  designation?: Maybe<SortOrder>;
  isSensitiveData?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  normalizedType?: Maybe<SortOrder>;
  existsInPims?: Maybe<SortOrder>;
  pimsSyncType?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  source_of_truth?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsSourceId?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsDataType?: Maybe<SortOrder>;
  pimsDisplayName?: Maybe<SortOrder>;
  pimsIsInactive?: Maybe<SortOrder>;
  pimsIsRequired?: Maybe<SortOrder>;
  pimsIsDeleted?: Maybe<SortOrder>;
  pimsCreated?: Maybe<SortOrder>;
  pimsUpdated?: Maybe<SortOrder>;
  pimsDeleted?: Maybe<SortOrder>;
  clinicPimsIntegrationId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<CustomFieldDefinitionCountOrderByAggregateInput>;
  _max?: Maybe<CustomFieldDefinitionMaxOrderByAggregateInput>;
  _min?: Maybe<CustomFieldDefinitionMinOrderByAggregateInput>;
};

export type CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  targetType?: Maybe<SortOrder>;
  designation?: Maybe<SortOrder>;
  isSensitiveData?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  normalizedType?: Maybe<SortOrder>;
  existsInPims?: Maybe<SortOrder>;
  pimsSyncType?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  source_of_truth?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsSourceId?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsDataType?: Maybe<SortOrder>;
  pimsDisplayName?: Maybe<SortOrder>;
  pimsIsInactive?: Maybe<SortOrder>;
  pimsIsRequired?: Maybe<SortOrder>;
  pimsIsDeleted?: Maybe<SortOrder>;
  pimsCreated?: Maybe<SortOrder>;
  pimsUpdated?: Maybe<SortOrder>;
  pimsDeleted?: Maybe<SortOrder>;
  clinicPimsIntegrationId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueOrderByRelationAggregateInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueOrderByRelationAggregateInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryOrderByRelationAggregateInput>;
  _relevance?: Maybe<CustomFieldDefinitionOrderByRelevanceInput>;
};

export type CustomFieldDefinitionPimsIdClinicPimsIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  clinicPimsIntegrationId: Scalars['String'];
};

export type CustomFieldDefinitionRelationFilter = {
  is?: Maybe<CustomFieldDefinitionWhereInput>;
  isNot?: Maybe<CustomFieldDefinitionWhereInput>;
};

export enum CustomFieldDefinitionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  TargetType = 'targetType',
  Designation = 'designation',
  IsSensitiveData = 'isSensitiveData',
  FlowAlias = 'flowAlias',
  NormalizedType = 'normalizedType',
  ExistsInPims = 'existsInPims',
  PimsSyncType = 'pimsSyncType',
  LastSyncedAt = 'lastSyncedAt',
  SourceOfTruth = 'source_of_truth',
  PimsId = 'pimsId',
  PimsSourceId = 'pimsSourceId',
  PimsName = 'pimsName',
  PimsDataType = 'pimsDataType',
  PimsDisplayName = 'pimsDisplayName',
  PimsIsInactive = 'pimsIsInactive',
  PimsIsRequired = 'pimsIsRequired',
  PimsIsDeleted = 'pimsIsDeleted',
  PimsCreated = 'pimsCreated',
  PimsUpdated = 'pimsUpdated',
  PimsDeleted = 'pimsDeleted',
  ClinicPimsIntegrationId = 'clinicPimsIntegrationId',
  RawPimsValue = 'rawPimsValue'
}

export type CustomFieldDefinitionScalarWhereInput = {
  AND?: Maybe<Array<CustomFieldDefinitionScalarWhereInput>>;
  OR?: Maybe<Array<CustomFieldDefinitionScalarWhereInput>>;
  NOT?: Maybe<Array<CustomFieldDefinitionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  targetType?: Maybe<EnumCustomFieldTargetTypeFilter>;
  designation?: Maybe<EnumCustomFieldDesignationFilter>;
  isSensitiveData?: Maybe<BoolNullableFilter>;
  flowAlias?: Maybe<StringNullableFilter>;
  normalizedType?: Maybe<EnumCustomFieldNormalizedDataTypeFilter>;
  existsInPims?: Maybe<BoolFilter>;
  pimsSyncType?: Maybe<EnumCustomFieldPimsSyncTypeFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  source_of_truth?: Maybe<EnumCustomFieldsDataSourceNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  pimsSourceId?: Maybe<StringNullableFilter>;
  pimsName?: Maybe<StringNullableFilter>;
  pimsDataType?: Maybe<StringNullableFilter>;
  pimsDisplayName?: Maybe<StringNullableFilter>;
  pimsIsInactive?: Maybe<BoolNullableFilter>;
  pimsIsRequired?: Maybe<BoolNullableFilter>;
  pimsIsDeleted?: Maybe<BoolNullableFilter>;
  pimsCreated?: Maybe<DateTimeNullableFilter>;
  pimsUpdated?: Maybe<DateTimeNullableFilter>;
  pimsDeleted?: Maybe<DateTimeNullableFilter>;
  clinicPimsIntegrationId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type CustomFieldDefinitionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CustomFieldDefinitionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CustomFieldDefinitionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CustomFieldDefinitionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  targetType?: Maybe<EnumCustomFieldTargetTypeWithAggregatesFilter>;
  designation?: Maybe<EnumCustomFieldDesignationWithAggregatesFilter>;
  isSensitiveData?: Maybe<BoolNullableWithAggregatesFilter>;
  flowAlias?: Maybe<StringNullableWithAggregatesFilter>;
  normalizedType?: Maybe<EnumCustomFieldNormalizedDataTypeWithAggregatesFilter>;
  existsInPims?: Maybe<BoolWithAggregatesFilter>;
  pimsSyncType?: Maybe<EnumCustomFieldPimsSyncTypeWithAggregatesFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  source_of_truth?: Maybe<EnumCustomFieldsDataSourceNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  pimsSourceId?: Maybe<StringNullableWithAggregatesFilter>;
  pimsName?: Maybe<StringNullableWithAggregatesFilter>;
  pimsDataType?: Maybe<StringNullableWithAggregatesFilter>;
  pimsDisplayName?: Maybe<StringNullableWithAggregatesFilter>;
  pimsIsInactive?: Maybe<BoolNullableWithAggregatesFilter>;
  pimsIsRequired?: Maybe<BoolNullableWithAggregatesFilter>;
  pimsIsDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  pimsCreated?: Maybe<DateTimeNullableWithAggregatesFilter>;
  pimsUpdated?: Maybe<DateTimeNullableWithAggregatesFilter>;
  pimsDeleted?: Maybe<DateTimeNullableWithAggregatesFilter>;
  clinicPimsIntegrationId?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type CustomFieldDefinitionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType: CustomFieldTargetType;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType: CustomFieldNormalizedDataType;
  existsInPims: Scalars['Boolean'];
  pimsSyncType: CustomFieldPimsSyncType;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  clinicPimsIntegrationId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput = {
  create?: Maybe<Array<CustomFieldDefinitionCreateWithoutClinicPimsIntegrationInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldDefinitionCreateOrConnectWithoutClinicPimsIntegrationInput>>;
  createMany?: Maybe<CustomFieldDefinitionCreateManyClinicPimsIntegrationInputEnvelope>;
  connect?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
};

export type CustomFieldDefinitionUncheckedCreateWithoutClinicPetFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType: CustomFieldTargetType;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType: CustomFieldNormalizedDataType;
  existsInPims: Scalars['Boolean'];
  pimsSyncType: CustomFieldPimsSyncType;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  clinicPimsIntegrationId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionUncheckedCreateWithoutClinicPetParentFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType: CustomFieldTargetType;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType: CustomFieldNormalizedDataType;
  existsInPims: Scalars['Boolean'];
  pimsSyncType: CustomFieldPimsSyncType;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  clinicPimsIntegrationId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType: CustomFieldTargetType;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType: CustomFieldNormalizedDataType;
  existsInPims: Scalars['Boolean'];
  pimsSyncType: CustomFieldPimsSyncType;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionUncheckedCreateWithoutWritebackLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType: CustomFieldTargetType;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType: CustomFieldNormalizedDataType;
  existsInPims: Scalars['Boolean'];
  pimsSyncType: CustomFieldPimsSyncType;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  clinicPimsIntegrationId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
};

export type CustomFieldDefinitionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  clinicPimsIntegrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  clinicPimsIntegrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput = {
  create?: Maybe<Array<CustomFieldDefinitionCreateWithoutClinicPimsIntegrationInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldDefinitionCreateOrConnectWithoutClinicPimsIntegrationInput>>;
  upsert?: Maybe<Array<CustomFieldDefinitionUpsertWithWhereUniqueWithoutClinicPimsIntegrationInput>>;
  createMany?: Maybe<CustomFieldDefinitionCreateManyClinicPimsIntegrationInputEnvelope>;
  set?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  delete?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  connect?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<CustomFieldDefinitionUpdateWithWhereUniqueWithoutClinicPimsIntegrationInput>>;
  updateMany?: Maybe<Array<CustomFieldDefinitionUpdateManyWithWhereWithoutClinicPimsIntegrationInput>>;
  deleteMany?: Maybe<Array<CustomFieldDefinitionScalarWhereInput>>;
};

export type CustomFieldDefinitionUncheckedUpdateManyWithoutCustomFieldDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type CustomFieldDefinitionUncheckedUpdateWithoutClinicPetFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  clinicPimsIntegrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUncheckedUpdateWithoutClinicPetParentFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  clinicPimsIntegrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUncheckedUpdateWithoutWritebackLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  clinicPimsIntegrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutCustomFieldDefinitionsNestedInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type CustomFieldDefinitionUpdateManyWithWhereWithoutClinicPimsIntegrationInput = {
  where: CustomFieldDefinitionScalarWhereInput;
  data: CustomFieldDefinitionUncheckedUpdateManyWithoutCustomFieldDefinitionsInput;
};

export type CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput = {
  create?: Maybe<Array<CustomFieldDefinitionCreateWithoutClinicPimsIntegrationInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldDefinitionCreateOrConnectWithoutClinicPimsIntegrationInput>>;
  upsert?: Maybe<Array<CustomFieldDefinitionUpsertWithWhereUniqueWithoutClinicPimsIntegrationInput>>;
  createMany?: Maybe<CustomFieldDefinitionCreateManyClinicPimsIntegrationInputEnvelope>;
  set?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  delete?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  connect?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<CustomFieldDefinitionUpdateWithWhereUniqueWithoutClinicPimsIntegrationInput>>;
  updateMany?: Maybe<Array<CustomFieldDefinitionUpdateManyWithWhereWithoutClinicPimsIntegrationInput>>;
  deleteMany?: Maybe<Array<CustomFieldDefinitionScalarWhereInput>>;
};

export type CustomFieldDefinitionUpdateOneRequiredWithoutClinicPetFieldValuesNestedInput = {
  create?: Maybe<CustomFieldDefinitionUncheckedCreateWithoutClinicPetFieldValuesInput>;
  connectOrCreate?: Maybe<CustomFieldDefinitionCreateOrConnectWithoutClinicPetFieldValuesInput>;
  upsert?: Maybe<CustomFieldDefinitionUpsertWithoutClinicPetFieldValuesInput>;
  connect?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  update?: Maybe<CustomFieldDefinitionUncheckedUpdateWithoutClinicPetFieldValuesInput>;
};

export type CustomFieldDefinitionUpdateOneRequiredWithoutClinicPetParentFieldValuesNestedInput = {
  create?: Maybe<CustomFieldDefinitionUncheckedCreateWithoutClinicPetParentFieldValuesInput>;
  connectOrCreate?: Maybe<CustomFieldDefinitionCreateOrConnectWithoutClinicPetParentFieldValuesInput>;
  upsert?: Maybe<CustomFieldDefinitionUpsertWithoutClinicPetParentFieldValuesInput>;
  connect?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  update?: Maybe<CustomFieldDefinitionUncheckedUpdateWithoutClinicPetParentFieldValuesInput>;
};

export type CustomFieldDefinitionUpdateOneRequiredWithoutWritebackLogsNestedInput = {
  create?: Maybe<CustomFieldDefinitionUncheckedCreateWithoutWritebackLogsInput>;
  connectOrCreate?: Maybe<CustomFieldDefinitionCreateOrConnectWithoutWritebackLogsInput>;
  upsert?: Maybe<CustomFieldDefinitionUpsertWithoutWritebackLogsInput>;
  connect?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  update?: Maybe<CustomFieldDefinitionUncheckedUpdateWithoutWritebackLogsInput>;
};

export type CustomFieldDefinitionUpdateWithWhereUniqueWithoutClinicPimsIntegrationInput = {
  where: CustomFieldDefinitionWhereUniqueInput;
  data: CustomFieldDefinitionUncheckedUpdateWithoutClinicPimsIntegrationInput;
};

export type CustomFieldDefinitionUpdateWithoutClinicPetFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutCustomFieldDefinitionsNestedInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUpdateWithoutClinicPetParentFieldValuesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutCustomFieldDefinitionsNestedInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUpdateWithoutWritebackLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  targetType?: Maybe<CustomFieldTargetType>;
  designation?: Maybe<CustomFieldDesignation>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  pimsId?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutCustomFieldDefinitionsNestedInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUpsertWithWhereUniqueWithoutClinicPimsIntegrationInput = {
  where: CustomFieldDefinitionWhereUniqueInput;
  update: CustomFieldDefinitionUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: CustomFieldDefinitionUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type CustomFieldDefinitionUpsertWithoutClinicPetFieldValuesInput = {
  update: CustomFieldDefinitionUncheckedUpdateWithoutClinicPetFieldValuesInput;
  create: CustomFieldDefinitionUncheckedCreateWithoutClinicPetFieldValuesInput;
};

export type CustomFieldDefinitionUpsertWithoutClinicPetParentFieldValuesInput = {
  update: CustomFieldDefinitionUncheckedUpdateWithoutClinicPetParentFieldValuesInput;
  create: CustomFieldDefinitionUncheckedCreateWithoutClinicPetParentFieldValuesInput;
};

export type CustomFieldDefinitionUpsertWithoutWritebackLogsInput = {
  update: CustomFieldDefinitionUncheckedUpdateWithoutWritebackLogsInput;
  create: CustomFieldDefinitionUncheckedCreateWithoutWritebackLogsInput;
};

export type CustomFieldDefinitionWhereInput = {
  AND?: Maybe<Array<CustomFieldDefinitionWhereInput>>;
  OR?: Maybe<Array<CustomFieldDefinitionWhereInput>>;
  NOT?: Maybe<Array<CustomFieldDefinitionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  targetType?: Maybe<EnumCustomFieldTargetTypeFilter>;
  designation?: Maybe<EnumCustomFieldDesignationFilter>;
  isSensitiveData?: Maybe<BoolNullableFilter>;
  flowAlias?: Maybe<StringNullableFilter>;
  normalizedType?: Maybe<EnumCustomFieldNormalizedDataTypeFilter>;
  existsInPims?: Maybe<BoolFilter>;
  pimsSyncType?: Maybe<EnumCustomFieldPimsSyncTypeFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  source_of_truth?: Maybe<EnumCustomFieldsDataSourceNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  pimsSourceId?: Maybe<StringNullableFilter>;
  pimsName?: Maybe<StringNullableFilter>;
  pimsDataType?: Maybe<StringNullableFilter>;
  pimsDisplayName?: Maybe<StringNullableFilter>;
  pimsIsInactive?: Maybe<BoolNullableFilter>;
  pimsIsRequired?: Maybe<BoolNullableFilter>;
  pimsIsDeleted?: Maybe<BoolNullableFilter>;
  pimsCreated?: Maybe<DateTimeNullableFilter>;
  pimsUpdated?: Maybe<DateTimeNullableFilter>;
  pimsDeleted?: Maybe<DateTimeNullableFilter>;
  clinicPimsIntegrationId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueListRelationFilter>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueListRelationFilter>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryListRelationFilter>;
};

export type CustomFieldDefinitionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsId_clinicPimsIntegrationId?: Maybe<CustomFieldDefinitionPimsIdClinicPimsIntegrationIdCompoundUniqueInput>;
};

export enum CustomFieldDesignation {
  ClinicPetParentCareSubscriptionStatus = 'ClinicPetParent_CareSubscriptionStatus',
  ClinicPetCareSubscriptionStatus = 'ClinicPet_CareSubscriptionStatus',
  ClinicPetCarePlanName = 'ClinicPet_CarePlanName',
  Unspecified = 'Unspecified'
}

export enum CustomFieldNormalizedDataType {
  String = 'String',
  Boolean = 'Boolean',
  Number = 'Number',
  Unknown = 'Unknown'
}

export enum CustomFieldPimsSyncType {
  ReadOnly = 'ReadOnly',
  WriteOnly = 'WriteOnly',
  ReadWrite = 'ReadWrite',
  NoSync = 'NoSync'
}

export type CustomFieldPimsWritebackLogEntry = {
  __typename?: 'CustomFieldPimsWritebackLogEntry';
  writebackLogId: Scalars['String'];
  customFieldDefinitionId: Scalars['String'];
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
  writebackLog: PimsWritebackLogEntry;
  customFieldDefinition: CustomFieldDefinition;
};

export type CustomFieldPimsWritebackLogEntryCountAggregateOutputType = {
  __typename?: 'CustomFieldPimsWritebackLogEntryCountAggregateOutputType';
  writebackLogId: Scalars['Int'];
  customFieldDefinitionId: Scalars['Int'];
  customFieldValue: Scalars['Int'];
  customEntityId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CustomFieldPimsWritebackLogEntryCountOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  customFieldDefinitionId?: Maybe<SortOrder>;
  customFieldValue?: Maybe<SortOrder>;
  customEntityId?: Maybe<SortOrder>;
};

export type CustomFieldPimsWritebackLogEntryCreateInput = {
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutCustomFieldsLogInput;
  customFieldDefinition: CustomFieldDefinitionCreateNestedOneWithoutWritebackLogsInput;
};

export type CustomFieldPimsWritebackLogEntryCreateManyCustomFieldDefinitionInput = {
  writebackLogId: Scalars['String'];
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryCreateManyCustomFieldDefinitionInputEnvelope = {
  data: Array<CustomFieldPimsWritebackLogEntryCreateManyCustomFieldDefinitionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CustomFieldPimsWritebackLogEntryCreateManyInput = {
  writebackLogId: Scalars['String'];
  customFieldDefinitionId: Scalars['String'];
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryCreateNestedManyWithoutCustomFieldDefinitionInput = {
  create?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateWithoutCustomFieldDefinitionInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutCustomFieldDefinitionInput>>;
  createMany?: Maybe<CustomFieldPimsWritebackLogEntryCreateManyCustomFieldDefinitionInputEnvelope>;
  connect?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
};

export type CustomFieldPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput = {
  create?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  connect?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
};

export type CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutCustomFieldDefinitionInput = {
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
  create: CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutCustomFieldDefinitionInput;
};

export type CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput = {
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
  create: CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
};

export type CustomFieldPimsWritebackLogEntryCreateWithoutCustomFieldDefinitionInput = {
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutCustomFieldsLogInput;
};

export type CustomFieldPimsWritebackLogEntryCreateWithoutWritebackLogInput = {
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinition: CustomFieldDefinitionCreateNestedOneWithoutWritebackLogsInput;
};

export type CustomFieldPimsWritebackLogEntryListRelationFilter = {
  every?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
  some?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
  none?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
};

export type CustomFieldPimsWritebackLogEntryMaxAggregateOutputType = {
  __typename?: 'CustomFieldPimsWritebackLogEntryMaxAggregateOutputType';
  writebackLogId?: Maybe<Scalars['String']>;
  customFieldDefinitionId?: Maybe<Scalars['String']>;
  customEntityId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryMaxOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  customFieldDefinitionId?: Maybe<SortOrder>;
  customEntityId?: Maybe<SortOrder>;
};

export type CustomFieldPimsWritebackLogEntryMinAggregateOutputType = {
  __typename?: 'CustomFieldPimsWritebackLogEntryMinAggregateOutputType';
  writebackLogId?: Maybe<Scalars['String']>;
  customFieldDefinitionId?: Maybe<Scalars['String']>;
  customEntityId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryMinOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  customFieldDefinitionId?: Maybe<SortOrder>;
  customEntityId?: Maybe<SortOrder>;
};

export type CustomFieldPimsWritebackLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CustomFieldPimsWritebackLogEntryOrderByRelevanceFieldEnum {
  WritebackLogId = 'writebackLogId',
  CustomFieldDefinitionId = 'customFieldDefinitionId',
  CustomEntityId = 'customEntityId'
}

export type CustomFieldPimsWritebackLogEntryOrderByRelevanceInput = {
  fields: Array<CustomFieldPimsWritebackLogEntryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CustomFieldPimsWritebackLogEntryOrderByWithAggregationInput = {
  writebackLogId?: Maybe<SortOrder>;
  customFieldDefinitionId?: Maybe<SortOrder>;
  customFieldValue?: Maybe<SortOrder>;
  customEntityId?: Maybe<SortOrder>;
  _count?: Maybe<CustomFieldPimsWritebackLogEntryCountOrderByAggregateInput>;
  _max?: Maybe<CustomFieldPimsWritebackLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<CustomFieldPimsWritebackLogEntryMinOrderByAggregateInput>;
};

export type CustomFieldPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  writebackLogId?: Maybe<SortOrder>;
  customFieldDefinitionId?: Maybe<SortOrder>;
  customFieldValue?: Maybe<SortOrder>;
  customEntityId?: Maybe<SortOrder>;
  writebackLog?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  customFieldDefinition?: Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CustomFieldPimsWritebackLogEntryOrderByRelevanceInput>;
};

export type CustomFieldPimsWritebackLogEntryRelationFilter = {
  is?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
  isNot?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
};

export enum CustomFieldPimsWritebackLogEntryScalarFieldEnum {
  WritebackLogId = 'writebackLogId',
  CustomFieldDefinitionId = 'customFieldDefinitionId',
  CustomFieldValue = 'customFieldValue',
  CustomEntityId = 'customEntityId'
}

export type CustomFieldPimsWritebackLogEntryScalarWhereInput = {
  AND?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereInput>>;
  writebackLogId?: Maybe<StringFilter>;
  customFieldDefinitionId?: Maybe<StringFilter>;
  customFieldValue?: Maybe<JsonNullableFilter>;
  customEntityId?: Maybe<StringNullableFilter>;
};

export type CustomFieldPimsWritebackLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  writebackLogId?: Maybe<StringWithAggregatesFilter>;
  customFieldDefinitionId?: Maybe<StringWithAggregatesFilter>;
  customFieldValue?: Maybe<JsonNullableWithAggregatesFilter>;
  customEntityId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedCreateInput = {
  writebackLogId: Scalars['String'];
  customFieldDefinitionId: Scalars['String'];
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCustomFieldDefinitionInput = {
  create?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateWithoutCustomFieldDefinitionInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutCustomFieldDefinitionInput>>;
  createMany?: Maybe<CustomFieldPimsWritebackLogEntryCreateManyCustomFieldDefinitionInputEnvelope>;
  connect?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput = {
  create?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  connect?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutCustomFieldDefinitionInput = {
  writebackLogId: Scalars['String'];
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput = {
  customFieldDefinitionId: Scalars['String'];
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedUpdateInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  customFieldDefinitionId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedUpdateManyInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  customFieldDefinitionId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedUpdateManyWithoutCustomFieldDefinitionNestedInput = {
  create?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateWithoutCustomFieldDefinitionInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutCustomFieldDefinitionInput>>;
  upsert?: Maybe<Array<CustomFieldPimsWritebackLogEntryUpsertWithWhereUniqueWithoutCustomFieldDefinitionInput>>;
  createMany?: Maybe<CustomFieldPimsWritebackLogEntryCreateManyCustomFieldDefinitionInputEnvelope>;
  set?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<CustomFieldPimsWritebackLogEntryUpdateWithWhereUniqueWithoutCustomFieldDefinitionInput>>;
  updateMany?: Maybe<Array<CustomFieldPimsWritebackLogEntryUpdateManyWithWhereWithoutCustomFieldDefinitionInput>>;
  deleteMany?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereInput>>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogsInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput = {
  create?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  upsert?: Maybe<CustomFieldPimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedUpdateWithoutCustomFieldDefinitionInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput = {
  customFieldDefinitionId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryUpdateInput = {
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutCustomFieldsLogNestedInput>;
  customFieldDefinition?: Maybe<CustomFieldDefinitionUpdateOneRequiredWithoutWritebackLogsNestedInput>;
};

export type CustomFieldPimsWritebackLogEntryUpdateManyMutationInput = {
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryUpdateManyWithWhereWithoutCustomFieldDefinitionInput = {
  where: CustomFieldPimsWritebackLogEntryScalarWhereInput;
  data: CustomFieldPimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogsInput;
};

export type CustomFieldPimsWritebackLogEntryUpdateManyWithoutCustomFieldDefinitionNestedInput = {
  create?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateWithoutCustomFieldDefinitionInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutCustomFieldDefinitionInput>>;
  upsert?: Maybe<Array<CustomFieldPimsWritebackLogEntryUpsertWithWhereUniqueWithoutCustomFieldDefinitionInput>>;
  createMany?: Maybe<CustomFieldPimsWritebackLogEntryCreateManyCustomFieldDefinitionInputEnvelope>;
  set?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<CustomFieldPimsWritebackLogEntryUpdateWithWhereUniqueWithoutCustomFieldDefinitionInput>>;
  updateMany?: Maybe<Array<CustomFieldPimsWritebackLogEntryUpdateManyWithWhereWithoutCustomFieldDefinitionInput>>;
  deleteMany?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereInput>>;
};

export type CustomFieldPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput = {
  create?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  upsert?: Maybe<CustomFieldPimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
};

export type CustomFieldPimsWritebackLogEntryUpdateWithWhereUniqueWithoutCustomFieldDefinitionInput = {
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
  data: CustomFieldPimsWritebackLogEntryUncheckedUpdateWithoutCustomFieldDefinitionInput;
};

export type CustomFieldPimsWritebackLogEntryUpdateWithoutCustomFieldDefinitionInput = {
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutCustomFieldsLogNestedInput>;
};

export type CustomFieldPimsWritebackLogEntryUpdateWithoutWritebackLogInput = {
  customFieldValue?: Maybe<Scalars['Json']>;
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinition?: Maybe<CustomFieldDefinitionUpdateOneRequiredWithoutWritebackLogsNestedInput>;
};

export type CustomFieldPimsWritebackLogEntryUpsertWithWhereUniqueWithoutCustomFieldDefinitionInput = {
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
  update: CustomFieldPimsWritebackLogEntryUncheckedUpdateWithoutCustomFieldDefinitionInput;
  create: CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutCustomFieldDefinitionInput;
};

export type CustomFieldPimsWritebackLogEntryUpsertWithoutWritebackLogInput = {
  update: CustomFieldPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput;
  create: CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
};

export type CustomFieldPimsWritebackLogEntryWhereInput = {
  AND?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereInput>>;
  OR?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereInput>>;
  NOT?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereInput>>;
  writebackLogId?: Maybe<StringFilter>;
  customFieldDefinitionId?: Maybe<StringFilter>;
  customFieldValue?: Maybe<JsonNullableFilter>;
  customEntityId?: Maybe<StringNullableFilter>;
  writebackLog?: Maybe<PimsWritebackLogEntryWhereInput>;
  customFieldDefinition?: Maybe<CustomFieldDefinitionWhereInput>;
};

export type CustomFieldPimsWritebackLogEntryWhereUniqueInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  writebackLogId_customFieldDefinitionId?: Maybe<CustomFieldPimsWritebackLogEntryWritebackLogIdCustomFieldDefinitionIdCompoundUniqueInput>;
};

export type CustomFieldPimsWritebackLogEntryWritebackLogIdCustomFieldDefinitionIdCompoundUniqueInput = {
  writebackLogId: Scalars['String'];
  customFieldDefinitionId: Scalars['String'];
};

export enum CustomFieldTargetType {
  ClinicPet = 'ClinicPet',
  ClinicPetParent = 'ClinicPetParent',
  Unknown = 'Unknown'
}

export enum CustomFieldsDataSource {
  Pims = 'Pims',
  Flow = 'Flow'
}

export type CustomFindUniqueUserBoardFilterResponse = {
  __typename?: 'CustomFindUniqueUserBoardFilterResponse';
  currentView?: Maybe<BoardFilterView>;
  collapsedColumnsMap?: Maybe<Scalars['Json']>;
  selectedChannelStatusIdsMap?: Maybe<Scalars['Json']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  specificDateRange?: Maybe<DateRangeOutput>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
};

export type CustomFindUniqueUserBoardFilterWhereInput = {
  userId: Scalars['String'];
};

export type CustomUpsertOneUserBoardFilterDataInput = {
  currentView?: Maybe<BoardFilterView>;
  collapsedColumnsMap?: Maybe<Scalars['Json']>;
  selectedChannelStatusIdsMap?: Maybe<Scalars['Json']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  specificDateRange?: Maybe<DateRangeInput>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
};

export type CustomUpsertOneUserBoardFilterResponse = {
  __typename?: 'CustomUpsertOneUserBoardFilterResponse';
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
};

export type CustomUpsertOneUserBoardFilterWhereInput = {
  userId: Scalars['String'];
  clinicId: Scalars['String'];
};

export type DashboardSummary = {
  __typename?: 'DashboardSummary';
  Arn?: Maybe<Scalars['String']>;
  DashboardId?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CreatedTime?: Maybe<Scalars['String']>;
  LastUpdatedTime?: Maybe<Scalars['String']>;
  PublishedVersionNumber?: Maybe<Scalars['Int']>;
  LastPublishedTime?: Maybe<Scalars['String']>;
};

export type DataPointIntegration = {
  __typename?: 'DataPointIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
  password: Scalars['String'];
  siteId: Scalars['String'];
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation: Scalars['Boolean'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type DataPointIntegrationCountAggregateOutputType = {
  __typename?: 'DataPointIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  username: Scalars['Int'];
  password: Scalars['Int'];
  siteId: Scalars['Int'];
  fixAppointmentTime: Scalars['Int'];
  isMultiLocation: Scalars['Int'];
  _all: Scalars['Int'];
};

export type DataPointIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  fixAppointmentTime?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
};

export type DataPointIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  siteId: Scalars['String'];
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutDatapointInput>;
};

export type DataPointIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  siteId: Scalars['String'];
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<DataPointIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<DataPointIntegrationWhereUniqueInput>;
};

export type DataPointIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: DataPointIntegrationWhereUniqueInput;
  create: DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type DataPointIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  siteId: Scalars['String'];
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationMaxAggregateOutputType = {
  __typename?: 'DataPointIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  fixAppointmentTime?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
};

export type DataPointIntegrationMinAggregateOutputType = {
  __typename?: 'DataPointIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  fixAppointmentTime?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
};

export enum DataPointIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  Username = 'username',
  Password = 'password',
  SiteId = 'siteId'
}

export type DataPointIntegrationOrderByRelevanceInput = {
  fields: Array<DataPointIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type DataPointIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  fixAppointmentTime?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
  _count?: Maybe<DataPointIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<DataPointIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<DataPointIntegrationMinOrderByAggregateInput>;
};

export type DataPointIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  fixAppointmentTime?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<DataPointIntegrationOrderByRelevanceInput>;
};

export type DataPointIntegrationRelationFilter = {
  is?: Maybe<DataPointIntegrationWhereInput>;
  isNot?: Maybe<DataPointIntegrationWhereInput>;
};

export enum DataPointIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Username = 'username',
  Password = 'password',
  SiteId = 'siteId',
  FixAppointmentTime = 'fixAppointmentTime',
  IsMultiLocation = 'isMultiLocation'
}

export type DataPointIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DataPointIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DataPointIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DataPointIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  username?: Maybe<StringWithAggregatesFilter>;
  password?: Maybe<StringWithAggregatesFilter>;
  siteId?: Maybe<StringWithAggregatesFilter>;
  fixAppointmentTime?: Maybe<BoolNullableWithAggregatesFilter>;
  isMultiLocation?: Maybe<BoolWithAggregatesFilter>;
};

export type DataPointIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  siteId: Scalars['String'];
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutDatapointInput>;
};

export type DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  siteId: Scalars['String'];
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutDatapointNestedInput>;
};

export type DataPointIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutDatapointNestedInput>;
};

export type DataPointIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  create?: Maybe<DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<DataPointIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<DataPointIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DataPointIntegrationWhereUniqueInput>;
  update?: Maybe<DataPointIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type DataPointIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: DataPointIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type DataPointIntegrationWhereInput = {
  AND?: Maybe<Array<DataPointIntegrationWhereInput>>;
  OR?: Maybe<Array<DataPointIntegrationWhereInput>>;
  NOT?: Maybe<Array<DataPointIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  username?: Maybe<StringFilter>;
  password?: Maybe<StringFilter>;
  siteId?: Maybe<StringFilter>;
  fixAppointmentTime?: Maybe<BoolNullableFilter>;
  isMultiLocation?: Maybe<BoolFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type DataPointIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};


export type DateRangeInput = {
  gte: Scalars['DateTime'];
  lte: Scalars['DateTime'];
};

export type DateRangeOutput = {
  __typename?: 'DateRangeOutput';
  gte: Scalars['DateTime'];
  lte: Scalars['DateTime'];
};


export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type DateTimeNullableListFilter = {
  equals?: Maybe<Array<Scalars['DateTime']>>;
  has?: Maybe<Scalars['DateTime']>;
  hasEvery?: Maybe<Array<Scalars['DateTime']>>;
  hasSome?: Maybe<Array<Scalars['DateTime']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type DateTimeNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
};

export type DateTimeWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
};

export type DeleteEmailCampaignCustomListInput = {
  emailCampaignId: Scalars['String'];
};

export enum DeviceOs {
  Ios = 'IOS',
  Iphoneos = 'IPHONEOS',
  Ipados = 'IPADOS',
  Android = 'ANDROID'
}

export enum DeviceType {
  Handset = 'HANDSET',
  Tablet = 'TABLET',
  Tv = 'TV',
  Desktop = 'DESKTOP',
  Gamingconsole = 'GAMINGCONSOLE',
  Unknown = 'UNKNOWN'
}

export type DirectBookingAppointmentResponse = {
  __typename?: 'DirectBookingAppointmentResponse';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
};

export enum DirectBookingAppointmentTypeClientType {
  ExistingClients = 'ExistingClients',
  NewClients = 'NewClients',
  Both = 'Both'
}

export type DirectBookingAppointmentTypeInput = {
  clinicId: Scalars['String'];
};

export type DirectBookingAppointmentTypeName = {
  name: Scalars['String'];
};

export type DirectBookingAppointmentTypeSetting = {
  __typename?: 'DirectBookingAppointmentTypeSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  appointmentTypeId: Scalars['String'];
  clientType: DirectBookingAppointmentTypeClientType;
  isPaymentCollectionEnabled: Scalars['Boolean'];
  paymentAmountToCollect: Scalars['Int'];
  allowNewClientBooking: Scalars['Boolean'];
  appointmentType: AppointmentType;
};

export type DirectBookingAppointmentTypeSettingAvgAggregateOutputType = {
  __typename?: 'DirectBookingAppointmentTypeSettingAvgAggregateOutputType';
  paymentAmountToCollect?: Maybe<Scalars['Float']>;
};

export type DirectBookingAppointmentTypeSettingAvgOrderByAggregateInput = {
  paymentAmountToCollect?: Maybe<SortOrder>;
};

export type DirectBookingAppointmentTypeSettingCountAggregateOutputType = {
  __typename?: 'DirectBookingAppointmentTypeSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  clientType: Scalars['Int'];
  isPaymentCollectionEnabled: Scalars['Int'];
  paymentAmountToCollect: Scalars['Int'];
  allowNewClientBooking: Scalars['Int'];
  _all: Scalars['Int'];
};

export type DirectBookingAppointmentTypeSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clientType?: Maybe<SortOrder>;
  isPaymentCollectionEnabled?: Maybe<SortOrder>;
  paymentAmountToCollect?: Maybe<SortOrder>;
  allowNewClientBooking?: Maybe<SortOrder>;
};

export type DirectBookingAppointmentTypeSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  appointmentType: AppointmentTypeCreateNestedOneWithoutDirectBookingAppointmentTypeSettingInput;
};

export type DirectBookingAppointmentTypeSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput = {
  create?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput>;
  connectOrCreate?: Maybe<DirectBookingAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>;
  connect?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
};

export type DirectBookingAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput = {
  where: DirectBookingAppointmentTypeSettingWhereUniqueInput;
  create: DirectBookingAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput;
};

export type DirectBookingAppointmentTypeSettingCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingAppointmentTypeSettingMaxAggregateOutputType = {
  __typename?: 'DirectBookingAppointmentTypeSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingAppointmentTypeSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clientType?: Maybe<SortOrder>;
  isPaymentCollectionEnabled?: Maybe<SortOrder>;
  paymentAmountToCollect?: Maybe<SortOrder>;
  allowNewClientBooking?: Maybe<SortOrder>;
};

export type DirectBookingAppointmentTypeSettingMinAggregateOutputType = {
  __typename?: 'DirectBookingAppointmentTypeSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingAppointmentTypeSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clientType?: Maybe<SortOrder>;
  isPaymentCollectionEnabled?: Maybe<SortOrder>;
  paymentAmountToCollect?: Maybe<SortOrder>;
  allowNewClientBooking?: Maybe<SortOrder>;
};

export enum DirectBookingAppointmentTypeSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  AppointmentTypeId = 'appointmentTypeId'
}

export type DirectBookingAppointmentTypeSettingOrderByRelevanceInput = {
  fields: Array<DirectBookingAppointmentTypeSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type DirectBookingAppointmentTypeSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clientType?: Maybe<SortOrder>;
  isPaymentCollectionEnabled?: Maybe<SortOrder>;
  paymentAmountToCollect?: Maybe<SortOrder>;
  allowNewClientBooking?: Maybe<SortOrder>;
  _count?: Maybe<DirectBookingAppointmentTypeSettingCountOrderByAggregateInput>;
  _avg?: Maybe<DirectBookingAppointmentTypeSettingAvgOrderByAggregateInput>;
  _max?: Maybe<DirectBookingAppointmentTypeSettingMaxOrderByAggregateInput>;
  _min?: Maybe<DirectBookingAppointmentTypeSettingMinOrderByAggregateInput>;
  _sum?: Maybe<DirectBookingAppointmentTypeSettingSumOrderByAggregateInput>;
};

export type DirectBookingAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clientType?: Maybe<SortOrder>;
  isPaymentCollectionEnabled?: Maybe<SortOrder>;
  paymentAmountToCollect?: Maybe<SortOrder>;
  allowNewClientBooking?: Maybe<SortOrder>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<DirectBookingAppointmentTypeSettingOrderByRelevanceInput>;
};

export type DirectBookingAppointmentTypeSettingRelationFilter = {
  is?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
  isNot?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
};

export enum DirectBookingAppointmentTypeSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  AppointmentTypeId = 'appointmentTypeId',
  ClientType = 'clientType',
  IsPaymentCollectionEnabled = 'isPaymentCollectionEnabled',
  PaymentAmountToCollect = 'paymentAmountToCollect',
  AllowNewClientBooking = 'allowNewClientBooking'
}

export type DirectBookingAppointmentTypeSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DirectBookingAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DirectBookingAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DirectBookingAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  appointmentTypeId?: Maybe<StringWithAggregatesFilter>;
  clientType?: Maybe<EnumDirectBookingAppointmentTypeClientTypeWithAggregatesFilter>;
  isPaymentCollectionEnabled?: Maybe<BoolWithAggregatesFilter>;
  paymentAmountToCollect?: Maybe<IntWithAggregatesFilter>;
  allowNewClientBooking?: Maybe<BoolWithAggregatesFilter>;
};

export type DirectBookingAppointmentTypeSettingSelection = {
  __typename?: 'DirectBookingAppointmentTypeSettingSelection';
  id?: Maybe<Scalars['String']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
};

export type DirectBookingAppointmentTypeSettingSumAggregateOutputType = {
  __typename?: 'DirectBookingAppointmentTypeSettingSumAggregateOutputType';
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
};

export type DirectBookingAppointmentTypeSettingSumOrderByAggregateInput = {
  paymentAmountToCollect?: Maybe<SortOrder>;
};

export type DirectBookingAppointmentTypeSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput = {
  create?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput>;
  connectOrCreate?: Maybe<DirectBookingAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>;
  connect?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
};

export type DirectBookingAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingAppointmentTypeSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingAppointmentTypeSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput = {
  create?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput>;
  connectOrCreate?: Maybe<DirectBookingAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>;
  upsert?: Maybe<DirectBookingAppointmentTypeSettingUpsertWithoutAppointmentTypeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
  update?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput>;
};

export type DirectBookingAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingAppointmentTypeSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutDirectBookingAppointmentTypeSettingNestedInput>;
};

export type DirectBookingAppointmentTypeSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput = {
  create?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput>;
  connectOrCreate?: Maybe<DirectBookingAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>;
  upsert?: Maybe<DirectBookingAppointmentTypeSettingUpsertWithoutAppointmentTypeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
  update?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput>;
};

export type DirectBookingAppointmentTypeSettingUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingAppointmentTypeSettingUpsertWithoutAppointmentTypeInput = {
  update: DirectBookingAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput;
  create: DirectBookingAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput;
};

export type DirectBookingAppointmentTypeSettingWhereInput = {
  AND?: Maybe<Array<DirectBookingAppointmentTypeSettingWhereInput>>;
  OR?: Maybe<Array<DirectBookingAppointmentTypeSettingWhereInput>>;
  NOT?: Maybe<Array<DirectBookingAppointmentTypeSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  appointmentTypeId?: Maybe<StringFilter>;
  clientType?: Maybe<EnumDirectBookingAppointmentTypeClientTypeFilter>;
  isPaymentCollectionEnabled?: Maybe<BoolFilter>;
  paymentAmountToCollect?: Maybe<IntFilter>;
  allowNewClientBooking?: Maybe<BoolFilter>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
};

export type DirectBookingAppointmentTypeSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type DirectBookingAppointmentTypes = {
  __typename?: 'DirectBookingAppointmentTypes';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingSelection>;
  clinicEmployeeAppointmentTypeSettings: Array<AppointmentTypeClinicEmployeeMapping>;
};

export type DirectBookingAvailability = {
  __typename?: 'DirectBookingAvailability';
  bookingDate?: Maybe<Scalars['DateTime']>;
  hasAvailability?: Maybe<Scalars['Boolean']>;
  openTimeSlots?: Maybe<Scalars['Json']>;
};

export type DirectBookingClinicEmployeeName = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type DirectBookingClinicRoomName = {
  name: Scalars['String'];
};

export type DirectBookingCreateOneProviderAppointmentTypeRoomMapByNameInput = {
  clinicId: Scalars['String'];
  clinicEmployee: DirectBookingClinicEmployeeName;
  clinicRoom: DirectBookingClinicRoomName;
  appointmentType: DirectBookingAppointmentTypeName;
};

export type DirectBookingCreateOneProviderAppointmentTypeRoomMapByNameResponse = {
  __typename?: 'DirectBookingCreateOneProviderAppointmentTypeRoomMapByNameResponse';
  success: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type DirectBookingDateAvailability = {
  __typename?: 'DirectBookingDateAvailability';
  bookingDate: Scalars['String'];
  hasAvailability: Scalars['Boolean'];
  openTimeSlots: Array<Scalars['DateTime']>;
};

export type DirectBookingExclusionRule = {
  __typename?: 'DirectBookingExclusionRule';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  clinicId: Scalars['String'];
  isActive: Scalars['Boolean'];
  appointmentTypes: Array<AppointmentType>;
  providers: Array<ClinicEmployee>;
  clinic: Clinic;
  timeSlots: Array<DirectBookingExclusionTimeSlot>;
  _count: DirectBookingExclusionRuleCountOutputType;
};


export type DirectBookingExclusionRuleAppointmentTypesArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentTypeScalarFieldEnum>;
};


export type DirectBookingExclusionRuleProvidersArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeScalarFieldEnum>;
};


export type DirectBookingExclusionRuleTimeSlotsArgs = {
  where?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
  orderBy?: Maybe<DirectBookingExclusionTimeSlotOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<DirectBookingExclusionTimeSlotWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<DirectBookingExclusionTimeSlotScalarFieldEnum>;
};

export type DirectBookingExclusionRuleCountAggregateOutputType = {
  __typename?: 'DirectBookingExclusionRuleCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  name: Scalars['Int'];
  clinicId: Scalars['Int'];
  isActive: Scalars['Int'];
  _all: Scalars['Int'];
};

export type DirectBookingExclusionRuleCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
};

export type DirectBookingExclusionRuleCountOutputType = {
  __typename?: 'DirectBookingExclusionRuleCountOutputType';
  appointmentTypes: Scalars['Int'];
  providers: Scalars['Int'];
  timeSlots: Scalars['Int'];
};

export type DirectBookingExclusionRuleCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  providers?: Maybe<ClinicEmployeeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  clinic: ClinicCreateNestedOneWithoutDirectBookingExclusionRulesInput;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotCreateNestedManyWithoutExclusionRuleInput>;
};

export type DirectBookingExclusionRuleCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingExclusionRuleCreateManyClinicInputEnvelope = {
  data: Array<DirectBookingExclusionRuleCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingExclusionRuleCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  clinicId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput = {
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutAppointmentTypesInput>>;
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
};

export type DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<DirectBookingExclusionRuleCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
};

export type DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput = {
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutProvidersInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutProvidersInput>>;
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
};

export type DirectBookingExclusionRuleCreateNestedOneWithoutTimeSlotsInput = {
  create?: Maybe<DirectBookingExclusionRuleUncheckedCreateWithoutTimeSlotsInput>;
  connectOrCreate?: Maybe<DirectBookingExclusionRuleCreateOrConnectWithoutTimeSlotsInput>;
  connect?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
};

export type DirectBookingExclusionRuleCreateOrConnectWithoutAppointmentTypesInput = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
  create: DirectBookingExclusionRuleUncheckedCreateWithoutAppointmentTypesInput;
};

export type DirectBookingExclusionRuleCreateOrConnectWithoutClinicInput = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
  create: DirectBookingExclusionRuleUncheckedCreateWithoutClinicInput;
};

export type DirectBookingExclusionRuleCreateOrConnectWithoutProvidersInput = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
  create: DirectBookingExclusionRuleUncheckedCreateWithoutProvidersInput;
};

export type DirectBookingExclusionRuleCreateOrConnectWithoutTimeSlotsInput = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
  create: DirectBookingExclusionRuleUncheckedCreateWithoutTimeSlotsInput;
};

export type DirectBookingExclusionRuleCreateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  providers?: Maybe<ClinicEmployeeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  clinic: ClinicCreateNestedOneWithoutDirectBookingExclusionRulesInput;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotCreateNestedManyWithoutExclusionRuleInput>;
};

export type DirectBookingExclusionRuleCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  providers?: Maybe<ClinicEmployeeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotCreateNestedManyWithoutExclusionRuleInput>;
};

export type DirectBookingExclusionRuleCreateWithoutProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  clinic: ClinicCreateNestedOneWithoutDirectBookingExclusionRulesInput;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotCreateNestedManyWithoutExclusionRuleInput>;
};

export type DirectBookingExclusionRuleCreateWithoutTimeSlotsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  providers?: Maybe<ClinicEmployeeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  clinic: ClinicCreateNestedOneWithoutDirectBookingExclusionRulesInput;
};

export type DirectBookingExclusionRuleListRelationFilter = {
  every?: Maybe<DirectBookingExclusionRuleWhereInput>;
  some?: Maybe<DirectBookingExclusionRuleWhereInput>;
  none?: Maybe<DirectBookingExclusionRuleWhereInput>;
};

export type DirectBookingExclusionRuleMaxAggregateOutputType = {
  __typename?: 'DirectBookingExclusionRuleMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingExclusionRuleMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
};

export type DirectBookingExclusionRuleMinAggregateOutputType = {
  __typename?: 'DirectBookingExclusionRuleMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingExclusionRuleMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
};

export type DirectBookingExclusionRuleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum DirectBookingExclusionRuleOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  ClinicId = 'clinicId'
}

export type DirectBookingExclusionRuleOrderByRelevanceInput = {
  fields: Array<DirectBookingExclusionRuleOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type DirectBookingExclusionRuleOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  _count?: Maybe<DirectBookingExclusionRuleCountOrderByAggregateInput>;
  _max?: Maybe<DirectBookingExclusionRuleMaxOrderByAggregateInput>;
  _min?: Maybe<DirectBookingExclusionRuleMinOrderByAggregateInput>;
};

export type DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  appointmentTypes?: Maybe<AppointmentTypeOrderByRelationAggregateInput>;
  providers?: Maybe<ClinicEmployeeOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotOrderByRelationAggregateInput>;
  _relevance?: Maybe<DirectBookingExclusionRuleOrderByRelevanceInput>;
};

export type DirectBookingExclusionRuleRelationFilter = {
  is?: Maybe<DirectBookingExclusionRuleWhereInput>;
  isNot?: Maybe<DirectBookingExclusionRuleWhereInput>;
};

export enum DirectBookingExclusionRuleScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Name = 'name',
  ClinicId = 'clinicId',
  IsActive = 'isActive'
}

export type DirectBookingExclusionRuleScalarWhereInput = {
  AND?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
  OR?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
  NOT?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
};

export type DirectBookingExclusionRuleScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DirectBookingExclusionRuleScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DirectBookingExclusionRuleScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DirectBookingExclusionRuleScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
};

export type DirectBookingExclusionRuleUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  clinicId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  providers?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedCreateNestedManyWithoutExclusionRuleInput>;
};

export type DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput = {
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutAppointmentTypesInput>>;
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
};

export type DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<DirectBookingExclusionRuleCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
};

export type DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput = {
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutProvidersInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutProvidersInput>>;
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
};

export type DirectBookingExclusionRuleUncheckedCreateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  clinicId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  providers?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedCreateNestedManyWithoutExclusionRuleInput>;
};

export type DirectBookingExclusionRuleUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  providers?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedCreateNestedManyWithoutExclusionRuleInput>;
};

export type DirectBookingExclusionRuleUncheckedCreateWithoutProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  clinicId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedCreateNestedManyWithoutExclusionRuleInput>;
};

export type DirectBookingExclusionRuleUncheckedCreateWithoutTimeSlotsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  clinicId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  providers?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
};

export type DirectBookingExclusionRuleUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  providers?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedUpdateManyWithoutExclusionRuleNestedInput>;
};

export type DirectBookingExclusionRuleUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput = {
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
  set?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  delete?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionRuleUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
};

export type DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<DirectBookingExclusionRuleCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  delete?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionRuleUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
};

export type DirectBookingExclusionRuleUncheckedUpdateManyWithoutDirectBookingExclusionRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput = {
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutProvidersInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutProvidersInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutProvidersInput>>;
  set?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  delete?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutProvidersInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionRuleUpdateManyWithWhereWithoutProvidersInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
};

export type DirectBookingExclusionRuleUncheckedUpdateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  providers?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedUpdateManyWithoutExclusionRuleNestedInput>;
};

export type DirectBookingExclusionRuleUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  providers?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedUpdateManyWithoutExclusionRuleNestedInput>;
};

export type DirectBookingExclusionRuleUncheckedUpdateWithoutProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedUpdateManyWithoutExclusionRuleNestedInput>;
};

export type DirectBookingExclusionRuleUncheckedUpdateWithoutTimeSlotsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  providers?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
};

export type DirectBookingExclusionRuleUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  providers?: Maybe<ClinicEmployeeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutDirectBookingExclusionRulesNestedInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUpdateManyWithoutExclusionRuleNestedInput>;
};

export type DirectBookingExclusionRuleUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingExclusionRuleUpdateManyWithWhereWithoutAppointmentTypesInput = {
  where: DirectBookingExclusionRuleScalarWhereInput;
  data: DirectBookingExclusionRuleUncheckedUpdateManyWithoutDirectBookingExclusionRulesInput;
};

export type DirectBookingExclusionRuleUpdateManyWithWhereWithoutClinicInput = {
  where: DirectBookingExclusionRuleScalarWhereInput;
  data: DirectBookingExclusionRuleUncheckedUpdateManyWithoutDirectBookingExclusionRulesInput;
};

export type DirectBookingExclusionRuleUpdateManyWithWhereWithoutProvidersInput = {
  where: DirectBookingExclusionRuleScalarWhereInput;
  data: DirectBookingExclusionRuleUncheckedUpdateManyWithoutDirectBookingExclusionRulesInput;
};

export type DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput = {
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
  set?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  delete?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionRuleUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
};

export type DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<DirectBookingExclusionRuleCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  delete?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionRuleUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
};

export type DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput = {
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutProvidersInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutProvidersInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutProvidersInput>>;
  set?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  delete?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutProvidersInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionRuleUpdateManyWithWhereWithoutProvidersInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
};

export type DirectBookingExclusionRuleUpdateOneRequiredWithoutTimeSlotsNestedInput = {
  create?: Maybe<DirectBookingExclusionRuleUncheckedCreateWithoutTimeSlotsInput>;
  connectOrCreate?: Maybe<DirectBookingExclusionRuleCreateOrConnectWithoutTimeSlotsInput>;
  upsert?: Maybe<DirectBookingExclusionRuleUpsertWithoutTimeSlotsInput>;
  connect?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  update?: Maybe<DirectBookingExclusionRuleUncheckedUpdateWithoutTimeSlotsInput>;
};

export type DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutAppointmentTypesInput = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
  data: DirectBookingExclusionRuleUncheckedUpdateWithoutAppointmentTypesInput;
};

export type DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutClinicInput = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
  data: DirectBookingExclusionRuleUncheckedUpdateWithoutClinicInput;
};

export type DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutProvidersInput = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
  data: DirectBookingExclusionRuleUncheckedUpdateWithoutProvidersInput;
};

export type DirectBookingExclusionRuleUpdateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  providers?: Maybe<ClinicEmployeeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutDirectBookingExclusionRulesNestedInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUpdateManyWithoutExclusionRuleNestedInput>;
};

export type DirectBookingExclusionRuleUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  providers?: Maybe<ClinicEmployeeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUpdateManyWithoutExclusionRuleNestedInput>;
};

export type DirectBookingExclusionRuleUpdateWithoutProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutDirectBookingExclusionRulesNestedInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUpdateManyWithoutExclusionRuleNestedInput>;
};

export type DirectBookingExclusionRuleUpdateWithoutTimeSlotsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  providers?: Maybe<ClinicEmployeeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutDirectBookingExclusionRulesNestedInput>;
};

export type DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutAppointmentTypesInput = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
  update: DirectBookingExclusionRuleUncheckedUpdateWithoutAppointmentTypesInput;
  create: DirectBookingExclusionRuleUncheckedCreateWithoutAppointmentTypesInput;
};

export type DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutClinicInput = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
  update: DirectBookingExclusionRuleUncheckedUpdateWithoutClinicInput;
  create: DirectBookingExclusionRuleUncheckedCreateWithoutClinicInput;
};

export type DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutProvidersInput = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
  update: DirectBookingExclusionRuleUncheckedUpdateWithoutProvidersInput;
  create: DirectBookingExclusionRuleUncheckedCreateWithoutProvidersInput;
};

export type DirectBookingExclusionRuleUpsertWithoutTimeSlotsInput = {
  update: DirectBookingExclusionRuleUncheckedUpdateWithoutTimeSlotsInput;
  create: DirectBookingExclusionRuleUncheckedCreateWithoutTimeSlotsInput;
};

export type DirectBookingExclusionRuleWhereInput = {
  AND?: Maybe<Array<DirectBookingExclusionRuleWhereInput>>;
  OR?: Maybe<Array<DirectBookingExclusionRuleWhereInput>>;
  NOT?: Maybe<Array<DirectBookingExclusionRuleWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  appointmentTypes?: Maybe<AppointmentTypeListRelationFilter>;
  providers?: Maybe<ClinicEmployeeListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotListRelationFilter>;
};

export type DirectBookingExclusionRuleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DirectBookingExclusionTimeSlot = {
  __typename?: 'DirectBookingExclusionTimeSlot';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  exclusionRuleId: Scalars['String'];
  dayOfWeek: Scalars['Int'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  exclusionRule: DirectBookingExclusionRule;
};

export type DirectBookingExclusionTimeSlotAvgAggregateOutputType = {
  __typename?: 'DirectBookingExclusionTimeSlotAvgAggregateOutputType';
  dayOfWeek?: Maybe<Scalars['Float']>;
};

export type DirectBookingExclusionTimeSlotAvgOrderByAggregateInput = {
  dayOfWeek?: Maybe<SortOrder>;
};

export type DirectBookingExclusionTimeSlotCountAggregateOutputType = {
  __typename?: 'DirectBookingExclusionTimeSlotCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  exclusionRuleId: Scalars['Int'];
  dayOfWeek: Scalars['Int'];
  startTime: Scalars['Int'];
  endTime: Scalars['Int'];
  _all: Scalars['Int'];
};

export type DirectBookingExclusionTimeSlotCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  exclusionRuleId?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  endTime?: Maybe<SortOrder>;
};

export type DirectBookingExclusionTimeSlotCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  exclusionRule: DirectBookingExclusionRuleCreateNestedOneWithoutTimeSlotsInput;
};

export type DirectBookingExclusionTimeSlotCreateManyExclusionRuleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
};

export type DirectBookingExclusionTimeSlotCreateManyExclusionRuleInputEnvelope = {
  data: Array<DirectBookingExclusionTimeSlotCreateManyExclusionRuleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingExclusionTimeSlotCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  exclusionRuleId: Scalars['String'];
  dayOfWeek: Scalars['Int'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
};

export type DirectBookingExclusionTimeSlotCreateNestedManyWithoutExclusionRuleInput = {
  create?: Maybe<Array<DirectBookingExclusionTimeSlotCreateWithoutExclusionRuleInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionTimeSlotCreateOrConnectWithoutExclusionRuleInput>>;
  createMany?: Maybe<DirectBookingExclusionTimeSlotCreateManyExclusionRuleInputEnvelope>;
  connect?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
};

export type DirectBookingExclusionTimeSlotCreateOrConnectWithoutExclusionRuleInput = {
  where: DirectBookingExclusionTimeSlotWhereUniqueInput;
  create: DirectBookingExclusionTimeSlotUncheckedCreateWithoutExclusionRuleInput;
};

export type DirectBookingExclusionTimeSlotCreateWithoutExclusionRuleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
};

export type DirectBookingExclusionTimeSlotListRelationFilter = {
  every?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
  some?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
  none?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
};

export type DirectBookingExclusionTimeSlotMaxAggregateOutputType = {
  __typename?: 'DirectBookingExclusionTimeSlotMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  exclusionRuleId?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
};

export type DirectBookingExclusionTimeSlotMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  exclusionRuleId?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  endTime?: Maybe<SortOrder>;
};

export type DirectBookingExclusionTimeSlotMinAggregateOutputType = {
  __typename?: 'DirectBookingExclusionTimeSlotMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  exclusionRuleId?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
};

export type DirectBookingExclusionTimeSlotMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  exclusionRuleId?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  endTime?: Maybe<SortOrder>;
};

export type DirectBookingExclusionTimeSlotOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum DirectBookingExclusionTimeSlotOrderByRelevanceFieldEnum {
  Id = 'id',
  ExclusionRuleId = 'exclusionRuleId',
  StartTime = 'startTime',
  EndTime = 'endTime'
}

export type DirectBookingExclusionTimeSlotOrderByRelevanceInput = {
  fields: Array<DirectBookingExclusionTimeSlotOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type DirectBookingExclusionTimeSlotOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  exclusionRuleId?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  endTime?: Maybe<SortOrder>;
  _count?: Maybe<DirectBookingExclusionTimeSlotCountOrderByAggregateInput>;
  _avg?: Maybe<DirectBookingExclusionTimeSlotAvgOrderByAggregateInput>;
  _max?: Maybe<DirectBookingExclusionTimeSlotMaxOrderByAggregateInput>;
  _min?: Maybe<DirectBookingExclusionTimeSlotMinOrderByAggregateInput>;
  _sum?: Maybe<DirectBookingExclusionTimeSlotSumOrderByAggregateInput>;
};

export type DirectBookingExclusionTimeSlotOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  exclusionRuleId?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  endTime?: Maybe<SortOrder>;
  exclusionRule?: Maybe<DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<DirectBookingExclusionTimeSlotOrderByRelevanceInput>;
};

export enum DirectBookingExclusionTimeSlotScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ExclusionRuleId = 'exclusionRuleId',
  DayOfWeek = 'dayOfWeek',
  StartTime = 'startTime',
  EndTime = 'endTime'
}

export type DirectBookingExclusionTimeSlotScalarWhereInput = {
  AND?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereInput>>;
  OR?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereInput>>;
  NOT?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  exclusionRuleId?: Maybe<StringFilter>;
  dayOfWeek?: Maybe<IntFilter>;
  startTime?: Maybe<StringFilter>;
  endTime?: Maybe<StringFilter>;
};

export type DirectBookingExclusionTimeSlotScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  exclusionRuleId?: Maybe<StringWithAggregatesFilter>;
  dayOfWeek?: Maybe<IntWithAggregatesFilter>;
  startTime?: Maybe<StringWithAggregatesFilter>;
  endTime?: Maybe<StringWithAggregatesFilter>;
};

export type DirectBookingExclusionTimeSlotSumAggregateOutputType = {
  __typename?: 'DirectBookingExclusionTimeSlotSumAggregateOutputType';
  dayOfWeek?: Maybe<Scalars['Int']>;
};

export type DirectBookingExclusionTimeSlotSumOrderByAggregateInput = {
  dayOfWeek?: Maybe<SortOrder>;
};

export type DirectBookingExclusionTimeSlotUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  exclusionRuleId: Scalars['String'];
  dayOfWeek: Scalars['Int'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
};

export type DirectBookingExclusionTimeSlotUncheckedCreateNestedManyWithoutExclusionRuleInput = {
  create?: Maybe<Array<DirectBookingExclusionTimeSlotCreateWithoutExclusionRuleInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionTimeSlotCreateOrConnectWithoutExclusionRuleInput>>;
  createMany?: Maybe<DirectBookingExclusionTimeSlotCreateManyExclusionRuleInputEnvelope>;
  connect?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
};

export type DirectBookingExclusionTimeSlotUncheckedCreateWithoutExclusionRuleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
};

export type DirectBookingExclusionTimeSlotUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  exclusionRuleId?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
};

export type DirectBookingExclusionTimeSlotUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  exclusionRuleId?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
};

export type DirectBookingExclusionTimeSlotUncheckedUpdateManyWithoutExclusionRuleNestedInput = {
  create?: Maybe<Array<DirectBookingExclusionTimeSlotCreateWithoutExclusionRuleInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionTimeSlotCreateOrConnectWithoutExclusionRuleInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionTimeSlotUpsertWithWhereUniqueWithoutExclusionRuleInput>>;
  createMany?: Maybe<DirectBookingExclusionTimeSlotCreateManyExclusionRuleInputEnvelope>;
  set?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  delete?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  connect?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionTimeSlotUpdateWithWhereUniqueWithoutExclusionRuleInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionTimeSlotUpdateManyWithWhereWithoutExclusionRuleInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereInput>>;
};

export type DirectBookingExclusionTimeSlotUncheckedUpdateManyWithoutTimeSlotsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
};

export type DirectBookingExclusionTimeSlotUncheckedUpdateWithoutExclusionRuleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
};

export type DirectBookingExclusionTimeSlotUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  exclusionRule?: Maybe<DirectBookingExclusionRuleUpdateOneRequiredWithoutTimeSlotsNestedInput>;
};

export type DirectBookingExclusionTimeSlotUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
};

export type DirectBookingExclusionTimeSlotUpdateManyWithWhereWithoutExclusionRuleInput = {
  where: DirectBookingExclusionTimeSlotScalarWhereInput;
  data: DirectBookingExclusionTimeSlotUncheckedUpdateManyWithoutTimeSlotsInput;
};

export type DirectBookingExclusionTimeSlotUpdateManyWithoutExclusionRuleNestedInput = {
  create?: Maybe<Array<DirectBookingExclusionTimeSlotCreateWithoutExclusionRuleInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionTimeSlotCreateOrConnectWithoutExclusionRuleInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionTimeSlotUpsertWithWhereUniqueWithoutExclusionRuleInput>>;
  createMany?: Maybe<DirectBookingExclusionTimeSlotCreateManyExclusionRuleInputEnvelope>;
  set?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  delete?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  connect?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionTimeSlotUpdateWithWhereUniqueWithoutExclusionRuleInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionTimeSlotUpdateManyWithWhereWithoutExclusionRuleInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereInput>>;
};

export type DirectBookingExclusionTimeSlotUpdateWithWhereUniqueWithoutExclusionRuleInput = {
  where: DirectBookingExclusionTimeSlotWhereUniqueInput;
  data: DirectBookingExclusionTimeSlotUncheckedUpdateWithoutExclusionRuleInput;
};

export type DirectBookingExclusionTimeSlotUpdateWithoutExclusionRuleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
};

export type DirectBookingExclusionTimeSlotUpsertWithWhereUniqueWithoutExclusionRuleInput = {
  where: DirectBookingExclusionTimeSlotWhereUniqueInput;
  update: DirectBookingExclusionTimeSlotUncheckedUpdateWithoutExclusionRuleInput;
  create: DirectBookingExclusionTimeSlotUncheckedCreateWithoutExclusionRuleInput;
};

export type DirectBookingExclusionTimeSlotWhereInput = {
  AND?: Maybe<Array<DirectBookingExclusionTimeSlotWhereInput>>;
  OR?: Maybe<Array<DirectBookingExclusionTimeSlotWhereInput>>;
  NOT?: Maybe<Array<DirectBookingExclusionTimeSlotWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  exclusionRuleId?: Maybe<StringFilter>;
  dayOfWeek?: Maybe<IntFilter>;
  startTime?: Maybe<StringFilter>;
  endTime?: Maybe<StringFilter>;
  exclusionRule?: Maybe<DirectBookingExclusionRuleWhereInput>;
};

export type DirectBookingExclusionTimeSlotWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DirectBookingOpenTimeSlot2Availability = {
  __typename?: 'DirectBookingOpenTimeSlot2Availability';
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicEmployeeFirstName?: Maybe<Scalars['String']>;
  clinicEmployeeLastName?: Maybe<Scalars['String']>;
  availability: Array<DirectBookingDateAvailability>;
};

export type DirectBookingOpenTimeSlot2Input = {
  clinicId: Scalars['String'];
  clinicEmployeeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  dates: Array<Maybe<DirectBookingOpenTimeSlotRange>>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  soonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  showFirstAvailabilityDayOnly?: Maybe<Scalars['Boolean']>;
  petParentTimezone?: Maybe<Scalars['String']>;
};

export type DirectBookingOpenTimeSlot2Response = {
  __typename?: 'DirectBookingOpenTimeSlot2Response';
  availableTimeSlots?: Maybe<Array<DirectBookingOpenTimeSlot2Availability>>;
  usedTimezone?: Maybe<Scalars['String']>;
};

export type DirectBookingOpenTimeSlotRange = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export enum DirectBookingSpeciesOption {
  Used = 'Used',
  NotUsed = 'NotUsed'
}

export type DirectedAuthLinkInput = {
  clinicPetParentId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  organizationPlanId?: Maybe<Scalars['String']>;
  loginType?: Maybe<Scalars['String']>;
};

export type DirectedAuthLinkResult = {
  __typename?: 'DirectedAuthLinkResult';
  success: Scalars['Boolean'];
};

/** @deprecated - 1.0 */
export type Disbursement = {
  __typename?: 'Disbursement';
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  amount: Scalars['Float'];
  disbursementId: Scalars['String'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source: Scalars['String'];
  transactions: Array<Transaction>;
  _count: DisbursementCountOutputType;
};


/** @deprecated - 1.0 */
export type DisbursementTransactionsArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<TransactionScalarFieldEnum>;
};

export type DisbursementAvgAggregateOutputType = {
  __typename?: 'DisbursementAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type DisbursementAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type DisbursementCountAggregateOutputType = {
  __typename?: 'DisbursementCountAggregateOutputType';
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
  amount: Scalars['Int'];
  disbursementId: Scalars['Int'];
  disbursementDate: Scalars['Int'];
  source: Scalars['Int'];
  _all: Scalars['Int'];
};

export type DisbursementCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type DisbursementCountOutputType = {
  __typename?: 'DisbursementCountOutputType';
  transactions: Scalars['Int'];
};

export type DisbursementCreateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  disbursementId: Scalars['String'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutDisbursementInput>;
};

export type DisbursementCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  disbursementId: Scalars['String'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementCreateNestedOneWithoutTransactionsInput = {
  create?: Maybe<DisbursementUncheckedCreateWithoutTransactionsInput>;
  connectOrCreate?: Maybe<DisbursementCreateOrConnectWithoutTransactionsInput>;
  connect?: Maybe<DisbursementWhereUniqueInput>;
};

export type DisbursementCreateOrConnectWithoutTransactionsInput = {
  where: DisbursementWhereUniqueInput;
  create: DisbursementUncheckedCreateWithoutTransactionsInput;
};

export type DisbursementCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  disbursementId: Scalars['String'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementMaxAggregateOutputType = {
  __typename?: 'DisbursementMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type DisbursementMinAggregateOutputType = {
  __typename?: 'DisbursementMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export enum DisbursementOrderByRelevanceFieldEnum {
  Id = 'id',
  DisbursementId = 'disbursementId',
  Source = 'source'
}

export type DisbursementOrderByRelevanceInput = {
  fields: Array<DisbursementOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type DisbursementOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  _count?: Maybe<DisbursementCountOrderByAggregateInput>;
  _avg?: Maybe<DisbursementAvgOrderByAggregateInput>;
  _max?: Maybe<DisbursementMaxOrderByAggregateInput>;
  _min?: Maybe<DisbursementMinOrderByAggregateInput>;
  _sum?: Maybe<DisbursementSumOrderByAggregateInput>;
};

export type DisbursementOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  transactions?: Maybe<TransactionOrderByRelationAggregateInput>;
  _relevance?: Maybe<DisbursementOrderByRelevanceInput>;
};

export type DisbursementRelationFilter = {
  is?: Maybe<DisbursementWhereInput>;
  isNot?: Maybe<DisbursementWhereInput>;
};

export enum DisbursementScalarFieldEnum {
  Id = 'id',
  UpdatedAt = 'updatedAt',
  Amount = 'amount',
  DisbursementId = 'disbursementId',
  DisbursementDate = 'disbursementDate',
  Source = 'source'
}

export type DisbursementScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DisbursementScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DisbursementScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DisbursementScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  disbursementId?: Maybe<StringWithAggregatesFilter>;
  disbursementDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  source?: Maybe<StringWithAggregatesFilter>;
};

export type DisbursementSumAggregateOutputType = {
  __typename?: 'DisbursementSumAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type DisbursementSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type DisbursementUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  disbursementId: Scalars['String'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutDisbursementInput>;
};

export type DisbursementUncheckedCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  disbursementId: Scalars['String'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutDisbursementNestedInput>;
};

export type DisbursementUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementUncheckedUpdateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementUpdateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUpdateManyWithoutDisbursementNestedInput>;
};

export type DisbursementUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementUpdateOneWithoutTransactionsNestedInput = {
  create?: Maybe<DisbursementUncheckedCreateWithoutTransactionsInput>;
  connectOrCreate?: Maybe<DisbursementCreateOrConnectWithoutTransactionsInput>;
  upsert?: Maybe<DisbursementUpsertWithoutTransactionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DisbursementWhereUniqueInput>;
  update?: Maybe<DisbursementUncheckedUpdateWithoutTransactionsInput>;
};

export type DisbursementUpdateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementUpsertWithoutTransactionsInput = {
  update: DisbursementUncheckedUpdateWithoutTransactionsInput;
  create: DisbursementUncheckedCreateWithoutTransactionsInput;
};

export type DisbursementWhereInput = {
  AND?: Maybe<Array<DisbursementWhereInput>>;
  OR?: Maybe<Array<DisbursementWhereInput>>;
  NOT?: Maybe<Array<DisbursementWhereInput>>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  amount?: Maybe<FloatFilter>;
  disbursementId?: Maybe<StringFilter>;
  disbursementDate?: Maybe<DateTimeNullableFilter>;
  source?: Maybe<StringFilter>;
  transactions?: Maybe<TransactionListRelationFilter>;
};

export type DisbursementWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
};

export type Documentation = {
  __typename?: 'Documentation';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  provider: DocumentationProvider;
  category: DocumentationCategory;
  title: Scalars['String'];
  url: Scalars['String'];
};

export enum DocumentationCategory {
  Dogs = 'Dogs',
  Cats = 'Cats',
  Horses = 'Horses',
  Pigs = 'Pigs',
  Birds = 'Birds',
  Reptiles = 'Reptiles',
  Ruminants = 'Ruminants',
  SmallMammals = 'SmallMammals',
  GeneralInformation = 'GeneralInformation',
  Medications = 'Medications'
}

export type DocumentationCountAggregateOutputType = {
  __typename?: 'DocumentationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  provider: Scalars['Int'];
  category: Scalars['Int'];
  title: Scalars['Int'];
  url: Scalars['Int'];
  _all: Scalars['Int'];
};

export type DocumentationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  category?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export type DocumentationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: DocumentationProvider;
  category: DocumentationCategory;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type DocumentationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: DocumentationProvider;
  category: DocumentationCategory;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type DocumentationMaxAggregateOutputType = {
  __typename?: 'DocumentationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<DocumentationProvider>;
  category?: Maybe<DocumentationCategory>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  category?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export type DocumentationMinAggregateOutputType = {
  __typename?: 'DocumentationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<DocumentationProvider>;
  category?: Maybe<DocumentationCategory>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  category?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export enum DocumentationOrderByRelevanceFieldEnum {
  Id = 'id',
  Title = 'title',
  Url = 'url'
}

export type DocumentationOrderByRelevanceInput = {
  fields: Array<DocumentationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type DocumentationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  category?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  _count?: Maybe<DocumentationCountOrderByAggregateInput>;
  _max?: Maybe<DocumentationMaxOrderByAggregateInput>;
  _min?: Maybe<DocumentationMinOrderByAggregateInput>;
};

export type DocumentationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  category?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  _relevance?: Maybe<DocumentationOrderByRelevanceInput>;
};

export enum DocumentationProvider {
  VeterinaryPartner = 'VeterinaryPartner'
}

export enum DocumentationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Provider = 'provider',
  Category = 'category',
  Title = 'title',
  Url = 'url'
}

export type DocumentationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DocumentationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DocumentationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DocumentationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  provider?: Maybe<EnumDocumentationProviderWithAggregatesFilter>;
  category?: Maybe<EnumDocumentationCategoryWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  url?: Maybe<StringWithAggregatesFilter>;
};

export type DocumentationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: DocumentationProvider;
  category: DocumentationCategory;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type DocumentationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<DocumentationProvider>;
  category?: Maybe<DocumentationCategory>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<DocumentationProvider>;
  category?: Maybe<DocumentationCategory>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<DocumentationProvider>;
  category?: Maybe<DocumentationCategory>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<DocumentationProvider>;
  category?: Maybe<DocumentationCategory>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentationWhereInput = {
  AND?: Maybe<Array<DocumentationWhereInput>>;
  OR?: Maybe<Array<DocumentationWhereInput>>;
  NOT?: Maybe<Array<DocumentationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  provider?: Maybe<EnumDocumentationProviderFilter>;
  category?: Maybe<EnumDocumentationCategoryFilter>;
  title?: Maybe<StringFilter>;
  url?: Maybe<StringFilter>;
};

export type DocumentationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DuplicateEmailCampaignInput = {
  emailCampaignId: Scalars['String'];
  userId: Scalars['String'];
};

export type EmailCampaign = {
  __typename?: 'EmailCampaign';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: EmailCampaignType;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status: EmailCampaignStatus;
  subject?: Maybe<Scalars['String']>;
  from: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType: EmailCampaignSegmentationType;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<User>;
  lastUpdatedByUser?: Maybe<User>;
  sentByUser?: Maybe<User>;
  clinic: Clinic;
  emailCampaignLogs: Array<EmailCampaignLog>;
  user?: Maybe<User>;
  emailCampaignUnsubscribes: Array<EmailCampaignUnsubscribe>;
  informCampaignExecution?: Maybe<InformCampaignExecution>;
  customList?: Maybe<EmailCampaignCustomList>;
  _count: EmailCampaignCountOutputType;
};


export type EmailCampaignEmailCampaignLogsArgs = {
  where?: Maybe<EmailCampaignLogWhereInput>;
  orderBy?: Maybe<EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignLogScalarFieldEnum>;
};


export type EmailCampaignEmailCampaignUnsubscribesArgs = {
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
  orderBy?: Maybe<EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignUnsubscribeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignUnsubscribeScalarFieldEnum>;
};

export type EmailCampaignAvgAggregateOutputType = {
  __typename?: 'EmailCampaignAvgAggregateOutputType';
  totalSegmentContacts?: Maybe<Scalars['Float']>;
};

export type EmailCampaignAvgOrderByAggregateInput = {
  totalSegmentContacts?: Maybe<SortOrder>;
};

export type EmailCampaignCountAggregateOutputType = {
  __typename?: 'EmailCampaignCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  startsAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  type: Scalars['Int'];
  informCampaignExecutionId: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  subject: Scalars['Int'];
  from: Scalars['Int'];
  replyTo: Scalars['Int'];
  replyToJson: Scalars['Int'];
  createdByUserId: Scalars['Int'];
  latUpdatedByUserId: Scalars['Int'];
  sentByUserId: Scalars['Int'];
  clinicId: Scalars['Int'];
  htmlContent: Scalars['Int'];
  unlayerJson: Scalars['Int'];
  scheduledAt: Scalars['Int'];
  startedAt: Scalars['Int'];
  completedAt: Scalars['Int'];
  totalSegmentContacts: Scalars['Int'];
  segmentWhere: Scalars['Int'];
  userId: Scalars['Int'];
  contexts: Scalars['Int'];
  segmentArgs: Scalars['Int'];
  segmentationType: Scalars['Int'];
  segmentationDefinition: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EmailCampaignCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  informCampaignExecutionId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  replyTo?: Maybe<SortOrder>;
  replyToJson?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  totalSegmentContacts?: Maybe<SortOrder>;
  segmentWhere?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  contexts?: Maybe<SortOrder>;
  segmentArgs?: Maybe<SortOrder>;
  segmentationType?: Maybe<SortOrder>;
  segmentationDefinition?: Maybe<SortOrder>;
};

export type EmailCampaignCountOutputType = {
  __typename?: 'EmailCampaignCountOutputType';
  emailCampaignLogs: Scalars['Int'];
  emailCampaignUnsubscribes: Scalars['Int'];
};

export type EmailCampaignCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
  typedSegmentArgs?: Maybe<SegmentsArgsInput>;
};

export type EmailCampaignCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
};

export type EmailCampaignCreateManyClinicInputEnvelope = {
  data: Array<EmailCampaignCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCreateManyCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
};

export type EmailCampaignCreateManyCreatedByUserInputEnvelope = {
  data: Array<EmailCampaignCreateManyCreatedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCreateManyInformCampaignExecutionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
};

export type EmailCampaignCreateManyInformCampaignExecutionInputEnvelope = {
  data: Array<EmailCampaignCreateManyInformCampaignExecutionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
};

export type EmailCampaignCreateManyLastUpdatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
};

export type EmailCampaignCreateManyLastUpdatedByUserInputEnvelope = {
  data: Array<EmailCampaignCreateManyLastUpdatedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCreateManySentByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
};

export type EmailCampaignCreateManySentByUserInputEnvelope = {
  data: Array<EmailCampaignCreateManySentByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
};

export type EmailCampaignCreateManyUserInputEnvelope = {
  data: Array<EmailCampaignCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
};

export type EmailCampaignCreateNestedManyWithoutCreatedByUserInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutCreatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyCreatedByUserInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
};

export type EmailCampaignCreateNestedManyWithoutInformCampaignExecutionInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutInformCampaignExecutionInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutInformCampaignExecutionInput>>;
  createMany?: Maybe<EmailCampaignCreateManyInformCampaignExecutionInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
};

export type EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutLastUpdatedByUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutLastUpdatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyLastUpdatedByUserInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
};

export type EmailCampaignCreateNestedManyWithoutSentByUserInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutSentByUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutSentByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManySentByUserInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
};

export type EmailCampaignCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
};

export type EmailCampaignCreateNestedOneWithoutCustomListInput = {
  create?: Maybe<EmailCampaignUncheckedCreateWithoutCustomListInput>;
  connectOrCreate?: Maybe<EmailCampaignCreateOrConnectWithoutCustomListInput>;
  connect?: Maybe<EmailCampaignWhereUniqueInput>;
};

export type EmailCampaignCreateNestedOneWithoutEmailCampaignLogsInput = {
  create?: Maybe<EmailCampaignUncheckedCreateWithoutEmailCampaignLogsInput>;
  connectOrCreate?: Maybe<EmailCampaignCreateOrConnectWithoutEmailCampaignLogsInput>;
  connect?: Maybe<EmailCampaignWhereUniqueInput>;
};

export type EmailCampaignCreateNestedOneWithoutEmailCampaignUnsubscribesInput = {
  create?: Maybe<EmailCampaignUncheckedCreateWithoutEmailCampaignUnsubscribesInput>;
  connectOrCreate?: Maybe<EmailCampaignCreateOrConnectWithoutEmailCampaignUnsubscribesInput>;
  connect?: Maybe<EmailCampaignWhereUniqueInput>;
};

export type EmailCampaignCreateOrConnectWithoutClinicInput = {
  where: EmailCampaignWhereUniqueInput;
  create: EmailCampaignUncheckedCreateWithoutClinicInput;
};

export type EmailCampaignCreateOrConnectWithoutCreatedByUserInput = {
  where: EmailCampaignWhereUniqueInput;
  create: EmailCampaignUncheckedCreateWithoutCreatedByUserInput;
};

export type EmailCampaignCreateOrConnectWithoutCustomListInput = {
  where: EmailCampaignWhereUniqueInput;
  create: EmailCampaignUncheckedCreateWithoutCustomListInput;
};

export type EmailCampaignCreateOrConnectWithoutEmailCampaignLogsInput = {
  where: EmailCampaignWhereUniqueInput;
  create: EmailCampaignUncheckedCreateWithoutEmailCampaignLogsInput;
};

export type EmailCampaignCreateOrConnectWithoutEmailCampaignUnsubscribesInput = {
  where: EmailCampaignWhereUniqueInput;
  create: EmailCampaignUncheckedCreateWithoutEmailCampaignUnsubscribesInput;
};

export type EmailCampaignCreateOrConnectWithoutInformCampaignExecutionInput = {
  where: EmailCampaignWhereUniqueInput;
  create: EmailCampaignUncheckedCreateWithoutInformCampaignExecutionInput;
};

export type EmailCampaignCreateOrConnectWithoutLastUpdatedByUserInput = {
  where: EmailCampaignWhereUniqueInput;
  create: EmailCampaignUncheckedCreateWithoutLastUpdatedByUserInput;
};

export type EmailCampaignCreateOrConnectWithoutSentByUserInput = {
  where: EmailCampaignWhereUniqueInput;
  create: EmailCampaignUncheckedCreateWithoutSentByUserInput;
};

export type EmailCampaignCreateOrConnectWithoutUserInput = {
  where: EmailCampaignWhereUniqueInput;
  create: EmailCampaignUncheckedCreateWithoutUserInput;
};

export type EmailCampaignCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutCustomListInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
};

export type EmailCampaignCreateWithoutEmailCampaignLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutEmailCampaignUnsubscribesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutInformCampaignExecutionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutLastUpdatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutSentByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCustomList = {
  __typename?: 'EmailCampaignCustomList';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  emailCampaignId: Scalars['String'];
  awsFileKey: Scalars['String'];
  fileName: Scalars['String'];
  validRecipients: Scalars['Int'];
  invalidRecipients: Scalars['Int'];
  emailCampaign: EmailCampaign;
  recipients: Array<EmailCampaignCustomListRecipient>;
  _count: EmailCampaignCustomListCountOutputType;
};


export type EmailCampaignCustomListRecipientsArgs = {
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
  orderBy?: Maybe<EmailCampaignCustomListRecipientOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignCustomListRecipientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignCustomListRecipientScalarFieldEnum>;
};

export type EmailCampaignCustomListAvgAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListAvgAggregateOutputType';
  validRecipients?: Maybe<Scalars['Float']>;
  invalidRecipients?: Maybe<Scalars['Float']>;
};

export type EmailCampaignCustomListAvgOrderByAggregateInput = {
  validRecipients?: Maybe<SortOrder>;
  invalidRecipients?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListCountAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  emailCampaignId: Scalars['Int'];
  awsFileKey: Scalars['Int'];
  fileName: Scalars['Int'];
  validRecipients: Scalars['Int'];
  invalidRecipients: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EmailCampaignCustomListCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  awsFileKey?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  validRecipients?: Maybe<SortOrder>;
  invalidRecipients?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListCountOutputType = {
  __typename?: 'EmailCampaignCustomListCountOutputType';
  recipients: Scalars['Int'];
};

export type EmailCampaignCustomListCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  awsFileKey: Scalars['String'];
  fileName: Scalars['String'];
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  emailCampaign: EmailCampaignCreateNestedOneWithoutCustomListInput;
  recipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutEmailCampaignCustomListInput>;
};

export type EmailCampaignCustomListCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  awsFileKey: Scalars['String'];
  fileName: Scalars['String'];
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput = {
  create?: Maybe<EmailCampaignCustomListUncheckedCreateWithoutEmailCampaignInput>;
  connectOrCreate?: Maybe<EmailCampaignCustomListCreateOrConnectWithoutEmailCampaignInput>;
  connect?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
};

export type EmailCampaignCustomListCreateNestedOneWithoutRecipientsInput = {
  create?: Maybe<EmailCampaignCustomListUncheckedCreateWithoutRecipientsInput>;
  connectOrCreate?: Maybe<EmailCampaignCustomListCreateOrConnectWithoutRecipientsInput>;
  connect?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
};

export type EmailCampaignCustomListCreateOrConnectWithoutEmailCampaignInput = {
  where: EmailCampaignCustomListWhereUniqueInput;
  create: EmailCampaignCustomListUncheckedCreateWithoutEmailCampaignInput;
};

export type EmailCampaignCustomListCreateOrConnectWithoutRecipientsInput = {
  where: EmailCampaignCustomListWhereUniqueInput;
  create: EmailCampaignCustomListUncheckedCreateWithoutRecipientsInput;
};

export type EmailCampaignCustomListCreateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  awsFileKey: Scalars['String'];
  fileName: Scalars['String'];
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  recipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutEmailCampaignCustomListInput>;
};

export type EmailCampaignCustomListCreateWithoutRecipientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  awsFileKey: Scalars['String'];
  fileName: Scalars['String'];
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  emailCampaign: EmailCampaignCreateNestedOneWithoutCustomListInput;
};

export type EmailCampaignCustomListMaxAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  awsFileKey?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  awsFileKey?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  validRecipients?: Maybe<SortOrder>;
  invalidRecipients?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListMinAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  awsFileKey?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  awsFileKey?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  validRecipients?: Maybe<SortOrder>;
  invalidRecipients?: Maybe<SortOrder>;
};

export enum EmailCampaignCustomListOrderByRelevanceFieldEnum {
  Id = 'id',
  EmailCampaignId = 'emailCampaignId',
  AwsFileKey = 'awsFileKey',
  FileName = 'fileName'
}

export type EmailCampaignCustomListOrderByRelevanceInput = {
  fields: Array<EmailCampaignCustomListOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EmailCampaignCustomListOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  awsFileKey?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  validRecipients?: Maybe<SortOrder>;
  invalidRecipients?: Maybe<SortOrder>;
  _count?: Maybe<EmailCampaignCustomListCountOrderByAggregateInput>;
  _avg?: Maybe<EmailCampaignCustomListAvgOrderByAggregateInput>;
  _max?: Maybe<EmailCampaignCustomListMaxOrderByAggregateInput>;
  _min?: Maybe<EmailCampaignCustomListMinOrderByAggregateInput>;
  _sum?: Maybe<EmailCampaignCustomListSumOrderByAggregateInput>;
};

export type EmailCampaignCustomListOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  awsFileKey?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  validRecipients?: Maybe<SortOrder>;
  invalidRecipients?: Maybe<SortOrder>;
  emailCampaign?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  recipients?: Maybe<EmailCampaignCustomListRecipientOrderByRelationAggregateInput>;
  _relevance?: Maybe<EmailCampaignCustomListOrderByRelevanceInput>;
};

export type EmailCampaignCustomListRecipient = {
  __typename?: 'EmailCampaignCustomListRecipient';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  emailCampaignCustomListId: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status: EmailCampaignCustomListRecipientStatus;
  clinicPetParentId?: Maybe<Scalars['String']>;
  emailCampaignCustomList: EmailCampaignCustomList;
  clinicPetParent?: Maybe<ClinicPetParent>;
};

export type EmailCampaignCustomListRecipientCountAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListRecipientCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  emailCampaignCustomListId: Scalars['Int'];
  email: Scalars['Int'];
  firstName: Scalars['Int'];
  lastName: Scalars['Int'];
  reason: Scalars['Int'];
  status: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EmailCampaignCustomListRecipientCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailCampaignCustomListId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListRecipientCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  emailCampaignCustomList: EmailCampaignCustomListCreateNestedOneWithoutRecipientsInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutEmailCampaignCustomListRecipientsInput>;
};

export type EmailCampaignCustomListRecipientCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignCustomListId: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
};

export type EmailCampaignCustomListRecipientCreateManyClinicPetParentInputEnvelope = {
  data: Array<EmailCampaignCustomListRecipientCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCustomListRecipientCreateManyEmailCampaignCustomListInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCustomListRecipientCreateManyEmailCampaignCustomListInputEnvelope = {
  data: Array<EmailCampaignCustomListRecipientCreateManyEmailCampaignCustomListInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCustomListRecipientCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignCustomListId: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
};

export type EmailCampaignCustomListRecipientCreateNestedManyWithoutEmailCampaignCustomListInput = {
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutEmailCampaignCustomListInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutEmailCampaignCustomListInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyEmailCampaignCustomListInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
};

export type EmailCampaignCustomListRecipientCreateOrConnectWithoutClinicPetParentInput = {
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
  create: EmailCampaignCustomListRecipientUncheckedCreateWithoutClinicPetParentInput;
};

export type EmailCampaignCustomListRecipientCreateOrConnectWithoutEmailCampaignCustomListInput = {
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
  create: EmailCampaignCustomListRecipientUncheckedCreateWithoutEmailCampaignCustomListInput;
};

export type EmailCampaignCustomListRecipientCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  emailCampaignCustomList: EmailCampaignCustomListCreateNestedOneWithoutRecipientsInput;
};

export type EmailCampaignCustomListRecipientCreateWithoutEmailCampaignCustomListInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutEmailCampaignCustomListRecipientsInput>;
};

export type EmailCampaignCustomListRecipientListRelationFilter = {
  every?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
  some?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
  none?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
};

export type EmailCampaignCustomListRecipientMaxAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListRecipientMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignCustomListId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCustomListRecipientMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailCampaignCustomListId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListRecipientMinAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListRecipientMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignCustomListId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCustomListRecipientMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailCampaignCustomListId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListRecipientOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailCampaignCustomListRecipientOrderByRelevanceFieldEnum {
  Id = 'id',
  EmailCampaignCustomListId = 'emailCampaignCustomListId',
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  Reason = 'reason',
  ClinicPetParentId = 'clinicPetParentId'
}

export type EmailCampaignCustomListRecipientOrderByRelevanceInput = {
  fields: Array<EmailCampaignCustomListRecipientOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EmailCampaignCustomListRecipientOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailCampaignCustomListId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  _count?: Maybe<EmailCampaignCustomListRecipientCountOrderByAggregateInput>;
  _max?: Maybe<EmailCampaignCustomListRecipientMaxOrderByAggregateInput>;
  _min?: Maybe<EmailCampaignCustomListRecipientMinOrderByAggregateInput>;
};

export type EmailCampaignCustomListRecipientOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailCampaignCustomListId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  emailCampaignCustomList?: Maybe<EmailCampaignCustomListOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<EmailCampaignCustomListRecipientOrderByRelevanceInput>;
};

export enum EmailCampaignCustomListRecipientScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  EmailCampaignCustomListId = 'emailCampaignCustomListId',
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  Reason = 'reason',
  Status = 'status',
  ClinicPetParentId = 'clinicPetParentId'
}

export type EmailCampaignCustomListRecipientScalarWhereInput = {
  AND?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereInput>>;
  OR?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  emailCampaignCustomListId?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  firstName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumEmailCampaignCustomListRecipientStatusFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
};

export type EmailCampaignCustomListRecipientScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  emailCampaignCustomListId?: Maybe<StringWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  firstName?: Maybe<StringNullableWithAggregatesFilter>;
  lastName?: Maybe<StringNullableWithAggregatesFilter>;
  reason?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumEmailCampaignCustomListRecipientStatusWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum EmailCampaignCustomListRecipientStatus {
  Pending = 'Pending',
  Valid = 'Valid',
  Invalid = 'Invalid'
}

export type EmailCampaignCustomListRecipientUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignCustomListId: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
};

export type EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutEmailCampaignCustomListInput = {
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutEmailCampaignCustomListInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutEmailCampaignCustomListInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyEmailCampaignCustomListInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
};

export type EmailCampaignCustomListRecipientUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignCustomListId: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
};

export type EmailCampaignCustomListRecipientUncheckedCreateWithoutEmailCampaignCustomListInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignCustomListId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignCustomListId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailCampaignCustomListRecipientUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignCustomListRecipientUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailCampaignCustomListRecipientUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereInput>>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutEmailCampaignCustomListNestedInput = {
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutEmailCampaignCustomListInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutEmailCampaignCustomListInput>>;
  upsert?: Maybe<Array<EmailCampaignCustomListRecipientUpsertWithWhereUniqueWithoutEmailCampaignCustomListInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyEmailCampaignCustomListInputEnvelope>;
  set?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignCustomListRecipientUpdateWithWhereUniqueWithoutEmailCampaignCustomListInput>>;
  updateMany?: Maybe<Array<EmailCampaignCustomListRecipientUpdateManyWithWhereWithoutEmailCampaignCustomListInput>>;
  deleteMany?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereInput>>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutEmailCampaignCustomListRecipientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignCustomListId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutRecipientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignCustomListId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateWithoutEmailCampaignCustomListInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCustomListRecipientUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  emailCampaignCustomList?: Maybe<EmailCampaignCustomListUpdateOneRequiredWithoutRecipientsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutEmailCampaignCustomListRecipientsNestedInput>;
};

export type EmailCampaignCustomListRecipientUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
};

export type EmailCampaignCustomListRecipientUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: EmailCampaignCustomListRecipientScalarWhereInput;
  data: EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutEmailCampaignCustomListRecipientsInput;
};

export type EmailCampaignCustomListRecipientUpdateManyWithWhereWithoutEmailCampaignCustomListInput = {
  where: EmailCampaignCustomListRecipientScalarWhereInput;
  data: EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutRecipientsInput;
};

export type EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailCampaignCustomListRecipientUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignCustomListRecipientUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailCampaignCustomListRecipientUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereInput>>;
};

export type EmailCampaignCustomListRecipientUpdateManyWithoutEmailCampaignCustomListNestedInput = {
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutEmailCampaignCustomListInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutEmailCampaignCustomListInput>>;
  upsert?: Maybe<Array<EmailCampaignCustomListRecipientUpsertWithWhereUniqueWithoutEmailCampaignCustomListInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyEmailCampaignCustomListInputEnvelope>;
  set?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignCustomListRecipientUpdateWithWhereUniqueWithoutEmailCampaignCustomListInput>>;
  updateMany?: Maybe<Array<EmailCampaignCustomListRecipientUpdateManyWithWhereWithoutEmailCampaignCustomListInput>>;
  deleteMany?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereInput>>;
};

export type EmailCampaignCustomListRecipientUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
  data: EmailCampaignCustomListRecipientUncheckedUpdateWithoutClinicPetParentInput;
};

export type EmailCampaignCustomListRecipientUpdateWithWhereUniqueWithoutEmailCampaignCustomListInput = {
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
  data: EmailCampaignCustomListRecipientUncheckedUpdateWithoutEmailCampaignCustomListInput;
};

export type EmailCampaignCustomListRecipientUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  emailCampaignCustomList?: Maybe<EmailCampaignCustomListUpdateOneRequiredWithoutRecipientsNestedInput>;
};

export type EmailCampaignCustomListRecipientUpdateWithoutEmailCampaignCustomListInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutEmailCampaignCustomListRecipientsNestedInput>;
};

export type EmailCampaignCustomListRecipientUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
  update: EmailCampaignCustomListRecipientUncheckedUpdateWithoutClinicPetParentInput;
  create: EmailCampaignCustomListRecipientUncheckedCreateWithoutClinicPetParentInput;
};

export type EmailCampaignCustomListRecipientUpsertWithWhereUniqueWithoutEmailCampaignCustomListInput = {
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
  update: EmailCampaignCustomListRecipientUncheckedUpdateWithoutEmailCampaignCustomListInput;
  create: EmailCampaignCustomListRecipientUncheckedCreateWithoutEmailCampaignCustomListInput;
};

export type EmailCampaignCustomListRecipientWhereInput = {
  AND?: Maybe<Array<EmailCampaignCustomListRecipientWhereInput>>;
  OR?: Maybe<Array<EmailCampaignCustomListRecipientWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignCustomListRecipientWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  emailCampaignCustomListId?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  firstName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumEmailCampaignCustomListRecipientStatusFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  emailCampaignCustomList?: Maybe<EmailCampaignCustomListWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
};

export type EmailCampaignCustomListRecipientWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EmailCampaignCustomListRelationFilter = {
  is?: Maybe<EmailCampaignCustomListWhereInput>;
  isNot?: Maybe<EmailCampaignCustomListWhereInput>;
};

export enum EmailCampaignCustomListScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  EmailCampaignId = 'emailCampaignId',
  AwsFileKey = 'awsFileKey',
  FileName = 'fileName',
  ValidRecipients = 'validRecipients',
  InvalidRecipients = 'invalidRecipients'
}

export type EmailCampaignCustomListScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailCampaignCustomListScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailCampaignCustomListScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailCampaignCustomListScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  emailCampaignId?: Maybe<StringWithAggregatesFilter>;
  awsFileKey?: Maybe<StringWithAggregatesFilter>;
  fileName?: Maybe<StringWithAggregatesFilter>;
  validRecipients?: Maybe<IntWithAggregatesFilter>;
  invalidRecipients?: Maybe<IntWithAggregatesFilter>;
};

export type EmailCampaignCustomListSumAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListSumAggregateOutputType';
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListSumOrderByAggregateInput = {
  validRecipients?: Maybe<SortOrder>;
  invalidRecipients?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  awsFileKey: Scalars['String'];
  fileName: Scalars['String'];
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  recipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutEmailCampaignCustomListInput>;
};

export type EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput = {
  create?: Maybe<EmailCampaignCustomListUncheckedCreateWithoutEmailCampaignInput>;
  connectOrCreate?: Maybe<EmailCampaignCustomListCreateOrConnectWithoutEmailCampaignInput>;
  connect?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
};

export type EmailCampaignCustomListUncheckedCreateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  awsFileKey: Scalars['String'];
  fileName: Scalars['String'];
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  recipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutEmailCampaignCustomListInput>;
};

export type EmailCampaignCustomListUncheckedCreateWithoutRecipientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  awsFileKey: Scalars['String'];
  fileName: Scalars['String'];
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  awsFileKey?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  recipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutEmailCampaignCustomListNestedInput>;
};

export type EmailCampaignCustomListUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  awsFileKey?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput = {
  create?: Maybe<EmailCampaignCustomListUncheckedCreateWithoutEmailCampaignInput>;
  connectOrCreate?: Maybe<EmailCampaignCustomListCreateOrConnectWithoutEmailCampaignInput>;
  upsert?: Maybe<EmailCampaignCustomListUpsertWithoutEmailCampaignInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  update?: Maybe<EmailCampaignCustomListUncheckedUpdateWithoutEmailCampaignInput>;
};

export type EmailCampaignCustomListUncheckedUpdateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  awsFileKey?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  recipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutEmailCampaignCustomListNestedInput>;
};

export type EmailCampaignCustomListUncheckedUpdateWithoutRecipientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  awsFileKey?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  awsFileKey?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneRequiredWithoutCustomListNestedInput>;
  recipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutEmailCampaignCustomListNestedInput>;
};

export type EmailCampaignCustomListUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  awsFileKey?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUpdateOneRequiredWithoutRecipientsNestedInput = {
  create?: Maybe<EmailCampaignCustomListUncheckedCreateWithoutRecipientsInput>;
  connectOrCreate?: Maybe<EmailCampaignCustomListCreateOrConnectWithoutRecipientsInput>;
  upsert?: Maybe<EmailCampaignCustomListUpsertWithoutRecipientsInput>;
  connect?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  update?: Maybe<EmailCampaignCustomListUncheckedUpdateWithoutRecipientsInput>;
};

export type EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput = {
  create?: Maybe<EmailCampaignCustomListUncheckedCreateWithoutEmailCampaignInput>;
  connectOrCreate?: Maybe<EmailCampaignCustomListCreateOrConnectWithoutEmailCampaignInput>;
  upsert?: Maybe<EmailCampaignCustomListUpsertWithoutEmailCampaignInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  update?: Maybe<EmailCampaignCustomListUncheckedUpdateWithoutEmailCampaignInput>;
};

export type EmailCampaignCustomListUpdateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  awsFileKey?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  recipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutEmailCampaignCustomListNestedInput>;
};

export type EmailCampaignCustomListUpdateWithoutRecipientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  awsFileKey?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  validRecipients?: Maybe<Scalars['Int']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneRequiredWithoutCustomListNestedInput>;
};

export type EmailCampaignCustomListUpsertWithoutEmailCampaignInput = {
  update: EmailCampaignCustomListUncheckedUpdateWithoutEmailCampaignInput;
  create: EmailCampaignCustomListUncheckedCreateWithoutEmailCampaignInput;
};

export type EmailCampaignCustomListUpsertWithoutRecipientsInput = {
  update: EmailCampaignCustomListUncheckedUpdateWithoutRecipientsInput;
  create: EmailCampaignCustomListUncheckedCreateWithoutRecipientsInput;
};

export type EmailCampaignCustomListUrlInput = {
  emailCampaignId: Scalars['String'];
};

export type EmailCampaignCustomListWhereInput = {
  AND?: Maybe<Array<EmailCampaignCustomListWhereInput>>;
  OR?: Maybe<Array<EmailCampaignCustomListWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignCustomListWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  emailCampaignId?: Maybe<StringFilter>;
  awsFileKey?: Maybe<StringFilter>;
  fileName?: Maybe<StringFilter>;
  validRecipients?: Maybe<IntFilter>;
  invalidRecipients?: Maybe<IntFilter>;
  emailCampaign?: Maybe<EmailCampaignWhereInput>;
  recipients?: Maybe<EmailCampaignCustomListRecipientListRelationFilter>;
};

export type EmailCampaignCustomListWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  emailCampaignId?: Maybe<Scalars['String']>;
};

export type EmailCampaignListRelationFilter = {
  every?: Maybe<EmailCampaignWhereInput>;
  some?: Maybe<EmailCampaignWhereInput>;
  none?: Maybe<EmailCampaignWhereInput>;
};

export type EmailCampaignLog = {
  __typename?: 'EmailCampaignLog';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  emailCampaignId: Scalars['String'];
  emailCampaignType: EmailCampaignType;
  provider: EmailProvider;
  clinicPetParentId: Scalars['String'];
  targetEmail: Scalars['String'];
  action: EmailCampaignLogAction;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  clinic: Clinic;
  emailCampaign: EmailCampaign;
  clinicPetParent: ClinicPetParent;
};

export enum EmailCampaignLogAction {
  Sent = 'Sent',
  Skipped = 'Skipped',
  Error = 'Error'
}

export type EmailCampaignLogCountAggregateOutputType = {
  __typename?: 'EmailCampaignLogCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  emailCampaignId: Scalars['Int'];
  emailCampaignType: Scalars['Int'];
  provider: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  targetEmail: Scalars['Int'];
  action: Scalars['Int'];
  skipReason: Scalars['Int'];
  error: Scalars['Int'];
  providerTransactionId: Scalars['Int'];
  variant: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EmailCampaignLogCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  emailCampaignType?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  skipReason?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  providerTransactionId?: Maybe<SortOrder>;
  variant?: Maybe<SortOrder>;
};

export type EmailCampaignLogCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  targetEmail: Scalars['String'];
  action: EmailCampaignLogAction;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignLogInput;
  emailCampaign: EmailCampaignCreateNestedOneWithoutEmailCampaignLogsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutEmailCampaignLogInput;
};

export type EmailCampaignLogCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  clinicPetParentId: Scalars['String'];
  targetEmail: Scalars['String'];
  action: EmailCampaignLogAction;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogCreateManyClinicInputEnvelope = {
  data: Array<EmailCampaignLogCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignLogCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  emailCampaignId: Scalars['String'];
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  targetEmail: Scalars['String'];
  action: EmailCampaignLogAction;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogCreateManyClinicPetParentInputEnvelope = {
  data: Array<EmailCampaignLogCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignLogCreateManyEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  clinicPetParentId: Scalars['String'];
  targetEmail: Scalars['String'];
  action: EmailCampaignLogAction;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogCreateManyEmailCampaignInputEnvelope = {
  data: Array<EmailCampaignLogCreateManyEmailCampaignInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignLogCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  emailCampaignId: Scalars['String'];
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  clinicPetParentId: Scalars['String'];
  targetEmail: Scalars['String'];
  action: EmailCampaignLogAction;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
};

export type EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
};

export type EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput = {
  create?: Maybe<Array<EmailCampaignLogCreateWithoutEmailCampaignInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyEmailCampaignInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
};

export type EmailCampaignLogCreateOrConnectWithoutClinicInput = {
  where: EmailCampaignLogWhereUniqueInput;
  create: EmailCampaignLogUncheckedCreateWithoutClinicInput;
};

export type EmailCampaignLogCreateOrConnectWithoutClinicPetParentInput = {
  where: EmailCampaignLogWhereUniqueInput;
  create: EmailCampaignLogUncheckedCreateWithoutClinicPetParentInput;
};

export type EmailCampaignLogCreateOrConnectWithoutEmailCampaignInput = {
  where: EmailCampaignLogWhereUniqueInput;
  create: EmailCampaignLogUncheckedCreateWithoutEmailCampaignInput;
};

export type EmailCampaignLogCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  targetEmail: Scalars['String'];
  action: EmailCampaignLogAction;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  emailCampaign: EmailCampaignCreateNestedOneWithoutEmailCampaignLogsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutEmailCampaignLogInput;
};

export type EmailCampaignLogCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  targetEmail: Scalars['String'];
  action: EmailCampaignLogAction;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignLogInput;
  emailCampaign: EmailCampaignCreateNestedOneWithoutEmailCampaignLogsInput;
};

export type EmailCampaignLogCreateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  targetEmail: Scalars['String'];
  action: EmailCampaignLogAction;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignLogInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutEmailCampaignLogInput;
};

export type EmailCampaignLogListRelationFilter = {
  every?: Maybe<EmailCampaignLogWhereInput>;
  some?: Maybe<EmailCampaignLogWhereInput>;
  none?: Maybe<EmailCampaignLogWhereInput>;
};

export type EmailCampaignLogMaxAggregateOutputType = {
  __typename?: 'EmailCampaignLogMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  action?: Maybe<EmailCampaignLogAction>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  emailCampaignType?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  skipReason?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  providerTransactionId?: Maybe<SortOrder>;
  variant?: Maybe<SortOrder>;
};

export type EmailCampaignLogMinAggregateOutputType = {
  __typename?: 'EmailCampaignLogMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  action?: Maybe<EmailCampaignLogAction>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  emailCampaignType?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  skipReason?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  providerTransactionId?: Maybe<SortOrder>;
  variant?: Maybe<SortOrder>;
};

export type EmailCampaignLogOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailCampaignLogOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  EmailCampaignId = 'emailCampaignId',
  ClinicPetParentId = 'clinicPetParentId',
  TargetEmail = 'targetEmail',
  Error = 'error',
  ProviderTransactionId = 'providerTransactionId',
  Variant = 'variant'
}

export type EmailCampaignLogOrderByRelevanceInput = {
  fields: Array<EmailCampaignLogOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EmailCampaignLogOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  emailCampaignType?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  skipReason?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  providerTransactionId?: Maybe<SortOrder>;
  variant?: Maybe<SortOrder>;
  _count?: Maybe<EmailCampaignLogCountOrderByAggregateInput>;
  _max?: Maybe<EmailCampaignLogMaxOrderByAggregateInput>;
  _min?: Maybe<EmailCampaignLogMinOrderByAggregateInput>;
};

export type EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  emailCampaignType?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  skipReason?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  providerTransactionId?: Maybe<SortOrder>;
  variant?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  emailCampaign?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<EmailCampaignLogOrderByRelevanceInput>;
};

export enum EmailCampaignLogScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  EmailCampaignId = 'emailCampaignId',
  EmailCampaignType = 'emailCampaignType',
  Provider = 'provider',
  ClinicPetParentId = 'clinicPetParentId',
  TargetEmail = 'targetEmail',
  Action = 'action',
  SkipReason = 'skipReason',
  Error = 'error',
  ProviderTransactionId = 'providerTransactionId',
  Variant = 'variant'
}

export type EmailCampaignLogScalarWhereInput = {
  AND?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
  OR?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  emailCampaignId?: Maybe<StringFilter>;
  emailCampaignType?: Maybe<EnumEmailCampaignTypeFilter>;
  provider?: Maybe<EnumEmailProviderFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  targetEmail?: Maybe<StringFilter>;
  action?: Maybe<EnumEmailCampaignLogActionFilter>;
  skipReason?: Maybe<EnumEmailCampaignLogSkipReasonNullableFilter>;
  error?: Maybe<StringNullableFilter>;
  providerTransactionId?: Maybe<StringNullableFilter>;
  variant?: Maybe<StringNullableFilter>;
};

export type EmailCampaignLogScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailCampaignLogScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailCampaignLogScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailCampaignLogScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  emailCampaignId?: Maybe<StringWithAggregatesFilter>;
  emailCampaignType?: Maybe<EnumEmailCampaignTypeWithAggregatesFilter>;
  provider?: Maybe<EnumEmailProviderWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  targetEmail?: Maybe<StringWithAggregatesFilter>;
  action?: Maybe<EnumEmailCampaignLogActionWithAggregatesFilter>;
  skipReason?: Maybe<EnumEmailCampaignLogSkipReasonNullableWithAggregatesFilter>;
  error?: Maybe<StringNullableWithAggregatesFilter>;
  providerTransactionId?: Maybe<StringNullableWithAggregatesFilter>;
  variant?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum EmailCampaignLogSkipReason {
  Unsubscribed = 'Unsubscribed',
  InvalidEmail = 'InvalidEmail',
  Suppressed = 'Suppressed'
}

export type EmailCampaignLogUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  emailCampaignId: Scalars['String'];
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  clinicPetParentId: Scalars['String'];
  targetEmail: Scalars['String'];
  action: EmailCampaignLogAction;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
};

export type EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
};

export type EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput = {
  create?: Maybe<Array<EmailCampaignLogCreateWithoutEmailCampaignInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyEmailCampaignInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
};

export type EmailCampaignLogUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  clinicPetParentId: Scalars['String'];
  targetEmail: Scalars['String'];
  action: EmailCampaignLogAction;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  emailCampaignId: Scalars['String'];
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  targetEmail: Scalars['String'];
  action: EmailCampaignLogAction;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedCreateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  clinicPetParentId: Scalars['String'];
  targetEmail: Scalars['String'];
  action: EmailCampaignLogAction;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  action?: Maybe<EmailCampaignLogAction>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  action?: Maybe<EmailCampaignLogAction>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignLogUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignLogUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignLogUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
};

export type EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailCampaignLogUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignLogUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailCampaignLogUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
};

export type EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  action?: Maybe<EmailCampaignLogAction>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  action?: Maybe<EmailCampaignLogAction>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput = {
  create?: Maybe<Array<EmailCampaignLogCreateWithoutEmailCampaignInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutEmailCampaignInput>>;
  upsert?: Maybe<Array<EmailCampaignLogUpsertWithWhereUniqueWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyEmailCampaignInputEnvelope>;
  set?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignLogUpdateWithWhereUniqueWithoutEmailCampaignInput>>;
  updateMany?: Maybe<Array<EmailCampaignLogUpdateManyWithWhereWithoutEmailCampaignInput>>;
  deleteMany?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
};

export type EmailCampaignLogUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  action?: Maybe<EmailCampaignLogAction>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  targetEmail?: Maybe<Scalars['String']>;
  action?: Maybe<EmailCampaignLogAction>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedUpdateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  action?: Maybe<EmailCampaignLogAction>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  targetEmail?: Maybe<Scalars['String']>;
  action?: Maybe<EmailCampaignLogAction>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignLogNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneRequiredWithoutEmailCampaignLogsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutEmailCampaignLogNestedInput>;
};

export type EmailCampaignLogUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  targetEmail?: Maybe<Scalars['String']>;
  action?: Maybe<EmailCampaignLogAction>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUpdateManyWithWhereWithoutClinicInput = {
  where: EmailCampaignLogScalarWhereInput;
  data: EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignLogInput;
};

export type EmailCampaignLogUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: EmailCampaignLogScalarWhereInput;
  data: EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignLogInput;
};

export type EmailCampaignLogUpdateManyWithWhereWithoutEmailCampaignInput = {
  where: EmailCampaignLogScalarWhereInput;
  data: EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignLogsInput;
};

export type EmailCampaignLogUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignLogUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignLogUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignLogUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
};

export type EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailCampaignLogUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignLogUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailCampaignLogUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
};

export type EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput = {
  create?: Maybe<Array<EmailCampaignLogCreateWithoutEmailCampaignInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutEmailCampaignInput>>;
  upsert?: Maybe<Array<EmailCampaignLogUpsertWithWhereUniqueWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyEmailCampaignInputEnvelope>;
  set?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignLogUpdateWithWhereUniqueWithoutEmailCampaignInput>>;
  updateMany?: Maybe<Array<EmailCampaignLogUpdateManyWithWhereWithoutEmailCampaignInput>>;
  deleteMany?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
};

export type EmailCampaignLogUpdateWithWhereUniqueWithoutClinicInput = {
  where: EmailCampaignLogWhereUniqueInput;
  data: EmailCampaignLogUncheckedUpdateWithoutClinicInput;
};

export type EmailCampaignLogUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: EmailCampaignLogWhereUniqueInput;
  data: EmailCampaignLogUncheckedUpdateWithoutClinicPetParentInput;
};

export type EmailCampaignLogUpdateWithWhereUniqueWithoutEmailCampaignInput = {
  where: EmailCampaignLogWhereUniqueInput;
  data: EmailCampaignLogUncheckedUpdateWithoutEmailCampaignInput;
};

export type EmailCampaignLogUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  targetEmail?: Maybe<Scalars['String']>;
  action?: Maybe<EmailCampaignLogAction>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneRequiredWithoutEmailCampaignLogsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutEmailCampaignLogNestedInput>;
};

export type EmailCampaignLogUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  targetEmail?: Maybe<Scalars['String']>;
  action?: Maybe<EmailCampaignLogAction>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignLogNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneRequiredWithoutEmailCampaignLogsNestedInput>;
};

export type EmailCampaignLogUpdateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  provider?: Maybe<EmailProvider>;
  targetEmail?: Maybe<Scalars['String']>;
  action?: Maybe<EmailCampaignLogAction>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  error?: Maybe<Scalars['String']>;
  providerTransactionId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignLogNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutEmailCampaignLogNestedInput>;
};

export type EmailCampaignLogUpsertWithWhereUniqueWithoutClinicInput = {
  where: EmailCampaignLogWhereUniqueInput;
  update: EmailCampaignLogUncheckedUpdateWithoutClinicInput;
  create: EmailCampaignLogUncheckedCreateWithoutClinicInput;
};

export type EmailCampaignLogUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: EmailCampaignLogWhereUniqueInput;
  update: EmailCampaignLogUncheckedUpdateWithoutClinicPetParentInput;
  create: EmailCampaignLogUncheckedCreateWithoutClinicPetParentInput;
};

export type EmailCampaignLogUpsertWithWhereUniqueWithoutEmailCampaignInput = {
  where: EmailCampaignLogWhereUniqueInput;
  update: EmailCampaignLogUncheckedUpdateWithoutEmailCampaignInput;
  create: EmailCampaignLogUncheckedCreateWithoutEmailCampaignInput;
};

export type EmailCampaignLogWhereInput = {
  AND?: Maybe<Array<EmailCampaignLogWhereInput>>;
  OR?: Maybe<Array<EmailCampaignLogWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignLogWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  emailCampaignId?: Maybe<StringFilter>;
  emailCampaignType?: Maybe<EnumEmailCampaignTypeFilter>;
  provider?: Maybe<EnumEmailProviderFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  targetEmail?: Maybe<StringFilter>;
  action?: Maybe<EnumEmailCampaignLogActionFilter>;
  skipReason?: Maybe<EnumEmailCampaignLogSkipReasonNullableFilter>;
  error?: Maybe<StringNullableFilter>;
  providerTransactionId?: Maybe<StringNullableFilter>;
  variant?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  emailCampaign?: Maybe<EmailCampaignWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
};

export type EmailCampaignLogWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EmailCampaignMaxAggregateOutputType = {
  __typename?: 'EmailCampaignMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
};

export type EmailCampaignMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  informCampaignExecutionId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  replyTo?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  totalSegmentContacts?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  segmentationType?: Maybe<SortOrder>;
};

export type EmailCampaignMinAggregateOutputType = {
  __typename?: 'EmailCampaignMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
};

export type EmailCampaignMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  informCampaignExecutionId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  replyTo?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  totalSegmentContacts?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  segmentationType?: Maybe<SortOrder>;
};

export type EmailCampaignOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailCampaignOrderByRelevanceFieldEnum {
  Id = 'id',
  InformCampaignExecutionId = 'informCampaignExecutionId',
  Name = 'name',
  Subject = 'subject',
  From = 'from',
  ReplyTo = 'replyTo',
  CreatedByUserId = 'createdByUserId',
  LatUpdatedByUserId = 'latUpdatedByUserId',
  SentByUserId = 'sentByUserId',
  ClinicId = 'clinicId',
  HtmlContent = 'htmlContent',
  UserId = 'userId'
}

export type EmailCampaignOrderByRelevanceInput = {
  fields: Array<EmailCampaignOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EmailCampaignOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  informCampaignExecutionId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  replyTo?: Maybe<SortOrder>;
  replyToJson?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  totalSegmentContacts?: Maybe<SortOrder>;
  segmentWhere?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  contexts?: Maybe<SortOrder>;
  segmentArgs?: Maybe<SortOrder>;
  segmentationType?: Maybe<SortOrder>;
  segmentationDefinition?: Maybe<SortOrder>;
  _count?: Maybe<EmailCampaignCountOrderByAggregateInput>;
  _avg?: Maybe<EmailCampaignAvgOrderByAggregateInput>;
  _max?: Maybe<EmailCampaignMaxOrderByAggregateInput>;
  _min?: Maybe<EmailCampaignMinOrderByAggregateInput>;
  _sum?: Maybe<EmailCampaignSumOrderByAggregateInput>;
};

export type EmailCampaignOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  informCampaignExecutionId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  replyTo?: Maybe<SortOrder>;
  replyToJson?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  totalSegmentContacts?: Maybe<SortOrder>;
  segmentWhere?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  contexts?: Maybe<SortOrder>;
  segmentArgs?: Maybe<SortOrder>;
  segmentationType?: Maybe<SortOrder>;
  segmentationDefinition?: Maybe<SortOrder>;
  createdByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  lastUpdatedByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  sentByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  emailCampaignLogs?: Maybe<EmailCampaignLogOrderByRelationAggregateInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeOrderByRelationAggregateInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionOrderByWithRelationAndSearchRelevanceInput>;
  customList?: Maybe<EmailCampaignCustomListOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<EmailCampaignOrderByRelevanceInput>;
};

export type EmailCampaignRelationFilter = {
  is?: Maybe<EmailCampaignWhereInput>;
  isNot?: Maybe<EmailCampaignWhereInput>;
};

export enum EmailCampaignScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StartsAt = 'startsAt',
  DeletedAt = 'deletedAt',
  Type = 'type',
  InformCampaignExecutionId = 'informCampaignExecutionId',
  Name = 'name',
  Status = 'status',
  Subject = 'subject',
  From = 'from',
  ReplyTo = 'replyTo',
  ReplyToJson = 'replyToJson',
  CreatedByUserId = 'createdByUserId',
  LatUpdatedByUserId = 'latUpdatedByUserId',
  SentByUserId = 'sentByUserId',
  ClinicId = 'clinicId',
  HtmlContent = 'htmlContent',
  UnlayerJson = 'unlayerJson',
  ScheduledAt = 'scheduledAt',
  StartedAt = 'startedAt',
  CompletedAt = 'completedAt',
  TotalSegmentContacts = 'totalSegmentContacts',
  SegmentWhere = 'segmentWhere',
  UserId = 'userId',
  Contexts = 'contexts',
  SegmentArgs = 'segmentArgs',
  SegmentationType = 'segmentationType',
  SegmentationDefinition = 'segmentationDefinition'
}

export type EmailCampaignScalarWhereInput = {
  AND?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  OR?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  startsAt?: Maybe<DateTimeNullableFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<EnumEmailCampaignTypeFilter>;
  informCampaignExecutionId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumEmailCampaignStatusFilter>;
  subject?: Maybe<StringNullableFilter>;
  from?: Maybe<StringFilter>;
  replyTo?: Maybe<StringNullableFilter>;
  replyToJson?: Maybe<JsonNullableFilter>;
  createdByUserId?: Maybe<StringNullableFilter>;
  latUpdatedByUserId?: Maybe<StringNullableFilter>;
  sentByUserId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  htmlContent?: Maybe<StringNullableFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
  scheduledAt?: Maybe<DateTimeNullableFilter>;
  startedAt?: Maybe<DateTimeNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  totalSegmentContacts?: Maybe<IntNullableFilter>;
  segmentWhere?: Maybe<JsonNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  contexts?: Maybe<JsonNullableFilter>;
  segmentArgs?: Maybe<JsonNullableFilter>;
  segmentationType?: Maybe<EnumEmailCampaignSegmentationTypeFilter>;
  segmentationDefinition?: Maybe<JsonNullableFilter>;
};

export type EmailCampaignScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailCampaignScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailCampaignScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailCampaignScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  startsAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  type?: Maybe<EnumEmailCampaignTypeWithAggregatesFilter>;
  informCampaignExecutionId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumEmailCampaignStatusWithAggregatesFilter>;
  subject?: Maybe<StringNullableWithAggregatesFilter>;
  from?: Maybe<StringWithAggregatesFilter>;
  replyTo?: Maybe<StringNullableWithAggregatesFilter>;
  replyToJson?: Maybe<JsonNullableWithAggregatesFilter>;
  createdByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  latUpdatedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  sentByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  htmlContent?: Maybe<StringNullableWithAggregatesFilter>;
  unlayerJson?: Maybe<JsonNullableWithAggregatesFilter>;
  scheduledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  startedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  totalSegmentContacts?: Maybe<IntNullableWithAggregatesFilter>;
  segmentWhere?: Maybe<JsonNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  contexts?: Maybe<JsonNullableWithAggregatesFilter>;
  segmentArgs?: Maybe<JsonNullableWithAggregatesFilter>;
  segmentationType?: Maybe<EnumEmailCampaignSegmentationTypeWithAggregatesFilter>;
  segmentationDefinition?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum EmailCampaignSegmentationType {
  AllActiveClients = 'AllActiveClients',
  ClinicPetParentPrismaWhere = 'ClinicPetParentPrismaWhere',
  ClinicPetParentRawSql = 'ClinicPetParentRawSql',
  CustomList = 'CustomList'
}

export enum EmailCampaignStatus {
  Draft = 'Draft',
  Scheduled = 'Scheduled',
  Sending = 'Sending',
  Sent = 'Sent',
  Cancelled = 'Cancelled'
}

export type EmailCampaignSumAggregateOutputType = {
  __typename?: 'EmailCampaignSumAggregateOutputType';
  totalSegmentContacts?: Maybe<Scalars['Int']>;
};

export type EmailCampaignSumOrderByAggregateInput = {
  totalSegmentContacts?: Maybe<SortOrder>;
};

export type EmailCampaignTemplate = {
  __typename?: 'EmailCampaignTemplate';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  clinicId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  htmlContent: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
  User?: Maybe<User>;
  clinic?: Maybe<Clinic>;
  organization?: Maybe<Organization>;
};

export type EmailCampaignTemplateCountAggregateOutputType = {
  __typename?: 'EmailCampaignTemplateCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  latUpdatedByUserId: Scalars['Int'];
  name: Scalars['Int'];
  templateAvailability: Scalars['Int'];
  clinicId: Scalars['Int'];
  organizationId: Scalars['Int'];
  htmlContent: Scalars['Int'];
  unlayerJson: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EmailCampaignTemplateCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  templateAvailability?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
};

export type EmailCampaignTemplateCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  htmlContent: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
  User?: Maybe<UserCreateNestedOneWithoutEmailCampaignTemplateInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutEmailCampaignTemplateInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutEmailCampaignTemplateInput>;
};

export type EmailCampaignTemplateCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  organizationId?: Maybe<Scalars['String']>;
  htmlContent: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateCreateManyClinicInputEnvelope = {
  data: Array<EmailCampaignTemplateCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignTemplateCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  clinicId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  htmlContent: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateCreateManyOrganizationInputEnvelope = {
  data: Array<EmailCampaignTemplateCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignTemplateCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  clinicId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  htmlContent: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateCreateManyUserInputEnvelope = {
  data: Array<EmailCampaignTemplateCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignTemplateCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
};

export type EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
};

export type EmailCampaignTemplateCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
};

export type EmailCampaignTemplateCreateOrConnectWithoutClinicInput = {
  where: EmailCampaignTemplateWhereUniqueInput;
  create: EmailCampaignTemplateUncheckedCreateWithoutClinicInput;
};

export type EmailCampaignTemplateCreateOrConnectWithoutOrganizationInput = {
  where: EmailCampaignTemplateWhereUniqueInput;
  create: EmailCampaignTemplateUncheckedCreateWithoutOrganizationInput;
};

export type EmailCampaignTemplateCreateOrConnectWithoutUserInput = {
  where: EmailCampaignTemplateWhereUniqueInput;
  create: EmailCampaignTemplateUncheckedCreateWithoutUserInput;
};

export type EmailCampaignTemplateCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  htmlContent: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
  User?: Maybe<UserCreateNestedOneWithoutEmailCampaignTemplateInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutEmailCampaignTemplateInput>;
};

export type EmailCampaignTemplateCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  htmlContent: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
  User?: Maybe<UserCreateNestedOneWithoutEmailCampaignTemplateInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutEmailCampaignTemplateInput>;
};

export type EmailCampaignTemplateCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  htmlContent: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutEmailCampaignTemplateInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutEmailCampaignTemplateInput>;
};

export type EmailCampaignTemplateListRelationFilter = {
  every?: Maybe<EmailCampaignTemplateWhereInput>;
  some?: Maybe<EmailCampaignTemplateWhereInput>;
  none?: Maybe<EmailCampaignTemplateWhereInput>;
};

export type EmailCampaignTemplateMaxAggregateOutputType = {
  __typename?: 'EmailCampaignTemplateMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  clinicId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
};

export type EmailCampaignTemplateMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  templateAvailability?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
};

export type EmailCampaignTemplateMinAggregateOutputType = {
  __typename?: 'EmailCampaignTemplateMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  clinicId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
};

export type EmailCampaignTemplateMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  templateAvailability?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
};

export type EmailCampaignTemplateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailCampaignTemplateOrderByRelevanceFieldEnum {
  Id = 'id',
  LatUpdatedByUserId = 'latUpdatedByUserId',
  Name = 'name',
  ClinicId = 'clinicId',
  OrganizationId = 'organizationId',
  HtmlContent = 'htmlContent'
}

export type EmailCampaignTemplateOrderByRelevanceInput = {
  fields: Array<EmailCampaignTemplateOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EmailCampaignTemplateOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  templateAvailability?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  _count?: Maybe<EmailCampaignTemplateCountOrderByAggregateInput>;
  _max?: Maybe<EmailCampaignTemplateMaxOrderByAggregateInput>;
  _min?: Maybe<EmailCampaignTemplateMinOrderByAggregateInput>;
};

export type EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  templateAvailability?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  User?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<EmailCampaignTemplateOrderByRelevanceInput>;
};

export enum EmailCampaignTemplateScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  LatUpdatedByUserId = 'latUpdatedByUserId',
  Name = 'name',
  TemplateAvailability = 'templateAvailability',
  ClinicId = 'clinicId',
  OrganizationId = 'organizationId',
  HtmlContent = 'htmlContent',
  UnlayerJson = 'unlayerJson'
}

export type EmailCampaignTemplateScalarWhereInput = {
  AND?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
  OR?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  latUpdatedByUserId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  templateAvailability?: Maybe<EnumTemplateAvailabilityFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  htmlContent?: Maybe<StringFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
};

export type EmailCampaignTemplateScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailCampaignTemplateScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailCampaignTemplateScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailCampaignTemplateScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  latUpdatedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  templateAvailability?: Maybe<EnumTemplateAvailabilityWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  organizationId?: Maybe<StringNullableWithAggregatesFilter>;
  htmlContent?: Maybe<StringWithAggregatesFilter>;
  unlayerJson?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type EmailCampaignTemplateUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  clinicId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  htmlContent: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
};

export type EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
};

export type EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
};

export type EmailCampaignTemplateUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  organizationId?: Maybe<Scalars['String']>;
  htmlContent: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateUncheckedCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  clinicId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  htmlContent: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  clinicId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  clinicId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignTemplateUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
};

export type EmailCampaignTemplateUncheckedUpdateManyWithoutEmailCampaignTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  organizationId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<EmailCampaignTemplateUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignTemplateUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<EmailCampaignTemplateUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
};

export type EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<EmailCampaignTemplateUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyUserInputEnvelope>;
  set?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignTemplateUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignTemplateUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
};

export type EmailCampaignTemplateUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  organizationId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateUncheckedUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  clinicId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  User?: Maybe<UserUpdateOneWithoutEmailCampaignTemplateNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutEmailCampaignTemplateNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutEmailCampaignTemplateNestedInput>;
};

export type EmailCampaignTemplateUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type EmailCampaignTemplateUpdateManyWithWhereWithoutClinicInput = {
  where: EmailCampaignTemplateScalarWhereInput;
  data: EmailCampaignTemplateUncheckedUpdateManyWithoutEmailCampaignTemplateInput;
};

export type EmailCampaignTemplateUpdateManyWithWhereWithoutOrganizationInput = {
  where: EmailCampaignTemplateScalarWhereInput;
  data: EmailCampaignTemplateUncheckedUpdateManyWithoutEmailCampaignTemplateInput;
};

export type EmailCampaignTemplateUpdateManyWithWhereWithoutUserInput = {
  where: EmailCampaignTemplateScalarWhereInput;
  data: EmailCampaignTemplateUncheckedUpdateManyWithoutEmailCampaignTemplateInput;
};

export type EmailCampaignTemplateUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignTemplateUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
};

export type EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<EmailCampaignTemplateUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignTemplateUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<EmailCampaignTemplateUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
};

export type EmailCampaignTemplateUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<EmailCampaignTemplateUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyUserInputEnvelope>;
  set?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignTemplateUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignTemplateUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
};

export type EmailCampaignTemplateUpdateWithWhereUniqueWithoutClinicInput = {
  where: EmailCampaignTemplateWhereUniqueInput;
  data: EmailCampaignTemplateUncheckedUpdateWithoutClinicInput;
};

export type EmailCampaignTemplateUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: EmailCampaignTemplateWhereUniqueInput;
  data: EmailCampaignTemplateUncheckedUpdateWithoutOrganizationInput;
};

export type EmailCampaignTemplateUpdateWithWhereUniqueWithoutUserInput = {
  where: EmailCampaignTemplateWhereUniqueInput;
  data: EmailCampaignTemplateUncheckedUpdateWithoutUserInput;
};

export type EmailCampaignTemplateUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  User?: Maybe<UserUpdateOneWithoutEmailCampaignTemplateNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutEmailCampaignTemplateNestedInput>;
};

export type EmailCampaignTemplateUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  User?: Maybe<UserUpdateOneWithoutEmailCampaignTemplateNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutEmailCampaignTemplateNestedInput>;
};

export type EmailCampaignTemplateUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutEmailCampaignTemplateNestedInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutEmailCampaignTemplateNestedInput>;
};

export type EmailCampaignTemplateUpsertWithWhereUniqueWithoutClinicInput = {
  where: EmailCampaignTemplateWhereUniqueInput;
  update: EmailCampaignTemplateUncheckedUpdateWithoutClinicInput;
  create: EmailCampaignTemplateUncheckedCreateWithoutClinicInput;
};

export type EmailCampaignTemplateUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: EmailCampaignTemplateWhereUniqueInput;
  update: EmailCampaignTemplateUncheckedUpdateWithoutOrganizationInput;
  create: EmailCampaignTemplateUncheckedCreateWithoutOrganizationInput;
};

export type EmailCampaignTemplateUpsertWithWhereUniqueWithoutUserInput = {
  where: EmailCampaignTemplateWhereUniqueInput;
  update: EmailCampaignTemplateUncheckedUpdateWithoutUserInput;
  create: EmailCampaignTemplateUncheckedCreateWithoutUserInput;
};

export type EmailCampaignTemplateWhereInput = {
  AND?: Maybe<Array<EmailCampaignTemplateWhereInput>>;
  OR?: Maybe<Array<EmailCampaignTemplateWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignTemplateWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  latUpdatedByUserId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  templateAvailability?: Maybe<EnumTemplateAvailabilityFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  htmlContent?: Maybe<StringFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
  User?: Maybe<UserWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  organization?: Maybe<OrganizationWhereInput>;
};

export type EmailCampaignTemplateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum EmailCampaignType {
  InformScheduledCampaign = 'InformScheduledCampaign',
  ClinicEmailCampaign = 'ClinicEmailCampaign'
}

export type EmailCampaignUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
};

export type EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutCreatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyCreatedByUserInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
};

export type EmailCampaignUncheckedCreateNestedManyWithoutInformCampaignExecutionInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutInformCampaignExecutionInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutInformCampaignExecutionInput>>;
  createMany?: Maybe<EmailCampaignCreateManyInformCampaignExecutionInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
};

export type EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutLastUpdatedByUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutLastUpdatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyLastUpdatedByUserInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
};

export type EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutSentByUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutSentByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManySentByUserInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
};

export type EmailCampaignUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
};

export type EmailCampaignUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignUncheckedCreateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignUncheckedCreateWithoutCustomListInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
};

export type EmailCampaignUncheckedCreateWithoutEmailCampaignLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignUncheckedCreateWithoutEmailCampaignUnsubscribesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignUncheckedCreateWithoutInformCampaignExecutionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignUncheckedCreateWithoutLastUpdatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignUncheckedCreateWithoutSentByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
};

export type EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
};

export type EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutCreatedByUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutCreatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyCreatedByUserInputEnvelope>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutCreatedByUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutCreatedByUserInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
};

export type EmailCampaignUncheckedUpdateManyWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
};

export type EmailCampaignUncheckedUpdateManyWithoutEmailCampaignUpdatedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
};

export type EmailCampaignUncheckedUpdateManyWithoutEmailCampaignsCreatedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
};

export type EmailCampaignUncheckedUpdateManyWithoutEmailCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
};

export type EmailCampaignUncheckedUpdateManyWithoutEmailCampaignsSentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
};

export type EmailCampaignUncheckedUpdateManyWithoutInformCampaignExecutionNestedInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutInformCampaignExecutionInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutInformCampaignExecutionInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutInformCampaignExecutionInput>>;
  createMany?: Maybe<EmailCampaignCreateManyInformCampaignExecutionInputEnvelope>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutInformCampaignExecutionInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutInformCampaignExecutionInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
};

export type EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutLastUpdatedByUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutLastUpdatedByUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutLastUpdatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyLastUpdatedByUserInputEnvelope>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutLastUpdatedByUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutLastUpdatedByUserInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
};

export type EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutSentByUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutSentByUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutSentByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManySentByUserInputEnvelope>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutSentByUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutSentByUserInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
};

export type EmailCampaignUncheckedUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyUserInputEnvelope>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
};

export type EmailCampaignUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUncheckedUpdateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUncheckedUpdateWithoutCustomListInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUncheckedUpdateWithoutEmailCampaignLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUncheckedUpdateWithoutEmailCampaignUnsubscribesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUncheckedUpdateWithoutInformCampaignExecutionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUncheckedUpdateWithoutLastUpdatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUncheckedUpdateWithoutSentByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  userId?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  createdByUserId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUnsubscribe = {
  __typename?: 'EmailCampaignUnsubscribe';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail: Scalars['String'];
  source: EmailCampaignUnsubscribeSource;
  emailCampaign?: Maybe<EmailCampaign>;
  clinic?: Maybe<Clinic>;
  clinicPetParent?: Maybe<ClinicPetParent>;
};

export type EmailCampaignUnsubscribeCountAggregateOutputType = {
  __typename?: 'EmailCampaignUnsubscribeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  resubscribedAt: Scalars['Int'];
  emailCampaignId: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  targetEmail: Scalars['Int'];
  source: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EmailCampaignUnsubscribeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  resubscribedAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type EmailCampaignUnsubscribeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  targetEmail: Scalars['String'];
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedOneWithoutEmailCampaignUnsubscribesInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutEmailCampaignUnsubscribeInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutEmailCampaignUnsubscribeInput>;
};

export type EmailCampaignUnsubscribeCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail: Scalars['String'];
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeCreateManyClinicInputEnvelope = {
  data: Array<EmailCampaignUnsubscribeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignUnsubscribeCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  targetEmail: Scalars['String'];
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeCreateManyClinicPetParentInputEnvelope = {
  data: Array<EmailCampaignUnsubscribeCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignUnsubscribeCreateManyEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail: Scalars['String'];
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeCreateManyEmailCampaignInputEnvelope = {
  data: Array<EmailCampaignUnsubscribeCreateManyEmailCampaignInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignUnsubscribeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail: Scalars['String'];
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
};

export type EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
};

export type EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput = {
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutEmailCampaignInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyEmailCampaignInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
};

export type EmailCampaignUnsubscribeCreateOrConnectWithoutClinicInput = {
  where: EmailCampaignUnsubscribeWhereUniqueInput;
  create: EmailCampaignUnsubscribeUncheckedCreateWithoutClinicInput;
};

export type EmailCampaignUnsubscribeCreateOrConnectWithoutClinicPetParentInput = {
  where: EmailCampaignUnsubscribeWhereUniqueInput;
  create: EmailCampaignUnsubscribeUncheckedCreateWithoutClinicPetParentInput;
};

export type EmailCampaignUnsubscribeCreateOrConnectWithoutEmailCampaignInput = {
  where: EmailCampaignUnsubscribeWhereUniqueInput;
  create: EmailCampaignUnsubscribeUncheckedCreateWithoutEmailCampaignInput;
};

export type EmailCampaignUnsubscribeCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  targetEmail: Scalars['String'];
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedOneWithoutEmailCampaignUnsubscribesInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutEmailCampaignUnsubscribeInput>;
};

export type EmailCampaignUnsubscribeCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  targetEmail: Scalars['String'];
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedOneWithoutEmailCampaignUnsubscribesInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutEmailCampaignUnsubscribeInput>;
};

export type EmailCampaignUnsubscribeCreateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  targetEmail: Scalars['String'];
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutEmailCampaignUnsubscribeInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutEmailCampaignUnsubscribeInput>;
};

export type EmailCampaignUnsubscribeListRelationFilter = {
  every?: Maybe<EmailCampaignUnsubscribeWhereInput>;
  some?: Maybe<EmailCampaignUnsubscribeWhereInput>;
  none?: Maybe<EmailCampaignUnsubscribeWhereInput>;
};

export type EmailCampaignUnsubscribeMaxAggregateOutputType = {
  __typename?: 'EmailCampaignUnsubscribeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  resubscribedAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type EmailCampaignUnsubscribeMinAggregateOutputType = {
  __typename?: 'EmailCampaignUnsubscribeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  resubscribedAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type EmailCampaignUnsubscribeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailCampaignUnsubscribeOrderByRelevanceFieldEnum {
  Id = 'id',
  EmailCampaignId = 'emailCampaignId',
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  TargetEmail = 'targetEmail'
}

export type EmailCampaignUnsubscribeOrderByRelevanceInput = {
  fields: Array<EmailCampaignUnsubscribeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EmailCampaignUnsubscribeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  resubscribedAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  _count?: Maybe<EmailCampaignUnsubscribeCountOrderByAggregateInput>;
  _max?: Maybe<EmailCampaignUnsubscribeMaxOrderByAggregateInput>;
  _min?: Maybe<EmailCampaignUnsubscribeMinOrderByAggregateInput>;
};

export type EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  resubscribedAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  emailCampaign?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<EmailCampaignUnsubscribeOrderByRelevanceInput>;
};

export enum EmailCampaignUnsubscribeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ResubscribedAt = 'resubscribedAt',
  EmailCampaignId = 'emailCampaignId',
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  TargetEmail = 'targetEmail',
  Source = 'source'
}

export type EmailCampaignUnsubscribeScalarWhereInput = {
  AND?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
  OR?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  resubscribedAt?: Maybe<DateTimeNullableFilter>;
  emailCampaignId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  targetEmail?: Maybe<StringFilter>;
  source?: Maybe<EnumEmailCampaignUnsubscribeSourceFilter>;
};

export type EmailCampaignUnsubscribeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  resubscribedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  emailCampaignId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  targetEmail?: Maybe<StringWithAggregatesFilter>;
  source?: Maybe<EnumEmailCampaignUnsubscribeSourceWithAggregatesFilter>;
};

export enum EmailCampaignUnsubscribeSource {
  Unsubscribe = 'Unsubscribe',
  SpamReport = 'SpamReport',
  Bounce = 'Bounce'
}

export type EmailCampaignUnsubscribeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail: Scalars['String'];
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
};

export type EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
};

export type EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput = {
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutEmailCampaignInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyEmailCampaignInputEnvelope>;
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
};

export type EmailCampaignUnsubscribeUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail: Scalars['String'];
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  targetEmail: Scalars['String'];
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeUncheckedCreateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail: Scalars['String'];
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignUnsubscribeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailCampaignUnsubscribeUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput = {
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutEmailCampaignInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutEmailCampaignInput>>;
  upsert?: Maybe<Array<EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyEmailCampaignInputEnvelope>;
  set?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutEmailCampaignInput>>;
  updateMany?: Maybe<Array<EmailCampaignUnsubscribeUpdateManyWithWhereWithoutEmailCampaignInput>>;
  deleteMany?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignUnsubscribeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignUnsubscribesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  targetEmail?: Maybe<Scalars['String']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  targetEmail?: Maybe<Scalars['String']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneWithoutEmailCampaignUnsubscribesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutEmailCampaignUnsubscribeNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutEmailCampaignUnsubscribeNestedInput>;
};

export type EmailCampaignUnsubscribeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  targetEmail?: Maybe<Scalars['String']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EmailCampaignUnsubscribeUpdateManyWithWhereWithoutClinicInput = {
  where: EmailCampaignUnsubscribeScalarWhereInput;
  data: EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignUnsubscribeInput;
};

export type EmailCampaignUnsubscribeUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: EmailCampaignUnsubscribeScalarWhereInput;
  data: EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignUnsubscribeInput;
};

export type EmailCampaignUnsubscribeUpdateManyWithWhereWithoutEmailCampaignInput = {
  where: EmailCampaignUnsubscribeScalarWhereInput;
  data: EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignUnsubscribesInput;
};

export type EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignUnsubscribeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
};

export type EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailCampaignUnsubscribeUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
};

export type EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput = {
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutEmailCampaignInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutEmailCampaignInput>>;
  upsert?: Maybe<Array<EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyEmailCampaignInputEnvelope>;
  set?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutEmailCampaignInput>>;
  updateMany?: Maybe<Array<EmailCampaignUnsubscribeUpdateManyWithWhereWithoutEmailCampaignInput>>;
  deleteMany?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
};

export type EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutClinicInput = {
  where: EmailCampaignUnsubscribeWhereUniqueInput;
  data: EmailCampaignUnsubscribeUncheckedUpdateWithoutClinicInput;
};

export type EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: EmailCampaignUnsubscribeWhereUniqueInput;
  data: EmailCampaignUnsubscribeUncheckedUpdateWithoutClinicPetParentInput;
};

export type EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutEmailCampaignInput = {
  where: EmailCampaignUnsubscribeWhereUniqueInput;
  data: EmailCampaignUnsubscribeUncheckedUpdateWithoutEmailCampaignInput;
};

export type EmailCampaignUnsubscribeUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  targetEmail?: Maybe<Scalars['String']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneWithoutEmailCampaignUnsubscribesNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutEmailCampaignUnsubscribeNestedInput>;
};

export type EmailCampaignUnsubscribeUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  targetEmail?: Maybe<Scalars['String']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneWithoutEmailCampaignUnsubscribesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutEmailCampaignUnsubscribeNestedInput>;
};

export type EmailCampaignUnsubscribeUpdateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  targetEmail?: Maybe<Scalars['String']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  clinic?: Maybe<ClinicUpdateOneWithoutEmailCampaignUnsubscribeNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutEmailCampaignUnsubscribeNestedInput>;
};

export type EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutClinicInput = {
  where: EmailCampaignUnsubscribeWhereUniqueInput;
  update: EmailCampaignUnsubscribeUncheckedUpdateWithoutClinicInput;
  create: EmailCampaignUnsubscribeUncheckedCreateWithoutClinicInput;
};

export type EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: EmailCampaignUnsubscribeWhereUniqueInput;
  update: EmailCampaignUnsubscribeUncheckedUpdateWithoutClinicPetParentInput;
  create: EmailCampaignUnsubscribeUncheckedCreateWithoutClinicPetParentInput;
};

export type EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutEmailCampaignInput = {
  where: EmailCampaignUnsubscribeWhereUniqueInput;
  update: EmailCampaignUnsubscribeUncheckedUpdateWithoutEmailCampaignInput;
  create: EmailCampaignUnsubscribeUncheckedCreateWithoutEmailCampaignInput;
};

export type EmailCampaignUnsubscribeWhereInput = {
  AND?: Maybe<Array<EmailCampaignUnsubscribeWhereInput>>;
  OR?: Maybe<Array<EmailCampaignUnsubscribeWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignUnsubscribeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  resubscribedAt?: Maybe<DateTimeNullableFilter>;
  emailCampaignId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  targetEmail?: Maybe<StringFilter>;
  source?: Maybe<EnumEmailCampaignUnsubscribeSourceFilter>;
  emailCampaign?: Maybe<EmailCampaignWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
};

export type EmailCampaignUnsubscribeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EmailCampaignUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
  typedSegmentArgs?: Maybe<SegmentsArgsInput>;
};

export type EmailCampaignUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
};

export type EmailCampaignUpdateManyWithWhereWithoutClinicInput = {
  where: EmailCampaignScalarWhereInput;
  data: EmailCampaignUncheckedUpdateManyWithoutEmailCampaignInput;
};

export type EmailCampaignUpdateManyWithWhereWithoutCreatedByUserInput = {
  where: EmailCampaignScalarWhereInput;
  data: EmailCampaignUncheckedUpdateManyWithoutEmailCampaignsCreatedInput;
};

export type EmailCampaignUpdateManyWithWhereWithoutInformCampaignExecutionInput = {
  where: EmailCampaignScalarWhereInput;
  data: EmailCampaignUncheckedUpdateManyWithoutEmailCampaignsInput;
};

export type EmailCampaignUpdateManyWithWhereWithoutLastUpdatedByUserInput = {
  where: EmailCampaignScalarWhereInput;
  data: EmailCampaignUncheckedUpdateManyWithoutEmailCampaignUpdatedInput;
};

export type EmailCampaignUpdateManyWithWhereWithoutSentByUserInput = {
  where: EmailCampaignScalarWhereInput;
  data: EmailCampaignUncheckedUpdateManyWithoutEmailCampaignsSentInput;
};

export type EmailCampaignUpdateManyWithWhereWithoutUserInput = {
  where: EmailCampaignScalarWhereInput;
  data: EmailCampaignUncheckedUpdateManyWithoutEmailCampaignInput;
};

export type EmailCampaignUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
};

export type EmailCampaignUpdateManyWithoutCreatedByUserNestedInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutCreatedByUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutCreatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyCreatedByUserInputEnvelope>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutCreatedByUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutCreatedByUserInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
};

export type EmailCampaignUpdateManyWithoutInformCampaignExecutionNestedInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutInformCampaignExecutionInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutInformCampaignExecutionInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutInformCampaignExecutionInput>>;
  createMany?: Maybe<EmailCampaignCreateManyInformCampaignExecutionInputEnvelope>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutInformCampaignExecutionInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutInformCampaignExecutionInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
};

export type EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutLastUpdatedByUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutLastUpdatedByUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutLastUpdatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyLastUpdatedByUserInputEnvelope>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutLastUpdatedByUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutLastUpdatedByUserInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
};

export type EmailCampaignUpdateManyWithoutSentByUserNestedInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutSentByUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutSentByUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutSentByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManySentByUserInputEnvelope>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutSentByUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutSentByUserInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
};

export type EmailCampaignUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<EmailCampaignCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyUserInputEnvelope>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
};

export type EmailCampaignUpdateOneRequiredWithoutCustomListNestedInput = {
  create?: Maybe<EmailCampaignUncheckedCreateWithoutCustomListInput>;
  connectOrCreate?: Maybe<EmailCampaignCreateOrConnectWithoutCustomListInput>;
  upsert?: Maybe<EmailCampaignUpsertWithoutCustomListInput>;
  connect?: Maybe<EmailCampaignWhereUniqueInput>;
  update?: Maybe<EmailCampaignUncheckedUpdateWithoutCustomListInput>;
};

export type EmailCampaignUpdateOneRequiredWithoutEmailCampaignLogsNestedInput = {
  create?: Maybe<EmailCampaignUncheckedCreateWithoutEmailCampaignLogsInput>;
  connectOrCreate?: Maybe<EmailCampaignCreateOrConnectWithoutEmailCampaignLogsInput>;
  upsert?: Maybe<EmailCampaignUpsertWithoutEmailCampaignLogsInput>;
  connect?: Maybe<EmailCampaignWhereUniqueInput>;
  update?: Maybe<EmailCampaignUncheckedUpdateWithoutEmailCampaignLogsInput>;
};

export type EmailCampaignUpdateOneWithoutEmailCampaignUnsubscribesNestedInput = {
  create?: Maybe<EmailCampaignUncheckedCreateWithoutEmailCampaignUnsubscribesInput>;
  connectOrCreate?: Maybe<EmailCampaignCreateOrConnectWithoutEmailCampaignUnsubscribesInput>;
  upsert?: Maybe<EmailCampaignUpsertWithoutEmailCampaignUnsubscribesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<EmailCampaignWhereUniqueInput>;
  update?: Maybe<EmailCampaignUncheckedUpdateWithoutEmailCampaignUnsubscribesInput>;
};

export type EmailCampaignUpdateWithWhereUniqueWithoutClinicInput = {
  where: EmailCampaignWhereUniqueInput;
  data: EmailCampaignUncheckedUpdateWithoutClinicInput;
};

export type EmailCampaignUpdateWithWhereUniqueWithoutCreatedByUserInput = {
  where: EmailCampaignWhereUniqueInput;
  data: EmailCampaignUncheckedUpdateWithoutCreatedByUserInput;
};

export type EmailCampaignUpdateWithWhereUniqueWithoutInformCampaignExecutionInput = {
  where: EmailCampaignWhereUniqueInput;
  data: EmailCampaignUncheckedUpdateWithoutInformCampaignExecutionInput;
};

export type EmailCampaignUpdateWithWhereUniqueWithoutLastUpdatedByUserInput = {
  where: EmailCampaignWhereUniqueInput;
  data: EmailCampaignUncheckedUpdateWithoutLastUpdatedByUserInput;
};

export type EmailCampaignUpdateWithWhereUniqueWithoutSentByUserInput = {
  where: EmailCampaignWhereUniqueInput;
  data: EmailCampaignUncheckedUpdateWithoutSentByUserInput;
};

export type EmailCampaignUpdateWithWhereUniqueWithoutUserInput = {
  where: EmailCampaignWhereUniqueInput;
  data: EmailCampaignUncheckedUpdateWithoutUserInput;
};

export type EmailCampaignUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutCustomListInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
};

export type EmailCampaignUpdateWithoutEmailCampaignLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutEmailCampaignUnsubscribesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutInformCampaignExecutionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutLastUpdatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutSentByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EmailCampaignType>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  contexts?: Maybe<Scalars['Json']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpsertWithWhereUniqueWithoutClinicInput = {
  where: EmailCampaignWhereUniqueInput;
  update: EmailCampaignUncheckedUpdateWithoutClinicInput;
  create: EmailCampaignUncheckedCreateWithoutClinicInput;
};

export type EmailCampaignUpsertWithWhereUniqueWithoutCreatedByUserInput = {
  where: EmailCampaignWhereUniqueInput;
  update: EmailCampaignUncheckedUpdateWithoutCreatedByUserInput;
  create: EmailCampaignUncheckedCreateWithoutCreatedByUserInput;
};

export type EmailCampaignUpsertWithWhereUniqueWithoutInformCampaignExecutionInput = {
  where: EmailCampaignWhereUniqueInput;
  update: EmailCampaignUncheckedUpdateWithoutInformCampaignExecutionInput;
  create: EmailCampaignUncheckedCreateWithoutInformCampaignExecutionInput;
};

export type EmailCampaignUpsertWithWhereUniqueWithoutLastUpdatedByUserInput = {
  where: EmailCampaignWhereUniqueInput;
  update: EmailCampaignUncheckedUpdateWithoutLastUpdatedByUserInput;
  create: EmailCampaignUncheckedCreateWithoutLastUpdatedByUserInput;
};

export type EmailCampaignUpsertWithWhereUniqueWithoutSentByUserInput = {
  where: EmailCampaignWhereUniqueInput;
  update: EmailCampaignUncheckedUpdateWithoutSentByUserInput;
  create: EmailCampaignUncheckedCreateWithoutSentByUserInput;
};

export type EmailCampaignUpsertWithWhereUniqueWithoutUserInput = {
  where: EmailCampaignWhereUniqueInput;
  update: EmailCampaignUncheckedUpdateWithoutUserInput;
  create: EmailCampaignUncheckedCreateWithoutUserInput;
};

export type EmailCampaignUpsertWithoutCustomListInput = {
  update: EmailCampaignUncheckedUpdateWithoutCustomListInput;
  create: EmailCampaignUncheckedCreateWithoutCustomListInput;
};

export type EmailCampaignUpsertWithoutEmailCampaignLogsInput = {
  update: EmailCampaignUncheckedUpdateWithoutEmailCampaignLogsInput;
  create: EmailCampaignUncheckedCreateWithoutEmailCampaignLogsInput;
};

export type EmailCampaignUpsertWithoutEmailCampaignUnsubscribesInput = {
  update: EmailCampaignUncheckedUpdateWithoutEmailCampaignUnsubscribesInput;
  create: EmailCampaignUncheckedCreateWithoutEmailCampaignUnsubscribesInput;
};

export type EmailCampaignWhereInput = {
  AND?: Maybe<Array<EmailCampaignWhereInput>>;
  OR?: Maybe<Array<EmailCampaignWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  startsAt?: Maybe<DateTimeNullableFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<EnumEmailCampaignTypeFilter>;
  informCampaignExecutionId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumEmailCampaignStatusFilter>;
  subject?: Maybe<StringNullableFilter>;
  from?: Maybe<StringFilter>;
  replyTo?: Maybe<StringNullableFilter>;
  replyToJson?: Maybe<JsonNullableFilter>;
  createdByUserId?: Maybe<StringNullableFilter>;
  latUpdatedByUserId?: Maybe<StringNullableFilter>;
  sentByUserId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  htmlContent?: Maybe<StringNullableFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
  scheduledAt?: Maybe<DateTimeNullableFilter>;
  startedAt?: Maybe<DateTimeNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  totalSegmentContacts?: Maybe<IntNullableFilter>;
  segmentWhere?: Maybe<JsonNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  contexts?: Maybe<JsonNullableFilter>;
  segmentArgs?: Maybe<JsonNullableFilter>;
  segmentationType?: Maybe<EnumEmailCampaignSegmentationTypeFilter>;
  segmentationDefinition?: Maybe<JsonNullableFilter>;
  createdByUser?: Maybe<UserWhereInput>;
  lastUpdatedByUser?: Maybe<UserWhereInput>;
  sentByUser?: Maybe<UserWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  emailCampaignLogs?: Maybe<EmailCampaignLogListRelationFilter>;
  user?: Maybe<UserWhereInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeListRelationFilter>;
  informCampaignExecution?: Maybe<InformCampaignExecutionWhereInput>;
  customList?: Maybe<EmailCampaignCustomListWhereInput>;
};

export type EmailCampaignWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum EmailProvider {
  Sendgrid = 'Sendgrid'
}

export type EmailProviderLog = {
  __typename?: 'EmailProviderLog';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  provider: EmailProvider;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  entityId: Scalars['String'];
  clinicPetParent: ClinicPetParent;
};

export type EmailProviderLogCountAggregateOutputType = {
  __typename?: 'EmailProviderLogCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  provider: Scalars['Int'];
  providerEmailTemplateId: Scalars['Int'];
  providerMessageId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  entityType: Scalars['Int'];
  entityId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EmailProviderLogCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
};

export type EmailProviderLogCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  entityId: Scalars['String'];
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutEmailProviderLogsInput;
};

export type EmailProviderLogCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  entityId: Scalars['String'];
};

export type EmailProviderLogCreateManyClinicPetParentInputEnvelope = {
  data: Array<EmailProviderLogCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailProviderLogCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  entityId: Scalars['String'];
};

export type EmailProviderLogCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<EmailProviderLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailProviderLogCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailProviderLogCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
};

export type EmailProviderLogCreateOrConnectWithoutClinicPetParentInput = {
  where: EmailProviderLogWhereUniqueInput;
  create: EmailProviderLogUncheckedCreateWithoutClinicPetParentInput;
};

export type EmailProviderLogCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  entityId: Scalars['String'];
};

export enum EmailProviderLogEntityType {
  Appointment = 'Appointment',
  AutomationRun = 'AutomationRun',
  Channel = 'Channel',
  ChannelMessage = 'ChannelMessage',
  ClinicWidgetRequest = 'ClinicWidgetRequest',
  ClinicPetParent = 'ClinicPetParent',
  ServiceReminderNotification = 'ServiceReminderNotification',
  StripeRefund = 'StripeRefund'
}

export type EmailProviderLogListRelationFilter = {
  every?: Maybe<EmailProviderLogWhereInput>;
  some?: Maybe<EmailProviderLogWhereInput>;
  none?: Maybe<EmailProviderLogWhereInput>;
};

export type EmailProviderLogMaxAggregateOutputType = {
  __typename?: 'EmailProviderLogMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
};

export type EmailProviderLogMinAggregateOutputType = {
  __typename?: 'EmailProviderLogMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
};

export type EmailProviderLogOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailProviderLogOrderByRelevanceFieldEnum {
  Id = 'id',
  ProviderEmailTemplateId = 'providerEmailTemplateId',
  ProviderMessageId = 'providerMessageId',
  ClinicPetParentId = 'clinicPetParentId',
  EntityId = 'entityId'
}

export type EmailProviderLogOrderByRelevanceInput = {
  fields: Array<EmailProviderLogOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EmailProviderLogOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  _count?: Maybe<EmailProviderLogCountOrderByAggregateInput>;
  _max?: Maybe<EmailProviderLogMaxOrderByAggregateInput>;
  _min?: Maybe<EmailProviderLogMinOrderByAggregateInput>;
};

export type EmailProviderLogOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<EmailProviderLogOrderByRelevanceInput>;
};

export enum EmailProviderLogScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Provider = 'provider',
  ProviderEmailTemplateId = 'providerEmailTemplateId',
  ProviderMessageId = 'providerMessageId',
  ClinicPetParentId = 'clinicPetParentId',
  EntityType = 'entityType',
  EntityId = 'entityId'
}

export type EmailProviderLogScalarWhereInput = {
  AND?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
  OR?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
  NOT?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  provider?: Maybe<EnumEmailProviderFilter>;
  providerEmailTemplateId?: Maybe<StringFilter>;
  providerMessageId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  entityType?: Maybe<EnumEmailProviderLogEntityTypeFilter>;
  entityId?: Maybe<StringFilter>;
};

export type EmailProviderLogScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailProviderLogScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailProviderLogScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailProviderLogScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  provider?: Maybe<EnumEmailProviderWithAggregatesFilter>;
  providerEmailTemplateId?: Maybe<StringWithAggregatesFilter>;
  providerMessageId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  entityType?: Maybe<EnumEmailProviderLogEntityTypeWithAggregatesFilter>;
  entityId?: Maybe<StringWithAggregatesFilter>;
};

export type EmailProviderLogUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  entityId: Scalars['String'];
};

export type EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<EmailProviderLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailProviderLogCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailProviderLogCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
};

export type EmailProviderLogUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  entityId: Scalars['String'];
};

export type EmailProviderLogUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<EmailProviderLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailProviderLogCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailProviderLogUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailProviderLogCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  delete?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  connect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailProviderLogUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailProviderLogUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
};

export type EmailProviderLogUncheckedUpdateManyWithoutEmailProviderLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutEmailProviderLogsNestedInput>;
};

export type EmailProviderLogUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: EmailProviderLogScalarWhereInput;
  data: EmailProviderLogUncheckedUpdateManyWithoutEmailProviderLogsInput;
};

export type EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<EmailProviderLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailProviderLogCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailProviderLogUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailProviderLogCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  delete?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  connect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailProviderLogUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailProviderLogUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
};

export type EmailProviderLogUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: EmailProviderLogWhereUniqueInput;
  data: EmailProviderLogUncheckedUpdateWithoutClinicPetParentInput;
};

export type EmailProviderLogUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: EmailProviderLogWhereUniqueInput;
  update: EmailProviderLogUncheckedUpdateWithoutClinicPetParentInput;
  create: EmailProviderLogUncheckedCreateWithoutClinicPetParentInput;
};

export type EmailProviderLogWhereInput = {
  AND?: Maybe<Array<EmailProviderLogWhereInput>>;
  OR?: Maybe<Array<EmailProviderLogWhereInput>>;
  NOT?: Maybe<Array<EmailProviderLogWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  provider?: Maybe<EnumEmailProviderFilter>;
  providerEmailTemplateId?: Maybe<StringFilter>;
  providerMessageId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  entityType?: Maybe<EnumEmailProviderLogEntityTypeFilter>;
  entityId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
};

export type EmailProviderLogWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  type: EmailTemplateType;
  unlayerSettingId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  unlayerSetting: UnlayerSetting;
  emailTemplatesVersions: Array<EmailTemplateVersion>;
  _count: EmailTemplateCountOutputType;
};


export type EmailTemplateEmailTemplatesVersionsArgs = {
  where?: Maybe<EmailTemplateVersionWhereInput>;
  orderBy?: Maybe<EmailTemplateVersionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailTemplateVersionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailTemplateVersionScalarFieldEnum>;
};

export type EmailTemplateAvgAggregateOutputType = {
  __typename?: 'EmailTemplateAvgAggregateOutputType';
  unlayerSettingId?: Maybe<Scalars['Float']>;
};

export type EmailTemplateAvgOrderByAggregateInput = {
  unlayerSettingId?: Maybe<SortOrder>;
};

export type EmailTemplateCountAggregateOutputType = {
  __typename?: 'EmailTemplateCountAggregateOutputType';
  id: Scalars['Int'];
  name: Scalars['Int'];
  description: Scalars['Int'];
  type: Scalars['Int'];
  unlayerSettingId: Scalars['Int'];
  isActive: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  lastPublishedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EmailTemplateCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastPublishedAt?: Maybe<SortOrder>;
};

export type EmailTemplateCountOutputType = {
  __typename?: 'EmailTemplateCountOutputType';
  emailTemplatesVersions: Scalars['Int'];
};

export type EmailTemplateCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  type: EmailTemplateType;
  isActive: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  unlayerSetting: UnlayerSettingCreateNestedOneWithoutEmailTemplatesInput;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutEmailTemplateInput>;
};

export type EmailTemplateCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  type: EmailTemplateType;
  unlayerSettingId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateCreateManyUnlayerSettingInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  type: EmailTemplateType;
  isActive: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateCreateManyUnlayerSettingInputEnvelope = {
  data: Array<EmailTemplateCreateManyUnlayerSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailTemplateCreateNestedManyWithoutUnlayerSettingInput = {
  create?: Maybe<Array<EmailTemplateCreateWithoutUnlayerSettingInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateCreateOrConnectWithoutUnlayerSettingInput>>;
  createMany?: Maybe<EmailTemplateCreateManyUnlayerSettingInputEnvelope>;
  connect?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
};

export type EmailTemplateCreateNestedOneWithoutEmailTemplatesVersionsInput = {
  create?: Maybe<EmailTemplateUncheckedCreateWithoutEmailTemplatesVersionsInput>;
  connectOrCreate?: Maybe<EmailTemplateCreateOrConnectWithoutEmailTemplatesVersionsInput>;
  connect?: Maybe<EmailTemplateWhereUniqueInput>;
};

export type EmailTemplateCreateOrConnectWithoutEmailTemplatesVersionsInput = {
  where: EmailTemplateWhereUniqueInput;
  create: EmailTemplateUncheckedCreateWithoutEmailTemplatesVersionsInput;
};

export type EmailTemplateCreateOrConnectWithoutUnlayerSettingInput = {
  where: EmailTemplateWhereUniqueInput;
  create: EmailTemplateUncheckedCreateWithoutUnlayerSettingInput;
};

export type EmailTemplateCreateWithoutEmailTemplatesVersionsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  type: EmailTemplateType;
  isActive: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  unlayerSetting: UnlayerSettingCreateNestedOneWithoutEmailTemplatesInput;
};

export type EmailTemplateCreateWithoutUnlayerSettingInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  type: EmailTemplateType;
  isActive: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutEmailTemplateInput>;
};

export type EmailTemplateListRelationFilter = {
  every?: Maybe<EmailTemplateWhereInput>;
  some?: Maybe<EmailTemplateWhereInput>;
  none?: Maybe<EmailTemplateWhereInput>;
};

export type EmailTemplateMaxAggregateOutputType = {
  __typename?: 'EmailTemplateMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastPublishedAt?: Maybe<SortOrder>;
};

export type EmailTemplateMinAggregateOutputType = {
  __typename?: 'EmailTemplateMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastPublishedAt?: Maybe<SortOrder>;
};

export type EmailTemplateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailTemplateOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  Description = 'description'
}

export type EmailTemplateOrderByRelevanceInput = {
  fields: Array<EmailTemplateOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EmailTemplateOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastPublishedAt?: Maybe<SortOrder>;
  _count?: Maybe<EmailTemplateCountOrderByAggregateInput>;
  _avg?: Maybe<EmailTemplateAvgOrderByAggregateInput>;
  _max?: Maybe<EmailTemplateMaxOrderByAggregateInput>;
  _min?: Maybe<EmailTemplateMinOrderByAggregateInput>;
  _sum?: Maybe<EmailTemplateSumOrderByAggregateInput>;
};

export type EmailTemplateOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastPublishedAt?: Maybe<SortOrder>;
  unlayerSetting?: Maybe<UnlayerSettingOrderByWithRelationAndSearchRelevanceInput>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionOrderByRelationAggregateInput>;
  _relevance?: Maybe<EmailTemplateOrderByRelevanceInput>;
};

export type EmailTemplateRelationFilter = {
  is?: Maybe<EmailTemplateWhereInput>;
  isNot?: Maybe<EmailTemplateWhereInput>;
};

export enum EmailTemplateScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  Type = 'type',
  UnlayerSettingId = 'unlayerSettingId',
  IsActive = 'isActive',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  LastPublishedAt = 'lastPublishedAt'
}

export type EmailTemplateScalarWhereInput = {
  AND?: Maybe<Array<EmailTemplateScalarWhereInput>>;
  OR?: Maybe<Array<EmailTemplateScalarWhereInput>>;
  NOT?: Maybe<Array<EmailTemplateScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  type?: Maybe<EnumEmailTemplateTypeFilter>;
  unlayerSettingId?: Maybe<IntFilter>;
  isActive?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  lastPublishedAt?: Maybe<DateTimeNullableFilter>;
};

export type EmailTemplateScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailTemplateScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailTemplateScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailTemplateScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumEmailTemplateTypeWithAggregatesFilter>;
  unlayerSettingId?: Maybe<IntWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  lastPublishedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
};

export type EmailTemplateSumAggregateOutputType = {
  __typename?: 'EmailTemplateSumAggregateOutputType';
  unlayerSettingId?: Maybe<Scalars['Int']>;
};

export type EmailTemplateSumOrderByAggregateInput = {
  unlayerSettingId?: Maybe<SortOrder>;
};

export enum EmailTemplateType {
  ServiceReminder = 'ServiceReminder'
}

export type EmailTemplateUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  type: EmailTemplateType;
  unlayerSettingId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutEmailTemplateInput>;
};

export type EmailTemplateUncheckedCreateNestedManyWithoutUnlayerSettingInput = {
  create?: Maybe<Array<EmailTemplateCreateWithoutUnlayerSettingInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateCreateOrConnectWithoutUnlayerSettingInput>>;
  createMany?: Maybe<EmailTemplateCreateManyUnlayerSettingInputEnvelope>;
  connect?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
};

export type EmailTemplateUncheckedCreateWithoutEmailTemplatesVersionsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  type: EmailTemplateType;
  unlayerSettingId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUncheckedCreateWithoutUnlayerSettingInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  type: EmailTemplateType;
  isActive: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutEmailTemplateInput>;
};

export type EmailTemplateUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutEmailTemplateNestedInput>;
};

export type EmailTemplateUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUncheckedUpdateManyWithoutEmailTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUncheckedUpdateManyWithoutUnlayerSettingNestedInput = {
  create?: Maybe<Array<EmailTemplateCreateWithoutUnlayerSettingInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateCreateOrConnectWithoutUnlayerSettingInput>>;
  upsert?: Maybe<Array<EmailTemplateUpsertWithWhereUniqueWithoutUnlayerSettingInput>>;
  createMany?: Maybe<EmailTemplateCreateManyUnlayerSettingInputEnvelope>;
  set?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailTemplateUpdateWithWhereUniqueWithoutUnlayerSettingInput>>;
  updateMany?: Maybe<Array<EmailTemplateUpdateManyWithWhereWithoutUnlayerSettingInput>>;
  deleteMany?: Maybe<Array<EmailTemplateScalarWhereInput>>;
};

export type EmailTemplateUncheckedUpdateWithoutEmailTemplatesVersionsInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUncheckedUpdateWithoutUnlayerSettingInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutEmailTemplateNestedInput>;
};

export type EmailTemplateUpdateInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneRequiredWithoutEmailTemplatesNestedInput>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutEmailTemplateNestedInput>;
};

export type EmailTemplateUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUpdateManyWithWhereWithoutUnlayerSettingInput = {
  where: EmailTemplateScalarWhereInput;
  data: EmailTemplateUncheckedUpdateManyWithoutEmailTemplatesInput;
};

export type EmailTemplateUpdateManyWithoutUnlayerSettingNestedInput = {
  create?: Maybe<Array<EmailTemplateCreateWithoutUnlayerSettingInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateCreateOrConnectWithoutUnlayerSettingInput>>;
  upsert?: Maybe<Array<EmailTemplateUpsertWithWhereUniqueWithoutUnlayerSettingInput>>;
  createMany?: Maybe<EmailTemplateCreateManyUnlayerSettingInputEnvelope>;
  set?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailTemplateUpdateWithWhereUniqueWithoutUnlayerSettingInput>>;
  updateMany?: Maybe<Array<EmailTemplateUpdateManyWithWhereWithoutUnlayerSettingInput>>;
  deleteMany?: Maybe<Array<EmailTemplateScalarWhereInput>>;
};

export type EmailTemplateUpdateOneRequiredWithoutEmailTemplatesVersionsNestedInput = {
  create?: Maybe<EmailTemplateUncheckedCreateWithoutEmailTemplatesVersionsInput>;
  connectOrCreate?: Maybe<EmailTemplateCreateOrConnectWithoutEmailTemplatesVersionsInput>;
  upsert?: Maybe<EmailTemplateUpsertWithoutEmailTemplatesVersionsInput>;
  connect?: Maybe<EmailTemplateWhereUniqueInput>;
  update?: Maybe<EmailTemplateUncheckedUpdateWithoutEmailTemplatesVersionsInput>;
};

export type EmailTemplateUpdateWithWhereUniqueWithoutUnlayerSettingInput = {
  where: EmailTemplateWhereUniqueInput;
  data: EmailTemplateUncheckedUpdateWithoutUnlayerSettingInput;
};

export type EmailTemplateUpdateWithoutEmailTemplatesVersionsInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneRequiredWithoutEmailTemplatesNestedInput>;
};

export type EmailTemplateUpdateWithoutUnlayerSettingInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutEmailTemplateNestedInput>;
};

export type EmailTemplateUpsertWithWhereUniqueWithoutUnlayerSettingInput = {
  where: EmailTemplateWhereUniqueInput;
  update: EmailTemplateUncheckedUpdateWithoutUnlayerSettingInput;
  create: EmailTemplateUncheckedCreateWithoutUnlayerSettingInput;
};

export type EmailTemplateUpsertWithoutEmailTemplatesVersionsInput = {
  update: EmailTemplateUncheckedUpdateWithoutEmailTemplatesVersionsInput;
  create: EmailTemplateUncheckedCreateWithoutEmailTemplatesVersionsInput;
};

export type EmailTemplateVersion = {
  __typename?: 'EmailTemplateVersion';
  id: Scalars['String'];
  html: Scalars['String'];
  design: Scalars['Json'];
  previewImageUrl?: Maybe<Scalars['String']>;
  emailTemplateId: Scalars['String'];
  isCurrent: Scalars['Boolean'];
  version: Scalars['Int'];
  lastEditedById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  emailTemplate: EmailTemplate;
  lastEditedBy?: Maybe<User>;
};

export type EmailTemplateVersionAvgAggregateOutputType = {
  __typename?: 'EmailTemplateVersionAvgAggregateOutputType';
  version?: Maybe<Scalars['Float']>;
};

export type EmailTemplateVersionAvgOrderByAggregateInput = {
  version?: Maybe<SortOrder>;
};

export type EmailTemplateVersionCountAggregateOutputType = {
  __typename?: 'EmailTemplateVersionCountAggregateOutputType';
  id: Scalars['Int'];
  html: Scalars['Int'];
  design: Scalars['Int'];
  previewImageUrl: Scalars['Int'];
  emailTemplateId: Scalars['Int'];
  isCurrent: Scalars['Int'];
  version: Scalars['Int'];
  lastEditedById: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  publishedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EmailTemplateVersionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  html?: Maybe<SortOrder>;
  design?: Maybe<SortOrder>;
  previewImageUrl?: Maybe<SortOrder>;
  emailTemplateId?: Maybe<SortOrder>;
  isCurrent?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
  lastEditedById?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  publishedAt?: Maybe<SortOrder>;
};

export type EmailTemplateVersionCreateInput = {
  id?: Maybe<Scalars['String']>;
  html: Scalars['String'];
  design: Scalars['Json'];
  previewImageUrl?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  emailTemplate: EmailTemplateCreateNestedOneWithoutEmailTemplatesVersionsInput;
  lastEditedBy?: Maybe<UserCreateNestedOneWithoutEmailTemplateVersionsInput>;
};

export type EmailTemplateVersionCreateManyEmailTemplateInput = {
  id?: Maybe<Scalars['String']>;
  html: Scalars['String'];
  design: Scalars['Json'];
  previewImageUrl?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  lastEditedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionCreateManyEmailTemplateInputEnvelope = {
  data: Array<EmailTemplateVersionCreateManyEmailTemplateInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailTemplateVersionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  html: Scalars['String'];
  design: Scalars['Json'];
  previewImageUrl?: Maybe<Scalars['String']>;
  emailTemplateId: Scalars['String'];
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  lastEditedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionCreateManyLastEditedByInput = {
  id?: Maybe<Scalars['String']>;
  html: Scalars['String'];
  design: Scalars['Json'];
  previewImageUrl?: Maybe<Scalars['String']>;
  emailTemplateId: Scalars['String'];
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionCreateManyLastEditedByInputEnvelope = {
  data: Array<EmailTemplateVersionCreateManyLastEditedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailTemplateVersionCreateNestedManyWithoutEmailTemplateInput = {
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutEmailTemplateInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutEmailTemplateInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyEmailTemplateInputEnvelope>;
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
};

export type EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput = {
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutLastEditedByInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutLastEditedByInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyLastEditedByInputEnvelope>;
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
};

export type EmailTemplateVersionCreateOrConnectWithoutEmailTemplateInput = {
  where: EmailTemplateVersionWhereUniqueInput;
  create: EmailTemplateVersionUncheckedCreateWithoutEmailTemplateInput;
};

export type EmailTemplateVersionCreateOrConnectWithoutLastEditedByInput = {
  where: EmailTemplateVersionWhereUniqueInput;
  create: EmailTemplateVersionUncheckedCreateWithoutLastEditedByInput;
};

export type EmailTemplateVersionCreateWithoutEmailTemplateInput = {
  id?: Maybe<Scalars['String']>;
  html: Scalars['String'];
  design: Scalars['Json'];
  previewImageUrl?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  lastEditedBy?: Maybe<UserCreateNestedOneWithoutEmailTemplateVersionsInput>;
};

export type EmailTemplateVersionCreateWithoutLastEditedByInput = {
  id?: Maybe<Scalars['String']>;
  html: Scalars['String'];
  design: Scalars['Json'];
  previewImageUrl?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  emailTemplate: EmailTemplateCreateNestedOneWithoutEmailTemplatesVersionsInput;
};

export type EmailTemplateVersionListRelationFilter = {
  every?: Maybe<EmailTemplateVersionWhereInput>;
  some?: Maybe<EmailTemplateVersionWhereInput>;
  none?: Maybe<EmailTemplateVersionWhereInput>;
};

export type EmailTemplateVersionMaxAggregateOutputType = {
  __typename?: 'EmailTemplateVersionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  emailTemplateId?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  lastEditedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  html?: Maybe<SortOrder>;
  previewImageUrl?: Maybe<SortOrder>;
  emailTemplateId?: Maybe<SortOrder>;
  isCurrent?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
  lastEditedById?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  publishedAt?: Maybe<SortOrder>;
};

export type EmailTemplateVersionMinAggregateOutputType = {
  __typename?: 'EmailTemplateVersionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  emailTemplateId?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  lastEditedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  html?: Maybe<SortOrder>;
  previewImageUrl?: Maybe<SortOrder>;
  emailTemplateId?: Maybe<SortOrder>;
  isCurrent?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
  lastEditedById?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  publishedAt?: Maybe<SortOrder>;
};

export type EmailTemplateVersionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailTemplateVersionOrderByRelevanceFieldEnum {
  Id = 'id',
  Html = 'html',
  PreviewImageUrl = 'previewImageUrl',
  EmailTemplateId = 'emailTemplateId',
  LastEditedById = 'lastEditedById'
}

export type EmailTemplateVersionOrderByRelevanceInput = {
  fields: Array<EmailTemplateVersionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EmailTemplateVersionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  html?: Maybe<SortOrder>;
  design?: Maybe<SortOrder>;
  previewImageUrl?: Maybe<SortOrder>;
  emailTemplateId?: Maybe<SortOrder>;
  isCurrent?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
  lastEditedById?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  publishedAt?: Maybe<SortOrder>;
  _count?: Maybe<EmailTemplateVersionCountOrderByAggregateInput>;
  _avg?: Maybe<EmailTemplateVersionAvgOrderByAggregateInput>;
  _max?: Maybe<EmailTemplateVersionMaxOrderByAggregateInput>;
  _min?: Maybe<EmailTemplateVersionMinOrderByAggregateInput>;
  _sum?: Maybe<EmailTemplateVersionSumOrderByAggregateInput>;
};

export type EmailTemplateVersionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  html?: Maybe<SortOrder>;
  design?: Maybe<SortOrder>;
  previewImageUrl?: Maybe<SortOrder>;
  emailTemplateId?: Maybe<SortOrder>;
  isCurrent?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
  lastEditedById?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  publishedAt?: Maybe<SortOrder>;
  emailTemplate?: Maybe<EmailTemplateOrderByWithRelationAndSearchRelevanceInput>;
  lastEditedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<EmailTemplateVersionOrderByRelevanceInput>;
};

export enum EmailTemplateVersionScalarFieldEnum {
  Id = 'id',
  Html = 'html',
  Design = 'design',
  PreviewImageUrl = 'previewImageUrl',
  EmailTemplateId = 'emailTemplateId',
  IsCurrent = 'isCurrent',
  Version = 'version',
  LastEditedById = 'lastEditedById',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  PublishedAt = 'publishedAt'
}

export type EmailTemplateVersionScalarWhereInput = {
  AND?: Maybe<Array<EmailTemplateVersionScalarWhereInput>>;
  OR?: Maybe<Array<EmailTemplateVersionScalarWhereInput>>;
  NOT?: Maybe<Array<EmailTemplateVersionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  html?: Maybe<StringFilter>;
  design?: Maybe<JsonFilter>;
  previewImageUrl?: Maybe<StringNullableFilter>;
  emailTemplateId?: Maybe<StringFilter>;
  isCurrent?: Maybe<BoolFilter>;
  version?: Maybe<IntFilter>;
  lastEditedById?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  publishedAt?: Maybe<DateTimeNullableFilter>;
};

export type EmailTemplateVersionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailTemplateVersionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailTemplateVersionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailTemplateVersionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  html?: Maybe<StringWithAggregatesFilter>;
  design?: Maybe<JsonWithAggregatesFilter>;
  previewImageUrl?: Maybe<StringNullableWithAggregatesFilter>;
  emailTemplateId?: Maybe<StringWithAggregatesFilter>;
  isCurrent?: Maybe<BoolWithAggregatesFilter>;
  version?: Maybe<IntWithAggregatesFilter>;
  lastEditedById?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  publishedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
};

export type EmailTemplateVersionSumAggregateOutputType = {
  __typename?: 'EmailTemplateVersionSumAggregateOutputType';
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionSumOrderByAggregateInput = {
  version?: Maybe<SortOrder>;
};

export type EmailTemplateVersionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  html: Scalars['String'];
  design: Scalars['Json'];
  previewImageUrl?: Maybe<Scalars['String']>;
  emailTemplateId: Scalars['String'];
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  lastEditedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionUncheckedCreateNestedManyWithoutEmailTemplateInput = {
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutEmailTemplateInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutEmailTemplateInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyEmailTemplateInputEnvelope>;
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
};

export type EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput = {
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutLastEditedByInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutLastEditedByInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyLastEditedByInputEnvelope>;
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
};

export type EmailTemplateVersionUncheckedCreateWithoutEmailTemplateInput = {
  id?: Maybe<Scalars['String']>;
  html: Scalars['String'];
  design: Scalars['Json'];
  previewImageUrl?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  lastEditedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionUncheckedCreateWithoutLastEditedByInput = {
  id?: Maybe<Scalars['String']>;
  html: Scalars['String'];
  design: Scalars['Json'];
  previewImageUrl?: Maybe<Scalars['String']>;
  emailTemplateId: Scalars['String'];
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  design?: Maybe<Scalars['Json']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  emailTemplateId?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  lastEditedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  design?: Maybe<Scalars['Json']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  emailTemplateId?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  lastEditedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionUncheckedUpdateManyWithoutEmailTemplateNestedInput = {
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutEmailTemplateInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutEmailTemplateInput>>;
  upsert?: Maybe<Array<EmailTemplateVersionUpsertWithWhereUniqueWithoutEmailTemplateInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyEmailTemplateInputEnvelope>;
  set?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  delete?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  update?: Maybe<Array<EmailTemplateVersionUpdateWithWhereUniqueWithoutEmailTemplateInput>>;
  updateMany?: Maybe<Array<EmailTemplateVersionUpdateManyWithWhereWithoutEmailTemplateInput>>;
  deleteMany?: Maybe<Array<EmailTemplateVersionScalarWhereInput>>;
};

export type EmailTemplateVersionUncheckedUpdateManyWithoutEmailTemplateVersionsInput = {
  id?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  design?: Maybe<Scalars['Json']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  emailTemplateId?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionUncheckedUpdateManyWithoutEmailTemplatesVersionsInput = {
  id?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  design?: Maybe<Scalars['Json']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  lastEditedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput = {
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutLastEditedByInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutLastEditedByInput>>;
  upsert?: Maybe<Array<EmailTemplateVersionUpsertWithWhereUniqueWithoutLastEditedByInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyLastEditedByInputEnvelope>;
  set?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  delete?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  update?: Maybe<Array<EmailTemplateVersionUpdateWithWhereUniqueWithoutLastEditedByInput>>;
  updateMany?: Maybe<Array<EmailTemplateVersionUpdateManyWithWhereWithoutLastEditedByInput>>;
  deleteMany?: Maybe<Array<EmailTemplateVersionScalarWhereInput>>;
};

export type EmailTemplateVersionUncheckedUpdateWithoutEmailTemplateInput = {
  id?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  design?: Maybe<Scalars['Json']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  lastEditedById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionUncheckedUpdateWithoutLastEditedByInput = {
  id?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  design?: Maybe<Scalars['Json']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  emailTemplateId?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  design?: Maybe<Scalars['Json']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  emailTemplate?: Maybe<EmailTemplateUpdateOneRequiredWithoutEmailTemplatesVersionsNestedInput>;
  lastEditedBy?: Maybe<UserUpdateOneWithoutEmailTemplateVersionsNestedInput>;
};

export type EmailTemplateVersionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  design?: Maybe<Scalars['Json']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateVersionUpdateManyWithWhereWithoutEmailTemplateInput = {
  where: EmailTemplateVersionScalarWhereInput;
  data: EmailTemplateVersionUncheckedUpdateManyWithoutEmailTemplatesVersionsInput;
};

export type EmailTemplateVersionUpdateManyWithWhereWithoutLastEditedByInput = {
  where: EmailTemplateVersionScalarWhereInput;
  data: EmailTemplateVersionUncheckedUpdateManyWithoutEmailTemplateVersionsInput;
};

export type EmailTemplateVersionUpdateManyWithoutEmailTemplateNestedInput = {
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutEmailTemplateInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutEmailTemplateInput>>;
  upsert?: Maybe<Array<EmailTemplateVersionUpsertWithWhereUniqueWithoutEmailTemplateInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyEmailTemplateInputEnvelope>;
  set?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  delete?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  update?: Maybe<Array<EmailTemplateVersionUpdateWithWhereUniqueWithoutEmailTemplateInput>>;
  updateMany?: Maybe<Array<EmailTemplateVersionUpdateManyWithWhereWithoutEmailTemplateInput>>;
  deleteMany?: Maybe<Array<EmailTemplateVersionScalarWhereInput>>;
};

export type EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput = {
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutLastEditedByInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutLastEditedByInput>>;
  upsert?: Maybe<Array<EmailTemplateVersionUpsertWithWhereUniqueWithoutLastEditedByInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyLastEditedByInputEnvelope>;
  set?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  delete?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  update?: Maybe<Array<EmailTemplateVersionUpdateWithWhereUniqueWithoutLastEditedByInput>>;
  updateMany?: Maybe<Array<EmailTemplateVersionUpdateManyWithWhereWithoutLastEditedByInput>>;
  deleteMany?: Maybe<Array<EmailTemplateVersionScalarWhereInput>>;
};

export type EmailTemplateVersionUpdateWithWhereUniqueWithoutEmailTemplateInput = {
  where: EmailTemplateVersionWhereUniqueInput;
  data: EmailTemplateVersionUncheckedUpdateWithoutEmailTemplateInput;
};

export type EmailTemplateVersionUpdateWithWhereUniqueWithoutLastEditedByInput = {
  where: EmailTemplateVersionWhereUniqueInput;
  data: EmailTemplateVersionUncheckedUpdateWithoutLastEditedByInput;
};

export type EmailTemplateVersionUpdateWithoutEmailTemplateInput = {
  id?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  design?: Maybe<Scalars['Json']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  lastEditedBy?: Maybe<UserUpdateOneWithoutEmailTemplateVersionsNestedInput>;
};

export type EmailTemplateVersionUpdateWithoutLastEditedByInput = {
  id?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  design?: Maybe<Scalars['Json']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  emailTemplate?: Maybe<EmailTemplateUpdateOneRequiredWithoutEmailTemplatesVersionsNestedInput>;
};

export type EmailTemplateVersionUpsertWithWhereUniqueWithoutEmailTemplateInput = {
  where: EmailTemplateVersionWhereUniqueInput;
  update: EmailTemplateVersionUncheckedUpdateWithoutEmailTemplateInput;
  create: EmailTemplateVersionUncheckedCreateWithoutEmailTemplateInput;
};

export type EmailTemplateVersionUpsertWithWhereUniqueWithoutLastEditedByInput = {
  where: EmailTemplateVersionWhereUniqueInput;
  update: EmailTemplateVersionUncheckedUpdateWithoutLastEditedByInput;
  create: EmailTemplateVersionUncheckedCreateWithoutLastEditedByInput;
};

export type EmailTemplateVersionWhereInput = {
  AND?: Maybe<Array<EmailTemplateVersionWhereInput>>;
  OR?: Maybe<Array<EmailTemplateVersionWhereInput>>;
  NOT?: Maybe<Array<EmailTemplateVersionWhereInput>>;
  id?: Maybe<StringFilter>;
  html?: Maybe<StringFilter>;
  design?: Maybe<JsonFilter>;
  previewImageUrl?: Maybe<StringNullableFilter>;
  emailTemplateId?: Maybe<StringFilter>;
  isCurrent?: Maybe<BoolFilter>;
  version?: Maybe<IntFilter>;
  lastEditedById?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  publishedAt?: Maybe<DateTimeNullableFilter>;
  emailTemplate?: Maybe<EmailTemplateWhereInput>;
  lastEditedBy?: Maybe<UserWhereInput>;
};

export type EmailTemplateVersionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EmailTemplateWhereInput = {
  AND?: Maybe<Array<EmailTemplateWhereInput>>;
  OR?: Maybe<Array<EmailTemplateWhereInput>>;
  NOT?: Maybe<Array<EmailTemplateWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  type?: Maybe<EnumEmailTemplateTypeFilter>;
  unlayerSettingId?: Maybe<IntFilter>;
  isActive?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  lastPublishedAt?: Maybe<DateTimeNullableFilter>;
  unlayerSetting?: Maybe<UnlayerSettingWhereInput>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionListRelationFilter>;
};

export type EmailTemplateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EmbedUrl = {
  __typename?: 'EmbedURL';
  EmbedUrl?: Maybe<Scalars['String']>;
  RequestId?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Int']>;
};

export type EmergencyClinicContactInfo = {
  __typename?: 'EmergencyClinicContactInfo';
  name?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['String']>;
};

export type EmergencyClinics = {
  __typename?: 'EmergencyClinics';
  id: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  clinicSettingId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
  clinicSetting: ClinicSetting;
};

export type EmergencyClinicsAvgAggregateOutputType = {
  __typename?: 'EmergencyClinicsAvgAggregateOutputType';
  displayOrder?: Maybe<Scalars['Float']>;
};

export type EmergencyClinicsAvgOrderByAggregateInput = {
  displayOrder?: Maybe<SortOrder>;
};

export type EmergencyClinicsClinicSettingIdDisplayOrderCompoundUniqueInput = {
  clinicSettingId: Scalars['String'];
  displayOrder: Scalars['Int'];
};

export type EmergencyClinicsCountAggregateOutputType = {
  __typename?: 'EmergencyClinicsCountAggregateOutputType';
  id: Scalars['Int'];
  name: Scalars['Int'];
  phone: Scalars['Int'];
  city: Scalars['Int'];
  state: Scalars['Int'];
  addressLine1: Scalars['Int'];
  addressLine2: Scalars['Int'];
  postalCode: Scalars['Int'];
  fullAddress: Scalars['Int'];
  displayOrder: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isDeleted: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EmergencyClinicsCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type EmergencyClinicsCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicSetting: ClinicSettingCreateNestedOneWithoutEmergencyClinicsInput;
};

export type EmergencyClinicsCreateManyClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsCreateManyClinicSettingInputEnvelope = {
  data: Array<EmergencyClinicsCreateManyClinicSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  clinicSettingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsCreateNestedManyWithoutClinicSettingInput = {
  create?: Maybe<Array<EmergencyClinicsCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<EmergencyClinicsCreateOrConnectWithoutClinicSettingInput>>;
  createMany?: Maybe<EmergencyClinicsCreateManyClinicSettingInputEnvelope>;
  connect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
};

export type EmergencyClinicsCreateOrConnectWithoutClinicSettingInput = {
  where: EmergencyClinicsWhereUniqueInput;
  create: EmergencyClinicsUncheckedCreateWithoutClinicSettingInput;
};

export type EmergencyClinicsCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsListRelationFilter = {
  every?: Maybe<EmergencyClinicsWhereInput>;
  some?: Maybe<EmergencyClinicsWhereInput>;
  none?: Maybe<EmergencyClinicsWhereInput>;
};

export type EmergencyClinicsMaxAggregateOutputType = {
  __typename?: 'EmergencyClinicsMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type EmergencyClinicsMinAggregateOutputType = {
  __typename?: 'EmergencyClinicsMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type EmergencyClinicsOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmergencyClinicsOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  Phone = 'phone',
  City = 'city',
  State = 'state',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  PostalCode = 'postalCode',
  FullAddress = 'fullAddress',
  ClinicSettingId = 'clinicSettingId'
}

export type EmergencyClinicsOrderByRelevanceInput = {
  fields: Array<EmergencyClinicsOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EmergencyClinicsOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _count?: Maybe<EmergencyClinicsCountOrderByAggregateInput>;
  _avg?: Maybe<EmergencyClinicsAvgOrderByAggregateInput>;
  _max?: Maybe<EmergencyClinicsMaxOrderByAggregateInput>;
  _min?: Maybe<EmergencyClinicsMinOrderByAggregateInput>;
  _sum?: Maybe<EmergencyClinicsSumOrderByAggregateInput>;
};

export type EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<EmergencyClinicsOrderByRelevanceInput>;
};

export enum EmergencyClinicsScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Phone = 'phone',
  City = 'city',
  State = 'state',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  PostalCode = 'postalCode',
  FullAddress = 'fullAddress',
  DisplayOrder = 'displayOrder',
  ClinicSettingId = 'clinicSettingId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsDeleted = 'isDeleted'
}

export type EmergencyClinicsScalarWhereInput = {
  AND?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
  OR?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
  NOT?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  phone?: Maybe<StringFilter>;
  city?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  addressLine1?: Maybe<StringNullableFilter>;
  addressLine2?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  fullAddress?: Maybe<StringNullableFilter>;
  displayOrder?: Maybe<IntFilter>;
  clinicSettingId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type EmergencyClinicsScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmergencyClinicsScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmergencyClinicsScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmergencyClinicsScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  phone?: Maybe<StringWithAggregatesFilter>;
  city?: Maybe<StringNullableWithAggregatesFilter>;
  state?: Maybe<StringNullableWithAggregatesFilter>;
  addressLine1?: Maybe<StringNullableWithAggregatesFilter>;
  addressLine2?: Maybe<StringNullableWithAggregatesFilter>;
  postalCode?: Maybe<StringNullableWithAggregatesFilter>;
  fullAddress?: Maybe<StringNullableWithAggregatesFilter>;
  displayOrder?: Maybe<IntWithAggregatesFilter>;
  clinicSettingId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
};

export type EmergencyClinicsSumAggregateOutputType = {
  __typename?: 'EmergencyClinicsSumAggregateOutputType';
  displayOrder?: Maybe<Scalars['Int']>;
};

export type EmergencyClinicsSumOrderByAggregateInput = {
  displayOrder?: Maybe<SortOrder>;
};

export type EmergencyClinicsUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  clinicSettingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput = {
  create?: Maybe<Array<EmergencyClinicsCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<EmergencyClinicsCreateOrConnectWithoutClinicSettingInput>>;
  createMany?: Maybe<EmergencyClinicsCreateManyClinicSettingInputEnvelope>;
  connect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
};

export type EmergencyClinicsUncheckedCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingNestedInput = {
  create?: Maybe<Array<EmergencyClinicsCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<EmergencyClinicsCreateOrConnectWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<EmergencyClinicsUpsertWithWhereUniqueWithoutClinicSettingInput>>;
  createMany?: Maybe<EmergencyClinicsCreateManyClinicSettingInputEnvelope>;
  set?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  delete?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  connect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  update?: Maybe<Array<EmergencyClinicsUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<EmergencyClinicsUpdateManyWithWhereWithoutClinicSettingInput>>;
  deleteMany?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
};

export type EmergencyClinicsUncheckedUpdateManyWithoutEmergencyClinicsInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUncheckedUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUpdateInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutEmergencyClinicsNestedInput>;
};

export type EmergencyClinicsUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUpdateManyWithWhereWithoutClinicSettingInput = {
  where: EmergencyClinicsScalarWhereInput;
  data: EmergencyClinicsUncheckedUpdateManyWithoutEmergencyClinicsInput;
};

export type EmergencyClinicsUpdateManyWithoutClinicSettingNestedInput = {
  create?: Maybe<Array<EmergencyClinicsCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<EmergencyClinicsCreateOrConnectWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<EmergencyClinicsUpsertWithWhereUniqueWithoutClinicSettingInput>>;
  createMany?: Maybe<EmergencyClinicsCreateManyClinicSettingInputEnvelope>;
  set?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  delete?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  connect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  update?: Maybe<Array<EmergencyClinicsUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<EmergencyClinicsUpdateManyWithWhereWithoutClinicSettingInput>>;
  deleteMany?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
};

export type EmergencyClinicsUpdateWithWhereUniqueWithoutClinicSettingInput = {
  where: EmergencyClinicsWhereUniqueInput;
  data: EmergencyClinicsUncheckedUpdateWithoutClinicSettingInput;
};

export type EmergencyClinicsUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUpsertWithWhereUniqueWithoutClinicSettingInput = {
  where: EmergencyClinicsWhereUniqueInput;
  update: EmergencyClinicsUncheckedUpdateWithoutClinicSettingInput;
  create: EmergencyClinicsUncheckedCreateWithoutClinicSettingInput;
};

export type EmergencyClinicsWhereInput = {
  AND?: Maybe<Array<EmergencyClinicsWhereInput>>;
  OR?: Maybe<Array<EmergencyClinicsWhereInput>>;
  NOT?: Maybe<Array<EmergencyClinicsWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  phone?: Maybe<StringFilter>;
  city?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  addressLine1?: Maybe<StringNullableFilter>;
  addressLine2?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  fullAddress?: Maybe<StringNullableFilter>;
  displayOrder?: Maybe<IntFilter>;
  clinicSettingId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
};

export type EmergencyClinicsWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicSettingId_displayOrder?: Maybe<EmergencyClinicsClinicSettingIdDisplayOrderCompoundUniqueInput>;
};

export enum EncryptedSignedUploadPurpose {
  LegalEntityClinic = 'LegalEntity_Clinic',
  LegalEntityRepresentative = 'LegalEntity_Representative'
}

export type EncryptedUploadResponse = {
  __typename?: 'EncryptedUploadResponse';
  success: Scalars['Boolean'];
};

export type EnhancedStripeRefundPaymentInput = {
  amount: Scalars['Int'];
  reason: StripeRefundReason;
  reasonOther?: Maybe<Scalars['String']>;
  issuerInitials?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type EnrollmentAndSubscriptionInput = {
  organizationPetId: Scalars['String'];
  carePlanId: Scalars['String'];
};

export enum EnrollmentStatus {
  Pending = 'Pending',
  Active = 'Active',
  PaymentFailed = 'PaymentFailed',
  Cancelling = 'Cancelling',
  Canceled = 'Canceled',
  Expired = 'Expired',
  NeverStarted = 'NeverStarted'
}

export type EnrollmentSupportEvent = {
  __typename?: 'EnrollmentSupportEvent';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status: SupportEventStatus;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  carePlanEnrollment: CarePlanEnrollment;
  requester: User;
  pointOfContact: User;
  responder?: Maybe<User>;
  currentPlan?: Maybe<CarePlan>;
  newPlan?: Maybe<CarePlan>;
  transferFromPet?: Maybe<OrganizationPet>;
  transferToPet?: Maybe<OrganizationPet>;
  relatedInvoice?: Maybe<StripeInvoice>;
};

export type EnrollmentSupportEventAvgAggregateOutputType = {
  __typename?: 'EnrollmentSupportEventAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type EnrollmentSupportEventAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type EnrollmentSupportEventCountAggregateOutputType = {
  __typename?: 'EnrollmentSupportEventCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  carePlanEnrollmentId: Scalars['Int'];
  requesterId: Scalars['Int'];
  pointOfContactId: Scalars['Int'];
  manageMembershipRequestType: Scalars['Int'];
  cancelReason: Scalars['Int'];
  manageMembershipDescription: Scalars['Int'];
  amount: Scalars['Int'];
  paymentSchedule: Scalars['Int'];
  status: Scalars['Int'];
  responderId: Scalars['Int'];
  zendeskTicketUrl: Scalars['Int'];
  notes: Scalars['Int'];
  currentPlanId: Scalars['Int'];
  newPlanId: Scalars['Int'];
  transferFromPetId: Scalars['Int'];
  transferToPetId: Scalars['Int'];
  relatedInvoiceId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EnrollmentSupportEventCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  requesterId?: Maybe<SortOrder>;
  pointOfContactId?: Maybe<SortOrder>;
  manageMembershipRequestType?: Maybe<SortOrder>;
  cancelReason?: Maybe<SortOrder>;
  manageMembershipDescription?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  paymentSchedule?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  responderId?: Maybe<SortOrder>;
  zendeskTicketUrl?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  currentPlanId?: Maybe<SortOrder>;
  newPlanId?: Maybe<SortOrder>;
  transferFromPetId?: Maybe<SortOrder>;
  transferToPetId?: Maybe<SortOrder>;
  relatedInvoiceId?: Maybe<SortOrder>;
};

export type EnrollmentSupportEventCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  requester: UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput;
  pointOfContact: UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
};

export type EnrollmentSupportEventCreateManyCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyCarePlanEnrollmentInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyCarePlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyCurrentPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyCurrentPlanInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyCurrentPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyNewPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyNewPlanInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyNewPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyPointOfContactInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyPointOfContactInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyPointOfContactInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyRelatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyRelatedInvoiceInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyRelatedInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyRequesterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyRequesterInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyRequesterInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyResponderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyResponderInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyResponderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyTransferFromPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyTransferFromPetInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyTransferFromPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyTransferToPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyTransferToPetInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyTransferToPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCarePlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCurrentPlanInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCurrentPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCurrentPlanInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutNewPlanInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutNewPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyNewPlanInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutPointOfContactInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutPointOfContactInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyPointOfContactInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRelatedInvoiceInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRelatedInvoiceInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRelatedInvoiceInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutRequesterInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRequesterInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRequesterInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRequesterInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutResponderInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutResponderInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutResponderInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyResponderInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferFromPetInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferFromPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferFromPetInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferToPetInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferToPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferToPetInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventCreateOrConnectWithoutCarePlanEnrollmentInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutCarePlanEnrollmentInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutCurrentPlanInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutCurrentPlanInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutNewPlanInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutNewPlanInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutPointOfContactInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutPointOfContactInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutRelatedInvoiceInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutRelatedInvoiceInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutRequesterInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutRequesterInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutResponderInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutResponderInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutTransferFromPetInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutTransferFromPetInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutTransferToPetInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutTransferToPetInput;
};

export type EnrollmentSupportEventCreateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  requester: UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput;
  pointOfContact: UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
};

export type EnrollmentSupportEventCreateWithoutCurrentPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  requester: UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput;
  pointOfContact: UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
};

export type EnrollmentSupportEventCreateWithoutNewPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  requester: UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput;
  pointOfContact: UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
};

export type EnrollmentSupportEventCreateWithoutPointOfContactInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  requester: UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
};

export type EnrollmentSupportEventCreateWithoutRelatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  requester: UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput;
  pointOfContact: UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
};

export type EnrollmentSupportEventCreateWithoutRequesterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  pointOfContact: UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
};

export type EnrollmentSupportEventCreateWithoutResponderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  requester: UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput;
  pointOfContact: UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
};

export type EnrollmentSupportEventCreateWithoutTransferFromPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  requester: UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput;
  pointOfContact: UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
};

export type EnrollmentSupportEventCreateWithoutTransferToPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  requester: UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput;
  pointOfContact: UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
};

export type EnrollmentSupportEventListRelationFilter = {
  every?: Maybe<EnrollmentSupportEventWhereInput>;
  some?: Maybe<EnrollmentSupportEventWhereInput>;
  none?: Maybe<EnrollmentSupportEventWhereInput>;
};

export type EnrollmentSupportEventMaxAggregateOutputType = {
  __typename?: 'EnrollmentSupportEventMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  requesterId?: Maybe<SortOrder>;
  pointOfContactId?: Maybe<SortOrder>;
  manageMembershipRequestType?: Maybe<SortOrder>;
  cancelReason?: Maybe<SortOrder>;
  manageMembershipDescription?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  paymentSchedule?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  responderId?: Maybe<SortOrder>;
  zendeskTicketUrl?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  currentPlanId?: Maybe<SortOrder>;
  newPlanId?: Maybe<SortOrder>;
  transferFromPetId?: Maybe<SortOrder>;
  transferToPetId?: Maybe<SortOrder>;
  relatedInvoiceId?: Maybe<SortOrder>;
};

export type EnrollmentSupportEventMinAggregateOutputType = {
  __typename?: 'EnrollmentSupportEventMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  requesterId?: Maybe<SortOrder>;
  pointOfContactId?: Maybe<SortOrder>;
  manageMembershipRequestType?: Maybe<SortOrder>;
  cancelReason?: Maybe<SortOrder>;
  manageMembershipDescription?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  paymentSchedule?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  responderId?: Maybe<SortOrder>;
  zendeskTicketUrl?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  currentPlanId?: Maybe<SortOrder>;
  newPlanId?: Maybe<SortOrder>;
  transferFromPetId?: Maybe<SortOrder>;
  transferToPetId?: Maybe<SortOrder>;
  relatedInvoiceId?: Maybe<SortOrder>;
};

export type EnrollmentSupportEventOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EnrollmentSupportEventOrderByRelevanceFieldEnum {
  Id = 'id',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  RequesterId = 'requesterId',
  PointOfContactId = 'pointOfContactId',
  ManageMembershipDescription = 'manageMembershipDescription',
  ResponderId = 'responderId',
  ZendeskTicketUrl = 'zendeskTicketUrl',
  Notes = 'notes',
  CurrentPlanId = 'currentPlanId',
  NewPlanId = 'newPlanId',
  TransferFromPetId = 'transferFromPetId',
  TransferToPetId = 'transferToPetId',
  RelatedInvoiceId = 'relatedInvoiceId'
}

export type EnrollmentSupportEventOrderByRelevanceInput = {
  fields: Array<EnrollmentSupportEventOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EnrollmentSupportEventOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  requesterId?: Maybe<SortOrder>;
  pointOfContactId?: Maybe<SortOrder>;
  manageMembershipRequestType?: Maybe<SortOrder>;
  cancelReason?: Maybe<SortOrder>;
  manageMembershipDescription?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  paymentSchedule?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  responderId?: Maybe<SortOrder>;
  zendeskTicketUrl?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  currentPlanId?: Maybe<SortOrder>;
  newPlanId?: Maybe<SortOrder>;
  transferFromPetId?: Maybe<SortOrder>;
  transferToPetId?: Maybe<SortOrder>;
  relatedInvoiceId?: Maybe<SortOrder>;
  _count?: Maybe<EnrollmentSupportEventCountOrderByAggregateInput>;
  _avg?: Maybe<EnrollmentSupportEventAvgOrderByAggregateInput>;
  _max?: Maybe<EnrollmentSupportEventMaxOrderByAggregateInput>;
  _min?: Maybe<EnrollmentSupportEventMinOrderByAggregateInput>;
  _sum?: Maybe<EnrollmentSupportEventSumOrderByAggregateInput>;
};

export type EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  requesterId?: Maybe<SortOrder>;
  pointOfContactId?: Maybe<SortOrder>;
  manageMembershipRequestType?: Maybe<SortOrder>;
  cancelReason?: Maybe<SortOrder>;
  manageMembershipDescription?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  paymentSchedule?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  responderId?: Maybe<SortOrder>;
  zendeskTicketUrl?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  currentPlanId?: Maybe<SortOrder>;
  newPlanId?: Maybe<SortOrder>;
  transferFromPetId?: Maybe<SortOrder>;
  transferToPetId?: Maybe<SortOrder>;
  relatedInvoiceId?: Maybe<SortOrder>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  requester?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  pointOfContact?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  responder?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  currentPlan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  newPlan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  transferFromPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  transferToPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  relatedInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<EnrollmentSupportEventOrderByRelevanceInput>;
};

export enum EnrollmentSupportEventScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  RequesterId = 'requesterId',
  PointOfContactId = 'pointOfContactId',
  ManageMembershipRequestType = 'manageMembershipRequestType',
  CancelReason = 'cancelReason',
  ManageMembershipDescription = 'manageMembershipDescription',
  Amount = 'amount',
  PaymentSchedule = 'paymentSchedule',
  Status = 'status',
  ResponderId = 'responderId',
  ZendeskTicketUrl = 'zendeskTicketUrl',
  Notes = 'notes',
  CurrentPlanId = 'currentPlanId',
  NewPlanId = 'newPlanId',
  TransferFromPetId = 'transferFromPetId',
  TransferToPetId = 'transferToPetId',
  RelatedInvoiceId = 'relatedInvoiceId'
}

export type EnrollmentSupportEventScalarWhereInput = {
  AND?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  OR?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  NOT?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  carePlanEnrollmentId?: Maybe<StringFilter>;
  requesterId?: Maybe<StringFilter>;
  pointOfContactId?: Maybe<StringFilter>;
  manageMembershipRequestType?: Maybe<EnumManageMembershipRequestTypeFilter>;
  cancelReason?: Maybe<EnumCancelReasonTypeNullableFilter>;
  manageMembershipDescription?: Maybe<StringNullableFilter>;
  amount?: Maybe<FloatNullableFilter>;
  paymentSchedule?: Maybe<EnumPaymentScheduleNullableFilter>;
  status?: Maybe<EnumSupportEventStatusFilter>;
  responderId?: Maybe<StringNullableFilter>;
  zendeskTicketUrl?: Maybe<StringNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  currentPlanId?: Maybe<StringNullableFilter>;
  newPlanId?: Maybe<StringNullableFilter>;
  transferFromPetId?: Maybe<StringNullableFilter>;
  transferToPetId?: Maybe<StringNullableFilter>;
  relatedInvoiceId?: Maybe<StringNullableFilter>;
};

export type EnrollmentSupportEventScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EnrollmentSupportEventScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EnrollmentSupportEventScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EnrollmentSupportEventScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  carePlanEnrollmentId?: Maybe<StringWithAggregatesFilter>;
  requesterId?: Maybe<StringWithAggregatesFilter>;
  pointOfContactId?: Maybe<StringWithAggregatesFilter>;
  manageMembershipRequestType?: Maybe<EnumManageMembershipRequestTypeWithAggregatesFilter>;
  cancelReason?: Maybe<EnumCancelReasonTypeNullableWithAggregatesFilter>;
  manageMembershipDescription?: Maybe<StringNullableWithAggregatesFilter>;
  amount?: Maybe<FloatNullableWithAggregatesFilter>;
  paymentSchedule?: Maybe<EnumPaymentScheduleNullableWithAggregatesFilter>;
  status?: Maybe<EnumSupportEventStatusWithAggregatesFilter>;
  responderId?: Maybe<StringNullableWithAggregatesFilter>;
  zendeskTicketUrl?: Maybe<StringNullableWithAggregatesFilter>;
  notes?: Maybe<StringNullableWithAggregatesFilter>;
  currentPlanId?: Maybe<StringNullableWithAggregatesFilter>;
  newPlanId?: Maybe<StringNullableWithAggregatesFilter>;
  transferFromPetId?: Maybe<StringNullableWithAggregatesFilter>;
  transferToPetId?: Maybe<StringNullableWithAggregatesFilter>;
  relatedInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type EnrollmentSupportEventSumAggregateOutputType = {
  __typename?: 'EnrollmentSupportEventSumAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type EnrollmentSupportEventSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type EnrollmentSupportEventUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCarePlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCurrentPlanInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCurrentPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCurrentPlanInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutNewPlanInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutNewPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyNewPlanInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutPointOfContactInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutPointOfContactInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyPointOfContactInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRelatedInvoiceInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRelatedInvoiceInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRelatedInvoiceInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRequesterInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRequesterInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRequesterInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutResponderInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutResponderInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyResponderInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferFromPetInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferFromPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferFromPetInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferToPetInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferToPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferToPetInputEnvelope>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutCurrentPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutNewPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutPointOfContactInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutRelatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutRequesterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutResponderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutTransferFromPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutTransferToPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId: Scalars['String'];
  requesterId: Scalars['String'];
  pointOfContactId: Scalars['String'];
  manageMembershipRequestType: ManageMembershipRequestType;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCarePlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCurrentPlanInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCurrentPlanInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutCurrentPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCurrentPlanInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutCurrentPlanInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutCurrentPlanInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutNewPlanInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutNewPlanInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutNewPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyNewPlanInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutNewPlanInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutNewPlanInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutPointOfContactInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutPointOfContactInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutPointOfContactInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyPointOfContactInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutPointOfContactInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutPointOfContactInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRelatedInvoiceInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRelatedInvoiceInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutRelatedInvoiceInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRelatedInvoiceInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutRelatedInvoiceInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutRelatedInvoiceInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutRequestedEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRequesterInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRequesterInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutRequesterInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRequesterInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutRequesterInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutRequesterInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutResponderEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutResponderInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutResponderInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutResponderInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyResponderInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutResponderInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutResponderInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetEnrollmentSupportEventInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferFromPetInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferFromPetInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutTransferFromPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferFromPetInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutTransferFromPetInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutTransferFromPetInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetEnrollmentSupportEventInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferToPetInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferToPetInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutTransferToPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferToPetInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutTransferToPetInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutTransferToPetInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutCurrentPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutNewPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutPointOfContactInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutRelatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutRequesterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutResponderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutTransferFromPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutTransferToPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  pointOfContactId?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  responderId?: Maybe<Scalars['String']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  currentPlanId?: Maybe<Scalars['String']>;
  newPlanId?: Maybe<Scalars['String']>;
  transferFromPetId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneRequiredWithoutRequestedEnrollmentSupportEventsNestedInput>;
  pointOfContact?: Maybe<UserUpdateOneRequiredWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
};

export type EnrollmentSupportEventUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutCarePlanEnrollmentInput = {
  where: EnrollmentSupportEventScalarWhereInput;
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutEnrollmentSupportEventsInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutCurrentPlanInput = {
  where: EnrollmentSupportEventScalarWhereInput;
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanEnrollmentSupportEventsInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutNewPlanInput = {
  where: EnrollmentSupportEventScalarWhereInput;
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanEnrollmentSupportEventsInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutPointOfContactInput = {
  where: EnrollmentSupportEventScalarWhereInput;
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactEnrollmentSupportEventsInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutRelatedInvoiceInput = {
  where: EnrollmentSupportEventScalarWhereInput;
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedEnrollmentSupportEventsInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutRequesterInput = {
  where: EnrollmentSupportEventScalarWhereInput;
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutRequestedEnrollmentSupportEventsInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutResponderInput = {
  where: EnrollmentSupportEventScalarWhereInput;
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutResponderEnrollmentSupportEventsInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutTransferFromPetInput = {
  where: EnrollmentSupportEventScalarWhereInput;
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetEnrollmentSupportEventInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutTransferToPetInput = {
  where: EnrollmentSupportEventScalarWhereInput;
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetEnrollmentSupportEventInput;
};

export type EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCarePlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCurrentPlanInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCurrentPlanInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutCurrentPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCurrentPlanInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutCurrentPlanInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutCurrentPlanInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutNewPlanInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutNewPlanInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutNewPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyNewPlanInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutNewPlanInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutNewPlanInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutPointOfContactInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutPointOfContactInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutPointOfContactInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyPointOfContactInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutPointOfContactInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutPointOfContactInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRelatedInvoiceInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRelatedInvoiceInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutRelatedInvoiceInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRelatedInvoiceInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutRelatedInvoiceInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutRelatedInvoiceInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRequesterInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRequesterInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutRequesterInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRequesterInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutRequesterInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutRequesterInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutResponderNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutResponderInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutResponderInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutResponderInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyResponderInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutResponderInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutResponderInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferFromPetInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferFromPetInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutTransferFromPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferFromPetInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutTransferFromPetInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutTransferFromPetInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput = {
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferToPetInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferToPetInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutTransferToPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferToPetInputEnvelope>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutTransferToPetInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutTransferToPetInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  data: EnrollmentSupportEventUncheckedUpdateWithoutCarePlanEnrollmentInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutCurrentPlanInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  data: EnrollmentSupportEventUncheckedUpdateWithoutCurrentPlanInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutNewPlanInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  data: EnrollmentSupportEventUncheckedUpdateWithoutNewPlanInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutPointOfContactInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  data: EnrollmentSupportEventUncheckedUpdateWithoutPointOfContactInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutRelatedInvoiceInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  data: EnrollmentSupportEventUncheckedUpdateWithoutRelatedInvoiceInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutRequesterInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  data: EnrollmentSupportEventUncheckedUpdateWithoutRequesterInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutResponderInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  data: EnrollmentSupportEventUncheckedUpdateWithoutResponderInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutTransferFromPetInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  data: EnrollmentSupportEventUncheckedUpdateWithoutTransferFromPetInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutTransferToPetInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  data: EnrollmentSupportEventUncheckedUpdateWithoutTransferToPetInput;
};

export type EnrollmentSupportEventUpdateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  requester?: Maybe<UserUpdateOneRequiredWithoutRequestedEnrollmentSupportEventsNestedInput>;
  pointOfContact?: Maybe<UserUpdateOneRequiredWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
};

export type EnrollmentSupportEventUpdateWithoutCurrentPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneRequiredWithoutRequestedEnrollmentSupportEventsNestedInput>;
  pointOfContact?: Maybe<UserUpdateOneRequiredWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
};

export type EnrollmentSupportEventUpdateWithoutNewPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneRequiredWithoutRequestedEnrollmentSupportEventsNestedInput>;
  pointOfContact?: Maybe<UserUpdateOneRequiredWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
};

export type EnrollmentSupportEventUpdateWithoutPointOfContactInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneRequiredWithoutRequestedEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
};

export type EnrollmentSupportEventUpdateWithoutRelatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneRequiredWithoutRequestedEnrollmentSupportEventsNestedInput>;
  pointOfContact?: Maybe<UserUpdateOneRequiredWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
};

export type EnrollmentSupportEventUpdateWithoutRequesterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  pointOfContact?: Maybe<UserUpdateOneRequiredWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
};

export type EnrollmentSupportEventUpdateWithoutResponderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneRequiredWithoutRequestedEnrollmentSupportEventsNestedInput>;
  pointOfContact?: Maybe<UserUpdateOneRequiredWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
};

export type EnrollmentSupportEventUpdateWithoutTransferFromPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneRequiredWithoutRequestedEnrollmentSupportEventsNestedInput>;
  pointOfContact?: Maybe<UserUpdateOneRequiredWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
};

export type EnrollmentSupportEventUpdateWithoutTransferToPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  cancelReason?: Maybe<CancelReasonType>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneRequiredWithoutRequestedEnrollmentSupportEventsNestedInput>;
  pointOfContact?: Maybe<UserUpdateOneRequiredWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutCarePlanEnrollmentInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutCarePlanEnrollmentInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutCurrentPlanInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutCurrentPlanInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutCurrentPlanInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutNewPlanInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutNewPlanInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutNewPlanInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutPointOfContactInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutPointOfContactInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutPointOfContactInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutRelatedInvoiceInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutRelatedInvoiceInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutRelatedInvoiceInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutRequesterInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutRequesterInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutRequesterInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutResponderInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutResponderInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutResponderInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutTransferFromPetInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutTransferFromPetInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutTransferFromPetInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutTransferToPetInput = {
  where: EnrollmentSupportEventWhereUniqueInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutTransferToPetInput;
  create: EnrollmentSupportEventUncheckedCreateWithoutTransferToPetInput;
};

export type EnrollmentSupportEventWhereInput = {
  AND?: Maybe<Array<EnrollmentSupportEventWhereInput>>;
  OR?: Maybe<Array<EnrollmentSupportEventWhereInput>>;
  NOT?: Maybe<Array<EnrollmentSupportEventWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  carePlanEnrollmentId?: Maybe<StringFilter>;
  requesterId?: Maybe<StringFilter>;
  pointOfContactId?: Maybe<StringFilter>;
  manageMembershipRequestType?: Maybe<EnumManageMembershipRequestTypeFilter>;
  cancelReason?: Maybe<EnumCancelReasonTypeNullableFilter>;
  manageMembershipDescription?: Maybe<StringNullableFilter>;
  amount?: Maybe<FloatNullableFilter>;
  paymentSchedule?: Maybe<EnumPaymentScheduleNullableFilter>;
  status?: Maybe<EnumSupportEventStatusFilter>;
  responderId?: Maybe<StringNullableFilter>;
  zendeskTicketUrl?: Maybe<StringNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  currentPlanId?: Maybe<StringNullableFilter>;
  newPlanId?: Maybe<StringNullableFilter>;
  transferFromPetId?: Maybe<StringNullableFilter>;
  transferToPetId?: Maybe<StringNullableFilter>;
  relatedInvoiceId?: Maybe<StringNullableFilter>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  requester?: Maybe<UserWhereInput>;
  pointOfContact?: Maybe<UserWhereInput>;
  responder?: Maybe<UserWhereInput>;
  currentPlan?: Maybe<CarePlanWhereInput>;
  newPlan?: Maybe<CarePlanWhereInput>;
  transferFromPet?: Maybe<OrganizationPetWhereInput>;
  transferToPet?: Maybe<OrganizationPetWhereInput>;
  relatedInvoice?: Maybe<StripeInvoiceWhereInput>;
};

export type EnrollmentSupportEventWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EnsurePetsPromotedInput = {
  clinicPetParentId: Scalars['String'];
};

export type EnsurePetsPromotedResult = {
  __typename?: 'EnsurePetsPromotedResult';
  success: Scalars['Boolean'];
};

export type EnumAmericanKennelClubBreedGroupNullableFilter = {
  equals?: Maybe<AmericanKennelClubBreedGroup>;
  in?: Maybe<Array<AmericanKennelClubBreedGroup>>;
  notIn?: Maybe<Array<AmericanKennelClubBreedGroup>>;
  not?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableFilter>;
};

export type EnumAmericanKennelClubBreedGroupNullableWithAggregatesFilter = {
  equals?: Maybe<AmericanKennelClubBreedGroup>;
  in?: Maybe<Array<AmericanKennelClubBreedGroup>>;
  notIn?: Maybe<Array<AmericanKennelClubBreedGroup>>;
  not?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableFilter>;
};

export type EnumAmericanKennelClubBreedTemperamentNullableListFilter = {
  equals?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  has?: Maybe<AmericanKennelClubBreedTemperament>;
  hasEvery?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  hasSome?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumAmericanKennelClubDemeanorCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubDemeanorCategory>;
  in?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableFilter>;
};

export type EnumAmericanKennelClubDemeanorCategoryNullableWithAggregatesFilter = {
  equals?: Maybe<AmericanKennelClubDemeanorCategory>;
  in?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableFilter>;
};

export type EnumAmericanKennelClubEnergyLevelCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  in?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableFilter>;
};

export type EnumAmericanKennelClubEnergyLevelCategoryNullableWithAggregatesFilter = {
  equals?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  in?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableFilter>;
};

export type EnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  in?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter>;
};

export type EnumAmericanKennelClubGroomingFrequencyCategoryNullableWithAggregatesFilter = {
  equals?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  in?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter>;
};

export type EnumAmericanKennelClubSheddingCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubSheddingCategory>;
  in?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableFilter>;
};

export type EnumAmericanKennelClubSheddingCategoryNullableWithAggregatesFilter = {
  equals?: Maybe<AmericanKennelClubSheddingCategory>;
  in?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableFilter>;
};

export type EnumAmericanKennelClubTrainabilityCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubTrainabilityCategory>;
  in?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableFilter>;
};

export type EnumAmericanKennelClubTrainabilityCategoryNullableWithAggregatesFilter = {
  equals?: Maybe<AmericanKennelClubTrainabilityCategory>;
  in?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableFilter>;
};

export type EnumAppointmentDateRangeTypeFieldUpdateOperationsInput = {
  set?: Maybe<AppointmentDateRangeType>;
};

export type EnumAppointmentDateRangeTypeFilter = {
  equals?: Maybe<AppointmentDateRangeType>;
  in?: Maybe<Array<AppointmentDateRangeType>>;
  notIn?: Maybe<Array<AppointmentDateRangeType>>;
  not?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
};

export type EnumAppointmentDateRangeTypeWithAggregatesFilter = {
  equals?: Maybe<AppointmentDateRangeType>;
  in?: Maybe<Array<AppointmentDateRangeType>>;
  notIn?: Maybe<Array<AppointmentDateRangeType>>;
  not?: Maybe<NestedEnumAppointmentDateRangeTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
  _max?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
};

export type EnumAppointmentStatusFieldUpdateOperationsInput = {
  set?: Maybe<AppointmentStatus>;
};

export type EnumAppointmentStatusFilter = {
  equals?: Maybe<AppointmentStatus>;
  in?: Maybe<Array<AppointmentStatus>>;
  notIn?: Maybe<Array<AppointmentStatus>>;
  not?: Maybe<NestedEnumAppointmentStatusFilter>;
};

export type EnumAppointmentStatusWithAggregatesFilter = {
  equals?: Maybe<AppointmentStatus>;
  in?: Maybe<Array<AppointmentStatus>>;
  notIn?: Maybe<Array<AppointmentStatus>>;
  not?: Maybe<NestedEnumAppointmentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAppointmentStatusFilter>;
  _max?: Maybe<NestedEnumAppointmentStatusFilter>;
};

export type EnumAppointmentTypeVisibilityFieldUpdateOperationsInput = {
  set?: Maybe<AppointmentTypeVisibility>;
};

export type EnumAppointmentTypeVisibilityFilter = {
  equals?: Maybe<AppointmentTypeVisibility>;
  in?: Maybe<Array<AppointmentTypeVisibility>>;
  notIn?: Maybe<Array<AppointmentTypeVisibility>>;
  not?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
};

export type EnumAppointmentTypeVisibilityWithAggregatesFilter = {
  equals?: Maybe<AppointmentTypeVisibility>;
  in?: Maybe<Array<AppointmentTypeVisibility>>;
  notIn?: Maybe<Array<AppointmentTypeVisibility>>;
  not?: Maybe<NestedEnumAppointmentTypeVisibilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
  _max?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
};

export type EnumAutoResponseSuggestionStatusFieldUpdateOperationsInput = {
  set?: Maybe<AutoResponseSuggestionStatus>;
};

export type EnumAutoResponseSuggestionStatusFilter = {
  equals?: Maybe<AutoResponseSuggestionStatus>;
  in?: Maybe<Array<AutoResponseSuggestionStatus>>;
  notIn?: Maybe<Array<AutoResponseSuggestionStatus>>;
  not?: Maybe<NestedEnumAutoResponseSuggestionStatusFilter>;
};

export type EnumAutoResponseSuggestionStatusWithAggregatesFilter = {
  equals?: Maybe<AutoResponseSuggestionStatus>;
  in?: Maybe<Array<AutoResponseSuggestionStatus>>;
  notIn?: Maybe<Array<AutoResponseSuggestionStatus>>;
  not?: Maybe<NestedEnumAutoResponseSuggestionStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutoResponseSuggestionStatusFilter>;
  _max?: Maybe<NestedEnumAutoResponseSuggestionStatusFilter>;
};

export type EnumAutomatedTriggerTypeNullableFilter = {
  equals?: Maybe<AutomatedTriggerType>;
  in?: Maybe<Array<AutomatedTriggerType>>;
  notIn?: Maybe<Array<AutomatedTriggerType>>;
  not?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
};

export type EnumAutomatedTriggerTypeNullableWithAggregatesFilter = {
  equals?: Maybe<AutomatedTriggerType>;
  in?: Maybe<Array<AutomatedTriggerType>>;
  notIn?: Maybe<Array<AutomatedTriggerType>>;
  not?: Maybe<NestedEnumAutomatedTriggerTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
  _max?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
};

export type EnumAutomationRunActionPromptTypeFieldUpdateOperationsInput = {
  set?: Maybe<AutomationRunActionPromptType>;
};

export type EnumAutomationRunActionPromptTypeFilter = {
  equals?: Maybe<AutomationRunActionPromptType>;
  in?: Maybe<Array<AutomationRunActionPromptType>>;
  notIn?: Maybe<Array<AutomationRunActionPromptType>>;
  not?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
};

export type EnumAutomationRunActionPromptTypeWithAggregatesFilter = {
  equals?: Maybe<AutomationRunActionPromptType>;
  in?: Maybe<Array<AutomationRunActionPromptType>>;
  notIn?: Maybe<Array<AutomationRunActionPromptType>>;
  not?: Maybe<NestedEnumAutomationRunActionPromptTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
  _max?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
};

export type EnumAutomationRunActionTypeFieldUpdateOperationsInput = {
  set?: Maybe<AutomationRunActionType>;
};

export type EnumAutomationRunActionTypeFilter = {
  equals?: Maybe<AutomationRunActionType>;
  in?: Maybe<Array<AutomationRunActionType>>;
  notIn?: Maybe<Array<AutomationRunActionType>>;
  not?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
};

export type EnumAutomationRunActionTypeWithAggregatesFilter = {
  equals?: Maybe<AutomationRunActionType>;
  in?: Maybe<Array<AutomationRunActionType>>;
  notIn?: Maybe<Array<AutomationRunActionType>>;
  not?: Maybe<NestedEnumAutomationRunActionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
  _max?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
};

export type EnumAutomationRunStatusFieldUpdateOperationsInput = {
  set?: Maybe<AutomationRunStatus>;
};

export type EnumAutomationRunStatusFilter = {
  equals?: Maybe<AutomationRunStatus>;
  in?: Maybe<Array<AutomationRunStatus>>;
  notIn?: Maybe<Array<AutomationRunStatus>>;
  not?: Maybe<NestedEnumAutomationRunStatusFilter>;
};

export type EnumAutomationRunStatusWithAggregatesFilter = {
  equals?: Maybe<AutomationRunStatus>;
  in?: Maybe<Array<AutomationRunStatus>>;
  notIn?: Maybe<Array<AutomationRunStatus>>;
  not?: Maybe<NestedEnumAutomationRunStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunStatusFilter>;
  _max?: Maybe<NestedEnumAutomationRunStatusFilter>;
};

export type EnumAutomationRunTypeFieldUpdateOperationsInput = {
  set?: Maybe<AutomationRunType>;
};

export type EnumAutomationRunTypeFilter = {
  equals?: Maybe<AutomationRunType>;
  in?: Maybe<Array<AutomationRunType>>;
  notIn?: Maybe<Array<AutomationRunType>>;
  not?: Maybe<NestedEnumAutomationRunTypeFilter>;
};

export type EnumAutomationRunTypeWithAggregatesFilter = {
  equals?: Maybe<AutomationRunType>;
  in?: Maybe<Array<AutomationRunType>>;
  notIn?: Maybe<Array<AutomationRunType>>;
  not?: Maybe<NestedEnumAutomationRunTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunTypeFilter>;
  _max?: Maybe<NestedEnumAutomationRunTypeFilter>;
};

export type EnumAutomationStatusFieldUpdateOperationsInput = {
  set?: Maybe<AutomationStatus>;
};

export type EnumAutomationStatusFilter = {
  equals?: Maybe<AutomationStatus>;
  in?: Maybe<Array<AutomationStatus>>;
  notIn?: Maybe<Array<AutomationStatus>>;
  not?: Maybe<NestedEnumAutomationStatusFilter>;
};

export type EnumAutomationStatusWithAggregatesFilter = {
  equals?: Maybe<AutomationStatus>;
  in?: Maybe<Array<AutomationStatus>>;
  notIn?: Maybe<Array<AutomationStatus>>;
  not?: Maybe<NestedEnumAutomationStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationStatusFilter>;
  _max?: Maybe<NestedEnumAutomationStatusFilter>;
};

export type EnumBitwerxHealthcheckRunStatusNullableFilter = {
  equals?: Maybe<BitwerxHealthcheckRunStatus>;
  in?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
  notIn?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableFilter>;
};

export type EnumBitwerxHealthcheckRunStatusNullableWithAggregatesFilter = {
  equals?: Maybe<BitwerxHealthcheckRunStatus>;
  in?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
  notIn?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableFilter>;
  _max?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableFilter>;
};

export type EnumBitwerxHealthcheckTypeFieldUpdateOperationsInput = {
  set?: Maybe<BitwerxHealthcheckType>;
};

export type EnumBitwerxHealthcheckTypeFilter = {
  equals?: Maybe<BitwerxHealthcheckType>;
  in?: Maybe<Array<BitwerxHealthcheckType>>;
  notIn?: Maybe<Array<BitwerxHealthcheckType>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckTypeFilter>;
};

export type EnumBitwerxHealthcheckTypeWithAggregatesFilter = {
  equals?: Maybe<BitwerxHealthcheckType>;
  in?: Maybe<Array<BitwerxHealthcheckType>>;
  notIn?: Maybe<Array<BitwerxHealthcheckType>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBitwerxHealthcheckTypeFilter>;
  _max?: Maybe<NestedEnumBitwerxHealthcheckTypeFilter>;
};

export type EnumBitwerxPaymentEndpointFieldUpdateOperationsInput = {
  set?: Maybe<BitwerxPaymentEndpoint>;
};

export type EnumBitwerxPaymentEndpointFilter = {
  equals?: Maybe<BitwerxPaymentEndpoint>;
  in?: Maybe<Array<BitwerxPaymentEndpoint>>;
  notIn?: Maybe<Array<BitwerxPaymentEndpoint>>;
  not?: Maybe<NestedEnumBitwerxPaymentEndpointFilter>;
};

export type EnumBitwerxPaymentEndpointWithAggregatesFilter = {
  equals?: Maybe<BitwerxPaymentEndpoint>;
  in?: Maybe<Array<BitwerxPaymentEndpoint>>;
  notIn?: Maybe<Array<BitwerxPaymentEndpoint>>;
  not?: Maybe<NestedEnumBitwerxPaymentEndpointWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBitwerxPaymentEndpointFilter>;
  _max?: Maybe<NestedEnumBitwerxPaymentEndpointFilter>;
};

export type EnumBitwerxSystemFieldUpdateOperationsInput = {
  set?: Maybe<BitwerxSystem>;
};

export type EnumBitwerxSystemFilter = {
  equals?: Maybe<BitwerxSystem>;
  in?: Maybe<Array<BitwerxSystem>>;
  notIn?: Maybe<Array<BitwerxSystem>>;
  not?: Maybe<NestedEnumBitwerxSystemFilter>;
};

export type EnumBitwerxSystemWithAggregatesFilter = {
  equals?: Maybe<BitwerxSystem>;
  in?: Maybe<Array<BitwerxSystem>>;
  notIn?: Maybe<Array<BitwerxSystem>>;
  not?: Maybe<NestedEnumBitwerxSystemWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBitwerxSystemFilter>;
  _max?: Maybe<NestedEnumBitwerxSystemFilter>;
};

export type EnumBoardFilterColumnTypeFieldUpdateOperationsInput = {
  set?: Maybe<BoardFilterColumnType>;
};

export type EnumBoardFilterColumnTypeFilter = {
  equals?: Maybe<BoardFilterColumnType>;
  in?: Maybe<Array<BoardFilterColumnType>>;
  notIn?: Maybe<Array<BoardFilterColumnType>>;
  not?: Maybe<NestedEnumBoardFilterColumnTypeFilter>;
};

export type EnumBoardFilterColumnTypeWithAggregatesFilter = {
  equals?: Maybe<BoardFilterColumnType>;
  in?: Maybe<Array<BoardFilterColumnType>>;
  notIn?: Maybe<Array<BoardFilterColumnType>>;
  not?: Maybe<NestedEnumBoardFilterColumnTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBoardFilterColumnTypeFilter>;
  _max?: Maybe<NestedEnumBoardFilterColumnTypeFilter>;
};

export type EnumBoardFilterDateRangeNullableFilter = {
  equals?: Maybe<BoardFilterDateRange>;
  in?: Maybe<Array<BoardFilterDateRange>>;
  notIn?: Maybe<Array<BoardFilterDateRange>>;
  not?: Maybe<NestedEnumBoardFilterDateRangeNullableFilter>;
};

export type EnumBoardFilterDateRangeNullableWithAggregatesFilter = {
  equals?: Maybe<BoardFilterDateRange>;
  in?: Maybe<Array<BoardFilterDateRange>>;
  notIn?: Maybe<Array<BoardFilterDateRange>>;
  not?: Maybe<NestedEnumBoardFilterDateRangeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumBoardFilterDateRangeNullableFilter>;
  _max?: Maybe<NestedEnumBoardFilterDateRangeNullableFilter>;
};

export type EnumBoardFilterViewFieldUpdateOperationsInput = {
  set?: Maybe<BoardFilterView>;
};

export type EnumBoardFilterViewFilter = {
  equals?: Maybe<BoardFilterView>;
  in?: Maybe<Array<BoardFilterView>>;
  notIn?: Maybe<Array<BoardFilterView>>;
  not?: Maybe<NestedEnumBoardFilterViewFilter>;
};

export type EnumBoardFilterViewWithAggregatesFilter = {
  equals?: Maybe<BoardFilterView>;
  in?: Maybe<Array<BoardFilterView>>;
  notIn?: Maybe<Array<BoardFilterView>>;
  not?: Maybe<NestedEnumBoardFilterViewWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBoardFilterViewFilter>;
  _max?: Maybe<NestedEnumBoardFilterViewFilter>;
};

export type EnumCallHistoryTypeFieldUpdateOperationsInput = {
  set?: Maybe<CallHistoryType>;
};

export type EnumCallHistoryTypeFilter = {
  equals?: Maybe<CallHistoryType>;
  in?: Maybe<Array<CallHistoryType>>;
  notIn?: Maybe<Array<CallHistoryType>>;
  not?: Maybe<NestedEnumCallHistoryTypeFilter>;
};

export type EnumCallHistoryTypeWithAggregatesFilter = {
  equals?: Maybe<CallHistoryType>;
  in?: Maybe<Array<CallHistoryType>>;
  notIn?: Maybe<Array<CallHistoryType>>;
  not?: Maybe<NestedEnumCallHistoryTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCallHistoryTypeFilter>;
  _max?: Maybe<NestedEnumCallHistoryTypeFilter>;
};

export type EnumCallProviderFieldUpdateOperationsInput = {
  set?: Maybe<CallProvider>;
};

export type EnumCallProviderFilter = {
  equals?: Maybe<CallProvider>;
  in?: Maybe<Array<CallProvider>>;
  notIn?: Maybe<Array<CallProvider>>;
  not?: Maybe<NestedEnumCallProviderFilter>;
};

export type EnumCallProviderWithAggregatesFilter = {
  equals?: Maybe<CallProvider>;
  in?: Maybe<Array<CallProvider>>;
  notIn?: Maybe<Array<CallProvider>>;
  not?: Maybe<NestedEnumCallProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCallProviderFilter>;
  _max?: Maybe<NestedEnumCallProviderFilter>;
};

export type EnumCallStatusFieldUpdateOperationsInput = {
  set?: Maybe<CallStatus>;
};

export type EnumCallStatusFilter = {
  equals?: Maybe<CallStatus>;
  in?: Maybe<Array<CallStatus>>;
  notIn?: Maybe<Array<CallStatus>>;
  not?: Maybe<NestedEnumCallStatusFilter>;
};

export type EnumCallStatusWithAggregatesFilter = {
  equals?: Maybe<CallStatus>;
  in?: Maybe<Array<CallStatus>>;
  notIn?: Maybe<Array<CallStatus>>;
  not?: Maybe<NestedEnumCallStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCallStatusFilter>;
  _max?: Maybe<NestedEnumCallStatusFilter>;
};

export type EnumCampaignRegistryBrandIdentityStatusNullableFilter = {
  equals?: Maybe<CampaignRegistryBrandIdentityStatus>;
  in?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
  notIn?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableFilter>;
};

export type EnumCampaignRegistryBrandIdentityStatusNullableWithAggregatesFilter = {
  equals?: Maybe<CampaignRegistryBrandIdentityStatus>;
  in?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
  notIn?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableFilter>;
  _max?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableFilter>;
};

export type EnumCampaignRegistryCampaignSharingStatusNullableFilter = {
  equals?: Maybe<CampaignRegistryCampaignSharingStatus>;
  in?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
  notIn?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableFilter>;
};

export type EnumCampaignRegistryCampaignSharingStatusNullableWithAggregatesFilter = {
  equals?: Maybe<CampaignRegistryCampaignSharingStatus>;
  in?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
  notIn?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableFilter>;
  _max?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableFilter>;
};

export type EnumCancelReasonTypeNullableFilter = {
  equals?: Maybe<CancelReasonType>;
  in?: Maybe<Array<CancelReasonType>>;
  notIn?: Maybe<Array<CancelReasonType>>;
  not?: Maybe<NestedEnumCancelReasonTypeNullableFilter>;
};

export type EnumCancelReasonTypeNullableWithAggregatesFilter = {
  equals?: Maybe<CancelReasonType>;
  in?: Maybe<Array<CancelReasonType>>;
  notIn?: Maybe<Array<CancelReasonType>>;
  not?: Maybe<NestedEnumCancelReasonTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCancelReasonTypeNullableFilter>;
  _max?: Maybe<NestedEnumCancelReasonTypeNullableFilter>;
};

export type EnumCanonicalDataSourceFieldUpdateOperationsInput = {
  set?: Maybe<CanonicalDataSource>;
};

export type EnumCanonicalDataSourceFilter = {
  equals?: Maybe<CanonicalDataSource>;
  in?: Maybe<Array<CanonicalDataSource>>;
  notIn?: Maybe<Array<CanonicalDataSource>>;
  not?: Maybe<NestedEnumCanonicalDataSourceFilter>;
};

export type EnumCanonicalDataSourceWithAggregatesFilter = {
  equals?: Maybe<CanonicalDataSource>;
  in?: Maybe<Array<CanonicalDataSource>>;
  notIn?: Maybe<Array<CanonicalDataSource>>;
  not?: Maybe<NestedEnumCanonicalDataSourceWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCanonicalDataSourceFilter>;
  _max?: Maybe<NestedEnumCanonicalDataSourceFilter>;
};

export type EnumCanonicalServiceStatusFieldUpdateOperationsInput = {
  set?: Maybe<CanonicalServiceStatus>;
};

export type EnumCanonicalServiceStatusFilter = {
  equals?: Maybe<CanonicalServiceStatus>;
  in?: Maybe<Array<CanonicalServiceStatus>>;
  notIn?: Maybe<Array<CanonicalServiceStatus>>;
  not?: Maybe<NestedEnumCanonicalServiceStatusFilter>;
};

export type EnumCanonicalServiceStatusWithAggregatesFilter = {
  equals?: Maybe<CanonicalServiceStatus>;
  in?: Maybe<Array<CanonicalServiceStatus>>;
  notIn?: Maybe<Array<CanonicalServiceStatus>>;
  not?: Maybe<NestedEnumCanonicalServiceStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCanonicalServiceStatusFilter>;
  _max?: Maybe<NestedEnumCanonicalServiceStatusFilter>;
};

export type EnumCanonicalServiceTypeFieldUpdateOperationsInput = {
  set?: Maybe<CanonicalServiceType>;
};

export type EnumCanonicalServiceTypeFilter = {
  equals?: Maybe<CanonicalServiceType>;
  in?: Maybe<Array<CanonicalServiceType>>;
  notIn?: Maybe<Array<CanonicalServiceType>>;
  not?: Maybe<NestedEnumCanonicalServiceTypeFilter>;
};

export type EnumCanonicalServiceTypeWithAggregatesFilter = {
  equals?: Maybe<CanonicalServiceType>;
  in?: Maybe<Array<CanonicalServiceType>>;
  notIn?: Maybe<Array<CanonicalServiceType>>;
  not?: Maybe<NestedEnumCanonicalServiceTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCanonicalServiceTypeFilter>;
  _max?: Maybe<NestedEnumCanonicalServiceTypeFilter>;
};

export type EnumCardBrandFieldUpdateOperationsInput = {
  set?: Maybe<CardBrand>;
};

export type EnumCardBrandFilter = {
  equals?: Maybe<CardBrand>;
  in?: Maybe<Array<CardBrand>>;
  notIn?: Maybe<Array<CardBrand>>;
  not?: Maybe<NestedEnumCardBrandFilter>;
};

export type EnumCardBrandWithAggregatesFilter = {
  equals?: Maybe<CardBrand>;
  in?: Maybe<Array<CardBrand>>;
  notIn?: Maybe<Array<CardBrand>>;
  not?: Maybe<NestedEnumCardBrandWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCardBrandFilter>;
  _max?: Maybe<NestedEnumCardBrandFilter>;
};

export type EnumCardFundingNullableFilter = {
  equals?: Maybe<CardFunding>;
  in?: Maybe<Array<CardFunding>>;
  notIn?: Maybe<Array<CardFunding>>;
  not?: Maybe<NestedEnumCardFundingNullableFilter>;
};

export type EnumCardFundingNullableListFilter = {
  equals?: Maybe<Array<CardFunding>>;
  has?: Maybe<CardFunding>;
  hasEvery?: Maybe<Array<CardFunding>>;
  hasSome?: Maybe<Array<CardFunding>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumCardFundingNullableWithAggregatesFilter = {
  equals?: Maybe<CardFunding>;
  in?: Maybe<Array<CardFunding>>;
  notIn?: Maybe<Array<CardFunding>>;
  not?: Maybe<NestedEnumCardFundingNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCardFundingNullableFilter>;
  _max?: Maybe<NestedEnumCardFundingNullableFilter>;
};

export type EnumCareBenefitTypeFieldUpdateOperationsInput = {
  set?: Maybe<CareBenefitType>;
};

export type EnumCareBenefitTypeFilter = {
  equals?: Maybe<CareBenefitType>;
  in?: Maybe<Array<CareBenefitType>>;
  notIn?: Maybe<Array<CareBenefitType>>;
  not?: Maybe<NestedEnumCareBenefitTypeFilter>;
};

export type EnumCareBenefitTypeWithAggregatesFilter = {
  equals?: Maybe<CareBenefitType>;
  in?: Maybe<Array<CareBenefitType>>;
  notIn?: Maybe<Array<CareBenefitType>>;
  not?: Maybe<NestedEnumCareBenefitTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCareBenefitTypeFilter>;
  _max?: Maybe<NestedEnumCareBenefitTypeFilter>;
};

export type EnumCareBenefitUnitOfMeasureNullableFilter = {
  equals?: Maybe<CareBenefitUnitOfMeasure>;
  in?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  notIn?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  not?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
};

export type EnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter = {
  equals?: Maybe<CareBenefitUnitOfMeasure>;
  in?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  notIn?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  not?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
  _max?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
};

export type EnumCareLapsedPaymentStatusFieldUpdateOperationsInput = {
  set?: Maybe<CareLapsedPaymentStatus>;
};

export type EnumCareLapsedPaymentStatusFilter = {
  equals?: Maybe<CareLapsedPaymentStatus>;
  in?: Maybe<Array<CareLapsedPaymentStatus>>;
  notIn?: Maybe<Array<CareLapsedPaymentStatus>>;
  not?: Maybe<NestedEnumCareLapsedPaymentStatusFilter>;
};

export type EnumCareLapsedPaymentStatusWithAggregatesFilter = {
  equals?: Maybe<CareLapsedPaymentStatus>;
  in?: Maybe<Array<CareLapsedPaymentStatus>>;
  notIn?: Maybe<Array<CareLapsedPaymentStatus>>;
  not?: Maybe<NestedEnumCareLapsedPaymentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCareLapsedPaymentStatusFilter>;
  _max?: Maybe<NestedEnumCareLapsedPaymentStatusFilter>;
};

export type EnumCarePlanCycleTypeFieldUpdateOperationsInput = {
  set?: Maybe<CarePlanCycleType>;
};

export type EnumCarePlanCycleTypeFilter = {
  equals?: Maybe<CarePlanCycleType>;
  in?: Maybe<Array<CarePlanCycleType>>;
  notIn?: Maybe<Array<CarePlanCycleType>>;
  not?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
};

export type EnumCarePlanCycleTypeWithAggregatesFilter = {
  equals?: Maybe<CarePlanCycleType>;
  in?: Maybe<Array<CarePlanCycleType>>;
  notIn?: Maybe<Array<CarePlanCycleType>>;
  not?: Maybe<NestedEnumCarePlanCycleTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
  _max?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
};

export type EnumCareProgramLaunchStatusFieldUpdateOperationsInput = {
  set?: Maybe<CareProgramLaunchStatus>;
};

export type EnumCareProgramLaunchStatusFilter = {
  equals?: Maybe<CareProgramLaunchStatus>;
  in?: Maybe<Array<CareProgramLaunchStatus>>;
  notIn?: Maybe<Array<CareProgramLaunchStatus>>;
  not?: Maybe<NestedEnumCareProgramLaunchStatusFilter>;
};

export type EnumCareProgramLaunchStatusWithAggregatesFilter = {
  equals?: Maybe<CareProgramLaunchStatus>;
  in?: Maybe<Array<CareProgramLaunchStatus>>;
  notIn?: Maybe<Array<CareProgramLaunchStatus>>;
  not?: Maybe<NestedEnumCareProgramLaunchStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCareProgramLaunchStatusFilter>;
  _max?: Maybe<NestedEnumCareProgramLaunchStatusFilter>;
};

export type EnumCareSubscriptionPaymentStatusNullableFilter = {
  equals?: Maybe<CareSubscriptionPaymentStatus>;
  in?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  notIn?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
};

export type EnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter = {
  equals?: Maybe<CareSubscriptionPaymentStatus>;
  in?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  notIn?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
  _max?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
};

export type EnumCareSubscriptionTrueUpStatusFieldUpdateOperationsInput = {
  set?: Maybe<CareSubscriptionTrueUpStatus>;
};

export type EnumCareSubscriptionTrueUpStatusFilter = {
  equals?: Maybe<CareSubscriptionTrueUpStatus>;
  in?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpStatusFilter>;
};

export type EnumCareSubscriptionTrueUpStatusWithAggregatesFilter = {
  equals?: Maybe<CareSubscriptionTrueUpStatus>;
  in?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCareSubscriptionTrueUpStatusFilter>;
  _max?: Maybe<NestedEnumCareSubscriptionTrueUpStatusFilter>;
};

export type EnumCareSubscriptionTrueUpTypeFieldUpdateOperationsInput = {
  set?: Maybe<CareSubscriptionTrueUpType>;
};

export type EnumCareSubscriptionTrueUpTypeFilter = {
  equals?: Maybe<CareSubscriptionTrueUpType>;
  in?: Maybe<Array<CareSubscriptionTrueUpType>>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpType>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpTypeFilter>;
};

export type EnumCareSubscriptionTrueUpTypeWithAggregatesFilter = {
  equals?: Maybe<CareSubscriptionTrueUpType>;
  in?: Maybe<Array<CareSubscriptionTrueUpType>>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpType>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCareSubscriptionTrueUpTypeFilter>;
  _max?: Maybe<NestedEnumCareSubscriptionTrueUpTypeFilter>;
};

export type EnumChannelCreationSourceTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChannelCreationSourceType>;
};

export type EnumChannelCreationSourceTypeFilter = {
  equals?: Maybe<ChannelCreationSourceType>;
  in?: Maybe<Array<ChannelCreationSourceType>>;
  notIn?: Maybe<Array<ChannelCreationSourceType>>;
  not?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
};

export type EnumChannelCreationSourceTypeWithAggregatesFilter = {
  equals?: Maybe<ChannelCreationSourceType>;
  in?: Maybe<Array<ChannelCreationSourceType>>;
  notIn?: Maybe<Array<ChannelCreationSourceType>>;
  not?: Maybe<NestedEnumChannelCreationSourceTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
  _max?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
};

export type EnumChannelMessageAttachmentEntityTypeNullableFilter = {
  equals?: Maybe<ChannelMessageAttachmentEntityType>;
  in?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
};

export type EnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelMessageAttachmentEntityType>;
  in?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
};

export type EnumChannelMessageAttachmentTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChannelMessageAttachmentType>;
};

export type EnumChannelMessageAttachmentTypeFilter = {
  equals?: Maybe<ChannelMessageAttachmentType>;
  in?: Maybe<Array<ChannelMessageAttachmentType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
};

export type EnumChannelMessageAttachmentTypeWithAggregatesFilter = {
  equals?: Maybe<ChannelMessageAttachmentType>;
  in?: Maybe<Array<ChannelMessageAttachmentType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
};

export type EnumChannelMessageAttachmentUploadStatusNullableFilter = {
  equals?: Maybe<ChannelMessageAttachmentUploadStatus>;
  in?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
};

export type EnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelMessageAttachmentUploadStatus>;
  in?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
};

export type EnumChannelStatusActionFieldUpdateOperationsInput = {
  set?: Maybe<ChannelStatusAction>;
};

export type EnumChannelStatusActionFilter = {
  equals?: Maybe<ChannelStatusAction>;
  in?: Maybe<Array<ChannelStatusAction>>;
  notIn?: Maybe<Array<ChannelStatusAction>>;
  not?: Maybe<NestedEnumChannelStatusActionFilter>;
};

export type EnumChannelStatusActionWithAggregatesFilter = {
  equals?: Maybe<ChannelStatusAction>;
  in?: Maybe<Array<ChannelStatusAction>>;
  notIn?: Maybe<Array<ChannelStatusAction>>;
  not?: Maybe<NestedEnumChannelStatusActionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChannelStatusActionFilter>;
  _max?: Maybe<NestedEnumChannelStatusActionFilter>;
};

export type EnumChannelTypeNullableFilter = {
  equals?: Maybe<ChannelType>;
  in?: Maybe<Array<ChannelType>>;
  notIn?: Maybe<Array<ChannelType>>;
  not?: Maybe<NestedEnumChannelTypeNullableFilter>;
};

export type EnumChannelTypeNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelType>;
  in?: Maybe<Array<ChannelType>>;
  notIn?: Maybe<Array<ChannelType>>;
  not?: Maybe<NestedEnumChannelTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelTypeNullableFilter>;
  _max?: Maybe<NestedEnumChannelTypeNullableFilter>;
};

export type EnumChannelVisibilityNullableFilter = {
  equals?: Maybe<ChannelVisibility>;
  in?: Maybe<Array<ChannelVisibility>>;
  notIn?: Maybe<Array<ChannelVisibility>>;
  not?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
};

export type EnumChannelVisibilityNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelVisibility>;
  in?: Maybe<Array<ChannelVisibility>>;
  notIn?: Maybe<Array<ChannelVisibility>>;
  not?: Maybe<NestedEnumChannelVisibilityNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
  _max?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
};

export type EnumChargeCaptureTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChargeCaptureType>;
};

export type EnumChargeCaptureTypeFilter = {
  equals?: Maybe<ChargeCaptureType>;
  in?: Maybe<Array<ChargeCaptureType>>;
  notIn?: Maybe<Array<ChargeCaptureType>>;
  not?: Maybe<NestedEnumChargeCaptureTypeFilter>;
};

export type EnumChargeCaptureTypeWithAggregatesFilter = {
  equals?: Maybe<ChargeCaptureType>;
  in?: Maybe<Array<ChargeCaptureType>>;
  notIn?: Maybe<Array<ChargeCaptureType>>;
  not?: Maybe<NestedEnumChargeCaptureTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChargeCaptureTypeFilter>;
  _max?: Maybe<NestedEnumChargeCaptureTypeFilter>;
};

export type EnumClientConnectClinicSoftwareFieldUpdateOperationsInput = {
  set?: Maybe<ClientConnectClinicSoftware>;
};

export type EnumClientConnectClinicSoftwareFilter = {
  equals?: Maybe<ClientConnectClinicSoftware>;
  in?: Maybe<Array<ClientConnectClinicSoftware>>;
  notIn?: Maybe<Array<ClientConnectClinicSoftware>>;
  not?: Maybe<NestedEnumClientConnectClinicSoftwareFilter>;
};

export type EnumClientConnectClinicSoftwareWithAggregatesFilter = {
  equals?: Maybe<ClientConnectClinicSoftware>;
  in?: Maybe<Array<ClientConnectClinicSoftware>>;
  notIn?: Maybe<Array<ClientConnectClinicSoftware>>;
  not?: Maybe<NestedEnumClientConnectClinicSoftwareWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumClientConnectClinicSoftwareFilter>;
  _max?: Maybe<NestedEnumClientConnectClinicSoftwareFilter>;
};

export type EnumClientConnectSystemFieldUpdateOperationsInput = {
  set?: Maybe<ClientConnectSystem>;
};

export type EnumClientConnectSystemFilter = {
  equals?: Maybe<ClientConnectSystem>;
  in?: Maybe<Array<ClientConnectSystem>>;
  notIn?: Maybe<Array<ClientConnectSystem>>;
  not?: Maybe<NestedEnumClientConnectSystemFilter>;
};

export type EnumClientConnectSystemWithAggregatesFilter = {
  equals?: Maybe<ClientConnectSystem>;
  in?: Maybe<Array<ClientConnectSystem>>;
  notIn?: Maybe<Array<ClientConnectSystem>>;
  not?: Maybe<NestedEnumClientConnectSystemWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumClientConnectSystemFilter>;
  _max?: Maybe<NestedEnumClientConnectSystemFilter>;
};

export type EnumClinicEntityCreationSourceNullableFilter = {
  equals?: Maybe<ClinicEntityCreationSource>;
  in?: Maybe<Array<ClinicEntityCreationSource>>;
  notIn?: Maybe<Array<ClinicEntityCreationSource>>;
  not?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
};

export type EnumClinicEntityCreationSourceNullableWithAggregatesFilter = {
  equals?: Maybe<ClinicEntityCreationSource>;
  in?: Maybe<Array<ClinicEntityCreationSource>>;
  notIn?: Maybe<Array<ClinicEntityCreationSource>>;
  not?: Maybe<NestedEnumClinicEntityCreationSourceNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
  _max?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
};

export type EnumClinicEntityPhoneNumberSmsStatusNullableFilter = {
  equals?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  in?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  notIn?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  not?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
};

export type EnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter = {
  equals?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  in?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  notIn?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  not?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  _max?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
};

export type EnumClinicOfficeHoursTypeFieldUpdateOperationsInput = {
  set?: Maybe<ClinicOfficeHoursType>;
};

export type EnumClinicOfficeHoursTypeFilter = {
  equals?: Maybe<ClinicOfficeHoursType>;
  in?: Maybe<Array<ClinicOfficeHoursType>>;
  notIn?: Maybe<Array<ClinicOfficeHoursType>>;
  not?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
};

export type EnumClinicOfficeHoursTypeWithAggregatesFilter = {
  equals?: Maybe<ClinicOfficeHoursType>;
  in?: Maybe<Array<ClinicOfficeHoursType>>;
  notIn?: Maybe<Array<ClinicOfficeHoursType>>;
  not?: Maybe<NestedEnumClinicOfficeHoursTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
  _max?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
};

export type EnumClinicPetParentAddressTypeNullableFilter = {
  equals?: Maybe<ClinicPetParentAddressType>;
  in?: Maybe<Array<ClinicPetParentAddressType>>;
  notIn?: Maybe<Array<ClinicPetParentAddressType>>;
  not?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
};

export type EnumClinicPetParentAddressTypeNullableWithAggregatesFilter = {
  equals?: Maybe<ClinicPetParentAddressType>;
  in?: Maybe<Array<ClinicPetParentAddressType>>;
  notIn?: Maybe<Array<ClinicPetParentAddressType>>;
  not?: Maybe<NestedEnumClinicPetParentAddressTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
  _max?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
};

export type EnumClinicPimsIntegrationTypeFieldUpdateOperationsInput = {
  set?: Maybe<ClinicPimsIntegrationType>;
};

export type EnumClinicPimsIntegrationTypeFilter = {
  equals?: Maybe<ClinicPimsIntegrationType>;
  in?: Maybe<Array<ClinicPimsIntegrationType>>;
  notIn?: Maybe<Array<ClinicPimsIntegrationType>>;
  not?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
};

export type EnumClinicPimsIntegrationTypeWithAggregatesFilter = {
  equals?: Maybe<ClinicPimsIntegrationType>;
  in?: Maybe<Array<ClinicPimsIntegrationType>>;
  notIn?: Maybe<Array<ClinicPimsIntegrationType>>;
  not?: Maybe<NestedEnumClinicPimsIntegrationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
  _max?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
};

export type EnumConditionAttributeFieldUpdateOperationsInput = {
  set?: Maybe<ConditionAttribute>;
};

export type EnumConditionAttributeFilter = {
  equals?: Maybe<ConditionAttribute>;
  in?: Maybe<Array<ConditionAttribute>>;
  notIn?: Maybe<Array<ConditionAttribute>>;
  not?: Maybe<NestedEnumConditionAttributeFilter>;
};

export type EnumConditionAttributeWithAggregatesFilter = {
  equals?: Maybe<ConditionAttribute>;
  in?: Maybe<Array<ConditionAttribute>>;
  notIn?: Maybe<Array<ConditionAttribute>>;
  not?: Maybe<NestedEnumConditionAttributeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumConditionAttributeFilter>;
  _max?: Maybe<NestedEnumConditionAttributeFilter>;
};

export type EnumConditionEvaluationStatusFieldUpdateOperationsInput = {
  set?: Maybe<ConditionEvaluationStatus>;
};

export type EnumConditionEvaluationStatusFilter = {
  equals?: Maybe<ConditionEvaluationStatus>;
  in?: Maybe<Array<ConditionEvaluationStatus>>;
  notIn?: Maybe<Array<ConditionEvaluationStatus>>;
  not?: Maybe<NestedEnumConditionEvaluationStatusFilter>;
};

export type EnumConditionEvaluationStatusWithAggregatesFilter = {
  equals?: Maybe<ConditionEvaluationStatus>;
  in?: Maybe<Array<ConditionEvaluationStatus>>;
  notIn?: Maybe<Array<ConditionEvaluationStatus>>;
  not?: Maybe<NestedEnumConditionEvaluationStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumConditionEvaluationStatusFilter>;
  _max?: Maybe<NestedEnumConditionEvaluationStatusFilter>;
};

export type EnumConditionOperatorFieldUpdateOperationsInput = {
  set?: Maybe<ConditionOperator>;
};

export type EnumConditionOperatorFilter = {
  equals?: Maybe<ConditionOperator>;
  in?: Maybe<Array<ConditionOperator>>;
  notIn?: Maybe<Array<ConditionOperator>>;
  not?: Maybe<NestedEnumConditionOperatorFilter>;
};

export type EnumConditionOperatorWithAggregatesFilter = {
  equals?: Maybe<ConditionOperator>;
  in?: Maybe<Array<ConditionOperator>>;
  notIn?: Maybe<Array<ConditionOperator>>;
  not?: Maybe<NestedEnumConditionOperatorWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumConditionOperatorFilter>;
  _max?: Maybe<NestedEnumConditionOperatorFilter>;
};

export type EnumConditionSetOperatorFieldUpdateOperationsInput = {
  set?: Maybe<ConditionSetOperator>;
};

export type EnumConditionSetOperatorFilter = {
  equals?: Maybe<ConditionSetOperator>;
  in?: Maybe<Array<ConditionSetOperator>>;
  notIn?: Maybe<Array<ConditionSetOperator>>;
  not?: Maybe<NestedEnumConditionSetOperatorFilter>;
};

export type EnumConditionSetOperatorWithAggregatesFilter = {
  equals?: Maybe<ConditionSetOperator>;
  in?: Maybe<Array<ConditionSetOperator>>;
  notIn?: Maybe<Array<ConditionSetOperator>>;
  not?: Maybe<NestedEnumConditionSetOperatorWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumConditionSetOperatorFilter>;
  _max?: Maybe<NestedEnumConditionSetOperatorFilter>;
};

export type EnumConditionalEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<ConditionalEntityType>;
};

export type EnumConditionalEntityTypeFilter = {
  equals?: Maybe<ConditionalEntityType>;
  in?: Maybe<Array<ConditionalEntityType>>;
  notIn?: Maybe<Array<ConditionalEntityType>>;
  not?: Maybe<NestedEnumConditionalEntityTypeFilter>;
};

export type EnumConditionalEntityTypeWithAggregatesFilter = {
  equals?: Maybe<ConditionalEntityType>;
  in?: Maybe<Array<ConditionalEntityType>>;
  notIn?: Maybe<Array<ConditionalEntityType>>;
  not?: Maybe<NestedEnumConditionalEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumConditionalEntityTypeFilter>;
  _max?: Maybe<NestedEnumConditionalEntityTypeFilter>;
};

export type EnumContactEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<ContactEntityType>;
};

export type EnumContactEntityTypeFilter = {
  equals?: Maybe<ContactEntityType>;
  in?: Maybe<Array<ContactEntityType>>;
  notIn?: Maybe<Array<ContactEntityType>>;
  not?: Maybe<NestedEnumContactEntityTypeFilter>;
};

export type EnumContactEntityTypeWithAggregatesFilter = {
  equals?: Maybe<ContactEntityType>;
  in?: Maybe<Array<ContactEntityType>>;
  notIn?: Maybe<Array<ContactEntityType>>;
  not?: Maybe<NestedEnumContactEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumContactEntityTypeFilter>;
  _max?: Maybe<NestedEnumContactEntityTypeFilter>;
};

export type EnumContactVerificationMethodNullableFilter = {
  equals?: Maybe<ContactVerificationMethod>;
  in?: Maybe<Array<ContactVerificationMethod>>;
  notIn?: Maybe<Array<ContactVerificationMethod>>;
  not?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
};

export type EnumContactVerificationMethodNullableWithAggregatesFilter = {
  equals?: Maybe<ContactVerificationMethod>;
  in?: Maybe<Array<ContactVerificationMethod>>;
  notIn?: Maybe<Array<ContactVerificationMethod>>;
  not?: Maybe<NestedEnumContactVerificationMethodNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
  _max?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
};

export type EnumContextEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<ContextEntityType>;
};

export type EnumContextEntityTypeFilter = {
  equals?: Maybe<ContextEntityType>;
  in?: Maybe<Array<ContextEntityType>>;
  notIn?: Maybe<Array<ContextEntityType>>;
  not?: Maybe<NestedEnumContextEntityTypeFilter>;
};

export type EnumContextEntityTypeWithAggregatesFilter = {
  equals?: Maybe<ContextEntityType>;
  in?: Maybe<Array<ContextEntityType>>;
  notIn?: Maybe<Array<ContextEntityType>>;
  not?: Maybe<NestedEnumContextEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumContextEntityTypeFilter>;
  _max?: Maybe<NestedEnumContextEntityTypeFilter>;
};

export type EnumCurrencyFieldUpdateOperationsInput = {
  set?: Maybe<Currency>;
};

export type EnumCurrencyFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  notIn?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyFilter>;
};

export type EnumCurrencyWithAggregatesFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  notIn?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCurrencyFilter>;
  _max?: Maybe<NestedEnumCurrencyFilter>;
};

export type EnumCustomFieldDesignationFieldUpdateOperationsInput = {
  set?: Maybe<CustomFieldDesignation>;
};

export type EnumCustomFieldDesignationFilter = {
  equals?: Maybe<CustomFieldDesignation>;
  in?: Maybe<Array<CustomFieldDesignation>>;
  notIn?: Maybe<Array<CustomFieldDesignation>>;
  not?: Maybe<NestedEnumCustomFieldDesignationFilter>;
};

export type EnumCustomFieldDesignationWithAggregatesFilter = {
  equals?: Maybe<CustomFieldDesignation>;
  in?: Maybe<Array<CustomFieldDesignation>>;
  notIn?: Maybe<Array<CustomFieldDesignation>>;
  not?: Maybe<NestedEnumCustomFieldDesignationWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCustomFieldDesignationFilter>;
  _max?: Maybe<NestedEnumCustomFieldDesignationFilter>;
};

export type EnumCustomFieldNormalizedDataTypeFieldUpdateOperationsInput = {
  set?: Maybe<CustomFieldNormalizedDataType>;
};

export type EnumCustomFieldNormalizedDataTypeFilter = {
  equals?: Maybe<CustomFieldNormalizedDataType>;
  in?: Maybe<Array<CustomFieldNormalizedDataType>>;
  notIn?: Maybe<Array<CustomFieldNormalizedDataType>>;
  not?: Maybe<NestedEnumCustomFieldNormalizedDataTypeFilter>;
};

export type EnumCustomFieldNormalizedDataTypeWithAggregatesFilter = {
  equals?: Maybe<CustomFieldNormalizedDataType>;
  in?: Maybe<Array<CustomFieldNormalizedDataType>>;
  notIn?: Maybe<Array<CustomFieldNormalizedDataType>>;
  not?: Maybe<NestedEnumCustomFieldNormalizedDataTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCustomFieldNormalizedDataTypeFilter>;
  _max?: Maybe<NestedEnumCustomFieldNormalizedDataTypeFilter>;
};

export type EnumCustomFieldPimsSyncTypeFieldUpdateOperationsInput = {
  set?: Maybe<CustomFieldPimsSyncType>;
};

export type EnumCustomFieldPimsSyncTypeFilter = {
  equals?: Maybe<CustomFieldPimsSyncType>;
  in?: Maybe<Array<CustomFieldPimsSyncType>>;
  notIn?: Maybe<Array<CustomFieldPimsSyncType>>;
  not?: Maybe<NestedEnumCustomFieldPimsSyncTypeFilter>;
};

export type EnumCustomFieldPimsSyncTypeWithAggregatesFilter = {
  equals?: Maybe<CustomFieldPimsSyncType>;
  in?: Maybe<Array<CustomFieldPimsSyncType>>;
  notIn?: Maybe<Array<CustomFieldPimsSyncType>>;
  not?: Maybe<NestedEnumCustomFieldPimsSyncTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCustomFieldPimsSyncTypeFilter>;
  _max?: Maybe<NestedEnumCustomFieldPimsSyncTypeFilter>;
};

export type EnumCustomFieldTargetTypeFieldUpdateOperationsInput = {
  set?: Maybe<CustomFieldTargetType>;
};

export type EnumCustomFieldTargetTypeFilter = {
  equals?: Maybe<CustomFieldTargetType>;
  in?: Maybe<Array<CustomFieldTargetType>>;
  notIn?: Maybe<Array<CustomFieldTargetType>>;
  not?: Maybe<NestedEnumCustomFieldTargetTypeFilter>;
};

export type EnumCustomFieldTargetTypeWithAggregatesFilter = {
  equals?: Maybe<CustomFieldTargetType>;
  in?: Maybe<Array<CustomFieldTargetType>>;
  notIn?: Maybe<Array<CustomFieldTargetType>>;
  not?: Maybe<NestedEnumCustomFieldTargetTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCustomFieldTargetTypeFilter>;
  _max?: Maybe<NestedEnumCustomFieldTargetTypeFilter>;
};

export type EnumCustomFieldsDataSourceNullableFilter = {
  equals?: Maybe<CustomFieldsDataSource>;
  in?: Maybe<Array<CustomFieldsDataSource>>;
  notIn?: Maybe<Array<CustomFieldsDataSource>>;
  not?: Maybe<NestedEnumCustomFieldsDataSourceNullableFilter>;
};

export type EnumCustomFieldsDataSourceNullableWithAggregatesFilter = {
  equals?: Maybe<CustomFieldsDataSource>;
  in?: Maybe<Array<CustomFieldsDataSource>>;
  notIn?: Maybe<Array<CustomFieldsDataSource>>;
  not?: Maybe<NestedEnumCustomFieldsDataSourceNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCustomFieldsDataSourceNullableFilter>;
  _max?: Maybe<NestedEnumCustomFieldsDataSourceNullableFilter>;
};

export type EnumDeviceOsNullableFilter = {
  equals?: Maybe<DeviceOs>;
  in?: Maybe<Array<DeviceOs>>;
  notIn?: Maybe<Array<DeviceOs>>;
  not?: Maybe<NestedEnumDeviceOsNullableFilter>;
};

export type EnumDeviceOsNullableWithAggregatesFilter = {
  equals?: Maybe<DeviceOs>;
  in?: Maybe<Array<DeviceOs>>;
  notIn?: Maybe<Array<DeviceOs>>;
  not?: Maybe<NestedEnumDeviceOsNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumDeviceOsNullableFilter>;
  _max?: Maybe<NestedEnumDeviceOsNullableFilter>;
};

export type EnumDeviceTypeNullableFilter = {
  equals?: Maybe<DeviceType>;
  in?: Maybe<Array<DeviceType>>;
  notIn?: Maybe<Array<DeviceType>>;
  not?: Maybe<NestedEnumDeviceTypeNullableFilter>;
};

export type EnumDeviceTypeNullableWithAggregatesFilter = {
  equals?: Maybe<DeviceType>;
  in?: Maybe<Array<DeviceType>>;
  notIn?: Maybe<Array<DeviceType>>;
  not?: Maybe<NestedEnumDeviceTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumDeviceTypeNullableFilter>;
  _max?: Maybe<NestedEnumDeviceTypeNullableFilter>;
};

export type EnumDirectBookingAppointmentTypeClientTypeFieldUpdateOperationsInput = {
  set?: Maybe<DirectBookingAppointmentTypeClientType>;
};

export type EnumDirectBookingAppointmentTypeClientTypeFilter = {
  equals?: Maybe<DirectBookingAppointmentTypeClientType>;
  in?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
  notIn?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
  not?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeFilter>;
};

export type EnumDirectBookingAppointmentTypeClientTypeWithAggregatesFilter = {
  equals?: Maybe<DirectBookingAppointmentTypeClientType>;
  in?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
  notIn?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
  not?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeFilter>;
  _max?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeFilter>;
};

export type EnumDirectBookingSpeciesOptionFieldUpdateOperationsInput = {
  set?: Maybe<DirectBookingSpeciesOption>;
};

export type EnumDirectBookingSpeciesOptionFilter = {
  equals?: Maybe<DirectBookingSpeciesOption>;
  in?: Maybe<Array<DirectBookingSpeciesOption>>;
  notIn?: Maybe<Array<DirectBookingSpeciesOption>>;
  not?: Maybe<NestedEnumDirectBookingSpeciesOptionFilter>;
};

export type EnumDirectBookingSpeciesOptionWithAggregatesFilter = {
  equals?: Maybe<DirectBookingSpeciesOption>;
  in?: Maybe<Array<DirectBookingSpeciesOption>>;
  notIn?: Maybe<Array<DirectBookingSpeciesOption>>;
  not?: Maybe<NestedEnumDirectBookingSpeciesOptionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumDirectBookingSpeciesOptionFilter>;
  _max?: Maybe<NestedEnumDirectBookingSpeciesOptionFilter>;
};

export type EnumDocumentationCategoryFieldUpdateOperationsInput = {
  set?: Maybe<DocumentationCategory>;
};

export type EnumDocumentationCategoryFilter = {
  equals?: Maybe<DocumentationCategory>;
  in?: Maybe<Array<DocumentationCategory>>;
  notIn?: Maybe<Array<DocumentationCategory>>;
  not?: Maybe<NestedEnumDocumentationCategoryFilter>;
};

export type EnumDocumentationCategoryWithAggregatesFilter = {
  equals?: Maybe<DocumentationCategory>;
  in?: Maybe<Array<DocumentationCategory>>;
  notIn?: Maybe<Array<DocumentationCategory>>;
  not?: Maybe<NestedEnumDocumentationCategoryWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumDocumentationCategoryFilter>;
  _max?: Maybe<NestedEnumDocumentationCategoryFilter>;
};

export type EnumDocumentationProviderFieldUpdateOperationsInput = {
  set?: Maybe<DocumentationProvider>;
};

export type EnumDocumentationProviderFilter = {
  equals?: Maybe<DocumentationProvider>;
  in?: Maybe<Array<DocumentationProvider>>;
  notIn?: Maybe<Array<DocumentationProvider>>;
  not?: Maybe<NestedEnumDocumentationProviderFilter>;
};

export type EnumDocumentationProviderWithAggregatesFilter = {
  equals?: Maybe<DocumentationProvider>;
  in?: Maybe<Array<DocumentationProvider>>;
  notIn?: Maybe<Array<DocumentationProvider>>;
  not?: Maybe<NestedEnumDocumentationProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumDocumentationProviderFilter>;
  _max?: Maybe<NestedEnumDocumentationProviderFilter>;
};

export type EnumEmailCampaignCustomListRecipientStatusFieldUpdateOperationsInput = {
  set?: Maybe<EmailCampaignCustomListRecipientStatus>;
};

export type EnumEmailCampaignCustomListRecipientStatusFilter = {
  equals?: Maybe<EmailCampaignCustomListRecipientStatus>;
  in?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
  notIn?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
  not?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusFilter>;
};

export type EnumEmailCampaignCustomListRecipientStatusWithAggregatesFilter = {
  equals?: Maybe<EmailCampaignCustomListRecipientStatus>;
  in?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
  notIn?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
  not?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusFilter>;
  _max?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusFilter>;
};

export type EnumEmailCampaignLogActionFieldUpdateOperationsInput = {
  set?: Maybe<EmailCampaignLogAction>;
};

export type EnumEmailCampaignLogActionFilter = {
  equals?: Maybe<EmailCampaignLogAction>;
  in?: Maybe<Array<EmailCampaignLogAction>>;
  notIn?: Maybe<Array<EmailCampaignLogAction>>;
  not?: Maybe<NestedEnumEmailCampaignLogActionFilter>;
};

export type EnumEmailCampaignLogActionWithAggregatesFilter = {
  equals?: Maybe<EmailCampaignLogAction>;
  in?: Maybe<Array<EmailCampaignLogAction>>;
  notIn?: Maybe<Array<EmailCampaignLogAction>>;
  not?: Maybe<NestedEnumEmailCampaignLogActionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailCampaignLogActionFilter>;
  _max?: Maybe<NestedEnumEmailCampaignLogActionFilter>;
};

export type EnumEmailCampaignLogSkipReasonNullableFilter = {
  equals?: Maybe<EmailCampaignLogSkipReason>;
  in?: Maybe<Array<EmailCampaignLogSkipReason>>;
  notIn?: Maybe<Array<EmailCampaignLogSkipReason>>;
  not?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableFilter>;
};

export type EnumEmailCampaignLogSkipReasonNullableWithAggregatesFilter = {
  equals?: Maybe<EmailCampaignLogSkipReason>;
  in?: Maybe<Array<EmailCampaignLogSkipReason>>;
  notIn?: Maybe<Array<EmailCampaignLogSkipReason>>;
  not?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableFilter>;
  _max?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableFilter>;
};

export type EnumEmailCampaignSegmentationTypeFieldUpdateOperationsInput = {
  set?: Maybe<EmailCampaignSegmentationType>;
};

export type EnumEmailCampaignSegmentationTypeFilter = {
  equals?: Maybe<EmailCampaignSegmentationType>;
  in?: Maybe<Array<EmailCampaignSegmentationType>>;
  notIn?: Maybe<Array<EmailCampaignSegmentationType>>;
  not?: Maybe<NestedEnumEmailCampaignSegmentationTypeFilter>;
};

export type EnumEmailCampaignSegmentationTypeWithAggregatesFilter = {
  equals?: Maybe<EmailCampaignSegmentationType>;
  in?: Maybe<Array<EmailCampaignSegmentationType>>;
  notIn?: Maybe<Array<EmailCampaignSegmentationType>>;
  not?: Maybe<NestedEnumEmailCampaignSegmentationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailCampaignSegmentationTypeFilter>;
  _max?: Maybe<NestedEnumEmailCampaignSegmentationTypeFilter>;
};

export type EnumEmailCampaignStatusFieldUpdateOperationsInput = {
  set?: Maybe<EmailCampaignStatus>;
};

export type EnumEmailCampaignStatusFilter = {
  equals?: Maybe<EmailCampaignStatus>;
  in?: Maybe<Array<EmailCampaignStatus>>;
  notIn?: Maybe<Array<EmailCampaignStatus>>;
  not?: Maybe<NestedEnumEmailCampaignStatusFilter>;
};

export type EnumEmailCampaignStatusWithAggregatesFilter = {
  equals?: Maybe<EmailCampaignStatus>;
  in?: Maybe<Array<EmailCampaignStatus>>;
  notIn?: Maybe<Array<EmailCampaignStatus>>;
  not?: Maybe<NestedEnumEmailCampaignStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailCampaignStatusFilter>;
  _max?: Maybe<NestedEnumEmailCampaignStatusFilter>;
};

export type EnumEmailCampaignTypeFieldUpdateOperationsInput = {
  set?: Maybe<EmailCampaignType>;
};

export type EnumEmailCampaignTypeFilter = {
  equals?: Maybe<EmailCampaignType>;
  in?: Maybe<Array<EmailCampaignType>>;
  notIn?: Maybe<Array<EmailCampaignType>>;
  not?: Maybe<NestedEnumEmailCampaignTypeFilter>;
};

export type EnumEmailCampaignTypeWithAggregatesFilter = {
  equals?: Maybe<EmailCampaignType>;
  in?: Maybe<Array<EmailCampaignType>>;
  notIn?: Maybe<Array<EmailCampaignType>>;
  not?: Maybe<NestedEnumEmailCampaignTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailCampaignTypeFilter>;
  _max?: Maybe<NestedEnumEmailCampaignTypeFilter>;
};

export type EnumEmailCampaignUnsubscribeSourceFieldUpdateOperationsInput = {
  set?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EnumEmailCampaignUnsubscribeSourceFilter = {
  equals?: Maybe<EmailCampaignUnsubscribeSource>;
  in?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
  notIn?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
  not?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceFilter>;
};

export type EnumEmailCampaignUnsubscribeSourceWithAggregatesFilter = {
  equals?: Maybe<EmailCampaignUnsubscribeSource>;
  in?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
  notIn?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
  not?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceFilter>;
  _max?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceFilter>;
};

export type EnumEmailProviderFieldUpdateOperationsInput = {
  set?: Maybe<EmailProvider>;
};

export type EnumEmailProviderFilter = {
  equals?: Maybe<EmailProvider>;
  in?: Maybe<Array<EmailProvider>>;
  notIn?: Maybe<Array<EmailProvider>>;
  not?: Maybe<NestedEnumEmailProviderFilter>;
};

export type EnumEmailProviderLogEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<EmailProviderLogEntityType>;
};

export type EnumEmailProviderLogEntityTypeFilter = {
  equals?: Maybe<EmailProviderLogEntityType>;
  in?: Maybe<Array<EmailProviderLogEntityType>>;
  notIn?: Maybe<Array<EmailProviderLogEntityType>>;
  not?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
};

export type EnumEmailProviderLogEntityTypeWithAggregatesFilter = {
  equals?: Maybe<EmailProviderLogEntityType>;
  in?: Maybe<Array<EmailProviderLogEntityType>>;
  notIn?: Maybe<Array<EmailProviderLogEntityType>>;
  not?: Maybe<NestedEnumEmailProviderLogEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
  _max?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
};

export type EnumEmailProviderWithAggregatesFilter = {
  equals?: Maybe<EmailProvider>;
  in?: Maybe<Array<EmailProvider>>;
  notIn?: Maybe<Array<EmailProvider>>;
  not?: Maybe<NestedEnumEmailProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailProviderFilter>;
  _max?: Maybe<NestedEnumEmailProviderFilter>;
};

export type EnumEmailTemplateTypeFieldUpdateOperationsInput = {
  set?: Maybe<EmailTemplateType>;
};

export type EnumEmailTemplateTypeFilter = {
  equals?: Maybe<EmailTemplateType>;
  in?: Maybe<Array<EmailTemplateType>>;
  notIn?: Maybe<Array<EmailTemplateType>>;
  not?: Maybe<NestedEnumEmailTemplateTypeFilter>;
};

export type EnumEmailTemplateTypeWithAggregatesFilter = {
  equals?: Maybe<EmailTemplateType>;
  in?: Maybe<Array<EmailTemplateType>>;
  notIn?: Maybe<Array<EmailTemplateType>>;
  not?: Maybe<NestedEnumEmailTemplateTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailTemplateTypeFilter>;
  _max?: Maybe<NestedEnumEmailTemplateTypeFilter>;
};

export type EnumEnrollmentStatusFieldUpdateOperationsInput = {
  set?: Maybe<EnrollmentStatus>;
};

export type EnumEnrollmentStatusFilter = {
  equals?: Maybe<EnrollmentStatus>;
  in?: Maybe<Array<EnrollmentStatus>>;
  notIn?: Maybe<Array<EnrollmentStatus>>;
  not?: Maybe<NestedEnumEnrollmentStatusFilter>;
};

export type EnumEnrollmentStatusWithAggregatesFilter = {
  equals?: Maybe<EnrollmentStatus>;
  in?: Maybe<Array<EnrollmentStatus>>;
  notIn?: Maybe<Array<EnrollmentStatus>>;
  not?: Maybe<NestedEnumEnrollmentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEnrollmentStatusFilter>;
  _max?: Maybe<NestedEnumEnrollmentStatusFilter>;
};

export type EnumFeatureFlagStateFieldUpdateOperationsInput = {
  set?: Maybe<FeatureFlagState>;
};

export type EnumFeatureFlagStateFilter = {
  equals?: Maybe<FeatureFlagState>;
  in?: Maybe<Array<FeatureFlagState>>;
  notIn?: Maybe<Array<FeatureFlagState>>;
  not?: Maybe<NestedEnumFeatureFlagStateFilter>;
};

export type EnumFeatureFlagStateWithAggregatesFilter = {
  equals?: Maybe<FeatureFlagState>;
  in?: Maybe<Array<FeatureFlagState>>;
  notIn?: Maybe<Array<FeatureFlagState>>;
  not?: Maybe<NestedEnumFeatureFlagStateWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFeatureFlagStateFilter>;
  _max?: Maybe<NestedEnumFeatureFlagStateFilter>;
};

export type EnumFeatureFlagTypeFieldUpdateOperationsInput = {
  set?: Maybe<FeatureFlagType>;
};

export type EnumFeatureFlagTypeFilter = {
  equals?: Maybe<FeatureFlagType>;
  in?: Maybe<Array<FeatureFlagType>>;
  notIn?: Maybe<Array<FeatureFlagType>>;
  not?: Maybe<NestedEnumFeatureFlagTypeFilter>;
};

export type EnumFeatureFlagTypeWithAggregatesFilter = {
  equals?: Maybe<FeatureFlagType>;
  in?: Maybe<Array<FeatureFlagType>>;
  notIn?: Maybe<Array<FeatureFlagType>>;
  not?: Maybe<NestedEnumFeatureFlagTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFeatureFlagTypeFilter>;
  _max?: Maybe<NestedEnumFeatureFlagTypeFilter>;
};

export type EnumFeeCollectionFieldUpdateOperationsInput = {
  set?: Maybe<FeeCollection>;
};

export type EnumFeeCollectionFilter = {
  equals?: Maybe<FeeCollection>;
  in?: Maybe<Array<FeeCollection>>;
  notIn?: Maybe<Array<FeeCollection>>;
  not?: Maybe<NestedEnumFeeCollectionFilter>;
};

export type EnumFeeCollectionWithAggregatesFilter = {
  equals?: Maybe<FeeCollection>;
  in?: Maybe<Array<FeeCollection>>;
  notIn?: Maybe<Array<FeeCollection>>;
  not?: Maybe<NestedEnumFeeCollectionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFeeCollectionFilter>;
  _max?: Maybe<NestedEnumFeeCollectionFilter>;
};

export type EnumFilterSelectionTypeFieldUpdateOperationsInput = {
  set?: Maybe<FilterSelectionType>;
};

export type EnumFilterSelectionTypeFilter = {
  equals?: Maybe<FilterSelectionType>;
  in?: Maybe<Array<FilterSelectionType>>;
  notIn?: Maybe<Array<FilterSelectionType>>;
  not?: Maybe<NestedEnumFilterSelectionTypeFilter>;
};

export type EnumFilterSelectionTypeWithAggregatesFilter = {
  equals?: Maybe<FilterSelectionType>;
  in?: Maybe<Array<FilterSelectionType>>;
  notIn?: Maybe<Array<FilterSelectionType>>;
  not?: Maybe<NestedEnumFilterSelectionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFilterSelectionTypeFilter>;
  _max?: Maybe<NestedEnumFilterSelectionTypeFilter>;
};

export type EnumFinancialTransactionTypeFieldUpdateOperationsInput = {
  set?: Maybe<FinancialTransactionType>;
};

export type EnumFinancialTransactionTypeFilter = {
  equals?: Maybe<FinancialTransactionType>;
  in?: Maybe<Array<FinancialTransactionType>>;
  notIn?: Maybe<Array<FinancialTransactionType>>;
  not?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
};

export type EnumFinancialTransactionTypeWithAggregatesFilter = {
  equals?: Maybe<FinancialTransactionType>;
  in?: Maybe<Array<FinancialTransactionType>>;
  notIn?: Maybe<Array<FinancialTransactionType>>;
  not?: Maybe<NestedEnumFinancialTransactionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
  _max?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
};

export type EnumFormTemplateTypeFieldUpdateOperationsInput = {
  set?: Maybe<FormTemplateType>;
};

export type EnumFormTemplateTypeFilter = {
  equals?: Maybe<FormTemplateType>;
  in?: Maybe<Array<FormTemplateType>>;
  notIn?: Maybe<Array<FormTemplateType>>;
  not?: Maybe<NestedEnumFormTemplateTypeFilter>;
};

export type EnumFormTemplateTypeWithAggregatesFilter = {
  equals?: Maybe<FormTemplateType>;
  in?: Maybe<Array<FormTemplateType>>;
  notIn?: Maybe<Array<FormTemplateType>>;
  not?: Maybe<NestedEnumFormTemplateTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFormTemplateTypeFilter>;
  _max?: Maybe<NestedEnumFormTemplateTypeFilter>;
};

export type EnumHillsToHomeResultTypeFieldUpdateOperationsInput = {
  set?: Maybe<HillsToHomeResultType>;
};

export type EnumHillsToHomeResultTypeFilter = {
  equals?: Maybe<HillsToHomeResultType>;
  in?: Maybe<Array<HillsToHomeResultType>>;
  notIn?: Maybe<Array<HillsToHomeResultType>>;
  not?: Maybe<NestedEnumHillsToHomeResultTypeFilter>;
};

export type EnumHillsToHomeResultTypeWithAggregatesFilter = {
  equals?: Maybe<HillsToHomeResultType>;
  in?: Maybe<Array<HillsToHomeResultType>>;
  notIn?: Maybe<Array<HillsToHomeResultType>>;
  not?: Maybe<NestedEnumHillsToHomeResultTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumHillsToHomeResultTypeFilter>;
  _max?: Maybe<NestedEnumHillsToHomeResultTypeFilter>;
};

export type EnumHillsToHomeSpeciesNullableFilter = {
  equals?: Maybe<HillsToHomeSpecies>;
  in?: Maybe<Array<HillsToHomeSpecies>>;
  notIn?: Maybe<Array<HillsToHomeSpecies>>;
  not?: Maybe<NestedEnumHillsToHomeSpeciesNullableFilter>;
};

export type EnumHillsToHomeSpeciesNullableWithAggregatesFilter = {
  equals?: Maybe<HillsToHomeSpecies>;
  in?: Maybe<Array<HillsToHomeSpecies>>;
  notIn?: Maybe<Array<HillsToHomeSpecies>>;
  not?: Maybe<NestedEnumHillsToHomeSpeciesNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumHillsToHomeSpeciesNullableFilter>;
  _max?: Maybe<NestedEnumHillsToHomeSpeciesNullableFilter>;
};

export type EnumInformAudienceDefinitionStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformAudienceDefinitionStatus>;
};

export type EnumInformAudienceDefinitionStatusFilter = {
  equals?: Maybe<InformAudienceDefinitionStatus>;
  in?: Maybe<Array<InformAudienceDefinitionStatus>>;
  notIn?: Maybe<Array<InformAudienceDefinitionStatus>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionStatusFilter>;
};

export type EnumInformAudienceDefinitionStatusWithAggregatesFilter = {
  equals?: Maybe<InformAudienceDefinitionStatus>;
  in?: Maybe<Array<InformAudienceDefinitionStatus>>;
  notIn?: Maybe<Array<InformAudienceDefinitionStatus>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformAudienceDefinitionStatusFilter>;
  _max?: Maybe<NestedEnumInformAudienceDefinitionStatusFilter>;
};

export type EnumInformAudienceDefinitionTypeFieldUpdateOperationsInput = {
  set?: Maybe<InformAudienceDefinitionType>;
};

export type EnumInformAudienceDefinitionTypeFilter = {
  equals?: Maybe<InformAudienceDefinitionType>;
  in?: Maybe<Array<InformAudienceDefinitionType>>;
  notIn?: Maybe<Array<InformAudienceDefinitionType>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionTypeFilter>;
};

export type EnumInformAudienceDefinitionTypeWithAggregatesFilter = {
  equals?: Maybe<InformAudienceDefinitionType>;
  in?: Maybe<Array<InformAudienceDefinitionType>>;
  notIn?: Maybe<Array<InformAudienceDefinitionType>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformAudienceDefinitionTypeFilter>;
  _max?: Maybe<NestedEnumInformAudienceDefinitionTypeFilter>;
};

export type EnumInformCampaignExecutionStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformCampaignExecutionStatus>;
};

export type EnumInformCampaignExecutionStatusFilter = {
  equals?: Maybe<InformCampaignExecutionStatus>;
  in?: Maybe<Array<InformCampaignExecutionStatus>>;
  notIn?: Maybe<Array<InformCampaignExecutionStatus>>;
  not?: Maybe<NestedEnumInformCampaignExecutionStatusFilter>;
};

export type EnumInformCampaignExecutionStatusWithAggregatesFilter = {
  equals?: Maybe<InformCampaignExecutionStatus>;
  in?: Maybe<Array<InformCampaignExecutionStatus>>;
  notIn?: Maybe<Array<InformCampaignExecutionStatus>>;
  not?: Maybe<NestedEnumInformCampaignExecutionStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformCampaignExecutionStatusFilter>;
  _max?: Maybe<NestedEnumInformCampaignExecutionStatusFilter>;
};

export type EnumInformCampaignStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformCampaignStatus>;
};

export type EnumInformCampaignStatusFilter = {
  equals?: Maybe<InformCampaignStatus>;
  in?: Maybe<Array<InformCampaignStatus>>;
  notIn?: Maybe<Array<InformCampaignStatus>>;
  not?: Maybe<NestedEnumInformCampaignStatusFilter>;
};

export type EnumInformCampaignStatusWithAggregatesFilter = {
  equals?: Maybe<InformCampaignStatus>;
  in?: Maybe<Array<InformCampaignStatus>>;
  notIn?: Maybe<Array<InformCampaignStatus>>;
  not?: Maybe<NestedEnumInformCampaignStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformCampaignStatusFilter>;
  _max?: Maybe<NestedEnumInformCampaignStatusFilter>;
};

export type EnumInformCampaignTypeFieldUpdateOperationsInput = {
  set?: Maybe<InformCampaignType>;
};

export type EnumInformCampaignTypeFilter = {
  equals?: Maybe<InformCampaignType>;
  in?: Maybe<Array<InformCampaignType>>;
  notIn?: Maybe<Array<InformCampaignType>>;
  not?: Maybe<NestedEnumInformCampaignTypeFilter>;
};

export type EnumInformCampaignTypeWithAggregatesFilter = {
  equals?: Maybe<InformCampaignType>;
  in?: Maybe<Array<InformCampaignType>>;
  notIn?: Maybe<Array<InformCampaignType>>;
  not?: Maybe<NestedEnumInformCampaignTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformCampaignTypeFilter>;
  _max?: Maybe<NestedEnumInformCampaignTypeFilter>;
};

export type EnumInformControlGroupEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<InformControlGroupEntityType>;
};

export type EnumInformControlGroupEntityTypeFilter = {
  equals?: Maybe<InformControlGroupEntityType>;
  in?: Maybe<Array<InformControlGroupEntityType>>;
  notIn?: Maybe<Array<InformControlGroupEntityType>>;
  not?: Maybe<NestedEnumInformControlGroupEntityTypeFilter>;
};

export type EnumInformControlGroupEntityTypeWithAggregatesFilter = {
  equals?: Maybe<InformControlGroupEntityType>;
  in?: Maybe<Array<InformControlGroupEntityType>>;
  notIn?: Maybe<Array<InformControlGroupEntityType>>;
  not?: Maybe<NestedEnumInformControlGroupEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformControlGroupEntityTypeFilter>;
  _max?: Maybe<NestedEnumInformControlGroupEntityTypeFilter>;
};

export type EnumInformEmailTemplateStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformEmailTemplateStatus>;
};

export type EnumInformEmailTemplateStatusFilter = {
  equals?: Maybe<InformEmailTemplateStatus>;
  in?: Maybe<Array<InformEmailTemplateStatus>>;
  notIn?: Maybe<Array<InformEmailTemplateStatus>>;
  not?: Maybe<NestedEnumInformEmailTemplateStatusFilter>;
};

export type EnumInformEmailTemplateStatusWithAggregatesFilter = {
  equals?: Maybe<InformEmailTemplateStatus>;
  in?: Maybe<Array<InformEmailTemplateStatus>>;
  notIn?: Maybe<Array<InformEmailTemplateStatus>>;
  not?: Maybe<NestedEnumInformEmailTemplateStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformEmailTemplateStatusFilter>;
  _max?: Maybe<NestedEnumInformEmailTemplateStatusFilter>;
};

export type EnumInformEnrollmentStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformEnrollmentStatus>;
};

export type EnumInformEnrollmentStatusFilter = {
  equals?: Maybe<InformEnrollmentStatus>;
  in?: Maybe<Array<InformEnrollmentStatus>>;
  notIn?: Maybe<Array<InformEnrollmentStatus>>;
  not?: Maybe<NestedEnumInformEnrollmentStatusFilter>;
};

export type EnumInformEnrollmentStatusWithAggregatesFilter = {
  equals?: Maybe<InformEnrollmentStatus>;
  in?: Maybe<Array<InformEnrollmentStatus>>;
  notIn?: Maybe<Array<InformEnrollmentStatus>>;
  not?: Maybe<NestedEnumInformEnrollmentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformEnrollmentStatusFilter>;
  _max?: Maybe<NestedEnumInformEnrollmentStatusFilter>;
};

export type EnumInformPartnerCustomerPharmacyProviderNameFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerCustomerPharmacyProviderName>;
};

export type EnumInformPartnerCustomerPharmacyProviderNameFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameFilter>;
};

export type EnumInformPartnerCustomerPharmacyProviderNameWithAggregatesFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameFilter>;
  _max?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameFilter>;
};

export type EnumInformPartnerCustomerPharmacyProviderStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
};

export type EnumInformPartnerCustomerPharmacyProviderStatusFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusFilter>;
};

export type EnumInformPartnerCustomerPharmacyProviderStatusWithAggregatesFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusFilter>;
  _max?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusFilter>;
};

export type EnumInformPartnerCustomerPharmacyProviderTypeFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerCustomerPharmacyProviderType>;
};

export type EnumInformPartnerCustomerPharmacyProviderTypeFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeFilter>;
};

export type EnumInformPartnerCustomerPharmacyProviderTypeWithAggregatesFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeFilter>;
  _max?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeFilter>;
};

export type EnumInformPartnerIntegrationModelNameFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerIntegrationModelName>;
};

export type EnumInformPartnerIntegrationModelNameFilter = {
  equals?: Maybe<InformPartnerIntegrationModelName>;
  in?: Maybe<Array<InformPartnerIntegrationModelName>>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelName>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelNameFilter>;
};

export type EnumInformPartnerIntegrationModelNameWithAggregatesFilter = {
  equals?: Maybe<InformPartnerIntegrationModelName>;
  in?: Maybe<Array<InformPartnerIntegrationModelName>>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelName>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelNameWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerIntegrationModelNameFilter>;
  _max?: Maybe<NestedEnumInformPartnerIntegrationModelNameFilter>;
};

export type EnumInformPartnerIntegrationModelStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerIntegrationModelStatus>;
};

export type EnumInformPartnerIntegrationModelStatusFilter = {
  equals?: Maybe<InformPartnerIntegrationModelStatus>;
  in?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelStatusFilter>;
};

export type EnumInformPartnerIntegrationModelStatusWithAggregatesFilter = {
  equals?: Maybe<InformPartnerIntegrationModelStatus>;
  in?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerIntegrationModelStatusFilter>;
  _max?: Maybe<NestedEnumInformPartnerIntegrationModelStatusFilter>;
};

export type EnumInformPartnerIntegrationStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerIntegrationStatus>;
};

export type EnumInformPartnerIntegrationStatusFilter = {
  equals?: Maybe<InformPartnerIntegrationStatus>;
  in?: Maybe<Array<InformPartnerIntegrationStatus>>;
  notIn?: Maybe<Array<InformPartnerIntegrationStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationStatusFilter>;
};

export type EnumInformPartnerIntegrationStatusWithAggregatesFilter = {
  equals?: Maybe<InformPartnerIntegrationStatus>;
  in?: Maybe<Array<InformPartnerIntegrationStatus>>;
  notIn?: Maybe<Array<InformPartnerIntegrationStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerIntegrationStatusFilter>;
  _max?: Maybe<NestedEnumInformPartnerIntegrationStatusFilter>;
};

export type EnumInformPartnerProgramStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerProgramStatus>;
};

export type EnumInformPartnerProgramStatusFilter = {
  equals?: Maybe<InformPartnerProgramStatus>;
  in?: Maybe<Array<InformPartnerProgramStatus>>;
  notIn?: Maybe<Array<InformPartnerProgramStatus>>;
  not?: Maybe<NestedEnumInformPartnerProgramStatusFilter>;
};

export type EnumInformPartnerProgramStatusWithAggregatesFilter = {
  equals?: Maybe<InformPartnerProgramStatus>;
  in?: Maybe<Array<InformPartnerProgramStatus>>;
  notIn?: Maybe<Array<InformPartnerProgramStatus>>;
  not?: Maybe<NestedEnumInformPartnerProgramStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerProgramStatusFilter>;
  _max?: Maybe<NestedEnumInformPartnerProgramStatusFilter>;
};

export type EnumInformPartnerStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerStatus>;
};

export type EnumInformPartnerStatusFilter = {
  equals?: Maybe<InformPartnerStatus>;
  in?: Maybe<Array<InformPartnerStatus>>;
  notIn?: Maybe<Array<InformPartnerStatus>>;
  not?: Maybe<NestedEnumInformPartnerStatusFilter>;
};

export type EnumInformPartnerStatusWithAggregatesFilter = {
  equals?: Maybe<InformPartnerStatus>;
  in?: Maybe<Array<InformPartnerStatus>>;
  notIn?: Maybe<Array<InformPartnerStatus>>;
  not?: Maybe<NestedEnumInformPartnerStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerStatusFilter>;
  _max?: Maybe<NestedEnumInformPartnerStatusFilter>;
};

export type EnumInformPartnerUserRoleNameFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerUserRoleName>;
};

export type EnumInformPartnerUserRoleNameFilter = {
  equals?: Maybe<InformPartnerUserRoleName>;
  in?: Maybe<Array<InformPartnerUserRoleName>>;
  notIn?: Maybe<Array<InformPartnerUserRoleName>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleNameFilter>;
};

export type EnumInformPartnerUserRoleNameWithAggregatesFilter = {
  equals?: Maybe<InformPartnerUserRoleName>;
  in?: Maybe<Array<InformPartnerUserRoleName>>;
  notIn?: Maybe<Array<InformPartnerUserRoleName>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleNameWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerUserRoleNameFilter>;
  _max?: Maybe<NestedEnumInformPartnerUserRoleNameFilter>;
};

export type EnumInformPartnerUserRoleStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerUserRoleStatus>;
};

export type EnumInformPartnerUserRoleStatusFilter = {
  equals?: Maybe<InformPartnerUserRoleStatus>;
  in?: Maybe<Array<InformPartnerUserRoleStatus>>;
  notIn?: Maybe<Array<InformPartnerUserRoleStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleStatusFilter>;
};

export type EnumInformPartnerUserRoleStatusWithAggregatesFilter = {
  equals?: Maybe<InformPartnerUserRoleStatus>;
  in?: Maybe<Array<InformPartnerUserRoleStatus>>;
  notIn?: Maybe<Array<InformPartnerUserRoleStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerUserRoleStatusFilter>;
  _max?: Maybe<NestedEnumInformPartnerUserRoleStatusFilter>;
};

export type EnumInformPartnerUserStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerUserStatus>;
};

export type EnumInformPartnerUserStatusFilter = {
  equals?: Maybe<InformPartnerUserStatus>;
  in?: Maybe<Array<InformPartnerUserStatus>>;
  notIn?: Maybe<Array<InformPartnerUserStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserStatusFilter>;
};

export type EnumInformPartnerUserStatusWithAggregatesFilter = {
  equals?: Maybe<InformPartnerUserStatus>;
  in?: Maybe<Array<InformPartnerUserStatus>>;
  notIn?: Maybe<Array<InformPartnerUserStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerUserStatusFilter>;
  _max?: Maybe<NestedEnumInformPartnerUserStatusFilter>;
};

export type EnumInformScheduledCampaignCadenceNullableFilter = {
  equals?: Maybe<InformScheduledCampaignCadence>;
  in?: Maybe<Array<InformScheduledCampaignCadence>>;
  notIn?: Maybe<Array<InformScheduledCampaignCadence>>;
  not?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableFilter>;
};

export type EnumInformScheduledCampaignCadenceNullableWithAggregatesFilter = {
  equals?: Maybe<InformScheduledCampaignCadence>;
  in?: Maybe<Array<InformScheduledCampaignCadence>>;
  notIn?: Maybe<Array<InformScheduledCampaignCadence>>;
  not?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableFilter>;
  _max?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableFilter>;
};

export type EnumInformScheduledCampaignCommunicationChannelNullableListFilter = {
  equals?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  has?: Maybe<InformScheduledCampaignCommunicationChannel>;
  hasEvery?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  hasSome?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumInsuranceIntegrationTypeFieldUpdateOperationsInput = {
  set?: Maybe<InsuranceIntegrationType>;
};

export type EnumInsuranceIntegrationTypeFilter = {
  equals?: Maybe<InsuranceIntegrationType>;
  in?: Maybe<Array<InsuranceIntegrationType>>;
  notIn?: Maybe<Array<InsuranceIntegrationType>>;
  not?: Maybe<NestedEnumInsuranceIntegrationTypeFilter>;
};

export type EnumInsuranceIntegrationTypeWithAggregatesFilter = {
  equals?: Maybe<InsuranceIntegrationType>;
  in?: Maybe<Array<InsuranceIntegrationType>>;
  notIn?: Maybe<Array<InsuranceIntegrationType>>;
  not?: Maybe<NestedEnumInsuranceIntegrationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInsuranceIntegrationTypeFilter>;
  _max?: Maybe<NestedEnumInsuranceIntegrationTypeFilter>;
};

export type EnumIntegrationStatusNullableFilter = {
  equals?: Maybe<IntegrationStatus>;
  in?: Maybe<Array<IntegrationStatus>>;
  notIn?: Maybe<Array<IntegrationStatus>>;
  not?: Maybe<NestedEnumIntegrationStatusNullableFilter>;
};

export type EnumIntegrationStatusNullableWithAggregatesFilter = {
  equals?: Maybe<IntegrationStatus>;
  in?: Maybe<Array<IntegrationStatus>>;
  notIn?: Maybe<Array<IntegrationStatus>>;
  not?: Maybe<NestedEnumIntegrationStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumIntegrationStatusNullableFilter>;
  _max?: Maybe<NestedEnumIntegrationStatusNullableFilter>;
};

export type EnumIntegrationTypeNullableFilter = {
  equals?: Maybe<IntegrationType>;
  in?: Maybe<Array<IntegrationType>>;
  notIn?: Maybe<Array<IntegrationType>>;
  not?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
};

export type EnumIntegrationTypeNullableWithAggregatesFilter = {
  equals?: Maybe<IntegrationType>;
  in?: Maybe<Array<IntegrationType>>;
  notIn?: Maybe<Array<IntegrationType>>;
  not?: Maybe<NestedEnumIntegrationTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
  _max?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
};

export type EnumInvoiceLineItemTypeFieldUpdateOperationsInput = {
  set?: Maybe<InvoiceLineItemType>;
};

export type EnumInvoiceLineItemTypeFilter = {
  equals?: Maybe<InvoiceLineItemType>;
  in?: Maybe<Array<InvoiceLineItemType>>;
  notIn?: Maybe<Array<InvoiceLineItemType>>;
  not?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
};

export type EnumInvoiceLineItemTypeWithAggregatesFilter = {
  equals?: Maybe<InvoiceLineItemType>;
  in?: Maybe<Array<InvoiceLineItemType>>;
  notIn?: Maybe<Array<InvoiceLineItemType>>;
  not?: Maybe<NestedEnumInvoiceLineItemTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
  _max?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
};

export type EnumInvoicePimsWritebackActionFieldUpdateOperationsInput = {
  set?: Maybe<InvoicePimsWritebackAction>;
};

export type EnumInvoicePimsWritebackActionFilter = {
  equals?: Maybe<InvoicePimsWritebackAction>;
  in?: Maybe<Array<InvoicePimsWritebackAction>>;
  notIn?: Maybe<Array<InvoicePimsWritebackAction>>;
  not?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
};

export type EnumInvoicePimsWritebackActionWithAggregatesFilter = {
  equals?: Maybe<InvoicePimsWritebackAction>;
  in?: Maybe<Array<InvoicePimsWritebackAction>>;
  notIn?: Maybe<Array<InvoicePimsWritebackAction>>;
  not?: Maybe<NestedEnumInvoicePimsWritebackActionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
  _max?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
};

export type EnumInvoiceStatusFieldUpdateOperationsInput = {
  set?: Maybe<InvoiceStatus>;
};

export type EnumInvoiceStatusFilter = {
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  notIn?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusFilter>;
};

export type EnumInvoiceStatusWithAggregatesFilter = {
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  notIn?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInvoiceStatusFilter>;
  _max?: Maybe<NestedEnumInvoiceStatusFilter>;
};

export type EnumLegalEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<LegalEntityType>;
};

export type EnumLegalEntityTypeFilter = {
  equals?: Maybe<LegalEntityType>;
  in?: Maybe<Array<LegalEntityType>>;
  notIn?: Maybe<Array<LegalEntityType>>;
  not?: Maybe<NestedEnumLegalEntityTypeFilter>;
};

export type EnumLegalEntityTypeWithAggregatesFilter = {
  equals?: Maybe<LegalEntityType>;
  in?: Maybe<Array<LegalEntityType>>;
  notIn?: Maybe<Array<LegalEntityType>>;
  not?: Maybe<NestedEnumLegalEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLegalEntityTypeFilter>;
  _max?: Maybe<NestedEnumLegalEntityTypeFilter>;
};

export type EnumLoyaltyAccountMergerStatusFieldUpdateOperationsInput = {
  set?: Maybe<LoyaltyAccountMergerStatus>;
};

export type EnumLoyaltyAccountMergerStatusFilter = {
  equals?: Maybe<LoyaltyAccountMergerStatus>;
  in?: Maybe<Array<LoyaltyAccountMergerStatus>>;
  notIn?: Maybe<Array<LoyaltyAccountMergerStatus>>;
  not?: Maybe<NestedEnumLoyaltyAccountMergerStatusFilter>;
};

export type EnumLoyaltyAccountMergerStatusWithAggregatesFilter = {
  equals?: Maybe<LoyaltyAccountMergerStatus>;
  in?: Maybe<Array<LoyaltyAccountMergerStatus>>;
  notIn?: Maybe<Array<LoyaltyAccountMergerStatus>>;
  not?: Maybe<NestedEnumLoyaltyAccountMergerStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLoyaltyAccountMergerStatusFilter>;
  _max?: Maybe<NestedEnumLoyaltyAccountMergerStatusFilter>;
};

export type EnumLoyaltyPointDeltaSourceFieldUpdateOperationsInput = {
  set?: Maybe<LoyaltyPointDeltaSource>;
};

export type EnumLoyaltyPointDeltaSourceFilter = {
  equals?: Maybe<LoyaltyPointDeltaSource>;
  in?: Maybe<Array<LoyaltyPointDeltaSource>>;
  notIn?: Maybe<Array<LoyaltyPointDeltaSource>>;
  not?: Maybe<NestedEnumLoyaltyPointDeltaSourceFilter>;
};

export type EnumLoyaltyPointDeltaSourceWithAggregatesFilter = {
  equals?: Maybe<LoyaltyPointDeltaSource>;
  in?: Maybe<Array<LoyaltyPointDeltaSource>>;
  notIn?: Maybe<Array<LoyaltyPointDeltaSource>>;
  not?: Maybe<NestedEnumLoyaltyPointDeltaSourceWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLoyaltyPointDeltaSourceFilter>;
  _max?: Maybe<NestedEnumLoyaltyPointDeltaSourceFilter>;
};

export type EnumLoyaltyProgramStatusFieldUpdateOperationsInput = {
  set?: Maybe<LoyaltyProgramStatus>;
};

export type EnumLoyaltyProgramStatusFilter = {
  equals?: Maybe<LoyaltyProgramStatus>;
  in?: Maybe<Array<LoyaltyProgramStatus>>;
  notIn?: Maybe<Array<LoyaltyProgramStatus>>;
  not?: Maybe<NestedEnumLoyaltyProgramStatusFilter>;
};

export type EnumLoyaltyProgramStatusWithAggregatesFilter = {
  equals?: Maybe<LoyaltyProgramStatus>;
  in?: Maybe<Array<LoyaltyProgramStatus>>;
  notIn?: Maybe<Array<LoyaltyProgramStatus>>;
  not?: Maybe<NestedEnumLoyaltyProgramStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLoyaltyProgramStatusFilter>;
  _max?: Maybe<NestedEnumLoyaltyProgramStatusFilter>;
};

export type EnumLoyaltyRewardAvailabilityFieldUpdateOperationsInput = {
  set?: Maybe<LoyaltyRewardAvailability>;
};

export type EnumLoyaltyRewardAvailabilityFilter = {
  equals?: Maybe<LoyaltyRewardAvailability>;
  in?: Maybe<Array<LoyaltyRewardAvailability>>;
  notIn?: Maybe<Array<LoyaltyRewardAvailability>>;
  not?: Maybe<NestedEnumLoyaltyRewardAvailabilityFilter>;
};

export type EnumLoyaltyRewardAvailabilityWithAggregatesFilter = {
  equals?: Maybe<LoyaltyRewardAvailability>;
  in?: Maybe<Array<LoyaltyRewardAvailability>>;
  notIn?: Maybe<Array<LoyaltyRewardAvailability>>;
  not?: Maybe<NestedEnumLoyaltyRewardAvailabilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLoyaltyRewardAvailabilityFilter>;
  _max?: Maybe<NestedEnumLoyaltyRewardAvailabilityFilter>;
};

export type EnumLoyaltyRewardRedemptionActorTypeFieldUpdateOperationsInput = {
  set?: Maybe<LoyaltyRewardRedemptionActorType>;
};

export type EnumLoyaltyRewardRedemptionActorTypeFilter = {
  equals?: Maybe<LoyaltyRewardRedemptionActorType>;
  in?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeFilter>;
};

export type EnumLoyaltyRewardRedemptionActorTypeWithAggregatesFilter = {
  equals?: Maybe<LoyaltyRewardRedemptionActorType>;
  in?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeFilter>;
  _max?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeFilter>;
};

export type EnumLoyaltyRewardRedemptionStatusFieldUpdateOperationsInput = {
  set?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type EnumLoyaltyRewardRedemptionStatusFilter = {
  equals?: Maybe<LoyaltyRewardRedemptionStatus>;
  in?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusFilter>;
};

export type EnumLoyaltyRewardRedemptionStatusWithAggregatesFilter = {
  equals?: Maybe<LoyaltyRewardRedemptionStatus>;
  in?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusFilter>;
  _max?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusFilter>;
};

export type EnumManageMembershipRequestTypeFieldUpdateOperationsInput = {
  set?: Maybe<ManageMembershipRequestType>;
};

export type EnumManageMembershipRequestTypeFilter = {
  equals?: Maybe<ManageMembershipRequestType>;
  in?: Maybe<Array<ManageMembershipRequestType>>;
  notIn?: Maybe<Array<ManageMembershipRequestType>>;
  not?: Maybe<NestedEnumManageMembershipRequestTypeFilter>;
};

export type EnumManageMembershipRequestTypeWithAggregatesFilter = {
  equals?: Maybe<ManageMembershipRequestType>;
  in?: Maybe<Array<ManageMembershipRequestType>>;
  notIn?: Maybe<Array<ManageMembershipRequestType>>;
  not?: Maybe<NestedEnumManageMembershipRequestTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumManageMembershipRequestTypeFilter>;
  _max?: Maybe<NestedEnumManageMembershipRequestTypeFilter>;
};

export type EnumMassTextAlertStatusFieldUpdateOperationsInput = {
  set?: Maybe<MassTextAlertStatus>;
};

export type EnumMassTextAlertStatusFilter = {
  equals?: Maybe<MassTextAlertStatus>;
  in?: Maybe<Array<MassTextAlertStatus>>;
  notIn?: Maybe<Array<MassTextAlertStatus>>;
  not?: Maybe<NestedEnumMassTextAlertStatusFilter>;
};

export type EnumMassTextAlertStatusWithAggregatesFilter = {
  equals?: Maybe<MassTextAlertStatus>;
  in?: Maybe<Array<MassTextAlertStatus>>;
  notIn?: Maybe<Array<MassTextAlertStatus>>;
  not?: Maybe<NestedEnumMassTextAlertStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMassTextAlertStatusFilter>;
  _max?: Maybe<NestedEnumMassTextAlertStatusFilter>;
};

export type EnumMediaTypeFieldUpdateOperationsInput = {
  set?: Maybe<MediaType>;
};

export type EnumMediaTypeFilter = {
  equals?: Maybe<MediaType>;
  in?: Maybe<Array<MediaType>>;
  notIn?: Maybe<Array<MediaType>>;
  not?: Maybe<NestedEnumMediaTypeFilter>;
};

export type EnumMediaTypeWithAggregatesFilter = {
  equals?: Maybe<MediaType>;
  in?: Maybe<Array<MediaType>>;
  notIn?: Maybe<Array<MediaType>>;
  not?: Maybe<NestedEnumMediaTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMediaTypeFilter>;
  _max?: Maybe<NestedEnumMediaTypeFilter>;
};

export type EnumMediaVersionFieldUpdateOperationsInput = {
  set?: Maybe<MediaVersion>;
};

export type EnumMediaVersionFilter = {
  equals?: Maybe<MediaVersion>;
  in?: Maybe<Array<MediaVersion>>;
  notIn?: Maybe<Array<MediaVersion>>;
  not?: Maybe<NestedEnumMediaVersionFilter>;
};

export type EnumMediaVersionWithAggregatesFilter = {
  equals?: Maybe<MediaVersion>;
  in?: Maybe<Array<MediaVersion>>;
  notIn?: Maybe<Array<MediaVersion>>;
  not?: Maybe<NestedEnumMediaVersionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMediaVersionFilter>;
  _max?: Maybe<NestedEnumMediaVersionFilter>;
};

export type EnumMessageSourceFieldUpdateOperationsInput = {
  set?: Maybe<MessageSource>;
};

export type EnumMessageSourceFilter = {
  equals?: Maybe<MessageSource>;
  in?: Maybe<Array<MessageSource>>;
  notIn?: Maybe<Array<MessageSource>>;
  not?: Maybe<NestedEnumMessageSourceFilter>;
};

export type EnumMessageSourceWithAggregatesFilter = {
  equals?: Maybe<MessageSource>;
  in?: Maybe<Array<MessageSource>>;
  notIn?: Maybe<Array<MessageSource>>;
  not?: Maybe<NestedEnumMessageSourceWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMessageSourceFilter>;
  _max?: Maybe<NestedEnumMessageSourceFilter>;
};

export type EnumMessageTypeFieldUpdateOperationsInput = {
  set?: Maybe<MessageType>;
};

export type EnumMessageTypeFilter = {
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  notIn?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeFilter>;
};

export type EnumMessageTypeWithAggregatesFilter = {
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  notIn?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMessageTypeFilter>;
  _max?: Maybe<NestedEnumMessageTypeFilter>;
};

export type EnumMessagingCampaignStatusFieldUpdateOperationsInput = {
  set?: Maybe<MessagingCampaignStatus>;
};

export type EnumMessagingCampaignStatusFilter = {
  equals?: Maybe<MessagingCampaignStatus>;
  in?: Maybe<Array<MessagingCampaignStatus>>;
  notIn?: Maybe<Array<MessagingCampaignStatus>>;
  not?: Maybe<NestedEnumMessagingCampaignStatusFilter>;
};

export type EnumMessagingCampaignStatusWithAggregatesFilter = {
  equals?: Maybe<MessagingCampaignStatus>;
  in?: Maybe<Array<MessagingCampaignStatus>>;
  notIn?: Maybe<Array<MessagingCampaignStatus>>;
  not?: Maybe<NestedEnumMessagingCampaignStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMessagingCampaignStatusFilter>;
  _max?: Maybe<NestedEnumMessagingCampaignStatusFilter>;
};

export type EnumMessagingCampaignTypeFieldUpdateOperationsInput = {
  set?: Maybe<MessagingCampaignType>;
};

export type EnumMessagingCampaignTypeFilter = {
  equals?: Maybe<MessagingCampaignType>;
  in?: Maybe<Array<MessagingCampaignType>>;
  notIn?: Maybe<Array<MessagingCampaignType>>;
  not?: Maybe<NestedEnumMessagingCampaignTypeFilter>;
};

export type EnumMessagingCampaignTypeWithAggregatesFilter = {
  equals?: Maybe<MessagingCampaignType>;
  in?: Maybe<Array<MessagingCampaignType>>;
  notIn?: Maybe<Array<MessagingCampaignType>>;
  not?: Maybe<NestedEnumMessagingCampaignTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMessagingCampaignTypeFilter>;
  _max?: Maybe<NestedEnumMessagingCampaignTypeFilter>;
};

export type EnumMissedCallMessageResponseStatusFieldUpdateOperationsInput = {
  set?: Maybe<MissedCallMessageResponseStatus>;
};

export type EnumMissedCallMessageResponseStatusFilter = {
  equals?: Maybe<MissedCallMessageResponseStatus>;
  in?: Maybe<Array<MissedCallMessageResponseStatus>>;
  notIn?: Maybe<Array<MissedCallMessageResponseStatus>>;
  not?: Maybe<NestedEnumMissedCallMessageResponseStatusFilter>;
};

export type EnumMissedCallMessageResponseStatusWithAggregatesFilter = {
  equals?: Maybe<MissedCallMessageResponseStatus>;
  in?: Maybe<Array<MissedCallMessageResponseStatus>>;
  notIn?: Maybe<Array<MissedCallMessageResponseStatus>>;
  not?: Maybe<NestedEnumMissedCallMessageResponseStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMissedCallMessageResponseStatusFilter>;
  _max?: Maybe<NestedEnumMissedCallMessageResponseStatusFilter>;
};

export type EnumNormalizedWritebackErrorNullableFilter = {
  equals?: Maybe<NormalizedWritebackError>;
  in?: Maybe<Array<NormalizedWritebackError>>;
  notIn?: Maybe<Array<NormalizedWritebackError>>;
  not?: Maybe<NestedEnumNormalizedWritebackErrorNullableFilter>;
};

export type EnumNormalizedWritebackErrorNullableWithAggregatesFilter = {
  equals?: Maybe<NormalizedWritebackError>;
  in?: Maybe<Array<NormalizedWritebackError>>;
  notIn?: Maybe<Array<NormalizedWritebackError>>;
  not?: Maybe<NestedEnumNormalizedWritebackErrorNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumNormalizedWritebackErrorNullableFilter>;
  _max?: Maybe<NestedEnumNormalizedWritebackErrorNullableFilter>;
};

export type EnumNotificationEventFieldUpdateOperationsInput = {
  set?: Maybe<NotificationEvent>;
};

export type EnumNotificationEventFilter = {
  equals?: Maybe<NotificationEvent>;
  in?: Maybe<Array<NotificationEvent>>;
  notIn?: Maybe<Array<NotificationEvent>>;
  not?: Maybe<NestedEnumNotificationEventFilter>;
};

export type EnumNotificationEventWithAggregatesFilter = {
  equals?: Maybe<NotificationEvent>;
  in?: Maybe<Array<NotificationEvent>>;
  notIn?: Maybe<Array<NotificationEvent>>;
  not?: Maybe<NestedEnumNotificationEventWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumNotificationEventFilter>;
  _max?: Maybe<NestedEnumNotificationEventFilter>;
};

export type EnumNotificationSettingTypeFieldUpdateOperationsInput = {
  set?: Maybe<NotificationSettingType>;
};

export type EnumNotificationSettingTypeFilter = {
  equals?: Maybe<NotificationSettingType>;
  in?: Maybe<Array<NotificationSettingType>>;
  notIn?: Maybe<Array<NotificationSettingType>>;
  not?: Maybe<NestedEnumNotificationSettingTypeFilter>;
};

export type EnumNotificationSettingTypeWithAggregatesFilter = {
  equals?: Maybe<NotificationSettingType>;
  in?: Maybe<Array<NotificationSettingType>>;
  notIn?: Maybe<Array<NotificationSettingType>>;
  not?: Maybe<NestedEnumNotificationSettingTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumNotificationSettingTypeFilter>;
  _max?: Maybe<NestedEnumNotificationSettingTypeFilter>;
};

export type EnumPaymentMediumNullableFilter = {
  equals?: Maybe<PaymentMedium>;
  in?: Maybe<Array<PaymentMedium>>;
  notIn?: Maybe<Array<PaymentMedium>>;
  not?: Maybe<NestedEnumPaymentMediumNullableFilter>;
};

export type EnumPaymentMediumNullableListFilter = {
  equals?: Maybe<Array<PaymentMedium>>;
  has?: Maybe<PaymentMedium>;
  hasEvery?: Maybe<Array<PaymentMedium>>;
  hasSome?: Maybe<Array<PaymentMedium>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumPaymentMediumNullableWithAggregatesFilter = {
  equals?: Maybe<PaymentMedium>;
  in?: Maybe<Array<PaymentMedium>>;
  notIn?: Maybe<Array<PaymentMedium>>;
  not?: Maybe<NestedEnumPaymentMediumNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPaymentMediumNullableFilter>;
  _max?: Maybe<NestedEnumPaymentMediumNullableFilter>;
};

export type EnumPaymentPimsWritebackActionFieldUpdateOperationsInput = {
  set?: Maybe<PaymentPimsWritebackAction>;
};

export type EnumPaymentPimsWritebackActionFilter = {
  equals?: Maybe<PaymentPimsWritebackAction>;
  in?: Maybe<Array<PaymentPimsWritebackAction>>;
  notIn?: Maybe<Array<PaymentPimsWritebackAction>>;
  not?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
};

export type EnumPaymentPimsWritebackActionWithAggregatesFilter = {
  equals?: Maybe<PaymentPimsWritebackAction>;
  in?: Maybe<Array<PaymentPimsWritebackAction>>;
  notIn?: Maybe<Array<PaymentPimsWritebackAction>>;
  not?: Maybe<NestedEnumPaymentPimsWritebackActionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
  _max?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
};

export type EnumPaymentRelationshipNullableFilter = {
  equals?: Maybe<PaymentRelationship>;
  in?: Maybe<Array<PaymentRelationship>>;
  notIn?: Maybe<Array<PaymentRelationship>>;
  not?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
};

export type EnumPaymentRelationshipNullableWithAggregatesFilter = {
  equals?: Maybe<PaymentRelationship>;
  in?: Maybe<Array<PaymentRelationship>>;
  notIn?: Maybe<Array<PaymentRelationship>>;
  not?: Maybe<NestedEnumPaymentRelationshipNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
  _max?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
};

export type EnumPaymentScheduleNullableFilter = {
  equals?: Maybe<PaymentSchedule>;
  in?: Maybe<Array<PaymentSchedule>>;
  notIn?: Maybe<Array<PaymentSchedule>>;
  not?: Maybe<NestedEnumPaymentScheduleNullableFilter>;
};

export type EnumPaymentScheduleNullableWithAggregatesFilter = {
  equals?: Maybe<PaymentSchedule>;
  in?: Maybe<Array<PaymentSchedule>>;
  notIn?: Maybe<Array<PaymentSchedule>>;
  not?: Maybe<NestedEnumPaymentScheduleNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPaymentScheduleNullableFilter>;
  _max?: Maybe<NestedEnumPaymentScheduleNullableFilter>;
};

export type EnumPaymentSourceFieldUpdateOperationsInput = {
  set?: Maybe<PaymentSource>;
};

export type EnumPaymentSourceFilter = {
  equals?: Maybe<PaymentSource>;
  in?: Maybe<Array<PaymentSource>>;
  notIn?: Maybe<Array<PaymentSource>>;
  not?: Maybe<NestedEnumPaymentSourceFilter>;
};

export type EnumPaymentSourceWithAggregatesFilter = {
  equals?: Maybe<PaymentSource>;
  in?: Maybe<Array<PaymentSource>>;
  notIn?: Maybe<Array<PaymentSource>>;
  not?: Maybe<NestedEnumPaymentSourceWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPaymentSourceFilter>;
  _max?: Maybe<NestedEnumPaymentSourceFilter>;
};

export type EnumPermissionTypeFieldUpdateOperationsInput = {
  set?: Maybe<PermissionType>;
};

export type EnumPermissionTypeFilter = {
  equals?: Maybe<PermissionType>;
  in?: Maybe<Array<PermissionType>>;
  notIn?: Maybe<Array<PermissionType>>;
  not?: Maybe<NestedEnumPermissionTypeFilter>;
};

export type EnumPermissionTypeWithAggregatesFilter = {
  equals?: Maybe<PermissionType>;
  in?: Maybe<Array<PermissionType>>;
  notIn?: Maybe<Array<PermissionType>>;
  not?: Maybe<NestedEnumPermissionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPermissionTypeFilter>;
  _max?: Maybe<NestedEnumPermissionTypeFilter>;
};

export type EnumPhoneNumberLookupRequestStatusFieldUpdateOperationsInput = {
  set?: Maybe<PhoneNumberLookupRequestStatus>;
};

export type EnumPhoneNumberLookupRequestStatusFilter = {
  equals?: Maybe<PhoneNumberLookupRequestStatus>;
  in?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
  notIn?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
  not?: Maybe<NestedEnumPhoneNumberLookupRequestStatusFilter>;
};

export type EnumPhoneNumberLookupRequestStatusWithAggregatesFilter = {
  equals?: Maybe<PhoneNumberLookupRequestStatus>;
  in?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
  notIn?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
  not?: Maybe<NestedEnumPhoneNumberLookupRequestStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPhoneNumberLookupRequestStatusFilter>;
  _max?: Maybe<NestedEnumPhoneNumberLookupRequestStatusFilter>;
};

export type EnumPhoneNumberStatusFieldUpdateOperationsInput = {
  set?: Maybe<PhoneNumberStatus>;
};

export type EnumPhoneNumberStatusFilter = {
  equals?: Maybe<PhoneNumberStatus>;
  in?: Maybe<Array<PhoneNumberStatus>>;
  notIn?: Maybe<Array<PhoneNumberStatus>>;
  not?: Maybe<NestedEnumPhoneNumberStatusFilter>;
};

export type EnumPhoneNumberStatusWithAggregatesFilter = {
  equals?: Maybe<PhoneNumberStatus>;
  in?: Maybe<Array<PhoneNumberStatus>>;
  notIn?: Maybe<Array<PhoneNumberStatus>>;
  not?: Maybe<NestedEnumPhoneNumberStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPhoneNumberStatusFilter>;
  _max?: Maybe<NestedEnumPhoneNumberStatusFilter>;
};

export type EnumPimsIntegrationCapabilityFieldUpdateOperationsInput = {
  set?: Maybe<PimsIntegrationCapability>;
};

export type EnumPimsIntegrationCapabilityFilter = {
  equals?: Maybe<PimsIntegrationCapability>;
  in?: Maybe<Array<PimsIntegrationCapability>>;
  notIn?: Maybe<Array<PimsIntegrationCapability>>;
  not?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
};

export type EnumPimsIntegrationCapabilityWithAggregatesFilter = {
  equals?: Maybe<PimsIntegrationCapability>;
  in?: Maybe<Array<PimsIntegrationCapability>>;
  notIn?: Maybe<Array<PimsIntegrationCapability>>;
  not?: Maybe<NestedEnumPimsIntegrationCapabilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
  _max?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
};

export type EnumPimsInvoiceLineItemTypeFieldUpdateOperationsInput = {
  set?: Maybe<PimsInvoiceLineItemType>;
};

export type EnumPimsInvoiceLineItemTypeFilter = {
  equals?: Maybe<PimsInvoiceLineItemType>;
  in?: Maybe<Array<PimsInvoiceLineItemType>>;
  notIn?: Maybe<Array<PimsInvoiceLineItemType>>;
  not?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
};

export type EnumPimsInvoiceLineItemTypeWithAggregatesFilter = {
  equals?: Maybe<PimsInvoiceLineItemType>;
  in?: Maybe<Array<PimsInvoiceLineItemType>>;
  notIn?: Maybe<Array<PimsInvoiceLineItemType>>;
  not?: Maybe<NestedEnumPimsInvoiceLineItemTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
  _max?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
};

export type EnumPimsSyncFrequencyFieldUpdateOperationsInput = {
  set?: Maybe<PimsSyncFrequency>;
};

export type EnumPimsSyncFrequencyFilter = {
  equals?: Maybe<PimsSyncFrequency>;
  in?: Maybe<Array<PimsSyncFrequency>>;
  notIn?: Maybe<Array<PimsSyncFrequency>>;
  not?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
};

export type EnumPimsSyncFrequencyWithAggregatesFilter = {
  equals?: Maybe<PimsSyncFrequency>;
  in?: Maybe<Array<PimsSyncFrequency>>;
  notIn?: Maybe<Array<PimsSyncFrequency>>;
  not?: Maybe<NestedEnumPimsSyncFrequencyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
  _max?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
};

export type EnumPimsSyncStatusNullableFilter = {
  equals?: Maybe<PimsSyncStatus>;
  in?: Maybe<Array<PimsSyncStatus>>;
  notIn?: Maybe<Array<PimsSyncStatus>>;
  not?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
};

export type EnumPimsSyncStatusNullableWithAggregatesFilter = {
  equals?: Maybe<PimsSyncStatus>;
  in?: Maybe<Array<PimsSyncStatus>>;
  notIn?: Maybe<Array<PimsSyncStatus>>;
  not?: Maybe<NestedEnumPimsSyncStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
  _max?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
};

export type EnumPimsWritebackStatusFieldUpdateOperationsInput = {
  set?: Maybe<PimsWritebackStatus>;
};

export type EnumPimsWritebackStatusFilter = {
  equals?: Maybe<PimsWritebackStatus>;
  in?: Maybe<Array<PimsWritebackStatus>>;
  notIn?: Maybe<Array<PimsWritebackStatus>>;
  not?: Maybe<NestedEnumPimsWritebackStatusFilter>;
};

export type EnumPimsWritebackStatusWithAggregatesFilter = {
  equals?: Maybe<PimsWritebackStatus>;
  in?: Maybe<Array<PimsWritebackStatus>>;
  notIn?: Maybe<Array<PimsWritebackStatus>>;
  not?: Maybe<NestedEnumPimsWritebackStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsWritebackStatusFilter>;
  _max?: Maybe<NestedEnumPimsWritebackStatusFilter>;
};

export type EnumPimsWritebackTypeFieldUpdateOperationsInput = {
  set?: Maybe<PimsWritebackType>;
};

export type EnumPimsWritebackTypeFilter = {
  equals?: Maybe<PimsWritebackType>;
  in?: Maybe<Array<PimsWritebackType>>;
  notIn?: Maybe<Array<PimsWritebackType>>;
  not?: Maybe<NestedEnumPimsWritebackTypeFilter>;
};

export type EnumPimsWritebackTypeWithAggregatesFilter = {
  equals?: Maybe<PimsWritebackType>;
  in?: Maybe<Array<PimsWritebackType>>;
  notIn?: Maybe<Array<PimsWritebackType>>;
  not?: Maybe<NestedEnumPimsWritebackTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsWritebackTypeFilter>;
  _max?: Maybe<NestedEnumPimsWritebackTypeFilter>;
};

export type EnumPricingModelFieldUpdateOperationsInput = {
  set?: Maybe<PricingModel>;
};

export type EnumPricingModelFilter = {
  equals?: Maybe<PricingModel>;
  in?: Maybe<Array<PricingModel>>;
  notIn?: Maybe<Array<PricingModel>>;
  not?: Maybe<NestedEnumPricingModelFilter>;
};

export type EnumPricingModelWithAggregatesFilter = {
  equals?: Maybe<PricingModel>;
  in?: Maybe<Array<PricingModel>>;
  notIn?: Maybe<Array<PricingModel>>;
  not?: Maybe<NestedEnumPricingModelWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPricingModelFilter>;
  _max?: Maybe<NestedEnumPricingModelFilter>;
};

export type EnumProductIndicationNullableListFilter = {
  equals?: Maybe<Array<ProductIndication>>;
  has?: Maybe<ProductIndication>;
  hasEvery?: Maybe<Array<ProductIndication>>;
  hasSome?: Maybe<Array<ProductIndication>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumProductSupplyDurationUnitNullableFilter = {
  equals?: Maybe<ProductSupplyDurationUnit>;
  in?: Maybe<Array<ProductSupplyDurationUnit>>;
  notIn?: Maybe<Array<ProductSupplyDurationUnit>>;
  not?: Maybe<NestedEnumProductSupplyDurationUnitNullableFilter>;
};

export type EnumProductSupplyDurationUnitNullableWithAggregatesFilter = {
  equals?: Maybe<ProductSupplyDurationUnit>;
  in?: Maybe<Array<ProductSupplyDurationUnit>>;
  notIn?: Maybe<Array<ProductSupplyDurationUnit>>;
  not?: Maybe<NestedEnumProductSupplyDurationUnitNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumProductSupplyDurationUnitNullableFilter>;
  _max?: Maybe<NestedEnumProductSupplyDurationUnitNullableFilter>;
};

export type EnumProductTypeFieldUpdateOperationsInput = {
  set?: Maybe<ProductType>;
};

export type EnumProductTypeFilter = {
  equals?: Maybe<ProductType>;
  in?: Maybe<Array<ProductType>>;
  notIn?: Maybe<Array<ProductType>>;
  not?: Maybe<NestedEnumProductTypeFilter>;
};

export type EnumProductTypeWithAggregatesFilter = {
  equals?: Maybe<ProductType>;
  in?: Maybe<Array<ProductType>>;
  notIn?: Maybe<Array<ProductType>>;
  not?: Maybe<NestedEnumProductTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumProductTypeFilter>;
  _max?: Maybe<NestedEnumProductTypeFilter>;
};

export type EnumProfilePimsSyncStatusFieldUpdateOperationsInput = {
  set?: Maybe<ProfilePimsSyncStatus>;
};

export type EnumProfilePimsSyncStatusFilter = {
  equals?: Maybe<ProfilePimsSyncStatus>;
  in?: Maybe<Array<ProfilePimsSyncStatus>>;
  notIn?: Maybe<Array<ProfilePimsSyncStatus>>;
  not?: Maybe<NestedEnumProfilePimsSyncStatusFilter>;
};

export type EnumProfilePimsSyncStatusWithAggregatesFilter = {
  equals?: Maybe<ProfilePimsSyncStatus>;
  in?: Maybe<Array<ProfilePimsSyncStatus>>;
  notIn?: Maybe<Array<ProfilePimsSyncStatus>>;
  not?: Maybe<NestedEnumProfilePimsSyncStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumProfilePimsSyncStatusFilter>;
  _max?: Maybe<NestedEnumProfilePimsSyncStatusFilter>;
};

export type EnumPushNotificationDeliveryErrorTypeNullableFilter = {
  equals?: Maybe<PushNotificationDeliveryErrorType>;
  in?: Maybe<Array<PushNotificationDeliveryErrorType>>;
  notIn?: Maybe<Array<PushNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableFilter>;
};

export type EnumPushNotificationDeliveryErrorTypeNullableWithAggregatesFilter = {
  equals?: Maybe<PushNotificationDeliveryErrorType>;
  in?: Maybe<Array<PushNotificationDeliveryErrorType>>;
  notIn?: Maybe<Array<PushNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableFilter>;
  _max?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableFilter>;
};

export type EnumPushNotificationLogStatusTypeFieldUpdateOperationsInput = {
  set?: Maybe<PushNotificationLogStatusType>;
};

export type EnumPushNotificationLogStatusTypeFilter = {
  equals?: Maybe<PushNotificationLogStatusType>;
  in?: Maybe<Array<PushNotificationLogStatusType>>;
  notIn?: Maybe<Array<PushNotificationLogStatusType>>;
  not?: Maybe<NestedEnumPushNotificationLogStatusTypeFilter>;
};

export type EnumPushNotificationLogStatusTypeWithAggregatesFilter = {
  equals?: Maybe<PushNotificationLogStatusType>;
  in?: Maybe<Array<PushNotificationLogStatusType>>;
  notIn?: Maybe<Array<PushNotificationLogStatusType>>;
  not?: Maybe<NestedEnumPushNotificationLogStatusTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPushNotificationLogStatusTypeFilter>;
  _max?: Maybe<NestedEnumPushNotificationLogStatusTypeFilter>;
};

export type EnumPushNotificationStatusFieldUpdateOperationsInput = {
  set?: Maybe<PushNotificationStatus>;
};

export type EnumPushNotificationStatusFilter = {
  equals?: Maybe<PushNotificationStatus>;
  in?: Maybe<Array<PushNotificationStatus>>;
  notIn?: Maybe<Array<PushNotificationStatus>>;
  not?: Maybe<NestedEnumPushNotificationStatusFilter>;
};

export type EnumPushNotificationStatusWithAggregatesFilter = {
  equals?: Maybe<PushNotificationStatus>;
  in?: Maybe<Array<PushNotificationStatus>>;
  notIn?: Maybe<Array<PushNotificationStatus>>;
  not?: Maybe<NestedEnumPushNotificationStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPushNotificationStatusFilter>;
  _max?: Maybe<NestedEnumPushNotificationStatusFilter>;
};

export type EnumRecordingDownloadStatusFieldUpdateOperationsInput = {
  set?: Maybe<RecordingDownloadStatus>;
};

export type EnumRecordingDownloadStatusFilter = {
  equals?: Maybe<RecordingDownloadStatus>;
  in?: Maybe<Array<RecordingDownloadStatus>>;
  notIn?: Maybe<Array<RecordingDownloadStatus>>;
  not?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
};

export type EnumRecordingDownloadStatusWithAggregatesFilter = {
  equals?: Maybe<RecordingDownloadStatus>;
  in?: Maybe<Array<RecordingDownloadStatus>>;
  notIn?: Maybe<Array<RecordingDownloadStatus>>;
  not?: Maybe<NestedEnumRecordingDownloadStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
  _max?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
};

export type EnumRefundReasonFieldUpdateOperationsInput = {
  set?: Maybe<RefundReason>;
};

export type EnumRefundReasonFilter = {
  equals?: Maybe<RefundReason>;
  in?: Maybe<Array<RefundReason>>;
  notIn?: Maybe<Array<RefundReason>>;
  not?: Maybe<NestedEnumRefundReasonFilter>;
};

export type EnumRefundReasonWithAggregatesFilter = {
  equals?: Maybe<RefundReason>;
  in?: Maybe<Array<RefundReason>>;
  notIn?: Maybe<Array<RefundReason>>;
  not?: Maybe<NestedEnumRefundReasonWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRefundReasonFilter>;
  _max?: Maybe<NestedEnumRefundReasonFilter>;
};

export type EnumRenewalRejectionReasonNullableFilter = {
  equals?: Maybe<RenewalRejectionReason>;
  in?: Maybe<Array<RenewalRejectionReason>>;
  notIn?: Maybe<Array<RenewalRejectionReason>>;
  not?: Maybe<NestedEnumRenewalRejectionReasonNullableFilter>;
};

export type EnumRenewalRejectionReasonNullableWithAggregatesFilter = {
  equals?: Maybe<RenewalRejectionReason>;
  in?: Maybe<Array<RenewalRejectionReason>>;
  notIn?: Maybe<Array<RenewalRejectionReason>>;
  not?: Maybe<NestedEnumRenewalRejectionReasonNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumRenewalRejectionReasonNullableFilter>;
  _max?: Maybe<NestedEnumRenewalRejectionReasonNullableFilter>;
};

export type EnumRoleFieldUpdateOperationsInput = {
  set?: Maybe<Role>;
};

export type EnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  notIn?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleFilter>;
};

export type EnumRoleWithAggregatesFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  notIn?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRoleFilter>;
  _max?: Maybe<NestedEnumRoleFilter>;
};

export type EnumRuleActionsFieldUpdateOperationsInput = {
  set?: Maybe<RuleActions>;
};

export type EnumRuleActionsFilter = {
  equals?: Maybe<RuleActions>;
  in?: Maybe<Array<RuleActions>>;
  notIn?: Maybe<Array<RuleActions>>;
  not?: Maybe<NestedEnumRuleActionsFilter>;
};

export type EnumRuleActionsWithAggregatesFilter = {
  equals?: Maybe<RuleActions>;
  in?: Maybe<Array<RuleActions>>;
  notIn?: Maybe<Array<RuleActions>>;
  not?: Maybe<NestedEnumRuleActionsWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRuleActionsFilter>;
  _max?: Maybe<NestedEnumRuleActionsFilter>;
};

export type EnumSegmentModelTypeFieldUpdateOperationsInput = {
  set?: Maybe<SegmentModelType>;
};

export type EnumSegmentModelTypeFilter = {
  equals?: Maybe<SegmentModelType>;
  in?: Maybe<Array<SegmentModelType>>;
  notIn?: Maybe<Array<SegmentModelType>>;
  not?: Maybe<NestedEnumSegmentModelTypeFilter>;
};

export type EnumSegmentModelTypeWithAggregatesFilter = {
  equals?: Maybe<SegmentModelType>;
  in?: Maybe<Array<SegmentModelType>>;
  notIn?: Maybe<Array<SegmentModelType>>;
  not?: Maybe<NestedEnumSegmentModelTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSegmentModelTypeFilter>;
  _max?: Maybe<NestedEnumSegmentModelTypeFilter>;
};

export type EnumSegmentTypeFieldUpdateOperationsInput = {
  set?: Maybe<SegmentType>;
};

export type EnumSegmentTypeFilter = {
  equals?: Maybe<SegmentType>;
  in?: Maybe<Array<SegmentType>>;
  notIn?: Maybe<Array<SegmentType>>;
  not?: Maybe<NestedEnumSegmentTypeFilter>;
};

export type EnumSegmentTypeWithAggregatesFilter = {
  equals?: Maybe<SegmentType>;
  in?: Maybe<Array<SegmentType>>;
  notIn?: Maybe<Array<SegmentType>>;
  not?: Maybe<NestedEnumSegmentTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSegmentTypeFilter>;
  _max?: Maybe<NestedEnumSegmentTypeFilter>;
};

export type EnumServiceCapabilityFieldUpdateOperationsInput = {
  set?: Maybe<ServiceCapability>;
};

export type EnumServiceCapabilityFilter = {
  equals?: Maybe<ServiceCapability>;
  in?: Maybe<Array<ServiceCapability>>;
  notIn?: Maybe<Array<ServiceCapability>>;
  not?: Maybe<NestedEnumServiceCapabilityFilter>;
};

export type EnumServiceCapabilityWithAggregatesFilter = {
  equals?: Maybe<ServiceCapability>;
  in?: Maybe<Array<ServiceCapability>>;
  notIn?: Maybe<Array<ServiceCapability>>;
  not?: Maybe<NestedEnumServiceCapabilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceCapabilityFilter>;
  _max?: Maybe<NestedEnumServiceCapabilityFilter>;
};

export type EnumServiceProviderFieldUpdateOperationsInput = {
  set?: Maybe<ServiceProvider>;
};

export type EnumServiceProviderFilter = {
  equals?: Maybe<ServiceProvider>;
  in?: Maybe<Array<ServiceProvider>>;
  notIn?: Maybe<Array<ServiceProvider>>;
  not?: Maybe<NestedEnumServiceProviderFilter>;
};

export type EnumServiceProviderWithAggregatesFilter = {
  equals?: Maybe<ServiceProvider>;
  in?: Maybe<Array<ServiceProvider>>;
  notIn?: Maybe<Array<ServiceProvider>>;
  not?: Maybe<NestedEnumServiceProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceProviderFilter>;
  _max?: Maybe<NestedEnumServiceProviderFilter>;
};

export type EnumServiceReminderNotificationTypeFieldUpdateOperationsInput = {
  set?: Maybe<ServiceReminderNotificationType>;
};

export type EnumServiceReminderNotificationTypeFilter = {
  equals?: Maybe<ServiceReminderNotificationType>;
  in?: Maybe<Array<ServiceReminderNotificationType>>;
  notIn?: Maybe<Array<ServiceReminderNotificationType>>;
  not?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
};

export type EnumServiceReminderNotificationTypeWithAggregatesFilter = {
  equals?: Maybe<ServiceReminderNotificationType>;
  in?: Maybe<Array<ServiceReminderNotificationType>>;
  notIn?: Maybe<Array<ServiceReminderNotificationType>>;
  not?: Maybe<NestedEnumServiceReminderNotificationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
  _max?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
};

export type EnumServiceReminderTimingOffsetUnitFieldUpdateOperationsInput = {
  set?: Maybe<ServiceReminderTimingOffsetUnit>;
};

export type EnumServiceReminderTimingOffsetUnitFilter = {
  equals?: Maybe<ServiceReminderTimingOffsetUnit>;
  in?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  notIn?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  not?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
};

export type EnumServiceReminderTimingOffsetUnitWithAggregatesFilter = {
  equals?: Maybe<ServiceReminderTimingOffsetUnit>;
  in?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  notIn?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  not?: Maybe<NestedEnumServiceReminderTimingOffsetUnitWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
  _max?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
};

export type EnumServiceReminderTimingTypeFieldUpdateOperationsInput = {
  set?: Maybe<ServiceReminderTimingType>;
};

export type EnumServiceReminderTimingTypeFilter = {
  equals?: Maybe<ServiceReminderTimingType>;
  in?: Maybe<Array<ServiceReminderTimingType>>;
  notIn?: Maybe<Array<ServiceReminderTimingType>>;
  not?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
};

export type EnumServiceReminderTimingTypeWithAggregatesFilter = {
  equals?: Maybe<ServiceReminderTimingType>;
  in?: Maybe<Array<ServiceReminderTimingType>>;
  notIn?: Maybe<Array<ServiceReminderTimingType>>;
  not?: Maybe<NestedEnumServiceReminderTimingTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
  _max?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
};

export type EnumSmsEntityTypeNullableFilter = {
  equals?: Maybe<SmsEntityType>;
  in?: Maybe<Array<SmsEntityType>>;
  notIn?: Maybe<Array<SmsEntityType>>;
  not?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
};

export type EnumSmsEntityTypeNullableWithAggregatesFilter = {
  equals?: Maybe<SmsEntityType>;
  in?: Maybe<Array<SmsEntityType>>;
  notIn?: Maybe<Array<SmsEntityType>>;
  not?: Maybe<NestedEnumSmsEntityTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
  _max?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
};

export type EnumSmsNotificationDeliveryErrorTypeNullableFilter = {
  equals?: Maybe<SmsNotificationDeliveryErrorType>;
  in?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  notIn?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
};

export type EnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter = {
  equals?: Maybe<SmsNotificationDeliveryErrorType>;
  in?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  notIn?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  _max?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
};

export type EnumSmsNotificationProviderFieldUpdateOperationsInput = {
  set?: Maybe<SmsNotificationProvider>;
};

export type EnumSmsNotificationProviderFilter = {
  equals?: Maybe<SmsNotificationProvider>;
  in?: Maybe<Array<SmsNotificationProvider>>;
  notIn?: Maybe<Array<SmsNotificationProvider>>;
  not?: Maybe<NestedEnumSmsNotificationProviderFilter>;
};

export type EnumSmsNotificationProviderWithAggregatesFilter = {
  equals?: Maybe<SmsNotificationProvider>;
  in?: Maybe<Array<SmsNotificationProvider>>;
  notIn?: Maybe<Array<SmsNotificationProvider>>;
  not?: Maybe<NestedEnumSmsNotificationProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSmsNotificationProviderFilter>;
  _max?: Maybe<NestedEnumSmsNotificationProviderFilter>;
};

export type EnumSmsNotificationStatusTypeFieldUpdateOperationsInput = {
  set?: Maybe<SmsNotificationStatusType>;
};

export type EnumSmsNotificationStatusTypeFilter = {
  equals?: Maybe<SmsNotificationStatusType>;
  in?: Maybe<Array<SmsNotificationStatusType>>;
  notIn?: Maybe<Array<SmsNotificationStatusType>>;
  not?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
};

export type EnumSmsNotificationStatusTypeWithAggregatesFilter = {
  equals?: Maybe<SmsNotificationStatusType>;
  in?: Maybe<Array<SmsNotificationStatusType>>;
  notIn?: Maybe<Array<SmsNotificationStatusType>>;
  not?: Maybe<NestedEnumSmsNotificationStatusTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
  _max?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
};

export type EnumSpeciesTypeFieldUpdateOperationsInput = {
  set?: Maybe<SpeciesType>;
};

export type EnumSpeciesTypeFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeFilter>;
};

export type EnumSpeciesTypeNullableFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
};

export type EnumSpeciesTypeNullableWithAggregatesFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
  _max?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
};

export type EnumSpeciesTypeWithAggregatesFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSpeciesTypeFilter>;
  _max?: Maybe<NestedEnumSpeciesTypeFilter>;
};

export type EnumStateFieldUpdateOperationsInput = {
  set?: Maybe<State>;
};

export type EnumStateFilter = {
  equals?: Maybe<State>;
  in?: Maybe<Array<State>>;
  notIn?: Maybe<Array<State>>;
  not?: Maybe<NestedEnumStateFilter>;
};

export type EnumStateWithAggregatesFilter = {
  equals?: Maybe<State>;
  in?: Maybe<Array<State>>;
  notIn?: Maybe<Array<State>>;
  not?: Maybe<NestedEnumStateWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStateFilter>;
  _max?: Maybe<NestedEnumStateFilter>;
};

export type EnumStripeCollectionMethodFieldUpdateOperationsInput = {
  set?: Maybe<StripeCollectionMethod>;
};

export type EnumStripeCollectionMethodFilter = {
  equals?: Maybe<StripeCollectionMethod>;
  in?: Maybe<Array<StripeCollectionMethod>>;
  notIn?: Maybe<Array<StripeCollectionMethod>>;
  not?: Maybe<NestedEnumStripeCollectionMethodFilter>;
};

export type EnumStripeCollectionMethodWithAggregatesFilter = {
  equals?: Maybe<StripeCollectionMethod>;
  in?: Maybe<Array<StripeCollectionMethod>>;
  notIn?: Maybe<Array<StripeCollectionMethod>>;
  not?: Maybe<NestedEnumStripeCollectionMethodWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeCollectionMethodFilter>;
  _max?: Maybe<NestedEnumStripeCollectionMethodFilter>;
};

export type EnumStripeDisputeStatusFieldUpdateOperationsInput = {
  set?: Maybe<StripeDisputeStatus>;
};

export type EnumStripeDisputeStatusFilter = {
  equals?: Maybe<StripeDisputeStatus>;
  in?: Maybe<Array<StripeDisputeStatus>>;
  notIn?: Maybe<Array<StripeDisputeStatus>>;
  not?: Maybe<NestedEnumStripeDisputeStatusFilter>;
};

export type EnumStripeDisputeStatusWithAggregatesFilter = {
  equals?: Maybe<StripeDisputeStatus>;
  in?: Maybe<Array<StripeDisputeStatus>>;
  notIn?: Maybe<Array<StripeDisputeStatus>>;
  not?: Maybe<NestedEnumStripeDisputeStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeDisputeStatusFilter>;
  _max?: Maybe<NestedEnumStripeDisputeStatusFilter>;
};

export type EnumStripeInvoiceStatusFieldUpdateOperationsInput = {
  set?: Maybe<StripeInvoiceStatus>;
};

export type EnumStripeInvoiceStatusFilter = {
  equals?: Maybe<StripeInvoiceStatus>;
  in?: Maybe<Array<StripeInvoiceStatus>>;
  notIn?: Maybe<Array<StripeInvoiceStatus>>;
  not?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
};

export type EnumStripeInvoiceStatusWithAggregatesFilter = {
  equals?: Maybe<StripeInvoiceStatus>;
  in?: Maybe<Array<StripeInvoiceStatus>>;
  notIn?: Maybe<Array<StripeInvoiceStatus>>;
  not?: Maybe<NestedEnumStripeInvoiceStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
  _max?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
};

export type EnumStripePaymentErrorNullableFilter = {
  equals?: Maybe<StripePaymentError>;
  in?: Maybe<Array<StripePaymentError>>;
  notIn?: Maybe<Array<StripePaymentError>>;
  not?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
};

export type EnumStripePaymentErrorNullableWithAggregatesFilter = {
  equals?: Maybe<StripePaymentError>;
  in?: Maybe<Array<StripePaymentError>>;
  notIn?: Maybe<Array<StripePaymentError>>;
  not?: Maybe<NestedEnumStripePaymentErrorNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
  _max?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
};

export type EnumStripePaymentIntentStatusFieldUpdateOperationsInput = {
  set?: Maybe<StripePaymentIntentStatus>;
};

export type EnumStripePaymentIntentStatusFilter = {
  equals?: Maybe<StripePaymentIntentStatus>;
  in?: Maybe<Array<StripePaymentIntentStatus>>;
  notIn?: Maybe<Array<StripePaymentIntentStatus>>;
  not?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
};

export type EnumStripePaymentIntentStatusWithAggregatesFilter = {
  equals?: Maybe<StripePaymentIntentStatus>;
  in?: Maybe<Array<StripePaymentIntentStatus>>;
  notIn?: Maybe<Array<StripePaymentIntentStatus>>;
  not?: Maybe<NestedEnumStripePaymentIntentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
  _max?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
};

export type EnumStripePaymentMethodTypeFieldUpdateOperationsInput = {
  set?: Maybe<StripePaymentMethodType>;
};

export type EnumStripePaymentMethodTypeFilter = {
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
};

export type EnumStripePaymentMethodTypeWithAggregatesFilter = {
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
  _max?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
};

export type EnumStripePaymentTransferTypeFieldUpdateOperationsInput = {
  set?: Maybe<StripePaymentTransferType>;
};

export type EnumStripePaymentTransferTypeFilter = {
  equals?: Maybe<StripePaymentTransferType>;
  in?: Maybe<Array<StripePaymentTransferType>>;
  notIn?: Maybe<Array<StripePaymentTransferType>>;
  not?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
};

export type EnumStripePaymentTransferTypeWithAggregatesFilter = {
  equals?: Maybe<StripePaymentTransferType>;
  in?: Maybe<Array<StripePaymentTransferType>>;
  notIn?: Maybe<Array<StripePaymentTransferType>>;
  not?: Maybe<NestedEnumStripePaymentTransferTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
  _max?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
};

export type EnumStripePayoutStatusFieldUpdateOperationsInput = {
  set?: Maybe<StripePayoutStatus>;
};

export type EnumStripePayoutStatusFilter = {
  equals?: Maybe<StripePayoutStatus>;
  in?: Maybe<Array<StripePayoutStatus>>;
  notIn?: Maybe<Array<StripePayoutStatus>>;
  not?: Maybe<NestedEnumStripePayoutStatusFilter>;
};

export type EnumStripePayoutStatusWithAggregatesFilter = {
  equals?: Maybe<StripePayoutStatus>;
  in?: Maybe<Array<StripePayoutStatus>>;
  notIn?: Maybe<Array<StripePayoutStatus>>;
  not?: Maybe<NestedEnumStripePayoutStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePayoutStatusFilter>;
  _max?: Maybe<NestedEnumStripePayoutStatusFilter>;
};

export type EnumStripeReceiptMediumFieldUpdateOperationsInput = {
  set?: Maybe<StripeReceiptMedium>;
};

export type EnumStripeReceiptMediumFilter = {
  equals?: Maybe<StripeReceiptMedium>;
  in?: Maybe<Array<StripeReceiptMedium>>;
  notIn?: Maybe<Array<StripeReceiptMedium>>;
  not?: Maybe<NestedEnumStripeReceiptMediumFilter>;
};

export type EnumStripeReceiptMediumWithAggregatesFilter = {
  equals?: Maybe<StripeReceiptMedium>;
  in?: Maybe<Array<StripeReceiptMedium>>;
  notIn?: Maybe<Array<StripeReceiptMedium>>;
  not?: Maybe<NestedEnumStripeReceiptMediumWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeReceiptMediumFilter>;
  _max?: Maybe<NestedEnumStripeReceiptMediumFilter>;
};

export type EnumStripeTerminalLocationTypeFieldUpdateOperationsInput = {
  set?: Maybe<StripeTerminalLocationType>;
};

export type EnumStripeTerminalLocationTypeFilter = {
  equals?: Maybe<StripeTerminalLocationType>;
  in?: Maybe<Array<StripeTerminalLocationType>>;
  notIn?: Maybe<Array<StripeTerminalLocationType>>;
  not?: Maybe<NestedEnumStripeTerminalLocationTypeFilter>;
};

export type EnumStripeTerminalLocationTypeWithAggregatesFilter = {
  equals?: Maybe<StripeTerminalLocationType>;
  in?: Maybe<Array<StripeTerminalLocationType>>;
  notIn?: Maybe<Array<StripeTerminalLocationType>>;
  not?: Maybe<NestedEnumStripeTerminalLocationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeTerminalLocationTypeFilter>;
  _max?: Maybe<NestedEnumStripeTerminalLocationTypeFilter>;
};

export type EnumStripeTerminalModelFieldUpdateOperationsInput = {
  set?: Maybe<StripeTerminalModel>;
};

export type EnumStripeTerminalModelFilter = {
  equals?: Maybe<StripeTerminalModel>;
  in?: Maybe<Array<StripeTerminalModel>>;
  notIn?: Maybe<Array<StripeTerminalModel>>;
  not?: Maybe<NestedEnumStripeTerminalModelFilter>;
};

export type EnumStripeTerminalModelWithAggregatesFilter = {
  equals?: Maybe<StripeTerminalModel>;
  in?: Maybe<Array<StripeTerminalModel>>;
  notIn?: Maybe<Array<StripeTerminalModel>>;
  not?: Maybe<NestedEnumStripeTerminalModelWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeTerminalModelFilter>;
  _max?: Maybe<NestedEnumStripeTerminalModelFilter>;
};

export type EnumStripeTerminalStatusFieldUpdateOperationsInput = {
  set?: Maybe<StripeTerminalStatus>;
};

export type EnumStripeTerminalStatusFilter = {
  equals?: Maybe<StripeTerminalStatus>;
  in?: Maybe<Array<StripeTerminalStatus>>;
  notIn?: Maybe<Array<StripeTerminalStatus>>;
  not?: Maybe<NestedEnumStripeTerminalStatusFilter>;
};

export type EnumStripeTerminalStatusWithAggregatesFilter = {
  equals?: Maybe<StripeTerminalStatus>;
  in?: Maybe<Array<StripeTerminalStatus>>;
  notIn?: Maybe<Array<StripeTerminalStatus>>;
  not?: Maybe<NestedEnumStripeTerminalStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeTerminalStatusFilter>;
  _max?: Maybe<NestedEnumStripeTerminalStatusFilter>;
};

export type EnumSupportEventStatusFieldUpdateOperationsInput = {
  set?: Maybe<SupportEventStatus>;
};

export type EnumSupportEventStatusFilter = {
  equals?: Maybe<SupportEventStatus>;
  in?: Maybe<Array<SupportEventStatus>>;
  notIn?: Maybe<Array<SupportEventStatus>>;
  not?: Maybe<NestedEnumSupportEventStatusFilter>;
};

export type EnumSupportEventStatusWithAggregatesFilter = {
  equals?: Maybe<SupportEventStatus>;
  in?: Maybe<Array<SupportEventStatus>>;
  notIn?: Maybe<Array<SupportEventStatus>>;
  not?: Maybe<NestedEnumSupportEventStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSupportEventStatusFilter>;
  _max?: Maybe<NestedEnumSupportEventStatusFilter>;
};

export type EnumSurveyStepNullableFilter = {
  equals?: Maybe<SurveyStep>;
  in?: Maybe<Array<SurveyStep>>;
  notIn?: Maybe<Array<SurveyStep>>;
  not?: Maybe<NestedEnumSurveyStepNullableFilter>;
};

export type EnumSurveyStepNullableWithAggregatesFilter = {
  equals?: Maybe<SurveyStep>;
  in?: Maybe<Array<SurveyStep>>;
  notIn?: Maybe<Array<SurveyStep>>;
  not?: Maybe<NestedEnumSurveyStepNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSurveyStepNullableFilter>;
  _max?: Maybe<NestedEnumSurveyStepNullableFilter>;
};

export type EnumSurveyTypeFieldUpdateOperationsInput = {
  set?: Maybe<SurveyType>;
};

export type EnumSurveyTypeFilter = {
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  notIn?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeFilter>;
};

export type EnumSurveyTypeNullableFilter = {
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  notIn?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeNullableFilter>;
};

export type EnumSurveyTypeNullableWithAggregatesFilter = {
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  notIn?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSurveyTypeNullableFilter>;
  _max?: Maybe<NestedEnumSurveyTypeNullableFilter>;
};

export type EnumSurveyTypeWithAggregatesFilter = {
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  notIn?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSurveyTypeFilter>;
  _max?: Maybe<NestedEnumSurveyTypeFilter>;
};

export type EnumTemplateAvailabilityFieldUpdateOperationsInput = {
  set?: Maybe<TemplateAvailability>;
};

export type EnumTemplateAvailabilityFilter = {
  equals?: Maybe<TemplateAvailability>;
  in?: Maybe<Array<TemplateAvailability>>;
  notIn?: Maybe<Array<TemplateAvailability>>;
  not?: Maybe<NestedEnumTemplateAvailabilityFilter>;
};

export type EnumTemplateAvailabilityWithAggregatesFilter = {
  equals?: Maybe<TemplateAvailability>;
  in?: Maybe<Array<TemplateAvailability>>;
  notIn?: Maybe<Array<TemplateAvailability>>;
  not?: Maybe<NestedEnumTemplateAvailabilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumTemplateAvailabilityFilter>;
  _max?: Maybe<NestedEnumTemplateAvailabilityFilter>;
};

export type EnumTwilioRecordingStatusFieldUpdateOperationsInput = {
  set?: Maybe<TwilioRecordingStatus>;
};

export type EnumTwilioRecordingStatusFilter = {
  equals?: Maybe<TwilioRecordingStatus>;
  in?: Maybe<Array<TwilioRecordingStatus>>;
  notIn?: Maybe<Array<TwilioRecordingStatus>>;
  not?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
};

export type EnumTwilioRecordingStatusWithAggregatesFilter = {
  equals?: Maybe<TwilioRecordingStatus>;
  in?: Maybe<Array<TwilioRecordingStatus>>;
  notIn?: Maybe<Array<TwilioRecordingStatus>>;
  not?: Maybe<NestedEnumTwilioRecordingStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
  _max?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
};

export type EnumTwilioVerificationStatusFieldUpdateOperationsInput = {
  set?: Maybe<TwilioVerificationStatus>;
};

export type EnumTwilioVerificationStatusFilter = {
  equals?: Maybe<TwilioVerificationStatus>;
  in?: Maybe<Array<TwilioVerificationStatus>>;
  notIn?: Maybe<Array<TwilioVerificationStatus>>;
  not?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
};

export type EnumTwilioVerificationStatusWithAggregatesFilter = {
  equals?: Maybe<TwilioVerificationStatus>;
  in?: Maybe<Array<TwilioVerificationStatus>>;
  notIn?: Maybe<Array<TwilioVerificationStatus>>;
  not?: Maybe<NestedEnumTwilioVerificationStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
  _max?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
};

export type EnumUserTypeFieldUpdateOperationsInput = {
  set?: Maybe<UserType>;
};

export type EnumUserTypeFilter = {
  equals?: Maybe<UserType>;
  in?: Maybe<Array<UserType>>;
  notIn?: Maybe<Array<UserType>>;
  not?: Maybe<NestedEnumUserTypeFilter>;
};

export type EnumUserTypeWithAggregatesFilter = {
  equals?: Maybe<UserType>;
  in?: Maybe<Array<UserType>>;
  notIn?: Maybe<Array<UserType>>;
  not?: Maybe<NestedEnumUserTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumUserTypeFilter>;
  _max?: Maybe<NestedEnumUserTypeFilter>;
};

export type EnumVetDataIntegrationSystemFieldUpdateOperationsInput = {
  set?: Maybe<VetDataIntegrationSystem>;
};

export type EnumVetDataIntegrationSystemFilter = {
  equals?: Maybe<VetDataIntegrationSystem>;
  in?: Maybe<Array<VetDataIntegrationSystem>>;
  notIn?: Maybe<Array<VetDataIntegrationSystem>>;
  not?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
};

export type EnumVetDataIntegrationSystemWithAggregatesFilter = {
  equals?: Maybe<VetDataIntegrationSystem>;
  in?: Maybe<Array<VetDataIntegrationSystem>>;
  notIn?: Maybe<Array<VetDataIntegrationSystem>>;
  not?: Maybe<NestedEnumVetDataIntegrationSystemWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
  _max?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
};

export type EnumVetLicenseTypeFieldUpdateOperationsInput = {
  set?: Maybe<VetLicenseType>;
};

export type EnumVetLicenseTypeFilter = {
  equals?: Maybe<VetLicenseType>;
  in?: Maybe<Array<VetLicenseType>>;
  notIn?: Maybe<Array<VetLicenseType>>;
  not?: Maybe<NestedEnumVetLicenseTypeFilter>;
};

export type EnumVetLicenseTypeWithAggregatesFilter = {
  equals?: Maybe<VetLicenseType>;
  in?: Maybe<Array<VetLicenseType>>;
  notIn?: Maybe<Array<VetLicenseType>>;
  not?: Maybe<NestedEnumVetLicenseTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVetLicenseTypeFilter>;
  _max?: Maybe<NestedEnumVetLicenseTypeFilter>;
};

export type EnumVetsourceCodeTagTypeFieldUpdateOperationsInput = {
  set?: Maybe<VetsourceCodeTagType>;
};

export type EnumVetsourceCodeTagTypeFilter = {
  equals?: Maybe<VetsourceCodeTagType>;
  in?: Maybe<Array<VetsourceCodeTagType>>;
  notIn?: Maybe<Array<VetsourceCodeTagType>>;
  not?: Maybe<NestedEnumVetsourceCodeTagTypeFilter>;
};

export type EnumVetsourceCodeTagTypeWithAggregatesFilter = {
  equals?: Maybe<VetsourceCodeTagType>;
  in?: Maybe<Array<VetsourceCodeTagType>>;
  notIn?: Maybe<Array<VetsourceCodeTagType>>;
  not?: Maybe<NestedEnumVetsourceCodeTagTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVetsourceCodeTagTypeFilter>;
  _max?: Maybe<NestedEnumVetsourceCodeTagTypeFilter>;
};

export type EnumVetsourceInstallationConnectivityStateFieldUpdateOperationsInput = {
  set?: Maybe<VetsourceInstallationConnectivityState>;
};

export type EnumVetsourceInstallationConnectivityStateFilter = {
  equals?: Maybe<VetsourceInstallationConnectivityState>;
  in?: Maybe<Array<VetsourceInstallationConnectivityState>>;
  notIn?: Maybe<Array<VetsourceInstallationConnectivityState>>;
  not?: Maybe<NestedEnumVetsourceInstallationConnectivityStateFilter>;
};

export type EnumVetsourceInstallationConnectivityStateWithAggregatesFilter = {
  equals?: Maybe<VetsourceInstallationConnectivityState>;
  in?: Maybe<Array<VetsourceInstallationConnectivityState>>;
  notIn?: Maybe<Array<VetsourceInstallationConnectivityState>>;
  not?: Maybe<NestedEnumVetsourceInstallationConnectivityStateWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVetsourceInstallationConnectivityStateFilter>;
  _max?: Maybe<NestedEnumVetsourceInstallationConnectivityStateFilter>;
};

export type EnumWidgetRequestTypeFieldUpdateOperationsInput = {
  set?: Maybe<WidgetRequestType>;
};

export type EnumWidgetRequestTypeFilter = {
  equals?: Maybe<WidgetRequestType>;
  in?: Maybe<Array<WidgetRequestType>>;
  notIn?: Maybe<Array<WidgetRequestType>>;
  not?: Maybe<NestedEnumWidgetRequestTypeFilter>;
};

export type EnumWidgetRequestTypeWithAggregatesFilter = {
  equals?: Maybe<WidgetRequestType>;
  in?: Maybe<Array<WidgetRequestType>>;
  notIn?: Maybe<Array<WidgetRequestType>>;
  not?: Maybe<NestedEnumWidgetRequestTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWidgetRequestTypeFilter>;
  _max?: Maybe<NestedEnumWidgetRequestTypeFilter>;
};

export type EnumWorkflowActionTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowActionType>;
};

export type EnumWorkflowActionTypeFilter = {
  equals?: Maybe<WorkflowActionType>;
  in?: Maybe<Array<WorkflowActionType>>;
  notIn?: Maybe<Array<WorkflowActionType>>;
  not?: Maybe<NestedEnumWorkflowActionTypeFilter>;
};

export type EnumWorkflowActionTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowActionType>;
  in?: Maybe<Array<WorkflowActionType>>;
  notIn?: Maybe<Array<WorkflowActionType>>;
  not?: Maybe<NestedEnumWorkflowActionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowActionTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowActionTypeFilter>;
};

export type EnumWorkflowEventFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowEvent>;
};

export type EnumWorkflowEventFilter = {
  equals?: Maybe<WorkflowEvent>;
  in?: Maybe<Array<WorkflowEvent>>;
  notIn?: Maybe<Array<WorkflowEvent>>;
  not?: Maybe<NestedEnumWorkflowEventFilter>;
};

export type EnumWorkflowEventTriggerTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowEventTriggerType>;
};

export type EnumWorkflowEventTriggerTypeFilter = {
  equals?: Maybe<WorkflowEventTriggerType>;
  in?: Maybe<Array<WorkflowEventTriggerType>>;
  notIn?: Maybe<Array<WorkflowEventTriggerType>>;
  not?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
};

export type EnumWorkflowEventTriggerTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowEventTriggerType>;
  in?: Maybe<Array<WorkflowEventTriggerType>>;
  notIn?: Maybe<Array<WorkflowEventTriggerType>>;
  not?: Maybe<NestedEnumWorkflowEventTriggerTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
};

export type EnumWorkflowEventWithAggregatesFilter = {
  equals?: Maybe<WorkflowEvent>;
  in?: Maybe<Array<WorkflowEvent>>;
  notIn?: Maybe<Array<WorkflowEvent>>;
  not?: Maybe<NestedEnumWorkflowEventWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowEventFilter>;
  _max?: Maybe<NestedEnumWorkflowEventFilter>;
};

export type EnumWorkflowSendingHoursFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowSendingHours>;
};

export type EnumWorkflowSendingHoursFilter = {
  equals?: Maybe<WorkflowSendingHours>;
  in?: Maybe<Array<WorkflowSendingHours>>;
  notIn?: Maybe<Array<WorkflowSendingHours>>;
  not?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
};

export type EnumWorkflowSendingHoursWithAggregatesFilter = {
  equals?: Maybe<WorkflowSendingHours>;
  in?: Maybe<Array<WorkflowSendingHours>>;
  notIn?: Maybe<Array<WorkflowSendingHours>>;
  not?: Maybe<NestedEnumWorkflowSendingHoursWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
  _max?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
};

export type EnumWorkflowTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowType>;
};

export type EnumWorkflowTypeFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeFilter>;
};

export type EnumWorkflowTypeNullableFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
};

export type EnumWorkflowTypeNullableWithAggregatesFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
  _max?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
};

export type EnumWorkflowTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowTypeFilter>;
};

export type EnumWorkflowVisitTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowVisitType>;
};

export type EnumWorkflowVisitTypeFilter = {
  equals?: Maybe<WorkflowVisitType>;
  in?: Maybe<Array<WorkflowVisitType>>;
  notIn?: Maybe<Array<WorkflowVisitType>>;
  not?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
};

export type EnumWorkflowVisitTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowVisitType>;
  in?: Maybe<Array<WorkflowVisitType>>;
  notIn?: Maybe<Array<WorkflowVisitType>>;
  not?: Maybe<NestedEnumWorkflowVisitTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
};

export type ExportCancellationSummaryAsPdfInput = {
  petName: Scalars['String'];
  species: Scalars['String'];
  plan: Scalars['String'];
  hasAccountCredit: Scalars['Boolean'];
  hasDiscount: Scalars['Boolean'];
  hasTeletriage: Scalars['Boolean'];
  hasPlanDiscount: Scalars['Boolean'];
  accountCredit?: Maybe<ItemInfo>;
  exams?: Maybe<ItemInfo>;
  discount?: Maybe<ItemInfo>;
  teletriage?: Maybe<ItemInfo>;
  fees?: Maybe<ItemInfo>;
  manualAdjustment?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<ItemInfo>;
  paymentsMade?: Maybe<PaymentsMade>;
  balanceDue: Scalars['Float'];
  totalSavings: Scalars['Float'];
  includeFees: Scalars['Boolean'];
  includeManualAdjustment: Scalars['Boolean'];
  remainingMembershipBalance?: Maybe<PaymentsMade>;
  applyDiscounts: Scalars['Boolean'];
};

export type ExportCancellationSummaryAsPdfResponse = {
  __typename?: 'ExportCancellationSummaryAsPdfResponse';
  success: Scalars['Boolean'];
  base64PDF?: Maybe<Scalars['String']>;
};

export type ExportItemizedInvoiceToReceiptPdfInput = {
  invoiceId: Scalars['String'];
};

export type ExportItemizedInvoiceToReceiptPdfResponse = {
  __typename?: 'ExportItemizedInvoiceToReceiptPDFResponse';
  success: Scalars['Boolean'];
  base64PDF?: Maybe<Scalars['String']>;
};

export type ExportNonItemizedReceiptAsPdfInput = {
  stripePaymentIntentId: Scalars['String'];
};

export type ExportNonItemizedReceiptAsPdfResponse = {
  __typename?: 'ExportNonItemizedReceiptAsPdfResponse';
  success: Scalars['Boolean'];
  base64PDF?: Maybe<Scalars['String']>;
};

export type EzyVetIntegration = {
  __typename?: 'EzyVetIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type EzyVetIntegrationCountAggregateOutputType = {
  __typename?: 'EzyVetIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  client_id: Scalars['Int'];
  client_secret: Scalars['Int'];
  ownership_id: Scalars['Int'];
  defaultAppointmentTypeId: Scalars['Int'];
  scopes: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EzyVetIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
};

export type EzyVetIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEzyvetInput>;
};

export type EzyVetIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<EzyVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<EzyVetIntegrationWhereUniqueInput>;
};

export type EzyVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: EzyVetIntegrationWhereUniqueInput;
  create: EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type EzyVetIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationMaxAggregateOutputType = {
  __typename?: 'EzyVetIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
};

export type EzyVetIntegrationMinAggregateOutputType = {
  __typename?: 'EzyVetIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
};

export enum EzyVetIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  ClientId = 'client_id',
  ClientSecret = 'client_secret',
  OwnershipId = 'ownership_id',
  DefaultAppointmentTypeId = 'defaultAppointmentTypeId',
  Scopes = 'scopes'
}

export type EzyVetIntegrationOrderByRelevanceInput = {
  fields: Array<EzyVetIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EzyVetIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
  _count?: Maybe<EzyVetIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<EzyVetIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<EzyVetIntegrationMinOrderByAggregateInput>;
};

export type EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<EzyVetIntegrationOrderByRelevanceInput>;
};

export type EzyVetIntegrationRelationFilter = {
  is?: Maybe<EzyVetIntegrationWhereInput>;
  isNot?: Maybe<EzyVetIntegrationWhereInput>;
};

export enum EzyVetIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClientId = 'client_id',
  ClientSecret = 'client_secret',
  OwnershipId = 'ownership_id',
  DefaultAppointmentTypeId = 'defaultAppointmentTypeId',
  Scopes = 'scopes'
}

export type EzyVetIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EzyVetIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EzyVetIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EzyVetIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  client_id?: Maybe<StringNullableWithAggregatesFilter>;
  client_secret?: Maybe<StringNullableWithAggregatesFilter>;
  ownership_id?: Maybe<StringNullableWithAggregatesFilter>;
  defaultAppointmentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  scopes?: Maybe<StringNullableWithAggregatesFilter>;
};

export type EzyVetIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutEzyvetInput>;
};

export type EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutEzyvetNestedInput>;
};

export type EzyVetIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEzyvetNestedInput>;
};

export type EzyVetIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  create?: Maybe<EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<EzyVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<EzyVetIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  update?: Maybe<EzyVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type EzyVetIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: EzyVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type EzyVetIntegrationWhereInput = {
  AND?: Maybe<Array<EzyVetIntegrationWhereInput>>;
  OR?: Maybe<Array<EzyVetIntegrationWhereInput>>;
  NOT?: Maybe<Array<EzyVetIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  client_id?: Maybe<StringNullableFilter>;
  client_secret?: Maybe<StringNullableFilter>;
  ownership_id?: Maybe<StringNullableFilter>;
  defaultAppointmentTypeId?: Maybe<StringNullableFilter>;
  scopes?: Maybe<StringNullableFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type EzyVetIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: FeatureFlagType;
  state: FeatureFlagState;
  clinics: Array<Clinic>;
  _count: FeatureFlagCountOutputType;
};


export type FeatureFlagClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};

export type FeatureFlagCountAggregateOutputType = {
  __typename?: 'FeatureFlagCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  key: Scalars['Int'];
  description: Scalars['Int'];
  type: Scalars['Int'];
  state: Scalars['Int'];
  _all: Scalars['Int'];
};

export type FeatureFlagCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
};

export type FeatureFlagCountOutputType = {
  __typename?: 'FeatureFlagCountOutputType';
  clinics: Scalars['Int'];
};

export type FeatureFlagCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: FeatureFlagType;
  state?: Maybe<FeatureFlagState>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutFeatureFlagsInput>;
};

export type FeatureFlagCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: FeatureFlagType;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<FeatureFlagCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<FeatureFlagCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
};

export type FeatureFlagCreateOrConnectWithoutClinicsInput = {
  where: FeatureFlagWhereUniqueInput;
  create: FeatureFlagUncheckedCreateWithoutClinicsInput;
};

export type FeatureFlagCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: FeatureFlagType;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagListRelationFilter = {
  every?: Maybe<FeatureFlagWhereInput>;
  some?: Maybe<FeatureFlagWhereInput>;
  none?: Maybe<FeatureFlagWhereInput>;
};

export type FeatureFlagMaxAggregateOutputType = {
  __typename?: 'FeatureFlagMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
};

export type FeatureFlagMinAggregateOutputType = {
  __typename?: 'FeatureFlagMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
};

export type FeatureFlagOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum FeatureFlagOrderByRelevanceFieldEnum {
  Id = 'id',
  Key = 'key',
  Description = 'description'
}

export type FeatureFlagOrderByRelevanceInput = {
  fields: Array<FeatureFlagOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type FeatureFlagOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  _count?: Maybe<FeatureFlagCountOrderByAggregateInput>;
  _max?: Maybe<FeatureFlagMaxOrderByAggregateInput>;
  _min?: Maybe<FeatureFlagMinOrderByAggregateInput>;
};

export type FeatureFlagOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  _relevance?: Maybe<FeatureFlagOrderByRelevanceInput>;
};

export enum FeatureFlagScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Key = 'key',
  Description = 'description',
  Type = 'type',
  State = 'state'
}

export type FeatureFlagScalarWhereInput = {
  AND?: Maybe<Array<FeatureFlagScalarWhereInput>>;
  OR?: Maybe<Array<FeatureFlagScalarWhereInput>>;
  NOT?: Maybe<Array<FeatureFlagScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  key?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumFeatureFlagTypeFilter>;
  state?: Maybe<EnumFeatureFlagStateFilter>;
};

export type FeatureFlagScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FeatureFlagScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FeatureFlagScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FeatureFlagScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  key?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  type?: Maybe<EnumFeatureFlagTypeWithAggregatesFilter>;
  state?: Maybe<EnumFeatureFlagStateWithAggregatesFilter>;
};

export enum FeatureFlagState {
  On = 'On',
  Off = 'Off'
}

export enum FeatureFlagType {
  Global = 'Global',
  Clinic = 'Clinic'
}

export type FeatureFlagUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: FeatureFlagType;
  state?: Maybe<FeatureFlagState>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutFeatureFlagsInput>;
};

export type FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<FeatureFlagCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<FeatureFlagCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
};

export type FeatureFlagUncheckedCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: FeatureFlagType;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutFeatureFlagsNestedInput>;
};

export type FeatureFlagUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput = {
  create?: Maybe<Array<FeatureFlagCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<FeatureFlagCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<FeatureFlagUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  disconnect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  delete?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  connect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  update?: Maybe<Array<FeatureFlagUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<FeatureFlagUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<FeatureFlagScalarWhereInput>>;
};

export type FeatureFlagUncheckedUpdateManyWithoutFeatureFlagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagUncheckedUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
  clinics?: Maybe<ClinicUpdateManyWithoutFeatureFlagsNestedInput>;
};

export type FeatureFlagUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagUpdateManyWithWhereWithoutClinicsInput = {
  where: FeatureFlagScalarWhereInput;
  data: FeatureFlagUncheckedUpdateManyWithoutFeatureFlagsInput;
};

export type FeatureFlagUpdateManyWithoutClinicsNestedInput = {
  create?: Maybe<Array<FeatureFlagCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<FeatureFlagCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<FeatureFlagUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  disconnect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  delete?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  connect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  update?: Maybe<Array<FeatureFlagUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<FeatureFlagUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<FeatureFlagScalarWhereInput>>;
};

export type FeatureFlagUpdateWithWhereUniqueWithoutClinicsInput = {
  where: FeatureFlagWhereUniqueInput;
  data: FeatureFlagUncheckedUpdateWithoutClinicsInput;
};

export type FeatureFlagUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagUpsertWithWhereUniqueWithoutClinicsInput = {
  where: FeatureFlagWhereUniqueInput;
  update: FeatureFlagUncheckedUpdateWithoutClinicsInput;
  create: FeatureFlagUncheckedCreateWithoutClinicsInput;
};

export type FeatureFlagWhereInput = {
  AND?: Maybe<Array<FeatureFlagWhereInput>>;
  OR?: Maybe<Array<FeatureFlagWhereInput>>;
  NOT?: Maybe<Array<FeatureFlagWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  key?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumFeatureFlagTypeFilter>;
  state?: Maybe<EnumFeatureFlagStateFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
};

export type FeatureFlagWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export enum FeeCollection {
  PostPaid = 'PostPaid',
  DuringPayment = 'DuringPayment'
}

export type FileExportData = {
  __typename?: 'FileExportData';
  fileType: Scalars['String'];
  base64Encoding: Scalars['String'];
};

export enum FilterSelectionType {
  Private = 'Private',
  PublicReadonly = 'PublicReadonly'
}

export type FinancialAdjustment = {
  __typename?: 'FinancialAdjustment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  amount: Scalars['Int'];
  description: Scalars['String'];
  financialTransaction?: Maybe<FinancialTransaction>;
};

export type FinancialAdjustmentAvgAggregateOutputType = {
  __typename?: 'FinancialAdjustmentAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type FinancialAdjustmentAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type FinancialAdjustmentCountAggregateOutputType = {
  __typename?: 'FinancialAdjustmentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  amount: Scalars['Int'];
  description: Scalars['Int'];
  _all: Scalars['Int'];
};

export type FinancialAdjustmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type FinancialAdjustmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  description: Scalars['String'];
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutAdjustmentInput>;
};

export type FinancialAdjustmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  description: Scalars['String'];
};

export type FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput = {
  create?: Maybe<FinancialAdjustmentUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<FinancialAdjustmentCreateOrConnectWithoutFinancialTransactionInput>;
  connect?: Maybe<FinancialAdjustmentWhereUniqueInput>;
};

export type FinancialAdjustmentCreateOrConnectWithoutFinancialTransactionInput = {
  where: FinancialAdjustmentWhereUniqueInput;
  create: FinancialAdjustmentUncheckedCreateWithoutFinancialTransactionInput;
};

export type FinancialAdjustmentCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  description: Scalars['String'];
};

export type FinancialAdjustmentMaxAggregateOutputType = {
  __typename?: 'FinancialAdjustmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type FinancialAdjustmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type FinancialAdjustmentMinAggregateOutputType = {
  __typename?: 'FinancialAdjustmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type FinancialAdjustmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export enum FinancialAdjustmentOrderByRelevanceFieldEnum {
  Id = 'id',
  Description = 'description'
}

export type FinancialAdjustmentOrderByRelevanceInput = {
  fields: Array<FinancialAdjustmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type FinancialAdjustmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  _count?: Maybe<FinancialAdjustmentCountOrderByAggregateInput>;
  _avg?: Maybe<FinancialAdjustmentAvgOrderByAggregateInput>;
  _max?: Maybe<FinancialAdjustmentMaxOrderByAggregateInput>;
  _min?: Maybe<FinancialAdjustmentMinOrderByAggregateInput>;
  _sum?: Maybe<FinancialAdjustmentSumOrderByAggregateInput>;
};

export type FinancialAdjustmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  financialTransaction?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<FinancialAdjustmentOrderByRelevanceInput>;
};

export type FinancialAdjustmentRelationFilter = {
  is?: Maybe<FinancialAdjustmentWhereInput>;
  isNot?: Maybe<FinancialAdjustmentWhereInput>;
};

export enum FinancialAdjustmentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Amount = 'amount',
  Description = 'description'
}

export type FinancialAdjustmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FinancialAdjustmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FinancialAdjustmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FinancialAdjustmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
};

export type FinancialAdjustmentSumAggregateOutputType = {
  __typename?: 'FinancialAdjustmentSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
};

export type FinancialAdjustmentSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type FinancialAdjustmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  description: Scalars['String'];
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutAdjustmentInput>;
};

export type FinancialAdjustmentUncheckedCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  description: Scalars['String'];
};

export type FinancialAdjustmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutAdjustmentNestedInput>;
};

export type FinancialAdjustmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type FinancialAdjustmentUncheckedUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type FinancialAdjustmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutAdjustmentNestedInput>;
};

export type FinancialAdjustmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput = {
  create?: Maybe<FinancialAdjustmentUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<FinancialAdjustmentCreateOrConnectWithoutFinancialTransactionInput>;
  upsert?: Maybe<FinancialAdjustmentUpsertWithoutFinancialTransactionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialAdjustmentWhereUniqueInput>;
  update?: Maybe<FinancialAdjustmentUncheckedUpdateWithoutFinancialTransactionInput>;
};

export type FinancialAdjustmentUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type FinancialAdjustmentUpsertWithoutFinancialTransactionInput = {
  update: FinancialAdjustmentUncheckedUpdateWithoutFinancialTransactionInput;
  create: FinancialAdjustmentUncheckedCreateWithoutFinancialTransactionInput;
};

export type FinancialAdjustmentWhereInput = {
  AND?: Maybe<Array<FinancialAdjustmentWhereInput>>;
  OR?: Maybe<Array<FinancialAdjustmentWhereInput>>;
  NOT?: Maybe<Array<FinancialAdjustmentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  amount?: Maybe<IntFilter>;
  description?: Maybe<StringFilter>;
  financialTransaction?: Maybe<FinancialTransactionWhereInput>;
};

export type FinancialAdjustmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FinancialTransaction = {
  __typename?: 'FinancialTransaction';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinic: Clinic;
  stripePaymentIntent?: Maybe<StripePaymentIntent>;
  stripeInvoice?: Maybe<StripeInvoice>;
  stripeRefund?: Maybe<StripeRefund>;
  stripeDispute?: Maybe<StripeDispute>;
  adjustment?: Maybe<FinancialAdjustment>;
  stripePayout?: Maybe<StripePayout>;
  channel?: Maybe<Channel>;
};

export type FinancialTransactionCountAggregateOutputType = {
  __typename?: 'FinancialTransactionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  timestamp: Scalars['Int'];
  type: Scalars['Int'];
  clinicId: Scalars['Int'];
  stripePaymentIntentId: Scalars['Int'];
  stripeInvoiceId: Scalars['Int'];
  stripeRefundId: Scalars['Int'];
  stripeDisputeId: Scalars['Int'];
  adjustmentId: Scalars['Int'];
  stripePayoutId: Scalars['Int'];
  channelId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type FinancialTransactionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  stripeDisputeId?: Maybe<SortOrder>;
  adjustmentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
};

export type FinancialTransactionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateManyChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionCreateManyChannelInputEnvelope = {
  data: Array<FinancialTransactionCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FinancialTransactionCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionCreateManyClinicInputEnvelope = {
  data: Array<FinancialTransactionCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FinancialTransactionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionCreateManyStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionCreateManyStripePayoutInputEnvelope = {
  data: Array<FinancialTransactionCreateManyStripePayoutInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FinancialTransactionCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
};

export type FinancialTransactionCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
};

export type FinancialTransactionCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
};

export type FinancialTransactionCreateNestedOneWithoutAdjustmentInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutAdjustmentInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutAdjustmentInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionCreateNestedOneWithoutStripeDisputeInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeDisputeInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeDisputeInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionCreateNestedOneWithoutStripeRefundInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeRefundInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeRefundInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionCreateOrConnectWithoutAdjustmentInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutAdjustmentInput;
};

export type FinancialTransactionCreateOrConnectWithoutChannelInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutChannelInput;
};

export type FinancialTransactionCreateOrConnectWithoutClinicInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutClinicInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripeDisputeInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutStripeDisputeInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripePayoutInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutStripePayoutInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripeRefundInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutStripeRefundInput;
};

export type FinancialTransactionCreateWithoutAdjustmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateWithoutStripeRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionListRelationFilter = {
  every?: Maybe<FinancialTransactionWhereInput>;
  some?: Maybe<FinancialTransactionWhereInput>;
  none?: Maybe<FinancialTransactionWhereInput>;
};

export type FinancialTransactionMaxAggregateOutputType = {
  __typename?: 'FinancialTransactionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  stripeDisputeId?: Maybe<SortOrder>;
  adjustmentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
};

export type FinancialTransactionMinAggregateOutputType = {
  __typename?: 'FinancialTransactionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  stripeDisputeId?: Maybe<SortOrder>;
  adjustmentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
};

export type FinancialTransactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum FinancialTransactionOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripeInvoiceId = 'stripeInvoiceId',
  StripeRefundId = 'stripeRefundId',
  StripeDisputeId = 'stripeDisputeId',
  AdjustmentId = 'adjustmentId',
  StripePayoutId = 'stripePayoutId',
  ChannelId = 'channelId'
}

export type FinancialTransactionOrderByRelevanceInput = {
  fields: Array<FinancialTransactionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type FinancialTransactionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  stripeDisputeId?: Maybe<SortOrder>;
  adjustmentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  _count?: Maybe<FinancialTransactionCountOrderByAggregateInput>;
  _max?: Maybe<FinancialTransactionMaxOrderByAggregateInput>;
  _min?: Maybe<FinancialTransactionMinOrderByAggregateInput>;
};

export type FinancialTransactionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  stripeDisputeId?: Maybe<SortOrder>;
  adjustmentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  stripeInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  stripeRefund?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  stripeDispute?: Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>;
  adjustment?: Maybe<FinancialAdjustmentOrderByWithRelationAndSearchRelevanceInput>;
  stripePayout?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<FinancialTransactionOrderByRelevanceInput>;
};

export type FinancialTransactionRelationFilter = {
  is?: Maybe<FinancialTransactionWhereInput>;
  isNot?: Maybe<FinancialTransactionWhereInput>;
};

export enum FinancialTransactionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Timestamp = 'timestamp',
  Type = 'type',
  ClinicId = 'clinicId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripeInvoiceId = 'stripeInvoiceId',
  StripeRefundId = 'stripeRefundId',
  StripeDisputeId = 'stripeDisputeId',
  AdjustmentId = 'adjustmentId',
  StripePayoutId = 'stripePayoutId',
  ChannelId = 'channelId'
}

export type FinancialTransactionScalarWhereInput = {
  AND?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  OR?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  NOT?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  timestamp?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumFinancialTransactionTypeFilter>;
  clinicId?: Maybe<StringFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
  stripeRefundId?: Maybe<StringNullableFilter>;
  stripeDisputeId?: Maybe<StringNullableFilter>;
  adjustmentId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
};

export type FinancialTransactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FinancialTransactionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FinancialTransactionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FinancialTransactionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  timestamp?: Maybe<DateTimeWithAggregatesFilter>;
  type?: Maybe<EnumFinancialTransactionTypeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  stripePaymentIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeRefundId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeDisputeId?: Maybe<StringNullableWithAggregatesFilter>;
  adjustmentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePayoutId?: Maybe<StringNullableWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum FinancialTransactionType {
  Sale = 'Sale',
  Subscription = 'Subscription',
  Refund = 'Refund',
  Dispute = 'Dispute',
  Adjustment = 'Adjustment'
}

export type FinancialTransactionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
};

export type FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
};

export type FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
};

export type FinancialTransactionUncheckedCreateNestedOneWithoutAdjustmentInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutAdjustmentInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutAdjustmentInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionUncheckedCreateNestedOneWithoutStripeDisputeInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeDisputeInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeDisputeInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeRefundInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeRefundInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionUncheckedCreateWithoutAdjustmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripeRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
};

export type FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
};

export type FinancialTransactionUncheckedUpdateManyWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateManyWithoutStripePayoutNestedInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
};

export type FinancialTransactionUncheckedUpdateOneWithoutAdjustmentNestedInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutAdjustmentInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutAdjustmentInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutAdjustmentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutAdjustmentInput>;
};

export type FinancialTransactionUncheckedUpdateOneWithoutStripeDisputeNestedInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeDisputeInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeDisputeInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeDisputeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeDisputeInput>;
};

export type FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeInvoiceInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeInvoiceInput>;
};

export type FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripePaymentIntentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripePaymentIntentInput>;
};

export type FinancialTransactionUncheckedUpdateOneWithoutStripeRefundNestedInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeRefundInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeRefundInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeRefundInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeRefundInput>;
};

export type FinancialTransactionUncheckedUpdateWithoutAdjustmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripeRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
};

export type FinancialTransactionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
};

export type FinancialTransactionUpdateManyWithWhereWithoutChannelInput = {
  where: FinancialTransactionScalarWhereInput;
  data: FinancialTransactionUncheckedUpdateManyWithoutFinancialTransactionsInput;
};

export type FinancialTransactionUpdateManyWithWhereWithoutClinicInput = {
  where: FinancialTransactionScalarWhereInput;
  data: FinancialTransactionUncheckedUpdateManyWithoutFinancialTransactionsInput;
};

export type FinancialTransactionUpdateManyWithWhereWithoutStripePayoutInput = {
  where: FinancialTransactionScalarWhereInput;
  data: FinancialTransactionUncheckedUpdateManyWithoutFinancialTransactionsInput;
};

export type FinancialTransactionUpdateManyWithoutChannelNestedInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
};

export type FinancialTransactionUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
};

export type FinancialTransactionUpdateManyWithoutStripePayoutNestedInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
};

export type FinancialTransactionUpdateOneWithoutAdjustmentNestedInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutAdjustmentInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutAdjustmentInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutAdjustmentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutAdjustmentInput>;
};

export type FinancialTransactionUpdateOneWithoutStripeDisputeNestedInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeDisputeInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeDisputeInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeDisputeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeDisputeInput>;
};

export type FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeInvoiceInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeInvoiceInput>;
};

export type FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripePaymentIntentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripePaymentIntentInput>;
};

export type FinancialTransactionUpdateOneWithoutStripeRefundNestedInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeRefundInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeRefundInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeRefundInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeRefundInput>;
};

export type FinancialTransactionUpdateWithWhereUniqueWithoutChannelInput = {
  where: FinancialTransactionWhereUniqueInput;
  data: FinancialTransactionUncheckedUpdateWithoutChannelInput;
};

export type FinancialTransactionUpdateWithWhereUniqueWithoutClinicInput = {
  where: FinancialTransactionWhereUniqueInput;
  data: FinancialTransactionUncheckedUpdateWithoutClinicInput;
};

export type FinancialTransactionUpdateWithWhereUniqueWithoutStripePayoutInput = {
  where: FinancialTransactionWhereUniqueInput;
  data: FinancialTransactionUncheckedUpdateWithoutStripePayoutInput;
};

export type FinancialTransactionUpdateWithoutAdjustmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
};

export type FinancialTransactionUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
};

export type FinancialTransactionUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
};

export type FinancialTransactionUpdateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
};

export type FinancialTransactionUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
};

export type FinancialTransactionUpdateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
};

export type FinancialTransactionUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
};

export type FinancialTransactionUpdateWithoutStripeRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
};

export type FinancialTransactionUpsertWithWhereUniqueWithoutChannelInput = {
  where: FinancialTransactionWhereUniqueInput;
  update: FinancialTransactionUncheckedUpdateWithoutChannelInput;
  create: FinancialTransactionUncheckedCreateWithoutChannelInput;
};

export type FinancialTransactionUpsertWithWhereUniqueWithoutClinicInput = {
  where: FinancialTransactionWhereUniqueInput;
  update: FinancialTransactionUncheckedUpdateWithoutClinicInput;
  create: FinancialTransactionUncheckedCreateWithoutClinicInput;
};

export type FinancialTransactionUpsertWithWhereUniqueWithoutStripePayoutInput = {
  where: FinancialTransactionWhereUniqueInput;
  update: FinancialTransactionUncheckedUpdateWithoutStripePayoutInput;
  create: FinancialTransactionUncheckedCreateWithoutStripePayoutInput;
};

export type FinancialTransactionUpsertWithoutAdjustmentInput = {
  update: FinancialTransactionUncheckedUpdateWithoutAdjustmentInput;
  create: FinancialTransactionUncheckedCreateWithoutAdjustmentInput;
};

export type FinancialTransactionUpsertWithoutStripeDisputeInput = {
  update: FinancialTransactionUncheckedUpdateWithoutStripeDisputeInput;
  create: FinancialTransactionUncheckedCreateWithoutStripeDisputeInput;
};

export type FinancialTransactionUpsertWithoutStripeInvoiceInput = {
  update: FinancialTransactionUncheckedUpdateWithoutStripeInvoiceInput;
  create: FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput;
};

export type FinancialTransactionUpsertWithoutStripePaymentIntentInput = {
  update: FinancialTransactionUncheckedUpdateWithoutStripePaymentIntentInput;
  create: FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput;
};

export type FinancialTransactionUpsertWithoutStripeRefundInput = {
  update: FinancialTransactionUncheckedUpdateWithoutStripeRefundInput;
  create: FinancialTransactionUncheckedCreateWithoutStripeRefundInput;
};

export type FinancialTransactionWhereInput = {
  AND?: Maybe<Array<FinancialTransactionWhereInput>>;
  OR?: Maybe<Array<FinancialTransactionWhereInput>>;
  NOT?: Maybe<Array<FinancialTransactionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  timestamp?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumFinancialTransactionTypeFilter>;
  clinicId?: Maybe<StringFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
  stripeRefundId?: Maybe<StringNullableFilter>;
  stripeDisputeId?: Maybe<StringNullableFilter>;
  adjustmentId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentWhereInput>;
  stripeInvoice?: Maybe<StripeInvoiceWhereInput>;
  stripeRefund?: Maybe<StripeRefundWhereInput>;
  stripeDispute?: Maybe<StripeDisputeWhereInput>;
  adjustment?: Maybe<FinancialAdjustmentWhereInput>;
  stripePayout?: Maybe<StripePayoutWhereInput>;
  channel?: Maybe<ChannelWhereInput>;
};

export type FinancialTransactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  adjustmentId?: Maybe<Scalars['String']>;
};

export type FindClinicPetParentByPhoneOrEmailRequest = {
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type FindCurrentEmailTemplateVersionInput = {
  type: Scalars['String'];
};

export type FindEmployerIdentificationNumberInput = {
  companyName: Scalars['String'];
};

export type FindEmployerIdentificationNumberResponse = {
  __typename?: 'FindEmployerIdentificationNumberResponse';
  ein?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  companyPhone?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type FindMissingBatchingPeriodDatesOutput = {
  __typename?: 'FindMissingBatchingPeriodDatesOutput';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  dates?: Maybe<Array<Scalars['String']>>;
};

/** @deprecated - 1.0 */
export type FirebaseStructure = {
  __typename?: 'FirebaseStructure';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  table: Scalars['String'];
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureCountAggregateOutputType = {
  __typename?: 'FirebaseStructureCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  table: Scalars['Int'];
  structure: Scalars['Int'];
  _all: Scalars['Int'];
};

export type FirebaseStructureCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
};

export type FirebaseStructureCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table: Scalars['String'];
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table: Scalars['String'];
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureMaxAggregateOutputType = {
  __typename?: 'FirebaseStructureMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
};

export type FirebaseStructureMinAggregateOutputType = {
  __typename?: 'FirebaseStructureMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
};

export enum FirebaseStructureOrderByRelevanceFieldEnum {
  Id = 'id',
  Table = 'table',
  Structure = 'structure'
}

export type FirebaseStructureOrderByRelevanceInput = {
  fields: Array<FirebaseStructureOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type FirebaseStructureOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
  _count?: Maybe<FirebaseStructureCountOrderByAggregateInput>;
  _max?: Maybe<FirebaseStructureMaxOrderByAggregateInput>;
  _min?: Maybe<FirebaseStructureMinOrderByAggregateInput>;
};

export type FirebaseStructureOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
  _relevance?: Maybe<FirebaseStructureOrderByRelevanceInput>;
};

export enum FirebaseStructureScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Table = 'table',
  Structure = 'structure'
}

export type FirebaseStructureScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FirebaseStructureScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FirebaseStructureScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FirebaseStructureScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  table?: Maybe<StringWithAggregatesFilter>;
  structure?: Maybe<StringNullableWithAggregatesFilter>;
};

export type FirebaseStructureUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table: Scalars['String'];
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureWhereInput = {
  AND?: Maybe<Array<FirebaseStructureWhereInput>>;
  OR?: Maybe<Array<FirebaseStructureWhereInput>>;
  NOT?: Maybe<Array<FirebaseStructureWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  table?: Maybe<StringFilter>;
  structure?: Maybe<StringNullableFilter>;
};

export type FirebaseStructureWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
};

export type FloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type FloatNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedFloatNullableFilter>;
  _min?: Maybe<NestedFloatNullableFilter>;
  _max?: Maybe<NestedFloatNullableFilter>;
};

export type FloatWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedFloatFilter>;
  _min?: Maybe<NestedFloatFilter>;
  _max?: Maybe<NestedFloatFilter>;
};

export enum FormExportErrorType {
  GeneratingForm = 'GeneratingForm',
  InvalidPet = 'InvalidPet',
  InvalidParent = 'InvalidParent',
  PimsConfiguration = 'PimsConfiguration',
  Unauthorized = 'Unauthorized',
  Throttled = 'Throttled',
  PimsServer = 'PimsServer',
  Connection = 'Connection',
  Unknown = 'Unknown'
}

export type FormSubmission = {
  __typename?: 'FormSubmission';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<Appointment>;
  clinic?: Maybe<Clinic>;
  clinicPet?: Maybe<ClinicPet>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  formTemplate?: Maybe<FormTemplate>;
  users: Array<User>;
  serviceReminderNotifications: Array<ServiceReminderNotification>;
  clinicWidgetRequests: Array<ClinicWidgetRequest>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channelCreationSources: Array<ChannelCreationSource>;
  automationRuns: Array<AutomationRun>;
  automationRunAction?: Maybe<AutomationRunAction>;
  _count: FormSubmissionCountOutputType;
};


export type FormSubmissionUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};


export type FormSubmissionServiceReminderNotificationsArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderNotificationScalarFieldEnum>;
};


export type FormSubmissionClinicWidgetRequestsArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestScalarFieldEnum>;
};


export type FormSubmissionChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};


export type FormSubmissionChannelCreationSourcesArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
};


export type FormSubmissionAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};

export type FormSubmissionCountAggregateOutputType = {
  __typename?: 'FormSubmissionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  completedContent: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  clinicId: Scalars['Int'];
  appointmentId: Scalars['Int'];
  formTemplateId: Scalars['Int'];
  draftContent: Scalars['Int'];
  submittedAt: Scalars['Int'];
  isCallDeflectionForm: Scalars['Int'];
  _all: Scalars['Int'];
};

export type FormSubmissionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  completedContent?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  isCallDeflectionForm?: Maybe<SortOrder>;
};

export type FormSubmissionCountOutputType = {
  __typename?: 'FormSubmissionCountOutputType';
  users: Scalars['Int'];
  serviceReminderNotifications: Scalars['Int'];
  clinicWidgetRequests: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  automationRuns: Scalars['Int'];
};

export type FormSubmissionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateManyAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyAppointmentInputEnvelope = {
  data: Array<FormSubmissionCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicInputEnvelope = {
  data: Array<FormSubmissionCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicPetInputEnvelope = {
  data: Array<FormSubmissionCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicPetParentInputEnvelope = {
  data: Array<FormSubmissionCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyFormTemplateInputEnvelope = {
  data: Array<FormSubmissionCreateManyFormTemplateInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionCreateNestedManyWithoutFormTemplateInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutFormTemplateInput>>;
  createMany?: Maybe<FormSubmissionCreateManyFormTemplateInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionCreateNestedManyWithoutUsersInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutUsersInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionCreateNestedOneWithoutAutomationRunActionInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutAutomationRunActionInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutAutomationRunActionInput>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
};

export type FormSubmissionCreateNestedOneWithoutAutomationRunsInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutAutomationRunsInput>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
};

export type FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
};

export type FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutChannelCreationSourcesInput>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
};

export type FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutClinicWidgetRequestsInput>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
};

export type FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutServiceReminderNotificationsInput>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
};

export type FormSubmissionCreateOrConnectWithoutAppointmentInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutAppointmentInput;
};

export type FormSubmissionCreateOrConnectWithoutAutomationRunActionInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutAutomationRunActionInput;
};

export type FormSubmissionCreateOrConnectWithoutAutomationRunsInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutAutomationRunsInput;
};

export type FormSubmissionCreateOrConnectWithoutChannelAutomationStatusesInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type FormSubmissionCreateOrConnectWithoutChannelCreationSourcesInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type FormSubmissionCreateOrConnectWithoutClinicInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutClinicInput;
};

export type FormSubmissionCreateOrConnectWithoutClinicPetInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutClinicPetInput;
};

export type FormSubmissionCreateOrConnectWithoutClinicPetParentInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutClinicPetParentInput;
};

export type FormSubmissionCreateOrConnectWithoutClinicWidgetRequestsInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type FormSubmissionCreateOrConnectWithoutFormTemplateInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutFormTemplateInput;
};

export type FormSubmissionCreateOrConnectWithoutServiceReminderNotificationsInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput;
};

export type FormSubmissionCreateOrConnectWithoutUsersInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutUsersInput;
};

export type FormSubmissionCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutServiceReminderNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionExportInput = {
  id: Scalars['String'];
  petIds: Array<Maybe<Scalars['String']>>;
};

export type FormSubmissionExportResponse = {
  __typename?: 'FormSubmissionExportResponse';
  results: Array<Maybe<FormSubmissionExportResult>>;
};

export type FormSubmissionExportResult = {
  __typename?: 'FormSubmissionExportResult';
  success: Scalars['Boolean'];
  petId: Scalars['String'];
  formId: Scalars['String'];
  errorType?: Maybe<FormExportErrorType>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type FormSubmissionListRelationFilter = {
  every?: Maybe<FormSubmissionWhereInput>;
  some?: Maybe<FormSubmissionWhereInput>;
  none?: Maybe<FormSubmissionWhereInput>;
};

export type FormSubmissionMaxAggregateOutputType = {
  __typename?: 'FormSubmissionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  isCallDeflectionForm?: Maybe<SortOrder>;
};

export type FormSubmissionMinAggregateOutputType = {
  __typename?: 'FormSubmissionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  isCallDeflectionForm?: Maybe<SortOrder>;
};

export type FormSubmissionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum FormSubmissionOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicPetId = 'clinicPetId',
  ClinicPetParentId = 'clinicPetParentId',
  ClinicId = 'clinicId',
  AppointmentId = 'appointmentId',
  FormTemplateId = 'formTemplateId'
}

export type FormSubmissionOrderByRelevanceInput = {
  fields: Array<FormSubmissionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type FormSubmissionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  completedContent?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  isCallDeflectionForm?: Maybe<SortOrder>;
  _count?: Maybe<FormSubmissionCountOrderByAggregateInput>;
  _max?: Maybe<FormSubmissionMaxOrderByAggregateInput>;
  _min?: Maybe<FormSubmissionMinOrderByAggregateInput>;
};

export type FormSubmissionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  completedContent?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  isCallDeflectionForm?: Maybe<SortOrder>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  formTemplate?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  users?: Maybe<UserOrderByRelationAggregateInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationOrderByRelationAggregateInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  automationRunAction?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<FormSubmissionOrderByRelevanceInput>;
};

export type FormSubmissionRelationFilter = {
  is?: Maybe<FormSubmissionWhereInput>;
  isNot?: Maybe<FormSubmissionWhereInput>;
};

export enum FormSubmissionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CompletedContent = 'completedContent',
  ClinicPetId = 'clinicPetId',
  ClinicPetParentId = 'clinicPetParentId',
  ClinicId = 'clinicId',
  AppointmentId = 'appointmentId',
  FormTemplateId = 'formTemplateId',
  DraftContent = 'draftContent',
  SubmittedAt = 'submittedAt',
  IsCallDeflectionForm = 'isCallDeflectionForm'
}

export type FormSubmissionScalarWhereInput = {
  AND?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  OR?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  NOT?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  completedContent?: Maybe<JsonNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
  draftContent?: Maybe<JsonNullableFilter>;
  submittedAt?: Maybe<DateTimeNullableFilter>;
  isCallDeflectionForm?: Maybe<BoolNullableFilter>;
};

export type FormSubmissionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FormSubmissionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FormSubmissionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FormSubmissionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  completedContent?: Maybe<JsonNullableWithAggregatesFilter>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  appointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  formTemplateId?: Maybe<StringNullableWithAggregatesFilter>;
  draftContent?: Maybe<JsonNullableWithAggregatesFilter>;
  submittedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isCallDeflectionForm?: Maybe<BoolNullableWithAggregatesFilter>;
};

export type FormSubmissionSubscription = {
  __typename?: 'FormSubmissionSubscription';
  node: FormSubmission;
  mutation: MutationType;
};

export type FormSubmissionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutFormTemplateInput>>;
  createMany?: Maybe<FormSubmissionCreateManyFormTemplateInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutUsersInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutUsersInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionUncheckedCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionUncheckedUpdateManyWithoutFormTemplateNestedInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutFormTemplateInput>>;
  createMany?: Maybe<FormSubmissionCreateManyFormTemplateInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutFormTemplateInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutUsersInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutUsersInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutUsersInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUncheckedUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutServiceReminderNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionUpdateManyWithWhereWithoutAppointmentInput = {
  where: FormSubmissionScalarWhereInput;
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutClinicInput = {
  where: FormSubmissionScalarWhereInput;
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutClinicPetInput = {
  where: FormSubmissionScalarWhereInput;
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: FormSubmissionScalarWhereInput;
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutFormTemplateInput = {
  where: FormSubmissionScalarWhereInput;
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutUsersInput = {
  where: FormSubmissionScalarWhereInput;
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
};

export type FormSubmissionUpdateManyWithoutAppointmentNestedInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUpdateManyWithoutFormTemplateNestedInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutFormTemplateInput>>;
  createMany?: Maybe<FormSubmissionCreateManyFormTemplateInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutFormTemplateInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUpdateManyWithoutUsersNestedInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutUsersInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutUsersInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutUsersInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutAutomationRunActionInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutAutomationRunActionInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutAutomationRunActionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutAutomationRunActionInput>;
};

export type FormSubmissionUpdateOneWithoutAutomationRunsNestedInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutAutomationRunsInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutAutomationRunsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutAutomationRunsInput>;
};

export type FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutChannelAutomationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutChannelAutomationStatusesInput>;
};

export type FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutChannelCreationSourcesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutChannelCreationSourcesInput>;
};

export type FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutClinicWidgetRequestsInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutClinicWidgetRequestsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutClinicWidgetRequestsInput>;
};

export type FormSubmissionUpdateOneWithoutServiceReminderNotificationsNestedInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutServiceReminderNotificationsInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutServiceReminderNotificationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutServiceReminderNotificationsInput>;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutAppointmentInput = {
  where: FormSubmissionWhereUniqueInput;
  data: FormSubmissionUncheckedUpdateWithoutAppointmentInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutClinicInput = {
  where: FormSubmissionWhereUniqueInput;
  data: FormSubmissionUncheckedUpdateWithoutClinicInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: FormSubmissionWhereUniqueInput;
  data: FormSubmissionUncheckedUpdateWithoutClinicPetInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: FormSubmissionWhereUniqueInput;
  data: FormSubmissionUncheckedUpdateWithoutClinicPetParentInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutFormTemplateInput = {
  where: FormSubmissionWhereUniqueInput;
  data: FormSubmissionUncheckedUpdateWithoutFormTemplateInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutUsersInput = {
  where: FormSubmissionWhereUniqueInput;
  data: FormSubmissionUncheckedUpdateWithoutUsersInput;
};

export type FormSubmissionUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUpdateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUpdateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUpdateWithoutServiceReminderNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUpdateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutAppointmentInput = {
  where: FormSubmissionWhereUniqueInput;
  update: FormSubmissionUncheckedUpdateWithoutAppointmentInput;
  create: FormSubmissionUncheckedCreateWithoutAppointmentInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutClinicInput = {
  where: FormSubmissionWhereUniqueInput;
  update: FormSubmissionUncheckedUpdateWithoutClinicInput;
  create: FormSubmissionUncheckedCreateWithoutClinicInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: FormSubmissionWhereUniqueInput;
  update: FormSubmissionUncheckedUpdateWithoutClinicPetInput;
  create: FormSubmissionUncheckedCreateWithoutClinicPetInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: FormSubmissionWhereUniqueInput;
  update: FormSubmissionUncheckedUpdateWithoutClinicPetParentInput;
  create: FormSubmissionUncheckedCreateWithoutClinicPetParentInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutFormTemplateInput = {
  where: FormSubmissionWhereUniqueInput;
  update: FormSubmissionUncheckedUpdateWithoutFormTemplateInput;
  create: FormSubmissionUncheckedCreateWithoutFormTemplateInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutUsersInput = {
  where: FormSubmissionWhereUniqueInput;
  update: FormSubmissionUncheckedUpdateWithoutUsersInput;
  create: FormSubmissionUncheckedCreateWithoutUsersInput;
};

export type FormSubmissionUpsertWithoutAutomationRunActionInput = {
  update: FormSubmissionUncheckedUpdateWithoutAutomationRunActionInput;
  create: FormSubmissionUncheckedCreateWithoutAutomationRunActionInput;
};

export type FormSubmissionUpsertWithoutAutomationRunsInput = {
  update: FormSubmissionUncheckedUpdateWithoutAutomationRunsInput;
  create: FormSubmissionUncheckedCreateWithoutAutomationRunsInput;
};

export type FormSubmissionUpsertWithoutChannelAutomationStatusesInput = {
  update: FormSubmissionUncheckedUpdateWithoutChannelAutomationStatusesInput;
  create: FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type FormSubmissionUpsertWithoutChannelCreationSourcesInput = {
  update: FormSubmissionUncheckedUpdateWithoutChannelCreationSourcesInput;
  create: FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type FormSubmissionUpsertWithoutClinicWidgetRequestsInput = {
  update: FormSubmissionUncheckedUpdateWithoutClinicWidgetRequestsInput;
  create: FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type FormSubmissionUpsertWithoutServiceReminderNotificationsInput = {
  update: FormSubmissionUncheckedUpdateWithoutServiceReminderNotificationsInput;
  create: FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput;
};

export type FormSubmissionWhereInput = {
  AND?: Maybe<Array<FormSubmissionWhereInput>>;
  OR?: Maybe<Array<FormSubmissionWhereInput>>;
  NOT?: Maybe<Array<FormSubmissionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  completedContent?: Maybe<JsonNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
  draftContent?: Maybe<JsonNullableFilter>;
  submittedAt?: Maybe<DateTimeNullableFilter>;
  isCallDeflectionForm?: Maybe<BoolNullableFilter>;
  appointment?: Maybe<AppointmentWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  formTemplate?: Maybe<FormTemplateWhereInput>;
  users?: Maybe<UserListRelationFilter>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationListRelationFilter>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  automationRunAction?: Maybe<AutomationRunActionWhereInput>;
};

export type FormSubmissionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FormTemplate = {
  __typename?: 'FormTemplate';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  autoExportEnabled: Scalars['Boolean'];
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType: FormTemplateType;
  clinic?: Maybe<Clinic>;
  formSubmissions: Array<FormSubmission>;
  clinicWidgetRequestTypes: Array<ClinicWidgetRequestType>;
  services: Array<Service>;
  triggeredWorkflows: Array<WorkflowEventSetting>;
  _count: FormTemplateCountOutputType;
};


export type FormTemplateFormSubmissionsArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
};


export type FormTemplateClinicWidgetRequestTypesArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
};


export type FormTemplateServicesArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceScalarFieldEnum>;
};


export type FormTemplateTriggeredWorkflowsArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
};

export type FormTemplateCountAggregateOutputType = {
  __typename?: 'FormTemplateCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  title: Scalars['Int'];
  autoExportEnabled: Scalars['Int'];
  content: Scalars['Int'];
  draftContent: Scalars['Int'];
  isDeleted: Scalars['Int'];
  clinicId: Scalars['Int'];
  isActive: Scalars['Int'];
  formTemplateType: Scalars['Int'];
  _all: Scalars['Int'];
};

export type FormTemplateCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  autoExportEnabled?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  formTemplateType?: Maybe<SortOrder>;
};

export type FormTemplateCountOutputType = {
  __typename?: 'FormTemplateCountOutputType';
  formSubmissions: Scalars['Int'];
  clinicWidgetRequestTypes: Scalars['Int'];
  services: Scalars['Int'];
  triggeredWorkflows: Scalars['Int'];
};

export type FormTemplateCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateCreateManyClinicInputEnvelope = {
  data: Array<FormTemplateCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormTemplateCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<FormTemplateCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
};

export type FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutTriggeredWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput>>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
};

export type FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput = {
  create?: Maybe<FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutClinicWidgetRequestTypesInput>;
  connect?: Maybe<FormTemplateWhereUniqueInput>;
};

export type FormTemplateCreateNestedOneWithoutFormSubmissionsInput = {
  create?: Maybe<FormTemplateUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutFormSubmissionsInput>;
  connect?: Maybe<FormTemplateWhereUniqueInput>;
};

export type FormTemplateCreateNestedOneWithoutServicesInput = {
  create?: Maybe<FormTemplateUncheckedCreateWithoutServicesInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutServicesInput>;
  connect?: Maybe<FormTemplateWhereUniqueInput>;
};

export type FormTemplateCreateOrConnectWithoutClinicInput = {
  where: FormTemplateWhereUniqueInput;
  create: FormTemplateUncheckedCreateWithoutClinicInput;
};

export type FormTemplateCreateOrConnectWithoutClinicWidgetRequestTypesInput = {
  where: FormTemplateWhereUniqueInput;
  create: FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput;
};

export type FormTemplateCreateOrConnectWithoutFormSubmissionsInput = {
  where: FormTemplateWhereUniqueInput;
  create: FormTemplateUncheckedCreateWithoutFormSubmissionsInput;
};

export type FormTemplateCreateOrConnectWithoutServicesInput = {
  where: FormTemplateWhereUniqueInput;
  create: FormTemplateUncheckedCreateWithoutServicesInput;
};

export type FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput = {
  where: FormTemplateWhereUniqueInput;
  create: FormTemplateUncheckedCreateWithoutTriggeredWorkflowsInput;
};

export type FormTemplateCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateCreateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateCreateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateCreateWithoutTriggeredWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
};

export type FormTemplateListRelationFilter = {
  every?: Maybe<FormTemplateWhereInput>;
  some?: Maybe<FormTemplateWhereInput>;
  none?: Maybe<FormTemplateWhereInput>;
};

export type FormTemplateMaxAggregateOutputType = {
  __typename?: 'FormTemplateMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  autoExportEnabled?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  formTemplateType?: Maybe<SortOrder>;
};

export type FormTemplateMinAggregateOutputType = {
  __typename?: 'FormTemplateMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  autoExportEnabled?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  formTemplateType?: Maybe<SortOrder>;
};

export type FormTemplateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum FormTemplateOrderByRelevanceFieldEnum {
  Id = 'id',
  Title = 'title',
  ClinicId = 'clinicId'
}

export type FormTemplateOrderByRelevanceInput = {
  fields: Array<FormTemplateOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type FormTemplateOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  autoExportEnabled?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  formTemplateType?: Maybe<SortOrder>;
  _count?: Maybe<FormTemplateCountOrderByAggregateInput>;
  _max?: Maybe<FormTemplateMaxOrderByAggregateInput>;
  _min?: Maybe<FormTemplateMinOrderByAggregateInput>;
};

export type FormTemplateOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  autoExportEnabled?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  formTemplateType?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  services?: Maybe<ServiceOrderByRelationAggregateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
  _relevance?: Maybe<FormTemplateOrderByRelevanceInput>;
};

export type FormTemplateRelationFilter = {
  is?: Maybe<FormTemplateWhereInput>;
  isNot?: Maybe<FormTemplateWhereInput>;
};

export enum FormTemplateScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Title = 'title',
  AutoExportEnabled = 'autoExportEnabled',
  Content = 'content',
  DraftContent = 'draftContent',
  IsDeleted = 'isDeleted',
  ClinicId = 'clinicId',
  IsActive = 'isActive',
  FormTemplateType = 'formTemplateType'
}

export type FormTemplateScalarWhereInput = {
  AND?: Maybe<Array<FormTemplateScalarWhereInput>>;
  OR?: Maybe<Array<FormTemplateScalarWhereInput>>;
  NOT?: Maybe<Array<FormTemplateScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  autoExportEnabled?: Maybe<BoolFilter>;
  content?: Maybe<JsonNullableFilter>;
  draftContent?: Maybe<JsonNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  formTemplateType?: Maybe<EnumFormTemplateTypeFilter>;
};

export type FormTemplateScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FormTemplateScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FormTemplateScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FormTemplateScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  autoExportEnabled?: Maybe<BoolWithAggregatesFilter>;
  content?: Maybe<JsonNullableWithAggregatesFilter>;
  draftContent?: Maybe<JsonNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  formTemplateType?: Maybe<EnumFormTemplateTypeWithAggregatesFilter>;
};

export enum FormTemplateType {
  General = 'General',
  ServiceReminder = 'ServiceReminder',
  WidgetRequest = 'WidgetRequest',
  FillInTheBlank = 'FillInTheBlank'
}

export type FormTemplateUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<FormTemplateCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
};

export type FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutTriggeredWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput>>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
};

export type FormTemplateUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedCreateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedCreateWithoutTriggeredWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
};

export type FormTemplateUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
};

export type FormTemplateUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<FormTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<FormTemplateCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  update?: Maybe<Array<FormTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FormTemplateUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<FormTemplateScalarWhereInput>>;
};

export type FormTemplateUncheckedUpdateManyWithoutFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutTriggeredWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput>>;
  upsert?: Maybe<Array<FormTemplateUpsertWithWhereUniqueWithoutTriggeredWorkflowsInput>>;
  set?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  update?: Maybe<Array<FormTemplateUpdateWithWhereUniqueWithoutTriggeredWorkflowsInput>>;
  updateMany?: Maybe<Array<FormTemplateUpdateManyWithWhereWithoutTriggeredWorkflowsInput>>;
  deleteMany?: Maybe<Array<FormTemplateScalarWhereInput>>;
};

export type FormTemplateUncheckedUpdateManyWithoutTriggeringFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
};

export type FormTemplateUncheckedUpdateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
};

export type FormTemplateUncheckedUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
};

export type FormTemplateUncheckedUpdateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
};

export type FormTemplateUncheckedUpdateWithoutTriggeredWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateNestedInput>;
};

export type FormTemplateUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateNestedInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
};

export type FormTemplateUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateUpdateManyWithWhereWithoutClinicInput = {
  where: FormTemplateScalarWhereInput;
  data: FormTemplateUncheckedUpdateManyWithoutFormTemplatesInput;
};

export type FormTemplateUpdateManyWithWhereWithoutTriggeredWorkflowsInput = {
  where: FormTemplateScalarWhereInput;
  data: FormTemplateUncheckedUpdateManyWithoutTriggeringFormTemplatesInput;
};

export type FormTemplateUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<FormTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<FormTemplateCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  update?: Maybe<Array<FormTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FormTemplateUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<FormTemplateScalarWhereInput>>;
};

export type FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutTriggeredWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput>>;
  upsert?: Maybe<Array<FormTemplateUpsertWithWhereUniqueWithoutTriggeredWorkflowsInput>>;
  set?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  update?: Maybe<Array<FormTemplateUpdateWithWhereUniqueWithoutTriggeredWorkflowsInput>>;
  updateMany?: Maybe<Array<FormTemplateUpdateManyWithWhereWithoutTriggeredWorkflowsInput>>;
  deleteMany?: Maybe<Array<FormTemplateScalarWhereInput>>;
};

export type FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput = {
  create?: Maybe<FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutClinicWidgetRequestTypesInput>;
  upsert?: Maybe<FormTemplateUpsertWithoutClinicWidgetRequestTypesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormTemplateWhereUniqueInput>;
  update?: Maybe<FormTemplateUncheckedUpdateWithoutClinicWidgetRequestTypesInput>;
};

export type FormTemplateUpdateOneWithoutFormSubmissionsNestedInput = {
  create?: Maybe<FormTemplateUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutFormSubmissionsInput>;
  upsert?: Maybe<FormTemplateUpsertWithoutFormSubmissionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormTemplateWhereUniqueInput>;
  update?: Maybe<FormTemplateUncheckedUpdateWithoutFormSubmissionsInput>;
};

export type FormTemplateUpdateOneWithoutServicesNestedInput = {
  create?: Maybe<FormTemplateUncheckedCreateWithoutServicesInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutServicesInput>;
  upsert?: Maybe<FormTemplateUpsertWithoutServicesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormTemplateWhereUniqueInput>;
  update?: Maybe<FormTemplateUncheckedUpdateWithoutServicesInput>;
};

export type FormTemplateUpdateWithWhereUniqueWithoutClinicInput = {
  where: FormTemplateWhereUniqueInput;
  data: FormTemplateUncheckedUpdateWithoutClinicInput;
};

export type FormTemplateUpdateWithWhereUniqueWithoutTriggeredWorkflowsInput = {
  where: FormTemplateWhereUniqueInput;
  data: FormTemplateUncheckedUpdateWithoutTriggeredWorkflowsInput;
};

export type FormTemplateUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateNestedInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
};

export type FormTemplateUpdateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateNestedInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
};

export type FormTemplateUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateNestedInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
};

export type FormTemplateUpdateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateNestedInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
};

export type FormTemplateUpdateWithoutTriggeredWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateNestedInput>;
};

export type FormTemplateUpsertWithWhereUniqueWithoutClinicInput = {
  where: FormTemplateWhereUniqueInput;
  update: FormTemplateUncheckedUpdateWithoutClinicInput;
  create: FormTemplateUncheckedCreateWithoutClinicInput;
};

export type FormTemplateUpsertWithWhereUniqueWithoutTriggeredWorkflowsInput = {
  where: FormTemplateWhereUniqueInput;
  update: FormTemplateUncheckedUpdateWithoutTriggeredWorkflowsInput;
  create: FormTemplateUncheckedCreateWithoutTriggeredWorkflowsInput;
};

export type FormTemplateUpsertWithoutClinicWidgetRequestTypesInput = {
  update: FormTemplateUncheckedUpdateWithoutClinicWidgetRequestTypesInput;
  create: FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput;
};

export type FormTemplateUpsertWithoutFormSubmissionsInput = {
  update: FormTemplateUncheckedUpdateWithoutFormSubmissionsInput;
  create: FormTemplateUncheckedCreateWithoutFormSubmissionsInput;
};

export type FormTemplateUpsertWithoutServicesInput = {
  update: FormTemplateUncheckedUpdateWithoutServicesInput;
  create: FormTemplateUncheckedCreateWithoutServicesInput;
};

export type FormTemplateWhereInput = {
  AND?: Maybe<Array<FormTemplateWhereInput>>;
  OR?: Maybe<Array<FormTemplateWhereInput>>;
  NOT?: Maybe<Array<FormTemplateWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  autoExportEnabled?: Maybe<BoolFilter>;
  content?: Maybe<JsonNullableFilter>;
  draftContent?: Maybe<JsonNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  formTemplateType?: Maybe<EnumFormTemplateTypeFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
  services?: Maybe<ServiceListRelationFilter>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingListRelationFilter>;
};

export type FormTemplateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type GenerateCustomTokenResponse = {
  __typename?: 'GenerateCustomTokenResponse';
  token?: Maybe<Scalars['String']>;
};

export type GeneratePetIdPdfInput = {
  petId: Scalars['String'];
  petParentId: Scalars['String'];
};

export type GeneratePetIdPdfResponse = {
  __typename?: 'GeneratePetIdPdfResponse';
  success: Scalars['Boolean'];
  base64PDF?: Maybe<Scalars['String']>;
};

export type GenerateTerminalAuthTokenResponse = {
  __typename?: 'GenerateTerminalAuthTokenResponse';
  terminal: StripeTerminal;
  token: Scalars['String'];
};

export type GenerateTerminalSetupPinResponse = {
  __typename?: 'GenerateTerminalSetupPinResponse';
  pin: Scalars['String'];
};

export type GenerateTinyUrlAndTokenInput = {
  clinicPetParentId: Scalars['String'];
};

export type GenerateWidgetAuthenticationRequestResponse = {
  __typename?: 'GenerateWidgetAuthenticationRequestResponse';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type GenericResponse = {
  __typename?: 'GenericResponse';
  success: Scalars['Boolean'];
  payload?: Maybe<Scalars['Json']>;
};

export type GetBalanceForClientInput = {
  clinicPetParentId: Scalars['String'];
};

export type GetChannelStatusIdsWithEntityCountOfZeroWhere = {
  clinicId: Scalars['String'];
  channelStatusIds: Array<Scalars['String']>;
  entity: ChannelStatusEntity;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type GetDirectedAuthLinkResult = {
  __typename?: 'GetDirectedAuthLinkResult';
  link: Scalars['String'];
};

export type GetLatestActiveSubscriptionInvoiceOutput = {
  __typename?: 'GetLatestActiveSubscriptionInvoiceOutput';
  subscriptionStatus: Scalars['String'];
  paymentAmountNeeded: Scalars['Int'];
  paymentAmountReceived: Scalars['Int'];
  paymentIntentSecret: Scalars['String'];
};

export type GetOrCreateActiveChannelInput = {
  clinicPetParentIds: Array<Scalars['String']>;
};

export type GetOrCreateActiveClientChannelInput = {
  clinicPetParentIds: Array<Scalars['String']>;
};

export type GetPetIdDataInput = {
  petId: Scalars['String'];
  petParentId: Scalars['String'];
};

export type GetPetIdDataResponse = {
  __typename?: 'GetPetIdDataResponse';
  history: Array<PetIdHistoryItem>;
  pet: ClinicPet;
  petParent: ClinicPetParent;
  clinic: Clinic;
};

export type GetSignedUploadInput = {
  key: Scalars['String'];
  contentType: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type GetSignedUploadUrlOutput = {
  __typename?: 'GetSignedUploadUrlOutput';
  key: Scalars['String'];
  uploadUrl: Scalars['String'];
  finalUrl: Scalars['String'];
};

export type GetTwilioNumbersInput = {
  clinicId: Scalars['ID'];
};

export type GlobalPetParent = {
  __typename?: 'GlobalPetParent';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Used for matching */
  email: Scalars['String'];
  /** Used as a secondary characteristic for matching */
  primaryPhoneNumber: Scalars['String'];
  /** all related clinic pet parents */
  clinicPetParents: Array<ClinicPetParent>;
  _count: GlobalPetParentCountOutputType;
};


export type GlobalPetParentClinicPetParentsArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};

export type GlobalPetParentCountAggregateOutputType = {
  __typename?: 'GlobalPetParentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  email: Scalars['Int'];
  primaryPhoneNumber: Scalars['Int'];
  _all: Scalars['Int'];
};

export type GlobalPetParentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  primaryPhoneNumber?: Maybe<SortOrder>;
};

export type GlobalPetParentCountOutputType = {
  __typename?: 'GlobalPetParentCountOutputType';
  clinicPetParents: Scalars['Int'];
};

export type GlobalPetParentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  primaryPhoneNumber: Scalars['String'];
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutGlobalPetParentInput>;
};

export type GlobalPetParentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  primaryPhoneNumber: Scalars['String'];
};

export type GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput = {
  create?: Maybe<GlobalPetParentUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<GlobalPetParentCreateOrConnectWithoutClinicPetParentsInput>;
  connect?: Maybe<GlobalPetParentWhereUniqueInput>;
};

export type GlobalPetParentCreateOrConnectWithoutClinicPetParentsInput = {
  where: GlobalPetParentWhereUniqueInput;
  create: GlobalPetParentUncheckedCreateWithoutClinicPetParentsInput;
};

export type GlobalPetParentCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  primaryPhoneNumber: Scalars['String'];
};

export type GlobalPetParentMaxAggregateOutputType = {
  __typename?: 'GlobalPetParentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
};

export type GlobalPetParentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  primaryPhoneNumber?: Maybe<SortOrder>;
};

export type GlobalPetParentMinAggregateOutputType = {
  __typename?: 'GlobalPetParentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
};

export type GlobalPetParentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  primaryPhoneNumber?: Maybe<SortOrder>;
};

export enum GlobalPetParentOrderByRelevanceFieldEnum {
  Id = 'id',
  Email = 'email',
  PrimaryPhoneNumber = 'primaryPhoneNumber'
}

export type GlobalPetParentOrderByRelevanceInput = {
  fields: Array<GlobalPetParentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type GlobalPetParentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  primaryPhoneNumber?: Maybe<SortOrder>;
  _count?: Maybe<GlobalPetParentCountOrderByAggregateInput>;
  _max?: Maybe<GlobalPetParentMaxOrderByAggregateInput>;
  _min?: Maybe<GlobalPetParentMinOrderByAggregateInput>;
};

export type GlobalPetParentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  primaryPhoneNumber?: Maybe<SortOrder>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  _relevance?: Maybe<GlobalPetParentOrderByRelevanceInput>;
};

export type GlobalPetParentRelationFilter = {
  is?: Maybe<GlobalPetParentWhereInput>;
  isNot?: Maybe<GlobalPetParentWhereInput>;
};

export enum GlobalPetParentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Email = 'email',
  PrimaryPhoneNumber = 'primaryPhoneNumber'
}

export type GlobalPetParentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<GlobalPetParentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<GlobalPetParentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<GlobalPetParentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  primaryPhoneNumber?: Maybe<StringWithAggregatesFilter>;
};

export type GlobalPetParentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  primaryPhoneNumber: Scalars['String'];
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutGlobalPetParentInput>;
};

export type GlobalPetParentUncheckedCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  primaryPhoneNumber: Scalars['String'];
};

export type GlobalPetParentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutGlobalPetParentNestedInput>;
};

export type GlobalPetParentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
};

export type GlobalPetParentUncheckedUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
};

export type GlobalPetParentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutGlobalPetParentNestedInput>;
};

export type GlobalPetParentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
};

export type GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput = {
  create?: Maybe<GlobalPetParentUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<GlobalPetParentCreateOrConnectWithoutClinicPetParentsInput>;
  upsert?: Maybe<GlobalPetParentUpsertWithoutClinicPetParentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<GlobalPetParentWhereUniqueInput>;
  update?: Maybe<GlobalPetParentUncheckedUpdateWithoutClinicPetParentsInput>;
};

export type GlobalPetParentUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
};

export type GlobalPetParentUpsertWithoutClinicPetParentsInput = {
  update: GlobalPetParentUncheckedUpdateWithoutClinicPetParentsInput;
  create: GlobalPetParentUncheckedCreateWithoutClinicPetParentsInput;
};

export type GlobalPetParentWhereInput = {
  AND?: Maybe<Array<GlobalPetParentWhereInput>>;
  OR?: Maybe<Array<GlobalPetParentWhereInput>>;
  NOT?: Maybe<Array<GlobalPetParentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  primaryPhoneNumber?: Maybe<StringFilter>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
};

export type GlobalPetParentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type HasCarePetWithEnrollmentOutput = {
  __typename?: 'HasCarePetWithEnrollmentOutput';
  hasEnrolledPet: Scalars['Boolean'];
};

export type HasCarePetWithEnrollmentWhereInput = {
  organizationPetParentId: Scalars['String'];
};

export type HasConflictingAppointmentInput = {
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  startAt: Scalars['DateTime'];
  appointmentTypeId: Scalars['String'];
};

export type HillsToHomeApiLog = {
  __typename?: 'HillsToHomeAPILog';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinic: Clinic;
  clinicPetParent?: Maybe<ClinicPetParent>;
  clinicPet?: Maybe<ClinicPet>;
  automationRun?: Maybe<AutomationRun>;
  channel?: Maybe<Channel>;
};

export type HillsToHomeApiLogCountAggregateOutputType = {
  __typename?: 'HillsToHomeAPILogCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  petParentId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  products: Scalars['Int'];
  shipTo: Scalars['Int'];
  species: Scalars['Int'];
  response: Scalars['Int'];
  resultType: Scalars['Int'];
  automationRunId: Scalars['Int'];
  channelId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type HillsToHomeApiLogCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  products?: Maybe<SortOrder>;
  shipTo?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  response?: Maybe<SortOrder>;
  resultType?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
};

export type HillsToHomeApiLogCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  clinic: ClinicCreateNestedOneWithoutHillsToHomeApiLogInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutHillsToHomeApiLogInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutHillsToHomeApiLogInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutHillsToHomeApiLogInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutHillsToHomeApiLogInput>;
};

export type HillsToHomeApiLogCreateManyAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogCreateManyAutomationRunInputEnvelope = {
  data: Array<HillsToHomeApiLogCreateManyAutomationRunInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type HillsToHomeApiLogCreateManyChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  automationRunId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogCreateManyChannelInputEnvelope = {
  data: Array<HillsToHomeApiLogCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type HillsToHomeApiLogCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogCreateManyClinicInputEnvelope = {
  data: Array<HillsToHomeApiLogCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type HillsToHomeApiLogCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogCreateManyClinicPetInputEnvelope = {
  data: Array<HillsToHomeApiLogCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type HillsToHomeApiLogCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogCreateManyClinicPetParentInputEnvelope = {
  data: Array<HillsToHomeApiLogCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type HillsToHomeApiLogCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutAutomationRunInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyAutomationRunInputEnvelope>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
};

export type HillsToHomeApiLogCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
};

export type HillsToHomeApiLogCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
};

export type HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
};

export type HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
};

export type HillsToHomeApiLogCreateOrConnectWithoutAutomationRunInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  create: HillsToHomeApiLogUncheckedCreateWithoutAutomationRunInput;
};

export type HillsToHomeApiLogCreateOrConnectWithoutChannelInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  create: HillsToHomeApiLogUncheckedCreateWithoutChannelInput;
};

export type HillsToHomeApiLogCreateOrConnectWithoutClinicInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  create: HillsToHomeApiLogUncheckedCreateWithoutClinicInput;
};

export type HillsToHomeApiLogCreateOrConnectWithoutClinicPetInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  create: HillsToHomeApiLogUncheckedCreateWithoutClinicPetInput;
};

export type HillsToHomeApiLogCreateOrConnectWithoutClinicPetParentInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  create: HillsToHomeApiLogUncheckedCreateWithoutClinicPetParentInput;
};

export type HillsToHomeApiLogCreateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  clinic: ClinicCreateNestedOneWithoutHillsToHomeApiLogInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutHillsToHomeApiLogInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutHillsToHomeApiLogInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutHillsToHomeApiLogInput>;
};

export type HillsToHomeApiLogCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  clinic: ClinicCreateNestedOneWithoutHillsToHomeApiLogInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutHillsToHomeApiLogInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutHillsToHomeApiLogInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutHillsToHomeApiLogInput>;
};

export type HillsToHomeApiLogCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutHillsToHomeApiLogInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutHillsToHomeApiLogInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutHillsToHomeApiLogInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutHillsToHomeApiLogInput>;
};

export type HillsToHomeApiLogCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  clinic: ClinicCreateNestedOneWithoutHillsToHomeApiLogInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutHillsToHomeApiLogInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutHillsToHomeApiLogInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutHillsToHomeApiLogInput>;
};

export type HillsToHomeApiLogCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  clinic: ClinicCreateNestedOneWithoutHillsToHomeApiLogInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutHillsToHomeApiLogInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutHillsToHomeApiLogInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutHillsToHomeApiLogInput>;
};

export type HillsToHomeApiLogListRelationFilter = {
  every?: Maybe<HillsToHomeApiLogWhereInput>;
  some?: Maybe<HillsToHomeApiLogWhereInput>;
  none?: Maybe<HillsToHomeApiLogWhereInput>;
};

export type HillsToHomeApiLogMaxAggregateOutputType = {
  __typename?: 'HillsToHomeAPILogMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  resultType?: Maybe<HillsToHomeResultType>;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  products?: Maybe<SortOrder>;
  shipTo?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  resultType?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
};

export type HillsToHomeApiLogMinAggregateOutputType = {
  __typename?: 'HillsToHomeAPILogMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  resultType?: Maybe<HillsToHomeResultType>;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  products?: Maybe<SortOrder>;
  shipTo?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  resultType?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
};

export type HillsToHomeApiLogOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum HillsToHomeApiLogOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  PetParentId = 'petParentId',
  ClinicPetId = 'clinicPetId',
  Products = 'products',
  ShipTo = 'shipTo',
  AutomationRunId = 'automationRunId',
  ChannelId = 'channelId'
}

export type HillsToHomeApiLogOrderByRelevanceInput = {
  fields: Array<HillsToHomeApiLogOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type HillsToHomeApiLogOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  products?: Maybe<SortOrder>;
  shipTo?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  response?: Maybe<SortOrder>;
  resultType?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  _count?: Maybe<HillsToHomeApiLogCountOrderByAggregateInput>;
  _max?: Maybe<HillsToHomeApiLogMaxOrderByAggregateInput>;
  _min?: Maybe<HillsToHomeApiLogMinOrderByAggregateInput>;
};

export type HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  products?: Maybe<SortOrder>;
  shipTo?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  response?: Maybe<SortOrder>;
  resultType?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  automationRun?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<HillsToHomeApiLogOrderByRelevanceInput>;
};

export enum HillsToHomeApiLogScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  PetParentId = 'petParentId',
  ClinicPetId = 'clinicPetId',
  Products = 'products',
  ShipTo = 'shipTo',
  Species = 'species',
  Response = 'response',
  ResultType = 'resultType',
  AutomationRunId = 'automationRunId',
  ChannelId = 'channelId'
}

export type HillsToHomeApiLogScalarWhereInput = {
  AND?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  OR?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  NOT?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  products?: Maybe<StringNullableFilter>;
  shipTo?: Maybe<StringNullableFilter>;
  species?: Maybe<EnumHillsToHomeSpeciesNullableFilter>;
  response?: Maybe<JsonFilter>;
  resultType?: Maybe<EnumHillsToHomeResultTypeFilter>;
  automationRunId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
};

export type HillsToHomeApiLogScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<HillsToHomeApiLogScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<HillsToHomeApiLogScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<HillsToHomeApiLogScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  petParentId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
  products?: Maybe<StringNullableWithAggregatesFilter>;
  shipTo?: Maybe<StringNullableWithAggregatesFilter>;
  species?: Maybe<EnumHillsToHomeSpeciesNullableWithAggregatesFilter>;
  response?: Maybe<JsonWithAggregatesFilter>;
  resultType?: Maybe<EnumHillsToHomeResultTypeWithAggregatesFilter>;
  automationRunId?: Maybe<StringNullableWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type HillsToHomeApiLogUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutAutomationRunInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyAutomationRunInputEnvelope>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
};

export type HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
};

export type HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
};

export type HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
};

export type HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
};

export type HillsToHomeApiLogUncheckedCreateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogUncheckedCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  automationRunId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutAutomationRunInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutAutomationRunInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyAutomationRunInputEnvelope>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutAutomationRunInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutAutomationRunInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
};

export type HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
};

export type HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
};

export type HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
};

export type HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
};

export type HillsToHomeApiLogUncheckedUpdateManyWithoutHillsToHomeApiLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  automationRunId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogUncheckedUpdateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogUncheckedUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  automationRunId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type HillsToHomeApiLogUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutHillsToHomeApiLogNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutHillsToHomeApiLogNestedInput>;
};

export type HillsToHomeApiLogUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
};

export type HillsToHomeApiLogUpdateManyWithWhereWithoutAutomationRunInput = {
  where: HillsToHomeApiLogScalarWhereInput;
  data: HillsToHomeApiLogUncheckedUpdateManyWithoutHillsToHomeApiLogInput;
};

export type HillsToHomeApiLogUpdateManyWithWhereWithoutChannelInput = {
  where: HillsToHomeApiLogScalarWhereInput;
  data: HillsToHomeApiLogUncheckedUpdateManyWithoutHillsToHomeApiLogInput;
};

export type HillsToHomeApiLogUpdateManyWithWhereWithoutClinicInput = {
  where: HillsToHomeApiLogScalarWhereInput;
  data: HillsToHomeApiLogUncheckedUpdateManyWithoutHillsToHomeApiLogInput;
};

export type HillsToHomeApiLogUpdateManyWithWhereWithoutClinicPetInput = {
  where: HillsToHomeApiLogScalarWhereInput;
  data: HillsToHomeApiLogUncheckedUpdateManyWithoutHillsToHomeApiLogInput;
};

export type HillsToHomeApiLogUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: HillsToHomeApiLogScalarWhereInput;
  data: HillsToHomeApiLogUncheckedUpdateManyWithoutHillsToHomeApiLogInput;
};

export type HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutAutomationRunInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutAutomationRunInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyAutomationRunInputEnvelope>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutAutomationRunInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutAutomationRunInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
};

export type HillsToHomeApiLogUpdateManyWithoutChannelNestedInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
};

export type HillsToHomeApiLogUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
};

export type HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
};

export type HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
};

export type HillsToHomeApiLogUpdateWithWhereUniqueWithoutAutomationRunInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  data: HillsToHomeApiLogUncheckedUpdateWithoutAutomationRunInput;
};

export type HillsToHomeApiLogUpdateWithWhereUniqueWithoutChannelInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  data: HillsToHomeApiLogUncheckedUpdateWithoutChannelInput;
};

export type HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  data: HillsToHomeApiLogUncheckedUpdateWithoutClinicInput;
};

export type HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  data: HillsToHomeApiLogUncheckedUpdateWithoutClinicPetInput;
};

export type HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  data: HillsToHomeApiLogUncheckedUpdateWithoutClinicPetParentInput;
};

export type HillsToHomeApiLogUpdateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutHillsToHomeApiLogNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutHillsToHomeApiLogNestedInput>;
};

export type HillsToHomeApiLogUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutHillsToHomeApiLogNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutHillsToHomeApiLogNestedInput>;
};

export type HillsToHomeApiLogUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutHillsToHomeApiLogNestedInput>;
};

export type HillsToHomeApiLogUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutHillsToHomeApiLogNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutHillsToHomeApiLogNestedInput>;
};

export type HillsToHomeApiLogUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutHillsToHomeApiLogNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutHillsToHomeApiLogNestedInput>;
};

export type HillsToHomeApiLogUpsertWithWhereUniqueWithoutAutomationRunInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  update: HillsToHomeApiLogUncheckedUpdateWithoutAutomationRunInput;
  create: HillsToHomeApiLogUncheckedCreateWithoutAutomationRunInput;
};

export type HillsToHomeApiLogUpsertWithWhereUniqueWithoutChannelInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  update: HillsToHomeApiLogUncheckedUpdateWithoutChannelInput;
  create: HillsToHomeApiLogUncheckedCreateWithoutChannelInput;
};

export type HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  update: HillsToHomeApiLogUncheckedUpdateWithoutClinicInput;
  create: HillsToHomeApiLogUncheckedCreateWithoutClinicInput;
};

export type HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  update: HillsToHomeApiLogUncheckedUpdateWithoutClinicPetInput;
  create: HillsToHomeApiLogUncheckedCreateWithoutClinicPetInput;
};

export type HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: HillsToHomeApiLogWhereUniqueInput;
  update: HillsToHomeApiLogUncheckedUpdateWithoutClinicPetParentInput;
  create: HillsToHomeApiLogUncheckedCreateWithoutClinicPetParentInput;
};

export type HillsToHomeApiLogWhereInput = {
  AND?: Maybe<Array<HillsToHomeApiLogWhereInput>>;
  OR?: Maybe<Array<HillsToHomeApiLogWhereInput>>;
  NOT?: Maybe<Array<HillsToHomeApiLogWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  products?: Maybe<StringNullableFilter>;
  shipTo?: Maybe<StringNullableFilter>;
  species?: Maybe<EnumHillsToHomeSpeciesNullableFilter>;
  response?: Maybe<JsonFilter>;
  resultType?: Maybe<EnumHillsToHomeResultTypeFilter>;
  automationRunId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  automationRun?: Maybe<AutomationRunWhereInput>;
  channel?: Maybe<ChannelWhereInput>;
};

export type HillsToHomeApiLogWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum HillsToHomeResultType {
  New = 'New',
  Existing = 'Existing',
  Error = 'Error'
}

export enum HillsToHomeSpecies {
  Dog = 'Dog',
  Cat = 'Cat'
}

export type IdInput = {
  id: Scalars['String'];
};

/** @deprecated - 1.0 */
export type Image = {
  __typename?: 'Image';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  source: Scalars['String'];
  consultation: Array<Consultation>;
  _count: ImageCountOutputType;
};


/** @deprecated - 1.0 */
export type ImageConsultationArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
};

export type ImageCountAggregateOutputType = {
  __typename?: 'ImageCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  source: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ImageCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type ImageCountOutputType = {
  __typename?: 'ImageCountOutputType';
  consultation: Scalars['Int'];
};

export type ImageCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: Scalars['String'];
  consultation?: Maybe<ConsultationCreateNestedManyWithoutPicsInput>;
};

export type ImageCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: Scalars['String'];
};

export type ImageCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<ImageCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutConsultationInput>>;
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
};

export type ImageCreateOrConnectWithoutConsultationInput = {
  where: ImageWhereUniqueInput;
  create: ImageUncheckedCreateWithoutConsultationInput;
};

export type ImageCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: Scalars['String'];
};

export type ImageListRelationFilter = {
  every?: Maybe<ImageWhereInput>;
  some?: Maybe<ImageWhereInput>;
  none?: Maybe<ImageWhereInput>;
};

export type ImageMaxAggregateOutputType = {
  __typename?: 'ImageMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type ImageMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type ImageMinAggregateOutputType = {
  __typename?: 'ImageMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type ImageMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type ImageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ImageOrderByRelevanceFieldEnum {
  Id = 'id',
  Source = 'source'
}

export type ImageOrderByRelevanceInput = {
  fields: Array<ImageOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ImageOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  _count?: Maybe<ImageCountOrderByAggregateInput>;
  _max?: Maybe<ImageMaxOrderByAggregateInput>;
  _min?: Maybe<ImageMinOrderByAggregateInput>;
};

export type ImageOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  consultation?: Maybe<ConsultationOrderByRelationAggregateInput>;
  _relevance?: Maybe<ImageOrderByRelevanceInput>;
};

export enum ImageScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Source = 'source'
}

export type ImageScalarWhereInput = {
  AND?: Maybe<Array<ImageScalarWhereInput>>;
  OR?: Maybe<Array<ImageScalarWhereInput>>;
  NOT?: Maybe<Array<ImageScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  source?: Maybe<StringFilter>;
};

export type ImageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ImageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ImageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ImageScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  source?: Maybe<StringWithAggregatesFilter>;
};

export type ImageUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: Scalars['String'];
  consultation?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPicsInput>;
};

export type ImageUncheckedCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<ImageCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutConsultationInput>>;
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
};

export type ImageUncheckedCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: Scalars['String'];
};

export type ImageUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  consultation?: Maybe<ConsultationUncheckedUpdateManyWithoutPicsNestedInput>;
};

export type ImageUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type ImageUncheckedUpdateManyWithoutConsultationNestedInput = {
  create?: Maybe<Array<ImageCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<ImageUpsertWithWhereUniqueWithoutConsultationInput>>;
  set?: Maybe<Array<ImageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ImageWhereUniqueInput>>;
  delete?: Maybe<Array<ImageWhereUniqueInput>>;
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
  update?: Maybe<Array<ImageUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<ImageUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<ImageScalarWhereInput>>;
};

export type ImageUncheckedUpdateManyWithoutPicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type ImageUncheckedUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type ImageUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  consultation?: Maybe<ConsultationUpdateManyWithoutPicsNestedInput>;
};

export type ImageUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type ImageUpdateManyWithWhereWithoutConsultationInput = {
  where: ImageScalarWhereInput;
  data: ImageUncheckedUpdateManyWithoutPicsInput;
};

export type ImageUpdateManyWithoutConsultationNestedInput = {
  create?: Maybe<Array<ImageCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<ImageUpsertWithWhereUniqueWithoutConsultationInput>>;
  set?: Maybe<Array<ImageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ImageWhereUniqueInput>>;
  delete?: Maybe<Array<ImageWhereUniqueInput>>;
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
  update?: Maybe<Array<ImageUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<ImageUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<ImageScalarWhereInput>>;
};

export type ImageUpdateWithWhereUniqueWithoutConsultationInput = {
  where: ImageWhereUniqueInput;
  data: ImageUncheckedUpdateWithoutConsultationInput;
};

export type ImageUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type ImageUpsertWithWhereUniqueWithoutConsultationInput = {
  where: ImageWhereUniqueInput;
  update: ImageUncheckedUpdateWithoutConsultationInput;
  create: ImageUncheckedCreateWithoutConsultationInput;
};

export type ImageWhereInput = {
  AND?: Maybe<Array<ImageWhereInput>>;
  OR?: Maybe<Array<ImageWhereInput>>;
  NOT?: Maybe<Array<ImageWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  source?: Maybe<StringFilter>;
  consultation?: Maybe<ConsultationListRelationFilter>;
};

export type ImageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type InformAudienceDefinition = {
  __typename?: 'InformAudienceDefinition';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  informPartnerId?: Maybe<Scalars['String']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartner>;
  informCampaigns: Array<InformCampaign>;
  canonicalSpecies: Array<CanonicalSpecies>;
  canonicalBreeds: Array<CanonicalBreed>;
  canonicalServices: Array<CanonicalService>;
  _count: InformAudienceDefinitionCountOutputType;
};


export type InformAudienceDefinitionInformCampaignsArgs = {
  where?: Maybe<InformCampaignWhereInput>;
  orderBy?: Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformCampaignScalarFieldEnum>;
};


export type InformAudienceDefinitionCanonicalSpeciesArgs = {
  where?: Maybe<CanonicalSpeciesWhereInput>;
  orderBy?: Maybe<CanonicalSpeciesOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CanonicalSpeciesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CanonicalSpeciesScalarFieldEnum>;
};


export type InformAudienceDefinitionCanonicalBreedsArgs = {
  where?: Maybe<CanonicalBreedWhereInput>;
  orderBy?: Maybe<CanonicalBreedOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CanonicalBreedWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CanonicalBreedScalarFieldEnum>;
};


export type InformAudienceDefinitionCanonicalServicesArgs = {
  where?: Maybe<CanonicalServiceWhereInput>;
  orderBy?: Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CanonicalServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CanonicalServiceScalarFieldEnum>;
};

export type InformAudienceDefinitionCountAggregateOutputType = {
  __typename?: 'InformAudienceDefinitionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  name: Scalars['Int'];
  type: Scalars['Int'];
  description: Scalars['Int'];
  settings: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformAudienceDefinitionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
};

export type InformAudienceDefinitionCountOutputType = {
  __typename?: 'InformAudienceDefinitionCountOutputType';
  informCampaigns: Scalars['Int'];
  canonicalSpecies: Scalars['Int'];
  canonicalBreeds: Scalars['Int'];
  canonicalServices: Scalars['Int'];
};

export type InformAudienceDefinitionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutInformAudienceDefinitionsInput>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformAudienceDefinitionInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalBreeds?: Maybe<CanonicalBreedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutInformAudienceDefinitionsInput>;
};

export type InformAudienceDefinitionCreateManyInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformAudienceDefinitionCreateManyInformPartnerInputEnvelope = {
  data: Array<InformAudienceDefinitionCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformAudienceDefinitionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformAudienceDefinitionCreateNestedManyWithoutCanonicalBreedsInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalBreedsInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalBreedsInput>>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
};

export type InformAudienceDefinitionCreateNestedManyWithoutCanonicalServicesInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalServicesInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalServicesInput>>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
};

export type InformAudienceDefinitionCreateNestedManyWithoutCanonicalSpeciesInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalSpeciesInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalSpeciesInput>>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
};

export type InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<InformAudienceDefinitionCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
};

export type InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput = {
  create?: Maybe<InformAudienceDefinitionUncheckedCreateWithoutInformCampaignsInput>;
  connectOrCreate?: Maybe<InformAudienceDefinitionCreateOrConnectWithoutInformCampaignsInput>;
  connect?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
};

export type InformAudienceDefinitionCreateOrConnectWithoutCanonicalBreedsInput = {
  where: InformAudienceDefinitionWhereUniqueInput;
  create: InformAudienceDefinitionUncheckedCreateWithoutCanonicalBreedsInput;
};

export type InformAudienceDefinitionCreateOrConnectWithoutCanonicalServicesInput = {
  where: InformAudienceDefinitionWhereUniqueInput;
  create: InformAudienceDefinitionUncheckedCreateWithoutCanonicalServicesInput;
};

export type InformAudienceDefinitionCreateOrConnectWithoutCanonicalSpeciesInput = {
  where: InformAudienceDefinitionWhereUniqueInput;
  create: InformAudienceDefinitionUncheckedCreateWithoutCanonicalSpeciesInput;
};

export type InformAudienceDefinitionCreateOrConnectWithoutInformCampaignsInput = {
  where: InformAudienceDefinitionWhereUniqueInput;
  create: InformAudienceDefinitionUncheckedCreateWithoutInformCampaignsInput;
};

export type InformAudienceDefinitionCreateOrConnectWithoutInformPartnerInput = {
  where: InformAudienceDefinitionWhereUniqueInput;
  create: InformAudienceDefinitionUncheckedCreateWithoutInformPartnerInput;
};

export type InformAudienceDefinitionCreateWithoutCanonicalBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutInformAudienceDefinitionsInput>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformAudienceDefinitionInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutInformAudienceDefinitionsInput>;
};

export type InformAudienceDefinitionCreateWithoutCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutInformAudienceDefinitionsInput>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformAudienceDefinitionInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalBreeds?: Maybe<CanonicalBreedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
};

export type InformAudienceDefinitionCreateWithoutCanonicalSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutInformAudienceDefinitionsInput>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformAudienceDefinitionInput>;
  canonicalBreeds?: Maybe<CanonicalBreedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutInformAudienceDefinitionsInput>;
};

export type InformAudienceDefinitionCreateWithoutInformCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutInformAudienceDefinitionsInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalBreeds?: Maybe<CanonicalBreedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutInformAudienceDefinitionsInput>;
};

export type InformAudienceDefinitionCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformAudienceDefinitionInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalBreeds?: Maybe<CanonicalBreedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutInformAudienceDefinitionsInput>;
};

export type InformAudienceDefinitionListRelationFilter = {
  every?: Maybe<InformAudienceDefinitionWhereInput>;
  some?: Maybe<InformAudienceDefinitionWhereInput>;
  none?: Maybe<InformAudienceDefinitionWhereInput>;
};

export type InformAudienceDefinitionMaxAggregateOutputType = {
  __typename?: 'InformAudienceDefinitionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
};

export type InformAudienceDefinitionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type InformAudienceDefinitionMinAggregateOutputType = {
  __typename?: 'InformAudienceDefinitionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
};

export type InformAudienceDefinitionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type InformAudienceDefinitionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformAudienceDefinitionOrderByRelevanceFieldEnum {
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  Name = 'name',
  Description = 'description'
}

export type InformAudienceDefinitionOrderByRelevanceInput = {
  fields: Array<InformAudienceDefinitionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformAudienceDefinitionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  _count?: Maybe<InformAudienceDefinitionCountOrderByAggregateInput>;
  _max?: Maybe<InformAudienceDefinitionMaxOrderByAggregateInput>;
  _min?: Maybe<InformAudienceDefinitionMinOrderByAggregateInput>;
};

export type InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informCampaigns?: Maybe<InformCampaignOrderByRelationAggregateInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesOrderByRelationAggregateInput>;
  canonicalBreeds?: Maybe<CanonicalBreedOrderByRelationAggregateInput>;
  canonicalServices?: Maybe<CanonicalServiceOrderByRelationAggregateInput>;
  _relevance?: Maybe<InformAudienceDefinitionOrderByRelevanceInput>;
};

export type InformAudienceDefinitionRelationFilter = {
  is?: Maybe<InformAudienceDefinitionWhereInput>;
  isNot?: Maybe<InformAudienceDefinitionWhereInput>;
};

export enum InformAudienceDefinitionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InformPartnerId = 'informPartnerId',
  Status = 'status',
  StatusMessage = 'statusMessage',
  Name = 'name',
  Type = 'type',
  Description = 'description',
  Settings = 'settings'
}

export type InformAudienceDefinitionScalarWhereInput = {
  AND?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
  OR?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
  NOT?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumInformAudienceDefinitionStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  name?: Maybe<StringFilter>;
  type?: Maybe<EnumInformAudienceDefinitionTypeFilter>;
  description?: Maybe<StringNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
};

export type InformAudienceDefinitionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformAudienceDefinitionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformAudienceDefinitionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformAudienceDefinitionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  informPartnerId?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumInformAudienceDefinitionStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumInformAudienceDefinitionTypeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum InformAudienceDefinitionStatus {
  Active = 'Active',
  Paused = 'Paused',
  Archived = 'Archived'
}

export enum InformAudienceDefinitionType {
  FinalDose = 'FinalDose',
  NonUser = 'NonUser',
  Lapsed = 'Lapsed',
  LapsedOrNonUser = 'LapsedOrNonUser',
  FirstPurchase = 'FirstPurchase'
}

export type InformAudienceDefinitionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformAudienceDefinitionInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
};

export type InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalBreedsInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalBreedsInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalBreedsInput>>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
};

export type InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalServicesInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalServicesInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalServicesInput>>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
};

export type InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalSpeciesInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalSpeciesInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalSpeciesInput>>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
};

export type InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<InformAudienceDefinitionCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
};

export type InformAudienceDefinitionUncheckedCreateWithoutCanonicalBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformAudienceDefinitionInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
};

export type InformAudienceDefinitionUncheckedCreateWithoutCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformAudienceDefinitionInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
};

export type InformAudienceDefinitionUncheckedCreateWithoutCanonicalSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformAudienceDefinitionInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
};

export type InformAudienceDefinitionUncheckedCreateWithoutInformCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
};

export type InformAudienceDefinitionUncheckedCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  type: InformAudienceDefinitionType;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformAudienceDefinitionInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
};

export type InformAudienceDefinitionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
};

export type InformAudienceDefinitionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalBreedsNestedInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalBreedsInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalBreedsInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalBreedsInput>>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalBreedsInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalBreedsInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
};

export type InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalServicesNestedInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalServicesInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalServicesInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalServicesInput>>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalServicesInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalServicesInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
};

export type InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalSpeciesNestedInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalSpeciesInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalSpeciesInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalSpeciesInput>>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalSpeciesInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalSpeciesInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
};

export type InformAudienceDefinitionUncheckedUpdateManyWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<InformAudienceDefinitionCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
};

export type InformAudienceDefinitionUncheckedUpdateWithoutCanonicalBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
};

export type InformAudienceDefinitionUncheckedUpdateWithoutCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
};

export type InformAudienceDefinitionUncheckedUpdateWithoutCanonicalSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
};

export type InformAudienceDefinitionUncheckedUpdateWithoutInformCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
};

export type InformAudienceDefinitionUncheckedUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
};

export type InformAudienceDefinitionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutInformAudienceDefinitionsNestedInput>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
};

export type InformAudienceDefinitionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalBreedsInput = {
  where: InformAudienceDefinitionScalarWhereInput;
  data: InformAudienceDefinitionUncheckedUpdateManyWithoutInformAudienceDefinitionsInput;
};

export type InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalServicesInput = {
  where: InformAudienceDefinitionScalarWhereInput;
  data: InformAudienceDefinitionUncheckedUpdateManyWithoutInformAudienceDefinitionsInput;
};

export type InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalSpeciesInput = {
  where: InformAudienceDefinitionScalarWhereInput;
  data: InformAudienceDefinitionUncheckedUpdateManyWithoutInformAudienceDefinitionsInput;
};

export type InformAudienceDefinitionUpdateManyWithWhereWithoutInformPartnerInput = {
  where: InformAudienceDefinitionScalarWhereInput;
  data: InformAudienceDefinitionUncheckedUpdateManyWithoutInformAudienceDefinitionsInput;
};

export type InformAudienceDefinitionUpdateManyWithoutCanonicalBreedsNestedInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalBreedsInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalBreedsInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalBreedsInput>>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalBreedsInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalBreedsInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
};

export type InformAudienceDefinitionUpdateManyWithoutCanonicalServicesNestedInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalServicesInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalServicesInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalServicesInput>>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalServicesInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalServicesInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
};

export type InformAudienceDefinitionUpdateManyWithoutCanonicalSpeciesNestedInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalSpeciesInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalSpeciesInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalSpeciesInput>>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalSpeciesInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalSpeciesInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
};

export type InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<InformAudienceDefinitionCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
};

export type InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput = {
  create?: Maybe<InformAudienceDefinitionUncheckedCreateWithoutInformCampaignsInput>;
  connectOrCreate?: Maybe<InformAudienceDefinitionCreateOrConnectWithoutInformCampaignsInput>;
  upsert?: Maybe<InformAudienceDefinitionUpsertWithoutInformCampaignsInput>;
  connect?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  update?: Maybe<InformAudienceDefinitionUncheckedUpdateWithoutInformCampaignsInput>;
};

export type InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalBreedsInput = {
  where: InformAudienceDefinitionWhereUniqueInput;
  data: InformAudienceDefinitionUncheckedUpdateWithoutCanonicalBreedsInput;
};

export type InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalServicesInput = {
  where: InformAudienceDefinitionWhereUniqueInput;
  data: InformAudienceDefinitionUncheckedUpdateWithoutCanonicalServicesInput;
};

export type InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalSpeciesInput = {
  where: InformAudienceDefinitionWhereUniqueInput;
  data: InformAudienceDefinitionUncheckedUpdateWithoutCanonicalSpeciesInput;
};

export type InformAudienceDefinitionUpdateWithWhereUniqueWithoutInformPartnerInput = {
  where: InformAudienceDefinitionWhereUniqueInput;
  data: InformAudienceDefinitionUncheckedUpdateWithoutInformPartnerInput;
};

export type InformAudienceDefinitionUpdateWithoutCanonicalBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutInformAudienceDefinitionsNestedInput>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
};

export type InformAudienceDefinitionUpdateWithoutCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutInformAudienceDefinitionsNestedInput>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
};

export type InformAudienceDefinitionUpdateWithoutCanonicalSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutInformAudienceDefinitionsNestedInput>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
};

export type InformAudienceDefinitionUpdateWithoutInformCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutInformAudienceDefinitionsNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
};

export type InformAudienceDefinitionUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InformAudienceDefinitionType>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalBreeds?: Maybe<CanonicalBreedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
};

export type InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalBreedsInput = {
  where: InformAudienceDefinitionWhereUniqueInput;
  update: InformAudienceDefinitionUncheckedUpdateWithoutCanonicalBreedsInput;
  create: InformAudienceDefinitionUncheckedCreateWithoutCanonicalBreedsInput;
};

export type InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalServicesInput = {
  where: InformAudienceDefinitionWhereUniqueInput;
  update: InformAudienceDefinitionUncheckedUpdateWithoutCanonicalServicesInput;
  create: InformAudienceDefinitionUncheckedCreateWithoutCanonicalServicesInput;
};

export type InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalSpeciesInput = {
  where: InformAudienceDefinitionWhereUniqueInput;
  update: InformAudienceDefinitionUncheckedUpdateWithoutCanonicalSpeciesInput;
  create: InformAudienceDefinitionUncheckedCreateWithoutCanonicalSpeciesInput;
};

export type InformAudienceDefinitionUpsertWithWhereUniqueWithoutInformPartnerInput = {
  where: InformAudienceDefinitionWhereUniqueInput;
  update: InformAudienceDefinitionUncheckedUpdateWithoutInformPartnerInput;
  create: InformAudienceDefinitionUncheckedCreateWithoutInformPartnerInput;
};

export type InformAudienceDefinitionUpsertWithoutInformCampaignsInput = {
  update: InformAudienceDefinitionUncheckedUpdateWithoutInformCampaignsInput;
  create: InformAudienceDefinitionUncheckedCreateWithoutInformCampaignsInput;
};

export type InformAudienceDefinitionWhereInput = {
  AND?: Maybe<Array<InformAudienceDefinitionWhereInput>>;
  OR?: Maybe<Array<InformAudienceDefinitionWhereInput>>;
  NOT?: Maybe<Array<InformAudienceDefinitionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumInformAudienceDefinitionStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  name?: Maybe<StringFilter>;
  type?: Maybe<EnumInformAudienceDefinitionTypeFilter>;
  description?: Maybe<StringNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informCampaigns?: Maybe<InformCampaignListRelationFilter>;
  canonicalSpecies?: Maybe<CanonicalSpeciesListRelationFilter>;
  canonicalBreeds?: Maybe<CanonicalBreedListRelationFilter>;
  canonicalServices?: Maybe<CanonicalServiceListRelationFilter>;
};

export type InformAudienceDefinitionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaign = {
  __typename?: 'InformAutomationCampaign';
  id: Scalars['String'];
  workflowEventSettingId: Scalars['String'];
  workflowEventSetting: WorkflowEventSetting;
  informCampaign: InformCampaign;
};

export type InformAutomationCampaignCountAggregateOutputType = {
  __typename?: 'InformAutomationCampaignCountAggregateOutputType';
  id: Scalars['Int'];
  workflowEventSettingId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformAutomationCampaignCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type InformAutomationCampaignCreateInput = {
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutInformAutomationCampaignsInput;
  informCampaign: InformCampaignCreateNestedOneWithoutInformAutomationCampaignInput;
};

export type InformAutomationCampaignCreateManyInput = {
  id: Scalars['String'];
  workflowEventSettingId: Scalars['String'];
};

export type InformAutomationCampaignCreateManyWorkflowEventSettingInput = {
  id: Scalars['String'];
};

export type InformAutomationCampaignCreateManyWorkflowEventSettingInputEnvelope = {
  data: Array<InformAutomationCampaignCreateManyWorkflowEventSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<InformAutomationCampaignCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<InformAutomationCampaignCreateOrConnectWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<InformAutomationCampaignCreateManyWorkflowEventSettingInputEnvelope>;
  connect?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
};

export type InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput = {
  create?: Maybe<InformAutomationCampaignUncheckedCreateWithoutInformCampaignInput>;
  connectOrCreate?: Maybe<InformAutomationCampaignCreateOrConnectWithoutInformCampaignInput>;
  connect?: Maybe<InformAutomationCampaignWhereUniqueInput>;
};

export type InformAutomationCampaignCreateOrConnectWithoutInformCampaignInput = {
  where: InformAutomationCampaignWhereUniqueInput;
  create: InformAutomationCampaignUncheckedCreateWithoutInformCampaignInput;
};

export type InformAutomationCampaignCreateOrConnectWithoutWorkflowEventSettingInput = {
  where: InformAutomationCampaignWhereUniqueInput;
  create: InformAutomationCampaignUncheckedCreateWithoutWorkflowEventSettingInput;
};

export type InformAutomationCampaignCreateWithoutInformCampaignInput = {
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutInformAutomationCampaignsInput;
};

export type InformAutomationCampaignCreateWithoutWorkflowEventSettingInput = {
  informCampaign: InformCampaignCreateNestedOneWithoutInformAutomationCampaignInput;
};

export type InformAutomationCampaignListRelationFilter = {
  every?: Maybe<InformAutomationCampaignWhereInput>;
  some?: Maybe<InformAutomationCampaignWhereInput>;
  none?: Maybe<InformAutomationCampaignWhereInput>;
};

export type InformAutomationCampaignMaxAggregateOutputType = {
  __typename?: 'InformAutomationCampaignMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaignMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type InformAutomationCampaignMinAggregateOutputType = {
  __typename?: 'InformAutomationCampaignMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaignMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type InformAutomationCampaignOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformAutomationCampaignOrderByRelevanceFieldEnum {
  Id = 'id',
  WorkflowEventSettingId = 'workflowEventSettingId'
}

export type InformAutomationCampaignOrderByRelevanceInput = {
  fields: Array<InformAutomationCampaignOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformAutomationCampaignOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  _count?: Maybe<InformAutomationCampaignCountOrderByAggregateInput>;
  _max?: Maybe<InformAutomationCampaignMaxOrderByAggregateInput>;
  _min?: Maybe<InformAutomationCampaignMinOrderByAggregateInput>;
};

export type InformAutomationCampaignOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  workflowEventSetting?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  informCampaign?: Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<InformAutomationCampaignOrderByRelevanceInput>;
};

export type InformAutomationCampaignRelationFilter = {
  is?: Maybe<InformAutomationCampaignWhereInput>;
  isNot?: Maybe<InformAutomationCampaignWhereInput>;
};

export enum InformAutomationCampaignScalarFieldEnum {
  Id = 'id',
  WorkflowEventSettingId = 'workflowEventSettingId'
}

export type InformAutomationCampaignScalarWhereInput = {
  AND?: Maybe<Array<InformAutomationCampaignScalarWhereInput>>;
  OR?: Maybe<Array<InformAutomationCampaignScalarWhereInput>>;
  NOT?: Maybe<Array<InformAutomationCampaignScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  workflowEventSettingId?: Maybe<StringFilter>;
};

export type InformAutomationCampaignScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformAutomationCampaignScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformAutomationCampaignScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformAutomationCampaignScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  workflowEventSettingId?: Maybe<StringWithAggregatesFilter>;
};

export type InformAutomationCampaignUncheckedCreateInput = {
  id: Scalars['String'];
  workflowEventSettingId: Scalars['String'];
};

export type InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<InformAutomationCampaignCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<InformAutomationCampaignCreateOrConnectWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<InformAutomationCampaignCreateManyWorkflowEventSettingInputEnvelope>;
  connect?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
};

export type InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput = {
  create?: Maybe<InformAutomationCampaignUncheckedCreateWithoutInformCampaignInput>;
  connectOrCreate?: Maybe<InformAutomationCampaignCreateOrConnectWithoutInformCampaignInput>;
  connect?: Maybe<InformAutomationCampaignWhereUniqueInput>;
};

export type InformAutomationCampaignUncheckedCreateWithoutInformCampaignInput = {
  workflowEventSettingId: Scalars['String'];
};

export type InformAutomationCampaignUncheckedCreateWithoutWorkflowEventSettingInput = {
  id: Scalars['String'];
};

export type InformAutomationCampaignUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaignUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaignUncheckedUpdateManyWithoutInformAutomationCampaignsInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput = {
  create?: Maybe<Array<InformAutomationCampaignCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<InformAutomationCampaignCreateOrConnectWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<InformAutomationCampaignUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<InformAutomationCampaignCreateManyWorkflowEventSettingInputEnvelope>;
  set?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformAutomationCampaignUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<InformAutomationCampaignUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  deleteMany?: Maybe<Array<InformAutomationCampaignScalarWhereInput>>;
};

export type InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput = {
  create?: Maybe<InformAutomationCampaignUncheckedCreateWithoutInformCampaignInput>;
  connectOrCreate?: Maybe<InformAutomationCampaignCreateOrConnectWithoutInformCampaignInput>;
  upsert?: Maybe<InformAutomationCampaignUpsertWithoutInformCampaignInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  update?: Maybe<InformAutomationCampaignUncheckedUpdateWithoutInformCampaignInput>;
};

export type InformAutomationCampaignUncheckedUpdateWithoutInformCampaignInput = {
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaignUncheckedUpdateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaignUpdateInput = {
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutInformAutomationCampaignsNestedInput>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformAutomationCampaignNestedInput>;
};

export type InformAutomationCampaignUpdateManyWithWhereWithoutWorkflowEventSettingInput = {
  where: InformAutomationCampaignScalarWhereInput;
  data: InformAutomationCampaignUncheckedUpdateManyWithoutInformAutomationCampaignsInput;
};

export type InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput = {
  create?: Maybe<Array<InformAutomationCampaignCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<InformAutomationCampaignCreateOrConnectWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<InformAutomationCampaignUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<InformAutomationCampaignCreateManyWorkflowEventSettingInputEnvelope>;
  set?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformAutomationCampaignUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<InformAutomationCampaignUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  deleteMany?: Maybe<Array<InformAutomationCampaignScalarWhereInput>>;
};

export type InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput = {
  create?: Maybe<InformAutomationCampaignUncheckedCreateWithoutInformCampaignInput>;
  connectOrCreate?: Maybe<InformAutomationCampaignCreateOrConnectWithoutInformCampaignInput>;
  upsert?: Maybe<InformAutomationCampaignUpsertWithoutInformCampaignInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  update?: Maybe<InformAutomationCampaignUncheckedUpdateWithoutInformCampaignInput>;
};

export type InformAutomationCampaignUpdateWithWhereUniqueWithoutWorkflowEventSettingInput = {
  where: InformAutomationCampaignWhereUniqueInput;
  data: InformAutomationCampaignUncheckedUpdateWithoutWorkflowEventSettingInput;
};

export type InformAutomationCampaignUpdateWithoutInformCampaignInput = {
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutInformAutomationCampaignsNestedInput>;
};

export type InformAutomationCampaignUpdateWithoutWorkflowEventSettingInput = {
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformAutomationCampaignNestedInput>;
};

export type InformAutomationCampaignUpsertWithWhereUniqueWithoutWorkflowEventSettingInput = {
  where: InformAutomationCampaignWhereUniqueInput;
  update: InformAutomationCampaignUncheckedUpdateWithoutWorkflowEventSettingInput;
  create: InformAutomationCampaignUncheckedCreateWithoutWorkflowEventSettingInput;
};

export type InformAutomationCampaignUpsertWithoutInformCampaignInput = {
  update: InformAutomationCampaignUncheckedUpdateWithoutInformCampaignInput;
  create: InformAutomationCampaignUncheckedCreateWithoutInformCampaignInput;
};

export type InformAutomationCampaignWhereInput = {
  AND?: Maybe<Array<InformAutomationCampaignWhereInput>>;
  OR?: Maybe<Array<InformAutomationCampaignWhereInput>>;
  NOT?: Maybe<Array<InformAutomationCampaignWhereInput>>;
  id?: Maybe<StringFilter>;
  workflowEventSettingId?: Maybe<StringFilter>;
  workflowEventSetting?: Maybe<WorkflowEventSettingWhereInput>;
  informCampaign?: Maybe<InformCampaignWhereInput>;
};

export type InformAutomationCampaignWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformCampaign = {
  __typename?: 'InformCampaign';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  informPartnerProgramId: Scalars['String'];
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status: InformCampaignStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition: InformAudienceDefinition;
  informPartnerProgram: InformPartnerProgram;
  informCampaignGroup?: Maybe<InformCampaignGroup>;
  informScheduledCampaign?: Maybe<InformScheduledCampaign>;
  informCampaignEnrollments: Array<InformCampaignEnrollment>;
  informCampaignExecutions: Array<InformCampaignExecution>;
  informAutomationCampaign?: Maybe<InformAutomationCampaign>;
  _count: InformCampaignCountOutputType;
};


export type InformCampaignInformCampaignEnrollmentsArgs = {
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
  orderBy?: Maybe<InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformCampaignEnrollmentScalarFieldEnum>;
};


export type InformCampaignInformCampaignExecutionsArgs = {
  where?: Maybe<InformCampaignExecutionWhereInput>;
  orderBy?: Maybe<InformCampaignExecutionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformCampaignExecutionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformCampaignExecutionScalarFieldEnum>;
};

export type InformCampaignCountAggregateOutputType = {
  __typename?: 'InformCampaignCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  informPartnerProgramId: Scalars['Int'];
  informCampaignGroupId: Scalars['Int'];
  informAudienceDefinitionId: Scalars['Int'];
  type: Scalars['Int'];
  name: Scalars['Int'];
  displayName: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformCampaignCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  informCampaignGroupId?: Maybe<SortOrder>;
  informAudienceDefinitionId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
};

export type InformCampaignCountOutputType = {
  __typename?: 'InformCampaignCountOutputType';
  informCampaignEnrollments: Scalars['Int'];
  informCampaignExecutions: Scalars['Int'];
};

export type InformCampaignCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition: InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput;
  informCampaignGroup?: Maybe<InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput>;
};

export type InformCampaignCreateManyInformAudienceDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId: Scalars['String'];
  informCampaignGroupId?: Maybe<Scalars['String']>;
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
};

export type InformCampaignCreateManyInformAudienceDefinitionInputEnvelope = {
  data: Array<InformCampaignCreateManyInformAudienceDefinitionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignCreateManyInformCampaignGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId: Scalars['String'];
  informAudienceDefinitionId: Scalars['String'];
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
};

export type InformCampaignCreateManyInformCampaignGroupInputEnvelope = {
  data: Array<InformCampaignCreateManyInformCampaignGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignCreateManyInformPartnerProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
};

export type InformCampaignCreateManyInformPartnerProgramInputEnvelope = {
  data: Array<InformCampaignCreateManyInformPartnerProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId: Scalars['String'];
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
};

export type InformCampaignCreateNestedManyWithoutInformAudienceDefinitionInput = {
  create?: Maybe<Array<InformCampaignCreateWithoutInformAudienceDefinitionInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformAudienceDefinitionInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformAudienceDefinitionInputEnvelope>;
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
};

export type InformCampaignCreateNestedManyWithoutInformCampaignGroupInput = {
  create?: Maybe<Array<InformCampaignCreateWithoutInformCampaignGroupInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformCampaignGroupInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformCampaignGroupInputEnvelope>;
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
};

export type InformCampaignCreateNestedManyWithoutInformPartnerProgramInput = {
  create?: Maybe<Array<InformCampaignCreateWithoutInformPartnerProgramInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformPartnerProgramInputEnvelope>;
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
};

export type InformCampaignCreateNestedOneWithoutInformAutomationCampaignInput = {
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformAutomationCampaignInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformAutomationCampaignInput>;
  connect?: Maybe<InformCampaignWhereUniqueInput>;
};

export type InformCampaignCreateNestedOneWithoutInformCampaignEnrollmentsInput = {
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformCampaignEnrollmentsInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformCampaignEnrollmentsInput>;
  connect?: Maybe<InformCampaignWhereUniqueInput>;
};

export type InformCampaignCreateNestedOneWithoutInformCampaignExecutionsInput = {
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformCampaignExecutionsInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformCampaignExecutionsInput>;
  connect?: Maybe<InformCampaignWhereUniqueInput>;
};

export type InformCampaignCreateNestedOneWithoutInformScheduledCampaignInput = {
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformScheduledCampaignInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformScheduledCampaignInput>;
  connect?: Maybe<InformCampaignWhereUniqueInput>;
};

export type InformCampaignCreateOrConnectWithoutInformAudienceDefinitionInput = {
  where: InformCampaignWhereUniqueInput;
  create: InformCampaignUncheckedCreateWithoutInformAudienceDefinitionInput;
};

export type InformCampaignCreateOrConnectWithoutInformAutomationCampaignInput = {
  where: InformCampaignWhereUniqueInput;
  create: InformCampaignUncheckedCreateWithoutInformAutomationCampaignInput;
};

export type InformCampaignCreateOrConnectWithoutInformCampaignEnrollmentsInput = {
  where: InformCampaignWhereUniqueInput;
  create: InformCampaignUncheckedCreateWithoutInformCampaignEnrollmentsInput;
};

export type InformCampaignCreateOrConnectWithoutInformCampaignExecutionsInput = {
  where: InformCampaignWhereUniqueInput;
  create: InformCampaignUncheckedCreateWithoutInformCampaignExecutionsInput;
};

export type InformCampaignCreateOrConnectWithoutInformCampaignGroupInput = {
  where: InformCampaignWhereUniqueInput;
  create: InformCampaignUncheckedCreateWithoutInformCampaignGroupInput;
};

export type InformCampaignCreateOrConnectWithoutInformPartnerProgramInput = {
  where: InformCampaignWhereUniqueInput;
  create: InformCampaignUncheckedCreateWithoutInformPartnerProgramInput;
};

export type InformCampaignCreateOrConnectWithoutInformScheduledCampaignInput = {
  where: InformCampaignWhereUniqueInput;
  create: InformCampaignUncheckedCreateWithoutInformScheduledCampaignInput;
};

export type InformCampaignCreateWithoutInformAudienceDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput;
  informCampaignGroup?: Maybe<InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput>;
};

export type InformCampaignCreateWithoutInformAutomationCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition: InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput;
  informCampaignGroup?: Maybe<InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput>;
};

export type InformCampaignCreateWithoutInformCampaignEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition: InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput;
  informCampaignGroup?: Maybe<InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput>;
};

export type InformCampaignCreateWithoutInformCampaignExecutionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition: InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput;
  informCampaignGroup?: Maybe<InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput>;
};

export type InformCampaignCreateWithoutInformCampaignGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition: InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput;
  informScheduledCampaign?: Maybe<InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput>;
};

export type InformCampaignCreateWithoutInformPartnerProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition: InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput;
  informCampaignGroup?: Maybe<InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput>;
};

export type InformCampaignCreateWithoutInformScheduledCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition: InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput;
  informCampaignGroup?: Maybe<InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput>;
};

export type InformCampaignEnrollment = {
  __typename?: 'InformCampaignEnrollment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollmentId: Scalars['String'];
  informCampaignId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollment: InformPartnerProgramEnrollment;
  informCampaign: InformCampaign;
  informCampaignEnrollmentCanonicalServices: Array<InformCampaignEnrollmentCanonicalService>;
  _count: InformCampaignEnrollmentCountOutputType;
};


export type InformCampaignEnrollmentInformCampaignEnrollmentCanonicalServicesArgs = {
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
  orderBy?: Maybe<InformCampaignEnrollmentCanonicalServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformCampaignEnrollmentCanonicalServiceScalarFieldEnum>;
};

export type InformCampaignEnrollmentCanonicalService = {
  __typename?: 'InformCampaignEnrollmentCanonicalService';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  informCampaignEnrollmentId: Scalars['String'];
  canonicalServiceId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollment: InformCampaignEnrollment;
  canonicalService: CanonicalService;
};

export type InformCampaignEnrollmentCanonicalServiceCountAggregateOutputType = {
  __typename?: 'InformCampaignEnrollmentCanonicalServiceCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  informCampaignEnrollmentId: Scalars['Int'];
  canonicalServiceId: Scalars['Int'];
  onlinePharmacyUrl: Scalars['Int'];
  settings: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformCampaignEnrollmentCanonicalServiceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informCampaignEnrollmentId?: Maybe<SortOrder>;
  canonicalServiceId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollment: InformCampaignEnrollmentCreateNestedOneWithoutInformCampaignEnrollmentCanonicalServicesInput;
  canonicalService: CanonicalServiceCreateNestedOneWithoutInformCampaignEnrollmentCanonicalServicesInput;
};

export type InformCampaignEnrollmentCanonicalServiceCreateManyCanonicalServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignEnrollmentId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateManyCanonicalServiceInputEnvelope = {
  data: Array<InformCampaignEnrollmentCanonicalServiceCreateManyCanonicalServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateManyInformCampaignEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  canonicalServiceId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateManyInformCampaignEnrollmentInputEnvelope = {
  data: Array<InformCampaignEnrollmentCanonicalServiceCreateManyInformCampaignEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignEnrollmentId: Scalars['String'];
  canonicalServiceId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutCanonicalServiceInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutCanonicalServiceInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyCanonicalServiceInputEnvelope>;
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutInformCampaignEnrollmentInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutInformCampaignEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyInformCampaignEnrollmentInputEnvelope>;
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutCanonicalServiceInput = {
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
  create: InformCampaignEnrollmentCanonicalServiceUncheckedCreateWithoutCanonicalServiceInput;
};

export type InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentInput = {
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
  create: InformCampaignEnrollmentCanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentInput;
};

export type InformCampaignEnrollmentCanonicalServiceCreateWithoutCanonicalServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollment: InformCampaignEnrollmentCreateNestedOneWithoutInformCampaignEnrollmentCanonicalServicesInput;
};

export type InformCampaignEnrollmentCanonicalServiceCreateWithoutInformCampaignEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  canonicalService: CanonicalServiceCreateNestedOneWithoutInformCampaignEnrollmentCanonicalServicesInput;
};

export type InformCampaignEnrollmentCanonicalServiceInformCampaignEnrollmentIdCanonicalServiceIdCompoundUniqueInput = {
  informCampaignEnrollmentId: Scalars['String'];
  canonicalServiceId: Scalars['String'];
};

export type InformCampaignEnrollmentCanonicalServiceListRelationFilter = {
  every?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
  some?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
  none?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
};

export type InformCampaignEnrollmentCanonicalServiceMaxAggregateOutputType = {
  __typename?: 'InformCampaignEnrollmentCanonicalServiceMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignEnrollmentId?: Maybe<Scalars['String']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
};

export type InformCampaignEnrollmentCanonicalServiceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informCampaignEnrollmentId?: Maybe<SortOrder>;
  canonicalServiceId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentCanonicalServiceMinAggregateOutputType = {
  __typename?: 'InformCampaignEnrollmentCanonicalServiceMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignEnrollmentId?: Maybe<Scalars['String']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
};

export type InformCampaignEnrollmentCanonicalServiceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informCampaignEnrollmentId?: Maybe<SortOrder>;
  canonicalServiceId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentCanonicalServiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformCampaignEnrollmentCanonicalServiceOrderByRelevanceFieldEnum {
  Id = 'id',
  InformCampaignEnrollmentId = 'informCampaignEnrollmentId',
  CanonicalServiceId = 'canonicalServiceId',
  OnlinePharmacyUrl = 'onlinePharmacyUrl'
}

export type InformCampaignEnrollmentCanonicalServiceOrderByRelevanceInput = {
  fields: Array<InformCampaignEnrollmentCanonicalServiceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformCampaignEnrollmentCanonicalServiceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informCampaignEnrollmentId?: Maybe<SortOrder>;
  canonicalServiceId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  _count?: Maybe<InformCampaignEnrollmentCanonicalServiceCountOrderByAggregateInput>;
  _max?: Maybe<InformCampaignEnrollmentCanonicalServiceMaxOrderByAggregateInput>;
  _min?: Maybe<InformCampaignEnrollmentCanonicalServiceMinOrderByAggregateInput>;
};

export type InformCampaignEnrollmentCanonicalServiceOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informCampaignEnrollmentId?: Maybe<SortOrder>;
  canonicalServiceId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  informCampaignEnrollment?: Maybe<InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  canonicalService?: Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<InformCampaignEnrollmentCanonicalServiceOrderByRelevanceInput>;
};

export enum InformCampaignEnrollmentCanonicalServiceScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InformCampaignEnrollmentId = 'informCampaignEnrollmentId',
  CanonicalServiceId = 'canonicalServiceId',
  OnlinePharmacyUrl = 'onlinePharmacyUrl',
  Settings = 'settings'
}

export type InformCampaignEnrollmentCanonicalServiceScalarWhereInput = {
  AND?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereInput>>;
  OR?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereInput>>;
  NOT?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informCampaignEnrollmentId?: Maybe<StringFilter>;
  canonicalServiceId?: Maybe<StringFilter>;
  onlinePharmacyUrl?: Maybe<StringNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
};

export type InformCampaignEnrollmentCanonicalServiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  informCampaignEnrollmentId?: Maybe<StringWithAggregatesFilter>;
  canonicalServiceId?: Maybe<StringWithAggregatesFilter>;
  onlinePharmacyUrl?: Maybe<StringNullableWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignEnrollmentId: Scalars['String'];
  canonicalServiceId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutCanonicalServiceInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyCanonicalServiceInputEnvelope>;
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutInformCampaignEnrollmentInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutInformCampaignEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyInformCampaignEnrollmentInputEnvelope>;
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedCreateWithoutCanonicalServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignEnrollmentId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  canonicalServiceId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignEnrollmentId?: Maybe<Scalars['String']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignEnrollmentId?: Maybe<Scalars['String']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutCanonicalServiceInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpsertWithWhereUniqueWithoutCanonicalServiceInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyCanonicalServiceInputEnvelope>;
  set?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateWithWhereUniqueWithoutCanonicalServiceInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateManyWithWhereWithoutCanonicalServiceInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereInput>>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignEnrollmentId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutInformCampaignEnrollmentNestedInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutInformCampaignEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpsertWithWhereUniqueWithoutInformCampaignEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyInformCampaignEnrollmentInputEnvelope>;
  set?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateWithWhereUniqueWithoutInformCampaignEnrollmentInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateManyWithWhereWithoutInformCampaignEnrollmentInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereInput>>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedUpdateWithoutCanonicalServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignEnrollmentId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedUpdateWithoutInformCampaignEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollment?: Maybe<InformCampaignEnrollmentUpdateOneRequiredWithoutInformCampaignEnrollmentCanonicalServicesNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutInformCampaignEnrollmentCanonicalServicesNestedInput>;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateManyWithWhereWithoutCanonicalServiceInput = {
  where: InformCampaignEnrollmentCanonicalServiceScalarWhereInput;
  data: InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutInformCampaignEnrollmentCanonicalServicesInput;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateManyWithWhereWithoutInformCampaignEnrollmentInput = {
  where: InformCampaignEnrollmentCanonicalServiceScalarWhereInput;
  data: InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutInformCampaignEnrollmentCanonicalServicesInput;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutCanonicalServiceNestedInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutCanonicalServiceInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpsertWithWhereUniqueWithoutCanonicalServiceInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyCanonicalServiceInputEnvelope>;
  set?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateWithWhereUniqueWithoutCanonicalServiceInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateManyWithWhereWithoutCanonicalServiceInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereInput>>;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutInformCampaignEnrollmentNestedInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutInformCampaignEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpsertWithWhereUniqueWithoutInformCampaignEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyInformCampaignEnrollmentInputEnvelope>;
  set?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateWithWhereUniqueWithoutInformCampaignEnrollmentInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateManyWithWhereWithoutInformCampaignEnrollmentInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereInput>>;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateWithWhereUniqueWithoutCanonicalServiceInput = {
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
  data: InformCampaignEnrollmentCanonicalServiceUncheckedUpdateWithoutCanonicalServiceInput;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateWithWhereUniqueWithoutInformCampaignEnrollmentInput = {
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
  data: InformCampaignEnrollmentCanonicalServiceUncheckedUpdateWithoutInformCampaignEnrollmentInput;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateWithoutCanonicalServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollment?: Maybe<InformCampaignEnrollmentUpdateOneRequiredWithoutInformCampaignEnrollmentCanonicalServicesNestedInput>;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateWithoutInformCampaignEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutInformCampaignEnrollmentCanonicalServicesNestedInput>;
};

export type InformCampaignEnrollmentCanonicalServiceUpsertWithWhereUniqueWithoutCanonicalServiceInput = {
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
  update: InformCampaignEnrollmentCanonicalServiceUncheckedUpdateWithoutCanonicalServiceInput;
  create: InformCampaignEnrollmentCanonicalServiceUncheckedCreateWithoutCanonicalServiceInput;
};

export type InformCampaignEnrollmentCanonicalServiceUpsertWithWhereUniqueWithoutInformCampaignEnrollmentInput = {
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
  update: InformCampaignEnrollmentCanonicalServiceUncheckedUpdateWithoutInformCampaignEnrollmentInput;
  create: InformCampaignEnrollmentCanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentInput;
};

export type InformCampaignEnrollmentCanonicalServiceWhereInput = {
  AND?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereInput>>;
  OR?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereInput>>;
  NOT?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informCampaignEnrollmentId?: Maybe<StringFilter>;
  canonicalServiceId?: Maybe<StringFilter>;
  onlinePharmacyUrl?: Maybe<StringNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  informCampaignEnrollment?: Maybe<InformCampaignEnrollmentWhereInput>;
  canonicalService?: Maybe<CanonicalServiceWhereInput>;
};

export type InformCampaignEnrollmentCanonicalServiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentId_canonicalServiceId?: Maybe<InformCampaignEnrollmentCanonicalServiceInformCampaignEnrollmentIdCanonicalServiceIdCompoundUniqueInput>;
};

export type InformCampaignEnrollmentCountAggregateOutputType = {
  __typename?: 'InformCampaignEnrollmentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  informPartnerProgramEnrollmentId: Scalars['Int'];
  informCampaignId: Scalars['Int'];
  settings: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformCampaignEnrollmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  informPartnerProgramEnrollmentId?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentCountOutputType = {
  __typename?: 'InformCampaignEnrollmentCountOutputType';
  informCampaignEnrollmentCanonicalServices: Scalars['Int'];
};

export type InformCampaignEnrollmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollment: InformPartnerProgramEnrollmentCreateNestedOneWithoutInformCampaignEnrollmentsInput;
  informCampaign: InformCampaignCreateNestedOneWithoutInformCampaignEnrollmentsInput;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutInformCampaignEnrollmentInput>;
};

export type InformCampaignEnrollmentCreateManyInformCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollmentId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCreateManyInformCampaignInputEnvelope = {
  data: Array<InformCampaignEnrollmentCreateManyInformCampaignInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignEnrollmentCreateManyInformPartnerProgramEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  informCampaignId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCreateManyInformPartnerProgramEnrollmentInputEnvelope = {
  data: Array<InformCampaignEnrollmentCreateManyInformPartnerProgramEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignEnrollmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollmentId: Scalars['String'];
  informCampaignId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformCampaignInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformCampaignInputEnvelope>;
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
};

export type InformCampaignEnrollmentCreateNestedManyWithoutInformPartnerProgramEnrollmentInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformPartnerProgramEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformPartnerProgramEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformPartnerProgramEnrollmentInputEnvelope>;
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
};

export type InformCampaignEnrollmentCreateNestedOneWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  create?: Maybe<InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  connectOrCreate?: Maybe<InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  connect?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
};

export type InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  where: InformCampaignEnrollmentWhereUniqueInput;
  create: InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput;
};

export type InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignInput = {
  where: InformCampaignEnrollmentWhereUniqueInput;
  create: InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignInput;
};

export type InformCampaignEnrollmentCreateOrConnectWithoutInformPartnerProgramEnrollmentInput = {
  where: InformCampaignEnrollmentWhereUniqueInput;
  create: InformCampaignEnrollmentUncheckedCreateWithoutInformPartnerProgramEnrollmentInput;
};

export type InformCampaignEnrollmentCreateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollment: InformPartnerProgramEnrollmentCreateNestedOneWithoutInformCampaignEnrollmentsInput;
  informCampaign: InformCampaignCreateNestedOneWithoutInformCampaignEnrollmentsInput;
};

export type InformCampaignEnrollmentCreateWithoutInformCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollment: InformPartnerProgramEnrollmentCreateNestedOneWithoutInformCampaignEnrollmentsInput;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutInformCampaignEnrollmentInput>;
};

export type InformCampaignEnrollmentCreateWithoutInformPartnerProgramEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaign: InformCampaignCreateNestedOneWithoutInformCampaignEnrollmentsInput;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutInformCampaignEnrollmentInput>;
};

export type InformCampaignEnrollmentInformPartnerProgramEnrollmentIdInformCampaignIdCompoundUniqueInput = {
  informPartnerProgramEnrollmentId: Scalars['String'];
  informCampaignId: Scalars['String'];
};

export type InformCampaignEnrollmentListRelationFilter = {
  every?: Maybe<InformCampaignEnrollmentWhereInput>;
  some?: Maybe<InformCampaignEnrollmentWhereInput>;
  none?: Maybe<InformCampaignEnrollmentWhereInput>;
};

export type InformCampaignEnrollmentMaxAggregateOutputType = {
  __typename?: 'InformCampaignEnrollmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  informPartnerProgramEnrollmentId?: Maybe<Scalars['String']>;
  informCampaignId?: Maybe<Scalars['String']>;
};

export type InformCampaignEnrollmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  informPartnerProgramEnrollmentId?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentMinAggregateOutputType = {
  __typename?: 'InformCampaignEnrollmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  informPartnerProgramEnrollmentId?: Maybe<Scalars['String']>;
  informCampaignId?: Maybe<Scalars['String']>;
};

export type InformCampaignEnrollmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  informPartnerProgramEnrollmentId?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformCampaignEnrollmentOrderByRelevanceFieldEnum {
  Id = 'id',
  InformPartnerProgramEnrollmentId = 'informPartnerProgramEnrollmentId',
  InformCampaignId = 'informCampaignId'
}

export type InformCampaignEnrollmentOrderByRelevanceInput = {
  fields: Array<InformCampaignEnrollmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformCampaignEnrollmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  informPartnerProgramEnrollmentId?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  _count?: Maybe<InformCampaignEnrollmentCountOrderByAggregateInput>;
  _max?: Maybe<InformCampaignEnrollmentMaxOrderByAggregateInput>;
  _min?: Maybe<InformCampaignEnrollmentMinOrderByAggregateInput>;
};

export type InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  informPartnerProgramEnrollmentId?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  informPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  informCampaign?: Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceOrderByRelationAggregateInput>;
  _relevance?: Maybe<InformCampaignEnrollmentOrderByRelevanceInput>;
};

export type InformCampaignEnrollmentRelationFilter = {
  is?: Maybe<InformCampaignEnrollmentWhereInput>;
  isNot?: Maybe<InformCampaignEnrollmentWhereInput>;
};

export enum InformCampaignEnrollmentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  StatusMessage = 'statusMessage',
  InformPartnerProgramEnrollmentId = 'informPartnerProgramEnrollmentId',
  InformCampaignId = 'informCampaignId',
  Settings = 'settings'
}

export type InformCampaignEnrollmentScalarWhereInput = {
  AND?: Maybe<Array<InformCampaignEnrollmentScalarWhereInput>>;
  OR?: Maybe<Array<InformCampaignEnrollmentScalarWhereInput>>;
  NOT?: Maybe<Array<InformCampaignEnrollmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumInformEnrollmentStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  informPartnerProgramEnrollmentId?: Maybe<StringFilter>;
  informCampaignId?: Maybe<StringFilter>;
  settings?: Maybe<JsonNullableFilter>;
};

export type InformCampaignEnrollmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformCampaignEnrollmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformCampaignEnrollmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformCampaignEnrollmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumInformEnrollmentStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
  informPartnerProgramEnrollmentId?: Maybe<StringWithAggregatesFilter>;
  informCampaignId?: Maybe<StringWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type InformCampaignEnrollmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollmentId: Scalars['String'];
  informCampaignId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutInformCampaignEnrollmentInput>;
};

export type InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformCampaignInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformCampaignInputEnvelope>;
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
};

export type InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramEnrollmentInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformPartnerProgramEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformPartnerProgramEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformPartnerProgramEnrollmentInputEnvelope>;
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
};

export type InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollmentId: Scalars['String'];
  informCampaignId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollmentId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutInformCampaignEnrollmentInput>;
};

export type InformCampaignEnrollmentUncheckedCreateWithoutInformPartnerProgramEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  informCampaignId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutInformCampaignEnrollmentInput>;
};

export type InformCampaignEnrollmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollmentId?: Maybe<Scalars['String']>;
  informCampaignId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutInformCampaignEnrollmentNestedInput>;
};

export type InformCampaignEnrollmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollmentId?: Maybe<Scalars['String']>;
  informCampaignId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollmentId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformCampaignInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentUpsertWithWhereUniqueWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformCampaignInputEnvelope>;
  set?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentUpdateWithWhereUniqueWithoutInformCampaignInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentUpdateManyWithWhereWithoutInformCampaignInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentScalarWhereInput>>;
};

export type InformCampaignEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformPartnerProgramEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformPartnerProgramEnrollmentInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentUpsertWithWhereUniqueWithoutInformPartnerProgramEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformPartnerProgramEnrollmentInputEnvelope>;
  set?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentUpdateWithWhereUniqueWithoutInformPartnerProgramEnrollmentInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentUpdateManyWithWhereWithoutInformPartnerProgramEnrollmentInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentScalarWhereInput>>;
};

export type InformCampaignEnrollmentUncheckedUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollmentId?: Maybe<Scalars['String']>;
  informCampaignId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentUncheckedUpdateWithoutInformCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollmentId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutInformCampaignEnrollmentNestedInput>;
};

export type InformCampaignEnrollmentUncheckedUpdateWithoutInformPartnerProgramEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informCampaignId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutInformCampaignEnrollmentNestedInput>;
};

export type InformCampaignEnrollmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollmentUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutInformCampaignEnrollmentNestedInput>;
};

export type InformCampaignEnrollmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignEnrollmentUpdateManyWithWhereWithoutInformCampaignInput = {
  where: InformCampaignEnrollmentScalarWhereInput;
  data: InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignEnrollmentsInput;
};

export type InformCampaignEnrollmentUpdateManyWithWhereWithoutInformPartnerProgramEnrollmentInput = {
  where: InformCampaignEnrollmentScalarWhereInput;
  data: InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignEnrollmentsInput;
};

export type InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformCampaignInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentUpsertWithWhereUniqueWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformCampaignInputEnvelope>;
  set?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentUpdateWithWhereUniqueWithoutInformCampaignInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentUpdateManyWithWhereWithoutInformCampaignInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentScalarWhereInput>>;
};

export type InformCampaignEnrollmentUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput = {
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformPartnerProgramEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformPartnerProgramEnrollmentInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentUpsertWithWhereUniqueWithoutInformPartnerProgramEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformPartnerProgramEnrollmentInputEnvelope>;
  set?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentUpdateWithWhereUniqueWithoutInformPartnerProgramEnrollmentInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentUpdateManyWithWhereWithoutInformPartnerProgramEnrollmentInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentScalarWhereInput>>;
};

export type InformCampaignEnrollmentUpdateOneRequiredWithoutInformCampaignEnrollmentCanonicalServicesNestedInput = {
  create?: Maybe<InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  connectOrCreate?: Maybe<InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  upsert?: Maybe<InformCampaignEnrollmentUpsertWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  connect?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  update?: Maybe<InformCampaignEnrollmentUncheckedUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput>;
};

export type InformCampaignEnrollmentUpdateWithWhereUniqueWithoutInformCampaignInput = {
  where: InformCampaignEnrollmentWhereUniqueInput;
  data: InformCampaignEnrollmentUncheckedUpdateWithoutInformCampaignInput;
};

export type InformCampaignEnrollmentUpdateWithWhereUniqueWithoutInformPartnerProgramEnrollmentInput = {
  where: InformCampaignEnrollmentWhereUniqueInput;
  data: InformCampaignEnrollmentUncheckedUpdateWithoutInformPartnerProgramEnrollmentInput;
};

export type InformCampaignEnrollmentUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollmentUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput>;
};

export type InformCampaignEnrollmentUpdateWithoutInformCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollmentUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutInformCampaignEnrollmentNestedInput>;
};

export type InformCampaignEnrollmentUpdateWithoutInformPartnerProgramEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutInformCampaignEnrollmentNestedInput>;
};

export type InformCampaignEnrollmentUpsertWithWhereUniqueWithoutInformCampaignInput = {
  where: InformCampaignEnrollmentWhereUniqueInput;
  update: InformCampaignEnrollmentUncheckedUpdateWithoutInformCampaignInput;
  create: InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignInput;
};

export type InformCampaignEnrollmentUpsertWithWhereUniqueWithoutInformPartnerProgramEnrollmentInput = {
  where: InformCampaignEnrollmentWhereUniqueInput;
  update: InformCampaignEnrollmentUncheckedUpdateWithoutInformPartnerProgramEnrollmentInput;
  create: InformCampaignEnrollmentUncheckedCreateWithoutInformPartnerProgramEnrollmentInput;
};

export type InformCampaignEnrollmentUpsertWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  update: InformCampaignEnrollmentUncheckedUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput;
  create: InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput;
};

export type InformCampaignEnrollmentWhereInput = {
  AND?: Maybe<Array<InformCampaignEnrollmentWhereInput>>;
  OR?: Maybe<Array<InformCampaignEnrollmentWhereInput>>;
  NOT?: Maybe<Array<InformCampaignEnrollmentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumInformEnrollmentStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  informPartnerProgramEnrollmentId?: Maybe<StringFilter>;
  informCampaignId?: Maybe<StringFilter>;
  settings?: Maybe<JsonNullableFilter>;
  informPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  informCampaign?: Maybe<InformCampaignWhereInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceListRelationFilter>;
};

export type InformCampaignEnrollmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentId_informCampaignId?: Maybe<InformCampaignEnrollmentInformPartnerProgramEnrollmentIdInformCampaignIdCompoundUniqueInput>;
};

export type InformCampaignExecution = {
  __typename?: 'InformCampaignExecution';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  informCampaignId: Scalars['String'];
  executeAt: Scalars['DateTime'];
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaign: InformCampaign;
  emailCampaigns: Array<EmailCampaign>;
  _count: InformCampaignExecutionCountOutputType;
};


export type InformCampaignExecutionEmailCampaignsArgs = {
  where?: Maybe<EmailCampaignWhereInput>;
  orderBy?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignScalarFieldEnum>;
};

export type InformCampaignExecutionCountAggregateOutputType = {
  __typename?: 'InformCampaignExecutionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  informCampaignId: Scalars['Int'];
  executeAt: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  settings: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformCampaignExecutionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
};

export type InformCampaignExecutionCountOutputType = {
  __typename?: 'InformCampaignExecutionCountOutputType';
  emailCampaigns: Scalars['Int'];
};

export type InformCampaignExecutionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  executeAt: Scalars['DateTime'];
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaign: InformCampaignCreateNestedOneWithoutInformCampaignExecutionsInput;
  emailCampaigns?: Maybe<EmailCampaignCreateNestedManyWithoutInformCampaignExecutionInput>;
};

export type InformCampaignExecutionCreateManyInformCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  executeAt: Scalars['DateTime'];
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignExecutionCreateManyInformCampaignInputEnvelope = {
  data: Array<InformCampaignExecutionCreateManyInformCampaignInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignExecutionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignId: Scalars['String'];
  executeAt: Scalars['DateTime'];
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput = {
  create?: Maybe<Array<InformCampaignExecutionCreateWithoutInformCampaignInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignExecutionCreateOrConnectWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignExecutionCreateManyInformCampaignInputEnvelope>;
  connect?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
};

export type InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput = {
  create?: Maybe<InformCampaignExecutionUncheckedCreateWithoutEmailCampaignsInput>;
  connectOrCreate?: Maybe<InformCampaignExecutionCreateOrConnectWithoutEmailCampaignsInput>;
  connect?: Maybe<InformCampaignExecutionWhereUniqueInput>;
};

export type InformCampaignExecutionCreateOrConnectWithoutEmailCampaignsInput = {
  where: InformCampaignExecutionWhereUniqueInput;
  create: InformCampaignExecutionUncheckedCreateWithoutEmailCampaignsInput;
};

export type InformCampaignExecutionCreateOrConnectWithoutInformCampaignInput = {
  where: InformCampaignExecutionWhereUniqueInput;
  create: InformCampaignExecutionUncheckedCreateWithoutInformCampaignInput;
};

export type InformCampaignExecutionCreateWithoutEmailCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  executeAt: Scalars['DateTime'];
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaign: InformCampaignCreateNestedOneWithoutInformCampaignExecutionsInput;
};

export type InformCampaignExecutionCreateWithoutInformCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  executeAt: Scalars['DateTime'];
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  emailCampaigns?: Maybe<EmailCampaignCreateNestedManyWithoutInformCampaignExecutionInput>;
};

export type InformCampaignExecutionListRelationFilter = {
  every?: Maybe<InformCampaignExecutionWhereInput>;
  some?: Maybe<InformCampaignExecutionWhereInput>;
  none?: Maybe<InformCampaignExecutionWhereInput>;
};

export type InformCampaignExecutionMaxAggregateOutputType = {
  __typename?: 'InformCampaignExecutionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignId?: Maybe<Scalars['String']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformCampaignExecutionStatus>;
};

export type InformCampaignExecutionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type InformCampaignExecutionMinAggregateOutputType = {
  __typename?: 'InformCampaignExecutionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignId?: Maybe<Scalars['String']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformCampaignExecutionStatus>;
};

export type InformCampaignExecutionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type InformCampaignExecutionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformCampaignExecutionOrderByRelevanceFieldEnum {
  Id = 'id',
  InformCampaignId = 'informCampaignId'
}

export type InformCampaignExecutionOrderByRelevanceInput = {
  fields: Array<InformCampaignExecutionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformCampaignExecutionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  _count?: Maybe<InformCampaignExecutionCountOrderByAggregateInput>;
  _max?: Maybe<InformCampaignExecutionMaxOrderByAggregateInput>;
  _min?: Maybe<InformCampaignExecutionMinOrderByAggregateInput>;
};

export type InformCampaignExecutionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  informCampaign?: Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>;
  emailCampaigns?: Maybe<EmailCampaignOrderByRelationAggregateInput>;
  _relevance?: Maybe<InformCampaignExecutionOrderByRelevanceInput>;
};

export type InformCampaignExecutionRelationFilter = {
  is?: Maybe<InformCampaignExecutionWhereInput>;
  isNot?: Maybe<InformCampaignExecutionWhereInput>;
};

export enum InformCampaignExecutionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InformCampaignId = 'informCampaignId',
  ExecuteAt = 'executeAt',
  Status = 'status',
  StatusMessage = 'statusMessage',
  Settings = 'settings'
}

export type InformCampaignExecutionScalarWhereInput = {
  AND?: Maybe<Array<InformCampaignExecutionScalarWhereInput>>;
  OR?: Maybe<Array<InformCampaignExecutionScalarWhereInput>>;
  NOT?: Maybe<Array<InformCampaignExecutionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informCampaignId?: Maybe<StringFilter>;
  executeAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumInformCampaignExecutionStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
};

export type InformCampaignExecutionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformCampaignExecutionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformCampaignExecutionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformCampaignExecutionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  informCampaignId?: Maybe<StringWithAggregatesFilter>;
  executeAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumInformCampaignExecutionStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum InformCampaignExecutionStatus {
  Scheduled = 'Scheduled',
  Running = 'Running',
  Completed = 'Completed',
  Failed = 'Failed',
  Skipped = 'Skipped'
}

export type InformCampaignExecutionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignId: Scalars['String'];
  executeAt: Scalars['DateTime'];
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  emailCampaigns?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutInformCampaignExecutionInput>;
};

export type InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput = {
  create?: Maybe<Array<InformCampaignExecutionCreateWithoutInformCampaignInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignExecutionCreateOrConnectWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignExecutionCreateManyInformCampaignInputEnvelope>;
  connect?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
};

export type InformCampaignExecutionUncheckedCreateWithoutEmailCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignId: Scalars['String'];
  executeAt: Scalars['DateTime'];
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignExecutionUncheckedCreateWithoutInformCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  executeAt: Scalars['DateTime'];
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  emailCampaigns?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutInformCampaignExecutionInput>;
};

export type InformCampaignExecutionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignId?: Maybe<Scalars['String']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  emailCampaigns?: Maybe<EmailCampaignUncheckedUpdateManyWithoutInformCampaignExecutionNestedInput>;
};

export type InformCampaignExecutionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignId?: Maybe<Scalars['String']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignExecutionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput = {
  create?: Maybe<Array<InformCampaignExecutionCreateWithoutInformCampaignInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignExecutionCreateOrConnectWithoutInformCampaignInput>>;
  upsert?: Maybe<Array<InformCampaignExecutionUpsertWithWhereUniqueWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignExecutionCreateManyInformCampaignInputEnvelope>;
  set?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignExecutionUpdateWithWhereUniqueWithoutInformCampaignInput>>;
  updateMany?: Maybe<Array<InformCampaignExecutionUpdateManyWithWhereWithoutInformCampaignInput>>;
  deleteMany?: Maybe<Array<InformCampaignExecutionScalarWhereInput>>;
};

export type InformCampaignExecutionUncheckedUpdateWithoutEmailCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignId?: Maybe<Scalars['String']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignExecutionUncheckedUpdateWithoutInformCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  emailCampaigns?: Maybe<EmailCampaignUncheckedUpdateManyWithoutInformCampaignExecutionNestedInput>;
};

export type InformCampaignExecutionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformCampaignExecutionsNestedInput>;
  emailCampaigns?: Maybe<EmailCampaignUpdateManyWithoutInformCampaignExecutionNestedInput>;
};

export type InformCampaignExecutionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformCampaignExecutionUpdateManyWithWhereWithoutInformCampaignInput = {
  where: InformCampaignExecutionScalarWhereInput;
  data: InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignExecutionsInput;
};

export type InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput = {
  create?: Maybe<Array<InformCampaignExecutionCreateWithoutInformCampaignInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignExecutionCreateOrConnectWithoutInformCampaignInput>>;
  upsert?: Maybe<Array<InformCampaignExecutionUpsertWithWhereUniqueWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignExecutionCreateManyInformCampaignInputEnvelope>;
  set?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignExecutionUpdateWithWhereUniqueWithoutInformCampaignInput>>;
  updateMany?: Maybe<Array<InformCampaignExecutionUpdateManyWithWhereWithoutInformCampaignInput>>;
  deleteMany?: Maybe<Array<InformCampaignExecutionScalarWhereInput>>;
};

export type InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput = {
  create?: Maybe<InformCampaignExecutionUncheckedCreateWithoutEmailCampaignsInput>;
  connectOrCreate?: Maybe<InformCampaignExecutionCreateOrConnectWithoutEmailCampaignsInput>;
  upsert?: Maybe<InformCampaignExecutionUpsertWithoutEmailCampaignsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformCampaignExecutionWhereUniqueInput>;
  update?: Maybe<InformCampaignExecutionUncheckedUpdateWithoutEmailCampaignsInput>;
};

export type InformCampaignExecutionUpdateWithWhereUniqueWithoutInformCampaignInput = {
  where: InformCampaignExecutionWhereUniqueInput;
  data: InformCampaignExecutionUncheckedUpdateWithoutInformCampaignInput;
};

export type InformCampaignExecutionUpdateWithoutEmailCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformCampaignExecutionsNestedInput>;
};

export type InformCampaignExecutionUpdateWithoutInformCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  emailCampaigns?: Maybe<EmailCampaignUpdateManyWithoutInformCampaignExecutionNestedInput>;
};

export type InformCampaignExecutionUpsertWithWhereUniqueWithoutInformCampaignInput = {
  where: InformCampaignExecutionWhereUniqueInput;
  update: InformCampaignExecutionUncheckedUpdateWithoutInformCampaignInput;
  create: InformCampaignExecutionUncheckedCreateWithoutInformCampaignInput;
};

export type InformCampaignExecutionUpsertWithoutEmailCampaignsInput = {
  update: InformCampaignExecutionUncheckedUpdateWithoutEmailCampaignsInput;
  create: InformCampaignExecutionUncheckedCreateWithoutEmailCampaignsInput;
};

export type InformCampaignExecutionWhereInput = {
  AND?: Maybe<Array<InformCampaignExecutionWhereInput>>;
  OR?: Maybe<Array<InformCampaignExecutionWhereInput>>;
  NOT?: Maybe<Array<InformCampaignExecutionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informCampaignId?: Maybe<StringFilter>;
  executeAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumInformCampaignExecutionStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  informCampaign?: Maybe<InformCampaignWhereInput>;
  emailCampaigns?: Maybe<EmailCampaignListRelationFilter>;
};

export type InformCampaignExecutionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformCampaignGroup = {
  __typename?: 'InformCampaignGroup';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  informPartner: InformPartner;
  informCampaigns: Array<InformCampaign>;
  _count: InformCampaignGroupCountOutputType;
};


export type InformCampaignGroupInformCampaignsArgs = {
  where?: Maybe<InformCampaignWhereInput>;
  orderBy?: Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformCampaignScalarFieldEnum>;
};

export type InformCampaignGroupCountAggregateOutputType = {
  __typename?: 'InformCampaignGroupCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  name: Scalars['Int'];
  displayName: Scalars['Int'];
  description: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformCampaignGroupCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type InformCampaignGroupCountOutputType = {
  __typename?: 'InformCampaignGroupCountOutputType';
  informCampaigns: Scalars['Int'];
};

export type InformCampaignGroupCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformCampaignGroupsInput;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformCampaignGroupInput>;
};

export type InformCampaignGroupCreateManyInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InformCampaignGroupCreateManyInformPartnerInputEnvelope = {
  data: Array<InformCampaignGroupCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignGroupCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InformCampaignGroupCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<InformCampaignGroupCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignGroupCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<InformCampaignGroupCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
};

export type InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput = {
  create?: Maybe<InformCampaignGroupUncheckedCreateWithoutInformCampaignsInput>;
  connectOrCreate?: Maybe<InformCampaignGroupCreateOrConnectWithoutInformCampaignsInput>;
  connect?: Maybe<InformCampaignGroupWhereUniqueInput>;
};

export type InformCampaignGroupCreateOrConnectWithoutInformCampaignsInput = {
  where: InformCampaignGroupWhereUniqueInput;
  create: InformCampaignGroupUncheckedCreateWithoutInformCampaignsInput;
};

export type InformCampaignGroupCreateOrConnectWithoutInformPartnerInput = {
  where: InformCampaignGroupWhereUniqueInput;
  create: InformCampaignGroupUncheckedCreateWithoutInformPartnerInput;
};

export type InformCampaignGroupCreateWithoutInformCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformCampaignGroupsInput;
};

export type InformCampaignGroupCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformCampaignGroupInput>;
};

export type InformCampaignGroupListRelationFilter = {
  every?: Maybe<InformCampaignGroupWhereInput>;
  some?: Maybe<InformCampaignGroupWhereInput>;
  none?: Maybe<InformCampaignGroupWhereInput>;
};

export type InformCampaignGroupMaxAggregateOutputType = {
  __typename?: 'InformCampaignGroupMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InformCampaignGroupMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type InformCampaignGroupMinAggregateOutputType = {
  __typename?: 'InformCampaignGroupMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InformCampaignGroupMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type InformCampaignGroupNameInformPartnerIdCompoundUniqueInput = {
  name: Scalars['String'];
  informPartnerId: Scalars['String'];
};

export type InformCampaignGroupOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformCampaignGroupOrderByRelevanceFieldEnum {
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  Name = 'name',
  DisplayName = 'displayName',
  Description = 'description'
}

export type InformCampaignGroupOrderByRelevanceInput = {
  fields: Array<InformCampaignGroupOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformCampaignGroupOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  _count?: Maybe<InformCampaignGroupCountOrderByAggregateInput>;
  _max?: Maybe<InformCampaignGroupMaxOrderByAggregateInput>;
  _min?: Maybe<InformCampaignGroupMinOrderByAggregateInput>;
};

export type InformCampaignGroupOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informCampaigns?: Maybe<InformCampaignOrderByRelationAggregateInput>;
  _relevance?: Maybe<InformCampaignGroupOrderByRelevanceInput>;
};

export type InformCampaignGroupRelationFilter = {
  is?: Maybe<InformCampaignGroupWhereInput>;
  isNot?: Maybe<InformCampaignGroupWhereInput>;
};

export enum InformCampaignGroupScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InformPartnerId = 'informPartnerId',
  Name = 'name',
  DisplayName = 'displayName',
  Description = 'description'
}

export type InformCampaignGroupScalarWhereInput = {
  AND?: Maybe<Array<InformCampaignGroupScalarWhereInput>>;
  OR?: Maybe<Array<InformCampaignGroupScalarWhereInput>>;
  NOT?: Maybe<Array<InformCampaignGroupScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  displayName?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
};

export type InformCampaignGroupScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformCampaignGroupScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformCampaignGroupScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformCampaignGroupScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  informPartnerId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  displayName?: Maybe<StringNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
};

export type InformCampaignGroupUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformCampaignGroupInput>;
};

export type InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<InformCampaignGroupCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignGroupCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<InformCampaignGroupCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
};

export type InformCampaignGroupUncheckedCreateWithoutInformCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InformCampaignGroupUncheckedCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformCampaignGroupInput>;
};

export type InformCampaignGroupUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformCampaignGroupNestedInput>;
};

export type InformCampaignGroupUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InformCampaignGroupUncheckedUpdateManyWithoutInformCampaignGroupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<InformCampaignGroupCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignGroupCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformCampaignGroupUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<InformCampaignGroupCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignGroupUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformCampaignGroupUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<InformCampaignGroupScalarWhereInput>>;
};

export type InformCampaignGroupUncheckedUpdateWithoutInformCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InformCampaignGroupUncheckedUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformCampaignGroupNestedInput>;
};

export type InformCampaignGroupUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformCampaignGroupsNestedInput>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformCampaignGroupNestedInput>;
};

export type InformCampaignGroupUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InformCampaignGroupUpdateManyWithWhereWithoutInformPartnerInput = {
  where: InformCampaignGroupScalarWhereInput;
  data: InformCampaignGroupUncheckedUpdateManyWithoutInformCampaignGroupsInput;
};

export type InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<InformCampaignGroupCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignGroupCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformCampaignGroupUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<InformCampaignGroupCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignGroupUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformCampaignGroupUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<InformCampaignGroupScalarWhereInput>>;
};

export type InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput = {
  create?: Maybe<InformCampaignGroupUncheckedCreateWithoutInformCampaignsInput>;
  connectOrCreate?: Maybe<InformCampaignGroupCreateOrConnectWithoutInformCampaignsInput>;
  upsert?: Maybe<InformCampaignGroupUpsertWithoutInformCampaignsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformCampaignGroupWhereUniqueInput>;
  update?: Maybe<InformCampaignGroupUncheckedUpdateWithoutInformCampaignsInput>;
};

export type InformCampaignGroupUpdateWithWhereUniqueWithoutInformPartnerInput = {
  where: InformCampaignGroupWhereUniqueInput;
  data: InformCampaignGroupUncheckedUpdateWithoutInformPartnerInput;
};

export type InformCampaignGroupUpdateWithoutInformCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformCampaignGroupsNestedInput>;
};

export type InformCampaignGroupUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformCampaignGroupNestedInput>;
};

export type InformCampaignGroupUpsertWithWhereUniqueWithoutInformPartnerInput = {
  where: InformCampaignGroupWhereUniqueInput;
  update: InformCampaignGroupUncheckedUpdateWithoutInformPartnerInput;
  create: InformCampaignGroupUncheckedCreateWithoutInformPartnerInput;
};

export type InformCampaignGroupUpsertWithoutInformCampaignsInput = {
  update: InformCampaignGroupUncheckedUpdateWithoutInformCampaignsInput;
  create: InformCampaignGroupUncheckedCreateWithoutInformCampaignsInput;
};

export type InformCampaignGroupWhereInput = {
  AND?: Maybe<Array<InformCampaignGroupWhereInput>>;
  OR?: Maybe<Array<InformCampaignGroupWhereInput>>;
  NOT?: Maybe<Array<InformCampaignGroupWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  displayName?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informCampaigns?: Maybe<InformCampaignListRelationFilter>;
};

export type InformCampaignGroupWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name_informPartnerId?: Maybe<InformCampaignGroupNameInformPartnerIdCompoundUniqueInput>;
};

export type InformCampaignListRelationFilter = {
  every?: Maybe<InformCampaignWhereInput>;
  some?: Maybe<InformCampaignWhereInput>;
  none?: Maybe<InformCampaignWhereInput>;
};

export type InformCampaignMaxAggregateOutputType = {
  __typename?: 'InformCampaignMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
};

export type InformCampaignMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  informCampaignGroupId?: Maybe<SortOrder>;
  informAudienceDefinitionId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type InformCampaignMinAggregateOutputType = {
  __typename?: 'InformCampaignMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
};

export type InformCampaignMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  informCampaignGroupId?: Maybe<SortOrder>;
  informAudienceDefinitionId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type InformCampaignOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformCampaignOrderByRelevanceFieldEnum {
  Id = 'id',
  InformPartnerProgramId = 'informPartnerProgramId',
  InformCampaignGroupId = 'informCampaignGroupId',
  InformAudienceDefinitionId = 'informAudienceDefinitionId',
  Name = 'name',
  DisplayName = 'displayName'
}

export type InformCampaignOrderByRelevanceInput = {
  fields: Array<InformCampaignOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformCampaignOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  informCampaignGroupId?: Maybe<SortOrder>;
  informAudienceDefinitionId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  _count?: Maybe<InformCampaignCountOrderByAggregateInput>;
  _max?: Maybe<InformCampaignMaxOrderByAggregateInput>;
  _min?: Maybe<InformCampaignMinOrderByAggregateInput>;
};

export type InformCampaignOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  informCampaignGroupId?: Maybe<SortOrder>;
  informAudienceDefinitionId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupOrderByWithRelationAndSearchRelevanceInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignOrderByWithRelationAndSearchRelevanceInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentOrderByRelationAggregateInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionOrderByRelationAggregateInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<InformCampaignOrderByRelevanceInput>;
};

export type InformCampaignRelationFilter = {
  is?: Maybe<InformCampaignWhereInput>;
  isNot?: Maybe<InformCampaignWhereInput>;
};

export enum InformCampaignScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InformPartnerProgramId = 'informPartnerProgramId',
  InformCampaignGroupId = 'informCampaignGroupId',
  InformAudienceDefinitionId = 'informAudienceDefinitionId',
  Type = 'type',
  Name = 'name',
  DisplayName = 'displayName',
  Status = 'status',
  StatusMessage = 'statusMessage'
}

export type InformCampaignScalarWhereInput = {
  AND?: Maybe<Array<InformCampaignScalarWhereInput>>;
  OR?: Maybe<Array<InformCampaignScalarWhereInput>>;
  NOT?: Maybe<Array<InformCampaignScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerProgramId?: Maybe<StringFilter>;
  informCampaignGroupId?: Maybe<StringNullableFilter>;
  informAudienceDefinitionId?: Maybe<StringFilter>;
  type?: Maybe<EnumInformCampaignTypeFilter>;
  name?: Maybe<StringFilter>;
  displayName?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumInformCampaignStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
};

export type InformCampaignScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformCampaignScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformCampaignScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformCampaignScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  informPartnerProgramId?: Maybe<StringWithAggregatesFilter>;
  informCampaignGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  informAudienceDefinitionId?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumInformCampaignTypeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  displayName?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumInformCampaignStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum InformCampaignStatus {
  Active = 'Active',
  Paused = 'Paused',
  Archived = 'Archived'
}

export enum InformCampaignType {
  Scheduled = 'Scheduled',
  Automation = 'Automation'
}

export type InformCampaignUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId: Scalars['String'];
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
};

export type InformCampaignUncheckedCreateNestedManyWithoutInformAudienceDefinitionInput = {
  create?: Maybe<Array<InformCampaignCreateWithoutInformAudienceDefinitionInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformAudienceDefinitionInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformAudienceDefinitionInputEnvelope>;
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
};

export type InformCampaignUncheckedCreateNestedManyWithoutInformCampaignGroupInput = {
  create?: Maybe<Array<InformCampaignCreateWithoutInformCampaignGroupInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformCampaignGroupInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformCampaignGroupInputEnvelope>;
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
};

export type InformCampaignUncheckedCreateNestedManyWithoutInformPartnerProgramInput = {
  create?: Maybe<Array<InformCampaignCreateWithoutInformPartnerProgramInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformPartnerProgramInputEnvelope>;
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
};

export type InformCampaignUncheckedCreateWithoutInformAudienceDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId: Scalars['String'];
  informCampaignGroupId?: Maybe<Scalars['String']>;
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
};

export type InformCampaignUncheckedCreateWithoutInformAutomationCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId: Scalars['String'];
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput>;
};

export type InformCampaignUncheckedCreateWithoutInformCampaignEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId: Scalars['String'];
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
};

export type InformCampaignUncheckedCreateWithoutInformCampaignExecutionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId: Scalars['String'];
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
};

export type InformCampaignUncheckedCreateWithoutInformCampaignGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId: Scalars['String'];
  informAudienceDefinitionId: Scalars['String'];
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
};

export type InformCampaignUncheckedCreateWithoutInformPartnerProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
};

export type InformCampaignUncheckedCreateWithoutInformScheduledCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId: Scalars['String'];
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  type: InformCampaignType;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
};

export type InformCampaignUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
};

export type InformCampaignUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
};

export type InformCampaignUncheckedUpdateManyWithoutInformAudienceDefinitionNestedInput = {
  create?: Maybe<Array<InformCampaignCreateWithoutInformAudienceDefinitionInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformAudienceDefinitionInput>>;
  upsert?: Maybe<Array<InformCampaignUpsertWithWhereUniqueWithoutInformAudienceDefinitionInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformAudienceDefinitionInputEnvelope>;
  set?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignUpdateWithWhereUniqueWithoutInformAudienceDefinitionInput>>;
  updateMany?: Maybe<Array<InformCampaignUpdateManyWithWhereWithoutInformAudienceDefinitionInput>>;
  deleteMany?: Maybe<Array<InformCampaignScalarWhereInput>>;
};

export type InformCampaignUncheckedUpdateManyWithoutInformCampaignGroupNestedInput = {
  create?: Maybe<Array<InformCampaignCreateWithoutInformCampaignGroupInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformCampaignGroupInput>>;
  upsert?: Maybe<Array<InformCampaignUpsertWithWhereUniqueWithoutInformCampaignGroupInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformCampaignGroupInputEnvelope>;
  set?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignUpdateWithWhereUniqueWithoutInformCampaignGroupInput>>;
  updateMany?: Maybe<Array<InformCampaignUpdateManyWithWhereWithoutInformCampaignGroupInput>>;
  deleteMany?: Maybe<Array<InformCampaignScalarWhereInput>>;
};

export type InformCampaignUncheckedUpdateManyWithoutInformCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
};

export type InformCampaignUncheckedUpdateManyWithoutInformPartnerProgramNestedInput = {
  create?: Maybe<Array<InformCampaignCreateWithoutInformPartnerProgramInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformPartnerProgramInput>>;
  upsert?: Maybe<Array<InformCampaignUpsertWithWhereUniqueWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformPartnerProgramInputEnvelope>;
  set?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignUpdateWithWhereUniqueWithoutInformPartnerProgramInput>>;
  updateMany?: Maybe<Array<InformCampaignUpdateManyWithWhereWithoutInformPartnerProgramInput>>;
  deleteMany?: Maybe<Array<InformCampaignScalarWhereInput>>;
};

export type InformCampaignUncheckedUpdateWithoutInformAudienceDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
};

export type InformCampaignUncheckedUpdateWithoutInformAutomationCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput>;
};

export type InformCampaignUncheckedUpdateWithoutInformCampaignEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
};

export type InformCampaignUncheckedUpdateWithoutInformCampaignExecutionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
};

export type InformCampaignUncheckedUpdateWithoutInformCampaignGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
};

export type InformCampaignUncheckedUpdateWithoutInformPartnerProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
};

export type InformCampaignUncheckedUpdateWithoutInformScheduledCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
};

export type InformCampaignUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput>;
};

export type InformCampaignUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
};

export type InformCampaignUpdateManyWithWhereWithoutInformAudienceDefinitionInput = {
  where: InformCampaignScalarWhereInput;
  data: InformCampaignUncheckedUpdateManyWithoutInformCampaignsInput;
};

export type InformCampaignUpdateManyWithWhereWithoutInformCampaignGroupInput = {
  where: InformCampaignScalarWhereInput;
  data: InformCampaignUncheckedUpdateManyWithoutInformCampaignsInput;
};

export type InformCampaignUpdateManyWithWhereWithoutInformPartnerProgramInput = {
  where: InformCampaignScalarWhereInput;
  data: InformCampaignUncheckedUpdateManyWithoutInformCampaignsInput;
};

export type InformCampaignUpdateManyWithoutInformAudienceDefinitionNestedInput = {
  create?: Maybe<Array<InformCampaignCreateWithoutInformAudienceDefinitionInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformAudienceDefinitionInput>>;
  upsert?: Maybe<Array<InformCampaignUpsertWithWhereUniqueWithoutInformAudienceDefinitionInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformAudienceDefinitionInputEnvelope>;
  set?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignUpdateWithWhereUniqueWithoutInformAudienceDefinitionInput>>;
  updateMany?: Maybe<Array<InformCampaignUpdateManyWithWhereWithoutInformAudienceDefinitionInput>>;
  deleteMany?: Maybe<Array<InformCampaignScalarWhereInput>>;
};

export type InformCampaignUpdateManyWithoutInformCampaignGroupNestedInput = {
  create?: Maybe<Array<InformCampaignCreateWithoutInformCampaignGroupInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformCampaignGroupInput>>;
  upsert?: Maybe<Array<InformCampaignUpsertWithWhereUniqueWithoutInformCampaignGroupInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformCampaignGroupInputEnvelope>;
  set?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignUpdateWithWhereUniqueWithoutInformCampaignGroupInput>>;
  updateMany?: Maybe<Array<InformCampaignUpdateManyWithWhereWithoutInformCampaignGroupInput>>;
  deleteMany?: Maybe<Array<InformCampaignScalarWhereInput>>;
};

export type InformCampaignUpdateManyWithoutInformPartnerProgramNestedInput = {
  create?: Maybe<Array<InformCampaignCreateWithoutInformPartnerProgramInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformPartnerProgramInput>>;
  upsert?: Maybe<Array<InformCampaignUpsertWithWhereUniqueWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformPartnerProgramInputEnvelope>;
  set?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignUpdateWithWhereUniqueWithoutInformPartnerProgramInput>>;
  updateMany?: Maybe<Array<InformCampaignUpdateManyWithWhereWithoutInformPartnerProgramInput>>;
  deleteMany?: Maybe<Array<InformCampaignScalarWhereInput>>;
};

export type InformCampaignUpdateOneRequiredWithoutInformAutomationCampaignNestedInput = {
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformAutomationCampaignInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformAutomationCampaignInput>;
  upsert?: Maybe<InformCampaignUpsertWithoutInformAutomationCampaignInput>;
  connect?: Maybe<InformCampaignWhereUniqueInput>;
  update?: Maybe<InformCampaignUncheckedUpdateWithoutInformAutomationCampaignInput>;
};

export type InformCampaignUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput = {
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformCampaignEnrollmentsInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformCampaignEnrollmentsInput>;
  upsert?: Maybe<InformCampaignUpsertWithoutInformCampaignEnrollmentsInput>;
  connect?: Maybe<InformCampaignWhereUniqueInput>;
  update?: Maybe<InformCampaignUncheckedUpdateWithoutInformCampaignEnrollmentsInput>;
};

export type InformCampaignUpdateOneRequiredWithoutInformCampaignExecutionsNestedInput = {
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformCampaignExecutionsInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformCampaignExecutionsInput>;
  upsert?: Maybe<InformCampaignUpsertWithoutInformCampaignExecutionsInput>;
  connect?: Maybe<InformCampaignWhereUniqueInput>;
  update?: Maybe<InformCampaignUncheckedUpdateWithoutInformCampaignExecutionsInput>;
};

export type InformCampaignUpdateOneRequiredWithoutInformScheduledCampaignNestedInput = {
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformScheduledCampaignInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformScheduledCampaignInput>;
  upsert?: Maybe<InformCampaignUpsertWithoutInformScheduledCampaignInput>;
  connect?: Maybe<InformCampaignWhereUniqueInput>;
  update?: Maybe<InformCampaignUncheckedUpdateWithoutInformScheduledCampaignInput>;
};

export type InformCampaignUpdateWithWhereUniqueWithoutInformAudienceDefinitionInput = {
  where: InformCampaignWhereUniqueInput;
  data: InformCampaignUncheckedUpdateWithoutInformAudienceDefinitionInput;
};

export type InformCampaignUpdateWithWhereUniqueWithoutInformCampaignGroupInput = {
  where: InformCampaignWhereUniqueInput;
  data: InformCampaignUncheckedUpdateWithoutInformCampaignGroupInput;
};

export type InformCampaignUpdateWithWhereUniqueWithoutInformPartnerProgramInput = {
  where: InformCampaignWhereUniqueInput;
  data: InformCampaignUncheckedUpdateWithoutInformPartnerProgramInput;
};

export type InformCampaignUpdateWithoutInformAudienceDefinitionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput>;
};

export type InformCampaignUpdateWithoutInformAutomationCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput>;
};

export type InformCampaignUpdateWithoutInformCampaignEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput>;
};

export type InformCampaignUpdateWithoutInformCampaignExecutionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput>;
};

export type InformCampaignUpdateWithoutInformCampaignGroupInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput>;
};

export type InformCampaignUpdateWithoutInformPartnerProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput>;
};

export type InformCampaignUpdateWithoutInformScheduledCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InformCampaignType>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput>;
};

export type InformCampaignUpsertWithWhereUniqueWithoutInformAudienceDefinitionInput = {
  where: InformCampaignWhereUniqueInput;
  update: InformCampaignUncheckedUpdateWithoutInformAudienceDefinitionInput;
  create: InformCampaignUncheckedCreateWithoutInformAudienceDefinitionInput;
};

export type InformCampaignUpsertWithWhereUniqueWithoutInformCampaignGroupInput = {
  where: InformCampaignWhereUniqueInput;
  update: InformCampaignUncheckedUpdateWithoutInformCampaignGroupInput;
  create: InformCampaignUncheckedCreateWithoutInformCampaignGroupInput;
};

export type InformCampaignUpsertWithWhereUniqueWithoutInformPartnerProgramInput = {
  where: InformCampaignWhereUniqueInput;
  update: InformCampaignUncheckedUpdateWithoutInformPartnerProgramInput;
  create: InformCampaignUncheckedCreateWithoutInformPartnerProgramInput;
};

export type InformCampaignUpsertWithoutInformAutomationCampaignInput = {
  update: InformCampaignUncheckedUpdateWithoutInformAutomationCampaignInput;
  create: InformCampaignUncheckedCreateWithoutInformAutomationCampaignInput;
};

export type InformCampaignUpsertWithoutInformCampaignEnrollmentsInput = {
  update: InformCampaignUncheckedUpdateWithoutInformCampaignEnrollmentsInput;
  create: InformCampaignUncheckedCreateWithoutInformCampaignEnrollmentsInput;
};

export type InformCampaignUpsertWithoutInformCampaignExecutionsInput = {
  update: InformCampaignUncheckedUpdateWithoutInformCampaignExecutionsInput;
  create: InformCampaignUncheckedCreateWithoutInformCampaignExecutionsInput;
};

export type InformCampaignUpsertWithoutInformScheduledCampaignInput = {
  update: InformCampaignUncheckedUpdateWithoutInformScheduledCampaignInput;
  create: InformCampaignUncheckedCreateWithoutInformScheduledCampaignInput;
};

export type InformCampaignWhereInput = {
  AND?: Maybe<Array<InformCampaignWhereInput>>;
  OR?: Maybe<Array<InformCampaignWhereInput>>;
  NOT?: Maybe<Array<InformCampaignWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerProgramId?: Maybe<StringFilter>;
  informCampaignGroupId?: Maybe<StringNullableFilter>;
  informAudienceDefinitionId?: Maybe<StringFilter>;
  type?: Maybe<EnumInformCampaignTypeFilter>;
  name?: Maybe<StringFilter>;
  displayName?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumInformCampaignStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionWhereInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramWhereInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupWhereInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignWhereInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentListRelationFilter>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionListRelationFilter>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignWhereInput>;
};

export type InformCampaignWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum InformControlGroupEntityType {
  InformCampaign = 'InformCampaign',
  InformCampaignGroup = 'InformCampaignGroup',
  InformPartnerProgram = 'InformPartnerProgram'
}

export type InformControlGroupParticipant = {
  __typename?: 'InformControlGroupParticipant';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  controlGroupEntityId: Scalars['String'];
  controlGroupEntityType: InformControlGroupEntityType;
  clinicPetParentId: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  clinicPetParent: ClinicPetParent;
};

export type InformControlGroupParticipantCountAggregateOutputType = {
  __typename?: 'InformControlGroupParticipantCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  controlGroupEntityId: Scalars['Int'];
  controlGroupEntityType: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformControlGroupParticipantCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  controlGroupEntityId?: Maybe<SortOrder>;
  controlGroupEntityType?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type InformControlGroupParticipantCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId: Scalars['String'];
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInformControlGroupParticipantsInput;
};

export type InformControlGroupParticipantCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId: Scalars['String'];
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantCreateManyClinicPetParentInputEnvelope = {
  data: Array<InformControlGroupParticipantCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId: Scalars['String'];
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  clinicPetParentId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<InformControlGroupParticipantCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<InformControlGroupParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<InformControlGroupParticipantCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
};

export type InformControlGroupParticipantCreateOrConnectWithoutClinicPetParentInput = {
  where: InformControlGroupParticipantWhereUniqueInput;
  create: InformControlGroupParticipantUncheckedCreateWithoutClinicPetParentInput;
};

export type InformControlGroupParticipantCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId: Scalars['String'];
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantListRelationFilter = {
  every?: Maybe<InformControlGroupParticipantWhereInput>;
  some?: Maybe<InformControlGroupParticipantWhereInput>;
  none?: Maybe<InformControlGroupParticipantWhereInput>;
};

export type InformControlGroupParticipantMaxAggregateOutputType = {
  __typename?: 'InformControlGroupParticipantMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  controlGroupEntityId?: Maybe<SortOrder>;
  controlGroupEntityType?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type InformControlGroupParticipantMinAggregateOutputType = {
  __typename?: 'InformControlGroupParticipantMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  controlGroupEntityId?: Maybe<SortOrder>;
  controlGroupEntityType?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type InformControlGroupParticipantOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformControlGroupParticipantOrderByRelevanceFieldEnum {
  Id = 'id',
  ControlGroupEntityId = 'controlGroupEntityId',
  ClinicPetParentId = 'clinicPetParentId'
}

export type InformControlGroupParticipantOrderByRelevanceInput = {
  fields: Array<InformControlGroupParticipantOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformControlGroupParticipantOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  controlGroupEntityId?: Maybe<SortOrder>;
  controlGroupEntityType?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _count?: Maybe<InformControlGroupParticipantCountOrderByAggregateInput>;
  _max?: Maybe<InformControlGroupParticipantMaxOrderByAggregateInput>;
  _min?: Maybe<InformControlGroupParticipantMinOrderByAggregateInput>;
};

export type InformControlGroupParticipantOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  controlGroupEntityId?: Maybe<SortOrder>;
  controlGroupEntityType?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<InformControlGroupParticipantOrderByRelevanceInput>;
};

export enum InformControlGroupParticipantScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ControlGroupEntityId = 'controlGroupEntityId',
  ControlGroupEntityType = 'controlGroupEntityType',
  ClinicPetParentId = 'clinicPetParentId',
  IsDeleted = 'isDeleted'
}

export type InformControlGroupParticipantScalarWhereInput = {
  AND?: Maybe<Array<InformControlGroupParticipantScalarWhereInput>>;
  OR?: Maybe<Array<InformControlGroupParticipantScalarWhereInput>>;
  NOT?: Maybe<Array<InformControlGroupParticipantScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  controlGroupEntityId?: Maybe<StringFilter>;
  controlGroupEntityType?: Maybe<EnumInformControlGroupEntityTypeFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type InformControlGroupParticipantScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformControlGroupParticipantScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformControlGroupParticipantScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformControlGroupParticipantScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  controlGroupEntityId?: Maybe<StringWithAggregatesFilter>;
  controlGroupEntityType?: Maybe<EnumInformControlGroupEntityTypeWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
};

export type InformControlGroupParticipantUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId: Scalars['String'];
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  clinicPetParentId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<InformControlGroupParticipantCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<InformControlGroupParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<InformControlGroupParticipantCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
};

export type InformControlGroupParticipantUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId: Scalars['String'];
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<InformControlGroupParticipantCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<InformControlGroupParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<InformControlGroupParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<InformControlGroupParticipantCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  delete?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  connect?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  update?: Maybe<Array<InformControlGroupParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<InformControlGroupParticipantUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<InformControlGroupParticipantScalarWhereInput>>;
};

export type InformControlGroupParticipantUncheckedUpdateManyWithoutInformControlGroupParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInformControlGroupParticipantsNestedInput>;
};

export type InformControlGroupParticipantUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: InformControlGroupParticipantScalarWhereInput;
  data: InformControlGroupParticipantUncheckedUpdateManyWithoutInformControlGroupParticipantsInput;
};

export type InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<InformControlGroupParticipantCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<InformControlGroupParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<InformControlGroupParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<InformControlGroupParticipantCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  delete?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  connect?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  update?: Maybe<Array<InformControlGroupParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<InformControlGroupParticipantUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<InformControlGroupParticipantScalarWhereInput>>;
};

export type InformControlGroupParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: InformControlGroupParticipantWhereUniqueInput;
  data: InformControlGroupParticipantUncheckedUpdateWithoutClinicPetParentInput;
};

export type InformControlGroupParticipantUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: InformControlGroupParticipantWhereUniqueInput;
  update: InformControlGroupParticipantUncheckedUpdateWithoutClinicPetParentInput;
  create: InformControlGroupParticipantUncheckedCreateWithoutClinicPetParentInput;
};

export type InformControlGroupParticipantWhereInput = {
  AND?: Maybe<Array<InformControlGroupParticipantWhereInput>>;
  OR?: Maybe<Array<InformControlGroupParticipantWhereInput>>;
  NOT?: Maybe<Array<InformControlGroupParticipantWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  controlGroupEntityId?: Maybe<StringFilter>;
  controlGroupEntityType?: Maybe<EnumInformControlGroupEntityTypeFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
};

export type InformControlGroupParticipantWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformEmailTemplate = {
  __typename?: 'InformEmailTemplate';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  informPartnerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status: InformEmailTemplateStatus;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history: Array<Scalars['Json']>;
  informPartner?: Maybe<InformPartner>;
  informScheduledCampaigns: Array<InformScheduledCampaign>;
  _count: InformEmailTemplateCountOutputType;
};


export type InformEmailTemplateInformScheduledCampaignsArgs = {
  where?: Maybe<InformScheduledCampaignWhereInput>;
  orderBy?: Maybe<InformScheduledCampaignOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformScheduledCampaignScalarFieldEnum>;
};

export type InformEmailTemplateCountAggregateOutputType = {
  __typename?: 'InformEmailTemplateCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  htmlContent: Scalars['Int'];
  unlayerJson: Scalars['Int'];
  history: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformEmailTemplateCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  history?: Maybe<SortOrder>;
};

export type InformEmailTemplateCountOutputType = {
  __typename?: 'InformEmailTemplateCountOutputType';
  informScheduledCampaigns: Scalars['Int'];
};

export type InformEmailTemplateCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutInformEmailTemplatesInput>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignCreateNestedManyWithoutInformEmailTemplateInput>;
};

export type InformEmailTemplateCreateManyInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
};

export type InformEmailTemplateCreateManyInformPartnerInputEnvelope = {
  data: Array<InformEmailTemplateCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformEmailTemplateCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
};

export type InformEmailTemplateCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<InformEmailTemplateCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformEmailTemplateCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<InformEmailTemplateCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
};

export type InformEmailTemplateCreateNestedOneWithoutInformScheduledCampaignsInput = {
  create?: Maybe<InformEmailTemplateUncheckedCreateWithoutInformScheduledCampaignsInput>;
  connectOrCreate?: Maybe<InformEmailTemplateCreateOrConnectWithoutInformScheduledCampaignsInput>;
  connect?: Maybe<InformEmailTemplateWhereUniqueInput>;
};

export type InformEmailTemplateCreateOrConnectWithoutInformPartnerInput = {
  where: InformEmailTemplateWhereUniqueInput;
  create: InformEmailTemplateUncheckedCreateWithoutInformPartnerInput;
};

export type InformEmailTemplateCreateOrConnectWithoutInformScheduledCampaignsInput = {
  where: InformEmailTemplateWhereUniqueInput;
  create: InformEmailTemplateUncheckedCreateWithoutInformScheduledCampaignsInput;
};

export type InformEmailTemplateCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignCreateNestedManyWithoutInformEmailTemplateInput>;
};

export type InformEmailTemplateCreateWithoutInformScheduledCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutInformEmailTemplatesInput>;
};

export type InformEmailTemplateCreatehistoryInput = {
  set: Array<Scalars['Json']>;
};

export type InformEmailTemplateListRelationFilter = {
  every?: Maybe<InformEmailTemplateWhereInput>;
  some?: Maybe<InformEmailTemplateWhereInput>;
  none?: Maybe<InformEmailTemplateWhereInput>;
};

export type InformEmailTemplateMaxAggregateOutputType = {
  __typename?: 'InformEmailTemplateMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
};

export type InformEmailTemplateMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
};

export type InformEmailTemplateMinAggregateOutputType = {
  __typename?: 'InformEmailTemplateMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
};

export type InformEmailTemplateMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
};

export type InformEmailTemplateNameInformPartnerIdCompoundUniqueInput = {
  name: Scalars['String'];
  informPartnerId: Scalars['String'];
};

export type InformEmailTemplateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformEmailTemplateOrderByRelevanceFieldEnum {
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  Name = 'name',
  HtmlContent = 'htmlContent'
}

export type InformEmailTemplateOrderByRelevanceInput = {
  fields: Array<InformEmailTemplateOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformEmailTemplateOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  history?: Maybe<SortOrder>;
  _count?: Maybe<InformEmailTemplateCountOrderByAggregateInput>;
  _max?: Maybe<InformEmailTemplateMaxOrderByAggregateInput>;
  _min?: Maybe<InformEmailTemplateMinOrderByAggregateInput>;
};

export type InformEmailTemplateOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  history?: Maybe<SortOrder>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignOrderByRelationAggregateInput>;
  _relevance?: Maybe<InformEmailTemplateOrderByRelevanceInput>;
};

export type InformEmailTemplateRelationFilter = {
  is?: Maybe<InformEmailTemplateWhereInput>;
  isNot?: Maybe<InformEmailTemplateWhereInput>;
};

export enum InformEmailTemplateScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InformPartnerId = 'informPartnerId',
  Name = 'name',
  Status = 'status',
  HtmlContent = 'htmlContent',
  UnlayerJson = 'unlayerJson',
  History = 'history'
}

export type InformEmailTemplateScalarWhereInput = {
  AND?: Maybe<Array<InformEmailTemplateScalarWhereInput>>;
  OR?: Maybe<Array<InformEmailTemplateScalarWhereInput>>;
  NOT?: Maybe<Array<InformEmailTemplateScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumInformEmailTemplateStatusFilter>;
  htmlContent?: Maybe<StringNullableFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
  history?: Maybe<JsonNullableListFilter>;
};

export type InformEmailTemplateScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformEmailTemplateScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformEmailTemplateScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformEmailTemplateScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  informPartnerId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumInformEmailTemplateStatusWithAggregatesFilter>;
  htmlContent?: Maybe<StringNullableWithAggregatesFilter>;
  unlayerJson?: Maybe<JsonNullableWithAggregatesFilter>;
  history?: Maybe<JsonNullableListFilter>;
};

export enum InformEmailTemplateStatus {
  Draft = 'Draft',
  Published = 'Published',
  Archived = 'Archived'
}

export type InformEmailTemplateUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignUncheckedCreateNestedManyWithoutInformEmailTemplateInput>;
};

export type InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<InformEmailTemplateCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformEmailTemplateCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<InformEmailTemplateCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
};

export type InformEmailTemplateUncheckedCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignUncheckedCreateNestedManyWithoutInformEmailTemplateInput>;
};

export type InformEmailTemplateUncheckedCreateWithoutInformScheduledCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
};

export type InformEmailTemplateUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignUncheckedUpdateManyWithoutInformEmailTemplateNestedInput>;
};

export type InformEmailTemplateUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
};

export type InformEmailTemplateUncheckedUpdateManyWithoutInformEmailTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
};

export type InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<InformEmailTemplateCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformEmailTemplateCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformEmailTemplateUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<InformEmailTemplateCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  update?: Maybe<Array<InformEmailTemplateUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformEmailTemplateUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<InformEmailTemplateScalarWhereInput>>;
};

export type InformEmailTemplateUncheckedUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignUncheckedUpdateManyWithoutInformEmailTemplateNestedInput>;
};

export type InformEmailTemplateUncheckedUpdateWithoutInformScheduledCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
};

export type InformEmailTemplateUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutInformEmailTemplatesNestedInput>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignUpdateManyWithoutInformEmailTemplateNestedInput>;
};

export type InformEmailTemplateUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
};

export type InformEmailTemplateUpdateManyWithWhereWithoutInformPartnerInput = {
  where: InformEmailTemplateScalarWhereInput;
  data: InformEmailTemplateUncheckedUpdateManyWithoutInformEmailTemplatesInput;
};

export type InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<InformEmailTemplateCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformEmailTemplateCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformEmailTemplateUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<InformEmailTemplateCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  update?: Maybe<Array<InformEmailTemplateUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformEmailTemplateUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<InformEmailTemplateScalarWhereInput>>;
};

export type InformEmailTemplateUpdateOneWithoutInformScheduledCampaignsNestedInput = {
  create?: Maybe<InformEmailTemplateUncheckedCreateWithoutInformScheduledCampaignsInput>;
  connectOrCreate?: Maybe<InformEmailTemplateCreateOrConnectWithoutInformScheduledCampaignsInput>;
  upsert?: Maybe<InformEmailTemplateUpsertWithoutInformScheduledCampaignsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformEmailTemplateWhereUniqueInput>;
  update?: Maybe<InformEmailTemplateUncheckedUpdateWithoutInformScheduledCampaignsInput>;
};

export type InformEmailTemplateUpdateWithWhereUniqueWithoutInformPartnerInput = {
  where: InformEmailTemplateWhereUniqueInput;
  data: InformEmailTemplateUncheckedUpdateWithoutInformPartnerInput;
};

export type InformEmailTemplateUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignUpdateManyWithoutInformEmailTemplateNestedInput>;
};

export type InformEmailTemplateUpdateWithoutInformScheduledCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  htmlContent?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  history?: Maybe<Array<Scalars['Json']>>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutInformEmailTemplatesNestedInput>;
};

export type InformEmailTemplateUpdatehistoryInput = {
  set?: Maybe<Array<Scalars['Json']>>;
  push?: Maybe<Array<Scalars['Json']>>;
};

export type InformEmailTemplateUpsertWithWhereUniqueWithoutInformPartnerInput = {
  where: InformEmailTemplateWhereUniqueInput;
  update: InformEmailTemplateUncheckedUpdateWithoutInformPartnerInput;
  create: InformEmailTemplateUncheckedCreateWithoutInformPartnerInput;
};

export type InformEmailTemplateUpsertWithoutInformScheduledCampaignsInput = {
  update: InformEmailTemplateUncheckedUpdateWithoutInformScheduledCampaignsInput;
  create: InformEmailTemplateUncheckedCreateWithoutInformScheduledCampaignsInput;
};

export type InformEmailTemplateWhereInput = {
  AND?: Maybe<Array<InformEmailTemplateWhereInput>>;
  OR?: Maybe<Array<InformEmailTemplateWhereInput>>;
  NOT?: Maybe<Array<InformEmailTemplateWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumInformEmailTemplateStatusFilter>;
  htmlContent?: Maybe<StringNullableFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
  history?: Maybe<JsonNullableListFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignListRelationFilter>;
};

export type InformEmailTemplateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name_informPartnerId?: Maybe<InformEmailTemplateNameInformPartnerIdCompoundUniqueInput>;
};

export enum InformEnrollmentStatus {
  Active = 'Active',
  Paused = 'Paused',
  Archived = 'Archived',
  NeedsReview = 'NeedsReview',
  Onboarding = 'Onboarding',
  Canceled = 'Canceled',
  NotEnrolled = 'NotEnrolled',
  NotMigrated = 'NotMigrated'
}

export type InformEntityHistory = {
  __typename?: 'InformEntityHistory';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  state: Scalars['Json'];
};

export type InformEntityHistoryCountAggregateOutputType = {
  __typename?: 'InformEntityHistoryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  entityId: Scalars['Int'];
  entityType: Scalars['Int'];
  authorId: Scalars['Int'];
  authorType: Scalars['Int'];
  state: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformEntityHistoryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  authorType?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
};

export type InformEntityHistoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  state: Scalars['Json'];
};

export type InformEntityHistoryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  state: Scalars['Json'];
};

export type InformEntityHistoryMaxAggregateOutputType = {
  __typename?: 'InformEntityHistoryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
};

export type InformEntityHistoryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  authorType?: Maybe<SortOrder>;
};

export type InformEntityHistoryMinAggregateOutputType = {
  __typename?: 'InformEntityHistoryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
};

export type InformEntityHistoryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  authorType?: Maybe<SortOrder>;
};

export enum InformEntityHistoryOrderByRelevanceFieldEnum {
  Id = 'id',
  EntityId = 'entityId',
  EntityType = 'entityType',
  AuthorId = 'authorId',
  AuthorType = 'authorType'
}

export type InformEntityHistoryOrderByRelevanceInput = {
  fields: Array<InformEntityHistoryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformEntityHistoryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  authorType?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  _count?: Maybe<InformEntityHistoryCountOrderByAggregateInput>;
  _max?: Maybe<InformEntityHistoryMaxOrderByAggregateInput>;
  _min?: Maybe<InformEntityHistoryMinOrderByAggregateInput>;
};

export type InformEntityHistoryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  authorType?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  _relevance?: Maybe<InformEntityHistoryOrderByRelevanceInput>;
};

export enum InformEntityHistoryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  EntityId = 'entityId',
  EntityType = 'entityType',
  AuthorId = 'authorId',
  AuthorType = 'authorType',
  State = 'state'
}

export type InformEntityHistoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformEntityHistoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformEntityHistoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformEntityHistoryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  entityId?: Maybe<StringWithAggregatesFilter>;
  entityType?: Maybe<StringWithAggregatesFilter>;
  authorId?: Maybe<StringNullableWithAggregatesFilter>;
  authorType?: Maybe<StringNullableWithAggregatesFilter>;
  state?: Maybe<JsonWithAggregatesFilter>;
};

export type InformEntityHistoryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  state: Scalars['Json'];
};

export type InformEntityHistoryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Json']>;
};

export type InformEntityHistoryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Json']>;
};

export type InformEntityHistoryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Json']>;
};

export type InformEntityHistoryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Json']>;
};

export type InformEntityHistoryWhereInput = {
  AND?: Maybe<Array<InformEntityHistoryWhereInput>>;
  OR?: Maybe<Array<InformEntityHistoryWhereInput>>;
  NOT?: Maybe<Array<InformEntityHistoryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  entityId?: Maybe<StringFilter>;
  entityType?: Maybe<StringFilter>;
  authorId?: Maybe<StringNullableFilter>;
  authorType?: Maybe<StringNullableFilter>;
  state?: Maybe<JsonFilter>;
};

export type InformEntityHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformPartner = {
  __typename?: 'InformPartner';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  status: InformPartnerStatus;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms: Array<InformPartnerProgram>;
  informCampaignGroups: Array<InformCampaignGroup>;
  informAudienceDefinitions: Array<InformAudienceDefinition>;
  informEmailTemplates: Array<InformEmailTemplate>;
  products: Array<Product>;
  informPartnerIntegrations: Array<InformPartnerIntegration>;
  informPartnerUsers: Array<InformPartnerUser>;
  InformPartnerCustomer: Array<InformPartnerCustomer>;
  _count: InformPartnerCountOutputType;
};


export type InformPartnerInformPartnerProgramsArgs = {
  where?: Maybe<InformPartnerProgramWhereInput>;
  orderBy?: Maybe<InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerProgramWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerProgramScalarFieldEnum>;
};


export type InformPartnerInformCampaignGroupsArgs = {
  where?: Maybe<InformCampaignGroupWhereInput>;
  orderBy?: Maybe<InformCampaignGroupOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformCampaignGroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformCampaignGroupScalarFieldEnum>;
};


export type InformPartnerInformAudienceDefinitionsArgs = {
  where?: Maybe<InformAudienceDefinitionWhereInput>;
  orderBy?: Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformAudienceDefinitionScalarFieldEnum>;
};


export type InformPartnerInformEmailTemplatesArgs = {
  where?: Maybe<InformEmailTemplateWhereInput>;
  orderBy?: Maybe<InformEmailTemplateOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformEmailTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformEmailTemplateScalarFieldEnum>;
};


export type InformPartnerProductsArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ProductScalarFieldEnum>;
};


export type InformPartnerInformPartnerIntegrationsArgs = {
  where?: Maybe<InformPartnerIntegrationWhereInput>;
  orderBy?: Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerIntegrationScalarFieldEnum>;
};


export type InformPartnerInformPartnerUsersArgs = {
  where?: Maybe<InformPartnerUserWhereInput>;
  orderBy?: Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerUserScalarFieldEnum>;
};


export type InformPartnerInformPartnerCustomerArgs = {
  where?: Maybe<InformPartnerCustomerWhereInput>;
  orderBy?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerCustomerScalarFieldEnum>;
};

export type InformPartnerCountAggregateOutputType = {
  __typename?: 'InformPartnerCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  description: Scalars['Int'];
  settings: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformPartnerCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
};

export type InformPartnerCountOutputType = {
  __typename?: 'InformPartnerCountOutputType';
  informPartnerPrograms: Scalars['Int'];
  informCampaignGroups: Scalars['Int'];
  informAudienceDefinitions: Scalars['Int'];
  informEmailTemplates: Scalars['Int'];
  products: Scalars['Int'];
  informPartnerIntegrations: Scalars['Int'];
  informPartnerUsers: Scalars['Int'];
  InformPartnerCustomer: Scalars['Int'];
};

export type InformPartnerCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerCreateNestedOneWithoutInformAudienceDefinitionsInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformAudienceDefinitionsInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformAudienceDefinitionsInput>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
};

export type InformPartnerCreateNestedOneWithoutInformCampaignGroupsInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformCampaignGroupsInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformCampaignGroupsInput>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
};

export type InformPartnerCreateNestedOneWithoutInformEmailTemplatesInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformEmailTemplatesInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformEmailTemplatesInput>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
};

export type InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerCustomerInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerCustomerInput>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
};

export type InformPartnerCreateNestedOneWithoutInformPartnerIntegrationsInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerIntegrationsInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerIntegrationsInput>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
};

export type InformPartnerCreateNestedOneWithoutInformPartnerProgramsInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerProgramsInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerProgramsInput>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
};

export type InformPartnerCreateNestedOneWithoutInformPartnerUsersInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerUsersInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerUsersInput>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
};

export type InformPartnerCreateNestedOneWithoutProductsInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutProductsInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutProductsInput>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
};

export type InformPartnerCreateOrConnectWithoutInformAudienceDefinitionsInput = {
  where: InformPartnerWhereUniqueInput;
  create: InformPartnerUncheckedCreateWithoutInformAudienceDefinitionsInput;
};

export type InformPartnerCreateOrConnectWithoutInformCampaignGroupsInput = {
  where: InformPartnerWhereUniqueInput;
  create: InformPartnerUncheckedCreateWithoutInformCampaignGroupsInput;
};

export type InformPartnerCreateOrConnectWithoutInformEmailTemplatesInput = {
  where: InformPartnerWhereUniqueInput;
  create: InformPartnerUncheckedCreateWithoutInformEmailTemplatesInput;
};

export type InformPartnerCreateOrConnectWithoutInformPartnerCustomerInput = {
  where: InformPartnerWhereUniqueInput;
  create: InformPartnerUncheckedCreateWithoutInformPartnerCustomerInput;
};

export type InformPartnerCreateOrConnectWithoutInformPartnerIntegrationsInput = {
  where: InformPartnerWhereUniqueInput;
  create: InformPartnerUncheckedCreateWithoutInformPartnerIntegrationsInput;
};

export type InformPartnerCreateOrConnectWithoutInformPartnerProgramsInput = {
  where: InformPartnerWhereUniqueInput;
  create: InformPartnerUncheckedCreateWithoutInformPartnerProgramsInput;
};

export type InformPartnerCreateOrConnectWithoutInformPartnerUsersInput = {
  where: InformPartnerWhereUniqueInput;
  create: InformPartnerUncheckedCreateWithoutInformPartnerUsersInput;
};

export type InformPartnerCreateOrConnectWithoutProductsInput = {
  where: InformPartnerWhereUniqueInput;
  create: InformPartnerUncheckedCreateWithoutProductsInput;
};

export type InformPartnerCreateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerCreateWithoutInformCampaignGroupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerCreateWithoutInformEmailTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerCreateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerCreateWithoutInformPartnerIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerCreateWithoutInformPartnerProgramsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerCreateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerCreateWithoutProductsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerCustomer = {
  __typename?: 'InformPartnerCustomer';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartner;
  informPartnerIntegration: InformPartnerIntegration;
  boehringerIngelheimCustomerAlignments: Array<BoehringerIngelheimCustomerAlignment>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomer>;
  childInformPartnerCustomers: Array<InformPartnerCustomer>;
  clinic?: Maybe<Clinic>;
  pharmacyProviders: Array<InformPartnerCustomerPharmacyProvider>;
  informPartnerUsers: Array<InformPartnerUser>;
  roles: Array<InformPartnerUserRole>;
  _count: InformPartnerCustomerCountOutputType;
};


export type InformPartnerCustomerBoehringerIngelheimCustomerAlignmentsArgs = {
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
  orderBy?: Maybe<BoehringerIngelheimCustomerAlignmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BoehringerIngelheimCustomerAlignmentScalarFieldEnum>;
};


export type InformPartnerCustomerChildInformPartnerCustomersArgs = {
  where?: Maybe<InformPartnerCustomerWhereInput>;
  orderBy?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerCustomerScalarFieldEnum>;
};


export type InformPartnerCustomerPharmacyProvidersArgs = {
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
  orderBy?: Maybe<InformPartnerCustomerPharmacyProviderOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerCustomerPharmacyProviderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerCustomerPharmacyProviderScalarFieldEnum>;
};


export type InformPartnerCustomerInformPartnerUsersArgs = {
  where?: Maybe<InformPartnerUserWhereInput>;
  orderBy?: Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerUserScalarFieldEnum>;
};


export type InformPartnerCustomerRolesArgs = {
  where?: Maybe<InformPartnerUserRoleWhereInput>;
  orderBy?: Maybe<InformPartnerUserRoleOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerUserRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerUserRoleScalarFieldEnum>;
};

export type InformPartnerCustomerCountAggregateOutputType = {
  __typename?: 'InformPartnerCustomerCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  parentInformPartnerCustomerId: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  informPartnerIntegrationId: Scalars['Int'];
  externalId: Scalars['Int'];
  parentExternalId: Scalars['Int'];
  name: Scalars['Int'];
  postalCode: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  checksum: Scalars['Int'];
  rawData: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformPartnerCustomerCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  parentInformPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  parentExternalId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
};

export type InformPartnerCustomerCountOutputType = {
  __typename?: 'InformPartnerCustomerCountOutputType';
  boehringerIngelheimCustomerAlignments: Scalars['Int'];
  childInformPartnerCustomers: Scalars['Int'];
  pharmacyProviders: Scalars['Int'];
  informPartnerUsers: Scalars['Int'];
  roles: Scalars['Int'];
};

export type InformPartnerCustomerCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerCreateManyInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerCreateManyInformPartnerInputEnvelope = {
  data: Array<InformPartnerCustomerCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerCustomerCreateManyInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerCreateManyInformPartnerIntegrationInputEnvelope = {
  data: Array<InformPartnerCustomerCreateManyInformPartnerIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerCustomerCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerCreateManyParentInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerCreateManyParentInformPartnerCustomerInputEnvelope = {
  data: Array<InformPartnerCustomerCreateManyParentInformPartnerCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
};

export type InformPartnerCustomerCreateNestedManyWithoutInformPartnerIntegrationInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerIntegrationInputEnvelope>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
};

export type InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerUsersInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerUsersInput>>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
};

export type InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutParentInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutParentInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyParentInformPartnerCustomerInputEnvelope>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
};

export type InformPartnerCustomerCreateNestedOneWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
};

export type InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput = {
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutChildInformPartnerCustomersInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutChildInformPartnerCustomersInput>;
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
};

export type InformPartnerCustomerCreateNestedOneWithoutClinicInput = {
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutClinicInput>;
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
};

export type InformPartnerCustomerCreateNestedOneWithoutPharmacyProvidersInput = {
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutPharmacyProvidersInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutPharmacyProvidersInput>;
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
};

export type InformPartnerCustomerCreateNestedOneWithoutRolesInput = {
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutRolesInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutRolesInput>;
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
};

export type InformPartnerCustomerCreateOrConnectWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  create: InformPartnerCustomerUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutChildInformPartnerCustomersInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  create: InformPartnerCustomerUncheckedCreateWithoutChildInformPartnerCustomersInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutClinicInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  create: InformPartnerCustomerUncheckedCreateWithoutClinicInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutInformPartnerInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  create: InformPartnerCustomerUncheckedCreateWithoutInformPartnerInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutInformPartnerIntegrationInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  create: InformPartnerCustomerUncheckedCreateWithoutInformPartnerIntegrationInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutInformPartnerUsersInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  create: InformPartnerCustomerUncheckedCreateWithoutInformPartnerUsersInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutParentInformPartnerCustomerInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  create: InformPartnerCustomerUncheckedCreateWithoutParentInformPartnerCustomerInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutPharmacyProvidersInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  create: InformPartnerCustomerUncheckedCreateWithoutPharmacyProvidersInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutRolesInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  create: InformPartnerCustomerUncheckedCreateWithoutRolesInput;
};

export type InformPartnerCustomerCreateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerCreateWithoutChildInformPartnerCustomersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerCreateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerCreateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerCreateWithoutParentInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerCreateWithoutPharmacyProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
};

export type InformPartnerCustomerInformPartnerIdExternalIdCompoundUniqueInput = {
  informPartnerId: Scalars['String'];
  externalId: Scalars['String'];
};

export type InformPartnerCustomerListRelationFilter = {
  every?: Maybe<InformPartnerCustomerWhereInput>;
  some?: Maybe<InformPartnerCustomerWhereInput>;
  none?: Maybe<InformPartnerCustomerWhereInput>;
};

export type InformPartnerCustomerMaxAggregateOutputType = {
  __typename?: 'InformPartnerCustomerMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
};

export type InformPartnerCustomerMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  parentInformPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  parentExternalId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
};

export type InformPartnerCustomerMinAggregateOutputType = {
  __typename?: 'InformPartnerCustomerMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
};

export type InformPartnerCustomerMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  parentInformPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  parentExternalId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
};

export type InformPartnerCustomerOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerCustomerOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  ParentInformPartnerCustomerId = 'parentInformPartnerCustomerId',
  InformPartnerId = 'informPartnerId',
  InformPartnerIntegrationId = 'informPartnerIntegrationId',
  ExternalId = 'externalId',
  ParentExternalId = 'parentExternalId',
  Name = 'name',
  PostalCode = 'postalCode',
  PhoneNumber = 'phoneNumber',
  Checksum = 'checksum'
}

export type InformPartnerCustomerOrderByRelevanceInput = {
  fields: Array<InformPartnerCustomerOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformPartnerCustomerOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  parentInformPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  parentExternalId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  _count?: Maybe<InformPartnerCustomerCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerCustomerMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerCustomerMinOrderByAggregateInput>;
};

export type InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  parentInformPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  parentExternalId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentOrderByRelationAggregateInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderOrderByRelationAggregateInput>;
  informPartnerUsers?: Maybe<InformPartnerUserOrderByRelationAggregateInput>;
  roles?: Maybe<InformPartnerUserRoleOrderByRelationAggregateInput>;
  _relevance?: Maybe<InformPartnerCustomerOrderByRelevanceInput>;
};

export type InformPartnerCustomerPharmacyProvider = {
  __typename?: 'InformPartnerCustomerPharmacyProvider';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  informPartnerIntegrationId: Scalars['String'];
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status: InformPartnerCustomerPharmacyProviderStatus;
  name: InformPartnerCustomerPharmacyProviderName;
  type: InformPartnerCustomerPharmacyProviderType;
  externalId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomer>;
  informPartnerIntegration: InformPartnerIntegration;
};

export type InformPartnerCustomerPharmacyProviderCountAggregateOutputType = {
  __typename?: 'InformPartnerCustomerPharmacyProviderCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  informPartnerIntegrationId: Scalars['Int'];
  informPartnerCustomerId: Scalars['Int'];
  status: Scalars['Int'];
  name: Scalars['Int'];
  type: Scalars['Int'];
  externalId: Scalars['Int'];
  onlinePharmacyUrl: Scalars['Int'];
  postalCode: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  settings: Scalars['Int'];
  rawData: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformPartnerCustomerPharmacyProviderCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
};

export type InformPartnerCustomerPharmacyProviderCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name: InformPartnerCustomerPharmacyProviderName;
  type: InformPartnerCustomerPharmacyProviderType;
  externalId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutPharmacyProvidersInput>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomerPharmacyProvidersInput;
};

export type InformPartnerCustomerPharmacyProviderCreateManyInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name: InformPartnerCustomerPharmacyProviderName;
  type: InformPartnerCustomerPharmacyProviderType;
  externalId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerPharmacyProviderCreateManyInformPartnerCustomerInputEnvelope = {
  data: Array<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerCustomerPharmacyProviderCreateManyInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name: InformPartnerCustomerPharmacyProviderName;
  type: InformPartnerCustomerPharmacyProviderType;
  externalId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerPharmacyProviderCreateManyInformPartnerIntegrationInputEnvelope = {
  data: Array<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerCustomerPharmacyProviderCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name: InformPartnerCustomerPharmacyProviderName;
  type: InformPartnerCustomerPharmacyProviderType;
  externalId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput = {
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerCustomerInputEnvelope>;
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
};

export type InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerIntegrationInput = {
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerIntegrationInputEnvelope>;
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
};

export type InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerCustomerInput = {
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
  create: InformPartnerCustomerPharmacyProviderUncheckedCreateWithoutInformPartnerCustomerInput;
};

export type InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerIntegrationInput = {
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
  create: InformPartnerCustomerPharmacyProviderUncheckedCreateWithoutInformPartnerIntegrationInput;
};

export type InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name: InformPartnerCustomerPharmacyProviderName;
  type: InformPartnerCustomerPharmacyProviderType;
  externalId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomerPharmacyProvidersInput;
};

export type InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name: InformPartnerCustomerPharmacyProviderName;
  type: InformPartnerCustomerPharmacyProviderType;
  externalId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutPharmacyProvidersInput>;
};

export type InformPartnerCustomerPharmacyProviderExternalIdNameCompoundUniqueInput = {
  externalId: Scalars['String'];
  name: InformPartnerCustomerPharmacyProviderName;
};

export type InformPartnerCustomerPharmacyProviderListRelationFilter = {
  every?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
  some?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
  none?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
};

export type InformPartnerCustomerPharmacyProviderMaxAggregateOutputType = {
  __typename?: 'InformPartnerCustomerPharmacyProviderMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  externalId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type InformPartnerCustomerPharmacyProviderMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
};

export type InformPartnerCustomerPharmacyProviderMinAggregateOutputType = {
  __typename?: 'InformPartnerCustomerPharmacyProviderMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  externalId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type InformPartnerCustomerPharmacyProviderMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
};

export enum InformPartnerCustomerPharmacyProviderName {
  Vetsource = 'Vetsource'
}

export type InformPartnerCustomerPharmacyProviderOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerCustomerPharmacyProviderOrderByRelevanceFieldEnum {
  Id = 'id',
  InformPartnerIntegrationId = 'informPartnerIntegrationId',
  InformPartnerCustomerId = 'informPartnerCustomerId',
  ExternalId = 'externalId',
  OnlinePharmacyUrl = 'onlinePharmacyUrl',
  PostalCode = 'postalCode',
  PhoneNumber = 'phoneNumber'
}

export type InformPartnerCustomerPharmacyProviderOrderByRelevanceInput = {
  fields: Array<InformPartnerCustomerPharmacyProviderOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformPartnerCustomerPharmacyProviderOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  _count?: Maybe<InformPartnerCustomerPharmacyProviderCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerCustomerPharmacyProviderMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerCustomerPharmacyProviderMinOrderByAggregateInput>;
};

export type InformPartnerCustomerPharmacyProviderOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<InformPartnerCustomerPharmacyProviderOrderByRelevanceInput>;
};

export enum InformPartnerCustomerPharmacyProviderScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InformPartnerIntegrationId = 'informPartnerIntegrationId',
  InformPartnerCustomerId = 'informPartnerCustomerId',
  Status = 'status',
  Name = 'name',
  Type = 'type',
  ExternalId = 'externalId',
  OnlinePharmacyUrl = 'onlinePharmacyUrl',
  PostalCode = 'postalCode',
  PhoneNumber = 'phoneNumber',
  Settings = 'settings',
  RawData = 'rawData'
}

export type InformPartnerCustomerPharmacyProviderScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  informPartnerCustomerId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumInformPartnerCustomerPharmacyProviderStatusFilter>;
  name?: Maybe<EnumInformPartnerCustomerPharmacyProviderNameFilter>;
  type?: Maybe<EnumInformPartnerCustomerPharmacyProviderTypeFilter>;
  externalId?: Maybe<StringFilter>;
  onlinePharmacyUrl?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  rawData?: Maybe<JsonNullableFilter>;
};

export type InformPartnerCustomerPharmacyProviderScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  informPartnerIntegrationId?: Maybe<StringWithAggregatesFilter>;
  informPartnerCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumInformPartnerCustomerPharmacyProviderStatusWithAggregatesFilter>;
  name?: Maybe<EnumInformPartnerCustomerPharmacyProviderNameWithAggregatesFilter>;
  type?: Maybe<EnumInformPartnerCustomerPharmacyProviderTypeWithAggregatesFilter>;
  externalId?: Maybe<StringWithAggregatesFilter>;
  onlinePharmacyUrl?: Maybe<StringNullableWithAggregatesFilter>;
  postalCode?: Maybe<StringNullableWithAggregatesFilter>;
  phoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
  rawData?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum InformPartnerCustomerPharmacyProviderStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum InformPartnerCustomerPharmacyProviderType {
  Online = 'Online',
  InStore = 'InStore',
  OnlineAndInStore = 'OnlineAndInStore'
}

export type InformPartnerCustomerPharmacyProviderUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name: InformPartnerCustomerPharmacyProviderName;
  type: InformPartnerCustomerPharmacyProviderType;
  externalId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput = {
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerCustomerInputEnvelope>;
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput = {
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerIntegrationInputEnvelope>;
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedCreateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name: InformPartnerCustomerPharmacyProviderName;
  type: InformPartnerCustomerPharmacyProviderType;
  externalId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedCreateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name: InformPartnerCustomerPharmacyProviderName;
  type: InformPartnerCustomerPharmacyProviderType;
  externalId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  externalId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  externalId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput = {
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpsertWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerCustomerInputEnvelope>;
  set?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateManyWithWhereWithoutInformPartnerCustomerInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereInput>>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerPharmacyProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  externalId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerIntegrationInputEnvelope>;
  set?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereInput>>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutPharmacyProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  externalId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  externalId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  externalId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerPharmacyProviderUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  externalId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutPharmacyProvidersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomerPharmacyProvidersNestedInput>;
};

export type InformPartnerCustomerPharmacyProviderUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  externalId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerPharmacyProviderUpdateManyWithWhereWithoutInformPartnerCustomerInput = {
  where: InformPartnerCustomerPharmacyProviderScalarWhereInput;
  data: InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutPharmacyProvidersInput;
};

export type InformPartnerCustomerPharmacyProviderUpdateManyWithWhereWithoutInformPartnerIntegrationInput = {
  where: InformPartnerCustomerPharmacyProviderScalarWhereInput;
  data: InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerPharmacyProvidersInput;
};

export type InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput = {
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpsertWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerCustomerInputEnvelope>;
  set?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateManyWithWhereWithoutInformPartnerCustomerInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereInput>>;
};

export type InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerIntegrationInputEnvelope>;
  set?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereInput>>;
};

export type InformPartnerCustomerPharmacyProviderUpdateWithWhereUniqueWithoutInformPartnerCustomerInput = {
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
  data: InformPartnerCustomerPharmacyProviderUncheckedUpdateWithoutInformPartnerCustomerInput;
};

export type InformPartnerCustomerPharmacyProviderUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
  data: InformPartnerCustomerPharmacyProviderUncheckedUpdateWithoutInformPartnerIntegrationInput;
};

export type InformPartnerCustomerPharmacyProviderUpdateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  externalId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomerPharmacyProvidersNestedInput>;
};

export type InformPartnerCustomerPharmacyProviderUpdateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  externalId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutPharmacyProvidersNestedInput>;
};

export type InformPartnerCustomerPharmacyProviderUpsertWithWhereUniqueWithoutInformPartnerCustomerInput = {
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
  update: InformPartnerCustomerPharmacyProviderUncheckedUpdateWithoutInformPartnerCustomerInput;
  create: InformPartnerCustomerPharmacyProviderUncheckedCreateWithoutInformPartnerCustomerInput;
};

export type InformPartnerCustomerPharmacyProviderUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
  update: InformPartnerCustomerPharmacyProviderUncheckedUpdateWithoutInformPartnerIntegrationInput;
  create: InformPartnerCustomerPharmacyProviderUncheckedCreateWithoutInformPartnerIntegrationInput;
};

export type InformPartnerCustomerPharmacyProviderWhereInput = {
  AND?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereInput>>;
  OR?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereInput>>;
  NOT?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  informPartnerCustomerId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumInformPartnerCustomerPharmacyProviderStatusFilter>;
  name?: Maybe<EnumInformPartnerCustomerPharmacyProviderNameFilter>;
  type?: Maybe<EnumInformPartnerCustomerPharmacyProviderTypeFilter>;
  externalId?: Maybe<StringFilter>;
  onlinePharmacyUrl?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  rawData?: Maybe<JsonNullableFilter>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerWhereInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationWhereInput>;
};

export type InformPartnerCustomerPharmacyProviderWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  externalId_name?: Maybe<InformPartnerCustomerPharmacyProviderExternalIdNameCompoundUniqueInput>;
};

export type InformPartnerCustomerRelationFilter = {
  is?: Maybe<InformPartnerCustomerWhereInput>;
  isNot?: Maybe<InformPartnerCustomerWhereInput>;
};

export enum InformPartnerCustomerScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  ParentInformPartnerCustomerId = 'parentInformPartnerCustomerId',
  InformPartnerId = 'informPartnerId',
  InformPartnerIntegrationId = 'informPartnerIntegrationId',
  ExternalId = 'externalId',
  ParentExternalId = 'parentExternalId',
  Name = 'name',
  PostalCode = 'postalCode',
  PhoneNumber = 'phoneNumber',
  Checksum = 'checksum',
  RawData = 'rawData'
}

export type InformPartnerCustomerScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  parentInformPartnerCustomerId?: Maybe<StringNullableFilter>;
  informPartnerId?: Maybe<StringFilter>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  externalId?: Maybe<StringFilter>;
  parentExternalId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  checksum?: Maybe<StringFilter>;
  rawData?: Maybe<JsonNullableFilter>;
};

export type InformPartnerCustomerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerCustomerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerCustomerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerCustomerScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  parentInformPartnerCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
  informPartnerId?: Maybe<StringWithAggregatesFilter>;
  informPartnerIntegrationId?: Maybe<StringWithAggregatesFilter>;
  externalId?: Maybe<StringWithAggregatesFilter>;
  parentExternalId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  postalCode?: Maybe<StringNullableWithAggregatesFilter>;
  phoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  checksum?: Maybe<StringWithAggregatesFilter>;
  rawData?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type InformPartnerCustomerUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
};

export type InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerIntegrationInputEnvelope>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
};

export type InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerUsersInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerUsersInput>>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
};

export type InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutParentInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutParentInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyParentInformPartnerCustomerInputEnvelope>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
};

export type InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput = {
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutClinicInput>;
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
};

export type InformPartnerCustomerUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerUncheckedCreateWithoutChildInformPartnerCustomersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerUncheckedCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerUncheckedCreateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerUncheckedCreateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerUncheckedCreateWithoutParentInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerUncheckedCreateWithoutPharmacyProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCustomerUncheckedCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  externalId: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
};

export type InformPartnerCustomerUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerUncheckedUpdateManyWithoutChildInformPartnerCustomersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerCustomersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerIntegrationInputEnvelope>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
};

export type InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
};

export type InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerUsersInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerUsersInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerUsersInput>>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerUsersInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerUsersInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
};

export type InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutParentInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutParentInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutParentInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyParentInformPartnerCustomerInputEnvelope>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutParentInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutParentInformPartnerCustomerInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
};

export type InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput = {
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutClinicInput>;
  upsert?: Maybe<InformPartnerCustomerUpsertWithoutClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  update?: Maybe<InformPartnerCustomerUncheckedUpdateWithoutClinicInput>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutChildInformPartnerCustomersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutParentInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutPharmacyProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
};

export type InformPartnerCustomerUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerInput = {
  where: InformPartnerCustomerScalarWhereInput;
  data: InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerCustomerInput;
};

export type InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerIntegrationInput = {
  where: InformPartnerCustomerScalarWhereInput;
  data: InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerCustomersInput;
};

export type InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerUsersInput = {
  where: InformPartnerCustomerScalarWhereInput;
  data: InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerCustomersInput;
};

export type InformPartnerCustomerUpdateManyWithWhereWithoutParentInformPartnerCustomerInput = {
  where: InformPartnerCustomerScalarWhereInput;
  data: InformPartnerCustomerUncheckedUpdateManyWithoutChildInformPartnerCustomersInput;
};

export type InformPartnerCustomerUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerIntegrationInputEnvelope>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
};

export type InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
};

export type InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerUsersInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerUsersInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerUsersInput>>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerUsersInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerUsersInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
};

export type InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput = {
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutParentInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutParentInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutParentInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyParentInformPartnerCustomerInputEnvelope>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutParentInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutParentInformPartnerCustomerInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
};

export type InformPartnerCustomerUpdateOneRequiredWithoutBoehringerIngelheimCustomerAlignmentsNestedInput = {
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  upsert?: Maybe<InformPartnerCustomerUpsertWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  update?: Maybe<InformPartnerCustomerUncheckedUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput>;
};

export type InformPartnerCustomerUpdateOneRequiredWithoutRolesNestedInput = {
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutRolesInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutRolesInput>;
  upsert?: Maybe<InformPartnerCustomerUpsertWithoutRolesInput>;
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  update?: Maybe<InformPartnerCustomerUncheckedUpdateWithoutRolesInput>;
};

export type InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput = {
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutChildInformPartnerCustomersInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutChildInformPartnerCustomersInput>;
  upsert?: Maybe<InformPartnerCustomerUpsertWithoutChildInformPartnerCustomersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  update?: Maybe<InformPartnerCustomerUncheckedUpdateWithoutChildInformPartnerCustomersInput>;
};

export type InformPartnerCustomerUpdateOneWithoutClinicNestedInput = {
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutClinicInput>;
  upsert?: Maybe<InformPartnerCustomerUpsertWithoutClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  update?: Maybe<InformPartnerCustomerUncheckedUpdateWithoutClinicInput>;
};

export type InformPartnerCustomerUpdateOneWithoutPharmacyProvidersNestedInput = {
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutPharmacyProvidersInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutPharmacyProvidersInput>;
  upsert?: Maybe<InformPartnerCustomerUpsertWithoutPharmacyProvidersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  update?: Maybe<InformPartnerCustomerUncheckedUpdateWithoutPharmacyProvidersInput>;
};

export type InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  data: InformPartnerCustomerUncheckedUpdateWithoutInformPartnerInput;
};

export type InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  data: InformPartnerCustomerUncheckedUpdateWithoutInformPartnerIntegrationInput;
};

export type InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerUsersInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  data: InformPartnerCustomerUncheckedUpdateWithoutInformPartnerUsersInput;
};

export type InformPartnerCustomerUpdateWithWhereUniqueWithoutParentInformPartnerCustomerInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  data: InformPartnerCustomerUncheckedUpdateWithoutParentInformPartnerCustomerInput;
};

export type InformPartnerCustomerUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUpdateWithoutChildInformPartnerCustomersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUpdateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUpdateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUpdateWithoutParentInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUpdateWithoutPharmacyProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
};

export type InformPartnerCustomerUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
};

export type InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  update: InformPartnerCustomerUncheckedUpdateWithoutInformPartnerInput;
  create: InformPartnerCustomerUncheckedCreateWithoutInformPartnerInput;
};

export type InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  update: InformPartnerCustomerUncheckedUpdateWithoutInformPartnerIntegrationInput;
  create: InformPartnerCustomerUncheckedCreateWithoutInformPartnerIntegrationInput;
};

export type InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerUsersInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  update: InformPartnerCustomerUncheckedUpdateWithoutInformPartnerUsersInput;
  create: InformPartnerCustomerUncheckedCreateWithoutInformPartnerUsersInput;
};

export type InformPartnerCustomerUpsertWithWhereUniqueWithoutParentInformPartnerCustomerInput = {
  where: InformPartnerCustomerWhereUniqueInput;
  update: InformPartnerCustomerUncheckedUpdateWithoutParentInformPartnerCustomerInput;
  create: InformPartnerCustomerUncheckedCreateWithoutParentInformPartnerCustomerInput;
};

export type InformPartnerCustomerUpsertWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  update: InformPartnerCustomerUncheckedUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput;
  create: InformPartnerCustomerUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput;
};

export type InformPartnerCustomerUpsertWithoutChildInformPartnerCustomersInput = {
  update: InformPartnerCustomerUncheckedUpdateWithoutChildInformPartnerCustomersInput;
  create: InformPartnerCustomerUncheckedCreateWithoutChildInformPartnerCustomersInput;
};

export type InformPartnerCustomerUpsertWithoutClinicInput = {
  update: InformPartnerCustomerUncheckedUpdateWithoutClinicInput;
  create: InformPartnerCustomerUncheckedCreateWithoutClinicInput;
};

export type InformPartnerCustomerUpsertWithoutPharmacyProvidersInput = {
  update: InformPartnerCustomerUncheckedUpdateWithoutPharmacyProvidersInput;
  create: InformPartnerCustomerUncheckedCreateWithoutPharmacyProvidersInput;
};

export type InformPartnerCustomerUpsertWithoutRolesInput = {
  update: InformPartnerCustomerUncheckedUpdateWithoutRolesInput;
  create: InformPartnerCustomerUncheckedCreateWithoutRolesInput;
};

export type InformPartnerCustomerWhereInput = {
  AND?: Maybe<Array<InformPartnerCustomerWhereInput>>;
  OR?: Maybe<Array<InformPartnerCustomerWhereInput>>;
  NOT?: Maybe<Array<InformPartnerCustomerWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  parentInformPartnerCustomerId?: Maybe<StringNullableFilter>;
  informPartnerId?: Maybe<StringFilter>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  externalId?: Maybe<StringFilter>;
  parentExternalId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  checksum?: Maybe<StringFilter>;
  rawData?: Maybe<JsonNullableFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationWhereInput>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentListRelationFilter>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerWhereInput>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderListRelationFilter>;
  informPartnerUsers?: Maybe<InformPartnerUserListRelationFilter>;
  roles?: Maybe<InformPartnerUserRoleListRelationFilter>;
};

export type InformPartnerCustomerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  informPartnerId_externalId?: Maybe<InformPartnerCustomerInformPartnerIdExternalIdCompoundUniqueInput>;
};

export type InformPartnerIntegration = {
  __typename?: 'InformPartnerIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  informPartnerId: Scalars['String'];
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  models: Array<InformPartnerIntegrationModel>;
  informPartner: InformPartner;
  informPartnerUsers: Array<InformPartnerUser>;
  informPartnerCustomers: Array<InformPartnerCustomer>;
  informPartnerCustomerPharmacyProviders: Array<InformPartnerCustomerPharmacyProvider>;
  _count: InformPartnerIntegrationCountOutputType;
};


export type InformPartnerIntegrationModelsArgs = {
  where?: Maybe<InformPartnerIntegrationModelWhereInput>;
  orderBy?: Maybe<InformPartnerIntegrationModelOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerIntegrationModelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerIntegrationModelScalarFieldEnum>;
};


export type InformPartnerIntegrationInformPartnerUsersArgs = {
  where?: Maybe<InformPartnerUserWhereInput>;
  orderBy?: Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerUserScalarFieldEnum>;
};


export type InformPartnerIntegrationInformPartnerCustomersArgs = {
  where?: Maybe<InformPartnerCustomerWhereInput>;
  orderBy?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerCustomerScalarFieldEnum>;
};


export type InformPartnerIntegrationInformPartnerCustomerPharmacyProvidersArgs = {
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
  orderBy?: Maybe<InformPartnerCustomerPharmacyProviderOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerCustomerPharmacyProviderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerCustomerPharmacyProviderScalarFieldEnum>;
};

export type InformPartnerIntegrationCountAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  credentials: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformPartnerIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  credentials?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationCountOutputType = {
  __typename?: 'InformPartnerIntegrationCountOutputType';
  models: Scalars['Int'];
  informPartnerUsers: Scalars['Int'];
  informPartnerCustomers: Scalars['Int'];
  informPartnerCustomerPharmacyProviders: Scalars['Int'];
};

export type InformPartnerIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerIntegrationsInput;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerIntegrationInput>;
};

export type InformPartnerIntegrationCreateManyInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
};

export type InformPartnerIntegrationCreateManyInformPartnerInputEnvelope = {
  data: Array<InformPartnerIntegrationCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
};

export type InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<InformPartnerIntegrationCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerIntegrationCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
};

export type InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomerPharmacyProvidersInput = {
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomerPharmacyProvidersInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerCustomerPharmacyProvidersInput>;
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
};

export type InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput = {
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomersInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerCustomersInput>;
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
};

export type InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput = {
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutInformPartnerUsersInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerUsersInput>;
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
};

export type InformPartnerIntegrationCreateNestedOneWithoutModelsInput = {
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutModelsInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutModelsInput>;
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
};

export type InformPartnerIntegrationCreateOrConnectWithoutInformPartnerCustomerPharmacyProvidersInput = {
  where: InformPartnerIntegrationWhereUniqueInput;
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomerPharmacyProvidersInput;
};

export type InformPartnerIntegrationCreateOrConnectWithoutInformPartnerCustomersInput = {
  where: InformPartnerIntegrationWhereUniqueInput;
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomersInput;
};

export type InformPartnerIntegrationCreateOrConnectWithoutInformPartnerInput = {
  where: InformPartnerIntegrationWhereUniqueInput;
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerInput;
};

export type InformPartnerIntegrationCreateOrConnectWithoutInformPartnerUsersInput = {
  where: InformPartnerIntegrationWhereUniqueInput;
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerUsersInput;
};

export type InformPartnerIntegrationCreateOrConnectWithoutModelsInput = {
  where: InformPartnerIntegrationWhereUniqueInput;
  create: InformPartnerIntegrationUncheckedCreateWithoutModelsInput;
};

export type InformPartnerIntegrationCreateWithoutInformPartnerCustomerPharmacyProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerIntegrationsInput;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerIntegrationInput>;
};

export type InformPartnerIntegrationCreateWithoutInformPartnerCustomersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerIntegrationsInput;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerIntegrationInput>;
};

export type InformPartnerIntegrationCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerIntegrationInput>;
};

export type InformPartnerIntegrationCreateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerIntegrationsInput;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerIntegrationInput>;
};

export type InformPartnerIntegrationCreateWithoutModelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerIntegrationsInput;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerIntegrationInput>;
};

export type InformPartnerIntegrationListRelationFilter = {
  every?: Maybe<InformPartnerIntegrationWhereInput>;
  some?: Maybe<InformPartnerIntegrationWhereInput>;
  none?: Maybe<InformPartnerIntegrationWhereInput>;
};

export type InformPartnerIntegrationMaxAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
};

export type InformPartnerIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationMinAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
};

export type InformPartnerIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationModel = {
  __typename?: 'InformPartnerIntegrationModel';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  informPartnerIntegrationId: Scalars['String'];
  status: InformPartnerIntegrationModelStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: InformPartnerIntegrationModelName;
  syncFrequencyInMinutes: Scalars['Int'];
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegration: InformPartnerIntegration;
};

export type InformPartnerIntegrationModelAvgAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationModelAvgAggregateOutputType';
  syncFrequencyInMinutes?: Maybe<Scalars['Float']>;
};

export type InformPartnerIntegrationModelAvgOrderByAggregateInput = {
  syncFrequencyInMinutes?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationModelCountAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationModelCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  informPartnerIntegrationId: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  name: Scalars['Int'];
  syncFrequencyInMinutes: Scalars['Int'];
  lastSyncedAt: Scalars['Int'];
  executeAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformPartnerIntegrationModelCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationModelCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformPartnerIntegrationModelStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: InformPartnerIntegrationModelName;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutModelsInput;
};

export type InformPartnerIntegrationModelCreateManyInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformPartnerIntegrationModelStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: InformPartnerIntegrationModelName;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelCreateManyInformPartnerIntegrationInputEnvelope = {
  data: Array<InformPartnerIntegrationModelCreateManyInformPartnerIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerIntegrationModelCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  status: InformPartnerIntegrationModelStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: InformPartnerIntegrationModelName;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelCreateNestedManyWithoutInformPartnerIntegrationInput = {
  create?: Maybe<Array<InformPartnerIntegrationModelCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationModelCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerIntegrationModelCreateManyInformPartnerIntegrationInputEnvelope>;
  connect?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
};

export type InformPartnerIntegrationModelCreateOrConnectWithoutInformPartnerIntegrationInput = {
  where: InformPartnerIntegrationModelWhereUniqueInput;
  create: InformPartnerIntegrationModelUncheckedCreateWithoutInformPartnerIntegrationInput;
};

export type InformPartnerIntegrationModelCreateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformPartnerIntegrationModelStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: InformPartnerIntegrationModelName;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelInformPartnerIntegrationIdNameCompoundUniqueInput = {
  informPartnerIntegrationId: Scalars['String'];
  name: InformPartnerIntegrationModelName;
};

export type InformPartnerIntegrationModelListRelationFilter = {
  every?: Maybe<InformPartnerIntegrationModelWhereInput>;
  some?: Maybe<InformPartnerIntegrationModelWhereInput>;
  none?: Maybe<InformPartnerIntegrationModelWhereInput>;
};

export type InformPartnerIntegrationModelMaxAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationModelMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationModelMinAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationModelMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
};

export enum InformPartnerIntegrationModelName {
  InformPartnerUser = 'InformPartnerUser',
  InformPartnerCustomer = 'InformPartnerCustomer',
  BoehringerIngelheimTerritory = 'BoehringerIngelheimTerritory',
  BoehringerIngelheimCustomerAlignment = 'BoehringerIngelheimCustomerAlignment',
  InformPartnerCustomerPharmacyProvider = 'InformPartnerCustomerPharmacyProvider'
}

export type InformPartnerIntegrationModelOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerIntegrationModelOrderByRelevanceFieldEnum {
  Id = 'id',
  InformPartnerIntegrationId = 'informPartnerIntegrationId'
}

export type InformPartnerIntegrationModelOrderByRelevanceInput = {
  fields: Array<InformPartnerIntegrationModelOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformPartnerIntegrationModelOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  _count?: Maybe<InformPartnerIntegrationModelCountOrderByAggregateInput>;
  _avg?: Maybe<InformPartnerIntegrationModelAvgOrderByAggregateInput>;
  _max?: Maybe<InformPartnerIntegrationModelMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerIntegrationModelMinOrderByAggregateInput>;
  _sum?: Maybe<InformPartnerIntegrationModelSumOrderByAggregateInput>;
};

export type InformPartnerIntegrationModelOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<InformPartnerIntegrationModelOrderByRelevanceInput>;
};

export enum InformPartnerIntegrationModelScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InformPartnerIntegrationId = 'informPartnerIntegrationId',
  Status = 'status',
  StatusMessage = 'statusMessage',
  Name = 'name',
  SyncFrequencyInMinutes = 'syncFrequencyInMinutes',
  LastSyncedAt = 'lastSyncedAt',
  ExecuteAt = 'executeAt'
}

export type InformPartnerIntegrationModelScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerIntegrationModelScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerIntegrationModelScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerIntegrationModelScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  status?: Maybe<EnumInformPartnerIntegrationModelStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  name?: Maybe<EnumInformPartnerIntegrationModelNameFilter>;
  syncFrequencyInMinutes?: Maybe<IntFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  executeAt?: Maybe<DateTimeNullableFilter>;
};

export type InformPartnerIntegrationModelScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerIntegrationModelScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerIntegrationModelScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerIntegrationModelScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  informPartnerIntegrationId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumInformPartnerIntegrationModelStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
  name?: Maybe<EnumInformPartnerIntegrationModelNameWithAggregatesFilter>;
  syncFrequencyInMinutes?: Maybe<IntWithAggregatesFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  executeAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
};

export enum InformPartnerIntegrationModelStatus {
  New = 'New',
  Syncing = 'Syncing',
  Synced = 'Synced',
  Error = 'Error',
  Paused = 'Paused'
}

export type InformPartnerIntegrationModelSumAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationModelSumAggregateOutputType';
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
};

export type InformPartnerIntegrationModelSumOrderByAggregateInput = {
  syncFrequencyInMinutes?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationModelUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  status: InformPartnerIntegrationModelStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: InformPartnerIntegrationModelName;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput = {
  create?: Maybe<Array<InformPartnerIntegrationModelCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationModelCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerIntegrationModelCreateManyInformPartnerIntegrationInputEnvelope>;
  connect?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
};

export type InformPartnerIntegrationModelUncheckedCreateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformPartnerIntegrationModelStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  name: InformPartnerIntegrationModelName;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  create?: Maybe<Array<InformPartnerIntegrationModelCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationModelCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerIntegrationModelUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerIntegrationModelCreateManyInformPartnerIntegrationInputEnvelope>;
  set?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerIntegrationModelUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerIntegrationModelUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  deleteMany?: Maybe<Array<InformPartnerIntegrationModelScalarWhereInput>>;
};

export type InformPartnerIntegrationModelUncheckedUpdateManyWithoutModelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUncheckedUpdateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutModelsNestedInput>;
};

export type InformPartnerIntegrationModelUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUpdateManyWithWhereWithoutInformPartnerIntegrationInput = {
  where: InformPartnerIntegrationModelScalarWhereInput;
  data: InformPartnerIntegrationModelUncheckedUpdateManyWithoutModelsInput;
};

export type InformPartnerIntegrationModelUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  create?: Maybe<Array<InformPartnerIntegrationModelCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationModelCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerIntegrationModelUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerIntegrationModelCreateManyInformPartnerIntegrationInputEnvelope>;
  set?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerIntegrationModelUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerIntegrationModelUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  deleteMany?: Maybe<Array<InformPartnerIntegrationModelScalarWhereInput>>;
};

export type InformPartnerIntegrationModelUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  where: InformPartnerIntegrationModelWhereUniqueInput;
  data: InformPartnerIntegrationModelUncheckedUpdateWithoutInformPartnerIntegrationInput;
};

export type InformPartnerIntegrationModelUpdateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  where: InformPartnerIntegrationModelWhereUniqueInput;
  update: InformPartnerIntegrationModelUncheckedUpdateWithoutInformPartnerIntegrationInput;
  create: InformPartnerIntegrationModelUncheckedCreateWithoutInformPartnerIntegrationInput;
};

export type InformPartnerIntegrationModelWhereInput = {
  AND?: Maybe<Array<InformPartnerIntegrationModelWhereInput>>;
  OR?: Maybe<Array<InformPartnerIntegrationModelWhereInput>>;
  NOT?: Maybe<Array<InformPartnerIntegrationModelWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  status?: Maybe<EnumInformPartnerIntegrationModelStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  name?: Maybe<EnumInformPartnerIntegrationModelNameFilter>;
  syncFrequencyInMinutes?: Maybe<IntFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  executeAt?: Maybe<DateTimeNullableFilter>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationWhereInput>;
};

export type InformPartnerIntegrationModelWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId_name?: Maybe<InformPartnerIntegrationModelInformPartnerIntegrationIdNameCompoundUniqueInput>;
};

export type InformPartnerIntegrationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  InformPartnerId = 'informPartnerId'
}

export type InformPartnerIntegrationOrderByRelevanceInput = {
  fields: Array<InformPartnerIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformPartnerIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  credentials?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  _count?: Maybe<InformPartnerIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerIntegrationMinOrderByAggregateInput>;
};

export type InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  credentials?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  models?: Maybe<InformPartnerIntegrationModelOrderByRelationAggregateInput>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerUsers?: Maybe<InformPartnerUserOrderByRelationAggregateInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerOrderByRelationAggregateInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderOrderByRelationAggregateInput>;
  _relevance?: Maybe<InformPartnerIntegrationOrderByRelevanceInput>;
};

export type InformPartnerIntegrationRelationFilter = {
  is?: Maybe<InformPartnerIntegrationWhereInput>;
  isNot?: Maybe<InformPartnerIntegrationWhereInput>;
};

export enum InformPartnerIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InformPartnerId = 'informPartnerId',
  Credentials = 'credentials',
  Status = 'status',
  StatusMessage = 'statusMessage'
}

export type InformPartnerIntegrationScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerIntegrationScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerIntegrationScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerIntegrationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerId?: Maybe<StringFilter>;
  credentials?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformPartnerIntegrationStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
};

export type InformPartnerIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  informPartnerId?: Maybe<StringWithAggregatesFilter>;
  credentials?: Maybe<JsonNullableWithAggregatesFilter>;
  status?: Maybe<EnumInformPartnerIntegrationStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum InformPartnerIntegrationStatus {
  Active = 'Active',
  Paused = 'Paused',
  Archived = 'Archived'
}

export type InformPartnerIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
};

export type InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<InformPartnerIntegrationCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerIntegrationCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
};

export type InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomerPharmacyProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
};

export type InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
};

export type InformPartnerIntegrationUncheckedCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
};

export type InformPartnerIntegrationUncheckedCreateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
};

export type InformPartnerIntegrationUncheckedCreateWithoutModelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  credentials?: Maybe<Scalars['Json']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
};

export type InformPartnerIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
};

export type InformPartnerIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
};

export type InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
};

export type InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<InformPartnerIntegrationCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerIntegrationUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerIntegrationCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerIntegrationUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerIntegrationUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<InformPartnerIntegrationScalarWhereInput>>;
};

export type InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerCustomerPharmacyProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
};

export type InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerCustomersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
};

export type InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
};

export type InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
};

export type InformPartnerIntegrationUncheckedUpdateWithoutModelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
};

export type InformPartnerIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerIntegrationsNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerIntegrationNestedInput>;
};

export type InformPartnerIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
};

export type InformPartnerIntegrationUpdateManyWithWhereWithoutInformPartnerInput = {
  where: InformPartnerIntegrationScalarWhereInput;
  data: InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerIntegrationsInput;
};

export type InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<InformPartnerIntegrationCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerIntegrationUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerIntegrationCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerIntegrationUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerIntegrationUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<InformPartnerIntegrationScalarWhereInput>>;
};

export type InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomerPharmacyProvidersNestedInput = {
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomerPharmacyProvidersInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerCustomerPharmacyProvidersInput>;
  upsert?: Maybe<InformPartnerIntegrationUpsertWithoutInformPartnerCustomerPharmacyProvidersInput>;
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  update?: Maybe<InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerCustomerPharmacyProvidersInput>;
};

export type InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput = {
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomersInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerCustomersInput>;
  upsert?: Maybe<InformPartnerIntegrationUpsertWithoutInformPartnerCustomersInput>;
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  update?: Maybe<InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerCustomersInput>;
};

export type InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput = {
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutInformPartnerUsersInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerUsersInput>;
  upsert?: Maybe<InformPartnerIntegrationUpsertWithoutInformPartnerUsersInput>;
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  update?: Maybe<InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerUsersInput>;
};

export type InformPartnerIntegrationUpdateOneRequiredWithoutModelsNestedInput = {
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutModelsInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutModelsInput>;
  upsert?: Maybe<InformPartnerIntegrationUpsertWithoutModelsInput>;
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  update?: Maybe<InformPartnerIntegrationUncheckedUpdateWithoutModelsInput>;
};

export type InformPartnerIntegrationUpdateWithWhereUniqueWithoutInformPartnerInput = {
  where: InformPartnerIntegrationWhereUniqueInput;
  data: InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerInput;
};

export type InformPartnerIntegrationUpdateWithoutInformPartnerCustomerPharmacyProvidersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerIntegrationsNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerIntegrationNestedInput>;
};

export type InformPartnerIntegrationUpdateWithoutInformPartnerCustomersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerIntegrationsNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerIntegrationNestedInput>;
};

export type InformPartnerIntegrationUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerIntegrationNestedInput>;
};

export type InformPartnerIntegrationUpdateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  models?: Maybe<InformPartnerIntegrationModelUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerIntegrationsNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerIntegrationNestedInput>;
};

export type InformPartnerIntegrationUpdateWithoutModelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerIntegrationsNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerIntegrationNestedInput>;
};

export type InformPartnerIntegrationUpsertWithWhereUniqueWithoutInformPartnerInput = {
  where: InformPartnerIntegrationWhereUniqueInput;
  update: InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerInput;
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerInput;
};

export type InformPartnerIntegrationUpsertWithoutInformPartnerCustomerPharmacyProvidersInput = {
  update: InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerCustomerPharmacyProvidersInput;
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomerPharmacyProvidersInput;
};

export type InformPartnerIntegrationUpsertWithoutInformPartnerCustomersInput = {
  update: InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerCustomersInput;
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomersInput;
};

export type InformPartnerIntegrationUpsertWithoutInformPartnerUsersInput = {
  update: InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerUsersInput;
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerUsersInput;
};

export type InformPartnerIntegrationUpsertWithoutModelsInput = {
  update: InformPartnerIntegrationUncheckedUpdateWithoutModelsInput;
  create: InformPartnerIntegrationUncheckedCreateWithoutModelsInput;
};

export type InformPartnerIntegrationWhereInput = {
  AND?: Maybe<Array<InformPartnerIntegrationWhereInput>>;
  OR?: Maybe<Array<InformPartnerIntegrationWhereInput>>;
  NOT?: Maybe<Array<InformPartnerIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerId?: Maybe<StringFilter>;
  credentials?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformPartnerIntegrationStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  models?: Maybe<InformPartnerIntegrationModelListRelationFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informPartnerUsers?: Maybe<InformPartnerUserListRelationFilter>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerListRelationFilter>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderListRelationFilter>;
};

export type InformPartnerIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformPartnerMaxAggregateOutputType = {
  __typename?: 'InformPartnerMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
};

export type InformPartnerMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type InformPartnerMinAggregateOutputType = {
  __typename?: 'InformPartnerMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
};

export type InformPartnerMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export enum InformPartnerOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  Description = 'description'
}

export type InformPartnerOrderByRelevanceInput = {
  fields: Array<InformPartnerOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformPartnerOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  _count?: Maybe<InformPartnerCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerMinOrderByAggregateInput>;
};

export type InformPartnerOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  informPartnerPrograms?: Maybe<InformPartnerProgramOrderByRelationAggregateInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupOrderByRelationAggregateInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionOrderByRelationAggregateInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateOrderByRelationAggregateInput>;
  products?: Maybe<ProductOrderByRelationAggregateInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationOrderByRelationAggregateInput>;
  informPartnerUsers?: Maybe<InformPartnerUserOrderByRelationAggregateInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerOrderByRelationAggregateInput>;
  _relevance?: Maybe<InformPartnerOrderByRelevanceInput>;
};

export type InformPartnerProgram = {
  __typename?: 'InformPartnerProgram';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  status: InformPartnerProgramStatus;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner: InformPartner;
  informPartnerProgramEnrollments: Array<InformPartnerProgramEnrollment>;
  informCampaigns: Array<InformCampaign>;
  _count: InformPartnerProgramCountOutputType;
};


export type InformPartnerProgramInformPartnerProgramEnrollmentsArgs = {
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  orderBy?: Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerProgramEnrollmentScalarFieldEnum>;
};


export type InformPartnerProgramInformCampaignsArgs = {
  where?: Maybe<InformCampaignWhereInput>;
  orderBy?: Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformCampaignScalarFieldEnum>;
};

export type InformPartnerProgramCountAggregateOutputType = {
  __typename?: 'InformPartnerProgramCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  description: Scalars['Int'];
  settings: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformPartnerProgramCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
};

export type InformPartnerProgramCountOutputType = {
  __typename?: 'InformPartnerProgramCountOutputType';
  informPartnerProgramEnrollments: Scalars['Int'];
  informCampaigns: Scalars['Int'];
};

export type InformPartnerProgramCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerProgramsInput;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutInformPartnerProgramInput>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformPartnerProgramInput>;
};

export type InformPartnerProgramCreateManyInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramCreateManyInformPartnerInputEnvelope = {
  data: Array<InformPartnerProgramCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerProgramCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<InformPartnerProgramCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerProgramCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
};

export type InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput = {
  create?: Maybe<InformPartnerProgramUncheckedCreateWithoutInformCampaignsInput>;
  connectOrCreate?: Maybe<InformPartnerProgramCreateOrConnectWithoutInformCampaignsInput>;
  connect?: Maybe<InformPartnerProgramWhereUniqueInput>;
};

export type InformPartnerProgramCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput = {
  create?: Maybe<InformPartnerProgramUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput>;
  connectOrCreate?: Maybe<InformPartnerProgramCreateOrConnectWithoutInformPartnerProgramEnrollmentsInput>;
  connect?: Maybe<InformPartnerProgramWhereUniqueInput>;
};

export type InformPartnerProgramCreateOrConnectWithoutInformCampaignsInput = {
  where: InformPartnerProgramWhereUniqueInput;
  create: InformPartnerProgramUncheckedCreateWithoutInformCampaignsInput;
};

export type InformPartnerProgramCreateOrConnectWithoutInformPartnerInput = {
  where: InformPartnerProgramWhereUniqueInput;
  create: InformPartnerProgramUncheckedCreateWithoutInformPartnerInput;
};

export type InformPartnerProgramCreateOrConnectWithoutInformPartnerProgramEnrollmentsInput = {
  where: InformPartnerProgramWhereUniqueInput;
  create: InformPartnerProgramUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput;
};

export type InformPartnerProgramCreateWithoutInformCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerProgramsInput;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutInformPartnerProgramInput>;
};

export type InformPartnerProgramCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutInformPartnerProgramInput>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformPartnerProgramInput>;
};

export type InformPartnerProgramCreateWithoutInformPartnerProgramEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerProgramsInput;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformPartnerProgramInput>;
};

export type InformPartnerProgramEnrollment = {
  __typename?: 'InformPartnerProgramEnrollment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  clinicId: Scalars['String'];
  informPartnerProgramId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  clinic: Clinic;
  informPartnerProgram: InformPartnerProgram;
  acceptedTermsByUser?: Maybe<User>;
  informCampaignEnrollments: Array<InformCampaignEnrollment>;
  _count: InformPartnerProgramEnrollmentCountOutputType;
};


export type InformPartnerProgramEnrollmentInformCampaignEnrollmentsArgs = {
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
  orderBy?: Maybe<InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformCampaignEnrollmentScalarFieldEnum>;
};

export type InformPartnerProgramEnrollmentClinicIdInformPartnerProgramIdCompoundUniqueInput = {
  clinicId: Scalars['String'];
  informPartnerProgramId: Scalars['String'];
};

export type InformPartnerProgramEnrollmentCountAggregateOutputType = {
  __typename?: 'InformPartnerProgramEnrollmentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  clinicId: Scalars['Int'];
  informPartnerProgramId: Scalars['Int'];
  externalId: Scalars['Int'];
  acceptedTermsByUserId: Scalars['Int'];
  acceptedTermsAt: Scalars['Int'];
  statusUpdatedAt: Scalars['Int'];
  settings: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformPartnerProgramEnrollmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  acceptedTermsByUserId?: Maybe<SortOrder>;
  acceptedTermsAt?: Maybe<SortOrder>;
  statusUpdatedAt?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
};

export type InformPartnerProgramEnrollmentCountOutputType = {
  __typename?: 'InformPartnerProgramEnrollmentCountOutputType';
  informCampaignEnrollments: Scalars['Int'];
};

export type InformPartnerProgramEnrollmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  acceptedTermsByUser?: Maybe<UserCreateNestedOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
};

export type InformPartnerProgramEnrollmentCreateManyAcceptedTermsByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  clinicId: Scalars['String'];
  informPartnerProgramId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramEnrollmentCreateManyAcceptedTermsByUserInputEnvelope = {
  data: Array<InformPartnerProgramEnrollmentCreateManyAcceptedTermsByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerProgramEnrollmentCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramEnrollmentCreateManyClinicInputEnvelope = {
  data: Array<InformPartnerProgramEnrollmentCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerProgramEnrollmentCreateManyInformPartnerProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  clinicId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramEnrollmentCreateManyInformPartnerProgramInputEnvelope = {
  data: Array<InformPartnerProgramEnrollmentCreateManyInformPartnerProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerProgramEnrollmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  clinicId: Scalars['String'];
  informPartnerProgramId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput = {
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutAcceptedTermsByUserInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutAcceptedTermsByUserInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyAcceptedTermsByUserInputEnvelope>;
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
};

export type InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
};

export type InformPartnerProgramEnrollmentCreateNestedManyWithoutInformPartnerProgramInput = {
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutInformPartnerProgramInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyInformPartnerProgramInputEnvelope>;
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
};

export type InformPartnerProgramEnrollmentCreateNestedOneWithoutInformCampaignEnrollmentsInput = {
  create?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentsInput>;
  connectOrCreate?: Maybe<InformPartnerProgramEnrollmentCreateOrConnectWithoutInformCampaignEnrollmentsInput>;
  connect?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
};

export type InformPartnerProgramEnrollmentCreateOrConnectWithoutAcceptedTermsByUserInput = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutAcceptedTermsByUserInput;
};

export type InformPartnerProgramEnrollmentCreateOrConnectWithoutClinicInput = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutClinicInput;
};

export type InformPartnerProgramEnrollmentCreateOrConnectWithoutInformCampaignEnrollmentsInput = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentsInput;
};

export type InformPartnerProgramEnrollmentCreateOrConnectWithoutInformPartnerProgramInput = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutInformPartnerProgramInput;
};

export type InformPartnerProgramEnrollmentCreateWithoutAcceptedTermsByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
};

export type InformPartnerProgramEnrollmentCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  acceptedTermsByUser?: Maybe<UserCreateNestedOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
};

export type InformPartnerProgramEnrollmentCreateWithoutInformCampaignEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  acceptedTermsByUser?: Maybe<UserCreateNestedOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
};

export type InformPartnerProgramEnrollmentCreateWithoutInformPartnerProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  acceptedTermsByUser?: Maybe<UserCreateNestedOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
};

export type InformPartnerProgramEnrollmentListRelationFilter = {
  every?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  some?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  none?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};

export type InformPartnerProgramEnrollmentMaxAggregateOutputType = {
  __typename?: 'InformPartnerProgramEnrollmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  clinicId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  acceptedTermsByUserId?: Maybe<SortOrder>;
  acceptedTermsAt?: Maybe<SortOrder>;
  statusUpdatedAt?: Maybe<SortOrder>;
};

export type InformPartnerProgramEnrollmentMinAggregateOutputType = {
  __typename?: 'InformPartnerProgramEnrollmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  clinicId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  acceptedTermsByUserId?: Maybe<SortOrder>;
  acceptedTermsAt?: Maybe<SortOrder>;
  statusUpdatedAt?: Maybe<SortOrder>;
};

export type InformPartnerProgramEnrollmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerProgramEnrollmentOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  InformPartnerProgramId = 'informPartnerProgramId',
  ExternalId = 'externalId',
  AcceptedTermsByUserId = 'acceptedTermsByUserId'
}

export type InformPartnerProgramEnrollmentOrderByRelevanceInput = {
  fields: Array<InformPartnerProgramEnrollmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformPartnerProgramEnrollmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  acceptedTermsByUserId?: Maybe<SortOrder>;
  acceptedTermsAt?: Maybe<SortOrder>;
  statusUpdatedAt?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  _count?: Maybe<InformPartnerProgramEnrollmentCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerProgramEnrollmentMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerProgramEnrollmentMinOrderByAggregateInput>;
};

export type InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  acceptedTermsByUserId?: Maybe<SortOrder>;
  acceptedTermsAt?: Maybe<SortOrder>;
  statusUpdatedAt?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput>;
  acceptedTermsByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentOrderByRelationAggregateInput>;
  _relevance?: Maybe<InformPartnerProgramEnrollmentOrderByRelevanceInput>;
};

export type InformPartnerProgramEnrollmentRelationFilter = {
  is?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  isNot?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};

export enum InformPartnerProgramEnrollmentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  StatusMessage = 'statusMessage',
  ClinicId = 'clinicId',
  InformPartnerProgramId = 'informPartnerProgramId',
  ExternalId = 'externalId',
  AcceptedTermsByUserId = 'acceptedTermsByUserId',
  AcceptedTermsAt = 'acceptedTermsAt',
  StatusUpdatedAt = 'statusUpdatedAt',
  Settings = 'settings'
}

export type InformPartnerProgramEnrollmentScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumInformEnrollmentStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  informPartnerProgramId?: Maybe<StringFilter>;
  externalId?: Maybe<StringNullableFilter>;
  acceptedTermsByUserId?: Maybe<StringNullableFilter>;
  acceptedTermsAt?: Maybe<DateTimeNullableFilter>;
  statusUpdatedAt?: Maybe<DateTimeNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
};

export type InformPartnerProgramEnrollmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumInformEnrollmentStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  informPartnerProgramId?: Maybe<StringWithAggregatesFilter>;
  externalId?: Maybe<StringNullableWithAggregatesFilter>;
  acceptedTermsByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  acceptedTermsAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  statusUpdatedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  clinicId: Scalars['String'];
  informPartnerProgramId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput = {
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutAcceptedTermsByUserInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutAcceptedTermsByUserInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyAcceptedTermsByUserInputEnvelope>;
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramInput = {
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutInformPartnerProgramInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyInformPartnerProgramInputEnvelope>;
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateWithoutAcceptedTermsByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  clinicId: Scalars['String'];
  informPartnerProgramId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  clinicId: Scalars['String'];
  informPartnerProgramId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateWithoutInformPartnerProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  clinicId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput = {
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutAcceptedTermsByUserInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutAcceptedTermsByUserInput>>;
  upsert?: Maybe<Array<InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutAcceptedTermsByUserInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyAcceptedTermsByUserInputEnvelope>;
  set?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutAcceptedTermsByUserInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutAcceptedTermsByUserInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramNestedInput = {
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutInformPartnerProgramInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutInformPartnerProgramInput>>;
  upsert?: Maybe<Array<InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyInformPartnerProgramInputEnvelope>;
  set?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutInformPartnerProgramInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutInformPartnerProgramInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateWithoutAcceptedTermsByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateWithoutInformCampaignEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateWithoutInformPartnerProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
};

export type InformPartnerProgramEnrollmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  acceptedTermsByUser?: Maybe<UserUpdateOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
};

export type InformPartnerProgramEnrollmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutAcceptedTermsByUserInput = {
  where: InformPartnerProgramEnrollmentScalarWhereInput;
  data: InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput;
};

export type InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutClinicInput = {
  where: InformPartnerProgramEnrollmentScalarWhereInput;
  data: InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentsInput;
};

export type InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutInformPartnerProgramInput = {
  where: InformPartnerProgramEnrollmentScalarWhereInput;
  data: InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentsInput;
};

export type InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput = {
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutAcceptedTermsByUserInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutAcceptedTermsByUserInput>>;
  upsert?: Maybe<Array<InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutAcceptedTermsByUserInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyAcceptedTermsByUserInputEnvelope>;
  set?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutAcceptedTermsByUserInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutAcceptedTermsByUserInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
};

export type InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
};

export type InformPartnerProgramEnrollmentUpdateManyWithoutInformPartnerProgramNestedInput = {
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutInformPartnerProgramInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutInformPartnerProgramInput>>;
  upsert?: Maybe<Array<InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyInformPartnerProgramInputEnvelope>;
  set?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutInformPartnerProgramInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutInformPartnerProgramInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
};

export type InformPartnerProgramEnrollmentUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput = {
  create?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentsInput>;
  connectOrCreate?: Maybe<InformPartnerProgramEnrollmentCreateOrConnectWithoutInformCampaignEnrollmentsInput>;
  upsert?: Maybe<InformPartnerProgramEnrollmentUpsertWithoutInformCampaignEnrollmentsInput>;
  connect?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  update?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateWithoutInformCampaignEnrollmentsInput>;
};

export type InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutAcceptedTermsByUserInput = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
  data: InformPartnerProgramEnrollmentUncheckedUpdateWithoutAcceptedTermsByUserInput;
};

export type InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutClinicInput = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
  data: InformPartnerProgramEnrollmentUncheckedUpdateWithoutClinicInput;
};

export type InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutInformPartnerProgramInput = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
  data: InformPartnerProgramEnrollmentUncheckedUpdateWithoutInformPartnerProgramInput;
};

export type InformPartnerProgramEnrollmentUpdateWithoutAcceptedTermsByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
};

export type InformPartnerProgramEnrollmentUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  acceptedTermsByUser?: Maybe<UserUpdateOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
};

export type InformPartnerProgramEnrollmentUpdateWithoutInformCampaignEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  acceptedTermsByUser?: Maybe<UserUpdateOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesNestedInput>;
};

export type InformPartnerProgramEnrollmentUpdateWithoutInformPartnerProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  externalId?: Maybe<Scalars['String']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  acceptedTermsByUser?: Maybe<UserUpdateOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
};

export type InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutAcceptedTermsByUserInput = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
  update: InformPartnerProgramEnrollmentUncheckedUpdateWithoutAcceptedTermsByUserInput;
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutAcceptedTermsByUserInput;
};

export type InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutClinicInput = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
  update: InformPartnerProgramEnrollmentUncheckedUpdateWithoutClinicInput;
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutClinicInput;
};

export type InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutInformPartnerProgramInput = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
  update: InformPartnerProgramEnrollmentUncheckedUpdateWithoutInformPartnerProgramInput;
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutInformPartnerProgramInput;
};

export type InformPartnerProgramEnrollmentUpsertWithoutInformCampaignEnrollmentsInput = {
  update: InformPartnerProgramEnrollmentUncheckedUpdateWithoutInformCampaignEnrollmentsInput;
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentsInput;
};

export type InformPartnerProgramEnrollmentWhereInput = {
  AND?: Maybe<Array<InformPartnerProgramEnrollmentWhereInput>>;
  OR?: Maybe<Array<InformPartnerProgramEnrollmentWhereInput>>;
  NOT?: Maybe<Array<InformPartnerProgramEnrollmentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumInformEnrollmentStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  informPartnerProgramId?: Maybe<StringFilter>;
  externalId?: Maybe<StringNullableFilter>;
  acceptedTermsByUserId?: Maybe<StringNullableFilter>;
  acceptedTermsAt?: Maybe<DateTimeNullableFilter>;
  statusUpdatedAt?: Maybe<DateTimeNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramWhereInput>;
  acceptedTermsByUser?: Maybe<UserWhereInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentListRelationFilter>;
};

export type InformPartnerProgramEnrollmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicId_informPartnerProgramId?: Maybe<InformPartnerProgramEnrollmentClinicIdInformPartnerProgramIdCompoundUniqueInput>;
};

export type InformPartnerProgramInformPartnerIdNameCompoundUniqueInput = {
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
};

export type InformPartnerProgramListRelationFilter = {
  every?: Maybe<InformPartnerProgramWhereInput>;
  some?: Maybe<InformPartnerProgramWhereInput>;
  none?: Maybe<InformPartnerProgramWhereInput>;
};

export type InformPartnerProgramMaxAggregateOutputType = {
  __typename?: 'InformPartnerProgramMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
};

export type InformPartnerProgramMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type InformPartnerProgramMinAggregateOutputType = {
  __typename?: 'InformPartnerProgramMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
};

export type InformPartnerProgramMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type InformPartnerProgramOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerProgramOrderByRelevanceFieldEnum {
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  Name = 'name',
  Description = 'description'
}

export type InformPartnerProgramOrderByRelevanceInput = {
  fields: Array<InformPartnerProgramOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformPartnerProgramOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  _count?: Maybe<InformPartnerProgramCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerProgramMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerProgramMinOrderByAggregateInput>;
};

export type InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentOrderByRelationAggregateInput>;
  informCampaigns?: Maybe<InformCampaignOrderByRelationAggregateInput>;
  _relevance?: Maybe<InformPartnerProgramOrderByRelevanceInput>;
};

export type InformPartnerProgramRelationFilter = {
  is?: Maybe<InformPartnerProgramWhereInput>;
  isNot?: Maybe<InformPartnerProgramWhereInput>;
};

export enum InformPartnerProgramScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InformPartnerId = 'informPartnerId',
  Name = 'name',
  Status = 'status',
  Description = 'description',
  Settings = 'settings'
}

export type InformPartnerProgramScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerProgramScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerProgramScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerProgramScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumInformPartnerProgramStatusFilter>;
  description?: Maybe<StringNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
};

export type InformPartnerProgramScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerProgramScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerProgramScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerProgramScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  informPartnerId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumInformPartnerProgramStatusWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum InformPartnerProgramStatus {
  Active = 'Active',
  Paused = 'Paused',
  Archived = 'Archived'
}

export type InformPartnerProgramUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramInput>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformPartnerProgramInput>;
};

export type InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<InformPartnerProgramCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerProgramCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
};

export type InformPartnerProgramUncheckedCreateWithoutInformCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramInput>;
};

export type InformPartnerProgramUncheckedCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramInput>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformPartnerProgramInput>;
};

export type InformPartnerProgramUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformPartnerProgramInput>;
};

export type InformPartnerProgramUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramNestedInput>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformPartnerProgramNestedInput>;
};

export type InformPartnerProgramUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<InformPartnerProgramCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerProgramUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerProgramCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramScalarWhereInput>>;
};

export type InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerProgramsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramUncheckedUpdateWithoutInformCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramNestedInput>;
};

export type InformPartnerProgramUncheckedUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramNestedInput>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformPartnerProgramNestedInput>;
};

export type InformPartnerProgramUncheckedUpdateWithoutInformPartnerProgramEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformPartnerProgramNestedInput>;
};

export type InformPartnerProgramUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerProgramsNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutInformPartnerProgramNestedInput>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformPartnerProgramNestedInput>;
};

export type InformPartnerProgramUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerProgramUpdateManyWithWhereWithoutInformPartnerInput = {
  where: InformPartnerProgramScalarWhereInput;
  data: InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerProgramsInput;
};

export type InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<InformPartnerProgramCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerProgramUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerProgramCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramScalarWhereInput>>;
};

export type InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput = {
  create?: Maybe<InformPartnerProgramUncheckedCreateWithoutInformCampaignsInput>;
  connectOrCreate?: Maybe<InformPartnerProgramCreateOrConnectWithoutInformCampaignsInput>;
  upsert?: Maybe<InformPartnerProgramUpsertWithoutInformCampaignsInput>;
  connect?: Maybe<InformPartnerProgramWhereUniqueInput>;
  update?: Maybe<InformPartnerProgramUncheckedUpdateWithoutInformCampaignsInput>;
};

export type InformPartnerProgramUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput = {
  create?: Maybe<InformPartnerProgramUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput>;
  connectOrCreate?: Maybe<InformPartnerProgramCreateOrConnectWithoutInformPartnerProgramEnrollmentsInput>;
  upsert?: Maybe<InformPartnerProgramUpsertWithoutInformPartnerProgramEnrollmentsInput>;
  connect?: Maybe<InformPartnerProgramWhereUniqueInput>;
  update?: Maybe<InformPartnerProgramUncheckedUpdateWithoutInformPartnerProgramEnrollmentsInput>;
};

export type InformPartnerProgramUpdateWithWhereUniqueWithoutInformPartnerInput = {
  where: InformPartnerProgramWhereUniqueInput;
  data: InformPartnerProgramUncheckedUpdateWithoutInformPartnerInput;
};

export type InformPartnerProgramUpdateWithoutInformCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerProgramsNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutInformPartnerProgramNestedInput>;
};

export type InformPartnerProgramUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutInformPartnerProgramNestedInput>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformPartnerProgramNestedInput>;
};

export type InformPartnerProgramUpdateWithoutInformPartnerProgramEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerProgramsNestedInput>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformPartnerProgramNestedInput>;
};

export type InformPartnerProgramUpsertWithWhereUniqueWithoutInformPartnerInput = {
  where: InformPartnerProgramWhereUniqueInput;
  update: InformPartnerProgramUncheckedUpdateWithoutInformPartnerInput;
  create: InformPartnerProgramUncheckedCreateWithoutInformPartnerInput;
};

export type InformPartnerProgramUpsertWithoutInformCampaignsInput = {
  update: InformPartnerProgramUncheckedUpdateWithoutInformCampaignsInput;
  create: InformPartnerProgramUncheckedCreateWithoutInformCampaignsInput;
};

export type InformPartnerProgramUpsertWithoutInformPartnerProgramEnrollmentsInput = {
  update: InformPartnerProgramUncheckedUpdateWithoutInformPartnerProgramEnrollmentsInput;
  create: InformPartnerProgramUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput;
};

export type InformPartnerProgramWhereInput = {
  AND?: Maybe<Array<InformPartnerProgramWhereInput>>;
  OR?: Maybe<Array<InformPartnerProgramWhereInput>>;
  NOT?: Maybe<Array<InformPartnerProgramWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumInformPartnerProgramStatusFilter>;
  description?: Maybe<StringNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentListRelationFilter>;
  informCampaigns?: Maybe<InformCampaignListRelationFilter>;
};

export type InformPartnerProgramWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  informPartnerId_name?: Maybe<InformPartnerProgramInformPartnerIdNameCompoundUniqueInput>;
};

export type InformPartnerRelationFilter = {
  is?: Maybe<InformPartnerWhereInput>;
  isNot?: Maybe<InformPartnerWhereInput>;
};

export enum InformPartnerScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Name = 'name',
  Status = 'status',
  Description = 'description',
  Settings = 'settings'
}

export type InformPartnerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumInformPartnerStatusWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum InformPartnerStatus {
  Active = 'Active',
  Paused = 'Paused',
  Archived = 'Archived'
}

export type InformPartnerUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerUncheckedCreateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerUncheckedCreateWithoutInformCampaignGroupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerUncheckedCreateWithoutInformEmailTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerUncheckedCreateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerUncheckedCreateWithoutInformPartnerIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerUncheckedCreateWithoutInformPartnerProgramsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerUncheckedCreateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerUncheckedCreateWithoutProductsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
};

export type InformPartnerUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerUncheckedUpdateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUncheckedUpdateWithoutInformCampaignGroupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUncheckedUpdateWithoutInformEmailTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUncheckedUpdateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUncheckedUpdateWithoutInformPartnerIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUncheckedUpdateWithoutInformPartnerProgramsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUncheckedUpdateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUncheckedUpdateWithoutProductsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerUpdateOneRequiredWithoutInformCampaignGroupsNestedInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformCampaignGroupsInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformCampaignGroupsInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutInformCampaignGroupsInput>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutInformCampaignGroupsInput>;
};

export type InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerCustomerInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerCustomerInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutInformPartnerCustomerInput>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutInformPartnerCustomerInput>;
};

export type InformPartnerUpdateOneRequiredWithoutInformPartnerIntegrationsNestedInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerIntegrationsInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerIntegrationsInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutInformPartnerIntegrationsInput>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutInformPartnerIntegrationsInput>;
};

export type InformPartnerUpdateOneRequiredWithoutInformPartnerProgramsNestedInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerProgramsInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerProgramsInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutInformPartnerProgramsInput>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutInformPartnerProgramsInput>;
};

export type InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerUsersInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerUsersInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutInformPartnerUsersInput>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutInformPartnerUsersInput>;
};

export type InformPartnerUpdateOneWithoutInformAudienceDefinitionsNestedInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformAudienceDefinitionsInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformAudienceDefinitionsInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutInformAudienceDefinitionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutInformAudienceDefinitionsInput>;
};

export type InformPartnerUpdateOneWithoutInformEmailTemplatesNestedInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformEmailTemplatesInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformEmailTemplatesInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutInformEmailTemplatesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutInformEmailTemplatesInput>;
};

export type InformPartnerUpdateOneWithoutProductsNestedInput = {
  create?: Maybe<InformPartnerUncheckedCreateWithoutProductsInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutProductsInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutProductsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutProductsInput>;
};

export type InformPartnerUpdateWithoutInformAudienceDefinitionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUpdateWithoutInformCampaignGroupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUpdateWithoutInformEmailTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUpdateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUpdateWithoutInformPartnerIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUpdateWithoutInformPartnerProgramsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUpdateWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUpdateWithoutProductsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
};

export type InformPartnerUpsertWithoutInformAudienceDefinitionsInput = {
  update: InformPartnerUncheckedUpdateWithoutInformAudienceDefinitionsInput;
  create: InformPartnerUncheckedCreateWithoutInformAudienceDefinitionsInput;
};

export type InformPartnerUpsertWithoutInformCampaignGroupsInput = {
  update: InformPartnerUncheckedUpdateWithoutInformCampaignGroupsInput;
  create: InformPartnerUncheckedCreateWithoutInformCampaignGroupsInput;
};

export type InformPartnerUpsertWithoutInformEmailTemplatesInput = {
  update: InformPartnerUncheckedUpdateWithoutInformEmailTemplatesInput;
  create: InformPartnerUncheckedCreateWithoutInformEmailTemplatesInput;
};

export type InformPartnerUpsertWithoutInformPartnerCustomerInput = {
  update: InformPartnerUncheckedUpdateWithoutInformPartnerCustomerInput;
  create: InformPartnerUncheckedCreateWithoutInformPartnerCustomerInput;
};

export type InformPartnerUpsertWithoutInformPartnerIntegrationsInput = {
  update: InformPartnerUncheckedUpdateWithoutInformPartnerIntegrationsInput;
  create: InformPartnerUncheckedCreateWithoutInformPartnerIntegrationsInput;
};

export type InformPartnerUpsertWithoutInformPartnerProgramsInput = {
  update: InformPartnerUncheckedUpdateWithoutInformPartnerProgramsInput;
  create: InformPartnerUncheckedCreateWithoutInformPartnerProgramsInput;
};

export type InformPartnerUpsertWithoutInformPartnerUsersInput = {
  update: InformPartnerUncheckedUpdateWithoutInformPartnerUsersInput;
  create: InformPartnerUncheckedCreateWithoutInformPartnerUsersInput;
};

export type InformPartnerUpsertWithoutProductsInput = {
  update: InformPartnerUncheckedUpdateWithoutProductsInput;
  create: InformPartnerUncheckedCreateWithoutProductsInput;
};

export type InformPartnerUser = {
  __typename?: 'InformPartnerUser';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  informPartnerIntegrationId: Scalars['String'];
  informPartnerId: Scalars['String'];
  status: InformPartnerUserStatus;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartner;
  informPartnerIntegration: InformPartnerIntegration;
  boehringerIngelheimTerritories: Array<BoehringerIngelheimTerritory>;
  informPartnerCustomers: Array<InformPartnerCustomer>;
  roles: Array<InformPartnerUserRole>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id: Array<UserImpersonation>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id: Array<UserImpersonation>;
  _count: InformPartnerUserCountOutputType;
};


export type InformPartnerUserBoehringerIngelheimTerritoriesArgs = {
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  orderBy?: Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BoehringerIngelheimTerritoryScalarFieldEnum>;
};


export type InformPartnerUserInformPartnerCustomersArgs = {
  where?: Maybe<InformPartnerCustomerWhereInput>;
  orderBy?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerCustomerScalarFieldEnum>;
};


export type InformPartnerUserRolesArgs = {
  where?: Maybe<InformPartnerUserRoleWhereInput>;
  orderBy?: Maybe<InformPartnerUserRoleOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerUserRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerUserRoleScalarFieldEnum>;
};


export type InformPartnerUserUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdArgs = {
  where?: Maybe<UserImpersonationWhereInput>;
  orderBy?: Maybe<UserImpersonationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserImpersonationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserImpersonationScalarFieldEnum>;
};


export type InformPartnerUserUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdArgs = {
  where?: Maybe<UserImpersonationWhereInput>;
  orderBy?: Maybe<UserImpersonationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserImpersonationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserImpersonationScalarFieldEnum>;
};

export type InformPartnerUserCountAggregateOutputType = {
  __typename?: 'InformPartnerUserCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  informPartnerIntegrationId: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  status: Scalars['Int'];
  externalId: Scalars['Int'];
  email: Scalars['Int'];
  phone: Scalars['Int'];
  firstName: Scalars['Int'];
  lastName: Scalars['Int'];
  firebaseId: Scalars['Int'];
  settings: Scalars['Int'];
  checksum: Scalars['Int'];
  rawData: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformPartnerUserCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
};

export type InformPartnerUserCountOutputType = {
  __typename?: 'InformPartnerUserCountOutputType';
  boehringerIngelheimTerritories: Scalars['Int'];
  informPartnerCustomers: Scalars['Int'];
  roles: Scalars['Int'];
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id: Scalars['Int'];
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id: Scalars['Int'];
};

export type InformPartnerUserCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerUsersInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutInformPartnerUserInput>;
};

export type InformPartnerUserCreateManyInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserCreateManyInformPartnerInputEnvelope = {
  data: Array<InformPartnerUserCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerUserCreateManyInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserCreateManyInformPartnerIntegrationInputEnvelope = {
  data: Array<InformPartnerUserCreateManyInformPartnerIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerUserCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  informPartnerId: Scalars['String'];
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutBoehringerIngelheimTerritoriesInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutBoehringerIngelheimTerritoriesInput>>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
};

export type InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerCustomersInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerCustomersInput>>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
};

export type InformPartnerUserCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
};

export type InformPartnerUserCreateNestedManyWithoutInformPartnerIntegrationInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerIntegrationInputEnvelope>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
};

export type InformPartnerUserCreateNestedOneWithoutRolesInput = {
  create?: Maybe<InformPartnerUserUncheckedCreateWithoutRolesInput>;
  connectOrCreate?: Maybe<InformPartnerUserCreateOrConnectWithoutRolesInput>;
  connect?: Maybe<InformPartnerUserWhereUniqueInput>;
};

export type InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  create?: Maybe<InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  connectOrCreate?: Maybe<InformPartnerUserCreateOrConnectWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  connect?: Maybe<InformPartnerUserWhereUniqueInput>;
};

export type InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  create?: Maybe<InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
  connectOrCreate?: Maybe<InformPartnerUserCreateOrConnectWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
  connect?: Maybe<InformPartnerUserWhereUniqueInput>;
};

export type InformPartnerUserCreateOrConnectWithoutBoehringerIngelheimTerritoriesInput = {
  where: InformPartnerUserWhereUniqueInput;
  create: InformPartnerUserUncheckedCreateWithoutBoehringerIngelheimTerritoriesInput;
};

export type InformPartnerUserCreateOrConnectWithoutInformPartnerCustomersInput = {
  where: InformPartnerUserWhereUniqueInput;
  create: InformPartnerUserUncheckedCreateWithoutInformPartnerCustomersInput;
};

export type InformPartnerUserCreateOrConnectWithoutInformPartnerInput = {
  where: InformPartnerUserWhereUniqueInput;
  create: InformPartnerUserUncheckedCreateWithoutInformPartnerInput;
};

export type InformPartnerUserCreateOrConnectWithoutInformPartnerIntegrationInput = {
  where: InformPartnerUserWhereUniqueInput;
  create: InformPartnerUserUncheckedCreateWithoutInformPartnerIntegrationInput;
};

export type InformPartnerUserCreateOrConnectWithoutRolesInput = {
  where: InformPartnerUserWhereUniqueInput;
  create: InformPartnerUserUncheckedCreateWithoutRolesInput;
};

export type InformPartnerUserCreateOrConnectWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  where: InformPartnerUserWhereUniqueInput;
  create: InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput;
};

export type InformPartnerUserCreateOrConnectWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  where: InformPartnerUserWhereUniqueInput;
  create: InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput;
};

export type InformPartnerUserCreateWithoutBoehringerIngelheimTerritoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerUsersInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutInformPartnerUserInput>;
};

export type InformPartnerUserCreateWithoutInformPartnerCustomersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerUsersInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutInformPartnerUserInput>;
};

export type InformPartnerUserCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutInformPartnerUserInput>;
};

export type InformPartnerUserCreateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerUsersInput;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutInformPartnerUserInput>;
};

export type InformPartnerUserCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerUsersInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutInformPartnerUserInput>;
};

export type InformPartnerUserCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerUsersInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutInformPartnerUserInput>;
};

export type InformPartnerUserCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerUsersInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
};

export type InformPartnerUserInformPartnerIdEmailCompoundUniqueInput = {
  informPartnerId: Scalars['String'];
  email: Scalars['String'];
};

export type InformPartnerUserInformPartnerIdExternalIdCompoundUniqueInput = {
  informPartnerId: Scalars['String'];
  externalId: Scalars['String'];
};

export type InformPartnerUserInformPartnerIdFirebaseIdCompoundUniqueInput = {
  informPartnerId: Scalars['String'];
  firebaseId: Scalars['String'];
};

export type InformPartnerUserListRelationFilter = {
  every?: Maybe<InformPartnerUserWhereInput>;
  some?: Maybe<InformPartnerUserWhereInput>;
  none?: Maybe<InformPartnerUserWhereInput>;
};

export type InformPartnerUserMaxAggregateOutputType = {
  __typename?: 'InformPartnerUserMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
};

export type InformPartnerUserMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
};

export type InformPartnerUserMinAggregateOutputType = {
  __typename?: 'InformPartnerUserMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
};

export type InformPartnerUserMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
};

export type InformPartnerUserOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerUserOrderByRelevanceFieldEnum {
  Id = 'id',
  InformPartnerIntegrationId = 'informPartnerIntegrationId',
  InformPartnerId = 'informPartnerId',
  ExternalId = 'externalId',
  Email = 'email',
  Phone = 'phone',
  FirstName = 'firstName',
  LastName = 'lastName',
  FirebaseId = 'firebaseId',
  Checksum = 'checksum'
}

export type InformPartnerUserOrderByRelevanceInput = {
  fields: Array<InformPartnerUserOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformPartnerUserOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  _count?: Maybe<InformPartnerUserCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerUserMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerUserMinOrderByAggregateInput>;
};

export type InformPartnerUserOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryOrderByRelationAggregateInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerOrderByRelationAggregateInput>;
  roles?: Maybe<InformPartnerUserRoleOrderByRelationAggregateInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationOrderByRelationAggregateInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationOrderByRelationAggregateInput>;
  _relevance?: Maybe<InformPartnerUserOrderByRelevanceInput>;
};

export type InformPartnerUserRelationFilter = {
  is?: Maybe<InformPartnerUserWhereInput>;
  isNot?: Maybe<InformPartnerUserWhereInput>;
};

export type InformPartnerUserRole = {
  __typename?: 'InformPartnerUserRole';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  informPartnerUserId: Scalars['String'];
  informPartnerCustomerId: Scalars['String'];
  status: InformPartnerUserRoleStatus;
  name: InformPartnerUserRoleName;
  settings?: Maybe<Scalars['Json']>;
  informPartnerCustomer: InformPartnerCustomer;
  informPartnerUser: InformPartnerUser;
};

export type InformPartnerUserRoleCountAggregateOutputType = {
  __typename?: 'InformPartnerUserRoleCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  informPartnerUserId: Scalars['Int'];
  informPartnerCustomerId: Scalars['Int'];
  status: Scalars['Int'];
  name: Scalars['Int'];
  settings: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformPartnerUserRoleCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
};

export type InformPartnerUserRoleCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerCustomer: InformPartnerCustomerCreateNestedOneWithoutRolesInput;
  informPartnerUser: InformPartnerUserCreateNestedOneWithoutRolesInput;
};

export type InformPartnerUserRoleCreateManyInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerUserId: Scalars['String'];
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserRoleCreateManyInformPartnerCustomerInputEnvelope = {
  data: Array<InformPartnerUserRoleCreateManyInformPartnerCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerUserRoleCreateManyInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerCustomerId: Scalars['String'];
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserRoleCreateManyInformPartnerUserInputEnvelope = {
  data: Array<InformPartnerUserRoleCreateManyInformPartnerUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerUserRoleCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerUserId: Scalars['String'];
  informPartnerCustomerId: Scalars['String'];
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput = {
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerCustomerInputEnvelope>;
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
};

export type InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput = {
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerUserInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerUserInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerUserInputEnvelope>;
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
};

export type InformPartnerUserRoleCreateOrConnectWithoutInformPartnerCustomerInput = {
  where: InformPartnerUserRoleWhereUniqueInput;
  create: InformPartnerUserRoleUncheckedCreateWithoutInformPartnerCustomerInput;
};

export type InformPartnerUserRoleCreateOrConnectWithoutInformPartnerUserInput = {
  where: InformPartnerUserRoleWhereUniqueInput;
  create: InformPartnerUserRoleUncheckedCreateWithoutInformPartnerUserInput;
};

export type InformPartnerUserRoleCreateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerUser: InformPartnerUserCreateNestedOneWithoutRolesInput;
};

export type InformPartnerUserRoleCreateWithoutInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerCustomer: InformPartnerCustomerCreateNestedOneWithoutRolesInput;
};

export type InformPartnerUserRoleInformPartnerUserIdInformPartnerCustomerIdNameCompoundUniqueInput = {
  informPartnerUserId: Scalars['String'];
  informPartnerCustomerId: Scalars['String'];
  name: InformPartnerUserRoleName;
};

export type InformPartnerUserRoleListRelationFilter = {
  every?: Maybe<InformPartnerUserRoleWhereInput>;
  some?: Maybe<InformPartnerUserRoleWhereInput>;
  none?: Maybe<InformPartnerUserRoleWhereInput>;
};

export type InformPartnerUserRoleMaxAggregateOutputType = {
  __typename?: 'InformPartnerUserRoleMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
};

export type InformPartnerUserRoleMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type InformPartnerUserRoleMinAggregateOutputType = {
  __typename?: 'InformPartnerUserRoleMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
};

export type InformPartnerUserRoleMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export enum InformPartnerUserRoleName {
  Default = 'Default'
}

export type InformPartnerUserRoleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerUserRoleOrderByRelevanceFieldEnum {
  Id = 'id',
  InformPartnerUserId = 'informPartnerUserId',
  InformPartnerCustomerId = 'informPartnerCustomerId'
}

export type InformPartnerUserRoleOrderByRelevanceInput = {
  fields: Array<InformPartnerUserRoleOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformPartnerUserRoleOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  _count?: Maybe<InformPartnerUserRoleCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerUserRoleMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerUserRoleMinOrderByAggregateInput>;
};

export type InformPartnerUserRoleOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerUser?: Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<InformPartnerUserRoleOrderByRelevanceInput>;
};

export enum InformPartnerUserRoleScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InformPartnerUserId = 'informPartnerUserId',
  InformPartnerCustomerId = 'informPartnerCustomerId',
  Status = 'status',
  Name = 'name',
  Settings = 'settings'
}

export type InformPartnerUserRoleScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerUserRoleScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerUserRoleScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerUserRoleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerUserId?: Maybe<StringFilter>;
  informPartnerCustomerId?: Maybe<StringFilter>;
  status?: Maybe<EnumInformPartnerUserRoleStatusFilter>;
  name?: Maybe<EnumInformPartnerUserRoleNameFilter>;
  settings?: Maybe<JsonNullableFilter>;
};

export type InformPartnerUserRoleScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerUserRoleScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerUserRoleScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerUserRoleScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  informPartnerUserId?: Maybe<StringWithAggregatesFilter>;
  informPartnerCustomerId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumInformPartnerUserRoleStatusWithAggregatesFilter>;
  name?: Maybe<EnumInformPartnerUserRoleNameWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum InformPartnerUserRoleStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type InformPartnerUserRoleUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerUserId: Scalars['String'];
  informPartnerCustomerId: Scalars['String'];
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput = {
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerCustomerInputEnvelope>;
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
};

export type InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput = {
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerUserInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerUserInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerUserInputEnvelope>;
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
};

export type InformPartnerUserRoleUncheckedCreateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerUserId: Scalars['String'];
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserRoleUncheckedCreateWithoutInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerCustomerId: Scalars['String'];
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserRoleUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserRoleUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput = {
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<InformPartnerUserRoleUpsertWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerCustomerInputEnvelope>;
  set?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserRoleUpdateWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<InformPartnerUserRoleUpdateManyWithWhereWithoutInformPartnerCustomerInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserRoleScalarWhereInput>>;
};

export type InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput = {
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerUserInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerUserInput>>;
  upsert?: Maybe<Array<InformPartnerUserRoleUpsertWithWhereUniqueWithoutInformPartnerUserInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerUserInputEnvelope>;
  set?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserRoleUpdateWithWhereUniqueWithoutInformPartnerUserInput>>;
  updateMany?: Maybe<Array<InformPartnerUserRoleUpdateManyWithWhereWithoutInformPartnerUserInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserRoleScalarWhereInput>>;
};

export type InformPartnerUserRoleUncheckedUpdateManyWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserRoleUncheckedUpdateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserRoleUncheckedUpdateWithoutInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserRoleUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneRequiredWithoutRolesNestedInput>;
  informPartnerUser?: Maybe<InformPartnerUserUpdateOneRequiredWithoutRolesNestedInput>;
};

export type InformPartnerUserRoleUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserRoleUpdateManyWithWhereWithoutInformPartnerCustomerInput = {
  where: InformPartnerUserRoleScalarWhereInput;
  data: InformPartnerUserRoleUncheckedUpdateManyWithoutRolesInput;
};

export type InformPartnerUserRoleUpdateManyWithWhereWithoutInformPartnerUserInput = {
  where: InformPartnerUserRoleScalarWhereInput;
  data: InformPartnerUserRoleUncheckedUpdateManyWithoutRolesInput;
};

export type InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput = {
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerCustomerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<InformPartnerUserRoleUpsertWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerCustomerInputEnvelope>;
  set?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserRoleUpdateWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<InformPartnerUserRoleUpdateManyWithWhereWithoutInformPartnerCustomerInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserRoleScalarWhereInput>>;
};

export type InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput = {
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerUserInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerUserInput>>;
  upsert?: Maybe<Array<InformPartnerUserRoleUpsertWithWhereUniqueWithoutInformPartnerUserInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerUserInputEnvelope>;
  set?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserRoleUpdateWithWhereUniqueWithoutInformPartnerUserInput>>;
  updateMany?: Maybe<Array<InformPartnerUserRoleUpdateManyWithWhereWithoutInformPartnerUserInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserRoleScalarWhereInput>>;
};

export type InformPartnerUserRoleUpdateWithWhereUniqueWithoutInformPartnerCustomerInput = {
  where: InformPartnerUserRoleWhereUniqueInput;
  data: InformPartnerUserRoleUncheckedUpdateWithoutInformPartnerCustomerInput;
};

export type InformPartnerUserRoleUpdateWithWhereUniqueWithoutInformPartnerUserInput = {
  where: InformPartnerUserRoleWhereUniqueInput;
  data: InformPartnerUserRoleUncheckedUpdateWithoutInformPartnerUserInput;
};

export type InformPartnerUserRoleUpdateWithoutInformPartnerCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerUser?: Maybe<InformPartnerUserUpdateOneRequiredWithoutRolesNestedInput>;
};

export type InformPartnerUserRoleUpdateWithoutInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneRequiredWithoutRolesNestedInput>;
};

export type InformPartnerUserRoleUpsertWithWhereUniqueWithoutInformPartnerCustomerInput = {
  where: InformPartnerUserRoleWhereUniqueInput;
  update: InformPartnerUserRoleUncheckedUpdateWithoutInformPartnerCustomerInput;
  create: InformPartnerUserRoleUncheckedCreateWithoutInformPartnerCustomerInput;
};

export type InformPartnerUserRoleUpsertWithWhereUniqueWithoutInformPartnerUserInput = {
  where: InformPartnerUserRoleWhereUniqueInput;
  update: InformPartnerUserRoleUncheckedUpdateWithoutInformPartnerUserInput;
  create: InformPartnerUserRoleUncheckedCreateWithoutInformPartnerUserInput;
};

export type InformPartnerUserRoleWhereInput = {
  AND?: Maybe<Array<InformPartnerUserRoleWhereInput>>;
  OR?: Maybe<Array<InformPartnerUserRoleWhereInput>>;
  NOT?: Maybe<Array<InformPartnerUserRoleWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerUserId?: Maybe<StringFilter>;
  informPartnerCustomerId?: Maybe<StringFilter>;
  status?: Maybe<EnumInformPartnerUserRoleStatusFilter>;
  name?: Maybe<EnumInformPartnerUserRoleNameFilter>;
  settings?: Maybe<JsonNullableFilter>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerWhereInput>;
  informPartnerUser?: Maybe<InformPartnerUserWhereInput>;
};

export type InformPartnerUserRoleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  informPartnerUserId_informPartnerCustomerId_name?: Maybe<InformPartnerUserRoleInformPartnerUserIdInformPartnerCustomerIdNameCompoundUniqueInput>;
};

export enum InformPartnerUserScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InformPartnerIntegrationId = 'informPartnerIntegrationId',
  InformPartnerId = 'informPartnerId',
  Status = 'status',
  ExternalId = 'externalId',
  Email = 'email',
  Phone = 'phone',
  FirstName = 'firstName',
  LastName = 'lastName',
  FirebaseId = 'firebaseId',
  Settings = 'settings',
  Checksum = 'checksum',
  RawData = 'rawData'
}

export type InformPartnerUserScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  informPartnerId?: Maybe<StringFilter>;
  status?: Maybe<EnumInformPartnerUserStatusFilter>;
  externalId?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  phone?: Maybe<StringFilter>;
  firstName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  checksum?: Maybe<StringFilter>;
  rawData?: Maybe<JsonNullableFilter>;
};

export type InformPartnerUserScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerUserScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerUserScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerUserScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  informPartnerIntegrationId?: Maybe<StringWithAggregatesFilter>;
  informPartnerId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumInformPartnerUserStatusWithAggregatesFilter>;
  externalId?: Maybe<StringWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  phone?: Maybe<StringWithAggregatesFilter>;
  firstName?: Maybe<StringNullableWithAggregatesFilter>;
  lastName?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
  checksum?: Maybe<StringWithAggregatesFilter>;
  rawData?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum InformPartnerUserStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type InformPartnerUserUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  informPartnerId: Scalars['String'];
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
};

export type InformPartnerUserUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutBoehringerIngelheimTerritoriesInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutBoehringerIngelheimTerritoriesInput>>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
};

export type InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerCustomersInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerCustomersInput>>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
};

export type InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
};

export type InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerIntegrationInputEnvelope>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
};

export type InformPartnerUserUncheckedCreateWithoutBoehringerIngelheimTerritoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  informPartnerId: Scalars['String'];
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
};

export type InformPartnerUserUncheckedCreateWithoutInformPartnerCustomersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  informPartnerId: Scalars['String'];
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
};

export type InformPartnerUserUncheckedCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
};

export type InformPartnerUserUncheckedCreateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId: Scalars['String'];
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
};

export type InformPartnerUserUncheckedCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  informPartnerId: Scalars['String'];
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
};

export type InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  informPartnerId: Scalars['String'];
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
};

export type InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId: Scalars['String'];
  informPartnerId: Scalars['String'];
  status?: Maybe<InformPartnerUserStatus>;
  externalId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
};

export type InformPartnerUserUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
};

export type InformPartnerUserUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserUncheckedUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutBoehringerIngelheimTerritoriesInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutBoehringerIngelheimTerritoriesInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutBoehringerIngelheimTerritoriesInput>>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutBoehringerIngelheimTerritoriesInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutBoehringerIngelheimTerritoriesInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
};

export type InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerCustomersInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerCustomersInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerCustomersInput>>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerCustomersInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutInformPartnerCustomersInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
};

export type InformPartnerUserUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerIntegrationInputEnvelope>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
};

export type InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
};

export type InformPartnerUserUncheckedUpdateManyWithoutInformPartnerUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserUncheckedUpdateWithoutBoehringerIngelheimTerritoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
};

export type InformPartnerUserUncheckedUpdateWithoutInformPartnerCustomersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
};

export type InformPartnerUserUncheckedUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
};

export type InformPartnerUserUncheckedUpdateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
};

export type InformPartnerUserUncheckedUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
};

export type InformPartnerUserUncheckedUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
};

export type InformPartnerUserUncheckedUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
};

export type InformPartnerUserUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput>;
};

export type InformPartnerUserUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
};

export type InformPartnerUserUpdateManyWithWhereWithoutBoehringerIngelheimTerritoriesInput = {
  where: InformPartnerUserScalarWhereInput;
  data: InformPartnerUserUncheckedUpdateManyWithoutInformPartnerUsersInput;
};

export type InformPartnerUserUpdateManyWithWhereWithoutInformPartnerCustomersInput = {
  where: InformPartnerUserScalarWhereInput;
  data: InformPartnerUserUncheckedUpdateManyWithoutInformPartnerUsersInput;
};

export type InformPartnerUserUpdateManyWithWhereWithoutInformPartnerInput = {
  where: InformPartnerUserScalarWhereInput;
  data: InformPartnerUserUncheckedUpdateManyWithoutInformPartnerUsersInput;
};

export type InformPartnerUserUpdateManyWithWhereWithoutInformPartnerIntegrationInput = {
  where: InformPartnerUserScalarWhereInput;
  data: InformPartnerUserUncheckedUpdateManyWithoutInformPartnerUsersInput;
};

export type InformPartnerUserUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutBoehringerIngelheimTerritoriesInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutBoehringerIngelheimTerritoriesInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutBoehringerIngelheimTerritoriesInput>>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutBoehringerIngelheimTerritoriesInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutBoehringerIngelheimTerritoriesInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
};

export type InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerCustomersInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerCustomersInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerCustomersInput>>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerCustomersInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutInformPartnerCustomersInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
};

export type InformPartnerUserUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerIntegrationInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerIntegrationInputEnvelope>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
};

export type InformPartnerUserUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
};

export type InformPartnerUserUpdateOneRequiredWithoutRolesNestedInput = {
  create?: Maybe<InformPartnerUserUncheckedCreateWithoutRolesInput>;
  connectOrCreate?: Maybe<InformPartnerUserCreateOrConnectWithoutRolesInput>;
  upsert?: Maybe<InformPartnerUserUpsertWithoutRolesInput>;
  connect?: Maybe<InformPartnerUserWhereUniqueInput>;
  update?: Maybe<InformPartnerUserUncheckedUpdateWithoutRolesInput>;
};

export type InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdNestedInput = {
  create?: Maybe<InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  connectOrCreate?: Maybe<InformPartnerUserCreateOrConnectWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  upsert?: Maybe<InformPartnerUserUpsertWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformPartnerUserWhereUniqueInput>;
  update?: Maybe<InformPartnerUserUncheckedUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
};

export type InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdNestedInput = {
  create?: Maybe<InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
  connectOrCreate?: Maybe<InformPartnerUserCreateOrConnectWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
  upsert?: Maybe<InformPartnerUserUpsertWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformPartnerUserWhereUniqueInput>;
  update?: Maybe<InformPartnerUserUncheckedUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
};

export type InformPartnerUserUpdateWithWhereUniqueWithoutBoehringerIngelheimTerritoriesInput = {
  where: InformPartnerUserWhereUniqueInput;
  data: InformPartnerUserUncheckedUpdateWithoutBoehringerIngelheimTerritoriesInput;
};

export type InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerCustomersInput = {
  where: InformPartnerUserWhereUniqueInput;
  data: InformPartnerUserUncheckedUpdateWithoutInformPartnerCustomersInput;
};

export type InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerInput = {
  where: InformPartnerUserWhereUniqueInput;
  data: InformPartnerUserUncheckedUpdateWithoutInformPartnerInput;
};

export type InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  where: InformPartnerUserWhereUniqueInput;
  data: InformPartnerUserUncheckedUpdateWithoutInformPartnerIntegrationInput;
};

export type InformPartnerUserUpdateWithoutBoehringerIngelheimTerritoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput>;
};

export type InformPartnerUserUpdateWithoutInformPartnerCustomersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput>;
};

export type InformPartnerUserUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput>;
};

export type InformPartnerUserUpdateWithoutInformPartnerIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput>;
};

export type InformPartnerUserUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput>;
};

export type InformPartnerUserUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput>;
};

export type InformPartnerUserUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InformPartnerUserStatus>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  checksum?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
};

export type InformPartnerUserUpsertWithWhereUniqueWithoutBoehringerIngelheimTerritoriesInput = {
  where: InformPartnerUserWhereUniqueInput;
  update: InformPartnerUserUncheckedUpdateWithoutBoehringerIngelheimTerritoriesInput;
  create: InformPartnerUserUncheckedCreateWithoutBoehringerIngelheimTerritoriesInput;
};

export type InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerCustomersInput = {
  where: InformPartnerUserWhereUniqueInput;
  update: InformPartnerUserUncheckedUpdateWithoutInformPartnerCustomersInput;
  create: InformPartnerUserUncheckedCreateWithoutInformPartnerCustomersInput;
};

export type InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerInput = {
  where: InformPartnerUserWhereUniqueInput;
  update: InformPartnerUserUncheckedUpdateWithoutInformPartnerInput;
  create: InformPartnerUserUncheckedCreateWithoutInformPartnerInput;
};

export type InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  where: InformPartnerUserWhereUniqueInput;
  update: InformPartnerUserUncheckedUpdateWithoutInformPartnerIntegrationInput;
  create: InformPartnerUserUncheckedCreateWithoutInformPartnerIntegrationInput;
};

export type InformPartnerUserUpsertWithoutRolesInput = {
  update: InformPartnerUserUncheckedUpdateWithoutRolesInput;
  create: InformPartnerUserUncheckedCreateWithoutRolesInput;
};

export type InformPartnerUserUpsertWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  update: InformPartnerUserUncheckedUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput;
  create: InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput;
};

export type InformPartnerUserUpsertWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  update: InformPartnerUserUncheckedUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput;
  create: InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput;
};

export type InformPartnerUserWhereInput = {
  AND?: Maybe<Array<InformPartnerUserWhereInput>>;
  OR?: Maybe<Array<InformPartnerUserWhereInput>>;
  NOT?: Maybe<Array<InformPartnerUserWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  informPartnerId?: Maybe<StringFilter>;
  status?: Maybe<EnumInformPartnerUserStatusFilter>;
  externalId?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  phone?: Maybe<StringFilter>;
  firstName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  checksum?: Maybe<StringFilter>;
  rawData?: Maybe<JsonNullableFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationWhereInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryListRelationFilter>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerListRelationFilter>;
  roles?: Maybe<InformPartnerUserRoleListRelationFilter>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationListRelationFilter>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationListRelationFilter>;
};

export type InformPartnerUserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  informPartnerId_externalId?: Maybe<InformPartnerUserInformPartnerIdExternalIdCompoundUniqueInput>;
  informPartnerId_email?: Maybe<InformPartnerUserInformPartnerIdEmailCompoundUniqueInput>;
  informPartnerId_firebaseId?: Maybe<InformPartnerUserInformPartnerIdFirebaseIdCompoundUniqueInput>;
};

export type InformPartnerWhereInput = {
  AND?: Maybe<Array<InformPartnerWhereInput>>;
  OR?: Maybe<Array<InformPartnerWhereInput>>;
  NOT?: Maybe<Array<InformPartnerWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumInformPartnerStatusFilter>;
  description?: Maybe<StringNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  informPartnerPrograms?: Maybe<InformPartnerProgramListRelationFilter>;
  informCampaignGroups?: Maybe<InformCampaignGroupListRelationFilter>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionListRelationFilter>;
  informEmailTemplates?: Maybe<InformEmailTemplateListRelationFilter>;
  products?: Maybe<ProductListRelationFilter>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationListRelationFilter>;
  informPartnerUsers?: Maybe<InformPartnerUserListRelationFilter>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerListRelationFilter>;
};

export type InformPartnerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type InformScheduledCampaign = {
  __typename?: 'InformScheduledCampaign';
  id: Scalars['String'];
  informEmailTemplateId?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels: Array<InformScheduledCampaignCommunicationChannel>;
  informCampaign: InformCampaign;
  informEmailTemplate?: Maybe<InformEmailTemplate>;
};

export enum InformScheduledCampaignCadence {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Seasonally = 'Seasonally',
  Annually = 'Annually'
}

export enum InformScheduledCampaignCommunicationChannel {
  Email = 'Email',
  Sms = 'SMS'
}

export type InformScheduledCampaignCountAggregateOutputType = {
  __typename?: 'InformScheduledCampaignCountAggregateOutputType';
  id: Scalars['Int'];
  informEmailTemplateId: Scalars['Int'];
  subject: Scalars['Int'];
  content: Scalars['Int'];
  unlayerJson: Scalars['Int'];
  cadence: Scalars['Int'];
  schedule: Scalars['Int'];
  communicationChannels: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InformScheduledCampaignCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  informEmailTemplateId?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  cadence?: Maybe<SortOrder>;
  schedule?: Maybe<SortOrder>;
  communicationChannels?: Maybe<SortOrder>;
};

export type InformScheduledCampaignCreateInput = {
  subject: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  informCampaign: InformCampaignCreateNestedOneWithoutInformScheduledCampaignInput;
  informEmailTemplate?: Maybe<InformEmailTemplateCreateNestedOneWithoutInformScheduledCampaignsInput>;
};

export type InformScheduledCampaignCreateManyInformEmailTemplateInput = {
  id: Scalars['String'];
  subject: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
};

export type InformScheduledCampaignCreateManyInformEmailTemplateInputEnvelope = {
  data: Array<InformScheduledCampaignCreateManyInformEmailTemplateInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformScheduledCampaignCreateManyInput = {
  id: Scalars['String'];
  informEmailTemplateId?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
};

export type InformScheduledCampaignCreateNestedManyWithoutInformEmailTemplateInput = {
  create?: Maybe<Array<InformScheduledCampaignCreateWithoutInformEmailTemplateInput>>;
  connectOrCreate?: Maybe<Array<InformScheduledCampaignCreateOrConnectWithoutInformEmailTemplateInput>>;
  createMany?: Maybe<InformScheduledCampaignCreateManyInformEmailTemplateInputEnvelope>;
  connect?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
};

export type InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput = {
  create?: Maybe<InformScheduledCampaignUncheckedCreateWithoutInformCampaignInput>;
  connectOrCreate?: Maybe<InformScheduledCampaignCreateOrConnectWithoutInformCampaignInput>;
  connect?: Maybe<InformScheduledCampaignWhereUniqueInput>;
};

export type InformScheduledCampaignCreateOrConnectWithoutInformCampaignInput = {
  where: InformScheduledCampaignWhereUniqueInput;
  create: InformScheduledCampaignUncheckedCreateWithoutInformCampaignInput;
};

export type InformScheduledCampaignCreateOrConnectWithoutInformEmailTemplateInput = {
  where: InformScheduledCampaignWhereUniqueInput;
  create: InformScheduledCampaignUncheckedCreateWithoutInformEmailTemplateInput;
};

export type InformScheduledCampaignCreateWithoutInformCampaignInput = {
  subject: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  informEmailTemplate?: Maybe<InformEmailTemplateCreateNestedOneWithoutInformScheduledCampaignsInput>;
};

export type InformScheduledCampaignCreateWithoutInformEmailTemplateInput = {
  subject: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  informCampaign: InformCampaignCreateNestedOneWithoutInformScheduledCampaignInput;
};

export type InformScheduledCampaignCreatecommunicationChannelsInput = {
  set: Array<InformScheduledCampaignCommunicationChannel>;
};

export type InformScheduledCampaignListRelationFilter = {
  every?: Maybe<InformScheduledCampaignWhereInput>;
  some?: Maybe<InformScheduledCampaignWhereInput>;
  none?: Maybe<InformScheduledCampaignWhereInput>;
};

export type InformScheduledCampaignMaxAggregateOutputType = {
  __typename?: 'InformScheduledCampaignMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  informEmailTemplateId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
};

export type InformScheduledCampaignMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  informEmailTemplateId?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  cadence?: Maybe<SortOrder>;
};

export type InformScheduledCampaignMinAggregateOutputType = {
  __typename?: 'InformScheduledCampaignMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  informEmailTemplateId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
};

export type InformScheduledCampaignMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  informEmailTemplateId?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  cadence?: Maybe<SortOrder>;
};

export type InformScheduledCampaignOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformScheduledCampaignOrderByRelevanceFieldEnum {
  Id = 'id',
  InformEmailTemplateId = 'informEmailTemplateId',
  Subject = 'subject',
  Content = 'content'
}

export type InformScheduledCampaignOrderByRelevanceInput = {
  fields: Array<InformScheduledCampaignOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InformScheduledCampaignOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  informEmailTemplateId?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  cadence?: Maybe<SortOrder>;
  schedule?: Maybe<SortOrder>;
  communicationChannels?: Maybe<SortOrder>;
  _count?: Maybe<InformScheduledCampaignCountOrderByAggregateInput>;
  _max?: Maybe<InformScheduledCampaignMaxOrderByAggregateInput>;
  _min?: Maybe<InformScheduledCampaignMinOrderByAggregateInput>;
};

export type InformScheduledCampaignOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  informEmailTemplateId?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  cadence?: Maybe<SortOrder>;
  schedule?: Maybe<SortOrder>;
  communicationChannels?: Maybe<SortOrder>;
  informCampaign?: Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>;
  informEmailTemplate?: Maybe<InformEmailTemplateOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<InformScheduledCampaignOrderByRelevanceInput>;
};

export type InformScheduledCampaignRelationFilter = {
  is?: Maybe<InformScheduledCampaignWhereInput>;
  isNot?: Maybe<InformScheduledCampaignWhereInput>;
};

export enum InformScheduledCampaignScalarFieldEnum {
  Id = 'id',
  InformEmailTemplateId = 'informEmailTemplateId',
  Subject = 'subject',
  Content = 'content',
  UnlayerJson = 'unlayerJson',
  Cadence = 'cadence',
  Schedule = 'schedule',
  CommunicationChannels = 'communicationChannels'
}

export type InformScheduledCampaignScalarWhereInput = {
  AND?: Maybe<Array<InformScheduledCampaignScalarWhereInput>>;
  OR?: Maybe<Array<InformScheduledCampaignScalarWhereInput>>;
  NOT?: Maybe<Array<InformScheduledCampaignScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  informEmailTemplateId?: Maybe<StringNullableFilter>;
  subject?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
  cadence?: Maybe<EnumInformScheduledCampaignCadenceNullableFilter>;
  schedule?: Maybe<JsonNullableFilter>;
  communicationChannels?: Maybe<EnumInformScheduledCampaignCommunicationChannelNullableListFilter>;
};

export type InformScheduledCampaignScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformScheduledCampaignScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformScheduledCampaignScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformScheduledCampaignScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  informEmailTemplateId?: Maybe<StringNullableWithAggregatesFilter>;
  subject?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
  unlayerJson?: Maybe<JsonNullableWithAggregatesFilter>;
  cadence?: Maybe<EnumInformScheduledCampaignCadenceNullableWithAggregatesFilter>;
  schedule?: Maybe<JsonNullableWithAggregatesFilter>;
  communicationChannels?: Maybe<EnumInformScheduledCampaignCommunicationChannelNullableListFilter>;
};

export type InformScheduledCampaignUncheckedCreateInput = {
  id: Scalars['String'];
  informEmailTemplateId?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
};

export type InformScheduledCampaignUncheckedCreateNestedManyWithoutInformEmailTemplateInput = {
  create?: Maybe<Array<InformScheduledCampaignCreateWithoutInformEmailTemplateInput>>;
  connectOrCreate?: Maybe<Array<InformScheduledCampaignCreateOrConnectWithoutInformEmailTemplateInput>>;
  createMany?: Maybe<InformScheduledCampaignCreateManyInformEmailTemplateInputEnvelope>;
  connect?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
};

export type InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput = {
  create?: Maybe<InformScheduledCampaignUncheckedCreateWithoutInformCampaignInput>;
  connectOrCreate?: Maybe<InformScheduledCampaignCreateOrConnectWithoutInformCampaignInput>;
  connect?: Maybe<InformScheduledCampaignWhereUniqueInput>;
};

export type InformScheduledCampaignUncheckedCreateWithoutInformCampaignInput = {
  informEmailTemplateId?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
};

export type InformScheduledCampaignUncheckedCreateWithoutInformEmailTemplateInput = {
  id: Scalars['String'];
  subject: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
};

export type InformScheduledCampaignUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  informEmailTemplateId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
};

export type InformScheduledCampaignUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  informEmailTemplateId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
};

export type InformScheduledCampaignUncheckedUpdateManyWithoutInformEmailTemplateNestedInput = {
  create?: Maybe<Array<InformScheduledCampaignCreateWithoutInformEmailTemplateInput>>;
  connectOrCreate?: Maybe<Array<InformScheduledCampaignCreateOrConnectWithoutInformEmailTemplateInput>>;
  upsert?: Maybe<Array<InformScheduledCampaignUpsertWithWhereUniqueWithoutInformEmailTemplateInput>>;
  createMany?: Maybe<InformScheduledCampaignCreateManyInformEmailTemplateInputEnvelope>;
  set?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformScheduledCampaignUpdateWithWhereUniqueWithoutInformEmailTemplateInput>>;
  updateMany?: Maybe<Array<InformScheduledCampaignUpdateManyWithWhereWithoutInformEmailTemplateInput>>;
  deleteMany?: Maybe<Array<InformScheduledCampaignScalarWhereInput>>;
};

export type InformScheduledCampaignUncheckedUpdateManyWithoutInformScheduledCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
};

export type InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput = {
  create?: Maybe<InformScheduledCampaignUncheckedCreateWithoutInformCampaignInput>;
  connectOrCreate?: Maybe<InformScheduledCampaignCreateOrConnectWithoutInformCampaignInput>;
  upsert?: Maybe<InformScheduledCampaignUpsertWithoutInformCampaignInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  update?: Maybe<InformScheduledCampaignUncheckedUpdateWithoutInformCampaignInput>;
};

export type InformScheduledCampaignUncheckedUpdateWithoutInformCampaignInput = {
  informEmailTemplateId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
};

export type InformScheduledCampaignUncheckedUpdateWithoutInformEmailTemplateInput = {
  id?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
};

export type InformScheduledCampaignUpdateInput = {
  subject?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformScheduledCampaignNestedInput>;
  informEmailTemplate?: Maybe<InformEmailTemplateUpdateOneWithoutInformScheduledCampaignsNestedInput>;
};

export type InformScheduledCampaignUpdateManyMutationInput = {
  subject?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
};

export type InformScheduledCampaignUpdateManyWithWhereWithoutInformEmailTemplateInput = {
  where: InformScheduledCampaignScalarWhereInput;
  data: InformScheduledCampaignUncheckedUpdateManyWithoutInformScheduledCampaignsInput;
};

export type InformScheduledCampaignUpdateManyWithoutInformEmailTemplateNestedInput = {
  create?: Maybe<Array<InformScheduledCampaignCreateWithoutInformEmailTemplateInput>>;
  connectOrCreate?: Maybe<Array<InformScheduledCampaignCreateOrConnectWithoutInformEmailTemplateInput>>;
  upsert?: Maybe<Array<InformScheduledCampaignUpsertWithWhereUniqueWithoutInformEmailTemplateInput>>;
  createMany?: Maybe<InformScheduledCampaignCreateManyInformEmailTemplateInputEnvelope>;
  set?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformScheduledCampaignUpdateWithWhereUniqueWithoutInformEmailTemplateInput>>;
  updateMany?: Maybe<Array<InformScheduledCampaignUpdateManyWithWhereWithoutInformEmailTemplateInput>>;
  deleteMany?: Maybe<Array<InformScheduledCampaignScalarWhereInput>>;
};

export type InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput = {
  create?: Maybe<InformScheduledCampaignUncheckedCreateWithoutInformCampaignInput>;
  connectOrCreate?: Maybe<InformScheduledCampaignCreateOrConnectWithoutInformCampaignInput>;
  upsert?: Maybe<InformScheduledCampaignUpsertWithoutInformCampaignInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  update?: Maybe<InformScheduledCampaignUncheckedUpdateWithoutInformCampaignInput>;
};

export type InformScheduledCampaignUpdateWithWhereUniqueWithoutInformEmailTemplateInput = {
  where: InformScheduledCampaignWhereUniqueInput;
  data: InformScheduledCampaignUncheckedUpdateWithoutInformEmailTemplateInput;
};

export type InformScheduledCampaignUpdateWithoutInformCampaignInput = {
  subject?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  informEmailTemplate?: Maybe<InformEmailTemplateUpdateOneWithoutInformScheduledCampaignsNestedInput>;
};

export type InformScheduledCampaignUpdateWithoutInformEmailTemplateInput = {
  subject?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
  cadence?: Maybe<InformScheduledCampaignCadence>;
  schedule?: Maybe<Scalars['Json']>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformScheduledCampaignNestedInput>;
};

export type InformScheduledCampaignUpdatecommunicationChannelsInput = {
  set?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  push?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
};

export type InformScheduledCampaignUpsertWithWhereUniqueWithoutInformEmailTemplateInput = {
  where: InformScheduledCampaignWhereUniqueInput;
  update: InformScheduledCampaignUncheckedUpdateWithoutInformEmailTemplateInput;
  create: InformScheduledCampaignUncheckedCreateWithoutInformEmailTemplateInput;
};

export type InformScheduledCampaignUpsertWithoutInformCampaignInput = {
  update: InformScheduledCampaignUncheckedUpdateWithoutInformCampaignInput;
  create: InformScheduledCampaignUncheckedCreateWithoutInformCampaignInput;
};

export type InformScheduledCampaignWhereInput = {
  AND?: Maybe<Array<InformScheduledCampaignWhereInput>>;
  OR?: Maybe<Array<InformScheduledCampaignWhereInput>>;
  NOT?: Maybe<Array<InformScheduledCampaignWhereInput>>;
  id?: Maybe<StringFilter>;
  informEmailTemplateId?: Maybe<StringNullableFilter>;
  subject?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
  cadence?: Maybe<EnumInformScheduledCampaignCadenceNullableFilter>;
  schedule?: Maybe<JsonNullableFilter>;
  communicationChannels?: Maybe<EnumInformScheduledCampaignCommunicationChannelNullableListFilter>;
  informCampaign?: Maybe<InformCampaignWhereInput>;
  informEmailTemplate?: Maybe<InformEmailTemplateWhereInput>;
};

export type InformScheduledCampaignWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InstinctIntegration = {
  __typename?: 'InstinctIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
  password: Scalars['String'];
  /** each "Market" (a clinic or collection of clinics) has a subdomain */
  subdomain: Scalars['String'];
  /** The specific clinic location, if clinic has multiple locations i.e. is part of a Market */
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type InstinctIntegrationCountAggregateOutputType = {
  __typename?: 'InstinctIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  username: Scalars['Int'];
  password: Scalars['Int'];
  subdomain: Scalars['Int'];
  locationId: Scalars['Int'];
  defaultAppointmentTypeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InstinctIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
};

export type InstinctIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  subdomain: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutInstinctInput>;
};

export type InstinctIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  subdomain: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<InstinctIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<InstinctIntegrationWhereUniqueInput>;
};

export type InstinctIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: InstinctIntegrationWhereUniqueInput;
  create: InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type InstinctIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  subdomain: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationMaxAggregateOutputType = {
  __typename?: 'InstinctIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
};

export type InstinctIntegrationMinAggregateOutputType = {
  __typename?: 'InstinctIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
};

export enum InstinctIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  Username = 'username',
  Password = 'password',
  Subdomain = 'subdomain',
  LocationId = 'locationId',
  DefaultAppointmentTypeId = 'defaultAppointmentTypeId'
}

export type InstinctIntegrationOrderByRelevanceInput = {
  fields: Array<InstinctIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InstinctIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  _count?: Maybe<InstinctIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<InstinctIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<InstinctIntegrationMinOrderByAggregateInput>;
};

export type InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<InstinctIntegrationOrderByRelevanceInput>;
};

export type InstinctIntegrationRelationFilter = {
  is?: Maybe<InstinctIntegrationWhereInput>;
  isNot?: Maybe<InstinctIntegrationWhereInput>;
};

export enum InstinctIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Username = 'username',
  Password = 'password',
  Subdomain = 'subdomain',
  LocationId = 'locationId',
  DefaultAppointmentTypeId = 'defaultAppointmentTypeId'
}

export type InstinctIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InstinctIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InstinctIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InstinctIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  username?: Maybe<StringWithAggregatesFilter>;
  password?: Maybe<StringWithAggregatesFilter>;
  subdomain?: Maybe<StringWithAggregatesFilter>;
  locationId?: Maybe<StringNullableWithAggregatesFilter>;
  defaultAppointmentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type InstinctIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  subdomain: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutInstinctInput>;
};

export type InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  subdomain: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutInstinctNestedInput>;
};

export type InstinctIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutInstinctNestedInput>;
};

export type InstinctIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  create?: Maybe<InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<InstinctIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<InstinctIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InstinctIntegrationWhereUniqueInput>;
  update?: Maybe<InstinctIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type InstinctIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: InstinctIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type InstinctIntegrationWhereInput = {
  AND?: Maybe<Array<InstinctIntegrationWhereInput>>;
  OR?: Maybe<Array<InstinctIntegrationWhereInput>>;
  NOT?: Maybe<Array<InstinctIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  username?: Maybe<StringFilter>;
  password?: Maybe<StringFilter>;
  subdomain?: Maybe<StringFilter>;
  locationId?: Maybe<StringNullableFilter>;
  defaultAppointmentTypeId?: Maybe<StringNullableFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type InstinctIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InsuranceIntegration = {
  __typename?: 'InsuranceIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  isActive: Scalars['Boolean'];
  type: InsuranceIntegrationType;
  trupanionIntegration?: Maybe<TrupanionIntegration>;
  clinic: Clinic;
};

export type InsuranceIntegrationCountAggregateOutputType = {
  __typename?: 'InsuranceIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  isActive: Scalars['Int'];
  type: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InsuranceIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type InsuranceIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type: InsuranceIntegrationType;
  trupanionIntegration?: Maybe<TrupanionIntegrationCreateNestedOneWithoutInsuranceIntegrationInput>;
  clinic: ClinicCreateNestedOneWithoutInsuranceIntegrationsInput;
};

export type InsuranceIntegrationCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type: InsuranceIntegrationType;
};

export type InsuranceIntegrationCreateManyClinicInputEnvelope = {
  data: Array<InsuranceIntegrationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InsuranceIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  type: InsuranceIntegrationType;
};

export type InsuranceIntegrationCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<InsuranceIntegrationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<InsuranceIntegrationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<InsuranceIntegrationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
};

export type InsuranceIntegrationCreateNestedOneWithoutTrupanionIntegrationInput = {
  create?: Maybe<InsuranceIntegrationUncheckedCreateWithoutTrupanionIntegrationInput>;
  connectOrCreate?: Maybe<InsuranceIntegrationCreateOrConnectWithoutTrupanionIntegrationInput>;
  connect?: Maybe<InsuranceIntegrationWhereUniqueInput>;
};

export type InsuranceIntegrationCreateOrConnectWithoutClinicInput = {
  where: InsuranceIntegrationWhereUniqueInput;
  create: InsuranceIntegrationUncheckedCreateWithoutClinicInput;
};

export type InsuranceIntegrationCreateOrConnectWithoutTrupanionIntegrationInput = {
  where: InsuranceIntegrationWhereUniqueInput;
  create: InsuranceIntegrationUncheckedCreateWithoutTrupanionIntegrationInput;
};

export type InsuranceIntegrationCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type: InsuranceIntegrationType;
  trupanionIntegration?: Maybe<TrupanionIntegrationCreateNestedOneWithoutInsuranceIntegrationInput>;
};

export type InsuranceIntegrationCreateWithoutTrupanionIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type: InsuranceIntegrationType;
  clinic: ClinicCreateNestedOneWithoutInsuranceIntegrationsInput;
};

export type InsuranceIntegrationListRelationFilter = {
  every?: Maybe<InsuranceIntegrationWhereInput>;
  some?: Maybe<InsuranceIntegrationWhereInput>;
  none?: Maybe<InsuranceIntegrationWhereInput>;
};

export type InsuranceIntegrationMaxAggregateOutputType = {
  __typename?: 'InsuranceIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
};

export type InsuranceIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type InsuranceIntegrationMinAggregateOutputType = {
  __typename?: 'InsuranceIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
};

export type InsuranceIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type InsuranceIntegrationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InsuranceIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId'
}

export type InsuranceIntegrationOrderByRelevanceInput = {
  fields: Array<InsuranceIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InsuranceIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  _count?: Maybe<InsuranceIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<InsuranceIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<InsuranceIntegrationMinOrderByAggregateInput>;
};

export type InsuranceIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  trupanionIntegration?: Maybe<TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<InsuranceIntegrationOrderByRelevanceInput>;
};

export type InsuranceIntegrationRelationFilter = {
  is?: Maybe<InsuranceIntegrationWhereInput>;
  isNot?: Maybe<InsuranceIntegrationWhereInput>;
};

export enum InsuranceIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  IsActive = 'isActive',
  Type = 'type'
}

export type InsuranceIntegrationScalarWhereInput = {
  AND?: Maybe<Array<InsuranceIntegrationScalarWhereInput>>;
  OR?: Maybe<Array<InsuranceIntegrationScalarWhereInput>>;
  NOT?: Maybe<Array<InsuranceIntegrationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  type?: Maybe<EnumInsuranceIntegrationTypeFilter>;
};

export type InsuranceIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InsuranceIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InsuranceIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InsuranceIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  type?: Maybe<EnumInsuranceIntegrationTypeWithAggregatesFilter>;
};

export enum InsuranceIntegrationType {
  Trupanion = 'Trupanion'
}

export type InsuranceIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  type: InsuranceIntegrationType;
  trupanionIntegration?: Maybe<TrupanionIntegrationUncheckedCreateNestedOneWithoutInsuranceIntegrationInput>;
};

export type InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<InsuranceIntegrationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<InsuranceIntegrationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<InsuranceIntegrationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
};

export type InsuranceIntegrationUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type: InsuranceIntegrationType;
  trupanionIntegration?: Maybe<TrupanionIntegrationUncheckedCreateNestedOneWithoutInsuranceIntegrationInput>;
};

export type InsuranceIntegrationUncheckedCreateWithoutTrupanionIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  type: InsuranceIntegrationType;
};

export type InsuranceIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUncheckedUpdateOneWithoutInsuranceIntegrationNestedInput>;
};

export type InsuranceIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
};

export type InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<InsuranceIntegrationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<InsuranceIntegrationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<InsuranceIntegrationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<InsuranceIntegrationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  disconnect?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  delete?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  connect?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  update?: Maybe<Array<InsuranceIntegrationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<InsuranceIntegrationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<InsuranceIntegrationScalarWhereInput>>;
};

export type InsuranceIntegrationUncheckedUpdateManyWithoutInsuranceIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
};

export type InsuranceIntegrationUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUncheckedUpdateOneWithoutInsuranceIntegrationNestedInput>;
};

export type InsuranceIntegrationUncheckedUpdateWithoutTrupanionIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
};

export type InsuranceIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneWithoutInsuranceIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutInsuranceIntegrationsNestedInput>;
};

export type InsuranceIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
};

export type InsuranceIntegrationUpdateManyWithWhereWithoutClinicInput = {
  where: InsuranceIntegrationScalarWhereInput;
  data: InsuranceIntegrationUncheckedUpdateManyWithoutInsuranceIntegrationsInput;
};

export type InsuranceIntegrationUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<InsuranceIntegrationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<InsuranceIntegrationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<InsuranceIntegrationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<InsuranceIntegrationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  disconnect?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  delete?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  connect?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  update?: Maybe<Array<InsuranceIntegrationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<InsuranceIntegrationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<InsuranceIntegrationScalarWhereInput>>;
};

export type InsuranceIntegrationUpdateOneRequiredWithoutTrupanionIntegrationNestedInput = {
  create?: Maybe<InsuranceIntegrationUncheckedCreateWithoutTrupanionIntegrationInput>;
  connectOrCreate?: Maybe<InsuranceIntegrationCreateOrConnectWithoutTrupanionIntegrationInput>;
  upsert?: Maybe<InsuranceIntegrationUpsertWithoutTrupanionIntegrationInput>;
  connect?: Maybe<InsuranceIntegrationWhereUniqueInput>;
  update?: Maybe<InsuranceIntegrationUncheckedUpdateWithoutTrupanionIntegrationInput>;
};

export type InsuranceIntegrationUpdateWithWhereUniqueWithoutClinicInput = {
  where: InsuranceIntegrationWhereUniqueInput;
  data: InsuranceIntegrationUncheckedUpdateWithoutClinicInput;
};

export type InsuranceIntegrationUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneWithoutInsuranceIntegrationNestedInput>;
};

export type InsuranceIntegrationUpdateWithoutTrupanionIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutInsuranceIntegrationsNestedInput>;
};

export type InsuranceIntegrationUpsertWithWhereUniqueWithoutClinicInput = {
  where: InsuranceIntegrationWhereUniqueInput;
  update: InsuranceIntegrationUncheckedUpdateWithoutClinicInput;
  create: InsuranceIntegrationUncheckedCreateWithoutClinicInput;
};

export type InsuranceIntegrationUpsertWithoutTrupanionIntegrationInput = {
  update: InsuranceIntegrationUncheckedUpdateWithoutTrupanionIntegrationInput;
  create: InsuranceIntegrationUncheckedCreateWithoutTrupanionIntegrationInput;
};

export type InsuranceIntegrationWhereInput = {
  AND?: Maybe<Array<InsuranceIntegrationWhereInput>>;
  OR?: Maybe<Array<InsuranceIntegrationWhereInput>>;
  NOT?: Maybe<Array<InsuranceIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  type?: Maybe<EnumInsuranceIntegrationTypeFilter>;
  trupanionIntegration?: Maybe<TrupanionIntegrationWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type InsuranceIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type IntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type IntNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
};

export type IntWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
};

export type IntegrationOutageHistoryEntry = {
  __typename?: 'IntegrationOutageHistoryEntry';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  integrationId: Scalars['String'];
  status: Scalars['String'];
  isActive: Scalars['Boolean'];
  integration: ClinicPimsIntegration;
};

export type IntegrationOutageHistoryEntryCountAggregateOutputType = {
  __typename?: 'IntegrationOutageHistoryEntryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  integrationId: Scalars['Int'];
  status: Scalars['Int'];
  isActive: Scalars['Int'];
  _all: Scalars['Int'];
};

export type IntegrationOutageHistoryEntryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
};

export type IntegrationOutageHistoryEntryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutIntegrationOutageHistoryEntryInput;
};

export type IntegrationOutageHistoryEntryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  status: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryCreateManyIntegrationInputEnvelope = {
  data: Array<IntegrationOutageHistoryEntryCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<IntegrationOutageHistoryEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<IntegrationOutageHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<IntegrationOutageHistoryEntryCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
};

export type IntegrationOutageHistoryEntryCreateOrConnectWithoutIntegrationInput = {
  where: IntegrationOutageHistoryEntryWhereUniqueInput;
  create: IntegrationOutageHistoryEntryUncheckedCreateWithoutIntegrationInput;
};

export type IntegrationOutageHistoryEntryCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryListRelationFilter = {
  every?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
  some?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
  none?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
};

export type IntegrationOutageHistoryEntryMaxAggregateOutputType = {
  __typename?: 'IntegrationOutageHistoryEntryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
};

export type IntegrationOutageHistoryEntryMinAggregateOutputType = {
  __typename?: 'IntegrationOutageHistoryEntryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
};

export type IntegrationOutageHistoryEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum IntegrationOutageHistoryEntryOrderByRelevanceFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  Status = 'status'
}

export type IntegrationOutageHistoryEntryOrderByRelevanceInput = {
  fields: Array<IntegrationOutageHistoryEntryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type IntegrationOutageHistoryEntryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  _count?: Maybe<IntegrationOutageHistoryEntryCountOrderByAggregateInput>;
  _max?: Maybe<IntegrationOutageHistoryEntryMaxOrderByAggregateInput>;
  _min?: Maybe<IntegrationOutageHistoryEntryMinOrderByAggregateInput>;
};

export type IntegrationOutageHistoryEntryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<IntegrationOutageHistoryEntryOrderByRelevanceInput>;
};

export enum IntegrationOutageHistoryEntryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  IntegrationId = 'integrationId',
  Status = 'status',
  IsActive = 'isActive'
}

export type IntegrationOutageHistoryEntryScalarWhereInput = {
  AND?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereInput>>;
  OR?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereInput>>;
  NOT?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  integrationId?: Maybe<StringFilter>;
  status?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
};

export type IntegrationOutageHistoryEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
};

export type IntegrationOutageHistoryEntrySubscription = {
  __typename?: 'IntegrationOutageHistoryEntrySubscription';
  node: IntegrationOutageHistoryEntry;
  mutation: MutationType;
};

export type IntegrationOutageHistoryEntryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  status: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<IntegrationOutageHistoryEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<IntegrationOutageHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<IntegrationOutageHistoryEntryCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
};

export type IntegrationOutageHistoryEntryUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<IntegrationOutageHistoryEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<IntegrationOutageHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<IntegrationOutageHistoryEntryUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<IntegrationOutageHistoryEntryCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  delete?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  connect?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<IntegrationOutageHistoryEntryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<IntegrationOutageHistoryEntryUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereInput>>;
};

export type IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationOutageHistoryEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutIntegrationOutageHistoryEntryNestedInput>;
};

export type IntegrationOutageHistoryEntryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryUpdateManyWithWhereWithoutIntegrationInput = {
  where: IntegrationOutageHistoryEntryScalarWhereInput;
  data: IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationOutageHistoryEntryInput;
};

export type IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<IntegrationOutageHistoryEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<IntegrationOutageHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<IntegrationOutageHistoryEntryUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<IntegrationOutageHistoryEntryCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  delete?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  connect?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<IntegrationOutageHistoryEntryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<IntegrationOutageHistoryEntryUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereInput>>;
};

export type IntegrationOutageHistoryEntryUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: IntegrationOutageHistoryEntryWhereUniqueInput;
  data: IntegrationOutageHistoryEntryUncheckedUpdateWithoutIntegrationInput;
};

export type IntegrationOutageHistoryEntryUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: IntegrationOutageHistoryEntryWhereUniqueInput;
  update: IntegrationOutageHistoryEntryUncheckedUpdateWithoutIntegrationInput;
  create: IntegrationOutageHistoryEntryUncheckedCreateWithoutIntegrationInput;
};

export type IntegrationOutageHistoryEntryWhereInput = {
  AND?: Maybe<Array<IntegrationOutageHistoryEntryWhereInput>>;
  OR?: Maybe<Array<IntegrationOutageHistoryEntryWhereInput>>;
  NOT?: Maybe<Array<IntegrationOutageHistoryEntryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  integrationId?: Maybe<StringFilter>;
  status?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type IntegrationOutageHistoryEntryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum IntegrationStatus {
  Created = 'Created',
  Connected = 'Connected',
  Valid = 'Valid',
  Abandoned = 'Abandoned'
}

export enum IntegrationType {
  Neo = 'NEO',
  Ezyvet = 'EZYVET',
  Vetdata = 'VETDATA',
  Instinct = 'INSTINCT',
  Vetter = 'VETTER',
  PetWidget = 'PET_WIDGET',
  ClinicWebApp = 'CLINIC_WEB_APP',
  Internal = 'INTERNAL',
  SalesforceWebhook = 'SALESFORCE_WEBHOOK',
  DocupostWebhook = 'DOCUPOST_WEBHOOK',
  VideoPluginWebApp = 'VIDEO_PLUGIN_WEB_APP',
  TerminalApp = 'TERMINAL_APP',
  StripePciReview = 'STRIPE_PCI_REVIEW'
}

export type InterchangeRate = {
  __typename?: 'InterchangeRate';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicPaymentFeeConfigurationId: Scalars['String'];
  paymentMedia: Array<PaymentMedium>;
  fundingSources: Array<CardFunding>;
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  isActive: Scalars['Boolean'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  clinicPaymentFeeConfiguration: ClinicPaymentFeeConfiguration;
};

export type InterchangeRateAvgAggregateOutputType = {
  __typename?: 'InterchangeRateAvgAggregateOutputType';
  basisPoints?: Maybe<Scalars['Float']>;
  centsPerTransaction?: Maybe<Scalars['Float']>;
};

export type InterchangeRateAvgOrderByAggregateInput = {
  basisPoints?: Maybe<SortOrder>;
  centsPerTransaction?: Maybe<SortOrder>;
};

export type InterchangeRateCountAggregateOutputType = {
  __typename?: 'InterchangeRateCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicPaymentFeeConfigurationId: Scalars['Int'];
  paymentMedia: Scalars['Int'];
  fundingSources: Scalars['Int'];
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  isActive: Scalars['Int'];
  startsAt: Scalars['Int'];
  endsAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InterchangeRateCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPaymentFeeConfigurationId?: Maybe<SortOrder>;
  paymentMedia?: Maybe<SortOrder>;
  fundingSources?: Maybe<SortOrder>;
  basisPoints?: Maybe<SortOrder>;
  centsPerTransaction?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
};

export type InterchangeRateCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  fundingSources?: Maybe<Array<CardFunding>>;
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  clinicPaymentFeeConfiguration: ClinicPaymentFeeConfigurationCreateNestedOneWithoutInterchangeRatesInput;
};

export type InterchangeRateCreateManyClinicPaymentFeeConfigurationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  fundingSources?: Maybe<Array<CardFunding>>;
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
};

export type InterchangeRateCreateManyClinicPaymentFeeConfigurationInputEnvelope = {
  data: Array<InterchangeRateCreateManyClinicPaymentFeeConfigurationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InterchangeRateCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPaymentFeeConfigurationId: Scalars['String'];
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  fundingSources?: Maybe<Array<CardFunding>>;
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
};

export type InterchangeRateCreateNestedManyWithoutClinicPaymentFeeConfigurationInput = {
  create?: Maybe<Array<InterchangeRateCreateWithoutClinicPaymentFeeConfigurationInput>>;
  connectOrCreate?: Maybe<Array<InterchangeRateCreateOrConnectWithoutClinicPaymentFeeConfigurationInput>>;
  createMany?: Maybe<InterchangeRateCreateManyClinicPaymentFeeConfigurationInputEnvelope>;
  connect?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
};

export type InterchangeRateCreateOrConnectWithoutClinicPaymentFeeConfigurationInput = {
  where: InterchangeRateWhereUniqueInput;
  create: InterchangeRateUncheckedCreateWithoutClinicPaymentFeeConfigurationInput;
};

export type InterchangeRateCreateWithoutClinicPaymentFeeConfigurationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  fundingSources?: Maybe<Array<CardFunding>>;
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
};

export type InterchangeRateCreatefundingSourcesInput = {
  set: Array<CardFunding>;
};

export type InterchangeRateCreatepaymentMediaInput = {
  set: Array<PaymentMedium>;
};

export type InterchangeRateListRelationFilter = {
  every?: Maybe<InterchangeRateWhereInput>;
  some?: Maybe<InterchangeRateWhereInput>;
  none?: Maybe<InterchangeRateWhereInput>;
};

export type InterchangeRateMaxAggregateOutputType = {
  __typename?: 'InterchangeRateMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPaymentFeeConfigurationId?: Maybe<Scalars['String']>;
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPaymentFeeConfigurationId?: Maybe<SortOrder>;
  basisPoints?: Maybe<SortOrder>;
  centsPerTransaction?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
};

export type InterchangeRateMinAggregateOutputType = {
  __typename?: 'InterchangeRateMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPaymentFeeConfigurationId?: Maybe<Scalars['String']>;
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPaymentFeeConfigurationId?: Maybe<SortOrder>;
  basisPoints?: Maybe<SortOrder>;
  centsPerTransaction?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
};

export type InterchangeRateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InterchangeRateOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicPaymentFeeConfigurationId = 'clinicPaymentFeeConfigurationId'
}

export type InterchangeRateOrderByRelevanceInput = {
  fields: Array<InterchangeRateOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InterchangeRateOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPaymentFeeConfigurationId?: Maybe<SortOrder>;
  paymentMedia?: Maybe<SortOrder>;
  fundingSources?: Maybe<SortOrder>;
  basisPoints?: Maybe<SortOrder>;
  centsPerTransaction?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  _count?: Maybe<InterchangeRateCountOrderByAggregateInput>;
  _avg?: Maybe<InterchangeRateAvgOrderByAggregateInput>;
  _max?: Maybe<InterchangeRateMaxOrderByAggregateInput>;
  _min?: Maybe<InterchangeRateMinOrderByAggregateInput>;
  _sum?: Maybe<InterchangeRateSumOrderByAggregateInput>;
};

export type InterchangeRateOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPaymentFeeConfigurationId?: Maybe<SortOrder>;
  paymentMedia?: Maybe<SortOrder>;
  fundingSources?: Maybe<SortOrder>;
  basisPoints?: Maybe<SortOrder>;
  centsPerTransaction?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  clinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<InterchangeRateOrderByRelevanceInput>;
};

export enum InterchangeRateScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicPaymentFeeConfigurationId = 'clinicPaymentFeeConfigurationId',
  PaymentMedia = 'paymentMedia',
  FundingSources = 'fundingSources',
  BasisPoints = 'basisPoints',
  CentsPerTransaction = 'centsPerTransaction',
  IsActive = 'isActive',
  StartsAt = 'startsAt',
  EndsAt = 'endsAt'
}

export type InterchangeRateScalarWhereInput = {
  AND?: Maybe<Array<InterchangeRateScalarWhereInput>>;
  OR?: Maybe<Array<InterchangeRateScalarWhereInput>>;
  NOT?: Maybe<Array<InterchangeRateScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicPaymentFeeConfigurationId?: Maybe<StringFilter>;
  paymentMedia?: Maybe<EnumPaymentMediumNullableListFilter>;
  fundingSources?: Maybe<EnumCardFundingNullableListFilter>;
  basisPoints?: Maybe<IntFilter>;
  centsPerTransaction?: Maybe<IntFilter>;
  isActive?: Maybe<BoolFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  endsAt?: Maybe<DateTimeFilter>;
};

export type InterchangeRateScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InterchangeRateScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InterchangeRateScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InterchangeRateScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicPaymentFeeConfigurationId?: Maybe<StringWithAggregatesFilter>;
  paymentMedia?: Maybe<EnumPaymentMediumNullableListFilter>;
  fundingSources?: Maybe<EnumCardFundingNullableListFilter>;
  basisPoints?: Maybe<IntWithAggregatesFilter>;
  centsPerTransaction?: Maybe<IntWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  startsAt?: Maybe<DateTimeWithAggregatesFilter>;
  endsAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type InterchangeRateSumAggregateOutputType = {
  __typename?: 'InterchangeRateSumAggregateOutputType';
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
};

export type InterchangeRateSumOrderByAggregateInput = {
  basisPoints?: Maybe<SortOrder>;
  centsPerTransaction?: Maybe<SortOrder>;
};

export type InterchangeRateUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPaymentFeeConfigurationId: Scalars['String'];
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  fundingSources?: Maybe<Array<CardFunding>>;
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
};

export type InterchangeRateUncheckedCreateNestedManyWithoutClinicPaymentFeeConfigurationInput = {
  create?: Maybe<Array<InterchangeRateCreateWithoutClinicPaymentFeeConfigurationInput>>;
  connectOrCreate?: Maybe<Array<InterchangeRateCreateOrConnectWithoutClinicPaymentFeeConfigurationInput>>;
  createMany?: Maybe<InterchangeRateCreateManyClinicPaymentFeeConfigurationInputEnvelope>;
  connect?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
};

export type InterchangeRateUncheckedCreateWithoutClinicPaymentFeeConfigurationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  fundingSources?: Maybe<Array<CardFunding>>;
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
};

export type InterchangeRateUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPaymentFeeConfigurationId?: Maybe<Scalars['String']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  fundingSources?: Maybe<Array<CardFunding>>;
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPaymentFeeConfigurationId?: Maybe<Scalars['String']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  fundingSources?: Maybe<Array<CardFunding>>;
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUncheckedUpdateManyWithoutClinicPaymentFeeConfigurationNestedInput = {
  create?: Maybe<Array<InterchangeRateCreateWithoutClinicPaymentFeeConfigurationInput>>;
  connectOrCreate?: Maybe<Array<InterchangeRateCreateOrConnectWithoutClinicPaymentFeeConfigurationInput>>;
  upsert?: Maybe<Array<InterchangeRateUpsertWithWhereUniqueWithoutClinicPaymentFeeConfigurationInput>>;
  createMany?: Maybe<InterchangeRateCreateManyClinicPaymentFeeConfigurationInputEnvelope>;
  set?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  disconnect?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  delete?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  connect?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  update?: Maybe<Array<InterchangeRateUpdateWithWhereUniqueWithoutClinicPaymentFeeConfigurationInput>>;
  updateMany?: Maybe<Array<InterchangeRateUpdateManyWithWhereWithoutClinicPaymentFeeConfigurationInput>>;
  deleteMany?: Maybe<Array<InterchangeRateScalarWhereInput>>;
};

export type InterchangeRateUncheckedUpdateManyWithoutInterchangeRatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  fundingSources?: Maybe<Array<CardFunding>>;
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUncheckedUpdateWithoutClinicPaymentFeeConfigurationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  fundingSources?: Maybe<Array<CardFunding>>;
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  fundingSources?: Maybe<Array<CardFunding>>;
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  clinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfigurationUpdateOneRequiredWithoutInterchangeRatesNestedInput>;
};

export type InterchangeRateUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  fundingSources?: Maybe<Array<CardFunding>>;
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUpdateManyWithWhereWithoutClinicPaymentFeeConfigurationInput = {
  where: InterchangeRateScalarWhereInput;
  data: InterchangeRateUncheckedUpdateManyWithoutInterchangeRatesInput;
};

export type InterchangeRateUpdateManyWithoutClinicPaymentFeeConfigurationNestedInput = {
  create?: Maybe<Array<InterchangeRateCreateWithoutClinicPaymentFeeConfigurationInput>>;
  connectOrCreate?: Maybe<Array<InterchangeRateCreateOrConnectWithoutClinicPaymentFeeConfigurationInput>>;
  upsert?: Maybe<Array<InterchangeRateUpsertWithWhereUniqueWithoutClinicPaymentFeeConfigurationInput>>;
  createMany?: Maybe<InterchangeRateCreateManyClinicPaymentFeeConfigurationInputEnvelope>;
  set?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  disconnect?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  delete?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  connect?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  update?: Maybe<Array<InterchangeRateUpdateWithWhereUniqueWithoutClinicPaymentFeeConfigurationInput>>;
  updateMany?: Maybe<Array<InterchangeRateUpdateManyWithWhereWithoutClinicPaymentFeeConfigurationInput>>;
  deleteMany?: Maybe<Array<InterchangeRateScalarWhereInput>>;
};

export type InterchangeRateUpdateWithWhereUniqueWithoutClinicPaymentFeeConfigurationInput = {
  where: InterchangeRateWhereUniqueInput;
  data: InterchangeRateUncheckedUpdateWithoutClinicPaymentFeeConfigurationInput;
};

export type InterchangeRateUpdateWithoutClinicPaymentFeeConfigurationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  fundingSources?: Maybe<Array<CardFunding>>;
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUpdatefundingSourcesInput = {
  set?: Maybe<Array<CardFunding>>;
  push?: Maybe<Array<CardFunding>>;
};

export type InterchangeRateUpdatepaymentMediaInput = {
  set?: Maybe<Array<PaymentMedium>>;
  push?: Maybe<Array<PaymentMedium>>;
};

export type InterchangeRateUpsertWithWhereUniqueWithoutClinicPaymentFeeConfigurationInput = {
  where: InterchangeRateWhereUniqueInput;
  update: InterchangeRateUncheckedUpdateWithoutClinicPaymentFeeConfigurationInput;
  create: InterchangeRateUncheckedCreateWithoutClinicPaymentFeeConfigurationInput;
};

export type InterchangeRateWhereInput = {
  AND?: Maybe<Array<InterchangeRateWhereInput>>;
  OR?: Maybe<Array<InterchangeRateWhereInput>>;
  NOT?: Maybe<Array<InterchangeRateWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicPaymentFeeConfigurationId?: Maybe<StringFilter>;
  paymentMedia?: Maybe<EnumPaymentMediumNullableListFilter>;
  fundingSources?: Maybe<EnumCardFundingNullableListFilter>;
  basisPoints?: Maybe<IntFilter>;
  centsPerTransaction?: Maybe<IntFilter>;
  isActive?: Maybe<BoolFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  clinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
};

export type InterchangeRateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes: Scalars['Int'];
  total: Scalars['Int'];
  status: InvoiceStatus;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance: Scalars['Int'];
  currency: Currency;
  /** The clinic readable identifier for this invoice, e.g. number */
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParent;
  sentBy?: Maybe<User>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  lineItems: Array<InvoiceLineItem>;
  pimsInvoice?: Maybe<PimsInvoice>;
  relatedInvoiceWritebacks: Array<InvoicePimsWritebackLogEntry>;
  associatedCareBenefitCredits: Array<CareBenefitUsageToInvoiceLineItem>;
  loyaltyPointDeltas: Array<LoyaltyPointDelta>;
  careAccountCreditIntents: Array<CareAccountCreditIntent>;
  _count: InvoiceCountOutputType;
};


export type InvoiceStripePaymentIntentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};


export type InvoiceLineItemsArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
};


export type InvoiceRelatedInvoiceWritebacksArgs = {
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoicePimsWritebackLogEntryScalarFieldEnum>;
};


export type InvoiceAssociatedCareBenefitCreditsArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
};


export type InvoiceLoyaltyPointDeltasArgs = {
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
  orderBy?: Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyPointDeltaScalarFieldEnum>;
};


export type InvoiceCareAccountCreditIntentsArgs = {
  where?: Maybe<CareAccountCreditIntentWhereInput>;
  orderBy?: Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAccountCreditIntentScalarFieldEnum>;
};

export type InvoiceAvgAggregateOutputType = {
  __typename?: 'InvoiceAvgAggregateOutputType';
  subtotal?: Maybe<Scalars['Float']>;
  taxes?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  outstandingBalance?: Maybe<Scalars['Float']>;
};

export type InvoiceAvgOrderByAggregateInput = {
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
};

export type InvoiceCountAggregateOutputType = {
  __typename?: 'InvoiceCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  subtotal: Scalars['Int'];
  taxes: Scalars['Int'];
  total: Scalars['Int'];
  status: Scalars['Int'];
  date: Scalars['Int'];
  outstandingBalance: Scalars['Int'];
  currency: Scalars['Int'];
  identifier: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  sentByUserId: Scalars['Int'];
  description: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InvoiceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type InvoiceCountOutputType = {
  __typename?: 'InvoiceCountOutputType';
  stripePaymentIntents: Scalars['Int'];
  lineItems: Scalars['Int'];
  relatedInvoiceWritebacks: Scalars['Int'];
  associatedCareBenefitCredits: Scalars['Int'];
  loyaltyPointDeltas: Scalars['Int'];
  careAccountCreditIntents: Scalars['Int'];
};

export type InvoiceCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceCreateManyClinicPetParentInputEnvelope = {
  data: Array<InvoiceCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceCreateManySentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type InvoiceCreateManySentByInputEnvelope = {
  data: Array<InvoiceCreateManySentByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<InvoiceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<InvoiceCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceCreateNestedManyWithoutSentByInput = {
  create?: Maybe<Array<InvoiceCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutSentByInput>>;
  createMany?: Maybe<InvoiceCreateManySentByInputEnvelope>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceCreateNestedManyWithoutStripePaymentIntentsInput = {
  create?: Maybe<Array<InvoiceCreateWithoutStripePaymentIntentsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutStripePaymentIntentsInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutAssociatedCareBenefitCreditsInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type InvoiceCreateNestedOneWithoutCareAccountCreditIntentsInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutCareAccountCreditIntentsInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutCareAccountCreditIntentsInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type InvoiceCreateNestedOneWithoutLineItemsInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutLineItemsInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutLineItemsInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type InvoiceCreateNestedOneWithoutLoyaltyPointDeltasInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutLoyaltyPointDeltasInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutLoyaltyPointDeltasInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type InvoiceCreateNestedOneWithoutPimsInvoiceInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutPimsInvoiceInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutPimsInvoiceInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type InvoiceCreateNestedOneWithoutRelatedInvoiceWritebacksInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutRelatedInvoiceWritebacksInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type InvoiceCreateOrConnectWithoutAssociatedCareBenefitCreditsInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput;
};

export type InvoiceCreateOrConnectWithoutCareAccountCreditIntentsInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutCareAccountCreditIntentsInput;
};

export type InvoiceCreateOrConnectWithoutClinicPetParentInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutClinicPetParentInput;
};

export type InvoiceCreateOrConnectWithoutLineItemsInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutLineItemsInput;
};

export type InvoiceCreateOrConnectWithoutLoyaltyPointDeltasInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutLoyaltyPointDeltasInput;
};

export type InvoiceCreateOrConnectWithoutPimsInvoiceInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutPimsInvoiceInput;
};

export type InvoiceCreateOrConnectWithoutRelatedInvoiceWritebacksInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput;
};

export type InvoiceCreateOrConnectWithoutSentByInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutSentByInput;
};

export type InvoiceCreateOrConnectWithoutStripePaymentIntentsInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type InvoiceCreateWithoutAssociatedCareBenefitCreditsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceCreateWithoutCareAccountCreditIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
};

export type InvoiceCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceCreateWithoutLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceCreateWithoutLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceCreateWithoutPimsInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceCreateWithoutRelatedInvoiceWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceCreateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isVoided: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  /** The time field is the time the item was added (or service was rendered for PIMS-managed invoices) */
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  invoice: Invoice;
  service?: Maybe<Service>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  clinicPets: Array<ClinicPet>;
  provider?: Maybe<ClinicEmployee>;
  enteredBy?: Maybe<ClinicEmployee>;
  connectedCareBenefits: Array<CareBenefitUsageToInvoiceLineItem>;
  AutomationRun: Array<AutomationRun>;
  _count: InvoiceLineItemCountOutputType;
};


export type InvoiceLineItemClinicPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};


export type InvoiceLineItemConnectedCareBenefitsArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
};


export type InvoiceLineItemAutomationRunArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};

export type InvoiceLineItemAvgAggregateOutputType = {
  __typename?: 'InvoiceLineItemAvgAggregateOutputType';
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  lineNumber?: Maybe<Scalars['Float']>;
};

export type InvoiceLineItemAvgOrderByAggregateInput = {
  quantity?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
};

export type InvoiceLineItemCountAggregateOutputType = {
  __typename?: 'InvoiceLineItemCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  invoiceId: Scalars['Int'];
  serviceId: Scalars['Int'];
  description: Scalars['Int'];
  type: Scalars['Int'];
  quantity: Scalars['Int'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable: Scalars['Int'];
  isHidden: Scalars['Int'];
  isVoided: Scalars['Int'];
  isDeleted: Scalars['Int'];
  time: Scalars['Int'];
  providerId: Scalars['Int'];
  enteredById: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InvoiceLineItemCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  providerId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
};

export type InvoiceLineItemCountOutputType = {
  __typename?: 'InvoiceLineItemCountOutputType';
  clinicPets: Scalars['Int'];
  connectedCareBenefits: Scalars['Int'];
  AutomationRun: Scalars['Int'];
};

export type InvoiceLineItemCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemCreateManyEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemCreateManyEnteredByInputEnvelope = {
  data: Array<InvoiceLineItemCreateManyEnteredByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceLineItemCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemCreateManyInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemCreateManyInvoiceInputEnvelope = {
  data: Array<InvoiceLineItemCreateManyInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceLineItemCreateManyProviderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemCreateManyProviderInputEnvelope = {
  data: Array<InvoiceLineItemCreateManyProviderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceLineItemCreateManyServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemCreateManyServiceInputEnvelope = {
  data: Array<InvoiceLineItemCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceLineItemCreateNestedManyWithoutClinicPetsInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutClinicPetsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutClinicPetsInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemCreateNestedManyWithoutEnteredByInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutEnteredByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutEnteredByInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyEnteredByInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemCreateNestedManyWithoutInvoiceInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutInvoiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyInvoiceInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemCreateNestedManyWithoutProviderInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutProviderInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutProviderInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyProviderInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemCreateNestedOneWithoutAutomationRunInput = {
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutAutomationRunInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutAutomationRunInput>;
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
};

export type InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput = {
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutConnectedCareBenefitsInput>;
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
};

export type InvoiceLineItemCreateNestedOneWithoutPimsInvoiceLineItemInput = {
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutPimsInvoiceLineItemInput>;
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
};

export type InvoiceLineItemCreateOrConnectWithoutAutomationRunInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutAutomationRunInput;
};

export type InvoiceLineItemCreateOrConnectWithoutClinicPetsInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutClinicPetsInput;
};

export type InvoiceLineItemCreateOrConnectWithoutConnectedCareBenefitsInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput;
};

export type InvoiceLineItemCreateOrConnectWithoutEnteredByInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutEnteredByInput;
};

export type InvoiceLineItemCreateOrConnectWithoutInvoiceInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutInvoiceInput;
};

export type InvoiceLineItemCreateOrConnectWithoutPimsInvoiceLineItemInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput;
};

export type InvoiceLineItemCreateOrConnectWithoutProviderInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutProviderInput;
};

export type InvoiceLineItemCreateOrConnectWithoutServiceInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutServiceInput;
};

export type InvoiceLineItemCreateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemCreateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemCreateWithoutConnectedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemCreateWithoutEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemCreateWithoutInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemCreateWithoutPimsInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemCreateWithoutProviderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemListRelationFilter = {
  every?: Maybe<InvoiceLineItemWhereInput>;
  some?: Maybe<InvoiceLineItemWhereInput>;
  none?: Maybe<InvoiceLineItemWhereInput>;
};

export type InvoiceLineItemMaxAggregateOutputType = {
  __typename?: 'InvoiceLineItemMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  providerId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
};

export type InvoiceLineItemMinAggregateOutputType = {
  __typename?: 'InvoiceLineItemMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  providerId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
};

export type InvoiceLineItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InvoiceLineItemOrderByRelevanceFieldEnum {
  Id = 'id',
  InvoiceId = 'invoiceId',
  ServiceId = 'serviceId',
  Description = 'description',
  ProviderId = 'providerId',
  EnteredById = 'enteredById'
}

export type InvoiceLineItemOrderByRelevanceInput = {
  fields: Array<InvoiceLineItemOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InvoiceLineItemOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  providerId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  _count?: Maybe<InvoiceLineItemCountOrderByAggregateInput>;
  _avg?: Maybe<InvoiceLineItemAvgOrderByAggregateInput>;
  _max?: Maybe<InvoiceLineItemMaxOrderByAggregateInput>;
  _min?: Maybe<InvoiceLineItemMinOrderByAggregateInput>;
  _sum?: Maybe<InvoiceLineItemSumOrderByAggregateInput>;
};

export type InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  providerId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  invoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  clinicPets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  provider?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  enteredBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  AutomationRun?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  _relevance?: Maybe<InvoiceLineItemOrderByRelevanceInput>;
};

export type InvoiceLineItemRelationFilter = {
  is?: Maybe<InvoiceLineItemWhereInput>;
  isNot?: Maybe<InvoiceLineItemWhereInput>;
};

export enum InvoiceLineItemScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InvoiceId = 'invoiceId',
  ServiceId = 'serviceId',
  Description = 'description',
  Type = 'type',
  Quantity = 'quantity',
  Amount = 'amount',
  Discount = 'discount',
  Fee = 'fee',
  LineNumber = 'lineNumber',
  IsTaxable = 'isTaxable',
  IsHidden = 'isHidden',
  IsVoided = 'isVoided',
  IsDeleted = 'isDeleted',
  Time = 'time',
  ProviderId = 'providerId',
  EnteredById = 'enteredById'
}

export type InvoiceLineItemScalarWhereInput = {
  AND?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  OR?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  NOT?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  invoiceId?: Maybe<StringFilter>;
  serviceId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringFilter>;
  type?: Maybe<EnumInvoiceLineItemTypeFilter>;
  quantity?: Maybe<FloatFilter>;
  amount?: Maybe<IntFilter>;
  discount?: Maybe<IntFilter>;
  fee?: Maybe<IntFilter>;
  lineNumber?: Maybe<IntFilter>;
  isTaxable?: Maybe<BoolFilter>;
  isHidden?: Maybe<BoolFilter>;
  isVoided?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  time?: Maybe<DateTimeNullableFilter>;
  providerId?: Maybe<StringNullableFilter>;
  enteredById?: Maybe<StringNullableFilter>;
};

export type InvoiceLineItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InvoiceLineItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InvoiceLineItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InvoiceLineItemScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  invoiceId?: Maybe<StringWithAggregatesFilter>;
  serviceId?: Maybe<StringNullableWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumInvoiceLineItemTypeWithAggregatesFilter>;
  quantity?: Maybe<FloatWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  discount?: Maybe<IntWithAggregatesFilter>;
  fee?: Maybe<IntWithAggregatesFilter>;
  lineNumber?: Maybe<IntWithAggregatesFilter>;
  isTaxable?: Maybe<BoolWithAggregatesFilter>;
  isHidden?: Maybe<BoolWithAggregatesFilter>;
  isVoided?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  time?: Maybe<DateTimeNullableWithAggregatesFilter>;
  providerId?: Maybe<StringNullableWithAggregatesFilter>;
  enteredById?: Maybe<StringNullableWithAggregatesFilter>;
};

export type InvoiceLineItemSumAggregateOutputType = {
  __typename?: 'InvoiceLineItemSumAggregateOutputType';
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
};

export type InvoiceLineItemSumOrderByAggregateInput = {
  quantity?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
};

export enum InvoiceLineItemType {
  Adjustment = 'adjustment',
  Discount = 'discount',
  Code = 'code',
  Return = 'return',
  Payment = 'payment',
  Note = 'note',
  Fee = 'fee',
  Estimate = 'estimate',
  Writeoff = 'writeoff',
  Tax = 'tax',
  Unknown = 'unknown'
}

export type InvoiceLineItemUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutClinicPetsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutClinicPetsInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutEnteredByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutEnteredByInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyEnteredByInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutInvoiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyInvoiceInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutProviderInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutProviderInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyProviderInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemUncheckedCreateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUncheckedCreateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemUncheckedCreateWithoutEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemUncheckedCreateWithoutInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemUncheckedCreateWithoutProviderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  enteredById?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemUncheckedCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
};

export type InvoiceLineItemUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutClinicPetsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutClinicPetsInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutClinicPetsInput>>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutClinicPetsInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutClinicPetsInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutEnteredByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutEnteredByInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutEnteredByInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyEnteredByInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutEnteredByInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutEnteredByInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutInvoiceInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyInvoiceInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutInvoiceInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutProviderInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutProviderInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutProviderInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutProviderInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyProviderInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutProviderInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutProviderInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUncheckedUpdateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
};

export type InvoiceLineItemUncheckedUpdateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUncheckedUpdateWithoutConnectedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUncheckedUpdateWithoutEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUncheckedUpdateWithoutInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUncheckedUpdateWithoutPimsInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUncheckedUpdateWithoutProviderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  enteredById?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUncheckedUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutClinicPetsInput = {
  where: InvoiceLineItemScalarWhereInput;
  data: InvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemsInput;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutEnteredByInput = {
  where: InvoiceLineItemScalarWhereInput;
  data: InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInvoiceLineItemsInput;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutInvoiceInput = {
  where: InvoiceLineItemScalarWhereInput;
  data: InvoiceLineItemUncheckedUpdateManyWithoutLineItemsInput;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutProviderInput = {
  where: InvoiceLineItemScalarWhereInput;
  data: InvoiceLineItemUncheckedUpdateManyWithoutProviderInvoiceLineItemsInput;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutServiceInput = {
  where: InvoiceLineItemScalarWhereInput;
  data: InvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemsInput;
};

export type InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutClinicPetsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutClinicPetsInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutClinicPetsInput>>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutClinicPetsInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutClinicPetsInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUpdateManyWithoutEnteredByNestedInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutEnteredByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutEnteredByInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutEnteredByInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyEnteredByInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutEnteredByInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutEnteredByInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUpdateManyWithoutInvoiceNestedInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutInvoiceInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyInvoiceInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutInvoiceInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUpdateManyWithoutProviderNestedInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutProviderInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutProviderInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutProviderInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyProviderInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutProviderInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutProviderInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUpdateManyWithoutServiceNestedInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUpdateOneRequiredWithoutPimsInvoiceLineItemNestedInput = {
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutPimsInvoiceLineItemInput>;
  upsert?: Maybe<InvoiceLineItemUpsertWithoutPimsInvoiceLineItemInput>;
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
  update?: Maybe<InvoiceLineItemUncheckedUpdateWithoutPimsInvoiceLineItemInput>;
};

export type InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput = {
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutAutomationRunInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutAutomationRunInput>;
  upsert?: Maybe<InvoiceLineItemUpsertWithoutAutomationRunInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
  update?: Maybe<InvoiceLineItemUncheckedUpdateWithoutAutomationRunInput>;
};

export type InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsNestedInput = {
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutConnectedCareBenefitsInput>;
  upsert?: Maybe<InvoiceLineItemUpsertWithoutConnectedCareBenefitsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
  update?: Maybe<InvoiceLineItemUncheckedUpdateWithoutConnectedCareBenefitsInput>;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutClinicPetsInput = {
  where: InvoiceLineItemWhereUniqueInput;
  data: InvoiceLineItemUncheckedUpdateWithoutClinicPetsInput;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutEnteredByInput = {
  where: InvoiceLineItemWhereUniqueInput;
  data: InvoiceLineItemUncheckedUpdateWithoutEnteredByInput;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceInput = {
  where: InvoiceLineItemWhereUniqueInput;
  data: InvoiceLineItemUncheckedUpdateWithoutInvoiceInput;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutProviderInput = {
  where: InvoiceLineItemWhereUniqueInput;
  data: InvoiceLineItemUncheckedUpdateWithoutProviderInput;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutServiceInput = {
  where: InvoiceLineItemWhereUniqueInput;
  data: InvoiceLineItemUncheckedUpdateWithoutServiceInput;
};

export type InvoiceLineItemUpdateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
};

export type InvoiceLineItemUpdateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUpdateWithoutConnectedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUpdateWithoutEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUpdateWithoutInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUpdateWithoutPimsInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUpdateWithoutProviderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutClinicPetsInput = {
  where: InvoiceLineItemWhereUniqueInput;
  update: InvoiceLineItemUncheckedUpdateWithoutClinicPetsInput;
  create: InvoiceLineItemUncheckedCreateWithoutClinicPetsInput;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutEnteredByInput = {
  where: InvoiceLineItemWhereUniqueInput;
  update: InvoiceLineItemUncheckedUpdateWithoutEnteredByInput;
  create: InvoiceLineItemUncheckedCreateWithoutEnteredByInput;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceInput = {
  where: InvoiceLineItemWhereUniqueInput;
  update: InvoiceLineItemUncheckedUpdateWithoutInvoiceInput;
  create: InvoiceLineItemUncheckedCreateWithoutInvoiceInput;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutProviderInput = {
  where: InvoiceLineItemWhereUniqueInput;
  update: InvoiceLineItemUncheckedUpdateWithoutProviderInput;
  create: InvoiceLineItemUncheckedCreateWithoutProviderInput;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutServiceInput = {
  where: InvoiceLineItemWhereUniqueInput;
  update: InvoiceLineItemUncheckedUpdateWithoutServiceInput;
  create: InvoiceLineItemUncheckedCreateWithoutServiceInput;
};

export type InvoiceLineItemUpsertWithoutAutomationRunInput = {
  update: InvoiceLineItemUncheckedUpdateWithoutAutomationRunInput;
  create: InvoiceLineItemUncheckedCreateWithoutAutomationRunInput;
};

export type InvoiceLineItemUpsertWithoutConnectedCareBenefitsInput = {
  update: InvoiceLineItemUncheckedUpdateWithoutConnectedCareBenefitsInput;
  create: InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput;
};

export type InvoiceLineItemUpsertWithoutPimsInvoiceLineItemInput = {
  update: InvoiceLineItemUncheckedUpdateWithoutPimsInvoiceLineItemInput;
  create: InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput;
};

export type InvoiceLineItemWhereInput = {
  AND?: Maybe<Array<InvoiceLineItemWhereInput>>;
  OR?: Maybe<Array<InvoiceLineItemWhereInput>>;
  NOT?: Maybe<Array<InvoiceLineItemWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  invoiceId?: Maybe<StringFilter>;
  serviceId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringFilter>;
  type?: Maybe<EnumInvoiceLineItemTypeFilter>;
  quantity?: Maybe<FloatFilter>;
  amount?: Maybe<IntFilter>;
  discount?: Maybe<IntFilter>;
  fee?: Maybe<IntFilter>;
  lineNumber?: Maybe<IntFilter>;
  isTaxable?: Maybe<BoolFilter>;
  isHidden?: Maybe<BoolFilter>;
  isVoided?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  time?: Maybe<DateTimeNullableFilter>;
  providerId?: Maybe<StringNullableFilter>;
  enteredById?: Maybe<StringNullableFilter>;
  invoice?: Maybe<InvoiceWhereInput>;
  service?: Maybe<ServiceWhereInput>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemWhereInput>;
  clinicPets?: Maybe<ClinicPetListRelationFilter>;
  provider?: Maybe<ClinicEmployeeWhereInput>;
  enteredBy?: Maybe<ClinicEmployeeWhereInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
  AutomationRun?: Maybe<AutomationRunListRelationFilter>;
};

export type InvoiceLineItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InvoiceListRelationFilter = {
  every?: Maybe<InvoiceWhereInput>;
  some?: Maybe<InvoiceWhereInput>;
  none?: Maybe<InvoiceWhereInput>;
};

export type InvoiceMaxAggregateOutputType = {
  __typename?: 'InvoiceMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type InvoiceMinAggregateOutputType = {
  __typename?: 'InvoiceMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type InvoiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InvoiceOrderByRelevanceFieldEnum {
  Id = 'id',
  Identifier = 'identifier',
  ClinicPetParentId = 'clinicPetParentId',
  SentByUserId = 'sentByUserId',
  Description = 'description'
}

export type InvoiceOrderByRelevanceInput = {
  fields: Array<InvoiceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InvoiceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  _count?: Maybe<InvoiceCountOrderByAggregateInput>;
  _avg?: Maybe<InvoiceAvgOrderByAggregateInput>;
  _max?: Maybe<InvoiceMaxOrderByAggregateInput>;
  _min?: Maybe<InvoiceMinOrderByAggregateInput>;
  _sum?: Maybe<InvoiceSumOrderByAggregateInput>;
};

export type InvoiceOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  sentBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  lineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  pimsInvoice?: Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryOrderByRelationAggregateInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaOrderByRelationAggregateInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentOrderByRelationAggregateInput>;
  _relevance?: Maybe<InvoiceOrderByRelevanceInput>;
};

export enum InvoicePimsWritebackAction {
  PaymentCollected = 'Payment_Collected',
  CareDiscountApplied = 'Care_Discount_Applied'
}

export type InvoicePimsWritebackLogEntry = {
  __typename?: 'InvoicePimsWritebackLogEntry';
  writebackLogId: Scalars['String'];
  invoiceId: Scalars['String'];
  action: InvoicePimsWritebackAction;
  writebackLog: PimsWritebackLogEntry;
  invoice: Invoice;
};

export type InvoicePimsWritebackLogEntryCountAggregateOutputType = {
  __typename?: 'InvoicePimsWritebackLogEntryCountAggregateOutputType';
  writebackLogId: Scalars['Int'];
  invoiceId: Scalars['Int'];
  action: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InvoicePimsWritebackLogEntryCountOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
};

export type InvoicePimsWritebackLogEntryCreateInput = {
  action: InvoicePimsWritebackAction;
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutInvoiceLogInput;
  invoice: InvoiceCreateNestedOneWithoutRelatedInvoiceWritebacksInput;
};

export type InvoicePimsWritebackLogEntryCreateManyInput = {
  writebackLogId: Scalars['String'];
  invoiceId: Scalars['String'];
  action: InvoicePimsWritebackAction;
};

export type InvoicePimsWritebackLogEntryCreateManyInvoiceInput = {
  writebackLogId: Scalars['String'];
  action: InvoicePimsWritebackAction;
};

export type InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope = {
  data: Array<InvoicePimsWritebackLogEntryCreateManyInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput = {
  create?: Maybe<Array<InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput>>;
  createMany?: Maybe<InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope>;
  connect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
};

export type InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput = {
  create?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  connect?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
};

export type InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
  create: InvoicePimsWritebackLogEntryUncheckedCreateWithoutInvoiceInput;
};

export type InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
  create: InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
};

export type InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput = {
  action: InvoicePimsWritebackAction;
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutInvoiceLogInput;
};

export type InvoicePimsWritebackLogEntryCreateWithoutWritebackLogInput = {
  action: InvoicePimsWritebackAction;
  invoice: InvoiceCreateNestedOneWithoutRelatedInvoiceWritebacksInput;
};

export type InvoicePimsWritebackLogEntryListRelationFilter = {
  every?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  some?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  none?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};

export type InvoicePimsWritebackLogEntryMaxAggregateOutputType = {
  __typename?: 'InvoicePimsWritebackLogEntryMaxAggregateOutputType';
  writebackLogId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryMaxOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
};

export type InvoicePimsWritebackLogEntryMinAggregateOutputType = {
  __typename?: 'InvoicePimsWritebackLogEntryMinAggregateOutputType';
  writebackLogId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryMinOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
};

export type InvoicePimsWritebackLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InvoicePimsWritebackLogEntryOrderByRelevanceFieldEnum {
  WritebackLogId = 'writebackLogId',
  InvoiceId = 'invoiceId'
}

export type InvoicePimsWritebackLogEntryOrderByRelevanceInput = {
  fields: Array<InvoicePimsWritebackLogEntryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InvoicePimsWritebackLogEntryOrderByWithAggregationInput = {
  writebackLogId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  _count?: Maybe<InvoicePimsWritebackLogEntryCountOrderByAggregateInput>;
  _max?: Maybe<InvoicePimsWritebackLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<InvoicePimsWritebackLogEntryMinOrderByAggregateInput>;
};

export type InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  writebackLogId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  writebackLog?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  invoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<InvoicePimsWritebackLogEntryOrderByRelevanceInput>;
};

export type InvoicePimsWritebackLogEntryRelationFilter = {
  is?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  isNot?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};

export enum InvoicePimsWritebackLogEntryScalarFieldEnum {
  WritebackLogId = 'writebackLogId',
  InvoiceId = 'invoiceId',
  Action = 'action'
}

export type InvoicePimsWritebackLogEntryScalarWhereInput = {
  AND?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
  writebackLogId?: Maybe<StringFilter>;
  invoiceId?: Maybe<StringFilter>;
  action?: Maybe<EnumInvoicePimsWritebackActionFilter>;
};

export type InvoicePimsWritebackLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  writebackLogId?: Maybe<StringWithAggregatesFilter>;
  invoiceId?: Maybe<StringWithAggregatesFilter>;
  action?: Maybe<EnumInvoicePimsWritebackActionWithAggregatesFilter>;
};

export type InvoicePimsWritebackLogEntryUncheckedCreateInput = {
  writebackLogId: Scalars['String'];
  invoiceId: Scalars['String'];
  action: InvoicePimsWritebackAction;
};

export type InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput = {
  create?: Maybe<Array<InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput>>;
  createMany?: Maybe<InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope>;
  connect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
};

export type InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput = {
  create?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  connect?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
};

export type InvoicePimsWritebackLogEntryUncheckedCreateWithoutInvoiceInput = {
  writebackLogId: Scalars['String'];
  action: InvoicePimsWritebackAction;
};

export type InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput = {
  invoiceId: Scalars['String'];
  action: InvoicePimsWritebackAction;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateManyInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput = {
  create?: Maybe<Array<InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput>>;
  upsert?: Maybe<Array<InvoicePimsWritebackLogEntryUpsertWithWhereUniqueWithoutInvoiceInput>>;
  createMany?: Maybe<InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope>;
  set?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<InvoicePimsWritebackLogEntryUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<InvoicePimsWritebackLogEntryUpdateManyWithWhereWithoutInvoiceInput>>;
  deleteMany?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutRelatedInvoiceWritebacksInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput = {
  create?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  upsert?: Maybe<InvoicePimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateWithoutInvoiceInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput = {
  invoiceId?: Maybe<Scalars['String']>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUpdateInput = {
  action?: Maybe<InvoicePimsWritebackAction>;
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutInvoiceLogNestedInput>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutRelatedInvoiceWritebacksNestedInput>;
};

export type InvoicePimsWritebackLogEntryUpdateManyMutationInput = {
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUpdateManyWithWhereWithoutInvoiceInput = {
  where: InvoicePimsWritebackLogEntryScalarWhereInput;
  data: InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutRelatedInvoiceWritebacksInput;
};

export type InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput = {
  create?: Maybe<Array<InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput>>;
  upsert?: Maybe<Array<InvoicePimsWritebackLogEntryUpsertWithWhereUniqueWithoutInvoiceInput>>;
  createMany?: Maybe<InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope>;
  set?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<InvoicePimsWritebackLogEntryUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<InvoicePimsWritebackLogEntryUpdateManyWithWhereWithoutInvoiceInput>>;
  deleteMany?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
};

export type InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput = {
  create?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  upsert?: Maybe<InvoicePimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
};

export type InvoicePimsWritebackLogEntryUpdateWithWhereUniqueWithoutInvoiceInput = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
  data: InvoicePimsWritebackLogEntryUncheckedUpdateWithoutInvoiceInput;
};

export type InvoicePimsWritebackLogEntryUpdateWithoutInvoiceInput = {
  action?: Maybe<InvoicePimsWritebackAction>;
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutInvoiceLogNestedInput>;
};

export type InvoicePimsWritebackLogEntryUpdateWithoutWritebackLogInput = {
  action?: Maybe<InvoicePimsWritebackAction>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutRelatedInvoiceWritebacksNestedInput>;
};

export type InvoicePimsWritebackLogEntryUpsertWithWhereUniqueWithoutInvoiceInput = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
  update: InvoicePimsWritebackLogEntryUncheckedUpdateWithoutInvoiceInput;
  create: InvoicePimsWritebackLogEntryUncheckedCreateWithoutInvoiceInput;
};

export type InvoicePimsWritebackLogEntryUpsertWithoutWritebackLogInput = {
  update: InvoicePimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput;
  create: InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
};

export type InvoicePimsWritebackLogEntryWhereInput = {
  AND?: Maybe<Array<InvoicePimsWritebackLogEntryWhereInput>>;
  OR?: Maybe<Array<InvoicePimsWritebackLogEntryWhereInput>>;
  NOT?: Maybe<Array<InvoicePimsWritebackLogEntryWhereInput>>;
  writebackLogId?: Maybe<StringFilter>;
  invoiceId?: Maybe<StringFilter>;
  action?: Maybe<EnumInvoicePimsWritebackActionFilter>;
  writebackLog?: Maybe<PimsWritebackLogEntryWhereInput>;
  invoice?: Maybe<InvoiceWhereInput>;
};

export type InvoicePimsWritebackLogEntryWhereUniqueInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  writebackLogId_invoiceId?: Maybe<InvoicePimsWritebackLogEntryWritebackLogIdInvoiceIdCompoundUniqueInput>;
};

export type InvoicePimsWritebackLogEntryWritebackLogIdInvoiceIdCompoundUniqueInput = {
  writebackLogId: Scalars['String'];
  invoiceId: Scalars['String'];
};

export type InvoiceRelationFilter = {
  is?: Maybe<InvoiceWhereInput>;
  isNot?: Maybe<InvoiceWhereInput>;
};

export enum InvoiceScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Subtotal = 'subtotal',
  Taxes = 'taxes',
  Total = 'total',
  Status = 'status',
  Date = 'date',
  OutstandingBalance = 'outstandingBalance',
  Currency = 'currency',
  Identifier = 'identifier',
  ClinicPetParentId = 'clinicPetParentId',
  SentByUserId = 'sentByUserId',
  Description = 'description'
}

export type InvoiceScalarWhereInput = {
  AND?: Maybe<Array<InvoiceScalarWhereInput>>;
  OR?: Maybe<Array<InvoiceScalarWhereInput>>;
  NOT?: Maybe<Array<InvoiceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  subtotal?: Maybe<IntFilter>;
  taxes?: Maybe<IntFilter>;
  total?: Maybe<IntFilter>;
  status?: Maybe<EnumInvoiceStatusFilter>;
  date?: Maybe<DateTimeNullableFilter>;
  outstandingBalance?: Maybe<IntFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  identifier?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  sentByUserId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
};

export type InvoiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InvoiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InvoiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InvoiceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  subtotal?: Maybe<IntWithAggregatesFilter>;
  taxes?: Maybe<IntWithAggregatesFilter>;
  total?: Maybe<IntWithAggregatesFilter>;
  status?: Maybe<EnumInvoiceStatusWithAggregatesFilter>;
  date?: Maybe<DateTimeNullableWithAggregatesFilter>;
  outstandingBalance?: Maybe<IntWithAggregatesFilter>;
  currency?: Maybe<EnumCurrencyWithAggregatesFilter>;
  identifier?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  sentByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum InvoiceStatus {
  Open = 'Open',
  Closed = 'Closed'
}

export type InvoiceSumAggregateOutputType = {
  __typename?: 'InvoiceSumAggregateOutputType';
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
};

export type InvoiceSumOrderByAggregateInput = {
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
};

export type InvoiceUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<InvoiceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<InvoiceCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceUncheckedCreateNestedManyWithoutSentByInput = {
  create?: Maybe<Array<InvoiceCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutSentByInput>>;
  createMany?: Maybe<InvoiceCreateManySentByInputEnvelope>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput = {
  create?: Maybe<Array<InvoiceCreateWithoutStripePaymentIntentsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutStripePaymentIntentsInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceUncheckedCreateWithoutCareAccountCreditIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
};

export type InvoiceUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceUncheckedCreateWithoutLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceUncheckedCreateWithoutLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceUncheckedCreateWithoutPimsInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceUncheckedCreateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceUncheckedCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<InvoiceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<InvoiceCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
};

export type InvoiceUncheckedUpdateManyWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceUncheckedUpdateManyWithoutSentByNestedInput = {
  create?: Maybe<Array<InvoiceCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutSentByInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutSentByInput>>;
  createMany?: Maybe<InvoiceCreateManySentByInputEnvelope>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutSentByInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutSentByInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
};

export type InvoiceUncheckedUpdateManyWithoutSentInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput = {
  create?: Maybe<Array<InvoiceCreateWithoutStripePaymentIntentsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutStripePaymentIntentsInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutStripePaymentIntentsInput>>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutStripePaymentIntentsInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutStripePaymentIntentsInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
};

export type InvoiceUncheckedUpdateWithoutAssociatedCareBenefitCreditsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUncheckedUpdateWithoutCareAccountCreditIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
};

export type InvoiceUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUncheckedUpdateWithoutLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUncheckedUpdateWithoutLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUncheckedUpdateWithoutPimsInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUncheckedUpdateWithoutRelatedInvoiceWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUncheckedUpdateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUncheckedUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: InvoiceScalarWhereInput;
  data: InvoiceUncheckedUpdateManyWithoutInvoicesInput;
};

export type InvoiceUpdateManyWithWhereWithoutSentByInput = {
  where: InvoiceScalarWhereInput;
  data: InvoiceUncheckedUpdateManyWithoutSentInvoicesInput;
};

export type InvoiceUpdateManyWithWhereWithoutStripePaymentIntentsInput = {
  where: InvoiceScalarWhereInput;
  data: InvoiceUncheckedUpdateManyWithoutInvoicesInput;
};

export type InvoiceUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<InvoiceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<InvoiceCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
};

export type InvoiceUpdateManyWithoutSentByNestedInput = {
  create?: Maybe<Array<InvoiceCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutSentByInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutSentByInput>>;
  createMany?: Maybe<InvoiceCreateManySentByInputEnvelope>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutSentByInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutSentByInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
};

export type InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput = {
  create?: Maybe<Array<InvoiceCreateWithoutStripePaymentIntentsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutStripePaymentIntentsInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutStripePaymentIntentsInput>>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutStripePaymentIntentsInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutStripePaymentIntentsInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
};

export type InvoiceUpdateOneRequiredWithoutLineItemsNestedInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutLineItemsInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutLineItemsInput>;
  upsert?: Maybe<InvoiceUpsertWithoutLineItemsInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutLineItemsInput>;
};

export type InvoiceUpdateOneRequiredWithoutPimsInvoiceNestedInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutPimsInvoiceInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutPimsInvoiceInput>;
  upsert?: Maybe<InvoiceUpsertWithoutPimsInvoiceInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutPimsInvoiceInput>;
};

export type InvoiceUpdateOneRequiredWithoutRelatedInvoiceWritebacksNestedInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutRelatedInvoiceWritebacksInput>;
  upsert?: Maybe<InvoiceUpsertWithoutRelatedInvoiceWritebacksInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutRelatedInvoiceWritebacksInput>;
};

export type InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsNestedInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutAssociatedCareBenefitCreditsInput>;
  upsert?: Maybe<InvoiceUpsertWithoutAssociatedCareBenefitCreditsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutAssociatedCareBenefitCreditsInput>;
};

export type InvoiceUpdateOneWithoutCareAccountCreditIntentsNestedInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutCareAccountCreditIntentsInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutCareAccountCreditIntentsInput>;
  upsert?: Maybe<InvoiceUpsertWithoutCareAccountCreditIntentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutCareAccountCreditIntentsInput>;
};

export type InvoiceUpdateOneWithoutLoyaltyPointDeltasNestedInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutLoyaltyPointDeltasInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutLoyaltyPointDeltasInput>;
  upsert?: Maybe<InvoiceUpsertWithoutLoyaltyPointDeltasInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutLoyaltyPointDeltasInput>;
};

export type InvoiceUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: InvoiceWhereUniqueInput;
  data: InvoiceUncheckedUpdateWithoutClinicPetParentInput;
};

export type InvoiceUpdateWithWhereUniqueWithoutSentByInput = {
  where: InvoiceWhereUniqueInput;
  data: InvoiceUncheckedUpdateWithoutSentByInput;
};

export type InvoiceUpdateWithWhereUniqueWithoutStripePaymentIntentsInput = {
  where: InvoiceWhereUniqueInput;
  data: InvoiceUncheckedUpdateWithoutStripePaymentIntentsInput;
};

export type InvoiceUpdateWithoutAssociatedCareBenefitCreditsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUpdateWithoutCareAccountCreditIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
};

export type InvoiceUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUpdateWithoutLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUpdateWithoutLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUpdateWithoutPimsInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUpdateWithoutRelatedInvoiceWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUpdateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
};

export type InvoiceUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: InvoiceWhereUniqueInput;
  update: InvoiceUncheckedUpdateWithoutClinicPetParentInput;
  create: InvoiceUncheckedCreateWithoutClinicPetParentInput;
};

export type InvoiceUpsertWithWhereUniqueWithoutSentByInput = {
  where: InvoiceWhereUniqueInput;
  update: InvoiceUncheckedUpdateWithoutSentByInput;
  create: InvoiceUncheckedCreateWithoutSentByInput;
};

export type InvoiceUpsertWithWhereUniqueWithoutStripePaymentIntentsInput = {
  where: InvoiceWhereUniqueInput;
  update: InvoiceUncheckedUpdateWithoutStripePaymentIntentsInput;
  create: InvoiceUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type InvoiceUpsertWithoutAssociatedCareBenefitCreditsInput = {
  update: InvoiceUncheckedUpdateWithoutAssociatedCareBenefitCreditsInput;
  create: InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput;
};

export type InvoiceUpsertWithoutCareAccountCreditIntentsInput = {
  update: InvoiceUncheckedUpdateWithoutCareAccountCreditIntentsInput;
  create: InvoiceUncheckedCreateWithoutCareAccountCreditIntentsInput;
};

export type InvoiceUpsertWithoutLineItemsInput = {
  update: InvoiceUncheckedUpdateWithoutLineItemsInput;
  create: InvoiceUncheckedCreateWithoutLineItemsInput;
};

export type InvoiceUpsertWithoutLoyaltyPointDeltasInput = {
  update: InvoiceUncheckedUpdateWithoutLoyaltyPointDeltasInput;
  create: InvoiceUncheckedCreateWithoutLoyaltyPointDeltasInput;
};

export type InvoiceUpsertWithoutPimsInvoiceInput = {
  update: InvoiceUncheckedUpdateWithoutPimsInvoiceInput;
  create: InvoiceUncheckedCreateWithoutPimsInvoiceInput;
};

export type InvoiceUpsertWithoutRelatedInvoiceWritebacksInput = {
  update: InvoiceUncheckedUpdateWithoutRelatedInvoiceWritebacksInput;
  create: InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput;
};

export type InvoiceWhereInput = {
  AND?: Maybe<Array<InvoiceWhereInput>>;
  OR?: Maybe<Array<InvoiceWhereInput>>;
  NOT?: Maybe<Array<InvoiceWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  subtotal?: Maybe<IntFilter>;
  taxes?: Maybe<IntFilter>;
  total?: Maybe<IntFilter>;
  status?: Maybe<EnumInvoiceStatusFilter>;
  date?: Maybe<DateTimeNullableFilter>;
  outstandingBalance?: Maybe<IntFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  identifier?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  sentByUserId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  sentBy?: Maybe<UserWhereInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  lineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  pimsInvoice?: Maybe<PimsInvoiceWhereInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryListRelationFilter>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaListRelationFilter>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentListRelationFilter>;
};

export type InvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InvokeAiConverseContent = {
  text: Scalars['String'];
};

export type InvokeAiConverseInput = {
  messages: Array<InvokeAiConverseMessage>;
  systemPrompt?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
};

export type InvokeAiConverseMessage = {
  role: AiConverseRole;
  content: Array<InvokeAiConverseContent>;
};

export type InvokeAiConverseOutput = {
  __typename?: 'InvokeAiConverseOutput';
  message: Scalars['String'];
  role: AiConverseRole;
};

export type ItemInfo = {
  usedValue: Scalars['Float'];
  savings?: Maybe<Scalars['Float']>;
  discountedValue?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
};

export type JobChain = {
  __typename?: 'JobChain';
  name: Scalars['String'];
  id?: Maybe<Scalars['String']>;
};

export type JobChainStatus = {
  __typename?: 'JobChainStatus';
  error?: Maybe<Scalars['Json']>;
  payload?: Maybe<Array<Maybe<Scalars['Json']>>>;
  jobChainId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  erroredAt?: Maybe<Scalars['DateTime']>;
  queuedAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
};

export type JobChainStatusInput = {
  jobChainId: Scalars['String'];
};

export type JobChainSubscritptionPayload = {
  __typename?: 'JobChainSubscritptionPayload';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['Json']>;
  payload?: Maybe<Array<Maybe<Scalars['Json']>>>;
  jobChainId?: Maybe<Scalars['String']>;
};

export type JobChainSubscritptionWhereInput = {
  id: StringFilter;
};


export type JsonFilter = {
  equals?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  array_contains?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
};

export enum JsonNullValueFilter {
  DbNull = 'DbNull',
  JsonNull = 'JsonNull',
  AnyNull = 'AnyNull'
}

export enum JsonNullValueInput {
  JsonNull = 'JsonNull'
}

export type JsonNullableFilter = {
  equals?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  array_contains?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
};

export type JsonNullableListFilter = {
  equals?: Maybe<Array<Scalars['Json']>>;
  has?: Maybe<Scalars['Json']>;
  hasEvery?: Maybe<Array<Scalars['Json']>>;
  hasSome?: Maybe<Array<Scalars['Json']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type JsonNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  array_contains?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedJsonNullableFilter>;
  _max?: Maybe<NestedJsonNullableFilter>;
};

export type JsonWithAggregatesFilter = {
  equals?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  array_contains?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedJsonFilter>;
  _max?: Maybe<NestedJsonFilter>;
};

export type LapsedPetParentTriggers = {
  __typename?: 'LapsedPetParentTriggers';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  automationId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  triggerAt: Scalars['DateTime'];
  isQueued: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  automation: WorkflowEventSetting;
  clinicPetParent: ClinicPetParent;
};

export type LapsedPetParentTriggersAutomationIdClinicPetParentIdCompoundUniqueInput = {
  automationId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type LapsedPetParentTriggersCountAggregateOutputType = {
  __typename?: 'LapsedPetParentTriggersCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  automationId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  triggerAt: Scalars['Int'];
  isQueued: Scalars['Int'];
  isDeleted: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LapsedPetParentTriggersCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  isQueued?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type LapsedPetParentTriggersCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt: Scalars['DateTime'];
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  automation: WorkflowEventSettingCreateNestedOneWithoutLapsedPetParentTriggersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutLapsedPetParentTriggersInput;
};

export type LapsedPetParentTriggersCreateManyAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  triggerAt: Scalars['DateTime'];
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersCreateManyAutomationInputEnvelope = {
  data: Array<LapsedPetParentTriggersCreateManyAutomationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationId: Scalars['String'];
  triggerAt: Scalars['DateTime'];
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersCreateManyClinicPetParentInputEnvelope = {
  data: Array<LapsedPetParentTriggersCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  triggerAt: Scalars['DateTime'];
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput = {
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutAutomationInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutAutomationInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyAutomationInputEnvelope>;
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
};

export type LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
};

export type LapsedPetParentTriggersCreateOrConnectWithoutAutomationInput = {
  where: LapsedPetParentTriggersWhereUniqueInput;
  create: LapsedPetParentTriggersUncheckedCreateWithoutAutomationInput;
};

export type LapsedPetParentTriggersCreateOrConnectWithoutClinicPetParentInput = {
  where: LapsedPetParentTriggersWhereUniqueInput;
  create: LapsedPetParentTriggersUncheckedCreateWithoutClinicPetParentInput;
};

export type LapsedPetParentTriggersCreateWithoutAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt: Scalars['DateTime'];
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutLapsedPetParentTriggersInput;
};

export type LapsedPetParentTriggersCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt: Scalars['DateTime'];
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  automation: WorkflowEventSettingCreateNestedOneWithoutLapsedPetParentTriggersInput;
};

export type LapsedPetParentTriggersListRelationFilter = {
  every?: Maybe<LapsedPetParentTriggersWhereInput>;
  some?: Maybe<LapsedPetParentTriggersWhereInput>;
  none?: Maybe<LapsedPetParentTriggersWhereInput>;
};

export type LapsedPetParentTriggersMaxAggregateOutputType = {
  __typename?: 'LapsedPetParentTriggersMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  isQueued?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type LapsedPetParentTriggersMinAggregateOutputType = {
  __typename?: 'LapsedPetParentTriggersMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  isQueued?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type LapsedPetParentTriggersOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LapsedPetParentTriggersOrderByRelevanceFieldEnum {
  Id = 'id',
  AutomationId = 'automationId',
  ClinicPetParentId = 'clinicPetParentId'
}

export type LapsedPetParentTriggersOrderByRelevanceInput = {
  fields: Array<LapsedPetParentTriggersOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LapsedPetParentTriggersOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  isQueued?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _count?: Maybe<LapsedPetParentTriggersCountOrderByAggregateInput>;
  _max?: Maybe<LapsedPetParentTriggersMaxOrderByAggregateInput>;
  _min?: Maybe<LapsedPetParentTriggersMinOrderByAggregateInput>;
};

export type LapsedPetParentTriggersOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  isQueued?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  automation?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<LapsedPetParentTriggersOrderByRelevanceInput>;
};

export enum LapsedPetParentTriggersScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  AutomationId = 'automationId',
  ClinicPetParentId = 'clinicPetParentId',
  TriggerAt = 'triggerAt',
  IsQueued = 'isQueued',
  IsDeleted = 'isDeleted'
}

export type LapsedPetParentTriggersScalarWhereInput = {
  AND?: Maybe<Array<LapsedPetParentTriggersScalarWhereInput>>;
  OR?: Maybe<Array<LapsedPetParentTriggersScalarWhereInput>>;
  NOT?: Maybe<Array<LapsedPetParentTriggersScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  automationId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  triggerAt?: Maybe<DateTimeFilter>;
  isQueued?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type LapsedPetParentTriggersScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LapsedPetParentTriggersScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LapsedPetParentTriggersScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LapsedPetParentTriggersScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  automationId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  triggerAt?: Maybe<DateTimeWithAggregatesFilter>;
  isQueued?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
};

export type LapsedPetParentTriggersUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  triggerAt: Scalars['DateTime'];
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput = {
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutAutomationInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutAutomationInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyAutomationInputEnvelope>;
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
};

export type LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
};

export type LapsedPetParentTriggersUncheckedCreateWithoutAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  triggerAt: Scalars['DateTime'];
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationId: Scalars['String'];
  triggerAt: Scalars['DateTime'];
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput = {
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutAutomationInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutAutomationInput>>;
  upsert?: Maybe<Array<LapsedPetParentTriggersUpsertWithWhereUniqueWithoutAutomationInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyAutomationInputEnvelope>;
  set?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  disconnect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  delete?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  update?: Maybe<Array<LapsedPetParentTriggersUpdateWithWhereUniqueWithoutAutomationInput>>;
  updateMany?: Maybe<Array<LapsedPetParentTriggersUpdateManyWithWhereWithoutAutomationInput>>;
  deleteMany?: Maybe<Array<LapsedPetParentTriggersScalarWhereInput>>;
};

export type LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<LapsedPetParentTriggersUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  disconnect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  delete?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  update?: Maybe<Array<LapsedPetParentTriggersUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<LapsedPetParentTriggersUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<LapsedPetParentTriggersScalarWhereInput>>;
};

export type LapsedPetParentTriggersUncheckedUpdateManyWithoutLapsedPetParentTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersUncheckedUpdateWithoutAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  automation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutLapsedPetParentTriggersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutLapsedPetParentTriggersNestedInput>;
};

export type LapsedPetParentTriggersUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersUpdateManyWithWhereWithoutAutomationInput = {
  where: LapsedPetParentTriggersScalarWhereInput;
  data: LapsedPetParentTriggersUncheckedUpdateManyWithoutLapsedPetParentTriggersInput;
};

export type LapsedPetParentTriggersUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: LapsedPetParentTriggersScalarWhereInput;
  data: LapsedPetParentTriggersUncheckedUpdateManyWithoutLapsedPetParentTriggersInput;
};

export type LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput = {
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutAutomationInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutAutomationInput>>;
  upsert?: Maybe<Array<LapsedPetParentTriggersUpsertWithWhereUniqueWithoutAutomationInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyAutomationInputEnvelope>;
  set?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  disconnect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  delete?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  update?: Maybe<Array<LapsedPetParentTriggersUpdateWithWhereUniqueWithoutAutomationInput>>;
  updateMany?: Maybe<Array<LapsedPetParentTriggersUpdateManyWithWhereWithoutAutomationInput>>;
  deleteMany?: Maybe<Array<LapsedPetParentTriggersScalarWhereInput>>;
};

export type LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<LapsedPetParentTriggersUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  disconnect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  delete?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  update?: Maybe<Array<LapsedPetParentTriggersUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<LapsedPetParentTriggersUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<LapsedPetParentTriggersScalarWhereInput>>;
};

export type LapsedPetParentTriggersUpdateWithWhereUniqueWithoutAutomationInput = {
  where: LapsedPetParentTriggersWhereUniqueInput;
  data: LapsedPetParentTriggersUncheckedUpdateWithoutAutomationInput;
};

export type LapsedPetParentTriggersUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: LapsedPetParentTriggersWhereUniqueInput;
  data: LapsedPetParentTriggersUncheckedUpdateWithoutClinicPetParentInput;
};

export type LapsedPetParentTriggersUpdateWithoutAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutLapsedPetParentTriggersNestedInput>;
};

export type LapsedPetParentTriggersUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  automation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutLapsedPetParentTriggersNestedInput>;
};

export type LapsedPetParentTriggersUpsertWithWhereUniqueWithoutAutomationInput = {
  where: LapsedPetParentTriggersWhereUniqueInput;
  update: LapsedPetParentTriggersUncheckedUpdateWithoutAutomationInput;
  create: LapsedPetParentTriggersUncheckedCreateWithoutAutomationInput;
};

export type LapsedPetParentTriggersUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: LapsedPetParentTriggersWhereUniqueInput;
  update: LapsedPetParentTriggersUncheckedUpdateWithoutClinicPetParentInput;
  create: LapsedPetParentTriggersUncheckedCreateWithoutClinicPetParentInput;
};

export type LapsedPetParentTriggersWhereInput = {
  AND?: Maybe<Array<LapsedPetParentTriggersWhereInput>>;
  OR?: Maybe<Array<LapsedPetParentTriggersWhereInput>>;
  NOT?: Maybe<Array<LapsedPetParentTriggersWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  automationId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  triggerAt?: Maybe<DateTimeFilter>;
  isQueued?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  automation?: Maybe<WorkflowEventSettingWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
};

export type LapsedPetParentTriggersWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  automationId_clinicPetParentId?: Maybe<LapsedPetParentTriggersAutomationIdClinicPetParentIdCompoundUniqueInput>;
};

export type LegalEntity = {
  __typename?: 'LegalEntity';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: LegalEntityType;
  ein: Scalars['String'];
  name: Scalars['String'];
  clinics: Array<Clinic>;
  _count: LegalEntityCountOutputType;
};


export type LegalEntityClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};

export type LegalEntityCountAggregateOutputType = {
  __typename?: 'LegalEntityCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  type: Scalars['Int'];
  ein: Scalars['Int'];
  name: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LegalEntityCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  ein?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type LegalEntityCountOutputType = {
  __typename?: 'LegalEntityCountOutputType';
  clinics: Scalars['Int'];
};

export type LegalEntityCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: LegalEntityType;
  ein: Scalars['String'];
  name: Scalars['String'];
  clinics?: Maybe<ClinicCreateNestedManyWithoutLegalEntityInput>;
};

export type LegalEntityCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: LegalEntityType;
  ein: Scalars['String'];
  name: Scalars['String'];
};

export type LegalEntityCreateNestedOneWithoutClinicsInput = {
  create?: Maybe<LegalEntityUncheckedCreateWithoutClinicsInput>;
  connectOrCreate?: Maybe<LegalEntityCreateOrConnectWithoutClinicsInput>;
  connect?: Maybe<LegalEntityWhereUniqueInput>;
};

export type LegalEntityCreateOrConnectWithoutClinicsInput = {
  where: LegalEntityWhereUniqueInput;
  create: LegalEntityUncheckedCreateWithoutClinicsInput;
};

export type LegalEntityCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: LegalEntityType;
  ein: Scalars['String'];
  name: Scalars['String'];
};

export type LegalEntityMaxAggregateOutputType = {
  __typename?: 'LegalEntityMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<LegalEntityType>;
  ein?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LegalEntityMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  ein?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type LegalEntityMinAggregateOutputType = {
  __typename?: 'LegalEntityMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<LegalEntityType>;
  ein?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LegalEntityMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  ein?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export enum LegalEntityOrderByRelevanceFieldEnum {
  Id = 'id',
  Ein = 'ein',
  Name = 'name'
}

export type LegalEntityOrderByRelevanceInput = {
  fields: Array<LegalEntityOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LegalEntityOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  ein?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  _count?: Maybe<LegalEntityCountOrderByAggregateInput>;
  _max?: Maybe<LegalEntityMaxOrderByAggregateInput>;
  _min?: Maybe<LegalEntityMinOrderByAggregateInput>;
};

export type LegalEntityOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  ein?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  _relevance?: Maybe<LegalEntityOrderByRelevanceInput>;
};

export type LegalEntityRelationFilter = {
  is?: Maybe<LegalEntityWhereInput>;
  isNot?: Maybe<LegalEntityWhereInput>;
};

export enum LegalEntityScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Type = 'type',
  Ein = 'ein',
  Name = 'name'
}

export type LegalEntityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LegalEntityScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LegalEntityScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LegalEntityScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  type?: Maybe<EnumLegalEntityTypeWithAggregatesFilter>;
  ein?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
};

export enum LegalEntityType {
  SingleMemberLlc = 'SINGLE_MEMBER_LLC',
  MultiMemberLlc = 'MULTI_MEMBER_LLC',
  PrivateCorporation = 'PRIVATE_CORPORATION',
  PrivatePartnership = 'PRIVATE_PARTNERSHIP',
  PublicCorporation = 'PUBLIC_CORPORATION',
  PublicPartnership = 'PUBLIC_PARTNERSHIP',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
  UnincorporatedAssociation = 'UNINCORPORATED_ASSOCIATION',
  IncorporatedNonProfit = 'INCORPORATED_NON_PROFIT',
  UnincorporatedNonProfit = 'UNINCORPORATED_NON_PROFIT',
  GovernmentInstrumentality = 'GOVERNMENT_INSTRUMENTALITY',
  GovernmentalUnit = 'GOVERNMENTAL_UNIT',
  TaxExemptGovernmentInstrumentality = 'TAX_EXEMPT_GOVERNMENT_INSTRUMENTALITY'
}

export type LegalEntityUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: LegalEntityType;
  ein: Scalars['String'];
  name: Scalars['String'];
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutLegalEntityInput>;
};

export type LegalEntityUncheckedCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: LegalEntityType;
  ein: Scalars['String'];
  name: Scalars['String'];
};

export type LegalEntityUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<LegalEntityType>;
  ein?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutLegalEntityNestedInput>;
};

export type LegalEntityUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<LegalEntityType>;
  ein?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LegalEntityUncheckedUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<LegalEntityType>;
  ein?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LegalEntityUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<LegalEntityType>;
  ein?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUpdateManyWithoutLegalEntityNestedInput>;
};

export type LegalEntityUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<LegalEntityType>;
  ein?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LegalEntityUpdateOneWithoutClinicsNestedInput = {
  create?: Maybe<LegalEntityUncheckedCreateWithoutClinicsInput>;
  connectOrCreate?: Maybe<LegalEntityCreateOrConnectWithoutClinicsInput>;
  upsert?: Maybe<LegalEntityUpsertWithoutClinicsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<LegalEntityWhereUniqueInput>;
  update?: Maybe<LegalEntityUncheckedUpdateWithoutClinicsInput>;
};

export type LegalEntityUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<LegalEntityType>;
  ein?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LegalEntityUpsertWithoutClinicsInput = {
  update: LegalEntityUncheckedUpdateWithoutClinicsInput;
  create: LegalEntityUncheckedCreateWithoutClinicsInput;
};

export type LegalEntityWhereInput = {
  AND?: Maybe<Array<LegalEntityWhereInput>>;
  OR?: Maybe<Array<LegalEntityWhereInput>>;
  NOT?: Maybe<Array<LegalEntityWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumLegalEntityTypeFilter>;
  ein?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
};

export type LegalEntityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoginLinkOutput = {
  __typename?: 'LoginLinkOutput';
  url: Scalars['String'];
};

export type LoyaltyAccount = {
  __typename?: 'LoyaltyAccount';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive: Scalars['Boolean'];
  deactivationDate?: Maybe<Scalars['DateTime']>;
  /** how many points an account has */
  pointBalance: Scalars['Int'];
  /** leftover balance from running total point systems */
  leftOverSpend: Scalars['Int'];
  programId: Scalars['String'];
  program: LoyaltyProgram;
  accountHolders: Array<LoyaltyAccountHolder>;
  pointDeltas: Array<LoyaltyPointDelta>;
  rewardRedemptions: Array<LoyaltyRewardRedemption>;
  mergesTaken: Array<LoyaltyAccountMerger>;
  mergeSent?: Maybe<LoyaltyAccountMerger>;
  _count: LoyaltyAccountCountOutputType;
};


export type LoyaltyAccountAccountHoldersArgs = {
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
  orderBy?: Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyAccountHolderScalarFieldEnum>;
};


export type LoyaltyAccountPointDeltasArgs = {
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
  orderBy?: Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyPointDeltaScalarFieldEnum>;
};


export type LoyaltyAccountRewardRedemptionsArgs = {
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
  orderBy?: Maybe<LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyRewardRedemptionScalarFieldEnum>;
};


export type LoyaltyAccountMergesTakenArgs = {
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
  orderBy?: Maybe<LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyAccountMergerScalarFieldEnum>;
};

export type LoyaltyAccountAvgAggregateOutputType = {
  __typename?: 'LoyaltyAccountAvgAggregateOutputType';
  pointBalance?: Maybe<Scalars['Float']>;
  leftOverSpend?: Maybe<Scalars['Float']>;
};

export type LoyaltyAccountAvgOrderByAggregateInput = {
  pointBalance?: Maybe<SortOrder>;
  leftOverSpend?: Maybe<SortOrder>;
};

export type LoyaltyAccountCountAggregateOutputType = {
  __typename?: 'LoyaltyAccountCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  migratedAt: Scalars['Int'];
  isActive: Scalars['Int'];
  deactivationDate: Scalars['Int'];
  pointBalance: Scalars['Int'];
  leftOverSpend: Scalars['Int'];
  programId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LoyaltyAccountCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  migratedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  deactivationDate?: Maybe<SortOrder>;
  pointBalance?: Maybe<SortOrder>;
  leftOverSpend?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
};

export type LoyaltyAccountCountOutputType = {
  __typename?: 'LoyaltyAccountCountOutputType';
  accountHolders: Scalars['Int'];
  pointDeltas: Scalars['Int'];
  rewardRedemptions: Scalars['Int'];
  mergesTaken: Scalars['Int'];
};

export type LoyaltyAccountCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  program: LoyaltyProgramCreateNestedOneWithoutAccountsInput;
  accountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutAccountInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutAccountInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutMergeDataIntoAccountInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerCreateNestedOneWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId: Scalars['String'];
};

export type LoyaltyAccountCreateManyProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
};

export type LoyaltyAccountCreateManyProgramInputEnvelope = {
  data: Array<LoyaltyAccountCreateManyProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyAccountCreateNestedManyWithoutProgramInput = {
  create?: Maybe<Array<LoyaltyAccountCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountCreateOrConnectWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountCreateManyProgramInputEnvelope>;
  connect?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
};

export type LoyaltyAccountCreateNestedOneWithoutAccountHoldersInput = {
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutAccountHoldersInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutAccountHoldersInput>;
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
};

export type LoyaltyAccountCreateNestedOneWithoutMergeSentInput = {
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutMergeSentInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutMergeSentInput>;
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
};

export type LoyaltyAccountCreateNestedOneWithoutMergesTakenInput = {
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutMergesTakenInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutMergesTakenInput>;
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
};

export type LoyaltyAccountCreateNestedOneWithoutPointDeltasInput = {
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutPointDeltasInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutPointDeltasInput>;
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
};

export type LoyaltyAccountCreateNestedOneWithoutRewardRedemptionsInput = {
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutRewardRedemptionsInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutRewardRedemptionsInput>;
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
};

export type LoyaltyAccountCreateOrConnectWithoutAccountHoldersInput = {
  where: LoyaltyAccountWhereUniqueInput;
  create: LoyaltyAccountUncheckedCreateWithoutAccountHoldersInput;
};

export type LoyaltyAccountCreateOrConnectWithoutMergeSentInput = {
  where: LoyaltyAccountWhereUniqueInput;
  create: LoyaltyAccountUncheckedCreateWithoutMergeSentInput;
};

export type LoyaltyAccountCreateOrConnectWithoutMergesTakenInput = {
  where: LoyaltyAccountWhereUniqueInput;
  create: LoyaltyAccountUncheckedCreateWithoutMergesTakenInput;
};

export type LoyaltyAccountCreateOrConnectWithoutPointDeltasInput = {
  where: LoyaltyAccountWhereUniqueInput;
  create: LoyaltyAccountUncheckedCreateWithoutPointDeltasInput;
};

export type LoyaltyAccountCreateOrConnectWithoutProgramInput = {
  where: LoyaltyAccountWhereUniqueInput;
  create: LoyaltyAccountUncheckedCreateWithoutProgramInput;
};

export type LoyaltyAccountCreateOrConnectWithoutRewardRedemptionsInput = {
  where: LoyaltyAccountWhereUniqueInput;
  create: LoyaltyAccountUncheckedCreateWithoutRewardRedemptionsInput;
};

export type LoyaltyAccountCreateWithoutAccountHoldersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  program: LoyaltyProgramCreateNestedOneWithoutAccountsInput;
  pointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutAccountInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutMergeDataIntoAccountInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerCreateNestedOneWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountCreateWithoutMergeSentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  program: LoyaltyProgramCreateNestedOneWithoutAccountsInput;
  accountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutAccountInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutAccountInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutMergeDataIntoAccountInput>;
};

export type LoyaltyAccountCreateWithoutMergesTakenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  program: LoyaltyProgramCreateNestedOneWithoutAccountsInput;
  accountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutAccountInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutAccountInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutAccountInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerCreateNestedOneWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountCreateWithoutPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  program: LoyaltyProgramCreateNestedOneWithoutAccountsInput;
  accountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutAccountInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutMergeDataIntoAccountInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerCreateNestedOneWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountCreateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  accountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutAccountInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutAccountInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutMergeDataIntoAccountInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerCreateNestedOneWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountCreateWithoutRewardRedemptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  program: LoyaltyProgramCreateNestedOneWithoutAccountsInput;
  accountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutAccountInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutMergeDataIntoAccountInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerCreateNestedOneWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountHolder = {
  __typename?: 'LoyaltyAccountHolder';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  programId: Scalars['String'];
  accountId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  program: LoyaltyProgram;
  account: LoyaltyAccount;
  clinicPetParent: ClinicPetParent;
  rewardRedemptionActions: Array<LoyaltyRewardRedemptionHistoryEntry>;
  _count: LoyaltyAccountHolderCountOutputType;
};


export type LoyaltyAccountHolderRewardRedemptionActionsArgs = {
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  orderBy?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum>;
};

export type LoyaltyAccountHolderClinicPetParentIdAccountIdCompoundUniqueInput = {
  clinicPetParentId: Scalars['String'];
  accountId: Scalars['String'];
};

export type LoyaltyAccountHolderCountAggregateOutputType = {
  __typename?: 'LoyaltyAccountHolderCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  programId: Scalars['Int'];
  accountId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LoyaltyAccountHolderCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
};

export type LoyaltyAccountHolderCountOutputType = {
  __typename?: 'LoyaltyAccountHolderCountOutputType';
  rewardRedemptionActions: Scalars['Int'];
};

export type LoyaltyAccountHolderCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  program: LoyaltyProgramCreateNestedOneWithoutLoyaltyAccountHoldersInput;
  account: LoyaltyAccountCreateNestedOneWithoutAccountHoldersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutLoyaltyAccountHolderInput;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingAccountHolderInput>;
};

export type LoyaltyAccountHolderCreateManyAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type LoyaltyAccountHolderCreateManyAccountInputEnvelope = {
  data: Array<LoyaltyAccountHolderCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyAccountHolderCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId: Scalars['String'];
  accountId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type LoyaltyAccountHolderCreateManyProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type LoyaltyAccountHolderCreateManyProgramInputEnvelope = {
  data: Array<LoyaltyAccountHolderCreateManyProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyAccountHolderCreateNestedManyWithoutAccountInput = {
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyAccountInputEnvelope>;
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
};

export type LoyaltyAccountHolderCreateNestedManyWithoutProgramInput = {
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyProgramInputEnvelope>;
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
};

export type LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput = {
  create?: Maybe<LoyaltyAccountHolderUncheckedCreateWithoutClinicPetParentInput>;
  connectOrCreate?: Maybe<LoyaltyAccountHolderCreateOrConnectWithoutClinicPetParentInput>;
  connect?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
};

export type LoyaltyAccountHolderCreateNestedOneWithoutRewardRedemptionActionsInput = {
  create?: Maybe<LoyaltyAccountHolderUncheckedCreateWithoutRewardRedemptionActionsInput>;
  connectOrCreate?: Maybe<LoyaltyAccountHolderCreateOrConnectWithoutRewardRedemptionActionsInput>;
  connect?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
};

export type LoyaltyAccountHolderCreateOrConnectWithoutAccountInput = {
  where: LoyaltyAccountHolderWhereUniqueInput;
  create: LoyaltyAccountHolderUncheckedCreateWithoutAccountInput;
};

export type LoyaltyAccountHolderCreateOrConnectWithoutClinicPetParentInput = {
  where: LoyaltyAccountHolderWhereUniqueInput;
  create: LoyaltyAccountHolderUncheckedCreateWithoutClinicPetParentInput;
};

export type LoyaltyAccountHolderCreateOrConnectWithoutProgramInput = {
  where: LoyaltyAccountHolderWhereUniqueInput;
  create: LoyaltyAccountHolderUncheckedCreateWithoutProgramInput;
};

export type LoyaltyAccountHolderCreateOrConnectWithoutRewardRedemptionActionsInput = {
  where: LoyaltyAccountHolderWhereUniqueInput;
  create: LoyaltyAccountHolderUncheckedCreateWithoutRewardRedemptionActionsInput;
};

export type LoyaltyAccountHolderCreateWithoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  program: LoyaltyProgramCreateNestedOneWithoutLoyaltyAccountHoldersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutLoyaltyAccountHolderInput;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingAccountHolderInput>;
};

export type LoyaltyAccountHolderCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  program: LoyaltyProgramCreateNestedOneWithoutLoyaltyAccountHoldersInput;
  account: LoyaltyAccountCreateNestedOneWithoutAccountHoldersInput;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingAccountHolderInput>;
};

export type LoyaltyAccountHolderCreateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  account: LoyaltyAccountCreateNestedOneWithoutAccountHoldersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutLoyaltyAccountHolderInput;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingAccountHolderInput>;
};

export type LoyaltyAccountHolderCreateWithoutRewardRedemptionActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  program: LoyaltyProgramCreateNestedOneWithoutLoyaltyAccountHoldersInput;
  account: LoyaltyAccountCreateNestedOneWithoutAccountHoldersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutLoyaltyAccountHolderInput;
};

export type LoyaltyAccountHolderListRelationFilter = {
  every?: Maybe<LoyaltyAccountHolderWhereInput>;
  some?: Maybe<LoyaltyAccountHolderWhereInput>;
  none?: Maybe<LoyaltyAccountHolderWhereInput>;
};

export type LoyaltyAccountHolderMaxAggregateOutputType = {
  __typename?: 'LoyaltyAccountHolderMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountHolderMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
};

export type LoyaltyAccountHolderMinAggregateOutputType = {
  __typename?: 'LoyaltyAccountHolderMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountHolderMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
};

export type LoyaltyAccountHolderOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyAccountHolderOrderByRelevanceFieldEnum {
  Id = 'id',
  ProgramId = 'programId',
  AccountId = 'accountId',
  ClinicPetParentId = 'clinicPetParentId'
}

export type LoyaltyAccountHolderOrderByRelevanceInput = {
  fields: Array<LoyaltyAccountHolderOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LoyaltyAccountHolderOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  _count?: Maybe<LoyaltyAccountHolderCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyAccountHolderMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyAccountHolderMinOrderByAggregateInput>;
};

export type LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  program?: Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>;
  account?: Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByRelationAggregateInput>;
  _relevance?: Maybe<LoyaltyAccountHolderOrderByRelevanceInput>;
};

export type LoyaltyAccountHolderRelationFilter = {
  is?: Maybe<LoyaltyAccountHolderWhereInput>;
  isNot?: Maybe<LoyaltyAccountHolderWhereInput>;
};

export enum LoyaltyAccountHolderScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ProgramId = 'programId',
  AccountId = 'accountId',
  ClinicPetParentId = 'clinicPetParentId'
}

export type LoyaltyAccountHolderScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyAccountHolderScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyAccountHolderScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyAccountHolderScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  programId?: Maybe<StringFilter>;
  accountId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
};

export type LoyaltyAccountHolderScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyAccountHolderScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyAccountHolderScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyAccountHolderScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  programId?: Maybe<StringWithAggregatesFilter>;
  accountId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
};

export type LoyaltyAccountHolderUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId: Scalars['String'];
  accountId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingAccountHolderInput>;
};

export type LoyaltyAccountHolderUncheckedCreateNestedManyWithoutAccountInput = {
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyAccountInputEnvelope>;
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
};

export type LoyaltyAccountHolderUncheckedCreateNestedManyWithoutProgramInput = {
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyProgramInputEnvelope>;
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
};

export type LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput = {
  create?: Maybe<LoyaltyAccountHolderUncheckedCreateWithoutClinicPetParentInput>;
  connectOrCreate?: Maybe<LoyaltyAccountHolderCreateOrConnectWithoutClinicPetParentInput>;
  connect?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
};

export type LoyaltyAccountHolderUncheckedCreateWithoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingAccountHolderInput>;
};

export type LoyaltyAccountHolderUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId: Scalars['String'];
  accountId: Scalars['String'];
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingAccountHolderInput>;
};

export type LoyaltyAccountHolderUncheckedCreateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingAccountHolderInput>;
};

export type LoyaltyAccountHolderUncheckedCreateWithoutRewardRedemptionActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId: Scalars['String'];
  accountId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type LoyaltyAccountHolderUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingAccountHolderNestedInput>;
};

export type LoyaltyAccountHolderUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountHoldersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountNestedInput = {
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutAccountInput>>;
  upsert?: Maybe<Array<LoyaltyAccountHolderUpsertWithWhereUniqueWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyAccountInputEnvelope>;
  set?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountHolderUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountHolderUpdateManyWithWhereWithoutAccountInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountHolderScalarWhereInput>>;
};

export type LoyaltyAccountHolderUncheckedUpdateManyWithoutLoyaltyAccountHoldersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountHolderUncheckedUpdateManyWithoutProgramNestedInput = {
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyAccountHolderUpsertWithWhereUniqueWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyProgramInputEnvelope>;
  set?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountHolderUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountHolderUpdateManyWithWhereWithoutProgramInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountHolderScalarWhereInput>>;
};

export type LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput = {
  create?: Maybe<LoyaltyAccountHolderUncheckedCreateWithoutClinicPetParentInput>;
  connectOrCreate?: Maybe<LoyaltyAccountHolderCreateOrConnectWithoutClinicPetParentInput>;
  upsert?: Maybe<LoyaltyAccountHolderUpsertWithoutClinicPetParentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  update?: Maybe<LoyaltyAccountHolderUncheckedUpdateWithoutClinicPetParentInput>;
};

export type LoyaltyAccountHolderUncheckedUpdateWithoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingAccountHolderNestedInput>;
};

export type LoyaltyAccountHolderUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingAccountHolderNestedInput>;
};

export type LoyaltyAccountHolderUncheckedUpdateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingAccountHolderNestedInput>;
};

export type LoyaltyAccountHolderUncheckedUpdateWithoutRewardRedemptionActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountHolderUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutLoyaltyAccountHoldersNestedInput>;
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutAccountHoldersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutLoyaltyAccountHolderNestedInput>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingAccountHolderNestedInput>;
};

export type LoyaltyAccountHolderUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUpdateManyWithWhereWithoutAccountInput = {
  where: LoyaltyAccountHolderScalarWhereInput;
  data: LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountHoldersInput;
};

export type LoyaltyAccountHolderUpdateManyWithWhereWithoutProgramInput = {
  where: LoyaltyAccountHolderScalarWhereInput;
  data: LoyaltyAccountHolderUncheckedUpdateManyWithoutLoyaltyAccountHoldersInput;
};

export type LoyaltyAccountHolderUpdateManyWithoutAccountNestedInput = {
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutAccountInput>>;
  upsert?: Maybe<Array<LoyaltyAccountHolderUpsertWithWhereUniqueWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyAccountInputEnvelope>;
  set?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountHolderUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountHolderUpdateManyWithWhereWithoutAccountInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountHolderScalarWhereInput>>;
};

export type LoyaltyAccountHolderUpdateManyWithoutProgramNestedInput = {
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyAccountHolderUpsertWithWhereUniqueWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyProgramInputEnvelope>;
  set?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountHolderUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountHolderUpdateManyWithWhereWithoutProgramInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountHolderScalarWhereInput>>;
};

export type LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput = {
  create?: Maybe<LoyaltyAccountHolderUncheckedCreateWithoutClinicPetParentInput>;
  connectOrCreate?: Maybe<LoyaltyAccountHolderCreateOrConnectWithoutClinicPetParentInput>;
  upsert?: Maybe<LoyaltyAccountHolderUpsertWithoutClinicPetParentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  update?: Maybe<LoyaltyAccountHolderUncheckedUpdateWithoutClinicPetParentInput>;
};

export type LoyaltyAccountHolderUpdateOneWithoutRewardRedemptionActionsNestedInput = {
  create?: Maybe<LoyaltyAccountHolderUncheckedCreateWithoutRewardRedemptionActionsInput>;
  connectOrCreate?: Maybe<LoyaltyAccountHolderCreateOrConnectWithoutRewardRedemptionActionsInput>;
  upsert?: Maybe<LoyaltyAccountHolderUpsertWithoutRewardRedemptionActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  update?: Maybe<LoyaltyAccountHolderUncheckedUpdateWithoutRewardRedemptionActionsInput>;
};

export type LoyaltyAccountHolderUpdateWithWhereUniqueWithoutAccountInput = {
  where: LoyaltyAccountHolderWhereUniqueInput;
  data: LoyaltyAccountHolderUncheckedUpdateWithoutAccountInput;
};

export type LoyaltyAccountHolderUpdateWithWhereUniqueWithoutProgramInput = {
  where: LoyaltyAccountHolderWhereUniqueInput;
  data: LoyaltyAccountHolderUncheckedUpdateWithoutProgramInput;
};

export type LoyaltyAccountHolderUpdateWithoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutLoyaltyAccountHoldersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutLoyaltyAccountHolderNestedInput>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingAccountHolderNestedInput>;
};

export type LoyaltyAccountHolderUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutLoyaltyAccountHoldersNestedInput>;
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutAccountHoldersNestedInput>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingAccountHolderNestedInput>;
};

export type LoyaltyAccountHolderUpdateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutAccountHoldersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutLoyaltyAccountHolderNestedInput>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingAccountHolderNestedInput>;
};

export type LoyaltyAccountHolderUpdateWithoutRewardRedemptionActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutLoyaltyAccountHoldersNestedInput>;
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutAccountHoldersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutLoyaltyAccountHolderNestedInput>;
};

export type LoyaltyAccountHolderUpsertWithWhereUniqueWithoutAccountInput = {
  where: LoyaltyAccountHolderWhereUniqueInput;
  update: LoyaltyAccountHolderUncheckedUpdateWithoutAccountInput;
  create: LoyaltyAccountHolderUncheckedCreateWithoutAccountInput;
};

export type LoyaltyAccountHolderUpsertWithWhereUniqueWithoutProgramInput = {
  where: LoyaltyAccountHolderWhereUniqueInput;
  update: LoyaltyAccountHolderUncheckedUpdateWithoutProgramInput;
  create: LoyaltyAccountHolderUncheckedCreateWithoutProgramInput;
};

export type LoyaltyAccountHolderUpsertWithoutClinicPetParentInput = {
  update: LoyaltyAccountHolderUncheckedUpdateWithoutClinicPetParentInput;
  create: LoyaltyAccountHolderUncheckedCreateWithoutClinicPetParentInput;
};

export type LoyaltyAccountHolderUpsertWithoutRewardRedemptionActionsInput = {
  update: LoyaltyAccountHolderUncheckedUpdateWithoutRewardRedemptionActionsInput;
  create: LoyaltyAccountHolderUncheckedCreateWithoutRewardRedemptionActionsInput;
};

export type LoyaltyAccountHolderWhereInput = {
  AND?: Maybe<Array<LoyaltyAccountHolderWhereInput>>;
  OR?: Maybe<Array<LoyaltyAccountHolderWhereInput>>;
  NOT?: Maybe<Array<LoyaltyAccountHolderWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  programId?: Maybe<StringFilter>;
  accountId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  program?: Maybe<LoyaltyProgramWhereInput>;
  account?: Maybe<LoyaltyAccountWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryListRelationFilter>;
};

export type LoyaltyAccountHolderWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicPetParentId_accountId?: Maybe<LoyaltyAccountHolderClinicPetParentIdAccountIdCompoundUniqueInput>;
};

export type LoyaltyAccountListRelationFilter = {
  every?: Maybe<LoyaltyAccountWhereInput>;
  some?: Maybe<LoyaltyAccountWhereInput>;
  none?: Maybe<LoyaltyAccountWhereInput>;
};

export type LoyaltyAccountMaxAggregateOutputType = {
  __typename?: 'LoyaltyAccountMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  migratedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  deactivationDate?: Maybe<SortOrder>;
  pointBalance?: Maybe<SortOrder>;
  leftOverSpend?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
};

export type LoyaltyAccountMerger = {
  __typename?: 'LoyaltyAccountMerger';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyAccountMergerStatus;
  errorMessage?: Maybe<Scalars['String']>;
  mergerInitiatorId: Scalars['String'];
  mergeDataIntoAccountId: Scalars['String'];
  mergeDataFromAccountId: Scalars['String'];
  initiatedBy: User;
  mergeDataIntoAccount: LoyaltyAccount;
  mergeDataFromAccount: LoyaltyAccount;
};

export type LoyaltyAccountMergerCountAggregateOutputType = {
  __typename?: 'LoyaltyAccountMergerCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  completedAt: Scalars['Int'];
  status: Scalars['Int'];
  errorMessage: Scalars['Int'];
  mergerInitiatorId: Scalars['Int'];
  mergeDataIntoAccountId: Scalars['Int'];
  mergeDataFromAccountId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LoyaltyAccountMergerCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  errorMessage?: Maybe<SortOrder>;
  mergerInitiatorId?: Maybe<SortOrder>;
  mergeDataIntoAccountId?: Maybe<SortOrder>;
  mergeDataFromAccountId?: Maybe<SortOrder>;
};

export type LoyaltyAccountMergerCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  initiatedBy: UserCreateNestedOneWithoutIntitiatedLoyaltyAccountMergesInput;
  mergeDataIntoAccount: LoyaltyAccountCreateNestedOneWithoutMergesTakenInput;
  mergeDataFromAccount: LoyaltyAccountCreateNestedOneWithoutMergeSentInput;
};

export type LoyaltyAccountMergerCreateManyInitiatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId: Scalars['String'];
  mergeDataFromAccountId: Scalars['String'];
};

export type LoyaltyAccountMergerCreateManyInitiatedByInputEnvelope = {
  data: Array<LoyaltyAccountMergerCreateManyInitiatedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyAccountMergerCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergerInitiatorId: Scalars['String'];
  mergeDataIntoAccountId: Scalars['String'];
  mergeDataFromAccountId: Scalars['String'];
};

export type LoyaltyAccountMergerCreateManyMergeDataIntoAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergerInitiatorId: Scalars['String'];
  mergeDataFromAccountId: Scalars['String'];
};

export type LoyaltyAccountMergerCreateManyMergeDataIntoAccountInputEnvelope = {
  data: Array<LoyaltyAccountMergerCreateManyMergeDataIntoAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput = {
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutInitiatedByInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutInitiatedByInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyInitiatedByInputEnvelope>;
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
};

export type LoyaltyAccountMergerCreateNestedManyWithoutMergeDataIntoAccountInput = {
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutMergeDataIntoAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataIntoAccountInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyMergeDataIntoAccountInputEnvelope>;
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
};

export type LoyaltyAccountMergerCreateNestedOneWithoutMergeDataFromAccountInput = {
  create?: Maybe<LoyaltyAccountMergerUncheckedCreateWithoutMergeDataFromAccountInput>;
  connectOrCreate?: Maybe<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataFromAccountInput>;
  connect?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
};

export type LoyaltyAccountMergerCreateOrConnectWithoutInitiatedByInput = {
  where: LoyaltyAccountMergerWhereUniqueInput;
  create: LoyaltyAccountMergerUncheckedCreateWithoutInitiatedByInput;
};

export type LoyaltyAccountMergerCreateOrConnectWithoutMergeDataFromAccountInput = {
  where: LoyaltyAccountMergerWhereUniqueInput;
  create: LoyaltyAccountMergerUncheckedCreateWithoutMergeDataFromAccountInput;
};

export type LoyaltyAccountMergerCreateOrConnectWithoutMergeDataIntoAccountInput = {
  where: LoyaltyAccountMergerWhereUniqueInput;
  create: LoyaltyAccountMergerUncheckedCreateWithoutMergeDataIntoAccountInput;
};

export type LoyaltyAccountMergerCreateWithoutInitiatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergeDataIntoAccount: LoyaltyAccountCreateNestedOneWithoutMergesTakenInput;
  mergeDataFromAccount: LoyaltyAccountCreateNestedOneWithoutMergeSentInput;
};

export type LoyaltyAccountMergerCreateWithoutMergeDataFromAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  initiatedBy: UserCreateNestedOneWithoutIntitiatedLoyaltyAccountMergesInput;
  mergeDataIntoAccount: LoyaltyAccountCreateNestedOneWithoutMergesTakenInput;
};

export type LoyaltyAccountMergerCreateWithoutMergeDataIntoAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  initiatedBy: UserCreateNestedOneWithoutIntitiatedLoyaltyAccountMergesInput;
  mergeDataFromAccount: LoyaltyAccountCreateNestedOneWithoutMergeSentInput;
};

export type LoyaltyAccountMergerListRelationFilter = {
  every?: Maybe<LoyaltyAccountMergerWhereInput>;
  some?: Maybe<LoyaltyAccountMergerWhereInput>;
  none?: Maybe<LoyaltyAccountMergerWhereInput>;
};

export type LoyaltyAccountMergerMaxAggregateOutputType = {
  __typename?: 'LoyaltyAccountMergerMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergerInitiatorId?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountMergerMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  errorMessage?: Maybe<SortOrder>;
  mergerInitiatorId?: Maybe<SortOrder>;
  mergeDataIntoAccountId?: Maybe<SortOrder>;
  mergeDataFromAccountId?: Maybe<SortOrder>;
};

export type LoyaltyAccountMergerMinAggregateOutputType = {
  __typename?: 'LoyaltyAccountMergerMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergerInitiatorId?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountMergerMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  errorMessage?: Maybe<SortOrder>;
  mergerInitiatorId?: Maybe<SortOrder>;
  mergeDataIntoAccountId?: Maybe<SortOrder>;
  mergeDataFromAccountId?: Maybe<SortOrder>;
};

export type LoyaltyAccountMergerOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyAccountMergerOrderByRelevanceFieldEnum {
  Id = 'id',
  ErrorMessage = 'errorMessage',
  MergerInitiatorId = 'mergerInitiatorId',
  MergeDataIntoAccountId = 'mergeDataIntoAccountId',
  MergeDataFromAccountId = 'mergeDataFromAccountId'
}

export type LoyaltyAccountMergerOrderByRelevanceInput = {
  fields: Array<LoyaltyAccountMergerOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LoyaltyAccountMergerOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  errorMessage?: Maybe<SortOrder>;
  mergerInitiatorId?: Maybe<SortOrder>;
  mergeDataIntoAccountId?: Maybe<SortOrder>;
  mergeDataFromAccountId?: Maybe<SortOrder>;
  _count?: Maybe<LoyaltyAccountMergerCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyAccountMergerMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyAccountMergerMinOrderByAggregateInput>;
};

export type LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  errorMessage?: Maybe<SortOrder>;
  mergerInitiatorId?: Maybe<SortOrder>;
  mergeDataIntoAccountId?: Maybe<SortOrder>;
  mergeDataFromAccountId?: Maybe<SortOrder>;
  initiatedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  mergeDataIntoAccount?: Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>;
  mergeDataFromAccount?: Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<LoyaltyAccountMergerOrderByRelevanceInput>;
};

export type LoyaltyAccountMergerRelationFilter = {
  is?: Maybe<LoyaltyAccountMergerWhereInput>;
  isNot?: Maybe<LoyaltyAccountMergerWhereInput>;
};

export enum LoyaltyAccountMergerScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  CompletedAt = 'completedAt',
  Status = 'status',
  ErrorMessage = 'errorMessage',
  MergerInitiatorId = 'mergerInitiatorId',
  MergeDataIntoAccountId = 'mergeDataIntoAccountId',
  MergeDataFromAccountId = 'mergeDataFromAccountId'
}

export type LoyaltyAccountMergerScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyAccountMergerScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyAccountMergerScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyAccountMergerScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumLoyaltyAccountMergerStatusFilter>;
  errorMessage?: Maybe<StringNullableFilter>;
  mergerInitiatorId?: Maybe<StringFilter>;
  mergeDataIntoAccountId?: Maybe<StringFilter>;
  mergeDataFromAccountId?: Maybe<StringFilter>;
};

export type LoyaltyAccountMergerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyAccountMergerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyAccountMergerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyAccountMergerScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumLoyaltyAccountMergerStatusWithAggregatesFilter>;
  errorMessage?: Maybe<StringNullableWithAggregatesFilter>;
  mergerInitiatorId?: Maybe<StringWithAggregatesFilter>;
  mergeDataIntoAccountId?: Maybe<StringWithAggregatesFilter>;
  mergeDataFromAccountId?: Maybe<StringWithAggregatesFilter>;
};

export enum LoyaltyAccountMergerStatus {
  Started = 'Started',
  Completed = 'Completed',
  Errored = 'Errored'
}

export type LoyaltyAccountMergerUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergerInitiatorId: Scalars['String'];
  mergeDataIntoAccountId: Scalars['String'];
  mergeDataFromAccountId: Scalars['String'];
};

export type LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput = {
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutInitiatedByInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutInitiatedByInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyInitiatedByInputEnvelope>;
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
};

export type LoyaltyAccountMergerUncheckedCreateNestedManyWithoutMergeDataIntoAccountInput = {
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutMergeDataIntoAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataIntoAccountInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyMergeDataIntoAccountInputEnvelope>;
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
};

export type LoyaltyAccountMergerUncheckedCreateNestedOneWithoutMergeDataFromAccountInput = {
  create?: Maybe<LoyaltyAccountMergerUncheckedCreateWithoutMergeDataFromAccountInput>;
  connectOrCreate?: Maybe<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataFromAccountInput>;
  connect?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
};

export type LoyaltyAccountMergerUncheckedCreateWithoutInitiatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId: Scalars['String'];
  mergeDataFromAccountId: Scalars['String'];
};

export type LoyaltyAccountMergerUncheckedCreateWithoutMergeDataFromAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergerInitiatorId: Scalars['String'];
  mergeDataIntoAccountId: Scalars['String'];
};

export type LoyaltyAccountMergerUncheckedCreateWithoutMergeDataIntoAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergerInitiatorId: Scalars['String'];
  mergeDataFromAccountId: Scalars['String'];
};

export type LoyaltyAccountMergerUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergerInitiatorId?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountMergerUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergerInitiatorId?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput = {
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutInitiatedByInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutInitiatedByInput>>;
  upsert?: Maybe<Array<LoyaltyAccountMergerUpsertWithWhereUniqueWithoutInitiatedByInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyInitiatedByInputEnvelope>;
  set?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountMergerUpdateWithWhereUniqueWithoutInitiatedByInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountMergerUpdateManyWithWhereWithoutInitiatedByInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountMergerScalarWhereInput>>;
};

export type LoyaltyAccountMergerUncheckedUpdateManyWithoutIntitiatedLoyaltyAccountMergesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountMergerUncheckedUpdateManyWithoutMergeDataIntoAccountNestedInput = {
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutMergeDataIntoAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataIntoAccountInput>>;
  upsert?: Maybe<Array<LoyaltyAccountMergerUpsertWithWhereUniqueWithoutMergeDataIntoAccountInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyMergeDataIntoAccountInputEnvelope>;
  set?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountMergerUpdateWithWhereUniqueWithoutMergeDataIntoAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountMergerUpdateManyWithWhereWithoutMergeDataIntoAccountInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountMergerScalarWhereInput>>;
};

export type LoyaltyAccountMergerUncheckedUpdateManyWithoutMergesTakenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergerInitiatorId?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountMergerUncheckedUpdateOneWithoutMergeDataFromAccountNestedInput = {
  create?: Maybe<LoyaltyAccountMergerUncheckedCreateWithoutMergeDataFromAccountInput>;
  connectOrCreate?: Maybe<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataFromAccountInput>;
  upsert?: Maybe<LoyaltyAccountMergerUpsertWithoutMergeDataFromAccountInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  update?: Maybe<LoyaltyAccountMergerUncheckedUpdateWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountMergerUncheckedUpdateWithoutInitiatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountMergerUncheckedUpdateWithoutMergeDataFromAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergerInitiatorId?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountMergerUncheckedUpdateWithoutMergeDataIntoAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergerInitiatorId?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountMergerUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  initiatedBy?: Maybe<UserUpdateOneRequiredWithoutIntitiatedLoyaltyAccountMergesNestedInput>;
  mergeDataIntoAccount?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutMergesTakenNestedInput>;
  mergeDataFromAccount?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutMergeSentNestedInput>;
};

export type LoyaltyAccountMergerUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountMergerUpdateManyWithWhereWithoutInitiatedByInput = {
  where: LoyaltyAccountMergerScalarWhereInput;
  data: LoyaltyAccountMergerUncheckedUpdateManyWithoutIntitiatedLoyaltyAccountMergesInput;
};

export type LoyaltyAccountMergerUpdateManyWithWhereWithoutMergeDataIntoAccountInput = {
  where: LoyaltyAccountMergerScalarWhereInput;
  data: LoyaltyAccountMergerUncheckedUpdateManyWithoutMergesTakenInput;
};

export type LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput = {
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutInitiatedByInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutInitiatedByInput>>;
  upsert?: Maybe<Array<LoyaltyAccountMergerUpsertWithWhereUniqueWithoutInitiatedByInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyInitiatedByInputEnvelope>;
  set?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountMergerUpdateWithWhereUniqueWithoutInitiatedByInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountMergerUpdateManyWithWhereWithoutInitiatedByInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountMergerScalarWhereInput>>;
};

export type LoyaltyAccountMergerUpdateManyWithoutMergeDataIntoAccountNestedInput = {
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutMergeDataIntoAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataIntoAccountInput>>;
  upsert?: Maybe<Array<LoyaltyAccountMergerUpsertWithWhereUniqueWithoutMergeDataIntoAccountInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyMergeDataIntoAccountInputEnvelope>;
  set?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountMergerUpdateWithWhereUniqueWithoutMergeDataIntoAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountMergerUpdateManyWithWhereWithoutMergeDataIntoAccountInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountMergerScalarWhereInput>>;
};

export type LoyaltyAccountMergerUpdateOneWithoutMergeDataFromAccountNestedInput = {
  create?: Maybe<LoyaltyAccountMergerUncheckedCreateWithoutMergeDataFromAccountInput>;
  connectOrCreate?: Maybe<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataFromAccountInput>;
  upsert?: Maybe<LoyaltyAccountMergerUpsertWithoutMergeDataFromAccountInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  update?: Maybe<LoyaltyAccountMergerUncheckedUpdateWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountMergerUpdateWithWhereUniqueWithoutInitiatedByInput = {
  where: LoyaltyAccountMergerWhereUniqueInput;
  data: LoyaltyAccountMergerUncheckedUpdateWithoutInitiatedByInput;
};

export type LoyaltyAccountMergerUpdateWithWhereUniqueWithoutMergeDataIntoAccountInput = {
  where: LoyaltyAccountMergerWhereUniqueInput;
  data: LoyaltyAccountMergerUncheckedUpdateWithoutMergeDataIntoAccountInput;
};

export type LoyaltyAccountMergerUpdateWithoutInitiatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  mergeDataIntoAccount?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutMergesTakenNestedInput>;
  mergeDataFromAccount?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutMergeSentNestedInput>;
};

export type LoyaltyAccountMergerUpdateWithoutMergeDataFromAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  initiatedBy?: Maybe<UserUpdateOneRequiredWithoutIntitiatedLoyaltyAccountMergesNestedInput>;
  mergeDataIntoAccount?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutMergesTakenNestedInput>;
};

export type LoyaltyAccountMergerUpdateWithoutMergeDataIntoAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  initiatedBy?: Maybe<UserUpdateOneRequiredWithoutIntitiatedLoyaltyAccountMergesNestedInput>;
  mergeDataFromAccount?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutMergeSentNestedInput>;
};

export type LoyaltyAccountMergerUpsertWithWhereUniqueWithoutInitiatedByInput = {
  where: LoyaltyAccountMergerWhereUniqueInput;
  update: LoyaltyAccountMergerUncheckedUpdateWithoutInitiatedByInput;
  create: LoyaltyAccountMergerUncheckedCreateWithoutInitiatedByInput;
};

export type LoyaltyAccountMergerUpsertWithWhereUniqueWithoutMergeDataIntoAccountInput = {
  where: LoyaltyAccountMergerWhereUniqueInput;
  update: LoyaltyAccountMergerUncheckedUpdateWithoutMergeDataIntoAccountInput;
  create: LoyaltyAccountMergerUncheckedCreateWithoutMergeDataIntoAccountInput;
};

export type LoyaltyAccountMergerUpsertWithoutMergeDataFromAccountInput = {
  update: LoyaltyAccountMergerUncheckedUpdateWithoutMergeDataFromAccountInput;
  create: LoyaltyAccountMergerUncheckedCreateWithoutMergeDataFromAccountInput;
};

export type LoyaltyAccountMergerWhereInput = {
  AND?: Maybe<Array<LoyaltyAccountMergerWhereInput>>;
  OR?: Maybe<Array<LoyaltyAccountMergerWhereInput>>;
  NOT?: Maybe<Array<LoyaltyAccountMergerWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumLoyaltyAccountMergerStatusFilter>;
  errorMessage?: Maybe<StringNullableFilter>;
  mergerInitiatorId?: Maybe<StringFilter>;
  mergeDataIntoAccountId?: Maybe<StringFilter>;
  mergeDataFromAccountId?: Maybe<StringFilter>;
  initiatedBy?: Maybe<UserWhereInput>;
  mergeDataIntoAccount?: Maybe<LoyaltyAccountWhereInput>;
  mergeDataFromAccount?: Maybe<LoyaltyAccountWhereInput>;
};

export type LoyaltyAccountMergerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountMinAggregateOutputType = {
  __typename?: 'LoyaltyAccountMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  migratedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  deactivationDate?: Maybe<SortOrder>;
  pointBalance?: Maybe<SortOrder>;
  leftOverSpend?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
};

export type LoyaltyAccountOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyAccountOrderByRelevanceFieldEnum {
  Id = 'id',
  ProgramId = 'programId'
}

export type LoyaltyAccountOrderByRelevanceInput = {
  fields: Array<LoyaltyAccountOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LoyaltyAccountOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  migratedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  deactivationDate?: Maybe<SortOrder>;
  pointBalance?: Maybe<SortOrder>;
  leftOverSpend?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  _count?: Maybe<LoyaltyAccountCountOrderByAggregateInput>;
  _avg?: Maybe<LoyaltyAccountAvgOrderByAggregateInput>;
  _max?: Maybe<LoyaltyAccountMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyAccountMinOrderByAggregateInput>;
  _sum?: Maybe<LoyaltyAccountSumOrderByAggregateInput>;
};

export type LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  migratedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  deactivationDate?: Maybe<SortOrder>;
  pointBalance?: Maybe<SortOrder>;
  leftOverSpend?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  program?: Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>;
  accountHolders?: Maybe<LoyaltyAccountHolderOrderByRelationAggregateInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaOrderByRelationAggregateInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionOrderByRelationAggregateInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerOrderByRelationAggregateInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<LoyaltyAccountOrderByRelevanceInput>;
};

export type LoyaltyAccountRelationFilter = {
  is?: Maybe<LoyaltyAccountWhereInput>;
  isNot?: Maybe<LoyaltyAccountWhereInput>;
};

export enum LoyaltyAccountScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  MigratedAt = 'migratedAt',
  IsActive = 'isActive',
  DeactivationDate = 'deactivationDate',
  PointBalance = 'pointBalance',
  LeftOverSpend = 'leftOverSpend',
  ProgramId = 'programId'
}

export type LoyaltyAccountScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyAccountScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyAccountScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyAccountScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  migratedAt?: Maybe<DateTimeNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  deactivationDate?: Maybe<DateTimeNullableFilter>;
  pointBalance?: Maybe<IntFilter>;
  leftOverSpend?: Maybe<IntFilter>;
  programId?: Maybe<StringFilter>;
};

export type LoyaltyAccountScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyAccountScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyAccountScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyAccountScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  migratedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  deactivationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  pointBalance?: Maybe<IntWithAggregatesFilter>;
  leftOverSpend?: Maybe<IntWithAggregatesFilter>;
  programId?: Maybe<StringWithAggregatesFilter>;
};

export type LoyaltyAccountSubscription = {
  __typename?: 'LoyaltyAccountSubscription';
  node: LoyaltyAccount;
  mutation: MutationType;
};

export type LoyaltyAccountSumAggregateOutputType = {
  __typename?: 'LoyaltyAccountSumAggregateOutputType';
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
};

export type LoyaltyAccountSumOrderByAggregateInput = {
  pointBalance?: Maybe<SortOrder>;
  leftOverSpend?: Maybe<SortOrder>;
};

export type LoyaltyAccountUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId: Scalars['String'];
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutAccountInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutAccountInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutMergeDataIntoAccountInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedOneWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountUncheckedCreateNestedManyWithoutProgramInput = {
  create?: Maybe<Array<LoyaltyAccountCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountCreateOrConnectWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountCreateManyProgramInputEnvelope>;
  connect?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
};

export type LoyaltyAccountUncheckedCreateWithoutAccountHoldersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId: Scalars['String'];
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutAccountInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutMergeDataIntoAccountInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedOneWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountUncheckedCreateWithoutMergeSentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId: Scalars['String'];
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutAccountInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutAccountInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutMergeDataIntoAccountInput>;
};

export type LoyaltyAccountUncheckedCreateWithoutMergesTakenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId: Scalars['String'];
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutAccountInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutAccountInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutAccountInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedOneWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountUncheckedCreateWithoutPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId: Scalars['String'];
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutAccountInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutMergeDataIntoAccountInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedOneWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountUncheckedCreateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutAccountInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutAccountInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutMergeDataIntoAccountInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedOneWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountUncheckedCreateWithoutRewardRedemptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId: Scalars['String'];
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutAccountInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutMergeDataIntoAccountInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedOneWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountNestedInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutAccountNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedUpdateOneWithoutMergeDataFromAccountNestedInput>;
};

export type LoyaltyAccountUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountUncheckedUpdateManyWithoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
};

export type LoyaltyAccountUncheckedUpdateManyWithoutProgramNestedInput = {
  create?: Maybe<Array<LoyaltyAccountCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountCreateOrConnectWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyAccountUpsertWithWhereUniqueWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountCreateManyProgramInputEnvelope>;
  set?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountUpdateManyWithWhereWithoutProgramInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountScalarWhereInput>>;
};

export type LoyaltyAccountUncheckedUpdateWithoutAccountHoldersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutAccountNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedUpdateOneWithoutMergeDataFromAccountNestedInput>;
};

export type LoyaltyAccountUncheckedUpdateWithoutMergeSentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountNestedInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutAccountNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutMergeDataIntoAccountNestedInput>;
};

export type LoyaltyAccountUncheckedUpdateWithoutMergesTakenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountNestedInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutAccountNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutAccountNestedInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedUpdateOneWithoutMergeDataFromAccountNestedInput>;
};

export type LoyaltyAccountUncheckedUpdateWithoutPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedUpdateOneWithoutMergeDataFromAccountNestedInput>;
};

export type LoyaltyAccountUncheckedUpdateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountNestedInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutAccountNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedUpdateOneWithoutMergeDataFromAccountNestedInput>;
};

export type LoyaltyAccountUncheckedUpdateWithoutRewardRedemptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountNestedInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedUpdateOneWithoutMergeDataFromAccountNestedInput>;
};

export type LoyaltyAccountUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutAccountsNestedInput>;
  accountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutAccountNestedInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutAccountNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUpdateOneWithoutMergeDataFromAccountNestedInput>;
};

export type LoyaltyAccountUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
};

export type LoyaltyAccountUpdateManyWithWhereWithoutProgramInput = {
  where: LoyaltyAccountScalarWhereInput;
  data: LoyaltyAccountUncheckedUpdateManyWithoutAccountsInput;
};

export type LoyaltyAccountUpdateManyWithoutProgramNestedInput = {
  create?: Maybe<Array<LoyaltyAccountCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountCreateOrConnectWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyAccountUpsertWithWhereUniqueWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountCreateManyProgramInputEnvelope>;
  set?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountUpdateManyWithWhereWithoutProgramInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountScalarWhereInput>>;
};

export type LoyaltyAccountUpdateOneRequiredWithoutAccountHoldersNestedInput = {
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutAccountHoldersInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutAccountHoldersInput>;
  upsert?: Maybe<LoyaltyAccountUpsertWithoutAccountHoldersInput>;
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  update?: Maybe<LoyaltyAccountUncheckedUpdateWithoutAccountHoldersInput>;
};

export type LoyaltyAccountUpdateOneRequiredWithoutMergeSentNestedInput = {
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutMergeSentInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutMergeSentInput>;
  upsert?: Maybe<LoyaltyAccountUpsertWithoutMergeSentInput>;
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  update?: Maybe<LoyaltyAccountUncheckedUpdateWithoutMergeSentInput>;
};

export type LoyaltyAccountUpdateOneRequiredWithoutMergesTakenNestedInput = {
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutMergesTakenInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutMergesTakenInput>;
  upsert?: Maybe<LoyaltyAccountUpsertWithoutMergesTakenInput>;
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  update?: Maybe<LoyaltyAccountUncheckedUpdateWithoutMergesTakenInput>;
};

export type LoyaltyAccountUpdateOneRequiredWithoutPointDeltasNestedInput = {
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutPointDeltasInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutPointDeltasInput>;
  upsert?: Maybe<LoyaltyAccountUpsertWithoutPointDeltasInput>;
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  update?: Maybe<LoyaltyAccountUncheckedUpdateWithoutPointDeltasInput>;
};

export type LoyaltyAccountUpdateOneRequiredWithoutRewardRedemptionsNestedInput = {
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutRewardRedemptionsInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutRewardRedemptionsInput>;
  upsert?: Maybe<LoyaltyAccountUpsertWithoutRewardRedemptionsInput>;
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  update?: Maybe<LoyaltyAccountUncheckedUpdateWithoutRewardRedemptionsInput>;
};

export type LoyaltyAccountUpdateWithWhereUniqueWithoutProgramInput = {
  where: LoyaltyAccountWhereUniqueInput;
  data: LoyaltyAccountUncheckedUpdateWithoutProgramInput;
};

export type LoyaltyAccountUpdateWithoutAccountHoldersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutAccountsNestedInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutAccountNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUpdateOneWithoutMergeDataFromAccountNestedInput>;
};

export type LoyaltyAccountUpdateWithoutMergeSentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutAccountsNestedInput>;
  accountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutAccountNestedInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutAccountNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUpdateManyWithoutMergeDataIntoAccountNestedInput>;
};

export type LoyaltyAccountUpdateWithoutMergesTakenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutAccountsNestedInput>;
  accountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutAccountNestedInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutAccountNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutAccountNestedInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUpdateOneWithoutMergeDataFromAccountNestedInput>;
};

export type LoyaltyAccountUpdateWithoutPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutAccountsNestedInput>;
  accountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutAccountNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUpdateOneWithoutMergeDataFromAccountNestedInput>;
};

export type LoyaltyAccountUpdateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  accountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutAccountNestedInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutAccountNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUpdateOneWithoutMergeDataFromAccountNestedInput>;
};

export type LoyaltyAccountUpdateWithoutRewardRedemptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutAccountsNestedInput>;
  accountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutAccountNestedInput>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  mergeSent?: Maybe<LoyaltyAccountMergerUpdateOneWithoutMergeDataFromAccountNestedInput>;
};

export type LoyaltyAccountUpsertWithWhereUniqueWithoutProgramInput = {
  where: LoyaltyAccountWhereUniqueInput;
  update: LoyaltyAccountUncheckedUpdateWithoutProgramInput;
  create: LoyaltyAccountUncheckedCreateWithoutProgramInput;
};

export type LoyaltyAccountUpsertWithoutAccountHoldersInput = {
  update: LoyaltyAccountUncheckedUpdateWithoutAccountHoldersInput;
  create: LoyaltyAccountUncheckedCreateWithoutAccountHoldersInput;
};

export type LoyaltyAccountUpsertWithoutMergeSentInput = {
  update: LoyaltyAccountUncheckedUpdateWithoutMergeSentInput;
  create: LoyaltyAccountUncheckedCreateWithoutMergeSentInput;
};

export type LoyaltyAccountUpsertWithoutMergesTakenInput = {
  update: LoyaltyAccountUncheckedUpdateWithoutMergesTakenInput;
  create: LoyaltyAccountUncheckedCreateWithoutMergesTakenInput;
};

export type LoyaltyAccountUpsertWithoutPointDeltasInput = {
  update: LoyaltyAccountUncheckedUpdateWithoutPointDeltasInput;
  create: LoyaltyAccountUncheckedCreateWithoutPointDeltasInput;
};

export type LoyaltyAccountUpsertWithoutRewardRedemptionsInput = {
  update: LoyaltyAccountUncheckedUpdateWithoutRewardRedemptionsInput;
  create: LoyaltyAccountUncheckedCreateWithoutRewardRedemptionsInput;
};

export type LoyaltyAccountWhereInput = {
  AND?: Maybe<Array<LoyaltyAccountWhereInput>>;
  OR?: Maybe<Array<LoyaltyAccountWhereInput>>;
  NOT?: Maybe<Array<LoyaltyAccountWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  migratedAt?: Maybe<DateTimeNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  deactivationDate?: Maybe<DateTimeNullableFilter>;
  pointBalance?: Maybe<IntFilter>;
  leftOverSpend?: Maybe<IntFilter>;
  programId?: Maybe<StringFilter>;
  program?: Maybe<LoyaltyProgramWhereInput>;
  accountHolders?: Maybe<LoyaltyAccountHolderListRelationFilter>;
  pointDeltas?: Maybe<LoyaltyPointDeltaListRelationFilter>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionListRelationFilter>;
  mergesTaken?: Maybe<LoyaltyAccountMergerListRelationFilter>;
  mergeSent?: Maybe<LoyaltyAccountMergerWhereInput>;
};

export type LoyaltyAccountWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoyaltyInvoicePointGranter = {
  __typename?: 'LoyaltyInvoicePointGranter';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  label: Scalars['String'];
  enabled: Scalars['Boolean'];
  allowAllServices: Scalars['Boolean'];
  programId: Scalars['String'];
  program: LoyaltyProgram;
  grantingServices: Array<LoyaltyPointGrantingService>;
  grantingServiceCategories: Array<LoyaltyPointGrantingServiceCategory>;
  relatedInvoiceLoyaltyPointDeltas: Array<LoyaltyPointDelta>;
  _count: LoyaltyInvoicePointGranterCountOutputType;
};


export type LoyaltyInvoicePointGranterGrantingServicesArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
  orderBy?: Maybe<LoyaltyPointGrantingServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyPointGrantingServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyPointGrantingServiceScalarFieldEnum>;
};


export type LoyaltyInvoicePointGranterGrantingServiceCategoriesArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
  orderBy?: Maybe<LoyaltyPointGrantingServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyPointGrantingServiceCategoryScalarFieldEnum>;
};


export type LoyaltyInvoicePointGranterRelatedInvoiceLoyaltyPointDeltasArgs = {
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
  orderBy?: Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyPointDeltaScalarFieldEnum>;
};

export type LoyaltyInvoicePointGranterCountAggregateOutputType = {
  __typename?: 'LoyaltyInvoicePointGranterCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  label: Scalars['Int'];
  enabled: Scalars['Int'];
  allowAllServices: Scalars['Int'];
  programId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LoyaltyInvoicePointGranterCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  enabled?: Maybe<SortOrder>;
  allowAllServices?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
};

export type LoyaltyInvoicePointGranterCountOutputType = {
  __typename?: 'LoyaltyInvoicePointGranterCountOutputType';
  grantingServices: Scalars['Int'];
  grantingServiceCategories: Scalars['Int'];
  relatedInvoiceLoyaltyPointDeltas: Scalars['Int'];
};

export type LoyaltyInvoicePointGranterCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  program: LoyaltyProgramCreateNestedOneWithoutPointGrantersInput;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutGranterInput>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutGranterInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutInvoicePointGranterInput>;
};

export type LoyaltyInvoicePointGranterCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  programId: Scalars['String'];
};

export type LoyaltyInvoicePointGranterCreateManyProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyInvoicePointGranterCreateManyProgramInputEnvelope = {
  data: Array<LoyaltyInvoicePointGranterCreateManyProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyInvoicePointGranterCreateNestedManyWithoutProgramInput = {
  create?: Maybe<Array<LoyaltyInvoicePointGranterCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyInvoicePointGranterCreateOrConnectWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyInvoicePointGranterCreateManyProgramInputEnvelope>;
  connect?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
};

export type LoyaltyInvoicePointGranterCreateNestedOneWithoutGrantingServiceCategoriesInput = {
  create?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServiceCategoriesInput>;
  connectOrCreate?: Maybe<LoyaltyInvoicePointGranterCreateOrConnectWithoutGrantingServiceCategoriesInput>;
  connect?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
};

export type LoyaltyInvoicePointGranterCreateNestedOneWithoutGrantingServicesInput = {
  create?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServicesInput>;
  connectOrCreate?: Maybe<LoyaltyInvoicePointGranterCreateOrConnectWithoutGrantingServicesInput>;
  connect?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
};

export type LoyaltyInvoicePointGranterCreateNestedOneWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  create?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  connectOrCreate?: Maybe<LoyaltyInvoicePointGranterCreateOrConnectWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  connect?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
};

export type LoyaltyInvoicePointGranterCreateOrConnectWithoutGrantingServiceCategoriesInput = {
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServiceCategoriesInput;
};

export type LoyaltyInvoicePointGranterCreateOrConnectWithoutGrantingServicesInput = {
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServicesInput;
};

export type LoyaltyInvoicePointGranterCreateOrConnectWithoutProgramInput = {
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutProgramInput;
};

export type LoyaltyInvoicePointGranterCreateOrConnectWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutRelatedInvoiceLoyaltyPointDeltasInput;
};

export type LoyaltyInvoicePointGranterCreateWithoutGrantingServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  program: LoyaltyProgramCreateNestedOneWithoutPointGrantersInput;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutGranterInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutInvoicePointGranterInput>;
};

export type LoyaltyInvoicePointGranterCreateWithoutGrantingServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  program: LoyaltyProgramCreateNestedOneWithoutPointGrantersInput;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutGranterInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutInvoicePointGranterInput>;
};

export type LoyaltyInvoicePointGranterCreateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutGranterInput>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutGranterInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutInvoicePointGranterInput>;
};

export type LoyaltyInvoicePointGranterCreateWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  program: LoyaltyProgramCreateNestedOneWithoutPointGrantersInput;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutGranterInput>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutGranterInput>;
};

export type LoyaltyInvoicePointGranterListRelationFilter = {
  every?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  some?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  none?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};

export type LoyaltyInvoicePointGranterMaxAggregateOutputType = {
  __typename?: 'LoyaltyInvoicePointGranterMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  programId?: Maybe<Scalars['String']>;
};

export type LoyaltyInvoicePointGranterMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  enabled?: Maybe<SortOrder>;
  allowAllServices?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
};

export type LoyaltyInvoicePointGranterMinAggregateOutputType = {
  __typename?: 'LoyaltyInvoicePointGranterMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  programId?: Maybe<Scalars['String']>;
};

export type LoyaltyInvoicePointGranterMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  enabled?: Maybe<SortOrder>;
  allowAllServices?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
};

export type LoyaltyInvoicePointGranterOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyInvoicePointGranterOrderByRelevanceFieldEnum {
  Id = 'id',
  Label = 'label',
  ProgramId = 'programId'
}

export type LoyaltyInvoicePointGranterOrderByRelevanceInput = {
  fields: Array<LoyaltyInvoicePointGranterOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LoyaltyInvoicePointGranterOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  enabled?: Maybe<SortOrder>;
  allowAllServices?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  _count?: Maybe<LoyaltyInvoicePointGranterCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyInvoicePointGranterMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyInvoicePointGranterMinOrderByAggregateInput>;
};

export type LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  enabled?: Maybe<SortOrder>;
  allowAllServices?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  program?: Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceOrderByRelationAggregateInput>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryOrderByRelationAggregateInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaOrderByRelationAggregateInput>;
  _relevance?: Maybe<LoyaltyInvoicePointGranterOrderByRelevanceInput>;
};

export type LoyaltyInvoicePointGranterRelationFilter = {
  is?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  isNot?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};

export enum LoyaltyInvoicePointGranterScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Label = 'label',
  Enabled = 'enabled',
  AllowAllServices = 'allowAllServices',
  ProgramId = 'programId'
}

export type LoyaltyInvoicePointGranterScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  label?: Maybe<StringFilter>;
  enabled?: Maybe<BoolFilter>;
  allowAllServices?: Maybe<BoolFilter>;
  programId?: Maybe<StringFilter>;
};

export type LoyaltyInvoicePointGranterScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  label?: Maybe<StringWithAggregatesFilter>;
  enabled?: Maybe<BoolWithAggregatesFilter>;
  allowAllServices?: Maybe<BoolWithAggregatesFilter>;
  programId?: Maybe<StringWithAggregatesFilter>;
};

export type LoyaltyInvoicePointGranterUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  programId: Scalars['String'];
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutGranterInput>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutGranterInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutInvoicePointGranterInput>;
};

export type LoyaltyInvoicePointGranterUncheckedCreateNestedManyWithoutProgramInput = {
  create?: Maybe<Array<LoyaltyInvoicePointGranterCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyInvoicePointGranterCreateOrConnectWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyInvoicePointGranterCreateManyProgramInputEnvelope>;
  connect?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
};

export type LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  programId: Scalars['String'];
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutGranterInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutInvoicePointGranterInput>;
};

export type LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  programId: Scalars['String'];
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutGranterInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutInvoicePointGranterInput>;
};

export type LoyaltyInvoicePointGranterUncheckedCreateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutGranterInput>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutGranterInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutInvoicePointGranterInput>;
};

export type LoyaltyInvoicePointGranterUncheckedCreateWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  programId: Scalars['String'];
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutGranterInput>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutGranterInput>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  programId?: Maybe<Scalars['String']>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutGranterNestedInput>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutGranterNestedInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutInvoicePointGranterNestedInput>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  programId?: Maybe<Scalars['String']>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutPointGrantersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutProgramNestedInput = {
  create?: Maybe<Array<LoyaltyInvoicePointGranterCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyInvoicePointGranterCreateOrConnectWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyInvoicePointGranterUpsertWithWhereUniqueWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyInvoicePointGranterCreateManyProgramInputEnvelope>;
  set?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyInvoicePointGranterUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyInvoicePointGranterUpdateManyWithWhereWithoutProgramInput>>;
  deleteMany?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereInput>>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateWithoutGrantingServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  programId?: Maybe<Scalars['String']>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutGranterNestedInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutInvoicePointGranterNestedInput>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateWithoutGrantingServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  programId?: Maybe<Scalars['String']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutGranterNestedInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutInvoicePointGranterNestedInput>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutGranterNestedInput>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutGranterNestedInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutInvoicePointGranterNestedInput>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  programId?: Maybe<Scalars['String']>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutGranterNestedInput>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutGranterNestedInput>;
};

export type LoyaltyInvoicePointGranterUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutPointGrantersNestedInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutGranterNestedInput>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutGranterNestedInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutInvoicePointGranterNestedInput>;
};

export type LoyaltyInvoicePointGranterUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyInvoicePointGranterUpdateManyWithWhereWithoutProgramInput = {
  where: LoyaltyInvoicePointGranterScalarWhereInput;
  data: LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutPointGrantersInput;
};

export type LoyaltyInvoicePointGranterUpdateManyWithoutProgramNestedInput = {
  create?: Maybe<Array<LoyaltyInvoicePointGranterCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyInvoicePointGranterCreateOrConnectWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyInvoicePointGranterUpsertWithWhereUniqueWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyInvoicePointGranterCreateManyProgramInputEnvelope>;
  set?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyInvoicePointGranterUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyInvoicePointGranterUpdateManyWithWhereWithoutProgramInput>>;
  deleteMany?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereInput>>;
};

export type LoyaltyInvoicePointGranterUpdateOneRequiredWithoutGrantingServiceCategoriesNestedInput = {
  create?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServiceCategoriesInput>;
  connectOrCreate?: Maybe<LoyaltyInvoicePointGranterCreateOrConnectWithoutGrantingServiceCategoriesInput>;
  upsert?: Maybe<LoyaltyInvoicePointGranterUpsertWithoutGrantingServiceCategoriesInput>;
  connect?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  update?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateWithoutGrantingServiceCategoriesInput>;
};

export type LoyaltyInvoicePointGranterUpdateOneRequiredWithoutGrantingServicesNestedInput = {
  create?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServicesInput>;
  connectOrCreate?: Maybe<LoyaltyInvoicePointGranterCreateOrConnectWithoutGrantingServicesInput>;
  upsert?: Maybe<LoyaltyInvoicePointGranterUpsertWithoutGrantingServicesInput>;
  connect?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  update?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateWithoutGrantingServicesInput>;
};

export type LoyaltyInvoicePointGranterUpdateOneWithoutRelatedInvoiceLoyaltyPointDeltasNestedInput = {
  create?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  connectOrCreate?: Maybe<LoyaltyInvoicePointGranterCreateOrConnectWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  upsert?: Maybe<LoyaltyInvoicePointGranterUpsertWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  update?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
};

export type LoyaltyInvoicePointGranterUpdateWithWhereUniqueWithoutProgramInput = {
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
  data: LoyaltyInvoicePointGranterUncheckedUpdateWithoutProgramInput;
};

export type LoyaltyInvoicePointGranterUpdateWithoutGrantingServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutPointGrantersNestedInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutGranterNestedInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutInvoicePointGranterNestedInput>;
};

export type LoyaltyInvoicePointGranterUpdateWithoutGrantingServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutPointGrantersNestedInput>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutGranterNestedInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutInvoicePointGranterNestedInput>;
};

export type LoyaltyInvoicePointGranterUpdateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutGranterNestedInput>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutGranterNestedInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutInvoicePointGranterNestedInput>;
};

export type LoyaltyInvoicePointGranterUpdateWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  allowAllServices?: Maybe<Scalars['Boolean']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutPointGrantersNestedInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutGranterNestedInput>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutGranterNestedInput>;
};

export type LoyaltyInvoicePointGranterUpsertWithWhereUniqueWithoutProgramInput = {
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
  update: LoyaltyInvoicePointGranterUncheckedUpdateWithoutProgramInput;
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutProgramInput;
};

export type LoyaltyInvoicePointGranterUpsertWithoutGrantingServiceCategoriesInput = {
  update: LoyaltyInvoicePointGranterUncheckedUpdateWithoutGrantingServiceCategoriesInput;
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServiceCategoriesInput;
};

export type LoyaltyInvoicePointGranterUpsertWithoutGrantingServicesInput = {
  update: LoyaltyInvoicePointGranterUncheckedUpdateWithoutGrantingServicesInput;
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServicesInput;
};

export type LoyaltyInvoicePointGranterUpsertWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  update: LoyaltyInvoicePointGranterUncheckedUpdateWithoutRelatedInvoiceLoyaltyPointDeltasInput;
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutRelatedInvoiceLoyaltyPointDeltasInput;
};

export type LoyaltyInvoicePointGranterWhereInput = {
  AND?: Maybe<Array<LoyaltyInvoicePointGranterWhereInput>>;
  OR?: Maybe<Array<LoyaltyInvoicePointGranterWhereInput>>;
  NOT?: Maybe<Array<LoyaltyInvoicePointGranterWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  label?: Maybe<StringFilter>;
  enabled?: Maybe<BoolFilter>;
  allowAllServices?: Maybe<BoolFilter>;
  programId?: Maybe<StringFilter>;
  program?: Maybe<LoyaltyProgramWhereInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceListRelationFilter>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryListRelationFilter>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaListRelationFilter>;
};

export type LoyaltyInvoicePointGranterWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoyaltyInvoiceTriggerInput = {
  invoiceId: Scalars['String'];
};

export type LoyaltyInvoiceTriggerOutput = {
  __typename?: 'LoyaltyInvoiceTriggerOutput';
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type LoyaltyPointDelta = {
  __typename?: 'LoyaltyPointDelta';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  source: LoyaltyPointDeltaSource;
  postedToAccount: Scalars['Boolean'];
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  accountId: Scalars['String'];
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId: Scalars['String'];
  account: LoyaltyAccount;
  grantingInvoice?: Maybe<Invoice>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranter>;
  userGrant?: Maybe<LoyaltyPointUserGrant>;
  relatedClinic: Clinic;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntry>;
};

export type LoyaltyPointDeltaAvgAggregateOutputType = {
  __typename?: 'LoyaltyPointDeltaAvgAggregateOutputType';
  points?: Maybe<Scalars['Float']>;
  leftOverSpendDelta?: Maybe<Scalars['Float']>;
};

export type LoyaltyPointDeltaAvgOrderByAggregateInput = {
  points?: Maybe<SortOrder>;
  leftOverSpendDelta?: Maybe<SortOrder>;
};

export type LoyaltyPointDeltaCountAggregateOutputType = {
  __typename?: 'LoyaltyPointDeltaCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  source: Scalars['Int'];
  postedToAccount: Scalars['Int'];
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  accountId: Scalars['Int'];
  grantingInvoiceId: Scalars['Int'];
  invoicePointGranterId: Scalars['Int'];
  userGrantId: Scalars['Int'];
  relatedClinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LoyaltyPointDeltaCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  postedToAccount?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  leftOverSpendDelta?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  grantingInvoiceId?: Maybe<SortOrder>;
  invoicePointGranterId?: Maybe<SortOrder>;
  userGrantId?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
};

export type LoyaltyPointDeltaCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  account: LoyaltyAccountCreateNestedOneWithoutPointDeltasInput;
  grantingInvoice?: Maybe<InvoiceCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterCreateNestedOneWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  userGrant?: Maybe<LoyaltyPointUserGrantCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  relatedClinic: ClinicCreateNestedOneWithoutLoyaltyPointDeltasInput;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedOneWithoutPointDeltaInput>;
};

export type LoyaltyPointDeltaCreateManyAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId: Scalars['String'];
};

export type LoyaltyPointDeltaCreateManyAccountInputEnvelope = {
  data: Array<LoyaltyPointDeltaCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointDeltaCreateManyGrantingInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  accountId: Scalars['String'];
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId: Scalars['String'];
};

export type LoyaltyPointDeltaCreateManyGrantingInvoiceInputEnvelope = {
  data: Array<LoyaltyPointDeltaCreateManyGrantingInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointDeltaCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  accountId: Scalars['String'];
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId: Scalars['String'];
};

export type LoyaltyPointDeltaCreateManyInvoicePointGranterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  accountId: Scalars['String'];
  grantingInvoiceId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId: Scalars['String'];
};

export type LoyaltyPointDeltaCreateManyInvoicePointGranterInputEnvelope = {
  data: Array<LoyaltyPointDeltaCreateManyInvoicePointGranterInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointDeltaCreateManyRelatedClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  accountId: Scalars['String'];
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaCreateManyRelatedClinicInputEnvelope = {
  data: Array<LoyaltyPointDeltaCreateManyRelatedClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointDeltaCreateManyUserGrantInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  accountId: Scalars['String'];
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  relatedClinicId: Scalars['String'];
};

export type LoyaltyPointDeltaCreateManyUserGrantInputEnvelope = {
  data: Array<LoyaltyPointDeltaCreateManyUserGrantInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointDeltaCreateNestedManyWithoutAccountInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyAccountInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
};

export type LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutGrantingInvoiceInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutGrantingInvoiceInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyGrantingInvoiceInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
};

export type LoyaltyPointDeltaCreateNestedManyWithoutInvoicePointGranterInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutInvoicePointGranterInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutInvoicePointGranterInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyInvoicePointGranterInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
};

export type LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutRelatedClinicInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyRelatedClinicInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
};

export type LoyaltyPointDeltaCreateNestedManyWithoutUserGrantInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutUserGrantInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutUserGrantInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyUserGrantInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
};

export type LoyaltyPointDeltaCreateNestedOneWithoutRelatedRedemptionHistoryEntryInput = {
  create?: Maybe<LoyaltyPointDeltaUncheckedCreateWithoutRelatedRedemptionHistoryEntryInput>;
  connectOrCreate?: Maybe<LoyaltyPointDeltaCreateOrConnectWithoutRelatedRedemptionHistoryEntryInput>;
  connect?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
};

export type LoyaltyPointDeltaCreateOrConnectWithoutAccountInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  create: LoyaltyPointDeltaUncheckedCreateWithoutAccountInput;
};

export type LoyaltyPointDeltaCreateOrConnectWithoutGrantingInvoiceInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  create: LoyaltyPointDeltaUncheckedCreateWithoutGrantingInvoiceInput;
};

export type LoyaltyPointDeltaCreateOrConnectWithoutInvoicePointGranterInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  create: LoyaltyPointDeltaUncheckedCreateWithoutInvoicePointGranterInput;
};

export type LoyaltyPointDeltaCreateOrConnectWithoutRelatedClinicInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  create: LoyaltyPointDeltaUncheckedCreateWithoutRelatedClinicInput;
};

export type LoyaltyPointDeltaCreateOrConnectWithoutRelatedRedemptionHistoryEntryInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  create: LoyaltyPointDeltaUncheckedCreateWithoutRelatedRedemptionHistoryEntryInput;
};

export type LoyaltyPointDeltaCreateOrConnectWithoutUserGrantInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  create: LoyaltyPointDeltaUncheckedCreateWithoutUserGrantInput;
};

export type LoyaltyPointDeltaCreateWithoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  grantingInvoice?: Maybe<InvoiceCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterCreateNestedOneWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  userGrant?: Maybe<LoyaltyPointUserGrantCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  relatedClinic: ClinicCreateNestedOneWithoutLoyaltyPointDeltasInput;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedOneWithoutPointDeltaInput>;
};

export type LoyaltyPointDeltaCreateWithoutGrantingInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  account: LoyaltyAccountCreateNestedOneWithoutPointDeltasInput;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterCreateNestedOneWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  userGrant?: Maybe<LoyaltyPointUserGrantCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  relatedClinic: ClinicCreateNestedOneWithoutLoyaltyPointDeltasInput;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedOneWithoutPointDeltaInput>;
};

export type LoyaltyPointDeltaCreateWithoutInvoicePointGranterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  account: LoyaltyAccountCreateNestedOneWithoutPointDeltasInput;
  grantingInvoice?: Maybe<InvoiceCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  userGrant?: Maybe<LoyaltyPointUserGrantCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  relatedClinic: ClinicCreateNestedOneWithoutLoyaltyPointDeltasInput;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedOneWithoutPointDeltaInput>;
};

export type LoyaltyPointDeltaCreateWithoutRelatedClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  account: LoyaltyAccountCreateNestedOneWithoutPointDeltasInput;
  grantingInvoice?: Maybe<InvoiceCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterCreateNestedOneWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  userGrant?: Maybe<LoyaltyPointUserGrantCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedOneWithoutPointDeltaInput>;
};

export type LoyaltyPointDeltaCreateWithoutRelatedRedemptionHistoryEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  account: LoyaltyAccountCreateNestedOneWithoutPointDeltasInput;
  grantingInvoice?: Maybe<InvoiceCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterCreateNestedOneWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  userGrant?: Maybe<LoyaltyPointUserGrantCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  relatedClinic: ClinicCreateNestedOneWithoutLoyaltyPointDeltasInput;
};

export type LoyaltyPointDeltaCreateWithoutUserGrantInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  account: LoyaltyAccountCreateNestedOneWithoutPointDeltasInput;
  grantingInvoice?: Maybe<InvoiceCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterCreateNestedOneWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  relatedClinic: ClinicCreateNestedOneWithoutLoyaltyPointDeltasInput;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedOneWithoutPointDeltaInput>;
};

export type LoyaltyPointDeltaGrantingInvoiceIdInvoicePointGranterIdCompoundUniqueInput = {
  grantingInvoiceId: Scalars['String'];
  invoicePointGranterId: Scalars['String'];
};

export type LoyaltyPointDeltaListRelationFilter = {
  every?: Maybe<LoyaltyPointDeltaWhereInput>;
  some?: Maybe<LoyaltyPointDeltaWhereInput>;
  none?: Maybe<LoyaltyPointDeltaWhereInput>;
};

export type LoyaltyPointDeltaMaxAggregateOutputType = {
  __typename?: 'LoyaltyPointDeltaMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['String']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  postedToAccount?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  leftOverSpendDelta?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  grantingInvoiceId?: Maybe<SortOrder>;
  invoicePointGranterId?: Maybe<SortOrder>;
  userGrantId?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
};

export type LoyaltyPointDeltaMinAggregateOutputType = {
  __typename?: 'LoyaltyPointDeltaMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['String']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  postedToAccount?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  leftOverSpendDelta?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  grantingInvoiceId?: Maybe<SortOrder>;
  invoicePointGranterId?: Maybe<SortOrder>;
  userGrantId?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
};

export type LoyaltyPointDeltaOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyPointDeltaOrderByRelevanceFieldEnum {
  Id = 'id',
  AccountId = 'accountId',
  GrantingInvoiceId = 'grantingInvoiceId',
  InvoicePointGranterId = 'invoicePointGranterId',
  UserGrantId = 'userGrantId',
  RelatedClinicId = 'relatedClinicId'
}

export type LoyaltyPointDeltaOrderByRelevanceInput = {
  fields: Array<LoyaltyPointDeltaOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LoyaltyPointDeltaOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  postedToAccount?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  leftOverSpendDelta?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  grantingInvoiceId?: Maybe<SortOrder>;
  invoicePointGranterId?: Maybe<SortOrder>;
  userGrantId?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  _count?: Maybe<LoyaltyPointDeltaCountOrderByAggregateInput>;
  _avg?: Maybe<LoyaltyPointDeltaAvgOrderByAggregateInput>;
  _max?: Maybe<LoyaltyPointDeltaMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyPointDeltaMinOrderByAggregateInput>;
  _sum?: Maybe<LoyaltyPointDeltaSumOrderByAggregateInput>;
};

export type LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  postedToAccount?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  leftOverSpendDelta?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  grantingInvoiceId?: Maybe<SortOrder>;
  invoicePointGranterId?: Maybe<SortOrder>;
  userGrantId?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  account?: Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>;
  grantingInvoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>;
  userGrant?: Maybe<LoyaltyPointUserGrantOrderByWithRelationAndSearchRelevanceInput>;
  relatedClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<LoyaltyPointDeltaOrderByRelevanceInput>;
};

export type LoyaltyPointDeltaRelationFilter = {
  is?: Maybe<LoyaltyPointDeltaWhereInput>;
  isNot?: Maybe<LoyaltyPointDeltaWhereInput>;
};

export enum LoyaltyPointDeltaScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Source = 'source',
  PostedToAccount = 'postedToAccount',
  Points = 'points',
  LeftOverSpendDelta = 'leftOverSpendDelta',
  AccountId = 'accountId',
  GrantingInvoiceId = 'grantingInvoiceId',
  InvoicePointGranterId = 'invoicePointGranterId',
  UserGrantId = 'userGrantId',
  RelatedClinicId = 'relatedClinicId'
}

export type LoyaltyPointDeltaScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  source?: Maybe<EnumLoyaltyPointDeltaSourceFilter>;
  postedToAccount?: Maybe<BoolFilter>;
  points?: Maybe<IntFilter>;
  leftOverSpendDelta?: Maybe<IntFilter>;
  accountId?: Maybe<StringFilter>;
  grantingInvoiceId?: Maybe<StringNullableFilter>;
  invoicePointGranterId?: Maybe<StringNullableFilter>;
  userGrantId?: Maybe<StringNullableFilter>;
  relatedClinicId?: Maybe<StringFilter>;
};

export type LoyaltyPointDeltaScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyPointDeltaScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyPointDeltaScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyPointDeltaScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  source?: Maybe<EnumLoyaltyPointDeltaSourceWithAggregatesFilter>;
  postedToAccount?: Maybe<BoolWithAggregatesFilter>;
  points?: Maybe<IntWithAggregatesFilter>;
  leftOverSpendDelta?: Maybe<IntWithAggregatesFilter>;
  accountId?: Maybe<StringWithAggregatesFilter>;
  grantingInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
  invoicePointGranterId?: Maybe<StringNullableWithAggregatesFilter>;
  userGrantId?: Maybe<StringNullableWithAggregatesFilter>;
  relatedClinicId?: Maybe<StringWithAggregatesFilter>;
};

export enum LoyaltyPointDeltaSource {
  Migration = 'Migration',
  Merge = 'Merge',
  InvoiceSpend = 'InvoiceSpend',
  RewardRedemption = 'RewardRedemption',
  UserGrant = 'UserGrant',
  Correction = 'Correction'
}

export type LoyaltyPointDeltaSumAggregateOutputType = {
  __typename?: 'LoyaltyPointDeltaSumAggregateOutputType';
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
};

export type LoyaltyPointDeltaSumOrderByAggregateInput = {
  points?: Maybe<SortOrder>;
  leftOverSpendDelta?: Maybe<SortOrder>;
};

export type LoyaltyPointDeltaUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  accountId: Scalars['String'];
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId: Scalars['String'];
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedOneWithoutPointDeltaInput>;
};

export type LoyaltyPointDeltaUncheckedCreateNestedManyWithoutAccountInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyAccountInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
};

export type LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutGrantingInvoiceInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutGrantingInvoiceInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyGrantingInvoiceInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
};

export type LoyaltyPointDeltaUncheckedCreateNestedManyWithoutInvoicePointGranterInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutInvoicePointGranterInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutInvoicePointGranterInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyInvoicePointGranterInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
};

export type LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutRelatedClinicInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyRelatedClinicInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
};

export type LoyaltyPointDeltaUncheckedCreateNestedManyWithoutUserGrantInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutUserGrantInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutUserGrantInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyUserGrantInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
};

export type LoyaltyPointDeltaUncheckedCreateWithoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId: Scalars['String'];
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedOneWithoutPointDeltaInput>;
};

export type LoyaltyPointDeltaUncheckedCreateWithoutGrantingInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  accountId: Scalars['String'];
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId: Scalars['String'];
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedOneWithoutPointDeltaInput>;
};

export type LoyaltyPointDeltaUncheckedCreateWithoutInvoicePointGranterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  accountId: Scalars['String'];
  grantingInvoiceId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId: Scalars['String'];
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedOneWithoutPointDeltaInput>;
};

export type LoyaltyPointDeltaUncheckedCreateWithoutRelatedClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  accountId: Scalars['String'];
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedOneWithoutPointDeltaInput>;
};

export type LoyaltyPointDeltaUncheckedCreateWithoutRelatedRedemptionHistoryEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  accountId: Scalars['String'];
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId: Scalars['String'];
};

export type LoyaltyPointDeltaUncheckedCreateWithoutUserGrantInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: LoyaltyPointDeltaSource;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  accountId: Scalars['String'];
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  relatedClinicId: Scalars['String'];
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedOneWithoutPointDeltaInput>;
};

export type LoyaltyPointDeltaUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['String']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateOneWithoutPointDeltaNestedInput>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['String']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutAccountNestedInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutAccountInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyAccountInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutAccountInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutGrantingInvoiceInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutGrantingInvoiceInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutGrantingInvoiceInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyGrantingInvoiceInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutGrantingInvoiceInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutGrantingInvoiceInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutInvoicePointGranterNestedInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutInvoicePointGranterInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutInvoicePointGranterInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutInvoicePointGranterInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyInvoicePointGranterInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutInvoicePointGranterInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutInvoicePointGranterInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['String']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutRelatedClinicInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutRelatedClinicInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyRelatedClinicInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutRelatedClinicInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutRelatedClinicInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['String']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutUserGrantNestedInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutUserGrantInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutUserGrantInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutUserGrantInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyUserGrantInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutUserGrantInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutUserGrantInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
};

export type LoyaltyPointDeltaUncheckedUpdateWithoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateOneWithoutPointDeltaNestedInput>;
};

export type LoyaltyPointDeltaUncheckedUpdateWithoutGrantingInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateOneWithoutPointDeltaNestedInput>;
};

export type LoyaltyPointDeltaUncheckedUpdateWithoutInvoicePointGranterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['String']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateOneWithoutPointDeltaNestedInput>;
};

export type LoyaltyPointDeltaUncheckedUpdateWithoutRelatedClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['String']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateOneWithoutPointDeltaNestedInput>;
};

export type LoyaltyPointDeltaUncheckedUpdateWithoutRelatedRedemptionHistoryEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['String']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  userGrantId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateWithoutUserGrantInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['String']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateOneWithoutPointDeltaNestedInput>;
};

export type LoyaltyPointDeltaUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutPointDeltasNestedInput>;
  grantingInvoice?: Maybe<InvoiceUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterUpdateOneWithoutRelatedInvoiceLoyaltyPointDeltasNestedInput>;
  userGrant?: Maybe<LoyaltyPointUserGrantUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneRequiredWithoutLoyaltyPointDeltasNestedInput>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateOneWithoutPointDeltaNestedInput>;
};

export type LoyaltyPointDeltaUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
};

export type LoyaltyPointDeltaUpdateManyWithWhereWithoutAccountInput = {
  where: LoyaltyPointDeltaScalarWhereInput;
  data: LoyaltyPointDeltaUncheckedUpdateManyWithoutPointDeltasInput;
};

export type LoyaltyPointDeltaUpdateManyWithWhereWithoutGrantingInvoiceInput = {
  where: LoyaltyPointDeltaScalarWhereInput;
  data: LoyaltyPointDeltaUncheckedUpdateManyWithoutLoyaltyPointDeltasInput;
};

export type LoyaltyPointDeltaUpdateManyWithWhereWithoutInvoicePointGranterInput = {
  where: LoyaltyPointDeltaScalarWhereInput;
  data: LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedInvoiceLoyaltyPointDeltasInput;
};

export type LoyaltyPointDeltaUpdateManyWithWhereWithoutRelatedClinicInput = {
  where: LoyaltyPointDeltaScalarWhereInput;
  data: LoyaltyPointDeltaUncheckedUpdateManyWithoutLoyaltyPointDeltasInput;
};

export type LoyaltyPointDeltaUpdateManyWithWhereWithoutUserGrantInput = {
  where: LoyaltyPointDeltaScalarWhereInput;
  data: LoyaltyPointDeltaUncheckedUpdateManyWithoutLoyaltyPointDeltasInput;
};

export type LoyaltyPointDeltaUpdateManyWithoutAccountNestedInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutAccountInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyAccountInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutAccountInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
};

export type LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutGrantingInvoiceInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutGrantingInvoiceInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutGrantingInvoiceInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyGrantingInvoiceInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutGrantingInvoiceInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutGrantingInvoiceInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
};

export type LoyaltyPointDeltaUpdateManyWithoutInvoicePointGranterNestedInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutInvoicePointGranterInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutInvoicePointGranterInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutInvoicePointGranterInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyInvoicePointGranterInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutInvoicePointGranterInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutInvoicePointGranterInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
};

export type LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutRelatedClinicInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutRelatedClinicInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyRelatedClinicInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutRelatedClinicInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutRelatedClinicInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
};

export type LoyaltyPointDeltaUpdateManyWithoutUserGrantNestedInput = {
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutUserGrantInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutUserGrantInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutUserGrantInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyUserGrantInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutUserGrantInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutUserGrantInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
};

export type LoyaltyPointDeltaUpdateOneWithoutRelatedRedemptionHistoryEntryNestedInput = {
  create?: Maybe<LoyaltyPointDeltaUncheckedCreateWithoutRelatedRedemptionHistoryEntryInput>;
  connectOrCreate?: Maybe<LoyaltyPointDeltaCreateOrConnectWithoutRelatedRedemptionHistoryEntryInput>;
  upsert?: Maybe<LoyaltyPointDeltaUpsertWithoutRelatedRedemptionHistoryEntryInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  update?: Maybe<LoyaltyPointDeltaUncheckedUpdateWithoutRelatedRedemptionHistoryEntryInput>;
};

export type LoyaltyPointDeltaUpdateWithWhereUniqueWithoutAccountInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  data: LoyaltyPointDeltaUncheckedUpdateWithoutAccountInput;
};

export type LoyaltyPointDeltaUpdateWithWhereUniqueWithoutGrantingInvoiceInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  data: LoyaltyPointDeltaUncheckedUpdateWithoutGrantingInvoiceInput;
};

export type LoyaltyPointDeltaUpdateWithWhereUniqueWithoutInvoicePointGranterInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  data: LoyaltyPointDeltaUncheckedUpdateWithoutInvoicePointGranterInput;
};

export type LoyaltyPointDeltaUpdateWithWhereUniqueWithoutRelatedClinicInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  data: LoyaltyPointDeltaUncheckedUpdateWithoutRelatedClinicInput;
};

export type LoyaltyPointDeltaUpdateWithWhereUniqueWithoutUserGrantInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  data: LoyaltyPointDeltaUncheckedUpdateWithoutUserGrantInput;
};

export type LoyaltyPointDeltaUpdateWithoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  grantingInvoice?: Maybe<InvoiceUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterUpdateOneWithoutRelatedInvoiceLoyaltyPointDeltasNestedInput>;
  userGrant?: Maybe<LoyaltyPointUserGrantUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneRequiredWithoutLoyaltyPointDeltasNestedInput>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateOneWithoutPointDeltaNestedInput>;
};

export type LoyaltyPointDeltaUpdateWithoutGrantingInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutPointDeltasNestedInput>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterUpdateOneWithoutRelatedInvoiceLoyaltyPointDeltasNestedInput>;
  userGrant?: Maybe<LoyaltyPointUserGrantUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneRequiredWithoutLoyaltyPointDeltasNestedInput>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateOneWithoutPointDeltaNestedInput>;
};

export type LoyaltyPointDeltaUpdateWithoutInvoicePointGranterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutPointDeltasNestedInput>;
  grantingInvoice?: Maybe<InvoiceUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  userGrant?: Maybe<LoyaltyPointUserGrantUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneRequiredWithoutLoyaltyPointDeltasNestedInput>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateOneWithoutPointDeltaNestedInput>;
};

export type LoyaltyPointDeltaUpdateWithoutRelatedClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutPointDeltasNestedInput>;
  grantingInvoice?: Maybe<InvoiceUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterUpdateOneWithoutRelatedInvoiceLoyaltyPointDeltasNestedInput>;
  userGrant?: Maybe<LoyaltyPointUserGrantUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateOneWithoutPointDeltaNestedInput>;
};

export type LoyaltyPointDeltaUpdateWithoutRelatedRedemptionHistoryEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutPointDeltasNestedInput>;
  grantingInvoice?: Maybe<InvoiceUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterUpdateOneWithoutRelatedInvoiceLoyaltyPointDeltasNestedInput>;
  userGrant?: Maybe<LoyaltyPointUserGrantUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneRequiredWithoutLoyaltyPointDeltasNestedInput>;
};

export type LoyaltyPointDeltaUpdateWithoutUserGrantInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutPointDeltasNestedInput>;
  grantingInvoice?: Maybe<InvoiceUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterUpdateOneWithoutRelatedInvoiceLoyaltyPointDeltasNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneRequiredWithoutLoyaltyPointDeltasNestedInput>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateOneWithoutPointDeltaNestedInput>;
};

export type LoyaltyPointDeltaUpsertWithWhereUniqueWithoutAccountInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  update: LoyaltyPointDeltaUncheckedUpdateWithoutAccountInput;
  create: LoyaltyPointDeltaUncheckedCreateWithoutAccountInput;
};

export type LoyaltyPointDeltaUpsertWithWhereUniqueWithoutGrantingInvoiceInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  update: LoyaltyPointDeltaUncheckedUpdateWithoutGrantingInvoiceInput;
  create: LoyaltyPointDeltaUncheckedCreateWithoutGrantingInvoiceInput;
};

export type LoyaltyPointDeltaUpsertWithWhereUniqueWithoutInvoicePointGranterInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  update: LoyaltyPointDeltaUncheckedUpdateWithoutInvoicePointGranterInput;
  create: LoyaltyPointDeltaUncheckedCreateWithoutInvoicePointGranterInput;
};

export type LoyaltyPointDeltaUpsertWithWhereUniqueWithoutRelatedClinicInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  update: LoyaltyPointDeltaUncheckedUpdateWithoutRelatedClinicInput;
  create: LoyaltyPointDeltaUncheckedCreateWithoutRelatedClinicInput;
};

export type LoyaltyPointDeltaUpsertWithWhereUniqueWithoutUserGrantInput = {
  where: LoyaltyPointDeltaWhereUniqueInput;
  update: LoyaltyPointDeltaUncheckedUpdateWithoutUserGrantInput;
  create: LoyaltyPointDeltaUncheckedCreateWithoutUserGrantInput;
};

export type LoyaltyPointDeltaUpsertWithoutRelatedRedemptionHistoryEntryInput = {
  update: LoyaltyPointDeltaUncheckedUpdateWithoutRelatedRedemptionHistoryEntryInput;
  create: LoyaltyPointDeltaUncheckedCreateWithoutRelatedRedemptionHistoryEntryInput;
};

export type LoyaltyPointDeltaWhereInput = {
  AND?: Maybe<Array<LoyaltyPointDeltaWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointDeltaWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointDeltaWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  source?: Maybe<EnumLoyaltyPointDeltaSourceFilter>;
  postedToAccount?: Maybe<BoolFilter>;
  points?: Maybe<IntFilter>;
  leftOverSpendDelta?: Maybe<IntFilter>;
  accountId?: Maybe<StringFilter>;
  grantingInvoiceId?: Maybe<StringNullableFilter>;
  invoicePointGranterId?: Maybe<StringNullableFilter>;
  userGrantId?: Maybe<StringNullableFilter>;
  relatedClinicId?: Maybe<StringFilter>;
  account?: Maybe<LoyaltyAccountWhereInput>;
  grantingInvoice?: Maybe<InvoiceWhereInput>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  userGrant?: Maybe<LoyaltyPointUserGrantWhereInput>;
  relatedClinic?: Maybe<ClinicWhereInput>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
};

export type LoyaltyPointDeltaWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  grantingInvoiceId_invoicePointGranterId?: Maybe<LoyaltyPointDeltaGrantingInvoiceIdInvoicePointGranterIdCompoundUniqueInput>;
};

export type LoyaltyPointGrantByUser = {
  loyaltyAccountId: Scalars['String'];
  points: Scalars['Int'];
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingService = {
  __typename?: 'LoyaltyPointGrantingService';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  serviceId: Scalars['String'];
  granterId: Scalars['String'];
  service: Service;
  granter: LoyaltyInvoicePointGranter;
};

export type LoyaltyPointGrantingServiceCategory = {
  __typename?: 'LoyaltyPointGrantingServiceCategory';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  serviceCategoryId: Scalars['String'];
  granterId: Scalars['String'];
  serviceCategory: ServiceCategory;
  granter: LoyaltyInvoicePointGranter;
};

export type LoyaltyPointGrantingServiceCategoryCountAggregateOutputType = {
  __typename?: 'LoyaltyPointGrantingServiceCategoryCountAggregateOutputType';
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  serviceCategoryId: Scalars['Int'];
  granterId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LoyaltyPointGrantingServiceCategoryCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
};

export type LoyaltyPointGrantingServiceCategoryCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceCategory: ServiceCategoryCreateNestedOneWithoutLoyaltyPointGrantingServiceCategoriesInput;
  granter: LoyaltyInvoicePointGranterCreateNestedOneWithoutGrantingServiceCategoriesInput;
};

export type LoyaltyPointGrantingServiceCategoryCreateManyGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceCategoryCreateManyGranterInputEnvelope = {
  data: Array<LoyaltyPointGrantingServiceCategoryCreateManyGranterInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointGrantingServiceCategoryCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId: Scalars['String'];
  granterId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceCategoryCreateManyServiceCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  granterId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceCategoryCreateManyServiceCategoryInputEnvelope = {
  data: Array<LoyaltyPointGrantingServiceCategoryCreateManyServiceCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutGranterInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutGranterInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyGranterInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
};

export type LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutServiceCategoryInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyServiceCategoryInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
};

export type LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutGranterInput = {
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
  create: LoyaltyPointGrantingServiceCategoryUncheckedCreateWithoutGranterInput;
};

export type LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutServiceCategoryInput = {
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
  create: LoyaltyPointGrantingServiceCategoryUncheckedCreateWithoutServiceCategoryInput;
};

export type LoyaltyPointGrantingServiceCategoryCreateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceCategory: ServiceCategoryCreateNestedOneWithoutLoyaltyPointGrantingServiceCategoriesInput;
};

export type LoyaltyPointGrantingServiceCategoryCreateWithoutServiceCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  granter: LoyaltyInvoicePointGranterCreateNestedOneWithoutGrantingServiceCategoriesInput;
};

export type LoyaltyPointGrantingServiceCategoryListRelationFilter = {
  every?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
  some?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
  none?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
};

export type LoyaltyPointGrantingServiceCategoryMaxAggregateOutputType = {
  __typename?: 'LoyaltyPointGrantingServiceCategoryMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  granterId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceCategoryMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
};

export type LoyaltyPointGrantingServiceCategoryMinAggregateOutputType = {
  __typename?: 'LoyaltyPointGrantingServiceCategoryMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  granterId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceCategoryMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
};

export type LoyaltyPointGrantingServiceCategoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyPointGrantingServiceCategoryOrderByRelevanceFieldEnum {
  ServiceCategoryId = 'serviceCategoryId',
  GranterId = 'granterId'
}

export type LoyaltyPointGrantingServiceCategoryOrderByRelevanceInput = {
  fields: Array<LoyaltyPointGrantingServiceCategoryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LoyaltyPointGrantingServiceCategoryOrderByWithAggregationInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
  _count?: Maybe<LoyaltyPointGrantingServiceCategoryCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyPointGrantingServiceCategoryMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyPointGrantingServiceCategoryMinOrderByAggregateInput>;
};

export type LoyaltyPointGrantingServiceCategoryOrderByWithRelationAndSearchRelevanceInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
  serviceCategory?: Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  granter?: Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<LoyaltyPointGrantingServiceCategoryOrderByRelevanceInput>;
};

export enum LoyaltyPointGrantingServiceCategoryScalarFieldEnum {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ServiceCategoryId = 'serviceCategoryId',
  GranterId = 'granterId'
}

export type LoyaltyPointGrantingServiceCategoryScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceCategoryId?: Maybe<StringFilter>;
  granterId?: Maybe<StringFilter>;
};

export type LoyaltyPointGrantingServiceCategoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  serviceCategoryId?: Maybe<StringWithAggregatesFilter>;
  granterId?: Maybe<StringWithAggregatesFilter>;
};

export type LoyaltyPointGrantingServiceCategoryServiceCategoryIdGranterIdCompoundUniqueInput = {
  serviceCategoryId: Scalars['String'];
  granterId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceCategoryUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId: Scalars['String'];
  granterId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutGranterInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutGranterInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyGranterInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyServiceCategoryInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedCreateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceCategoryUncheckedCreateWithoutServiceCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  granterId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  granterId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  granterId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutGranterNestedInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutGranterInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutGranterInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpsertWithWhereUniqueWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyGranterInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateWithWhereUniqueWithoutGranterInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateManyWithWhereWithoutGranterInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereInput>>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutGrantingServiceCategoriesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpsertWithWhereUniqueWithoutServiceCategoryInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyServiceCategoryInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateWithWhereUniqueWithoutServiceCategoryInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateManyWithWhereWithoutServiceCategoryInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereInput>>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateWithoutServiceCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceCategoryUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneRequiredWithoutLoyaltyPointGrantingServiceCategoriesNestedInput>;
  granter?: Maybe<LoyaltyInvoicePointGranterUpdateOneRequiredWithoutGrantingServiceCategoriesNestedInput>;
};

export type LoyaltyPointGrantingServiceCategoryUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryUpdateManyWithWhereWithoutGranterInput = {
  where: LoyaltyPointGrantingServiceCategoryScalarWhereInput;
  data: LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutGrantingServiceCategoriesInput;
};

export type LoyaltyPointGrantingServiceCategoryUpdateManyWithWhereWithoutServiceCategoryInput = {
  where: LoyaltyPointGrantingServiceCategoryScalarWhereInput;
  data: LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutLoyaltyPointGrantingServiceCategoriesInput;
};

export type LoyaltyPointGrantingServiceCategoryUpdateManyWithoutGranterNestedInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutGranterInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutGranterInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpsertWithWhereUniqueWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyGranterInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateWithWhereUniqueWithoutGranterInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateManyWithWhereWithoutGranterInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereInput>>;
};

export type LoyaltyPointGrantingServiceCategoryUpdateManyWithoutServiceCategoryNestedInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpsertWithWhereUniqueWithoutServiceCategoryInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyServiceCategoryInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateWithWhereUniqueWithoutServiceCategoryInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateManyWithWhereWithoutServiceCategoryInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereInput>>;
};

export type LoyaltyPointGrantingServiceCategoryUpdateWithWhereUniqueWithoutGranterInput = {
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
  data: LoyaltyPointGrantingServiceCategoryUncheckedUpdateWithoutGranterInput;
};

export type LoyaltyPointGrantingServiceCategoryUpdateWithWhereUniqueWithoutServiceCategoryInput = {
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
  data: LoyaltyPointGrantingServiceCategoryUncheckedUpdateWithoutServiceCategoryInput;
};

export type LoyaltyPointGrantingServiceCategoryUpdateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneRequiredWithoutLoyaltyPointGrantingServiceCategoriesNestedInput>;
};

export type LoyaltyPointGrantingServiceCategoryUpdateWithoutServiceCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  granter?: Maybe<LoyaltyInvoicePointGranterUpdateOneRequiredWithoutGrantingServiceCategoriesNestedInput>;
};

export type LoyaltyPointGrantingServiceCategoryUpsertWithWhereUniqueWithoutGranterInput = {
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
  update: LoyaltyPointGrantingServiceCategoryUncheckedUpdateWithoutGranterInput;
  create: LoyaltyPointGrantingServiceCategoryUncheckedCreateWithoutGranterInput;
};

export type LoyaltyPointGrantingServiceCategoryUpsertWithWhereUniqueWithoutServiceCategoryInput = {
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
  update: LoyaltyPointGrantingServiceCategoryUncheckedUpdateWithoutServiceCategoryInput;
  create: LoyaltyPointGrantingServiceCategoryUncheckedCreateWithoutServiceCategoryInput;
};

export type LoyaltyPointGrantingServiceCategoryWhereInput = {
  AND?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceCategoryId?: Maybe<StringFilter>;
  granterId?: Maybe<StringFilter>;
  serviceCategory?: Maybe<ServiceCategoryWhereInput>;
  granter?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};

export type LoyaltyPointGrantingServiceCategoryWhereUniqueInput = {
  serviceCategoryId_granterId?: Maybe<LoyaltyPointGrantingServiceCategoryServiceCategoryIdGranterIdCompoundUniqueInput>;
};

export type LoyaltyPointGrantingServiceCountAggregateOutputType = {
  __typename?: 'LoyaltyPointGrantingServiceCountAggregateOutputType';
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  serviceId: Scalars['Int'];
  granterId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LoyaltyPointGrantingServiceCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
};

export type LoyaltyPointGrantingServiceCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service: ServiceCreateNestedOneWithoutLoyaltyPointGrantingServicesInput;
  granter: LoyaltyInvoicePointGranterCreateNestedOneWithoutGrantingServicesInput;
};

export type LoyaltyPointGrantingServiceCreateManyGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceCreateManyGranterInputEnvelope = {
  data: Array<LoyaltyPointGrantingServiceCreateManyGranterInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointGrantingServiceCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  granterId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceCreateManyServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  granterId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceCreateManyServiceInputEnvelope = {
  data: Array<LoyaltyPointGrantingServiceCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointGrantingServiceCreateNestedManyWithoutGranterInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutGranterInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyGranterInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
};

export type LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
};

export type LoyaltyPointGrantingServiceCreateOrConnectWithoutGranterInput = {
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
  create: LoyaltyPointGrantingServiceUncheckedCreateWithoutGranterInput;
};

export type LoyaltyPointGrantingServiceCreateOrConnectWithoutServiceInput = {
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
  create: LoyaltyPointGrantingServiceUncheckedCreateWithoutServiceInput;
};

export type LoyaltyPointGrantingServiceCreateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service: ServiceCreateNestedOneWithoutLoyaltyPointGrantingServicesInput;
};

export type LoyaltyPointGrantingServiceCreateWithoutServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  granter: LoyaltyInvoicePointGranterCreateNestedOneWithoutGrantingServicesInput;
};

export type LoyaltyPointGrantingServiceListRelationFilter = {
  every?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
  some?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
  none?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
};

export type LoyaltyPointGrantingServiceMaxAggregateOutputType = {
  __typename?: 'LoyaltyPointGrantingServiceMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  granterId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
};

export type LoyaltyPointGrantingServiceMinAggregateOutputType = {
  __typename?: 'LoyaltyPointGrantingServiceMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  granterId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
};

export type LoyaltyPointGrantingServiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyPointGrantingServiceOrderByRelevanceFieldEnum {
  ServiceId = 'serviceId',
  GranterId = 'granterId'
}

export type LoyaltyPointGrantingServiceOrderByRelevanceInput = {
  fields: Array<LoyaltyPointGrantingServiceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LoyaltyPointGrantingServiceOrderByWithAggregationInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
  _count?: Maybe<LoyaltyPointGrantingServiceCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyPointGrantingServiceMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyPointGrantingServiceMinOrderByAggregateInput>;
};

export type LoyaltyPointGrantingServiceOrderByWithRelationAndSearchRelevanceInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  granter?: Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<LoyaltyPointGrantingServiceOrderByRelevanceInput>;
};

export enum LoyaltyPointGrantingServiceScalarFieldEnum {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ServiceId = 'serviceId',
  GranterId = 'granterId'
}

export type LoyaltyPointGrantingServiceScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceId?: Maybe<StringFilter>;
  granterId?: Maybe<StringFilter>;
};

export type LoyaltyPointGrantingServiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  serviceId?: Maybe<StringWithAggregatesFilter>;
  granterId?: Maybe<StringWithAggregatesFilter>;
};

export type LoyaltyPointGrantingServiceServiceIdGranterIdCompoundUniqueInput = {
  serviceId: Scalars['String'];
  granterId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  granterId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutGranterInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutGranterInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyGranterInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
};

export type LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
};

export type LoyaltyPointGrantingServiceUncheckedCreateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceUncheckedCreateWithoutServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  granterId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  granterId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  granterId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutGranterNestedInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutGranterInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutGranterInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceUpsertWithWhereUniqueWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyGranterInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceUpdateWithWhereUniqueWithoutGranterInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceUpdateManyWithWhereWithoutGranterInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereInput>>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutGrantingServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutLoyaltyPointGrantingServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereInput>>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateWithoutServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingServiceUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutLoyaltyPointGrantingServicesNestedInput>;
  granter?: Maybe<LoyaltyInvoicePointGranterUpdateOneRequiredWithoutGrantingServicesNestedInput>;
};

export type LoyaltyPointGrantingServiceUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceUpdateManyWithWhereWithoutGranterInput = {
  where: LoyaltyPointGrantingServiceScalarWhereInput;
  data: LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutGrantingServicesInput;
};

export type LoyaltyPointGrantingServiceUpdateManyWithWhereWithoutServiceInput = {
  where: LoyaltyPointGrantingServiceScalarWhereInput;
  data: LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutLoyaltyPointGrantingServicesInput;
};

export type LoyaltyPointGrantingServiceUpdateManyWithoutGranterNestedInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutGranterInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutGranterInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceUpsertWithWhereUniqueWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyGranterInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceUpdateWithWhereUniqueWithoutGranterInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceUpdateManyWithWhereWithoutGranterInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereInput>>;
};

export type LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput = {
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereInput>>;
};

export type LoyaltyPointGrantingServiceUpdateWithWhereUniqueWithoutGranterInput = {
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
  data: LoyaltyPointGrantingServiceUncheckedUpdateWithoutGranterInput;
};

export type LoyaltyPointGrantingServiceUpdateWithWhereUniqueWithoutServiceInput = {
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
  data: LoyaltyPointGrantingServiceUncheckedUpdateWithoutServiceInput;
};

export type LoyaltyPointGrantingServiceUpdateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutLoyaltyPointGrantingServicesNestedInput>;
};

export type LoyaltyPointGrantingServiceUpdateWithoutServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  granter?: Maybe<LoyaltyInvoicePointGranterUpdateOneRequiredWithoutGrantingServicesNestedInput>;
};

export type LoyaltyPointGrantingServiceUpsertWithWhereUniqueWithoutGranterInput = {
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
  update: LoyaltyPointGrantingServiceUncheckedUpdateWithoutGranterInput;
  create: LoyaltyPointGrantingServiceUncheckedCreateWithoutGranterInput;
};

export type LoyaltyPointGrantingServiceUpsertWithWhereUniqueWithoutServiceInput = {
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
  update: LoyaltyPointGrantingServiceUncheckedUpdateWithoutServiceInput;
  create: LoyaltyPointGrantingServiceUncheckedCreateWithoutServiceInput;
};

export type LoyaltyPointGrantingServiceWhereInput = {
  AND?: Maybe<Array<LoyaltyPointGrantingServiceWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointGrantingServiceWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointGrantingServiceWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceId?: Maybe<StringFilter>;
  granterId?: Maybe<StringFilter>;
  service?: Maybe<ServiceWhereInput>;
  granter?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};

export type LoyaltyPointGrantingServiceWhereUniqueInput = {
  serviceId_granterId?: Maybe<LoyaltyPointGrantingServiceServiceIdGranterIdCompoundUniqueInput>;
};

export type LoyaltyPointUserGrant = {
  __typename?: 'LoyaltyPointUserGrant';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  grantingUserId: Scalars['String'];
  amount: Scalars['Int'];
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  grantingUser: User;
  loyaltyPointDeltas: Array<LoyaltyPointDelta>;
  _count: LoyaltyPointUserGrantCountOutputType;
};


export type LoyaltyPointUserGrantLoyaltyPointDeltasArgs = {
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
  orderBy?: Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyPointDeltaScalarFieldEnum>;
};

export type LoyaltyPointUserGrantAvgAggregateOutputType = {
  __typename?: 'LoyaltyPointUserGrantAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type LoyaltyPointUserGrantAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type LoyaltyPointUserGrantCountAggregateOutputType = {
  __typename?: 'LoyaltyPointUserGrantCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  grantingUserId: Scalars['Int'];
  amount: Scalars['Int'];
  publicNotes: Scalars['Int'];
  internalNotes: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LoyaltyPointUserGrantCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  grantingUserId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  publicNotes?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
};

export type LoyaltyPointUserGrantCountOutputType = {
  __typename?: 'LoyaltyPointUserGrantCountOutputType';
  loyaltyPointDeltas: Scalars['Int'];
};

export type LoyaltyPointUserGrantCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  grantingUser: UserCreateNestedOneWithoutLoyaltyPointUserGrantsInput;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutUserGrantInput>;
};

export type LoyaltyPointUserGrantCreateManyGrantingUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
};

export type LoyaltyPointUserGrantCreateManyGrantingUserInputEnvelope = {
  data: Array<LoyaltyPointUserGrantCreateManyGrantingUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointUserGrantCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  grantingUserId: Scalars['String'];
  amount: Scalars['Int'];
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
};

export type LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput = {
  create?: Maybe<Array<LoyaltyPointUserGrantCreateWithoutGrantingUserInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointUserGrantCreateOrConnectWithoutGrantingUserInput>>;
  createMany?: Maybe<LoyaltyPointUserGrantCreateManyGrantingUserInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
};

export type LoyaltyPointUserGrantCreateNestedOneWithoutLoyaltyPointDeltasInput = {
  create?: Maybe<LoyaltyPointUserGrantUncheckedCreateWithoutLoyaltyPointDeltasInput>;
  connectOrCreate?: Maybe<LoyaltyPointUserGrantCreateOrConnectWithoutLoyaltyPointDeltasInput>;
  connect?: Maybe<LoyaltyPointUserGrantWhereUniqueInput>;
};

export type LoyaltyPointUserGrantCreateOrConnectWithoutGrantingUserInput = {
  where: LoyaltyPointUserGrantWhereUniqueInput;
  create: LoyaltyPointUserGrantUncheckedCreateWithoutGrantingUserInput;
};

export type LoyaltyPointUserGrantCreateOrConnectWithoutLoyaltyPointDeltasInput = {
  where: LoyaltyPointUserGrantWhereUniqueInput;
  create: LoyaltyPointUserGrantUncheckedCreateWithoutLoyaltyPointDeltasInput;
};

export type LoyaltyPointUserGrantCreateWithoutGrantingUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutUserGrantInput>;
};

export type LoyaltyPointUserGrantCreateWithoutLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  grantingUser: UserCreateNestedOneWithoutLoyaltyPointUserGrantsInput;
};

export type LoyaltyPointUserGrantListRelationFilter = {
  every?: Maybe<LoyaltyPointUserGrantWhereInput>;
  some?: Maybe<LoyaltyPointUserGrantWhereInput>;
  none?: Maybe<LoyaltyPointUserGrantWhereInput>;
};

export type LoyaltyPointUserGrantMaxAggregateOutputType = {
  __typename?: 'LoyaltyPointUserGrantMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  grantingUserId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
};

export type LoyaltyPointUserGrantMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  grantingUserId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  publicNotes?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
};

export type LoyaltyPointUserGrantMinAggregateOutputType = {
  __typename?: 'LoyaltyPointUserGrantMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  grantingUserId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
};

export type LoyaltyPointUserGrantMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  grantingUserId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  publicNotes?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
};

export type LoyaltyPointUserGrantOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyPointUserGrantOrderByRelevanceFieldEnum {
  Id = 'id',
  GrantingUserId = 'grantingUserId',
  PublicNotes = 'publicNotes',
  InternalNotes = 'internalNotes'
}

export type LoyaltyPointUserGrantOrderByRelevanceInput = {
  fields: Array<LoyaltyPointUserGrantOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LoyaltyPointUserGrantOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  grantingUserId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  publicNotes?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  _count?: Maybe<LoyaltyPointUserGrantCountOrderByAggregateInput>;
  _avg?: Maybe<LoyaltyPointUserGrantAvgOrderByAggregateInput>;
  _max?: Maybe<LoyaltyPointUserGrantMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyPointUserGrantMinOrderByAggregateInput>;
  _sum?: Maybe<LoyaltyPointUserGrantSumOrderByAggregateInput>;
};

export type LoyaltyPointUserGrantOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  grantingUserId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  publicNotes?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  grantingUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaOrderByRelationAggregateInput>;
  _relevance?: Maybe<LoyaltyPointUserGrantOrderByRelevanceInput>;
};

export type LoyaltyPointUserGrantRelationFilter = {
  is?: Maybe<LoyaltyPointUserGrantWhereInput>;
  isNot?: Maybe<LoyaltyPointUserGrantWhereInput>;
};

export enum LoyaltyPointUserGrantScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  GrantingUserId = 'grantingUserId',
  Amount = 'amount',
  PublicNotes = 'publicNotes',
  InternalNotes = 'internalNotes'
}

export type LoyaltyPointUserGrantScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyPointUserGrantScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointUserGrantScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointUserGrantScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  grantingUserId?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  publicNotes?: Maybe<StringNullableFilter>;
  internalNotes?: Maybe<StringNullableFilter>;
};

export type LoyaltyPointUserGrantScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyPointUserGrantScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyPointUserGrantScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyPointUserGrantScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  grantingUserId?: Maybe<StringWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  publicNotes?: Maybe<StringNullableWithAggregatesFilter>;
  internalNotes?: Maybe<StringNullableWithAggregatesFilter>;
};

export type LoyaltyPointUserGrantSumAggregateOutputType = {
  __typename?: 'LoyaltyPointUserGrantSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
};

export type LoyaltyPointUserGrantSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type LoyaltyPointUserGrantUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  grantingUserId: Scalars['String'];
  amount: Scalars['Int'];
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutUserGrantInput>;
};

export type LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput = {
  create?: Maybe<Array<LoyaltyPointUserGrantCreateWithoutGrantingUserInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointUserGrantCreateOrConnectWithoutGrantingUserInput>>;
  createMany?: Maybe<LoyaltyPointUserGrantCreateManyGrantingUserInputEnvelope>;
  connect?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
};

export type LoyaltyPointUserGrantUncheckedCreateWithoutGrantingUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutUserGrantInput>;
};

export type LoyaltyPointUserGrantUncheckedCreateWithoutLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  grantingUserId: Scalars['String'];
  amount: Scalars['Int'];
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
};

export type LoyaltyPointUserGrantUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  grantingUserId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutUserGrantNestedInput>;
};

export type LoyaltyPointUserGrantUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  grantingUserId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
};

export type LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput = {
  create?: Maybe<Array<LoyaltyPointUserGrantCreateWithoutGrantingUserInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointUserGrantCreateOrConnectWithoutGrantingUserInput>>;
  upsert?: Maybe<Array<LoyaltyPointUserGrantUpsertWithWhereUniqueWithoutGrantingUserInput>>;
  createMany?: Maybe<LoyaltyPointUserGrantCreateManyGrantingUserInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointUserGrantUpdateWithWhereUniqueWithoutGrantingUserInput>>;
  updateMany?: Maybe<Array<LoyaltyPointUserGrantUpdateManyWithWhereWithoutGrantingUserInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointUserGrantScalarWhereInput>>;
};

export type LoyaltyPointUserGrantUncheckedUpdateManyWithoutLoyaltyPointUserGrantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
};

export type LoyaltyPointUserGrantUncheckedUpdateWithoutGrantingUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutUserGrantNestedInput>;
};

export type LoyaltyPointUserGrantUncheckedUpdateWithoutLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  grantingUserId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
};

export type LoyaltyPointUserGrantUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  grantingUser?: Maybe<UserUpdateOneRequiredWithoutLoyaltyPointUserGrantsNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutUserGrantNestedInput>;
};

export type LoyaltyPointUserGrantUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
};

export type LoyaltyPointUserGrantUpdateManyWithWhereWithoutGrantingUserInput = {
  where: LoyaltyPointUserGrantScalarWhereInput;
  data: LoyaltyPointUserGrantUncheckedUpdateManyWithoutLoyaltyPointUserGrantsInput;
};

export type LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput = {
  create?: Maybe<Array<LoyaltyPointUserGrantCreateWithoutGrantingUserInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointUserGrantCreateOrConnectWithoutGrantingUserInput>>;
  upsert?: Maybe<Array<LoyaltyPointUserGrantUpsertWithWhereUniqueWithoutGrantingUserInput>>;
  createMany?: Maybe<LoyaltyPointUserGrantCreateManyGrantingUserInputEnvelope>;
  set?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointUserGrantUpdateWithWhereUniqueWithoutGrantingUserInput>>;
  updateMany?: Maybe<Array<LoyaltyPointUserGrantUpdateManyWithWhereWithoutGrantingUserInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointUserGrantScalarWhereInput>>;
};

export type LoyaltyPointUserGrantUpdateOneWithoutLoyaltyPointDeltasNestedInput = {
  create?: Maybe<LoyaltyPointUserGrantUncheckedCreateWithoutLoyaltyPointDeltasInput>;
  connectOrCreate?: Maybe<LoyaltyPointUserGrantCreateOrConnectWithoutLoyaltyPointDeltasInput>;
  upsert?: Maybe<LoyaltyPointUserGrantUpsertWithoutLoyaltyPointDeltasInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<LoyaltyPointUserGrantWhereUniqueInput>;
  update?: Maybe<LoyaltyPointUserGrantUncheckedUpdateWithoutLoyaltyPointDeltasInput>;
};

export type LoyaltyPointUserGrantUpdateWithWhereUniqueWithoutGrantingUserInput = {
  where: LoyaltyPointUserGrantWhereUniqueInput;
  data: LoyaltyPointUserGrantUncheckedUpdateWithoutGrantingUserInput;
};

export type LoyaltyPointUserGrantUpdateWithoutGrantingUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutUserGrantNestedInput>;
};

export type LoyaltyPointUserGrantUpdateWithoutLoyaltyPointDeltasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Int']>;
  publicNotes?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  grantingUser?: Maybe<UserUpdateOneRequiredWithoutLoyaltyPointUserGrantsNestedInput>;
};

export type LoyaltyPointUserGrantUpsertWithWhereUniqueWithoutGrantingUserInput = {
  where: LoyaltyPointUserGrantWhereUniqueInput;
  update: LoyaltyPointUserGrantUncheckedUpdateWithoutGrantingUserInput;
  create: LoyaltyPointUserGrantUncheckedCreateWithoutGrantingUserInput;
};

export type LoyaltyPointUserGrantUpsertWithoutLoyaltyPointDeltasInput = {
  update: LoyaltyPointUserGrantUncheckedUpdateWithoutLoyaltyPointDeltasInput;
  create: LoyaltyPointUserGrantUncheckedCreateWithoutLoyaltyPointDeltasInput;
};

export type LoyaltyPointUserGrantWhereInput = {
  AND?: Maybe<Array<LoyaltyPointUserGrantWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointUserGrantWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointUserGrantWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  grantingUserId?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  publicNotes?: Maybe<StringNullableFilter>;
  internalNotes?: Maybe<StringNullableFilter>;
  grantingUser?: Maybe<UserWhereInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaListRelationFilter>;
};

export type LoyaltyPointUserGrantWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoyaltyProgram = {
  __typename?: 'LoyaltyProgram';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: LoyaltyProgramStatus;
  description: Scalars['String'];
  centsPerPoint: Scalars['Int'];
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents: Scalars['Int'];
  allowOpenInvoices: Scalars['Boolean'];
  accounts: Array<LoyaltyAccount>;
  rewards: Array<LoyaltyReward>;
  statusHistories: Array<LoyaltyProgramStatusHistory>;
  pointGranters: Array<LoyaltyInvoicePointGranter>;
  participatingClinics: Array<Clinic>;
  loyaltyAccountHolders: Array<LoyaltyAccountHolder>;
  _count: LoyaltyProgramCountOutputType;
};


export type LoyaltyProgramAccountsArgs = {
  where?: Maybe<LoyaltyAccountWhereInput>;
  orderBy?: Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyAccountScalarFieldEnum>;
};


export type LoyaltyProgramRewardsArgs = {
  where?: Maybe<LoyaltyRewardWhereInput>;
  orderBy?: Maybe<LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyRewardWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyRewardScalarFieldEnum>;
};


export type LoyaltyProgramStatusHistoriesArgs = {
  where?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
  orderBy?: Maybe<LoyaltyProgramStatusHistoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyProgramStatusHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyProgramStatusHistoryScalarFieldEnum>;
};


export type LoyaltyProgramPointGrantersArgs = {
  where?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  orderBy?: Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyInvoicePointGranterScalarFieldEnum>;
};


export type LoyaltyProgramParticipatingClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};


export type LoyaltyProgramLoyaltyAccountHoldersArgs = {
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
  orderBy?: Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyAccountHolderScalarFieldEnum>;
};

export type LoyaltyProgramAvgAggregateOutputType = {
  __typename?: 'LoyaltyProgramAvgAggregateOutputType';
  centsPerPoint?: Maybe<Scalars['Float']>;
  minimumTransactionCents?: Maybe<Scalars['Float']>;
};

export type LoyaltyProgramAvgOrderByAggregateInput = {
  centsPerPoint?: Maybe<SortOrder>;
  minimumTransactionCents?: Maybe<SortOrder>;
};

export type LoyaltyProgramCountAggregateOutputType = {
  __typename?: 'LoyaltyProgramCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  status: Scalars['Int'];
  description: Scalars['Int'];
  centsPerPoint: Scalars['Int'];
  allowSpendCarryOver: Scalars['Int'];
  minimumTransactionCents: Scalars['Int'];
  allowOpenInvoices: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LoyaltyProgramCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  centsPerPoint?: Maybe<SortOrder>;
  allowSpendCarryOver?: Maybe<SortOrder>;
  minimumTransactionCents?: Maybe<SortOrder>;
  allowOpenInvoices?: Maybe<SortOrder>;
};

export type LoyaltyProgramCountOutputType = {
  __typename?: 'LoyaltyProgramCountOutputType';
  accounts: Scalars['Int'];
  rewards: Scalars['Int'];
  statusHistories: Scalars['Int'];
  pointGranters: Scalars['Int'];
  participatingClinics: Scalars['Int'];
  loyaltyAccountHolders: Scalars['Int'];
};

export type LoyaltyProgramCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardCreateNestedManyWithoutProgramInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryCreateNestedManyWithoutProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterCreateNestedManyWithoutProgramInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutLoyaltyProgramInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutProgramInput>;
};

export type LoyaltyProgramCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyProgramCreateNestedOneWithoutAccountsInput = {
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutAccountsInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutAccountsInput>;
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
};

export type LoyaltyProgramCreateNestedOneWithoutLoyaltyAccountHoldersInput = {
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutLoyaltyAccountHoldersInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutLoyaltyAccountHoldersInput>;
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
};

export type LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput = {
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutParticipatingClinicsInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutParticipatingClinicsInput>;
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
};

export type LoyaltyProgramCreateNestedOneWithoutPointGrantersInput = {
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutPointGrantersInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutPointGrantersInput>;
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
};

export type LoyaltyProgramCreateNestedOneWithoutRewardsInput = {
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutRewardsInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutRewardsInput>;
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
};

export type LoyaltyProgramCreateNestedOneWithoutStatusHistoriesInput = {
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutStatusHistoriesInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutStatusHistoriesInput>;
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
};

export type LoyaltyProgramCreateOrConnectWithoutAccountsInput = {
  where: LoyaltyProgramWhereUniqueInput;
  create: LoyaltyProgramUncheckedCreateWithoutAccountsInput;
};

export type LoyaltyProgramCreateOrConnectWithoutLoyaltyAccountHoldersInput = {
  where: LoyaltyProgramWhereUniqueInput;
  create: LoyaltyProgramUncheckedCreateWithoutLoyaltyAccountHoldersInput;
};

export type LoyaltyProgramCreateOrConnectWithoutParticipatingClinicsInput = {
  where: LoyaltyProgramWhereUniqueInput;
  create: LoyaltyProgramUncheckedCreateWithoutParticipatingClinicsInput;
};

export type LoyaltyProgramCreateOrConnectWithoutPointGrantersInput = {
  where: LoyaltyProgramWhereUniqueInput;
  create: LoyaltyProgramUncheckedCreateWithoutPointGrantersInput;
};

export type LoyaltyProgramCreateOrConnectWithoutRewardsInput = {
  where: LoyaltyProgramWhereUniqueInput;
  create: LoyaltyProgramUncheckedCreateWithoutRewardsInput;
};

export type LoyaltyProgramCreateOrConnectWithoutStatusHistoriesInput = {
  where: LoyaltyProgramWhereUniqueInput;
  create: LoyaltyProgramUncheckedCreateWithoutStatusHistoriesInput;
};

export type LoyaltyProgramCreateWithoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  rewards?: Maybe<LoyaltyRewardCreateNestedManyWithoutProgramInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryCreateNestedManyWithoutProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterCreateNestedManyWithoutProgramInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutLoyaltyProgramInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutProgramInput>;
};

export type LoyaltyProgramCreateWithoutLoyaltyAccountHoldersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardCreateNestedManyWithoutProgramInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryCreateNestedManyWithoutProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterCreateNestedManyWithoutProgramInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutLoyaltyProgramInput>;
};

export type LoyaltyProgramCreateWithoutParticipatingClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardCreateNestedManyWithoutProgramInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryCreateNestedManyWithoutProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterCreateNestedManyWithoutProgramInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutProgramInput>;
};

export type LoyaltyProgramCreateWithoutPointGrantersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardCreateNestedManyWithoutProgramInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryCreateNestedManyWithoutProgramInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutLoyaltyProgramInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutProgramInput>;
};

export type LoyaltyProgramCreateWithoutRewardsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountCreateNestedManyWithoutProgramInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryCreateNestedManyWithoutProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterCreateNestedManyWithoutProgramInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutLoyaltyProgramInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutProgramInput>;
};

export type LoyaltyProgramCreateWithoutStatusHistoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardCreateNestedManyWithoutProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterCreateNestedManyWithoutProgramInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutLoyaltyProgramInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutProgramInput>;
};

export type LoyaltyProgramMaxAggregateOutputType = {
  __typename?: 'LoyaltyProgramMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyProgramMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  centsPerPoint?: Maybe<SortOrder>;
  allowSpendCarryOver?: Maybe<SortOrder>;
  minimumTransactionCents?: Maybe<SortOrder>;
  allowOpenInvoices?: Maybe<SortOrder>;
};

export type LoyaltyProgramMinAggregateOutputType = {
  __typename?: 'LoyaltyProgramMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyProgramMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  centsPerPoint?: Maybe<SortOrder>;
  allowSpendCarryOver?: Maybe<SortOrder>;
  minimumTransactionCents?: Maybe<SortOrder>;
  allowOpenInvoices?: Maybe<SortOrder>;
};

export enum LoyaltyProgramOrderByRelevanceFieldEnum {
  Id = 'id',
  Description = 'description'
}

export type LoyaltyProgramOrderByRelevanceInput = {
  fields: Array<LoyaltyProgramOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LoyaltyProgramOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  centsPerPoint?: Maybe<SortOrder>;
  allowSpendCarryOver?: Maybe<SortOrder>;
  minimumTransactionCents?: Maybe<SortOrder>;
  allowOpenInvoices?: Maybe<SortOrder>;
  _count?: Maybe<LoyaltyProgramCountOrderByAggregateInput>;
  _avg?: Maybe<LoyaltyProgramAvgOrderByAggregateInput>;
  _max?: Maybe<LoyaltyProgramMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyProgramMinOrderByAggregateInput>;
  _sum?: Maybe<LoyaltyProgramSumOrderByAggregateInput>;
};

export type LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  centsPerPoint?: Maybe<SortOrder>;
  allowSpendCarryOver?: Maybe<SortOrder>;
  minimumTransactionCents?: Maybe<SortOrder>;
  allowOpenInvoices?: Maybe<SortOrder>;
  accounts?: Maybe<LoyaltyAccountOrderByRelationAggregateInput>;
  rewards?: Maybe<LoyaltyRewardOrderByRelationAggregateInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryOrderByRelationAggregateInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterOrderByRelationAggregateInput>;
  participatingClinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderOrderByRelationAggregateInput>;
  _relevance?: Maybe<LoyaltyProgramOrderByRelevanceInput>;
};

export type LoyaltyProgramRelationFilter = {
  is?: Maybe<LoyaltyProgramWhereInput>;
  isNot?: Maybe<LoyaltyProgramWhereInput>;
};

export enum LoyaltyProgramScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  Description = 'description',
  CentsPerPoint = 'centsPerPoint',
  AllowSpendCarryOver = 'allowSpendCarryOver',
  MinimumTransactionCents = 'minimumTransactionCents',
  AllowOpenInvoices = 'allowOpenInvoices'
}

export type LoyaltyProgramScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyProgramScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyProgramScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyProgramScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumLoyaltyProgramStatusWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  centsPerPoint?: Maybe<IntWithAggregatesFilter>;
  allowSpendCarryOver?: Maybe<BoolWithAggregatesFilter>;
  minimumTransactionCents?: Maybe<IntWithAggregatesFilter>;
  allowOpenInvoices?: Maybe<BoolWithAggregatesFilter>;
};

export enum LoyaltyProgramStatus {
  Draft = 'Draft',
  Test = 'Test',
  Live = 'Live',
  Archived = 'Archived'
}

export type LoyaltyProgramStatusHistory = {
  __typename?: 'LoyaltyProgramStatusHistory';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  status: LoyaltyProgramStatus;
  programId: Scalars['String'];
  program: LoyaltyProgram;
};

export type LoyaltyProgramStatusHistoryCountAggregateOutputType = {
  __typename?: 'LoyaltyProgramStatusHistoryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  status: Scalars['Int'];
  programId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LoyaltyProgramStatusHistoryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
};

export type LoyaltyProgramStatusHistoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  program: LoyaltyProgramCreateNestedOneWithoutStatusHistoriesInput;
};

export type LoyaltyProgramStatusHistoryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  programId: Scalars['String'];
};

export type LoyaltyProgramStatusHistoryCreateManyProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
};

export type LoyaltyProgramStatusHistoryCreateManyProgramInputEnvelope = {
  data: Array<LoyaltyProgramStatusHistoryCreateManyProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyProgramStatusHistoryCreateNestedManyWithoutProgramInput = {
  create?: Maybe<Array<LoyaltyProgramStatusHistoryCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyProgramStatusHistoryCreateOrConnectWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyProgramStatusHistoryCreateManyProgramInputEnvelope>;
  connect?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
};

export type LoyaltyProgramStatusHistoryCreateOrConnectWithoutProgramInput = {
  where: LoyaltyProgramStatusHistoryWhereUniqueInput;
  create: LoyaltyProgramStatusHistoryUncheckedCreateWithoutProgramInput;
};

export type LoyaltyProgramStatusHistoryCreateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
};

export type LoyaltyProgramStatusHistoryListRelationFilter = {
  every?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
  some?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
  none?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
};

export type LoyaltyProgramStatusHistoryMaxAggregateOutputType = {
  __typename?: 'LoyaltyProgramStatusHistoryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  programId?: Maybe<Scalars['String']>;
};

export type LoyaltyProgramStatusHistoryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
};

export type LoyaltyProgramStatusHistoryMinAggregateOutputType = {
  __typename?: 'LoyaltyProgramStatusHistoryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  programId?: Maybe<Scalars['String']>;
};

export type LoyaltyProgramStatusHistoryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
};

export type LoyaltyProgramStatusHistoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyProgramStatusHistoryOrderByRelevanceFieldEnum {
  Id = 'id',
  ProgramId = 'programId'
}

export type LoyaltyProgramStatusHistoryOrderByRelevanceInput = {
  fields: Array<LoyaltyProgramStatusHistoryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LoyaltyProgramStatusHistoryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  _count?: Maybe<LoyaltyProgramStatusHistoryCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyProgramStatusHistoryMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyProgramStatusHistoryMinOrderByAggregateInput>;
};

export type LoyaltyProgramStatusHistoryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  program?: Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<LoyaltyProgramStatusHistoryOrderByRelevanceInput>;
};

export enum LoyaltyProgramStatusHistoryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Status = 'status',
  ProgramId = 'programId'
}

export type LoyaltyProgramStatusHistoryScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumLoyaltyProgramStatusFilter>;
  programId?: Maybe<StringFilter>;
};

export type LoyaltyProgramStatusHistoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumLoyaltyProgramStatusWithAggregatesFilter>;
  programId?: Maybe<StringWithAggregatesFilter>;
};

export type LoyaltyProgramStatusHistoryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  programId: Scalars['String'];
};

export type LoyaltyProgramStatusHistoryUncheckedCreateNestedManyWithoutProgramInput = {
  create?: Maybe<Array<LoyaltyProgramStatusHistoryCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyProgramStatusHistoryCreateOrConnectWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyProgramStatusHistoryCreateManyProgramInputEnvelope>;
  connect?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
};

export type LoyaltyProgramStatusHistoryUncheckedCreateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
};

export type LoyaltyProgramStatusHistoryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  programId?: Maybe<Scalars['String']>;
};

export type LoyaltyProgramStatusHistoryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  programId?: Maybe<Scalars['String']>;
};

export type LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutProgramNestedInput = {
  create?: Maybe<Array<LoyaltyProgramStatusHistoryCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyProgramStatusHistoryCreateOrConnectWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyProgramStatusHistoryUpsertWithWhereUniqueWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyProgramStatusHistoryCreateManyProgramInputEnvelope>;
  set?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyProgramStatusHistoryUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyProgramStatusHistoryUpdateManyWithWhereWithoutProgramInput>>;
  deleteMany?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereInput>>;
};

export type LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutStatusHistoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
};

export type LoyaltyProgramStatusHistoryUncheckedUpdateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
};

export type LoyaltyProgramStatusHistoryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutStatusHistoriesNestedInput>;
};

export type LoyaltyProgramStatusHistoryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
};

export type LoyaltyProgramStatusHistoryUpdateManyWithWhereWithoutProgramInput = {
  where: LoyaltyProgramStatusHistoryScalarWhereInput;
  data: LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutStatusHistoriesInput;
};

export type LoyaltyProgramStatusHistoryUpdateManyWithoutProgramNestedInput = {
  create?: Maybe<Array<LoyaltyProgramStatusHistoryCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyProgramStatusHistoryCreateOrConnectWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyProgramStatusHistoryUpsertWithWhereUniqueWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyProgramStatusHistoryCreateManyProgramInputEnvelope>;
  set?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyProgramStatusHistoryUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyProgramStatusHistoryUpdateManyWithWhereWithoutProgramInput>>;
  deleteMany?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereInput>>;
};

export type LoyaltyProgramStatusHistoryUpdateWithWhereUniqueWithoutProgramInput = {
  where: LoyaltyProgramStatusHistoryWhereUniqueInput;
  data: LoyaltyProgramStatusHistoryUncheckedUpdateWithoutProgramInput;
};

export type LoyaltyProgramStatusHistoryUpdateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
};

export type LoyaltyProgramStatusHistoryUpsertWithWhereUniqueWithoutProgramInput = {
  where: LoyaltyProgramStatusHistoryWhereUniqueInput;
  update: LoyaltyProgramStatusHistoryUncheckedUpdateWithoutProgramInput;
  create: LoyaltyProgramStatusHistoryUncheckedCreateWithoutProgramInput;
};

export type LoyaltyProgramStatusHistoryWhereInput = {
  AND?: Maybe<Array<LoyaltyProgramStatusHistoryWhereInput>>;
  OR?: Maybe<Array<LoyaltyProgramStatusHistoryWhereInput>>;
  NOT?: Maybe<Array<LoyaltyProgramStatusHistoryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumLoyaltyProgramStatusFilter>;
  programId?: Maybe<StringFilter>;
  program?: Maybe<LoyaltyProgramWhereInput>;
};

export type LoyaltyProgramStatusHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoyaltyProgramSubscription = {
  __typename?: 'LoyaltyProgramSubscription';
  node: LoyaltyProgram;
  mutation: MutationType;
};

export type LoyaltyProgramSumAggregateOutputType = {
  __typename?: 'LoyaltyProgramSumAggregateOutputType';
  centsPerPoint?: Maybe<Scalars['Int']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
};

export type LoyaltyProgramSumOrderByAggregateInput = {
  centsPerPoint?: Maybe<SortOrder>;
  minimumTransactionCents?: Maybe<SortOrder>;
};

export type LoyaltyProgramUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUncheckedCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedCreateNestedManyWithoutProgramInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedCreateNestedManyWithoutProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateNestedManyWithoutProgramInput>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutLoyaltyProgramInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutProgramInput>;
};

export type LoyaltyProgramUncheckedCreateWithoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  rewards?: Maybe<LoyaltyRewardUncheckedCreateNestedManyWithoutProgramInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedCreateNestedManyWithoutProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateNestedManyWithoutProgramInput>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutLoyaltyProgramInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutProgramInput>;
};

export type LoyaltyProgramUncheckedCreateWithoutLoyaltyAccountHoldersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUncheckedCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedCreateNestedManyWithoutProgramInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedCreateNestedManyWithoutProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateNestedManyWithoutProgramInput>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutLoyaltyProgramInput>;
};

export type LoyaltyProgramUncheckedCreateWithoutParticipatingClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUncheckedCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedCreateNestedManyWithoutProgramInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedCreateNestedManyWithoutProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateNestedManyWithoutProgramInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutProgramInput>;
};

export type LoyaltyProgramUncheckedCreateWithoutPointGrantersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUncheckedCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedCreateNestedManyWithoutProgramInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedCreateNestedManyWithoutProgramInput>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutLoyaltyProgramInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutProgramInput>;
};

export type LoyaltyProgramUncheckedCreateWithoutRewardsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUncheckedCreateNestedManyWithoutProgramInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedCreateNestedManyWithoutProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateNestedManyWithoutProgramInput>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutLoyaltyProgramInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutProgramInput>;
};

export type LoyaltyProgramUncheckedCreateWithoutStatusHistoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyProgramStatus;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver: Scalars['Boolean'];
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUncheckedCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedCreateNestedManyWithoutProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateNestedManyWithoutProgramInput>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutLoyaltyProgramInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutProgramInput>;
};

export type LoyaltyProgramUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUncheckedUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedUpdateManyWithoutProgramNestedInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutProgramNestedInput>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutLoyaltyProgramNestedInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutProgramNestedInput>;
};

export type LoyaltyProgramUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyProgramUncheckedUpdateWithoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  rewards?: Maybe<LoyaltyRewardUncheckedUpdateManyWithoutProgramNestedInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutProgramNestedInput>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutLoyaltyProgramNestedInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutProgramNestedInput>;
};

export type LoyaltyProgramUncheckedUpdateWithoutLoyaltyAccountHoldersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUncheckedUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedUpdateManyWithoutProgramNestedInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutProgramNestedInput>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutLoyaltyProgramNestedInput>;
};

export type LoyaltyProgramUncheckedUpdateWithoutParticipatingClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUncheckedUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedUpdateManyWithoutProgramNestedInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutProgramNestedInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutProgramNestedInput>;
};

export type LoyaltyProgramUncheckedUpdateWithoutPointGrantersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUncheckedUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedUpdateManyWithoutProgramNestedInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutProgramNestedInput>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutLoyaltyProgramNestedInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutProgramNestedInput>;
};

export type LoyaltyProgramUncheckedUpdateWithoutRewardsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUncheckedUpdateManyWithoutProgramNestedInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutProgramNestedInput>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutLoyaltyProgramNestedInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutProgramNestedInput>;
};

export type LoyaltyProgramUncheckedUpdateWithoutStatusHistoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUncheckedUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedUpdateManyWithoutProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutProgramNestedInput>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutLoyaltyProgramNestedInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutProgramNestedInput>;
};

export type LoyaltyProgramUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUpdateManyWithoutProgramNestedInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUpdateManyWithoutProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUpdateManyWithoutProgramNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutLoyaltyProgramNestedInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutProgramNestedInput>;
};

export type LoyaltyProgramUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyProgramUpdateOneRequiredWithoutAccountsNestedInput = {
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutAccountsInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutAccountsInput>;
  upsert?: Maybe<LoyaltyProgramUpsertWithoutAccountsInput>;
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  update?: Maybe<LoyaltyProgramUncheckedUpdateWithoutAccountsInput>;
};

export type LoyaltyProgramUpdateOneRequiredWithoutLoyaltyAccountHoldersNestedInput = {
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutLoyaltyAccountHoldersInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutLoyaltyAccountHoldersInput>;
  upsert?: Maybe<LoyaltyProgramUpsertWithoutLoyaltyAccountHoldersInput>;
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  update?: Maybe<LoyaltyProgramUncheckedUpdateWithoutLoyaltyAccountHoldersInput>;
};

export type LoyaltyProgramUpdateOneRequiredWithoutPointGrantersNestedInput = {
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutPointGrantersInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutPointGrantersInput>;
  upsert?: Maybe<LoyaltyProgramUpsertWithoutPointGrantersInput>;
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  update?: Maybe<LoyaltyProgramUncheckedUpdateWithoutPointGrantersInput>;
};

export type LoyaltyProgramUpdateOneRequiredWithoutRewardsNestedInput = {
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutRewardsInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutRewardsInput>;
  upsert?: Maybe<LoyaltyProgramUpsertWithoutRewardsInput>;
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  update?: Maybe<LoyaltyProgramUncheckedUpdateWithoutRewardsInput>;
};

export type LoyaltyProgramUpdateOneRequiredWithoutStatusHistoriesNestedInput = {
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutStatusHistoriesInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutStatusHistoriesInput>;
  upsert?: Maybe<LoyaltyProgramUpsertWithoutStatusHistoriesInput>;
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  update?: Maybe<LoyaltyProgramUncheckedUpdateWithoutStatusHistoriesInput>;
};

export type LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput = {
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutParticipatingClinicsInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutParticipatingClinicsInput>;
  upsert?: Maybe<LoyaltyProgramUpsertWithoutParticipatingClinicsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  update?: Maybe<LoyaltyProgramUncheckedUpdateWithoutParticipatingClinicsInput>;
};

export type LoyaltyProgramUpdateWithoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  rewards?: Maybe<LoyaltyRewardUpdateManyWithoutProgramNestedInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUpdateManyWithoutProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUpdateManyWithoutProgramNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutLoyaltyProgramNestedInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutProgramNestedInput>;
};

export type LoyaltyProgramUpdateWithoutLoyaltyAccountHoldersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUpdateManyWithoutProgramNestedInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUpdateManyWithoutProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUpdateManyWithoutProgramNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutLoyaltyProgramNestedInput>;
};

export type LoyaltyProgramUpdateWithoutParticipatingClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUpdateManyWithoutProgramNestedInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUpdateManyWithoutProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUpdateManyWithoutProgramNestedInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutProgramNestedInput>;
};

export type LoyaltyProgramUpdateWithoutPointGrantersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUpdateManyWithoutProgramNestedInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUpdateManyWithoutProgramNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutLoyaltyProgramNestedInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutProgramNestedInput>;
};

export type LoyaltyProgramUpdateWithoutRewardsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUpdateManyWithoutProgramNestedInput>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUpdateManyWithoutProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUpdateManyWithoutProgramNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutLoyaltyProgramNestedInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutProgramNestedInput>;
};

export type LoyaltyProgramUpdateWithoutStatusHistoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyProgramStatus>;
  description?: Maybe<Scalars['String']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<LoyaltyAccountUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUpdateManyWithoutProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUpdateManyWithoutProgramNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutLoyaltyProgramNestedInput>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutProgramNestedInput>;
};

export type LoyaltyProgramUpsertWithoutAccountsInput = {
  update: LoyaltyProgramUncheckedUpdateWithoutAccountsInput;
  create: LoyaltyProgramUncheckedCreateWithoutAccountsInput;
};

export type LoyaltyProgramUpsertWithoutLoyaltyAccountHoldersInput = {
  update: LoyaltyProgramUncheckedUpdateWithoutLoyaltyAccountHoldersInput;
  create: LoyaltyProgramUncheckedCreateWithoutLoyaltyAccountHoldersInput;
};

export type LoyaltyProgramUpsertWithoutParticipatingClinicsInput = {
  update: LoyaltyProgramUncheckedUpdateWithoutParticipatingClinicsInput;
  create: LoyaltyProgramUncheckedCreateWithoutParticipatingClinicsInput;
};

export type LoyaltyProgramUpsertWithoutPointGrantersInput = {
  update: LoyaltyProgramUncheckedUpdateWithoutPointGrantersInput;
  create: LoyaltyProgramUncheckedCreateWithoutPointGrantersInput;
};

export type LoyaltyProgramUpsertWithoutRewardsInput = {
  update: LoyaltyProgramUncheckedUpdateWithoutRewardsInput;
  create: LoyaltyProgramUncheckedCreateWithoutRewardsInput;
};

export type LoyaltyProgramUpsertWithoutStatusHistoriesInput = {
  update: LoyaltyProgramUncheckedUpdateWithoutStatusHistoriesInput;
  create: LoyaltyProgramUncheckedCreateWithoutStatusHistoriesInput;
};

export type LoyaltyProgramWhereInput = {
  AND?: Maybe<Array<LoyaltyProgramWhereInput>>;
  OR?: Maybe<Array<LoyaltyProgramWhereInput>>;
  NOT?: Maybe<Array<LoyaltyProgramWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumLoyaltyProgramStatusFilter>;
  description?: Maybe<StringFilter>;
  centsPerPoint?: Maybe<IntFilter>;
  allowSpendCarryOver?: Maybe<BoolFilter>;
  minimumTransactionCents?: Maybe<IntFilter>;
  allowOpenInvoices?: Maybe<BoolFilter>;
  accounts?: Maybe<LoyaltyAccountListRelationFilter>;
  rewards?: Maybe<LoyaltyRewardListRelationFilter>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryListRelationFilter>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterListRelationFilter>;
  participatingClinics?: Maybe<ClinicListRelationFilter>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderListRelationFilter>;
};

export type LoyaltyProgramWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoyaltyReward = {
  __typename?: 'LoyaltyReward';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  description: Scalars['String'];
  /** Notes that are available only to the clinic */
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  availability: LoyaltyRewardAvailability;
  programId: Scalars['String'];
  program: LoyaltyProgram;
  redemptions: Array<LoyaltyRewardRedemption>;
  _count: LoyaltyRewardCountOutputType;
};


export type LoyaltyRewardRedemptionsArgs = {
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
  orderBy?: Maybe<LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyRewardRedemptionScalarFieldEnum>;
};

export enum LoyaltyRewardAvailability {
  Unpublished = 'Unpublished',
  Published = 'Published',
  Archived = 'Archived'
}

export type LoyaltyRewardAvgAggregateOutputType = {
  __typename?: 'LoyaltyRewardAvgAggregateOutputType';
  pointCost?: Maybe<Scalars['Float']>;
};

export type LoyaltyRewardAvgOrderByAggregateInput = {
  pointCost?: Maybe<SortOrder>;
};

export type LoyaltyRewardCountAggregateOutputType = {
  __typename?: 'LoyaltyRewardCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  title: Scalars['Int'];
  description: Scalars['Int'];
  internalNotes: Scalars['Int'];
  pointCost: Scalars['Int'];
  availability: Scalars['Int'];
  programId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LoyaltyRewardCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  pointCost?: Maybe<SortOrder>;
  availability?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
};

export type LoyaltyRewardCountOutputType = {
  __typename?: 'LoyaltyRewardCountOutputType';
  redemptions: Scalars['Int'];
};

export type LoyaltyRewardCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  availability?: Maybe<LoyaltyRewardAvailability>;
  program: LoyaltyProgramCreateNestedOneWithoutRewardsInput;
  redemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutRewardInput>;
};

export type LoyaltyRewardCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  availability?: Maybe<LoyaltyRewardAvailability>;
  programId: Scalars['String'];
};

export type LoyaltyRewardCreateManyProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  availability?: Maybe<LoyaltyRewardAvailability>;
};

export type LoyaltyRewardCreateManyProgramInputEnvelope = {
  data: Array<LoyaltyRewardCreateManyProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyRewardCreateNestedManyWithoutProgramInput = {
  create?: Maybe<Array<LoyaltyRewardCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardCreateOrConnectWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyRewardCreateManyProgramInputEnvelope>;
  connect?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
};

export type LoyaltyRewardCreateNestedOneWithoutRedemptionsInput = {
  create?: Maybe<LoyaltyRewardUncheckedCreateWithoutRedemptionsInput>;
  connectOrCreate?: Maybe<LoyaltyRewardCreateOrConnectWithoutRedemptionsInput>;
  connect?: Maybe<LoyaltyRewardWhereUniqueInput>;
};

export type LoyaltyRewardCreateOrConnectWithoutProgramInput = {
  where: LoyaltyRewardWhereUniqueInput;
  create: LoyaltyRewardUncheckedCreateWithoutProgramInput;
};

export type LoyaltyRewardCreateOrConnectWithoutRedemptionsInput = {
  where: LoyaltyRewardWhereUniqueInput;
  create: LoyaltyRewardUncheckedCreateWithoutRedemptionsInput;
};

export type LoyaltyRewardCreateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  availability?: Maybe<LoyaltyRewardAvailability>;
  redemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutRewardInput>;
};

export type LoyaltyRewardCreateWithoutRedemptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  availability?: Maybe<LoyaltyRewardAvailability>;
  program: LoyaltyProgramCreateNestedOneWithoutRewardsInput;
};

export type LoyaltyRewardListRelationFilter = {
  every?: Maybe<LoyaltyRewardWhereInput>;
  some?: Maybe<LoyaltyRewardWhereInput>;
  none?: Maybe<LoyaltyRewardWhereInput>;
};

export type LoyaltyRewardMaxAggregateOutputType = {
  __typename?: 'LoyaltyRewardMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  availability?: Maybe<LoyaltyRewardAvailability>;
  programId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  pointCost?: Maybe<SortOrder>;
  availability?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
};

export type LoyaltyRewardMinAggregateOutputType = {
  __typename?: 'LoyaltyRewardMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  availability?: Maybe<LoyaltyRewardAvailability>;
  programId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  pointCost?: Maybe<SortOrder>;
  availability?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
};

export type LoyaltyRewardOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyRewardOrderByRelevanceFieldEnum {
  Id = 'id',
  Title = 'title',
  Description = 'description',
  InternalNotes = 'internalNotes',
  ProgramId = 'programId'
}

export type LoyaltyRewardOrderByRelevanceInput = {
  fields: Array<LoyaltyRewardOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LoyaltyRewardOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  pointCost?: Maybe<SortOrder>;
  availability?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  _count?: Maybe<LoyaltyRewardCountOrderByAggregateInput>;
  _avg?: Maybe<LoyaltyRewardAvgOrderByAggregateInput>;
  _max?: Maybe<LoyaltyRewardMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyRewardMinOrderByAggregateInput>;
  _sum?: Maybe<LoyaltyRewardSumOrderByAggregateInput>;
};

export type LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  pointCost?: Maybe<SortOrder>;
  availability?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  program?: Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>;
  redemptions?: Maybe<LoyaltyRewardRedemptionOrderByRelationAggregateInput>;
  _relevance?: Maybe<LoyaltyRewardOrderByRelevanceInput>;
};

export type LoyaltyRewardRedemption = {
  __typename?: 'LoyaltyRewardRedemption';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: LoyaltyRewardRedemptionStatus;
  internalNote: Scalars['String'];
  rewardId: Scalars['String'];
  accountId: Scalars['String'];
  reward: LoyaltyReward;
  account: LoyaltyAccount;
  redemptionHistories: Array<LoyaltyRewardRedemptionHistoryEntry>;
  _count: LoyaltyRewardRedemptionCountOutputType;
};


export type LoyaltyRewardRedemptionRedemptionHistoriesArgs = {
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  orderBy?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum>;
};

export enum LoyaltyRewardRedemptionActorType {
  System = 'System',
  ClinicUser = 'ClinicUser',
  PetParent = 'PetParent'
}

export type LoyaltyRewardRedemptionCountAggregateOutputType = {
  __typename?: 'LoyaltyRewardRedemptionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  status: Scalars['Int'];
  internalNote: Scalars['Int'];
  rewardId: Scalars['Int'];
  accountId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LoyaltyRewardRedemptionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  internalNote?: Maybe<SortOrder>;
  rewardId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionCountOutputType = {
  __typename?: 'LoyaltyRewardRedemptionCountOutputType';
  redemptionHistories: Scalars['Int'];
};

export type LoyaltyRewardRedemptionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote: Scalars['String'];
  reward: LoyaltyRewardCreateNestedOneWithoutRedemptionsInput;
  account: LoyaltyAccountCreateNestedOneWithoutRewardRedemptionsInput;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRedemptionInput>;
};

export type LoyaltyRewardRedemptionCreateManyAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote: Scalars['String'];
  rewardId: Scalars['String'];
};

export type LoyaltyRewardRedemptionCreateManyAccountInputEnvelope = {
  data: Array<LoyaltyRewardRedemptionCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyRewardRedemptionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote: Scalars['String'];
  rewardId: Scalars['String'];
  accountId: Scalars['String'];
};

export type LoyaltyRewardRedemptionCreateManyRewardInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote: Scalars['String'];
  accountId: Scalars['String'];
};

export type LoyaltyRewardRedemptionCreateManyRewardInputEnvelope = {
  data: Array<LoyaltyRewardRedemptionCreateManyRewardInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyRewardRedemptionCreateNestedManyWithoutAccountInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyAccountInputEnvelope>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
};

export type LoyaltyRewardRedemptionCreateNestedManyWithoutRewardInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutRewardInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutRewardInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyRewardInputEnvelope>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
};

export type LoyaltyRewardRedemptionCreateNestedOneWithoutRedemptionHistoriesInput = {
  create?: Maybe<LoyaltyRewardRedemptionUncheckedCreateWithoutRedemptionHistoriesInput>;
  connectOrCreate?: Maybe<LoyaltyRewardRedemptionCreateOrConnectWithoutRedemptionHistoriesInput>;
  connect?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
};

export type LoyaltyRewardRedemptionCreateOrConnectWithoutAccountInput = {
  where: LoyaltyRewardRedemptionWhereUniqueInput;
  create: LoyaltyRewardRedemptionUncheckedCreateWithoutAccountInput;
};

export type LoyaltyRewardRedemptionCreateOrConnectWithoutRedemptionHistoriesInput = {
  where: LoyaltyRewardRedemptionWhereUniqueInput;
  create: LoyaltyRewardRedemptionUncheckedCreateWithoutRedemptionHistoriesInput;
};

export type LoyaltyRewardRedemptionCreateOrConnectWithoutRewardInput = {
  where: LoyaltyRewardRedemptionWhereUniqueInput;
  create: LoyaltyRewardRedemptionUncheckedCreateWithoutRewardInput;
};

export type LoyaltyRewardRedemptionCreateWithoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote: Scalars['String'];
  reward: LoyaltyRewardCreateNestedOneWithoutRedemptionsInput;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRedemptionInput>;
};

export type LoyaltyRewardRedemptionCreateWithoutRedemptionHistoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote: Scalars['String'];
  reward: LoyaltyRewardCreateNestedOneWithoutRedemptionsInput;
  account: LoyaltyAccountCreateNestedOneWithoutRewardRedemptionsInput;
};

export type LoyaltyRewardRedemptionCreateWithoutRewardInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote: Scalars['String'];
  account: LoyaltyAccountCreateNestedOneWithoutRewardRedemptionsInput;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRedemptionInput>;
};

/** Used for when a Loyalty Account holder requests a Loyalty Reward */
export type LoyaltyRewardRedemptionHistoryEntry = {
  __typename?: 'LoyaltyRewardRedemptionHistoryEntry';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  redemptionId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemption: LoyaltyRewardRedemption;
  actingAccountHolder?: Maybe<LoyaltyAccountHolder>;
  actingUser?: Maybe<User>;
  relatedClinic?: Maybe<Clinic>;
  pointDelta?: Maybe<LoyaltyPointDelta>;
};

export type LoyaltyRewardRedemptionHistoryEntryCountAggregateOutputType = {
  __typename?: 'LoyaltyRewardRedemptionHistoryEntryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  redemptionId: Scalars['Int'];
  status: Scalars['Int'];
  actorType: Scalars['Int'];
  actingAccountHolderId: Scalars['Int'];
  actingUserId: Scalars['Int'];
  relatedClinicId: Scalars['Int'];
  pointDeltaId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LoyaltyRewardRedemptionHistoryEntryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  redemptionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  actorType?: Maybe<SortOrder>;
  actingAccountHolderId?: Maybe<SortOrder>;
  actingUserId?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  pointDeltaId?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  redemption: LoyaltyRewardRedemptionCreateNestedOneWithoutRedemptionHistoriesInput;
  actingAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutRewardRedemptionActionsInput>;
  actingUser?: Maybe<UserCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  relatedClinic?: Maybe<ClinicCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  pointDelta?: Maybe<LoyaltyPointDeltaCreateNestedOneWithoutRelatedRedemptionHistoryEntryInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyActingAccountHolderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyActingAccountHolderInputEnvelope = {
  data: Array<LoyaltyRewardRedemptionHistoryEntryCreateManyActingAccountHolderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyActingUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyActingUserInputEnvelope = {
  data: Array<LoyaltyRewardRedemptionHistoryEntryCreateManyActingUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyRedemptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyRedemptionInputEnvelope = {
  data: Array<LoyaltyRewardRedemptionHistoryEntryCreateManyRedemptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyRelatedClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyRelatedClinicInputEnvelope = {
  data: Array<LoyaltyRewardRedemptionHistoryEntryCreateManyRelatedClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingAccountHolderInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingAccountHolderInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingAccountHolderInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingAccountHolderInputEnvelope>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingUserInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingUserInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingUserInputEnvelope>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRedemptionInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRedemptionInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRedemptionInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRedemptionInputEnvelope>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRelatedClinicInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRelatedClinicInputEnvelope>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateNestedOneWithoutPointDeltaInput = {
  create?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutPointDeltaInput>;
  connectOrCreate?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutPointDeltaInput>;
  connect?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingAccountHolderInput = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutActingAccountHolderInput;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingUserInput = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutActingUserInput;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutPointDeltaInput = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutPointDeltaInput;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRedemptionInput = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutRedemptionInput;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRelatedClinicInput = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutRelatedClinicInput;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingAccountHolderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  redemption: LoyaltyRewardRedemptionCreateNestedOneWithoutRedemptionHistoriesInput;
  actingUser?: Maybe<UserCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  relatedClinic?: Maybe<ClinicCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  pointDelta?: Maybe<LoyaltyPointDeltaCreateNestedOneWithoutRelatedRedemptionHistoryEntryInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  redemption: LoyaltyRewardRedemptionCreateNestedOneWithoutRedemptionHistoriesInput;
  actingAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutRewardRedemptionActionsInput>;
  relatedClinic?: Maybe<ClinicCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  pointDelta?: Maybe<LoyaltyPointDeltaCreateNestedOneWithoutRelatedRedemptionHistoryEntryInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateWithoutPointDeltaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  redemption: LoyaltyRewardRedemptionCreateNestedOneWithoutRedemptionHistoriesInput;
  actingAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutRewardRedemptionActionsInput>;
  actingUser?: Maybe<UserCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  relatedClinic?: Maybe<ClinicCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateWithoutRedemptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  actingAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutRewardRedemptionActionsInput>;
  actingUser?: Maybe<UserCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  relatedClinic?: Maybe<ClinicCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  pointDelta?: Maybe<LoyaltyPointDeltaCreateNestedOneWithoutRelatedRedemptionHistoryEntryInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateWithoutRelatedClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  redemption: LoyaltyRewardRedemptionCreateNestedOneWithoutRedemptionHistoriesInput;
  actingAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutRewardRedemptionActionsInput>;
  actingUser?: Maybe<UserCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  pointDelta?: Maybe<LoyaltyPointDeltaCreateNestedOneWithoutRelatedRedemptionHistoryEntryInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryListRelationFilter = {
  every?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  some?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  none?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryMaxAggregateOutputType = {
  __typename?: 'LoyaltyRewardRedemptionHistoryEntryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  redemptionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  actorType?: Maybe<SortOrder>;
  actingAccountHolderId?: Maybe<SortOrder>;
  actingUserId?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  pointDeltaId?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionHistoryEntryMinAggregateOutputType = {
  __typename?: 'LoyaltyRewardRedemptionHistoryEntryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  redemptionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  actorType?: Maybe<SortOrder>;
  actingAccountHolderId?: Maybe<SortOrder>;
  actingUserId?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  pointDeltaId?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionHistoryEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyRewardRedemptionHistoryEntryOrderByRelevanceFieldEnum {
  Id = 'id',
  RedemptionId = 'redemptionId',
  ActingAccountHolderId = 'actingAccountHolderId',
  ActingUserId = 'actingUserId',
  RelatedClinicId = 'relatedClinicId',
  PointDeltaId = 'pointDeltaId'
}

export type LoyaltyRewardRedemptionHistoryEntryOrderByRelevanceInput = {
  fields: Array<LoyaltyRewardRedemptionHistoryEntryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LoyaltyRewardRedemptionHistoryEntryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  redemptionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  actorType?: Maybe<SortOrder>;
  actingAccountHolderId?: Maybe<SortOrder>;
  actingUserId?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  pointDeltaId?: Maybe<SortOrder>;
  _count?: Maybe<LoyaltyRewardRedemptionHistoryEntryCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyRewardRedemptionHistoryEntryMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyRewardRedemptionHistoryEntryMinOrderByAggregateInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  redemptionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  actorType?: Maybe<SortOrder>;
  actingAccountHolderId?: Maybe<SortOrder>;
  actingUserId?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  pointDeltaId?: Maybe<SortOrder>;
  redemption?: Maybe<LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput>;
  actingAccountHolder?: Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>;
  actingUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  relatedClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  pointDelta?: Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByRelevanceInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryRedemptionIdStatusCompoundUniqueInput = {
  redemptionId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryRelationFilter = {
  is?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  isNot?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
};

export enum LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  RedemptionId = 'redemptionId',
  Status = 'status',
  ActorType = 'actorType',
  ActingAccountHolderId = 'actingAccountHolderId',
  ActingUserId = 'actingUserId',
  RelatedClinicId = 'relatedClinicId',
  PointDeltaId = 'pointDeltaId'
}

export type LoyaltyRewardRedemptionHistoryEntryScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  redemptionId?: Maybe<StringFilter>;
  status?: Maybe<EnumLoyaltyRewardRedemptionStatusFilter>;
  actorType?: Maybe<EnumLoyaltyRewardRedemptionActorTypeFilter>;
  actingAccountHolderId?: Maybe<StringNullableFilter>;
  actingUserId?: Maybe<StringNullableFilter>;
  relatedClinicId?: Maybe<StringNullableFilter>;
  pointDeltaId?: Maybe<StringNullableFilter>;
};

export type LoyaltyRewardRedemptionHistoryEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  redemptionId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumLoyaltyRewardRedemptionStatusWithAggregatesFilter>;
  actorType?: Maybe<EnumLoyaltyRewardRedemptionActorTypeWithAggregatesFilter>;
  actingAccountHolderId?: Maybe<StringNullableWithAggregatesFilter>;
  actingUserId?: Maybe<StringNullableWithAggregatesFilter>;
  relatedClinicId?: Maybe<StringNullableWithAggregatesFilter>;
  pointDeltaId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingAccountHolderInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingAccountHolderInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingAccountHolderInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingAccountHolderInputEnvelope>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingUserInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingUserInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingUserInputEnvelope>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRedemptionInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRedemptionInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRedemptionInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRedemptionInputEnvelope>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRelatedClinicInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRelatedClinicInputEnvelope>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedOneWithoutPointDeltaInput = {
  create?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutPointDeltaInput>;
  connectOrCreate?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutPointDeltaInput>;
  connect?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutActingAccountHolderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutActingUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutPointDeltaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutRedemptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutRelatedClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
  actorType: LoyaltyRewardRedemptionActorType;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingAccountHolderNestedInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingAccountHolderInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingAccountHolderInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutActingAccountHolderInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingAccountHolderInputEnvelope>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutActingAccountHolderInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutActingAccountHolderInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingUserInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingUserInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutActingUserInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingUserInputEnvelope>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutActingUserInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutActingUserInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutLoyaltyRewardRedemptionActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRedemptionHistoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRedemptionNestedInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRedemptionInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRedemptionInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutRedemptionInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRedemptionInputEnvelope>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutRedemptionInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutRedemptionInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRelatedClinicInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRelatedClinicInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRelatedClinicInputEnvelope>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutRelatedClinicInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutRelatedClinicInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRewardRedemptionActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateOneWithoutPointDeltaNestedInput = {
  create?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutPointDeltaInput>;
  connectOrCreate?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutPointDeltaInput>;
  upsert?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpsertWithoutPointDeltaInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  update?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutPointDeltaInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutActingAccountHolderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutActingUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutPointDeltaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutRedemptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutRelatedClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  redemptionId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  redemption?: Maybe<LoyaltyRewardRedemptionUpdateOneRequiredWithoutRedemptionHistoriesNestedInput>;
  actingAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutRewardRedemptionActionsNestedInput>;
  actingUser?: Maybe<UserUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  pointDelta?: Maybe<LoyaltyPointDeltaUpdateOneWithoutRelatedRedemptionHistoryEntryNestedInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutActingAccountHolderInput = {
  where: LoyaltyRewardRedemptionHistoryEntryScalarWhereInput;
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRewardRedemptionActionsInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutActingUserInput = {
  where: LoyaltyRewardRedemptionHistoryEntryScalarWhereInput;
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutLoyaltyRewardRedemptionActionsInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutRedemptionInput = {
  where: LoyaltyRewardRedemptionHistoryEntryScalarWhereInput;
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRedemptionHistoriesInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutRelatedClinicInput = {
  where: LoyaltyRewardRedemptionHistoryEntryScalarWhereInput;
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutLoyaltyRewardRedemptionActionsInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingAccountHolderNestedInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingAccountHolderInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingAccountHolderInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutActingAccountHolderInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingAccountHolderInputEnvelope>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutActingAccountHolderInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutActingAccountHolderInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingUserInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingUserInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutActingUserInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingUserInputEnvelope>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutActingUserInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutActingUserInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRedemptionNestedInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRedemptionInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRedemptionInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutRedemptionInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRedemptionInputEnvelope>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutRedemptionInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutRedemptionInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRelatedClinicInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRelatedClinicInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRelatedClinicInputEnvelope>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutRelatedClinicInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutRelatedClinicInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateOneWithoutPointDeltaNestedInput = {
  create?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutPointDeltaInput>;
  connectOrCreate?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutPointDeltaInput>;
  upsert?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpsertWithoutPointDeltaInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  update?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutPointDeltaInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutActingAccountHolderInput = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutActingAccountHolderInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutActingUserInput = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutActingUserInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutRedemptionInput = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutRedemptionInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutRelatedClinicInput = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutRelatedClinicInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithoutActingAccountHolderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  redemption?: Maybe<LoyaltyRewardRedemptionUpdateOneRequiredWithoutRedemptionHistoriesNestedInput>;
  actingUser?: Maybe<UserUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  pointDelta?: Maybe<LoyaltyPointDeltaUpdateOneWithoutRelatedRedemptionHistoryEntryNestedInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithoutActingUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  redemption?: Maybe<LoyaltyRewardRedemptionUpdateOneRequiredWithoutRedemptionHistoriesNestedInput>;
  actingAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutRewardRedemptionActionsNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  pointDelta?: Maybe<LoyaltyPointDeltaUpdateOneWithoutRelatedRedemptionHistoryEntryNestedInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithoutPointDeltaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  redemption?: Maybe<LoyaltyRewardRedemptionUpdateOneRequiredWithoutRedemptionHistoriesNestedInput>;
  actingAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutRewardRedemptionActionsNestedInput>;
  actingUser?: Maybe<UserUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithoutRedemptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  actingAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutRewardRedemptionActionsNestedInput>;
  actingUser?: Maybe<UserUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  pointDelta?: Maybe<LoyaltyPointDeltaUpdateOneWithoutRelatedRedemptionHistoryEntryNestedInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithoutRelatedClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  redemption?: Maybe<LoyaltyRewardRedemptionUpdateOneRequiredWithoutRedemptionHistoriesNestedInput>;
  actingAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutRewardRedemptionActionsNestedInput>;
  actingUser?: Maybe<UserUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  pointDelta?: Maybe<LoyaltyPointDeltaUpdateOneWithoutRelatedRedemptionHistoryEntryNestedInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutActingAccountHolderInput = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
  update: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutActingAccountHolderInput;
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutActingAccountHolderInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutActingUserInput = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
  update: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutActingUserInput;
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutActingUserInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutRedemptionInput = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
  update: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutRedemptionInput;
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutRedemptionInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutRelatedClinicInput = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
  update: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutRelatedClinicInput;
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutRelatedClinicInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpsertWithoutPointDeltaInput = {
  update: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutPointDeltaInput;
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutPointDeltaInput;
};

export type LoyaltyRewardRedemptionHistoryEntryWhereInput = {
  AND?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereInput>>;
  OR?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereInput>>;
  NOT?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  redemptionId?: Maybe<StringFilter>;
  status?: Maybe<EnumLoyaltyRewardRedemptionStatusFilter>;
  actorType?: Maybe<EnumLoyaltyRewardRedemptionActorTypeFilter>;
  actingAccountHolderId?: Maybe<StringNullableFilter>;
  actingUserId?: Maybe<StringNullableFilter>;
  relatedClinicId?: Maybe<StringNullableFilter>;
  pointDeltaId?: Maybe<StringNullableFilter>;
  redemption?: Maybe<LoyaltyRewardRedemptionWhereInput>;
  actingAccountHolder?: Maybe<LoyaltyAccountHolderWhereInput>;
  actingUser?: Maybe<UserWhereInput>;
  relatedClinic?: Maybe<ClinicWhereInput>;
  pointDelta?: Maybe<LoyaltyPointDeltaWhereInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId_status?: Maybe<LoyaltyRewardRedemptionHistoryEntryRedemptionIdStatusCompoundUniqueInput>;
};

export type LoyaltyRewardRedemptionInput = {
  loyaltyRewardRedemptionId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionListRelationFilter = {
  every?: Maybe<LoyaltyRewardRedemptionWhereInput>;
  some?: Maybe<LoyaltyRewardRedemptionWhereInput>;
  none?: Maybe<LoyaltyRewardRedemptionWhereInput>;
};

export type LoyaltyRewardRedemptionMaxAggregateOutputType = {
  __typename?: 'LoyaltyRewardRedemptionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  internalNote?: Maybe<SortOrder>;
  rewardId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionMinAggregateOutputType = {
  __typename?: 'LoyaltyRewardRedemptionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  internalNote?: Maybe<SortOrder>;
  rewardId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyRewardRedemptionOrderByRelevanceFieldEnum {
  Id = 'id',
  InternalNote = 'internalNote',
  RewardId = 'rewardId',
  AccountId = 'accountId'
}

export type LoyaltyRewardRedemptionOrderByRelevanceInput = {
  fields: Array<LoyaltyRewardRedemptionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type LoyaltyRewardRedemptionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  internalNote?: Maybe<SortOrder>;
  rewardId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  _count?: Maybe<LoyaltyRewardRedemptionCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyRewardRedemptionMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyRewardRedemptionMinOrderByAggregateInput>;
};

export type LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  internalNote?: Maybe<SortOrder>;
  rewardId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  reward?: Maybe<LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput>;
  account?: Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByRelationAggregateInput>;
  _relevance?: Maybe<LoyaltyRewardRedemptionOrderByRelevanceInput>;
};

export type LoyaltyRewardRedemptionRelationFilter = {
  is?: Maybe<LoyaltyRewardRedemptionWhereInput>;
  isNot?: Maybe<LoyaltyRewardRedemptionWhereInput>;
};

export enum LoyaltyRewardRedemptionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  InternalNote = 'internalNote',
  RewardId = 'rewardId',
  AccountId = 'accountId'
}

export type LoyaltyRewardRedemptionScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumLoyaltyRewardRedemptionStatusFilter>;
  internalNote?: Maybe<StringFilter>;
  rewardId?: Maybe<StringFilter>;
  accountId?: Maybe<StringFilter>;
};

export type LoyaltyRewardRedemptionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumLoyaltyRewardRedemptionStatusWithAggregatesFilter>;
  internalNote?: Maybe<StringWithAggregatesFilter>;
  rewardId?: Maybe<StringWithAggregatesFilter>;
  accountId?: Maybe<StringWithAggregatesFilter>;
};

export enum LoyaltyRewardRedemptionStatus {
  Requested = 'Requested',
  Rejected = 'Rejected',
  PendingFulfillment = 'PendingFulfillment',
  Canceling = 'Canceling',
  Canceled = 'Canceled',
  Fulfilled = 'Fulfilled'
}

export type LoyaltyRewardRedemptionSubscription = {
  __typename?: 'LoyaltyRewardRedemptionSubscription';
  node: LoyaltyRewardRedemption;
  mutation: MutationType;
};

export type LoyaltyRewardRedemptionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote: Scalars['String'];
  rewardId: Scalars['String'];
  accountId: Scalars['String'];
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRedemptionInput>;
};

export type LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutAccountInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyAccountInputEnvelope>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
};

export type LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutRewardInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutRewardInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutRewardInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyRewardInputEnvelope>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
};

export type LoyaltyRewardRedemptionUncheckedCreateWithoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote: Scalars['String'];
  rewardId: Scalars['String'];
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRedemptionInput>;
};

export type LoyaltyRewardRedemptionUncheckedCreateWithoutRedemptionHistoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote: Scalars['String'];
  rewardId: Scalars['String'];
  accountId: Scalars['String'];
};

export type LoyaltyRewardRedemptionUncheckedCreateWithoutRewardInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote: Scalars['String'];
  accountId: Scalars['String'];
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRedemptionInput>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRedemptionNestedInput>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateManyWithoutAccountNestedInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutAccountInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionUpsertWithWhereUniqueWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyAccountInputEnvelope>;
  set?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionUpdateManyWithWhereWithoutAccountInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereInput>>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateManyWithoutRedemptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateManyWithoutRewardNestedInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutRewardInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutRewardInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionUpsertWithWhereUniqueWithoutRewardInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyRewardInputEnvelope>;
  set?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionUpdateWithWhereUniqueWithoutRewardInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionUpdateManyWithWhereWithoutRewardInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereInput>>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateManyWithoutRewardRedemptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateWithoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRedemptionNestedInput>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateWithoutRedemptionHistoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateWithoutRewardInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRedemptionNestedInput>;
};

export type LoyaltyRewardRedemptionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote?: Maybe<Scalars['String']>;
  reward?: Maybe<LoyaltyRewardUpdateOneRequiredWithoutRedemptionsNestedInput>;
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutRewardRedemptionsNestedInput>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRedemptionNestedInput>;
};

export type LoyaltyRewardRedemptionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionUpdateManyWithWhereWithoutAccountInput = {
  where: LoyaltyRewardRedemptionScalarWhereInput;
  data: LoyaltyRewardRedemptionUncheckedUpdateManyWithoutRewardRedemptionsInput;
};

export type LoyaltyRewardRedemptionUpdateManyWithWhereWithoutRewardInput = {
  where: LoyaltyRewardRedemptionScalarWhereInput;
  data: LoyaltyRewardRedemptionUncheckedUpdateManyWithoutRedemptionsInput;
};

export type LoyaltyRewardRedemptionUpdateManyWithoutAccountNestedInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutAccountInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutAccountInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionUpsertWithWhereUniqueWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyAccountInputEnvelope>;
  set?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionUpdateManyWithWhereWithoutAccountInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereInput>>;
};

export type LoyaltyRewardRedemptionUpdateManyWithoutRewardNestedInput = {
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutRewardInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutRewardInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionUpsertWithWhereUniqueWithoutRewardInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyRewardInputEnvelope>;
  set?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionUpdateWithWhereUniqueWithoutRewardInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionUpdateManyWithWhereWithoutRewardInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereInput>>;
};

export type LoyaltyRewardRedemptionUpdateOneRequiredWithoutRedemptionHistoriesNestedInput = {
  create?: Maybe<LoyaltyRewardRedemptionUncheckedCreateWithoutRedemptionHistoriesInput>;
  connectOrCreate?: Maybe<LoyaltyRewardRedemptionCreateOrConnectWithoutRedemptionHistoriesInput>;
  upsert?: Maybe<LoyaltyRewardRedemptionUpsertWithoutRedemptionHistoriesInput>;
  connect?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  update?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateWithoutRedemptionHistoriesInput>;
};

export type LoyaltyRewardRedemptionUpdateWithWhereUniqueWithoutAccountInput = {
  where: LoyaltyRewardRedemptionWhereUniqueInput;
  data: LoyaltyRewardRedemptionUncheckedUpdateWithoutAccountInput;
};

export type LoyaltyRewardRedemptionUpdateWithWhereUniqueWithoutRewardInput = {
  where: LoyaltyRewardRedemptionWhereUniqueInput;
  data: LoyaltyRewardRedemptionUncheckedUpdateWithoutRewardInput;
};

export type LoyaltyRewardRedemptionUpdateWithoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote?: Maybe<Scalars['String']>;
  reward?: Maybe<LoyaltyRewardUpdateOneRequiredWithoutRedemptionsNestedInput>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRedemptionNestedInput>;
};

export type LoyaltyRewardRedemptionUpdateWithoutRedemptionHistoriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote?: Maybe<Scalars['String']>;
  reward?: Maybe<LoyaltyRewardUpdateOneRequiredWithoutRedemptionsNestedInput>;
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutRewardRedemptionsNestedInput>;
};

export type LoyaltyRewardRedemptionUpdateWithoutRewardInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  internalNote?: Maybe<Scalars['String']>;
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutRewardRedemptionsNestedInput>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRedemptionNestedInput>;
};

export type LoyaltyRewardRedemptionUpsertWithWhereUniqueWithoutAccountInput = {
  where: LoyaltyRewardRedemptionWhereUniqueInput;
  update: LoyaltyRewardRedemptionUncheckedUpdateWithoutAccountInput;
  create: LoyaltyRewardRedemptionUncheckedCreateWithoutAccountInput;
};

export type LoyaltyRewardRedemptionUpsertWithWhereUniqueWithoutRewardInput = {
  where: LoyaltyRewardRedemptionWhereUniqueInput;
  update: LoyaltyRewardRedemptionUncheckedUpdateWithoutRewardInput;
  create: LoyaltyRewardRedemptionUncheckedCreateWithoutRewardInput;
};

export type LoyaltyRewardRedemptionUpsertWithoutRedemptionHistoriesInput = {
  update: LoyaltyRewardRedemptionUncheckedUpdateWithoutRedemptionHistoriesInput;
  create: LoyaltyRewardRedemptionUncheckedCreateWithoutRedemptionHistoriesInput;
};

export type LoyaltyRewardRedemptionWhereInput = {
  AND?: Maybe<Array<LoyaltyRewardRedemptionWhereInput>>;
  OR?: Maybe<Array<LoyaltyRewardRedemptionWhereInput>>;
  NOT?: Maybe<Array<LoyaltyRewardRedemptionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumLoyaltyRewardRedemptionStatusFilter>;
  internalNote?: Maybe<StringFilter>;
  rewardId?: Maybe<StringFilter>;
  accountId?: Maybe<StringFilter>;
  reward?: Maybe<LoyaltyRewardWhereInput>;
  account?: Maybe<LoyaltyAccountWhereInput>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryListRelationFilter>;
};

export type LoyaltyRewardRedemptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRelationFilter = {
  is?: Maybe<LoyaltyRewardWhereInput>;
  isNot?: Maybe<LoyaltyRewardWhereInput>;
};

export enum LoyaltyRewardScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Title = 'title',
  Description = 'description',
  InternalNotes = 'internalNotes',
  PointCost = 'pointCost',
  Availability = 'availability',
  ProgramId = 'programId'
}

export type LoyaltyRewardScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyRewardScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyRewardScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyRewardScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  internalNotes?: Maybe<StringNullableFilter>;
  pointCost?: Maybe<IntFilter>;
  availability?: Maybe<EnumLoyaltyRewardAvailabilityFilter>;
  programId?: Maybe<StringFilter>;
};

export type LoyaltyRewardScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyRewardScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyRewardScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyRewardScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  internalNotes?: Maybe<StringNullableWithAggregatesFilter>;
  pointCost?: Maybe<IntWithAggregatesFilter>;
  availability?: Maybe<EnumLoyaltyRewardAvailabilityWithAggregatesFilter>;
  programId?: Maybe<StringWithAggregatesFilter>;
};

export type LoyaltyRewardSumAggregateOutputType = {
  __typename?: 'LoyaltyRewardSumAggregateOutputType';
  pointCost?: Maybe<Scalars['Int']>;
};

export type LoyaltyRewardSumOrderByAggregateInput = {
  pointCost?: Maybe<SortOrder>;
};

export type LoyaltyRewardUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  availability?: Maybe<LoyaltyRewardAvailability>;
  programId: Scalars['String'];
  redemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutRewardInput>;
};

export type LoyaltyRewardUncheckedCreateNestedManyWithoutProgramInput = {
  create?: Maybe<Array<LoyaltyRewardCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardCreateOrConnectWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyRewardCreateManyProgramInputEnvelope>;
  connect?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
};

export type LoyaltyRewardUncheckedCreateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  availability?: Maybe<LoyaltyRewardAvailability>;
  redemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutRewardInput>;
};

export type LoyaltyRewardUncheckedCreateWithoutRedemptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  availability?: Maybe<LoyaltyRewardAvailability>;
  programId: Scalars['String'];
};

export type LoyaltyRewardUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  availability?: Maybe<LoyaltyRewardAvailability>;
  programId?: Maybe<Scalars['String']>;
  redemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutRewardNestedInput>;
};

export type LoyaltyRewardUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  availability?: Maybe<LoyaltyRewardAvailability>;
  programId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardUncheckedUpdateManyWithoutProgramNestedInput = {
  create?: Maybe<Array<LoyaltyRewardCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardCreateOrConnectWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyRewardUpsertWithWhereUniqueWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyRewardCreateManyProgramInputEnvelope>;
  set?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardUpdateManyWithWhereWithoutProgramInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardScalarWhereInput>>;
};

export type LoyaltyRewardUncheckedUpdateManyWithoutRewardsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  availability?: Maybe<LoyaltyRewardAvailability>;
};

export type LoyaltyRewardUncheckedUpdateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  availability?: Maybe<LoyaltyRewardAvailability>;
  redemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutRewardNestedInput>;
};

export type LoyaltyRewardUncheckedUpdateWithoutRedemptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  availability?: Maybe<LoyaltyRewardAvailability>;
  programId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  availability?: Maybe<LoyaltyRewardAvailability>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutRewardsNestedInput>;
  redemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutRewardNestedInput>;
};

export type LoyaltyRewardUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  availability?: Maybe<LoyaltyRewardAvailability>;
};

export type LoyaltyRewardUpdateManyWithWhereWithoutProgramInput = {
  where: LoyaltyRewardScalarWhereInput;
  data: LoyaltyRewardUncheckedUpdateManyWithoutRewardsInput;
};

export type LoyaltyRewardUpdateManyWithoutProgramNestedInput = {
  create?: Maybe<Array<LoyaltyRewardCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardCreateOrConnectWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyRewardUpsertWithWhereUniqueWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyRewardCreateManyProgramInputEnvelope>;
  set?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  delete?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  connect?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardUpdateManyWithWhereWithoutProgramInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardScalarWhereInput>>;
};

export type LoyaltyRewardUpdateOneRequiredWithoutRedemptionsNestedInput = {
  create?: Maybe<LoyaltyRewardUncheckedCreateWithoutRedemptionsInput>;
  connectOrCreate?: Maybe<LoyaltyRewardCreateOrConnectWithoutRedemptionsInput>;
  upsert?: Maybe<LoyaltyRewardUpsertWithoutRedemptionsInput>;
  connect?: Maybe<LoyaltyRewardWhereUniqueInput>;
  update?: Maybe<LoyaltyRewardUncheckedUpdateWithoutRedemptionsInput>;
};

export type LoyaltyRewardUpdateWithWhereUniqueWithoutProgramInput = {
  where: LoyaltyRewardWhereUniqueInput;
  data: LoyaltyRewardUncheckedUpdateWithoutProgramInput;
};

export type LoyaltyRewardUpdateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  availability?: Maybe<LoyaltyRewardAvailability>;
  redemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutRewardNestedInput>;
};

export type LoyaltyRewardUpdateWithoutRedemptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  availability?: Maybe<LoyaltyRewardAvailability>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutRewardsNestedInput>;
};

export type LoyaltyRewardUpsertWithWhereUniqueWithoutProgramInput = {
  where: LoyaltyRewardWhereUniqueInput;
  update: LoyaltyRewardUncheckedUpdateWithoutProgramInput;
  create: LoyaltyRewardUncheckedCreateWithoutProgramInput;
};

export type LoyaltyRewardUpsertWithoutRedemptionsInput = {
  update: LoyaltyRewardUncheckedUpdateWithoutRedemptionsInput;
  create: LoyaltyRewardUncheckedCreateWithoutRedemptionsInput;
};

export type LoyaltyRewardWhereInput = {
  AND?: Maybe<Array<LoyaltyRewardWhereInput>>;
  OR?: Maybe<Array<LoyaltyRewardWhereInput>>;
  NOT?: Maybe<Array<LoyaltyRewardWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  internalNotes?: Maybe<StringNullableFilter>;
  pointCost?: Maybe<IntFilter>;
  availability?: Maybe<EnumLoyaltyRewardAvailabilityFilter>;
  programId?: Maybe<StringFilter>;
  program?: Maybe<LoyaltyProgramWhereInput>;
  redemptions?: Maybe<LoyaltyRewardRedemptionListRelationFilter>;
};

export type LoyaltyRewardWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ManageMembershipRequestType {
  EditBenefit = 'EditBenefit',
  RemoveBenefit = 'RemoveBenefit',
  Upgrade = 'Upgrade',
  OptOut = 'OptOut',
  Transfer = 'Transfer',
  Cancel = 'Cancel',
  Other = 'Other'
}

export type MassTextAlert = {
  __typename?: 'MassTextAlert';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  clinicId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  createdByUser: User;
  clinic: Clinic;
  massTextAlertEntries: Array<MassTextAlertEntry>;
  _count: MassTextAlertCountOutputType;
};


export type MassTextAlertMassTextAlertEntriesArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
  orderBy?: Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MassTextAlertEntryScalarFieldEnum>;
};

export type MassTextAlertCountAggregateOutputType = {
  __typename?: 'MassTextAlertCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  appointmentDateTimeFrom: Scalars['Int'];
  appointmentDateTimeTo: Scalars['Int'];
  createdByUserId: Scalars['Int'];
  clinicId: Scalars['Int'];
  alertBody: Scalars['Int'];
  status: Scalars['Int'];
  appointmentDateRangeType: Scalars['Int'];
  _all: Scalars['Int'];
};

export type MassTextAlertCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  alertBody?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
};

export type MassTextAlertCountOutputType = {
  __typename?: 'MassTextAlertCountOutputType';
  massTextAlertEntries: Scalars['Int'];
};

export type MassTextAlertCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  createdByUser: UserCreateNestedOneWithoutMassTextAlertsInput;
  clinic: ClinicCreateNestedOneWithoutMassTextAlertsInput;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutMassTextAlertInput>;
};

export type MassTextAlertCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
};

export type MassTextAlertCreateManyClinicInputEnvelope = {
  data: Array<MassTextAlertCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertCreateManyCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  clinicId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
};

export type MassTextAlertCreateManyCreatedByUserInputEnvelope = {
  data: Array<MassTextAlertCreateManyCreatedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  clinicId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
};

export type MassTextAlertCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<MassTextAlertCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
};

export type MassTextAlertCreateNestedManyWithoutCreatedByUserInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutCreatedByUserInput>>;
  createMany?: Maybe<MassTextAlertCreateManyCreatedByUserInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
};

export type MassTextAlertCreateNestedOneWithoutMassTextAlertEntriesInput = {
  create?: Maybe<MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput>;
  connectOrCreate?: Maybe<MassTextAlertCreateOrConnectWithoutMassTextAlertEntriesInput>;
  connect?: Maybe<MassTextAlertWhereUniqueInput>;
};

export type MassTextAlertCreateOrConnectWithoutClinicInput = {
  where: MassTextAlertWhereUniqueInput;
  create: MassTextAlertUncheckedCreateWithoutClinicInput;
};

export type MassTextAlertCreateOrConnectWithoutCreatedByUserInput = {
  where: MassTextAlertWhereUniqueInput;
  create: MassTextAlertUncheckedCreateWithoutCreatedByUserInput;
};

export type MassTextAlertCreateOrConnectWithoutMassTextAlertEntriesInput = {
  where: MassTextAlertWhereUniqueInput;
  create: MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput;
};

export type MassTextAlertCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  createdByUser: UserCreateNestedOneWithoutMassTextAlertsInput;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutMassTextAlertInput>;
};

export type MassTextAlertCreateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  clinic: ClinicCreateNestedOneWithoutMassTextAlertsInput;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutMassTextAlertInput>;
};

export type MassTextAlertCreateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  createdByUser: UserCreateNestedOneWithoutMassTextAlertsInput;
  clinic: ClinicCreateNestedOneWithoutMassTextAlertsInput;
};

export type MassTextAlertEntry = {
  __typename?: 'MassTextAlertEntry';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  massTextAlertId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
  massTextAlert: MassTextAlert;
  clinicPetParent: ClinicPetParent;
  massAlertEntryAppointments: Array<MassTextAlertEntryAppointment>;
  _count: MassTextAlertEntryCountOutputType;
};


export type MassTextAlertEntryMassAlertEntryAppointmentsArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  orderBy?: Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>;
};

export type MassTextAlertEntryAppointment = {
  __typename?: 'MassTextAlertEntryAppointment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  massTextAlertEntryId: Scalars['String'];
  appointmentId: Scalars['String'];
  massTextAlertEntry: MassTextAlertEntry;
  appointment: Appointment;
};

export type MassTextAlertEntryAppointmentCountAggregateOutputType = {
  __typename?: 'MassTextAlertEntryAppointmentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  massTextAlertEntryId: Scalars['Int'];
  appointmentId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type MassTextAlertEntryAppointmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
};

export type MassTextAlertEntryAppointmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntry: MassTextAlertEntryCreateNestedOneWithoutMassAlertEntryAppointmentsInput;
  appointment: AppointmentCreateNestedOneWithoutMassTextAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryAppointmentCreateManyAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope = {
  data: Array<MassTextAlertEntryAppointmentCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertEntryAppointmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId: Scalars['String'];
  appointmentId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope = {
  data: Array<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
};

export type MassTextAlertEntryAppointmentCreateNestedManyWithoutMassTextAlertEntryInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
};

export type MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
  create: MassTextAlertEntryAppointmentUncheckedCreateWithoutAppointmentInput;
};

export type MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
  create: MassTextAlertEntryAppointmentUncheckedCreateWithoutMassTextAlertEntryInput;
};

export type MassTextAlertEntryAppointmentCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntry: MassTextAlertEntryCreateNestedOneWithoutMassAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointment: AppointmentCreateNestedOneWithoutMassTextAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryAppointmentListRelationFilter = {
  every?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  some?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  none?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};

export type MassTextAlertEntryAppointmentMassTextAlertEntryIdAppointmentIdCompoundUniqueInput = {
  massTextAlertEntryId: Scalars['String'];
  appointmentId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentMaxAggregateOutputType = {
  __typename?: 'MassTextAlertEntryAppointmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
};

export type MassTextAlertEntryAppointmentMinAggregateOutputType = {
  __typename?: 'MassTextAlertEntryAppointmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
};

export type MassTextAlertEntryAppointmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MassTextAlertEntryAppointmentOrderByRelevanceFieldEnum {
  Id = 'id',
  MassTextAlertEntryId = 'massTextAlertEntryId',
  AppointmentId = 'appointmentId'
}

export type MassTextAlertEntryAppointmentOrderByRelevanceInput = {
  fields: Array<MassTextAlertEntryAppointmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type MassTextAlertEntryAppointmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  _count?: Maybe<MassTextAlertEntryAppointmentCountOrderByAggregateInput>;
  _max?: Maybe<MassTextAlertEntryAppointmentMaxOrderByAggregateInput>;
  _min?: Maybe<MassTextAlertEntryAppointmentMinOrderByAggregateInput>;
};

export type MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  massTextAlertEntry?: Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<MassTextAlertEntryAppointmentOrderByRelevanceInput>;
};

export enum MassTextAlertEntryAppointmentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  MassTextAlertEntryId = 'massTextAlertEntryId',
  AppointmentId = 'appointmentId'
}

export type MassTextAlertEntryAppointmentScalarWhereInput = {
  AND?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
  OR?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  massTextAlertEntryId?: Maybe<StringFilter>;
  appointmentId?: Maybe<StringFilter>;
};

export type MassTextAlertEntryAppointmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  massTextAlertEntryId?: Maybe<StringWithAggregatesFilter>;
  appointmentId?: Maybe<StringWithAggregatesFilter>;
};

export type MassTextAlertEntryAppointmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId: Scalars['String'];
  appointmentId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
};

export type MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutMassTextAlertEntryInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
};

export type MassTextAlertEntryAppointmentUncheckedCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentUncheckedCreateWithoutMassTextAlertEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryNestedInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutMassTextAlertEntryInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutMassTextAlertEntryInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutMassTextAlertEntryInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateWithoutMassTextAlertEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntry?: Maybe<MassTextAlertEntryUpdateOneRequiredWithoutMassAlertEntryAppointmentsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneRequiredWithoutMassTextAlertEntryAppointmentsNestedInput>;
};

export type MassTextAlertEntryAppointmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutAppointmentInput = {
  where: MassTextAlertEntryAppointmentScalarWhereInput;
  data: MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutMassTextAlertEntryInput = {
  where: MassTextAlertEntryAppointmentScalarWhereInput;
  data: MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
};

export type MassTextAlertEntryAppointmentUpdateManyWithoutMassTextAlertEntryNestedInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutMassTextAlertEntryInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutMassTextAlertEntryInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutMassTextAlertEntryInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
};

export type MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutAppointmentInput = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
  data: MassTextAlertEntryAppointmentUncheckedUpdateWithoutAppointmentInput;
};

export type MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutMassTextAlertEntryInput = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
  data: MassTextAlertEntryAppointmentUncheckedUpdateWithoutMassTextAlertEntryInput;
};

export type MassTextAlertEntryAppointmentUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntry?: Maybe<MassTextAlertEntryUpdateOneRequiredWithoutMassAlertEntryAppointmentsNestedInput>;
};

export type MassTextAlertEntryAppointmentUpdateWithoutMassTextAlertEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentUpdateOneRequiredWithoutMassTextAlertEntryAppointmentsNestedInput>;
};

export type MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutAppointmentInput = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
  update: MassTextAlertEntryAppointmentUncheckedUpdateWithoutAppointmentInput;
  create: MassTextAlertEntryAppointmentUncheckedCreateWithoutAppointmentInput;
};

export type MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutMassTextAlertEntryInput = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
  update: MassTextAlertEntryAppointmentUncheckedUpdateWithoutMassTextAlertEntryInput;
  create: MassTextAlertEntryAppointmentUncheckedCreateWithoutMassTextAlertEntryInput;
};

export type MassTextAlertEntryAppointmentWhereInput = {
  AND?: Maybe<Array<MassTextAlertEntryAppointmentWhereInput>>;
  OR?: Maybe<Array<MassTextAlertEntryAppointmentWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryAppointmentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  massTextAlertEntryId?: Maybe<StringFilter>;
  appointmentId?: Maybe<StringFilter>;
  massTextAlertEntry?: Maybe<MassTextAlertEntryWhereInput>;
  appointment?: Maybe<AppointmentWhereInput>;
};

export type MassTextAlertEntryAppointmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  massTextAlertEntryId_appointmentId?: Maybe<MassTextAlertEntryAppointmentMassTextAlertEntryIdAppointmentIdCompoundUniqueInput>;
};

export type MassTextAlertEntryCountAggregateOutputType = {
  __typename?: 'MassTextAlertEntryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  massTextAlertId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  _all: Scalars['Int'];
};

export type MassTextAlertEntryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
};

export type MassTextAlertEntryCountOutputType = {
  __typename?: 'MassTextAlertEntryCountOutputType';
  massAlertEntryAppointments: Scalars['Int'];
};

export type MassTextAlertEntryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber: Scalars['String'];
  massTextAlert: MassTextAlertCreateNestedOneWithoutMassTextAlertEntriesInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutMassTextAlertEntriesInput;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type MassTextAlertEntryCreateManyClinicPetParentInputEnvelope = {
  data: Array<MassTextAlertEntryCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertEntryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type MassTextAlertEntryCreateManyMassTextAlertInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type MassTextAlertEntryCreateManyMassTextAlertInputEnvelope = {
  data: Array<MassTextAlertEntryCreateManyMassTextAlertInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
};

export type MassTextAlertEntryCreateNestedManyWithoutMassTextAlertInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutMassTextAlertInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyMassTextAlertInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
};

export type MassTextAlertEntryCreateNestedOneWithoutMassAlertEntryAppointmentsInput = {
  create?: Maybe<MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput>;
  connectOrCreate?: Maybe<MassTextAlertEntryCreateOrConnectWithoutMassAlertEntryAppointmentsInput>;
  connect?: Maybe<MassTextAlertEntryWhereUniqueInput>;
};

export type MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput = {
  where: MassTextAlertEntryWhereUniqueInput;
  create: MassTextAlertEntryUncheckedCreateWithoutClinicPetParentInput;
};

export type MassTextAlertEntryCreateOrConnectWithoutMassAlertEntryAppointmentsInput = {
  where: MassTextAlertEntryWhereUniqueInput;
  create: MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput = {
  where: MassTextAlertEntryWhereUniqueInput;
  create: MassTextAlertEntryUncheckedCreateWithoutMassTextAlertInput;
};

export type MassTextAlertEntryCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber: Scalars['String'];
  massTextAlert: MassTextAlertCreateNestedOneWithoutMassTextAlertEntriesInput;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryCreateWithoutMassAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber: Scalars['String'];
  massTextAlert: MassTextAlertCreateNestedOneWithoutMassTextAlertEntriesInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutMassTextAlertEntriesInput;
};

export type MassTextAlertEntryCreateWithoutMassTextAlertInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber: Scalars['String'];
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutMassTextAlertEntriesInput;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryListRelationFilter = {
  every?: Maybe<MassTextAlertEntryWhereInput>;
  some?: Maybe<MassTextAlertEntryWhereInput>;
  none?: Maybe<MassTextAlertEntryWhereInput>;
};

export type MassTextAlertEntryMaxAggregateOutputType = {
  __typename?: 'MassTextAlertEntryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
};

export type MassTextAlertEntryMinAggregateOutputType = {
  __typename?: 'MassTextAlertEntryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
};

export type MassTextAlertEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MassTextAlertEntryOrderByRelevanceFieldEnum {
  Id = 'id',
  MassTextAlertId = 'massTextAlertId',
  ClinicPetParentId = 'clinicPetParentId',
  PhoneNumber = 'phoneNumber'
}

export type MassTextAlertEntryOrderByRelevanceInput = {
  fields: Array<MassTextAlertEntryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type MassTextAlertEntryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  _count?: Maybe<MassTextAlertEntryCountOrderByAggregateInput>;
  _max?: Maybe<MassTextAlertEntryMaxOrderByAggregateInput>;
  _min?: Maybe<MassTextAlertEntryMinOrderByAggregateInput>;
};

export type MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  massTextAlert?: Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentOrderByRelationAggregateInput>;
  _relevance?: Maybe<MassTextAlertEntryOrderByRelevanceInput>;
};

export type MassTextAlertEntryRelationFilter = {
  is?: Maybe<MassTextAlertEntryWhereInput>;
  isNot?: Maybe<MassTextAlertEntryWhereInput>;
};

export enum MassTextAlertEntryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  MassTextAlertId = 'massTextAlertId',
  ClinicPetParentId = 'clinicPetParentId',
  PhoneNumber = 'phoneNumber'
}

export type MassTextAlertEntryScalarWhereInput = {
  AND?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
  OR?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  massTextAlertId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
};

export type MassTextAlertEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MassTextAlertEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MassTextAlertEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  massTextAlertId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  phoneNumber?: Maybe<StringWithAggregatesFilter>;
};

export type MassTextAlertEntryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
};

export type MassTextAlertEntryUncheckedCreateNestedManyWithoutMassTextAlertInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutMassTextAlertInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyMassTextAlertInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
};

export type MassTextAlertEntryUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId: Scalars['String'];
  phoneNumber: Scalars['String'];
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type MassTextAlertEntryUncheckedCreateWithoutMassTextAlertInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryNestedInput>;
};

export type MassTextAlertEntryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
};

export type MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertNestedInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutMassTextAlertInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryUpsertWithWhereUniqueWithoutMassTextAlertInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyMassTextAlertInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryUpdateWithWhereUniqueWithoutMassTextAlertInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryUpdateManyWithWhereWithoutMassTextAlertInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
};

export type MassTextAlertEntryUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryNestedInput>;
};

export type MassTextAlertEntryUncheckedUpdateWithoutMassAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryUncheckedUpdateWithoutMassTextAlertInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryNestedInput>;
};

export type MassTextAlertEntryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  massTextAlert?: Maybe<MassTextAlertUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutMassTextAlertEntryNestedInput>;
};

export type MassTextAlertEntryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: MassTextAlertEntryScalarWhereInput;
  data: MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertEntriesInput;
};

export type MassTextAlertEntryUpdateManyWithWhereWithoutMassTextAlertInput = {
  where: MassTextAlertEntryScalarWhereInput;
  data: MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertEntriesInput;
};

export type MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
};

export type MassTextAlertEntryUpdateManyWithoutMassTextAlertNestedInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutMassTextAlertInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryUpsertWithWhereUniqueWithoutMassTextAlertInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyMassTextAlertInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryUpdateWithWhereUniqueWithoutMassTextAlertInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryUpdateManyWithWhereWithoutMassTextAlertInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
};

export type MassTextAlertEntryUpdateOneRequiredWithoutMassAlertEntryAppointmentsNestedInput = {
  create?: Maybe<MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput>;
  connectOrCreate?: Maybe<MassTextAlertEntryCreateOrConnectWithoutMassAlertEntryAppointmentsInput>;
  upsert?: Maybe<MassTextAlertEntryUpsertWithoutMassAlertEntryAppointmentsInput>;
  connect?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  update?: Maybe<MassTextAlertEntryUncheckedUpdateWithoutMassAlertEntryAppointmentsInput>;
};

export type MassTextAlertEntryUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: MassTextAlertEntryWhereUniqueInput;
  data: MassTextAlertEntryUncheckedUpdateWithoutClinicPetParentInput;
};

export type MassTextAlertEntryUpdateWithWhereUniqueWithoutMassTextAlertInput = {
  where: MassTextAlertEntryWhereUniqueInput;
  data: MassTextAlertEntryUncheckedUpdateWithoutMassTextAlertInput;
};

export type MassTextAlertEntryUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  massTextAlert?: Maybe<MassTextAlertUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutMassTextAlertEntryNestedInput>;
};

export type MassTextAlertEntryUpdateWithoutMassAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  massTextAlert?: Maybe<MassTextAlertUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput>;
};

export type MassTextAlertEntryUpdateWithoutMassTextAlertInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutMassTextAlertEntryNestedInput>;
};

export type MassTextAlertEntryUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: MassTextAlertEntryWhereUniqueInput;
  update: MassTextAlertEntryUncheckedUpdateWithoutClinicPetParentInput;
  create: MassTextAlertEntryUncheckedCreateWithoutClinicPetParentInput;
};

export type MassTextAlertEntryUpsertWithWhereUniqueWithoutMassTextAlertInput = {
  where: MassTextAlertEntryWhereUniqueInput;
  update: MassTextAlertEntryUncheckedUpdateWithoutMassTextAlertInput;
  create: MassTextAlertEntryUncheckedCreateWithoutMassTextAlertInput;
};

export type MassTextAlertEntryUpsertWithoutMassAlertEntryAppointmentsInput = {
  update: MassTextAlertEntryUncheckedUpdateWithoutMassAlertEntryAppointmentsInput;
  create: MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryWhereInput = {
  AND?: Maybe<Array<MassTextAlertEntryWhereInput>>;
  OR?: Maybe<Array<MassTextAlertEntryWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  massTextAlertId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
  massTextAlert?: Maybe<MassTextAlertWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentListRelationFilter>;
};

export type MassTextAlertEntryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type MassTextAlertListRelationFilter = {
  every?: Maybe<MassTextAlertWhereInput>;
  some?: Maybe<MassTextAlertWhereInput>;
  none?: Maybe<MassTextAlertWhereInput>;
};

export type MassTextAlertMaxAggregateOutputType = {
  __typename?: 'MassTextAlertMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
};

export type MassTextAlertMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  alertBody?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
};

export type MassTextAlertMinAggregateOutputType = {
  __typename?: 'MassTextAlertMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
};

export type MassTextAlertMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  alertBody?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
};

export type MassTextAlertOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MassTextAlertOrderByRelevanceFieldEnum {
  Id = 'id',
  CreatedByUserId = 'createdByUserId',
  ClinicId = 'clinicId',
  AlertBody = 'alertBody'
}

export type MassTextAlertOrderByRelevanceInput = {
  fields: Array<MassTextAlertOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type MassTextAlertOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  alertBody?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
  _count?: Maybe<MassTextAlertCountOrderByAggregateInput>;
  _max?: Maybe<MassTextAlertMaxOrderByAggregateInput>;
  _min?: Maybe<MassTextAlertMinOrderByAggregateInput>;
};

export type MassTextAlertOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  alertBody?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
  createdByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryOrderByRelationAggregateInput>;
  _relevance?: Maybe<MassTextAlertOrderByRelevanceInput>;
};

export type MassTextAlertRelationFilter = {
  is?: Maybe<MassTextAlertWhereInput>;
  isNot?: Maybe<MassTextAlertWhereInput>;
};

export type MassTextAlertReportEntry = {
  __typename?: 'MassTextAlertReportEntry';
  phoneNumber: Scalars['String'];
  petParentFullName: Scalars['String'];
  smsStatus?: Maybe<SmsNotificationStatusType>;
  errorDetails?: Maybe<Scalars['String']>;
};

export enum MassTextAlertScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  AppointmentDateTimeFrom = 'appointmentDateTimeFrom',
  AppointmentDateTimeTo = 'appointmentDateTimeTo',
  CreatedByUserId = 'createdByUserId',
  ClinicId = 'clinicId',
  AlertBody = 'alertBody',
  Status = 'status',
  AppointmentDateRangeType = 'appointmentDateRangeType'
}

export type MassTextAlertScalarWhereInput = {
  AND?: Maybe<Array<MassTextAlertScalarWhereInput>>;
  OR?: Maybe<Array<MassTextAlertScalarWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  appointmentDateTimeFrom?: Maybe<DateTimeFilter>;
  appointmentDateTimeTo?: Maybe<DateTimeFilter>;
  createdByUserId?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  alertBody?: Maybe<StringFilter>;
  status?: Maybe<EnumMassTextAlertStatusFilter>;
  appointmentDateRangeType?: Maybe<EnumAppointmentDateRangeTypeFilter>;
};

export type MassTextAlertScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MassTextAlertScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MassTextAlertScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MassTextAlertScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  appointmentDateTimeFrom?: Maybe<DateTimeWithAggregatesFilter>;
  appointmentDateTimeTo?: Maybe<DateTimeWithAggregatesFilter>;
  createdByUserId?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  alertBody?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumMassTextAlertStatusWithAggregatesFilter>;
  appointmentDateRangeType?: Maybe<EnumAppointmentDateRangeTypeWithAggregatesFilter>;
};

export enum MassTextAlertStatus {
  Processing = 'Processing',
  Sent = 'Sent'
}

export type MassTextAlertSubscription = {
  __typename?: 'MassTextAlertSubscription';
  node: MassTextAlert;
  mutation: MutationType;
};

export type MassTextAlertUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  clinicId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutMassTextAlertInput>;
};

export type MassTextAlertUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<MassTextAlertCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
};

export type MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutCreatedByUserInput>>;
  createMany?: Maybe<MassTextAlertCreateManyCreatedByUserInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
};

export type MassTextAlertUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutMassTextAlertInput>;
};

export type MassTextAlertUncheckedCreateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  clinicId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutMassTextAlertInput>;
};

export type MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  clinicId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
};

export type MassTextAlertUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertNestedInput>;
};

export type MassTextAlertUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
};

export type MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<MassTextAlertUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<MassTextAlertCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MassTextAlertUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<MassTextAlertScalarWhereInput>>;
};

export type MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutCreatedByUserInput>>;
  upsert?: Maybe<Array<MassTextAlertUpsertWithWhereUniqueWithoutCreatedByUserInput>>;
  createMany?: Maybe<MassTextAlertCreateManyCreatedByUserInputEnvelope>;
  set?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertUpdateWithWhereUniqueWithoutCreatedByUserInput>>;
  updateMany?: Maybe<Array<MassTextAlertUpdateManyWithWhereWithoutCreatedByUserInput>>;
  deleteMany?: Maybe<Array<MassTextAlertScalarWhereInput>>;
};

export type MassTextAlertUncheckedUpdateManyWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
};

export type MassTextAlertUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertNestedInput>;
};

export type MassTextAlertUncheckedUpdateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertNestedInput>;
};

export type MassTextAlertUncheckedUpdateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
};

export type MassTextAlertUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  createdByUser?: Maybe<UserUpdateOneRequiredWithoutMassTextAlertsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMassTextAlertsNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutMassTextAlertNestedInput>;
};

export type MassTextAlertUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
};

export type MassTextAlertUpdateManyWithWhereWithoutClinicInput = {
  where: MassTextAlertScalarWhereInput;
  data: MassTextAlertUncheckedUpdateManyWithoutMassTextAlertsInput;
};

export type MassTextAlertUpdateManyWithWhereWithoutCreatedByUserInput = {
  where: MassTextAlertScalarWhereInput;
  data: MassTextAlertUncheckedUpdateManyWithoutMassTextAlertsInput;
};

export type MassTextAlertUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<MassTextAlertUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<MassTextAlertCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MassTextAlertUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<MassTextAlertScalarWhereInput>>;
};

export type MassTextAlertUpdateManyWithoutCreatedByUserNestedInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutCreatedByUserInput>>;
  upsert?: Maybe<Array<MassTextAlertUpsertWithWhereUniqueWithoutCreatedByUserInput>>;
  createMany?: Maybe<MassTextAlertCreateManyCreatedByUserInputEnvelope>;
  set?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertUpdateWithWhereUniqueWithoutCreatedByUserInput>>;
  updateMany?: Maybe<Array<MassTextAlertUpdateManyWithWhereWithoutCreatedByUserInput>>;
  deleteMany?: Maybe<Array<MassTextAlertScalarWhereInput>>;
};

export type MassTextAlertUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput = {
  create?: Maybe<MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput>;
  connectOrCreate?: Maybe<MassTextAlertCreateOrConnectWithoutMassTextAlertEntriesInput>;
  upsert?: Maybe<MassTextAlertUpsertWithoutMassTextAlertEntriesInput>;
  connect?: Maybe<MassTextAlertWhereUniqueInput>;
  update?: Maybe<MassTextAlertUncheckedUpdateWithoutMassTextAlertEntriesInput>;
};

export type MassTextAlertUpdateWithWhereUniqueWithoutClinicInput = {
  where: MassTextAlertWhereUniqueInput;
  data: MassTextAlertUncheckedUpdateWithoutClinicInput;
};

export type MassTextAlertUpdateWithWhereUniqueWithoutCreatedByUserInput = {
  where: MassTextAlertWhereUniqueInput;
  data: MassTextAlertUncheckedUpdateWithoutCreatedByUserInput;
};

export type MassTextAlertUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  createdByUser?: Maybe<UserUpdateOneRequiredWithoutMassTextAlertsNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutMassTextAlertNestedInput>;
};

export type MassTextAlertUpdateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMassTextAlertsNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutMassTextAlertNestedInput>;
};

export type MassTextAlertUpdateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  createdByUser?: Maybe<UserUpdateOneRequiredWithoutMassTextAlertsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMassTextAlertsNestedInput>;
};

export type MassTextAlertUpsertWithWhereUniqueWithoutClinicInput = {
  where: MassTextAlertWhereUniqueInput;
  update: MassTextAlertUncheckedUpdateWithoutClinicInput;
  create: MassTextAlertUncheckedCreateWithoutClinicInput;
};

export type MassTextAlertUpsertWithWhereUniqueWithoutCreatedByUserInput = {
  where: MassTextAlertWhereUniqueInput;
  update: MassTextAlertUncheckedUpdateWithoutCreatedByUserInput;
  create: MassTextAlertUncheckedCreateWithoutCreatedByUserInput;
};

export type MassTextAlertUpsertWithoutMassTextAlertEntriesInput = {
  update: MassTextAlertUncheckedUpdateWithoutMassTextAlertEntriesInput;
  create: MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput;
};

export type MassTextAlertWhereInput = {
  AND?: Maybe<Array<MassTextAlertWhereInput>>;
  OR?: Maybe<Array<MassTextAlertWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  appointmentDateTimeFrom?: Maybe<DateTimeFilter>;
  appointmentDateTimeTo?: Maybe<DateTimeFilter>;
  createdByUserId?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  alertBody?: Maybe<StringFilter>;
  status?: Maybe<EnumMassTextAlertStatusFilter>;
  appointmentDateRangeType?: Maybe<EnumAppointmentDateRangeTypeFilter>;
  createdByUser?: Maybe<UserWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryListRelationFilter>;
};

export type MassTextAlertWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Media = {
  __typename?: 'Media';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: MediaType;
  version: MediaVersion;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<Media>;
  children: Array<Media>;
  channelMessageAttachments: Array<ChannelMessageAttachment>;
  clinicPets: Array<ClinicPet>;
  users: Array<User>;
  clinic?: Maybe<Clinic>;
  products: Array<Product>;
  _count: MediaCountOutputType;
};


export type MediaChildrenArgs = {
  where?: Maybe<MediaWhereInput>;
  orderBy?: Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MediaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MediaScalarFieldEnum>;
};


export type MediaChannelMessageAttachmentsArgs = {
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
  orderBy?: Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageAttachmentScalarFieldEnum>;
};


export type MediaClinicPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};


export type MediaUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};


export type MediaProductsArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ProductScalarFieldEnum>;
};

export type MediaAvgAggregateOutputType = {
  __typename?: 'MediaAvgAggregateOutputType';
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type MediaAvgOrderByAggregateInput = {
  sizeInMegabytes?: Maybe<SortOrder>;
  durationInSeconds?: Maybe<SortOrder>;
  height?: Maybe<SortOrder>;
  width?: Maybe<SortOrder>;
};

export type MediaCountAggregateOutputType = {
  __typename?: 'MediaCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  type: Scalars['Int'];
  version: Scalars['Int'];
  parentMediaId: Scalars['Int'];
  s3Bucket: Scalars['Int'];
  s3Key: Scalars['Int'];
  clinicId: Scalars['Int'];
  url: Scalars['Int'];
  checksum: Scalars['Int'];
  format: Scalars['Int'];
  sizeInMegabytes: Scalars['Int'];
  durationInSeconds: Scalars['Int'];
  height: Scalars['Int'];
  width: Scalars['Int'];
  properties: Scalars['Int'];
  _all: Scalars['Int'];
};

export type MediaCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
  parentMediaId?: Maybe<SortOrder>;
  s3Bucket?: Maybe<SortOrder>;
  s3Key?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  format?: Maybe<SortOrder>;
  sizeInMegabytes?: Maybe<SortOrder>;
  durationInSeconds?: Maybe<SortOrder>;
  height?: Maybe<SortOrder>;
  width?: Maybe<SortOrder>;
  properties?: Maybe<SortOrder>;
};

export type MediaCountOutputType = {
  __typename?: 'MediaCountOutputType';
  children: Scalars['Int'];
  channelMessageAttachments: Scalars['Int'];
  clinicPets: Scalars['Int'];
  users: Scalars['Int'];
  products: Scalars['Int'];
};

export type MediaCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<MediaCreateNestedOneWithoutChildrenInput>;
  children?: Maybe<MediaCreateNestedManyWithoutParentInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutMediaInput>;
  users?: Maybe<UserCreateNestedManyWithoutMediaInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutMediaInput>;
  products?: Maybe<ProductCreateNestedManyWithoutMediaInput>;
};

export type MediaCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
};

export type MediaCreateManyClinicInputEnvelope = {
  data: Array<MediaCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MediaCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
};

export type MediaCreateManyParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
};

export type MediaCreateManyParentInputEnvelope = {
  data: Array<MediaCreateManyParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MediaCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<MediaCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<MediaCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
};

export type MediaCreateNestedManyWithoutParentInput = {
  create?: Maybe<Array<MediaCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutParentInput>>;
  createMany?: Maybe<MediaCreateManyParentInputEnvelope>;
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
};

export type MediaCreateNestedManyWithoutProductsInput = {
  create?: Maybe<Array<MediaCreateWithoutProductsInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutProductsInput>>;
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
};

export type MediaCreateNestedOneWithoutChannelMessageAttachmentsInput = {
  create?: Maybe<MediaUncheckedCreateWithoutChannelMessageAttachmentsInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutChannelMessageAttachmentsInput>;
  connect?: Maybe<MediaWhereUniqueInput>;
};

export type MediaCreateNestedOneWithoutChildrenInput = {
  create?: Maybe<MediaUncheckedCreateWithoutChildrenInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutChildrenInput>;
  connect?: Maybe<MediaWhereUniqueInput>;
};

export type MediaCreateNestedOneWithoutClinicPetsInput = {
  create?: Maybe<MediaUncheckedCreateWithoutClinicPetsInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutClinicPetsInput>;
  connect?: Maybe<MediaWhereUniqueInput>;
};

export type MediaCreateNestedOneWithoutUsersInput = {
  create?: Maybe<MediaUncheckedCreateWithoutUsersInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutUsersInput>;
  connect?: Maybe<MediaWhereUniqueInput>;
};

export type MediaCreateOrConnectWithoutChannelMessageAttachmentsInput = {
  where: MediaWhereUniqueInput;
  create: MediaUncheckedCreateWithoutChannelMessageAttachmentsInput;
};

export type MediaCreateOrConnectWithoutChildrenInput = {
  where: MediaWhereUniqueInput;
  create: MediaUncheckedCreateWithoutChildrenInput;
};

export type MediaCreateOrConnectWithoutClinicInput = {
  where: MediaWhereUniqueInput;
  create: MediaUncheckedCreateWithoutClinicInput;
};

export type MediaCreateOrConnectWithoutClinicPetsInput = {
  where: MediaWhereUniqueInput;
  create: MediaUncheckedCreateWithoutClinicPetsInput;
};

export type MediaCreateOrConnectWithoutParentInput = {
  where: MediaWhereUniqueInput;
  create: MediaUncheckedCreateWithoutParentInput;
};

export type MediaCreateOrConnectWithoutProductsInput = {
  where: MediaWhereUniqueInput;
  create: MediaUncheckedCreateWithoutProductsInput;
};

export type MediaCreateOrConnectWithoutUsersInput = {
  where: MediaWhereUniqueInput;
  create: MediaUncheckedCreateWithoutUsersInput;
};

export type MediaCreateWithoutChannelMessageAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<MediaCreateNestedOneWithoutChildrenInput>;
  children?: Maybe<MediaCreateNestedManyWithoutParentInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutMediaInput>;
  users?: Maybe<UserCreateNestedManyWithoutMediaInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutMediaInput>;
  products?: Maybe<ProductCreateNestedManyWithoutMediaInput>;
};

export type MediaCreateWithoutChildrenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<MediaCreateNestedOneWithoutChildrenInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutMediaInput>;
  users?: Maybe<UserCreateNestedManyWithoutMediaInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutMediaInput>;
  products?: Maybe<ProductCreateNestedManyWithoutMediaInput>;
};

export type MediaCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<MediaCreateNestedOneWithoutChildrenInput>;
  children?: Maybe<MediaCreateNestedManyWithoutParentInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutMediaInput>;
  users?: Maybe<UserCreateNestedManyWithoutMediaInput>;
  products?: Maybe<ProductCreateNestedManyWithoutMediaInput>;
};

export type MediaCreateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<MediaCreateNestedOneWithoutChildrenInput>;
  children?: Maybe<MediaCreateNestedManyWithoutParentInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutMediaInput>;
  users?: Maybe<UserCreateNestedManyWithoutMediaInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutMediaInput>;
  products?: Maybe<ProductCreateNestedManyWithoutMediaInput>;
};

export type MediaCreateWithoutParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaCreateNestedManyWithoutParentInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutMediaInput>;
  users?: Maybe<UserCreateNestedManyWithoutMediaInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutMediaInput>;
  products?: Maybe<ProductCreateNestedManyWithoutMediaInput>;
};

export type MediaCreateWithoutProductsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<MediaCreateNestedOneWithoutChildrenInput>;
  children?: Maybe<MediaCreateNestedManyWithoutParentInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutMediaInput>;
  users?: Maybe<UserCreateNestedManyWithoutMediaInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutMediaInput>;
};

export type MediaCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<MediaCreateNestedOneWithoutChildrenInput>;
  children?: Maybe<MediaCreateNestedManyWithoutParentInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutMediaInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutMediaInput>;
  products?: Maybe<ProductCreateNestedManyWithoutMediaInput>;
};

export type MediaListRelationFilter = {
  every?: Maybe<MediaWhereInput>;
  some?: Maybe<MediaWhereInput>;
  none?: Maybe<MediaWhereInput>;
};

export type MediaMaxAggregateOutputType = {
  __typename?: 'MediaMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
  parentMediaId?: Maybe<SortOrder>;
  s3Bucket?: Maybe<SortOrder>;
  s3Key?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  format?: Maybe<SortOrder>;
  sizeInMegabytes?: Maybe<SortOrder>;
  durationInSeconds?: Maybe<SortOrder>;
  height?: Maybe<SortOrder>;
  width?: Maybe<SortOrder>;
};

export type MediaMinAggregateOutputType = {
  __typename?: 'MediaMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
  parentMediaId?: Maybe<SortOrder>;
  s3Bucket?: Maybe<SortOrder>;
  s3Key?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  format?: Maybe<SortOrder>;
  sizeInMegabytes?: Maybe<SortOrder>;
  durationInSeconds?: Maybe<SortOrder>;
  height?: Maybe<SortOrder>;
  width?: Maybe<SortOrder>;
};

export type MediaOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MediaOrderByRelevanceFieldEnum {
  Id = 'id',
  ParentMediaId = 'parentMediaId',
  S3Bucket = 's3Bucket',
  S3Key = 's3Key',
  ClinicId = 'clinicId',
  Url = 'url',
  Checksum = 'checksum',
  Format = 'format'
}

export type MediaOrderByRelevanceInput = {
  fields: Array<MediaOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type MediaOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
  parentMediaId?: Maybe<SortOrder>;
  s3Bucket?: Maybe<SortOrder>;
  s3Key?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  format?: Maybe<SortOrder>;
  sizeInMegabytes?: Maybe<SortOrder>;
  durationInSeconds?: Maybe<SortOrder>;
  height?: Maybe<SortOrder>;
  width?: Maybe<SortOrder>;
  properties?: Maybe<SortOrder>;
  _count?: Maybe<MediaCountOrderByAggregateInput>;
  _avg?: Maybe<MediaAvgOrderByAggregateInput>;
  _max?: Maybe<MediaMaxOrderByAggregateInput>;
  _min?: Maybe<MediaMinOrderByAggregateInput>;
  _sum?: Maybe<MediaSumOrderByAggregateInput>;
};

export type MediaOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
  parentMediaId?: Maybe<SortOrder>;
  s3Bucket?: Maybe<SortOrder>;
  s3Key?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  format?: Maybe<SortOrder>;
  sizeInMegabytes?: Maybe<SortOrder>;
  durationInSeconds?: Maybe<SortOrder>;
  height?: Maybe<SortOrder>;
  width?: Maybe<SortOrder>;
  properties?: Maybe<SortOrder>;
  parent?: Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  children?: Maybe<MediaOrderByRelationAggregateInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentOrderByRelationAggregateInput>;
  clinicPets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  users?: Maybe<UserOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  products?: Maybe<ProductOrderByRelationAggregateInput>;
  _relevance?: Maybe<MediaOrderByRelevanceInput>;
};

export type MediaRelationFilter = {
  is?: Maybe<MediaWhereInput>;
  isNot?: Maybe<MediaWhereInput>;
};

export enum MediaScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Type = 'type',
  Version = 'version',
  ParentMediaId = 'parentMediaId',
  S3Bucket = 's3Bucket',
  S3Key = 's3Key',
  ClinicId = 'clinicId',
  Url = 'url',
  Checksum = 'checksum',
  Format = 'format',
  SizeInMegabytes = 'sizeInMegabytes',
  DurationInSeconds = 'durationInSeconds',
  Height = 'height',
  Width = 'width',
  Properties = 'properties'
}

export type MediaScalarWhereInput = {
  AND?: Maybe<Array<MediaScalarWhereInput>>;
  OR?: Maybe<Array<MediaScalarWhereInput>>;
  NOT?: Maybe<Array<MediaScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumMediaTypeFilter>;
  version?: Maybe<EnumMediaVersionFilter>;
  parentMediaId?: Maybe<StringNullableFilter>;
  s3Bucket?: Maybe<StringNullableFilter>;
  s3Key?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  url?: Maybe<StringFilter>;
  checksum?: Maybe<StringFilter>;
  format?: Maybe<StringFilter>;
  sizeInMegabytes?: Maybe<FloatFilter>;
  durationInSeconds?: Maybe<FloatNullableFilter>;
  height?: Maybe<IntNullableFilter>;
  width?: Maybe<IntNullableFilter>;
  properties?: Maybe<JsonNullableFilter>;
};

export type MediaScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MediaScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MediaScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MediaScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  type?: Maybe<EnumMediaTypeWithAggregatesFilter>;
  version?: Maybe<EnumMediaVersionWithAggregatesFilter>;
  parentMediaId?: Maybe<StringNullableWithAggregatesFilter>;
  s3Bucket?: Maybe<StringNullableWithAggregatesFilter>;
  s3Key?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  url?: Maybe<StringWithAggregatesFilter>;
  checksum?: Maybe<StringWithAggregatesFilter>;
  format?: Maybe<StringWithAggregatesFilter>;
  sizeInMegabytes?: Maybe<FloatWithAggregatesFilter>;
  durationInSeconds?: Maybe<FloatNullableWithAggregatesFilter>;
  height?: Maybe<IntNullableWithAggregatesFilter>;
  width?: Maybe<IntNullableWithAggregatesFilter>;
  properties?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type MediaSumAggregateOutputType = {
  __typename?: 'MediaSumAggregateOutputType';
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaSumOrderByAggregateInput = {
  sizeInMegabytes?: Maybe<SortOrder>;
  durationInSeconds?: Maybe<SortOrder>;
  height?: Maybe<SortOrder>;
  width?: Maybe<SortOrder>;
};

export enum MediaType {
  Image = 'Image',
  Video = 'Video',
  Audio = 'Audio'
}

export type MediaUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUncheckedCreateNestedManyWithoutParentInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutMediaInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutMediaInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutMediaInput>;
};

export type MediaUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<MediaCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<MediaCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
};

export type MediaUncheckedCreateNestedManyWithoutParentInput = {
  create?: Maybe<Array<MediaCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutParentInput>>;
  createMany?: Maybe<MediaCreateManyParentInputEnvelope>;
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
};

export type MediaUncheckedCreateNestedManyWithoutProductsInput = {
  create?: Maybe<Array<MediaCreateWithoutProductsInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutProductsInput>>;
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
};

export type MediaUncheckedCreateWithoutChannelMessageAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUncheckedCreateNestedManyWithoutParentInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutMediaInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutMediaInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutMediaInput>;
};

export type MediaUncheckedCreateWithoutChildrenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutMediaInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutMediaInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutMediaInput>;
};

export type MediaUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUncheckedCreateNestedManyWithoutParentInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutMediaInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutMediaInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutMediaInput>;
};

export type MediaUncheckedCreateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUncheckedCreateNestedManyWithoutParentInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutMediaInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutMediaInput>;
};

export type MediaUncheckedCreateWithoutParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUncheckedCreateNestedManyWithoutParentInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutMediaInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutMediaInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutMediaInput>;
};

export type MediaUncheckedCreateWithoutProductsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUncheckedCreateNestedManyWithoutParentInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutMediaInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutMediaInput>;
};

export type MediaUncheckedCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: MediaType;
  version: MediaVersion;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  checksum: Scalars['String'];
  format: Scalars['String'];
  sizeInMegabytes: Scalars['Float'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUncheckedCreateNestedManyWithoutParentInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutMediaInput>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutMediaInput>;
};

export type MediaUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUncheckedUpdateManyWithoutParentNestedInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutMediaNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutMediaNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutMediaNestedInput>;
};

export type MediaUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
};

export type MediaUncheckedUpdateManyWithoutChildrenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
};

export type MediaUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<MediaCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<MediaUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<MediaCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<MediaWhereUniqueInput>>;
  disconnect?: Maybe<Array<MediaWhereUniqueInput>>;
  delete?: Maybe<Array<MediaWhereUniqueInput>>;
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  update?: Maybe<Array<MediaUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MediaUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<MediaScalarWhereInput>>;
};

export type MediaUncheckedUpdateManyWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
};

export type MediaUncheckedUpdateManyWithoutParentNestedInput = {
  create?: Maybe<Array<MediaCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutParentInput>>;
  upsert?: Maybe<Array<MediaUpsertWithWhereUniqueWithoutParentInput>>;
  createMany?: Maybe<MediaCreateManyParentInputEnvelope>;
  set?: Maybe<Array<MediaWhereUniqueInput>>;
  disconnect?: Maybe<Array<MediaWhereUniqueInput>>;
  delete?: Maybe<Array<MediaWhereUniqueInput>>;
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  update?: Maybe<Array<MediaUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<MediaUpdateManyWithWhereWithoutParentInput>>;
  deleteMany?: Maybe<Array<MediaScalarWhereInput>>;
};

export type MediaUncheckedUpdateManyWithoutProductsNestedInput = {
  create?: Maybe<Array<MediaCreateWithoutProductsInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutProductsInput>>;
  upsert?: Maybe<Array<MediaUpsertWithWhereUniqueWithoutProductsInput>>;
  set?: Maybe<Array<MediaWhereUniqueInput>>;
  disconnect?: Maybe<Array<MediaWhereUniqueInput>>;
  delete?: Maybe<Array<MediaWhereUniqueInput>>;
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  update?: Maybe<Array<MediaUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: Maybe<Array<MediaUpdateManyWithWhereWithoutProductsInput>>;
  deleteMany?: Maybe<Array<MediaScalarWhereInput>>;
};

export type MediaUncheckedUpdateWithoutChannelMessageAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUncheckedUpdateManyWithoutParentNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutMediaNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutMediaNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutMediaNestedInput>;
};

export type MediaUncheckedUpdateWithoutChildrenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutMediaNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutMediaNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutMediaNestedInput>;
};

export type MediaUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUncheckedUpdateManyWithoutParentNestedInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutMediaNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutMediaNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutMediaNestedInput>;
};

export type MediaUncheckedUpdateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUncheckedUpdateManyWithoutParentNestedInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutMediaNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutMediaNestedInput>;
};

export type MediaUncheckedUpdateWithoutParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUncheckedUpdateManyWithoutParentNestedInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutMediaNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutMediaNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutMediaNestedInput>;
};

export type MediaUncheckedUpdateWithoutProductsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUncheckedUpdateManyWithoutParentNestedInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutMediaNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutMediaNestedInput>;
};

export type MediaUncheckedUpdateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUncheckedUpdateManyWithoutParentNestedInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutMediaNestedInput>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutMediaNestedInput>;
};

export type MediaUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<MediaUpdateOneWithoutChildrenNestedInput>;
  children?: Maybe<MediaUpdateManyWithoutParentNestedInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutMediaNestedInput>;
  users?: Maybe<UserUpdateManyWithoutMediaNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutMediaNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutMediaNestedInput>;
};

export type MediaUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
};

export type MediaUpdateManyWithWhereWithoutClinicInput = {
  where: MediaScalarWhereInput;
  data: MediaUncheckedUpdateManyWithoutMediaInput;
};

export type MediaUpdateManyWithWhereWithoutParentInput = {
  where: MediaScalarWhereInput;
  data: MediaUncheckedUpdateManyWithoutChildrenInput;
};

export type MediaUpdateManyWithWhereWithoutProductsInput = {
  where: MediaScalarWhereInput;
  data: MediaUncheckedUpdateManyWithoutMediaInput;
};

export type MediaUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<MediaCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<MediaUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<MediaCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<MediaWhereUniqueInput>>;
  disconnect?: Maybe<Array<MediaWhereUniqueInput>>;
  delete?: Maybe<Array<MediaWhereUniqueInput>>;
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  update?: Maybe<Array<MediaUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MediaUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<MediaScalarWhereInput>>;
};

export type MediaUpdateManyWithoutParentNestedInput = {
  create?: Maybe<Array<MediaCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutParentInput>>;
  upsert?: Maybe<Array<MediaUpsertWithWhereUniqueWithoutParentInput>>;
  createMany?: Maybe<MediaCreateManyParentInputEnvelope>;
  set?: Maybe<Array<MediaWhereUniqueInput>>;
  disconnect?: Maybe<Array<MediaWhereUniqueInput>>;
  delete?: Maybe<Array<MediaWhereUniqueInput>>;
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  update?: Maybe<Array<MediaUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<MediaUpdateManyWithWhereWithoutParentInput>>;
  deleteMany?: Maybe<Array<MediaScalarWhereInput>>;
};

export type MediaUpdateManyWithoutProductsNestedInput = {
  create?: Maybe<Array<MediaCreateWithoutProductsInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutProductsInput>>;
  upsert?: Maybe<Array<MediaUpsertWithWhereUniqueWithoutProductsInput>>;
  set?: Maybe<Array<MediaWhereUniqueInput>>;
  disconnect?: Maybe<Array<MediaWhereUniqueInput>>;
  delete?: Maybe<Array<MediaWhereUniqueInput>>;
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  update?: Maybe<Array<MediaUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: Maybe<Array<MediaUpdateManyWithWhereWithoutProductsInput>>;
  deleteMany?: Maybe<Array<MediaScalarWhereInput>>;
};

export type MediaUpdateOneWithoutChannelMessageAttachmentsNestedInput = {
  create?: Maybe<MediaUncheckedCreateWithoutChannelMessageAttachmentsInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutChannelMessageAttachmentsInput>;
  upsert?: Maybe<MediaUpsertWithoutChannelMessageAttachmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<MediaWhereUniqueInput>;
  update?: Maybe<MediaUncheckedUpdateWithoutChannelMessageAttachmentsInput>;
};

export type MediaUpdateOneWithoutChildrenNestedInput = {
  create?: Maybe<MediaUncheckedCreateWithoutChildrenInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutChildrenInput>;
  upsert?: Maybe<MediaUpsertWithoutChildrenInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<MediaWhereUniqueInput>;
  update?: Maybe<MediaUncheckedUpdateWithoutChildrenInput>;
};

export type MediaUpdateOneWithoutClinicPetsNestedInput = {
  create?: Maybe<MediaUncheckedCreateWithoutClinicPetsInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutClinicPetsInput>;
  upsert?: Maybe<MediaUpsertWithoutClinicPetsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<MediaWhereUniqueInput>;
  update?: Maybe<MediaUncheckedUpdateWithoutClinicPetsInput>;
};

export type MediaUpdateOneWithoutUsersNestedInput = {
  create?: Maybe<MediaUncheckedCreateWithoutUsersInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutUsersInput>;
  upsert?: Maybe<MediaUpsertWithoutUsersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<MediaWhereUniqueInput>;
  update?: Maybe<MediaUncheckedUpdateWithoutUsersInput>;
};

export type MediaUpdateWithWhereUniqueWithoutClinicInput = {
  where: MediaWhereUniqueInput;
  data: MediaUncheckedUpdateWithoutClinicInput;
};

export type MediaUpdateWithWhereUniqueWithoutParentInput = {
  where: MediaWhereUniqueInput;
  data: MediaUncheckedUpdateWithoutParentInput;
};

export type MediaUpdateWithWhereUniqueWithoutProductsInput = {
  where: MediaWhereUniqueInput;
  data: MediaUncheckedUpdateWithoutProductsInput;
};

export type MediaUpdateWithoutChannelMessageAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<MediaUpdateOneWithoutChildrenNestedInput>;
  children?: Maybe<MediaUpdateManyWithoutParentNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutMediaNestedInput>;
  users?: Maybe<UserUpdateManyWithoutMediaNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutMediaNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutMediaNestedInput>;
};

export type MediaUpdateWithoutChildrenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<MediaUpdateOneWithoutChildrenNestedInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutMediaNestedInput>;
  users?: Maybe<UserUpdateManyWithoutMediaNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutMediaNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutMediaNestedInput>;
};

export type MediaUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<MediaUpdateOneWithoutChildrenNestedInput>;
  children?: Maybe<MediaUpdateManyWithoutParentNestedInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutMediaNestedInput>;
  users?: Maybe<UserUpdateManyWithoutMediaNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutMediaNestedInput>;
};

export type MediaUpdateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<MediaUpdateOneWithoutChildrenNestedInput>;
  children?: Maybe<MediaUpdateManyWithoutParentNestedInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput>;
  users?: Maybe<UserUpdateManyWithoutMediaNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutMediaNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutMediaNestedInput>;
};

export type MediaUpdateWithoutParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  children?: Maybe<MediaUpdateManyWithoutParentNestedInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutMediaNestedInput>;
  users?: Maybe<UserUpdateManyWithoutMediaNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutMediaNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutMediaNestedInput>;
};

export type MediaUpdateWithoutProductsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<MediaUpdateOneWithoutChildrenNestedInput>;
  children?: Maybe<MediaUpdateManyWithoutParentNestedInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutMediaNestedInput>;
  users?: Maybe<UserUpdateManyWithoutMediaNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutMediaNestedInput>;
};

export type MediaUpdateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MediaType>;
  version?: Maybe<MediaVersion>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  properties?: Maybe<Scalars['Json']>;
  parent?: Maybe<MediaUpdateOneWithoutChildrenNestedInput>;
  children?: Maybe<MediaUpdateManyWithoutParentNestedInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutMediaNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutMediaNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutMediaNestedInput>;
};

export type MediaUpsertWithWhereUniqueWithoutClinicInput = {
  where: MediaWhereUniqueInput;
  update: MediaUncheckedUpdateWithoutClinicInput;
  create: MediaUncheckedCreateWithoutClinicInput;
};

export type MediaUpsertWithWhereUniqueWithoutParentInput = {
  where: MediaWhereUniqueInput;
  update: MediaUncheckedUpdateWithoutParentInput;
  create: MediaUncheckedCreateWithoutParentInput;
};

export type MediaUpsertWithWhereUniqueWithoutProductsInput = {
  where: MediaWhereUniqueInput;
  update: MediaUncheckedUpdateWithoutProductsInput;
  create: MediaUncheckedCreateWithoutProductsInput;
};

export type MediaUpsertWithoutChannelMessageAttachmentsInput = {
  update: MediaUncheckedUpdateWithoutChannelMessageAttachmentsInput;
  create: MediaUncheckedCreateWithoutChannelMessageAttachmentsInput;
};

export type MediaUpsertWithoutChildrenInput = {
  update: MediaUncheckedUpdateWithoutChildrenInput;
  create: MediaUncheckedCreateWithoutChildrenInput;
};

export type MediaUpsertWithoutClinicPetsInput = {
  update: MediaUncheckedUpdateWithoutClinicPetsInput;
  create: MediaUncheckedCreateWithoutClinicPetsInput;
};

export type MediaUpsertWithoutUsersInput = {
  update: MediaUncheckedUpdateWithoutUsersInput;
  create: MediaUncheckedCreateWithoutUsersInput;
};

export enum MediaVersion {
  Original = 'Original',
  Derived = 'Derived',
  Thumbnail = 'Thumbnail',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large'
}

export type MediaWhereInput = {
  AND?: Maybe<Array<MediaWhereInput>>;
  OR?: Maybe<Array<MediaWhereInput>>;
  NOT?: Maybe<Array<MediaWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumMediaTypeFilter>;
  version?: Maybe<EnumMediaVersionFilter>;
  parentMediaId?: Maybe<StringNullableFilter>;
  s3Bucket?: Maybe<StringNullableFilter>;
  s3Key?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  url?: Maybe<StringFilter>;
  checksum?: Maybe<StringFilter>;
  format?: Maybe<StringFilter>;
  sizeInMegabytes?: Maybe<FloatFilter>;
  durationInSeconds?: Maybe<FloatNullableFilter>;
  height?: Maybe<IntNullableFilter>;
  width?: Maybe<IntNullableFilter>;
  properties?: Maybe<JsonNullableFilter>;
  parent?: Maybe<MediaWhereInput>;
  children?: Maybe<MediaListRelationFilter>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentListRelationFilter>;
  clinicPets?: Maybe<ClinicPetListRelationFilter>;
  users?: Maybe<UserListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  products?: Maybe<ProductListRelationFilter>;
};

export type MediaWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
};

export type MergePetParentInput = {
  clinicId: Scalars['String'];
  keepId: Scalars['String'];
  deleteId: Scalars['String'];
  update?: Maybe<ClinicPetParentUpdateInput>;
};

export type MergePetParentResult = {
  __typename?: 'MergePetParentResult';
  success: Scalars['Boolean'];
  messages?: Maybe<Array<Scalars['String']>>;
};

export type MessageEventResponse = {
  __typename?: 'MessageEventResponse';
  success?: Maybe<Scalars['Boolean']>;
  jobChainId?: Maybe<Scalars['String']>;
};

export enum MessageSource {
  Web = 'Web',
  Sms = 'Sms'
}

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  name: Scalars['String'];
  body: Scalars['String'];
  attachments: Scalars['Json'];
  isArchived: Scalars['Boolean'];
  updatedBy?: Maybe<User>;
  clinic: Clinic;
};

export type MessageTemplateCountAggregateOutputType = {
  __typename?: 'MessageTemplateCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  updatedById: Scalars['Int'];
  clinicId: Scalars['Int'];
  name: Scalars['Int'];
  body: Scalars['Int'];
  attachments: Scalars['Int'];
  isArchived: Scalars['Int'];
  _all: Scalars['Int'];
};

export type MessageTemplateCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedById?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
};

export type MessageTemplateCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserCreateNestedOneWithoutMessageTemplatesInput>;
  clinic: ClinicCreateNestedOneWithoutMessageTemplatesInput;
};

export type MessageTemplateCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateCreateManyClinicInputEnvelope = {
  data: Array<MessageTemplateCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateCreateManyUpdatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateCreateManyUpdatedByInputEnvelope = {
  data: Array<MessageTemplateCreateManyUpdatedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<MessageTemplateCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
};

export type MessageTemplateCreateNestedManyWithoutUpdatedByInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutUpdatedByInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutUpdatedByInput>>;
  createMany?: Maybe<MessageTemplateCreateManyUpdatedByInputEnvelope>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
};

export type MessageTemplateCreateOrConnectWithoutClinicInput = {
  where: MessageTemplateWhereUniqueInput;
  create: MessageTemplateUncheckedCreateWithoutClinicInput;
};

export type MessageTemplateCreateOrConnectWithoutUpdatedByInput = {
  where: MessageTemplateWhereUniqueInput;
  create: MessageTemplateUncheckedCreateWithoutUpdatedByInput;
};

export type MessageTemplateCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserCreateNestedOneWithoutMessageTemplatesInput>;
};

export type MessageTemplateCreateWithoutUpdatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  clinic: ClinicCreateNestedOneWithoutMessageTemplatesInput;
};

export type MessageTemplateListRelationFilter = {
  every?: Maybe<MessageTemplateWhereInput>;
  some?: Maybe<MessageTemplateWhereInput>;
  none?: Maybe<MessageTemplateWhereInput>;
};

export type MessageTemplateMaxAggregateOutputType = {
  __typename?: 'MessageTemplateMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedById?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
};

export type MessageTemplateMinAggregateOutputType = {
  __typename?: 'MessageTemplateMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedById?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
};

export type MessageTemplateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MessageTemplateOrderByRelevanceFieldEnum {
  Id = 'id',
  UpdatedById = 'updatedById',
  ClinicId = 'clinicId',
  Name = 'name',
  Body = 'body'
}

export type MessageTemplateOrderByRelevanceInput = {
  fields: Array<MessageTemplateOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type MessageTemplateOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedById?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
  _count?: Maybe<MessageTemplateCountOrderByAggregateInput>;
  _max?: Maybe<MessageTemplateMaxOrderByAggregateInput>;
  _min?: Maybe<MessageTemplateMinOrderByAggregateInput>;
};

export type MessageTemplateOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedById?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
  updatedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<MessageTemplateOrderByRelevanceInput>;
};

export enum MessageTemplateScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  UpdatedById = 'updatedById',
  ClinicId = 'clinicId',
  Name = 'name',
  Body = 'body',
  Attachments = 'attachments',
  IsArchived = 'isArchived'
}

export type MessageTemplateScalarWhereInput = {
  AND?: Maybe<Array<MessageTemplateScalarWhereInput>>;
  OR?: Maybe<Array<MessageTemplateScalarWhereInput>>;
  NOT?: Maybe<Array<MessageTemplateScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  updatedById?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  body?: Maybe<StringFilter>;
  attachments?: Maybe<JsonFilter>;
  isArchived?: Maybe<BoolFilter>;
};

export type MessageTemplateScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MessageTemplateScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MessageTemplateScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MessageTemplateScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedById?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  body?: Maybe<StringWithAggregatesFilter>;
  attachments?: Maybe<JsonWithAggregatesFilter>;
  isArchived?: Maybe<BoolWithAggregatesFilter>;
};

export type MessageTemplateUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<MessageTemplateCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
};

export type MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutUpdatedByInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutUpdatedByInput>>;
  createMany?: Maybe<MessageTemplateCreateManyUpdatedByInputEnvelope>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
};

export type MessageTemplateUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUncheckedCreateWithoutUpdatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<MessageTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<MessageTemplateCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  update?: Maybe<Array<MessageTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MessageTemplateUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<MessageTemplateScalarWhereInput>>;
};

export type MessageTemplateUncheckedUpdateManyWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutUpdatedByInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutUpdatedByInput>>;
  upsert?: Maybe<Array<MessageTemplateUpsertWithWhereUniqueWithoutUpdatedByInput>>;
  createMany?: Maybe<MessageTemplateCreateManyUpdatedByInputEnvelope>;
  set?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  update?: Maybe<Array<MessageTemplateUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: Maybe<Array<MessageTemplateUpdateManyWithWhereWithoutUpdatedByInput>>;
  deleteMany?: Maybe<Array<MessageTemplateScalarWhereInput>>;
};

export type MessageTemplateUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUncheckedUpdateWithoutUpdatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserUpdateOneWithoutMessageTemplatesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMessageTemplatesNestedInput>;
};

export type MessageTemplateUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUpdateManyWithWhereWithoutClinicInput = {
  where: MessageTemplateScalarWhereInput;
  data: MessageTemplateUncheckedUpdateManyWithoutMessageTemplatesInput;
};

export type MessageTemplateUpdateManyWithWhereWithoutUpdatedByInput = {
  where: MessageTemplateScalarWhereInput;
  data: MessageTemplateUncheckedUpdateManyWithoutMessageTemplatesInput;
};

export type MessageTemplateUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<MessageTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<MessageTemplateCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  update?: Maybe<Array<MessageTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MessageTemplateUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<MessageTemplateScalarWhereInput>>;
};

export type MessageTemplateUpdateManyWithoutUpdatedByNestedInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutUpdatedByInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutUpdatedByInput>>;
  upsert?: Maybe<Array<MessageTemplateUpsertWithWhereUniqueWithoutUpdatedByInput>>;
  createMany?: Maybe<MessageTemplateCreateManyUpdatedByInputEnvelope>;
  set?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  update?: Maybe<Array<MessageTemplateUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: Maybe<Array<MessageTemplateUpdateManyWithWhereWithoutUpdatedByInput>>;
  deleteMany?: Maybe<Array<MessageTemplateScalarWhereInput>>;
};

export type MessageTemplateUpdateWithWhereUniqueWithoutClinicInput = {
  where: MessageTemplateWhereUniqueInput;
  data: MessageTemplateUncheckedUpdateWithoutClinicInput;
};

export type MessageTemplateUpdateWithWhereUniqueWithoutUpdatedByInput = {
  where: MessageTemplateWhereUniqueInput;
  data: MessageTemplateUncheckedUpdateWithoutUpdatedByInput;
};

export type MessageTemplateUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserUpdateOneWithoutMessageTemplatesNestedInput>;
};

export type MessageTemplateUpdateWithoutUpdatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMessageTemplatesNestedInput>;
};

export type MessageTemplateUpsertWithWhereUniqueWithoutClinicInput = {
  where: MessageTemplateWhereUniqueInput;
  update: MessageTemplateUncheckedUpdateWithoutClinicInput;
  create: MessageTemplateUncheckedCreateWithoutClinicInput;
};

export type MessageTemplateUpsertWithWhereUniqueWithoutUpdatedByInput = {
  where: MessageTemplateWhereUniqueInput;
  update: MessageTemplateUncheckedUpdateWithoutUpdatedByInput;
  create: MessageTemplateUncheckedCreateWithoutUpdatedByInput;
};

export type MessageTemplateWhereInput = {
  AND?: Maybe<Array<MessageTemplateWhereInput>>;
  OR?: Maybe<Array<MessageTemplateWhereInput>>;
  NOT?: Maybe<Array<MessageTemplateWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  updatedById?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  body?: Maybe<StringFilter>;
  attachments?: Maybe<JsonFilter>;
  isArchived?: Maybe<BoolFilter>;
  updatedBy?: Maybe<UserWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type MessageTemplateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum MessageType {
  Message = 'Message',
  Note = 'Note'
}

export type MessagingCampaign = {
  __typename?: 'MessagingCampaign';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  messagingPartnerId: Scalars['String'];
  name: Scalars['String'];
  type: MessagingCampaignType;
  status: MessagingCampaignStatus;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings: Array<WorkflowEventSetting>;
  messagingPartner: MessagingPartner;
  _count: MessagingCampaignCountOutputType;
};


export type MessagingCampaignWorkflowEventSettingsArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
};

export type MessagingCampaignCountAggregateOutputType = {
  __typename?: 'MessagingCampaignCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  messagingPartnerId: Scalars['Int'];
  name: Scalars['Int'];
  type: Scalars['Int'];
  status: Scalars['Int'];
  workflowEventSettingTemplate: Scalars['Int'];
  _all: Scalars['Int'];
};

export type MessagingCampaignCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  messagingPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  workflowEventSettingTemplate?: Maybe<SortOrder>;
};

export type MessagingCampaignCountOutputType = {
  __typename?: 'MessagingCampaignCountOutputType';
  workflowEventSettings: Scalars['Int'];
};

export type MessagingCampaignCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: MessagingCampaignType;
  status: MessagingCampaignStatus;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutMessagingCampaignInput>;
  messagingPartner: MessagingPartnerCreateNestedOneWithoutMessagingCampaignsInput;
};

export type MessagingCampaignCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  messagingPartnerId: Scalars['String'];
  name: Scalars['String'];
  type: MessagingCampaignType;
  status: MessagingCampaignStatus;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignCreateManyMessagingPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: MessagingCampaignType;
  status: MessagingCampaignStatus;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignCreateManyMessagingPartnerInputEnvelope = {
  data: Array<MessagingCampaignCreateManyMessagingPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MessagingCampaignCreateNestedManyWithoutMessagingPartnerInput = {
  create?: Maybe<Array<MessagingCampaignCreateWithoutMessagingPartnerInput>>;
  connectOrCreate?: Maybe<Array<MessagingCampaignCreateOrConnectWithoutMessagingPartnerInput>>;
  createMany?: Maybe<MessagingCampaignCreateManyMessagingPartnerInputEnvelope>;
  connect?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
};

export type MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput = {
  create?: Maybe<MessagingCampaignUncheckedCreateWithoutWorkflowEventSettingsInput>;
  connectOrCreate?: Maybe<MessagingCampaignCreateOrConnectWithoutWorkflowEventSettingsInput>;
  connect?: Maybe<MessagingCampaignWhereUniqueInput>;
};

export type MessagingCampaignCreateOrConnectWithoutMessagingPartnerInput = {
  where: MessagingCampaignWhereUniqueInput;
  create: MessagingCampaignUncheckedCreateWithoutMessagingPartnerInput;
};

export type MessagingCampaignCreateOrConnectWithoutWorkflowEventSettingsInput = {
  where: MessagingCampaignWhereUniqueInput;
  create: MessagingCampaignUncheckedCreateWithoutWorkflowEventSettingsInput;
};

export type MessagingCampaignCreateWithoutMessagingPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: MessagingCampaignType;
  status: MessagingCampaignStatus;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutMessagingCampaignInput>;
};

export type MessagingCampaignCreateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: MessagingCampaignType;
  status: MessagingCampaignStatus;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  messagingPartner: MessagingPartnerCreateNestedOneWithoutMessagingCampaignsInput;
};

export type MessagingCampaignListRelationFilter = {
  every?: Maybe<MessagingCampaignWhereInput>;
  some?: Maybe<MessagingCampaignWhereInput>;
  none?: Maybe<MessagingCampaignWhereInput>;
};

export type MessagingCampaignMaxAggregateOutputType = {
  __typename?: 'MessagingCampaignMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  messagingPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<MessagingCampaignType>;
  status?: Maybe<MessagingCampaignStatus>;
};

export type MessagingCampaignMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  messagingPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type MessagingCampaignMinAggregateOutputType = {
  __typename?: 'MessagingCampaignMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  messagingPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<MessagingCampaignType>;
  status?: Maybe<MessagingCampaignStatus>;
};

export type MessagingCampaignMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  messagingPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type MessagingCampaignOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MessagingCampaignOrderByRelevanceFieldEnum {
  Id = 'id',
  MessagingPartnerId = 'messagingPartnerId',
  Name = 'name'
}

export type MessagingCampaignOrderByRelevanceInput = {
  fields: Array<MessagingCampaignOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type MessagingCampaignOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  messagingPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  workflowEventSettingTemplate?: Maybe<SortOrder>;
  _count?: Maybe<MessagingCampaignCountOrderByAggregateInput>;
  _max?: Maybe<MessagingCampaignMaxOrderByAggregateInput>;
  _min?: Maybe<MessagingCampaignMinOrderByAggregateInput>;
};

export type MessagingCampaignOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  messagingPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  workflowEventSettingTemplate?: Maybe<SortOrder>;
  workflowEventSettings?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
  messagingPartner?: Maybe<MessagingPartnerOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<MessagingCampaignOrderByRelevanceInput>;
};

export type MessagingCampaignRelationFilter = {
  is?: Maybe<MessagingCampaignWhereInput>;
  isNot?: Maybe<MessagingCampaignWhereInput>;
};

export enum MessagingCampaignScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  MessagingPartnerId = 'messagingPartnerId',
  Name = 'name',
  Type = 'type',
  Status = 'status',
  WorkflowEventSettingTemplate = 'workflowEventSettingTemplate'
}

export type MessagingCampaignScalarWhereInput = {
  AND?: Maybe<Array<MessagingCampaignScalarWhereInput>>;
  OR?: Maybe<Array<MessagingCampaignScalarWhereInput>>;
  NOT?: Maybe<Array<MessagingCampaignScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  messagingPartnerId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  type?: Maybe<EnumMessagingCampaignTypeFilter>;
  status?: Maybe<EnumMessagingCampaignStatusFilter>;
  workflowEventSettingTemplate?: Maybe<JsonNullableFilter>;
};

export type MessagingCampaignScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MessagingCampaignScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MessagingCampaignScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MessagingCampaignScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  messagingPartnerId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumMessagingCampaignTypeWithAggregatesFilter>;
  status?: Maybe<EnumMessagingCampaignStatusWithAggregatesFilter>;
  workflowEventSettingTemplate?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum MessagingCampaignStatus {
  Active = 'Active',
  Paused = 'Paused'
}

export enum MessagingCampaignType {
  Automation = 'Automation'
}

export type MessagingCampaignUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  messagingPartnerId: Scalars['String'];
  name: Scalars['String'];
  type: MessagingCampaignType;
  status: MessagingCampaignStatus;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutMessagingCampaignInput>;
};

export type MessagingCampaignUncheckedCreateNestedManyWithoutMessagingPartnerInput = {
  create?: Maybe<Array<MessagingCampaignCreateWithoutMessagingPartnerInput>>;
  connectOrCreate?: Maybe<Array<MessagingCampaignCreateOrConnectWithoutMessagingPartnerInput>>;
  createMany?: Maybe<MessagingCampaignCreateManyMessagingPartnerInputEnvelope>;
  connect?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
};

export type MessagingCampaignUncheckedCreateWithoutMessagingPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: MessagingCampaignType;
  status: MessagingCampaignStatus;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutMessagingCampaignInput>;
};

export type MessagingCampaignUncheckedCreateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  messagingPartnerId: Scalars['String'];
  name: Scalars['String'];
  type: MessagingCampaignType;
  status: MessagingCampaignStatus;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  messagingPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<MessagingCampaignType>;
  status?: Maybe<MessagingCampaignStatus>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutMessagingCampaignNestedInput>;
};

export type MessagingCampaignUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  messagingPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<MessagingCampaignType>;
  status?: Maybe<MessagingCampaignStatus>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignUncheckedUpdateManyWithoutMessagingCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<MessagingCampaignType>;
  status?: Maybe<MessagingCampaignStatus>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignUncheckedUpdateManyWithoutMessagingPartnerNestedInput = {
  create?: Maybe<Array<MessagingCampaignCreateWithoutMessagingPartnerInput>>;
  connectOrCreate?: Maybe<Array<MessagingCampaignCreateOrConnectWithoutMessagingPartnerInput>>;
  upsert?: Maybe<Array<MessagingCampaignUpsertWithWhereUniqueWithoutMessagingPartnerInput>>;
  createMany?: Maybe<MessagingCampaignCreateManyMessagingPartnerInputEnvelope>;
  set?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  update?: Maybe<Array<MessagingCampaignUpdateWithWhereUniqueWithoutMessagingPartnerInput>>;
  updateMany?: Maybe<Array<MessagingCampaignUpdateManyWithWhereWithoutMessagingPartnerInput>>;
  deleteMany?: Maybe<Array<MessagingCampaignScalarWhereInput>>;
};

export type MessagingCampaignUncheckedUpdateWithoutMessagingPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<MessagingCampaignType>;
  status?: Maybe<MessagingCampaignStatus>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutMessagingCampaignNestedInput>;
};

export type MessagingCampaignUncheckedUpdateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  messagingPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<MessagingCampaignType>;
  status?: Maybe<MessagingCampaignStatus>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<MessagingCampaignType>;
  status?: Maybe<MessagingCampaignStatus>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutMessagingCampaignNestedInput>;
  messagingPartner?: Maybe<MessagingPartnerUpdateOneRequiredWithoutMessagingCampaignsNestedInput>;
};

export type MessagingCampaignUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<MessagingCampaignType>;
  status?: Maybe<MessagingCampaignStatus>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignUpdateManyWithWhereWithoutMessagingPartnerInput = {
  where: MessagingCampaignScalarWhereInput;
  data: MessagingCampaignUncheckedUpdateManyWithoutMessagingCampaignsInput;
};

export type MessagingCampaignUpdateManyWithoutMessagingPartnerNestedInput = {
  create?: Maybe<Array<MessagingCampaignCreateWithoutMessagingPartnerInput>>;
  connectOrCreate?: Maybe<Array<MessagingCampaignCreateOrConnectWithoutMessagingPartnerInput>>;
  upsert?: Maybe<Array<MessagingCampaignUpsertWithWhereUniqueWithoutMessagingPartnerInput>>;
  createMany?: Maybe<MessagingCampaignCreateManyMessagingPartnerInputEnvelope>;
  set?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  disconnect?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  delete?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  connect?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  update?: Maybe<Array<MessagingCampaignUpdateWithWhereUniqueWithoutMessagingPartnerInput>>;
  updateMany?: Maybe<Array<MessagingCampaignUpdateManyWithWhereWithoutMessagingPartnerInput>>;
  deleteMany?: Maybe<Array<MessagingCampaignScalarWhereInput>>;
};

export type MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput = {
  create?: Maybe<MessagingCampaignUncheckedCreateWithoutWorkflowEventSettingsInput>;
  connectOrCreate?: Maybe<MessagingCampaignCreateOrConnectWithoutWorkflowEventSettingsInput>;
  upsert?: Maybe<MessagingCampaignUpsertWithoutWorkflowEventSettingsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<MessagingCampaignWhereUniqueInput>;
  update?: Maybe<MessagingCampaignUncheckedUpdateWithoutWorkflowEventSettingsInput>;
};

export type MessagingCampaignUpdateWithWhereUniqueWithoutMessagingPartnerInput = {
  where: MessagingCampaignWhereUniqueInput;
  data: MessagingCampaignUncheckedUpdateWithoutMessagingPartnerInput;
};

export type MessagingCampaignUpdateWithoutMessagingPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<MessagingCampaignType>;
  status?: Maybe<MessagingCampaignStatus>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutMessagingCampaignNestedInput>;
};

export type MessagingCampaignUpdateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<MessagingCampaignType>;
  status?: Maybe<MessagingCampaignStatus>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  messagingPartner?: Maybe<MessagingPartnerUpdateOneRequiredWithoutMessagingCampaignsNestedInput>;
};

export type MessagingCampaignUpsertWithWhereUniqueWithoutMessagingPartnerInput = {
  where: MessagingCampaignWhereUniqueInput;
  update: MessagingCampaignUncheckedUpdateWithoutMessagingPartnerInput;
  create: MessagingCampaignUncheckedCreateWithoutMessagingPartnerInput;
};

export type MessagingCampaignUpsertWithoutWorkflowEventSettingsInput = {
  update: MessagingCampaignUncheckedUpdateWithoutWorkflowEventSettingsInput;
  create: MessagingCampaignUncheckedCreateWithoutWorkflowEventSettingsInput;
};

export type MessagingCampaignWhereInput = {
  AND?: Maybe<Array<MessagingCampaignWhereInput>>;
  OR?: Maybe<Array<MessagingCampaignWhereInput>>;
  NOT?: Maybe<Array<MessagingCampaignWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  messagingPartnerId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  type?: Maybe<EnumMessagingCampaignTypeFilter>;
  status?: Maybe<EnumMessagingCampaignStatusFilter>;
  workflowEventSettingTemplate?: Maybe<JsonNullableFilter>;
  workflowEventSettings?: Maybe<WorkflowEventSettingListRelationFilter>;
  messagingPartner?: Maybe<MessagingPartnerWhereInput>;
};

export type MessagingCampaignWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MessagingPartner = {
  __typename?: 'MessagingPartner';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  messagingCampaigns: Array<MessagingCampaign>;
  _count: MessagingPartnerCountOutputType;
};


export type MessagingPartnerMessagingCampaignsArgs = {
  where?: Maybe<MessagingCampaignWhereInput>;
  orderBy?: Maybe<MessagingCampaignOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MessagingCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MessagingCampaignScalarFieldEnum>;
};

export type MessagingPartnerCountAggregateOutputType = {
  __typename?: 'MessagingPartnerCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  name: Scalars['Int'];
  _all: Scalars['Int'];
};

export type MessagingPartnerCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type MessagingPartnerCountOutputType = {
  __typename?: 'MessagingPartnerCountOutputType';
  messagingCampaigns: Scalars['Int'];
};

export type MessagingPartnerCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  messagingCampaigns?: Maybe<MessagingCampaignCreateNestedManyWithoutMessagingPartnerInput>;
};

export type MessagingPartnerCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type MessagingPartnerCreateNestedOneWithoutMessagingCampaignsInput = {
  create?: Maybe<MessagingPartnerUncheckedCreateWithoutMessagingCampaignsInput>;
  connectOrCreate?: Maybe<MessagingPartnerCreateOrConnectWithoutMessagingCampaignsInput>;
  connect?: Maybe<MessagingPartnerWhereUniqueInput>;
};

export type MessagingPartnerCreateOrConnectWithoutMessagingCampaignsInput = {
  where: MessagingPartnerWhereUniqueInput;
  create: MessagingPartnerUncheckedCreateWithoutMessagingCampaignsInput;
};

export type MessagingPartnerCreateWithoutMessagingCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type MessagingPartnerMaxAggregateOutputType = {
  __typename?: 'MessagingPartnerMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type MessagingPartnerMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type MessagingPartnerMinAggregateOutputType = {
  __typename?: 'MessagingPartnerMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type MessagingPartnerMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export enum MessagingPartnerOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name'
}

export type MessagingPartnerOrderByRelevanceInput = {
  fields: Array<MessagingPartnerOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type MessagingPartnerOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  _count?: Maybe<MessagingPartnerCountOrderByAggregateInput>;
  _max?: Maybe<MessagingPartnerMaxOrderByAggregateInput>;
  _min?: Maybe<MessagingPartnerMinOrderByAggregateInput>;
};

export type MessagingPartnerOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  messagingCampaigns?: Maybe<MessagingCampaignOrderByRelationAggregateInput>;
  _relevance?: Maybe<MessagingPartnerOrderByRelevanceInput>;
};

export type MessagingPartnerRelationFilter = {
  is?: Maybe<MessagingPartnerWhereInput>;
  isNot?: Maybe<MessagingPartnerWhereInput>;
};

export enum MessagingPartnerScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Name = 'name'
}

export type MessagingPartnerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MessagingPartnerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MessagingPartnerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MessagingPartnerScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
};

export type MessagingPartnerUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  messagingCampaigns?: Maybe<MessagingCampaignUncheckedCreateNestedManyWithoutMessagingPartnerInput>;
};

export type MessagingPartnerUncheckedCreateWithoutMessagingCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type MessagingPartnerUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  messagingCampaigns?: Maybe<MessagingCampaignUncheckedUpdateManyWithoutMessagingPartnerNestedInput>;
};

export type MessagingPartnerUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type MessagingPartnerUncheckedUpdateWithoutMessagingCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type MessagingPartnerUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  messagingCampaigns?: Maybe<MessagingCampaignUpdateManyWithoutMessagingPartnerNestedInput>;
};

export type MessagingPartnerUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type MessagingPartnerUpdateOneRequiredWithoutMessagingCampaignsNestedInput = {
  create?: Maybe<MessagingPartnerUncheckedCreateWithoutMessagingCampaignsInput>;
  connectOrCreate?: Maybe<MessagingPartnerCreateOrConnectWithoutMessagingCampaignsInput>;
  upsert?: Maybe<MessagingPartnerUpsertWithoutMessagingCampaignsInput>;
  connect?: Maybe<MessagingPartnerWhereUniqueInput>;
  update?: Maybe<MessagingPartnerUncheckedUpdateWithoutMessagingCampaignsInput>;
};

export type MessagingPartnerUpdateWithoutMessagingCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type MessagingPartnerUpsertWithoutMessagingCampaignsInput = {
  update: MessagingPartnerUncheckedUpdateWithoutMessagingCampaignsInput;
  create: MessagingPartnerUncheckedCreateWithoutMessagingCampaignsInput;
};

export type MessagingPartnerWhereInput = {
  AND?: Maybe<Array<MessagingPartnerWhereInput>>;
  OR?: Maybe<Array<MessagingPartnerWhereInput>>;
  NOT?: Maybe<Array<MessagingPartnerWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  messagingCampaigns?: Maybe<MessagingCampaignListRelationFilter>;
};

export type MessagingPartnerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum MissedCallMessageResponseStatus {
  Disabled = 'Disabled',
  All = 'All',
  PetParentsOnly = 'PetParentsOnly'
}

export type Mutation = {
  __typename?: 'Mutation';
  createOneAppointment: Appointment;
  updateOneAppointment: Appointment;
  deleteOneAppointment?: Maybe<Appointment>;
  upsertOneAppointment?: Maybe<Appointment>;
  deleteManyAppointment?: Maybe<BatchPayload>;
  updateManyAppointment?: Maybe<BatchPayload>;
  createOneAppointmentAvailability: AppointmentAvailability;
  updateOneAppointmentAvailability: AppointmentAvailability;
  deleteOneAppointmentAvailability?: Maybe<AppointmentAvailability>;
  upsertOneAppointmentAvailability?: Maybe<AppointmentAvailability>;
  deleteManyAppointmentAvailability?: Maybe<BatchPayload>;
  updateManyAppointmentAvailability?: Maybe<BatchPayload>;
  createOneAppointmentType: AppointmentType;
  updateOneAppointmentType: AppointmentType;
  deleteOneAppointmentType?: Maybe<AppointmentType>;
  upsertOneAppointmentType?: Maybe<AppointmentType>;
  deleteManyAppointmentType?: Maybe<BatchPayload>;
  updateManyAppointmentType?: Maybe<BatchPayload>;
  createOneBitwerxIntegration: BitwerxIntegration;
  updateOneBitwerxIntegration: BitwerxIntegration;
  deleteOneBitwerxIntegration?: Maybe<BitwerxIntegration>;
  upsertOneBitwerxIntegration?: Maybe<BitwerxIntegration>;
  deleteManyBitwerxIntegration?: Maybe<BatchPayload>;
  updateManyBitwerxIntegration?: Maybe<BatchPayload>;
  createOnePPCIntegration: PpcIntegration;
  updateOnePPCIntegration: PpcIntegration;
  deleteOnePPCIntegration?: Maybe<PpcIntegration>;
  upsertOnePPCIntegration?: Maybe<PpcIntegration>;
  deleteManyPPCIntegration?: Maybe<BatchPayload>;
  updateManyPPCIntegration?: Maybe<BatchPayload>;
  createOneChannelAutomationStatus: ChannelAutomationStatus;
  updateOneChannelAutomationStatus: ChannelAutomationStatus;
  deleteOneChannelAutomationStatus?: Maybe<ChannelAutomationStatus>;
  upsertOneChannelAutomationStatus?: Maybe<ChannelAutomationStatus>;
  deleteManyChannelAutomationStatus?: Maybe<BatchPayload>;
  updateManyChannelAutomationStatus?: Maybe<BatchPayload>;
  createOneChannelCreationSource: ChannelCreationSource;
  updateOneChannelCreationSource: ChannelCreationSource;
  deleteOneChannelCreationSource?: Maybe<ChannelCreationSource>;
  upsertOneChannelCreationSource?: Maybe<ChannelCreationSource>;
  deleteManyChannelCreationSource?: Maybe<BatchPayload>;
  updateManyChannelCreationSource?: Maybe<BatchPayload>;
  createOneChannel: Channel;
  updateOneChannel: Channel;
  deleteOneChannel?: Maybe<Channel>;
  upsertOneChannel?: Maybe<Channel>;
  deleteManyChannel?: Maybe<BatchPayload>;
  updateManyChannel?: Maybe<BatchPayload>;
  createOneChannelPin: ChannelPin;
  updateOneChannelPin: ChannelPin;
  deleteOneChannelPin?: Maybe<ChannelPin>;
  upsertOneChannelPin?: Maybe<ChannelPin>;
  deleteManyChannelPin?: Maybe<BatchPayload>;
  updateManyChannelPin?: Maybe<BatchPayload>;
  createOneChannelMember: ChannelMember;
  updateOneChannelMember: ChannelMember;
  deleteOneChannelMember?: Maybe<ChannelMember>;
  upsertOneChannelMember?: Maybe<ChannelMember>;
  deleteManyChannelMember?: Maybe<BatchPayload>;
  updateManyChannelMember?: Maybe<BatchPayload>;
  createOneChannelMessage: ChannelMessage;
  updateOneChannelMessage: ChannelMessage;
  deleteOneChannelMessage?: Maybe<ChannelMessage>;
  upsertOneChannelMessage?: Maybe<ChannelMessage>;
  deleteManyChannelMessage?: Maybe<BatchPayload>;
  updateManyChannelMessage?: Maybe<BatchPayload>;
  createOneChannelMessageReaction: ChannelMessageReaction;
  updateOneChannelMessageReaction: ChannelMessageReaction;
  deleteOneChannelMessageReaction?: Maybe<ChannelMessageReaction>;
  upsertOneChannelMessageReaction?: Maybe<ChannelMessageReaction>;
  deleteManyChannelMessageReaction?: Maybe<BatchPayload>;
  updateManyChannelMessageReaction?: Maybe<BatchPayload>;
  createOneChannelSelection: ChannelSelection;
  updateOneChannelSelection: ChannelSelection;
  deleteOneChannelSelection?: Maybe<ChannelSelection>;
  upsertOneChannelSelection?: Maybe<ChannelSelection>;
  deleteManyChannelSelection?: Maybe<BatchPayload>;
  updateManyChannelSelection?: Maybe<BatchPayload>;
  createOneChannelMessageAttachment: ChannelMessageAttachment;
  updateOneChannelMessageAttachment: ChannelMessageAttachment;
  deleteOneChannelMessageAttachment?: Maybe<ChannelMessageAttachment>;
  upsertOneChannelMessageAttachment?: Maybe<ChannelMessageAttachment>;
  deleteManyChannelMessageAttachment?: Maybe<BatchPayload>;
  updateManyChannelMessageAttachment?: Maybe<BatchPayload>;
  createOneChannelStatus: ChannelStatus;
  updateOneChannelStatus: ChannelStatus;
  deleteOneChannelStatus?: Maybe<ChannelStatus>;
  upsertOneChannelStatus?: Maybe<ChannelStatus>;
  deleteManyChannelStatus?: Maybe<BatchPayload>;
  updateManyChannelStatus?: Maybe<BatchPayload>;
  createOneChannelStatusChannelAssignee: ChannelStatusChannelAssignee;
  updateOneChannelStatusChannelAssignee: ChannelStatusChannelAssignee;
  deleteOneChannelStatusChannelAssignee?: Maybe<ChannelStatusChannelAssignee>;
  upsertOneChannelStatusChannelAssignee?: Maybe<ChannelStatusChannelAssignee>;
  deleteManyChannelStatusChannelAssignee?: Maybe<BatchPayload>;
  updateManyChannelStatusChannelAssignee?: Maybe<BatchPayload>;
  createOneClinic: Clinic;
  updateOneClinic: Clinic;
  deleteOneClinic?: Maybe<Clinic>;
  upsertOneClinic?: Maybe<Clinic>;
  deleteManyClinic?: Maybe<BatchPayload>;
  updateManyClinic?: Maybe<BatchPayload>;
  createOneClinicEmployee: ClinicEmployee;
  updateOneClinicEmployee: ClinicEmployee;
  deleteOneClinicEmployee?: Maybe<ClinicEmployee>;
  upsertOneClinicEmployee?: Maybe<ClinicEmployee>;
  deleteManyClinicEmployee?: Maybe<BatchPayload>;
  updateManyClinicEmployee?: Maybe<BatchPayload>;
  createOneDirectBookingAppointmentTypeSetting: DirectBookingAppointmentTypeSetting;
  updateOneDirectBookingAppointmentTypeSetting: DirectBookingAppointmentTypeSetting;
  deleteOneDirectBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSetting>;
  upsertOneDirectBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSetting>;
  deleteManyDirectBookingAppointmentTypeSetting?: Maybe<BatchPayload>;
  updateManyDirectBookingAppointmentTypeSetting?: Maybe<BatchPayload>;
  createOneClinicEmployeeAppointmentTypeSetting: ClinicEmployeeAppointmentTypeSetting;
  updateOneClinicEmployeeAppointmentTypeSetting: ClinicEmployeeAppointmentTypeSetting;
  deleteOneClinicEmployeeAppointmentTypeSetting?: Maybe<ClinicEmployeeAppointmentTypeSetting>;
  upsertOneClinicEmployeeAppointmentTypeSetting?: Maybe<ClinicEmployeeAppointmentTypeSetting>;
  deleteManyClinicEmployeeAppointmentTypeSetting?: Maybe<BatchPayload>;
  updateManyClinicEmployeeAppointmentTypeSetting?: Maybe<BatchPayload>;
  createOneClinicEntityPhoneNumber: ClinicEntityPhoneNumber;
  updateOneClinicEntityPhoneNumber: ClinicEntityPhoneNumber;
  deleteOneClinicEntityPhoneNumber?: Maybe<ClinicEntityPhoneNumber>;
  upsertOneClinicEntityPhoneNumber?: Maybe<ClinicEntityPhoneNumber>;
  deleteManyClinicEntityPhoneNumber?: Maybe<BatchPayload>;
  updateManyClinicEntityPhoneNumber?: Maybe<BatchPayload>;
  createOneClinicPaymentFeeConfiguration: ClinicPaymentFeeConfiguration;
  updateOneClinicPaymentFeeConfiguration: ClinicPaymentFeeConfiguration;
  deleteOneClinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfiguration>;
  upsertOneClinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfiguration>;
  deleteManyClinicPaymentFeeConfiguration?: Maybe<BatchPayload>;
  updateManyClinicPaymentFeeConfiguration?: Maybe<BatchPayload>;
  createOneInterchangeRate: InterchangeRate;
  updateOneInterchangeRate: InterchangeRate;
  deleteOneInterchangeRate?: Maybe<InterchangeRate>;
  upsertOneInterchangeRate?: Maybe<InterchangeRate>;
  deleteManyInterchangeRate?: Maybe<BatchPayload>;
  updateManyInterchangeRate?: Maybe<BatchPayload>;
  createOneClinicPet: ClinicPet;
  updateOneClinicPet: ClinicPet;
  deleteOneClinicPet?: Maybe<ClinicPet>;
  upsertOneClinicPet?: Maybe<ClinicPet>;
  deleteManyClinicPet?: Maybe<BatchPayload>;
  updateManyClinicPet?: Maybe<BatchPayload>;
  createOneClinicPetAlert: ClinicPetAlert;
  updateOneClinicPetAlert: ClinicPetAlert;
  deleteOneClinicPetAlert?: Maybe<ClinicPetAlert>;
  upsertOneClinicPetAlert?: Maybe<ClinicPetAlert>;
  deleteManyClinicPetAlert?: Maybe<BatchPayload>;
  updateManyClinicPetAlert?: Maybe<BatchPayload>;
  createOneClinicPetParentAddress: ClinicPetParentAddress;
  updateOneClinicPetParentAddress: ClinicPetParentAddress;
  deleteOneClinicPetParentAddress?: Maybe<ClinicPetParentAddress>;
  upsertOneClinicPetParentAddress?: Maybe<ClinicPetParentAddress>;
  deleteManyClinicPetParentAddress?: Maybe<BatchPayload>;
  updateManyClinicPetParentAddress?: Maybe<BatchPayload>;
  createOneClinicPetParent: ClinicPetParent;
  updateOneClinicPetParent: ClinicPetParent;
  deleteOneClinicPetParent?: Maybe<ClinicPetParent>;
  upsertOneClinicPetParent?: Maybe<ClinicPetParent>;
  deleteManyClinicPetParent?: Maybe<BatchPayload>;
  updateManyClinicPetParent?: Maybe<BatchPayload>;
  createOneClinicDirectBookingSetting: ClinicDirectBookingSetting;
  updateOneClinicDirectBookingSetting: ClinicDirectBookingSetting;
  deleteOneClinicDirectBookingSetting?: Maybe<ClinicDirectBookingSetting>;
  upsertOneClinicDirectBookingSetting?: Maybe<ClinicDirectBookingSetting>;
  deleteManyClinicDirectBookingSetting?: Maybe<BatchPayload>;
  updateManyClinicDirectBookingSetting?: Maybe<BatchPayload>;
  createOneDirectBookingExclusionRule: DirectBookingExclusionRule;
  updateOneDirectBookingExclusionRule: DirectBookingExclusionRule;
  deleteOneDirectBookingExclusionRule?: Maybe<DirectBookingExclusionRule>;
  upsertOneDirectBookingExclusionRule?: Maybe<DirectBookingExclusionRule>;
  createOneDirectBookingExclusionTimeSlot: DirectBookingExclusionTimeSlot;
  updateOneDirectBookingExclusionTimeSlot: DirectBookingExclusionTimeSlot;
  deleteOneDirectBookingExclusionTimeSlot?: Maybe<DirectBookingExclusionTimeSlot>;
  upsertOneDirectBookingExclusionTimeSlot?: Maybe<DirectBookingExclusionTimeSlot>;
  deleteManyDirectBookingExclusionTimeSlot?: Maybe<BatchPayload>;
  updateManyDirectBookingExclusionTimeSlot?: Maybe<BatchPayload>;
  createOneClinicEmailCampaignSetting: ClinicEmailCampaignSetting;
  updateOneClinicEmailCampaignSetting: ClinicEmailCampaignSetting;
  deleteOneClinicEmailCampaignSetting?: Maybe<ClinicEmailCampaignSetting>;
  upsertOneClinicEmailCampaignSetting?: Maybe<ClinicEmailCampaignSetting>;
  deleteManyClinicEmailCampaignSetting?: Maybe<BatchPayload>;
  updateManyClinicEmailCampaignSetting?: Maybe<BatchPayload>;
  createOneClinicPostcardSetting: ClinicPostcardSetting;
  updateOneClinicPostcardSetting: ClinicPostcardSetting;
  deleteOneClinicPostcardSetting?: Maybe<ClinicPostcardSetting>;
  upsertOneClinicPostcardSetting?: Maybe<ClinicPostcardSetting>;
  deleteManyClinicPostcardSetting?: Maybe<BatchPayload>;
  updateManyClinicPostcardSetting?: Maybe<BatchPayload>;
  createOneIntegrationOutageHistoryEntry: IntegrationOutageHistoryEntry;
  updateOneIntegrationOutageHistoryEntry: IntegrationOutageHistoryEntry;
  deleteOneIntegrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntry>;
  upsertOneIntegrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntry>;
  deleteManyIntegrationOutageHistoryEntry?: Maybe<BatchPayload>;
  updateManyIntegrationOutageHistoryEntry?: Maybe<BatchPayload>;
  createOneClinicPimsIntegrationCapabilityHistoryEntry: ClinicPimsIntegrationCapabilityHistoryEntry;
  updateOneClinicPimsIntegrationCapabilityHistoryEntry: ClinicPimsIntegrationCapabilityHistoryEntry;
  deleteOneClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntry>;
  upsertOneClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntry>;
  deleteManyClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<BatchPayload>;
  updateManyClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<BatchPayload>;
  createOneClinicPimsIntegration: ClinicPimsIntegration;
  updateOneClinicPimsIntegration: ClinicPimsIntegration;
  deleteOneClinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  upsertOneClinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  deleteManyClinicPimsIntegration?: Maybe<BatchPayload>;
  updateManyClinicPimsIntegration?: Maybe<BatchPayload>;
  createOneSyncVetIntegration: SyncVetIntegration;
  updateOneSyncVetIntegration: SyncVetIntegration;
  deleteOneSyncVetIntegration?: Maybe<SyncVetIntegration>;
  upsertOneSyncVetIntegration?: Maybe<SyncVetIntegration>;
  deleteManySyncVetIntegration?: Maybe<BatchPayload>;
  updateManySyncVetIntegration?: Maybe<BatchPayload>;
  createOneClinicPimsIntegrationCapability: ClinicPimsIntegrationCapability;
  updateOneClinicPimsIntegrationCapability: ClinicPimsIntegrationCapability;
  deleteOneClinicPimsIntegrationCapability?: Maybe<ClinicPimsIntegrationCapability>;
  upsertOneClinicPimsIntegrationCapability?: Maybe<ClinicPimsIntegrationCapability>;
  deleteManyClinicPimsIntegrationCapability?: Maybe<BatchPayload>;
  updateManyClinicPimsIntegrationCapability?: Maybe<BatchPayload>;
  createOneClinicRoom: ClinicRoom;
  updateOneClinicRoom: ClinicRoom;
  deleteOneClinicRoom?: Maybe<ClinicRoom>;
  upsertOneClinicRoom?: Maybe<ClinicRoom>;
  deleteManyClinicRoom?: Maybe<BatchPayload>;
  updateManyClinicRoom?: Maybe<BatchPayload>;
  createOneClinicSetting: ClinicSetting;
  updateOneClinicSetting: ClinicSetting;
  deleteOneClinicSetting?: Maybe<ClinicSetting>;
  upsertOneClinicSetting?: Maybe<ClinicSetting>;
  deleteManyClinicSetting?: Maybe<BatchPayload>;
  updateManyClinicSetting?: Maybe<BatchPayload>;
  createOneClinicWidgetRequest: ClinicWidgetRequest;
  updateOneClinicWidgetRequest: ClinicWidgetRequest;
  deleteOneClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  upsertOneClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  deleteManyClinicWidgetRequest?: Maybe<BatchPayload>;
  updateManyClinicWidgetRequest?: Maybe<BatchPayload>;
  createOneClinicWidgetRequestType: ClinicWidgetRequestType;
  updateOneClinicWidgetRequestType: ClinicWidgetRequestType;
  deleteOneClinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  upsertOneClinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  deleteManyClinicWidgetRequestType?: Maybe<BatchPayload>;
  updateManyClinicWidgetRequestType?: Maybe<BatchPayload>;
  createOneClinicWidgetSetting: ClinicWidgetSetting;
  updateOneClinicWidgetSetting: ClinicWidgetSetting;
  deleteOneClinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  upsertOneClinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  deleteManyClinicWidgetSetting?: Maybe<BatchPayload>;
  updateManyClinicWidgetSetting?: Maybe<BatchPayload>;
  createOneConsultationRequest: ConsultationRequest;
  updateOneConsultationRequest: ConsultationRequest;
  deleteOneConsultationRequest?: Maybe<ConsultationRequest>;
  upsertOneConsultationRequest?: Maybe<ConsultationRequest>;
  deleteManyConsultationRequest?: Maybe<BatchPayload>;
  updateManyConsultationRequest?: Maybe<BatchPayload>;
  createOneDisbursement: Disbursement;
  updateOneDisbursement: Disbursement;
  deleteOneDisbursement?: Maybe<Disbursement>;
  upsertOneDisbursement?: Maybe<Disbursement>;
  deleteManyDisbursement?: Maybe<BatchPayload>;
  updateManyDisbursement?: Maybe<BatchPayload>;
  createOneDocumentation: Documentation;
  updateOneDocumentation: Documentation;
  deleteOneDocumentation?: Maybe<Documentation>;
  upsertOneDocumentation?: Maybe<Documentation>;
  deleteManyDocumentation?: Maybe<BatchPayload>;
  updateManyDocumentation?: Maybe<BatchPayload>;
  createOneEzyVetIntegration: EzyVetIntegration;
  updateOneEzyVetIntegration: EzyVetIntegration;
  deleteOneEzyVetIntegration?: Maybe<EzyVetIntegration>;
  upsertOneEzyVetIntegration?: Maybe<EzyVetIntegration>;
  deleteManyEzyVetIntegration?: Maybe<BatchPayload>;
  updateManyEzyVetIntegration?: Maybe<BatchPayload>;
  createOneFinancialTransaction: FinancialTransaction;
  updateOneFinancialTransaction: FinancialTransaction;
  deleteOneFinancialTransaction?: Maybe<FinancialTransaction>;
  upsertOneFinancialTransaction?: Maybe<FinancialTransaction>;
  deleteManyFinancialTransaction?: Maybe<BatchPayload>;
  updateManyFinancialTransaction?: Maybe<BatchPayload>;
  createOneFinancialAdjustment: FinancialAdjustment;
  updateOneFinancialAdjustment: FinancialAdjustment;
  deleteOneFinancialAdjustment?: Maybe<FinancialAdjustment>;
  upsertOneFinancialAdjustment?: Maybe<FinancialAdjustment>;
  deleteManyFinancialAdjustment?: Maybe<BatchPayload>;
  updateManyFinancialAdjustment?: Maybe<BatchPayload>;
  createOneFormSubmission: FormSubmission;
  updateOneFormSubmission: FormSubmission;
  deleteOneFormSubmission?: Maybe<FormSubmission>;
  upsertOneFormSubmission?: Maybe<FormSubmission>;
  deleteManyFormSubmission?: Maybe<BatchPayload>;
  updateManyFormSubmission?: Maybe<BatchPayload>;
  createOneFormTemplate: FormTemplate;
  updateOneFormTemplate: FormTemplate;
  deleteOneFormTemplate?: Maybe<FormTemplate>;
  upsertOneFormTemplate?: Maybe<FormTemplate>;
  deleteManyFormTemplate?: Maybe<BatchPayload>;
  updateManyFormTemplate?: Maybe<BatchPayload>;
  createOneInstinctIntegration: InstinctIntegration;
  updateOneInstinctIntegration: InstinctIntegration;
  deleteOneInstinctIntegration?: Maybe<InstinctIntegration>;
  upsertOneInstinctIntegration?: Maybe<InstinctIntegration>;
  deleteManyInstinctIntegration?: Maybe<BatchPayload>;
  updateManyInstinctIntegration?: Maybe<BatchPayload>;
  createOneInvoice: Invoice;
  updateOneInvoice: Invoice;
  deleteOneInvoice?: Maybe<Invoice>;
  upsertOneInvoice?: Maybe<Invoice>;
  deleteManyInvoice?: Maybe<BatchPayload>;
  updateManyInvoice?: Maybe<BatchPayload>;
  createOneInvoiceLineItem: InvoiceLineItem;
  updateOneInvoiceLineItem: InvoiceLineItem;
  deleteOneInvoiceLineItem?: Maybe<InvoiceLineItem>;
  upsertOneInvoiceLineItem?: Maybe<InvoiceLineItem>;
  deleteManyInvoiceLineItem?: Maybe<BatchPayload>;
  updateManyInvoiceLineItem?: Maybe<BatchPayload>;
  createOneOrganization: Organization;
  updateOneOrganization: Organization;
  deleteOneOrganization?: Maybe<Organization>;
  upsertOneOrganization?: Maybe<Organization>;
  deleteManyOrganization?: Maybe<BatchPayload>;
  updateManyOrganization?: Maybe<BatchPayload>;
  createOneOrganizationPet: OrganizationPet;
  updateOneOrganizationPet: OrganizationPet;
  deleteOneOrganizationPet?: Maybe<OrganizationPet>;
  upsertOneOrganizationPet?: Maybe<OrganizationPet>;
  deleteManyOrganizationPet?: Maybe<BatchPayload>;
  updateManyOrganizationPet?: Maybe<BatchPayload>;
  createOneOrganizationPetParent: OrganizationPetParent;
  updateOneOrganizationPetParent: OrganizationPetParent;
  deleteOneOrganizationPetParent?: Maybe<OrganizationPetParent>;
  upsertOneOrganizationPetParent?: Maybe<OrganizationPetParent>;
  deleteManyOrganizationPetParent?: Maybe<BatchPayload>;
  updateManyOrganizationPetParent?: Maybe<BatchPayload>;
  createOneOrganizationPetToOrganizationPetParent: OrganizationPetToOrganizationPetParent;
  updateOneOrganizationPetToOrganizationPetParent: OrganizationPetToOrganizationPetParent;
  deleteOneOrganizationPetToOrganizationPetParent?: Maybe<OrganizationPetToOrganizationPetParent>;
  upsertOneOrganizationPetToOrganizationPetParent?: Maybe<OrganizationPetToOrganizationPetParent>;
  deleteManyOrganizationPetToOrganizationPetParent?: Maybe<BatchPayload>;
  createOnePermission: Permission;
  updateOnePermission: Permission;
  deleteOnePermission?: Maybe<Permission>;
  upsertOnePermission?: Maybe<Permission>;
  deleteManyPermission?: Maybe<BatchPayload>;
  updateManyPermission?: Maybe<BatchPayload>;
  createOnePetParentSetting: PetParentSetting;
  updateOnePetParentSetting: PetParentSetting;
  deleteOnePetParentSetting?: Maybe<PetParentSetting>;
  upsertOnePetParentSetting?: Maybe<PetParentSetting>;
  deleteManyPetParentSetting?: Maybe<BatchPayload>;
  updateManyPetParentSetting?: Maybe<BatchPayload>;
  createOnePimsInvoice: PimsInvoice;
  updateOnePimsInvoice: PimsInvoice;
  deleteOnePimsInvoice?: Maybe<PimsInvoice>;
  upsertOnePimsInvoice?: Maybe<PimsInvoice>;
  deleteManyPimsInvoice?: Maybe<BatchPayload>;
  updateManyPimsInvoice?: Maybe<BatchPayload>;
  createOnePimsInvoiceLineItem: PimsInvoiceLineItem;
  updateOnePimsInvoiceLineItem: PimsInvoiceLineItem;
  deleteOnePimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  upsertOnePimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  deleteManyPimsInvoiceLineItem?: Maybe<BatchPayload>;
  updateManyPimsInvoiceLineItem?: Maybe<BatchPayload>;
  createOneService: Service;
  updateOneService: Service;
  deleteOneService?: Maybe<Service>;
  upsertOneService?: Maybe<Service>;
  deleteManyService?: Maybe<BatchPayload>;
  updateManyService?: Maybe<BatchPayload>;
  createOneServiceReminderTiming: ServiceReminderTiming;
  updateOneServiceReminderTiming: ServiceReminderTiming;
  deleteOneServiceReminderTiming?: Maybe<ServiceReminderTiming>;
  upsertOneServiceReminderTiming?: Maybe<ServiceReminderTiming>;
  deleteManyServiceReminderTiming?: Maybe<BatchPayload>;
  updateManyServiceReminderTiming?: Maybe<BatchPayload>;
  createOneServiceReminder: ServiceReminder;
  updateOneServiceReminder: ServiceReminder;
  deleteOneServiceReminder?: Maybe<ServiceReminder>;
  upsertOneServiceReminder?: Maybe<ServiceReminder>;
  deleteManyServiceReminder?: Maybe<BatchPayload>;
  updateManyServiceReminder?: Maybe<BatchPayload>;
  createOneServiceReminderNotification: ServiceReminderNotification;
  updateOneServiceReminderNotification: ServiceReminderNotification;
  deleteOneServiceReminderNotification?: Maybe<ServiceReminderNotification>;
  upsertOneServiceReminderNotification?: Maybe<ServiceReminderNotification>;
  deleteManyServiceReminderNotification?: Maybe<BatchPayload>;
  updateManyServiceReminderNotification?: Maybe<BatchPayload>;
  createOneStaffRole: StaffRole;
  updateOneStaffRole: StaffRole;
  deleteOneStaffRole?: Maybe<StaffRole>;
  upsertOneStaffRole?: Maybe<StaffRole>;
  deleteManyStaffRole?: Maybe<BatchPayload>;
  updateManyStaffRole?: Maybe<BatchPayload>;
  createOneStripeDispute: StripeDispute;
  updateOneStripeDispute: StripeDispute;
  deleteOneStripeDispute?: Maybe<StripeDispute>;
  upsertOneStripeDispute?: Maybe<StripeDispute>;
  deleteManyStripeDispute?: Maybe<BatchPayload>;
  updateManyStripeDispute?: Maybe<BatchPayload>;
  createOneStripePaymentIntent: StripePaymentIntent;
  updateOneStripePaymentIntent: StripePaymentIntent;
  upsertOneStripePaymentIntent?: Maybe<StripePaymentIntent>;
  updateManyStripePaymentIntent?: Maybe<BatchPayload>;
  createOneStripePaymentMethod: StripePaymentMethod;
  updateOneStripePaymentMethod: StripePaymentMethod;
  deleteOneStripePaymentMethod?: Maybe<StripePaymentMethod>;
  upsertOneStripePaymentMethod?: Maybe<StripePaymentMethod>;
  deleteManyStripePaymentMethod?: Maybe<BatchPayload>;
  updateManyStripePaymentMethod?: Maybe<BatchPayload>;
  createOneStripePayout: StripePayout;
  updateOneStripePayout: StripePayout;
  deleteOneStripePayout?: Maybe<StripePayout>;
  upsertOneStripePayout?: Maybe<StripePayout>;
  deleteManyStripePayout?: Maybe<BatchPayload>;
  updateManyStripePayout?: Maybe<BatchPayload>;
  createOneStripePayoutAccount: StripePayoutAccount;
  updateOneStripePayoutAccount: StripePayoutAccount;
  deleteOneStripePayoutAccount?: Maybe<StripePayoutAccount>;
  upsertOneStripePayoutAccount?: Maybe<StripePayoutAccount>;
  deleteManyStripePayoutAccount?: Maybe<BatchPayload>;
  updateManyStripePayoutAccount?: Maybe<BatchPayload>;
  createOneStripeReceipt: StripeReceipt;
  updateOneStripeReceipt: StripeReceipt;
  deleteOneStripeReceipt?: Maybe<StripeReceipt>;
  upsertOneStripeReceipt?: Maybe<StripeReceipt>;
  deleteManyStripeReceipt?: Maybe<BatchPayload>;
  updateManyStripeReceipt?: Maybe<BatchPayload>;
  createOneStripeReceiptHistory: StripeReceiptHistory;
  updateOneStripeReceiptHistory: StripeReceiptHistory;
  deleteOneStripeReceiptHistory?: Maybe<StripeReceiptHistory>;
  upsertOneStripeReceiptHistory?: Maybe<StripeReceiptHistory>;
  deleteManyStripeReceiptHistory?: Maybe<BatchPayload>;
  updateManyStripeReceiptHistory?: Maybe<BatchPayload>;
  createOneStripeRefund: StripeRefund;
  updateOneStripeRefund: StripeRefund;
  deleteOneStripeRefund?: Maybe<StripeRefund>;
  upsertOneStripeRefund?: Maybe<StripeRefund>;
  deleteManyStripeRefund?: Maybe<BatchPayload>;
  updateManyStripeRefund?: Maybe<BatchPayload>;
  createOneStripeTerminal: StripeTerminal;
  updateOneStripeTerminal: StripeTerminal;
  deleteOneStripeTerminal?: Maybe<StripeTerminal>;
  upsertOneStripeTerminal?: Maybe<StripeTerminal>;
  deleteManyStripeTerminal?: Maybe<BatchPayload>;
  updateManyStripeTerminal?: Maybe<BatchPayload>;
  createOneStripeTerminalLocation: StripeTerminalLocation;
  updateOneStripeTerminalLocation: StripeTerminalLocation;
  deleteOneStripeTerminalLocation?: Maybe<StripeTerminalLocation>;
  upsertOneStripeTerminalLocation?: Maybe<StripeTerminalLocation>;
  deleteManyStripeTerminalLocation?: Maybe<BatchPayload>;
  updateManyStripeTerminalLocation?: Maybe<BatchPayload>;
  createOneTransaction: Transaction;
  updateOneTransaction: Transaction;
  deleteOneTransaction?: Maybe<Transaction>;
  upsertOneTransaction?: Maybe<Transaction>;
  deleteManyTransaction?: Maybe<BatchPayload>;
  updateManyTransaction?: Maybe<BatchPayload>;
  createOneUser: User;
  updateOneUser: User;
  deleteOneUser?: Maybe<User>;
  upsertOneUser?: Maybe<User>;
  deleteManyUser?: Maybe<BatchPayload>;
  updateManyUser?: Maybe<BatchPayload>;
  createOneUserAppointmentGrouping: UserAppointmentGrouping;
  updateOneUserAppointmentGrouping: UserAppointmentGrouping;
  deleteOneUserAppointmentGrouping?: Maybe<UserAppointmentGrouping>;
  upsertOneUserAppointmentGrouping?: Maybe<UserAppointmentGrouping>;
  deleteManyUserAppointmentGrouping?: Maybe<BatchPayload>;
  updateManyUserAppointmentGrouping?: Maybe<BatchPayload>;
  createOneUserNotificationSetting: UserNotificationSetting;
  updateOneUserNotificationSetting: UserNotificationSetting;
  deleteOneUserNotificationSetting?: Maybe<UserNotificationSetting>;
  upsertOneUserNotificationSetting?: Maybe<UserNotificationSetting>;
  deleteManyUserNotificationSetting?: Maybe<BatchPayload>;
  updateManyUserNotificationSetting?: Maybe<BatchPayload>;
  createOneUserSetting: UserSetting;
  updateOneUserSetting: UserSetting;
  deleteOneUserSetting?: Maybe<UserSetting>;
  upsertOneUserSetting?: Maybe<UserSetting>;
  deleteManyUserSetting?: Maybe<BatchPayload>;
  updateManyUserSetting?: Maybe<BatchPayload>;
  createOneChannelFilterSelection: ChannelFilterSelection;
  updateOneChannelFilterSelection: ChannelFilterSelection;
  deleteOneChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  upsertOneChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  deleteManyChannelFilterSelection?: Maybe<BatchPayload>;
  updateManyChannelFilterSelection?: Maybe<BatchPayload>;
  createOneUserChannelFilterSelection: UserChannelFilterSelection;
  updateOneUserChannelFilterSelection: UserChannelFilterSelection;
  deleteOneUserChannelFilterSelection?: Maybe<UserChannelFilterSelection>;
  upsertOneUserChannelFilterSelection?: Maybe<UserChannelFilterSelection>;
  deleteManyUserChannelFilterSelection?: Maybe<BatchPayload>;
  updateManyUserChannelFilterSelection?: Maybe<BatchPayload>;
  createOneVetAvailability: VetAvailability;
  updateOneVetAvailability: VetAvailability;
  deleteOneVetAvailability?: Maybe<VetAvailability>;
  upsertOneVetAvailability?: Maybe<VetAvailability>;
  deleteManyVetAvailability?: Maybe<BatchPayload>;
  updateManyVetAvailability?: Maybe<BatchPayload>;
  createOneProviderAppointmentTypeRoomMap: ProviderAppointmentTypeRoomMap;
  updateOneProviderAppointmentTypeRoomMap: ProviderAppointmentTypeRoomMap;
  deleteOneProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMap>;
  upsertOneProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMap>;
  deleteManyProviderAppointmentTypeRoomMap?: Maybe<BatchPayload>;
  updateManyProviderAppointmentTypeRoomMap?: Maybe<BatchPayload>;
  createOneVetAvailabilityMap: VetAvailabilityMap;
  updateOneVetAvailabilityMap: VetAvailabilityMap;
  deleteOneVetAvailabilityMap?: Maybe<VetAvailabilityMap>;
  upsertOneVetAvailabilityMap?: Maybe<VetAvailabilityMap>;
  deleteManyVetAvailabilityMap?: Maybe<BatchPayload>;
  updateManyVetAvailabilityMap?: Maybe<BatchPayload>;
  createOneVetAvailabilityClinicRoomMap: VetAvailabilityClinicRoomMap;
  updateOneVetAvailabilityClinicRoomMap: VetAvailabilityClinicRoomMap;
  deleteOneVetAvailabilityClinicRoomMap?: Maybe<VetAvailabilityClinicRoomMap>;
  upsertOneVetAvailabilityClinicRoomMap?: Maybe<VetAvailabilityClinicRoomMap>;
  deleteManyVetAvailabilityClinicRoomMap?: Maybe<BatchPayload>;
  updateManyVetAvailabilityClinicRoomMap?: Maybe<BatchPayload>;
  createOneClientConnectIntegration: ClientConnectIntegration;
  updateOneClientConnectIntegration: ClientConnectIntegration;
  deleteOneClientConnectIntegration?: Maybe<ClientConnectIntegration>;
  upsertOneClientConnectIntegration?: Maybe<ClientConnectIntegration>;
  deleteManyClientConnectIntegration?: Maybe<BatchPayload>;
  updateManyClientConnectIntegration?: Maybe<BatchPayload>;
  createOneVetInfo: VetInfo;
  updateOneVetInfo: VetInfo;
  deleteOneVetInfo?: Maybe<VetInfo>;
  upsertOneVetInfo?: Maybe<VetInfo>;
  deleteManyVetInfo?: Maybe<BatchPayload>;
  updateManyVetInfo?: Maybe<BatchPayload>;
  createOneWorkflowEventSetting: WorkflowEventSetting;
  updateOneWorkflowEventSetting: WorkflowEventSetting;
  deleteOneWorkflowEventSetting?: Maybe<WorkflowEventSetting>;
  upsertOneWorkflowEventSetting?: Maybe<WorkflowEventSetting>;
  deleteManyWorkflowEventSetting?: Maybe<BatchPayload>;
  updateManyWorkflowEventSetting?: Maybe<BatchPayload>;
  createOneClinicOfficeHour: ClinicOfficeHour;
  updateOneClinicOfficeHour: ClinicOfficeHour;
  deleteOneClinicOfficeHour?: Maybe<ClinicOfficeHour>;
  upsertOneClinicOfficeHour?: Maybe<ClinicOfficeHour>;
  deleteManyClinicOfficeHour?: Maybe<BatchPayload>;
  updateManyClinicOfficeHour?: Maybe<BatchPayload>;
  createOneEmailProviderLog: EmailProviderLog;
  updateOneEmailProviderLog: EmailProviderLog;
  deleteOneEmailProviderLog?: Maybe<EmailProviderLog>;
  upsertOneEmailProviderLog?: Maybe<EmailProviderLog>;
  deleteManyEmailProviderLog?: Maybe<BatchPayload>;
  updateManyEmailProviderLog?: Maybe<BatchPayload>;
  createOneCallHistory: CallHistory;
  updateOneCallHistory: CallHistory;
  deleteOneCallHistory?: Maybe<CallHistory>;
  upsertOneCallHistory?: Maybe<CallHistory>;
  deleteManyCallHistory?: Maybe<BatchPayload>;
  updateManyCallHistory?: Maybe<BatchPayload>;
  createOneCallParticipant: CallParticipant;
  updateOneCallParticipant: CallParticipant;
  deleteOneCallParticipant?: Maybe<CallParticipant>;
  upsertOneCallParticipant?: Maybe<CallParticipant>;
  deleteManyCallParticipant?: Maybe<BatchPayload>;
  updateManyCallParticipant?: Maybe<BatchPayload>;
  createOneSmsNotificationStatus: SmsNotificationStatus;
  updateOneSmsNotificationStatus: SmsNotificationStatus;
  deleteOneSmsNotificationStatus?: Maybe<SmsNotificationStatus>;
  upsertOneSmsNotificationStatus?: Maybe<SmsNotificationStatus>;
  deleteManySmsNotificationStatus?: Maybe<BatchPayload>;
  updateManySmsNotificationStatus?: Maybe<BatchPayload>;
  createOneFeatureFlag: FeatureFlag;
  updateOneFeatureFlag: FeatureFlag;
  deleteOneFeatureFlag?: Maybe<FeatureFlag>;
  upsertOneFeatureFlag?: Maybe<FeatureFlag>;
  deleteManyFeatureFlag?: Maybe<BatchPayload>;
  updateManyFeatureFlag?: Maybe<BatchPayload>;
  createOneCareAuthorizationRequest: CareAuthorizationRequest;
  updateOneCareAuthorizationRequest: CareAuthorizationRequest;
  deleteOneCareAuthorizationRequest?: Maybe<CareAuthorizationRequest>;
  upsertOneCareAuthorizationRequest?: Maybe<CareAuthorizationRequest>;
  deleteManyCareAuthorizationRequest?: Maybe<BatchPayload>;
  updateManyCareAuthorizationRequest?: Maybe<BatchPayload>;
  createOneCareBenefit: CareBenefit;
  updateOneCareBenefit: CareBenefit;
  deleteOneCareBenefit?: Maybe<CareBenefit>;
  upsertOneCareBenefit?: Maybe<CareBenefit>;
  deleteManyCareBenefit?: Maybe<BatchPayload>;
  updateManyCareBenefit?: Maybe<BatchPayload>;
  createOneCarePlan: CarePlan;
  updateOneCarePlan: CarePlan;
  deleteOneCarePlan?: Maybe<CarePlan>;
  upsertOneCarePlan?: Maybe<CarePlan>;
  deleteManyCarePlan?: Maybe<BatchPayload>;
  updateManyCarePlan?: Maybe<BatchPayload>;
  createOneCareAddonPackage: CareAddonPackage;
  updateOneCareAddonPackage: CareAddonPackage;
  deleteOneCareAddonPackage?: Maybe<CareAddonPackage>;
  upsertOneCareAddonPackage?: Maybe<CareAddonPackage>;
  deleteManyCareAddonPackage?: Maybe<BatchPayload>;
  updateManyCareAddonPackage?: Maybe<BatchPayload>;
  createOneCarePlanBenefitEnrollmentCarryover: CarePlanBenefitEnrollmentCarryover;
  updateOneCarePlanBenefitEnrollmentCarryover: CarePlanBenefitEnrollmentCarryover;
  deleteOneCarePlanBenefitEnrollmentCarryover?: Maybe<CarePlanBenefitEnrollmentCarryover>;
  upsertOneCarePlanBenefitEnrollmentCarryover?: Maybe<CarePlanBenefitEnrollmentCarryover>;
  deleteManyCarePlanBenefitEnrollmentCarryover?: Maybe<BatchPayload>;
  updateManyCarePlanBenefitEnrollmentCarryover?: Maybe<BatchPayload>;
  createOneCarePlanBenefit: CarePlanBenefit;
  updateOneCarePlanBenefit: CarePlanBenefit;
  deleteOneCarePlanBenefit?: Maybe<CarePlanBenefit>;
  upsertOneCarePlanBenefit?: Maybe<CarePlanBenefit>;
  deleteManyCarePlanBenefit?: Maybe<BatchPayload>;
  updateManyCarePlanBenefit?: Maybe<BatchPayload>;
  createOneCareAddonPackageBenefit: CareAddonPackageBenefit;
  updateOneCareAddonPackageBenefit: CareAddonPackageBenefit;
  deleteOneCareAddonPackageBenefit?: Maybe<CareAddonPackageBenefit>;
  upsertOneCareAddonPackageBenefit?: Maybe<CareAddonPackageBenefit>;
  deleteManyCareAddonPackageBenefit?: Maybe<BatchPayload>;
  updateManyCareAddonPackageBenefit?: Maybe<BatchPayload>;
  createOneEnrollmentSupportEvent: EnrollmentSupportEvent;
  updateOneEnrollmentSupportEvent: EnrollmentSupportEvent;
  deleteOneEnrollmentSupportEvent?: Maybe<EnrollmentSupportEvent>;
  upsertOneEnrollmentSupportEvent?: Maybe<EnrollmentSupportEvent>;
  deleteManyEnrollmentSupportEvent?: Maybe<BatchPayload>;
  updateManyEnrollmentSupportEvent?: Maybe<BatchPayload>;
  createOneCarePlanEnrollment: CarePlanEnrollment;
  updateOneCarePlanEnrollment: CarePlanEnrollment;
  deleteOneCarePlanEnrollment?: Maybe<CarePlanEnrollment>;
  upsertOneCarePlanEnrollment?: Maybe<CarePlanEnrollment>;
  deleteManyCarePlanEnrollment?: Maybe<BatchPayload>;
  updateManyCarePlanEnrollment?: Maybe<BatchPayload>;
  createOneCareAddonEnrollment: CareAddonEnrollment;
  updateOneCareAddonEnrollment: CareAddonEnrollment;
  deleteOneCareAddonEnrollment?: Maybe<CareAddonEnrollment>;
  upsertOneCareAddonEnrollment?: Maybe<CareAddonEnrollment>;
  deleteManyCareAddonEnrollment?: Maybe<BatchPayload>;
  updateManyCareAddonEnrollment?: Maybe<BatchPayload>;
  createOneCareEnrollmentToStripeInvoiceItem: CareEnrollmentToStripeInvoiceItem;
  updateOneCareEnrollmentToStripeInvoiceItem: CareEnrollmentToStripeInvoiceItem;
  deleteOneCareEnrollmentToStripeInvoiceItem?: Maybe<CareEnrollmentToStripeInvoiceItem>;
  upsertOneCareEnrollmentToStripeInvoiceItem?: Maybe<CareEnrollmentToStripeInvoiceItem>;
  deleteManyCareEnrollmentToStripeInvoiceItem?: Maybe<BatchPayload>;
  updateManyCareEnrollmentToStripeInvoiceItem?: Maybe<BatchPayload>;
  createOneCareBenefitUsage: CareBenefitUsage;
  updateOneCareBenefitUsage: CareBenefitUsage;
  deleteOneCareBenefitUsage?: Maybe<CareBenefitUsage>;
  upsertOneCareBenefitUsage?: Maybe<CareBenefitUsage>;
  deleteManyCareBenefitUsage?: Maybe<BatchPayload>;
  updateManyCareBenefitUsage?: Maybe<BatchPayload>;
  createOneCareBenefitUsageToInvoiceLineItem: CareBenefitUsageToInvoiceLineItem;
  updateOneCareBenefitUsageToInvoiceLineItem: CareBenefitUsageToInvoiceLineItem;
  deleteOneCareBenefitUsageToInvoiceLineItem?: Maybe<CareBenefitUsageToInvoiceLineItem>;
  upsertOneCareBenefitUsageToInvoiceLineItem?: Maybe<CareBenefitUsageToInvoiceLineItem>;
  deleteManyCareBenefitUsageToInvoiceLineItem?: Maybe<BatchPayload>;
  updateManyCareBenefitUsageToInvoiceLineItem?: Maybe<BatchPayload>;
  createOneCareStripeSubscription: CareStripeSubscription;
  updateOneCareStripeSubscription: CareStripeSubscription;
  deleteOneCareStripeSubscription?: Maybe<CareStripeSubscription>;
  upsertOneCareStripeSubscription?: Maybe<CareStripeSubscription>;
  deleteManyCareStripeSubscription?: Maybe<BatchPayload>;
  updateManyCareStripeSubscription?: Maybe<BatchPayload>;
  createOneStripeInvoice: StripeInvoice;
  updateOneStripeInvoice: StripeInvoice;
  deleteOneStripeInvoice?: Maybe<StripeInvoice>;
  upsertOneStripeInvoice?: Maybe<StripeInvoice>;
  deleteManyStripeInvoice?: Maybe<BatchPayload>;
  updateManyStripeInvoice?: Maybe<BatchPayload>;
  createOneStripeInvoiceItem: StripeInvoiceItem;
  updateOneStripeInvoiceItem: StripeInvoiceItem;
  deleteOneStripeInvoiceItem?: Maybe<StripeInvoiceItem>;
  upsertOneStripeInvoiceItem?: Maybe<StripeInvoiceItem>;
  deleteManyStripeInvoiceItem?: Maybe<BatchPayload>;
  updateManyStripeInvoiceItem?: Maybe<BatchPayload>;
  createOneStripeCustomer: StripeCustomer;
  updateOneStripeCustomer: StripeCustomer;
  deleteOneStripeCustomer?: Maybe<StripeCustomer>;
  upsertOneStripeCustomer?: Maybe<StripeCustomer>;
  deleteManyStripeCustomer?: Maybe<BatchPayload>;
  updateManyStripeCustomer?: Maybe<BatchPayload>;
  createOneVaccination: Vaccination;
  updateOneVaccination: Vaccination;
  deleteOneVaccination?: Maybe<Vaccination>;
  upsertOneVaccination?: Maybe<Vaccination>;
  deleteManyVaccination?: Maybe<BatchPayload>;
  updateManyVaccination?: Maybe<BatchPayload>;
  createOnePimsWritebackLogEntry: PimsWritebackLogEntry;
  updateOnePimsWritebackLogEntry: PimsWritebackLogEntry;
  deleteOnePimsWritebackLogEntry?: Maybe<PimsWritebackLogEntry>;
  upsertOnePimsWritebackLogEntry?: Maybe<PimsWritebackLogEntry>;
  deleteManyPimsWritebackLogEntry?: Maybe<BatchPayload>;
  updateManyPimsWritebackLogEntry?: Maybe<BatchPayload>;
  createOneInvoicePimsWritebackLogEntry: InvoicePimsWritebackLogEntry;
  updateOneInvoicePimsWritebackLogEntry: InvoicePimsWritebackLogEntry;
  deleteOneInvoicePimsWritebackLogEntry?: Maybe<InvoicePimsWritebackLogEntry>;
  upsertOneInvoicePimsWritebackLogEntry?: Maybe<InvoicePimsWritebackLogEntry>;
  deleteManyInvoicePimsWritebackLogEntry?: Maybe<BatchPayload>;
  updateManyInvoicePimsWritebackLogEntry?: Maybe<BatchPayload>;
  createOneOrganizationCareBenefitToClinicService: OrganizationCareBenefitToClinicService;
  updateOneOrganizationCareBenefitToClinicService: OrganizationCareBenefitToClinicService;
  deleteOneOrganizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicService>;
  upsertOneOrganizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicService>;
  deleteManyOrganizationCareBenefitToClinicService?: Maybe<BatchPayload>;
  createOneOrganizationCareBenefitToClinicServiceCategory: OrganizationCareBenefitToClinicServiceCategory;
  updateOneOrganizationCareBenefitToClinicServiceCategory: OrganizationCareBenefitToClinicServiceCategory;
  deleteOneOrganizationCareBenefitToClinicServiceCategory?: Maybe<OrganizationCareBenefitToClinicServiceCategory>;
  upsertOneOrganizationCareBenefitToClinicServiceCategory?: Maybe<OrganizationCareBenefitToClinicServiceCategory>;
  deleteManyOrganizationCareBenefitToClinicServiceCategory?: Maybe<BatchPayload>;
  updateManyOrganizationCareBenefitToClinicServiceCategory?: Maybe<BatchPayload>;
  createOneMessageTemplate: MessageTemplate;
  updateOneMessageTemplate: MessageTemplate;
  deleteOneMessageTemplate?: Maybe<MessageTemplate>;
  upsertOneMessageTemplate?: Maybe<MessageTemplate>;
  deleteManyMessageTemplate?: Maybe<BatchPayload>;
  updateManyMessageTemplate?: Maybe<BatchPayload>;
  createOneNotificationEventToMessage: NotificationEventToMessage;
  updateOneNotificationEventToMessage: NotificationEventToMessage;
  deleteOneNotificationEventToMessage?: Maybe<NotificationEventToMessage>;
  upsertOneNotificationEventToMessage?: Maybe<NotificationEventToMessage>;
  deleteManyNotificationEventToMessage?: Maybe<BatchPayload>;
  updateManyNotificationEventToMessage?: Maybe<BatchPayload>;
  createOneRules: Rules;
  updateOneRules: Rules;
  deleteOneRules?: Maybe<Rules>;
  upsertOneRules?: Maybe<Rules>;
  deleteManyRules?: Maybe<BatchPayload>;
  updateManyRules?: Maybe<BatchPayload>;
  createOneAutomationRun: AutomationRun;
  updateOneAutomationRun: AutomationRun;
  deleteOneAutomationRun?: Maybe<AutomationRun>;
  upsertOneAutomationRun?: Maybe<AutomationRun>;
  deleteManyAutomationRun?: Maybe<BatchPayload>;
  updateManyAutomationRun?: Maybe<BatchPayload>;
  createOneAutomationRunAction: AutomationRunAction;
  updateOneAutomationRunAction: AutomationRunAction;
  deleteOneAutomationRunAction?: Maybe<AutomationRunAction>;
  upsertOneAutomationRunAction?: Maybe<AutomationRunAction>;
  deleteManyAutomationRunAction?: Maybe<BatchPayload>;
  updateManyAutomationRunAction?: Maybe<BatchPayload>;
  createOneEmergencyClinics: EmergencyClinics;
  updateOneEmergencyClinics: EmergencyClinics;
  deleteOneEmergencyClinics?: Maybe<EmergencyClinics>;
  upsertOneEmergencyClinics?: Maybe<EmergencyClinics>;
  deleteManyEmergencyClinics?: Maybe<BatchPayload>;
  updateManyEmergencyClinics?: Maybe<BatchPayload>;
  createOneCareEnrollmentPayment: CareEnrollmentPayment;
  updateOneCareEnrollmentPayment: CareEnrollmentPayment;
  deleteOneCareEnrollmentPayment?: Maybe<CareEnrollmentPayment>;
  upsertOneCareEnrollmentPayment?: Maybe<CareEnrollmentPayment>;
  deleteManyCareEnrollmentPayment?: Maybe<BatchPayload>;
  updateManyCareEnrollmentPayment?: Maybe<BatchPayload>;
  createOneMassTextAlert: MassTextAlert;
  updateOneMassTextAlert: MassTextAlert;
  deleteOneMassTextAlert?: Maybe<MassTextAlert>;
  upsertOneMassTextAlert?: Maybe<MassTextAlert>;
  deleteManyMassTextAlert?: Maybe<BatchPayload>;
  updateManyMassTextAlert?: Maybe<BatchPayload>;
  createOneMassTextAlertEntry: MassTextAlertEntry;
  updateOneMassTextAlertEntry: MassTextAlertEntry;
  deleteOneMassTextAlertEntry?: Maybe<MassTextAlertEntry>;
  upsertOneMassTextAlertEntry?: Maybe<MassTextAlertEntry>;
  deleteManyMassTextAlertEntry?: Maybe<BatchPayload>;
  updateManyMassTextAlertEntry?: Maybe<BatchPayload>;
  createOneMassTextAlertEntryAppointment: MassTextAlertEntryAppointment;
  updateOneMassTextAlertEntryAppointment: MassTextAlertEntryAppointment;
  deleteOneMassTextAlertEntryAppointment?: Maybe<MassTextAlertEntryAppointment>;
  upsertOneMassTextAlertEntryAppointment?: Maybe<MassTextAlertEntryAppointment>;
  deleteManyMassTextAlertEntryAppointment?: Maybe<BatchPayload>;
  updateManyMassTextAlertEntryAppointment?: Maybe<BatchPayload>;
  createOnePetPortalSetting: PetPortalSetting;
  updateOnePetPortalSetting: PetPortalSetting;
  deleteOnePetPortalSetting?: Maybe<PetPortalSetting>;
  upsertOnePetPortalSetting?: Maybe<PetPortalSetting>;
  deleteManyPetPortalSetting?: Maybe<BatchPayload>;
  updateManyPetPortalSetting?: Maybe<BatchPayload>;
  createOnePrescription: Prescription;
  updateOnePrescription: Prescription;
  deleteOnePrescription?: Maybe<Prescription>;
  upsertOnePrescription?: Maybe<Prescription>;
  deleteManyPrescription?: Maybe<BatchPayload>;
  updateManyPrescription?: Maybe<BatchPayload>;
  createOneClinicPhoneNumber: ClinicPhoneNumber;
  updateOneClinicPhoneNumber: ClinicPhoneNumber;
  deleteOneClinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  upsertOneClinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  deleteManyClinicPhoneNumber?: Maybe<BatchPayload>;
  updateManyClinicPhoneNumber?: Maybe<BatchPayload>;
  createOneClinicOnboarding: ClinicOnboarding;
  updateOneClinicOnboarding: ClinicOnboarding;
  deleteOneClinicOnboarding?: Maybe<ClinicOnboarding>;
  upsertOneClinicOnboarding?: Maybe<ClinicOnboarding>;
  deleteManyClinicOnboarding?: Maybe<BatchPayload>;
  updateManyClinicOnboarding?: Maybe<BatchPayload>;
  createOneSurveyAppointmentTypeSetting: SurveyAppointmentTypeSetting;
  updateOneSurveyAppointmentTypeSetting: SurveyAppointmentTypeSetting;
  deleteOneSurveyAppointmentTypeSetting?: Maybe<SurveyAppointmentTypeSetting>;
  upsertOneSurveyAppointmentTypeSetting?: Maybe<SurveyAppointmentTypeSetting>;
  deleteManySurveyAppointmentTypeSetting?: Maybe<BatchPayload>;
  updateManySurveyAppointmentTypeSetting?: Maybe<BatchPayload>;
  createOneSurveySetting: SurveySetting;
  updateOneSurveySetting: SurveySetting;
  deleteOneSurveySetting?: Maybe<SurveySetting>;
  upsertOneSurveySetting?: Maybe<SurveySetting>;
  deleteManySurveySetting?: Maybe<BatchPayload>;
  updateManySurveySetting?: Maybe<BatchPayload>;
  createOneSurveyResult: SurveyResult;
  updateOneSurveyResult: SurveyResult;
  deleteOneSurveyResult?: Maybe<SurveyResult>;
  upsertOneSurveyResult?: Maybe<SurveyResult>;
  deleteManySurveyResult?: Maybe<BatchPayload>;
  updateManySurveyResult?: Maybe<BatchPayload>;
  createOneServiceCategory: ServiceCategory;
  updateOneServiceCategory: ServiceCategory;
  deleteOneServiceCategory?: Maybe<ServiceCategory>;
  upsertOneServiceCategory?: Maybe<ServiceCategory>;
  deleteManyServiceCategory?: Maybe<BatchPayload>;
  updateManyServiceCategory?: Maybe<BatchPayload>;
  updateOneBitwerxPaymentWritebackConfiguration: BitwerxPaymentWritebackConfiguration;
  upsertOneBitwerxPaymentWritebackConfiguration?: Maybe<BitwerxPaymentWritebackConfiguration>;
  createOneGlobalPetParent: GlobalPetParent;
  updateOneGlobalPetParent: GlobalPetParent;
  deleteOneGlobalPetParent?: Maybe<GlobalPetParent>;
  upsertOneGlobalPetParent?: Maybe<GlobalPetParent>;
  deleteManyGlobalPetParent?: Maybe<BatchPayload>;
  updateManyGlobalPetParent?: Maybe<BatchPayload>;
  updateOneLoyaltyProgram: LoyaltyProgram;
  createOneLoyaltyAccountMerger: LoyaltyAccountMerger;
  updateOneLoyaltyAccountMerger: LoyaltyAccountMerger;
  deleteOneLoyaltyAccountMerger?: Maybe<LoyaltyAccountMerger>;
  upsertOneLoyaltyAccountMerger?: Maybe<LoyaltyAccountMerger>;
  deleteManyLoyaltyAccountMerger?: Maybe<BatchPayload>;
  updateManyLoyaltyAccountMerger?: Maybe<BatchPayload>;
  createOneLoyaltyAccount: LoyaltyAccount;
  updateOneLoyaltyAccount: LoyaltyAccount;
  deleteOneLoyaltyAccount?: Maybe<LoyaltyAccount>;
  upsertOneLoyaltyAccount?: Maybe<LoyaltyAccount>;
  deleteManyLoyaltyAccount?: Maybe<BatchPayload>;
  updateManyLoyaltyAccount?: Maybe<BatchPayload>;
  createOneLoyaltyAccountHolder: LoyaltyAccountHolder;
  updateOneLoyaltyAccountHolder: LoyaltyAccountHolder;
  deleteOneLoyaltyAccountHolder?: Maybe<LoyaltyAccountHolder>;
  upsertOneLoyaltyAccountHolder?: Maybe<LoyaltyAccountHolder>;
  deleteManyLoyaltyAccountHolder?: Maybe<BatchPayload>;
  updateManyLoyaltyAccountHolder?: Maybe<BatchPayload>;
  createOneLoyaltyReward: LoyaltyReward;
  updateOneLoyaltyReward: LoyaltyReward;
  upsertOneLoyaltyReward?: Maybe<LoyaltyReward>;
  updateManyLoyaltyReward?: Maybe<BatchPayload>;
  createOneLoyaltyRewardRedemption: LoyaltyRewardRedemption;
  updateOneLoyaltyRewardRedemption: LoyaltyRewardRedemption;
  deleteOneLoyaltyRewardRedemption?: Maybe<LoyaltyRewardRedemption>;
  upsertOneLoyaltyRewardRedemption?: Maybe<LoyaltyRewardRedemption>;
  deleteManyLoyaltyRewardRedemption?: Maybe<BatchPayload>;
  updateManyLoyaltyRewardRedemption?: Maybe<BatchPayload>;
  createOneLoyaltyInvoicePointGranter: LoyaltyInvoicePointGranter;
  updateOneLoyaltyInvoicePointGranter: LoyaltyInvoicePointGranter;
  deleteOneLoyaltyInvoicePointGranter?: Maybe<LoyaltyInvoicePointGranter>;
  upsertOneLoyaltyInvoicePointGranter?: Maybe<LoyaltyInvoicePointGranter>;
  deleteManyLoyaltyInvoicePointGranter?: Maybe<BatchPayload>;
  updateManyLoyaltyInvoicePointGranter?: Maybe<BatchPayload>;
  createOneLoyaltyPointGrantingService: LoyaltyPointGrantingService;
  updateOneLoyaltyPointGrantingService: LoyaltyPointGrantingService;
  deleteOneLoyaltyPointGrantingService?: Maybe<LoyaltyPointGrantingService>;
  upsertOneLoyaltyPointGrantingService?: Maybe<LoyaltyPointGrantingService>;
  deleteManyLoyaltyPointGrantingService?: Maybe<BatchPayload>;
  updateManyLoyaltyPointGrantingService?: Maybe<BatchPayload>;
  createOneLoyaltyPointGrantingServiceCategory: LoyaltyPointGrantingServiceCategory;
  updateOneLoyaltyPointGrantingServiceCategory: LoyaltyPointGrantingServiceCategory;
  deleteOneLoyaltyPointGrantingServiceCategory?: Maybe<LoyaltyPointGrantingServiceCategory>;
  upsertOneLoyaltyPointGrantingServiceCategory?: Maybe<LoyaltyPointGrantingServiceCategory>;
  deleteManyLoyaltyPointGrantingServiceCategory?: Maybe<BatchPayload>;
  updateManyLoyaltyPointGrantingServiceCategory?: Maybe<BatchPayload>;
  createOneLoyaltyPointUserGrant: LoyaltyPointUserGrant;
  updateOneLoyaltyPointUserGrant: LoyaltyPointUserGrant;
  deleteOneLoyaltyPointUserGrant?: Maybe<LoyaltyPointUserGrant>;
  upsertOneLoyaltyPointUserGrant?: Maybe<LoyaltyPointUserGrant>;
  deleteManyLoyaltyPointUserGrant?: Maybe<BatchPayload>;
  updateManyLoyaltyPointUserGrant?: Maybe<BatchPayload>;
  createOnePhoneNumberLookupRequest: PhoneNumberLookupRequest;
  updateOnePhoneNumberLookupRequest: PhoneNumberLookupRequest;
  deleteOnePhoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequest>;
  upsertOnePhoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequest>;
  deleteManyPhoneNumberLookupRequest?: Maybe<BatchPayload>;
  updateManyPhoneNumberLookupRequest?: Maybe<BatchPayload>;
  createOnePhoneNumberLookupResult: PhoneNumberLookupResult;
  updateOnePhoneNumberLookupResult: PhoneNumberLookupResult;
  deleteOnePhoneNumberLookupResult?: Maybe<PhoneNumberLookupResult>;
  upsertOnePhoneNumberLookupResult?: Maybe<PhoneNumberLookupResult>;
  deleteManyPhoneNumberLookupResult?: Maybe<BatchPayload>;
  updateManyPhoneNumberLookupResult?: Maybe<BatchPayload>;
  createOneClinicPetPimsSyncStatus: ClinicPetPimsSyncStatus;
  updateOneClinicPetPimsSyncStatus: ClinicPetPimsSyncStatus;
  deleteOneClinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatus>;
  upsertOneClinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatus>;
  deleteManyClinicPetPimsSyncStatus?: Maybe<BatchPayload>;
  updateManyClinicPetPimsSyncStatus?: Maybe<BatchPayload>;
  createOneClinicPetParentPimsSyncStatus: ClinicPetParentPimsSyncStatus;
  updateOneClinicPetParentPimsSyncStatus: ClinicPetParentPimsSyncStatus;
  deleteOneClinicPetParentPimsSyncStatus?: Maybe<ClinicPetParentPimsSyncStatus>;
  upsertOneClinicPetParentPimsSyncStatus?: Maybe<ClinicPetParentPimsSyncStatus>;
  deleteManyClinicPetParentPimsSyncStatus?: Maybe<BatchPayload>;
  updateManyClinicPetParentPimsSyncStatus?: Maybe<BatchPayload>;
  createOneCampaignRegistryCampaign: CampaignRegistryCampaign;
  updateOneCampaignRegistryCampaign: CampaignRegistryCampaign;
  deleteOneCampaignRegistryCampaign?: Maybe<CampaignRegistryCampaign>;
  upsertOneCampaignRegistryCampaign?: Maybe<CampaignRegistryCampaign>;
  deleteManyCampaignRegistryCampaign?: Maybe<BatchPayload>;
  updateManyCampaignRegistryCampaign?: Maybe<BatchPayload>;
  createOneLegalEntity: LegalEntity;
  updateOneLegalEntity: LegalEntity;
  upsertOneLegalEntity?: Maybe<LegalEntity>;
  updateManyLegalEntity?: Maybe<BatchPayload>;
  createOneClinicPetParentDevice: ClinicPetParentDevice;
  updateOneClinicPetParentDevice: ClinicPetParentDevice;
  deleteOneClinicPetParentDevice?: Maybe<ClinicPetParentDevice>;
  upsertOneClinicPetParentDevice?: Maybe<ClinicPetParentDevice>;
  deleteManyClinicPetParentDevice?: Maybe<BatchPayload>;
  updateManyClinicPetParentDevice?: Maybe<BatchPayload>;
  updateOneEmailTemplateVersion: EmailTemplateVersion;
  updateManyEmailTemplateVersion?: Maybe<BatchPayload>;
  createOneCareBenefitUsageFollowup: CareBenefitUsageFollowup;
  updateOneCareBenefitUsageFollowup: CareBenefitUsageFollowup;
  deleteOneCareBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowup>;
  upsertOneCareBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowup>;
  deleteManyCareBenefitUsageFollowup?: Maybe<BatchPayload>;
  updateManyCareBenefitUsageFollowup?: Maybe<BatchPayload>;
  createOneCareBenefitUsageFollowupStep: CareBenefitUsageFollowupStep;
  updateOneCareBenefitUsageFollowupStep: CareBenefitUsageFollowupStep;
  deleteOneCareBenefitUsageFollowupStep?: Maybe<CareBenefitUsageFollowupStep>;
  upsertOneCareBenefitUsageFollowupStep?: Maybe<CareBenefitUsageFollowupStep>;
  deleteManyCareBenefitUsageFollowupStep?: Maybe<BatchPayload>;
  updateManyCareBenefitUsageFollowupStep?: Maybe<BatchPayload>;
  createOneCarePlanProviderGroup: CarePlanProviderGroup;
  updateOneCarePlanProviderGroup: CarePlanProviderGroup;
  deleteOneCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  upsertOneCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  deleteManyCarePlanProviderGroup?: Maybe<BatchPayload>;
  updateManyCarePlanProviderGroup?: Maybe<BatchPayload>;
  createOneInsuranceIntegration: InsuranceIntegration;
  updateOneInsuranceIntegration: InsuranceIntegration;
  deleteOneInsuranceIntegration?: Maybe<InsuranceIntegration>;
  upsertOneInsuranceIntegration?: Maybe<InsuranceIntegration>;
  deleteManyInsuranceIntegration?: Maybe<BatchPayload>;
  updateManyInsuranceIntegration?: Maybe<BatchPayload>;
  createOneTrupanionIntegration: TrupanionIntegration;
  updateOneTrupanionIntegration: TrupanionIntegration;
  deleteOneTrupanionIntegration?: Maybe<TrupanionIntegration>;
  upsertOneTrupanionIntegration?: Maybe<TrupanionIntegration>;
  deleteManyTrupanionIntegration?: Maybe<BatchPayload>;
  updateManyTrupanionIntegration?: Maybe<BatchPayload>;
  createOneTrupanionIntegratedAppointmentType: TrupanionIntegratedAppointmentType;
  updateOneTrupanionIntegratedAppointmentType: TrupanionIntegratedAppointmentType;
  deleteOneTrupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentType>;
  upsertOneTrupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentType>;
  deleteManyTrupanionIntegratedAppointmentType?: Maybe<BatchPayload>;
  updateManyTrupanionIntegratedAppointmentType?: Maybe<BatchPayload>;
  createOneSentTrupanionExamDayOffer: SentTrupanionExamDayOffer;
  updateOneSentTrupanionExamDayOffer: SentTrupanionExamDayOffer;
  deleteOneSentTrupanionExamDayOffer?: Maybe<SentTrupanionExamDayOffer>;
  upsertOneSentTrupanionExamDayOffer?: Maybe<SentTrupanionExamDayOffer>;
  deleteManySentTrupanionExamDayOffer?: Maybe<BatchPayload>;
  updateManySentTrupanionExamDayOffer?: Maybe<BatchPayload>;
  createOneBreed: Breed;
  updateOneBreed: Breed;
  deleteOneBreed?: Maybe<Breed>;
  upsertOneBreed?: Maybe<Breed>;
  deleteManyBreed?: Maybe<BatchPayload>;
  updateManyBreed?: Maybe<BatchPayload>;
  createOneSpecies: Species;
  updateOneSpecies: Species;
  deleteOneSpecies?: Maybe<Species>;
  upsertOneSpecies?: Maybe<Species>;
  deleteManySpecies?: Maybe<BatchPayload>;
  updateManySpecies?: Maybe<BatchPayload>;
  createOneSex: Sex;
  updateOneSex: Sex;
  deleteOneSex?: Maybe<Sex>;
  upsertOneSex?: Maybe<Sex>;
  deleteManySex?: Maybe<BatchPayload>;
  updateManySex?: Maybe<BatchPayload>;
  createOneColor: Color;
  updateOneColor: Color;
  deleteOneColor?: Maybe<Color>;
  upsertOneColor?: Maybe<Color>;
  deleteManyColor?: Maybe<BatchPayload>;
  updateManyColor?: Maybe<BatchPayload>;
  createOneMedia: Media;
  updateOneMedia: Media;
  deleteOneMedia?: Maybe<Media>;
  upsertOneMedia?: Maybe<Media>;
  deleteManyMedia?: Maybe<BatchPayload>;
  updateManyMedia?: Maybe<BatchPayload>;
  createOneLapsedPetParentTriggers: LapsedPetParentTriggers;
  updateOneLapsedPetParentTriggers: LapsedPetParentTriggers;
  deleteOneLapsedPetParentTriggers?: Maybe<LapsedPetParentTriggers>;
  upsertOneLapsedPetParentTriggers?: Maybe<LapsedPetParentTriggers>;
  deleteManyLapsedPetParentTriggers?: Maybe<BatchPayload>;
  updateManyLapsedPetParentTriggers?: Maybe<BatchPayload>;
  createOneConditionSet: ConditionSet;
  updateOneConditionSet: ConditionSet;
  deleteOneConditionSet?: Maybe<ConditionSet>;
  upsertOneConditionSet?: Maybe<ConditionSet>;
  deleteManyConditionSet?: Maybe<BatchPayload>;
  updateManyConditionSet?: Maybe<BatchPayload>;
  createOneCondition: Condition;
  updateOneCondition: Condition;
  deleteOneCondition?: Maybe<Condition>;
  upsertOneCondition?: Maybe<Condition>;
  deleteManyCondition?: Maybe<BatchPayload>;
  updateManyCondition?: Maybe<BatchPayload>;
  createOnePushNotificationLog: PushNotificationLog;
  updateOnePushNotificationLog: PushNotificationLog;
  deleteOnePushNotificationLog?: Maybe<PushNotificationLog>;
  upsertOnePushNotificationLog?: Maybe<PushNotificationLog>;
  deleteManyPushNotificationLog?: Maybe<BatchPayload>;
  updateManyPushNotificationLog?: Maybe<BatchPayload>;
  createOneTrupanionCertificateAvailability: TrupanionCertificateAvailability;
  updateOneTrupanionCertificateAvailability: TrupanionCertificateAvailability;
  deleteOneTrupanionCertificateAvailability?: Maybe<TrupanionCertificateAvailability>;
  upsertOneTrupanionCertificateAvailability?: Maybe<TrupanionCertificateAvailability>;
  deleteManyTrupanionCertificateAvailability?: Maybe<BatchPayload>;
  updateManyTrupanionCertificateAvailability?: Maybe<BatchPayload>;
  createOneMessagingPartner: MessagingPartner;
  updateOneMessagingPartner: MessagingPartner;
  deleteOneMessagingPartner?: Maybe<MessagingPartner>;
  upsertOneMessagingPartner?: Maybe<MessagingPartner>;
  deleteManyMessagingPartner?: Maybe<BatchPayload>;
  updateManyMessagingPartner?: Maybe<BatchPayload>;
  createOneMessagingCampaign: MessagingCampaign;
  updateOneMessagingCampaign: MessagingCampaign;
  deleteOneMessagingCampaign?: Maybe<MessagingCampaign>;
  upsertOneMessagingCampaign?: Maybe<MessagingCampaign>;
  deleteManyMessagingCampaign?: Maybe<BatchPayload>;
  updateManyMessagingCampaign?: Maybe<BatchPayload>;
  createOneConditionalEntityEvaluation: ConditionalEntityEvaluation;
  updateOneConditionalEntityEvaluation: ConditionalEntityEvaluation;
  deleteOneConditionalEntityEvaluation?: Maybe<ConditionalEntityEvaluation>;
  upsertOneConditionalEntityEvaluation?: Maybe<ConditionalEntityEvaluation>;
  deleteManyConditionalEntityEvaluation?: Maybe<BatchPayload>;
  updateManyConditionalEntityEvaluation?: Maybe<BatchPayload>;
  createOnePayoutBatchingPeriod: PayoutBatchingPeriod;
  updateOnePayoutBatchingPeriod: PayoutBatchingPeriod;
  upsertOnePayoutBatchingPeriod?: Maybe<PayoutBatchingPeriod>;
  updateManyPayoutBatchingPeriod?: Maybe<BatchPayload>;
  createOneBitwerxHealthcheck: BitwerxHealthcheck;
  updateOneBitwerxHealthcheck: BitwerxHealthcheck;
  deleteOneBitwerxHealthcheck?: Maybe<BitwerxHealthcheck>;
  upsertOneBitwerxHealthcheck?: Maybe<BitwerxHealthcheck>;
  deleteManyBitwerxHealthcheck?: Maybe<BatchPayload>;
  updateManyBitwerxHealthcheck?: Maybe<BatchPayload>;
  createOneBitwerxPaymentHealthcheckRun: BitwerxPaymentHealthcheckRun;
  updateOneBitwerxPaymentHealthcheckRun: BitwerxPaymentHealthcheckRun;
  deleteOneBitwerxPaymentHealthcheckRun?: Maybe<BitwerxPaymentHealthcheckRun>;
  upsertOneBitwerxPaymentHealthcheckRun?: Maybe<BitwerxPaymentHealthcheckRun>;
  deleteManyBitwerxPaymentHealthcheckRun?: Maybe<BatchPayload>;
  updateManyBitwerxPaymentHealthcheckRun?: Maybe<BatchPayload>;
  createOneBitwerxPaymentHealthcheckResult: BitwerxPaymentHealthcheckResult;
  updateOneBitwerxPaymentHealthcheckResult: BitwerxPaymentHealthcheckResult;
  deleteOneBitwerxPaymentHealthcheckResult?: Maybe<BitwerxPaymentHealthcheckResult>;
  upsertOneBitwerxPaymentHealthcheckResult?: Maybe<BitwerxPaymentHealthcheckResult>;
  deleteManyBitwerxPaymentHealthcheckResult?: Maybe<BatchPayload>;
  updateManyBitwerxPaymentHealthcheckResult?: Maybe<BatchPayload>;
  createOneCareLapsedPayment: CareLapsedPayment;
  updateOneCareLapsedPayment: CareLapsedPayment;
  deleteOneCareLapsedPayment?: Maybe<CareLapsedPayment>;
  upsertOneCareLapsedPayment?: Maybe<CareLapsedPayment>;
  deleteManyCareLapsedPayment?: Maybe<BatchPayload>;
  updateManyCareLapsedPayment?: Maybe<BatchPayload>;
  createOneClinicPetParentCustomFieldValue: ClinicPetParentCustomFieldValue;
  updateOneClinicPetParentCustomFieldValue: ClinicPetParentCustomFieldValue;
  deleteOneClinicPetParentCustomFieldValue?: Maybe<ClinicPetParentCustomFieldValue>;
  upsertOneClinicPetParentCustomFieldValue?: Maybe<ClinicPetParentCustomFieldValue>;
  deleteManyClinicPetParentCustomFieldValue?: Maybe<BatchPayload>;
  updateManyClinicPetParentCustomFieldValue?: Maybe<BatchPayload>;
  createOneClinicPetCustomFieldValue: ClinicPetCustomFieldValue;
  updateOneClinicPetCustomFieldValue: ClinicPetCustomFieldValue;
  deleteOneClinicPetCustomFieldValue?: Maybe<ClinicPetCustomFieldValue>;
  upsertOneClinicPetCustomFieldValue?: Maybe<ClinicPetCustomFieldValue>;
  deleteManyClinicPetCustomFieldValue?: Maybe<BatchPayload>;
  updateManyClinicPetCustomFieldValue?: Maybe<BatchPayload>;
  createOneCustomFieldDefinition: CustomFieldDefinition;
  updateOneCustomFieldDefinition: CustomFieldDefinition;
  deleteOneCustomFieldDefinition?: Maybe<CustomFieldDefinition>;
  upsertOneCustomFieldDefinition?: Maybe<CustomFieldDefinition>;
  deleteManyCustomFieldDefinition?: Maybe<BatchPayload>;
  updateManyCustomFieldDefinition?: Maybe<BatchPayload>;
  createOneCustomFieldPimsWritebackLogEntry: CustomFieldPimsWritebackLogEntry;
  updateOneCustomFieldPimsWritebackLogEntry: CustomFieldPimsWritebackLogEntry;
  deleteOneCustomFieldPimsWritebackLogEntry?: Maybe<CustomFieldPimsWritebackLogEntry>;
  upsertOneCustomFieldPimsWritebackLogEntry?: Maybe<CustomFieldPimsWritebackLogEntry>;
  deleteManyCustomFieldPimsWritebackLogEntry?: Maybe<BatchPayload>;
  updateManyCustomFieldPimsWritebackLogEntry?: Maybe<BatchPayload>;
  createOneEmailCampaign: EmailCampaign;
  updateOneEmailCampaign: EmailCampaign;
  deleteOneEmailCampaign?: Maybe<EmailCampaign>;
  upsertOneEmailCampaign?: Maybe<EmailCampaign>;
  deleteManyEmailCampaign?: Maybe<BatchPayload>;
  updateManyEmailCampaign?: Maybe<BatchPayload>;
  createOneEmailCampaignTemplate: EmailCampaignTemplate;
  updateOneEmailCampaignTemplate: EmailCampaignTemplate;
  deleteOneEmailCampaignTemplate?: Maybe<EmailCampaignTemplate>;
  upsertOneEmailCampaignTemplate?: Maybe<EmailCampaignTemplate>;
  deleteManyEmailCampaignTemplate?: Maybe<BatchPayload>;
  updateManyEmailCampaignTemplate?: Maybe<BatchPayload>;
  createOneEmailCampaignLog: EmailCampaignLog;
  updateOneEmailCampaignLog: EmailCampaignLog;
  deleteOneEmailCampaignLog?: Maybe<EmailCampaignLog>;
  upsertOneEmailCampaignLog?: Maybe<EmailCampaignLog>;
  deleteManyEmailCampaignLog?: Maybe<BatchPayload>;
  updateManyEmailCampaignLog?: Maybe<BatchPayload>;
  createOneEmailCampaignUnsubscribe: EmailCampaignUnsubscribe;
  updateOneEmailCampaignUnsubscribe: EmailCampaignUnsubscribe;
  deleteOneEmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribe>;
  upsertOneEmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribe>;
  deleteManyEmailCampaignUnsubscribe?: Maybe<BatchPayload>;
  updateManyEmailCampaignUnsubscribe?: Maybe<BatchPayload>;
  createOneCanonicalSpecies: CanonicalSpecies;
  updateOneCanonicalSpecies: CanonicalSpecies;
  deleteOneCanonicalSpecies?: Maybe<CanonicalSpecies>;
  upsertOneCanonicalSpecies?: Maybe<CanonicalSpecies>;
  deleteManyCanonicalSpecies?: Maybe<BatchPayload>;
  updateManyCanonicalSpecies?: Maybe<BatchPayload>;
  createOneCanonicalBreed: CanonicalBreed;
  updateOneCanonicalBreed: CanonicalBreed;
  deleteOneCanonicalBreed?: Maybe<CanonicalBreed>;
  upsertOneCanonicalBreed?: Maybe<CanonicalBreed>;
  deleteManyCanonicalBreed?: Maybe<BatchPayload>;
  updateManyCanonicalBreed?: Maybe<BatchPayload>;
  createOneCanonicalCanineBreedProfile: CanonicalCanineBreedProfile;
  updateOneCanonicalCanineBreedProfile: CanonicalCanineBreedProfile;
  deleteOneCanonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfile>;
  upsertOneCanonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfile>;
  deleteManyCanonicalCanineBreedProfile?: Maybe<BatchPayload>;
  updateManyCanonicalCanineBreedProfile?: Maybe<BatchPayload>;
  createOneCanonicalService: CanonicalService;
  updateOneCanonicalService: CanonicalService;
  deleteOneCanonicalService?: Maybe<CanonicalService>;
  upsertOneCanonicalService?: Maybe<CanonicalService>;
  deleteManyCanonicalService?: Maybe<BatchPayload>;
  updateManyCanonicalService?: Maybe<BatchPayload>;
  createOneChemicalCompound: ChemicalCompound;
  updateOneChemicalCompound: ChemicalCompound;
  deleteOneChemicalCompound?: Maybe<ChemicalCompound>;
  upsertOneChemicalCompound?: Maybe<ChemicalCompound>;
  deleteManyChemicalCompound?: Maybe<BatchPayload>;
  updateManyChemicalCompound?: Maybe<BatchPayload>;
  createOneActiveIngredient: ActiveIngredient;
  updateOneActiveIngredient: ActiveIngredient;
  deleteOneActiveIngredient?: Maybe<ActiveIngredient>;
  upsertOneActiveIngredient?: Maybe<ActiveIngredient>;
  deleteManyActiveIngredient?: Maybe<BatchPayload>;
  updateManyActiveIngredient?: Maybe<BatchPayload>;
  createOneProduct: Product;
  updateOneProduct: Product;
  deleteOneProduct?: Maybe<Product>;
  upsertOneProduct?: Maybe<Product>;
  deleteManyProduct?: Maybe<BatchPayload>;
  updateManyProduct?: Maybe<BatchPayload>;
  createOneInformPartner: InformPartner;
  updateOneInformPartner: InformPartner;
  deleteOneInformPartner?: Maybe<InformPartner>;
  upsertOneInformPartner?: Maybe<InformPartner>;
  deleteManyInformPartner?: Maybe<BatchPayload>;
  updateManyInformPartner?: Maybe<BatchPayload>;
  createOneInformPartnerIntegration: InformPartnerIntegration;
  updateOneInformPartnerIntegration: InformPartnerIntegration;
  deleteOneInformPartnerIntegration?: Maybe<InformPartnerIntegration>;
  upsertOneInformPartnerIntegration?: Maybe<InformPartnerIntegration>;
  deleteManyInformPartnerIntegration?: Maybe<BatchPayload>;
  updateManyInformPartnerIntegration?: Maybe<BatchPayload>;
  createOneInformPartnerIntegrationModel: InformPartnerIntegrationModel;
  updateOneInformPartnerIntegrationModel: InformPartnerIntegrationModel;
  deleteOneInformPartnerIntegrationModel?: Maybe<InformPartnerIntegrationModel>;
  upsertOneInformPartnerIntegrationModel?: Maybe<InformPartnerIntegrationModel>;
  deleteManyInformPartnerIntegrationModel?: Maybe<BatchPayload>;
  updateManyInformPartnerIntegrationModel?: Maybe<BatchPayload>;
  createOneInformPartnerUser: InformPartnerUser;
  updateOneInformPartnerUser: InformPartnerUser;
  deleteOneInformPartnerUser?: Maybe<InformPartnerUser>;
  upsertOneInformPartnerUser?: Maybe<InformPartnerUser>;
  deleteManyInformPartnerUser?: Maybe<BatchPayload>;
  updateManyInformPartnerUser?: Maybe<BatchPayload>;
  createOneInformPartnerUserRole: InformPartnerUserRole;
  updateOneInformPartnerUserRole: InformPartnerUserRole;
  deleteOneInformPartnerUserRole?: Maybe<InformPartnerUserRole>;
  upsertOneInformPartnerUserRole?: Maybe<InformPartnerUserRole>;
  deleteManyInformPartnerUserRole?: Maybe<BatchPayload>;
  updateManyInformPartnerUserRole?: Maybe<BatchPayload>;
  createOneBoehringerIngelheimTerritory: BoehringerIngelheimTerritory;
  updateOneBoehringerIngelheimTerritory: BoehringerIngelheimTerritory;
  deleteOneBoehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritory>;
  upsertOneBoehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritory>;
  deleteManyBoehringerIngelheimTerritory?: Maybe<BatchPayload>;
  updateManyBoehringerIngelheimTerritory?: Maybe<BatchPayload>;
  createOneBoehringerIngelheimCustomerAlignment: BoehringerIngelheimCustomerAlignment;
  updateOneBoehringerIngelheimCustomerAlignment: BoehringerIngelheimCustomerAlignment;
  deleteOneBoehringerIngelheimCustomerAlignment?: Maybe<BoehringerIngelheimCustomerAlignment>;
  upsertOneBoehringerIngelheimCustomerAlignment?: Maybe<BoehringerIngelheimCustomerAlignment>;
  deleteManyBoehringerIngelheimCustomerAlignment?: Maybe<BatchPayload>;
  updateManyBoehringerIngelheimCustomerAlignment?: Maybe<BatchPayload>;
  createOneInformPartnerCustomer: InformPartnerCustomer;
  updateOneInformPartnerCustomer: InformPartnerCustomer;
  deleteOneInformPartnerCustomer?: Maybe<InformPartnerCustomer>;
  upsertOneInformPartnerCustomer?: Maybe<InformPartnerCustomer>;
  deleteManyInformPartnerCustomer?: Maybe<BatchPayload>;
  updateManyInformPartnerCustomer?: Maybe<BatchPayload>;
  createOneInformPartnerProgram: InformPartnerProgram;
  updateOneInformPartnerProgram: InformPartnerProgram;
  deleteOneInformPartnerProgram?: Maybe<InformPartnerProgram>;
  upsertOneInformPartnerProgram?: Maybe<InformPartnerProgram>;
  deleteManyInformPartnerProgram?: Maybe<BatchPayload>;
  updateManyInformPartnerProgram?: Maybe<BatchPayload>;
  createOneInformCampaignGroup: InformCampaignGroup;
  updateOneInformCampaignGroup: InformCampaignGroup;
  deleteOneInformCampaignGroup?: Maybe<InformCampaignGroup>;
  upsertOneInformCampaignGroup?: Maybe<InformCampaignGroup>;
  deleteManyInformCampaignGroup?: Maybe<BatchPayload>;
  updateManyInformCampaignGroup?: Maybe<BatchPayload>;
  createOneInformAudienceDefinition: InformAudienceDefinition;
  updateOneInformAudienceDefinition: InformAudienceDefinition;
  deleteOneInformAudienceDefinition?: Maybe<InformAudienceDefinition>;
  upsertOneInformAudienceDefinition?: Maybe<InformAudienceDefinition>;
  deleteManyInformAudienceDefinition?: Maybe<BatchPayload>;
  updateManyInformAudienceDefinition?: Maybe<BatchPayload>;
  createOneInformCampaign: InformCampaign;
  updateOneInformCampaign: InformCampaign;
  deleteOneInformCampaign?: Maybe<InformCampaign>;
  upsertOneInformCampaign?: Maybe<InformCampaign>;
  deleteManyInformCampaign?: Maybe<BatchPayload>;
  updateManyInformCampaign?: Maybe<BatchPayload>;
  createOneInformScheduledCampaign: InformScheduledCampaign;
  updateOneInformScheduledCampaign: InformScheduledCampaign;
  deleteOneInformScheduledCampaign?: Maybe<InformScheduledCampaign>;
  upsertOneInformScheduledCampaign?: Maybe<InformScheduledCampaign>;
  deleteManyInformScheduledCampaign?: Maybe<BatchPayload>;
  updateManyInformScheduledCampaign?: Maybe<BatchPayload>;
  createOneInformAutomationCampaign: InformAutomationCampaign;
  updateOneInformAutomationCampaign: InformAutomationCampaign;
  deleteOneInformAutomationCampaign?: Maybe<InformAutomationCampaign>;
  upsertOneInformAutomationCampaign?: Maybe<InformAutomationCampaign>;
  deleteManyInformAutomationCampaign?: Maybe<BatchPayload>;
  createOneInformEmailTemplate: InformEmailTemplate;
  updateOneInformEmailTemplate: InformEmailTemplate;
  deleteOneInformEmailTemplate?: Maybe<InformEmailTemplate>;
  upsertOneInformEmailTemplate?: Maybe<InformEmailTemplate>;
  deleteManyInformEmailTemplate?: Maybe<BatchPayload>;
  updateManyInformEmailTemplate?: Maybe<BatchPayload>;
  createOneInformCampaignExecution: InformCampaignExecution;
  updateOneInformCampaignExecution: InformCampaignExecution;
  deleteOneInformCampaignExecution?: Maybe<InformCampaignExecution>;
  upsertOneInformCampaignExecution?: Maybe<InformCampaignExecution>;
  deleteManyInformCampaignExecution?: Maybe<BatchPayload>;
  updateManyInformCampaignExecution?: Maybe<BatchPayload>;
  createOneInformPartnerProgramEnrollment: InformPartnerProgramEnrollment;
  updateOneInformPartnerProgramEnrollment: InformPartnerProgramEnrollment;
  deleteOneInformPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollment>;
  upsertOneInformPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollment>;
  deleteManyInformPartnerProgramEnrollment?: Maybe<BatchPayload>;
  updateManyInformPartnerProgramEnrollment?: Maybe<BatchPayload>;
  createOneInformCampaignEnrollment: InformCampaignEnrollment;
  updateOneInformCampaignEnrollment: InformCampaignEnrollment;
  deleteOneInformCampaignEnrollment?: Maybe<InformCampaignEnrollment>;
  upsertOneInformCampaignEnrollment?: Maybe<InformCampaignEnrollment>;
  deleteManyInformCampaignEnrollment?: Maybe<BatchPayload>;
  updateManyInformCampaignEnrollment?: Maybe<BatchPayload>;
  createOneInformCampaignEnrollmentCanonicalService: InformCampaignEnrollmentCanonicalService;
  updateOneInformCampaignEnrollmentCanonicalService: InformCampaignEnrollmentCanonicalService;
  deleteOneInformCampaignEnrollmentCanonicalService?: Maybe<InformCampaignEnrollmentCanonicalService>;
  upsertOneInformCampaignEnrollmentCanonicalService?: Maybe<InformCampaignEnrollmentCanonicalService>;
  deleteManyInformCampaignEnrollmentCanonicalService?: Maybe<BatchPayload>;
  updateManyInformCampaignEnrollmentCanonicalService?: Maybe<BatchPayload>;
  createOneInformPartnerCustomerPharmacyProvider: InformPartnerCustomerPharmacyProvider;
  updateOneInformPartnerCustomerPharmacyProvider: InformPartnerCustomerPharmacyProvider;
  deleteOneInformPartnerCustomerPharmacyProvider?: Maybe<InformPartnerCustomerPharmacyProvider>;
  upsertOneInformPartnerCustomerPharmacyProvider?: Maybe<InformPartnerCustomerPharmacyProvider>;
  deleteManyInformPartnerCustomerPharmacyProvider?: Maybe<BatchPayload>;
  updateManyInformPartnerCustomerPharmacyProvider?: Maybe<BatchPayload>;
  createOneInformEntityHistory: InformEntityHistory;
  updateOneInformEntityHistory: InformEntityHistory;
  deleteOneInformEntityHistory?: Maybe<InformEntityHistory>;
  upsertOneInformEntityHistory?: Maybe<InformEntityHistory>;
  deleteManyInformEntityHistory?: Maybe<BatchPayload>;
  updateManyInformEntityHistory?: Maybe<BatchPayload>;
  createOneClinicBlockedPhoneNumber: ClinicBlockedPhoneNumber;
  updateOneClinicBlockedPhoneNumber: ClinicBlockedPhoneNumber;
  deleteOneClinicBlockedPhoneNumber?: Maybe<ClinicBlockedPhoneNumber>;
  upsertOneClinicBlockedPhoneNumber?: Maybe<ClinicBlockedPhoneNumber>;
  deleteManyClinicBlockedPhoneNumber?: Maybe<BatchPayload>;
  updateManyClinicBlockedPhoneNumber?: Maybe<BatchPayload>;
  createOneChannelMessageAutoResponse: ChannelMessageAutoResponse;
  updateOneChannelMessageAutoResponse: ChannelMessageAutoResponse;
  deleteOneChannelMessageAutoResponse?: Maybe<ChannelMessageAutoResponse>;
  upsertOneChannelMessageAutoResponse?: Maybe<ChannelMessageAutoResponse>;
  deleteManyChannelMessageAutoResponse?: Maybe<BatchPayload>;
  updateManyChannelMessageAutoResponse?: Maybe<BatchPayload>;
  createOneCareSubscriptionTrueUpLogEntry: CareSubscriptionTrueUpLogEntry;
  updateOneCareSubscriptionTrueUpLogEntry: CareSubscriptionTrueUpLogEntry;
  deleteOneCareSubscriptionTrueUpLogEntry?: Maybe<CareSubscriptionTrueUpLogEntry>;
  upsertOneCareSubscriptionTrueUpLogEntry?: Maybe<CareSubscriptionTrueUpLogEntry>;
  deleteManyCareSubscriptionTrueUpLogEntry?: Maybe<BatchPayload>;
  updateManyCareSubscriptionTrueUpLogEntry?: Maybe<BatchPayload>;
  createOneCareSubscriptionTrueUp: CareSubscriptionTrueUp;
  updateOneCareSubscriptionTrueUp: CareSubscriptionTrueUp;
  deleteOneCareSubscriptionTrueUp?: Maybe<CareSubscriptionTrueUp>;
  upsertOneCareSubscriptionTrueUp?: Maybe<CareSubscriptionTrueUp>;
  deleteManyCareSubscriptionTrueUp?: Maybe<BatchPayload>;
  updateManyCareSubscriptionTrueUp?: Maybe<BatchPayload>;
  createOneHillsToHomeAPILog: HillsToHomeApiLog;
  updateOneHillsToHomeAPILog: HillsToHomeApiLog;
  deleteOneHillsToHomeAPILog?: Maybe<HillsToHomeApiLog>;
  upsertOneHillsToHomeAPILog?: Maybe<HillsToHomeApiLog>;
  deleteManyHillsToHomeAPILog?: Maybe<BatchPayload>;
  updateManyHillsToHomeAPILog?: Maybe<BatchPayload>;
  createOneBoardFilterColumn: BoardFilterColumn;
  updateOneBoardFilterColumn: BoardFilterColumn;
  deleteOneBoardFilterColumn?: Maybe<BoardFilterColumn>;
  upsertOneBoardFilterColumn?: Maybe<BoardFilterColumn>;
  deleteManyBoardFilterColumn?: Maybe<BatchPayload>;
  updateManyBoardFilterColumn?: Maybe<BatchPayload>;
  createOneBoardFilterSpecificDateRange: BoardFilterSpecificDateRange;
  updateOneBoardFilterSpecificDateRange: BoardFilterSpecificDateRange;
  deleteOneBoardFilterSpecificDateRange?: Maybe<BoardFilterSpecificDateRange>;
  upsertOneBoardFilterSpecificDateRange?: Maybe<BoardFilterSpecificDateRange>;
  deleteManyBoardFilterSpecificDateRange?: Maybe<BatchPayload>;
  updateManyBoardFilterSpecificDateRange?: Maybe<BatchPayload>;
  createOneUserBoardFilter: UserBoardFilter;
  updateOneUserBoardFilter: UserBoardFilter;
  deleteOneUserBoardFilter?: Maybe<UserBoardFilter>;
  upsertOneUserBoardFilter?: Maybe<UserBoardFilter>;
  deleteManyUserBoardFilter?: Maybe<BatchPayload>;
  updateManyUserBoardFilter?: Maybe<BatchPayload>;
  createOneSegment: Segment;
  updateOneSegment: Segment;
  deleteOneSegment?: Maybe<Segment>;
  upsertOneSegment?: Maybe<Segment>;
  deleteManySegment?: Maybe<BatchPayload>;
  updateManySegment?: Maybe<BatchPayload>;
  createOneNonPrismaSchemaScriptRan: NonPrismaSchemaScriptRan;
  updateOneNonPrismaSchemaScriptRan: NonPrismaSchemaScriptRan;
  deleteOneNonPrismaSchemaScriptRan?: Maybe<NonPrismaSchemaScriptRan>;
  upsertOneNonPrismaSchemaScriptRan?: Maybe<NonPrismaSchemaScriptRan>;
  deleteManyNonPrismaSchemaScriptRan?: Maybe<BatchPayload>;
  updateManyNonPrismaSchemaScriptRan?: Maybe<BatchPayload>;
  createOneVetsourceInstallation: VetsourceInstallation;
  updateOneVetsourceInstallation: VetsourceInstallation;
  deleteOneVetsourceInstallation?: Maybe<VetsourceInstallation>;
  upsertOneVetsourceInstallation?: Maybe<VetsourceInstallation>;
  deleteManyVetsourceInstallation?: Maybe<BatchPayload>;
  updateManyVetsourceInstallation?: Maybe<BatchPayload>;
  createOneEmailCampaignCustomList: EmailCampaignCustomList;
  updateOneEmailCampaignCustomList: EmailCampaignCustomList;
  deleteOneEmailCampaignCustomList?: Maybe<EmailCampaignCustomList>;
  upsertOneEmailCampaignCustomList?: Maybe<EmailCampaignCustomList>;
  deleteManyEmailCampaignCustomList?: Maybe<BatchPayload>;
  updateManyEmailCampaignCustomList?: Maybe<BatchPayload>;
  createOneEmailCampaignCustomListRecipient: EmailCampaignCustomListRecipient;
  updateOneEmailCampaignCustomListRecipient: EmailCampaignCustomListRecipient;
  deleteOneEmailCampaignCustomListRecipient?: Maybe<EmailCampaignCustomListRecipient>;
  upsertOneEmailCampaignCustomListRecipient?: Maybe<EmailCampaignCustomListRecipient>;
  deleteManyEmailCampaignCustomListRecipient?: Maybe<BatchPayload>;
  updateManyEmailCampaignCustomListRecipient?: Maybe<BatchPayload>;
  createOneVetsourceRevenueCategory: VetsourceRevenueCategory;
  updateOneVetsourceRevenueCategory: VetsourceRevenueCategory;
  deleteOneVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategory>;
  upsertOneVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategory>;
  deleteManyVetsourceRevenueCategory?: Maybe<BatchPayload>;
  updateManyVetsourceRevenueCategory?: Maybe<BatchPayload>;
  createOneVetsourceCodeTag: VetsourceCodeTag;
  updateOneVetsourceCodeTag: VetsourceCodeTag;
  deleteOneVetsourceCodeTag?: Maybe<VetsourceCodeTag>;
  upsertOneVetsourceCodeTag?: Maybe<VetsourceCodeTag>;
  deleteManyVetsourceCodeTag?: Maybe<BatchPayload>;
  updateManyVetsourceCodeTag?: Maybe<BatchPayload>;
  createOneInformControlGroupParticipant: InformControlGroupParticipant;
  updateOneInformControlGroupParticipant: InformControlGroupParticipant;
  deleteOneInformControlGroupParticipant?: Maybe<InformControlGroupParticipant>;
  upsertOneInformControlGroupParticipant?: Maybe<InformControlGroupParticipant>;
  deleteManyInformControlGroupParticipant?: Maybe<BatchPayload>;
  updateManyInformControlGroupParticipant?: Maybe<BatchPayload>;
  configureEmailCampaignsForClinic?: Maybe<Scalars['String']>;
  addEmailCampaignsCareUrlField?: Maybe<Scalars['Boolean']>;
  triggerEmailCampaignsDataSync?: Maybe<Scalars['Boolean']>;
  confirmAppointment2: Appointment;
  updateClinicAppointmentTypeSettings?: Maybe<AppointmentType>;
  updateManyAppointmentTypeSortOrder: AppointmentTypeWithOrderResponse;
  resetAutomationRun?: Maybe<AutomationRun>;
  getSignedUploadUrl: GetSignedUploadUrlOutput;
  getSignedDownloadUrl?: Maybe<Scalars['String']>;
  encryptAndUploadData: EncryptedUploadResponse;
  createOneCallRecording?: Maybe<CallRecording>;
  updateOneCallRecording?: Maybe<CallRecording>;
  /** Deletes a CallRecording and its associated TwilioCallRecording */
  deleteOneCallRecording?: Maybe<CallRecording>;
  toggleTwilioVideoCallRecordingStatus?: Maybe<CallRecording>;
  requestCareLoginLink: AuthLinkRequestResult;
  sendDirectedCareLoginLink: DirectedAuthLinkResult;
  getDirectedCareLoginLink: GetDirectedAuthLinkResult;
  redeemOneTimeCode: RedeemOneTimeCodeResult;
  requestOneTimeCode: RequestOneTimeCodeResult;
  sendCareSignUpEmail?: Maybe<SendCareSignUpEmailOutput>;
  updateCareSubscription?: Maybe<UpdateCareSubscriptionResult>;
  cancelCareSubscription?: Maybe<CancelCareSubscriptionResult>;
  changeCareSubscriptionPaymentMethod?: Maybe<ChangeCareSubscriptionPaymentMethodOutput>;
  /** @deprecated use createCarePaymentMethodSetup */
  createPaymentMethodSetup: CreatePaymentMethodSetupInputResult;
  createCarePaymentMethodSetup: CreatePaymentMethodSetupInputResult;
  createCareEnrollmentsAndSubscriptions: CreateCareEnrollmentAndSubscriptionResult;
  sendEnrollmentEmail?: Maybe<SendEnrollmentEmailOutput>;
  transferCareSubscription?: Maybe<TransferSubscriptionOutput>;
  performCarePlanEnrollmentUpgrade?: Maybe<PerformCarePlanEnrollmentUpgradeOutput>;
  formSubmissionExportToPims?: Maybe<FormSubmissionExportResponse>;
  channelExportToPims?: Maybe<GenericResponse>;
  channelBulkExportToPims?: Maybe<GenericResponse>;
  addChannelTag?: Maybe<Channel>;
  removeChannelTag?: Maybe<Channel>;
  createTeamChannel2?: Maybe<Channel>;
  updateTeamChannel2?: Maybe<Channel>;
  getOrCreateActiveClientChannel?: Maybe<Channel>;
  getOrCreateActiveChannel?: Maybe<Channel>;
  createChannelFilterSelection: ChannelFilterSelection;
  updateChannelFilterSelection: ChannelFilterSelection;
  deleteChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  addChannelMember2?: Maybe<ChannelMember>;
  removeChannelMember2?: Maybe<ChannelMember>;
  createChannelMessage2: ChannelMessage;
  createSystemChannelMessage2?: Maybe<ChannelMessage>;
  clearUnreadMessages2: GenericResponse;
  updateManyChannelStatusSortOrder: ChannelStatusWithOrderResponse;
  cancelClinicSubscription?: Maybe<GenericResponse>;
  updateClinicPetWithoutPims?: Maybe<ClinicPet>;
  ensurePetsPromoted?: Maybe<EnsurePetsPromotedResult>;
  sendClinicPetParentAuthLink?: Maybe<ClinicPetParentAuthLinkResponse>;
  regeneratePetParentClinicListWithTokens?: Maybe<Scalars['Json']>;
  redeemClinicPetParentAuthCode?: Maybe<Scalars['Json']>;
  setClinicPetParentPrivacy?: Maybe<PrivacyResponse>;
  updateOneClinicPetParentAndEnqueuePhoneNumberLookup: ClinicPetParentWithJobChainsResponse;
  sendClinicPetParentAuthCode?: Maybe<SendClinicPetParentAuthCodeResponse>;
  claimClinicPetParentAuthCode?: Maybe<ClaimClinicPetParentAuthCodeResponse>;
  orderPhoneNumber?: Maybe<ClinicPhoneNumber>;
  requestBitwerxIntegration?: Maybe<ClinicPimsIntegration>;
  updateClinicPostcardSetting: ClinicPostcardSetting;
  /** Merge the information from the deleteId petparent into the keepId pet parent and soft delete deleteId */
  mergePetParent: MergePetParentResult;
  updateClinicPetParentContactInfoFromPims: ClinicPetParent;
  updateClinicSettingTransactionColumn: ClinicSetting;
  verifyClinicWidgetRequest2?: Maybe<ClinicWidgetRequest>;
  createClinicWidgetRequest2: ClinicWidgetRequest;
  sendWidgetCodeToDeveloper?: Maybe<GenericResponse>;
  sendConsultationReceipt?: Maybe<Scalars['Json']>;
  createDirectBookingAppointment?: Maybe<DirectBookingAppointmentResponse>;
  createOneProviderAppointmentTypeRoomMapByName: DirectBookingCreateOneProviderAppointmentTypeRoomMapByNameResponse;
  sendEmailCampaignNow?: Maybe<SentEmailCampaign>;
  uploadEmailCampaignCustomList?: Maybe<EmailCampaignCustomList>;
  deleteEmailCampaignCustomList?: Maybe<EmailCampaignCustomList>;
  duplicateEmailCampaign?: Maybe<EmailCampaign>;
  createEmailTemplateVersion: EmailTemplateVersion;
  saveEmailTemplateVersion: EmailTemplateVersion;
  submitForm2?: Maybe<SubmitFormResponse>;
  submitPetParentRequest?: Maybe<SubmitFormResponse>;
  postCareInvoiceAccountCreditToPims: WritebackCareInvoiceAccountCreditToPimsResponse;
  findOrCreateLoyaltyAccountForClinicPetParent: LoyaltyAccount;
  submitLoyaltyPointGrantToAccountByUser: OperationResult;
  deactivateLoyaltyAccount: LoyaltyAccount;
  triggerLoyaltyInvoiceProcessing: LoyaltyInvoiceTriggerOutput;
  createLoyaltyProgram: LoyaltyProgram;
  findOrCreateLoyaltyProgram: LoyaltyProgram;
  addClinicToLoyaltyProgram: OperationResult;
  removeClinicFromLoyaltyProgram: OperationResult;
  archiveLoyaltyProgram: OperationResult;
  requestLoyaltyReward: LoyaltyRewardRedemption;
  cancelLoyaltyRewardRedemption: LoyaltyRewardRedemption;
  fulfillLoyaltyRewardRedemption: LoyaltyRewardRedemption;
  stripeCreateConnectedAccount?: Maybe<StripeCreateAccountOutput>;
  stripeConnectConnectedAccount?: Maybe<StripeAccountOutput>;
  syncPimsIntegrationModel: ClinicPimsIntegrationCapability;
  syncManyPimsIntegrationModel?: Maybe<Array<ClinicPimsIntegrationCapability>>;
  syncClinic?: Maybe<Scalars['Json']>;
  submitPrescriptionRefillRequest: SubmitPrescriptionRefillRequestResponse;
  updateAppointmentTypeProviderRoomMaps: UpdateAppointmentTypeProviderRoomMapsResponse;
  buildSignedLookerUrl: Scalars['String'];
  updateManyServicesAndTimings: Array<Service>;
  updateManyTimingsDeliveryOptions: Array<Service>;
  resendReceiptEmail: ResendReceiptEmailOutput;
  retryPaymentWriteback?: Maybe<RetryPaymentWritebackOutput>;
  setPaymentIntentClinicPetParent2?: Maybe<StripePaymentIntent>;
  createStripePaymentIntent2?: Maybe<StripePaymentIntent>;
  cancelStripePaymentIntent2?: Maybe<StripePaymentIntent>;
  refundStripePaymentIntent2?: Maybe<StripePaymentIntent>;
  createTerminalAppPaymentIntent?: Maybe<TerminalAppPaymentResponse>;
  updateStripePayment?: Maybe<StripePaymentOutput>;
  updateTerminalAppPaymentIntent?: Maybe<StripePaymentIntent>;
  clearTerminalDisplay?: Maybe<ClearTerminalDisplayResponse>;
  processTerminalPayment?: Maybe<ProcessTerminalPaymentResponse>;
  sendPaymentToTerminal?: Maybe<SendPaymentToTerminalResponse>;
  connectStripeTerminal?: Maybe<StripeTerminal>;
  generateTerminalAuthToken?: Maybe<GenerateTerminalAuthTokenResponse>;
  generateTerminalSetupPin?: Maybe<GenerateTerminalSetupPinResponse>;
  createStripeTerminalLocation?: Maybe<StripeTerminalLocation>;
  initSurveySetting: SurveySetting;
  trupanionIssueCertificate?: Maybe<TrupanionIssueCertificateResponse>;
  sendUiRefreshEvent?: Maybe<UiRefresh>;
  /** @deprecated Use upsertUserToClinic instead */
  addUserToClinic: User;
  upsertUserToClinic: User;
  removeUserFromClinic: User;
  resendUserInvitation: User;
  acceptUserInvitation2?: Maybe<AcceptUserInvitationResponse>;
  generateCustomToken?: Maybe<GenerateCustomTokenResponse>;
  customUpsertOneUserBoardFilter?: Maybe<CustomUpsertOneUserBoardFilterResponse>;
  generateWidgetAuthenticationRequest?: Maybe<GenerateWidgetAuthenticationRequestResponse>;
  verifyWidgetAuthenticationRequest?: Maybe<VerifyWidgetAuthenticationRequestResponse>;
  createClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  verifyClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  copyOneWorkflowEventSetting: WorkflowEventSetting;
  createWorkflowEventSettingAndConditions: WorkflowEventSetting;
  bulkPublishWorkflowEventSettings: BulkPublishWorkflowEventSettingsResponse;
  generateTinyUrlAndToken?: Maybe<Scalars['Json']>;
  createTwilioToken: CreateTwilioTokenResponse;
  transferTwilioNumber?: Maybe<GenericResponse>;
  twilioVerifyCallerId?: Maybe<TwilioCallerIdVerification>;
  createImpersonation: TokenOutput;
  triggerWorkflowEvent?: Maybe<GenericResponse>;
  triggerMessageEvent?: Maybe<MessageEventResponse>;
};


export type MutationCreateOneAppointmentArgs = {
  data: AppointmentCreateInput;
};


export type MutationUpdateOneAppointmentArgs = {
  data: AppointmentUpdateInput;
  where: AppointmentWhereUniqueInput;
};


export type MutationDeleteOneAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};


export type MutationUpsertOneAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateInput;
  update: AppointmentUpdateInput;
};


export type MutationDeleteManyAppointmentArgs = {
  where?: Maybe<AppointmentWhereInput>;
};


export type MutationUpdateManyAppointmentArgs = {
  data: AppointmentUpdateManyMutationInput;
  where?: Maybe<AppointmentWhereInput>;
};


export type MutationCreateOneAppointmentAvailabilityArgs = {
  data: AppointmentAvailabilityCreateInput;
};


export type MutationUpdateOneAppointmentAvailabilityArgs = {
  data: AppointmentAvailabilityUpdateInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};


export type MutationDeleteOneAppointmentAvailabilityArgs = {
  where: AppointmentAvailabilityWhereUniqueInput;
};


export type MutationUpsertOneAppointmentAvailabilityArgs = {
  where: AppointmentAvailabilityWhereUniqueInput;
  create: AppointmentAvailabilityCreateInput;
  update: AppointmentAvailabilityUpdateInput;
};


export type MutationDeleteManyAppointmentAvailabilityArgs = {
  where?: Maybe<AppointmentAvailabilityWhereInput>;
};


export type MutationUpdateManyAppointmentAvailabilityArgs = {
  data: AppointmentAvailabilityUpdateManyMutationInput;
  where?: Maybe<AppointmentAvailabilityWhereInput>;
};


export type MutationCreateOneAppointmentTypeArgs = {
  data: AppointmentTypeCreateInput;
};


export type MutationUpdateOneAppointmentTypeArgs = {
  data: AppointmentTypeUpdateInput;
  where: AppointmentTypeWhereUniqueInput;
};


export type MutationDeleteOneAppointmentTypeArgs = {
  where: AppointmentTypeWhereUniqueInput;
};


export type MutationUpsertOneAppointmentTypeArgs = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeCreateInput;
  update: AppointmentTypeUpdateInput;
};


export type MutationDeleteManyAppointmentTypeArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
};


export type MutationUpdateManyAppointmentTypeArgs = {
  data: AppointmentTypeUpdateManyMutationInput;
  where?: Maybe<AppointmentTypeWhereInput>;
};


export type MutationCreateOneBitwerxIntegrationArgs = {
  data: BitwerxIntegrationCreateInput;
};


export type MutationUpdateOneBitwerxIntegrationArgs = {
  data: BitwerxIntegrationUpdateInput;
  where: BitwerxIntegrationWhereUniqueInput;
};


export type MutationDeleteOneBitwerxIntegrationArgs = {
  where: BitwerxIntegrationWhereUniqueInput;
};


export type MutationUpsertOneBitwerxIntegrationArgs = {
  where: BitwerxIntegrationWhereUniqueInput;
  create: BitwerxIntegrationCreateInput;
  update: BitwerxIntegrationUpdateInput;
};


export type MutationDeleteManyBitwerxIntegrationArgs = {
  where?: Maybe<BitwerxIntegrationWhereInput>;
};


export type MutationUpdateManyBitwerxIntegrationArgs = {
  data: BitwerxIntegrationUpdateManyMutationInput;
  where?: Maybe<BitwerxIntegrationWhereInput>;
};


export type MutationCreateOnePpcIntegrationArgs = {
  data: PpcIntegrationCreateInput;
};


export type MutationUpdateOnePpcIntegrationArgs = {
  data: PpcIntegrationUpdateInput;
  where: PpcIntegrationWhereUniqueInput;
};


export type MutationDeleteOnePpcIntegrationArgs = {
  where: PpcIntegrationWhereUniqueInput;
};


export type MutationUpsertOnePpcIntegrationArgs = {
  where: PpcIntegrationWhereUniqueInput;
  create: PpcIntegrationCreateInput;
  update: PpcIntegrationUpdateInput;
};


export type MutationDeleteManyPpcIntegrationArgs = {
  where?: Maybe<PpcIntegrationWhereInput>;
};


export type MutationUpdateManyPpcIntegrationArgs = {
  data: PpcIntegrationUpdateManyMutationInput;
  where?: Maybe<PpcIntegrationWhereInput>;
};


export type MutationCreateOneChannelAutomationStatusArgs = {
  data: ChannelAutomationStatusCreateInput;
};


export type MutationUpdateOneChannelAutomationStatusArgs = {
  data: ChannelAutomationStatusUpdateInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};


export type MutationDeleteOneChannelAutomationStatusArgs = {
  where: ChannelAutomationStatusWhereUniqueInput;
};


export type MutationUpsertOneChannelAutomationStatusArgs = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusCreateInput;
  update: ChannelAutomationStatusUpdateInput;
};


export type MutationDeleteManyChannelAutomationStatusArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type MutationUpdateManyChannelAutomationStatusArgs = {
  data: ChannelAutomationStatusUpdateManyMutationInput;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type MutationCreateOneChannelCreationSourceArgs = {
  data: ChannelCreationSourceCreateInput;
};


export type MutationUpdateOneChannelCreationSourceArgs = {
  data: ChannelCreationSourceUpdateInput;
  where: ChannelCreationSourceWhereUniqueInput;
};


export type MutationDeleteOneChannelCreationSourceArgs = {
  where: ChannelCreationSourceWhereUniqueInput;
};


export type MutationUpsertOneChannelCreationSourceArgs = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceCreateInput;
  update: ChannelCreationSourceUpdateInput;
};


export type MutationDeleteManyChannelCreationSourceArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
};


export type MutationUpdateManyChannelCreationSourceArgs = {
  data: ChannelCreationSourceUpdateManyMutationInput;
  where?: Maybe<ChannelCreationSourceWhereInput>;
};


export type MutationCreateOneChannelArgs = {
  data: ChannelCreateInput;
};


export type MutationUpdateOneChannelArgs = {
  data: ChannelUpdateInput;
  where: ChannelWhereUniqueInput;
};


export type MutationDeleteOneChannelArgs = {
  where: ChannelWhereUniqueInput;
};


export type MutationUpsertOneChannelArgs = {
  where: ChannelWhereUniqueInput;
  create: ChannelCreateInput;
  update: ChannelUpdateInput;
};


export type MutationDeleteManyChannelArgs = {
  where?: Maybe<ChannelWhereInput>;
};


export type MutationUpdateManyChannelArgs = {
  data: ChannelUpdateManyMutationInput;
  where?: Maybe<ChannelWhereInput>;
};


export type MutationCreateOneChannelPinArgs = {
  data: ChannelPinCreateInput;
};


export type MutationUpdateOneChannelPinArgs = {
  data: ChannelPinUpdateInput;
  where: ChannelPinWhereUniqueInput;
};


export type MutationDeleteOneChannelPinArgs = {
  where: ChannelPinWhereUniqueInput;
};


export type MutationUpsertOneChannelPinArgs = {
  where: ChannelPinWhereUniqueInput;
  create: ChannelPinCreateInput;
  update: ChannelPinUpdateInput;
};


export type MutationDeleteManyChannelPinArgs = {
  where?: Maybe<ChannelPinWhereInput>;
};


export type MutationUpdateManyChannelPinArgs = {
  data: ChannelPinUpdateManyMutationInput;
  where?: Maybe<ChannelPinWhereInput>;
};


export type MutationCreateOneChannelMemberArgs = {
  data: ChannelMemberCreateInput;
};


export type MutationUpdateOneChannelMemberArgs = {
  data: ChannelMemberUpdateInput;
  where: ChannelMemberWhereUniqueInput;
};


export type MutationDeleteOneChannelMemberArgs = {
  where: ChannelMemberWhereUniqueInput;
};


export type MutationUpsertOneChannelMemberArgs = {
  where: ChannelMemberWhereUniqueInput;
  create: ChannelMemberCreateInput;
  update: ChannelMemberUpdateInput;
};


export type MutationDeleteManyChannelMemberArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
};


export type MutationUpdateManyChannelMemberArgs = {
  data: ChannelMemberUpdateManyMutationInput;
  where?: Maybe<ChannelMemberWhereInput>;
};


export type MutationCreateOneChannelMessageArgs = {
  data: ChannelMessageCreateInput;
};


export type MutationUpdateOneChannelMessageArgs = {
  data: ChannelMessageUpdateInput;
  where: ChannelMessageWhereUniqueInput;
};


export type MutationDeleteOneChannelMessageArgs = {
  where: ChannelMessageWhereUniqueInput;
};


export type MutationUpsertOneChannelMessageArgs = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageCreateInput;
  update: ChannelMessageUpdateInput;
};


export type MutationDeleteManyChannelMessageArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
};


export type MutationUpdateManyChannelMessageArgs = {
  data: ChannelMessageUpdateManyMutationInput;
  where?: Maybe<ChannelMessageWhereInput>;
};


export type MutationCreateOneChannelMessageReactionArgs = {
  data: ChannelMessageReactionCreateInput;
};


export type MutationUpdateOneChannelMessageReactionArgs = {
  data: ChannelMessageReactionUpdateInput;
  where: ChannelMessageReactionWhereUniqueInput;
};


export type MutationDeleteOneChannelMessageReactionArgs = {
  where: ChannelMessageReactionWhereUniqueInput;
};


export type MutationUpsertOneChannelMessageReactionArgs = {
  where: ChannelMessageReactionWhereUniqueInput;
  create: ChannelMessageReactionCreateInput;
  update: ChannelMessageReactionUpdateInput;
};


export type MutationDeleteManyChannelMessageReactionArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
};


export type MutationUpdateManyChannelMessageReactionArgs = {
  data: ChannelMessageReactionUpdateManyMutationInput;
  where?: Maybe<ChannelMessageReactionWhereInput>;
};


export type MutationCreateOneChannelSelectionArgs = {
  data: ChannelSelectionCreateInput;
};


export type MutationUpdateOneChannelSelectionArgs = {
  data: ChannelSelectionUpdateInput;
  where: ChannelSelectionWhereUniqueInput;
};


export type MutationDeleteOneChannelSelectionArgs = {
  where: ChannelSelectionWhereUniqueInput;
};


export type MutationUpsertOneChannelSelectionArgs = {
  where: ChannelSelectionWhereUniqueInput;
  create: ChannelSelectionCreateInput;
  update: ChannelSelectionUpdateInput;
};


export type MutationDeleteManyChannelSelectionArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
};


export type MutationUpdateManyChannelSelectionArgs = {
  data: ChannelSelectionUpdateManyMutationInput;
  where?: Maybe<ChannelSelectionWhereInput>;
};


export type MutationCreateOneChannelMessageAttachmentArgs = {
  data: ChannelMessageAttachmentCreateInput;
};


export type MutationUpdateOneChannelMessageAttachmentArgs = {
  data: ChannelMessageAttachmentUpdateInput;
  where: ChannelMessageAttachmentWhereUniqueInput;
};


export type MutationDeleteOneChannelMessageAttachmentArgs = {
  where: ChannelMessageAttachmentWhereUniqueInput;
};


export type MutationUpsertOneChannelMessageAttachmentArgs = {
  where: ChannelMessageAttachmentWhereUniqueInput;
  create: ChannelMessageAttachmentCreateInput;
  update: ChannelMessageAttachmentUpdateInput;
};


export type MutationDeleteManyChannelMessageAttachmentArgs = {
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
};


export type MutationUpdateManyChannelMessageAttachmentArgs = {
  data: ChannelMessageAttachmentUpdateManyMutationInput;
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
};


export type MutationCreateOneChannelStatusArgs = {
  data: ChannelStatusCreateInput;
};


export type MutationUpdateOneChannelStatusArgs = {
  data: ChannelStatusUpdateInput;
  where: ChannelStatusWhereUniqueInput;
};


export type MutationDeleteOneChannelStatusArgs = {
  where: ChannelStatusWhereUniqueInput;
};


export type MutationUpsertOneChannelStatusArgs = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusCreateInput;
  update: ChannelStatusUpdateInput;
};


export type MutationDeleteManyChannelStatusArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
};


export type MutationUpdateManyChannelStatusArgs = {
  data: ChannelStatusUpdateManyMutationInput;
  where?: Maybe<ChannelStatusWhereInput>;
};


export type MutationCreateOneChannelStatusChannelAssigneeArgs = {
  data: ChannelStatusChannelAssigneeCreateInput;
};


export type MutationUpdateOneChannelStatusChannelAssigneeArgs = {
  data: ChannelStatusChannelAssigneeUpdateInput;
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};


export type MutationDeleteOneChannelStatusChannelAssigneeArgs = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};


export type MutationUpsertOneChannelStatusChannelAssigneeArgs = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  create: ChannelStatusChannelAssigneeCreateInput;
  update: ChannelStatusChannelAssigneeUpdateInput;
};


export type MutationDeleteManyChannelStatusChannelAssigneeArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};


export type MutationUpdateManyChannelStatusChannelAssigneeArgs = {
  data: ChannelStatusChannelAssigneeUpdateManyMutationInput;
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};


export type MutationCreateOneClinicArgs = {
  data: ClinicCreateInput;
};


export type MutationUpdateOneClinicArgs = {
  data: ClinicUpdateInput;
  where: ClinicWhereUniqueInput;
};


export type MutationDeleteOneClinicArgs = {
  where: ClinicWhereUniqueInput;
};


export type MutationUpsertOneClinicArgs = {
  where: ClinicWhereUniqueInput;
  create: ClinicCreateInput;
  update: ClinicUpdateInput;
};


export type MutationDeleteManyClinicArgs = {
  where?: Maybe<ClinicWhereInput>;
};


export type MutationUpdateManyClinicArgs = {
  data: ClinicUpdateManyMutationInput;
  where?: Maybe<ClinicWhereInput>;
};


export type MutationCreateOneClinicEmployeeArgs = {
  data: ClinicEmployeeCreateInput;
};


export type MutationUpdateOneClinicEmployeeArgs = {
  data: ClinicEmployeeUpdateInput;
  where: ClinicEmployeeWhereUniqueInput;
};


export type MutationDeleteOneClinicEmployeeArgs = {
  where: ClinicEmployeeWhereUniqueInput;
};


export type MutationUpsertOneClinicEmployeeArgs = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeCreateInput;
  update: ClinicEmployeeUpdateInput;
};


export type MutationDeleteManyClinicEmployeeArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
};


export type MutationUpdateManyClinicEmployeeArgs = {
  data: ClinicEmployeeUpdateManyMutationInput;
  where?: Maybe<ClinicEmployeeWhereInput>;
};


export type MutationCreateOneDirectBookingAppointmentTypeSettingArgs = {
  data: DirectBookingAppointmentTypeSettingCreateInput;
};


export type MutationUpdateOneDirectBookingAppointmentTypeSettingArgs = {
  data: DirectBookingAppointmentTypeSettingUpdateInput;
  where: DirectBookingAppointmentTypeSettingWhereUniqueInput;
};


export type MutationDeleteOneDirectBookingAppointmentTypeSettingArgs = {
  where: DirectBookingAppointmentTypeSettingWhereUniqueInput;
};


export type MutationUpsertOneDirectBookingAppointmentTypeSettingArgs = {
  where: DirectBookingAppointmentTypeSettingWhereUniqueInput;
  create: DirectBookingAppointmentTypeSettingCreateInput;
  update: DirectBookingAppointmentTypeSettingUpdateInput;
};


export type MutationDeleteManyDirectBookingAppointmentTypeSettingArgs = {
  where?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
};


export type MutationUpdateManyDirectBookingAppointmentTypeSettingArgs = {
  data: DirectBookingAppointmentTypeSettingUpdateManyMutationInput;
  where?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
};


export type MutationCreateOneClinicEmployeeAppointmentTypeSettingArgs = {
  data: ClinicEmployeeAppointmentTypeSettingCreateInput;
};


export type MutationUpdateOneClinicEmployeeAppointmentTypeSettingArgs = {
  data: ClinicEmployeeAppointmentTypeSettingUpdateInput;
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};


export type MutationDeleteOneClinicEmployeeAppointmentTypeSettingArgs = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};


export type MutationUpsertOneClinicEmployeeAppointmentTypeSettingArgs = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
  create: ClinicEmployeeAppointmentTypeSettingCreateInput;
  update: ClinicEmployeeAppointmentTypeSettingUpdateInput;
};


export type MutationDeleteManyClinicEmployeeAppointmentTypeSettingArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};


export type MutationUpdateManyClinicEmployeeAppointmentTypeSettingArgs = {
  data: ClinicEmployeeAppointmentTypeSettingUpdateManyMutationInput;
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};


export type MutationCreateOneClinicEntityPhoneNumberArgs = {
  data: ClinicEntityPhoneNumberCreateInput;
};


export type MutationUpdateOneClinicEntityPhoneNumberArgs = {
  data: ClinicEntityPhoneNumberUpdateInput;
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};


export type MutationDeleteOneClinicEntityPhoneNumberArgs = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};


export type MutationUpsertOneClinicEntityPhoneNumberArgs = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
  create: ClinicEntityPhoneNumberCreateInput;
  update: ClinicEntityPhoneNumberUpdateInput;
};


export type MutationDeleteManyClinicEntityPhoneNumberArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};


export type MutationUpdateManyClinicEntityPhoneNumberArgs = {
  data: ClinicEntityPhoneNumberUpdateManyMutationInput;
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};


export type MutationCreateOneClinicPaymentFeeConfigurationArgs = {
  data: ClinicPaymentFeeConfigurationCreateInput;
};


export type MutationUpdateOneClinicPaymentFeeConfigurationArgs = {
  data: ClinicPaymentFeeConfigurationUpdateInput;
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
};


export type MutationDeleteOneClinicPaymentFeeConfigurationArgs = {
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
};


export type MutationUpsertOneClinicPaymentFeeConfigurationArgs = {
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
  create: ClinicPaymentFeeConfigurationCreateInput;
  update: ClinicPaymentFeeConfigurationUpdateInput;
};


export type MutationDeleteManyClinicPaymentFeeConfigurationArgs = {
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
};


export type MutationUpdateManyClinicPaymentFeeConfigurationArgs = {
  data: ClinicPaymentFeeConfigurationUpdateManyMutationInput;
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
};


export type MutationCreateOneInterchangeRateArgs = {
  data: InterchangeRateCreateInput;
};


export type MutationUpdateOneInterchangeRateArgs = {
  data: InterchangeRateUpdateInput;
  where: InterchangeRateWhereUniqueInput;
};


export type MutationDeleteOneInterchangeRateArgs = {
  where: InterchangeRateWhereUniqueInput;
};


export type MutationUpsertOneInterchangeRateArgs = {
  where: InterchangeRateWhereUniqueInput;
  create: InterchangeRateCreateInput;
  update: InterchangeRateUpdateInput;
};


export type MutationDeleteManyInterchangeRateArgs = {
  where?: Maybe<InterchangeRateWhereInput>;
};


export type MutationUpdateManyInterchangeRateArgs = {
  data: InterchangeRateUpdateManyMutationInput;
  where?: Maybe<InterchangeRateWhereInput>;
};


export type MutationCreateOneClinicPetArgs = {
  data: ClinicPetCreateInput;
};


export type MutationUpdateOneClinicPetArgs = {
  data: ClinicPetUpdateInput;
  where: ClinicPetWhereUniqueInput;
};


export type MutationDeleteOneClinicPetArgs = {
  where: ClinicPetWhereUniqueInput;
};


export type MutationUpsertOneClinicPetArgs = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetCreateInput;
  update: ClinicPetUpdateInput;
};


export type MutationDeleteManyClinicPetArgs = {
  where?: Maybe<ClinicPetWhereInput>;
};


export type MutationUpdateManyClinicPetArgs = {
  data: ClinicPetUpdateManyMutationInput;
  where?: Maybe<ClinicPetWhereInput>;
};


export type MutationCreateOneClinicPetAlertArgs = {
  data: ClinicPetAlertCreateInput;
};


export type MutationUpdateOneClinicPetAlertArgs = {
  data: ClinicPetAlertUpdateInput;
  where: ClinicPetAlertWhereUniqueInput;
};


export type MutationDeleteOneClinicPetAlertArgs = {
  where: ClinicPetAlertWhereUniqueInput;
};


export type MutationUpsertOneClinicPetAlertArgs = {
  where: ClinicPetAlertWhereUniqueInput;
  create: ClinicPetAlertCreateInput;
  update: ClinicPetAlertUpdateInput;
};


export type MutationDeleteManyClinicPetAlertArgs = {
  where?: Maybe<ClinicPetAlertWhereInput>;
};


export type MutationUpdateManyClinicPetAlertArgs = {
  data: ClinicPetAlertUpdateManyMutationInput;
  where?: Maybe<ClinicPetAlertWhereInput>;
};


export type MutationCreateOneClinicPetParentAddressArgs = {
  data: ClinicPetParentAddressCreateInput;
};


export type MutationUpdateOneClinicPetParentAddressArgs = {
  data: ClinicPetParentAddressUpdateInput;
  where: ClinicPetParentAddressWhereUniqueInput;
};


export type MutationDeleteOneClinicPetParentAddressArgs = {
  where: ClinicPetParentAddressWhereUniqueInput;
};


export type MutationUpsertOneClinicPetParentAddressArgs = {
  where: ClinicPetParentAddressWhereUniqueInput;
  create: ClinicPetParentAddressCreateInput;
  update: ClinicPetParentAddressUpdateInput;
};


export type MutationDeleteManyClinicPetParentAddressArgs = {
  where?: Maybe<ClinicPetParentAddressWhereInput>;
};


export type MutationUpdateManyClinicPetParentAddressArgs = {
  data: ClinicPetParentAddressUpdateManyMutationInput;
  where?: Maybe<ClinicPetParentAddressWhereInput>;
};


export type MutationCreateOneClinicPetParentArgs = {
  data: ClinicPetParentCreateInput;
};


export type MutationUpdateOneClinicPetParentArgs = {
  data: ClinicPetParentUpdateInput;
  where: ClinicPetParentWhereUniqueInput;
};


export type MutationDeleteOneClinicPetParentArgs = {
  where: ClinicPetParentWhereUniqueInput;
};


export type MutationUpsertOneClinicPetParentArgs = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentCreateInput;
  update: ClinicPetParentUpdateInput;
};


export type MutationDeleteManyClinicPetParentArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type MutationUpdateManyClinicPetParentArgs = {
  data: ClinicPetParentUpdateManyMutationInput;
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type MutationCreateOneClinicDirectBookingSettingArgs = {
  data: ClinicDirectBookingSettingCreateInput;
};


export type MutationUpdateOneClinicDirectBookingSettingArgs = {
  data: ClinicDirectBookingSettingUpdateInput;
  where: ClinicDirectBookingSettingWhereUniqueInput;
};


export type MutationDeleteOneClinicDirectBookingSettingArgs = {
  where: ClinicDirectBookingSettingWhereUniqueInput;
};


export type MutationUpsertOneClinicDirectBookingSettingArgs = {
  where: ClinicDirectBookingSettingWhereUniqueInput;
  create: ClinicDirectBookingSettingCreateInput;
  update: ClinicDirectBookingSettingUpdateInput;
};


export type MutationDeleteManyClinicDirectBookingSettingArgs = {
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
};


export type MutationUpdateManyClinicDirectBookingSettingArgs = {
  data: ClinicDirectBookingSettingUpdateManyMutationInput;
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
};


export type MutationCreateOneDirectBookingExclusionRuleArgs = {
  data: DirectBookingExclusionRuleCreateInput;
};


export type MutationUpdateOneDirectBookingExclusionRuleArgs = {
  data: DirectBookingExclusionRuleUpdateInput;
  where: DirectBookingExclusionRuleWhereUniqueInput;
};


export type MutationDeleteOneDirectBookingExclusionRuleArgs = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
};


export type MutationUpsertOneDirectBookingExclusionRuleArgs = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
  create: DirectBookingExclusionRuleCreateInput;
  update: DirectBookingExclusionRuleUpdateInput;
};


export type MutationCreateOneDirectBookingExclusionTimeSlotArgs = {
  data: DirectBookingExclusionTimeSlotCreateInput;
};


export type MutationUpdateOneDirectBookingExclusionTimeSlotArgs = {
  data: DirectBookingExclusionTimeSlotUpdateInput;
  where: DirectBookingExclusionTimeSlotWhereUniqueInput;
};


export type MutationDeleteOneDirectBookingExclusionTimeSlotArgs = {
  where: DirectBookingExclusionTimeSlotWhereUniqueInput;
};


export type MutationUpsertOneDirectBookingExclusionTimeSlotArgs = {
  where: DirectBookingExclusionTimeSlotWhereUniqueInput;
  create: DirectBookingExclusionTimeSlotCreateInput;
  update: DirectBookingExclusionTimeSlotUpdateInput;
};


export type MutationDeleteManyDirectBookingExclusionTimeSlotArgs = {
  where?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
};


export type MutationUpdateManyDirectBookingExclusionTimeSlotArgs = {
  data: DirectBookingExclusionTimeSlotUpdateManyMutationInput;
  where?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
};


export type MutationCreateOneClinicEmailCampaignSettingArgs = {
  data: ClinicEmailCampaignSettingCreateInput;
};


export type MutationUpdateOneClinicEmailCampaignSettingArgs = {
  data: ClinicEmailCampaignSettingUpdateInput;
  where: ClinicEmailCampaignSettingWhereUniqueInput;
};


export type MutationDeleteOneClinicEmailCampaignSettingArgs = {
  where: ClinicEmailCampaignSettingWhereUniqueInput;
};


export type MutationUpsertOneClinicEmailCampaignSettingArgs = {
  where: ClinicEmailCampaignSettingWhereUniqueInput;
  create: ClinicEmailCampaignSettingCreateInput;
  update: ClinicEmailCampaignSettingUpdateInput;
};


export type MutationDeleteManyClinicEmailCampaignSettingArgs = {
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
};


export type MutationUpdateManyClinicEmailCampaignSettingArgs = {
  data: ClinicEmailCampaignSettingUpdateManyMutationInput;
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
};


export type MutationCreateOneClinicPostcardSettingArgs = {
  data: ClinicPostcardSettingCreateInput;
};


export type MutationUpdateOneClinicPostcardSettingArgs = {
  data: ClinicPostcardSettingUpdateInput;
  where: ClinicPostcardSettingWhereUniqueInput;
};


export type MutationDeleteOneClinicPostcardSettingArgs = {
  where: ClinicPostcardSettingWhereUniqueInput;
};


export type MutationUpsertOneClinicPostcardSettingArgs = {
  where: ClinicPostcardSettingWhereUniqueInput;
  create: ClinicPostcardSettingCreateInput;
  update: ClinicPostcardSettingUpdateInput;
};


export type MutationDeleteManyClinicPostcardSettingArgs = {
  where?: Maybe<ClinicPostcardSettingWhereInput>;
};


export type MutationUpdateManyClinicPostcardSettingArgs = {
  data: ClinicPostcardSettingUpdateManyMutationInput;
  where?: Maybe<ClinicPostcardSettingWhereInput>;
};


export type MutationCreateOneIntegrationOutageHistoryEntryArgs = {
  data: IntegrationOutageHistoryEntryCreateInput;
};


export type MutationUpdateOneIntegrationOutageHistoryEntryArgs = {
  data: IntegrationOutageHistoryEntryUpdateInput;
  where: IntegrationOutageHistoryEntryWhereUniqueInput;
};


export type MutationDeleteOneIntegrationOutageHistoryEntryArgs = {
  where: IntegrationOutageHistoryEntryWhereUniqueInput;
};


export type MutationUpsertOneIntegrationOutageHistoryEntryArgs = {
  where: IntegrationOutageHistoryEntryWhereUniqueInput;
  create: IntegrationOutageHistoryEntryCreateInput;
  update: IntegrationOutageHistoryEntryUpdateInput;
};


export type MutationDeleteManyIntegrationOutageHistoryEntryArgs = {
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
};


export type MutationUpdateManyIntegrationOutageHistoryEntryArgs = {
  data: IntegrationOutageHistoryEntryUpdateManyMutationInput;
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
};


export type MutationCreateOneClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  data: ClinicPimsIntegrationCapabilityHistoryEntryCreateInput;
};


export type MutationUpdateOneClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  data: ClinicPimsIntegrationCapabilityHistoryEntryUpdateInput;
  where: ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput;
};


export type MutationDeleteOneClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  where: ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput;
};


export type MutationUpsertOneClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  where: ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput;
  create: ClinicPimsIntegrationCapabilityHistoryEntryCreateInput;
  update: ClinicPimsIntegrationCapabilityHistoryEntryUpdateInput;
};


export type MutationDeleteManyClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
};


export type MutationUpdateManyClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  data: ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyMutationInput;
  where?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
};


export type MutationCreateOneClinicPimsIntegrationArgs = {
  data: ClinicPimsIntegrationCreateInput;
};


export type MutationUpdateOneClinicPimsIntegrationArgs = {
  data: ClinicPimsIntegrationUpdateInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};


export type MutationDeleteOneClinicPimsIntegrationArgs = {
  where: ClinicPimsIntegrationWhereUniqueInput;
};


export type MutationUpsertOneClinicPimsIntegrationArgs = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationCreateInput;
  update: ClinicPimsIntegrationUpdateInput;
};


export type MutationDeleteManyClinicPimsIntegrationArgs = {
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
};


export type MutationUpdateManyClinicPimsIntegrationArgs = {
  data: ClinicPimsIntegrationUpdateManyMutationInput;
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
};


export type MutationCreateOneSyncVetIntegrationArgs = {
  data: SyncVetIntegrationCreateInput;
};


export type MutationUpdateOneSyncVetIntegrationArgs = {
  data: SyncVetIntegrationUpdateInput;
  where: SyncVetIntegrationWhereUniqueInput;
};


export type MutationDeleteOneSyncVetIntegrationArgs = {
  where: SyncVetIntegrationWhereUniqueInput;
};


export type MutationUpsertOneSyncVetIntegrationArgs = {
  where: SyncVetIntegrationWhereUniqueInput;
  create: SyncVetIntegrationCreateInput;
  update: SyncVetIntegrationUpdateInput;
};


export type MutationDeleteManySyncVetIntegrationArgs = {
  where?: Maybe<SyncVetIntegrationWhereInput>;
};


export type MutationUpdateManySyncVetIntegrationArgs = {
  data: SyncVetIntegrationUpdateManyMutationInput;
  where?: Maybe<SyncVetIntegrationWhereInput>;
};


export type MutationCreateOneClinicPimsIntegrationCapabilityArgs = {
  data: ClinicPimsIntegrationCapabilityCreateInput;
};


export type MutationUpdateOneClinicPimsIntegrationCapabilityArgs = {
  data: ClinicPimsIntegrationCapabilityUpdateInput;
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
};


export type MutationDeleteOneClinicPimsIntegrationCapabilityArgs = {
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
};


export type MutationUpsertOneClinicPimsIntegrationCapabilityArgs = {
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
  create: ClinicPimsIntegrationCapabilityCreateInput;
  update: ClinicPimsIntegrationCapabilityUpdateInput;
};


export type MutationDeleteManyClinicPimsIntegrationCapabilityArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
};


export type MutationUpdateManyClinicPimsIntegrationCapabilityArgs = {
  data: ClinicPimsIntegrationCapabilityUpdateManyMutationInput;
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
};


export type MutationCreateOneClinicRoomArgs = {
  data: ClinicRoomCreateInput;
};


export type MutationUpdateOneClinicRoomArgs = {
  data: ClinicRoomUpdateInput;
  where: ClinicRoomWhereUniqueInput;
};


export type MutationDeleteOneClinicRoomArgs = {
  where: ClinicRoomWhereUniqueInput;
};


export type MutationUpsertOneClinicRoomArgs = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomCreateInput;
  update: ClinicRoomUpdateInput;
};


export type MutationDeleteManyClinicRoomArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
};


export type MutationUpdateManyClinicRoomArgs = {
  data: ClinicRoomUpdateManyMutationInput;
  where?: Maybe<ClinicRoomWhereInput>;
};


export type MutationCreateOneClinicSettingArgs = {
  data: ClinicSettingCreateInput;
};


export type MutationUpdateOneClinicSettingArgs = {
  data: ClinicSettingUpdateInput;
  where: ClinicSettingWhereUniqueInput;
};


export type MutationDeleteOneClinicSettingArgs = {
  where: ClinicSettingWhereUniqueInput;
};


export type MutationUpsertOneClinicSettingArgs = {
  where: ClinicSettingWhereUniqueInput;
  create: ClinicSettingCreateInput;
  update: ClinicSettingUpdateInput;
};


export type MutationDeleteManyClinicSettingArgs = {
  where?: Maybe<ClinicSettingWhereInput>;
};


export type MutationUpdateManyClinicSettingArgs = {
  data: ClinicSettingUpdateManyMutationInput;
  where?: Maybe<ClinicSettingWhereInput>;
};


export type MutationCreateOneClinicWidgetRequestArgs = {
  data: ClinicWidgetRequestCreateInput;
};


export type MutationUpdateOneClinicWidgetRequestArgs = {
  data: ClinicWidgetRequestUpdateInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};


export type MutationDeleteOneClinicWidgetRequestArgs = {
  where: ClinicWidgetRequestWhereUniqueInput;
};


export type MutationUpsertOneClinicWidgetRequestArgs = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestCreateInput;
  update: ClinicWidgetRequestUpdateInput;
};


export type MutationDeleteManyClinicWidgetRequestArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
};


export type MutationUpdateManyClinicWidgetRequestArgs = {
  data: ClinicWidgetRequestUpdateManyMutationInput;
  where?: Maybe<ClinicWidgetRequestWhereInput>;
};


export type MutationCreateOneClinicWidgetRequestTypeArgs = {
  data: ClinicWidgetRequestTypeCreateInput;
};


export type MutationUpdateOneClinicWidgetRequestTypeArgs = {
  data: ClinicWidgetRequestTypeUpdateInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};


export type MutationDeleteOneClinicWidgetRequestTypeArgs = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};


export type MutationUpsertOneClinicWidgetRequestTypeArgs = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  create: ClinicWidgetRequestTypeCreateInput;
  update: ClinicWidgetRequestTypeUpdateInput;
};


export type MutationDeleteManyClinicWidgetRequestTypeArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};


export type MutationUpdateManyClinicWidgetRequestTypeArgs = {
  data: ClinicWidgetRequestTypeUpdateManyMutationInput;
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};


export type MutationCreateOneClinicWidgetSettingArgs = {
  data: ClinicWidgetSettingCreateInput;
};


export type MutationUpdateOneClinicWidgetSettingArgs = {
  data: ClinicWidgetSettingUpdateInput;
  where: ClinicWidgetSettingWhereUniqueInput;
};


export type MutationDeleteOneClinicWidgetSettingArgs = {
  where: ClinicWidgetSettingWhereUniqueInput;
};


export type MutationUpsertOneClinicWidgetSettingArgs = {
  where: ClinicWidgetSettingWhereUniqueInput;
  create: ClinicWidgetSettingCreateInput;
  update: ClinicWidgetSettingUpdateInput;
};


export type MutationDeleteManyClinicWidgetSettingArgs = {
  where?: Maybe<ClinicWidgetSettingWhereInput>;
};


export type MutationUpdateManyClinicWidgetSettingArgs = {
  data: ClinicWidgetSettingUpdateManyMutationInput;
  where?: Maybe<ClinicWidgetSettingWhereInput>;
};


export type MutationCreateOneConsultationRequestArgs = {
  data: ConsultationRequestCreateInput;
};


export type MutationUpdateOneConsultationRequestArgs = {
  data: ConsultationRequestUpdateInput;
  where: ConsultationRequestWhereUniqueInput;
};


export type MutationDeleteOneConsultationRequestArgs = {
  where: ConsultationRequestWhereUniqueInput;
};


export type MutationUpsertOneConsultationRequestArgs = {
  where: ConsultationRequestWhereUniqueInput;
  create: ConsultationRequestCreateInput;
  update: ConsultationRequestUpdateInput;
};


export type MutationDeleteManyConsultationRequestArgs = {
  where?: Maybe<ConsultationRequestWhereInput>;
};


export type MutationUpdateManyConsultationRequestArgs = {
  data: ConsultationRequestUpdateManyMutationInput;
  where?: Maybe<ConsultationRequestWhereInput>;
};


export type MutationCreateOneDisbursementArgs = {
  data: DisbursementCreateInput;
};


export type MutationUpdateOneDisbursementArgs = {
  data: DisbursementUpdateInput;
  where: DisbursementWhereUniqueInput;
};


export type MutationDeleteOneDisbursementArgs = {
  where: DisbursementWhereUniqueInput;
};


export type MutationUpsertOneDisbursementArgs = {
  where: DisbursementWhereUniqueInput;
  create: DisbursementCreateInput;
  update: DisbursementUpdateInput;
};


export type MutationDeleteManyDisbursementArgs = {
  where?: Maybe<DisbursementWhereInput>;
};


export type MutationUpdateManyDisbursementArgs = {
  data: DisbursementUpdateManyMutationInput;
  where?: Maybe<DisbursementWhereInput>;
};


export type MutationCreateOneDocumentationArgs = {
  data: DocumentationCreateInput;
};


export type MutationUpdateOneDocumentationArgs = {
  data: DocumentationUpdateInput;
  where: DocumentationWhereUniqueInput;
};


export type MutationDeleteOneDocumentationArgs = {
  where: DocumentationWhereUniqueInput;
};


export type MutationUpsertOneDocumentationArgs = {
  where: DocumentationWhereUniqueInput;
  create: DocumentationCreateInput;
  update: DocumentationUpdateInput;
};


export type MutationDeleteManyDocumentationArgs = {
  where?: Maybe<DocumentationWhereInput>;
};


export type MutationUpdateManyDocumentationArgs = {
  data: DocumentationUpdateManyMutationInput;
  where?: Maybe<DocumentationWhereInput>;
};


export type MutationCreateOneEzyVetIntegrationArgs = {
  data: EzyVetIntegrationCreateInput;
};


export type MutationUpdateOneEzyVetIntegrationArgs = {
  data: EzyVetIntegrationUpdateInput;
  where: EzyVetIntegrationWhereUniqueInput;
};


export type MutationDeleteOneEzyVetIntegrationArgs = {
  where: EzyVetIntegrationWhereUniqueInput;
};


export type MutationUpsertOneEzyVetIntegrationArgs = {
  where: EzyVetIntegrationWhereUniqueInput;
  create: EzyVetIntegrationCreateInput;
  update: EzyVetIntegrationUpdateInput;
};


export type MutationDeleteManyEzyVetIntegrationArgs = {
  where?: Maybe<EzyVetIntegrationWhereInput>;
};


export type MutationUpdateManyEzyVetIntegrationArgs = {
  data: EzyVetIntegrationUpdateManyMutationInput;
  where?: Maybe<EzyVetIntegrationWhereInput>;
};


export type MutationCreateOneFinancialTransactionArgs = {
  data: FinancialTransactionCreateInput;
};


export type MutationUpdateOneFinancialTransactionArgs = {
  data: FinancialTransactionUpdateInput;
  where: FinancialTransactionWhereUniqueInput;
};


export type MutationDeleteOneFinancialTransactionArgs = {
  where: FinancialTransactionWhereUniqueInput;
};


export type MutationUpsertOneFinancialTransactionArgs = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionCreateInput;
  update: FinancialTransactionUpdateInput;
};


export type MutationDeleteManyFinancialTransactionArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
};


export type MutationUpdateManyFinancialTransactionArgs = {
  data: FinancialTransactionUpdateManyMutationInput;
  where?: Maybe<FinancialTransactionWhereInput>;
};


export type MutationCreateOneFinancialAdjustmentArgs = {
  data: FinancialAdjustmentCreateInput;
};


export type MutationUpdateOneFinancialAdjustmentArgs = {
  data: FinancialAdjustmentUpdateInput;
  where: FinancialAdjustmentWhereUniqueInput;
};


export type MutationDeleteOneFinancialAdjustmentArgs = {
  where: FinancialAdjustmentWhereUniqueInput;
};


export type MutationUpsertOneFinancialAdjustmentArgs = {
  where: FinancialAdjustmentWhereUniqueInput;
  create: FinancialAdjustmentCreateInput;
  update: FinancialAdjustmentUpdateInput;
};


export type MutationDeleteManyFinancialAdjustmentArgs = {
  where?: Maybe<FinancialAdjustmentWhereInput>;
};


export type MutationUpdateManyFinancialAdjustmentArgs = {
  data: FinancialAdjustmentUpdateManyMutationInput;
  where?: Maybe<FinancialAdjustmentWhereInput>;
};


export type MutationCreateOneFormSubmissionArgs = {
  data: FormSubmissionCreateInput;
};


export type MutationUpdateOneFormSubmissionArgs = {
  data: FormSubmissionUpdateInput;
  where: FormSubmissionWhereUniqueInput;
};


export type MutationDeleteOneFormSubmissionArgs = {
  where: FormSubmissionWhereUniqueInput;
};


export type MutationUpsertOneFormSubmissionArgs = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionCreateInput;
  update: FormSubmissionUpdateInput;
};


export type MutationDeleteManyFormSubmissionArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
};


export type MutationUpdateManyFormSubmissionArgs = {
  data: FormSubmissionUpdateManyMutationInput;
  where?: Maybe<FormSubmissionWhereInput>;
};


export type MutationCreateOneFormTemplateArgs = {
  data: FormTemplateCreateInput;
};


export type MutationUpdateOneFormTemplateArgs = {
  data: FormTemplateUpdateInput;
  where: FormTemplateWhereUniqueInput;
};


export type MutationDeleteOneFormTemplateArgs = {
  where: FormTemplateWhereUniqueInput;
};


export type MutationUpsertOneFormTemplateArgs = {
  where: FormTemplateWhereUniqueInput;
  create: FormTemplateCreateInput;
  update: FormTemplateUpdateInput;
};


export type MutationDeleteManyFormTemplateArgs = {
  where?: Maybe<FormTemplateWhereInput>;
};


export type MutationUpdateManyFormTemplateArgs = {
  data: FormTemplateUpdateManyMutationInput;
  where?: Maybe<FormTemplateWhereInput>;
};


export type MutationCreateOneInstinctIntegrationArgs = {
  data: InstinctIntegrationCreateInput;
};


export type MutationUpdateOneInstinctIntegrationArgs = {
  data: InstinctIntegrationUpdateInput;
  where: InstinctIntegrationWhereUniqueInput;
};


export type MutationDeleteOneInstinctIntegrationArgs = {
  where: InstinctIntegrationWhereUniqueInput;
};


export type MutationUpsertOneInstinctIntegrationArgs = {
  where: InstinctIntegrationWhereUniqueInput;
  create: InstinctIntegrationCreateInput;
  update: InstinctIntegrationUpdateInput;
};


export type MutationDeleteManyInstinctIntegrationArgs = {
  where?: Maybe<InstinctIntegrationWhereInput>;
};


export type MutationUpdateManyInstinctIntegrationArgs = {
  data: InstinctIntegrationUpdateManyMutationInput;
  where?: Maybe<InstinctIntegrationWhereInput>;
};


export type MutationCreateOneInvoiceArgs = {
  data: InvoiceCreateInput;
};


export type MutationUpdateOneInvoiceArgs = {
  data: InvoiceUpdateInput;
  where: InvoiceWhereUniqueInput;
};


export type MutationDeleteOneInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type MutationUpsertOneInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceCreateInput;
  update: InvoiceUpdateInput;
};


export type MutationDeleteManyInvoiceArgs = {
  where?: Maybe<InvoiceWhereInput>;
};


export type MutationUpdateManyInvoiceArgs = {
  data: InvoiceUpdateManyMutationInput;
  where?: Maybe<InvoiceWhereInput>;
};


export type MutationCreateOneInvoiceLineItemArgs = {
  data: InvoiceLineItemCreateInput;
};


export type MutationUpdateOneInvoiceLineItemArgs = {
  data: InvoiceLineItemUpdateInput;
  where: InvoiceLineItemWhereUniqueInput;
};


export type MutationDeleteOneInvoiceLineItemArgs = {
  where: InvoiceLineItemWhereUniqueInput;
};


export type MutationUpsertOneInvoiceLineItemArgs = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemCreateInput;
  update: InvoiceLineItemUpdateInput;
};


export type MutationDeleteManyInvoiceLineItemArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
};


export type MutationUpdateManyInvoiceLineItemArgs = {
  data: InvoiceLineItemUpdateManyMutationInput;
  where?: Maybe<InvoiceLineItemWhereInput>;
};


export type MutationCreateOneOrganizationArgs = {
  data: OrganizationCreateInput;
};


export type MutationUpdateOneOrganizationArgs = {
  data: OrganizationUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationDeleteOneOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
};


export type MutationUpsertOneOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateInput;
  update: OrganizationUpdateInput;
};


export type MutationDeleteManyOrganizationArgs = {
  where?: Maybe<OrganizationWhereInput>;
};


export type MutationUpdateManyOrganizationArgs = {
  data: OrganizationUpdateManyMutationInput;
  where?: Maybe<OrganizationWhereInput>;
};


export type MutationCreateOneOrganizationPetArgs = {
  data: OrganizationPetCreateInput;
};


export type MutationUpdateOneOrganizationPetArgs = {
  data: OrganizationPetUpdateInput;
  where: OrganizationPetWhereUniqueInput;
};


export type MutationDeleteOneOrganizationPetArgs = {
  where: OrganizationPetWhereUniqueInput;
};


export type MutationUpsertOneOrganizationPetArgs = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetCreateInput;
  update: OrganizationPetUpdateInput;
};


export type MutationDeleteManyOrganizationPetArgs = {
  where?: Maybe<OrganizationPetWhereInput>;
};


export type MutationUpdateManyOrganizationPetArgs = {
  data: OrganizationPetUpdateManyMutationInput;
  where?: Maybe<OrganizationPetWhereInput>;
};


export type MutationCreateOneOrganizationPetParentArgs = {
  data: OrganizationPetParentCreateInput;
};


export type MutationUpdateOneOrganizationPetParentArgs = {
  data: OrganizationPetParentUpdateInput;
  where: OrganizationPetParentWhereUniqueInput;
};


export type MutationDeleteOneOrganizationPetParentArgs = {
  where: OrganizationPetParentWhereUniqueInput;
};


export type MutationUpsertOneOrganizationPetParentArgs = {
  where: OrganizationPetParentWhereUniqueInput;
  create: OrganizationPetParentCreateInput;
  update: OrganizationPetParentUpdateInput;
};


export type MutationDeleteManyOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
};


export type MutationUpdateManyOrganizationPetParentArgs = {
  data: OrganizationPetParentUpdateManyMutationInput;
  where?: Maybe<OrganizationPetParentWhereInput>;
};


export type MutationCreateOneOrganizationPetToOrganizationPetParentArgs = {
  data: OrganizationPetToOrganizationPetParentCreateInput;
};


export type MutationUpdateOneOrganizationPetToOrganizationPetParentArgs = {
  data: OrganizationPetToOrganizationPetParentUpdateInput;
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};


export type MutationDeleteOneOrganizationPetToOrganizationPetParentArgs = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};


export type MutationUpsertOneOrganizationPetToOrganizationPetParentArgs = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
  create: OrganizationPetToOrganizationPetParentCreateInput;
  update: OrganizationPetToOrganizationPetParentUpdateInput;
};


export type MutationDeleteManyOrganizationPetToOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
};


export type MutationCreateOnePermissionArgs = {
  data: PermissionCreateInput;
};


export type MutationUpdateOnePermissionArgs = {
  data: PermissionUpdateInput;
  where: PermissionWhereUniqueInput;
};


export type MutationDeleteOnePermissionArgs = {
  where: PermissionWhereUniqueInput;
};


export type MutationUpsertOnePermissionArgs = {
  where: PermissionWhereUniqueInput;
  create: PermissionCreateInput;
  update: PermissionUpdateInput;
};


export type MutationDeleteManyPermissionArgs = {
  where?: Maybe<PermissionWhereInput>;
};


export type MutationUpdateManyPermissionArgs = {
  data: PermissionUpdateManyMutationInput;
  where?: Maybe<PermissionWhereInput>;
};


export type MutationCreateOnePetParentSettingArgs = {
  data: PetParentSettingCreateInput;
};


export type MutationUpdateOnePetParentSettingArgs = {
  data: PetParentSettingUpdateInput;
  where: PetParentSettingWhereUniqueInput;
};


export type MutationDeleteOnePetParentSettingArgs = {
  where: PetParentSettingWhereUniqueInput;
};


export type MutationUpsertOnePetParentSettingArgs = {
  where: PetParentSettingWhereUniqueInput;
  create: PetParentSettingCreateInput;
  update: PetParentSettingUpdateInput;
};


export type MutationDeleteManyPetParentSettingArgs = {
  where?: Maybe<PetParentSettingWhereInput>;
};


export type MutationUpdateManyPetParentSettingArgs = {
  data: PetParentSettingUpdateManyMutationInput;
  where?: Maybe<PetParentSettingWhereInput>;
};


export type MutationCreateOnePimsInvoiceArgs = {
  data: PimsInvoiceCreateInput;
};


export type MutationUpdateOnePimsInvoiceArgs = {
  data: PimsInvoiceUpdateInput;
  where: PimsInvoiceWhereUniqueInput;
};


export type MutationDeleteOnePimsInvoiceArgs = {
  where: PimsInvoiceWhereUniqueInput;
};


export type MutationUpsertOnePimsInvoiceArgs = {
  where: PimsInvoiceWhereUniqueInput;
  create: PimsInvoiceCreateInput;
  update: PimsInvoiceUpdateInput;
};


export type MutationDeleteManyPimsInvoiceArgs = {
  where?: Maybe<PimsInvoiceWhereInput>;
};


export type MutationUpdateManyPimsInvoiceArgs = {
  data: PimsInvoiceUpdateManyMutationInput;
  where?: Maybe<PimsInvoiceWhereInput>;
};


export type MutationCreateOnePimsInvoiceLineItemArgs = {
  data: PimsInvoiceLineItemCreateInput;
};


export type MutationUpdateOnePimsInvoiceLineItemArgs = {
  data: PimsInvoiceLineItemUpdateInput;
  where: PimsInvoiceLineItemWhereUniqueInput;
};


export type MutationDeleteOnePimsInvoiceLineItemArgs = {
  where: PimsInvoiceLineItemWhereUniqueInput;
};


export type MutationUpsertOnePimsInvoiceLineItemArgs = {
  where: PimsInvoiceLineItemWhereUniqueInput;
  create: PimsInvoiceLineItemCreateInput;
  update: PimsInvoiceLineItemUpdateInput;
};


export type MutationDeleteManyPimsInvoiceLineItemArgs = {
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
};


export type MutationUpdateManyPimsInvoiceLineItemArgs = {
  data: PimsInvoiceLineItemUpdateManyMutationInput;
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
};


export type MutationCreateOneServiceArgs = {
  data: ServiceCreateInput;
};


export type MutationUpdateOneServiceArgs = {
  data: ServiceUpdateInput;
  where: ServiceWhereUniqueInput;
};


export type MutationDeleteOneServiceArgs = {
  where: ServiceWhereUniqueInput;
};


export type MutationUpsertOneServiceArgs = {
  where: ServiceWhereUniqueInput;
  create: ServiceCreateInput;
  update: ServiceUpdateInput;
};


export type MutationDeleteManyServiceArgs = {
  where?: Maybe<ServiceWhereInput>;
};


export type MutationUpdateManyServiceArgs = {
  data: ServiceUpdateManyMutationInput;
  where?: Maybe<ServiceWhereInput>;
};


export type MutationCreateOneServiceReminderTimingArgs = {
  data: ServiceReminderTimingCreateInput;
};


export type MutationUpdateOneServiceReminderTimingArgs = {
  data: ServiceReminderTimingUpdateInput;
  where: ServiceReminderTimingWhereUniqueInput;
};


export type MutationDeleteOneServiceReminderTimingArgs = {
  where: ServiceReminderTimingWhereUniqueInput;
};


export type MutationUpsertOneServiceReminderTimingArgs = {
  where: ServiceReminderTimingWhereUniqueInput;
  create: ServiceReminderTimingCreateInput;
  update: ServiceReminderTimingUpdateInput;
};


export type MutationDeleteManyServiceReminderTimingArgs = {
  where?: Maybe<ServiceReminderTimingWhereInput>;
};


export type MutationUpdateManyServiceReminderTimingArgs = {
  data: ServiceReminderTimingUpdateManyMutationInput;
  where?: Maybe<ServiceReminderTimingWhereInput>;
};


export type MutationCreateOneServiceReminderArgs = {
  data: ServiceReminderCreateInput;
};


export type MutationUpdateOneServiceReminderArgs = {
  data: ServiceReminderUpdateInput;
  where: ServiceReminderWhereUniqueInput;
};


export type MutationDeleteOneServiceReminderArgs = {
  where: ServiceReminderWhereUniqueInput;
};


export type MutationUpsertOneServiceReminderArgs = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderCreateInput;
  update: ServiceReminderUpdateInput;
};


export type MutationDeleteManyServiceReminderArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
};


export type MutationUpdateManyServiceReminderArgs = {
  data: ServiceReminderUpdateManyMutationInput;
  where?: Maybe<ServiceReminderWhereInput>;
};


export type MutationCreateOneServiceReminderNotificationArgs = {
  data: ServiceReminderNotificationCreateInput;
};


export type MutationUpdateOneServiceReminderNotificationArgs = {
  data: ServiceReminderNotificationUpdateInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};


export type MutationDeleteOneServiceReminderNotificationArgs = {
  where: ServiceReminderNotificationWhereUniqueInput;
};


export type MutationUpsertOneServiceReminderNotificationArgs = {
  where: ServiceReminderNotificationWhereUniqueInput;
  create: ServiceReminderNotificationCreateInput;
  update: ServiceReminderNotificationUpdateInput;
};


export type MutationDeleteManyServiceReminderNotificationArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
};


export type MutationUpdateManyServiceReminderNotificationArgs = {
  data: ServiceReminderNotificationUpdateManyMutationInput;
  where?: Maybe<ServiceReminderNotificationWhereInput>;
};


export type MutationCreateOneStaffRoleArgs = {
  data: StaffRoleCreateInput;
};


export type MutationUpdateOneStaffRoleArgs = {
  data: StaffRoleUpdateInput;
  where: StaffRoleWhereUniqueInput;
};


export type MutationDeleteOneStaffRoleArgs = {
  where: StaffRoleWhereUniqueInput;
};


export type MutationUpsertOneStaffRoleArgs = {
  where: StaffRoleWhereUniqueInput;
  create: StaffRoleCreateInput;
  update: StaffRoleUpdateInput;
};


export type MutationDeleteManyStaffRoleArgs = {
  where?: Maybe<StaffRoleWhereInput>;
};


export type MutationUpdateManyStaffRoleArgs = {
  data: StaffRoleUpdateManyMutationInput;
  where?: Maybe<StaffRoleWhereInput>;
};


export type MutationCreateOneStripeDisputeArgs = {
  data: StripeDisputeCreateInput;
};


export type MutationUpdateOneStripeDisputeArgs = {
  data: StripeDisputeUpdateInput;
  where: StripeDisputeWhereUniqueInput;
};


export type MutationDeleteOneStripeDisputeArgs = {
  where: StripeDisputeWhereUniqueInput;
};


export type MutationUpsertOneStripeDisputeArgs = {
  where: StripeDisputeWhereUniqueInput;
  create: StripeDisputeCreateInput;
  update: StripeDisputeUpdateInput;
};


export type MutationDeleteManyStripeDisputeArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
};


export type MutationUpdateManyStripeDisputeArgs = {
  data: StripeDisputeUpdateManyMutationInput;
  where?: Maybe<StripeDisputeWhereInput>;
};


export type MutationCreateOneStripePaymentIntentArgs = {
  data: StripePaymentIntentCreateInput;
};


export type MutationUpdateOneStripePaymentIntentArgs = {
  data: StripePaymentIntentUpdateInput;
  where: StripePaymentIntentWhereUniqueInput;
};


export type MutationUpsertOneStripePaymentIntentArgs = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentCreateInput;
  update: StripePaymentIntentUpdateInput;
};


export type MutationUpdateManyStripePaymentIntentArgs = {
  data: StripePaymentIntentUpdateManyMutationInput;
  where?: Maybe<StripePaymentIntentWhereInput>;
};


export type MutationCreateOneStripePaymentMethodArgs = {
  data: StripePaymentMethodCreateInput;
};


export type MutationUpdateOneStripePaymentMethodArgs = {
  data: StripePaymentMethodUpdateInput;
  where: StripePaymentMethodWhereUniqueInput;
};


export type MutationDeleteOneStripePaymentMethodArgs = {
  where: StripePaymentMethodWhereUniqueInput;
};


export type MutationUpsertOneStripePaymentMethodArgs = {
  where: StripePaymentMethodWhereUniqueInput;
  create: StripePaymentMethodCreateInput;
  update: StripePaymentMethodUpdateInput;
};


export type MutationDeleteManyStripePaymentMethodArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
};


export type MutationUpdateManyStripePaymentMethodArgs = {
  data: StripePaymentMethodUpdateManyMutationInput;
  where?: Maybe<StripePaymentMethodWhereInput>;
};


export type MutationCreateOneStripePayoutArgs = {
  data: StripePayoutCreateInput;
};


export type MutationUpdateOneStripePayoutArgs = {
  data: StripePayoutUpdateInput;
  where: StripePayoutWhereUniqueInput;
};


export type MutationDeleteOneStripePayoutArgs = {
  where: StripePayoutWhereUniqueInput;
};


export type MutationUpsertOneStripePayoutArgs = {
  where: StripePayoutWhereUniqueInput;
  create: StripePayoutCreateInput;
  update: StripePayoutUpdateInput;
};


export type MutationDeleteManyStripePayoutArgs = {
  where?: Maybe<StripePayoutWhereInput>;
};


export type MutationUpdateManyStripePayoutArgs = {
  data: StripePayoutUpdateManyMutationInput;
  where?: Maybe<StripePayoutWhereInput>;
};


export type MutationCreateOneStripePayoutAccountArgs = {
  data: StripePayoutAccountCreateInput;
};


export type MutationUpdateOneStripePayoutAccountArgs = {
  data: StripePayoutAccountUpdateInput;
  where: StripePayoutAccountWhereUniqueInput;
};


export type MutationDeleteOneStripePayoutAccountArgs = {
  where: StripePayoutAccountWhereUniqueInput;
};


export type MutationUpsertOneStripePayoutAccountArgs = {
  where: StripePayoutAccountWhereUniqueInput;
  create: StripePayoutAccountCreateInput;
  update: StripePayoutAccountUpdateInput;
};


export type MutationDeleteManyStripePayoutAccountArgs = {
  where?: Maybe<StripePayoutAccountWhereInput>;
};


export type MutationUpdateManyStripePayoutAccountArgs = {
  data: StripePayoutAccountUpdateManyMutationInput;
  where?: Maybe<StripePayoutAccountWhereInput>;
};


export type MutationCreateOneStripeReceiptArgs = {
  data: StripeReceiptCreateInput;
};


export type MutationUpdateOneStripeReceiptArgs = {
  data: StripeReceiptUpdateInput;
  where: StripeReceiptWhereUniqueInput;
};


export type MutationDeleteOneStripeReceiptArgs = {
  where: StripeReceiptWhereUniqueInput;
};


export type MutationUpsertOneStripeReceiptArgs = {
  where: StripeReceiptWhereUniqueInput;
  create: StripeReceiptCreateInput;
  update: StripeReceiptUpdateInput;
};


export type MutationDeleteManyStripeReceiptArgs = {
  where?: Maybe<StripeReceiptWhereInput>;
};


export type MutationUpdateManyStripeReceiptArgs = {
  data: StripeReceiptUpdateManyMutationInput;
  where?: Maybe<StripeReceiptWhereInput>;
};


export type MutationCreateOneStripeReceiptHistoryArgs = {
  data: StripeReceiptHistoryCreateInput;
};


export type MutationUpdateOneStripeReceiptHistoryArgs = {
  data: StripeReceiptHistoryUpdateInput;
  where: StripeReceiptHistoryWhereUniqueInput;
};


export type MutationDeleteOneStripeReceiptHistoryArgs = {
  where: StripeReceiptHistoryWhereUniqueInput;
};


export type MutationUpsertOneStripeReceiptHistoryArgs = {
  where: StripeReceiptHistoryWhereUniqueInput;
  create: StripeReceiptHistoryCreateInput;
  update: StripeReceiptHistoryUpdateInput;
};


export type MutationDeleteManyStripeReceiptHistoryArgs = {
  where?: Maybe<StripeReceiptHistoryWhereInput>;
};


export type MutationUpdateManyStripeReceiptHistoryArgs = {
  data: StripeReceiptHistoryUpdateManyMutationInput;
  where?: Maybe<StripeReceiptHistoryWhereInput>;
};


export type MutationCreateOneStripeRefundArgs = {
  data: StripeRefundCreateInput;
};


export type MutationUpdateOneStripeRefundArgs = {
  data: StripeRefundUpdateInput;
  where: StripeRefundWhereUniqueInput;
};


export type MutationDeleteOneStripeRefundArgs = {
  where: StripeRefundWhereUniqueInput;
};


export type MutationUpsertOneStripeRefundArgs = {
  where: StripeRefundWhereUniqueInput;
  create: StripeRefundCreateInput;
  update: StripeRefundUpdateInput;
};


export type MutationDeleteManyStripeRefundArgs = {
  where?: Maybe<StripeRefundWhereInput>;
};


export type MutationUpdateManyStripeRefundArgs = {
  data: StripeRefundUpdateManyMutationInput;
  where?: Maybe<StripeRefundWhereInput>;
};


export type MutationCreateOneStripeTerminalArgs = {
  data: StripeTerminalCreateInput;
};


export type MutationUpdateOneStripeTerminalArgs = {
  data: StripeTerminalUpdateInput;
  where: StripeTerminalWhereUniqueInput;
};


export type MutationDeleteOneStripeTerminalArgs = {
  where: StripeTerminalWhereUniqueInput;
};


export type MutationUpsertOneStripeTerminalArgs = {
  where: StripeTerminalWhereUniqueInput;
  create: StripeTerminalCreateInput;
  update: StripeTerminalUpdateInput;
};


export type MutationDeleteManyStripeTerminalArgs = {
  where?: Maybe<StripeTerminalWhereInput>;
};


export type MutationUpdateManyStripeTerminalArgs = {
  data: StripeTerminalUpdateManyMutationInput;
  where?: Maybe<StripeTerminalWhereInput>;
};


export type MutationCreateOneStripeTerminalLocationArgs = {
  data: StripeTerminalLocationCreateInput;
};


export type MutationUpdateOneStripeTerminalLocationArgs = {
  data: StripeTerminalLocationUpdateInput;
  where: StripeTerminalLocationWhereUniqueInput;
};


export type MutationDeleteOneStripeTerminalLocationArgs = {
  where: StripeTerminalLocationWhereUniqueInput;
};


export type MutationUpsertOneStripeTerminalLocationArgs = {
  where: StripeTerminalLocationWhereUniqueInput;
  create: StripeTerminalLocationCreateInput;
  update: StripeTerminalLocationUpdateInput;
};


export type MutationDeleteManyStripeTerminalLocationArgs = {
  where?: Maybe<StripeTerminalLocationWhereInput>;
};


export type MutationUpdateManyStripeTerminalLocationArgs = {
  data: StripeTerminalLocationUpdateManyMutationInput;
  where?: Maybe<StripeTerminalLocationWhereInput>;
};


export type MutationCreateOneTransactionArgs = {
  data: TransactionCreateInput;
};


export type MutationUpdateOneTransactionArgs = {
  data: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};


export type MutationDeleteOneTransactionArgs = {
  where: TransactionWhereUniqueInput;
};


export type MutationUpsertOneTransactionArgs = {
  where: TransactionWhereUniqueInput;
  create: TransactionCreateInput;
  update: TransactionUpdateInput;
};


export type MutationDeleteManyTransactionArgs = {
  where?: Maybe<TransactionWhereInput>;
};


export type MutationUpdateManyTransactionArgs = {
  data: TransactionUpdateManyMutationInput;
  where?: Maybe<TransactionWhereInput>;
};


export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationUpsertOneUserArgs = {
  where: UserWhereUniqueInput;
  create: UserCreateInput;
  update: UserUpdateInput;
};


export type MutationDeleteManyUserArgs = {
  where?: Maybe<UserWhereInput>;
};


export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: Maybe<UserWhereInput>;
};


export type MutationCreateOneUserAppointmentGroupingArgs = {
  data: UserAppointmentGroupingCreateInput;
};


export type MutationUpdateOneUserAppointmentGroupingArgs = {
  data: UserAppointmentGroupingUpdateInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};


export type MutationDeleteOneUserAppointmentGroupingArgs = {
  where: UserAppointmentGroupingWhereUniqueInput;
};


export type MutationUpsertOneUserAppointmentGroupingArgs = {
  where: UserAppointmentGroupingWhereUniqueInput;
  create: UserAppointmentGroupingCreateInput;
  update: UserAppointmentGroupingUpdateInput;
};


export type MutationDeleteManyUserAppointmentGroupingArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};


export type MutationUpdateManyUserAppointmentGroupingArgs = {
  data: UserAppointmentGroupingUpdateManyMutationInput;
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};


export type MutationCreateOneUserNotificationSettingArgs = {
  data: UserNotificationSettingCreateInput;
};


export type MutationUpdateOneUserNotificationSettingArgs = {
  data: UserNotificationSettingUpdateInput;
  where: UserNotificationSettingWhereUniqueInput;
};


export type MutationDeleteOneUserNotificationSettingArgs = {
  where: UserNotificationSettingWhereUniqueInput;
};


export type MutationUpsertOneUserNotificationSettingArgs = {
  where: UserNotificationSettingWhereUniqueInput;
  create: UserNotificationSettingCreateInput;
  update: UserNotificationSettingUpdateInput;
};


export type MutationDeleteManyUserNotificationSettingArgs = {
  where?: Maybe<UserNotificationSettingWhereInput>;
};


export type MutationUpdateManyUserNotificationSettingArgs = {
  data: UserNotificationSettingUpdateManyMutationInput;
  where?: Maybe<UserNotificationSettingWhereInput>;
};


export type MutationCreateOneUserSettingArgs = {
  data: UserSettingCreateInput;
};


export type MutationUpdateOneUserSettingArgs = {
  data: UserSettingUpdateInput;
  where: UserSettingWhereUniqueInput;
};


export type MutationDeleteOneUserSettingArgs = {
  where: UserSettingWhereUniqueInput;
};


export type MutationUpsertOneUserSettingArgs = {
  where: UserSettingWhereUniqueInput;
  create: UserSettingCreateInput;
  update: UserSettingUpdateInput;
};


export type MutationDeleteManyUserSettingArgs = {
  where?: Maybe<UserSettingWhereInput>;
};


export type MutationUpdateManyUserSettingArgs = {
  data: UserSettingUpdateManyMutationInput;
  where?: Maybe<UserSettingWhereInput>;
};


export type MutationCreateOneChannelFilterSelectionArgs = {
  data: ChannelFilterSelectionCreateInput;
};


export type MutationUpdateOneChannelFilterSelectionArgs = {
  data: ChannelFilterSelectionUpdateInput;
  where: ChannelFilterSelectionWhereUniqueInput;
};


export type MutationDeleteOneChannelFilterSelectionArgs = {
  where: ChannelFilterSelectionWhereUniqueInput;
};


export type MutationUpsertOneChannelFilterSelectionArgs = {
  where: ChannelFilterSelectionWhereUniqueInput;
  create: ChannelFilterSelectionCreateInput;
  update: ChannelFilterSelectionUpdateInput;
};


export type MutationDeleteManyChannelFilterSelectionArgs = {
  where?: Maybe<ChannelFilterSelectionWhereInput>;
};


export type MutationUpdateManyChannelFilterSelectionArgs = {
  data: ChannelFilterSelectionUpdateManyMutationInput;
  where?: Maybe<ChannelFilterSelectionWhereInput>;
};


export type MutationCreateOneUserChannelFilterSelectionArgs = {
  data: UserChannelFilterSelectionCreateInput;
};


export type MutationUpdateOneUserChannelFilterSelectionArgs = {
  data: UserChannelFilterSelectionUpdateInput;
  where: UserChannelFilterSelectionWhereUniqueInput;
};


export type MutationDeleteOneUserChannelFilterSelectionArgs = {
  where: UserChannelFilterSelectionWhereUniqueInput;
};


export type MutationUpsertOneUserChannelFilterSelectionArgs = {
  where: UserChannelFilterSelectionWhereUniqueInput;
  create: UserChannelFilterSelectionCreateInput;
  update: UserChannelFilterSelectionUpdateInput;
};


export type MutationDeleteManyUserChannelFilterSelectionArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
};


export type MutationUpdateManyUserChannelFilterSelectionArgs = {
  data: UserChannelFilterSelectionUpdateManyMutationInput;
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
};


export type MutationCreateOneVetAvailabilityArgs = {
  data: VetAvailabilityCreateInput;
};


export type MutationUpdateOneVetAvailabilityArgs = {
  data: VetAvailabilityUpdateInput;
  where: VetAvailabilityWhereUniqueInput;
};


export type MutationDeleteOneVetAvailabilityArgs = {
  where: VetAvailabilityWhereUniqueInput;
};


export type MutationUpsertOneVetAvailabilityArgs = {
  where: VetAvailabilityWhereUniqueInput;
  create: VetAvailabilityCreateInput;
  update: VetAvailabilityUpdateInput;
};


export type MutationDeleteManyVetAvailabilityArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
};


export type MutationUpdateManyVetAvailabilityArgs = {
  data: VetAvailabilityUpdateManyMutationInput;
  where?: Maybe<VetAvailabilityWhereInput>;
};


export type MutationCreateOneProviderAppointmentTypeRoomMapArgs = {
  data: ProviderAppointmentTypeRoomMapCreateInput;
};


export type MutationUpdateOneProviderAppointmentTypeRoomMapArgs = {
  data: ProviderAppointmentTypeRoomMapUpdateInput;
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};


export type MutationDeleteOneProviderAppointmentTypeRoomMapArgs = {
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};


export type MutationUpsertOneProviderAppointmentTypeRoomMapArgs = {
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
  create: ProviderAppointmentTypeRoomMapCreateInput;
  update: ProviderAppointmentTypeRoomMapUpdateInput;
};


export type MutationDeleteManyProviderAppointmentTypeRoomMapArgs = {
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
};


export type MutationUpdateManyProviderAppointmentTypeRoomMapArgs = {
  data: ProviderAppointmentTypeRoomMapUpdateManyMutationInput;
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
};


export type MutationCreateOneVetAvailabilityMapArgs = {
  data: VetAvailabilityMapCreateInput;
};


export type MutationUpdateOneVetAvailabilityMapArgs = {
  data: VetAvailabilityMapUpdateInput;
  where: VetAvailabilityMapWhereUniqueInput;
};


export type MutationDeleteOneVetAvailabilityMapArgs = {
  where: VetAvailabilityMapWhereUniqueInput;
};


export type MutationUpsertOneVetAvailabilityMapArgs = {
  where: VetAvailabilityMapWhereUniqueInput;
  create: VetAvailabilityMapCreateInput;
  update: VetAvailabilityMapUpdateInput;
};


export type MutationDeleteManyVetAvailabilityMapArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
};


export type MutationUpdateManyVetAvailabilityMapArgs = {
  data: VetAvailabilityMapUpdateManyMutationInput;
  where?: Maybe<VetAvailabilityMapWhereInput>;
};


export type MutationCreateOneVetAvailabilityClinicRoomMapArgs = {
  data: VetAvailabilityClinicRoomMapCreateInput;
};


export type MutationUpdateOneVetAvailabilityClinicRoomMapArgs = {
  data: VetAvailabilityClinicRoomMapUpdateInput;
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
};


export type MutationDeleteOneVetAvailabilityClinicRoomMapArgs = {
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
};


export type MutationUpsertOneVetAvailabilityClinicRoomMapArgs = {
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
  create: VetAvailabilityClinicRoomMapCreateInput;
  update: VetAvailabilityClinicRoomMapUpdateInput;
};


export type MutationDeleteManyVetAvailabilityClinicRoomMapArgs = {
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
};


export type MutationUpdateManyVetAvailabilityClinicRoomMapArgs = {
  data: VetAvailabilityClinicRoomMapUpdateManyMutationInput;
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
};


export type MutationCreateOneClientConnectIntegrationArgs = {
  data: ClientConnectIntegrationCreateInput;
};


export type MutationUpdateOneClientConnectIntegrationArgs = {
  data: ClientConnectIntegrationUpdateInput;
  where: ClientConnectIntegrationWhereUniqueInput;
};


export type MutationDeleteOneClientConnectIntegrationArgs = {
  where: ClientConnectIntegrationWhereUniqueInput;
};


export type MutationUpsertOneClientConnectIntegrationArgs = {
  where: ClientConnectIntegrationWhereUniqueInput;
  create: ClientConnectIntegrationCreateInput;
  update: ClientConnectIntegrationUpdateInput;
};


export type MutationDeleteManyClientConnectIntegrationArgs = {
  where?: Maybe<ClientConnectIntegrationWhereInput>;
};


export type MutationUpdateManyClientConnectIntegrationArgs = {
  data: ClientConnectIntegrationUpdateManyMutationInput;
  where?: Maybe<ClientConnectIntegrationWhereInput>;
};


export type MutationCreateOneVetInfoArgs = {
  data: VetInfoCreateInput;
};


export type MutationUpdateOneVetInfoArgs = {
  data: VetInfoUpdateInput;
  where: VetInfoWhereUniqueInput;
};


export type MutationDeleteOneVetInfoArgs = {
  where: VetInfoWhereUniqueInput;
};


export type MutationUpsertOneVetInfoArgs = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoCreateInput;
  update: VetInfoUpdateInput;
};


export type MutationDeleteManyVetInfoArgs = {
  where?: Maybe<VetInfoWhereInput>;
};


export type MutationUpdateManyVetInfoArgs = {
  data: VetInfoUpdateManyMutationInput;
  where?: Maybe<VetInfoWhereInput>;
};


export type MutationCreateOneWorkflowEventSettingArgs = {
  data: WorkflowEventSettingCreateInput;
};


export type MutationUpdateOneWorkflowEventSettingArgs = {
  data: WorkflowEventSettingUpdateInput;
  where: WorkflowEventSettingWhereUniqueInput;
};


export type MutationDeleteOneWorkflowEventSettingArgs = {
  where: WorkflowEventSettingWhereUniqueInput;
};


export type MutationUpsertOneWorkflowEventSettingArgs = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingCreateInput;
  update: WorkflowEventSettingUpdateInput;
};


export type MutationDeleteManyWorkflowEventSettingArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
};


export type MutationUpdateManyWorkflowEventSettingArgs = {
  data: WorkflowEventSettingUpdateManyMutationInput;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};


export type MutationCreateOneClinicOfficeHourArgs = {
  data: ClinicOfficeHourCreateInput;
};


export type MutationUpdateOneClinicOfficeHourArgs = {
  data: ClinicOfficeHourUpdateInput;
  where: ClinicOfficeHourWhereUniqueInput;
};


export type MutationDeleteOneClinicOfficeHourArgs = {
  where: ClinicOfficeHourWhereUniqueInput;
};


export type MutationUpsertOneClinicOfficeHourArgs = {
  where: ClinicOfficeHourWhereUniqueInput;
  create: ClinicOfficeHourCreateInput;
  update: ClinicOfficeHourUpdateInput;
};


export type MutationDeleteManyClinicOfficeHourArgs = {
  where?: Maybe<ClinicOfficeHourWhereInput>;
};


export type MutationUpdateManyClinicOfficeHourArgs = {
  data: ClinicOfficeHourUpdateManyMutationInput;
  where?: Maybe<ClinicOfficeHourWhereInput>;
};


export type MutationCreateOneEmailProviderLogArgs = {
  data: EmailProviderLogCreateInput;
};


export type MutationUpdateOneEmailProviderLogArgs = {
  data: EmailProviderLogUpdateInput;
  where: EmailProviderLogWhereUniqueInput;
};


export type MutationDeleteOneEmailProviderLogArgs = {
  where: EmailProviderLogWhereUniqueInput;
};


export type MutationUpsertOneEmailProviderLogArgs = {
  where: EmailProviderLogWhereUniqueInput;
  create: EmailProviderLogCreateInput;
  update: EmailProviderLogUpdateInput;
};


export type MutationDeleteManyEmailProviderLogArgs = {
  where?: Maybe<EmailProviderLogWhereInput>;
};


export type MutationUpdateManyEmailProviderLogArgs = {
  data: EmailProviderLogUpdateManyMutationInput;
  where?: Maybe<EmailProviderLogWhereInput>;
};


export type MutationCreateOneCallHistoryArgs = {
  data: CallHistoryCreateInput;
};


export type MutationUpdateOneCallHistoryArgs = {
  data: CallHistoryUpdateInput;
  where: CallHistoryWhereUniqueInput;
};


export type MutationDeleteOneCallHistoryArgs = {
  where: CallHistoryWhereUniqueInput;
};


export type MutationUpsertOneCallHistoryArgs = {
  where: CallHistoryWhereUniqueInput;
  create: CallHistoryCreateInput;
  update: CallHistoryUpdateInput;
};


export type MutationDeleteManyCallHistoryArgs = {
  where?: Maybe<CallHistoryWhereInput>;
};


export type MutationUpdateManyCallHistoryArgs = {
  data: CallHistoryUpdateManyMutationInput;
  where?: Maybe<CallHistoryWhereInput>;
};


export type MutationCreateOneCallParticipantArgs = {
  data: CallParticipantCreateInput;
};


export type MutationUpdateOneCallParticipantArgs = {
  data: CallParticipantUpdateInput;
  where: CallParticipantWhereUniqueInput;
};


export type MutationDeleteOneCallParticipantArgs = {
  where: CallParticipantWhereUniqueInput;
};


export type MutationUpsertOneCallParticipantArgs = {
  where: CallParticipantWhereUniqueInput;
  create: CallParticipantCreateInput;
  update: CallParticipantUpdateInput;
};


export type MutationDeleteManyCallParticipantArgs = {
  where?: Maybe<CallParticipantWhereInput>;
};


export type MutationUpdateManyCallParticipantArgs = {
  data: CallParticipantUpdateManyMutationInput;
  where?: Maybe<CallParticipantWhereInput>;
};


export type MutationCreateOneSmsNotificationStatusArgs = {
  data: SmsNotificationStatusCreateInput;
};


export type MutationUpdateOneSmsNotificationStatusArgs = {
  data: SmsNotificationStatusUpdateInput;
  where: SmsNotificationStatusWhereUniqueInput;
};


export type MutationDeleteOneSmsNotificationStatusArgs = {
  where: SmsNotificationStatusWhereUniqueInput;
};


export type MutationUpsertOneSmsNotificationStatusArgs = {
  where: SmsNotificationStatusWhereUniqueInput;
  create: SmsNotificationStatusCreateInput;
  update: SmsNotificationStatusUpdateInput;
};


export type MutationDeleteManySmsNotificationStatusArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
};


export type MutationUpdateManySmsNotificationStatusArgs = {
  data: SmsNotificationStatusUpdateManyMutationInput;
  where?: Maybe<SmsNotificationStatusWhereInput>;
};


export type MutationCreateOneFeatureFlagArgs = {
  data: FeatureFlagCreateInput;
};


export type MutationUpdateOneFeatureFlagArgs = {
  data: FeatureFlagUpdateInput;
  where: FeatureFlagWhereUniqueInput;
};


export type MutationDeleteOneFeatureFlagArgs = {
  where: FeatureFlagWhereUniqueInput;
};


export type MutationUpsertOneFeatureFlagArgs = {
  where: FeatureFlagWhereUniqueInput;
  create: FeatureFlagCreateInput;
  update: FeatureFlagUpdateInput;
};


export type MutationDeleteManyFeatureFlagArgs = {
  where?: Maybe<FeatureFlagWhereInput>;
};


export type MutationUpdateManyFeatureFlagArgs = {
  data: FeatureFlagUpdateManyMutationInput;
  where?: Maybe<FeatureFlagWhereInput>;
};


export type MutationCreateOneCareAuthorizationRequestArgs = {
  data: CareAuthorizationRequestCreateInput;
};


export type MutationUpdateOneCareAuthorizationRequestArgs = {
  data: CareAuthorizationRequestUpdateInput;
  where: CareAuthorizationRequestWhereUniqueInput;
};


export type MutationDeleteOneCareAuthorizationRequestArgs = {
  where: CareAuthorizationRequestWhereUniqueInput;
};


export type MutationUpsertOneCareAuthorizationRequestArgs = {
  where: CareAuthorizationRequestWhereUniqueInput;
  create: CareAuthorizationRequestCreateInput;
  update: CareAuthorizationRequestUpdateInput;
};


export type MutationDeleteManyCareAuthorizationRequestArgs = {
  where?: Maybe<CareAuthorizationRequestWhereInput>;
};


export type MutationUpdateManyCareAuthorizationRequestArgs = {
  data: CareAuthorizationRequestUpdateManyMutationInput;
  where?: Maybe<CareAuthorizationRequestWhereInput>;
};


export type MutationCreateOneCareBenefitArgs = {
  data: CareBenefitCreateInput;
};


export type MutationUpdateOneCareBenefitArgs = {
  data: CareBenefitUpdateInput;
  where: CareBenefitWhereUniqueInput;
};


export type MutationDeleteOneCareBenefitArgs = {
  where: CareBenefitWhereUniqueInput;
};


export type MutationUpsertOneCareBenefitArgs = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitCreateInput;
  update: CareBenefitUpdateInput;
};


export type MutationDeleteManyCareBenefitArgs = {
  where?: Maybe<CareBenefitWhereInput>;
};


export type MutationUpdateManyCareBenefitArgs = {
  data: CareBenefitUpdateManyMutationInput;
  where?: Maybe<CareBenefitWhereInput>;
};


export type MutationCreateOneCarePlanArgs = {
  data: CarePlanCreateInput;
};


export type MutationUpdateOneCarePlanArgs = {
  data: CarePlanUpdateInput;
  where: CarePlanWhereUniqueInput;
};


export type MutationDeleteOneCarePlanArgs = {
  where: CarePlanWhereUniqueInput;
};


export type MutationUpsertOneCarePlanArgs = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanCreateInput;
  update: CarePlanUpdateInput;
};


export type MutationDeleteManyCarePlanArgs = {
  where?: Maybe<CarePlanWhereInput>;
};


export type MutationUpdateManyCarePlanArgs = {
  data: CarePlanUpdateManyMutationInput;
  where?: Maybe<CarePlanWhereInput>;
};


export type MutationCreateOneCareAddonPackageArgs = {
  data: CareAddonPackageCreateInput;
};


export type MutationUpdateOneCareAddonPackageArgs = {
  data: CareAddonPackageUpdateInput;
  where: CareAddonPackageWhereUniqueInput;
};


export type MutationDeleteOneCareAddonPackageArgs = {
  where: CareAddonPackageWhereUniqueInput;
};


export type MutationUpsertOneCareAddonPackageArgs = {
  where: CareAddonPackageWhereUniqueInput;
  create: CareAddonPackageCreateInput;
  update: CareAddonPackageUpdateInput;
};


export type MutationDeleteManyCareAddonPackageArgs = {
  where?: Maybe<CareAddonPackageWhereInput>;
};


export type MutationUpdateManyCareAddonPackageArgs = {
  data: CareAddonPackageUpdateManyMutationInput;
  where?: Maybe<CareAddonPackageWhereInput>;
};


export type MutationCreateOneCarePlanBenefitEnrollmentCarryoverArgs = {
  data: CarePlanBenefitEnrollmentCarryoverCreateInput;
};


export type MutationUpdateOneCarePlanBenefitEnrollmentCarryoverArgs = {
  data: CarePlanBenefitEnrollmentCarryoverUpdateInput;
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};


export type MutationDeleteOneCarePlanBenefitEnrollmentCarryoverArgs = {
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};


export type MutationUpsertOneCarePlanBenefitEnrollmentCarryoverArgs = {
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
  create: CarePlanBenefitEnrollmentCarryoverCreateInput;
  update: CarePlanBenefitEnrollmentCarryoverUpdateInput;
};


export type MutationDeleteManyCarePlanBenefitEnrollmentCarryoverArgs = {
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
};


export type MutationUpdateManyCarePlanBenefitEnrollmentCarryoverArgs = {
  data: CarePlanBenefitEnrollmentCarryoverUpdateManyMutationInput;
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
};


export type MutationCreateOneCarePlanBenefitArgs = {
  data: CarePlanBenefitCreateInput;
};


export type MutationUpdateOneCarePlanBenefitArgs = {
  data: CarePlanBenefitUpdateInput;
  where: CarePlanBenefitWhereUniqueInput;
};


export type MutationDeleteOneCarePlanBenefitArgs = {
  where: CarePlanBenefitWhereUniqueInput;
};


export type MutationUpsertOneCarePlanBenefitArgs = {
  where: CarePlanBenefitWhereUniqueInput;
  create: CarePlanBenefitCreateInput;
  update: CarePlanBenefitUpdateInput;
};


export type MutationDeleteManyCarePlanBenefitArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
};


export type MutationUpdateManyCarePlanBenefitArgs = {
  data: CarePlanBenefitUpdateManyMutationInput;
  where?: Maybe<CarePlanBenefitWhereInput>;
};


export type MutationCreateOneCareAddonPackageBenefitArgs = {
  data: CareAddonPackageBenefitCreateInput;
};


export type MutationUpdateOneCareAddonPackageBenefitArgs = {
  data: CareAddonPackageBenefitUpdateInput;
  where: CareAddonPackageBenefitWhereUniqueInput;
};


export type MutationDeleteOneCareAddonPackageBenefitArgs = {
  where: CareAddonPackageBenefitWhereUniqueInput;
};


export type MutationUpsertOneCareAddonPackageBenefitArgs = {
  where: CareAddonPackageBenefitWhereUniqueInput;
  create: CareAddonPackageBenefitCreateInput;
  update: CareAddonPackageBenefitUpdateInput;
};


export type MutationDeleteManyCareAddonPackageBenefitArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
};


export type MutationUpdateManyCareAddonPackageBenefitArgs = {
  data: CareAddonPackageBenefitUpdateManyMutationInput;
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
};


export type MutationCreateOneEnrollmentSupportEventArgs = {
  data: EnrollmentSupportEventCreateInput;
};


export type MutationUpdateOneEnrollmentSupportEventArgs = {
  data: EnrollmentSupportEventUpdateInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};


export type MutationDeleteOneEnrollmentSupportEventArgs = {
  where: EnrollmentSupportEventWhereUniqueInput;
};


export type MutationUpsertOneEnrollmentSupportEventArgs = {
  where: EnrollmentSupportEventWhereUniqueInput;
  create: EnrollmentSupportEventCreateInput;
  update: EnrollmentSupportEventUpdateInput;
};


export type MutationDeleteManyEnrollmentSupportEventArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type MutationUpdateManyEnrollmentSupportEventArgs = {
  data: EnrollmentSupportEventUpdateManyMutationInput;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type MutationCreateOneCarePlanEnrollmentArgs = {
  data: CarePlanEnrollmentCreateInput;
};


export type MutationUpdateOneCarePlanEnrollmentArgs = {
  data: CarePlanEnrollmentUpdateInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};


export type MutationDeleteOneCarePlanEnrollmentArgs = {
  where: CarePlanEnrollmentWhereUniqueInput;
};


export type MutationUpsertOneCarePlanEnrollmentArgs = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentCreateInput;
  update: CarePlanEnrollmentUpdateInput;
};


export type MutationDeleteManyCarePlanEnrollmentArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};


export type MutationUpdateManyCarePlanEnrollmentArgs = {
  data: CarePlanEnrollmentUpdateManyMutationInput;
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};


export type MutationCreateOneCareAddonEnrollmentArgs = {
  data: CareAddonEnrollmentCreateInput;
};


export type MutationUpdateOneCareAddonEnrollmentArgs = {
  data: CareAddonEnrollmentUpdateInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};


export type MutationDeleteOneCareAddonEnrollmentArgs = {
  where: CareAddonEnrollmentWhereUniqueInput;
};


export type MutationUpsertOneCareAddonEnrollmentArgs = {
  where: CareAddonEnrollmentWhereUniqueInput;
  create: CareAddonEnrollmentCreateInput;
  update: CareAddonEnrollmentUpdateInput;
};


export type MutationDeleteManyCareAddonEnrollmentArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
};


export type MutationUpdateManyCareAddonEnrollmentArgs = {
  data: CareAddonEnrollmentUpdateManyMutationInput;
  where?: Maybe<CareAddonEnrollmentWhereInput>;
};


export type MutationCreateOneCareEnrollmentToStripeInvoiceItemArgs = {
  data: CareEnrollmentToStripeInvoiceItemCreateInput;
};


export type MutationUpdateOneCareEnrollmentToStripeInvoiceItemArgs = {
  data: CareEnrollmentToStripeInvoiceItemUpdateInput;
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};


export type MutationDeleteOneCareEnrollmentToStripeInvoiceItemArgs = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};


export type MutationUpsertOneCareEnrollmentToStripeInvoiceItemArgs = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  create: CareEnrollmentToStripeInvoiceItemCreateInput;
  update: CareEnrollmentToStripeInvoiceItemUpdateInput;
};


export type MutationDeleteManyCareEnrollmentToStripeInvoiceItemArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};


export type MutationUpdateManyCareEnrollmentToStripeInvoiceItemArgs = {
  data: CareEnrollmentToStripeInvoiceItemUpdateManyMutationInput;
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};


export type MutationCreateOneCareBenefitUsageArgs = {
  data: CareBenefitUsageCreateInput;
};


export type MutationUpdateOneCareBenefitUsageArgs = {
  data: CareBenefitUsageUpdateInput;
  where: CareBenefitUsageWhereUniqueInput;
};


export type MutationDeleteOneCareBenefitUsageArgs = {
  where: CareBenefitUsageWhereUniqueInput;
};


export type MutationUpsertOneCareBenefitUsageArgs = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageCreateInput;
  update: CareBenefitUsageUpdateInput;
};


export type MutationDeleteManyCareBenefitUsageArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
};


export type MutationUpdateManyCareBenefitUsageArgs = {
  data: CareBenefitUsageUpdateManyMutationInput;
  where?: Maybe<CareBenefitUsageWhereInput>;
};


export type MutationCreateOneCareBenefitUsageToInvoiceLineItemArgs = {
  data: CareBenefitUsageToInvoiceLineItemCreateInput;
};


export type MutationUpdateOneCareBenefitUsageToInvoiceLineItemArgs = {
  data: CareBenefitUsageToInvoiceLineItemUpdateInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};


export type MutationDeleteOneCareBenefitUsageToInvoiceLineItemArgs = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};


export type MutationUpsertOneCareBenefitUsageToInvoiceLineItemArgs = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  create: CareBenefitUsageToInvoiceLineItemCreateInput;
  update: CareBenefitUsageToInvoiceLineItemUpdateInput;
};


export type MutationDeleteManyCareBenefitUsageToInvoiceLineItemArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};


export type MutationUpdateManyCareBenefitUsageToInvoiceLineItemArgs = {
  data: CareBenefitUsageToInvoiceLineItemUpdateManyMutationInput;
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};


export type MutationCreateOneCareStripeSubscriptionArgs = {
  data: CareStripeSubscriptionCreateInput;
};


export type MutationUpdateOneCareStripeSubscriptionArgs = {
  data: CareStripeSubscriptionUpdateInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};


export type MutationDeleteOneCareStripeSubscriptionArgs = {
  where: CareStripeSubscriptionWhereUniqueInput;
};


export type MutationUpsertOneCareStripeSubscriptionArgs = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionCreateInput;
  update: CareStripeSubscriptionUpdateInput;
};


export type MutationDeleteManyCareStripeSubscriptionArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
};


export type MutationUpdateManyCareStripeSubscriptionArgs = {
  data: CareStripeSubscriptionUpdateManyMutationInput;
  where?: Maybe<CareStripeSubscriptionWhereInput>;
};


export type MutationCreateOneStripeInvoiceArgs = {
  data: StripeInvoiceCreateInput;
};


export type MutationUpdateOneStripeInvoiceArgs = {
  data: StripeInvoiceUpdateInput;
  where: StripeInvoiceWhereUniqueInput;
};


export type MutationDeleteOneStripeInvoiceArgs = {
  where: StripeInvoiceWhereUniqueInput;
};


export type MutationUpsertOneStripeInvoiceArgs = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceCreateInput;
  update: StripeInvoiceUpdateInput;
};


export type MutationDeleteManyStripeInvoiceArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
};


export type MutationUpdateManyStripeInvoiceArgs = {
  data: StripeInvoiceUpdateManyMutationInput;
  where?: Maybe<StripeInvoiceWhereInput>;
};


export type MutationCreateOneStripeInvoiceItemArgs = {
  data: StripeInvoiceItemCreateInput;
};


export type MutationUpdateOneStripeInvoiceItemArgs = {
  data: StripeInvoiceItemUpdateInput;
  where: StripeInvoiceItemWhereUniqueInput;
};


export type MutationDeleteOneStripeInvoiceItemArgs = {
  where: StripeInvoiceItemWhereUniqueInput;
};


export type MutationUpsertOneStripeInvoiceItemArgs = {
  where: StripeInvoiceItemWhereUniqueInput;
  create: StripeInvoiceItemCreateInput;
  update: StripeInvoiceItemUpdateInput;
};


export type MutationDeleteManyStripeInvoiceItemArgs = {
  where?: Maybe<StripeInvoiceItemWhereInput>;
};


export type MutationUpdateManyStripeInvoiceItemArgs = {
  data: StripeInvoiceItemUpdateManyMutationInput;
  where?: Maybe<StripeInvoiceItemWhereInput>;
};


export type MutationCreateOneStripeCustomerArgs = {
  data: StripeCustomerCreateInput;
};


export type MutationUpdateOneStripeCustomerArgs = {
  data: StripeCustomerUpdateInput;
  where: StripeCustomerWhereUniqueInput;
};


export type MutationDeleteOneStripeCustomerArgs = {
  where: StripeCustomerWhereUniqueInput;
};


export type MutationUpsertOneStripeCustomerArgs = {
  where: StripeCustomerWhereUniqueInput;
  create: StripeCustomerCreateInput;
  update: StripeCustomerUpdateInput;
};


export type MutationDeleteManyStripeCustomerArgs = {
  where?: Maybe<StripeCustomerWhereInput>;
};


export type MutationUpdateManyStripeCustomerArgs = {
  data: StripeCustomerUpdateManyMutationInput;
  where?: Maybe<StripeCustomerWhereInput>;
};


export type MutationCreateOneVaccinationArgs = {
  data: VaccinationCreateInput;
};


export type MutationUpdateOneVaccinationArgs = {
  data: VaccinationUpdateInput;
  where: VaccinationWhereUniqueInput;
};


export type MutationDeleteOneVaccinationArgs = {
  where: VaccinationWhereUniqueInput;
};


export type MutationUpsertOneVaccinationArgs = {
  where: VaccinationWhereUniqueInput;
  create: VaccinationCreateInput;
  update: VaccinationUpdateInput;
};


export type MutationDeleteManyVaccinationArgs = {
  where?: Maybe<VaccinationWhereInput>;
};


export type MutationUpdateManyVaccinationArgs = {
  data: VaccinationUpdateManyMutationInput;
  where?: Maybe<VaccinationWhereInput>;
};


export type MutationCreateOnePimsWritebackLogEntryArgs = {
  data: PimsWritebackLogEntryCreateInput;
};


export type MutationUpdateOnePimsWritebackLogEntryArgs = {
  data: PimsWritebackLogEntryUpdateInput;
  where: PimsWritebackLogEntryWhereUniqueInput;
};


export type MutationDeleteOnePimsWritebackLogEntryArgs = {
  where: PimsWritebackLogEntryWhereUniqueInput;
};


export type MutationUpsertOnePimsWritebackLogEntryArgs = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  create: PimsWritebackLogEntryCreateInput;
  update: PimsWritebackLogEntryUpdateInput;
};


export type MutationDeleteManyPimsWritebackLogEntryArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
};


export type MutationUpdateManyPimsWritebackLogEntryArgs = {
  data: PimsWritebackLogEntryUpdateManyMutationInput;
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
};


export type MutationCreateOneInvoicePimsWritebackLogEntryArgs = {
  data: InvoicePimsWritebackLogEntryCreateInput;
};


export type MutationUpdateOneInvoicePimsWritebackLogEntryArgs = {
  data: InvoicePimsWritebackLogEntryUpdateInput;
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
};


export type MutationDeleteOneInvoicePimsWritebackLogEntryArgs = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
};


export type MutationUpsertOneInvoicePimsWritebackLogEntryArgs = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
  create: InvoicePimsWritebackLogEntryCreateInput;
  update: InvoicePimsWritebackLogEntryUpdateInput;
};


export type MutationDeleteManyInvoicePimsWritebackLogEntryArgs = {
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};


export type MutationUpdateManyInvoicePimsWritebackLogEntryArgs = {
  data: InvoicePimsWritebackLogEntryUpdateManyMutationInput;
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};


export type MutationCreateOneOrganizationCareBenefitToClinicServiceArgs = {
  data: OrganizationCareBenefitToClinicServiceCreateInput;
};


export type MutationUpdateOneOrganizationCareBenefitToClinicServiceArgs = {
  data: OrganizationCareBenefitToClinicServiceUpdateInput;
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};


export type MutationDeleteOneOrganizationCareBenefitToClinicServiceArgs = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};


export type MutationUpsertOneOrganizationCareBenefitToClinicServiceArgs = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
  create: OrganizationCareBenefitToClinicServiceCreateInput;
  update: OrganizationCareBenefitToClinicServiceUpdateInput;
};


export type MutationDeleteManyOrganizationCareBenefitToClinicServiceArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
};


export type MutationCreateOneOrganizationCareBenefitToClinicServiceCategoryArgs = {
  data: OrganizationCareBenefitToClinicServiceCategoryCreateInput;
};


export type MutationUpdateOneOrganizationCareBenefitToClinicServiceCategoryArgs = {
  data: OrganizationCareBenefitToClinicServiceCategoryUpdateInput;
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
};


export type MutationDeleteOneOrganizationCareBenefitToClinicServiceCategoryArgs = {
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
};


export type MutationUpsertOneOrganizationCareBenefitToClinicServiceCategoryArgs = {
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
  create: OrganizationCareBenefitToClinicServiceCategoryCreateInput;
  update: OrganizationCareBenefitToClinicServiceCategoryUpdateInput;
};


export type MutationDeleteManyOrganizationCareBenefitToClinicServiceCategoryArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
};


export type MutationUpdateManyOrganizationCareBenefitToClinicServiceCategoryArgs = {
  data: OrganizationCareBenefitToClinicServiceCategoryUpdateManyMutationInput;
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
};


export type MutationCreateOneMessageTemplateArgs = {
  data: MessageTemplateCreateInput;
};


export type MutationUpdateOneMessageTemplateArgs = {
  data: MessageTemplateUpdateInput;
  where: MessageTemplateWhereUniqueInput;
};


export type MutationDeleteOneMessageTemplateArgs = {
  where: MessageTemplateWhereUniqueInput;
};


export type MutationUpsertOneMessageTemplateArgs = {
  where: MessageTemplateWhereUniqueInput;
  create: MessageTemplateCreateInput;
  update: MessageTemplateUpdateInput;
};


export type MutationDeleteManyMessageTemplateArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
};


export type MutationUpdateManyMessageTemplateArgs = {
  data: MessageTemplateUpdateManyMutationInput;
  where?: Maybe<MessageTemplateWhereInput>;
};


export type MutationCreateOneNotificationEventToMessageArgs = {
  data: NotificationEventToMessageCreateInput;
};


export type MutationUpdateOneNotificationEventToMessageArgs = {
  data: NotificationEventToMessageUpdateInput;
  where: NotificationEventToMessageWhereUniqueInput;
};


export type MutationDeleteOneNotificationEventToMessageArgs = {
  where: NotificationEventToMessageWhereUniqueInput;
};


export type MutationUpsertOneNotificationEventToMessageArgs = {
  where: NotificationEventToMessageWhereUniqueInput;
  create: NotificationEventToMessageCreateInput;
  update: NotificationEventToMessageUpdateInput;
};


export type MutationDeleteManyNotificationEventToMessageArgs = {
  where?: Maybe<NotificationEventToMessageWhereInput>;
};


export type MutationUpdateManyNotificationEventToMessageArgs = {
  data: NotificationEventToMessageUpdateManyMutationInput;
  where?: Maybe<NotificationEventToMessageWhereInput>;
};


export type MutationCreateOneRulesArgs = {
  data: RulesCreateInput;
};


export type MutationUpdateOneRulesArgs = {
  data: RulesUpdateInput;
  where: RulesWhereUniqueInput;
};


export type MutationDeleteOneRulesArgs = {
  where: RulesWhereUniqueInput;
};


export type MutationUpsertOneRulesArgs = {
  where: RulesWhereUniqueInput;
  create: RulesCreateInput;
  update: RulesUpdateInput;
};


export type MutationDeleteManyRulesArgs = {
  where?: Maybe<RulesWhereInput>;
};


export type MutationUpdateManyRulesArgs = {
  data: RulesUpdateManyMutationInput;
  where?: Maybe<RulesWhereInput>;
};


export type MutationCreateOneAutomationRunArgs = {
  data: AutomationRunCreateInput;
};


export type MutationUpdateOneAutomationRunArgs = {
  data: AutomationRunUpdateInput;
  where: AutomationRunWhereUniqueInput;
};


export type MutationDeleteOneAutomationRunArgs = {
  where: AutomationRunWhereUniqueInput;
};


export type MutationUpsertOneAutomationRunArgs = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunCreateInput;
  update: AutomationRunUpdateInput;
};


export type MutationDeleteManyAutomationRunArgs = {
  where?: Maybe<AutomationRunWhereInput>;
};


export type MutationUpdateManyAutomationRunArgs = {
  data: AutomationRunUpdateManyMutationInput;
  where?: Maybe<AutomationRunWhereInput>;
};


export type MutationCreateOneAutomationRunActionArgs = {
  data: AutomationRunActionCreateInput;
};


export type MutationUpdateOneAutomationRunActionArgs = {
  data: AutomationRunActionUpdateInput;
  where: AutomationRunActionWhereUniqueInput;
};


export type MutationDeleteOneAutomationRunActionArgs = {
  where: AutomationRunActionWhereUniqueInput;
};


export type MutationUpsertOneAutomationRunActionArgs = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionCreateInput;
  update: AutomationRunActionUpdateInput;
};


export type MutationDeleteManyAutomationRunActionArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
};


export type MutationUpdateManyAutomationRunActionArgs = {
  data: AutomationRunActionUpdateManyMutationInput;
  where?: Maybe<AutomationRunActionWhereInput>;
};


export type MutationCreateOneEmergencyClinicsArgs = {
  data: EmergencyClinicsCreateInput;
};


export type MutationUpdateOneEmergencyClinicsArgs = {
  data: EmergencyClinicsUpdateInput;
  where: EmergencyClinicsWhereUniqueInput;
};


export type MutationDeleteOneEmergencyClinicsArgs = {
  where: EmergencyClinicsWhereUniqueInput;
};


export type MutationUpsertOneEmergencyClinicsArgs = {
  where: EmergencyClinicsWhereUniqueInput;
  create: EmergencyClinicsCreateInput;
  update: EmergencyClinicsUpdateInput;
};


export type MutationDeleteManyEmergencyClinicsArgs = {
  where?: Maybe<EmergencyClinicsWhereInput>;
};


export type MutationUpdateManyEmergencyClinicsArgs = {
  data: EmergencyClinicsUpdateManyMutationInput;
  where?: Maybe<EmergencyClinicsWhereInput>;
};


export type MutationCreateOneCareEnrollmentPaymentArgs = {
  data: CareEnrollmentPaymentCreateInput;
};


export type MutationUpdateOneCareEnrollmentPaymentArgs = {
  data: CareEnrollmentPaymentUpdateInput;
  where: CareEnrollmentPaymentWhereUniqueInput;
};


export type MutationDeleteOneCareEnrollmentPaymentArgs = {
  where: CareEnrollmentPaymentWhereUniqueInput;
};


export type MutationUpsertOneCareEnrollmentPaymentArgs = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  create: CareEnrollmentPaymentCreateInput;
  update: CareEnrollmentPaymentUpdateInput;
};


export type MutationDeleteManyCareEnrollmentPaymentArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
};


export type MutationUpdateManyCareEnrollmentPaymentArgs = {
  data: CareEnrollmentPaymentUpdateManyMutationInput;
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
};


export type MutationCreateOneMassTextAlertArgs = {
  data: MassTextAlertCreateInput;
};


export type MutationUpdateOneMassTextAlertArgs = {
  data: MassTextAlertUpdateInput;
  where: MassTextAlertWhereUniqueInput;
};


export type MutationDeleteOneMassTextAlertArgs = {
  where: MassTextAlertWhereUniqueInput;
};


export type MutationUpsertOneMassTextAlertArgs = {
  where: MassTextAlertWhereUniqueInput;
  create: MassTextAlertCreateInput;
  update: MassTextAlertUpdateInput;
};


export type MutationDeleteManyMassTextAlertArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
};


export type MutationUpdateManyMassTextAlertArgs = {
  data: MassTextAlertUpdateManyMutationInput;
  where?: Maybe<MassTextAlertWhereInput>;
};


export type MutationCreateOneMassTextAlertEntryArgs = {
  data: MassTextAlertEntryCreateInput;
};


export type MutationUpdateOneMassTextAlertEntryArgs = {
  data: MassTextAlertEntryUpdateInput;
  where: MassTextAlertEntryWhereUniqueInput;
};


export type MutationDeleteOneMassTextAlertEntryArgs = {
  where: MassTextAlertEntryWhereUniqueInput;
};


export type MutationUpsertOneMassTextAlertEntryArgs = {
  where: MassTextAlertEntryWhereUniqueInput;
  create: MassTextAlertEntryCreateInput;
  update: MassTextAlertEntryUpdateInput;
};


export type MutationDeleteManyMassTextAlertEntryArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
};


export type MutationUpdateManyMassTextAlertEntryArgs = {
  data: MassTextAlertEntryUpdateManyMutationInput;
  where?: Maybe<MassTextAlertEntryWhereInput>;
};


export type MutationCreateOneMassTextAlertEntryAppointmentArgs = {
  data: MassTextAlertEntryAppointmentCreateInput;
};


export type MutationUpdateOneMassTextAlertEntryAppointmentArgs = {
  data: MassTextAlertEntryAppointmentUpdateInput;
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};


export type MutationDeleteOneMassTextAlertEntryAppointmentArgs = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};


export type MutationUpsertOneMassTextAlertEntryAppointmentArgs = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
  create: MassTextAlertEntryAppointmentCreateInput;
  update: MassTextAlertEntryAppointmentUpdateInput;
};


export type MutationDeleteManyMassTextAlertEntryAppointmentArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};


export type MutationUpdateManyMassTextAlertEntryAppointmentArgs = {
  data: MassTextAlertEntryAppointmentUpdateManyMutationInput;
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};


export type MutationCreateOnePetPortalSettingArgs = {
  data: PetPortalSettingCreateInput;
};


export type MutationUpdateOnePetPortalSettingArgs = {
  data: PetPortalSettingUpdateInput;
  where: PetPortalSettingWhereUniqueInput;
};


export type MutationDeleteOnePetPortalSettingArgs = {
  where: PetPortalSettingWhereUniqueInput;
};


export type MutationUpsertOnePetPortalSettingArgs = {
  where: PetPortalSettingWhereUniqueInput;
  create: PetPortalSettingCreateInput;
  update: PetPortalSettingUpdateInput;
};


export type MutationDeleteManyPetPortalSettingArgs = {
  where?: Maybe<PetPortalSettingWhereInput>;
};


export type MutationUpdateManyPetPortalSettingArgs = {
  data: PetPortalSettingUpdateManyMutationInput;
  where?: Maybe<PetPortalSettingWhereInput>;
};


export type MutationCreateOnePrescriptionArgs = {
  data: PrescriptionCreateInput;
};


export type MutationUpdateOnePrescriptionArgs = {
  data: PrescriptionUpdateInput;
  where: PrescriptionWhereUniqueInput;
};


export type MutationDeleteOnePrescriptionArgs = {
  where: PrescriptionWhereUniqueInput;
};


export type MutationUpsertOnePrescriptionArgs = {
  where: PrescriptionWhereUniqueInput;
  create: PrescriptionCreateInput;
  update: PrescriptionUpdateInput;
};


export type MutationDeleteManyPrescriptionArgs = {
  where?: Maybe<PrescriptionWhereInput>;
};


export type MutationUpdateManyPrescriptionArgs = {
  data: PrescriptionUpdateManyMutationInput;
  where?: Maybe<PrescriptionWhereInput>;
};


export type MutationCreateOneClinicPhoneNumberArgs = {
  data: ClinicPhoneNumberCreateInput;
};


export type MutationUpdateOneClinicPhoneNumberArgs = {
  data: ClinicPhoneNumberUpdateInput;
  where: ClinicPhoneNumberWhereUniqueInput;
};


export type MutationDeleteOneClinicPhoneNumberArgs = {
  where: ClinicPhoneNumberWhereUniqueInput;
};


export type MutationUpsertOneClinicPhoneNumberArgs = {
  where: ClinicPhoneNumberWhereUniqueInput;
  create: ClinicPhoneNumberCreateInput;
  update: ClinicPhoneNumberUpdateInput;
};


export type MutationDeleteManyClinicPhoneNumberArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
};


export type MutationUpdateManyClinicPhoneNumberArgs = {
  data: ClinicPhoneNumberUpdateManyMutationInput;
  where?: Maybe<ClinicPhoneNumberWhereInput>;
};


export type MutationCreateOneClinicOnboardingArgs = {
  data: ClinicOnboardingCreateInput;
};


export type MutationUpdateOneClinicOnboardingArgs = {
  data: ClinicOnboardingUpdateInput;
  where: ClinicOnboardingWhereUniqueInput;
};


export type MutationDeleteOneClinicOnboardingArgs = {
  where: ClinicOnboardingWhereUniqueInput;
};


export type MutationUpsertOneClinicOnboardingArgs = {
  where: ClinicOnboardingWhereUniqueInput;
  create: ClinicOnboardingCreateInput;
  update: ClinicOnboardingUpdateInput;
};


export type MutationDeleteManyClinicOnboardingArgs = {
  where?: Maybe<ClinicOnboardingWhereInput>;
};


export type MutationUpdateManyClinicOnboardingArgs = {
  data: ClinicOnboardingUpdateManyMutationInput;
  where?: Maybe<ClinicOnboardingWhereInput>;
};


export type MutationCreateOneSurveyAppointmentTypeSettingArgs = {
  data: SurveyAppointmentTypeSettingCreateInput;
};


export type MutationUpdateOneSurveyAppointmentTypeSettingArgs = {
  data: SurveyAppointmentTypeSettingUpdateInput;
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
};


export type MutationDeleteOneSurveyAppointmentTypeSettingArgs = {
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
};


export type MutationUpsertOneSurveyAppointmentTypeSettingArgs = {
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
  create: SurveyAppointmentTypeSettingCreateInput;
  update: SurveyAppointmentTypeSettingUpdateInput;
};


export type MutationDeleteManySurveyAppointmentTypeSettingArgs = {
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
};


export type MutationUpdateManySurveyAppointmentTypeSettingArgs = {
  data: SurveyAppointmentTypeSettingUpdateManyMutationInput;
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
};


export type MutationCreateOneSurveySettingArgs = {
  data: SurveySettingCreateInput;
};


export type MutationUpdateOneSurveySettingArgs = {
  data: SurveySettingUpdateInput;
  where: SurveySettingWhereUniqueInput;
};


export type MutationDeleteOneSurveySettingArgs = {
  where: SurveySettingWhereUniqueInput;
};


export type MutationUpsertOneSurveySettingArgs = {
  where: SurveySettingWhereUniqueInput;
  create: SurveySettingCreateInput;
  update: SurveySettingUpdateInput;
};


export type MutationDeleteManySurveySettingArgs = {
  where?: Maybe<SurveySettingWhereInput>;
};


export type MutationUpdateManySurveySettingArgs = {
  data: SurveySettingUpdateManyMutationInput;
  where?: Maybe<SurveySettingWhereInput>;
};


export type MutationCreateOneSurveyResultArgs = {
  data: SurveyResultCreateInput;
};


export type MutationUpdateOneSurveyResultArgs = {
  data: SurveyResultUpdateInput;
  where: SurveyResultWhereUniqueInput;
};


export type MutationDeleteOneSurveyResultArgs = {
  where: SurveyResultWhereUniqueInput;
};


export type MutationUpsertOneSurveyResultArgs = {
  where: SurveyResultWhereUniqueInput;
  create: SurveyResultCreateInput;
  update: SurveyResultUpdateInput;
};


export type MutationDeleteManySurveyResultArgs = {
  where?: Maybe<SurveyResultWhereInput>;
};


export type MutationUpdateManySurveyResultArgs = {
  data: SurveyResultUpdateManyMutationInput;
  where?: Maybe<SurveyResultWhereInput>;
};


export type MutationCreateOneServiceCategoryArgs = {
  data: ServiceCategoryCreateInput;
};


export type MutationUpdateOneServiceCategoryArgs = {
  data: ServiceCategoryUpdateInput;
  where: ServiceCategoryWhereUniqueInput;
};


export type MutationDeleteOneServiceCategoryArgs = {
  where: ServiceCategoryWhereUniqueInput;
};


export type MutationUpsertOneServiceCategoryArgs = {
  where: ServiceCategoryWhereUniqueInput;
  create: ServiceCategoryCreateInput;
  update: ServiceCategoryUpdateInput;
};


export type MutationDeleteManyServiceCategoryArgs = {
  where?: Maybe<ServiceCategoryWhereInput>;
};


export type MutationUpdateManyServiceCategoryArgs = {
  data: ServiceCategoryUpdateManyMutationInput;
  where?: Maybe<ServiceCategoryWhereInput>;
};


export type MutationUpdateOneBitwerxPaymentWritebackConfigurationArgs = {
  data: BitwerxPaymentWritebackConfigurationUpdateInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};


export type MutationUpsertOneBitwerxPaymentWritebackConfigurationArgs = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  create: BitwerxPaymentWritebackConfigurationCreateInput;
  update: BitwerxPaymentWritebackConfigurationUpdateInput;
};


export type MutationCreateOneGlobalPetParentArgs = {
  data: GlobalPetParentCreateInput;
};


export type MutationUpdateOneGlobalPetParentArgs = {
  data: GlobalPetParentUpdateInput;
  where: GlobalPetParentWhereUniqueInput;
};


export type MutationDeleteOneGlobalPetParentArgs = {
  where: GlobalPetParentWhereUniqueInput;
};


export type MutationUpsertOneGlobalPetParentArgs = {
  where: GlobalPetParentWhereUniqueInput;
  create: GlobalPetParentCreateInput;
  update: GlobalPetParentUpdateInput;
};


export type MutationDeleteManyGlobalPetParentArgs = {
  where?: Maybe<GlobalPetParentWhereInput>;
};


export type MutationUpdateManyGlobalPetParentArgs = {
  data: GlobalPetParentUpdateManyMutationInput;
  where?: Maybe<GlobalPetParentWhereInput>;
};


export type MutationUpdateOneLoyaltyProgramArgs = {
  data: LoyaltyProgramUpdateInput;
  where: LoyaltyProgramWhereUniqueInput;
};


export type MutationCreateOneLoyaltyAccountMergerArgs = {
  data: LoyaltyAccountMergerCreateInput;
};


export type MutationUpdateOneLoyaltyAccountMergerArgs = {
  data: LoyaltyAccountMergerUpdateInput;
  where: LoyaltyAccountMergerWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyAccountMergerArgs = {
  where: LoyaltyAccountMergerWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyAccountMergerArgs = {
  where: LoyaltyAccountMergerWhereUniqueInput;
  create: LoyaltyAccountMergerCreateInput;
  update: LoyaltyAccountMergerUpdateInput;
};


export type MutationDeleteManyLoyaltyAccountMergerArgs = {
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
};


export type MutationUpdateManyLoyaltyAccountMergerArgs = {
  data: LoyaltyAccountMergerUpdateManyMutationInput;
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
};


export type MutationCreateOneLoyaltyAccountArgs = {
  data: LoyaltyAccountCreateInput;
};


export type MutationUpdateOneLoyaltyAccountArgs = {
  data: LoyaltyAccountUpdateInput;
  where: LoyaltyAccountWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyAccountArgs = {
  where: LoyaltyAccountWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyAccountArgs = {
  where: LoyaltyAccountWhereUniqueInput;
  create: LoyaltyAccountCreateInput;
  update: LoyaltyAccountUpdateInput;
};


export type MutationDeleteManyLoyaltyAccountArgs = {
  where?: Maybe<LoyaltyAccountWhereInput>;
};


export type MutationUpdateManyLoyaltyAccountArgs = {
  data: LoyaltyAccountUpdateManyMutationInput;
  where?: Maybe<LoyaltyAccountWhereInput>;
};


export type MutationCreateOneLoyaltyAccountHolderArgs = {
  data: LoyaltyAccountHolderCreateInput;
};


export type MutationUpdateOneLoyaltyAccountHolderArgs = {
  data: LoyaltyAccountHolderUpdateInput;
  where: LoyaltyAccountHolderWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyAccountHolderArgs = {
  where: LoyaltyAccountHolderWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyAccountHolderArgs = {
  where: LoyaltyAccountHolderWhereUniqueInput;
  create: LoyaltyAccountHolderCreateInput;
  update: LoyaltyAccountHolderUpdateInput;
};


export type MutationDeleteManyLoyaltyAccountHolderArgs = {
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
};


export type MutationUpdateManyLoyaltyAccountHolderArgs = {
  data: LoyaltyAccountHolderUpdateManyMutationInput;
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
};


export type MutationCreateOneLoyaltyRewardArgs = {
  data: LoyaltyRewardCreateInput;
};


export type MutationUpdateOneLoyaltyRewardArgs = {
  data: LoyaltyRewardUpdateInput;
  where: LoyaltyRewardWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyRewardArgs = {
  where: LoyaltyRewardWhereUniqueInput;
  create: LoyaltyRewardCreateInput;
  update: LoyaltyRewardUpdateInput;
};


export type MutationUpdateManyLoyaltyRewardArgs = {
  data: LoyaltyRewardUpdateManyMutationInput;
  where?: Maybe<LoyaltyRewardWhereInput>;
};


export type MutationCreateOneLoyaltyRewardRedemptionArgs = {
  data: LoyaltyRewardRedemptionCreateInput;
};


export type MutationUpdateOneLoyaltyRewardRedemptionArgs = {
  data: LoyaltyRewardRedemptionUpdateInput;
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyRewardRedemptionArgs = {
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyRewardRedemptionArgs = {
  where: LoyaltyRewardRedemptionWhereUniqueInput;
  create: LoyaltyRewardRedemptionCreateInput;
  update: LoyaltyRewardRedemptionUpdateInput;
};


export type MutationDeleteManyLoyaltyRewardRedemptionArgs = {
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
};


export type MutationUpdateManyLoyaltyRewardRedemptionArgs = {
  data: LoyaltyRewardRedemptionUpdateManyMutationInput;
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
};


export type MutationCreateOneLoyaltyInvoicePointGranterArgs = {
  data: LoyaltyInvoicePointGranterCreateInput;
};


export type MutationUpdateOneLoyaltyInvoicePointGranterArgs = {
  data: LoyaltyInvoicePointGranterUpdateInput;
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyInvoicePointGranterArgs = {
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyInvoicePointGranterArgs = {
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
  create: LoyaltyInvoicePointGranterCreateInput;
  update: LoyaltyInvoicePointGranterUpdateInput;
};


export type MutationDeleteManyLoyaltyInvoicePointGranterArgs = {
  where?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};


export type MutationUpdateManyLoyaltyInvoicePointGranterArgs = {
  data: LoyaltyInvoicePointGranterUpdateManyMutationInput;
  where?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};


export type MutationCreateOneLoyaltyPointGrantingServiceArgs = {
  data: LoyaltyPointGrantingServiceCreateInput;
};


export type MutationUpdateOneLoyaltyPointGrantingServiceArgs = {
  data: LoyaltyPointGrantingServiceUpdateInput;
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyPointGrantingServiceArgs = {
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyPointGrantingServiceArgs = {
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
  create: LoyaltyPointGrantingServiceCreateInput;
  update: LoyaltyPointGrantingServiceUpdateInput;
};


export type MutationDeleteManyLoyaltyPointGrantingServiceArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
};


export type MutationUpdateManyLoyaltyPointGrantingServiceArgs = {
  data: LoyaltyPointGrantingServiceUpdateManyMutationInput;
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
};


export type MutationCreateOneLoyaltyPointGrantingServiceCategoryArgs = {
  data: LoyaltyPointGrantingServiceCategoryCreateInput;
};


export type MutationUpdateOneLoyaltyPointGrantingServiceCategoryArgs = {
  data: LoyaltyPointGrantingServiceCategoryUpdateInput;
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyPointGrantingServiceCategoryArgs = {
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyPointGrantingServiceCategoryArgs = {
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
  create: LoyaltyPointGrantingServiceCategoryCreateInput;
  update: LoyaltyPointGrantingServiceCategoryUpdateInput;
};


export type MutationDeleteManyLoyaltyPointGrantingServiceCategoryArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
};


export type MutationUpdateManyLoyaltyPointGrantingServiceCategoryArgs = {
  data: LoyaltyPointGrantingServiceCategoryUpdateManyMutationInput;
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
};


export type MutationCreateOneLoyaltyPointUserGrantArgs = {
  data: LoyaltyPointUserGrantCreateInput;
};


export type MutationUpdateOneLoyaltyPointUserGrantArgs = {
  data: LoyaltyPointUserGrantUpdateInput;
  where: LoyaltyPointUserGrantWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyPointUserGrantArgs = {
  where: LoyaltyPointUserGrantWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyPointUserGrantArgs = {
  where: LoyaltyPointUserGrantWhereUniqueInput;
  create: LoyaltyPointUserGrantCreateInput;
  update: LoyaltyPointUserGrantUpdateInput;
};


export type MutationDeleteManyLoyaltyPointUserGrantArgs = {
  where?: Maybe<LoyaltyPointUserGrantWhereInput>;
};


export type MutationUpdateManyLoyaltyPointUserGrantArgs = {
  data: LoyaltyPointUserGrantUpdateManyMutationInput;
  where?: Maybe<LoyaltyPointUserGrantWhereInput>;
};


export type MutationCreateOnePhoneNumberLookupRequestArgs = {
  data: PhoneNumberLookupRequestCreateInput;
};


export type MutationUpdateOnePhoneNumberLookupRequestArgs = {
  data: PhoneNumberLookupRequestUpdateInput;
  where: PhoneNumberLookupRequestWhereUniqueInput;
};


export type MutationDeleteOnePhoneNumberLookupRequestArgs = {
  where: PhoneNumberLookupRequestWhereUniqueInput;
};


export type MutationUpsertOnePhoneNumberLookupRequestArgs = {
  where: PhoneNumberLookupRequestWhereUniqueInput;
  create: PhoneNumberLookupRequestCreateInput;
  update: PhoneNumberLookupRequestUpdateInput;
};


export type MutationDeleteManyPhoneNumberLookupRequestArgs = {
  where?: Maybe<PhoneNumberLookupRequestWhereInput>;
};


export type MutationUpdateManyPhoneNumberLookupRequestArgs = {
  data: PhoneNumberLookupRequestUpdateManyMutationInput;
  where?: Maybe<PhoneNumberLookupRequestWhereInput>;
};


export type MutationCreateOnePhoneNumberLookupResultArgs = {
  data: PhoneNumberLookupResultCreateInput;
};


export type MutationUpdateOnePhoneNumberLookupResultArgs = {
  data: PhoneNumberLookupResultUpdateInput;
  where: PhoneNumberLookupResultWhereUniqueInput;
};


export type MutationDeleteOnePhoneNumberLookupResultArgs = {
  where: PhoneNumberLookupResultWhereUniqueInput;
};


export type MutationUpsertOnePhoneNumberLookupResultArgs = {
  where: PhoneNumberLookupResultWhereUniqueInput;
  create: PhoneNumberLookupResultCreateInput;
  update: PhoneNumberLookupResultUpdateInput;
};


export type MutationDeleteManyPhoneNumberLookupResultArgs = {
  where?: Maybe<PhoneNumberLookupResultWhereInput>;
};


export type MutationUpdateManyPhoneNumberLookupResultArgs = {
  data: PhoneNumberLookupResultUpdateManyMutationInput;
  where?: Maybe<PhoneNumberLookupResultWhereInput>;
};


export type MutationCreateOneClinicPetPimsSyncStatusArgs = {
  data: ClinicPetPimsSyncStatusCreateInput;
};


export type MutationUpdateOneClinicPetPimsSyncStatusArgs = {
  data: ClinicPetPimsSyncStatusUpdateInput;
  where: ClinicPetPimsSyncStatusWhereUniqueInput;
};


export type MutationDeleteOneClinicPetPimsSyncStatusArgs = {
  where: ClinicPetPimsSyncStatusWhereUniqueInput;
};


export type MutationUpsertOneClinicPetPimsSyncStatusArgs = {
  where: ClinicPetPimsSyncStatusWhereUniqueInput;
  create: ClinicPetPimsSyncStatusCreateInput;
  update: ClinicPetPimsSyncStatusUpdateInput;
};


export type MutationDeleteManyClinicPetPimsSyncStatusArgs = {
  where?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
};


export type MutationUpdateManyClinicPetPimsSyncStatusArgs = {
  data: ClinicPetPimsSyncStatusUpdateManyMutationInput;
  where?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
};


export type MutationCreateOneClinicPetParentPimsSyncStatusArgs = {
  data: ClinicPetParentPimsSyncStatusCreateInput;
};


export type MutationUpdateOneClinicPetParentPimsSyncStatusArgs = {
  data: ClinicPetParentPimsSyncStatusUpdateInput;
  where: ClinicPetParentPimsSyncStatusWhereUniqueInput;
};


export type MutationDeleteOneClinicPetParentPimsSyncStatusArgs = {
  where: ClinicPetParentPimsSyncStatusWhereUniqueInput;
};


export type MutationUpsertOneClinicPetParentPimsSyncStatusArgs = {
  where: ClinicPetParentPimsSyncStatusWhereUniqueInput;
  create: ClinicPetParentPimsSyncStatusCreateInput;
  update: ClinicPetParentPimsSyncStatusUpdateInput;
};


export type MutationDeleteManyClinicPetParentPimsSyncStatusArgs = {
  where?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
};


export type MutationUpdateManyClinicPetParentPimsSyncStatusArgs = {
  data: ClinicPetParentPimsSyncStatusUpdateManyMutationInput;
  where?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
};


export type MutationCreateOneCampaignRegistryCampaignArgs = {
  data: CampaignRegistryCampaignCreateInput;
};


export type MutationUpdateOneCampaignRegistryCampaignArgs = {
  data: CampaignRegistryCampaignUpdateInput;
  where: CampaignRegistryCampaignWhereUniqueInput;
};


export type MutationDeleteOneCampaignRegistryCampaignArgs = {
  where: CampaignRegistryCampaignWhereUniqueInput;
};


export type MutationUpsertOneCampaignRegistryCampaignArgs = {
  where: CampaignRegistryCampaignWhereUniqueInput;
  create: CampaignRegistryCampaignCreateInput;
  update: CampaignRegistryCampaignUpdateInput;
};


export type MutationDeleteManyCampaignRegistryCampaignArgs = {
  where?: Maybe<CampaignRegistryCampaignWhereInput>;
};


export type MutationUpdateManyCampaignRegistryCampaignArgs = {
  data: CampaignRegistryCampaignUpdateManyMutationInput;
  where?: Maybe<CampaignRegistryCampaignWhereInput>;
};


export type MutationCreateOneLegalEntityArgs = {
  data: LegalEntityCreateInput;
};


export type MutationUpdateOneLegalEntityArgs = {
  data: LegalEntityUpdateInput;
  where: LegalEntityWhereUniqueInput;
};


export type MutationUpsertOneLegalEntityArgs = {
  where: LegalEntityWhereUniqueInput;
  create: LegalEntityCreateInput;
  update: LegalEntityUpdateInput;
};


export type MutationUpdateManyLegalEntityArgs = {
  data: LegalEntityUpdateManyMutationInput;
  where?: Maybe<LegalEntityWhereInput>;
};


export type MutationCreateOneClinicPetParentDeviceArgs = {
  data: ClinicPetParentDeviceCreateInput;
};


export type MutationUpdateOneClinicPetParentDeviceArgs = {
  data: ClinicPetParentDeviceUpdateInput;
  where: ClinicPetParentDeviceWhereUniqueInput;
};


export type MutationDeleteOneClinicPetParentDeviceArgs = {
  where: ClinicPetParentDeviceWhereUniqueInput;
};


export type MutationUpsertOneClinicPetParentDeviceArgs = {
  where: ClinicPetParentDeviceWhereUniqueInput;
  create: ClinicPetParentDeviceCreateInput;
  update: ClinicPetParentDeviceUpdateInput;
};


export type MutationDeleteManyClinicPetParentDeviceArgs = {
  where?: Maybe<ClinicPetParentDeviceWhereInput>;
};


export type MutationUpdateManyClinicPetParentDeviceArgs = {
  data: ClinicPetParentDeviceUpdateManyMutationInput;
  where?: Maybe<ClinicPetParentDeviceWhereInput>;
};


export type MutationUpdateOneEmailTemplateVersionArgs = {
  data: EmailTemplateVersionUpdateInput;
  where: EmailTemplateVersionWhereUniqueInput;
};


export type MutationUpdateManyEmailTemplateVersionArgs = {
  data: EmailTemplateVersionUpdateManyMutationInput;
  where?: Maybe<EmailTemplateVersionWhereInput>;
};


export type MutationCreateOneCareBenefitUsageFollowupArgs = {
  data: CareBenefitUsageFollowupCreateInput;
};


export type MutationUpdateOneCareBenefitUsageFollowupArgs = {
  data: CareBenefitUsageFollowupUpdateInput;
  where: CareBenefitUsageFollowupWhereUniqueInput;
};


export type MutationDeleteOneCareBenefitUsageFollowupArgs = {
  where: CareBenefitUsageFollowupWhereUniqueInput;
};


export type MutationUpsertOneCareBenefitUsageFollowupArgs = {
  where: CareBenefitUsageFollowupWhereUniqueInput;
  create: CareBenefitUsageFollowupCreateInput;
  update: CareBenefitUsageFollowupUpdateInput;
};


export type MutationDeleteManyCareBenefitUsageFollowupArgs = {
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
};


export type MutationUpdateManyCareBenefitUsageFollowupArgs = {
  data: CareBenefitUsageFollowupUpdateManyMutationInput;
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
};


export type MutationCreateOneCareBenefitUsageFollowupStepArgs = {
  data: CareBenefitUsageFollowupStepCreateInput;
};


export type MutationUpdateOneCareBenefitUsageFollowupStepArgs = {
  data: CareBenefitUsageFollowupStepUpdateInput;
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};


export type MutationDeleteOneCareBenefitUsageFollowupStepArgs = {
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};


export type MutationUpsertOneCareBenefitUsageFollowupStepArgs = {
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
  create: CareBenefitUsageFollowupStepCreateInput;
  update: CareBenefitUsageFollowupStepUpdateInput;
};


export type MutationDeleteManyCareBenefitUsageFollowupStepArgs = {
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
};


export type MutationUpdateManyCareBenefitUsageFollowupStepArgs = {
  data: CareBenefitUsageFollowupStepUpdateManyMutationInput;
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
};


export type MutationCreateOneCarePlanProviderGroupArgs = {
  data: CarePlanProviderGroupCreateInput;
};


export type MutationUpdateOneCarePlanProviderGroupArgs = {
  data: CarePlanProviderGroupUpdateInput;
  where: CarePlanProviderGroupWhereUniqueInput;
};


export type MutationDeleteOneCarePlanProviderGroupArgs = {
  where: CarePlanProviderGroupWhereUniqueInput;
};


export type MutationUpsertOneCarePlanProviderGroupArgs = {
  where: CarePlanProviderGroupWhereUniqueInput;
  create: CarePlanProviderGroupCreateInput;
  update: CarePlanProviderGroupUpdateInput;
};


export type MutationDeleteManyCarePlanProviderGroupArgs = {
  where?: Maybe<CarePlanProviderGroupWhereInput>;
};


export type MutationUpdateManyCarePlanProviderGroupArgs = {
  data: CarePlanProviderGroupUpdateManyMutationInput;
  where?: Maybe<CarePlanProviderGroupWhereInput>;
};


export type MutationCreateOneInsuranceIntegrationArgs = {
  data: InsuranceIntegrationCreateInput;
};


export type MutationUpdateOneInsuranceIntegrationArgs = {
  data: InsuranceIntegrationUpdateInput;
  where: InsuranceIntegrationWhereUniqueInput;
};


export type MutationDeleteOneInsuranceIntegrationArgs = {
  where: InsuranceIntegrationWhereUniqueInput;
};


export type MutationUpsertOneInsuranceIntegrationArgs = {
  where: InsuranceIntegrationWhereUniqueInput;
  create: InsuranceIntegrationCreateInput;
  update: InsuranceIntegrationUpdateInput;
};


export type MutationDeleteManyInsuranceIntegrationArgs = {
  where?: Maybe<InsuranceIntegrationWhereInput>;
};


export type MutationUpdateManyInsuranceIntegrationArgs = {
  data: InsuranceIntegrationUpdateManyMutationInput;
  where?: Maybe<InsuranceIntegrationWhereInput>;
};


export type MutationCreateOneTrupanionIntegrationArgs = {
  data: TrupanionIntegrationCreateInput;
};


export type MutationUpdateOneTrupanionIntegrationArgs = {
  data: TrupanionIntegrationUpdateInput;
  where: TrupanionIntegrationWhereUniqueInput;
};


export type MutationDeleteOneTrupanionIntegrationArgs = {
  where: TrupanionIntegrationWhereUniqueInput;
};


export type MutationUpsertOneTrupanionIntegrationArgs = {
  where: TrupanionIntegrationWhereUniqueInput;
  create: TrupanionIntegrationCreateInput;
  update: TrupanionIntegrationUpdateInput;
};


export type MutationDeleteManyTrupanionIntegrationArgs = {
  where?: Maybe<TrupanionIntegrationWhereInput>;
};


export type MutationUpdateManyTrupanionIntegrationArgs = {
  data: TrupanionIntegrationUpdateManyMutationInput;
  where?: Maybe<TrupanionIntegrationWhereInput>;
};


export type MutationCreateOneTrupanionIntegratedAppointmentTypeArgs = {
  data: TrupanionIntegratedAppointmentTypeCreateInput;
};


export type MutationUpdateOneTrupanionIntegratedAppointmentTypeArgs = {
  data: TrupanionIntegratedAppointmentTypeUpdateInput;
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
};


export type MutationDeleteOneTrupanionIntegratedAppointmentTypeArgs = {
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
};


export type MutationUpsertOneTrupanionIntegratedAppointmentTypeArgs = {
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
  create: TrupanionIntegratedAppointmentTypeCreateInput;
  update: TrupanionIntegratedAppointmentTypeUpdateInput;
};


export type MutationDeleteManyTrupanionIntegratedAppointmentTypeArgs = {
  where?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
};


export type MutationUpdateManyTrupanionIntegratedAppointmentTypeArgs = {
  data: TrupanionIntegratedAppointmentTypeUpdateManyMutationInput;
  where?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
};


export type MutationCreateOneSentTrupanionExamDayOfferArgs = {
  data: SentTrupanionExamDayOfferCreateInput;
};


export type MutationUpdateOneSentTrupanionExamDayOfferArgs = {
  data: SentTrupanionExamDayOfferUpdateInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};


export type MutationDeleteOneSentTrupanionExamDayOfferArgs = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};


export type MutationUpsertOneSentTrupanionExamDayOfferArgs = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  create: SentTrupanionExamDayOfferCreateInput;
  update: SentTrupanionExamDayOfferUpdateInput;
};


export type MutationDeleteManySentTrupanionExamDayOfferArgs = {
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};


export type MutationUpdateManySentTrupanionExamDayOfferArgs = {
  data: SentTrupanionExamDayOfferUpdateManyMutationInput;
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};


export type MutationCreateOneBreedArgs = {
  data: BreedCreateInput;
};


export type MutationUpdateOneBreedArgs = {
  data: BreedUpdateInput;
  where: BreedWhereUniqueInput;
};


export type MutationDeleteOneBreedArgs = {
  where: BreedWhereUniqueInput;
};


export type MutationUpsertOneBreedArgs = {
  where: BreedWhereUniqueInput;
  create: BreedCreateInput;
  update: BreedUpdateInput;
};


export type MutationDeleteManyBreedArgs = {
  where?: Maybe<BreedWhereInput>;
};


export type MutationUpdateManyBreedArgs = {
  data: BreedUpdateManyMutationInput;
  where?: Maybe<BreedWhereInput>;
};


export type MutationCreateOneSpeciesArgs = {
  data: SpeciesCreateInput;
};


export type MutationUpdateOneSpeciesArgs = {
  data: SpeciesUpdateInput;
  where: SpeciesWhereUniqueInput;
};


export type MutationDeleteOneSpeciesArgs = {
  where: SpeciesWhereUniqueInput;
};


export type MutationUpsertOneSpeciesArgs = {
  where: SpeciesWhereUniqueInput;
  create: SpeciesCreateInput;
  update: SpeciesUpdateInput;
};


export type MutationDeleteManySpeciesArgs = {
  where?: Maybe<SpeciesWhereInput>;
};


export type MutationUpdateManySpeciesArgs = {
  data: SpeciesUpdateManyMutationInput;
  where?: Maybe<SpeciesWhereInput>;
};


export type MutationCreateOneSexArgs = {
  data: SexCreateInput;
};


export type MutationUpdateOneSexArgs = {
  data: SexUpdateInput;
  where: SexWhereUniqueInput;
};


export type MutationDeleteOneSexArgs = {
  where: SexWhereUniqueInput;
};


export type MutationUpsertOneSexArgs = {
  where: SexWhereUniqueInput;
  create: SexCreateInput;
  update: SexUpdateInput;
};


export type MutationDeleteManySexArgs = {
  where?: Maybe<SexWhereInput>;
};


export type MutationUpdateManySexArgs = {
  data: SexUpdateManyMutationInput;
  where?: Maybe<SexWhereInput>;
};


export type MutationCreateOneColorArgs = {
  data: ColorCreateInput;
};


export type MutationUpdateOneColorArgs = {
  data: ColorUpdateInput;
  where: ColorWhereUniqueInput;
};


export type MutationDeleteOneColorArgs = {
  where: ColorWhereUniqueInput;
};


export type MutationUpsertOneColorArgs = {
  where: ColorWhereUniqueInput;
  create: ColorCreateInput;
  update: ColorUpdateInput;
};


export type MutationDeleteManyColorArgs = {
  where?: Maybe<ColorWhereInput>;
};


export type MutationUpdateManyColorArgs = {
  data: ColorUpdateManyMutationInput;
  where?: Maybe<ColorWhereInput>;
};


export type MutationCreateOneMediaArgs = {
  data: MediaCreateInput;
};


export type MutationUpdateOneMediaArgs = {
  data: MediaUpdateInput;
  where: MediaWhereUniqueInput;
};


export type MutationDeleteOneMediaArgs = {
  where: MediaWhereUniqueInput;
};


export type MutationUpsertOneMediaArgs = {
  where: MediaWhereUniqueInput;
  create: MediaCreateInput;
  update: MediaUpdateInput;
};


export type MutationDeleteManyMediaArgs = {
  where?: Maybe<MediaWhereInput>;
};


export type MutationUpdateManyMediaArgs = {
  data: MediaUpdateManyMutationInput;
  where?: Maybe<MediaWhereInput>;
};


export type MutationCreateOneLapsedPetParentTriggersArgs = {
  data: LapsedPetParentTriggersCreateInput;
};


export type MutationUpdateOneLapsedPetParentTriggersArgs = {
  data: LapsedPetParentTriggersUpdateInput;
  where: LapsedPetParentTriggersWhereUniqueInput;
};


export type MutationDeleteOneLapsedPetParentTriggersArgs = {
  where: LapsedPetParentTriggersWhereUniqueInput;
};


export type MutationUpsertOneLapsedPetParentTriggersArgs = {
  where: LapsedPetParentTriggersWhereUniqueInput;
  create: LapsedPetParentTriggersCreateInput;
  update: LapsedPetParentTriggersUpdateInput;
};


export type MutationDeleteManyLapsedPetParentTriggersArgs = {
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
};


export type MutationUpdateManyLapsedPetParentTriggersArgs = {
  data: LapsedPetParentTriggersUpdateManyMutationInput;
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
};


export type MutationCreateOneConditionSetArgs = {
  data: ConditionSetCreateInput;
};


export type MutationUpdateOneConditionSetArgs = {
  data: ConditionSetUpdateInput;
  where: ConditionSetWhereUniqueInput;
};


export type MutationDeleteOneConditionSetArgs = {
  where: ConditionSetWhereUniqueInput;
};


export type MutationUpsertOneConditionSetArgs = {
  where: ConditionSetWhereUniqueInput;
  create: ConditionSetCreateInput;
  update: ConditionSetUpdateInput;
};


export type MutationDeleteManyConditionSetArgs = {
  where?: Maybe<ConditionSetWhereInput>;
};


export type MutationUpdateManyConditionSetArgs = {
  data: ConditionSetUpdateManyMutationInput;
  where?: Maybe<ConditionSetWhereInput>;
};


export type MutationCreateOneConditionArgs = {
  data: ConditionCreateInput;
};


export type MutationUpdateOneConditionArgs = {
  data: ConditionUpdateInput;
  where: ConditionWhereUniqueInput;
};


export type MutationDeleteOneConditionArgs = {
  where: ConditionWhereUniqueInput;
};


export type MutationUpsertOneConditionArgs = {
  where: ConditionWhereUniqueInput;
  create: ConditionCreateInput;
  update: ConditionUpdateInput;
};


export type MutationDeleteManyConditionArgs = {
  where?: Maybe<ConditionWhereInput>;
};


export type MutationUpdateManyConditionArgs = {
  data: ConditionUpdateManyMutationInput;
  where?: Maybe<ConditionWhereInput>;
};


export type MutationCreateOnePushNotificationLogArgs = {
  data: PushNotificationLogCreateInput;
};


export type MutationUpdateOnePushNotificationLogArgs = {
  data: PushNotificationLogUpdateInput;
  where: PushNotificationLogWhereUniqueInput;
};


export type MutationDeleteOnePushNotificationLogArgs = {
  where: PushNotificationLogWhereUniqueInput;
};


export type MutationUpsertOnePushNotificationLogArgs = {
  where: PushNotificationLogWhereUniqueInput;
  create: PushNotificationLogCreateInput;
  update: PushNotificationLogUpdateInput;
};


export type MutationDeleteManyPushNotificationLogArgs = {
  where?: Maybe<PushNotificationLogWhereInput>;
};


export type MutationUpdateManyPushNotificationLogArgs = {
  data: PushNotificationLogUpdateManyMutationInput;
  where?: Maybe<PushNotificationLogWhereInput>;
};


export type MutationCreateOneTrupanionCertificateAvailabilityArgs = {
  data: TrupanionCertificateAvailabilityCreateInput;
};


export type MutationUpdateOneTrupanionCertificateAvailabilityArgs = {
  data: TrupanionCertificateAvailabilityUpdateInput;
  where: TrupanionCertificateAvailabilityWhereUniqueInput;
};


export type MutationDeleteOneTrupanionCertificateAvailabilityArgs = {
  where: TrupanionCertificateAvailabilityWhereUniqueInput;
};


export type MutationUpsertOneTrupanionCertificateAvailabilityArgs = {
  where: TrupanionCertificateAvailabilityWhereUniqueInput;
  create: TrupanionCertificateAvailabilityCreateInput;
  update: TrupanionCertificateAvailabilityUpdateInput;
};


export type MutationDeleteManyTrupanionCertificateAvailabilityArgs = {
  where?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
};


export type MutationUpdateManyTrupanionCertificateAvailabilityArgs = {
  data: TrupanionCertificateAvailabilityUpdateManyMutationInput;
  where?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
};


export type MutationCreateOneMessagingPartnerArgs = {
  data: MessagingPartnerCreateInput;
};


export type MutationUpdateOneMessagingPartnerArgs = {
  data: MessagingPartnerUpdateInput;
  where: MessagingPartnerWhereUniqueInput;
};


export type MutationDeleteOneMessagingPartnerArgs = {
  where: MessagingPartnerWhereUniqueInput;
};


export type MutationUpsertOneMessagingPartnerArgs = {
  where: MessagingPartnerWhereUniqueInput;
  create: MessagingPartnerCreateInput;
  update: MessagingPartnerUpdateInput;
};


export type MutationDeleteManyMessagingPartnerArgs = {
  where?: Maybe<MessagingPartnerWhereInput>;
};


export type MutationUpdateManyMessagingPartnerArgs = {
  data: MessagingPartnerUpdateManyMutationInput;
  where?: Maybe<MessagingPartnerWhereInput>;
};


export type MutationCreateOneMessagingCampaignArgs = {
  data: MessagingCampaignCreateInput;
};


export type MutationUpdateOneMessagingCampaignArgs = {
  data: MessagingCampaignUpdateInput;
  where: MessagingCampaignWhereUniqueInput;
};


export type MutationDeleteOneMessagingCampaignArgs = {
  where: MessagingCampaignWhereUniqueInput;
};


export type MutationUpsertOneMessagingCampaignArgs = {
  where: MessagingCampaignWhereUniqueInput;
  create: MessagingCampaignCreateInput;
  update: MessagingCampaignUpdateInput;
};


export type MutationDeleteManyMessagingCampaignArgs = {
  where?: Maybe<MessagingCampaignWhereInput>;
};


export type MutationUpdateManyMessagingCampaignArgs = {
  data: MessagingCampaignUpdateManyMutationInput;
  where?: Maybe<MessagingCampaignWhereInput>;
};


export type MutationCreateOneConditionalEntityEvaluationArgs = {
  data: ConditionalEntityEvaluationCreateInput;
};


export type MutationUpdateOneConditionalEntityEvaluationArgs = {
  data: ConditionalEntityEvaluationUpdateInput;
  where: ConditionalEntityEvaluationWhereUniqueInput;
};


export type MutationDeleteOneConditionalEntityEvaluationArgs = {
  where: ConditionalEntityEvaluationWhereUniqueInput;
};


export type MutationUpsertOneConditionalEntityEvaluationArgs = {
  where: ConditionalEntityEvaluationWhereUniqueInput;
  create: ConditionalEntityEvaluationCreateInput;
  update: ConditionalEntityEvaluationUpdateInput;
};


export type MutationDeleteManyConditionalEntityEvaluationArgs = {
  where?: Maybe<ConditionalEntityEvaluationWhereInput>;
};


export type MutationUpdateManyConditionalEntityEvaluationArgs = {
  data: ConditionalEntityEvaluationUpdateManyMutationInput;
  where?: Maybe<ConditionalEntityEvaluationWhereInput>;
};


export type MutationCreateOnePayoutBatchingPeriodArgs = {
  data: PayoutBatchingPeriodCreateInput;
};


export type MutationUpdateOnePayoutBatchingPeriodArgs = {
  data: PayoutBatchingPeriodUpdateInput;
  where: PayoutBatchingPeriodWhereUniqueInput;
};


export type MutationUpsertOnePayoutBatchingPeriodArgs = {
  where: PayoutBatchingPeriodWhereUniqueInput;
  create: PayoutBatchingPeriodCreateInput;
  update: PayoutBatchingPeriodUpdateInput;
};


export type MutationUpdateManyPayoutBatchingPeriodArgs = {
  data: PayoutBatchingPeriodUpdateManyMutationInput;
  where?: Maybe<PayoutBatchingPeriodWhereInput>;
};


export type MutationCreateOneBitwerxHealthcheckArgs = {
  data: BitwerxHealthcheckCreateInput;
};


export type MutationUpdateOneBitwerxHealthcheckArgs = {
  data: BitwerxHealthcheckUpdateInput;
  where: BitwerxHealthcheckWhereUniqueInput;
};


export type MutationDeleteOneBitwerxHealthcheckArgs = {
  where: BitwerxHealthcheckWhereUniqueInput;
};


export type MutationUpsertOneBitwerxHealthcheckArgs = {
  where: BitwerxHealthcheckWhereUniqueInput;
  create: BitwerxHealthcheckCreateInput;
  update: BitwerxHealthcheckUpdateInput;
};


export type MutationDeleteManyBitwerxHealthcheckArgs = {
  where?: Maybe<BitwerxHealthcheckWhereInput>;
};


export type MutationUpdateManyBitwerxHealthcheckArgs = {
  data: BitwerxHealthcheckUpdateManyMutationInput;
  where?: Maybe<BitwerxHealthcheckWhereInput>;
};


export type MutationCreateOneBitwerxPaymentHealthcheckRunArgs = {
  data: BitwerxPaymentHealthcheckRunCreateInput;
};


export type MutationUpdateOneBitwerxPaymentHealthcheckRunArgs = {
  data: BitwerxPaymentHealthcheckRunUpdateInput;
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
};


export type MutationDeleteOneBitwerxPaymentHealthcheckRunArgs = {
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
};


export type MutationUpsertOneBitwerxPaymentHealthcheckRunArgs = {
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
  create: BitwerxPaymentHealthcheckRunCreateInput;
  update: BitwerxPaymentHealthcheckRunUpdateInput;
};


export type MutationDeleteManyBitwerxPaymentHealthcheckRunArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
};


export type MutationUpdateManyBitwerxPaymentHealthcheckRunArgs = {
  data: BitwerxPaymentHealthcheckRunUpdateManyMutationInput;
  where?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
};


export type MutationCreateOneBitwerxPaymentHealthcheckResultArgs = {
  data: BitwerxPaymentHealthcheckResultCreateInput;
};


export type MutationUpdateOneBitwerxPaymentHealthcheckResultArgs = {
  data: BitwerxPaymentHealthcheckResultUpdateInput;
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
};


export type MutationDeleteOneBitwerxPaymentHealthcheckResultArgs = {
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
};


export type MutationUpsertOneBitwerxPaymentHealthcheckResultArgs = {
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
  create: BitwerxPaymentHealthcheckResultCreateInput;
  update: BitwerxPaymentHealthcheckResultUpdateInput;
};


export type MutationDeleteManyBitwerxPaymentHealthcheckResultArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
};


export type MutationUpdateManyBitwerxPaymentHealthcheckResultArgs = {
  data: BitwerxPaymentHealthcheckResultUpdateManyMutationInput;
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
};


export type MutationCreateOneCareLapsedPaymentArgs = {
  data: CareLapsedPaymentCreateInput;
};


export type MutationUpdateOneCareLapsedPaymentArgs = {
  data: CareLapsedPaymentUpdateInput;
  where: CareLapsedPaymentWhereUniqueInput;
};


export type MutationDeleteOneCareLapsedPaymentArgs = {
  where: CareLapsedPaymentWhereUniqueInput;
};


export type MutationUpsertOneCareLapsedPaymentArgs = {
  where: CareLapsedPaymentWhereUniqueInput;
  create: CareLapsedPaymentCreateInput;
  update: CareLapsedPaymentUpdateInput;
};


export type MutationDeleteManyCareLapsedPaymentArgs = {
  where?: Maybe<CareLapsedPaymentWhereInput>;
};


export type MutationUpdateManyCareLapsedPaymentArgs = {
  data: CareLapsedPaymentUpdateManyMutationInput;
  where?: Maybe<CareLapsedPaymentWhereInput>;
};


export type MutationCreateOneClinicPetParentCustomFieldValueArgs = {
  data: ClinicPetParentCustomFieldValueCreateInput;
};


export type MutationUpdateOneClinicPetParentCustomFieldValueArgs = {
  data: ClinicPetParentCustomFieldValueUpdateInput;
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
};


export type MutationDeleteOneClinicPetParentCustomFieldValueArgs = {
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
};


export type MutationUpsertOneClinicPetParentCustomFieldValueArgs = {
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
  create: ClinicPetParentCustomFieldValueCreateInput;
  update: ClinicPetParentCustomFieldValueUpdateInput;
};


export type MutationDeleteManyClinicPetParentCustomFieldValueArgs = {
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
};


export type MutationUpdateManyClinicPetParentCustomFieldValueArgs = {
  data: ClinicPetParentCustomFieldValueUpdateManyMutationInput;
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
};


export type MutationCreateOneClinicPetCustomFieldValueArgs = {
  data: ClinicPetCustomFieldValueCreateInput;
};


export type MutationUpdateOneClinicPetCustomFieldValueArgs = {
  data: ClinicPetCustomFieldValueUpdateInput;
  where: ClinicPetCustomFieldValueWhereUniqueInput;
};


export type MutationDeleteOneClinicPetCustomFieldValueArgs = {
  where: ClinicPetCustomFieldValueWhereUniqueInput;
};


export type MutationUpsertOneClinicPetCustomFieldValueArgs = {
  where: ClinicPetCustomFieldValueWhereUniqueInput;
  create: ClinicPetCustomFieldValueCreateInput;
  update: ClinicPetCustomFieldValueUpdateInput;
};


export type MutationDeleteManyClinicPetCustomFieldValueArgs = {
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
};


export type MutationUpdateManyClinicPetCustomFieldValueArgs = {
  data: ClinicPetCustomFieldValueUpdateManyMutationInput;
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
};


export type MutationCreateOneCustomFieldDefinitionArgs = {
  data: CustomFieldDefinitionCreateInput;
};


export type MutationUpdateOneCustomFieldDefinitionArgs = {
  data: CustomFieldDefinitionUpdateInput;
  where: CustomFieldDefinitionWhereUniqueInput;
};


export type MutationDeleteOneCustomFieldDefinitionArgs = {
  where: CustomFieldDefinitionWhereUniqueInput;
};


export type MutationUpsertOneCustomFieldDefinitionArgs = {
  where: CustomFieldDefinitionWhereUniqueInput;
  create: CustomFieldDefinitionCreateInput;
  update: CustomFieldDefinitionUpdateInput;
};


export type MutationDeleteManyCustomFieldDefinitionArgs = {
  where?: Maybe<CustomFieldDefinitionWhereInput>;
};


export type MutationUpdateManyCustomFieldDefinitionArgs = {
  data: CustomFieldDefinitionUpdateManyMutationInput;
  where?: Maybe<CustomFieldDefinitionWhereInput>;
};


export type MutationCreateOneCustomFieldPimsWritebackLogEntryArgs = {
  data: CustomFieldPimsWritebackLogEntryCreateInput;
};


export type MutationUpdateOneCustomFieldPimsWritebackLogEntryArgs = {
  data: CustomFieldPimsWritebackLogEntryUpdateInput;
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
};


export type MutationDeleteOneCustomFieldPimsWritebackLogEntryArgs = {
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
};


export type MutationUpsertOneCustomFieldPimsWritebackLogEntryArgs = {
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
  create: CustomFieldPimsWritebackLogEntryCreateInput;
  update: CustomFieldPimsWritebackLogEntryUpdateInput;
};


export type MutationDeleteManyCustomFieldPimsWritebackLogEntryArgs = {
  where?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
};


export type MutationUpdateManyCustomFieldPimsWritebackLogEntryArgs = {
  data: CustomFieldPimsWritebackLogEntryUpdateManyMutationInput;
  where?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
};


export type MutationCreateOneEmailCampaignArgs = {
  data: EmailCampaignCreateInput;
};


export type MutationUpdateOneEmailCampaignArgs = {
  data: EmailCampaignUpdateInput;
  where: EmailCampaignWhereUniqueInput;
};


export type MutationDeleteOneEmailCampaignArgs = {
  where: EmailCampaignWhereUniqueInput;
};


export type MutationUpsertOneEmailCampaignArgs = {
  where: EmailCampaignWhereUniqueInput;
  create: EmailCampaignCreateInput;
  update: EmailCampaignUpdateInput;
};


export type MutationDeleteManyEmailCampaignArgs = {
  where?: Maybe<EmailCampaignWhereInput>;
};


export type MutationUpdateManyEmailCampaignArgs = {
  data: EmailCampaignUpdateManyMutationInput;
  where?: Maybe<EmailCampaignWhereInput>;
};


export type MutationCreateOneEmailCampaignTemplateArgs = {
  data: EmailCampaignTemplateCreateInput;
};


export type MutationUpdateOneEmailCampaignTemplateArgs = {
  data: EmailCampaignTemplateUpdateInput;
  where: EmailCampaignTemplateWhereUniqueInput;
};


export type MutationDeleteOneEmailCampaignTemplateArgs = {
  where: EmailCampaignTemplateWhereUniqueInput;
};


export type MutationUpsertOneEmailCampaignTemplateArgs = {
  where: EmailCampaignTemplateWhereUniqueInput;
  create: EmailCampaignTemplateCreateInput;
  update: EmailCampaignTemplateUpdateInput;
};


export type MutationDeleteManyEmailCampaignTemplateArgs = {
  where?: Maybe<EmailCampaignTemplateWhereInput>;
};


export type MutationUpdateManyEmailCampaignTemplateArgs = {
  data: EmailCampaignTemplateUpdateManyMutationInput;
  where?: Maybe<EmailCampaignTemplateWhereInput>;
};


export type MutationCreateOneEmailCampaignLogArgs = {
  data: EmailCampaignLogCreateInput;
};


export type MutationUpdateOneEmailCampaignLogArgs = {
  data: EmailCampaignLogUpdateInput;
  where: EmailCampaignLogWhereUniqueInput;
};


export type MutationDeleteOneEmailCampaignLogArgs = {
  where: EmailCampaignLogWhereUniqueInput;
};


export type MutationUpsertOneEmailCampaignLogArgs = {
  where: EmailCampaignLogWhereUniqueInput;
  create: EmailCampaignLogCreateInput;
  update: EmailCampaignLogUpdateInput;
};


export type MutationDeleteManyEmailCampaignLogArgs = {
  where?: Maybe<EmailCampaignLogWhereInput>;
};


export type MutationUpdateManyEmailCampaignLogArgs = {
  data: EmailCampaignLogUpdateManyMutationInput;
  where?: Maybe<EmailCampaignLogWhereInput>;
};


export type MutationCreateOneEmailCampaignUnsubscribeArgs = {
  data: EmailCampaignUnsubscribeCreateInput;
};


export type MutationUpdateOneEmailCampaignUnsubscribeArgs = {
  data: EmailCampaignUnsubscribeUpdateInput;
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};


export type MutationDeleteOneEmailCampaignUnsubscribeArgs = {
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};


export type MutationUpsertOneEmailCampaignUnsubscribeArgs = {
  where: EmailCampaignUnsubscribeWhereUniqueInput;
  create: EmailCampaignUnsubscribeCreateInput;
  update: EmailCampaignUnsubscribeUpdateInput;
};


export type MutationDeleteManyEmailCampaignUnsubscribeArgs = {
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
};


export type MutationUpdateManyEmailCampaignUnsubscribeArgs = {
  data: EmailCampaignUnsubscribeUpdateManyMutationInput;
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
};


export type MutationCreateOneCanonicalSpeciesArgs = {
  data: CanonicalSpeciesCreateInput;
};


export type MutationUpdateOneCanonicalSpeciesArgs = {
  data: CanonicalSpeciesUpdateInput;
  where: CanonicalSpeciesWhereUniqueInput;
};


export type MutationDeleteOneCanonicalSpeciesArgs = {
  where: CanonicalSpeciesWhereUniqueInput;
};


export type MutationUpsertOneCanonicalSpeciesArgs = {
  where: CanonicalSpeciesWhereUniqueInput;
  create: CanonicalSpeciesCreateInput;
  update: CanonicalSpeciesUpdateInput;
};


export type MutationDeleteManyCanonicalSpeciesArgs = {
  where?: Maybe<CanonicalSpeciesWhereInput>;
};


export type MutationUpdateManyCanonicalSpeciesArgs = {
  data: CanonicalSpeciesUpdateManyMutationInput;
  where?: Maybe<CanonicalSpeciesWhereInput>;
};


export type MutationCreateOneCanonicalBreedArgs = {
  data: CanonicalBreedCreateInput;
};


export type MutationUpdateOneCanonicalBreedArgs = {
  data: CanonicalBreedUpdateInput;
  where: CanonicalBreedWhereUniqueInput;
};


export type MutationDeleteOneCanonicalBreedArgs = {
  where: CanonicalBreedWhereUniqueInput;
};


export type MutationUpsertOneCanonicalBreedArgs = {
  where: CanonicalBreedWhereUniqueInput;
  create: CanonicalBreedCreateInput;
  update: CanonicalBreedUpdateInput;
};


export type MutationDeleteManyCanonicalBreedArgs = {
  where?: Maybe<CanonicalBreedWhereInput>;
};


export type MutationUpdateManyCanonicalBreedArgs = {
  data: CanonicalBreedUpdateManyMutationInput;
  where?: Maybe<CanonicalBreedWhereInput>;
};


export type MutationCreateOneCanonicalCanineBreedProfileArgs = {
  data: CanonicalCanineBreedProfileCreateInput;
};


export type MutationUpdateOneCanonicalCanineBreedProfileArgs = {
  data: CanonicalCanineBreedProfileUpdateInput;
  where: CanonicalCanineBreedProfileWhereUniqueInput;
};


export type MutationDeleteOneCanonicalCanineBreedProfileArgs = {
  where: CanonicalCanineBreedProfileWhereUniqueInput;
};


export type MutationUpsertOneCanonicalCanineBreedProfileArgs = {
  where: CanonicalCanineBreedProfileWhereUniqueInput;
  create: CanonicalCanineBreedProfileCreateInput;
  update: CanonicalCanineBreedProfileUpdateInput;
};


export type MutationDeleteManyCanonicalCanineBreedProfileArgs = {
  where?: Maybe<CanonicalCanineBreedProfileWhereInput>;
};


export type MutationUpdateManyCanonicalCanineBreedProfileArgs = {
  data: CanonicalCanineBreedProfileUpdateManyMutationInput;
  where?: Maybe<CanonicalCanineBreedProfileWhereInput>;
};


export type MutationCreateOneCanonicalServiceArgs = {
  data: CanonicalServiceCreateInput;
};


export type MutationUpdateOneCanonicalServiceArgs = {
  data: CanonicalServiceUpdateInput;
  where: CanonicalServiceWhereUniqueInput;
};


export type MutationDeleteOneCanonicalServiceArgs = {
  where: CanonicalServiceWhereUniqueInput;
};


export type MutationUpsertOneCanonicalServiceArgs = {
  where: CanonicalServiceWhereUniqueInput;
  create: CanonicalServiceCreateInput;
  update: CanonicalServiceUpdateInput;
};


export type MutationDeleteManyCanonicalServiceArgs = {
  where?: Maybe<CanonicalServiceWhereInput>;
};


export type MutationUpdateManyCanonicalServiceArgs = {
  data: CanonicalServiceUpdateManyMutationInput;
  where?: Maybe<CanonicalServiceWhereInput>;
};


export type MutationCreateOneChemicalCompoundArgs = {
  data: ChemicalCompoundCreateInput;
};


export type MutationUpdateOneChemicalCompoundArgs = {
  data: ChemicalCompoundUpdateInput;
  where: ChemicalCompoundWhereUniqueInput;
};


export type MutationDeleteOneChemicalCompoundArgs = {
  where: ChemicalCompoundWhereUniqueInput;
};


export type MutationUpsertOneChemicalCompoundArgs = {
  where: ChemicalCompoundWhereUniqueInput;
  create: ChemicalCompoundCreateInput;
  update: ChemicalCompoundUpdateInput;
};


export type MutationDeleteManyChemicalCompoundArgs = {
  where?: Maybe<ChemicalCompoundWhereInput>;
};


export type MutationUpdateManyChemicalCompoundArgs = {
  data: ChemicalCompoundUpdateManyMutationInput;
  where?: Maybe<ChemicalCompoundWhereInput>;
};


export type MutationCreateOneActiveIngredientArgs = {
  data: ActiveIngredientCreateInput;
};


export type MutationUpdateOneActiveIngredientArgs = {
  data: ActiveIngredientUpdateInput;
  where: ActiveIngredientWhereUniqueInput;
};


export type MutationDeleteOneActiveIngredientArgs = {
  where: ActiveIngredientWhereUniqueInput;
};


export type MutationUpsertOneActiveIngredientArgs = {
  where: ActiveIngredientWhereUniqueInput;
  create: ActiveIngredientCreateInput;
  update: ActiveIngredientUpdateInput;
};


export type MutationDeleteManyActiveIngredientArgs = {
  where?: Maybe<ActiveIngredientWhereInput>;
};


export type MutationUpdateManyActiveIngredientArgs = {
  data: ActiveIngredientUpdateManyMutationInput;
  where?: Maybe<ActiveIngredientWhereInput>;
};


export type MutationCreateOneProductArgs = {
  data: ProductCreateInput;
};


export type MutationUpdateOneProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationDeleteOneProductArgs = {
  where: ProductWhereUniqueInput;
};


export type MutationUpsertOneProductArgs = {
  where: ProductWhereUniqueInput;
  create: ProductCreateInput;
  update: ProductUpdateInput;
};


export type MutationDeleteManyProductArgs = {
  where?: Maybe<ProductWhereInput>;
};


export type MutationUpdateManyProductArgs = {
  data: ProductUpdateManyMutationInput;
  where?: Maybe<ProductWhereInput>;
};


export type MutationCreateOneInformPartnerArgs = {
  data: InformPartnerCreateInput;
};


export type MutationUpdateOneInformPartnerArgs = {
  data: InformPartnerUpdateInput;
  where: InformPartnerWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerArgs = {
  where: InformPartnerWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerArgs = {
  where: InformPartnerWhereUniqueInput;
  create: InformPartnerCreateInput;
  update: InformPartnerUpdateInput;
};


export type MutationDeleteManyInformPartnerArgs = {
  where?: Maybe<InformPartnerWhereInput>;
};


export type MutationUpdateManyInformPartnerArgs = {
  data: InformPartnerUpdateManyMutationInput;
  where?: Maybe<InformPartnerWhereInput>;
};


export type MutationCreateOneInformPartnerIntegrationArgs = {
  data: InformPartnerIntegrationCreateInput;
};


export type MutationUpdateOneInformPartnerIntegrationArgs = {
  data: InformPartnerIntegrationUpdateInput;
  where: InformPartnerIntegrationWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerIntegrationArgs = {
  where: InformPartnerIntegrationWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerIntegrationArgs = {
  where: InformPartnerIntegrationWhereUniqueInput;
  create: InformPartnerIntegrationCreateInput;
  update: InformPartnerIntegrationUpdateInput;
};


export type MutationDeleteManyInformPartnerIntegrationArgs = {
  where?: Maybe<InformPartnerIntegrationWhereInput>;
};


export type MutationUpdateManyInformPartnerIntegrationArgs = {
  data: InformPartnerIntegrationUpdateManyMutationInput;
  where?: Maybe<InformPartnerIntegrationWhereInput>;
};


export type MutationCreateOneInformPartnerIntegrationModelArgs = {
  data: InformPartnerIntegrationModelCreateInput;
};


export type MutationUpdateOneInformPartnerIntegrationModelArgs = {
  data: InformPartnerIntegrationModelUpdateInput;
  where: InformPartnerIntegrationModelWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerIntegrationModelArgs = {
  where: InformPartnerIntegrationModelWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerIntegrationModelArgs = {
  where: InformPartnerIntegrationModelWhereUniqueInput;
  create: InformPartnerIntegrationModelCreateInput;
  update: InformPartnerIntegrationModelUpdateInput;
};


export type MutationDeleteManyInformPartnerIntegrationModelArgs = {
  where?: Maybe<InformPartnerIntegrationModelWhereInput>;
};


export type MutationUpdateManyInformPartnerIntegrationModelArgs = {
  data: InformPartnerIntegrationModelUpdateManyMutationInput;
  where?: Maybe<InformPartnerIntegrationModelWhereInput>;
};


export type MutationCreateOneInformPartnerUserArgs = {
  data: InformPartnerUserCreateInput;
};


export type MutationUpdateOneInformPartnerUserArgs = {
  data: InformPartnerUserUpdateInput;
  where: InformPartnerUserWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerUserArgs = {
  where: InformPartnerUserWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerUserArgs = {
  where: InformPartnerUserWhereUniqueInput;
  create: InformPartnerUserCreateInput;
  update: InformPartnerUserUpdateInput;
};


export type MutationDeleteManyInformPartnerUserArgs = {
  where?: Maybe<InformPartnerUserWhereInput>;
};


export type MutationUpdateManyInformPartnerUserArgs = {
  data: InformPartnerUserUpdateManyMutationInput;
  where?: Maybe<InformPartnerUserWhereInput>;
};


export type MutationCreateOneInformPartnerUserRoleArgs = {
  data: InformPartnerUserRoleCreateInput;
};


export type MutationUpdateOneInformPartnerUserRoleArgs = {
  data: InformPartnerUserRoleUpdateInput;
  where: InformPartnerUserRoleWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerUserRoleArgs = {
  where: InformPartnerUserRoleWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerUserRoleArgs = {
  where: InformPartnerUserRoleWhereUniqueInput;
  create: InformPartnerUserRoleCreateInput;
  update: InformPartnerUserRoleUpdateInput;
};


export type MutationDeleteManyInformPartnerUserRoleArgs = {
  where?: Maybe<InformPartnerUserRoleWhereInput>;
};


export type MutationUpdateManyInformPartnerUserRoleArgs = {
  data: InformPartnerUserRoleUpdateManyMutationInput;
  where?: Maybe<InformPartnerUserRoleWhereInput>;
};


export type MutationCreateOneBoehringerIngelheimTerritoryArgs = {
  data: BoehringerIngelheimTerritoryCreateInput;
};


export type MutationUpdateOneBoehringerIngelheimTerritoryArgs = {
  data: BoehringerIngelheimTerritoryUpdateInput;
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};


export type MutationDeleteOneBoehringerIngelheimTerritoryArgs = {
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};


export type MutationUpsertOneBoehringerIngelheimTerritoryArgs = {
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
  create: BoehringerIngelheimTerritoryCreateInput;
  update: BoehringerIngelheimTerritoryUpdateInput;
};


export type MutationDeleteManyBoehringerIngelheimTerritoryArgs = {
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
};


export type MutationUpdateManyBoehringerIngelheimTerritoryArgs = {
  data: BoehringerIngelheimTerritoryUpdateManyMutationInput;
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
};


export type MutationCreateOneBoehringerIngelheimCustomerAlignmentArgs = {
  data: BoehringerIngelheimCustomerAlignmentCreateInput;
};


export type MutationUpdateOneBoehringerIngelheimCustomerAlignmentArgs = {
  data: BoehringerIngelheimCustomerAlignmentUpdateInput;
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
};


export type MutationDeleteOneBoehringerIngelheimCustomerAlignmentArgs = {
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
};


export type MutationUpsertOneBoehringerIngelheimCustomerAlignmentArgs = {
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
  create: BoehringerIngelheimCustomerAlignmentCreateInput;
  update: BoehringerIngelheimCustomerAlignmentUpdateInput;
};


export type MutationDeleteManyBoehringerIngelheimCustomerAlignmentArgs = {
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
};


export type MutationUpdateManyBoehringerIngelheimCustomerAlignmentArgs = {
  data: BoehringerIngelheimCustomerAlignmentUpdateManyMutationInput;
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
};


export type MutationCreateOneInformPartnerCustomerArgs = {
  data: InformPartnerCustomerCreateInput;
};


export type MutationUpdateOneInformPartnerCustomerArgs = {
  data: InformPartnerCustomerUpdateInput;
  where: InformPartnerCustomerWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerCustomerArgs = {
  where: InformPartnerCustomerWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerCustomerArgs = {
  where: InformPartnerCustomerWhereUniqueInput;
  create: InformPartnerCustomerCreateInput;
  update: InformPartnerCustomerUpdateInput;
};


export type MutationDeleteManyInformPartnerCustomerArgs = {
  where?: Maybe<InformPartnerCustomerWhereInput>;
};


export type MutationUpdateManyInformPartnerCustomerArgs = {
  data: InformPartnerCustomerUpdateManyMutationInput;
  where?: Maybe<InformPartnerCustomerWhereInput>;
};


export type MutationCreateOneInformPartnerProgramArgs = {
  data: InformPartnerProgramCreateInput;
};


export type MutationUpdateOneInformPartnerProgramArgs = {
  data: InformPartnerProgramUpdateInput;
  where: InformPartnerProgramWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerProgramArgs = {
  where: InformPartnerProgramWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerProgramArgs = {
  where: InformPartnerProgramWhereUniqueInput;
  create: InformPartnerProgramCreateInput;
  update: InformPartnerProgramUpdateInput;
};


export type MutationDeleteManyInformPartnerProgramArgs = {
  where?: Maybe<InformPartnerProgramWhereInput>;
};


export type MutationUpdateManyInformPartnerProgramArgs = {
  data: InformPartnerProgramUpdateManyMutationInput;
  where?: Maybe<InformPartnerProgramWhereInput>;
};


export type MutationCreateOneInformCampaignGroupArgs = {
  data: InformCampaignGroupCreateInput;
};


export type MutationUpdateOneInformCampaignGroupArgs = {
  data: InformCampaignGroupUpdateInput;
  where: InformCampaignGroupWhereUniqueInput;
};


export type MutationDeleteOneInformCampaignGroupArgs = {
  where: InformCampaignGroupWhereUniqueInput;
};


export type MutationUpsertOneInformCampaignGroupArgs = {
  where: InformCampaignGroupWhereUniqueInput;
  create: InformCampaignGroupCreateInput;
  update: InformCampaignGroupUpdateInput;
};


export type MutationDeleteManyInformCampaignGroupArgs = {
  where?: Maybe<InformCampaignGroupWhereInput>;
};


export type MutationUpdateManyInformCampaignGroupArgs = {
  data: InformCampaignGroupUpdateManyMutationInput;
  where?: Maybe<InformCampaignGroupWhereInput>;
};


export type MutationCreateOneInformAudienceDefinitionArgs = {
  data: InformAudienceDefinitionCreateInput;
};


export type MutationUpdateOneInformAudienceDefinitionArgs = {
  data: InformAudienceDefinitionUpdateInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};


export type MutationDeleteOneInformAudienceDefinitionArgs = {
  where: InformAudienceDefinitionWhereUniqueInput;
};


export type MutationUpsertOneInformAudienceDefinitionArgs = {
  where: InformAudienceDefinitionWhereUniqueInput;
  create: InformAudienceDefinitionCreateInput;
  update: InformAudienceDefinitionUpdateInput;
};


export type MutationDeleteManyInformAudienceDefinitionArgs = {
  where?: Maybe<InformAudienceDefinitionWhereInput>;
};


export type MutationUpdateManyInformAudienceDefinitionArgs = {
  data: InformAudienceDefinitionUpdateManyMutationInput;
  where?: Maybe<InformAudienceDefinitionWhereInput>;
};


export type MutationCreateOneInformCampaignArgs = {
  data: InformCampaignCreateInput;
};


export type MutationUpdateOneInformCampaignArgs = {
  data: InformCampaignUpdateInput;
  where: InformCampaignWhereUniqueInput;
};


export type MutationDeleteOneInformCampaignArgs = {
  where: InformCampaignWhereUniqueInput;
};


export type MutationUpsertOneInformCampaignArgs = {
  where: InformCampaignWhereUniqueInput;
  create: InformCampaignCreateInput;
  update: InformCampaignUpdateInput;
};


export type MutationDeleteManyInformCampaignArgs = {
  where?: Maybe<InformCampaignWhereInput>;
};


export type MutationUpdateManyInformCampaignArgs = {
  data: InformCampaignUpdateManyMutationInput;
  where?: Maybe<InformCampaignWhereInput>;
};


export type MutationCreateOneInformScheduledCampaignArgs = {
  data: InformScheduledCampaignCreateInput;
};


export type MutationUpdateOneInformScheduledCampaignArgs = {
  data: InformScheduledCampaignUpdateInput;
  where: InformScheduledCampaignWhereUniqueInput;
};


export type MutationDeleteOneInformScheduledCampaignArgs = {
  where: InformScheduledCampaignWhereUniqueInput;
};


export type MutationUpsertOneInformScheduledCampaignArgs = {
  where: InformScheduledCampaignWhereUniqueInput;
  create: InformScheduledCampaignCreateInput;
  update: InformScheduledCampaignUpdateInput;
};


export type MutationDeleteManyInformScheduledCampaignArgs = {
  where?: Maybe<InformScheduledCampaignWhereInput>;
};


export type MutationUpdateManyInformScheduledCampaignArgs = {
  data: InformScheduledCampaignUpdateManyMutationInput;
  where?: Maybe<InformScheduledCampaignWhereInput>;
};


export type MutationCreateOneInformAutomationCampaignArgs = {
  data: InformAutomationCampaignCreateInput;
};


export type MutationUpdateOneInformAutomationCampaignArgs = {
  data: InformAutomationCampaignUpdateInput;
  where: InformAutomationCampaignWhereUniqueInput;
};


export type MutationDeleteOneInformAutomationCampaignArgs = {
  where: InformAutomationCampaignWhereUniqueInput;
};


export type MutationUpsertOneInformAutomationCampaignArgs = {
  where: InformAutomationCampaignWhereUniqueInput;
  create: InformAutomationCampaignCreateInput;
  update: InformAutomationCampaignUpdateInput;
};


export type MutationDeleteManyInformAutomationCampaignArgs = {
  where?: Maybe<InformAutomationCampaignWhereInput>;
};


export type MutationCreateOneInformEmailTemplateArgs = {
  data: InformEmailTemplateCreateInput;
};


export type MutationUpdateOneInformEmailTemplateArgs = {
  data: InformEmailTemplateUpdateInput;
  where: InformEmailTemplateWhereUniqueInput;
};


export type MutationDeleteOneInformEmailTemplateArgs = {
  where: InformEmailTemplateWhereUniqueInput;
};


export type MutationUpsertOneInformEmailTemplateArgs = {
  where: InformEmailTemplateWhereUniqueInput;
  create: InformEmailTemplateCreateInput;
  update: InformEmailTemplateUpdateInput;
};


export type MutationDeleteManyInformEmailTemplateArgs = {
  where?: Maybe<InformEmailTemplateWhereInput>;
};


export type MutationUpdateManyInformEmailTemplateArgs = {
  data: InformEmailTemplateUpdateManyMutationInput;
  where?: Maybe<InformEmailTemplateWhereInput>;
};


export type MutationCreateOneInformCampaignExecutionArgs = {
  data: InformCampaignExecutionCreateInput;
};


export type MutationUpdateOneInformCampaignExecutionArgs = {
  data: InformCampaignExecutionUpdateInput;
  where: InformCampaignExecutionWhereUniqueInput;
};


export type MutationDeleteOneInformCampaignExecutionArgs = {
  where: InformCampaignExecutionWhereUniqueInput;
};


export type MutationUpsertOneInformCampaignExecutionArgs = {
  where: InformCampaignExecutionWhereUniqueInput;
  create: InformCampaignExecutionCreateInput;
  update: InformCampaignExecutionUpdateInput;
};


export type MutationDeleteManyInformCampaignExecutionArgs = {
  where?: Maybe<InformCampaignExecutionWhereInput>;
};


export type MutationUpdateManyInformCampaignExecutionArgs = {
  data: InformCampaignExecutionUpdateManyMutationInput;
  where?: Maybe<InformCampaignExecutionWhereInput>;
};


export type MutationCreateOneInformPartnerProgramEnrollmentArgs = {
  data: InformPartnerProgramEnrollmentCreateInput;
};


export type MutationUpdateOneInformPartnerProgramEnrollmentArgs = {
  data: InformPartnerProgramEnrollmentUpdateInput;
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerProgramEnrollmentArgs = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerProgramEnrollmentArgs = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
  create: InformPartnerProgramEnrollmentCreateInput;
  update: InformPartnerProgramEnrollmentUpdateInput;
};


export type MutationDeleteManyInformPartnerProgramEnrollmentArgs = {
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};


export type MutationUpdateManyInformPartnerProgramEnrollmentArgs = {
  data: InformPartnerProgramEnrollmentUpdateManyMutationInput;
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};


export type MutationCreateOneInformCampaignEnrollmentArgs = {
  data: InformCampaignEnrollmentCreateInput;
};


export type MutationUpdateOneInformCampaignEnrollmentArgs = {
  data: InformCampaignEnrollmentUpdateInput;
  where: InformCampaignEnrollmentWhereUniqueInput;
};


export type MutationDeleteOneInformCampaignEnrollmentArgs = {
  where: InformCampaignEnrollmentWhereUniqueInput;
};


export type MutationUpsertOneInformCampaignEnrollmentArgs = {
  where: InformCampaignEnrollmentWhereUniqueInput;
  create: InformCampaignEnrollmentCreateInput;
  update: InformCampaignEnrollmentUpdateInput;
};


export type MutationDeleteManyInformCampaignEnrollmentArgs = {
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
};


export type MutationUpdateManyInformCampaignEnrollmentArgs = {
  data: InformCampaignEnrollmentUpdateManyMutationInput;
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
};


export type MutationCreateOneInformCampaignEnrollmentCanonicalServiceArgs = {
  data: InformCampaignEnrollmentCanonicalServiceCreateInput;
};


export type MutationUpdateOneInformCampaignEnrollmentCanonicalServiceArgs = {
  data: InformCampaignEnrollmentCanonicalServiceUpdateInput;
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
};


export type MutationDeleteOneInformCampaignEnrollmentCanonicalServiceArgs = {
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
};


export type MutationUpsertOneInformCampaignEnrollmentCanonicalServiceArgs = {
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
  create: InformCampaignEnrollmentCanonicalServiceCreateInput;
  update: InformCampaignEnrollmentCanonicalServiceUpdateInput;
};


export type MutationDeleteManyInformCampaignEnrollmentCanonicalServiceArgs = {
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
};


export type MutationUpdateManyInformCampaignEnrollmentCanonicalServiceArgs = {
  data: InformCampaignEnrollmentCanonicalServiceUpdateManyMutationInput;
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
};


export type MutationCreateOneInformPartnerCustomerPharmacyProviderArgs = {
  data: InformPartnerCustomerPharmacyProviderCreateInput;
};


export type MutationUpdateOneInformPartnerCustomerPharmacyProviderArgs = {
  data: InformPartnerCustomerPharmacyProviderUpdateInput;
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerCustomerPharmacyProviderArgs = {
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerCustomerPharmacyProviderArgs = {
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
  create: InformPartnerCustomerPharmacyProviderCreateInput;
  update: InformPartnerCustomerPharmacyProviderUpdateInput;
};


export type MutationDeleteManyInformPartnerCustomerPharmacyProviderArgs = {
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
};


export type MutationUpdateManyInformPartnerCustomerPharmacyProviderArgs = {
  data: InformPartnerCustomerPharmacyProviderUpdateManyMutationInput;
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
};


export type MutationCreateOneInformEntityHistoryArgs = {
  data: InformEntityHistoryCreateInput;
};


export type MutationUpdateOneInformEntityHistoryArgs = {
  data: InformEntityHistoryUpdateInput;
  where: InformEntityHistoryWhereUniqueInput;
};


export type MutationDeleteOneInformEntityHistoryArgs = {
  where: InformEntityHistoryWhereUniqueInput;
};


export type MutationUpsertOneInformEntityHistoryArgs = {
  where: InformEntityHistoryWhereUniqueInput;
  create: InformEntityHistoryCreateInput;
  update: InformEntityHistoryUpdateInput;
};


export type MutationDeleteManyInformEntityHistoryArgs = {
  where?: Maybe<InformEntityHistoryWhereInput>;
};


export type MutationUpdateManyInformEntityHistoryArgs = {
  data: InformEntityHistoryUpdateManyMutationInput;
  where?: Maybe<InformEntityHistoryWhereInput>;
};


export type MutationCreateOneClinicBlockedPhoneNumberArgs = {
  data: ClinicBlockedPhoneNumberCreateInput;
};


export type MutationUpdateOneClinicBlockedPhoneNumberArgs = {
  data: ClinicBlockedPhoneNumberUpdateInput;
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};


export type MutationDeleteOneClinicBlockedPhoneNumberArgs = {
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};


export type MutationUpsertOneClinicBlockedPhoneNumberArgs = {
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
  create: ClinicBlockedPhoneNumberCreateInput;
  update: ClinicBlockedPhoneNumberUpdateInput;
};


export type MutationDeleteManyClinicBlockedPhoneNumberArgs = {
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
};


export type MutationUpdateManyClinicBlockedPhoneNumberArgs = {
  data: ClinicBlockedPhoneNumberUpdateManyMutationInput;
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
};


export type MutationCreateOneChannelMessageAutoResponseArgs = {
  data: ChannelMessageAutoResponseCreateInput;
};


export type MutationUpdateOneChannelMessageAutoResponseArgs = {
  data: ChannelMessageAutoResponseUpdateInput;
  where: ChannelMessageAutoResponseWhereUniqueInput;
};


export type MutationDeleteOneChannelMessageAutoResponseArgs = {
  where: ChannelMessageAutoResponseWhereUniqueInput;
};


export type MutationUpsertOneChannelMessageAutoResponseArgs = {
  where: ChannelMessageAutoResponseWhereUniqueInput;
  create: ChannelMessageAutoResponseCreateInput;
  update: ChannelMessageAutoResponseUpdateInput;
};


export type MutationDeleteManyChannelMessageAutoResponseArgs = {
  where?: Maybe<ChannelMessageAutoResponseWhereInput>;
};


export type MutationUpdateManyChannelMessageAutoResponseArgs = {
  data: ChannelMessageAutoResponseUpdateManyMutationInput;
  where?: Maybe<ChannelMessageAutoResponseWhereInput>;
};


export type MutationCreateOneCareSubscriptionTrueUpLogEntryArgs = {
  data: CareSubscriptionTrueUpLogEntryCreateInput;
};


export type MutationUpdateOneCareSubscriptionTrueUpLogEntryArgs = {
  data: CareSubscriptionTrueUpLogEntryUpdateInput;
  where: CareSubscriptionTrueUpLogEntryWhereUniqueInput;
};


export type MutationDeleteOneCareSubscriptionTrueUpLogEntryArgs = {
  where: CareSubscriptionTrueUpLogEntryWhereUniqueInput;
};


export type MutationUpsertOneCareSubscriptionTrueUpLogEntryArgs = {
  where: CareSubscriptionTrueUpLogEntryWhereUniqueInput;
  create: CareSubscriptionTrueUpLogEntryCreateInput;
  update: CareSubscriptionTrueUpLogEntryUpdateInput;
};


export type MutationDeleteManyCareSubscriptionTrueUpLogEntryArgs = {
  where?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
};


export type MutationUpdateManyCareSubscriptionTrueUpLogEntryArgs = {
  data: CareSubscriptionTrueUpLogEntryUpdateManyMutationInput;
  where?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
};


export type MutationCreateOneCareSubscriptionTrueUpArgs = {
  data: CareSubscriptionTrueUpCreateInput;
};


export type MutationUpdateOneCareSubscriptionTrueUpArgs = {
  data: CareSubscriptionTrueUpUpdateInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};


export type MutationDeleteOneCareSubscriptionTrueUpArgs = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
};


export type MutationUpsertOneCareSubscriptionTrueUpArgs = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
  create: CareSubscriptionTrueUpCreateInput;
  update: CareSubscriptionTrueUpUpdateInput;
};


export type MutationDeleteManyCareSubscriptionTrueUpArgs = {
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
};


export type MutationUpdateManyCareSubscriptionTrueUpArgs = {
  data: CareSubscriptionTrueUpUpdateManyMutationInput;
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
};


export type MutationCreateOneHillsToHomeApiLogArgs = {
  data: HillsToHomeApiLogCreateInput;
};


export type MutationUpdateOneHillsToHomeApiLogArgs = {
  data: HillsToHomeApiLogUpdateInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};


export type MutationDeleteOneHillsToHomeApiLogArgs = {
  where: HillsToHomeApiLogWhereUniqueInput;
};


export type MutationUpsertOneHillsToHomeApiLogArgs = {
  where: HillsToHomeApiLogWhereUniqueInput;
  create: HillsToHomeApiLogCreateInput;
  update: HillsToHomeApiLogUpdateInput;
};


export type MutationDeleteManyHillsToHomeApiLogArgs = {
  where?: Maybe<HillsToHomeApiLogWhereInput>;
};


export type MutationUpdateManyHillsToHomeApiLogArgs = {
  data: HillsToHomeApiLogUpdateManyMutationInput;
  where?: Maybe<HillsToHomeApiLogWhereInput>;
};


export type MutationCreateOneBoardFilterColumnArgs = {
  data: BoardFilterColumnCreateInput;
};


export type MutationUpdateOneBoardFilterColumnArgs = {
  data: BoardFilterColumnUpdateInput;
  where: BoardFilterColumnWhereUniqueInput;
};


export type MutationDeleteOneBoardFilterColumnArgs = {
  where: BoardFilterColumnWhereUniqueInput;
};


export type MutationUpsertOneBoardFilterColumnArgs = {
  where: BoardFilterColumnWhereUniqueInput;
  create: BoardFilterColumnCreateInput;
  update: BoardFilterColumnUpdateInput;
};


export type MutationDeleteManyBoardFilterColumnArgs = {
  where?: Maybe<BoardFilterColumnWhereInput>;
};


export type MutationUpdateManyBoardFilterColumnArgs = {
  data: BoardFilterColumnUpdateManyMutationInput;
  where?: Maybe<BoardFilterColumnWhereInput>;
};


export type MutationCreateOneBoardFilterSpecificDateRangeArgs = {
  data: BoardFilterSpecificDateRangeCreateInput;
};


export type MutationUpdateOneBoardFilterSpecificDateRangeArgs = {
  data: BoardFilterSpecificDateRangeUpdateInput;
  where: BoardFilterSpecificDateRangeWhereUniqueInput;
};


export type MutationDeleteOneBoardFilterSpecificDateRangeArgs = {
  where: BoardFilterSpecificDateRangeWhereUniqueInput;
};


export type MutationUpsertOneBoardFilterSpecificDateRangeArgs = {
  where: BoardFilterSpecificDateRangeWhereUniqueInput;
  create: BoardFilterSpecificDateRangeCreateInput;
  update: BoardFilterSpecificDateRangeUpdateInput;
};


export type MutationDeleteManyBoardFilterSpecificDateRangeArgs = {
  where?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
};


export type MutationUpdateManyBoardFilterSpecificDateRangeArgs = {
  data: BoardFilterSpecificDateRangeUpdateManyMutationInput;
  where?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
};


export type MutationCreateOneUserBoardFilterArgs = {
  data: UserBoardFilterCreateInput;
};


export type MutationUpdateOneUserBoardFilterArgs = {
  data: UserBoardFilterUpdateInput;
  where: UserBoardFilterWhereUniqueInput;
};


export type MutationDeleteOneUserBoardFilterArgs = {
  where: UserBoardFilterWhereUniqueInput;
};


export type MutationUpsertOneUserBoardFilterArgs = {
  where: UserBoardFilterWhereUniqueInput;
  create: UserBoardFilterCreateInput;
  update: UserBoardFilterUpdateInput;
};


export type MutationDeleteManyUserBoardFilterArgs = {
  where?: Maybe<UserBoardFilterWhereInput>;
};


export type MutationUpdateManyUserBoardFilterArgs = {
  data: UserBoardFilterUpdateManyMutationInput;
  where?: Maybe<UserBoardFilterWhereInput>;
};


export type MutationCreateOneSegmentArgs = {
  data: SegmentCreateInput;
};


export type MutationUpdateOneSegmentArgs = {
  data: SegmentUpdateInput;
  where: SegmentWhereUniqueInput;
};


export type MutationDeleteOneSegmentArgs = {
  where: SegmentWhereUniqueInput;
};


export type MutationUpsertOneSegmentArgs = {
  where: SegmentWhereUniqueInput;
  create: SegmentCreateInput;
  update: SegmentUpdateInput;
};


export type MutationDeleteManySegmentArgs = {
  where?: Maybe<SegmentWhereInput>;
};


export type MutationUpdateManySegmentArgs = {
  data: SegmentUpdateManyMutationInput;
  where?: Maybe<SegmentWhereInput>;
};


export type MutationCreateOneNonPrismaSchemaScriptRanArgs = {
  data: NonPrismaSchemaScriptRanCreateInput;
};


export type MutationUpdateOneNonPrismaSchemaScriptRanArgs = {
  data: NonPrismaSchemaScriptRanUpdateInput;
  where: NonPrismaSchemaScriptRanWhereUniqueInput;
};


export type MutationDeleteOneNonPrismaSchemaScriptRanArgs = {
  where: NonPrismaSchemaScriptRanWhereUniqueInput;
};


export type MutationUpsertOneNonPrismaSchemaScriptRanArgs = {
  where: NonPrismaSchemaScriptRanWhereUniqueInput;
  create: NonPrismaSchemaScriptRanCreateInput;
  update: NonPrismaSchemaScriptRanUpdateInput;
};


export type MutationDeleteManyNonPrismaSchemaScriptRanArgs = {
  where?: Maybe<NonPrismaSchemaScriptRanWhereInput>;
};


export type MutationUpdateManyNonPrismaSchemaScriptRanArgs = {
  data: NonPrismaSchemaScriptRanUpdateManyMutationInput;
  where?: Maybe<NonPrismaSchemaScriptRanWhereInput>;
};


export type MutationCreateOneVetsourceInstallationArgs = {
  data: VetsourceInstallationCreateInput;
};


export type MutationUpdateOneVetsourceInstallationArgs = {
  data: VetsourceInstallationUpdateInput;
  where: VetsourceInstallationWhereUniqueInput;
};


export type MutationDeleteOneVetsourceInstallationArgs = {
  where: VetsourceInstallationWhereUniqueInput;
};


export type MutationUpsertOneVetsourceInstallationArgs = {
  where: VetsourceInstallationWhereUniqueInput;
  create: VetsourceInstallationCreateInput;
  update: VetsourceInstallationUpdateInput;
};


export type MutationDeleteManyVetsourceInstallationArgs = {
  where?: Maybe<VetsourceInstallationWhereInput>;
};


export type MutationUpdateManyVetsourceInstallationArgs = {
  data: VetsourceInstallationUpdateManyMutationInput;
  where?: Maybe<VetsourceInstallationWhereInput>;
};


export type MutationCreateOneEmailCampaignCustomListArgs = {
  data: EmailCampaignCustomListCreateInput;
};


export type MutationUpdateOneEmailCampaignCustomListArgs = {
  data: EmailCampaignCustomListUpdateInput;
  where: EmailCampaignCustomListWhereUniqueInput;
};


export type MutationDeleteOneEmailCampaignCustomListArgs = {
  where: EmailCampaignCustomListWhereUniqueInput;
};


export type MutationUpsertOneEmailCampaignCustomListArgs = {
  where: EmailCampaignCustomListWhereUniqueInput;
  create: EmailCampaignCustomListCreateInput;
  update: EmailCampaignCustomListUpdateInput;
};


export type MutationDeleteManyEmailCampaignCustomListArgs = {
  where?: Maybe<EmailCampaignCustomListWhereInput>;
};


export type MutationUpdateManyEmailCampaignCustomListArgs = {
  data: EmailCampaignCustomListUpdateManyMutationInput;
  where?: Maybe<EmailCampaignCustomListWhereInput>;
};


export type MutationCreateOneEmailCampaignCustomListRecipientArgs = {
  data: EmailCampaignCustomListRecipientCreateInput;
};


export type MutationUpdateOneEmailCampaignCustomListRecipientArgs = {
  data: EmailCampaignCustomListRecipientUpdateInput;
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
};


export type MutationDeleteOneEmailCampaignCustomListRecipientArgs = {
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
};


export type MutationUpsertOneEmailCampaignCustomListRecipientArgs = {
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
  create: EmailCampaignCustomListRecipientCreateInput;
  update: EmailCampaignCustomListRecipientUpdateInput;
};


export type MutationDeleteManyEmailCampaignCustomListRecipientArgs = {
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
};


export type MutationUpdateManyEmailCampaignCustomListRecipientArgs = {
  data: EmailCampaignCustomListRecipientUpdateManyMutationInput;
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
};


export type MutationCreateOneVetsourceRevenueCategoryArgs = {
  data: VetsourceRevenueCategoryCreateInput;
};


export type MutationUpdateOneVetsourceRevenueCategoryArgs = {
  data: VetsourceRevenueCategoryUpdateInput;
  where: VetsourceRevenueCategoryWhereUniqueInput;
};


export type MutationDeleteOneVetsourceRevenueCategoryArgs = {
  where: VetsourceRevenueCategoryWhereUniqueInput;
};


export type MutationUpsertOneVetsourceRevenueCategoryArgs = {
  where: VetsourceRevenueCategoryWhereUniqueInput;
  create: VetsourceRevenueCategoryCreateInput;
  update: VetsourceRevenueCategoryUpdateInput;
};


export type MutationDeleteManyVetsourceRevenueCategoryArgs = {
  where?: Maybe<VetsourceRevenueCategoryWhereInput>;
};


export type MutationUpdateManyVetsourceRevenueCategoryArgs = {
  data: VetsourceRevenueCategoryUpdateManyMutationInput;
  where?: Maybe<VetsourceRevenueCategoryWhereInput>;
};


export type MutationCreateOneVetsourceCodeTagArgs = {
  data: VetsourceCodeTagCreateInput;
};


export type MutationUpdateOneVetsourceCodeTagArgs = {
  data: VetsourceCodeTagUpdateInput;
  where: VetsourceCodeTagWhereUniqueInput;
};


export type MutationDeleteOneVetsourceCodeTagArgs = {
  where: VetsourceCodeTagWhereUniqueInput;
};


export type MutationUpsertOneVetsourceCodeTagArgs = {
  where: VetsourceCodeTagWhereUniqueInput;
  create: VetsourceCodeTagCreateInput;
  update: VetsourceCodeTagUpdateInput;
};


export type MutationDeleteManyVetsourceCodeTagArgs = {
  where?: Maybe<VetsourceCodeTagWhereInput>;
};


export type MutationUpdateManyVetsourceCodeTagArgs = {
  data: VetsourceCodeTagUpdateManyMutationInput;
  where?: Maybe<VetsourceCodeTagWhereInput>;
};


export type MutationCreateOneInformControlGroupParticipantArgs = {
  data: InformControlGroupParticipantCreateInput;
};


export type MutationUpdateOneInformControlGroupParticipantArgs = {
  data: InformControlGroupParticipantUpdateInput;
  where: InformControlGroupParticipantWhereUniqueInput;
};


export type MutationDeleteOneInformControlGroupParticipantArgs = {
  where: InformControlGroupParticipantWhereUniqueInput;
};


export type MutationUpsertOneInformControlGroupParticipantArgs = {
  where: InformControlGroupParticipantWhereUniqueInput;
  create: InformControlGroupParticipantCreateInput;
  update: InformControlGroupParticipantUpdateInput;
};


export type MutationDeleteManyInformControlGroupParticipantArgs = {
  where?: Maybe<InformControlGroupParticipantWhereInput>;
};


export type MutationUpdateManyInformControlGroupParticipantArgs = {
  data: InformControlGroupParticipantUpdateManyMutationInput;
  where?: Maybe<InformControlGroupParticipantWhereInput>;
};


export type MutationConfigureEmailCampaignsForClinicArgs = {
  data?: Maybe<ConfigureEmailCampaignsInput>;
};


export type MutationAddEmailCampaignsCareUrlFieldArgs = {
  clinicId: Scalars['String'];
};


export type MutationTriggerEmailCampaignsDataSyncArgs = {
  clinicId: Scalars['String'];
};


export type MutationConfirmAppointment2Args = {
  where?: Maybe<AppointmentWhereUniqueInput>;
};


export type MutationUpdateClinicAppointmentTypeSettingsArgs = {
  appointmentTypeId: Scalars['String'];
  displayName: Scalars['String'];
  showInPetPortal: Scalars['Boolean'];
};


export type MutationUpdateManyAppointmentTypeSortOrderArgs = {
  data?: Maybe<Array<AppointmentTypeWithOrder>>;
};


export type MutationResetAutomationRunArgs = {
  where?: Maybe<AutomationRunWhereUniqueInput>;
};


export type MutationGetSignedUploadUrlArgs = {
  data: GetSignedUploadInput;
};


export type MutationGetSignedDownloadUrlArgs = {
  assetUrl: Scalars['String'];
};


export type MutationEncryptAndUploadDataArgs = {
  body: Scalars['String'];
  purpose: EncryptedSignedUploadPurpose;
  extension: Scalars['String'];
};


export type MutationCreateOneCallRecordingArgs = {
  callSid: Scalars['String'];
};


export type MutationUpdateOneCallRecordingArgs = {
  where: CallRecordingWhereUniqueInput;
  data: CallRecordingUpdateInput;
};


export type MutationDeleteOneCallRecordingArgs = {
  where: CallRecordingWhereUniqueInput;
};


export type MutationToggleTwilioVideoCallRecordingStatusArgs = {
  data: VideoCallRecordingToggleInput;
};


export type MutationRequestCareLoginLinkArgs = {
  data: AuthLinkRequestInput;
};


export type MutationSendDirectedCareLoginLinkArgs = {
  data: DirectedAuthLinkInput;
};


export type MutationGetDirectedCareLoginLinkArgs = {
  data: DirectedAuthLinkInput;
};


export type MutationRedeemOneTimeCodeArgs = {
  data: RedeemOneTimeCodeInput;
};


export type MutationRequestOneTimeCodeArgs = {
  data?: Maybe<RequestOneTimeCodeInput>;
};


export type MutationSendCareSignUpEmailArgs = {
  data?: Maybe<SendCareSignUpEmailInput>;
};


export type MutationUpdateCareSubscriptionArgs = {
  data?: Maybe<UpdateCareSubscriptionInput>;
};


export type MutationCancelCareSubscriptionArgs = {
  data?: Maybe<CancelCareSubscriptionInput>;
};


export type MutationChangeCareSubscriptionPaymentMethodArgs = {
  data?: Maybe<ChangeCareSubscriptionPaymentMethodInput>;
};


export type MutationCreateCarePaymentMethodSetupArgs = {
  data?: Maybe<CreateCarePaymentMethodSetupInput>;
};


export type MutationCreateCareEnrollmentsAndSubscriptionsArgs = {
  data: CreateCareEnrollmentAndSubscriptionInput;
};


export type MutationSendEnrollmentEmailArgs = {
  data?: Maybe<SendEnrollmentEmailInput>;
};


export type MutationTransferCareSubscriptionArgs = {
  data?: Maybe<TransferSubscriptionInput>;
};


export type MutationPerformCarePlanEnrollmentUpgradeArgs = {
  data: PerformCarePlanEnrollmentUpgradeInput;
};


export type MutationFormSubmissionExportToPimsArgs = {
  data?: Maybe<FormSubmissionExportInput>;
};


export type MutationChannelExportToPimsArgs = {
  where: ChannelExportWhereInput;
};


export type MutationChannelBulkExportToPimsArgs = {
  where: Array<Maybe<ChannelExportWhereInput>>;
};


export type MutationAddChannelTagArgs = {
  channelId: Scalars['String'];
  tag?: Maybe<TagInput>;
};


export type MutationRemoveChannelTagArgs = {
  channelId: Scalars['String'];
  tagName?: Maybe<Scalars['String']>;
  tagType?: Maybe<TagType>;
};


export type MutationCreateTeamChannel2Args = {
  data: CreateTeamChannelInput;
};


export type MutationUpdateTeamChannel2Args = {
  data: ChannelUpdateInput;
  where: ChannelWhereUniqueInput;
};


export type MutationGetOrCreateActiveClientChannelArgs = {
  data: GetOrCreateActiveClientChannelInput;
  creationSource?: Maybe<ChannelCreationSourceInput>;
};


export type MutationGetOrCreateActiveChannelArgs = {
  data: GetOrCreateActiveChannelInput;
};


export type MutationCreateChannelFilterSelectionArgs = {
  data?: Maybe<CreateChannelFilterSelectionInput>;
};


export type MutationUpdateChannelFilterSelectionArgs = {
  data?: Maybe<UpdateChannelFilterSelectionInput>;
};


export type MutationDeleteChannelFilterSelectionArgs = {
  id: Scalars['String'];
};


export type MutationAddChannelMember2Args = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationRemoveChannelMember2Args = {
  channelMemberId: Scalars['String'];
};


export type MutationCreateChannelMessage2Args = {
  data: ChannelMessageCreateInput;
};


export type MutationCreateSystemChannelMessage2Args = {
  data: SystemMessageCreateInput;
};


export type MutationClearUnreadMessages2Args = {
  data: ClearUnreadMessagesInput;
};


export type MutationUpdateManyChannelStatusSortOrderArgs = {
  data?: Maybe<Array<ChannelStatusWithOrder>>;
};


export type MutationCancelClinicSubscriptionArgs = {
  data: ClinicCancellationInput;
  where: ClinicWhereUniqueInput;
};


export type MutationUpdateClinicPetWithoutPimsArgs = {
  data: ClinicPetUpdateInput;
  where: ClinicPetWhereUniqueInput;
};


export type MutationEnsurePetsPromotedArgs = {
  data?: Maybe<EnsurePetsPromotedInput>;
};


export type MutationSendClinicPetParentAuthLinkArgs = {
  data: SendClinicPetParentAuthLinkRequest;
};


export type MutationRedeemClinicPetParentAuthCodeArgs = {
  authCode: Scalars['String'];
};


export type MutationSetClinicPetParentPrivacyArgs = {
  clinicPetParentId: Scalars['String'];
};


export type MutationUpdateOneClinicPetParentAndEnqueuePhoneNumberLookupArgs = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUpdateInput;
};


export type MutationSendClinicPetParentAuthCodeArgs = {
  data: SendClinicPetParentAuthCodeRequest;
};


export type MutationClaimClinicPetParentAuthCodeArgs = {
  data: ClaimClinicPetParentAuthCodeRequest;
};


export type MutationOrderPhoneNumberArgs = {
  data: OrderPhoneNumberInput;
};


export type MutationRequestBitwerxIntegrationArgs = {
  data: RequestBitwerxIntegrationInput;
};


export type MutationUpdateClinicPostcardSettingArgs = {
  where?: Maybe<UpdateClinicPostcardSettingWhereInput>;
  data: UpdateClinicPostcardSettingDataInput;
};


export type MutationMergePetParentArgs = {
  data?: Maybe<MergePetParentInput>;
};


export type MutationUpdateClinicPetParentContactInfoFromPimsArgs = {
  where?: Maybe<ClinicPetParentWhereUniqueInput>;
};


export type MutationUpdateClinicSettingTransactionColumnArgs = {
  where: ClinicSettingWhereUniqueInput;
  data: ClinicSettingUpdateInput;
};


export type MutationVerifyClinicWidgetRequest2Args = {
  where: VerifyClinicWidgetRequestInput;
};


export type MutationCreateClinicWidgetRequest2Args = {
  data: ClinicWidgetRequestCreateInput;
};


export type MutationSendWidgetCodeToDeveloperArgs = {
  data: SendWidgetCodeToDeveloperInput;
};


export type MutationSendConsultationReceiptArgs = {
  data: SendConsultationReceiptInput;
};


export type MutationCreateDirectBookingAppointmentArgs = {
  data?: Maybe<AppointmentCreateInput>;
};


export type MutationCreateOneProviderAppointmentTypeRoomMapByNameArgs = {
  data: DirectBookingCreateOneProviderAppointmentTypeRoomMapByNameInput;
};


export type MutationSendEmailCampaignNowArgs = {
  data: SendEmailCampaignNowInput;
};


export type MutationUploadEmailCampaignCustomListArgs = {
  data: UploadEmailCampaignCustomListInput;
};


export type MutationDeleteEmailCampaignCustomListArgs = {
  data: DeleteEmailCampaignCustomListInput;
};


export type MutationDuplicateEmailCampaignArgs = {
  data: DuplicateEmailCampaignInput;
};


export type MutationCreateEmailTemplateVersionArgs = {
  data: CreateEmailTemplateVersionInput;
};


export type MutationSaveEmailTemplateVersionArgs = {
  data: SaveEmailTemplateVersionInput;
};


export type MutationSubmitForm2Args = {
  data: FormSubmissionUpdateInput;
  where: FormSubmissionWhereUniqueInput;
};


export type MutationSubmitPetParentRequestArgs = {
  data: SubmitPetParentRequestInput;
};


export type MutationPostCareInvoiceAccountCreditToPimsArgs = {
  data: WritebackCareInvoiceAccountCreditToPimsInput;
};


export type MutationFindOrCreateLoyaltyAccountForClinicPetParentArgs = {
  clinicPetParentId: Scalars['String'];
};


export type MutationSubmitLoyaltyPointGrantToAccountByUserArgs = {
  data: LoyaltyPointGrantByUser;
};


export type MutationDeactivateLoyaltyAccountArgs = {
  loyaltyAccountId: Scalars['String'];
};


export type MutationTriggerLoyaltyInvoiceProcessingArgs = {
  data: LoyaltyInvoiceTriggerInput;
};


export type MutationAddClinicToLoyaltyProgramArgs = {
  clinicId: Scalars['String'];
  programId: Scalars['String'];
};


export type MutationRemoveClinicFromLoyaltyProgramArgs = {
  clinicId: Scalars['String'];
  programId: Scalars['String'];
};


export type MutationArchiveLoyaltyProgramArgs = {
  programId: Scalars['String'];
  disconnectMemberClinics?: Maybe<Scalars['Boolean']>;
};


export type MutationRequestLoyaltyRewardArgs = {
  data: RequestLoyaltyRewardInput;
};


export type MutationCancelLoyaltyRewardRedemptionArgs = {
  data: LoyaltyRewardRedemptionInput;
};


export type MutationFulfillLoyaltyRewardRedemptionArgs = {
  data: LoyaltyRewardRedemptionInput;
};


export type MutationStripeCreateConnectedAccountArgs = {
  data: StripeCreateAccountInput;
};


export type MutationStripeConnectConnectedAccountArgs = {
  data: StripeConnectOauthInput;
};


export type MutationSyncPimsIntegrationModelArgs = {
  data: SyncPimsIntegrationModelInput;
};


export type MutationSyncManyPimsIntegrationModelArgs = {
  data: SyncManyPimsIntegrationModelInput;
};


export type MutationSyncClinicArgs = {
  clinicId: Scalars['String'];
};


export type MutationSubmitPrescriptionRefillRequestArgs = {
  data: SubmitPrescriptionRefillRequestInput;
};


export type MutationUpdateAppointmentTypeProviderRoomMapsArgs = {
  data: UpdateAppointmentTypeProviderRoomMapsInput;
};


export type MutationBuildSignedLookerUrlArgs = {
  reportId: Scalars['String'];
};


export type MutationUpdateManyServicesAndTimingsArgs = {
  where: ServiceWhereInput;
  data: Array<ServiceReminderTimingInput>;
};


export type MutationUpdateManyTimingsDeliveryOptionsArgs = {
  where: ServiceWhereInput;
  data: ServiceReminderTimingDeliveryOptionInput;
};


export type MutationResendReceiptEmailArgs = {
  stripePaymentIntentId: Scalars['String'];
  emailOverride?: Maybe<Scalars['String']>;
};


export type MutationRetryPaymentWritebackArgs = {
  stripePaymentIntentId: Scalars['String'];
};


export type MutationSetPaymentIntentClinicPetParent2Args = {
  where: StripePaymentIntentWhereUniqueInput;
};


export type MutationCreateStripePaymentIntent2Args = {
  data: StripeCreatePaymentInput;
  clinicPetParentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  stripeTerminalStripeId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  invoiceIds?: Maybe<Array<Scalars['String']>>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  emailOverride?: Maybe<Scalars['String']>;
  cardType?: Maybe<CardFunding>;
};


export type MutationCancelStripePaymentIntent2Args = {
  where: StripePaymentIntentWhereUniqueInput;
};


export type MutationRefundStripePaymentIntent2Args = {
  where: StripePaymentIntentWhereUniqueInput;
  data: EnhancedStripeRefundPaymentInput;
};


export type MutationCreateTerminalAppPaymentIntentArgs = {
  data: StripeCreatePaymentInput;
  clinicPetParentId?: Maybe<Scalars['String']>;
  invoiceIds?: Maybe<Array<Scalars['String']>>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  emailOverride?: Maybe<Scalars['String']>;
  cardType?: Maybe<CardFunding>;
  paymentMedium?: Maybe<PaymentMedium>;
};


export type MutationUpdateStripePaymentArgs = {
  data: StripeUpdatePaymentInput;
};


export type MutationUpdateTerminalAppPaymentIntentArgs = {
  data: StripeUpdatePaymentInput;
};


export type MutationClearTerminalDisplayArgs = {
  stripeTerminalId: Scalars['String'];
};


export type MutationProcessTerminalPaymentArgs = {
  stripeTerminalId: Scalars['String'];
  paymentIntent: PaymentIntentInput;
};


export type MutationSendPaymentToTerminalArgs = {
  stripePaymentIntentId: Scalars['String'];
  stripeTerminalId: Scalars['String'];
};


export type MutationConnectStripeTerminalArgs = {
  pairingCode: Scalars['String'];
  displayName: Scalars['String'];
};


export type MutationGenerateTerminalAuthTokenArgs = {
  stripeTerminalId: Scalars['String'];
};


export type MutationGenerateTerminalSetupPinArgs = {
  clinicId: Scalars['String'];
};


export type MutationCreateStripeTerminalLocationArgs = {
  displayName: Scalars['String'];
  type?: Maybe<StripeTerminalLocationType>;
};


export type MutationInitSurveySettingArgs = {
  clinicId: Scalars['String'];
};


export type MutationTrupanionIssueCertificateArgs = {
  where: TrupanionIssueCertificateWhereInput;
  data: TrupanionIssueCertificateDataInput;
};


export type MutationSendUiRefreshEventArgs = {
  data: UiRefreshInput;
};


export type MutationAddUserToClinicArgs = {
  data: UserCreateInput;
};


export type MutationUpsertUserToClinicArgs = {
  data: UpsertUserToClinicInput;
};


export type MutationRemoveUserFromClinicArgs = {
  data: RemoveUserFromClinicInput;
};


export type MutationResendUserInvitationArgs = {
  data: ResendUserInvitationInput;
};


export type MutationAcceptUserInvitation2Args = {
  data: AcceptUserInvitationInput;
};


export type MutationCustomUpsertOneUserBoardFilterArgs = {
  data: CustomUpsertOneUserBoardFilterDataInput;
  where: CustomUpsertOneUserBoardFilterWhereInput;
};


export type MutationGenerateWidgetAuthenticationRequestArgs = {
  where: WidgetClinicPetParentLookupInput;
};


export type MutationVerifyWidgetAuthenticationRequestArgs = {
  data: VerifyWidgetAuthenticationRequestInput;
};


export type MutationCreateClinicWidgetRequestArgs = {
  data: ClinicWidgetRequestCreateInput;
  skip?: Maybe<Scalars['Int']>;
};


export type MutationVerifyClinicWidgetRequestArgs = {
  where: VerifyClinicWidgetRequestInput;
};


export type MutationCopyOneWorkflowEventSettingArgs = {
  where: WorkflowEventSettingWhereUniqueInput;
};


export type MutationCreateWorkflowEventSettingAndConditionsArgs = {
  data: CreateWorkflowEventSettingAndConditionsInput;
};


export type MutationBulkPublishWorkflowEventSettingsArgs = {
  data: BulkPublishWorkflowEventSettingsInput;
};


export type MutationGenerateTinyUrlAndTokenArgs = {
  data: GenerateTinyUrlAndTokenInput;
};


export type MutationCreateTwilioTokenArgs = {
  data: CreateTwilioTokenInput;
};


export type MutationTransferTwilioNumberArgs = {
  data: TransferTwilioNumberInput;
};


export type MutationTwilioVerifyCallerIdArgs = {
  data: TwilioVerifyCallerIdInput;
};


export type MutationCreateImpersonationArgs = {
  data: IdInput;
};


export type MutationTriggerWorkflowEventArgs = {
  data: TriggerWorkflowEventInput;
};


export type MutationTriggerMessageEventArgs = {
  data: TriggerMessageEventInput;
};

export enum MutationType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Upsert = 'upsert'
}

export type NeoIntegration = {
  __typename?: 'NeoIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type NeoIntegrationCountAggregateOutputType = {
  __typename?: 'NeoIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  authToken: Scalars['Int'];
  branchId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type NeoIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
};

export type NeoIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutNeoInput>;
};

export type NeoIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<NeoIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<NeoIntegrationWhereUniqueInput>;
};

export type NeoIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: NeoIntegrationWhereUniqueInput;
  create: NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type NeoIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationMaxAggregateOutputType = {
  __typename?: 'NeoIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
};

export type NeoIntegrationMinAggregateOutputType = {
  __typename?: 'NeoIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
};

export enum NeoIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  AuthToken = 'authToken',
  BranchId = 'branchId'
}

export type NeoIntegrationOrderByRelevanceInput = {
  fields: Array<NeoIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type NeoIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
  _count?: Maybe<NeoIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<NeoIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<NeoIntegrationMinOrderByAggregateInput>;
};

export type NeoIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<NeoIntegrationOrderByRelevanceInput>;
};

export type NeoIntegrationRelationFilter = {
  is?: Maybe<NeoIntegrationWhereInput>;
  isNot?: Maybe<NeoIntegrationWhereInput>;
};

export enum NeoIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  AuthToken = 'authToken',
  BranchId = 'branchId'
}

export type NeoIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<NeoIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<NeoIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<NeoIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  authToken?: Maybe<StringWithAggregatesFilter>;
  branchId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type NeoIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutNeoInput>;
};

export type NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutNeoNestedInput>;
};

export type NeoIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutNeoNestedInput>;
};

export type NeoIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  create?: Maybe<NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<NeoIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<NeoIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<NeoIntegrationWhereUniqueInput>;
  update?: Maybe<NeoIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type NeoIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: NeoIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type NeoIntegrationWhereInput = {
  AND?: Maybe<Array<NeoIntegrationWhereInput>>;
  OR?: Maybe<Array<NeoIntegrationWhereInput>>;
  NOT?: Maybe<Array<NeoIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  authToken?: Maybe<StringFilter>;
  branchId?: Maybe<StringNullableFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type NeoIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedBoolNullableFilter>;
  _max?: Maybe<NestedBoolNullableFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedBoolFilter>;
  _max?: Maybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
};

export type NestedEnumAmericanKennelClubBreedGroupNullableFilter = {
  equals?: Maybe<AmericanKennelClubBreedGroup>;
  in?: Maybe<Array<AmericanKennelClubBreedGroup>>;
  notIn?: Maybe<Array<AmericanKennelClubBreedGroup>>;
  not?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableFilter>;
};

export type NestedEnumAmericanKennelClubBreedGroupNullableWithAggregatesFilter = {
  equals?: Maybe<AmericanKennelClubBreedGroup>;
  in?: Maybe<Array<AmericanKennelClubBreedGroup>>;
  notIn?: Maybe<Array<AmericanKennelClubBreedGroup>>;
  not?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableFilter>;
};

export type NestedEnumAmericanKennelClubDemeanorCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubDemeanorCategory>;
  in?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableFilter>;
};

export type NestedEnumAmericanKennelClubDemeanorCategoryNullableWithAggregatesFilter = {
  equals?: Maybe<AmericanKennelClubDemeanorCategory>;
  in?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableFilter>;
};

export type NestedEnumAmericanKennelClubEnergyLevelCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  in?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableFilter>;
};

export type NestedEnumAmericanKennelClubEnergyLevelCategoryNullableWithAggregatesFilter = {
  equals?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  in?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableFilter>;
};

export type NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  in?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter>;
};

export type NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableWithAggregatesFilter = {
  equals?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  in?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter>;
};

export type NestedEnumAmericanKennelClubSheddingCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubSheddingCategory>;
  in?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableFilter>;
};

export type NestedEnumAmericanKennelClubSheddingCategoryNullableWithAggregatesFilter = {
  equals?: Maybe<AmericanKennelClubSheddingCategory>;
  in?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableFilter>;
};

export type NestedEnumAmericanKennelClubTrainabilityCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubTrainabilityCategory>;
  in?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableFilter>;
};

export type NestedEnumAmericanKennelClubTrainabilityCategoryNullableWithAggregatesFilter = {
  equals?: Maybe<AmericanKennelClubTrainabilityCategory>;
  in?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
  notIn?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableFilter>;
};

export type NestedEnumAppointmentDateRangeTypeFilter = {
  equals?: Maybe<AppointmentDateRangeType>;
  in?: Maybe<Array<AppointmentDateRangeType>>;
  notIn?: Maybe<Array<AppointmentDateRangeType>>;
  not?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
};

export type NestedEnumAppointmentDateRangeTypeWithAggregatesFilter = {
  equals?: Maybe<AppointmentDateRangeType>;
  in?: Maybe<Array<AppointmentDateRangeType>>;
  notIn?: Maybe<Array<AppointmentDateRangeType>>;
  not?: Maybe<NestedEnumAppointmentDateRangeTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
  _max?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
};

export type NestedEnumAppointmentStatusFilter = {
  equals?: Maybe<AppointmentStatus>;
  in?: Maybe<Array<AppointmentStatus>>;
  notIn?: Maybe<Array<AppointmentStatus>>;
  not?: Maybe<NestedEnumAppointmentStatusFilter>;
};

export type NestedEnumAppointmentStatusWithAggregatesFilter = {
  equals?: Maybe<AppointmentStatus>;
  in?: Maybe<Array<AppointmentStatus>>;
  notIn?: Maybe<Array<AppointmentStatus>>;
  not?: Maybe<NestedEnumAppointmentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAppointmentStatusFilter>;
  _max?: Maybe<NestedEnumAppointmentStatusFilter>;
};

export type NestedEnumAppointmentTypeVisibilityFilter = {
  equals?: Maybe<AppointmentTypeVisibility>;
  in?: Maybe<Array<AppointmentTypeVisibility>>;
  notIn?: Maybe<Array<AppointmentTypeVisibility>>;
  not?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
};

export type NestedEnumAppointmentTypeVisibilityWithAggregatesFilter = {
  equals?: Maybe<AppointmentTypeVisibility>;
  in?: Maybe<Array<AppointmentTypeVisibility>>;
  notIn?: Maybe<Array<AppointmentTypeVisibility>>;
  not?: Maybe<NestedEnumAppointmentTypeVisibilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
  _max?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
};

export type NestedEnumAutoResponseSuggestionStatusFilter = {
  equals?: Maybe<AutoResponseSuggestionStatus>;
  in?: Maybe<Array<AutoResponseSuggestionStatus>>;
  notIn?: Maybe<Array<AutoResponseSuggestionStatus>>;
  not?: Maybe<NestedEnumAutoResponseSuggestionStatusFilter>;
};

export type NestedEnumAutoResponseSuggestionStatusWithAggregatesFilter = {
  equals?: Maybe<AutoResponseSuggestionStatus>;
  in?: Maybe<Array<AutoResponseSuggestionStatus>>;
  notIn?: Maybe<Array<AutoResponseSuggestionStatus>>;
  not?: Maybe<NestedEnumAutoResponseSuggestionStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutoResponseSuggestionStatusFilter>;
  _max?: Maybe<NestedEnumAutoResponseSuggestionStatusFilter>;
};

export type NestedEnumAutomatedTriggerTypeNullableFilter = {
  equals?: Maybe<AutomatedTriggerType>;
  in?: Maybe<Array<AutomatedTriggerType>>;
  notIn?: Maybe<Array<AutomatedTriggerType>>;
  not?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
};

export type NestedEnumAutomatedTriggerTypeNullableWithAggregatesFilter = {
  equals?: Maybe<AutomatedTriggerType>;
  in?: Maybe<Array<AutomatedTriggerType>>;
  notIn?: Maybe<Array<AutomatedTriggerType>>;
  not?: Maybe<NestedEnumAutomatedTriggerTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
  _max?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
};

export type NestedEnumAutomationRunActionPromptTypeFilter = {
  equals?: Maybe<AutomationRunActionPromptType>;
  in?: Maybe<Array<AutomationRunActionPromptType>>;
  notIn?: Maybe<Array<AutomationRunActionPromptType>>;
  not?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
};

export type NestedEnumAutomationRunActionPromptTypeWithAggregatesFilter = {
  equals?: Maybe<AutomationRunActionPromptType>;
  in?: Maybe<Array<AutomationRunActionPromptType>>;
  notIn?: Maybe<Array<AutomationRunActionPromptType>>;
  not?: Maybe<NestedEnumAutomationRunActionPromptTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
  _max?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
};

export type NestedEnumAutomationRunActionTypeFilter = {
  equals?: Maybe<AutomationRunActionType>;
  in?: Maybe<Array<AutomationRunActionType>>;
  notIn?: Maybe<Array<AutomationRunActionType>>;
  not?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
};

export type NestedEnumAutomationRunActionTypeWithAggregatesFilter = {
  equals?: Maybe<AutomationRunActionType>;
  in?: Maybe<Array<AutomationRunActionType>>;
  notIn?: Maybe<Array<AutomationRunActionType>>;
  not?: Maybe<NestedEnumAutomationRunActionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
  _max?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
};

export type NestedEnumAutomationRunStatusFilter = {
  equals?: Maybe<AutomationRunStatus>;
  in?: Maybe<Array<AutomationRunStatus>>;
  notIn?: Maybe<Array<AutomationRunStatus>>;
  not?: Maybe<NestedEnumAutomationRunStatusFilter>;
};

export type NestedEnumAutomationRunStatusWithAggregatesFilter = {
  equals?: Maybe<AutomationRunStatus>;
  in?: Maybe<Array<AutomationRunStatus>>;
  notIn?: Maybe<Array<AutomationRunStatus>>;
  not?: Maybe<NestedEnumAutomationRunStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunStatusFilter>;
  _max?: Maybe<NestedEnumAutomationRunStatusFilter>;
};

export type NestedEnumAutomationRunTypeFilter = {
  equals?: Maybe<AutomationRunType>;
  in?: Maybe<Array<AutomationRunType>>;
  notIn?: Maybe<Array<AutomationRunType>>;
  not?: Maybe<NestedEnumAutomationRunTypeFilter>;
};

export type NestedEnumAutomationRunTypeWithAggregatesFilter = {
  equals?: Maybe<AutomationRunType>;
  in?: Maybe<Array<AutomationRunType>>;
  notIn?: Maybe<Array<AutomationRunType>>;
  not?: Maybe<NestedEnumAutomationRunTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunTypeFilter>;
  _max?: Maybe<NestedEnumAutomationRunTypeFilter>;
};

export type NestedEnumAutomationStatusFilter = {
  equals?: Maybe<AutomationStatus>;
  in?: Maybe<Array<AutomationStatus>>;
  notIn?: Maybe<Array<AutomationStatus>>;
  not?: Maybe<NestedEnumAutomationStatusFilter>;
};

export type NestedEnumAutomationStatusWithAggregatesFilter = {
  equals?: Maybe<AutomationStatus>;
  in?: Maybe<Array<AutomationStatus>>;
  notIn?: Maybe<Array<AutomationStatus>>;
  not?: Maybe<NestedEnumAutomationStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationStatusFilter>;
  _max?: Maybe<NestedEnumAutomationStatusFilter>;
};

export type NestedEnumBitwerxHealthcheckRunStatusNullableFilter = {
  equals?: Maybe<BitwerxHealthcheckRunStatus>;
  in?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
  notIn?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableFilter>;
};

export type NestedEnumBitwerxHealthcheckRunStatusNullableWithAggregatesFilter = {
  equals?: Maybe<BitwerxHealthcheckRunStatus>;
  in?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
  notIn?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableFilter>;
  _max?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableFilter>;
};

export type NestedEnumBitwerxHealthcheckTypeFilter = {
  equals?: Maybe<BitwerxHealthcheckType>;
  in?: Maybe<Array<BitwerxHealthcheckType>>;
  notIn?: Maybe<Array<BitwerxHealthcheckType>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckTypeFilter>;
};

export type NestedEnumBitwerxHealthcheckTypeWithAggregatesFilter = {
  equals?: Maybe<BitwerxHealthcheckType>;
  in?: Maybe<Array<BitwerxHealthcheckType>>;
  notIn?: Maybe<Array<BitwerxHealthcheckType>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBitwerxHealthcheckTypeFilter>;
  _max?: Maybe<NestedEnumBitwerxHealthcheckTypeFilter>;
};

export type NestedEnumBitwerxPaymentEndpointFilter = {
  equals?: Maybe<BitwerxPaymentEndpoint>;
  in?: Maybe<Array<BitwerxPaymentEndpoint>>;
  notIn?: Maybe<Array<BitwerxPaymentEndpoint>>;
  not?: Maybe<NestedEnumBitwerxPaymentEndpointFilter>;
};

export type NestedEnumBitwerxPaymentEndpointWithAggregatesFilter = {
  equals?: Maybe<BitwerxPaymentEndpoint>;
  in?: Maybe<Array<BitwerxPaymentEndpoint>>;
  notIn?: Maybe<Array<BitwerxPaymentEndpoint>>;
  not?: Maybe<NestedEnumBitwerxPaymentEndpointWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBitwerxPaymentEndpointFilter>;
  _max?: Maybe<NestedEnumBitwerxPaymentEndpointFilter>;
};

export type NestedEnumBitwerxSystemFilter = {
  equals?: Maybe<BitwerxSystem>;
  in?: Maybe<Array<BitwerxSystem>>;
  notIn?: Maybe<Array<BitwerxSystem>>;
  not?: Maybe<NestedEnumBitwerxSystemFilter>;
};

export type NestedEnumBitwerxSystemWithAggregatesFilter = {
  equals?: Maybe<BitwerxSystem>;
  in?: Maybe<Array<BitwerxSystem>>;
  notIn?: Maybe<Array<BitwerxSystem>>;
  not?: Maybe<NestedEnumBitwerxSystemWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBitwerxSystemFilter>;
  _max?: Maybe<NestedEnumBitwerxSystemFilter>;
};

export type NestedEnumBoardFilterColumnTypeFilter = {
  equals?: Maybe<BoardFilterColumnType>;
  in?: Maybe<Array<BoardFilterColumnType>>;
  notIn?: Maybe<Array<BoardFilterColumnType>>;
  not?: Maybe<NestedEnumBoardFilterColumnTypeFilter>;
};

export type NestedEnumBoardFilterColumnTypeWithAggregatesFilter = {
  equals?: Maybe<BoardFilterColumnType>;
  in?: Maybe<Array<BoardFilterColumnType>>;
  notIn?: Maybe<Array<BoardFilterColumnType>>;
  not?: Maybe<NestedEnumBoardFilterColumnTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBoardFilterColumnTypeFilter>;
  _max?: Maybe<NestedEnumBoardFilterColumnTypeFilter>;
};

export type NestedEnumBoardFilterDateRangeNullableFilter = {
  equals?: Maybe<BoardFilterDateRange>;
  in?: Maybe<Array<BoardFilterDateRange>>;
  notIn?: Maybe<Array<BoardFilterDateRange>>;
  not?: Maybe<NestedEnumBoardFilterDateRangeNullableFilter>;
};

export type NestedEnumBoardFilterDateRangeNullableWithAggregatesFilter = {
  equals?: Maybe<BoardFilterDateRange>;
  in?: Maybe<Array<BoardFilterDateRange>>;
  notIn?: Maybe<Array<BoardFilterDateRange>>;
  not?: Maybe<NestedEnumBoardFilterDateRangeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumBoardFilterDateRangeNullableFilter>;
  _max?: Maybe<NestedEnumBoardFilterDateRangeNullableFilter>;
};

export type NestedEnumBoardFilterViewFilter = {
  equals?: Maybe<BoardFilterView>;
  in?: Maybe<Array<BoardFilterView>>;
  notIn?: Maybe<Array<BoardFilterView>>;
  not?: Maybe<NestedEnumBoardFilterViewFilter>;
};

export type NestedEnumBoardFilterViewWithAggregatesFilter = {
  equals?: Maybe<BoardFilterView>;
  in?: Maybe<Array<BoardFilterView>>;
  notIn?: Maybe<Array<BoardFilterView>>;
  not?: Maybe<NestedEnumBoardFilterViewWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBoardFilterViewFilter>;
  _max?: Maybe<NestedEnumBoardFilterViewFilter>;
};

export type NestedEnumCallHistoryTypeFilter = {
  equals?: Maybe<CallHistoryType>;
  in?: Maybe<Array<CallHistoryType>>;
  notIn?: Maybe<Array<CallHistoryType>>;
  not?: Maybe<NestedEnumCallHistoryTypeFilter>;
};

export type NestedEnumCallHistoryTypeWithAggregatesFilter = {
  equals?: Maybe<CallHistoryType>;
  in?: Maybe<Array<CallHistoryType>>;
  notIn?: Maybe<Array<CallHistoryType>>;
  not?: Maybe<NestedEnumCallHistoryTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCallHistoryTypeFilter>;
  _max?: Maybe<NestedEnumCallHistoryTypeFilter>;
};

export type NestedEnumCallProviderFilter = {
  equals?: Maybe<CallProvider>;
  in?: Maybe<Array<CallProvider>>;
  notIn?: Maybe<Array<CallProvider>>;
  not?: Maybe<NestedEnumCallProviderFilter>;
};

export type NestedEnumCallProviderWithAggregatesFilter = {
  equals?: Maybe<CallProvider>;
  in?: Maybe<Array<CallProvider>>;
  notIn?: Maybe<Array<CallProvider>>;
  not?: Maybe<NestedEnumCallProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCallProviderFilter>;
  _max?: Maybe<NestedEnumCallProviderFilter>;
};

export type NestedEnumCallStatusFilter = {
  equals?: Maybe<CallStatus>;
  in?: Maybe<Array<CallStatus>>;
  notIn?: Maybe<Array<CallStatus>>;
  not?: Maybe<NestedEnumCallStatusFilter>;
};

export type NestedEnumCallStatusWithAggregatesFilter = {
  equals?: Maybe<CallStatus>;
  in?: Maybe<Array<CallStatus>>;
  notIn?: Maybe<Array<CallStatus>>;
  not?: Maybe<NestedEnumCallStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCallStatusFilter>;
  _max?: Maybe<NestedEnumCallStatusFilter>;
};

export type NestedEnumCampaignRegistryBrandIdentityStatusNullableFilter = {
  equals?: Maybe<CampaignRegistryBrandIdentityStatus>;
  in?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
  notIn?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableFilter>;
};

export type NestedEnumCampaignRegistryBrandIdentityStatusNullableWithAggregatesFilter = {
  equals?: Maybe<CampaignRegistryBrandIdentityStatus>;
  in?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
  notIn?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableFilter>;
  _max?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableFilter>;
};

export type NestedEnumCampaignRegistryCampaignSharingStatusNullableFilter = {
  equals?: Maybe<CampaignRegistryCampaignSharingStatus>;
  in?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
  notIn?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableFilter>;
};

export type NestedEnumCampaignRegistryCampaignSharingStatusNullableWithAggregatesFilter = {
  equals?: Maybe<CampaignRegistryCampaignSharingStatus>;
  in?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
  notIn?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableFilter>;
  _max?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableFilter>;
};

export type NestedEnumCancelReasonTypeNullableFilter = {
  equals?: Maybe<CancelReasonType>;
  in?: Maybe<Array<CancelReasonType>>;
  notIn?: Maybe<Array<CancelReasonType>>;
  not?: Maybe<NestedEnumCancelReasonTypeNullableFilter>;
};

export type NestedEnumCancelReasonTypeNullableWithAggregatesFilter = {
  equals?: Maybe<CancelReasonType>;
  in?: Maybe<Array<CancelReasonType>>;
  notIn?: Maybe<Array<CancelReasonType>>;
  not?: Maybe<NestedEnumCancelReasonTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCancelReasonTypeNullableFilter>;
  _max?: Maybe<NestedEnumCancelReasonTypeNullableFilter>;
};

export type NestedEnumCanonicalDataSourceFilter = {
  equals?: Maybe<CanonicalDataSource>;
  in?: Maybe<Array<CanonicalDataSource>>;
  notIn?: Maybe<Array<CanonicalDataSource>>;
  not?: Maybe<NestedEnumCanonicalDataSourceFilter>;
};

export type NestedEnumCanonicalDataSourceWithAggregatesFilter = {
  equals?: Maybe<CanonicalDataSource>;
  in?: Maybe<Array<CanonicalDataSource>>;
  notIn?: Maybe<Array<CanonicalDataSource>>;
  not?: Maybe<NestedEnumCanonicalDataSourceWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCanonicalDataSourceFilter>;
  _max?: Maybe<NestedEnumCanonicalDataSourceFilter>;
};

export type NestedEnumCanonicalServiceStatusFilter = {
  equals?: Maybe<CanonicalServiceStatus>;
  in?: Maybe<Array<CanonicalServiceStatus>>;
  notIn?: Maybe<Array<CanonicalServiceStatus>>;
  not?: Maybe<NestedEnumCanonicalServiceStatusFilter>;
};

export type NestedEnumCanonicalServiceStatusWithAggregatesFilter = {
  equals?: Maybe<CanonicalServiceStatus>;
  in?: Maybe<Array<CanonicalServiceStatus>>;
  notIn?: Maybe<Array<CanonicalServiceStatus>>;
  not?: Maybe<NestedEnumCanonicalServiceStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCanonicalServiceStatusFilter>;
  _max?: Maybe<NestedEnumCanonicalServiceStatusFilter>;
};

export type NestedEnumCanonicalServiceTypeFilter = {
  equals?: Maybe<CanonicalServiceType>;
  in?: Maybe<Array<CanonicalServiceType>>;
  notIn?: Maybe<Array<CanonicalServiceType>>;
  not?: Maybe<NestedEnumCanonicalServiceTypeFilter>;
};

export type NestedEnumCanonicalServiceTypeWithAggregatesFilter = {
  equals?: Maybe<CanonicalServiceType>;
  in?: Maybe<Array<CanonicalServiceType>>;
  notIn?: Maybe<Array<CanonicalServiceType>>;
  not?: Maybe<NestedEnumCanonicalServiceTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCanonicalServiceTypeFilter>;
  _max?: Maybe<NestedEnumCanonicalServiceTypeFilter>;
};

export type NestedEnumCardBrandFilter = {
  equals?: Maybe<CardBrand>;
  in?: Maybe<Array<CardBrand>>;
  notIn?: Maybe<Array<CardBrand>>;
  not?: Maybe<NestedEnumCardBrandFilter>;
};

export type NestedEnumCardBrandWithAggregatesFilter = {
  equals?: Maybe<CardBrand>;
  in?: Maybe<Array<CardBrand>>;
  notIn?: Maybe<Array<CardBrand>>;
  not?: Maybe<NestedEnumCardBrandWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCardBrandFilter>;
  _max?: Maybe<NestedEnumCardBrandFilter>;
};

export type NestedEnumCardFundingNullableFilter = {
  equals?: Maybe<CardFunding>;
  in?: Maybe<Array<CardFunding>>;
  notIn?: Maybe<Array<CardFunding>>;
  not?: Maybe<NestedEnumCardFundingNullableFilter>;
};

export type NestedEnumCardFundingNullableWithAggregatesFilter = {
  equals?: Maybe<CardFunding>;
  in?: Maybe<Array<CardFunding>>;
  notIn?: Maybe<Array<CardFunding>>;
  not?: Maybe<NestedEnumCardFundingNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCardFundingNullableFilter>;
  _max?: Maybe<NestedEnumCardFundingNullableFilter>;
};

export type NestedEnumCareBenefitTypeFilter = {
  equals?: Maybe<CareBenefitType>;
  in?: Maybe<Array<CareBenefitType>>;
  notIn?: Maybe<Array<CareBenefitType>>;
  not?: Maybe<NestedEnumCareBenefitTypeFilter>;
};

export type NestedEnumCareBenefitTypeWithAggregatesFilter = {
  equals?: Maybe<CareBenefitType>;
  in?: Maybe<Array<CareBenefitType>>;
  notIn?: Maybe<Array<CareBenefitType>>;
  not?: Maybe<NestedEnumCareBenefitTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCareBenefitTypeFilter>;
  _max?: Maybe<NestedEnumCareBenefitTypeFilter>;
};

export type NestedEnumCareBenefitUnitOfMeasureNullableFilter = {
  equals?: Maybe<CareBenefitUnitOfMeasure>;
  in?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  notIn?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  not?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
};

export type NestedEnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter = {
  equals?: Maybe<CareBenefitUnitOfMeasure>;
  in?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  notIn?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  not?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
  _max?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
};

export type NestedEnumCareLapsedPaymentStatusFilter = {
  equals?: Maybe<CareLapsedPaymentStatus>;
  in?: Maybe<Array<CareLapsedPaymentStatus>>;
  notIn?: Maybe<Array<CareLapsedPaymentStatus>>;
  not?: Maybe<NestedEnumCareLapsedPaymentStatusFilter>;
};

export type NestedEnumCareLapsedPaymentStatusWithAggregatesFilter = {
  equals?: Maybe<CareLapsedPaymentStatus>;
  in?: Maybe<Array<CareLapsedPaymentStatus>>;
  notIn?: Maybe<Array<CareLapsedPaymentStatus>>;
  not?: Maybe<NestedEnumCareLapsedPaymentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCareLapsedPaymentStatusFilter>;
  _max?: Maybe<NestedEnumCareLapsedPaymentStatusFilter>;
};

export type NestedEnumCarePlanCycleTypeFilter = {
  equals?: Maybe<CarePlanCycleType>;
  in?: Maybe<Array<CarePlanCycleType>>;
  notIn?: Maybe<Array<CarePlanCycleType>>;
  not?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
};

export type NestedEnumCarePlanCycleTypeWithAggregatesFilter = {
  equals?: Maybe<CarePlanCycleType>;
  in?: Maybe<Array<CarePlanCycleType>>;
  notIn?: Maybe<Array<CarePlanCycleType>>;
  not?: Maybe<NestedEnumCarePlanCycleTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
  _max?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
};

export type NestedEnumCareProgramLaunchStatusFilter = {
  equals?: Maybe<CareProgramLaunchStatus>;
  in?: Maybe<Array<CareProgramLaunchStatus>>;
  notIn?: Maybe<Array<CareProgramLaunchStatus>>;
  not?: Maybe<NestedEnumCareProgramLaunchStatusFilter>;
};

export type NestedEnumCareProgramLaunchStatusWithAggregatesFilter = {
  equals?: Maybe<CareProgramLaunchStatus>;
  in?: Maybe<Array<CareProgramLaunchStatus>>;
  notIn?: Maybe<Array<CareProgramLaunchStatus>>;
  not?: Maybe<NestedEnumCareProgramLaunchStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCareProgramLaunchStatusFilter>;
  _max?: Maybe<NestedEnumCareProgramLaunchStatusFilter>;
};

export type NestedEnumCareSubscriptionPaymentStatusNullableFilter = {
  equals?: Maybe<CareSubscriptionPaymentStatus>;
  in?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  notIn?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
};

export type NestedEnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter = {
  equals?: Maybe<CareSubscriptionPaymentStatus>;
  in?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  notIn?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
  _max?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
};

export type NestedEnumCareSubscriptionTrueUpStatusFilter = {
  equals?: Maybe<CareSubscriptionTrueUpStatus>;
  in?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpStatusFilter>;
};

export type NestedEnumCareSubscriptionTrueUpStatusWithAggregatesFilter = {
  equals?: Maybe<CareSubscriptionTrueUpStatus>;
  in?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCareSubscriptionTrueUpStatusFilter>;
  _max?: Maybe<NestedEnumCareSubscriptionTrueUpStatusFilter>;
};

export type NestedEnumCareSubscriptionTrueUpTypeFilter = {
  equals?: Maybe<CareSubscriptionTrueUpType>;
  in?: Maybe<Array<CareSubscriptionTrueUpType>>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpType>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpTypeFilter>;
};

export type NestedEnumCareSubscriptionTrueUpTypeWithAggregatesFilter = {
  equals?: Maybe<CareSubscriptionTrueUpType>;
  in?: Maybe<Array<CareSubscriptionTrueUpType>>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpType>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCareSubscriptionTrueUpTypeFilter>;
  _max?: Maybe<NestedEnumCareSubscriptionTrueUpTypeFilter>;
};

export type NestedEnumChannelCreationSourceTypeFilter = {
  equals?: Maybe<ChannelCreationSourceType>;
  in?: Maybe<Array<ChannelCreationSourceType>>;
  notIn?: Maybe<Array<ChannelCreationSourceType>>;
  not?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
};

export type NestedEnumChannelCreationSourceTypeWithAggregatesFilter = {
  equals?: Maybe<ChannelCreationSourceType>;
  in?: Maybe<Array<ChannelCreationSourceType>>;
  notIn?: Maybe<Array<ChannelCreationSourceType>>;
  not?: Maybe<NestedEnumChannelCreationSourceTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
  _max?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
};

export type NestedEnumChannelMessageAttachmentEntityTypeNullableFilter = {
  equals?: Maybe<ChannelMessageAttachmentEntityType>;
  in?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
};

export type NestedEnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelMessageAttachmentEntityType>;
  in?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
};

export type NestedEnumChannelMessageAttachmentTypeFilter = {
  equals?: Maybe<ChannelMessageAttachmentType>;
  in?: Maybe<Array<ChannelMessageAttachmentType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
};

export type NestedEnumChannelMessageAttachmentTypeWithAggregatesFilter = {
  equals?: Maybe<ChannelMessageAttachmentType>;
  in?: Maybe<Array<ChannelMessageAttachmentType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
};

export type NestedEnumChannelMessageAttachmentUploadStatusNullableFilter = {
  equals?: Maybe<ChannelMessageAttachmentUploadStatus>;
  in?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
};

export type NestedEnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelMessageAttachmentUploadStatus>;
  in?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
};

export type NestedEnumChannelStatusActionFilter = {
  equals?: Maybe<ChannelStatusAction>;
  in?: Maybe<Array<ChannelStatusAction>>;
  notIn?: Maybe<Array<ChannelStatusAction>>;
  not?: Maybe<NestedEnumChannelStatusActionFilter>;
};

export type NestedEnumChannelStatusActionWithAggregatesFilter = {
  equals?: Maybe<ChannelStatusAction>;
  in?: Maybe<Array<ChannelStatusAction>>;
  notIn?: Maybe<Array<ChannelStatusAction>>;
  not?: Maybe<NestedEnumChannelStatusActionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChannelStatusActionFilter>;
  _max?: Maybe<NestedEnumChannelStatusActionFilter>;
};

export type NestedEnumChannelTypeNullableFilter = {
  equals?: Maybe<ChannelType>;
  in?: Maybe<Array<ChannelType>>;
  notIn?: Maybe<Array<ChannelType>>;
  not?: Maybe<NestedEnumChannelTypeNullableFilter>;
};

export type NestedEnumChannelTypeNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelType>;
  in?: Maybe<Array<ChannelType>>;
  notIn?: Maybe<Array<ChannelType>>;
  not?: Maybe<NestedEnumChannelTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelTypeNullableFilter>;
  _max?: Maybe<NestedEnumChannelTypeNullableFilter>;
};

export type NestedEnumChannelVisibilityNullableFilter = {
  equals?: Maybe<ChannelVisibility>;
  in?: Maybe<Array<ChannelVisibility>>;
  notIn?: Maybe<Array<ChannelVisibility>>;
  not?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
};

export type NestedEnumChannelVisibilityNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelVisibility>;
  in?: Maybe<Array<ChannelVisibility>>;
  notIn?: Maybe<Array<ChannelVisibility>>;
  not?: Maybe<NestedEnumChannelVisibilityNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
  _max?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
};

export type NestedEnumChargeCaptureTypeFilter = {
  equals?: Maybe<ChargeCaptureType>;
  in?: Maybe<Array<ChargeCaptureType>>;
  notIn?: Maybe<Array<ChargeCaptureType>>;
  not?: Maybe<NestedEnumChargeCaptureTypeFilter>;
};

export type NestedEnumChargeCaptureTypeWithAggregatesFilter = {
  equals?: Maybe<ChargeCaptureType>;
  in?: Maybe<Array<ChargeCaptureType>>;
  notIn?: Maybe<Array<ChargeCaptureType>>;
  not?: Maybe<NestedEnumChargeCaptureTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChargeCaptureTypeFilter>;
  _max?: Maybe<NestedEnumChargeCaptureTypeFilter>;
};

export type NestedEnumClientConnectClinicSoftwareFilter = {
  equals?: Maybe<ClientConnectClinicSoftware>;
  in?: Maybe<Array<ClientConnectClinicSoftware>>;
  notIn?: Maybe<Array<ClientConnectClinicSoftware>>;
  not?: Maybe<NestedEnumClientConnectClinicSoftwareFilter>;
};

export type NestedEnumClientConnectClinicSoftwareWithAggregatesFilter = {
  equals?: Maybe<ClientConnectClinicSoftware>;
  in?: Maybe<Array<ClientConnectClinicSoftware>>;
  notIn?: Maybe<Array<ClientConnectClinicSoftware>>;
  not?: Maybe<NestedEnumClientConnectClinicSoftwareWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumClientConnectClinicSoftwareFilter>;
  _max?: Maybe<NestedEnumClientConnectClinicSoftwareFilter>;
};

export type NestedEnumClientConnectSystemFilter = {
  equals?: Maybe<ClientConnectSystem>;
  in?: Maybe<Array<ClientConnectSystem>>;
  notIn?: Maybe<Array<ClientConnectSystem>>;
  not?: Maybe<NestedEnumClientConnectSystemFilter>;
};

export type NestedEnumClientConnectSystemWithAggregatesFilter = {
  equals?: Maybe<ClientConnectSystem>;
  in?: Maybe<Array<ClientConnectSystem>>;
  notIn?: Maybe<Array<ClientConnectSystem>>;
  not?: Maybe<NestedEnumClientConnectSystemWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumClientConnectSystemFilter>;
  _max?: Maybe<NestedEnumClientConnectSystemFilter>;
};

export type NestedEnumClinicEntityCreationSourceNullableFilter = {
  equals?: Maybe<ClinicEntityCreationSource>;
  in?: Maybe<Array<ClinicEntityCreationSource>>;
  notIn?: Maybe<Array<ClinicEntityCreationSource>>;
  not?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
};

export type NestedEnumClinicEntityCreationSourceNullableWithAggregatesFilter = {
  equals?: Maybe<ClinicEntityCreationSource>;
  in?: Maybe<Array<ClinicEntityCreationSource>>;
  notIn?: Maybe<Array<ClinicEntityCreationSource>>;
  not?: Maybe<NestedEnumClinicEntityCreationSourceNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
  _max?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
};

export type NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter = {
  equals?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  in?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  notIn?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  not?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
};

export type NestedEnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter = {
  equals?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  in?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  notIn?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  not?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  _max?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
};

export type NestedEnumClinicOfficeHoursTypeFilter = {
  equals?: Maybe<ClinicOfficeHoursType>;
  in?: Maybe<Array<ClinicOfficeHoursType>>;
  notIn?: Maybe<Array<ClinicOfficeHoursType>>;
  not?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
};

export type NestedEnumClinicOfficeHoursTypeWithAggregatesFilter = {
  equals?: Maybe<ClinicOfficeHoursType>;
  in?: Maybe<Array<ClinicOfficeHoursType>>;
  notIn?: Maybe<Array<ClinicOfficeHoursType>>;
  not?: Maybe<NestedEnumClinicOfficeHoursTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
  _max?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
};

export type NestedEnumClinicPetParentAddressTypeNullableFilter = {
  equals?: Maybe<ClinicPetParentAddressType>;
  in?: Maybe<Array<ClinicPetParentAddressType>>;
  notIn?: Maybe<Array<ClinicPetParentAddressType>>;
  not?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
};

export type NestedEnumClinicPetParentAddressTypeNullableWithAggregatesFilter = {
  equals?: Maybe<ClinicPetParentAddressType>;
  in?: Maybe<Array<ClinicPetParentAddressType>>;
  notIn?: Maybe<Array<ClinicPetParentAddressType>>;
  not?: Maybe<NestedEnumClinicPetParentAddressTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
  _max?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
};

export type NestedEnumClinicPimsIntegrationTypeFilter = {
  equals?: Maybe<ClinicPimsIntegrationType>;
  in?: Maybe<Array<ClinicPimsIntegrationType>>;
  notIn?: Maybe<Array<ClinicPimsIntegrationType>>;
  not?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
};

export type NestedEnumClinicPimsIntegrationTypeWithAggregatesFilter = {
  equals?: Maybe<ClinicPimsIntegrationType>;
  in?: Maybe<Array<ClinicPimsIntegrationType>>;
  notIn?: Maybe<Array<ClinicPimsIntegrationType>>;
  not?: Maybe<NestedEnumClinicPimsIntegrationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
  _max?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
};

export type NestedEnumConditionAttributeFilter = {
  equals?: Maybe<ConditionAttribute>;
  in?: Maybe<Array<ConditionAttribute>>;
  notIn?: Maybe<Array<ConditionAttribute>>;
  not?: Maybe<NestedEnumConditionAttributeFilter>;
};

export type NestedEnumConditionAttributeWithAggregatesFilter = {
  equals?: Maybe<ConditionAttribute>;
  in?: Maybe<Array<ConditionAttribute>>;
  notIn?: Maybe<Array<ConditionAttribute>>;
  not?: Maybe<NestedEnumConditionAttributeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumConditionAttributeFilter>;
  _max?: Maybe<NestedEnumConditionAttributeFilter>;
};

export type NestedEnumConditionEvaluationStatusFilter = {
  equals?: Maybe<ConditionEvaluationStatus>;
  in?: Maybe<Array<ConditionEvaluationStatus>>;
  notIn?: Maybe<Array<ConditionEvaluationStatus>>;
  not?: Maybe<NestedEnumConditionEvaluationStatusFilter>;
};

export type NestedEnumConditionEvaluationStatusWithAggregatesFilter = {
  equals?: Maybe<ConditionEvaluationStatus>;
  in?: Maybe<Array<ConditionEvaluationStatus>>;
  notIn?: Maybe<Array<ConditionEvaluationStatus>>;
  not?: Maybe<NestedEnumConditionEvaluationStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumConditionEvaluationStatusFilter>;
  _max?: Maybe<NestedEnumConditionEvaluationStatusFilter>;
};

export type NestedEnumConditionOperatorFilter = {
  equals?: Maybe<ConditionOperator>;
  in?: Maybe<Array<ConditionOperator>>;
  notIn?: Maybe<Array<ConditionOperator>>;
  not?: Maybe<NestedEnumConditionOperatorFilter>;
};

export type NestedEnumConditionOperatorWithAggregatesFilter = {
  equals?: Maybe<ConditionOperator>;
  in?: Maybe<Array<ConditionOperator>>;
  notIn?: Maybe<Array<ConditionOperator>>;
  not?: Maybe<NestedEnumConditionOperatorWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumConditionOperatorFilter>;
  _max?: Maybe<NestedEnumConditionOperatorFilter>;
};

export type NestedEnumConditionSetOperatorFilter = {
  equals?: Maybe<ConditionSetOperator>;
  in?: Maybe<Array<ConditionSetOperator>>;
  notIn?: Maybe<Array<ConditionSetOperator>>;
  not?: Maybe<NestedEnumConditionSetOperatorFilter>;
};

export type NestedEnumConditionSetOperatorWithAggregatesFilter = {
  equals?: Maybe<ConditionSetOperator>;
  in?: Maybe<Array<ConditionSetOperator>>;
  notIn?: Maybe<Array<ConditionSetOperator>>;
  not?: Maybe<NestedEnumConditionSetOperatorWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumConditionSetOperatorFilter>;
  _max?: Maybe<NestedEnumConditionSetOperatorFilter>;
};

export type NestedEnumConditionalEntityTypeFilter = {
  equals?: Maybe<ConditionalEntityType>;
  in?: Maybe<Array<ConditionalEntityType>>;
  notIn?: Maybe<Array<ConditionalEntityType>>;
  not?: Maybe<NestedEnumConditionalEntityTypeFilter>;
};

export type NestedEnumConditionalEntityTypeWithAggregatesFilter = {
  equals?: Maybe<ConditionalEntityType>;
  in?: Maybe<Array<ConditionalEntityType>>;
  notIn?: Maybe<Array<ConditionalEntityType>>;
  not?: Maybe<NestedEnumConditionalEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumConditionalEntityTypeFilter>;
  _max?: Maybe<NestedEnumConditionalEntityTypeFilter>;
};

export type NestedEnumContactEntityTypeFilter = {
  equals?: Maybe<ContactEntityType>;
  in?: Maybe<Array<ContactEntityType>>;
  notIn?: Maybe<Array<ContactEntityType>>;
  not?: Maybe<NestedEnumContactEntityTypeFilter>;
};

export type NestedEnumContactEntityTypeWithAggregatesFilter = {
  equals?: Maybe<ContactEntityType>;
  in?: Maybe<Array<ContactEntityType>>;
  notIn?: Maybe<Array<ContactEntityType>>;
  not?: Maybe<NestedEnumContactEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumContactEntityTypeFilter>;
  _max?: Maybe<NestedEnumContactEntityTypeFilter>;
};

export type NestedEnumContactVerificationMethodNullableFilter = {
  equals?: Maybe<ContactVerificationMethod>;
  in?: Maybe<Array<ContactVerificationMethod>>;
  notIn?: Maybe<Array<ContactVerificationMethod>>;
  not?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
};

export type NestedEnumContactVerificationMethodNullableWithAggregatesFilter = {
  equals?: Maybe<ContactVerificationMethod>;
  in?: Maybe<Array<ContactVerificationMethod>>;
  notIn?: Maybe<Array<ContactVerificationMethod>>;
  not?: Maybe<NestedEnumContactVerificationMethodNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
  _max?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
};

export type NestedEnumContextEntityTypeFilter = {
  equals?: Maybe<ContextEntityType>;
  in?: Maybe<Array<ContextEntityType>>;
  notIn?: Maybe<Array<ContextEntityType>>;
  not?: Maybe<NestedEnumContextEntityTypeFilter>;
};

export type NestedEnumContextEntityTypeWithAggregatesFilter = {
  equals?: Maybe<ContextEntityType>;
  in?: Maybe<Array<ContextEntityType>>;
  notIn?: Maybe<Array<ContextEntityType>>;
  not?: Maybe<NestedEnumContextEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumContextEntityTypeFilter>;
  _max?: Maybe<NestedEnumContextEntityTypeFilter>;
};

export type NestedEnumCurrencyFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  notIn?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyFilter>;
};

export type NestedEnumCurrencyWithAggregatesFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  notIn?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCurrencyFilter>;
  _max?: Maybe<NestedEnumCurrencyFilter>;
};

export type NestedEnumCustomFieldDesignationFilter = {
  equals?: Maybe<CustomFieldDesignation>;
  in?: Maybe<Array<CustomFieldDesignation>>;
  notIn?: Maybe<Array<CustomFieldDesignation>>;
  not?: Maybe<NestedEnumCustomFieldDesignationFilter>;
};

export type NestedEnumCustomFieldDesignationWithAggregatesFilter = {
  equals?: Maybe<CustomFieldDesignation>;
  in?: Maybe<Array<CustomFieldDesignation>>;
  notIn?: Maybe<Array<CustomFieldDesignation>>;
  not?: Maybe<NestedEnumCustomFieldDesignationWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCustomFieldDesignationFilter>;
  _max?: Maybe<NestedEnumCustomFieldDesignationFilter>;
};

export type NestedEnumCustomFieldNormalizedDataTypeFilter = {
  equals?: Maybe<CustomFieldNormalizedDataType>;
  in?: Maybe<Array<CustomFieldNormalizedDataType>>;
  notIn?: Maybe<Array<CustomFieldNormalizedDataType>>;
  not?: Maybe<NestedEnumCustomFieldNormalizedDataTypeFilter>;
};

export type NestedEnumCustomFieldNormalizedDataTypeWithAggregatesFilter = {
  equals?: Maybe<CustomFieldNormalizedDataType>;
  in?: Maybe<Array<CustomFieldNormalizedDataType>>;
  notIn?: Maybe<Array<CustomFieldNormalizedDataType>>;
  not?: Maybe<NestedEnumCustomFieldNormalizedDataTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCustomFieldNormalizedDataTypeFilter>;
  _max?: Maybe<NestedEnumCustomFieldNormalizedDataTypeFilter>;
};

export type NestedEnumCustomFieldPimsSyncTypeFilter = {
  equals?: Maybe<CustomFieldPimsSyncType>;
  in?: Maybe<Array<CustomFieldPimsSyncType>>;
  notIn?: Maybe<Array<CustomFieldPimsSyncType>>;
  not?: Maybe<NestedEnumCustomFieldPimsSyncTypeFilter>;
};

export type NestedEnumCustomFieldPimsSyncTypeWithAggregatesFilter = {
  equals?: Maybe<CustomFieldPimsSyncType>;
  in?: Maybe<Array<CustomFieldPimsSyncType>>;
  notIn?: Maybe<Array<CustomFieldPimsSyncType>>;
  not?: Maybe<NestedEnumCustomFieldPimsSyncTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCustomFieldPimsSyncTypeFilter>;
  _max?: Maybe<NestedEnumCustomFieldPimsSyncTypeFilter>;
};

export type NestedEnumCustomFieldTargetTypeFilter = {
  equals?: Maybe<CustomFieldTargetType>;
  in?: Maybe<Array<CustomFieldTargetType>>;
  notIn?: Maybe<Array<CustomFieldTargetType>>;
  not?: Maybe<NestedEnumCustomFieldTargetTypeFilter>;
};

export type NestedEnumCustomFieldTargetTypeWithAggregatesFilter = {
  equals?: Maybe<CustomFieldTargetType>;
  in?: Maybe<Array<CustomFieldTargetType>>;
  notIn?: Maybe<Array<CustomFieldTargetType>>;
  not?: Maybe<NestedEnumCustomFieldTargetTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCustomFieldTargetTypeFilter>;
  _max?: Maybe<NestedEnumCustomFieldTargetTypeFilter>;
};

export type NestedEnumCustomFieldsDataSourceNullableFilter = {
  equals?: Maybe<CustomFieldsDataSource>;
  in?: Maybe<Array<CustomFieldsDataSource>>;
  notIn?: Maybe<Array<CustomFieldsDataSource>>;
  not?: Maybe<NestedEnumCustomFieldsDataSourceNullableFilter>;
};

export type NestedEnumCustomFieldsDataSourceNullableWithAggregatesFilter = {
  equals?: Maybe<CustomFieldsDataSource>;
  in?: Maybe<Array<CustomFieldsDataSource>>;
  notIn?: Maybe<Array<CustomFieldsDataSource>>;
  not?: Maybe<NestedEnumCustomFieldsDataSourceNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCustomFieldsDataSourceNullableFilter>;
  _max?: Maybe<NestedEnumCustomFieldsDataSourceNullableFilter>;
};

export type NestedEnumDeviceOsNullableFilter = {
  equals?: Maybe<DeviceOs>;
  in?: Maybe<Array<DeviceOs>>;
  notIn?: Maybe<Array<DeviceOs>>;
  not?: Maybe<NestedEnumDeviceOsNullableFilter>;
};

export type NestedEnumDeviceOsNullableWithAggregatesFilter = {
  equals?: Maybe<DeviceOs>;
  in?: Maybe<Array<DeviceOs>>;
  notIn?: Maybe<Array<DeviceOs>>;
  not?: Maybe<NestedEnumDeviceOsNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumDeviceOsNullableFilter>;
  _max?: Maybe<NestedEnumDeviceOsNullableFilter>;
};

export type NestedEnumDeviceTypeNullableFilter = {
  equals?: Maybe<DeviceType>;
  in?: Maybe<Array<DeviceType>>;
  notIn?: Maybe<Array<DeviceType>>;
  not?: Maybe<NestedEnumDeviceTypeNullableFilter>;
};

export type NestedEnumDeviceTypeNullableWithAggregatesFilter = {
  equals?: Maybe<DeviceType>;
  in?: Maybe<Array<DeviceType>>;
  notIn?: Maybe<Array<DeviceType>>;
  not?: Maybe<NestedEnumDeviceTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumDeviceTypeNullableFilter>;
  _max?: Maybe<NestedEnumDeviceTypeNullableFilter>;
};

export type NestedEnumDirectBookingAppointmentTypeClientTypeFilter = {
  equals?: Maybe<DirectBookingAppointmentTypeClientType>;
  in?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
  notIn?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
  not?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeFilter>;
};

export type NestedEnumDirectBookingAppointmentTypeClientTypeWithAggregatesFilter = {
  equals?: Maybe<DirectBookingAppointmentTypeClientType>;
  in?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
  notIn?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
  not?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeFilter>;
  _max?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeFilter>;
};

export type NestedEnumDirectBookingSpeciesOptionFilter = {
  equals?: Maybe<DirectBookingSpeciesOption>;
  in?: Maybe<Array<DirectBookingSpeciesOption>>;
  notIn?: Maybe<Array<DirectBookingSpeciesOption>>;
  not?: Maybe<NestedEnumDirectBookingSpeciesOptionFilter>;
};

export type NestedEnumDirectBookingSpeciesOptionWithAggregatesFilter = {
  equals?: Maybe<DirectBookingSpeciesOption>;
  in?: Maybe<Array<DirectBookingSpeciesOption>>;
  notIn?: Maybe<Array<DirectBookingSpeciesOption>>;
  not?: Maybe<NestedEnumDirectBookingSpeciesOptionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumDirectBookingSpeciesOptionFilter>;
  _max?: Maybe<NestedEnumDirectBookingSpeciesOptionFilter>;
};

export type NestedEnumDocumentationCategoryFilter = {
  equals?: Maybe<DocumentationCategory>;
  in?: Maybe<Array<DocumentationCategory>>;
  notIn?: Maybe<Array<DocumentationCategory>>;
  not?: Maybe<NestedEnumDocumentationCategoryFilter>;
};

export type NestedEnumDocumentationCategoryWithAggregatesFilter = {
  equals?: Maybe<DocumentationCategory>;
  in?: Maybe<Array<DocumentationCategory>>;
  notIn?: Maybe<Array<DocumentationCategory>>;
  not?: Maybe<NestedEnumDocumentationCategoryWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumDocumentationCategoryFilter>;
  _max?: Maybe<NestedEnumDocumentationCategoryFilter>;
};

export type NestedEnumDocumentationProviderFilter = {
  equals?: Maybe<DocumentationProvider>;
  in?: Maybe<Array<DocumentationProvider>>;
  notIn?: Maybe<Array<DocumentationProvider>>;
  not?: Maybe<NestedEnumDocumentationProviderFilter>;
};

export type NestedEnumDocumentationProviderWithAggregatesFilter = {
  equals?: Maybe<DocumentationProvider>;
  in?: Maybe<Array<DocumentationProvider>>;
  notIn?: Maybe<Array<DocumentationProvider>>;
  not?: Maybe<NestedEnumDocumentationProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumDocumentationProviderFilter>;
  _max?: Maybe<NestedEnumDocumentationProviderFilter>;
};

export type NestedEnumEmailCampaignCustomListRecipientStatusFilter = {
  equals?: Maybe<EmailCampaignCustomListRecipientStatus>;
  in?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
  notIn?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
  not?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusFilter>;
};

export type NestedEnumEmailCampaignCustomListRecipientStatusWithAggregatesFilter = {
  equals?: Maybe<EmailCampaignCustomListRecipientStatus>;
  in?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
  notIn?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
  not?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusFilter>;
  _max?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusFilter>;
};

export type NestedEnumEmailCampaignLogActionFilter = {
  equals?: Maybe<EmailCampaignLogAction>;
  in?: Maybe<Array<EmailCampaignLogAction>>;
  notIn?: Maybe<Array<EmailCampaignLogAction>>;
  not?: Maybe<NestedEnumEmailCampaignLogActionFilter>;
};

export type NestedEnumEmailCampaignLogActionWithAggregatesFilter = {
  equals?: Maybe<EmailCampaignLogAction>;
  in?: Maybe<Array<EmailCampaignLogAction>>;
  notIn?: Maybe<Array<EmailCampaignLogAction>>;
  not?: Maybe<NestedEnumEmailCampaignLogActionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailCampaignLogActionFilter>;
  _max?: Maybe<NestedEnumEmailCampaignLogActionFilter>;
};

export type NestedEnumEmailCampaignLogSkipReasonNullableFilter = {
  equals?: Maybe<EmailCampaignLogSkipReason>;
  in?: Maybe<Array<EmailCampaignLogSkipReason>>;
  notIn?: Maybe<Array<EmailCampaignLogSkipReason>>;
  not?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableFilter>;
};

export type NestedEnumEmailCampaignLogSkipReasonNullableWithAggregatesFilter = {
  equals?: Maybe<EmailCampaignLogSkipReason>;
  in?: Maybe<Array<EmailCampaignLogSkipReason>>;
  notIn?: Maybe<Array<EmailCampaignLogSkipReason>>;
  not?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableFilter>;
  _max?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableFilter>;
};

export type NestedEnumEmailCampaignSegmentationTypeFilter = {
  equals?: Maybe<EmailCampaignSegmentationType>;
  in?: Maybe<Array<EmailCampaignSegmentationType>>;
  notIn?: Maybe<Array<EmailCampaignSegmentationType>>;
  not?: Maybe<NestedEnumEmailCampaignSegmentationTypeFilter>;
};

export type NestedEnumEmailCampaignSegmentationTypeWithAggregatesFilter = {
  equals?: Maybe<EmailCampaignSegmentationType>;
  in?: Maybe<Array<EmailCampaignSegmentationType>>;
  notIn?: Maybe<Array<EmailCampaignSegmentationType>>;
  not?: Maybe<NestedEnumEmailCampaignSegmentationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailCampaignSegmentationTypeFilter>;
  _max?: Maybe<NestedEnumEmailCampaignSegmentationTypeFilter>;
};

export type NestedEnumEmailCampaignStatusFilter = {
  equals?: Maybe<EmailCampaignStatus>;
  in?: Maybe<Array<EmailCampaignStatus>>;
  notIn?: Maybe<Array<EmailCampaignStatus>>;
  not?: Maybe<NestedEnumEmailCampaignStatusFilter>;
};

export type NestedEnumEmailCampaignStatusWithAggregatesFilter = {
  equals?: Maybe<EmailCampaignStatus>;
  in?: Maybe<Array<EmailCampaignStatus>>;
  notIn?: Maybe<Array<EmailCampaignStatus>>;
  not?: Maybe<NestedEnumEmailCampaignStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailCampaignStatusFilter>;
  _max?: Maybe<NestedEnumEmailCampaignStatusFilter>;
};

export type NestedEnumEmailCampaignTypeFilter = {
  equals?: Maybe<EmailCampaignType>;
  in?: Maybe<Array<EmailCampaignType>>;
  notIn?: Maybe<Array<EmailCampaignType>>;
  not?: Maybe<NestedEnumEmailCampaignTypeFilter>;
};

export type NestedEnumEmailCampaignTypeWithAggregatesFilter = {
  equals?: Maybe<EmailCampaignType>;
  in?: Maybe<Array<EmailCampaignType>>;
  notIn?: Maybe<Array<EmailCampaignType>>;
  not?: Maybe<NestedEnumEmailCampaignTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailCampaignTypeFilter>;
  _max?: Maybe<NestedEnumEmailCampaignTypeFilter>;
};

export type NestedEnumEmailCampaignUnsubscribeSourceFilter = {
  equals?: Maybe<EmailCampaignUnsubscribeSource>;
  in?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
  notIn?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
  not?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceFilter>;
};

export type NestedEnumEmailCampaignUnsubscribeSourceWithAggregatesFilter = {
  equals?: Maybe<EmailCampaignUnsubscribeSource>;
  in?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
  notIn?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
  not?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceFilter>;
  _max?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceFilter>;
};

export type NestedEnumEmailProviderFilter = {
  equals?: Maybe<EmailProvider>;
  in?: Maybe<Array<EmailProvider>>;
  notIn?: Maybe<Array<EmailProvider>>;
  not?: Maybe<NestedEnumEmailProviderFilter>;
};

export type NestedEnumEmailProviderLogEntityTypeFilter = {
  equals?: Maybe<EmailProviderLogEntityType>;
  in?: Maybe<Array<EmailProviderLogEntityType>>;
  notIn?: Maybe<Array<EmailProviderLogEntityType>>;
  not?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
};

export type NestedEnumEmailProviderLogEntityTypeWithAggregatesFilter = {
  equals?: Maybe<EmailProviderLogEntityType>;
  in?: Maybe<Array<EmailProviderLogEntityType>>;
  notIn?: Maybe<Array<EmailProviderLogEntityType>>;
  not?: Maybe<NestedEnumEmailProviderLogEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
  _max?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
};

export type NestedEnumEmailProviderWithAggregatesFilter = {
  equals?: Maybe<EmailProvider>;
  in?: Maybe<Array<EmailProvider>>;
  notIn?: Maybe<Array<EmailProvider>>;
  not?: Maybe<NestedEnumEmailProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailProviderFilter>;
  _max?: Maybe<NestedEnumEmailProviderFilter>;
};

export type NestedEnumEmailTemplateTypeFilter = {
  equals?: Maybe<EmailTemplateType>;
  in?: Maybe<Array<EmailTemplateType>>;
  notIn?: Maybe<Array<EmailTemplateType>>;
  not?: Maybe<NestedEnumEmailTemplateTypeFilter>;
};

export type NestedEnumEmailTemplateTypeWithAggregatesFilter = {
  equals?: Maybe<EmailTemplateType>;
  in?: Maybe<Array<EmailTemplateType>>;
  notIn?: Maybe<Array<EmailTemplateType>>;
  not?: Maybe<NestedEnumEmailTemplateTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailTemplateTypeFilter>;
  _max?: Maybe<NestedEnumEmailTemplateTypeFilter>;
};

export type NestedEnumEnrollmentStatusFilter = {
  equals?: Maybe<EnrollmentStatus>;
  in?: Maybe<Array<EnrollmentStatus>>;
  notIn?: Maybe<Array<EnrollmentStatus>>;
  not?: Maybe<NestedEnumEnrollmentStatusFilter>;
};

export type NestedEnumEnrollmentStatusWithAggregatesFilter = {
  equals?: Maybe<EnrollmentStatus>;
  in?: Maybe<Array<EnrollmentStatus>>;
  notIn?: Maybe<Array<EnrollmentStatus>>;
  not?: Maybe<NestedEnumEnrollmentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEnrollmentStatusFilter>;
  _max?: Maybe<NestedEnumEnrollmentStatusFilter>;
};

export type NestedEnumFeatureFlagStateFilter = {
  equals?: Maybe<FeatureFlagState>;
  in?: Maybe<Array<FeatureFlagState>>;
  notIn?: Maybe<Array<FeatureFlagState>>;
  not?: Maybe<NestedEnumFeatureFlagStateFilter>;
};

export type NestedEnumFeatureFlagStateWithAggregatesFilter = {
  equals?: Maybe<FeatureFlagState>;
  in?: Maybe<Array<FeatureFlagState>>;
  notIn?: Maybe<Array<FeatureFlagState>>;
  not?: Maybe<NestedEnumFeatureFlagStateWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFeatureFlagStateFilter>;
  _max?: Maybe<NestedEnumFeatureFlagStateFilter>;
};

export type NestedEnumFeatureFlagTypeFilter = {
  equals?: Maybe<FeatureFlagType>;
  in?: Maybe<Array<FeatureFlagType>>;
  notIn?: Maybe<Array<FeatureFlagType>>;
  not?: Maybe<NestedEnumFeatureFlagTypeFilter>;
};

export type NestedEnumFeatureFlagTypeWithAggregatesFilter = {
  equals?: Maybe<FeatureFlagType>;
  in?: Maybe<Array<FeatureFlagType>>;
  notIn?: Maybe<Array<FeatureFlagType>>;
  not?: Maybe<NestedEnumFeatureFlagTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFeatureFlagTypeFilter>;
  _max?: Maybe<NestedEnumFeatureFlagTypeFilter>;
};

export type NestedEnumFeeCollectionFilter = {
  equals?: Maybe<FeeCollection>;
  in?: Maybe<Array<FeeCollection>>;
  notIn?: Maybe<Array<FeeCollection>>;
  not?: Maybe<NestedEnumFeeCollectionFilter>;
};

export type NestedEnumFeeCollectionWithAggregatesFilter = {
  equals?: Maybe<FeeCollection>;
  in?: Maybe<Array<FeeCollection>>;
  notIn?: Maybe<Array<FeeCollection>>;
  not?: Maybe<NestedEnumFeeCollectionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFeeCollectionFilter>;
  _max?: Maybe<NestedEnumFeeCollectionFilter>;
};

export type NestedEnumFilterSelectionTypeFilter = {
  equals?: Maybe<FilterSelectionType>;
  in?: Maybe<Array<FilterSelectionType>>;
  notIn?: Maybe<Array<FilterSelectionType>>;
  not?: Maybe<NestedEnumFilterSelectionTypeFilter>;
};

export type NestedEnumFilterSelectionTypeWithAggregatesFilter = {
  equals?: Maybe<FilterSelectionType>;
  in?: Maybe<Array<FilterSelectionType>>;
  notIn?: Maybe<Array<FilterSelectionType>>;
  not?: Maybe<NestedEnumFilterSelectionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFilterSelectionTypeFilter>;
  _max?: Maybe<NestedEnumFilterSelectionTypeFilter>;
};

export type NestedEnumFinancialTransactionTypeFilter = {
  equals?: Maybe<FinancialTransactionType>;
  in?: Maybe<Array<FinancialTransactionType>>;
  notIn?: Maybe<Array<FinancialTransactionType>>;
  not?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
};

export type NestedEnumFinancialTransactionTypeWithAggregatesFilter = {
  equals?: Maybe<FinancialTransactionType>;
  in?: Maybe<Array<FinancialTransactionType>>;
  notIn?: Maybe<Array<FinancialTransactionType>>;
  not?: Maybe<NestedEnumFinancialTransactionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
  _max?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
};

export type NestedEnumFormTemplateTypeFilter = {
  equals?: Maybe<FormTemplateType>;
  in?: Maybe<Array<FormTemplateType>>;
  notIn?: Maybe<Array<FormTemplateType>>;
  not?: Maybe<NestedEnumFormTemplateTypeFilter>;
};

export type NestedEnumFormTemplateTypeWithAggregatesFilter = {
  equals?: Maybe<FormTemplateType>;
  in?: Maybe<Array<FormTemplateType>>;
  notIn?: Maybe<Array<FormTemplateType>>;
  not?: Maybe<NestedEnumFormTemplateTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFormTemplateTypeFilter>;
  _max?: Maybe<NestedEnumFormTemplateTypeFilter>;
};

export type NestedEnumHillsToHomeResultTypeFilter = {
  equals?: Maybe<HillsToHomeResultType>;
  in?: Maybe<Array<HillsToHomeResultType>>;
  notIn?: Maybe<Array<HillsToHomeResultType>>;
  not?: Maybe<NestedEnumHillsToHomeResultTypeFilter>;
};

export type NestedEnumHillsToHomeResultTypeWithAggregatesFilter = {
  equals?: Maybe<HillsToHomeResultType>;
  in?: Maybe<Array<HillsToHomeResultType>>;
  notIn?: Maybe<Array<HillsToHomeResultType>>;
  not?: Maybe<NestedEnumHillsToHomeResultTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumHillsToHomeResultTypeFilter>;
  _max?: Maybe<NestedEnumHillsToHomeResultTypeFilter>;
};

export type NestedEnumHillsToHomeSpeciesNullableFilter = {
  equals?: Maybe<HillsToHomeSpecies>;
  in?: Maybe<Array<HillsToHomeSpecies>>;
  notIn?: Maybe<Array<HillsToHomeSpecies>>;
  not?: Maybe<NestedEnumHillsToHomeSpeciesNullableFilter>;
};

export type NestedEnumHillsToHomeSpeciesNullableWithAggregatesFilter = {
  equals?: Maybe<HillsToHomeSpecies>;
  in?: Maybe<Array<HillsToHomeSpecies>>;
  notIn?: Maybe<Array<HillsToHomeSpecies>>;
  not?: Maybe<NestedEnumHillsToHomeSpeciesNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumHillsToHomeSpeciesNullableFilter>;
  _max?: Maybe<NestedEnumHillsToHomeSpeciesNullableFilter>;
};

export type NestedEnumInformAudienceDefinitionStatusFilter = {
  equals?: Maybe<InformAudienceDefinitionStatus>;
  in?: Maybe<Array<InformAudienceDefinitionStatus>>;
  notIn?: Maybe<Array<InformAudienceDefinitionStatus>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionStatusFilter>;
};

export type NestedEnumInformAudienceDefinitionStatusWithAggregatesFilter = {
  equals?: Maybe<InformAudienceDefinitionStatus>;
  in?: Maybe<Array<InformAudienceDefinitionStatus>>;
  notIn?: Maybe<Array<InformAudienceDefinitionStatus>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformAudienceDefinitionStatusFilter>;
  _max?: Maybe<NestedEnumInformAudienceDefinitionStatusFilter>;
};

export type NestedEnumInformAudienceDefinitionTypeFilter = {
  equals?: Maybe<InformAudienceDefinitionType>;
  in?: Maybe<Array<InformAudienceDefinitionType>>;
  notIn?: Maybe<Array<InformAudienceDefinitionType>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionTypeFilter>;
};

export type NestedEnumInformAudienceDefinitionTypeWithAggregatesFilter = {
  equals?: Maybe<InformAudienceDefinitionType>;
  in?: Maybe<Array<InformAudienceDefinitionType>>;
  notIn?: Maybe<Array<InformAudienceDefinitionType>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformAudienceDefinitionTypeFilter>;
  _max?: Maybe<NestedEnumInformAudienceDefinitionTypeFilter>;
};

export type NestedEnumInformCampaignExecutionStatusFilter = {
  equals?: Maybe<InformCampaignExecutionStatus>;
  in?: Maybe<Array<InformCampaignExecutionStatus>>;
  notIn?: Maybe<Array<InformCampaignExecutionStatus>>;
  not?: Maybe<NestedEnumInformCampaignExecutionStatusFilter>;
};

export type NestedEnumInformCampaignExecutionStatusWithAggregatesFilter = {
  equals?: Maybe<InformCampaignExecutionStatus>;
  in?: Maybe<Array<InformCampaignExecutionStatus>>;
  notIn?: Maybe<Array<InformCampaignExecutionStatus>>;
  not?: Maybe<NestedEnumInformCampaignExecutionStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformCampaignExecutionStatusFilter>;
  _max?: Maybe<NestedEnumInformCampaignExecutionStatusFilter>;
};

export type NestedEnumInformCampaignStatusFilter = {
  equals?: Maybe<InformCampaignStatus>;
  in?: Maybe<Array<InformCampaignStatus>>;
  notIn?: Maybe<Array<InformCampaignStatus>>;
  not?: Maybe<NestedEnumInformCampaignStatusFilter>;
};

export type NestedEnumInformCampaignStatusWithAggregatesFilter = {
  equals?: Maybe<InformCampaignStatus>;
  in?: Maybe<Array<InformCampaignStatus>>;
  notIn?: Maybe<Array<InformCampaignStatus>>;
  not?: Maybe<NestedEnumInformCampaignStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformCampaignStatusFilter>;
  _max?: Maybe<NestedEnumInformCampaignStatusFilter>;
};

export type NestedEnumInformCampaignTypeFilter = {
  equals?: Maybe<InformCampaignType>;
  in?: Maybe<Array<InformCampaignType>>;
  notIn?: Maybe<Array<InformCampaignType>>;
  not?: Maybe<NestedEnumInformCampaignTypeFilter>;
};

export type NestedEnumInformCampaignTypeWithAggregatesFilter = {
  equals?: Maybe<InformCampaignType>;
  in?: Maybe<Array<InformCampaignType>>;
  notIn?: Maybe<Array<InformCampaignType>>;
  not?: Maybe<NestedEnumInformCampaignTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformCampaignTypeFilter>;
  _max?: Maybe<NestedEnumInformCampaignTypeFilter>;
};

export type NestedEnumInformControlGroupEntityTypeFilter = {
  equals?: Maybe<InformControlGroupEntityType>;
  in?: Maybe<Array<InformControlGroupEntityType>>;
  notIn?: Maybe<Array<InformControlGroupEntityType>>;
  not?: Maybe<NestedEnumInformControlGroupEntityTypeFilter>;
};

export type NestedEnumInformControlGroupEntityTypeWithAggregatesFilter = {
  equals?: Maybe<InformControlGroupEntityType>;
  in?: Maybe<Array<InformControlGroupEntityType>>;
  notIn?: Maybe<Array<InformControlGroupEntityType>>;
  not?: Maybe<NestedEnumInformControlGroupEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformControlGroupEntityTypeFilter>;
  _max?: Maybe<NestedEnumInformControlGroupEntityTypeFilter>;
};

export type NestedEnumInformEmailTemplateStatusFilter = {
  equals?: Maybe<InformEmailTemplateStatus>;
  in?: Maybe<Array<InformEmailTemplateStatus>>;
  notIn?: Maybe<Array<InformEmailTemplateStatus>>;
  not?: Maybe<NestedEnumInformEmailTemplateStatusFilter>;
};

export type NestedEnumInformEmailTemplateStatusWithAggregatesFilter = {
  equals?: Maybe<InformEmailTemplateStatus>;
  in?: Maybe<Array<InformEmailTemplateStatus>>;
  notIn?: Maybe<Array<InformEmailTemplateStatus>>;
  not?: Maybe<NestedEnumInformEmailTemplateStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformEmailTemplateStatusFilter>;
  _max?: Maybe<NestedEnumInformEmailTemplateStatusFilter>;
};

export type NestedEnumInformEnrollmentStatusFilter = {
  equals?: Maybe<InformEnrollmentStatus>;
  in?: Maybe<Array<InformEnrollmentStatus>>;
  notIn?: Maybe<Array<InformEnrollmentStatus>>;
  not?: Maybe<NestedEnumInformEnrollmentStatusFilter>;
};

export type NestedEnumInformEnrollmentStatusWithAggregatesFilter = {
  equals?: Maybe<InformEnrollmentStatus>;
  in?: Maybe<Array<InformEnrollmentStatus>>;
  notIn?: Maybe<Array<InformEnrollmentStatus>>;
  not?: Maybe<NestedEnumInformEnrollmentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformEnrollmentStatusFilter>;
  _max?: Maybe<NestedEnumInformEnrollmentStatusFilter>;
};

export type NestedEnumInformPartnerCustomerPharmacyProviderNameFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameFilter>;
};

export type NestedEnumInformPartnerCustomerPharmacyProviderNameWithAggregatesFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameFilter>;
  _max?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameFilter>;
};

export type NestedEnumInformPartnerCustomerPharmacyProviderStatusFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusFilter>;
};

export type NestedEnumInformPartnerCustomerPharmacyProviderStatusWithAggregatesFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusFilter>;
  _max?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusFilter>;
};

export type NestedEnumInformPartnerCustomerPharmacyProviderTypeFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeFilter>;
};

export type NestedEnumInformPartnerCustomerPharmacyProviderTypeWithAggregatesFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeFilter>;
  _max?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeFilter>;
};

export type NestedEnumInformPartnerIntegrationModelNameFilter = {
  equals?: Maybe<InformPartnerIntegrationModelName>;
  in?: Maybe<Array<InformPartnerIntegrationModelName>>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelName>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelNameFilter>;
};

export type NestedEnumInformPartnerIntegrationModelNameWithAggregatesFilter = {
  equals?: Maybe<InformPartnerIntegrationModelName>;
  in?: Maybe<Array<InformPartnerIntegrationModelName>>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelName>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelNameWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerIntegrationModelNameFilter>;
  _max?: Maybe<NestedEnumInformPartnerIntegrationModelNameFilter>;
};

export type NestedEnumInformPartnerIntegrationModelStatusFilter = {
  equals?: Maybe<InformPartnerIntegrationModelStatus>;
  in?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelStatusFilter>;
};

export type NestedEnumInformPartnerIntegrationModelStatusWithAggregatesFilter = {
  equals?: Maybe<InformPartnerIntegrationModelStatus>;
  in?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerIntegrationModelStatusFilter>;
  _max?: Maybe<NestedEnumInformPartnerIntegrationModelStatusFilter>;
};

export type NestedEnumInformPartnerIntegrationStatusFilter = {
  equals?: Maybe<InformPartnerIntegrationStatus>;
  in?: Maybe<Array<InformPartnerIntegrationStatus>>;
  notIn?: Maybe<Array<InformPartnerIntegrationStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationStatusFilter>;
};

export type NestedEnumInformPartnerIntegrationStatusWithAggregatesFilter = {
  equals?: Maybe<InformPartnerIntegrationStatus>;
  in?: Maybe<Array<InformPartnerIntegrationStatus>>;
  notIn?: Maybe<Array<InformPartnerIntegrationStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerIntegrationStatusFilter>;
  _max?: Maybe<NestedEnumInformPartnerIntegrationStatusFilter>;
};

export type NestedEnumInformPartnerProgramStatusFilter = {
  equals?: Maybe<InformPartnerProgramStatus>;
  in?: Maybe<Array<InformPartnerProgramStatus>>;
  notIn?: Maybe<Array<InformPartnerProgramStatus>>;
  not?: Maybe<NestedEnumInformPartnerProgramStatusFilter>;
};

export type NestedEnumInformPartnerProgramStatusWithAggregatesFilter = {
  equals?: Maybe<InformPartnerProgramStatus>;
  in?: Maybe<Array<InformPartnerProgramStatus>>;
  notIn?: Maybe<Array<InformPartnerProgramStatus>>;
  not?: Maybe<NestedEnumInformPartnerProgramStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerProgramStatusFilter>;
  _max?: Maybe<NestedEnumInformPartnerProgramStatusFilter>;
};

export type NestedEnumInformPartnerStatusFilter = {
  equals?: Maybe<InformPartnerStatus>;
  in?: Maybe<Array<InformPartnerStatus>>;
  notIn?: Maybe<Array<InformPartnerStatus>>;
  not?: Maybe<NestedEnumInformPartnerStatusFilter>;
};

export type NestedEnumInformPartnerStatusWithAggregatesFilter = {
  equals?: Maybe<InformPartnerStatus>;
  in?: Maybe<Array<InformPartnerStatus>>;
  notIn?: Maybe<Array<InformPartnerStatus>>;
  not?: Maybe<NestedEnumInformPartnerStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerStatusFilter>;
  _max?: Maybe<NestedEnumInformPartnerStatusFilter>;
};

export type NestedEnumInformPartnerUserRoleNameFilter = {
  equals?: Maybe<InformPartnerUserRoleName>;
  in?: Maybe<Array<InformPartnerUserRoleName>>;
  notIn?: Maybe<Array<InformPartnerUserRoleName>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleNameFilter>;
};

export type NestedEnumInformPartnerUserRoleNameWithAggregatesFilter = {
  equals?: Maybe<InformPartnerUserRoleName>;
  in?: Maybe<Array<InformPartnerUserRoleName>>;
  notIn?: Maybe<Array<InformPartnerUserRoleName>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleNameWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerUserRoleNameFilter>;
  _max?: Maybe<NestedEnumInformPartnerUserRoleNameFilter>;
};

export type NestedEnumInformPartnerUserRoleStatusFilter = {
  equals?: Maybe<InformPartnerUserRoleStatus>;
  in?: Maybe<Array<InformPartnerUserRoleStatus>>;
  notIn?: Maybe<Array<InformPartnerUserRoleStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleStatusFilter>;
};

export type NestedEnumInformPartnerUserRoleStatusWithAggregatesFilter = {
  equals?: Maybe<InformPartnerUserRoleStatus>;
  in?: Maybe<Array<InformPartnerUserRoleStatus>>;
  notIn?: Maybe<Array<InformPartnerUserRoleStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerUserRoleStatusFilter>;
  _max?: Maybe<NestedEnumInformPartnerUserRoleStatusFilter>;
};

export type NestedEnumInformPartnerUserStatusFilter = {
  equals?: Maybe<InformPartnerUserStatus>;
  in?: Maybe<Array<InformPartnerUserStatus>>;
  notIn?: Maybe<Array<InformPartnerUserStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserStatusFilter>;
};

export type NestedEnumInformPartnerUserStatusWithAggregatesFilter = {
  equals?: Maybe<InformPartnerUserStatus>;
  in?: Maybe<Array<InformPartnerUserStatus>>;
  notIn?: Maybe<Array<InformPartnerUserStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInformPartnerUserStatusFilter>;
  _max?: Maybe<NestedEnumInformPartnerUserStatusFilter>;
};

export type NestedEnumInformScheduledCampaignCadenceNullableFilter = {
  equals?: Maybe<InformScheduledCampaignCadence>;
  in?: Maybe<Array<InformScheduledCampaignCadence>>;
  notIn?: Maybe<Array<InformScheduledCampaignCadence>>;
  not?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableFilter>;
};

export type NestedEnumInformScheduledCampaignCadenceNullableWithAggregatesFilter = {
  equals?: Maybe<InformScheduledCampaignCadence>;
  in?: Maybe<Array<InformScheduledCampaignCadence>>;
  notIn?: Maybe<Array<InformScheduledCampaignCadence>>;
  not?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableFilter>;
  _max?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableFilter>;
};

export type NestedEnumInsuranceIntegrationTypeFilter = {
  equals?: Maybe<InsuranceIntegrationType>;
  in?: Maybe<Array<InsuranceIntegrationType>>;
  notIn?: Maybe<Array<InsuranceIntegrationType>>;
  not?: Maybe<NestedEnumInsuranceIntegrationTypeFilter>;
};

export type NestedEnumInsuranceIntegrationTypeWithAggregatesFilter = {
  equals?: Maybe<InsuranceIntegrationType>;
  in?: Maybe<Array<InsuranceIntegrationType>>;
  notIn?: Maybe<Array<InsuranceIntegrationType>>;
  not?: Maybe<NestedEnumInsuranceIntegrationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInsuranceIntegrationTypeFilter>;
  _max?: Maybe<NestedEnumInsuranceIntegrationTypeFilter>;
};

export type NestedEnumIntegrationStatusNullableFilter = {
  equals?: Maybe<IntegrationStatus>;
  in?: Maybe<Array<IntegrationStatus>>;
  notIn?: Maybe<Array<IntegrationStatus>>;
  not?: Maybe<NestedEnumIntegrationStatusNullableFilter>;
};

export type NestedEnumIntegrationStatusNullableWithAggregatesFilter = {
  equals?: Maybe<IntegrationStatus>;
  in?: Maybe<Array<IntegrationStatus>>;
  notIn?: Maybe<Array<IntegrationStatus>>;
  not?: Maybe<NestedEnumIntegrationStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumIntegrationStatusNullableFilter>;
  _max?: Maybe<NestedEnumIntegrationStatusNullableFilter>;
};

export type NestedEnumIntegrationTypeNullableFilter = {
  equals?: Maybe<IntegrationType>;
  in?: Maybe<Array<IntegrationType>>;
  notIn?: Maybe<Array<IntegrationType>>;
  not?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
};

export type NestedEnumIntegrationTypeNullableWithAggregatesFilter = {
  equals?: Maybe<IntegrationType>;
  in?: Maybe<Array<IntegrationType>>;
  notIn?: Maybe<Array<IntegrationType>>;
  not?: Maybe<NestedEnumIntegrationTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
  _max?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
};

export type NestedEnumInvoiceLineItemTypeFilter = {
  equals?: Maybe<InvoiceLineItemType>;
  in?: Maybe<Array<InvoiceLineItemType>>;
  notIn?: Maybe<Array<InvoiceLineItemType>>;
  not?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
};

export type NestedEnumInvoiceLineItemTypeWithAggregatesFilter = {
  equals?: Maybe<InvoiceLineItemType>;
  in?: Maybe<Array<InvoiceLineItemType>>;
  notIn?: Maybe<Array<InvoiceLineItemType>>;
  not?: Maybe<NestedEnumInvoiceLineItemTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
  _max?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
};

export type NestedEnumInvoicePimsWritebackActionFilter = {
  equals?: Maybe<InvoicePimsWritebackAction>;
  in?: Maybe<Array<InvoicePimsWritebackAction>>;
  notIn?: Maybe<Array<InvoicePimsWritebackAction>>;
  not?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
};

export type NestedEnumInvoicePimsWritebackActionWithAggregatesFilter = {
  equals?: Maybe<InvoicePimsWritebackAction>;
  in?: Maybe<Array<InvoicePimsWritebackAction>>;
  notIn?: Maybe<Array<InvoicePimsWritebackAction>>;
  not?: Maybe<NestedEnumInvoicePimsWritebackActionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
  _max?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
};

export type NestedEnumInvoiceStatusFilter = {
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  notIn?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusFilter>;
};

export type NestedEnumInvoiceStatusWithAggregatesFilter = {
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  notIn?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInvoiceStatusFilter>;
  _max?: Maybe<NestedEnumInvoiceStatusFilter>;
};

export type NestedEnumLegalEntityTypeFilter = {
  equals?: Maybe<LegalEntityType>;
  in?: Maybe<Array<LegalEntityType>>;
  notIn?: Maybe<Array<LegalEntityType>>;
  not?: Maybe<NestedEnumLegalEntityTypeFilter>;
};

export type NestedEnumLegalEntityTypeWithAggregatesFilter = {
  equals?: Maybe<LegalEntityType>;
  in?: Maybe<Array<LegalEntityType>>;
  notIn?: Maybe<Array<LegalEntityType>>;
  not?: Maybe<NestedEnumLegalEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLegalEntityTypeFilter>;
  _max?: Maybe<NestedEnumLegalEntityTypeFilter>;
};

export type NestedEnumLoyaltyAccountMergerStatusFilter = {
  equals?: Maybe<LoyaltyAccountMergerStatus>;
  in?: Maybe<Array<LoyaltyAccountMergerStatus>>;
  notIn?: Maybe<Array<LoyaltyAccountMergerStatus>>;
  not?: Maybe<NestedEnumLoyaltyAccountMergerStatusFilter>;
};

export type NestedEnumLoyaltyAccountMergerStatusWithAggregatesFilter = {
  equals?: Maybe<LoyaltyAccountMergerStatus>;
  in?: Maybe<Array<LoyaltyAccountMergerStatus>>;
  notIn?: Maybe<Array<LoyaltyAccountMergerStatus>>;
  not?: Maybe<NestedEnumLoyaltyAccountMergerStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLoyaltyAccountMergerStatusFilter>;
  _max?: Maybe<NestedEnumLoyaltyAccountMergerStatusFilter>;
};

export type NestedEnumLoyaltyPointDeltaSourceFilter = {
  equals?: Maybe<LoyaltyPointDeltaSource>;
  in?: Maybe<Array<LoyaltyPointDeltaSource>>;
  notIn?: Maybe<Array<LoyaltyPointDeltaSource>>;
  not?: Maybe<NestedEnumLoyaltyPointDeltaSourceFilter>;
};

export type NestedEnumLoyaltyPointDeltaSourceWithAggregatesFilter = {
  equals?: Maybe<LoyaltyPointDeltaSource>;
  in?: Maybe<Array<LoyaltyPointDeltaSource>>;
  notIn?: Maybe<Array<LoyaltyPointDeltaSource>>;
  not?: Maybe<NestedEnumLoyaltyPointDeltaSourceWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLoyaltyPointDeltaSourceFilter>;
  _max?: Maybe<NestedEnumLoyaltyPointDeltaSourceFilter>;
};

export type NestedEnumLoyaltyProgramStatusFilter = {
  equals?: Maybe<LoyaltyProgramStatus>;
  in?: Maybe<Array<LoyaltyProgramStatus>>;
  notIn?: Maybe<Array<LoyaltyProgramStatus>>;
  not?: Maybe<NestedEnumLoyaltyProgramStatusFilter>;
};

export type NestedEnumLoyaltyProgramStatusWithAggregatesFilter = {
  equals?: Maybe<LoyaltyProgramStatus>;
  in?: Maybe<Array<LoyaltyProgramStatus>>;
  notIn?: Maybe<Array<LoyaltyProgramStatus>>;
  not?: Maybe<NestedEnumLoyaltyProgramStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLoyaltyProgramStatusFilter>;
  _max?: Maybe<NestedEnumLoyaltyProgramStatusFilter>;
};

export type NestedEnumLoyaltyRewardAvailabilityFilter = {
  equals?: Maybe<LoyaltyRewardAvailability>;
  in?: Maybe<Array<LoyaltyRewardAvailability>>;
  notIn?: Maybe<Array<LoyaltyRewardAvailability>>;
  not?: Maybe<NestedEnumLoyaltyRewardAvailabilityFilter>;
};

export type NestedEnumLoyaltyRewardAvailabilityWithAggregatesFilter = {
  equals?: Maybe<LoyaltyRewardAvailability>;
  in?: Maybe<Array<LoyaltyRewardAvailability>>;
  notIn?: Maybe<Array<LoyaltyRewardAvailability>>;
  not?: Maybe<NestedEnumLoyaltyRewardAvailabilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLoyaltyRewardAvailabilityFilter>;
  _max?: Maybe<NestedEnumLoyaltyRewardAvailabilityFilter>;
};

export type NestedEnumLoyaltyRewardRedemptionActorTypeFilter = {
  equals?: Maybe<LoyaltyRewardRedemptionActorType>;
  in?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeFilter>;
};

export type NestedEnumLoyaltyRewardRedemptionActorTypeWithAggregatesFilter = {
  equals?: Maybe<LoyaltyRewardRedemptionActorType>;
  in?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeFilter>;
  _max?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeFilter>;
};

export type NestedEnumLoyaltyRewardRedemptionStatusFilter = {
  equals?: Maybe<LoyaltyRewardRedemptionStatus>;
  in?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusFilter>;
};

export type NestedEnumLoyaltyRewardRedemptionStatusWithAggregatesFilter = {
  equals?: Maybe<LoyaltyRewardRedemptionStatus>;
  in?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusFilter>;
  _max?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusFilter>;
};

export type NestedEnumManageMembershipRequestTypeFilter = {
  equals?: Maybe<ManageMembershipRequestType>;
  in?: Maybe<Array<ManageMembershipRequestType>>;
  notIn?: Maybe<Array<ManageMembershipRequestType>>;
  not?: Maybe<NestedEnumManageMembershipRequestTypeFilter>;
};

export type NestedEnumManageMembershipRequestTypeWithAggregatesFilter = {
  equals?: Maybe<ManageMembershipRequestType>;
  in?: Maybe<Array<ManageMembershipRequestType>>;
  notIn?: Maybe<Array<ManageMembershipRequestType>>;
  not?: Maybe<NestedEnumManageMembershipRequestTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumManageMembershipRequestTypeFilter>;
  _max?: Maybe<NestedEnumManageMembershipRequestTypeFilter>;
};

export type NestedEnumMassTextAlertStatusFilter = {
  equals?: Maybe<MassTextAlertStatus>;
  in?: Maybe<Array<MassTextAlertStatus>>;
  notIn?: Maybe<Array<MassTextAlertStatus>>;
  not?: Maybe<NestedEnumMassTextAlertStatusFilter>;
};

export type NestedEnumMassTextAlertStatusWithAggregatesFilter = {
  equals?: Maybe<MassTextAlertStatus>;
  in?: Maybe<Array<MassTextAlertStatus>>;
  notIn?: Maybe<Array<MassTextAlertStatus>>;
  not?: Maybe<NestedEnumMassTextAlertStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMassTextAlertStatusFilter>;
  _max?: Maybe<NestedEnumMassTextAlertStatusFilter>;
};

export type NestedEnumMediaTypeFilter = {
  equals?: Maybe<MediaType>;
  in?: Maybe<Array<MediaType>>;
  notIn?: Maybe<Array<MediaType>>;
  not?: Maybe<NestedEnumMediaTypeFilter>;
};

export type NestedEnumMediaTypeWithAggregatesFilter = {
  equals?: Maybe<MediaType>;
  in?: Maybe<Array<MediaType>>;
  notIn?: Maybe<Array<MediaType>>;
  not?: Maybe<NestedEnumMediaTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMediaTypeFilter>;
  _max?: Maybe<NestedEnumMediaTypeFilter>;
};

export type NestedEnumMediaVersionFilter = {
  equals?: Maybe<MediaVersion>;
  in?: Maybe<Array<MediaVersion>>;
  notIn?: Maybe<Array<MediaVersion>>;
  not?: Maybe<NestedEnumMediaVersionFilter>;
};

export type NestedEnumMediaVersionWithAggregatesFilter = {
  equals?: Maybe<MediaVersion>;
  in?: Maybe<Array<MediaVersion>>;
  notIn?: Maybe<Array<MediaVersion>>;
  not?: Maybe<NestedEnumMediaVersionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMediaVersionFilter>;
  _max?: Maybe<NestedEnumMediaVersionFilter>;
};

export type NestedEnumMessageSourceFilter = {
  equals?: Maybe<MessageSource>;
  in?: Maybe<Array<MessageSource>>;
  notIn?: Maybe<Array<MessageSource>>;
  not?: Maybe<NestedEnumMessageSourceFilter>;
};

export type NestedEnumMessageSourceWithAggregatesFilter = {
  equals?: Maybe<MessageSource>;
  in?: Maybe<Array<MessageSource>>;
  notIn?: Maybe<Array<MessageSource>>;
  not?: Maybe<NestedEnumMessageSourceWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMessageSourceFilter>;
  _max?: Maybe<NestedEnumMessageSourceFilter>;
};

export type NestedEnumMessageTypeFilter = {
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  notIn?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeFilter>;
};

export type NestedEnumMessageTypeWithAggregatesFilter = {
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  notIn?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMessageTypeFilter>;
  _max?: Maybe<NestedEnumMessageTypeFilter>;
};

export type NestedEnumMessagingCampaignStatusFilter = {
  equals?: Maybe<MessagingCampaignStatus>;
  in?: Maybe<Array<MessagingCampaignStatus>>;
  notIn?: Maybe<Array<MessagingCampaignStatus>>;
  not?: Maybe<NestedEnumMessagingCampaignStatusFilter>;
};

export type NestedEnumMessagingCampaignStatusWithAggregatesFilter = {
  equals?: Maybe<MessagingCampaignStatus>;
  in?: Maybe<Array<MessagingCampaignStatus>>;
  notIn?: Maybe<Array<MessagingCampaignStatus>>;
  not?: Maybe<NestedEnumMessagingCampaignStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMessagingCampaignStatusFilter>;
  _max?: Maybe<NestedEnumMessagingCampaignStatusFilter>;
};

export type NestedEnumMessagingCampaignTypeFilter = {
  equals?: Maybe<MessagingCampaignType>;
  in?: Maybe<Array<MessagingCampaignType>>;
  notIn?: Maybe<Array<MessagingCampaignType>>;
  not?: Maybe<NestedEnumMessagingCampaignTypeFilter>;
};

export type NestedEnumMessagingCampaignTypeWithAggregatesFilter = {
  equals?: Maybe<MessagingCampaignType>;
  in?: Maybe<Array<MessagingCampaignType>>;
  notIn?: Maybe<Array<MessagingCampaignType>>;
  not?: Maybe<NestedEnumMessagingCampaignTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMessagingCampaignTypeFilter>;
  _max?: Maybe<NestedEnumMessagingCampaignTypeFilter>;
};

export type NestedEnumMissedCallMessageResponseStatusFilter = {
  equals?: Maybe<MissedCallMessageResponseStatus>;
  in?: Maybe<Array<MissedCallMessageResponseStatus>>;
  notIn?: Maybe<Array<MissedCallMessageResponseStatus>>;
  not?: Maybe<NestedEnumMissedCallMessageResponseStatusFilter>;
};

export type NestedEnumMissedCallMessageResponseStatusWithAggregatesFilter = {
  equals?: Maybe<MissedCallMessageResponseStatus>;
  in?: Maybe<Array<MissedCallMessageResponseStatus>>;
  notIn?: Maybe<Array<MissedCallMessageResponseStatus>>;
  not?: Maybe<NestedEnumMissedCallMessageResponseStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMissedCallMessageResponseStatusFilter>;
  _max?: Maybe<NestedEnumMissedCallMessageResponseStatusFilter>;
};

export type NestedEnumNormalizedWritebackErrorNullableFilter = {
  equals?: Maybe<NormalizedWritebackError>;
  in?: Maybe<Array<NormalizedWritebackError>>;
  notIn?: Maybe<Array<NormalizedWritebackError>>;
  not?: Maybe<NestedEnumNormalizedWritebackErrorNullableFilter>;
};

export type NestedEnumNormalizedWritebackErrorNullableWithAggregatesFilter = {
  equals?: Maybe<NormalizedWritebackError>;
  in?: Maybe<Array<NormalizedWritebackError>>;
  notIn?: Maybe<Array<NormalizedWritebackError>>;
  not?: Maybe<NestedEnumNormalizedWritebackErrorNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumNormalizedWritebackErrorNullableFilter>;
  _max?: Maybe<NestedEnumNormalizedWritebackErrorNullableFilter>;
};

export type NestedEnumNotificationEventFilter = {
  equals?: Maybe<NotificationEvent>;
  in?: Maybe<Array<NotificationEvent>>;
  notIn?: Maybe<Array<NotificationEvent>>;
  not?: Maybe<NestedEnumNotificationEventFilter>;
};

export type NestedEnumNotificationEventWithAggregatesFilter = {
  equals?: Maybe<NotificationEvent>;
  in?: Maybe<Array<NotificationEvent>>;
  notIn?: Maybe<Array<NotificationEvent>>;
  not?: Maybe<NestedEnumNotificationEventWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumNotificationEventFilter>;
  _max?: Maybe<NestedEnumNotificationEventFilter>;
};

export type NestedEnumNotificationSettingTypeFilter = {
  equals?: Maybe<NotificationSettingType>;
  in?: Maybe<Array<NotificationSettingType>>;
  notIn?: Maybe<Array<NotificationSettingType>>;
  not?: Maybe<NestedEnumNotificationSettingTypeFilter>;
};

export type NestedEnumNotificationSettingTypeWithAggregatesFilter = {
  equals?: Maybe<NotificationSettingType>;
  in?: Maybe<Array<NotificationSettingType>>;
  notIn?: Maybe<Array<NotificationSettingType>>;
  not?: Maybe<NestedEnumNotificationSettingTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumNotificationSettingTypeFilter>;
  _max?: Maybe<NestedEnumNotificationSettingTypeFilter>;
};

export type NestedEnumPaymentMediumNullableFilter = {
  equals?: Maybe<PaymentMedium>;
  in?: Maybe<Array<PaymentMedium>>;
  notIn?: Maybe<Array<PaymentMedium>>;
  not?: Maybe<NestedEnumPaymentMediumNullableFilter>;
};

export type NestedEnumPaymentMediumNullableWithAggregatesFilter = {
  equals?: Maybe<PaymentMedium>;
  in?: Maybe<Array<PaymentMedium>>;
  notIn?: Maybe<Array<PaymentMedium>>;
  not?: Maybe<NestedEnumPaymentMediumNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPaymentMediumNullableFilter>;
  _max?: Maybe<NestedEnumPaymentMediumNullableFilter>;
};

export type NestedEnumPaymentPimsWritebackActionFilter = {
  equals?: Maybe<PaymentPimsWritebackAction>;
  in?: Maybe<Array<PaymentPimsWritebackAction>>;
  notIn?: Maybe<Array<PaymentPimsWritebackAction>>;
  not?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
};

export type NestedEnumPaymentPimsWritebackActionWithAggregatesFilter = {
  equals?: Maybe<PaymentPimsWritebackAction>;
  in?: Maybe<Array<PaymentPimsWritebackAction>>;
  notIn?: Maybe<Array<PaymentPimsWritebackAction>>;
  not?: Maybe<NestedEnumPaymentPimsWritebackActionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
  _max?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
};

export type NestedEnumPaymentRelationshipNullableFilter = {
  equals?: Maybe<PaymentRelationship>;
  in?: Maybe<Array<PaymentRelationship>>;
  notIn?: Maybe<Array<PaymentRelationship>>;
  not?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
};

export type NestedEnumPaymentRelationshipNullableWithAggregatesFilter = {
  equals?: Maybe<PaymentRelationship>;
  in?: Maybe<Array<PaymentRelationship>>;
  notIn?: Maybe<Array<PaymentRelationship>>;
  not?: Maybe<NestedEnumPaymentRelationshipNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
  _max?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
};

export type NestedEnumPaymentScheduleNullableFilter = {
  equals?: Maybe<PaymentSchedule>;
  in?: Maybe<Array<PaymentSchedule>>;
  notIn?: Maybe<Array<PaymentSchedule>>;
  not?: Maybe<NestedEnumPaymentScheduleNullableFilter>;
};

export type NestedEnumPaymentScheduleNullableWithAggregatesFilter = {
  equals?: Maybe<PaymentSchedule>;
  in?: Maybe<Array<PaymentSchedule>>;
  notIn?: Maybe<Array<PaymentSchedule>>;
  not?: Maybe<NestedEnumPaymentScheduleNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPaymentScheduleNullableFilter>;
  _max?: Maybe<NestedEnumPaymentScheduleNullableFilter>;
};

export type NestedEnumPaymentSourceFilter = {
  equals?: Maybe<PaymentSource>;
  in?: Maybe<Array<PaymentSource>>;
  notIn?: Maybe<Array<PaymentSource>>;
  not?: Maybe<NestedEnumPaymentSourceFilter>;
};

export type NestedEnumPaymentSourceWithAggregatesFilter = {
  equals?: Maybe<PaymentSource>;
  in?: Maybe<Array<PaymentSource>>;
  notIn?: Maybe<Array<PaymentSource>>;
  not?: Maybe<NestedEnumPaymentSourceWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPaymentSourceFilter>;
  _max?: Maybe<NestedEnumPaymentSourceFilter>;
};

export type NestedEnumPermissionTypeFilter = {
  equals?: Maybe<PermissionType>;
  in?: Maybe<Array<PermissionType>>;
  notIn?: Maybe<Array<PermissionType>>;
  not?: Maybe<NestedEnumPermissionTypeFilter>;
};

export type NestedEnumPermissionTypeWithAggregatesFilter = {
  equals?: Maybe<PermissionType>;
  in?: Maybe<Array<PermissionType>>;
  notIn?: Maybe<Array<PermissionType>>;
  not?: Maybe<NestedEnumPermissionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPermissionTypeFilter>;
  _max?: Maybe<NestedEnumPermissionTypeFilter>;
};

export type NestedEnumPhoneNumberLookupRequestStatusFilter = {
  equals?: Maybe<PhoneNumberLookupRequestStatus>;
  in?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
  notIn?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
  not?: Maybe<NestedEnumPhoneNumberLookupRequestStatusFilter>;
};

export type NestedEnumPhoneNumberLookupRequestStatusWithAggregatesFilter = {
  equals?: Maybe<PhoneNumberLookupRequestStatus>;
  in?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
  notIn?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
  not?: Maybe<NestedEnumPhoneNumberLookupRequestStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPhoneNumberLookupRequestStatusFilter>;
  _max?: Maybe<NestedEnumPhoneNumberLookupRequestStatusFilter>;
};

export type NestedEnumPhoneNumberStatusFilter = {
  equals?: Maybe<PhoneNumberStatus>;
  in?: Maybe<Array<PhoneNumberStatus>>;
  notIn?: Maybe<Array<PhoneNumberStatus>>;
  not?: Maybe<NestedEnumPhoneNumberStatusFilter>;
};

export type NestedEnumPhoneNumberStatusWithAggregatesFilter = {
  equals?: Maybe<PhoneNumberStatus>;
  in?: Maybe<Array<PhoneNumberStatus>>;
  notIn?: Maybe<Array<PhoneNumberStatus>>;
  not?: Maybe<NestedEnumPhoneNumberStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPhoneNumberStatusFilter>;
  _max?: Maybe<NestedEnumPhoneNumberStatusFilter>;
};

export type NestedEnumPimsIntegrationCapabilityFilter = {
  equals?: Maybe<PimsIntegrationCapability>;
  in?: Maybe<Array<PimsIntegrationCapability>>;
  notIn?: Maybe<Array<PimsIntegrationCapability>>;
  not?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
};

export type NestedEnumPimsIntegrationCapabilityWithAggregatesFilter = {
  equals?: Maybe<PimsIntegrationCapability>;
  in?: Maybe<Array<PimsIntegrationCapability>>;
  notIn?: Maybe<Array<PimsIntegrationCapability>>;
  not?: Maybe<NestedEnumPimsIntegrationCapabilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
  _max?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
};

export type NestedEnumPimsInvoiceLineItemTypeFilter = {
  equals?: Maybe<PimsInvoiceLineItemType>;
  in?: Maybe<Array<PimsInvoiceLineItemType>>;
  notIn?: Maybe<Array<PimsInvoiceLineItemType>>;
  not?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
};

export type NestedEnumPimsInvoiceLineItemTypeWithAggregatesFilter = {
  equals?: Maybe<PimsInvoiceLineItemType>;
  in?: Maybe<Array<PimsInvoiceLineItemType>>;
  notIn?: Maybe<Array<PimsInvoiceLineItemType>>;
  not?: Maybe<NestedEnumPimsInvoiceLineItemTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
  _max?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
};

export type NestedEnumPimsSyncFrequencyFilter = {
  equals?: Maybe<PimsSyncFrequency>;
  in?: Maybe<Array<PimsSyncFrequency>>;
  notIn?: Maybe<Array<PimsSyncFrequency>>;
  not?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
};

export type NestedEnumPimsSyncFrequencyWithAggregatesFilter = {
  equals?: Maybe<PimsSyncFrequency>;
  in?: Maybe<Array<PimsSyncFrequency>>;
  notIn?: Maybe<Array<PimsSyncFrequency>>;
  not?: Maybe<NestedEnumPimsSyncFrequencyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
  _max?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
};

export type NestedEnumPimsSyncStatusNullableFilter = {
  equals?: Maybe<PimsSyncStatus>;
  in?: Maybe<Array<PimsSyncStatus>>;
  notIn?: Maybe<Array<PimsSyncStatus>>;
  not?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
};

export type NestedEnumPimsSyncStatusNullableWithAggregatesFilter = {
  equals?: Maybe<PimsSyncStatus>;
  in?: Maybe<Array<PimsSyncStatus>>;
  notIn?: Maybe<Array<PimsSyncStatus>>;
  not?: Maybe<NestedEnumPimsSyncStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
  _max?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
};

export type NestedEnumPimsWritebackStatusFilter = {
  equals?: Maybe<PimsWritebackStatus>;
  in?: Maybe<Array<PimsWritebackStatus>>;
  notIn?: Maybe<Array<PimsWritebackStatus>>;
  not?: Maybe<NestedEnumPimsWritebackStatusFilter>;
};

export type NestedEnumPimsWritebackStatusWithAggregatesFilter = {
  equals?: Maybe<PimsWritebackStatus>;
  in?: Maybe<Array<PimsWritebackStatus>>;
  notIn?: Maybe<Array<PimsWritebackStatus>>;
  not?: Maybe<NestedEnumPimsWritebackStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsWritebackStatusFilter>;
  _max?: Maybe<NestedEnumPimsWritebackStatusFilter>;
};

export type NestedEnumPimsWritebackTypeFilter = {
  equals?: Maybe<PimsWritebackType>;
  in?: Maybe<Array<PimsWritebackType>>;
  notIn?: Maybe<Array<PimsWritebackType>>;
  not?: Maybe<NestedEnumPimsWritebackTypeFilter>;
};

export type NestedEnumPimsWritebackTypeWithAggregatesFilter = {
  equals?: Maybe<PimsWritebackType>;
  in?: Maybe<Array<PimsWritebackType>>;
  notIn?: Maybe<Array<PimsWritebackType>>;
  not?: Maybe<NestedEnumPimsWritebackTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsWritebackTypeFilter>;
  _max?: Maybe<NestedEnumPimsWritebackTypeFilter>;
};

export type NestedEnumPricingModelFilter = {
  equals?: Maybe<PricingModel>;
  in?: Maybe<Array<PricingModel>>;
  notIn?: Maybe<Array<PricingModel>>;
  not?: Maybe<NestedEnumPricingModelFilter>;
};

export type NestedEnumPricingModelWithAggregatesFilter = {
  equals?: Maybe<PricingModel>;
  in?: Maybe<Array<PricingModel>>;
  notIn?: Maybe<Array<PricingModel>>;
  not?: Maybe<NestedEnumPricingModelWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPricingModelFilter>;
  _max?: Maybe<NestedEnumPricingModelFilter>;
};

export type NestedEnumProductSupplyDurationUnitNullableFilter = {
  equals?: Maybe<ProductSupplyDurationUnit>;
  in?: Maybe<Array<ProductSupplyDurationUnit>>;
  notIn?: Maybe<Array<ProductSupplyDurationUnit>>;
  not?: Maybe<NestedEnumProductSupplyDurationUnitNullableFilter>;
};

export type NestedEnumProductSupplyDurationUnitNullableWithAggregatesFilter = {
  equals?: Maybe<ProductSupplyDurationUnit>;
  in?: Maybe<Array<ProductSupplyDurationUnit>>;
  notIn?: Maybe<Array<ProductSupplyDurationUnit>>;
  not?: Maybe<NestedEnumProductSupplyDurationUnitNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumProductSupplyDurationUnitNullableFilter>;
  _max?: Maybe<NestedEnumProductSupplyDurationUnitNullableFilter>;
};

export type NestedEnumProductTypeFilter = {
  equals?: Maybe<ProductType>;
  in?: Maybe<Array<ProductType>>;
  notIn?: Maybe<Array<ProductType>>;
  not?: Maybe<NestedEnumProductTypeFilter>;
};

export type NestedEnumProductTypeWithAggregatesFilter = {
  equals?: Maybe<ProductType>;
  in?: Maybe<Array<ProductType>>;
  notIn?: Maybe<Array<ProductType>>;
  not?: Maybe<NestedEnumProductTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumProductTypeFilter>;
  _max?: Maybe<NestedEnumProductTypeFilter>;
};

export type NestedEnumProfilePimsSyncStatusFilter = {
  equals?: Maybe<ProfilePimsSyncStatus>;
  in?: Maybe<Array<ProfilePimsSyncStatus>>;
  notIn?: Maybe<Array<ProfilePimsSyncStatus>>;
  not?: Maybe<NestedEnumProfilePimsSyncStatusFilter>;
};

export type NestedEnumProfilePimsSyncStatusWithAggregatesFilter = {
  equals?: Maybe<ProfilePimsSyncStatus>;
  in?: Maybe<Array<ProfilePimsSyncStatus>>;
  notIn?: Maybe<Array<ProfilePimsSyncStatus>>;
  not?: Maybe<NestedEnumProfilePimsSyncStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumProfilePimsSyncStatusFilter>;
  _max?: Maybe<NestedEnumProfilePimsSyncStatusFilter>;
};

export type NestedEnumPushNotificationDeliveryErrorTypeNullableFilter = {
  equals?: Maybe<PushNotificationDeliveryErrorType>;
  in?: Maybe<Array<PushNotificationDeliveryErrorType>>;
  notIn?: Maybe<Array<PushNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableFilter>;
};

export type NestedEnumPushNotificationDeliveryErrorTypeNullableWithAggregatesFilter = {
  equals?: Maybe<PushNotificationDeliveryErrorType>;
  in?: Maybe<Array<PushNotificationDeliveryErrorType>>;
  notIn?: Maybe<Array<PushNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableFilter>;
  _max?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableFilter>;
};

export type NestedEnumPushNotificationLogStatusTypeFilter = {
  equals?: Maybe<PushNotificationLogStatusType>;
  in?: Maybe<Array<PushNotificationLogStatusType>>;
  notIn?: Maybe<Array<PushNotificationLogStatusType>>;
  not?: Maybe<NestedEnumPushNotificationLogStatusTypeFilter>;
};

export type NestedEnumPushNotificationLogStatusTypeWithAggregatesFilter = {
  equals?: Maybe<PushNotificationLogStatusType>;
  in?: Maybe<Array<PushNotificationLogStatusType>>;
  notIn?: Maybe<Array<PushNotificationLogStatusType>>;
  not?: Maybe<NestedEnumPushNotificationLogStatusTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPushNotificationLogStatusTypeFilter>;
  _max?: Maybe<NestedEnumPushNotificationLogStatusTypeFilter>;
};

export type NestedEnumPushNotificationStatusFilter = {
  equals?: Maybe<PushNotificationStatus>;
  in?: Maybe<Array<PushNotificationStatus>>;
  notIn?: Maybe<Array<PushNotificationStatus>>;
  not?: Maybe<NestedEnumPushNotificationStatusFilter>;
};

export type NestedEnumPushNotificationStatusWithAggregatesFilter = {
  equals?: Maybe<PushNotificationStatus>;
  in?: Maybe<Array<PushNotificationStatus>>;
  notIn?: Maybe<Array<PushNotificationStatus>>;
  not?: Maybe<NestedEnumPushNotificationStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPushNotificationStatusFilter>;
  _max?: Maybe<NestedEnumPushNotificationStatusFilter>;
};

export type NestedEnumRecordingDownloadStatusFilter = {
  equals?: Maybe<RecordingDownloadStatus>;
  in?: Maybe<Array<RecordingDownloadStatus>>;
  notIn?: Maybe<Array<RecordingDownloadStatus>>;
  not?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
};

export type NestedEnumRecordingDownloadStatusWithAggregatesFilter = {
  equals?: Maybe<RecordingDownloadStatus>;
  in?: Maybe<Array<RecordingDownloadStatus>>;
  notIn?: Maybe<Array<RecordingDownloadStatus>>;
  not?: Maybe<NestedEnumRecordingDownloadStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
  _max?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
};

export type NestedEnumRefundReasonFilter = {
  equals?: Maybe<RefundReason>;
  in?: Maybe<Array<RefundReason>>;
  notIn?: Maybe<Array<RefundReason>>;
  not?: Maybe<NestedEnumRefundReasonFilter>;
};

export type NestedEnumRefundReasonWithAggregatesFilter = {
  equals?: Maybe<RefundReason>;
  in?: Maybe<Array<RefundReason>>;
  notIn?: Maybe<Array<RefundReason>>;
  not?: Maybe<NestedEnumRefundReasonWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRefundReasonFilter>;
  _max?: Maybe<NestedEnumRefundReasonFilter>;
};

export type NestedEnumRenewalRejectionReasonNullableFilter = {
  equals?: Maybe<RenewalRejectionReason>;
  in?: Maybe<Array<RenewalRejectionReason>>;
  notIn?: Maybe<Array<RenewalRejectionReason>>;
  not?: Maybe<NestedEnumRenewalRejectionReasonNullableFilter>;
};

export type NestedEnumRenewalRejectionReasonNullableWithAggregatesFilter = {
  equals?: Maybe<RenewalRejectionReason>;
  in?: Maybe<Array<RenewalRejectionReason>>;
  notIn?: Maybe<Array<RenewalRejectionReason>>;
  not?: Maybe<NestedEnumRenewalRejectionReasonNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumRenewalRejectionReasonNullableFilter>;
  _max?: Maybe<NestedEnumRenewalRejectionReasonNullableFilter>;
};

export type NestedEnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  notIn?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleFilter>;
};

export type NestedEnumRoleWithAggregatesFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  notIn?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRoleFilter>;
  _max?: Maybe<NestedEnumRoleFilter>;
};

export type NestedEnumRuleActionsFilter = {
  equals?: Maybe<RuleActions>;
  in?: Maybe<Array<RuleActions>>;
  notIn?: Maybe<Array<RuleActions>>;
  not?: Maybe<NestedEnumRuleActionsFilter>;
};

export type NestedEnumRuleActionsWithAggregatesFilter = {
  equals?: Maybe<RuleActions>;
  in?: Maybe<Array<RuleActions>>;
  notIn?: Maybe<Array<RuleActions>>;
  not?: Maybe<NestedEnumRuleActionsWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRuleActionsFilter>;
  _max?: Maybe<NestedEnumRuleActionsFilter>;
};

export type NestedEnumSegmentModelTypeFilter = {
  equals?: Maybe<SegmentModelType>;
  in?: Maybe<Array<SegmentModelType>>;
  notIn?: Maybe<Array<SegmentModelType>>;
  not?: Maybe<NestedEnumSegmentModelTypeFilter>;
};

export type NestedEnumSegmentModelTypeWithAggregatesFilter = {
  equals?: Maybe<SegmentModelType>;
  in?: Maybe<Array<SegmentModelType>>;
  notIn?: Maybe<Array<SegmentModelType>>;
  not?: Maybe<NestedEnumSegmentModelTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSegmentModelTypeFilter>;
  _max?: Maybe<NestedEnumSegmentModelTypeFilter>;
};

export type NestedEnumSegmentTypeFilter = {
  equals?: Maybe<SegmentType>;
  in?: Maybe<Array<SegmentType>>;
  notIn?: Maybe<Array<SegmentType>>;
  not?: Maybe<NestedEnumSegmentTypeFilter>;
};

export type NestedEnumSegmentTypeWithAggregatesFilter = {
  equals?: Maybe<SegmentType>;
  in?: Maybe<Array<SegmentType>>;
  notIn?: Maybe<Array<SegmentType>>;
  not?: Maybe<NestedEnumSegmentTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSegmentTypeFilter>;
  _max?: Maybe<NestedEnumSegmentTypeFilter>;
};

export type NestedEnumServiceCapabilityFilter = {
  equals?: Maybe<ServiceCapability>;
  in?: Maybe<Array<ServiceCapability>>;
  notIn?: Maybe<Array<ServiceCapability>>;
  not?: Maybe<NestedEnumServiceCapabilityFilter>;
};

export type NestedEnumServiceCapabilityWithAggregatesFilter = {
  equals?: Maybe<ServiceCapability>;
  in?: Maybe<Array<ServiceCapability>>;
  notIn?: Maybe<Array<ServiceCapability>>;
  not?: Maybe<NestedEnumServiceCapabilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceCapabilityFilter>;
  _max?: Maybe<NestedEnumServiceCapabilityFilter>;
};

export type NestedEnumServiceProviderFilter = {
  equals?: Maybe<ServiceProvider>;
  in?: Maybe<Array<ServiceProvider>>;
  notIn?: Maybe<Array<ServiceProvider>>;
  not?: Maybe<NestedEnumServiceProviderFilter>;
};

export type NestedEnumServiceProviderWithAggregatesFilter = {
  equals?: Maybe<ServiceProvider>;
  in?: Maybe<Array<ServiceProvider>>;
  notIn?: Maybe<Array<ServiceProvider>>;
  not?: Maybe<NestedEnumServiceProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceProviderFilter>;
  _max?: Maybe<NestedEnumServiceProviderFilter>;
};

export type NestedEnumServiceReminderNotificationTypeFilter = {
  equals?: Maybe<ServiceReminderNotificationType>;
  in?: Maybe<Array<ServiceReminderNotificationType>>;
  notIn?: Maybe<Array<ServiceReminderNotificationType>>;
  not?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
};

export type NestedEnumServiceReminderNotificationTypeWithAggregatesFilter = {
  equals?: Maybe<ServiceReminderNotificationType>;
  in?: Maybe<Array<ServiceReminderNotificationType>>;
  notIn?: Maybe<Array<ServiceReminderNotificationType>>;
  not?: Maybe<NestedEnumServiceReminderNotificationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
  _max?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
};

export type NestedEnumServiceReminderTimingOffsetUnitFilter = {
  equals?: Maybe<ServiceReminderTimingOffsetUnit>;
  in?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  notIn?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  not?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
};

export type NestedEnumServiceReminderTimingOffsetUnitWithAggregatesFilter = {
  equals?: Maybe<ServiceReminderTimingOffsetUnit>;
  in?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  notIn?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  not?: Maybe<NestedEnumServiceReminderTimingOffsetUnitWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
  _max?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
};

export type NestedEnumServiceReminderTimingTypeFilter = {
  equals?: Maybe<ServiceReminderTimingType>;
  in?: Maybe<Array<ServiceReminderTimingType>>;
  notIn?: Maybe<Array<ServiceReminderTimingType>>;
  not?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
};

export type NestedEnumServiceReminderTimingTypeWithAggregatesFilter = {
  equals?: Maybe<ServiceReminderTimingType>;
  in?: Maybe<Array<ServiceReminderTimingType>>;
  notIn?: Maybe<Array<ServiceReminderTimingType>>;
  not?: Maybe<NestedEnumServiceReminderTimingTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
  _max?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
};

export type NestedEnumSmsEntityTypeNullableFilter = {
  equals?: Maybe<SmsEntityType>;
  in?: Maybe<Array<SmsEntityType>>;
  notIn?: Maybe<Array<SmsEntityType>>;
  not?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
};

export type NestedEnumSmsEntityTypeNullableWithAggregatesFilter = {
  equals?: Maybe<SmsEntityType>;
  in?: Maybe<Array<SmsEntityType>>;
  notIn?: Maybe<Array<SmsEntityType>>;
  not?: Maybe<NestedEnumSmsEntityTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
  _max?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
};

export type NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter = {
  equals?: Maybe<SmsNotificationDeliveryErrorType>;
  in?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  notIn?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
};

export type NestedEnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter = {
  equals?: Maybe<SmsNotificationDeliveryErrorType>;
  in?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  notIn?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  _max?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
};

export type NestedEnumSmsNotificationProviderFilter = {
  equals?: Maybe<SmsNotificationProvider>;
  in?: Maybe<Array<SmsNotificationProvider>>;
  notIn?: Maybe<Array<SmsNotificationProvider>>;
  not?: Maybe<NestedEnumSmsNotificationProviderFilter>;
};

export type NestedEnumSmsNotificationProviderWithAggregatesFilter = {
  equals?: Maybe<SmsNotificationProvider>;
  in?: Maybe<Array<SmsNotificationProvider>>;
  notIn?: Maybe<Array<SmsNotificationProvider>>;
  not?: Maybe<NestedEnumSmsNotificationProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSmsNotificationProviderFilter>;
  _max?: Maybe<NestedEnumSmsNotificationProviderFilter>;
};

export type NestedEnumSmsNotificationStatusTypeFilter = {
  equals?: Maybe<SmsNotificationStatusType>;
  in?: Maybe<Array<SmsNotificationStatusType>>;
  notIn?: Maybe<Array<SmsNotificationStatusType>>;
  not?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
};

export type NestedEnumSmsNotificationStatusTypeWithAggregatesFilter = {
  equals?: Maybe<SmsNotificationStatusType>;
  in?: Maybe<Array<SmsNotificationStatusType>>;
  notIn?: Maybe<Array<SmsNotificationStatusType>>;
  not?: Maybe<NestedEnumSmsNotificationStatusTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
  _max?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
};

export type NestedEnumSpeciesTypeFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeFilter>;
};

export type NestedEnumSpeciesTypeNullableFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
};

export type NestedEnumSpeciesTypeNullableWithAggregatesFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
  _max?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
};

export type NestedEnumSpeciesTypeWithAggregatesFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSpeciesTypeFilter>;
  _max?: Maybe<NestedEnumSpeciesTypeFilter>;
};

export type NestedEnumStateFilter = {
  equals?: Maybe<State>;
  in?: Maybe<Array<State>>;
  notIn?: Maybe<Array<State>>;
  not?: Maybe<NestedEnumStateFilter>;
};

export type NestedEnumStateWithAggregatesFilter = {
  equals?: Maybe<State>;
  in?: Maybe<Array<State>>;
  notIn?: Maybe<Array<State>>;
  not?: Maybe<NestedEnumStateWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStateFilter>;
  _max?: Maybe<NestedEnumStateFilter>;
};

export type NestedEnumStripeCollectionMethodFilter = {
  equals?: Maybe<StripeCollectionMethod>;
  in?: Maybe<Array<StripeCollectionMethod>>;
  notIn?: Maybe<Array<StripeCollectionMethod>>;
  not?: Maybe<NestedEnumStripeCollectionMethodFilter>;
};

export type NestedEnumStripeCollectionMethodWithAggregatesFilter = {
  equals?: Maybe<StripeCollectionMethod>;
  in?: Maybe<Array<StripeCollectionMethod>>;
  notIn?: Maybe<Array<StripeCollectionMethod>>;
  not?: Maybe<NestedEnumStripeCollectionMethodWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeCollectionMethodFilter>;
  _max?: Maybe<NestedEnumStripeCollectionMethodFilter>;
};

export type NestedEnumStripeDisputeStatusFilter = {
  equals?: Maybe<StripeDisputeStatus>;
  in?: Maybe<Array<StripeDisputeStatus>>;
  notIn?: Maybe<Array<StripeDisputeStatus>>;
  not?: Maybe<NestedEnumStripeDisputeStatusFilter>;
};

export type NestedEnumStripeDisputeStatusWithAggregatesFilter = {
  equals?: Maybe<StripeDisputeStatus>;
  in?: Maybe<Array<StripeDisputeStatus>>;
  notIn?: Maybe<Array<StripeDisputeStatus>>;
  not?: Maybe<NestedEnumStripeDisputeStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeDisputeStatusFilter>;
  _max?: Maybe<NestedEnumStripeDisputeStatusFilter>;
};

export type NestedEnumStripeInvoiceStatusFilter = {
  equals?: Maybe<StripeInvoiceStatus>;
  in?: Maybe<Array<StripeInvoiceStatus>>;
  notIn?: Maybe<Array<StripeInvoiceStatus>>;
  not?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
};

export type NestedEnumStripeInvoiceStatusWithAggregatesFilter = {
  equals?: Maybe<StripeInvoiceStatus>;
  in?: Maybe<Array<StripeInvoiceStatus>>;
  notIn?: Maybe<Array<StripeInvoiceStatus>>;
  not?: Maybe<NestedEnumStripeInvoiceStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
  _max?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
};

export type NestedEnumStripePaymentErrorNullableFilter = {
  equals?: Maybe<StripePaymentError>;
  in?: Maybe<Array<StripePaymentError>>;
  notIn?: Maybe<Array<StripePaymentError>>;
  not?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
};

export type NestedEnumStripePaymentErrorNullableWithAggregatesFilter = {
  equals?: Maybe<StripePaymentError>;
  in?: Maybe<Array<StripePaymentError>>;
  notIn?: Maybe<Array<StripePaymentError>>;
  not?: Maybe<NestedEnumStripePaymentErrorNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
  _max?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
};

export type NestedEnumStripePaymentIntentStatusFilter = {
  equals?: Maybe<StripePaymentIntentStatus>;
  in?: Maybe<Array<StripePaymentIntentStatus>>;
  notIn?: Maybe<Array<StripePaymentIntentStatus>>;
  not?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
};

export type NestedEnumStripePaymentIntentStatusWithAggregatesFilter = {
  equals?: Maybe<StripePaymentIntentStatus>;
  in?: Maybe<Array<StripePaymentIntentStatus>>;
  notIn?: Maybe<Array<StripePaymentIntentStatus>>;
  not?: Maybe<NestedEnumStripePaymentIntentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
  _max?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
};

export type NestedEnumStripePaymentMethodTypeFilter = {
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
};

export type NestedEnumStripePaymentMethodTypeWithAggregatesFilter = {
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
  _max?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
};

export type NestedEnumStripePaymentTransferTypeFilter = {
  equals?: Maybe<StripePaymentTransferType>;
  in?: Maybe<Array<StripePaymentTransferType>>;
  notIn?: Maybe<Array<StripePaymentTransferType>>;
  not?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
};

export type NestedEnumStripePaymentTransferTypeWithAggregatesFilter = {
  equals?: Maybe<StripePaymentTransferType>;
  in?: Maybe<Array<StripePaymentTransferType>>;
  notIn?: Maybe<Array<StripePaymentTransferType>>;
  not?: Maybe<NestedEnumStripePaymentTransferTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
  _max?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
};

export type NestedEnumStripePayoutStatusFilter = {
  equals?: Maybe<StripePayoutStatus>;
  in?: Maybe<Array<StripePayoutStatus>>;
  notIn?: Maybe<Array<StripePayoutStatus>>;
  not?: Maybe<NestedEnumStripePayoutStatusFilter>;
};

export type NestedEnumStripePayoutStatusWithAggregatesFilter = {
  equals?: Maybe<StripePayoutStatus>;
  in?: Maybe<Array<StripePayoutStatus>>;
  notIn?: Maybe<Array<StripePayoutStatus>>;
  not?: Maybe<NestedEnumStripePayoutStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePayoutStatusFilter>;
  _max?: Maybe<NestedEnumStripePayoutStatusFilter>;
};

export type NestedEnumStripeReceiptMediumFilter = {
  equals?: Maybe<StripeReceiptMedium>;
  in?: Maybe<Array<StripeReceiptMedium>>;
  notIn?: Maybe<Array<StripeReceiptMedium>>;
  not?: Maybe<NestedEnumStripeReceiptMediumFilter>;
};

export type NestedEnumStripeReceiptMediumWithAggregatesFilter = {
  equals?: Maybe<StripeReceiptMedium>;
  in?: Maybe<Array<StripeReceiptMedium>>;
  notIn?: Maybe<Array<StripeReceiptMedium>>;
  not?: Maybe<NestedEnumStripeReceiptMediumWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeReceiptMediumFilter>;
  _max?: Maybe<NestedEnumStripeReceiptMediumFilter>;
};

export type NestedEnumStripeTerminalLocationTypeFilter = {
  equals?: Maybe<StripeTerminalLocationType>;
  in?: Maybe<Array<StripeTerminalLocationType>>;
  notIn?: Maybe<Array<StripeTerminalLocationType>>;
  not?: Maybe<NestedEnumStripeTerminalLocationTypeFilter>;
};

export type NestedEnumStripeTerminalLocationTypeWithAggregatesFilter = {
  equals?: Maybe<StripeTerminalLocationType>;
  in?: Maybe<Array<StripeTerminalLocationType>>;
  notIn?: Maybe<Array<StripeTerminalLocationType>>;
  not?: Maybe<NestedEnumStripeTerminalLocationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeTerminalLocationTypeFilter>;
  _max?: Maybe<NestedEnumStripeTerminalLocationTypeFilter>;
};

export type NestedEnumStripeTerminalModelFilter = {
  equals?: Maybe<StripeTerminalModel>;
  in?: Maybe<Array<StripeTerminalModel>>;
  notIn?: Maybe<Array<StripeTerminalModel>>;
  not?: Maybe<NestedEnumStripeTerminalModelFilter>;
};

export type NestedEnumStripeTerminalModelWithAggregatesFilter = {
  equals?: Maybe<StripeTerminalModel>;
  in?: Maybe<Array<StripeTerminalModel>>;
  notIn?: Maybe<Array<StripeTerminalModel>>;
  not?: Maybe<NestedEnumStripeTerminalModelWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeTerminalModelFilter>;
  _max?: Maybe<NestedEnumStripeTerminalModelFilter>;
};

export type NestedEnumStripeTerminalStatusFilter = {
  equals?: Maybe<StripeTerminalStatus>;
  in?: Maybe<Array<StripeTerminalStatus>>;
  notIn?: Maybe<Array<StripeTerminalStatus>>;
  not?: Maybe<NestedEnumStripeTerminalStatusFilter>;
};

export type NestedEnumStripeTerminalStatusWithAggregatesFilter = {
  equals?: Maybe<StripeTerminalStatus>;
  in?: Maybe<Array<StripeTerminalStatus>>;
  notIn?: Maybe<Array<StripeTerminalStatus>>;
  not?: Maybe<NestedEnumStripeTerminalStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeTerminalStatusFilter>;
  _max?: Maybe<NestedEnumStripeTerminalStatusFilter>;
};

export type NestedEnumSupportEventStatusFilter = {
  equals?: Maybe<SupportEventStatus>;
  in?: Maybe<Array<SupportEventStatus>>;
  notIn?: Maybe<Array<SupportEventStatus>>;
  not?: Maybe<NestedEnumSupportEventStatusFilter>;
};

export type NestedEnumSupportEventStatusWithAggregatesFilter = {
  equals?: Maybe<SupportEventStatus>;
  in?: Maybe<Array<SupportEventStatus>>;
  notIn?: Maybe<Array<SupportEventStatus>>;
  not?: Maybe<NestedEnumSupportEventStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSupportEventStatusFilter>;
  _max?: Maybe<NestedEnumSupportEventStatusFilter>;
};

export type NestedEnumSurveyStepNullableFilter = {
  equals?: Maybe<SurveyStep>;
  in?: Maybe<Array<SurveyStep>>;
  notIn?: Maybe<Array<SurveyStep>>;
  not?: Maybe<NestedEnumSurveyStepNullableFilter>;
};

export type NestedEnumSurveyStepNullableWithAggregatesFilter = {
  equals?: Maybe<SurveyStep>;
  in?: Maybe<Array<SurveyStep>>;
  notIn?: Maybe<Array<SurveyStep>>;
  not?: Maybe<NestedEnumSurveyStepNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSurveyStepNullableFilter>;
  _max?: Maybe<NestedEnumSurveyStepNullableFilter>;
};

export type NestedEnumSurveyTypeFilter = {
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  notIn?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeFilter>;
};

export type NestedEnumSurveyTypeNullableFilter = {
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  notIn?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeNullableFilter>;
};

export type NestedEnumSurveyTypeNullableWithAggregatesFilter = {
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  notIn?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSurveyTypeNullableFilter>;
  _max?: Maybe<NestedEnumSurveyTypeNullableFilter>;
};

export type NestedEnumSurveyTypeWithAggregatesFilter = {
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  notIn?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSurveyTypeFilter>;
  _max?: Maybe<NestedEnumSurveyTypeFilter>;
};

export type NestedEnumTemplateAvailabilityFilter = {
  equals?: Maybe<TemplateAvailability>;
  in?: Maybe<Array<TemplateAvailability>>;
  notIn?: Maybe<Array<TemplateAvailability>>;
  not?: Maybe<NestedEnumTemplateAvailabilityFilter>;
};

export type NestedEnumTemplateAvailabilityWithAggregatesFilter = {
  equals?: Maybe<TemplateAvailability>;
  in?: Maybe<Array<TemplateAvailability>>;
  notIn?: Maybe<Array<TemplateAvailability>>;
  not?: Maybe<NestedEnumTemplateAvailabilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumTemplateAvailabilityFilter>;
  _max?: Maybe<NestedEnumTemplateAvailabilityFilter>;
};

export type NestedEnumTwilioRecordingStatusFilter = {
  equals?: Maybe<TwilioRecordingStatus>;
  in?: Maybe<Array<TwilioRecordingStatus>>;
  notIn?: Maybe<Array<TwilioRecordingStatus>>;
  not?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
};

export type NestedEnumTwilioRecordingStatusWithAggregatesFilter = {
  equals?: Maybe<TwilioRecordingStatus>;
  in?: Maybe<Array<TwilioRecordingStatus>>;
  notIn?: Maybe<Array<TwilioRecordingStatus>>;
  not?: Maybe<NestedEnumTwilioRecordingStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
  _max?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
};

export type NestedEnumTwilioVerificationStatusFilter = {
  equals?: Maybe<TwilioVerificationStatus>;
  in?: Maybe<Array<TwilioVerificationStatus>>;
  notIn?: Maybe<Array<TwilioVerificationStatus>>;
  not?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
};

export type NestedEnumTwilioVerificationStatusWithAggregatesFilter = {
  equals?: Maybe<TwilioVerificationStatus>;
  in?: Maybe<Array<TwilioVerificationStatus>>;
  notIn?: Maybe<Array<TwilioVerificationStatus>>;
  not?: Maybe<NestedEnumTwilioVerificationStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
  _max?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
};

export type NestedEnumUserTypeFilter = {
  equals?: Maybe<UserType>;
  in?: Maybe<Array<UserType>>;
  notIn?: Maybe<Array<UserType>>;
  not?: Maybe<NestedEnumUserTypeFilter>;
};

export type NestedEnumUserTypeWithAggregatesFilter = {
  equals?: Maybe<UserType>;
  in?: Maybe<Array<UserType>>;
  notIn?: Maybe<Array<UserType>>;
  not?: Maybe<NestedEnumUserTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumUserTypeFilter>;
  _max?: Maybe<NestedEnumUserTypeFilter>;
};

export type NestedEnumVetDataIntegrationSystemFilter = {
  equals?: Maybe<VetDataIntegrationSystem>;
  in?: Maybe<Array<VetDataIntegrationSystem>>;
  notIn?: Maybe<Array<VetDataIntegrationSystem>>;
  not?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
};

export type NestedEnumVetDataIntegrationSystemWithAggregatesFilter = {
  equals?: Maybe<VetDataIntegrationSystem>;
  in?: Maybe<Array<VetDataIntegrationSystem>>;
  notIn?: Maybe<Array<VetDataIntegrationSystem>>;
  not?: Maybe<NestedEnumVetDataIntegrationSystemWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
  _max?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
};

export type NestedEnumVetLicenseTypeFilter = {
  equals?: Maybe<VetLicenseType>;
  in?: Maybe<Array<VetLicenseType>>;
  notIn?: Maybe<Array<VetLicenseType>>;
  not?: Maybe<NestedEnumVetLicenseTypeFilter>;
};

export type NestedEnumVetLicenseTypeWithAggregatesFilter = {
  equals?: Maybe<VetLicenseType>;
  in?: Maybe<Array<VetLicenseType>>;
  notIn?: Maybe<Array<VetLicenseType>>;
  not?: Maybe<NestedEnumVetLicenseTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVetLicenseTypeFilter>;
  _max?: Maybe<NestedEnumVetLicenseTypeFilter>;
};

export type NestedEnumVetsourceCodeTagTypeFilter = {
  equals?: Maybe<VetsourceCodeTagType>;
  in?: Maybe<Array<VetsourceCodeTagType>>;
  notIn?: Maybe<Array<VetsourceCodeTagType>>;
  not?: Maybe<NestedEnumVetsourceCodeTagTypeFilter>;
};

export type NestedEnumVetsourceCodeTagTypeWithAggregatesFilter = {
  equals?: Maybe<VetsourceCodeTagType>;
  in?: Maybe<Array<VetsourceCodeTagType>>;
  notIn?: Maybe<Array<VetsourceCodeTagType>>;
  not?: Maybe<NestedEnumVetsourceCodeTagTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVetsourceCodeTagTypeFilter>;
  _max?: Maybe<NestedEnumVetsourceCodeTagTypeFilter>;
};

export type NestedEnumVetsourceInstallationConnectivityStateFilter = {
  equals?: Maybe<VetsourceInstallationConnectivityState>;
  in?: Maybe<Array<VetsourceInstallationConnectivityState>>;
  notIn?: Maybe<Array<VetsourceInstallationConnectivityState>>;
  not?: Maybe<NestedEnumVetsourceInstallationConnectivityStateFilter>;
};

export type NestedEnumVetsourceInstallationConnectivityStateWithAggregatesFilter = {
  equals?: Maybe<VetsourceInstallationConnectivityState>;
  in?: Maybe<Array<VetsourceInstallationConnectivityState>>;
  notIn?: Maybe<Array<VetsourceInstallationConnectivityState>>;
  not?: Maybe<NestedEnumVetsourceInstallationConnectivityStateWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVetsourceInstallationConnectivityStateFilter>;
  _max?: Maybe<NestedEnumVetsourceInstallationConnectivityStateFilter>;
};

export type NestedEnumWidgetRequestTypeFilter = {
  equals?: Maybe<WidgetRequestType>;
  in?: Maybe<Array<WidgetRequestType>>;
  notIn?: Maybe<Array<WidgetRequestType>>;
  not?: Maybe<NestedEnumWidgetRequestTypeFilter>;
};

export type NestedEnumWidgetRequestTypeWithAggregatesFilter = {
  equals?: Maybe<WidgetRequestType>;
  in?: Maybe<Array<WidgetRequestType>>;
  notIn?: Maybe<Array<WidgetRequestType>>;
  not?: Maybe<NestedEnumWidgetRequestTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWidgetRequestTypeFilter>;
  _max?: Maybe<NestedEnumWidgetRequestTypeFilter>;
};

export type NestedEnumWorkflowActionTypeFilter = {
  equals?: Maybe<WorkflowActionType>;
  in?: Maybe<Array<WorkflowActionType>>;
  notIn?: Maybe<Array<WorkflowActionType>>;
  not?: Maybe<NestedEnumWorkflowActionTypeFilter>;
};

export type NestedEnumWorkflowActionTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowActionType>;
  in?: Maybe<Array<WorkflowActionType>>;
  notIn?: Maybe<Array<WorkflowActionType>>;
  not?: Maybe<NestedEnumWorkflowActionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowActionTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowActionTypeFilter>;
};

export type NestedEnumWorkflowEventFilter = {
  equals?: Maybe<WorkflowEvent>;
  in?: Maybe<Array<WorkflowEvent>>;
  notIn?: Maybe<Array<WorkflowEvent>>;
  not?: Maybe<NestedEnumWorkflowEventFilter>;
};

export type NestedEnumWorkflowEventTriggerTypeFilter = {
  equals?: Maybe<WorkflowEventTriggerType>;
  in?: Maybe<Array<WorkflowEventTriggerType>>;
  notIn?: Maybe<Array<WorkflowEventTriggerType>>;
  not?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
};

export type NestedEnumWorkflowEventTriggerTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowEventTriggerType>;
  in?: Maybe<Array<WorkflowEventTriggerType>>;
  notIn?: Maybe<Array<WorkflowEventTriggerType>>;
  not?: Maybe<NestedEnumWorkflowEventTriggerTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
};

export type NestedEnumWorkflowEventWithAggregatesFilter = {
  equals?: Maybe<WorkflowEvent>;
  in?: Maybe<Array<WorkflowEvent>>;
  notIn?: Maybe<Array<WorkflowEvent>>;
  not?: Maybe<NestedEnumWorkflowEventWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowEventFilter>;
  _max?: Maybe<NestedEnumWorkflowEventFilter>;
};

export type NestedEnumWorkflowSendingHoursFilter = {
  equals?: Maybe<WorkflowSendingHours>;
  in?: Maybe<Array<WorkflowSendingHours>>;
  notIn?: Maybe<Array<WorkflowSendingHours>>;
  not?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
};

export type NestedEnumWorkflowSendingHoursWithAggregatesFilter = {
  equals?: Maybe<WorkflowSendingHours>;
  in?: Maybe<Array<WorkflowSendingHours>>;
  notIn?: Maybe<Array<WorkflowSendingHours>>;
  not?: Maybe<NestedEnumWorkflowSendingHoursWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
  _max?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
};

export type NestedEnumWorkflowTypeFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeFilter>;
};

export type NestedEnumWorkflowTypeNullableFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
};

export type NestedEnumWorkflowTypeNullableWithAggregatesFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
  _max?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
};

export type NestedEnumWorkflowTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowTypeFilter>;
};

export type NestedEnumWorkflowVisitTypeFilter = {
  equals?: Maybe<WorkflowVisitType>;
  in?: Maybe<Array<WorkflowVisitType>>;
  notIn?: Maybe<Array<WorkflowVisitType>>;
  not?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
};

export type NestedEnumWorkflowVisitTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowVisitType>;
  in?: Maybe<Array<WorkflowVisitType>>;
  notIn?: Maybe<Array<WorkflowVisitType>>;
  not?: Maybe<NestedEnumWorkflowVisitTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedFloatNullableFilter>;
  _min?: Maybe<NestedFloatNullableFilter>;
  _max?: Maybe<NestedFloatNullableFilter>;
};

export type NestedFloatWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedFloatFilter>;
  _min?: Maybe<NestedFloatFilter>;
  _max?: Maybe<NestedFloatFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedIntNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
};

export type NestedIntWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
};

export type NestedJsonFilter = {
  equals?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  array_contains?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
};

export type NestedJsonNullableFilter = {
  equals?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  array_contains?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
};

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedStringFilter>;
  _max?: Maybe<NestedStringFilter>;
};

export type NonPrismaSchemaScriptRan = {
  __typename?: 'NonPrismaSchemaScriptRan';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  scriptName: Scalars['String'];
  status: Scalars['String'];
};

export type NonPrismaSchemaScriptRanCountAggregateOutputType = {
  __typename?: 'NonPrismaSchemaScriptRanCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  scriptName: Scalars['Int'];
  status: Scalars['Int'];
  _all: Scalars['Int'];
};

export type NonPrismaSchemaScriptRanCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  scriptName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type NonPrismaSchemaScriptRanCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  scriptName: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type NonPrismaSchemaScriptRanCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  scriptName: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type NonPrismaSchemaScriptRanMaxAggregateOutputType = {
  __typename?: 'NonPrismaSchemaScriptRanMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  scriptName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type NonPrismaSchemaScriptRanMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  scriptName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type NonPrismaSchemaScriptRanMinAggregateOutputType = {
  __typename?: 'NonPrismaSchemaScriptRanMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  scriptName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type NonPrismaSchemaScriptRanMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  scriptName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export enum NonPrismaSchemaScriptRanOrderByRelevanceFieldEnum {
  Id = 'id',
  ScriptName = 'scriptName',
  Status = 'status'
}

export type NonPrismaSchemaScriptRanOrderByRelevanceInput = {
  fields: Array<NonPrismaSchemaScriptRanOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type NonPrismaSchemaScriptRanOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  scriptName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  _count?: Maybe<NonPrismaSchemaScriptRanCountOrderByAggregateInput>;
  _max?: Maybe<NonPrismaSchemaScriptRanMaxOrderByAggregateInput>;
  _min?: Maybe<NonPrismaSchemaScriptRanMinOrderByAggregateInput>;
};

export type NonPrismaSchemaScriptRanOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  scriptName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  _relevance?: Maybe<NonPrismaSchemaScriptRanOrderByRelevanceInput>;
};

export enum NonPrismaSchemaScriptRanScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ScriptName = 'scriptName',
  Status = 'status'
}

export type NonPrismaSchemaScriptRanScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<NonPrismaSchemaScriptRanScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<NonPrismaSchemaScriptRanScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<NonPrismaSchemaScriptRanScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  scriptName?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<StringWithAggregatesFilter>;
};

export type NonPrismaSchemaScriptRanUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  scriptName: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type NonPrismaSchemaScriptRanUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  scriptName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type NonPrismaSchemaScriptRanUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  scriptName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type NonPrismaSchemaScriptRanUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  scriptName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type NonPrismaSchemaScriptRanUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  scriptName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type NonPrismaSchemaScriptRanWhereInput = {
  AND?: Maybe<Array<NonPrismaSchemaScriptRanWhereInput>>;
  OR?: Maybe<Array<NonPrismaSchemaScriptRanWhereInput>>;
  NOT?: Maybe<Array<NonPrismaSchemaScriptRanWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  scriptName?: Maybe<StringFilter>;
  status?: Maybe<StringFilter>;
};

export type NonPrismaSchemaScriptRanWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  scriptName?: Maybe<Scalars['String']>;
};

export enum NormalizedWritebackError {
  BitwerxGenericError = 'Bitwerx_GenericError',
  BitwerxInvalidQuery = 'Bitwerx_InvalidQuery',
  BitwerxUnknown = 'Bitwerx_Unknown',
  BitwerxInvalidConfiguration = 'Bitwerx_InvalidConfiguration',
  BitwerxDown = 'Bitwerx_Down',
  BitwerxHeartbeatTooOld = 'Bitwerx_HeartbeatTooOld',
  BitwerxPimsConnectivityIssue = 'Bitwerx_PimsConnectivityIssue',
  BitwerxTimedOut = 'Bitwerx_TimedOut',
  BitwerxInvoiceClosed = 'Bitwerx_InvoiceClosed'
}

export enum NotificationEvent {
  AppointmentScheduled = 'AppointmentScheduled',
  AppointmentConfirmation = 'AppointmentConfirmation',
  AppointmentReminder = 'AppointmentReminder',
  AppointmentRescheduleRequested = 'AppointmentRescheduleRequested',
  AppointmentConfirmed = 'AppointmentConfirmed',
  AppointmentCancelation = 'AppointmentCancelation',
  AppointmentArrivalConfirmation = 'AppointmentArrivalConfirmation',
  AppointmentFollowup = 'AppointmentFollowup',
  AppointmentPimsSync = 'AppointmentPimsSync',
  ServiceReminderFormSubmitted = 'ServiceReminderFormSubmitted',
  WidgetRequestFormSubmitted = 'WidgetRequestFormSubmitted',
  CallDeflectionFormSubmitted = 'CallDeflectionFormSubmitted'
}

export type NotificationEventToMessage = {
  __typename?: 'NotificationEventToMessage';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  event: NotificationEvent;
  emailSubject: Scalars['String'];
  emailTitle: Scalars['String'];
  emailSalutation: Scalars['String'];
  emailBody: Scalars['String'];
  smsMessage: Scalars['String'];
  emailCallToAction: Scalars['String'];
};

export type NotificationEventToMessageCountAggregateOutputType = {
  __typename?: 'NotificationEventToMessageCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  event: Scalars['Int'];
  emailSubject: Scalars['Int'];
  emailTitle: Scalars['Int'];
  emailSalutation: Scalars['Int'];
  emailBody: Scalars['Int'];
  smsMessage: Scalars['Int'];
  emailCallToAction: Scalars['Int'];
  _all: Scalars['Int'];
};

export type NotificationEventToMessageCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
};

export type NotificationEventToMessageCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event: NotificationEvent;
  emailSubject: Scalars['String'];
  emailTitle: Scalars['String'];
  emailSalutation: Scalars['String'];
  emailBody: Scalars['String'];
  smsMessage: Scalars['String'];
  emailCallToAction: Scalars['String'];
};

export type NotificationEventToMessageCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event: NotificationEvent;
  emailSubject: Scalars['String'];
  emailTitle: Scalars['String'];
  emailSalutation: Scalars['String'];
  emailBody: Scalars['String'];
  smsMessage: Scalars['String'];
  emailCallToAction: Scalars['String'];
};

export type NotificationEventToMessageMaxAggregateOutputType = {
  __typename?: 'NotificationEventToMessageMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<NotificationEvent>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailBody?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
};

export type NotificationEventToMessageMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
};

export type NotificationEventToMessageMinAggregateOutputType = {
  __typename?: 'NotificationEventToMessageMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<NotificationEvent>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailBody?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
};

export type NotificationEventToMessageMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
};

export enum NotificationEventToMessageOrderByRelevanceFieldEnum {
  Id = 'id',
  EmailSubject = 'emailSubject',
  EmailTitle = 'emailTitle',
  EmailSalutation = 'emailSalutation',
  EmailBody = 'emailBody',
  SmsMessage = 'smsMessage',
  EmailCallToAction = 'emailCallToAction'
}

export type NotificationEventToMessageOrderByRelevanceInput = {
  fields: Array<NotificationEventToMessageOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type NotificationEventToMessageOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
  _count?: Maybe<NotificationEventToMessageCountOrderByAggregateInput>;
  _max?: Maybe<NotificationEventToMessageMaxOrderByAggregateInput>;
  _min?: Maybe<NotificationEventToMessageMinOrderByAggregateInput>;
};

export type NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
  _relevance?: Maybe<NotificationEventToMessageOrderByRelevanceInput>;
};

export enum NotificationEventToMessageScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Event = 'event',
  EmailSubject = 'emailSubject',
  EmailTitle = 'emailTitle',
  EmailSalutation = 'emailSalutation',
  EmailBody = 'emailBody',
  SmsMessage = 'smsMessage',
  EmailCallToAction = 'emailCallToAction'
}

export type NotificationEventToMessageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<NotificationEventToMessageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<NotificationEventToMessageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<NotificationEventToMessageScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  event?: Maybe<EnumNotificationEventWithAggregatesFilter>;
  emailSubject?: Maybe<StringWithAggregatesFilter>;
  emailTitle?: Maybe<StringWithAggregatesFilter>;
  emailSalutation?: Maybe<StringWithAggregatesFilter>;
  emailBody?: Maybe<StringWithAggregatesFilter>;
  smsMessage?: Maybe<StringWithAggregatesFilter>;
  emailCallToAction?: Maybe<StringWithAggregatesFilter>;
};

export type NotificationEventToMessageUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event: NotificationEvent;
  emailSubject: Scalars['String'];
  emailTitle: Scalars['String'];
  emailSalutation: Scalars['String'];
  emailBody: Scalars['String'];
  smsMessage: Scalars['String'];
  emailCallToAction: Scalars['String'];
};

export type NotificationEventToMessageUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<NotificationEvent>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailBody?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
};

export type NotificationEventToMessageUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<NotificationEvent>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailBody?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
};

export type NotificationEventToMessageUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<NotificationEvent>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailBody?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
};

export type NotificationEventToMessageUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<NotificationEvent>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailBody?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
};

export type NotificationEventToMessageWhereInput = {
  AND?: Maybe<Array<NotificationEventToMessageWhereInput>>;
  OR?: Maybe<Array<NotificationEventToMessageWhereInput>>;
  NOT?: Maybe<Array<NotificationEventToMessageWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  event?: Maybe<EnumNotificationEventFilter>;
  emailSubject?: Maybe<StringFilter>;
  emailTitle?: Maybe<StringFilter>;
  emailSalutation?: Maybe<StringFilter>;
  emailBody?: Maybe<StringFilter>;
  smsMessage?: Maybe<StringFilter>;
  emailCallToAction?: Maybe<StringFilter>;
};

export type NotificationEventToMessageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  event?: Maybe<NotificationEvent>;
};

export enum NotificationSettingType {
  AppointmentConfirmed = 'Appointment_Confirmed',
  AppointmentRescheduleRequested = 'Appointment_RescheduleRequested',
  ConnectWidgetRequestSubmitted = 'ConnectWidget_RequestSubmitted',
  ConversationAssigned = 'Conversation_Assigned',
  ConversationNewMessage = 'Conversation_NewMessage',
  ConversationStatusChange = 'Conversation_StatusChange',
  FormSubmitted = 'Form_Submitted',
  PaymentReceived = 'Payment_Received'
}

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableEnumAmericanKennelClubBreedGroupFieldUpdateOperationsInput = {
  set?: Maybe<AmericanKennelClubBreedGroup>;
};

export type NullableEnumAmericanKennelClubDemeanorCategoryFieldUpdateOperationsInput = {
  set?: Maybe<AmericanKennelClubDemeanorCategory>;
};

export type NullableEnumAmericanKennelClubEnergyLevelCategoryFieldUpdateOperationsInput = {
  set?: Maybe<AmericanKennelClubEnergyLevelCategory>;
};

export type NullableEnumAmericanKennelClubGroomingFrequencyCategoryFieldUpdateOperationsInput = {
  set?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
};

export type NullableEnumAmericanKennelClubSheddingCategoryFieldUpdateOperationsInput = {
  set?: Maybe<AmericanKennelClubSheddingCategory>;
};

export type NullableEnumAmericanKennelClubTrainabilityCategoryFieldUpdateOperationsInput = {
  set?: Maybe<AmericanKennelClubTrainabilityCategory>;
};

export type NullableEnumAutomatedTriggerTypeFieldUpdateOperationsInput = {
  set?: Maybe<AutomatedTriggerType>;
};

export type NullableEnumBitwerxHealthcheckRunStatusFieldUpdateOperationsInput = {
  set?: Maybe<BitwerxHealthcheckRunStatus>;
};

export type NullableEnumBoardFilterDateRangeFieldUpdateOperationsInput = {
  set?: Maybe<BoardFilterDateRange>;
};

export type NullableEnumCampaignRegistryBrandIdentityStatusFieldUpdateOperationsInput = {
  set?: Maybe<CampaignRegistryBrandIdentityStatus>;
};

export type NullableEnumCampaignRegistryCampaignSharingStatusFieldUpdateOperationsInput = {
  set?: Maybe<CampaignRegistryCampaignSharingStatus>;
};

export type NullableEnumCancelReasonTypeFieldUpdateOperationsInput = {
  set?: Maybe<CancelReasonType>;
};

export type NullableEnumCardFundingFieldUpdateOperationsInput = {
  set?: Maybe<CardFunding>;
};

export type NullableEnumCareBenefitUnitOfMeasureFieldUpdateOperationsInput = {
  set?: Maybe<CareBenefitUnitOfMeasure>;
};

export type NullableEnumCareSubscriptionPaymentStatusFieldUpdateOperationsInput = {
  set?: Maybe<CareSubscriptionPaymentStatus>;
};

export type NullableEnumChannelMessageAttachmentEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChannelMessageAttachmentEntityType>;
};

export type NullableEnumChannelMessageAttachmentUploadStatusFieldUpdateOperationsInput = {
  set?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export type NullableEnumChannelTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChannelType>;
};

export type NullableEnumChannelVisibilityFieldUpdateOperationsInput = {
  set?: Maybe<ChannelVisibility>;
};

export type NullableEnumClinicEntityCreationSourceFieldUpdateOperationsInput = {
  set?: Maybe<ClinicEntityCreationSource>;
};

export type NullableEnumClinicEntityPhoneNumberSmsStatusFieldUpdateOperationsInput = {
  set?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
};

export type NullableEnumClinicPetParentAddressTypeFieldUpdateOperationsInput = {
  set?: Maybe<ClinicPetParentAddressType>;
};

export type NullableEnumContactVerificationMethodFieldUpdateOperationsInput = {
  set?: Maybe<ContactVerificationMethod>;
};

export type NullableEnumCustomFieldsDataSourceFieldUpdateOperationsInput = {
  set?: Maybe<CustomFieldsDataSource>;
};

export type NullableEnumDeviceOsFieldUpdateOperationsInput = {
  set?: Maybe<DeviceOs>;
};

export type NullableEnumDeviceTypeFieldUpdateOperationsInput = {
  set?: Maybe<DeviceType>;
};

export type NullableEnumEmailCampaignLogSkipReasonFieldUpdateOperationsInput = {
  set?: Maybe<EmailCampaignLogSkipReason>;
};

export type NullableEnumHillsToHomeSpeciesFieldUpdateOperationsInput = {
  set?: Maybe<HillsToHomeSpecies>;
};

export type NullableEnumInformScheduledCampaignCadenceFieldUpdateOperationsInput = {
  set?: Maybe<InformScheduledCampaignCadence>;
};

export type NullableEnumIntegrationStatusFieldUpdateOperationsInput = {
  set?: Maybe<IntegrationStatus>;
};

export type NullableEnumIntegrationTypeFieldUpdateOperationsInput = {
  set?: Maybe<IntegrationType>;
};

export type NullableEnumNormalizedWritebackErrorFieldUpdateOperationsInput = {
  set?: Maybe<NormalizedWritebackError>;
};

export type NullableEnumPaymentMediumFieldUpdateOperationsInput = {
  set?: Maybe<PaymentMedium>;
};

export type NullableEnumPaymentRelationshipFieldUpdateOperationsInput = {
  set?: Maybe<PaymentRelationship>;
};

export type NullableEnumPaymentScheduleFieldUpdateOperationsInput = {
  set?: Maybe<PaymentSchedule>;
};

export type NullableEnumPimsSyncStatusFieldUpdateOperationsInput = {
  set?: Maybe<PimsSyncStatus>;
};

export type NullableEnumProductSupplyDurationUnitFieldUpdateOperationsInput = {
  set?: Maybe<ProductSupplyDurationUnit>;
};

export type NullableEnumPushNotificationDeliveryErrorTypeFieldUpdateOperationsInput = {
  set?: Maybe<PushNotificationDeliveryErrorType>;
};

export type NullableEnumRenewalRejectionReasonFieldUpdateOperationsInput = {
  set?: Maybe<RenewalRejectionReason>;
};

export type NullableEnumSmsEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<SmsEntityType>;
};

export type NullableEnumSmsNotificationDeliveryErrorTypeFieldUpdateOperationsInput = {
  set?: Maybe<SmsNotificationDeliveryErrorType>;
};

export type NullableEnumSpeciesTypeFieldUpdateOperationsInput = {
  set?: Maybe<SpeciesType>;
};

export type NullableEnumStripePaymentErrorFieldUpdateOperationsInput = {
  set?: Maybe<StripePaymentError>;
};

export type NullableEnumSurveyStepFieldUpdateOperationsInput = {
  set?: Maybe<SurveyStep>;
};

export type NullableEnumSurveyTypeFieldUpdateOperationsInput = {
  set?: Maybe<SurveyType>;
};

export type NullableEnumWorkflowTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowType>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export enum NullableJsonNullValueInput {
  DbNull = 'DbNull',
  JsonNull = 'JsonNull'
}

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type NumberOfPetParentsReached = {
  __typename?: 'NumberOfPetParentsReached';
  numberOfPetPerents?: Maybe<Scalars['Int']>;
  timeEstimateInSeconds?: Maybe<Scalars['Int']>;
};

export type OperationResult = {
  __typename?: 'OperationResult';
  success: Scalars['Boolean'];
  messages: Array<Scalars['String']>;
};

export type OrderPhoneNumberInput = {
  phoneNumber: Scalars['String'];
};

export type OrderPhoneNumberResponse = {
  __typename?: 'OrderPhoneNumberResponse';
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  crmId?: Maybe<Scalars['String']>;
  configuration: Scalars['Json'];
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics: Array<Clinic>;
  organizationPets: Array<OrganizationPet>;
  organizationPetParents: Array<OrganizationPetParent>;
  carePlans: Array<CarePlan>;
  careBenefits: Array<CareBenefit>;
  careAddonPackage: Array<CareAddonPackage>;
  paymentProcessorClinic?: Maybe<Clinic>;
  carePlanProviderGroups: Array<CarePlanProviderGroup>;
  EmailCampaignTemplate: Array<EmailCampaignTemplate>;
  _count: OrganizationCountOutputType;
};


export type OrganizationClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};


export type OrganizationOrganizationPetsArgs = {
  where?: Maybe<OrganizationPetWhereInput>;
  orderBy?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationPetScalarFieldEnum>;
};


export type OrganizationOrganizationPetParentsArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
  orderBy?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationPetParentScalarFieldEnum>;
};


export type OrganizationCarePlansArgs = {
  where?: Maybe<CarePlanWhereInput>;
  orderBy?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanScalarFieldEnum>;
};


export type OrganizationCareBenefitsArgs = {
  where?: Maybe<CareBenefitWhereInput>;
  orderBy?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitScalarFieldEnum>;
};


export type OrganizationCareAddonPackageArgs = {
  where?: Maybe<CareAddonPackageWhereInput>;
  orderBy?: Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAddonPackageScalarFieldEnum>;
};


export type OrganizationCarePlanProviderGroupsArgs = {
  where?: Maybe<CarePlanProviderGroupWhereInput>;
  orderBy?: Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanProviderGroupScalarFieldEnum>;
};


export type OrganizationEmailCampaignTemplateArgs = {
  where?: Maybe<EmailCampaignTemplateWhereInput>;
  orderBy?: Maybe<EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignTemplateScalarFieldEnum>;
};

export type OrganizationCareBenefitToClinicService = {
  __typename?: 'OrganizationCareBenefitToClinicService';
  benefitId: Scalars['String'];
  serviceId: Scalars['String'];
  benefit: CareBenefit;
  service: Service;
};

export type OrganizationCareBenefitToClinicServiceBenefitIdServiceIdCompoundUniqueInput = {
  benefitId: Scalars['String'];
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCategory = {
  __typename?: 'OrganizationCareBenefitToClinicServiceCategory';
  benefitId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  serviceCategoryId: Scalars['String'];
  benefit: CareBenefit;
  serviceCategory: ServiceCategory;
};

export type OrganizationCareBenefitToClinicServiceCategoryBenefitIdServiceCategoryIdCompoundUniqueInput = {
  benefitId: Scalars['String'];
  serviceCategoryId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCategoryCountAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceCategoryCountAggregateOutputType';
  benefitId: Scalars['Int'];
  createdAt: Scalars['Int'];
  serviceCategoryId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OrganizationCareBenefitToClinicServiceCategoryCountOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  benefit: CareBenefitCreateNestedOneWithoutConnectedServiceCategoriesInput;
  serviceCategory: ServiceCategoryCreateNestedOneWithoutConnectedCareBenefitsInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateManyBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateManyBenefitInputEnvelope = {
  data: Array<OrganizationCareBenefitToClinicServiceCategoryCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateManyInput = {
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateManyServiceCategoryInput = {
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateManyServiceCategoryInputEnvelope = {
  data: Array<OrganizationCareBenefitToClinicServiceCategoryCreateManyServiceCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutServiceCategoryInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyServiceCategoryInputEnvelope>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutBenefitInput = {
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
  create: OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateWithoutBenefitInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutServiceCategoryInput = {
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
  create: OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateWithoutServiceCategoryInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateWithoutBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategory: ServiceCategoryCreateNestedOneWithoutConnectedCareBenefitsInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateWithoutServiceCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  benefit: CareBenefitCreateNestedOneWithoutConnectedServiceCategoriesInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryListRelationFilter = {
  every?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
  some?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
  none?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
};

export type OrganizationCareBenefitToClinicServiceCategoryMaxAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceCategoryMaxAggregateOutputType';
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryMaxOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceCategoryMinAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceCategoryMinAggregateOutputType';
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryMinOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceCategoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum OrganizationCareBenefitToClinicServiceCategoryOrderByRelevanceFieldEnum {
  BenefitId = 'benefitId',
  ServiceCategoryId = 'serviceCategoryId'
}

export type OrganizationCareBenefitToClinicServiceCategoryOrderByRelevanceInput = {
  fields: Array<OrganizationCareBenefitToClinicServiceCategoryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCategoryOrderByWithAggregationInput = {
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  _count?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationCareBenefitToClinicServiceCategoryMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationCareBenefitToClinicServiceCategoryMinOrderByAggregateInput>;
};

export type OrganizationCareBenefitToClinicServiceCategoryOrderByWithRelationAndSearchRelevanceInput = {
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  serviceCategory?: Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByRelevanceInput>;
};

export enum OrganizationCareBenefitToClinicServiceCategoryScalarFieldEnum {
  BenefitId = 'benefitId',
  CreatedAt = 'createdAt',
  ServiceCategoryId = 'serviceCategoryId'
}

export type OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput>>;
  benefitId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  serviceCategoryId?: Maybe<StringFilter>;
};

export type OrganizationCareBenefitToClinicServiceCategoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereWithAggregatesInput>>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  serviceCategoryId?: Maybe<StringWithAggregatesFilter>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateInput = {
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyServiceCategoryInputEnvelope>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateWithoutBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateWithoutServiceCategoryInput = {
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateInput = {
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyInput = {
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput>>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutConnectedCareBenefitsInput = {
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutConnectedServiceCategoriesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpsertWithWhereUniqueWithoutServiceCategoryInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyServiceCategoryInputEnvelope>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateWithWhereUniqueWithoutServiceCategoryInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithWhereWithoutServiceCategoryInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput>>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateWithoutBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateWithoutServiceCategoryInput = {
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutConnectedServiceCategoriesNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneRequiredWithoutConnectedCareBenefitsNestedInput>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithWhereWithoutBenefitInput = {
  where: OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput;
  data: OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutConnectedServiceCategoriesInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithWhereWithoutServiceCategoryInput = {
  where: OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput;
  data: OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutConnectedCareBenefitsInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput>>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutServiceCategoryNestedInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpsertWithWhereUniqueWithoutServiceCategoryInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyServiceCategoryInputEnvelope>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateWithWhereUniqueWithoutServiceCategoryInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithWhereWithoutServiceCategoryInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput>>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateWithWhereUniqueWithoutBenefitInput = {
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
  data: OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateWithoutBenefitInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateWithWhereUniqueWithoutServiceCategoryInput = {
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
  data: OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateWithoutServiceCategoryInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateWithoutBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneRequiredWithoutConnectedCareBenefitsNestedInput>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateWithoutServiceCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutConnectedServiceCategoriesNestedInput>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpsertWithWhereUniqueWithoutBenefitInput = {
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
  update: OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateWithoutBenefitInput;
  create: OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateWithoutBenefitInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpsertWithWhereUniqueWithoutServiceCategoryInput = {
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
  update: OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateWithoutServiceCategoryInput;
  create: OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateWithoutServiceCategoryInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryWhereInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereInput>>;
  benefitId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  serviceCategoryId?: Maybe<StringFilter>;
  benefit?: Maybe<CareBenefitWhereInput>;
  serviceCategory?: Maybe<ServiceCategoryWhereInput>;
};

export type OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput = {
  benefitId_serviceCategoryId?: Maybe<OrganizationCareBenefitToClinicServiceCategoryBenefitIdServiceCategoryIdCompoundUniqueInput>;
};

export type OrganizationCareBenefitToClinicServiceCountAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceCountAggregateOutputType';
  benefitId: Scalars['Int'];
  serviceId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OrganizationCareBenefitToClinicServiceCountOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceCreateInput = {
  benefit: CareBenefitCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput;
  service: ServiceCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type OrganizationCareBenefitToClinicServiceCreateManyBenefitInput = {
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope = {
  data: Array<OrganizationCareBenefitToClinicServiceCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationCareBenefitToClinicServiceCreateManyInput = {
  benefitId: Scalars['String'];
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCreateManyServiceInput = {
  benefitId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope = {
  data: Array<OrganizationCareBenefitToClinicServiceCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
};

export type OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
};

export type OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
  create: OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutBenefitInput;
};

export type OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
  create: OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutServiceInput;
};

export type OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput = {
  service: ServiceCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput = {
  benefit: CareBenefitCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type OrganizationCareBenefitToClinicServiceListRelationFilter = {
  every?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  some?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  none?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
};

export type OrganizationCareBenefitToClinicServiceMaxAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceMaxAggregateOutputType';
  benefitId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceMaxOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceMinAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceMinAggregateOutputType';
  benefitId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceMinOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum OrganizationCareBenefitToClinicServiceOrderByRelevanceFieldEnum {
  BenefitId = 'benefitId',
  ServiceId = 'serviceId'
}

export type OrganizationCareBenefitToClinicServiceOrderByRelevanceInput = {
  fields: Array<OrganizationCareBenefitToClinicServiceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceOrderByWithAggregationInput = {
  benefitId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  _count?: Maybe<OrganizationCareBenefitToClinicServiceCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationCareBenefitToClinicServiceMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationCareBenefitToClinicServiceMinOrderByAggregateInput>;
};

export type OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput = {
  benefitId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<OrganizationCareBenefitToClinicServiceOrderByRelevanceInput>;
};

export enum OrganizationCareBenefitToClinicServiceScalarFieldEnum {
  BenefitId = 'benefitId',
  ServiceId = 'serviceId'
}

export type OrganizationCareBenefitToClinicServiceScalarWhereInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
  benefitId?: Maybe<StringFilter>;
  serviceId?: Maybe<StringFilter>;
};

export type OrganizationCareBenefitToClinicServiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereWithAggregatesInput>>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  serviceId?: Maybe<StringWithAggregatesFilter>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateInput = {
  benefitId: Scalars['String'];
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutBenefitInput = {
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutServiceInput = {
  benefitId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateInput = {
  benefitId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateManyInput = {
  benefitId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutOrganizationCareBenefitToClinicServiceInput = {
  benefitId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutBenefitInput = {
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutServiceInput = {
  benefitId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUpdateInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceNestedInput>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceNestedInput>;
};

export type OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutBenefitInput = {
  where: OrganizationCareBenefitToClinicServiceScalarWhereInput;
  data: OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutServiceInput = {
  where: OrganizationCareBenefitToClinicServiceScalarWhereInput;
  data: OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
};

export type OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
};

export type OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutBenefitInput = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
  data: OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutBenefitInput;
};

export type OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutServiceInput = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
  data: OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutServiceInput;
};

export type OrganizationCareBenefitToClinicServiceUpdateWithoutBenefitInput = {
  service?: Maybe<ServiceUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceNestedInput>;
};

export type OrganizationCareBenefitToClinicServiceUpdateWithoutServiceInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceNestedInput>;
};

export type OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutBenefitInput = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
  update: OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutBenefitInput;
  create: OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutBenefitInput;
};

export type OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutServiceInput = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
  update: OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutServiceInput;
  create: OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutServiceInput;
};

export type OrganizationCareBenefitToClinicServiceWhereInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereInput>>;
  benefitId?: Maybe<StringFilter>;
  serviceId?: Maybe<StringFilter>;
  benefit?: Maybe<CareBenefitWhereInput>;
  service?: Maybe<ServiceWhereInput>;
};

export type OrganizationCareBenefitToClinicServiceWhereUniqueInput = {
  benefitId_serviceId?: Maybe<OrganizationCareBenefitToClinicServiceBenefitIdServiceIdCompoundUniqueInput>;
};

export type OrganizationCountAggregateOutputType = {
  __typename?: 'OrganizationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
  crmId: Scalars['Int'];
  configuration: Scalars['Int'];
  paymentProcessorClinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OrganizationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  configuration?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
};

export type OrganizationCountOutputType = {
  __typename?: 'OrganizationCountOutputType';
  clinics: Scalars['Int'];
  organizationPets: Scalars['Int'];
  organizationPetParents: Scalars['Int'];
  carePlans: Scalars['Int'];
  careBenefits: Scalars['Int'];
  careAddonPackage: Scalars['Int'];
  carePlanProviderGroups: Scalars['Int'];
  EmailCampaignTemplate: Scalars['Int'];
};

export type OrganizationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationCreateNestedOneWithoutCareAddonPackageInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutCareAddonPackageInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCareAddonPackageInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutCareBenefitsInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutCareBenefitsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCareBenefitsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutCarePlanProviderGroupsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCarePlanProviderGroupsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutCarePlansInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutCarePlansInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCarePlansInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutClinicsInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutClinicsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutClinicsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutEmailCampaignTemplateInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutEmailCampaignTemplateInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutEmailCampaignTemplateInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutOrganizationPetParentsInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutOrganizationPetParentsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutOrganizationPetsInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutOrganizationPetsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutOrganizationPetsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateOrConnectWithoutCareAddonPackageInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutCareAddonPackageInput;
};

export type OrganizationCreateOrConnectWithoutCareBenefitsInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutCareBenefitsInput;
};

export type OrganizationCreateOrConnectWithoutCarePlanProviderGroupsInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutCarePlanProviderGroupsInput;
};

export type OrganizationCreateOrConnectWithoutCarePlansInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutCarePlansInput;
};

export type OrganizationCreateOrConnectWithoutClinicsInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutClinicsInput;
};

export type OrganizationCreateOrConnectWithoutEmailCampaignTemplateInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutEmailCampaignTemplateInput;
};

export type OrganizationCreateOrConnectWithoutOrganizationPetParentsInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutOrganizationPetParentsInput;
};

export type OrganizationCreateOrConnectWithoutOrganizationPetsInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutOrganizationPetsInput;
};

export type OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput;
};

export type OrganizationCreateWithoutCareAddonPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutCarePlanProviderGroupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutCarePlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutEmailCampaignTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutOrganizationPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutPaymentProcessorClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationMaxAggregateOutputType = {
  __typename?: 'OrganizationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
};

export type OrganizationMinAggregateOutputType = {
  __typename?: 'OrganizationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
};

export enum OrganizationOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  CrmId = 'crmId',
  PaymentProcessorClinicId = 'paymentProcessorClinicId'
}

export type OrganizationOrderByRelevanceInput = {
  fields: Array<OrganizationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type OrganizationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  configuration?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  _count?: Maybe<OrganizationCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationMinOrderByAggregateInput>;
};

export type OrganizationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  configuration?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  organizationPets?: Maybe<OrganizationPetOrderByRelationAggregateInput>;
  organizationPetParents?: Maybe<OrganizationPetParentOrderByRelationAggregateInput>;
  carePlans?: Maybe<CarePlanOrderByRelationAggregateInput>;
  careBenefits?: Maybe<CareBenefitOrderByRelationAggregateInput>;
  careAddonPackage?: Maybe<CareAddonPackageOrderByRelationAggregateInput>;
  paymentProcessorClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupOrderByRelationAggregateInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateOrderByRelationAggregateInput>;
  _relevance?: Maybe<OrganizationOrderByRelevanceInput>;
};

export type OrganizationPet = {
  __typename?: 'OrganizationPet';
  id: Scalars['String'];
  preferredName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organization: Organization;
  carePlanEnrollments: Array<CarePlanEnrollment>;
  careAddonEnrollments: Array<CareAddonEnrollment>;
  clinicPets: Array<ClinicPet>;
  organizationPetToOrganizationPetParents: Array<OrganizationPetToOrganizationPetParent>;
  associatedBenefitUsageFollowup: Array<CareBenefitUsageFollowup>;
  associatedSubscriptions: Array<CareStripeSubscription>;
  TransferFromPetEnrollmentSupportEvent: Array<EnrollmentSupportEvent>;
  TransferToPetEnrollmentSupportEvent: Array<EnrollmentSupportEvent>;
  _count: OrganizationPetCountOutputType;
};


export type OrganizationPetCarePlanEnrollmentsArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanEnrollmentScalarFieldEnum>;
};


export type OrganizationPetCareAddonEnrollmentsArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
  orderBy?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAddonEnrollmentScalarFieldEnum>;
};


export type OrganizationPetClinicPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};


export type OrganizationPetOrganizationPetToOrganizationPetParentsArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  orderBy?: Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>;
};


export type OrganizationPetAssociatedBenefitUsageFollowupArgs = {
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
  orderBy?: Maybe<CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageFollowupScalarFieldEnum>;
};


export type OrganizationPetAssociatedSubscriptionsArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
  orderBy?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareStripeSubscriptionScalarFieldEnum>;
};


export type OrganizationPetTransferFromPetEnrollmentSupportEventArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
};


export type OrganizationPetTransferToPetEnrollmentSupportEventArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
};

export type OrganizationPetCountAggregateOutputType = {
  __typename?: 'OrganizationPetCountAggregateOutputType';
  id: Scalars['Int'];
  preferredName: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  profilePictureUrl: Scalars['Int'];
  organizationId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OrganizationPetCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  preferredName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
};

export type OrganizationPetCountOutputType = {
  __typename?: 'OrganizationPetCountOutputType';
  carePlanEnrollments: Scalars['Int'];
  careAddonEnrollments: Scalars['Int'];
  clinicPets: Scalars['Int'];
  organizationPetToOrganizationPetParents: Scalars['Int'];
  associatedBenefitUsageFollowup: Scalars['Int'];
  associatedSubscriptions: Scalars['Int'];
  TransferFromPetEnrollmentSupportEvent: Scalars['Int'];
  TransferToPetEnrollmentSupportEvent: Scalars['Int'];
};

export type OrganizationPetCreateInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
};

export type OrganizationPetCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type OrganizationPetCreateManyOrganizationInputEnvelope = {
  data: Array<OrganizationPetCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<OrganizationPetCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
};

export type OrganizationPetCreateNestedOneWithoutAssociatedBenefitUsageFollowupInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutAssociatedBenefitUsageFollowupInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutAssociatedBenefitUsageFollowupInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
};

export type OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutAssociatedSubscriptionsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutAssociatedSubscriptionsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
};

export type OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutCareAddonEnrollmentsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
};

export type OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutCarePlanEnrollmentsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
};

export type OrganizationPetCreateNestedOneWithoutClinicPetsInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutClinicPetsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutClinicPetsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
};

export type OrganizationPetCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
};

export type OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutTransferFromPetEnrollmentSupportEventInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutTransferFromPetEnrollmentSupportEventInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
};

export type OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutTransferToPetEnrollmentSupportEventInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutTransferToPetEnrollmentSupportEventInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
};

export type OrganizationPetCreateOrConnectWithoutAssociatedBenefitUsageFollowupInput = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetUncheckedCreateWithoutAssociatedBenefitUsageFollowupInput;
};

export type OrganizationPetCreateOrConnectWithoutAssociatedSubscriptionsInput = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetUncheckedCreateWithoutAssociatedSubscriptionsInput;
};

export type OrganizationPetCreateOrConnectWithoutCareAddonEnrollmentsInput = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput;
};

export type OrganizationPetCreateOrConnectWithoutCarePlanEnrollmentsInput = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput;
};

export type OrganizationPetCreateOrConnectWithoutClinicPetsInput = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetUncheckedCreateWithoutClinicPetsInput;
};

export type OrganizationPetCreateOrConnectWithoutOrganizationInput = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetUncheckedCreateWithoutOrganizationInput;
};

export type OrganizationPetCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetCreateOrConnectWithoutTransferFromPetEnrollmentSupportEventInput = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetUncheckedCreateWithoutTransferFromPetEnrollmentSupportEventInput;
};

export type OrganizationPetCreateOrConnectWithoutTransferToPetEnrollmentSupportEventInput = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetUncheckedCreateWithoutTransferToPetEnrollmentSupportEventInput;
};

export type OrganizationPetCreateWithoutAssociatedBenefitUsageFollowupInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetCreateWithoutAssociatedSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetCreateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetCreateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetCreateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetCreateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetCreateWithoutTransferFromPetEnrollmentSupportEventInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetCreateWithoutTransferToPetEnrollmentSupportEventInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
};

export type OrganizationPetEnrollmentInput = {
  organizationPetId: Scalars['String'];
  carePlanIds: Array<Scalars['String']>;
  careAddonIds: Array<Scalars['String']>;
};

export type OrganizationPetListRelationFilter = {
  every?: Maybe<OrganizationPetWhereInput>;
  some?: Maybe<OrganizationPetWhereInput>;
  none?: Maybe<OrganizationPetWhereInput>;
};

export type OrganizationPetMaxAggregateOutputType = {
  __typename?: 'OrganizationPetMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

export type OrganizationPetMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  preferredName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
};

export type OrganizationPetMinAggregateOutputType = {
  __typename?: 'OrganizationPetMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

export type OrganizationPetMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  preferredName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
};

export type OrganizationPetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum OrganizationPetOrderByRelevanceFieldEnum {
  Id = 'id',
  PreferredName = 'preferredName',
  ProfilePictureUrl = 'profilePictureUrl',
  OrganizationId = 'organizationId'
}

export type OrganizationPetOrderByRelevanceInput = {
  fields: Array<OrganizationPetOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type OrganizationPetOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  preferredName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  _count?: Maybe<OrganizationPetCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationPetMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationPetMinOrderByAggregateInput>;
};

export type OrganizationPetOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  preferredName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentOrderByRelationAggregateInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentOrderByRelationAggregateInput>;
  clinicPets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentOrderByRelationAggregateInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupOrderByRelationAggregateInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionOrderByRelationAggregateInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  _relevance?: Maybe<OrganizationPetOrderByRelevanceInput>;
};

export type OrganizationPetParent = {
  __typename?: 'OrganizationPetParent';
  id: Scalars['String'];
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  organizationId: Scalars['String'];
  /** @deprecated - as parents might have multiple pet subscriptions */
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  organization: Organization;
  clinicPetParents: Array<ClinicPetParent>;
  organizationPetToOrganizationPetParents: Array<OrganizationPetToOrganizationPetParent>;
  /** @deprecated - as parents might have multiple pet subscriptions */
  activeCareStripeSubscription?: Maybe<CareStripeSubscription>;
  stripeCustomer?: Maybe<StripeCustomer>;
  allCareStripeSubscriptions: Array<CareStripeSubscription>;
  _count: OrganizationPetParentCountOutputType;
};


export type OrganizationPetParentClinicPetParentsArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};


export type OrganizationPetParentOrganizationPetToOrganizationPetParentsArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  orderBy?: Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>;
};


export type OrganizationPetParentAllCareStripeSubscriptionsArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
  orderBy?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareStripeSubscriptionScalarFieldEnum>;
};

export type OrganizationPetParentCountAggregateOutputType = {
  __typename?: 'OrganizationPetParentCountAggregateOutputType';
  id: Scalars['Int'];
  preferredFirstName: Scalars['Int'];
  preferredLastName: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  organizationId: Scalars['Int'];
  activeCareStripeSubscriptionId: Scalars['Int'];
  stripeCustomerTableId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OrganizationPetParentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  stripeCustomerTableId?: Maybe<SortOrder>;
};

export type OrganizationPetParentCountOutputType = {
  __typename?: 'OrganizationPetParentCountOutputType';
  clinicPetParents: Scalars['Int'];
  organizationPetToOrganizationPetParents: Scalars['Int'];
  allCareStripeSubscriptions: Scalars['Int'];
};

export type OrganizationPetParentCreateInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentCreateManyOrganizationInputEnvelope = {
  data: Array<OrganizationPetParentCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetParentCreateManyStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentCreateManyStripeCustomerInputEnvelope = {
  data: Array<OrganizationPetParentCreateManyStripeCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetParentCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
};

export type OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutAllCareStripeSubscriptionsInput>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
};

export type OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutClinicPetParentsInput>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
};

export type OrganizationPetParentCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
};

export type OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput = {
  where: OrganizationPetParentWhereUniqueInput;
  create: OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput;
};

export type OrganizationPetParentCreateOrConnectWithoutAllCareStripeSubscriptionsInput = {
  where: OrganizationPetParentWhereUniqueInput;
  create: OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput;
};

export type OrganizationPetParentCreateOrConnectWithoutClinicPetParentsInput = {
  where: OrganizationPetParentWhereUniqueInput;
  create: OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput;
};

export type OrganizationPetParentCreateOrConnectWithoutOrganizationInput = {
  where: OrganizationPetParentWhereUniqueInput;
  create: OrganizationPetParentUncheckedCreateWithoutOrganizationInput;
};

export type OrganizationPetParentCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput = {
  where: OrganizationPetParentWhereUniqueInput;
  create: OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput = {
  where: OrganizationPetParentWhereUniqueInput;
  create: OrganizationPetParentUncheckedCreateWithoutStripeCustomerInput;
};

export type OrganizationPetParentCreateWithoutActiveCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentCreateWithoutAllCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
};

export type OrganizationPetParentCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentCreateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentListRelationFilter = {
  every?: Maybe<OrganizationPetParentWhereInput>;
  some?: Maybe<OrganizationPetParentWhereInput>;
  none?: Maybe<OrganizationPetParentWhereInput>;
};

export type OrganizationPetParentMaxAggregateOutputType = {
  __typename?: 'OrganizationPetParentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  stripeCustomerTableId?: Maybe<SortOrder>;
};

export type OrganizationPetParentMinAggregateOutputType = {
  __typename?: 'OrganizationPetParentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  stripeCustomerTableId?: Maybe<SortOrder>;
};

export type OrganizationPetParentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum OrganizationPetParentOrderByRelevanceFieldEnum {
  Id = 'id',
  PreferredFirstName = 'preferredFirstName',
  PreferredLastName = 'preferredLastName',
  OrganizationId = 'organizationId',
  ActiveCareStripeSubscriptionId = 'activeCareStripeSubscriptionId',
  StripeCustomerTableId = 'stripeCustomerTableId'
}

export type OrganizationPetParentOrderByRelevanceInput = {
  fields: Array<OrganizationPetParentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type OrganizationPetParentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  stripeCustomerTableId?: Maybe<SortOrder>;
  _count?: Maybe<OrganizationPetParentCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationPetParentMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationPetParentMinOrderByAggregateInput>;
};

export type OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  stripeCustomerTableId?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentOrderByRelationAggregateInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  stripeCustomer?: Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionOrderByRelationAggregateInput>;
  _relevance?: Maybe<OrganizationPetParentOrderByRelevanceInput>;
};

export type OrganizationPetParentRelationFilter = {
  is?: Maybe<OrganizationPetParentWhereInput>;
  isNot?: Maybe<OrganizationPetParentWhereInput>;
};

export enum OrganizationPetParentScalarFieldEnum {
  Id = 'id',
  PreferredFirstName = 'preferredFirstName',
  PreferredLastName = 'preferredLastName',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  OrganizationId = 'organizationId',
  ActiveCareStripeSubscriptionId = 'activeCareStripeSubscriptionId',
  StripeCustomerTableId = 'stripeCustomerTableId'
}

export type OrganizationPetParentScalarWhereInput = {
  AND?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  preferredFirstName?: Maybe<StringFilter>;
  preferredLastName?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  organizationId?: Maybe<StringFilter>;
  activeCareStripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeCustomerTableId?: Maybe<StringNullableFilter>;
};

export type OrganizationPetParentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationPetParentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationPetParentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationPetParentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  preferredFirstName?: Maybe<StringWithAggregatesFilter>;
  preferredLastName?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  organizationId?: Maybe<StringWithAggregatesFilter>;
  activeCareStripeSubscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeCustomerTableId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type OrganizationPetParentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
};

export type OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
};

export type OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerNestedInput>;
};

export type OrganizationPetParentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<OrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<OrganizationPetParentUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<OrganizationPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<OrganizationPetParentUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutActiveCareStripeSubscriptionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutActiveCareStripeSubscriptionInput>;
};

export type OrganizationPetParentUncheckedUpdateWithoutActiveCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerNestedInput>;
};

export type OrganizationPetParentUncheckedUpdateWithoutAllCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
};

export type OrganizationPetParentUncheckedUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerNestedInput>;
};

export type OrganizationPetParentUncheckedUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerNestedInput>;
};

export type OrganizationPetParentUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerNestedInput>;
};

export type OrganizationPetParentUncheckedUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerNestedInput>;
};

export type OrganizationPetParentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerNestedInput>;
};

export type OrganizationPetParentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUpdateManyWithWhereWithoutOrganizationInput = {
  where: OrganizationPetParentScalarWhereInput;
  data: OrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentsInput;
};

export type OrganizationPetParentUpdateManyWithWhereWithoutStripeCustomerInput = {
  where: OrganizationPetParentScalarWhereInput;
  data: OrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentsInput;
};

export type OrganizationPetParentUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<OrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<OrganizationPetParentUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetParentUpdateManyWithoutStripeCustomerNestedInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<OrganizationPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<OrganizationPetParentUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutAllCareStripeSubscriptionsInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutAllCareStripeSubscriptionsInput>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutAllCareStripeSubscriptionsInput>;
};

export type OrganizationPetParentUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsNestedInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutOrganizationPetToOrganizationPetParentsInput>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput>;
};

export type OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutActiveCareStripeSubscriptionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutActiveCareStripeSubscriptionInput>;
};

export type OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutClinicPetParentsInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutClinicPetParentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutClinicPetParentsInput>;
};

export type OrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: OrganizationPetParentWhereUniqueInput;
  data: OrganizationPetParentUncheckedUpdateWithoutOrganizationInput;
};

export type OrganizationPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput = {
  where: OrganizationPetParentWhereUniqueInput;
  data: OrganizationPetParentUncheckedUpdateWithoutStripeCustomerInput;
};

export type OrganizationPetParentUpdateWithoutActiveCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerNestedInput>;
};

export type OrganizationPetParentUpdateWithoutAllCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsNestedInput>;
};

export type OrganizationPetParentUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerNestedInput>;
};

export type OrganizationPetParentUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerNestedInput>;
};

export type OrganizationPetParentUpdateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerNestedInput>;
};

export type OrganizationPetParentUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerNestedInput>;
};

export type OrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: OrganizationPetParentWhereUniqueInput;
  update: OrganizationPetParentUncheckedUpdateWithoutOrganizationInput;
  create: OrganizationPetParentUncheckedCreateWithoutOrganizationInput;
};

export type OrganizationPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput = {
  where: OrganizationPetParentWhereUniqueInput;
  update: OrganizationPetParentUncheckedUpdateWithoutStripeCustomerInput;
  create: OrganizationPetParentUncheckedCreateWithoutStripeCustomerInput;
};

export type OrganizationPetParentUpsertWithoutActiveCareStripeSubscriptionInput = {
  update: OrganizationPetParentUncheckedUpdateWithoutActiveCareStripeSubscriptionInput;
  create: OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput;
};

export type OrganizationPetParentUpsertWithoutAllCareStripeSubscriptionsInput = {
  update: OrganizationPetParentUncheckedUpdateWithoutAllCareStripeSubscriptionsInput;
  create: OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput;
};

export type OrganizationPetParentUpsertWithoutClinicPetParentsInput = {
  update: OrganizationPetParentUncheckedUpdateWithoutClinicPetParentsInput;
  create: OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput;
};

export type OrganizationPetParentUpsertWithoutOrganizationPetToOrganizationPetParentsInput = {
  update: OrganizationPetParentUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput;
  create: OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetParentWhereInput = {
  AND?: Maybe<Array<OrganizationPetParentWhereInput>>;
  OR?: Maybe<Array<OrganizationPetParentWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetParentWhereInput>>;
  id?: Maybe<StringFilter>;
  preferredFirstName?: Maybe<StringFilter>;
  preferredLastName?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  organizationId?: Maybe<StringFilter>;
  activeCareStripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeCustomerTableId?: Maybe<StringNullableFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentListRelationFilter>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  stripeCustomer?: Maybe<StripeCustomerWhereInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionListRelationFilter>;
};

export type OrganizationPetParentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type OrganizationPetRelationFilter = {
  is?: Maybe<OrganizationPetWhereInput>;
  isNot?: Maybe<OrganizationPetWhereInput>;
};

export enum OrganizationPetScalarFieldEnum {
  Id = 'id',
  PreferredName = 'preferredName',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ProfilePictureUrl = 'profilePictureUrl',
  OrganizationId = 'organizationId'
}

export type OrganizationPetScalarWhereInput = {
  AND?: Maybe<Array<OrganizationPetScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationPetScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  preferredName?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  profilePictureUrl?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringFilter>;
};

export type OrganizationPetScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationPetScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationPetScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationPetScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  preferredName?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  profilePictureUrl?: Maybe<StringNullableWithAggregatesFilter>;
  organizationId?: Maybe<StringWithAggregatesFilter>;
};

export type OrganizationPetToOrganizationPetParent = {
  __typename?: 'OrganizationPetToOrganizationPetParent';
  organizationPetId: Scalars['String'];
  organizationPetParentId: Scalars['String'];
  organizationPet: OrganizationPet;
  organizationPetParent: OrganizationPetParent;
};

export type OrganizationPetToOrganizationPetParentCountAggregateOutputType = {
  __typename?: 'OrganizationPetToOrganizationPetParentCountAggregateOutputType';
  organizationPetId: Scalars['Int'];
  organizationPetParentId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OrganizationPetToOrganizationPetParentCountOrderByAggregateInput = {
  organizationPetId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
};

export type OrganizationPetToOrganizationPetParentCreateInput = {
  organizationPet: OrganizationPetCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput;
  organizationPetParent: OrganizationPetParentCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetToOrganizationPetParentCreateManyInput = {
  organizationPetId: Scalars['String'];
  organizationPetParentId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInput = {
  organizationPetParentId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope = {
  data: Array<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInput = {
  organizationPetId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope = {
  data: Array<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
  create: OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetInput;
};

export type OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
  create: OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetParentInput;
};

export type OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput = {
  organizationPetParent: OrganizationPetParentCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput = {
  organizationPet: OrganizationPetCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetToOrganizationPetParentListRelationFilter = {
  every?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  some?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  none?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
};

export type OrganizationPetToOrganizationPetParentMaxAggregateOutputType = {
  __typename?: 'OrganizationPetToOrganizationPetParentMaxAggregateOutputType';
  organizationPetId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentMaxOrderByAggregateInput = {
  organizationPetId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
};

export type OrganizationPetToOrganizationPetParentMinAggregateOutputType = {
  __typename?: 'OrganizationPetToOrganizationPetParentMinAggregateOutputType';
  organizationPetId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentMinOrderByAggregateInput = {
  organizationPetId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
};

export type OrganizationPetToOrganizationPetParentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum OrganizationPetToOrganizationPetParentOrderByRelevanceFieldEnum {
  OrganizationPetId = 'organizationPetId',
  OrganizationPetParentId = 'organizationPetParentId'
}

export type OrganizationPetToOrganizationPetParentOrderByRelevanceInput = {
  fields: Array<OrganizationPetToOrganizationPetParentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentOrderByWithAggregationInput = {
  organizationPetId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  _count?: Maybe<OrganizationPetToOrganizationPetParentCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationPetToOrganizationPetParentMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationPetToOrganizationPetParentMinOrderByAggregateInput>;
};

export type OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput = {
  organizationPetId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  organizationPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  organizationPetParent?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<OrganizationPetToOrganizationPetParentOrderByRelevanceInput>;
};

export type OrganizationPetToOrganizationPetParentOrganizationPetIdOrganizationPetParentIdCompoundUniqueInput = {
  organizationPetId: Scalars['String'];
  organizationPetParentId: Scalars['String'];
};

export enum OrganizationPetToOrganizationPetParentScalarFieldEnum {
  OrganizationPetId = 'organizationPetId',
  OrganizationPetParentId = 'organizationPetParentId'
}

export type OrganizationPetToOrganizationPetParentScalarWhereInput = {
  AND?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
  organizationPetId?: Maybe<StringFilter>;
  organizationPetParentId?: Maybe<StringFilter>;
};

export type OrganizationPetToOrganizationPetParentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereWithAggregatesInput>>;
  organizationPetId?: Maybe<StringWithAggregatesFilter>;
  organizationPetParentId?: Maybe<StringWithAggregatesFilter>;
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateInput = {
  organizationPetId: Scalars['String'];
  organizationPetParentId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetInput = {
  organizationPetParentId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetParentInput = {
  organizationPetId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateInput = {
  organizationPetId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateManyInput = {
  organizationPetId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  upsert?: Maybe<Array<OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope>;
  set?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput>>;
  updateMany?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput>>;
  deleteMany?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetToOrganizationPetParentsInput = {
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetInput = {
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetParentInput = {
  organizationPetId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUpdateInput = {
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsNestedInput>;
};

export type OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetInput = {
  where: OrganizationPetToOrganizationPetParentScalarWhereInput;
  data: OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput = {
  where: OrganizationPetToOrganizationPetParentScalarWhereInput;
  data: OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentNestedInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  upsert?: Maybe<Array<OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope>;
  set?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput>>;
  updateMany?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput>>;
  deleteMany?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetInput = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
  data: OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetInput;
};

export type OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
  data: OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetParentInput;
};

export type OrganizationPetToOrganizationPetParentUpdateWithoutOrganizationPetInput = {
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsNestedInput>;
};

export type OrganizationPetToOrganizationPetParentUpdateWithoutOrganizationPetParentInput = {
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsNestedInput>;
};

export type OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetInput = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
  update: OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetInput;
  create: OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetInput;
};

export type OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
  update: OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetParentInput;
  create: OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetParentInput;
};

export type OrganizationPetToOrganizationPetParentWhereInput = {
  AND?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereInput>>;
  OR?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereInput>>;
  organizationPetId?: Maybe<StringFilter>;
  organizationPetParentId?: Maybe<StringFilter>;
  organizationPet?: Maybe<OrganizationPetWhereInput>;
  organizationPetParent?: Maybe<OrganizationPetParentWhereInput>;
};

export type OrganizationPetToOrganizationPetParentWhereUniqueInput = {
  organizationPetId_organizationPetParentId?: Maybe<OrganizationPetToOrganizationPetParentOrganizationPetIdOrganizationPetParentIdCompoundUniqueInput>;
};

export type OrganizationPetUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<OrganizationPetCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
};

export type OrganizationPetUncheckedCreateWithoutAssociatedBenefitUsageFollowupInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetUncheckedCreateWithoutAssociatedSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetUncheckedCreateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetUncheckedCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetUncheckedCreateWithoutTransferFromPetEnrollmentSupportEventInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
};

export type OrganizationPetUncheckedCreateWithoutTransferToPetEnrollmentSupportEventInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
};

export type OrganizationPetUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

export type OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<OrganizationPetCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<OrganizationPetUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<OrganizationPetUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<OrganizationPetScalarWhereInput>>;
};

export type OrganizationPetUncheckedUpdateManyWithoutOrganizationPetsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type OrganizationPetUncheckedUpdateWithoutAssociatedBenefitUsageFollowupInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUncheckedUpdateWithoutAssociatedSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUncheckedUpdateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUncheckedUpdateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUncheckedUpdateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUncheckedUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUncheckedUpdateWithoutTransferFromPetEnrollmentSupportEventInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUncheckedUpdateWithoutTransferToPetEnrollmentSupportEventInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
};

export type OrganizationPetUpdateInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type OrganizationPetUpdateManyWithWhereWithoutOrganizationInput = {
  where: OrganizationPetScalarWhereInput;
  data: OrganizationPetUncheckedUpdateManyWithoutOrganizationPetsInput;
};

export type OrganizationPetUpdateManyWithoutOrganizationNestedInput = {
  create?: Maybe<Array<OrganizationPetCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<OrganizationPetUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<OrganizationPetUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<OrganizationPetScalarWhereInput>>;
};

export type OrganizationPetUpdateOneRequiredWithoutAssociatedBenefitUsageFollowupNestedInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutAssociatedBenefitUsageFollowupInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutAssociatedBenefitUsageFollowupInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutAssociatedBenefitUsageFollowupInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutAssociatedBenefitUsageFollowupInput>;
};

export type OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsNestedInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutCareAddonEnrollmentsInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutCareAddonEnrollmentsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutCareAddonEnrollmentsInput>;
};

export type OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutCarePlanEnrollmentsInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutCarePlanEnrollmentsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutCarePlanEnrollmentsInput>;
};

export type OrganizationPetUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsNestedInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutOrganizationPetToOrganizationPetParentsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput>;
};

export type OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutAssociatedSubscriptionsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutAssociatedSubscriptionsInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutAssociatedSubscriptionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutAssociatedSubscriptionsInput>;
};

export type OrganizationPetUpdateOneWithoutClinicPetsNestedInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutClinicPetsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutClinicPetsInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutClinicPetsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutClinicPetsInput>;
};

export type OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutTransferFromPetEnrollmentSupportEventInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutTransferFromPetEnrollmentSupportEventInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutTransferFromPetEnrollmentSupportEventInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutTransferFromPetEnrollmentSupportEventInput>;
};

export type OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutTransferToPetEnrollmentSupportEventInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutTransferToPetEnrollmentSupportEventInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutTransferToPetEnrollmentSupportEventInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutTransferToPetEnrollmentSupportEventInput>;
};

export type OrganizationPetUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: OrganizationPetWhereUniqueInput;
  data: OrganizationPetUncheckedUpdateWithoutOrganizationInput;
};

export type OrganizationPetUpdateWithoutAssociatedBenefitUsageFollowupInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUpdateWithoutAssociatedSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUpdateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUpdateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUpdateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUpdateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUpdateWithoutTransferFromPetEnrollmentSupportEventInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
};

export type OrganizationPetUpdateWithoutTransferToPetEnrollmentSupportEventInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
};

export type OrganizationPetUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: OrganizationPetWhereUniqueInput;
  update: OrganizationPetUncheckedUpdateWithoutOrganizationInput;
  create: OrganizationPetUncheckedCreateWithoutOrganizationInput;
};

export type OrganizationPetUpsertWithoutAssociatedBenefitUsageFollowupInput = {
  update: OrganizationPetUncheckedUpdateWithoutAssociatedBenefitUsageFollowupInput;
  create: OrganizationPetUncheckedCreateWithoutAssociatedBenefitUsageFollowupInput;
};

export type OrganizationPetUpsertWithoutAssociatedSubscriptionsInput = {
  update: OrganizationPetUncheckedUpdateWithoutAssociatedSubscriptionsInput;
  create: OrganizationPetUncheckedCreateWithoutAssociatedSubscriptionsInput;
};

export type OrganizationPetUpsertWithoutCareAddonEnrollmentsInput = {
  update: OrganizationPetUncheckedUpdateWithoutCareAddonEnrollmentsInput;
  create: OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput;
};

export type OrganizationPetUpsertWithoutCarePlanEnrollmentsInput = {
  update: OrganizationPetUncheckedUpdateWithoutCarePlanEnrollmentsInput;
  create: OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput;
};

export type OrganizationPetUpsertWithoutClinicPetsInput = {
  update: OrganizationPetUncheckedUpdateWithoutClinicPetsInput;
  create: OrganizationPetUncheckedCreateWithoutClinicPetsInput;
};

export type OrganizationPetUpsertWithoutOrganizationPetToOrganizationPetParentsInput = {
  update: OrganizationPetUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput;
  create: OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetUpsertWithoutTransferFromPetEnrollmentSupportEventInput = {
  update: OrganizationPetUncheckedUpdateWithoutTransferFromPetEnrollmentSupportEventInput;
  create: OrganizationPetUncheckedCreateWithoutTransferFromPetEnrollmentSupportEventInput;
};

export type OrganizationPetUpsertWithoutTransferToPetEnrollmentSupportEventInput = {
  update: OrganizationPetUncheckedUpdateWithoutTransferToPetEnrollmentSupportEventInput;
  create: OrganizationPetUncheckedCreateWithoutTransferToPetEnrollmentSupportEventInput;
};

export type OrganizationPetWhereInput = {
  AND?: Maybe<Array<OrganizationPetWhereInput>>;
  OR?: Maybe<Array<OrganizationPetWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetWhereInput>>;
  id?: Maybe<StringFilter>;
  preferredName?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  profilePictureUrl?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentListRelationFilter>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentListRelationFilter>;
  clinicPets?: Maybe<ClinicPetListRelationFilter>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentListRelationFilter>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupListRelationFilter>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionListRelationFilter>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventListRelationFilter>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventListRelationFilter>;
};

export type OrganizationPetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrganizationRelationFilter = {
  is?: Maybe<OrganizationWhereInput>;
  isNot?: Maybe<OrganizationWhereInput>;
};

export enum OrganizationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  CrmId = 'crmId',
  Configuration = 'configuration',
  PaymentProcessorClinicId = 'paymentProcessorClinicId'
}

export type OrganizationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  crmId?: Maybe<StringNullableWithAggregatesFilter>;
  configuration?: Maybe<JsonWithAggregatesFilter>;
  paymentProcessorClinicId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type OrganizationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationUncheckedCreateWithoutCareAddonPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationUncheckedCreateWithoutCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationUncheckedCreateWithoutCarePlanProviderGroupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationUncheckedCreateWithoutCarePlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationUncheckedCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationUncheckedCreateWithoutEmailCampaignTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationUncheckedCreateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationUncheckedCreateWithoutOrganizationPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput>;
  upsert?: Maybe<OrganizationUpsertWithoutPaymentProcessorClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutPaymentProcessorClinicInput>;
};

export type OrganizationUncheckedUpdateWithoutCareAddonPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUncheckedUpdateWithoutCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUncheckedUpdateWithoutCarePlanProviderGroupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUncheckedUpdateWithoutCarePlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUncheckedUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUncheckedUpdateWithoutEmailCampaignTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUncheckedUpdateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUncheckedUpdateWithoutOrganizationPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUncheckedUpdateWithoutPaymentProcessorClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
};

export type OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutCareBenefitsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCareBenefitsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutCareBenefitsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutCareBenefitsInput>;
};

export type OrganizationUpdateOneRequiredWithoutCarePlansNestedInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutCarePlansInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCarePlansInput>;
  upsert?: Maybe<OrganizationUpsertWithoutCarePlansInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutCarePlansInput>;
};

export type OrganizationUpdateOneRequiredWithoutOrganizationPetParentsNestedInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutOrganizationPetParentsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutOrganizationPetParentsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutOrganizationPetParentsInput>;
};

export type OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutOrganizationPetsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutOrganizationPetsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutOrganizationPetsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutOrganizationPetsInput>;
};

export type OrganizationUpdateOneWithoutCareAddonPackageNestedInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutCareAddonPackageInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCareAddonPackageInput>;
  upsert?: Maybe<OrganizationUpsertWithoutCareAddonPackageInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutCareAddonPackageInput>;
};

export type OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutCarePlanProviderGroupsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCarePlanProviderGroupsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutCarePlanProviderGroupsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutCarePlanProviderGroupsInput>;
};

export type OrganizationUpdateOneWithoutClinicsNestedInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutClinicsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutClinicsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutClinicsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutClinicsInput>;
};

export type OrganizationUpdateOneWithoutEmailCampaignTemplateNestedInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutEmailCampaignTemplateInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutEmailCampaignTemplateInput>;
  upsert?: Maybe<OrganizationUpsertWithoutEmailCampaignTemplateInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutEmailCampaignTemplateInput>;
};

export type OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput>;
  upsert?: Maybe<OrganizationUpsertWithoutPaymentProcessorClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutPaymentProcessorClinicInput>;
};

export type OrganizationUpdateWithoutCareAddonPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUpdateWithoutCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUpdateWithoutCarePlanProviderGroupsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUpdateWithoutCarePlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUpdateWithoutEmailCampaignTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUpdateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUpdateWithoutOrganizationPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUpdateWithoutPaymentProcessorClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['Json']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUpsertWithoutCareAddonPackageInput = {
  update: OrganizationUncheckedUpdateWithoutCareAddonPackageInput;
  create: OrganizationUncheckedCreateWithoutCareAddonPackageInput;
};

export type OrganizationUpsertWithoutCareBenefitsInput = {
  update: OrganizationUncheckedUpdateWithoutCareBenefitsInput;
  create: OrganizationUncheckedCreateWithoutCareBenefitsInput;
};

export type OrganizationUpsertWithoutCarePlanProviderGroupsInput = {
  update: OrganizationUncheckedUpdateWithoutCarePlanProviderGroupsInput;
  create: OrganizationUncheckedCreateWithoutCarePlanProviderGroupsInput;
};

export type OrganizationUpsertWithoutCarePlansInput = {
  update: OrganizationUncheckedUpdateWithoutCarePlansInput;
  create: OrganizationUncheckedCreateWithoutCarePlansInput;
};

export type OrganizationUpsertWithoutClinicsInput = {
  update: OrganizationUncheckedUpdateWithoutClinicsInput;
  create: OrganizationUncheckedCreateWithoutClinicsInput;
};

export type OrganizationUpsertWithoutEmailCampaignTemplateInput = {
  update: OrganizationUncheckedUpdateWithoutEmailCampaignTemplateInput;
  create: OrganizationUncheckedCreateWithoutEmailCampaignTemplateInput;
};

export type OrganizationUpsertWithoutOrganizationPetParentsInput = {
  update: OrganizationUncheckedUpdateWithoutOrganizationPetParentsInput;
  create: OrganizationUncheckedCreateWithoutOrganizationPetParentsInput;
};

export type OrganizationUpsertWithoutOrganizationPetsInput = {
  update: OrganizationUncheckedUpdateWithoutOrganizationPetsInput;
  create: OrganizationUncheckedCreateWithoutOrganizationPetsInput;
};

export type OrganizationUpsertWithoutPaymentProcessorClinicInput = {
  update: OrganizationUncheckedUpdateWithoutPaymentProcessorClinicInput;
  create: OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput;
};

export type OrganizationWhereInput = {
  AND?: Maybe<Array<OrganizationWhereInput>>;
  OR?: Maybe<Array<OrganizationWhereInput>>;
  NOT?: Maybe<Array<OrganizationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  crmId?: Maybe<StringNullableFilter>;
  configuration?: Maybe<JsonFilter>;
  paymentProcessorClinicId?: Maybe<StringNullableFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
  organizationPets?: Maybe<OrganizationPetListRelationFilter>;
  organizationPetParents?: Maybe<OrganizationPetParentListRelationFilter>;
  carePlans?: Maybe<CarePlanListRelationFilter>;
  careBenefits?: Maybe<CareBenefitListRelationFilter>;
  careAddonPackage?: Maybe<CareAddonPackageListRelationFilter>;
  paymentProcessorClinic?: Maybe<ClinicWhereInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupListRelationFilter>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateListRelationFilter>;
};

export type OrganizationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

/** @deprecated - 1.0 */
export type OwnerInfo = {
  __typename?: 'OwnerInfo';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  consultations: Array<Consultation>;
  pets: Array<Pet>;
  user?: Maybe<User>;
  _count: OwnerInfoCountOutputType;
};


/** @deprecated - 1.0 */
export type OwnerInfoConsultationsArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
};


/** @deprecated - 1.0 */
export type OwnerInfoPetsArgs = {
  where?: Maybe<PetWhereInput>;
  orderBy?: Maybe<PetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PetScalarFieldEnum>;
};

export type OwnerInfoCountAggregateOutputType = {
  __typename?: 'OwnerInfoCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  firebaseId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  customerId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OwnerInfoCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
};

export type OwnerInfoCountOutputType = {
  __typename?: 'OwnerInfoCountOutputType';
  consultations: Scalars['Int'];
  pets: Scalars['Int'];
};

export type OwnerInfoCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetParentInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPetParentInput>;
  user?: Maybe<UserCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
};

export type OwnerInfoCreateNestedOneWithoutConsultationsInput = {
  create?: Maybe<OwnerInfoUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutConsultationsInput>;
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
};

export type OwnerInfoCreateNestedOneWithoutPetsInput = {
  create?: Maybe<OwnerInfoUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutPetsInput>;
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
};

export type OwnerInfoCreateNestedOneWithoutUserInput = {
  create?: Maybe<OwnerInfoUncheckedCreateWithoutUserInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutUserInput>;
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
};

export type OwnerInfoCreateOrConnectWithoutConsultationsInput = {
  where: OwnerInfoWhereUniqueInput;
  create: OwnerInfoUncheckedCreateWithoutConsultationsInput;
};

export type OwnerInfoCreateOrConnectWithoutPetsInput = {
  where: OwnerInfoWhereUniqueInput;
  create: OwnerInfoUncheckedCreateWithoutPetsInput;
};

export type OwnerInfoCreateOrConnectWithoutUserInput = {
  where: OwnerInfoWhereUniqueInput;
  create: OwnerInfoUncheckedCreateWithoutUserInput;
};

export type OwnerInfoCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  pets?: Maybe<PetCreateNestedManyWithoutPetParentInput>;
  user?: Maybe<UserCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetParentInput>;
  user?: Maybe<UserCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetParentInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPetParentInput>;
};

export type OwnerInfoMaxAggregateOutputType = {
  __typename?: 'OwnerInfoMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
};

export type OwnerInfoMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
};

export type OwnerInfoMinAggregateOutputType = {
  __typename?: 'OwnerInfoMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
};

export type OwnerInfoMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
};

export enum OwnerInfoOrderByRelevanceFieldEnum {
  Id = 'id',
  FirebaseId = 'firebaseId',
  CustomerId = 'customerId'
}

export type OwnerInfoOrderByRelevanceInput = {
  fields: Array<OwnerInfoOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type OwnerInfoOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  _count?: Maybe<OwnerInfoCountOrderByAggregateInput>;
  _max?: Maybe<OwnerInfoMaxOrderByAggregateInput>;
  _min?: Maybe<OwnerInfoMinOrderByAggregateInput>;
};

export type OwnerInfoOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  consultations?: Maybe<ConsultationOrderByRelationAggregateInput>;
  pets?: Maybe<PetOrderByRelationAggregateInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<OwnerInfoOrderByRelevanceInput>;
};

export type OwnerInfoRelationFilter = {
  is?: Maybe<OwnerInfoWhereInput>;
  isNot?: Maybe<OwnerInfoWhereInput>;
};

export enum OwnerInfoScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  FirebaseId = 'firebaseId',
  UpdatedAt = 'updatedAt',
  CustomerId = 'customerId'
}

export type OwnerInfoScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OwnerInfoScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OwnerInfoScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OwnerInfoScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  customerId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type OwnerInfoUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetParentInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPetParentInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUncheckedCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPetParentInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUncheckedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetParentInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetParentInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPetParentInput>;
};

export type OwnerInfoUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetParentNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPetParentNestedInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutOwnerInfoNestedInput>;
};

export type OwnerInfoUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
};

export type OwnerInfoUncheckedUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPetParentNestedInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutOwnerInfoNestedInput>;
};

export type OwnerInfoUncheckedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetParentNestedInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutOwnerInfoNestedInput>;
};

export type OwnerInfoUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetParentNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPetParentNestedInput>;
};

export type OwnerInfoUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetParentNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPetParentNestedInput>;
  user?: Maybe<UserUpdateOneWithoutOwnerInfoNestedInput>;
};

export type OwnerInfoUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
};

export type OwnerInfoUpdateOneWithoutConsultationsNestedInput = {
  create?: Maybe<OwnerInfoUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutConsultationsInput>;
  upsert?: Maybe<OwnerInfoUpsertWithoutConsultationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
  update?: Maybe<OwnerInfoUncheckedUpdateWithoutConsultationsInput>;
};

export type OwnerInfoUpdateOneWithoutPetsNestedInput = {
  create?: Maybe<OwnerInfoUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutPetsInput>;
  upsert?: Maybe<OwnerInfoUpsertWithoutPetsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
  update?: Maybe<OwnerInfoUncheckedUpdateWithoutPetsInput>;
};

export type OwnerInfoUpdateOneWithoutUserNestedInput = {
  create?: Maybe<OwnerInfoUncheckedCreateWithoutUserInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutUserInput>;
  upsert?: Maybe<OwnerInfoUpsertWithoutUserInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
  update?: Maybe<OwnerInfoUncheckedUpdateWithoutUserInput>;
};

export type OwnerInfoUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUpdateManyWithoutPetParentNestedInput>;
  user?: Maybe<UserUpdateOneWithoutOwnerInfoNestedInput>;
};

export type OwnerInfoUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetParentNestedInput>;
  user?: Maybe<UserUpdateOneWithoutOwnerInfoNestedInput>;
};

export type OwnerInfoUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetParentNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPetParentNestedInput>;
};

export type OwnerInfoUpsertWithoutConsultationsInput = {
  update: OwnerInfoUncheckedUpdateWithoutConsultationsInput;
  create: OwnerInfoUncheckedCreateWithoutConsultationsInput;
};

export type OwnerInfoUpsertWithoutPetsInput = {
  update: OwnerInfoUncheckedUpdateWithoutPetsInput;
  create: OwnerInfoUncheckedCreateWithoutPetsInput;
};

export type OwnerInfoUpsertWithoutUserInput = {
  update: OwnerInfoUncheckedUpdateWithoutUserInput;
  create: OwnerInfoUncheckedCreateWithoutUserInput;
};

export type OwnerInfoWhereInput = {
  AND?: Maybe<Array<OwnerInfoWhereInput>>;
  OR?: Maybe<Array<OwnerInfoWhereInput>>;
  NOT?: Maybe<Array<OwnerInfoWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  customerId?: Maybe<StringNullableFilter>;
  consultations?: Maybe<ConsultationListRelationFilter>;
  pets?: Maybe<PetListRelationFilter>;
  user?: Maybe<UserWhereInput>;
};

export type OwnerInfoWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
};

export type PpcIntegration = {
  __typename?: 'PPCIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type PpcIntegrationCountAggregateOutputType = {
  __typename?: 'PPCIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  ppcLocationId: Scalars['Int'];
  ppcClinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PpcIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
};

export type PpcIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPpcInput>;
};

export type PpcIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<PpcIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<PpcIntegrationWhereUniqueInput>;
};

export type PpcIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: PpcIntegrationWhereUniqueInput;
  create: PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type PpcIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationMaxAggregateOutputType = {
  __typename?: 'PPCIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
};

export type PpcIntegrationMinAggregateOutputType = {
  __typename?: 'PPCIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
};

export enum PpcIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  PpcLocationId = 'ppcLocationId',
  PpcClinicId = 'ppcClinicId'
}

export type PpcIntegrationOrderByRelevanceInput = {
  fields: Array<PpcIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PpcIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
  _count?: Maybe<PpcIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<PpcIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<PpcIntegrationMinOrderByAggregateInput>;
};

export type PpcIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PpcIntegrationOrderByRelevanceInput>;
};

export type PpcIntegrationRelationFilter = {
  is?: Maybe<PpcIntegrationWhereInput>;
  isNot?: Maybe<PpcIntegrationWhereInput>;
};

export enum PpcIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PpcLocationId = 'ppcLocationId',
  PpcClinicId = 'ppcClinicId'
}

export type PpcIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PpcIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PpcIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PpcIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  ppcLocationId?: Maybe<StringNullableWithAggregatesFilter>;
  ppcClinicId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type PpcIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutPpcInput>;
};

export type PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutPpcNestedInput>;
};

export type PpcIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPpcNestedInput>;
};

export type PpcIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  create?: Maybe<PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<PpcIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<PpcIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PpcIntegrationWhereUniqueInput>;
  update?: Maybe<PpcIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type PpcIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: PpcIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type PpcIntegrationWhereInput = {
  AND?: Maybe<Array<PpcIntegrationWhereInput>>;
  OR?: Maybe<Array<PpcIntegrationWhereInput>>;
  NOT?: Maybe<Array<PpcIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  ppcLocationId?: Maybe<StringNullableFilter>;
  ppcClinicId?: Maybe<StringNullableFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type PpcIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  create: StripeCreatePaymentInput;
  clinicPetParentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  invoiceIds?: Maybe<Array<Scalars['String']>>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  emailOverride?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export enum PaymentMedium {
  StripeOnline = 'StripeOnline',
  StripeTerminal = 'StripeTerminal',
  StripeTerminalApp = 'StripeTerminalApp',
  StripeVirtualTerminal = 'StripeVirtualTerminal'
}

export enum PaymentPimsWritebackAction {
  PaymentCollected = 'Payment_Collected',
  CareDiscountApplied = 'Care_Discount_Applied'
}

export type PaymentPimsWritebackLogEntry = {
  __typename?: 'PaymentPimsWritebackLogEntry';
  writebackLogId: Scalars['String'];
  paymentId: Scalars['String'];
  action: PaymentPimsWritebackAction;
  writebackLog: PimsWritebackLogEntry;
  payment: StripePaymentIntent;
};

export type PaymentPimsWritebackLogEntryCountAggregateOutputType = {
  __typename?: 'PaymentPimsWritebackLogEntryCountAggregateOutputType';
  writebackLogId: Scalars['Int'];
  paymentId: Scalars['Int'];
  action: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PaymentPimsWritebackLogEntryCountOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
};

export type PaymentPimsWritebackLogEntryCreateInput = {
  action: PaymentPimsWritebackAction;
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutPaymentLogInput;
  payment: StripePaymentIntentCreateNestedOneWithoutWritebackLogEntriesInput;
};

export type PaymentPimsWritebackLogEntryCreateManyInput = {
  writebackLogId: Scalars['String'];
  paymentId: Scalars['String'];
  action: PaymentPimsWritebackAction;
};

export type PaymentPimsWritebackLogEntryCreateManyPaymentInput = {
  writebackLogId: Scalars['String'];
  action: PaymentPimsWritebackAction;
};

export type PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope = {
  data: Array<PaymentPimsWritebackLogEntryCreateManyPaymentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput = {
  create?: Maybe<Array<PaymentPimsWritebackLogEntryCreateWithoutPaymentInput>>;
  connectOrCreate?: Maybe<Array<PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput>>;
  createMany?: Maybe<PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope>;
  connect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
};

export type PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput = {
  create?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  connect?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
};

export type PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput = {
  where: PaymentPimsWritebackLogEntryWhereUniqueInput;
  create: PaymentPimsWritebackLogEntryUncheckedCreateWithoutPaymentInput;
};

export type PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput = {
  where: PaymentPimsWritebackLogEntryWhereUniqueInput;
  create: PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
};

export type PaymentPimsWritebackLogEntryCreateWithoutPaymentInput = {
  action: PaymentPimsWritebackAction;
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutPaymentLogInput;
};

export type PaymentPimsWritebackLogEntryCreateWithoutWritebackLogInput = {
  action: PaymentPimsWritebackAction;
  payment: StripePaymentIntentCreateNestedOneWithoutWritebackLogEntriesInput;
};

export type PaymentPimsWritebackLogEntryListRelationFilter = {
  every?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
  some?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
  none?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
};

export type PaymentPimsWritebackLogEntryMaxAggregateOutputType = {
  __typename?: 'PaymentPimsWritebackLogEntryMaxAggregateOutputType';
  writebackLogId?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryMaxOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
};

export type PaymentPimsWritebackLogEntryMinAggregateOutputType = {
  __typename?: 'PaymentPimsWritebackLogEntryMinAggregateOutputType';
  writebackLogId?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryMinOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
};

export type PaymentPimsWritebackLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PaymentPimsWritebackLogEntryOrderByRelevanceFieldEnum {
  WritebackLogId = 'writebackLogId',
  PaymentId = 'paymentId'
}

export type PaymentPimsWritebackLogEntryOrderByRelevanceInput = {
  fields: Array<PaymentPimsWritebackLogEntryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PaymentPimsWritebackLogEntryOrderByWithAggregationInput = {
  writebackLogId?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  _count?: Maybe<PaymentPimsWritebackLogEntryCountOrderByAggregateInput>;
  _max?: Maybe<PaymentPimsWritebackLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<PaymentPimsWritebackLogEntryMinOrderByAggregateInput>;
};

export type PaymentPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  writebackLogId?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  writebackLog?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  payment?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PaymentPimsWritebackLogEntryOrderByRelevanceInput>;
};

export type PaymentPimsWritebackLogEntryRelationFilter = {
  is?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
  isNot?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
};

export enum PaymentPimsWritebackLogEntryScalarFieldEnum {
  WritebackLogId = 'writebackLogId',
  PaymentId = 'paymentId',
  Action = 'action'
}

export type PaymentPimsWritebackLogEntryScalarWhereInput = {
  AND?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
  writebackLogId?: Maybe<StringFilter>;
  paymentId?: Maybe<StringFilter>;
  action?: Maybe<EnumPaymentPimsWritebackActionFilter>;
};

export type PaymentPimsWritebackLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  writebackLogId?: Maybe<StringWithAggregatesFilter>;
  paymentId?: Maybe<StringWithAggregatesFilter>;
  action?: Maybe<EnumPaymentPimsWritebackActionWithAggregatesFilter>;
};

export type PaymentPimsWritebackLogEntryUncheckedCreateInput = {
  writebackLogId: Scalars['String'];
  paymentId: Scalars['String'];
  action: PaymentPimsWritebackAction;
};

export type PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput = {
  create?: Maybe<Array<PaymentPimsWritebackLogEntryCreateWithoutPaymentInput>>;
  connectOrCreate?: Maybe<Array<PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput>>;
  createMany?: Maybe<PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope>;
  connect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
};

export type PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput = {
  create?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  connect?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
};

export type PaymentPimsWritebackLogEntryUncheckedCreateWithoutPaymentInput = {
  writebackLogId: Scalars['String'];
  action: PaymentPimsWritebackAction;
};

export type PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput = {
  paymentId: Scalars['String'];
  action: PaymentPimsWritebackAction;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateManyInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput = {
  create?: Maybe<Array<PaymentPimsWritebackLogEntryCreateWithoutPaymentInput>>;
  connectOrCreate?: Maybe<Array<PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput>>;
  upsert?: Maybe<Array<PaymentPimsWritebackLogEntryUpsertWithWhereUniqueWithoutPaymentInput>>;
  createMany?: Maybe<PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope>;
  set?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PaymentPimsWritebackLogEntryUpdateWithWhereUniqueWithoutPaymentInput>>;
  updateMany?: Maybe<Array<PaymentPimsWritebackLogEntryUpdateManyWithWhereWithoutPaymentInput>>;
  deleteMany?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogEntriesInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput = {
  create?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  upsert?: Maybe<PaymentPimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateWithoutPaymentInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput = {
  paymentId?: Maybe<Scalars['String']>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUpdateInput = {
  action?: Maybe<PaymentPimsWritebackAction>;
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutPaymentLogNestedInput>;
  payment?: Maybe<StripePaymentIntentUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
};

export type PaymentPimsWritebackLogEntryUpdateManyMutationInput = {
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUpdateManyWithWhereWithoutPaymentInput = {
  where: PaymentPimsWritebackLogEntryScalarWhereInput;
  data: PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogEntriesInput;
};

export type PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput = {
  create?: Maybe<Array<PaymentPimsWritebackLogEntryCreateWithoutPaymentInput>>;
  connectOrCreate?: Maybe<Array<PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput>>;
  upsert?: Maybe<Array<PaymentPimsWritebackLogEntryUpsertWithWhereUniqueWithoutPaymentInput>>;
  createMany?: Maybe<PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope>;
  set?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PaymentPimsWritebackLogEntryUpdateWithWhereUniqueWithoutPaymentInput>>;
  updateMany?: Maybe<Array<PaymentPimsWritebackLogEntryUpdateManyWithWhereWithoutPaymentInput>>;
  deleteMany?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
};

export type PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput = {
  create?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  upsert?: Maybe<PaymentPimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
};

export type PaymentPimsWritebackLogEntryUpdateWithWhereUniqueWithoutPaymentInput = {
  where: PaymentPimsWritebackLogEntryWhereUniqueInput;
  data: PaymentPimsWritebackLogEntryUncheckedUpdateWithoutPaymentInput;
};

export type PaymentPimsWritebackLogEntryUpdateWithoutPaymentInput = {
  action?: Maybe<PaymentPimsWritebackAction>;
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutPaymentLogNestedInput>;
};

export type PaymentPimsWritebackLogEntryUpdateWithoutWritebackLogInput = {
  action?: Maybe<PaymentPimsWritebackAction>;
  payment?: Maybe<StripePaymentIntentUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
};

export type PaymentPimsWritebackLogEntryUpsertWithWhereUniqueWithoutPaymentInput = {
  where: PaymentPimsWritebackLogEntryWhereUniqueInput;
  update: PaymentPimsWritebackLogEntryUncheckedUpdateWithoutPaymentInput;
  create: PaymentPimsWritebackLogEntryUncheckedCreateWithoutPaymentInput;
};

export type PaymentPimsWritebackLogEntryUpsertWithoutWritebackLogInput = {
  update: PaymentPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput;
  create: PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
};

export type PaymentPimsWritebackLogEntryWhereInput = {
  AND?: Maybe<Array<PaymentPimsWritebackLogEntryWhereInput>>;
  OR?: Maybe<Array<PaymentPimsWritebackLogEntryWhereInput>>;
  NOT?: Maybe<Array<PaymentPimsWritebackLogEntryWhereInput>>;
  writebackLogId?: Maybe<StringFilter>;
  paymentId?: Maybe<StringFilter>;
  action?: Maybe<EnumPaymentPimsWritebackActionFilter>;
  writebackLog?: Maybe<PimsWritebackLogEntryWhereInput>;
  payment?: Maybe<StripePaymentIntentWhereInput>;
};

export type PaymentPimsWritebackLogEntryWhereUniqueInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  writebackLogId_paymentId?: Maybe<PaymentPimsWritebackLogEntryWritebackLogIdPaymentIdCompoundUniqueInput>;
};

export type PaymentPimsWritebackLogEntryWritebackLogIdPaymentIdCompoundUniqueInput = {
  writebackLogId: Scalars['String'];
  paymentId: Scalars['String'];
};

export enum PaymentRelationship {
  AccountBalance = 'AccountBalance',
  CustomAmount = 'CustomAmount'
}

export enum PaymentSchedule {
  Full = 'Full',
  OverTime = 'OverTime'
}

export enum PaymentSource {
  Braintree = 'Braintree',
  Stripe = 'Stripe'
}

export type PaymentsMade = {
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  total: Scalars['Float'];
};

export type PayoutBatchingPeriod = {
  __typename?: 'PayoutBatchingPeriod';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdByUser?: Maybe<User>;
  clinic: Clinic;
  stripePayout?: Maybe<StripePayout>;
};

export type PayoutBatchingPeriodClinicIdStartsAtEndsAtCompoundUniqueInput = {
  clinicId: Scalars['String'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
};

export type PayoutBatchingPeriodCountAggregateOutputType = {
  __typename?: 'PayoutBatchingPeriodCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  startsAt: Scalars['Int'];
  endsAt: Scalars['Int'];
  createdByUserId: Scalars['Int'];
  clinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PayoutBatchingPeriodCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type PayoutBatchingPeriodCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  createdByUser?: Maybe<UserCreateNestedOneWithoutCreatedPayoutBatchingPeriodsInput>;
  clinic: ClinicCreateNestedOneWithoutPayoutBatchingPeriodsInput;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutPayoutBatchingPeriodInput>;
};

export type PayoutBatchingPeriodCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
};

export type PayoutBatchingPeriodCreateManyClinicInputEnvelope = {
  data: Array<PayoutBatchingPeriodCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayoutBatchingPeriodCreateManyCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
};

export type PayoutBatchingPeriodCreateManyCreatedByUserInputEnvelope = {
  data: Array<PayoutBatchingPeriodCreateManyCreatedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayoutBatchingPeriodCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
};

export type PayoutBatchingPeriodCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
};

export type PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput = {
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutCreatedByUserInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyCreatedByUserInputEnvelope>;
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
};

export type PayoutBatchingPeriodCreateNestedOneWithoutStripePayoutInput = {
  create?: Maybe<PayoutBatchingPeriodUncheckedCreateWithoutStripePayoutInput>;
  connectOrCreate?: Maybe<PayoutBatchingPeriodCreateOrConnectWithoutStripePayoutInput>;
  connect?: Maybe<PayoutBatchingPeriodWhereUniqueInput>;
};

export type PayoutBatchingPeriodCreateOrConnectWithoutClinicInput = {
  where: PayoutBatchingPeriodWhereUniqueInput;
  create: PayoutBatchingPeriodUncheckedCreateWithoutClinicInput;
};

export type PayoutBatchingPeriodCreateOrConnectWithoutCreatedByUserInput = {
  where: PayoutBatchingPeriodWhereUniqueInput;
  create: PayoutBatchingPeriodUncheckedCreateWithoutCreatedByUserInput;
};

export type PayoutBatchingPeriodCreateOrConnectWithoutStripePayoutInput = {
  where: PayoutBatchingPeriodWhereUniqueInput;
  create: PayoutBatchingPeriodUncheckedCreateWithoutStripePayoutInput;
};

export type PayoutBatchingPeriodCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  createdByUser?: Maybe<UserCreateNestedOneWithoutCreatedPayoutBatchingPeriodsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutPayoutBatchingPeriodInput>;
};

export type PayoutBatchingPeriodCreateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  clinic: ClinicCreateNestedOneWithoutPayoutBatchingPeriodsInput;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutPayoutBatchingPeriodInput>;
};

export type PayoutBatchingPeriodCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  createdByUser?: Maybe<UserCreateNestedOneWithoutCreatedPayoutBatchingPeriodsInput>;
  clinic: ClinicCreateNestedOneWithoutPayoutBatchingPeriodsInput;
};

export type PayoutBatchingPeriodListRelationFilter = {
  every?: Maybe<PayoutBatchingPeriodWhereInput>;
  some?: Maybe<PayoutBatchingPeriodWhereInput>;
  none?: Maybe<PayoutBatchingPeriodWhereInput>;
};

export type PayoutBatchingPeriodMaxAggregateOutputType = {
  __typename?: 'PayoutBatchingPeriodMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type PayoutBatchingPeriodMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type PayoutBatchingPeriodMinAggregateOutputType = {
  __typename?: 'PayoutBatchingPeriodMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type PayoutBatchingPeriodMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type PayoutBatchingPeriodOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PayoutBatchingPeriodOrderByRelevanceFieldEnum {
  Id = 'id',
  CreatedByUserId = 'createdByUserId',
  ClinicId = 'clinicId'
}

export type PayoutBatchingPeriodOrderByRelevanceInput = {
  fields: Array<PayoutBatchingPeriodOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PayoutBatchingPeriodOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  _count?: Maybe<PayoutBatchingPeriodCountOrderByAggregateInput>;
  _max?: Maybe<PayoutBatchingPeriodMaxOrderByAggregateInput>;
  _min?: Maybe<PayoutBatchingPeriodMinOrderByAggregateInput>;
};

export type PayoutBatchingPeriodOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  stripePayout?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PayoutBatchingPeriodOrderByRelevanceInput>;
};

export type PayoutBatchingPeriodRelationFilter = {
  is?: Maybe<PayoutBatchingPeriodWhereInput>;
  isNot?: Maybe<PayoutBatchingPeriodWhereInput>;
};

export enum PayoutBatchingPeriodScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StartsAt = 'startsAt',
  EndsAt = 'endsAt',
  CreatedByUserId = 'createdByUserId',
  ClinicId = 'clinicId'
}

export type PayoutBatchingPeriodScalarWhereInput = {
  AND?: Maybe<Array<PayoutBatchingPeriodScalarWhereInput>>;
  OR?: Maybe<Array<PayoutBatchingPeriodScalarWhereInput>>;
  NOT?: Maybe<Array<PayoutBatchingPeriodScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  createdByUserId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
};

export type PayoutBatchingPeriodScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PayoutBatchingPeriodScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PayoutBatchingPeriodScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PayoutBatchingPeriodScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  startsAt?: Maybe<DateTimeWithAggregatesFilter>;
  endsAt?: Maybe<DateTimeWithAggregatesFilter>;
  createdByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
};

export type PayoutBatchingPeriodUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  stripePayout?: Maybe<StripePayoutUncheckedCreateNestedOneWithoutPayoutBatchingPeriodInput>;
};

export type PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
};

export type PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput = {
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutCreatedByUserInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyCreatedByUserInputEnvelope>;
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
};

export type PayoutBatchingPeriodUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  stripePayout?: Maybe<StripePayoutUncheckedCreateNestedOneWithoutPayoutBatchingPeriodInput>;
};

export type PayoutBatchingPeriodUncheckedCreateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  stripePayout?: Maybe<StripePayoutUncheckedCreateNestedOneWithoutPayoutBatchingPeriodInput>;
};

export type PayoutBatchingPeriodUncheckedCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
};

export type PayoutBatchingPeriodUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  stripePayout?: Maybe<StripePayoutUncheckedUpdateOneWithoutPayoutBatchingPeriodNestedInput>;
};

export type PayoutBatchingPeriodUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<PayoutBatchingPeriodUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  disconnect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  delete?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  update?: Maybe<Array<PayoutBatchingPeriodUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PayoutBatchingPeriodUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<PayoutBatchingPeriodScalarWhereInput>>;
};

export type PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput = {
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutCreatedByUserInput>>;
  upsert?: Maybe<Array<PayoutBatchingPeriodUpsertWithWhereUniqueWithoutCreatedByUserInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyCreatedByUserInputEnvelope>;
  set?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  disconnect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  delete?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  update?: Maybe<Array<PayoutBatchingPeriodUpdateWithWhereUniqueWithoutCreatedByUserInput>>;
  updateMany?: Maybe<Array<PayoutBatchingPeriodUpdateManyWithWhereWithoutCreatedByUserInput>>;
  deleteMany?: Maybe<Array<PayoutBatchingPeriodScalarWhereInput>>;
};

export type PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedPayoutBatchingPeriodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type PayoutBatchingPeriodUncheckedUpdateManyWithoutPayoutBatchingPeriodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
};

export type PayoutBatchingPeriodUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  stripePayout?: Maybe<StripePayoutUncheckedUpdateOneWithoutPayoutBatchingPeriodNestedInput>;
};

export type PayoutBatchingPeriodUncheckedUpdateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  stripePayout?: Maybe<StripePayoutUncheckedUpdateOneWithoutPayoutBatchingPeriodNestedInput>;
};

export type PayoutBatchingPeriodUncheckedUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type PayoutBatchingPeriodUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutCreatedPayoutBatchingPeriodsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPayoutBatchingPeriodsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutPayoutBatchingPeriodNestedInput>;
};

export type PayoutBatchingPeriodUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUpdateManyWithWhereWithoutClinicInput = {
  where: PayoutBatchingPeriodScalarWhereInput;
  data: PayoutBatchingPeriodUncheckedUpdateManyWithoutPayoutBatchingPeriodsInput;
};

export type PayoutBatchingPeriodUpdateManyWithWhereWithoutCreatedByUserInput = {
  where: PayoutBatchingPeriodScalarWhereInput;
  data: PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedPayoutBatchingPeriodsInput;
};

export type PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<PayoutBatchingPeriodUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  disconnect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  delete?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  update?: Maybe<Array<PayoutBatchingPeriodUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PayoutBatchingPeriodUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<PayoutBatchingPeriodScalarWhereInput>>;
};

export type PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput = {
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutCreatedByUserInput>>;
  upsert?: Maybe<Array<PayoutBatchingPeriodUpsertWithWhereUniqueWithoutCreatedByUserInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyCreatedByUserInputEnvelope>;
  set?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  disconnect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  delete?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  update?: Maybe<Array<PayoutBatchingPeriodUpdateWithWhereUniqueWithoutCreatedByUserInput>>;
  updateMany?: Maybe<Array<PayoutBatchingPeriodUpdateManyWithWhereWithoutCreatedByUserInput>>;
  deleteMany?: Maybe<Array<PayoutBatchingPeriodScalarWhereInput>>;
};

export type PayoutBatchingPeriodUpdateOneWithoutStripePayoutNestedInput = {
  create?: Maybe<PayoutBatchingPeriodUncheckedCreateWithoutStripePayoutInput>;
  connectOrCreate?: Maybe<PayoutBatchingPeriodCreateOrConnectWithoutStripePayoutInput>;
  upsert?: Maybe<PayoutBatchingPeriodUpsertWithoutStripePayoutInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PayoutBatchingPeriodWhereUniqueInput>;
  update?: Maybe<PayoutBatchingPeriodUncheckedUpdateWithoutStripePayoutInput>;
};

export type PayoutBatchingPeriodUpdateWithWhereUniqueWithoutClinicInput = {
  where: PayoutBatchingPeriodWhereUniqueInput;
  data: PayoutBatchingPeriodUncheckedUpdateWithoutClinicInput;
};

export type PayoutBatchingPeriodUpdateWithWhereUniqueWithoutCreatedByUserInput = {
  where: PayoutBatchingPeriodWhereUniqueInput;
  data: PayoutBatchingPeriodUncheckedUpdateWithoutCreatedByUserInput;
};

export type PayoutBatchingPeriodUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutCreatedPayoutBatchingPeriodsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutPayoutBatchingPeriodNestedInput>;
};

export type PayoutBatchingPeriodUpdateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPayoutBatchingPeriodsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutPayoutBatchingPeriodNestedInput>;
};

export type PayoutBatchingPeriodUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutCreatedPayoutBatchingPeriodsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPayoutBatchingPeriodsNestedInput>;
};

export type PayoutBatchingPeriodUpsertWithWhereUniqueWithoutClinicInput = {
  where: PayoutBatchingPeriodWhereUniqueInput;
  update: PayoutBatchingPeriodUncheckedUpdateWithoutClinicInput;
  create: PayoutBatchingPeriodUncheckedCreateWithoutClinicInput;
};

export type PayoutBatchingPeriodUpsertWithWhereUniqueWithoutCreatedByUserInput = {
  where: PayoutBatchingPeriodWhereUniqueInput;
  update: PayoutBatchingPeriodUncheckedUpdateWithoutCreatedByUserInput;
  create: PayoutBatchingPeriodUncheckedCreateWithoutCreatedByUserInput;
};

export type PayoutBatchingPeriodUpsertWithoutStripePayoutInput = {
  update: PayoutBatchingPeriodUncheckedUpdateWithoutStripePayoutInput;
  create: PayoutBatchingPeriodUncheckedCreateWithoutStripePayoutInput;
};

export type PayoutBatchingPeriodWhereInput = {
  AND?: Maybe<Array<PayoutBatchingPeriodWhereInput>>;
  OR?: Maybe<Array<PayoutBatchingPeriodWhereInput>>;
  NOT?: Maybe<Array<PayoutBatchingPeriodWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  createdByUserId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  createdByUser?: Maybe<UserWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  stripePayout?: Maybe<StripePayoutWhereInput>;
};

export type PayoutBatchingPeriodWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicId_startsAt_endsAt?: Maybe<PayoutBatchingPeriodClinicIdStartsAtEndsAtCompoundUniqueInput>;
};

export type PerformCarePlanEnrollmentUpgradeInput = {
  carePlanEnrollmentId: Scalars['String'];
  newPlanId: Scalars['String'];
  feeAmount: Scalars['Float'];
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type PerformCarePlanEnrollmentUpgradeOutput = {
  __typename?: 'PerformCarePlanEnrollmentUpgradeOutput';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  upgradeInvoiceId?: Maybe<Scalars['String']>;
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  type: PermissionType;
  updatedAt: Scalars['DateTime'];
  roles: Array<StaffRole>;
  _count: PermissionCountOutputType;
};


export type PermissionRolesArgs = {
  where?: Maybe<StaffRoleWhereInput>;
  orderBy?: Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StaffRoleScalarFieldEnum>;
};

export type PermissionCountAggregateOutputType = {
  __typename?: 'PermissionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PermissionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PermissionCountOutputType = {
  __typename?: 'PermissionCountOutputType';
  roles: Scalars['Int'];
};

export type PermissionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutPermissionsInput>;
};

export type PermissionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionCreateNestedManyWithoutRolesInput = {
  create?: Maybe<Array<PermissionCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<PermissionCreateOrConnectWithoutRolesInput>>;
  connect?: Maybe<Array<PermissionWhereUniqueInput>>;
};

export type PermissionCreateOrConnectWithoutRolesInput = {
  where: PermissionWhereUniqueInput;
  create: PermissionUncheckedCreateWithoutRolesInput;
};

export type PermissionCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionListRelationFilter = {
  every?: Maybe<PermissionWhereInput>;
  some?: Maybe<PermissionWhereInput>;
  none?: Maybe<PermissionWhereInput>;
};

export type PermissionMaxAggregateOutputType = {
  __typename?: 'PermissionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PermissionMinAggregateOutputType = {
  __typename?: 'PermissionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PermissionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PermissionOrderByRelevanceFieldEnum {
  Id = 'id'
}

export type PermissionOrderByRelevanceInput = {
  fields: Array<PermissionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PermissionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<PermissionCountOrderByAggregateInput>;
  _max?: Maybe<PermissionMaxOrderByAggregateInput>;
  _min?: Maybe<PermissionMinOrderByAggregateInput>;
};

export type PermissionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  roles?: Maybe<StaffRoleOrderByRelationAggregateInput>;
  _relevance?: Maybe<PermissionOrderByRelevanceInput>;
};

export enum PermissionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type PermissionScalarWhereInput = {
  AND?: Maybe<Array<PermissionScalarWhereInput>>;
  OR?: Maybe<Array<PermissionScalarWhereInput>>;
  NOT?: Maybe<Array<PermissionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumPermissionTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PermissionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PermissionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PermissionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PermissionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  type?: Maybe<EnumPermissionTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum PermissionType {
  ChangeClinicPricing = 'ChangeClinicPricing',
  ReadPet = 'ReadPet',
  WritePet = 'WritePet',
  WriteClinic = 'WriteClinic',
  ReadConsultation = 'ReadConsultation',
  WriteConsultation = 'WriteConsultation',
  ReadUser = 'ReadUser',
  WriteUser = 'WriteUser',
  ReadOrganization = 'ReadOrganization',
  WriteOrganization = 'WriteOrganization',
  ReadAppointment = 'ReadAppointment',
  WriteAppointment = 'WriteAppointment',
  ReadChannel = 'ReadChannel',
  WriteChannel = 'WriteChannel',
  ReadClinicPetParent = 'ReadClinicPetParent',
  WriteClinicPetParent = 'WriteClinicPetParent',
  ManageChannelMessage = 'ManageChannelMessage'
}

export type PermissionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutPermissionsInput>;
};

export type PermissionUncheckedCreateNestedManyWithoutRolesInput = {
  create?: Maybe<Array<PermissionCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<PermissionCreateOrConnectWithoutRolesInput>>;
  connect?: Maybe<Array<PermissionWhereUniqueInput>>;
};

export type PermissionUncheckedCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutPermissionsNestedInput>;
};

export type PermissionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUncheckedUpdateManyWithoutPermissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUncheckedUpdateManyWithoutRolesNestedInput = {
  create?: Maybe<Array<PermissionCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<PermissionCreateOrConnectWithoutRolesInput>>;
  upsert?: Maybe<Array<PermissionUpsertWithWhereUniqueWithoutRolesInput>>;
  set?: Maybe<Array<PermissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PermissionWhereUniqueInput>>;
  delete?: Maybe<Array<PermissionWhereUniqueInput>>;
  connect?: Maybe<Array<PermissionWhereUniqueInput>>;
  update?: Maybe<Array<PermissionUpdateWithWhereUniqueWithoutRolesInput>>;
  updateMany?: Maybe<Array<PermissionUpdateManyWithWhereWithoutRolesInput>>;
  deleteMany?: Maybe<Array<PermissionScalarWhereInput>>;
};

export type PermissionUncheckedUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutPermissionsNestedInput>;
};

export type PermissionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUpdateManyWithWhereWithoutRolesInput = {
  where: PermissionScalarWhereInput;
  data: PermissionUncheckedUpdateManyWithoutPermissionsInput;
};

export type PermissionUpdateManyWithoutRolesNestedInput = {
  create?: Maybe<Array<PermissionCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<PermissionCreateOrConnectWithoutRolesInput>>;
  upsert?: Maybe<Array<PermissionUpsertWithWhereUniqueWithoutRolesInput>>;
  set?: Maybe<Array<PermissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PermissionWhereUniqueInput>>;
  delete?: Maybe<Array<PermissionWhereUniqueInput>>;
  connect?: Maybe<Array<PermissionWhereUniqueInput>>;
  update?: Maybe<Array<PermissionUpdateWithWhereUniqueWithoutRolesInput>>;
  updateMany?: Maybe<Array<PermissionUpdateManyWithWhereWithoutRolesInput>>;
  deleteMany?: Maybe<Array<PermissionScalarWhereInput>>;
};

export type PermissionUpdateWithWhereUniqueWithoutRolesInput = {
  where: PermissionWhereUniqueInput;
  data: PermissionUncheckedUpdateWithoutRolesInput;
};

export type PermissionUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUpsertWithWhereUniqueWithoutRolesInput = {
  where: PermissionWhereUniqueInput;
  update: PermissionUncheckedUpdateWithoutRolesInput;
  create: PermissionUncheckedCreateWithoutRolesInput;
};

export type PermissionWhereInput = {
  AND?: Maybe<Array<PermissionWhereInput>>;
  OR?: Maybe<Array<PermissionWhereInput>>;
  NOT?: Maybe<Array<PermissionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumPermissionTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  roles?: Maybe<StaffRoleListRelationFilter>;
};

export type PermissionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PermissionType>;
};

/** @deprecated - 1.0 */
export type Pet = {
  __typename?: 'Pet';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight: Scalars['Float'];
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<Clinic>;
  petParent?: Maybe<OwnerInfo>;
  consultations: Array<Consultation>;
  previousClinics: Array<Clinic>;
  _count: PetCountOutputType;
};


/** @deprecated - 1.0 */
export type PetConsultationsArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
};


/** @deprecated - 1.0 */
export type PetPreviousClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};

export type PetAvgAggregateOutputType = {
  __typename?: 'PetAvgAggregateOutputType';
  weight?: Maybe<Scalars['Float']>;
  bornOnDay?: Maybe<Scalars['Float']>;
  bornOnMonth?: Maybe<Scalars['Float']>;
  bornOnYear?: Maybe<Scalars['Float']>;
};

export type PetAvgOrderByAggregateInput = {
  weight?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
};

export type PetBenefitUsage = {
  __typename?: 'PetBenefitUsage';
  organizationPetId: Scalars['String'];
  usages: Array<UsageByBenefit>;
};

export type PetCountAggregateOutputType = {
  __typename?: 'PetCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  name: Scalars['Int'];
  breed: Scalars['Int'];
  bornOn: Scalars['Int'];
  photo: Scalars['Int'];
  repoStatus: Scalars['Int'];
  environment: Scalars['Int'];
  weight: Scalars['Int'];
  firebaseId: Scalars['Int'];
  clinicId: Scalars['Int'];
  petParentId: Scalars['Int'];
  petType: Scalars['Int'];
  updatedAt: Scalars['Int'];
  gender: Scalars['Int'];
  bornOnDay: Scalars['Int'];
  bornOnMonth: Scalars['Int'];
  bornOnYear: Scalars['Int'];
  pimsId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PetCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  bornOn?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  repoStatus?: Maybe<SortOrder>;
  environment?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
};

export type PetCountOutputType = {
  __typename?: 'PetCountOutputType';
  consultations: Scalars['Int'];
  previousClinics: Scalars['Int'];
};

export type PetCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutPetsIdInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutPetsInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicCreateNestedManyWithoutPetsInput>;
};

export type PetCreateManyCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetCreateManyCurrentClinicInputEnvelope = {
  data: Array<PetCreateManyCurrentClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PetCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetCreateManyPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetCreateManyPetParentInputEnvelope = {
  data: Array<PetCreateManyPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PetCreateNestedManyWithoutCurrentClinicInput = {
  create?: Maybe<Array<PetCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutCurrentClinicInput>>;
  createMany?: Maybe<PetCreateManyCurrentClinicInputEnvelope>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
};

export type PetCreateNestedManyWithoutPetParentInput = {
  create?: Maybe<Array<PetCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPetParentInput>>;
  createMany?: Maybe<PetCreateManyPetParentInputEnvelope>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
};

export type PetCreateNestedManyWithoutPreviousClinicsInput = {
  create?: Maybe<Array<PetCreateWithoutPreviousClinicsInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPreviousClinicsInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
};

export type PetCreateNestedOneWithoutConsultationsInput = {
  create?: Maybe<PetUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<PetCreateOrConnectWithoutConsultationsInput>;
  connect?: Maybe<PetWhereUniqueInput>;
};

export type PetCreateOrConnectWithoutConsultationsInput = {
  where: PetWhereUniqueInput;
  create: PetUncheckedCreateWithoutConsultationsInput;
};

export type PetCreateOrConnectWithoutCurrentClinicInput = {
  where: PetWhereUniqueInput;
  create: PetUncheckedCreateWithoutCurrentClinicInput;
};

export type PetCreateOrConnectWithoutPetParentInput = {
  where: PetWhereUniqueInput;
  create: PetUncheckedCreateWithoutPetParentInput;
};

export type PetCreateOrConnectWithoutPreviousClinicsInput = {
  where: PetWhereUniqueInput;
  create: PetUncheckedCreateWithoutPreviousClinicsInput;
};

export type PetCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutPetsIdInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutPetsInput>;
  previousClinics?: Maybe<ClinicCreateNestedManyWithoutPetsInput>;
};

export type PetCreateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutPetsInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicCreateNestedManyWithoutPetsInput>;
};

export type PetCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutPetsIdInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicCreateNestedManyWithoutPetsInput>;
};

export type PetCreateWithoutPreviousClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutPetsIdInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutPetsInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetInput>;
};

export type PetIdHistoryItem = {
  __typename?: 'PetIdHistoryItem';
  id: Scalars['String'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
};

export type PetListRelationFilter = {
  every?: Maybe<PetWhereInput>;
  some?: Maybe<PetWhereInput>;
  none?: Maybe<PetWhereInput>;
};

export type PetMaxAggregateOutputType = {
  __typename?: 'PetMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  bornOn?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  repoStatus?: Maybe<SortOrder>;
  environment?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
};

export type PetMinAggregateOutputType = {
  __typename?: 'PetMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  bornOn?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  repoStatus?: Maybe<SortOrder>;
  environment?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
};

export type PetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PetOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  Breed = 'breed',
  Photo = 'photo',
  RepoStatus = 'repoStatus',
  Environment = 'environment',
  FirebaseId = 'firebaseId',
  ClinicId = 'clinicId',
  PetParentId = 'petParentId',
  PetType = 'petType',
  Gender = 'gender',
  PimsId = 'pimsId'
}

export type PetOrderByRelevanceInput = {
  fields: Array<PetOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PetOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  bornOn?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  repoStatus?: Maybe<SortOrder>;
  environment?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  _count?: Maybe<PetCountOrderByAggregateInput>;
  _avg?: Maybe<PetAvgOrderByAggregateInput>;
  _max?: Maybe<PetMaxOrderByAggregateInput>;
  _min?: Maybe<PetMinOrderByAggregateInput>;
  _sum?: Maybe<PetSumOrderByAggregateInput>;
};

export type PetOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  bornOn?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  repoStatus?: Maybe<SortOrder>;
  environment?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  currentClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  petParent?: Maybe<OwnerInfoOrderByWithRelationAndSearchRelevanceInput>;
  consultations?: Maybe<ConsultationOrderByRelationAggregateInput>;
  previousClinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  _relevance?: Maybe<PetOrderByRelevanceInput>;
};

export type PetParentSetting = {
  __typename?: 'PetParentSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  emailNotifications: Scalars['Boolean'];
  emailOptedOutByPetParent: Scalars['Boolean'];
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  smsNotifications: Scalars['Boolean'];
  smsOptedOutByPetParent: Scalars['Boolean'];
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  pushNotifications: Scalars['Boolean'];
  pushOptedOutByPetParent: Scalars['Boolean'];
  isSubscribedToReminders: Scalars['Boolean'];
  sendSurvey: Scalars['Boolean'];
  petParent: Array<ClinicPetParent>;
  _count: PetParentSettingCountOutputType;
};


export type PetParentSettingPetParentArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};

export type PetParentSettingCountAggregateOutputType = {
  __typename?: 'PetParentSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  emailNotifications: Scalars['Int'];
  emailOptedOutByPetParent: Scalars['Int'];
  emailOptedOutByPetParentAt: Scalars['Int'];
  smsNotifications: Scalars['Int'];
  smsOptedOutByPetParent: Scalars['Int'];
  smsOptedOutByPetParentAt: Scalars['Int'];
  pushNotifications: Scalars['Int'];
  pushOptedOutByPetParent: Scalars['Int'];
  isSubscribedToReminders: Scalars['Int'];
  sendSurvey: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PetParentSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  emailOptedOutByPetParentAt?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  smsOptedOutByPetParentAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  pushOptedOutByPetParent?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  sendSurvey?: Maybe<SortOrder>;
};

export type PetParentSettingCountOutputType = {
  __typename?: 'PetParentSettingCountOutputType';
  petParent: Scalars['Int'];
};

export type PetParentSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  petParent?: Maybe<ClinicPetParentCreateNestedManyWithoutPetParentSettingInput>;
};

export type PetParentSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingCreateNestedOneWithoutPetParentInput = {
  create?: Maybe<PetParentSettingUncheckedCreateWithoutPetParentInput>;
  connectOrCreate?: Maybe<PetParentSettingCreateOrConnectWithoutPetParentInput>;
  connect?: Maybe<PetParentSettingWhereUniqueInput>;
};

export type PetParentSettingCreateOrConnectWithoutPetParentInput = {
  where: PetParentSettingWhereUniqueInput;
  create: PetParentSettingUncheckedCreateWithoutPetParentInput;
};

export type PetParentSettingCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingMaxAggregateOutputType = {
  __typename?: 'PetParentSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  emailOptedOutByPetParentAt?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  smsOptedOutByPetParentAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  pushOptedOutByPetParent?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  sendSurvey?: Maybe<SortOrder>;
};

export type PetParentSettingMinAggregateOutputType = {
  __typename?: 'PetParentSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  emailOptedOutByPetParentAt?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  smsOptedOutByPetParentAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  pushOptedOutByPetParent?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  sendSurvey?: Maybe<SortOrder>;
};

export enum PetParentSettingOrderByRelevanceFieldEnum {
  Id = 'id'
}

export type PetParentSettingOrderByRelevanceInput = {
  fields: Array<PetParentSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PetParentSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  emailOptedOutByPetParentAt?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  smsOptedOutByPetParentAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  pushOptedOutByPetParent?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  sendSurvey?: Maybe<SortOrder>;
  _count?: Maybe<PetParentSettingCountOrderByAggregateInput>;
  _max?: Maybe<PetParentSettingMaxOrderByAggregateInput>;
  _min?: Maybe<PetParentSettingMinOrderByAggregateInput>;
};

export type PetParentSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  emailOptedOutByPetParentAt?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  smsOptedOutByPetParentAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  pushOptedOutByPetParent?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  sendSurvey?: Maybe<SortOrder>;
  petParent?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  _relevance?: Maybe<PetParentSettingOrderByRelevanceInput>;
};

export type PetParentSettingRelationFilter = {
  is?: Maybe<PetParentSettingWhereInput>;
  isNot?: Maybe<PetParentSettingWhereInput>;
};

export enum PetParentSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  EmailNotifications = 'emailNotifications',
  EmailOptedOutByPetParent = 'emailOptedOutByPetParent',
  EmailOptedOutByPetParentAt = 'emailOptedOutByPetParentAt',
  SmsNotifications = 'smsNotifications',
  SmsOptedOutByPetParent = 'smsOptedOutByPetParent',
  SmsOptedOutByPetParentAt = 'smsOptedOutByPetParentAt',
  PushNotifications = 'pushNotifications',
  PushOptedOutByPetParent = 'pushOptedOutByPetParent',
  IsSubscribedToReminders = 'isSubscribedToReminders',
  SendSurvey = 'sendSurvey'
}

export type PetParentSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PetParentSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PetParentSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PetParentSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  emailNotifications?: Maybe<BoolWithAggregatesFilter>;
  emailOptedOutByPetParent?: Maybe<BoolWithAggregatesFilter>;
  emailOptedOutByPetParentAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  smsNotifications?: Maybe<BoolWithAggregatesFilter>;
  smsOptedOutByPetParent?: Maybe<BoolWithAggregatesFilter>;
  smsOptedOutByPetParentAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  pushNotifications?: Maybe<BoolWithAggregatesFilter>;
  pushOptedOutByPetParent?: Maybe<BoolWithAggregatesFilter>;
  isSubscribedToReminders?: Maybe<BoolWithAggregatesFilter>;
  sendSurvey?: Maybe<BoolWithAggregatesFilter>;
};

export type PetParentSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  petParent?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetParentSettingInput>;
};

export type PetParentSettingUncheckedCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  petParent?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetParentSettingNestedInput>;
};

export type PetParentSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingUncheckedUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  petParent?: Maybe<ClinicPetParentUpdateManyWithoutPetParentSettingNestedInput>;
};

export type PetParentSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingUpdateOneWithoutPetParentNestedInput = {
  create?: Maybe<PetParentSettingUncheckedCreateWithoutPetParentInput>;
  connectOrCreate?: Maybe<PetParentSettingCreateOrConnectWithoutPetParentInput>;
  upsert?: Maybe<PetParentSettingUpsertWithoutPetParentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PetParentSettingWhereUniqueInput>;
  update?: Maybe<PetParentSettingUncheckedUpdateWithoutPetParentInput>;
};

export type PetParentSettingUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingUpsertWithoutPetParentInput = {
  update: PetParentSettingUncheckedUpdateWithoutPetParentInput;
  create: PetParentSettingUncheckedCreateWithoutPetParentInput;
};

export type PetParentSettingWhereInput = {
  AND?: Maybe<Array<PetParentSettingWhereInput>>;
  OR?: Maybe<Array<PetParentSettingWhereInput>>;
  NOT?: Maybe<Array<PetParentSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  emailNotifications?: Maybe<BoolFilter>;
  emailOptedOutByPetParent?: Maybe<BoolFilter>;
  emailOptedOutByPetParentAt?: Maybe<DateTimeNullableFilter>;
  smsNotifications?: Maybe<BoolFilter>;
  smsOptedOutByPetParent?: Maybe<BoolFilter>;
  smsOptedOutByPetParentAt?: Maybe<DateTimeNullableFilter>;
  pushNotifications?: Maybe<BoolFilter>;
  pushOptedOutByPetParent?: Maybe<BoolFilter>;
  isSubscribedToReminders?: Maybe<BoolFilter>;
  sendSurvey?: Maybe<BoolFilter>;
  petParent?: Maybe<ClinicPetParentListRelationFilter>;
};

export type PetParentSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PetPortalSetting = {
  __typename?: 'PetPortalSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  showAppointments: Scalars['Boolean'];
  clinicSettingId: Scalars['String'];
  clinicSetting: ClinicSetting;
};

export type PetPortalSettingCountAggregateOutputType = {
  __typename?: 'PetPortalSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  showAppointments: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PetPortalSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
};

export type PetPortalSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSetting: ClinicSettingCreateNestedOneWithoutPetPortalSettingInput;
};

export type PetPortalSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSettingId: Scalars['String'];
};

export type PetPortalSettingCreateNestedOneWithoutClinicSettingInput = {
  create?: Maybe<PetPortalSettingUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<PetPortalSettingCreateOrConnectWithoutClinicSettingInput>;
  connect?: Maybe<PetPortalSettingWhereUniqueInput>;
};

export type PetPortalSettingCreateOrConnectWithoutClinicSettingInput = {
  where: PetPortalSettingWhereUniqueInput;
  create: PetPortalSettingUncheckedCreateWithoutClinicSettingInput;
};

export type PetPortalSettingCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
};

export type PetPortalSettingMaxAggregateOutputType = {
  __typename?: 'PetPortalSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
};

export type PetPortalSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
};

export type PetPortalSettingMinAggregateOutputType = {
  __typename?: 'PetPortalSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
};

export type PetPortalSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
};

export enum PetPortalSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicSettingId = 'clinicSettingId'
}

export type PetPortalSettingOrderByRelevanceInput = {
  fields: Array<PetPortalSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PetPortalSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  _count?: Maybe<PetPortalSettingCountOrderByAggregateInput>;
  _max?: Maybe<PetPortalSettingMaxOrderByAggregateInput>;
  _min?: Maybe<PetPortalSettingMinOrderByAggregateInput>;
};

export type PetPortalSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PetPortalSettingOrderByRelevanceInput>;
};

export type PetPortalSettingRelationFilter = {
  is?: Maybe<PetPortalSettingWhereInput>;
  isNot?: Maybe<PetPortalSettingWhereInput>;
};

export enum PetPortalSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ShowAppointments = 'showAppointments',
  ClinicSettingId = 'clinicSettingId'
}

export type PetPortalSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PetPortalSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PetPortalSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PetPortalSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  showAppointments?: Maybe<BoolWithAggregatesFilter>;
  clinicSettingId?: Maybe<StringWithAggregatesFilter>;
};

export type PetPortalSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSettingId: Scalars['String'];
};

export type PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput = {
  create?: Maybe<PetPortalSettingUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<PetPortalSettingCreateOrConnectWithoutClinicSettingInput>;
  connect?: Maybe<PetPortalSettingWhereUniqueInput>;
};

export type PetPortalSettingUncheckedCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
};

export type PetPortalSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
};

export type PetPortalSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
};

export type PetPortalSettingUncheckedUpdateOneWithoutClinicSettingNestedInput = {
  create?: Maybe<PetPortalSettingUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<PetPortalSettingCreateOrConnectWithoutClinicSettingInput>;
  upsert?: Maybe<PetPortalSettingUpsertWithoutClinicSettingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PetPortalSettingWhereUniqueInput>;
  update?: Maybe<PetPortalSettingUncheckedUpdateWithoutClinicSettingInput>;
};

export type PetPortalSettingUncheckedUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
};

export type PetPortalSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutPetPortalSettingNestedInput>;
};

export type PetPortalSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
};

export type PetPortalSettingUpdateOneWithoutClinicSettingNestedInput = {
  create?: Maybe<PetPortalSettingUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<PetPortalSettingCreateOrConnectWithoutClinicSettingInput>;
  upsert?: Maybe<PetPortalSettingUpsertWithoutClinicSettingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PetPortalSettingWhereUniqueInput>;
  update?: Maybe<PetPortalSettingUncheckedUpdateWithoutClinicSettingInput>;
};

export type PetPortalSettingUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
};

export type PetPortalSettingUpsertWithoutClinicSettingInput = {
  update: PetPortalSettingUncheckedUpdateWithoutClinicSettingInput;
  create: PetPortalSettingUncheckedCreateWithoutClinicSettingInput;
};

export type PetPortalSettingWhereInput = {
  AND?: Maybe<Array<PetPortalSettingWhereInput>>;
  OR?: Maybe<Array<PetPortalSettingWhereInput>>;
  NOT?: Maybe<Array<PetPortalSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  showAppointments?: Maybe<BoolFilter>;
  clinicSettingId?: Maybe<StringFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
};

export type PetPortalSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
};

export type PetRelationFilter = {
  is?: Maybe<PetWhereInput>;
  isNot?: Maybe<PetWhereInput>;
};

export enum PetScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Name = 'name',
  Breed = 'breed',
  BornOn = 'bornOn',
  Photo = 'photo',
  RepoStatus = 'repoStatus',
  Environment = 'environment',
  Weight = 'weight',
  FirebaseId = 'firebaseId',
  ClinicId = 'clinicId',
  PetParentId = 'petParentId',
  PetType = 'petType',
  UpdatedAt = 'updatedAt',
  Gender = 'gender',
  BornOnDay = 'bornOnDay',
  BornOnMonth = 'bornOnMonth',
  BornOnYear = 'bornOnYear',
  PimsId = 'pimsId'
}

export type PetScalarWhereInput = {
  AND?: Maybe<Array<PetScalarWhereInput>>;
  OR?: Maybe<Array<PetScalarWhereInput>>;
  NOT?: Maybe<Array<PetScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  breed?: Maybe<StringNullableFilter>;
  bornOn?: Maybe<DateTimeNullableFilter>;
  photo?: Maybe<StringNullableFilter>;
  repoStatus?: Maybe<StringNullableFilter>;
  environment?: Maybe<StringFilter>;
  weight?: Maybe<FloatFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  petType?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  gender?: Maybe<StringFilter>;
  bornOnDay?: Maybe<IntNullableFilter>;
  bornOnMonth?: Maybe<IntNullableFilter>;
  bornOnYear?: Maybe<IntNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
};

export type PetScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PetScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PetScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PetScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  breed?: Maybe<StringNullableWithAggregatesFilter>;
  bornOn?: Maybe<DateTimeNullableWithAggregatesFilter>;
  photo?: Maybe<StringNullableWithAggregatesFilter>;
  repoStatus?: Maybe<StringNullableWithAggregatesFilter>;
  environment?: Maybe<StringWithAggregatesFilter>;
  weight?: Maybe<FloatWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  petParentId?: Maybe<StringNullableWithAggregatesFilter>;
  petType?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  gender?: Maybe<StringWithAggregatesFilter>;
  bornOnDay?: Maybe<IntNullableWithAggregatesFilter>;
  bornOnMonth?: Maybe<IntNullableWithAggregatesFilter>;
  bornOnYear?: Maybe<IntNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type PetSumAggregateOutputType = {
  __typename?: 'PetSumAggregateOutputType';
  weight?: Maybe<Scalars['Float']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
};

export type PetSumOrderByAggregateInput = {
  weight?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
};

export type PetUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutPetsInput>;
};

export type PetUncheckedCreateNestedManyWithoutCurrentClinicInput = {
  create?: Maybe<Array<PetCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutCurrentClinicInput>>;
  createMany?: Maybe<PetCreateManyCurrentClinicInputEnvelope>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
};

export type PetUncheckedCreateNestedManyWithoutPetParentInput = {
  create?: Maybe<Array<PetCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPetParentInput>>;
  createMany?: Maybe<PetCreateManyPetParentInputEnvelope>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
};

export type PetUncheckedCreateNestedManyWithoutPreviousClinicsInput = {
  create?: Maybe<Array<PetCreateWithoutPreviousClinicsInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPreviousClinicsInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
};

export type PetUncheckedCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutPetsInput>;
};

export type PetUncheckedCreateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutPetsInput>;
};

export type PetUncheckedCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutPetsInput>;
};

export type PetUncheckedCreateWithoutPreviousClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetInput>;
};

export type PetUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetNestedInput>;
  previousClinics?: Maybe<ClinicUncheckedUpdateManyWithoutPetsNestedInput>;
};

export type PetUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetUncheckedUpdateManyWithoutCurrentClinicNestedInput = {
  create?: Maybe<Array<PetCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutCurrentClinicInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutCurrentClinicInput>>;
  createMany?: Maybe<PetCreateManyCurrentClinicInputEnvelope>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutCurrentClinicInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutCurrentClinicInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
};

export type PetUncheckedUpdateManyWithoutPetParentNestedInput = {
  create?: Maybe<Array<PetCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPetParentInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutPetParentInput>>;
  createMany?: Maybe<PetCreateManyPetParentInputEnvelope>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutPetParentInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
};

export type PetUncheckedUpdateManyWithoutPetsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetUncheckedUpdateManyWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput = {
  create?: Maybe<Array<PetCreateWithoutPreviousClinicsInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPreviousClinicsInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutPreviousClinicsInput>>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutPreviousClinicsInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutPreviousClinicsInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
};

export type PetUncheckedUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUncheckedUpdateManyWithoutPetsNestedInput>;
};

export type PetUncheckedUpdateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetNestedInput>;
  previousClinics?: Maybe<ClinicUncheckedUpdateManyWithoutPetsNestedInput>;
};

export type PetUncheckedUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetNestedInput>;
  previousClinics?: Maybe<ClinicUncheckedUpdateManyWithoutPetsNestedInput>;
};

export type PetUncheckedUpdateWithoutPreviousClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetNestedInput>;
};

export type PetUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutPetsIdNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutPetsNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetNestedInput>;
  previousClinics?: Maybe<ClinicUpdateManyWithoutPetsNestedInput>;
};

export type PetUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetUpdateManyWithWhereWithoutCurrentClinicInput = {
  where: PetScalarWhereInput;
  data: PetUncheckedUpdateManyWithoutPetsIdInput;
};

export type PetUpdateManyWithWhereWithoutPetParentInput = {
  where: PetScalarWhereInput;
  data: PetUncheckedUpdateManyWithoutPetsInput;
};

export type PetUpdateManyWithWhereWithoutPreviousClinicsInput = {
  where: PetScalarWhereInput;
  data: PetUncheckedUpdateManyWithoutPetsInput;
};

export type PetUpdateManyWithoutCurrentClinicNestedInput = {
  create?: Maybe<Array<PetCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutCurrentClinicInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutCurrentClinicInput>>;
  createMany?: Maybe<PetCreateManyCurrentClinicInputEnvelope>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutCurrentClinicInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutCurrentClinicInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
};

export type PetUpdateManyWithoutPetParentNestedInput = {
  create?: Maybe<Array<PetCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPetParentInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutPetParentInput>>;
  createMany?: Maybe<PetCreateManyPetParentInputEnvelope>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutPetParentInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
};

export type PetUpdateManyWithoutPreviousClinicsNestedInput = {
  create?: Maybe<Array<PetCreateWithoutPreviousClinicsInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPreviousClinicsInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutPreviousClinicsInput>>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutPreviousClinicsInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutPreviousClinicsInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
};

export type PetUpdateOneWithoutConsultationsNestedInput = {
  create?: Maybe<PetUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<PetCreateOrConnectWithoutConsultationsInput>;
  upsert?: Maybe<PetUpsertWithoutConsultationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PetWhereUniqueInput>;
  update?: Maybe<PetUncheckedUpdateWithoutConsultationsInput>;
};

export type PetUpdateWithWhereUniqueWithoutCurrentClinicInput = {
  where: PetWhereUniqueInput;
  data: PetUncheckedUpdateWithoutCurrentClinicInput;
};

export type PetUpdateWithWhereUniqueWithoutPetParentInput = {
  where: PetWhereUniqueInput;
  data: PetUncheckedUpdateWithoutPetParentInput;
};

export type PetUpdateWithWhereUniqueWithoutPreviousClinicsInput = {
  where: PetWhereUniqueInput;
  data: PetUncheckedUpdateWithoutPreviousClinicsInput;
};

export type PetUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutPetsIdNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutPetsNestedInput>;
  previousClinics?: Maybe<ClinicUpdateManyWithoutPetsNestedInput>;
};

export type PetUpdateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutPetsNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetNestedInput>;
  previousClinics?: Maybe<ClinicUpdateManyWithoutPetsNestedInput>;
};

export type PetUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutPetsIdNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetNestedInput>;
  previousClinics?: Maybe<ClinicUpdateManyWithoutPetsNestedInput>;
};

export type PetUpdateWithoutPreviousClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutPetsIdNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutPetsNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetNestedInput>;
};

export type PetUpsertWithWhereUniqueWithoutCurrentClinicInput = {
  where: PetWhereUniqueInput;
  update: PetUncheckedUpdateWithoutCurrentClinicInput;
  create: PetUncheckedCreateWithoutCurrentClinicInput;
};

export type PetUpsertWithWhereUniqueWithoutPetParentInput = {
  where: PetWhereUniqueInput;
  update: PetUncheckedUpdateWithoutPetParentInput;
  create: PetUncheckedCreateWithoutPetParentInput;
};

export type PetUpsertWithWhereUniqueWithoutPreviousClinicsInput = {
  where: PetWhereUniqueInput;
  update: PetUncheckedUpdateWithoutPreviousClinicsInput;
  create: PetUncheckedCreateWithoutPreviousClinicsInput;
};

export type PetUpsertWithoutConsultationsInput = {
  update: PetUncheckedUpdateWithoutConsultationsInput;
  create: PetUncheckedCreateWithoutConsultationsInput;
};

export type PetWhereInput = {
  AND?: Maybe<Array<PetWhereInput>>;
  OR?: Maybe<Array<PetWhereInput>>;
  NOT?: Maybe<Array<PetWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  breed?: Maybe<StringNullableFilter>;
  bornOn?: Maybe<DateTimeNullableFilter>;
  photo?: Maybe<StringNullableFilter>;
  repoStatus?: Maybe<StringNullableFilter>;
  environment?: Maybe<StringFilter>;
  weight?: Maybe<FloatFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  petType?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  gender?: Maybe<StringFilter>;
  bornOnDay?: Maybe<IntNullableFilter>;
  bornOnMonth?: Maybe<IntNullableFilter>;
  bornOnYear?: Maybe<IntNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  currentClinic?: Maybe<ClinicWhereInput>;
  petParent?: Maybe<OwnerInfoWhereInput>;
  consultations?: Maybe<ConsultationListRelationFilter>;
  previousClinics?: Maybe<ClinicListRelationFilter>;
};

export type PetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupRequest = {
  __typename?: 'PhoneNumberLookupRequest';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: PhoneNumberLookupRequestStatus;
  providerRequestId: Scalars['String'];
  results: Array<PhoneNumberLookupResult>;
  clinicEntityPhoneNumbers: Array<ClinicEntityPhoneNumber>;
  _count: PhoneNumberLookupRequestCountOutputType;
};


export type PhoneNumberLookupRequestResultsArgs = {
  where?: Maybe<PhoneNumberLookupResultWhereInput>;
  orderBy?: Maybe<PhoneNumberLookupResultOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PhoneNumberLookupResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PhoneNumberLookupResultScalarFieldEnum>;
};


export type PhoneNumberLookupRequestClinicEntityPhoneNumbersArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  orderBy?: Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEntityPhoneNumberScalarFieldEnum>;
};

export type PhoneNumberLookupRequestCountAggregateOutputType = {
  __typename?: 'PhoneNumberLookupRequestCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  status: Scalars['Int'];
  providerRequestId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PhoneNumberLookupRequestCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  providerRequestId?: Maybe<SortOrder>;
};

export type PhoneNumberLookupRequestCountOutputType = {
  __typename?: 'PhoneNumberLookupRequestCountOutputType';
  results: Scalars['Int'];
  clinicEntityPhoneNumbers: Scalars['Int'];
};

export type PhoneNumberLookupRequestCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PhoneNumberLookupRequestStatus;
  providerRequestId: Scalars['String'];
  results?: Maybe<PhoneNumberLookupResultCreateNestedManyWithoutRequestInput>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutPhoneNumberLookupRequestInput>;
};

export type PhoneNumberLookupRequestCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PhoneNumberLookupRequestStatus;
  providerRequestId: Scalars['String'];
};

export type PhoneNumberLookupRequestCreateNestedOneWithoutClinicEntityPhoneNumbersInput = {
  create?: Maybe<PhoneNumberLookupRequestUncheckedCreateWithoutClinicEntityPhoneNumbersInput>;
  connectOrCreate?: Maybe<PhoneNumberLookupRequestCreateOrConnectWithoutClinicEntityPhoneNumbersInput>;
  connect?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
};

export type PhoneNumberLookupRequestCreateNestedOneWithoutResultsInput = {
  create?: Maybe<PhoneNumberLookupRequestUncheckedCreateWithoutResultsInput>;
  connectOrCreate?: Maybe<PhoneNumberLookupRequestCreateOrConnectWithoutResultsInput>;
  connect?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
};

export type PhoneNumberLookupRequestCreateOrConnectWithoutClinicEntityPhoneNumbersInput = {
  where: PhoneNumberLookupRequestWhereUniqueInput;
  create: PhoneNumberLookupRequestUncheckedCreateWithoutClinicEntityPhoneNumbersInput;
};

export type PhoneNumberLookupRequestCreateOrConnectWithoutResultsInput = {
  where: PhoneNumberLookupRequestWhereUniqueInput;
  create: PhoneNumberLookupRequestUncheckedCreateWithoutResultsInput;
};

export type PhoneNumberLookupRequestCreateWithoutClinicEntityPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PhoneNumberLookupRequestStatus;
  providerRequestId: Scalars['String'];
  results?: Maybe<PhoneNumberLookupResultCreateNestedManyWithoutRequestInput>;
};

export type PhoneNumberLookupRequestCreateWithoutResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PhoneNumberLookupRequestStatus;
  providerRequestId: Scalars['String'];
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutPhoneNumberLookupRequestInput>;
};

export type PhoneNumberLookupRequestMaxAggregateOutputType = {
  __typename?: 'PhoneNumberLookupRequestMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  providerRequestId?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupRequestMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  providerRequestId?: Maybe<SortOrder>;
};

export type PhoneNumberLookupRequestMinAggregateOutputType = {
  __typename?: 'PhoneNumberLookupRequestMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  providerRequestId?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupRequestMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  providerRequestId?: Maybe<SortOrder>;
};

export enum PhoneNumberLookupRequestOrderByRelevanceFieldEnum {
  Id = 'id',
  ProviderRequestId = 'providerRequestId'
}

export type PhoneNumberLookupRequestOrderByRelevanceInput = {
  fields: Array<PhoneNumberLookupRequestOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PhoneNumberLookupRequestOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  providerRequestId?: Maybe<SortOrder>;
  _count?: Maybe<PhoneNumberLookupRequestCountOrderByAggregateInput>;
  _max?: Maybe<PhoneNumberLookupRequestMaxOrderByAggregateInput>;
  _min?: Maybe<PhoneNumberLookupRequestMinOrderByAggregateInput>;
};

export type PhoneNumberLookupRequestOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  providerRequestId?: Maybe<SortOrder>;
  results?: Maybe<PhoneNumberLookupResultOrderByRelationAggregateInput>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberOrderByRelationAggregateInput>;
  _relevance?: Maybe<PhoneNumberLookupRequestOrderByRelevanceInput>;
};

export type PhoneNumberLookupRequestRelationFilter = {
  is?: Maybe<PhoneNumberLookupRequestWhereInput>;
  isNot?: Maybe<PhoneNumberLookupRequestWhereInput>;
};

export enum PhoneNumberLookupRequestScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  ProviderRequestId = 'providerRequestId'
}

export type PhoneNumberLookupRequestScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PhoneNumberLookupRequestScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PhoneNumberLookupRequestScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PhoneNumberLookupRequestScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumPhoneNumberLookupRequestStatusWithAggregatesFilter>;
  providerRequestId?: Maybe<StringWithAggregatesFilter>;
};

export enum PhoneNumberLookupRequestStatus {
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  PartialComplete = 'PARTIAL_COMPLETE',
  Failed = 'FAILED'
}

export type PhoneNumberLookupRequestUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PhoneNumberLookupRequestStatus;
  providerRequestId: Scalars['String'];
  results?: Maybe<PhoneNumberLookupResultUncheckedCreateNestedManyWithoutRequestInput>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutPhoneNumberLookupRequestInput>;
};

export type PhoneNumberLookupRequestUncheckedCreateWithoutClinicEntityPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PhoneNumberLookupRequestStatus;
  providerRequestId: Scalars['String'];
  results?: Maybe<PhoneNumberLookupResultUncheckedCreateNestedManyWithoutRequestInput>;
};

export type PhoneNumberLookupRequestUncheckedCreateWithoutResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PhoneNumberLookupRequestStatus;
  providerRequestId: Scalars['String'];
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutPhoneNumberLookupRequestInput>;
};

export type PhoneNumberLookupRequestUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  providerRequestId?: Maybe<Scalars['String']>;
  results?: Maybe<PhoneNumberLookupResultUncheckedUpdateManyWithoutRequestNestedInput>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumberLookupRequestNestedInput>;
};

export type PhoneNumberLookupRequestUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  providerRequestId?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupRequestUncheckedUpdateWithoutClinicEntityPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  providerRequestId?: Maybe<Scalars['String']>;
  results?: Maybe<PhoneNumberLookupResultUncheckedUpdateManyWithoutRequestNestedInput>;
};

export type PhoneNumberLookupRequestUncheckedUpdateWithoutResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  providerRequestId?: Maybe<Scalars['String']>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumberLookupRequestNestedInput>;
};

export type PhoneNumberLookupRequestUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  providerRequestId?: Maybe<Scalars['String']>;
  results?: Maybe<PhoneNumberLookupResultUpdateManyWithoutRequestNestedInput>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutPhoneNumberLookupRequestNestedInput>;
};

export type PhoneNumberLookupRequestUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  providerRequestId?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupRequestUpdateOneRequiredWithoutResultsNestedInput = {
  create?: Maybe<PhoneNumberLookupRequestUncheckedCreateWithoutResultsInput>;
  connectOrCreate?: Maybe<PhoneNumberLookupRequestCreateOrConnectWithoutResultsInput>;
  upsert?: Maybe<PhoneNumberLookupRequestUpsertWithoutResultsInput>;
  connect?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
  update?: Maybe<PhoneNumberLookupRequestUncheckedUpdateWithoutResultsInput>;
};

export type PhoneNumberLookupRequestUpdateOneWithoutClinicEntityPhoneNumbersNestedInput = {
  create?: Maybe<PhoneNumberLookupRequestUncheckedCreateWithoutClinicEntityPhoneNumbersInput>;
  connectOrCreate?: Maybe<PhoneNumberLookupRequestCreateOrConnectWithoutClinicEntityPhoneNumbersInput>;
  upsert?: Maybe<PhoneNumberLookupRequestUpsertWithoutClinicEntityPhoneNumbersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
  update?: Maybe<PhoneNumberLookupRequestUncheckedUpdateWithoutClinicEntityPhoneNumbersInput>;
};

export type PhoneNumberLookupRequestUpdateWithoutClinicEntityPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  providerRequestId?: Maybe<Scalars['String']>;
  results?: Maybe<PhoneNumberLookupResultUpdateManyWithoutRequestNestedInput>;
};

export type PhoneNumberLookupRequestUpdateWithoutResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  providerRequestId?: Maybe<Scalars['String']>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutPhoneNumberLookupRequestNestedInput>;
};

export type PhoneNumberLookupRequestUpsertWithoutClinicEntityPhoneNumbersInput = {
  update: PhoneNumberLookupRequestUncheckedUpdateWithoutClinicEntityPhoneNumbersInput;
  create: PhoneNumberLookupRequestUncheckedCreateWithoutClinicEntityPhoneNumbersInput;
};

export type PhoneNumberLookupRequestUpsertWithoutResultsInput = {
  update: PhoneNumberLookupRequestUncheckedUpdateWithoutResultsInput;
  create: PhoneNumberLookupRequestUncheckedCreateWithoutResultsInput;
};

export type PhoneNumberLookupRequestWhereInput = {
  AND?: Maybe<Array<PhoneNumberLookupRequestWhereInput>>;
  OR?: Maybe<Array<PhoneNumberLookupRequestWhereInput>>;
  NOT?: Maybe<Array<PhoneNumberLookupRequestWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumPhoneNumberLookupRequestStatusFilter>;
  providerRequestId?: Maybe<StringFilter>;
  results?: Maybe<PhoneNumberLookupResultListRelationFilter>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberListRelationFilter>;
};

export type PhoneNumberLookupRequestWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupResult = {
  __typename?: 'PhoneNumberLookupResult';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  lookupRequestId: Scalars['String'];
  responseCode: Scalars['Int'];
  message: Scalars['String'];
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  country: Scalars['String'];
  lineType: Scalars['String'];
  lineProvider: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  request: PhoneNumberLookupRequest;
  clinicEntityPhoneNumbers: Array<ClinicEntityPhoneNumber>;
  _count: PhoneNumberLookupResultCountOutputType;
};


export type PhoneNumberLookupResultClinicEntityPhoneNumbersArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  orderBy?: Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEntityPhoneNumberScalarFieldEnum>;
};

export type PhoneNumberLookupResultAvgAggregateOutputType = {
  __typename?: 'PhoneNumberLookupResultAvgAggregateOutputType';
  responseCode?: Maybe<Scalars['Float']>;
};

export type PhoneNumberLookupResultAvgOrderByAggregateInput = {
  responseCode?: Maybe<SortOrder>;
};

export type PhoneNumberLookupResultCountAggregateOutputType = {
  __typename?: 'PhoneNumberLookupResultCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  lookupRequestId: Scalars['Int'];
  responseCode: Scalars['Int'];
  message: Scalars['Int'];
  e164Format: Scalars['Int'];
  formatted: Scalars['Int'];
  country: Scalars['Int'];
  lineType: Scalars['Int'];
  lineProvider: Scalars['Int'];
  mobileCountryCode: Scalars['Int'];
  mobileNetworkCode: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PhoneNumberLookupResultCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  lookupRequestId?: Maybe<SortOrder>;
  responseCode?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  e164Format?: Maybe<SortOrder>;
  formatted?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  lineType?: Maybe<SortOrder>;
  lineProvider?: Maybe<SortOrder>;
  mobileCountryCode?: Maybe<SortOrder>;
  mobileNetworkCode?: Maybe<SortOrder>;
};

export type PhoneNumberLookupResultCountOutputType = {
  __typename?: 'PhoneNumberLookupResultCountOutputType';
  clinicEntityPhoneNumbers: Scalars['Int'];
};

export type PhoneNumberLookupResultCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  responseCode: Scalars['Int'];
  message: Scalars['String'];
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  country: Scalars['String'];
  lineType: Scalars['String'];
  lineProvider: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  request: PhoneNumberLookupRequestCreateNestedOneWithoutResultsInput;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutPhoneNumberLookupResultInput>;
};

export type PhoneNumberLookupResultCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  lookupRequestId: Scalars['String'];
  responseCode: Scalars['Int'];
  message: Scalars['String'];
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  country: Scalars['String'];
  lineType: Scalars['String'];
  lineProvider: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupResultCreateManyRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  responseCode: Scalars['Int'];
  message: Scalars['String'];
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  country: Scalars['String'];
  lineType: Scalars['String'];
  lineProvider: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupResultCreateManyRequestInputEnvelope = {
  data: Array<PhoneNumberLookupResultCreateManyRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PhoneNumberLookupResultCreateNestedManyWithoutRequestInput = {
  create?: Maybe<Array<PhoneNumberLookupResultCreateWithoutRequestInput>>;
  connectOrCreate?: Maybe<Array<PhoneNumberLookupResultCreateOrConnectWithoutRequestInput>>;
  createMany?: Maybe<PhoneNumberLookupResultCreateManyRequestInputEnvelope>;
  connect?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
};

export type PhoneNumberLookupResultCreateNestedOneWithoutClinicEntityPhoneNumbersInput = {
  create?: Maybe<PhoneNumberLookupResultUncheckedCreateWithoutClinicEntityPhoneNumbersInput>;
  connectOrCreate?: Maybe<PhoneNumberLookupResultCreateOrConnectWithoutClinicEntityPhoneNumbersInput>;
  connect?: Maybe<PhoneNumberLookupResultWhereUniqueInput>;
};

export type PhoneNumberLookupResultCreateOrConnectWithoutClinicEntityPhoneNumbersInput = {
  where: PhoneNumberLookupResultWhereUniqueInput;
  create: PhoneNumberLookupResultUncheckedCreateWithoutClinicEntityPhoneNumbersInput;
};

export type PhoneNumberLookupResultCreateOrConnectWithoutRequestInput = {
  where: PhoneNumberLookupResultWhereUniqueInput;
  create: PhoneNumberLookupResultUncheckedCreateWithoutRequestInput;
};

export type PhoneNumberLookupResultCreateWithoutClinicEntityPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  responseCode: Scalars['Int'];
  message: Scalars['String'];
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  country: Scalars['String'];
  lineType: Scalars['String'];
  lineProvider: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  request: PhoneNumberLookupRequestCreateNestedOneWithoutResultsInput;
};

export type PhoneNumberLookupResultCreateWithoutRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  responseCode: Scalars['Int'];
  message: Scalars['String'];
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  country: Scalars['String'];
  lineType: Scalars['String'];
  lineProvider: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutPhoneNumberLookupResultInput>;
};

export type PhoneNumberLookupResultListRelationFilter = {
  every?: Maybe<PhoneNumberLookupResultWhereInput>;
  some?: Maybe<PhoneNumberLookupResultWhereInput>;
  none?: Maybe<PhoneNumberLookupResultWhereInput>;
};

export type PhoneNumberLookupResultMaxAggregateOutputType = {
  __typename?: 'PhoneNumberLookupResultMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  lookupRequestId?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupResultMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  lookupRequestId?: Maybe<SortOrder>;
  responseCode?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  e164Format?: Maybe<SortOrder>;
  formatted?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  lineType?: Maybe<SortOrder>;
  lineProvider?: Maybe<SortOrder>;
  mobileCountryCode?: Maybe<SortOrder>;
  mobileNetworkCode?: Maybe<SortOrder>;
};

export type PhoneNumberLookupResultMinAggregateOutputType = {
  __typename?: 'PhoneNumberLookupResultMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  lookupRequestId?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupResultMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  lookupRequestId?: Maybe<SortOrder>;
  responseCode?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  e164Format?: Maybe<SortOrder>;
  formatted?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  lineType?: Maybe<SortOrder>;
  lineProvider?: Maybe<SortOrder>;
  mobileCountryCode?: Maybe<SortOrder>;
  mobileNetworkCode?: Maybe<SortOrder>;
};

export type PhoneNumberLookupResultOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PhoneNumberLookupResultOrderByRelevanceFieldEnum {
  Id = 'id',
  LookupRequestId = 'lookupRequestId',
  Message = 'message',
  E164Format = 'e164Format',
  Formatted = 'formatted',
  Country = 'country',
  LineType = 'lineType',
  LineProvider = 'lineProvider',
  MobileCountryCode = 'mobileCountryCode',
  MobileNetworkCode = 'mobileNetworkCode'
}

export type PhoneNumberLookupResultOrderByRelevanceInput = {
  fields: Array<PhoneNumberLookupResultOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PhoneNumberLookupResultOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  lookupRequestId?: Maybe<SortOrder>;
  responseCode?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  e164Format?: Maybe<SortOrder>;
  formatted?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  lineType?: Maybe<SortOrder>;
  lineProvider?: Maybe<SortOrder>;
  mobileCountryCode?: Maybe<SortOrder>;
  mobileNetworkCode?: Maybe<SortOrder>;
  _count?: Maybe<PhoneNumberLookupResultCountOrderByAggregateInput>;
  _avg?: Maybe<PhoneNumberLookupResultAvgOrderByAggregateInput>;
  _max?: Maybe<PhoneNumberLookupResultMaxOrderByAggregateInput>;
  _min?: Maybe<PhoneNumberLookupResultMinOrderByAggregateInput>;
  _sum?: Maybe<PhoneNumberLookupResultSumOrderByAggregateInput>;
};

export type PhoneNumberLookupResultOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  lookupRequestId?: Maybe<SortOrder>;
  responseCode?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  e164Format?: Maybe<SortOrder>;
  formatted?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  lineType?: Maybe<SortOrder>;
  lineProvider?: Maybe<SortOrder>;
  mobileCountryCode?: Maybe<SortOrder>;
  mobileNetworkCode?: Maybe<SortOrder>;
  request?: Maybe<PhoneNumberLookupRequestOrderByWithRelationAndSearchRelevanceInput>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberOrderByRelationAggregateInput>;
  _relevance?: Maybe<PhoneNumberLookupResultOrderByRelevanceInput>;
};

export type PhoneNumberLookupResultRelationFilter = {
  is?: Maybe<PhoneNumberLookupResultWhereInput>;
  isNot?: Maybe<PhoneNumberLookupResultWhereInput>;
};

export enum PhoneNumberLookupResultScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  LookupRequestId = 'lookupRequestId',
  ResponseCode = 'responseCode',
  Message = 'message',
  E164Format = 'e164Format',
  Formatted = 'formatted',
  Country = 'country',
  LineType = 'lineType',
  LineProvider = 'lineProvider',
  MobileCountryCode = 'mobileCountryCode',
  MobileNetworkCode = 'mobileNetworkCode'
}

export type PhoneNumberLookupResultScalarWhereInput = {
  AND?: Maybe<Array<PhoneNumberLookupResultScalarWhereInput>>;
  OR?: Maybe<Array<PhoneNumberLookupResultScalarWhereInput>>;
  NOT?: Maybe<Array<PhoneNumberLookupResultScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  lookupRequestId?: Maybe<StringFilter>;
  responseCode?: Maybe<IntFilter>;
  message?: Maybe<StringFilter>;
  e164Format?: Maybe<StringFilter>;
  formatted?: Maybe<StringFilter>;
  country?: Maybe<StringFilter>;
  lineType?: Maybe<StringFilter>;
  lineProvider?: Maybe<StringFilter>;
  mobileCountryCode?: Maybe<StringNullableFilter>;
  mobileNetworkCode?: Maybe<StringNullableFilter>;
};

export type PhoneNumberLookupResultScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PhoneNumberLookupResultScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PhoneNumberLookupResultScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PhoneNumberLookupResultScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  lookupRequestId?: Maybe<StringWithAggregatesFilter>;
  responseCode?: Maybe<IntWithAggregatesFilter>;
  message?: Maybe<StringWithAggregatesFilter>;
  e164Format?: Maybe<StringWithAggregatesFilter>;
  formatted?: Maybe<StringWithAggregatesFilter>;
  country?: Maybe<StringWithAggregatesFilter>;
  lineType?: Maybe<StringWithAggregatesFilter>;
  lineProvider?: Maybe<StringWithAggregatesFilter>;
  mobileCountryCode?: Maybe<StringNullableWithAggregatesFilter>;
  mobileNetworkCode?: Maybe<StringNullableWithAggregatesFilter>;
};

export type PhoneNumberLookupResultSumAggregateOutputType = {
  __typename?: 'PhoneNumberLookupResultSumAggregateOutputType';
  responseCode?: Maybe<Scalars['Int']>;
};

export type PhoneNumberLookupResultSumOrderByAggregateInput = {
  responseCode?: Maybe<SortOrder>;
};

export type PhoneNumberLookupResultUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  lookupRequestId: Scalars['String'];
  responseCode: Scalars['Int'];
  message: Scalars['String'];
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  country: Scalars['String'];
  lineType: Scalars['String'];
  lineProvider: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutPhoneNumberLookupResultInput>;
};

export type PhoneNumberLookupResultUncheckedCreateNestedManyWithoutRequestInput = {
  create?: Maybe<Array<PhoneNumberLookupResultCreateWithoutRequestInput>>;
  connectOrCreate?: Maybe<Array<PhoneNumberLookupResultCreateOrConnectWithoutRequestInput>>;
  createMany?: Maybe<PhoneNumberLookupResultCreateManyRequestInputEnvelope>;
  connect?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
};

export type PhoneNumberLookupResultUncheckedCreateWithoutClinicEntityPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  lookupRequestId: Scalars['String'];
  responseCode: Scalars['Int'];
  message: Scalars['String'];
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  country: Scalars['String'];
  lineType: Scalars['String'];
  lineProvider: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupResultUncheckedCreateWithoutRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  responseCode: Scalars['Int'];
  message: Scalars['String'];
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  country: Scalars['String'];
  lineType: Scalars['String'];
  lineProvider: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutPhoneNumberLookupResultInput>;
};

export type PhoneNumberLookupResultUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  lookupRequestId?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumberLookupResultNestedInput>;
};

export type PhoneNumberLookupResultUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  lookupRequestId?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupResultUncheckedUpdateManyWithoutRequestNestedInput = {
  create?: Maybe<Array<PhoneNumberLookupResultCreateWithoutRequestInput>>;
  connectOrCreate?: Maybe<Array<PhoneNumberLookupResultCreateOrConnectWithoutRequestInput>>;
  upsert?: Maybe<Array<PhoneNumberLookupResultUpsertWithWhereUniqueWithoutRequestInput>>;
  createMany?: Maybe<PhoneNumberLookupResultCreateManyRequestInputEnvelope>;
  set?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  delete?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  connect?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  update?: Maybe<Array<PhoneNumberLookupResultUpdateWithWhereUniqueWithoutRequestInput>>;
  updateMany?: Maybe<Array<PhoneNumberLookupResultUpdateManyWithWhereWithoutRequestInput>>;
  deleteMany?: Maybe<Array<PhoneNumberLookupResultScalarWhereInput>>;
};

export type PhoneNumberLookupResultUncheckedUpdateManyWithoutResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  responseCode?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupResultUncheckedUpdateWithoutClinicEntityPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  lookupRequestId?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupResultUncheckedUpdateWithoutRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  responseCode?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumberLookupResultNestedInput>;
};

export type PhoneNumberLookupResultUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  responseCode?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  request?: Maybe<PhoneNumberLookupRequestUpdateOneRequiredWithoutResultsNestedInput>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutPhoneNumberLookupResultNestedInput>;
};

export type PhoneNumberLookupResultUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  responseCode?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupResultUpdateManyWithWhereWithoutRequestInput = {
  where: PhoneNumberLookupResultScalarWhereInput;
  data: PhoneNumberLookupResultUncheckedUpdateManyWithoutResultsInput;
};

export type PhoneNumberLookupResultUpdateManyWithoutRequestNestedInput = {
  create?: Maybe<Array<PhoneNumberLookupResultCreateWithoutRequestInput>>;
  connectOrCreate?: Maybe<Array<PhoneNumberLookupResultCreateOrConnectWithoutRequestInput>>;
  upsert?: Maybe<Array<PhoneNumberLookupResultUpsertWithWhereUniqueWithoutRequestInput>>;
  createMany?: Maybe<PhoneNumberLookupResultCreateManyRequestInputEnvelope>;
  set?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  delete?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  connect?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  update?: Maybe<Array<PhoneNumberLookupResultUpdateWithWhereUniqueWithoutRequestInput>>;
  updateMany?: Maybe<Array<PhoneNumberLookupResultUpdateManyWithWhereWithoutRequestInput>>;
  deleteMany?: Maybe<Array<PhoneNumberLookupResultScalarWhereInput>>;
};

export type PhoneNumberLookupResultUpdateOneWithoutClinicEntityPhoneNumbersNestedInput = {
  create?: Maybe<PhoneNumberLookupResultUncheckedCreateWithoutClinicEntityPhoneNumbersInput>;
  connectOrCreate?: Maybe<PhoneNumberLookupResultCreateOrConnectWithoutClinicEntityPhoneNumbersInput>;
  upsert?: Maybe<PhoneNumberLookupResultUpsertWithoutClinicEntityPhoneNumbersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PhoneNumberLookupResultWhereUniqueInput>;
  update?: Maybe<PhoneNumberLookupResultUncheckedUpdateWithoutClinicEntityPhoneNumbersInput>;
};

export type PhoneNumberLookupResultUpdateWithWhereUniqueWithoutRequestInput = {
  where: PhoneNumberLookupResultWhereUniqueInput;
  data: PhoneNumberLookupResultUncheckedUpdateWithoutRequestInput;
};

export type PhoneNumberLookupResultUpdateWithoutClinicEntityPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  responseCode?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  request?: Maybe<PhoneNumberLookupRequestUpdateOneRequiredWithoutResultsNestedInput>;
};

export type PhoneNumberLookupResultUpdateWithoutRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  responseCode?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutPhoneNumberLookupResultNestedInput>;
};

export type PhoneNumberLookupResultUpsertWithWhereUniqueWithoutRequestInput = {
  where: PhoneNumberLookupResultWhereUniqueInput;
  update: PhoneNumberLookupResultUncheckedUpdateWithoutRequestInput;
  create: PhoneNumberLookupResultUncheckedCreateWithoutRequestInput;
};

export type PhoneNumberLookupResultUpsertWithoutClinicEntityPhoneNumbersInput = {
  update: PhoneNumberLookupResultUncheckedUpdateWithoutClinicEntityPhoneNumbersInput;
  create: PhoneNumberLookupResultUncheckedCreateWithoutClinicEntityPhoneNumbersInput;
};

export type PhoneNumberLookupResultWhereInput = {
  AND?: Maybe<Array<PhoneNumberLookupResultWhereInput>>;
  OR?: Maybe<Array<PhoneNumberLookupResultWhereInput>>;
  NOT?: Maybe<Array<PhoneNumberLookupResultWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  lookupRequestId?: Maybe<StringFilter>;
  responseCode?: Maybe<IntFilter>;
  message?: Maybe<StringFilter>;
  e164Format?: Maybe<StringFilter>;
  formatted?: Maybe<StringFilter>;
  country?: Maybe<StringFilter>;
  lineType?: Maybe<StringFilter>;
  lineProvider?: Maybe<StringFilter>;
  mobileCountryCode?: Maybe<StringNullableFilter>;
  mobileNetworkCode?: Maybe<StringNullableFilter>;
  request?: Maybe<PhoneNumberLookupRequestWhereInput>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberListRelationFilter>;
};

export type PhoneNumberLookupResultWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum PhoneNumberStatus {
  New = 'New',
  ReadyToHost = 'ReadyToHost',
  PortingInProgress = 'PortingInProgress',
  Hosted = 'Hosted',
  HostingNotAvailable = 'HostingNotAvailable',
  ProblemHosting = 'ProblemHosting'
}

export enum PimsIntegrationCapability {
  Appointment = 'Appointment',
  AppointmentAvailability = 'AppointmentAvailability',
  AppointmentLiveDelete = 'AppointmentLiveDelete',
  AppointmentType = 'AppointmentType',
  Availability = 'Availability',
  AvailabilityResources = 'AvailabilityResources',
  ClinicEmployee = 'ClinicEmployee',
  ClinicEntityPhoneNumber = 'ClinicEntityPhoneNumber',
  ClinicPet = 'ClinicPet',
  ClinicPetCustomFields = 'ClinicPetCustomFields',
  ClinicPetParent = 'ClinicPetParent',
  ClinicPetParentContactInfo = 'ClinicPetParentContactInfo',
  ClinicPetParentCustomFields = 'ClinicPetParentCustomFields',
  ClinicPetParentAddress = 'ClinicPetParentAddress',
  ClinicPetToClinicPetParent = 'ClinicPetToClinicPetParent',
  ClinicPetAlert = 'ClinicPetAlert',
  ClinicRoom = 'ClinicRoom',
  Email = 'Email',
  Invoice = 'Invoice',
  InvoiceLineItem = 'InvoiceLineItem',
  Service = 'Service',
  ServiceCategory = 'ServiceCategory',
  ServiceReminder = 'ServiceReminder',
  Vaccination = 'Vaccination',
  Prescription = 'Prescription',
  PimsPaymentType = 'PimsPaymentType',
  Breed = 'Breed',
  Species = 'Species',
  Color = 'Color',
  Sex = 'Sex',
  Vital = 'Vital',
  NormalizedService = 'NormalizedService'
}

export type PimsInvoice = {
  __typename?: 'PimsInvoice';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  invoiceId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  /** The status from PIMS for this invoice, since these are specific to a PIMS or a clinic config */
  status?: Maybe<Scalars['String']>;
  invoice: Invoice;
  integration: ClinicPimsIntegration;
};

export type PimsInvoiceCountAggregateOutputType = {
  __typename?: 'PimsInvoiceCountAggregateOutputType';
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  invoiceId: Scalars['Int'];
  integrationId: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  status: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PimsInvoiceCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type PimsInvoiceCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  invoice: InvoiceCreateNestedOneWithoutPimsInvoiceInput;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoicesInput;
};

export type PimsInvoiceCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceCreateManyIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceCreateManyIntegrationInputEnvelope = {
  data: Array<PimsInvoiceCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PimsInvoiceCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsInvoiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
};

export type PimsInvoiceCreateNestedOneWithoutInvoiceInput = {
  create?: Maybe<PimsInvoiceUncheckedCreateWithoutInvoiceInput>;
  connectOrCreate?: Maybe<PimsInvoiceCreateOrConnectWithoutInvoiceInput>;
  connect?: Maybe<PimsInvoiceWhereUniqueInput>;
};

export type PimsInvoiceCreateOrConnectWithoutIntegrationInput = {
  where: PimsInvoiceWhereUniqueInput;
  create: PimsInvoiceUncheckedCreateWithoutIntegrationInput;
};

export type PimsInvoiceCreateOrConnectWithoutInvoiceInput = {
  where: PimsInvoiceWhereUniqueInput;
  create: PimsInvoiceUncheckedCreateWithoutInvoiceInput;
};

export type PimsInvoiceCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  invoice: InvoiceCreateNestedOneWithoutPimsInvoiceInput;
};

export type PimsInvoiceCreateWithoutInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoicesInput;
};

export type PimsInvoiceInvoiceIdIntegrationIdPimsIdCompoundUniqueInput = {
  invoiceId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type PimsInvoiceLineItem = {
  __typename?: 'PimsInvoiceLineItem';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  invoiceLineItemId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  invoiceLineItem: InvoiceLineItem;
  integration: ClinicPimsIntegration;
};

export type PimsInvoiceLineItemCountAggregateOutputType = {
  __typename?: 'PimsInvoiceLineItemCountAggregateOutputType';
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  invoiceLineItemId: Scalars['Int'];
  integrationId: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  type: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PimsInvoiceLineItemCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type PimsInvoiceLineItemCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  invoiceLineItem: InvoiceLineItemCreateNestedOneWithoutPimsInvoiceLineItemInput;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoiceLineItemsInput;
};

export type PimsInvoiceLineItemCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
};

export type PimsInvoiceLineItemCreateManyIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
};

export type PimsInvoiceLineItemCreateManyIntegrationInputEnvelope = {
  data: Array<PimsInvoiceLineItemCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsInvoiceLineItemCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceLineItemCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
};

export type PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput = {
  create?: Maybe<PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput>;
  connectOrCreate?: Maybe<PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>;
  connect?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
};

export type PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput = {
  where: PimsInvoiceLineItemWhereUniqueInput;
  create: PimsInvoiceLineItemUncheckedCreateWithoutIntegrationInput;
};

export type PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput = {
  where: PimsInvoiceLineItemWhereUniqueInput;
  create: PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput;
};

export type PimsInvoiceLineItemCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  invoiceLineItem: InvoiceLineItemCreateNestedOneWithoutPimsInvoiceLineItemInput;
};

export type PimsInvoiceLineItemCreateWithoutInvoiceLineItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoiceLineItemsInput;
};

export type PimsInvoiceLineItemInvoiceLineItemIdIntegrationIdPimsIdCompoundUniqueInput = {
  invoiceLineItemId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type PimsInvoiceLineItemListRelationFilter = {
  every?: Maybe<PimsInvoiceLineItemWhereInput>;
  some?: Maybe<PimsInvoiceLineItemWhereInput>;
  none?: Maybe<PimsInvoiceLineItemWhereInput>;
};

export type PimsInvoiceLineItemMaxAggregateOutputType = {
  __typename?: 'PimsInvoiceLineItemMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type PimsInvoiceLineItemMinAggregateOutputType = {
  __typename?: 'PimsInvoiceLineItemMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type PimsInvoiceLineItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PimsInvoiceLineItemOrderByRelevanceFieldEnum {
  InvoiceLineItemId = 'invoiceLineItemId',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId'
}

export type PimsInvoiceLineItemOrderByRelevanceInput = {
  fields: Array<PimsInvoiceLineItemOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PimsInvoiceLineItemOrderByWithAggregationInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  _count?: Maybe<PimsInvoiceLineItemCountOrderByAggregateInput>;
  _max?: Maybe<PimsInvoiceLineItemMaxOrderByAggregateInput>;
  _min?: Maybe<PimsInvoiceLineItemMinOrderByAggregateInput>;
};

export type PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  invoiceLineItem?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PimsInvoiceLineItemOrderByRelevanceInput>;
};

export type PimsInvoiceLineItemPimsInvoiceLineItemUniqueIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type PimsInvoiceLineItemRelationFilter = {
  is?: Maybe<PimsInvoiceLineItemWhereInput>;
  isNot?: Maybe<PimsInvoiceLineItemWhereInput>;
};

export enum PimsInvoiceLineItemScalarFieldEnum {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InvoiceLineItemId = 'invoiceLineItemId',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  Type = 'type'
}

export type PimsInvoiceLineItemScalarWhereInput = {
  AND?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
  OR?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
  NOT?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  invoiceLineItemId?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  type?: Maybe<EnumPimsInvoiceLineItemTypeFilter>;
};

export type PimsInvoiceLineItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PimsInvoiceLineItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PimsInvoiceLineItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PimsInvoiceLineItemScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  invoiceLineItemId?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  type?: Maybe<EnumPimsInvoiceLineItemTypeWithAggregatesFilter>;
};

export enum PimsInvoiceLineItemType {
  BitwerxAdjustment = 'Bitwerx_Adjustment',
  BitwerxDiscount = 'Bitwerx_Discount',
  BitwerxCode = 'Bitwerx_Code',
  BitwerxReturn = 'Bitwerx_Return',
  BitwerxPayment = 'Bitwerx_Payment',
  BitwerxNote = 'Bitwerx_Note',
  BitwerxFee = 'Bitwerx_Fee',
  BitwerxEstimate = 'Bitwerx_Estimate',
  BitwerxWriteOff = 'Bitwerx_WriteOff',
  BitwerxTax = 'Bitwerx_Tax',
  VetdataAdjustment = 'Vetdata_Adjustment',
  VetdataDiscount = 'Vetdata_Discount',
  VetdataCode = 'Vetdata_Code',
  VetdataReturn = 'Vetdata_Return',
  VetdataPayment = 'Vetdata_Payment',
  VetdataNote = 'Vetdata_Note',
  VetdataFee = 'Vetdata_Fee',
  VetdataEstimate = 'Vetdata_Estimate',
  VetdataWriteOff = 'Vetdata_WriteOff',
  VetdataTax = 'Vetdata_Tax',
  VetdataUnknown = 'Vetdata_Unknown'
}

export type PimsInvoiceLineItemUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
};

export type PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsInvoiceLineItemCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceLineItemCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
};

export type PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput = {
  create?: Maybe<PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput>;
  connectOrCreate?: Maybe<PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>;
  connect?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
};

export type PimsInvoiceLineItemUncheckedCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
};

export type PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
};

export type PimsInvoiceLineItemUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<PimsInvoiceLineItemCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsInvoiceLineItemUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceLineItemCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<PimsInvoiceLineItemUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsInvoiceLineItemUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
};

export type PimsInvoiceLineItemUncheckedUpdateManyWithoutPimsInvoiceLineItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput = {
  create?: Maybe<PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput>;
  connectOrCreate?: Maybe<PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>;
  upsert?: Maybe<PimsInvoiceLineItemUpsertWithoutInvoiceLineItemInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  update?: Maybe<PimsInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput>;
};

export type PimsInvoiceLineItemUncheckedUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneRequiredWithoutPimsInvoiceLineItemNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoiceLineItemsNestedInput>;
};

export type PimsInvoiceLineItemUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemUpdateManyWithWhereWithoutIntegrationInput = {
  where: PimsInvoiceLineItemScalarWhereInput;
  data: PimsInvoiceLineItemUncheckedUpdateManyWithoutPimsInvoiceLineItemsInput;
};

export type PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<PimsInvoiceLineItemCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsInvoiceLineItemUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceLineItemCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<PimsInvoiceLineItemUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsInvoiceLineItemUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
};

export type PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput = {
  create?: Maybe<PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput>;
  connectOrCreate?: Maybe<PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>;
  upsert?: Maybe<PimsInvoiceLineItemUpsertWithoutInvoiceLineItemInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  update?: Maybe<PimsInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput>;
};

export type PimsInvoiceLineItemUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: PimsInvoiceLineItemWhereUniqueInput;
  data: PimsInvoiceLineItemUncheckedUpdateWithoutIntegrationInput;
};

export type PimsInvoiceLineItemUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneRequiredWithoutPimsInvoiceLineItemNestedInput>;
};

export type PimsInvoiceLineItemUpdateWithoutInvoiceLineItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoiceLineItemsNestedInput>;
};

export type PimsInvoiceLineItemUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: PimsInvoiceLineItemWhereUniqueInput;
  update: PimsInvoiceLineItemUncheckedUpdateWithoutIntegrationInput;
  create: PimsInvoiceLineItemUncheckedCreateWithoutIntegrationInput;
};

export type PimsInvoiceLineItemUpsertWithoutInvoiceLineItemInput = {
  update: PimsInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput;
  create: PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput;
};

export type PimsInvoiceLineItemWhereInput = {
  AND?: Maybe<Array<PimsInvoiceLineItemWhereInput>>;
  OR?: Maybe<Array<PimsInvoiceLineItemWhereInput>>;
  NOT?: Maybe<Array<PimsInvoiceLineItemWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  invoiceLineItemId?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  type?: Maybe<EnumPimsInvoiceLineItemTypeFilter>;
  invoiceLineItem?: Maybe<InvoiceLineItemWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type PimsInvoiceLineItemWhereUniqueInput = {
  invoiceLineItemId?: Maybe<Scalars['String']>;
  invoiceLineItemId_integrationId_pimsId?: Maybe<PimsInvoiceLineItemInvoiceLineItemIdIntegrationIdPimsIdCompoundUniqueInput>;
  pimsInvoiceLineItemUniqueIntegrationIdPimsId?: Maybe<PimsInvoiceLineItemPimsInvoiceLineItemUniqueIntegrationIdPimsIdCompoundUniqueInput>;
};

export type PimsInvoiceListRelationFilter = {
  every?: Maybe<PimsInvoiceWhereInput>;
  some?: Maybe<PimsInvoiceWhereInput>;
  none?: Maybe<PimsInvoiceWhereInput>;
};

export type PimsInvoiceMaxAggregateOutputType = {
  __typename?: 'PimsInvoiceMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type PimsInvoiceMinAggregateOutputType = {
  __typename?: 'PimsInvoiceMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type PimsInvoiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PimsInvoiceOrderByRelevanceFieldEnum {
  InvoiceId = 'invoiceId',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  Status = 'status'
}

export type PimsInvoiceOrderByRelevanceInput = {
  fields: Array<PimsInvoiceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PimsInvoiceOrderByWithAggregationInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  _count?: Maybe<PimsInvoiceCountOrderByAggregateInput>;
  _max?: Maybe<PimsInvoiceMaxOrderByAggregateInput>;
  _min?: Maybe<PimsInvoiceMinOrderByAggregateInput>;
};

export type PimsInvoiceOrderByWithRelationAndSearchRelevanceInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  invoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PimsInvoiceOrderByRelevanceInput>;
};

export type PimsInvoicePimsInvoiceUniqueIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type PimsInvoiceRelationFilter = {
  is?: Maybe<PimsInvoiceWhereInput>;
  isNot?: Maybe<PimsInvoiceWhereInput>;
};

export enum PimsInvoiceScalarFieldEnum {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InvoiceId = 'invoiceId',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  Status = 'status'
}

export type PimsInvoiceScalarWhereInput = {
  AND?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
  OR?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
  NOT?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  invoiceId?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  status?: Maybe<StringNullableFilter>;
};

export type PimsInvoiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PimsInvoiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PimsInvoiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PimsInvoiceScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  invoiceId?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  status?: Maybe<StringNullableWithAggregatesFilter>;
};

export type PimsInvoiceUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsInvoiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
};

export type PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput = {
  create?: Maybe<PimsInvoiceUncheckedCreateWithoutInvoiceInput>;
  connectOrCreate?: Maybe<PimsInvoiceCreateOrConnectWithoutInvoiceInput>;
  connect?: Maybe<PimsInvoiceWhereUniqueInput>;
};

export type PimsInvoiceUncheckedCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUncheckedCreateWithoutInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<PimsInvoiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsInvoiceUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<PimsInvoiceUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsInvoiceUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
};

export type PimsInvoiceUncheckedUpdateManyWithoutPimsInvoicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput = {
  create?: Maybe<PimsInvoiceUncheckedCreateWithoutInvoiceInput>;
  connectOrCreate?: Maybe<PimsInvoiceCreateOrConnectWithoutInvoiceInput>;
  upsert?: Maybe<PimsInvoiceUpsertWithoutInvoiceInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PimsInvoiceWhereUniqueInput>;
  update?: Maybe<PimsInvoiceUncheckedUpdateWithoutInvoiceInput>;
};

export type PimsInvoiceUncheckedUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUncheckedUpdateWithoutInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutPimsInvoiceNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoicesNestedInput>;
};

export type PimsInvoiceUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUpdateManyWithWhereWithoutIntegrationInput = {
  where: PimsInvoiceScalarWhereInput;
  data: PimsInvoiceUncheckedUpdateManyWithoutPimsInvoicesInput;
};

export type PimsInvoiceUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<PimsInvoiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsInvoiceUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<PimsInvoiceUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsInvoiceUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
};

export type PimsInvoiceUpdateOneWithoutInvoiceNestedInput = {
  create?: Maybe<PimsInvoiceUncheckedCreateWithoutInvoiceInput>;
  connectOrCreate?: Maybe<PimsInvoiceCreateOrConnectWithoutInvoiceInput>;
  upsert?: Maybe<PimsInvoiceUpsertWithoutInvoiceInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PimsInvoiceWhereUniqueInput>;
  update?: Maybe<PimsInvoiceUncheckedUpdateWithoutInvoiceInput>;
};

export type PimsInvoiceUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: PimsInvoiceWhereUniqueInput;
  data: PimsInvoiceUncheckedUpdateWithoutIntegrationInput;
};

export type PimsInvoiceUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutPimsInvoiceNestedInput>;
};

export type PimsInvoiceUpdateWithoutInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoicesNestedInput>;
};

export type PimsInvoiceUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: PimsInvoiceWhereUniqueInput;
  update: PimsInvoiceUncheckedUpdateWithoutIntegrationInput;
  create: PimsInvoiceUncheckedCreateWithoutIntegrationInput;
};

export type PimsInvoiceUpsertWithoutInvoiceInput = {
  update: PimsInvoiceUncheckedUpdateWithoutInvoiceInput;
  create: PimsInvoiceUncheckedCreateWithoutInvoiceInput;
};

export type PimsInvoiceWhereInput = {
  AND?: Maybe<Array<PimsInvoiceWhereInput>>;
  OR?: Maybe<Array<PimsInvoiceWhereInput>>;
  NOT?: Maybe<Array<PimsInvoiceWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  invoiceId?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  status?: Maybe<StringNullableFilter>;
  invoice?: Maybe<InvoiceWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type PimsInvoiceWhereUniqueInput = {
  invoiceId?: Maybe<Scalars['String']>;
  invoiceId_integrationId_pimsId?: Maybe<PimsInvoiceInvoiceIdIntegrationIdPimsIdCompoundUniqueInput>;
  pimsInvoiceUniqueIntegrationIdPimsId?: Maybe<PimsInvoicePimsInvoiceUniqueIntegrationIdPimsIdCompoundUniqueInput>;
};

export type PimsPaymentType = {
  __typename?: 'PimsPaymentType';
  id: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  integration: ClinicPimsIntegration;
  defaultBitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  visaBitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  mastercardBitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  amexBitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  discoverBitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  careDiscountBitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  _count: PimsPaymentTypeCountOutputType;
};


export type PimsPaymentTypeDefaultBitwerxPaymentWritebackConfigurationsArgs = {
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
};


export type PimsPaymentTypeVisaBitwerxPaymentWritebackConfigurationsArgs = {
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
};


export type PimsPaymentTypeMastercardBitwerxPaymentWritebackConfigurationsArgs = {
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
};


export type PimsPaymentTypeAmexBitwerxPaymentWritebackConfigurationsArgs = {
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
};


export type PimsPaymentTypeDiscoverBitwerxPaymentWritebackConfigurationsArgs = {
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
};


export type PimsPaymentTypeCareDiscountBitwerxPaymentWritebackConfigurationsArgs = {
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
};

export type PimsPaymentTypeCountAggregateOutputType = {
  __typename?: 'PimsPaymentTypeCountAggregateOutputType';
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  pimsId: Scalars['Int'];
  isCreditCard: Scalars['Int'];
  isInactive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  description: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PimsPaymentTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isCreditCard?: Maybe<SortOrder>;
  isInactive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type PimsPaymentTypeCountOutputType = {
  __typename?: 'PimsPaymentTypeCountOutputType';
  defaultBitwerxPaymentWritebackConfigurations: Scalars['Int'];
  visaBitwerxPaymentWritebackConfigurations: Scalars['Int'];
  mastercardBitwerxPaymentWritebackConfigurations: Scalars['Int'];
  amexBitwerxPaymentWritebackConfigurations: Scalars['Int'];
  discoverBitwerxPaymentWritebackConfigurations: Scalars['Int'];
  careDiscountBitwerxPaymentWritebackConfigurations: Scalars['Int'];
};

export type PimsPaymentTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
};

export type PimsPaymentTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
};

export type PimsPaymentTypeCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
};

export type PimsPaymentTypeCreateManyIntegrationInputEnvelope = {
  data: Array<PimsPaymentTypeCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PimsPaymentTypeCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsPaymentTypeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsPaymentTypeCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PimsPaymentTypeCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
};

export type PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
};

export type PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
};

export type PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
};

export type PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
};

export type PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
};

export type PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
};

export type PimsPaymentTypeCreateOrConnectWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  where: PimsPaymentTypeWhereUniqueInput;
  create: PimsPaymentTypeUncheckedCreateWithoutAmexBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeCreateOrConnectWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  where: PimsPaymentTypeWhereUniqueInput;
  create: PimsPaymentTypeUncheckedCreateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeCreateOrConnectWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  where: PimsPaymentTypeWhereUniqueInput;
  create: PimsPaymentTypeUncheckedCreateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeCreateOrConnectWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  where: PimsPaymentTypeWhereUniqueInput;
  create: PimsPaymentTypeUncheckedCreateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeCreateOrConnectWithoutIntegrationInput = {
  where: PimsPaymentTypeWhereUniqueInput;
  create: PimsPaymentTypeUncheckedCreateWithoutIntegrationInput;
};

export type PimsPaymentTypeCreateOrConnectWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  where: PimsPaymentTypeWhereUniqueInput;
  create: PimsPaymentTypeUncheckedCreateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeCreateOrConnectWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  where: PimsPaymentTypeWhereUniqueInput;
  create: PimsPaymentTypeUncheckedCreateWithoutVisaBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeCreateWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
};

export type PimsPaymentTypeCreateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
};

export type PimsPaymentTypeCreateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
};

export type PimsPaymentTypeCreateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
};

export type PimsPaymentTypeCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
};

export type PimsPaymentTypeCreateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
};

export type PimsPaymentTypeCreateWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
};

export type PimsPaymentTypeListRelationFilter = {
  every?: Maybe<PimsPaymentTypeWhereInput>;
  some?: Maybe<PimsPaymentTypeWhereInput>;
  none?: Maybe<PimsPaymentTypeWhereInput>;
};

export type PimsPaymentTypeMaxAggregateOutputType = {
  __typename?: 'PimsPaymentTypeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type PimsPaymentTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isCreditCard?: Maybe<SortOrder>;
  isInactive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type PimsPaymentTypeMinAggregateOutputType = {
  __typename?: 'PimsPaymentTypeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type PimsPaymentTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isCreditCard?: Maybe<SortOrder>;
  isInactive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type PimsPaymentTypeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PimsPaymentTypeOrderByRelevanceFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  Description = 'description'
}

export type PimsPaymentTypeOrderByRelevanceInput = {
  fields: Array<PimsPaymentTypeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PimsPaymentTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isCreditCard?: Maybe<SortOrder>;
  isInactive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<PimsPaymentTypeCountOrderByAggregateInput>;
  _max?: Maybe<PimsPaymentTypeMaxOrderByAggregateInput>;
  _min?: Maybe<PimsPaymentTypeMinOrderByAggregateInput>;
};

export type PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isCreditCard?: Maybe<SortOrder>;
  isInactive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  _relevance?: Maybe<PimsPaymentTypeOrderByRelevanceInput>;
};

export type PimsPaymentTypePimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type PimsPaymentTypeRelationFilter = {
  is?: Maybe<PimsPaymentTypeWhereInput>;
  isNot?: Maybe<PimsPaymentTypeWhereInput>;
};

export enum PimsPaymentTypeScalarFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  IsCreditCard = 'isCreditCard',
  IsInactive = 'isInactive',
  IsDeleted = 'isDeleted',
  Description = 'description',
  RawPimsValue = 'rawPimsValue'
}

export type PimsPaymentTypeScalarWhereInput = {
  AND?: Maybe<Array<PimsPaymentTypeScalarWhereInput>>;
  OR?: Maybe<Array<PimsPaymentTypeScalarWhereInput>>;
  NOT?: Maybe<Array<PimsPaymentTypeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  isCreditCard?: Maybe<BoolFilter>;
  isInactive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  description?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonFilter>;
};

export type PimsPaymentTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PimsPaymentTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PimsPaymentTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PimsPaymentTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  isCreditCard?: Maybe<BoolWithAggregatesFilter>;
  isInactive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonWithAggregatesFilter>;
};

export type PimsPaymentTypeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsPaymentTypeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsPaymentTypeCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PimsPaymentTypeCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
};

export type PimsPaymentTypeUncheckedCreateWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedCreateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedCreateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedCreateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedCreateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedCreateWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue: Scalars['Json'];
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<PimsPaymentTypeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsPaymentTypeCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsPaymentTypeUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PimsPaymentTypeCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  update?: Maybe<Array<PimsPaymentTypeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsPaymentTypeUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PimsPaymentTypeScalarWhereInput>>;
};

export type PimsPaymentTypeUncheckedUpdateManyWithoutPimsPaymentTypesInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type PimsPaymentTypeUncheckedUpdateWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUncheckedUpdateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUncheckedUpdateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUncheckedUpdateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUncheckedUpdateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUncheckedUpdateWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type PimsPaymentTypeUpdateManyWithWhereWithoutIntegrationInput = {
  where: PimsPaymentTypeScalarWhereInput;
  data: PimsPaymentTypeUncheckedUpdateManyWithoutPimsPaymentTypesInput;
};

export type PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<PimsPaymentTypeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsPaymentTypeCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsPaymentTypeUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PimsPaymentTypeCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  update?: Maybe<Array<PimsPaymentTypeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsPaymentTypeUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PimsPaymentTypeScalarWhereInput>>;
};

export type PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput = {
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<PimsPaymentTypeUpsertWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  update?: Maybe<PimsPaymentTypeUncheckedUpdateWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput = {
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<PimsPaymentTypeUpsertWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  update?: Maybe<PimsPaymentTypeUncheckedUpdateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput = {
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<PimsPaymentTypeUpsertWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  update?: Maybe<PimsPaymentTypeUncheckedUpdateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput = {
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<PimsPaymentTypeUpsertWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  update?: Maybe<PimsPaymentTypeUncheckedUpdateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput = {
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<PimsPaymentTypeUpsertWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  update?: Maybe<PimsPaymentTypeUncheckedUpdateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput = {
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<PimsPaymentTypeUpsertWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  update?: Maybe<PimsPaymentTypeUncheckedUpdateWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: PimsPaymentTypeWhereUniqueInput;
  data: PimsPaymentTypeUncheckedUpdateWithoutIntegrationInput;
};

export type PimsPaymentTypeUpdateWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: PimsPaymentTypeWhereUniqueInput;
  update: PimsPaymentTypeUncheckedUpdateWithoutIntegrationInput;
  create: PimsPaymentTypeUncheckedCreateWithoutIntegrationInput;
};

export type PimsPaymentTypeUpsertWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  update: PimsPaymentTypeUncheckedUpdateWithoutAmexBitwerxPaymentWritebackConfigurationsInput;
  create: PimsPaymentTypeUncheckedCreateWithoutAmexBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeUpsertWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  update: PimsPaymentTypeUncheckedUpdateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput;
  create: PimsPaymentTypeUncheckedCreateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeUpsertWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  update: PimsPaymentTypeUncheckedUpdateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput;
  create: PimsPaymentTypeUncheckedCreateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeUpsertWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  update: PimsPaymentTypeUncheckedUpdateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput;
  create: PimsPaymentTypeUncheckedCreateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeUpsertWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  update: PimsPaymentTypeUncheckedUpdateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput;
  create: PimsPaymentTypeUncheckedCreateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeUpsertWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  update: PimsPaymentTypeUncheckedUpdateWithoutVisaBitwerxPaymentWritebackConfigurationsInput;
  create: PimsPaymentTypeUncheckedCreateWithoutVisaBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeWhereInput = {
  AND?: Maybe<Array<PimsPaymentTypeWhereInput>>;
  OR?: Maybe<Array<PimsPaymentTypeWhereInput>>;
  NOT?: Maybe<Array<PimsPaymentTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  isCreditCard?: Maybe<BoolFilter>;
  isInactive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  description?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
};

export type PimsPaymentTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsIdAndIntegrationId?: Maybe<PimsPaymentTypePimsIdAndIntegrationIdCompoundUniqueInput>;
};

export enum PimsSyncFrequency {
  Sync_30Sec = 'Sync_30_Sec',
  Sync_5Min = 'Sync_5_Min',
  Sync_24Hour = 'Sync_24_Hour'
}

export enum PimsSyncStatus {
  Created = 'Created',
  Started = 'Started',
  Completed = 'Completed',
  Error = 'Error'
}

export type PimsWritebackLogEntry = {
  __typename?: 'PimsWritebackLogEntry';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  /** Only set this when there were errors */
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId: Scalars['String'];
  triggeredByUserId?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegration;
  triggeredBy?: Maybe<User>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntry>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntry>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntry>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntry>;
};

export type PimsWritebackLogEntryAvgAggregateOutputType = {
  __typename?: 'PimsWritebackLogEntryAvgAggregateOutputType';
  httpCode?: Maybe<Scalars['Float']>;
};

export type PimsWritebackLogEntryAvgOrderByAggregateInput = {
  httpCode?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryCountAggregateOutputType = {
  __typename?: 'PimsWritebackLogEntryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  status: Scalars['Int'];
  type: Scalars['Int'];
  pimsError: Scalars['Int'];
  pimsId: Scalars['Int'];
  requestBody: Scalars['Int'];
  requestId: Scalars['Int'];
  httpCode: Scalars['Int'];
  normalizedError: Scalars['Int'];
  integrationId: Scalars['Int'];
  triggeredByUserId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PimsWritebackLogEntryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  normalizedError?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  triggeredByUserId?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId: Scalars['String'];
  triggeredByUserId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  triggeredByUserId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryCreateManyIntegrationInputEnvelope = {
  data: Array<PimsWritebackLogEntryCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PimsWritebackLogEntryCreateManyTriggeredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId: Scalars['String'];
};

export type PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope = {
  data: Array<PimsWritebackLogEntryCreateManyTriggeredByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
};

export type PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutTriggeredByInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
};

export type PimsWritebackLogEntryCreateNestedOneWithoutCareAccountCreditLogInput = {
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditLogInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditLogInput>;
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
};

export type PimsWritebackLogEntryCreateNestedOneWithoutCustomFieldsLogInput = {
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutCustomFieldsLogInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutCustomFieldsLogInput>;
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
};

export type PimsWritebackLogEntryCreateNestedOneWithoutInvoiceLogInput = {
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutInvoiceLogInput>;
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
};

export type PimsWritebackLogEntryCreateNestedOneWithoutPaymentLogInput = {
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutPaymentLogInput>;
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditLogInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditLogInput;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutCustomFieldsLogInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutCustomFieldsLogInput;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutIntegrationInput;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutInvoiceLogInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutPaymentLogInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutTriggeredByInput;
};

export type PimsWritebackLogEntryCreateWithoutCareAccountCreditLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryCreateWithoutCustomFieldsLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryCreateWithoutInvoiceLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryCreateWithoutPaymentLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryCreateWithoutTriggeredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryListRelationFilter = {
  every?: Maybe<PimsWritebackLogEntryWhereInput>;
  some?: Maybe<PimsWritebackLogEntryWhereInput>;
  none?: Maybe<PimsWritebackLogEntryWhereInput>;
};

export type PimsWritebackLogEntryMaxAggregateOutputType = {
  __typename?: 'PimsWritebackLogEntryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  normalizedError?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  triggeredByUserId?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryMinAggregateOutputType = {
  __typename?: 'PimsWritebackLogEntryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  normalizedError?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  triggeredByUserId?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PimsWritebackLogEntryOrderByRelevanceFieldEnum {
  Id = 'id',
  PimsError = 'pimsError',
  PimsId = 'pimsId',
  RequestId = 'requestId',
  IntegrationId = 'integrationId',
  TriggeredByUserId = 'triggeredByUserId'
}

export type PimsWritebackLogEntryOrderByRelevanceInput = {
  fields: Array<PimsWritebackLogEntryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PimsWritebackLogEntryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  normalizedError?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  triggeredByUserId?: Maybe<SortOrder>;
  _count?: Maybe<PimsWritebackLogEntryCountOrderByAggregateInput>;
  _avg?: Maybe<PimsWritebackLogEntryAvgOrderByAggregateInput>;
  _max?: Maybe<PimsWritebackLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<PimsWritebackLogEntryMinOrderByAggregateInput>;
  _sum?: Maybe<PimsWritebackLogEntrySumOrderByAggregateInput>;
};

export type PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  normalizedError?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  triggeredByUserId?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  triggeredBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PimsWritebackLogEntryOrderByRelevanceInput>;
};

export type PimsWritebackLogEntryRelationFilter = {
  is?: Maybe<PimsWritebackLogEntryWhereInput>;
  isNot?: Maybe<PimsWritebackLogEntryWhereInput>;
};

export enum PimsWritebackLogEntryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  Type = 'type',
  PimsError = 'pimsError',
  PimsId = 'pimsId',
  RequestBody = 'requestBody',
  RequestId = 'requestId',
  HttpCode = 'httpCode',
  NormalizedError = 'normalizedError',
  IntegrationId = 'integrationId',
  TriggeredByUserId = 'triggeredByUserId'
}

export type PimsWritebackLogEntryScalarWhereInput = {
  AND?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumPimsWritebackStatusFilter>;
  type?: Maybe<EnumPimsWritebackTypeFilter>;
  pimsError?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  requestBody?: Maybe<JsonNullableFilter>;
  requestId?: Maybe<StringNullableFilter>;
  httpCode?: Maybe<IntNullableFilter>;
  normalizedError?: Maybe<EnumNormalizedWritebackErrorNullableFilter>;
  integrationId?: Maybe<StringFilter>;
  triggeredByUserId?: Maybe<StringNullableFilter>;
};

export type PimsWritebackLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumPimsWritebackStatusWithAggregatesFilter>;
  type?: Maybe<EnumPimsWritebackTypeWithAggregatesFilter>;
  pimsError?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  requestBody?: Maybe<JsonNullableWithAggregatesFilter>;
  requestId?: Maybe<StringNullableWithAggregatesFilter>;
  httpCode?: Maybe<IntNullableWithAggregatesFilter>;
  normalizedError?: Maybe<EnumNormalizedWritebackErrorNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  triggeredByUserId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type PimsWritebackLogEntrySumAggregateOutputType = {
  __typename?: 'PimsWritebackLogEntrySumAggregateOutputType';
  httpCode?: Maybe<Scalars['Int']>;
};

export type PimsWritebackLogEntrySumOrderByAggregateInput = {
  httpCode?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId: Scalars['String'];
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
};

export type PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutTriggeredByInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId: Scalars['String'];
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutCustomFieldsLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId: Scalars['String'];
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId: Scalars['String'];
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId: Scalars['String'];
  triggeredByUserId?: Maybe<Scalars['String']>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutTriggeredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId: Scalars['String'];
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsWritebackLogEntryUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PimsWritebackLogEntryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsWritebackLogEntryUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutTriggeredByInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput>>;
  upsert?: Maybe<Array<PimsWritebackLogEntryUpsertWithWhereUniqueWithoutTriggeredByInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope>;
  set?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PimsWritebackLogEntryUpdateWithWhereUniqueWithoutTriggeredByInput>>;
  updateMany?: Maybe<Array<PimsWritebackLogEntryUpdateManyWithWhereWithoutTriggeredByInput>>;
  deleteMany?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  triggeredByUserId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutCareAccountCreditLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutCustomFieldsLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutInvoiceLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutPaymentLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutTriggeredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integrationId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
};

export type PimsWritebackLogEntryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksNestedInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
};

export type PimsWritebackLogEntryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
};

export type PimsWritebackLogEntryUpdateManyWithWhereWithoutIntegrationInput = {
  where: PimsWritebackLogEntryScalarWhereInput;
  data: PimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogEntriesInput;
};

export type PimsWritebackLogEntryUpdateManyWithWhereWithoutTriggeredByInput = {
  where: PimsWritebackLogEntryScalarWhereInput;
  data: PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredWritebacksInput;
};

export type PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsWritebackLogEntryUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PimsWritebackLogEntryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsWritebackLogEntryUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
};

export type PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutTriggeredByInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput>>;
  upsert?: Maybe<Array<PimsWritebackLogEntryUpsertWithWhereUniqueWithoutTriggeredByInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope>;
  set?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PimsWritebackLogEntryUpdateWithWhereUniqueWithoutTriggeredByInput>>;
  updateMany?: Maybe<Array<PimsWritebackLogEntryUpdateManyWithWhereWithoutTriggeredByInput>>;
  deleteMany?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
};

export type PimsWritebackLogEntryUpdateOneRequiredWithoutCareAccountCreditLogNestedInput = {
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditLogInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditLogInput>;
  upsert?: Maybe<PimsWritebackLogEntryUpsertWithoutCareAccountCreditLogInput>;
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<PimsWritebackLogEntryUncheckedUpdateWithoutCareAccountCreditLogInput>;
};

export type PimsWritebackLogEntryUpdateOneRequiredWithoutCustomFieldsLogNestedInput = {
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutCustomFieldsLogInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutCustomFieldsLogInput>;
  upsert?: Maybe<PimsWritebackLogEntryUpsertWithoutCustomFieldsLogInput>;
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<PimsWritebackLogEntryUncheckedUpdateWithoutCustomFieldsLogInput>;
};

export type PimsWritebackLogEntryUpdateOneRequiredWithoutInvoiceLogNestedInput = {
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutInvoiceLogInput>;
  upsert?: Maybe<PimsWritebackLogEntryUpsertWithoutInvoiceLogInput>;
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<PimsWritebackLogEntryUncheckedUpdateWithoutInvoiceLogInput>;
};

export type PimsWritebackLogEntryUpdateOneRequiredWithoutPaymentLogNestedInput = {
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutPaymentLogInput>;
  upsert?: Maybe<PimsWritebackLogEntryUpsertWithoutPaymentLogInput>;
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<PimsWritebackLogEntryUncheckedUpdateWithoutPaymentLogInput>;
};

export type PimsWritebackLogEntryUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  data: PimsWritebackLogEntryUncheckedUpdateWithoutIntegrationInput;
};

export type PimsWritebackLogEntryUpdateWithWhereUniqueWithoutTriggeredByInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  data: PimsWritebackLogEntryUncheckedUpdateWithoutTriggeredByInput;
};

export type PimsWritebackLogEntryUpdateWithoutCareAccountCreditLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksNestedInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
};

export type PimsWritebackLogEntryUpdateWithoutCustomFieldsLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksNestedInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
};

export type PimsWritebackLogEntryUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksNestedInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
};

export type PimsWritebackLogEntryUpdateWithoutInvoiceLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksNestedInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
};

export type PimsWritebackLogEntryUpdateWithoutPaymentLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
};

export type PimsWritebackLogEntryUpdateWithoutTriggeredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
};

export type PimsWritebackLogEntryUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  update: PimsWritebackLogEntryUncheckedUpdateWithoutIntegrationInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutIntegrationInput;
};

export type PimsWritebackLogEntryUpsertWithWhereUniqueWithoutTriggeredByInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  update: PimsWritebackLogEntryUncheckedUpdateWithoutTriggeredByInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutTriggeredByInput;
};

export type PimsWritebackLogEntryUpsertWithoutCareAccountCreditLogInput = {
  update: PimsWritebackLogEntryUncheckedUpdateWithoutCareAccountCreditLogInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditLogInput;
};

export type PimsWritebackLogEntryUpsertWithoutCustomFieldsLogInput = {
  update: PimsWritebackLogEntryUncheckedUpdateWithoutCustomFieldsLogInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutCustomFieldsLogInput;
};

export type PimsWritebackLogEntryUpsertWithoutInvoiceLogInput = {
  update: PimsWritebackLogEntryUncheckedUpdateWithoutInvoiceLogInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput;
};

export type PimsWritebackLogEntryUpsertWithoutPaymentLogInput = {
  update: PimsWritebackLogEntryUncheckedUpdateWithoutPaymentLogInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput;
};

export type PimsWritebackLogEntryWhereInput = {
  AND?: Maybe<Array<PimsWritebackLogEntryWhereInput>>;
  OR?: Maybe<Array<PimsWritebackLogEntryWhereInput>>;
  NOT?: Maybe<Array<PimsWritebackLogEntryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumPimsWritebackStatusFilter>;
  type?: Maybe<EnumPimsWritebackTypeFilter>;
  pimsError?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  requestBody?: Maybe<JsonNullableFilter>;
  requestId?: Maybe<StringNullableFilter>;
  httpCode?: Maybe<IntNullableFilter>;
  normalizedError?: Maybe<EnumNormalizedWritebackErrorNullableFilter>;
  integrationId?: Maybe<StringFilter>;
  triggeredByUserId?: Maybe<StringNullableFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  triggeredBy?: Maybe<UserWhereInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
};

export type PimsWritebackLogEntryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum PimsWritebackStatus {
  Success = 'Success',
  Failure = 'Failure',
  Pending = 'Pending',
  PendingRetryAutomatic = 'Pending_RetryAutomatic',
  PendingRetryManual = 'Pending_RetryManual'
}

export enum PimsWritebackType {
  Payment = 'Payment',
  CustomField = 'CustomField'
}


export type Prescription = {
  __typename?: 'Prescription';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
  prescribingDoctor?: Maybe<ClinicEmployee>;
  clinic: Clinic;
  clinicPet: ClinicPet;
  integration: ClinicPimsIntegration;
  service?: Maybe<Service>;
};

export type PrescriptionAvgAggregateOutputType = {
  __typename?: 'PrescriptionAvgAggregateOutputType';
  quantity?: Maybe<Scalars['Float']>;
  currentRefillNumber?: Maybe<Scalars['Float']>;
  totalRefills?: Maybe<Scalars['Float']>;
};

export type PrescriptionAvgOrderByAggregateInput = {
  quantity?: Maybe<SortOrder>;
  currentRefillNumber?: Maybe<SortOrder>;
  totalRefills?: Maybe<SortOrder>;
};

export type PrescriptionCountAggregateOutputType = {
  __typename?: 'PrescriptionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  pimsId: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  serviceId: Scalars['Int'];
  quantity: Scalars['Int'];
  prescribingDoctorId: Scalars['Int'];
  description: Scalars['Int'];
  instructions: Scalars['Int'];
  comments: Scalars['Int'];
  issuedAt: Scalars['Int'];
  expiredAt: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  lastRequestedAt: Scalars['Int'];
  currentRefillNumber: Scalars['Int'];
  totalRefills: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PrescriptionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  prescribingDoctorId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
  currentRefillNumber?: Maybe<SortOrder>;
  totalRefills?: Maybe<SortOrder>;
};

export type PrescriptionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
};

export type PrescriptionCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionCreateManyClinicInputEnvelope = {
  data: Array<PrescriptionCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionCreateManyClinicPetInputEnvelope = {
  data: Array<PrescriptionCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionCreateManyIntegrationInputEnvelope = {
  data: Array<PrescriptionCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateManyPrescribingDoctorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionCreateManyPrescribingDoctorInputEnvelope = {
  data: Array<PrescriptionCreateManyPrescribingDoctorInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateManyServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionCreateManyServiceInputEnvelope = {
  data: Array<PrescriptionCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PrescriptionCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionCreateNestedManyWithoutPrescribingDoctorInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutPrescribingDoctorInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutPrescribingDoctorInput>>;
  createMany?: Maybe<PrescriptionCreateManyPrescribingDoctorInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<PrescriptionCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionCreateOrConnectWithoutClinicInput = {
  where: PrescriptionWhereUniqueInput;
  create: PrescriptionUncheckedCreateWithoutClinicInput;
};

export type PrescriptionCreateOrConnectWithoutClinicPetInput = {
  where: PrescriptionWhereUniqueInput;
  create: PrescriptionUncheckedCreateWithoutClinicPetInput;
};

export type PrescriptionCreateOrConnectWithoutIntegrationInput = {
  where: PrescriptionWhereUniqueInput;
  create: PrescriptionUncheckedCreateWithoutIntegrationInput;
};

export type PrescriptionCreateOrConnectWithoutPrescribingDoctorInput = {
  where: PrescriptionWhereUniqueInput;
  create: PrescriptionUncheckedCreateWithoutPrescribingDoctorInput;
};

export type PrescriptionCreateOrConnectWithoutServiceInput = {
  where: PrescriptionWhereUniqueInput;
  create: PrescriptionUncheckedCreateWithoutServiceInput;
};

export type PrescriptionCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
};

export type PrescriptionCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
};

export type PrescriptionCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
};

export type PrescriptionCreateWithoutPrescribingDoctorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
  clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
};

export type PrescriptionCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
};

export type PrescriptionListRelationFilter = {
  every?: Maybe<PrescriptionWhereInput>;
  some?: Maybe<PrescriptionWhereInput>;
  none?: Maybe<PrescriptionWhereInput>;
};

export type PrescriptionMaxAggregateOutputType = {
  __typename?: 'PrescriptionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  prescribingDoctorId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
  currentRefillNumber?: Maybe<SortOrder>;
  totalRefills?: Maybe<SortOrder>;
};

export type PrescriptionMinAggregateOutputType = {
  __typename?: 'PrescriptionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  prescribingDoctorId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
  currentRefillNumber?: Maybe<SortOrder>;
  totalRefills?: Maybe<SortOrder>;
};

export type PrescriptionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PrescriptionOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  ServiceId = 'serviceId',
  PrescribingDoctorId = 'prescribingDoctorId',
  Description = 'description',
  Instructions = 'instructions',
  Comments = 'comments'
}

export type PrescriptionOrderByRelevanceInput = {
  fields: Array<PrescriptionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PrescriptionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  prescribingDoctorId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
  currentRefillNumber?: Maybe<SortOrder>;
  totalRefills?: Maybe<SortOrder>;
  _count?: Maybe<PrescriptionCountOrderByAggregateInput>;
  _avg?: Maybe<PrescriptionAvgOrderByAggregateInput>;
  _max?: Maybe<PrescriptionMaxOrderByAggregateInput>;
  _min?: Maybe<PrescriptionMinOrderByAggregateInput>;
  _sum?: Maybe<PrescriptionSumOrderByAggregateInput>;
};

export type PrescriptionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  prescribingDoctorId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
  currentRefillNumber?: Maybe<SortOrder>;
  totalRefills?: Maybe<SortOrder>;
  prescribingDoctor?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PrescriptionOrderByRelevanceInput>;
};

export type PrescriptionPimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export enum PrescriptionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  ServiceId = 'serviceId',
  Quantity = 'quantity',
  PrescribingDoctorId = 'prescribingDoctorId',
  Description = 'description',
  Instructions = 'instructions',
  Comments = 'comments',
  IssuedAt = 'issuedAt',
  ExpiredAt = 'expiredAt',
  RawPimsValue = 'rawPimsValue',
  LastRequestedAt = 'lastRequestedAt',
  CurrentRefillNumber = 'currentRefillNumber',
  TotalRefills = 'totalRefills'
}

export type PrescriptionScalarWhereInput = {
  AND?: Maybe<Array<PrescriptionScalarWhereInput>>;
  OR?: Maybe<Array<PrescriptionScalarWhereInput>>;
  NOT?: Maybe<Array<PrescriptionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  serviceId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<FloatNullableFilter>;
  prescribingDoctorId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  instructions?: Maybe<StringNullableFilter>;
  comments?: Maybe<StringNullableFilter>;
  issuedAt?: Maybe<DateTimeNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  lastRequestedAt?: Maybe<DateTimeNullableFilter>;
  currentRefillNumber?: Maybe<IntNullableFilter>;
  totalRefills?: Maybe<IntNullableFilter>;
};

export type PrescriptionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PrescriptionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PrescriptionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PrescriptionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  serviceId?: Maybe<StringNullableWithAggregatesFilter>;
  quantity?: Maybe<FloatNullableWithAggregatesFilter>;
  prescribingDoctorId?: Maybe<StringNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  instructions?: Maybe<StringNullableWithAggregatesFilter>;
  comments?: Maybe<StringNullableWithAggregatesFilter>;
  issuedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  expiredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  lastRequestedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  currentRefillNumber?: Maybe<IntNullableWithAggregatesFilter>;
  totalRefills?: Maybe<IntNullableWithAggregatesFilter>;
};

export type PrescriptionSumAggregateOutputType = {
  __typename?: 'PrescriptionSumAggregateOutputType';
  quantity?: Maybe<Scalars['Float']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionSumOrderByAggregateInput = {
  quantity?: Maybe<SortOrder>;
  currentRefillNumber?: Maybe<SortOrder>;
  totalRefills?: Maybe<SortOrder>;
};

export type PrescriptionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PrescriptionCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutPrescribingDoctorInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutPrescribingDoctorInput>>;
  createMany?: Maybe<PrescriptionCreateManyPrescribingDoctorInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<PrescriptionCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUncheckedCreateWithoutPrescribingDoctorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUncheckedCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PrescriptionCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutPrescribingDoctorInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutPrescribingDoctorInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutPrescribingDoctorInput>>;
  createMany?: Maybe<PrescriptionCreateManyPrescribingDoctorInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutPrescribingDoctorInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutPrescribingDoctorInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUncheckedUpdateManyWithoutServiceNestedInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<PrescriptionCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUncheckedUpdateWithoutPrescribingDoctorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUncheckedUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsNestedInput>;
};

export type PrescriptionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionUpdateManyWithWhereWithoutClinicInput = {
  where: PrescriptionScalarWhereInput;
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
};

export type PrescriptionUpdateManyWithWhereWithoutClinicPetInput = {
  where: PrescriptionScalarWhereInput;
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
};

export type PrescriptionUpdateManyWithWhereWithoutIntegrationInput = {
  where: PrescriptionScalarWhereInput;
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
};

export type PrescriptionUpdateManyWithWhereWithoutPrescribingDoctorInput = {
  where: PrescriptionScalarWhereInput;
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
};

export type PrescriptionUpdateManyWithWhereWithoutServiceInput = {
  where: PrescriptionScalarWhereInput;
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
};

export type PrescriptionUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PrescriptionCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutPrescribingDoctorInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutPrescribingDoctorInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutPrescribingDoctorInput>>;
  createMany?: Maybe<PrescriptionCreateManyPrescribingDoctorInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutPrescribingDoctorInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutPrescribingDoctorInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUpdateManyWithoutServiceNestedInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<PrescriptionCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUpdateWithWhereUniqueWithoutClinicInput = {
  where: PrescriptionWhereUniqueInput;
  data: PrescriptionUncheckedUpdateWithoutClinicInput;
};

export type PrescriptionUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: PrescriptionWhereUniqueInput;
  data: PrescriptionUncheckedUpdateWithoutClinicPetInput;
};

export type PrescriptionUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: PrescriptionWhereUniqueInput;
  data: PrescriptionUncheckedUpdateWithoutIntegrationInput;
};

export type PrescriptionUpdateWithWhereUniqueWithoutPrescribingDoctorInput = {
  where: PrescriptionWhereUniqueInput;
  data: PrescriptionUncheckedUpdateWithoutPrescribingDoctorInput;
};

export type PrescriptionUpdateWithWhereUniqueWithoutServiceInput = {
  where: PrescriptionWhereUniqueInput;
  data: PrescriptionUncheckedUpdateWithoutServiceInput;
};

export type PrescriptionUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsNestedInput>;
};

export type PrescriptionUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsNestedInput>;
};

export type PrescriptionUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsNestedInput>;
};

export type PrescriptionUpdateWithoutPrescribingDoctorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsNestedInput>;
};

export type PrescriptionUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  totalRefills?: Maybe<Scalars['Int']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsNestedInput>;
};

export type PrescriptionUpsertWithWhereUniqueWithoutClinicInput = {
  where: PrescriptionWhereUniqueInput;
  update: PrescriptionUncheckedUpdateWithoutClinicInput;
  create: PrescriptionUncheckedCreateWithoutClinicInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: PrescriptionWhereUniqueInput;
  update: PrescriptionUncheckedUpdateWithoutClinicPetInput;
  create: PrescriptionUncheckedCreateWithoutClinicPetInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: PrescriptionWhereUniqueInput;
  update: PrescriptionUncheckedUpdateWithoutIntegrationInput;
  create: PrescriptionUncheckedCreateWithoutIntegrationInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutPrescribingDoctorInput = {
  where: PrescriptionWhereUniqueInput;
  update: PrescriptionUncheckedUpdateWithoutPrescribingDoctorInput;
  create: PrescriptionUncheckedCreateWithoutPrescribingDoctorInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutServiceInput = {
  where: PrescriptionWhereUniqueInput;
  update: PrescriptionUncheckedUpdateWithoutServiceInput;
  create: PrescriptionUncheckedCreateWithoutServiceInput;
};

export type PrescriptionWhereInput = {
  AND?: Maybe<Array<PrescriptionWhereInput>>;
  OR?: Maybe<Array<PrescriptionWhereInput>>;
  NOT?: Maybe<Array<PrescriptionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  serviceId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<FloatNullableFilter>;
  prescribingDoctorId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  instructions?: Maybe<StringNullableFilter>;
  comments?: Maybe<StringNullableFilter>;
  issuedAt?: Maybe<DateTimeNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  lastRequestedAt?: Maybe<DateTimeNullableFilter>;
  currentRefillNumber?: Maybe<IntNullableFilter>;
  totalRefills?: Maybe<IntNullableFilter>;
  prescribingDoctor?: Maybe<ClinicEmployeeWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  service?: Maybe<ServiceWhereInput>;
};

export type PrescriptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsIdAndIntegrationId?: Maybe<PrescriptionPimsIdAndIntegrationIdCompoundUniqueInput>;
};

export enum PricingModel {
  InterchangePlus = 'InterchangePlus',
  BlendedRate = 'BlendedRate'
}

export type PrivacyResponse = {
  __typename?: 'PrivacyResponse';
  success: Scalars['Boolean'];
  confirmation: Scalars['String'];
};

export type ProcessTerminalPaymentResponse = {
  __typename?: 'ProcessTerminalPaymentResponse';
  terminal: StripeTerminal;
  success: Scalars['Boolean'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  parentProductId?: Maybe<Scalars['String']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications: Array<ProductIndication>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  parent?: Maybe<Product>;
  variants: Array<Product>;
  media: Array<Media>;
  activeIngredients: Array<ActiveIngredient>;
  informPartner?: Maybe<InformPartner>;
  canonicalService: CanonicalService;
  _count: ProductCountOutputType;
};


export type ProductVariantsArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ProductScalarFieldEnum>;
};


export type ProductMediaArgs = {
  where?: Maybe<MediaWhereInput>;
  orderBy?: Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MediaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MediaScalarFieldEnum>;
};


export type ProductActiveIngredientsArgs = {
  where?: Maybe<ActiveIngredientWhereInput>;
  orderBy?: Maybe<ActiveIngredientOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ActiveIngredientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ActiveIngredientScalarFieldEnum>;
};

export type ProductAvgAggregateOutputType = {
  __typename?: 'ProductAvgAggregateOutputType';
  supplyDuration?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
};

export type ProductAvgOrderByAggregateInput = {
  supplyDuration?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
};

export type ProductCountAggregateOutputType = {
  __typename?: 'ProductCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  parentProductId: Scalars['Int'];
  type: Scalars['Int'];
  name: Scalars['Int'];
  description: Scalars['Int'];
  brand: Scalars['Int'];
  manufacturer: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  universalProductCode: Scalars['Int'];
  indications: Scalars['Int'];
  supplyDuration: Scalars['Int'];
  supplyDurationUnit: Scalars['Int'];
  quantity: Scalars['Int'];
  minWeightInKg: Scalars['Int'];
  maxWeightInKg: Scalars['Int'];
  details: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ProductCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentProductId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  universalProductCode?: Maybe<SortOrder>;
  indications?: Maybe<SortOrder>;
  supplyDuration?: Maybe<SortOrder>;
  supplyDurationUnit?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
};

export type ProductCountOutputType = {
  __typename?: 'ProductCountOutputType';
  variants: Scalars['Int'];
  media: Scalars['Int'];
  activeIngredients: Scalars['Int'];
};

export type ProductCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  media?: Maybe<MediaCreateNestedManyWithoutProductsInput>;
  activeIngredients?: Maybe<ActiveIngredientCreateNestedManyWithoutProductInput>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutProductsInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutProductInput>;
};

export type ProductCreateManyInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
};

export type ProductCreateManyInformPartnerInputEnvelope = {
  data: Array<ProductCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
};

export type ProductCreateManyParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
};

export type ProductCreateManyParentInputEnvelope = {
  data: Array<ProductCreateManyParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<ProductCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<ProductCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductCreateNestedManyWithoutMediaInput = {
  create?: Maybe<Array<ProductCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutMediaInput>>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductCreateNestedManyWithoutParentInput = {
  create?: Maybe<Array<ProductCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutParentInput>>;
  createMany?: Maybe<ProductCreateManyParentInputEnvelope>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductCreateNestedOneWithoutActiveIngredientsInput = {
  create?: Maybe<ProductUncheckedCreateWithoutActiveIngredientsInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutActiveIngredientsInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreateNestedOneWithoutCanonicalServiceInput = {
  create?: Maybe<ProductUncheckedCreateWithoutCanonicalServiceInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutCanonicalServiceInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreateNestedOneWithoutVariantsInput = {
  create?: Maybe<ProductUncheckedCreateWithoutVariantsInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutVariantsInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreateOrConnectWithoutActiveIngredientsInput = {
  where: ProductWhereUniqueInput;
  create: ProductUncheckedCreateWithoutActiveIngredientsInput;
};

export type ProductCreateOrConnectWithoutCanonicalServiceInput = {
  where: ProductWhereUniqueInput;
  create: ProductUncheckedCreateWithoutCanonicalServiceInput;
};

export type ProductCreateOrConnectWithoutInformPartnerInput = {
  where: ProductWhereUniqueInput;
  create: ProductUncheckedCreateWithoutInformPartnerInput;
};

export type ProductCreateOrConnectWithoutMediaInput = {
  where: ProductWhereUniqueInput;
  create: ProductUncheckedCreateWithoutMediaInput;
};

export type ProductCreateOrConnectWithoutParentInput = {
  where: ProductWhereUniqueInput;
  create: ProductUncheckedCreateWithoutParentInput;
};

export type ProductCreateOrConnectWithoutVariantsInput = {
  where: ProductWhereUniqueInput;
  create: ProductUncheckedCreateWithoutVariantsInput;
};

export type ProductCreateWithoutActiveIngredientsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  media?: Maybe<MediaCreateNestedManyWithoutProductsInput>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutProductsInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutProductInput>;
};

export type ProductCreateWithoutCanonicalServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  media?: Maybe<MediaCreateNestedManyWithoutProductsInput>;
  activeIngredients?: Maybe<ActiveIngredientCreateNestedManyWithoutProductInput>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutProductsInput>;
};

export type ProductCreateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  media?: Maybe<MediaCreateNestedManyWithoutProductsInput>;
  activeIngredients?: Maybe<ActiveIngredientCreateNestedManyWithoutProductInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutProductInput>;
};

export type ProductCreateWithoutMediaInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  activeIngredients?: Maybe<ActiveIngredientCreateNestedManyWithoutProductInput>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutProductsInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutProductInput>;
};

export type ProductCreateWithoutParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  media?: Maybe<MediaCreateNestedManyWithoutProductsInput>;
  activeIngredients?: Maybe<ActiveIngredientCreateNestedManyWithoutProductInput>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutProductsInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutProductInput>;
};

export type ProductCreateWithoutVariantsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  media?: Maybe<MediaCreateNestedManyWithoutProductsInput>;
  activeIngredients?: Maybe<ActiveIngredientCreateNestedManyWithoutProductInput>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutProductsInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutProductInput>;
};

export type ProductCreateindicationsInput = {
  set: Array<ProductIndication>;
};

export enum ProductIndication {
  Flea = 'Flea',
  Tick = 'Tick',
  Heartworm = 'Heartworm',
  Dental = 'Dental',
  Kidney = 'Kidney',
  Urinary = 'Urinary',
  CongestiveHeartFailure = 'CongestiveHeartFailure',
  PituitaryParsIntermediaDysfunction = 'PituitaryParsIntermediaDysfunction',
  Nsaid = 'NSAID',
  HeartDisease = 'HEART_DISEASE',
  Fleatick = 'FLEATICK',
  Combo = 'COMBO',
  Other = 'OTHER',
  JointSuppThera = 'JOINT_SUPP_THERA',
  Vaccine = 'VACCINE',
  DigestiveCare = 'DigestiveCare',
  SkinCare = 'SkinCare',
  WeightManagement = 'WeightManagement'
}

export type ProductListRelationFilter = {
  every?: Maybe<ProductWhereInput>;
  some?: Maybe<ProductWhereInput>;
  none?: Maybe<ProductWhereInput>;
};

export type ProductMaxAggregateOutputType = {
  __typename?: 'ProductMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
};

export type ProductMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentProductId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  universalProductCode?: Maybe<SortOrder>;
  supplyDuration?: Maybe<SortOrder>;
  supplyDurationUnit?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
};

export type ProductMinAggregateOutputType = {
  __typename?: 'ProductMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
};

export type ProductMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentProductId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  universalProductCode?: Maybe<SortOrder>;
  supplyDuration?: Maybe<SortOrder>;
  supplyDurationUnit?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
};

export type ProductOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ProductOrderByRelevanceFieldEnum {
  Id = 'id',
  ParentProductId = 'parentProductId',
  Name = 'name',
  Description = 'description',
  Brand = 'brand',
  Manufacturer = 'manufacturer',
  InformPartnerId = 'informPartnerId',
  UniversalProductCode = 'universalProductCode'
}

export type ProductOrderByRelevanceInput = {
  fields: Array<ProductOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ProductOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentProductId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  universalProductCode?: Maybe<SortOrder>;
  indications?: Maybe<SortOrder>;
  supplyDuration?: Maybe<SortOrder>;
  supplyDurationUnit?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  _count?: Maybe<ProductCountOrderByAggregateInput>;
  _avg?: Maybe<ProductAvgOrderByAggregateInput>;
  _max?: Maybe<ProductMaxOrderByAggregateInput>;
  _min?: Maybe<ProductMinOrderByAggregateInput>;
  _sum?: Maybe<ProductSumOrderByAggregateInput>;
};

export type ProductOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  parentProductId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  universalProductCode?: Maybe<SortOrder>;
  indications?: Maybe<SortOrder>;
  supplyDuration?: Maybe<SortOrder>;
  supplyDurationUnit?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  parent?: Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>;
  variants?: Maybe<ProductOrderByRelationAggregateInput>;
  media?: Maybe<MediaOrderByRelationAggregateInput>;
  activeIngredients?: Maybe<ActiveIngredientOrderByRelationAggregateInput>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  canonicalService?: Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ProductOrderByRelevanceInput>;
};

export type ProductRelationFilter = {
  is?: Maybe<ProductWhereInput>;
  isNot?: Maybe<ProductWhereInput>;
};

export enum ProductScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ParentProductId = 'parentProductId',
  Type = 'type',
  Name = 'name',
  Description = 'description',
  Brand = 'brand',
  Manufacturer = 'manufacturer',
  InformPartnerId = 'informPartnerId',
  UniversalProductCode = 'universalProductCode',
  Indications = 'indications',
  SupplyDuration = 'supplyDuration',
  SupplyDurationUnit = 'supplyDurationUnit',
  Quantity = 'quantity',
  MinWeightInKg = 'minWeightInKg',
  MaxWeightInKg = 'maxWeightInKg',
  Details = 'details'
}

export type ProductScalarWhereInput = {
  AND?: Maybe<Array<ProductScalarWhereInput>>;
  OR?: Maybe<Array<ProductScalarWhereInput>>;
  NOT?: Maybe<Array<ProductScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  parentProductId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumProductTypeFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  brand?: Maybe<StringFilter>;
  manufacturer?: Maybe<StringFilter>;
  informPartnerId?: Maybe<StringNullableFilter>;
  universalProductCode?: Maybe<StringNullableFilter>;
  indications?: Maybe<EnumProductIndicationNullableListFilter>;
  supplyDuration?: Maybe<FloatNullableFilter>;
  supplyDurationUnit?: Maybe<EnumProductSupplyDurationUnitNullableFilter>;
  quantity?: Maybe<IntNullableFilter>;
  minWeightInKg?: Maybe<FloatNullableFilter>;
  maxWeightInKg?: Maybe<FloatNullableFilter>;
  details?: Maybe<JsonNullableFilter>;
};

export type ProductScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ProductScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ProductScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ProductScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  parentProductId?: Maybe<StringNullableWithAggregatesFilter>;
  type?: Maybe<EnumProductTypeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  brand?: Maybe<StringWithAggregatesFilter>;
  manufacturer?: Maybe<StringWithAggregatesFilter>;
  informPartnerId?: Maybe<StringNullableWithAggregatesFilter>;
  universalProductCode?: Maybe<StringNullableWithAggregatesFilter>;
  indications?: Maybe<EnumProductIndicationNullableListFilter>;
  supplyDuration?: Maybe<FloatNullableWithAggregatesFilter>;
  supplyDurationUnit?: Maybe<EnumProductSupplyDurationUnitNullableWithAggregatesFilter>;
  quantity?: Maybe<IntNullableWithAggregatesFilter>;
  minWeightInKg?: Maybe<FloatNullableWithAggregatesFilter>;
  maxWeightInKg?: Maybe<FloatNullableWithAggregatesFilter>;
  details?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ProductSumAggregateOutputType = {
  __typename?: 'ProductSumAggregateOutputType';
  supplyDuration?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
};

export type ProductSumOrderByAggregateInput = {
  supplyDuration?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
};

export enum ProductSupplyDurationUnit {
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
  Years = 'Years'
}

export enum ProductType {
  Medication = 'Medication',
  Food = 'Food'
}

export type ProductTypeNameBrandManufacturerCompoundUniqueInput = {
  type: ProductType;
  name: Scalars['String'];
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
};

export type ProductUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  variants?: Maybe<ProductUncheckedCreateNestedManyWithoutParentInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutProductsInput>;
  activeIngredients?: Maybe<ActiveIngredientUncheckedCreateNestedManyWithoutProductInput>;
};

export type ProductUncheckedCreateNestedManyWithoutInformPartnerInput = {
  create?: Maybe<Array<ProductCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutInformPartnerInput>>;
  createMany?: Maybe<ProductCreateManyInformPartnerInputEnvelope>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductUncheckedCreateNestedManyWithoutMediaInput = {
  create?: Maybe<Array<ProductCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutMediaInput>>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductUncheckedCreateNestedManyWithoutParentInput = {
  create?: Maybe<Array<ProductCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutParentInput>>;
  createMany?: Maybe<ProductCreateManyParentInputEnvelope>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductUncheckedCreateNestedOneWithoutCanonicalServiceInput = {
  create?: Maybe<ProductUncheckedCreateWithoutCanonicalServiceInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutCanonicalServiceInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductUncheckedCreateWithoutActiveIngredientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  variants?: Maybe<ProductUncheckedCreateNestedManyWithoutParentInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutProductsInput>;
};

export type ProductUncheckedCreateWithoutCanonicalServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  variants?: Maybe<ProductUncheckedCreateNestedManyWithoutParentInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutProductsInput>;
  activeIngredients?: Maybe<ActiveIngredientUncheckedCreateNestedManyWithoutProductInput>;
};

export type ProductUncheckedCreateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  variants?: Maybe<ProductUncheckedCreateNestedManyWithoutParentInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutProductsInput>;
  activeIngredients?: Maybe<ActiveIngredientUncheckedCreateNestedManyWithoutProductInput>;
};

export type ProductUncheckedCreateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  variants?: Maybe<ProductUncheckedCreateNestedManyWithoutParentInput>;
  activeIngredients?: Maybe<ActiveIngredientUncheckedCreateNestedManyWithoutProductInput>;
};

export type ProductUncheckedCreateWithoutParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  variants?: Maybe<ProductUncheckedCreateNestedManyWithoutParentInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutProductsInput>;
  activeIngredients?: Maybe<ActiveIngredientUncheckedCreateNestedManyWithoutProductInput>;
};

export type ProductUncheckedCreateWithoutVariantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type: ProductType;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutProductsInput>;
  activeIngredients?: Maybe<ActiveIngredientUncheckedCreateNestedManyWithoutProductInput>;
};

export type ProductUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  variants?: Maybe<ProductUncheckedUpdateManyWithoutParentNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutProductsNestedInput>;
  activeIngredients?: Maybe<ActiveIngredientUncheckedUpdateManyWithoutProductNestedInput>;
};

export type ProductUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
};

export type ProductUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<ProductCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<ProductCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
};

export type ProductUncheckedUpdateManyWithoutMediaNestedInput = {
  create?: Maybe<Array<ProductCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutMediaInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutMediaInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutMediaInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
};

export type ProductUncheckedUpdateManyWithoutParentNestedInput = {
  create?: Maybe<Array<ProductCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutParentInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutParentInput>>;
  createMany?: Maybe<ProductCreateManyParentInputEnvelope>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutParentInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
};

export type ProductUncheckedUpdateManyWithoutProductsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
};

export type ProductUncheckedUpdateManyWithoutVariantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
};

export type ProductUncheckedUpdateOneWithoutCanonicalServiceNestedInput = {
  create?: Maybe<ProductUncheckedCreateWithoutCanonicalServiceInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutCanonicalServiceInput>;
  upsert?: Maybe<ProductUpsertWithoutCanonicalServiceInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ProductWhereUniqueInput>;
  update?: Maybe<ProductUncheckedUpdateWithoutCanonicalServiceInput>;
};

export type ProductUncheckedUpdateWithoutActiveIngredientsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  variants?: Maybe<ProductUncheckedUpdateManyWithoutParentNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutProductsNestedInput>;
};

export type ProductUncheckedUpdateWithoutCanonicalServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  variants?: Maybe<ProductUncheckedUpdateManyWithoutParentNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutProductsNestedInput>;
  activeIngredients?: Maybe<ActiveIngredientUncheckedUpdateManyWithoutProductNestedInput>;
};

export type ProductUncheckedUpdateWithoutInformPartnerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  variants?: Maybe<ProductUncheckedUpdateManyWithoutParentNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutProductsNestedInput>;
  activeIngredients?: Maybe<ActiveIngredientUncheckedUpdateManyWithoutProductNestedInput>;
};

export type ProductUncheckedUpdateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  variants?: Maybe<ProductUncheckedUpdateManyWithoutParentNestedInput>;
  activeIngredients?: Maybe<ActiveIngredientUncheckedUpdateManyWithoutProductNestedInput>;
};

export type ProductUncheckedUpdateWithoutParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  variants?: Maybe<ProductUncheckedUpdateManyWithoutParentNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutProductsNestedInput>;
  activeIngredients?: Maybe<ActiveIngredientUncheckedUpdateManyWithoutProductNestedInput>;
};

export type ProductUncheckedUpdateWithoutVariantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  parentProductId?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutProductsNestedInput>;
  activeIngredients?: Maybe<ActiveIngredientUncheckedUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsNestedInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutProductsNestedInput>;
  activeIngredients?: Maybe<ActiveIngredientUpdateManyWithoutProductNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutProductsNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutProductNestedInput>;
};

export type ProductUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
};

export type ProductUpdateManyWithWhereWithoutInformPartnerInput = {
  where: ProductScalarWhereInput;
  data: ProductUncheckedUpdateManyWithoutProductsInput;
};

export type ProductUpdateManyWithWhereWithoutMediaInput = {
  where: ProductScalarWhereInput;
  data: ProductUncheckedUpdateManyWithoutProductsInput;
};

export type ProductUpdateManyWithWhereWithoutParentInput = {
  where: ProductScalarWhereInput;
  data: ProductUncheckedUpdateManyWithoutVariantsInput;
};

export type ProductUpdateManyWithoutInformPartnerNestedInput = {
  create?: Maybe<Array<ProductCreateWithoutInformPartnerInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutInformPartnerInput>>;
  createMany?: Maybe<ProductCreateManyInformPartnerInputEnvelope>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutInformPartnerInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
};

export type ProductUpdateManyWithoutMediaNestedInput = {
  create?: Maybe<Array<ProductCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutMediaInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutMediaInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutMediaInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
};

export type ProductUpdateManyWithoutParentNestedInput = {
  create?: Maybe<Array<ProductCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutParentInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutParentInput>>;
  createMany?: Maybe<ProductCreateManyParentInputEnvelope>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutParentInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
};

export type ProductUpdateOneRequiredWithoutActiveIngredientsNestedInput = {
  create?: Maybe<ProductUncheckedCreateWithoutActiveIngredientsInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutActiveIngredientsInput>;
  upsert?: Maybe<ProductUpsertWithoutActiveIngredientsInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
  update?: Maybe<ProductUncheckedUpdateWithoutActiveIngredientsInput>;
};

export type ProductUpdateOneWithoutCanonicalServiceNestedInput = {
  create?: Maybe<ProductUncheckedCreateWithoutCanonicalServiceInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutCanonicalServiceInput>;
  upsert?: Maybe<ProductUpsertWithoutCanonicalServiceInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ProductWhereUniqueInput>;
  update?: Maybe<ProductUncheckedUpdateWithoutCanonicalServiceInput>;
};

export type ProductUpdateOneWithoutVariantsNestedInput = {
  create?: Maybe<ProductUncheckedCreateWithoutVariantsInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutVariantsInput>;
  upsert?: Maybe<ProductUpsertWithoutVariantsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ProductWhereUniqueInput>;
  update?: Maybe<ProductUncheckedUpdateWithoutVariantsInput>;
};

export type ProductUpdateWithWhereUniqueWithoutInformPartnerInput = {
  where: ProductWhereUniqueInput;
  data: ProductUncheckedUpdateWithoutInformPartnerInput;
};

export type ProductUpdateWithWhereUniqueWithoutMediaInput = {
  where: ProductWhereUniqueInput;
  data: ProductUncheckedUpdateWithoutMediaInput;
};

export type ProductUpdateWithWhereUniqueWithoutParentInput = {
  where: ProductWhereUniqueInput;
  data: ProductUncheckedUpdateWithoutParentInput;
};

export type ProductUpdateWithoutActiveIngredientsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsNestedInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutProductsNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutProductsNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutProductNestedInput>;
};

export type ProductUpdateWithoutCanonicalServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsNestedInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutProductsNestedInput>;
  activeIngredients?: Maybe<ActiveIngredientUpdateManyWithoutProductNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutProductsNestedInput>;
};

export type ProductUpdateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsNestedInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutProductsNestedInput>;
  activeIngredients?: Maybe<ActiveIngredientUpdateManyWithoutProductNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutProductNestedInput>;
};

export type ProductUpdateWithoutMediaInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsNestedInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentNestedInput>;
  activeIngredients?: Maybe<ActiveIngredientUpdateManyWithoutProductNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutProductsNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutProductNestedInput>;
};

export type ProductUpdateWithoutParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  variants?: Maybe<ProductUpdateManyWithoutParentNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutProductsNestedInput>;
  activeIngredients?: Maybe<ActiveIngredientUpdateManyWithoutProductNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutProductsNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutProductNestedInput>;
};

export type ProductUpdateWithoutVariantsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ProductType>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  universalProductCode?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  quantity?: Maybe<Scalars['Int']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['Json']>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutProductsNestedInput>;
  activeIngredients?: Maybe<ActiveIngredientUpdateManyWithoutProductNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutProductsNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutProductNestedInput>;
};

export type ProductUpdateindicationsInput = {
  set?: Maybe<Array<ProductIndication>>;
  push?: Maybe<Array<ProductIndication>>;
};

export type ProductUpsertWithWhereUniqueWithoutInformPartnerInput = {
  where: ProductWhereUniqueInput;
  update: ProductUncheckedUpdateWithoutInformPartnerInput;
  create: ProductUncheckedCreateWithoutInformPartnerInput;
};

export type ProductUpsertWithWhereUniqueWithoutMediaInput = {
  where: ProductWhereUniqueInput;
  update: ProductUncheckedUpdateWithoutMediaInput;
  create: ProductUncheckedCreateWithoutMediaInput;
};

export type ProductUpsertWithWhereUniqueWithoutParentInput = {
  where: ProductWhereUniqueInput;
  update: ProductUncheckedUpdateWithoutParentInput;
  create: ProductUncheckedCreateWithoutParentInput;
};

export type ProductUpsertWithoutActiveIngredientsInput = {
  update: ProductUncheckedUpdateWithoutActiveIngredientsInput;
  create: ProductUncheckedCreateWithoutActiveIngredientsInput;
};

export type ProductUpsertWithoutCanonicalServiceInput = {
  update: ProductUncheckedUpdateWithoutCanonicalServiceInput;
  create: ProductUncheckedCreateWithoutCanonicalServiceInput;
};

export type ProductUpsertWithoutVariantsInput = {
  update: ProductUncheckedUpdateWithoutVariantsInput;
  create: ProductUncheckedCreateWithoutVariantsInput;
};

export type ProductWhereInput = {
  AND?: Maybe<Array<ProductWhereInput>>;
  OR?: Maybe<Array<ProductWhereInput>>;
  NOT?: Maybe<Array<ProductWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  parentProductId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumProductTypeFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  brand?: Maybe<StringFilter>;
  manufacturer?: Maybe<StringFilter>;
  informPartnerId?: Maybe<StringNullableFilter>;
  universalProductCode?: Maybe<StringNullableFilter>;
  indications?: Maybe<EnumProductIndicationNullableListFilter>;
  supplyDuration?: Maybe<FloatNullableFilter>;
  supplyDurationUnit?: Maybe<EnumProductSupplyDurationUnitNullableFilter>;
  quantity?: Maybe<IntNullableFilter>;
  minWeightInKg?: Maybe<FloatNullableFilter>;
  maxWeightInKg?: Maybe<FloatNullableFilter>;
  details?: Maybe<JsonNullableFilter>;
  parent?: Maybe<ProductWhereInput>;
  variants?: Maybe<ProductListRelationFilter>;
  media?: Maybe<MediaListRelationFilter>;
  activeIngredients?: Maybe<ActiveIngredientListRelationFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  canonicalService?: Maybe<CanonicalServiceWhereInput>;
};

export type ProductWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  type_name_brand_manufacturer?: Maybe<ProductTypeNameBrandManufacturerCompoundUniqueInput>;
};

export enum ProfilePimsSyncStatus {
  Queued = 'Queued',
  InProgress = 'InProgress',
  Success = 'Success',
  Error = 'Error'
}

/** @deprecated - 1.0 */
export type PromoCode = {
  __typename?: 'PromoCode';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  code: Scalars['String'];
  created: Scalars['DateTime'];
  discount: Scalars['Float'];
  clinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
  clinic?: Maybe<Clinic>;
};

export type PromoCodeAvgAggregateOutputType = {
  __typename?: 'PromoCodeAvgAggregateOutputType';
  discount?: Maybe<Scalars['Float']>;
  amountUsed?: Maybe<Scalars['Float']>;
  amountAvailable?: Maybe<Scalars['Float']>;
};

export type PromoCodeAvgOrderByAggregateInput = {
  discount?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  amountAvailable?: Maybe<SortOrder>;
};

export type PromoCodeCountAggregateOutputType = {
  __typename?: 'PromoCodeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  code: Scalars['Int'];
  created: Scalars['Int'];
  discount: Scalars['Int'];
  clinicId: Scalars['Int'];
  firebaseId: Scalars['Int'];
  expiresAt: Scalars['Int'];
  amountUsed: Scalars['Int'];
  amountAvailable: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PromoCodeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  amountAvailable?: Maybe<SortOrder>;
};

export type PromoCodeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  discount: Scalars['Float'];
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutPromoCodesInput>;
};

export type PromoCodeCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  discount: Scalars['Float'];
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeCreateManyClinicInputEnvelope = {
  data: Array<PromoCodeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PromoCodeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  discount: Scalars['Float'];
  clinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<PromoCodeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PromoCodeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<PromoCodeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
};

export type PromoCodeCreateOrConnectWithoutClinicInput = {
  where: PromoCodeWhereUniqueInput;
  create: PromoCodeUncheckedCreateWithoutClinicInput;
};

export type PromoCodeCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  discount: Scalars['Float'];
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeListRelationFilter = {
  every?: Maybe<PromoCodeWhereInput>;
  some?: Maybe<PromoCodeWhereInput>;
  none?: Maybe<PromoCodeWhereInput>;
};

export type PromoCodeMaxAggregateOutputType = {
  __typename?: 'PromoCodeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  amountAvailable?: Maybe<SortOrder>;
};

export type PromoCodeMinAggregateOutputType = {
  __typename?: 'PromoCodeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  amountAvailable?: Maybe<SortOrder>;
};

export type PromoCodeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PromoCodeOrderByRelevanceFieldEnum {
  Id = 'id',
  Code = 'code',
  ClinicId = 'clinicId',
  FirebaseId = 'firebaseId'
}

export type PromoCodeOrderByRelevanceInput = {
  fields: Array<PromoCodeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PromoCodeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  amountAvailable?: Maybe<SortOrder>;
  _count?: Maybe<PromoCodeCountOrderByAggregateInput>;
  _avg?: Maybe<PromoCodeAvgOrderByAggregateInput>;
  _max?: Maybe<PromoCodeMaxOrderByAggregateInput>;
  _min?: Maybe<PromoCodeMinOrderByAggregateInput>;
  _sum?: Maybe<PromoCodeSumOrderByAggregateInput>;
};

export type PromoCodeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  amountAvailable?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PromoCodeOrderByRelevanceInput>;
};

export enum PromoCodeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Code = 'code',
  Created = 'created',
  Discount = 'discount',
  ClinicId = 'clinicId',
  FirebaseId = 'firebaseId',
  ExpiresAt = 'expiresAt',
  AmountUsed = 'amountUsed',
  AmountAvailable = 'amountAvailable'
}

export type PromoCodeScalarWhereInput = {
  AND?: Maybe<Array<PromoCodeScalarWhereInput>>;
  OR?: Maybe<Array<PromoCodeScalarWhereInput>>;
  NOT?: Maybe<Array<PromoCodeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  code?: Maybe<StringFilter>;
  created?: Maybe<DateTimeFilter>;
  discount?: Maybe<FloatFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  expiresAt?: Maybe<DateTimeFilter>;
  amountUsed?: Maybe<IntNullableFilter>;
  amountAvailable?: Maybe<IntNullableFilter>;
};

export type PromoCodeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PromoCodeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PromoCodeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PromoCodeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  code?: Maybe<StringWithAggregatesFilter>;
  created?: Maybe<DateTimeWithAggregatesFilter>;
  discount?: Maybe<FloatWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  expiresAt?: Maybe<DateTimeWithAggregatesFilter>;
  amountUsed?: Maybe<IntNullableWithAggregatesFilter>;
  amountAvailable?: Maybe<IntNullableWithAggregatesFilter>;
};

export type PromoCodeSumAggregateOutputType = {
  __typename?: 'PromoCodeSumAggregateOutputType';
  discount?: Maybe<Scalars['Float']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeSumOrderByAggregateInput = {
  discount?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  amountAvailable?: Maybe<SortOrder>;
};

export type PromoCodeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  discount: Scalars['Float'];
  clinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<PromoCodeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PromoCodeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<PromoCodeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
};

export type PromoCodeUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  discount: Scalars['Float'];
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<PromoCodeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PromoCodeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<PromoCodeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<PromoCodeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  disconnect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  delete?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  connect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  update?: Maybe<Array<PromoCodeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PromoCodeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<PromoCodeScalarWhereInput>>;
};

export type PromoCodeUncheckedUpdateManyWithoutPromoCodesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutPromoCodesNestedInput>;
};

export type PromoCodeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUpdateManyWithWhereWithoutClinicInput = {
  where: PromoCodeScalarWhereInput;
  data: PromoCodeUncheckedUpdateManyWithoutPromoCodesInput;
};

export type PromoCodeUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<PromoCodeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PromoCodeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<PromoCodeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<PromoCodeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  disconnect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  delete?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  connect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  update?: Maybe<Array<PromoCodeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PromoCodeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<PromoCodeScalarWhereInput>>;
};

export type PromoCodeUpdateWithWhereUniqueWithoutClinicInput = {
  where: PromoCodeWhereUniqueInput;
  data: PromoCodeUncheckedUpdateWithoutClinicInput;
};

export type PromoCodeUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUpsertWithWhereUniqueWithoutClinicInput = {
  where: PromoCodeWhereUniqueInput;
  update: PromoCodeUncheckedUpdateWithoutClinicInput;
  create: PromoCodeUncheckedCreateWithoutClinicInput;
};

export type PromoCodeWhereInput = {
  AND?: Maybe<Array<PromoCodeWhereInput>>;
  OR?: Maybe<Array<PromoCodeWhereInput>>;
  NOT?: Maybe<Array<PromoCodeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  code?: Maybe<StringFilter>;
  created?: Maybe<DateTimeFilter>;
  discount?: Maybe<FloatFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  expiresAt?: Maybe<DateTimeFilter>;
  amountUsed?: Maybe<IntNullableFilter>;
  amountAvailable?: Maybe<IntNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type PromoCodeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
};

export type ProviderAppointmentTypeRoomMap = {
  __typename?: 'ProviderAppointmentTypeRoomMap';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicEmployeeId: Scalars['String'];
  appoinmentTypeId?: Maybe<Scalars['String']>;
  roomId: Scalars['String'];
  clinicEmployee: ClinicEmployee;
  appointmentType?: Maybe<AppointmentType>;
  room: ClinicRoom;
};

export type ProviderAppointmentTypeRoomMapClinicEmployeeIdAppoinmentTypeIdRoomIdCompoundUniqueInput = {
  clinicEmployeeId: Scalars['String'];
  appoinmentTypeId: Scalars['String'];
  roomId: Scalars['String'];
};

export type ProviderAppointmentTypeRoomMapCountAggregateOutputType = {
  __typename?: 'ProviderAppointmentTypeRoomMapCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicEmployeeId: Scalars['Int'];
  appoinmentTypeId: Scalars['Int'];
  roomId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ProviderAppointmentTypeRoomMapCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
};

export type ProviderAppointmentTypeRoomMapCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput>;
  room: ClinicRoomCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput;
};

export type ProviderAppointmentTypeRoomMapCreateManyAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
  roomId: Scalars['String'];
};

export type ProviderAppointmentTypeRoomMapCreateManyAppointmentTypeInputEnvelope = {
  data: Array<ProviderAppointmentTypeRoomMapCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProviderAppointmentTypeRoomMapCreateManyClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  roomId: Scalars['String'];
};

export type ProviderAppointmentTypeRoomMapCreateManyClinicEmployeeInputEnvelope = {
  data: Array<ProviderAppointmentTypeRoomMapCreateManyClinicEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProviderAppointmentTypeRoomMapCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
  appoinmentTypeId?: Maybe<Scalars['String']>;
  roomId: Scalars['String'];
};

export type ProviderAppointmentTypeRoomMapCreateManyRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
  appoinmentTypeId?: Maybe<Scalars['String']>;
};

export type ProviderAppointmentTypeRoomMapCreateManyRoomInputEnvelope = {
  data: Array<ProviderAppointmentTypeRoomMapCreateManyRoomInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
};

export type ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
};

export type ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput = {
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutRoomInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyRoomInputEnvelope>;
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
};

export type ProviderAppointmentTypeRoomMapCreateOrConnectWithoutAppointmentTypeInput = {
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
  create: ProviderAppointmentTypeRoomMapUncheckedCreateWithoutAppointmentTypeInput;
};

export type ProviderAppointmentTypeRoomMapCreateOrConnectWithoutClinicEmployeeInput = {
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
  create: ProviderAppointmentTypeRoomMapUncheckedCreateWithoutClinicEmployeeInput;
};

export type ProviderAppointmentTypeRoomMapCreateOrConnectWithoutRoomInput = {
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
  create: ProviderAppointmentTypeRoomMapUncheckedCreateWithoutRoomInput;
};

export type ProviderAppointmentTypeRoomMapCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput;
  room: ClinicRoomCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput;
};

export type ProviderAppointmentTypeRoomMapCreateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput>;
  room: ClinicRoomCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput;
};

export type ProviderAppointmentTypeRoomMapCreateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput>;
};

export type ProviderAppointmentTypeRoomMapListRelationFilter = {
  every?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
  some?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
  none?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
};

export type ProviderAppointmentTypeRoomMapMaxAggregateOutputType = {
  __typename?: 'ProviderAppointmentTypeRoomMapMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
};

export type ProviderAppointmentTypeRoomMapMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
};

export type ProviderAppointmentTypeRoomMapMinAggregateOutputType = {
  __typename?: 'ProviderAppointmentTypeRoomMapMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
};

export type ProviderAppointmentTypeRoomMapMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
};

export type ProviderAppointmentTypeRoomMapOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ProviderAppointmentTypeRoomMapOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicEmployeeId = 'clinicEmployeeId',
  AppoinmentTypeId = 'appoinmentTypeId',
  RoomId = 'roomId'
}

export type ProviderAppointmentTypeRoomMapOrderByRelevanceInput = {
  fields: Array<ProviderAppointmentTypeRoomMapOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ProviderAppointmentTypeRoomMapOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  _count?: Maybe<ProviderAppointmentTypeRoomMapCountOrderByAggregateInput>;
  _max?: Maybe<ProviderAppointmentTypeRoomMapMaxOrderByAggregateInput>;
  _min?: Maybe<ProviderAppointmentTypeRoomMapMinOrderByAggregateInput>;
};

export type ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  clinicEmployee?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  room?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ProviderAppointmentTypeRoomMapOrderByRelevanceInput>;
};

export enum ProviderAppointmentTypeRoomMapScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicEmployeeId = 'clinicEmployeeId',
  AppoinmentTypeId = 'appoinmentTypeId',
  RoomId = 'roomId'
}

export type ProviderAppointmentTypeRoomMapScalarWhereInput = {
  AND?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
  OR?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
  NOT?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
  appoinmentTypeId?: Maybe<StringNullableFilter>;
  roomId?: Maybe<StringFilter>;
};

export type ProviderAppointmentTypeRoomMapScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicEmployeeId?: Maybe<StringWithAggregatesFilter>;
  appoinmentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  roomId?: Maybe<StringWithAggregatesFilter>;
};

export type ProviderAppointmentTypeRoomMapUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
  appoinmentTypeId?: Maybe<Scalars['String']>;
  roomId: Scalars['String'];
};

export type ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
};

export type ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
};

export type ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput = {
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutRoomInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyRoomInputEnvelope>;
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
};

export type ProviderAppointmentTypeRoomMapUncheckedCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
  roomId: Scalars['String'];
};

export type ProviderAppointmentTypeRoomMapUncheckedCreateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  roomId: Scalars['String'];
};

export type ProviderAppointmentTypeRoomMapUncheckedCreateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
  appoinmentTypeId?: Maybe<Scalars['String']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput = {
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  delete?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput = {
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  delete?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutProviderAppointmentTypeRoomMapInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput = {
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutRoomInput>>;
  upsert?: Maybe<Array<ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutRoomInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyRoomInputEnvelope>;
  set?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  delete?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutRoomInput>>;
  updateMany?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutRoomInput>>;
  deleteMany?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
};

export type ProviderAppointmentTypeRoomMapUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutProviderAppointmentTypeRoomMapNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput>;
};

export type ProviderAppointmentTypeRoomMapUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutAppointmentTypeInput = {
  where: ProviderAppointmentTypeRoomMapScalarWhereInput;
  data: ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutProviderAppointmentTypeRoomMapInput;
};

export type ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutClinicEmployeeInput = {
  where: ProviderAppointmentTypeRoomMapScalarWhereInput;
  data: ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutProviderAppointmentTypeRoomMapInput;
};

export type ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutRoomInput = {
  where: ProviderAppointmentTypeRoomMapScalarWhereInput;
  data: ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutProviderAppointmentTypeRoomMapInput;
};

export type ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput = {
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  delete?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
};

export type ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput = {
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  delete?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
};

export type ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput = {
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutRoomInput>>;
  upsert?: Maybe<Array<ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutRoomInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyRoomInputEnvelope>;
  set?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  delete?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutRoomInput>>;
  updateMany?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutRoomInput>>;
  deleteMany?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
};

export type ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
  data: ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutAppointmentTypeInput;
};

export type ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutClinicEmployeeInput = {
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
  data: ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutClinicEmployeeInput;
};

export type ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutRoomInput = {
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
  data: ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutRoomInput;
};

export type ProviderAppointmentTypeRoomMapUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput>;
};

export type ProviderAppointmentTypeRoomMapUpdateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutProviderAppointmentTypeRoomMapNestedInput>;
  room?: Maybe<ClinicRoomUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput>;
};

export type ProviderAppointmentTypeRoomMapUpdateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutProviderAppointmentTypeRoomMapNestedInput>;
};

export type ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
  update: ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutAppointmentTypeInput;
  create: ProviderAppointmentTypeRoomMapUncheckedCreateWithoutAppointmentTypeInput;
};

export type ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutClinicEmployeeInput = {
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
  update: ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutClinicEmployeeInput;
  create: ProviderAppointmentTypeRoomMapUncheckedCreateWithoutClinicEmployeeInput;
};

export type ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutRoomInput = {
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
  update: ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutRoomInput;
  create: ProviderAppointmentTypeRoomMapUncheckedCreateWithoutRoomInput;
};

export type ProviderAppointmentTypeRoomMapWhereInput = {
  AND?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereInput>>;
  OR?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereInput>>;
  NOT?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
  appoinmentTypeId?: Maybe<StringNullableFilter>;
  roomId?: Maybe<StringFilter>;
  clinicEmployee?: Maybe<ClinicEmployeeWhereInput>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
  room?: Maybe<ClinicRoomWhereInput>;
};

export type ProviderAppointmentTypeRoomMapWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicEmployeeId_appoinmentTypeId_roomId?: Maybe<ProviderAppointmentTypeRoomMapClinicEmployeeIdAppoinmentTypeIdRoomIdCompoundUniqueInput>;
};

export enum PushNotificationDeliveryErrorType {
  Unknown = 'Unknown',
  AuthorizationError = 'AuthorizationError',
  EndpointDisabled = 'EndpointDisabled',
  InternalError = 'InternalError',
  InvalidParameter = 'InvalidParameter'
}

export type PushNotificationLog = {
  __typename?: 'PushNotificationLog';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicPetParentDeviceId: Scalars['String'];
  status: PushNotificationLogStatusType;
  /** optionally included: the channel associated with this notification */
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  /** Our categorization of the error */
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  /** the error message given by the provider */
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPetParentDevice: ClinicPetParentDevice;
  sentChannelMessage?: Maybe<ChannelMessage>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  appointment?: Maybe<Appointment>;
  stripeRefund?: Maybe<StripeRefund>;
};

export type PushNotificationLogCountAggregateOutputType = {
  __typename?: 'PushNotificationLogCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicPetParentDeviceId: Scalars['Int'];
  status: Scalars['Int'];
  sentChannelMessageId: Scalars['Int'];
  clinicWidgetRequestId: Scalars['Int'];
  appointmentId: Scalars['Int'];
  stripeRefundId: Scalars['Int'];
  errorType: Scalars['Int'];
  providerErrorData: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PushNotificationLogCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentDeviceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
};

export type PushNotificationLogCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PushNotificationLogStatusType;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPetParentDevice: ClinicPetParentDeviceCreateNestedOneWithoutPushNotificationLogsInput;
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutPushNotificationLogsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutPushNotificationLogsInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPushNotificationLogsInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutPushNotificationLogsInput>;
};

export type PushNotificationLogCreateManyAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId: Scalars['String'];
  status: PushNotificationLogStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogCreateManyAppointmentInputEnvelope = {
  data: Array<PushNotificationLogCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PushNotificationLogCreateManyClinicPetParentDeviceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PushNotificationLogStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogCreateManyClinicPetParentDeviceInputEnvelope = {
  data: Array<PushNotificationLogCreateManyClinicPetParentDeviceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PushNotificationLogCreateManyClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId: Scalars['String'];
  status: PushNotificationLogStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogCreateManyClinicWidgetRequestInputEnvelope = {
  data: Array<PushNotificationLogCreateManyClinicWidgetRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PushNotificationLogCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId: Scalars['String'];
  status: PushNotificationLogStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogCreateManySentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId: Scalars['String'];
  status: PushNotificationLogStatusType;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogCreateManySentChannelMessageInputEnvelope = {
  data: Array<PushNotificationLogCreateManySentChannelMessageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PushNotificationLogCreateManyStripeRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId: Scalars['String'];
  status: PushNotificationLogStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogCreateManyStripeRefundInputEnvelope = {
  data: Array<PushNotificationLogCreateManyStripeRefundInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PushNotificationLogCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
};

export type PushNotificationLogCreateNestedManyWithoutClinicPetParentDeviceInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicPetParentDeviceInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicPetParentDeviceInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicPetParentDeviceInputEnvelope>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
};

export type PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicWidgetRequestInputEnvelope>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
};

export type PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutSentChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutSentChannelMessageInput>>;
  createMany?: Maybe<PushNotificationLogCreateManySentChannelMessageInputEnvelope>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
};

export type PushNotificationLogCreateNestedManyWithoutStripeRefundInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutStripeRefundInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutStripeRefundInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyStripeRefundInputEnvelope>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
};

export type PushNotificationLogCreateOrConnectWithoutAppointmentInput = {
  where: PushNotificationLogWhereUniqueInput;
  create: PushNotificationLogUncheckedCreateWithoutAppointmentInput;
};

export type PushNotificationLogCreateOrConnectWithoutClinicPetParentDeviceInput = {
  where: PushNotificationLogWhereUniqueInput;
  create: PushNotificationLogUncheckedCreateWithoutClinicPetParentDeviceInput;
};

export type PushNotificationLogCreateOrConnectWithoutClinicWidgetRequestInput = {
  where: PushNotificationLogWhereUniqueInput;
  create: PushNotificationLogUncheckedCreateWithoutClinicWidgetRequestInput;
};

export type PushNotificationLogCreateOrConnectWithoutSentChannelMessageInput = {
  where: PushNotificationLogWhereUniqueInput;
  create: PushNotificationLogUncheckedCreateWithoutSentChannelMessageInput;
};

export type PushNotificationLogCreateOrConnectWithoutStripeRefundInput = {
  where: PushNotificationLogWhereUniqueInput;
  create: PushNotificationLogUncheckedCreateWithoutStripeRefundInput;
};

export type PushNotificationLogCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PushNotificationLogStatusType;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPetParentDevice: ClinicPetParentDeviceCreateNestedOneWithoutPushNotificationLogsInput;
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutPushNotificationLogsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutPushNotificationLogsInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutPushNotificationLogsInput>;
};

export type PushNotificationLogCreateWithoutClinicPetParentDeviceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PushNotificationLogStatusType;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutPushNotificationLogsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutPushNotificationLogsInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPushNotificationLogsInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutPushNotificationLogsInput>;
};

export type PushNotificationLogCreateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PushNotificationLogStatusType;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPetParentDevice: ClinicPetParentDeviceCreateNestedOneWithoutPushNotificationLogsInput;
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutPushNotificationLogsInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPushNotificationLogsInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutPushNotificationLogsInput>;
};

export type PushNotificationLogCreateWithoutSentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PushNotificationLogStatusType;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPetParentDevice: ClinicPetParentDeviceCreateNestedOneWithoutPushNotificationLogsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutPushNotificationLogsInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPushNotificationLogsInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutPushNotificationLogsInput>;
};

export type PushNotificationLogCreateWithoutStripeRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PushNotificationLogStatusType;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPetParentDevice: ClinicPetParentDeviceCreateNestedOneWithoutPushNotificationLogsInput;
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutPushNotificationLogsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutPushNotificationLogsInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPushNotificationLogsInput>;
};

export type PushNotificationLogListRelationFilter = {
  every?: Maybe<PushNotificationLogWhereInput>;
  some?: Maybe<PushNotificationLogWhereInput>;
  none?: Maybe<PushNotificationLogWhereInput>;
};

export type PushNotificationLogMaxAggregateOutputType = {
  __typename?: 'PushNotificationLogMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentDeviceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
};

export type PushNotificationLogMinAggregateOutputType = {
  __typename?: 'PushNotificationLogMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentDeviceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
};

export type PushNotificationLogOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PushNotificationLogOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicPetParentDeviceId = 'clinicPetParentDeviceId',
  SentChannelMessageId = 'sentChannelMessageId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  AppointmentId = 'appointmentId',
  StripeRefundId = 'stripeRefundId',
  ProviderErrorData = 'providerErrorData'
}

export type PushNotificationLogOrderByRelevanceInput = {
  fields: Array<PushNotificationLogOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PushNotificationLogOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentDeviceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  _count?: Maybe<PushNotificationLogCountOrderByAggregateInput>;
  _max?: Maybe<PushNotificationLogMaxOrderByAggregateInput>;
  _min?: Maybe<PushNotificationLogMinOrderByAggregateInput>;
};

export type PushNotificationLogOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetParentDeviceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  clinicPetParentDevice?: Maybe<ClinicPetParentDeviceOrderByWithRelationAndSearchRelevanceInput>;
  sentChannelMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  stripeRefund?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PushNotificationLogOrderByRelevanceInput>;
};

export enum PushNotificationLogScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicPetParentDeviceId = 'clinicPetParentDeviceId',
  Status = 'status',
  SentChannelMessageId = 'sentChannelMessageId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  AppointmentId = 'appointmentId',
  StripeRefundId = 'stripeRefundId',
  ErrorType = 'errorType',
  ProviderErrorData = 'providerErrorData'
}

export type PushNotificationLogScalarWhereInput = {
  AND?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  OR?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  NOT?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicPetParentDeviceId?: Maybe<StringFilter>;
  status?: Maybe<EnumPushNotificationLogStatusTypeFilter>;
  sentChannelMessageId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  stripeRefundId?: Maybe<StringNullableFilter>;
  errorType?: Maybe<EnumPushNotificationDeliveryErrorTypeNullableFilter>;
  providerErrorData?: Maybe<StringNullableFilter>;
};

export type PushNotificationLogScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PushNotificationLogScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PushNotificationLogScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PushNotificationLogScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicPetParentDeviceId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumPushNotificationLogStatusTypeWithAggregatesFilter>;
  sentChannelMessageId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableWithAggregatesFilter>;
  appointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeRefundId?: Maybe<StringNullableWithAggregatesFilter>;
  errorType?: Maybe<EnumPushNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  providerErrorData?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum PushNotificationLogStatusType {
  Sent = 'Sent',
  Error = 'Error'
}

export type PushNotificationLogUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId: Scalars['String'];
  status: PushNotificationLogStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
};

export type PushNotificationLogUncheckedCreateNestedManyWithoutClinicPetParentDeviceInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicPetParentDeviceInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicPetParentDeviceInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicPetParentDeviceInputEnvelope>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
};

export type PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicWidgetRequestInputEnvelope>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
};

export type PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutSentChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutSentChannelMessageInput>>;
  createMany?: Maybe<PushNotificationLogCreateManySentChannelMessageInputEnvelope>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
};

export type PushNotificationLogUncheckedCreateNestedManyWithoutStripeRefundInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutStripeRefundInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutStripeRefundInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyStripeRefundInputEnvelope>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
};

export type PushNotificationLogUncheckedCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId: Scalars['String'];
  status: PushNotificationLogStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUncheckedCreateWithoutClinicPetParentDeviceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PushNotificationLogStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUncheckedCreateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId: Scalars['String'];
  status: PushNotificationLogStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUncheckedCreateWithoutSentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId: Scalars['String'];
  status: PushNotificationLogStatusType;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUncheckedCreateWithoutStripeRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId: Scalars['String'];
  status: PushNotificationLogStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
};

export type PushNotificationLogUncheckedUpdateManyWithoutClinicPetParentDeviceNestedInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicPetParentDeviceInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicPetParentDeviceInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutClinicPetParentDeviceInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicPetParentDeviceInputEnvelope>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutClinicPetParentDeviceInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutClinicPetParentDeviceInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
};

export type PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicWidgetRequestInputEnvelope>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
};

export type PushNotificationLogUncheckedUpdateManyWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutSentChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutSentChannelMessageInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutSentChannelMessageInput>>;
  createMany?: Maybe<PushNotificationLogCreateManySentChannelMessageInputEnvelope>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutSentChannelMessageInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutSentChannelMessageInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
};

export type PushNotificationLogUncheckedUpdateManyWithoutStripeRefundNestedInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutStripeRefundInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutStripeRefundInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutStripeRefundInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyStripeRefundInputEnvelope>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutStripeRefundInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutStripeRefundInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
};

export type PushNotificationLogUncheckedUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUncheckedUpdateWithoutClinicPetParentDeviceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PushNotificationLogStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUncheckedUpdateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUncheckedUpdateWithoutSentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUncheckedUpdateWithoutStripeRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PushNotificationLogStatusType>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPetParentDevice?: Maybe<ClinicPetParentDeviceUpdateOneRequiredWithoutPushNotificationLogsNestedInput>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutPushNotificationLogsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutPushNotificationLogsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPushNotificationLogsNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutPushNotificationLogsNestedInput>;
};

export type PushNotificationLogUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PushNotificationLogStatusType>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
};

export type PushNotificationLogUpdateManyWithWhereWithoutAppointmentInput = {
  where: PushNotificationLogScalarWhereInput;
  data: PushNotificationLogUncheckedUpdateManyWithoutPushNotificationLogsInput;
};

export type PushNotificationLogUpdateManyWithWhereWithoutClinicPetParentDeviceInput = {
  where: PushNotificationLogScalarWhereInput;
  data: PushNotificationLogUncheckedUpdateManyWithoutPushNotificationLogsInput;
};

export type PushNotificationLogUpdateManyWithWhereWithoutClinicWidgetRequestInput = {
  where: PushNotificationLogScalarWhereInput;
  data: PushNotificationLogUncheckedUpdateManyWithoutPushNotificationLogsInput;
};

export type PushNotificationLogUpdateManyWithWhereWithoutSentChannelMessageInput = {
  where: PushNotificationLogScalarWhereInput;
  data: PushNotificationLogUncheckedUpdateManyWithoutPushNotificationLogsInput;
};

export type PushNotificationLogUpdateManyWithWhereWithoutStripeRefundInput = {
  where: PushNotificationLogScalarWhereInput;
  data: PushNotificationLogUncheckedUpdateManyWithoutPushNotificationLogsInput;
};

export type PushNotificationLogUpdateManyWithoutAppointmentNestedInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
};

export type PushNotificationLogUpdateManyWithoutClinicPetParentDeviceNestedInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicPetParentDeviceInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicPetParentDeviceInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutClinicPetParentDeviceInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicPetParentDeviceInputEnvelope>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutClinicPetParentDeviceInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutClinicPetParentDeviceInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
};

export type PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicWidgetRequestInputEnvelope>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
};

export type PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutSentChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutSentChannelMessageInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutSentChannelMessageInput>>;
  createMany?: Maybe<PushNotificationLogCreateManySentChannelMessageInputEnvelope>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutSentChannelMessageInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutSentChannelMessageInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
};

export type PushNotificationLogUpdateManyWithoutStripeRefundNestedInput = {
  create?: Maybe<Array<PushNotificationLogCreateWithoutStripeRefundInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutStripeRefundInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutStripeRefundInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyStripeRefundInputEnvelope>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutStripeRefundInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutStripeRefundInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
};

export type PushNotificationLogUpdateWithWhereUniqueWithoutAppointmentInput = {
  where: PushNotificationLogWhereUniqueInput;
  data: PushNotificationLogUncheckedUpdateWithoutAppointmentInput;
};

export type PushNotificationLogUpdateWithWhereUniqueWithoutClinicPetParentDeviceInput = {
  where: PushNotificationLogWhereUniqueInput;
  data: PushNotificationLogUncheckedUpdateWithoutClinicPetParentDeviceInput;
};

export type PushNotificationLogUpdateWithWhereUniqueWithoutClinicWidgetRequestInput = {
  where: PushNotificationLogWhereUniqueInput;
  data: PushNotificationLogUncheckedUpdateWithoutClinicWidgetRequestInput;
};

export type PushNotificationLogUpdateWithWhereUniqueWithoutSentChannelMessageInput = {
  where: PushNotificationLogWhereUniqueInput;
  data: PushNotificationLogUncheckedUpdateWithoutSentChannelMessageInput;
};

export type PushNotificationLogUpdateWithWhereUniqueWithoutStripeRefundInput = {
  where: PushNotificationLogWhereUniqueInput;
  data: PushNotificationLogUncheckedUpdateWithoutStripeRefundInput;
};

export type PushNotificationLogUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PushNotificationLogStatusType>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPetParentDevice?: Maybe<ClinicPetParentDeviceUpdateOneRequiredWithoutPushNotificationLogsNestedInput>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutPushNotificationLogsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutPushNotificationLogsNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutPushNotificationLogsNestedInput>;
};

export type PushNotificationLogUpdateWithoutClinicPetParentDeviceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PushNotificationLogStatusType>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutPushNotificationLogsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutPushNotificationLogsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPushNotificationLogsNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutPushNotificationLogsNestedInput>;
};

export type PushNotificationLogUpdateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PushNotificationLogStatusType>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPetParentDevice?: Maybe<ClinicPetParentDeviceUpdateOneRequiredWithoutPushNotificationLogsNestedInput>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutPushNotificationLogsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPushNotificationLogsNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutPushNotificationLogsNestedInput>;
};

export type PushNotificationLogUpdateWithoutSentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PushNotificationLogStatusType>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPetParentDevice?: Maybe<ClinicPetParentDeviceUpdateOneRequiredWithoutPushNotificationLogsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutPushNotificationLogsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPushNotificationLogsNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutPushNotificationLogsNestedInput>;
};

export type PushNotificationLogUpdateWithoutStripeRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PushNotificationLogStatusType>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPetParentDevice?: Maybe<ClinicPetParentDeviceUpdateOneRequiredWithoutPushNotificationLogsNestedInput>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutPushNotificationLogsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutPushNotificationLogsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPushNotificationLogsNestedInput>;
};

export type PushNotificationLogUpsertWithWhereUniqueWithoutAppointmentInput = {
  where: PushNotificationLogWhereUniqueInput;
  update: PushNotificationLogUncheckedUpdateWithoutAppointmentInput;
  create: PushNotificationLogUncheckedCreateWithoutAppointmentInput;
};

export type PushNotificationLogUpsertWithWhereUniqueWithoutClinicPetParentDeviceInput = {
  where: PushNotificationLogWhereUniqueInput;
  update: PushNotificationLogUncheckedUpdateWithoutClinicPetParentDeviceInput;
  create: PushNotificationLogUncheckedCreateWithoutClinicPetParentDeviceInput;
};

export type PushNotificationLogUpsertWithWhereUniqueWithoutClinicWidgetRequestInput = {
  where: PushNotificationLogWhereUniqueInput;
  update: PushNotificationLogUncheckedUpdateWithoutClinicWidgetRequestInput;
  create: PushNotificationLogUncheckedCreateWithoutClinicWidgetRequestInput;
};

export type PushNotificationLogUpsertWithWhereUniqueWithoutSentChannelMessageInput = {
  where: PushNotificationLogWhereUniqueInput;
  update: PushNotificationLogUncheckedUpdateWithoutSentChannelMessageInput;
  create: PushNotificationLogUncheckedCreateWithoutSentChannelMessageInput;
};

export type PushNotificationLogUpsertWithWhereUniqueWithoutStripeRefundInput = {
  where: PushNotificationLogWhereUniqueInput;
  update: PushNotificationLogUncheckedUpdateWithoutStripeRefundInput;
  create: PushNotificationLogUncheckedCreateWithoutStripeRefundInput;
};

export type PushNotificationLogWhereInput = {
  AND?: Maybe<Array<PushNotificationLogWhereInput>>;
  OR?: Maybe<Array<PushNotificationLogWhereInput>>;
  NOT?: Maybe<Array<PushNotificationLogWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicPetParentDeviceId?: Maybe<StringFilter>;
  status?: Maybe<EnumPushNotificationLogStatusTypeFilter>;
  sentChannelMessageId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  stripeRefundId?: Maybe<StringNullableFilter>;
  errorType?: Maybe<EnumPushNotificationDeliveryErrorTypeNullableFilter>;
  providerErrorData?: Maybe<StringNullableFilter>;
  clinicPetParentDevice?: Maybe<ClinicPetParentDeviceWhereInput>;
  sentChannelMessage?: Maybe<ChannelMessageWhereInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestWhereInput>;
  appointment?: Maybe<AppointmentWhereInput>;
  stripeRefund?: Maybe<StripeRefundWhereInput>;
};

export type PushNotificationLogWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum PushNotificationStatus {
  Unavailable = 'UNAVAILABLE',
  Denied = 'DENIED',
  Granted = 'GRANTED',
  Limited = 'LIMITED',
  Blocked = 'BLOCKED',
  Undetermined = 'UNDETERMINED'
}

export type Query = {
  __typename?: 'Query';
  findUniqueAppointment?: Maybe<Appointment>;
  findFirstAppointment?: Maybe<Appointment>;
  findManyAppointment?: Maybe<Array<Appointment>>;
  findManyAppointmentCount: Scalars['Int'];
  aggregateAppointment?: Maybe<AggregateAppointment>;
  findUniqueAppointmentAvailability?: Maybe<AppointmentAvailability>;
  findFirstAppointmentAvailability?: Maybe<AppointmentAvailability>;
  findManyAppointmentAvailability?: Maybe<Array<AppointmentAvailability>>;
  findManyAppointmentAvailabilityCount: Scalars['Int'];
  aggregateAppointmentAvailability?: Maybe<AggregateAppointmentAvailability>;
  findUniqueAppointmentType?: Maybe<AppointmentType>;
  findFirstAppointmentType?: Maybe<AppointmentType>;
  findManyAppointmentType?: Maybe<Array<AppointmentType>>;
  findManyAppointmentTypeCount: Scalars['Int'];
  aggregateAppointmentType?: Maybe<AggregateAppointmentType>;
  findUniqueBitwerxIntegration?: Maybe<BitwerxIntegration>;
  findFirstBitwerxIntegration?: Maybe<BitwerxIntegration>;
  findManyBitwerxIntegration?: Maybe<Array<BitwerxIntegration>>;
  findManyBitwerxIntegrationCount: Scalars['Int'];
  aggregateBitwerxIntegration?: Maybe<AggregateBitwerxIntegration>;
  findUniquePPCIntegration?: Maybe<PpcIntegration>;
  findFirstPPCIntegration?: Maybe<PpcIntegration>;
  findManyPPCIntegration?: Maybe<Array<PpcIntegration>>;
  findManyPPCIntegrationCount: Scalars['Int'];
  aggregatePPCIntegration?: Maybe<AggregatePpcIntegration>;
  findUniqueChannelAutomationStatus?: Maybe<ChannelAutomationStatus>;
  findFirstChannelAutomationStatus?: Maybe<ChannelAutomationStatus>;
  findManyChannelAutomationStatus?: Maybe<Array<ChannelAutomationStatus>>;
  findManyChannelAutomationStatusCount: Scalars['Int'];
  aggregateChannelAutomationStatus?: Maybe<AggregateChannelAutomationStatus>;
  findUniqueChannelCreationSource?: Maybe<ChannelCreationSource>;
  findFirstChannelCreationSource?: Maybe<ChannelCreationSource>;
  findManyChannelCreationSource?: Maybe<Array<ChannelCreationSource>>;
  findManyChannelCreationSourceCount: Scalars['Int'];
  aggregateChannelCreationSource?: Maybe<AggregateChannelCreationSource>;
  findUniqueChannel?: Maybe<Channel>;
  findFirstChannel?: Maybe<Channel>;
  findManyChannel?: Maybe<Array<Channel>>;
  findManyChannelCount: Scalars['Int'];
  aggregateChannel?: Maybe<AggregateChannel>;
  findUniqueChannelPin?: Maybe<ChannelPin>;
  findFirstChannelPin?: Maybe<ChannelPin>;
  findManyChannelPin?: Maybe<Array<ChannelPin>>;
  findManyChannelPinCount: Scalars['Int'];
  aggregateChannelPin?: Maybe<AggregateChannelPin>;
  findUniqueChannelMember?: Maybe<ChannelMember>;
  findFirstChannelMember?: Maybe<ChannelMember>;
  findManyChannelMember?: Maybe<Array<ChannelMember>>;
  findManyChannelMemberCount: Scalars['Int'];
  aggregateChannelMember?: Maybe<AggregateChannelMember>;
  findUniqueChannelMessage?: Maybe<ChannelMessage>;
  findFirstChannelMessage?: Maybe<ChannelMessage>;
  findManyChannelMessage?: Maybe<Array<ChannelMessage>>;
  findManyChannelMessageCount: Scalars['Int'];
  aggregateChannelMessage?: Maybe<AggregateChannelMessage>;
  findUniqueChannelMessageReaction?: Maybe<ChannelMessageReaction>;
  findFirstChannelMessageReaction?: Maybe<ChannelMessageReaction>;
  findManyChannelMessageReaction?: Maybe<Array<ChannelMessageReaction>>;
  findManyChannelMessageReactionCount: Scalars['Int'];
  aggregateChannelMessageReaction?: Maybe<AggregateChannelMessageReaction>;
  findUniqueChannelSelection?: Maybe<ChannelSelection>;
  findFirstChannelSelection?: Maybe<ChannelSelection>;
  findManyChannelSelection?: Maybe<Array<ChannelSelection>>;
  findManyChannelSelectionCount: Scalars['Int'];
  aggregateChannelSelection?: Maybe<AggregateChannelSelection>;
  findUniqueChannelMessageAttachment?: Maybe<ChannelMessageAttachment>;
  findFirstChannelMessageAttachment?: Maybe<ChannelMessageAttachment>;
  findManyChannelMessageAttachment?: Maybe<Array<ChannelMessageAttachment>>;
  findManyChannelMessageAttachmentCount: Scalars['Int'];
  aggregateChannelMessageAttachment?: Maybe<AggregateChannelMessageAttachment>;
  findUniqueChannelStatus?: Maybe<ChannelStatus>;
  findFirstChannelStatus?: Maybe<ChannelStatus>;
  findManyChannelStatus?: Maybe<Array<ChannelStatus>>;
  findManyChannelStatusCount: Scalars['Int'];
  aggregateChannelStatus?: Maybe<AggregateChannelStatus>;
  findUniqueChannelStatusChannelAssignee?: Maybe<ChannelStatusChannelAssignee>;
  findFirstChannelStatusChannelAssignee?: Maybe<ChannelStatusChannelAssignee>;
  findManyChannelStatusChannelAssignee?: Maybe<Array<ChannelStatusChannelAssignee>>;
  findManyChannelStatusChannelAssigneeCount: Scalars['Int'];
  aggregateChannelStatusChannelAssignee?: Maybe<AggregateChannelStatusChannelAssignee>;
  findUniqueClinic?: Maybe<Clinic>;
  findFirstClinic?: Maybe<Clinic>;
  findManyClinic?: Maybe<Array<Clinic>>;
  findManyClinicCount: Scalars['Int'];
  aggregateClinic?: Maybe<AggregateClinic>;
  findUniqueClinicEmployee?: Maybe<ClinicEmployee>;
  findFirstClinicEmployee?: Maybe<ClinicEmployee>;
  findManyClinicEmployee?: Maybe<Array<ClinicEmployee>>;
  findManyClinicEmployeeCount: Scalars['Int'];
  aggregateClinicEmployee?: Maybe<AggregateClinicEmployee>;
  findUniqueDirectBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSetting>;
  findFirstDirectBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSetting>;
  findManyDirectBookingAppointmentTypeSetting?: Maybe<Array<DirectBookingAppointmentTypeSetting>>;
  findManyDirectBookingAppointmentTypeSettingCount: Scalars['Int'];
  aggregateDirectBookingAppointmentTypeSetting?: Maybe<AggregateDirectBookingAppointmentTypeSetting>;
  findUniqueClinicEmployeeAppointmentTypeSetting?: Maybe<ClinicEmployeeAppointmentTypeSetting>;
  findFirstClinicEmployeeAppointmentTypeSetting?: Maybe<ClinicEmployeeAppointmentTypeSetting>;
  findManyClinicEmployeeAppointmentTypeSetting?: Maybe<Array<ClinicEmployeeAppointmentTypeSetting>>;
  findManyClinicEmployeeAppointmentTypeSettingCount: Scalars['Int'];
  aggregateClinicEmployeeAppointmentTypeSetting?: Maybe<AggregateClinicEmployeeAppointmentTypeSetting>;
  findUniqueClinicEntityPhoneNumber?: Maybe<ClinicEntityPhoneNumber>;
  findFirstClinicEntityPhoneNumber?: Maybe<ClinicEntityPhoneNumber>;
  findManyClinicEntityPhoneNumber?: Maybe<Array<ClinicEntityPhoneNumber>>;
  findManyClinicEntityPhoneNumberCount: Scalars['Int'];
  aggregateClinicEntityPhoneNumber?: Maybe<AggregateClinicEntityPhoneNumber>;
  findUniqueClinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfiguration>;
  findFirstClinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfiguration>;
  findManyClinicPaymentFeeConfiguration?: Maybe<Array<ClinicPaymentFeeConfiguration>>;
  findManyClinicPaymentFeeConfigurationCount: Scalars['Int'];
  aggregateClinicPaymentFeeConfiguration?: Maybe<AggregateClinicPaymentFeeConfiguration>;
  findUniqueInterchangeRate?: Maybe<InterchangeRate>;
  findFirstInterchangeRate?: Maybe<InterchangeRate>;
  findManyInterchangeRate?: Maybe<Array<InterchangeRate>>;
  findManyInterchangeRateCount: Scalars['Int'];
  aggregateInterchangeRate?: Maybe<AggregateInterchangeRate>;
  findUniqueClinicPet?: Maybe<ClinicPet>;
  findFirstClinicPet?: Maybe<ClinicPet>;
  findManyClinicPet?: Maybe<Array<ClinicPet>>;
  findManyClinicPetCount: Scalars['Int'];
  aggregateClinicPet?: Maybe<AggregateClinicPet>;
  findUniqueClinicPetAlert?: Maybe<ClinicPetAlert>;
  findFirstClinicPetAlert?: Maybe<ClinicPetAlert>;
  findManyClinicPetAlert?: Maybe<Array<ClinicPetAlert>>;
  findManyClinicPetAlertCount: Scalars['Int'];
  aggregateClinicPetAlert?: Maybe<AggregateClinicPetAlert>;
  findUniqueClinicPetParentAddress?: Maybe<ClinicPetParentAddress>;
  findFirstClinicPetParentAddress?: Maybe<ClinicPetParentAddress>;
  findManyClinicPetParentAddress?: Maybe<Array<ClinicPetParentAddress>>;
  findManyClinicPetParentAddressCount: Scalars['Int'];
  aggregateClinicPetParentAddress?: Maybe<AggregateClinicPetParentAddress>;
  findUniqueClinicPetParent?: Maybe<ClinicPetParent>;
  findFirstClinicPetParent?: Maybe<ClinicPetParent>;
  findManyClinicPetParent?: Maybe<Array<ClinicPetParent>>;
  findManyClinicPetParentCount: Scalars['Int'];
  aggregateClinicPetParent?: Maybe<AggregateClinicPetParent>;
  findUniqueClinicDirectBookingSetting?: Maybe<ClinicDirectBookingSetting>;
  findFirstClinicDirectBookingSetting?: Maybe<ClinicDirectBookingSetting>;
  findManyClinicDirectBookingSetting?: Maybe<Array<ClinicDirectBookingSetting>>;
  findManyClinicDirectBookingSettingCount: Scalars['Int'];
  aggregateClinicDirectBookingSetting?: Maybe<AggregateClinicDirectBookingSetting>;
  findUniqueDirectBookingExclusionRule?: Maybe<DirectBookingExclusionRule>;
  findFirstDirectBookingExclusionRule?: Maybe<DirectBookingExclusionRule>;
  findManyDirectBookingExclusionRule?: Maybe<Array<DirectBookingExclusionRule>>;
  findManyDirectBookingExclusionRuleCount: Scalars['Int'];
  findUniqueDirectBookingExclusionTimeSlot?: Maybe<DirectBookingExclusionTimeSlot>;
  findFirstDirectBookingExclusionTimeSlot?: Maybe<DirectBookingExclusionTimeSlot>;
  findManyDirectBookingExclusionTimeSlot?: Maybe<Array<DirectBookingExclusionTimeSlot>>;
  findManyDirectBookingExclusionTimeSlotCount: Scalars['Int'];
  findUniqueClinicEmailCampaignSetting?: Maybe<ClinicEmailCampaignSetting>;
  findFirstClinicEmailCampaignSetting?: Maybe<ClinicEmailCampaignSetting>;
  findManyClinicEmailCampaignSetting?: Maybe<Array<ClinicEmailCampaignSetting>>;
  findManyClinicEmailCampaignSettingCount: Scalars['Int'];
  aggregateClinicEmailCampaignSetting?: Maybe<AggregateClinicEmailCampaignSetting>;
  findUniqueClinicPostcardSetting?: Maybe<ClinicPostcardSetting>;
  findFirstClinicPostcardSetting?: Maybe<ClinicPostcardSetting>;
  findManyClinicPostcardSetting?: Maybe<Array<ClinicPostcardSetting>>;
  findManyClinicPostcardSettingCount: Scalars['Int'];
  aggregateClinicPostcardSetting?: Maybe<AggregateClinicPostcardSetting>;
  findUniqueIntegrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntry>;
  findFirstIntegrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntry>;
  findManyIntegrationOutageHistoryEntry?: Maybe<Array<IntegrationOutageHistoryEntry>>;
  findManyIntegrationOutageHistoryEntryCount: Scalars['Int'];
  aggregateIntegrationOutageHistoryEntry?: Maybe<AggregateIntegrationOutageHistoryEntry>;
  findUniqueClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntry>;
  findFirstClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntry>;
  findManyClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntry>>;
  findManyClinicPimsIntegrationCapabilityHistoryEntryCount: Scalars['Int'];
  aggregateClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<AggregateClinicPimsIntegrationCapabilityHistoryEntry>;
  findUniqueClinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  findFirstClinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  findManyClinicPimsIntegration?: Maybe<Array<ClinicPimsIntegration>>;
  findManyClinicPimsIntegrationCount: Scalars['Int'];
  aggregateClinicPimsIntegration?: Maybe<AggregateClinicPimsIntegration>;
  findUniqueSyncVetIntegration?: Maybe<SyncVetIntegration>;
  findFirstSyncVetIntegration?: Maybe<SyncVetIntegration>;
  findManySyncVetIntegration?: Maybe<Array<SyncVetIntegration>>;
  findManySyncVetIntegrationCount: Scalars['Int'];
  aggregateSyncVetIntegration?: Maybe<AggregateSyncVetIntegration>;
  findUniqueClinicPimsIntegrationCapability?: Maybe<ClinicPimsIntegrationCapability>;
  findFirstClinicPimsIntegrationCapability?: Maybe<ClinicPimsIntegrationCapability>;
  findManyClinicPimsIntegrationCapability?: Maybe<Array<ClinicPimsIntegrationCapability>>;
  findManyClinicPimsIntegrationCapabilityCount: Scalars['Int'];
  aggregateClinicPimsIntegrationCapability?: Maybe<AggregateClinicPimsIntegrationCapability>;
  findUniqueClinicRoom?: Maybe<ClinicRoom>;
  findFirstClinicRoom?: Maybe<ClinicRoom>;
  findManyClinicRoom?: Maybe<Array<ClinicRoom>>;
  findManyClinicRoomCount: Scalars['Int'];
  aggregateClinicRoom?: Maybe<AggregateClinicRoom>;
  findUniqueClinicSetting?: Maybe<ClinicSetting>;
  findFirstClinicSetting?: Maybe<ClinicSetting>;
  findManyClinicSetting?: Maybe<Array<ClinicSetting>>;
  findManyClinicSettingCount: Scalars['Int'];
  aggregateClinicSetting?: Maybe<AggregateClinicSetting>;
  findUniqueClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  findFirstClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  findManyClinicWidgetRequest?: Maybe<Array<ClinicWidgetRequest>>;
  findManyClinicWidgetRequestCount: Scalars['Int'];
  aggregateClinicWidgetRequest?: Maybe<AggregateClinicWidgetRequest>;
  findUniqueClinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  findFirstClinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  findManyClinicWidgetRequestType?: Maybe<Array<ClinicWidgetRequestType>>;
  findManyClinicWidgetRequestTypeCount: Scalars['Int'];
  aggregateClinicWidgetRequestType?: Maybe<AggregateClinicWidgetRequestType>;
  findUniqueClinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  findFirstClinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  findManyClinicWidgetSetting?: Maybe<Array<ClinicWidgetSetting>>;
  findManyClinicWidgetSettingCount: Scalars['Int'];
  aggregateClinicWidgetSetting?: Maybe<AggregateClinicWidgetSetting>;
  findUniqueConsultation?: Maybe<Consultation>;
  findFirstConsultation?: Maybe<Consultation>;
  findManyConsultation?: Maybe<Array<Consultation>>;
  findManyConsultationCount: Scalars['Int'];
  aggregateConsultation?: Maybe<AggregateConsultation>;
  findUniqueConsultationRequest?: Maybe<ConsultationRequest>;
  findFirstConsultationRequest?: Maybe<ConsultationRequest>;
  findManyConsultationRequest?: Maybe<Array<ConsultationRequest>>;
  findManyConsultationRequestCount: Scalars['Int'];
  aggregateConsultationRequest?: Maybe<AggregateConsultationRequest>;
  findUniqueDisbursement?: Maybe<Disbursement>;
  findFirstDisbursement?: Maybe<Disbursement>;
  findManyDisbursement?: Maybe<Array<Disbursement>>;
  findManyDisbursementCount: Scalars['Int'];
  aggregateDisbursement?: Maybe<AggregateDisbursement>;
  findUniqueDocumentation?: Maybe<Documentation>;
  findFirstDocumentation?: Maybe<Documentation>;
  findManyDocumentation?: Maybe<Array<Documentation>>;
  findManyDocumentationCount: Scalars['Int'];
  aggregateDocumentation?: Maybe<AggregateDocumentation>;
  findUniqueEzyVetIntegration?: Maybe<EzyVetIntegration>;
  findFirstEzyVetIntegration?: Maybe<EzyVetIntegration>;
  findManyEzyVetIntegration?: Maybe<Array<EzyVetIntegration>>;
  findManyEzyVetIntegrationCount: Scalars['Int'];
  aggregateEzyVetIntegration?: Maybe<AggregateEzyVetIntegration>;
  findUniqueFinancialTransaction?: Maybe<FinancialTransaction>;
  findFirstFinancialTransaction?: Maybe<FinancialTransaction>;
  findManyFinancialTransaction?: Maybe<Array<FinancialTransaction>>;
  findManyFinancialTransactionCount: Scalars['Int'];
  aggregateFinancialTransaction?: Maybe<AggregateFinancialTransaction>;
  findUniqueFinancialAdjustment?: Maybe<FinancialAdjustment>;
  findFirstFinancialAdjustment?: Maybe<FinancialAdjustment>;
  findManyFinancialAdjustment?: Maybe<Array<FinancialAdjustment>>;
  findManyFinancialAdjustmentCount: Scalars['Int'];
  aggregateFinancialAdjustment?: Maybe<AggregateFinancialAdjustment>;
  findUniqueFormSubmission?: Maybe<FormSubmission>;
  findFirstFormSubmission?: Maybe<FormSubmission>;
  findManyFormSubmission?: Maybe<Array<FormSubmission>>;
  findManyFormSubmissionCount: Scalars['Int'];
  aggregateFormSubmission?: Maybe<AggregateFormSubmission>;
  findUniqueFormTemplate?: Maybe<FormTemplate>;
  findFirstFormTemplate?: Maybe<FormTemplate>;
  findManyFormTemplate?: Maybe<Array<FormTemplate>>;
  findManyFormTemplateCount: Scalars['Int'];
  aggregateFormTemplate?: Maybe<AggregateFormTemplate>;
  findUniqueInstinctIntegration?: Maybe<InstinctIntegration>;
  findFirstInstinctIntegration?: Maybe<InstinctIntegration>;
  findManyInstinctIntegration?: Maybe<Array<InstinctIntegration>>;
  findManyInstinctIntegrationCount: Scalars['Int'];
  aggregateInstinctIntegration?: Maybe<AggregateInstinctIntegration>;
  findUniqueInvoice?: Maybe<Invoice>;
  findFirstInvoice?: Maybe<Invoice>;
  findManyInvoice?: Maybe<Array<Invoice>>;
  findManyInvoiceCount: Scalars['Int'];
  aggregateInvoice?: Maybe<AggregateInvoice>;
  findUniqueInvoiceLineItem?: Maybe<InvoiceLineItem>;
  findFirstInvoiceLineItem?: Maybe<InvoiceLineItem>;
  findManyInvoiceLineItem?: Maybe<Array<InvoiceLineItem>>;
  findManyInvoiceLineItemCount: Scalars['Int'];
  aggregateInvoiceLineItem?: Maybe<AggregateInvoiceLineItem>;
  findUniqueOrganization?: Maybe<Organization>;
  findFirstOrganization?: Maybe<Organization>;
  findManyOrganization?: Maybe<Array<Organization>>;
  findManyOrganizationCount: Scalars['Int'];
  aggregateOrganization?: Maybe<AggregateOrganization>;
  findUniqueOrganizationPet?: Maybe<OrganizationPet>;
  findFirstOrganizationPet?: Maybe<OrganizationPet>;
  findManyOrganizationPet?: Maybe<Array<OrganizationPet>>;
  findManyOrganizationPetCount: Scalars['Int'];
  aggregateOrganizationPet?: Maybe<AggregateOrganizationPet>;
  findUniqueOrganizationPetParent?: Maybe<OrganizationPetParent>;
  findFirstOrganizationPetParent?: Maybe<OrganizationPetParent>;
  findManyOrganizationPetParent?: Maybe<Array<OrganizationPetParent>>;
  findManyOrganizationPetParentCount: Scalars['Int'];
  aggregateOrganizationPetParent?: Maybe<AggregateOrganizationPetParent>;
  findUniqueOrganizationPetToOrganizationPetParent?: Maybe<OrganizationPetToOrganizationPetParent>;
  findFirstOrganizationPetToOrganizationPetParent?: Maybe<OrganizationPetToOrganizationPetParent>;
  findManyOrganizationPetToOrganizationPetParent?: Maybe<Array<OrganizationPetToOrganizationPetParent>>;
  findManyOrganizationPetToOrganizationPetParentCount: Scalars['Int'];
  aggregateOrganizationPetToOrganizationPetParent?: Maybe<AggregateOrganizationPetToOrganizationPetParent>;
  findUniquePermission?: Maybe<Permission>;
  findFirstPermission?: Maybe<Permission>;
  findManyPermission?: Maybe<Array<Permission>>;
  findManyPermissionCount: Scalars['Int'];
  aggregatePermission?: Maybe<AggregatePermission>;
  findUniquePetParentSetting?: Maybe<PetParentSetting>;
  findFirstPetParentSetting?: Maybe<PetParentSetting>;
  findManyPetParentSetting?: Maybe<Array<PetParentSetting>>;
  findManyPetParentSettingCount: Scalars['Int'];
  aggregatePetParentSetting?: Maybe<AggregatePetParentSetting>;
  findUniquePimsInvoice?: Maybe<PimsInvoice>;
  findFirstPimsInvoice?: Maybe<PimsInvoice>;
  findManyPimsInvoice?: Maybe<Array<PimsInvoice>>;
  findManyPimsInvoiceCount: Scalars['Int'];
  aggregatePimsInvoice?: Maybe<AggregatePimsInvoice>;
  findUniquePimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  findFirstPimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  findManyPimsInvoiceLineItem?: Maybe<Array<PimsInvoiceLineItem>>;
  findManyPimsInvoiceLineItemCount: Scalars['Int'];
  aggregatePimsInvoiceLineItem?: Maybe<AggregatePimsInvoiceLineItem>;
  findUniqueService?: Maybe<Service>;
  findFirstService?: Maybe<Service>;
  findManyService?: Maybe<Array<Service>>;
  findManyServiceCount: Scalars['Int'];
  aggregateService?: Maybe<AggregateService>;
  findUniqueServiceReminderTiming?: Maybe<ServiceReminderTiming>;
  findFirstServiceReminderTiming?: Maybe<ServiceReminderTiming>;
  findManyServiceReminderTiming?: Maybe<Array<ServiceReminderTiming>>;
  findManyServiceReminderTimingCount: Scalars['Int'];
  aggregateServiceReminderTiming?: Maybe<AggregateServiceReminderTiming>;
  findUniqueServiceReminder?: Maybe<ServiceReminder>;
  findFirstServiceReminder?: Maybe<ServiceReminder>;
  findManyServiceReminder?: Maybe<Array<ServiceReminder>>;
  findManyServiceReminderCount: Scalars['Int'];
  aggregateServiceReminder?: Maybe<AggregateServiceReminder>;
  findUniqueServiceReminderNotification?: Maybe<ServiceReminderNotification>;
  findFirstServiceReminderNotification?: Maybe<ServiceReminderNotification>;
  findManyServiceReminderNotification?: Maybe<Array<ServiceReminderNotification>>;
  findManyServiceReminderNotificationCount: Scalars['Int'];
  aggregateServiceReminderNotification?: Maybe<AggregateServiceReminderNotification>;
  findUniqueStaffRole?: Maybe<StaffRole>;
  findFirstStaffRole?: Maybe<StaffRole>;
  findManyStaffRole?: Maybe<Array<StaffRole>>;
  findManyStaffRoleCount: Scalars['Int'];
  aggregateStaffRole?: Maybe<AggregateStaffRole>;
  findUniqueStripeDispute?: Maybe<StripeDispute>;
  findFirstStripeDispute?: Maybe<StripeDispute>;
  findManyStripeDispute?: Maybe<Array<StripeDispute>>;
  findManyStripeDisputeCount: Scalars['Int'];
  aggregateStripeDispute?: Maybe<AggregateStripeDispute>;
  findUniqueStripePaymentIntent?: Maybe<StripePaymentIntent>;
  findFirstStripePaymentIntent?: Maybe<StripePaymentIntent>;
  findManyStripePaymentIntent?: Maybe<Array<StripePaymentIntent>>;
  findManyStripePaymentIntentCount: Scalars['Int'];
  aggregateStripePaymentIntent?: Maybe<AggregateStripePaymentIntent>;
  findUniqueStripePaymentMethod?: Maybe<StripePaymentMethod>;
  findFirstStripePaymentMethod?: Maybe<StripePaymentMethod>;
  findManyStripePaymentMethod?: Maybe<Array<StripePaymentMethod>>;
  findManyStripePaymentMethodCount: Scalars['Int'];
  aggregateStripePaymentMethod?: Maybe<AggregateStripePaymentMethod>;
  findUniqueStripePayout?: Maybe<StripePayout>;
  findFirstStripePayout?: Maybe<StripePayout>;
  findManyStripePayout?: Maybe<Array<StripePayout>>;
  findManyStripePayoutCount: Scalars['Int'];
  aggregateStripePayout?: Maybe<AggregateStripePayout>;
  findUniqueStripePayoutAccount?: Maybe<StripePayoutAccount>;
  findFirstStripePayoutAccount?: Maybe<StripePayoutAccount>;
  findManyStripePayoutAccount?: Maybe<Array<StripePayoutAccount>>;
  findManyStripePayoutAccountCount: Scalars['Int'];
  aggregateStripePayoutAccount?: Maybe<AggregateStripePayoutAccount>;
  findUniqueStripeReceipt?: Maybe<StripeReceipt>;
  findFirstStripeReceipt?: Maybe<StripeReceipt>;
  findManyStripeReceipt?: Maybe<Array<StripeReceipt>>;
  findManyStripeReceiptCount: Scalars['Int'];
  aggregateStripeReceipt?: Maybe<AggregateStripeReceipt>;
  findUniqueStripeReceiptHistory?: Maybe<StripeReceiptHistory>;
  findFirstStripeReceiptHistory?: Maybe<StripeReceiptHistory>;
  findManyStripeReceiptHistory?: Maybe<Array<StripeReceiptHistory>>;
  findManyStripeReceiptHistoryCount: Scalars['Int'];
  aggregateStripeReceiptHistory?: Maybe<AggregateStripeReceiptHistory>;
  findUniqueStripeRefund?: Maybe<StripeRefund>;
  findFirstStripeRefund?: Maybe<StripeRefund>;
  findManyStripeRefund?: Maybe<Array<StripeRefund>>;
  findManyStripeRefundCount: Scalars['Int'];
  aggregateStripeRefund?: Maybe<AggregateStripeRefund>;
  findUniqueStripeTerminal?: Maybe<StripeTerminal>;
  findFirstStripeTerminal?: Maybe<StripeTerminal>;
  findManyStripeTerminal?: Maybe<Array<StripeTerminal>>;
  findManyStripeTerminalCount: Scalars['Int'];
  aggregateStripeTerminal?: Maybe<AggregateStripeTerminal>;
  findUniqueStripeTerminalLocation?: Maybe<StripeTerminalLocation>;
  findFirstStripeTerminalLocation?: Maybe<StripeTerminalLocation>;
  findManyStripeTerminalLocation?: Maybe<Array<StripeTerminalLocation>>;
  findManyStripeTerminalLocationCount: Scalars['Int'];
  aggregateStripeTerminalLocation?: Maybe<AggregateStripeTerminalLocation>;
  findUniqueTransaction?: Maybe<Transaction>;
  findFirstTransaction?: Maybe<Transaction>;
  findManyTransaction?: Maybe<Array<Transaction>>;
  findManyTransactionCount: Scalars['Int'];
  aggregateTransaction?: Maybe<AggregateTransaction>;
  findUniqueUser?: Maybe<User>;
  findFirstUser?: Maybe<User>;
  findManyUser?: Maybe<Array<User>>;
  findManyUserCount: Scalars['Int'];
  aggregateUser?: Maybe<AggregateUser>;
  findUniqueUserAppointmentGrouping?: Maybe<UserAppointmentGrouping>;
  findFirstUserAppointmentGrouping?: Maybe<UserAppointmentGrouping>;
  findManyUserAppointmentGrouping?: Maybe<Array<UserAppointmentGrouping>>;
  findManyUserAppointmentGroupingCount: Scalars['Int'];
  aggregateUserAppointmentGrouping?: Maybe<AggregateUserAppointmentGrouping>;
  findUniqueUserNotificationSetting?: Maybe<UserNotificationSetting>;
  findFirstUserNotificationSetting?: Maybe<UserNotificationSetting>;
  findManyUserNotificationSetting?: Maybe<Array<UserNotificationSetting>>;
  findManyUserNotificationSettingCount: Scalars['Int'];
  aggregateUserNotificationSetting?: Maybe<AggregateUserNotificationSetting>;
  findUniqueUserSetting?: Maybe<UserSetting>;
  findFirstUserSetting?: Maybe<UserSetting>;
  findManyUserSetting?: Maybe<Array<UserSetting>>;
  findManyUserSettingCount: Scalars['Int'];
  aggregateUserSetting?: Maybe<AggregateUserSetting>;
  findUniqueChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  findFirstChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  findManyChannelFilterSelection?: Maybe<Array<ChannelFilterSelection>>;
  findManyChannelFilterSelectionCount: Scalars['Int'];
  aggregateChannelFilterSelection?: Maybe<AggregateChannelFilterSelection>;
  findUniqueUserChannelFilterSelection?: Maybe<UserChannelFilterSelection>;
  findFirstUserChannelFilterSelection?: Maybe<UserChannelFilterSelection>;
  findManyUserChannelFilterSelection?: Maybe<Array<UserChannelFilterSelection>>;
  findManyUserChannelFilterSelectionCount: Scalars['Int'];
  aggregateUserChannelFilterSelection?: Maybe<AggregateUserChannelFilterSelection>;
  findUniqueVetAvailability?: Maybe<VetAvailability>;
  findFirstVetAvailability?: Maybe<VetAvailability>;
  findManyVetAvailability?: Maybe<Array<VetAvailability>>;
  findManyVetAvailabilityCount: Scalars['Int'];
  aggregateVetAvailability?: Maybe<AggregateVetAvailability>;
  findUniqueProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMap>;
  findFirstProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMap>;
  findManyProviderAppointmentTypeRoomMap?: Maybe<Array<ProviderAppointmentTypeRoomMap>>;
  findManyProviderAppointmentTypeRoomMapCount: Scalars['Int'];
  aggregateProviderAppointmentTypeRoomMap?: Maybe<AggregateProviderAppointmentTypeRoomMap>;
  findUniqueVetAvailabilityMap?: Maybe<VetAvailabilityMap>;
  findFirstVetAvailabilityMap?: Maybe<VetAvailabilityMap>;
  findManyVetAvailabilityMap?: Maybe<Array<VetAvailabilityMap>>;
  findManyVetAvailabilityMapCount: Scalars['Int'];
  aggregateVetAvailabilityMap?: Maybe<AggregateVetAvailabilityMap>;
  findUniqueVetAvailabilityClinicRoomMap?: Maybe<VetAvailabilityClinicRoomMap>;
  findFirstVetAvailabilityClinicRoomMap?: Maybe<VetAvailabilityClinicRoomMap>;
  findManyVetAvailabilityClinicRoomMap?: Maybe<Array<VetAvailabilityClinicRoomMap>>;
  findManyVetAvailabilityClinicRoomMapCount: Scalars['Int'];
  aggregateVetAvailabilityClinicRoomMap?: Maybe<AggregateVetAvailabilityClinicRoomMap>;
  findUniqueClientConnectIntegration?: Maybe<ClientConnectIntegration>;
  findFirstClientConnectIntegration?: Maybe<ClientConnectIntegration>;
  findManyClientConnectIntegration?: Maybe<Array<ClientConnectIntegration>>;
  findManyClientConnectIntegrationCount: Scalars['Int'];
  aggregateClientConnectIntegration?: Maybe<AggregateClientConnectIntegration>;
  findUniqueVetInfo?: Maybe<VetInfo>;
  findFirstVetInfo?: Maybe<VetInfo>;
  findManyVetInfo?: Maybe<Array<VetInfo>>;
  findManyVetInfoCount: Scalars['Int'];
  aggregateVetInfo?: Maybe<AggregateVetInfo>;
  findUniqueWorkflowEventSetting?: Maybe<WorkflowEventSetting>;
  findFirstWorkflowEventSetting?: Maybe<WorkflowEventSetting>;
  findManyWorkflowEventSetting?: Maybe<Array<WorkflowEventSetting>>;
  findManyWorkflowEventSettingCount: Scalars['Int'];
  aggregateWorkflowEventSetting?: Maybe<AggregateWorkflowEventSetting>;
  findUniqueClinicOfficeHour?: Maybe<ClinicOfficeHour>;
  findFirstClinicOfficeHour?: Maybe<ClinicOfficeHour>;
  findManyClinicOfficeHour?: Maybe<Array<ClinicOfficeHour>>;
  findManyClinicOfficeHourCount: Scalars['Int'];
  aggregateClinicOfficeHour?: Maybe<AggregateClinicOfficeHour>;
  findUniqueEmailProviderLog?: Maybe<EmailProviderLog>;
  findFirstEmailProviderLog?: Maybe<EmailProviderLog>;
  findManyEmailProviderLog?: Maybe<Array<EmailProviderLog>>;
  findManyEmailProviderLogCount: Scalars['Int'];
  aggregateEmailProviderLog?: Maybe<AggregateEmailProviderLog>;
  findUniqueTwilioCallRecording?: Maybe<TwilioCallRecording>;
  findFirstTwilioCallRecording?: Maybe<TwilioCallRecording>;
  findManyTwilioCallRecording?: Maybe<Array<TwilioCallRecording>>;
  findManyTwilioCallRecordingCount: Scalars['Int'];
  aggregateTwilioCallRecording?: Maybe<AggregateTwilioCallRecording>;
  findUniqueCallRecording?: Maybe<CallRecording>;
  findFirstCallRecording?: Maybe<CallRecording>;
  findManyCallRecording?: Maybe<Array<CallRecording>>;
  findManyCallRecordingCount: Scalars['Int'];
  aggregateCallRecording?: Maybe<AggregateCallRecording>;
  findUniqueCallHistory?: Maybe<CallHistory>;
  findFirstCallHistory?: Maybe<CallHistory>;
  findManyCallHistory?: Maybe<Array<CallHistory>>;
  findManyCallHistoryCount: Scalars['Int'];
  aggregateCallHistory?: Maybe<AggregateCallHistory>;
  findUniqueCallParticipant?: Maybe<CallParticipant>;
  findFirstCallParticipant?: Maybe<CallParticipant>;
  findManyCallParticipant?: Maybe<Array<CallParticipant>>;
  findManyCallParticipantCount: Scalars['Int'];
  aggregateCallParticipant?: Maybe<AggregateCallParticipant>;
  findUniqueSmsNotificationStatus?: Maybe<SmsNotificationStatus>;
  findFirstSmsNotificationStatus?: Maybe<SmsNotificationStatus>;
  findManySmsNotificationStatus?: Maybe<Array<SmsNotificationStatus>>;
  findManySmsNotificationStatusCount: Scalars['Int'];
  aggregateSmsNotificationStatus?: Maybe<AggregateSmsNotificationStatus>;
  findUniqueFeatureFlag?: Maybe<FeatureFlag>;
  findFirstFeatureFlag?: Maybe<FeatureFlag>;
  findManyFeatureFlag?: Maybe<Array<FeatureFlag>>;
  findManyFeatureFlagCount: Scalars['Int'];
  aggregateFeatureFlag?: Maybe<AggregateFeatureFlag>;
  findUniqueCareAuthorizationRequest?: Maybe<CareAuthorizationRequest>;
  findFirstCareAuthorizationRequest?: Maybe<CareAuthorizationRequest>;
  findManyCareAuthorizationRequest?: Maybe<Array<CareAuthorizationRequest>>;
  findManyCareAuthorizationRequestCount: Scalars['Int'];
  aggregateCareAuthorizationRequest?: Maybe<AggregateCareAuthorizationRequest>;
  findUniqueCareBenefit?: Maybe<CareBenefit>;
  findFirstCareBenefit?: Maybe<CareBenefit>;
  findManyCareBenefit?: Maybe<Array<CareBenefit>>;
  findManyCareBenefitCount: Scalars['Int'];
  aggregateCareBenefit?: Maybe<AggregateCareBenefit>;
  findUniqueCarePlan?: Maybe<CarePlan>;
  findFirstCarePlan?: Maybe<CarePlan>;
  findManyCarePlan?: Maybe<Array<CarePlan>>;
  findManyCarePlanCount: Scalars['Int'];
  aggregateCarePlan?: Maybe<AggregateCarePlan>;
  findUniqueCareAddonPackage?: Maybe<CareAddonPackage>;
  findFirstCareAddonPackage?: Maybe<CareAddonPackage>;
  findManyCareAddonPackage?: Maybe<Array<CareAddonPackage>>;
  findManyCareAddonPackageCount: Scalars['Int'];
  aggregateCareAddonPackage?: Maybe<AggregateCareAddonPackage>;
  findUniqueCarePlanBenefitEnrollmentCarryover?: Maybe<CarePlanBenefitEnrollmentCarryover>;
  findFirstCarePlanBenefitEnrollmentCarryover?: Maybe<CarePlanBenefitEnrollmentCarryover>;
  findManyCarePlanBenefitEnrollmentCarryover?: Maybe<Array<CarePlanBenefitEnrollmentCarryover>>;
  findManyCarePlanBenefitEnrollmentCarryoverCount: Scalars['Int'];
  aggregateCarePlanBenefitEnrollmentCarryover?: Maybe<AggregateCarePlanBenefitEnrollmentCarryover>;
  findUniqueCarePlanBenefit?: Maybe<CarePlanBenefit>;
  findFirstCarePlanBenefit?: Maybe<CarePlanBenefit>;
  findManyCarePlanBenefit?: Maybe<Array<CarePlanBenefit>>;
  findManyCarePlanBenefitCount: Scalars['Int'];
  aggregateCarePlanBenefit?: Maybe<AggregateCarePlanBenefit>;
  findUniqueCareAddonPackageBenefit?: Maybe<CareAddonPackageBenefit>;
  findFirstCareAddonPackageBenefit?: Maybe<CareAddonPackageBenefit>;
  findManyCareAddonPackageBenefit?: Maybe<Array<CareAddonPackageBenefit>>;
  findManyCareAddonPackageBenefitCount: Scalars['Int'];
  aggregateCareAddonPackageBenefit?: Maybe<AggregateCareAddonPackageBenefit>;
  findUniqueEnrollmentSupportEvent?: Maybe<EnrollmentSupportEvent>;
  findFirstEnrollmentSupportEvent?: Maybe<EnrollmentSupportEvent>;
  findManyEnrollmentSupportEvent?: Maybe<Array<EnrollmentSupportEvent>>;
  findManyEnrollmentSupportEventCount: Scalars['Int'];
  aggregateEnrollmentSupportEvent?: Maybe<AggregateEnrollmentSupportEvent>;
  findUniqueCarePlanEnrollment?: Maybe<CarePlanEnrollment>;
  findFirstCarePlanEnrollment?: Maybe<CarePlanEnrollment>;
  findManyCarePlanEnrollment?: Maybe<Array<CarePlanEnrollment>>;
  findManyCarePlanEnrollmentCount: Scalars['Int'];
  aggregateCarePlanEnrollment?: Maybe<AggregateCarePlanEnrollment>;
  findUniqueCareAddonEnrollment?: Maybe<CareAddonEnrollment>;
  findFirstCareAddonEnrollment?: Maybe<CareAddonEnrollment>;
  findManyCareAddonEnrollment?: Maybe<Array<CareAddonEnrollment>>;
  findManyCareAddonEnrollmentCount: Scalars['Int'];
  aggregateCareAddonEnrollment?: Maybe<AggregateCareAddonEnrollment>;
  findUniqueCareEnrollmentToStripeInvoiceItem?: Maybe<CareEnrollmentToStripeInvoiceItem>;
  findFirstCareEnrollmentToStripeInvoiceItem?: Maybe<CareEnrollmentToStripeInvoiceItem>;
  findManyCareEnrollmentToStripeInvoiceItem?: Maybe<Array<CareEnrollmentToStripeInvoiceItem>>;
  findManyCareEnrollmentToStripeInvoiceItemCount: Scalars['Int'];
  aggregateCareEnrollmentToStripeInvoiceItem?: Maybe<AggregateCareEnrollmentToStripeInvoiceItem>;
  findUniqueCareBenefitUsage?: Maybe<CareBenefitUsage>;
  findFirstCareBenefitUsage?: Maybe<CareBenefitUsage>;
  findManyCareBenefitUsage?: Maybe<Array<CareBenefitUsage>>;
  findManyCareBenefitUsageCount: Scalars['Int'];
  aggregateCareBenefitUsage?: Maybe<AggregateCareBenefitUsage>;
  findUniqueCareBenefitUsageToInvoiceLineItem?: Maybe<CareBenefitUsageToInvoiceLineItem>;
  findFirstCareBenefitUsageToInvoiceLineItem?: Maybe<CareBenefitUsageToInvoiceLineItem>;
  findManyCareBenefitUsageToInvoiceLineItem?: Maybe<Array<CareBenefitUsageToInvoiceLineItem>>;
  findManyCareBenefitUsageToInvoiceLineItemCount: Scalars['Int'];
  aggregateCareBenefitUsageToInvoiceLineItem?: Maybe<AggregateCareBenefitUsageToInvoiceLineItem>;
  findUniqueCareStripeSubscription?: Maybe<CareStripeSubscription>;
  findFirstCareStripeSubscription?: Maybe<CareStripeSubscription>;
  findManyCareStripeSubscription?: Maybe<Array<CareStripeSubscription>>;
  findManyCareStripeSubscriptionCount: Scalars['Int'];
  aggregateCareStripeSubscription?: Maybe<AggregateCareStripeSubscription>;
  findUniqueStripeInvoice?: Maybe<StripeInvoice>;
  findFirstStripeInvoice?: Maybe<StripeInvoice>;
  findManyStripeInvoice?: Maybe<Array<StripeInvoice>>;
  findManyStripeInvoiceCount: Scalars['Int'];
  aggregateStripeInvoice?: Maybe<AggregateStripeInvoice>;
  findUniqueStripeInvoiceItem?: Maybe<StripeInvoiceItem>;
  findFirstStripeInvoiceItem?: Maybe<StripeInvoiceItem>;
  findManyStripeInvoiceItem?: Maybe<Array<StripeInvoiceItem>>;
  findManyStripeInvoiceItemCount: Scalars['Int'];
  aggregateStripeInvoiceItem?: Maybe<AggregateStripeInvoiceItem>;
  findUniqueStripeCustomer?: Maybe<StripeCustomer>;
  findFirstStripeCustomer?: Maybe<StripeCustomer>;
  findManyStripeCustomer?: Maybe<Array<StripeCustomer>>;
  findManyStripeCustomerCount: Scalars['Int'];
  aggregateStripeCustomer?: Maybe<AggregateStripeCustomer>;
  findUniqueVaccination?: Maybe<Vaccination>;
  findFirstVaccination?: Maybe<Vaccination>;
  findManyVaccination?: Maybe<Array<Vaccination>>;
  findManyVaccinationCount: Scalars['Int'];
  aggregateVaccination?: Maybe<AggregateVaccination>;
  findUniquePimsWritebackLogEntry?: Maybe<PimsWritebackLogEntry>;
  findFirstPimsWritebackLogEntry?: Maybe<PimsWritebackLogEntry>;
  findManyPimsWritebackLogEntry?: Maybe<Array<PimsWritebackLogEntry>>;
  findManyPimsWritebackLogEntryCount: Scalars['Int'];
  aggregatePimsWritebackLogEntry?: Maybe<AggregatePimsWritebackLogEntry>;
  findUniqueCareAccountCreditIntent?: Maybe<CareAccountCreditIntent>;
  findFirstCareAccountCreditIntent?: Maybe<CareAccountCreditIntent>;
  findManyCareAccountCreditIntent?: Maybe<Array<CareAccountCreditIntent>>;
  findManyCareAccountCreditIntentCount: Scalars['Int'];
  aggregateCareAccountCreditIntent?: Maybe<AggregateCareAccountCreditIntent>;
  findUniqueCareAccountCreditPimsWritebackLogEntry?: Maybe<CareAccountCreditPimsWritebackLogEntry>;
  findFirstCareAccountCreditPimsWritebackLogEntry?: Maybe<CareAccountCreditPimsWritebackLogEntry>;
  findManyCareAccountCreditPimsWritebackLogEntry?: Maybe<Array<CareAccountCreditPimsWritebackLogEntry>>;
  findManyCareAccountCreditPimsWritebackLogEntryCount: Scalars['Int'];
  aggregateCareAccountCreditPimsWritebackLogEntry?: Maybe<AggregateCareAccountCreditPimsWritebackLogEntry>;
  findUniqueInvoicePimsWritebackLogEntry?: Maybe<InvoicePimsWritebackLogEntry>;
  findFirstInvoicePimsWritebackLogEntry?: Maybe<InvoicePimsWritebackLogEntry>;
  findManyInvoicePimsWritebackLogEntry?: Maybe<Array<InvoicePimsWritebackLogEntry>>;
  findManyInvoicePimsWritebackLogEntryCount: Scalars['Int'];
  aggregateInvoicePimsWritebackLogEntry?: Maybe<AggregateInvoicePimsWritebackLogEntry>;
  findUniqueOrganizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicService>;
  findFirstOrganizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicService>;
  findManyOrganizationCareBenefitToClinicService?: Maybe<Array<OrganizationCareBenefitToClinicService>>;
  findManyOrganizationCareBenefitToClinicServiceCount: Scalars['Int'];
  aggregateOrganizationCareBenefitToClinicService?: Maybe<AggregateOrganizationCareBenefitToClinicService>;
  findUniqueOrganizationCareBenefitToClinicServiceCategory?: Maybe<OrganizationCareBenefitToClinicServiceCategory>;
  findFirstOrganizationCareBenefitToClinicServiceCategory?: Maybe<OrganizationCareBenefitToClinicServiceCategory>;
  findManyOrganizationCareBenefitToClinicServiceCategory?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategory>>;
  findManyOrganizationCareBenefitToClinicServiceCategoryCount: Scalars['Int'];
  aggregateOrganizationCareBenefitToClinicServiceCategory?: Maybe<AggregateOrganizationCareBenefitToClinicServiceCategory>;
  findUniqueMessageTemplate?: Maybe<MessageTemplate>;
  findFirstMessageTemplate?: Maybe<MessageTemplate>;
  findManyMessageTemplate?: Maybe<Array<MessageTemplate>>;
  findManyMessageTemplateCount: Scalars['Int'];
  aggregateMessageTemplate?: Maybe<AggregateMessageTemplate>;
  findUniqueNotificationEventToMessage?: Maybe<NotificationEventToMessage>;
  findFirstNotificationEventToMessage?: Maybe<NotificationEventToMessage>;
  findManyNotificationEventToMessage?: Maybe<Array<NotificationEventToMessage>>;
  findManyNotificationEventToMessageCount: Scalars['Int'];
  aggregateNotificationEventToMessage?: Maybe<AggregateNotificationEventToMessage>;
  findUniqueRules?: Maybe<Rules>;
  findFirstRules?: Maybe<Rules>;
  findManyRules?: Maybe<Array<Rules>>;
  findManyRulesCount: Scalars['Int'];
  aggregateRules?: Maybe<AggregateRules>;
  findUniqueAutomationRun?: Maybe<AutomationRun>;
  findFirstAutomationRun?: Maybe<AutomationRun>;
  findManyAutomationRun?: Maybe<Array<AutomationRun>>;
  findManyAutomationRunCount: Scalars['Int'];
  aggregateAutomationRun?: Maybe<AggregateAutomationRun>;
  findUniqueAutomationRunAction?: Maybe<AutomationRunAction>;
  findFirstAutomationRunAction?: Maybe<AutomationRunAction>;
  findManyAutomationRunAction?: Maybe<Array<AutomationRunAction>>;
  findManyAutomationRunActionCount: Scalars['Int'];
  aggregateAutomationRunAction?: Maybe<AggregateAutomationRunAction>;
  findUniqueEmergencyClinics?: Maybe<EmergencyClinics>;
  findFirstEmergencyClinics?: Maybe<EmergencyClinics>;
  findManyEmergencyClinics?: Maybe<Array<EmergencyClinics>>;
  findManyEmergencyClinicsCount: Scalars['Int'];
  aggregateEmergencyClinics?: Maybe<AggregateEmergencyClinics>;
  findUniqueCareEnrollmentPayment?: Maybe<CareEnrollmentPayment>;
  findFirstCareEnrollmentPayment?: Maybe<CareEnrollmentPayment>;
  findManyCareEnrollmentPayment?: Maybe<Array<CareEnrollmentPayment>>;
  findManyCareEnrollmentPaymentCount: Scalars['Int'];
  aggregateCareEnrollmentPayment?: Maybe<AggregateCareEnrollmentPayment>;
  findUniqueMassTextAlert?: Maybe<MassTextAlert>;
  findFirstMassTextAlert?: Maybe<MassTextAlert>;
  findManyMassTextAlert?: Maybe<Array<MassTextAlert>>;
  findManyMassTextAlertCount: Scalars['Int'];
  aggregateMassTextAlert?: Maybe<AggregateMassTextAlert>;
  findUniqueMassTextAlertEntry?: Maybe<MassTextAlertEntry>;
  findFirstMassTextAlertEntry?: Maybe<MassTextAlertEntry>;
  findManyMassTextAlertEntry?: Maybe<Array<MassTextAlertEntry>>;
  findManyMassTextAlertEntryCount: Scalars['Int'];
  aggregateMassTextAlertEntry?: Maybe<AggregateMassTextAlertEntry>;
  findUniqueMassTextAlertEntryAppointment?: Maybe<MassTextAlertEntryAppointment>;
  findFirstMassTextAlertEntryAppointment?: Maybe<MassTextAlertEntryAppointment>;
  findManyMassTextAlertEntryAppointment?: Maybe<Array<MassTextAlertEntryAppointment>>;
  findManyMassTextAlertEntryAppointmentCount: Scalars['Int'];
  aggregateMassTextAlertEntryAppointment?: Maybe<AggregateMassTextAlertEntryAppointment>;
  findUniquePetPortalSetting?: Maybe<PetPortalSetting>;
  findFirstPetPortalSetting?: Maybe<PetPortalSetting>;
  findManyPetPortalSetting?: Maybe<Array<PetPortalSetting>>;
  findManyPetPortalSettingCount: Scalars['Int'];
  aggregatePetPortalSetting?: Maybe<AggregatePetPortalSetting>;
  findUniquePrescription?: Maybe<Prescription>;
  findFirstPrescription?: Maybe<Prescription>;
  findManyPrescription?: Maybe<Array<Prescription>>;
  findManyPrescriptionCount: Scalars['Int'];
  aggregatePrescription?: Maybe<AggregatePrescription>;
  findUniqueClinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  findFirstClinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  findManyClinicPhoneNumber?: Maybe<Array<ClinicPhoneNumber>>;
  findManyClinicPhoneNumberCount: Scalars['Int'];
  aggregateClinicPhoneNumber?: Maybe<AggregateClinicPhoneNumber>;
  findUniqueClinicOnboarding?: Maybe<ClinicOnboarding>;
  findFirstClinicOnboarding?: Maybe<ClinicOnboarding>;
  findManyClinicOnboarding?: Maybe<Array<ClinicOnboarding>>;
  findManyClinicOnboardingCount: Scalars['Int'];
  aggregateClinicOnboarding?: Maybe<AggregateClinicOnboarding>;
  findUniqueSurveyAppointmentTypeSetting?: Maybe<SurveyAppointmentTypeSetting>;
  findFirstSurveyAppointmentTypeSetting?: Maybe<SurveyAppointmentTypeSetting>;
  findManySurveyAppointmentTypeSetting?: Maybe<Array<SurveyAppointmentTypeSetting>>;
  findManySurveyAppointmentTypeSettingCount: Scalars['Int'];
  aggregateSurveyAppointmentTypeSetting?: Maybe<AggregateSurveyAppointmentTypeSetting>;
  findUniqueSurveySetting?: Maybe<SurveySetting>;
  findFirstSurveySetting?: Maybe<SurveySetting>;
  findManySurveySetting?: Maybe<Array<SurveySetting>>;
  findManySurveySettingCount: Scalars['Int'];
  aggregateSurveySetting?: Maybe<AggregateSurveySetting>;
  findUniqueSurveyResult?: Maybe<SurveyResult>;
  findFirstSurveyResult?: Maybe<SurveyResult>;
  findManySurveyResult?: Maybe<Array<SurveyResult>>;
  findManySurveyResultCount: Scalars['Int'];
  aggregateSurveyResult?: Maybe<AggregateSurveyResult>;
  findUniqueServiceCategory?: Maybe<ServiceCategory>;
  findFirstServiceCategory?: Maybe<ServiceCategory>;
  findManyServiceCategory?: Maybe<Array<ServiceCategory>>;
  findManyServiceCategoryCount: Scalars['Int'];
  aggregateServiceCategory?: Maybe<AggregateServiceCategory>;
  findUniquePimsPaymentType?: Maybe<PimsPaymentType>;
  findFirstPimsPaymentType?: Maybe<PimsPaymentType>;
  findManyPimsPaymentType?: Maybe<Array<PimsPaymentType>>;
  findManyPimsPaymentTypeCount: Scalars['Int'];
  aggregatePimsPaymentType?: Maybe<AggregatePimsPaymentType>;
  findUniqueBitwerxPaymentWritebackConfiguration?: Maybe<BitwerxPaymentWritebackConfiguration>;
  findFirstBitwerxPaymentWritebackConfiguration?: Maybe<BitwerxPaymentWritebackConfiguration>;
  findManyBitwerxPaymentWritebackConfigurationCount: Scalars['Int'];
  findUniqueGlobalPetParent?: Maybe<GlobalPetParent>;
  findFirstGlobalPetParent?: Maybe<GlobalPetParent>;
  findManyGlobalPetParent?: Maybe<Array<GlobalPetParent>>;
  findManyGlobalPetParentCount: Scalars['Int'];
  aggregateGlobalPetParent?: Maybe<AggregateGlobalPetParent>;
  findUniqueLoyaltyProgramStatusHistory?: Maybe<LoyaltyProgramStatusHistory>;
  findFirstLoyaltyProgramStatusHistory?: Maybe<LoyaltyProgramStatusHistory>;
  findManyLoyaltyProgramStatusHistory?: Maybe<Array<LoyaltyProgramStatusHistory>>;
  findManyLoyaltyProgramStatusHistoryCount: Scalars['Int'];
  aggregateLoyaltyProgramStatusHistory?: Maybe<AggregateLoyaltyProgramStatusHistory>;
  findUniqueLoyaltyProgram?: Maybe<LoyaltyProgram>;
  findFirstLoyaltyProgram?: Maybe<LoyaltyProgram>;
  findManyLoyaltyProgram?: Maybe<Array<LoyaltyProgram>>;
  findManyLoyaltyProgramCount: Scalars['Int'];
  aggregateLoyaltyProgram?: Maybe<AggregateLoyaltyProgram>;
  findUniqueLoyaltyAccountMerger?: Maybe<LoyaltyAccountMerger>;
  findFirstLoyaltyAccountMerger?: Maybe<LoyaltyAccountMerger>;
  findManyLoyaltyAccountMerger?: Maybe<Array<LoyaltyAccountMerger>>;
  findManyLoyaltyAccountMergerCount: Scalars['Int'];
  aggregateLoyaltyAccountMerger?: Maybe<AggregateLoyaltyAccountMerger>;
  findUniqueLoyaltyAccount?: Maybe<LoyaltyAccount>;
  findFirstLoyaltyAccount?: Maybe<LoyaltyAccount>;
  findManyLoyaltyAccount?: Maybe<Array<LoyaltyAccount>>;
  findManyLoyaltyAccountCount: Scalars['Int'];
  aggregateLoyaltyAccount?: Maybe<AggregateLoyaltyAccount>;
  findUniqueLoyaltyAccountHolder?: Maybe<LoyaltyAccountHolder>;
  findFirstLoyaltyAccountHolder?: Maybe<LoyaltyAccountHolder>;
  findManyLoyaltyAccountHolder?: Maybe<Array<LoyaltyAccountHolder>>;
  findManyLoyaltyAccountHolderCount: Scalars['Int'];
  aggregateLoyaltyAccountHolder?: Maybe<AggregateLoyaltyAccountHolder>;
  findUniqueLoyaltyReward?: Maybe<LoyaltyReward>;
  findFirstLoyaltyReward?: Maybe<LoyaltyReward>;
  findManyLoyaltyReward?: Maybe<Array<LoyaltyReward>>;
  findManyLoyaltyRewardCount: Scalars['Int'];
  aggregateLoyaltyReward?: Maybe<AggregateLoyaltyReward>;
  findUniqueLoyaltyRewardRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntry>;
  findFirstLoyaltyRewardRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntry>;
  findManyLoyaltyRewardRedemptionHistoryEntry?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntry>>;
  findManyLoyaltyRewardRedemptionHistoryEntryCount: Scalars['Int'];
  aggregateLoyaltyRewardRedemptionHistoryEntry?: Maybe<AggregateLoyaltyRewardRedemptionHistoryEntry>;
  findUniqueLoyaltyRewardRedemption?: Maybe<LoyaltyRewardRedemption>;
  findFirstLoyaltyRewardRedemption?: Maybe<LoyaltyRewardRedemption>;
  findManyLoyaltyRewardRedemption?: Maybe<Array<LoyaltyRewardRedemption>>;
  findManyLoyaltyRewardRedemptionCount: Scalars['Int'];
  aggregateLoyaltyRewardRedemption?: Maybe<AggregateLoyaltyRewardRedemption>;
  findUniqueLoyaltyPointDelta?: Maybe<LoyaltyPointDelta>;
  findFirstLoyaltyPointDelta?: Maybe<LoyaltyPointDelta>;
  findManyLoyaltyPointDelta?: Maybe<Array<LoyaltyPointDelta>>;
  findManyLoyaltyPointDeltaCount: Scalars['Int'];
  aggregateLoyaltyPointDelta?: Maybe<AggregateLoyaltyPointDelta>;
  findUniqueLoyaltyInvoicePointGranter?: Maybe<LoyaltyInvoicePointGranter>;
  findFirstLoyaltyInvoicePointGranter?: Maybe<LoyaltyInvoicePointGranter>;
  findManyLoyaltyInvoicePointGranter?: Maybe<Array<LoyaltyInvoicePointGranter>>;
  findManyLoyaltyInvoicePointGranterCount: Scalars['Int'];
  aggregateLoyaltyInvoicePointGranter?: Maybe<AggregateLoyaltyInvoicePointGranter>;
  findUniqueLoyaltyPointGrantingService?: Maybe<LoyaltyPointGrantingService>;
  findFirstLoyaltyPointGrantingService?: Maybe<LoyaltyPointGrantingService>;
  findManyLoyaltyPointGrantingService?: Maybe<Array<LoyaltyPointGrantingService>>;
  findManyLoyaltyPointGrantingServiceCount: Scalars['Int'];
  aggregateLoyaltyPointGrantingService?: Maybe<AggregateLoyaltyPointGrantingService>;
  findUniqueLoyaltyPointGrantingServiceCategory?: Maybe<LoyaltyPointGrantingServiceCategory>;
  findFirstLoyaltyPointGrantingServiceCategory?: Maybe<LoyaltyPointGrantingServiceCategory>;
  findManyLoyaltyPointGrantingServiceCategory?: Maybe<Array<LoyaltyPointGrantingServiceCategory>>;
  findManyLoyaltyPointGrantingServiceCategoryCount: Scalars['Int'];
  aggregateLoyaltyPointGrantingServiceCategory?: Maybe<AggregateLoyaltyPointGrantingServiceCategory>;
  findUniqueLoyaltyPointUserGrant?: Maybe<LoyaltyPointUserGrant>;
  findFirstLoyaltyPointUserGrant?: Maybe<LoyaltyPointUserGrant>;
  findManyLoyaltyPointUserGrant?: Maybe<Array<LoyaltyPointUserGrant>>;
  findManyLoyaltyPointUserGrantCount: Scalars['Int'];
  aggregateLoyaltyPointUserGrant?: Maybe<AggregateLoyaltyPointUserGrant>;
  findUniquePhoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequest>;
  findFirstPhoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequest>;
  findManyPhoneNumberLookupRequest?: Maybe<Array<PhoneNumberLookupRequest>>;
  findManyPhoneNumberLookupRequestCount: Scalars['Int'];
  aggregatePhoneNumberLookupRequest?: Maybe<AggregatePhoneNumberLookupRequest>;
  findUniquePhoneNumberLookupResult?: Maybe<PhoneNumberLookupResult>;
  findFirstPhoneNumberLookupResult?: Maybe<PhoneNumberLookupResult>;
  findManyPhoneNumberLookupResult?: Maybe<Array<PhoneNumberLookupResult>>;
  findManyPhoneNumberLookupResultCount: Scalars['Int'];
  aggregatePhoneNumberLookupResult?: Maybe<AggregatePhoneNumberLookupResult>;
  findUniqueClinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatus>;
  findFirstClinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatus>;
  findManyClinicPetPimsSyncStatus?: Maybe<Array<ClinicPetPimsSyncStatus>>;
  findManyClinicPetPimsSyncStatusCount: Scalars['Int'];
  aggregateClinicPetPimsSyncStatus?: Maybe<AggregateClinicPetPimsSyncStatus>;
  findUniqueClinicPetParentPimsSyncStatus?: Maybe<ClinicPetParentPimsSyncStatus>;
  findFirstClinicPetParentPimsSyncStatus?: Maybe<ClinicPetParentPimsSyncStatus>;
  findManyClinicPetParentPimsSyncStatus?: Maybe<Array<ClinicPetParentPimsSyncStatus>>;
  findManyClinicPetParentPimsSyncStatusCount: Scalars['Int'];
  aggregateClinicPetParentPimsSyncStatus?: Maybe<AggregateClinicPetParentPimsSyncStatus>;
  findUniqueCampaignRegistryCampaign?: Maybe<CampaignRegistryCampaign>;
  findFirstCampaignRegistryCampaign?: Maybe<CampaignRegistryCampaign>;
  findManyCampaignRegistryCampaign?: Maybe<Array<CampaignRegistryCampaign>>;
  findManyCampaignRegistryCampaignCount: Scalars['Int'];
  aggregateCampaignRegistryCampaign?: Maybe<AggregateCampaignRegistryCampaign>;
  findUniqueLegalEntity?: Maybe<LegalEntity>;
  findFirstLegalEntity?: Maybe<LegalEntity>;
  findManyLegalEntity?: Maybe<Array<LegalEntity>>;
  findManyLegalEntityCount: Scalars['Int'];
  aggregateLegalEntity?: Maybe<AggregateLegalEntity>;
  findUniqueClinicPetParentDevice?: Maybe<ClinicPetParentDevice>;
  findFirstClinicPetParentDevice?: Maybe<ClinicPetParentDevice>;
  findManyClinicPetParentDevice?: Maybe<Array<ClinicPetParentDevice>>;
  findManyClinicPetParentDeviceCount: Scalars['Int'];
  aggregateClinicPetParentDevice?: Maybe<AggregateClinicPetParentDevice>;
  findUniqueCareBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowup>;
  findFirstCareBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowup>;
  findManyCareBenefitUsageFollowup?: Maybe<Array<CareBenefitUsageFollowup>>;
  findManyCareBenefitUsageFollowupCount: Scalars['Int'];
  aggregateCareBenefitUsageFollowup?: Maybe<AggregateCareBenefitUsageFollowup>;
  findUniqueCareBenefitUsageFollowupStep?: Maybe<CareBenefitUsageFollowupStep>;
  findFirstCareBenefitUsageFollowupStep?: Maybe<CareBenefitUsageFollowupStep>;
  findManyCareBenefitUsageFollowupStep?: Maybe<Array<CareBenefitUsageFollowupStep>>;
  findManyCareBenefitUsageFollowupStepCount: Scalars['Int'];
  aggregateCareBenefitUsageFollowupStep?: Maybe<AggregateCareBenefitUsageFollowupStep>;
  findUniqueCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  findFirstCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  findManyCarePlanProviderGroup?: Maybe<Array<CarePlanProviderGroup>>;
  findManyCarePlanProviderGroupCount: Scalars['Int'];
  aggregateCarePlanProviderGroup?: Maybe<AggregateCarePlanProviderGroup>;
  findUniqueInsuranceIntegration?: Maybe<InsuranceIntegration>;
  findFirstInsuranceIntegration?: Maybe<InsuranceIntegration>;
  findManyInsuranceIntegration?: Maybe<Array<InsuranceIntegration>>;
  findManyInsuranceIntegrationCount: Scalars['Int'];
  aggregateInsuranceIntegration?: Maybe<AggregateInsuranceIntegration>;
  findUniqueTrupanionIntegration?: Maybe<TrupanionIntegration>;
  findFirstTrupanionIntegration?: Maybe<TrupanionIntegration>;
  findManyTrupanionIntegration?: Maybe<Array<TrupanionIntegration>>;
  findManyTrupanionIntegrationCount: Scalars['Int'];
  aggregateTrupanionIntegration?: Maybe<AggregateTrupanionIntegration>;
  findUniqueTrupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentType>;
  findFirstTrupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentType>;
  findManyTrupanionIntegratedAppointmentType?: Maybe<Array<TrupanionIntegratedAppointmentType>>;
  findManyTrupanionIntegratedAppointmentTypeCount: Scalars['Int'];
  aggregateTrupanionIntegratedAppointmentType?: Maybe<AggregateTrupanionIntegratedAppointmentType>;
  findUniqueSentTrupanionExamDayOffer?: Maybe<SentTrupanionExamDayOffer>;
  findFirstSentTrupanionExamDayOffer?: Maybe<SentTrupanionExamDayOffer>;
  findManySentTrupanionExamDayOffer?: Maybe<Array<SentTrupanionExamDayOffer>>;
  findManySentTrupanionExamDayOfferCount: Scalars['Int'];
  aggregateSentTrupanionExamDayOffer?: Maybe<AggregateSentTrupanionExamDayOffer>;
  findUniqueBreed?: Maybe<Breed>;
  findFirstBreed?: Maybe<Breed>;
  findManyBreed?: Maybe<Array<Breed>>;
  findManyBreedCount: Scalars['Int'];
  aggregateBreed?: Maybe<AggregateBreed>;
  findUniqueSpecies?: Maybe<Species>;
  findFirstSpecies?: Maybe<Species>;
  findManySpecies?: Maybe<Array<Species>>;
  findManySpeciesCount: Scalars['Int'];
  aggregateSpecies?: Maybe<AggregateSpecies>;
  findUniqueSex?: Maybe<Sex>;
  findFirstSex?: Maybe<Sex>;
  findManySex?: Maybe<Array<Sex>>;
  findManySexCount: Scalars['Int'];
  aggregateSex?: Maybe<AggregateSex>;
  findUniqueColor?: Maybe<Color>;
  findFirstColor?: Maybe<Color>;
  findManyColor?: Maybe<Array<Color>>;
  findManyColorCount: Scalars['Int'];
  aggregateColor?: Maybe<AggregateColor>;
  findUniqueMedia?: Maybe<Media>;
  findFirstMedia?: Maybe<Media>;
  findManyMedia?: Maybe<Array<Media>>;
  findManyMediaCount: Scalars['Int'];
  aggregateMedia?: Maybe<AggregateMedia>;
  findUniqueLapsedPetParentTriggers?: Maybe<LapsedPetParentTriggers>;
  findFirstLapsedPetParentTriggers?: Maybe<LapsedPetParentTriggers>;
  findManyLapsedPetParentTriggers?: Maybe<Array<LapsedPetParentTriggers>>;
  findManyLapsedPetParentTriggersCount: Scalars['Int'];
  aggregateLapsedPetParentTriggers?: Maybe<AggregateLapsedPetParentTriggers>;
  findUniqueConditionSet?: Maybe<ConditionSet>;
  findFirstConditionSet?: Maybe<ConditionSet>;
  findManyConditionSet?: Maybe<Array<ConditionSet>>;
  findManyConditionSetCount: Scalars['Int'];
  aggregateConditionSet?: Maybe<AggregateConditionSet>;
  findUniqueCondition?: Maybe<Condition>;
  findFirstCondition?: Maybe<Condition>;
  findManyCondition?: Maybe<Array<Condition>>;
  findManyConditionCount: Scalars['Int'];
  aggregateCondition?: Maybe<AggregateCondition>;
  findUniquePushNotificationLog?: Maybe<PushNotificationLog>;
  findFirstPushNotificationLog?: Maybe<PushNotificationLog>;
  findManyPushNotificationLog?: Maybe<Array<PushNotificationLog>>;
  findManyPushNotificationLogCount: Scalars['Int'];
  aggregatePushNotificationLog?: Maybe<AggregatePushNotificationLog>;
  findUniqueTrupanionCertificateAvailability?: Maybe<TrupanionCertificateAvailability>;
  findFirstTrupanionCertificateAvailability?: Maybe<TrupanionCertificateAvailability>;
  findManyTrupanionCertificateAvailability?: Maybe<Array<TrupanionCertificateAvailability>>;
  findManyTrupanionCertificateAvailabilityCount: Scalars['Int'];
  aggregateTrupanionCertificateAvailability?: Maybe<AggregateTrupanionCertificateAvailability>;
  findUniqueMessagingPartner?: Maybe<MessagingPartner>;
  findFirstMessagingPartner?: Maybe<MessagingPartner>;
  findManyMessagingPartner?: Maybe<Array<MessagingPartner>>;
  findManyMessagingPartnerCount: Scalars['Int'];
  aggregateMessagingPartner?: Maybe<AggregateMessagingPartner>;
  findUniqueMessagingCampaign?: Maybe<MessagingCampaign>;
  findFirstMessagingCampaign?: Maybe<MessagingCampaign>;
  findManyMessagingCampaign?: Maybe<Array<MessagingCampaign>>;
  findManyMessagingCampaignCount: Scalars['Int'];
  aggregateMessagingCampaign?: Maybe<AggregateMessagingCampaign>;
  findUniqueConditionalEntityEvaluation?: Maybe<ConditionalEntityEvaluation>;
  findFirstConditionalEntityEvaluation?: Maybe<ConditionalEntityEvaluation>;
  findManyConditionalEntityEvaluation?: Maybe<Array<ConditionalEntityEvaluation>>;
  findManyConditionalEntityEvaluationCount: Scalars['Int'];
  aggregateConditionalEntityEvaluation?: Maybe<AggregateConditionalEntityEvaluation>;
  findUniquePayoutBatchingPeriod?: Maybe<PayoutBatchingPeriod>;
  findFirstPayoutBatchingPeriod?: Maybe<PayoutBatchingPeriod>;
  findManyPayoutBatchingPeriod?: Maybe<Array<PayoutBatchingPeriod>>;
  findManyPayoutBatchingPeriodCount: Scalars['Int'];
  findUniqueBitwerxHealthcheck?: Maybe<BitwerxHealthcheck>;
  findFirstBitwerxHealthcheck?: Maybe<BitwerxHealthcheck>;
  findManyBitwerxHealthcheck?: Maybe<Array<BitwerxHealthcheck>>;
  findManyBitwerxHealthcheckCount: Scalars['Int'];
  aggregateBitwerxHealthcheck?: Maybe<AggregateBitwerxHealthcheck>;
  findUniqueBitwerxPaymentHealthcheckRun?: Maybe<BitwerxPaymentHealthcheckRun>;
  findFirstBitwerxPaymentHealthcheckRun?: Maybe<BitwerxPaymentHealthcheckRun>;
  findManyBitwerxPaymentHealthcheckRun?: Maybe<Array<BitwerxPaymentHealthcheckRun>>;
  findManyBitwerxPaymentHealthcheckRunCount: Scalars['Int'];
  aggregateBitwerxPaymentHealthcheckRun?: Maybe<AggregateBitwerxPaymentHealthcheckRun>;
  findUniqueBitwerxPaymentHealthcheckResult?: Maybe<BitwerxPaymentHealthcheckResult>;
  findFirstBitwerxPaymentHealthcheckResult?: Maybe<BitwerxPaymentHealthcheckResult>;
  findManyBitwerxPaymentHealthcheckResult?: Maybe<Array<BitwerxPaymentHealthcheckResult>>;
  findManyBitwerxPaymentHealthcheckResultCount: Scalars['Int'];
  aggregateBitwerxPaymentHealthcheckResult?: Maybe<AggregateBitwerxPaymentHealthcheckResult>;
  findUniqueCareLapsedPayment?: Maybe<CareLapsedPayment>;
  findFirstCareLapsedPayment?: Maybe<CareLapsedPayment>;
  findManyCareLapsedPayment?: Maybe<Array<CareLapsedPayment>>;
  findManyCareLapsedPaymentCount: Scalars['Int'];
  aggregateCareLapsedPayment?: Maybe<AggregateCareLapsedPayment>;
  findUniqueClinicPetParentCustomFieldValue?: Maybe<ClinicPetParentCustomFieldValue>;
  findFirstClinicPetParentCustomFieldValue?: Maybe<ClinicPetParentCustomFieldValue>;
  findManyClinicPetParentCustomFieldValue?: Maybe<Array<ClinicPetParentCustomFieldValue>>;
  findManyClinicPetParentCustomFieldValueCount: Scalars['Int'];
  aggregateClinicPetParentCustomFieldValue?: Maybe<AggregateClinicPetParentCustomFieldValue>;
  findUniqueClinicPetCustomFieldValue?: Maybe<ClinicPetCustomFieldValue>;
  findFirstClinicPetCustomFieldValue?: Maybe<ClinicPetCustomFieldValue>;
  findManyClinicPetCustomFieldValue?: Maybe<Array<ClinicPetCustomFieldValue>>;
  findManyClinicPetCustomFieldValueCount: Scalars['Int'];
  aggregateClinicPetCustomFieldValue?: Maybe<AggregateClinicPetCustomFieldValue>;
  findUniqueCustomFieldDefinition?: Maybe<CustomFieldDefinition>;
  findFirstCustomFieldDefinition?: Maybe<CustomFieldDefinition>;
  findManyCustomFieldDefinition?: Maybe<Array<CustomFieldDefinition>>;
  findManyCustomFieldDefinitionCount: Scalars['Int'];
  aggregateCustomFieldDefinition?: Maybe<AggregateCustomFieldDefinition>;
  findUniqueCustomFieldPimsWritebackLogEntry?: Maybe<CustomFieldPimsWritebackLogEntry>;
  findFirstCustomFieldPimsWritebackLogEntry?: Maybe<CustomFieldPimsWritebackLogEntry>;
  findManyCustomFieldPimsWritebackLogEntry?: Maybe<Array<CustomFieldPimsWritebackLogEntry>>;
  findManyCustomFieldPimsWritebackLogEntryCount: Scalars['Int'];
  aggregateCustomFieldPimsWritebackLogEntry?: Maybe<AggregateCustomFieldPimsWritebackLogEntry>;
  findUniqueEmailCampaign?: Maybe<EmailCampaign>;
  findFirstEmailCampaign?: Maybe<EmailCampaign>;
  findManyEmailCampaign?: Maybe<Array<EmailCampaign>>;
  findManyEmailCampaignCount: Scalars['Int'];
  aggregateEmailCampaign?: Maybe<AggregateEmailCampaign>;
  findUniqueEmailCampaignTemplate?: Maybe<EmailCampaignTemplate>;
  findFirstEmailCampaignTemplate?: Maybe<EmailCampaignTemplate>;
  findManyEmailCampaignTemplate?: Maybe<Array<EmailCampaignTemplate>>;
  findManyEmailCampaignTemplateCount: Scalars['Int'];
  aggregateEmailCampaignTemplate?: Maybe<AggregateEmailCampaignTemplate>;
  findUniqueEmailCampaignLog?: Maybe<EmailCampaignLog>;
  findFirstEmailCampaignLog?: Maybe<EmailCampaignLog>;
  findManyEmailCampaignLog?: Maybe<Array<EmailCampaignLog>>;
  findManyEmailCampaignLogCount: Scalars['Int'];
  aggregateEmailCampaignLog?: Maybe<AggregateEmailCampaignLog>;
  findUniqueEmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribe>;
  findFirstEmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribe>;
  findManyEmailCampaignUnsubscribe?: Maybe<Array<EmailCampaignUnsubscribe>>;
  findManyEmailCampaignUnsubscribeCount: Scalars['Int'];
  aggregateEmailCampaignUnsubscribe?: Maybe<AggregateEmailCampaignUnsubscribe>;
  findUniqueCanonicalSpecies?: Maybe<CanonicalSpecies>;
  findFirstCanonicalSpecies?: Maybe<CanonicalSpecies>;
  findManyCanonicalSpecies?: Maybe<Array<CanonicalSpecies>>;
  findManyCanonicalSpeciesCount: Scalars['Int'];
  aggregateCanonicalSpecies?: Maybe<AggregateCanonicalSpecies>;
  findUniqueCanonicalBreed?: Maybe<CanonicalBreed>;
  findFirstCanonicalBreed?: Maybe<CanonicalBreed>;
  findManyCanonicalBreed?: Maybe<Array<CanonicalBreed>>;
  findManyCanonicalBreedCount: Scalars['Int'];
  aggregateCanonicalBreed?: Maybe<AggregateCanonicalBreed>;
  findUniqueCanonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfile>;
  findFirstCanonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfile>;
  findManyCanonicalCanineBreedProfile?: Maybe<Array<CanonicalCanineBreedProfile>>;
  findManyCanonicalCanineBreedProfileCount: Scalars['Int'];
  aggregateCanonicalCanineBreedProfile?: Maybe<AggregateCanonicalCanineBreedProfile>;
  findUniqueCanonicalService?: Maybe<CanonicalService>;
  findFirstCanonicalService?: Maybe<CanonicalService>;
  findManyCanonicalService?: Maybe<Array<CanonicalService>>;
  findManyCanonicalServiceCount: Scalars['Int'];
  aggregateCanonicalService?: Maybe<AggregateCanonicalService>;
  findUniqueChemicalCompound?: Maybe<ChemicalCompound>;
  findFirstChemicalCompound?: Maybe<ChemicalCompound>;
  findManyChemicalCompound?: Maybe<Array<ChemicalCompound>>;
  findManyChemicalCompoundCount: Scalars['Int'];
  aggregateChemicalCompound?: Maybe<AggregateChemicalCompound>;
  findUniqueActiveIngredient?: Maybe<ActiveIngredient>;
  findFirstActiveIngredient?: Maybe<ActiveIngredient>;
  findManyActiveIngredient?: Maybe<Array<ActiveIngredient>>;
  findManyActiveIngredientCount: Scalars['Int'];
  aggregateActiveIngredient?: Maybe<AggregateActiveIngredient>;
  findUniqueProduct?: Maybe<Product>;
  findFirstProduct?: Maybe<Product>;
  findManyProduct?: Maybe<Array<Product>>;
  findManyProductCount: Scalars['Int'];
  aggregateProduct?: Maybe<AggregateProduct>;
  findUniqueInformPartner?: Maybe<InformPartner>;
  findFirstInformPartner?: Maybe<InformPartner>;
  findManyInformPartner?: Maybe<Array<InformPartner>>;
  findManyInformPartnerCount: Scalars['Int'];
  aggregateInformPartner?: Maybe<AggregateInformPartner>;
  findUniqueInformPartnerIntegration?: Maybe<InformPartnerIntegration>;
  findFirstInformPartnerIntegration?: Maybe<InformPartnerIntegration>;
  findManyInformPartnerIntegration?: Maybe<Array<InformPartnerIntegration>>;
  findManyInformPartnerIntegrationCount: Scalars['Int'];
  aggregateInformPartnerIntegration?: Maybe<AggregateInformPartnerIntegration>;
  findUniqueInformPartnerIntegrationModel?: Maybe<InformPartnerIntegrationModel>;
  findFirstInformPartnerIntegrationModel?: Maybe<InformPartnerIntegrationModel>;
  findManyInformPartnerIntegrationModel?: Maybe<Array<InformPartnerIntegrationModel>>;
  findManyInformPartnerIntegrationModelCount: Scalars['Int'];
  aggregateInformPartnerIntegrationModel?: Maybe<AggregateInformPartnerIntegrationModel>;
  findUniqueInformPartnerUser?: Maybe<InformPartnerUser>;
  findFirstInformPartnerUser?: Maybe<InformPartnerUser>;
  findManyInformPartnerUser?: Maybe<Array<InformPartnerUser>>;
  findManyInformPartnerUserCount: Scalars['Int'];
  aggregateInformPartnerUser?: Maybe<AggregateInformPartnerUser>;
  findUniqueInformPartnerUserRole?: Maybe<InformPartnerUserRole>;
  findFirstInformPartnerUserRole?: Maybe<InformPartnerUserRole>;
  findManyInformPartnerUserRole?: Maybe<Array<InformPartnerUserRole>>;
  findManyInformPartnerUserRoleCount: Scalars['Int'];
  aggregateInformPartnerUserRole?: Maybe<AggregateInformPartnerUserRole>;
  findUniqueBoehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritory>;
  findFirstBoehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritory>;
  findManyBoehringerIngelheimTerritory?: Maybe<Array<BoehringerIngelheimTerritory>>;
  findManyBoehringerIngelheimTerritoryCount: Scalars['Int'];
  aggregateBoehringerIngelheimTerritory?: Maybe<AggregateBoehringerIngelheimTerritory>;
  findUniqueBoehringerIngelheimCustomerAlignment?: Maybe<BoehringerIngelheimCustomerAlignment>;
  findFirstBoehringerIngelheimCustomerAlignment?: Maybe<BoehringerIngelheimCustomerAlignment>;
  findManyBoehringerIngelheimCustomerAlignment?: Maybe<Array<BoehringerIngelheimCustomerAlignment>>;
  findManyBoehringerIngelheimCustomerAlignmentCount: Scalars['Int'];
  aggregateBoehringerIngelheimCustomerAlignment?: Maybe<AggregateBoehringerIngelheimCustomerAlignment>;
  findUniqueInformPartnerCustomer?: Maybe<InformPartnerCustomer>;
  findFirstInformPartnerCustomer?: Maybe<InformPartnerCustomer>;
  findManyInformPartnerCustomer?: Maybe<Array<InformPartnerCustomer>>;
  findManyInformPartnerCustomerCount: Scalars['Int'];
  aggregateInformPartnerCustomer?: Maybe<AggregateInformPartnerCustomer>;
  findUniqueInformPartnerProgram?: Maybe<InformPartnerProgram>;
  findFirstInformPartnerProgram?: Maybe<InformPartnerProgram>;
  findManyInformPartnerProgram?: Maybe<Array<InformPartnerProgram>>;
  findManyInformPartnerProgramCount: Scalars['Int'];
  aggregateInformPartnerProgram?: Maybe<AggregateInformPartnerProgram>;
  findUniqueInformCampaignGroup?: Maybe<InformCampaignGroup>;
  findFirstInformCampaignGroup?: Maybe<InformCampaignGroup>;
  findManyInformCampaignGroup?: Maybe<Array<InformCampaignGroup>>;
  findManyInformCampaignGroupCount: Scalars['Int'];
  aggregateInformCampaignGroup?: Maybe<AggregateInformCampaignGroup>;
  findUniqueInformAudienceDefinition?: Maybe<InformAudienceDefinition>;
  findFirstInformAudienceDefinition?: Maybe<InformAudienceDefinition>;
  findManyInformAudienceDefinition?: Maybe<Array<InformAudienceDefinition>>;
  findManyInformAudienceDefinitionCount: Scalars['Int'];
  aggregateInformAudienceDefinition?: Maybe<AggregateInformAudienceDefinition>;
  findUniqueInformCampaign?: Maybe<InformCampaign>;
  findFirstInformCampaign?: Maybe<InformCampaign>;
  findManyInformCampaign?: Maybe<Array<InformCampaign>>;
  findManyInformCampaignCount: Scalars['Int'];
  aggregateInformCampaign?: Maybe<AggregateInformCampaign>;
  findUniqueInformScheduledCampaign?: Maybe<InformScheduledCampaign>;
  findFirstInformScheduledCampaign?: Maybe<InformScheduledCampaign>;
  findManyInformScheduledCampaign?: Maybe<Array<InformScheduledCampaign>>;
  findManyInformScheduledCampaignCount: Scalars['Int'];
  aggregateInformScheduledCampaign?: Maybe<AggregateInformScheduledCampaign>;
  findUniqueInformAutomationCampaign?: Maybe<InformAutomationCampaign>;
  findFirstInformAutomationCampaign?: Maybe<InformAutomationCampaign>;
  findManyInformAutomationCampaign?: Maybe<Array<InformAutomationCampaign>>;
  findManyInformAutomationCampaignCount: Scalars['Int'];
  aggregateInformAutomationCampaign?: Maybe<AggregateInformAutomationCampaign>;
  findUniqueInformEmailTemplate?: Maybe<InformEmailTemplate>;
  findFirstInformEmailTemplate?: Maybe<InformEmailTemplate>;
  findManyInformEmailTemplate?: Maybe<Array<InformEmailTemplate>>;
  findManyInformEmailTemplateCount: Scalars['Int'];
  aggregateInformEmailTemplate?: Maybe<AggregateInformEmailTemplate>;
  findUniqueInformCampaignExecution?: Maybe<InformCampaignExecution>;
  findFirstInformCampaignExecution?: Maybe<InformCampaignExecution>;
  findManyInformCampaignExecution?: Maybe<Array<InformCampaignExecution>>;
  findManyInformCampaignExecutionCount: Scalars['Int'];
  aggregateInformCampaignExecution?: Maybe<AggregateInformCampaignExecution>;
  findUniqueInformPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollment>;
  findFirstInformPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollment>;
  findManyInformPartnerProgramEnrollment?: Maybe<Array<InformPartnerProgramEnrollment>>;
  findManyInformPartnerProgramEnrollmentCount: Scalars['Int'];
  aggregateInformPartnerProgramEnrollment?: Maybe<AggregateInformPartnerProgramEnrollment>;
  findUniqueInformCampaignEnrollment?: Maybe<InformCampaignEnrollment>;
  findFirstInformCampaignEnrollment?: Maybe<InformCampaignEnrollment>;
  findManyInformCampaignEnrollment?: Maybe<Array<InformCampaignEnrollment>>;
  findManyInformCampaignEnrollmentCount: Scalars['Int'];
  aggregateInformCampaignEnrollment?: Maybe<AggregateInformCampaignEnrollment>;
  findUniqueInformCampaignEnrollmentCanonicalService?: Maybe<InformCampaignEnrollmentCanonicalService>;
  findFirstInformCampaignEnrollmentCanonicalService?: Maybe<InformCampaignEnrollmentCanonicalService>;
  findManyInformCampaignEnrollmentCanonicalService?: Maybe<Array<InformCampaignEnrollmentCanonicalService>>;
  findManyInformCampaignEnrollmentCanonicalServiceCount: Scalars['Int'];
  aggregateInformCampaignEnrollmentCanonicalService?: Maybe<AggregateInformCampaignEnrollmentCanonicalService>;
  findUniqueInformPartnerCustomerPharmacyProvider?: Maybe<InformPartnerCustomerPharmacyProvider>;
  findFirstInformPartnerCustomerPharmacyProvider?: Maybe<InformPartnerCustomerPharmacyProvider>;
  findManyInformPartnerCustomerPharmacyProvider?: Maybe<Array<InformPartnerCustomerPharmacyProvider>>;
  findManyInformPartnerCustomerPharmacyProviderCount: Scalars['Int'];
  aggregateInformPartnerCustomerPharmacyProvider?: Maybe<AggregateInformPartnerCustomerPharmacyProvider>;
  findUniqueInformEntityHistory?: Maybe<InformEntityHistory>;
  findFirstInformEntityHistory?: Maybe<InformEntityHistory>;
  findManyInformEntityHistory?: Maybe<Array<InformEntityHistory>>;
  findManyInformEntityHistoryCount: Scalars['Int'];
  aggregateInformEntityHistory?: Maybe<AggregateInformEntityHistory>;
  findUniqueClinicBlockedPhoneNumber?: Maybe<ClinicBlockedPhoneNumber>;
  findFirstClinicBlockedPhoneNumber?: Maybe<ClinicBlockedPhoneNumber>;
  findManyClinicBlockedPhoneNumber?: Maybe<Array<ClinicBlockedPhoneNumber>>;
  findManyClinicBlockedPhoneNumberCount: Scalars['Int'];
  aggregateClinicBlockedPhoneNumber?: Maybe<AggregateClinicBlockedPhoneNumber>;
  findUniqueChannelMessageAutoResponse?: Maybe<ChannelMessageAutoResponse>;
  findFirstChannelMessageAutoResponse?: Maybe<ChannelMessageAutoResponse>;
  findManyChannelMessageAutoResponse?: Maybe<Array<ChannelMessageAutoResponse>>;
  findManyChannelMessageAutoResponseCount: Scalars['Int'];
  aggregateChannelMessageAutoResponse?: Maybe<AggregateChannelMessageAutoResponse>;
  findUniqueCareSubscriptionTrueUpLogEntry?: Maybe<CareSubscriptionTrueUpLogEntry>;
  findFirstCareSubscriptionTrueUpLogEntry?: Maybe<CareSubscriptionTrueUpLogEntry>;
  findManyCareSubscriptionTrueUpLogEntry?: Maybe<Array<CareSubscriptionTrueUpLogEntry>>;
  findManyCareSubscriptionTrueUpLogEntryCount: Scalars['Int'];
  aggregateCareSubscriptionTrueUpLogEntry?: Maybe<AggregateCareSubscriptionTrueUpLogEntry>;
  findUniqueCareSubscriptionTrueUp?: Maybe<CareSubscriptionTrueUp>;
  findFirstCareSubscriptionTrueUp?: Maybe<CareSubscriptionTrueUp>;
  findManyCareSubscriptionTrueUp?: Maybe<Array<CareSubscriptionTrueUp>>;
  findManyCareSubscriptionTrueUpCount: Scalars['Int'];
  aggregateCareSubscriptionTrueUp?: Maybe<AggregateCareSubscriptionTrueUp>;
  findUniqueHillsToHomeAPILog?: Maybe<HillsToHomeApiLog>;
  findFirstHillsToHomeAPILog?: Maybe<HillsToHomeApiLog>;
  findManyHillsToHomeAPILog?: Maybe<Array<HillsToHomeApiLog>>;
  findManyHillsToHomeAPILogCount: Scalars['Int'];
  aggregateHillsToHomeAPILog?: Maybe<AggregateHillsToHomeApiLog>;
  findUniqueBoardFilterColumn?: Maybe<BoardFilterColumn>;
  findFirstBoardFilterColumn?: Maybe<BoardFilterColumn>;
  findManyBoardFilterColumn?: Maybe<Array<BoardFilterColumn>>;
  findManyBoardFilterColumnCount: Scalars['Int'];
  aggregateBoardFilterColumn?: Maybe<AggregateBoardFilterColumn>;
  findUniqueBoardFilterSpecificDateRange?: Maybe<BoardFilterSpecificDateRange>;
  findFirstBoardFilterSpecificDateRange?: Maybe<BoardFilterSpecificDateRange>;
  findManyBoardFilterSpecificDateRange?: Maybe<Array<BoardFilterSpecificDateRange>>;
  findManyBoardFilterSpecificDateRangeCount: Scalars['Int'];
  aggregateBoardFilterSpecificDateRange?: Maybe<AggregateBoardFilterSpecificDateRange>;
  findUniqueUserBoardFilter?: Maybe<UserBoardFilter>;
  findFirstUserBoardFilter?: Maybe<UserBoardFilter>;
  findManyUserBoardFilter?: Maybe<Array<UserBoardFilter>>;
  findManyUserBoardFilterCount: Scalars['Int'];
  aggregateUserBoardFilter?: Maybe<AggregateUserBoardFilter>;
  findUniqueSegment?: Maybe<Segment>;
  findFirstSegment?: Maybe<Segment>;
  findManySegment?: Maybe<Array<Segment>>;
  findManySegmentCount: Scalars['Int'];
  aggregateSegment?: Maybe<AggregateSegment>;
  findUniqueNonPrismaSchemaScriptRan?: Maybe<NonPrismaSchemaScriptRan>;
  findFirstNonPrismaSchemaScriptRan?: Maybe<NonPrismaSchemaScriptRan>;
  findManyNonPrismaSchemaScriptRan?: Maybe<Array<NonPrismaSchemaScriptRan>>;
  findManyNonPrismaSchemaScriptRanCount: Scalars['Int'];
  aggregateNonPrismaSchemaScriptRan?: Maybe<AggregateNonPrismaSchemaScriptRan>;
  findUniqueVetsourceInstallation?: Maybe<VetsourceInstallation>;
  findFirstVetsourceInstallation?: Maybe<VetsourceInstallation>;
  findManyVetsourceInstallation?: Maybe<Array<VetsourceInstallation>>;
  findManyVetsourceInstallationCount: Scalars['Int'];
  aggregateVetsourceInstallation?: Maybe<AggregateVetsourceInstallation>;
  findUniqueEmailCampaignCustomList?: Maybe<EmailCampaignCustomList>;
  findFirstEmailCampaignCustomList?: Maybe<EmailCampaignCustomList>;
  findManyEmailCampaignCustomList?: Maybe<Array<EmailCampaignCustomList>>;
  findManyEmailCampaignCustomListCount: Scalars['Int'];
  aggregateEmailCampaignCustomList?: Maybe<AggregateEmailCampaignCustomList>;
  findUniqueEmailCampaignCustomListRecipient?: Maybe<EmailCampaignCustomListRecipient>;
  findFirstEmailCampaignCustomListRecipient?: Maybe<EmailCampaignCustomListRecipient>;
  findManyEmailCampaignCustomListRecipient?: Maybe<Array<EmailCampaignCustomListRecipient>>;
  findManyEmailCampaignCustomListRecipientCount: Scalars['Int'];
  aggregateEmailCampaignCustomListRecipient?: Maybe<AggregateEmailCampaignCustomListRecipient>;
  findUniqueVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategory>;
  findFirstVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategory>;
  findManyVetsourceRevenueCategory?: Maybe<Array<VetsourceRevenueCategory>>;
  findManyVetsourceRevenueCategoryCount: Scalars['Int'];
  aggregateVetsourceRevenueCategory?: Maybe<AggregateVetsourceRevenueCategory>;
  findUniqueVetsourceCodeTag?: Maybe<VetsourceCodeTag>;
  findFirstVetsourceCodeTag?: Maybe<VetsourceCodeTag>;
  findManyVetsourceCodeTag?: Maybe<Array<VetsourceCodeTag>>;
  findManyVetsourceCodeTagCount: Scalars['Int'];
  aggregateVetsourceCodeTag?: Maybe<AggregateVetsourceCodeTag>;
  findUniqueInformControlGroupParticipant?: Maybe<InformControlGroupParticipant>;
  findFirstInformControlGroupParticipant?: Maybe<InformControlGroupParticipant>;
  findManyInformControlGroupParticipant?: Maybe<Array<InformControlGroupParticipant>>;
  findManyInformControlGroupParticipantCount: Scalars['Int'];
  aggregateInformControlGroupParticipant?: Maybe<AggregateInformControlGroupParticipant>;
  invokeConverseAiRequest: InvokeAiConverseOutput;
  getEmailCampaignLoginUrl?: Maybe<Scalars['String']>;
  renderWorkflowNotificationBody?: Maybe<Scalars['String']>;
  getAvailableFormTemplatesForWorkflows: Array<Maybe<FormTemplate>>;
  getPossiblyDeflectedFormSubmission?: Maybe<FormSubmission>;
  checkClinicCareStatus: CheckClinicCareStatusResult;
  clientReadTest?: Maybe<Scalars['Boolean']>;
  clientWriteTest?: Maybe<Scalars['Boolean']>;
  exportCancellationSummaryAsPdf: ExportCancellationSummaryAsPdfResponse;
  getLatestActiveSubscriptionInvoice?: Maybe<GetLatestActiveSubscriptionInvoiceOutput>;
  calculatePetBenefitUsage?: Maybe<CalculatePetBenefitUsageOutput>;
  calculateMultiplePetsBenefitUsage?: Maybe<CalculateMultiplePetsBenefitUsageOutput>;
  channelUnreadMessageCount?: Maybe<ChannelUnreadMessageCountResult>;
  channelExport?: Maybe<FileExportData>;
  formSubmissionExport?: Maybe<FileExportData>;
  aiChannelSuggestedMessage?: Maybe<ChannelSuggestedMessageResponse>;
  savedChannelFilterSelections: Array<ChannelFilterSelection>;
  channelMessageUnreadCount: ChannelMessageUnreadCountResult;
  getChannelStatusIdsWithEntityCountOfZero: Array<Scalars['String']>;
  retrieveAuthTokenForContact?: Maybe<ChurnZeroResponse>;
  findClinicContactInfo?: Maybe<ClinicContactInfo>;
  findClinicPetParentByToken?: Maybe<ClinicPetParent>;
  findClinicPetParentByPhoneOrEmail: Array<ClinicPetParent>;
  searchPhoneNumber?: Maybe<SearchPhoneNumberResponse>;
  widgetClinicPetParentLookup2: Array<WidgetClinicPetParentLookupResponse>;
  widgetClinicPetParentLookupEnrollment: Array<Maybe<WidgetClinicPetParentLookupResponse>>;
  getOrCreateActiveChannel?: Maybe<Channel>;
  getDirectBookingAppointmentTypes: Array<Maybe<DirectBookingAppointmentTypes>>;
  getDirectBookingOpenTimeSlots2: DirectBookingOpenTimeSlot2Response;
  getClinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingsResponse>;
  getHasConflictingAppointment?: Maybe<Scalars['Boolean']>;
  getEmailCampaignSegmentCount?: Maybe<Scalars['Int']>;
  canSendEmailCampaign?: Maybe<Scalars['Boolean']>;
  getEmailCampaignCustomListUrl?: Maybe<Scalars['String']>;
  findCurrentEmailTemplateVersions: CurrentEmailTemplateVersionsFindResponse;
  findEmployerIdentificationNumber?: Maybe<Array<Maybe<FindEmployerIdentificationNumberResponse>>>;
  getCurrentInformPartnerUser?: Maybe<InformPartnerUser>;
  getInvoiceById?: Maybe<Invoice>;
  getInvoicesForClient: Array<Invoice>;
  getInvoicesForClients: Array<Invoice>;
  exportItemizedInvoiceAsReceiptPDF: ExportItemizedInvoiceToReceiptPdfResponse;
  jobChainStatus?: Maybe<JobChainStatus>;
  estimateNumberOfPetParentsReached?: Maybe<NumberOfPetParentsReached>;
  massTextAlertReport?: Maybe<Array<Maybe<MassTextAlertReportEntry>>>;
  findOrganizationPetParentByToken?: Maybe<OrganizationPetParent>;
  sendPetParentReceipt?: Maybe<Scalars['Json']>;
  sendConsultationReceipt?: Maybe<Scalars['Json']>;
  sendVetSubscriptionReceipt?: Maybe<Scalars['Json']>;
  sendDailyCronJobs?: Maybe<Scalars['Json']>;
  sendDailyFinancialReport?: Maybe<Scalars['Json']>;
  stripeCreateLoginLink?: Maybe<LoginLinkOutput>;
  stripeRetrieveConnectedAccount?: Maybe<StripeAccountOutput>;
  getTerminalToken?: Maybe<StripeTerminalTokenOutput>;
  stripeCreateCustomerPortal?: Maybe<StripeCustomerPortalOutput>;
  clinicPaymentActivity?: Maybe<ClinicPaymentActivityOutput>;
  findMissingPayoutBatchingPeriodDates: FindMissingBatchingPeriodDatesOutput;
  getPetIdData: GetPetIdDataResponse;
  generatePetIdPdf: GeneratePetIdPdfResponse;
  getQuickSightAdminDashboards?: Maybe<Array<Maybe<DashboardSummary>>>;
  getQuickSightDashboards?: Maybe<Array<Maybe<DashboardSummary>>>;
  getQuickSightEmbedURL?: Maybe<EmbedUrl>;
  clinicPetSearch: Array<ClinicPet>;
  clinicPetParentSearch: Array<ClinicPetParent>;
  channelSearch: ChannelSearchOutput;
  exportNonItemizedReceiptAsPdf: ExportNonItemizedReceiptAsPdfResponse;
  getStripePaymentIntentData?: Maybe<StripePaymentOutput>;
  canManuallyRetryPaymentWriteback: CanManuallyRetryPaymentWritebackOutput;
  stripeRetrievePetParentPortalUrl?: Maybe<StripeCustomerPortalOutput>;
  verifyTerminalSetupPin: VerifyTerminalSetupPinResponse;
  trupanionCertificateAvailability?: Maybe<Array<Maybe<TrupanionAvailability>>>;
  trupanionHospitalSearch?: Maybe<Array<Maybe<TrupanionHospital>>>;
  getCurrentUser?: Maybe<User>;
  customFindUniqueUserBoardFilter?: Maybe<CustomFindUniqueUserBoardFilterResponse>;
  findManyParentWorkflowEventSetting: Array<Maybe<WorkflowEventSetting>>;
  getBalanceForClient?: Maybe<BitwerxClientBalance>;
  healthCheck?: Maybe<GenericResponse>;
  tokenValidation?: Maybe<Scalars['Json']>;
  getTwilioNumbers?: Maybe<GenericResponse>;
  searchTwilioNumbers?: Maybe<GenericResponse>;
  twilioCapabilityToken?: Maybe<TwilioCapabilityToken>;
  verificationRequest?: Maybe<GenericResponse>;
  verificationCheck?: Maybe<GenericResponse>;
  checkPhoneVerification?: Maybe<Scalars['Json']>;
};


export type QueryFindUniqueAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};


export type QueryFindFirstAppointmentArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentScalarFieldEnum>>>;
};


export type QueryFindManyAppointmentArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentScalarFieldEnum>>>;
};


export type QueryFindManyAppointmentCountArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentScalarFieldEnum>>>;
};


export type QueryAggregateAppointmentArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueAppointmentAvailabilityArgs = {
  where: AppointmentAvailabilityWhereUniqueInput;
};


export type QueryFindFirstAppointmentAvailabilityArgs = {
  where?: Maybe<AppointmentAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentAvailabilityScalarFieldEnum>>>;
};


export type QueryFindManyAppointmentAvailabilityArgs = {
  where?: Maybe<AppointmentAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentAvailabilityScalarFieldEnum>>>;
};


export type QueryFindManyAppointmentAvailabilityCountArgs = {
  where?: Maybe<AppointmentAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentAvailabilityScalarFieldEnum>>>;
};


export type QueryAggregateAppointmentAvailabilityArgs = {
  where?: Maybe<AppointmentAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueAppointmentTypeArgs = {
  where: AppointmentTypeWhereUniqueInput;
};


export type QueryFindFirstAppointmentTypeArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentTypeScalarFieldEnum>>>;
};


export type QueryFindManyAppointmentTypeArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentTypeScalarFieldEnum>>>;
};


export type QueryFindManyAppointmentTypeCountArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentTypeScalarFieldEnum>>>;
};


export type QueryAggregateAppointmentTypeArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueBitwerxIntegrationArgs = {
  where: BitwerxIntegrationWhereUniqueInput;
};


export type QueryFindFirstBitwerxIntegrationArgs = {
  where?: Maybe<BitwerxIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyBitwerxIntegrationArgs = {
  where?: Maybe<BitwerxIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyBitwerxIntegrationCountArgs = {
  where?: Maybe<BitwerxIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxIntegrationScalarFieldEnum>>>;
};


export type QueryAggregateBitwerxIntegrationArgs = {
  where?: Maybe<BitwerxIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniquePpcIntegrationArgs = {
  where: PpcIntegrationWhereUniqueInput;
};


export type QueryFindFirstPpcIntegrationArgs = {
  where?: Maybe<PpcIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PpcIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PpcIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyPpcIntegrationArgs = {
  where?: Maybe<PpcIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PpcIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PpcIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyPpcIntegrationCountArgs = {
  where?: Maybe<PpcIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PpcIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PpcIntegrationScalarFieldEnum>>>;
};


export type QueryAggregatePpcIntegrationArgs = {
  where?: Maybe<PpcIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PpcIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueChannelAutomationStatusArgs = {
  where: ChannelAutomationStatusWhereUniqueInput;
};


export type QueryFindFirstChannelAutomationStatusArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelAutomationStatusScalarFieldEnum>>>;
};


export type QueryFindManyChannelAutomationStatusArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelAutomationStatusScalarFieldEnum>>>;
};


export type QueryFindManyChannelAutomationStatusCountArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelAutomationStatusScalarFieldEnum>>>;
};


export type QueryAggregateChannelAutomationStatusArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueChannelCreationSourceArgs = {
  where: ChannelCreationSourceWhereUniqueInput;
};


export type QueryFindFirstChannelCreationSourceArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelCreationSourceScalarFieldEnum>>>;
};


export type QueryFindManyChannelCreationSourceArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelCreationSourceScalarFieldEnum>>>;
};


export type QueryFindManyChannelCreationSourceCountArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelCreationSourceScalarFieldEnum>>>;
};


export type QueryAggregateChannelCreationSourceArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueChannelArgs = {
  where: ChannelWhereUniqueInput;
};


export type QueryFindFirstChannelArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelScalarFieldEnum>>>;
};


export type QueryFindManyChannelArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelScalarFieldEnum>>>;
};


export type QueryFindManyChannelCountArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelScalarFieldEnum>>>;
};


export type QueryAggregateChannelArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueChannelPinArgs = {
  where: ChannelPinWhereUniqueInput;
};


export type QueryFindFirstChannelPinArgs = {
  where?: Maybe<ChannelPinWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelPinOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelPinWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelPinScalarFieldEnum>>>;
};


export type QueryFindManyChannelPinArgs = {
  where?: Maybe<ChannelPinWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelPinOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelPinWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelPinScalarFieldEnum>>>;
};


export type QueryFindManyChannelPinCountArgs = {
  where?: Maybe<ChannelPinWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelPinOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelPinWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelPinScalarFieldEnum>>>;
};


export type QueryAggregateChannelPinArgs = {
  where?: Maybe<ChannelPinWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelPinOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelPinWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueChannelMemberArgs = {
  where: ChannelMemberWhereUniqueInput;
};


export type QueryFindFirstChannelMemberArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMemberScalarFieldEnum>>>;
};


export type QueryFindManyChannelMemberArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMemberScalarFieldEnum>>>;
};


export type QueryFindManyChannelMemberCountArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMemberScalarFieldEnum>>>;
};


export type QueryAggregateChannelMemberArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueChannelMessageArgs = {
  where: ChannelMessageWhereUniqueInput;
};


export type QueryFindFirstChannelMessageArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageScalarFieldEnum>>>;
};


export type QueryFindManyChannelMessageArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageScalarFieldEnum>>>;
};


export type QueryFindManyChannelMessageCountArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageScalarFieldEnum>>>;
};


export type QueryAggregateChannelMessageArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueChannelMessageReactionArgs = {
  where: ChannelMessageReactionWhereUniqueInput;
};


export type QueryFindFirstChannelMessageReactionArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageReactionScalarFieldEnum>>>;
};


export type QueryFindManyChannelMessageReactionArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageReactionScalarFieldEnum>>>;
};


export type QueryFindManyChannelMessageReactionCountArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageReactionScalarFieldEnum>>>;
};


export type QueryAggregateChannelMessageReactionArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueChannelSelectionArgs = {
  where: ChannelSelectionWhereUniqueInput;
};


export type QueryFindFirstChannelSelectionArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelSelectionScalarFieldEnum>>>;
};


export type QueryFindManyChannelSelectionArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelSelectionScalarFieldEnum>>>;
};


export type QueryFindManyChannelSelectionCountArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelSelectionScalarFieldEnum>>>;
};


export type QueryAggregateChannelSelectionArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueChannelMessageAttachmentArgs = {
  where: ChannelMessageAttachmentWhereUniqueInput;
};


export type QueryFindFirstChannelMessageAttachmentArgs = {
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAttachmentScalarFieldEnum>>>;
};


export type QueryFindManyChannelMessageAttachmentArgs = {
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAttachmentScalarFieldEnum>>>;
};


export type QueryFindManyChannelMessageAttachmentCountArgs = {
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAttachmentScalarFieldEnum>>>;
};


export type QueryAggregateChannelMessageAttachmentArgs = {
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueChannelStatusArgs = {
  where: ChannelStatusWhereUniqueInput;
};


export type QueryFindFirstChannelStatusArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelStatusScalarFieldEnum>>>;
};


export type QueryFindManyChannelStatusArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelStatusScalarFieldEnum>>>;
};


export type QueryFindManyChannelStatusCountArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelStatusScalarFieldEnum>>>;
};


export type QueryAggregateChannelStatusArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueChannelStatusChannelAssigneeArgs = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};


export type QueryFindFirstChannelStatusChannelAssigneeArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>>>;
};


export type QueryFindManyChannelStatusChannelAssigneeArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>>>;
};


export type QueryFindManyChannelStatusChannelAssigneeCountArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>>>;
};


export type QueryAggregateChannelStatusChannelAssigneeArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicArgs = {
  where: ClinicWhereUniqueInput;
};


export type QueryFindFirstClinicArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicScalarFieldEnum>>>;
};


export type QueryFindManyClinicArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicScalarFieldEnum>>>;
};


export type QueryFindManyClinicCountArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicScalarFieldEnum>>>;
};


export type QueryAggregateClinicArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicEmployeeArgs = {
  where: ClinicEmployeeWhereUniqueInput;
};


export type QueryFindFirstClinicEmployeeArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeScalarFieldEnum>>>;
};


export type QueryFindManyClinicEmployeeArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeScalarFieldEnum>>>;
};


export type QueryFindManyClinicEmployeeCountArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeScalarFieldEnum>>>;
};


export type QueryAggregateClinicEmployeeArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueDirectBookingAppointmentTypeSettingArgs = {
  where: DirectBookingAppointmentTypeSettingWhereUniqueInput;
};


export type QueryFindFirstDirectBookingAppointmentTypeSettingArgs = {
  where?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<DirectBookingAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DirectBookingAppointmentTypeSettingScalarFieldEnum>>>;
};


export type QueryFindManyDirectBookingAppointmentTypeSettingArgs = {
  where?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<DirectBookingAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DirectBookingAppointmentTypeSettingScalarFieldEnum>>>;
};


export type QueryFindManyDirectBookingAppointmentTypeSettingCountArgs = {
  where?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<DirectBookingAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DirectBookingAppointmentTypeSettingScalarFieldEnum>>>;
};


export type QueryAggregateDirectBookingAppointmentTypeSettingArgs = {
  where?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<DirectBookingAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicEmployeeAppointmentTypeSettingArgs = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};


export type QueryFindFirstClinicEmployeeAppointmentTypeSettingArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>>>;
};


export type QueryFindManyClinicEmployeeAppointmentTypeSettingArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>>>;
};


export type QueryFindManyClinicEmployeeAppointmentTypeSettingCountArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>>>;
};


export type QueryAggregateClinicEmployeeAppointmentTypeSettingArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicEntityPhoneNumberArgs = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};


export type QueryFindFirstClinicEntityPhoneNumberArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEntityPhoneNumberScalarFieldEnum>>>;
};


export type QueryFindManyClinicEntityPhoneNumberArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEntityPhoneNumberScalarFieldEnum>>>;
};


export type QueryFindManyClinicEntityPhoneNumberCountArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEntityPhoneNumberScalarFieldEnum>>>;
};


export type QueryAggregateClinicEntityPhoneNumberArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPaymentFeeConfigurationArgs = {
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
};


export type QueryFindFirstClinicPaymentFeeConfigurationArgs = {
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationScalarFieldEnum>>>;
};


export type QueryFindManyClinicPaymentFeeConfigurationArgs = {
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationScalarFieldEnum>>>;
};


export type QueryFindManyClinicPaymentFeeConfigurationCountArgs = {
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationScalarFieldEnum>>>;
};


export type QueryAggregateClinicPaymentFeeConfigurationArgs = {
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInterchangeRateArgs = {
  where: InterchangeRateWhereUniqueInput;
};


export type QueryFindFirstInterchangeRateArgs = {
  where?: Maybe<InterchangeRateWhereInput>;
  orderBy?: Maybe<Array<Maybe<InterchangeRateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InterchangeRateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InterchangeRateScalarFieldEnum>>>;
};


export type QueryFindManyInterchangeRateArgs = {
  where?: Maybe<InterchangeRateWhereInput>;
  orderBy?: Maybe<Array<Maybe<InterchangeRateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InterchangeRateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InterchangeRateScalarFieldEnum>>>;
};


export type QueryFindManyInterchangeRateCountArgs = {
  where?: Maybe<InterchangeRateWhereInput>;
  orderBy?: Maybe<Array<Maybe<InterchangeRateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InterchangeRateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InterchangeRateScalarFieldEnum>>>;
};


export type QueryAggregateInterchangeRateArgs = {
  where?: Maybe<InterchangeRateWhereInput>;
  orderBy?: Maybe<Array<Maybe<InterchangeRateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InterchangeRateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPetArgs = {
  where: ClinicPetWhereUniqueInput;
};


export type QueryFindFirstClinicPetArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetCountArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetScalarFieldEnum>>>;
};


export type QueryAggregateClinicPetArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPetAlertArgs = {
  where: ClinicPetAlertWhereUniqueInput;
};


export type QueryFindFirstClinicPetAlertArgs = {
  where?: Maybe<ClinicPetAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetAlertScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetAlertArgs = {
  where?: Maybe<ClinicPetAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetAlertScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetAlertCountArgs = {
  where?: Maybe<ClinicPetAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetAlertScalarFieldEnum>>>;
};


export type QueryAggregateClinicPetAlertArgs = {
  where?: Maybe<ClinicPetAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPetParentAddressArgs = {
  where: ClinicPetParentAddressWhereUniqueInput;
};


export type QueryFindFirstClinicPetParentAddressArgs = {
  where?: Maybe<ClinicPetParentAddressWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentAddressScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetParentAddressArgs = {
  where?: Maybe<ClinicPetParentAddressWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentAddressScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetParentAddressCountArgs = {
  where?: Maybe<ClinicPetParentAddressWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentAddressScalarFieldEnum>>>;
};


export type QueryAggregateClinicPetParentAddressArgs = {
  where?: Maybe<ClinicPetParentAddressWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPetParentArgs = {
  where: ClinicPetParentWhereUniqueInput;
};


export type QueryFindFirstClinicPetParentArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetParentArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetParentCountArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentScalarFieldEnum>>>;
};


export type QueryAggregateClinicPetParentArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicDirectBookingSettingArgs = {
  where: ClinicDirectBookingSettingWhereUniqueInput;
};


export type QueryFindFirstClinicDirectBookingSettingArgs = {
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicDirectBookingSettingScalarFieldEnum>>>;
};


export type QueryFindManyClinicDirectBookingSettingArgs = {
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicDirectBookingSettingScalarFieldEnum>>>;
};


export type QueryFindManyClinicDirectBookingSettingCountArgs = {
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicDirectBookingSettingScalarFieldEnum>>>;
};


export type QueryAggregateClinicDirectBookingSettingArgs = {
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueDirectBookingExclusionRuleArgs = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
};


export type QueryFindFirstDirectBookingExclusionRuleArgs = {
  where?: Maybe<DirectBookingExclusionRuleWhereInput>;
  orderBy?: Maybe<Array<Maybe<DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DirectBookingExclusionRuleScalarFieldEnum>>>;
};


export type QueryFindManyDirectBookingExclusionRuleArgs = {
  where?: Maybe<DirectBookingExclusionRuleWhereInput>;
  orderBy?: Maybe<Array<Maybe<DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DirectBookingExclusionRuleScalarFieldEnum>>>;
};


export type QueryFindManyDirectBookingExclusionRuleCountArgs = {
  where?: Maybe<DirectBookingExclusionRuleWhereInput>;
  orderBy?: Maybe<Array<Maybe<DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DirectBookingExclusionRuleScalarFieldEnum>>>;
};


export type QueryFindUniqueDirectBookingExclusionTimeSlotArgs = {
  where: DirectBookingExclusionTimeSlotWhereUniqueInput;
};


export type QueryFindFirstDirectBookingExclusionTimeSlotArgs = {
  where?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
  orderBy?: Maybe<Array<Maybe<DirectBookingExclusionTimeSlotOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DirectBookingExclusionTimeSlotWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DirectBookingExclusionTimeSlotScalarFieldEnum>>>;
};


export type QueryFindManyDirectBookingExclusionTimeSlotArgs = {
  where?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
  orderBy?: Maybe<Array<Maybe<DirectBookingExclusionTimeSlotOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DirectBookingExclusionTimeSlotWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DirectBookingExclusionTimeSlotScalarFieldEnum>>>;
};


export type QueryFindManyDirectBookingExclusionTimeSlotCountArgs = {
  where?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
  orderBy?: Maybe<Array<Maybe<DirectBookingExclusionTimeSlotOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DirectBookingExclusionTimeSlotWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DirectBookingExclusionTimeSlotScalarFieldEnum>>>;
};


export type QueryFindUniqueClinicEmailCampaignSettingArgs = {
  where: ClinicEmailCampaignSettingWhereUniqueInput;
};


export type QueryFindFirstClinicEmailCampaignSettingArgs = {
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmailCampaignSettingScalarFieldEnum>>>;
};


export type QueryFindManyClinicEmailCampaignSettingArgs = {
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmailCampaignSettingScalarFieldEnum>>>;
};


export type QueryFindManyClinicEmailCampaignSettingCountArgs = {
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmailCampaignSettingScalarFieldEnum>>>;
};


export type QueryAggregateClinicEmailCampaignSettingArgs = {
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPostcardSettingArgs = {
  where: ClinicPostcardSettingWhereUniqueInput;
};


export type QueryFindFirstClinicPostcardSettingArgs = {
  where?: Maybe<ClinicPostcardSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPostcardSettingScalarFieldEnum>>>;
};


export type QueryFindManyClinicPostcardSettingArgs = {
  where?: Maybe<ClinicPostcardSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPostcardSettingScalarFieldEnum>>>;
};


export type QueryFindManyClinicPostcardSettingCountArgs = {
  where?: Maybe<ClinicPostcardSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPostcardSettingScalarFieldEnum>>>;
};


export type QueryAggregateClinicPostcardSettingArgs = {
  where?: Maybe<ClinicPostcardSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueIntegrationOutageHistoryEntryArgs = {
  where: IntegrationOutageHistoryEntryWhereUniqueInput;
};


export type QueryFindFirstIntegrationOutageHistoryEntryArgs = {
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<IntegrationOutageHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<IntegrationOutageHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<IntegrationOutageHistoryEntryScalarFieldEnum>>>;
};


export type QueryFindManyIntegrationOutageHistoryEntryArgs = {
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<IntegrationOutageHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<IntegrationOutageHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<IntegrationOutageHistoryEntryScalarFieldEnum>>>;
};


export type QueryFindManyIntegrationOutageHistoryEntryCountArgs = {
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<IntegrationOutageHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<IntegrationOutageHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<IntegrationOutageHistoryEntryScalarFieldEnum>>>;
};


export type QueryAggregateIntegrationOutageHistoryEntryArgs = {
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<IntegrationOutageHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<IntegrationOutageHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  where: ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput;
};


export type QueryFindFirstClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityHistoryEntryScalarFieldEnum>>>;
};


export type QueryFindManyClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityHistoryEntryScalarFieldEnum>>>;
};


export type QueryFindManyClinicPimsIntegrationCapabilityHistoryEntryCountArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityHistoryEntryScalarFieldEnum>>>;
};


export type QueryAggregateClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPimsIntegrationArgs = {
  where: ClinicPimsIntegrationWhereUniqueInput;
};


export type QueryFindFirstClinicPimsIntegrationArgs = {
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyClinicPimsIntegrationArgs = {
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyClinicPimsIntegrationCountArgs = {
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationScalarFieldEnum>>>;
};


export type QueryAggregateClinicPimsIntegrationArgs = {
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueSyncVetIntegrationArgs = {
  where: SyncVetIntegrationWhereUniqueInput;
};


export type QueryFindFirstSyncVetIntegrationArgs = {
  where?: Maybe<SyncVetIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<SyncVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SyncVetIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SyncVetIntegrationScalarFieldEnum>>>;
};


export type QueryFindManySyncVetIntegrationArgs = {
  where?: Maybe<SyncVetIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<SyncVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SyncVetIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SyncVetIntegrationScalarFieldEnum>>>;
};


export type QueryFindManySyncVetIntegrationCountArgs = {
  where?: Maybe<SyncVetIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<SyncVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SyncVetIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SyncVetIntegrationScalarFieldEnum>>>;
};


export type QueryAggregateSyncVetIntegrationArgs = {
  where?: Maybe<SyncVetIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<SyncVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SyncVetIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPimsIntegrationCapabilityArgs = {
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
};


export type QueryFindFirstClinicPimsIntegrationCapabilityArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityScalarFieldEnum>>>;
};


export type QueryFindManyClinicPimsIntegrationCapabilityArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityScalarFieldEnum>>>;
};


export type QueryFindManyClinicPimsIntegrationCapabilityCountArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityScalarFieldEnum>>>;
};


export type QueryAggregateClinicPimsIntegrationCapabilityArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicRoomArgs = {
  where: ClinicRoomWhereUniqueInput;
};


export type QueryFindFirstClinicRoomArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicRoomScalarFieldEnum>>>;
};


export type QueryFindManyClinicRoomArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicRoomScalarFieldEnum>>>;
};


export type QueryFindManyClinicRoomCountArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicRoomScalarFieldEnum>>>;
};


export type QueryAggregateClinicRoomArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicSettingArgs = {
  where: ClinicSettingWhereUniqueInput;
};


export type QueryFindFirstClinicSettingArgs = {
  where?: Maybe<ClinicSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicSettingScalarFieldEnum>>>;
};


export type QueryFindManyClinicSettingArgs = {
  where?: Maybe<ClinicSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicSettingScalarFieldEnum>>>;
};


export type QueryFindManyClinicSettingCountArgs = {
  where?: Maybe<ClinicSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicSettingScalarFieldEnum>>>;
};


export type QueryAggregateClinicSettingArgs = {
  where?: Maybe<ClinicSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicWidgetRequestArgs = {
  where: ClinicWidgetRequestWhereUniqueInput;
};


export type QueryFindFirstClinicWidgetRequestArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestScalarFieldEnum>>>;
};


export type QueryFindManyClinicWidgetRequestArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestScalarFieldEnum>>>;
};


export type QueryFindManyClinicWidgetRequestCountArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestScalarFieldEnum>>>;
};


export type QueryAggregateClinicWidgetRequestArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicWidgetRequestTypeArgs = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};


export type QueryFindFirstClinicWidgetRequestTypeArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestTypeScalarFieldEnum>>>;
};


export type QueryFindManyClinicWidgetRequestTypeArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestTypeScalarFieldEnum>>>;
};


export type QueryFindManyClinicWidgetRequestTypeCountArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestTypeScalarFieldEnum>>>;
};


export type QueryAggregateClinicWidgetRequestTypeArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicWidgetSettingArgs = {
  where: ClinicWidgetSettingWhereUniqueInput;
};


export type QueryFindFirstClinicWidgetSettingArgs = {
  where?: Maybe<ClinicWidgetSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetSettingScalarFieldEnum>>>;
};


export type QueryFindManyClinicWidgetSettingArgs = {
  where?: Maybe<ClinicWidgetSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetSettingScalarFieldEnum>>>;
};


export type QueryFindManyClinicWidgetSettingCountArgs = {
  where?: Maybe<ClinicWidgetSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetSettingScalarFieldEnum>>>;
};


export type QueryAggregateClinicWidgetSettingArgs = {
  where?: Maybe<ClinicWidgetSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueConsultationArgs = {
  where: ConsultationWhereUniqueInput;
};


export type QueryFindFirstConsultationArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConsultationScalarFieldEnum>>>;
};


export type QueryFindManyConsultationArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConsultationScalarFieldEnum>>>;
};


export type QueryFindManyConsultationCountArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConsultationScalarFieldEnum>>>;
};


export type QueryAggregateConsultationArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueConsultationRequestArgs = {
  where: ConsultationRequestWhereUniqueInput;
};


export type QueryFindFirstConsultationRequestArgs = {
  where?: Maybe<ConsultationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConsultationRequestScalarFieldEnum>>>;
};


export type QueryFindManyConsultationRequestArgs = {
  where?: Maybe<ConsultationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConsultationRequestScalarFieldEnum>>>;
};


export type QueryFindManyConsultationRequestCountArgs = {
  where?: Maybe<ConsultationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConsultationRequestScalarFieldEnum>>>;
};


export type QueryAggregateConsultationRequestArgs = {
  where?: Maybe<ConsultationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueDisbursementArgs = {
  where: DisbursementWhereUniqueInput;
};


export type QueryFindFirstDisbursementArgs = {
  where?: Maybe<DisbursementWhereInput>;
  orderBy?: Maybe<Array<Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DisbursementWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DisbursementScalarFieldEnum>>>;
};


export type QueryFindManyDisbursementArgs = {
  where?: Maybe<DisbursementWhereInput>;
  orderBy?: Maybe<Array<Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DisbursementWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DisbursementScalarFieldEnum>>>;
};


export type QueryFindManyDisbursementCountArgs = {
  where?: Maybe<DisbursementWhereInput>;
  orderBy?: Maybe<Array<Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DisbursementWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DisbursementScalarFieldEnum>>>;
};


export type QueryAggregateDisbursementArgs = {
  where?: Maybe<DisbursementWhereInput>;
  orderBy?: Maybe<Array<Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DisbursementWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueDocumentationArgs = {
  where: DocumentationWhereUniqueInput;
};


export type QueryFindFirstDocumentationArgs = {
  where?: Maybe<DocumentationWhereInput>;
  orderBy?: Maybe<Array<Maybe<DocumentationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DocumentationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DocumentationScalarFieldEnum>>>;
};


export type QueryFindManyDocumentationArgs = {
  where?: Maybe<DocumentationWhereInput>;
  orderBy?: Maybe<Array<Maybe<DocumentationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DocumentationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DocumentationScalarFieldEnum>>>;
};


export type QueryFindManyDocumentationCountArgs = {
  where?: Maybe<DocumentationWhereInput>;
  orderBy?: Maybe<Array<Maybe<DocumentationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DocumentationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DocumentationScalarFieldEnum>>>;
};


export type QueryAggregateDocumentationArgs = {
  where?: Maybe<DocumentationWhereInput>;
  orderBy?: Maybe<Array<Maybe<DocumentationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DocumentationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueEzyVetIntegrationArgs = {
  where: EzyVetIntegrationWhereUniqueInput;
};


export type QueryFindFirstEzyVetIntegrationArgs = {
  where?: Maybe<EzyVetIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EzyVetIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyEzyVetIntegrationArgs = {
  where?: Maybe<EzyVetIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EzyVetIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyEzyVetIntegrationCountArgs = {
  where?: Maybe<EzyVetIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EzyVetIntegrationScalarFieldEnum>>>;
};


export type QueryAggregateEzyVetIntegrationArgs = {
  where?: Maybe<EzyVetIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueFinancialTransactionArgs = {
  where: FinancialTransactionWhereUniqueInput;
};


export type QueryFindFirstFinancialTransactionArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FinancialTransactionScalarFieldEnum>>>;
};


export type QueryFindManyFinancialTransactionArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FinancialTransactionScalarFieldEnum>>>;
};


export type QueryFindManyFinancialTransactionCountArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FinancialTransactionScalarFieldEnum>>>;
};


export type QueryAggregateFinancialTransactionArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueFinancialAdjustmentArgs = {
  where: FinancialAdjustmentWhereUniqueInput;
};


export type QueryFindFirstFinancialAdjustmentArgs = {
  where?: Maybe<FinancialAdjustmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<FinancialAdjustmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FinancialAdjustmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FinancialAdjustmentScalarFieldEnum>>>;
};


export type QueryFindManyFinancialAdjustmentArgs = {
  where?: Maybe<FinancialAdjustmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<FinancialAdjustmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FinancialAdjustmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FinancialAdjustmentScalarFieldEnum>>>;
};


export type QueryFindManyFinancialAdjustmentCountArgs = {
  where?: Maybe<FinancialAdjustmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<FinancialAdjustmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FinancialAdjustmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FinancialAdjustmentScalarFieldEnum>>>;
};


export type QueryAggregateFinancialAdjustmentArgs = {
  where?: Maybe<FinancialAdjustmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<FinancialAdjustmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FinancialAdjustmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueFormSubmissionArgs = {
  where: FormSubmissionWhereUniqueInput;
};


export type QueryFindFirstFormSubmissionArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FormSubmissionScalarFieldEnum>>>;
};


export type QueryFindManyFormSubmissionArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FormSubmissionScalarFieldEnum>>>;
};


export type QueryFindManyFormSubmissionCountArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FormSubmissionScalarFieldEnum>>>;
};


export type QueryAggregateFormSubmissionArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueFormTemplateArgs = {
  where: FormTemplateWhereUniqueInput;
};


export type QueryFindFirstFormTemplateArgs = {
  where?: Maybe<FormTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FormTemplateScalarFieldEnum>>>;
};


export type QueryFindManyFormTemplateArgs = {
  where?: Maybe<FormTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FormTemplateScalarFieldEnum>>>;
};


export type QueryFindManyFormTemplateCountArgs = {
  where?: Maybe<FormTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FormTemplateScalarFieldEnum>>>;
};


export type QueryAggregateFormTemplateArgs = {
  where?: Maybe<FormTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInstinctIntegrationArgs = {
  where: InstinctIntegrationWhereUniqueInput;
};


export type QueryFindFirstInstinctIntegrationArgs = {
  where?: Maybe<InstinctIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InstinctIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InstinctIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyInstinctIntegrationArgs = {
  where?: Maybe<InstinctIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InstinctIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InstinctIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyInstinctIntegrationCountArgs = {
  where?: Maybe<InstinctIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InstinctIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InstinctIntegrationScalarFieldEnum>>>;
};


export type QueryAggregateInstinctIntegrationArgs = {
  where?: Maybe<InstinctIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InstinctIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryFindFirstInvoiceArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoiceScalarFieldEnum>>>;
};


export type QueryFindManyInvoiceArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoiceScalarFieldEnum>>>;
};


export type QueryFindManyInvoiceCountArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoiceScalarFieldEnum>>>;
};


export type QueryAggregateInvoiceArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInvoiceLineItemArgs = {
  where: InvoiceLineItemWhereUniqueInput;
};


export type QueryFindFirstInvoiceLineItemArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoiceLineItemScalarFieldEnum>>>;
};


export type QueryFindManyInvoiceLineItemArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoiceLineItemScalarFieldEnum>>>;
};


export type QueryFindManyInvoiceLineItemCountArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoiceLineItemScalarFieldEnum>>>;
};


export type QueryAggregateInvoiceLineItemArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
};


export type QueryFindFirstOrganizationArgs = {
  where?: Maybe<OrganizationWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationScalarFieldEnum>>>;
};


export type QueryFindManyOrganizationArgs = {
  where?: Maybe<OrganizationWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationScalarFieldEnum>>>;
};


export type QueryFindManyOrganizationCountArgs = {
  where?: Maybe<OrganizationWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationScalarFieldEnum>>>;
};


export type QueryAggregateOrganizationArgs = {
  where?: Maybe<OrganizationWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueOrganizationPetArgs = {
  where: OrganizationPetWhereUniqueInput;
};


export type QueryFindFirstOrganizationPetArgs = {
  where?: Maybe<OrganizationPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetScalarFieldEnum>>>;
};


export type QueryFindManyOrganizationPetArgs = {
  where?: Maybe<OrganizationPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetScalarFieldEnum>>>;
};


export type QueryFindManyOrganizationPetCountArgs = {
  where?: Maybe<OrganizationPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetScalarFieldEnum>>>;
};


export type QueryAggregateOrganizationPetArgs = {
  where?: Maybe<OrganizationPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueOrganizationPetParentArgs = {
  where: OrganizationPetParentWhereUniqueInput;
};


export type QueryFindFirstOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetParentScalarFieldEnum>>>;
};


export type QueryFindManyOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetParentScalarFieldEnum>>>;
};


export type QueryFindManyOrganizationPetParentCountArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetParentScalarFieldEnum>>>;
};


export type QueryAggregateOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueOrganizationPetToOrganizationPetParentArgs = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};


export type QueryFindFirstOrganizationPetToOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>>>;
};


export type QueryFindManyOrganizationPetToOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>>>;
};


export type QueryFindManyOrganizationPetToOrganizationPetParentCountArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>>>;
};


export type QueryAggregateOrganizationPetToOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniquePermissionArgs = {
  where: PermissionWhereUniqueInput;
};


export type QueryFindFirstPermissionArgs = {
  where?: Maybe<PermissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PermissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PermissionScalarFieldEnum>>>;
};


export type QueryFindManyPermissionArgs = {
  where?: Maybe<PermissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PermissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PermissionScalarFieldEnum>>>;
};


export type QueryFindManyPermissionCountArgs = {
  where?: Maybe<PermissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PermissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PermissionScalarFieldEnum>>>;
};


export type QueryAggregatePermissionArgs = {
  where?: Maybe<PermissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PermissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniquePetParentSettingArgs = {
  where: PetParentSettingWhereUniqueInput;
};


export type QueryFindFirstPetParentSettingArgs = {
  where?: Maybe<PetParentSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetParentSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PetParentSettingScalarFieldEnum>>>;
};


export type QueryFindManyPetParentSettingArgs = {
  where?: Maybe<PetParentSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetParentSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PetParentSettingScalarFieldEnum>>>;
};


export type QueryFindManyPetParentSettingCountArgs = {
  where?: Maybe<PetParentSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetParentSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PetParentSettingScalarFieldEnum>>>;
};


export type QueryAggregatePetParentSettingArgs = {
  where?: Maybe<PetParentSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetParentSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniquePimsInvoiceArgs = {
  where: PimsInvoiceWhereUniqueInput;
};


export type QueryFindFirstPimsInvoiceArgs = {
  where?: Maybe<PimsInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceScalarFieldEnum>>>;
};


export type QueryFindManyPimsInvoiceArgs = {
  where?: Maybe<PimsInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceScalarFieldEnum>>>;
};


export type QueryFindManyPimsInvoiceCountArgs = {
  where?: Maybe<PimsInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceScalarFieldEnum>>>;
};


export type QueryAggregatePimsInvoiceArgs = {
  where?: Maybe<PimsInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniquePimsInvoiceLineItemArgs = {
  where: PimsInvoiceLineItemWhereUniqueInput;
};


export type QueryFindFirstPimsInvoiceLineItemArgs = {
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceLineItemScalarFieldEnum>>>;
};


export type QueryFindManyPimsInvoiceLineItemArgs = {
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceLineItemScalarFieldEnum>>>;
};


export type QueryFindManyPimsInvoiceLineItemCountArgs = {
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceLineItemScalarFieldEnum>>>;
};


export type QueryAggregatePimsInvoiceLineItemArgs = {
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueServiceArgs = {
  where: ServiceWhereUniqueInput;
};


export type QueryFindFirstServiceArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceScalarFieldEnum>>>;
};


export type QueryFindManyServiceArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceScalarFieldEnum>>>;
};


export type QueryFindManyServiceCountArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceScalarFieldEnum>>>;
};


export type QueryAggregateServiceArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueServiceReminderTimingArgs = {
  where: ServiceReminderTimingWhereUniqueInput;
};


export type QueryFindFirstServiceReminderTimingArgs = {
  where?: Maybe<ServiceReminderTimingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderTimingScalarFieldEnum>>>;
};


export type QueryFindManyServiceReminderTimingArgs = {
  where?: Maybe<ServiceReminderTimingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderTimingScalarFieldEnum>>>;
};


export type QueryFindManyServiceReminderTimingCountArgs = {
  where?: Maybe<ServiceReminderTimingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderTimingScalarFieldEnum>>>;
};


export type QueryAggregateServiceReminderTimingArgs = {
  where?: Maybe<ServiceReminderTimingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueServiceReminderArgs = {
  where: ServiceReminderWhereUniqueInput;
};


export type QueryFindFirstServiceReminderArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderScalarFieldEnum>>>;
};


export type QueryFindManyServiceReminderArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderScalarFieldEnum>>>;
};


export type QueryFindManyServiceReminderCountArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderScalarFieldEnum>>>;
};


export type QueryAggregateServiceReminderArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueServiceReminderNotificationArgs = {
  where: ServiceReminderNotificationWhereUniqueInput;
};


export type QueryFindFirstServiceReminderNotificationArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderNotificationScalarFieldEnum>>>;
};


export type QueryFindManyServiceReminderNotificationArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderNotificationScalarFieldEnum>>>;
};


export type QueryFindManyServiceReminderNotificationCountArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderNotificationScalarFieldEnum>>>;
};


export type QueryAggregateServiceReminderNotificationArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueStaffRoleArgs = {
  where: StaffRoleWhereUniqueInput;
};


export type QueryFindFirstStaffRoleArgs = {
  where?: Maybe<StaffRoleWhereInput>;
  orderBy?: Maybe<Array<Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StaffRoleScalarFieldEnum>>>;
};


export type QueryFindManyStaffRoleArgs = {
  where?: Maybe<StaffRoleWhereInput>;
  orderBy?: Maybe<Array<Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StaffRoleScalarFieldEnum>>>;
};


export type QueryFindManyStaffRoleCountArgs = {
  where?: Maybe<StaffRoleWhereInput>;
  orderBy?: Maybe<Array<Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StaffRoleScalarFieldEnum>>>;
};


export type QueryAggregateStaffRoleArgs = {
  where?: Maybe<StaffRoleWhereInput>;
  orderBy?: Maybe<Array<Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueStripeDisputeArgs = {
  where: StripeDisputeWhereUniqueInput;
};


export type QueryFindFirstStripeDisputeArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeDisputeScalarFieldEnum>>>;
};


export type QueryFindManyStripeDisputeArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeDisputeScalarFieldEnum>>>;
};


export type QueryFindManyStripeDisputeCountArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeDisputeScalarFieldEnum>>>;
};


export type QueryAggregateStripeDisputeArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueStripePaymentIntentArgs = {
  where: StripePaymentIntentWhereUniqueInput;
};


export type QueryFindFirstStripePaymentIntentArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePaymentIntentScalarFieldEnum>>>;
};


export type QueryFindManyStripePaymentIntentArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePaymentIntentScalarFieldEnum>>>;
};


export type QueryFindManyStripePaymentIntentCountArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePaymentIntentScalarFieldEnum>>>;
};


export type QueryAggregateStripePaymentIntentArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueStripePaymentMethodArgs = {
  where: StripePaymentMethodWhereUniqueInput;
};


export type QueryFindFirstStripePaymentMethodArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePaymentMethodScalarFieldEnum>>>;
};


export type QueryFindManyStripePaymentMethodArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePaymentMethodScalarFieldEnum>>>;
};


export type QueryFindManyStripePaymentMethodCountArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePaymentMethodScalarFieldEnum>>>;
};


export type QueryAggregateStripePaymentMethodArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueStripePayoutArgs = {
  where: StripePayoutWhereUniqueInput;
};


export type QueryFindFirstStripePayoutArgs = {
  where?: Maybe<StripePayoutWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePayoutScalarFieldEnum>>>;
};


export type QueryFindManyStripePayoutArgs = {
  where?: Maybe<StripePayoutWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePayoutScalarFieldEnum>>>;
};


export type QueryFindManyStripePayoutCountArgs = {
  where?: Maybe<StripePayoutWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePayoutScalarFieldEnum>>>;
};


export type QueryAggregateStripePayoutArgs = {
  where?: Maybe<StripePayoutWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueStripePayoutAccountArgs = {
  where: StripePayoutAccountWhereUniqueInput;
};


export type QueryFindFirstStripePayoutAccountArgs = {
  where?: Maybe<StripePayoutAccountWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePayoutAccountScalarFieldEnum>>>;
};


export type QueryFindManyStripePayoutAccountArgs = {
  where?: Maybe<StripePayoutAccountWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePayoutAccountScalarFieldEnum>>>;
};


export type QueryFindManyStripePayoutAccountCountArgs = {
  where?: Maybe<StripePayoutAccountWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePayoutAccountScalarFieldEnum>>>;
};


export type QueryAggregateStripePayoutAccountArgs = {
  where?: Maybe<StripePayoutAccountWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueStripeReceiptArgs = {
  where: StripeReceiptWhereUniqueInput;
};


export type QueryFindFirstStripeReceiptArgs = {
  where?: Maybe<StripeReceiptWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeReceiptScalarFieldEnum>>>;
};


export type QueryFindManyStripeReceiptArgs = {
  where?: Maybe<StripeReceiptWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeReceiptScalarFieldEnum>>>;
};


export type QueryFindManyStripeReceiptCountArgs = {
  where?: Maybe<StripeReceiptWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeReceiptScalarFieldEnum>>>;
};


export type QueryAggregateStripeReceiptArgs = {
  where?: Maybe<StripeReceiptWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueStripeReceiptHistoryArgs = {
  where: StripeReceiptHistoryWhereUniqueInput;
};


export type QueryFindFirstStripeReceiptHistoryArgs = {
  where?: Maybe<StripeReceiptHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeReceiptHistoryScalarFieldEnum>>>;
};


export type QueryFindManyStripeReceiptHistoryArgs = {
  where?: Maybe<StripeReceiptHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeReceiptHistoryScalarFieldEnum>>>;
};


export type QueryFindManyStripeReceiptHistoryCountArgs = {
  where?: Maybe<StripeReceiptHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeReceiptHistoryScalarFieldEnum>>>;
};


export type QueryAggregateStripeReceiptHistoryArgs = {
  where?: Maybe<StripeReceiptHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueStripeRefundArgs = {
  where: StripeRefundWhereUniqueInput;
};


export type QueryFindFirstStripeRefundArgs = {
  where?: Maybe<StripeRefundWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeRefundScalarFieldEnum>>>;
};


export type QueryFindManyStripeRefundArgs = {
  where?: Maybe<StripeRefundWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeRefundScalarFieldEnum>>>;
};


export type QueryFindManyStripeRefundCountArgs = {
  where?: Maybe<StripeRefundWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeRefundScalarFieldEnum>>>;
};


export type QueryAggregateStripeRefundArgs = {
  where?: Maybe<StripeRefundWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueStripeTerminalArgs = {
  where: StripeTerminalWhereUniqueInput;
};


export type QueryFindFirstStripeTerminalArgs = {
  where?: Maybe<StripeTerminalWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeTerminalScalarFieldEnum>>>;
};


export type QueryFindManyStripeTerminalArgs = {
  where?: Maybe<StripeTerminalWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeTerminalScalarFieldEnum>>>;
};


export type QueryFindManyStripeTerminalCountArgs = {
  where?: Maybe<StripeTerminalWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeTerminalScalarFieldEnum>>>;
};


export type QueryAggregateStripeTerminalArgs = {
  where?: Maybe<StripeTerminalWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueStripeTerminalLocationArgs = {
  where: StripeTerminalLocationWhereUniqueInput;
};


export type QueryFindFirstStripeTerminalLocationArgs = {
  where?: Maybe<StripeTerminalLocationWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeTerminalLocationScalarFieldEnum>>>;
};


export type QueryFindManyStripeTerminalLocationArgs = {
  where?: Maybe<StripeTerminalLocationWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeTerminalLocationScalarFieldEnum>>>;
};


export type QueryFindManyStripeTerminalLocationCountArgs = {
  where?: Maybe<StripeTerminalLocationWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeTerminalLocationScalarFieldEnum>>>;
};


export type QueryAggregateStripeTerminalLocationArgs = {
  where?: Maybe<StripeTerminalLocationWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueTransactionArgs = {
  where: TransactionWhereUniqueInput;
};


export type QueryFindFirstTransactionArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TransactionScalarFieldEnum>>>;
};


export type QueryFindManyTransactionArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TransactionScalarFieldEnum>>>;
};


export type QueryFindManyTransactionCountArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TransactionScalarFieldEnum>>>;
};


export type QueryAggregateTransactionArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryFindFirstUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserScalarFieldEnum>>>;
};


export type QueryFindManyUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserScalarFieldEnum>>>;
};


export type QueryFindManyUserCountArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserScalarFieldEnum>>>;
};


export type QueryAggregateUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueUserAppointmentGroupingArgs = {
  where: UserAppointmentGroupingWhereUniqueInput;
};


export type QueryFindFirstUserAppointmentGroupingArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserAppointmentGroupingScalarFieldEnum>>>;
};


export type QueryFindManyUserAppointmentGroupingArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserAppointmentGroupingScalarFieldEnum>>>;
};


export type QueryFindManyUserAppointmentGroupingCountArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserAppointmentGroupingScalarFieldEnum>>>;
};


export type QueryAggregateUserAppointmentGroupingArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueUserNotificationSettingArgs = {
  where: UserNotificationSettingWhereUniqueInput;
};


export type QueryFindFirstUserNotificationSettingArgs = {
  where?: Maybe<UserNotificationSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserNotificationSettingScalarFieldEnum>>>;
};


export type QueryFindManyUserNotificationSettingArgs = {
  where?: Maybe<UserNotificationSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserNotificationSettingScalarFieldEnum>>>;
};


export type QueryFindManyUserNotificationSettingCountArgs = {
  where?: Maybe<UserNotificationSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserNotificationSettingScalarFieldEnum>>>;
};


export type QueryAggregateUserNotificationSettingArgs = {
  where?: Maybe<UserNotificationSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueUserSettingArgs = {
  where: UserSettingWhereUniqueInput;
};


export type QueryFindFirstUserSettingArgs = {
  where?: Maybe<UserSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserSettingScalarFieldEnum>>>;
};


export type QueryFindManyUserSettingArgs = {
  where?: Maybe<UserSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserSettingScalarFieldEnum>>>;
};


export type QueryFindManyUserSettingCountArgs = {
  where?: Maybe<UserSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserSettingScalarFieldEnum>>>;
};


export type QueryAggregateUserSettingArgs = {
  where?: Maybe<UserSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueChannelFilterSelectionArgs = {
  where: ChannelFilterSelectionWhereUniqueInput;
};


export type QueryFindFirstChannelFilterSelectionArgs = {
  where?: Maybe<ChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelFilterSelectionScalarFieldEnum>>>;
};


export type QueryFindManyChannelFilterSelectionArgs = {
  where?: Maybe<ChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelFilterSelectionScalarFieldEnum>>>;
};


export type QueryFindManyChannelFilterSelectionCountArgs = {
  where?: Maybe<ChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelFilterSelectionScalarFieldEnum>>>;
};


export type QueryAggregateChannelFilterSelectionArgs = {
  where?: Maybe<ChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueUserChannelFilterSelectionArgs = {
  where: UserChannelFilterSelectionWhereUniqueInput;
};


export type QueryFindFirstUserChannelFilterSelectionArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserChannelFilterSelectionScalarFieldEnum>>>;
};


export type QueryFindManyUserChannelFilterSelectionArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserChannelFilterSelectionScalarFieldEnum>>>;
};


export type QueryFindManyUserChannelFilterSelectionCountArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserChannelFilterSelectionScalarFieldEnum>>>;
};


export type QueryAggregateUserChannelFilterSelectionArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueVetAvailabilityArgs = {
  where: VetAvailabilityWhereUniqueInput;
};


export type QueryFindFirstVetAvailabilityArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityScalarFieldEnum>>>;
};


export type QueryFindManyVetAvailabilityArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityScalarFieldEnum>>>;
};


export type QueryFindManyVetAvailabilityCountArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityScalarFieldEnum>>>;
};


export type QueryAggregateVetAvailabilityArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueProviderAppointmentTypeRoomMapArgs = {
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};


export type QueryFindFirstProviderAppointmentTypeRoomMapArgs = {
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ProviderAppointmentTypeRoomMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ProviderAppointmentTypeRoomMapScalarFieldEnum>>>;
};


export type QueryFindManyProviderAppointmentTypeRoomMapArgs = {
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ProviderAppointmentTypeRoomMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ProviderAppointmentTypeRoomMapScalarFieldEnum>>>;
};


export type QueryFindManyProviderAppointmentTypeRoomMapCountArgs = {
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ProviderAppointmentTypeRoomMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ProviderAppointmentTypeRoomMapScalarFieldEnum>>>;
};


export type QueryAggregateProviderAppointmentTypeRoomMapArgs = {
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ProviderAppointmentTypeRoomMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueVetAvailabilityMapArgs = {
  where: VetAvailabilityMapWhereUniqueInput;
};


export type QueryFindFirstVetAvailabilityMapArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityMapScalarFieldEnum>>>;
};


export type QueryFindManyVetAvailabilityMapArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityMapScalarFieldEnum>>>;
};


export type QueryFindManyVetAvailabilityMapCountArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityMapScalarFieldEnum>>>;
};


export type QueryAggregateVetAvailabilityMapArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueVetAvailabilityClinicRoomMapArgs = {
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
};


export type QueryFindFirstVetAvailabilityClinicRoomMapArgs = {
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityClinicRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityClinicRoomMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityClinicRoomMapScalarFieldEnum>>>;
};


export type QueryFindManyVetAvailabilityClinicRoomMapArgs = {
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityClinicRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityClinicRoomMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityClinicRoomMapScalarFieldEnum>>>;
};


export type QueryFindManyVetAvailabilityClinicRoomMapCountArgs = {
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityClinicRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityClinicRoomMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityClinicRoomMapScalarFieldEnum>>>;
};


export type QueryAggregateVetAvailabilityClinicRoomMapArgs = {
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityClinicRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityClinicRoomMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClientConnectIntegrationArgs = {
  where: ClientConnectIntegrationWhereUniqueInput;
};


export type QueryFindFirstClientConnectIntegrationArgs = {
  where?: Maybe<ClientConnectIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClientConnectIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClientConnectIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClientConnectIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyClientConnectIntegrationArgs = {
  where?: Maybe<ClientConnectIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClientConnectIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClientConnectIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClientConnectIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyClientConnectIntegrationCountArgs = {
  where?: Maybe<ClientConnectIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClientConnectIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClientConnectIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClientConnectIntegrationScalarFieldEnum>>>;
};


export type QueryAggregateClientConnectIntegrationArgs = {
  where?: Maybe<ClientConnectIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClientConnectIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClientConnectIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueVetInfoArgs = {
  where: VetInfoWhereUniqueInput;
};


export type QueryFindFirstVetInfoArgs = {
  where?: Maybe<VetInfoWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetInfoScalarFieldEnum>>>;
};


export type QueryFindManyVetInfoArgs = {
  where?: Maybe<VetInfoWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetInfoScalarFieldEnum>>>;
};


export type QueryFindManyVetInfoCountArgs = {
  where?: Maybe<VetInfoWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetInfoScalarFieldEnum>>>;
};


export type QueryAggregateVetInfoArgs = {
  where?: Maybe<VetInfoWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueWorkflowEventSettingArgs = {
  where: WorkflowEventSettingWhereUniqueInput;
};


export type QueryFindFirstWorkflowEventSettingArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<WorkflowEventSettingScalarFieldEnum>>>;
};


export type QueryFindManyWorkflowEventSettingArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<WorkflowEventSettingScalarFieldEnum>>>;
};


export type QueryFindManyWorkflowEventSettingCountArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<WorkflowEventSettingScalarFieldEnum>>>;
};


export type QueryAggregateWorkflowEventSettingArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicOfficeHourArgs = {
  where: ClinicOfficeHourWhereUniqueInput;
};


export type QueryFindFirstClinicOfficeHourArgs = {
  where?: Maybe<ClinicOfficeHourWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicOfficeHourScalarFieldEnum>>>;
};


export type QueryFindManyClinicOfficeHourArgs = {
  where?: Maybe<ClinicOfficeHourWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicOfficeHourScalarFieldEnum>>>;
};


export type QueryFindManyClinicOfficeHourCountArgs = {
  where?: Maybe<ClinicOfficeHourWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicOfficeHourScalarFieldEnum>>>;
};


export type QueryAggregateClinicOfficeHourArgs = {
  where?: Maybe<ClinicOfficeHourWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueEmailProviderLogArgs = {
  where: EmailProviderLogWhereUniqueInput;
};


export type QueryFindFirstEmailProviderLogArgs = {
  where?: Maybe<EmailProviderLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailProviderLogScalarFieldEnum>>>;
};


export type QueryFindManyEmailProviderLogArgs = {
  where?: Maybe<EmailProviderLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailProviderLogScalarFieldEnum>>>;
};


export type QueryFindManyEmailProviderLogCountArgs = {
  where?: Maybe<EmailProviderLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailProviderLogScalarFieldEnum>>>;
};


export type QueryAggregateEmailProviderLogArgs = {
  where?: Maybe<EmailProviderLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueTwilioCallRecordingArgs = {
  where: TwilioCallRecordingWhereUniqueInput;
};


export type QueryFindFirstTwilioCallRecordingArgs = {
  where?: Maybe<TwilioCallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TwilioCallRecordingScalarFieldEnum>>>;
};


export type QueryFindManyTwilioCallRecordingArgs = {
  where?: Maybe<TwilioCallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TwilioCallRecordingScalarFieldEnum>>>;
};


export type QueryFindManyTwilioCallRecordingCountArgs = {
  where?: Maybe<TwilioCallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TwilioCallRecordingScalarFieldEnum>>>;
};


export type QueryAggregateTwilioCallRecordingArgs = {
  where?: Maybe<TwilioCallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCallRecordingArgs = {
  where: CallRecordingWhereUniqueInput;
};


export type QueryFindFirstCallRecordingArgs = {
  where?: Maybe<CallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallRecordingScalarFieldEnum>>>;
};


export type QueryFindManyCallRecordingArgs = {
  where?: Maybe<CallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallRecordingScalarFieldEnum>>>;
};


export type QueryFindManyCallRecordingCountArgs = {
  where?: Maybe<CallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallRecordingScalarFieldEnum>>>;
};


export type QueryAggregateCallRecordingArgs = {
  where?: Maybe<CallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCallHistoryArgs = {
  where: CallHistoryWhereUniqueInput;
};


export type QueryFindFirstCallHistoryArgs = {
  where?: Maybe<CallHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallHistoryScalarFieldEnum>>>;
};


export type QueryFindManyCallHistoryArgs = {
  where?: Maybe<CallHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallHistoryScalarFieldEnum>>>;
};


export type QueryFindManyCallHistoryCountArgs = {
  where?: Maybe<CallHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallHistoryScalarFieldEnum>>>;
};


export type QueryAggregateCallHistoryArgs = {
  where?: Maybe<CallHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCallParticipantArgs = {
  where: CallParticipantWhereUniqueInput;
};


export type QueryFindFirstCallParticipantArgs = {
  where?: Maybe<CallParticipantWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallParticipantScalarFieldEnum>>>;
};


export type QueryFindManyCallParticipantArgs = {
  where?: Maybe<CallParticipantWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallParticipantScalarFieldEnum>>>;
};


export type QueryFindManyCallParticipantCountArgs = {
  where?: Maybe<CallParticipantWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallParticipantScalarFieldEnum>>>;
};


export type QueryAggregateCallParticipantArgs = {
  where?: Maybe<CallParticipantWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueSmsNotificationStatusArgs = {
  where: SmsNotificationStatusWhereUniqueInput;
};


export type QueryFindFirstSmsNotificationStatusArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SmsNotificationStatusScalarFieldEnum>>>;
};


export type QueryFindManySmsNotificationStatusArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SmsNotificationStatusScalarFieldEnum>>>;
};


export type QueryFindManySmsNotificationStatusCountArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SmsNotificationStatusScalarFieldEnum>>>;
};


export type QueryAggregateSmsNotificationStatusArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueFeatureFlagArgs = {
  where: FeatureFlagWhereUniqueInput;
};


export type QueryFindFirstFeatureFlagArgs = {
  where?: Maybe<FeatureFlagWhereInput>;
  orderBy?: Maybe<Array<Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FeatureFlagScalarFieldEnum>>>;
};


export type QueryFindManyFeatureFlagArgs = {
  where?: Maybe<FeatureFlagWhereInput>;
  orderBy?: Maybe<Array<Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FeatureFlagScalarFieldEnum>>>;
};


export type QueryFindManyFeatureFlagCountArgs = {
  where?: Maybe<FeatureFlagWhereInput>;
  orderBy?: Maybe<Array<Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FeatureFlagScalarFieldEnum>>>;
};


export type QueryAggregateFeatureFlagArgs = {
  where?: Maybe<FeatureFlagWhereInput>;
  orderBy?: Maybe<Array<Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareAuthorizationRequestArgs = {
  where: CareAuthorizationRequestWhereUniqueInput;
};


export type QueryFindFirstCareAuthorizationRequestArgs = {
  where?: Maybe<CareAuthorizationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAuthorizationRequestScalarFieldEnum>>>;
};


export type QueryFindManyCareAuthorizationRequestArgs = {
  where?: Maybe<CareAuthorizationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAuthorizationRequestScalarFieldEnum>>>;
};


export type QueryFindManyCareAuthorizationRequestCountArgs = {
  where?: Maybe<CareAuthorizationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAuthorizationRequestScalarFieldEnum>>>;
};


export type QueryAggregateCareAuthorizationRequestArgs = {
  where?: Maybe<CareAuthorizationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareBenefitArgs = {
  where: CareBenefitWhereUniqueInput;
};


export type QueryFindFirstCareBenefitArgs = {
  where?: Maybe<CareBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitScalarFieldEnum>>>;
};


export type QueryFindManyCareBenefitArgs = {
  where?: Maybe<CareBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitScalarFieldEnum>>>;
};


export type QueryFindManyCareBenefitCountArgs = {
  where?: Maybe<CareBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitScalarFieldEnum>>>;
};


export type QueryAggregateCareBenefitArgs = {
  where?: Maybe<CareBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCarePlanArgs = {
  where: CarePlanWhereUniqueInput;
};


export type QueryFindFirstCarePlanArgs = {
  where?: Maybe<CarePlanWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanScalarFieldEnum>>>;
};


export type QueryFindManyCarePlanArgs = {
  where?: Maybe<CarePlanWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanScalarFieldEnum>>>;
};


export type QueryFindManyCarePlanCountArgs = {
  where?: Maybe<CarePlanWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanScalarFieldEnum>>>;
};


export type QueryAggregateCarePlanArgs = {
  where?: Maybe<CarePlanWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareAddonPackageArgs = {
  where: CareAddonPackageWhereUniqueInput;
};


export type QueryFindFirstCareAddonPackageArgs = {
  where?: Maybe<CareAddonPackageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageScalarFieldEnum>>>;
};


export type QueryFindManyCareAddonPackageArgs = {
  where?: Maybe<CareAddonPackageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageScalarFieldEnum>>>;
};


export type QueryFindManyCareAddonPackageCountArgs = {
  where?: Maybe<CareAddonPackageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageScalarFieldEnum>>>;
};


export type QueryAggregateCareAddonPackageArgs = {
  where?: Maybe<CareAddonPackageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCarePlanBenefitEnrollmentCarryoverArgs = {
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};


export type QueryFindFirstCarePlanBenefitEnrollmentCarryoverArgs = {
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitEnrollmentCarryoverScalarFieldEnum>>>;
};


export type QueryFindManyCarePlanBenefitEnrollmentCarryoverArgs = {
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitEnrollmentCarryoverScalarFieldEnum>>>;
};


export type QueryFindManyCarePlanBenefitEnrollmentCarryoverCountArgs = {
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitEnrollmentCarryoverScalarFieldEnum>>>;
};


export type QueryAggregateCarePlanBenefitEnrollmentCarryoverArgs = {
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCarePlanBenefitArgs = {
  where: CarePlanBenefitWhereUniqueInput;
};


export type QueryFindFirstCarePlanBenefitArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitScalarFieldEnum>>>;
};


export type QueryFindManyCarePlanBenefitArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitScalarFieldEnum>>>;
};


export type QueryFindManyCarePlanBenefitCountArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitScalarFieldEnum>>>;
};


export type QueryAggregateCarePlanBenefitArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareAddonPackageBenefitArgs = {
  where: CareAddonPackageBenefitWhereUniqueInput;
};


export type QueryFindFirstCareAddonPackageBenefitArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageBenefitScalarFieldEnum>>>;
};


export type QueryFindManyCareAddonPackageBenefitArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageBenefitScalarFieldEnum>>>;
};


export type QueryFindManyCareAddonPackageBenefitCountArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageBenefitScalarFieldEnum>>>;
};


export type QueryAggregateCareAddonPackageBenefitArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueEnrollmentSupportEventArgs = {
  where: EnrollmentSupportEventWhereUniqueInput;
};


export type QueryFindFirstEnrollmentSupportEventArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
  orderBy?: Maybe<Array<Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EnrollmentSupportEventScalarFieldEnum>>>;
};


export type QueryFindManyEnrollmentSupportEventArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
  orderBy?: Maybe<Array<Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EnrollmentSupportEventScalarFieldEnum>>>;
};


export type QueryFindManyEnrollmentSupportEventCountArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
  orderBy?: Maybe<Array<Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EnrollmentSupportEventScalarFieldEnum>>>;
};


export type QueryAggregateEnrollmentSupportEventArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
  orderBy?: Maybe<Array<Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCarePlanEnrollmentArgs = {
  where: CarePlanEnrollmentWhereUniqueInput;
};


export type QueryFindFirstCarePlanEnrollmentArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanEnrollmentScalarFieldEnum>>>;
};


export type QueryFindManyCarePlanEnrollmentArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanEnrollmentScalarFieldEnum>>>;
};


export type QueryFindManyCarePlanEnrollmentCountArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanEnrollmentScalarFieldEnum>>>;
};


export type QueryAggregateCarePlanEnrollmentArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareAddonEnrollmentArgs = {
  where: CareAddonEnrollmentWhereUniqueInput;
};


export type QueryFindFirstCareAddonEnrollmentArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonEnrollmentScalarFieldEnum>>>;
};


export type QueryFindManyCareAddonEnrollmentArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonEnrollmentScalarFieldEnum>>>;
};


export type QueryFindManyCareAddonEnrollmentCountArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonEnrollmentScalarFieldEnum>>>;
};


export type QueryAggregateCareAddonEnrollmentArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareEnrollmentToStripeInvoiceItemArgs = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};


export type QueryFindFirstCareEnrollmentToStripeInvoiceItemArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>>>;
};


export type QueryFindManyCareEnrollmentToStripeInvoiceItemArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>>>;
};


export type QueryFindManyCareEnrollmentToStripeInvoiceItemCountArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>>>;
};


export type QueryAggregateCareEnrollmentToStripeInvoiceItemArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareBenefitUsageArgs = {
  where: CareBenefitUsageWhereUniqueInput;
};


export type QueryFindFirstCareBenefitUsageArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageScalarFieldEnum>>>;
};


export type QueryFindManyCareBenefitUsageArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageScalarFieldEnum>>>;
};


export type QueryFindManyCareBenefitUsageCountArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageScalarFieldEnum>>>;
};


export type QueryAggregateCareBenefitUsageArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareBenefitUsageToInvoiceLineItemArgs = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};


export type QueryFindFirstCareBenefitUsageToInvoiceLineItemArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>>>;
};


export type QueryFindManyCareBenefitUsageToInvoiceLineItemArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>>>;
};


export type QueryFindManyCareBenefitUsageToInvoiceLineItemCountArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>>>;
};


export type QueryAggregateCareBenefitUsageToInvoiceLineItemArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareStripeSubscriptionArgs = {
  where: CareStripeSubscriptionWhereUniqueInput;
};


export type QueryFindFirstCareStripeSubscriptionArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareStripeSubscriptionScalarFieldEnum>>>;
};


export type QueryFindManyCareStripeSubscriptionArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareStripeSubscriptionScalarFieldEnum>>>;
};


export type QueryFindManyCareStripeSubscriptionCountArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareStripeSubscriptionScalarFieldEnum>>>;
};


export type QueryAggregateCareStripeSubscriptionArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueStripeInvoiceArgs = {
  where: StripeInvoiceWhereUniqueInput;
};


export type QueryFindFirstStripeInvoiceArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceScalarFieldEnum>>>;
};


export type QueryFindManyStripeInvoiceArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceScalarFieldEnum>>>;
};


export type QueryFindManyStripeInvoiceCountArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceScalarFieldEnum>>>;
};


export type QueryAggregateStripeInvoiceArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueStripeInvoiceItemArgs = {
  where: StripeInvoiceItemWhereUniqueInput;
};


export type QueryFindFirstStripeInvoiceItemArgs = {
  where?: Maybe<StripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceItemScalarFieldEnum>>>;
};


export type QueryFindManyStripeInvoiceItemArgs = {
  where?: Maybe<StripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceItemScalarFieldEnum>>>;
};


export type QueryFindManyStripeInvoiceItemCountArgs = {
  where?: Maybe<StripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceItemScalarFieldEnum>>>;
};


export type QueryAggregateStripeInvoiceItemArgs = {
  where?: Maybe<StripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueStripeCustomerArgs = {
  where: StripeCustomerWhereUniqueInput;
};


export type QueryFindFirstStripeCustomerArgs = {
  where?: Maybe<StripeCustomerWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeCustomerScalarFieldEnum>>>;
};


export type QueryFindManyStripeCustomerArgs = {
  where?: Maybe<StripeCustomerWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeCustomerScalarFieldEnum>>>;
};


export type QueryFindManyStripeCustomerCountArgs = {
  where?: Maybe<StripeCustomerWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeCustomerScalarFieldEnum>>>;
};


export type QueryAggregateStripeCustomerArgs = {
  where?: Maybe<StripeCustomerWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueVaccinationArgs = {
  where: VaccinationWhereUniqueInput;
};


export type QueryFindFirstVaccinationArgs = {
  where?: Maybe<VaccinationWhereInput>;
  orderBy?: Maybe<Array<Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VaccinationScalarFieldEnum>>>;
};


export type QueryFindManyVaccinationArgs = {
  where?: Maybe<VaccinationWhereInput>;
  orderBy?: Maybe<Array<Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VaccinationScalarFieldEnum>>>;
};


export type QueryFindManyVaccinationCountArgs = {
  where?: Maybe<VaccinationWhereInput>;
  orderBy?: Maybe<Array<Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VaccinationScalarFieldEnum>>>;
};


export type QueryAggregateVaccinationArgs = {
  where?: Maybe<VaccinationWhereInput>;
  orderBy?: Maybe<Array<Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniquePimsWritebackLogEntryArgs = {
  where: PimsWritebackLogEntryWhereUniqueInput;
};


export type QueryFindFirstPimsWritebackLogEntryArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsWritebackLogEntryScalarFieldEnum>>>;
};


export type QueryFindManyPimsWritebackLogEntryArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsWritebackLogEntryScalarFieldEnum>>>;
};


export type QueryFindManyPimsWritebackLogEntryCountArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsWritebackLogEntryScalarFieldEnum>>>;
};


export type QueryAggregatePimsWritebackLogEntryArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareAccountCreditIntentArgs = {
  where: CareAccountCreditIntentWhereUniqueInput;
};


export type QueryFindFirstCareAccountCreditIntentArgs = {
  where?: Maybe<CareAccountCreditIntentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAccountCreditIntentScalarFieldEnum>>>;
};


export type QueryFindManyCareAccountCreditIntentArgs = {
  where?: Maybe<CareAccountCreditIntentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAccountCreditIntentScalarFieldEnum>>>;
};


export type QueryFindManyCareAccountCreditIntentCountArgs = {
  where?: Maybe<CareAccountCreditIntentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAccountCreditIntentScalarFieldEnum>>>;
};


export type QueryAggregateCareAccountCreditIntentArgs = {
  where?: Maybe<CareAccountCreditIntentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareAccountCreditPimsWritebackLogEntryArgs = {
  where: CareAccountCreditPimsWritebackLogEntryWhereUniqueInput;
};


export type QueryFindFirstCareAccountCreditPimsWritebackLogEntryArgs = {
  where?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAccountCreditPimsWritebackLogEntryScalarFieldEnum>>>;
};


export type QueryFindManyCareAccountCreditPimsWritebackLogEntryArgs = {
  where?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAccountCreditPimsWritebackLogEntryScalarFieldEnum>>>;
};


export type QueryFindManyCareAccountCreditPimsWritebackLogEntryCountArgs = {
  where?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAccountCreditPimsWritebackLogEntryScalarFieldEnum>>>;
};


export type QueryAggregateCareAccountCreditPimsWritebackLogEntryArgs = {
  where?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInvoicePimsWritebackLogEntryArgs = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
};


export type QueryFindFirstInvoicePimsWritebackLogEntryArgs = {
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryScalarFieldEnum>>>;
};


export type QueryFindManyInvoicePimsWritebackLogEntryArgs = {
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryScalarFieldEnum>>>;
};


export type QueryFindManyInvoicePimsWritebackLogEntryCountArgs = {
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryScalarFieldEnum>>>;
};


export type QueryAggregateInvoicePimsWritebackLogEntryArgs = {
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueOrganizationCareBenefitToClinicServiceArgs = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};


export type QueryFindFirstOrganizationCareBenefitToClinicServiceArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>>>;
};


export type QueryFindManyOrganizationCareBenefitToClinicServiceArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>>>;
};


export type QueryFindManyOrganizationCareBenefitToClinicServiceCountArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>>>;
};


export type QueryAggregateOrganizationCareBenefitToClinicServiceArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueOrganizationCareBenefitToClinicServiceCategoryArgs = {
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
};


export type QueryFindFirstOrganizationCareBenefitToClinicServiceCategoryArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceCategoryScalarFieldEnum>>>;
};


export type QueryFindManyOrganizationCareBenefitToClinicServiceCategoryArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceCategoryScalarFieldEnum>>>;
};


export type QueryFindManyOrganizationCareBenefitToClinicServiceCategoryCountArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceCategoryScalarFieldEnum>>>;
};


export type QueryAggregateOrganizationCareBenefitToClinicServiceCategoryArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueMessageTemplateArgs = {
  where: MessageTemplateWhereUniqueInput;
};


export type QueryFindFirstMessageTemplateArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MessageTemplateScalarFieldEnum>>>;
};


export type QueryFindManyMessageTemplateArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MessageTemplateScalarFieldEnum>>>;
};


export type QueryFindManyMessageTemplateCountArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MessageTemplateScalarFieldEnum>>>;
};


export type QueryAggregateMessageTemplateArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueNotificationEventToMessageArgs = {
  where: NotificationEventToMessageWhereUniqueInput;
};


export type QueryFindFirstNotificationEventToMessageArgs = {
  where?: Maybe<NotificationEventToMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<NotificationEventToMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<NotificationEventToMessageScalarFieldEnum>>>;
};


export type QueryFindManyNotificationEventToMessageArgs = {
  where?: Maybe<NotificationEventToMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<NotificationEventToMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<NotificationEventToMessageScalarFieldEnum>>>;
};


export type QueryFindManyNotificationEventToMessageCountArgs = {
  where?: Maybe<NotificationEventToMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<NotificationEventToMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<NotificationEventToMessageScalarFieldEnum>>>;
};


export type QueryAggregateNotificationEventToMessageArgs = {
  where?: Maybe<NotificationEventToMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<NotificationEventToMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueRulesArgs = {
  where: RulesWhereUniqueInput;
};


export type QueryFindFirstRulesArgs = {
  where?: Maybe<RulesWhereInput>;
  orderBy?: Maybe<Array<Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<RulesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<RulesScalarFieldEnum>>>;
};


export type QueryFindManyRulesArgs = {
  where?: Maybe<RulesWhereInput>;
  orderBy?: Maybe<Array<Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<RulesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<RulesScalarFieldEnum>>>;
};


export type QueryFindManyRulesCountArgs = {
  where?: Maybe<RulesWhereInput>;
  orderBy?: Maybe<Array<Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<RulesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<RulesScalarFieldEnum>>>;
};


export type QueryAggregateRulesArgs = {
  where?: Maybe<RulesWhereInput>;
  orderBy?: Maybe<Array<Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<RulesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueAutomationRunArgs = {
  where: AutomationRunWhereUniqueInput;
};


export type QueryFindFirstAutomationRunArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AutomationRunScalarFieldEnum>>>;
};


export type QueryFindManyAutomationRunArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AutomationRunScalarFieldEnum>>>;
};


export type QueryFindManyAutomationRunCountArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AutomationRunScalarFieldEnum>>>;
};


export type QueryAggregateAutomationRunArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueAutomationRunActionArgs = {
  where: AutomationRunActionWhereUniqueInput;
};


export type QueryFindFirstAutomationRunActionArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AutomationRunActionScalarFieldEnum>>>;
};


export type QueryFindManyAutomationRunActionArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AutomationRunActionScalarFieldEnum>>>;
};


export type QueryFindManyAutomationRunActionCountArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AutomationRunActionScalarFieldEnum>>>;
};


export type QueryAggregateAutomationRunActionArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueEmergencyClinicsArgs = {
  where: EmergencyClinicsWhereUniqueInput;
};


export type QueryFindFirstEmergencyClinicsArgs = {
  where?: Maybe<EmergencyClinicsWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmergencyClinicsScalarFieldEnum>>>;
};


export type QueryFindManyEmergencyClinicsArgs = {
  where?: Maybe<EmergencyClinicsWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmergencyClinicsScalarFieldEnum>>>;
};


export type QueryFindManyEmergencyClinicsCountArgs = {
  where?: Maybe<EmergencyClinicsWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmergencyClinicsScalarFieldEnum>>>;
};


export type QueryAggregateEmergencyClinicsArgs = {
  where?: Maybe<EmergencyClinicsWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareEnrollmentPaymentArgs = {
  where: CareEnrollmentPaymentWhereUniqueInput;
};


export type QueryFindFirstCareEnrollmentPaymentArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentPaymentScalarFieldEnum>>>;
};


export type QueryFindManyCareEnrollmentPaymentArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentPaymentScalarFieldEnum>>>;
};


export type QueryFindManyCareEnrollmentPaymentCountArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentPaymentScalarFieldEnum>>>;
};


export type QueryAggregateCareEnrollmentPaymentArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueMassTextAlertArgs = {
  where: MassTextAlertWhereUniqueInput;
};


export type QueryFindFirstMassTextAlertArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertScalarFieldEnum>>>;
};


export type QueryFindManyMassTextAlertArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertScalarFieldEnum>>>;
};


export type QueryFindManyMassTextAlertCountArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertScalarFieldEnum>>>;
};


export type QueryAggregateMassTextAlertArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueMassTextAlertEntryArgs = {
  where: MassTextAlertEntryWhereUniqueInput;
};


export type QueryFindFirstMassTextAlertEntryArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryScalarFieldEnum>>>;
};


export type QueryFindManyMassTextAlertEntryArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryScalarFieldEnum>>>;
};


export type QueryFindManyMassTextAlertEntryCountArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryScalarFieldEnum>>>;
};


export type QueryAggregateMassTextAlertEntryArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueMassTextAlertEntryAppointmentArgs = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};


export type QueryFindFirstMassTextAlertEntryAppointmentArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>>>;
};


export type QueryFindManyMassTextAlertEntryAppointmentArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>>>;
};


export type QueryFindManyMassTextAlertEntryAppointmentCountArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>>>;
};


export type QueryAggregateMassTextAlertEntryAppointmentArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniquePetPortalSettingArgs = {
  where: PetPortalSettingWhereUniqueInput;
};


export type QueryFindFirstPetPortalSettingArgs = {
  where?: Maybe<PetPortalSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetPortalSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PetPortalSettingScalarFieldEnum>>>;
};


export type QueryFindManyPetPortalSettingArgs = {
  where?: Maybe<PetPortalSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetPortalSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PetPortalSettingScalarFieldEnum>>>;
};


export type QueryFindManyPetPortalSettingCountArgs = {
  where?: Maybe<PetPortalSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetPortalSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PetPortalSettingScalarFieldEnum>>>;
};


export type QueryAggregatePetPortalSettingArgs = {
  where?: Maybe<PetPortalSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetPortalSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniquePrescriptionArgs = {
  where: PrescriptionWhereUniqueInput;
};


export type QueryFindFirstPrescriptionArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PrescriptionScalarFieldEnum>>>;
};


export type QueryFindManyPrescriptionArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PrescriptionScalarFieldEnum>>>;
};


export type QueryFindManyPrescriptionCountArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PrescriptionScalarFieldEnum>>>;
};


export type QueryAggregatePrescriptionArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPhoneNumberArgs = {
  where: ClinicPhoneNumberWhereUniqueInput;
};


export type QueryFindFirstClinicPhoneNumberArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPhoneNumberScalarFieldEnum>>>;
};


export type QueryFindManyClinicPhoneNumberArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPhoneNumberScalarFieldEnum>>>;
};


export type QueryFindManyClinicPhoneNumberCountArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPhoneNumberScalarFieldEnum>>>;
};


export type QueryAggregateClinicPhoneNumberArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicOnboardingArgs = {
  where: ClinicOnboardingWhereUniqueInput;
};


export type QueryFindFirstClinicOnboardingArgs = {
  where?: Maybe<ClinicOnboardingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOnboardingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicOnboardingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicOnboardingScalarFieldEnum>>>;
};


export type QueryFindManyClinicOnboardingArgs = {
  where?: Maybe<ClinicOnboardingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOnboardingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicOnboardingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicOnboardingScalarFieldEnum>>>;
};


export type QueryFindManyClinicOnboardingCountArgs = {
  where?: Maybe<ClinicOnboardingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOnboardingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicOnboardingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicOnboardingScalarFieldEnum>>>;
};


export type QueryAggregateClinicOnboardingArgs = {
  where?: Maybe<ClinicOnboardingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOnboardingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicOnboardingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueSurveyAppointmentTypeSettingArgs = {
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
};


export type QueryFindFirstSurveyAppointmentTypeSettingArgs = {
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<SurveyAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SurveyAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SurveyAppointmentTypeSettingScalarFieldEnum>>>;
};


export type QueryFindManySurveyAppointmentTypeSettingArgs = {
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<SurveyAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SurveyAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SurveyAppointmentTypeSettingScalarFieldEnum>>>;
};


export type QueryFindManySurveyAppointmentTypeSettingCountArgs = {
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<SurveyAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SurveyAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SurveyAppointmentTypeSettingScalarFieldEnum>>>;
};


export type QueryAggregateSurveyAppointmentTypeSettingArgs = {
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<SurveyAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SurveyAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueSurveySettingArgs = {
  where: SurveySettingWhereUniqueInput;
};


export type QueryFindFirstSurveySettingArgs = {
  where?: Maybe<SurveySettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<SurveySettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SurveySettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SurveySettingScalarFieldEnum>>>;
};


export type QueryFindManySurveySettingArgs = {
  where?: Maybe<SurveySettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<SurveySettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SurveySettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SurveySettingScalarFieldEnum>>>;
};


export type QueryFindManySurveySettingCountArgs = {
  where?: Maybe<SurveySettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<SurveySettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SurveySettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SurveySettingScalarFieldEnum>>>;
};


export type QueryAggregateSurveySettingArgs = {
  where?: Maybe<SurveySettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<SurveySettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SurveySettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueSurveyResultArgs = {
  where: SurveyResultWhereUniqueInput;
};


export type QueryFindFirstSurveyResultArgs = {
  where?: Maybe<SurveyResultWhereInput>;
  orderBy?: Maybe<Array<Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SurveyResultScalarFieldEnum>>>;
};


export type QueryFindManySurveyResultArgs = {
  where?: Maybe<SurveyResultWhereInput>;
  orderBy?: Maybe<Array<Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SurveyResultScalarFieldEnum>>>;
};


export type QueryFindManySurveyResultCountArgs = {
  where?: Maybe<SurveyResultWhereInput>;
  orderBy?: Maybe<Array<Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SurveyResultScalarFieldEnum>>>;
};


export type QueryAggregateSurveyResultArgs = {
  where?: Maybe<SurveyResultWhereInput>;
  orderBy?: Maybe<Array<Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueServiceCategoryArgs = {
  where: ServiceCategoryWhereUniqueInput;
};


export type QueryFindFirstServiceCategoryArgs = {
  where?: Maybe<ServiceCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceCategoryScalarFieldEnum>>>;
};


export type QueryFindManyServiceCategoryArgs = {
  where?: Maybe<ServiceCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceCategoryScalarFieldEnum>>>;
};


export type QueryFindManyServiceCategoryCountArgs = {
  where?: Maybe<ServiceCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceCategoryScalarFieldEnum>>>;
};


export type QueryAggregateServiceCategoryArgs = {
  where?: Maybe<ServiceCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniquePimsPaymentTypeArgs = {
  where: PimsPaymentTypeWhereUniqueInput;
};


export type QueryFindFirstPimsPaymentTypeArgs = {
  where?: Maybe<PimsPaymentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsPaymentTypeScalarFieldEnum>>>;
};


export type QueryFindManyPimsPaymentTypeArgs = {
  where?: Maybe<PimsPaymentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsPaymentTypeScalarFieldEnum>>>;
};


export type QueryFindManyPimsPaymentTypeCountArgs = {
  where?: Maybe<PimsPaymentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsPaymentTypeScalarFieldEnum>>>;
};


export type QueryAggregatePimsPaymentTypeArgs = {
  where?: Maybe<PimsPaymentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueBitwerxPaymentWritebackConfigurationArgs = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};


export type QueryFindFirstBitwerxPaymentWritebackConfigurationArgs = {
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>>>;
};


export type QueryFindManyBitwerxPaymentWritebackConfigurationCountArgs = {
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>>>;
};


export type QueryFindUniqueGlobalPetParentArgs = {
  where: GlobalPetParentWhereUniqueInput;
};


export type QueryFindFirstGlobalPetParentArgs = {
  where?: Maybe<GlobalPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<GlobalPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<GlobalPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<GlobalPetParentScalarFieldEnum>>>;
};


export type QueryFindManyGlobalPetParentArgs = {
  where?: Maybe<GlobalPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<GlobalPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<GlobalPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<GlobalPetParentScalarFieldEnum>>>;
};


export type QueryFindManyGlobalPetParentCountArgs = {
  where?: Maybe<GlobalPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<GlobalPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<GlobalPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<GlobalPetParentScalarFieldEnum>>>;
};


export type QueryAggregateGlobalPetParentArgs = {
  where?: Maybe<GlobalPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<GlobalPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<GlobalPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLoyaltyProgramStatusHistoryArgs = {
  where: LoyaltyProgramStatusHistoryWhereUniqueInput;
};


export type QueryFindFirstLoyaltyProgramStatusHistoryArgs = {
  where?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramStatusHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyProgramStatusHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyProgramStatusHistoryScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyProgramStatusHistoryArgs = {
  where?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramStatusHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyProgramStatusHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyProgramStatusHistoryScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyProgramStatusHistoryCountArgs = {
  where?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramStatusHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyProgramStatusHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyProgramStatusHistoryScalarFieldEnum>>>;
};


export type QueryAggregateLoyaltyProgramStatusHistoryArgs = {
  where?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramStatusHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyProgramStatusHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLoyaltyProgramArgs = {
  where: LoyaltyProgramWhereUniqueInput;
};


export type QueryFindFirstLoyaltyProgramArgs = {
  where?: Maybe<LoyaltyProgramWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyProgramWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyProgramScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyProgramArgs = {
  where?: Maybe<LoyaltyProgramWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyProgramWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyProgramScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyProgramCountArgs = {
  where?: Maybe<LoyaltyProgramWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyProgramWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyProgramScalarFieldEnum>>>;
};


export type QueryAggregateLoyaltyProgramArgs = {
  where?: Maybe<LoyaltyProgramWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyProgramWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLoyaltyAccountMergerArgs = {
  where: LoyaltyAccountMergerWhereUniqueInput;
};


export type QueryFindFirstLoyaltyAccountMergerArgs = {
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountMergerScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyAccountMergerArgs = {
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountMergerScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyAccountMergerCountArgs = {
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountMergerScalarFieldEnum>>>;
};


export type QueryAggregateLoyaltyAccountMergerArgs = {
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLoyaltyAccountArgs = {
  where: LoyaltyAccountWhereUniqueInput;
};


export type QueryFindFirstLoyaltyAccountArgs = {
  where?: Maybe<LoyaltyAccountWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyAccountArgs = {
  where?: Maybe<LoyaltyAccountWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyAccountCountArgs = {
  where?: Maybe<LoyaltyAccountWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountScalarFieldEnum>>>;
};


export type QueryAggregateLoyaltyAccountArgs = {
  where?: Maybe<LoyaltyAccountWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLoyaltyAccountHolderArgs = {
  where: LoyaltyAccountHolderWhereUniqueInput;
};


export type QueryFindFirstLoyaltyAccountHolderArgs = {
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountHolderScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyAccountHolderArgs = {
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountHolderScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyAccountHolderCountArgs = {
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountHolderScalarFieldEnum>>>;
};


export type QueryAggregateLoyaltyAccountHolderArgs = {
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLoyaltyRewardArgs = {
  where: LoyaltyRewardWhereUniqueInput;
};


export type QueryFindFirstLoyaltyRewardArgs = {
  where?: Maybe<LoyaltyRewardWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyRewardWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyRewardArgs = {
  where?: Maybe<LoyaltyRewardWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyRewardWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyRewardCountArgs = {
  where?: Maybe<LoyaltyRewardWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyRewardWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardScalarFieldEnum>>>;
};


export type QueryAggregateLoyaltyRewardArgs = {
  where?: Maybe<LoyaltyRewardWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyRewardWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLoyaltyRewardRedemptionHistoryEntryArgs = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};


export type QueryFindFirstLoyaltyRewardRedemptionHistoryEntryArgs = {
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyRewardRedemptionHistoryEntryArgs = {
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyRewardRedemptionHistoryEntryCountArgs = {
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum>>>;
};


export type QueryAggregateLoyaltyRewardRedemptionHistoryEntryArgs = {
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLoyaltyRewardRedemptionArgs = {
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};


export type QueryFindFirstLoyaltyRewardRedemptionArgs = {
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardRedemptionScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyRewardRedemptionArgs = {
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardRedemptionScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyRewardRedemptionCountArgs = {
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardRedemptionScalarFieldEnum>>>;
};


export type QueryAggregateLoyaltyRewardRedemptionArgs = {
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLoyaltyPointDeltaArgs = {
  where: LoyaltyPointDeltaWhereUniqueInput;
};


export type QueryFindFirstLoyaltyPointDeltaArgs = {
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointDeltaScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyPointDeltaArgs = {
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointDeltaScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyPointDeltaCountArgs = {
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointDeltaScalarFieldEnum>>>;
};


export type QueryAggregateLoyaltyPointDeltaArgs = {
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLoyaltyInvoicePointGranterArgs = {
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
};


export type QueryFindFirstLoyaltyInvoicePointGranterArgs = {
  where?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyInvoicePointGranterScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyInvoicePointGranterArgs = {
  where?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyInvoicePointGranterScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyInvoicePointGranterCountArgs = {
  where?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyInvoicePointGranterScalarFieldEnum>>>;
};


export type QueryAggregateLoyaltyInvoicePointGranterArgs = {
  where?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLoyaltyPointGrantingServiceArgs = {
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
};


export type QueryFindFirstLoyaltyPointGrantingServiceArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointGrantingServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyPointGrantingServiceArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointGrantingServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyPointGrantingServiceCountArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointGrantingServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceScalarFieldEnum>>>;
};


export type QueryAggregateLoyaltyPointGrantingServiceArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointGrantingServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLoyaltyPointGrantingServiceCategoryArgs = {
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
};


export type QueryFindFirstLoyaltyPointGrantingServiceCategoryArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceCategoryScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyPointGrantingServiceCategoryArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceCategoryScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyPointGrantingServiceCategoryCountArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceCategoryScalarFieldEnum>>>;
};


export type QueryAggregateLoyaltyPointGrantingServiceCategoryArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLoyaltyPointUserGrantArgs = {
  where: LoyaltyPointUserGrantWhereUniqueInput;
};


export type QueryFindFirstLoyaltyPointUserGrantArgs = {
  where?: Maybe<LoyaltyPointUserGrantWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointUserGrantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointUserGrantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointUserGrantScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyPointUserGrantArgs = {
  where?: Maybe<LoyaltyPointUserGrantWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointUserGrantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointUserGrantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointUserGrantScalarFieldEnum>>>;
};


export type QueryFindManyLoyaltyPointUserGrantCountArgs = {
  where?: Maybe<LoyaltyPointUserGrantWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointUserGrantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointUserGrantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointUserGrantScalarFieldEnum>>>;
};


export type QueryAggregateLoyaltyPointUserGrantArgs = {
  where?: Maybe<LoyaltyPointUserGrantWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointUserGrantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LoyaltyPointUserGrantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniquePhoneNumberLookupRequestArgs = {
  where: PhoneNumberLookupRequestWhereUniqueInput;
};


export type QueryFindFirstPhoneNumberLookupRequestArgs = {
  where?: Maybe<PhoneNumberLookupRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PhoneNumberLookupRequestScalarFieldEnum>>>;
};


export type QueryFindManyPhoneNumberLookupRequestArgs = {
  where?: Maybe<PhoneNumberLookupRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PhoneNumberLookupRequestScalarFieldEnum>>>;
};


export type QueryFindManyPhoneNumberLookupRequestCountArgs = {
  where?: Maybe<PhoneNumberLookupRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PhoneNumberLookupRequestScalarFieldEnum>>>;
};


export type QueryAggregatePhoneNumberLookupRequestArgs = {
  where?: Maybe<PhoneNumberLookupRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniquePhoneNumberLookupResultArgs = {
  where: PhoneNumberLookupResultWhereUniqueInput;
};


export type QueryFindFirstPhoneNumberLookupResultArgs = {
  where?: Maybe<PhoneNumberLookupResultWhereInput>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupResultOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PhoneNumberLookupResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PhoneNumberLookupResultScalarFieldEnum>>>;
};


export type QueryFindManyPhoneNumberLookupResultArgs = {
  where?: Maybe<PhoneNumberLookupResultWhereInput>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupResultOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PhoneNumberLookupResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PhoneNumberLookupResultScalarFieldEnum>>>;
};


export type QueryFindManyPhoneNumberLookupResultCountArgs = {
  where?: Maybe<PhoneNumberLookupResultWhereInput>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupResultOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PhoneNumberLookupResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PhoneNumberLookupResultScalarFieldEnum>>>;
};


export type QueryAggregatePhoneNumberLookupResultArgs = {
  where?: Maybe<PhoneNumberLookupResultWhereInput>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupResultOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PhoneNumberLookupResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPetPimsSyncStatusArgs = {
  where: ClinicPetPimsSyncStatusWhereUniqueInput;
};


export type QueryFindFirstClinicPetPimsSyncStatusArgs = {
  where?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetPimsSyncStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetPimsSyncStatusScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetPimsSyncStatusArgs = {
  where?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetPimsSyncStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetPimsSyncStatusScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetPimsSyncStatusCountArgs = {
  where?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetPimsSyncStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetPimsSyncStatusScalarFieldEnum>>>;
};


export type QueryAggregateClinicPetPimsSyncStatusArgs = {
  where?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetPimsSyncStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPetParentPimsSyncStatusArgs = {
  where: ClinicPetParentPimsSyncStatusWhereUniqueInput;
};


export type QueryFindFirstClinicPetParentPimsSyncStatusArgs = {
  where?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentPimsSyncStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentPimsSyncStatusScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetParentPimsSyncStatusArgs = {
  where?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentPimsSyncStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentPimsSyncStatusScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetParentPimsSyncStatusCountArgs = {
  where?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentPimsSyncStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentPimsSyncStatusScalarFieldEnum>>>;
};


export type QueryAggregateClinicPetParentPimsSyncStatusArgs = {
  where?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentPimsSyncStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCampaignRegistryCampaignArgs = {
  where: CampaignRegistryCampaignWhereUniqueInput;
};


export type QueryFindFirstCampaignRegistryCampaignArgs = {
  where?: Maybe<CampaignRegistryCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<CampaignRegistryCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CampaignRegistryCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CampaignRegistryCampaignScalarFieldEnum>>>;
};


export type QueryFindManyCampaignRegistryCampaignArgs = {
  where?: Maybe<CampaignRegistryCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<CampaignRegistryCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CampaignRegistryCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CampaignRegistryCampaignScalarFieldEnum>>>;
};


export type QueryFindManyCampaignRegistryCampaignCountArgs = {
  where?: Maybe<CampaignRegistryCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<CampaignRegistryCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CampaignRegistryCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CampaignRegistryCampaignScalarFieldEnum>>>;
};


export type QueryAggregateCampaignRegistryCampaignArgs = {
  where?: Maybe<CampaignRegistryCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<CampaignRegistryCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CampaignRegistryCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLegalEntityArgs = {
  where: LegalEntityWhereUniqueInput;
};


export type QueryFindFirstLegalEntityArgs = {
  where?: Maybe<LegalEntityWhereInput>;
  orderBy?: Maybe<Array<Maybe<LegalEntityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LegalEntityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LegalEntityScalarFieldEnum>>>;
};


export type QueryFindManyLegalEntityArgs = {
  where?: Maybe<LegalEntityWhereInput>;
  orderBy?: Maybe<Array<Maybe<LegalEntityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LegalEntityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LegalEntityScalarFieldEnum>>>;
};


export type QueryFindManyLegalEntityCountArgs = {
  where?: Maybe<LegalEntityWhereInput>;
  orderBy?: Maybe<Array<Maybe<LegalEntityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LegalEntityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LegalEntityScalarFieldEnum>>>;
};


export type QueryAggregateLegalEntityArgs = {
  where?: Maybe<LegalEntityWhereInput>;
  orderBy?: Maybe<Array<Maybe<LegalEntityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LegalEntityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPetParentDeviceArgs = {
  where: ClinicPetParentDeviceWhereUniqueInput;
};


export type QueryFindFirstClinicPetParentDeviceArgs = {
  where?: Maybe<ClinicPetParentDeviceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentDeviceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentDeviceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentDeviceScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetParentDeviceArgs = {
  where?: Maybe<ClinicPetParentDeviceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentDeviceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentDeviceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentDeviceScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetParentDeviceCountArgs = {
  where?: Maybe<ClinicPetParentDeviceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentDeviceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentDeviceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentDeviceScalarFieldEnum>>>;
};


export type QueryAggregateClinicPetParentDeviceArgs = {
  where?: Maybe<ClinicPetParentDeviceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentDeviceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentDeviceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareBenefitUsageFollowupArgs = {
  where: CareBenefitUsageFollowupWhereUniqueInput;
};


export type QueryFindFirstCareBenefitUsageFollowupArgs = {
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageFollowupScalarFieldEnum>>>;
};


export type QueryFindManyCareBenefitUsageFollowupArgs = {
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageFollowupScalarFieldEnum>>>;
};


export type QueryFindManyCareBenefitUsageFollowupCountArgs = {
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageFollowupScalarFieldEnum>>>;
};


export type QueryAggregateCareBenefitUsageFollowupArgs = {
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareBenefitUsageFollowupStepArgs = {
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};


export type QueryFindFirstCareBenefitUsageFollowupStepArgs = {
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageFollowupStepScalarFieldEnum>>>;
};


export type QueryFindManyCareBenefitUsageFollowupStepArgs = {
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageFollowupStepScalarFieldEnum>>>;
};


export type QueryFindManyCareBenefitUsageFollowupStepCountArgs = {
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageFollowupStepScalarFieldEnum>>>;
};


export type QueryAggregateCareBenefitUsageFollowupStepArgs = {
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCarePlanProviderGroupArgs = {
  where: CarePlanProviderGroupWhereUniqueInput;
};


export type QueryFindFirstCarePlanProviderGroupArgs = {
  where?: Maybe<CarePlanProviderGroupWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanProviderGroupScalarFieldEnum>>>;
};


export type QueryFindManyCarePlanProviderGroupArgs = {
  where?: Maybe<CarePlanProviderGroupWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanProviderGroupScalarFieldEnum>>>;
};


export type QueryFindManyCarePlanProviderGroupCountArgs = {
  where?: Maybe<CarePlanProviderGroupWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanProviderGroupScalarFieldEnum>>>;
};


export type QueryAggregateCarePlanProviderGroupArgs = {
  where?: Maybe<CarePlanProviderGroupWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInsuranceIntegrationArgs = {
  where: InsuranceIntegrationWhereUniqueInput;
};


export type QueryFindFirstInsuranceIntegrationArgs = {
  where?: Maybe<InsuranceIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InsuranceIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InsuranceIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InsuranceIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyInsuranceIntegrationArgs = {
  where?: Maybe<InsuranceIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InsuranceIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InsuranceIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InsuranceIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyInsuranceIntegrationCountArgs = {
  where?: Maybe<InsuranceIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InsuranceIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InsuranceIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InsuranceIntegrationScalarFieldEnum>>>;
};


export type QueryAggregateInsuranceIntegrationArgs = {
  where?: Maybe<InsuranceIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InsuranceIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InsuranceIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueTrupanionIntegrationArgs = {
  where: TrupanionIntegrationWhereUniqueInput;
};


export type QueryFindFirstTrupanionIntegrationArgs = {
  where?: Maybe<TrupanionIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TrupanionIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyTrupanionIntegrationArgs = {
  where?: Maybe<TrupanionIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TrupanionIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyTrupanionIntegrationCountArgs = {
  where?: Maybe<TrupanionIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TrupanionIntegrationScalarFieldEnum>>>;
};


export type QueryAggregateTrupanionIntegrationArgs = {
  where?: Maybe<TrupanionIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueTrupanionIntegratedAppointmentTypeArgs = {
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
};


export type QueryFindFirstTrupanionIntegratedAppointmentTypeArgs = {
  where?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegratedAppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TrupanionIntegratedAppointmentTypeScalarFieldEnum>>>;
};


export type QueryFindManyTrupanionIntegratedAppointmentTypeArgs = {
  where?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegratedAppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TrupanionIntegratedAppointmentTypeScalarFieldEnum>>>;
};


export type QueryFindManyTrupanionIntegratedAppointmentTypeCountArgs = {
  where?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegratedAppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TrupanionIntegratedAppointmentTypeScalarFieldEnum>>>;
};


export type QueryAggregateTrupanionIntegratedAppointmentTypeArgs = {
  where?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegratedAppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueSentTrupanionExamDayOfferArgs = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};


export type QueryFindFirstSentTrupanionExamDayOfferArgs = {
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
  orderBy?: Maybe<Array<Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SentTrupanionExamDayOfferScalarFieldEnum>>>;
};


export type QueryFindManySentTrupanionExamDayOfferArgs = {
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
  orderBy?: Maybe<Array<Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SentTrupanionExamDayOfferScalarFieldEnum>>>;
};


export type QueryFindManySentTrupanionExamDayOfferCountArgs = {
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
  orderBy?: Maybe<Array<Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SentTrupanionExamDayOfferScalarFieldEnum>>>;
};


export type QueryAggregateSentTrupanionExamDayOfferArgs = {
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
  orderBy?: Maybe<Array<Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueBreedArgs = {
  where: BreedWhereUniqueInput;
};


export type QueryFindFirstBreedArgs = {
  where?: Maybe<BreedWhereInput>;
  orderBy?: Maybe<Array<Maybe<BreedOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BreedWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BreedScalarFieldEnum>>>;
};


export type QueryFindManyBreedArgs = {
  where?: Maybe<BreedWhereInput>;
  orderBy?: Maybe<Array<Maybe<BreedOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BreedWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BreedScalarFieldEnum>>>;
};


export type QueryFindManyBreedCountArgs = {
  where?: Maybe<BreedWhereInput>;
  orderBy?: Maybe<Array<Maybe<BreedOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BreedWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BreedScalarFieldEnum>>>;
};


export type QueryAggregateBreedArgs = {
  where?: Maybe<BreedWhereInput>;
  orderBy?: Maybe<Array<Maybe<BreedOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BreedWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueSpeciesArgs = {
  where: SpeciesWhereUniqueInput;
};


export type QueryFindFirstSpeciesArgs = {
  where?: Maybe<SpeciesWhereInput>;
  orderBy?: Maybe<Array<Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SpeciesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SpeciesScalarFieldEnum>>>;
};


export type QueryFindManySpeciesArgs = {
  where?: Maybe<SpeciesWhereInput>;
  orderBy?: Maybe<Array<Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SpeciesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SpeciesScalarFieldEnum>>>;
};


export type QueryFindManySpeciesCountArgs = {
  where?: Maybe<SpeciesWhereInput>;
  orderBy?: Maybe<Array<Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SpeciesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SpeciesScalarFieldEnum>>>;
};


export type QueryAggregateSpeciesArgs = {
  where?: Maybe<SpeciesWhereInput>;
  orderBy?: Maybe<Array<Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SpeciesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueSexArgs = {
  where: SexWhereUniqueInput;
};


export type QueryFindFirstSexArgs = {
  where?: Maybe<SexWhereInput>;
  orderBy?: Maybe<Array<Maybe<SexOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SexWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SexScalarFieldEnum>>>;
};


export type QueryFindManySexArgs = {
  where?: Maybe<SexWhereInput>;
  orderBy?: Maybe<Array<Maybe<SexOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SexWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SexScalarFieldEnum>>>;
};


export type QueryFindManySexCountArgs = {
  where?: Maybe<SexWhereInput>;
  orderBy?: Maybe<Array<Maybe<SexOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SexWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SexScalarFieldEnum>>>;
};


export type QueryAggregateSexArgs = {
  where?: Maybe<SexWhereInput>;
  orderBy?: Maybe<Array<Maybe<SexOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SexWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueColorArgs = {
  where: ColorWhereUniqueInput;
};


export type QueryFindFirstColorArgs = {
  where?: Maybe<ColorWhereInput>;
  orderBy?: Maybe<Array<Maybe<ColorOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ColorWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ColorScalarFieldEnum>>>;
};


export type QueryFindManyColorArgs = {
  where?: Maybe<ColorWhereInput>;
  orderBy?: Maybe<Array<Maybe<ColorOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ColorWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ColorScalarFieldEnum>>>;
};


export type QueryFindManyColorCountArgs = {
  where?: Maybe<ColorWhereInput>;
  orderBy?: Maybe<Array<Maybe<ColorOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ColorWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ColorScalarFieldEnum>>>;
};


export type QueryAggregateColorArgs = {
  where?: Maybe<ColorWhereInput>;
  orderBy?: Maybe<Array<Maybe<ColorOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ColorWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueMediaArgs = {
  where: MediaWhereUniqueInput;
};


export type QueryFindFirstMediaArgs = {
  where?: Maybe<MediaWhereInput>;
  orderBy?: Maybe<Array<Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MediaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MediaScalarFieldEnum>>>;
};


export type QueryFindManyMediaArgs = {
  where?: Maybe<MediaWhereInput>;
  orderBy?: Maybe<Array<Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MediaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MediaScalarFieldEnum>>>;
};


export type QueryFindManyMediaCountArgs = {
  where?: Maybe<MediaWhereInput>;
  orderBy?: Maybe<Array<Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MediaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MediaScalarFieldEnum>>>;
};


export type QueryAggregateMediaArgs = {
  where?: Maybe<MediaWhereInput>;
  orderBy?: Maybe<Array<Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MediaWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueLapsedPetParentTriggersArgs = {
  where: LapsedPetParentTriggersWhereUniqueInput;
};


export type QueryFindFirstLapsedPetParentTriggersArgs = {
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
  orderBy?: Maybe<Array<Maybe<LapsedPetParentTriggersOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LapsedPetParentTriggersWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LapsedPetParentTriggersScalarFieldEnum>>>;
};


export type QueryFindManyLapsedPetParentTriggersArgs = {
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
  orderBy?: Maybe<Array<Maybe<LapsedPetParentTriggersOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LapsedPetParentTriggersWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LapsedPetParentTriggersScalarFieldEnum>>>;
};


export type QueryFindManyLapsedPetParentTriggersCountArgs = {
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
  orderBy?: Maybe<Array<Maybe<LapsedPetParentTriggersOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LapsedPetParentTriggersWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<LapsedPetParentTriggersScalarFieldEnum>>>;
};


export type QueryAggregateLapsedPetParentTriggersArgs = {
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
  orderBy?: Maybe<Array<Maybe<LapsedPetParentTriggersOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<LapsedPetParentTriggersWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueConditionSetArgs = {
  where: ConditionSetWhereUniqueInput;
};


export type QueryFindFirstConditionSetArgs = {
  where?: Maybe<ConditionSetWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConditionSetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConditionSetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConditionSetScalarFieldEnum>>>;
};


export type QueryFindManyConditionSetArgs = {
  where?: Maybe<ConditionSetWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConditionSetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConditionSetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConditionSetScalarFieldEnum>>>;
};


export type QueryFindManyConditionSetCountArgs = {
  where?: Maybe<ConditionSetWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConditionSetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConditionSetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConditionSetScalarFieldEnum>>>;
};


export type QueryAggregateConditionSetArgs = {
  where?: Maybe<ConditionSetWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConditionSetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConditionSetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueConditionArgs = {
  where: ConditionWhereUniqueInput;
};


export type QueryFindFirstConditionArgs = {
  where?: Maybe<ConditionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConditionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConditionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConditionScalarFieldEnum>>>;
};


export type QueryFindManyConditionArgs = {
  where?: Maybe<ConditionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConditionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConditionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConditionScalarFieldEnum>>>;
};


export type QueryFindManyConditionCountArgs = {
  where?: Maybe<ConditionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConditionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConditionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConditionScalarFieldEnum>>>;
};


export type QueryAggregateConditionArgs = {
  where?: Maybe<ConditionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConditionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConditionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniquePushNotificationLogArgs = {
  where: PushNotificationLogWhereUniqueInput;
};


export type QueryFindFirstPushNotificationLogArgs = {
  where?: Maybe<PushNotificationLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PushNotificationLogScalarFieldEnum>>>;
};


export type QueryFindManyPushNotificationLogArgs = {
  where?: Maybe<PushNotificationLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PushNotificationLogScalarFieldEnum>>>;
};


export type QueryFindManyPushNotificationLogCountArgs = {
  where?: Maybe<PushNotificationLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PushNotificationLogScalarFieldEnum>>>;
};


export type QueryAggregatePushNotificationLogArgs = {
  where?: Maybe<PushNotificationLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueTrupanionCertificateAvailabilityArgs = {
  where: TrupanionCertificateAvailabilityWhereUniqueInput;
};


export type QueryFindFirstTrupanionCertificateAvailabilityArgs = {
  where?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionCertificateAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TrupanionCertificateAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TrupanionCertificateAvailabilityScalarFieldEnum>>>;
};


export type QueryFindManyTrupanionCertificateAvailabilityArgs = {
  where?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionCertificateAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TrupanionCertificateAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TrupanionCertificateAvailabilityScalarFieldEnum>>>;
};


export type QueryFindManyTrupanionCertificateAvailabilityCountArgs = {
  where?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionCertificateAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TrupanionCertificateAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TrupanionCertificateAvailabilityScalarFieldEnum>>>;
};


export type QueryAggregateTrupanionCertificateAvailabilityArgs = {
  where?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionCertificateAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TrupanionCertificateAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueMessagingPartnerArgs = {
  where: MessagingPartnerWhereUniqueInput;
};


export type QueryFindFirstMessagingPartnerArgs = {
  where?: Maybe<MessagingPartnerWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessagingPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessagingPartnerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MessagingPartnerScalarFieldEnum>>>;
};


export type QueryFindManyMessagingPartnerArgs = {
  where?: Maybe<MessagingPartnerWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessagingPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessagingPartnerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MessagingPartnerScalarFieldEnum>>>;
};


export type QueryFindManyMessagingPartnerCountArgs = {
  where?: Maybe<MessagingPartnerWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessagingPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessagingPartnerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MessagingPartnerScalarFieldEnum>>>;
};


export type QueryAggregateMessagingPartnerArgs = {
  where?: Maybe<MessagingPartnerWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessagingPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessagingPartnerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueMessagingCampaignArgs = {
  where: MessagingCampaignWhereUniqueInput;
};


export type QueryFindFirstMessagingCampaignArgs = {
  where?: Maybe<MessagingCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessagingCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessagingCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MessagingCampaignScalarFieldEnum>>>;
};


export type QueryFindManyMessagingCampaignArgs = {
  where?: Maybe<MessagingCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessagingCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessagingCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MessagingCampaignScalarFieldEnum>>>;
};


export type QueryFindManyMessagingCampaignCountArgs = {
  where?: Maybe<MessagingCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessagingCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessagingCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MessagingCampaignScalarFieldEnum>>>;
};


export type QueryAggregateMessagingCampaignArgs = {
  where?: Maybe<MessagingCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessagingCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessagingCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueConditionalEntityEvaluationArgs = {
  where: ConditionalEntityEvaluationWhereUniqueInput;
};


export type QueryFindFirstConditionalEntityEvaluationArgs = {
  where?: Maybe<ConditionalEntityEvaluationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConditionalEntityEvaluationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConditionalEntityEvaluationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConditionalEntityEvaluationScalarFieldEnum>>>;
};


export type QueryFindManyConditionalEntityEvaluationArgs = {
  where?: Maybe<ConditionalEntityEvaluationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConditionalEntityEvaluationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConditionalEntityEvaluationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConditionalEntityEvaluationScalarFieldEnum>>>;
};


export type QueryFindManyConditionalEntityEvaluationCountArgs = {
  where?: Maybe<ConditionalEntityEvaluationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConditionalEntityEvaluationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConditionalEntityEvaluationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConditionalEntityEvaluationScalarFieldEnum>>>;
};


export type QueryAggregateConditionalEntityEvaluationArgs = {
  where?: Maybe<ConditionalEntityEvaluationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConditionalEntityEvaluationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConditionalEntityEvaluationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniquePayoutBatchingPeriodArgs = {
  where: PayoutBatchingPeriodWhereUniqueInput;
};


export type QueryFindFirstPayoutBatchingPeriodArgs = {
  where?: Maybe<PayoutBatchingPeriodWhereInput>;
  orderBy?: Maybe<Array<Maybe<PayoutBatchingPeriodOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PayoutBatchingPeriodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PayoutBatchingPeriodScalarFieldEnum>>>;
};


export type QueryFindManyPayoutBatchingPeriodArgs = {
  where?: Maybe<PayoutBatchingPeriodWhereInput>;
  orderBy?: Maybe<Array<Maybe<PayoutBatchingPeriodOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PayoutBatchingPeriodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PayoutBatchingPeriodScalarFieldEnum>>>;
};


export type QueryFindManyPayoutBatchingPeriodCountArgs = {
  where?: Maybe<PayoutBatchingPeriodWhereInput>;
  orderBy?: Maybe<Array<Maybe<PayoutBatchingPeriodOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PayoutBatchingPeriodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PayoutBatchingPeriodScalarFieldEnum>>>;
};


export type QueryFindUniqueBitwerxHealthcheckArgs = {
  where: BitwerxHealthcheckWhereUniqueInput;
};


export type QueryFindFirstBitwerxHealthcheckArgs = {
  where?: Maybe<BitwerxHealthcheckWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxHealthcheckOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxHealthcheckWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxHealthcheckScalarFieldEnum>>>;
};


export type QueryFindManyBitwerxHealthcheckArgs = {
  where?: Maybe<BitwerxHealthcheckWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxHealthcheckOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxHealthcheckWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxHealthcheckScalarFieldEnum>>>;
};


export type QueryFindManyBitwerxHealthcheckCountArgs = {
  where?: Maybe<BitwerxHealthcheckWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxHealthcheckOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxHealthcheckWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxHealthcheckScalarFieldEnum>>>;
};


export type QueryAggregateBitwerxHealthcheckArgs = {
  where?: Maybe<BitwerxHealthcheckWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxHealthcheckOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxHealthcheckWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueBitwerxPaymentHealthcheckRunArgs = {
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
};


export type QueryFindFirstBitwerxPaymentHealthcheckRunArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckRunOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxPaymentHealthcheckRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckRunScalarFieldEnum>>>;
};


export type QueryFindManyBitwerxPaymentHealthcheckRunArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckRunOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxPaymentHealthcheckRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckRunScalarFieldEnum>>>;
};


export type QueryFindManyBitwerxPaymentHealthcheckRunCountArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckRunOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxPaymentHealthcheckRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckRunScalarFieldEnum>>>;
};


export type QueryAggregateBitwerxPaymentHealthcheckRunArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckRunOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxPaymentHealthcheckRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueBitwerxPaymentHealthcheckResultArgs = {
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
};


export type QueryFindFirstBitwerxPaymentHealthcheckResultArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckResultOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxPaymentHealthcheckResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckResultScalarFieldEnum>>>;
};


export type QueryFindManyBitwerxPaymentHealthcheckResultArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckResultOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxPaymentHealthcheckResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckResultScalarFieldEnum>>>;
};


export type QueryFindManyBitwerxPaymentHealthcheckResultCountArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckResultOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxPaymentHealthcheckResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckResultScalarFieldEnum>>>;
};


export type QueryAggregateBitwerxPaymentHealthcheckResultArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckResultOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxPaymentHealthcheckResultWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareLapsedPaymentArgs = {
  where: CareLapsedPaymentWhereUniqueInput;
};


export type QueryFindFirstCareLapsedPaymentArgs = {
  where?: Maybe<CareLapsedPaymentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareLapsedPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareLapsedPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareLapsedPaymentScalarFieldEnum>>>;
};


export type QueryFindManyCareLapsedPaymentArgs = {
  where?: Maybe<CareLapsedPaymentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareLapsedPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareLapsedPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareLapsedPaymentScalarFieldEnum>>>;
};


export type QueryFindManyCareLapsedPaymentCountArgs = {
  where?: Maybe<CareLapsedPaymentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareLapsedPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareLapsedPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareLapsedPaymentScalarFieldEnum>>>;
};


export type QueryAggregateCareLapsedPaymentArgs = {
  where?: Maybe<CareLapsedPaymentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareLapsedPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareLapsedPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPetParentCustomFieldValueArgs = {
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
};


export type QueryFindFirstClinicPetParentCustomFieldValueArgs = {
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentCustomFieldValueWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentCustomFieldValueScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetParentCustomFieldValueArgs = {
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentCustomFieldValueWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentCustomFieldValueScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetParentCustomFieldValueCountArgs = {
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentCustomFieldValueWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentCustomFieldValueScalarFieldEnum>>>;
};


export type QueryAggregateClinicPetParentCustomFieldValueArgs = {
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentCustomFieldValueWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicPetCustomFieldValueArgs = {
  where: ClinicPetCustomFieldValueWhereUniqueInput;
};


export type QueryFindFirstClinicPetCustomFieldValueArgs = {
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetCustomFieldValueWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetCustomFieldValueScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetCustomFieldValueArgs = {
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetCustomFieldValueWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetCustomFieldValueScalarFieldEnum>>>;
};


export type QueryFindManyClinicPetCustomFieldValueCountArgs = {
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetCustomFieldValueWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetCustomFieldValueScalarFieldEnum>>>;
};


export type QueryAggregateClinicPetCustomFieldValueArgs = {
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetCustomFieldValueWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCustomFieldDefinitionArgs = {
  where: CustomFieldDefinitionWhereUniqueInput;
};


export type QueryFindFirstCustomFieldDefinitionArgs = {
  where?: Maybe<CustomFieldDefinitionWhereInput>;
  orderBy?: Maybe<Array<Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CustomFieldDefinitionScalarFieldEnum>>>;
};


export type QueryFindManyCustomFieldDefinitionArgs = {
  where?: Maybe<CustomFieldDefinitionWhereInput>;
  orderBy?: Maybe<Array<Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CustomFieldDefinitionScalarFieldEnum>>>;
};


export type QueryFindManyCustomFieldDefinitionCountArgs = {
  where?: Maybe<CustomFieldDefinitionWhereInput>;
  orderBy?: Maybe<Array<Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CustomFieldDefinitionScalarFieldEnum>>>;
};


export type QueryAggregateCustomFieldDefinitionArgs = {
  where?: Maybe<CustomFieldDefinitionWhereInput>;
  orderBy?: Maybe<Array<Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCustomFieldPimsWritebackLogEntryArgs = {
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
};


export type QueryFindFirstCustomFieldPimsWritebackLogEntryArgs = {
  where?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CustomFieldPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CustomFieldPimsWritebackLogEntryScalarFieldEnum>>>;
};


export type QueryFindManyCustomFieldPimsWritebackLogEntryArgs = {
  where?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CustomFieldPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CustomFieldPimsWritebackLogEntryScalarFieldEnum>>>;
};


export type QueryFindManyCustomFieldPimsWritebackLogEntryCountArgs = {
  where?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CustomFieldPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CustomFieldPimsWritebackLogEntryScalarFieldEnum>>>;
};


export type QueryAggregateCustomFieldPimsWritebackLogEntryArgs = {
  where?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CustomFieldPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueEmailCampaignArgs = {
  where: EmailCampaignWhereUniqueInput;
};


export type QueryFindFirstEmailCampaignArgs = {
  where?: Maybe<EmailCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignScalarFieldEnum>>>;
};


export type QueryFindManyEmailCampaignArgs = {
  where?: Maybe<EmailCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignScalarFieldEnum>>>;
};


export type QueryFindManyEmailCampaignCountArgs = {
  where?: Maybe<EmailCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignScalarFieldEnum>>>;
};


export type QueryAggregateEmailCampaignArgs = {
  where?: Maybe<EmailCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueEmailCampaignTemplateArgs = {
  where: EmailCampaignTemplateWhereUniqueInput;
};


export type QueryFindFirstEmailCampaignTemplateArgs = {
  where?: Maybe<EmailCampaignTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignTemplateScalarFieldEnum>>>;
};


export type QueryFindManyEmailCampaignTemplateArgs = {
  where?: Maybe<EmailCampaignTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignTemplateScalarFieldEnum>>>;
};


export type QueryFindManyEmailCampaignTemplateCountArgs = {
  where?: Maybe<EmailCampaignTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignTemplateScalarFieldEnum>>>;
};


export type QueryAggregateEmailCampaignTemplateArgs = {
  where?: Maybe<EmailCampaignTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueEmailCampaignLogArgs = {
  where: EmailCampaignLogWhereUniqueInput;
};


export type QueryFindFirstEmailCampaignLogArgs = {
  where?: Maybe<EmailCampaignLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignLogScalarFieldEnum>>>;
};


export type QueryFindManyEmailCampaignLogArgs = {
  where?: Maybe<EmailCampaignLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignLogScalarFieldEnum>>>;
};


export type QueryFindManyEmailCampaignLogCountArgs = {
  where?: Maybe<EmailCampaignLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignLogScalarFieldEnum>>>;
};


export type QueryAggregateEmailCampaignLogArgs = {
  where?: Maybe<EmailCampaignLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueEmailCampaignUnsubscribeArgs = {
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};


export type QueryFindFirstEmailCampaignUnsubscribeArgs = {
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignUnsubscribeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignUnsubscribeScalarFieldEnum>>>;
};


export type QueryFindManyEmailCampaignUnsubscribeArgs = {
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignUnsubscribeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignUnsubscribeScalarFieldEnum>>>;
};


export type QueryFindManyEmailCampaignUnsubscribeCountArgs = {
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignUnsubscribeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignUnsubscribeScalarFieldEnum>>>;
};


export type QueryAggregateEmailCampaignUnsubscribeArgs = {
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignUnsubscribeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCanonicalSpeciesArgs = {
  where: CanonicalSpeciesWhereUniqueInput;
};


export type QueryFindFirstCanonicalSpeciesArgs = {
  where?: Maybe<CanonicalSpeciesWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalSpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalSpeciesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CanonicalSpeciesScalarFieldEnum>>>;
};


export type QueryFindManyCanonicalSpeciesArgs = {
  where?: Maybe<CanonicalSpeciesWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalSpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalSpeciesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CanonicalSpeciesScalarFieldEnum>>>;
};


export type QueryFindManyCanonicalSpeciesCountArgs = {
  where?: Maybe<CanonicalSpeciesWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalSpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalSpeciesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CanonicalSpeciesScalarFieldEnum>>>;
};


export type QueryAggregateCanonicalSpeciesArgs = {
  where?: Maybe<CanonicalSpeciesWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalSpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalSpeciesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCanonicalBreedArgs = {
  where: CanonicalBreedWhereUniqueInput;
};


export type QueryFindFirstCanonicalBreedArgs = {
  where?: Maybe<CanonicalBreedWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalBreedOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalBreedWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CanonicalBreedScalarFieldEnum>>>;
};


export type QueryFindManyCanonicalBreedArgs = {
  where?: Maybe<CanonicalBreedWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalBreedOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalBreedWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CanonicalBreedScalarFieldEnum>>>;
};


export type QueryFindManyCanonicalBreedCountArgs = {
  where?: Maybe<CanonicalBreedWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalBreedOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalBreedWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CanonicalBreedScalarFieldEnum>>>;
};


export type QueryAggregateCanonicalBreedArgs = {
  where?: Maybe<CanonicalBreedWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalBreedOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalBreedWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCanonicalCanineBreedProfileArgs = {
  where: CanonicalCanineBreedProfileWhereUniqueInput;
};


export type QueryFindFirstCanonicalCanineBreedProfileArgs = {
  where?: Maybe<CanonicalCanineBreedProfileWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalCanineBreedProfileOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CanonicalCanineBreedProfileScalarFieldEnum>>>;
};


export type QueryFindManyCanonicalCanineBreedProfileArgs = {
  where?: Maybe<CanonicalCanineBreedProfileWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalCanineBreedProfileOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CanonicalCanineBreedProfileScalarFieldEnum>>>;
};


export type QueryFindManyCanonicalCanineBreedProfileCountArgs = {
  where?: Maybe<CanonicalCanineBreedProfileWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalCanineBreedProfileOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CanonicalCanineBreedProfileScalarFieldEnum>>>;
};


export type QueryAggregateCanonicalCanineBreedProfileArgs = {
  where?: Maybe<CanonicalCanineBreedProfileWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalCanineBreedProfileOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCanonicalServiceArgs = {
  where: CanonicalServiceWhereUniqueInput;
};


export type QueryFindFirstCanonicalServiceArgs = {
  where?: Maybe<CanonicalServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CanonicalServiceScalarFieldEnum>>>;
};


export type QueryFindManyCanonicalServiceArgs = {
  where?: Maybe<CanonicalServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CanonicalServiceScalarFieldEnum>>>;
};


export type QueryFindManyCanonicalServiceCountArgs = {
  where?: Maybe<CanonicalServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CanonicalServiceScalarFieldEnum>>>;
};


export type QueryAggregateCanonicalServiceArgs = {
  where?: Maybe<CanonicalServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CanonicalServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueChemicalCompoundArgs = {
  where: ChemicalCompoundWhereUniqueInput;
};


export type QueryFindFirstChemicalCompoundArgs = {
  where?: Maybe<ChemicalCompoundWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChemicalCompoundOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChemicalCompoundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChemicalCompoundScalarFieldEnum>>>;
};


export type QueryFindManyChemicalCompoundArgs = {
  where?: Maybe<ChemicalCompoundWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChemicalCompoundOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChemicalCompoundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChemicalCompoundScalarFieldEnum>>>;
};


export type QueryFindManyChemicalCompoundCountArgs = {
  where?: Maybe<ChemicalCompoundWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChemicalCompoundOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChemicalCompoundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChemicalCompoundScalarFieldEnum>>>;
};


export type QueryAggregateChemicalCompoundArgs = {
  where?: Maybe<ChemicalCompoundWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChemicalCompoundOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChemicalCompoundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueActiveIngredientArgs = {
  where: ActiveIngredientWhereUniqueInput;
};


export type QueryFindFirstActiveIngredientArgs = {
  where?: Maybe<ActiveIngredientWhereInput>;
  orderBy?: Maybe<Array<Maybe<ActiveIngredientOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ActiveIngredientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ActiveIngredientScalarFieldEnum>>>;
};


export type QueryFindManyActiveIngredientArgs = {
  where?: Maybe<ActiveIngredientWhereInput>;
  orderBy?: Maybe<Array<Maybe<ActiveIngredientOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ActiveIngredientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ActiveIngredientScalarFieldEnum>>>;
};


export type QueryFindManyActiveIngredientCountArgs = {
  where?: Maybe<ActiveIngredientWhereInput>;
  orderBy?: Maybe<Array<Maybe<ActiveIngredientOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ActiveIngredientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ActiveIngredientScalarFieldEnum>>>;
};


export type QueryAggregateActiveIngredientArgs = {
  where?: Maybe<ActiveIngredientWhereInput>;
  orderBy?: Maybe<Array<Maybe<ActiveIngredientOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ActiveIngredientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryFindFirstProductArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ProductScalarFieldEnum>>>;
};


export type QueryFindManyProductArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ProductScalarFieldEnum>>>;
};


export type QueryFindManyProductCountArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ProductScalarFieldEnum>>>;
};


export type QueryAggregateProductArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformPartnerArgs = {
  where: InformPartnerWhereUniqueInput;
};


export type QueryFindFirstInformPartnerArgs = {
  where?: Maybe<InformPartnerWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerArgs = {
  where?: Maybe<InformPartnerWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerCountArgs = {
  where?: Maybe<InformPartnerWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerScalarFieldEnum>>>;
};


export type QueryAggregateInformPartnerArgs = {
  where?: Maybe<InformPartnerWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformPartnerIntegrationArgs = {
  where: InformPartnerIntegrationWhereUniqueInput;
};


export type QueryFindFirstInformPartnerIntegrationArgs = {
  where?: Maybe<InformPartnerIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerIntegrationArgs = {
  where?: Maybe<InformPartnerIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerIntegrationScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerIntegrationCountArgs = {
  where?: Maybe<InformPartnerIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerIntegrationScalarFieldEnum>>>;
};


export type QueryAggregateInformPartnerIntegrationArgs = {
  where?: Maybe<InformPartnerIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformPartnerIntegrationModelArgs = {
  where: InformPartnerIntegrationModelWhereUniqueInput;
};


export type QueryFindFirstInformPartnerIntegrationModelArgs = {
  where?: Maybe<InformPartnerIntegrationModelWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationModelOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerIntegrationModelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerIntegrationModelScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerIntegrationModelArgs = {
  where?: Maybe<InformPartnerIntegrationModelWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationModelOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerIntegrationModelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerIntegrationModelScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerIntegrationModelCountArgs = {
  where?: Maybe<InformPartnerIntegrationModelWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationModelOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerIntegrationModelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerIntegrationModelScalarFieldEnum>>>;
};


export type QueryAggregateInformPartnerIntegrationModelArgs = {
  where?: Maybe<InformPartnerIntegrationModelWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationModelOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerIntegrationModelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformPartnerUserArgs = {
  where: InformPartnerUserWhereUniqueInput;
};


export type QueryFindFirstInformPartnerUserArgs = {
  where?: Maybe<InformPartnerUserWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerUserScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerUserArgs = {
  where?: Maybe<InformPartnerUserWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerUserScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerUserCountArgs = {
  where?: Maybe<InformPartnerUserWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerUserScalarFieldEnum>>>;
};


export type QueryAggregateInformPartnerUserArgs = {
  where?: Maybe<InformPartnerUserWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformPartnerUserRoleArgs = {
  where: InformPartnerUserRoleWhereUniqueInput;
};


export type QueryFindFirstInformPartnerUserRoleArgs = {
  where?: Maybe<InformPartnerUserRoleWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerUserRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerUserRoleScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerUserRoleArgs = {
  where?: Maybe<InformPartnerUserRoleWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerUserRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerUserRoleScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerUserRoleCountArgs = {
  where?: Maybe<InformPartnerUserRoleWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerUserRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerUserRoleScalarFieldEnum>>>;
};


export type QueryAggregateInformPartnerUserRoleArgs = {
  where?: Maybe<InformPartnerUserRoleWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerUserRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueBoehringerIngelheimTerritoryArgs = {
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};


export type QueryFindFirstBoehringerIngelheimTerritoryArgs = {
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BoehringerIngelheimTerritoryScalarFieldEnum>>>;
};


export type QueryFindManyBoehringerIngelheimTerritoryArgs = {
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BoehringerIngelheimTerritoryScalarFieldEnum>>>;
};


export type QueryFindManyBoehringerIngelheimTerritoryCountArgs = {
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BoehringerIngelheimTerritoryScalarFieldEnum>>>;
};


export type QueryAggregateBoehringerIngelheimTerritoryArgs = {
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueBoehringerIngelheimCustomerAlignmentArgs = {
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
};


export type QueryFindFirstBoehringerIngelheimCustomerAlignmentArgs = {
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimCustomerAlignmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BoehringerIngelheimCustomerAlignmentScalarFieldEnum>>>;
};


export type QueryFindManyBoehringerIngelheimCustomerAlignmentArgs = {
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimCustomerAlignmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BoehringerIngelheimCustomerAlignmentScalarFieldEnum>>>;
};


export type QueryFindManyBoehringerIngelheimCustomerAlignmentCountArgs = {
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimCustomerAlignmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BoehringerIngelheimCustomerAlignmentScalarFieldEnum>>>;
};


export type QueryAggregateBoehringerIngelheimCustomerAlignmentArgs = {
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimCustomerAlignmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformPartnerCustomerArgs = {
  where: InformPartnerCustomerWhereUniqueInput;
};


export type QueryFindFirstInformPartnerCustomerArgs = {
  where?: Maybe<InformPartnerCustomerWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerCustomerScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerCustomerArgs = {
  where?: Maybe<InformPartnerCustomerWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerCustomerScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerCustomerCountArgs = {
  where?: Maybe<InformPartnerCustomerWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerCustomerScalarFieldEnum>>>;
};


export type QueryAggregateInformPartnerCustomerArgs = {
  where?: Maybe<InformPartnerCustomerWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformPartnerProgramArgs = {
  where: InformPartnerProgramWhereUniqueInput;
};


export type QueryFindFirstInformPartnerProgramArgs = {
  where?: Maybe<InformPartnerProgramWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerProgramWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerProgramScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerProgramArgs = {
  where?: Maybe<InformPartnerProgramWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerProgramWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerProgramScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerProgramCountArgs = {
  where?: Maybe<InformPartnerProgramWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerProgramWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerProgramScalarFieldEnum>>>;
};


export type QueryAggregateInformPartnerProgramArgs = {
  where?: Maybe<InformPartnerProgramWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerProgramWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformCampaignGroupArgs = {
  where: InformCampaignGroupWhereUniqueInput;
};


export type QueryFindFirstInformCampaignGroupArgs = {
  where?: Maybe<InformCampaignGroupWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignGroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignGroupScalarFieldEnum>>>;
};


export type QueryFindManyInformCampaignGroupArgs = {
  where?: Maybe<InformCampaignGroupWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignGroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignGroupScalarFieldEnum>>>;
};


export type QueryFindManyInformCampaignGroupCountArgs = {
  where?: Maybe<InformCampaignGroupWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignGroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignGroupScalarFieldEnum>>>;
};


export type QueryAggregateInformCampaignGroupArgs = {
  where?: Maybe<InformCampaignGroupWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignGroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformAudienceDefinitionArgs = {
  where: InformAudienceDefinitionWhereUniqueInput;
};


export type QueryFindFirstInformAudienceDefinitionArgs = {
  where?: Maybe<InformAudienceDefinitionWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformAudienceDefinitionScalarFieldEnum>>>;
};


export type QueryFindManyInformAudienceDefinitionArgs = {
  where?: Maybe<InformAudienceDefinitionWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformAudienceDefinitionScalarFieldEnum>>>;
};


export type QueryFindManyInformAudienceDefinitionCountArgs = {
  where?: Maybe<InformAudienceDefinitionWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformAudienceDefinitionScalarFieldEnum>>>;
};


export type QueryAggregateInformAudienceDefinitionArgs = {
  where?: Maybe<InformAudienceDefinitionWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformCampaignArgs = {
  where: InformCampaignWhereUniqueInput;
};


export type QueryFindFirstInformCampaignArgs = {
  where?: Maybe<InformCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignScalarFieldEnum>>>;
};


export type QueryFindManyInformCampaignArgs = {
  where?: Maybe<InformCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignScalarFieldEnum>>>;
};


export type QueryFindManyInformCampaignCountArgs = {
  where?: Maybe<InformCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignScalarFieldEnum>>>;
};


export type QueryAggregateInformCampaignArgs = {
  where?: Maybe<InformCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformScheduledCampaignArgs = {
  where: InformScheduledCampaignWhereUniqueInput;
};


export type QueryFindFirstInformScheduledCampaignArgs = {
  where?: Maybe<InformScheduledCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformScheduledCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformScheduledCampaignScalarFieldEnum>>>;
};


export type QueryFindManyInformScheduledCampaignArgs = {
  where?: Maybe<InformScheduledCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformScheduledCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformScheduledCampaignScalarFieldEnum>>>;
};


export type QueryFindManyInformScheduledCampaignCountArgs = {
  where?: Maybe<InformScheduledCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformScheduledCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformScheduledCampaignScalarFieldEnum>>>;
};


export type QueryAggregateInformScheduledCampaignArgs = {
  where?: Maybe<InformScheduledCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformScheduledCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformAutomationCampaignArgs = {
  where: InformAutomationCampaignWhereUniqueInput;
};


export type QueryFindFirstInformAutomationCampaignArgs = {
  where?: Maybe<InformAutomationCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformAutomationCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformAutomationCampaignScalarFieldEnum>>>;
};


export type QueryFindManyInformAutomationCampaignArgs = {
  where?: Maybe<InformAutomationCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformAutomationCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformAutomationCampaignScalarFieldEnum>>>;
};


export type QueryFindManyInformAutomationCampaignCountArgs = {
  where?: Maybe<InformAutomationCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformAutomationCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformAutomationCampaignScalarFieldEnum>>>;
};


export type QueryAggregateInformAutomationCampaignArgs = {
  where?: Maybe<InformAutomationCampaignWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformAutomationCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformEmailTemplateArgs = {
  where: InformEmailTemplateWhereUniqueInput;
};


export type QueryFindFirstInformEmailTemplateArgs = {
  where?: Maybe<InformEmailTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformEmailTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformEmailTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformEmailTemplateScalarFieldEnum>>>;
};


export type QueryFindManyInformEmailTemplateArgs = {
  where?: Maybe<InformEmailTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformEmailTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformEmailTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformEmailTemplateScalarFieldEnum>>>;
};


export type QueryFindManyInformEmailTemplateCountArgs = {
  where?: Maybe<InformEmailTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformEmailTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformEmailTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformEmailTemplateScalarFieldEnum>>>;
};


export type QueryAggregateInformEmailTemplateArgs = {
  where?: Maybe<InformEmailTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformEmailTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformEmailTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformCampaignExecutionArgs = {
  where: InformCampaignExecutionWhereUniqueInput;
};


export type QueryFindFirstInformCampaignExecutionArgs = {
  where?: Maybe<InformCampaignExecutionWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignExecutionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignExecutionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignExecutionScalarFieldEnum>>>;
};


export type QueryFindManyInformCampaignExecutionArgs = {
  where?: Maybe<InformCampaignExecutionWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignExecutionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignExecutionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignExecutionScalarFieldEnum>>>;
};


export type QueryFindManyInformCampaignExecutionCountArgs = {
  where?: Maybe<InformCampaignExecutionWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignExecutionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignExecutionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignExecutionScalarFieldEnum>>>;
};


export type QueryAggregateInformCampaignExecutionArgs = {
  where?: Maybe<InformCampaignExecutionWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignExecutionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignExecutionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformPartnerProgramEnrollmentArgs = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};


export type QueryFindFirstInformPartnerProgramEnrollmentArgs = {
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerProgramEnrollmentScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerProgramEnrollmentArgs = {
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerProgramEnrollmentScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerProgramEnrollmentCountArgs = {
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerProgramEnrollmentScalarFieldEnum>>>;
};


export type QueryAggregateInformPartnerProgramEnrollmentArgs = {
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformCampaignEnrollmentArgs = {
  where: InformCampaignEnrollmentWhereUniqueInput;
};


export type QueryFindFirstInformCampaignEnrollmentArgs = {
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignEnrollmentScalarFieldEnum>>>;
};


export type QueryFindManyInformCampaignEnrollmentArgs = {
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignEnrollmentScalarFieldEnum>>>;
};


export type QueryFindManyInformCampaignEnrollmentCountArgs = {
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignEnrollmentScalarFieldEnum>>>;
};


export type QueryAggregateInformCampaignEnrollmentArgs = {
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformCampaignEnrollmentCanonicalServiceArgs = {
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
};


export type QueryFindFirstInformCampaignEnrollmentCanonicalServiceArgs = {
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentCanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignEnrollmentCanonicalServiceScalarFieldEnum>>>;
};


export type QueryFindManyInformCampaignEnrollmentCanonicalServiceArgs = {
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentCanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignEnrollmentCanonicalServiceScalarFieldEnum>>>;
};


export type QueryFindManyInformCampaignEnrollmentCanonicalServiceCountArgs = {
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentCanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformCampaignEnrollmentCanonicalServiceScalarFieldEnum>>>;
};


export type QueryAggregateInformCampaignEnrollmentCanonicalServiceArgs = {
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentCanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformPartnerCustomerPharmacyProviderArgs = {
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
};


export type QueryFindFirstInformPartnerCustomerPharmacyProviderArgs = {
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerPharmacyProviderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerCustomerPharmacyProviderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerCustomerPharmacyProviderScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerCustomerPharmacyProviderArgs = {
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerPharmacyProviderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerCustomerPharmacyProviderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerCustomerPharmacyProviderScalarFieldEnum>>>;
};


export type QueryFindManyInformPartnerCustomerPharmacyProviderCountArgs = {
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerPharmacyProviderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerCustomerPharmacyProviderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformPartnerCustomerPharmacyProviderScalarFieldEnum>>>;
};


export type QueryAggregateInformPartnerCustomerPharmacyProviderArgs = {
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerPharmacyProviderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformPartnerCustomerPharmacyProviderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformEntityHistoryArgs = {
  where: InformEntityHistoryWhereUniqueInput;
};


export type QueryFindFirstInformEntityHistoryArgs = {
  where?: Maybe<InformEntityHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformEntityHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformEntityHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformEntityHistoryScalarFieldEnum>>>;
};


export type QueryFindManyInformEntityHistoryArgs = {
  where?: Maybe<InformEntityHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformEntityHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformEntityHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformEntityHistoryScalarFieldEnum>>>;
};


export type QueryFindManyInformEntityHistoryCountArgs = {
  where?: Maybe<InformEntityHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformEntityHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformEntityHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformEntityHistoryScalarFieldEnum>>>;
};


export type QueryAggregateInformEntityHistoryArgs = {
  where?: Maybe<InformEntityHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformEntityHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformEntityHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueClinicBlockedPhoneNumberArgs = {
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};


export type QueryFindFirstClinicBlockedPhoneNumberArgs = {
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicBlockedPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicBlockedPhoneNumberScalarFieldEnum>>>;
};


export type QueryFindManyClinicBlockedPhoneNumberArgs = {
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicBlockedPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicBlockedPhoneNumberScalarFieldEnum>>>;
};


export type QueryFindManyClinicBlockedPhoneNumberCountArgs = {
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicBlockedPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicBlockedPhoneNumberScalarFieldEnum>>>;
};


export type QueryAggregateClinicBlockedPhoneNumberArgs = {
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicBlockedPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueChannelMessageAutoResponseArgs = {
  where: ChannelMessageAutoResponseWhereUniqueInput;
};


export type QueryFindFirstChannelMessageAutoResponseArgs = {
  where?: Maybe<ChannelMessageAutoResponseWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAutoResponseOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageAutoResponseWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAutoResponseScalarFieldEnum>>>;
};


export type QueryFindManyChannelMessageAutoResponseArgs = {
  where?: Maybe<ChannelMessageAutoResponseWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAutoResponseOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageAutoResponseWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAutoResponseScalarFieldEnum>>>;
};


export type QueryFindManyChannelMessageAutoResponseCountArgs = {
  where?: Maybe<ChannelMessageAutoResponseWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAutoResponseOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageAutoResponseWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAutoResponseScalarFieldEnum>>>;
};


export type QueryAggregateChannelMessageAutoResponseArgs = {
  where?: Maybe<ChannelMessageAutoResponseWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAutoResponseOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageAutoResponseWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareSubscriptionTrueUpLogEntryArgs = {
  where: CareSubscriptionTrueUpLogEntryWhereUniqueInput;
};


export type QueryFindFirstCareSubscriptionTrueUpLogEntryArgs = {
  where?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareSubscriptionTrueUpLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareSubscriptionTrueUpLogEntryScalarFieldEnum>>>;
};


export type QueryFindManyCareSubscriptionTrueUpLogEntryArgs = {
  where?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareSubscriptionTrueUpLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareSubscriptionTrueUpLogEntryScalarFieldEnum>>>;
};


export type QueryFindManyCareSubscriptionTrueUpLogEntryCountArgs = {
  where?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareSubscriptionTrueUpLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareSubscriptionTrueUpLogEntryScalarFieldEnum>>>;
};


export type QueryAggregateCareSubscriptionTrueUpLogEntryArgs = {
  where?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareSubscriptionTrueUpLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueCareSubscriptionTrueUpArgs = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
};


export type QueryFindFirstCareSubscriptionTrueUpArgs = {
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareSubscriptionTrueUpScalarFieldEnum>>>;
};


export type QueryFindManyCareSubscriptionTrueUpArgs = {
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareSubscriptionTrueUpScalarFieldEnum>>>;
};


export type QueryFindManyCareSubscriptionTrueUpCountArgs = {
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareSubscriptionTrueUpScalarFieldEnum>>>;
};


export type QueryAggregateCareSubscriptionTrueUpArgs = {
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueHillsToHomeApiLogArgs = {
  where: HillsToHomeApiLogWhereUniqueInput;
};


export type QueryFindFirstHillsToHomeApiLogArgs = {
  where?: Maybe<HillsToHomeApiLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<HillsToHomeApiLogScalarFieldEnum>>>;
};


export type QueryFindManyHillsToHomeApiLogArgs = {
  where?: Maybe<HillsToHomeApiLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<HillsToHomeApiLogScalarFieldEnum>>>;
};


export type QueryFindManyHillsToHomeApiLogCountArgs = {
  where?: Maybe<HillsToHomeApiLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<HillsToHomeApiLogScalarFieldEnum>>>;
};


export type QueryAggregateHillsToHomeApiLogArgs = {
  where?: Maybe<HillsToHomeApiLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueBoardFilterColumnArgs = {
  where: BoardFilterColumnWhereUniqueInput;
};


export type QueryFindFirstBoardFilterColumnArgs = {
  where?: Maybe<BoardFilterColumnWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoardFilterColumnWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BoardFilterColumnScalarFieldEnum>>>;
};


export type QueryFindManyBoardFilterColumnArgs = {
  where?: Maybe<BoardFilterColumnWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoardFilterColumnWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BoardFilterColumnScalarFieldEnum>>>;
};


export type QueryFindManyBoardFilterColumnCountArgs = {
  where?: Maybe<BoardFilterColumnWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoardFilterColumnWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BoardFilterColumnScalarFieldEnum>>>;
};


export type QueryAggregateBoardFilterColumnArgs = {
  where?: Maybe<BoardFilterColumnWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoardFilterColumnWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueBoardFilterSpecificDateRangeArgs = {
  where: BoardFilterSpecificDateRangeWhereUniqueInput;
};


export type QueryFindFirstBoardFilterSpecificDateRangeArgs = {
  where?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoardFilterSpecificDateRangeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BoardFilterSpecificDateRangeScalarFieldEnum>>>;
};


export type QueryFindManyBoardFilterSpecificDateRangeArgs = {
  where?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoardFilterSpecificDateRangeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BoardFilterSpecificDateRangeScalarFieldEnum>>>;
};


export type QueryFindManyBoardFilterSpecificDateRangeCountArgs = {
  where?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoardFilterSpecificDateRangeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BoardFilterSpecificDateRangeScalarFieldEnum>>>;
};


export type QueryAggregateBoardFilterSpecificDateRangeArgs = {
  where?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
  orderBy?: Maybe<Array<Maybe<BoardFilterSpecificDateRangeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueUserBoardFilterArgs = {
  where: UserBoardFilterWhereUniqueInput;
};


export type QueryFindFirstUserBoardFilterArgs = {
  where?: Maybe<UserBoardFilterWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserBoardFilterOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserBoardFilterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserBoardFilterScalarFieldEnum>>>;
};


export type QueryFindManyUserBoardFilterArgs = {
  where?: Maybe<UserBoardFilterWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserBoardFilterOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserBoardFilterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserBoardFilterScalarFieldEnum>>>;
};


export type QueryFindManyUserBoardFilterCountArgs = {
  where?: Maybe<UserBoardFilterWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserBoardFilterOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserBoardFilterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserBoardFilterScalarFieldEnum>>>;
};


export type QueryAggregateUserBoardFilterArgs = {
  where?: Maybe<UserBoardFilterWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserBoardFilterOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserBoardFilterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueSegmentArgs = {
  where: SegmentWhereUniqueInput;
};


export type QueryFindFirstSegmentArgs = {
  where?: Maybe<SegmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<SegmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SegmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SegmentScalarFieldEnum>>>;
};


export type QueryFindManySegmentArgs = {
  where?: Maybe<SegmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<SegmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SegmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SegmentScalarFieldEnum>>>;
};


export type QueryFindManySegmentCountArgs = {
  where?: Maybe<SegmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<SegmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SegmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SegmentScalarFieldEnum>>>;
};


export type QueryAggregateSegmentArgs = {
  where?: Maybe<SegmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<SegmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SegmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueNonPrismaSchemaScriptRanArgs = {
  where: NonPrismaSchemaScriptRanWhereUniqueInput;
};


export type QueryFindFirstNonPrismaSchemaScriptRanArgs = {
  where?: Maybe<NonPrismaSchemaScriptRanWhereInput>;
  orderBy?: Maybe<Array<Maybe<NonPrismaSchemaScriptRanOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<NonPrismaSchemaScriptRanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<NonPrismaSchemaScriptRanScalarFieldEnum>>>;
};


export type QueryFindManyNonPrismaSchemaScriptRanArgs = {
  where?: Maybe<NonPrismaSchemaScriptRanWhereInput>;
  orderBy?: Maybe<Array<Maybe<NonPrismaSchemaScriptRanOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<NonPrismaSchemaScriptRanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<NonPrismaSchemaScriptRanScalarFieldEnum>>>;
};


export type QueryFindManyNonPrismaSchemaScriptRanCountArgs = {
  where?: Maybe<NonPrismaSchemaScriptRanWhereInput>;
  orderBy?: Maybe<Array<Maybe<NonPrismaSchemaScriptRanOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<NonPrismaSchemaScriptRanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<NonPrismaSchemaScriptRanScalarFieldEnum>>>;
};


export type QueryAggregateNonPrismaSchemaScriptRanArgs = {
  where?: Maybe<NonPrismaSchemaScriptRanWhereInput>;
  orderBy?: Maybe<Array<Maybe<NonPrismaSchemaScriptRanOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<NonPrismaSchemaScriptRanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueVetsourceInstallationArgs = {
  where: VetsourceInstallationWhereUniqueInput;
};


export type QueryFindFirstVetsourceInstallationArgs = {
  where?: Maybe<VetsourceInstallationWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceInstallationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetsourceInstallationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetsourceInstallationScalarFieldEnum>>>;
};


export type QueryFindManyVetsourceInstallationArgs = {
  where?: Maybe<VetsourceInstallationWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceInstallationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetsourceInstallationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetsourceInstallationScalarFieldEnum>>>;
};


export type QueryFindManyVetsourceInstallationCountArgs = {
  where?: Maybe<VetsourceInstallationWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceInstallationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetsourceInstallationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetsourceInstallationScalarFieldEnum>>>;
};


export type QueryAggregateVetsourceInstallationArgs = {
  where?: Maybe<VetsourceInstallationWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceInstallationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetsourceInstallationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueEmailCampaignCustomListArgs = {
  where: EmailCampaignCustomListWhereUniqueInput;
};


export type QueryFindFirstEmailCampaignCustomListArgs = {
  where?: Maybe<EmailCampaignCustomListWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListScalarFieldEnum>>>;
};


export type QueryFindManyEmailCampaignCustomListArgs = {
  where?: Maybe<EmailCampaignCustomListWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListScalarFieldEnum>>>;
};


export type QueryFindManyEmailCampaignCustomListCountArgs = {
  where?: Maybe<EmailCampaignCustomListWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListScalarFieldEnum>>>;
};


export type QueryAggregateEmailCampaignCustomListArgs = {
  where?: Maybe<EmailCampaignCustomListWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueEmailCampaignCustomListRecipientArgs = {
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
};


export type QueryFindFirstEmailCampaignCustomListRecipientArgs = {
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListRecipientOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignCustomListRecipientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListRecipientScalarFieldEnum>>>;
};


export type QueryFindManyEmailCampaignCustomListRecipientArgs = {
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListRecipientOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignCustomListRecipientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListRecipientScalarFieldEnum>>>;
};


export type QueryFindManyEmailCampaignCustomListRecipientCountArgs = {
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListRecipientOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignCustomListRecipientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListRecipientScalarFieldEnum>>>;
};


export type QueryAggregateEmailCampaignCustomListRecipientArgs = {
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListRecipientOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailCampaignCustomListRecipientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueVetsourceRevenueCategoryArgs = {
  where: VetsourceRevenueCategoryWhereUniqueInput;
};


export type QueryFindFirstVetsourceRevenueCategoryArgs = {
  where?: Maybe<VetsourceRevenueCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetsourceRevenueCategoryScalarFieldEnum>>>;
};


export type QueryFindManyVetsourceRevenueCategoryArgs = {
  where?: Maybe<VetsourceRevenueCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetsourceRevenueCategoryScalarFieldEnum>>>;
};


export type QueryFindManyVetsourceRevenueCategoryCountArgs = {
  where?: Maybe<VetsourceRevenueCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetsourceRevenueCategoryScalarFieldEnum>>>;
};


export type QueryAggregateVetsourceRevenueCategoryArgs = {
  where?: Maybe<VetsourceRevenueCategoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueVetsourceCodeTagArgs = {
  where: VetsourceCodeTagWhereUniqueInput;
};


export type QueryFindFirstVetsourceCodeTagArgs = {
  where?: Maybe<VetsourceCodeTagWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceCodeTagOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetsourceCodeTagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetsourceCodeTagScalarFieldEnum>>>;
};


export type QueryFindManyVetsourceCodeTagArgs = {
  where?: Maybe<VetsourceCodeTagWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceCodeTagOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetsourceCodeTagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetsourceCodeTagScalarFieldEnum>>>;
};


export type QueryFindManyVetsourceCodeTagCountArgs = {
  where?: Maybe<VetsourceCodeTagWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceCodeTagOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetsourceCodeTagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetsourceCodeTagScalarFieldEnum>>>;
};


export type QueryAggregateVetsourceCodeTagArgs = {
  where?: Maybe<VetsourceCodeTagWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceCodeTagOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetsourceCodeTagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInformControlGroupParticipantArgs = {
  where: InformControlGroupParticipantWhereUniqueInput;
};


export type QueryFindFirstInformControlGroupParticipantArgs = {
  where?: Maybe<InformControlGroupParticipantWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformControlGroupParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformControlGroupParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformControlGroupParticipantScalarFieldEnum>>>;
};


export type QueryFindManyInformControlGroupParticipantArgs = {
  where?: Maybe<InformControlGroupParticipantWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformControlGroupParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformControlGroupParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformControlGroupParticipantScalarFieldEnum>>>;
};


export type QueryFindManyInformControlGroupParticipantCountArgs = {
  where?: Maybe<InformControlGroupParticipantWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformControlGroupParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformControlGroupParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InformControlGroupParticipantScalarFieldEnum>>>;
};


export type QueryAggregateInformControlGroupParticipantArgs = {
  where?: Maybe<InformControlGroupParticipantWhereInput>;
  orderBy?: Maybe<Array<Maybe<InformControlGroupParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InformControlGroupParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryInvokeConverseAiRequestArgs = {
  data: InvokeAiConverseInput;
};


export type QueryGetEmailCampaignLoginUrlArgs = {
  data?: Maybe<ConfigureEmailCampaignsInput>;
};


export type QueryRenderWorkflowNotificationBodyArgs = {
  automationRunId: Scalars['String'];
};


export type QueryGetAvailableFormTemplatesForWorkflowsArgs = {
  where?: Maybe<FormTemplateWhereInput>;
};


export type QueryGetPossiblyDeflectedFormSubmissionArgs = {
  data?: Maybe<FormSubmissionWhereInput>;
};


export type QueryCheckClinicCareStatusArgs = {
  data: CheckClinicCareInput;
};


export type QueryExportCancellationSummaryAsPdfArgs = {
  data: ExportCancellationSummaryAsPdfInput;
};


export type QueryCalculatePetBenefitUsageArgs = {
  where?: Maybe<CalculatePetBenefitUsageInput>;
};


export type QueryCalculateMultiplePetsBenefitUsageArgs = {
  where?: Maybe<CalculateMultiplePetsBenefitUsageInput>;
};


export type QueryChannelExportArgs = {
  where: ChannelExportWhereInput;
};


export type QueryFormSubmissionExportArgs = {
  where: FormSubmissionWhereUniqueInput;
};


export type QueryAiChannelSuggestedMessageArgs = {
  where: ChannelSuggestedMessageWhereInput;
  alternatePrompt?: Maybe<Scalars['String']>;
  maxTokensToSample?: Maybe<Scalars['Int']>;
};


export type QuerySavedChannelFilterSelectionsArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetChannelStatusIdsWithEntityCountOfZeroArgs = {
  where: GetChannelStatusIdsWithEntityCountOfZeroWhere;
};


export type QueryFindClinicContactInfoArgs = {
  clinicId: Scalars['String'];
};


export type QueryFindClinicPetParentByPhoneOrEmailArgs = {
  where: FindClinicPetParentByPhoneOrEmailRequest;
};


export type QuerySearchPhoneNumberArgs = {
  data?: Maybe<SearchPhoneNumberInput>;
};


export type QueryWidgetClinicPetParentLookup2Args = {
  where: WidgetClinicPetParentLookupInput;
};


export type QueryWidgetClinicPetParentLookupEnrollmentArgs = {
  where: WidgetClinicPetParentLookupInput;
};


export type QueryGetOrCreateActiveChannelArgs = {
  data: GetOrCreateActiveChannelInput;
};


export type QueryGetDirectBookingAppointmentTypesArgs = {
  data: DirectBookingAppointmentTypeInput;
};


export type QueryGetDirectBookingOpenTimeSlots2Args = {
  data: DirectBookingOpenTimeSlot2Input;
};


export type QueryGetClinicDirectBookingSettingsArgs = {
  data?: Maybe<ClinicDirectBookingSettingsInput>;
};


export type QueryGetHasConflictingAppointmentArgs = {
  data: HasConflictingAppointmentInput;
};


export type QueryGetEmailCampaignSegmentCountArgs = {
  clinicId: Scalars['String'];
  emailCampaignId?: Maybe<Scalars['String']>;
};


export type QueryCanSendEmailCampaignArgs = {
  clinicId: Scalars['String'];
};


export type QueryGetEmailCampaignCustomListUrlArgs = {
  data: EmailCampaignCustomListUrlInput;
};


export type QueryFindCurrentEmailTemplateVersionsArgs = {
  data: FindCurrentEmailTemplateVersionInput;
};


export type QueryFindEmployerIdentificationNumberArgs = {
  data: FindEmployerIdentificationNumberInput;
};


export type QueryGetCurrentInformPartnerUserArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryGetInvoiceByIdArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryGetInvoicesForClientArgs = {
  where: InvoiceWhereInput;
  orderBy?: Maybe<Array<InvoiceOrderByWithRelationAndSearchRelevanceInput>>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryGetInvoicesForClientsArgs = {
  where: InvoiceWhereInput;
  orderBy?: Maybe<Array<InvoiceOrderByWithRelationAndSearchRelevanceInput>>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryExportItemizedInvoiceAsReceiptPdfArgs = {
  data: ExportItemizedInvoiceToReceiptPdfInput;
};


export type QueryJobChainStatusArgs = {
  where: JobChainStatusInput;
};


export type QueryEstimateNumberOfPetParentsReachedArgs = {
  clinicId: Scalars['String'];
  appointmentDateTimeFrom: Scalars['String'];
  appointmentDateTimeTo: Scalars['String'];
  alertBody: Scalars['String'];
};


export type QueryMassTextAlertReportArgs = {
  massTextAlertId: Scalars['String'];
};


export type QuerySendPetParentReceiptArgs = {
  data: SendPetParentReceiptInput;
};


export type QuerySendConsultationReceiptArgs = {
  data: SendConsultationReceiptInput;
};


export type QuerySendVetSubscriptionReceiptArgs = {
  data: SendVetSubscriptionReceiptInput;
};


export type QueryGetTerminalTokenArgs = {
  where: StripeTerminalLocationUniqueInput;
};


export type QueryStripeCreateCustomerPortalArgs = {
  data?: Maybe<StripeCustomerPortalInput>;
};


export type QueryGetPetIdDataArgs = {
  data: GetPetIdDataInput;
};


export type QueryGeneratePetIdPdfArgs = {
  data: GeneratePetIdPdfInput;
};


export type QueryGetQuickSightEmbedUrlArgs = {
  where: GetQuickSightEmbedUrlInput;
};


export type QueryClinicPetSearchArgs = {
  where?: Maybe<SearchClinicPetInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryClinicPetParentSearchArgs = {
  where?: Maybe<SearchClinicPetParentInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryChannelSearchArgs = {
  where?: Maybe<SearchChannelInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
};


export type QueryExportNonItemizedReceiptAsPdfArgs = {
  data: ExportNonItemizedReceiptAsPdfInput;
};


export type QueryGetStripePaymentIntentDataArgs = {
  where: StripePaymentIntentWhereUniqueInput;
};


export type QueryCanManuallyRetryPaymentWritebackArgs = {
  stripePaymentIntentId: Scalars['String'];
};


export type QueryStripeRetrievePetParentPortalUrlArgs = {
  data?: Maybe<StripeCustomerPortalInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};


export type QueryVerifyTerminalSetupPinArgs = {
  pin: Scalars['String'];
};


export type QueryTrupanionCertificateAvailabilityArgs = {
  where: TrupanionCertificateAvailabilityInput;
};


export type QueryTrupanionHospitalSearchArgs = {
  where: TrupanionHospitalSearchInput;
};


export type QueryCustomFindUniqueUserBoardFilterArgs = {
  where: CustomFindUniqueUserBoardFilterWhereInput;
};


export type QueryFindManyParentWorkflowEventSettingArgs = {
  workflowId: Scalars['String'];
};


export type QueryGetBalanceForClientArgs = {
  where: GetBalanceForClientInput;
};


export type QueryTokenValidationArgs = {
  data: TokenValidationInput;
};


export type QueryGetTwilioNumbersArgs = {
  data?: Maybe<GetTwilioNumbersInput>;
};


export type QuerySearchTwilioNumbersArgs = {
  data: SearchTwilioNumbersInput;
};


export type QueryTwilioCapabilityTokenArgs = {
  type?: Maybe<TwilioCapabilityType>;
};


export type QueryVerificationRequestArgs = {
  data?: Maybe<VerificationRequestInput>;
};


export type QueryVerificationCheckArgs = {
  data?: Maybe<VerificationCheckInput>;
};


export type QueryCheckPhoneVerificationArgs = {
  data: CheckPhoneVerificationInput;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum RecordingControlStatus {
  Paused = 'Paused',
  InProgress = 'InProgress',
  Stopped = 'Stopped'
}

export enum RecordingDownloadStatus {
  NotStarted = 'NotStarted',
  Pending = 'Pending',
  InProgress = 'InProgress',
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed'
}

export type RedeemOneTimeCodeInput = {
  code: Scalars['String'];
  trusted: Scalars['Boolean'];
};

export type RedeemOneTimeCodeResult = {
  __typename?: 'RedeemOneTimeCodeResult';
  success: Scalars['Boolean'];
  token: Scalars['String'];
};

export enum RefundReason {
  RequestedByCustomer = 'Requested_By_Customer',
  Fraudulent = 'Fraudulent',
  Duplicate = 'Duplicate',
  PartialRefund = 'Partial_Refund',
  Other = 'Other'
}

export type RemoveUserFromClinicInput = {
  userId: Scalars['String'];
  clinicId: Scalars['String'];
};

export enum RenewalRejectionReason {
  OptOut = 'OptOut',
  DeceasedPet = 'DeceasedPet',
  PaymentsMissing = 'PaymentsMissing'
}

export type RequestBitwerxIntegrationInput = {
  clinicContactEmail: Scalars['String'];
  forPayments?: Maybe<Scalars['Boolean']>;
};

export type RequestLoyaltyRewardInput = {
  loyaltyAccountId: Scalars['String'];
  loyaltyRewardId: Scalars['String'];
};

export type RequestOneTimeCodeInput = {
  method: RequestOneTimeCodeMethod;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
};

export enum RequestOneTimeCodeMethod {
  Email = 'EMAIL',
  Text = 'TEXT'
}

export type RequestOneTimeCodeResult = {
  __typename?: 'RequestOneTimeCodeResult';
  success: Scalars['Boolean'];
};

export type ResendReceiptEmailOutput = {
  __typename?: 'ResendReceiptEmailOutput';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  jobChainId?: Maybe<Scalars['String']>;
};

export type ResendUserInvitationInput = {
  email: Scalars['String'];
};

export type RetryPaymentWritebackOutput = {
  __typename?: 'RetryPaymentWritebackOutput';
  success: Scalars['Boolean'];
  jobChainId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

/** @deprecated - 1.0 */
export type Review = {
  __typename?: 'Review';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  reviewTags: Array<ReviewTag>;
  _count: ReviewCountOutputType;
};


/** @deprecated - 1.0 */
export type ReviewReviewTagsArgs = {
  where?: Maybe<ReviewTagWhereInput>;
  orderBy?: Maybe<ReviewTagOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ReviewTagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ReviewTagScalarFieldEnum>;
};

export type ReviewAvgAggregateOutputType = {
  __typename?: 'ReviewAvgAggregateOutputType';
  rating?: Maybe<Scalars['Float']>;
  ratingScale?: Maybe<Scalars['Float']>;
};

export type ReviewAvgOrderByAggregateInput = {
  rating?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
};

export type ReviewCountAggregateOutputType = {
  __typename?: 'ReviewCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  rating: Scalars['Int'];
  ratingDisplay: Scalars['Int'];
  ratingScale: Scalars['Int'];
  relationshipId: Scalars['Int'];
  reviewType: Scalars['Int'];
  feedbackMessage: Scalars['Int'];
  userId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ReviewCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewType?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ReviewCountOutputType = {
  __typename?: 'ReviewCountOutputType';
  reviewTags: Scalars['Int'];
};

export type ReviewCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  user?: Maybe<UserCreateNestedOneWithoutReviewsInput>;
  reviewTags?: Maybe<ReviewTagCreateNestedManyWithoutReviewsInput>;
};

export type ReviewCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
};

export type ReviewCreateManyUserInputEnvelope = {
  data: Array<ReviewCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ReviewCreateNestedManyWithoutReviewTagsInput = {
  create?: Maybe<Array<ReviewCreateWithoutReviewTagsInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutReviewTagsInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
};

export type ReviewCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ReviewCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
};

export type ReviewCreateOrConnectWithoutReviewTagsInput = {
  where: ReviewWhereUniqueInput;
  create: ReviewUncheckedCreateWithoutReviewTagsInput;
};

export type ReviewCreateOrConnectWithoutUserInput = {
  where: ReviewWhereUniqueInput;
  create: ReviewUncheckedCreateWithoutUserInput;
};

export type ReviewCreateWithoutReviewTagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  user?: Maybe<UserCreateNestedOneWithoutReviewsInput>;
};

export type ReviewCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagCreateNestedManyWithoutReviewsInput>;
};

export type ReviewListRelationFilter = {
  every?: Maybe<ReviewWhereInput>;
  some?: Maybe<ReviewWhereInput>;
  none?: Maybe<ReviewWhereInput>;
};

export type ReviewMaxAggregateOutputType = {
  __typename?: 'ReviewMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewType?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ReviewMinAggregateOutputType = {
  __typename?: 'ReviewMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewType?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ReviewOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ReviewOrderByRelevanceFieldEnum {
  Id = 'id',
  RatingDisplay = 'ratingDisplay',
  RelationshipId = 'relationshipId',
  ReviewType = 'reviewType',
  FeedbackMessage = 'feedbackMessage',
  UserId = 'userId'
}

export type ReviewOrderByRelevanceInput = {
  fields: Array<ReviewOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ReviewOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewType?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  _count?: Maybe<ReviewCountOrderByAggregateInput>;
  _avg?: Maybe<ReviewAvgOrderByAggregateInput>;
  _max?: Maybe<ReviewMaxOrderByAggregateInput>;
  _min?: Maybe<ReviewMinOrderByAggregateInput>;
  _sum?: Maybe<ReviewSumOrderByAggregateInput>;
};

export type ReviewOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewType?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  reviewTags?: Maybe<ReviewTagOrderByRelationAggregateInput>;
  _relevance?: Maybe<ReviewOrderByRelevanceInput>;
};

export enum ReviewScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Rating = 'rating',
  RatingDisplay = 'ratingDisplay',
  RatingScale = 'ratingScale',
  RelationshipId = 'relationshipId',
  ReviewType = 'reviewType',
  FeedbackMessage = 'feedbackMessage',
  UserId = 'userId'
}

export type ReviewScalarWhereInput = {
  AND?: Maybe<Array<ReviewScalarWhereInput>>;
  OR?: Maybe<Array<ReviewScalarWhereInput>>;
  NOT?: Maybe<Array<ReviewScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  rating?: Maybe<IntFilter>;
  ratingDisplay?: Maybe<StringFilter>;
  ratingScale?: Maybe<IntFilter>;
  relationshipId?: Maybe<StringNullableFilter>;
  reviewType?: Maybe<StringFilter>;
  feedbackMessage?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type ReviewScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ReviewScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ReviewScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ReviewScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  rating?: Maybe<IntWithAggregatesFilter>;
  ratingDisplay?: Maybe<StringWithAggregatesFilter>;
  ratingScale?: Maybe<IntWithAggregatesFilter>;
  relationshipId?: Maybe<StringNullableWithAggregatesFilter>;
  reviewType?: Maybe<StringWithAggregatesFilter>;
  feedbackMessage?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ReviewSumAggregateOutputType = {
  __typename?: 'ReviewSumAggregateOutputType';
  rating?: Maybe<Scalars['Int']>;
  ratingScale?: Maybe<Scalars['Int']>;
};

export type ReviewSumOrderByAggregateInput = {
  rating?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
};

/** @deprecated - 1.0 */
export type ReviewTag = {
  __typename?: 'ReviewTag';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  reviews: Array<Review>;
  _count: ReviewTagCountOutputType;
};


/** @deprecated - 1.0 */
export type ReviewTagReviewsArgs = {
  where?: Maybe<ReviewWhereInput>;
  orderBy?: Maybe<ReviewOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ReviewWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ReviewScalarFieldEnum>;
};

export type ReviewTagCountAggregateOutputType = {
  __typename?: 'ReviewTagCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isActive: Scalars['Int'];
  name: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ReviewTagCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type ReviewTagCountOutputType = {
  __typename?: 'ReviewTagCountOutputType';
  reviews: Scalars['Int'];
};

export type ReviewTagCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  reviews?: Maybe<ReviewCreateNestedManyWithoutReviewTagsInput>;
};

export type ReviewTagCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type ReviewTagCreateNestedManyWithoutReviewsInput = {
  create?: Maybe<Array<ReviewTagCreateWithoutReviewsInput>>;
  connectOrCreate?: Maybe<Array<ReviewTagCreateOrConnectWithoutReviewsInput>>;
  connect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
};

export type ReviewTagCreateOrConnectWithoutReviewsInput = {
  where: ReviewTagWhereUniqueInput;
  create: ReviewTagUncheckedCreateWithoutReviewsInput;
};

export type ReviewTagCreateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type ReviewTagListRelationFilter = {
  every?: Maybe<ReviewTagWhereInput>;
  some?: Maybe<ReviewTagWhereInput>;
  none?: Maybe<ReviewTagWhereInput>;
};

export type ReviewTagMaxAggregateOutputType = {
  __typename?: 'ReviewTagMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewTagMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type ReviewTagMinAggregateOutputType = {
  __typename?: 'ReviewTagMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewTagMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type ReviewTagOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ReviewTagOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name'
}

export type ReviewTagOrderByRelevanceInput = {
  fields: Array<ReviewTagOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ReviewTagOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  _count?: Maybe<ReviewTagCountOrderByAggregateInput>;
  _max?: Maybe<ReviewTagMaxOrderByAggregateInput>;
  _min?: Maybe<ReviewTagMinOrderByAggregateInput>;
};

export type ReviewTagOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  reviews?: Maybe<ReviewOrderByRelationAggregateInput>;
  _relevance?: Maybe<ReviewTagOrderByRelevanceInput>;
};

export enum ReviewTagScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsActive = 'isActive',
  Name = 'name'
}

export type ReviewTagScalarWhereInput = {
  AND?: Maybe<Array<ReviewTagScalarWhereInput>>;
  OR?: Maybe<Array<ReviewTagScalarWhereInput>>;
  NOT?: Maybe<Array<ReviewTagScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
};

export type ReviewTagScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ReviewTagScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ReviewTagScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ReviewTagScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
};

export type ReviewTagUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutReviewTagsInput>;
};

export type ReviewTagUncheckedCreateNestedManyWithoutReviewsInput = {
  create?: Maybe<Array<ReviewTagCreateWithoutReviewsInput>>;
  connectOrCreate?: Maybe<Array<ReviewTagCreateOrConnectWithoutReviewsInput>>;
  connect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
};

export type ReviewTagUncheckedCreateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type ReviewTagUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutReviewTagsNestedInput>;
};

export type ReviewTagUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewTagUncheckedUpdateManyWithoutReviewTagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewTagUncheckedUpdateManyWithoutReviewsNestedInput = {
  create?: Maybe<Array<ReviewTagCreateWithoutReviewsInput>>;
  connectOrCreate?: Maybe<Array<ReviewTagCreateOrConnectWithoutReviewsInput>>;
  upsert?: Maybe<Array<ReviewTagUpsertWithWhereUniqueWithoutReviewsInput>>;
  set?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  delete?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  connect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  update?: Maybe<Array<ReviewTagUpdateWithWhereUniqueWithoutReviewsInput>>;
  updateMany?: Maybe<Array<ReviewTagUpdateManyWithWhereWithoutReviewsInput>>;
  deleteMany?: Maybe<Array<ReviewTagScalarWhereInput>>;
};

export type ReviewTagUncheckedUpdateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewTagUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  reviews?: Maybe<ReviewUpdateManyWithoutReviewTagsNestedInput>;
};

export type ReviewTagUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewTagUpdateManyWithWhereWithoutReviewsInput = {
  where: ReviewTagScalarWhereInput;
  data: ReviewTagUncheckedUpdateManyWithoutReviewTagsInput;
};

export type ReviewTagUpdateManyWithoutReviewsNestedInput = {
  create?: Maybe<Array<ReviewTagCreateWithoutReviewsInput>>;
  connectOrCreate?: Maybe<Array<ReviewTagCreateOrConnectWithoutReviewsInput>>;
  upsert?: Maybe<Array<ReviewTagUpsertWithWhereUniqueWithoutReviewsInput>>;
  set?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  delete?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  connect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  update?: Maybe<Array<ReviewTagUpdateWithWhereUniqueWithoutReviewsInput>>;
  updateMany?: Maybe<Array<ReviewTagUpdateManyWithWhereWithoutReviewsInput>>;
  deleteMany?: Maybe<Array<ReviewTagScalarWhereInput>>;
};

export type ReviewTagUpdateWithWhereUniqueWithoutReviewsInput = {
  where: ReviewTagWhereUniqueInput;
  data: ReviewTagUncheckedUpdateWithoutReviewsInput;
};

export type ReviewTagUpdateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewTagUpsertWithWhereUniqueWithoutReviewsInput = {
  where: ReviewTagWhereUniqueInput;
  update: ReviewTagUncheckedUpdateWithoutReviewsInput;
  create: ReviewTagUncheckedCreateWithoutReviewsInput;
};

export type ReviewTagWhereInput = {
  AND?: Maybe<Array<ReviewTagWhereInput>>;
  OR?: Maybe<Array<ReviewTagWhereInput>>;
  NOT?: Maybe<Array<ReviewTagWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  reviews?: Maybe<ReviewListRelationFilter>;
};

export type ReviewTagWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUncheckedCreateNestedManyWithoutReviewsInput>;
};

export type ReviewUncheckedCreateNestedManyWithoutReviewTagsInput = {
  create?: Maybe<Array<ReviewCreateWithoutReviewTagsInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutReviewTagsInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
};

export type ReviewUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ReviewCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
};

export type ReviewUncheckedCreateWithoutReviewTagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUncheckedCreateNestedManyWithoutReviewsInput>;
};

export type ReviewUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUncheckedUpdateManyWithoutReviewsNestedInput>;
};

export type ReviewUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedUpdateManyWithoutReviewTagsNestedInput = {
  create?: Maybe<Array<ReviewCreateWithoutReviewTagsInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutReviewTagsInput>>;
  upsert?: Maybe<Array<ReviewUpsertWithWhereUniqueWithoutReviewTagsInput>>;
  set?: Maybe<Array<ReviewWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReviewWhereUniqueInput>>;
  delete?: Maybe<Array<ReviewWhereUniqueInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  update?: Maybe<Array<ReviewUpdateWithWhereUniqueWithoutReviewTagsInput>>;
  updateMany?: Maybe<Array<ReviewUpdateManyWithWhereWithoutReviewTagsInput>>;
  deleteMany?: Maybe<Array<ReviewScalarWhereInput>>;
};

export type ReviewUncheckedUpdateManyWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ReviewUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ReviewCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ReviewWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReviewWhereUniqueInput>>;
  delete?: Maybe<Array<ReviewWhereUniqueInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  update?: Maybe<Array<ReviewUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ReviewUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ReviewScalarWhereInput>>;
};

export type ReviewUncheckedUpdateWithoutReviewTagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUncheckedUpdateManyWithoutReviewsNestedInput>;
};

export type ReviewUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  user?: Maybe<UserUpdateOneWithoutReviewsNestedInput>;
  reviewTags?: Maybe<ReviewTagUpdateManyWithoutReviewsNestedInput>;
};

export type ReviewUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
};

export type ReviewUpdateManyWithWhereWithoutReviewTagsInput = {
  where: ReviewScalarWhereInput;
  data: ReviewUncheckedUpdateManyWithoutReviewsInput;
};

export type ReviewUpdateManyWithWhereWithoutUserInput = {
  where: ReviewScalarWhereInput;
  data: ReviewUncheckedUpdateManyWithoutReviewsInput;
};

export type ReviewUpdateManyWithoutReviewTagsNestedInput = {
  create?: Maybe<Array<ReviewCreateWithoutReviewTagsInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutReviewTagsInput>>;
  upsert?: Maybe<Array<ReviewUpsertWithWhereUniqueWithoutReviewTagsInput>>;
  set?: Maybe<Array<ReviewWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReviewWhereUniqueInput>>;
  delete?: Maybe<Array<ReviewWhereUniqueInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  update?: Maybe<Array<ReviewUpdateWithWhereUniqueWithoutReviewTagsInput>>;
  updateMany?: Maybe<Array<ReviewUpdateManyWithWhereWithoutReviewTagsInput>>;
  deleteMany?: Maybe<Array<ReviewScalarWhereInput>>;
};

export type ReviewUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ReviewUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ReviewCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ReviewWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReviewWhereUniqueInput>>;
  delete?: Maybe<Array<ReviewWhereUniqueInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  update?: Maybe<Array<ReviewUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ReviewUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ReviewScalarWhereInput>>;
};

export type ReviewUpdateWithWhereUniqueWithoutReviewTagsInput = {
  where: ReviewWhereUniqueInput;
  data: ReviewUncheckedUpdateWithoutReviewTagsInput;
};

export type ReviewUpdateWithWhereUniqueWithoutUserInput = {
  where: ReviewWhereUniqueInput;
  data: ReviewUncheckedUpdateWithoutUserInput;
};

export type ReviewUpdateWithoutReviewTagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  user?: Maybe<UserUpdateOneWithoutReviewsNestedInput>;
};

export type ReviewUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUpdateManyWithoutReviewsNestedInput>;
};

export type ReviewUpsertWithWhereUniqueWithoutReviewTagsInput = {
  where: ReviewWhereUniqueInput;
  update: ReviewUncheckedUpdateWithoutReviewTagsInput;
  create: ReviewUncheckedCreateWithoutReviewTagsInput;
};

export type ReviewUpsertWithWhereUniqueWithoutUserInput = {
  where: ReviewWhereUniqueInput;
  update: ReviewUncheckedUpdateWithoutUserInput;
  create: ReviewUncheckedCreateWithoutUserInput;
};

export type ReviewWhereInput = {
  AND?: Maybe<Array<ReviewWhereInput>>;
  OR?: Maybe<Array<ReviewWhereInput>>;
  NOT?: Maybe<Array<ReviewWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  rating?: Maybe<IntFilter>;
  ratingDisplay?: Maybe<StringFilter>;
  ratingScale?: Maybe<IntFilter>;
  relationshipId?: Maybe<StringNullableFilter>;
  reviewType?: Maybe<StringFilter>;
  feedbackMessage?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  user?: Maybe<UserWhereInput>;
  reviewTags?: Maybe<ReviewTagListRelationFilter>;
};

export type ReviewWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum Role {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Vet = 'VET',
  Head = 'HEAD',
  Rec = 'REC',
  Pm = 'PM',
  Nurse = 'NURSE',
  Technician = 'TECHNICIAN'
}

export enum RuleActions {
  UpdateInactiveChannelStatus = 'UpdateInactiveChannelStatus'
}

export type Rules = {
  __typename?: 'Rules';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  action: RuleActions;
  options: Scalars['Json'];
  clinicId: Scalars['String'];
  clinic: Clinic;
};

export type RulesCountAggregateOutputType = {
  __typename?: 'RulesCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  action: Scalars['Int'];
  options: Scalars['Int'];
  clinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type RulesCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  options?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type RulesCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutRulesInput;
};

export type RulesCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
};

export type RulesCreateManyClinicInputEnvelope = {
  data: Array<RulesCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RulesCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
  clinicId: Scalars['String'];
};

export type RulesCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<RulesCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<RulesCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<RulesCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<RulesWhereUniqueInput>>;
};

export type RulesCreateOrConnectWithoutClinicInput = {
  where: RulesWhereUniqueInput;
  create: RulesUncheckedCreateWithoutClinicInput;
};

export type RulesCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
};

export type RulesListRelationFilter = {
  every?: Maybe<RulesWhereInput>;
  some?: Maybe<RulesWhereInput>;
  none?: Maybe<RulesWhereInput>;
};

export type RulesMaxAggregateOutputType = {
  __typename?: 'RulesMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  clinicId?: Maybe<Scalars['String']>;
};

export type RulesMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type RulesMinAggregateOutputType = {
  __typename?: 'RulesMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  clinicId?: Maybe<Scalars['String']>;
};

export type RulesMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type RulesOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum RulesOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId'
}

export type RulesOrderByRelevanceInput = {
  fields: Array<RulesOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type RulesOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  options?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  _count?: Maybe<RulesCountOrderByAggregateInput>;
  _max?: Maybe<RulesMaxOrderByAggregateInput>;
  _min?: Maybe<RulesMinOrderByAggregateInput>;
};

export type RulesOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  options?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<RulesOrderByRelevanceInput>;
};

export enum RulesScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Action = 'action',
  Options = 'options',
  ClinicId = 'clinicId'
}

export type RulesScalarWhereInput = {
  AND?: Maybe<Array<RulesScalarWhereInput>>;
  OR?: Maybe<Array<RulesScalarWhereInput>>;
  NOT?: Maybe<Array<RulesScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  action?: Maybe<EnumRuleActionsFilter>;
  options?: Maybe<JsonFilter>;
  clinicId?: Maybe<StringFilter>;
};

export type RulesScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<RulesScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<RulesScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<RulesScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  action?: Maybe<EnumRuleActionsWithAggregatesFilter>;
  options?: Maybe<JsonWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
};

export type RulesUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
  clinicId: Scalars['String'];
};

export type RulesUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<RulesCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<RulesCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<RulesCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<RulesWhereUniqueInput>>;
};

export type RulesUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
};

export type RulesUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type RulesUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type RulesUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<RulesCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<RulesCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<RulesUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<RulesCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<RulesWhereUniqueInput>>;
  disconnect?: Maybe<Array<RulesWhereUniqueInput>>;
  delete?: Maybe<Array<RulesWhereUniqueInput>>;
  connect?: Maybe<Array<RulesWhereUniqueInput>>;
  update?: Maybe<Array<RulesUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<RulesUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<RulesScalarWhereInput>>;
};

export type RulesUncheckedUpdateManyWithoutRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
};

export type RulesUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
};

export type RulesUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutRulesNestedInput>;
};

export type RulesUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
};

export type RulesUpdateManyWithWhereWithoutClinicInput = {
  where: RulesScalarWhereInput;
  data: RulesUncheckedUpdateManyWithoutRulesInput;
};

export type RulesUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<RulesCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<RulesCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<RulesUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<RulesCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<RulesWhereUniqueInput>>;
  disconnect?: Maybe<Array<RulesWhereUniqueInput>>;
  delete?: Maybe<Array<RulesWhereUniqueInput>>;
  connect?: Maybe<Array<RulesWhereUniqueInput>>;
  update?: Maybe<Array<RulesUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<RulesUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<RulesScalarWhereInput>>;
};

export type RulesUpdateWithWhereUniqueWithoutClinicInput = {
  where: RulesWhereUniqueInput;
  data: RulesUncheckedUpdateWithoutClinicInput;
};

export type RulesUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
};

export type RulesUpsertWithWhereUniqueWithoutClinicInput = {
  where: RulesWhereUniqueInput;
  update: RulesUncheckedUpdateWithoutClinicInput;
  create: RulesUncheckedCreateWithoutClinicInput;
};

export type RulesWhereInput = {
  AND?: Maybe<Array<RulesWhereInput>>;
  OR?: Maybe<Array<RulesWhereInput>>;
  NOT?: Maybe<Array<RulesWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  action?: Maybe<EnumRuleActionsFilter>;
  options?: Maybe<JsonFilter>;
  clinicId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type RulesWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SaveEmailTemplateVersionInput = {
  emailTemplateVersionId: Scalars['String'];
  html: Scalars['String'];
  design: Scalars['Json'];
  shouldPublish?: Maybe<Scalars['Boolean']>;
};

export type SearchChannelInput = {
  id?: Maybe<Scalars['String']>;
  idIn?: Maybe<Array<Scalars['String']>>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isUnassigned?: Maybe<Scalars['Boolean']>;
  petNames?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  petParentNames?: Maybe<Scalars['String']>;
  userNames?: Maybe<Scalars['String']>;
  assigneeIds?: Maybe<Array<Scalars['String']>>;
  channelPinUserIds?: Maybe<Array<Scalars['String']>>;
  excludedChannelPinUserIds?: Maybe<Array<Scalars['String']>>;
  assigneeNames?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  messageBodies?: Maybe<Scalars['String']>;
  channelStatusIds?: Maybe<Array<Scalars['String']>>;
  channelStatusName?: Maybe<Scalars['String']>;
  channelStatusAction?: Maybe<Scalars['String']>;
  channelType?: Maybe<Scalars['String']>;
  appointmentTypeIds?: Maybe<Array<Scalars['String']>>;
  appointmentRoomIds?: Maybe<Array<Scalars['String']>>;
  appointmentClinicEmployeeIds?: Maybe<Array<Scalars['String']>>;
  tagNames?: Maybe<Array<Scalars['String']>>;
  and?: Maybe<Array<Maybe<SearchChannelInput>>>;
  or?: Maybe<Array<Maybe<SearchChannelInput>>>;
};

export type SearchClinicPetInput = {
  name?: Maybe<Scalars['String']>;
  petParentNames?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type SearchClinicPetParentInput = {
  fullName?: Maybe<Scalars['String']>;
  petNames?: Maybe<Scalars['String']>;
  petPimsIds?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  and?: Maybe<Array<Maybe<SearchClinicPetParentInput>>>;
  or?: Maybe<Array<Maybe<SearchClinicPetParentInput>>>;
};

export type SearchPhoneNumberInput = {
  code: Scalars['String'];
  quantity: Scalars['String'];
};

export type SearchPhoneNumberResponse = {
  __typename?: 'SearchPhoneNumberResponse';
  total?: Maybe<Scalars['Int']>;
  phoneNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SearchTwilioNumbersInput = {
  areaCode?: Maybe<Scalars['String']>;
};

export type Segment = {
  __typename?: 'Segment';
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sortingOrder: Scalars['Int'];
  type: SegmentType;
  model: SegmentModelType;
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  definitionVariables?: Maybe<Scalars['Json']>;
};

export type SegmentAvgAggregateOutputType = {
  __typename?: 'SegmentAvgAggregateOutputType';
  sortingOrder?: Maybe<Scalars['Float']>;
};

export type SegmentAvgOrderByAggregateInput = {
  sortingOrder?: Maybe<SortOrder>;
};

export type SegmentBuilderDefinitionVariablesInput = {
  staticVariables: Scalars['Json'];
};

export type SegmentCountAggregateOutputType = {
  __typename?: 'SegmentCountAggregateOutputType';
  id: Scalars['Int'];
  name: Scalars['Int'];
  description: Scalars['Int'];
  sortingOrder: Scalars['Int'];
  type: Scalars['Int'];
  model: Scalars['Int'];
  prismaDefinition: Scalars['Int'];
  rawSqlDefinition: Scalars['Int'];
  definitionVariables: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SegmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  sortingOrder?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  prismaDefinition?: Maybe<SortOrder>;
  rawSqlDefinition?: Maybe<SortOrder>;
  definitionVariables?: Maybe<SortOrder>;
};

export type SegmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sortingOrder: Scalars['Int'];
  type: SegmentType;
  model: SegmentModelType;
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  definitionVariables?: Maybe<Scalars['Json']>;
};

export type SegmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sortingOrder: Scalars['Int'];
  type: SegmentType;
  model: SegmentModelType;
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  definitionVariables?: Maybe<Scalars['Json']>;
};

export type SegmentMaxAggregateOutputType = {
  __typename?: 'SegmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<SegmentType>;
  model?: Maybe<SegmentModelType>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
};

export type SegmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  sortingOrder?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  rawSqlDefinition?: Maybe<SortOrder>;
};

export type SegmentMinAggregateOutputType = {
  __typename?: 'SegmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<SegmentType>;
  model?: Maybe<SegmentModelType>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
};

export type SegmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  sortingOrder?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  rawSqlDefinition?: Maybe<SortOrder>;
};

export enum SegmentModelType {
  ClinicPetParent = 'ClinicPetParent'
}

export enum SegmentOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  RawSqlDefinition = 'rawSqlDefinition'
}

export type SegmentOrderByRelevanceInput = {
  fields: Array<SegmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type SegmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  sortingOrder?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  prismaDefinition?: Maybe<SortOrder>;
  rawSqlDefinition?: Maybe<SortOrder>;
  definitionVariables?: Maybe<SortOrder>;
  _count?: Maybe<SegmentCountOrderByAggregateInput>;
  _avg?: Maybe<SegmentAvgOrderByAggregateInput>;
  _max?: Maybe<SegmentMaxOrderByAggregateInput>;
  _min?: Maybe<SegmentMinOrderByAggregateInput>;
  _sum?: Maybe<SegmentSumOrderByAggregateInput>;
};

export type SegmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  sortingOrder?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  prismaDefinition?: Maybe<SortOrder>;
  rawSqlDefinition?: Maybe<SortOrder>;
  definitionVariables?: Maybe<SortOrder>;
  _relevance?: Maybe<SegmentOrderByRelevanceInput>;
};

export enum SegmentScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  SortingOrder = 'sortingOrder',
  Type = 'type',
  Model = 'model',
  PrismaDefinition = 'prismaDefinition',
  RawSqlDefinition = 'rawSqlDefinition',
  DefinitionVariables = 'definitionVariables'
}

export type SegmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SegmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SegmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SegmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  sortingOrder?: Maybe<IntWithAggregatesFilter>;
  type?: Maybe<EnumSegmentTypeWithAggregatesFilter>;
  model?: Maybe<EnumSegmentModelTypeWithAggregatesFilter>;
  prismaDefinition?: Maybe<JsonNullableWithAggregatesFilter>;
  rawSqlDefinition?: Maybe<StringNullableWithAggregatesFilter>;
  definitionVariables?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type SegmentSumAggregateOutputType = {
  __typename?: 'SegmentSumAggregateOutputType';
  sortingOrder?: Maybe<Scalars['Int']>;
};

export type SegmentSumOrderByAggregateInput = {
  sortingOrder?: Maybe<SortOrder>;
};

export enum SegmentType {
  System = 'System',
  Clinic = 'Clinic',
  SystemRawSql = 'SystemRawSql',
  ClinicRawSql = 'ClinicRawSql'
}

export type SegmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sortingOrder: Scalars['Int'];
  type: SegmentType;
  model: SegmentModelType;
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  definitionVariables?: Maybe<Scalars['Json']>;
};

export type SegmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<SegmentType>;
  model?: Maybe<SegmentModelType>;
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  definitionVariables?: Maybe<Scalars['Json']>;
};

export type SegmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<SegmentType>;
  model?: Maybe<SegmentModelType>;
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  definitionVariables?: Maybe<Scalars['Json']>;
};

export type SegmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<SegmentType>;
  model?: Maybe<SegmentModelType>;
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  definitionVariables?: Maybe<Scalars['Json']>;
};

export type SegmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<SegmentType>;
  model?: Maybe<SegmentModelType>;
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  definitionVariables?: Maybe<Scalars['Json']>;
};

export type SegmentWhereInput = {
  AND?: Maybe<Array<SegmentWhereInput>>;
  OR?: Maybe<Array<SegmentWhereInput>>;
  NOT?: Maybe<Array<SegmentWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  sortingOrder?: Maybe<IntFilter>;
  type?: Maybe<EnumSegmentTypeFilter>;
  model?: Maybe<EnumSegmentModelTypeFilter>;
  prismaDefinition?: Maybe<JsonNullableFilter>;
  rawSqlDefinition?: Maybe<StringNullableFilter>;
  definitionVariables?: Maybe<JsonNullableFilter>;
};

export type SegmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SegmentsArgsInput = {
  conditionalSegments?: Maybe<Array<Maybe<ConditionalSegmentsInput>>>;
};

export type SendCareSignUpEmailInput = {
  clinicPetParentId: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type SendCareSignUpEmailOutput = {
  __typename?: 'SendCareSignUpEmailOutput';
  success: Scalars['Boolean'];
};

export type SendCareSignUpEmailResult = {
  __typename?: 'SendCareSignUpEmailResult';
  success: Scalars['Boolean'];
};

export type SendClinicPetParentAuthCodeRequest = {
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['Json']>;
};

export type SendClinicPetParentAuthCodeResponse = {
  __typename?: 'SendClinicPetParentAuthCodeResponse';
  success: Scalars['Boolean'];
  message: Scalars['String'];
  requestId?: Maybe<Scalars['String']>;
};

export type SendClinicPetParentAuthLinkRequest = {
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isDeepLink?: Maybe<Scalars['Boolean']>;
  queryParams?: Maybe<Scalars['Json']>;
};

export type SendConsultationReceiptInput = {
  consultationFirebaseId: Scalars['String'];
  emails: Array<Scalars['String']>;
  consultationId: Scalars['String'];
  firebaseId: Scalars['String'];
};

export type SendEnrollmentEmailInput = {
  careStripeSubscriptionId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type SendEnrollmentEmailOutput = {
  __typename?: 'SendEnrollmentEmailOutput';
  success: Scalars['Boolean'];
};

export type SendPaymentToTerminalResponse = {
  __typename?: 'SendPaymentToTerminalResponse';
  terminal: StripeTerminal;
  success: Scalars['Boolean'];
};

export type SendPetParentReceiptInput = {
  consultationId: Scalars['String'];
  firebaseId: Scalars['String'];
};

export type SendWidgetCodeToDeveloperInput = {
  emailAddress: Scalars['String'];
  ccEmailAddresses?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type SentTrupanionExamDayOffer = {
  __typename?: 'SentTrupanionExamDayOffer';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  trupanionIntegrationId: Scalars['String'];
  clinicId: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicPetId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  trupanionIntegration: TrupanionIntegration;
  clinic: Clinic;
  clinicUser: User;
  clinicPetParent: ClinicPetParent;
  clinicPet: ClinicPet;
  appointmentType: AppointmentType;
};

export type SentTrupanionExamDayOfferCountAggregateOutputType = {
  __typename?: 'SentTrupanionExamDayOfferCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  trupanionIntegrationId: Scalars['Int'];
  clinicId: Scalars['Int'];
  sentByClinicUserId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  referenceNumber: Scalars['Int'];
  activationDate: Scalars['Int'];
  expirationDate: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SentTrupanionExamDayOfferCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  sentByClinicUserId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  referenceNumber?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
};

export type SentTrupanionExamDayOfferCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinic: ClinicCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicUser: UserCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicPet: ClinicPetCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  appointmentType: AppointmentTypeCreateNestedOneWithoutRelatedTrupanionExamDayOffersInput;
};

export type SentTrupanionExamDayOfferCreateManyAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
  clinicId: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicPetId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyAppointmentTypeInputEnvelope = {
  data: Array<SentTrupanionExamDayOfferCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicPetId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicInputEnvelope = {
  data: Array<SentTrupanionExamDayOfferCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
  clinicId: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicPetInputEnvelope = {
  data: Array<SentTrupanionExamDayOfferCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
  clinicId: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  clinicPetId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicPetParentInputEnvelope = {
  data: Array<SentTrupanionExamDayOfferCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicPetId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicUserInputEnvelope = {
  data: Array<SentTrupanionExamDayOfferCreateManyClinicUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SentTrupanionExamDayOfferCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
  clinicId: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicPetId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyTrupanionIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicPetId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyTrupanionIntegrationInputEnvelope = {
  data: Array<SentTrupanionExamDayOfferCreateManyTrupanionIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
};

export type SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
};

export type SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
};

export type SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
};

export type SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicUserInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicUserInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicUserInputEnvelope>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
};

export type SentTrupanionExamDayOfferCreateNestedManyWithoutTrupanionIntegrationInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutTrupanionIntegrationInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyTrupanionIntegrationInputEnvelope>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
};

export type SentTrupanionExamDayOfferCreateOrConnectWithoutAppointmentTypeInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutAppointmentTypeInput;
};

export type SentTrupanionExamDayOfferCreateOrConnectWithoutClinicInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicInput;
};

export type SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicPetInput;
};

export type SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetParentInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicPetParentInput;
};

export type SentTrupanionExamDayOfferCreateOrConnectWithoutClinicUserInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicUserInput;
};

export type SentTrupanionExamDayOfferCreateOrConnectWithoutTrupanionIntegrationInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutTrupanionIntegrationInput;
};

export type SentTrupanionExamDayOfferCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinic: ClinicCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicUser: UserCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicPet: ClinicPetCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
};

export type SentTrupanionExamDayOfferCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicUser: UserCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicPet: ClinicPetCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  appointmentType: AppointmentTypeCreateNestedOneWithoutRelatedTrupanionExamDayOffersInput;
};

export type SentTrupanionExamDayOfferCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinic: ClinicCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicUser: UserCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  appointmentType: AppointmentTypeCreateNestedOneWithoutRelatedTrupanionExamDayOffersInput;
};

export type SentTrupanionExamDayOfferCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinic: ClinicCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicUser: UserCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicPet: ClinicPetCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  appointmentType: AppointmentTypeCreateNestedOneWithoutRelatedTrupanionExamDayOffersInput;
};

export type SentTrupanionExamDayOfferCreateWithoutClinicUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinic: ClinicCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicPet: ClinicPetCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  appointmentType: AppointmentTypeCreateNestedOneWithoutRelatedTrupanionExamDayOffersInput;
};

export type SentTrupanionExamDayOfferCreateWithoutTrupanionIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  clinic: ClinicCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicUser: UserCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicPet: ClinicPetCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  appointmentType: AppointmentTypeCreateNestedOneWithoutRelatedTrupanionExamDayOffersInput;
};

export type SentTrupanionExamDayOfferListRelationFilter = {
  every?: Maybe<SentTrupanionExamDayOfferWhereInput>;
  some?: Maybe<SentTrupanionExamDayOfferWhereInput>;
  none?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};

export type SentTrupanionExamDayOfferMaxAggregateOutputType = {
  __typename?: 'SentTrupanionExamDayOfferMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  sentByClinicUserId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  referenceNumber?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
};

export type SentTrupanionExamDayOfferMinAggregateOutputType = {
  __typename?: 'SentTrupanionExamDayOfferMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  sentByClinicUserId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  referenceNumber?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
};

export type SentTrupanionExamDayOfferOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum SentTrupanionExamDayOfferOrderByRelevanceFieldEnum {
  Id = 'id',
  TrupanionIntegrationId = 'trupanionIntegrationId',
  ClinicId = 'clinicId',
  SentByClinicUserId = 'sentByClinicUserId',
  ClinicPetParentId = 'clinicPetParentId',
  ClinicPetId = 'clinicPetId',
  AppointmentTypeId = 'appointmentTypeId',
  ReferenceNumber = 'referenceNumber'
}

export type SentTrupanionExamDayOfferOrderByRelevanceInput = {
  fields: Array<SentTrupanionExamDayOfferOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type SentTrupanionExamDayOfferOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  sentByClinicUserId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  referenceNumber?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  _count?: Maybe<SentTrupanionExamDayOfferCountOrderByAggregateInput>;
  _max?: Maybe<SentTrupanionExamDayOfferMaxOrderByAggregateInput>;
  _min?: Maybe<SentTrupanionExamDayOfferMinOrderByAggregateInput>;
};

export type SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  sentByClinicUserId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  referenceNumber?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  trupanionIntegration?: Maybe<TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<SentTrupanionExamDayOfferOrderByRelevanceInput>;
};

export enum SentTrupanionExamDayOfferScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  TrupanionIntegrationId = 'trupanionIntegrationId',
  ClinicId = 'clinicId',
  SentByClinicUserId = 'sentByClinicUserId',
  ClinicPetParentId = 'clinicPetParentId',
  ClinicPetId = 'clinicPetId',
  AppointmentTypeId = 'appointmentTypeId',
  ReferenceNumber = 'referenceNumber',
  ActivationDate = 'activationDate',
  ExpirationDate = 'expirationDate'
}

export type SentTrupanionExamDayOfferScalarWhereInput = {
  AND?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  OR?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  NOT?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  trupanionIntegrationId?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  sentByClinicUserId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  appointmentTypeId?: Maybe<StringFilter>;
  referenceNumber?: Maybe<StringFilter>;
  activationDate?: Maybe<DateTimeNullableFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
};

export type SentTrupanionExamDayOfferScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  trupanionIntegrationId?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  sentByClinicUserId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
  appointmentTypeId?: Maybe<StringWithAggregatesFilter>;
  referenceNumber?: Maybe<StringWithAggregatesFilter>;
  activationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  expirationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
};

export type SentTrupanionExamDayOfferUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
  clinicId: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicPetId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
};

export type SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
};

export type SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
};

export type SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
};

export type SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicUserInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicUserInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicUserInputEnvelope>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
};

export type SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutTrupanionIntegrationInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutTrupanionIntegrationInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyTrupanionIntegrationInputEnvelope>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
};

export type SentTrupanionExamDayOfferUncheckedCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
  clinicId: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicPetId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicPetId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
  clinicId: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
  clinicId: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  clinicPetId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedCreateWithoutClinicUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicPetId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedCreateWithoutTrupanionIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicPetId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  referenceNumber: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicUserInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicUserInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicUserInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicUserInputEnvelope>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicUserInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicUserInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutReceivedTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutRelatedTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutSentTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutTrupanionIntegrationInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutTrupanionIntegrationInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyTrupanionIntegrationInputEnvelope>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutTrupanionIntegrationInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutTrupanionIntegrationInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateWithoutTrupanionIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicUser?: Maybe<UserUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutRelatedTrupanionExamDayOffersNestedInput>;
};

export type SentTrupanionExamDayOfferUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUpdateManyWithWhereWithoutAppointmentTypeInput = {
  where: SentTrupanionExamDayOfferScalarWhereInput;
  data: SentTrupanionExamDayOfferUncheckedUpdateManyWithoutRelatedTrupanionExamDayOffersInput;
};

export type SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicInput = {
  where: SentTrupanionExamDayOfferScalarWhereInput;
  data: SentTrupanionExamDayOfferUncheckedUpdateManyWithoutSentTrupanionExamDayOffersInput;
};

export type SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicPetInput = {
  where: SentTrupanionExamDayOfferScalarWhereInput;
  data: SentTrupanionExamDayOfferUncheckedUpdateManyWithoutReceivedTrupanionExamDayOffersInput;
};

export type SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: SentTrupanionExamDayOfferScalarWhereInput;
  data: SentTrupanionExamDayOfferUncheckedUpdateManyWithoutReceivedTrupanionExamDayOffersInput;
};

export type SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicUserInput = {
  where: SentTrupanionExamDayOfferScalarWhereInput;
  data: SentTrupanionExamDayOfferUncheckedUpdateManyWithoutSentTrupanionExamDayOffersInput;
};

export type SentTrupanionExamDayOfferUpdateManyWithWhereWithoutTrupanionIntegrationInput = {
  where: SentTrupanionExamDayOfferScalarWhereInput;
  data: SentTrupanionExamDayOfferUncheckedUpdateManyWithoutSentTrupanionExamDayOffersInput;
};

export type SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
};

export type SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
};

export type SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
};

export type SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
};

export type SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicUserInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicUserInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicUserInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicUserInputEnvelope>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicUserInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicUserInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
};

export type SentTrupanionExamDayOfferUpdateManyWithoutTrupanionIntegrationNestedInput = {
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutTrupanionIntegrationInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutTrupanionIntegrationInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyTrupanionIntegrationInputEnvelope>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutTrupanionIntegrationInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutTrupanionIntegrationInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
};

export type SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  data: SentTrupanionExamDayOfferUncheckedUpdateWithoutAppointmentTypeInput;
};

export type SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  data: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicInput;
};

export type SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  data: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicPetInput;
};

export type SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  data: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicPetParentInput;
};

export type SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicUserInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  data: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicUserInput;
};

export type SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutTrupanionIntegrationInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  data: SentTrupanionExamDayOfferUncheckedUpdateWithoutTrupanionIntegrationInput;
};

export type SentTrupanionExamDayOfferUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicUser?: Maybe<UserUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
};

export type SentTrupanionExamDayOfferUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicUser?: Maybe<UserUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutRelatedTrupanionExamDayOffersNestedInput>;
};

export type SentTrupanionExamDayOfferUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicUser?: Maybe<UserUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutRelatedTrupanionExamDayOffersNestedInput>;
};

export type SentTrupanionExamDayOfferUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicUser?: Maybe<UserUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutRelatedTrupanionExamDayOffersNestedInput>;
};

export type SentTrupanionExamDayOfferUpdateWithoutClinicUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutRelatedTrupanionExamDayOffersNestedInput>;
};

export type SentTrupanionExamDayOfferUpdateWithoutTrupanionIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicUser?: Maybe<UserUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutRelatedTrupanionExamDayOffersNestedInput>;
};

export type SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  update: SentTrupanionExamDayOfferUncheckedUpdateWithoutAppointmentTypeInput;
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutAppointmentTypeInput;
};

export type SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  update: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicInput;
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicInput;
};

export type SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  update: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicPetInput;
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicPetInput;
};

export type SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  update: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicPetParentInput;
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicPetParentInput;
};

export type SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicUserInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  update: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicUserInput;
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicUserInput;
};

export type SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutTrupanionIntegrationInput = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
  update: SentTrupanionExamDayOfferUncheckedUpdateWithoutTrupanionIntegrationInput;
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutTrupanionIntegrationInput;
};

export type SentTrupanionExamDayOfferWhereInput = {
  AND?: Maybe<Array<SentTrupanionExamDayOfferWhereInput>>;
  OR?: Maybe<Array<SentTrupanionExamDayOfferWhereInput>>;
  NOT?: Maybe<Array<SentTrupanionExamDayOfferWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  trupanionIntegrationId?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  sentByClinicUserId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  appointmentTypeId?: Maybe<StringFilter>;
  referenceNumber?: Maybe<StringFilter>;
  activationDate?: Maybe<DateTimeNullableFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  trupanionIntegration?: Maybe<TrupanionIntegrationWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicUser?: Maybe<UserWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
};

export type SentTrupanionExamDayOfferWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Service = {
  __typename?: 'Service';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive: Scalars['Boolean'];
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  hasConfiguredReminders: Scalars['Boolean'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal: Scalars['Boolean'];
  isPostcard: Scalars['Boolean'];
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings: Array<ServiceReminderTiming>;
  reminders: Array<ServiceReminder>;
  integration: ClinicPimsIntegration;
  formTemplate?: Maybe<FormTemplate>;
  invoiceLineItems: Array<InvoiceLineItem>;
  canonicalService?: Maybe<CanonicalService>;
  organizationCareBenefitToClinicService: Array<OrganizationCareBenefitToClinicService>;
  draftCareBenefitUsageToInvoiceLineItems: Array<CareBenefitUsageToInvoiceLineItem>;
  prescriptions: Array<Prescription>;
  loyaltyPointGrantingServices: Array<LoyaltyPointGrantingService>;
  serviceCategory?: Maybe<ServiceCategory>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategory>;
  vetsourceCodeTags: Array<VetsourceCodeTag>;
  _count: ServiceCountOutputType;
};


export type ServiceReminderTimingsArgs = {
  where?: Maybe<ServiceReminderTimingWhereInput>;
  orderBy?: Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderTimingScalarFieldEnum>;
};


export type ServiceRemindersArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderScalarFieldEnum>;
};


export type ServiceInvoiceLineItemsArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
};


export type ServiceOrganizationCareBenefitToClinicServiceArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  orderBy?: Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>;
};


export type ServiceDraftCareBenefitUsageToInvoiceLineItemsArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
};


export type ServicePrescriptionsArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
};


export type ServiceLoyaltyPointGrantingServicesArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
  orderBy?: Maybe<LoyaltyPointGrantingServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyPointGrantingServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyPointGrantingServiceScalarFieldEnum>;
};


export type ServiceVetsourceCodeTagsArgs = {
  where?: Maybe<VetsourceCodeTagWhereInput>;
  orderBy?: Maybe<VetsourceCodeTagOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetsourceCodeTagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetsourceCodeTagScalarFieldEnum>;
};

export enum ServiceCapability {
  Sms = 'SMS',
  Voice = 'Voice',
  Mms = 'MMS'
}

export type ServiceCategory = {
  __typename?: 'ServiceCategory';
  id: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegration;
  parentServiceCategory?: Maybe<ServiceCategory>;
  childServiceCategoires: Array<ServiceCategory>;
  childServices: Array<Service>;
  connectedCareBenefits: Array<OrganizationCareBenefitToClinicServiceCategory>;
  loyaltyPointGrantingServiceCategories: Array<LoyaltyPointGrantingServiceCategory>;
  _count: ServiceCategoryCountOutputType;
};


export type ServiceCategoryChildServiceCategoiresArgs = {
  where?: Maybe<ServiceCategoryWhereInput>;
  orderBy?: Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceCategoryScalarFieldEnum>;
};


export type ServiceCategoryChildServicesArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceScalarFieldEnum>;
};


export type ServiceCategoryConnectedCareBenefitsArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
  orderBy?: Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationCareBenefitToClinicServiceCategoryScalarFieldEnum>;
};


export type ServiceCategoryLoyaltyPointGrantingServiceCategoriesArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
  orderBy?: Maybe<LoyaltyPointGrantingServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyPointGrantingServiceCategoryScalarFieldEnum>;
};

export type ServiceCategoryCountAggregateOutputType = {
  __typename?: 'ServiceCategoryCountAggregateOutputType';
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  pimsId: Scalars['Int'];
  parentCategoryPimsId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  description: Scalars['Int'];
  shortDescription: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  parentServiceCategoryId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ServiceCategoryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  parentCategoryPimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  shortDescription?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  parentServiceCategoryId?: Maybe<SortOrder>;
};

export type ServiceCategoryCountOutputType = {
  __typename?: 'ServiceCategoryCountOutputType';
  childServiceCategoires: Scalars['Int'];
  childServices: Scalars['Int'];
  connectedCareBenefits: Scalars['Int'];
  loyaltyPointGrantingServiceCategories: Scalars['Int'];
};

export type ServiceCategoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceCategoriesInput;
  parentServiceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServiceCategoiresInput>;
  childServiceCategoires?: Maybe<ServiceCategoryCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
};

export type ServiceCategoryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  parentServiceCategoryId?: Maybe<Scalars['String']>;
};

export type ServiceCategoryCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  parentServiceCategoryId?: Maybe<Scalars['String']>;
};

export type ServiceCategoryCreateManyIntegrationInputEnvelope = {
  data: Array<ServiceCategoryCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCategoryCreateManyParentServiceCategoryInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
};

export type ServiceCategoryCreateManyParentServiceCategoryInputEnvelope = {
  data: Array<ServiceCategoryCreateManyParentServiceCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCategoryCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ServiceCategoryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
};

export type ServiceCategoryCreateNestedManyWithoutParentServiceCategoryInput = {
  create?: Maybe<Array<ServiceCategoryCreateWithoutParentServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutParentServiceCategoryInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyParentServiceCategoryInputEnvelope>;
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
};

export type ServiceCategoryCreateNestedOneWithoutChildServiceCategoiresInput = {
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutChildServiceCategoiresInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutChildServiceCategoiresInput>;
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
};

export type ServiceCategoryCreateNestedOneWithoutChildServicesInput = {
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutChildServicesInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutChildServicesInput>;
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
};

export type ServiceCategoryCreateNestedOneWithoutConnectedCareBenefitsInput = {
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutConnectedCareBenefitsInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutConnectedCareBenefitsInput>;
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
};

export type ServiceCategoryCreateNestedOneWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutLoyaltyPointGrantingServiceCategoriesInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutLoyaltyPointGrantingServiceCategoriesInput>;
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
};

export type ServiceCategoryCreateOrConnectWithoutChildServiceCategoiresInput = {
  where: ServiceCategoryWhereUniqueInput;
  create: ServiceCategoryUncheckedCreateWithoutChildServiceCategoiresInput;
};

export type ServiceCategoryCreateOrConnectWithoutChildServicesInput = {
  where: ServiceCategoryWhereUniqueInput;
  create: ServiceCategoryUncheckedCreateWithoutChildServicesInput;
};

export type ServiceCategoryCreateOrConnectWithoutConnectedCareBenefitsInput = {
  where: ServiceCategoryWhereUniqueInput;
  create: ServiceCategoryUncheckedCreateWithoutConnectedCareBenefitsInput;
};

export type ServiceCategoryCreateOrConnectWithoutIntegrationInput = {
  where: ServiceCategoryWhereUniqueInput;
  create: ServiceCategoryUncheckedCreateWithoutIntegrationInput;
};

export type ServiceCategoryCreateOrConnectWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  where: ServiceCategoryWhereUniqueInput;
  create: ServiceCategoryUncheckedCreateWithoutLoyaltyPointGrantingServiceCategoriesInput;
};

export type ServiceCategoryCreateOrConnectWithoutParentServiceCategoryInput = {
  where: ServiceCategoryWhereUniqueInput;
  create: ServiceCategoryUncheckedCreateWithoutParentServiceCategoryInput;
};

export type ServiceCategoryCreateWithoutChildServiceCategoiresInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceCategoriesInput;
  parentServiceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServiceCategoiresInput>;
  childServices?: Maybe<ServiceCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
};

export type ServiceCategoryCreateWithoutChildServicesInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceCategoriesInput;
  parentServiceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServiceCategoiresInput>;
  childServiceCategoires?: Maybe<ServiceCategoryCreateNestedManyWithoutParentServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
};

export type ServiceCategoryCreateWithoutConnectedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceCategoriesInput;
  parentServiceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServiceCategoiresInput>;
  childServiceCategoires?: Maybe<ServiceCategoryCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceCreateNestedManyWithoutServiceCategoryInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
};

export type ServiceCategoryCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  parentServiceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServiceCategoiresInput>;
  childServiceCategoires?: Maybe<ServiceCategoryCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
};

export type ServiceCategoryCreateWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceCategoriesInput;
  parentServiceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServiceCategoiresInput>;
  childServiceCategoires?: Maybe<ServiceCategoryCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
};

export type ServiceCategoryCreateWithoutParentServiceCategoryInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceCategoriesInput;
  childServiceCategoires?: Maybe<ServiceCategoryCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
};

export type ServiceCategoryListRelationFilter = {
  every?: Maybe<ServiceCategoryWhereInput>;
  some?: Maybe<ServiceCategoryWhereInput>;
  none?: Maybe<ServiceCategoryWhereInput>;
};

export type ServiceCategoryMaxAggregateOutputType = {
  __typename?: 'ServiceCategoryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
};

export type ServiceCategoryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  parentCategoryPimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  shortDescription?: Maybe<SortOrder>;
  parentServiceCategoryId?: Maybe<SortOrder>;
};

export type ServiceCategoryMinAggregateOutputType = {
  __typename?: 'ServiceCategoryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
};

export type ServiceCategoryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  parentCategoryPimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  shortDescription?: Maybe<SortOrder>;
  parentServiceCategoryId?: Maybe<SortOrder>;
};

export type ServiceCategoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ServiceCategoryOrderByRelevanceFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  ParentCategoryPimsId = 'parentCategoryPimsId',
  Description = 'description',
  ShortDescription = 'shortDescription',
  ParentServiceCategoryId = 'parentServiceCategoryId'
}

export type ServiceCategoryOrderByRelevanceInput = {
  fields: Array<ServiceCategoryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ServiceCategoryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  parentCategoryPimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  shortDescription?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  parentServiceCategoryId?: Maybe<SortOrder>;
  _count?: Maybe<ServiceCategoryCountOrderByAggregateInput>;
  _max?: Maybe<ServiceCategoryMaxOrderByAggregateInput>;
  _min?: Maybe<ServiceCategoryMinOrderByAggregateInput>;
};

export type ServiceCategoryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  parentCategoryPimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  shortDescription?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  parentServiceCategoryId?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  parentServiceCategory?: Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  childServiceCategoires?: Maybe<ServiceCategoryOrderByRelationAggregateInput>;
  childServices?: Maybe<ServiceOrderByRelationAggregateInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByRelationAggregateInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryOrderByRelationAggregateInput>;
  _relevance?: Maybe<ServiceCategoryOrderByRelevanceInput>;
};

export type ServiceCategoryPimsIdIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type ServiceCategoryRelationFilter = {
  is?: Maybe<ServiceCategoryWhereInput>;
  isNot?: Maybe<ServiceCategoryWhereInput>;
};

export enum ServiceCategoryScalarFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  ParentCategoryPimsId = 'parentCategoryPimsId',
  IsDeleted = 'isDeleted',
  Description = 'description',
  ShortDescription = 'shortDescription',
  RawPimsValue = 'rawPimsValue',
  ParentServiceCategoryId = 'parentServiceCategoryId'
}

export type ServiceCategoryScalarWhereInput = {
  AND?: Maybe<Array<ServiceCategoryScalarWhereInput>>;
  OR?: Maybe<Array<ServiceCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<ServiceCategoryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  parentCategoryPimsId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  description?: Maybe<StringFilter>;
  shortDescription?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonFilter>;
  parentServiceCategoryId?: Maybe<StringNullableFilter>;
};

export type ServiceCategoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ServiceCategoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ServiceCategoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ServiceCategoryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  parentCategoryPimsId?: Maybe<StringNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  shortDescription?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonWithAggregatesFilter>;
  parentServiceCategoryId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ServiceCategoryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
};

export type ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ServiceCategoryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
};

export type ServiceCategoryUncheckedCreateNestedManyWithoutParentServiceCategoryInput = {
  create?: Maybe<Array<ServiceCategoryCreateWithoutParentServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutParentServiceCategoryInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyParentServiceCategoryInputEnvelope>;
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
};

export type ServiceCategoryUncheckedCreateWithoutChildServiceCategoiresInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  childServices?: Maybe<ServiceUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
};

export type ServiceCategoryUncheckedCreateWithoutChildServicesInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutParentServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
};

export type ServiceCategoryUncheckedCreateWithoutConnectedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
};

export type ServiceCategoryUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
};

export type ServiceCategoryUncheckedCreateWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
};

export type ServiceCategoryUncheckedCreateWithoutParentServiceCategoryInput = {
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  shortDescription: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
};

export type ServiceCategoryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
};

export type ServiceCategoryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUncheckedUpdateManyWithoutChildServiceCategoiresInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ServiceCategoryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ServiceCategoryUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<ServiceCategoryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ServiceCategoryUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ServiceCategoryScalarWhereInput>>;
};

export type ServiceCategoryUncheckedUpdateManyWithoutParentServiceCategoryNestedInput = {
  create?: Maybe<Array<ServiceCategoryCreateWithoutParentServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutParentServiceCategoryInput>>;
  upsert?: Maybe<Array<ServiceCategoryUpsertWithWhereUniqueWithoutParentServiceCategoryInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyParentServiceCategoryInputEnvelope>;
  set?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<ServiceCategoryUpdateWithWhereUniqueWithoutParentServiceCategoryInput>>;
  updateMany?: Maybe<Array<ServiceCategoryUpdateManyWithWhereWithoutParentServiceCategoryInput>>;
  deleteMany?: Maybe<Array<ServiceCategoryScalarWhereInput>>;
};

export type ServiceCategoryUncheckedUpdateManyWithoutServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUncheckedUpdateWithoutChildServiceCategoiresInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  childServices?: Maybe<ServiceUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
};

export type ServiceCategoryUncheckedUpdateWithoutChildServicesInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutParentServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
};

export type ServiceCategoryUncheckedUpdateWithoutConnectedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
};

export type ServiceCategoryUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
};

export type ServiceCategoryUncheckedUpdateWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
};

export type ServiceCategoryUncheckedUpdateWithoutParentServiceCategoryInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
};

export type ServiceCategoryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceCategoriesNestedInput>;
  parentServiceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServiceCategoiresNestedInput>;
  childServiceCategoires?: Maybe<ServiceCategoryUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
};

export type ServiceCategoryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceCategoryUpdateManyWithWhereWithoutIntegrationInput = {
  where: ServiceCategoryScalarWhereInput;
  data: ServiceCategoryUncheckedUpdateManyWithoutServiceCategoriesInput;
};

export type ServiceCategoryUpdateManyWithWhereWithoutParentServiceCategoryInput = {
  where: ServiceCategoryScalarWhereInput;
  data: ServiceCategoryUncheckedUpdateManyWithoutChildServiceCategoiresInput;
};

export type ServiceCategoryUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ServiceCategoryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ServiceCategoryUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<ServiceCategoryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ServiceCategoryUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ServiceCategoryScalarWhereInput>>;
};

export type ServiceCategoryUpdateManyWithoutParentServiceCategoryNestedInput = {
  create?: Maybe<Array<ServiceCategoryCreateWithoutParentServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutParentServiceCategoryInput>>;
  upsert?: Maybe<Array<ServiceCategoryUpsertWithWhereUniqueWithoutParentServiceCategoryInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyParentServiceCategoryInputEnvelope>;
  set?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<ServiceCategoryUpdateWithWhereUniqueWithoutParentServiceCategoryInput>>;
  updateMany?: Maybe<Array<ServiceCategoryUpdateManyWithWhereWithoutParentServiceCategoryInput>>;
  deleteMany?: Maybe<Array<ServiceCategoryScalarWhereInput>>;
};

export type ServiceCategoryUpdateOneRequiredWithoutConnectedCareBenefitsNestedInput = {
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutConnectedCareBenefitsInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutConnectedCareBenefitsInput>;
  upsert?: Maybe<ServiceCategoryUpsertWithoutConnectedCareBenefitsInput>;
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
  update?: Maybe<ServiceCategoryUncheckedUpdateWithoutConnectedCareBenefitsInput>;
};

export type ServiceCategoryUpdateOneRequiredWithoutLoyaltyPointGrantingServiceCategoriesNestedInput = {
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutLoyaltyPointGrantingServiceCategoriesInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutLoyaltyPointGrantingServiceCategoriesInput>;
  upsert?: Maybe<ServiceCategoryUpsertWithoutLoyaltyPointGrantingServiceCategoriesInput>;
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
  update?: Maybe<ServiceCategoryUncheckedUpdateWithoutLoyaltyPointGrantingServiceCategoriesInput>;
};

export type ServiceCategoryUpdateOneWithoutChildServiceCategoiresNestedInput = {
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutChildServiceCategoiresInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutChildServiceCategoiresInput>;
  upsert?: Maybe<ServiceCategoryUpsertWithoutChildServiceCategoiresInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
  update?: Maybe<ServiceCategoryUncheckedUpdateWithoutChildServiceCategoiresInput>;
};

export type ServiceCategoryUpdateOneWithoutChildServicesNestedInput = {
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutChildServicesInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutChildServicesInput>;
  upsert?: Maybe<ServiceCategoryUpsertWithoutChildServicesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
  update?: Maybe<ServiceCategoryUncheckedUpdateWithoutChildServicesInput>;
};

export type ServiceCategoryUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ServiceCategoryWhereUniqueInput;
  data: ServiceCategoryUncheckedUpdateWithoutIntegrationInput;
};

export type ServiceCategoryUpdateWithWhereUniqueWithoutParentServiceCategoryInput = {
  where: ServiceCategoryWhereUniqueInput;
  data: ServiceCategoryUncheckedUpdateWithoutParentServiceCategoryInput;
};

export type ServiceCategoryUpdateWithoutChildServiceCategoiresInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceCategoriesNestedInput>;
  parentServiceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServiceCategoiresNestedInput>;
  childServices?: Maybe<ServiceUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
};

export type ServiceCategoryUpdateWithoutChildServicesInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceCategoriesNestedInput>;
  parentServiceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServiceCategoiresNestedInput>;
  childServiceCategoires?: Maybe<ServiceCategoryUpdateManyWithoutParentServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
};

export type ServiceCategoryUpdateWithoutConnectedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceCategoriesNestedInput>;
  parentServiceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServiceCategoiresNestedInput>;
  childServiceCategoires?: Maybe<ServiceCategoryUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUpdateManyWithoutServiceCategoryNestedInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
};

export type ServiceCategoryUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  parentServiceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServiceCategoiresNestedInput>;
  childServiceCategoires?: Maybe<ServiceCategoryUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
};

export type ServiceCategoryUpdateWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceCategoriesNestedInput>;
  parentServiceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServiceCategoiresNestedInput>;
  childServiceCategoires?: Maybe<ServiceCategoryUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
};

export type ServiceCategoryUpdateWithoutParentServiceCategoryInput = {
  id?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceCategoriesNestedInput>;
  childServiceCategoires?: Maybe<ServiceCategoryUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
};

export type ServiceCategoryUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ServiceCategoryWhereUniqueInput;
  update: ServiceCategoryUncheckedUpdateWithoutIntegrationInput;
  create: ServiceCategoryUncheckedCreateWithoutIntegrationInput;
};

export type ServiceCategoryUpsertWithWhereUniqueWithoutParentServiceCategoryInput = {
  where: ServiceCategoryWhereUniqueInput;
  update: ServiceCategoryUncheckedUpdateWithoutParentServiceCategoryInput;
  create: ServiceCategoryUncheckedCreateWithoutParentServiceCategoryInput;
};

export type ServiceCategoryUpsertWithoutChildServiceCategoiresInput = {
  update: ServiceCategoryUncheckedUpdateWithoutChildServiceCategoiresInput;
  create: ServiceCategoryUncheckedCreateWithoutChildServiceCategoiresInput;
};

export type ServiceCategoryUpsertWithoutChildServicesInput = {
  update: ServiceCategoryUncheckedUpdateWithoutChildServicesInput;
  create: ServiceCategoryUncheckedCreateWithoutChildServicesInput;
};

export type ServiceCategoryUpsertWithoutConnectedCareBenefitsInput = {
  update: ServiceCategoryUncheckedUpdateWithoutConnectedCareBenefitsInput;
  create: ServiceCategoryUncheckedCreateWithoutConnectedCareBenefitsInput;
};

export type ServiceCategoryUpsertWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  update: ServiceCategoryUncheckedUpdateWithoutLoyaltyPointGrantingServiceCategoriesInput;
  create: ServiceCategoryUncheckedCreateWithoutLoyaltyPointGrantingServiceCategoriesInput;
};

export type ServiceCategoryWhereInput = {
  AND?: Maybe<Array<ServiceCategoryWhereInput>>;
  OR?: Maybe<Array<ServiceCategoryWhereInput>>;
  NOT?: Maybe<Array<ServiceCategoryWhereInput>>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  parentCategoryPimsId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  description?: Maybe<StringFilter>;
  shortDescription?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonFilter>;
  parentServiceCategoryId?: Maybe<StringNullableFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  parentServiceCategory?: Maybe<ServiceCategoryWhereInput>;
  childServiceCategoires?: Maybe<ServiceCategoryListRelationFilter>;
  childServices?: Maybe<ServiceListRelationFilter>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryListRelationFilter>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryListRelationFilter>;
};

export type ServiceCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsId_integrationId?: Maybe<ServiceCategoryPimsIdIntegrationIdCompoundUniqueInput>;
};

export type ServiceCountAggregateOutputType = {
  __typename?: 'ServiceCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  name: Scalars['Int'];
  code: Scalars['Int'];
  pimsName: Scalars['Int'];
  pimsId: Scalars['Int'];
  isReminderActive: Scalars['Int'];
  reminderActivationDate: Scalars['Int'];
  integrationId: Scalars['Int'];
  formTemplateId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  hasConfiguredReminders: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  isPetPortal: Scalars['Int'];
  isPostcard: Scalars['Int'];
  canonicalServiceId: Scalars['Int'];
  serviceCategoryId: Scalars['Int'];
  vetsourceRevenueCategoryName: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ServiceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isReminderActive?: Maybe<SortOrder>;
  reminderActivationDate?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  isPetPortal?: Maybe<SortOrder>;
  isPostcard?: Maybe<SortOrder>;
  canonicalServiceId?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  vetsourceRevenueCategoryName?: Maybe<SortOrder>;
};

export type ServiceCountOutputType = {
  __typename?: 'ServiceCountOutputType';
  reminderTimings: Scalars['Int'];
  reminders: Scalars['Int'];
  invoiceLineItems: Scalars['Int'];
  organizationCareBenefitToClinicService: Scalars['Int'];
  draftCareBenefitUsageToInvoiceLineItems: Scalars['Int'];
  prescriptions: Scalars['Int'];
  loyaltyPointGrantingServices: Scalars['Int'];
  vetsourceCodeTags: Scalars['Int'];
};

export type ServiceCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateManyCanonicalServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceCreateManyCanonicalServiceInputEnvelope = {
  data: Array<ServiceCreateManyCanonicalServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCreateManyFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceCreateManyFormTemplateInputEnvelope = {
  data: Array<ServiceCreateManyFormTemplateInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceCreateManyIntegrationInputEnvelope = {
  data: Array<ServiceCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCreateManyServiceCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceCreateManyServiceCategoryInputEnvelope = {
  data: Array<ServiceCreateManyServiceCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCreateManyVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type ServiceCreateManyVetsourceRevenueCategoryInputEnvelope = {
  data: Array<ServiceCreateManyVetsourceRevenueCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCreateNestedManyWithoutCanonicalServiceInput = {
  create?: Maybe<Array<ServiceCreateWithoutCanonicalServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  createMany?: Maybe<ServiceCreateManyCanonicalServiceInputEnvelope>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceCreateNestedManyWithoutFormTemplateInput = {
  create?: Maybe<Array<ServiceCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutFormTemplateInput>>;
  createMany?: Maybe<ServiceCreateManyFormTemplateInputEnvelope>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ServiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceCreateNestedManyWithoutServiceCategoryInput = {
  create?: Maybe<Array<ServiceCreateWithoutServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutServiceCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyServiceCategoryInputEnvelope>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceCreateNestedManyWithoutVetsourceCodeTagsInput = {
  create?: Maybe<Array<ServiceCreateWithoutVetsourceCodeTagsInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceCodeTagsInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceCreateNestedManyWithoutVetsourceRevenueCategoryInput = {
  create?: Maybe<Array<ServiceCreateWithoutVetsourceRevenueCategoryInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyVetsourceRevenueCategoryInputEnvelope>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
};

export type ServiceCreateNestedOneWithoutInvoiceLineItemsInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutInvoiceLineItemsInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
};

export type ServiceCreateNestedOneWithoutLoyaltyPointGrantingServicesInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutLoyaltyPointGrantingServicesInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutLoyaltyPointGrantingServicesInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
};

export type ServiceCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
};

export type ServiceCreateNestedOneWithoutPrescriptionsInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutPrescriptionsInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
};

export type ServiceCreateNestedOneWithoutReminderTimingsInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutReminderTimingsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutReminderTimingsInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
};

export type ServiceCreateNestedOneWithoutRemindersInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutRemindersInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutRemindersInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
};

export type ServiceCreateOrConnectWithoutCanonicalServiceInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutCanonicalServiceInput;
};

export type ServiceCreateOrConnectWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput;
};

export type ServiceCreateOrConnectWithoutFormTemplateInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutFormTemplateInput;
};

export type ServiceCreateOrConnectWithoutIntegrationInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutIntegrationInput;
};

export type ServiceCreateOrConnectWithoutInvoiceLineItemsInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutInvoiceLineItemsInput;
};

export type ServiceCreateOrConnectWithoutLoyaltyPointGrantingServicesInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutLoyaltyPointGrantingServicesInput;
};

export type ServiceCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type ServiceCreateOrConnectWithoutPrescriptionsInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutPrescriptionsInput;
};

export type ServiceCreateOrConnectWithoutReminderTimingsInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutReminderTimingsInput;
};

export type ServiceCreateOrConnectWithoutRemindersInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutRemindersInput;
};

export type ServiceCreateOrConnectWithoutServiceCategoryInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutServiceCategoryInput;
};

export type ServiceCreateOrConnectWithoutVetsourceCodeTagsInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutVetsourceCodeTagsInput;
};

export type ServiceCreateOrConnectWithoutVetsourceRevenueCategoryInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutVetsourceRevenueCategoryInput;
};

export type ServiceCreateWithoutCanonicalServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutLoyaltyPointGrantingServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutReminderTimingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutServiceCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutVetsourceCodeTagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
};

export type ServiceCreateWithoutVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
};

export type ServiceListRelationFilter = {
  every?: Maybe<ServiceWhereInput>;
  some?: Maybe<ServiceWhereInput>;
  none?: Maybe<ServiceWhereInput>;
};

export type ServiceMaxAggregateOutputType = {
  __typename?: 'ServiceMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isReminderActive?: Maybe<SortOrder>;
  reminderActivationDate?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
  isPetPortal?: Maybe<SortOrder>;
  isPostcard?: Maybe<SortOrder>;
  canonicalServiceId?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  vetsourceRevenueCategoryName?: Maybe<SortOrder>;
};

export type ServiceMinAggregateOutputType = {
  __typename?: 'ServiceMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isReminderActive?: Maybe<SortOrder>;
  reminderActivationDate?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
  isPetPortal?: Maybe<SortOrder>;
  isPostcard?: Maybe<SortOrder>;
  canonicalServiceId?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  vetsourceRevenueCategoryName?: Maybe<SortOrder>;
};

export type ServiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ServiceOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  Code = 'code',
  PimsName = 'pimsName',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  FormTemplateId = 'formTemplateId',
  CanonicalServiceId = 'canonicalServiceId',
  ServiceCategoryId = 'serviceCategoryId',
  VetsourceRevenueCategoryName = 'vetsourceRevenueCategoryName'
}

export type ServiceOrderByRelevanceInput = {
  fields: Array<ServiceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ServiceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isReminderActive?: Maybe<SortOrder>;
  reminderActivationDate?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  isPetPortal?: Maybe<SortOrder>;
  isPostcard?: Maybe<SortOrder>;
  canonicalServiceId?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  vetsourceRevenueCategoryName?: Maybe<SortOrder>;
  _count?: Maybe<ServiceCountOrderByAggregateInput>;
  _max?: Maybe<ServiceMaxOrderByAggregateInput>;
  _min?: Maybe<ServiceMinOrderByAggregateInput>;
};

export type ServiceOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isReminderActive?: Maybe<SortOrder>;
  reminderActivationDate?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  isPetPortal?: Maybe<SortOrder>;
  isPostcard?: Maybe<SortOrder>;
  canonicalServiceId?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  vetsourceRevenueCategoryName?: Maybe<SortOrder>;
  reminderTimings?: Maybe<ServiceReminderTimingOrderByRelationAggregateInput>;
  reminders?: Maybe<ServiceReminderOrderByRelationAggregateInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  formTemplate?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  canonicalService?: Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceOrderByRelationAggregateInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceOrderByRelationAggregateInput>;
  serviceCategory?: Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagOrderByRelationAggregateInput>;
  _relevance?: Maybe<ServiceOrderByRelevanceInput>;
};

export enum ServiceProvider {
  Bandwidth = 'Bandwidth',
  Twilio = 'Twilio'
}

export type ServiceRelationFilter = {
  is?: Maybe<ServiceWhereInput>;
  isNot?: Maybe<ServiceWhereInput>;
};

export type ServiceReminder = {
  __typename?: 'ServiceReminder';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  hasResponded: Scalars['Boolean'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: Service;
  clinicPet?: Maybe<ClinicPet>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  integration: ClinicPimsIntegration;
  notifications: Array<ServiceReminderNotification>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channelCreationSources: Array<ChannelCreationSource>;
  automationRuns: Array<AutomationRun>;
  _count: ServiceReminderCountOutputType;
};


export type ServiceReminderNotificationsArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderNotificationScalarFieldEnum>;
};


export type ServiceReminderChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};


export type ServiceReminderChannelCreationSourcesArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
};


export type ServiceReminderAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};

export type ServiceReminderCountAggregateOutputType = {
  __typename?: 'ServiceReminderCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  serviceId: Scalars['Int'];
  dueDate: Scalars['Int'];
  dateLastGiven: Scalars['Int'];
  pimsId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  integrationId: Scalars['Int'];
  hasResponded: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ServiceReminderCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  dateLastGiven?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ServiceReminderCountOutputType = {
  __typename?: 'ServiceReminderCountOutputType';
  notifications: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  automationRuns: Scalars['Int'];
};

export type ServiceReminderCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderCreateManyClinicPetInputEnvelope = {
  data: Array<ServiceReminderCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderCreateManyClinicPetParentInputEnvelope = {
  data: Array<ServiceReminderCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderCreateManyIntegrationInputEnvelope = {
  data: Array<ServiceReminderCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderCreateManyServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderCreateManyServiceInputEnvelope = {
  data: Array<ServiceReminderCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
};

export type ServiceReminderCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
};

export type ServiceReminderCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceReminderCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
};

export type ServiceReminderCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
};

export type ServiceReminderCreateNestedOneWithoutAutomationRunsInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutAutomationRunsInput>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
};

export type ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
};

export type ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutChannelCreationSourcesInput>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
};

export type ServiceReminderCreateNestedOneWithoutNotificationsInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutNotificationsInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutNotificationsInput>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
};

export type ServiceReminderCreateOrConnectWithoutAutomationRunsInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutAutomationRunsInput;
};

export type ServiceReminderCreateOrConnectWithoutChannelAutomationStatusesInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type ServiceReminderCreateOrConnectWithoutChannelCreationSourcesInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type ServiceReminderCreateOrConnectWithoutClinicPetInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutClinicPetInput;
};

export type ServiceReminderCreateOrConnectWithoutClinicPetParentInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutClinicPetParentInput;
};

export type ServiceReminderCreateOrConnectWithoutIntegrationInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutIntegrationInput;
};

export type ServiceReminderCreateOrConnectWithoutNotificationsInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutNotificationsInput;
};

export type ServiceReminderCreateOrConnectWithoutServiceInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutServiceInput;
};

export type ServiceReminderCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderListRelationFilter = {
  every?: Maybe<ServiceReminderWhereInput>;
  some?: Maybe<ServiceReminderWhereInput>;
  none?: Maybe<ServiceReminderWhereInput>;
};

export type ServiceReminderMaxAggregateOutputType = {
  __typename?: 'ServiceReminderMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  dateLastGiven?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
};

export type ServiceReminderMinAggregateOutputType = {
  __typename?: 'ServiceReminderMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  dateLastGiven?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
};

export type ServiceReminderNotification = {
  __typename?: 'ServiceReminderNotification';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded: Scalars['Boolean'];
  queueAt: Scalars['DateTime'];
  queued: Scalars['Boolean'];
  notificationType: ServiceReminderNotificationType;
  parentNotificationId?: Maybe<Scalars['String']>;
  serviceReminder: ServiceReminder;
  timing?: Maybe<ServiceReminderTiming>;
  formSubmission?: Maybe<FormSubmission>;
  bundledNotifications: Array<ServiceReminderNotification>;
  parentNotification?: Maybe<ServiceReminderNotification>;
  _count: ServiceReminderNotificationCountOutputType;
};


export type ServiceReminderNotificationBundledNotificationsArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderNotificationScalarFieldEnum>;
};

export type ServiceReminderNotificationCountAggregateOutputType = {
  __typename?: 'ServiceReminderNotificationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  serviceReminderId: Scalars['Int'];
  timingId: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  hasResponded: Scalars['Int'];
  queueAt: Scalars['Int'];
  queued: Scalars['Int'];
  notificationType: Scalars['Int'];
  parentNotificationId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ServiceReminderNotificationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  timingId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  parentNotificationId?: Maybe<SortOrder>;
};

export type ServiceReminderNotificationCountOutputType = {
  __typename?: 'ServiceReminderNotificationCountOutputType';
  bundledNotifications: Scalars['Int'];
};

export type ServiceReminderNotificationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationCreateManyFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope = {
  data: Array<ServiceReminderNotificationCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderNotificationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationCreateManyParentNotificationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
};

export type ServiceReminderNotificationCreateManyParentNotificationInputEnvelope = {
  data: Array<ServiceReminderNotificationCreateManyParentNotificationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderNotificationCreateManyServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationCreateManyServiceReminderInputEnvelope = {
  data: Array<ServiceReminderNotificationCreateManyServiceReminderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderNotificationCreateManyTimingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationCreateManyTimingInputEnvelope = {
  data: Array<ServiceReminderNotificationCreateManyTimingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutParentNotificationInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyParentNotificationInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationCreateNestedManyWithoutTimingInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutTimingInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutTimingInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyTimingInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput = {
  create?: Maybe<ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput>;
  connectOrCreate?: Maybe<ServiceReminderNotificationCreateOrConnectWithoutBundledNotificationsInput>;
  connect?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
};

export type ServiceReminderNotificationCreateOrConnectWithoutBundledNotificationsInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput;
};

export type ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutFormSubmissionInput;
};

export type ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutParentNotificationInput;
};

export type ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutServiceReminderInput;
};

export type ServiceReminderNotificationCreateOrConnectWithoutTimingInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutTimingInput;
};

export type ServiceReminderNotificationCreateWithoutBundledNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationCreateWithoutParentNotificationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
};

export type ServiceReminderNotificationCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationCreateWithoutTimingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationListRelationFilter = {
  every?: Maybe<ServiceReminderNotificationWhereInput>;
  some?: Maybe<ServiceReminderNotificationWhereInput>;
  none?: Maybe<ServiceReminderNotificationWhereInput>;
};

export type ServiceReminderNotificationMaxAggregateOutputType = {
  __typename?: 'ServiceReminderNotificationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  timingId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  parentNotificationId?: Maybe<SortOrder>;
};

export type ServiceReminderNotificationMinAggregateOutputType = {
  __typename?: 'ServiceReminderNotificationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  timingId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  parentNotificationId?: Maybe<SortOrder>;
};

export type ServiceReminderNotificationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ServiceReminderNotificationOrderByRelevanceFieldEnum {
  Id = 'id',
  ServiceReminderId = 'serviceReminderId',
  TimingId = 'timingId',
  FormSubmissionId = 'formSubmissionId',
  ParentNotificationId = 'parentNotificationId'
}

export type ServiceReminderNotificationOrderByRelevanceInput = {
  fields: Array<ServiceReminderNotificationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ServiceReminderNotificationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  timingId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  parentNotificationId?: Maybe<SortOrder>;
  _count?: Maybe<ServiceReminderNotificationCountOrderByAggregateInput>;
  _max?: Maybe<ServiceReminderNotificationMaxOrderByAggregateInput>;
  _min?: Maybe<ServiceReminderNotificationMinOrderByAggregateInput>;
};

export type ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  timingId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  parentNotificationId?: Maybe<SortOrder>;
  serviceReminder?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  timing?: Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  bundledNotifications?: Maybe<ServiceReminderNotificationOrderByRelationAggregateInput>;
  parentNotification?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ServiceReminderNotificationOrderByRelevanceInput>;
};

export type ServiceReminderNotificationRelationFilter = {
  is?: Maybe<ServiceReminderNotificationWhereInput>;
  isNot?: Maybe<ServiceReminderNotificationWhereInput>;
};

export enum ServiceReminderNotificationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ServiceReminderId = 'serviceReminderId',
  TimingId = 'timingId',
  FormSubmissionId = 'formSubmissionId',
  HasResponded = 'hasResponded',
  QueueAt = 'queueAt',
  Queued = 'queued',
  NotificationType = 'notificationType',
  ParentNotificationId = 'parentNotificationId'
}

export type ServiceReminderNotificationScalarWhereInput = {
  AND?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  OR?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceReminderId?: Maybe<StringFilter>;
  timingId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  hasResponded?: Maybe<BoolFilter>;
  queueAt?: Maybe<DateTimeFilter>;
  queued?: Maybe<BoolFilter>;
  notificationType?: Maybe<EnumServiceReminderNotificationTypeFilter>;
  parentNotificationId?: Maybe<StringNullableFilter>;
};

export type ServiceReminderNotificationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ServiceReminderNotificationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ServiceReminderNotificationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ServiceReminderNotificationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  serviceReminderId?: Maybe<StringWithAggregatesFilter>;
  timingId?: Maybe<StringNullableWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  hasResponded?: Maybe<BoolWithAggregatesFilter>;
  queueAt?: Maybe<DateTimeWithAggregatesFilter>;
  queued?: Maybe<BoolWithAggregatesFilter>;
  notificationType?: Maybe<EnumServiceReminderNotificationTypeWithAggregatesFilter>;
  parentNotificationId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum ServiceReminderNotificationType {
  Digital = 'Digital',
  Postcard = 'Postcard'
}

export type ServiceReminderNotificationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
};

export type ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutParentNotificationInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyParentNotificationInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationUncheckedCreateNestedManyWithoutTimingInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutTimingInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutTimingInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyTimingInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutParentNotificationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutTimingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
};

export type ServiceReminderNotificationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationNestedInput>;
};

export type ServiceReminderNotificationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutBundledNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationNestedInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutParentNotificationInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutParentNotificationInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyParentNotificationInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutParentNotificationInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutParentNotificationInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutTimingNestedInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutTimingInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutTimingInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutTimingInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyTimingInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutTimingInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutTimingInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutBundledNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationNestedInput>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutParentNotificationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationNestedInput>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationNestedInput>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutTimingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationNestedInput>;
};

export type ServiceReminderNotificationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsNestedInput>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsNestedInput>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationNestedInput>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsNestedInput>;
};

export type ServiceReminderNotificationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
};

export type ServiceReminderNotificationUpdateManyWithWhereWithoutFormSubmissionInput = {
  where: ServiceReminderNotificationScalarWhereInput;
  data: ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNotificationsInput;
};

export type ServiceReminderNotificationUpdateManyWithWhereWithoutParentNotificationInput = {
  where: ServiceReminderNotificationScalarWhereInput;
  data: ServiceReminderNotificationUncheckedUpdateManyWithoutBundledNotificationsInput;
};

export type ServiceReminderNotificationUpdateManyWithWhereWithoutServiceReminderInput = {
  where: ServiceReminderNotificationScalarWhereInput;
  data: ServiceReminderNotificationUncheckedUpdateManyWithoutNotificationsInput;
};

export type ServiceReminderNotificationUpdateManyWithWhereWithoutTimingInput = {
  where: ServiceReminderNotificationScalarWhereInput;
  data: ServiceReminderNotificationUncheckedUpdateManyWithoutNotificationsInput;
};

export type ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUpdateManyWithoutParentNotificationNestedInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutParentNotificationInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutParentNotificationInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyParentNotificationInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutParentNotificationInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutParentNotificationInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUpdateManyWithoutTimingNestedInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutTimingInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutTimingInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutTimingInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyTimingInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutTimingInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutTimingInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUpdateOneWithoutBundledNotificationsNestedInput = {
  create?: Maybe<ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput>;
  connectOrCreate?: Maybe<ServiceReminderNotificationCreateOrConnectWithoutBundledNotificationsInput>;
  upsert?: Maybe<ServiceReminderNotificationUpsertWithoutBundledNotificationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  update?: Maybe<ServiceReminderNotificationUncheckedUpdateWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  data: ServiceReminderNotificationUncheckedUpdateWithoutFormSubmissionInput;
};

export type ServiceReminderNotificationUpdateWithWhereUniqueWithoutParentNotificationInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  data: ServiceReminderNotificationUncheckedUpdateWithoutParentNotificationInput;
};

export type ServiceReminderNotificationUpdateWithWhereUniqueWithoutServiceReminderInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  data: ServiceReminderNotificationUncheckedUpdateWithoutServiceReminderInput;
};

export type ServiceReminderNotificationUpdateWithWhereUniqueWithoutTimingInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  data: ServiceReminderNotificationUncheckedUpdateWithoutTimingInput;
};

export type ServiceReminderNotificationUpdateWithoutBundledNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsNestedInput>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsNestedInput>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsNestedInput>;
};

export type ServiceReminderNotificationUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsNestedInput>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsNestedInput>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationNestedInput>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsNestedInput>;
};

export type ServiceReminderNotificationUpdateWithoutParentNotificationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsNestedInput>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsNestedInput>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationNestedInput>;
};

export type ServiceReminderNotificationUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsNestedInput>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationNestedInput>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsNestedInput>;
};

export type ServiceReminderNotificationUpdateWithoutTimingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsNestedInput>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationNestedInput>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsNestedInput>;
};

export type ServiceReminderNotificationUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  update: ServiceReminderNotificationUncheckedUpdateWithoutFormSubmissionInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutFormSubmissionInput;
};

export type ServiceReminderNotificationUpsertWithWhereUniqueWithoutParentNotificationInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  update: ServiceReminderNotificationUncheckedUpdateWithoutParentNotificationInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutParentNotificationInput;
};

export type ServiceReminderNotificationUpsertWithWhereUniqueWithoutServiceReminderInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  update: ServiceReminderNotificationUncheckedUpdateWithoutServiceReminderInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutServiceReminderInput;
};

export type ServiceReminderNotificationUpsertWithWhereUniqueWithoutTimingInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  update: ServiceReminderNotificationUncheckedUpdateWithoutTimingInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutTimingInput;
};

export type ServiceReminderNotificationUpsertWithoutBundledNotificationsInput = {
  update: ServiceReminderNotificationUncheckedUpdateWithoutBundledNotificationsInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput;
};

export type ServiceReminderNotificationWhereInput = {
  AND?: Maybe<Array<ServiceReminderNotificationWhereInput>>;
  OR?: Maybe<Array<ServiceReminderNotificationWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderNotificationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceReminderId?: Maybe<StringFilter>;
  timingId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  hasResponded?: Maybe<BoolFilter>;
  queueAt?: Maybe<DateTimeFilter>;
  queued?: Maybe<BoolFilter>;
  notificationType?: Maybe<EnumServiceReminderNotificationTypeFilter>;
  parentNotificationId?: Maybe<StringNullableFilter>;
  serviceReminder?: Maybe<ServiceReminderWhereInput>;
  timing?: Maybe<ServiceReminderTimingWhereInput>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  bundledNotifications?: Maybe<ServiceReminderNotificationListRelationFilter>;
  parentNotification?: Maybe<ServiceReminderNotificationWhereInput>;
};

export type ServiceReminderNotificationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ServiceReminderOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ServiceReminderOrderByRelevanceFieldEnum {
  Id = 'id',
  ServiceId = 'serviceId',
  PimsId = 'pimsId',
  ClinicPetId = 'clinicPetId',
  ClinicPetParentId = 'clinicPetParentId',
  IntegrationId = 'integrationId'
}

export type ServiceReminderOrderByRelevanceInput = {
  fields: Array<ServiceReminderOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ServiceReminderOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  dateLastGiven?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ServiceReminderCountOrderByAggregateInput>;
  _max?: Maybe<ServiceReminderMaxOrderByAggregateInput>;
  _min?: Maybe<ServiceReminderMinOrderByAggregateInput>;
};

export type ServiceReminderOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  dateLastGiven?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  notifications?: Maybe<ServiceReminderNotificationOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  _relevance?: Maybe<ServiceReminderOrderByRelevanceInput>;
};

export type ServiceReminderPimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type ServiceReminderRelationFilter = {
  is?: Maybe<ServiceReminderWhereInput>;
  isNot?: Maybe<ServiceReminderWhereInput>;
};

export enum ServiceReminderScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ServiceId = 'serviceId',
  DueDate = 'dueDate',
  DateLastGiven = 'dateLastGiven',
  PimsId = 'pimsId',
  IsDeleted = 'isDeleted',
  ClinicPetId = 'clinicPetId',
  ClinicPetParentId = 'clinicPetParentId',
  IntegrationId = 'integrationId',
  HasResponded = 'hasResponded',
  RawPimsValue = 'rawPimsValue'
}

export type ServiceReminderScalarWhereInput = {
  AND?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  OR?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceId?: Maybe<StringFilter>;
  dueDate?: Maybe<DateTimeFilter>;
  dateLastGiven?: Maybe<DateTimeNullableFilter>;
  pimsId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringFilter>;
  hasResponded?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ServiceReminderScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ServiceReminderScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ServiceReminderScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ServiceReminderScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  serviceId?: Maybe<StringWithAggregatesFilter>;
  dueDate?: Maybe<DateTimeWithAggregatesFilter>;
  dateLastGiven?: Maybe<DateTimeNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  hasResponded?: Maybe<BoolWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ServiceReminderTiming = {
  __typename?: 'ServiceReminderTiming';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  serviceId: Scalars['String'];
  timingType: ServiceReminderTimingType;
  offset: Scalars['Float'];
  offsetUnit: ServiceReminderTimingOffsetUnit;
  hourOfDay: Scalars['Int'];
  minuteOfHour: Scalars['Int'];
  isEmail: Scalars['Boolean'];
  isSms: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isPush: Scalars['Boolean'];
  service: Service;
  notifications: Array<ServiceReminderNotification>;
  _count: ServiceReminderTimingCountOutputType;
};


export type ServiceReminderTimingNotificationsArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderNotificationScalarFieldEnum>;
};

export type ServiceReminderTimingAvgAggregateOutputType = {
  __typename?: 'ServiceReminderTimingAvgAggregateOutputType';
  offset?: Maybe<Scalars['Float']>;
  hourOfDay?: Maybe<Scalars['Float']>;
  minuteOfHour?: Maybe<Scalars['Float']>;
};

export type ServiceReminderTimingAvgOrderByAggregateInput = {
  offset?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
};

export type ServiceReminderTimingCountAggregateOutputType = {
  __typename?: 'ServiceReminderTimingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  serviceId: Scalars['Int'];
  timingType: Scalars['Int'];
  offset: Scalars['Int'];
  offsetUnit: Scalars['Int'];
  hourOfDay: Scalars['Int'];
  minuteOfHour: Scalars['Int'];
  isEmail: Scalars['Int'];
  isSms: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isPush: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ServiceReminderTimingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  timingType?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPush?: Maybe<SortOrder>;
};

export type ServiceReminderTimingCountOutputType = {
  __typename?: 'ServiceReminderTimingCountOutputType';
  notifications: Scalars['Int'];
};

export type ServiceReminderTimingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  service: ServiceCreateNestedOneWithoutReminderTimingsInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutTimingInput>;
};

export type ServiceReminderTimingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingCreateManyServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingCreateManyServiceInputEnvelope = {
  data: Array<ServiceReminderTimingCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<ServiceReminderTimingCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderTimingCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderTimingCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
};

export type ServiceReminderTimingCreateNestedOneWithoutNotificationsInput = {
  create?: Maybe<ServiceReminderTimingUncheckedCreateWithoutNotificationsInput>;
  connectOrCreate?: Maybe<ServiceReminderTimingCreateOrConnectWithoutNotificationsInput>;
  connect?: Maybe<ServiceReminderTimingWhereUniqueInput>;
};

export type ServiceReminderTimingCreateOrConnectWithoutNotificationsInput = {
  where: ServiceReminderTimingWhereUniqueInput;
  create: ServiceReminderTimingUncheckedCreateWithoutNotificationsInput;
};

export type ServiceReminderTimingCreateOrConnectWithoutServiceInput = {
  where: ServiceReminderTimingWhereUniqueInput;
  create: ServiceReminderTimingUncheckedCreateWithoutServiceInput;
};

export type ServiceReminderTimingCreateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  service: ServiceCreateNestedOneWithoutReminderTimingsInput;
};

export type ServiceReminderTimingCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutTimingInput>;
};

export type ServiceReminderTimingDeliveryOptionInput = {
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingInput = {
  hourOfDay: Scalars['Int'];
  minuteOfHour: Scalars['Int'];
  offset: Scalars['Float'];
  offsetUnit: Scalars['String'];
  timingType: ServiceReminderTimingType;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingListRelationFilter = {
  every?: Maybe<ServiceReminderTimingWhereInput>;
  some?: Maybe<ServiceReminderTimingWhereInput>;
  none?: Maybe<ServiceReminderTimingWhereInput>;
};

export type ServiceReminderTimingMaxAggregateOutputType = {
  __typename?: 'ServiceReminderTimingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  timingType?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPush?: Maybe<SortOrder>;
};

export type ServiceReminderTimingMinAggregateOutputType = {
  __typename?: 'ServiceReminderTimingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  timingType?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPush?: Maybe<SortOrder>;
};

export enum ServiceReminderTimingOffsetUnit {
  Months = 'Months',
  Weeks = 'Weeks',
  Days = 'Days'
}

export type ServiceReminderTimingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ServiceReminderTimingOrderByRelevanceFieldEnum {
  Id = 'id',
  ServiceId = 'serviceId'
}

export type ServiceReminderTimingOrderByRelevanceInput = {
  fields: Array<ServiceReminderTimingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ServiceReminderTimingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  timingType?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPush?: Maybe<SortOrder>;
  _count?: Maybe<ServiceReminderTimingCountOrderByAggregateInput>;
  _avg?: Maybe<ServiceReminderTimingAvgOrderByAggregateInput>;
  _max?: Maybe<ServiceReminderTimingMaxOrderByAggregateInput>;
  _min?: Maybe<ServiceReminderTimingMinOrderByAggregateInput>;
  _sum?: Maybe<ServiceReminderTimingSumOrderByAggregateInput>;
};

export type ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  timingType?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPush?: Maybe<SortOrder>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  notifications?: Maybe<ServiceReminderNotificationOrderByRelationAggregateInput>;
  _relevance?: Maybe<ServiceReminderTimingOrderByRelevanceInput>;
};

export type ServiceReminderTimingRelationFilter = {
  is?: Maybe<ServiceReminderTimingWhereInput>;
  isNot?: Maybe<ServiceReminderTimingWhereInput>;
};

export enum ServiceReminderTimingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ServiceId = 'serviceId',
  TimingType = 'timingType',
  Offset = 'offset',
  OffsetUnit = 'offsetUnit',
  HourOfDay = 'hourOfDay',
  MinuteOfHour = 'minuteOfHour',
  IsEmail = 'isEmail',
  IsSms = 'isSms',
  IsDeleted = 'isDeleted',
  IsPush = 'isPush'
}

export type ServiceReminderTimingScalarWhereInput = {
  AND?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
  OR?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceId?: Maybe<StringFilter>;
  timingType?: Maybe<EnumServiceReminderTimingTypeFilter>;
  offset?: Maybe<FloatFilter>;
  offsetUnit?: Maybe<EnumServiceReminderTimingOffsetUnitFilter>;
  hourOfDay?: Maybe<IntFilter>;
  minuteOfHour?: Maybe<IntFilter>;
  isEmail?: Maybe<BoolFilter>;
  isSms?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isPush?: Maybe<BoolFilter>;
};

export type ServiceReminderTimingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ServiceReminderTimingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ServiceReminderTimingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ServiceReminderTimingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  serviceId?: Maybe<StringWithAggregatesFilter>;
  timingType?: Maybe<EnumServiceReminderTimingTypeWithAggregatesFilter>;
  offset?: Maybe<FloatWithAggregatesFilter>;
  offsetUnit?: Maybe<EnumServiceReminderTimingOffsetUnitWithAggregatesFilter>;
  hourOfDay?: Maybe<IntWithAggregatesFilter>;
  minuteOfHour?: Maybe<IntWithAggregatesFilter>;
  isEmail?: Maybe<BoolWithAggregatesFilter>;
  isSms?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  isPush?: Maybe<BoolWithAggregatesFilter>;
};

export type ServiceReminderTimingSumAggregateOutputType = {
  __typename?: 'ServiceReminderTimingSumAggregateOutputType';
  offset?: Maybe<Scalars['Float']>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
};

export type ServiceReminderTimingSumOrderByAggregateInput = {
  offset?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
};

export enum ServiceReminderTimingType {
  BeforeDue = 'BeforeDue',
  PastDue = 'PastDue'
}

export type ServiceReminderTimingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutTimingInput>;
};

export type ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<ServiceReminderTimingCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderTimingCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderTimingCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
};

export type ServiceReminderTimingUncheckedCreateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUncheckedCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutTimingInput>;
};

export type ServiceReminderTimingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutTimingNestedInput>;
};

export type ServiceReminderTimingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUncheckedUpdateManyWithoutReminderTimingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput = {
  create?: Maybe<Array<ServiceReminderTimingCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderTimingCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<ServiceReminderTimingUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderTimingCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderTimingUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<ServiceReminderTimingUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
};

export type ServiceReminderTimingUncheckedUpdateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUncheckedUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutTimingNestedInput>;
};

export type ServiceReminderTimingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutReminderTimingsNestedInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutTimingNestedInput>;
};

export type ServiceReminderTimingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUpdateManyWithWhereWithoutServiceInput = {
  where: ServiceReminderTimingScalarWhereInput;
  data: ServiceReminderTimingUncheckedUpdateManyWithoutReminderTimingsInput;
};

export type ServiceReminderTimingUpdateManyWithoutServiceNestedInput = {
  create?: Maybe<Array<ServiceReminderTimingCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderTimingCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<ServiceReminderTimingUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderTimingCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderTimingUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<ServiceReminderTimingUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
};

export type ServiceReminderTimingUpdateOneWithoutNotificationsNestedInput = {
  create?: Maybe<ServiceReminderTimingUncheckedCreateWithoutNotificationsInput>;
  connectOrCreate?: Maybe<ServiceReminderTimingCreateOrConnectWithoutNotificationsInput>;
  upsert?: Maybe<ServiceReminderTimingUpsertWithoutNotificationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  update?: Maybe<ServiceReminderTimingUncheckedUpdateWithoutNotificationsInput>;
};

export type ServiceReminderTimingUpdateWithWhereUniqueWithoutServiceInput = {
  where: ServiceReminderTimingWhereUniqueInput;
  data: ServiceReminderTimingUncheckedUpdateWithoutServiceInput;
};

export type ServiceReminderTimingUpdateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutReminderTimingsNestedInput>;
};

export type ServiceReminderTimingUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutTimingNestedInput>;
};

export type ServiceReminderTimingUpsertWithWhereUniqueWithoutServiceInput = {
  where: ServiceReminderTimingWhereUniqueInput;
  update: ServiceReminderTimingUncheckedUpdateWithoutServiceInput;
  create: ServiceReminderTimingUncheckedCreateWithoutServiceInput;
};

export type ServiceReminderTimingUpsertWithoutNotificationsInput = {
  update: ServiceReminderTimingUncheckedUpdateWithoutNotificationsInput;
  create: ServiceReminderTimingUncheckedCreateWithoutNotificationsInput;
};

export type ServiceReminderTimingWhereInput = {
  AND?: Maybe<Array<ServiceReminderTimingWhereInput>>;
  OR?: Maybe<Array<ServiceReminderTimingWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderTimingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceId?: Maybe<StringFilter>;
  timingType?: Maybe<EnumServiceReminderTimingTypeFilter>;
  offset?: Maybe<FloatFilter>;
  offsetUnit?: Maybe<EnumServiceReminderTimingOffsetUnitFilter>;
  hourOfDay?: Maybe<IntFilter>;
  minuteOfHour?: Maybe<IntFilter>;
  isEmail?: Maybe<BoolFilter>;
  isSms?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isPush?: Maybe<BoolFilter>;
  service?: Maybe<ServiceWhereInput>;
  notifications?: Maybe<ServiceReminderNotificationListRelationFilter>;
};

export type ServiceReminderTimingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ServiceReminderUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
};

export type ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
};

export type ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceReminderCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
};

export type ServiceReminderUncheckedCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
};

export type ServiceReminderUncheckedCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
};

export type ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
};

export type ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceReminderCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
};

export type ServiceReminderUncheckedUpdateManyWithoutRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
};

export type ServiceReminderUncheckedUpdateManyWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderUncheckedUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUncheckedUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUncheckedUpdateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUncheckedUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderUpdateManyWithWhereWithoutClinicPetInput = {
  where: ServiceReminderScalarWhereInput;
  data: ServiceReminderUncheckedUpdateManyWithoutServiceRemindersInput;
};

export type ServiceReminderUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ServiceReminderScalarWhereInput;
  data: ServiceReminderUncheckedUpdateManyWithoutServiceRemindersInput;
};

export type ServiceReminderUpdateManyWithWhereWithoutIntegrationInput = {
  where: ServiceReminderScalarWhereInput;
  data: ServiceReminderUncheckedUpdateManyWithoutServiceRemindersInput;
};

export type ServiceReminderUpdateManyWithWhereWithoutServiceInput = {
  where: ServiceReminderScalarWhereInput;
  data: ServiceReminderUncheckedUpdateManyWithoutRemindersInput;
};

export type ServiceReminderUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
};

export type ServiceReminderUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
};

export type ServiceReminderUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceReminderCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
};

export type ServiceReminderUpdateManyWithoutServiceNestedInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
};

export type ServiceReminderUpdateOneRequiredWithoutNotificationsNestedInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutNotificationsInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutNotificationsInput>;
  upsert?: Maybe<ServiceReminderUpsertWithoutNotificationsInput>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  update?: Maybe<ServiceReminderUncheckedUpdateWithoutNotificationsInput>;
};

export type ServiceReminderUpdateOneWithoutAutomationRunsNestedInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutAutomationRunsInput>;
  upsert?: Maybe<ServiceReminderUpsertWithoutAutomationRunsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  update?: Maybe<ServiceReminderUncheckedUpdateWithoutAutomationRunsInput>;
};

export type ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<ServiceReminderUpsertWithoutChannelAutomationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  update?: Maybe<ServiceReminderUncheckedUpdateWithoutChannelAutomationStatusesInput>;
};

export type ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<ServiceReminderUpsertWithoutChannelCreationSourcesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  update?: Maybe<ServiceReminderUncheckedUpdateWithoutChannelCreationSourcesInput>;
};

export type ServiceReminderUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: ServiceReminderWhereUniqueInput;
  data: ServiceReminderUncheckedUpdateWithoutClinicPetInput;
};

export type ServiceReminderUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ServiceReminderWhereUniqueInput;
  data: ServiceReminderUncheckedUpdateWithoutClinicPetParentInput;
};

export type ServiceReminderUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ServiceReminderWhereUniqueInput;
  data: ServiceReminderUncheckedUpdateWithoutIntegrationInput;
};

export type ServiceReminderUpdateWithWhereUniqueWithoutServiceInput = {
  where: ServiceReminderWhereUniqueInput;
  data: ServiceReminderUncheckedUpdateWithoutServiceInput;
};

export type ServiceReminderUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUpdateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
};

export type ServiceReminderUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: ServiceReminderWhereUniqueInput;
  update: ServiceReminderUncheckedUpdateWithoutClinicPetInput;
  create: ServiceReminderUncheckedCreateWithoutClinicPetInput;
};

export type ServiceReminderUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ServiceReminderWhereUniqueInput;
  update: ServiceReminderUncheckedUpdateWithoutClinicPetParentInput;
  create: ServiceReminderUncheckedCreateWithoutClinicPetParentInput;
};

export type ServiceReminderUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ServiceReminderWhereUniqueInput;
  update: ServiceReminderUncheckedUpdateWithoutIntegrationInput;
  create: ServiceReminderUncheckedCreateWithoutIntegrationInput;
};

export type ServiceReminderUpsertWithWhereUniqueWithoutServiceInput = {
  where: ServiceReminderWhereUniqueInput;
  update: ServiceReminderUncheckedUpdateWithoutServiceInput;
  create: ServiceReminderUncheckedCreateWithoutServiceInput;
};

export type ServiceReminderUpsertWithoutAutomationRunsInput = {
  update: ServiceReminderUncheckedUpdateWithoutAutomationRunsInput;
  create: ServiceReminderUncheckedCreateWithoutAutomationRunsInput;
};

export type ServiceReminderUpsertWithoutChannelAutomationStatusesInput = {
  update: ServiceReminderUncheckedUpdateWithoutChannelAutomationStatusesInput;
  create: ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type ServiceReminderUpsertWithoutChannelCreationSourcesInput = {
  update: ServiceReminderUncheckedUpdateWithoutChannelCreationSourcesInput;
  create: ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type ServiceReminderUpsertWithoutNotificationsInput = {
  update: ServiceReminderUncheckedUpdateWithoutNotificationsInput;
  create: ServiceReminderUncheckedCreateWithoutNotificationsInput;
};

export type ServiceReminderWhereInput = {
  AND?: Maybe<Array<ServiceReminderWhereInput>>;
  OR?: Maybe<Array<ServiceReminderWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceId?: Maybe<StringFilter>;
  dueDate?: Maybe<DateTimeFilter>;
  dateLastGiven?: Maybe<DateTimeNullableFilter>;
  pimsId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringFilter>;
  hasResponded?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  service?: Maybe<ServiceWhereInput>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  notifications?: Maybe<ServiceReminderNotificationListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
};

export type ServiceReminderWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsIdAndIntegrationId?: Maybe<ServiceReminderPimsIdAndIntegrationIdCompoundUniqueInput>;
};

export enum ServiceScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Name = 'name',
  Code = 'code',
  PimsName = 'pimsName',
  PimsId = 'pimsId',
  IsReminderActive = 'isReminderActive',
  ReminderActivationDate = 'reminderActivationDate',
  IntegrationId = 'integrationId',
  FormTemplateId = 'formTemplateId',
  IsDeleted = 'isDeleted',
  HasConfiguredReminders = 'hasConfiguredReminders',
  RawPimsValue = 'rawPimsValue',
  IsPetPortal = 'isPetPortal',
  IsPostcard = 'isPostcard',
  CanonicalServiceId = 'canonicalServiceId',
  ServiceCategoryId = 'serviceCategoryId',
  VetsourceRevenueCategoryName = 'vetsourceRevenueCategoryName'
}

export type ServiceScalarWhereInput = {
  AND?: Maybe<Array<ServiceScalarWhereInput>>;
  OR?: Maybe<Array<ServiceScalarWhereInput>>;
  NOT?: Maybe<Array<ServiceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  code?: Maybe<StringFilter>;
  pimsName?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  isReminderActive?: Maybe<BoolFilter>;
  reminderActivationDate?: Maybe<DateTimeNullableFilter>;
  integrationId?: Maybe<StringFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  hasConfiguredReminders?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  isPetPortal?: Maybe<BoolFilter>;
  isPostcard?: Maybe<BoolFilter>;
  canonicalServiceId?: Maybe<StringNullableFilter>;
  serviceCategoryId?: Maybe<StringNullableFilter>;
  vetsourceRevenueCategoryName?: Maybe<StringNullableFilter>;
};

export type ServiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ServiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ServiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ServiceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  code?: Maybe<StringWithAggregatesFilter>;
  pimsName?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  isReminderActive?: Maybe<BoolWithAggregatesFilter>;
  reminderActivationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  formTemplateId?: Maybe<StringNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  hasConfiguredReminders?: Maybe<BoolWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  isPetPortal?: Maybe<BoolWithAggregatesFilter>;
  isPostcard?: Maybe<BoolWithAggregatesFilter>;
  canonicalServiceId?: Maybe<StringNullableWithAggregatesFilter>;
  serviceCategoryId?: Maybe<StringNullableWithAggregatesFilter>;
  vetsourceRevenueCategoryName?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ServiceServiceCodeAndIntegrationIdCompoundUniqueInput = {
  code: Scalars['String'];
  integrationId: Scalars['String'];
};

export type ServiceServicePimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type ServiceUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput = {
  create?: Maybe<Array<ServiceCreateWithoutCanonicalServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  createMany?: Maybe<ServiceCreateManyCanonicalServiceInputEnvelope>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceUncheckedCreateNestedManyWithoutFormTemplateInput = {
  create?: Maybe<Array<ServiceCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutFormTemplateInput>>;
  createMany?: Maybe<ServiceCreateManyFormTemplateInputEnvelope>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ServiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceUncheckedCreateNestedManyWithoutServiceCategoryInput = {
  create?: Maybe<Array<ServiceCreateWithoutServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutServiceCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyServiceCategoryInputEnvelope>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceUncheckedCreateNestedManyWithoutVetsourceCodeTagsInput = {
  create?: Maybe<Array<ServiceCreateWithoutVetsourceCodeTagsInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceCodeTagsInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceUncheckedCreateNestedManyWithoutVetsourceRevenueCategoryInput = {
  create?: Maybe<Array<ServiceCreateWithoutVetsourceRevenueCategoryInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyVetsourceRevenueCategoryInputEnvelope>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceUncheckedCreateWithoutCanonicalServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutLoyaltyPointGrantingServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutReminderTimingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutServiceCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutVetsourceCodeTagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput = {
  create?: Maybe<Array<ServiceCreateWithoutCanonicalServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutCanonicalServiceInput>>;
  createMany?: Maybe<ServiceCreateManyCanonicalServiceInputEnvelope>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutCanonicalServiceInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutCanonicalServiceInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUncheckedUpdateManyWithoutChildServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateManyWithoutFormTemplateNestedInput = {
  create?: Maybe<Array<ServiceCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutFormTemplateInput>>;
  createMany?: Maybe<ServiceCreateManyFormTemplateInputEnvelope>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutFormTemplateInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ServiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUncheckedUpdateManyWithoutServiceCategoryNestedInput = {
  create?: Maybe<Array<ServiceCreateWithoutServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutServiceCategoryInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutServiceCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyServiceCategoryInputEnvelope>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutServiceCategoryInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutServiceCategoryInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUncheckedUpdateManyWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateManyWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateManyWithoutVetsourceCodeTagsNestedInput = {
  create?: Maybe<Array<ServiceCreateWithoutVetsourceCodeTagsInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceCodeTagsInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutVetsourceCodeTagsInput>>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutVetsourceCodeTagsInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutVetsourceCodeTagsInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUncheckedUpdateManyWithoutVetsourceRevenueCategoryNestedInput = {
  create?: Maybe<Array<ServiceCreateWithoutVetsourceRevenueCategoryInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceRevenueCategoryInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyVetsourceRevenueCategoryInputEnvelope>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutVetsourceRevenueCategoryInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutVetsourceRevenueCategoryInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUncheckedUpdateWithoutCanonicalServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUncheckedUpdateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUncheckedUpdateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUncheckedUpdateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUncheckedUpdateWithoutLoyaltyPointGrantingServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUncheckedUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUncheckedUpdateWithoutReminderTimingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUncheckedUpdateWithoutRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUncheckedUpdateWithoutServiceCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUncheckedUpdateWithoutVetsourceCodeTagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUncheckedUpdateWithoutVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
};

export type ServiceUpdateManyWithWhereWithoutCanonicalServiceInput = {
  where: ServiceScalarWhereInput;
  data: ServiceUncheckedUpdateManyWithoutServicesInput;
};

export type ServiceUpdateManyWithWhereWithoutFormTemplateInput = {
  where: ServiceScalarWhereInput;
  data: ServiceUncheckedUpdateManyWithoutServicesInput;
};

export type ServiceUpdateManyWithWhereWithoutIntegrationInput = {
  where: ServiceScalarWhereInput;
  data: ServiceUncheckedUpdateManyWithoutServicesInput;
};

export type ServiceUpdateManyWithWhereWithoutServiceCategoryInput = {
  where: ServiceScalarWhereInput;
  data: ServiceUncheckedUpdateManyWithoutChildServicesInput;
};

export type ServiceUpdateManyWithWhereWithoutVetsourceCodeTagsInput = {
  where: ServiceScalarWhereInput;
  data: ServiceUncheckedUpdateManyWithoutServiceInput;
};

export type ServiceUpdateManyWithWhereWithoutVetsourceRevenueCategoryInput = {
  where: ServiceScalarWhereInput;
  data: ServiceUncheckedUpdateManyWithoutServiceInput;
};

export type ServiceUpdateManyWithoutCanonicalServiceNestedInput = {
  create?: Maybe<Array<ServiceCreateWithoutCanonicalServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutCanonicalServiceInput>>;
  createMany?: Maybe<ServiceCreateManyCanonicalServiceInputEnvelope>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutCanonicalServiceInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutCanonicalServiceInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUpdateManyWithoutFormTemplateNestedInput = {
  create?: Maybe<Array<ServiceCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutFormTemplateInput>>;
  createMany?: Maybe<ServiceCreateManyFormTemplateInputEnvelope>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutFormTemplateInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<ServiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUpdateManyWithoutServiceCategoryNestedInput = {
  create?: Maybe<Array<ServiceCreateWithoutServiceCategoryInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutServiceCategoryInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutServiceCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyServiceCategoryInputEnvelope>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutServiceCategoryInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutServiceCategoryInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUpdateManyWithoutVetsourceCodeTagsNestedInput = {
  create?: Maybe<Array<ServiceCreateWithoutVetsourceCodeTagsInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceCodeTagsInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutVetsourceCodeTagsInput>>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutVetsourceCodeTagsInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutVetsourceCodeTagsInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUpdateManyWithoutVetsourceRevenueCategoryNestedInput = {
  create?: Maybe<Array<ServiceCreateWithoutVetsourceRevenueCategoryInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceRevenueCategoryInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyVetsourceRevenueCategoryInputEnvelope>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutVetsourceRevenueCategoryInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutVetsourceRevenueCategoryInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUpdateOneRequiredWithoutLoyaltyPointGrantingServicesNestedInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutLoyaltyPointGrantingServicesInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutLoyaltyPointGrantingServicesInput>;
  upsert?: Maybe<ServiceUpsertWithoutLoyaltyPointGrantingServicesInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutLoyaltyPointGrantingServicesInput>;
};

export type ServiceUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceNestedInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput>;
  upsert?: Maybe<ServiceUpsertWithoutOrganizationCareBenefitToClinicServiceInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput>;
};

export type ServiceUpdateOneRequiredWithoutReminderTimingsNestedInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutReminderTimingsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutReminderTimingsInput>;
  upsert?: Maybe<ServiceUpsertWithoutReminderTimingsInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutReminderTimingsInput>;
};

export type ServiceUpdateOneRequiredWithoutRemindersNestedInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutRemindersInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutRemindersInput>;
  upsert?: Maybe<ServiceUpsertWithoutRemindersInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutRemindersInput>;
};

export type ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsNestedInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  upsert?: Maybe<ServiceUpsertWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceWhereUniqueInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type ServiceUpdateOneWithoutInvoiceLineItemsNestedInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutInvoiceLineItemsInput>;
  upsert?: Maybe<ServiceUpsertWithoutInvoiceLineItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceWhereUniqueInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutInvoiceLineItemsInput>;
};

export type ServiceUpdateOneWithoutPrescriptionsNestedInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutPrescriptionsInput>;
  upsert?: Maybe<ServiceUpsertWithoutPrescriptionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceWhereUniqueInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutPrescriptionsInput>;
};

export type ServiceUpdateWithWhereUniqueWithoutCanonicalServiceInput = {
  where: ServiceWhereUniqueInput;
  data: ServiceUncheckedUpdateWithoutCanonicalServiceInput;
};

export type ServiceUpdateWithWhereUniqueWithoutFormTemplateInput = {
  where: ServiceWhereUniqueInput;
  data: ServiceUncheckedUpdateWithoutFormTemplateInput;
};

export type ServiceUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ServiceWhereUniqueInput;
  data: ServiceUncheckedUpdateWithoutIntegrationInput;
};

export type ServiceUpdateWithWhereUniqueWithoutServiceCategoryInput = {
  where: ServiceWhereUniqueInput;
  data: ServiceUncheckedUpdateWithoutServiceCategoryInput;
};

export type ServiceUpdateWithWhereUniqueWithoutVetsourceCodeTagsInput = {
  where: ServiceWhereUniqueInput;
  data: ServiceUncheckedUpdateWithoutVetsourceCodeTagsInput;
};

export type ServiceUpdateWithWhereUniqueWithoutVetsourceRevenueCategoryInput = {
  where: ServiceWhereUniqueInput;
  data: ServiceUncheckedUpdateWithoutVetsourceRevenueCategoryInput;
};

export type ServiceUpdateWithoutCanonicalServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutLoyaltyPointGrantingServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutReminderTimingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutServiceCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutVetsourceCodeTagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpsertWithWhereUniqueWithoutCanonicalServiceInput = {
  where: ServiceWhereUniqueInput;
  update: ServiceUncheckedUpdateWithoutCanonicalServiceInput;
  create: ServiceUncheckedCreateWithoutCanonicalServiceInput;
};

export type ServiceUpsertWithWhereUniqueWithoutFormTemplateInput = {
  where: ServiceWhereUniqueInput;
  update: ServiceUncheckedUpdateWithoutFormTemplateInput;
  create: ServiceUncheckedCreateWithoutFormTemplateInput;
};

export type ServiceUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ServiceWhereUniqueInput;
  update: ServiceUncheckedUpdateWithoutIntegrationInput;
  create: ServiceUncheckedCreateWithoutIntegrationInput;
};

export type ServiceUpsertWithWhereUniqueWithoutServiceCategoryInput = {
  where: ServiceWhereUniqueInput;
  update: ServiceUncheckedUpdateWithoutServiceCategoryInput;
  create: ServiceUncheckedCreateWithoutServiceCategoryInput;
};

export type ServiceUpsertWithWhereUniqueWithoutVetsourceCodeTagsInput = {
  where: ServiceWhereUniqueInput;
  update: ServiceUncheckedUpdateWithoutVetsourceCodeTagsInput;
  create: ServiceUncheckedCreateWithoutVetsourceCodeTagsInput;
};

export type ServiceUpsertWithWhereUniqueWithoutVetsourceRevenueCategoryInput = {
  where: ServiceWhereUniqueInput;
  update: ServiceUncheckedUpdateWithoutVetsourceRevenueCategoryInput;
  create: ServiceUncheckedCreateWithoutVetsourceRevenueCategoryInput;
};

export type ServiceUpsertWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  update: ServiceUncheckedUpdateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput;
  create: ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput;
};

export type ServiceUpsertWithoutInvoiceLineItemsInput = {
  update: ServiceUncheckedUpdateWithoutInvoiceLineItemsInput;
  create: ServiceUncheckedCreateWithoutInvoiceLineItemsInput;
};

export type ServiceUpsertWithoutLoyaltyPointGrantingServicesInput = {
  update: ServiceUncheckedUpdateWithoutLoyaltyPointGrantingServicesInput;
  create: ServiceUncheckedCreateWithoutLoyaltyPointGrantingServicesInput;
};

export type ServiceUpsertWithoutOrganizationCareBenefitToClinicServiceInput = {
  update: ServiceUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput;
  create: ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type ServiceUpsertWithoutPrescriptionsInput = {
  update: ServiceUncheckedUpdateWithoutPrescriptionsInput;
  create: ServiceUncheckedCreateWithoutPrescriptionsInput;
};

export type ServiceUpsertWithoutReminderTimingsInput = {
  update: ServiceUncheckedUpdateWithoutReminderTimingsInput;
  create: ServiceUncheckedCreateWithoutReminderTimingsInput;
};

export type ServiceUpsertWithoutRemindersInput = {
  update: ServiceUncheckedUpdateWithoutRemindersInput;
  create: ServiceUncheckedCreateWithoutRemindersInput;
};

export type ServiceWhereInput = {
  AND?: Maybe<Array<ServiceWhereInput>>;
  OR?: Maybe<Array<ServiceWhereInput>>;
  NOT?: Maybe<Array<ServiceWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  code?: Maybe<StringFilter>;
  pimsName?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  isReminderActive?: Maybe<BoolFilter>;
  reminderActivationDate?: Maybe<DateTimeNullableFilter>;
  integrationId?: Maybe<StringFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  hasConfiguredReminders?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  isPetPortal?: Maybe<BoolFilter>;
  isPostcard?: Maybe<BoolFilter>;
  canonicalServiceId?: Maybe<StringNullableFilter>;
  serviceCategoryId?: Maybe<StringNullableFilter>;
  vetsourceRevenueCategoryName?: Maybe<StringNullableFilter>;
  reminderTimings?: Maybe<ServiceReminderTimingListRelationFilter>;
  reminders?: Maybe<ServiceReminderListRelationFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  formTemplate?: Maybe<FormTemplateWhereInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  canonicalService?: Maybe<CanonicalServiceWhereInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceListRelationFilter>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceListRelationFilter>;
  serviceCategory?: Maybe<ServiceCategoryWhereInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryWhereInput>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagListRelationFilter>;
};

export type ServiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  serviceCodeAndIntegrationId?: Maybe<ServiceServiceCodeAndIntegrationIdCompoundUniqueInput>;
  servicePimsIdAndIntegrationId?: Maybe<ServiceServicePimsIdAndIntegrationIdCompoundUniqueInput>;
};

export type Sex = {
  __typename?: 'Sex';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  integrationId: Scalars['String'];
  speciesId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  integration: ClinicPimsIntegration;
  pets: Array<ClinicPet>;
  species?: Maybe<Species>;
  _count: SexCountOutputType;
};


export type SexPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};

export type SexCountAggregateOutputType = {
  __typename?: 'SexCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  integrationId: Scalars['Int'];
  speciesId: Scalars['Int'];
  pimsId: Scalars['Int'];
  name: Scalars['Int'];
  abbreviation: Scalars['Int'];
  isDesexed: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SexCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  abbreviation?: Maybe<SortOrder>;
  isDesexed?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type SexCountOutputType = {
  __typename?: 'SexCountOutputType';
  pets: Scalars['Int'];
};

export type SexCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutSexesInput;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutSexModelInput>;
  species?: Maybe<SpeciesCreateNestedOneWithoutSexesInput>;
};

export type SexCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  speciesId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type SexCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  speciesId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type SexCreateManyIntegrationInputEnvelope = {
  data: Array<SexCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SexCreateManySpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type SexCreateManySpeciesInputEnvelope = {
  data: Array<SexCreateManySpeciesInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SexCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<SexCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<SexCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<SexWhereUniqueInput>>;
};

export type SexCreateNestedManyWithoutSpeciesInput = {
  create?: Maybe<Array<SexCreateWithoutSpeciesInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutSpeciesInput>>;
  createMany?: Maybe<SexCreateManySpeciesInputEnvelope>;
  connect?: Maybe<Array<SexWhereUniqueInput>>;
};

export type SexCreateNestedOneWithoutPetsInput = {
  create?: Maybe<SexUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<SexCreateOrConnectWithoutPetsInput>;
  connect?: Maybe<SexWhereUniqueInput>;
};

export type SexCreateOrConnectWithoutIntegrationInput = {
  where: SexWhereUniqueInput;
  create: SexUncheckedCreateWithoutIntegrationInput;
};

export type SexCreateOrConnectWithoutPetsInput = {
  where: SexWhereUniqueInput;
  create: SexUncheckedCreateWithoutPetsInput;
};

export type SexCreateOrConnectWithoutSpeciesInput = {
  where: SexWhereUniqueInput;
  create: SexUncheckedCreateWithoutSpeciesInput;
};

export type SexCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutSexModelInput>;
  species?: Maybe<SpeciesCreateNestedOneWithoutSexesInput>;
};

export type SexCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutSexesInput;
  species?: Maybe<SpeciesCreateNestedOneWithoutSexesInput>;
};

export type SexCreateWithoutSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutSexesInput;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutSexModelInput>;
};

export type SexIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type SexListRelationFilter = {
  every?: Maybe<SexWhereInput>;
  some?: Maybe<SexWhereInput>;
  none?: Maybe<SexWhereInput>;
};

export type SexMaxAggregateOutputType = {
  __typename?: 'SexMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type SexMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  abbreviation?: Maybe<SortOrder>;
  isDesexed?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type SexMinAggregateOutputType = {
  __typename?: 'SexMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type SexMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  abbreviation?: Maybe<SortOrder>;
  isDesexed?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type SexOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum SexOrderByRelevanceFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  SpeciesId = 'speciesId',
  PimsId = 'pimsId',
  Name = 'name',
  Abbreviation = 'abbreviation'
}

export type SexOrderByRelevanceInput = {
  fields: Array<SexOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type SexOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  abbreviation?: Maybe<SortOrder>;
  isDesexed?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _count?: Maybe<SexCountOrderByAggregateInput>;
  _max?: Maybe<SexMaxOrderByAggregateInput>;
  _min?: Maybe<SexMinOrderByAggregateInput>;
};

export type SexOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  abbreviation?: Maybe<SortOrder>;
  isDesexed?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  species?: Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<SexOrderByRelevanceInput>;
};

export type SexRelationFilter = {
  is?: Maybe<SexWhereInput>;
  isNot?: Maybe<SexWhereInput>;
};

export enum SexScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IntegrationId = 'integrationId',
  SpeciesId = 'speciesId',
  PimsId = 'pimsId',
  Name = 'name',
  Abbreviation = 'abbreviation',
  IsDesexed = 'isDesexed',
  RawPimsValue = 'rawPimsValue',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted'
}

export type SexScalarWhereInput = {
  AND?: Maybe<Array<SexScalarWhereInput>>;
  OR?: Maybe<Array<SexScalarWhereInput>>;
  NOT?: Maybe<Array<SexScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  integrationId?: Maybe<StringFilter>;
  speciesId?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  abbreviation?: Maybe<StringNullableFilter>;
  isDesexed?: Maybe<BoolNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type SexScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SexScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SexScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SexScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  speciesId?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  abbreviation?: Maybe<StringNullableWithAggregatesFilter>;
  isDesexed?: Maybe<BoolNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
};

export type SexUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  speciesId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutSexModelInput>;
};

export type SexUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<SexCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<SexCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<SexWhereUniqueInput>>;
};

export type SexUncheckedCreateNestedManyWithoutSpeciesInput = {
  create?: Maybe<Array<SexCreateWithoutSpeciesInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutSpeciesInput>>;
  createMany?: Maybe<SexCreateManySpeciesInputEnvelope>;
  connect?: Maybe<Array<SexWhereUniqueInput>>;
};

export type SexUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  speciesId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutSexModelInput>;
};

export type SexUncheckedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  speciesId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type SexUncheckedCreateWithoutSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutSexModelInput>;
};

export type SexUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutSexModelNestedInput>;
};

export type SexUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type SexUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<SexCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<SexUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<SexCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<SexWhereUniqueInput>>;
  disconnect?: Maybe<Array<SexWhereUniqueInput>>;
  delete?: Maybe<Array<SexWhereUniqueInput>>;
  connect?: Maybe<Array<SexWhereUniqueInput>>;
  update?: Maybe<Array<SexUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<SexUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<SexScalarWhereInput>>;
};

export type SexUncheckedUpdateManyWithoutSexesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  speciesId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type SexUncheckedUpdateManyWithoutSpeciesNestedInput = {
  create?: Maybe<Array<SexCreateWithoutSpeciesInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutSpeciesInput>>;
  upsert?: Maybe<Array<SexUpsertWithWhereUniqueWithoutSpeciesInput>>;
  createMany?: Maybe<SexCreateManySpeciesInputEnvelope>;
  set?: Maybe<Array<SexWhereUniqueInput>>;
  disconnect?: Maybe<Array<SexWhereUniqueInput>>;
  delete?: Maybe<Array<SexWhereUniqueInput>>;
  connect?: Maybe<Array<SexWhereUniqueInput>>;
  update?: Maybe<Array<SexUpdateWithWhereUniqueWithoutSpeciesInput>>;
  updateMany?: Maybe<Array<SexUpdateManyWithWhereWithoutSpeciesInput>>;
  deleteMany?: Maybe<Array<SexScalarWhereInput>>;
};

export type SexUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  speciesId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutSexModelNestedInput>;
};

export type SexUncheckedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type SexUncheckedUpdateWithoutSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutSexModelNestedInput>;
};

export type SexUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutSexesNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutSexModelNestedInput>;
  species?: Maybe<SpeciesUpdateOneWithoutSexesNestedInput>;
};

export type SexUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type SexUpdateManyWithWhereWithoutIntegrationInput = {
  where: SexScalarWhereInput;
  data: SexUncheckedUpdateManyWithoutSexesInput;
};

export type SexUpdateManyWithWhereWithoutSpeciesInput = {
  where: SexScalarWhereInput;
  data: SexUncheckedUpdateManyWithoutSexesInput;
};

export type SexUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<SexCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<SexUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<SexCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<SexWhereUniqueInput>>;
  disconnect?: Maybe<Array<SexWhereUniqueInput>>;
  delete?: Maybe<Array<SexWhereUniqueInput>>;
  connect?: Maybe<Array<SexWhereUniqueInput>>;
  update?: Maybe<Array<SexUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<SexUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<SexScalarWhereInput>>;
};

export type SexUpdateManyWithoutSpeciesNestedInput = {
  create?: Maybe<Array<SexCreateWithoutSpeciesInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutSpeciesInput>>;
  upsert?: Maybe<Array<SexUpsertWithWhereUniqueWithoutSpeciesInput>>;
  createMany?: Maybe<SexCreateManySpeciesInputEnvelope>;
  set?: Maybe<Array<SexWhereUniqueInput>>;
  disconnect?: Maybe<Array<SexWhereUniqueInput>>;
  delete?: Maybe<Array<SexWhereUniqueInput>>;
  connect?: Maybe<Array<SexWhereUniqueInput>>;
  update?: Maybe<Array<SexUpdateWithWhereUniqueWithoutSpeciesInput>>;
  updateMany?: Maybe<Array<SexUpdateManyWithWhereWithoutSpeciesInput>>;
  deleteMany?: Maybe<Array<SexScalarWhereInput>>;
};

export type SexUpdateOneWithoutPetsNestedInput = {
  create?: Maybe<SexUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<SexCreateOrConnectWithoutPetsInput>;
  upsert?: Maybe<SexUpsertWithoutPetsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SexWhereUniqueInput>;
  update?: Maybe<SexUncheckedUpdateWithoutPetsInput>;
};

export type SexUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: SexWhereUniqueInput;
  data: SexUncheckedUpdateWithoutIntegrationInput;
};

export type SexUpdateWithWhereUniqueWithoutSpeciesInput = {
  where: SexWhereUniqueInput;
  data: SexUncheckedUpdateWithoutSpeciesInput;
};

export type SexUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutSexModelNestedInput>;
  species?: Maybe<SpeciesUpdateOneWithoutSexesNestedInput>;
};

export type SexUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutSexesNestedInput>;
  species?: Maybe<SpeciesUpdateOneWithoutSexesNestedInput>;
};

export type SexUpdateWithoutSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutSexesNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutSexModelNestedInput>;
};

export type SexUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: SexWhereUniqueInput;
  update: SexUncheckedUpdateWithoutIntegrationInput;
  create: SexUncheckedCreateWithoutIntegrationInput;
};

export type SexUpsertWithWhereUniqueWithoutSpeciesInput = {
  where: SexWhereUniqueInput;
  update: SexUncheckedUpdateWithoutSpeciesInput;
  create: SexUncheckedCreateWithoutSpeciesInput;
};

export type SexUpsertWithoutPetsInput = {
  update: SexUncheckedUpdateWithoutPetsInput;
  create: SexUncheckedCreateWithoutPetsInput;
};

export type SexWhereInput = {
  AND?: Maybe<Array<SexWhereInput>>;
  OR?: Maybe<Array<SexWhereInput>>;
  NOT?: Maybe<Array<SexWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  integrationId?: Maybe<StringFilter>;
  speciesId?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  abbreviation?: Maybe<StringNullableFilter>;
  isDesexed?: Maybe<BoolNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  species?: Maybe<SpeciesWhereInput>;
};

export type SexWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  integrationId_pimsId?: Maybe<SexIntegrationIdPimsIdCompoundUniqueInput>;
};

export enum SmsEntityType {
  ChannelMessage = 'ChannelMessage',
  ServiceReminderNotification = 'ServiceReminderNotification',
  MassTextAlertEntry = 'MassTextAlertEntry',
  AutomationRun = 'AutomationRun'
}

export enum SmsNotificationDeliveryErrorType {
  Unknown = 'Unknown',
  InvalidContactInfo = 'InvalidContactInfo',
  ConnectionError = 'ConnectionError',
  NotificationRefused = 'NotificationRefused',
  CannotReceiveMessageType = 'CannotReceiveMessageType',
  MessageFilteredByCarrier = 'MessageFilteredByCarrier',
  ErrorSendingMessageToProvider = 'ErrorSendingMessageToProvider'
}

export enum SmsNotificationProvider {
  Twilio = 'Twilio',
  Bandwidth = 'Bandwidth'
}

export type SmsNotificationStatus = {
  __typename?: 'SmsNotificationStatus';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** (e.g. the phone number or email of the message) */
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  /** the kind of entity the sourceContactEntityId references */
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  /** optionally included: the channel associated with this notification */
  sentChannelMessageId?: Maybe<Scalars['String']>;
  /** who is delivering our message (e.g. Twilio) */
  provider: SmsNotificationProvider;
  /** the unique id the deliverer gives us to track the message (e.g. message_sId) */
  providerMessageId: Scalars['String'];
  /** Our categorization of the error */
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  /** the error message given by the provider */
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  sentChannelMessage?: Maybe<ChannelMessage>;
};

export type SmsNotificationStatusCountAggregateOutputType = {
  __typename?: 'SmsNotificationStatusCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  receivingNumber: Scalars['Int'];
  sourceContactEntityId: Scalars['Int'];
  sourceContactEntityType: Scalars['Int'];
  status: Scalars['Int'];
  sentChannelMessageId: Scalars['Int'];
  provider: Scalars['Int'];
  providerMessageId: Scalars['Int'];
  errorType: Scalars['Int'];
  providerErrorData: Scalars['Int'];
  clinicPhoneNumberId: Scalars['Int'];
  entityType: Scalars['Int'];
  entityId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SmsNotificationStatusCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  clinicPhoneNumberId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
};

export type SmsNotificationStatusCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedOneWithoutSmsNotificationStatusesInput>;
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutSmsNotifificationStatusesInput>;
};

export type SmsNotificationStatusCreateManyClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope = {
  data: Array<SmsNotificationStatusCreateManyClinicPhoneNumberInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SmsNotificationStatusCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusCreateManySentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusCreateManySentChannelMessageInputEnvelope = {
  data: Array<SmsNotificationStatusCreateManySentChannelMessageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SmsNotificationStatusCreateNestedManyWithoutClinicPhoneNumberInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutClinicPhoneNumberInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
};

export type SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutSentChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManySentChannelMessageInputEnvelope>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
};

export type SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput = {
  where: SmsNotificationStatusWhereUniqueInput;
  create: SmsNotificationStatusUncheckedCreateWithoutClinicPhoneNumberInput;
};

export type SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput = {
  where: SmsNotificationStatusWhereUniqueInput;
  create: SmsNotificationStatusUncheckedCreateWithoutSentChannelMessageInput;
};

export type SmsNotificationStatusCreateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutSmsNotifificationStatusesInput>;
};

export type SmsNotificationStatusCreateWithoutSentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedOneWithoutSmsNotificationStatusesInput>;
};

export type SmsNotificationStatusListRelationFilter = {
  every?: Maybe<SmsNotificationStatusWhereInput>;
  some?: Maybe<SmsNotificationStatusWhereInput>;
  none?: Maybe<SmsNotificationStatusWhereInput>;
};

export type SmsNotificationStatusMaxAggregateOutputType = {
  __typename?: 'SmsNotificationStatusMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  clinicPhoneNumberId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
};

export type SmsNotificationStatusMinAggregateOutputType = {
  __typename?: 'SmsNotificationStatusMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  clinicPhoneNumberId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
};

export type SmsNotificationStatusOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum SmsNotificationStatusOrderByRelevanceFieldEnum {
  Id = 'id',
  ReceivingNumber = 'receivingNumber',
  SourceContactEntityId = 'sourceContactEntityId',
  SentChannelMessageId = 'sentChannelMessageId',
  ProviderMessageId = 'providerMessageId',
  ProviderErrorData = 'providerErrorData',
  ClinicPhoneNumberId = 'clinicPhoneNumberId',
  EntityId = 'entityId'
}

export type SmsNotificationStatusOrderByRelevanceInput = {
  fields: Array<SmsNotificationStatusOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type SmsNotificationStatusOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  clinicPhoneNumberId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  _count?: Maybe<SmsNotificationStatusCountOrderByAggregateInput>;
  _max?: Maybe<SmsNotificationStatusMaxOrderByAggregateInput>;
  _min?: Maybe<SmsNotificationStatusMinOrderByAggregateInput>;
};

export type SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  clinicPhoneNumberId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  sentChannelMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<SmsNotificationStatusOrderByRelevanceInput>;
};

export enum SmsNotificationStatusScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ReceivingNumber = 'receivingNumber',
  SourceContactEntityId = 'sourceContactEntityId',
  SourceContactEntityType = 'sourceContactEntityType',
  Status = 'status',
  SentChannelMessageId = 'sentChannelMessageId',
  Provider = 'provider',
  ProviderMessageId = 'providerMessageId',
  ErrorType = 'errorType',
  ProviderErrorData = 'providerErrorData',
  ClinicPhoneNumberId = 'clinicPhoneNumberId',
  EntityType = 'entityType',
  EntityId = 'entityId'
}

export type SmsNotificationStatusScalarWhereInput = {
  AND?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
  OR?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
  NOT?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  receivingNumber?: Maybe<StringFilter>;
  sourceContactEntityId?: Maybe<StringFilter>;
  sourceContactEntityType?: Maybe<EnumContactEntityTypeFilter>;
  status?: Maybe<EnumSmsNotificationStatusTypeFilter>;
  sentChannelMessageId?: Maybe<StringNullableFilter>;
  provider?: Maybe<EnumSmsNotificationProviderFilter>;
  providerMessageId?: Maybe<StringFilter>;
  errorType?: Maybe<EnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  providerErrorData?: Maybe<StringNullableFilter>;
  clinicPhoneNumberId?: Maybe<StringNullableFilter>;
  entityType?: Maybe<EnumSmsEntityTypeNullableFilter>;
  entityId?: Maybe<StringNullableFilter>;
};

export type SmsNotificationStatusScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SmsNotificationStatusScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SmsNotificationStatusScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SmsNotificationStatusScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  receivingNumber?: Maybe<StringWithAggregatesFilter>;
  sourceContactEntityId?: Maybe<StringWithAggregatesFilter>;
  sourceContactEntityType?: Maybe<EnumContactEntityTypeWithAggregatesFilter>;
  status?: Maybe<EnumSmsNotificationStatusTypeWithAggregatesFilter>;
  sentChannelMessageId?: Maybe<StringNullableWithAggregatesFilter>;
  provider?: Maybe<EnumSmsNotificationProviderWithAggregatesFilter>;
  providerMessageId?: Maybe<StringWithAggregatesFilter>;
  errorType?: Maybe<EnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  providerErrorData?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPhoneNumberId?: Maybe<StringNullableWithAggregatesFilter>;
  entityType?: Maybe<EnumSmsEntityTypeNullableWithAggregatesFilter>;
  entityId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type SmsNotificationStatusSubscription = {
  __typename?: 'SmsNotificationStatusSubscription';
  node: SmsNotificationStatus;
  mutation: MutationType;
};

export enum SmsNotificationStatusType {
  SendError = 'Send_Error',
  Sent = 'Sent',
  Delivered = 'Delivered',
  Error = 'Error'
}

export type SmsNotificationStatusUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedCreateNestedManyWithoutClinicPhoneNumberInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutClinicPhoneNumberInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
};

export type SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutSentChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManySentChannelMessageInputEnvelope>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
};

export type SmsNotificationStatusUncheckedCreateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedCreateWithoutSentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedUpdateManyWithoutClinicPhoneNumberNestedInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutClinicPhoneNumberInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput>>;
  upsert?: Maybe<Array<SmsNotificationStatusUpsertWithWhereUniqueWithoutClinicPhoneNumberInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope>;
  set?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  update?: Maybe<Array<SmsNotificationStatusUpdateWithWhereUniqueWithoutClinicPhoneNumberInput>>;
  updateMany?: Maybe<Array<SmsNotificationStatusUpdateManyWithWhereWithoutClinicPhoneNumberInput>>;
  deleteMany?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
};

export type SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutSentChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput>>;
  upsert?: Maybe<Array<SmsNotificationStatusUpsertWithWhereUniqueWithoutSentChannelMessageInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManySentChannelMessageInputEnvelope>;
  set?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  update?: Maybe<Array<SmsNotificationStatusUpdateWithWhereUniqueWithoutSentChannelMessageInput>>;
  updateMany?: Maybe<Array<SmsNotificationStatusUpdateManyWithWhereWithoutSentChannelMessageInput>>;
  deleteMany?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
};

export type SmsNotificationStatusUncheckedUpdateManyWithoutSmsNotificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedUpdateManyWithoutSmsNotifificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedUpdateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedUpdateWithoutSentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateOneWithoutSmsNotificationStatusesNestedInput>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutSmsNotifificationStatusesNestedInput>;
};

export type SmsNotificationStatusUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUpdateManyWithWhereWithoutClinicPhoneNumberInput = {
  where: SmsNotificationStatusScalarWhereInput;
  data: SmsNotificationStatusUncheckedUpdateManyWithoutSmsNotificationStatusesInput;
};

export type SmsNotificationStatusUpdateManyWithWhereWithoutSentChannelMessageInput = {
  where: SmsNotificationStatusScalarWhereInput;
  data: SmsNotificationStatusUncheckedUpdateManyWithoutSmsNotifificationStatusesInput;
};

export type SmsNotificationStatusUpdateManyWithoutClinicPhoneNumberNestedInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutClinicPhoneNumberInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput>>;
  upsert?: Maybe<Array<SmsNotificationStatusUpsertWithWhereUniqueWithoutClinicPhoneNumberInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope>;
  set?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  update?: Maybe<Array<SmsNotificationStatusUpdateWithWhereUniqueWithoutClinicPhoneNumberInput>>;
  updateMany?: Maybe<Array<SmsNotificationStatusUpdateManyWithWhereWithoutClinicPhoneNumberInput>>;
  deleteMany?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
};

export type SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutSentChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput>>;
  upsert?: Maybe<Array<SmsNotificationStatusUpsertWithWhereUniqueWithoutSentChannelMessageInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManySentChannelMessageInputEnvelope>;
  set?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  update?: Maybe<Array<SmsNotificationStatusUpdateWithWhereUniqueWithoutSentChannelMessageInput>>;
  updateMany?: Maybe<Array<SmsNotificationStatusUpdateManyWithWhereWithoutSentChannelMessageInput>>;
  deleteMany?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
};

export type SmsNotificationStatusUpdateWithWhereUniqueWithoutClinicPhoneNumberInput = {
  where: SmsNotificationStatusWhereUniqueInput;
  data: SmsNotificationStatusUncheckedUpdateWithoutClinicPhoneNumberInput;
};

export type SmsNotificationStatusUpdateWithWhereUniqueWithoutSentChannelMessageInput = {
  where: SmsNotificationStatusWhereUniqueInput;
  data: SmsNotificationStatusUncheckedUpdateWithoutSentChannelMessageInput;
};

export type SmsNotificationStatusUpdateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutSmsNotifificationStatusesNestedInput>;
};

export type SmsNotificationStatusUpdateWithoutSentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateOneWithoutSmsNotificationStatusesNestedInput>;
};

export type SmsNotificationStatusUpsertWithWhereUniqueWithoutClinicPhoneNumberInput = {
  where: SmsNotificationStatusWhereUniqueInput;
  update: SmsNotificationStatusUncheckedUpdateWithoutClinicPhoneNumberInput;
  create: SmsNotificationStatusUncheckedCreateWithoutClinicPhoneNumberInput;
};

export type SmsNotificationStatusUpsertWithWhereUniqueWithoutSentChannelMessageInput = {
  where: SmsNotificationStatusWhereUniqueInput;
  update: SmsNotificationStatusUncheckedUpdateWithoutSentChannelMessageInput;
  create: SmsNotificationStatusUncheckedCreateWithoutSentChannelMessageInput;
};

export type SmsNotificationStatusWhereInput = {
  AND?: Maybe<Array<SmsNotificationStatusWhereInput>>;
  OR?: Maybe<Array<SmsNotificationStatusWhereInput>>;
  NOT?: Maybe<Array<SmsNotificationStatusWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  receivingNumber?: Maybe<StringFilter>;
  sourceContactEntityId?: Maybe<StringFilter>;
  sourceContactEntityType?: Maybe<EnumContactEntityTypeFilter>;
  status?: Maybe<EnumSmsNotificationStatusTypeFilter>;
  sentChannelMessageId?: Maybe<StringNullableFilter>;
  provider?: Maybe<EnumSmsNotificationProviderFilter>;
  providerMessageId?: Maybe<StringFilter>;
  errorType?: Maybe<EnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  providerErrorData?: Maybe<StringNullableFilter>;
  clinicPhoneNumberId?: Maybe<StringNullableFilter>;
  entityType?: Maybe<EnumSmsEntityTypeNullableFilter>;
  entityId?: Maybe<StringNullableFilter>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberWhereInput>;
  sentChannelMessage?: Maybe<ChannelMessageWhereInput>;
};

export type SmsNotificationStatusWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Species = {
  __typename?: 'Species';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption: DirectBookingSpeciesOption;
  integration: ClinicPimsIntegration;
  breeds: Array<Breed>;
  pets: Array<ClinicPet>;
  sexes: Array<Sex>;
  _count: SpeciesCountOutputType;
};


export type SpeciesBreedsArgs = {
  where?: Maybe<BreedWhereInput>;
  orderBy?: Maybe<BreedOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BreedWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BreedScalarFieldEnum>;
};


export type SpeciesPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};


export type SpeciesSexesArgs = {
  where?: Maybe<SexWhereInput>;
  orderBy?: Maybe<SexOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SexWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SexScalarFieldEnum>;
};

export type SpeciesCountAggregateOutputType = {
  __typename?: 'SpeciesCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  integrationId: Scalars['Int'];
  pimsId: Scalars['Int'];
  name: Scalars['Int'];
  description: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  directBookingSpeciesOption: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SpeciesCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  directBookingSpeciesOption?: Maybe<SortOrder>;
};

export type SpeciesCountOutputType = {
  __typename?: 'SpeciesCountOutputType';
  breeds: Scalars['Int'];
  pets: Scalars['Int'];
  sexes: Scalars['Int'];
};

export type SpeciesCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutSpeciesInput;
  breeds?: Maybe<BreedCreateNestedManyWithoutSpeciesInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutSpeciesModelInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutSpeciesInput>;
};

export type SpeciesCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
};

export type SpeciesCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
};

export type SpeciesCreateManyIntegrationInputEnvelope = {
  data: Array<SpeciesCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SpeciesCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<SpeciesCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<SpeciesCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<SpeciesCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<SpeciesWhereUniqueInput>>;
};

export type SpeciesCreateNestedOneWithoutBreedsInput = {
  create?: Maybe<SpeciesUncheckedCreateWithoutBreedsInput>;
  connectOrCreate?: Maybe<SpeciesCreateOrConnectWithoutBreedsInput>;
  connect?: Maybe<SpeciesWhereUniqueInput>;
};

export type SpeciesCreateNestedOneWithoutPetsInput = {
  create?: Maybe<SpeciesUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<SpeciesCreateOrConnectWithoutPetsInput>;
  connect?: Maybe<SpeciesWhereUniqueInput>;
};

export type SpeciesCreateNestedOneWithoutSexesInput = {
  create?: Maybe<SpeciesUncheckedCreateWithoutSexesInput>;
  connectOrCreate?: Maybe<SpeciesCreateOrConnectWithoutSexesInput>;
  connect?: Maybe<SpeciesWhereUniqueInput>;
};

export type SpeciesCreateOrConnectWithoutBreedsInput = {
  where: SpeciesWhereUniqueInput;
  create: SpeciesUncheckedCreateWithoutBreedsInput;
};

export type SpeciesCreateOrConnectWithoutIntegrationInput = {
  where: SpeciesWhereUniqueInput;
  create: SpeciesUncheckedCreateWithoutIntegrationInput;
};

export type SpeciesCreateOrConnectWithoutPetsInput = {
  where: SpeciesWhereUniqueInput;
  create: SpeciesUncheckedCreateWithoutPetsInput;
};

export type SpeciesCreateOrConnectWithoutSexesInput = {
  where: SpeciesWhereUniqueInput;
  create: SpeciesUncheckedCreateWithoutSexesInput;
};

export type SpeciesCreateWithoutBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutSpeciesInput;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutSpeciesModelInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutSpeciesInput>;
};

export type SpeciesCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  breeds?: Maybe<BreedCreateNestedManyWithoutSpeciesInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutSpeciesModelInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutSpeciesInput>;
};

export type SpeciesCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutSpeciesInput;
  breeds?: Maybe<BreedCreateNestedManyWithoutSpeciesInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutSpeciesInput>;
};

export type SpeciesCreateWithoutSexesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutSpeciesInput;
  breeds?: Maybe<BreedCreateNestedManyWithoutSpeciesInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutSpeciesModelInput>;
};

export type SpeciesIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type SpeciesListRelationFilter = {
  every?: Maybe<SpeciesWhereInput>;
  some?: Maybe<SpeciesWhereInput>;
  none?: Maybe<SpeciesWhereInput>;
};

export type SpeciesMaxAggregateOutputType = {
  __typename?: 'SpeciesMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
};

export type SpeciesMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  directBookingSpeciesOption?: Maybe<SortOrder>;
};

export type SpeciesMinAggregateOutputType = {
  __typename?: 'SpeciesMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
};

export type SpeciesMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  directBookingSpeciesOption?: Maybe<SortOrder>;
};

export type SpeciesOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum SpeciesOrderByRelevanceFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  Name = 'name',
  Description = 'description'
}

export type SpeciesOrderByRelevanceInput = {
  fields: Array<SpeciesOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type SpeciesOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  directBookingSpeciesOption?: Maybe<SortOrder>;
  _count?: Maybe<SpeciesCountOrderByAggregateInput>;
  _max?: Maybe<SpeciesMaxOrderByAggregateInput>;
  _min?: Maybe<SpeciesMinOrderByAggregateInput>;
};

export type SpeciesOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  directBookingSpeciesOption?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  breeds?: Maybe<BreedOrderByRelationAggregateInput>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  sexes?: Maybe<SexOrderByRelationAggregateInput>;
  _relevance?: Maybe<SpeciesOrderByRelevanceInput>;
};

export type SpeciesRelationFilter = {
  is?: Maybe<SpeciesWhereInput>;
  isNot?: Maybe<SpeciesWhereInput>;
};

export enum SpeciesScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  Name = 'name',
  Description = 'description',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  RawPimsValue = 'rawPimsValue',
  DirectBookingSpeciesOption = 'directBookingSpeciesOption'
}

export type SpeciesScalarWhereInput = {
  AND?: Maybe<Array<SpeciesScalarWhereInput>>;
  OR?: Maybe<Array<SpeciesScalarWhereInput>>;
  NOT?: Maybe<Array<SpeciesScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  directBookingSpeciesOption?: Maybe<EnumDirectBookingSpeciesOptionFilter>;
};

export type SpeciesScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SpeciesScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SpeciesScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SpeciesScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  directBookingSpeciesOption?: Maybe<EnumDirectBookingSpeciesOptionWithAggregatesFilter>;
};

export enum SpeciesType {
  Feline = 'Feline',
  Canine = 'Canine',
  Avian = 'Avian',
  Bovine = 'Bovine',
  Equine = 'Equine',
  Other = 'Other'
}

export type SpeciesUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutSpeciesInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutSpeciesModelInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutSpeciesInput>;
};

export type SpeciesUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<SpeciesCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<SpeciesCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<SpeciesCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<SpeciesWhereUniqueInput>>;
};

export type SpeciesUncheckedCreateWithoutBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutSpeciesModelInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutSpeciesInput>;
};

export type SpeciesUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutSpeciesInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutSpeciesModelInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutSpeciesInput>;
};

export type SpeciesUncheckedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutSpeciesInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutSpeciesInput>;
};

export type SpeciesUncheckedCreateWithoutSexesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutSpeciesInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutSpeciesModelInput>;
};

export type SpeciesUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutSpeciesNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutSpeciesModelNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutSpeciesNestedInput>;
};

export type SpeciesUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
};

export type SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<SpeciesCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<SpeciesCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<SpeciesUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<SpeciesCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<SpeciesWhereUniqueInput>>;
  disconnect?: Maybe<Array<SpeciesWhereUniqueInput>>;
  delete?: Maybe<Array<SpeciesWhereUniqueInput>>;
  connect?: Maybe<Array<SpeciesWhereUniqueInput>>;
  update?: Maybe<Array<SpeciesUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<SpeciesUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<SpeciesScalarWhereInput>>;
};

export type SpeciesUncheckedUpdateManyWithoutSpeciesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
};

export type SpeciesUncheckedUpdateWithoutBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutSpeciesModelNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutSpeciesNestedInput>;
};

export type SpeciesUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutSpeciesNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutSpeciesModelNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutSpeciesNestedInput>;
};

export type SpeciesUncheckedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutSpeciesNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutSpeciesNestedInput>;
};

export type SpeciesUncheckedUpdateWithoutSexesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutSpeciesNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutSpeciesModelNestedInput>;
};

export type SpeciesUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutSpeciesNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutSpeciesNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutSpeciesModelNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutSpeciesNestedInput>;
};

export type SpeciesUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
};

export type SpeciesUpdateManyWithWhereWithoutIntegrationInput = {
  where: SpeciesScalarWhereInput;
  data: SpeciesUncheckedUpdateManyWithoutSpeciesInput;
};

export type SpeciesUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<SpeciesCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<SpeciesCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<SpeciesUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<SpeciesCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<SpeciesWhereUniqueInput>>;
  disconnect?: Maybe<Array<SpeciesWhereUniqueInput>>;
  delete?: Maybe<Array<SpeciesWhereUniqueInput>>;
  connect?: Maybe<Array<SpeciesWhereUniqueInput>>;
  update?: Maybe<Array<SpeciesUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<SpeciesUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<SpeciesScalarWhereInput>>;
};

export type SpeciesUpdateOneWithoutBreedsNestedInput = {
  create?: Maybe<SpeciesUncheckedCreateWithoutBreedsInput>;
  connectOrCreate?: Maybe<SpeciesCreateOrConnectWithoutBreedsInput>;
  upsert?: Maybe<SpeciesUpsertWithoutBreedsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SpeciesWhereUniqueInput>;
  update?: Maybe<SpeciesUncheckedUpdateWithoutBreedsInput>;
};

export type SpeciesUpdateOneWithoutPetsNestedInput = {
  create?: Maybe<SpeciesUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<SpeciesCreateOrConnectWithoutPetsInput>;
  upsert?: Maybe<SpeciesUpsertWithoutPetsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SpeciesWhereUniqueInput>;
  update?: Maybe<SpeciesUncheckedUpdateWithoutPetsInput>;
};

export type SpeciesUpdateOneWithoutSexesNestedInput = {
  create?: Maybe<SpeciesUncheckedCreateWithoutSexesInput>;
  connectOrCreate?: Maybe<SpeciesCreateOrConnectWithoutSexesInput>;
  upsert?: Maybe<SpeciesUpsertWithoutSexesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SpeciesWhereUniqueInput>;
  update?: Maybe<SpeciesUncheckedUpdateWithoutSexesInput>;
};

export type SpeciesUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: SpeciesWhereUniqueInput;
  data: SpeciesUncheckedUpdateWithoutIntegrationInput;
};

export type SpeciesUpdateWithoutBreedsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutSpeciesNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutSpeciesModelNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutSpeciesNestedInput>;
};

export type SpeciesUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  breeds?: Maybe<BreedUpdateManyWithoutSpeciesNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutSpeciesModelNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutSpeciesNestedInput>;
};

export type SpeciesUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutSpeciesNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutSpeciesNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutSpeciesNestedInput>;
};

export type SpeciesUpdateWithoutSexesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutSpeciesNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutSpeciesNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutSpeciesModelNestedInput>;
};

export type SpeciesUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: SpeciesWhereUniqueInput;
  update: SpeciesUncheckedUpdateWithoutIntegrationInput;
  create: SpeciesUncheckedCreateWithoutIntegrationInput;
};

export type SpeciesUpsertWithoutBreedsInput = {
  update: SpeciesUncheckedUpdateWithoutBreedsInput;
  create: SpeciesUncheckedCreateWithoutBreedsInput;
};

export type SpeciesUpsertWithoutPetsInput = {
  update: SpeciesUncheckedUpdateWithoutPetsInput;
  create: SpeciesUncheckedCreateWithoutPetsInput;
};

export type SpeciesUpsertWithoutSexesInput = {
  update: SpeciesUncheckedUpdateWithoutSexesInput;
  create: SpeciesUncheckedCreateWithoutSexesInput;
};

export type SpeciesWhereInput = {
  AND?: Maybe<Array<SpeciesWhereInput>>;
  OR?: Maybe<Array<SpeciesWhereInput>>;
  NOT?: Maybe<Array<SpeciesWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  directBookingSpeciesOption?: Maybe<EnumDirectBookingSpeciesOptionFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  breeds?: Maybe<BreedListRelationFilter>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  sexes?: Maybe<SexListRelationFilter>;
};

export type SpeciesWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  integrationId_pimsId?: Maybe<SpeciesIntegrationIdPimsIdCompoundUniqueInput>;
};

export enum SqsQueueNames {
  CoreQueue = 'CoreQueue',
  GenericModelChangeQueue = 'GenericModelChangeQueue',
  NotificationJobs = 'NotificationJobs',
  PimsEtlOnboardingIntegrationSync = 'PimsEtlOnboardingIntegrationSync',
  PimsWriteback = 'PimsWriteback',
  PimsEtlOnboardingModelDataSync = 'PimsEtlOnboardingModelDataSync',
  PimsEtlOnboardingModelChanges = 'PimsEtlOnboardingModelChanges',
  PimsEtlOnboardingRetryModelChanges = 'PimsEtlOnboardingRetryModelChanges',
  PimsEtlIntegrationSync = 'PimsEtlIntegrationSync',
  PimsEtlModelDataSync = 'PimsEtlModelDataSync',
  PimsEtlModelChanges = 'PimsEtlModelChanges',
  PimsEtlRetryModelChanges = 'PimsEtlRetryModelChanges',
  PimsSyncJobs = 'PimsSyncJobs',
  RealTimeGenericModelChangeQueue = 'RealTimeGenericModelChangeQueue',
  SearchIndexing = 'SearchIndexing',
  SearchIndexingPimsCreate = 'SearchIndexingPimsCreate',
  MediaJobs = 'MediaJobs',
  ConditionEngineJobs = 'ConditionEngineJobs',
  LoyaltyProgram = 'LoyaltyProgram',
  InformJobs = 'InformJobs',
  InformPartnerIntegrationRequestJobs = 'InformPartnerIntegrationRequestJobs',
  InformCampaignJobs = 'InformCampaignJobs'
}

export type StaffRole = {
  __typename?: 'StaffRole';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  role: Role;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  clinic?: Maybe<Clinic>;
  permissions: Array<Permission>;
  users: Array<VetInfo>;
  _count: StaffRoleCountOutputType;
};


export type StaffRolePermissionsArgs = {
  where?: Maybe<PermissionWhereInput>;
  orderBy?: Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PermissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PermissionScalarFieldEnum>;
};


export type StaffRoleUsersArgs = {
  where?: Maybe<VetInfoWhereInput>;
  orderBy?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetInfoScalarFieldEnum>;
};

export type StaffRoleCountAggregateOutputType = {
  __typename?: 'StaffRoleCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  role: Scalars['Int'];
  clinicId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StaffRoleCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StaffRoleCountOutputType = {
  __typename?: 'StaffRoleCountOutputType';
  permissions: Scalars['Int'];
  users: Scalars['Int'];
};

export type StaffRoleCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutRolesInput>;
  permissions?: Maybe<PermissionCreateNestedManyWithoutRolesInput>;
  users?: Maybe<VetInfoCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleCreateManyClinicInputEnvelope = {
  data: Array<StaffRoleCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StaffRoleCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<StaffRoleCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
};

export type StaffRoleCreateNestedManyWithoutPermissionsInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutPermissionsInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutPermissionsInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
};

export type StaffRoleCreateNestedManyWithoutUsersInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutUsersInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
};

export type StaffRoleCreateOrConnectWithoutClinicInput = {
  where: StaffRoleWhereUniqueInput;
  create: StaffRoleUncheckedCreateWithoutClinicInput;
};

export type StaffRoleCreateOrConnectWithoutPermissionsInput = {
  where: StaffRoleWhereUniqueInput;
  create: StaffRoleUncheckedCreateWithoutPermissionsInput;
};

export type StaffRoleCreateOrConnectWithoutUsersInput = {
  where: StaffRoleWhereUniqueInput;
  create: StaffRoleUncheckedCreateWithoutUsersInput;
};

export type StaffRoleCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionCreateNestedManyWithoutRolesInput>;
  users?: Maybe<VetInfoCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleCreateWithoutPermissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutRolesInput>;
  users?: Maybe<VetInfoCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutRolesInput>;
  permissions?: Maybe<PermissionCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleListRelationFilter = {
  every?: Maybe<StaffRoleWhereInput>;
  some?: Maybe<StaffRoleWhereInput>;
  none?: Maybe<StaffRoleWhereInput>;
};

export type StaffRoleMaxAggregateOutputType = {
  __typename?: 'StaffRoleMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StaffRoleMinAggregateOutputType = {
  __typename?: 'StaffRoleMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StaffRoleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StaffRoleOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId'
}

export type StaffRoleOrderByRelevanceInput = {
  fields: Array<StaffRoleOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StaffRoleOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<StaffRoleCountOrderByAggregateInput>;
  _max?: Maybe<StaffRoleMaxOrderByAggregateInput>;
  _min?: Maybe<StaffRoleMinOrderByAggregateInput>;
};

export type StaffRoleOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  permissions?: Maybe<PermissionOrderByRelationAggregateInput>;
  users?: Maybe<VetInfoOrderByRelationAggregateInput>;
  _relevance?: Maybe<StaffRoleOrderByRelevanceInput>;
};

export enum StaffRoleScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Role = 'role',
  ClinicId = 'clinicId',
  UpdatedAt = 'updatedAt'
}

export type StaffRoleScalarWhereInput = {
  AND?: Maybe<Array<StaffRoleScalarWhereInput>>;
  OR?: Maybe<Array<StaffRoleScalarWhereInput>>;
  NOT?: Maybe<Array<StaffRoleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  role?: Maybe<EnumRoleFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StaffRoleScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StaffRoleScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StaffRoleScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StaffRoleScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  role?: Maybe<EnumRoleWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type StaffRoleUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionUncheckedCreateNestedManyWithoutRolesInput>;
  users?: Maybe<VetInfoUncheckedCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<StaffRoleCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
};

export type StaffRoleUncheckedCreateNestedManyWithoutPermissionsInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutPermissionsInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutPermissionsInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
};

export type StaffRoleUncheckedCreateNestedManyWithoutUsersInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutUsersInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
};

export type StaffRoleUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionUncheckedCreateNestedManyWithoutRolesInput>;
  users?: Maybe<VetInfoUncheckedCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleUncheckedCreateWithoutPermissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoUncheckedCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleUncheckedCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionUncheckedCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionUncheckedUpdateManyWithoutRolesNestedInput>;
  users?: Maybe<VetInfoUncheckedUpdateManyWithoutRolesNestedInput>;
};

export type StaffRoleUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<StaffRoleCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
};

export type StaffRoleUncheckedUpdateManyWithoutPermissionsNestedInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutPermissionsInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutPermissionsInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutPermissionsInput>>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutPermissionsInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutPermissionsInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
};

export type StaffRoleUncheckedUpdateManyWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleUncheckedUpdateManyWithoutUsersNestedInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutUsersInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutUsersInput>>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutUsersInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
};

export type StaffRoleUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionUncheckedUpdateManyWithoutRolesNestedInput>;
  users?: Maybe<VetInfoUncheckedUpdateManyWithoutRolesNestedInput>;
};

export type StaffRoleUncheckedUpdateWithoutPermissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoUncheckedUpdateManyWithoutRolesNestedInput>;
};

export type StaffRoleUncheckedUpdateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionUncheckedUpdateManyWithoutRolesNestedInput>;
};

export type StaffRoleUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneWithoutRolesNestedInput>;
  permissions?: Maybe<PermissionUpdateManyWithoutRolesNestedInput>;
  users?: Maybe<VetInfoUpdateManyWithoutRolesNestedInput>;
};

export type StaffRoleUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleUpdateManyWithWhereWithoutClinicInput = {
  where: StaffRoleScalarWhereInput;
  data: StaffRoleUncheckedUpdateManyWithoutRolesInput;
};

export type StaffRoleUpdateManyWithWhereWithoutPermissionsInput = {
  where: StaffRoleScalarWhereInput;
  data: StaffRoleUncheckedUpdateManyWithoutRolesInput;
};

export type StaffRoleUpdateManyWithWhereWithoutUsersInput = {
  where: StaffRoleScalarWhereInput;
  data: StaffRoleUncheckedUpdateManyWithoutRolesInput;
};

export type StaffRoleUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<StaffRoleCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
};

export type StaffRoleUpdateManyWithoutPermissionsNestedInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutPermissionsInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutPermissionsInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutPermissionsInput>>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutPermissionsInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutPermissionsInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
};

export type StaffRoleUpdateManyWithoutUsersNestedInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutUsersInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutUsersInput>>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutUsersInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
};

export type StaffRoleUpdateWithWhereUniqueWithoutClinicInput = {
  where: StaffRoleWhereUniqueInput;
  data: StaffRoleUncheckedUpdateWithoutClinicInput;
};

export type StaffRoleUpdateWithWhereUniqueWithoutPermissionsInput = {
  where: StaffRoleWhereUniqueInput;
  data: StaffRoleUncheckedUpdateWithoutPermissionsInput;
};

export type StaffRoleUpdateWithWhereUniqueWithoutUsersInput = {
  where: StaffRoleWhereUniqueInput;
  data: StaffRoleUncheckedUpdateWithoutUsersInput;
};

export type StaffRoleUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionUpdateManyWithoutRolesNestedInput>;
  users?: Maybe<VetInfoUpdateManyWithoutRolesNestedInput>;
};

export type StaffRoleUpdateWithoutPermissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneWithoutRolesNestedInput>;
  users?: Maybe<VetInfoUpdateManyWithoutRolesNestedInput>;
};

export type StaffRoleUpdateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneWithoutRolesNestedInput>;
  permissions?: Maybe<PermissionUpdateManyWithoutRolesNestedInput>;
};

export type StaffRoleUpsertWithWhereUniqueWithoutClinicInput = {
  where: StaffRoleWhereUniqueInput;
  update: StaffRoleUncheckedUpdateWithoutClinicInput;
  create: StaffRoleUncheckedCreateWithoutClinicInput;
};

export type StaffRoleUpsertWithWhereUniqueWithoutPermissionsInput = {
  where: StaffRoleWhereUniqueInput;
  update: StaffRoleUncheckedUpdateWithoutPermissionsInput;
  create: StaffRoleUncheckedCreateWithoutPermissionsInput;
};

export type StaffRoleUpsertWithWhereUniqueWithoutUsersInput = {
  where: StaffRoleWhereUniqueInput;
  update: StaffRoleUncheckedUpdateWithoutUsersInput;
  create: StaffRoleUncheckedCreateWithoutUsersInput;
};

export type StaffRoleWhereInput = {
  AND?: Maybe<Array<StaffRoleWhereInput>>;
  OR?: Maybe<Array<StaffRoleWhereInput>>;
  NOT?: Maybe<Array<StaffRoleWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  role?: Maybe<EnumRoleFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  permissions?: Maybe<PermissionListRelationFilter>;
  users?: Maybe<VetInfoListRelationFilter>;
};

export type StaffRoleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum State {
  Al = 'AL',
  Ak = 'AK',
  As = 'AS',
  Az = 'AZ',
  Ar = 'AR',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  De = 'DE',
  Dc = 'DC',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ia = 'IA',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Me = 'ME',
  Md = 'MD',
  Ma = 'MA',
  Mi = 'MI',
  Mn = 'MN',
  Ms = 'MS',
  Mo = 'MO',
  Mt = 'MT',
  Ne = 'NE',
  Nv = 'NV',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Ny = 'NY',
  Nc = 'NC',
  Nd = 'ND',
  Mp = 'MP',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Vt = 'VT',
  Vi = 'VI',
  Va = 'VA',
  Wa = 'WA',
  Wv = 'WV',
  Wi = 'WI',
  Wy = 'WY'
}

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Scalars['String']>>;
  has?: Maybe<Scalars['String']>;
  hasEvery?: Maybe<Array<Scalars['String']>>;
  hasSome?: Maybe<Array<Scalars['String']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type StringNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
};

export type StringWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedStringFilter>;
  _max?: Maybe<NestedStringFilter>;
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  id: Scalars['String'];
  chargesEnabled: Scalars['Boolean'];
  payoutsEnabled: Scalars['Boolean'];
  capabilities: StripeCapabilities;
  requirements: StripeRequirements;
  payoutSchedule: StripePayoutSchedule;
  payoutAccounts?: Maybe<Array<StripePayoutAccount>>;
  defaultAccount?: Maybe<StripePayoutAccount>;
};

export type StripeAccountOutput = {
  __typename?: 'StripeAccountOutput';
  account: StripeAccount;
};

export type StripeCapabilities = {
  __typename?: 'StripeCapabilities';
  card_payments?: Maybe<StripeCapabilityState>;
  transfers: StripeCapabilityState;
};

export enum StripeCapabilityState {
  Active = 'active',
  Inactive = 'inactive'
}

export enum StripeCaptureType {
  Automatic = 'automatic',
  Manual = 'manual'
}

export enum StripeCollectionMethod {
  ChargeAutomatically = 'charge_automatically',
  SendInvoice = 'send_invoice'
}

export type StripeConnectOauthInput = {
  oauthCode: Scalars['String'];
};

export type StripeCreateAccountInput = {
  redirectUrl: Scalars['String'];
};

export type StripeCreateAccountOutput = {
  __typename?: 'StripeCreateAccountOutput';
  account: StripeAccount;
  onboarding: LoginLinkOutput;
};

export type StripeCreatePaymentInput = {
  amount: Scalars['Int'];
  customer?: Maybe<Scalars['String']>;
  receiptEmail?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  captureType?: Maybe<StripeCaptureType>;
  description?: Maybe<Scalars['String']>;
  descriptorSuffix?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  paymentMethodType?: Maybe<StripePaymentMethodType>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  cardType?: Maybe<CardFunding>;
  paymentMedium?: Maybe<PaymentMedium>;
};

export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  id: Scalars['String'];
  stripeId: Scalars['String'];
  organizationPetParents: Array<OrganizationPetParent>;
  clinicPetParents: Array<ClinicPetParent>;
  paymentMethods: Array<StripePaymentMethod>;
  invoices: Array<StripeInvoice>;
  _count: StripeCustomerCountOutputType;
};


export type StripeCustomerOrganizationPetParentsArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
  orderBy?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationPetParentScalarFieldEnum>;
};


export type StripeCustomerClinicPetParentsArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};


export type StripeCustomerPaymentMethodsArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
  orderBy?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentMethodScalarFieldEnum>;
};


export type StripeCustomerInvoicesArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
  orderBy?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeInvoiceScalarFieldEnum>;
};

export type StripeCustomerCountAggregateOutputType = {
  __typename?: 'StripeCustomerCountAggregateOutputType';
  id: Scalars['Int'];
  stripeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeCustomerCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
};

export type StripeCustomerCountOutputType = {
  __typename?: 'StripeCustomerCountOutputType';
  organizationPetParents: Scalars['Int'];
  clinicPetParents: Scalars['Int'];
  paymentMethods: Scalars['Int'];
  invoices: Scalars['Int'];
};

export type StripeCustomerCreateInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
};

export type StripeCustomerCreateNestedOneWithoutClinicPetParentsInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutClinicPetParentsInput>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
};

export type StripeCustomerCreateNestedOneWithoutInvoicesInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutInvoicesInput>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
};

export type StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutOrganizationPetParentsInput>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
};

export type StripeCustomerCreateNestedOneWithoutPaymentMethodsInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutPaymentMethodsInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutPaymentMethodsInput>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
};

export type StripeCustomerCreateOrConnectWithoutClinicPetParentsInput = {
  where: StripeCustomerWhereUniqueInput;
  create: StripeCustomerUncheckedCreateWithoutClinicPetParentsInput;
};

export type StripeCustomerCreateOrConnectWithoutInvoicesInput = {
  where: StripeCustomerWhereUniqueInput;
  create: StripeCustomerUncheckedCreateWithoutInvoicesInput;
};

export type StripeCustomerCreateOrConnectWithoutOrganizationPetParentsInput = {
  where: StripeCustomerWhereUniqueInput;
  create: StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput;
};

export type StripeCustomerCreateOrConnectWithoutPaymentMethodsInput = {
  where: StripeCustomerWhereUniqueInput;
  create: StripeCustomerUncheckedCreateWithoutPaymentMethodsInput;
};

export type StripeCustomerCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerCreateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerCreateWithoutPaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerMaxAggregateOutputType = {
  __typename?: 'StripeCustomerMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
};

export type StripeCustomerMinAggregateOutputType = {
  __typename?: 'StripeCustomerMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
};

export enum StripeCustomerOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId'
}

export type StripeCustomerOrderByRelevanceInput = {
  fields: Array<StripeCustomerOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeCustomerOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  _count?: Maybe<StripeCustomerCountOrderByAggregateInput>;
  _max?: Maybe<StripeCustomerMaxOrderByAggregateInput>;
  _min?: Maybe<StripeCustomerMinOrderByAggregateInput>;
};

export type StripeCustomerOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  organizationPetParents?: Maybe<OrganizationPetParentOrderByRelationAggregateInput>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  paymentMethods?: Maybe<StripePaymentMethodOrderByRelationAggregateInput>;
  invoices?: Maybe<StripeInvoiceOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripeCustomerOrderByRelevanceInput>;
};

export type StripeCustomerPortalInput = {
  returnUrl?: Maybe<Scalars['String']>;
};

export type StripeCustomerPortalOutput = {
  __typename?: 'StripeCustomerPortalOutput';
  id: Scalars['String'];
  url: Scalars['String'];
};

export type StripeCustomerPortalWhereUniqueInput = {
  customerId: Scalars['String'];
};

export type StripeCustomerRelationFilter = {
  is?: Maybe<StripeCustomerWhereInput>;
  isNot?: Maybe<StripeCustomerWhereInput>;
};

export enum StripeCustomerScalarFieldEnum {
  Id = 'id',
  StripeId = 'stripeId'
}

export type StripeCustomerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeCustomerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeCustomerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeCustomerScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
};

export type StripeCustomerUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUncheckedCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUncheckedCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUncheckedCreateWithoutPaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
};

export type StripeCustomerUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUncheckedUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
};

export type StripeCustomerUncheckedUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
};

export type StripeCustomerUncheckedUpdateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
};

export type StripeCustomerUncheckedUpdateWithoutPaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
};

export type StripeCustomerUpdateInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutStripeCustomerNestedInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutStripeCustomerNestedInput>;
};

export type StripeCustomerUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutInvoicesInput>;
  upsert?: Maybe<StripeCustomerUpsertWithoutInvoicesInput>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  update?: Maybe<StripeCustomerUncheckedUpdateWithoutInvoicesInput>;
};

export type StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutClinicPetParentsInput>;
  upsert?: Maybe<StripeCustomerUpsertWithoutClinicPetParentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  update?: Maybe<StripeCustomerUncheckedUpdateWithoutClinicPetParentsInput>;
};

export type StripeCustomerUpdateOneWithoutOrganizationPetParentsNestedInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutOrganizationPetParentsInput>;
  upsert?: Maybe<StripeCustomerUpsertWithoutOrganizationPetParentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  update?: Maybe<StripeCustomerUncheckedUpdateWithoutOrganizationPetParentsInput>;
};

export type StripeCustomerUpdateOneWithoutPaymentMethodsNestedInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutPaymentMethodsInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutPaymentMethodsInput>;
  upsert?: Maybe<StripeCustomerUpsertWithoutPaymentMethodsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  update?: Maybe<StripeCustomerUncheckedUpdateWithoutPaymentMethodsInput>;
};

export type StripeCustomerUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutStripeCustomerNestedInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutStripeCustomerNestedInput>;
};

export type StripeCustomerUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutStripeCustomerNestedInput>;
};

export type StripeCustomerUpdateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutStripeCustomerNestedInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutStripeCustomerNestedInput>;
};

export type StripeCustomerUpdateWithoutPaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutStripeCustomerNestedInput>;
};

export type StripeCustomerUpsertWithoutClinicPetParentsInput = {
  update: StripeCustomerUncheckedUpdateWithoutClinicPetParentsInput;
  create: StripeCustomerUncheckedCreateWithoutClinicPetParentsInput;
};

export type StripeCustomerUpsertWithoutInvoicesInput = {
  update: StripeCustomerUncheckedUpdateWithoutInvoicesInput;
  create: StripeCustomerUncheckedCreateWithoutInvoicesInput;
};

export type StripeCustomerUpsertWithoutOrganizationPetParentsInput = {
  update: StripeCustomerUncheckedUpdateWithoutOrganizationPetParentsInput;
  create: StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput;
};

export type StripeCustomerUpsertWithoutPaymentMethodsInput = {
  update: StripeCustomerUncheckedUpdateWithoutPaymentMethodsInput;
  create: StripeCustomerUncheckedCreateWithoutPaymentMethodsInput;
};

export type StripeCustomerWhereInput = {
  AND?: Maybe<Array<StripeCustomerWhereInput>>;
  OR?: Maybe<Array<StripeCustomerWhereInput>>;
  NOT?: Maybe<Array<StripeCustomerWhereInput>>;
  id?: Maybe<StringFilter>;
  stripeId?: Maybe<StringFilter>;
  organizationPetParents?: Maybe<OrganizationPetParentListRelationFilter>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  paymentMethods?: Maybe<StripePaymentMethodListRelationFilter>;
  invoices?: Maybe<StripeInvoiceListRelationFilter>;
};

export type StripeCustomerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeDispute = {
  __typename?: 'StripeDispute';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  /** This will be the amount we charge the clinic for the dispute if they lose it */
  disputeFee?: Maybe<Scalars['Int']>;
  /** This is how much we refunded to them for the processing fees if they lose the dispute */
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntent>;
  stripePayout?: Maybe<StripePayout>;
  financialTransaction?: Maybe<FinancialTransaction>;
};

export type StripeDisputeAvgAggregateOutputType = {
  __typename?: 'StripeDisputeAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  disputeFee?: Maybe<Scalars['Float']>;
  processingFeesRefunded?: Maybe<Scalars['Float']>;
};

export type StripeDisputeAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  disputeFee?: Maybe<SortOrder>;
  processingFeesRefunded?: Maybe<SortOrder>;
};

export type StripeDisputeCountAggregateOutputType = {
  __typename?: 'StripeDisputeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  amount: Scalars['Int'];
  status: Scalars['Int'];
  stripePaymentIntentId: Scalars['Int'];
  stripePayoutId: Scalars['Int'];
  stripeTransferReversalId: Scalars['Int'];
  stripeDisputeFeeChargeId: Scalars['Int'];
  disputeFee: Scalars['Int'];
  processingFeesRefunded: Scalars['Int'];
  fundsAvailableAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeDisputeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
  disputeFee?: Maybe<SortOrder>;
  processingFeesRefunded?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
};

export type StripeDisputeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeDisputeInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeDisputesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeDisputeInput>;
};

export type StripeDisputeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeCreateManyStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeCreateManyStripePaymentIntentInputEnvelope = {
  data: Array<StripeDisputeCreateManyStripePaymentIntentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeDisputeCreateManyStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeCreateManyStripePayoutInputEnvelope = {
  data: Array<StripeDisputeCreateManyStripePayoutInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePaymentIntentInputEnvelope>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
};

export type StripeDisputeCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
};

export type StripeDisputeCreateNestedOneWithoutFinancialTransactionInput = {
  create?: Maybe<StripeDisputeUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripeDisputeCreateOrConnectWithoutFinancialTransactionInput>;
  connect?: Maybe<StripeDisputeWhereUniqueInput>;
};

export type StripeDisputeCreateOrConnectWithoutFinancialTransactionInput = {
  where: StripeDisputeWhereUniqueInput;
  create: StripeDisputeUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput = {
  where: StripeDisputeWhereUniqueInput;
  create: StripeDisputeUncheckedCreateWithoutStripePaymentIntentInput;
};

export type StripeDisputeCreateOrConnectWithoutStripePayoutInput = {
  where: StripeDisputeWhereUniqueInput;
  create: StripeDisputeUncheckedCreateWithoutStripePayoutInput;
};

export type StripeDisputeCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeDisputeInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeDisputesInput>;
};

export type StripeDisputeCreateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeDisputesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeDisputeInput>;
};

export type StripeDisputeCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeDisputeInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeDisputeInput>;
};

export type StripeDisputeListRelationFilter = {
  every?: Maybe<StripeDisputeWhereInput>;
  some?: Maybe<StripeDisputeWhereInput>;
  none?: Maybe<StripeDisputeWhereInput>;
};

export type StripeDisputeMaxAggregateOutputType = {
  __typename?: 'StripeDisputeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
  disputeFee?: Maybe<SortOrder>;
  processingFeesRefunded?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
};

export type StripeDisputeMinAggregateOutputType = {
  __typename?: 'StripeDisputeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
  disputeFee?: Maybe<SortOrder>;
  processingFeesRefunded?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
};

export type StripeDisputeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeDisputeOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripePayoutId = 'stripePayoutId',
  StripeTransferReversalId = 'stripeTransferReversalId',
  StripeDisputeFeeChargeId = 'stripeDisputeFeeChargeId'
}

export type StripeDisputeOrderByRelevanceInput = {
  fields: Array<StripeDisputeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeDisputeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
  disputeFee?: Maybe<SortOrder>;
  processingFeesRefunded?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  _count?: Maybe<StripeDisputeCountOrderByAggregateInput>;
  _avg?: Maybe<StripeDisputeAvgOrderByAggregateInput>;
  _max?: Maybe<StripeDisputeMaxOrderByAggregateInput>;
  _min?: Maybe<StripeDisputeMinOrderByAggregateInput>;
  _sum?: Maybe<StripeDisputeSumOrderByAggregateInput>;
};

export type StripeDisputeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
  disputeFee?: Maybe<SortOrder>;
  processingFeesRefunded?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  stripePaymentIntent?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  stripePayout?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  financialTransaction?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<StripeDisputeOrderByRelevanceInput>;
};

export type StripeDisputeRelationFilter = {
  is?: Maybe<StripeDisputeWhereInput>;
  isNot?: Maybe<StripeDisputeWhereInput>;
};

export enum StripeDisputeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeId = 'stripeId',
  Amount = 'amount',
  Status = 'status',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripePayoutId = 'stripePayoutId',
  StripeTransferReversalId = 'stripeTransferReversalId',
  StripeDisputeFeeChargeId = 'stripeDisputeFeeChargeId',
  DisputeFee = 'disputeFee',
  ProcessingFeesRefunded = 'processingFeesRefunded',
  FundsAvailableAt = 'fundsAvailableAt'
}

export type StripeDisputeScalarWhereInput = {
  AND?: Maybe<Array<StripeDisputeScalarWhereInput>>;
  OR?: Maybe<Array<StripeDisputeScalarWhereInput>>;
  NOT?: Maybe<Array<StripeDisputeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  status?: Maybe<EnumStripeDisputeStatusFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripeTransferReversalId?: Maybe<StringNullableFilter>;
  stripeDisputeFeeChargeId?: Maybe<StringNullableFilter>;
  disputeFee?: Maybe<IntNullableFilter>;
  processingFeesRefunded?: Maybe<IntNullableFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
};

export type StripeDisputeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeDisputeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeDisputeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeDisputeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  status?: Maybe<EnumStripeDisputeStatusWithAggregatesFilter>;
  stripePaymentIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePayoutId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeTransferReversalId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeDisputeFeeChargeId?: Maybe<StringNullableWithAggregatesFilter>;
  disputeFee?: Maybe<IntNullableWithAggregatesFilter>;
  processingFeesRefunded?: Maybe<IntNullableWithAggregatesFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
};

export enum StripeDisputeStatus {
  WarningUnderReview = 'Warning_Under_Review',
  WarningClosed = 'Warning_Closed',
  WarningNeedsResponse = 'Warning_Needs_Response',
  NeedsResponse = 'Needs_Response',
  UnderReview = 'Under_Review',
  ChargeRefunded = 'Charge_Refunded',
  Won = 'Won',
  Lost = 'Lost'
}

export type StripeDisputeSumAggregateOutputType = {
  __typename?: 'StripeDisputeSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
};

export type StripeDisputeSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  disputeFee?: Maybe<SortOrder>;
  processingFeesRefunded?: Maybe<SortOrder>;
};

export type StripeDisputeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeDisputeInput>;
};

export type StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePaymentIntentInputEnvelope>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
};

export type StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
};

export type StripeDisputeUncheckedCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedCreateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeDisputeInput>;
};

export type StripeDisputeUncheckedCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeDisputeInput>;
};

export type StripeDisputeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeDisputeNestedInput>;
};

export type StripeDisputeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedUpdateManyWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedUpdateManyWithoutStripeDisputesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput>>;
  upsert?: Maybe<Array<StripeDisputeUpsertWithWhereUniqueWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePaymentIntentInputEnvelope>;
  set?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  delete?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  update?: Maybe<Array<StripeDisputeUpdateWithWhereUniqueWithoutStripePaymentIntentInput>>;
  updateMany?: Maybe<Array<StripeDisputeUpdateManyWithWhereWithoutStripePaymentIntentInput>>;
  deleteMany?: Maybe<Array<StripeDisputeScalarWhereInput>>;
};

export type StripeDisputeUncheckedUpdateManyWithoutStripePayoutNestedInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripeDisputeUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  delete?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  update?: Maybe<Array<StripeDisputeUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripeDisputeUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<StripeDisputeScalarWhereInput>>;
};

export type StripeDisputeUncheckedUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedUpdateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeDisputeNestedInput>;
};

export type StripeDisputeUncheckedUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeDisputeNestedInput>;
};

export type StripeDisputeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeDisputeNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeDisputesNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeDisputeNestedInput>;
};

export type StripeDisputeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUpdateManyWithWhereWithoutStripePaymentIntentInput = {
  where: StripeDisputeScalarWhereInput;
  data: StripeDisputeUncheckedUpdateManyWithoutStripeDisputeInput;
};

export type StripeDisputeUpdateManyWithWhereWithoutStripePayoutInput = {
  where: StripeDisputeScalarWhereInput;
  data: StripeDisputeUncheckedUpdateManyWithoutStripeDisputesInput;
};

export type StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput>>;
  upsert?: Maybe<Array<StripeDisputeUpsertWithWhereUniqueWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePaymentIntentInputEnvelope>;
  set?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  delete?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  update?: Maybe<Array<StripeDisputeUpdateWithWhereUniqueWithoutStripePaymentIntentInput>>;
  updateMany?: Maybe<Array<StripeDisputeUpdateManyWithWhereWithoutStripePaymentIntentInput>>;
  deleteMany?: Maybe<Array<StripeDisputeScalarWhereInput>>;
};

export type StripeDisputeUpdateManyWithoutStripePayoutNestedInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripeDisputeUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  delete?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  update?: Maybe<Array<StripeDisputeUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripeDisputeUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<StripeDisputeScalarWhereInput>>;
};

export type StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput = {
  create?: Maybe<StripeDisputeUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripeDisputeCreateOrConnectWithoutFinancialTransactionInput>;
  upsert?: Maybe<StripeDisputeUpsertWithoutFinancialTransactionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeDisputeWhereUniqueInput>;
  update?: Maybe<StripeDisputeUncheckedUpdateWithoutFinancialTransactionInput>;
};

export type StripeDisputeUpdateWithWhereUniqueWithoutStripePaymentIntentInput = {
  where: StripeDisputeWhereUniqueInput;
  data: StripeDisputeUncheckedUpdateWithoutStripePaymentIntentInput;
};

export type StripeDisputeUpdateWithWhereUniqueWithoutStripePayoutInput = {
  where: StripeDisputeWhereUniqueInput;
  data: StripeDisputeUncheckedUpdateWithoutStripePayoutInput;
};

export type StripeDisputeUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeDisputeNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeDisputesNestedInput>;
};

export type StripeDisputeUpdateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeDisputesNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeDisputeNestedInput>;
};

export type StripeDisputeUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeDisputeNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeDisputeNestedInput>;
};

export type StripeDisputeUpsertWithWhereUniqueWithoutStripePaymentIntentInput = {
  where: StripeDisputeWhereUniqueInput;
  update: StripeDisputeUncheckedUpdateWithoutStripePaymentIntentInput;
  create: StripeDisputeUncheckedCreateWithoutStripePaymentIntentInput;
};

export type StripeDisputeUpsertWithWhereUniqueWithoutStripePayoutInput = {
  where: StripeDisputeWhereUniqueInput;
  update: StripeDisputeUncheckedUpdateWithoutStripePayoutInput;
  create: StripeDisputeUncheckedCreateWithoutStripePayoutInput;
};

export type StripeDisputeUpsertWithoutFinancialTransactionInput = {
  update: StripeDisputeUncheckedUpdateWithoutFinancialTransactionInput;
  create: StripeDisputeUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripeDisputeWhereInput = {
  AND?: Maybe<Array<StripeDisputeWhereInput>>;
  OR?: Maybe<Array<StripeDisputeWhereInput>>;
  NOT?: Maybe<Array<StripeDisputeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  status?: Maybe<EnumStripeDisputeStatusFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripeTransferReversalId?: Maybe<StringNullableFilter>;
  stripeDisputeFeeChargeId?: Maybe<StringNullableFilter>;
  disputeFee?: Maybe<IntNullableFilter>;
  processingFeesRefunded?: Maybe<IntNullableFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
  stripePaymentIntent?: Maybe<StripePaymentIntentWhereInput>;
  stripePayout?: Maybe<StripePayoutWhereInput>;
  financialTransaction?: Maybe<FinancialTransactionWhereInput>;
};

export type StripeDisputeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  /** example 71.66% would be 0.7166 */
  serviceFeePercent: Scalars['Float'];
  serviceFeeAmount: Scalars['Int'];
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer: StripeCustomer;
  careStripeSubscription: CareStripeSubscription;
  stripePaymentMethod?: Maybe<StripePaymentMethod>;
  financialTransaction?: Maybe<FinancialTransaction>;
  relatedStripeInvoiceItems: Array<StripeInvoiceItem>;
  enrollmentPayment: Array<CareEnrollmentPayment>;
  lapsedPayment?: Maybe<CareLapsedPayment>;
  careSubscriptionTrueUps: Array<CareSubscriptionTrueUp>;
  relatedEnrollmentSupportEvents: Array<EnrollmentSupportEvent>;
  _count: StripeInvoiceCountOutputType;
};


export type StripeInvoiceRelatedStripeInvoiceItemsArgs = {
  where?: Maybe<StripeInvoiceItemWhereInput>;
  orderBy?: Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeInvoiceItemScalarFieldEnum>;
};


export type StripeInvoiceEnrollmentPaymentArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
  orderBy?: Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareEnrollmentPaymentScalarFieldEnum>;
};


export type StripeInvoiceCareSubscriptionTrueUpsArgs = {
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
  orderBy?: Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareSubscriptionTrueUpScalarFieldEnum>;
};


export type StripeInvoiceRelatedEnrollmentSupportEventsArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
};

export type StripeInvoiceAvgAggregateOutputType = {
  __typename?: 'StripeInvoiceAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
};

export type StripeInvoiceAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  serviceFeePercent?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
};

export type StripeInvoiceCountAggregateOutputType = {
  __typename?: 'StripeInvoiceCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  paidAt: Scalars['Int'];
  dueAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: Scalars['Int'];
  currency: Scalars['Int'];
  collectionMethod: Scalars['Int'];
  serviceFeePercent: Scalars['Int'];
  serviceFeeAmount: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  careStripeSubscriptionId: Scalars['Int'];
  stripePaymentMethodId: Scalars['Int'];
  fundsAvailableAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeInvoiceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  collectionMethod?: Maybe<SortOrder>;
  serviceFeePercent?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
};

export type StripeInvoiceCountOutputType = {
  __typename?: 'StripeInvoiceCountOutputType';
  relatedStripeInvoiceItems: Scalars['Int'];
  enrollmentPayment: Scalars['Int'];
  careSubscriptionTrueUps: Scalars['Int'];
  relatedEnrollmentSupportEvents: Scalars['Int'];
};

export type StripeInvoiceCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceCreateManyCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope = {
  data: Array<StripeInvoiceCreateManyCareStripeSubscriptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeInvoiceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateManyStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateManyStripeCustomerInputEnvelope = {
  data: Array<StripeInvoiceCreateManyStripeCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeInvoiceCreateManyStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateManyStripePaymentMethodInputEnvelope = {
  data: Array<StripeInvoiceCreateManyStripePaymentMethodInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
};

export type StripeInvoiceCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
};

export type StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripePaymentMethodInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
};

export type StripeInvoiceCreateNestedOneWithoutCareSubscriptionTrueUpsInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
};

export type StripeInvoiceCreateNestedOneWithoutEnrollmentPaymentInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutEnrollmentPaymentInput>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
};

export type StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutFinancialTransactionInput>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
};

export type StripeInvoiceCreateNestedOneWithoutLapsedPaymentInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutLapsedPaymentInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutLapsedPaymentInput>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
};

export type StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutRelatedEnrollmentSupportEventsInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutRelatedEnrollmentSupportEventsInput>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
};

export type StripeInvoiceCreateNestedOneWithoutRelatedStripeInvoiceItemsInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutRelatedStripeInvoiceItemsInput>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
};

export type StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type StripeInvoiceCreateOrConnectWithoutCareSubscriptionTrueUpsInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
};

export type StripeInvoiceCreateOrConnectWithoutEnrollmentPaymentInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput;
};

export type StripeInvoiceCreateOrConnectWithoutFinancialTransactionInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripeInvoiceCreateOrConnectWithoutLapsedPaymentInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutLapsedPaymentInput;
};

export type StripeInvoiceCreateOrConnectWithoutRelatedEnrollmentSupportEventsInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutRelatedEnrollmentSupportEventsInput;
};

export type StripeInvoiceCreateOrConnectWithoutRelatedStripeInvoiceItemsInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput;
};

export type StripeInvoiceCreateOrConnectWithoutStripeCustomerInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutStripeCustomerInput;
};

export type StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutStripePaymentMethodInput;
};

export type StripeInvoiceCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceCreateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceCreateWithoutEnrollmentPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceCreateWithoutLapsedPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceCreateWithoutRelatedEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceCreateWithoutRelatedStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceCreateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceItem = {
  __typename?: 'StripeInvoiceItem';
  id: Scalars['String'];
  stripeInvoiceId: Scalars['String'];
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  stripeInvoice: StripeInvoice;
  enrollmentToStripeInvoiceItems: Array<CareEnrollmentToStripeInvoiceItem>;
  _count: StripeInvoiceItemCountOutputType;
};


export type StripeInvoiceItemEnrollmentToStripeInvoiceItemsArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  orderBy?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>;
};

export type StripeInvoiceItemAvgAggregateOutputType = {
  __typename?: 'StripeInvoiceItemAvgAggregateOutputType';
  unitAmount?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type StripeInvoiceItemAvgOrderByAggregateInput = {
  unitAmount?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
};

export type StripeInvoiceItemCountAggregateOutputType = {
  __typename?: 'StripeInvoiceItemCountAggregateOutputType';
  id: Scalars['Int'];
  stripeInvoiceId: Scalars['Int'];
  description: Scalars['Int'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['Int'];
  periodStart: Scalars['Int'];
  periodEnd: Scalars['Int'];
  stripeId: Scalars['Int'];
  stripeSubscriptionItemId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeInvoiceItemCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type StripeInvoiceItemCountOutputType = {
  __typename?: 'StripeInvoiceItemCountOutputType';
  enrollmentToStripeInvoiceItems: Scalars['Int'];
};

export type StripeInvoiceItemCreateInput = {
  id?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  stripeInvoice: StripeInvoiceCreateNestedOneWithoutRelatedStripeInvoiceItemsInput;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutStripeInvoiceItemInput>;
};

export type StripeInvoiceItemCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId: Scalars['String'];
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemCreateManyStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope = {
  data: Array<StripeInvoiceItemCreateManyStripeInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput = {
  create?: Maybe<Array<StripeInvoiceItemCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput>>;
  createMany?: Maybe<StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
};

export type StripeInvoiceItemCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput = {
  create?: Maybe<StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<StripeInvoiceItemCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  connect?: Maybe<StripeInvoiceItemWhereUniqueInput>;
};

export type StripeInvoiceItemCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput = {
  where: StripeInvoiceItemWhereUniqueInput;
  create: StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput = {
  where: StripeInvoiceItemWhereUniqueInput;
  create: StripeInvoiceItemUncheckedCreateWithoutStripeInvoiceInput;
};

export type StripeInvoiceItemCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  stripeInvoice: StripeInvoiceCreateNestedOneWithoutRelatedStripeInvoiceItemsInput;
};

export type StripeInvoiceItemCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutStripeInvoiceItemInput>;
};

export type StripeInvoiceItemListRelationFilter = {
  every?: Maybe<StripeInvoiceItemWhereInput>;
  some?: Maybe<StripeInvoiceItemWhereInput>;
  none?: Maybe<StripeInvoiceItemWhereInput>;
};

export type StripeInvoiceItemMaxAggregateOutputType = {
  __typename?: 'StripeInvoiceItemMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type StripeInvoiceItemMinAggregateOutputType = {
  __typename?: 'StripeInvoiceItemMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type StripeInvoiceItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeInvoiceItemOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeInvoiceId = 'stripeInvoiceId',
  Description = 'description',
  StripeId = 'stripeId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId'
}

export type StripeInvoiceItemOrderByRelevanceInput = {
  fields: Array<StripeInvoiceItemOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeInvoiceItemOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  _count?: Maybe<StripeInvoiceItemCountOrderByAggregateInput>;
  _avg?: Maybe<StripeInvoiceItemAvgOrderByAggregateInput>;
  _max?: Maybe<StripeInvoiceItemMaxOrderByAggregateInput>;
  _min?: Maybe<StripeInvoiceItemMinOrderByAggregateInput>;
  _sum?: Maybe<StripeInvoiceItemSumOrderByAggregateInput>;
};

export type StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  stripeInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripeInvoiceItemOrderByRelevanceInput>;
};

export type StripeInvoiceItemRelationFilter = {
  is?: Maybe<StripeInvoiceItemWhereInput>;
  isNot?: Maybe<StripeInvoiceItemWhereInput>;
};

export enum StripeInvoiceItemScalarFieldEnum {
  Id = 'id',
  StripeInvoiceId = 'stripeInvoiceId',
  Description = 'description',
  UnitAmount = 'unitAmount',
  Amount = 'amount',
  Quantity = 'quantity',
  Date = 'date',
  PeriodStart = 'periodStart',
  PeriodEnd = 'periodEnd',
  StripeId = 'stripeId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId'
}

export type StripeInvoiceItemScalarWhereInput = {
  AND?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
  OR?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
  NOT?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  stripeInvoiceId?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  unitAmount?: Maybe<IntFilter>;
  amount?: Maybe<IntFilter>;
  quantity?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  periodStart?: Maybe<DateTimeFilter>;
  periodEnd?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
};

export type StripeInvoiceItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeInvoiceItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeInvoiceItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeInvoiceItemScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  stripeInvoiceId?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  unitAmount?: Maybe<IntWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  quantity?: Maybe<IntWithAggregatesFilter>;
  date?: Maybe<DateTimeWithAggregatesFilter>;
  periodStart?: Maybe<DateTimeWithAggregatesFilter>;
  periodEnd?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type StripeInvoiceItemSumAggregateOutputType = {
  __typename?: 'StripeInvoiceItemSumAggregateOutputType';
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceItemSumOrderByAggregateInput = {
  unitAmount?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
};

export type StripeInvoiceItemUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId: Scalars['String'];
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceItemInput>;
};

export type StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput = {
  create?: Maybe<Array<StripeInvoiceItemCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput>>;
  createMany?: Maybe<StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
};

export type StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId: Scalars['String'];
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemUncheckedCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceItemInput>;
};

export type StripeInvoiceItemUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceItemNestedInput>;
};

export type StripeInvoiceItemUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemUncheckedUpdateManyWithoutRelatedStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput = {
  create?: Maybe<Array<StripeInvoiceItemCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<StripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
  createMany?: Maybe<StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<StripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
};

export type StripeInvoiceItemUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceItemNestedInput>;
};

export type StripeInvoiceItemUpdateInput = {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneRequiredWithoutRelatedStripeInvoiceItemsNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutStripeInvoiceItemNestedInput>;
};

export type StripeInvoiceItemUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceInput = {
  where: StripeInvoiceItemScalarWhereInput;
  data: StripeInvoiceItemUncheckedUpdateManyWithoutRelatedStripeInvoiceItemsInput;
};

export type StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput = {
  create?: Maybe<Array<StripeInvoiceItemCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<StripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
  createMany?: Maybe<StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<StripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
};

export type StripeInvoiceItemUpdateOneRequiredWithoutEnrollmentToStripeInvoiceItemsNestedInput = {
  create?: Maybe<StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<StripeInvoiceItemCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  upsert?: Maybe<StripeInvoiceItemUpsertWithoutEnrollmentToStripeInvoiceItemsInput>;
  connect?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  update?: Maybe<StripeInvoiceItemUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type StripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceInput = {
  where: StripeInvoiceItemWhereUniqueInput;
  data: StripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceInput;
};

export type StripeInvoiceItemUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneRequiredWithoutRelatedStripeInvoiceItemsNestedInput>;
};

export type StripeInvoiceItemUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutStripeInvoiceItemNestedInput>;
};

export type StripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceInput = {
  where: StripeInvoiceItemWhereUniqueInput;
  update: StripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceInput;
  create: StripeInvoiceItemUncheckedCreateWithoutStripeInvoiceInput;
};

export type StripeInvoiceItemUpsertWithoutEnrollmentToStripeInvoiceItemsInput = {
  update: StripeInvoiceItemUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput;
  create: StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type StripeInvoiceItemWhereInput = {
  AND?: Maybe<Array<StripeInvoiceItemWhereInput>>;
  OR?: Maybe<Array<StripeInvoiceItemWhereInput>>;
  NOT?: Maybe<Array<StripeInvoiceItemWhereInput>>;
  id?: Maybe<StringFilter>;
  stripeInvoiceId?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  unitAmount?: Maybe<IntFilter>;
  amount?: Maybe<IntFilter>;
  quantity?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  periodStart?: Maybe<DateTimeFilter>;
  periodEnd?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
  stripeInvoice?: Maybe<StripeInvoiceWhereInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemListRelationFilter>;
};

export type StripeInvoiceItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceListRelationFilter = {
  every?: Maybe<StripeInvoiceWhereInput>;
  some?: Maybe<StripeInvoiceWhereInput>;
  none?: Maybe<StripeInvoiceWhereInput>;
};

export type StripeInvoiceMaxAggregateOutputType = {
  __typename?: 'StripeInvoiceMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  collectionMethod?: Maybe<SortOrder>;
  serviceFeePercent?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
};

export type StripeInvoiceMinAggregateOutputType = {
  __typename?: 'StripeInvoiceMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  collectionMethod?: Maybe<SortOrder>;
  serviceFeePercent?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
};

export type StripeInvoiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeInvoiceOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  StripeCustomerId = 'stripeCustomerId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  StripePaymentMethodId = 'stripePaymentMethodId'
}

export type StripeInvoiceOrderByRelevanceInput = {
  fields: Array<StripeInvoiceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeInvoiceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  collectionMethod?: Maybe<SortOrder>;
  serviceFeePercent?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  _count?: Maybe<StripeInvoiceCountOrderByAggregateInput>;
  _avg?: Maybe<StripeInvoiceAvgOrderByAggregateInput>;
  _max?: Maybe<StripeInvoiceMaxOrderByAggregateInput>;
  _min?: Maybe<StripeInvoiceMinOrderByAggregateInput>;
  _sum?: Maybe<StripeInvoiceSumOrderByAggregateInput>;
};

export type StripeInvoiceOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  collectionMethod?: Maybe<SortOrder>;
  serviceFeePercent?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  stripeCustomer?: Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  financialTransaction?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemOrderByRelationAggregateInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentOrderByRelationAggregateInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentOrderByWithRelationAndSearchRelevanceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpOrderByRelationAggregateInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripeInvoiceOrderByRelevanceInput>;
};

export type StripeInvoiceRelationFilter = {
  is?: Maybe<StripeInvoiceWhereInput>;
  isNot?: Maybe<StripeInvoiceWhereInput>;
};

export enum StripeInvoiceScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PaidAt = 'paidAt',
  DueAt = 'dueAt',
  StripeId = 'stripeId',
  Amount = 'amount',
  Total = 'total',
  Status = 'status',
  Currency = 'currency',
  CollectionMethod = 'collectionMethod',
  ServiceFeePercent = 'serviceFeePercent',
  ServiceFeeAmount = 'serviceFeeAmount',
  StripeCustomerId = 'stripeCustomerId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  StripePaymentMethodId = 'stripePaymentMethodId',
  FundsAvailableAt = 'fundsAvailableAt'
}

export type StripeInvoiceScalarWhereInput = {
  AND?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  OR?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  NOT?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  dueAt?: Maybe<DateTimeNullableFilter>;
  stripeId?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  total?: Maybe<IntFilter>;
  status?: Maybe<EnumStripeInvoiceStatusFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  collectionMethod?: Maybe<EnumStripeCollectionMethodFilter>;
  serviceFeePercent?: Maybe<FloatFilter>;
  serviceFeeAmount?: Maybe<IntFilter>;
  stripeCustomerId?: Maybe<StringFilter>;
  careStripeSubscriptionId?: Maybe<StringFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
};

export type StripeInvoiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeInvoiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeInvoiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeInvoiceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  paidAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  dueAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  total?: Maybe<IntWithAggregatesFilter>;
  status?: Maybe<EnumStripeInvoiceStatusWithAggregatesFilter>;
  currency?: Maybe<EnumCurrencyWithAggregatesFilter>;
  collectionMethod?: Maybe<EnumStripeCollectionMethodWithAggregatesFilter>;
  serviceFeePercent?: Maybe<FloatWithAggregatesFilter>;
  serviceFeeAmount?: Maybe<IntWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringWithAggregatesFilter>;
  careStripeSubscriptionId?: Maybe<StringWithAggregatesFilter>;
  stripePaymentMethodId?: Maybe<StringNullableWithAggregatesFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
};

export enum StripeInvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void'
}

export type StripeInvoiceSumAggregateOutputType = {
  __typename?: 'StripeInvoiceSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  serviceFeePercent?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
};

export type StripeInvoiceUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
};

export type StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
};

export type StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripePaymentMethodInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
};

export type StripeInvoiceUncheckedCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateWithoutLapsedPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateWithoutRelatedEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
};

export type StripeInvoiceUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerNestedInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutStripeInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodNestedInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripePaymentMethodInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
};

export type StripeInvoiceUncheckedUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUncheckedUpdateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUncheckedUpdateWithoutEnrollmentPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUncheckedUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUncheckedUpdateWithoutLapsedPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUncheckedUpdateWithoutRelatedEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
};

export type StripeInvoiceUncheckedUpdateWithoutRelatedStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUncheckedUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUncheckedUpdateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUpdateManyWithWhereWithoutCareStripeSubscriptionInput = {
  where: StripeInvoiceScalarWhereInput;
  data: StripeInvoiceUncheckedUpdateManyWithoutInvoicesInput;
};

export type StripeInvoiceUpdateManyWithWhereWithoutStripeCustomerInput = {
  where: StripeInvoiceScalarWhereInput;
  data: StripeInvoiceUncheckedUpdateManyWithoutInvoicesInput;
};

export type StripeInvoiceUpdateManyWithWhereWithoutStripePaymentMethodInput = {
  where: StripeInvoiceScalarWhereInput;
  data: StripeInvoiceUncheckedUpdateManyWithoutStripeInvoicesInput;
};

export type StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
};

export type StripeInvoiceUpdateManyWithoutStripeCustomerNestedInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
};

export type StripeInvoiceUpdateManyWithoutStripePaymentMethodNestedInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripePaymentMethodInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
};

export type StripeInvoiceUpdateOneRequiredWithoutLapsedPaymentNestedInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutLapsedPaymentInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutLapsedPaymentInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutLapsedPaymentInput>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutLapsedPaymentInput>;
};

export type StripeInvoiceUpdateOneRequiredWithoutRelatedStripeInvoiceItemsNestedInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutRelatedStripeInvoiceItemsInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutRelatedStripeInvoiceItemsInput>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutRelatedStripeInvoiceItemsInput>;
};

export type StripeInvoiceUpdateOneWithoutCareSubscriptionTrueUpsNestedInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutCareSubscriptionTrueUpsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutCareSubscriptionTrueUpsInput>;
};

export type StripeInvoiceUpdateOneWithoutEnrollmentPaymentNestedInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutEnrollmentPaymentInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutEnrollmentPaymentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutEnrollmentPaymentInput>;
};

export type StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutFinancialTransactionInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutFinancialTransactionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutFinancialTransactionInput>;
};

export type StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutRelatedEnrollmentSupportEventsInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutRelatedEnrollmentSupportEventsInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutRelatedEnrollmentSupportEventsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutRelatedEnrollmentSupportEventsInput>;
};

export type StripeInvoiceUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: StripeInvoiceWhereUniqueInput;
  data: StripeInvoiceUncheckedUpdateWithoutCareStripeSubscriptionInput;
};

export type StripeInvoiceUpdateWithWhereUniqueWithoutStripeCustomerInput = {
  where: StripeInvoiceWhereUniqueInput;
  data: StripeInvoiceUncheckedUpdateWithoutStripeCustomerInput;
};

export type StripeInvoiceUpdateWithWhereUniqueWithoutStripePaymentMethodInput = {
  where: StripeInvoiceWhereUniqueInput;
  data: StripeInvoiceUncheckedUpdateWithoutStripePaymentMethodInput;
};

export type StripeInvoiceUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUpdateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUpdateWithoutEnrollmentPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUpdateWithoutLapsedPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUpdateWithoutRelatedEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
};

export type StripeInvoiceUpdateWithoutRelatedStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUpdateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
};

export type StripeInvoiceUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: StripeInvoiceWhereUniqueInput;
  update: StripeInvoiceUncheckedUpdateWithoutCareStripeSubscriptionInput;
  create: StripeInvoiceUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type StripeInvoiceUpsertWithWhereUniqueWithoutStripeCustomerInput = {
  where: StripeInvoiceWhereUniqueInput;
  update: StripeInvoiceUncheckedUpdateWithoutStripeCustomerInput;
  create: StripeInvoiceUncheckedCreateWithoutStripeCustomerInput;
};

export type StripeInvoiceUpsertWithWhereUniqueWithoutStripePaymentMethodInput = {
  where: StripeInvoiceWhereUniqueInput;
  update: StripeInvoiceUncheckedUpdateWithoutStripePaymentMethodInput;
  create: StripeInvoiceUncheckedCreateWithoutStripePaymentMethodInput;
};

export type StripeInvoiceUpsertWithoutCareSubscriptionTrueUpsInput = {
  update: StripeInvoiceUncheckedUpdateWithoutCareSubscriptionTrueUpsInput;
  create: StripeInvoiceUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
};

export type StripeInvoiceUpsertWithoutEnrollmentPaymentInput = {
  update: StripeInvoiceUncheckedUpdateWithoutEnrollmentPaymentInput;
  create: StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput;
};

export type StripeInvoiceUpsertWithoutFinancialTransactionInput = {
  update: StripeInvoiceUncheckedUpdateWithoutFinancialTransactionInput;
  create: StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripeInvoiceUpsertWithoutLapsedPaymentInput = {
  update: StripeInvoiceUncheckedUpdateWithoutLapsedPaymentInput;
  create: StripeInvoiceUncheckedCreateWithoutLapsedPaymentInput;
};

export type StripeInvoiceUpsertWithoutRelatedEnrollmentSupportEventsInput = {
  update: StripeInvoiceUncheckedUpdateWithoutRelatedEnrollmentSupportEventsInput;
  create: StripeInvoiceUncheckedCreateWithoutRelatedEnrollmentSupportEventsInput;
};

export type StripeInvoiceUpsertWithoutRelatedStripeInvoiceItemsInput = {
  update: StripeInvoiceUncheckedUpdateWithoutRelatedStripeInvoiceItemsInput;
  create: StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput;
};

export type StripeInvoiceWhereInput = {
  AND?: Maybe<Array<StripeInvoiceWhereInput>>;
  OR?: Maybe<Array<StripeInvoiceWhereInput>>;
  NOT?: Maybe<Array<StripeInvoiceWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  dueAt?: Maybe<DateTimeNullableFilter>;
  stripeId?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  total?: Maybe<IntFilter>;
  status?: Maybe<EnumStripeInvoiceStatusFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  collectionMethod?: Maybe<EnumStripeCollectionMethodFilter>;
  serviceFeePercent?: Maybe<FloatFilter>;
  serviceFeeAmount?: Maybe<IntFilter>;
  stripeCustomerId?: Maybe<StringFilter>;
  careStripeSubscriptionId?: Maybe<StringFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
  stripeCustomer?: Maybe<StripeCustomerWhereInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodWhereInput>;
  financialTransaction?: Maybe<FinancialTransactionWhereInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemListRelationFilter>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentListRelationFilter>;
  lapsedPayment?: Maybe<CareLapsedPaymentWhereInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpListRelationFilter>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventListRelationFilter>;
};

export type StripeInvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripePayment = {
  __typename?: 'StripePayment';
  id: Scalars['String'];
  status: Scalars['String'];
  amount: Scalars['Int'];
  total?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  amountRefunded?: Maybe<Scalars['Int']>;
  fees?: Maybe<StripePaymentFees>;
  transferType?: Maybe<Scalars['String']>;
  receiptUrl?: Maybe<Scalars['String']>;
};

export enum StripePaymentError {
  GenericDecline = 'GenericDecline',
  Blocked = 'Blocked',
  CardNotSupported = 'CardNotSupported',
  CurrencyNotSupported = 'CurrencyNotSupported',
  DuplicateTransaction = 'DuplicateTransaction',
  ExpiredCard = 'ExpiredCard',
  Fraudulent = 'Fraudulent',
  IncorrectNumber = 'IncorrectNumber',
  IncorrectPin = 'IncorrectPIN',
  IncorrectCvc = 'IncorrectCVC',
  IncorrectPostalCode = 'IncorrectPostalCode',
  InsufficientFunds = 'InsufficientFunds',
  InvalidAccount = 'InvalidAccount',
  InvalidExpiry = 'InvalidExpiry',
  LostStolenCard = 'LostStolenCard'
}

export type StripePaymentFees = {
  __typename?: 'StripePaymentFees';
  clinicServiceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFeeAmount: Scalars['Int'];
  clientServiceFeePercent: Scalars['Float'];
};

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeId: Scalars['String'];
  status: StripePaymentIntentStatus;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType: StripePaymentTransferType;
  captureType: ChargeCaptureType;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation: Scalars['Boolean'];
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  stripePaymentMethod?: Maybe<StripePaymentMethod>;
  stripePayout?: Maybe<StripePayout>;
  StripeDispute: Array<StripeDispute>;
  stripeRefunds: Array<StripeRefund>;
  stripeTerminal?: Maybe<StripeTerminal>;
  stripeReceipt?: Maybe<StripeReceipt>;
  financialTransaction?: Maybe<FinancialTransaction>;
  sentBy?: Maybe<User>;
  invoices: Array<Invoice>;
  writebackLogEntries: Array<PaymentPimsWritebackLogEntry>;
  automationRunAction?: Maybe<AutomationRunAction>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntent>;
  _count: StripePaymentIntentCountOutputType;
};


export type StripePaymentIntentStripeDisputeArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
  orderBy?: Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeDisputeScalarFieldEnum>;
};


export type StripePaymentIntentStripeRefundsArgs = {
  where?: Maybe<StripeRefundWhereInput>;
  orderBy?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeRefundScalarFieldEnum>;
};


export type StripePaymentIntentInvoicesArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
};


export type StripePaymentIntentWritebackLogEntriesArgs = {
  where?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<PaymentPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PaymentPimsWritebackLogEntryScalarFieldEnum>;
};

export type StripePaymentIntentAvgAggregateOutputType = {
  __typename?: 'StripePaymentIntentAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  amountCaptured?: Maybe<Scalars['Float']>;
  totalCaptured?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Float']>;
  clientServiceFee?: Maybe<Scalars['Float']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type StripePaymentIntentAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type StripePaymentIntentCountAggregateOutputType = {
  __typename?: 'StripePaymentIntentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  status: Scalars['Int'];
  paidAt: Scalars['Int'];
  settledAt: Scalars['Int'];
  transferType: Scalars['Int'];
  captureType: Scalars['Int'];
  paymentMedium: Scalars['Int'];
  cardType: Scalars['Int'];
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured: Scalars['Int'];
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent: Scalars['Int'];
  clinicServiceFeeFlat: Scalars['Int'];
  clientServiceFee: Scalars['Int'];
  clientServiceFeePercent: Scalars['Int'];
  total: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  stripePaymentMethodId: Scalars['Int'];
  stripePayoutId: Scalars['Int'];
  stripeTerminalId: Scalars['Int'];
  stripeReceiptId: Scalars['Int'];
  emailOverride: Scalars['Int'];
  paymentIdentifier: Scalars['Int'];
  sentByAutomation: Scalars['Int'];
  sentByUserId: Scalars['Int'];
  fundsAvailableAt: Scalars['Int'];
  lastPaymentError: Scalars['Int'];
  paymentRelationship: Scalars['Int'];
  careAccountCreditIntentId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripePaymentIntentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  captureType?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTerminalId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  emailOverride?: Maybe<SortOrder>;
  paymentIdentifier?: Maybe<SortOrder>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  paymentRelationship?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
};

export type StripePaymentIntentCountOutputType = {
  __typename?: 'StripePaymentIntentCountOutputType';
  StripeDispute: Scalars['Int'];
  stripeRefunds: Scalars['Int'];
  invoices: Scalars['Int'];
  writebackLogEntries: Scalars['Int'];
};

export type StripePaymentIntentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
};

export type StripePaymentIntentCreateManyCareAccountCreditIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentCreateManyCareAccountCreditIntentInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyCareAccountCreditIntentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentCreateManyClinicInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentCreateManyClinicPetParentInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentCreateManySentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentCreateManySentByInputEnvelope = {
  data: Array<StripePaymentIntentCreateManySentByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyStripePaymentMethodInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentCreateManyStripePayoutInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyStripePayoutInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyStripeTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentCreateManyStripeTerminalInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyStripeTerminalInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateNestedManyWithoutCareAccountCreditIntentInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutCareAccountCreditIntentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyCareAccountCreditIntentInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedManyWithoutInvoicesInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutInvoicesInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutInvoicesInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedManyWithoutSentByInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutSentByInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManySentByInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedManyWithoutStripeTerminalInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripeTerminalInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripeTerminalInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutAutomationRunActionInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutFinancialTransactionInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutStripeDisputeInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeDisputeInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutStripeReceiptInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeRefundsInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutWritebackLogEntriesInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutWritebackLogEntriesInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
};

export type StripePaymentIntentCreateOrConnectWithoutAutomationRunActionInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput;
};

export type StripePaymentIntentCreateOrConnectWithoutCareAccountCreditIntentInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutCareAccountCreditIntentInput;
};

export type StripePaymentIntentCreateOrConnectWithoutClinicInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutClinicInput;
};

export type StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutClinicPetParentInput;
};

export type StripePaymentIntentCreateOrConnectWithoutFinancialTransactionInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripePaymentIntentCreateOrConnectWithoutInvoicesInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutInvoicesInput;
};

export type StripePaymentIntentCreateOrConnectWithoutSentByInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutSentByInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripeDisputeInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripePaymentMethodInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripePayoutInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripePayoutInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripeRefundsInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeTerminalInput;
};

export type StripePaymentIntentCreateOrConnectWithoutWritebackLogEntriesInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput;
};

export type StripePaymentIntentCreateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
};

export type StripePaymentIntentCreateWithoutCareAccountCreditIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
};

export type StripePaymentIntentCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
};

export type StripePaymentIntentCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
};

export type StripePaymentIntentCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
};

export type StripePaymentIntentCreateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
};

export type StripePaymentIntentCreateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
};

export type StripePaymentIntentCreateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
};

export type StripePaymentIntentCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
};

export type StripePaymentIntentCreateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
};

export type StripePaymentIntentCreateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
};

export type StripePaymentIntentCreateWithoutStripeTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
};

export type StripePaymentIntentCreateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
};

export type StripePaymentIntentListRelationFilter = {
  every?: Maybe<StripePaymentIntentWhereInput>;
  some?: Maybe<StripePaymentIntentWhereInput>;
  none?: Maybe<StripePaymentIntentWhereInput>;
};

export type StripePaymentIntentMaxAggregateOutputType = {
  __typename?: 'StripePaymentIntentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  captureType?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTerminalId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  emailOverride?: Maybe<SortOrder>;
  paymentIdentifier?: Maybe<SortOrder>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  paymentRelationship?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
};

export type StripePaymentIntentMinAggregateOutputType = {
  __typename?: 'StripePaymentIntentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  captureType?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTerminalId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  emailOverride?: Maybe<SortOrder>;
  paymentIdentifier?: Maybe<SortOrder>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  paymentRelationship?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
};

export type StripePaymentIntentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripePaymentIntentOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  StripePaymentMethodId = 'stripePaymentMethodId',
  StripePayoutId = 'stripePayoutId',
  StripeTerminalId = 'stripeTerminalId',
  StripeReceiptId = 'stripeReceiptId',
  EmailOverride = 'emailOverride',
  PaymentIdentifier = 'paymentIdentifier',
  SentByUserId = 'sentByUserId',
  CareAccountCreditIntentId = 'careAccountCreditIntentId'
}

export type StripePaymentIntentOrderByRelevanceInput = {
  fields: Array<StripePaymentIntentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripePaymentIntentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  captureType?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTerminalId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  emailOverride?: Maybe<SortOrder>;
  paymentIdentifier?: Maybe<SortOrder>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  paymentRelationship?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  _count?: Maybe<StripePaymentIntentCountOrderByAggregateInput>;
  _avg?: Maybe<StripePaymentIntentAvgOrderByAggregateInput>;
  _max?: Maybe<StripePaymentIntentMaxOrderByAggregateInput>;
  _min?: Maybe<StripePaymentIntentMinOrderByAggregateInput>;
  _sum?: Maybe<StripePaymentIntentSumOrderByAggregateInput>;
};

export type StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  captureType?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTerminalId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  emailOverride?: Maybe<SortOrder>;
  paymentIdentifier?: Maybe<SortOrder>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  paymentRelationship?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  stripePayout?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  StripeDispute?: Maybe<StripeDisputeOrderByRelationAggregateInput>;
  stripeRefunds?: Maybe<StripeRefundOrderByRelationAggregateInput>;
  stripeTerminal?: Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>;
  stripeReceipt?: Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>;
  financialTransaction?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  sentBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  invoices?: Maybe<InvoiceOrderByRelationAggregateInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryOrderByRelationAggregateInput>;
  automationRunAction?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<StripePaymentIntentOrderByRelevanceInput>;
};

export type StripePaymentIntentRelationFilter = {
  is?: Maybe<StripePaymentIntentWhereInput>;
  isNot?: Maybe<StripePaymentIntentWhereInput>;
};

export enum StripePaymentIntentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeId = 'stripeId',
  Status = 'status',
  PaidAt = 'paidAt',
  SettledAt = 'settledAt',
  TransferType = 'transferType',
  CaptureType = 'captureType',
  PaymentMedium = 'paymentMedium',
  CardType = 'cardType',
  Amount = 'amount',
  AmountCaptured = 'amountCaptured',
  TotalCaptured = 'totalCaptured',
  ServiceFeeAmount = 'serviceFeeAmount',
  ClinicServiceFeePercent = 'clinicServiceFeePercent',
  ClinicServiceFeeFlat = 'clinicServiceFeeFlat',
  ClientServiceFee = 'clientServiceFee',
  ClientServiceFeePercent = 'clientServiceFeePercent',
  Total = 'total',
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  StripePaymentMethodId = 'stripePaymentMethodId',
  StripePayoutId = 'stripePayoutId',
  StripeTerminalId = 'stripeTerminalId',
  StripeReceiptId = 'stripeReceiptId',
  EmailOverride = 'emailOverride',
  PaymentIdentifier = 'paymentIdentifier',
  SentByAutomation = 'sentByAutomation',
  SentByUserId = 'sentByUserId',
  FundsAvailableAt = 'fundsAvailableAt',
  LastPaymentError = 'lastPaymentError',
  PaymentRelationship = 'paymentRelationship',
  CareAccountCreditIntentId = 'careAccountCreditIntentId'
}

export type StripePaymentIntentScalarWhereInput = {
  AND?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  OR?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  NOT?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  status?: Maybe<EnumStripePaymentIntentStatusFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  settledAt?: Maybe<DateTimeNullableFilter>;
  transferType?: Maybe<EnumStripePaymentTransferTypeFilter>;
  captureType?: Maybe<EnumChargeCaptureTypeFilter>;
  paymentMedium?: Maybe<EnumPaymentMediumNullableFilter>;
  cardType?: Maybe<EnumCardFundingNullableFilter>;
  amount?: Maybe<IntFilter>;
  amountCaptured?: Maybe<IntFilter>;
  totalCaptured?: Maybe<IntNullableFilter>;
  serviceFeeAmount?: Maybe<IntFilter>;
  clinicServiceFeePercent?: Maybe<FloatNullableFilter>;
  clinicServiceFeeFlat?: Maybe<IntNullableFilter>;
  clientServiceFee?: Maybe<IntNullableFilter>;
  clientServiceFeePercent?: Maybe<FloatNullableFilter>;
  total?: Maybe<IntFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripeTerminalId?: Maybe<StringNullableFilter>;
  stripeReceiptId?: Maybe<StringNullableFilter>;
  emailOverride?: Maybe<StringNullableFilter>;
  paymentIdentifier?: Maybe<StringNullableFilter>;
  sentByAutomation?: Maybe<BoolFilter>;
  sentByUserId?: Maybe<StringNullableFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableFilter>;
  paymentRelationship?: Maybe<EnumPaymentRelationshipNullableFilter>;
  careAccountCreditIntentId?: Maybe<StringNullableFilter>;
};

export type StripePaymentIntentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripePaymentIntentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripePaymentIntentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripePaymentIntentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumStripePaymentIntentStatusWithAggregatesFilter>;
  paidAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  settledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  transferType?: Maybe<EnumStripePaymentTransferTypeWithAggregatesFilter>;
  captureType?: Maybe<EnumChargeCaptureTypeWithAggregatesFilter>;
  paymentMedium?: Maybe<EnumPaymentMediumNullableWithAggregatesFilter>;
  cardType?: Maybe<EnumCardFundingNullableWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  amountCaptured?: Maybe<IntWithAggregatesFilter>;
  totalCaptured?: Maybe<IntNullableWithAggregatesFilter>;
  serviceFeeAmount?: Maybe<IntWithAggregatesFilter>;
  clinicServiceFeePercent?: Maybe<FloatNullableWithAggregatesFilter>;
  clinicServiceFeeFlat?: Maybe<IntNullableWithAggregatesFilter>;
  clientServiceFee?: Maybe<IntNullableWithAggregatesFilter>;
  clientServiceFeePercent?: Maybe<FloatNullableWithAggregatesFilter>;
  total?: Maybe<IntWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePaymentMethodId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePayoutId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeTerminalId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeReceiptId?: Maybe<StringNullableWithAggregatesFilter>;
  emailOverride?: Maybe<StringNullableWithAggregatesFilter>;
  paymentIdentifier?: Maybe<StringNullableWithAggregatesFilter>;
  sentByAutomation?: Maybe<BoolWithAggregatesFilter>;
  sentByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableWithAggregatesFilter>;
  paymentRelationship?: Maybe<EnumPaymentRelationshipNullableWithAggregatesFilter>;
  careAccountCreditIntentId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum StripePaymentIntentStatus {
  Created = 'Created',
  Succeeded = 'Succeeded',
  Canceled = 'Canceled',
  RequiresPaymentMethod = 'Requires_Payment_Method',
  RequiresConfirmation = 'Requires_Confirmation',
  RequiresAction = 'Requires_Action',
  Processing = 'Processing',
  RequiresCapture = 'Requires_Capture'
}

export type StripePaymentIntentSubscription = {
  __typename?: 'StripePaymentIntentSubscription';
  node: StripePaymentIntent;
  model: Scalars['String'];
  mutation: MutationType;
};

export type StripePaymentIntentSumAggregateOutputType = {
  __typename?: 'StripePaymentIntentSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
};

export type StripePaymentIntentSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type StripePaymentIntentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutCareAccountCreditIntentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyCareAccountCreditIntentInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutInvoicesInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutInvoicesInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutSentByInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManySentByInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutStripeTerminalInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripeTerminalInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripeTerminalInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedOneWithoutStripeReceiptInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutCareAccountCreditIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripeTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutCareAccountCreditIntentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyCareAccountCreditIntentInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutCareAccountCreditIntentInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutInvoicesInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutInvoicesInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutInvoicesInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutInvoicesInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutInvoicesInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutSentByInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutSentByInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManySentByInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutSentByInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutSentByInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutSentStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutStripeTerminalNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripeTerminalInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripeTerminalInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripeTerminalInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripeTerminalInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripeTerminalInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateOneWithoutStripeReceiptNestedInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutStripeReceiptInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutStripeReceiptInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutCareAccountCreditIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripeTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
};

export type StripePaymentIntentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutCareAccountCreditIntentInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutClinicInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutInvoicesInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutSentByInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutSentStripePaymentIntentsInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutStripePaymentMethodInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutStripePayoutInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutStripeTerminalInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutPaymentsInput;
};

export type StripePaymentIntentUpdateManyWithoutCareAccountCreditIntentNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutCareAccountCreditIntentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyCareAccountCreditIntentInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutCareAccountCreditIntentInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateManyWithoutInvoicesNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutInvoicesInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutInvoicesInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutInvoicesInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutInvoicesInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutInvoicesInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateManyWithoutSentByNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutSentByInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutSentByInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManySentByInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutSentByInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutSentByInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateManyWithoutStripePaymentMethodNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateManyWithoutStripePayoutNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateManyWithoutStripeTerminalNestedInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripeTerminalInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripeTerminalInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripeTerminalInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripeTerminalInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripeTerminalInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateOneRequiredWithoutWritebackLogEntriesNestedInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutWritebackLogEntriesInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutWritebackLogEntriesInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutWritebackLogEntriesInput>;
};

export type StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutAutomationRunActionInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutAutomationRunActionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutAutomationRunActionInput>;
};

export type StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutFinancialTransactionInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutFinancialTransactionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutFinancialTransactionInput>;
};

export type StripePaymentIntentUpdateOneWithoutStripeDisputeNestedInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeDisputeInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutStripeDisputeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutStripeDisputeInput>;
};

export type StripePaymentIntentUpdateOneWithoutStripeReceiptNestedInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutStripeReceiptInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutStripeReceiptInput>;
};

export type StripePaymentIntentUpdateOneWithoutStripeRefundsNestedInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeRefundsInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutStripeRefundsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutStripeRefundsInput>;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutCareAccountCreditIntentInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutClinicInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutClinicInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutClinicPetParentInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutInvoicesInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutInvoicesInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutSentByInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutSentByInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutStripePaymentMethodInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutStripePaymentMethodInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutStripePayoutInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutStripePayoutInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutStripeTerminalInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutStripeTerminalInput;
};

export type StripePaymentIntentUpdateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
};

export type StripePaymentIntentUpdateWithoutCareAccountCreditIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
};

export type StripePaymentIntentUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
};

export type StripePaymentIntentUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
};

export type StripePaymentIntentUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
};

export type StripePaymentIntentUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
};

export type StripePaymentIntentUpdateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
};

export type StripePaymentIntentUpdateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
};

export type StripePaymentIntentUpdateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
};

export type StripePaymentIntentUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
};

export type StripePaymentIntentUpdateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
};

export type StripePaymentIntentUpdateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
};

export type StripePaymentIntentUpdateWithoutStripeTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
};

export type StripePaymentIntentUpdateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  cardType?: Maybe<CardFunding>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  emailOverride?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutCareAccountCreditIntentInput;
  create: StripePaymentIntentUncheckedCreateWithoutCareAccountCreditIntentInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutClinicInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutClinicInput;
  create: StripePaymentIntentUncheckedCreateWithoutClinicInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutClinicPetParentInput;
  create: StripePaymentIntentUncheckedCreateWithoutClinicPetParentInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutInvoicesInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutInvoicesInput;
  create: StripePaymentIntentUncheckedCreateWithoutInvoicesInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutSentByInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutSentByInput;
  create: StripePaymentIntentUncheckedCreateWithoutSentByInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutStripePaymentMethodInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutStripePaymentMethodInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripePaymentMethodInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutStripePayoutInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutStripePayoutInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripePayoutInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutStripeTerminalInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutStripeTerminalInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeTerminalInput;
};

export type StripePaymentIntentUpsertWithoutAutomationRunActionInput = {
  update: StripePaymentIntentUncheckedUpdateWithoutAutomationRunActionInput;
  create: StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput;
};

export type StripePaymentIntentUpsertWithoutFinancialTransactionInput = {
  update: StripePaymentIntentUncheckedUpdateWithoutFinancialTransactionInput;
  create: StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripePaymentIntentUpsertWithoutStripeDisputeInput = {
  update: StripePaymentIntentUncheckedUpdateWithoutStripeDisputeInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput;
};

export type StripePaymentIntentUpsertWithoutStripeReceiptInput = {
  update: StripePaymentIntentUncheckedUpdateWithoutStripeReceiptInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput;
};

export type StripePaymentIntentUpsertWithoutStripeRefundsInput = {
  update: StripePaymentIntentUncheckedUpdateWithoutStripeRefundsInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput;
};

export type StripePaymentIntentUpsertWithoutWritebackLogEntriesInput = {
  update: StripePaymentIntentUncheckedUpdateWithoutWritebackLogEntriesInput;
  create: StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput;
};

export type StripePaymentIntentWhereInput = {
  AND?: Maybe<Array<StripePaymentIntentWhereInput>>;
  OR?: Maybe<Array<StripePaymentIntentWhereInput>>;
  NOT?: Maybe<Array<StripePaymentIntentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  status?: Maybe<EnumStripePaymentIntentStatusFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  settledAt?: Maybe<DateTimeNullableFilter>;
  transferType?: Maybe<EnumStripePaymentTransferTypeFilter>;
  captureType?: Maybe<EnumChargeCaptureTypeFilter>;
  paymentMedium?: Maybe<EnumPaymentMediumNullableFilter>;
  cardType?: Maybe<EnumCardFundingNullableFilter>;
  amount?: Maybe<IntFilter>;
  amountCaptured?: Maybe<IntFilter>;
  totalCaptured?: Maybe<IntNullableFilter>;
  serviceFeeAmount?: Maybe<IntFilter>;
  clinicServiceFeePercent?: Maybe<FloatNullableFilter>;
  clinicServiceFeeFlat?: Maybe<IntNullableFilter>;
  clientServiceFee?: Maybe<IntNullableFilter>;
  clientServiceFeePercent?: Maybe<FloatNullableFilter>;
  total?: Maybe<IntFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripeTerminalId?: Maybe<StringNullableFilter>;
  stripeReceiptId?: Maybe<StringNullableFilter>;
  emailOverride?: Maybe<StringNullableFilter>;
  paymentIdentifier?: Maybe<StringNullableFilter>;
  sentByAutomation?: Maybe<BoolFilter>;
  sentByUserId?: Maybe<StringNullableFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableFilter>;
  paymentRelationship?: Maybe<EnumPaymentRelationshipNullableFilter>;
  careAccountCreditIntentId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodWhereInput>;
  stripePayout?: Maybe<StripePayoutWhereInput>;
  StripeDispute?: Maybe<StripeDisputeListRelationFilter>;
  stripeRefunds?: Maybe<StripeRefundListRelationFilter>;
  stripeTerminal?: Maybe<StripeTerminalWhereInput>;
  stripeReceipt?: Maybe<StripeReceiptWhereInput>;
  financialTransaction?: Maybe<FinancialTransactionWhereInput>;
  sentBy?: Maybe<UserWhereInput>;
  invoices?: Maybe<InvoiceListRelationFilter>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryListRelationFilter>;
  automationRunAction?: Maybe<AutomationRunActionWhereInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentWhereInput>;
};

export type StripePaymentIntentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
};

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  isReusable: Scalars['Boolean'];
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type: StripePaymentMethodType;
  permitsFutureUsage: Scalars['Boolean'];
  clinicPetParent?: Maybe<ClinicPetParent>;
  stripeCustomer?: Maybe<StripeCustomer>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  careStripeSubscriptions: Array<CareStripeSubscription>;
  stripeInvoices: Array<StripeInvoice>;
  careSubscriptionTrueUps: Array<CareSubscriptionTrueUp>;
  _count: StripePaymentMethodCountOutputType;
};


export type StripePaymentMethodStripePaymentIntentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};


export type StripePaymentMethodCareStripeSubscriptionsArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
  orderBy?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareStripeSubscriptionScalarFieldEnum>;
};


export type StripePaymentMethodStripeInvoicesArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
  orderBy?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeInvoiceScalarFieldEnum>;
};


export type StripePaymentMethodCareSubscriptionTrueUpsArgs = {
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
  orderBy?: Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareSubscriptionTrueUpScalarFieldEnum>;
};

export type StripePaymentMethodAvgAggregateOutputType = {
  __typename?: 'StripePaymentMethodAvgAggregateOutputType';
  expirationMonth?: Maybe<Scalars['Float']>;
  expirationYear?: Maybe<Scalars['Float']>;
};

export type StripePaymentMethodAvgOrderByAggregateInput = {
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
};

export type StripePaymentMethodCountAggregateOutputType = {
  __typename?: 'StripePaymentMethodCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isDeleted: Scalars['Int'];
  stripeId: Scalars['Int'];
  last4: Scalars['Int'];
  fingerprint: Scalars['Int'];
  isDefault: Scalars['Int'];
  isExpired: Scalars['Int'];
  isReusable: Scalars['Int'];
  funding: Scalars['Int'];
  cardBrand: Scalars['Int'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  type: Scalars['Int'];
  permitsFutureUsage: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripePaymentMethodCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
};

export type StripePaymentMethodCountOutputType = {
  __typename?: 'StripePaymentMethodCountOutputType';
  stripePaymentIntents: Scalars['Int'];
  careStripeSubscriptions: Scalars['Int'];
  stripeInvoices: Scalars['Int'];
  careSubscriptionTrueUps: Scalars['Int'];
};

export type StripePaymentMethodCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodCreateManyClinicPetParentInputEnvelope = {
  data: Array<StripePaymentMethodCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodCreateManyStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodCreateManyStripeCustomerInputEnvelope = {
  data: Array<StripePaymentMethodCreateManyStripeCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
};

export type StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
};

export type StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput = {
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutCareStripeSubscriptionsInput>;
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
};

export type StripePaymentMethodCreateNestedOneWithoutCareSubscriptionTrueUpsInput = {
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
};

export type StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput = {
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutStripeInvoicesInput>;
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
};

export type StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput = {
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutStripePaymentIntentsInput>;
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
};

export type StripePaymentMethodCreateOrConnectWithoutCareStripeSubscriptionsInput = {
  where: StripePaymentMethodWhereUniqueInput;
  create: StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput;
};

export type StripePaymentMethodCreateOrConnectWithoutCareSubscriptionTrueUpsInput = {
  where: StripePaymentMethodWhereUniqueInput;
  create: StripePaymentMethodUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
};

export type StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput = {
  where: StripePaymentMethodWhereUniqueInput;
  create: StripePaymentMethodUncheckedCreateWithoutClinicPetParentInput;
};

export type StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput = {
  where: StripePaymentMethodWhereUniqueInput;
  create: StripePaymentMethodUncheckedCreateWithoutStripeCustomerInput;
};

export type StripePaymentMethodCreateOrConnectWithoutStripeInvoicesInput = {
  where: StripePaymentMethodWhereUniqueInput;
  create: StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput;
};

export type StripePaymentMethodCreateOrConnectWithoutStripePaymentIntentsInput = {
  where: StripePaymentMethodWhereUniqueInput;
  create: StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type StripePaymentMethodCreateWithoutCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodCreateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodCreateWithoutStripeInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodFingerprintAndCustomerIdCompoundUniqueInput = {
  fingerprint: Scalars['String'];
  stripeCustomerId: Scalars['String'];
};

export type StripePaymentMethodListRelationFilter = {
  every?: Maybe<StripePaymentMethodWhereInput>;
  some?: Maybe<StripePaymentMethodWhereInput>;
  none?: Maybe<StripePaymentMethodWhereInput>;
};

export type StripePaymentMethodMaxAggregateOutputType = {
  __typename?: 'StripePaymentMethodMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
};

export type StripePaymentMethodMinAggregateOutputType = {
  __typename?: 'StripePaymentMethodMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
};

export type StripePaymentMethodOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripePaymentMethodOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  Last4 = 'last4',
  Fingerprint = 'fingerprint',
  ClinicPetParentId = 'clinicPetParentId',
  StripeCustomerId = 'stripeCustomerId'
}

export type StripePaymentMethodOrderByRelevanceInput = {
  fields: Array<StripePaymentMethodOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripePaymentMethodOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
  _count?: Maybe<StripePaymentMethodCountOrderByAggregateInput>;
  _avg?: Maybe<StripePaymentMethodAvgOrderByAggregateInput>;
  _max?: Maybe<StripePaymentMethodMaxOrderByAggregateInput>;
  _min?: Maybe<StripePaymentMethodMinOrderByAggregateInput>;
  _sum?: Maybe<StripePaymentMethodSumOrderByAggregateInput>;
};

export type StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  stripeCustomer?: Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionOrderByRelationAggregateInput>;
  stripeInvoices?: Maybe<StripeInvoiceOrderByRelationAggregateInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripePaymentMethodOrderByRelevanceInput>;
};

export type StripePaymentMethodRelationFilter = {
  is?: Maybe<StripePaymentMethodWhereInput>;
  isNot?: Maybe<StripePaymentMethodWhereInput>;
};

export enum StripePaymentMethodScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsDeleted = 'isDeleted',
  StripeId = 'stripeId',
  Last4 = 'last4',
  Fingerprint = 'fingerprint',
  IsDefault = 'isDefault',
  IsExpired = 'isExpired',
  IsReusable = 'isReusable',
  Funding = 'funding',
  CardBrand = 'cardBrand',
  ExpirationMonth = 'expirationMonth',
  ExpirationYear = 'expirationYear',
  ClinicPetParentId = 'clinicPetParentId',
  StripeCustomerId = 'stripeCustomerId',
  Type = 'type',
  PermitsFutureUsage = 'permitsFutureUsage'
}

export type StripePaymentMethodScalarWhereInput = {
  AND?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
  OR?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
  NOT?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  stripeId?: Maybe<StringFilter>;
  last4?: Maybe<StringFilter>;
  fingerprint?: Maybe<StringNullableFilter>;
  isDefault?: Maybe<BoolFilter>;
  isExpired?: Maybe<BoolFilter>;
  isReusable?: Maybe<BoolFilter>;
  funding?: Maybe<EnumCardFundingNullableFilter>;
  cardBrand?: Maybe<EnumCardBrandFilter>;
  expirationMonth?: Maybe<IntFilter>;
  expirationYear?: Maybe<IntFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumStripePaymentMethodTypeFilter>;
  permitsFutureUsage?: Maybe<BoolFilter>;
};

export type StripePaymentMethodScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripePaymentMethodScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripePaymentMethodScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripePaymentMethodScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  last4?: Maybe<StringWithAggregatesFilter>;
  fingerprint?: Maybe<StringNullableWithAggregatesFilter>;
  isDefault?: Maybe<BoolWithAggregatesFilter>;
  isExpired?: Maybe<BoolWithAggregatesFilter>;
  isReusable?: Maybe<BoolWithAggregatesFilter>;
  funding?: Maybe<EnumCardFundingNullableWithAggregatesFilter>;
  cardBrand?: Maybe<EnumCardBrandWithAggregatesFilter>;
  expirationMonth?: Maybe<IntWithAggregatesFilter>;
  expirationYear?: Maybe<IntWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
  type?: Maybe<EnumStripePaymentMethodTypeWithAggregatesFilter>;
  permitsFutureUsage?: Maybe<BoolWithAggregatesFilter>;
};

export type StripePaymentMethodSumAggregateOutputType = {
  __typename?: 'StripePaymentMethodSumAggregateOutputType';
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
};

export type StripePaymentMethodSumOrderByAggregateInput = {
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
};

export enum StripePaymentMethodType {
  CardPresent = 'CardPresent',
  CardNotPresent = 'CardNotPresent'
}

export type StripePaymentMethodUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
};

export type StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
};

export type StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedCreateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
};

export type StripePaymentMethodUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<StripePaymentMethodUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentMethodUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<StripePaymentMethodUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
};

export type StripePaymentMethodUncheckedUpdateManyWithoutPaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerNestedInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<StripePaymentMethodUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentMethodUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<StripePaymentMethodUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
};

export type StripePaymentMethodUncheckedUpdateManyWithoutStripePaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodUncheckedUpdateWithoutCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
};

export type StripePaymentMethodUncheckedUpdateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
};

export type StripePaymentMethodUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
};

export type StripePaymentMethodUncheckedUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
};

export type StripePaymentMethodUncheckedUpdateWithoutStripeInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
};

export type StripePaymentMethodUncheckedUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
};

export type StripePaymentMethodUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodNestedInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripePaymentMethodNestedInput>;
};

export type StripePaymentMethodUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: StripePaymentMethodScalarWhereInput;
  data: StripePaymentMethodUncheckedUpdateManyWithoutStripePaymentMethodsInput;
};

export type StripePaymentMethodUpdateManyWithWhereWithoutStripeCustomerInput = {
  where: StripePaymentMethodScalarWhereInput;
  data: StripePaymentMethodUncheckedUpdateManyWithoutPaymentMethodsInput;
};

export type StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<StripePaymentMethodUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentMethodUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<StripePaymentMethodUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
};

export type StripePaymentMethodUpdateManyWithoutStripeCustomerNestedInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<StripePaymentMethodUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentMethodUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<StripePaymentMethodUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
};

export type StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput = {
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutCareStripeSubscriptionsInput>;
  upsert?: Maybe<StripePaymentMethodUpsertWithoutCareStripeSubscriptionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  update?: Maybe<StripePaymentMethodUncheckedUpdateWithoutCareStripeSubscriptionsInput>;
};

export type StripePaymentMethodUpdateOneWithoutCareSubscriptionTrueUpsNestedInput = {
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  upsert?: Maybe<StripePaymentMethodUpsertWithoutCareSubscriptionTrueUpsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  update?: Maybe<StripePaymentMethodUncheckedUpdateWithoutCareSubscriptionTrueUpsInput>;
};

export type StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput = {
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutStripeInvoicesInput>;
  upsert?: Maybe<StripePaymentMethodUpsertWithoutStripeInvoicesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  update?: Maybe<StripePaymentMethodUncheckedUpdateWithoutStripeInvoicesInput>;
};

export type StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput = {
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutStripePaymentIntentsInput>;
  upsert?: Maybe<StripePaymentMethodUpsertWithoutStripePaymentIntentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  update?: Maybe<StripePaymentMethodUncheckedUpdateWithoutStripePaymentIntentsInput>;
};

export type StripePaymentMethodUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: StripePaymentMethodWhereUniqueInput;
  data: StripePaymentMethodUncheckedUpdateWithoutClinicPetParentInput;
};

export type StripePaymentMethodUpdateWithWhereUniqueWithoutStripeCustomerInput = {
  where: StripePaymentMethodWhereUniqueInput;
  data: StripePaymentMethodUncheckedUpdateWithoutStripeCustomerInput;
};

export type StripePaymentMethodUpdateWithoutCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripePaymentMethodNestedInput>;
};

export type StripePaymentMethodUpdateWithoutCareSubscriptionTrueUpsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodNestedInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodNestedInput>;
};

export type StripePaymentMethodUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodNestedInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripePaymentMethodNestedInput>;
};

export type StripePaymentMethodUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodNestedInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripePaymentMethodNestedInput>;
};

export type StripePaymentMethodUpdateWithoutStripeInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodNestedInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripePaymentMethodNestedInput>;
};

export type StripePaymentMethodUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsNestedInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsNestedInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripePaymentMethodNestedInput>;
};

export type StripePaymentMethodUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: StripePaymentMethodWhereUniqueInput;
  update: StripePaymentMethodUncheckedUpdateWithoutClinicPetParentInput;
  create: StripePaymentMethodUncheckedCreateWithoutClinicPetParentInput;
};

export type StripePaymentMethodUpsertWithWhereUniqueWithoutStripeCustomerInput = {
  where: StripePaymentMethodWhereUniqueInput;
  update: StripePaymentMethodUncheckedUpdateWithoutStripeCustomerInput;
  create: StripePaymentMethodUncheckedCreateWithoutStripeCustomerInput;
};

export type StripePaymentMethodUpsertWithoutCareStripeSubscriptionsInput = {
  update: StripePaymentMethodUncheckedUpdateWithoutCareStripeSubscriptionsInput;
  create: StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput;
};

export type StripePaymentMethodUpsertWithoutCareSubscriptionTrueUpsInput = {
  update: StripePaymentMethodUncheckedUpdateWithoutCareSubscriptionTrueUpsInput;
  create: StripePaymentMethodUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
};

export type StripePaymentMethodUpsertWithoutStripeInvoicesInput = {
  update: StripePaymentMethodUncheckedUpdateWithoutStripeInvoicesInput;
  create: StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput;
};

export type StripePaymentMethodUpsertWithoutStripePaymentIntentsInput = {
  update: StripePaymentMethodUncheckedUpdateWithoutStripePaymentIntentsInput;
  create: StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type StripePaymentMethodWhereInput = {
  AND?: Maybe<Array<StripePaymentMethodWhereInput>>;
  OR?: Maybe<Array<StripePaymentMethodWhereInput>>;
  NOT?: Maybe<Array<StripePaymentMethodWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  stripeId?: Maybe<StringFilter>;
  last4?: Maybe<StringFilter>;
  fingerprint?: Maybe<StringNullableFilter>;
  isDefault?: Maybe<BoolFilter>;
  isExpired?: Maybe<BoolFilter>;
  isReusable?: Maybe<BoolFilter>;
  funding?: Maybe<EnumCardFundingNullableFilter>;
  cardBrand?: Maybe<EnumCardBrandFilter>;
  expirationMonth?: Maybe<IntFilter>;
  expirationYear?: Maybe<IntFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumStripePaymentMethodTypeFilter>;
  permitsFutureUsage?: Maybe<BoolFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  stripeCustomer?: Maybe<StripeCustomerWhereInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionListRelationFilter>;
  stripeInvoices?: Maybe<StripeInvoiceListRelationFilter>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpListRelationFilter>;
};

export type StripePaymentMethodWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  fingerprintAndCustomerId?: Maybe<StripePaymentMethodFingerprintAndCustomerIdCompoundUniqueInput>;
};

export type StripePaymentOutput = {
  __typename?: 'StripePaymentOutput';
  payment: StripePayment;
  paymentIntentClientSecret?: Maybe<Scalars['String']>;
  requiresAction?: Maybe<Scalars['Boolean']>;
};

export enum StripePaymentTransferType {
  TransferData = 'Transfer_Data',
  ApplicationFee = 'Application_Fee'
}

export type StripePayout = {
  __typename?: 'StripePayout';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccount>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriod>;
  stripeDisputes: Array<StripeDispute>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  stripeRefunds: Array<StripeRefund>;
  financialTransactions: Array<FinancialTransaction>;
  _count: StripePayoutCountOutputType;
};


export type StripePayoutStripeDisputesArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
  orderBy?: Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeDisputeScalarFieldEnum>;
};


export type StripePayoutStripePaymentIntentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};


export type StripePayoutStripeRefundsArgs = {
  where?: Maybe<StripeRefundWhereInput>;
  orderBy?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeRefundScalarFieldEnum>;
};


export type StripePayoutFinancialTransactionsArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
  orderBy?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FinancialTransactionScalarFieldEnum>;
};

export type StripePayoutAccount = {
  __typename?: 'StripePayoutAccount';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  stripePayouts: Array<StripePayout>;
  clinics: Array<Clinic>;
  _count: StripePayoutAccountCountOutputType;
  default: Scalars['Boolean'];
  type: StripePayoutAccountType;
  currency: Scalars['String'];
};


export type StripePayoutAccountStripePayoutsArgs = {
  where?: Maybe<StripePayoutWhereInput>;
  orderBy?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePayoutWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePayoutScalarFieldEnum>;
};


export type StripePayoutAccountClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};

export type StripePayoutAccountCountAggregateOutputType = {
  __typename?: 'StripePayoutAccountCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isDeleted: Scalars['Int'];
  stripeId: Scalars['Int'];
  last4: Scalars['Int'];
  bankName: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripePayoutAccountCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  bankName?: Maybe<SortOrder>;
};

export type StripePayoutAccountCountOutputType = {
  __typename?: 'StripePayoutAccountCountOutputType';
  stripePayouts: Scalars['Int'];
  clinics: Scalars['Int'];
};

export type StripePayoutAccountCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutCreateNestedManyWithoutStripePayoutAccountInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStripePayoutAccountsInput>;
};

export type StripePayoutAccountCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
};

export type StripePayoutAccountCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<StripePayoutAccountCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutAccountCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
};

export type StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput = {
  create?: Maybe<StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput>;
  connectOrCreate?: Maybe<StripePayoutAccountCreateOrConnectWithoutStripePayoutsInput>;
  connect?: Maybe<StripePayoutAccountWhereUniqueInput>;
};

export type StripePayoutAccountCreateOrConnectWithoutClinicsInput = {
  where: StripePayoutAccountWhereUniqueInput;
  create: StripePayoutAccountUncheckedCreateWithoutClinicsInput;
};

export type StripePayoutAccountCreateOrConnectWithoutStripePayoutsInput = {
  where: StripePayoutAccountWhereUniqueInput;
  create: StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput;
};

export type StripePayoutAccountCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutCreateNestedManyWithoutStripePayoutAccountInput>;
};

export type StripePayoutAccountCreateWithoutStripePayoutsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStripePayoutAccountsInput>;
};

export type StripePayoutAccountListRelationFilter = {
  every?: Maybe<StripePayoutAccountWhereInput>;
  some?: Maybe<StripePayoutAccountWhereInput>;
  none?: Maybe<StripePayoutAccountWhereInput>;
};

export type StripePayoutAccountMaxAggregateOutputType = {
  __typename?: 'StripePayoutAccountMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
};

export type StripePayoutAccountMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  bankName?: Maybe<SortOrder>;
};

export type StripePayoutAccountMinAggregateOutputType = {
  __typename?: 'StripePayoutAccountMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
};

export type StripePayoutAccountMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  bankName?: Maybe<SortOrder>;
};

export type StripePayoutAccountOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripePayoutAccountOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  Last4 = 'last4',
  BankName = 'bankName'
}

export type StripePayoutAccountOrderByRelevanceInput = {
  fields: Array<StripePayoutAccountOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripePayoutAccountOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  bankName?: Maybe<SortOrder>;
  _count?: Maybe<StripePayoutAccountCountOrderByAggregateInput>;
  _max?: Maybe<StripePayoutAccountMaxOrderByAggregateInput>;
  _min?: Maybe<StripePayoutAccountMinOrderByAggregateInput>;
};

export type StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  bankName?: Maybe<SortOrder>;
  stripePayouts?: Maybe<StripePayoutOrderByRelationAggregateInput>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripePayoutAccountOrderByRelevanceInput>;
};

export type StripePayoutAccountRelationFilter = {
  is?: Maybe<StripePayoutAccountWhereInput>;
  isNot?: Maybe<StripePayoutAccountWhereInput>;
};

export enum StripePayoutAccountScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsDeleted = 'isDeleted',
  StripeId = 'stripeId',
  Last4 = 'last4',
  BankName = 'bankName'
}

export type StripePayoutAccountScalarWhereInput = {
  AND?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
  OR?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
  NOT?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  stripeId?: Maybe<StringFilter>;
  last4?: Maybe<StringFilter>;
  bankName?: Maybe<StringNullableFilter>;
};

export type StripePayoutAccountScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripePayoutAccountScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripePayoutAccountScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripePayoutAccountScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  last4?: Maybe<StringWithAggregatesFilter>;
  bankName?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum StripePayoutAccountType {
  BankAccount = 'bank_account',
  Card = 'card'
}

export type StripePayoutAccountUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUncheckedCreateNestedManyWithoutStripePayoutAccountInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStripePayoutAccountsInput>;
};

export type StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<StripePayoutAccountCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutAccountCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
};

export type StripePayoutAccountUncheckedCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUncheckedCreateNestedManyWithoutStripePayoutAccountInput>;
};

export type StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStripePayoutAccountsInput>;
};

export type StripePayoutAccountUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUncheckedUpdateManyWithoutStripePayoutAccountNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStripePayoutAccountsNestedInput>;
};

export type StripePayoutAccountUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
};

export type StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput = {
  create?: Maybe<Array<StripePayoutAccountCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutAccountCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<StripePayoutAccountUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  delete?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  connect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  update?: Maybe<Array<StripePayoutAccountUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<StripePayoutAccountUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
};

export type StripePayoutAccountUncheckedUpdateManyWithoutStripePayoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
};

export type StripePayoutAccountUncheckedUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUncheckedUpdateManyWithoutStripePayoutAccountNestedInput>;
};

export type StripePayoutAccountUncheckedUpdateWithoutStripePayoutsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStripePayoutAccountsNestedInput>;
};

export type StripePayoutAccountUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUpdateManyWithoutStripePayoutAccountNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStripePayoutAccountsNestedInput>;
};

export type StripePayoutAccountUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
};

export type StripePayoutAccountUpdateManyWithWhereWithoutClinicsInput = {
  where: StripePayoutAccountScalarWhereInput;
  data: StripePayoutAccountUncheckedUpdateManyWithoutStripePayoutAccountsInput;
};

export type StripePayoutAccountUpdateManyWithoutClinicsNestedInput = {
  create?: Maybe<Array<StripePayoutAccountCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutAccountCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<StripePayoutAccountUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  delete?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  connect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  update?: Maybe<Array<StripePayoutAccountUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<StripePayoutAccountUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
};

export type StripePayoutAccountUpdateOneWithoutStripePayoutsNestedInput = {
  create?: Maybe<StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput>;
  connectOrCreate?: Maybe<StripePayoutAccountCreateOrConnectWithoutStripePayoutsInput>;
  upsert?: Maybe<StripePayoutAccountUpsertWithoutStripePayoutsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePayoutAccountWhereUniqueInput>;
  update?: Maybe<StripePayoutAccountUncheckedUpdateWithoutStripePayoutsInput>;
};

export type StripePayoutAccountUpdateWithWhereUniqueWithoutClinicsInput = {
  where: StripePayoutAccountWhereUniqueInput;
  data: StripePayoutAccountUncheckedUpdateWithoutClinicsInput;
};

export type StripePayoutAccountUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUpdateManyWithoutStripePayoutAccountNestedInput>;
};

export type StripePayoutAccountUpdateWithoutStripePayoutsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUpdateManyWithoutStripePayoutAccountsNestedInput>;
};

export type StripePayoutAccountUpsertWithWhereUniqueWithoutClinicsInput = {
  where: StripePayoutAccountWhereUniqueInput;
  update: StripePayoutAccountUncheckedUpdateWithoutClinicsInput;
  create: StripePayoutAccountUncheckedCreateWithoutClinicsInput;
};

export type StripePayoutAccountUpsertWithoutStripePayoutsInput = {
  update: StripePayoutAccountUncheckedUpdateWithoutStripePayoutsInput;
  create: StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput;
};

export type StripePayoutAccountWhereInput = {
  AND?: Maybe<Array<StripePayoutAccountWhereInput>>;
  OR?: Maybe<Array<StripePayoutAccountWhereInput>>;
  NOT?: Maybe<Array<StripePayoutAccountWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  stripeId?: Maybe<StringFilter>;
  last4?: Maybe<StringFilter>;
  bankName?: Maybe<StringNullableFilter>;
  stripePayouts?: Maybe<StripePayoutListRelationFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
};

export type StripePayoutAccountWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripePayoutAvgAggregateOutputType = {
  __typename?: 'StripePayoutAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type StripePayoutAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type StripePayoutCountAggregateOutputType = {
  __typename?: 'StripePayoutCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  amount: Scalars['Int'];
  arrivalDate: Scalars['Int'];
  status: Scalars['Int'];
  initiatedAt: Scalars['Int'];
  stripePayoutAccountId: Scalars['Int'];
  payoutBatchingPeriodId: Scalars['Int'];
  statementDescriptor: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripePayoutCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  stripePayoutAccountId?: Maybe<SortOrder>;
  payoutBatchingPeriodId?: Maybe<SortOrder>;
  statementDescriptor?: Maybe<SortOrder>;
};

export type StripePayoutCountOutputType = {
  __typename?: 'StripePayoutCountOutputType';
  stripeDisputes: Scalars['Int'];
  stripePaymentIntents: Scalars['Int'];
  stripeRefunds: Scalars['Int'];
  financialTransactions: Scalars['Int'];
};

export type StripePayoutCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodCreateNestedOneWithoutStripePayoutInput>;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
};

export type StripePayoutCreateManyStripePayoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
};

export type StripePayoutCreateManyStripePayoutAccountInputEnvelope = {
  data: Array<StripePayoutCreateManyStripePayoutAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePayoutCreateNestedManyWithoutStripePayoutAccountInput = {
  create?: Maybe<Array<StripePayoutCreateWithoutStripePayoutAccountInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutCreateOrConnectWithoutStripePayoutAccountInput>>;
  createMany?: Maybe<StripePayoutCreateManyStripePayoutAccountInputEnvelope>;
  connect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
};

export type StripePayoutCreateNestedOneWithoutFinancialTransactionsInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutFinancialTransactionsInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutFinancialTransactionsInput>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
};

export type StripePayoutCreateNestedOneWithoutPayoutBatchingPeriodInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutPayoutBatchingPeriodInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutPayoutBatchingPeriodInput>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
};

export type StripePayoutCreateNestedOneWithoutStripeDisputesInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripeDisputesInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripeDisputesInput>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
};

export type StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripePaymentIntentsInput>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
};

export type StripePayoutCreateNestedOneWithoutStripeRefundsInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripeRefundsInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripeRefundsInput>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
};

export type StripePayoutCreateOrConnectWithoutFinancialTransactionsInput = {
  where: StripePayoutWhereUniqueInput;
  create: StripePayoutUncheckedCreateWithoutFinancialTransactionsInput;
};

export type StripePayoutCreateOrConnectWithoutPayoutBatchingPeriodInput = {
  where: StripePayoutWhereUniqueInput;
  create: StripePayoutUncheckedCreateWithoutPayoutBatchingPeriodInput;
};

export type StripePayoutCreateOrConnectWithoutStripeDisputesInput = {
  where: StripePayoutWhereUniqueInput;
  create: StripePayoutUncheckedCreateWithoutStripeDisputesInput;
};

export type StripePayoutCreateOrConnectWithoutStripePaymentIntentsInput = {
  where: StripePayoutWhereUniqueInput;
  create: StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type StripePayoutCreateOrConnectWithoutStripePayoutAccountInput = {
  where: StripePayoutWhereUniqueInput;
  create: StripePayoutUncheckedCreateWithoutStripePayoutAccountInput;
};

export type StripePayoutCreateOrConnectWithoutStripeRefundsInput = {
  where: StripePayoutWhereUniqueInput;
  create: StripePayoutUncheckedCreateWithoutStripeRefundsInput;
};

export type StripePayoutCreateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodCreateNestedOneWithoutStripePayoutInput>;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutCreateWithoutPayoutBatchingPeriodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutCreateWithoutStripeDisputesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodCreateNestedOneWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodCreateNestedOneWithoutStripePayoutInput>;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutCreateWithoutStripePayoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodCreateNestedOneWithoutStripePayoutInput>;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutCreateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodCreateNestedOneWithoutStripePayoutInput>;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutListRelationFilter = {
  every?: Maybe<StripePayoutWhereInput>;
  some?: Maybe<StripePayoutWhereInput>;
  none?: Maybe<StripePayoutWhereInput>;
};

export type StripePayoutMaxAggregateOutputType = {
  __typename?: 'StripePayoutMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
};

export type StripePayoutMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  stripePayoutAccountId?: Maybe<SortOrder>;
  payoutBatchingPeriodId?: Maybe<SortOrder>;
  statementDescriptor?: Maybe<SortOrder>;
};

export type StripePayoutMinAggregateOutputType = {
  __typename?: 'StripePayoutMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
};

export type StripePayoutMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  stripePayoutAccountId?: Maybe<SortOrder>;
  payoutBatchingPeriodId?: Maybe<SortOrder>;
  statementDescriptor?: Maybe<SortOrder>;
};

export type StripePayoutOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripePayoutOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  StripePayoutAccountId = 'stripePayoutAccountId',
  PayoutBatchingPeriodId = 'payoutBatchingPeriodId',
  StatementDescriptor = 'statementDescriptor'
}

export type StripePayoutOrderByRelevanceInput = {
  fields: Array<StripePayoutOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripePayoutOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  stripePayoutAccountId?: Maybe<SortOrder>;
  payoutBatchingPeriodId?: Maybe<SortOrder>;
  statementDescriptor?: Maybe<SortOrder>;
  _count?: Maybe<StripePayoutCountOrderByAggregateInput>;
  _avg?: Maybe<StripePayoutAvgOrderByAggregateInput>;
  _max?: Maybe<StripePayoutMaxOrderByAggregateInput>;
  _min?: Maybe<StripePayoutMinOrderByAggregateInput>;
  _sum?: Maybe<StripePayoutSumOrderByAggregateInput>;
};

export type StripePayoutOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  stripePayoutAccountId?: Maybe<SortOrder>;
  payoutBatchingPeriodId?: Maybe<SortOrder>;
  statementDescriptor?: Maybe<SortOrder>;
  stripePayoutAccount?: Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodOrderByWithRelationAndSearchRelevanceInput>;
  stripeDisputes?: Maybe<StripeDisputeOrderByRelationAggregateInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  stripeRefunds?: Maybe<StripeRefundOrderByRelationAggregateInput>;
  financialTransactions?: Maybe<FinancialTransactionOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripePayoutOrderByRelevanceInput>;
};

export type StripePayoutRelationFilter = {
  is?: Maybe<StripePayoutWhereInput>;
  isNot?: Maybe<StripePayoutWhereInput>;
};

export enum StripePayoutScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeId = 'stripeId',
  Amount = 'amount',
  ArrivalDate = 'arrivalDate',
  Status = 'status',
  InitiatedAt = 'initiatedAt',
  StripePayoutAccountId = 'stripePayoutAccountId',
  PayoutBatchingPeriodId = 'payoutBatchingPeriodId',
  StatementDescriptor = 'statementDescriptor'
}

export type StripePayoutScalarWhereInput = {
  AND?: Maybe<Array<StripePayoutScalarWhereInput>>;
  OR?: Maybe<Array<StripePayoutScalarWhereInput>>;
  NOT?: Maybe<Array<StripePayoutScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  arrivalDate?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumStripePayoutStatusFilter>;
  initiatedAt?: Maybe<DateTimeNullableFilter>;
  stripePayoutAccountId?: Maybe<StringNullableFilter>;
  payoutBatchingPeriodId?: Maybe<StringNullableFilter>;
  statementDescriptor?: Maybe<StringNullableFilter>;
};

export type StripePayoutScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripePayoutScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripePayoutScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripePayoutScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  arrivalDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumStripePayoutStatusWithAggregatesFilter>;
  initiatedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  stripePayoutAccountId?: Maybe<StringNullableWithAggregatesFilter>;
  payoutBatchingPeriodId?: Maybe<StringNullableWithAggregatesFilter>;
  statementDescriptor?: Maybe<StringNullableWithAggregatesFilter>;
};

export type StripePayoutSchedule = {
  __typename?: 'StripePayoutSchedule';
  daysDelayed: Scalars['Int'];
  interval: Scalars['String'];
  automatic: Scalars['Boolean'];
};

export enum StripePayoutStatus {
  Paid = 'Paid',
  Pending = 'Pending',
  InTransit = 'In_Transit',
  Canceled = 'Canceled',
  Failed = 'Failed'
}

export type StripePayoutSumAggregateOutputType = {
  __typename?: 'StripePayoutSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
};

export type StripePayoutSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type StripePayoutUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedCreateNestedManyWithoutStripePayoutAccountInput = {
  create?: Maybe<Array<StripePayoutCreateWithoutStripePayoutAccountInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutCreateOrConnectWithoutStripePayoutAccountInput>>;
  createMany?: Maybe<StripePayoutCreateManyStripePayoutAccountInputEnvelope>;
  connect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
};

export type StripePayoutUncheckedCreateNestedOneWithoutPayoutBatchingPeriodInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutPayoutBatchingPeriodInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutPayoutBatchingPeriodInput>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
};

export type StripePayoutUncheckedCreateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedCreateWithoutPayoutBatchingPeriodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedCreateWithoutStripeDisputesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedCreateWithoutStripePayoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedCreateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutNestedInput>;
};

export type StripePayoutUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
};

export type StripePayoutUncheckedUpdateManyWithoutStripePayoutAccountNestedInput = {
  create?: Maybe<Array<StripePayoutCreateWithoutStripePayoutAccountInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutCreateOrConnectWithoutStripePayoutAccountInput>>;
  upsert?: Maybe<Array<StripePayoutUpsertWithWhereUniqueWithoutStripePayoutAccountInput>>;
  createMany?: Maybe<StripePayoutCreateManyStripePayoutAccountInputEnvelope>;
  set?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  delete?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  connect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  update?: Maybe<Array<StripePayoutUpdateWithWhereUniqueWithoutStripePayoutAccountInput>>;
  updateMany?: Maybe<Array<StripePayoutUpdateManyWithWhereWithoutStripePayoutAccountInput>>;
  deleteMany?: Maybe<Array<StripePayoutScalarWhereInput>>;
};

export type StripePayoutUncheckedUpdateManyWithoutStripePayoutsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
};

export type StripePayoutUncheckedUpdateOneWithoutPayoutBatchingPeriodNestedInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutPayoutBatchingPeriodInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutPayoutBatchingPeriodInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutPayoutBatchingPeriodInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutPayoutBatchingPeriodInput>;
};

export type StripePayoutUncheckedUpdateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutNestedInput>;
};

export type StripePayoutUncheckedUpdateWithoutPayoutBatchingPeriodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutNestedInput>;
};

export type StripePayoutUncheckedUpdateWithoutStripeDisputesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutNestedInput>;
};

export type StripePayoutUncheckedUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutNestedInput>;
};

export type StripePayoutUncheckedUpdateWithoutStripePayoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutNestedInput>;
};

export type StripePayoutUncheckedUpdateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutNestedInput>;
};

export type StripePayoutUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsNestedInput>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodUpdateOneWithoutStripePayoutNestedInput>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutNestedInput>;
};

export type StripePayoutUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
};

export type StripePayoutUpdateManyWithWhereWithoutStripePayoutAccountInput = {
  where: StripePayoutScalarWhereInput;
  data: StripePayoutUncheckedUpdateManyWithoutStripePayoutsInput;
};

export type StripePayoutUpdateManyWithoutStripePayoutAccountNestedInput = {
  create?: Maybe<Array<StripePayoutCreateWithoutStripePayoutAccountInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutCreateOrConnectWithoutStripePayoutAccountInput>>;
  upsert?: Maybe<Array<StripePayoutUpsertWithWhereUniqueWithoutStripePayoutAccountInput>>;
  createMany?: Maybe<StripePayoutCreateManyStripePayoutAccountInputEnvelope>;
  set?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  delete?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  connect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  update?: Maybe<Array<StripePayoutUpdateWithWhereUniqueWithoutStripePayoutAccountInput>>;
  updateMany?: Maybe<Array<StripePayoutUpdateManyWithWhereWithoutStripePayoutAccountInput>>;
  deleteMany?: Maybe<Array<StripePayoutScalarWhereInput>>;
};

export type StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutFinancialTransactionsInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutFinancialTransactionsInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutFinancialTransactionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutFinancialTransactionsInput>;
};

export type StripePayoutUpdateOneWithoutPayoutBatchingPeriodNestedInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutPayoutBatchingPeriodInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutPayoutBatchingPeriodInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutPayoutBatchingPeriodInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutPayoutBatchingPeriodInput>;
};

export type StripePayoutUpdateOneWithoutStripeDisputesNestedInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripeDisputesInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripeDisputesInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutStripeDisputesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutStripeDisputesInput>;
};

export type StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripePaymentIntentsInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutStripePaymentIntentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutStripePaymentIntentsInput>;
};

export type StripePayoutUpdateOneWithoutStripeRefundsNestedInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripeRefundsInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripeRefundsInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutStripeRefundsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutStripeRefundsInput>;
};

export type StripePayoutUpdateWithWhereUniqueWithoutStripePayoutAccountInput = {
  where: StripePayoutWhereUniqueInput;
  data: StripePayoutUncheckedUpdateWithoutStripePayoutAccountInput;
};

export type StripePayoutUpdateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsNestedInput>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodUpdateOneWithoutStripePayoutNestedInput>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutNestedInput>;
};

export type StripePayoutUpdateWithoutPayoutBatchingPeriodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsNestedInput>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutNestedInput>;
};

export type StripePayoutUpdateWithoutStripeDisputesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsNestedInput>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodUpdateOneWithoutStripePayoutNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutNestedInput>;
};

export type StripePayoutUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsNestedInput>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodUpdateOneWithoutStripePayoutNestedInput>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutNestedInput>;
};

export type StripePayoutUpdateWithoutStripePayoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodUpdateOneWithoutStripePayoutNestedInput>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutNestedInput>;
};

export type StripePayoutUpdateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsNestedInput>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodUpdateOneWithoutStripePayoutNestedInput>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutNestedInput>;
};

export type StripePayoutUpsertWithWhereUniqueWithoutStripePayoutAccountInput = {
  where: StripePayoutWhereUniqueInput;
  update: StripePayoutUncheckedUpdateWithoutStripePayoutAccountInput;
  create: StripePayoutUncheckedCreateWithoutStripePayoutAccountInput;
};

export type StripePayoutUpsertWithoutFinancialTransactionsInput = {
  update: StripePayoutUncheckedUpdateWithoutFinancialTransactionsInput;
  create: StripePayoutUncheckedCreateWithoutFinancialTransactionsInput;
};

export type StripePayoutUpsertWithoutPayoutBatchingPeriodInput = {
  update: StripePayoutUncheckedUpdateWithoutPayoutBatchingPeriodInput;
  create: StripePayoutUncheckedCreateWithoutPayoutBatchingPeriodInput;
};

export type StripePayoutUpsertWithoutStripeDisputesInput = {
  update: StripePayoutUncheckedUpdateWithoutStripeDisputesInput;
  create: StripePayoutUncheckedCreateWithoutStripeDisputesInput;
};

export type StripePayoutUpsertWithoutStripePaymentIntentsInput = {
  update: StripePayoutUncheckedUpdateWithoutStripePaymentIntentsInput;
  create: StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type StripePayoutUpsertWithoutStripeRefundsInput = {
  update: StripePayoutUncheckedUpdateWithoutStripeRefundsInput;
  create: StripePayoutUncheckedCreateWithoutStripeRefundsInput;
};

export type StripePayoutWhereInput = {
  AND?: Maybe<Array<StripePayoutWhereInput>>;
  OR?: Maybe<Array<StripePayoutWhereInput>>;
  NOT?: Maybe<Array<StripePayoutWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  arrivalDate?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumStripePayoutStatusFilter>;
  initiatedAt?: Maybe<DateTimeNullableFilter>;
  stripePayoutAccountId?: Maybe<StringNullableFilter>;
  payoutBatchingPeriodId?: Maybe<StringNullableFilter>;
  statementDescriptor?: Maybe<StringNullableFilter>;
  stripePayoutAccount?: Maybe<StripePayoutAccountWhereInput>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodWhereInput>;
  stripeDisputes?: Maybe<StripeDisputeListRelationFilter>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  stripeRefunds?: Maybe<StripeRefundListRelationFilter>;
  financialTransactions?: Maybe<FinancialTransactionListRelationFilter>;
};

export type StripePayoutWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
};

export type StripeReceipt = {
  __typename?: 'StripeReceipt';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  history: Array<StripeReceiptHistory>;
  payment?: Maybe<StripePaymentIntent>;
  refund?: Maybe<StripeRefund>;
  _count: StripeReceiptCountOutputType;
};


export type StripeReceiptHistoryArgs = {
  where?: Maybe<StripeReceiptHistoryWhereInput>;
  orderBy?: Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeReceiptHistoryScalarFieldEnum>;
};

export type StripeReceiptCountAggregateOutputType = {
  __typename?: 'StripeReceiptCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  applicationName: Scalars['Int'];
  dedicatedFileName: Scalars['Int'];
  accountType: Scalars['Int'];
  authorizationCode: Scalars['Int'];
  applicationCryptogram: Scalars['Int'];
  terminalVerificationResults: Scalars['Int'];
  transactionStatusInformation: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeReceiptCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  applicationName?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  accountType?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
};

export type StripeReceiptCountOutputType = {
  __typename?: 'StripeReceiptCountOutputType';
  history: Scalars['Int'];
};

export type StripeReceiptCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryCreateNestedManyWithoutReceiptInput>;
  payment?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundCreateNestedOneWithoutStripeReceiptInput>;
};

export type StripeReceiptCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptCreateNestedOneWithoutHistoryInput = {
  create?: Maybe<StripeReceiptUncheckedCreateWithoutHistoryInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutHistoryInput>;
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
};

export type StripeReceiptCreateNestedOneWithoutPaymentInput = {
  create?: Maybe<StripeReceiptUncheckedCreateWithoutPaymentInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutPaymentInput>;
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
};

export type StripeReceiptCreateNestedOneWithoutRefundInput = {
  create?: Maybe<StripeReceiptUncheckedCreateWithoutRefundInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutRefundInput>;
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
};

export type StripeReceiptCreateOrConnectWithoutHistoryInput = {
  where: StripeReceiptWhereUniqueInput;
  create: StripeReceiptUncheckedCreateWithoutHistoryInput;
};

export type StripeReceiptCreateOrConnectWithoutPaymentInput = {
  where: StripeReceiptWhereUniqueInput;
  create: StripeReceiptUncheckedCreateWithoutPaymentInput;
};

export type StripeReceiptCreateOrConnectWithoutRefundInput = {
  where: StripeReceiptWhereUniqueInput;
  create: StripeReceiptUncheckedCreateWithoutRefundInput;
};

export type StripeReceiptCreateWithoutHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundCreateNestedOneWithoutStripeReceiptInput>;
};

export type StripeReceiptCreateWithoutPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryCreateNestedManyWithoutReceiptInput>;
  refund?: Maybe<StripeRefundCreateNestedOneWithoutStripeReceiptInput>;
};

export type StripeReceiptCreateWithoutRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryCreateNestedManyWithoutReceiptInput>;
  payment?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeReceiptInput>;
};

export type StripeReceiptHistory = {
  __typename?: 'StripeReceiptHistory';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  stripeReceiptId: Scalars['String'];
  medium: StripeReceiptMedium;
  receipt: StripeReceipt;
};

export type StripeReceiptHistoryCountAggregateOutputType = {
  __typename?: 'StripeReceiptHistoryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  stripeReceiptId: Scalars['Int'];
  medium: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeReceiptHistoryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  medium?: Maybe<SortOrder>;
};

export type StripeReceiptHistoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
  receipt: StripeReceiptCreateNestedOneWithoutHistoryInput;
};

export type StripeReceiptHistoryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeReceiptId: Scalars['String'];
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryCreateManyReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryCreateManyReceiptInputEnvelope = {
  data: Array<StripeReceiptHistoryCreateManyReceiptInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeReceiptHistoryCreateNestedManyWithoutReceiptInput = {
  create?: Maybe<Array<StripeReceiptHistoryCreateWithoutReceiptInput>>;
  connectOrCreate?: Maybe<Array<StripeReceiptHistoryCreateOrConnectWithoutReceiptInput>>;
  createMany?: Maybe<StripeReceiptHistoryCreateManyReceiptInputEnvelope>;
  connect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
};

export type StripeReceiptHistoryCreateOrConnectWithoutReceiptInput = {
  where: StripeReceiptHistoryWhereUniqueInput;
  create: StripeReceiptHistoryUncheckedCreateWithoutReceiptInput;
};

export type StripeReceiptHistoryCreateWithoutReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryListRelationFilter = {
  every?: Maybe<StripeReceiptHistoryWhereInput>;
  some?: Maybe<StripeReceiptHistoryWhereInput>;
  none?: Maybe<StripeReceiptHistoryWhereInput>;
};

export type StripeReceiptHistoryMaxAggregateOutputType = {
  __typename?: 'StripeReceiptHistoryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  medium?: Maybe<SortOrder>;
};

export type StripeReceiptHistoryMinAggregateOutputType = {
  __typename?: 'StripeReceiptHistoryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  medium?: Maybe<SortOrder>;
};

export type StripeReceiptHistoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeReceiptHistoryOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeReceiptId = 'stripeReceiptId'
}

export type StripeReceiptHistoryOrderByRelevanceInput = {
  fields: Array<StripeReceiptHistoryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeReceiptHistoryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  medium?: Maybe<SortOrder>;
  _count?: Maybe<StripeReceiptHistoryCountOrderByAggregateInput>;
  _max?: Maybe<StripeReceiptHistoryMaxOrderByAggregateInput>;
  _min?: Maybe<StripeReceiptHistoryMinOrderByAggregateInput>;
};

export type StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  medium?: Maybe<SortOrder>;
  receipt?: Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<StripeReceiptHistoryOrderByRelevanceInput>;
};

export enum StripeReceiptHistoryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  StripeReceiptId = 'stripeReceiptId',
  Medium = 'medium'
}

export type StripeReceiptHistoryScalarWhereInput = {
  AND?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
  OR?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  stripeReceiptId?: Maybe<StringFilter>;
  medium?: Maybe<EnumStripeReceiptMediumFilter>;
};

export type StripeReceiptHistoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeReceiptHistoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeReceiptHistoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeReceiptHistoryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeReceiptId?: Maybe<StringWithAggregatesFilter>;
  medium?: Maybe<EnumStripeReceiptMediumWithAggregatesFilter>;
};

export type StripeReceiptHistoryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeReceiptId: Scalars['String'];
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUncheckedCreateNestedManyWithoutReceiptInput = {
  create?: Maybe<Array<StripeReceiptHistoryCreateWithoutReceiptInput>>;
  connectOrCreate?: Maybe<Array<StripeReceiptHistoryCreateOrConnectWithoutReceiptInput>>;
  createMany?: Maybe<StripeReceiptHistoryCreateManyReceiptInputEnvelope>;
  connect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
};

export type StripeReceiptHistoryUncheckedCreateWithoutReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUncheckedUpdateManyWithoutHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUncheckedUpdateManyWithoutReceiptNestedInput = {
  create?: Maybe<Array<StripeReceiptHistoryCreateWithoutReceiptInput>>;
  connectOrCreate?: Maybe<Array<StripeReceiptHistoryCreateOrConnectWithoutReceiptInput>>;
  upsert?: Maybe<Array<StripeReceiptHistoryUpsertWithWhereUniqueWithoutReceiptInput>>;
  createMany?: Maybe<StripeReceiptHistoryCreateManyReceiptInputEnvelope>;
  set?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  delete?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  update?: Maybe<Array<StripeReceiptHistoryUpdateWithWhereUniqueWithoutReceiptInput>>;
  updateMany?: Maybe<Array<StripeReceiptHistoryUpdateManyWithWhereWithoutReceiptInput>>;
  deleteMany?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
};

export type StripeReceiptHistoryUncheckedUpdateWithoutReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
  receipt?: Maybe<StripeReceiptUpdateOneRequiredWithoutHistoryNestedInput>;
};

export type StripeReceiptHistoryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUpdateManyWithWhereWithoutReceiptInput = {
  where: StripeReceiptHistoryScalarWhereInput;
  data: StripeReceiptHistoryUncheckedUpdateManyWithoutHistoryInput;
};

export type StripeReceiptHistoryUpdateManyWithoutReceiptNestedInput = {
  create?: Maybe<Array<StripeReceiptHistoryCreateWithoutReceiptInput>>;
  connectOrCreate?: Maybe<Array<StripeReceiptHistoryCreateOrConnectWithoutReceiptInput>>;
  upsert?: Maybe<Array<StripeReceiptHistoryUpsertWithWhereUniqueWithoutReceiptInput>>;
  createMany?: Maybe<StripeReceiptHistoryCreateManyReceiptInputEnvelope>;
  set?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  delete?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  update?: Maybe<Array<StripeReceiptHistoryUpdateWithWhereUniqueWithoutReceiptInput>>;
  updateMany?: Maybe<Array<StripeReceiptHistoryUpdateManyWithWhereWithoutReceiptInput>>;
  deleteMany?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
};

export type StripeReceiptHistoryUpdateWithWhereUniqueWithoutReceiptInput = {
  where: StripeReceiptHistoryWhereUniqueInput;
  data: StripeReceiptHistoryUncheckedUpdateWithoutReceiptInput;
};

export type StripeReceiptHistoryUpdateWithoutReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUpsertWithWhereUniqueWithoutReceiptInput = {
  where: StripeReceiptHistoryWhereUniqueInput;
  update: StripeReceiptHistoryUncheckedUpdateWithoutReceiptInput;
  create: StripeReceiptHistoryUncheckedCreateWithoutReceiptInput;
};

export type StripeReceiptHistoryWhereInput = {
  AND?: Maybe<Array<StripeReceiptHistoryWhereInput>>;
  OR?: Maybe<Array<StripeReceiptHistoryWhereInput>>;
  NOT?: Maybe<Array<StripeReceiptHistoryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  stripeReceiptId?: Maybe<StringFilter>;
  medium?: Maybe<EnumStripeReceiptMediumFilter>;
  receipt?: Maybe<StripeReceiptWhereInput>;
};

export type StripeReceiptHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type StripeReceiptMaxAggregateOutputType = {
  __typename?: 'StripeReceiptMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  applicationName?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  accountType?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
};

export enum StripeReceiptMedium {
  Email = 'Email',
  Printed = 'Printed'
}

export type StripeReceiptMinAggregateOutputType = {
  __typename?: 'StripeReceiptMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  applicationName?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  accountType?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
};

export enum StripeReceiptOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  ApplicationName = 'applicationName',
  DedicatedFileName = 'dedicatedFileName',
  AccountType = 'accountType',
  AuthorizationCode = 'authorizationCode',
  ApplicationCryptogram = 'applicationCryptogram',
  TerminalVerificationResults = 'terminalVerificationResults',
  TransactionStatusInformation = 'transactionStatusInformation'
}

export type StripeReceiptOrderByRelevanceInput = {
  fields: Array<StripeReceiptOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeReceiptOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  applicationName?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  accountType?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
  _count?: Maybe<StripeReceiptCountOrderByAggregateInput>;
  _max?: Maybe<StripeReceiptMaxOrderByAggregateInput>;
  _min?: Maybe<StripeReceiptMinOrderByAggregateInput>;
};

export type StripeReceiptOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  applicationName?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  accountType?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
  history?: Maybe<StripeReceiptHistoryOrderByRelationAggregateInput>;
  payment?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  refund?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<StripeReceiptOrderByRelevanceInput>;
};

export type StripeReceiptRelationFilter = {
  is?: Maybe<StripeReceiptWhereInput>;
  isNot?: Maybe<StripeReceiptWhereInput>;
};

export enum StripeReceiptScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  StripeId = 'stripeId',
  ApplicationName = 'applicationName',
  DedicatedFileName = 'dedicatedFileName',
  AccountType = 'accountType',
  AuthorizationCode = 'authorizationCode',
  ApplicationCryptogram = 'applicationCryptogram',
  TerminalVerificationResults = 'terminalVerificationResults',
  TransactionStatusInformation = 'transactionStatusInformation'
}

export type StripeReceiptScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeReceiptScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeReceiptScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeReceiptScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringNullableWithAggregatesFilter>;
  applicationName?: Maybe<StringNullableWithAggregatesFilter>;
  dedicatedFileName?: Maybe<StringNullableWithAggregatesFilter>;
  accountType?: Maybe<StringNullableWithAggregatesFilter>;
  authorizationCode?: Maybe<StringNullableWithAggregatesFilter>;
  applicationCryptogram?: Maybe<StringNullableWithAggregatesFilter>;
  terminalVerificationResults?: Maybe<StringNullableWithAggregatesFilter>;
  transactionStatusInformation?: Maybe<StringNullableWithAggregatesFilter>;
};

export type StripeReceiptUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedCreateNestedManyWithoutReceiptInput>;
  payment?: Maybe<StripePaymentIntentUncheckedCreateNestedOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundUncheckedCreateNestedOneWithoutStripeReceiptInput>;
};

export type StripeReceiptUncheckedCreateWithoutHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUncheckedCreateNestedOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundUncheckedCreateNestedOneWithoutStripeReceiptInput>;
};

export type StripeReceiptUncheckedCreateWithoutPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedCreateNestedManyWithoutReceiptInput>;
  refund?: Maybe<StripeRefundUncheckedCreateNestedOneWithoutStripeReceiptInput>;
};

export type StripeReceiptUncheckedCreateWithoutRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedCreateNestedManyWithoutReceiptInput>;
  payment?: Maybe<StripePaymentIntentUncheckedCreateNestedOneWithoutStripeReceiptInput>;
};

export type StripeReceiptUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedUpdateManyWithoutReceiptNestedInput>;
  payment?: Maybe<StripePaymentIntentUncheckedUpdateOneWithoutStripeReceiptNestedInput>;
  refund?: Maybe<StripeRefundUncheckedUpdateOneWithoutStripeReceiptNestedInput>;
};

export type StripeReceiptUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedUpdateWithoutHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUncheckedUpdateOneWithoutStripeReceiptNestedInput>;
  refund?: Maybe<StripeRefundUncheckedUpdateOneWithoutStripeReceiptNestedInput>;
};

export type StripeReceiptUncheckedUpdateWithoutPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedUpdateManyWithoutReceiptNestedInput>;
  refund?: Maybe<StripeRefundUncheckedUpdateOneWithoutStripeReceiptNestedInput>;
};

export type StripeReceiptUncheckedUpdateWithoutRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedUpdateManyWithoutReceiptNestedInput>;
  payment?: Maybe<StripePaymentIntentUncheckedUpdateOneWithoutStripeReceiptNestedInput>;
};

export type StripeReceiptUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUpdateManyWithoutReceiptNestedInput>;
  payment?: Maybe<StripePaymentIntentUpdateOneWithoutStripeReceiptNestedInput>;
  refund?: Maybe<StripeRefundUpdateOneWithoutStripeReceiptNestedInput>;
};

export type StripeReceiptUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUpdateOneRequiredWithoutHistoryNestedInput = {
  create?: Maybe<StripeReceiptUncheckedCreateWithoutHistoryInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutHistoryInput>;
  upsert?: Maybe<StripeReceiptUpsertWithoutHistoryInput>;
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
  update?: Maybe<StripeReceiptUncheckedUpdateWithoutHistoryInput>;
};

export type StripeReceiptUpdateOneWithoutPaymentNestedInput = {
  create?: Maybe<StripeReceiptUncheckedCreateWithoutPaymentInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutPaymentInput>;
  upsert?: Maybe<StripeReceiptUpsertWithoutPaymentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
  update?: Maybe<StripeReceiptUncheckedUpdateWithoutPaymentInput>;
};

export type StripeReceiptUpdateOneWithoutRefundNestedInput = {
  create?: Maybe<StripeReceiptUncheckedCreateWithoutRefundInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutRefundInput>;
  upsert?: Maybe<StripeReceiptUpsertWithoutRefundInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
  update?: Maybe<StripeReceiptUncheckedUpdateWithoutRefundInput>;
};

export type StripeReceiptUpdateWithoutHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUpdateOneWithoutStripeReceiptNestedInput>;
  refund?: Maybe<StripeRefundUpdateOneWithoutStripeReceiptNestedInput>;
};

export type StripeReceiptUpdateWithoutPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUpdateManyWithoutReceiptNestedInput>;
  refund?: Maybe<StripeRefundUpdateOneWithoutStripeReceiptNestedInput>;
};

export type StripeReceiptUpdateWithoutRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUpdateManyWithoutReceiptNestedInput>;
  payment?: Maybe<StripePaymentIntentUpdateOneWithoutStripeReceiptNestedInput>;
};

export type StripeReceiptUpsertWithoutHistoryInput = {
  update: StripeReceiptUncheckedUpdateWithoutHistoryInput;
  create: StripeReceiptUncheckedCreateWithoutHistoryInput;
};

export type StripeReceiptUpsertWithoutPaymentInput = {
  update: StripeReceiptUncheckedUpdateWithoutPaymentInput;
  create: StripeReceiptUncheckedCreateWithoutPaymentInput;
};

export type StripeReceiptUpsertWithoutRefundInput = {
  update: StripeReceiptUncheckedUpdateWithoutRefundInput;
  create: StripeReceiptUncheckedCreateWithoutRefundInput;
};

export type StripeReceiptWhereInput = {
  AND?: Maybe<Array<StripeReceiptWhereInput>>;
  OR?: Maybe<Array<StripeReceiptWhereInput>>;
  NOT?: Maybe<Array<StripeReceiptWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringNullableFilter>;
  applicationName?: Maybe<StringNullableFilter>;
  dedicatedFileName?: Maybe<StringNullableFilter>;
  accountType?: Maybe<StringNullableFilter>;
  authorizationCode?: Maybe<StringNullableFilter>;
  applicationCryptogram?: Maybe<StringNullableFilter>;
  terminalVerificationResults?: Maybe<StringNullableFilter>;
  transactionStatusInformation?: Maybe<StringNullableFilter>;
  history?: Maybe<StripeReceiptHistoryListRelationFilter>;
  payment?: Maybe<StripePaymentIntentWhereInput>;
  refund?: Maybe<StripeRefundWhereInput>;
};

export type StripeReceiptWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeRefund = {
  __typename?: 'StripeRefund';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeId: Scalars['String'];
  reason: RefundReason;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntent>;
  stripePayout?: Maybe<StripePayout>;
  stripeReceipt?: Maybe<StripeReceipt>;
  issuedBy?: Maybe<User>;
  financialTransaction?: Maybe<FinancialTransaction>;
  pushNotificationLogs: Array<PushNotificationLog>;
  _count: StripeRefundCountOutputType;
};


export type StripeRefundPushNotificationLogsArgs = {
  where?: Maybe<PushNotificationLogWhereInput>;
  orderBy?: Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PushNotificationLogScalarFieldEnum>;
};

export type StripeRefundAvgAggregateOutputType = {
  __typename?: 'StripeRefundAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  clientFeeRefunded?: Maybe<Scalars['Float']>;
  clinicFeeRefunded?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type StripeRefundAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type StripeRefundCountAggregateOutputType = {
  __typename?: 'StripeRefundCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  reason: Scalars['Int'];
  reasonOther: Scalars['Int'];
  amount: Scalars['Int'];
  clientFeeRefunded: Scalars['Int'];
  clinicFeeRefunded: Scalars['Int'];
  total: Scalars['Int'];
  stripePayoutId: Scalars['Int'];
  stripePaymentIntentId: Scalars['Int'];
  stripeReceiptId: Scalars['Int'];
  issuedByUserId: Scalars['Int'];
  fundsAvailableAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeRefundCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  issuedByUserId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
};

export type StripeRefundCountOutputType = {
  __typename?: 'StripeRefundCountOutputType';
  pushNotificationLogs: Scalars['Int'];
};

export type StripeRefundCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutStripeRefundInput>;
};

export type StripeRefundCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundCreateManyIssuedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundCreateManyIssuedByInputEnvelope = {
  data: Array<StripeRefundCreateManyIssuedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeRefundCreateManyStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundCreateManyStripePaymentIntentInputEnvelope = {
  data: Array<StripeRefundCreateManyStripePaymentIntentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeRefundCreateManyStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundCreateManyStripePayoutInputEnvelope = {
  data: Array<StripeRefundCreateManyStripePayoutInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeRefundCreateNestedManyWithoutIssuedByInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutIssuedByInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutIssuedByInput>>;
  createMany?: Maybe<StripeRefundCreateManyIssuedByInputEnvelope>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
};

export type StripeRefundCreateNestedManyWithoutStripePaymentIntentInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePaymentIntentInputEnvelope>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
};

export type StripeRefundCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
};

export type StripeRefundCreateNestedOneWithoutFinancialTransactionInput = {
  create?: Maybe<StripeRefundUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutFinancialTransactionInput>;
  connect?: Maybe<StripeRefundWhereUniqueInput>;
};

export type StripeRefundCreateNestedOneWithoutPushNotificationLogsInput = {
  create?: Maybe<StripeRefundUncheckedCreateWithoutPushNotificationLogsInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutPushNotificationLogsInput>;
  connect?: Maybe<StripeRefundWhereUniqueInput>;
};

export type StripeRefundCreateNestedOneWithoutStripeReceiptInput = {
  create?: Maybe<StripeRefundUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutStripeReceiptInput>;
  connect?: Maybe<StripeRefundWhereUniqueInput>;
};

export type StripeRefundCreateOrConnectWithoutFinancialTransactionInput = {
  where: StripeRefundWhereUniqueInput;
  create: StripeRefundUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripeRefundCreateOrConnectWithoutIssuedByInput = {
  where: StripeRefundWhereUniqueInput;
  create: StripeRefundUncheckedCreateWithoutIssuedByInput;
};

export type StripeRefundCreateOrConnectWithoutPushNotificationLogsInput = {
  where: StripeRefundWhereUniqueInput;
  create: StripeRefundUncheckedCreateWithoutPushNotificationLogsInput;
};

export type StripeRefundCreateOrConnectWithoutStripePaymentIntentInput = {
  where: StripeRefundWhereUniqueInput;
  create: StripeRefundUncheckedCreateWithoutStripePaymentIntentInput;
};

export type StripeRefundCreateOrConnectWithoutStripePayoutInput = {
  where: StripeRefundWhereUniqueInput;
  create: StripeRefundUncheckedCreateWithoutStripePayoutInput;
};

export type StripeRefundCreateOrConnectWithoutStripeReceiptInput = {
  where: StripeRefundWhereUniqueInput;
  create: StripeRefundUncheckedCreateWithoutStripeReceiptInput;
};

export type StripeRefundCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutStripeRefundInput>;
};

export type StripeRefundCreateWithoutIssuedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutStripeRefundInput>;
};

export type StripeRefundCreateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
};

export type StripeRefundCreateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutStripeRefundInput>;
};

export type StripeRefundCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutStripeRefundInput>;
};

export type StripeRefundCreateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutStripeRefundInput>;
};

export type StripeRefundListRelationFilter = {
  every?: Maybe<StripeRefundWhereInput>;
  some?: Maybe<StripeRefundWhereInput>;
  none?: Maybe<StripeRefundWhereInput>;
};

export type StripeRefundMaxAggregateOutputType = {
  __typename?: 'StripeRefundMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  issuedByUserId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
};

export type StripeRefundMinAggregateOutputType = {
  __typename?: 'StripeRefundMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  issuedByUserId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
};

export type StripeRefundOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeRefundOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  ReasonOther = 'reasonOther',
  StripePayoutId = 'stripePayoutId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripeReceiptId = 'stripeReceiptId',
  IssuedByUserId = 'issuedByUserId'
}

export type StripeRefundOrderByRelevanceInput = {
  fields: Array<StripeRefundOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeRefundOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  issuedByUserId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  _count?: Maybe<StripeRefundCountOrderByAggregateInput>;
  _avg?: Maybe<StripeRefundAvgOrderByAggregateInput>;
  _max?: Maybe<StripeRefundMaxOrderByAggregateInput>;
  _min?: Maybe<StripeRefundMinOrderByAggregateInput>;
  _sum?: Maybe<StripeRefundSumOrderByAggregateInput>;
};

export type StripeRefundOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  issuedByUserId?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  stripePaymentIntent?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  stripePayout?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  stripeReceipt?: Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>;
  issuedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  financialTransaction?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripeRefundOrderByRelevanceInput>;
};

export enum StripeRefundReason {
  Duplicate = 'Duplicate',
  Fraudulent = 'Fraudulent',
  RequestedByCustomer = 'Requested_By_Customer',
  PartialRefund = 'Partial_Refund',
  Other = 'Other'
}

export type StripeRefundRelationFilter = {
  is?: Maybe<StripeRefundWhereInput>;
  isNot?: Maybe<StripeRefundWhereInput>;
};

export enum StripeRefundScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeId = 'stripeId',
  Reason = 'reason',
  ReasonOther = 'reasonOther',
  Amount = 'amount',
  ClientFeeRefunded = 'clientFeeRefunded',
  ClinicFeeRefunded = 'clinicFeeRefunded',
  Total = 'total',
  StripePayoutId = 'stripePayoutId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripeReceiptId = 'stripeReceiptId',
  IssuedByUserId = 'issuedByUserId',
  FundsAvailableAt = 'fundsAvailableAt'
}

export type StripeRefundScalarWhereInput = {
  AND?: Maybe<Array<StripeRefundScalarWhereInput>>;
  OR?: Maybe<Array<StripeRefundScalarWhereInput>>;
  NOT?: Maybe<Array<StripeRefundScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  reason?: Maybe<EnumRefundReasonFilter>;
  reasonOther?: Maybe<StringNullableFilter>;
  amount?: Maybe<IntFilter>;
  clientFeeRefunded?: Maybe<IntNullableFilter>;
  clinicFeeRefunded?: Maybe<IntNullableFilter>;
  total?: Maybe<IntNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripeReceiptId?: Maybe<StringNullableFilter>;
  issuedByUserId?: Maybe<StringNullableFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
};

export type StripeRefundScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeRefundScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeRefundScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeRefundScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  reason?: Maybe<EnumRefundReasonWithAggregatesFilter>;
  reasonOther?: Maybe<StringNullableWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  clientFeeRefunded?: Maybe<IntNullableWithAggregatesFilter>;
  clinicFeeRefunded?: Maybe<IntNullableWithAggregatesFilter>;
  total?: Maybe<IntNullableWithAggregatesFilter>;
  stripePayoutId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePaymentIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeReceiptId?: Maybe<StringNullableWithAggregatesFilter>;
  issuedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
};

export type StripeRefundSumAggregateOutputType = {
  __typename?: 'StripeRefundSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type StripeRefundSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type StripeRefundUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutIssuedByInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutIssuedByInput>>;
  createMany?: Maybe<StripeRefundCreateManyIssuedByInputEnvelope>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
};

export type StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePaymentIntentInputEnvelope>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
};

export type StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
};

export type StripeRefundUncheckedCreateNestedOneWithoutStripeReceiptInput = {
  create?: Maybe<StripeRefundUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutStripeReceiptInput>;
  connect?: Maybe<StripeRefundWhereUniqueInput>;
};

export type StripeRefundUncheckedCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedCreateWithoutIssuedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedCreateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedCreateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedCreateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutStripeRefundNestedInput>;
};

export type StripeRefundUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutIssuedByInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutIssuedByInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutIssuedByInput>>;
  createMany?: Maybe<StripeRefundCreateManyIssuedByInputEnvelope>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutIssuedByInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutIssuedByInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
};

export type StripeRefundUncheckedUpdateManyWithoutRefundsIssuedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePaymentIntentInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePaymentIntentInputEnvelope>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutStripePaymentIntentInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutStripePaymentIntentInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
};

export type StripeRefundUncheckedUpdateManyWithoutStripePayoutNestedInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
};

export type StripeRefundUncheckedUpdateManyWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedUpdateOneWithoutStripeReceiptNestedInput = {
  create?: Maybe<StripeRefundUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutStripeReceiptInput>;
  upsert?: Maybe<StripeRefundUpsertWithoutStripeReceiptInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  update?: Maybe<StripeRefundUncheckedUpdateWithoutStripeReceiptInput>;
};

export type StripeRefundUncheckedUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutStripeRefundNestedInput>;
};

export type StripeRefundUncheckedUpdateWithoutIssuedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutStripeRefundNestedInput>;
};

export type StripeRefundUncheckedUpdateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundNestedInput>;
};

export type StripeRefundUncheckedUpdateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutStripeRefundNestedInput>;
};

export type StripeRefundUncheckedUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutStripeRefundNestedInput>;
};

export type StripeRefundUncheckedUpdateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutStripeRefundNestedInput>;
};

export type StripeRefundUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundNestedInput>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutStripeRefundNestedInput>;
};

export type StripeRefundUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUpdateManyWithWhereWithoutIssuedByInput = {
  where: StripeRefundScalarWhereInput;
  data: StripeRefundUncheckedUpdateManyWithoutRefundsIssuedInput;
};

export type StripeRefundUpdateManyWithWhereWithoutStripePaymentIntentInput = {
  where: StripeRefundScalarWhereInput;
  data: StripeRefundUncheckedUpdateManyWithoutStripeRefundsInput;
};

export type StripeRefundUpdateManyWithWhereWithoutStripePayoutInput = {
  where: StripeRefundScalarWhereInput;
  data: StripeRefundUncheckedUpdateManyWithoutStripeRefundsInput;
};

export type StripeRefundUpdateManyWithoutIssuedByNestedInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutIssuedByInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutIssuedByInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutIssuedByInput>>;
  createMany?: Maybe<StripeRefundCreateManyIssuedByInputEnvelope>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutIssuedByInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutIssuedByInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
};

export type StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePaymentIntentInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePaymentIntentInputEnvelope>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutStripePaymentIntentInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutStripePaymentIntentInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
};

export type StripeRefundUpdateManyWithoutStripePayoutNestedInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
};

export type StripeRefundUpdateOneWithoutFinancialTransactionNestedInput = {
  create?: Maybe<StripeRefundUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutFinancialTransactionInput>;
  upsert?: Maybe<StripeRefundUpsertWithoutFinancialTransactionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  update?: Maybe<StripeRefundUncheckedUpdateWithoutFinancialTransactionInput>;
};

export type StripeRefundUpdateOneWithoutPushNotificationLogsNestedInput = {
  create?: Maybe<StripeRefundUncheckedCreateWithoutPushNotificationLogsInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutPushNotificationLogsInput>;
  upsert?: Maybe<StripeRefundUpsertWithoutPushNotificationLogsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  update?: Maybe<StripeRefundUncheckedUpdateWithoutPushNotificationLogsInput>;
};

export type StripeRefundUpdateOneWithoutStripeReceiptNestedInput = {
  create?: Maybe<StripeRefundUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutStripeReceiptInput>;
  upsert?: Maybe<StripeRefundUpsertWithoutStripeReceiptInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  update?: Maybe<StripeRefundUncheckedUpdateWithoutStripeReceiptInput>;
};

export type StripeRefundUpdateWithWhereUniqueWithoutIssuedByInput = {
  where: StripeRefundWhereUniqueInput;
  data: StripeRefundUncheckedUpdateWithoutIssuedByInput;
};

export type StripeRefundUpdateWithWhereUniqueWithoutStripePaymentIntentInput = {
  where: StripeRefundWhereUniqueInput;
  data: StripeRefundUncheckedUpdateWithoutStripePaymentIntentInput;
};

export type StripeRefundUpdateWithWhereUniqueWithoutStripePayoutInput = {
  where: StripeRefundWhereUniqueInput;
  data: StripeRefundUncheckedUpdateWithoutStripePayoutInput;
};

export type StripeRefundUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundNestedInput>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutStripeRefundNestedInput>;
};

export type StripeRefundUpdateWithoutIssuedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutStripeRefundNestedInput>;
};

export type StripeRefundUpdateWithoutPushNotificationLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundNestedInput>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundNestedInput>;
};

export type StripeRefundUpdateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundNestedInput>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutStripeRefundNestedInput>;
};

export type StripeRefundUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundNestedInput>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutStripeRefundNestedInput>;
};

export type StripeRefundUpdateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsNestedInput>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutStripeRefundNestedInput>;
};

export type StripeRefundUpsertWithWhereUniqueWithoutIssuedByInput = {
  where: StripeRefundWhereUniqueInput;
  update: StripeRefundUncheckedUpdateWithoutIssuedByInput;
  create: StripeRefundUncheckedCreateWithoutIssuedByInput;
};

export type StripeRefundUpsertWithWhereUniqueWithoutStripePaymentIntentInput = {
  where: StripeRefundWhereUniqueInput;
  update: StripeRefundUncheckedUpdateWithoutStripePaymentIntentInput;
  create: StripeRefundUncheckedCreateWithoutStripePaymentIntentInput;
};

export type StripeRefundUpsertWithWhereUniqueWithoutStripePayoutInput = {
  where: StripeRefundWhereUniqueInput;
  update: StripeRefundUncheckedUpdateWithoutStripePayoutInput;
  create: StripeRefundUncheckedCreateWithoutStripePayoutInput;
};

export type StripeRefundUpsertWithoutFinancialTransactionInput = {
  update: StripeRefundUncheckedUpdateWithoutFinancialTransactionInput;
  create: StripeRefundUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripeRefundUpsertWithoutPushNotificationLogsInput = {
  update: StripeRefundUncheckedUpdateWithoutPushNotificationLogsInput;
  create: StripeRefundUncheckedCreateWithoutPushNotificationLogsInput;
};

export type StripeRefundUpsertWithoutStripeReceiptInput = {
  update: StripeRefundUncheckedUpdateWithoutStripeReceiptInput;
  create: StripeRefundUncheckedCreateWithoutStripeReceiptInput;
};

export type StripeRefundWhereInput = {
  AND?: Maybe<Array<StripeRefundWhereInput>>;
  OR?: Maybe<Array<StripeRefundWhereInput>>;
  NOT?: Maybe<Array<StripeRefundWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  reason?: Maybe<EnumRefundReasonFilter>;
  reasonOther?: Maybe<StringNullableFilter>;
  amount?: Maybe<IntFilter>;
  clientFeeRefunded?: Maybe<IntNullableFilter>;
  clinicFeeRefunded?: Maybe<IntNullableFilter>;
  total?: Maybe<IntNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripeReceiptId?: Maybe<StringNullableFilter>;
  issuedByUserId?: Maybe<StringNullableFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
  stripePaymentIntent?: Maybe<StripePaymentIntentWhereInput>;
  stripePayout?: Maybe<StripePayoutWhereInput>;
  stripeReceipt?: Maybe<StripeReceiptWhereInput>;
  issuedBy?: Maybe<UserWhereInput>;
  financialTransaction?: Maybe<FinancialTransactionWhereInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogListRelationFilter>;
};

export type StripeRefundWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
};

export type StripeRequirements = {
  __typename?: 'StripeRequirements';
  deadline?: Maybe<Scalars['Date']>;
  currentlyDue?: Maybe<Array<Scalars['String']>>;
  eventuallyDue?: Maybe<Array<Scalars['String']>>;
  pastDue?: Maybe<Array<Scalars['String']>>;
  disabledReason?: Maybe<Scalars['String']>;
  pendingVerification?: Maybe<Array<Scalars['String']>>;
};

export type StripeTerminal = {
  __typename?: 'StripeTerminal';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeId: Scalars['String'];
  locationId: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
  location: StripeTerminalLocation;
  payments: Array<StripePaymentIntent>;
  healthchecks: Array<StripeTerminalHealthcheck>;
  _count: StripeTerminalCountOutputType;
};


export type StripeTerminalPaymentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};


export type StripeTerminalHealthchecksArgs = {
  where?: Maybe<StripeTerminalHealthcheckWhereInput>;
  orderBy?: Maybe<StripeTerminalHealthcheckOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeTerminalHealthcheckWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeTerminalHealthcheckScalarFieldEnum>;
};

export type StripeTerminalCountAggregateOutputType = {
  __typename?: 'StripeTerminalCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  locationId: Scalars['Int'];
  isActive: Scalars['Int'];
  name: Scalars['Int'];
  model: Scalars['Int'];
  serialNumber: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeTerminalCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
};

export type StripeTerminalCountOutputType = {
  __typename?: 'StripeTerminalCountOutputType';
  payments: Scalars['Int'];
  healthchecks: Scalars['Int'];
};

export type StripeTerminalCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
  location: StripeTerminalLocationCreateNestedOneWithoutTerminalsInput;
  payments?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripeTerminalInput>;
  healthchecks?: Maybe<StripeTerminalHealthcheckCreateNestedManyWithoutTerminalInput>;
};

export type StripeTerminalCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  locationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
};

export type StripeTerminalCreateManyLocationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
};

export type StripeTerminalCreateManyLocationInputEnvelope = {
  data: Array<StripeTerminalCreateManyLocationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeTerminalCreateNestedManyWithoutLocationInput = {
  create?: Maybe<Array<StripeTerminalCreateWithoutLocationInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalCreateOrConnectWithoutLocationInput>>;
  createMany?: Maybe<StripeTerminalCreateManyLocationInputEnvelope>;
  connect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
};

export type StripeTerminalCreateNestedOneWithoutHealthchecksInput = {
  create?: Maybe<StripeTerminalUncheckedCreateWithoutHealthchecksInput>;
  connectOrCreate?: Maybe<StripeTerminalCreateOrConnectWithoutHealthchecksInput>;
  connect?: Maybe<StripeTerminalWhereUniqueInput>;
};

export type StripeTerminalCreateNestedOneWithoutPaymentsInput = {
  create?: Maybe<StripeTerminalUncheckedCreateWithoutPaymentsInput>;
  connectOrCreate?: Maybe<StripeTerminalCreateOrConnectWithoutPaymentsInput>;
  connect?: Maybe<StripeTerminalWhereUniqueInput>;
};

export type StripeTerminalCreateOrConnectWithoutHealthchecksInput = {
  where: StripeTerminalWhereUniqueInput;
  create: StripeTerminalUncheckedCreateWithoutHealthchecksInput;
};

export type StripeTerminalCreateOrConnectWithoutLocationInput = {
  where: StripeTerminalWhereUniqueInput;
  create: StripeTerminalUncheckedCreateWithoutLocationInput;
};

export type StripeTerminalCreateOrConnectWithoutPaymentsInput = {
  where: StripeTerminalWhereUniqueInput;
  create: StripeTerminalUncheckedCreateWithoutPaymentsInput;
};

export type StripeTerminalCreateWithoutHealthchecksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
  location: StripeTerminalLocationCreateNestedOneWithoutTerminalsInput;
  payments?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripeTerminalInput>;
};

export type StripeTerminalCreateWithoutLocationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
  payments?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripeTerminalInput>;
  healthchecks?: Maybe<StripeTerminalHealthcheckCreateNestedManyWithoutTerminalInput>;
};

export type StripeTerminalCreateWithoutPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
  location: StripeTerminalLocationCreateNestedOneWithoutTerminalsInput;
  healthchecks?: Maybe<StripeTerminalHealthcheckCreateNestedManyWithoutTerminalInput>;
};

export type StripeTerminalHealthcheck = {
  __typename?: 'StripeTerminalHealthcheck';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: StripeTerminalStatus;
  terminalId: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
  terminal: StripeTerminal;
};

export type StripeTerminalHealthcheckCountAggregateOutputType = {
  __typename?: 'StripeTerminalHealthcheckCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  status: Scalars['Int'];
  terminalId: Scalars['Int'];
  ipAddress: Scalars['Int'];
  tapVersion: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeTerminalHealthcheckCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  terminalId?: Maybe<SortOrder>;
  ipAddress?: Maybe<SortOrder>;
  tapVersion?: Maybe<SortOrder>;
};

export type StripeTerminalHealthcheckCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: StripeTerminalStatus;
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
  terminal: StripeTerminalCreateNestedOneWithoutHealthchecksInput;
};

export type StripeTerminalHealthcheckCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: StripeTerminalStatus;
  terminalId: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
};

export type StripeTerminalHealthcheckCreateManyTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: StripeTerminalStatus;
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
};

export type StripeTerminalHealthcheckCreateManyTerminalInputEnvelope = {
  data: Array<StripeTerminalHealthcheckCreateManyTerminalInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeTerminalHealthcheckCreateNestedManyWithoutTerminalInput = {
  create?: Maybe<Array<StripeTerminalHealthcheckCreateWithoutTerminalInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalHealthcheckCreateOrConnectWithoutTerminalInput>>;
  createMany?: Maybe<StripeTerminalHealthcheckCreateManyTerminalInputEnvelope>;
  connect?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
};

export type StripeTerminalHealthcheckCreateOrConnectWithoutTerminalInput = {
  where: StripeTerminalHealthcheckWhereUniqueInput;
  create: StripeTerminalHealthcheckUncheckedCreateWithoutTerminalInput;
};

export type StripeTerminalHealthcheckCreateWithoutTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: StripeTerminalStatus;
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
};

export type StripeTerminalHealthcheckListRelationFilter = {
  every?: Maybe<StripeTerminalHealthcheckWhereInput>;
  some?: Maybe<StripeTerminalHealthcheckWhereInput>;
  none?: Maybe<StripeTerminalHealthcheckWhereInput>;
};

export type StripeTerminalHealthcheckMaxAggregateOutputType = {
  __typename?: 'StripeTerminalHealthcheckMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripeTerminalStatus>;
  terminalId?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
};

export type StripeTerminalHealthcheckMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  terminalId?: Maybe<SortOrder>;
  ipAddress?: Maybe<SortOrder>;
  tapVersion?: Maybe<SortOrder>;
};

export type StripeTerminalHealthcheckMinAggregateOutputType = {
  __typename?: 'StripeTerminalHealthcheckMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripeTerminalStatus>;
  terminalId?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
};

export type StripeTerminalHealthcheckMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  terminalId?: Maybe<SortOrder>;
  ipAddress?: Maybe<SortOrder>;
  tapVersion?: Maybe<SortOrder>;
};

export type StripeTerminalHealthcheckOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeTerminalHealthcheckOrderByRelevanceFieldEnum {
  Id = 'id',
  TerminalId = 'terminalId',
  IpAddress = 'ipAddress',
  TapVersion = 'tapVersion'
}

export type StripeTerminalHealthcheckOrderByRelevanceInput = {
  fields: Array<StripeTerminalHealthcheckOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeTerminalHealthcheckOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  terminalId?: Maybe<SortOrder>;
  ipAddress?: Maybe<SortOrder>;
  tapVersion?: Maybe<SortOrder>;
  _count?: Maybe<StripeTerminalHealthcheckCountOrderByAggregateInput>;
  _max?: Maybe<StripeTerminalHealthcheckMaxOrderByAggregateInput>;
  _min?: Maybe<StripeTerminalHealthcheckMinOrderByAggregateInput>;
};

export type StripeTerminalHealthcheckOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  terminalId?: Maybe<SortOrder>;
  ipAddress?: Maybe<SortOrder>;
  tapVersion?: Maybe<SortOrder>;
  terminal?: Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<StripeTerminalHealthcheckOrderByRelevanceInput>;
};

export enum StripeTerminalHealthcheckScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  TerminalId = 'terminalId',
  IpAddress = 'ipAddress',
  TapVersion = 'tapVersion'
}

export type StripeTerminalHealthcheckScalarWhereInput = {
  AND?: Maybe<Array<StripeTerminalHealthcheckScalarWhereInput>>;
  OR?: Maybe<Array<StripeTerminalHealthcheckScalarWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalHealthcheckScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumStripeTerminalStatusFilter>;
  terminalId?: Maybe<StringFilter>;
  ipAddress?: Maybe<StringNullableFilter>;
  tapVersion?: Maybe<StringNullableFilter>;
};

export type StripeTerminalHealthcheckScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeTerminalHealthcheckScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeTerminalHealthcheckScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeTerminalHealthcheckScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumStripeTerminalStatusWithAggregatesFilter>;
  terminalId?: Maybe<StringWithAggregatesFilter>;
  ipAddress?: Maybe<StringNullableWithAggregatesFilter>;
  tapVersion?: Maybe<StringNullableWithAggregatesFilter>;
};

export type StripeTerminalHealthcheckUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: StripeTerminalStatus;
  terminalId: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
};

export type StripeTerminalHealthcheckUncheckedCreateNestedManyWithoutTerminalInput = {
  create?: Maybe<Array<StripeTerminalHealthcheckCreateWithoutTerminalInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalHealthcheckCreateOrConnectWithoutTerminalInput>>;
  createMany?: Maybe<StripeTerminalHealthcheckCreateManyTerminalInputEnvelope>;
  connect?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
};

export type StripeTerminalHealthcheckUncheckedCreateWithoutTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: StripeTerminalStatus;
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
};

export type StripeTerminalHealthcheckUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripeTerminalStatus>;
  terminalId?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
};

export type StripeTerminalHealthcheckUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripeTerminalStatus>;
  terminalId?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
};

export type StripeTerminalHealthcheckUncheckedUpdateManyWithoutHealthchecksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripeTerminalStatus>;
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
};

export type StripeTerminalHealthcheckUncheckedUpdateManyWithoutTerminalNestedInput = {
  create?: Maybe<Array<StripeTerminalHealthcheckCreateWithoutTerminalInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalHealthcheckCreateOrConnectWithoutTerminalInput>>;
  upsert?: Maybe<Array<StripeTerminalHealthcheckUpsertWithWhereUniqueWithoutTerminalInput>>;
  createMany?: Maybe<StripeTerminalHealthcheckCreateManyTerminalInputEnvelope>;
  set?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  delete?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  connect?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalHealthcheckUpdateWithWhereUniqueWithoutTerminalInput>>;
  updateMany?: Maybe<Array<StripeTerminalHealthcheckUpdateManyWithWhereWithoutTerminalInput>>;
  deleteMany?: Maybe<Array<StripeTerminalHealthcheckScalarWhereInput>>;
};

export type StripeTerminalHealthcheckUncheckedUpdateWithoutTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripeTerminalStatus>;
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
};

export type StripeTerminalHealthcheckUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripeTerminalStatus>;
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
  terminal?: Maybe<StripeTerminalUpdateOneRequiredWithoutHealthchecksNestedInput>;
};

export type StripeTerminalHealthcheckUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripeTerminalStatus>;
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
};

export type StripeTerminalHealthcheckUpdateManyWithWhereWithoutTerminalInput = {
  where: StripeTerminalHealthcheckScalarWhereInput;
  data: StripeTerminalHealthcheckUncheckedUpdateManyWithoutHealthchecksInput;
};

export type StripeTerminalHealthcheckUpdateManyWithoutTerminalNestedInput = {
  create?: Maybe<Array<StripeTerminalHealthcheckCreateWithoutTerminalInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalHealthcheckCreateOrConnectWithoutTerminalInput>>;
  upsert?: Maybe<Array<StripeTerminalHealthcheckUpsertWithWhereUniqueWithoutTerminalInput>>;
  createMany?: Maybe<StripeTerminalHealthcheckCreateManyTerminalInputEnvelope>;
  set?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  delete?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  connect?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalHealthcheckUpdateWithWhereUniqueWithoutTerminalInput>>;
  updateMany?: Maybe<Array<StripeTerminalHealthcheckUpdateManyWithWhereWithoutTerminalInput>>;
  deleteMany?: Maybe<Array<StripeTerminalHealthcheckScalarWhereInput>>;
};

export type StripeTerminalHealthcheckUpdateWithWhereUniqueWithoutTerminalInput = {
  where: StripeTerminalHealthcheckWhereUniqueInput;
  data: StripeTerminalHealthcheckUncheckedUpdateWithoutTerminalInput;
};

export type StripeTerminalHealthcheckUpdateWithoutTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripeTerminalStatus>;
  ipAddress?: Maybe<Scalars['String']>;
  tapVersion?: Maybe<Scalars['String']>;
};

export type StripeTerminalHealthcheckUpsertWithWhereUniqueWithoutTerminalInput = {
  where: StripeTerminalHealthcheckWhereUniqueInput;
  update: StripeTerminalHealthcheckUncheckedUpdateWithoutTerminalInput;
  create: StripeTerminalHealthcheckUncheckedCreateWithoutTerminalInput;
};

export type StripeTerminalHealthcheckWhereInput = {
  AND?: Maybe<Array<StripeTerminalHealthcheckWhereInput>>;
  OR?: Maybe<Array<StripeTerminalHealthcheckWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalHealthcheckWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumStripeTerminalStatusFilter>;
  terminalId?: Maybe<StringFilter>;
  ipAddress?: Maybe<StringNullableFilter>;
  tapVersion?: Maybe<StringNullableFilter>;
  terminal?: Maybe<StripeTerminalWhereInput>;
};

export type StripeTerminalHealthcheckWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type StripeTerminalListRelationFilter = {
  every?: Maybe<StripeTerminalWhereInput>;
  some?: Maybe<StripeTerminalWhereInput>;
  none?: Maybe<StripeTerminalWhereInput>;
};

export type StripeTerminalLocation = {
  __typename?: 'StripeTerminalLocation';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeId: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  clinicId: Scalars['String'];
  type: StripeTerminalLocationType;
  terminals: Array<StripeTerminal>;
  clinic: Clinic;
  _count: StripeTerminalLocationCountOutputType;
};


export type StripeTerminalLocationTerminalsArgs = {
  where?: Maybe<StripeTerminalWhereInput>;
  orderBy?: Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeTerminalScalarFieldEnum>;
};

export type StripeTerminalLocationCountAggregateOutputType = {
  __typename?: 'StripeTerminalLocationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  isActive: Scalars['Int'];
  name: Scalars['Int'];
  clinicId: Scalars['Int'];
  type: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeTerminalLocationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type StripeTerminalLocationCountOutputType = {
  __typename?: 'StripeTerminalLocationCountOutputType';
  terminals: Scalars['Int'];
};

export type StripeTerminalLocationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  type?: Maybe<StripeTerminalLocationType>;
  terminals?: Maybe<StripeTerminalCreateNestedManyWithoutLocationInput>;
  clinic: ClinicCreateNestedOneWithoutStripeTerminalLocationsInput;
};

export type StripeTerminalLocationCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  type?: Maybe<StripeTerminalLocationType>;
};

export type StripeTerminalLocationCreateManyClinicInputEnvelope = {
  data: Array<StripeTerminalLocationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeTerminalLocationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  clinicId: Scalars['String'];
  type?: Maybe<StripeTerminalLocationType>;
};

export type StripeTerminalLocationCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<StripeTerminalLocationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalLocationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<StripeTerminalLocationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
};

export type StripeTerminalLocationCreateNestedOneWithoutTerminalsInput = {
  create?: Maybe<StripeTerminalLocationUncheckedCreateWithoutTerminalsInput>;
  connectOrCreate?: Maybe<StripeTerminalLocationCreateOrConnectWithoutTerminalsInput>;
  connect?: Maybe<StripeTerminalLocationWhereUniqueInput>;
};

export type StripeTerminalLocationCreateOrConnectWithoutClinicInput = {
  where: StripeTerminalLocationWhereUniqueInput;
  create: StripeTerminalLocationUncheckedCreateWithoutClinicInput;
};

export type StripeTerminalLocationCreateOrConnectWithoutTerminalsInput = {
  where: StripeTerminalLocationWhereUniqueInput;
  create: StripeTerminalLocationUncheckedCreateWithoutTerminalsInput;
};

export type StripeTerminalLocationCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  type?: Maybe<StripeTerminalLocationType>;
  terminals?: Maybe<StripeTerminalCreateNestedManyWithoutLocationInput>;
};

export type StripeTerminalLocationCreateWithoutTerminalsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  type?: Maybe<StripeTerminalLocationType>;
  clinic: ClinicCreateNestedOneWithoutStripeTerminalLocationsInput;
};

export type StripeTerminalLocationListRelationFilter = {
  every?: Maybe<StripeTerminalLocationWhereInput>;
  some?: Maybe<StripeTerminalLocationWhereInput>;
  none?: Maybe<StripeTerminalLocationWhereInput>;
};

export type StripeTerminalLocationMaxAggregateOutputType = {
  __typename?: 'StripeTerminalLocationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
};

export type StripeTerminalLocationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type StripeTerminalLocationMinAggregateOutputType = {
  __typename?: 'StripeTerminalLocationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
};

export type StripeTerminalLocationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type StripeTerminalLocationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeTerminalLocationOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  Name = 'name',
  ClinicId = 'clinicId'
}

export type StripeTerminalLocationOrderByRelevanceInput = {
  fields: Array<StripeTerminalLocationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeTerminalLocationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  _count?: Maybe<StripeTerminalLocationCountOrderByAggregateInput>;
  _max?: Maybe<StripeTerminalLocationMaxOrderByAggregateInput>;
  _min?: Maybe<StripeTerminalLocationMinOrderByAggregateInput>;
};

export type StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  terminals?: Maybe<StripeTerminalOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<StripeTerminalLocationOrderByRelevanceInput>;
};

export type StripeTerminalLocationRelationFilter = {
  is?: Maybe<StripeTerminalLocationWhereInput>;
  isNot?: Maybe<StripeTerminalLocationWhereInput>;
};

export enum StripeTerminalLocationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeId = 'stripeId',
  IsActive = 'isActive',
  Name = 'name',
  ClinicId = 'clinicId',
  Type = 'type'
}

export type StripeTerminalLocationScalarWhereInput = {
  AND?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
  OR?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  type?: Maybe<EnumStripeTerminalLocationTypeFilter>;
};

export type StripeTerminalLocationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeTerminalLocationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeTerminalLocationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeTerminalLocationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumStripeTerminalLocationTypeWithAggregatesFilter>;
};

export enum StripeTerminalLocationType {
  StripeApp = 'StripeApp',
  Tap = 'TAP'
}

export type StripeTerminalLocationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  clinicId: Scalars['String'];
  type?: Maybe<StripeTerminalLocationType>;
  terminals?: Maybe<StripeTerminalUncheckedCreateNestedManyWithoutLocationInput>;
};

export type StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<StripeTerminalLocationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalLocationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<StripeTerminalLocationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
};

export type StripeTerminalLocationUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  type?: Maybe<StripeTerminalLocationType>;
  terminals?: Maybe<StripeTerminalUncheckedCreateNestedManyWithoutLocationInput>;
};

export type StripeTerminalLocationUncheckedCreateWithoutTerminalsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  clinicId: Scalars['String'];
  type?: Maybe<StripeTerminalLocationType>;
};

export type StripeTerminalLocationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
  terminals?: Maybe<StripeTerminalUncheckedUpdateManyWithoutLocationNestedInput>;
};

export type StripeTerminalLocationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
};

export type StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<StripeTerminalLocationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalLocationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<StripeTerminalLocationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<StripeTerminalLocationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  delete?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  connect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalLocationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StripeTerminalLocationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
};

export type StripeTerminalLocationUncheckedUpdateManyWithoutStripeTerminalLocationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
};

export type StripeTerminalLocationUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
  terminals?: Maybe<StripeTerminalUncheckedUpdateManyWithoutLocationNestedInput>;
};

export type StripeTerminalLocationUncheckedUpdateWithoutTerminalsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
};

export type StripeTerminalLocationUniqueInput = {
  locationId: Scalars['String'];
};

export type StripeTerminalLocationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
  terminals?: Maybe<StripeTerminalUpdateManyWithoutLocationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutStripeTerminalLocationsNestedInput>;
};

export type StripeTerminalLocationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
};

export type StripeTerminalLocationUpdateManyWithWhereWithoutClinicInput = {
  where: StripeTerminalLocationScalarWhereInput;
  data: StripeTerminalLocationUncheckedUpdateManyWithoutStripeTerminalLocationsInput;
};

export type StripeTerminalLocationUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<StripeTerminalLocationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalLocationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<StripeTerminalLocationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<StripeTerminalLocationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  delete?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  connect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalLocationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StripeTerminalLocationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
};

export type StripeTerminalLocationUpdateOneRequiredWithoutTerminalsNestedInput = {
  create?: Maybe<StripeTerminalLocationUncheckedCreateWithoutTerminalsInput>;
  connectOrCreate?: Maybe<StripeTerminalLocationCreateOrConnectWithoutTerminalsInput>;
  upsert?: Maybe<StripeTerminalLocationUpsertWithoutTerminalsInput>;
  connect?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  update?: Maybe<StripeTerminalLocationUncheckedUpdateWithoutTerminalsInput>;
};

export type StripeTerminalLocationUpdateWithWhereUniqueWithoutClinicInput = {
  where: StripeTerminalLocationWhereUniqueInput;
  data: StripeTerminalLocationUncheckedUpdateWithoutClinicInput;
};

export type StripeTerminalLocationUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
  terminals?: Maybe<StripeTerminalUpdateManyWithoutLocationNestedInput>;
};

export type StripeTerminalLocationUpdateWithoutTerminalsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutStripeTerminalLocationsNestedInput>;
};

export type StripeTerminalLocationUpsertWithWhereUniqueWithoutClinicInput = {
  where: StripeTerminalLocationWhereUniqueInput;
  update: StripeTerminalLocationUncheckedUpdateWithoutClinicInput;
  create: StripeTerminalLocationUncheckedCreateWithoutClinicInput;
};

export type StripeTerminalLocationUpsertWithoutTerminalsInput = {
  update: StripeTerminalLocationUncheckedUpdateWithoutTerminalsInput;
  create: StripeTerminalLocationUncheckedCreateWithoutTerminalsInput;
};

export type StripeTerminalLocationWhereInput = {
  AND?: Maybe<Array<StripeTerminalLocationWhereInput>>;
  OR?: Maybe<Array<StripeTerminalLocationWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalLocationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  type?: Maybe<EnumStripeTerminalLocationTypeFilter>;
  terminals?: Maybe<StripeTerminalListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type StripeTerminalLocationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeTerminalMaxAggregateOutputType = {
  __typename?: 'StripeTerminalMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type StripeTerminalMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
};

export type StripeTerminalMinAggregateOutputType = {
  __typename?: 'StripeTerminalMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type StripeTerminalMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
};

export enum StripeTerminalModel {
  VerifoneP400 = 'Verifone_P400',
  BbposWisePosE = 'BBPOS_WisePOS_E',
  StripeS700 = 'Stripe_S700'
}

export type StripeTerminalOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeTerminalOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  LocationId = 'locationId',
  Name = 'name',
  SerialNumber = 'serialNumber'
}

export type StripeTerminalOrderByRelevanceInput = {
  fields: Array<StripeTerminalOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeTerminalOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  _count?: Maybe<StripeTerminalCountOrderByAggregateInput>;
  _max?: Maybe<StripeTerminalMaxOrderByAggregateInput>;
  _min?: Maybe<StripeTerminalMinOrderByAggregateInput>;
};

export type StripeTerminalOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  location?: Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>;
  payments?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  healthchecks?: Maybe<StripeTerminalHealthcheckOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripeTerminalOrderByRelevanceInput>;
};

export type StripeTerminalRelationFilter = {
  is?: Maybe<StripeTerminalWhereInput>;
  isNot?: Maybe<StripeTerminalWhereInput>;
};

export enum StripeTerminalScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeId = 'stripeId',
  LocationId = 'locationId',
  IsActive = 'isActive',
  Name = 'name',
  Model = 'model',
  SerialNumber = 'serialNumber'
}

export type StripeTerminalScalarWhereInput = {
  AND?: Maybe<Array<StripeTerminalScalarWhereInput>>;
  OR?: Maybe<Array<StripeTerminalScalarWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  locationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  model?: Maybe<EnumStripeTerminalModelFilter>;
  serialNumber?: Maybe<StringFilter>;
};

export type StripeTerminalScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeTerminalScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeTerminalScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeTerminalScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  locationId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  model?: Maybe<EnumStripeTerminalModelWithAggregatesFilter>;
  serialNumber?: Maybe<StringWithAggregatesFilter>;
};

export enum StripeTerminalStatus {
  Online = 'Online',
  Offline = 'Offline'
}

export type StripeTerminalTokenOutput = {
  __typename?: 'StripeTerminalTokenOutput';
  secret: Scalars['String'];
};

export type StripeTerminalUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  locationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
  payments?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripeTerminalInput>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUncheckedCreateNestedManyWithoutTerminalInput>;
};

export type StripeTerminalUncheckedCreateNestedManyWithoutLocationInput = {
  create?: Maybe<Array<StripeTerminalCreateWithoutLocationInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalCreateOrConnectWithoutLocationInput>>;
  createMany?: Maybe<StripeTerminalCreateManyLocationInputEnvelope>;
  connect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
};

export type StripeTerminalUncheckedCreateWithoutHealthchecksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  locationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
  payments?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripeTerminalInput>;
};

export type StripeTerminalUncheckedCreateWithoutLocationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
  payments?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripeTerminalInput>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUncheckedCreateNestedManyWithoutTerminalInput>;
};

export type StripeTerminalUncheckedCreateWithoutPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  locationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
  healthchecks?: Maybe<StripeTerminalHealthcheckUncheckedCreateNestedManyWithoutTerminalInput>;
};

export type StripeTerminalUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
  payments?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripeTerminalNestedInput>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUncheckedUpdateManyWithoutTerminalNestedInput>;
};

export type StripeTerminalUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type StripeTerminalUncheckedUpdateManyWithoutLocationNestedInput = {
  create?: Maybe<Array<StripeTerminalCreateWithoutLocationInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalCreateOrConnectWithoutLocationInput>>;
  upsert?: Maybe<Array<StripeTerminalUpsertWithWhereUniqueWithoutLocationInput>>;
  createMany?: Maybe<StripeTerminalCreateManyLocationInputEnvelope>;
  set?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  delete?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  connect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: Maybe<Array<StripeTerminalUpdateManyWithWhereWithoutLocationInput>>;
  deleteMany?: Maybe<Array<StripeTerminalScalarWhereInput>>;
};

export type StripeTerminalUncheckedUpdateManyWithoutTerminalsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type StripeTerminalUncheckedUpdateWithoutHealthchecksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
  payments?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripeTerminalNestedInput>;
};

export type StripeTerminalUncheckedUpdateWithoutLocationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
  payments?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripeTerminalNestedInput>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUncheckedUpdateManyWithoutTerminalNestedInput>;
};

export type StripeTerminalUncheckedUpdateWithoutPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUncheckedUpdateManyWithoutTerminalNestedInput>;
};

export type StripeTerminalUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
  location?: Maybe<StripeTerminalLocationUpdateOneRequiredWithoutTerminalsNestedInput>;
  payments?: Maybe<StripePaymentIntentUpdateManyWithoutStripeTerminalNestedInput>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUpdateManyWithoutTerminalNestedInput>;
};

export type StripeTerminalUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type StripeTerminalUpdateManyWithWhereWithoutLocationInput = {
  where: StripeTerminalScalarWhereInput;
  data: StripeTerminalUncheckedUpdateManyWithoutTerminalsInput;
};

export type StripeTerminalUpdateManyWithoutLocationNestedInput = {
  create?: Maybe<Array<StripeTerminalCreateWithoutLocationInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalCreateOrConnectWithoutLocationInput>>;
  upsert?: Maybe<Array<StripeTerminalUpsertWithWhereUniqueWithoutLocationInput>>;
  createMany?: Maybe<StripeTerminalCreateManyLocationInputEnvelope>;
  set?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  delete?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  connect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: Maybe<Array<StripeTerminalUpdateManyWithWhereWithoutLocationInput>>;
  deleteMany?: Maybe<Array<StripeTerminalScalarWhereInput>>;
};

export type StripeTerminalUpdateOneRequiredWithoutHealthchecksNestedInput = {
  create?: Maybe<StripeTerminalUncheckedCreateWithoutHealthchecksInput>;
  connectOrCreate?: Maybe<StripeTerminalCreateOrConnectWithoutHealthchecksInput>;
  upsert?: Maybe<StripeTerminalUpsertWithoutHealthchecksInput>;
  connect?: Maybe<StripeTerminalWhereUniqueInput>;
  update?: Maybe<StripeTerminalUncheckedUpdateWithoutHealthchecksInput>;
};

export type StripeTerminalUpdateOneWithoutPaymentsNestedInput = {
  create?: Maybe<StripeTerminalUncheckedCreateWithoutPaymentsInput>;
  connectOrCreate?: Maybe<StripeTerminalCreateOrConnectWithoutPaymentsInput>;
  upsert?: Maybe<StripeTerminalUpsertWithoutPaymentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeTerminalWhereUniqueInput>;
  update?: Maybe<StripeTerminalUncheckedUpdateWithoutPaymentsInput>;
};

export type StripeTerminalUpdateWithWhereUniqueWithoutLocationInput = {
  where: StripeTerminalWhereUniqueInput;
  data: StripeTerminalUncheckedUpdateWithoutLocationInput;
};

export type StripeTerminalUpdateWithoutHealthchecksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
  location?: Maybe<StripeTerminalLocationUpdateOneRequiredWithoutTerminalsNestedInput>;
  payments?: Maybe<StripePaymentIntentUpdateManyWithoutStripeTerminalNestedInput>;
};

export type StripeTerminalUpdateWithoutLocationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
  payments?: Maybe<StripePaymentIntentUpdateManyWithoutStripeTerminalNestedInput>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUpdateManyWithoutTerminalNestedInput>;
};

export type StripeTerminalUpdateWithoutPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
  location?: Maybe<StripeTerminalLocationUpdateOneRequiredWithoutTerminalsNestedInput>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUpdateManyWithoutTerminalNestedInput>;
};

export type StripeTerminalUpsertWithWhereUniqueWithoutLocationInput = {
  where: StripeTerminalWhereUniqueInput;
  update: StripeTerminalUncheckedUpdateWithoutLocationInput;
  create: StripeTerminalUncheckedCreateWithoutLocationInput;
};

export type StripeTerminalUpsertWithoutHealthchecksInput = {
  update: StripeTerminalUncheckedUpdateWithoutHealthchecksInput;
  create: StripeTerminalUncheckedCreateWithoutHealthchecksInput;
};

export type StripeTerminalUpsertWithoutPaymentsInput = {
  update: StripeTerminalUncheckedUpdateWithoutPaymentsInput;
  create: StripeTerminalUncheckedCreateWithoutPaymentsInput;
};

export type StripeTerminalWhereInput = {
  AND?: Maybe<Array<StripeTerminalWhereInput>>;
  OR?: Maybe<Array<StripeTerminalWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  locationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  model?: Maybe<EnumStripeTerminalModelFilter>;
  serialNumber?: Maybe<StringFilter>;
  location?: Maybe<StripeTerminalLocationWhereInput>;
  payments?: Maybe<StripePaymentIntentListRelationFilter>;
  healthchecks?: Maybe<StripeTerminalHealthcheckListRelationFilter>;
};

export type StripeTerminalWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeUpdatePaymentInput = {
  amount: Scalars['Int'];
  customer?: Maybe<Scalars['String']>;
  receiptEmail?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  captureType?: Maybe<StripeCaptureType>;
  description?: Maybe<Scalars['String']>;
  descriptorSuffix?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  paymentMethodType?: Maybe<StripePaymentMethodType>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  cardType?: Maybe<CardFunding>;
  paymentMedium?: Maybe<PaymentMedium>;
  clinicId: Scalars['String'];
  paymentIntentId: Scalars['String'];
};

export type SubmitFormResponse = {
  __typename?: 'SubmitFormResponse';
  formSubmission: FormSubmission;
  channelId?: Maybe<Scalars['String']>;
};

export type SubmitPetParentRequestInput = {
  clinicPetParentId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId: Scalars['String'];
  formTemplateId: Scalars['String'];
  completedContent: Scalars['Json'];
};

export type SubmitPrescriptionRefillRequestInput = {
  petParentId: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  formSubmission: Scalars['Json'];
  prescriptionsIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  clinicWidgetRequestTypeId: Scalars['String'];
};

export type SubmitPrescriptionRefillRequestResponse = {
  __typename?: 'SubmitPrescriptionRefillRequestResponse';
  clinicWidgetRequestId: Scalars['String'];
  success: Scalars['Boolean'];
  channelId?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  automationRunChanged: AutomationRunSubscription;
  automationRunActionChanged: AutomationRunActionSubscription;
  recordingStatusChanged: TwilioCallRecordingSubscription;
  checkPetEnrollment: CareEnrollmentOutput;
  checkPetParentHasEnrolledPet: HasCarePetWithEnrollmentOutput;
  checkPetEnrollmentsByClinic: CareEnrollmentsByClinicOutput;
  channelSyncedToSearch: Channel;
  channelChanged: ChannelSubscription;
  unreadMessageCountChanged: ChannelSubscription;
  channelMemberChanged: ChannelMemberSubscription;
  channelMessageChanged: ChannelMessageSubscription;
  channelMessageAttachmentChanged: ChannelMessageAttachmentSubscription;
  clinicChanged: ClinicSubscription;
  clinicPhoneNumberChanged: ClinicPhoneNumberSubscription;
  clinicWidgetRequestChanged: ClinicWidgetRequestSubscription;
  formSubmissionChanged?: Maybe<FormSubmissionSubscription>;
  integrationOutageHistoryEntryChanged: IntegrationOutageHistoryEntrySubscription;
  jobChainCompleted?: Maybe<JobChainSubscritptionPayload>;
  loyaltyAccountUpdated: LoyaltyAccountSubscription;
  loyaltyProgramUpdated: LoyaltyProgramSubscription;
  loyaltyRewardRedemptionUpdated: LoyaltyRewardRedemptionSubscription;
  massTextAlertChanged: MassTextAlertSubscription;
  smsNotificationStatusChanged?: Maybe<SmsNotificationStatusSubscription>;
  stripePaymentIntentChanged: StripePaymentIntentSubscription;
  twilioVerificationChanged: TwilioVerificationSubscription;
  uiRefresh?: Maybe<UiRefresh>;
};


export type SubscriptionAutomationRunChangedArgs = {
  where: SubscriptionAutomationRunActionWhereInput;
};


export type SubscriptionAutomationRunActionChangedArgs = {
  where: SubscriptionAutomationRunActionWhereInput;
};


export type SubscriptionRecordingStatusChangedArgs = {
  where: SubscriptionTwilioCallRecordingWhereInput;
};


export type SubscriptionCheckPetEnrollmentArgs = {
  where: CareEnrollmentWhereInput;
};


export type SubscriptionCheckPetParentHasEnrolledPetArgs = {
  where: HasCarePetWithEnrollmentWhereInput;
};


export type SubscriptionCheckPetEnrollmentsByClinicArgs = {
  where: CareEnrollmentsByClinicWhereInput;
};


export type SubscriptionChannelSyncedToSearchArgs = {
  where?: Maybe<ChannelSyncedToSearchWhereInput>;
};


export type SubscriptionChannelChangedArgs = {
  where: SubscriptionChannelWhereInput;
};


export type SubscriptionUnreadMessageCountChangedArgs = {
  where: SubscriptionChannelWhereInput;
};


export type SubscriptionChannelMemberChangedArgs = {
  where: SubscriptionChannelMemberWhereInput;
};


export type SubscriptionChannelMessageChangedArgs = {
  where: SubscriptionChannelMessageWhereInput;
};


export type SubscriptionChannelMessageAttachmentChangedArgs = {
  where: SubscriptionChannelMessageAttachmentWhereInput;
};


export type SubscriptionClinicChangedArgs = {
  where: SubscriptionClinicWhereInput;
};


export type SubscriptionClinicPhoneNumberChangedArgs = {
  where: ClinicPhoneNumberWhereInput;
};


export type SubscriptionClinicWidgetRequestChangedArgs = {
  where: SubscriptionClinicWidgetRequestWhereInput;
};


export type SubscriptionFormSubmissionChangedArgs = {
  where?: Maybe<SubscriptionFormSubmissionWhereInput>;
};


export type SubscriptionIntegrationOutageHistoryEntryChangedArgs = {
  where: IntegrationOutageHistoryEntryWhereInput;
};


export type SubscriptionJobChainCompletedArgs = {
  where: JobChainSubscritptionWhereInput;
};


export type SubscriptionLoyaltyAccountUpdatedArgs = {
  where: LoyaltyAccountWhereUniqueInput;
};


export type SubscriptionLoyaltyProgramUpdatedArgs = {
  where: LoyaltyProgramWhereUniqueInput;
};


export type SubscriptionLoyaltyRewardRedemptionUpdatedArgs = {
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};


export type SubscriptionMassTextAlertChangedArgs = {
  where: SubscriptionMassTextAlertWhereInput;
};


export type SubscriptionSmsNotificationStatusChangedArgs = {
  where: SubscriptionSmsNotificationStatusWhereInput;
};


export type SubscriptionStripePaymentIntentChangedArgs = {
  where: SubscriptionStripePaymentIntentWhereInput;
};


export type SubscriptionTwilioVerificationChangedArgs = {
  where: SubscriptionTwilioVerificationWhereInput;
};

export type SubscriptionAutomationRunActionWhereInput = {
  node: AutomationRunActionWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionAutomationRunWhereInput = {
  node: AutomationRunWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionChannelMemberWhereInput = {
  node: ChannelMemberWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionChannelMessageAttachmentWhereInput = {
  node: ChannelMessageAttachmentWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionChannelMessageWhereInput = {
  node: ChannelMessageWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionChannelWhereInput = {
  node: ChannelWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionClinicWhereInput = {
  node: ClinicWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionClinicWidgetRequestWhereInput = {
  node: ClinicWidgetRequestWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionFormSubmissionWhereInput = {
  node: FormSubmissionWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionMassTextAlertWhereInput = {
  node: MassTextAlertWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionSmsNotificationStatusWhereInput = {
  node: SmsNotificationStatusWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionStripePaymentIntentWhereInput = {
  node: StripePaymentIntentWhereUniqueInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionTwilioCallRecordingWhereInput = {
  node: TwilioCallRecordingWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionTwilioVerificationWhereInput = {
  node: TwilioVerificationWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export enum SupportEventStatus {
  Submitted = 'Submitted',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Canceled = 'Canceled',
  Closed = 'Closed',
  System = 'System'
}

export type SurveyAppointmentTypeSetting = {
  __typename?: 'SurveyAppointmentTypeSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  automatedTriggerInMinutes: Scalars['Int'];
  appointmentTypeId: Scalars['String'];
  surveySettingId: Scalars['String'];
  appointmentType: AppointmentType;
  surveySetting: SurveySetting;
};

export type SurveyAppointmentTypeSettingAvgAggregateOutputType = {
  __typename?: 'SurveyAppointmentTypeSettingAvgAggregateOutputType';
  automatedTriggerInMinutes?: Maybe<Scalars['Float']>;
};

export type SurveyAppointmentTypeSettingAvgOrderByAggregateInput = {
  automatedTriggerInMinutes?: Maybe<SortOrder>;
};

export type SurveyAppointmentTypeSettingCountAggregateOutputType = {
  __typename?: 'SurveyAppointmentTypeSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isActive: Scalars['Int'];
  automatedTriggerInMinutes: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  surveySettingId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SurveyAppointmentTypeSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
};

export type SurveyAppointmentTypeSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  appointmentType: AppointmentTypeCreateNestedOneWithoutSurveyAppointmentTypeSettingsInput;
  surveySetting: SurveySettingCreateNestedOneWithoutAppointmentTypeSettingsInput;
};

export type SurveyAppointmentTypeSettingCreateManyAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  surveySettingId: Scalars['String'];
};

export type SurveyAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope = {
  data: Array<SurveyAppointmentTypeSettingCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SurveyAppointmentTypeSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  appointmentTypeId: Scalars['String'];
  surveySettingId: Scalars['String'];
};

export type SurveyAppointmentTypeSettingCreateManySurveySettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  appointmentTypeId: Scalars['String'];
};

export type SurveyAppointmentTypeSettingCreateManySurveySettingInputEnvelope = {
  data: Array<SurveyAppointmentTypeSettingCreateManySurveySettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
};

export type SurveyAppointmentTypeSettingCreateNestedManyWithoutSurveySettingInput = {
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutSurveySettingInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutSurveySettingInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManySurveySettingInputEnvelope>;
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
};

export type SurveyAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput = {
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
  create: SurveyAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput;
};

export type SurveyAppointmentTypeSettingCreateOrConnectWithoutSurveySettingInput = {
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
  create: SurveyAppointmentTypeSettingUncheckedCreateWithoutSurveySettingInput;
};

export type SurveyAppointmentTypeSettingCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  surveySetting: SurveySettingCreateNestedOneWithoutAppointmentTypeSettingsInput;
};

export type SurveyAppointmentTypeSettingCreateWithoutSurveySettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  appointmentType: AppointmentTypeCreateNestedOneWithoutSurveyAppointmentTypeSettingsInput;
};

export type SurveyAppointmentTypeSettingListRelationFilter = {
  every?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
  some?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
  none?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
};

export type SurveyAppointmentTypeSettingMaxAggregateOutputType = {
  __typename?: 'SurveyAppointmentTypeSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  surveySettingId?: Maybe<Scalars['String']>;
};

export type SurveyAppointmentTypeSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
};

export type SurveyAppointmentTypeSettingMinAggregateOutputType = {
  __typename?: 'SurveyAppointmentTypeSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  surveySettingId?: Maybe<Scalars['String']>;
};

export type SurveyAppointmentTypeSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
};

export type SurveyAppointmentTypeSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum SurveyAppointmentTypeSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  AppointmentTypeId = 'appointmentTypeId',
  SurveySettingId = 'surveySettingId'
}

export type SurveyAppointmentTypeSettingOrderByRelevanceInput = {
  fields: Array<SurveyAppointmentTypeSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type SurveyAppointmentTypeSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  _count?: Maybe<SurveyAppointmentTypeSettingCountOrderByAggregateInput>;
  _avg?: Maybe<SurveyAppointmentTypeSettingAvgOrderByAggregateInput>;
  _max?: Maybe<SurveyAppointmentTypeSettingMaxOrderByAggregateInput>;
  _min?: Maybe<SurveyAppointmentTypeSettingMinOrderByAggregateInput>;
  _sum?: Maybe<SurveyAppointmentTypeSettingSumOrderByAggregateInput>;
};

export type SurveyAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  surveySetting?: Maybe<SurveySettingOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<SurveyAppointmentTypeSettingOrderByRelevanceInput>;
};

export enum SurveyAppointmentTypeSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsDeleted = 'isDeleted',
  IsActive = 'isActive',
  AutomatedTriggerInMinutes = 'automatedTriggerInMinutes',
  AppointmentTypeId = 'appointmentTypeId',
  SurveySettingId = 'surveySettingId'
}

export type SurveyAppointmentTypeSettingScalarWhereInput = {
  AND?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereInput>>;
  OR?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereInput>>;
  NOT?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isActive?: Maybe<BoolFilter>;
  automatedTriggerInMinutes?: Maybe<IntFilter>;
  appointmentTypeId?: Maybe<StringFilter>;
  surveySettingId?: Maybe<StringFilter>;
};

export type SurveyAppointmentTypeSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  automatedTriggerInMinutes?: Maybe<IntWithAggregatesFilter>;
  appointmentTypeId?: Maybe<StringWithAggregatesFilter>;
  surveySettingId?: Maybe<StringWithAggregatesFilter>;
};

export type SurveyAppointmentTypeSettingSumAggregateOutputType = {
  __typename?: 'SurveyAppointmentTypeSettingSumAggregateOutputType';
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
};

export type SurveyAppointmentTypeSettingSumOrderByAggregateInput = {
  automatedTriggerInMinutes?: Maybe<SortOrder>;
};

export type SurveyAppointmentTypeSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  appointmentTypeId: Scalars['String'];
  surveySettingId: Scalars['String'];
};

export type SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
};

export type SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutSurveySettingInput = {
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutSurveySettingInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutSurveySettingInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManySurveySettingInputEnvelope>;
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
};

export type SurveyAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  surveySettingId: Scalars['String'];
};

export type SurveyAppointmentTypeSettingUncheckedCreateWithoutSurveySettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  appointmentTypeId: Scalars['String'];
};

export type SurveyAppointmentTypeSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  surveySettingId?: Maybe<Scalars['String']>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  surveySettingId?: Maybe<Scalars['String']>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput = {
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<SurveyAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  delete?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<SurveyAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<SurveyAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereInput>>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutSurveyAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  surveySettingId?: Maybe<Scalars['String']>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutSurveySettingNestedInput = {
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutSurveySettingInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutSurveySettingInput>>;
  upsert?: Maybe<Array<SurveyAppointmentTypeSettingUpsertWithWhereUniqueWithoutSurveySettingInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManySurveySettingInputEnvelope>;
  set?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  delete?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<SurveyAppointmentTypeSettingUpdateWithWhereUniqueWithoutSurveySettingInput>>;
  updateMany?: Maybe<Array<SurveyAppointmentTypeSettingUpdateManyWithWhereWithoutSurveySettingInput>>;
  deleteMany?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereInput>>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  surveySettingId?: Maybe<Scalars['String']>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateWithoutSurveySettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type SurveyAppointmentTypeSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutSurveyAppointmentTypeSettingsNestedInput>;
  surveySetting?: Maybe<SurveySettingUpdateOneRequiredWithoutAppointmentTypeSettingsNestedInput>;
};

export type SurveyAppointmentTypeSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
};

export type SurveyAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput = {
  where: SurveyAppointmentTypeSettingScalarWhereInput;
  data: SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutSurveyAppointmentTypeSettingsInput;
};

export type SurveyAppointmentTypeSettingUpdateManyWithWhereWithoutSurveySettingInput = {
  where: SurveyAppointmentTypeSettingScalarWhereInput;
  data: SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeSettingsInput;
};

export type SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput = {
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<SurveyAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  delete?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<SurveyAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<SurveyAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereInput>>;
};

export type SurveyAppointmentTypeSettingUpdateManyWithoutSurveySettingNestedInput = {
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutSurveySettingInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutSurveySettingInput>>;
  upsert?: Maybe<Array<SurveyAppointmentTypeSettingUpsertWithWhereUniqueWithoutSurveySettingInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManySurveySettingInputEnvelope>;
  set?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  delete?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<SurveyAppointmentTypeSettingUpdateWithWhereUniqueWithoutSurveySettingInput>>;
  updateMany?: Maybe<Array<SurveyAppointmentTypeSettingUpdateManyWithWhereWithoutSurveySettingInput>>;
  deleteMany?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereInput>>;
};

export type SurveyAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
  data: SurveyAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput;
};

export type SurveyAppointmentTypeSettingUpdateWithWhereUniqueWithoutSurveySettingInput = {
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
  data: SurveyAppointmentTypeSettingUncheckedUpdateWithoutSurveySettingInput;
};

export type SurveyAppointmentTypeSettingUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  surveySetting?: Maybe<SurveySettingUpdateOneRequiredWithoutAppointmentTypeSettingsNestedInput>;
};

export type SurveyAppointmentTypeSettingUpdateWithoutSurveySettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutSurveyAppointmentTypeSettingsNestedInput>;
};

export type SurveyAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
  update: SurveyAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput;
  create: SurveyAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput;
};

export type SurveyAppointmentTypeSettingUpsertWithWhereUniqueWithoutSurveySettingInput = {
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
  update: SurveyAppointmentTypeSettingUncheckedUpdateWithoutSurveySettingInput;
  create: SurveyAppointmentTypeSettingUncheckedCreateWithoutSurveySettingInput;
};

export type SurveyAppointmentTypeSettingWhereInput = {
  AND?: Maybe<Array<SurveyAppointmentTypeSettingWhereInput>>;
  OR?: Maybe<Array<SurveyAppointmentTypeSettingWhereInput>>;
  NOT?: Maybe<Array<SurveyAppointmentTypeSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isActive?: Maybe<BoolFilter>;
  automatedTriggerInMinutes?: Maybe<IntFilter>;
  appointmentTypeId?: Maybe<StringFilter>;
  surveySettingId?: Maybe<StringFilter>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
  surveySetting?: Maybe<SurveySettingWhereInput>;
};

export type SurveyAppointmentTypeSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SurveyResult = {
  __typename?: 'SurveyResult';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
  type: SurveyType;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  appointmentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinic: Clinic;
  clinicPetParent: ClinicPetParent;
  appointment: Appointment;
  automationRun?: Maybe<AutomationRun>;
};

export type SurveyResultAvgAggregateOutputType = {
  __typename?: 'SurveyResultAvgAggregateOutputType';
  score?: Maybe<Scalars['Float']>;
};

export type SurveyResultAvgOrderByAggregateInput = {
  score?: Maybe<SortOrder>;
};

export type SurveyResultCountAggregateOutputType = {
  __typename?: 'SurveyResultCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isDeleted: Scalars['Int'];
  type: Scalars['Int'];
  surveySentAt: Scalars['Int'];
  score: Scalars['Int'];
  feedback: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  appointmentId: Scalars['Int'];
  automationRunId: Scalars['Int'];
  clinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SurveyResultCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  surveySentAt?: Maybe<SortOrder>;
  score?: Maybe<SortOrder>;
  feedback?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type SurveyResultCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutSurveyResultsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutSurveyResultsInput;
  appointment: AppointmentCreateNestedOneWithoutSurveyResultsInput;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutSurveyResultsInput>;
};

export type SurveyResultCreateManyAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
};

export type SurveyResultCreateManyAppointmentInputEnvelope = {
  data: Array<SurveyResultCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SurveyResultCreateManyAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  appointmentId: Scalars['String'];
  clinicId: Scalars['String'];
};

export type SurveyResultCreateManyAutomationRunInputEnvelope = {
  data: Array<SurveyResultCreateManyAutomationRunInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SurveyResultCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  appointmentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
};

export type SurveyResultCreateManyClinicInputEnvelope = {
  data: Array<SurveyResultCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SurveyResultCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  appointmentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
};

export type SurveyResultCreateManyClinicPetParentInputEnvelope = {
  data: Array<SurveyResultCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SurveyResultCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  appointmentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
};

export type SurveyResultCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<SurveyResultCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
};

export type SurveyResultCreateNestedManyWithoutAutomationRunInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAutomationRunInput>>;
  createMany?: Maybe<SurveyResultCreateManyAutomationRunInputEnvelope>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
};

export type SurveyResultCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
};

export type SurveyResultCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
};

export type SurveyResultCreateOrConnectWithoutAppointmentInput = {
  where: SurveyResultWhereUniqueInput;
  create: SurveyResultUncheckedCreateWithoutAppointmentInput;
};

export type SurveyResultCreateOrConnectWithoutAutomationRunInput = {
  where: SurveyResultWhereUniqueInput;
  create: SurveyResultUncheckedCreateWithoutAutomationRunInput;
};

export type SurveyResultCreateOrConnectWithoutClinicInput = {
  where: SurveyResultWhereUniqueInput;
  create: SurveyResultUncheckedCreateWithoutClinicInput;
};

export type SurveyResultCreateOrConnectWithoutClinicPetParentInput = {
  where: SurveyResultWhereUniqueInput;
  create: SurveyResultUncheckedCreateWithoutClinicPetParentInput;
};

export type SurveyResultCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutSurveyResultsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutSurveyResultsInput;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutSurveyResultsInput>;
};

export type SurveyResultCreateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutSurveyResultsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutSurveyResultsInput;
  appointment: AppointmentCreateNestedOneWithoutSurveyResultsInput;
};

export type SurveyResultCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutSurveyResultsInput;
  appointment: AppointmentCreateNestedOneWithoutSurveyResultsInput;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutSurveyResultsInput>;
};

export type SurveyResultCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutSurveyResultsInput;
  appointment: AppointmentCreateNestedOneWithoutSurveyResultsInput;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutSurveyResultsInput>;
};

export type SurveyResultListRelationFilter = {
  every?: Maybe<SurveyResultWhereInput>;
  some?: Maybe<SurveyResultWhereInput>;
  none?: Maybe<SurveyResultWhereInput>;
};

export type SurveyResultMaxAggregateOutputType = {
  __typename?: 'SurveyResultMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type SurveyResultMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  surveySentAt?: Maybe<SortOrder>;
  score?: Maybe<SortOrder>;
  feedback?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type SurveyResultMinAggregateOutputType = {
  __typename?: 'SurveyResultMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type SurveyResultMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  surveySentAt?: Maybe<SortOrder>;
  score?: Maybe<SortOrder>;
  feedback?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type SurveyResultOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum SurveyResultOrderByRelevanceFieldEnum {
  Id = 'id',
  Feedback = 'feedback',
  ClinicPetParentId = 'clinicPetParentId',
  AppointmentId = 'appointmentId',
  AutomationRunId = 'automationRunId',
  ClinicId = 'clinicId'
}

export type SurveyResultOrderByRelevanceInput = {
  fields: Array<SurveyResultOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type SurveyResultOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  surveySentAt?: Maybe<SortOrder>;
  score?: Maybe<SortOrder>;
  feedback?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  _count?: Maybe<SurveyResultCountOrderByAggregateInput>;
  _avg?: Maybe<SurveyResultAvgOrderByAggregateInput>;
  _max?: Maybe<SurveyResultMaxOrderByAggregateInput>;
  _min?: Maybe<SurveyResultMinOrderByAggregateInput>;
  _sum?: Maybe<SurveyResultSumOrderByAggregateInput>;
};

export type SurveyResultOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  surveySentAt?: Maybe<SortOrder>;
  score?: Maybe<SortOrder>;
  feedback?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  automationRun?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<SurveyResultOrderByRelevanceInput>;
};

export enum SurveyResultScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsDeleted = 'isDeleted',
  Type = 'type',
  SurveySentAt = 'surveySentAt',
  Score = 'score',
  Feedback = 'feedback',
  ClinicPetParentId = 'clinicPetParentId',
  AppointmentId = 'appointmentId',
  AutomationRunId = 'automationRunId',
  ClinicId = 'clinicId'
}

export type SurveyResultScalarWhereInput = {
  AND?: Maybe<Array<SurveyResultScalarWhereInput>>;
  OR?: Maybe<Array<SurveyResultScalarWhereInput>>;
  NOT?: Maybe<Array<SurveyResultScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  type?: Maybe<EnumSurveyTypeFilter>;
  surveySentAt?: Maybe<DateTimeFilter>;
  score?: Maybe<IntFilter>;
  feedback?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  appointmentId?: Maybe<StringFilter>;
  automationRunId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
};

export type SurveyResultScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SurveyResultScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SurveyResultScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SurveyResultScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  type?: Maybe<EnumSurveyTypeWithAggregatesFilter>;
  surveySentAt?: Maybe<DateTimeWithAggregatesFilter>;
  score?: Maybe<IntWithAggregatesFilter>;
  feedback?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  appointmentId?: Maybe<StringWithAggregatesFilter>;
  automationRunId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
};

export type SurveyResultSumAggregateOutputType = {
  __typename?: 'SurveyResultSumAggregateOutputType';
  score?: Maybe<Scalars['Int']>;
};

export type SurveyResultSumOrderByAggregateInput = {
  score?: Maybe<SortOrder>;
};

export type SurveyResultUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  appointmentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
};

export type SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<SurveyResultCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
};

export type SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAutomationRunInput>>;
  createMany?: Maybe<SurveyResultCreateManyAutomationRunInputEnvelope>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
};

export type SurveyResultUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
};

export type SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
};

export type SurveyResultUncheckedCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
};

export type SurveyResultUncheckedCreateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  appointmentId: Scalars['String'];
  clinicId: Scalars['String'];
};

export type SurveyResultUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  appointmentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
};

export type SurveyResultUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt: Scalars['DateTime'];
  score: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  appointmentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
};

export type SurveyResultUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type SurveyResultUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<SurveyResultCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
};

export type SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAutomationRunInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutAutomationRunInput>>;
  createMany?: Maybe<SurveyResultCreateManyAutomationRunInputEnvelope>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutAutomationRunInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutAutomationRunInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
};

export type SurveyResultUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
};

export type SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
};

export type SurveyResultUncheckedUpdateManyWithoutSurveyResultsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type SurveyResultUncheckedUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type SurveyResultUncheckedUpdateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type SurveyResultUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
};

export type SurveyResultUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type SurveyResultUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutSurveyResultsNestedInput>;
};

export type SurveyResultUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
};

export type SurveyResultUpdateManyWithWhereWithoutAppointmentInput = {
  where: SurveyResultScalarWhereInput;
  data: SurveyResultUncheckedUpdateManyWithoutSurveyResultsInput;
};

export type SurveyResultUpdateManyWithWhereWithoutAutomationRunInput = {
  where: SurveyResultScalarWhereInput;
  data: SurveyResultUncheckedUpdateManyWithoutSurveyResultsInput;
};

export type SurveyResultUpdateManyWithWhereWithoutClinicInput = {
  where: SurveyResultScalarWhereInput;
  data: SurveyResultUncheckedUpdateManyWithoutSurveyResultsInput;
};

export type SurveyResultUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: SurveyResultScalarWhereInput;
  data: SurveyResultUncheckedUpdateManyWithoutSurveyResultsInput;
};

export type SurveyResultUpdateManyWithoutAppointmentNestedInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<SurveyResultCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
};

export type SurveyResultUpdateManyWithoutAutomationRunNestedInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAutomationRunInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutAutomationRunInput>>;
  createMany?: Maybe<SurveyResultCreateManyAutomationRunInputEnvelope>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutAutomationRunInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutAutomationRunInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
};

export type SurveyResultUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
};

export type SurveyResultUpdateManyWithoutClinicPetParentNestedInput = {
  create?: Maybe<Array<SurveyResultCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
};

export type SurveyResultUpdateWithWhereUniqueWithoutAppointmentInput = {
  where: SurveyResultWhereUniqueInput;
  data: SurveyResultUncheckedUpdateWithoutAppointmentInput;
};

export type SurveyResultUpdateWithWhereUniqueWithoutAutomationRunInput = {
  where: SurveyResultWhereUniqueInput;
  data: SurveyResultUncheckedUpdateWithoutAutomationRunInput;
};

export type SurveyResultUpdateWithWhereUniqueWithoutClinicInput = {
  where: SurveyResultWhereUniqueInput;
  data: SurveyResultUncheckedUpdateWithoutClinicInput;
};

export type SurveyResultUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: SurveyResultWhereUniqueInput;
  data: SurveyResultUncheckedUpdateWithoutClinicPetParentInput;
};

export type SurveyResultUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutSurveyResultsNestedInput>;
};

export type SurveyResultUpdateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
};

export type SurveyResultUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutSurveyResultsNestedInput>;
};

export type SurveyResultUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SurveyType>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  score?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutSurveyResultsNestedInput>;
};

export type SurveyResultUpsertWithWhereUniqueWithoutAppointmentInput = {
  where: SurveyResultWhereUniqueInput;
  update: SurveyResultUncheckedUpdateWithoutAppointmentInput;
  create: SurveyResultUncheckedCreateWithoutAppointmentInput;
};

export type SurveyResultUpsertWithWhereUniqueWithoutAutomationRunInput = {
  where: SurveyResultWhereUniqueInput;
  update: SurveyResultUncheckedUpdateWithoutAutomationRunInput;
  create: SurveyResultUncheckedCreateWithoutAutomationRunInput;
};

export type SurveyResultUpsertWithWhereUniqueWithoutClinicInput = {
  where: SurveyResultWhereUniqueInput;
  update: SurveyResultUncheckedUpdateWithoutClinicInput;
  create: SurveyResultUncheckedCreateWithoutClinicInput;
};

export type SurveyResultUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: SurveyResultWhereUniqueInput;
  update: SurveyResultUncheckedUpdateWithoutClinicPetParentInput;
  create: SurveyResultUncheckedCreateWithoutClinicPetParentInput;
};

export type SurveyResultWhereInput = {
  AND?: Maybe<Array<SurveyResultWhereInput>>;
  OR?: Maybe<Array<SurveyResultWhereInput>>;
  NOT?: Maybe<Array<SurveyResultWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  type?: Maybe<EnumSurveyTypeFilter>;
  surveySentAt?: Maybe<DateTimeFilter>;
  score?: Maybe<IntFilter>;
  feedback?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  appointmentId?: Maybe<StringFilter>;
  automationRunId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  appointment?: Maybe<AppointmentWhereInput>;
  automationRun?: Maybe<AutomationRunWhereInput>;
};

export type SurveyResultWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SurveySetting = {
  __typename?: 'SurveySetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
  isAutomatedTriggerForAllAppointmentTypes: Scalars['Boolean'];
  frequencyInDays: Scalars['Int'];
  showLinkToLeaveReview: Scalars['Boolean'];
  showLinkToLeaveReviewNeutral: Scalars['Boolean'];
  showLinkToLeaveReviewSatisfied: Scalars['Boolean'];
  linkToLeaveReview?: Maybe<Scalars['String']>;
  workflowEventSettings: Array<WorkflowEventSetting>;
  appointmentTypeSettings: Array<SurveyAppointmentTypeSetting>;
  _count: SurveySettingCountOutputType;
};


export type SurveySettingWorkflowEventSettingsArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
};


export type SurveySettingAppointmentTypeSettingsArgs = {
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<SurveyAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SurveyAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SurveyAppointmentTypeSettingScalarFieldEnum>;
};

export type SurveySettingAvgAggregateOutputType = {
  __typename?: 'SurveySettingAvgAggregateOutputType';
  frequencyInDays?: Maybe<Scalars['Float']>;
};

export type SurveySettingAvgOrderByAggregateInput = {
  frequencyInDays?: Maybe<SortOrder>;
};

export type SurveySettingCountAggregateOutputType = {
  __typename?: 'SurveySettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isAutomatedTriggerForAllAppointmentTypes: Scalars['Int'];
  frequencyInDays: Scalars['Int'];
  showLinkToLeaveReview: Scalars['Int'];
  showLinkToLeaveReviewNeutral: Scalars['Int'];
  showLinkToLeaveReviewSatisfied: Scalars['Int'];
  linkToLeaveReview: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SurveySettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<SortOrder>;
  frequencyInDays?: Maybe<SortOrder>;
  showLinkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReviewNeutral?: Maybe<SortOrder>;
  showLinkToLeaveReviewSatisfied?: Maybe<SortOrder>;
  linkToLeaveReview?: Maybe<SortOrder>;
};

export type SurveySettingCountOutputType = {
  __typename?: 'SurveySettingCountOutputType';
  workflowEventSettings: Scalars['Int'];
  appointmentTypeSettings: Scalars['Int'];
};

export type SurveySettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutSurveySettingInput>;
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutSurveySettingInput>;
};

export type SurveySettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
};

export type SurveySettingCreateNestedOneWithoutAppointmentTypeSettingsInput = {
  create?: Maybe<SurveySettingUncheckedCreateWithoutAppointmentTypeSettingsInput>;
  connectOrCreate?: Maybe<SurveySettingCreateOrConnectWithoutAppointmentTypeSettingsInput>;
  connect?: Maybe<SurveySettingWhereUniqueInput>;
};

export type SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput = {
  create?: Maybe<SurveySettingUncheckedCreateWithoutWorkflowEventSettingsInput>;
  connectOrCreate?: Maybe<SurveySettingCreateOrConnectWithoutWorkflowEventSettingsInput>;
  connect?: Maybe<SurveySettingWhereUniqueInput>;
};

export type SurveySettingCreateOrConnectWithoutAppointmentTypeSettingsInput = {
  where: SurveySettingWhereUniqueInput;
  create: SurveySettingUncheckedCreateWithoutAppointmentTypeSettingsInput;
};

export type SurveySettingCreateOrConnectWithoutWorkflowEventSettingsInput = {
  where: SurveySettingWhereUniqueInput;
  create: SurveySettingUncheckedCreateWithoutWorkflowEventSettingsInput;
};

export type SurveySettingCreateWithoutAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutSurveySettingInput>;
};

export type SurveySettingCreateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutSurveySettingInput>;
};

export type SurveySettingMaxAggregateOutputType = {
  __typename?: 'SurveySettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
};

export type SurveySettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<SortOrder>;
  frequencyInDays?: Maybe<SortOrder>;
  showLinkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReviewNeutral?: Maybe<SortOrder>;
  showLinkToLeaveReviewSatisfied?: Maybe<SortOrder>;
  linkToLeaveReview?: Maybe<SortOrder>;
};

export type SurveySettingMinAggregateOutputType = {
  __typename?: 'SurveySettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
};

export type SurveySettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<SortOrder>;
  frequencyInDays?: Maybe<SortOrder>;
  showLinkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReviewNeutral?: Maybe<SortOrder>;
  showLinkToLeaveReviewSatisfied?: Maybe<SortOrder>;
  linkToLeaveReview?: Maybe<SortOrder>;
};

export enum SurveySettingOrderByRelevanceFieldEnum {
  Id = 'id',
  LinkToLeaveReview = 'linkToLeaveReview'
}

export type SurveySettingOrderByRelevanceInput = {
  fields: Array<SurveySettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type SurveySettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<SortOrder>;
  frequencyInDays?: Maybe<SortOrder>;
  showLinkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReviewNeutral?: Maybe<SortOrder>;
  showLinkToLeaveReviewSatisfied?: Maybe<SortOrder>;
  linkToLeaveReview?: Maybe<SortOrder>;
  _count?: Maybe<SurveySettingCountOrderByAggregateInput>;
  _avg?: Maybe<SurveySettingAvgOrderByAggregateInput>;
  _max?: Maybe<SurveySettingMaxOrderByAggregateInput>;
  _min?: Maybe<SurveySettingMinOrderByAggregateInput>;
  _sum?: Maybe<SurveySettingSumOrderByAggregateInput>;
};

export type SurveySettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<SortOrder>;
  frequencyInDays?: Maybe<SortOrder>;
  showLinkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReviewNeutral?: Maybe<SortOrder>;
  showLinkToLeaveReviewSatisfied?: Maybe<SortOrder>;
  linkToLeaveReview?: Maybe<SortOrder>;
  workflowEventSettings?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingOrderByRelationAggregateInput>;
  _relevance?: Maybe<SurveySettingOrderByRelevanceInput>;
};

export type SurveySettingRelationFilter = {
  is?: Maybe<SurveySettingWhereInput>;
  isNot?: Maybe<SurveySettingWhereInput>;
};

export enum SurveySettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsDeleted = 'isDeleted',
  IsAutomatedTriggerForAllAppointmentTypes = 'isAutomatedTriggerForAllAppointmentTypes',
  FrequencyInDays = 'frequencyInDays',
  ShowLinkToLeaveReview = 'showLinkToLeaveReview',
  ShowLinkToLeaveReviewNeutral = 'showLinkToLeaveReviewNeutral',
  ShowLinkToLeaveReviewSatisfied = 'showLinkToLeaveReviewSatisfied',
  LinkToLeaveReview = 'linkToLeaveReview'
}

export type SurveySettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SurveySettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SurveySettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SurveySettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<BoolWithAggregatesFilter>;
  frequencyInDays?: Maybe<IntWithAggregatesFilter>;
  showLinkToLeaveReview?: Maybe<BoolWithAggregatesFilter>;
  showLinkToLeaveReviewNeutral?: Maybe<BoolWithAggregatesFilter>;
  showLinkToLeaveReviewSatisfied?: Maybe<BoolWithAggregatesFilter>;
  linkToLeaveReview?: Maybe<StringNullableWithAggregatesFilter>;
};

export type SurveySettingSumAggregateOutputType = {
  __typename?: 'SurveySettingSumAggregateOutputType';
  frequencyInDays?: Maybe<Scalars['Int']>;
};

export type SurveySettingSumOrderByAggregateInput = {
  frequencyInDays?: Maybe<SortOrder>;
};

export type SurveySettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutSurveySettingInput>;
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutSurveySettingInput>;
};

export type SurveySettingUncheckedCreateWithoutAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutSurveySettingInput>;
};

export type SurveySettingUncheckedCreateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutSurveySettingInput>;
};

export type SurveySettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutSurveySettingNestedInput>;
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutSurveySettingNestedInput>;
};

export type SurveySettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
};

export type SurveySettingUncheckedUpdateWithoutAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutSurveySettingNestedInput>;
};

export type SurveySettingUncheckedUpdateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutSurveySettingNestedInput>;
};

export type SurveySettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutSurveySettingNestedInput>;
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutSurveySettingNestedInput>;
};

export type SurveySettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
};

export type SurveySettingUpdateOneRequiredWithoutAppointmentTypeSettingsNestedInput = {
  create?: Maybe<SurveySettingUncheckedCreateWithoutAppointmentTypeSettingsInput>;
  connectOrCreate?: Maybe<SurveySettingCreateOrConnectWithoutAppointmentTypeSettingsInput>;
  upsert?: Maybe<SurveySettingUpsertWithoutAppointmentTypeSettingsInput>;
  connect?: Maybe<SurveySettingWhereUniqueInput>;
  update?: Maybe<SurveySettingUncheckedUpdateWithoutAppointmentTypeSettingsInput>;
};

export type SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput = {
  create?: Maybe<SurveySettingUncheckedCreateWithoutWorkflowEventSettingsInput>;
  connectOrCreate?: Maybe<SurveySettingCreateOrConnectWithoutWorkflowEventSettingsInput>;
  upsert?: Maybe<SurveySettingUpsertWithoutWorkflowEventSettingsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SurveySettingWhereUniqueInput>;
  update?: Maybe<SurveySettingUncheckedUpdateWithoutWorkflowEventSettingsInput>;
};

export type SurveySettingUpdateWithoutAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutSurveySettingNestedInput>;
};

export type SurveySettingUpdateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutSurveySettingNestedInput>;
};

export type SurveySettingUpsertWithoutAppointmentTypeSettingsInput = {
  update: SurveySettingUncheckedUpdateWithoutAppointmentTypeSettingsInput;
  create: SurveySettingUncheckedCreateWithoutAppointmentTypeSettingsInput;
};

export type SurveySettingUpsertWithoutWorkflowEventSettingsInput = {
  update: SurveySettingUncheckedUpdateWithoutWorkflowEventSettingsInput;
  create: SurveySettingUncheckedCreateWithoutWorkflowEventSettingsInput;
};

export type SurveySettingWhereInput = {
  AND?: Maybe<Array<SurveySettingWhereInput>>;
  OR?: Maybe<Array<SurveySettingWhereInput>>;
  NOT?: Maybe<Array<SurveySettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<BoolFilter>;
  frequencyInDays?: Maybe<IntFilter>;
  showLinkToLeaveReview?: Maybe<BoolFilter>;
  showLinkToLeaveReviewNeutral?: Maybe<BoolFilter>;
  showLinkToLeaveReviewSatisfied?: Maybe<BoolFilter>;
  linkToLeaveReview?: Maybe<StringNullableFilter>;
  workflowEventSettings?: Maybe<WorkflowEventSettingListRelationFilter>;
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingListRelationFilter>;
};

export type SurveySettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum SurveyStep {
  Rating = 'Rating',
  SatisfiedFeedback = 'SatisfiedFeedback',
  DissatisfiedFeedback = 'DissatisfiedFeedback',
  PassivePromoterFeedback = 'PassivePromoterFeedback',
  DetractorFeedback = 'DetractorFeedback'
}

export enum SurveyType {
  Nps = 'NPS',
  Csat = 'CSAT'
}

export type SyncManyPimsIntegrationModelInput = {
  clinicId: Scalars['String'];
  models?: Maybe<Array<PimsIntegrationCapability>>;
  syncFrom?: Maybe<Scalars['String']>;
  integrationTypes?: Maybe<Array<Maybe<ClinicPimsIntegrationType>>>;
};

export type SyncPimsIntegrationModelInput = {
  clinicId: Scalars['String'];
  model: PimsIntegrationCapability;
  syncFrom?: Maybe<Scalars['String']>;
  integrationTypes?: Maybe<Array<Maybe<ClinicPimsIntegrationType>>>;
};

export type SyncVetIntegration = {
  __typename?: 'SyncVetIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pimsName: Scalars['String'];
  syncvetId: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type SyncVetIntegrationCountAggregateOutputType = {
  __typename?: 'SyncVetIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  pimsName: Scalars['Int'];
  syncvetId: Scalars['Int'];
  practiceId: Scalars['Int'];
  siteId: Scalars['Int'];
  integrationStatus: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SyncVetIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  integrationStatus?: Maybe<SortOrder>;
};

export type SyncVetIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsName: Scalars['String'];
  syncvetId: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutSyncvetInput>;
};

export type SyncVetIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsName: Scalars['String'];
  syncvetId: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
};

export type SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<SyncVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<SyncVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<SyncVetIntegrationWhereUniqueInput>;
};

export type SyncVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: SyncVetIntegrationWhereUniqueInput;
  create: SyncVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type SyncVetIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsName: Scalars['String'];
  syncvetId: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
};

export type SyncVetIntegrationMaxAggregateOutputType = {
  __typename?: 'SyncVetIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsName?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
};

export type SyncVetIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  integrationStatus?: Maybe<SortOrder>;
};

export type SyncVetIntegrationMinAggregateOutputType = {
  __typename?: 'SyncVetIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsName?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
};

export type SyncVetIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  integrationStatus?: Maybe<SortOrder>;
};

export enum SyncVetIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  PimsName = 'pimsName',
  SyncvetId = 'syncvetId',
  PracticeId = 'practiceId',
  SiteId = 'siteId'
}

export type SyncVetIntegrationOrderByRelevanceInput = {
  fields: Array<SyncVetIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type SyncVetIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  integrationStatus?: Maybe<SortOrder>;
  _count?: Maybe<SyncVetIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<SyncVetIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<SyncVetIntegrationMinOrderByAggregateInput>;
};

export type SyncVetIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  integrationStatus?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<SyncVetIntegrationOrderByRelevanceInput>;
};

export type SyncVetIntegrationRelationFilter = {
  is?: Maybe<SyncVetIntegrationWhereInput>;
  isNot?: Maybe<SyncVetIntegrationWhereInput>;
};

export enum SyncVetIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PimsName = 'pimsName',
  SyncvetId = 'syncvetId',
  PracticeId = 'practiceId',
  SiteId = 'siteId',
  IntegrationStatus = 'integrationStatus'
}

export type SyncVetIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SyncVetIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SyncVetIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SyncVetIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  pimsName?: Maybe<StringWithAggregatesFilter>;
  syncvetId?: Maybe<StringWithAggregatesFilter>;
  practiceId?: Maybe<StringNullableWithAggregatesFilter>;
  siteId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationStatus?: Maybe<EnumIntegrationStatusNullableWithAggregatesFilter>;
};

export type SyncVetIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsName: Scalars['String'];
  syncvetId: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutSyncvetInput>;
};

export type SyncVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsName: Scalars['String'];
  syncvetId: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
};

export type SyncVetIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsName?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutSyncvetNestedInput>;
};

export type SyncVetIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsName?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
};

export type SyncVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsName?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
};

export type SyncVetIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsName?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutSyncvetNestedInput>;
};

export type SyncVetIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsName?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
};

export type SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  create?: Maybe<SyncVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<SyncVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<SyncVetIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SyncVetIntegrationWhereUniqueInput>;
  update?: Maybe<SyncVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type SyncVetIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsName?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
};

export type SyncVetIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: SyncVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: SyncVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type SyncVetIntegrationWhereInput = {
  AND?: Maybe<Array<SyncVetIntegrationWhereInput>>;
  OR?: Maybe<Array<SyncVetIntegrationWhereInput>>;
  NOT?: Maybe<Array<SyncVetIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  pimsName?: Maybe<StringFilter>;
  syncvetId?: Maybe<StringFilter>;
  practiceId?: Maybe<StringNullableFilter>;
  siteId?: Maybe<StringNullableFilter>;
  integrationStatus?: Maybe<EnumIntegrationStatusNullableFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type SyncVetIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SystemMessageCreateInput = {
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  channelId?: Maybe<Scalars['String']>;
  petParentIds?: Maybe<Array<Scalars['String']>>;
  messageType?: Maybe<MessageType>;
};

export type TagEntity = {
  id: Scalars['String'];
  type: TagEntityType;
  label: Scalars['String'];
};

export enum TagEntityType {
  FormSubmission = 'FormSubmission',
  ClinicPet = 'ClinicPet'
}

export type TagInput = {
  name: Scalars['String'];
  tagType?: Maybe<TagType>;
  colorBackground?: Maybe<Scalars['String']>;
  colorText?: Maybe<Scalars['String']>;
  tagEntities?: Maybe<Array<TagEntity>>;
};

export enum TagType {
  Custom = 'Custom',
  PaymentRequested = 'PaymentRequested',
  PaymentReceived = 'PaymentReceived',
  FormSubmitted = 'FormSubmitted',
  FormRequested = 'FormRequested',
  TrupanionCandidate = 'TrupanionCandidate'
}

export enum TemplateAvailability {
  Clinic = 'Clinic',
  Organization = 'Organization',
  Global = 'Global',
  Care = 'Care'
}

export type TerminalAppPaymentResponse = {
  __typename?: 'TerminalAppPaymentResponse';
  paymentIntent: StripePaymentIntent;
  clientSecret: Scalars['String'];
};

export type TinyUrl = {
  __typename?: 'TinyUrl';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  code: Scalars['String'];
  url: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type TinyUrlCountAggregateOutputType = {
  __typename?: 'TinyUrlCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  code: Scalars['Int'];
  url: Scalars['Int'];
  isActive: Scalars['Int'];
  token: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TinyUrlCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
};

export type TinyUrlCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  url: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type TinyUrlCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  url: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type TinyUrlMaxAggregateOutputType = {
  __typename?: 'TinyUrlMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type TinyUrlMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
};

export type TinyUrlMinAggregateOutputType = {
  __typename?: 'TinyUrlMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type TinyUrlMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
};

export enum TinyUrlOrderByRelevanceFieldEnum {
  Id = 'id',
  Code = 'code',
  Url = 'url',
  Token = 'token'
}

export type TinyUrlOrderByRelevanceInput = {
  fields: Array<TinyUrlOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type TinyUrlOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  _count?: Maybe<TinyUrlCountOrderByAggregateInput>;
  _max?: Maybe<TinyUrlMaxOrderByAggregateInput>;
  _min?: Maybe<TinyUrlMinOrderByAggregateInput>;
};

export type TinyUrlOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  _relevance?: Maybe<TinyUrlOrderByRelevanceInput>;
};

export enum TinyUrlScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Code = 'code',
  Url = 'url',
  IsActive = 'isActive',
  Token = 'token'
}

export type TinyUrlScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TinyUrlScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TinyUrlScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TinyUrlScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  code?: Maybe<StringWithAggregatesFilter>;
  url?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  token?: Maybe<StringWithAggregatesFilter>;
};

export type TinyUrlUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  url: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type TinyUrlUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type TinyUrlUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type TinyUrlUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type TinyUrlUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type TinyUrlWhereInput = {
  AND?: Maybe<Array<TinyUrlWhereInput>>;
  OR?: Maybe<Array<TinyUrlWhereInput>>;
  NOT?: Maybe<Array<TinyUrlWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  code?: Maybe<StringFilter>;
  url?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  token?: Maybe<StringFilter>;
};

export type TinyUrlWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type TokenOutput = {
  __typename?: 'TokenOutput';
  token: Scalars['String'];
};

export type TokenValidationInput = {
  clinicPetParentId: Scalars['String'];
};

/** @deprecated - 1.0 */
export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source: PaymentSource;
  consultation?: Maybe<Consultation>;
  disbursement?: Maybe<Disbursement>;
};

export type TransactionAvgAggregateOutputType = {
  __typename?: 'TransactionAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
};

export type TransactionAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
};

export type TransactionCountAggregateOutputType = {
  __typename?: 'TransactionCountAggregateOutputType';
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
  transactionId: Scalars['Int'];
  status: Scalars['Int'];
  createdAt: Scalars['Int'];
  settlementDate: Scalars['Int'];
  amount: Scalars['Int'];
  amountForSettlement: Scalars['Int'];
  serviceFeeAmount: Scalars['Int'];
  cardType: Scalars['Int'];
  cardLast4: Scalars['Int'];
  amountSettled: Scalars['Int'];
  clinicEarnings: Scalars['Int'];
  consultationId: Scalars['Int'];
  disbursementId: Scalars['Int'];
  braintreeGraphqlId: Scalars['Int'];
  disbursementDate: Scalars['Int'];
  source: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TransactionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type TransactionCreateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutTransactionsInput>;
  disbursement?: Maybe<DisbursementCreateNestedOneWithoutTransactionsInput>;
};

export type TransactionCreateManyConsultationInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionCreateManyConsultationInputEnvelope = {
  data: Array<TransactionCreateManyConsultationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TransactionCreateManyDisbursementInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionCreateManyDisbursementInputEnvelope = {
  data: Array<TransactionCreateManyDisbursementInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TransactionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<TransactionCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutConsultationInput>>;
  createMany?: Maybe<TransactionCreateManyConsultationInputEnvelope>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
};

export type TransactionCreateNestedManyWithoutDisbursementInput = {
  create?: Maybe<Array<TransactionCreateWithoutDisbursementInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutDisbursementInput>>;
  createMany?: Maybe<TransactionCreateManyDisbursementInputEnvelope>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
};

export type TransactionCreateOrConnectWithoutConsultationInput = {
  where: TransactionWhereUniqueInput;
  create: TransactionUncheckedCreateWithoutConsultationInput;
};

export type TransactionCreateOrConnectWithoutDisbursementInput = {
  where: TransactionWhereUniqueInput;
  create: TransactionUncheckedCreateWithoutDisbursementInput;
};

export type TransactionCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
  disbursement?: Maybe<DisbursementCreateNestedOneWithoutTransactionsInput>;
};

export type TransactionCreateWithoutDisbursementInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutTransactionsInput>;
};

export enum TransactionIsolationLevel {
  ReadUncommitted = 'ReadUncommitted',
  ReadCommitted = 'ReadCommitted',
  RepeatableRead = 'RepeatableRead',
  Serializable = 'Serializable'
}

export type TransactionListRelationFilter = {
  every?: Maybe<TransactionWhereInput>;
  some?: Maybe<TransactionWhereInput>;
  none?: Maybe<TransactionWhereInput>;
};

export type TransactionMaxAggregateOutputType = {
  __typename?: 'TransactionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type TransactionMinAggregateOutputType = {
  __typename?: 'TransactionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type TransactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum TransactionOrderByRelevanceFieldEnum {
  Id = 'id',
  TransactionId = 'transactionId',
  Status = 'status',
  CardType = 'cardType',
  CardLast4 = 'cardLast4',
  ConsultationId = 'consultationId',
  DisbursementId = 'disbursementId',
  BraintreeGraphqlId = 'braintreeGraphqlId',
  DisbursementDate = 'disbursementDate'
}

export type TransactionOrderByRelevanceInput = {
  fields: Array<TransactionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type TransactionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  _count?: Maybe<TransactionCountOrderByAggregateInput>;
  _avg?: Maybe<TransactionAvgOrderByAggregateInput>;
  _max?: Maybe<TransactionMaxOrderByAggregateInput>;
  _min?: Maybe<TransactionMinOrderByAggregateInput>;
  _sum?: Maybe<TransactionSumOrderByAggregateInput>;
};

export type TransactionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  consultation?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  disbursement?: Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<TransactionOrderByRelevanceInput>;
};

export enum TransactionScalarFieldEnum {
  Id = 'id',
  UpdatedAt = 'updatedAt',
  TransactionId = 'transactionId',
  Status = 'status',
  CreatedAt = 'createdAt',
  SettlementDate = 'settlementDate',
  Amount = 'amount',
  AmountForSettlement = 'amountForSettlement',
  ServiceFeeAmount = 'serviceFeeAmount',
  CardType = 'cardType',
  CardLast4 = 'cardLast4',
  AmountSettled = 'amountSettled',
  ClinicEarnings = 'clinicEarnings',
  ConsultationId = 'consultationId',
  DisbursementId = 'disbursementId',
  BraintreeGraphqlId = 'braintreeGraphqlId',
  DisbursementDate = 'disbursementDate',
  Source = 'source'
}

export type TransactionScalarWhereInput = {
  AND?: Maybe<Array<TransactionScalarWhereInput>>;
  OR?: Maybe<Array<TransactionScalarWhereInput>>;
  NOT?: Maybe<Array<TransactionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  transactionId?: Maybe<StringFilter>;
  status?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  settlementDate?: Maybe<DateTimeNullableFilter>;
  amount?: Maybe<FloatFilter>;
  amountForSettlement?: Maybe<FloatNullableFilter>;
  serviceFeeAmount?: Maybe<FloatNullableFilter>;
  cardType?: Maybe<StringFilter>;
  cardLast4?: Maybe<StringFilter>;
  amountSettled?: Maybe<FloatNullableFilter>;
  clinicEarnings?: Maybe<FloatNullableFilter>;
  consultationId?: Maybe<StringNullableFilter>;
  disbursementId?: Maybe<StringNullableFilter>;
  braintreeGraphqlId?: Maybe<StringNullableFilter>;
  disbursementDate?: Maybe<StringNullableFilter>;
  source?: Maybe<EnumPaymentSourceFilter>;
};

export type TransactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  transactionId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  settlementDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  amountForSettlement?: Maybe<FloatNullableWithAggregatesFilter>;
  serviceFeeAmount?: Maybe<FloatNullableWithAggregatesFilter>;
  cardType?: Maybe<StringWithAggregatesFilter>;
  cardLast4?: Maybe<StringWithAggregatesFilter>;
  amountSettled?: Maybe<FloatNullableWithAggregatesFilter>;
  clinicEarnings?: Maybe<FloatNullableWithAggregatesFilter>;
  consultationId?: Maybe<StringNullableWithAggregatesFilter>;
  disbursementId?: Maybe<StringNullableWithAggregatesFilter>;
  braintreeGraphqlId?: Maybe<StringNullableWithAggregatesFilter>;
  disbursementDate?: Maybe<StringNullableWithAggregatesFilter>;
  source?: Maybe<EnumPaymentSourceWithAggregatesFilter>;
};

export type TransactionSumAggregateOutputType = {
  __typename?: 'TransactionSumAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
};

export type TransactionSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
};

export type TransactionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUncheckedCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<TransactionCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutConsultationInput>>;
  createMany?: Maybe<TransactionCreateManyConsultationInputEnvelope>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
};

export type TransactionUncheckedCreateNestedManyWithoutDisbursementInput = {
  create?: Maybe<Array<TransactionCreateWithoutDisbursementInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutDisbursementInput>>;
  createMany?: Maybe<TransactionCreateManyDisbursementInputEnvelope>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
};

export type TransactionUncheckedCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUncheckedCreateWithoutDisbursementInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUncheckedUpdateManyWithoutConsultationNestedInput = {
  create?: Maybe<Array<TransactionCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutConsultationInput>>;
  createMany?: Maybe<TransactionCreateManyConsultationInputEnvelope>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
};

export type TransactionUncheckedUpdateManyWithoutDisbursementNestedInput = {
  create?: Maybe<Array<TransactionCreateWithoutDisbursementInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutDisbursementInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutDisbursementInput>>;
  createMany?: Maybe<TransactionCreateManyDisbursementInputEnvelope>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutDisbursementInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutDisbursementInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
};

export type TransactionUncheckedUpdateManyWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUncheckedUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUncheckedUpdateWithoutDisbursementInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
  consultation?: Maybe<ConsultationUpdateOneWithoutTransactionsNestedInput>;
  disbursement?: Maybe<DisbursementUpdateOneWithoutTransactionsNestedInput>;
};

export type TransactionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUpdateManyWithWhereWithoutConsultationInput = {
  where: TransactionScalarWhereInput;
  data: TransactionUncheckedUpdateManyWithoutTransactionsInput;
};

export type TransactionUpdateManyWithWhereWithoutDisbursementInput = {
  where: TransactionScalarWhereInput;
  data: TransactionUncheckedUpdateManyWithoutTransactionsInput;
};

export type TransactionUpdateManyWithoutConsultationNestedInput = {
  create?: Maybe<Array<TransactionCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutConsultationInput>>;
  createMany?: Maybe<TransactionCreateManyConsultationInputEnvelope>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
};

export type TransactionUpdateManyWithoutDisbursementNestedInput = {
  create?: Maybe<Array<TransactionCreateWithoutDisbursementInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutDisbursementInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutDisbursementInput>>;
  createMany?: Maybe<TransactionCreateManyDisbursementInputEnvelope>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutDisbursementInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutDisbursementInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
};

export type TransactionUpdateWithWhereUniqueWithoutConsultationInput = {
  where: TransactionWhereUniqueInput;
  data: TransactionUncheckedUpdateWithoutConsultationInput;
};

export type TransactionUpdateWithWhereUniqueWithoutDisbursementInput = {
  where: TransactionWhereUniqueInput;
  data: TransactionUncheckedUpdateWithoutDisbursementInput;
};

export type TransactionUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
  disbursement?: Maybe<DisbursementUpdateOneWithoutTransactionsNestedInput>;
};

export type TransactionUpdateWithoutDisbursementInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
  consultation?: Maybe<ConsultationUpdateOneWithoutTransactionsNestedInput>;
};

export type TransactionUpsertWithWhereUniqueWithoutConsultationInput = {
  where: TransactionWhereUniqueInput;
  update: TransactionUncheckedUpdateWithoutConsultationInput;
  create: TransactionUncheckedCreateWithoutConsultationInput;
};

export type TransactionUpsertWithWhereUniqueWithoutDisbursementInput = {
  where: TransactionWhereUniqueInput;
  update: TransactionUncheckedUpdateWithoutDisbursementInput;
  create: TransactionUncheckedCreateWithoutDisbursementInput;
};

export type TransactionWhereInput = {
  AND?: Maybe<Array<TransactionWhereInput>>;
  OR?: Maybe<Array<TransactionWhereInput>>;
  NOT?: Maybe<Array<TransactionWhereInput>>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  transactionId?: Maybe<StringFilter>;
  status?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  settlementDate?: Maybe<DateTimeNullableFilter>;
  amount?: Maybe<FloatFilter>;
  amountForSettlement?: Maybe<FloatNullableFilter>;
  serviceFeeAmount?: Maybe<FloatNullableFilter>;
  cardType?: Maybe<StringFilter>;
  cardLast4?: Maybe<StringFilter>;
  amountSettled?: Maybe<FloatNullableFilter>;
  clinicEarnings?: Maybe<FloatNullableFilter>;
  consultationId?: Maybe<StringNullableFilter>;
  disbursementId?: Maybe<StringNullableFilter>;
  braintreeGraphqlId?: Maybe<StringNullableFilter>;
  disbursementDate?: Maybe<StringNullableFilter>;
  source?: Maybe<EnumPaymentSourceFilter>;
  consultation?: Maybe<ConsultationWhereInput>;
  disbursement?: Maybe<DisbursementWhereInput>;
};

export type TransactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
};

export type TransferSubscriptionInput = {
  careStripeSubscriptionId: Scalars['String'];
  carePlanEnrollmentId: Scalars['String'];
  currentClinicPetId: Scalars['String'];
  newClinicPetId: Scalars['String'];
};

export type TransferSubscriptionOutput = {
  __typename?: 'TransferSubscriptionOutput';
  success: Scalars['Boolean'];
};

export type TransferTwilioNumberInput = {
  phoneNumberSid: Scalars['String'];
  fromSubaccountSid?: Maybe<Scalars['String']>;
};

export type TriggerMessageEventInput = {
  data?: Maybe<Scalars['Json']>;
  event?: Maybe<Scalars['String']>;
  queueName?: Maybe<SqsQueueNames>;
  useSubscription?: Maybe<Scalars['Boolean']>;
};

export type TriggerWorkflowEventInput = {
  entityId: Scalars['String'];
  event: Scalars['String'];
  actionNumber?: Maybe<Scalars['Int']>;
  actionId?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['Int']>;
  workflowId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type TrupanionAvailability = {
  __typename?: 'TrupanionAvailability';
  clinicPetId: Scalars['String'];
  clinicPetName?: Maybe<Scalars['String']>;
  trupanionClosestBreed?: Maybe<Scalars['String']>;
  pimsBreed?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<UnavailableReasons>;
  errorMessages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TrupanionCertificateAvailability = {
  __typename?: 'TrupanionCertificateAvailability';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  clinicPetId: Scalars['String'];
  requestBody: Scalars['Json'];
  responseStatusCode: Scalars['Int'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
  clinicPet: ClinicPet;
};

export type TrupanionCertificateAvailabilityAvgAggregateOutputType = {
  __typename?: 'TrupanionCertificateAvailabilityAvgAggregateOutputType';
  responseStatusCode?: Maybe<Scalars['Float']>;
};

export type TrupanionCertificateAvailabilityAvgOrderByAggregateInput = {
  responseStatusCode?: Maybe<SortOrder>;
};

export type TrupanionCertificateAvailabilityCountAggregateOutputType = {
  __typename?: 'TrupanionCertificateAvailabilityCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  requestBody: Scalars['Int'];
  responseStatusCode: Scalars['Int'];
  isAvailable: Scalars['Int'];
  unavailableReasons: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TrupanionCertificateAvailabilityCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  responseStatusCode?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  unavailableReasons?: Maybe<SortOrder>;
};

export type TrupanionCertificateAvailabilityCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  requestBody: Scalars['Json'];
  responseStatusCode: Scalars['Int'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
  clinicPet: ClinicPetCreateNestedOneWithoutTrupanionCertificateAvailabilitiesInput;
};

export type TrupanionCertificateAvailabilityCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  requestBody: Scalars['Json'];
  responseStatusCode: Scalars['Int'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityCreateManyClinicPetInputEnvelope = {
  data: Array<TrupanionCertificateAvailabilityCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TrupanionCertificateAvailabilityCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicPetId: Scalars['String'];
  requestBody: Scalars['Json'];
  responseStatusCode: Scalars['Int'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<TrupanionCertificateAvailabilityCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<TrupanionCertificateAvailabilityCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<TrupanionCertificateAvailabilityCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
};

export type TrupanionCertificateAvailabilityCreateOrConnectWithoutClinicPetInput = {
  where: TrupanionCertificateAvailabilityWhereUniqueInput;
  create: TrupanionCertificateAvailabilityUncheckedCreateWithoutClinicPetInput;
};

export type TrupanionCertificateAvailabilityCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  requestBody: Scalars['Json'];
  responseStatusCode: Scalars['Int'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityInput = {
  clinicPetParentId: Scalars['String'];
};

export type TrupanionCertificateAvailabilityListRelationFilter = {
  every?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
  some?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
  none?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
};

export type TrupanionCertificateAvailabilityMaxAggregateOutputType = {
  __typename?: 'TrupanionCertificateAvailabilityMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type TrupanionCertificateAvailabilityMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  responseStatusCode?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
};

export type TrupanionCertificateAvailabilityMinAggregateOutputType = {
  __typename?: 'TrupanionCertificateAvailabilityMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type TrupanionCertificateAvailabilityMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  responseStatusCode?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
};

export type TrupanionCertificateAvailabilityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum TrupanionCertificateAvailabilityOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicPetId = 'clinicPetId'
}

export type TrupanionCertificateAvailabilityOrderByRelevanceInput = {
  fields: Array<TrupanionCertificateAvailabilityOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type TrupanionCertificateAvailabilityOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  responseStatusCode?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  unavailableReasons?: Maybe<SortOrder>;
  _count?: Maybe<TrupanionCertificateAvailabilityCountOrderByAggregateInput>;
  _avg?: Maybe<TrupanionCertificateAvailabilityAvgOrderByAggregateInput>;
  _max?: Maybe<TrupanionCertificateAvailabilityMaxOrderByAggregateInput>;
  _min?: Maybe<TrupanionCertificateAvailabilityMinOrderByAggregateInput>;
  _sum?: Maybe<TrupanionCertificateAvailabilitySumOrderByAggregateInput>;
};

export type TrupanionCertificateAvailabilityOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  responseStatusCode?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  unavailableReasons?: Maybe<SortOrder>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<TrupanionCertificateAvailabilityOrderByRelevanceInput>;
};

export enum TrupanionCertificateAvailabilityScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  ClinicPetId = 'clinicPetId',
  RequestBody = 'requestBody',
  ResponseStatusCode = 'responseStatusCode',
  IsAvailable = 'isAvailable',
  UnavailableReasons = 'unavailableReasons'
}

export type TrupanionCertificateAvailabilityScalarWhereInput = {
  AND?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereInput>>;
  OR?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereInput>>;
  NOT?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  clinicPetId?: Maybe<StringFilter>;
  requestBody?: Maybe<JsonFilter>;
  responseStatusCode?: Maybe<IntFilter>;
  isAvailable?: Maybe<BoolNullableFilter>;
  unavailableReasons?: Maybe<JsonNullableFilter>;
};

export type TrupanionCertificateAvailabilityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
  requestBody?: Maybe<JsonWithAggregatesFilter>;
  responseStatusCode?: Maybe<IntWithAggregatesFilter>;
  isAvailable?: Maybe<BoolNullableWithAggregatesFilter>;
  unavailableReasons?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type TrupanionCertificateAvailabilitySumAggregateOutputType = {
  __typename?: 'TrupanionCertificateAvailabilitySumAggregateOutputType';
  responseStatusCode?: Maybe<Scalars['Int']>;
};

export type TrupanionCertificateAvailabilitySumOrderByAggregateInput = {
  responseStatusCode?: Maybe<SortOrder>;
};

export type TrupanionCertificateAvailabilityUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicPetId: Scalars['String'];
  requestBody: Scalars['Json'];
  responseStatusCode: Scalars['Int'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<TrupanionCertificateAvailabilityCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<TrupanionCertificateAvailabilityCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<TrupanionCertificateAvailabilityCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
};

export type TrupanionCertificateAvailabilityUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  requestBody: Scalars['Json'];
  responseStatusCode: Scalars['Int'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<TrupanionCertificateAvailabilityCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<TrupanionCertificateAvailabilityCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<TrupanionCertificateAvailabilityUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<TrupanionCertificateAvailabilityCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<TrupanionCertificateAvailabilityUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<TrupanionCertificateAvailabilityUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereInput>>;
};

export type TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutTrupanionCertificateAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  requestBody?: Maybe<Scalars['Json']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  requestBody?: Maybe<Scalars['Json']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  requestBody?: Maybe<Scalars['Json']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutTrupanionCertificateAvailabilitiesNestedInput>;
};

export type TrupanionCertificateAvailabilityUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  requestBody?: Maybe<Scalars['Json']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUpdateManyWithWhereWithoutClinicPetInput = {
  where: TrupanionCertificateAvailabilityScalarWhereInput;
  data: TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutTrupanionCertificateAvailabilitiesInput;
};

export type TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<TrupanionCertificateAvailabilityCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<TrupanionCertificateAvailabilityCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<TrupanionCertificateAvailabilityUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<TrupanionCertificateAvailabilityCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<TrupanionCertificateAvailabilityUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<TrupanionCertificateAvailabilityUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereInput>>;
};

export type TrupanionCertificateAvailabilityUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: TrupanionCertificateAvailabilityWhereUniqueInput;
  data: TrupanionCertificateAvailabilityUncheckedUpdateWithoutClinicPetInput;
};

export type TrupanionCertificateAvailabilityUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  requestBody?: Maybe<Scalars['Json']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: TrupanionCertificateAvailabilityWhereUniqueInput;
  update: TrupanionCertificateAvailabilityUncheckedUpdateWithoutClinicPetInput;
  create: TrupanionCertificateAvailabilityUncheckedCreateWithoutClinicPetInput;
};

export type TrupanionCertificateAvailabilityWhereInput = {
  AND?: Maybe<Array<TrupanionCertificateAvailabilityWhereInput>>;
  OR?: Maybe<Array<TrupanionCertificateAvailabilityWhereInput>>;
  NOT?: Maybe<Array<TrupanionCertificateAvailabilityWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  clinicPetId?: Maybe<StringFilter>;
  requestBody?: Maybe<JsonFilter>;
  responseStatusCode?: Maybe<IntFilter>;
  isAvailable?: Maybe<BoolNullableFilter>;
  unavailableReasons?: Maybe<JsonNullableFilter>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
};

export type TrupanionCertificateAvailabilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type TrupanionHospital = {
  __typename?: 'TrupanionHospital';
  Id: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Distance?: Maybe<Scalars['Float']>;
  LegacyId?: Maybe<Scalars['String']>;
  VetDirectPayAccepted?: Maybe<Scalars['Boolean']>;
  IsCertificateOptedIn?: Maybe<Scalars['Boolean']>;
  PhoneNumber?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Lon?: Maybe<Scalars['Float']>;
  Address1?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['String']>;
  IsoAlpha3CountryCode?: Maybe<Scalars['String']>;
};

export type TrupanionHospitalSearchInput = {
  clinicId: Scalars['String'];
  radius?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type TrupanionIntegratedAppointmentType = {
  __typename?: 'TrupanionIntegratedAppointmentType';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  trupanionIntegrationId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  trupanionIntegration: TrupanionIntegration;
  appointmentType: AppointmentType;
};

export type TrupanionIntegratedAppointmentTypeCountAggregateOutputType = {
  __typename?: 'TrupanionIntegratedAppointmentTypeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  trupanionIntegrationId: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TrupanionIntegratedAppointmentTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
};

export type TrupanionIntegratedAppointmentTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutTrupanionIntegratedAppointmentTypesInput;
  appointmentType: AppointmentTypeCreateNestedOneWithoutTrupanionIntegratedAppointmentTypeInput;
};

export type TrupanionIntegratedAppointmentTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
};

export type TrupanionIntegratedAppointmentTypeCreateManyTrupanionIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
};

export type TrupanionIntegratedAppointmentTypeCreateManyTrupanionIntegrationInputEnvelope = {
  data: Array<TrupanionIntegratedAppointmentTypeCreateManyTrupanionIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TrupanionIntegratedAppointmentTypeCreateNestedManyWithoutTrupanionIntegrationInput = {
  create?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateWithoutTrupanionIntegrationInput>>;
  connectOrCreate?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<TrupanionIntegratedAppointmentTypeCreateManyTrupanionIntegrationInputEnvelope>;
  connect?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
};

export type TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput = {
  create?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutAppointmentTypeInput>;
  connectOrCreate?: Maybe<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutAppointmentTypeInput>;
  connect?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
};

export type TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutAppointmentTypeInput = {
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
  create: TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutAppointmentTypeInput;
};

export type TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutTrupanionIntegrationInput = {
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
  create: TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutTrupanionIntegrationInput;
};

export type TrupanionIntegratedAppointmentTypeCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutTrupanionIntegratedAppointmentTypesInput;
};

export type TrupanionIntegratedAppointmentTypeCreateWithoutTrupanionIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentType: AppointmentTypeCreateNestedOneWithoutTrupanionIntegratedAppointmentTypeInput;
};

export type TrupanionIntegratedAppointmentTypeListRelationFilter = {
  every?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
  some?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
  none?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
};

export type TrupanionIntegratedAppointmentTypeMaxAggregateOutputType = {
  __typename?: 'TrupanionIntegratedAppointmentTypeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type TrupanionIntegratedAppointmentTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
};

export type TrupanionIntegratedAppointmentTypeMinAggregateOutputType = {
  __typename?: 'TrupanionIntegratedAppointmentTypeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type TrupanionIntegratedAppointmentTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
};

export type TrupanionIntegratedAppointmentTypeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum TrupanionIntegratedAppointmentTypeOrderByRelevanceFieldEnum {
  Id = 'id',
  TrupanionIntegrationId = 'trupanionIntegrationId',
  AppointmentTypeId = 'appointmentTypeId'
}

export type TrupanionIntegratedAppointmentTypeOrderByRelevanceInput = {
  fields: Array<TrupanionIntegratedAppointmentTypeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type TrupanionIntegratedAppointmentTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  _count?: Maybe<TrupanionIntegratedAppointmentTypeCountOrderByAggregateInput>;
  _max?: Maybe<TrupanionIntegratedAppointmentTypeMaxOrderByAggregateInput>;
  _min?: Maybe<TrupanionIntegratedAppointmentTypeMinOrderByAggregateInput>;
};

export type TrupanionIntegratedAppointmentTypeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  trupanionIntegration?: Maybe<TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<TrupanionIntegratedAppointmentTypeOrderByRelevanceInput>;
};

export type TrupanionIntegratedAppointmentTypeRelationFilter = {
  is?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
  isNot?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
};

export enum TrupanionIntegratedAppointmentTypeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  TrupanionIntegrationId = 'trupanionIntegrationId',
  AppointmentTypeId = 'appointmentTypeId'
}

export type TrupanionIntegratedAppointmentTypeScalarWhereInput = {
  AND?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereInput>>;
  OR?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereInput>>;
  NOT?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  trupanionIntegrationId?: Maybe<StringFilter>;
  appointmentTypeId?: Maybe<StringFilter>;
};

export type TrupanionIntegratedAppointmentTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  trupanionIntegrationId?: Maybe<StringWithAggregatesFilter>;
  appointmentTypeId?: Maybe<StringWithAggregatesFilter>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
};

export type TrupanionIntegratedAppointmentTypeUncheckedCreateNestedManyWithoutTrupanionIntegrationInput = {
  create?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateWithoutTrupanionIntegrationInput>>;
  connectOrCreate?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<TrupanionIntegratedAppointmentTypeCreateManyTrupanionIntegrationInputEnvelope>;
  connect?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput = {
  create?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutAppointmentTypeInput>;
  connectOrCreate?: Maybe<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutAppointmentTypeInput>;
  connect?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId: Scalars['String'];
};

export type TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutTrupanionIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
};

export type TrupanionIntegratedAppointmentTypeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedUpdateManyWithoutTrupanionIntegratedAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput = {
  create?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateWithoutTrupanionIntegrationInput>>;
  connectOrCreate?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutTrupanionIntegrationInput>>;
  upsert?: Maybe<Array<TrupanionIntegratedAppointmentTypeUpsertWithWhereUniqueWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<TrupanionIntegratedAppointmentTypeCreateManyTrupanionIntegrationInputEnvelope>;
  set?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<TrupanionIntegratedAppointmentTypeUpdateWithWhereUniqueWithoutTrupanionIntegrationInput>>;
  updateMany?: Maybe<Array<TrupanionIntegratedAppointmentTypeUpdateManyWithWhereWithoutTrupanionIntegrationInput>>;
  deleteMany?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereInput>>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput = {
  create?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutAppointmentTypeInput>;
  connectOrCreate?: Maybe<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutAppointmentTypeInput>;
  upsert?: Maybe<TrupanionIntegratedAppointmentTypeUpsertWithoutAppointmentTypeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  update?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateWithoutAppointmentTypeInput>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedUpdateWithoutTrupanionIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type TrupanionIntegratedAppointmentTypeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutTrupanionIntegratedAppointmentTypesNestedInput>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutTrupanionIntegratedAppointmentTypeNestedInput>;
};

export type TrupanionIntegratedAppointmentTypeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeUpdateManyWithWhereWithoutTrupanionIntegrationInput = {
  where: TrupanionIntegratedAppointmentTypeScalarWhereInput;
  data: TrupanionIntegratedAppointmentTypeUncheckedUpdateManyWithoutTrupanionIntegratedAppointmentTypesInput;
};

export type TrupanionIntegratedAppointmentTypeUpdateManyWithoutTrupanionIntegrationNestedInput = {
  create?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateWithoutTrupanionIntegrationInput>>;
  connectOrCreate?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutTrupanionIntegrationInput>>;
  upsert?: Maybe<Array<TrupanionIntegratedAppointmentTypeUpsertWithWhereUniqueWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<TrupanionIntegratedAppointmentTypeCreateManyTrupanionIntegrationInputEnvelope>;
  set?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<TrupanionIntegratedAppointmentTypeUpdateWithWhereUniqueWithoutTrupanionIntegrationInput>>;
  updateMany?: Maybe<Array<TrupanionIntegratedAppointmentTypeUpdateManyWithWhereWithoutTrupanionIntegrationInput>>;
  deleteMany?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereInput>>;
};

export type TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput = {
  create?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutAppointmentTypeInput>;
  connectOrCreate?: Maybe<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutAppointmentTypeInput>;
  upsert?: Maybe<TrupanionIntegratedAppointmentTypeUpsertWithoutAppointmentTypeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  update?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateWithoutAppointmentTypeInput>;
};

export type TrupanionIntegratedAppointmentTypeUpdateWithWhereUniqueWithoutTrupanionIntegrationInput = {
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
  data: TrupanionIntegratedAppointmentTypeUncheckedUpdateWithoutTrupanionIntegrationInput;
};

export type TrupanionIntegratedAppointmentTypeUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutTrupanionIntegratedAppointmentTypesNestedInput>;
};

export type TrupanionIntegratedAppointmentTypeUpdateWithoutTrupanionIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutTrupanionIntegratedAppointmentTypeNestedInput>;
};

export type TrupanionIntegratedAppointmentTypeUpsertWithWhereUniqueWithoutTrupanionIntegrationInput = {
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
  update: TrupanionIntegratedAppointmentTypeUncheckedUpdateWithoutTrupanionIntegrationInput;
  create: TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutTrupanionIntegrationInput;
};

export type TrupanionIntegratedAppointmentTypeUpsertWithoutAppointmentTypeInput = {
  update: TrupanionIntegratedAppointmentTypeUncheckedUpdateWithoutAppointmentTypeInput;
  create: TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutAppointmentTypeInput;
};

export type TrupanionIntegratedAppointmentTypeWhereInput = {
  AND?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereInput>>;
  OR?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereInput>>;
  NOT?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  trupanionIntegrationId?: Maybe<StringFilter>;
  appointmentTypeId?: Maybe<StringFilter>;
  trupanionIntegration?: Maybe<TrupanionIntegrationWhereInput>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
};

export type TrupanionIntegratedAppointmentTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type TrupanionIntegration = {
  __typename?: 'TrupanionIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  insuranceIntegrationId: Scalars['String'];
  hospitalId?: Maybe<Scalars['String']>;
  insuranceIntegration: InsuranceIntegration;
  trupanionIntegratedAppointmentTypes: Array<TrupanionIntegratedAppointmentType>;
  sentTrupanionExamDayOffers: Array<SentTrupanionExamDayOffer>;
  _count: TrupanionIntegrationCountOutputType;
};


export type TrupanionIntegrationTrupanionIntegratedAppointmentTypesArgs = {
  where?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
  orderBy?: Maybe<TrupanionIntegratedAppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<TrupanionIntegratedAppointmentTypeScalarFieldEnum>;
};


export type TrupanionIntegrationSentTrupanionExamDayOffersArgs = {
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
  orderBy?: Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SentTrupanionExamDayOfferScalarFieldEnum>;
};

export type TrupanionIntegrationCountAggregateOutputType = {
  __typename?: 'TrupanionIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  insuranceIntegrationId: Scalars['Int'];
  hospitalId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TrupanionIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  insuranceIntegrationId?: Maybe<SortOrder>;
  hospitalId?: Maybe<SortOrder>;
};

export type TrupanionIntegrationCountOutputType = {
  __typename?: 'TrupanionIntegrationCountOutputType';
  trupanionIntegratedAppointmentTypes: Scalars['Int'];
  sentTrupanionExamDayOffers: Scalars['Int'];
};

export type TrupanionIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  insuranceIntegration: InsuranceIntegrationCreateNestedOneWithoutTrupanionIntegrationInput;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedManyWithoutTrupanionIntegrationInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutTrupanionIntegrationInput>;
};

export type TrupanionIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  insuranceIntegrationId: Scalars['String'];
  hospitalId?: Maybe<Scalars['String']>;
};

export type TrupanionIntegrationCreateNestedOneWithoutInsuranceIntegrationInput = {
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutInsuranceIntegrationInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutInsuranceIntegrationInput>;
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
};

export type TrupanionIntegrationCreateNestedOneWithoutSentTrupanionExamDayOffersInput = {
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutSentTrupanionExamDayOffersInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutSentTrupanionExamDayOffersInput>;
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
};

export type TrupanionIntegrationCreateNestedOneWithoutTrupanionIntegratedAppointmentTypesInput = {
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutTrupanionIntegratedAppointmentTypesInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutTrupanionIntegratedAppointmentTypesInput>;
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
};

export type TrupanionIntegrationCreateOrConnectWithoutInsuranceIntegrationInput = {
  where: TrupanionIntegrationWhereUniqueInput;
  create: TrupanionIntegrationUncheckedCreateWithoutInsuranceIntegrationInput;
};

export type TrupanionIntegrationCreateOrConnectWithoutSentTrupanionExamDayOffersInput = {
  where: TrupanionIntegrationWhereUniqueInput;
  create: TrupanionIntegrationUncheckedCreateWithoutSentTrupanionExamDayOffersInput;
};

export type TrupanionIntegrationCreateOrConnectWithoutTrupanionIntegratedAppointmentTypesInput = {
  where: TrupanionIntegrationWhereUniqueInput;
  create: TrupanionIntegrationUncheckedCreateWithoutTrupanionIntegratedAppointmentTypesInput;
};

export type TrupanionIntegrationCreateWithoutInsuranceIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedManyWithoutTrupanionIntegrationInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutTrupanionIntegrationInput>;
};

export type TrupanionIntegrationCreateWithoutSentTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  insuranceIntegration: InsuranceIntegrationCreateNestedOneWithoutTrupanionIntegrationInput;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedManyWithoutTrupanionIntegrationInput>;
};

export type TrupanionIntegrationCreateWithoutTrupanionIntegratedAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  insuranceIntegration: InsuranceIntegrationCreateNestedOneWithoutTrupanionIntegrationInput;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutTrupanionIntegrationInput>;
};

export type TrupanionIntegrationMaxAggregateOutputType = {
  __typename?: 'TrupanionIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  insuranceIntegrationId?: Maybe<Scalars['String']>;
  hospitalId?: Maybe<Scalars['String']>;
};

export type TrupanionIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  insuranceIntegrationId?: Maybe<SortOrder>;
  hospitalId?: Maybe<SortOrder>;
};

export type TrupanionIntegrationMinAggregateOutputType = {
  __typename?: 'TrupanionIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  insuranceIntegrationId?: Maybe<Scalars['String']>;
  hospitalId?: Maybe<Scalars['String']>;
};

export type TrupanionIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  insuranceIntegrationId?: Maybe<SortOrder>;
  hospitalId?: Maybe<SortOrder>;
};

export enum TrupanionIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  InsuranceIntegrationId = 'insuranceIntegrationId',
  HospitalId = 'hospitalId'
}

export type TrupanionIntegrationOrderByRelevanceInput = {
  fields: Array<TrupanionIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type TrupanionIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  insuranceIntegrationId?: Maybe<SortOrder>;
  hospitalId?: Maybe<SortOrder>;
  _count?: Maybe<TrupanionIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<TrupanionIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<TrupanionIntegrationMinOrderByAggregateInput>;
};

export type TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  insuranceIntegrationId?: Maybe<SortOrder>;
  hospitalId?: Maybe<SortOrder>;
  insuranceIntegration?: Maybe<InsuranceIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeOrderByRelationAggregateInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferOrderByRelationAggregateInput>;
  _relevance?: Maybe<TrupanionIntegrationOrderByRelevanceInput>;
};

export type TrupanionIntegrationRelationFilter = {
  is?: Maybe<TrupanionIntegrationWhereInput>;
  isNot?: Maybe<TrupanionIntegrationWhereInput>;
};

export enum TrupanionIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InsuranceIntegrationId = 'insuranceIntegrationId',
  HospitalId = 'hospitalId'
}

export type TrupanionIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TrupanionIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TrupanionIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TrupanionIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  insuranceIntegrationId?: Maybe<StringWithAggregatesFilter>;
  hospitalId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type TrupanionIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  insuranceIntegrationId: Scalars['String'];
  hospitalId?: Maybe<Scalars['String']>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedManyWithoutTrupanionIntegrationInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutTrupanionIntegrationInput>;
};

export type TrupanionIntegrationUncheckedCreateNestedOneWithoutInsuranceIntegrationInput = {
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutInsuranceIntegrationInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutInsuranceIntegrationInput>;
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
};

export type TrupanionIntegrationUncheckedCreateWithoutInsuranceIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedManyWithoutTrupanionIntegrationInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutTrupanionIntegrationInput>;
};

export type TrupanionIntegrationUncheckedCreateWithoutSentTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  insuranceIntegrationId: Scalars['String'];
  hospitalId?: Maybe<Scalars['String']>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedManyWithoutTrupanionIntegrationInput>;
};

export type TrupanionIntegrationUncheckedCreateWithoutTrupanionIntegratedAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  insuranceIntegrationId: Scalars['String'];
  hospitalId?: Maybe<Scalars['String']>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutTrupanionIntegrationInput>;
};

export type TrupanionIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  insuranceIntegrationId?: Maybe<Scalars['String']>;
  hospitalId?: Maybe<Scalars['String']>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput>;
};

export type TrupanionIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  insuranceIntegrationId?: Maybe<Scalars['String']>;
  hospitalId?: Maybe<Scalars['String']>;
};

export type TrupanionIntegrationUncheckedUpdateOneWithoutInsuranceIntegrationNestedInput = {
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutInsuranceIntegrationInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutInsuranceIntegrationInput>;
  upsert?: Maybe<TrupanionIntegrationUpsertWithoutInsuranceIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  update?: Maybe<TrupanionIntegrationUncheckedUpdateWithoutInsuranceIntegrationInput>;
};

export type TrupanionIntegrationUncheckedUpdateWithoutInsuranceIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput>;
};

export type TrupanionIntegrationUncheckedUpdateWithoutSentTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  insuranceIntegrationId?: Maybe<Scalars['String']>;
  hospitalId?: Maybe<Scalars['String']>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput>;
};

export type TrupanionIntegrationUncheckedUpdateWithoutTrupanionIntegratedAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  insuranceIntegrationId?: Maybe<Scalars['String']>;
  hospitalId?: Maybe<Scalars['String']>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput>;
};

export type TrupanionIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  insuranceIntegration?: Maybe<InsuranceIntegrationUpdateOneRequiredWithoutTrupanionIntegrationNestedInput>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUpdateManyWithoutTrupanionIntegrationNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutTrupanionIntegrationNestedInput>;
};

export type TrupanionIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
};

export type TrupanionIntegrationUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput = {
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutSentTrupanionExamDayOffersInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutSentTrupanionExamDayOffersInput>;
  upsert?: Maybe<TrupanionIntegrationUpsertWithoutSentTrupanionExamDayOffersInput>;
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  update?: Maybe<TrupanionIntegrationUncheckedUpdateWithoutSentTrupanionExamDayOffersInput>;
};

export type TrupanionIntegrationUpdateOneRequiredWithoutTrupanionIntegratedAppointmentTypesNestedInput = {
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutTrupanionIntegratedAppointmentTypesInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutTrupanionIntegratedAppointmentTypesInput>;
  upsert?: Maybe<TrupanionIntegrationUpsertWithoutTrupanionIntegratedAppointmentTypesInput>;
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  update?: Maybe<TrupanionIntegrationUncheckedUpdateWithoutTrupanionIntegratedAppointmentTypesInput>;
};

export type TrupanionIntegrationUpdateOneWithoutInsuranceIntegrationNestedInput = {
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutInsuranceIntegrationInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutInsuranceIntegrationInput>;
  upsert?: Maybe<TrupanionIntegrationUpsertWithoutInsuranceIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  update?: Maybe<TrupanionIntegrationUncheckedUpdateWithoutInsuranceIntegrationInput>;
};

export type TrupanionIntegrationUpdateWithoutInsuranceIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUpdateManyWithoutTrupanionIntegrationNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutTrupanionIntegrationNestedInput>;
};

export type TrupanionIntegrationUpdateWithoutSentTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  insuranceIntegration?: Maybe<InsuranceIntegrationUpdateOneRequiredWithoutTrupanionIntegrationNestedInput>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUpdateManyWithoutTrupanionIntegrationNestedInput>;
};

export type TrupanionIntegrationUpdateWithoutTrupanionIntegratedAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  insuranceIntegration?: Maybe<InsuranceIntegrationUpdateOneRequiredWithoutTrupanionIntegrationNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutTrupanionIntegrationNestedInput>;
};

export type TrupanionIntegrationUpsertWithoutInsuranceIntegrationInput = {
  update: TrupanionIntegrationUncheckedUpdateWithoutInsuranceIntegrationInput;
  create: TrupanionIntegrationUncheckedCreateWithoutInsuranceIntegrationInput;
};

export type TrupanionIntegrationUpsertWithoutSentTrupanionExamDayOffersInput = {
  update: TrupanionIntegrationUncheckedUpdateWithoutSentTrupanionExamDayOffersInput;
  create: TrupanionIntegrationUncheckedCreateWithoutSentTrupanionExamDayOffersInput;
};

export type TrupanionIntegrationUpsertWithoutTrupanionIntegratedAppointmentTypesInput = {
  update: TrupanionIntegrationUncheckedUpdateWithoutTrupanionIntegratedAppointmentTypesInput;
  create: TrupanionIntegrationUncheckedCreateWithoutTrupanionIntegratedAppointmentTypesInput;
};

export type TrupanionIntegrationWhereInput = {
  AND?: Maybe<Array<TrupanionIntegrationWhereInput>>;
  OR?: Maybe<Array<TrupanionIntegrationWhereInput>>;
  NOT?: Maybe<Array<TrupanionIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  insuranceIntegrationId?: Maybe<StringFilter>;
  hospitalId?: Maybe<StringNullableFilter>;
  insuranceIntegration?: Maybe<InsuranceIntegrationWhereInput>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeListRelationFilter>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferListRelationFilter>;
};

export type TrupanionIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  insuranceIntegrationId?: Maybe<Scalars['String']>;
};

export type TrupanionIssueCertificateDataInput = {
  isSmsOptedIn?: Maybe<Scalars['Boolean']>;
  trupanionBreed?: Maybe<Scalars['String']>;
};

export type TrupanionIssueCertificateResponse = {
  __typename?: 'TrupanionIssueCertificateResponse';
  referenceNumber?: Maybe<Scalars['String']>;
  errorMessages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TrupanionIssueCertificateWhereInput = {
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type TwilioCallRecording = {
  __typename?: 'TwilioCallRecording';
  sid: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: TwilioRecordingStatus;
  uri: Scalars['String'];
  errorCode?: Maybe<Scalars['Int']>;
  callRecordingId: Scalars['String'];
  callRecording: CallRecording;
};

export type TwilioCallRecordingAvgAggregateOutputType = {
  __typename?: 'TwilioCallRecordingAvgAggregateOutputType';
  errorCode?: Maybe<Scalars['Float']>;
};

export type TwilioCallRecordingAvgOrderByAggregateInput = {
  errorCode?: Maybe<SortOrder>;
};

export type TwilioCallRecordingCountAggregateOutputType = {
  __typename?: 'TwilioCallRecordingCountAggregateOutputType';
  sid: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  status: Scalars['Int'];
  uri: Scalars['Int'];
  errorCode: Scalars['Int'];
  callRecordingId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TwilioCallRecordingCountOrderByAggregateInput = {
  sid?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  callRecordingId?: Maybe<SortOrder>;
};

export type TwilioCallRecordingCreateInput = {
  sid: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri: Scalars['String'];
  errorCode?: Maybe<Scalars['Int']>;
  callRecording: CallRecordingCreateNestedOneWithoutTwilioCallRecordingInput;
};

export type TwilioCallRecordingCreateManyInput = {
  sid: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri: Scalars['String'];
  errorCode?: Maybe<Scalars['Int']>;
  callRecordingId: Scalars['String'];
};

export type TwilioCallRecordingCreateNestedOneWithoutCallRecordingInput = {
  create?: Maybe<TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput>;
  connectOrCreate?: Maybe<TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput>;
  connect?: Maybe<TwilioCallRecordingWhereUniqueInput>;
};

export type TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput = {
  where: TwilioCallRecordingWhereUniqueInput;
  create: TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput;
};

export type TwilioCallRecordingCreateWithoutCallRecordingInput = {
  sid: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri: Scalars['String'];
  errorCode?: Maybe<Scalars['Int']>;
};

export type TwilioCallRecordingMaxAggregateOutputType = {
  __typename?: 'TwilioCallRecordingMaxAggregateOutputType';
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
  callRecordingId?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingMaxOrderByAggregateInput = {
  sid?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  callRecordingId?: Maybe<SortOrder>;
};

export type TwilioCallRecordingMinAggregateOutputType = {
  __typename?: 'TwilioCallRecordingMinAggregateOutputType';
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
  callRecordingId?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingMinOrderByAggregateInput = {
  sid?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  callRecordingId?: Maybe<SortOrder>;
};

export enum TwilioCallRecordingOrderByRelevanceFieldEnum {
  Sid = 'sid',
  Uri = 'uri',
  CallRecordingId = 'callRecordingId'
}

export type TwilioCallRecordingOrderByRelevanceInput = {
  fields: Array<TwilioCallRecordingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type TwilioCallRecordingOrderByWithAggregationInput = {
  sid?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  callRecordingId?: Maybe<SortOrder>;
  _count?: Maybe<TwilioCallRecordingCountOrderByAggregateInput>;
  _avg?: Maybe<TwilioCallRecordingAvgOrderByAggregateInput>;
  _max?: Maybe<TwilioCallRecordingMaxOrderByAggregateInput>;
  _min?: Maybe<TwilioCallRecordingMinOrderByAggregateInput>;
  _sum?: Maybe<TwilioCallRecordingSumOrderByAggregateInput>;
};

export type TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput = {
  sid?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  callRecordingId?: Maybe<SortOrder>;
  callRecording?: Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<TwilioCallRecordingOrderByRelevanceInput>;
};

export type TwilioCallRecordingRelationFilter = {
  is?: Maybe<TwilioCallRecordingWhereInput>;
  isNot?: Maybe<TwilioCallRecordingWhereInput>;
};

export enum TwilioCallRecordingScalarFieldEnum {
  Sid = 'sid',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  Uri = 'uri',
  ErrorCode = 'errorCode',
  CallRecordingId = 'callRecordingId'
}

export type TwilioCallRecordingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TwilioCallRecordingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TwilioCallRecordingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TwilioCallRecordingScalarWhereWithAggregatesInput>>;
  sid?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumTwilioRecordingStatusWithAggregatesFilter>;
  uri?: Maybe<StringWithAggregatesFilter>;
  errorCode?: Maybe<IntNullableWithAggregatesFilter>;
  callRecordingId?: Maybe<StringWithAggregatesFilter>;
};

export type TwilioCallRecordingSubscription = {
  __typename?: 'TwilioCallRecordingSubscription';
  node: TwilioCallRecording;
  mutation: MutationType;
};

export type TwilioCallRecordingSumAggregateOutputType = {
  __typename?: 'TwilioCallRecordingSumAggregateOutputType';
  errorCode?: Maybe<Scalars['Int']>;
};

export type TwilioCallRecordingSumOrderByAggregateInput = {
  errorCode?: Maybe<SortOrder>;
};

export type TwilioCallRecordingUncheckedCreateInput = {
  sid: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri: Scalars['String'];
  errorCode?: Maybe<Scalars['Int']>;
  callRecordingId: Scalars['String'];
};

export type TwilioCallRecordingUncheckedCreateNestedOneWithoutCallRecordingInput = {
  create?: Maybe<TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput>;
  connectOrCreate?: Maybe<TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput>;
  connect?: Maybe<TwilioCallRecordingWhereUniqueInput>;
};

export type TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput = {
  sid: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri: Scalars['String'];
  errorCode?: Maybe<Scalars['Int']>;
};

export type TwilioCallRecordingUncheckedUpdateInput = {
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
  callRecordingId?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingUncheckedUpdateManyInput = {
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
  callRecordingId?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingUncheckedUpdateOneWithoutCallRecordingNestedInput = {
  create?: Maybe<TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput>;
  connectOrCreate?: Maybe<TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput>;
  upsert?: Maybe<TwilioCallRecordingUpsertWithoutCallRecordingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  update?: Maybe<TwilioCallRecordingUncheckedUpdateWithoutCallRecordingInput>;
};

export type TwilioCallRecordingUncheckedUpdateWithoutCallRecordingInput = {
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
};

export type TwilioCallRecordingUpdateInput = {
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
  callRecording?: Maybe<CallRecordingUpdateOneRequiredWithoutTwilioCallRecordingNestedInput>;
};

export type TwilioCallRecordingUpdateManyMutationInput = {
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
};

export type TwilioCallRecordingUpdateOneWithoutCallRecordingNestedInput = {
  create?: Maybe<TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput>;
  connectOrCreate?: Maybe<TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput>;
  upsert?: Maybe<TwilioCallRecordingUpsertWithoutCallRecordingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  update?: Maybe<TwilioCallRecordingUncheckedUpdateWithoutCallRecordingInput>;
};

export type TwilioCallRecordingUpdateWithoutCallRecordingInput = {
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
};

export type TwilioCallRecordingUpsertWithoutCallRecordingInput = {
  update: TwilioCallRecordingUncheckedUpdateWithoutCallRecordingInput;
  create: TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput;
};

export type TwilioCallRecordingWhereInput = {
  AND?: Maybe<Array<TwilioCallRecordingWhereInput>>;
  OR?: Maybe<Array<TwilioCallRecordingWhereInput>>;
  NOT?: Maybe<Array<TwilioCallRecordingWhereInput>>;
  sid?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumTwilioRecordingStatusFilter>;
  uri?: Maybe<StringFilter>;
  errorCode?: Maybe<IntNullableFilter>;
  callRecordingId?: Maybe<StringFilter>;
  callRecording?: Maybe<CallRecordingWhereInput>;
};

export type TwilioCallRecordingWhereUniqueInput = {
  sid?: Maybe<Scalars['String']>;
  callRecordingId?: Maybe<Scalars['String']>;
};

export type TwilioCallerIdVerification = {
  __typename?: 'TwilioCallerIdVerification';
  callSid: Scalars['String'];
  validationCode: Scalars['String'];
};

export type TwilioCapabilityToken = {
  __typename?: 'TwilioCapabilityToken';
  token: Scalars['String'];
};

export enum TwilioCapabilityType {
  Phone = 'Phone',
  Video = 'Video'
}

export type TwilioConfiguration = {
  __typename?: 'TwilioConfiguration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  clinic: Clinic;
};

export type TwilioConfigurationCountAggregateOutputType = {
  __typename?: 'TwilioConfigurationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  subaccountSid: Scalars['Int'];
  messagingServiceSid: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TwilioConfigurationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
};

export type TwilioConfigurationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutTwilioConfigurationInput;
};

export type TwilioConfigurationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationCreateNestedOneWithoutClinicInput = {
  create?: Maybe<TwilioConfigurationUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<TwilioConfigurationCreateOrConnectWithoutClinicInput>;
  connect?: Maybe<TwilioConfigurationWhereUniqueInput>;
};

export type TwilioConfigurationCreateOrConnectWithoutClinicInput = {
  where: TwilioConfigurationWhereUniqueInput;
  create: TwilioConfigurationUncheckedCreateWithoutClinicInput;
};

export type TwilioConfigurationCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationMaxAggregateOutputType = {
  __typename?: 'TwilioConfigurationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
};

export type TwilioConfigurationMinAggregateOutputType = {
  __typename?: 'TwilioConfigurationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
};

export enum TwilioConfigurationOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  SubaccountSid = 'subaccountSid',
  MessagingServiceSid = 'messagingServiceSid'
}

export type TwilioConfigurationOrderByRelevanceInput = {
  fields: Array<TwilioConfigurationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type TwilioConfigurationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
  _count?: Maybe<TwilioConfigurationCountOrderByAggregateInput>;
  _max?: Maybe<TwilioConfigurationMaxOrderByAggregateInput>;
  _min?: Maybe<TwilioConfigurationMinOrderByAggregateInput>;
};

export type TwilioConfigurationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<TwilioConfigurationOrderByRelevanceInput>;
};

export type TwilioConfigurationRelationFilter = {
  is?: Maybe<TwilioConfigurationWhereInput>;
  isNot?: Maybe<TwilioConfigurationWhereInput>;
};

export enum TwilioConfigurationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  SubaccountSid = 'subaccountSid',
  MessagingServiceSid = 'messagingServiceSid'
}

export type TwilioConfigurationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TwilioConfigurationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TwilioConfigurationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TwilioConfigurationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  subaccountSid?: Maybe<StringNullableWithAggregatesFilter>;
  messagingServiceSid?: Maybe<StringNullableWithAggregatesFilter>;
};

export type TwilioConfigurationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput = {
  create?: Maybe<TwilioConfigurationUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<TwilioConfigurationCreateOrConnectWithoutClinicInput>;
  connect?: Maybe<TwilioConfigurationWhereUniqueInput>;
};

export type TwilioConfigurationUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput = {
  create?: Maybe<TwilioConfigurationUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<TwilioConfigurationCreateOrConnectWithoutClinicInput>;
  upsert?: Maybe<TwilioConfigurationUpsertWithoutClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TwilioConfigurationWhereUniqueInput>;
  update?: Maybe<TwilioConfigurationUncheckedUpdateWithoutClinicInput>;
};

export type TwilioConfigurationUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutTwilioConfigurationNestedInput>;
};

export type TwilioConfigurationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUpdateOneWithoutClinicNestedInput = {
  create?: Maybe<TwilioConfigurationUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<TwilioConfigurationCreateOrConnectWithoutClinicInput>;
  upsert?: Maybe<TwilioConfigurationUpsertWithoutClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TwilioConfigurationWhereUniqueInput>;
  update?: Maybe<TwilioConfigurationUncheckedUpdateWithoutClinicInput>;
};

export type TwilioConfigurationUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUpsertWithoutClinicInput = {
  update: TwilioConfigurationUncheckedUpdateWithoutClinicInput;
  create: TwilioConfigurationUncheckedCreateWithoutClinicInput;
};

export type TwilioConfigurationWhereInput = {
  AND?: Maybe<Array<TwilioConfigurationWhereInput>>;
  OR?: Maybe<Array<TwilioConfigurationWhereInput>>;
  NOT?: Maybe<Array<TwilioConfigurationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  subaccountSid?: Maybe<StringNullableFilter>;
  messagingServiceSid?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type TwilioConfigurationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export enum TwilioRecordingStatus {
  Completed = 'Completed',
  Processing = 'Processing',
  Absent = 'Absent',
  Paused = 'Paused',
  InProgress = 'InProgress',
  Stopped = 'Stopped'
}

export type TwilioVerification = {
  __typename?: 'TwilioVerification';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: TwilioVerificationStatus;
  phoneNumber: Scalars['String'];
  twilioCallSid: Scalars['String'];
  clinicId: Scalars['String'];
  clinic: Clinic;
};

export type TwilioVerificationCountAggregateOutputType = {
  __typename?: 'TwilioVerificationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  status: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  twilioCallSid: Scalars['Int'];
  clinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TwilioVerificationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type TwilioVerificationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber: Scalars['String'];
  twilioCallSid: Scalars['String'];
  clinic: ClinicCreateNestedOneWithoutTwilioVerificationsInput;
};

export type TwilioVerificationCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber: Scalars['String'];
  twilioCallSid: Scalars['String'];
};

export type TwilioVerificationCreateManyClinicInputEnvelope = {
  data: Array<TwilioVerificationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TwilioVerificationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber: Scalars['String'];
  twilioCallSid: Scalars['String'];
  clinicId: Scalars['String'];
};

export type TwilioVerificationCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<TwilioVerificationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<TwilioVerificationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<TwilioVerificationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
};

export type TwilioVerificationCreateOrConnectWithoutClinicInput = {
  where: TwilioVerificationWhereUniqueInput;
  create: TwilioVerificationUncheckedCreateWithoutClinicInput;
};

export type TwilioVerificationCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber: Scalars['String'];
  twilioCallSid: Scalars['String'];
};

export type TwilioVerificationListRelationFilter = {
  every?: Maybe<TwilioVerificationWhereInput>;
  some?: Maybe<TwilioVerificationWhereInput>;
  none?: Maybe<TwilioVerificationWhereInput>;
};

export type TwilioVerificationMaxAggregateOutputType = {
  __typename?: 'TwilioVerificationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type TwilioVerificationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type TwilioVerificationMinAggregateOutputType = {
  __typename?: 'TwilioVerificationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type TwilioVerificationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type TwilioVerificationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum TwilioVerificationOrderByRelevanceFieldEnum {
  Id = 'id',
  PhoneNumber = 'phoneNumber',
  TwilioCallSid = 'twilioCallSid',
  ClinicId = 'clinicId'
}

export type TwilioVerificationOrderByRelevanceInput = {
  fields: Array<TwilioVerificationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type TwilioVerificationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  _count?: Maybe<TwilioVerificationCountOrderByAggregateInput>;
  _max?: Maybe<TwilioVerificationMaxOrderByAggregateInput>;
  _min?: Maybe<TwilioVerificationMinOrderByAggregateInput>;
};

export type TwilioVerificationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<TwilioVerificationOrderByRelevanceInput>;
};

export enum TwilioVerificationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  PhoneNumber = 'phoneNumber',
  TwilioCallSid = 'twilioCallSid',
  ClinicId = 'clinicId'
}

export type TwilioVerificationScalarWhereInput = {
  AND?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
  OR?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
  NOT?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumTwilioVerificationStatusFilter>;
  phoneNumber?: Maybe<StringFilter>;
  twilioCallSid?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
};

export type TwilioVerificationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TwilioVerificationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TwilioVerificationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TwilioVerificationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumTwilioVerificationStatusWithAggregatesFilter>;
  phoneNumber?: Maybe<StringWithAggregatesFilter>;
  twilioCallSid?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
};

export enum TwilioVerificationStatus {
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed'
}

export type TwilioVerificationSubscription = {
  __typename?: 'TwilioVerificationSubscription';
  node: TwilioVerification;
  mutation: MutationType;
};

export type TwilioVerificationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber: Scalars['String'];
  twilioCallSid: Scalars['String'];
  clinicId: Scalars['String'];
};

export type TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<TwilioVerificationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<TwilioVerificationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<TwilioVerificationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
};

export type TwilioVerificationUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber: Scalars['String'];
  twilioCallSid: Scalars['String'];
};

export type TwilioVerificationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type TwilioVerificationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<TwilioVerificationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<TwilioVerificationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<TwilioVerificationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<TwilioVerificationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  delete?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  connect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  update?: Maybe<Array<TwilioVerificationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<TwilioVerificationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
};

export type TwilioVerificationUncheckedUpdateManyWithoutTwilioVerificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
};

export type TwilioVerificationUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
};

export type TwilioVerificationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutTwilioVerificationsNestedInput>;
};

export type TwilioVerificationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
};

export type TwilioVerificationUpdateManyWithWhereWithoutClinicInput = {
  where: TwilioVerificationScalarWhereInput;
  data: TwilioVerificationUncheckedUpdateManyWithoutTwilioVerificationsInput;
};

export type TwilioVerificationUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<TwilioVerificationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<TwilioVerificationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<TwilioVerificationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<TwilioVerificationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  delete?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  connect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  update?: Maybe<Array<TwilioVerificationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<TwilioVerificationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
};

export type TwilioVerificationUpdateWithWhereUniqueWithoutClinicInput = {
  where: TwilioVerificationWhereUniqueInput;
  data: TwilioVerificationUncheckedUpdateWithoutClinicInput;
};

export type TwilioVerificationUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
};

export type TwilioVerificationUpsertWithWhereUniqueWithoutClinicInput = {
  where: TwilioVerificationWhereUniqueInput;
  update: TwilioVerificationUncheckedUpdateWithoutClinicInput;
  create: TwilioVerificationUncheckedCreateWithoutClinicInput;
};

export type TwilioVerificationWhereInput = {
  AND?: Maybe<Array<TwilioVerificationWhereInput>>;
  OR?: Maybe<Array<TwilioVerificationWhereInput>>;
  NOT?: Maybe<Array<TwilioVerificationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumTwilioVerificationStatusFilter>;
  phoneNumber?: Maybe<StringFilter>;
  twilioCallSid?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type TwilioVerificationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
};

export type TwilioVerifyCallerIdInput = {
  phoneNumber: Scalars['String'];
  friendlyName: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  callDelay?: Maybe<Scalars['Int']>;
  isCallerId?: Maybe<Scalars['Boolean']>;
};

export type UiRefresh = {
  __typename?: 'UiRefresh';
  refreshType: UiRefreshType;
  message: Scalars['String'];
  metadata?: Maybe<Scalars['Json']>;
};

export type UiRefreshInput = {
  refreshType: UiRefreshType;
  message: Scalars['String'];
  metadata?: Maybe<Scalars['Json']>;
};

export enum UiRefreshType {
  HardRefresh = 'HardRefresh',
  SuggestRefresh = 'SuggestRefresh'
}

export type UnavailableReasons = {
  __typename?: 'UnavailableReasons';
  IsPetAlreadyEnrolled?: Maybe<Scalars['Boolean']>;
  IsPetPolicyCancelled?: Maybe<Scalars['Boolean']>;
  IsActiveOrPendingCertificateFound?: Maybe<Scalars['Boolean']>;
  IsExamDateOutsideOfActivationWindow?: Maybe<Scalars['Boolean']>;
  IsAdoptionDateOutsideOfActivationWindow?: Maybe<Scalars['Boolean']>;
  IsEmailAssociatedToManyPolicies?: Maybe<Scalars['Boolean']>;
};

export type UnlayerSetting = {
  __typename?: 'UnlayerSetting';
  id: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  emailTemplates: Array<EmailTemplate>;
  clinics: Array<Clinic>;
  _count: UnlayerSettingCountOutputType;
};


export type UnlayerSettingEmailTemplatesArgs = {
  where?: Maybe<EmailTemplateWhereInput>;
  orderBy?: Maybe<EmailTemplateOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailTemplateScalarFieldEnum>;
};


export type UnlayerSettingClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};

export type UnlayerSettingAvgAggregateOutputType = {
  __typename?: 'UnlayerSettingAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']>;
};

export type UnlayerSettingAvgOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
};

export type UnlayerSettingCountAggregateOutputType = {
  __typename?: 'UnlayerSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UnlayerSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UnlayerSettingCountOutputType = {
  __typename?: 'UnlayerSettingCountOutputType';
  emailTemplates: Scalars['Int'];
  clinics: Scalars['Int'];
};

export type UnlayerSettingCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateCreateNestedManyWithoutUnlayerSettingInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUnlayerSettingInput>;
};

export type UnlayerSettingCreateManyInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingCreateNestedOneWithoutClinicsInput = {
  create?: Maybe<UnlayerSettingUncheckedCreateWithoutClinicsInput>;
  connectOrCreate?: Maybe<UnlayerSettingCreateOrConnectWithoutClinicsInput>;
  connect?: Maybe<UnlayerSettingWhereUniqueInput>;
};

export type UnlayerSettingCreateNestedOneWithoutEmailTemplatesInput = {
  create?: Maybe<UnlayerSettingUncheckedCreateWithoutEmailTemplatesInput>;
  connectOrCreate?: Maybe<UnlayerSettingCreateOrConnectWithoutEmailTemplatesInput>;
  connect?: Maybe<UnlayerSettingWhereUniqueInput>;
};

export type UnlayerSettingCreateOrConnectWithoutClinicsInput = {
  where: UnlayerSettingWhereUniqueInput;
  create: UnlayerSettingUncheckedCreateWithoutClinicsInput;
};

export type UnlayerSettingCreateOrConnectWithoutEmailTemplatesInput = {
  where: UnlayerSettingWhereUniqueInput;
  create: UnlayerSettingUncheckedCreateWithoutEmailTemplatesInput;
};

export type UnlayerSettingCreateWithoutClinicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateCreateNestedManyWithoutUnlayerSettingInput>;
};

export type UnlayerSettingCreateWithoutEmailTemplatesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUnlayerSettingInput>;
};

export type UnlayerSettingMaxAggregateOutputType = {
  __typename?: 'UnlayerSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UnlayerSettingMinAggregateOutputType = {
  __typename?: 'UnlayerSettingMinAggregateOutputType';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UnlayerSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<UnlayerSettingCountOrderByAggregateInput>;
  _avg?: Maybe<UnlayerSettingAvgOrderByAggregateInput>;
  _max?: Maybe<UnlayerSettingMaxOrderByAggregateInput>;
  _min?: Maybe<UnlayerSettingMinOrderByAggregateInput>;
  _sum?: Maybe<UnlayerSettingSumOrderByAggregateInput>;
};

export type UnlayerSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailTemplates?: Maybe<EmailTemplateOrderByRelationAggregateInput>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
};

export type UnlayerSettingRelationFilter = {
  is?: Maybe<UnlayerSettingWhereInput>;
  isNot?: Maybe<UnlayerSettingWhereInput>;
};

export enum UnlayerSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type UnlayerSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UnlayerSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UnlayerSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UnlayerSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<IntWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type UnlayerSettingSumAggregateOutputType = {
  __typename?: 'UnlayerSettingSumAggregateOutputType';
  id?: Maybe<Scalars['Int']>;
};

export type UnlayerSettingSumOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
};

export type UnlayerSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateUncheckedCreateNestedManyWithoutUnlayerSettingInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUnlayerSettingInput>;
};

export type UnlayerSettingUncheckedCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateUncheckedCreateNestedManyWithoutUnlayerSettingInput>;
};

export type UnlayerSettingUncheckedCreateWithoutEmailTemplatesInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUnlayerSettingInput>;
};

export type UnlayerSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateUncheckedUpdateManyWithoutUnlayerSettingNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUnlayerSettingNestedInput>;
};

export type UnlayerSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingUncheckedUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateUncheckedUpdateManyWithoutUnlayerSettingNestedInput>;
};

export type UnlayerSettingUncheckedUpdateWithoutEmailTemplatesInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUnlayerSettingNestedInput>;
};

export type UnlayerSettingUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateUpdateManyWithoutUnlayerSettingNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUnlayerSettingNestedInput>;
};

export type UnlayerSettingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingUpdateOneRequiredWithoutEmailTemplatesNestedInput = {
  create?: Maybe<UnlayerSettingUncheckedCreateWithoutEmailTemplatesInput>;
  connectOrCreate?: Maybe<UnlayerSettingCreateOrConnectWithoutEmailTemplatesInput>;
  upsert?: Maybe<UnlayerSettingUpsertWithoutEmailTemplatesInput>;
  connect?: Maybe<UnlayerSettingWhereUniqueInput>;
  update?: Maybe<UnlayerSettingUncheckedUpdateWithoutEmailTemplatesInput>;
};

export type UnlayerSettingUpdateOneWithoutClinicsNestedInput = {
  create?: Maybe<UnlayerSettingUncheckedCreateWithoutClinicsInput>;
  connectOrCreate?: Maybe<UnlayerSettingCreateOrConnectWithoutClinicsInput>;
  upsert?: Maybe<UnlayerSettingUpsertWithoutClinicsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UnlayerSettingWhereUniqueInput>;
  update?: Maybe<UnlayerSettingUncheckedUpdateWithoutClinicsInput>;
};

export type UnlayerSettingUpdateWithoutClinicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateUpdateManyWithoutUnlayerSettingNestedInput>;
};

export type UnlayerSettingUpdateWithoutEmailTemplatesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUpdateManyWithoutUnlayerSettingNestedInput>;
};

export type UnlayerSettingUpsertWithoutClinicsInput = {
  update: UnlayerSettingUncheckedUpdateWithoutClinicsInput;
  create: UnlayerSettingUncheckedCreateWithoutClinicsInput;
};

export type UnlayerSettingUpsertWithoutEmailTemplatesInput = {
  update: UnlayerSettingUncheckedUpdateWithoutEmailTemplatesInput;
  create: UnlayerSettingUncheckedCreateWithoutEmailTemplatesInput;
};

export type UnlayerSettingWhereInput = {
  AND?: Maybe<Array<UnlayerSettingWhereInput>>;
  OR?: Maybe<Array<UnlayerSettingWhereInput>>;
  NOT?: Maybe<Array<UnlayerSettingWhereInput>>;
  id?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  emailTemplates?: Maybe<EmailTemplateListRelationFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
};

export type UnlayerSettingWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type UnlayerUser = {
  __typename?: 'UnlayerUser';
  userId: Scalars['Int'];
  signature: Scalars['String'];
};

export type UnreadMessageChannel = {
  __typename?: 'UnreadMessageChannel';
  channelId: Scalars['String'];
  channelLastMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  unreadMessageCount: Scalars['Int'];
  channelType?: Maybe<ChannelType>;
};

export type UpdateAppointmentTypeProviderRoomMapsInput = {
  appointmentTypeId: Scalars['String'];
  providerRoomMaps: Scalars['Json'];
};

export type UpdateAppointmentTypeProviderRoomMapsResponse = {
  __typename?: 'UpdateAppointmentTypeProviderRoomMapsResponse';
  success: Scalars['Boolean'];
  count?: Maybe<Scalars['Int']>;
};

export type UpdateCareSubscriptionInput = {
  addedEnrollments: Array<Maybe<OrganizationPetEnrollmentInput>>;
  cancelledPlanEnrollmentIds?: Maybe<Array<Scalars['String']>>;
  cancelledAddonEnrollmentIds?: Maybe<Array<Scalars['String']>>;
};

export type UpdateCareSubscriptionResult = {
  __typename?: 'UpdateCareSubscriptionResult';
  success: Scalars['Boolean'];
};

export type UpdateChannelFilterSelectionInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
};

export type UpdateClinicPostcardSettingDataInput = {
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateClinicPostcardSettingWhereInput = {
  id: Scalars['String'];
};

export type UploadEmailCampaignCustomListInput = {
  emailCampaignId: Scalars['String'];
  awsFileKey: Scalars['String'];
  fileName: Scalars['String'];
};

export type UpsertUserToClinicInput = {
  clinicId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
};

export type UsageByBenefit = {
  __typename?: 'UsageByBenefit';
  provided: Scalars['Int'];
  used: Scalars['Int'];
  displayOrder?: Maybe<Scalars['Int']>;
  carryover?: Maybe<Scalars['Int']>;
  costCovered?: Maybe<Scalars['Int']>;
  exhausted: Scalars['Boolean'];
  benefit: CareBenefit;
  recentActivity: Array<CareBenefitUsage>;
  sources: BenefitSource;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type: UserType;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired: Scalars['Boolean'];
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfo>;
  invitedBy?: Maybe<User>;
  userSetting?: Maybe<UserSetting>;
  vetInfo?: Maybe<VetInfo>;
  channels: Array<Channel>;
  channelMembers: Array<ChannelMember>;
  channelStatuses: Array<ChannelStatus>;
  channelStatusChannelAssignees: Array<ChannelStatusChannelAssignee>;
  clinics: Array<Clinic>;
  /** @deprecated - 1.0 */
  clinicsId: Array<Clinic>;
  clinicWidgetRequestTypes: Array<ClinicWidgetRequestType>;
  /** @deprecated - 1.0 */
  consultationChats: Array<ConsultationChat>;
  formSubmissions: Array<FormSubmission>;
  invitations: Array<User>;
  refundsIssued: Array<StripeRefund>;
  /** @deprecated - 1.0 */
  reviews: Array<Review>;
  /** Test pet parents */
  clinicPetParents: Array<ClinicPetParent>;
  media?: Maybe<Media>;
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id: Array<UserImpersonation>;
  UserImpersonation_UserToUserImpersonation_user_id: Array<UserImpersonation>;
  sentStripePaymentIntents: Array<StripePaymentIntent>;
  sentInvoices: Array<Invoice>;
  calls: Array<CallParticipant>;
  channelSelections: Array<ChannelSelection>;
  triggeredWritebacks: Array<PimsWritebackLogEntry>;
  channelCreationSources: Array<ChannelCreationSource>;
  usagesRecorded: Array<CareBenefitUsage>;
  careBenefitUsageToInvoiceLineItems: Array<CareBenefitUsageToInvoiceLineItem>;
  userChannelFilterSelections: Array<UserChannelFilterSelection>;
  messageTemplates: Array<MessageTemplate>;
  channelMessagesWithMentions: Array<ChannelMessage>;
  manuallySentAutomationRuns: Array<AutomationRun>;
  channelMessageReactions: Array<ChannelMessageReaction>;
  massTextAlerts: Array<MassTextAlert>;
  loaSignedClinicPhoneNumbers: Array<ClinicPhoneNumber>;
  loyaltyRewardRedemptionActions: Array<LoyaltyRewardRedemptionHistoryEntry>;
  intitiatedLoyaltyAccountMerges: Array<LoyaltyAccountMerger>;
  loyaltyPointUserGrants: Array<LoyaltyPointUserGrant>;
  emailTemplateVersions: Array<EmailTemplateVersion>;
  completedBenefitUsageFollowupSteps: Array<CareBenefitUsageFollowupStep>;
  sentTrupanionExamDayOffers: Array<SentTrupanionExamDayOffer>;
  createdPayoutBatchingPeriods: Array<PayoutBatchingPeriod>;
  validatedCanonicalServices: Array<CanonicalService>;
  requestedEnrollmentSupportEvents: Array<EnrollmentSupportEvent>;
  pointOfContactEnrollmentSupportEvents: Array<EnrollmentSupportEvent>;
  responderEnrollmentSupportEvents: Array<EnrollmentSupportEvent>;
  EmailCampaignsCreated: Array<EmailCampaign>;
  EmailCampaignUpdated: Array<EmailCampaign>;
  EmailCampaignsSent: Array<EmailCampaign>;
  EmailCampaignTemplate: Array<EmailCampaignTemplate>;
  EmailCampaign: Array<EmailCampaign>;
  blockedNumbers: Array<ClinicBlockedPhoneNumber>;
  unblockedNumbers: Array<ClinicBlockedPhoneNumber>;
  pinnedChannels: Array<ChannelPin>;
  boardFilters: Array<UserBoardFilter>;
  informPartnerProgramEnrollmentTermsAcceptances: Array<InformPartnerProgramEnrollment>;
  _count: UserCountOutputType;
  computed_optimizedProfilePic?: Maybe<Scalars['String']>;
};


export type UserChannelsArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
};


export type UserChannelMembersArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
  orderBy?: Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMemberScalarFieldEnum>;
};


export type UserChannelStatusesArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
  orderBy?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelStatusScalarFieldEnum>;
};


export type UserChannelStatusChannelAssigneesArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  orderBy?: Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>;
};


export type UserClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};


export type UserClinicsIdArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};


export type UserClinicWidgetRequestTypesArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
};


export type UserConsultationChatsArgs = {
  where?: Maybe<ConsultationChatWhereInput>;
  orderBy?: Maybe<ConsultationChatOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationChatWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationChatScalarFieldEnum>;
};


export type UserFormSubmissionsArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
};


export type UserInvitationsArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};


export type UserRefundsIssuedArgs = {
  where?: Maybe<StripeRefundWhereInput>;
  orderBy?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeRefundScalarFieldEnum>;
};


export type UserReviewsArgs = {
  where?: Maybe<ReviewWhereInput>;
  orderBy?: Maybe<ReviewOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ReviewWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ReviewScalarFieldEnum>;
};


export type UserClinicPetParentsArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};


export type UserUserAppointmentGroupingsArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
};


export type UserUserImpersonation_UserToUserImpersonation_Impersonated_User_IdArgs = {
  where?: Maybe<UserImpersonationWhereInput>;
  orderBy?: Maybe<UserImpersonationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserImpersonationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserImpersonationScalarFieldEnum>;
};


export type UserUserImpersonation_UserToUserImpersonation_User_IdArgs = {
  where?: Maybe<UserImpersonationWhereInput>;
  orderBy?: Maybe<UserImpersonationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserImpersonationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserImpersonationScalarFieldEnum>;
};


export type UserSentStripePaymentIntentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};


export type UserSentInvoicesArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
};


export type UserCallsArgs = {
  where?: Maybe<CallParticipantWhereInput>;
  orderBy?: Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CallParticipantScalarFieldEnum>;
};


export type UserChannelSelectionsArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
  orderBy?: Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelSelectionScalarFieldEnum>;
};


export type UserTriggeredWritebacksArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PimsWritebackLogEntryScalarFieldEnum>;
};


export type UserChannelCreationSourcesArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
};


export type UserUsagesRecordedArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
};


export type UserCareBenefitUsageToInvoiceLineItemsArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
};


export type UserUserChannelFilterSelectionsArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserChannelFilterSelectionScalarFieldEnum>;
};


export type UserMessageTemplatesArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
  orderBy?: Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MessageTemplateScalarFieldEnum>;
};


export type UserChannelMessagesWithMentionsArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageScalarFieldEnum>;
};


export type UserManuallySentAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};


export type UserChannelMessageReactionsArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
  orderBy?: Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageReactionScalarFieldEnum>;
};


export type UserMassTextAlertsArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
  orderBy?: Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MassTextAlertScalarFieldEnum>;
};


export type UserLoaSignedClinicPhoneNumbersArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
  orderBy?: Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPhoneNumberScalarFieldEnum>;
};


export type UserLoyaltyRewardRedemptionActionsArgs = {
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  orderBy?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum>;
};


export type UserIntitiatedLoyaltyAccountMergesArgs = {
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
  orderBy?: Maybe<LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyAccountMergerScalarFieldEnum>;
};


export type UserLoyaltyPointUserGrantsArgs = {
  where?: Maybe<LoyaltyPointUserGrantWhereInput>;
  orderBy?: Maybe<LoyaltyPointUserGrantOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LoyaltyPointUserGrantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LoyaltyPointUserGrantScalarFieldEnum>;
};


export type UserEmailTemplateVersionsArgs = {
  where?: Maybe<EmailTemplateVersionWhereInput>;
  orderBy?: Maybe<EmailTemplateVersionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailTemplateVersionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailTemplateVersionScalarFieldEnum>;
};


export type UserCompletedBenefitUsageFollowupStepsArgs = {
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  orderBy?: Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageFollowupStepScalarFieldEnum>;
};


export type UserSentTrupanionExamDayOffersArgs = {
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
  orderBy?: Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SentTrupanionExamDayOfferScalarFieldEnum>;
};


export type UserCreatedPayoutBatchingPeriodsArgs = {
  where?: Maybe<PayoutBatchingPeriodWhereInput>;
  orderBy?: Maybe<PayoutBatchingPeriodOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PayoutBatchingPeriodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PayoutBatchingPeriodScalarFieldEnum>;
};


export type UserValidatedCanonicalServicesArgs = {
  where?: Maybe<CanonicalServiceWhereInput>;
  orderBy?: Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CanonicalServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CanonicalServiceScalarFieldEnum>;
};


export type UserRequestedEnrollmentSupportEventsArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
};


export type UserPointOfContactEnrollmentSupportEventsArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
};


export type UserResponderEnrollmentSupportEventsArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
};


export type UserEmailCampaignsCreatedArgs = {
  where?: Maybe<EmailCampaignWhereInput>;
  orderBy?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignScalarFieldEnum>;
};


export type UserEmailCampaignUpdatedArgs = {
  where?: Maybe<EmailCampaignWhereInput>;
  orderBy?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignScalarFieldEnum>;
};


export type UserEmailCampaignsSentArgs = {
  where?: Maybe<EmailCampaignWhereInput>;
  orderBy?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignScalarFieldEnum>;
};


export type UserEmailCampaignTemplateArgs = {
  where?: Maybe<EmailCampaignTemplateWhereInput>;
  orderBy?: Maybe<EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignTemplateScalarFieldEnum>;
};


export type UserEmailCampaignArgs = {
  where?: Maybe<EmailCampaignWhereInput>;
  orderBy?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailCampaignScalarFieldEnum>;
};


export type UserBlockedNumbersArgs = {
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
  orderBy?: Maybe<ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicBlockedPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicBlockedPhoneNumberScalarFieldEnum>;
};


export type UserUnblockedNumbersArgs = {
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
  orderBy?: Maybe<ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicBlockedPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicBlockedPhoneNumberScalarFieldEnum>;
};


export type UserPinnedChannelsArgs = {
  where?: Maybe<ChannelPinWhereInput>;
  orderBy?: Maybe<ChannelPinOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelPinWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelPinScalarFieldEnum>;
};


export type UserBoardFiltersArgs = {
  where?: Maybe<UserBoardFilterWhereInput>;
  orderBy?: Maybe<UserBoardFilterOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserBoardFilterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserBoardFilterScalarFieldEnum>;
};


export type UserInformPartnerProgramEnrollmentTermsAcceptancesArgs = {
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  orderBy?: Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformPartnerProgramEnrollmentScalarFieldEnum>;
};

export type UserAppointmentGrouping = {
  __typename?: 'UserAppointmentGrouping';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
  user?: Maybe<User>;
  appointmentTypes: Array<AppointmentType>;
  clinicEmployees: Array<ClinicEmployee>;
  rooms: Array<ClinicRoom>;
  _count: UserAppointmentGroupingCountOutputType;
};


export type UserAppointmentGroupingAppointmentTypesArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentTypeScalarFieldEnum>;
};


export type UserAppointmentGroupingClinicEmployeesArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeScalarFieldEnum>;
};


export type UserAppointmentGroupingRoomsArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
  orderBy?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicRoomScalarFieldEnum>;
};

export type UserAppointmentGroupingCountAggregateOutputType = {
  __typename?: 'UserAppointmentGroupingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  label: Scalars['Int'];
  userId: Scalars['Int'];
  clinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserAppointmentGroupingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type UserAppointmentGroupingCountOutputType = {
  __typename?: 'UserAppointmentGroupingCountOutputType';
  appointmentTypes: Scalars['Int'];
  clinicEmployees: Scalars['Int'];
  rooms: Scalars['Int'];
};

export type UserAppointmentGroupingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingCreateManyClinicInputEnvelope = {
  data: Array<UserAppointmentGroupingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserAppointmentGroupingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingCreateManyUserInputEnvelope = {
  data: Array<UserAppointmentGroupingCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutRoomsInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutRoomsInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutRoomsInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutAppointmentTypesInput;
};

export type UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutClinicEmployeesInput;
};

export type UserAppointmentGroupingCreateOrConnectWithoutClinicInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutClinicInput;
};

export type UserAppointmentGroupingCreateOrConnectWithoutRoomsInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutRoomsInput;
};

export type UserAppointmentGroupingCreateOrConnectWithoutUserInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutUserInput;
};

export type UserAppointmentGroupingCreateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingListRelationFilter = {
  every?: Maybe<UserAppointmentGroupingWhereInput>;
  some?: Maybe<UserAppointmentGroupingWhereInput>;
  none?: Maybe<UserAppointmentGroupingWhereInput>;
};

export type UserAppointmentGroupingMaxAggregateOutputType = {
  __typename?: 'UserAppointmentGroupingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type UserAppointmentGroupingMinAggregateOutputType = {
  __typename?: 'UserAppointmentGroupingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type UserAppointmentGroupingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserAppointmentGroupingOrderByRelevanceFieldEnum {
  Id = 'id',
  Label = 'label',
  UserId = 'userId',
  ClinicId = 'clinicId'
}

export type UserAppointmentGroupingOrderByRelevanceInput = {
  fields: Array<UserAppointmentGroupingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type UserAppointmentGroupingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  _count?: Maybe<UserAppointmentGroupingCountOrderByAggregateInput>;
  _max?: Maybe<UserAppointmentGroupingMaxOrderByAggregateInput>;
  _min?: Maybe<UserAppointmentGroupingMinOrderByAggregateInput>;
};

export type UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  appointmentTypes?: Maybe<AppointmentTypeOrderByRelationAggregateInput>;
  clinicEmployees?: Maybe<ClinicEmployeeOrderByRelationAggregateInput>;
  rooms?: Maybe<ClinicRoomOrderByRelationAggregateInput>;
  _relevance?: Maybe<UserAppointmentGroupingOrderByRelevanceInput>;
};

export enum UserAppointmentGroupingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Label = 'label',
  UserId = 'userId',
  ClinicId = 'clinicId'
}

export type UserAppointmentGroupingScalarWhereInput = {
  AND?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  OR?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  NOT?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  label?: Maybe<StringFilter>;
  userId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
};

export type UserAppointmentGroupingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserAppointmentGroupingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserAppointmentGroupingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserAppointmentGroupingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  label?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type UserAppointmentGroupingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutRoomsInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutRoomsInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicEmployeesInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicEmployeesInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutClinicEmployeesInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutRoomsInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutRoomsInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutRoomsInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutRoomsInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutRoomsInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutAppointmentTypesInput = {
  where: UserAppointmentGroupingScalarWhereInput;
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutClinicEmployeesInput = {
  where: UserAppointmentGroupingScalarWhereInput;
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutClinicInput = {
  where: UserAppointmentGroupingScalarWhereInput;
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutRoomsInput = {
  where: UserAppointmentGroupingScalarWhereInput;
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutUserInput = {
  where: UserAppointmentGroupingScalarWhereInput;
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
};

export type UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicEmployeesInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicEmployeesInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutClinicEmployeesInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutRoomsInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutRoomsInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutRoomsInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutRoomsInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutRoomsInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutAppointmentTypesInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  data: UserAppointmentGroupingUncheckedUpdateWithoutAppointmentTypesInput;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicEmployeesInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  data: UserAppointmentGroupingUncheckedUpdateWithoutClinicEmployeesInput;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  data: UserAppointmentGroupingUncheckedUpdateWithoutClinicInput;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutRoomsInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  data: UserAppointmentGroupingUncheckedUpdateWithoutRoomsInput;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutUserInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  data: UserAppointmentGroupingUncheckedUpdateWithoutUserInput;
};

export type UserAppointmentGroupingUpdateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUpdateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutAppointmentTypesInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutAppointmentTypesInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutAppointmentTypesInput;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicEmployeesInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutClinicEmployeesInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutClinicEmployeesInput;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutClinicInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutClinicInput;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutRoomsInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutRoomsInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutRoomsInput;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutUserInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutUserInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutUserInput;
};

export type UserAppointmentGroupingWhereInput = {
  AND?: Maybe<Array<UserAppointmentGroupingWhereInput>>;
  OR?: Maybe<Array<UserAppointmentGroupingWhereInput>>;
  NOT?: Maybe<Array<UserAppointmentGroupingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  label?: Maybe<StringFilter>;
  userId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  user?: Maybe<UserWhereInput>;
  appointmentTypes?: Maybe<AppointmentTypeListRelationFilter>;
  clinicEmployees?: Maybe<ClinicEmployeeListRelationFilter>;
  rooms?: Maybe<ClinicRoomListRelationFilter>;
};

export type UserAppointmentGroupingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type UserBoardFilter = {
  __typename?: 'UserBoardFilter';
  id: Scalars['String'];
  userId: Scalars['String'];
  currentView: BoardFilterView;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  columns: Array<BoardFilterColumn>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRange>;
  user: User;
  _count: UserBoardFilterCountOutputType;
};


export type UserBoardFilterColumnsArgs = {
  where?: Maybe<BoardFilterColumnWhereInput>;
  orderBy?: Maybe<BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<BoardFilterColumnWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<BoardFilterColumnScalarFieldEnum>;
};

export type UserBoardFilterCountAggregateOutputType = {
  __typename?: 'UserBoardFilterCountAggregateOutputType';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  currentView: Scalars['Int'];
  shouldHideEmptyColumns: Scalars['Int'];
  dateRangeOption: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserBoardFilterCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  currentView?: Maybe<SortOrder>;
  shouldHideEmptyColumns?: Maybe<SortOrder>;
  dateRangeOption?: Maybe<SortOrder>;
};

export type UserBoardFilterCountOutputType = {
  __typename?: 'UserBoardFilterCountOutputType';
  columns: Scalars['Int'];
};

export type UserBoardFilterCreateInput = {
  id?: Maybe<Scalars['String']>;
  currentView: BoardFilterView;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  columns?: Maybe<BoardFilterColumnCreateNestedManyWithoutUserBoardFilterInput>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeCreateNestedOneWithoutUserBoardFilterInput>;
  user: UserCreateNestedOneWithoutBoardFiltersInput;
};

export type UserBoardFilterCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  currentView: BoardFilterView;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
};

export type UserBoardFilterCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  currentView: BoardFilterView;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
};

export type UserBoardFilterCreateManyUserInputEnvelope = {
  data: Array<UserBoardFilterCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserBoardFilterCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserBoardFilterCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserBoardFilterCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserBoardFilterCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
};

export type UserBoardFilterCreateNestedOneWithoutColumnsInput = {
  create?: Maybe<UserBoardFilterUncheckedCreateWithoutColumnsInput>;
  connectOrCreate?: Maybe<UserBoardFilterCreateOrConnectWithoutColumnsInput>;
  connect?: Maybe<UserBoardFilterWhereUniqueInput>;
};

export type UserBoardFilterCreateNestedOneWithoutSpecificDateRangeInput = {
  create?: Maybe<UserBoardFilterUncheckedCreateWithoutSpecificDateRangeInput>;
  connectOrCreate?: Maybe<UserBoardFilterCreateOrConnectWithoutSpecificDateRangeInput>;
  connect?: Maybe<UserBoardFilterWhereUniqueInput>;
};

export type UserBoardFilterCreateOrConnectWithoutColumnsInput = {
  where: UserBoardFilterWhereUniqueInput;
  create: UserBoardFilterUncheckedCreateWithoutColumnsInput;
};

export type UserBoardFilterCreateOrConnectWithoutSpecificDateRangeInput = {
  where: UserBoardFilterWhereUniqueInput;
  create: UserBoardFilterUncheckedCreateWithoutSpecificDateRangeInput;
};

export type UserBoardFilterCreateOrConnectWithoutUserInput = {
  where: UserBoardFilterWhereUniqueInput;
  create: UserBoardFilterUncheckedCreateWithoutUserInput;
};

export type UserBoardFilterCreateWithoutColumnsInput = {
  id?: Maybe<Scalars['String']>;
  currentView: BoardFilterView;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeCreateNestedOneWithoutUserBoardFilterInput>;
  user: UserCreateNestedOneWithoutBoardFiltersInput;
};

export type UserBoardFilterCreateWithoutSpecificDateRangeInput = {
  id?: Maybe<Scalars['String']>;
  currentView: BoardFilterView;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  columns?: Maybe<BoardFilterColumnCreateNestedManyWithoutUserBoardFilterInput>;
  user: UserCreateNestedOneWithoutBoardFiltersInput;
};

export type UserBoardFilterCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  currentView: BoardFilterView;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  columns?: Maybe<BoardFilterColumnCreateNestedManyWithoutUserBoardFilterInput>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeCreateNestedOneWithoutUserBoardFilterInput>;
};

export type UserBoardFilterListRelationFilter = {
  every?: Maybe<UserBoardFilterWhereInput>;
  some?: Maybe<UserBoardFilterWhereInput>;
  none?: Maybe<UserBoardFilterWhereInput>;
};

export type UserBoardFilterMaxAggregateOutputType = {
  __typename?: 'UserBoardFilterMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  currentView?: Maybe<BoardFilterView>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
};

export type UserBoardFilterMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  currentView?: Maybe<SortOrder>;
  shouldHideEmptyColumns?: Maybe<SortOrder>;
  dateRangeOption?: Maybe<SortOrder>;
};

export type UserBoardFilterMinAggregateOutputType = {
  __typename?: 'UserBoardFilterMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  currentView?: Maybe<BoardFilterView>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
};

export type UserBoardFilterMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  currentView?: Maybe<SortOrder>;
  shouldHideEmptyColumns?: Maybe<SortOrder>;
  dateRangeOption?: Maybe<SortOrder>;
};

export type UserBoardFilterOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserBoardFilterOrderByRelevanceFieldEnum {
  Id = 'id',
  UserId = 'userId'
}

export type UserBoardFilterOrderByRelevanceInput = {
  fields: Array<UserBoardFilterOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type UserBoardFilterOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  currentView?: Maybe<SortOrder>;
  shouldHideEmptyColumns?: Maybe<SortOrder>;
  dateRangeOption?: Maybe<SortOrder>;
  _count?: Maybe<UserBoardFilterCountOrderByAggregateInput>;
  _max?: Maybe<UserBoardFilterMaxOrderByAggregateInput>;
  _min?: Maybe<UserBoardFilterMinOrderByAggregateInput>;
};

export type UserBoardFilterOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  currentView?: Maybe<SortOrder>;
  shouldHideEmptyColumns?: Maybe<SortOrder>;
  dateRangeOption?: Maybe<SortOrder>;
  columns?: Maybe<BoardFilterColumnOrderByRelationAggregateInput>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<UserBoardFilterOrderByRelevanceInput>;
};

export type UserBoardFilterRelationFilter = {
  is?: Maybe<UserBoardFilterWhereInput>;
  isNot?: Maybe<UserBoardFilterWhereInput>;
};

export enum UserBoardFilterScalarFieldEnum {
  Id = 'id',
  UserId = 'userId',
  CurrentView = 'currentView',
  ShouldHideEmptyColumns = 'shouldHideEmptyColumns',
  DateRangeOption = 'dateRangeOption'
}

export type UserBoardFilterScalarWhereInput = {
  AND?: Maybe<Array<UserBoardFilterScalarWhereInput>>;
  OR?: Maybe<Array<UserBoardFilterScalarWhereInput>>;
  NOT?: Maybe<Array<UserBoardFilterScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  currentView?: Maybe<EnumBoardFilterViewFilter>;
  shouldHideEmptyColumns?: Maybe<BoolNullableFilter>;
  dateRangeOption?: Maybe<EnumBoardFilterDateRangeNullableFilter>;
};

export type UserBoardFilterScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserBoardFilterScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserBoardFilterScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserBoardFilterScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  currentView?: Maybe<EnumBoardFilterViewWithAggregatesFilter>;
  shouldHideEmptyColumns?: Maybe<BoolNullableWithAggregatesFilter>;
  dateRangeOption?: Maybe<EnumBoardFilterDateRangeNullableWithAggregatesFilter>;
};

export type UserBoardFilterUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  currentView: BoardFilterView;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  columns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutUserBoardFilterInput>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUncheckedCreateNestedOneWithoutUserBoardFilterInput>;
};

export type UserBoardFilterUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserBoardFilterCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserBoardFilterCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserBoardFilterCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
};

export type UserBoardFilterUncheckedCreateWithoutColumnsInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  currentView: BoardFilterView;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUncheckedCreateNestedOneWithoutUserBoardFilterInput>;
};

export type UserBoardFilterUncheckedCreateWithoutSpecificDateRangeInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  currentView: BoardFilterView;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  columns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutUserBoardFilterInput>;
};

export type UserBoardFilterUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  currentView: BoardFilterView;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  columns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutUserBoardFilterInput>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUncheckedCreateNestedOneWithoutUserBoardFilterInput>;
};

export type UserBoardFilterUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  currentView?: Maybe<BoardFilterView>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  columns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutUserBoardFilterNestedInput>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUncheckedUpdateOneWithoutUserBoardFilterNestedInput>;
};

export type UserBoardFilterUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  currentView?: Maybe<BoardFilterView>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
};

export type UserBoardFilterUncheckedUpdateManyWithoutBoardFiltersInput = {
  id?: Maybe<Scalars['String']>;
  currentView?: Maybe<BoardFilterView>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
};

export type UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<UserBoardFilterCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserBoardFilterCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserBoardFilterUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserBoardFilterCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  delete?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  connect?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  update?: Maybe<Array<UserBoardFilterUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserBoardFilterUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserBoardFilterScalarWhereInput>>;
};

export type UserBoardFilterUncheckedUpdateWithoutColumnsInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  currentView?: Maybe<BoardFilterView>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUncheckedUpdateOneWithoutUserBoardFilterNestedInput>;
};

export type UserBoardFilterUncheckedUpdateWithoutSpecificDateRangeInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  currentView?: Maybe<BoardFilterView>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  columns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutUserBoardFilterNestedInput>;
};

export type UserBoardFilterUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  currentView?: Maybe<BoardFilterView>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  columns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutUserBoardFilterNestedInput>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUncheckedUpdateOneWithoutUserBoardFilterNestedInput>;
};

export type UserBoardFilterUpdateInput = {
  id?: Maybe<Scalars['String']>;
  currentView?: Maybe<BoardFilterView>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  columns?: Maybe<BoardFilterColumnUpdateManyWithoutUserBoardFilterNestedInput>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUpdateOneWithoutUserBoardFilterNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutBoardFiltersNestedInput>;
};

export type UserBoardFilterUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  currentView?: Maybe<BoardFilterView>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
};

export type UserBoardFilterUpdateManyWithWhereWithoutUserInput = {
  where: UserBoardFilterScalarWhereInput;
  data: UserBoardFilterUncheckedUpdateManyWithoutBoardFiltersInput;
};

export type UserBoardFilterUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<UserBoardFilterCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserBoardFilterCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserBoardFilterUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserBoardFilterCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  delete?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  connect?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  update?: Maybe<Array<UserBoardFilterUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserBoardFilterUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserBoardFilterScalarWhereInput>>;
};

export type UserBoardFilterUpdateOneWithoutColumnsNestedInput = {
  create?: Maybe<UserBoardFilterUncheckedCreateWithoutColumnsInput>;
  connectOrCreate?: Maybe<UserBoardFilterCreateOrConnectWithoutColumnsInput>;
  upsert?: Maybe<UserBoardFilterUpsertWithoutColumnsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserBoardFilterWhereUniqueInput>;
  update?: Maybe<UserBoardFilterUncheckedUpdateWithoutColumnsInput>;
};

export type UserBoardFilterUpdateOneWithoutSpecificDateRangeNestedInput = {
  create?: Maybe<UserBoardFilterUncheckedCreateWithoutSpecificDateRangeInput>;
  connectOrCreate?: Maybe<UserBoardFilterCreateOrConnectWithoutSpecificDateRangeInput>;
  upsert?: Maybe<UserBoardFilterUpsertWithoutSpecificDateRangeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserBoardFilterWhereUniqueInput>;
  update?: Maybe<UserBoardFilterUncheckedUpdateWithoutSpecificDateRangeInput>;
};

export type UserBoardFilterUpdateWithWhereUniqueWithoutUserInput = {
  where: UserBoardFilterWhereUniqueInput;
  data: UserBoardFilterUncheckedUpdateWithoutUserInput;
};

export type UserBoardFilterUpdateWithoutColumnsInput = {
  id?: Maybe<Scalars['String']>;
  currentView?: Maybe<BoardFilterView>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUpdateOneWithoutUserBoardFilterNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutBoardFiltersNestedInput>;
};

export type UserBoardFilterUpdateWithoutSpecificDateRangeInput = {
  id?: Maybe<Scalars['String']>;
  currentView?: Maybe<BoardFilterView>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  columns?: Maybe<BoardFilterColumnUpdateManyWithoutUserBoardFilterNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutBoardFiltersNestedInput>;
};

export type UserBoardFilterUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  currentView?: Maybe<BoardFilterView>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  columns?: Maybe<BoardFilterColumnUpdateManyWithoutUserBoardFilterNestedInput>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUpdateOneWithoutUserBoardFilterNestedInput>;
};

export type UserBoardFilterUpsertWithWhereUniqueWithoutUserInput = {
  where: UserBoardFilterWhereUniqueInput;
  update: UserBoardFilterUncheckedUpdateWithoutUserInput;
  create: UserBoardFilterUncheckedCreateWithoutUserInput;
};

export type UserBoardFilterUpsertWithoutColumnsInput = {
  update: UserBoardFilterUncheckedUpdateWithoutColumnsInput;
  create: UserBoardFilterUncheckedCreateWithoutColumnsInput;
};

export type UserBoardFilterUpsertWithoutSpecificDateRangeInput = {
  update: UserBoardFilterUncheckedUpdateWithoutSpecificDateRangeInput;
  create: UserBoardFilterUncheckedCreateWithoutSpecificDateRangeInput;
};

export type UserBoardFilterWhereInput = {
  AND?: Maybe<Array<UserBoardFilterWhereInput>>;
  OR?: Maybe<Array<UserBoardFilterWhereInput>>;
  NOT?: Maybe<Array<UserBoardFilterWhereInput>>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  currentView?: Maybe<EnumBoardFilterViewFilter>;
  shouldHideEmptyColumns?: Maybe<BoolNullableFilter>;
  dateRangeOption?: Maybe<EnumBoardFilterDateRangeNullableFilter>;
  columns?: Maybe<BoardFilterColumnListRelationFilter>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
  user?: Maybe<UserWhereInput>;
};

export type UserBoardFilterWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type UserChannelFilterSelection = {
  __typename?: 'UserChannelFilterSelection';
  id: Scalars['String'];
  userId: Scalars['String'];
  filterSelectionId: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
  user: User;
  filterSelection: ChannelFilterSelection;
};

export type UserChannelFilterSelectionCountAggregateOutputType = {
  __typename?: 'UserChannelFilterSelectionCountAggregateOutputType';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  filterSelectionId: Scalars['Int'];
  isFilterOwner: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserChannelFilterSelectionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  filterSelectionId?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
};

export type UserChannelFilterSelectionCreateInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
  user: UserCreateNestedOneWithoutUserChannelFilterSelectionsInput;
  filterSelection: ChannelFilterSelectionCreateNestedOneWithoutUserChannelFilterSelectionsInput;
};

export type UserChannelFilterSelectionCreateManyFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope = {
  data: Array<UserChannelFilterSelectionCreateManyFilterSelectionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  filterSelectionId: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  filterSelectionId: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionCreateManyUserInputEnvelope = {
  data: Array<UserChannelFilterSelectionCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionCreateNestedManyWithoutFilterSelectionInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutFilterSelectionInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
};

export type UserChannelFilterSelectionCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
};

export type UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput = {
  where: UserChannelFilterSelectionWhereUniqueInput;
  create: UserChannelFilterSelectionUncheckedCreateWithoutFilterSelectionInput;
};

export type UserChannelFilterSelectionCreateOrConnectWithoutUserInput = {
  where: UserChannelFilterSelectionWhereUniqueInput;
  create: UserChannelFilterSelectionUncheckedCreateWithoutUserInput;
};

export type UserChannelFilterSelectionCreateWithoutFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
  user: UserCreateNestedOneWithoutUserChannelFilterSelectionsInput;
};

export type UserChannelFilterSelectionCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
  filterSelection: ChannelFilterSelectionCreateNestedOneWithoutUserChannelFilterSelectionsInput;
};

export type UserChannelFilterSelectionListRelationFilter = {
  every?: Maybe<UserChannelFilterSelectionWhereInput>;
  some?: Maybe<UserChannelFilterSelectionWhereInput>;
  none?: Maybe<UserChannelFilterSelectionWhereInput>;
};

export type UserChannelFilterSelectionMaxAggregateOutputType = {
  __typename?: 'UserChannelFilterSelectionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  filterSelectionId?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  filterSelectionId?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
};

export type UserChannelFilterSelectionMinAggregateOutputType = {
  __typename?: 'UserChannelFilterSelectionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  filterSelectionId?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  filterSelectionId?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
};

export type UserChannelFilterSelectionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserChannelFilterSelectionOrderByRelevanceFieldEnum {
  Id = 'id',
  UserId = 'userId',
  FilterSelectionId = 'filterSelectionId'
}

export type UserChannelFilterSelectionOrderByRelevanceInput = {
  fields: Array<UserChannelFilterSelectionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type UserChannelFilterSelectionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  filterSelectionId?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
  _count?: Maybe<UserChannelFilterSelectionCountOrderByAggregateInput>;
  _max?: Maybe<UserChannelFilterSelectionMaxOrderByAggregateInput>;
  _min?: Maybe<UserChannelFilterSelectionMinOrderByAggregateInput>;
};

export type UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  filterSelectionId?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  filterSelection?: Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<UserChannelFilterSelectionOrderByRelevanceInput>;
};

export enum UserChannelFilterSelectionScalarFieldEnum {
  Id = 'id',
  UserId = 'userId',
  FilterSelectionId = 'filterSelectionId',
  IsFilterOwner = 'isFilterOwner'
}

export type UserChannelFilterSelectionScalarWhereInput = {
  AND?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
  OR?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
  NOT?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  filterSelectionId?: Maybe<StringFilter>;
  isFilterOwner?: Maybe<BoolFilter>;
};

export type UserChannelFilterSelectionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  filterSelectionId?: Maybe<StringWithAggregatesFilter>;
  isFilterOwner?: Maybe<BoolWithAggregatesFilter>;
};

export type UserChannelFilterSelectionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  filterSelectionId: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionUncheckedCreateNestedManyWithoutFilterSelectionInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutFilterSelectionInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
};

export type UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
};

export type UserChannelFilterSelectionUncheckedCreateWithoutFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  filterSelectionId: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  filterSelectionId?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  filterSelectionId?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUncheckedUpdateManyWithoutFilterSelectionNestedInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutFilterSelectionInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput>>;
  upsert?: Maybe<Array<UserChannelFilterSelectionUpsertWithWhereUniqueWithoutFilterSelectionInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope>;
  set?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<UserChannelFilterSelectionUpdateWithWhereUniqueWithoutFilterSelectionInput>>;
  updateMany?: Maybe<Array<UserChannelFilterSelectionUpdateManyWithWhereWithoutFilterSelectionInput>>;
  deleteMany?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
};

export type UserChannelFilterSelectionUncheckedUpdateManyWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  filterSelectionId?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserChannelFilterSelectionUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<UserChannelFilterSelectionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserChannelFilterSelectionUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
};

export type UserChannelFilterSelectionUncheckedUpdateWithoutFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  filterSelectionId?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUpdateOneRequiredWithoutUserChannelFilterSelectionsNestedInput>;
  filterSelection?: Maybe<ChannelFilterSelectionUpdateOneRequiredWithoutUserChannelFilterSelectionsNestedInput>;
};

export type UserChannelFilterSelectionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUpdateManyWithWhereWithoutFilterSelectionInput = {
  where: UserChannelFilterSelectionScalarWhereInput;
  data: UserChannelFilterSelectionUncheckedUpdateManyWithoutUserChannelFilterSelectionsInput;
};

export type UserChannelFilterSelectionUpdateManyWithWhereWithoutUserInput = {
  where: UserChannelFilterSelectionScalarWhereInput;
  data: UserChannelFilterSelectionUncheckedUpdateManyWithoutUserChannelFilterSelectionsInput;
};

export type UserChannelFilterSelectionUpdateManyWithoutFilterSelectionNestedInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutFilterSelectionInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput>>;
  upsert?: Maybe<Array<UserChannelFilterSelectionUpsertWithWhereUniqueWithoutFilterSelectionInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope>;
  set?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<UserChannelFilterSelectionUpdateWithWhereUniqueWithoutFilterSelectionInput>>;
  updateMany?: Maybe<Array<UserChannelFilterSelectionUpdateManyWithWhereWithoutFilterSelectionInput>>;
  deleteMany?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
};

export type UserChannelFilterSelectionUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserChannelFilterSelectionUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<UserChannelFilterSelectionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserChannelFilterSelectionUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
};

export type UserChannelFilterSelectionUpdateWithWhereUniqueWithoutFilterSelectionInput = {
  where: UserChannelFilterSelectionWhereUniqueInput;
  data: UserChannelFilterSelectionUncheckedUpdateWithoutFilterSelectionInput;
};

export type UserChannelFilterSelectionUpdateWithWhereUniqueWithoutUserInput = {
  where: UserChannelFilterSelectionWhereUniqueInput;
  data: UserChannelFilterSelectionUncheckedUpdateWithoutUserInput;
};

export type UserChannelFilterSelectionUpdateWithoutFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUpdateOneRequiredWithoutUserChannelFilterSelectionsNestedInput>;
};

export type UserChannelFilterSelectionUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
  filterSelection?: Maybe<ChannelFilterSelectionUpdateOneRequiredWithoutUserChannelFilterSelectionsNestedInput>;
};

export type UserChannelFilterSelectionUpsertWithWhereUniqueWithoutFilterSelectionInput = {
  where: UserChannelFilterSelectionWhereUniqueInput;
  update: UserChannelFilterSelectionUncheckedUpdateWithoutFilterSelectionInput;
  create: UserChannelFilterSelectionUncheckedCreateWithoutFilterSelectionInput;
};

export type UserChannelFilterSelectionUpsertWithWhereUniqueWithoutUserInput = {
  where: UserChannelFilterSelectionWhereUniqueInput;
  update: UserChannelFilterSelectionUncheckedUpdateWithoutUserInput;
  create: UserChannelFilterSelectionUncheckedCreateWithoutUserInput;
};

export type UserChannelFilterSelectionUserIdAndFilterSelectionIdCompoundUniqueInput = {
  userId: Scalars['String'];
  filterSelectionId: Scalars['String'];
};

export type UserChannelFilterSelectionWhereInput = {
  AND?: Maybe<Array<UserChannelFilterSelectionWhereInput>>;
  OR?: Maybe<Array<UserChannelFilterSelectionWhereInput>>;
  NOT?: Maybe<Array<UserChannelFilterSelectionWhereInput>>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  filterSelectionId?: Maybe<StringFilter>;
  isFilterOwner?: Maybe<BoolFilter>;
  user?: Maybe<UserWhereInput>;
  filterSelection?: Maybe<ChannelFilterSelectionWhereInput>;
};

export type UserChannelFilterSelectionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  userIdAndFilterSelectionId?: Maybe<UserChannelFilterSelectionUserIdAndFilterSelectionIdCompoundUniqueInput>;
};

export type UserCountAggregateOutputType = {
  __typename?: 'UserCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  phone: Scalars['Int'];
  signUpDate: Scalars['Int'];
  type: Scalars['Int'];
  firebaseId: Scalars['Int'];
  profilePic: Scalars['Int'];
  ownerInfoId: Scalars['Int'];
  vetInfoId: Scalars['Int'];
  isAdmin: Scalars['Int'];
  updatedAt: Scalars['Int'];
  firstName: Scalars['Int'];
  lastName: Scalars['Int'];
  isActive: Scalars['Int'];
  userSettingId: Scalars['Int'];
  nameDisplay: Scalars['Int'];
  namePrefix: Scalars['Int'];
  nameSuffix: Scalars['Int'];
  invitedAt: Scalars['Int'];
  invitationCode: Scalars['Int'];
  isNewPasswordRequired: Scalars['Int'];
  invitedById: Scalars['Int'];
  mediaId: Scalars['Int'];
  crmId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  signUpDate?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  profilePic?: Maybe<SortOrder>;
  ownerInfoId?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  invitedAt?: Maybe<SortOrder>;
  invitationCode?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  invitedById?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
};

export type UserCountOutputType = {
  __typename?: 'UserCountOutputType';
  channels: Scalars['Int'];
  channelMembers: Scalars['Int'];
  channelStatuses: Scalars['Int'];
  channelStatusChannelAssignees: Scalars['Int'];
  clinics: Scalars['Int'];
  clinicsId: Scalars['Int'];
  clinicWidgetRequestTypes: Scalars['Int'];
  consultationChats: Scalars['Int'];
  formSubmissions: Scalars['Int'];
  invitations: Scalars['Int'];
  refundsIssued: Scalars['Int'];
  reviews: Scalars['Int'];
  clinicPetParents: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  UserImpersonation_UserToUserImpersonation_impersonated_user_id: Scalars['Int'];
  UserImpersonation_UserToUserImpersonation_user_id: Scalars['Int'];
  sentStripePaymentIntents: Scalars['Int'];
  sentInvoices: Scalars['Int'];
  calls: Scalars['Int'];
  channelSelections: Scalars['Int'];
  triggeredWritebacks: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  usagesRecorded: Scalars['Int'];
  careBenefitUsageToInvoiceLineItems: Scalars['Int'];
  userChannelFilterSelections: Scalars['Int'];
  messageTemplates: Scalars['Int'];
  channelMessagesWithMentions: Scalars['Int'];
  manuallySentAutomationRuns: Scalars['Int'];
  channelMessageReactions: Scalars['Int'];
  massTextAlerts: Scalars['Int'];
  loaSignedClinicPhoneNumbers: Scalars['Int'];
  loyaltyRewardRedemptionActions: Scalars['Int'];
  intitiatedLoyaltyAccountMerges: Scalars['Int'];
  loyaltyPointUserGrants: Scalars['Int'];
  emailTemplateVersions: Scalars['Int'];
  completedBenefitUsageFollowupSteps: Scalars['Int'];
  sentTrupanionExamDayOffers: Scalars['Int'];
  createdPayoutBatchingPeriods: Scalars['Int'];
  validatedCanonicalServices: Scalars['Int'];
  requestedEnrollmentSupportEvents: Scalars['Int'];
  pointOfContactEnrollmentSupportEvents: Scalars['Int'];
  responderEnrollmentSupportEvents: Scalars['Int'];
  EmailCampaignsCreated: Scalars['Int'];
  EmailCampaignUpdated: Scalars['Int'];
  EmailCampaignsSent: Scalars['Int'];
  EmailCampaignTemplate: Scalars['Int'];
  EmailCampaign: Scalars['Int'];
  blockedNumbers: Scalars['Int'];
  unblockedNumbers: Scalars['Int'];
  pinnedChannels: Scalars['Int'];
  boardFilters: Scalars['Int'];
  informPartnerProgramEnrollmentTermsAcceptances: Scalars['Int'];
};

export type UserCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
};

export type UserCreateManyInvitedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
};

export type UserCreateManyInvitedByInputEnvelope = {
  data: Array<UserCreateManyInvitedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserCreateManyMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
};

export type UserCreateManyMediaInputEnvelope = {
  data: Array<UserCreateManyMediaInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserCreateManyUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
};

export type UserCreateManyUserSettingInputEnvelope = {
  data: Array<UserCreateManyUserSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutChannelMessagesWithMentionsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelMessagesWithMentionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelMessagesWithMentionsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutChannelSelectionsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelSelectionsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutChannelsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutClinicWidgetRequestTypesInput = {
  create?: Maybe<Array<UserCreateWithoutClinicWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicWidgetRequestTypesInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<UserCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutFormSubmissionsInput = {
  create?: Maybe<Array<UserCreateWithoutFormSubmissionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFormSubmissionsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutInvitedByInput = {
  create?: Maybe<Array<UserCreateWithoutInvitedByInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutInvitedByInput>>;
  createMany?: Maybe<UserCreateManyInvitedByInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutMediaInput = {
  create?: Maybe<Array<UserCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutMediaInput>>;
  createMany?: Maybe<UserCreateManyMediaInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutUserSettingInput = {
  create?: Maybe<Array<UserCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutUserSettingInput>>;
  createMany?: Maybe<UserCreateManyUserSettingInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedOneWithoutBlockedNumbersInput = {
  create?: Maybe<UserUncheckedCreateWithoutBlockedNumbersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBlockedNumbersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutBoardFiltersInput = {
  create?: Maybe<UserUncheckedCreateWithoutBoardFiltersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBoardFiltersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutCallsInput = {
  create?: Maybe<UserUncheckedCreateWithoutCallsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCallsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  create?: Maybe<UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelCreationSourcesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutChannelMembersInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelMembersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelMembersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutChannelMessageReactionsInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelMessageReactionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelMessageReactionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutChannelStatusChannelAssigneesInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutChannelStatusesInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelStatusesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelStatusesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutClinicPetParentsInput = {
  create?: Maybe<UserUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutClinicPetParentsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutClinicsIdInput = {
  create?: Maybe<UserUncheckedCreateWithoutClinicsIdInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutClinicsIdInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutCompletedBenefitUsageFollowupStepsInput = {
  create?: Maybe<UserUncheckedCreateWithoutCompletedBenefitUsageFollowupStepsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCompletedBenefitUsageFollowupStepsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutConsultationChatsInput = {
  create?: Maybe<UserUncheckedCreateWithoutConsultationChatsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutConsultationChatsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutCreatedPayoutBatchingPeriodsInput = {
  create?: Maybe<UserUncheckedCreateWithoutCreatedPayoutBatchingPeriodsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCreatedPayoutBatchingPeriodsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutEmailCampaignInput = {
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutEmailCampaignTemplateInput = {
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignTemplateInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignTemplateInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutEmailCampaignUpdatedInput = {
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignUpdatedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignUpdatedInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutEmailCampaignsCreatedInput = {
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignsCreatedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignsCreatedInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutEmailCampaignsSentInput = {
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignsSentInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignsSentInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutEmailTemplateVersionsInput = {
  create?: Maybe<UserUncheckedCreateWithoutEmailTemplateVersionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailTemplateVersionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  create?: Maybe<UserUncheckedCreateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutIntitiatedLoyaltyAccountMergesInput = {
  create?: Maybe<UserUncheckedCreateWithoutIntitiatedLoyaltyAccountMergesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutIntitiatedLoyaltyAccountMergesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutInvitationsInput = {
  create?: Maybe<UserUncheckedCreateWithoutInvitationsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutInvitationsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutLoaSignedClinicPhoneNumbersInput = {
  create?: Maybe<UserUncheckedCreateWithoutLoaSignedClinicPhoneNumbersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutLoaSignedClinicPhoneNumbersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutLoyaltyPointUserGrantsInput = {
  create?: Maybe<UserUncheckedCreateWithoutLoyaltyPointUserGrantsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutLoyaltyPointUserGrantsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput = {
  create?: Maybe<UserUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutLoyaltyRewardRedemptionActionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutManuallySentAutomationRunsInput = {
  create?: Maybe<UserUncheckedCreateWithoutManuallySentAutomationRunsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutManuallySentAutomationRunsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutMassTextAlertsInput = {
  create?: Maybe<UserUncheckedCreateWithoutMassTextAlertsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMassTextAlertsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutMessageTemplatesInput = {
  create?: Maybe<UserUncheckedCreateWithoutMessageTemplatesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMessageTemplatesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutOwnerInfoInput = {
  create?: Maybe<UserUncheckedCreateWithoutOwnerInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOwnerInfoInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutPinnedChannelsInput = {
  create?: Maybe<UserUncheckedCreateWithoutPinnedChannelsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPinnedChannelsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput = {
  create?: Maybe<UserUncheckedCreateWithoutPointOfContactEnrollmentSupportEventsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPointOfContactEnrollmentSupportEventsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutRefundsIssuedInput = {
  create?: Maybe<UserUncheckedCreateWithoutRefundsIssuedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutRefundsIssuedInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput = {
  create?: Maybe<UserUncheckedCreateWithoutRequestedEnrollmentSupportEventsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutRequestedEnrollmentSupportEventsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput = {
  create?: Maybe<UserUncheckedCreateWithoutResponderEnrollmentSupportEventsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutResponderEnrollmentSupportEventsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutReviewsInput = {
  create?: Maybe<UserUncheckedCreateWithoutReviewsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutReviewsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutSentInvoicesInput = {
  create?: Maybe<UserUncheckedCreateWithoutSentInvoicesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentInvoicesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutSentStripePaymentIntentsInput = {
  create?: Maybe<UserUncheckedCreateWithoutSentStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentStripePaymentIntentsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutSentTrupanionExamDayOffersInput = {
  create?: Maybe<UserUncheckedCreateWithoutSentTrupanionExamDayOffersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentTrupanionExamDayOffersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutTriggeredWritebacksInput = {
  create?: Maybe<UserUncheckedCreateWithoutTriggeredWritebacksInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTriggeredWritebacksInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutUnblockedNumbersInput = {
  create?: Maybe<UserUncheckedCreateWithoutUnblockedNumbersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUnblockedNumbersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutUsagesRecordedInput = {
  create?: Maybe<UserUncheckedCreateWithoutUsagesRecordedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUsagesRecordedInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserAppointmentGroupingsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserAppointmentGroupingsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutUserChannelFilterSelectionsInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserChannelFilterSelectionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserChannelFilterSelectionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutValidatedCanonicalServicesInput = {
  create?: Maybe<UserUncheckedCreateWithoutValidatedCanonicalServicesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutValidatedCanonicalServicesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutVetInfoInput = {
  create?: Maybe<UserUncheckedCreateWithoutVetInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVetInfoInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateOrConnectWithoutBlockedNumbersInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutBlockedNumbersInput;
};

export type UserCreateOrConnectWithoutBoardFiltersInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutBoardFiltersInput;
};

export type UserCreateOrConnectWithoutCallsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutCallsInput;
};

export type UserCreateOrConnectWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput;
};

export type UserCreateOrConnectWithoutChannelCreationSourcesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type UserCreateOrConnectWithoutChannelMembersInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelMembersInput;
};

export type UserCreateOrConnectWithoutChannelMessageReactionsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelMessageReactionsInput;
};

export type UserCreateOrConnectWithoutChannelMessagesWithMentionsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelMessagesWithMentionsInput;
};

export type UserCreateOrConnectWithoutChannelSelectionsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelSelectionsInput;
};

export type UserCreateOrConnectWithoutChannelStatusChannelAssigneesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
};

export type UserCreateOrConnectWithoutChannelStatusesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelStatusesInput;
};

export type UserCreateOrConnectWithoutChannelsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelsInput;
};

export type UserCreateOrConnectWithoutClinicPetParentsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutClinicPetParentsInput;
};

export type UserCreateOrConnectWithoutClinicWidgetRequestTypesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutClinicWidgetRequestTypesInput;
};

export type UserCreateOrConnectWithoutClinicsIdInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutClinicsIdInput;
};

export type UserCreateOrConnectWithoutClinicsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutClinicsInput;
};

export type UserCreateOrConnectWithoutCompletedBenefitUsageFollowupStepsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutCompletedBenefitUsageFollowupStepsInput;
};

export type UserCreateOrConnectWithoutConsultationChatsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutConsultationChatsInput;
};

export type UserCreateOrConnectWithoutCreatedPayoutBatchingPeriodsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutCreatedPayoutBatchingPeriodsInput;
};

export type UserCreateOrConnectWithoutEmailCampaignInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutEmailCampaignInput;
};

export type UserCreateOrConnectWithoutEmailCampaignTemplateInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutEmailCampaignTemplateInput;
};

export type UserCreateOrConnectWithoutEmailCampaignUpdatedInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutEmailCampaignUpdatedInput;
};

export type UserCreateOrConnectWithoutEmailCampaignsCreatedInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutEmailCampaignsCreatedInput;
};

export type UserCreateOrConnectWithoutEmailCampaignsSentInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutEmailCampaignsSentInput;
};

export type UserCreateOrConnectWithoutEmailTemplateVersionsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutEmailTemplateVersionsInput;
};

export type UserCreateOrConnectWithoutFormSubmissionsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutFormSubmissionsInput;
};

export type UserCreateOrConnectWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput;
};

export type UserCreateOrConnectWithoutIntitiatedLoyaltyAccountMergesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutIntitiatedLoyaltyAccountMergesInput;
};

export type UserCreateOrConnectWithoutInvitationsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutInvitationsInput;
};

export type UserCreateOrConnectWithoutInvitedByInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutInvitedByInput;
};

export type UserCreateOrConnectWithoutLoaSignedClinicPhoneNumbersInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutLoaSignedClinicPhoneNumbersInput;
};

export type UserCreateOrConnectWithoutLoyaltyPointUserGrantsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutLoyaltyPointUserGrantsInput;
};

export type UserCreateOrConnectWithoutLoyaltyRewardRedemptionActionsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput;
};

export type UserCreateOrConnectWithoutManuallySentAutomationRunsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutManuallySentAutomationRunsInput;
};

export type UserCreateOrConnectWithoutMassTextAlertsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutMassTextAlertsInput;
};

export type UserCreateOrConnectWithoutMediaInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutMediaInput;
};

export type UserCreateOrConnectWithoutMessageTemplatesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutMessageTemplatesInput;
};

export type UserCreateOrConnectWithoutOwnerInfoInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutOwnerInfoInput;
};

export type UserCreateOrConnectWithoutPinnedChannelsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutPinnedChannelsInput;
};

export type UserCreateOrConnectWithoutPointOfContactEnrollmentSupportEventsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutPointOfContactEnrollmentSupportEventsInput;
};

export type UserCreateOrConnectWithoutRefundsIssuedInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutRefundsIssuedInput;
};

export type UserCreateOrConnectWithoutRequestedEnrollmentSupportEventsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutRequestedEnrollmentSupportEventsInput;
};

export type UserCreateOrConnectWithoutResponderEnrollmentSupportEventsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutResponderEnrollmentSupportEventsInput;
};

export type UserCreateOrConnectWithoutReviewsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutReviewsInput;
};

export type UserCreateOrConnectWithoutSentInvoicesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutSentInvoicesInput;
};

export type UserCreateOrConnectWithoutSentStripePaymentIntentsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutSentStripePaymentIntentsInput;
};

export type UserCreateOrConnectWithoutSentTrupanionExamDayOffersInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutSentTrupanionExamDayOffersInput;
};

export type UserCreateOrConnectWithoutTriggeredWritebacksInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutTriggeredWritebacksInput;
};

export type UserCreateOrConnectWithoutUnblockedNumbersInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutUnblockedNumbersInput;
};

export type UserCreateOrConnectWithoutUsagesRecordedInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutUsagesRecordedInput;
};

export type UserCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type UserCreateOrConnectWithoutUserChannelFilterSelectionsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutUserChannelFilterSelectionsInput;
};

export type UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput;
};

export type UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput;
};

export type UserCreateOrConnectWithoutUserSettingInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutUserSettingInput;
};

export type UserCreateOrConnectWithoutValidatedCanonicalServicesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutValidatedCanonicalServicesInput;
};

export type UserCreateOrConnectWithoutVetInfoInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutVetInfoInput;
};

export type UserCreateWithoutBlockedNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutBoardFiltersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutChannelMessageReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutChannelMessagesWithMentionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutClinicsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutCompletedBenefitUsageFollowupStepsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutConsultationChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutCreatedPayoutBatchingPeriodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutEmailCampaignTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutEmailCampaignUpdatedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutEmailCampaignsCreatedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutEmailCampaignsSentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutEmailTemplateVersionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutIntitiatedLoyaltyAccountMergesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutInvitationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutInvitedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutLoaSignedClinicPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutLoyaltyPointUserGrantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutLoyaltyRewardRedemptionActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutManuallySentAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutOwnerInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutPinnedChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutPointOfContactEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutRefundsIssuedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutRequestedEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutResponderEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutSentInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutSentStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutSentTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutTriggeredWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutUnblockedNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutUsagesRecordedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutValidatedCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserCreateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserImpersonation = {
  __typename?: 'UserImpersonation';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUser?: Maybe<User>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUser>;
  user?: Maybe<User>;
  informPartnerUser?: Maybe<InformPartnerUser>;
};

export type UserImpersonationCountAggregateOutputType = {
  __typename?: 'UserImpersonationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  impersonatedUserId: Scalars['Int'];
  impersonatedInformPartnerUserId: Scalars['Int'];
  userId: Scalars['Int'];
  informPartnerUserId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserImpersonationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  impersonatedUserId?: Maybe<SortOrder>;
  impersonatedInformPartnerUserId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
};

export type UserImpersonationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUser?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  user?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  informPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
};

export type UserImpersonationCreateManyImpersonatedInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationCreateManyImpersonatedInformPartnerUserInputEnvelope = {
  data: Array<UserImpersonationCreateManyImpersonatedInformPartnerUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserImpersonationCreateManyImpersonatedUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationCreateManyImpersonatedUserInputEnvelope = {
  data: Array<UserImpersonationCreateManyImpersonatedUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserImpersonationCreateManyInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationCreateManyInformPartnerUserInputEnvelope = {
  data: Array<UserImpersonationCreateManyInformPartnerUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserImpersonationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationCreateManyUserInputEnvelope = {
  data: Array<UserImpersonationCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedInformPartnerUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedInformPartnerUserInputEnvelope>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
};

export type UserImpersonationCreateNestedManyWithoutImpersonatedUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedUserInputEnvelope>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
};

export type UserImpersonationCreateNestedManyWithoutInformPartnerUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutInformPartnerUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyInformPartnerUserInputEnvelope>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
};

export type UserImpersonationCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
};

export type UserImpersonationCreateOrConnectWithoutImpersonatedInformPartnerUserInput = {
  where: UserImpersonationWhereUniqueInput;
  create: UserImpersonationUncheckedCreateWithoutImpersonatedInformPartnerUserInput;
};

export type UserImpersonationCreateOrConnectWithoutImpersonatedUserInput = {
  where: UserImpersonationWhereUniqueInput;
  create: UserImpersonationUncheckedCreateWithoutImpersonatedUserInput;
};

export type UserImpersonationCreateOrConnectWithoutInformPartnerUserInput = {
  where: UserImpersonationWhereUniqueInput;
  create: UserImpersonationUncheckedCreateWithoutInformPartnerUserInput;
};

export type UserImpersonationCreateOrConnectWithoutUserInput = {
  where: UserImpersonationWhereUniqueInput;
  create: UserImpersonationUncheckedCreateWithoutUserInput;
};

export type UserImpersonationCreateWithoutImpersonatedInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUser?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  user?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  informPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
};

export type UserImpersonationCreateWithoutImpersonatedUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  user?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  informPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
};

export type UserImpersonationCreateWithoutInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUser?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  user?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
};

export type UserImpersonationCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUser?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  informPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
};

export type UserImpersonationListRelationFilter = {
  every?: Maybe<UserImpersonationWhereInput>;
  some?: Maybe<UserImpersonationWhereInput>;
  none?: Maybe<UserImpersonationWhereInput>;
};

export type UserImpersonationMaxAggregateOutputType = {
  __typename?: 'UserImpersonationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  impersonatedUserId?: Maybe<SortOrder>;
  impersonatedInformPartnerUserId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
};

export type UserImpersonationMinAggregateOutputType = {
  __typename?: 'UserImpersonationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  impersonatedUserId?: Maybe<SortOrder>;
  impersonatedInformPartnerUserId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
};

export type UserImpersonationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserImpersonationOrderByRelevanceFieldEnum {
  Id = 'id',
  ImpersonatedUserId = 'impersonatedUserId',
  ImpersonatedInformPartnerUserId = 'impersonatedInformPartnerUserId',
  UserId = 'userId',
  InformPartnerUserId = 'informPartnerUserId'
}

export type UserImpersonationOrderByRelevanceInput = {
  fields: Array<UserImpersonationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type UserImpersonationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  impersonatedUserId?: Maybe<SortOrder>;
  impersonatedInformPartnerUserId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  _count?: Maybe<UserImpersonationCountOrderByAggregateInput>;
  _max?: Maybe<UserImpersonationMaxOrderByAggregateInput>;
  _min?: Maybe<UserImpersonationMinOrderByAggregateInput>;
};

export type UserImpersonationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  impersonatedUserId?: Maybe<SortOrder>;
  impersonatedInformPartnerUserId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  impersonatedUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerUser?: Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<UserImpersonationOrderByRelevanceInput>;
};

export enum UserImpersonationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ImpersonatedUserId = 'impersonatedUserId',
  ImpersonatedInformPartnerUserId = 'impersonatedInformPartnerUserId',
  UserId = 'userId',
  InformPartnerUserId = 'informPartnerUserId'
}

export type UserImpersonationScalarWhereInput = {
  AND?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  OR?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  NOT?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  impersonatedUserId?: Maybe<StringNullableFilter>;
  impersonatedInformPartnerUserId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  informPartnerUserId?: Maybe<StringNullableFilter>;
};

export type UserImpersonationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserImpersonationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserImpersonationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserImpersonationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  impersonatedUserId?: Maybe<StringNullableWithAggregatesFilter>;
  impersonatedInformPartnerUserId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  informPartnerUserId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type UserImpersonationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedInformPartnerUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedInformPartnerUserInputEnvelope>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
};

export type UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedUserInputEnvelope>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
};

export type UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutInformPartnerUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyInformPartnerUserInputEnvelope>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
};

export type UserImpersonationUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
};

export type UserImpersonationUncheckedCreateWithoutImpersonatedInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedCreateWithoutImpersonatedUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedCreateWithoutInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedInformPartnerUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedInformPartnerUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedInformPartnerUserInputEnvelope>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedInformPartnerUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutImpersonatedInformPartnerUserInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
};

export type UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedUserInputEnvelope>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutImpersonatedUserInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
};

export type UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutInformPartnerUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutInformPartnerUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyInformPartnerUserInputEnvelope>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutInformPartnerUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutInformPartnerUserInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
};

export type UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
};

export type UserImpersonationUncheckedUpdateWithoutImpersonatedInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateWithoutImpersonatedUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateWithoutInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUser?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdNestedInput>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdNestedInput>;
  user?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_User_IdNestedInput>;
  informPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdNestedInput>;
};

export type UserImpersonationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserImpersonationUpdateManyWithWhereWithoutImpersonatedInformPartnerUserInput = {
  where: UserImpersonationScalarWhereInput;
  data: UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput;
};

export type UserImpersonationUpdateManyWithWhereWithoutImpersonatedUserInput = {
  where: UserImpersonationScalarWhereInput;
  data: UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput;
};

export type UserImpersonationUpdateManyWithWhereWithoutInformPartnerUserInput = {
  where: UserImpersonationScalarWhereInput;
  data: UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput;
};

export type UserImpersonationUpdateManyWithWhereWithoutUserInput = {
  where: UserImpersonationScalarWhereInput;
  data: UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_UserToUserImpersonation_User_IdInput;
};

export type UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedInformPartnerUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedInformPartnerUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedInformPartnerUserInputEnvelope>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedInformPartnerUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutImpersonatedInformPartnerUserInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
};

export type UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedUserInputEnvelope>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutImpersonatedUserInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
};

export type UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutInformPartnerUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutInformPartnerUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyInformPartnerUserInputEnvelope>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutInformPartnerUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutInformPartnerUserInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
};

export type UserImpersonationUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
};

export type UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedInformPartnerUserInput = {
  where: UserImpersonationWhereUniqueInput;
  data: UserImpersonationUncheckedUpdateWithoutImpersonatedInformPartnerUserInput;
};

export type UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedUserInput = {
  where: UserImpersonationWhereUniqueInput;
  data: UserImpersonationUncheckedUpdateWithoutImpersonatedUserInput;
};

export type UserImpersonationUpdateWithWhereUniqueWithoutInformPartnerUserInput = {
  where: UserImpersonationWhereUniqueInput;
  data: UserImpersonationUncheckedUpdateWithoutInformPartnerUserInput;
};

export type UserImpersonationUpdateWithWhereUniqueWithoutUserInput = {
  where: UserImpersonationWhereUniqueInput;
  data: UserImpersonationUncheckedUpdateWithoutUserInput;
};

export type UserImpersonationUpdateWithoutImpersonatedInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUser?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdNestedInput>;
  user?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_User_IdNestedInput>;
  informPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdNestedInput>;
};

export type UserImpersonationUpdateWithoutImpersonatedUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdNestedInput>;
  user?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_User_IdNestedInput>;
  informPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdNestedInput>;
};

export type UserImpersonationUpdateWithoutInformPartnerUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUser?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdNestedInput>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdNestedInput>;
  user?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_User_IdNestedInput>;
};

export type UserImpersonationUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUser?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdNestedInput>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdNestedInput>;
  informPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdNestedInput>;
};

export type UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedInformPartnerUserInput = {
  where: UserImpersonationWhereUniqueInput;
  update: UserImpersonationUncheckedUpdateWithoutImpersonatedInformPartnerUserInput;
  create: UserImpersonationUncheckedCreateWithoutImpersonatedInformPartnerUserInput;
};

export type UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedUserInput = {
  where: UserImpersonationWhereUniqueInput;
  update: UserImpersonationUncheckedUpdateWithoutImpersonatedUserInput;
  create: UserImpersonationUncheckedCreateWithoutImpersonatedUserInput;
};

export type UserImpersonationUpsertWithWhereUniqueWithoutInformPartnerUserInput = {
  where: UserImpersonationWhereUniqueInput;
  update: UserImpersonationUncheckedUpdateWithoutInformPartnerUserInput;
  create: UserImpersonationUncheckedCreateWithoutInformPartnerUserInput;
};

export type UserImpersonationUpsertWithWhereUniqueWithoutUserInput = {
  where: UserImpersonationWhereUniqueInput;
  update: UserImpersonationUncheckedUpdateWithoutUserInput;
  create: UserImpersonationUncheckedCreateWithoutUserInput;
};

export type UserImpersonationWhereInput = {
  AND?: Maybe<Array<UserImpersonationWhereInput>>;
  OR?: Maybe<Array<UserImpersonationWhereInput>>;
  NOT?: Maybe<Array<UserImpersonationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  impersonatedUserId?: Maybe<StringNullableFilter>;
  impersonatedInformPartnerUserId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  informPartnerUserId?: Maybe<StringNullableFilter>;
  impersonatedUser?: Maybe<UserWhereInput>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserWhereInput>;
  user?: Maybe<UserWhereInput>;
  informPartnerUser?: Maybe<InformPartnerUserWhereInput>;
};

export type UserImpersonationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type UserListRelationFilter = {
  every?: Maybe<UserWhereInput>;
  some?: Maybe<UserWhereInput>;
  none?: Maybe<UserWhereInput>;
};

export type UserMaxAggregateOutputType = {
  __typename?: 'UserMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
};

export type UserMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  signUpDate?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  profilePic?: Maybe<SortOrder>;
  ownerInfoId?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  invitedAt?: Maybe<SortOrder>;
  invitationCode?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  invitedById?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
};

export type UserMinAggregateOutputType = {
  __typename?: 'UserMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
};

export type UserMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  signUpDate?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  profilePic?: Maybe<SortOrder>;
  ownerInfoId?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  invitedAt?: Maybe<SortOrder>;
  invitationCode?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  invitedById?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
};

export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  settingType: NotificationSettingType;
  smsEnabled: Scalars['Boolean'];
  emailEnabled: Scalars['Boolean'];
  pushNotificationEnabled: Scalars['Boolean'];
  userSettingId?: Maybe<Scalars['String']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSetting?: Maybe<UserSetting>;
};

export type UserNotificationSettingCountAggregateOutputType = {
  __typename?: 'UserNotificationSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  settingType: Scalars['Int'];
  smsEnabled: Scalars['Int'];
  emailEnabled: Scalars['Int'];
  pushNotificationEnabled: Scalars['Int'];
  userSettingId: Scalars['Int'];
  soundNotificationUrl: Scalars['Int'];
  browserNotificationEnabled: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserNotificationSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  soundNotificationUrl?: Maybe<SortOrder>;
  browserNotificationEnabled?: Maybe<SortOrder>;
};

export type UserNotificationSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutNotificationSettingsInput>;
};

export type UserNotificationSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingCreateManyUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingCreateManyUserSettingInputEnvelope = {
  data: Array<UserNotificationSettingCreateManyUserSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingCreateNestedManyWithoutUserSettingInput = {
  create?: Maybe<Array<UserNotificationSettingCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserNotificationSettingCreateOrConnectWithoutUserSettingInput>>;
  createMany?: Maybe<UserNotificationSettingCreateManyUserSettingInputEnvelope>;
  connect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
};

export type UserNotificationSettingCreateOrConnectWithoutUserSettingInput = {
  where: UserNotificationSettingWhereUniqueInput;
  create: UserNotificationSettingUncheckedCreateWithoutUserSettingInput;
};

export type UserNotificationSettingCreateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingListRelationFilter = {
  every?: Maybe<UserNotificationSettingWhereInput>;
  some?: Maybe<UserNotificationSettingWhereInput>;
  none?: Maybe<UserNotificationSettingWhereInput>;
};

export type UserNotificationSettingMaxAggregateOutputType = {
  __typename?: 'UserNotificationSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  soundNotificationUrl?: Maybe<SortOrder>;
  browserNotificationEnabled?: Maybe<SortOrder>;
};

export type UserNotificationSettingMinAggregateOutputType = {
  __typename?: 'UserNotificationSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  soundNotificationUrl?: Maybe<SortOrder>;
  browserNotificationEnabled?: Maybe<SortOrder>;
};

export type UserNotificationSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserNotificationSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  UserSettingId = 'userSettingId',
  SoundNotificationUrl = 'soundNotificationUrl'
}

export type UserNotificationSettingOrderByRelevanceInput = {
  fields: Array<UserNotificationSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type UserNotificationSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  soundNotificationUrl?: Maybe<SortOrder>;
  browserNotificationEnabled?: Maybe<SortOrder>;
  _count?: Maybe<UserNotificationSettingCountOrderByAggregateInput>;
  _max?: Maybe<UserNotificationSettingMaxOrderByAggregateInput>;
  _min?: Maybe<UserNotificationSettingMinOrderByAggregateInput>;
};

export type UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  soundNotificationUrl?: Maybe<SortOrder>;
  browserNotificationEnabled?: Maybe<SortOrder>;
  userSetting?: Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<UserNotificationSettingOrderByRelevanceInput>;
};

export enum UserNotificationSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  SettingType = 'settingType',
  SmsEnabled = 'smsEnabled',
  EmailEnabled = 'emailEnabled',
  PushNotificationEnabled = 'pushNotificationEnabled',
  UserSettingId = 'userSettingId',
  SoundNotificationUrl = 'soundNotificationUrl',
  BrowserNotificationEnabled = 'browserNotificationEnabled'
}

export type UserNotificationSettingScalarWhereInput = {
  AND?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
  OR?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
  NOT?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  settingType?: Maybe<EnumNotificationSettingTypeFilter>;
  smsEnabled?: Maybe<BoolFilter>;
  emailEnabled?: Maybe<BoolFilter>;
  pushNotificationEnabled?: Maybe<BoolFilter>;
  userSettingId?: Maybe<StringNullableFilter>;
  soundNotificationUrl?: Maybe<StringNullableFilter>;
  browserNotificationEnabled?: Maybe<BoolNullableFilter>;
};

export type UserNotificationSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserNotificationSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserNotificationSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserNotificationSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  settingType?: Maybe<EnumNotificationSettingTypeWithAggregatesFilter>;
  smsEnabled?: Maybe<BoolWithAggregatesFilter>;
  emailEnabled?: Maybe<BoolWithAggregatesFilter>;
  pushNotificationEnabled?: Maybe<BoolWithAggregatesFilter>;
  userSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  soundNotificationUrl?: Maybe<StringNullableWithAggregatesFilter>;
  browserNotificationEnabled?: Maybe<BoolNullableWithAggregatesFilter>;
};

export type UserNotificationSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUncheckedCreateNestedManyWithoutUserSettingInput = {
  create?: Maybe<Array<UserNotificationSettingCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserNotificationSettingCreateOrConnectWithoutUserSettingInput>>;
  createMany?: Maybe<UserNotificationSettingCreateManyUserSettingInputEnvelope>;
  connect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
};

export type UserNotificationSettingUncheckedCreateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUncheckedUpdateManyWithoutNotificationSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUncheckedUpdateManyWithoutUserSettingNestedInput = {
  create?: Maybe<Array<UserNotificationSettingCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserNotificationSettingCreateOrConnectWithoutUserSettingInput>>;
  upsert?: Maybe<Array<UserNotificationSettingUpsertWithWhereUniqueWithoutUserSettingInput>>;
  createMany?: Maybe<UserNotificationSettingCreateManyUserSettingInputEnvelope>;
  set?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  delete?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  connect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  update?: Maybe<Array<UserNotificationSettingUpdateWithWhereUniqueWithoutUserSettingInput>>;
  updateMany?: Maybe<Array<UserNotificationSettingUpdateManyWithWhereWithoutUserSettingInput>>;
  deleteMany?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
};

export type UserNotificationSettingUncheckedUpdateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutNotificationSettingsNestedInput>;
};

export type UserNotificationSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUpdateManyWithWhereWithoutUserSettingInput = {
  where: UserNotificationSettingScalarWhereInput;
  data: UserNotificationSettingUncheckedUpdateManyWithoutNotificationSettingsInput;
};

export type UserNotificationSettingUpdateManyWithoutUserSettingNestedInput = {
  create?: Maybe<Array<UserNotificationSettingCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserNotificationSettingCreateOrConnectWithoutUserSettingInput>>;
  upsert?: Maybe<Array<UserNotificationSettingUpsertWithWhereUniqueWithoutUserSettingInput>>;
  createMany?: Maybe<UserNotificationSettingCreateManyUserSettingInputEnvelope>;
  set?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  delete?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  connect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  update?: Maybe<Array<UserNotificationSettingUpdateWithWhereUniqueWithoutUserSettingInput>>;
  updateMany?: Maybe<Array<UserNotificationSettingUpdateManyWithWhereWithoutUserSettingInput>>;
  deleteMany?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
};

export type UserNotificationSettingUpdateWithWhereUniqueWithoutUserSettingInput = {
  where: UserNotificationSettingWhereUniqueInput;
  data: UserNotificationSettingUncheckedUpdateWithoutUserSettingInput;
};

export type UserNotificationSettingUpdateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUpsertWithWhereUniqueWithoutUserSettingInput = {
  where: UserNotificationSettingWhereUniqueInput;
  update: UserNotificationSettingUncheckedUpdateWithoutUserSettingInput;
  create: UserNotificationSettingUncheckedCreateWithoutUserSettingInput;
};

export type UserNotificationSettingWhereInput = {
  AND?: Maybe<Array<UserNotificationSettingWhereInput>>;
  OR?: Maybe<Array<UserNotificationSettingWhereInput>>;
  NOT?: Maybe<Array<UserNotificationSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  settingType?: Maybe<EnumNotificationSettingTypeFilter>;
  smsEnabled?: Maybe<BoolFilter>;
  emailEnabled?: Maybe<BoolFilter>;
  pushNotificationEnabled?: Maybe<BoolFilter>;
  userSettingId?: Maybe<StringNullableFilter>;
  soundNotificationUrl?: Maybe<StringNullableFilter>;
  browserNotificationEnabled?: Maybe<BoolNullableFilter>;
  userSetting?: Maybe<UserSettingWhereInput>;
};

export type UserNotificationSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserOrderByRelevanceFieldEnum {
  Id = 'id',
  Email = 'email',
  Phone = 'phone',
  FirebaseId = 'firebaseId',
  ProfilePic = 'profilePic',
  OwnerInfoId = 'ownerInfoId',
  VetInfoId = 'vetInfoId',
  FirstName = 'firstName',
  LastName = 'lastName',
  UserSettingId = 'userSettingId',
  NameDisplay = 'nameDisplay',
  NamePrefix = 'namePrefix',
  NameSuffix = 'nameSuffix',
  InvitationCode = 'invitationCode',
  InvitedById = 'invitedById',
  MediaId = 'mediaId',
  CrmId = 'crmId'
}

export type UserOrderByRelevanceInput = {
  fields: Array<UserOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type UserOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  signUpDate?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  profilePic?: Maybe<SortOrder>;
  ownerInfoId?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  invitedAt?: Maybe<SortOrder>;
  invitationCode?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  invitedById?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  _count?: Maybe<UserCountOrderByAggregateInput>;
  _max?: Maybe<UserMaxOrderByAggregateInput>;
  _min?: Maybe<UserMinOrderByAggregateInput>;
};

export type UserOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  signUpDate?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  profilePic?: Maybe<SortOrder>;
  ownerInfoId?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  invitedAt?: Maybe<SortOrder>;
  invitationCode?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  invitedById?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  ownerInfo?: Maybe<OwnerInfoOrderByWithRelationAndSearchRelevanceInput>;
  invitedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userSetting?: Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>;
  vetInfo?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  channelMembers?: Maybe<ChannelMemberOrderByRelationAggregateInput>;
  channelStatuses?: Maybe<ChannelStatusOrderByRelationAggregateInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeOrderByRelationAggregateInput>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  clinicsId?: Maybe<ClinicOrderByRelationAggregateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  consultationChats?: Maybe<ConsultationChatOrderByRelationAggregateInput>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  invitations?: Maybe<UserOrderByRelationAggregateInput>;
  refundsIssued?: Maybe<StripeRefundOrderByRelationAggregateInput>;
  reviews?: Maybe<ReviewOrderByRelationAggregateInput>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  media?: Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationOrderByRelationAggregateInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationOrderByRelationAggregateInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  sentInvoices?: Maybe<InvoiceOrderByRelationAggregateInput>;
  calls?: Maybe<CallParticipantOrderByRelationAggregateInput>;
  channelSelections?: Maybe<ChannelSelectionOrderByRelationAggregateInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  usagesRecorded?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionOrderByRelationAggregateInput>;
  messageTemplates?: Maybe<MessageTemplateOrderByRelationAggregateInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageOrderByRelationAggregateInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionOrderByRelationAggregateInput>;
  massTextAlerts?: Maybe<MassTextAlertOrderByRelationAggregateInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberOrderByRelationAggregateInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByRelationAggregateInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerOrderByRelationAggregateInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantOrderByRelationAggregateInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionOrderByRelationAggregateInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepOrderByRelationAggregateInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferOrderByRelationAggregateInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodOrderByRelationAggregateInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceOrderByRelationAggregateInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignOrderByRelationAggregateInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignOrderByRelationAggregateInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignOrderByRelationAggregateInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateOrderByRelationAggregateInput>;
  EmailCampaign?: Maybe<EmailCampaignOrderByRelationAggregateInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberOrderByRelationAggregateInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberOrderByRelationAggregateInput>;
  pinnedChannels?: Maybe<ChannelPinOrderByRelationAggregateInput>;
  boardFilters?: Maybe<UserBoardFilterOrderByRelationAggregateInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentOrderByRelationAggregateInput>;
  _relevance?: Maybe<UserOrderByRelevanceInput>;
};

export type UserRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Email = 'email',
  Phone = 'phone',
  SignUpDate = 'signUpDate',
  Type = 'type',
  FirebaseId = 'firebaseId',
  ProfilePic = 'profilePic',
  OwnerInfoId = 'ownerInfoId',
  VetInfoId = 'vetInfoId',
  IsAdmin = 'isAdmin',
  UpdatedAt = 'updatedAt',
  FirstName = 'firstName',
  LastName = 'lastName',
  IsActive = 'isActive',
  UserSettingId = 'userSettingId',
  NameDisplay = 'nameDisplay',
  NamePrefix = 'namePrefix',
  NameSuffix = 'nameSuffix',
  InvitedAt = 'invitedAt',
  InvitationCode = 'invitationCode',
  IsNewPasswordRequired = 'isNewPasswordRequired',
  InvitedById = 'invitedById',
  MediaId = 'mediaId',
  CrmId = 'crmId'
}

export type UserScalarWhereInput = {
  AND?: Maybe<Array<UserScalarWhereInput>>;
  OR?: Maybe<Array<UserScalarWhereInput>>;
  NOT?: Maybe<Array<UserScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  phone?: Maybe<StringNullableFilter>;
  signUpDate?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<EnumUserTypeFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  profilePic?: Maybe<StringNullableFilter>;
  ownerInfoId?: Maybe<StringNullableFilter>;
  vetInfoId?: Maybe<StringNullableFilter>;
  isAdmin?: Maybe<BoolNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  userSettingId?: Maybe<StringNullableFilter>;
  nameDisplay?: Maybe<StringNullableFilter>;
  namePrefix?: Maybe<StringNullableFilter>;
  nameSuffix?: Maybe<StringNullableFilter>;
  invitedAt?: Maybe<DateTimeNullableFilter>;
  invitationCode?: Maybe<StringNullableFilter>;
  isNewPasswordRequired?: Maybe<BoolFilter>;
  invitedById?: Maybe<StringNullableFilter>;
  mediaId?: Maybe<StringNullableFilter>;
  crmId?: Maybe<StringNullableFilter>;
};

export type UserScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  phone?: Maybe<StringNullableWithAggregatesFilter>;
  signUpDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  type?: Maybe<EnumUserTypeWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  profilePic?: Maybe<StringNullableWithAggregatesFilter>;
  ownerInfoId?: Maybe<StringNullableWithAggregatesFilter>;
  vetInfoId?: Maybe<StringNullableWithAggregatesFilter>;
  isAdmin?: Maybe<BoolNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  firstName?: Maybe<StringWithAggregatesFilter>;
  lastName?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  userSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  nameDisplay?: Maybe<StringNullableWithAggregatesFilter>;
  namePrefix?: Maybe<StringNullableWithAggregatesFilter>;
  nameSuffix?: Maybe<StringNullableWithAggregatesFilter>;
  invitedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  invitationCode?: Maybe<StringNullableWithAggregatesFilter>;
  isNewPasswordRequired?: Maybe<BoolWithAggregatesFilter>;
  invitedById?: Maybe<StringNullableWithAggregatesFilter>;
  mediaId?: Maybe<StringNullableWithAggregatesFilter>;
  crmId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pushNotifications: Scalars['Boolean'];
  emailNotifications: Scalars['Boolean'];
  smsNotifications: Scalars['Boolean'];
  leftNavPanel: Scalars['Boolean'];
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  enterAddsNewLine: Scalars['Boolean'];
  skipLoadingAnimation: Scalars['Boolean'];
  user: Array<User>;
  notificationSettings: Array<UserNotificationSetting>;
  browserNotificationFilter?: Maybe<ChannelSelection>;
  _count: UserSettingCountOutputType;
};


export type UserSettingUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};


export type UserSettingNotificationSettingsArgs = {
  where?: Maybe<UserNotificationSettingWhereInput>;
  orderBy?: Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserNotificationSettingScalarFieldEnum>;
};

export type UserSettingCountAggregateOutputType = {
  __typename?: 'UserSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  pushNotifications: Scalars['Int'];
  emailNotifications: Scalars['Int'];
  smsNotifications: Scalars['Int'];
  leftNavPanel: Scalars['Int'];
  snoozeNotificationsUntil: Scalars['Int'];
  browserNotificationFilterId: Scalars['Int'];
  enterAddsNewLine: Scalars['Int'];
  skipLoadingAnimation: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  leftNavPanel?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  browserNotificationFilterId?: Maybe<SortOrder>;
  enterAddsNewLine?: Maybe<SortOrder>;
  skipLoadingAnimation?: Maybe<SortOrder>;
};

export type UserSettingCountOutputType = {
  __typename?: 'UserSettingCountOutputType';
  user: Scalars['Int'];
  notificationSettings: Scalars['Int'];
};

export type UserSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserCreateNestedManyWithoutUserSettingInput>;
  notificationSettings?: Maybe<UserNotificationSettingCreateNestedManyWithoutUserSettingInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionCreateNestedOneWithoutUserSettingInput>;
};

export type UserSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
};

export type UserSettingCreateNestedOneWithoutBrowserNotificationFilterInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
};

export type UserSettingCreateNestedOneWithoutNotificationSettingsInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutNotificationSettingsInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutNotificationSettingsInput>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
};

export type UserSettingCreateNestedOneWithoutUserInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutUserInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutUserInput>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
};

export type UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput = {
  where: UserSettingWhereUniqueInput;
  create: UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput;
};

export type UserSettingCreateOrConnectWithoutNotificationSettingsInput = {
  where: UserSettingWhereUniqueInput;
  create: UserSettingUncheckedCreateWithoutNotificationSettingsInput;
};

export type UserSettingCreateOrConnectWithoutUserInput = {
  where: UserSettingWhereUniqueInput;
  create: UserSettingUncheckedCreateWithoutUserInput;
};

export type UserSettingCreateWithoutBrowserNotificationFilterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserCreateNestedManyWithoutUserSettingInput>;
  notificationSettings?: Maybe<UserNotificationSettingCreateNestedManyWithoutUserSettingInput>;
};

export type UserSettingCreateWithoutNotificationSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserCreateNestedManyWithoutUserSettingInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionCreateNestedOneWithoutUserSettingInput>;
};

export type UserSettingCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingCreateNestedManyWithoutUserSettingInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionCreateNestedOneWithoutUserSettingInput>;
};

export type UserSettingMaxAggregateOutputType = {
  __typename?: 'UserSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
};

export type UserSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  leftNavPanel?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  browserNotificationFilterId?: Maybe<SortOrder>;
  enterAddsNewLine?: Maybe<SortOrder>;
  skipLoadingAnimation?: Maybe<SortOrder>;
};

export type UserSettingMinAggregateOutputType = {
  __typename?: 'UserSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
};

export type UserSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  leftNavPanel?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  browserNotificationFilterId?: Maybe<SortOrder>;
  enterAddsNewLine?: Maybe<SortOrder>;
  skipLoadingAnimation?: Maybe<SortOrder>;
};

export enum UserSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  BrowserNotificationFilterId = 'browserNotificationFilterId'
}

export type UserSettingOrderByRelevanceInput = {
  fields: Array<UserSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type UserSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  leftNavPanel?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  browserNotificationFilterId?: Maybe<SortOrder>;
  enterAddsNewLine?: Maybe<SortOrder>;
  skipLoadingAnimation?: Maybe<SortOrder>;
  _count?: Maybe<UserSettingCountOrderByAggregateInput>;
  _max?: Maybe<UserSettingMaxOrderByAggregateInput>;
  _min?: Maybe<UserSettingMinOrderByAggregateInput>;
};

export type UserSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  leftNavPanel?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  browserNotificationFilterId?: Maybe<SortOrder>;
  enterAddsNewLine?: Maybe<SortOrder>;
  skipLoadingAnimation?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByRelationAggregateInput>;
  notificationSettings?: Maybe<UserNotificationSettingOrderByRelationAggregateInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<UserSettingOrderByRelevanceInput>;
};

export type UserSettingRelationFilter = {
  is?: Maybe<UserSettingWhereInput>;
  isNot?: Maybe<UserSettingWhereInput>;
};

export enum UserSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PushNotifications = 'pushNotifications',
  EmailNotifications = 'emailNotifications',
  SmsNotifications = 'smsNotifications',
  LeftNavPanel = 'leftNavPanel',
  SnoozeNotificationsUntil = 'snoozeNotificationsUntil',
  BrowserNotificationFilterId = 'browserNotificationFilterId',
  EnterAddsNewLine = 'enterAddsNewLine',
  SkipLoadingAnimation = 'skipLoadingAnimation'
}

export type UserSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  pushNotifications?: Maybe<BoolWithAggregatesFilter>;
  emailNotifications?: Maybe<BoolWithAggregatesFilter>;
  smsNotifications?: Maybe<BoolWithAggregatesFilter>;
  leftNavPanel?: Maybe<BoolWithAggregatesFilter>;
  snoozeNotificationsUntil?: Maybe<DateTimeNullableWithAggregatesFilter>;
  browserNotificationFilterId?: Maybe<StringNullableWithAggregatesFilter>;
  enterAddsNewLine?: Maybe<BoolWithAggregatesFilter>;
  skipLoadingAnimation?: Maybe<BoolWithAggregatesFilter>;
};

export type UserSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUncheckedCreateNestedManyWithoutUserSettingInput>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedCreateNestedManyWithoutUserSettingInput>;
};

export type UserSettingUncheckedCreateNestedOneWithoutBrowserNotificationFilterInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
};

export type UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUncheckedCreateNestedManyWithoutUserSettingInput>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedCreateNestedManyWithoutUserSettingInput>;
};

export type UserSettingUncheckedCreateWithoutNotificationSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUncheckedCreateNestedManyWithoutUserSettingInput>;
};

export type UserSettingUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedCreateNestedManyWithoutUserSettingInput>;
};

export type UserSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUncheckedUpdateManyWithoutUserSettingNestedInput>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedUpdateManyWithoutUserSettingNestedInput>;
};

export type UserSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
};

export type UserSettingUncheckedUpdateOneWithoutBrowserNotificationFilterNestedInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput>;
  upsert?: Maybe<UserSettingUpsertWithoutBrowserNotificationFilterInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
  update?: Maybe<UserSettingUncheckedUpdateWithoutBrowserNotificationFilterInput>;
};

export type UserSettingUncheckedUpdateWithoutBrowserNotificationFilterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUncheckedUpdateManyWithoutUserSettingNestedInput>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedUpdateManyWithoutUserSettingNestedInput>;
};

export type UserSettingUncheckedUpdateWithoutNotificationSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUncheckedUpdateManyWithoutUserSettingNestedInput>;
};

export type UserSettingUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedUpdateManyWithoutUserSettingNestedInput>;
};

export type UserSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUpdateManyWithoutUserSettingNestedInput>;
  notificationSettings?: Maybe<UserNotificationSettingUpdateManyWithoutUserSettingNestedInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionUpdateOneWithoutUserSettingNestedInput>;
};

export type UserSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
};

export type UserSettingUpdateOneWithoutBrowserNotificationFilterNestedInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput>;
  upsert?: Maybe<UserSettingUpsertWithoutBrowserNotificationFilterInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
  update?: Maybe<UserSettingUncheckedUpdateWithoutBrowserNotificationFilterInput>;
};

export type UserSettingUpdateOneWithoutNotificationSettingsNestedInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutNotificationSettingsInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutNotificationSettingsInput>;
  upsert?: Maybe<UserSettingUpsertWithoutNotificationSettingsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
  update?: Maybe<UserSettingUncheckedUpdateWithoutNotificationSettingsInput>;
};

export type UserSettingUpdateOneWithoutUserNestedInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutUserInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutUserInput>;
  upsert?: Maybe<UserSettingUpsertWithoutUserInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
  update?: Maybe<UserSettingUncheckedUpdateWithoutUserInput>;
};

export type UserSettingUpdateWithoutBrowserNotificationFilterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUpdateManyWithoutUserSettingNestedInput>;
  notificationSettings?: Maybe<UserNotificationSettingUpdateManyWithoutUserSettingNestedInput>;
};

export type UserSettingUpdateWithoutNotificationSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUpdateManyWithoutUserSettingNestedInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionUpdateOneWithoutUserSettingNestedInput>;
};

export type UserSettingUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingUpdateManyWithoutUserSettingNestedInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionUpdateOneWithoutUserSettingNestedInput>;
};

export type UserSettingUpsertWithoutBrowserNotificationFilterInput = {
  update: UserSettingUncheckedUpdateWithoutBrowserNotificationFilterInput;
  create: UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput;
};

export type UserSettingUpsertWithoutNotificationSettingsInput = {
  update: UserSettingUncheckedUpdateWithoutNotificationSettingsInput;
  create: UserSettingUncheckedCreateWithoutNotificationSettingsInput;
};

export type UserSettingUpsertWithoutUserInput = {
  update: UserSettingUncheckedUpdateWithoutUserInput;
  create: UserSettingUncheckedCreateWithoutUserInput;
};

export type UserSettingWhereInput = {
  AND?: Maybe<Array<UserSettingWhereInput>>;
  OR?: Maybe<Array<UserSettingWhereInput>>;
  NOT?: Maybe<Array<UserSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  pushNotifications?: Maybe<BoolFilter>;
  emailNotifications?: Maybe<BoolFilter>;
  smsNotifications?: Maybe<BoolFilter>;
  leftNavPanel?: Maybe<BoolFilter>;
  snoozeNotificationsUntil?: Maybe<DateTimeNullableFilter>;
  browserNotificationFilterId?: Maybe<StringNullableFilter>;
  enterAddsNewLine?: Maybe<BoolFilter>;
  skipLoadingAnimation?: Maybe<BoolFilter>;
  user?: Maybe<UserListRelationFilter>;
  notificationSettings?: Maybe<UserNotificationSettingListRelationFilter>;
  browserNotificationFilter?: Maybe<ChannelSelectionWhereInput>;
};

export type UserSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
};

export enum UserType {
  Owner = 'OWNER',
  Vet = 'VET'
}

export type UserUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelMessagesWithMentionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelMessagesWithMentionsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutChannelSelectionsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelSelectionsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutChannelsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput = {
  create?: Maybe<Array<UserCreateWithoutClinicWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicWidgetRequestTypesInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<UserCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutFormSubmissionsInput = {
  create?: Maybe<Array<UserCreateWithoutFormSubmissionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFormSubmissionsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutInvitedByInput = {
  create?: Maybe<Array<UserCreateWithoutInvitedByInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutInvitedByInput>>;
  createMany?: Maybe<UserCreateManyInvitedByInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutMediaInput = {
  create?: Maybe<Array<UserCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutMediaInput>>;
  createMany?: Maybe<UserCreateManyMediaInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutUserSettingInput = {
  create?: Maybe<Array<UserCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutUserSettingInput>>;
  createMany?: Maybe<UserCreateManyUserSettingInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedOneWithoutOwnerInfoInput = {
  create?: Maybe<UserUncheckedCreateWithoutOwnerInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOwnerInfoInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUncheckedCreateNestedOneWithoutVetInfoInput = {
  create?: Maybe<UserUncheckedCreateWithoutVetInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVetInfoInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUncheckedCreateWithoutBlockedNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutBoardFiltersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutChannelMessageReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutChannelMessagesWithMentionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutClinicsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutCompletedBenefitUsageFollowupStepsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutConsultationChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutCreatedPayoutBatchingPeriodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutEmailCampaignTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutEmailCampaignUpdatedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutEmailCampaignsCreatedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutEmailCampaignsSentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutEmailTemplateVersionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
};

export type UserUncheckedCreateWithoutIntitiatedLoyaltyAccountMergesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutInvitationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutInvitedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutLoaSignedClinicPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutLoyaltyPointUserGrantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutManuallySentAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutOwnerInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutPinnedChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutPointOfContactEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutRefundsIssuedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutRequestedEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutResponderEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutSentInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutSentStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutSentTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutTriggeredWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutUnblockedNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutUsagesRecordedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutValidatedCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedCreateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
};

export type UserUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput = {
  create?: Maybe<Array<UserCreateWithoutChannelMessagesWithMentionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelMessagesWithMentionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelMessagesWithMentionsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelMessagesWithMentionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelMessagesWithMentionsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutChannelSelectionsNestedInput = {
  create?: Maybe<Array<UserCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelSelectionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelSelectionsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelSelectionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelSelectionsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutChannelsNestedInput = {
  create?: Maybe<Array<UserCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesNestedInput = {
  create?: Maybe<Array<UserCreateWithoutClinicWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicWidgetRequestTypesInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutClinicWidgetRequestTypesInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutClinicWidgetRequestTypesInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutClinicWidgetRequestTypesInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutClinicsNestedInput = {
  create?: Maybe<Array<UserCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput = {
  create?: Maybe<Array<UserCreateWithoutFormSubmissionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFormSubmissionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutFormSubmissionsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutFormSubmissionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutFormSubmissionsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutInvitationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyWithoutInvitedByNestedInput = {
  create?: Maybe<Array<UserCreateWithoutInvitedByInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutInvitedByInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutInvitedByInput>>;
  createMany?: Maybe<UserCreateManyInvitedByInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutInvitedByInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutInvitedByInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutMediaNestedInput = {
  create?: Maybe<Array<UserCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutMediaInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutMediaInput>>;
  createMany?: Maybe<UserCreateManyMediaInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutMediaInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutMentionedUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyWithoutUserSettingNestedInput = {
  create?: Maybe<Array<UserCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutUserSettingInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutUserSettingInput>>;
  createMany?: Maybe<UserCreateManyUserSettingInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutUserSettingInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutUserSettingInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateOneWithoutOwnerInfoNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutOwnerInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOwnerInfoInput>;
  upsert?: Maybe<UserUpsertWithoutOwnerInfoInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutOwnerInfoInput>;
};

export type UserUncheckedUpdateOneWithoutVetInfoNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutVetInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVetInfoInput>;
  upsert?: Maybe<UserUpsertWithoutVetInfoInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutVetInfoInput>;
};

export type UserUncheckedUpdateWithoutBlockedNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutBoardFiltersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutChannelMessageReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutChannelMessagesWithMentionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutClinicsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutCompletedBenefitUsageFollowupStepsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutConsultationChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutCreatedPayoutBatchingPeriodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutEmailCampaignTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutEmailCampaignUpdatedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutEmailCampaignsCreatedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutEmailCampaignsSentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutEmailTemplateVersionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
};

export type UserUncheckedUpdateWithoutIntitiatedLoyaltyAccountMergesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutInvitationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutInvitedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutLoaSignedClinicPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutLoyaltyPointUserGrantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutLoyaltyRewardRedemptionActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutManuallySentAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutOwnerInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutPinnedChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutPointOfContactEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutRefundsIssuedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutRequestedEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutResponderEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutSentInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutSentStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutSentTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutTriggeredWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutUnblockedNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutUsagesRecordedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutValidatedCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUncheckedUpdateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
};

export type UserUpdateManyWithWhereWithoutChannelMessagesWithMentionsInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutMentionedUsersInput;
};

export type UserUpdateManyWithWhereWithoutChannelSelectionsInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutAssigneesInput;
};

export type UserUpdateManyWithWhereWithoutChannelsInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutAssigneesInput;
};

export type UserUpdateManyWithWhereWithoutClinicWidgetRequestTypesInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutAssigneesInput;
};

export type UserUpdateManyWithWhereWithoutClinicsInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutUsersInput;
};

export type UserUpdateManyWithWhereWithoutFormSubmissionsInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutUsersInput;
};

export type UserUpdateManyWithWhereWithoutInvitedByInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutInvitationsInput;
};

export type UserUpdateManyWithWhereWithoutMediaInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutUsersInput;
};

export type UserUpdateManyWithWhereWithoutUserSettingInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutUserInput;
};

export type UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput = {
  create?: Maybe<Array<UserCreateWithoutChannelMessagesWithMentionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelMessagesWithMentionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelMessagesWithMentionsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelMessagesWithMentionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelMessagesWithMentionsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutChannelSelectionsNestedInput = {
  create?: Maybe<Array<UserCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelSelectionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelSelectionsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelSelectionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelSelectionsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutChannelsNestedInput = {
  create?: Maybe<Array<UserCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutClinicWidgetRequestTypesNestedInput = {
  create?: Maybe<Array<UserCreateWithoutClinicWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicWidgetRequestTypesInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutClinicWidgetRequestTypesInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutClinicWidgetRequestTypesInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutClinicWidgetRequestTypesInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutClinicsNestedInput = {
  create?: Maybe<Array<UserCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutFormSubmissionsNestedInput = {
  create?: Maybe<Array<UserCreateWithoutFormSubmissionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFormSubmissionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutFormSubmissionsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutFormSubmissionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutFormSubmissionsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutInvitedByNestedInput = {
  create?: Maybe<Array<UserCreateWithoutInvitedByInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutInvitedByInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutInvitedByInput>>;
  createMany?: Maybe<UserCreateManyInvitedByInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutInvitedByInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutInvitedByInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutMediaNestedInput = {
  create?: Maybe<Array<UserCreateWithoutMediaInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutMediaInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutMediaInput>>;
  createMany?: Maybe<UserCreateManyMediaInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutMediaInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutUserSettingNestedInput = {
  create?: Maybe<Array<UserCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutUserSettingInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutUserSettingInput>>;
  createMany?: Maybe<UserCreateManyUserSettingInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutUserSettingInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutUserSettingInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateOneRequiredWithoutBoardFiltersNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutBoardFiltersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBoardFiltersInput>;
  upsert?: Maybe<UserUpsertWithoutBoardFiltersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutBoardFiltersInput>;
};

export type UserUpdateOneRequiredWithoutChannelMessageReactionsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelMessageReactionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelMessageReactionsInput>;
  upsert?: Maybe<UserUpsertWithoutChannelMessageReactionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelMessageReactionsInput>;
};

export type UserUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  upsert?: Maybe<UserUpsertWithoutChannelStatusChannelAssigneesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelStatusChannelAssigneesInput>;
};

export type UserUpdateOneRequiredWithoutIntitiatedLoyaltyAccountMergesNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutIntitiatedLoyaltyAccountMergesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutIntitiatedLoyaltyAccountMergesInput>;
  upsert?: Maybe<UserUpsertWithoutIntitiatedLoyaltyAccountMergesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutIntitiatedLoyaltyAccountMergesInput>;
};

export type UserUpdateOneRequiredWithoutLoyaltyPointUserGrantsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutLoyaltyPointUserGrantsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutLoyaltyPointUserGrantsInput>;
  upsert?: Maybe<UserUpsertWithoutLoyaltyPointUserGrantsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutLoyaltyPointUserGrantsInput>;
};

export type UserUpdateOneRequiredWithoutMassTextAlertsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutMassTextAlertsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMassTextAlertsInput>;
  upsert?: Maybe<UserUpsertWithoutMassTextAlertsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutMassTextAlertsInput>;
};

export type UserUpdateOneRequiredWithoutPinnedChannelsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutPinnedChannelsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPinnedChannelsInput>;
  upsert?: Maybe<UserUpsertWithoutPinnedChannelsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutPinnedChannelsInput>;
};

export type UserUpdateOneRequiredWithoutPointOfContactEnrollmentSupportEventsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutPointOfContactEnrollmentSupportEventsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPointOfContactEnrollmentSupportEventsInput>;
  upsert?: Maybe<UserUpsertWithoutPointOfContactEnrollmentSupportEventsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutPointOfContactEnrollmentSupportEventsInput>;
};

export type UserUpdateOneRequiredWithoutRequestedEnrollmentSupportEventsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutRequestedEnrollmentSupportEventsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutRequestedEnrollmentSupportEventsInput>;
  upsert?: Maybe<UserUpsertWithoutRequestedEnrollmentSupportEventsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutRequestedEnrollmentSupportEventsInput>;
};

export type UserUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutSentTrupanionExamDayOffersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentTrupanionExamDayOffersInput>;
  upsert?: Maybe<UserUpsertWithoutSentTrupanionExamDayOffersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutSentTrupanionExamDayOffersInput>;
};

export type UserUpdateOneRequiredWithoutUserChannelFilterSelectionsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserChannelFilterSelectionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserChannelFilterSelectionsInput>;
  upsert?: Maybe<UserUpsertWithoutUserChannelFilterSelectionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutUserChannelFilterSelectionsInput>;
};

export type UserUpdateOneWithoutBlockedNumbersNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutBlockedNumbersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBlockedNumbersInput>;
  upsert?: Maybe<UserUpsertWithoutBlockedNumbersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutBlockedNumbersInput>;
};

export type UserUpdateOneWithoutCallsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutCallsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCallsInput>;
  upsert?: Maybe<UserUpsertWithoutCallsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutCallsInput>;
};

export type UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  upsert?: Maybe<UserUpsertWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutCareBenefitUsageToInvoiceLineItemsInput>;
};

export type UserUpdateOneWithoutChannelCreationSourcesNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<UserUpsertWithoutChannelCreationSourcesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelCreationSourcesInput>;
};

export type UserUpdateOneWithoutChannelMembersNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelMembersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelMembersInput>;
  upsert?: Maybe<UserUpsertWithoutChannelMembersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelMembersInput>;
};

export type UserUpdateOneWithoutChannelStatusesNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelStatusesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelStatusesInput>;
  upsert?: Maybe<UserUpsertWithoutChannelStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelStatusesInput>;
};

export type UserUpdateOneWithoutClinicPetParentsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutClinicPetParentsInput>;
  upsert?: Maybe<UserUpsertWithoutClinicPetParentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutClinicPetParentsInput>;
};

export type UserUpdateOneWithoutClinicsIdNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutClinicsIdInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutClinicsIdInput>;
  upsert?: Maybe<UserUpsertWithoutClinicsIdInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutClinicsIdInput>;
};

export type UserUpdateOneWithoutCompletedBenefitUsageFollowupStepsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutCompletedBenefitUsageFollowupStepsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCompletedBenefitUsageFollowupStepsInput>;
  upsert?: Maybe<UserUpsertWithoutCompletedBenefitUsageFollowupStepsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutCompletedBenefitUsageFollowupStepsInput>;
};

export type UserUpdateOneWithoutConsultationChatsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutConsultationChatsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutConsultationChatsInput>;
  upsert?: Maybe<UserUpsertWithoutConsultationChatsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutConsultationChatsInput>;
};

export type UserUpdateOneWithoutCreatedPayoutBatchingPeriodsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutCreatedPayoutBatchingPeriodsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCreatedPayoutBatchingPeriodsInput>;
  upsert?: Maybe<UserUpsertWithoutCreatedPayoutBatchingPeriodsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutCreatedPayoutBatchingPeriodsInput>;
};

export type UserUpdateOneWithoutEmailCampaignNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignInput>;
  upsert?: Maybe<UserUpsertWithoutEmailCampaignInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutEmailCampaignInput>;
};

export type UserUpdateOneWithoutEmailCampaignTemplateNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignTemplateInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignTemplateInput>;
  upsert?: Maybe<UserUpsertWithoutEmailCampaignTemplateInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutEmailCampaignTemplateInput>;
};

export type UserUpdateOneWithoutEmailCampaignUpdatedNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignUpdatedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignUpdatedInput>;
  upsert?: Maybe<UserUpsertWithoutEmailCampaignUpdatedInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutEmailCampaignUpdatedInput>;
};

export type UserUpdateOneWithoutEmailCampaignsCreatedNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignsCreatedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignsCreatedInput>;
  upsert?: Maybe<UserUpsertWithoutEmailCampaignsCreatedInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutEmailCampaignsCreatedInput>;
};

export type UserUpdateOneWithoutEmailCampaignsSentNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignsSentInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignsSentInput>;
  upsert?: Maybe<UserUpsertWithoutEmailCampaignsSentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutEmailCampaignsSentInput>;
};

export type UserUpdateOneWithoutEmailTemplateVersionsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutEmailTemplateVersionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailTemplateVersionsInput>;
  upsert?: Maybe<UserUpsertWithoutEmailTemplateVersionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutEmailTemplateVersionsInput>;
};

export type UserUpdateOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  upsert?: Maybe<UserUpsertWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
};

export type UserUpdateOneWithoutInvitationsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutInvitationsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutInvitationsInput>;
  upsert?: Maybe<UserUpsertWithoutInvitationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutInvitationsInput>;
};

export type UserUpdateOneWithoutLoaSignedClinicPhoneNumbersNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutLoaSignedClinicPhoneNumbersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutLoaSignedClinicPhoneNumbersInput>;
  upsert?: Maybe<UserUpsertWithoutLoaSignedClinicPhoneNumbersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutLoaSignedClinicPhoneNumbersInput>;
};

export type UserUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutLoyaltyRewardRedemptionActionsInput>;
  upsert?: Maybe<UserUpsertWithoutLoyaltyRewardRedemptionActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutLoyaltyRewardRedemptionActionsInput>;
};

export type UserUpdateOneWithoutManuallySentAutomationRunsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutManuallySentAutomationRunsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutManuallySentAutomationRunsInput>;
  upsert?: Maybe<UserUpsertWithoutManuallySentAutomationRunsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutManuallySentAutomationRunsInput>;
};

export type UserUpdateOneWithoutMessageTemplatesNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutMessageTemplatesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMessageTemplatesInput>;
  upsert?: Maybe<UserUpsertWithoutMessageTemplatesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutMessageTemplatesInput>;
};

export type UserUpdateOneWithoutOwnerInfoNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutOwnerInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOwnerInfoInput>;
  upsert?: Maybe<UserUpsertWithoutOwnerInfoInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutOwnerInfoInput>;
};

export type UserUpdateOneWithoutRefundsIssuedNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutRefundsIssuedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutRefundsIssuedInput>;
  upsert?: Maybe<UserUpsertWithoutRefundsIssuedInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutRefundsIssuedInput>;
};

export type UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutResponderEnrollmentSupportEventsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutResponderEnrollmentSupportEventsInput>;
  upsert?: Maybe<UserUpsertWithoutResponderEnrollmentSupportEventsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutResponderEnrollmentSupportEventsInput>;
};

export type UserUpdateOneWithoutReviewsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutReviewsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutReviewsInput>;
  upsert?: Maybe<UserUpsertWithoutReviewsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutReviewsInput>;
};

export type UserUpdateOneWithoutSentInvoicesNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutSentInvoicesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentInvoicesInput>;
  upsert?: Maybe<UserUpsertWithoutSentInvoicesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutSentInvoicesInput>;
};

export type UserUpdateOneWithoutSentStripePaymentIntentsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutSentStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentStripePaymentIntentsInput>;
  upsert?: Maybe<UserUpsertWithoutSentStripePaymentIntentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutSentStripePaymentIntentsInput>;
};

export type UserUpdateOneWithoutTriggeredWritebacksNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutTriggeredWritebacksInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTriggeredWritebacksInput>;
  upsert?: Maybe<UserUpsertWithoutTriggeredWritebacksInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutTriggeredWritebacksInput>;
};

export type UserUpdateOneWithoutUnblockedNumbersNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutUnblockedNumbersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUnblockedNumbersInput>;
  upsert?: Maybe<UserUpsertWithoutUnblockedNumbersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutUnblockedNumbersInput>;
};

export type UserUpdateOneWithoutUsagesRecordedNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutUsagesRecordedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUsagesRecordedInput>;
  upsert?: Maybe<UserUpsertWithoutUsagesRecordedInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutUsagesRecordedInput>;
};

export type UserUpdateOneWithoutUserAppointmentGroupingsNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserAppointmentGroupingsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserAppointmentGroupingsInput>;
  upsert?: Maybe<UserUpsertWithoutUserAppointmentGroupingsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutUserAppointmentGroupingsInput>;
};

export type UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  upsert?: Maybe<UserUpsertWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
};

export type UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_User_IdNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  upsert?: Maybe<UserUpsertWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
};

export type UserUpdateOneWithoutValidatedCanonicalServicesNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutValidatedCanonicalServicesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutValidatedCanonicalServicesInput>;
  upsert?: Maybe<UserUpsertWithoutValidatedCanonicalServicesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutValidatedCanonicalServicesInput>;
};

export type UserUpdateOneWithoutVetInfoNestedInput = {
  create?: Maybe<UserUncheckedCreateWithoutVetInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVetInfoInput>;
  upsert?: Maybe<UserUpsertWithoutVetInfoInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutVetInfoInput>;
};

export type UserUpdateWithWhereUniqueWithoutChannelMessagesWithMentionsInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutChannelMessagesWithMentionsInput;
};

export type UserUpdateWithWhereUniqueWithoutChannelSelectionsInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutChannelSelectionsInput;
};

export type UserUpdateWithWhereUniqueWithoutChannelsInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutChannelsInput;
};

export type UserUpdateWithWhereUniqueWithoutClinicWidgetRequestTypesInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutClinicWidgetRequestTypesInput;
};

export type UserUpdateWithWhereUniqueWithoutClinicsInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutClinicsInput;
};

export type UserUpdateWithWhereUniqueWithoutFormSubmissionsInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutFormSubmissionsInput;
};

export type UserUpdateWithWhereUniqueWithoutInvitedByInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutInvitedByInput;
};

export type UserUpdateWithWhereUniqueWithoutMediaInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutMediaInput;
};

export type UserUpdateWithWhereUniqueWithoutUserSettingInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutUserSettingInput;
};

export type UserUpdateWithoutBlockedNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutBoardFiltersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutChannelMessageReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutChannelMessagesWithMentionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutClinicsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutCompletedBenefitUsageFollowupStepsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutConsultationChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutCreatedPayoutBatchingPeriodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutEmailCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutEmailCampaignTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutEmailCampaignUpdatedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutEmailCampaignsCreatedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutEmailCampaignsSentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutEmailTemplateVersionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutIntitiatedLoyaltyAccountMergesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutInvitationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutInvitedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutLoaSignedClinicPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutLoyaltyPointUserGrantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutLoyaltyRewardRedemptionActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutManuallySentAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutMediaInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutOwnerInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutPinnedChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutPointOfContactEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutRefundsIssuedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutRequestedEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutResponderEnrollmentSupportEventsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutSentInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutSentStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutSentTrupanionExamDayOffersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutTriggeredWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutUnblockedNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutUsagesRecordedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutValidatedCanonicalServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpdateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  crmId?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
};

export type UserUpsertWithWhereUniqueWithoutChannelMessagesWithMentionsInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutChannelMessagesWithMentionsInput;
  create: UserUncheckedCreateWithoutChannelMessagesWithMentionsInput;
};

export type UserUpsertWithWhereUniqueWithoutChannelSelectionsInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutChannelSelectionsInput;
  create: UserUncheckedCreateWithoutChannelSelectionsInput;
};

export type UserUpsertWithWhereUniqueWithoutChannelsInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutChannelsInput;
  create: UserUncheckedCreateWithoutChannelsInput;
};

export type UserUpsertWithWhereUniqueWithoutClinicWidgetRequestTypesInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutClinicWidgetRequestTypesInput;
  create: UserUncheckedCreateWithoutClinicWidgetRequestTypesInput;
};

export type UserUpsertWithWhereUniqueWithoutClinicsInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutClinicsInput;
  create: UserUncheckedCreateWithoutClinicsInput;
};

export type UserUpsertWithWhereUniqueWithoutFormSubmissionsInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutFormSubmissionsInput;
  create: UserUncheckedCreateWithoutFormSubmissionsInput;
};

export type UserUpsertWithWhereUniqueWithoutInvitedByInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutInvitedByInput;
  create: UserUncheckedCreateWithoutInvitedByInput;
};

export type UserUpsertWithWhereUniqueWithoutMediaInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutMediaInput;
  create: UserUncheckedCreateWithoutMediaInput;
};

export type UserUpsertWithWhereUniqueWithoutUserSettingInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutUserSettingInput;
  create: UserUncheckedCreateWithoutUserSettingInput;
};

export type UserUpsertWithoutBlockedNumbersInput = {
  update: UserUncheckedUpdateWithoutBlockedNumbersInput;
  create: UserUncheckedCreateWithoutBlockedNumbersInput;
};

export type UserUpsertWithoutBoardFiltersInput = {
  update: UserUncheckedUpdateWithoutBoardFiltersInput;
  create: UserUncheckedCreateWithoutBoardFiltersInput;
};

export type UserUpsertWithoutCallsInput = {
  update: UserUncheckedUpdateWithoutCallsInput;
  create: UserUncheckedCreateWithoutCallsInput;
};

export type UserUpsertWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  update: UserUncheckedUpdateWithoutCareBenefitUsageToInvoiceLineItemsInput;
  create: UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput;
};

export type UserUpsertWithoutChannelCreationSourcesInput = {
  update: UserUncheckedUpdateWithoutChannelCreationSourcesInput;
  create: UserUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type UserUpsertWithoutChannelMembersInput = {
  update: UserUncheckedUpdateWithoutChannelMembersInput;
  create: UserUncheckedCreateWithoutChannelMembersInput;
};

export type UserUpsertWithoutChannelMessageReactionsInput = {
  update: UserUncheckedUpdateWithoutChannelMessageReactionsInput;
  create: UserUncheckedCreateWithoutChannelMessageReactionsInput;
};

export type UserUpsertWithoutChannelStatusChannelAssigneesInput = {
  update: UserUncheckedUpdateWithoutChannelStatusChannelAssigneesInput;
  create: UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
};

export type UserUpsertWithoutChannelStatusesInput = {
  update: UserUncheckedUpdateWithoutChannelStatusesInput;
  create: UserUncheckedCreateWithoutChannelStatusesInput;
};

export type UserUpsertWithoutClinicPetParentsInput = {
  update: UserUncheckedUpdateWithoutClinicPetParentsInput;
  create: UserUncheckedCreateWithoutClinicPetParentsInput;
};

export type UserUpsertWithoutClinicsIdInput = {
  update: UserUncheckedUpdateWithoutClinicsIdInput;
  create: UserUncheckedCreateWithoutClinicsIdInput;
};

export type UserUpsertWithoutCompletedBenefitUsageFollowupStepsInput = {
  update: UserUncheckedUpdateWithoutCompletedBenefitUsageFollowupStepsInput;
  create: UserUncheckedCreateWithoutCompletedBenefitUsageFollowupStepsInput;
};

export type UserUpsertWithoutConsultationChatsInput = {
  update: UserUncheckedUpdateWithoutConsultationChatsInput;
  create: UserUncheckedCreateWithoutConsultationChatsInput;
};

export type UserUpsertWithoutCreatedPayoutBatchingPeriodsInput = {
  update: UserUncheckedUpdateWithoutCreatedPayoutBatchingPeriodsInput;
  create: UserUncheckedCreateWithoutCreatedPayoutBatchingPeriodsInput;
};

export type UserUpsertWithoutEmailCampaignInput = {
  update: UserUncheckedUpdateWithoutEmailCampaignInput;
  create: UserUncheckedCreateWithoutEmailCampaignInput;
};

export type UserUpsertWithoutEmailCampaignTemplateInput = {
  update: UserUncheckedUpdateWithoutEmailCampaignTemplateInput;
  create: UserUncheckedCreateWithoutEmailCampaignTemplateInput;
};

export type UserUpsertWithoutEmailCampaignUpdatedInput = {
  update: UserUncheckedUpdateWithoutEmailCampaignUpdatedInput;
  create: UserUncheckedCreateWithoutEmailCampaignUpdatedInput;
};

export type UserUpsertWithoutEmailCampaignsCreatedInput = {
  update: UserUncheckedUpdateWithoutEmailCampaignsCreatedInput;
  create: UserUncheckedCreateWithoutEmailCampaignsCreatedInput;
};

export type UserUpsertWithoutEmailCampaignsSentInput = {
  update: UserUncheckedUpdateWithoutEmailCampaignsSentInput;
  create: UserUncheckedCreateWithoutEmailCampaignsSentInput;
};

export type UserUpsertWithoutEmailTemplateVersionsInput = {
  update: UserUncheckedUpdateWithoutEmailTemplateVersionsInput;
  create: UserUncheckedCreateWithoutEmailTemplateVersionsInput;
};

export type UserUpsertWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  update: UserUncheckedUpdateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput;
  create: UserUncheckedCreateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput;
};

export type UserUpsertWithoutIntitiatedLoyaltyAccountMergesInput = {
  update: UserUncheckedUpdateWithoutIntitiatedLoyaltyAccountMergesInput;
  create: UserUncheckedCreateWithoutIntitiatedLoyaltyAccountMergesInput;
};

export type UserUpsertWithoutInvitationsInput = {
  update: UserUncheckedUpdateWithoutInvitationsInput;
  create: UserUncheckedCreateWithoutInvitationsInput;
};

export type UserUpsertWithoutLoaSignedClinicPhoneNumbersInput = {
  update: UserUncheckedUpdateWithoutLoaSignedClinicPhoneNumbersInput;
  create: UserUncheckedCreateWithoutLoaSignedClinicPhoneNumbersInput;
};

export type UserUpsertWithoutLoyaltyPointUserGrantsInput = {
  update: UserUncheckedUpdateWithoutLoyaltyPointUserGrantsInput;
  create: UserUncheckedCreateWithoutLoyaltyPointUserGrantsInput;
};

export type UserUpsertWithoutLoyaltyRewardRedemptionActionsInput = {
  update: UserUncheckedUpdateWithoutLoyaltyRewardRedemptionActionsInput;
  create: UserUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput;
};

export type UserUpsertWithoutManuallySentAutomationRunsInput = {
  update: UserUncheckedUpdateWithoutManuallySentAutomationRunsInput;
  create: UserUncheckedCreateWithoutManuallySentAutomationRunsInput;
};

export type UserUpsertWithoutMassTextAlertsInput = {
  update: UserUncheckedUpdateWithoutMassTextAlertsInput;
  create: UserUncheckedCreateWithoutMassTextAlertsInput;
};

export type UserUpsertWithoutMessageTemplatesInput = {
  update: UserUncheckedUpdateWithoutMessageTemplatesInput;
  create: UserUncheckedCreateWithoutMessageTemplatesInput;
};

export type UserUpsertWithoutOwnerInfoInput = {
  update: UserUncheckedUpdateWithoutOwnerInfoInput;
  create: UserUncheckedCreateWithoutOwnerInfoInput;
};

export type UserUpsertWithoutPinnedChannelsInput = {
  update: UserUncheckedUpdateWithoutPinnedChannelsInput;
  create: UserUncheckedCreateWithoutPinnedChannelsInput;
};

export type UserUpsertWithoutPointOfContactEnrollmentSupportEventsInput = {
  update: UserUncheckedUpdateWithoutPointOfContactEnrollmentSupportEventsInput;
  create: UserUncheckedCreateWithoutPointOfContactEnrollmentSupportEventsInput;
};

export type UserUpsertWithoutRefundsIssuedInput = {
  update: UserUncheckedUpdateWithoutRefundsIssuedInput;
  create: UserUncheckedCreateWithoutRefundsIssuedInput;
};

export type UserUpsertWithoutRequestedEnrollmentSupportEventsInput = {
  update: UserUncheckedUpdateWithoutRequestedEnrollmentSupportEventsInput;
  create: UserUncheckedCreateWithoutRequestedEnrollmentSupportEventsInput;
};

export type UserUpsertWithoutResponderEnrollmentSupportEventsInput = {
  update: UserUncheckedUpdateWithoutResponderEnrollmentSupportEventsInput;
  create: UserUncheckedCreateWithoutResponderEnrollmentSupportEventsInput;
};

export type UserUpsertWithoutReviewsInput = {
  update: UserUncheckedUpdateWithoutReviewsInput;
  create: UserUncheckedCreateWithoutReviewsInput;
};

export type UserUpsertWithoutSentInvoicesInput = {
  update: UserUncheckedUpdateWithoutSentInvoicesInput;
  create: UserUncheckedCreateWithoutSentInvoicesInput;
};

export type UserUpsertWithoutSentStripePaymentIntentsInput = {
  update: UserUncheckedUpdateWithoutSentStripePaymentIntentsInput;
  create: UserUncheckedCreateWithoutSentStripePaymentIntentsInput;
};

export type UserUpsertWithoutSentTrupanionExamDayOffersInput = {
  update: UserUncheckedUpdateWithoutSentTrupanionExamDayOffersInput;
  create: UserUncheckedCreateWithoutSentTrupanionExamDayOffersInput;
};

export type UserUpsertWithoutTriggeredWritebacksInput = {
  update: UserUncheckedUpdateWithoutTriggeredWritebacksInput;
  create: UserUncheckedCreateWithoutTriggeredWritebacksInput;
};

export type UserUpsertWithoutUnblockedNumbersInput = {
  update: UserUncheckedUpdateWithoutUnblockedNumbersInput;
  create: UserUncheckedCreateWithoutUnblockedNumbersInput;
};

export type UserUpsertWithoutUsagesRecordedInput = {
  update: UserUncheckedUpdateWithoutUsagesRecordedInput;
  create: UserUncheckedCreateWithoutUsagesRecordedInput;
};

export type UserUpsertWithoutUserAppointmentGroupingsInput = {
  update: UserUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  create: UserUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type UserUpsertWithoutUserChannelFilterSelectionsInput = {
  update: UserUncheckedUpdateWithoutUserChannelFilterSelectionsInput;
  create: UserUncheckedCreateWithoutUserChannelFilterSelectionsInput;
};

export type UserUpsertWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  update: UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput;
  create: UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput;
};

export type UserUpsertWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  update: UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput;
  create: UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput;
};

export type UserUpsertWithoutValidatedCanonicalServicesInput = {
  update: UserUncheckedUpdateWithoutValidatedCanonicalServicesInput;
  create: UserUncheckedCreateWithoutValidatedCanonicalServicesInput;
};

export type UserUpsertWithoutVetInfoInput = {
  update: UserUncheckedUpdateWithoutVetInfoInput;
  create: UserUncheckedCreateWithoutVetInfoInput;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  phone?: Maybe<StringNullableFilter>;
  signUpDate?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<EnumUserTypeFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  profilePic?: Maybe<StringNullableFilter>;
  ownerInfoId?: Maybe<StringNullableFilter>;
  vetInfoId?: Maybe<StringNullableFilter>;
  isAdmin?: Maybe<BoolNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  userSettingId?: Maybe<StringNullableFilter>;
  nameDisplay?: Maybe<StringNullableFilter>;
  namePrefix?: Maybe<StringNullableFilter>;
  nameSuffix?: Maybe<StringNullableFilter>;
  invitedAt?: Maybe<DateTimeNullableFilter>;
  invitationCode?: Maybe<StringNullableFilter>;
  isNewPasswordRequired?: Maybe<BoolFilter>;
  invitedById?: Maybe<StringNullableFilter>;
  mediaId?: Maybe<StringNullableFilter>;
  crmId?: Maybe<StringNullableFilter>;
  ownerInfo?: Maybe<OwnerInfoWhereInput>;
  invitedBy?: Maybe<UserWhereInput>;
  userSetting?: Maybe<UserSettingWhereInput>;
  vetInfo?: Maybe<VetInfoWhereInput>;
  channels?: Maybe<ChannelListRelationFilter>;
  channelMembers?: Maybe<ChannelMemberListRelationFilter>;
  channelStatuses?: Maybe<ChannelStatusListRelationFilter>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeListRelationFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
  clinicsId?: Maybe<ClinicListRelationFilter>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
  consultationChats?: Maybe<ConsultationChatListRelationFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  invitations?: Maybe<UserListRelationFilter>;
  refundsIssued?: Maybe<StripeRefundListRelationFilter>;
  reviews?: Maybe<ReviewListRelationFilter>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  media?: Maybe<MediaWhereInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationListRelationFilter>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationListRelationFilter>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  sentInvoices?: Maybe<InvoiceListRelationFilter>;
  calls?: Maybe<CallParticipantListRelationFilter>;
  channelSelections?: Maybe<ChannelSelectionListRelationFilter>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  usagesRecorded?: Maybe<CareBenefitUsageListRelationFilter>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionListRelationFilter>;
  messageTemplates?: Maybe<MessageTemplateListRelationFilter>;
  channelMessagesWithMentions?: Maybe<ChannelMessageListRelationFilter>;
  manuallySentAutomationRuns?: Maybe<AutomationRunListRelationFilter>;
  channelMessageReactions?: Maybe<ChannelMessageReactionListRelationFilter>;
  massTextAlerts?: Maybe<MassTextAlertListRelationFilter>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberListRelationFilter>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryListRelationFilter>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerListRelationFilter>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantListRelationFilter>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionListRelationFilter>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepListRelationFilter>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferListRelationFilter>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodListRelationFilter>;
  validatedCanonicalServices?: Maybe<CanonicalServiceListRelationFilter>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventListRelationFilter>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventListRelationFilter>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventListRelationFilter>;
  EmailCampaignsCreated?: Maybe<EmailCampaignListRelationFilter>;
  EmailCampaignUpdated?: Maybe<EmailCampaignListRelationFilter>;
  EmailCampaignsSent?: Maybe<EmailCampaignListRelationFilter>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateListRelationFilter>;
  EmailCampaign?: Maybe<EmailCampaignListRelationFilter>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberListRelationFilter>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberListRelationFilter>;
  pinnedChannels?: Maybe<ChannelPinListRelationFilter>;
  boardFilters?: Maybe<UserBoardFilterListRelationFilter>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentListRelationFilter>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  userSettingId?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
};

export type Vaccination = {
  __typename?: 'Vaccination';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: Clinic;
  clinicPet: ClinicPet;
  integration?: Maybe<ClinicPimsIntegration>;
};

export type VaccinationCountAggregateOutputType = {
  __typename?: 'VaccinationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  pimsId: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  description: Scalars['Int'];
  siteId: Scalars['Int'];
  administeredAt: Scalars['Int'];
  expiredAt: Scalars['Int'];
  manufacturer: Scalars['Int'];
  virusType: Scalars['Int'];
  serialNumber: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VaccinationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  administeredAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type VaccinationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutVaccinationsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutVaccinationsInput;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVaccinationsInput>;
};

export type VaccinationCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationCreateManyClinicInputEnvelope = {
  data: Array<VaccinationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VaccinationCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationCreateManyClinicPetInputEnvelope = {
  data: Array<VaccinationCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VaccinationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationCreateManyIntegrationInputEnvelope = {
  data: Array<VaccinationCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VaccinationCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
};

export type VaccinationCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
};

export type VaccinationCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<VaccinationCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<VaccinationCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
};

export type VaccinationCreateOrConnectWithoutClinicInput = {
  where: VaccinationWhereUniqueInput;
  create: VaccinationUncheckedCreateWithoutClinicInput;
};

export type VaccinationCreateOrConnectWithoutClinicPetInput = {
  where: VaccinationWhereUniqueInput;
  create: VaccinationUncheckedCreateWithoutClinicPetInput;
};

export type VaccinationCreateOrConnectWithoutIntegrationInput = {
  where: VaccinationWhereUniqueInput;
  create: VaccinationUncheckedCreateWithoutIntegrationInput;
};

export type VaccinationCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPet: ClinicPetCreateNestedOneWithoutVaccinationsInput;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVaccinationsInput>;
};

export type VaccinationCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutVaccinationsInput;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVaccinationsInput>;
};

export type VaccinationCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutVaccinationsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutVaccinationsInput;
};

export type VaccinationListRelationFilter = {
  every?: Maybe<VaccinationWhereInput>;
  some?: Maybe<VaccinationWhereInput>;
  none?: Maybe<VaccinationWhereInput>;
};

export type VaccinationMaxAggregateOutputType = {
  __typename?: 'VaccinationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type VaccinationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  administeredAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
};

export type VaccinationMinAggregateOutputType = {
  __typename?: 'VaccinationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type VaccinationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  administeredAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
};

export type VaccinationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VaccinationOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  Description = 'description',
  SiteId = 'siteId',
  Manufacturer = 'manufacturer',
  VirusType = 'virusType',
  SerialNumber = 'serialNumber'
}

export type VaccinationOrderByRelevanceInput = {
  fields: Array<VaccinationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VaccinationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  administeredAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<VaccinationCountOrderByAggregateInput>;
  _max?: Maybe<VaccinationMaxOrderByAggregateInput>;
  _min?: Maybe<VaccinationMinOrderByAggregateInput>;
};

export type VaccinationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  administeredAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<VaccinationOrderByRelevanceInput>;
};

export enum VaccinationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Description = 'description',
  SiteId = 'siteId',
  AdministeredAt = 'administeredAt',
  ExpiredAt = 'expiredAt',
  Manufacturer = 'manufacturer',
  VirusType = 'virusType',
  SerialNumber = 'serialNumber',
  RawPimsValue = 'rawPimsValue'
}

export type VaccinationScalarWhereInput = {
  AND?: Maybe<Array<VaccinationScalarWhereInput>>;
  OR?: Maybe<Array<VaccinationScalarWhereInput>>;
  NOT?: Maybe<Array<VaccinationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  description?: Maybe<StringNullableFilter>;
  siteId?: Maybe<StringNullableFilter>;
  administeredAt?: Maybe<DateTimeNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  manufacturer?: Maybe<StringNullableFilter>;
  virusType?: Maybe<StringNullableFilter>;
  serialNumber?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type VaccinationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VaccinationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VaccinationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VaccinationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  siteId?: Maybe<StringNullableWithAggregatesFilter>;
  administeredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  expiredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  manufacturer?: Maybe<StringNullableWithAggregatesFilter>;
  virusType?: Maybe<StringNullableWithAggregatesFilter>;
  serialNumber?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type VaccinationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
};

export type VaccinationUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
};

export type VaccinationUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<VaccinationCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<VaccinationCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
};

export type VaccinationUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
};

export type VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
};

export type VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<VaccinationCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<VaccinationCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
};

export type VaccinationUncheckedUpdateManyWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVaccinationsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutVaccinationsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVaccinationsNestedInput>;
};

export type VaccinationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUpdateManyWithWhereWithoutClinicInput = {
  where: VaccinationScalarWhereInput;
  data: VaccinationUncheckedUpdateManyWithoutVaccinationsInput;
};

export type VaccinationUpdateManyWithWhereWithoutClinicPetInput = {
  where: VaccinationScalarWhereInput;
  data: VaccinationUncheckedUpdateManyWithoutVaccinationsInput;
};

export type VaccinationUpdateManyWithWhereWithoutIntegrationInput = {
  where: VaccinationScalarWhereInput;
  data: VaccinationUncheckedUpdateManyWithoutVaccinationsInput;
};

export type VaccinationUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
};

export type VaccinationUpdateManyWithoutClinicPetNestedInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
};

export type VaccinationUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<VaccinationCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<VaccinationCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
};

export type VaccinationUpdateWithWhereUniqueWithoutClinicInput = {
  where: VaccinationWhereUniqueInput;
  data: VaccinationUncheckedUpdateWithoutClinicInput;
};

export type VaccinationUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: VaccinationWhereUniqueInput;
  data: VaccinationUncheckedUpdateWithoutClinicPetInput;
};

export type VaccinationUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: VaccinationWhereUniqueInput;
  data: VaccinationUncheckedUpdateWithoutIntegrationInput;
};

export type VaccinationUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutVaccinationsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVaccinationsNestedInput>;
};

export type VaccinationUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVaccinationsNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVaccinationsNestedInput>;
};

export type VaccinationUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVaccinationsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutVaccinationsNestedInput>;
};

export type VaccinationUpsertWithWhereUniqueWithoutClinicInput = {
  where: VaccinationWhereUniqueInput;
  update: VaccinationUncheckedUpdateWithoutClinicInput;
  create: VaccinationUncheckedCreateWithoutClinicInput;
};

export type VaccinationUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: VaccinationWhereUniqueInput;
  update: VaccinationUncheckedUpdateWithoutClinicPetInput;
  create: VaccinationUncheckedCreateWithoutClinicPetInput;
};

export type VaccinationUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: VaccinationWhereUniqueInput;
  update: VaccinationUncheckedUpdateWithoutIntegrationInput;
  create: VaccinationUncheckedCreateWithoutIntegrationInput;
};

export type VaccinationVaccinationsPimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type VaccinationWhereInput = {
  AND?: Maybe<Array<VaccinationWhereInput>>;
  OR?: Maybe<Array<VaccinationWhereInput>>;
  NOT?: Maybe<Array<VaccinationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  description?: Maybe<StringNullableFilter>;
  siteId?: Maybe<StringNullableFilter>;
  administeredAt?: Maybe<DateTimeNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  manufacturer?: Maybe<StringNullableFilter>;
  virusType?: Maybe<StringNullableFilter>;
  serialNumber?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type VaccinationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  vaccinationsPimsIdAndIntegrationId?: Maybe<VaccinationVaccinationsPimsIdAndIntegrationIdCompoundUniqueInput>;
};

export type VerificationCheckInput = {
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  type: VerificationType;
};

export type VerificationRequestInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type: VerificationType;
  clinicId?: Maybe<Scalars['String']>;
};

export enum VerificationType {
  Sms = 'SMS',
  Email = 'EMAIL'
}

export type VerifyClinicWidgetRequestInput = {
  clinicWidgetRequestId: Scalars['ID'];
};

export type VerifyTerminalSetupPinResponse = {
  __typename?: 'VerifyTerminalSetupPinResponse';
  clinicId: Scalars['String'];
  locationId: Scalars['String'];
  connectionToken: Scalars['String'];
};

export type VerifyWidgetAuthenticationRequestInput = {
  code: Scalars['String'];
};

export type VerifyWidgetAuthenticationRequestResponse = {
  __typename?: 'VerifyWidgetAuthenticationRequestResponse';
  success?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type VetAvailability = {
  __typename?: 'VetAvailability';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isRecurring: Scalars['Boolean'];
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds: Array<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: Clinic;
  integration?: Maybe<ClinicPimsIntegration>;
  vetAvailabilityMaps: Array<VetAvailabilityMap>;
  vetAvailabilityClinicRoomMaps: Array<VetAvailabilityClinicRoomMap>;
  _count: VetAvailabilityCountOutputType;
};


export type VetAvailabilityVetAvailabilityMapsArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
  orderBy?: Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetAvailabilityMapScalarFieldEnum>;
};


export type VetAvailabilityVetAvailabilityClinicRoomMapsArgs = {
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
  orderBy?: Maybe<VetAvailabilityClinicRoomMapOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetAvailabilityClinicRoomMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetAvailabilityClinicRoomMapScalarFieldEnum>;
};

export type VetAvailabilityClinicRoomMap = {
  __typename?: 'VetAvailabilityClinicRoomMap';
  createdAt: Scalars['DateTime'];
  vetAvailabilityId: Scalars['String'];
  clinicRoomId: Scalars['String'];
  clinicRoom: ClinicRoom;
  vetAvailability: VetAvailability;
};

export type VetAvailabilityClinicRoomMapClinicRoomIdVetAvailabilityIdCompoundUniqueInput = {
  clinicRoomId: Scalars['String'];
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityClinicRoomMapCountAggregateOutputType = {
  __typename?: 'VetAvailabilityClinicRoomMapCountAggregateOutputType';
  createdAt: Scalars['Int'];
  vetAvailabilityId: Scalars['Int'];
  clinicRoomId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetAvailabilityClinicRoomMapCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
};

export type VetAvailabilityClinicRoomMapCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoom: ClinicRoomCreateNestedOneWithoutVetAvailabilityClinicRoomMapsInput;
  vetAvailability: VetAvailabilityCreateNestedOneWithoutVetAvailabilityClinicRoomMapsInput;
};

export type VetAvailabilityClinicRoomMapCreateManyClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityClinicRoomMapCreateManyClinicRoomInputEnvelope = {
  data: Array<VetAvailabilityClinicRoomMapCreateManyClinicRoomInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityClinicRoomMapCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
  clinicRoomId: Scalars['String'];
};

export type VetAvailabilityClinicRoomMapCreateManyVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoomId: Scalars['String'];
};

export type VetAvailabilityClinicRoomMapCreateManyVetAvailabilityInputEnvelope = {
  data: Array<VetAvailabilityClinicRoomMapCreateManyVetAvailabilityInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput = {
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutClinicRoomInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutClinicRoomInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyClinicRoomInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
};

export type VetAvailabilityClinicRoomMapCreateNestedManyWithoutVetAvailabilityInput = {
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutVetAvailabilityInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyVetAvailabilityInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
};

export type VetAvailabilityClinicRoomMapCreateOrConnectWithoutClinicRoomInput = {
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
  create: VetAvailabilityClinicRoomMapUncheckedCreateWithoutClinicRoomInput;
};

export type VetAvailabilityClinicRoomMapCreateOrConnectWithoutVetAvailabilityInput = {
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
  create: VetAvailabilityClinicRoomMapUncheckedCreateWithoutVetAvailabilityInput;
};

export type VetAvailabilityClinicRoomMapCreateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailability: VetAvailabilityCreateNestedOneWithoutVetAvailabilityClinicRoomMapsInput;
};

export type VetAvailabilityClinicRoomMapCreateWithoutVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoom: ClinicRoomCreateNestedOneWithoutVetAvailabilityClinicRoomMapsInput;
};

export type VetAvailabilityClinicRoomMapListRelationFilter = {
  every?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
  some?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
  none?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
};

export type VetAvailabilityClinicRoomMapMaxAggregateOutputType = {
  __typename?: 'VetAvailabilityClinicRoomMapMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityClinicRoomMapMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
};

export type VetAvailabilityClinicRoomMapMinAggregateOutputType = {
  __typename?: 'VetAvailabilityClinicRoomMapMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityClinicRoomMapMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
};

export type VetAvailabilityClinicRoomMapOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetAvailabilityClinicRoomMapOrderByRelevanceFieldEnum {
  VetAvailabilityId = 'vetAvailabilityId',
  ClinicRoomId = 'clinicRoomId'
}

export type VetAvailabilityClinicRoomMapOrderByRelevanceInput = {
  fields: Array<VetAvailabilityClinicRoomMapOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetAvailabilityClinicRoomMapOrderByWithAggregationInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
  _count?: Maybe<VetAvailabilityClinicRoomMapCountOrderByAggregateInput>;
  _max?: Maybe<VetAvailabilityClinicRoomMapMaxOrderByAggregateInput>;
  _min?: Maybe<VetAvailabilityClinicRoomMapMinOrderByAggregateInput>;
};

export type VetAvailabilityClinicRoomMapOrderByWithRelationAndSearchRelevanceInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
  clinicRoom?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  vetAvailability?: Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<VetAvailabilityClinicRoomMapOrderByRelevanceInput>;
};

export enum VetAvailabilityClinicRoomMapScalarFieldEnum {
  CreatedAt = 'createdAt',
  VetAvailabilityId = 'vetAvailabilityId',
  ClinicRoomId = 'clinicRoomId'
}

export type VetAvailabilityClinicRoomMapScalarWhereInput = {
  AND?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  vetAvailabilityId?: Maybe<StringFilter>;
  clinicRoomId?: Maybe<StringFilter>;
};

export type VetAvailabilityClinicRoomMapScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  vetAvailabilityId?: Maybe<StringWithAggregatesFilter>;
  clinicRoomId?: Maybe<StringWithAggregatesFilter>;
};

export type VetAvailabilityClinicRoomMapUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
  clinicRoomId: Scalars['String'];
};

export type VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput = {
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutClinicRoomInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutClinicRoomInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyClinicRoomInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
};

export type VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutVetAvailabilityInput = {
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutVetAvailabilityInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyVetAvailabilityInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
};

export type VetAvailabilityClinicRoomMapUncheckedCreateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityClinicRoomMapUncheckedCreateWithoutVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoomId: Scalars['String'];
};

export type VetAvailabilityClinicRoomMapUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityClinicRoomMapUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput = {
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutClinicRoomInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutClinicRoomInput>>;
  upsert?: Maybe<Array<VetAvailabilityClinicRoomMapUpsertWithWhereUniqueWithoutClinicRoomInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyClinicRoomInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateWithWhereUniqueWithoutClinicRoomInput>>;
  updateMany?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateManyWithWhereWithoutClinicRoomInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereInput>>;
};

export type VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityClinicRoomMapsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput = {
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutVetAvailabilityInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutVetAvailabilityInput>>;
  upsert?: Maybe<Array<VetAvailabilityClinicRoomMapUpsertWithWhereUniqueWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyVetAvailabilityInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateWithWhereUniqueWithoutVetAvailabilityInput>>;
  updateMany?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateManyWithWhereWithoutVetAvailabilityInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereInput>>;
};

export type VetAvailabilityClinicRoomMapUncheckedUpdateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityClinicRoomMapUncheckedUpdateWithoutVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityClinicRoomMapUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoom?: Maybe<ClinicRoomUpdateOneRequiredWithoutVetAvailabilityClinicRoomMapsNestedInput>;
  vetAvailability?: Maybe<VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityClinicRoomMapsNestedInput>;
};

export type VetAvailabilityClinicRoomMapUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityClinicRoomMapUpdateManyWithWhereWithoutClinicRoomInput = {
  where: VetAvailabilityClinicRoomMapScalarWhereInput;
  data: VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityClinicRoomMapsInput;
};

export type VetAvailabilityClinicRoomMapUpdateManyWithWhereWithoutVetAvailabilityInput = {
  where: VetAvailabilityClinicRoomMapScalarWhereInput;
  data: VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityClinicRoomMapsInput;
};

export type VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput = {
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutClinicRoomInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutClinicRoomInput>>;
  upsert?: Maybe<Array<VetAvailabilityClinicRoomMapUpsertWithWhereUniqueWithoutClinicRoomInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyClinicRoomInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateWithWhereUniqueWithoutClinicRoomInput>>;
  updateMany?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateManyWithWhereWithoutClinicRoomInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereInput>>;
};

export type VetAvailabilityClinicRoomMapUpdateManyWithoutVetAvailabilityNestedInput = {
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutVetAvailabilityInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutVetAvailabilityInput>>;
  upsert?: Maybe<Array<VetAvailabilityClinicRoomMapUpsertWithWhereUniqueWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyVetAvailabilityInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateWithWhereUniqueWithoutVetAvailabilityInput>>;
  updateMany?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateManyWithWhereWithoutVetAvailabilityInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereInput>>;
};

export type VetAvailabilityClinicRoomMapUpdateWithWhereUniqueWithoutClinicRoomInput = {
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
  data: VetAvailabilityClinicRoomMapUncheckedUpdateWithoutClinicRoomInput;
};

export type VetAvailabilityClinicRoomMapUpdateWithWhereUniqueWithoutVetAvailabilityInput = {
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
  data: VetAvailabilityClinicRoomMapUncheckedUpdateWithoutVetAvailabilityInput;
};

export type VetAvailabilityClinicRoomMapUpdateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailability?: Maybe<VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityClinicRoomMapsNestedInput>;
};

export type VetAvailabilityClinicRoomMapUpdateWithoutVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoom?: Maybe<ClinicRoomUpdateOneRequiredWithoutVetAvailabilityClinicRoomMapsNestedInput>;
};

export type VetAvailabilityClinicRoomMapUpsertWithWhereUniqueWithoutClinicRoomInput = {
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
  update: VetAvailabilityClinicRoomMapUncheckedUpdateWithoutClinicRoomInput;
  create: VetAvailabilityClinicRoomMapUncheckedCreateWithoutClinicRoomInput;
};

export type VetAvailabilityClinicRoomMapUpsertWithWhereUniqueWithoutVetAvailabilityInput = {
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
  update: VetAvailabilityClinicRoomMapUncheckedUpdateWithoutVetAvailabilityInput;
  create: VetAvailabilityClinicRoomMapUncheckedCreateWithoutVetAvailabilityInput;
};

export type VetAvailabilityClinicRoomMapWhereInput = {
  AND?: Maybe<Array<VetAvailabilityClinicRoomMapWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityClinicRoomMapWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityClinicRoomMapWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  vetAvailabilityId?: Maybe<StringFilter>;
  clinicRoomId?: Maybe<StringFilter>;
  clinicRoom?: Maybe<ClinicRoomWhereInput>;
  vetAvailability?: Maybe<VetAvailabilityWhereInput>;
};

export type VetAvailabilityClinicRoomMapWhereUniqueInput = {
  clinicRoomIdVetAvailabilityId?: Maybe<VetAvailabilityClinicRoomMapClinicRoomIdVetAvailabilityIdCompoundUniqueInput>;
};

export type VetAvailabilityCountAggregateOutputType = {
  __typename?: 'VetAvailabilityCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  isAvailable: Scalars['Int'];
  isActive: Scalars['Int'];
  isRecurring: Scalars['Int'];
  recurringRules: Scalars['Int'];
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
  pimsId: Scalars['Int'];
  integrationId: Scalars['Int'];
  availabilityType: Scalars['Int'];
  availabilityPimsType: Scalars['Int'];
  reason: Scalars['Int'];
  siteId: Scalars['Int'];
  resourceIds: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetAvailabilityCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  availabilityPimsType?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  resourceIds?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type VetAvailabilityCountOutputType = {
  __typename?: 'VetAvailabilityCountOutputType';
  vetAvailabilityMaps: Scalars['Int'];
  vetAvailabilityClinicRoomMaps: Scalars['Int'];
};

export type VetAvailabilityCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutVetAvailabilitiesInput;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetAvailabilitiesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutVetAvailabilityInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VetAvailabilityCreateManyClinicInputEnvelope = {
  data: Array<VetAvailabilityCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VetAvailabilityCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VetAvailabilityCreateManyIntegrationInputEnvelope = {
  data: Array<VetAvailabilityCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
};

export type VetAvailabilityCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
};

export type VetAvailabilityCreateNestedOneWithoutVetAvailabilityClinicRoomMapsInput = {
  create?: Maybe<VetAvailabilityUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput>;
  connectOrCreate?: Maybe<VetAvailabilityCreateOrConnectWithoutVetAvailabilityClinicRoomMapsInput>;
  connect?: Maybe<VetAvailabilityWhereUniqueInput>;
};

export type VetAvailabilityCreateNestedOneWithoutVetAvailabilityMapsInput = {
  create?: Maybe<VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput>;
  connectOrCreate?: Maybe<VetAvailabilityCreateOrConnectWithoutVetAvailabilityMapsInput>;
  connect?: Maybe<VetAvailabilityWhereUniqueInput>;
};

export type VetAvailabilityCreateOrConnectWithoutClinicInput = {
  where: VetAvailabilityWhereUniqueInput;
  create: VetAvailabilityUncheckedCreateWithoutClinicInput;
};

export type VetAvailabilityCreateOrConnectWithoutIntegrationInput = {
  where: VetAvailabilityWhereUniqueInput;
  create: VetAvailabilityUncheckedCreateWithoutIntegrationInput;
};

export type VetAvailabilityCreateOrConnectWithoutVetAvailabilityClinicRoomMapsInput = {
  where: VetAvailabilityWhereUniqueInput;
  create: VetAvailabilityUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput;
};

export type VetAvailabilityCreateOrConnectWithoutVetAvailabilityMapsInput = {
  where: VetAvailabilityWhereUniqueInput;
  create: VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetAvailabilitiesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutVetAvailabilityInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutVetAvailabilitiesInput;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutVetAvailabilityInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityCreateWithoutVetAvailabilityClinicRoomMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutVetAvailabilitiesInput;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetAvailabilitiesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityCreateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutVetAvailabilitiesInput;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetAvailabilitiesInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityCreateresourceIdsInput = {
  set: Array<Scalars['String']>;
};

export type VetAvailabilityListRelationFilter = {
  every?: Maybe<VetAvailabilityWhereInput>;
  some?: Maybe<VetAvailabilityWhereInput>;
  none?: Maybe<VetAvailabilityWhereInput>;
};

export type VetAvailabilityMap = {
  __typename?: 'VetAvailabilityMap';
  createdAt: Scalars['DateTime'];
  vetAvailabilityId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
  clinicEmployee: ClinicEmployee;
  vetAvailability: VetAvailability;
};

export type VetAvailabilityMapClinicEmployeeIdVetAvailabilityIdCompoundUniqueInput = {
  clinicEmployeeId: Scalars['String'];
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityMapCountAggregateOutputType = {
  __typename?: 'VetAvailabilityMapCountAggregateOutputType';
  createdAt: Scalars['Int'];
  vetAvailabilityId: Scalars['Int'];
  clinicEmployeeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetAvailabilityMapCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
};

export type VetAvailabilityMapCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutVetAvailabilityMapsInput;
  vetAvailability: VetAvailabilityCreateNestedOneWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityMapCreateManyClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope = {
  data: Array<VetAvailabilityMapCreateManyClinicEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityMapCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
};

export type VetAvailabilityMapCreateManyVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
};

export type VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope = {
  data: Array<VetAvailabilityMapCreateManyVetAvailabilityInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
};

export type VetAvailabilityMapCreateNestedManyWithoutVetAvailabilityInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutVetAvailabilityInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
};

export type VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput = {
  where: VetAvailabilityMapWhereUniqueInput;
  create: VetAvailabilityMapUncheckedCreateWithoutClinicEmployeeInput;
};

export type VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput = {
  where: VetAvailabilityMapWhereUniqueInput;
  create: VetAvailabilityMapUncheckedCreateWithoutVetAvailabilityInput;
};

export type VetAvailabilityMapCreateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailability: VetAvailabilityCreateNestedOneWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityMapCreateWithoutVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityMapListRelationFilter = {
  every?: Maybe<VetAvailabilityMapWhereInput>;
  some?: Maybe<VetAvailabilityMapWhereInput>;
  none?: Maybe<VetAvailabilityMapWhereInput>;
};

export type VetAvailabilityMapMaxAggregateOutputType = {
  __typename?: 'VetAvailabilityMapMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
};

export type VetAvailabilityMapMinAggregateOutputType = {
  __typename?: 'VetAvailabilityMapMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
};

export type VetAvailabilityMapOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetAvailabilityMapOrderByRelevanceFieldEnum {
  VetAvailabilityId = 'vetAvailabilityId',
  ClinicEmployeeId = 'clinicEmployeeId'
}

export type VetAvailabilityMapOrderByRelevanceInput = {
  fields: Array<VetAvailabilityMapOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetAvailabilityMapOrderByWithAggregationInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  _count?: Maybe<VetAvailabilityMapCountOrderByAggregateInput>;
  _max?: Maybe<VetAvailabilityMapMaxOrderByAggregateInput>;
  _min?: Maybe<VetAvailabilityMapMinOrderByAggregateInput>;
};

export type VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  clinicEmployee?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  vetAvailability?: Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<VetAvailabilityMapOrderByRelevanceInput>;
};

export enum VetAvailabilityMapScalarFieldEnum {
  CreatedAt = 'createdAt',
  VetAvailabilityId = 'vetAvailabilityId',
  ClinicEmployeeId = 'clinicEmployeeId'
}

export type VetAvailabilityMapScalarWhereInput = {
  AND?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  vetAvailabilityId?: Maybe<StringFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
};

export type VetAvailabilityMapScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetAvailabilityMapScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetAvailabilityMapScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetAvailabilityMapScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  vetAvailabilityId?: Maybe<StringWithAggregatesFilter>;
  clinicEmployeeId?: Maybe<StringWithAggregatesFilter>;
};

export type VetAvailabilityMapUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
};

export type VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
};

export type VetAvailabilityMapUncheckedCreateNestedManyWithoutVetAvailabilityInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutVetAvailabilityInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
};

export type VetAvailabilityMapUncheckedCreateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityMapUncheckedCreateWithoutVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
};

export type VetAvailabilityMapUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<VetAvailabilityMapUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityMapUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<VetAvailabilityMapUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
};

export type VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityMapsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutVetAvailabilityInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput>>;
  upsert?: Maybe<Array<VetAvailabilityMapUpsertWithWhereUniqueWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityMapUpdateWithWhereUniqueWithoutVetAvailabilityInput>>;
  updateMany?: Maybe<Array<VetAvailabilityMapUpdateManyWithWhereWithoutVetAvailabilityInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
};

export type VetAvailabilityMapUncheckedUpdateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapUncheckedUpdateWithoutVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutVetAvailabilityMapsNestedInput>;
  vetAvailability?: Maybe<VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityMapsNestedInput>;
};

export type VetAvailabilityMapUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityMapUpdateManyWithWhereWithoutClinicEmployeeInput = {
  where: VetAvailabilityMapScalarWhereInput;
  data: VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityMapUpdateManyWithWhereWithoutVetAvailabilityInput = {
  where: VetAvailabilityMapScalarWhereInput;
  data: VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<VetAvailabilityMapUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityMapUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<VetAvailabilityMapUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
};

export type VetAvailabilityMapUpdateManyWithoutVetAvailabilityNestedInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutVetAvailabilityInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput>>;
  upsert?: Maybe<Array<VetAvailabilityMapUpsertWithWhereUniqueWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityMapUpdateWithWhereUniqueWithoutVetAvailabilityInput>>;
  updateMany?: Maybe<Array<VetAvailabilityMapUpdateManyWithWhereWithoutVetAvailabilityInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
};

export type VetAvailabilityMapUpdateWithWhereUniqueWithoutClinicEmployeeInput = {
  where: VetAvailabilityMapWhereUniqueInput;
  data: VetAvailabilityMapUncheckedUpdateWithoutClinicEmployeeInput;
};

export type VetAvailabilityMapUpdateWithWhereUniqueWithoutVetAvailabilityInput = {
  where: VetAvailabilityMapWhereUniqueInput;
  data: VetAvailabilityMapUncheckedUpdateWithoutVetAvailabilityInput;
};

export type VetAvailabilityMapUpdateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailability?: Maybe<VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityMapsNestedInput>;
};

export type VetAvailabilityMapUpdateWithoutVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutVetAvailabilityMapsNestedInput>;
};

export type VetAvailabilityMapUpsertWithWhereUniqueWithoutClinicEmployeeInput = {
  where: VetAvailabilityMapWhereUniqueInput;
  update: VetAvailabilityMapUncheckedUpdateWithoutClinicEmployeeInput;
  create: VetAvailabilityMapUncheckedCreateWithoutClinicEmployeeInput;
};

export type VetAvailabilityMapUpsertWithWhereUniqueWithoutVetAvailabilityInput = {
  where: VetAvailabilityMapWhereUniqueInput;
  update: VetAvailabilityMapUncheckedUpdateWithoutVetAvailabilityInput;
  create: VetAvailabilityMapUncheckedCreateWithoutVetAvailabilityInput;
};

export type VetAvailabilityMapWhereInput = {
  AND?: Maybe<Array<VetAvailabilityMapWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityMapWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityMapWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  vetAvailabilityId?: Maybe<StringFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
  clinicEmployee?: Maybe<ClinicEmployeeWhereInput>;
  vetAvailability?: Maybe<VetAvailabilityWhereInput>;
};

export type VetAvailabilityMapWhereUniqueInput = {
  clinicEmployeeIdVetAvailabilityId?: Maybe<VetAvailabilityMapClinicEmployeeIdVetAvailabilityIdCompoundUniqueInput>;
};

export type VetAvailabilityMaxAggregateOutputType = {
  __typename?: 'VetAvailabilityMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  availabilityPimsType?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
};

export type VetAvailabilityMinAggregateOutputType = {
  __typename?: 'VetAvailabilityMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  availabilityPimsType?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
};

export type VetAvailabilityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetAvailabilityOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  RecurringRules = 'recurringRules',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  AvailabilityType = 'availabilityType',
  AvailabilityPimsType = 'availabilityPimsType',
  Reason = 'reason',
  SiteId = 'siteId',
  ResourceIds = 'resourceIds'
}

export type VetAvailabilityOrderByRelevanceInput = {
  fields: Array<VetAvailabilityOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetAvailabilityOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  availabilityPimsType?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  resourceIds?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<VetAvailabilityCountOrderByAggregateInput>;
  _max?: Maybe<VetAvailabilityMaxOrderByAggregateInput>;
  _min?: Maybe<VetAvailabilityMinOrderByAggregateInput>;
};

export type VetAvailabilityOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  availabilityPimsType?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  resourceIds?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapOrderByRelationAggregateInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapOrderByRelationAggregateInput>;
  _relevance?: Maybe<VetAvailabilityOrderByRelevanceInput>;
};

export type VetAvailabilityRelationFilter = {
  is?: Maybe<VetAvailabilityWhereInput>;
  isNot?: Maybe<VetAvailabilityWhereInput>;
};

export enum VetAvailabilityScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  IsAvailable = 'isAvailable',
  IsActive = 'isActive',
  IsRecurring = 'isRecurring',
  RecurringRules = 'recurringRules',
  StartDate = 'startDate',
  EndDate = 'endDate',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  AvailabilityType = 'availabilityType',
  AvailabilityPimsType = 'availabilityPimsType',
  Reason = 'reason',
  SiteId = 'siteId',
  ResourceIds = 'resourceIds',
  RawPimsValue = 'rawPimsValue'
}

export type VetAvailabilityScalarWhereInput = {
  AND?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  isAvailable?: Maybe<BoolFilter>;
  isActive?: Maybe<BoolFilter>;
  isRecurring?: Maybe<BoolFilter>;
  recurringRules?: Maybe<StringNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  endDate?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  availabilityType?: Maybe<StringNullableFilter>;
  availabilityPimsType?: Maybe<StringNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  siteId?: Maybe<StringNullableFilter>;
  resourceIds?: Maybe<StringNullableListFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type VetAvailabilityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetAvailabilityScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetAvailabilityScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetAvailabilityScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  isAvailable?: Maybe<BoolWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isRecurring?: Maybe<BoolWithAggregatesFilter>;
  recurringRules?: Maybe<StringNullableWithAggregatesFilter>;
  startDate?: Maybe<DateTimeWithAggregatesFilter>;
  endDate?: Maybe<DateTimeWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  availabilityType?: Maybe<StringNullableWithAggregatesFilter>;
  availabilityPimsType?: Maybe<StringNullableWithAggregatesFilter>;
  reason?: Maybe<StringNullableWithAggregatesFilter>;
  siteId?: Maybe<StringNullableWithAggregatesFilter>;
  resourceIds?: Maybe<StringNullableListFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type VetAvailabilityUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
};

export type VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
};

export type VetAvailabilityUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<VetAvailabilityUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<VetAvailabilityUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
};

export type VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<VetAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<VetAvailabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
};

export type VetAvailabilityUncheckedUpdateManyWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VetAvailabilityUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUncheckedUpdateWithoutVetAvailabilityClinicRoomMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUncheckedUpdateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVetAvailabilitiesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetAvailabilitiesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutVetAvailabilityNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VetAvailabilityUpdateManyWithWhereWithoutClinicInput = {
  where: VetAvailabilityScalarWhereInput;
  data: VetAvailabilityUncheckedUpdateManyWithoutVetAvailabilitiesInput;
};

export type VetAvailabilityUpdateManyWithWhereWithoutIntegrationInput = {
  where: VetAvailabilityScalarWhereInput;
  data: VetAvailabilityUncheckedUpdateManyWithoutVetAvailabilitiesInput;
};

export type VetAvailabilityUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<VetAvailabilityUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<VetAvailabilityUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
};

export type VetAvailabilityUpdateManyWithoutIntegrationNestedInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<VetAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<VetAvailabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
};

export type VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityClinicRoomMapsNestedInput = {
  create?: Maybe<VetAvailabilityUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput>;
  connectOrCreate?: Maybe<VetAvailabilityCreateOrConnectWithoutVetAvailabilityClinicRoomMapsInput>;
  upsert?: Maybe<VetAvailabilityUpsertWithoutVetAvailabilityClinicRoomMapsInput>;
  connect?: Maybe<VetAvailabilityWhereUniqueInput>;
  update?: Maybe<VetAvailabilityUncheckedUpdateWithoutVetAvailabilityClinicRoomMapsInput>;
};

export type VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityMapsNestedInput = {
  create?: Maybe<VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput>;
  connectOrCreate?: Maybe<VetAvailabilityCreateOrConnectWithoutVetAvailabilityMapsInput>;
  upsert?: Maybe<VetAvailabilityUpsertWithoutVetAvailabilityMapsInput>;
  connect?: Maybe<VetAvailabilityWhereUniqueInput>;
  update?: Maybe<VetAvailabilityUncheckedUpdateWithoutVetAvailabilityMapsInput>;
};

export type VetAvailabilityUpdateWithWhereUniqueWithoutClinicInput = {
  where: VetAvailabilityWhereUniqueInput;
  data: VetAvailabilityUncheckedUpdateWithoutClinicInput;
};

export type VetAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: VetAvailabilityWhereUniqueInput;
  data: VetAvailabilityUncheckedUpdateWithoutIntegrationInput;
};

export type VetAvailabilityUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetAvailabilitiesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutVetAvailabilityNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVetAvailabilitiesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutVetAvailabilityNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUpdateWithoutVetAvailabilityClinicRoomMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVetAvailabilitiesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetAvailabilitiesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUpdateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVetAvailabilitiesNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetAvailabilitiesNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUpdateresourceIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
  push?: Maybe<Array<Scalars['String']>>;
};

export type VetAvailabilityUpsertWithWhereUniqueWithoutClinicInput = {
  where: VetAvailabilityWhereUniqueInput;
  update: VetAvailabilityUncheckedUpdateWithoutClinicInput;
  create: VetAvailabilityUncheckedCreateWithoutClinicInput;
};

export type VetAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: VetAvailabilityWhereUniqueInput;
  update: VetAvailabilityUncheckedUpdateWithoutIntegrationInput;
  create: VetAvailabilityUncheckedCreateWithoutIntegrationInput;
};

export type VetAvailabilityUpsertWithoutVetAvailabilityClinicRoomMapsInput = {
  update: VetAvailabilityUncheckedUpdateWithoutVetAvailabilityClinicRoomMapsInput;
  create: VetAvailabilityUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput;
};

export type VetAvailabilityUpsertWithoutVetAvailabilityMapsInput = {
  update: VetAvailabilityUncheckedUpdateWithoutVetAvailabilityMapsInput;
  create: VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityVetAvailabilityPimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type VetAvailabilityWhereInput = {
  AND?: Maybe<Array<VetAvailabilityWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  isAvailable?: Maybe<BoolFilter>;
  isActive?: Maybe<BoolFilter>;
  isRecurring?: Maybe<BoolFilter>;
  recurringRules?: Maybe<StringNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  endDate?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  availabilityType?: Maybe<StringNullableFilter>;
  availabilityPimsType?: Maybe<StringNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  siteId?: Maybe<StringNullableFilter>;
  resourceIds?: Maybe<StringNullableListFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapListRelationFilter>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapListRelationFilter>;
};

export type VetAvailabilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  vetAvailabilityPimsIdAndIntegrationId?: Maybe<VetAvailabilityVetAvailabilityPimsIdAndIntegrationIdCompoundUniqueInput>;
};

export type VetDataIntegration = {
  __typename?: 'VetDataIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  installationId: Scalars['String'];
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  isMultiSite: Scalars['Boolean'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type VetDataIntegrationCountAggregateOutputType = {
  __typename?: 'VetDataIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  installationId: Scalars['Int'];
  siteId: Scalars['Int'];
  system: Scalars['Int'];
  invoiceCodeId: Scalars['Int'];
  defaultProviderId: Scalars['Int'];
  isMultiSite: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetDataIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
};

export type VetDataIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId: Scalars['String'];
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetdataInput>;
};

export type VetDataIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId: Scalars['String'];
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<VetDataIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<VetDataIntegrationWhereUniqueInput>;
};

export type VetDataIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: VetDataIntegrationWhereUniqueInput;
  create: VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type VetDataIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId: Scalars['String'];
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type VetDataIntegrationMaxAggregateOutputType = {
  __typename?: 'VetDataIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type VetDataIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
};

export type VetDataIntegrationMinAggregateOutputType = {
  __typename?: 'VetDataIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type VetDataIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
};

export enum VetDataIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  InstallationId = 'installationId',
  SiteId = 'siteId',
  InvoiceCodeId = 'invoiceCodeId',
  DefaultProviderId = 'defaultProviderId'
}

export type VetDataIntegrationOrderByRelevanceInput = {
  fields: Array<VetDataIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetDataIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  _count?: Maybe<VetDataIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<VetDataIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<VetDataIntegrationMinOrderByAggregateInput>;
};

export type VetDataIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<VetDataIntegrationOrderByRelevanceInput>;
};

export type VetDataIntegrationRelationFilter = {
  is?: Maybe<VetDataIntegrationWhereInput>;
  isNot?: Maybe<VetDataIntegrationWhereInput>;
};

export enum VetDataIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InstallationId = 'installationId',
  SiteId = 'siteId',
  System = 'system',
  InvoiceCodeId = 'invoiceCodeId',
  DefaultProviderId = 'defaultProviderId',
  IsMultiSite = 'isMultiSite'
}

export type VetDataIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetDataIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetDataIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetDataIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  installationId?: Maybe<StringWithAggregatesFilter>;
  siteId?: Maybe<StringNullableWithAggregatesFilter>;
  system?: Maybe<EnumVetDataIntegrationSystemWithAggregatesFilter>;
  invoiceCodeId?: Maybe<StringNullableWithAggregatesFilter>;
  defaultProviderId?: Maybe<StringNullableWithAggregatesFilter>;
  isMultiSite?: Maybe<BoolWithAggregatesFilter>;
};

export enum VetDataIntegrationSystem {
  Advantage = 'ADVANTAGE',
  Avimark = 'AVIMARK',
  Clientrax = 'CLIENTRAX',
  Cornerstone = 'CORNERSTONE',
  DvmManager = 'DVM_MANAGER',
  DvmaxSql = 'DVMAX_SQL',
  Evetpractice = 'EVETPRACTICE',
  HippoManager = 'HIPPO_MANAGER',
  Hvms = 'HVMS',
  Impromed = 'IMPROMED',
  ImpromedTriplecrown = 'IMPROMED_TRIPLECROWN',
  Intravet = 'INTRAVET',
  Stringsoft = 'STRINGSOFT',
  Via = 'VIA',
  Vtp = 'VTP'
}

export type VetDataIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId: Scalars['String'];
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutVetdataInput>;
};

export type VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId: Scalars['String'];
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type VetDataIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutVetdataNestedInput>;
};

export type VetDataIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type VetDataIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type VetDataIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetdataNestedInput>;
};

export type VetDataIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  create?: Maybe<VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<VetDataIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<VetDataIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetDataIntegrationWhereUniqueInput>;
  update?: Maybe<VetDataIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type VetDataIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
};

export type VetDataIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: VetDataIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type VetDataIntegrationWhereInput = {
  AND?: Maybe<Array<VetDataIntegrationWhereInput>>;
  OR?: Maybe<Array<VetDataIntegrationWhereInput>>;
  NOT?: Maybe<Array<VetDataIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  installationId?: Maybe<StringFilter>;
  siteId?: Maybe<StringNullableFilter>;
  system?: Maybe<EnumVetDataIntegrationSystemFilter>;
  invoiceCodeId?: Maybe<StringNullableFilter>;
  defaultProviderId?: Maybe<StringNullableFilter>;
  isMultiSite?: Maybe<BoolFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type VetDataIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VetInfo = {
  __typename?: 'VetInfo';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  currentClinic?: Maybe<Clinic>;
  consultationsId: Array<Consultation>;
  consultations: Array<Consultation>;
  user?: Maybe<User>;
  vetLicenses: Array<VetLicense>;
  clinics: Array<Clinic>;
  roles: Array<StaffRole>;
  _count: VetInfoCountOutputType;
};


export type VetInfoConsultationsIdArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
};


export type VetInfoConsultationsArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
};


export type VetInfoVetLicensesArgs = {
  where?: Maybe<VetLicenseWhereInput>;
  orderBy?: Maybe<VetLicenseOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetLicenseWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetLicenseScalarFieldEnum>;
};


export type VetInfoClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};


export type VetInfoRolesArgs = {
  where?: Maybe<StaffRoleWhereInput>;
  orderBy?: Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StaffRoleScalarFieldEnum>;
};

export type VetInfoCountAggregateOutputType = {
  __typename?: 'VetInfoCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  currentClinicId: Scalars['Int'];
  firebaseId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetInfoCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetInfoCountOutputType = {
  __typename?: 'VetInfoCountOutputType';
  consultationsId: Scalars['Int'];
  consultations: Scalars['Int'];
  vetLicenses: Scalars['Int'];
  clinics: Scalars['Int'];
  roles: Scalars['Int'];
};

export type VetInfoCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
};

export type VetInfoCreateManyCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoCreateManyCurrentClinicInputEnvelope = {
  data: Array<VetInfoCreateManyCurrentClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetInfoCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<VetInfoCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
};

export type VetInfoCreateNestedManyWithoutCurrentClinicInput = {
  create?: Maybe<Array<VetInfoCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutCurrentClinicInput>>;
  createMany?: Maybe<VetInfoCreateManyCurrentClinicInputEnvelope>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
};

export type VetInfoCreateNestedManyWithoutRolesInput = {
  create?: Maybe<Array<VetInfoCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutRolesInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
};

export type VetInfoCreateNestedOneWithoutConsultationsIdInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutConsultationsIdInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutConsultationsIdInput>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
};

export type VetInfoCreateNestedOneWithoutConsultationsInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutConsultationsInput>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
};

export type VetInfoCreateNestedOneWithoutUserInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutUserInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutUserInput>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
};

export type VetInfoCreateNestedOneWithoutVetLicensesInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutVetLicensesInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutVetLicensesInput>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
};

export type VetInfoCreateOrConnectWithoutClinicsInput = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoUncheckedCreateWithoutClinicsInput;
};

export type VetInfoCreateOrConnectWithoutConsultationsIdInput = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoUncheckedCreateWithoutConsultationsIdInput;
};

export type VetInfoCreateOrConnectWithoutConsultationsInput = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoUncheckedCreateWithoutConsultationsInput;
};

export type VetInfoCreateOrConnectWithoutCurrentClinicInput = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoUncheckedCreateWithoutCurrentClinicInput;
};

export type VetInfoCreateOrConnectWithoutRolesInput = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoUncheckedCreateWithoutRolesInput;
};

export type VetInfoCreateOrConnectWithoutUserInput = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoUncheckedCreateWithoutUserInput;
};

export type VetInfoCreateOrConnectWithoutVetLicensesInput = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoUncheckedCreateWithoutVetLicensesInput;
};

export type VetInfoCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
};

export type VetInfoCreateWithoutConsultationsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
};

export type VetInfoCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
};

export type VetInfoCreateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
};

export type VetInfoCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
};

export type VetInfoCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
};

export type VetInfoCreateWithoutVetLicensesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
};

export type VetInfoListRelationFilter = {
  every?: Maybe<VetInfoWhereInput>;
  some?: Maybe<VetInfoWhereInput>;
  none?: Maybe<VetInfoWhereInput>;
};

export type VetInfoMaxAggregateOutputType = {
  __typename?: 'VetInfoMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetInfoMinAggregateOutputType = {
  __typename?: 'VetInfoMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetInfoOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetInfoOrderByRelevanceFieldEnum {
  Id = 'id',
  CurrentClinicId = 'currentClinicId',
  FirebaseId = 'firebaseId'
}

export type VetInfoOrderByRelevanceInput = {
  fields: Array<VetInfoOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetInfoOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<VetInfoCountOrderByAggregateInput>;
  _max?: Maybe<VetInfoMaxOrderByAggregateInput>;
  _min?: Maybe<VetInfoMinOrderByAggregateInput>;
};

export type VetInfoOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  currentClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  consultationsId?: Maybe<ConsultationOrderByRelationAggregateInput>;
  consultations?: Maybe<ConsultationOrderByRelationAggregateInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  vetLicenses?: Maybe<VetLicenseOrderByRelationAggregateInput>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  roles?: Maybe<StaffRoleOrderByRelationAggregateInput>;
  _relevance?: Maybe<VetInfoOrderByRelevanceInput>;
};

export type VetInfoRelationFilter = {
  is?: Maybe<VetInfoWhereInput>;
  isNot?: Maybe<VetInfoWhereInput>;
};

export enum VetInfoScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  CurrentClinicId = 'currentClinicId',
  FirebaseId = 'firebaseId',
  UpdatedAt = 'updatedAt'
}

export type VetInfoScalarWhereInput = {
  AND?: Maybe<Array<VetInfoScalarWhereInput>>;
  OR?: Maybe<Array<VetInfoScalarWhereInput>>;
  NOT?: Maybe<Array<VetInfoScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currentClinicId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type VetInfoScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetInfoScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetInfoScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetInfoScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  currentClinicId?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type VetInfoUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
};

export type VetInfoUncheckedCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<VetInfoCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
};

export type VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput = {
  create?: Maybe<Array<VetInfoCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutCurrentClinicInput>>;
  createMany?: Maybe<VetInfoCreateManyCurrentClinicInputEnvelope>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
};

export type VetInfoUncheckedCreateNestedManyWithoutRolesInput = {
  create?: Maybe<Array<VetInfoCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutRolesInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
};

export type VetInfoUncheckedCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
};

export type VetInfoUncheckedCreateWithoutConsultationsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
};

export type VetInfoUncheckedCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
};

export type VetInfoUncheckedCreateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
};

export type VetInfoUncheckedCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
};

export type VetInfoUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
};

export type VetInfoUncheckedCreateWithoutVetLicensesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
};

export type VetInfoUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetNestedInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersNestedInput>;
};

export type VetInfoUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUncheckedUpdateManyWithoutClinicsNestedInput = {
  create?: Maybe<Array<VetInfoCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
};

export type VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput = {
  create?: Maybe<Array<VetInfoCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutCurrentClinicInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutCurrentClinicInput>>;
  createMany?: Maybe<VetInfoCreateManyCurrentClinicInputEnvelope>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutCurrentClinicInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutCurrentClinicInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
};

export type VetInfoUncheckedUpdateManyWithoutRolesNestedInput = {
  create?: Maybe<Array<VetInfoCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutRolesInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutRolesInput>>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutRolesInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutRolesInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
};

export type VetInfoUncheckedUpdateManyWithoutStaffIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUncheckedUpdateManyWithoutStaffInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUncheckedUpdateManyWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUncheckedUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetNestedInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersNestedInput>;
};

export type VetInfoUncheckedUpdateWithoutConsultationsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetNestedInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersNestedInput>;
};

export type VetInfoUncheckedUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseNestedInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersNestedInput>;
};

export type VetInfoUncheckedUpdateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetNestedInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersNestedInput>;
};

export type VetInfoUncheckedUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetNestedInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffNestedInput>;
};

export type VetInfoUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetNestedInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersNestedInput>;
  currentClinic?: Maybe<ClinicUpdateWithoutStaffIdInput>;
};

export type VetInfoUncheckedUpdateWithoutVetLicensesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetNestedInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffNestedInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersNestedInput>;
};

export type VetInfoUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdNestedInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetNestedInput>;
  user?: Maybe<UserUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStaffNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersNestedInput>;
};

export type VetInfoUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUpdateManyWithWhereWithoutClinicsInput = {
  where: VetInfoScalarWhereInput;
  data: VetInfoUncheckedUpdateManyWithoutStaffInput;
};

export type VetInfoUpdateManyWithWhereWithoutCurrentClinicInput = {
  where: VetInfoScalarWhereInput;
  data: VetInfoUncheckedUpdateManyWithoutStaffIdInput;
};

export type VetInfoUpdateManyWithWhereWithoutRolesInput = {
  where: VetInfoScalarWhereInput;
  data: VetInfoUncheckedUpdateManyWithoutUsersInput;
};

export type VetInfoUpdateManyWithoutClinicsNestedInput = {
  create?: Maybe<Array<VetInfoCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
};

export type VetInfoUpdateManyWithoutCurrentClinicNestedInput = {
  create?: Maybe<Array<VetInfoCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutCurrentClinicInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutCurrentClinicInput>>;
  createMany?: Maybe<VetInfoCreateManyCurrentClinicInputEnvelope>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutCurrentClinicInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutCurrentClinicInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
};

export type VetInfoUpdateManyWithoutRolesNestedInput = {
  create?: Maybe<Array<VetInfoCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutRolesInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutRolesInput>>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutRolesInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutRolesInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
};

export type VetInfoUpdateOneWithoutConsultationsIdNestedInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutConsultationsIdInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutConsultationsIdInput>;
  upsert?: Maybe<VetInfoUpsertWithoutConsultationsIdInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
  update?: Maybe<VetInfoUncheckedUpdateWithoutConsultationsIdInput>;
};

export type VetInfoUpdateOneWithoutConsultationsNestedInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutConsultationsInput>;
  upsert?: Maybe<VetInfoUpsertWithoutConsultationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
  update?: Maybe<VetInfoUncheckedUpdateWithoutConsultationsInput>;
};

export type VetInfoUpdateOneWithoutUserNestedInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutUserInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutUserInput>;
  upsert?: Maybe<VetInfoUpsertWithoutUserInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
  update?: Maybe<VetInfoUncheckedUpdateWithoutUserInput>;
};

export type VetInfoUpdateOneWithoutVetLicensesNestedInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutVetLicensesInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutVetLicensesInput>;
  upsert?: Maybe<VetInfoUpsertWithoutVetLicensesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
  update?: Maybe<VetInfoUncheckedUpdateWithoutVetLicensesInput>;
};

export type VetInfoUpdateWithWhereUniqueWithoutClinicsInput = {
  where: VetInfoWhereUniqueInput;
  data: VetInfoUncheckedUpdateWithoutClinicsInput;
};

export type VetInfoUpdateWithWhereUniqueWithoutCurrentClinicInput = {
  where: VetInfoWhereUniqueInput;
  data: VetInfoUncheckedUpdateWithoutCurrentClinicInput;
};

export type VetInfoUpdateWithWhereUniqueWithoutRolesInput = {
  where: VetInfoWhereUniqueInput;
  data: VetInfoUncheckedUpdateWithoutRolesInput;
};

export type VetInfoUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdNestedInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetNestedInput>;
  user?: Maybe<UserUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersNestedInput>;
};

export type VetInfoUpdateWithoutConsultationsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetNestedInput>;
  user?: Maybe<UserUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStaffNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersNestedInput>;
};

export type VetInfoUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdNestedInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseNestedInput>;
  user?: Maybe<UserUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStaffNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersNestedInput>;
};

export type VetInfoUpdateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetNestedInput>;
  user?: Maybe<UserUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStaffNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersNestedInput>;
};

export type VetInfoUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdNestedInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetNestedInput>;
  user?: Maybe<UserUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStaffNestedInput>;
};

export type VetInfoUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdNestedInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetNestedInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStaffNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersNestedInput>;
};

export type VetInfoUpdateWithoutVetLicensesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdNestedInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetNestedInput>;
  user?: Maybe<UserUpdateOneWithoutVetInfoNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStaffNestedInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersNestedInput>;
};

export type VetInfoUpsertWithWhereUniqueWithoutClinicsInput = {
  where: VetInfoWhereUniqueInput;
  update: VetInfoUncheckedUpdateWithoutClinicsInput;
  create: VetInfoUncheckedCreateWithoutClinicsInput;
};

export type VetInfoUpsertWithWhereUniqueWithoutCurrentClinicInput = {
  where: VetInfoWhereUniqueInput;
  update: VetInfoUncheckedUpdateWithoutCurrentClinicInput;
  create: VetInfoUncheckedCreateWithoutCurrentClinicInput;
};

export type VetInfoUpsertWithWhereUniqueWithoutRolesInput = {
  where: VetInfoWhereUniqueInput;
  update: VetInfoUncheckedUpdateWithoutRolesInput;
  create: VetInfoUncheckedCreateWithoutRolesInput;
};

export type VetInfoUpsertWithoutConsultationsIdInput = {
  update: VetInfoUncheckedUpdateWithoutConsultationsIdInput;
  create: VetInfoUncheckedCreateWithoutConsultationsIdInput;
};

export type VetInfoUpsertWithoutConsultationsInput = {
  update: VetInfoUncheckedUpdateWithoutConsultationsInput;
  create: VetInfoUncheckedCreateWithoutConsultationsInput;
};

export type VetInfoUpsertWithoutUserInput = {
  update: VetInfoUncheckedUpdateWithoutUserInput;
  create: VetInfoUncheckedCreateWithoutUserInput;
};

export type VetInfoUpsertWithoutVetLicensesInput = {
  update: VetInfoUncheckedUpdateWithoutVetLicensesInput;
  create: VetInfoUncheckedCreateWithoutVetLicensesInput;
};

export type VetInfoWhereInput = {
  AND?: Maybe<Array<VetInfoWhereInput>>;
  OR?: Maybe<Array<VetInfoWhereInput>>;
  NOT?: Maybe<Array<VetInfoWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currentClinicId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  currentClinic?: Maybe<ClinicWhereInput>;
  consultationsId?: Maybe<ConsultationListRelationFilter>;
  consultations?: Maybe<ConsultationListRelationFilter>;
  user?: Maybe<UserWhereInput>;
  vetLicenses?: Maybe<VetLicenseListRelationFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
  roles?: Maybe<StaffRoleListRelationFilter>;
};

export type VetInfoWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
};

export type VetLicense = {
  __typename?: 'VetLicense';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  licenseNumber: Scalars['String'];
  issuingAuthority: State;
  expirationDate: Scalars['DateTime'];
  vetInfoId?: Maybe<Scalars['String']>;
  licenseType: VetLicenseType;
  vetInfo?: Maybe<VetInfo>;
};

export type VetLicenseCountAggregateOutputType = {
  __typename?: 'VetLicenseCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  licenseNumber: Scalars['Int'];
  issuingAuthority: Scalars['Int'];
  expirationDate: Scalars['Int'];
  vetInfoId: Scalars['Int'];
  licenseType: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetLicenseCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
};

export type VetLicenseCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber: Scalars['String'];
  issuingAuthority: State;
  expirationDate: Scalars['DateTime'];
  licenseType: VetLicenseType;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutVetLicensesInput>;
};

export type VetLicenseCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber: Scalars['String'];
  issuingAuthority: State;
  expirationDate: Scalars['DateTime'];
  vetInfoId?: Maybe<Scalars['String']>;
  licenseType: VetLicenseType;
};

export type VetLicenseCreateManyVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber: Scalars['String'];
  issuingAuthority: State;
  expirationDate: Scalars['DateTime'];
  licenseType: VetLicenseType;
};

export type VetLicenseCreateManyVetInfoInputEnvelope = {
  data: Array<VetLicenseCreateManyVetInfoInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetLicenseCreateNestedManyWithoutVetInfoInput = {
  create?: Maybe<Array<VetLicenseCreateWithoutVetInfoInput>>;
  connectOrCreate?: Maybe<Array<VetLicenseCreateOrConnectWithoutVetInfoInput>>;
  createMany?: Maybe<VetLicenseCreateManyVetInfoInputEnvelope>;
  connect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
};

export type VetLicenseCreateOrConnectWithoutVetInfoInput = {
  where: VetLicenseWhereUniqueInput;
  create: VetLicenseUncheckedCreateWithoutVetInfoInput;
};

export type VetLicenseCreateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber: Scalars['String'];
  issuingAuthority: State;
  expirationDate: Scalars['DateTime'];
  licenseType: VetLicenseType;
};

export type VetLicenseListRelationFilter = {
  every?: Maybe<VetLicenseWhereInput>;
  some?: Maybe<VetLicenseWhereInput>;
  none?: Maybe<VetLicenseWhereInput>;
};

export type VetLicenseMaxAggregateOutputType = {
  __typename?: 'VetLicenseMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
};

export type VetLicenseMinAggregateOutputType = {
  __typename?: 'VetLicenseMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
};

export type VetLicenseOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetLicenseOrderByRelevanceFieldEnum {
  Id = 'id',
  LicenseNumber = 'licenseNumber',
  VetInfoId = 'vetInfoId'
}

export type VetLicenseOrderByRelevanceInput = {
  fields: Array<VetLicenseOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetLicenseOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
  _count?: Maybe<VetLicenseCountOrderByAggregateInput>;
  _max?: Maybe<VetLicenseMaxOrderByAggregateInput>;
  _min?: Maybe<VetLicenseMinOrderByAggregateInput>;
};

export type VetLicenseOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
  vetInfo?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<VetLicenseOrderByRelevanceInput>;
};

export enum VetLicenseScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  LicenseNumber = 'licenseNumber',
  IssuingAuthority = 'issuingAuthority',
  ExpirationDate = 'expirationDate',
  VetInfoId = 'vetInfoId',
  LicenseType = 'licenseType'
}

export type VetLicenseScalarWhereInput = {
  AND?: Maybe<Array<VetLicenseScalarWhereInput>>;
  OR?: Maybe<Array<VetLicenseScalarWhereInput>>;
  NOT?: Maybe<Array<VetLicenseScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  licenseNumber?: Maybe<StringFilter>;
  issuingAuthority?: Maybe<EnumStateFilter>;
  expirationDate?: Maybe<DateTimeFilter>;
  vetInfoId?: Maybe<StringNullableFilter>;
  licenseType?: Maybe<EnumVetLicenseTypeFilter>;
};

export type VetLicenseScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetLicenseScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetLicenseScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetLicenseScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  licenseNumber?: Maybe<StringWithAggregatesFilter>;
  issuingAuthority?: Maybe<EnumStateWithAggregatesFilter>;
  expirationDate?: Maybe<DateTimeWithAggregatesFilter>;
  vetInfoId?: Maybe<StringNullableWithAggregatesFilter>;
  licenseType?: Maybe<EnumVetLicenseTypeWithAggregatesFilter>;
};

export enum VetLicenseType {
  Veterinary = 'Veterinary',
  Technician = 'Technician',
  Dea = 'DEA'
}

export type VetLicenseUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber: Scalars['String'];
  issuingAuthority: State;
  expirationDate: Scalars['DateTime'];
  vetInfoId?: Maybe<Scalars['String']>;
  licenseType: VetLicenseType;
};

export type VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput = {
  create?: Maybe<Array<VetLicenseCreateWithoutVetInfoInput>>;
  connectOrCreate?: Maybe<Array<VetLicenseCreateOrConnectWithoutVetInfoInput>>;
  createMany?: Maybe<VetLicenseCreateManyVetInfoInputEnvelope>;
  connect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
};

export type VetLicenseUncheckedCreateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber: Scalars['String'];
  issuingAuthority: State;
  expirationDate: Scalars['DateTime'];
  licenseType: VetLicenseType;
};

export type VetLicenseUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput = {
  create?: Maybe<Array<VetLicenseCreateWithoutVetInfoInput>>;
  connectOrCreate?: Maybe<Array<VetLicenseCreateOrConnectWithoutVetInfoInput>>;
  upsert?: Maybe<Array<VetLicenseUpsertWithWhereUniqueWithoutVetInfoInput>>;
  createMany?: Maybe<VetLicenseCreateManyVetInfoInputEnvelope>;
  set?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  delete?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  connect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  update?: Maybe<Array<VetLicenseUpdateWithWhereUniqueWithoutVetInfoInput>>;
  updateMany?: Maybe<Array<VetLicenseUpdateManyWithWhereWithoutVetInfoInput>>;
  deleteMany?: Maybe<Array<VetLicenseScalarWhereInput>>;
};

export type VetLicenseUncheckedUpdateManyWithoutVetLicensesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseUncheckedUpdateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  licenseType?: Maybe<VetLicenseType>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutVetLicensesNestedInput>;
};

export type VetLicenseUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseUpdateManyWithWhereWithoutVetInfoInput = {
  where: VetLicenseScalarWhereInput;
  data: VetLicenseUncheckedUpdateManyWithoutVetLicensesInput;
};

export type VetLicenseUpdateManyWithoutVetInfoNestedInput = {
  create?: Maybe<Array<VetLicenseCreateWithoutVetInfoInput>>;
  connectOrCreate?: Maybe<Array<VetLicenseCreateOrConnectWithoutVetInfoInput>>;
  upsert?: Maybe<Array<VetLicenseUpsertWithWhereUniqueWithoutVetInfoInput>>;
  createMany?: Maybe<VetLicenseCreateManyVetInfoInputEnvelope>;
  set?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  delete?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  connect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  update?: Maybe<Array<VetLicenseUpdateWithWhereUniqueWithoutVetInfoInput>>;
  updateMany?: Maybe<Array<VetLicenseUpdateManyWithWhereWithoutVetInfoInput>>;
  deleteMany?: Maybe<Array<VetLicenseScalarWhereInput>>;
};

export type VetLicenseUpdateWithWhereUniqueWithoutVetInfoInput = {
  where: VetLicenseWhereUniqueInput;
  data: VetLicenseUncheckedUpdateWithoutVetInfoInput;
};

export type VetLicenseUpdateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseUpsertWithWhereUniqueWithoutVetInfoInput = {
  where: VetLicenseWhereUniqueInput;
  update: VetLicenseUncheckedUpdateWithoutVetInfoInput;
  create: VetLicenseUncheckedCreateWithoutVetInfoInput;
};

export type VetLicenseWhereInput = {
  AND?: Maybe<Array<VetLicenseWhereInput>>;
  OR?: Maybe<Array<VetLicenseWhereInput>>;
  NOT?: Maybe<Array<VetLicenseWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  licenseNumber?: Maybe<StringFilter>;
  issuingAuthority?: Maybe<EnumStateFilter>;
  expirationDate?: Maybe<DateTimeFilter>;
  vetInfoId?: Maybe<StringNullableFilter>;
  licenseType?: Maybe<EnumVetLicenseTypeFilter>;
  vetInfo?: Maybe<VetInfoWhereInput>;
};

export type VetLicenseWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VetsourceCodeTag = {
  __typename?: 'VetsourceCodeTag';
  id: Scalars['String'];
  name: Scalars['String'];
  externalId: Scalars['Int'];
  type: VetsourceCodeTagType;
  Service: Array<Service>;
  _count: VetsourceCodeTagCountOutputType;
};


export type VetsourceCodeTagServiceArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceScalarFieldEnum>;
};

export type VetsourceCodeTagAvgAggregateOutputType = {
  __typename?: 'VetsourceCodeTagAvgAggregateOutputType';
  externalId?: Maybe<Scalars['Float']>;
};

export type VetsourceCodeTagAvgOrderByAggregateInput = {
  externalId?: Maybe<SortOrder>;
};

export type VetsourceCodeTagCountAggregateOutputType = {
  __typename?: 'VetsourceCodeTagCountAggregateOutputType';
  id: Scalars['Int'];
  name: Scalars['Int'];
  externalId: Scalars['Int'];
  type: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetsourceCodeTagCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type VetsourceCodeTagCountOutputType = {
  __typename?: 'VetsourceCodeTagCountOutputType';
  Service: Scalars['Int'];
};

export type VetsourceCodeTagCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  externalId: Scalars['Int'];
  type: VetsourceCodeTagType;
  Service?: Maybe<ServiceCreateNestedManyWithoutVetsourceCodeTagsInput>;
};

export type VetsourceCodeTagCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  externalId: Scalars['Int'];
  type: VetsourceCodeTagType;
};

export type VetsourceCodeTagCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<VetsourceCodeTagCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<VetsourceCodeTagCreateOrConnectWithoutServiceInput>>;
  connect?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
};

export type VetsourceCodeTagCreateOrConnectWithoutServiceInput = {
  where: VetsourceCodeTagWhereUniqueInput;
  create: VetsourceCodeTagUncheckedCreateWithoutServiceInput;
};

export type VetsourceCodeTagCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  externalId: Scalars['Int'];
  type: VetsourceCodeTagType;
};

export type VetsourceCodeTagListRelationFilter = {
  every?: Maybe<VetsourceCodeTagWhereInput>;
  some?: Maybe<VetsourceCodeTagWhereInput>;
  none?: Maybe<VetsourceCodeTagWhereInput>;
};

export type VetsourceCodeTagMaxAggregateOutputType = {
  __typename?: 'VetsourceCodeTagMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type VetsourceCodeTagMinAggregateOutputType = {
  __typename?: 'VetsourceCodeTagMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type VetsourceCodeTagOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetsourceCodeTagOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name'
}

export type VetsourceCodeTagOrderByRelevanceInput = {
  fields: Array<VetsourceCodeTagOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetsourceCodeTagOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  _count?: Maybe<VetsourceCodeTagCountOrderByAggregateInput>;
  _avg?: Maybe<VetsourceCodeTagAvgOrderByAggregateInput>;
  _max?: Maybe<VetsourceCodeTagMaxOrderByAggregateInput>;
  _min?: Maybe<VetsourceCodeTagMinOrderByAggregateInput>;
  _sum?: Maybe<VetsourceCodeTagSumOrderByAggregateInput>;
};

export type VetsourceCodeTagOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  Service?: Maybe<ServiceOrderByRelationAggregateInput>;
  _relevance?: Maybe<VetsourceCodeTagOrderByRelevanceInput>;
};

export enum VetsourceCodeTagScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  ExternalId = 'externalId',
  Type = 'type'
}

export type VetsourceCodeTagScalarWhereInput = {
  AND?: Maybe<Array<VetsourceCodeTagScalarWhereInput>>;
  OR?: Maybe<Array<VetsourceCodeTagScalarWhereInput>>;
  NOT?: Maybe<Array<VetsourceCodeTagScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  externalId?: Maybe<IntFilter>;
  type?: Maybe<EnumVetsourceCodeTagTypeFilter>;
};

export type VetsourceCodeTagScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetsourceCodeTagScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetsourceCodeTagScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetsourceCodeTagScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  externalId?: Maybe<IntWithAggregatesFilter>;
  type?: Maybe<EnumVetsourceCodeTagTypeWithAggregatesFilter>;
};

export type VetsourceCodeTagSumAggregateOutputType = {
  __typename?: 'VetsourceCodeTagSumAggregateOutputType';
  externalId?: Maybe<Scalars['Int']>;
};

export type VetsourceCodeTagSumOrderByAggregateInput = {
  externalId?: Maybe<SortOrder>;
};

export enum VetsourceCodeTagType {
  Dental = 'Dental',
  Diagnostic = 'Diagnostic',
  Diet = 'Diet',
  Vaccine = 'Vaccine',
  WellnessPlan = 'WellnessPlan'
}

export type VetsourceCodeTagUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  externalId: Scalars['Int'];
  type: VetsourceCodeTagType;
  Service?: Maybe<ServiceUncheckedCreateNestedManyWithoutVetsourceCodeTagsInput>;
};

export type VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<VetsourceCodeTagCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<VetsourceCodeTagCreateOrConnectWithoutServiceInput>>;
  connect?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
};

export type VetsourceCodeTagUncheckedCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  externalId: Scalars['Int'];
  type: VetsourceCodeTagType;
};

export type VetsourceCodeTagUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  type?: Maybe<VetsourceCodeTagType>;
  Service?: Maybe<ServiceUncheckedUpdateManyWithoutVetsourceCodeTagsNestedInput>;
};

export type VetsourceCodeTagUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput = {
  create?: Maybe<Array<VetsourceCodeTagCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<VetsourceCodeTagCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<VetsourceCodeTagUpsertWithWhereUniqueWithoutServiceInput>>;
  set?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  delete?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  connect?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  update?: Maybe<Array<VetsourceCodeTagUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<VetsourceCodeTagUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<VetsourceCodeTagScalarWhereInput>>;
};

export type VetsourceCodeTagUncheckedUpdateManyWithoutVetsourceCodeTagsInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagUncheckedUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagUpdateInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  type?: Maybe<VetsourceCodeTagType>;
  Service?: Maybe<ServiceUpdateManyWithoutVetsourceCodeTagsNestedInput>;
};

export type VetsourceCodeTagUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagUpdateManyWithWhereWithoutServiceInput = {
  where: VetsourceCodeTagScalarWhereInput;
  data: VetsourceCodeTagUncheckedUpdateManyWithoutVetsourceCodeTagsInput;
};

export type VetsourceCodeTagUpdateManyWithoutServiceNestedInput = {
  create?: Maybe<Array<VetsourceCodeTagCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<VetsourceCodeTagCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<VetsourceCodeTagUpsertWithWhereUniqueWithoutServiceInput>>;
  set?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  delete?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  connect?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  update?: Maybe<Array<VetsourceCodeTagUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<VetsourceCodeTagUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<VetsourceCodeTagScalarWhereInput>>;
};

export type VetsourceCodeTagUpdateWithWhereUniqueWithoutServiceInput = {
  where: VetsourceCodeTagWhereUniqueInput;
  data: VetsourceCodeTagUncheckedUpdateWithoutServiceInput;
};

export type VetsourceCodeTagUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagUpsertWithWhereUniqueWithoutServiceInput = {
  where: VetsourceCodeTagWhereUniqueInput;
  update: VetsourceCodeTagUncheckedUpdateWithoutServiceInput;
  create: VetsourceCodeTagUncheckedCreateWithoutServiceInput;
};

export type VetsourceCodeTagWhereInput = {
  AND?: Maybe<Array<VetsourceCodeTagWhereInput>>;
  OR?: Maybe<Array<VetsourceCodeTagWhereInput>>;
  NOT?: Maybe<Array<VetsourceCodeTagWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  externalId?: Maybe<IntFilter>;
  type?: Maybe<EnumVetsourceCodeTagTypeFilter>;
  Service?: Maybe<ServiceListRelationFilter>;
};

export type VetsourceCodeTagWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type VetsourceInstallation = {
  __typename?: 'VetsourceInstallation';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId?: Maybe<Scalars['String']>;
  pims: Scalars['String'];
  connectivityState: VetsourceInstallationConnectivityState;
  newConnectionPartner?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  segment: Scalars['String'];
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
};

export enum VetsourceInstallationConnectivityState {
  NeedsConnection = 'NeedsConnection',
  AlreadyConnected = 'AlreadyConnected'
}

export type VetsourceInstallationCountAggregateOutputType = {
  __typename?: 'VetsourceInstallationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  pims: Scalars['Int'];
  connectivityState: Scalars['Int'];
  newConnectionPartner: Scalars['Int'];
  isVetDataInstalled: Scalars['Int'];
  segment: Scalars['Int'];
  syncVetId: Scalars['Int'];
  syncVetRegistrationKey: Scalars['Int'];
  syncVetEulaUrl: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetsourceInstallationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  pims?: Maybe<SortOrder>;
  connectivityState?: Maybe<SortOrder>;
  newConnectionPartner?: Maybe<SortOrder>;
  isVetDataInstalled?: Maybe<SortOrder>;
  segment?: Maybe<SortOrder>;
  syncVetId?: Maybe<SortOrder>;
  syncVetRegistrationKey?: Maybe<SortOrder>;
  syncVetEulaUrl?: Maybe<SortOrder>;
};

export type VetsourceInstallationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pims: Scalars['String'];
  connectivityState: VetsourceInstallationConnectivityState;
  newConnectionPartner?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  segment: Scalars['String'];
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutVetsourceInstallationInput>;
};

export type VetsourceInstallationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  pims: Scalars['String'];
  connectivityState: VetsourceInstallationConnectivityState;
  newConnectionPartner?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  segment: Scalars['String'];
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
};

export type VetsourceInstallationCreateNestedOneWithoutClinicInput = {
  create?: Maybe<VetsourceInstallationUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<VetsourceInstallationCreateOrConnectWithoutClinicInput>;
  connect?: Maybe<VetsourceInstallationWhereUniqueInput>;
};

export type VetsourceInstallationCreateOrConnectWithoutClinicInput = {
  where: VetsourceInstallationWhereUniqueInput;
  create: VetsourceInstallationUncheckedCreateWithoutClinicInput;
};

export type VetsourceInstallationCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pims: Scalars['String'];
  connectivityState: VetsourceInstallationConnectivityState;
  newConnectionPartner?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  segment: Scalars['String'];
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
};

export type VetsourceInstallationMaxAggregateOutputType = {
  __typename?: 'VetsourceInstallationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  pims?: Maybe<Scalars['String']>;
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
};

export type VetsourceInstallationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  pims?: Maybe<SortOrder>;
  connectivityState?: Maybe<SortOrder>;
  newConnectionPartner?: Maybe<SortOrder>;
  isVetDataInstalled?: Maybe<SortOrder>;
  segment?: Maybe<SortOrder>;
  syncVetId?: Maybe<SortOrder>;
  syncVetRegistrationKey?: Maybe<SortOrder>;
  syncVetEulaUrl?: Maybe<SortOrder>;
};

export type VetsourceInstallationMinAggregateOutputType = {
  __typename?: 'VetsourceInstallationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  pims?: Maybe<Scalars['String']>;
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
};

export type VetsourceInstallationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  pims?: Maybe<SortOrder>;
  connectivityState?: Maybe<SortOrder>;
  newConnectionPartner?: Maybe<SortOrder>;
  isVetDataInstalled?: Maybe<SortOrder>;
  segment?: Maybe<SortOrder>;
  syncVetId?: Maybe<SortOrder>;
  syncVetRegistrationKey?: Maybe<SortOrder>;
  syncVetEulaUrl?: Maybe<SortOrder>;
};

export enum VetsourceInstallationOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  Pims = 'pims',
  NewConnectionPartner = 'newConnectionPartner',
  Segment = 'segment',
  SyncVetId = 'syncVetId',
  SyncVetRegistrationKey = 'syncVetRegistrationKey',
  SyncVetEulaUrl = 'syncVetEulaUrl'
}

export type VetsourceInstallationOrderByRelevanceInput = {
  fields: Array<VetsourceInstallationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetsourceInstallationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  pims?: Maybe<SortOrder>;
  connectivityState?: Maybe<SortOrder>;
  newConnectionPartner?: Maybe<SortOrder>;
  isVetDataInstalled?: Maybe<SortOrder>;
  segment?: Maybe<SortOrder>;
  syncVetId?: Maybe<SortOrder>;
  syncVetRegistrationKey?: Maybe<SortOrder>;
  syncVetEulaUrl?: Maybe<SortOrder>;
  _count?: Maybe<VetsourceInstallationCountOrderByAggregateInput>;
  _max?: Maybe<VetsourceInstallationMaxOrderByAggregateInput>;
  _min?: Maybe<VetsourceInstallationMinOrderByAggregateInput>;
};

export type VetsourceInstallationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  pims?: Maybe<SortOrder>;
  connectivityState?: Maybe<SortOrder>;
  newConnectionPartner?: Maybe<SortOrder>;
  isVetDataInstalled?: Maybe<SortOrder>;
  segment?: Maybe<SortOrder>;
  syncVetId?: Maybe<SortOrder>;
  syncVetRegistrationKey?: Maybe<SortOrder>;
  syncVetEulaUrl?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<VetsourceInstallationOrderByRelevanceInput>;
};

export type VetsourceInstallationRelationFilter = {
  is?: Maybe<VetsourceInstallationWhereInput>;
  isNot?: Maybe<VetsourceInstallationWhereInput>;
};

export enum VetsourceInstallationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  Pims = 'pims',
  ConnectivityState = 'connectivityState',
  NewConnectionPartner = 'newConnectionPartner',
  IsVetDataInstalled = 'isVetDataInstalled',
  Segment = 'segment',
  SyncVetId = 'syncVetId',
  SyncVetRegistrationKey = 'syncVetRegistrationKey',
  SyncVetEulaUrl = 'syncVetEulaUrl'
}

export type VetsourceInstallationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetsourceInstallationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetsourceInstallationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetsourceInstallationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  pims?: Maybe<StringWithAggregatesFilter>;
  connectivityState?: Maybe<EnumVetsourceInstallationConnectivityStateWithAggregatesFilter>;
  newConnectionPartner?: Maybe<StringNullableWithAggregatesFilter>;
  isVetDataInstalled?: Maybe<BoolNullableWithAggregatesFilter>;
  segment?: Maybe<StringWithAggregatesFilter>;
  syncVetId?: Maybe<StringNullableWithAggregatesFilter>;
  syncVetRegistrationKey?: Maybe<StringNullableWithAggregatesFilter>;
  syncVetEulaUrl?: Maybe<StringNullableWithAggregatesFilter>;
};

export type VetsourceInstallationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  pims: Scalars['String'];
  connectivityState: VetsourceInstallationConnectivityState;
  newConnectionPartner?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  segment: Scalars['String'];
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
};

export type VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput = {
  create?: Maybe<VetsourceInstallationUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<VetsourceInstallationCreateOrConnectWithoutClinicInput>;
  connect?: Maybe<VetsourceInstallationWhereUniqueInput>;
};

export type VetsourceInstallationUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pims: Scalars['String'];
  connectivityState: VetsourceInstallationConnectivityState;
  newConnectionPartner?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  segment: Scalars['String'];
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
};

export type VetsourceInstallationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  pims?: Maybe<Scalars['String']>;
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
};

export type VetsourceInstallationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  pims?: Maybe<Scalars['String']>;
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
};

export type VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput = {
  create?: Maybe<VetsourceInstallationUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<VetsourceInstallationCreateOrConnectWithoutClinicInput>;
  upsert?: Maybe<VetsourceInstallationUpsertWithoutClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetsourceInstallationWhereUniqueInput>;
  update?: Maybe<VetsourceInstallationUncheckedUpdateWithoutClinicInput>;
};

export type VetsourceInstallationUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pims?: Maybe<Scalars['String']>;
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
};

export type VetsourceInstallationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pims?: Maybe<Scalars['String']>;
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutVetsourceInstallationNestedInput>;
};

export type VetsourceInstallationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pims?: Maybe<Scalars['String']>;
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
};

export type VetsourceInstallationUpdateOneWithoutClinicNestedInput = {
  create?: Maybe<VetsourceInstallationUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<VetsourceInstallationCreateOrConnectWithoutClinicInput>;
  upsert?: Maybe<VetsourceInstallationUpsertWithoutClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetsourceInstallationWhereUniqueInput>;
  update?: Maybe<VetsourceInstallationUncheckedUpdateWithoutClinicInput>;
};

export type VetsourceInstallationUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pims?: Maybe<Scalars['String']>;
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
};

export type VetsourceInstallationUpsertWithoutClinicInput = {
  update: VetsourceInstallationUncheckedUpdateWithoutClinicInput;
  create: VetsourceInstallationUncheckedCreateWithoutClinicInput;
};

export type VetsourceInstallationWhereInput = {
  AND?: Maybe<Array<VetsourceInstallationWhereInput>>;
  OR?: Maybe<Array<VetsourceInstallationWhereInput>>;
  NOT?: Maybe<Array<VetsourceInstallationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  pims?: Maybe<StringFilter>;
  connectivityState?: Maybe<EnumVetsourceInstallationConnectivityStateFilter>;
  newConnectionPartner?: Maybe<StringNullableFilter>;
  isVetDataInstalled?: Maybe<BoolNullableFilter>;
  segment?: Maybe<StringFilter>;
  syncVetId?: Maybe<StringNullableFilter>;
  syncVetRegistrationKey?: Maybe<StringNullableFilter>;
  syncVetEulaUrl?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type VetsourceInstallationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type VetsourceRevenueCategory = {
  __typename?: 'VetsourceRevenueCategory';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  externalId: Scalars['Int'];
  name: Scalars['String'];
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategory>;
  childrenVetsourceRevenueCategory: Array<VetsourceRevenueCategory>;
  Service: Array<Service>;
  _count: VetsourceRevenueCategoryCountOutputType;
};


export type VetsourceRevenueCategoryChildrenVetsourceRevenueCategoryArgs = {
  where?: Maybe<VetsourceRevenueCategoryWhereInput>;
  orderBy?: Maybe<VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetsourceRevenueCategoryScalarFieldEnum>;
};


export type VetsourceRevenueCategoryServiceArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceScalarFieldEnum>;
};

export type VetsourceRevenueCategoryAvgAggregateOutputType = {
  __typename?: 'VetsourceRevenueCategoryAvgAggregateOutputType';
  externalId?: Maybe<Scalars['Float']>;
  accountNumber?: Maybe<Scalars['Float']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Float']>;
};

export type VetsourceRevenueCategoryAvgOrderByAggregateInput = {
  externalId?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<SortOrder>;
};

export type VetsourceRevenueCategoryCountAggregateOutputType = {
  __typename?: 'VetsourceRevenueCategoryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  externalId: Scalars['Int'];
  name: Scalars['Int'];
  accountNumber: Scalars['Int'];
  accountName: Scalars['Int'];
  parentVetsourceRevenueCategoryExternalId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetsourceRevenueCategoryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  accountName?: Maybe<SortOrder>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<SortOrder>;
};

export type VetsourceRevenueCategoryCountOutputType = {
  __typename?: 'VetsourceRevenueCategoryCountOutputType';
  childrenVetsourceRevenueCategory: Scalars['Int'];
  Service: Scalars['Int'];
};

export type VetsourceRevenueCategoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  name: Scalars['String'];
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutChildrenVetsourceRevenueCategoryInput>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedManyWithoutParentVetsourceRevenueCategoryInput>;
  Service?: Maybe<ServiceCreateNestedManyWithoutVetsourceRevenueCategoryInput>;
};

export type VetsourceRevenueCategoryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  name: Scalars['String'];
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
};

export type VetsourceRevenueCategoryCreateManyParentVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  name: Scalars['String'];
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
};

export type VetsourceRevenueCategoryCreateManyParentVetsourceRevenueCategoryInputEnvelope = {
  data: Array<VetsourceRevenueCategoryCreateManyParentVetsourceRevenueCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetsourceRevenueCategoryCreateNestedManyWithoutParentVetsourceRevenueCategoryInput = {
  create?: Maybe<Array<VetsourceRevenueCategoryCreateWithoutParentVetsourceRevenueCategoryInput>>;
  connectOrCreate?: Maybe<Array<VetsourceRevenueCategoryCreateOrConnectWithoutParentVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<VetsourceRevenueCategoryCreateManyParentVetsourceRevenueCategoryInputEnvelope>;
  connect?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
};

export type VetsourceRevenueCategoryCreateNestedOneWithoutChildrenVetsourceRevenueCategoryInput = {
  create?: Maybe<VetsourceRevenueCategoryUncheckedCreateWithoutChildrenVetsourceRevenueCategoryInput>;
  connectOrCreate?: Maybe<VetsourceRevenueCategoryCreateOrConnectWithoutChildrenVetsourceRevenueCategoryInput>;
  connect?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
};

export type VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput = {
  create?: Maybe<VetsourceRevenueCategoryUncheckedCreateWithoutServiceInput>;
  connectOrCreate?: Maybe<VetsourceRevenueCategoryCreateOrConnectWithoutServiceInput>;
  connect?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
};

export type VetsourceRevenueCategoryCreateOrConnectWithoutChildrenVetsourceRevenueCategoryInput = {
  where: VetsourceRevenueCategoryWhereUniqueInput;
  create: VetsourceRevenueCategoryUncheckedCreateWithoutChildrenVetsourceRevenueCategoryInput;
};

export type VetsourceRevenueCategoryCreateOrConnectWithoutParentVetsourceRevenueCategoryInput = {
  where: VetsourceRevenueCategoryWhereUniqueInput;
  create: VetsourceRevenueCategoryUncheckedCreateWithoutParentVetsourceRevenueCategoryInput;
};

export type VetsourceRevenueCategoryCreateOrConnectWithoutServiceInput = {
  where: VetsourceRevenueCategoryWhereUniqueInput;
  create: VetsourceRevenueCategoryUncheckedCreateWithoutServiceInput;
};

export type VetsourceRevenueCategoryCreateWithoutChildrenVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  name: Scalars['String'];
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutChildrenVetsourceRevenueCategoryInput>;
  Service?: Maybe<ServiceCreateNestedManyWithoutVetsourceRevenueCategoryInput>;
};

export type VetsourceRevenueCategoryCreateWithoutParentVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  name: Scalars['String'];
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedManyWithoutParentVetsourceRevenueCategoryInput>;
  Service?: Maybe<ServiceCreateNestedManyWithoutVetsourceRevenueCategoryInput>;
};

export type VetsourceRevenueCategoryCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  name: Scalars['String'];
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutChildrenVetsourceRevenueCategoryInput>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedManyWithoutParentVetsourceRevenueCategoryInput>;
};

export type VetsourceRevenueCategoryListRelationFilter = {
  every?: Maybe<VetsourceRevenueCategoryWhereInput>;
  some?: Maybe<VetsourceRevenueCategoryWhereInput>;
  none?: Maybe<VetsourceRevenueCategoryWhereInput>;
};

export type VetsourceRevenueCategoryMaxAggregateOutputType = {
  __typename?: 'VetsourceRevenueCategoryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
};

export type VetsourceRevenueCategoryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  accountName?: Maybe<SortOrder>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<SortOrder>;
};

export type VetsourceRevenueCategoryMinAggregateOutputType = {
  __typename?: 'VetsourceRevenueCategoryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
};

export type VetsourceRevenueCategoryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  accountName?: Maybe<SortOrder>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<SortOrder>;
};

export type VetsourceRevenueCategoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetsourceRevenueCategoryOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  AccountName = 'accountName'
}

export type VetsourceRevenueCategoryOrderByRelevanceInput = {
  fields: Array<VetsourceRevenueCategoryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetsourceRevenueCategoryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  accountName?: Maybe<SortOrder>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<SortOrder>;
  _count?: Maybe<VetsourceRevenueCategoryCountOrderByAggregateInput>;
  _avg?: Maybe<VetsourceRevenueCategoryAvgOrderByAggregateInput>;
  _max?: Maybe<VetsourceRevenueCategoryMaxOrderByAggregateInput>;
  _min?: Maybe<VetsourceRevenueCategoryMinOrderByAggregateInput>;
  _sum?: Maybe<VetsourceRevenueCategorySumOrderByAggregateInput>;
};

export type VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  accountName?: Maybe<SortOrder>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<SortOrder>;
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryOrderByRelationAggregateInput>;
  Service?: Maybe<ServiceOrderByRelationAggregateInput>;
  _relevance?: Maybe<VetsourceRevenueCategoryOrderByRelevanceInput>;
};

export type VetsourceRevenueCategoryRelationFilter = {
  is?: Maybe<VetsourceRevenueCategoryWhereInput>;
  isNot?: Maybe<VetsourceRevenueCategoryWhereInput>;
};

export enum VetsourceRevenueCategoryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ExternalId = 'externalId',
  Name = 'name',
  AccountNumber = 'accountNumber',
  AccountName = 'accountName',
  ParentVetsourceRevenueCategoryExternalId = 'parentVetsourceRevenueCategoryExternalId'
}

export type VetsourceRevenueCategoryScalarWhereInput = {
  AND?: Maybe<Array<VetsourceRevenueCategoryScalarWhereInput>>;
  OR?: Maybe<Array<VetsourceRevenueCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<VetsourceRevenueCategoryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  externalId?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  accountNumber?: Maybe<IntNullableFilter>;
  accountName?: Maybe<StringNullableFilter>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<IntNullableFilter>;
};

export type VetsourceRevenueCategoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetsourceRevenueCategoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetsourceRevenueCategoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetsourceRevenueCategoryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  externalId?: Maybe<IntWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  accountNumber?: Maybe<IntNullableWithAggregatesFilter>;
  accountName?: Maybe<StringNullableWithAggregatesFilter>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<IntNullableWithAggregatesFilter>;
};

export type VetsourceRevenueCategorySumAggregateOutputType = {
  __typename?: 'VetsourceRevenueCategorySumAggregateOutputType';
  externalId?: Maybe<Scalars['Int']>;
  accountNumber?: Maybe<Scalars['Int']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
};

export type VetsourceRevenueCategorySumOrderByAggregateInput = {
  externalId?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<SortOrder>;
};

export type VetsourceRevenueCategoryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  name: Scalars['String'];
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUncheckedCreateNestedManyWithoutParentVetsourceRevenueCategoryInput>;
  Service?: Maybe<ServiceUncheckedCreateNestedManyWithoutVetsourceRevenueCategoryInput>;
};

export type VetsourceRevenueCategoryUncheckedCreateNestedManyWithoutParentVetsourceRevenueCategoryInput = {
  create?: Maybe<Array<VetsourceRevenueCategoryCreateWithoutParentVetsourceRevenueCategoryInput>>;
  connectOrCreate?: Maybe<Array<VetsourceRevenueCategoryCreateOrConnectWithoutParentVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<VetsourceRevenueCategoryCreateManyParentVetsourceRevenueCategoryInputEnvelope>;
  connect?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
};

export type VetsourceRevenueCategoryUncheckedCreateWithoutChildrenVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  name: Scalars['String'];
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  Service?: Maybe<ServiceUncheckedCreateNestedManyWithoutVetsourceRevenueCategoryInput>;
};

export type VetsourceRevenueCategoryUncheckedCreateWithoutParentVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  name: Scalars['String'];
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUncheckedCreateNestedManyWithoutParentVetsourceRevenueCategoryInput>;
  Service?: Maybe<ServiceUncheckedCreateNestedManyWithoutVetsourceRevenueCategoryInput>;
};

export type VetsourceRevenueCategoryUncheckedCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  name: Scalars['String'];
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUncheckedCreateNestedManyWithoutParentVetsourceRevenueCategoryInput>;
};

export type VetsourceRevenueCategoryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUncheckedUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput>;
  Service?: Maybe<ServiceUncheckedUpdateManyWithoutVetsourceRevenueCategoryNestedInput>;
};

export type VetsourceRevenueCategoryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
};

export type VetsourceRevenueCategoryUncheckedUpdateManyWithoutChildrenVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
};

export type VetsourceRevenueCategoryUncheckedUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput = {
  create?: Maybe<Array<VetsourceRevenueCategoryCreateWithoutParentVetsourceRevenueCategoryInput>>;
  connectOrCreate?: Maybe<Array<VetsourceRevenueCategoryCreateOrConnectWithoutParentVetsourceRevenueCategoryInput>>;
  upsert?: Maybe<Array<VetsourceRevenueCategoryUpsertWithWhereUniqueWithoutParentVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<VetsourceRevenueCategoryCreateManyParentVetsourceRevenueCategoryInputEnvelope>;
  set?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  delete?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  update?: Maybe<Array<VetsourceRevenueCategoryUpdateWithWhereUniqueWithoutParentVetsourceRevenueCategoryInput>>;
  updateMany?: Maybe<Array<VetsourceRevenueCategoryUpdateManyWithWhereWithoutParentVetsourceRevenueCategoryInput>>;
  deleteMany?: Maybe<Array<VetsourceRevenueCategoryScalarWhereInput>>;
};

export type VetsourceRevenueCategoryUncheckedUpdateWithoutChildrenVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  Service?: Maybe<ServiceUncheckedUpdateManyWithoutVetsourceRevenueCategoryNestedInput>;
};

export type VetsourceRevenueCategoryUncheckedUpdateWithoutParentVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUncheckedUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput>;
  Service?: Maybe<ServiceUncheckedUpdateManyWithoutVetsourceRevenueCategoryNestedInput>;
};

export type VetsourceRevenueCategoryUncheckedUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUncheckedUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput>;
};

export type VetsourceRevenueCategoryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutChildrenVetsourceRevenueCategoryNestedInput>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput>;
  Service?: Maybe<ServiceUpdateManyWithoutVetsourceRevenueCategoryNestedInput>;
};

export type VetsourceRevenueCategoryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
};

export type VetsourceRevenueCategoryUpdateManyWithWhereWithoutParentVetsourceRevenueCategoryInput = {
  where: VetsourceRevenueCategoryScalarWhereInput;
  data: VetsourceRevenueCategoryUncheckedUpdateManyWithoutChildrenVetsourceRevenueCategoryInput;
};

export type VetsourceRevenueCategoryUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput = {
  create?: Maybe<Array<VetsourceRevenueCategoryCreateWithoutParentVetsourceRevenueCategoryInput>>;
  connectOrCreate?: Maybe<Array<VetsourceRevenueCategoryCreateOrConnectWithoutParentVetsourceRevenueCategoryInput>>;
  upsert?: Maybe<Array<VetsourceRevenueCategoryUpsertWithWhereUniqueWithoutParentVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<VetsourceRevenueCategoryCreateManyParentVetsourceRevenueCategoryInputEnvelope>;
  set?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  delete?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  update?: Maybe<Array<VetsourceRevenueCategoryUpdateWithWhereUniqueWithoutParentVetsourceRevenueCategoryInput>>;
  updateMany?: Maybe<Array<VetsourceRevenueCategoryUpdateManyWithWhereWithoutParentVetsourceRevenueCategoryInput>>;
  deleteMany?: Maybe<Array<VetsourceRevenueCategoryScalarWhereInput>>;
};

export type VetsourceRevenueCategoryUpdateOneWithoutChildrenVetsourceRevenueCategoryNestedInput = {
  create?: Maybe<VetsourceRevenueCategoryUncheckedCreateWithoutChildrenVetsourceRevenueCategoryInput>;
  connectOrCreate?: Maybe<VetsourceRevenueCategoryCreateOrConnectWithoutChildrenVetsourceRevenueCategoryInput>;
  upsert?: Maybe<VetsourceRevenueCategoryUpsertWithoutChildrenVetsourceRevenueCategoryInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  update?: Maybe<VetsourceRevenueCategoryUncheckedUpdateWithoutChildrenVetsourceRevenueCategoryInput>;
};

export type VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput = {
  create?: Maybe<VetsourceRevenueCategoryUncheckedCreateWithoutServiceInput>;
  connectOrCreate?: Maybe<VetsourceRevenueCategoryCreateOrConnectWithoutServiceInput>;
  upsert?: Maybe<VetsourceRevenueCategoryUpsertWithoutServiceInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  update?: Maybe<VetsourceRevenueCategoryUncheckedUpdateWithoutServiceInput>;
};

export type VetsourceRevenueCategoryUpdateWithWhereUniqueWithoutParentVetsourceRevenueCategoryInput = {
  where: VetsourceRevenueCategoryWhereUniqueInput;
  data: VetsourceRevenueCategoryUncheckedUpdateWithoutParentVetsourceRevenueCategoryInput;
};

export type VetsourceRevenueCategoryUpdateWithoutChildrenVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutChildrenVetsourceRevenueCategoryNestedInput>;
  Service?: Maybe<ServiceUpdateManyWithoutVetsourceRevenueCategoryNestedInput>;
};

export type VetsourceRevenueCategoryUpdateWithoutParentVetsourceRevenueCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput>;
  Service?: Maybe<ServiceUpdateManyWithoutVetsourceRevenueCategoryNestedInput>;
};

export type VetsourceRevenueCategoryUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutChildrenVetsourceRevenueCategoryNestedInput>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput>;
};

export type VetsourceRevenueCategoryUpsertWithWhereUniqueWithoutParentVetsourceRevenueCategoryInput = {
  where: VetsourceRevenueCategoryWhereUniqueInput;
  update: VetsourceRevenueCategoryUncheckedUpdateWithoutParentVetsourceRevenueCategoryInput;
  create: VetsourceRevenueCategoryUncheckedCreateWithoutParentVetsourceRevenueCategoryInput;
};

export type VetsourceRevenueCategoryUpsertWithoutChildrenVetsourceRevenueCategoryInput = {
  update: VetsourceRevenueCategoryUncheckedUpdateWithoutChildrenVetsourceRevenueCategoryInput;
  create: VetsourceRevenueCategoryUncheckedCreateWithoutChildrenVetsourceRevenueCategoryInput;
};

export type VetsourceRevenueCategoryUpsertWithoutServiceInput = {
  update: VetsourceRevenueCategoryUncheckedUpdateWithoutServiceInput;
  create: VetsourceRevenueCategoryUncheckedCreateWithoutServiceInput;
};

export type VetsourceRevenueCategoryWhereInput = {
  AND?: Maybe<Array<VetsourceRevenueCategoryWhereInput>>;
  OR?: Maybe<Array<VetsourceRevenueCategoryWhereInput>>;
  NOT?: Maybe<Array<VetsourceRevenueCategoryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  externalId?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  accountNumber?: Maybe<IntNullableFilter>;
  accountName?: Maybe<StringNullableFilter>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<IntNullableFilter>;
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryWhereInput>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryListRelationFilter>;
  Service?: Maybe<ServiceListRelationFilter>;
};

export type VetsourceRevenueCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type VetterIntegration = {
  __typename?: 'VetterIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  apiKey: Scalars['String'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type VetterIntegrationCountAggregateOutputType = {
  __typename?: 'VetterIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  apiKey: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetterIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
};

export type VetterIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey: Scalars['String'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetterInput>;
};

export type VetterIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey: Scalars['String'];
};

export type VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<VetterIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<VetterIntegrationWhereUniqueInput>;
};

export type VetterIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: VetterIntegrationWhereUniqueInput;
  create: VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type VetterIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey: Scalars['String'];
};

export type VetterIntegrationMaxAggregateOutputType = {
  __typename?: 'VetterIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
};

export type VetterIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
};

export type VetterIntegrationMinAggregateOutputType = {
  __typename?: 'VetterIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
};

export type VetterIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
};

export enum VetterIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  ApiKey = 'apiKey'
}

export type VetterIntegrationOrderByRelevanceInput = {
  fields: Array<VetterIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetterIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
  _count?: Maybe<VetterIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<VetterIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<VetterIntegrationMinOrderByAggregateInput>;
};

export type VetterIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<VetterIntegrationOrderByRelevanceInput>;
};

export type VetterIntegrationRelationFilter = {
  is?: Maybe<VetterIntegrationWhereInput>;
  isNot?: Maybe<VetterIntegrationWhereInput>;
};

export enum VetterIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ApiKey = 'apiKey'
}

export type VetterIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetterIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetterIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetterIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  apiKey?: Maybe<StringWithAggregatesFilter>;
};

export type VetterIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey: Scalars['String'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutVetterInput>;
};

export type VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey: Scalars['String'];
};

export type VetterIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutVetterNestedInput>;
};

export type VetterIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
};

export type VetterIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
};

export type VetterIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetterNestedInput>;
};

export type VetterIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
};

export type VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  create?: Maybe<VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<VetterIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<VetterIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetterIntegrationWhereUniqueInput>;
  update?: Maybe<VetterIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type VetterIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
};

export type VetterIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: VetterIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type VetterIntegrationWhereInput = {
  AND?: Maybe<Array<VetterIntegrationWhereInput>>;
  OR?: Maybe<Array<VetterIntegrationWhereInput>>;
  NOT?: Maybe<Array<VetterIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  apiKey?: Maybe<StringFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type VetterIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VideoCallRecordingToggleInput = {
  status: RecordingControlStatus;
  roomSid: Scalars['String'];
};

export type WidgetClinicPetParentLookupInput = {
  clinicId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petParentFirstName?: Maybe<Scalars['String']>;
  petParentLastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type WidgetClinicPetParentLookupResponse = {
  __typename?: 'WidgetClinicPetParentLookupResponse';
  id: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumbers: Array<ClinicEntityPhoneNumber>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  pets: Array<ClinicPet>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
};

export enum WidgetRequestType {
  RequestAppointment = 'RequestAppointment',
  RequestRxRefill = 'RequestRxRefill',
  AdvancedRequestRxRefill = 'AdvancedRequestRxRefill',
  RequestGroomingAppoinment = 'RequestGroomingAppoinment',
  RequestBoarding = 'RequestBoarding',
  TalkToStaff = 'TalkToStaff',
  RequestMedicalRecords = 'RequestMedicalRecords',
  RequestVirtualConsult = 'RequestVirtualConsult',
  InboundSms = 'InboundSms',
  DirectBooking = 'DirectBooking',
  Teletriage = 'Teletriage'
}

export enum WorkflowActionType {
  ConversationMessageTemplate = 'Conversation_MessageTemplate',
  ConversationStatusChange = 'Conversation_StatusChange',
  ConversationSystemMessage = 'Conversation_SystemMessage',
  WorkflowEventTrigger = 'WorkflowEvent_Trigger'
}

export enum WorkflowEvent {
  AppointmentScheduled = 'Appointment_Scheduled',
  AppointmentConfirmReminder = 'Appointment_ConfirmReminder',
  AppointmentReminder = 'Appointment_Reminder',
  AppointmentClientArrival = 'Appointment_ClientArrival',
  AppointmentPimsSync = 'Appointment_Pims_Sync',
  AppointmentFollowup = 'Appointment_Followup',
  ServiceReminderFormSubmitted = 'ServiceReminder_FormSubmitted',
  WidgetRequestFormSubmitted = 'WidgetRequest_FormSubmitted',
  CallDeflectionFormSubmitted = 'CallDeflection_FormSubmitted',
  LapsedPetParent = 'LapsedPetParent',
  InvoiceLineItemCreated = 'InvoiceLineItem_Created'
}

export type WorkflowEventAction = {
  __typename?: 'WorkflowEventAction';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType: AutomationRunActionPromptType;
  channelStatusChange?: Maybe<ChannelStatus>;
  responseChannelStatusChange?: Maybe<ChannelStatus>;
  workflowEventSetting?: Maybe<WorkflowEventSetting>;
  triggerWorkflow?: Maybe<WorkflowEventSetting>;
  channels: Array<Channel>;
  automationRunActions: Array<AutomationRunAction>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  returnedAutomationRuns: Array<AutomationRun>;
  _count: WorkflowEventActionCountOutputType;
};


export type WorkflowEventActionChannelsArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
};


export type WorkflowEventActionAutomationRunActionsArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
};


export type WorkflowEventActionChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};


export type WorkflowEventActionReturnedAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};

export type WorkflowEventActionAvgAggregateOutputType = {
  __typename?: 'WorkflowEventActionAvgAggregateOutputType';
  order?: Maybe<Scalars['Float']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Float']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Float']>;
};

export type WorkflowEventActionAvgOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
};

export type WorkflowEventActionCountAggregateOutputType = {
  __typename?: 'WorkflowEventActionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  actionType: Scalars['Int'];
  config: Scalars['Int'];
  order: Scalars['Int'];
  workflowEventSettingId: Scalars['Int'];
  triggerWorkflowId: Scalars['Int'];
  workflowEventTrigger: Scalars['Int'];
  workflowEventTriggerActionNumber: Scalars['Int'];
  workflowEventTriggerDelayInMilliseconds: Scalars['Int'];
  channelStatusChangeId: Scalars['Int'];
  responseChannelStatusChangeId: Scalars['Int'];
  promptType: Scalars['Int'];
  _all: Scalars['Int'];
};

export type WorkflowEventActionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  config?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  triggerWorkflowId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  responseChannelStatusChangeId?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
};

export type WorkflowEventActionCountOutputType = {
  __typename?: 'WorkflowEventActionCountOutputType';
  channels: Scalars['Int'];
  automationRunActions: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  returnedAutomationRuns: Scalars['Int'];
};

export type WorkflowEventActionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionCreateManyChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
};

export type WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope = {
  data: Array<WorkflowEventActionCreateManyChannelStatusChangeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventActionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
};

export type WorkflowEventActionCreateManyResponseChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
};

export type WorkflowEventActionCreateManyResponseChannelStatusChangeInputEnvelope = {
  data: Array<WorkflowEventActionCreateManyResponseChannelStatusChangeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventActionCreateManyTriggerWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
};

export type WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope = {
  data: Array<WorkflowEventActionCreateManyTriggerWorkflowInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventActionCreateManyWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
};

export type WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope = {
  data: Array<WorkflowEventActionCreateManyWorkflowEventSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
};

export type WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutResponseChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutResponseChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyResponseChannelStatusChangeInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
};

export type WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutTriggerWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
};

export type WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
};

export type WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput = {
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutAutomationRunActionsInput>;
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
};

export type WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
};

export type WorkflowEventActionCreateNestedOneWithoutChannelsInput = {
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutChannelsInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutChannelsInput>;
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
};

export type WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput = {
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutReturnedAutomationRunsInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutReturnedAutomationRunsInput>;
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
};

export type WorkflowEventActionCreateOrConnectWithoutAutomationRunActionsInput = {
  where: WorkflowEventActionWhereUniqueInput;
  create: WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput;
};

export type WorkflowEventActionCreateOrConnectWithoutChannelAutomationStatusesInput = {
  where: WorkflowEventActionWhereUniqueInput;
  create: WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput = {
  where: WorkflowEventActionWhereUniqueInput;
  create: WorkflowEventActionUncheckedCreateWithoutChannelStatusChangeInput;
};

export type WorkflowEventActionCreateOrConnectWithoutChannelsInput = {
  where: WorkflowEventActionWhereUniqueInput;
  create: WorkflowEventActionUncheckedCreateWithoutChannelsInput;
};

export type WorkflowEventActionCreateOrConnectWithoutResponseChannelStatusChangeInput = {
  where: WorkflowEventActionWhereUniqueInput;
  create: WorkflowEventActionUncheckedCreateWithoutResponseChannelStatusChangeInput;
};

export type WorkflowEventActionCreateOrConnectWithoutReturnedAutomationRunsInput = {
  where: WorkflowEventActionWhereUniqueInput;
  create: WorkflowEventActionUncheckedCreateWithoutReturnedAutomationRunsInput;
};

export type WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput = {
  where: WorkflowEventActionWhereUniqueInput;
  create: WorkflowEventActionUncheckedCreateWithoutTriggerWorkflowInput;
};

export type WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput = {
  where: WorkflowEventActionWhereUniqueInput;
  create: WorkflowEventActionUncheckedCreateWithoutWorkflowEventSettingInput;
};

export type WorkflowEventActionCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionCreateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionCreateWithoutResponseChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionCreateWithoutReturnedAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionCreateWithoutTriggerWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionCreateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionListRelationFilter = {
  every?: Maybe<WorkflowEventActionWhereInput>;
  some?: Maybe<WorkflowEventActionWhereInput>;
  none?: Maybe<WorkflowEventActionWhereInput>;
};

export type WorkflowEventActionMaxAggregateOutputType = {
  __typename?: 'WorkflowEventActionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
};

export type WorkflowEventActionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  triggerWorkflowId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  responseChannelStatusChangeId?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
};

export type WorkflowEventActionMinAggregateOutputType = {
  __typename?: 'WorkflowEventActionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
};

export type WorkflowEventActionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  triggerWorkflowId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  responseChannelStatusChangeId?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
};

export type WorkflowEventActionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum WorkflowEventActionOrderByRelevanceFieldEnum {
  Id = 'id',
  WorkflowEventSettingId = 'workflowEventSettingId',
  TriggerWorkflowId = 'triggerWorkflowId',
  WorkflowEventTrigger = 'workflowEventTrigger',
  ChannelStatusChangeId = 'channelStatusChangeId',
  ResponseChannelStatusChangeId = 'responseChannelStatusChangeId'
}

export type WorkflowEventActionOrderByRelevanceInput = {
  fields: Array<WorkflowEventActionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type WorkflowEventActionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  config?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  triggerWorkflowId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  responseChannelStatusChangeId?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  _count?: Maybe<WorkflowEventActionCountOrderByAggregateInput>;
  _avg?: Maybe<WorkflowEventActionAvgOrderByAggregateInput>;
  _max?: Maybe<WorkflowEventActionMaxOrderByAggregateInput>;
  _min?: Maybe<WorkflowEventActionMinOrderByAggregateInput>;
  _sum?: Maybe<WorkflowEventActionSumOrderByAggregateInput>;
};

export type WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  config?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  triggerWorkflowId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  responseChannelStatusChangeId?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  channelStatusChange?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  automationRunActions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  returnedAutomationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  _relevance?: Maybe<WorkflowEventActionOrderByRelevanceInput>;
};

export type WorkflowEventActionRelationFilter = {
  is?: Maybe<WorkflowEventActionWhereInput>;
  isNot?: Maybe<WorkflowEventActionWhereInput>;
};

export enum WorkflowEventActionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ActionType = 'actionType',
  Config = 'config',
  Order = 'order',
  WorkflowEventSettingId = 'workflowEventSettingId',
  TriggerWorkflowId = 'triggerWorkflowId',
  WorkflowEventTrigger = 'workflowEventTrigger',
  WorkflowEventTriggerActionNumber = 'workflowEventTriggerActionNumber',
  WorkflowEventTriggerDelayInMilliseconds = 'workflowEventTriggerDelayInMilliseconds',
  ChannelStatusChangeId = 'channelStatusChangeId',
  ResponseChannelStatusChangeId = 'responseChannelStatusChangeId',
  PromptType = 'promptType'
}

export type WorkflowEventActionScalarWhereInput = {
  AND?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  OR?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  NOT?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  actionType?: Maybe<EnumWorkflowActionTypeFilter>;
  config?: Maybe<JsonFilter>;
  order?: Maybe<IntFilter>;
  workflowEventSettingId?: Maybe<StringNullableFilter>;
  triggerWorkflowId?: Maybe<StringNullableFilter>;
  workflowEventTrigger?: Maybe<StringNullableFilter>;
  workflowEventTriggerActionNumber?: Maybe<IntNullableFilter>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<IntNullableFilter>;
  channelStatusChangeId?: Maybe<StringNullableFilter>;
  responseChannelStatusChangeId?: Maybe<StringNullableFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeFilter>;
};

export type WorkflowEventActionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<WorkflowEventActionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<WorkflowEventActionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<WorkflowEventActionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  actionType?: Maybe<EnumWorkflowActionTypeWithAggregatesFilter>;
  config?: Maybe<JsonWithAggregatesFilter>;
  order?: Maybe<IntWithAggregatesFilter>;
  workflowEventSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  triggerWorkflowId?: Maybe<StringNullableWithAggregatesFilter>;
  workflowEventTrigger?: Maybe<StringNullableWithAggregatesFilter>;
  workflowEventTriggerActionNumber?: Maybe<IntNullableWithAggregatesFilter>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<IntNullableWithAggregatesFilter>;
  channelStatusChangeId?: Maybe<StringNullableWithAggregatesFilter>;
  responseChannelStatusChangeId?: Maybe<StringNullableWithAggregatesFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeWithAggregatesFilter>;
};

export type WorkflowEventActionSumAggregateOutputType = {
  __typename?: 'WorkflowEventActionSumAggregateOutputType';
  order?: Maybe<Scalars['Int']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionSumOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
};

export type WorkflowEventActionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
};

export type WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutResponseChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutResponseChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyResponseChannelStatusChangeInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
};

export type WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutTriggerWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
};

export type WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
};

export type WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionUncheckedCreateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionUncheckedCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionUncheckedCreateWithoutResponseChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionUncheckedCreateWithoutReturnedAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionUncheckedCreateWithoutTriggerWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionUncheckedCreateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
};

export type WorkflowEventActionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutChannelStatusChangeInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutResponseChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutResponseChannelStatusChangeInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutResponseChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyResponseChannelStatusChangeInputEnvelope>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutResponseChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutResponseChannelStatusChangeInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutResponseWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutTriggerWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutTriggerWorkflowInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutTriggerWorkflowInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutTriggerWorkflowInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
};

export type WorkflowEventActionUncheckedUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUncheckedUpdateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUncheckedUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUncheckedUpdateWithoutResponseChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUncheckedUpdateWithoutReturnedAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
};

export type WorkflowEventActionUncheckedUpdateWithoutTriggerWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUncheckedUpdateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
};

export type WorkflowEventActionUpdateManyWithWhereWithoutChannelStatusChangeInput = {
  where: WorkflowEventActionScalarWhereInput;
  data: WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventActionInput;
};

export type WorkflowEventActionUpdateManyWithWhereWithoutResponseChannelStatusChangeInput = {
  where: WorkflowEventActionScalarWhereInput;
  data: WorkflowEventActionUncheckedUpdateManyWithoutResponseWorkflowEventActionsInput;
};

export type WorkflowEventActionUpdateManyWithWhereWithoutTriggerWorkflowInput = {
  where: WorkflowEventActionScalarWhereInput;
  data: WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventActionsInput;
};

export type WorkflowEventActionUpdateManyWithWhereWithoutWorkflowEventSettingInput = {
  where: WorkflowEventActionScalarWhereInput;
  data: WorkflowEventActionUncheckedUpdateManyWithoutActionsInput;
};

export type WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutChannelStatusChangeInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
};

export type WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutResponseChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutResponseChannelStatusChangeInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutResponseChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyResponseChannelStatusChangeInputEnvelope>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutResponseChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutResponseChannelStatusChangeInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
};

export type WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutTriggerWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutTriggerWorkflowInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutTriggerWorkflowInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutTriggerWorkflowInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
};

export type WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
};

export type WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput = {
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutAutomationRunActionsInput>;
  upsert?: Maybe<WorkflowEventActionUpsertWithoutAutomationRunActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  update?: Maybe<WorkflowEventActionUncheckedUpdateWithoutAutomationRunActionsInput>;
};

export type WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput = {
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<WorkflowEventActionUpsertWithoutChannelAutomationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  update?: Maybe<WorkflowEventActionUncheckedUpdateWithoutChannelAutomationStatusesInput>;
};

export type WorkflowEventActionUpdateOneWithoutChannelsNestedInput = {
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutChannelsInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutChannelsInput>;
  upsert?: Maybe<WorkflowEventActionUpsertWithoutChannelsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  update?: Maybe<WorkflowEventActionUncheckedUpdateWithoutChannelsInput>;
};

export type WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput = {
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutReturnedAutomationRunsInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutReturnedAutomationRunsInput>;
  upsert?: Maybe<WorkflowEventActionUpsertWithoutReturnedAutomationRunsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  update?: Maybe<WorkflowEventActionUncheckedUpdateWithoutReturnedAutomationRunsInput>;
};

export type WorkflowEventActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput = {
  where: WorkflowEventActionWhereUniqueInput;
  data: WorkflowEventActionUncheckedUpdateWithoutChannelStatusChangeInput;
};

export type WorkflowEventActionUpdateWithWhereUniqueWithoutResponseChannelStatusChangeInput = {
  where: WorkflowEventActionWhereUniqueInput;
  data: WorkflowEventActionUncheckedUpdateWithoutResponseChannelStatusChangeInput;
};

export type WorkflowEventActionUpdateWithWhereUniqueWithoutTriggerWorkflowInput = {
  where: WorkflowEventActionWhereUniqueInput;
  data: WorkflowEventActionUncheckedUpdateWithoutTriggerWorkflowInput;
};

export type WorkflowEventActionUpdateWithWhereUniqueWithoutWorkflowEventSettingInput = {
  where: WorkflowEventActionWhereUniqueInput;
  data: WorkflowEventActionUncheckedUpdateWithoutWorkflowEventSettingInput;
};

export type WorkflowEventActionUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUpdateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUpdateWithoutResponseChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUpdateWithoutReturnedAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
};

export type WorkflowEventActionUpdateWithoutTriggerWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUpdateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
};

export type WorkflowEventActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput = {
  where: WorkflowEventActionWhereUniqueInput;
  update: WorkflowEventActionUncheckedUpdateWithoutChannelStatusChangeInput;
  create: WorkflowEventActionUncheckedCreateWithoutChannelStatusChangeInput;
};

export type WorkflowEventActionUpsertWithWhereUniqueWithoutResponseChannelStatusChangeInput = {
  where: WorkflowEventActionWhereUniqueInput;
  update: WorkflowEventActionUncheckedUpdateWithoutResponseChannelStatusChangeInput;
  create: WorkflowEventActionUncheckedCreateWithoutResponseChannelStatusChangeInput;
};

export type WorkflowEventActionUpsertWithWhereUniqueWithoutTriggerWorkflowInput = {
  where: WorkflowEventActionWhereUniqueInput;
  update: WorkflowEventActionUncheckedUpdateWithoutTriggerWorkflowInput;
  create: WorkflowEventActionUncheckedCreateWithoutTriggerWorkflowInput;
};

export type WorkflowEventActionUpsertWithWhereUniqueWithoutWorkflowEventSettingInput = {
  where: WorkflowEventActionWhereUniqueInput;
  update: WorkflowEventActionUncheckedUpdateWithoutWorkflowEventSettingInput;
  create: WorkflowEventActionUncheckedCreateWithoutWorkflowEventSettingInput;
};

export type WorkflowEventActionUpsertWithoutAutomationRunActionsInput = {
  update: WorkflowEventActionUncheckedUpdateWithoutAutomationRunActionsInput;
  create: WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput;
};

export type WorkflowEventActionUpsertWithoutChannelAutomationStatusesInput = {
  update: WorkflowEventActionUncheckedUpdateWithoutChannelAutomationStatusesInput;
  create: WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type WorkflowEventActionUpsertWithoutChannelsInput = {
  update: WorkflowEventActionUncheckedUpdateWithoutChannelsInput;
  create: WorkflowEventActionUncheckedCreateWithoutChannelsInput;
};

export type WorkflowEventActionUpsertWithoutReturnedAutomationRunsInput = {
  update: WorkflowEventActionUncheckedUpdateWithoutReturnedAutomationRunsInput;
  create: WorkflowEventActionUncheckedCreateWithoutReturnedAutomationRunsInput;
};

export type WorkflowEventActionWhereInput = {
  AND?: Maybe<Array<WorkflowEventActionWhereInput>>;
  OR?: Maybe<Array<WorkflowEventActionWhereInput>>;
  NOT?: Maybe<Array<WorkflowEventActionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  actionType?: Maybe<EnumWorkflowActionTypeFilter>;
  config?: Maybe<JsonFilter>;
  order?: Maybe<IntFilter>;
  workflowEventSettingId?: Maybe<StringNullableFilter>;
  triggerWorkflowId?: Maybe<StringNullableFilter>;
  workflowEventTrigger?: Maybe<StringNullableFilter>;
  workflowEventTriggerActionNumber?: Maybe<IntNullableFilter>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<IntNullableFilter>;
  channelStatusChangeId?: Maybe<StringNullableFilter>;
  responseChannelStatusChangeId?: Maybe<StringNullableFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeFilter>;
  channelStatusChange?: Maybe<ChannelStatusWhereInput>;
  responseChannelStatusChange?: Maybe<ChannelStatusWhereInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingWhereInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingWhereInput>;
  channels?: Maybe<ChannelListRelationFilter>;
  automationRunActions?: Maybe<AutomationRunActionListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  returnedAutomationRuns?: Maybe<AutomationRunListRelationFilter>;
};

export type WorkflowEventActionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type WorkflowEventSetting = {
  __typename?: 'WorkflowEventSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType: WorkflowVisitType;
  sendingHours: WorkflowSendingHours;
  name: Scalars['String'];
  workflowType: WorkflowType;
  triggerType: WorkflowEventTriggerType;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours: Scalars['Int'];
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus: ConditionEvaluationStatus;
  messagingCampaignId?: Maybe<Scalars['String']>;
  surveySetting?: Maybe<SurveySetting>;
  clinic?: Maybe<Clinic>;
  initialChannelStatusChange?: Maybe<ChannelStatus>;
  actions: Array<WorkflowEventAction>;
  appointmentTypes: Array<AppointmentType>;
  widgetRequestTypes: Array<ClinicWidgetRequestType>;
  appointments: Array<Appointment>;
  workflowEventActions: Array<WorkflowEventAction>;
  workflowTriggers: Array<WorkflowTrigger>;
  triggeringFormTemplates: Array<FormTemplate>;
  clinicRooms: Array<ClinicRoomToWorkflowEventSetting>;
  clinicEmployees: Array<ClinicEmployeeToWorkflowEventSetting>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channelCreationSources: Array<ChannelCreationSource>;
  automationRuns: Array<AutomationRun>;
  lapsedPetParentTriggers: Array<LapsedPetParentTriggers>;
  messagingCampaign?: Maybe<MessagingCampaign>;
  informAutomationCampaigns: Array<InformAutomationCampaign>;
  _count: WorkflowEventSettingCountOutputType;
  conditionSets: Array<ConditionSet>;
};


export type WorkflowEventSettingActionsArgs = {
  where?: Maybe<WorkflowEventActionWhereInput>;
  orderBy?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventActionScalarFieldEnum>;
};


export type WorkflowEventSettingAppointmentTypesArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentTypeScalarFieldEnum>;
};


export type WorkflowEventSettingWidgetRequestTypesArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
};


export type WorkflowEventSettingAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};


export type WorkflowEventSettingWorkflowEventActionsArgs = {
  where?: Maybe<WorkflowEventActionWhereInput>;
  orderBy?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventActionScalarFieldEnum>;
};


export type WorkflowEventSettingWorkflowTriggersArgs = {
  where?: Maybe<WorkflowTriggerWhereInput>;
  orderBy?: Maybe<WorkflowTriggerOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowTriggerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowTriggerScalarFieldEnum>;
};


export type WorkflowEventSettingTriggeringFormTemplatesArgs = {
  where?: Maybe<FormTemplateWhereInput>;
  orderBy?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormTemplateScalarFieldEnum>;
};


export type WorkflowEventSettingClinicRoomsArgs = {
  where?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
  orderBy?: Maybe<ClinicRoomToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicRoomToWorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicRoomToWorkflowEventSettingScalarFieldEnum>;
};


export type WorkflowEventSettingClinicEmployeesArgs = {
  where?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
  orderBy?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeToWorkflowEventSettingScalarFieldEnum>;
};


export type WorkflowEventSettingChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};


export type WorkflowEventSettingChannelCreationSourcesArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
};


export type WorkflowEventSettingAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};


export type WorkflowEventSettingLapsedPetParentTriggersArgs = {
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
  orderBy?: Maybe<LapsedPetParentTriggersOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<LapsedPetParentTriggersWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<LapsedPetParentTriggersScalarFieldEnum>;
};


export type WorkflowEventSettingInformAutomationCampaignsArgs = {
  where?: Maybe<InformAutomationCampaignWhereInput>;
  orderBy?: Maybe<InformAutomationCampaignOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InformAutomationCampaignScalarFieldEnum>;
};

export type WorkflowEventSettingAvgAggregateOutputType = {
  __typename?: 'WorkflowEventSettingAvgAggregateOutputType';
  automatedTriggerInMinutes?: Maybe<Scalars['Float']>;
  groupByWindowInHours?: Maybe<Scalars['Float']>;
};

export type WorkflowEventSettingAvgOrderByAggregateInput = {
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
};

export type WorkflowEventSettingCountAggregateOutputType = {
  __typename?: 'WorkflowEventSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  automatedTriggerType: Scalars['Int'];
  automatedTriggerInMinutes: Scalars['Int'];
  automatedTriggerTimeOfDay: Scalars['Int'];
  event: Scalars['Int'];
  visitType: Scalars['Int'];
  sendingHours: Scalars['Int'];
  name: Scalars['Int'];
  workflowType: Scalars['Int'];
  triggerType: Scalars['Int'];
  clinicId: Scalars['Int'];
  isPublished: Scalars['Int'];
  notificationBody: Scalars['Int'];
  groupByWindowInHours: Scalars['Int'];
  surveyStep: Scalars['Int'];
  surveySettingId: Scalars['Int'];
  surveyType: Scalars['Int'];
  initialChannelStatusChangeId: Scalars['Int'];
  timeFormat: Scalars['Int'];
  childRunEvaluationStatus: Scalars['Int'];
  messagingCampaignId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type WorkflowEventSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  automatedTriggerTimeOfDay?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  workflowType?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  surveyStep?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  surveyType?: Maybe<SortOrder>;
  initialChannelStatusChangeId?: Maybe<SortOrder>;
  timeFormat?: Maybe<SortOrder>;
  childRunEvaluationStatus?: Maybe<SortOrder>;
  messagingCampaignId?: Maybe<SortOrder>;
};

export type WorkflowEventSettingCountOutputType = {
  __typename?: 'WorkflowEventSettingCountOutputType';
  actions: Scalars['Int'];
  appointmentTypes: Scalars['Int'];
  widgetRequestTypes: Scalars['Int'];
  appointments: Scalars['Int'];
  workflowEventActions: Scalars['Int'];
  workflowTriggers: Scalars['Int'];
  triggeringFormTemplates: Scalars['Int'];
  clinicRooms: Scalars['Int'];
  clinicEmployees: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  automationRuns: Scalars['Int'];
  lapsedPetParentTriggers: Scalars['Int'];
  informAutomationCampaigns: Scalars['Int'];
};

export type WorkflowEventSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingCreateManyClinicInputEnvelope = {
  data: Array<WorkflowEventSettingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventSettingCreateManyInitialChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingCreateManyInitialChannelStatusChangeInputEnvelope = {
  data: Array<WorkflowEventSettingCreateManyInitialChannelStatusChangeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingCreateManyMessagingCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
};

export type WorkflowEventSettingCreateManyMessagingCampaignInputEnvelope = {
  data: Array<WorkflowEventSettingCreateManyMessagingCampaignInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventSettingCreateManySurveySettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingCreateManySurveySettingInputEnvelope = {
  data: Array<WorkflowEventSettingCreateManySurveySettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutInitialChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutInitialChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyInitialChannelStatusChangeInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingCreateNestedManyWithoutMessagingCampaignInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutMessagingCampaignInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutMessagingCampaignInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyMessagingCampaignInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingCreateNestedManyWithoutSurveySettingInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutSurveySettingInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutSurveySettingInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManySurveySettingInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingCreateNestedOneWithoutActionsInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutActionsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutActionsInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutAutomationRunsInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutChannelCreationSourcesInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutClinicEmployeesInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutClinicEmployeesInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutClinicRoomsInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutClinicRoomsInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutInformAutomationCampaignsInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutInformAutomationCampaignsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutInformAutomationCampaignsInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutLapsedPetParentTriggersInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutLapsedPetParentTriggersInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutLapsedPetParentTriggersInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutWorkflowEventActionsInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutWorkflowTriggersInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutWorkflowTriggersInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateOrConnectWithoutActionsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutActionsInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutAppointmentTypesInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutAppointmentsInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutAutomationRunsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutChannelAutomationStatusesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutChannelCreationSourcesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutClinicEmployeesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutClinicInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutClinicInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutClinicRoomsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutInformAutomationCampaignsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutInformAutomationCampaignsInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutInitialChannelStatusChangeInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutInitialChannelStatusChangeInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutLapsedPetParentTriggersInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutLapsedPetParentTriggersInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutMessagingCampaignInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutMessagingCampaignInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutSurveySettingInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutSurveySettingInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutTriggeringFormTemplatesInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutWidgetRequestTypesInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutWorkflowEventActionsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutWorkflowTriggersInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput;
};

export type WorkflowEventSettingCreateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutInformAutomationCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
};

export type WorkflowEventSettingCreateWithoutInitialChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutLapsedPetParentTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutMessagingCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutSurveySettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingListRelationFilter = {
  every?: Maybe<WorkflowEventSettingWhereInput>;
  some?: Maybe<WorkflowEventSettingWhereInput>;
  none?: Maybe<WorkflowEventSettingWhereInput>;
};

export type WorkflowEventSettingMaxAggregateOutputType = {
  __typename?: 'WorkflowEventSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  automatedTriggerTimeOfDay?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  workflowType?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  surveyStep?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  surveyType?: Maybe<SortOrder>;
  initialChannelStatusChangeId?: Maybe<SortOrder>;
  timeFormat?: Maybe<SortOrder>;
  childRunEvaluationStatus?: Maybe<SortOrder>;
  messagingCampaignId?: Maybe<SortOrder>;
};

export type WorkflowEventSettingMinAggregateOutputType = {
  __typename?: 'WorkflowEventSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  automatedTriggerTimeOfDay?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  workflowType?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  surveyStep?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  surveyType?: Maybe<SortOrder>;
  initialChannelStatusChangeId?: Maybe<SortOrder>;
  timeFormat?: Maybe<SortOrder>;
  childRunEvaluationStatus?: Maybe<SortOrder>;
  messagingCampaignId?: Maybe<SortOrder>;
};

export type WorkflowEventSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum WorkflowEventSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  ClinicId = 'clinicId',
  NotificationBody = 'notificationBody',
  SurveySettingId = 'surveySettingId',
  InitialChannelStatusChangeId = 'initialChannelStatusChangeId',
  TimeFormat = 'timeFormat',
  MessagingCampaignId = 'messagingCampaignId'
}

export type WorkflowEventSettingOrderByRelevanceInput = {
  fields: Array<WorkflowEventSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type WorkflowEventSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  automatedTriggerTimeOfDay?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  workflowType?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  surveyStep?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  surveyType?: Maybe<SortOrder>;
  initialChannelStatusChangeId?: Maybe<SortOrder>;
  timeFormat?: Maybe<SortOrder>;
  childRunEvaluationStatus?: Maybe<SortOrder>;
  messagingCampaignId?: Maybe<SortOrder>;
  _count?: Maybe<WorkflowEventSettingCountOrderByAggregateInput>;
  _avg?: Maybe<WorkflowEventSettingAvgOrderByAggregateInput>;
  _max?: Maybe<WorkflowEventSettingMaxOrderByAggregateInput>;
  _min?: Maybe<WorkflowEventSettingMinOrderByAggregateInput>;
  _sum?: Maybe<WorkflowEventSettingSumOrderByAggregateInput>;
};

export type WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  automatedTriggerTimeOfDay?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  workflowType?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  surveyStep?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  surveyType?: Maybe<SortOrder>;
  initialChannelStatusChangeId?: Maybe<SortOrder>;
  timeFormat?: Maybe<SortOrder>;
  childRunEvaluationStatus?: Maybe<SortOrder>;
  messagingCampaignId?: Maybe<SortOrder>;
  surveySetting?: Maybe<SurveySettingOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  actions?: Maybe<WorkflowEventActionOrderByRelationAggregateInput>;
  appointmentTypes?: Maybe<AppointmentTypeOrderByRelationAggregateInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  workflowEventActions?: Maybe<WorkflowEventActionOrderByRelationAggregateInput>;
  workflowTriggers?: Maybe<WorkflowTriggerOrderByRelationAggregateInput>;
  triggeringFormTemplates?: Maybe<FormTemplateOrderByRelationAggregateInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingOrderByRelationAggregateInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersOrderByRelationAggregateInput>;
  messagingCampaign?: Maybe<MessagingCampaignOrderByWithRelationAndSearchRelevanceInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignOrderByRelationAggregateInput>;
  _relevance?: Maybe<WorkflowEventSettingOrderByRelevanceInput>;
};

export type WorkflowEventSettingRelationFilter = {
  is?: Maybe<WorkflowEventSettingWhereInput>;
  isNot?: Maybe<WorkflowEventSettingWhereInput>;
};

export enum WorkflowEventSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  AutomatedTriggerType = 'automatedTriggerType',
  AutomatedTriggerInMinutes = 'automatedTriggerInMinutes',
  AutomatedTriggerTimeOfDay = 'automatedTriggerTimeOfDay',
  Event = 'event',
  VisitType = 'visitType',
  SendingHours = 'sendingHours',
  Name = 'name',
  WorkflowType = 'workflowType',
  TriggerType = 'triggerType',
  ClinicId = 'clinicId',
  IsPublished = 'isPublished',
  NotificationBody = 'notificationBody',
  GroupByWindowInHours = 'groupByWindowInHours',
  SurveyStep = 'surveyStep',
  SurveySettingId = 'surveySettingId',
  SurveyType = 'surveyType',
  InitialChannelStatusChangeId = 'initialChannelStatusChangeId',
  TimeFormat = 'timeFormat',
  ChildRunEvaluationStatus = 'childRunEvaluationStatus',
  MessagingCampaignId = 'messagingCampaignId'
}

export type WorkflowEventSettingScalarWhereInput = {
  AND?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  OR?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  NOT?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  automatedTriggerType?: Maybe<EnumAutomatedTriggerTypeNullableFilter>;
  automatedTriggerInMinutes?: Maybe<IntNullableFilter>;
  automatedTriggerTimeOfDay?: Maybe<DateTimeNullableFilter>;
  event?: Maybe<EnumWorkflowEventFilter>;
  visitType?: Maybe<EnumWorkflowVisitTypeFilter>;
  sendingHours?: Maybe<EnumWorkflowSendingHoursFilter>;
  name?: Maybe<StringFilter>;
  workflowType?: Maybe<EnumWorkflowTypeFilter>;
  triggerType?: Maybe<EnumWorkflowEventTriggerTypeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  isPublished?: Maybe<BoolNullableFilter>;
  notificationBody?: Maybe<StringNullableFilter>;
  groupByWindowInHours?: Maybe<IntFilter>;
  surveyStep?: Maybe<EnumSurveyStepNullableFilter>;
  surveySettingId?: Maybe<StringNullableFilter>;
  surveyType?: Maybe<EnumSurveyTypeNullableFilter>;
  initialChannelStatusChangeId?: Maybe<StringNullableFilter>;
  timeFormat?: Maybe<StringNullableFilter>;
  childRunEvaluationStatus?: Maybe<EnumConditionEvaluationStatusFilter>;
  messagingCampaignId?: Maybe<StringNullableFilter>;
};

export type WorkflowEventSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<WorkflowEventSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<WorkflowEventSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<WorkflowEventSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  automatedTriggerType?: Maybe<EnumAutomatedTriggerTypeNullableWithAggregatesFilter>;
  automatedTriggerInMinutes?: Maybe<IntNullableWithAggregatesFilter>;
  automatedTriggerTimeOfDay?: Maybe<DateTimeNullableWithAggregatesFilter>;
  event?: Maybe<EnumWorkflowEventWithAggregatesFilter>;
  visitType?: Maybe<EnumWorkflowVisitTypeWithAggregatesFilter>;
  sendingHours?: Maybe<EnumWorkflowSendingHoursWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  workflowType?: Maybe<EnumWorkflowTypeWithAggregatesFilter>;
  triggerType?: Maybe<EnumWorkflowEventTriggerTypeWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  isPublished?: Maybe<BoolNullableWithAggregatesFilter>;
  notificationBody?: Maybe<StringNullableWithAggregatesFilter>;
  groupByWindowInHours?: Maybe<IntWithAggregatesFilter>;
  surveyStep?: Maybe<EnumSurveyStepNullableWithAggregatesFilter>;
  surveySettingId?: Maybe<StringNullableWithAggregatesFilter>;
  surveyType?: Maybe<EnumSurveyTypeNullableWithAggregatesFilter>;
  initialChannelStatusChangeId?: Maybe<StringNullableWithAggregatesFilter>;
  timeFormat?: Maybe<StringNullableWithAggregatesFilter>;
  childRunEvaluationStatus?: Maybe<EnumConditionEvaluationStatusWithAggregatesFilter>;
  messagingCampaignId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type WorkflowEventSettingSumAggregateOutputType = {
  __typename?: 'WorkflowEventSettingSumAggregateOutputType';
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
};

export type WorkflowEventSettingSumOrderByAggregateInput = {
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
};

export type WorkflowEventSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutInitialChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutInitialChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyInitialChannelStatusChangeInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutMessagingCampaignInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutMessagingCampaignInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutMessagingCampaignInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyMessagingCampaignInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutSurveySettingInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutSurveySettingInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutSurveySettingInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManySurveySettingInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingUncheckedCreateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutInformAutomationCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutInitialChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutLapsedPetParentTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutMessagingCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutSurveySettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutTriggeringFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentsInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentsInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutInitialChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutInitialChannelStatusChangeInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutInitialChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyInitialChannelStatusChangeInputEnvelope>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutInitialChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutInitialChannelStatusChangeInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutMessagingCampaignNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutMessagingCampaignInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutMessagingCampaignInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutMessagingCampaignInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyMessagingCampaignInputEnvelope>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutMessagingCampaignInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutMessagingCampaignInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutSurveySettingNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutSurveySettingInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutSurveySettingInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutSurveySettingInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManySurveySettingInputEnvelope>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutSurveySettingInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutSurveySettingInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutTriggeredWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutTriggeringFormTemplatesInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutTriggeringFormTemplatesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutTriggeringFormTemplatesInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutWidgetRequestTypesInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutWidgetRequestTypesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutWidgetRequestTypesInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutInformAutomationCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutInitialChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutLapsedPetParentTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutMessagingCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutSurveySettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutTriggeringFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyType?: Maybe<SurveyType>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentTypesInput = {
  where: WorkflowEventSettingScalarWhereInput;
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentsInput = {
  where: WorkflowEventSettingScalarWhereInput;
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutClinicInput = {
  where: WorkflowEventSettingScalarWhereInput;
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowEventSettingsInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutInitialChannelStatusChangeInput = {
  where: WorkflowEventSettingScalarWhereInput;
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowEventSettingsInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutMessagingCampaignInput = {
  where: WorkflowEventSettingScalarWhereInput;
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowEventSettingsInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutSurveySettingInput = {
  where: WorkflowEventSettingScalarWhereInput;
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowEventSettingsInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutTriggeringFormTemplatesInput = {
  where: WorkflowEventSettingScalarWhereInput;
  data: WorkflowEventSettingUncheckedUpdateManyWithoutTriggeredWorkflowsInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutWidgetRequestTypesInput = {
  where: WorkflowEventSettingScalarWhereInput;
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
};

export type WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentsInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentsInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutClinicNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutInitialChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutInitialChannelStatusChangeInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutInitialChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyInitialChannelStatusChangeInputEnvelope>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutInitialChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutInitialChannelStatusChangeInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutMessagingCampaignNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutMessagingCampaignInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutMessagingCampaignInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutMessagingCampaignInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyMessagingCampaignInputEnvelope>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutMessagingCampaignInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutMessagingCampaignInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutSurveySettingNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutSurveySettingInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutSurveySettingInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutSurveySettingInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManySurveySettingInputEnvelope>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutSurveySettingInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutSurveySettingInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutTriggeringFormTemplatesInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutTriggeringFormTemplatesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutTriggeringFormTemplatesInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesNestedInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutWidgetRequestTypesInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutWidgetRequestTypesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutWidgetRequestTypesInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutAutomationRunsInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutAutomationRunsInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutAutomationRunsInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutChannelAutomationStatusesInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutClinicEmployeesNestedInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutClinicEmployeesInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutClinicEmployeesInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutClinicEmployeesInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutClinicRoomsNestedInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutClinicRoomsInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutClinicRoomsInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutClinicRoomsInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutInformAutomationCampaignsNestedInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutInformAutomationCampaignsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutInformAutomationCampaignsInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutInformAutomationCampaignsInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutInformAutomationCampaignsInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutLapsedPetParentTriggersNestedInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutLapsedPetParentTriggersInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutLapsedPetParentTriggersInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutLapsedPetParentTriggersInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutLapsedPetParentTriggersInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutWorkflowTriggersNestedInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutWorkflowTriggersInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutWorkflowTriggersInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutWorkflowTriggersInput>;
};

export type WorkflowEventSettingUpdateOneWithoutActionsNestedInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutActionsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutActionsInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutActionsInput>;
};

export type WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutChannelCreationSourcesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutChannelCreationSourcesInput>;
};

export type WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutWorkflowEventActionsInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutWorkflowEventActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutWorkflowEventActionsInput>;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentTypesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  data: WorkflowEventSettingUncheckedUpdateWithoutAppointmentTypesInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  data: WorkflowEventSettingUncheckedUpdateWithoutAppointmentsInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutClinicInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  data: WorkflowEventSettingUncheckedUpdateWithoutClinicInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutInitialChannelStatusChangeInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  data: WorkflowEventSettingUncheckedUpdateWithoutInitialChannelStatusChangeInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutMessagingCampaignInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  data: WorkflowEventSettingUncheckedUpdateWithoutMessagingCampaignInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutSurveySettingInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  data: WorkflowEventSettingUncheckedUpdateWithoutSurveySettingInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutTriggeringFormTemplatesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  data: WorkflowEventSettingUncheckedUpdateWithoutTriggeringFormTemplatesInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutWidgetRequestTypesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  data: WorkflowEventSettingUncheckedUpdateWithoutWidgetRequestTypesInput;
};

export type WorkflowEventSettingUpdateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutInformAutomationCampaignsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutInitialChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutLapsedPetParentTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutMessagingCampaignInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutSurveySettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutTriggeringFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpdateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentTypesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutAppointmentTypesInput;
  create: WorkflowEventSettingUncheckedCreateWithoutAppointmentTypesInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutAppointmentsInput;
  create: WorkflowEventSettingUncheckedCreateWithoutAppointmentsInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutClinicInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutClinicInput;
  create: WorkflowEventSettingUncheckedCreateWithoutClinicInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutInitialChannelStatusChangeInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutInitialChannelStatusChangeInput;
  create: WorkflowEventSettingUncheckedCreateWithoutInitialChannelStatusChangeInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutMessagingCampaignInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutMessagingCampaignInput;
  create: WorkflowEventSettingUncheckedCreateWithoutMessagingCampaignInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutSurveySettingInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutSurveySettingInput;
  create: WorkflowEventSettingUncheckedCreateWithoutSurveySettingInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutTriggeringFormTemplatesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutTriggeringFormTemplatesInput;
  create: WorkflowEventSettingUncheckedCreateWithoutTriggeringFormTemplatesInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutWidgetRequestTypesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutWidgetRequestTypesInput;
  create: WorkflowEventSettingUncheckedCreateWithoutWidgetRequestTypesInput;
};

export type WorkflowEventSettingUpsertWithoutActionsInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutActionsInput;
  create: WorkflowEventSettingUncheckedCreateWithoutActionsInput;
};

export type WorkflowEventSettingUpsertWithoutAutomationRunsInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutAutomationRunsInput;
  create: WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput;
};

export type WorkflowEventSettingUpsertWithoutChannelAutomationStatusesInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutChannelAutomationStatusesInput;
  create: WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type WorkflowEventSettingUpsertWithoutChannelCreationSourcesInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutChannelCreationSourcesInput;
  create: WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type WorkflowEventSettingUpsertWithoutClinicEmployeesInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutClinicEmployeesInput;
  create: WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput;
};

export type WorkflowEventSettingUpsertWithoutClinicRoomsInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutClinicRoomsInput;
  create: WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput;
};

export type WorkflowEventSettingUpsertWithoutInformAutomationCampaignsInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutInformAutomationCampaignsInput;
  create: WorkflowEventSettingUncheckedCreateWithoutInformAutomationCampaignsInput;
};

export type WorkflowEventSettingUpsertWithoutLapsedPetParentTriggersInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutLapsedPetParentTriggersInput;
  create: WorkflowEventSettingUncheckedCreateWithoutLapsedPetParentTriggersInput;
};

export type WorkflowEventSettingUpsertWithoutWorkflowEventActionsInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutWorkflowEventActionsInput;
  create: WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput;
};

export type WorkflowEventSettingUpsertWithoutWorkflowTriggersInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutWorkflowTriggersInput;
  create: WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput;
};

export type WorkflowEventSettingWhereInput = {
  AND?: Maybe<Array<WorkflowEventSettingWhereInput>>;
  OR?: Maybe<Array<WorkflowEventSettingWhereInput>>;
  NOT?: Maybe<Array<WorkflowEventSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  automatedTriggerType?: Maybe<EnumAutomatedTriggerTypeNullableFilter>;
  automatedTriggerInMinutes?: Maybe<IntNullableFilter>;
  automatedTriggerTimeOfDay?: Maybe<DateTimeNullableFilter>;
  event?: Maybe<EnumWorkflowEventFilter>;
  visitType?: Maybe<EnumWorkflowVisitTypeFilter>;
  sendingHours?: Maybe<EnumWorkflowSendingHoursFilter>;
  name?: Maybe<StringFilter>;
  workflowType?: Maybe<EnumWorkflowTypeFilter>;
  triggerType?: Maybe<EnumWorkflowEventTriggerTypeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  isPublished?: Maybe<BoolNullableFilter>;
  notificationBody?: Maybe<StringNullableFilter>;
  groupByWindowInHours?: Maybe<IntFilter>;
  surveyStep?: Maybe<EnumSurveyStepNullableFilter>;
  surveySettingId?: Maybe<StringNullableFilter>;
  surveyType?: Maybe<EnumSurveyTypeNullableFilter>;
  initialChannelStatusChangeId?: Maybe<StringNullableFilter>;
  timeFormat?: Maybe<StringNullableFilter>;
  childRunEvaluationStatus?: Maybe<EnumConditionEvaluationStatusFilter>;
  messagingCampaignId?: Maybe<StringNullableFilter>;
  surveySetting?: Maybe<SurveySettingWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusWhereInput>;
  actions?: Maybe<WorkflowEventActionListRelationFilter>;
  appointmentTypes?: Maybe<AppointmentTypeListRelationFilter>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  workflowEventActions?: Maybe<WorkflowEventActionListRelationFilter>;
  workflowTriggers?: Maybe<WorkflowTriggerListRelationFilter>;
  triggeringFormTemplates?: Maybe<FormTemplateListRelationFilter>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingListRelationFilter>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersListRelationFilter>;
  messagingCampaign?: Maybe<MessagingCampaignWhereInput>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignListRelationFilter>;
};

export type WorkflowEventSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum WorkflowEventTriggerType {
  Manual = 'Manual',
  Automated = 'Automated'
}

export enum WorkflowSendingHours {
  AutomationHours = 'AutomationHours',
  BusinessHours = 'BusinessHours',
  All24Hours = 'All24Hours'
}

export type WorkflowTrigger = {
  __typename?: 'WorkflowTrigger';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  workflowId: Scalars['String'];
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  workflow: WorkflowEventSetting;
  appointment?: Maybe<Appointment>;
};

export type WorkflowTriggerAppointmentIdWorkflowIdCompoundUniqueInput = {
  appointmentId: Scalars['String'];
  workflowId: Scalars['String'];
};

export type WorkflowTriggerCountAggregateOutputType = {
  __typename?: 'WorkflowTriggerCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  workflowId: Scalars['Int'];
  appointmentId: Scalars['Int'];
  triggerAt: Scalars['Int'];
  hasTriggered: Scalars['Int'];
  isDeleted: Scalars['Int'];
  _all: Scalars['Int'];
};

export type WorkflowTriggerCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type WorkflowTriggerCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutWorkflowTriggersInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutWorkflowTriggersInput>;
};

export type WorkflowTriggerCreateManyAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerCreateManyAppointmentInputEnvelope = {
  data: Array<WorkflowTriggerCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerCreateManyWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerCreateManyWorkflowInputEnvelope = {
  data: Array<WorkflowTriggerCreateManyWorkflowInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
};

export type WorkflowTriggerCreateNestedManyWithoutWorkflowInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutWorkflowInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyWorkflowInputEnvelope>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
};

export type WorkflowTriggerCreateOrConnectWithoutAppointmentInput = {
  where: WorkflowTriggerWhereUniqueInput;
  create: WorkflowTriggerUncheckedCreateWithoutAppointmentInput;
};

export type WorkflowTriggerCreateOrConnectWithoutWorkflowInput = {
  where: WorkflowTriggerWhereUniqueInput;
  create: WorkflowTriggerUncheckedCreateWithoutWorkflowInput;
};

export type WorkflowTriggerCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutWorkflowTriggersInput;
};

export type WorkflowTriggerCreateWithoutWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutWorkflowTriggersInput>;
};

export type WorkflowTriggerListRelationFilter = {
  every?: Maybe<WorkflowTriggerWhereInput>;
  some?: Maybe<WorkflowTriggerWhereInput>;
  none?: Maybe<WorkflowTriggerWhereInput>;
};

export type WorkflowTriggerMaxAggregateOutputType = {
  __typename?: 'WorkflowTriggerMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type WorkflowTriggerMinAggregateOutputType = {
  __typename?: 'WorkflowTriggerMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type WorkflowTriggerOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum WorkflowTriggerOrderByRelevanceFieldEnum {
  Id = 'id',
  WorkflowId = 'workflowId',
  AppointmentId = 'appointmentId'
}

export type WorkflowTriggerOrderByRelevanceInput = {
  fields: Array<WorkflowTriggerOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type WorkflowTriggerOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _count?: Maybe<WorkflowTriggerCountOrderByAggregateInput>;
  _max?: Maybe<WorkflowTriggerMaxOrderByAggregateInput>;
  _min?: Maybe<WorkflowTriggerMinOrderByAggregateInput>;
};

export type WorkflowTriggerOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  workflow?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<WorkflowTriggerOrderByRelevanceInput>;
};

export enum WorkflowTriggerScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  WorkflowId = 'workflowId',
  AppointmentId = 'appointmentId',
  TriggerAt = 'triggerAt',
  HasTriggered = 'hasTriggered',
  IsDeleted = 'isDeleted'
}

export type WorkflowTriggerScalarWhereInput = {
  AND?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
  OR?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
  NOT?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  triggerAt?: Maybe<DateTimeFilter>;
  hasTriggered?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type WorkflowTriggerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<WorkflowTriggerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<WorkflowTriggerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<WorkflowTriggerScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  workflowId?: Maybe<StringWithAggregatesFilter>;
  appointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  triggerAt?: Maybe<DateTimeWithAggregatesFilter>;
  hasTriggered?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
};

export type WorkflowTriggerUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
};

export type WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutWorkflowInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyWorkflowInputEnvelope>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
};

export type WorkflowTriggerUncheckedCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUncheckedCreateWithoutWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<WorkflowTriggerUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowTriggerUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<WorkflowTriggerUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
};

export type WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutWorkflowInput>>;
  upsert?: Maybe<Array<WorkflowTriggerUpsertWithWhereUniqueWithoutWorkflowInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyWorkflowInputEnvelope>;
  set?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowTriggerUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<WorkflowTriggerUpdateManyWithWhereWithoutWorkflowInput>>;
  deleteMany?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
};

export type WorkflowTriggerUncheckedUpdateManyWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUncheckedUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUncheckedUpdateWithoutWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutWorkflowTriggersNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutWorkflowTriggersNestedInput>;
};

export type WorkflowTriggerUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUpdateManyWithWhereWithoutAppointmentInput = {
  where: WorkflowTriggerScalarWhereInput;
  data: WorkflowTriggerUncheckedUpdateManyWithoutWorkflowTriggersInput;
};

export type WorkflowTriggerUpdateManyWithWhereWithoutWorkflowInput = {
  where: WorkflowTriggerScalarWhereInput;
  data: WorkflowTriggerUncheckedUpdateManyWithoutWorkflowTriggersInput;
};

export type WorkflowTriggerUpdateManyWithoutAppointmentNestedInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<WorkflowTriggerUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowTriggerUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<WorkflowTriggerUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
};

export type WorkflowTriggerUpdateManyWithoutWorkflowNestedInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutWorkflowInput>>;
  upsert?: Maybe<Array<WorkflowTriggerUpsertWithWhereUniqueWithoutWorkflowInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyWorkflowInputEnvelope>;
  set?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowTriggerUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<WorkflowTriggerUpdateManyWithWhereWithoutWorkflowInput>>;
  deleteMany?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
};

export type WorkflowTriggerUpdateWithWhereUniqueWithoutAppointmentInput = {
  where: WorkflowTriggerWhereUniqueInput;
  data: WorkflowTriggerUncheckedUpdateWithoutAppointmentInput;
};

export type WorkflowTriggerUpdateWithWhereUniqueWithoutWorkflowInput = {
  where: WorkflowTriggerWhereUniqueInput;
  data: WorkflowTriggerUncheckedUpdateWithoutWorkflowInput;
};

export type WorkflowTriggerUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutWorkflowTriggersNestedInput>;
};

export type WorkflowTriggerUpdateWithoutWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutWorkflowTriggersNestedInput>;
};

export type WorkflowTriggerUpsertWithWhereUniqueWithoutAppointmentInput = {
  where: WorkflowTriggerWhereUniqueInput;
  update: WorkflowTriggerUncheckedUpdateWithoutAppointmentInput;
  create: WorkflowTriggerUncheckedCreateWithoutAppointmentInput;
};

export type WorkflowTriggerUpsertWithWhereUniqueWithoutWorkflowInput = {
  where: WorkflowTriggerWhereUniqueInput;
  update: WorkflowTriggerUncheckedUpdateWithoutWorkflowInput;
  create: WorkflowTriggerUncheckedCreateWithoutWorkflowInput;
};

export type WorkflowTriggerWhereInput = {
  AND?: Maybe<Array<WorkflowTriggerWhereInput>>;
  OR?: Maybe<Array<WorkflowTriggerWhereInput>>;
  NOT?: Maybe<Array<WorkflowTriggerWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  triggerAt?: Maybe<DateTimeFilter>;
  hasTriggered?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  workflow?: Maybe<WorkflowEventSettingWhereInput>;
  appointment?: Maybe<AppointmentWhereInput>;
};

export type WorkflowTriggerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  appointmentId_workflowId?: Maybe<WorkflowTriggerAppointmentIdWorkflowIdCompoundUniqueInput>;
};

export enum WorkflowType {
  NoWorkflow = 'NoWorkflow',
  Curbside = 'Curbside',
  Telemedicine = 'Telemedicine',
  InHouse = 'InHouse',
  Emergency = 'Emergency',
  Surgery = 'Surgery',
  Wellness = 'Wellness',
  Boarding = 'Boarding',
  Specialty = 'Specialty',
  Diagnostics = 'Diagnostics',
  MobileVisit = 'MobileVisit'
}

export enum WorkflowVisitType {
  Client = 'Client',
  Patient = 'Patient'
}

export type WritebackCareInvoiceAccountCreditToPimsInput = {
  invoiceId: Scalars['String'];
  paymentAmount: Scalars['Int'];
};

export type WritebackCareInvoiceAccountCreditToPimsResponse = {
  __typename?: 'WritebackCareInvoiceAccountCreditToPimsResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type GetQuickSightEmbedUrlInput = {
  dashboardId: Scalars['String'];
};

export type SendEmailCampaignNowInput = {
  emailCampaignId: Scalars['String'];
};

export type SendVetSubscriptionReceiptInput = {
  clinicId: Scalars['String'];
  firebaseId: Scalars['String'];
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SentEmailCampaign = {
  __typename?: 'sentEmailCampaign';
  id?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
};

export type CreateDirectBookingAppointmentMutationVariables = Exact<{
  data?: Maybe<AppointmentCreateInput>;
}>;


export type CreateDirectBookingAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { createDirectBookingAppointment?: Maybe<(
    { __typename?: 'DirectBookingAppointmentResponse' }
    & Pick<DirectBookingAppointmentResponse, 'id' | 'startAt'>
  )> }
);

export type SyncAvailabilitiesMutationVariables = Exact<{
  data: SyncManyPimsIntegrationModelInput;
}>;


export type SyncAvailabilitiesMutation = (
  { __typename?: 'Mutation' }
  & { syncManyPimsIntegrationModel?: Maybe<Array<(
    { __typename?: 'ClinicPimsIntegrationCapability' }
    & Pick<ClinicPimsIntegrationCapability, 'id'>
  )>> }
);

export type GetClinicDirectBookingSettingsQueryVariables = Exact<{
  data: ClinicDirectBookingSettingsInput;
}>;


export type GetClinicDirectBookingSettingsQuery = (
  { __typename?: 'Query' }
  & { getClinicDirectBookingSettings?: Maybe<(
    { __typename?: 'ClinicDirectBookingSettingsResponse' }
    & Pick<ClinicDirectBookingSettingsResponse, 'maxSchedulingRangeInMonths' | 'bookingDeadlineInHours' | 'bookingNeedsConfirmation' | 'isOneTimePasswordEnabled' | 'appointmentIntervalInMinutes' | 'appointmentIntervalInMinutesDisplay'>
  )> }
);

export type GetDirectBookingAppointmentTypesQueryVariables = Exact<{
  data: DirectBookingAppointmentTypeInput;
}>;


export type GetDirectBookingAppointmentTypesQuery = (
  { __typename?: 'Query' }
  & { getDirectBookingAppointmentTypes: Array<Maybe<(
    { __typename?: 'DirectBookingAppointmentTypes' }
    & Pick<DirectBookingAppointmentTypes, 'id' | 'name' | 'displayName' | 'defaultDurationInMinutes' | 'delayedStartTime' | 'description' | 'defaultSoonestAvailableBookingInHours'>
    & { directBookingAppointmentTypeSetting?: Maybe<(
      { __typename?: 'DirectBookingAppointmentTypeSettingSelection' }
      & Pick<DirectBookingAppointmentTypeSettingSelection, 'id' | 'clientType'>
    )>, clinicEmployeeAppointmentTypeSettings: Array<(
      { __typename?: 'AppointmentTypeClinicEmployeeMapping' }
      & { clinicEmployee: (
        { __typename?: 'AppointmentTypeClinicEmployee' }
        & Pick<AppointmentTypeClinicEmployee, 'id' | 'firstName' | 'lastName'>
      ) }
    )> }
  )>> }
);

export type GetDirectBookingAvailabilityDatesQueryVariables = Exact<{
  data: DirectBookingOpenTimeSlot2Input;
}>;


export type GetDirectBookingAvailabilityDatesQuery = (
  { __typename?: 'Query' }
  & { getDirectBookingOpenTimeSlots2: (
    { __typename?: 'DirectBookingOpenTimeSlot2Response' }
    & Pick<DirectBookingOpenTimeSlot2Response, 'usedTimezone'>
    & { availableTimeSlots?: Maybe<Array<(
      { __typename?: 'DirectBookingOpenTimeSlot2Availability' }
      & Pick<DirectBookingOpenTimeSlot2Availability, 'clinicEmployeeId' | 'clinicEmployeeFirstName' | 'clinicEmployeeLastName'>
      & { availability: Array<(
        { __typename?: 'DirectBookingDateAvailability' }
        & Pick<DirectBookingDateAvailability, 'bookingDate' | 'hasAvailability' | 'openTimeSlots'>
      )> }
    )>> }
  ) }
);

export type GetDirectBookingAvailabilityTimeSlotsQueryVariables = Exact<{
  data: DirectBookingOpenTimeSlot2Input;
}>;


export type GetDirectBookingAvailabilityTimeSlotsQuery = (
  { __typename?: 'Query' }
  & { getDirectBookingOpenTimeSlots2: (
    { __typename?: 'DirectBookingOpenTimeSlot2Response' }
    & Pick<DirectBookingOpenTimeSlot2Response, 'usedTimezone'>
    & { availableTimeSlots?: Maybe<Array<(
      { __typename?: 'DirectBookingOpenTimeSlot2Availability' }
      & Pick<DirectBookingOpenTimeSlot2Availability, 'clinicEmployeeId' | 'clinicEmployeeFirstName' | 'clinicEmployeeLastName'>
      & { availability: Array<(
        { __typename?: 'DirectBookingDateAvailability' }
        & Pick<DirectBookingDateAvailability, 'bookingDate' | 'hasAvailability' | 'openTimeSlots'>
      )> }
    )>> }
  ) }
);

export type GetHasConflictingAppointmentQueryVariables = Exact<{
  data: HasConflictingAppointmentInput;
}>;


export type GetHasConflictingAppointmentQuery = (
  { __typename?: 'Query' }
  & { hasConflictingAppointment: Query['getHasConflictingAppointment'] }
);

export type GetNewPetOptionsQueryVariables = Exact<{
  integrationId: Scalars['String'];
}>;


export type GetNewPetOptionsQuery = (
  { __typename?: 'Query' }
  & { findManySpecies?: Maybe<Array<(
    { __typename?: 'Species' }
    & Pick<Species, 'id' | 'name'>
    & { breeds: Array<(
      { __typename?: 'Breed' }
      & Pick<Breed, 'id' | 'name'>
    )> }
  )>> }
);

export type GetSexesQueryVariables = Exact<{
  integrationId: Scalars['String'];
}>;


export type GetSexesQuery = (
  { __typename?: 'Query' }
  & { findManySex?: Maybe<Array<(
    { __typename?: 'Sex' }
    & Pick<Sex, 'id' | 'name' | 'speciesId'>
  )>> }
);

export type GetProviderAppointmentTypeRoomMapQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetProviderAppointmentTypeRoomMapQuery = (
  { __typename?: 'Query' }
  & { findManyProviderAppointmentTypeRoomMap?: Maybe<Array<(
    { __typename?: 'ProviderAppointmentTypeRoomMap' }
    & Pick<ProviderAppointmentTypeRoomMap, 'id' | 'clinicEmployeeId' | 'appoinmentTypeId' | 'roomId'>
  )>> }
);

export type SubmitPrescriptionRefillRequestMutationVariables = Exact<{
  data: SubmitPrescriptionRefillRequestInput;
}>;


export type SubmitPrescriptionRefillRequestMutation = (
  { __typename?: 'Mutation' }
  & { submitPrescriptionRefillRequest: (
    { __typename?: 'SubmitPrescriptionRefillRequestResponse' }
    & Pick<SubmitPrescriptionRefillRequestResponse, 'success' | 'channelId' | 'clinicWidgetRequestId'>
  ) }
);

export type CreateClinicWidgetRequestMutationVariables = Exact<{
  data: ClinicWidgetRequestCreateInput;
}>;


export type CreateClinicWidgetRequestMutation = (
  { __typename?: 'Mutation' }
  & { createClinicWidgetRequest2: (
    { __typename?: 'ClinicWidgetRequest' }
    & Pick<ClinicWidgetRequest, 'id' | 'clinicId'>
    & { clinicWidgetRequestType?: Maybe<(
      { __typename?: 'ClinicWidgetRequestType' }
      & Pick<ClinicWidgetRequestType, 'requestType'>
    )>, clinic?: Maybe<(
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'name'>
    )> }
  ) }
);

export type CreateParentAndPatientResponseFragment = (
  { __typename?: 'ClinicPetParent' }
  & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName' | 'email' | 'createdAt' | 'creationSource'>
  & { phoneNumbers: Array<(
    { __typename?: 'ClinicEntityPhoneNumber' }
    & Pick<ClinicEntityPhoneNumber, 'id' | 'number'>
  )>, pets: Array<(
    { __typename?: 'ClinicPet' }
    & Pick<ClinicPet, 'id' | 'name' | 'species'>
  )> }
);

export type CreateParentAndPatientMutationVariables = Exact<{
  data: ClinicPetParentCreateInput;
}>;


export type CreateParentAndPatientMutation = (
  { __typename?: 'Mutation' }
  & { createOneClinicPetParent: (
    { __typename?: 'ClinicPetParent' }
    & CreateParentAndPatientResponseFragment
  ) }
);

export type GenerateWidgetAuthenticationRequestMutationVariables = Exact<{
  where: WidgetClinicPetParentLookupInput;
}>;


export type GenerateWidgetAuthenticationRequestMutation = (
  { __typename?: 'Mutation' }
  & { generateWidgetAuthenticationRequest?: Maybe<(
    { __typename?: 'GenerateWidgetAuthenticationRequestResponse' }
    & Pick<GenerateWidgetAuthenticationRequestResponse, 'success' | 'message'>
  )> }
);

export type SendCarePlanSignUpLinkMutationVariables = Exact<{
  data?: Maybe<SendCareSignUpEmailInput>;
}>;


export type SendCarePlanSignUpLinkMutation = (
  { __typename?: 'Mutation' }
  & { sendCareSignUpEmail?: Maybe<(
    { __typename?: 'SendCareSignUpEmailOutput' }
    & Pick<SendCareSignUpEmailOutput, 'success'>
  )> }
);

export type SendClinicPetParentAuthLinkMutationVariables = Exact<{
  data: SendClinicPetParentAuthLinkRequest;
}>;


export type SendClinicPetParentAuthLinkMutation = (
  { __typename?: 'Mutation' }
  & { sendClinicPetParentAuthLink?: Maybe<(
    { __typename?: 'ClinicPetParentAuthLinkResponse' }
    & Pick<ClinicPetParentAuthLinkResponse, 'success' | 'message'>
  )> }
);

export type VerifyWidgetAuthenticationRequestMutationVariables = Exact<{
  data: VerifyWidgetAuthenticationRequestInput;
}>;


export type VerifyWidgetAuthenticationRequestMutation = (
  { __typename?: 'Mutation' }
  & { verifyWidgetAuthenticationRequest?: Maybe<(
    { __typename?: 'VerifyWidgetAuthenticationRequestResponse' }
    & Pick<VerifyWidgetAuthenticationRequestResponse, 'success' | 'token'>
  )> }
);

export type GetClinicWidgetRequestQueryVariables = Exact<{
  where: ClinicWidgetRequestWhereUniqueInput;
}>;


export type GetClinicWidgetRequestQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinicWidgetRequest?: Maybe<(
    { __typename?: 'ClinicWidgetRequest' }
    & Pick<ClinicWidgetRequest, 'id' | 'isVerified'>
    & { clinicWidgetRequestType?: Maybe<(
      { __typename?: 'ClinicWidgetRequestType' }
      & Pick<ClinicWidgetRequestType, 'id' | 'requestType'>
    )> }
  )> }
);

export type ClinicPimsIntegrationSelectionFragment = (
  { __typename?: 'ClinicPimsIntegration' }
  & Pick<ClinicPimsIntegration, 'id' | 'isActive' | 'type'>
  & { vetdata?: Maybe<(
    { __typename?: 'VetDataIntegration' }
    & Pick<VetDataIntegration, 'system'>
  )> }
);

export type GetClinicWidgetSettingQueryVariables = Exact<{
  where: ClinicWidgetSettingWhereInput;
}>;


export type GetClinicWidgetSettingQuery = (
  { __typename?: 'Query' }
  & { findFirstClinicWidgetSetting?: Maybe<(
    { __typename?: 'ClinicWidgetSetting' }
    & Pick<ClinicWidgetSetting, 'id' | 'isEnabled' | 'welcomeMessage' | 'primaryColor' | 'secondaryColor' | 'whiteLabelUrls'>
    & { clinic: Array<(
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name' | 'logo' | 'optimizedLogoUrl' | 'clinicEmail' | 'phone'>
      & { featureFlags: Array<(
        { __typename?: 'FeatureFlag' }
        & Pick<FeatureFlag, 'id' | 'key'>
      )>, integrations: Array<(
        { __typename?: 'ClinicPimsIntegration' }
        & ClinicPimsIntegrationSelectionFragment
      )> }
    )>, clinicWidgetRequestTypes: Array<(
      { __typename?: 'ClinicWidgetRequestType' }
      & Pick<ClinicWidgetRequestType, 'id' | 'displayName' | 'isDeleted' | 'isActive' | 'order' | 'requestType' | 'requestFormTemplate'>
    )> }
  )> }
);

export type WidgetClinicPetParentLookupQueryVariables = Exact<{
  where: WidgetClinicPetParentLookupInput;
}>;


export type WidgetClinicPetParentLookupQuery = (
  { __typename?: 'Query' }
  & { widgetClinicPetParentLookup2: Array<(
    { __typename?: 'WidgetClinicPetParentLookupResponse' }
    & Pick<WidgetClinicPetParentLookupResponse, 'id' | 'pimsId' | 'firstName' | 'lastName' | 'email' | 'isActive' | 'createdAt' | 'creationSource'>
    & { pets: Array<(
      { __typename?: 'ClinicPet' }
      & Pick<ClinicPet, 'id' | 'pimsId' | 'name' | 'species' | 'photoUrl'>
      & { prescriptions: Array<(
        { __typename?: 'Prescription' }
        & Pick<Prescription, 'id' | 'description' | 'quantity' | 'instructions' | 'comments' | 'issuedAt' | 'expiredAt' | 'lastRequestedAt' | 'isActive' | 'isDeleted' | 'currentRefillNumber' | 'totalRefills'>
      )> }
    )>, phoneNumbers: Array<(
      { __typename?: 'ClinicEntityPhoneNumber' }
      & Pick<ClinicEntityPhoneNumber, 'number' | 'isPrimary' | 'isMobile' | 'isDeleted' | 'updatedAt'>
    )> }
  )> }
);

export type WidgetClinicPetParentLookupEnrollmentQueryVariables = Exact<{
  where: WidgetClinicPetParentLookupInput;
}>;


export type WidgetClinicPetParentLookupEnrollmentQuery = (
  { __typename?: 'Query' }
  & { widgetClinicPetParentLookupEnrollment: Array<Maybe<(
    { __typename?: 'WidgetClinicPetParentLookupResponse' }
    & Pick<WidgetClinicPetParentLookupResponse, 'id' | 'firstName' | 'lastName' | 'email' | 'isActive'>
    & { pets: Array<(
      { __typename?: 'ClinicPet' }
      & WidgetClinicPetLookupEnrollmentFragment
    )>, phoneNumbers: Array<(
      { __typename?: 'ClinicEntityPhoneNumber' }
      & Pick<ClinicEntityPhoneNumber, 'id' | 'number' | 'isPrimary' | 'isMobile' | 'isDeleted' | 'updatedAt'>
    )> }
  )>> }
);

export type WidgetClinicPetLookupEnrollmentFragment = (
  { __typename?: 'ClinicPet' }
  & Pick<ClinicPet, 'id' | 'name' | 'species' | 'photoUrl' | 'organizationPetId'>
  & { organizationPet?: Maybe<(
    { __typename?: 'OrganizationPet' }
    & Pick<OrganizationPet, 'id'>
    & { carePlanEnrollments: Array<(
      { __typename?: 'CarePlanEnrollment' }
      & Pick<CarePlanEnrollment, 'id' | 'status' | 'startDate' | 'expirationDate'>
    )> }
  )> }
);

export const CreateParentAndPatientResponseFragmentDoc = gql`
    fragment CreateParentAndPatientResponse on ClinicPetParent {
  id
  firstName
  lastName
  email
  createdAt
  creationSource
  phoneNumbers {
    id
    number
  }
  pets {
    id
    name
    species
  }
}
    `;
export const ClinicPimsIntegrationSelectionFragmentDoc = gql`
    fragment ClinicPimsIntegrationSelection on ClinicPimsIntegration {
  id
  isActive
  type
  vetdata {
    system
  }
}
    `;
export const WidgetClinicPetLookupEnrollmentFragmentDoc = gql`
    fragment WidgetClinicPetLookupEnrollment on ClinicPet {
  id
  name
  species
  photoUrl
  organizationPetId
  organizationPet {
    id
    carePlanEnrollments {
      id
      status
      startDate
      expirationDate
    }
  }
}
    `;
export const CreateDirectBookingAppointmentDocument = gql`
    mutation createDirectBookingAppointment($data: AppointmentCreateInput) {
  createDirectBookingAppointment(data: $data) {
    id
    startAt
  }
}
    `;
export type CreateDirectBookingAppointmentMutationFn = Apollo.MutationFunction<CreateDirectBookingAppointmentMutation, CreateDirectBookingAppointmentMutationVariables>;

/**
 * __useCreateDirectBookingAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateDirectBookingAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectBookingAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectBookingAppointmentMutation, { data, loading, error }] = useCreateDirectBookingAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDirectBookingAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDirectBookingAppointmentMutation, CreateDirectBookingAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDirectBookingAppointmentMutation, CreateDirectBookingAppointmentMutationVariables>(CreateDirectBookingAppointmentDocument, options);
      }
export type CreateDirectBookingAppointmentMutationHookResult = ReturnType<typeof useCreateDirectBookingAppointmentMutation>;
export type CreateDirectBookingAppointmentMutationResult = Apollo.MutationResult<CreateDirectBookingAppointmentMutation>;
export type CreateDirectBookingAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateDirectBookingAppointmentMutation, CreateDirectBookingAppointmentMutationVariables>;
export const SyncAvailabilitiesDocument = gql`
    mutation syncAvailabilities($data: SyncManyPimsIntegrationModelInput!) {
  syncManyPimsIntegrationModel(data: $data) {
    id
  }
}
    `;
export type SyncAvailabilitiesMutationFn = Apollo.MutationFunction<SyncAvailabilitiesMutation, SyncAvailabilitiesMutationVariables>;

/**
 * __useSyncAvailabilitiesMutation__
 *
 * To run a mutation, you first call `useSyncAvailabilitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncAvailabilitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncAvailabilitiesMutation, { data, loading, error }] = useSyncAvailabilitiesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSyncAvailabilitiesMutation(baseOptions?: Apollo.MutationHookOptions<SyncAvailabilitiesMutation, SyncAvailabilitiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncAvailabilitiesMutation, SyncAvailabilitiesMutationVariables>(SyncAvailabilitiesDocument, options);
      }
export type SyncAvailabilitiesMutationHookResult = ReturnType<typeof useSyncAvailabilitiesMutation>;
export type SyncAvailabilitiesMutationResult = Apollo.MutationResult<SyncAvailabilitiesMutation>;
export type SyncAvailabilitiesMutationOptions = Apollo.BaseMutationOptions<SyncAvailabilitiesMutation, SyncAvailabilitiesMutationVariables>;
export const GetClinicDirectBookingSettingsDocument = gql`
    query getClinicDirectBookingSettings($data: ClinicDirectBookingSettingsInput!) {
  getClinicDirectBookingSettings(data: $data) {
    maxSchedulingRangeInMonths
    bookingDeadlineInHours
    bookingNeedsConfirmation
    isOneTimePasswordEnabled
    appointmentIntervalInMinutes
    appointmentIntervalInMinutesDisplay
  }
}
    `;

/**
 * __useGetClinicDirectBookingSettingsQuery__
 *
 * To run a query within a React component, call `useGetClinicDirectBookingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicDirectBookingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicDirectBookingSettingsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetClinicDirectBookingSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetClinicDirectBookingSettingsQuery, GetClinicDirectBookingSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicDirectBookingSettingsQuery, GetClinicDirectBookingSettingsQueryVariables>(GetClinicDirectBookingSettingsDocument, options);
      }
export function useGetClinicDirectBookingSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicDirectBookingSettingsQuery, GetClinicDirectBookingSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicDirectBookingSettingsQuery, GetClinicDirectBookingSettingsQueryVariables>(GetClinicDirectBookingSettingsDocument, options);
        }
export type GetClinicDirectBookingSettingsQueryHookResult = ReturnType<typeof useGetClinicDirectBookingSettingsQuery>;
export type GetClinicDirectBookingSettingsLazyQueryHookResult = ReturnType<typeof useGetClinicDirectBookingSettingsLazyQuery>;
export type GetClinicDirectBookingSettingsQueryResult = Apollo.QueryResult<GetClinicDirectBookingSettingsQuery, GetClinicDirectBookingSettingsQueryVariables>;
export function refetchGetClinicDirectBookingSettingsQuery(variables?: GetClinicDirectBookingSettingsQueryVariables) {
      return { query: GetClinicDirectBookingSettingsDocument, variables: variables }
    }
export const GetDirectBookingAppointmentTypesDocument = gql`
    query getDirectBookingAppointmentTypes($data: DirectBookingAppointmentTypeInput!) {
  getDirectBookingAppointmentTypes(data: $data) {
    id
    name
    displayName
    defaultDurationInMinutes
    delayedStartTime
    description
    defaultSoonestAvailableBookingInHours
    directBookingAppointmentTypeSetting {
      id
      clientType
    }
    clinicEmployeeAppointmentTypeSettings {
      clinicEmployee {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetDirectBookingAppointmentTypesQuery__
 *
 * To run a query within a React component, call `useGetDirectBookingAppointmentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectBookingAppointmentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectBookingAppointmentTypesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetDirectBookingAppointmentTypesQuery(baseOptions: Apollo.QueryHookOptions<GetDirectBookingAppointmentTypesQuery, GetDirectBookingAppointmentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDirectBookingAppointmentTypesQuery, GetDirectBookingAppointmentTypesQueryVariables>(GetDirectBookingAppointmentTypesDocument, options);
      }
export function useGetDirectBookingAppointmentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDirectBookingAppointmentTypesQuery, GetDirectBookingAppointmentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDirectBookingAppointmentTypesQuery, GetDirectBookingAppointmentTypesQueryVariables>(GetDirectBookingAppointmentTypesDocument, options);
        }
export type GetDirectBookingAppointmentTypesQueryHookResult = ReturnType<typeof useGetDirectBookingAppointmentTypesQuery>;
export type GetDirectBookingAppointmentTypesLazyQueryHookResult = ReturnType<typeof useGetDirectBookingAppointmentTypesLazyQuery>;
export type GetDirectBookingAppointmentTypesQueryResult = Apollo.QueryResult<GetDirectBookingAppointmentTypesQuery, GetDirectBookingAppointmentTypesQueryVariables>;
export function refetchGetDirectBookingAppointmentTypesQuery(variables?: GetDirectBookingAppointmentTypesQueryVariables) {
      return { query: GetDirectBookingAppointmentTypesDocument, variables: variables }
    }
export const GetDirectBookingAvailabilityDatesDocument = gql`
    query getDirectBookingAvailabilityDates($data: DirectBookingOpenTimeSlot2Input!) {
  getDirectBookingOpenTimeSlots2(data: $data) {
    usedTimezone
    availableTimeSlots {
      clinicEmployeeId
      clinicEmployeeFirstName
      clinicEmployeeLastName
      availability {
        bookingDate
        hasAvailability
        openTimeSlots
      }
    }
  }
}
    `;

/**
 * __useGetDirectBookingAvailabilityDatesQuery__
 *
 * To run a query within a React component, call `useGetDirectBookingAvailabilityDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectBookingAvailabilityDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectBookingAvailabilityDatesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetDirectBookingAvailabilityDatesQuery(baseOptions: Apollo.QueryHookOptions<GetDirectBookingAvailabilityDatesQuery, GetDirectBookingAvailabilityDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDirectBookingAvailabilityDatesQuery, GetDirectBookingAvailabilityDatesQueryVariables>(GetDirectBookingAvailabilityDatesDocument, options);
      }
export function useGetDirectBookingAvailabilityDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDirectBookingAvailabilityDatesQuery, GetDirectBookingAvailabilityDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDirectBookingAvailabilityDatesQuery, GetDirectBookingAvailabilityDatesQueryVariables>(GetDirectBookingAvailabilityDatesDocument, options);
        }
export type GetDirectBookingAvailabilityDatesQueryHookResult = ReturnType<typeof useGetDirectBookingAvailabilityDatesQuery>;
export type GetDirectBookingAvailabilityDatesLazyQueryHookResult = ReturnType<typeof useGetDirectBookingAvailabilityDatesLazyQuery>;
export type GetDirectBookingAvailabilityDatesQueryResult = Apollo.QueryResult<GetDirectBookingAvailabilityDatesQuery, GetDirectBookingAvailabilityDatesQueryVariables>;
export function refetchGetDirectBookingAvailabilityDatesQuery(variables?: GetDirectBookingAvailabilityDatesQueryVariables) {
      return { query: GetDirectBookingAvailabilityDatesDocument, variables: variables }
    }
export const GetDirectBookingAvailabilityTimeSlotsDocument = gql`
    query getDirectBookingAvailabilityTimeSlots($data: DirectBookingOpenTimeSlot2Input!) {
  getDirectBookingOpenTimeSlots2(data: $data) {
    usedTimezone
    availableTimeSlots {
      clinicEmployeeId
      clinicEmployeeFirstName
      clinicEmployeeLastName
      availability {
        bookingDate
        hasAvailability
        openTimeSlots
      }
    }
  }
}
    `;

/**
 * __useGetDirectBookingAvailabilityTimeSlotsQuery__
 *
 * To run a query within a React component, call `useGetDirectBookingAvailabilityTimeSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectBookingAvailabilityTimeSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectBookingAvailabilityTimeSlotsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetDirectBookingAvailabilityTimeSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetDirectBookingAvailabilityTimeSlotsQuery, GetDirectBookingAvailabilityTimeSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDirectBookingAvailabilityTimeSlotsQuery, GetDirectBookingAvailabilityTimeSlotsQueryVariables>(GetDirectBookingAvailabilityTimeSlotsDocument, options);
      }
export function useGetDirectBookingAvailabilityTimeSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDirectBookingAvailabilityTimeSlotsQuery, GetDirectBookingAvailabilityTimeSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDirectBookingAvailabilityTimeSlotsQuery, GetDirectBookingAvailabilityTimeSlotsQueryVariables>(GetDirectBookingAvailabilityTimeSlotsDocument, options);
        }
export type GetDirectBookingAvailabilityTimeSlotsQueryHookResult = ReturnType<typeof useGetDirectBookingAvailabilityTimeSlotsQuery>;
export type GetDirectBookingAvailabilityTimeSlotsLazyQueryHookResult = ReturnType<typeof useGetDirectBookingAvailabilityTimeSlotsLazyQuery>;
export type GetDirectBookingAvailabilityTimeSlotsQueryResult = Apollo.QueryResult<GetDirectBookingAvailabilityTimeSlotsQuery, GetDirectBookingAvailabilityTimeSlotsQueryVariables>;
export function refetchGetDirectBookingAvailabilityTimeSlotsQuery(variables?: GetDirectBookingAvailabilityTimeSlotsQueryVariables) {
      return { query: GetDirectBookingAvailabilityTimeSlotsDocument, variables: variables }
    }
export const GetHasConflictingAppointmentDocument = gql`
    query getHasConflictingAppointment($data: HasConflictingAppointmentInput!) {
  hasConflictingAppointment: getHasConflictingAppointment(data: $data)
}
    `;

/**
 * __useGetHasConflictingAppointmentQuery__
 *
 * To run a query within a React component, call `useGetHasConflictingAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHasConflictingAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHasConflictingAppointmentQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetHasConflictingAppointmentQuery(baseOptions: Apollo.QueryHookOptions<GetHasConflictingAppointmentQuery, GetHasConflictingAppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHasConflictingAppointmentQuery, GetHasConflictingAppointmentQueryVariables>(GetHasConflictingAppointmentDocument, options);
      }
export function useGetHasConflictingAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHasConflictingAppointmentQuery, GetHasConflictingAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHasConflictingAppointmentQuery, GetHasConflictingAppointmentQueryVariables>(GetHasConflictingAppointmentDocument, options);
        }
export type GetHasConflictingAppointmentQueryHookResult = ReturnType<typeof useGetHasConflictingAppointmentQuery>;
export type GetHasConflictingAppointmentLazyQueryHookResult = ReturnType<typeof useGetHasConflictingAppointmentLazyQuery>;
export type GetHasConflictingAppointmentQueryResult = Apollo.QueryResult<GetHasConflictingAppointmentQuery, GetHasConflictingAppointmentQueryVariables>;
export function refetchGetHasConflictingAppointmentQuery(variables?: GetHasConflictingAppointmentQueryVariables) {
      return { query: GetHasConflictingAppointmentDocument, variables: variables }
    }
export const GetNewPetOptionsDocument = gql`
    query getNewPetOptions($integrationId: String!) {
  findManySpecies(
    where: {integrationId: {equals: $integrationId}, isActive: {equals: true}, isDeleted: {equals: false}, directBookingSpeciesOption: {equals: Used}}
  ) {
    id
    name
    breeds(where: {isActive: {equals: true}, isDeleted: {equals: false}}) {
      id
      name
    }
  }
}
    `;

/**
 * __useGetNewPetOptionsQuery__
 *
 * To run a query within a React component, call `useGetNewPetOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewPetOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewPetOptionsQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useGetNewPetOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetNewPetOptionsQuery, GetNewPetOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewPetOptionsQuery, GetNewPetOptionsQueryVariables>(GetNewPetOptionsDocument, options);
      }
export function useGetNewPetOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewPetOptionsQuery, GetNewPetOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewPetOptionsQuery, GetNewPetOptionsQueryVariables>(GetNewPetOptionsDocument, options);
        }
export type GetNewPetOptionsQueryHookResult = ReturnType<typeof useGetNewPetOptionsQuery>;
export type GetNewPetOptionsLazyQueryHookResult = ReturnType<typeof useGetNewPetOptionsLazyQuery>;
export type GetNewPetOptionsQueryResult = Apollo.QueryResult<GetNewPetOptionsQuery, GetNewPetOptionsQueryVariables>;
export function refetchGetNewPetOptionsQuery(variables?: GetNewPetOptionsQueryVariables) {
      return { query: GetNewPetOptionsDocument, variables: variables }
    }
export const GetSexesDocument = gql`
    query getSexes($integrationId: String!) {
  findManySex(
    where: {integrationId: {equals: $integrationId}, isActive: {equals: true}, isDeleted: {equals: false}}
  ) {
    id
    name
    speciesId
  }
}
    `;

/**
 * __useGetSexesQuery__
 *
 * To run a query within a React component, call `useGetSexesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSexesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSexesQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useGetSexesQuery(baseOptions: Apollo.QueryHookOptions<GetSexesQuery, GetSexesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSexesQuery, GetSexesQueryVariables>(GetSexesDocument, options);
      }
export function useGetSexesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSexesQuery, GetSexesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSexesQuery, GetSexesQueryVariables>(GetSexesDocument, options);
        }
export type GetSexesQueryHookResult = ReturnType<typeof useGetSexesQuery>;
export type GetSexesLazyQueryHookResult = ReturnType<typeof useGetSexesLazyQuery>;
export type GetSexesQueryResult = Apollo.QueryResult<GetSexesQuery, GetSexesQueryVariables>;
export function refetchGetSexesQuery(variables?: GetSexesQueryVariables) {
      return { query: GetSexesDocument, variables: variables }
    }
export const GetProviderAppointmentTypeRoomMapDocument = gql`
    query getProviderAppointmentTypeRoomMap($clinicId: String!) {
  findManyProviderAppointmentTypeRoomMap(
    where: {appointmentType: {clinicId: {equals: $clinicId}}}
  ) {
    id
    clinicEmployeeId
    appoinmentTypeId
    roomId
  }
}
    `;

/**
 * __useGetProviderAppointmentTypeRoomMapQuery__
 *
 * To run a query within a React component, call `useGetProviderAppointmentTypeRoomMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderAppointmentTypeRoomMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderAppointmentTypeRoomMapQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetProviderAppointmentTypeRoomMapQuery(baseOptions: Apollo.QueryHookOptions<GetProviderAppointmentTypeRoomMapQuery, GetProviderAppointmentTypeRoomMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProviderAppointmentTypeRoomMapQuery, GetProviderAppointmentTypeRoomMapQueryVariables>(GetProviderAppointmentTypeRoomMapDocument, options);
      }
export function useGetProviderAppointmentTypeRoomMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProviderAppointmentTypeRoomMapQuery, GetProviderAppointmentTypeRoomMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProviderAppointmentTypeRoomMapQuery, GetProviderAppointmentTypeRoomMapQueryVariables>(GetProviderAppointmentTypeRoomMapDocument, options);
        }
export type GetProviderAppointmentTypeRoomMapQueryHookResult = ReturnType<typeof useGetProviderAppointmentTypeRoomMapQuery>;
export type GetProviderAppointmentTypeRoomMapLazyQueryHookResult = ReturnType<typeof useGetProviderAppointmentTypeRoomMapLazyQuery>;
export type GetProviderAppointmentTypeRoomMapQueryResult = Apollo.QueryResult<GetProviderAppointmentTypeRoomMapQuery, GetProviderAppointmentTypeRoomMapQueryVariables>;
export function refetchGetProviderAppointmentTypeRoomMapQuery(variables?: GetProviderAppointmentTypeRoomMapQueryVariables) {
      return { query: GetProviderAppointmentTypeRoomMapDocument, variables: variables }
    }
export const SubmitPrescriptionRefillRequestDocument = gql`
    mutation submitPrescriptionRefillRequest($data: SubmitPrescriptionRefillRequestInput!) {
  submitPrescriptionRefillRequest(data: $data) {
    success
    channelId
    clinicWidgetRequestId
  }
}
    `;
export type SubmitPrescriptionRefillRequestMutationFn = Apollo.MutationFunction<SubmitPrescriptionRefillRequestMutation, SubmitPrescriptionRefillRequestMutationVariables>;

/**
 * __useSubmitPrescriptionRefillRequestMutation__
 *
 * To run a mutation, you first call `useSubmitPrescriptionRefillRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPrescriptionRefillRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPrescriptionRefillRequestMutation, { data, loading, error }] = useSubmitPrescriptionRefillRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitPrescriptionRefillRequestMutation(baseOptions?: Apollo.MutationHookOptions<SubmitPrescriptionRefillRequestMutation, SubmitPrescriptionRefillRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitPrescriptionRefillRequestMutation, SubmitPrescriptionRefillRequestMutationVariables>(SubmitPrescriptionRefillRequestDocument, options);
      }
export type SubmitPrescriptionRefillRequestMutationHookResult = ReturnType<typeof useSubmitPrescriptionRefillRequestMutation>;
export type SubmitPrescriptionRefillRequestMutationResult = Apollo.MutationResult<SubmitPrescriptionRefillRequestMutation>;
export type SubmitPrescriptionRefillRequestMutationOptions = Apollo.BaseMutationOptions<SubmitPrescriptionRefillRequestMutation, SubmitPrescriptionRefillRequestMutationVariables>;
export const CreateClinicWidgetRequestDocument = gql`
    mutation createClinicWidgetRequest($data: ClinicWidgetRequestCreateInput!) {
  createClinicWidgetRequest2(data: $data) {
    id
    clinicWidgetRequestType {
      requestType
    }
    clinicId
    clinic {
      name
    }
  }
}
    `;
export type CreateClinicWidgetRequestMutationFn = Apollo.MutationFunction<CreateClinicWidgetRequestMutation, CreateClinicWidgetRequestMutationVariables>;

/**
 * __useCreateClinicWidgetRequestMutation__
 *
 * To run a mutation, you first call `useCreateClinicWidgetRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClinicWidgetRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClinicWidgetRequestMutation, { data, loading, error }] = useCreateClinicWidgetRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateClinicWidgetRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateClinicWidgetRequestMutation, CreateClinicWidgetRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClinicWidgetRequestMutation, CreateClinicWidgetRequestMutationVariables>(CreateClinicWidgetRequestDocument, options);
      }
export type CreateClinicWidgetRequestMutationHookResult = ReturnType<typeof useCreateClinicWidgetRequestMutation>;
export type CreateClinicWidgetRequestMutationResult = Apollo.MutationResult<CreateClinicWidgetRequestMutation>;
export type CreateClinicWidgetRequestMutationOptions = Apollo.BaseMutationOptions<CreateClinicWidgetRequestMutation, CreateClinicWidgetRequestMutationVariables>;
export const CreateParentAndPatientDocument = gql`
    mutation createParentAndPatient($data: ClinicPetParentCreateInput!) {
  createOneClinicPetParent(data: $data) {
    ...CreateParentAndPatientResponse
  }
}
    ${CreateParentAndPatientResponseFragmentDoc}`;
export type CreateParentAndPatientMutationFn = Apollo.MutationFunction<CreateParentAndPatientMutation, CreateParentAndPatientMutationVariables>;

/**
 * __useCreateParentAndPatientMutation__
 *
 * To run a mutation, you first call `useCreateParentAndPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParentAndPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParentAndPatientMutation, { data, loading, error }] = useCreateParentAndPatientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateParentAndPatientMutation(baseOptions?: Apollo.MutationHookOptions<CreateParentAndPatientMutation, CreateParentAndPatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateParentAndPatientMutation, CreateParentAndPatientMutationVariables>(CreateParentAndPatientDocument, options);
      }
export type CreateParentAndPatientMutationHookResult = ReturnType<typeof useCreateParentAndPatientMutation>;
export type CreateParentAndPatientMutationResult = Apollo.MutationResult<CreateParentAndPatientMutation>;
export type CreateParentAndPatientMutationOptions = Apollo.BaseMutationOptions<CreateParentAndPatientMutation, CreateParentAndPatientMutationVariables>;
export const GenerateWidgetAuthenticationRequestDocument = gql`
    mutation generateWidgetAuthenticationRequest($where: WidgetClinicPetParentLookupInput!) {
  generateWidgetAuthenticationRequest(where: $where) {
    success
    message
  }
}
    `;
export type GenerateWidgetAuthenticationRequestMutationFn = Apollo.MutationFunction<GenerateWidgetAuthenticationRequestMutation, GenerateWidgetAuthenticationRequestMutationVariables>;

/**
 * __useGenerateWidgetAuthenticationRequestMutation__
 *
 * To run a mutation, you first call `useGenerateWidgetAuthenticationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateWidgetAuthenticationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateWidgetAuthenticationRequestMutation, { data, loading, error }] = useGenerateWidgetAuthenticationRequestMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGenerateWidgetAuthenticationRequestMutation(baseOptions?: Apollo.MutationHookOptions<GenerateWidgetAuthenticationRequestMutation, GenerateWidgetAuthenticationRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateWidgetAuthenticationRequestMutation, GenerateWidgetAuthenticationRequestMutationVariables>(GenerateWidgetAuthenticationRequestDocument, options);
      }
export type GenerateWidgetAuthenticationRequestMutationHookResult = ReturnType<typeof useGenerateWidgetAuthenticationRequestMutation>;
export type GenerateWidgetAuthenticationRequestMutationResult = Apollo.MutationResult<GenerateWidgetAuthenticationRequestMutation>;
export type GenerateWidgetAuthenticationRequestMutationOptions = Apollo.BaseMutationOptions<GenerateWidgetAuthenticationRequestMutation, GenerateWidgetAuthenticationRequestMutationVariables>;
export const SendCarePlanSignUpLinkDocument = gql`
    mutation sendCarePlanSignUpLink($data: SendCareSignUpEmailInput) {
  sendCareSignUpEmail(data: $data) {
    success
  }
}
    `;
export type SendCarePlanSignUpLinkMutationFn = Apollo.MutationFunction<SendCarePlanSignUpLinkMutation, SendCarePlanSignUpLinkMutationVariables>;

/**
 * __useSendCarePlanSignUpLinkMutation__
 *
 * To run a mutation, you first call `useSendCarePlanSignUpLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCarePlanSignUpLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCarePlanSignUpLinkMutation, { data, loading, error }] = useSendCarePlanSignUpLinkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendCarePlanSignUpLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendCarePlanSignUpLinkMutation, SendCarePlanSignUpLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCarePlanSignUpLinkMutation, SendCarePlanSignUpLinkMutationVariables>(SendCarePlanSignUpLinkDocument, options);
      }
export type SendCarePlanSignUpLinkMutationHookResult = ReturnType<typeof useSendCarePlanSignUpLinkMutation>;
export type SendCarePlanSignUpLinkMutationResult = Apollo.MutationResult<SendCarePlanSignUpLinkMutation>;
export type SendCarePlanSignUpLinkMutationOptions = Apollo.BaseMutationOptions<SendCarePlanSignUpLinkMutation, SendCarePlanSignUpLinkMutationVariables>;
export const SendClinicPetParentAuthLinkDocument = gql`
    mutation sendClinicPetParentAuthLink($data: SendClinicPetParentAuthLinkRequest!) {
  sendClinicPetParentAuthLink(data: $data) {
    success
    message
  }
}
    `;
export type SendClinicPetParentAuthLinkMutationFn = Apollo.MutationFunction<SendClinicPetParentAuthLinkMutation, SendClinicPetParentAuthLinkMutationVariables>;

/**
 * __useSendClinicPetParentAuthLinkMutation__
 *
 * To run a mutation, you first call `useSendClinicPetParentAuthLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendClinicPetParentAuthLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendClinicPetParentAuthLinkMutation, { data, loading, error }] = useSendClinicPetParentAuthLinkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendClinicPetParentAuthLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendClinicPetParentAuthLinkMutation, SendClinicPetParentAuthLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendClinicPetParentAuthLinkMutation, SendClinicPetParentAuthLinkMutationVariables>(SendClinicPetParentAuthLinkDocument, options);
      }
export type SendClinicPetParentAuthLinkMutationHookResult = ReturnType<typeof useSendClinicPetParentAuthLinkMutation>;
export type SendClinicPetParentAuthLinkMutationResult = Apollo.MutationResult<SendClinicPetParentAuthLinkMutation>;
export type SendClinicPetParentAuthLinkMutationOptions = Apollo.BaseMutationOptions<SendClinicPetParentAuthLinkMutation, SendClinicPetParentAuthLinkMutationVariables>;
export const VerifyWidgetAuthenticationRequestDocument = gql`
    mutation verifyWidgetAuthenticationRequest($data: VerifyWidgetAuthenticationRequestInput!) {
  verifyWidgetAuthenticationRequest(data: $data) {
    success
    token
  }
}
    `;
export type VerifyWidgetAuthenticationRequestMutationFn = Apollo.MutationFunction<VerifyWidgetAuthenticationRequestMutation, VerifyWidgetAuthenticationRequestMutationVariables>;

/**
 * __useVerifyWidgetAuthenticationRequestMutation__
 *
 * To run a mutation, you first call `useVerifyWidgetAuthenticationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyWidgetAuthenticationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyWidgetAuthenticationRequestMutation, { data, loading, error }] = useVerifyWidgetAuthenticationRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVerifyWidgetAuthenticationRequestMutation(baseOptions?: Apollo.MutationHookOptions<VerifyWidgetAuthenticationRequestMutation, VerifyWidgetAuthenticationRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyWidgetAuthenticationRequestMutation, VerifyWidgetAuthenticationRequestMutationVariables>(VerifyWidgetAuthenticationRequestDocument, options);
      }
export type VerifyWidgetAuthenticationRequestMutationHookResult = ReturnType<typeof useVerifyWidgetAuthenticationRequestMutation>;
export type VerifyWidgetAuthenticationRequestMutationResult = Apollo.MutationResult<VerifyWidgetAuthenticationRequestMutation>;
export type VerifyWidgetAuthenticationRequestMutationOptions = Apollo.BaseMutationOptions<VerifyWidgetAuthenticationRequestMutation, VerifyWidgetAuthenticationRequestMutationVariables>;
export const GetClinicWidgetRequestDocument = gql`
    query getClinicWidgetRequest($where: ClinicWidgetRequestWhereUniqueInput!) {
  findUniqueClinicWidgetRequest(where: $where) {
    id
    isVerified
    clinicWidgetRequestType {
      id
      requestType
    }
  }
}
    `;

/**
 * __useGetClinicWidgetRequestQuery__
 *
 * To run a query within a React component, call `useGetClinicWidgetRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicWidgetRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicWidgetRequestQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicWidgetRequestQuery(baseOptions: Apollo.QueryHookOptions<GetClinicWidgetRequestQuery, GetClinicWidgetRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicWidgetRequestQuery, GetClinicWidgetRequestQueryVariables>(GetClinicWidgetRequestDocument, options);
      }
export function useGetClinicWidgetRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicWidgetRequestQuery, GetClinicWidgetRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicWidgetRequestQuery, GetClinicWidgetRequestQueryVariables>(GetClinicWidgetRequestDocument, options);
        }
export type GetClinicWidgetRequestQueryHookResult = ReturnType<typeof useGetClinicWidgetRequestQuery>;
export type GetClinicWidgetRequestLazyQueryHookResult = ReturnType<typeof useGetClinicWidgetRequestLazyQuery>;
export type GetClinicWidgetRequestQueryResult = Apollo.QueryResult<GetClinicWidgetRequestQuery, GetClinicWidgetRequestQueryVariables>;
export function refetchGetClinicWidgetRequestQuery(variables?: GetClinicWidgetRequestQueryVariables) {
      return { query: GetClinicWidgetRequestDocument, variables: variables }
    }
export const GetClinicWidgetSettingDocument = gql`
    query getClinicWidgetSetting($where: ClinicWidgetSettingWhereInput!) {
  findFirstClinicWidgetSetting(where: $where) {
    id
    clinic(where: {isClinicActive: {equals: true}}) {
      id
      name
      logo
      optimizedLogoUrl
      clinicEmail
      phone
      featureFlags {
        id
        key
      }
      integrations {
        ...ClinicPimsIntegrationSelection
      }
    }
    isEnabled
    welcomeMessage
    primaryColor
    secondaryColor
    whiteLabelUrls
    clinicWidgetRequestTypes {
      id
      displayName
      isDeleted
      isActive
      order
      requestType
      requestFormTemplate
    }
  }
}
    ${ClinicPimsIntegrationSelectionFragmentDoc}`;

/**
 * __useGetClinicWidgetSettingQuery__
 *
 * To run a query within a React component, call `useGetClinicWidgetSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicWidgetSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicWidgetSettingQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicWidgetSettingQuery(baseOptions: Apollo.QueryHookOptions<GetClinicWidgetSettingQuery, GetClinicWidgetSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicWidgetSettingQuery, GetClinicWidgetSettingQueryVariables>(GetClinicWidgetSettingDocument, options);
      }
export function useGetClinicWidgetSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicWidgetSettingQuery, GetClinicWidgetSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicWidgetSettingQuery, GetClinicWidgetSettingQueryVariables>(GetClinicWidgetSettingDocument, options);
        }
export type GetClinicWidgetSettingQueryHookResult = ReturnType<typeof useGetClinicWidgetSettingQuery>;
export type GetClinicWidgetSettingLazyQueryHookResult = ReturnType<typeof useGetClinicWidgetSettingLazyQuery>;
export type GetClinicWidgetSettingQueryResult = Apollo.QueryResult<GetClinicWidgetSettingQuery, GetClinicWidgetSettingQueryVariables>;
export function refetchGetClinicWidgetSettingQuery(variables?: GetClinicWidgetSettingQueryVariables) {
      return { query: GetClinicWidgetSettingDocument, variables: variables }
    }
export const WidgetClinicPetParentLookupDocument = gql`
    query widgetClinicPetParentLookup($where: WidgetClinicPetParentLookupInput!) {
  widgetClinicPetParentLookup2(where: $where) {
    id
    pimsId
    firstName
    lastName
    email
    isActive
    createdAt
    creationSource
    pets {
      id
      pimsId
      name
      species
      photoUrl
      prescriptions(orderBy: {description: asc}) {
        id
        description
        quantity
        instructions
        comments
        issuedAt
        expiredAt
        lastRequestedAt
        isActive
        isDeleted
        currentRefillNumber
        totalRefills
      }
    }
    phoneNumbers {
      number
      isPrimary
      isMobile
      isDeleted
      updatedAt
    }
  }
}
    `;

/**
 * __useWidgetClinicPetParentLookupQuery__
 *
 * To run a query within a React component, call `useWidgetClinicPetParentLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useWidgetClinicPetParentLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWidgetClinicPetParentLookupQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useWidgetClinicPetParentLookupQuery(baseOptions: Apollo.QueryHookOptions<WidgetClinicPetParentLookupQuery, WidgetClinicPetParentLookupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WidgetClinicPetParentLookupQuery, WidgetClinicPetParentLookupQueryVariables>(WidgetClinicPetParentLookupDocument, options);
      }
export function useWidgetClinicPetParentLookupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WidgetClinicPetParentLookupQuery, WidgetClinicPetParentLookupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WidgetClinicPetParentLookupQuery, WidgetClinicPetParentLookupQueryVariables>(WidgetClinicPetParentLookupDocument, options);
        }
export type WidgetClinicPetParentLookupQueryHookResult = ReturnType<typeof useWidgetClinicPetParentLookupQuery>;
export type WidgetClinicPetParentLookupLazyQueryHookResult = ReturnType<typeof useWidgetClinicPetParentLookupLazyQuery>;
export type WidgetClinicPetParentLookupQueryResult = Apollo.QueryResult<WidgetClinicPetParentLookupQuery, WidgetClinicPetParentLookupQueryVariables>;
export function refetchWidgetClinicPetParentLookupQuery(variables?: WidgetClinicPetParentLookupQueryVariables) {
      return { query: WidgetClinicPetParentLookupDocument, variables: variables }
    }
export const WidgetClinicPetParentLookupEnrollmentDocument = gql`
    query widgetClinicPetParentLookupEnrollment($where: WidgetClinicPetParentLookupInput!) {
  widgetClinicPetParentLookupEnrollment(where: $where) {
    id
    firstName
    lastName
    email
    isActive
    pets {
      ...WidgetClinicPetLookupEnrollment
    }
    phoneNumbers {
      id
      number
      isPrimary
      isMobile
      isDeleted
      updatedAt
    }
  }
}
    ${WidgetClinicPetLookupEnrollmentFragmentDoc}`;

/**
 * __useWidgetClinicPetParentLookupEnrollmentQuery__
 *
 * To run a query within a React component, call `useWidgetClinicPetParentLookupEnrollmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useWidgetClinicPetParentLookupEnrollmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWidgetClinicPetParentLookupEnrollmentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useWidgetClinicPetParentLookupEnrollmentQuery(baseOptions: Apollo.QueryHookOptions<WidgetClinicPetParentLookupEnrollmentQuery, WidgetClinicPetParentLookupEnrollmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WidgetClinicPetParentLookupEnrollmentQuery, WidgetClinicPetParentLookupEnrollmentQueryVariables>(WidgetClinicPetParentLookupEnrollmentDocument, options);
      }
export function useWidgetClinicPetParentLookupEnrollmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WidgetClinicPetParentLookupEnrollmentQuery, WidgetClinicPetParentLookupEnrollmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WidgetClinicPetParentLookupEnrollmentQuery, WidgetClinicPetParentLookupEnrollmentQueryVariables>(WidgetClinicPetParentLookupEnrollmentDocument, options);
        }
export type WidgetClinicPetParentLookupEnrollmentQueryHookResult = ReturnType<typeof useWidgetClinicPetParentLookupEnrollmentQuery>;
export type WidgetClinicPetParentLookupEnrollmentLazyQueryHookResult = ReturnType<typeof useWidgetClinicPetParentLookupEnrollmentLazyQuery>;
export type WidgetClinicPetParentLookupEnrollmentQueryResult = Apollo.QueryResult<WidgetClinicPetParentLookupEnrollmentQuery, WidgetClinicPetParentLookupEnrollmentQueryVariables>;
export function refetchWidgetClinicPetParentLookupEnrollmentQuery(variables?: WidgetClinicPetParentLookupEnrollmentQueryVariables) {
      return { query: WidgetClinicPetParentLookupEnrollmentDocument, variables: variables }
    }